 

import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Button } from 'react-bootstrap';
import { Button as PrimeButton } from "primereact/button";
import { Modal, Form, Col, Row, Card, Accordion, Container } from 'react-bootstrap';
import memoize from 'memoize-one';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import DataTable from 'react-data-table-component';
import { handleRequest } from '../../redux/config/env';
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Row as AntRow, Col as AntCol, Table, InputNumber, message, DatePicker, Checkbox, Input, Select, Divider, Upload, Button as AntdButton } from 'antd';
import { UploadOutlined, DownloadOutlined, PlusOutlined  } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { API_BASE } from '../../redux/config/env';

const { Option } = Select;
const sortIcon = <ArrowDownward />;

const columns = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Title",
        selector: "title",
        sortable: true,
        cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Directior",
        selector: "director",
        sortable: true
    },
    {
        name: "Runtime (m)",
        selector: "runtime",
        sortable: true,
        right: true
    },
    {
        name: 'Year',
        selector: 'year',
        sortable: true,
        right: true,
    }
]);

const columnsEquipmentManufacturing = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "id",
        sortable: true,
        width: '56px',
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Manufacturer",
        selector: "manufacturer",
        sortable: true
    },
    {
        name: "Manufacturing Year",
        selector: "manufacturingYear",
        sortable: true,
    },
    {
        name: 'Brand',
        selector: 'brand',
        sortable: true,
    },
    {
        name: 'Model',
        selector: 'model',
        sortable: true,
    },
    {
        name: 'Serial No',
        selector: 'serialNo',
        sortable: true,
    },
    {
        name: 'Chasis No',
        selector: 'chasisNo',
        sortable: true,
    }
]);

const columnsEquipmentOperating = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "index",
        sortable: true,
        width: '56px'
    },
    {
        name: "Purpose of Usage",
        selector: "purposeOfUsageTypeCode.name",
        sortable: true
    },
    {
        name: "Department",
        selector: "department.departmentName",
        sortable: true,
    },
    {
        name: 'Discipline',
        selector: 'discipline.disciplineName',
        sortable: true,
    },
    {
        name: 'Activity',
        selector: 'activityTypeCode.name',
        sortable: true,
    },
    {
        name: 'Operator',
        selector: 'operatorUserTypeCode.name',
        sortable: true,
    },
    {
        name: 'Energy/Fuel Type',
        selector: 'energyTypeCode.name',
        sortable: true,
    },
    {
        name: 'Consumption',
        selector: 'consumption',
        sortable: true,
        cell: row => <div><div style={{ fontWeight: 700 }}>{row.consumption}</div>{row.consumptionTypeCode.name} per {row.consumptionPerTypeCode.name}</div>
    },
    {
        name: 'Capacity',
        selector: 'capacity',
        sortable: true,
        cell: row => <div><div style={{ fontWeight: 700 }}>{row.capacity}</div>{row.capacityTypeCode.name}</div>
    }
]);

const columnsEquipmentCalibration = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "id",
        sortable: true,
        width: '56px',
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Date",
        selector: "lastCalibrationDate",
        sortable: true
    },
    {
        name: "Period For Calibration ",
        selector: "periodForCalibration",
        sortable: true,
        hide: 3000
    },
    {
        name: "Period For Calibration Type",
        selector: "periodForCalibrationTypeCode.name",
        sortable: true,
        hide: 3000
    },
    {
        name: "Calibration Body",
        selector: "calibrationBodyTypeCode.name",
        sortable: true,
        hide: 3000
    },
    {
        name: "Next Calibration Date",
        selector: "price",
        sortable: true,
    },
    {
        name: 'Certificate',
        selector: 'certificatePath',
        sortable: true,
    },
    {
        name: 'Status',
        selector: '',
        sortable: true,
    }
]);

const columnsEquipmentVehicleLicence = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "id",
        sortable: true,
        width: '56px',
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Plate No",
        selector: "plateNo",
        sortable: true
    },
    {
        name: "Licence/Estimara",
        selector: "licence",
        sortable: true,
    },
    {
        name: 'Expirity Date',
        selector: 'licenceExpirityDate',
        sortable: true,
    },
    {
        name: 'Photo',
        selector: 'licencePhotoPath',
        sortable: true,
    }
]);

const columnsEquipmentInsurance = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "id",
        sortable: true,
        width: '56px',
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Insurance Company",
        selector: "insuranceCompany",
        sortable: true
    },
    {
        name: "Insurance Start Date",
        selector: "insuranceStartDate",
        sortable: true,
    },
    {
        name: "Insurance End Date",
        selector: "insuranceEndDate",
        sortable: true,
    },
    {
        name: 'Emergency Phone',
        selector: 'emergencyPhone',
        sortable: true,
    },
    {
        name: 'Insurance PDF',
        selector: 'insurancePhotoPath',
        sortable: true,
    }
]);

const columnsEquipmentMaintenance = memoize((deleteHandler, deleteHandler2) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={deleteHandler} onDeleteRow2={deleteHandler2} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "id",
        sortable: true,
        width: '56px',
        //cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Maintenance Type",
        selector: "name",
        sortable: true
    },
    {
        name: "Date of Maintenance",
        selector: "price",
        sortable: true,
    },
    {
        name: 'Status',
        selector: '',
        sortable: true,
    },
    {
        name: 'Details',
        selector: '',
        sortable: true,
    }
]);
  
export class EquipmentLogSub extends Component {

    constructor() {
        super();
        this.state = {
            deleteId: 0,
            deleteFormName: "",
            editId: 0,
            testModal: false,
            customerId: 0,
            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            lastButton: "Save",
            expanded: [],
            equipmentCode: "",
            manufacturer: "",
            manufacturingYear: "",
            brand: "",
            model: "",
            serialNo: "",
            chasisNo: "",
            plateNo: "",
            licence: "",
            licenceExpirityDate: null,
            licencePhotoPath: "",
            lastCalibrationDate: null,
            period: "",
            insuranceCompany: "",
            insuranceStartDate: null,
            insuranceEndDate: null,
            emergencyPhone: "",
            departments: [],
            departmentId: null,
            disciplines: [],
            disciplineId: null,
            department: "Select Department",
            discipline: "Select Discipline",
            capacityTypes: [],
            capacityTypeId: null,
            capacityType: "Select Capacity Type",
            fuelTankCapacityTypes: [],
            fuelTankCapacityId: null,
            fuelTankCapacityType: "Select Fuel Tank Capacity Type",
            fuelTankCapacity: "",
            capacity: "",
            consumption: "",
            modalShowDelete: false,
            modalShowManufacturing: false,
            modalShowOperating: false,
            modalShowCalibration: false,
            modalShowVehicleLicence: false,
            modalShowInsurance: false,
            equipmentLogId: "",
            equipmentManufacturingLogs: [],
            equipmentOperatingLogs: [],
            equipmentCalibrationLogs: [],
            equipmentVehicleLicenceLogs: [],
            equipmentInsuranceLogs: [],
            equipmentMaintenanceLogs: [],
            equipmentPartListLogs: [],
            lastButtonManufacturingModal: "Save",
            lastButtonVehicleLicenceModal: "Save",
            lastButtonInsuranceModal: "Save",
            lastButtonCalibrationModal: "Save",
            calibrationBody: null,
            calibrationBodies: [],
            periodForCalibrationText: "",
            periodForCalibration: null,
            periodForCalibrations: [],
            purpose: null,
            purposes: [],
            activity: null,
            activities: [],
            energyType: null,
            energyTypes: [],
            consumptionType: null,
            consumptionTypes: [],
            consumptionTypeFrequency: null,
            consumptionTypeFrequencies: [],
            operatorUser: null,
            operatorUsers: [],
            averageSpeed: "",
            capitalCost: "",
            redemptionRate: "",
            diesel: "",
            maintenance: "",
            repairs: "",
            insuranceG: "",
            insuranceP: "",
            taxes: "",
            fees: "",
            salary: "",
            food: "",
            accom: "",
            feesDriver: "",
            insurance: "",
            equipmentTruckCost: [],
            equipmentTruckCostId: 0,
            date: null,
            workflowList: [],
            insuranceWorkflowId: null,
            insuranceSchedule: true,
            EQLUnitGroups:[],
            PhotoFileUpload: [],
            photoFilePath: "",
            fileList: [],
            hiddenDataGirisPartlist:true,
            hiddenPartListUpdate:true,
            hiddenPartListAdd:false,
            unitGroup:0,
            equipmentPartListId:0,
            createNewPartListButton:"Create New",
            createNewPartListVariant:"info",
            maintenanceSchedule:false,
            hiddenMaintenanceSchedule:false,
        };

        this.onPeriodForCalibrationChange = this.onPeriodForCalibrationChange.bind(this);
        this.onCalibrationBodyChange = this.onCalibrationBodyChange.bind(this);
        this.onPurposeChange = this.onPurposeChange.bind(this);
        this.onActivityChange = this.onActivityChange.bind(this);
        this.onEnergyTypeChange = this.onEnergyTypeChange.bind(this);
        this.onConsumptionTypeChange = this.onConsumptionTypeChange.bind(this);
        this.onConsumptionTypeFrequencyChange = this.onConsumptionTypeFrequencyChange.bind(this);
        this.onOperatorUserChange = this.onOperatorUserChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

    };

    componentDidMount = async () => {

        console.log("RRRR",this.props.row);  //Expand ettiğimizde expand edilen satır gelir.

        this.setState({
            equipmentLogId: this.props.row.id, 
        });


        const response9 = await handleRequest("GET", "/WorkFlow/getAll");

        if (Boolean(response9.data)) {
            let wList = response9.data;
            var insuranceWorkflow = wList.find(p => p.executionArea === 1);
            if (Boolean(insuranceWorkflow))
                this.setState({ insuranceWorkflowId: insuranceWorkflow.id });

        }

        const response3 = await handleRequest("GET", "/EquipmentManufacturing/getAll");

        if (response3.data.length === 0) {
            this.setState({
                equipmentManufacturingLogs: [],
            });
        }

        if (response3.data.length !== 0) {

            const columns = response3.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentManufacturingLogs: options,
            });
        }

        const response4 = await handleRequest("GET", "/EquipmentOperating/getAll");

        if (response4.data.length === 0) {
            this.setState({
                equipmentOperatingLogs: [],
            });
        }

        if (response4.data.length !== 0) {

            const columns = response4.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentOperatingLogs: options,
            });
        }

        const response5 = await handleRequest("GET", "/EquipmentCalibration/getAll");

        if (response5.data.length === 0) {
            this.setState({
                equipmentCalibrationLogs: [],
            });
        }

        if (response5.data.length !== 0) {

            const columns = response5.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentCalibrationLogs: options,
            });
        }

        const response6 = await handleRequest("GET", "/EquipmentVehicleLicence/getAll");

        if (response6.data.length === 0) {
            this.setState({
                equipmentVehicleLicenceLogs: [],
            });
        }

        if (response6.data.length !== 0) {

            const columns = response6.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentVehicleLicenceLogs: options,
            });
        }

        const response7 = await handleRequest("GET", "/EquipmentInsurance/getAll");

        if (response7.data.length === 0) {
            this.setState({
                equipmentInsuranceLogs: [],
            });
        }

        if (response7.data.length !== 0) {

            const columns = response7.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentInsuranceLogs: options,
            });
        }

        const response8 = await handleRequest("GET", "/EquipmentTruckCost/getAll");

        if (response8.data.length === 0) {
            this.setState({
                equipmentTruckCost: [],
                equipmentTruckCostId: 0
            });
        }

        if (response8.data.length !== 0) {

            const columns = response8.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);

                    if (columns[index].dateOfPurchasing === null || columns[index].dateOfPurchasing === undefined) {
                        var today = new Date();
                        var dd = String(today.getDate()).padStart(2, '0');
                        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                        var yyyy = today.getFullYear();

                        today = dd + '/' + mm + '/' + yyyy;
                        this.setState({
                            date: today
                        });
                    } else {
                        this.setState({
                            date: columns[index].dateOfPurchasing
                        });
                    }

                    this.setState({
                        equipmentTruckCostId: columns[index].id,
                        capitalCost: columns[index].capitalCost,
                        redemptionRate: columns[index].redemptionRate,
                        averageSpeed: columns[index].averageSpeed,
                        diesel: columns[index].diesel,
                        maintenance: columns[index].maintenance,
                        repairs: columns[index].repairs,
                        insuranceG: columns[index].insuranceG,
                        insuranceP: columns[index].insuranceP,
                        taxes: columns[index].taxes,
                        fees: columns[index].fees,
                        salary: columns[index].salary,
                        food: columns[index].food,
                        accom: columns[index].accom,
                        feesDriver: columns[index].feesDriver,
                        insurance: columns[index].insurance
                    });
                }
            }

            this.setState({
                equipmentTruckCost: options,
            });
        }

        //Bu kısımlar bu sayfalar eklendikten sonra yapılacak!
        // const response8 = await handleRequest("GET", "/EquipmentMaintenance/getAll");

        // if (response8.data.length === 0) {
        //     this.setState({
        //         equipmentMaintenanceLogs: [],
        //     });
        // }

        // if (response8.data.length !== 0) {

        //     const columns = response8.data;

        //     var options = [];

        //     for (let index = 0; index < columns.length; index++) {
        //         if (columns[index].equipmentLogId === this.props.row.id) {
        //             options.push(columns[index]);
        //         }
        //     }

        //     this.setState({
        //         equipmentMaintenanceLogs: options,
        //     });
        // }
 
        const response10 = await handleRequest("GET", "/Code/getAll");

        if (response10.data.length !== 0) {

            const columns = response10.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "periodForCalibration") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                periodForCalibrations: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "calibrationBody") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                calibrationBodies: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "purposeOfUsage") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                purposes: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "activity") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                activities: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "energyType") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                energyTypes: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "consumptionType") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                consumptionTypes: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "consumptionTypeFrequency") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                consumptionTypeFrequencies: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "operatorUser") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                operatorUsers: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "capacityType") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                capacityTypes: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "fuelTankCapacityType") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                fuelTankCapacityTypes: options
            });
        }

        const response = await handleRequest("GET", "/Department/getAll");

        if (response.data.length === 0) {
            this.setState({
                departments: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                departments: response.data,
            });

            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() }
            })

            this.setState({
                departments: options,
            });
        }

        const response2 = await handleRequest("GET", "/Discipline/getAll");

        if (response2.data.length !== 0) {

            const columns2 = response2.data;

            const options2 = columns2.map(function (row) {
                return { name: row.disciplineName.toString(), id: row.id.toString() }
            })

            this.setState({
                disciplines: options2,
            });
        }


        this.getEquipmentMaintenance();  
        this.getEquipmentMaintenanceScheduleCheck(this.props.row.equipmentTypeId);
        this.getCodesEQLUnitGroup();
        this.getEquipmentPartList();
    };

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Success"
        });
    };
    
    showError(msg) {
        if (!msg) {
            msg =  <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !"/>;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Error"
        });
    };
    
    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs"  defaultMessage="Something went wrong. Please check inputs."  />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Warning"
        });
    };

    actionTemplate(rowData) {
        return <React.Fragment> 
            <CustomMaterialMenu row={rowData} 
            onDeleteRow={this.deleteEquipmentPartList.bind(this)} 
            onEditRow={this.editEquipmentPartList.bind(this)} />   
            </React.Fragment>;
      };

     getEquipmentPartList = async () => {
        var equipmentLogId="-1";
        if(this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!=""){
            equipmentLogId=this.state.equipmentLogId;
        }
        const newItem = {
            equipmentLogId: equipmentLogId,
        }
        const response = await handleRequest("POST", "/EquipmentPartList/getResult", newItem);
        if (response.data.length === 0) {
            this.setState({
                equipmentPartListLogs: [],
            });
        }else if (response.data.length !== 0) {
            this.setState({
                equipmentPartListLogs: response.data,
            });
        }
    } 


    handleChangeEQLUnitGroup = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.EQLUnitGroups.length; z++) {
          if (this.state.EQLUnitGroups[z].id == e) {
            deger = this.state.EQLUnitGroups[z].name;
          }
        }
        this.setState({
            unitGroupAck: deger,
            unitGroup: e,
        });
    };
    
    addEQLUnitGroups = async () => {
        const { itemEQLUnitGroups } = this.state;
        if (itemEQLUnitGroups != null && itemEQLUnitGroups != "" && itemEQLUnitGroups != undefined) {
            const newItem = {
            name: itemEQLUnitGroups,
            type: "EQLUnitGroup"
            }
    
            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
            itemEQLUnitGroups: '',
            });
            this.getCodesEQLUnitGroup();
        } else {
            this.showWarning();
        }
    };
    
    getCodesEQLUnitGroup = async () => {
        const response = await handleRequest("GET", "/Code/type/EQLUnitGroup");   
    
        if (response.data !== null && response.data !== undefined) {
          this.setState({
            EQLUnitGroups: response.data
          });
        }
    } 

    getEquipmentMaintenance = async () => {
        var equipmentLogId="-1";
        if(this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!=""){
            equipmentLogId=this.state.equipmentLogId;
        }
        const newItem = {
            equipmentLogId: equipmentLogId,
        }
        const response = await handleRequest("POST", "/EquipmentMaintenance/getResult", newItem);   
        if (response.data.length !== 0) {
            this.setState({
                maintenanceSchedule: true,
            });
        }else{
              this.setState({
                maintenanceSchedule: false,
            });
        }
    } 

    getEquipmentMaintenanceScheduleCheck = async (equipmentTypeId) => {
        if(equipmentTypeId!=null && equipmentTypeId!=undefined && equipmentTypeId!=""){
            equipmentTypeId=equipmentTypeId;
        }else{
            equipmentTypeId="-1";
        }
        const newItem = {
            equipmentTypeId: equipmentTypeId,
        }
        const response = await handleRequest("POST", "/EquipmentLog/maintenanceScheduleCheck", newItem);   
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    hiddenMaintenanceSchedule:true,
                });
            }
            else {
                this.setState({
                    hiddenMaintenanceSchedule:false,
                });
            }
        }else{
            this.setState({
                hiddenMaintenanceSchedule:false,
            });
        }
    } 

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeDepartment = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.departments.length; z++) {
            if (this.state.departments[z].name === e.target.value) {
                idValue = this.state.departments[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            departmentId: idValue
        });
    }

    handleChangeDiscipline = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.disciplines.length; z++) {
            if (this.state.disciplines[z].name === e.target.value) {
                idValue = this.state.disciplines[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            disciplineId: idValue
        });
    }

    handleChangeFuelTankCapacityType = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.fuelTankCapacityTypes.length; z++) {
            if (this.state.fuelTankCapacityTypes[z].name === e.target.value) {
                idValue = this.state.fuelTankCapacityTypes[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            fuelTankCapacityId: idValue
        });
    }

    handleChangeCapacityType = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.capacityTypes.length; z++) {
            if (this.state.capacityTypes[z].name === e.target.value) {
                idValue = this.state.capacityTypes[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            capacityTypeId: idValue
        });
    }

    showOrHideModalManufacturing = () => {
        this.setState({
            modalShowManufacturing: !this.state.modalShowManufacturing,
            editId: 0,
            manufacturer: "",
            manufacturingYear: "",
            brand: "",
            model: "",
            serialNo: "",
            chasisNo: "",
            lastButtonManufacturingModal: "Save"
        });
    }

    showOrHideModalOperating = () => {
        this.setState({
            modalShowOperating: !this.state.modalShowOperating,
            editId: 0,
            purpose: null,
            activity: null,
            department: "Select Department",
            discipline: "Select Discipline",
            energyType: null,
            consumption: "",
            consumptionType: null,
            consumptionTypeFrequency: null,
            operatorUser: null,
            capacity: "",
            capacityType: "Select Capacity Type",
            lastButtonOperatingModal: "Save"
        });
    }

    showOrHideModalCalibration = () => {
        this.setState({
            modalShowCalibration: !this.state.modalShowCalibration,
            editId: 0,
            lastCalibrationDate: null,
            periodForCalibration: null,
            periodForCalibrationText: "",
            calibrationBody: null,
            lastButtonCalibrationModal: "Save"
        });
    };

    showOrHideModalVehicleLicence = () => {
        this.setState({
            modalShowVehicleLicence: !this.state.modalShowVehicleLicence,
            editId: 0,
            plateNo: "",
            licence: "",
            licenceExpirityDate: null,
            lastButtonVehicleLicenceModal: "Save"
        });
    };

    showOrHideModalInsurance = () => {
        this.setState({
            modalShowInsurance: !this.state.modalShowInsurance,
            editId: 0,
            insuranceCompany: "",
            emergencyPhone: "",
            insuranceStartDate: null,
            insuranceEndDate: null,
            lastButtonInsuranceModal: "Save"
        });
    };

    goMaintanencePlan = () => {
        //Başka bir sayfaya yönlendirilecek.
    };

    createNewPartList = () => {
        if(this.state.createNewPartListButton=="Create New"){
            this.setState({
                hiddenDataGirisPartlist:!this.state.hiddenDataGirisPartlist,
                createNewPartListButton:"Cancel",
                createNewPartListVariant: "danger",
                hiddenPartListAdd:false,
                hiddenPartListUpdate:true,
                
            });
        }else{
            this.setState({
                hiddenDataGirisPartlist:!this.state.hiddenDataGirisPartlist,
                createNewPartListButton:"Create New",
                createNewPartListVariant: "info",
                hiddenPartListAdd:false,
                hiddenPartListUpdate:true,
            });
        }
        this.resetInputsPartList();
    }

    //Bunların altında veri çekilecek ve state'leri doldurulacak modal'lar üzerinde

    //#region Edit kısımları

    editEquipmentManufacturing = (row) => {
        this.setState({
            modalShowManufacturing: !this.state.modalShowManufacturing,
            editId: row.id,
            lastButtonManufacturingModal: "Update",
            manufacturer: row.manufacturer,
            brand: row.brand,
            serialNo: row.serialNo,
            manufacturingYear: row.manufacturingYear,
            model: row.model,
            chasisNo: row.chasisNo
        });
    };

    editEquipmentOperating = (row) => {

        if (row.fuelTankCapacity !== undefined && row.fuelTankCapacity !== null) {
            this.setState({
                fuelTankCapacity: row.fuelTankCapacity,
                fuelTankCapacityType: row.fuelTankCapacityTypeCode.name,
                fuelTankCapacityTypeId: row.fuelTankCapacityTypeCode.id
            });
        }

        this.setState({
            modalShowOperating: !this.state.modalShowOperating,
            editId: row.id,
            lastButtonOperatingModal: "Update",
            purpose: row.purposeOfUsageTypeCode.name,
            activity: row.activityTypeCode.name,
            department: row.department.departmentName,
            departmentId: row.department.id,
            discipline: row.discipline.disciplineName,
            disciplineId: row.discipline.id,
            energyType: row.energyTypeCode.name,
            consumption: row.consumption,
            consumptionType: row.consumptionTypeCode.name,
            consumptionTypeFrequency: row.consumptionPerTypeCode.name,
            operatorUser: row.operatorUserTypeCode.name,
            capacity: row.capacity,
            capacityType: row.capacityTypeCode.name,
            capacityTypeId: row.capacityTypeCode.id
        });
    };

    editEquipmentCalibration = (row) => {
        this.setState({
            modalShowCalibration: !this.state.modalShowCalibration,
            editId: row.id,
            lastButtonCalibrationModal: "Update",
            lastCalibrationDate: new Date(row.lastCalibrationDate),
            periodForCalibrationText: row.periodForCalibration,
            periodForCalibration: row.periodForCalibrationTypeCode.name,
            calibrationBody: row.calibrationBodyTypeCode.name
        });
    };

    editEquipmentVehicleLicence = (row) => {
        this.setState({
            modalShowVehicleLicence: !this.state.modalShowVehicleLicence,
            editId: row.id,
            lastButtonVehicleLicenceModal: "Update",
            plateNo: row.plateNo,
            licence: row.licence,
            licenceExpirityDate: new Date(row.licenceExpirityDate)
        });
    };

    editEquipmentInsurance = (row) => {
        this.setState({
            modalShowInsurance: !this.state.modalShowInsurance,
            editId: row.id,
            lastButtonInsuranceModal: "Update",
            insuranceCompany: row.insuranceCompany,
            insuranceStartDate: new Date(row.insuranceStartDate),
            insuranceEndDate: new Date(row.insuranceEndDate),
            emergencyPhone: row.emergencyPhone
        });
    };

    editEquipmentMaintenance = (row) => {
        //Başka bir sayfaya yönlendirilecek.
    };

    editEquipmentPartList = (row) => {
        console.log("RRR",row);
        this.setState({
            hiddenPartListAdd:true,
            hiddenPartListUpdate:false,
            hiddenDataGirisPartlist:false,
            createNewPartListButton:"Cancel",
            createNewPartListVariant:"danger",
         
            equipmentPartListId:row.id,
            unitGroup:row.unitGroup,
            unitGroupAck:(row.codeUnitGroup!=null && row.codeUnitGroup!=undefined) ? row.codeUnitGroup.name : "" ,
            partName:row.partName,
            partCode:row.partCode,
            photoFilePath:row.photoFilePath,
        });
    };

    //#endregion

    //#region Delete kısımları

    deleteRecord = async () => {
        //deleteId ve deleteFormName altında tutuluyor.
        if (this.state.deleteFormName === "Manufacturing Information") {
            const deletedEquipmentManufactureLog = {
                id: this.state.deleteId
            }
            await handleRequest("PUT", "/EquipmentManufacturing/delete", deletedEquipmentManufactureLog);

            this.setState({
                modalShowDelete: false
            });
            this.restartManufactureTable();
        } else if (this.state.deleteFormName === "Operating") {
            const deletedEquipmentOperatingLog = {
                id: this.state.deleteId
            }
            await handleRequest("POST", "/EquipmentOperating/delete", deletedEquipmentOperatingLog);

            this.setState({
                modalShowDelete: false
            });
            this.restartOperatingTable();
        } else if (this.state.deleteFormName === "Calibration") {
            const deletedEquipmentCalibrationLog = {
                id: this.state.deleteId
            }
            await handleRequest("POST", "/EquipmentCalibration/delete", deletedEquipmentCalibrationLog);

            this.setState({
                modalShowDelete: false
            });
            this.restartCalibrationTable();
        } else if (this.state.deleteFormName === "Vehicle Licence") {
            const deletedEquipmentVehicleLicenceLog = {
                id: this.state.deleteId
            }
            await handleRequest("PUT", "/EquipmentVehicleLicence/delete", deletedEquipmentVehicleLicenceLog);

            this.setState({
                modalShowDelete: false
            });
            this.restartVehicleLicenceTable();
        } else if (this.state.deleteFormName === "Insurance") {
            const deletedEquipmentInsuranceLog = {
                id: this.state.deleteId
            }
            await handleRequest("PUT", "/EquipmentInsurance/delete", deletedEquipmentInsuranceLog);

            this.setState({
                modalShowDelete: false
            });
            this.restartInsuranceTable();
        } else if (this.state.deleteFormName === "Maintenance") {
            const deletedEquipmentMaintenanceLog = {
                id: this.state.deleteId
            }
            await handleRequest("PUT", "/EquipmentMaintenance/delete", deletedEquipmentMaintenanceLog);

            this.setState({
                modalShowDelete: false
            });
            this.restartMaintenanceTable();
        } else if (this.state.deleteFormName === "Part List") {
            const deletedEquipmentPartListLog = {
                id: this.state.deleteId
            }
            await handleRequest("PUT", "/EquipmentPartList/delete", deletedEquipmentPartListLog);

            this.setState({
                modalShowDelete: false
            });
            this.getEquipmentPartList();
        }

    };

    showOrHideModalDelete = () => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete
        });
    };

    deleteEquipmentManufacturing = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Manufacturing Information"
        });
    };

    deleteEquipmentOperating = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Operating"
        });
    };

    deleteEquipmentCalibration = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Calibration"
        });
    };

    deleteEquipmentVehicleLicence = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Vehicle Licence"
        });
    };

    deleteEquipmentInsurance = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Insurance"
        });
    };

    deleteEquipmentMaintenance = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Maintenance"
        });
    };

    deleteEquipmentPartList = (row) => {
        this.setState({
            modalShowDelete: !this.state.modalShowDelete,
            deleteId: row.id,
            deleteFormName: "Part List"
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    //#endregion

    saveOrEditManufacturingInformation = async () => {
        if (this.state.lastButtonManufacturingModal === "Save") {

            const newEquipmentManufactureLog = {
                equipmentLogId: this.state.equipmentLogId,
                manufacturer: this.state.manufacturer,
                manufacturingYear: this.state.manufacturingYear,
                brand: this.state.brand,
                model: this.state.model,
                serialNo: this.state.serialNo,
                chasisNo: this.state.chasisNo
            }

            await handleRequest("POST", "/EquipmentManufacturing/add", newEquipmentManufactureLog);

            this.refreshManufactureAccordions();
            this.restartManufactureTable();
        }
        else if (this.state.lastButtonManufacturingModal === "Update") {

            const updatedEquipmentManufactureLog = {
                id: this.state.editId,
                equipmentLogId: this.state.equipmentLogId,
                manufacturer: this.state.manufacturer,
                manufacturingYear: this.state.manufacturingYear,
                brand: this.state.brand,
                model: this.state.model,
                serialNo: this.state.serialNo,
                chasisNo: this.state.chasisNo
            }

            await handleRequest("PUT", "/EquipmentManufacturing/update", updatedEquipmentManufactureLog);

            this.refreshManufactureAccordions();
            this.restartManufactureTable();
        }
    }

    refreshManufactureAccordions = () => {
        this.setState({
            modalShowManufacturing: false,
            manufacturer: "",
            manufacturingYear: "",
            brand: "",
            model: "",
            serialNo: "",
            chasisNo: "",
            lastButtonManufacturingModal: "Save"
        });
    };

    saveOrEditInsurance = async () => {
        var iwf = this.state.insuranceWorkflowId;
        if (!this.state.insuranceSchedule)
            iwf = null;
        if (this.state.lastButtonInsuranceModal === "Save") {

            const newEquipmentInsuranceLog = {
                equipmentLogId: this.state.equipmentLogId,
                insuranceCompany: this.state.insuranceCompany,
                emergencyPhone: this.state.emergencyPhone,
                insuranceStartDate: this.state.insuranceStartDate,
                insuranceEndDate: this.state.insuranceEndDate,
                insurancePhotoPath: "Test Photo",
                workflowId: iwf
            }

            var response = await handleRequest("POST", "/EquipmentInsurance/add", newEquipmentInsuranceLog);
            if (Boolean(response.data)) {
                console.log(response.data);
                this.addNotification(response.data);
            }

            this.refreshInsuranceAccordions();
            this.restartInsuranceTable();
        }
        else if (this.state.lastButtonInsuranceModal === "Update") {

            const updatedEquipmentInsuranceLog = {
                id: this.state.editId,
                equipmentLogId: this.state.equipmentLogId,
                insuranceCompany: this.state.insuranceCompany,
                emergencyPhone: this.state.emergencyPhone,
                insuranceStartDate: this.state.insuranceStartDate,
                insuranceEndDate: this.state.insuranceEndDate,
                insurancePhotoPath: "Test Photo", 
                workflowId:iwf
            }

            await handleRequest("PUT", "/EquipmentInsurance/update", updatedEquipmentInsuranceLog);

            this.refreshInsuranceAccordions();
            this.restartInsuranceTable();
        }
    }

    refreshInsuranceAccordions = () => {
        this.setState({
            modalShowInsurance: false,
            insuranceCompany: "",
            emergencyPhone: "",
            insuranceStartDate: null,
            insuranceEndDate: null,
            lastButtonInsuranceModal: "Save",
            insuranceWorkflowId: null

        });
    };

    addNotification = async (pId) => {

        const wfId = this.state.insuranceWorkflowId;

        const response = await handleRequest("GET", "/WorkFlow/" + wfId);
        if (Boolean(response.data)) {
            //console.log(response.data);
            const steps = response.data.steps;

            if (Boolean(steps)) {
                var period = response.data.periods.filter(p => p.id == pId);
                if (Boolean(period)) {
                    const first = Object.assign({}, period[0]);
                    var flagDate = moment(first.startDate);
                    var flagDate2 = moment(first.startDate);
                    var startStepIndex = steps.findIndex(p => p.startStep === true);

                    var index = 0;
                    var index2 = -1;

                    if (startStepIndex > -1) {
                        index = startStepIndex;
                        index2 = startStepIndex - 1;
                    }


                    while (index < steps.length) {
                        // steps.forEach(element => {
                        var element = steps[index];
                        console.log(element);
                        element.index = index + 1;
                        if (index === 0 || index === startStepIndex) {

                            let eDate = flagDate.toDate();
                            element.startDate = eDate;
                        }
                        else {
                            element.startDate = steps[index - 1].endDate;
                        }

                        // flagDate = flagDate.add(1, 'years');
                        // let eDate = flagDate.toDate(); 
                        // element.endDate = eDate;

                        switch (element.durationType) {
                            case 'year':
                                if (Boolean(element.startDate)) {
                                    flagDate = flagDate.add(element.duration, 'years');
                                    let eDate = flagDate.toDate();
                                    element.endDate = eDate;
                                }
                                break;

                            case 'month':

                                if (Boolean(element.startDate)) {
                                    flagDate = flagDate.add(element.duration, 'months');
                                    let eDate = flagDate.toDate();
                                    element.endDate = eDate;
                                }

                                break;
                            case 'week':
                                if (Boolean(element.startDate)) {
                                    flagDate = flagDate.add(element.duration, 'weeks');
                                    let eDate = flagDate.toDate();
                                    element.endDate = eDate;
                                }
                                break;
                            case 'day':
                                if (Boolean(element.startDate)) {
                                    flagDate = moment(element.startDate).add(element.duration, 'days');
                                    let eDate = flagDate.toDate();
                                    element.endDate = eDate;
                                }
                                break;
                            case 'hour':
                                if (Boolean(element.startDate)) {
                                    flagDate = moment(element.startDate).add(element.duration, 'hours');
                                    let eDate = flagDate.toDate();
                                    element.endDate = eDate;
                                }
                                break;
                            case 'minute':
                                if (Boolean(element.startDate)) {
                                    flagDate = flagDate.add(element.duration, 'minutes');
                                    let eDate = flagDate.toDate();
                                    element.endDate = eDate;
                                }
                                break;

                            default:
                                element.endDate = element.startDate;
                                break;
                        }


                        index++;
                    }


                    while (index2 >= 0) {
                        // steps.forEach(element => {
                        var element = steps[index2];
                        console.log(element);
                        element.index = index2 + 1;

                        element.endDate = steps[index2 + 1].startDate;

                        // flagDate = flagDate.add(1, 'years');
                        // let eDate = flagDate.toDate(); 
                        // element.endDate = eDate;

                        switch (element.durationType) {
                            case 'year':
                                if (Boolean(element.endDate)) {
                                    flagDate2 = flagDate2.subtract(element.duration, 'years');
                                    let eDate = flagDate2.toDate();
                                    element.startDate = eDate;
                                }
                                break;

                            case 'month':

                                if (Boolean(element.endDate)) {
                                    flagDate2 = flagDate2.subtract(element.duration, 'months');
                                    let eDate = flagDate2.toDate();
                                    element.startDate = eDate;
                                }

                                break;
                            case 'week':
                                if (Boolean(element.endDate)) {
                                    flagDate2 = flagDate2.subtract(element.duration, 'weeks');
                                    let eDate = flagDate2.toDate();
                                    element.startDate = eDate;
                                }
                                break;
                            case 'day':
                                if (Boolean(element.endDate)) {
                                    flagDate2 = moment(element.endDate).subtract(element.duration, 'days');
                                    let eDate = flagDate2.toDate();
                                    element.startDate = eDate;
                                }
                                break;
                            case 'hour':
                                if (Boolean(element.endDate)) {
                                    flagDate2 = moment(element.endDate).subtract(element.duration, 'hours');
                                    let eDate = flagDate2.toDate();
                                    element.startDate = eDate;
                                }
                                break;
                            case 'minute':
                                if (Boolean(element.endDate)) {
                                    flagDate2 = flagDate2.subtract(element.duration, 'minutes');
                                    let eDate = flagDate2.toDate();
                                    element.startDate = eDate;
                                }
                                break;

                            default:
                                element.startDate = element.endDate;
                                break;
                        }


                        index2--;
                    }



                }

            }

            var notificationList = [];
            steps.forEach(element => {

                element.messages.forEach(msg => {
                    let obj = {
                        toUserId: msg.userId,
                        pageUrl: "WFStart/" + wfId + "/" + pId + "/" + element.id,
                        status: "Waiting",
                        message: "There is a notification for you is about workflow. Workflow : " + response.data.workFlowName + "  Step : " + element.actiontype,
                        activationTime: element.startDate


                    }

                    notificationList.push(obj);
                });

            });



            await handleRequest("POST", "/Notification/addAll", notificationList);

        }


    }

    saveOrEditVehicleLicence = async () => {
        if (this.state.lastButtonVehicleLicenceModal === "Save") {

            const newEquipmentVehicleLicenceLog = {
                equipmentLogId: this.state.equipmentLogId,
                plateNo: this.state.plateNo,
                licence: this.state.licence,
                licenceExpirityDate: this.state.licenceExpirityDate,
                licencePhotoPath: "Test Photo"
            }

            await handleRequest("POST", "/EquipmentVehicleLicence/add", newEquipmentVehicleLicenceLog);

            this.refreshVehicleLicenceAccordions();
            this.restartVehicleLicenceTable();
        }
        else if (this.state.lastButtonVehicleLicenceModal === "Update") {

            const updatedEquipmentVehicleLicenceLog = {
                id: this.state.editId,
                equipmentLogId: this.state.equipmentLogId,
                plateNo: this.state.plateNo,
                licence: this.state.licence,
                licenceExpirityDate: this.state.licenceExpirityDate,
                licencePhotoPath: "Test Photo"
            }

            await handleRequest("PUT", "/EquipmentVehicleLicence/update", updatedEquipmentVehicleLicenceLog);

            this.refreshVehicleLicenceAccordions();
            this.restartVehicleLicenceTable();
        }
    }

    refreshVehicleLicenceAccordions = () => {
        this.setState({
            modalShowVehicleLicence: false,
            plateNo: "",
            licence: "",
            licenceExpirityDate: null,
            lastButtonVehicleLicenceModal: "Save"
        });
    };

    saveOrEditCalibration = async () => {
        if (this.state.lastButtonCalibrationModal === "Save") {

            const newEquipmentCalibrationLog = {
                equipmentLogId: this.state.equipmentLogId,
                lastCalibrationDate: this.state.lastCalibrationDate,
                periodForCalibration: this.state.periodForCalibrationText,
                period: this.state.periodForCalibration,
                calibrationBody: this.state.calibrationBody,
                certificatePath: "Test Certificate"
            }

            await handleRequest("POST", "/EquipmentCalibration/add", newEquipmentCalibrationLog);

            this.refreshCalibrationAccordions();
            this.restartCalibrationTable();
        }
        else if (this.state.lastButtonCalibrationModal === "Update") {

            const updatedEquipmentCalibrationLog = {
                id: this.state.editId,
                equipmentLogId: this.state.equipmentLogId,
                lastCalibrationDate: this.state.lastCalibrationDate,
                periodForCalibration: this.state.periodForCalibrationText,
                period: this.state.periodForCalibration,
                calibrationBody: this.state.calibrationBody,
                certificatePath: "Test Certificate"
            }

            await handleRequest("POST", "/EquipmentCalibration/update", updatedEquipmentCalibrationLog);

            this.refreshCalibrationAccordions();
            this.restartCalibrationTable();
        }
    }

    refreshCalibrationAccordions = () => {
        this.setState({
            modalShowCalibration: false,
            periodForCalibrationText: "",
            periodForCalibration: null,
            lastCalibrationDate: null,
            lastButtonCalibrationModal: "Save"
        });
    }

    saveOrEditOperating = async () => {
        if (this.state.lastButtonOperatingModal === "Save") {

            const newEquipmentOperatingLog = {
                equipmentLogId: this.state.equipmentLogId,
                purposeOfUsage: this.state.purpose,
                activity: this.state.activity,
                departmentId: this.state.departmentId,
                disciplineId: this.state.disciplineId,
                energy: this.state.energyType,
                consumption: this.state.consumption,
                consumptionCode: this.state.consumptionType,
                consumptionPerCode: this.state.consumptionTypeFrequency,
                operator: this.state.operatorUser,
                capacity: this.state.capacity,
                capacityType: this.state.capacityTypeId,
                fuelTankCapacity: this.state.fuelTankCapacity,
                fuelTankCapacityType: this.state.fuelTankCapacityTypeId
            }

            await handleRequest("POST", "/EquipmentOperating/add", newEquipmentOperatingLog);

            this.refreshOperatingAccordions();
            this.restartOperatingTable();
        }
        else if (this.state.lastButtonOperatingModal === "Update") {

            const updatedEquipmentOperatingLog = {
                id: this.state.editId,
                equipmentLogId: this.state.equipmentLogId,
                purposeOfUsage: this.state.purpose,
                activity: this.state.activity,
                departmentId: this.state.departmentId,
                disciplineId: this.state.disciplineId,
                energy: this.state.energyType,
                consumption: this.state.consumption,
                consumptionCode: this.state.consumptionType,
                consumptionPerCode: this.state.consumptionTypeFrequency,
                operator: this.state.operatorUser,
                capacity: this.state.capacity,
                capacityType: this.state.capacityTypeId,
                fuelTankCapacity: this.state.fuelTankCapacity,
                fuelTankCapacityType: this.state.fuelTankCapacityTypeId
            }

            await handleRequest("POST", "/EquipmentOperating/update", updatedEquipmentOperatingLog);

            this.refreshOperatingAccordions();
            this.restartOperatingTable();
        }
    }

    refreshOperatingAccordions = () => {
        this.setState({
            modalShowOperating: false,
            purpose: null,
            activity: null,
            department: "Select Department",
            discipline: "Select Discipline",
            energyType: null,
            consumption: "",
            consumptionType: null,
            consumptionTypeFrequency: null,
            operatorUser: null,
            capacity: "",
            capacityType: "Select Capacity Type",
            fuelTankCapacity: "",
            fuelTankCapacityType: "Select Fuel Tank Capacity Type",
            lastButtonOperatingModal: "Save"
        });
    }

    //#region Reload Datatables

    restartManufactureTable = async () => {
        const response = await handleRequest("GET", "/EquipmentManufacturing/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentManufacturingLogs: [],
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentManufacturingLogs: options,
            });
        }
    }

    restartOperatingTable = async () => {
        const response = await handleRequest("GET", "/EquipmentOperating/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentOperatingLogs: [],
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentOperatingLogs: options,
            });
        }
    }

    restartCalibrationTable = async () => {
        const response = await handleRequest("GET", "/EquipmentCalibration/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentCalibrationLogs: [],
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentCalibrationLogs: options,
            });
        }
    }

    restartVehicleLicenceTable = async () => {
        const response = await handleRequest("GET", "/EquipmentVehicleLicence/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentVehicleLicenceLogs: [],
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentVehicleLicenceLogs: options,
            });
        }
    }

    restartInsuranceTable = async () => {
        const response = await handleRequest("GET", "/EquipmentInsurance/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentInsuranceLogs: [],
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            this.setState({
                equipmentInsuranceLogs: options,
            });
        }
    }

    //#endregion

    onPeriodForCalibrationChange(e) {
        this.setState({ periodForCalibration: e.value });
    }

    onCalibrationBodyChange(e) {
        this.setState({ calibrationBody: e.value });
    }

    onPurposeChange(e) {
        this.setState({ purpose: e.value });
    }

    onActivityChange(e) {
        this.setState({ activity: e.value });
    }

    onEnergyTypeChange(e) {
        this.setState({ energyType: e.value });
    }

    onConsumptionTypeChange(e) {
        this.setState({ consumptionType: e.value });
    }

    onConsumptionTypeFrequencyChange(e) {
        this.setState({ consumptionTypeFrequency: e.value });
    }

    onOperatorUserChange(e) {
        this.setState({ operatorUser: e.value });
    }

    onChangeAverageSpeed = (value) => {
        this.setState({
            averageSpeed: value
        });
    }

    onChangeCapitalCost = (value) => {
        this.setState({
            capitalCost: value
        });
    }

    onChangeRedemptionRate = (value) => {
        this.setState({
            redemptionRate: value
        });
    }

    onChangeDiesel = (value) => {
        this.setState({
            diesel: value
        });
    }

    onChangeMaintenance = (value) => {
        this.setState({
            maintenance: value
        });
    }

    onChangeRepairs = (value) => {
        this.setState({
            repairs: value
        });
    }

    onChangeInsuranceG = (value) => {
        this.setState({
            insuranceG: value
        });
    }

    onChangeInsuranceP = (value) => {
        this.setState({
            insuranceP: value
        });
    }

    onChangeTaxes = (value) => {
        this.setState({
            taxes: value
        });
    }

    onChangeFees = (value) => {
        this.setState({
            fees: value
        });
    }

    onChangeSalary = (value) => {
        this.setState({
            salary: value
        });
    }

    onChangeFood = (value) => {
        this.setState({
            food: value
        });
    }

    onChangeAccom = (value) => {
        this.setState({
            accom: value
        });
    }

    onChangeFeesDriver = (value) => {
        this.setState({
            feesDriver: value
        });
    }

    onChangeInsurance = (value) => {
        this.setState({
            insurance: value
        });
    }

    saveTruckCost = async () => {
        if (this.state.equipmentTruckCostId === null || this.state.equipmentTruckCostId === 0) {

            const newEquipmentTruckCost = {
                equipmentLogId: this.state.equipmentLogId,
                capitalCost: this.state.capitalCost,
                dateOfPurchasing: this.state.date,
                redemptionRate: this.state.redemptionRate,
                averageSpeed: this.state.averageSpeed,
                diesel: this.state.diesel,
                maintenance: this.state.maintenance,
                repairs: this.state.repairs,
                insuranceG: this.state.insuranceG,
                insuranceP: this.state.insuranceP,
                taxes: this.state.taxes,
                fees: this.state.fees,
                salary: this.state.salary,
                food: this.state.food,
                accom: this.state.accom,
                feesDriver: this.state.feesDriver,
                insurance: this.state.insurance
            }

            await handleRequest("POST", "/EquipmentTruckCost/add", newEquipmentTruckCost);

            message.success(`The operation is successful.`);
        }
        else if (this.state.equipmentTruckCostId !== null || this.state.equipmentTruckCostId !== 0) {

            const updatedEquipmentTruckCost = {
                id: this.state.equipmentTruckCostId,
                equipmentLogId: this.state.equipmentLogId,
                capitalCost: this.state.capitalCost,
                dateOfPurchasing: this.state.date,
                redemptionRate: this.state.redemptionRate,
                averageSpeed: this.state.averageSpeed,
                diesel: this.state.diesel,
                maintenance: this.state.maintenance,
                repairs: this.state.repairs,
                insuranceG: this.state.insuranceG,
                insuranceP: this.state.insuranceP,
                taxes: this.state.taxes,
                fees: this.state.fees,
                salary: this.state.salary,
                food: this.state.food,
                accom: this.state.accom,
                feesDriver: this.state.feesDriver,
                insurance: this.state.insurance
            }

            await handleRequest("POST", "/EquipmentTruckCost/update", updatedEquipmentTruckCost);

            message.success(`The operation is successful.`);
        }
    }

    onChangeDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date: dateString
            });
        } else {
            this.setState({
                date: today
            });
        }
    } 

    downloadFile = async (url) => {
        window.location.href = url;
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    addPartList = async () => { 
        if (this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!="" && this.state.unitGroup > 0 && this.state.partName!=null && this.state.partName!=undefined && this.state.partName!="" &&
            this.state.partCode!=null && this.state.partCode!=undefined && this.state.partCode!="") {
            var newPartList = {
                equipmentLogId:this.state.equipmentLogId,
                unitGroup: this.state.unitGroup,
                partName: this.state.partName,
                partCode: this.state.partCode,
                photoFilePath: this.state.photoFilePath,
            };
            console.log("III",newPartList);
            var response = await handleRequest("POST", "/EquipmentPartList/add", newPartList);
            if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                hiddenDataGirisPartlist: true,
                createNewPartListButton:"Create New",
                createNewPartListVariant: "info",
                hiddenPartListAdd: false,
                hiddenPartListUpdate: true,
                });
                this.showSuccess();
                this.getEquipmentPartList();
                this.resetInputsPartList();
            }
            else {
                this.showError();
            }
            } else {
            this.showError();
            }
        } else {
            this.showWarning();
        }
    };

    updateDonePartList = async () => {
        if (this.state.equipmentPartListId>0 &&
            this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!="" && 
            this.state.unitGroup > 0 && this.state.partName!=null && this.state.partName!=undefined && this.state.partName!="" &&
            this.state.partCode!=null && this.state.partCode!=undefined && this.state.partCode!="") {
            
            var updatePartList = {
                id:this.state.equipmentPartListId,
                equipmentLogId:this.state.equipmentLogId,
                unitGroup: this.state.unitGroup,
                partName: this.state.partName,
                partCode: this.state.partCode,
                photoFilePath: this.state.photoFilePath,
            };
            console.log("UUU",updatePartList);
            var response = await handleRequest("POST", "/EquipmentPartList/update", updatePartList);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        hiddenDataGirisPartlist: true,
                        createNewPartListButton:"Create New",
                        createNewPartListVariant: "info",
                        hiddenPartListAdd: false,
                        hiddenPartListUpdate: true,
                    });
                    this.showSuccess();
                    this.getEquipmentPartList();
                    this.resetInputsPartList();
                }
                else {
                    this.showError();
                }
            } else {
            this.showError();
            }
        } else {
            this.showWarning();
        }
    };

    changeButtonCancelPartList = async () => {
        this.setState({
            hiddenDataGirisPartlist: true,
            createNewPartListButton:"Create New",
            createNewPartListVariant: "info",
            hiddenPartListAdd: false,
            hiddenPartListUpdate: true, 
        }); 
        this.resetInputsPartList();
    };  
    
    resetInputsPartList = async () => {
        this.setState({
            equipmentPartListId:0,
            unitGroup: 0,
            unitGroupAck: "",
            partName: "",
            partCode: "",
            photoFilePath: "",
            PhotoFileUpload:[],
            fileList:[],
        });  
    };

    addEquipmentMaintenanceSchedule = async () => { 
        if (this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!="") {
            var newEquipmentMaintenance = {
                equipmentLogId:this.state.equipmentLogId,
            };

            var response = await handleRequest("POST", "/EquipmentMaintenance/add", newEquipmentMaintenance);
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS")
               { 
                   this.showSuccess();
               } 
               else  {
                 this.showError(); 
               }  
            }else {
              this.showError();
           } 
        } else {
            this.showWarning();
        }
    };

    deleteEquipmentMaintenanceSchedule = async () => { 
        if (this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!="") {
            var newEquipmentMaintenance = {
                equipmentLogId:this.state.equipmentLogId,
            };

            var response = await handleRequest("POST", "/EquipmentMaintenance/deleteEquipmentMaintenanceSchedule", newEquipmentMaintenance);
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS")
               { 
                   this.showSuccess();
               } 
               else  {
                 this.showError(); 
               }  
            }else {
              this.showError();
           } 
        } else {
            this.showWarning();
        }
    };

    render() {
        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
          };

        let optionsDepartments = this.state.departments.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDisciplines = this.state.disciplines.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsCapacityTypes = this.state.capacityTypes.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsFuelTankCapacityTypes = this.state.fuelTankCapacityTypes.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        ) 

      const renderDownloadFileRender = (photoFilePath) => {
            if (photoFilePath !== null && photoFilePath !== "" && photoFilePath !== undefined) {
                return <React.Fragment>
                <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(photoFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
                </AntdButton>
                </React.Fragment>
            }
            return "";
        }

        const columnsEquipmentPartList = [  
            {
                key: "key",
                title: "",
                render: (record) => (this.actionTemplate(record))
            }, 
            {
                dataIndex: "index",
                key: "key",
                title: <FormattedMessage id="GeneralTableSN" defaultMessage="S/N" />,
            }, 
             {
                dataIndex: "equipmentLog",
                key: "key",
                title: <FormattedMessage id="EQLEquipmentName" defaultMessage="Equipment Name"/>,
                render: (data) =>   
                   {  
                    if(data!=null && data!=undefined){
                       return data.equipmentDescription;
                    } 
                    return "";
                }
            },
            {
                dataIndex: "codeUnitGroup",
                key: "key",
                title: <FormattedMessage id="EQLUnitGroup" defaultMessage="Unit Group"/>,
                render: (data) =>   
                   {  
                    if(data!=null && data!=undefined){
                       return data.name;
                    } 
                    return "";
                }
            }, 
            {
                dataIndex: "partName",
                key: "key",
                title: <FormattedMessage id="EQLPartName" defaultMessage="Part Name"/>,
            },  
            {
                dataIndex: "partCode",
                key: "key",
                title: <FormattedMessage id="EQLPartCode" defaultMessage="Part Code"/>,
            },
            {
                dataIndex: "photoFilePath",
                key: "photoFilePath",
                title: <FormattedMessage id="ModalPhotoText" defaultMessage="Photo" />,
                render: (record) => (renderDownloadFileRender(record))
              },
        ]; 

        return (
            <div style={{ padding: "15px" }}>
                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                            <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                            
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                            </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row > 
                        <Col sm="12">
                            {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowDelete}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this data?
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>Close</Button>
                        <Button variant="danger" onClick={this.deleteRecord}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowManufacturing}
                    onHide={this.showOrHideModalManufacturing}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Manufacturing Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Manufacturer:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ manufacturer: e.target.value })} value={this.state.manufacturer} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Manufacturing year:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ manufacturingYear: e.target.value })} value={this.state.manufacturingYear} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Brand:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ brand: e.target.value })} value={this.state.brand} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Model:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ model: e.target.value })} value={this.state.model} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Serial No:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ serialNo: e.target.value })} value={this.state.serialNo} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Chasis No:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ chasisNo: e.target.value })} value={this.state.chasisNo} />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalManufacturing}>Close</Button>
                        <Button variant="info" onClick={this.saveOrEditManufacturingInformation}>{this.state.lastButtonManufacturingModal}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowOperating}
                    onHide={this.showOrHideModalOperating}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Operating</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Purpose of Usage:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.purpose} options={this.state.purposes} onChange={this.onPurposeChange}
                                        editable={true} placeholder="Select Purpose of Usage" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Activity:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.activity} options={this.state.activities} onChange={this.onActivityChange}
                                        editable={true} placeholder="Select Activity" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Department:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="department" value={this.state.department}
                                        onChange={this.handleChangeDepartment} as="select">
                                        <option value="">
                                            Select Department
                                </option>
                                        {optionsDepartments}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Discipline:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="discipline" value={this.state.discipline}
                                        onChange={this.handleChangeDiscipline} as="select">
                                        <option value="">
                                            Select Discipline
                                </option>
                                        {optionsDisciplines}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Energy/FuelType:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.energyType} options={this.state.energyTypes} onChange={this.onEnergyTypeChange}
                                        editable={true} placeholder="Select Energy/FuelType" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Fuel Tank Capacity:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ fuelTankCapacity: e.target.value })} value={this.state.fuelTankCapacity} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="fuelTankCapacityType" value={this.state.fuelTankCapacityType}
                                        onChange={this.handleChangeCapacityType} as="select">
                                        <option value="">
                                            Select Fuel Tank Capacity Type
                                        </option>
                                        {optionsFuelTankCapacityTypes}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Consumption:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ consumption: e.target.value })} value={this.state.consumption} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Consumption Type & Frequency:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.consumptionType} options={this.state.consumptionTypes}
                                        onChange={this.onConsumptionTypeChange} editable={true} placeholder="Select Consumption" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.consumptionTypeFrequency}
                                        options={this.state.consumptionTypeFrequencies} onChange={this.onConsumptionTypeFrequencyChange}
                                        editable={true} placeholder="Select Consumption Frequency" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Operator/User:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.operatorUser} options={this.state.operatorUsers} onChange={this.onOperatorUserChange}
                                        editable={true} placeholder="Select Operator/User" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Loading Capacity:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ capacity: e.target.value })} value={this.state.capacity} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="capacityType" value={this.state.capacityType}
                                        onChange={this.handleChangeCapacityType} as="select">
                                        <option value="">
                                            Select Capacity Type
                                        </option>
                                        {optionsCapacityTypes}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalOperating}>Close</Button>
                        <Button variant="info" onClick={this.saveOrEditOperating}>{this.state.lastButtonOperatingModal}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowCalibration}
                    onHide={this.showOrHideModalCalibration}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Calibration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Last Calibration Date:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.lastCalibrationDate} onChange={(e) => this.setState({ lastCalibrationDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="2000:2050" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Period for Calibration:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ periodForCalibrationText: e.target.value })} value={this.state.periodForCalibrationText} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.periodForCalibration} options={this.state.periodForCalibrations} onChange={this.onPeriodForCalibrationChange}
                                        editable={true} placeholder="Select Period" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Calibration body:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.calibrationBody} options={this.state.calibrationBodies} onChange={this.onCalibrationBodyChange}
                                        editable={true} placeholder="Select Calibration Body" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <FileUpload style={{ width: "90%" }} mode="basic" chooseLabel="Attach Certificate" name="demo[]" maxFileSize={1000000} onUpload={this.onBasicUpload} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalCalibration}>Close</Button>
                        <Button variant="info" onClick={this.saveOrEditCalibration}>{this.state.lastButtonCalibrationModal}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowVehicleLicence}
                    onHide={this.showOrHideModalVehicleLicence}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Vehicle Licence</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Plate No:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ plateNo: e.target.value })} value={this.state.plateNo} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <FileUpload style={{ width: "90%" }} mode="basic" chooseLabel="Attach Licence Photo" name="demo[]" maxFileSize={1000000} onUpload={this.onBasicUpload} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Licence/Estimara:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ licence: e.target.value })} value={this.state.licence} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Expirity Date:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.licenceExpirityDate} onChange={(e) => this.setState({ licenceExpirityDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="2000:2050" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalVehicleLicence}>Close</Button>
                        <Button variant="info" onClick={this.saveOrEditVehicleLicence}>{this.state.lastButtonVehicleLicenceModal}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowInsurance}
                    onHide={this.showOrHideModalInsurance}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Insurance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Insurance Company:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ insuranceCompany: e.target.value })} value={this.state.insuranceCompany} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Insurance Start Date:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.insuranceStartDate} onChange={(e) => this.setState({ insuranceStartDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="2020:2050" />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Insurance End Date:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <Calendar dateFormat="dd/mm/yy" value={this.state.insuranceEndDate} onChange={(e) => this.setState({ insuranceEndDate: e.value })} showTime={true} showSeconds={true} monthNavigator={true} yearNavigator={true} yearRange="2020:2050" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Emergency Phone:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ emergencyPhone: e.target.value })} value={this.state.emergencyPhone} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                    <FileUpload style={{ width: "90%" }} mode="basic" chooseLabel="Attach Insurance Certificate" name="demo[]" maxFileSize={1000000} onUpload={this.onBasicUpload} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                </Form.Group>

                            </Form.Row>
                            <Form.Row>

                                {/* <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>WorkFlow:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                <Form.Control name="type"
                                        as="select" onChange={(e) => {
                                            e.persist();
                                            let val = e.target.value;
                                            this.setState({
                                                insuranceWorkflowId : val 
                                            }) 
                                        }} value={this.state.insuranceWorkflowId} 
                                    > 
                                        <option value="Select">Select Option</option>
                                        {this.state.workflowList.map((opt, j) => (
                                            <option value={opt.id}>{opt.workFlowName}</option>  
    
                                        ))} 
                                    </Form.Control>
                                </Form.Group> */}
                                <Form.Group as={Col} controlId="xx">

                                    <Checkbox checked={this.state.insuranceSchedule} onChange={(e) => {

                                        this.setState({ insuranceSchedule: e.target.checked })
                                    }} > Add to schedule </Checkbox>
                                </Form.Group>
                                <Form.Group as={Col} controlId="yy">
                                </Form.Group>
                                <Form.Group as={Col} controlId="zz">
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridZip">
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                </Form.Group>



                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalInsurance}>Close</Button>
                        <Button variant="info" onClick={this.saveOrEditInsurance}>{this.state.lastButtonInsuranceModal}</Button>
                    </Modal.Footer>
                </Modal>

                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Manufacturing Information</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion" eventKey="0" >
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <PrimeButton onClick={this.showOrHideModalManufacturing} type="button" style={{ width: "60%" }} id='create-partlist' label="Add New" />
                                    </Form.Group>
                                </Form.Row>

                                <DataTable
                                    noHeader
                                    columns={columnsEquipmentManufacturing(this.editEquipmentManufacturing, this.deleteEquipmentManufacturing)}
                                    data={this.state.equipmentManufacturingLogs}
                                    sortIcon={sortIcon}
                                    pagination
                                    striped
                                />

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Operating</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion1" eventKey="0" >
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <PrimeButton onClick={this.showOrHideModalOperating} type="button" style={{ width: "60%" }} id='create-partlist' label="Add New" />
                                    </Form.Group>
                                </Form.Row>

                                <DataTable
                                    noHeader
                                    columns={columnsEquipmentOperating(this.editEquipmentOperating, this.deleteEquipmentOperating)}
                                    data={this.state.equipmentOperatingLogs}
                                    sortIcon={sortIcon}
                                    pagination
                                />

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Calibration</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion2" eventKey="0" >
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <PrimeButton onClick={this.showOrHideModalCalibration} type="button" style={{ width: "60%" }} id='create-partlist' label="Add New" />
                                    </Form.Group>
                                </Form.Row>

                                <DataTable
                                    noHeader
                                    columns={columnsEquipmentCalibration(this.editEquipmentCalibration, this.deleteEquipmentCalibration)}
                                    data={this.state.equipmentCalibrationLogs}
                                    sortIcon={sortIcon}
                                    pagination
                                />

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Vehicle Licence</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion3" eventKey="0" >
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <PrimeButton onClick={this.showOrHideModalVehicleLicence} type="button" style={{ width: "60%" }} id='create-partlist' label="Add New" />
                                    </Form.Group>
                                </Form.Row>

                                <DataTable
                                    noHeader
                                    columns={columnsEquipmentVehicleLicence(this.editEquipmentVehicleLicence, this.deleteEquipmentVehicleLicence)}
                                    data={this.state.equipmentVehicleLicenceLogs}
                                    sortIcon={sortIcon}
                                    pagination
                                />

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Insurance</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion4" eventKey="0" >
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridCity">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                        <PrimeButton onClick={this.showOrHideModalInsurance} type="button" style={{ width: "60%" }} id='create-partlist' label="Add New" />
                                    </Form.Group>
                                </Form.Row>

                                <DataTable
                                    noHeader
                                    columns={columnsEquipmentInsurance(this.editEquipmentInsurance, this.deleteEquipmentInsurance)}
                                    data={this.state.equipmentInsuranceLogs}
                                    sortIcon={sortIcon}
                                    pagination
                                />

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Maintenance</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion5" eventKey="0" >
                            <Card.Body>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                <Col sm="12" hidden={!this.state.hiddenMaintenanceSchedule}>  
                                    <p style={{ color: 'red', fontWeight: 'bold' }}><FormattedMessage id="EQLMaintenanceScheduleMessage1" defaultMessage="Same type of equipment are in maintenance schedule. This equipment is also strictly recommended to be added to schedule." /></p>
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                <Col sm="12">  
                                 <Checkbox checked={this.state.maintenanceSchedule} onChange={(e) => {
                                    if(e.target.checked){
                                        this.addEquipmentMaintenanceSchedule();
                                    }else{
                                        this.deleteEquipmentMaintenanceSchedule();
                                    }
                                    this.setState({
                                        maintenanceSchedule:e.target.checked,
                                    });
                                }} style={{ color: 'black', marginTop: '0.25rem' }}>{<FormattedMessage id="EQLAddToMaintenanceSchedule" defaultMessage="Add to Maintenance Schedule" />}</Checkbox>

                                </Col>
                            </Form.Group>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Part List</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion6" eventKey="0" >
                            <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                            <Row>
                            <Col xs={9}></Col>
                            <Col xs={3}>
                                <Button onClick={this.createNewPartList} style={{ width: '100%' }} variant={this.state.createNewPartListVariant} >
                                    {this.state.createNewPartListButton}
                                </Button> 
                            </Col>
                            </Row>
                            <br />
                            <br />

                        <div hidden={this.state.hiddenDataGirisPartlist}>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    <FormattedMessage id="EQLUnitGroup" defaultMessage="Unit Group"/> 
                                </Form.Label>
                                <Col sm="4">
                                    <Select
                                        name="unitGroup"
                                        style={{ width: '100%' }}
                                        value={this.state.unitGroupAck}
                                        onChange={this.handleChangeEQLUnitGroup}

                                        dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Input style={{ flex: 'auto' }} value={this.state.itemEQLUnitGroups}
                                                onChange={(event) => { this.setState({ itemEQLUnitGroups: event.target.value }) }} />
                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                onClick={this.addEQLUnitGroups} >
                                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item" />  </a>
                                            </div>
                                        </div>
                                        )}>
                                        {this.state.EQLUnitGroups.map(i => (
                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                        ))}
                                </Select>
                                </Col>
                               </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    <FormattedMessage id="EQLPartName" defaultMessage="Part Name"/> 
                                </Form.Label>
                                <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.partName}  
                                    onChange={(e) =>
                                    this.setState({
                                        partName: e.target.value,
                                }) } />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    <FormattedMessage id="EQLPartCode" defaultMessage="Part Code" /> 
                                </Form.Label>
                                <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.partCode}  
                                    onChange={(e) =>
                                    this.setState({
                                        partCode: e.target.value,
                                }) } />
                                </Col>
                                <Col sm="4">
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.PhotoFileUpload}
                                    onChange={({ fileList }) => {
                                        if (fileList.length == 1) {
                                        var photoFilePath = "";
                                        if (Boolean(fileList[0].response)) {
                                            photoFilePath = fileList[0].response.url;
                                        }
                                        this.setState({
                                            PhotoFileUpload: fileList,
                                            photoFilePath: photoFilePath,
                                        });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.PhotoFileUpload.length > 0) {
                                        message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                                        return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                        PhotoFileUpload: [],
                                        photoFilePath: "",
                                        })
                                    }
                                    }>
                                    <AntdButton>
                                        <UploadOutlined /> <FormattedMessage id="ModalPhotoText" defaultMessage="Photo"/>
                                        </AntdButton>
                                    </Upload>
                                </Col>

                            </Form.Group>
                            
                            <div hidden={this.state.hiddenPartListAdd}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="4">
                                        <Button onClick={this.addPartList} style={{ width: '100%' }} variant="success">
                                            <FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />
                                        </Button>
                                    </Col>
                                </Form.Group> 
                            </div>


                            <div hidden={this.state.hiddenPartListUpdate}>
                                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Row>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                    <Button onClick={this.changeButtonCancelPartList} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></Button>
                                    </Col>
                                    <Col sm="2">
                                    <Button onClick={this.updateDonePartList} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></Button>
                                    </Col>
                                </Row>
                                </Container>
                            </div>
                        
                        </div>


                      
                        <Table columns={columnsEquipmentPartList} dataSource={this.state.equipmentPartListLogs} 
                            responsive striped hover bordered scroll={{ x: 100, scrollToFirstRowOnChange: true }} />
                               

                                {/* <DataTable
                                    noHeader
                                    columns={columnsEquipmentPartList(this.editEquipmentPartList, this.deleteEquipmentPartList)}
                                    data={this.state.equipmentPartListLogs}
                                    sortIcon={sortIcon}
                                    pagination
                                /> */}
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                            <Row>
                                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Truck Cost</Card.Header></Col>
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse id="overallAccordion6" eventKey="0" >
                            <Card.Body>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Capital Cost:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.capitalCost} onChange={this.onChangeCapitalCost} /></AntCol>
                                    <AntCol span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SAR</div></AntCol>
                                    <AntCol span={8}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Date of Purchasing:</div></AntCol>
                                    <AntCol span={3}><DatePicker style={{ width: '100%' }} value={moment(this.state.date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeDate} /></AntCol>
                                    <AntCol span={10}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Redemption Rate:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.redemptionRate} onChange={this.onChangeRedemptionRate} /></AntCol>
                                    <AntCol span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>Year</div></AntCol>
                                    <AntCol span={2}></AntCol>
                                    <AntCol span={6}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Average Speed:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.averageSpeed} onChange={this.onChangeAverageSpeed} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>km/hour</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Diesel:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.diesel} onChange={this.onChangeDiesel} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>liter per 100 Km</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Maintenance:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.maintenance} onChange={this.onChangeMaintenance} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Repairs:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.repairs} onChange={this.onChangeRepairs} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Insurance - Government:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.insuranceG} onChange={this.onChangeInsuranceG} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Insurance - Private:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.insuranceP} onChange={this.onChangeInsuranceP} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Taxes:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.taxes} onChange={this.onChangeTaxes} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Fees:</div></AntCol>
                                    <AntCol span={3}><InputNumber style={{ width: '100%' }} min={0} value={this.state.fees} onChange={this.onChangeFees} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Driver:</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                    <AntCol span={6}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={10}></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Salary:</div></AntCol>
                                    <AntCol span={2}><InputNumber style={{ width: '100%' }} min={0} value={this.state.salary} onChange={this.onChangeSalary} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Month</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={10}></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Food:</div></AntCol>
                                    <AntCol span={2}><InputNumber style={{ width: '100%' }} min={0} value={this.state.food} onChange={this.onChangeFood} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Month</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={10}></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Accom.:</div></AntCol>
                                    <AntCol span={2}><InputNumber style={{ width: '100%' }} min={0} value={this.state.accom} onChange={this.onChangeAccom} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Month</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={10}></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Fees:</div></AntCol>
                                    <AntCol span={2}><InputNumber style={{ width: '100%' }} min={0} value={this.state.feesDriver} onChange={this.onChangeFeesDriver} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={10}></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Insurance:</div></AntCol>
                                    <AntCol span={2}><InputNumber style={{ width: '100%' }} min={0} value={this.state.insurance} onChange={this.onChangeInsurance} /></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></AntCol>
                                    <AntCol span={7}></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '10px' }}>
                                    <AntCol span={5}></AntCol>
                                    <AntCol span={13}><Button style={{ width: '100%' }} variant="primary" onClick={this.saveTruckCost}>Save</Button></AntCol>
                                    <AntCol span={5}></AntCol>
                                </AntRow>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLogSub)