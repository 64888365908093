import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Accordion, Button as ReactButton, Container, Col, Form, Row, Modal } from 'react-bootstrap';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Select, InputNumber, Space, Row as RowAnt, Checkbox, Table, Avatar, Button as AntButton, DatePicker } from 'antd';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import { API_BASE, handleRequest } from '../../redux/config/env';
import PurchaseOrderSub from './PurchaseOrderSub';
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { FacebookShareButton, WhatsappShareButton, EmailShareButton, FacebookIcon, WhatsappIcon, EmailIcon } from "react-share";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import moment from 'moment';

const { Column, ColumnGroup } = Table;
const { Option } = Select;

var _paymentRows = [
    {
        paymentIndex: 1,
        title: "1. payment",
        description: "",
        paymentValue: "",
        paymentValueType: "Select"
    }
];

var _termCondRows = [];
var _noteRows = [];
var _requiredDocumentRows = [];

export class PurchaseOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            priceDiscountModal: false,
            selectedPriceDiscountRow: null,
            selectedPriceDiscountRowIndex: null,
            vendorList: [],
            vendorEvaluations: [],
            vendorEvaluation: "",
            hidden: true,
            itemsContacts: [],
            address: "",
            phone: [],
            fax: [],
            email: [],
            social: [],
            validity: "",
            respDepartment: "",
            certificate: "",
            loading: false,
            imageUrl: null,
            products: [],
            productDetail: [],
            locationList: [],
            name: "",
            midname: "",
            surname: "",
            position: "",
            rPhone: "",
            rEmail: "",
            country: "",
            serviceList: [],
            code: "",
            unit: '',
            selectedRow: null,
            vendorFullName: "",
            vendorShortName: "",
            modalDelete: false,
            modalEdit: false,
            discountCheck: false,
            quoteValidityCheck: false,
            quoteValidityValue: '',
            quoteValidityTimeType: "Select",
            paymentCheck: false,
            VATTaxCheck: false,
            VATTaxValue: '',
            paymentRows: [
                {
                    paymentIndex: 1,
                    title: "1. payment",
                    description: "",
                    paymentValue: "",
                    paymentValueType: "Select"
                }
            ],
            termCondRows: [],
            termCondName: "",
            noteRows: [],
            noteName: "",
            totalDiscountInput: "",
            web: "",
            modalError: false,
            modalErrorNewQuote: false,
            price: "",
            unit: "",
            quantity: 1,
            currency: "",
            discount: "",
            deliveryDate: null,
            modalDeleteProduct: false,
            productId: null,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: [],
            subTotalCheck: false,
            totalCheck: false,
            subDiscount: 0,
            subTotalDiscount: [],
            total: [],
            lastPurchaseOrders: [],
            quoteNo: "",
            modalPdfViewer: false,
            pdfFile: "",
            pagination: {
                current: 1,
                pageSize: 5,
            },
            material: "",
            materials: [],
            date: null,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            requiredDocumentRows: [],
            invoiceCheck: false,
            deliveryNoteCheck: false,
            packingListCheck: false,
            testReportCheck: false,
            manufacturerManualCheck: false
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    formRef = React.createRef();

    onChangeDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date: dateString
            });
        } else {
            this.setState({
                date: today
            });
        }
    }

    componentDidMount = async () => {

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "PurchaseOrder" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "PurchaseOrder",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "PurchaseOrder");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: today
        });

        //this.fillMaterials();
        this.restartTable();
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters });
    }

    fetch = async (params = {}) => {
        this.setState({
            loadingTable: true
        });

        const newObj = {
            id: 0,
            firstResult: params.pagination.current,
            maxResults: params.pagination.pageSize,
        }

        const response = await handleRequest("POST", "/VendorLog/getVendorLogPaging", newObj);

        if (response.data.length !== 0) {
            this.setState({
                loadingTable: false,
                vendorList: response.data.data,
                pagination: {
                    ...params.pagination,
                    total: response.data.totalRecords,
                },
            });
        } else {
            this.setState({
                loadingTable: false,
                vendorList: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
            });
        }
    }

    restartTable = async () => {
        const responseVendorLog = await handleRequest("GET", "/VendorLog/getAllByCompany");

        if (responseVendorLog.data.length !== 0) {
            var rData = responseVendorLog.data;

            rData.forEach(element => {
                element.key = element.id;
            });

            this.setState({
                vendorList: rData
            });

            console.log(rData);
        } else {
            this.setState({
                vendorList: []
            });
        }

        const response = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");
        let purchaseOrderVendorEvaluations = [];

        if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {
                purchaseOrderVendorEvaluations.push(response.data[index].rfqId);
            }
        }

        const responseEvaluations = await handleRequest("GET", "/VendorEvaluationSelected/getAllByCompany");

        console.log(responseEvaluations);

        if (responseEvaluations.data.length === 0) {
            this.setState({
                vendorEvaluations: []
            });
        } else {
            let flagVendorEva = [];
            for (let index = 0; index < responseEvaluations.data.length; index++) {
                if (responseEvaluations.data[index].selected !== undefined)
                    if (responseEvaluations.data[index].selected && !(purchaseOrderVendorEvaluations.includes(responseEvaluations.data[index].rfqId)))
                        flagVendorEva.push(responseEvaluations.data[index]);
            }

            console.log(flagVendorEva);

            this.setState({
                vendorEvaluations: flagVendorEva
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showReport = (a) => {
        try {
            const fileRealUrl = `${API_BASE}/PurchaseOrder/download1/` + a;

            console.log("fileUrl: " + fileRealUrl);

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            console.log(error);
        }
    }

    fillMaterials = async () => {
        const responseMaterialLogs = await handleRequest("GET", "/MaterialIdentification/getAll");

        var columns = responseMaterialLogs.data;
        var options = [];
        for (let index = 0; index < columns.length; index++) {
            options.push({
                text: columns[index].materialName,
                value: columns[index].id,
                materialName: columns[index].materialName,
                materialCode: columns[index].materialCode
            });
        }

        if (responseMaterialLogs.data.length === 0) {
            this.setState({
                materials: [],
            });
        }

        if (responseMaterialLogs.data.length > 0) {
            this.setState({
                materials: options,
            });
        }
    }

    resetInputs = () => {
        _requiredDocumentRows = [];

        this.setState({
            hidden: true,
            vendorFullName: "",
            vendorShortName: "",
            serviceList: [],
            material: "",
            quoteValidityCheck: false,
            quoteValidityValue: '',
            quoteValidityTimeType: "Select",
            paymentCheck: false,
            VATTaxCheck: false,
            VATTaxValue: '',
            paymentRows: [
                {
                    paymentIndex: 1,
                    title: "1. payment",
                    description: "",
                    paymentValue: "",
                    paymentValueType: "Select"
                }
            ],
            termCondRows: [],
            termCondName: "",
            noteRows: [],
            noteName: "",
            totalDiscountInput: "",
            modalError: false,
            price: "",
            unit: "",
            quantity: 1,
            currency: "",
            discount: "",
            deliverySchedule: "",
            modalDeleteProduct: false,
            productId: null,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: [],
            subTotalCheck: false,
            subDiscount: 0,
            subTotalDiscount: [],
            total: [],
            quoteNo: "",
            vendorEvaluation: "",
            rfqItemList: [],
            requiredDocumentRows: [],
            invoiceCheck: false,
            deliveryNoteCheck: false,
            packingListCheck: false,
            testReportCheck: false,
            manufacturerManualCheck: false
        });
    }

    createNew = async () => {
        if (this.state.selectedRow !== null && this.state.selectedRow.length !== 0) {
            this.resetInputs();

            const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
            const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
            var _representative;
            var _contact;
            var _phones = [];
            var _faxes = [];
            var _mails = [];

            if (this.state.selectedRow[0].contact !== null && this.state.selectedRow[0].contact !== undefined) {
                _contact = this.state.selectedRow[0].contact;
                if (_contact.phones !== null && _contact.phones !== undefined) {
                    _phones = _contact.phones.replace(",", ", ");
                }
                if (_contact.faxes !== null && _contact.faxes !== undefined)
                    _faxes = _contact.faxes.replace(",", ", ");
                if (_contact.mails !== null && _contact.mails !== undefined)
                    _mails = _contact.mails.replace(",", ", ");
            }
            else _contact = _contactDefault;

            if (this.state.selectedRow[0].representative !== null && this.state.selectedRow[0].representative !== undefined)
                _representative = this.state.selectedRow[0].representative;
            else _representative = _representativeDefault;

            var _imageUrl = "";

            if (this.state.selectedRow[0].logoPath !== null && this.state.selectedRow[0].logoPath !== undefined && this.state.selectedRow[0].logoPath.length > 0) {
                _imageUrl = `${API_BASE}/VendorLog/download/` + this.state.selectedRow[0].id
            }

            this.setState({
                vendorFullName: this.state.selectedRow[0].fullName,
                vendorShortName: this.state.selectedRow[0].shortName,
                paymentRows: _paymentRows,
                imageUrl: _imageUrl,
                address: _contact.address,
                country: _contact.country,
                phone: _phones,
                fax: _faxes,
                email: _mails,
                web: _contact.web,
                name: _representative.name,
                midname: _representative.midname,
                surname: _representative.lastname,
                position: _representative.position,
                rPhone: _representative.phone,
                rEmail: _representative.email,
            });

            const response = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");

            if (response.data.length === 0) {
                this.setState({
                    lastPurchaseOrders: []
                });
            }

            if (response.data.length !== 0) {
                let flagData = response.data;

                console.log(flagData[flagData.length - 1]);

                let qvCheck = false;
                if (flagData[flagData.length - 1].quoteValidityCheck === 1) {
                    qvCheck = true;
                }

                let vCheck = false;
                if (flagData[flagData.length - 1].vatCheck === 1) {
                    vCheck = true;
                }

                _termCondRows = flagData[flagData.length - 1].purchaseOrderNewTermList;
                _noteRows = flagData[flagData.length - 1].purchaseOrderNoteList;

                this.setState({
                    lastPurchaseOrders: flagData[flagData.length - 1],
                    quoteValidityCheck: qvCheck,
                    quoteValidityValue: flagData[flagData.length - 1].quoteValidityValue,
                    quoteValidityTimeType: flagData[flagData.length - 1].quoteValidityDateType,
                    VATTaxCheck: vCheck,
                    VATTaxValue: flagData[flagData.length - 1].vatValue,
                    termCondRows: flagData[flagData.length - 1].purchaseOrderNewTermList,
                    noteRows: flagData[flagData.length - 1].purchaseOrderNoteList
                });
            }

            if (this.state.hidden) {
                this.setState({
                    hidden: false
                });
            } else {
                this.setState({
                    hidden: true
                });
            }
        } else {
            this.createNew2();

            // normalde vendor seçimi yapılmadığında vendor seçmesi isteniyordu.
            // this.setState({
            //     modalErrorNewQuote: true  
            // });
        }
    }

    createNew2 = async () => {
        this.resetInputs();

        var _representative = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
        var _contact = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
        var _phones = [];
        var _faxes = [];
        var _mails = [];
        var _imageUrl = "";

        this.setState({
            vendorFullName: "",
            vendorShortName: "",
            paymentRows: _paymentRows,
            imageUrl: _imageUrl,
            address: _contact.address,
            country: _contact.country,
            phone: _phones,
            fax: _faxes,
            email: _mails,
            web: _contact.web,
            name: _representative.name,
            midname: _representative.midname,
            surname: _representative.lastname,
            position: _representative.position,
            rPhone: _representative.phone,
            rEmail: _representative.email,
        });

        if (this.state.hidden) {
            this.setState({
                hidden: false
            });
        } else {
            this.setState({
                hidden: true
            });
        }
    }

    handleChangeVendorEvaluation = (e) => {
        this.setState({
            vendorEvaluation: e
        });

        for (let index = 0; index < this.state.vendorEvaluations.length; index++) {
            if (this.state.vendorEvaluations[index].id === e) {

                _paymentRows = [
                    {
                        paymentIndex: 1,
                        title: "1. payment",
                        description: "",
                        paymentValue: "",
                        paymentValueType: "Select"
                    }
                ];

                const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
                const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
                var _representative;
                var _contact;
                var _phones = [];
                var _faxes = [];
                var _mails = [];
                var _rfqItemList = [];

                if (this.state.vendorEvaluations[index].vendorLog.contact !== null && this.state.vendorEvaluations[index].vendorLog.contact !== undefined) {
                    _contact = this.state.vendorEvaluations[index].vendorLog.contact;
                    if (_contact.phones !== null && _contact.phones !== undefined) {
                        _phones = _contact.phones.replace(",", ", ");
                    }
                    if (_contact.faxes !== null && _contact.faxes !== undefined)
                        _faxes = _contact.faxes.replace(",", ", ");
                    if (_contact.mails !== null && _contact.mails !== undefined)
                        _mails = _contact.mails.replace(",", ", ");
                }
                else _contact = _contactDefault;

                if (this.state.vendorEvaluations[index].vendorLog.representative !== null && this.state.vendorEvaluations[index].vendorLog.representative !== undefined)
                    _representative = this.state.vendorEvaluations[index].vendorLog.representative;
                else _representative = _representativeDefault;

                var _imageUrl = "";

                if (this.state.vendorEvaluations[index].vendorLog.logoPath !== null && this.state.vendorEvaluations[index].vendorLog.logoPath !== undefined && this.state.vendorEvaluations[index].vendorLog.logoPath.length > 0) {
                    _imageUrl = `${API_BASE}/VendorLog/download/` + this.state.vendorEvaluations[index].vendorLog.id
                }

                if (this.state.vendorEvaluations[index].requestForQuotation !== undefined)
                    if (this.state.vendorEvaluations[index].requestForQuotation !== null) {
                        if (this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs.length > 0) {
                            for (let index1 = 0; index1 < this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs.length; index1++) {
                                if (this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1].quantity.includes(",")) {
                                    var flagQuantityLength = this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",").length;
                                    var flagQuantity = this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",");
                                    var flagDeliveryDate = this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1].deliveryDate.split(",");
                                    for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                        _rfqItemList.push({
                                            materialName: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                            materialCode: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                            unit: "",
                                            price: "",
                                            priceCurrency: "",
                                            discount: "",
                                            projectTakeOffList: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0],
                                            projectTakeOffListSub: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1],
                                            quantity: flagQuantity[index2],
                                            deliveryDate: flagDeliveryDate[index2]
                                        });
                                    }
                                } else {
                                    _rfqItemList.push({
                                        materialName: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                        materialCode: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                        unit: "",
                                        price: "",
                                        priceCurrency: "",
                                        discount: "",
                                        projectTakeOffList: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0],
                                        projectTakeOffListSub: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1],
                                        quantity: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1].quantity,
                                        deliveryDate: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffListSubs[index1].deliveryDate
                                    });
                                }
                            }
                        } else if (this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists.length > 0) {
                            if (this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0].quantity.includes(",")) {
                                var flagQuantityLength = this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0].quantity.split(",").length;
                                var flagQuantity = this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0].quantity.split(",");
                                var flagDeliveryDate = this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0].deliveryDate.split(",");
                                for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                    _rfqItemList.push({
                                        materialName: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.description,
                                        materialCode: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.code,
                                        unit: "",
                                        price: "",
                                        priceCurrency: "",
                                        discount: "",
                                        projectTakeOffList: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList,
                                        projectTakeOffListSub: null,
                                        quantity: flagQuantity[index2],
                                        deliveryDate: flagDeliveryDate[index2]
                                    });
                                }
                            } else {
                                _rfqItemList.push({
                                    materialName: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.description,
                                    materialCode: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList.code,
                                    unit: "",
                                    price: "",
                                    priceCurrency: "",
                                    discount: "",
                                    projectTakeOffList: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffList,
                                    projectTakeOffListSub: null,
                                    quantity: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0].quantity,
                                    deliveryDate: this.state.vendorEvaluations[index].requestForQuotation.projectTakeOffLists[0].deliveryDate
                                });
                            }
                        } else
                            _rfqItemList = [];
                    }
                    else
                        _rfqItemList = [];


                this.setState({
                    vendorFullName: this.state.vendorEvaluations[index].vendorLog.fullName,
                    vendorShortName: this.state.vendorEvaluations[index].vendorLog.shortName,
                    paymentRows: _paymentRows,
                    imageUrl: _imageUrl,
                    address: _contact.address,
                    country: _contact.country,
                    phone: _phones,
                    fax: _faxes,
                    email: _mails,
                    web: _contact.web,
                    name: _representative.name,
                    midname: _representative.midname,
                    surname: _representative.lastname,
                    position: _representative.position,
                    rPhone: _representative.phone,
                    rEmail: _representative.email,
                    rfqItemList: _rfqItemList
                });
            }
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    numberOfProjects2(record) {
        var rowData = record;
        var result = 0;
        if (rowData.projects !== null && rowData.projects !== undefined) {
            result = rowData.projects.length;
        }
        return result;
    }

    phones(value) {
        var rowData = value;
        var result = [];
        if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].phones !== null && rowData[0].phones !== undefined) {
            rowData[0].phones.forEach(e => {
                result.push(e.phone);
            });
        }
        return result.toString();
    }

    faxes(value) {
        var rowData = value;
        var result = [];

        if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].faxes !== null && rowData[0].faxes !== undefined) {
            rowData[0].faxes.forEach(e => {
                result.push(e.fax);
            });
        }
        return result.toString();
    }

    mails(value) {
        var rowData = value;
        var result = [];
        if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].mails !== null && rowData[0].mails !== undefined) {
            rowData[0].mails.forEach(e => {
                result.push(e.mail);
            });
        }
        return result.toString();
    }

    // createNewVendor = () => {
    //     window.location.href = '/VendorLog/0';
    // }

    addProduct = () => {
        var list = this.state.serviceList;

        if (this.state.material !== "" && this.state.unit !== ""
            && this.state.price !== "" && this.state.currency !== "") {
            var _key = list.length + 1;
            let discountValue = 0;

            if (this.state.discount === null || this.state.discount === "") {
                discountValue = 0
            } else {
                discountValue = this.state.discount
            }

            const newItem = {
                key: _key,
                material: this.state.material,
                quantity: this.state.quantity,
                unit: this.state.unit,
                price: this.state.price,
                currency: this.state.currency,
                discount: discountValue,
                date: this.state.date,
            }

            list.push(newItem);

            let diffCurrencies = [];

            for (let index = 0; index < list.length; index++) {
                if (!diffCurrencies.includes(list[index].currency)) {
                    diffCurrencies.push(list[index].currency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < list.length; index1++) {
                    if (diffCurrencies[index] === list[index1].currency) {
                        flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (this.state.subDiscount != "") {
                    let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            this.setState({
                serviceList: list.slice(),
                material: "",
                quantity: "1",
                unit: "",
                price: "",
                currency: "",
                discount: "",
                date: today,
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState
            });
        } else {
            this.setState({
                modalError: true
            });
        }
    }

    updateProduct = () => {
        let list = this.state.serviceList;
        let flagProductId = this.state.productId;

        let objIndex = list.findIndex((obj => obj.key === flagProductId));

        let discountValue = 0;

        if (this.state.discount === null || this.state.discount === "") {
            discountValue = 0
        } else {
            discountValue = this.state.discount
        }

        list[objIndex].material = this.state.material;
        list[objIndex].quantity = this.state.quantity;
        list[objIndex].unit = this.state.unit;
        list[objIndex].price = this.state.price;
        list[objIndex].currency = this.state.currency;
        list[objIndex].discount = discountValue;
        list[objIndex].date = this.state.date;

        let diffCurrencies = [];

        for (let index = 0; index < list.length; index++) {
            if (!diffCurrencies.includes(list[index].currency)) {
                diffCurrencies.push(list[index].currency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < list.length; index1++) {
                if (diffCurrencies[index] === list[index1].currency) {
                    flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            serviceList: list.slice(),
            productId: null,
            material: "",
            quantity: "1",
            unit: "",
            price: "",
            currency: "",
            discount: "",
            date: today,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });
    }

    cancelProduct = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            productId: null,
            material: "",
            quantity: "1",
            unit: "",
            price: "",
            currency: "",
            discount: "",
            date: today,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block"
        });
    }

    deleteProductModal = (row) => {
        this.setState({
            productId: row.key,
            modalDeleteProduct: true
        });
    }

    deleteProductModalOK = () => {
        let list = this.state.serviceList;
        let flagProductId = this.state.productId;

        list.splice(list.findIndex(function (i) {
            return i.key === flagProductId;
        }), 1);

        let diffCurrencies = [];

        for (let index = 0; index < list.length; index++) {
            if (!diffCurrencies.includes(list[index].currency)) {
                diffCurrencies.push(list[index].currency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < list.length; index1++) {
                if (diffCurrencies[index] === list[index1].currency) {
                    flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            serviceList: list.slice(),
            productId: null,
            modalDeleteProduct: false,
            material: "",
            quantity: "1",
            unit: "",
            price: "",
            currency: "",
            discount: "",
            date: today,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    editService = (row) => {
        this.setState({
            productId: row.key,
            material: row.material,
            quantity: row.quantity,
            unit: row.unit,
            price: row.price,
            currency: row.currency,
            discount: row.discount,
            date: row.date,
            productUpdateButtonHidden: "block",
            productAddButtonHidden: "none"
        });
    }

    onChangeSubTotalCheck = (e) => {
        this.setState({
            subTotalCheck: e.target.checked
        });
    }

    onChangeValidityCB = (e) => {
        this.setState({
            quoteValidityCheck: e.target.checked
        });
    }

    onChangeDiscountCheck = (e) => {
        this.setState({
            discountCheck: e.target.checked
        });
    }

    onChangeTotalCheck = (e) => {
        this.setState({
            totalCheck: e.target.checked
        });
    }

    onChangePaymentCB = (e) => {
        this.setState({
            paymentCheck: e.target.checked
        });
    }

    onChangeVATTaxCB = (e) => {
        this.setState({
            VATTaxCheck: e.target.checked
        });
    }

    applyDiscount = () => {
        let list = this.state.serviceList;
        let diffCurrencies = [];

        for (let index = 0; index < list.length; index++) {
            if (!diffCurrencies.includes(list[index].currency)) {
                diffCurrencies.push(list[index].currency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < list.length; index1++) {
                if (diffCurrencies[index] === list[index1].currency) {
                    flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        this.setState({
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });
    }

    addNewTermCond = () => {
        _termCondRows.push(
            {
                title: this.state.termCondName,
                description: "",
                checked: false
            }
        );

        this.setState({
            termCondRows: _termCondRows,
            termCondName: ""
        });
    }

    handleChangeTermCond = (index, e) => {
        e.preventDefault();
        let list = this.state.termCondRows;
        list[index].description = e.target.value;

        this.setState({
            termCondRows: list.slice()
        });
    }

    onChangeTermCond = (index, e) => {
        let list = this.state.termCondRows;
        list[index].checked = e.target.checked;

        this.setState({
            termCondRows: list.slice()
        });
    }

    addNewNote = () => {
        _noteRows.push(
            {
                title: this.state.noteName,
                description: "",
                checked: false
            }
        );

        this.setState({
            noteRows: _noteRows,
            noteName: ""
        });
    }

    handleChangeNote = (index, e) => {
        e.preventDefault();
        let list = this.state.noteRows;
        list[index].description = e.target.value;

        this.setState({
            noteRows: list.slice()
        });
    }

    onChangeNote = (index, e) => {
        let list = this.state.noteRows;
        list[index].checked = e.target.checked;

        this.setState({
            noteRows: list.slice()
        });
    }

    showOrHideModalDeleteProduct = () => {
        this.setState({
            modalDeleteProduct: !this.state.modalDeleteProduct
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalError = () => {
        this.setState({
            modalError: !this.state.modalError
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalErrorNewQuote = () => {
        this.setState({
            modalErrorNewQuote: !this.state.modalErrorNewQuote
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    addNewPaymentLine = () => {
        _paymentRows.push(
            {
                paymentIndex: _paymentRows.length + 1,
                title: (_paymentRows.length + 1).toString() + ". payment",
                description: "",
                paymentValue: "",
                paymentValueType: "Select"
            }
        );

        this.setState({
            paymentRows: _paymentRows
        });
    }

    removeLastPaymentLine = () => {

        if (_paymentRows.length !== 1) {
            _paymentRows.pop();
        }

        this.setState({
            paymentRows: _paymentRows
        });
    }

    handleChangePaymentDesc = (index, e) => {
        e.preventDefault();
        let list = this.state.paymentRows;
        list[index].description = e.target.value;

        this.setState({
            paymentRows: list.slice()
        });
    }

    handleChangePaymentValue = (index, value) => {
        let list = this.state.paymentRows;
        list[index].paymentValue = value;

        this.setState({
            paymentRows: list.slice()
        });
    }

    handleChangePaymentValueType = (index, e) => {
        e.preventDefault();
        let list = this.state.paymentRows;
        list[index].paymentValueType = e.target.value;

        this.setState({
            paymentRows: list.slice()
        });
    }

    saveQuote = async () => {
        if (this.state.vendorEvaluation !== "" && this.state.vendorEvaluation !== null) {
            let discountValue = 0;

            if (this.state.subDiscount === null || this.state.subDiscount === "") {
                discountValue = 0;
            } else {
                discountValue = this.state.subDiscount;
            }

            let list = this.state.rfqItemList;
            let flagMaterialList = [];
            let flagMaterialListSub = [];

            let flagIndex = 0;

            for (let index = 0; index < this.state.vendorEvaluations.length; index++) {
                if (this.state.vendorEvaluations[index].id === this.state.vendorEvaluation)
                    flagIndex = index;
            }

            let flagVendorEvaluation = this.state.vendorEvaluations[flagIndex];

            for (let index = 0; index < list.length; index++) {
                if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs !== undefined && flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs !== null
                    && flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length !== 0) {
                    if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0)
                        flagMaterialListSub.push({
                            materialName: list[index].materialName,
                            materialCode: list[index].materialCode,
                            quantity: list[index].quantity,
                            price: list[index].price,
                            currency: list[index].priceCurrency,
                            discount: list[index].discount,
                            rfqProjectTakeOffListSubId: list[index].projectTakeOffListSub.id,
                            deliveryDate: list[index].deliveryDate
                        });
                }
                else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists !== undefined && flagVendorEvaluation.requestForQuotation.projectTakeOffLists !== null
                    && flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length !== 0) {
                    if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0)
                        flagMaterialList.push({
                            materialName: list[index].materialName,
                            materialCode: list[index].materialCode,
                            quantity: list[index].quantity,
                            price: list[index].price,
                            currency: list[index].priceCurrency,
                            discount: list[index].discount,
                            rfqProjectTakeOffListId: list[index].projectTakeOffList.id,
                            deliveryDate: list[index].deliveryDate
                        });
                }
            }

            let qvCheck = 0;

            if (this.state.quoteValidityCheck === true) { qvCheck = 1; }

            let pCheck = 0;

            if (this.state.paymentCheck === true) { pCheck = 1; }

            let vCheck = 0;

            if (this.state.VATTaxCheck === true) { vCheck = 1; }

            let stCheck = 0;

            if (this.state.subTotalCheck === true) { stCheck = 1; }

            let dCheck = 0;

            if (this.state.discountCheck === true) { dCheck = 1; }

            let tCheck = 0;

            if (this.state.totalCheck === true) { tCheck = 1; }

            let paymentList = this.state.paymentRows;

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            let _flagRequiredDocuments = [];

            for (let index = 0; index < this.state.requiredDocumentRows.length; index++) {
                if (this.state.requiredDocumentRows[index].checked === true)
                    _flagRequiredDocuments.push(this.state.requiredDocumentRows[index]);
            }

            const newPurchaseOrder = {
                vendorLogId: this.state.vendorEvaluations[flagIndex].vendorLog.id,
                rfqId: this.state.vendorEvaluations[flagIndex].requestForQuotation.id,
                vendorEvaluationSelectedId: this.state.vendorEvaluations[flagIndex].id,
                discount: discountValue,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                totalCheck: tCheck,
                quoteDate: today,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: this.state.quoteValidityValue,
                quoteValidityDateType: this.state.quoteValidityTimeType,
                paymentCheck: pCheck,
                purchaseOrderPaymentList: paymentList,
                vatCheck: vCheck,
                vatValue: this.state.VATTaxValue,
                purchaseOrderNewTermList: this.state.termCondRows,
                purchaseOrderNoteList: this.state.noteRows,
                quoteNo: this.state.quoteNo,
                revNo: 0,
                quotePdfPath: "",
                responseDate: "",
                needsForContract: false,
                status: "Waiting",
                purchaseOrderItemList: flagMaterialList,
                purchaseOrderItemListSub: flagMaterialListSub,
                invoice: this.state.invoiceCheck,
                deliveryNote: this.state.deliveryNoteCheck,
                packingList: this.state.packingListCheck,
                testReport: this.state.testReportCheck,
                manufacturerManual: this.state.manufacturerManualCheck,
                purchaseOrderRequiredDocuments: _flagRequiredDocuments
            }

            var a = await handleRequest("POST", "/PurchaseOrder/add", newPurchaseOrder);

            this.resetInputs();
            this.restartTable();
            this.showReport(a.data);
        } else
            this.showWarning();
    }

    saveQuote2 = async () => {
        // let discountValue = 0;

        // if (this.state.subDiscount === null || this.state.subDiscount === "") {
        //     discountValue = 0;
        // } else {
        //     discountValue = this.state.subDiscount;
        // }

        // let list = this.state.serviceList;
        // let flagProductList = [];

        // for (let index = 0; index < list.length; index++) {
        //     flagProductList.push(
        //         {
        //             key1: list[index].key,
        //             materialId: list[index].material,
        //             quantity: list[index].quantity,
        //             unit: list[index].unit,
        //             price: list[index].price,
        //             currency: list[index].currency,
        //             discount: list[index].discount,
        //             deliveryDate: list[index].date
        //         }
        //     );
        // }

        // let qvCheck = 0;

        // if (this.state.quoteValidityCheck === true) { qvCheck = 1; }

        // let pCheck = 0;

        // if (this.state.paymentCheck === true) { pCheck = 1; }

        // let vCheck = 0;

        // if (this.state.VATTaxCheck === true) { vCheck = 1; }

        // let stCheck = 0;

        // if (this.state.subTotalCheck === true) { stCheck = 1; }

        // let dCheck = 0;

        // if (this.state.discountCheck === true) { dCheck = 1; }

        // let tCheck = 0;

        // if (this.state.totalCheck === true) { tCheck = 1; }

        // let paymentList = this.state.paymentRows;

        // var today = new Date();
        // var dd = String(today.getDate()).padStart(2, '0');
        // var mm = String(today.getMonth() + 1).padStart(2, '0');
        // var yyyy = today.getFullYear();

        // today = dd + '/' + mm + '/' + yyyy;

        // const newPurchaseOrder = {
        //     vendorLogId: this.state.selectedRow[0].id,
        //     purchaseOrderMaterialList: flagProductList,
        //     discount: discountValue,
        //     subTotalCheck: stCheck,
        //     discountCheck: dCheck,
        //     totalCheck: tCheck,
        //     quoteDate: today,
        //     quoteValidityCheck: qvCheck,
        //     quoteValidityValue: this.state.quoteValidityValue,
        //     quoteValidityDateType: this.state.quoteValidityTimeType,
        //     paymentCheck: pCheck,
        //     purchaseOrderPaymentList: paymentList,
        //     vatCheck: vCheck,
        //     vatValue: this.state.VATTaxValue,
        //     purchaseOrderNewTermList: this.state.termCondRows,
        //     purchaseOrderNoteList: this.state.noteRows,
        //     quoteNo: this.state.quoteNo,
        //     revNo: 0,
        //     quotePdfPath: "",
        //     responseDate: "",
        //     needsForContract: false,
        //     status: "Waiting"
        // }

        // var a = await handleRequest("POST", "/PurchaseOrder/add", newPurchaseOrder);

        // this.resetInputs();
        // this.showReport(a.data);
    }

    stopProcess = () => {
        this.setState({
            hidden: true
        });

        this.resetInputs();
    }

    disabledDate = (current) => {
        return current < moment().endOf('day');
    }

    selectMaterial = (value) => {
        var list = this.state.materials;
        var filteredList = list.filter(p => p.value === value);

        if (filteredList.length > 0) {
            this.setState({
                material: value
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    showModalPriceDiscount = (row, index) => {
        this.setState({
            selectedPriceDiscountRow: row,
            selectedPriceDiscountRowIndex: index,
            setPriceModal: row.price,
            setPriceCurrencyModal: row.priceCurrency,
            setDiscountModal: row.discount,
            priceDiscountModal: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalPriceDiscount = () => {
        this.setState({
            priceDiscountModal: !this.state.priceDiscountModal,
            selectedPriceDiscountRow: null,
            setPriceModal: "",
            setPriceCurrencyModal: "",
            setDiscountModal: ""
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    setPriceDiscount = () => {
        if (this.state.setPriceModal !== null && this.state.setPriceModal !== 0 && this.state.setPriceModal !== ""
            && this.state.setPriceCurrencyModal !== null && this.state.setPriceCurrencyModal !== "") {

            let list = this.state.rfqItemList;
            let flagIndex = this.state.selectedPriceDiscountRowIndex;
            list[flagIndex].price = this.state.setPriceModal;
            list[flagIndex].priceCurrency = this.state.setPriceCurrencyModal;

            if (this.state.setDiscountModal === null || this.state.setDiscountModal === 0 || this.state.setDiscountModal === "")
                list[flagIndex].discount = 0;
            else
                list[flagIndex].discount = this.state.setDiscountModal;

            let filledList = [];

            for (let index = 0; index < list.length; index++) {
                if (list[index].price !== null && list[index].price !== 0) {
                    filledList.push(list[index]);
                }
            }

            let discountValue = 0;
            if (this.state.discount === null || this.state.discount === "") {
                discountValue = 0
            } else {
                discountValue = this.state.discount
            }

            let diffCurrencies = [];

            for (let index = 0; index < filledList.length; index++) {
                if ((!diffCurrencies.includes(filledList[index].priceCurrency)) && filledList[index].priceCurrency !== "" && filledList[index].priceCurrency !== null) {
                    diffCurrencies.push(filledList[index].priceCurrency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < filledList.length; index1++) {
                    if (diffCurrencies[index] === filledList[index1].priceCurrency) {
                        flagSubTotals[index] += ((filledList[index1].price * filledList[index1].quantity) - (((filledList[index1].price * filledList[index1].quantity) * filledList[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (this.state.subDiscount != "") {
                    let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            this.setState({
                rfqItemList: list.slice(),
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState,
                priceDiscountModal: false
            });
        } else {
            this.showWarning();
        }
    }

    applyDiscount2 = () => {
        let list = this.state.rfqItemList;
        let filledList = [];

        for (let index = 0; index < list.length; index++) {
            if (list[index].price !== null && list[index].price !== 0) {
                filledList.push(list[index]);
            }
        }

        let diffCurrencies = [];

        for (let index = 0; index < filledList.length; index++) {
            if ((!diffCurrencies.includes(filledList[index].priceCurrency)) && filledList[index].priceCurrency !== "" && filledList[index].priceCurrency !== null) {
                diffCurrencies.push(filledList[index].priceCurrency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < filledList.length; index1++) {
                if (diffCurrencies[index] === filledList[index1].priceCurrency) {
                    flagSubTotals[index] += ((filledList[index1].price * filledList[index1].quantity) - (((filledList[index1].price * filledList[index1].quantity) * filledList[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        this.setState({
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });
    }

    addNewRequiredDocument = () => {
        if (this.state.requiredDocumentRows.length === 0) {
            _requiredDocumentRows.push(
                {
                    requiredDocumentIndex: _requiredDocumentRows.length + 1,
                    description: "",
                    checked: false
                }
            );

            this.setState({
                requiredDocumentRows: _requiredDocumentRows
            });
        }

        if (this.state.requiredDocumentRows.length > 0)
            if (this.state.requiredDocumentRows[this.state.requiredDocumentRows.length - 1].description !== ""
                && this.state.requiredDocumentRows[this.state.requiredDocumentRows.length - 1].description !== null) {
                _requiredDocumentRows.push(
                    {
                        requiredDocumentIndex: _requiredDocumentRows.length + 1,
                        description: "",
                        checked: false
                    }
                );

                this.setState({
                    requiredDocumentRows: _requiredDocumentRows
                });
            }
    }

    removeRequiredDocument = (index, e) => {
        _requiredDocumentRows.splice(index, 1);

        this.setState({
            requiredDocumentRows: _requiredDocumentRows
        });
    }

    handleChangeRequiredDocument = (index, e) => {
        e.preventDefault();
        let list = this.state.requiredDocumentRows;
        list[index].description = e.target.value;

        this.setState({
            requiredDocumentRows: list.slice()
        });
    }

    onChangeRequiredDocument = (index, e) => {
        let list = this.state.requiredDocumentRows;
        list[index].checked = e.target.checked;

        this.setState({
            requiredDocumentRows: list.slice()
        });
    }

    render() {

        let optionsMaterials = this.state.materials.map((data) =>
            <Select.Option
                key={data.value}
                value={data.value}
            >
                {data.materialName}
            </Select.Option>
        );

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                let flagSelectedRow = [];
                let flagSelectedRowKey = [];

                if (selectedRows.length >= 1) {
                    flagSelectedRow.push(selectedRows[selectedRows.length - 1]);
                    flagSelectedRowKey.push(selectedRowKeys[selectedRowKeys.length - 1]);
                }

                this.setState({
                    selectedRow: flagSelectedRow,
                    selectedRowKeys: flagSelectedRowKey
                });
            },
        };

        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalErrorNewQuote}
                        onHide={this.showOrHideModalErrorNewQuote}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Select a Vendor!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Please, be sure to select a vendor.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalErrorNewQuote}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalDeleteProduct}
                        onHide={this.showOrHideModalDeleteProduct}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete Material Record?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalDeleteProduct}>Close</Button>
                            <Button variant="danger" onClick={this.deleteProductModalOK} >Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalError}
                        onHide={this.showOrHideModalError}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Missing Values!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Please, be sure to enter all values.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalError}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="xl"
                        show={this.state.modalPdfViewer}
                        onHide={this.showOrHideModalPdfViewer}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                PDF Report Viewer
               </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                                <FacebookShareButton url={this.state.pdfFile} quote="Purchase Order PDF File Link">
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Purchase Order PDF File Link is here: " subject="Purchase Order PDF File Link">
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                                <WhatsappShareButton
                                    style={{ marginLeft: "1rem" }}
                                    url={this.state.pdfFile}
                                    title="Purchase Order PDF File Link"
                                    separator=": "
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                                <div style={{ height: "900px" }}>
                                    <Viewer fileUrl={this.state.pdfFile} />
                                </div>
                            </Worker>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalPdfViewer}
                            >
                                Close
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.priceDiscountModal}
                        onHide={this.showOrHideModalPriceDiscount}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="RFQFixItem" defaultMessage="Set Price & Discount" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Price:
                                    </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ setPriceModal: e })}
                                        value={this.state.setPriceModal} />
                                </Col>
                                <Col xs={4}>
                                    <Select value={this.state.setPriceCurrencyModal} placeholder="Select a Currency" onChange={(value) => this.setState({ setPriceCurrencyModal: value })} >
                                        {/*<Option value="$">$</Option>
                                        <Option value="€">€</Option>*/}
                                        <Option value="SR">SR</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Discount:
                                </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ setDiscountModal: e })}
                                        value={this.state.setDiscountModal} />
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                            <ReactButton variant="light" onClick={this.showOrHideModalPriceDiscount}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="primary" onClick={this.setPriceDiscount}>
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {/*{this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <ReactButton id="PurchaseOrderCreateNewVendorButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNewVendor}><FormattedMessage id="PurchaseOrderCreateNewVendorButtonText" defaultMessage="Create New Vendor" /></ReactButton>
                                    }*/}
                                </Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="PurchaseOrderCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="PurchaseOrderStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        {(this.state.selectedRow === null || this.state.selectedRow.length === 0) && <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                            <Card border="light">
                                <Card.Body>
                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row style={{ marginBottom: '1rem' }}>
                                            <Col xs={2} style={{ textAlign: 'right' }}>
                                                <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Evaluation:</Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Select name="Evaluation"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Evaluation"
                                                    value={this.state.vendorEvaluation}
                                                    onChange={this.handleChangeVendorEvaluation}>
                                                    {this.state.vendorEvaluations.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.vendorLog.shortName + " / " + i.requestForQuotation.rfqNo + " / " + i.requestForQuotation.projectTakeOffList.description}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Purchase Order No:</Form.Label></Col>
                                            <Col xs={4}><Form.Control name="quoteNo" value={this.state.quoteNo} onChange={this.handleChange} /></Col>
                                        </Row>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Vendor Info</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Company's Full Name:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.vendorFullName}
                                                            </Form.Label>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Company's Short Name:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.vendorShortName}
                                                            </Form.Label>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Company Logo:
                                                            </Form.Label>
                                                            <Col sm="2">
                                                                {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div>No Image</div>}
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ fontWeight: 'bold', color: 'black' }} column sm="3">
                                                                Head Office
                                                            </Form.Label>
                                                            <Col sm="4">
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Address:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.address}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Country:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.country}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Phone:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.phone}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Fax:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.fax}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Web:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.web}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                E-Mail:
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.email}
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Vendor Representative</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Name
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.name}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Midname
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.midname}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Surname
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.surname}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Position
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.position}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Phone
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.rPhone}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Email
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.rEmail}
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Materials and Price</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.rfqItemList} bordered >
                                                            <Column title="Material Name" key="materialName" dataIndex="materialName" />
                                                            <Column title="Material Code" key="materialCode" dataIndex="materialCode" />
                                                            <Column title="Quantity" key="quantity" dataIndex="quantity" />
                                                            <Column title="Unit" key="unit" dataIndex="unit" />
                                                            <Column title="Price" key="price" render={(value, row, index) => {
                                                                if (row.price !== null && row.priceCurrency !== null)
                                                                    return row.price + " " + row.priceCurrency;
                                                                else
                                                                    return row.price;
                                                            }} />
                                                            <Column title="Discount (%)" key="discount" dataIndex="discount" />
                                                            <Column title="Delivery Date" key="deliveryDate" dataIndex="deliveryDate" />
                                                            <Column title="" width='100px' key="set" render={(value, row, index) => {
                                                                return <AntButton type="primary" onClick={() => this.showModalPriceDiscount(row, index)}>Set Price & Discount</AntButton>
                                                            }} />
                                                        </Table>

                                                        <Form>
                                                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                                <Col sm="5">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeSubTotalCheck}
                                                                        checked={this.state.subTotalCheck}>Sub Total</Checkbox>
                                                                </Col>
                                                                {this.state.subTotals.length > 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        {this.state.subTotals[0].total} {this.state.subTotals[0].currency}
                                                                    </Form.Label>
                                                                }
                                                                {this.state.subTotals.length === 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        N/A Total / Unit
                                                                </Form.Label>
                                                                }
                                                                <Col sm="3">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.subTotals.map((item, index) => (
                                                                (item.index !== 1) && (
                                                                    <Form.Group key={index} as={Row}>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                            {this.state.subTotals[item.index - 1].total} {this.state.subTotals[item.index - 1].currency}
                                                                        </Form.Label>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}

                                                            <Form.Group as={Row}>
                                                                <Col sm="2">
                                                                </Col>
                                                                <Col sm="1" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.subDiscount}
                                                                        formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                                        onChange={(value) => { this.setState({ subDiscount: value }) }} />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.applyDiscount2} type="button">Apply Discount</Button>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeDiscountCheck2}
                                                                        checked={this.state.discountCheck}>Discount</Checkbox>
                                                                </Col>
                                                                {this.state.subTotalDiscount.length > 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        {this.state.subTotalDiscount[0].total} {this.state.subTotalDiscount[0].currency}
                                                                    </Form.Label>
                                                                }
                                                                {this.state.subTotalDiscount.length === 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        N/A Total / Unit
                                                                </Form.Label>
                                                                }
                                                                <Col sm="3">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.subTotalDiscount.map((item, index) => (
                                                                (item.index !== 1) && (
                                                                    <Form.Group key={index} as={Row}>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                            {this.state.subTotalDiscount[item.index - 1].total} {this.state.subTotalDiscount[item.index - 1].currency}
                                                                        </Form.Label>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}

                                                            <Form.Group as={Row}>
                                                                <Col sm="5">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeTotalCheck}
                                                                        checked={this.state.totalCheck}>Total</Checkbox>
                                                                </Col>
                                                                {this.state.total.length > 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        {this.state.total[0].total} {this.state.total[0].currency}
                                                                    </Form.Label>
                                                                }
                                                                {this.state.total.length === 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        N/A Total / Unit
                                                                </Form.Label>
                                                                }
                                                                <Col sm="3">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.total.map((item, index) => (
                                                                (item.index !== 1) && (
                                                                    <Form.Group key={index} as={Row}>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                            {this.state.total[item.index - 1].total} {this.state.total[item.index - 1].currency}
                                                                        </Form.Label>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}
                                                        </Form>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Terms and Conditions</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form>
                                                            <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                                                                <Form.Label style={{ color: 'black' }} column sm="3">
                                                                    New Terms/Conditions Name:
                                                                </Form.Label>
                                                                <Col sm="4">
                                                                    <Form.Control name="termCondName" value={this.state.termCondName} onChange={this.handleChange} />
                                                                </Col>
                                                                <Col sm="3">
                                                                    <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewTermCond} type="button">Add</Button>
                                                                </Col>
                                                                <Col sm="2">
                                                                </Col>
                                                            </Form.Group>

                                                            {/*<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeValidityCB} checked={this.state.quoteValidityCheck}>Quote Validty</Checkbox>
                                                                </Col>
                                                                <Col sm="2" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.quoteValidityValue}
                                                                        onChange={(value) => { this.setState({ quoteValidityValue: value }) }} />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Form.Control name="quoteValidityTimeType" value={this.state.quoteValidityTimeType}
                                                                        onChange={this.handleChange} as="select">
                                                                        <option>Select</option>
                                                                        <option>Day</option>
                                                                        <option>Week</option>
                                                                        <option>Month</option>
                                                                        <option>Year</option>
                                                                    </Form.Control>
                                                                </Col>
                                                                </Form.Group>*/}

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangePaymentCB} checked={this.state.paymentCheck}>Payment Terms</Checkbox>
                                                                </Col>
                                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                    {this.state.paymentRows[0].title}
                                                                </Form.Label>
                                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                    Description:
                                                        </Form.Label>
                                                                <Col sm="2">
                                                                    <Form.Control name={this.state.paymentRows[0].title} value={this.state.paymentRows[0].description}
                                                                        onChange={(e) => this.handleChangePaymentDesc(0, e)} />
                                                                </Col>
                                                                <Col sm="1" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.paymentRows[0].paymentValue}
                                                                        onChange={(value) => this.handleChangePaymentValue(0, value)} />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Form.Control name={this.state.paymentRows[0].title} value={this.state.paymentRows[0].paymentValueType}
                                                                        onChange={(e) => this.handleChangePaymentValueType(0, e)} as="select">
                                                                        <option>Select</option>
                                                                        <option>Currency</option>
                                                                        <option>%</option>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.paymentRows.map((item, index) => (
                                                                (index !== 0) && (
                                                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                                        <Col sm="2">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                            {item.title}
                                                                        </Form.Label>
                                                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                            Description:
                                                                </Form.Label>
                                                                        <Col sm="2">
                                                                            <Form.Control name={item.title} value={item.description}
                                                                                onChange={(e) => this.handleChangePaymentDesc(index, e)} />
                                                                        </Col>
                                                                        <Col sm="1" style={{ textAlign: 'right' }}>
                                                                            <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.paymentRows[index].paymentValue}
                                                                                onChange={(value) => this.handleChangePaymentValue(index, value)} />
                                                                        </Col>
                                                                        <Col sm="2">
                                                                            <Form.Control name={item.title} value={item.paymentValueType}
                                                                                onChange={(e) => this.handleChangePaymentValueType(index, e)} as="select">
                                                                                <option>Select</option>
                                                                                <option>Currency</option>
                                                                                <option>%</option>
                                                                            </Form.Control>
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="4">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <AntButton
                                                                        type="dashed"
                                                                        onClick={this.removeLastPaymentLine}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MinusOutlined /> Remove A Line
                                          </AntButton>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <AntButton
                                                                        type="dashed"
                                                                        onClick={this.addNewPaymentLine}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <PlusOutlined /> Add New Line
                                          </AntButton>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeVATTaxCB} checked={this.state.VATTaxCheck}>VAT Tax</Checkbox>
                                                                </Col>
                                                                <Col sm="2" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.VATTaxValue}
                                                                        formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                                        onChange={(value) => { this.setState({ VATTaxValue: value }) }} />
                                                                </Col>
                                                                <Col sm="2">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.termCondRows.map((item, index) => (
                                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                    <Col sm="3">
                                                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                                                            onChange={(e) => this.onChangeTermCond(index, e)}
                                                                            checked={item.checked}>{item.title}</Checkbox>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <Form.Control name={item.title} value={item.description}
                                                                            onChange={(e) => this.handleChangeTermCond(index, e)} />
                                                                    </Col>
                                                                </Form.Group>
                                                            ))}

                                                        </Form>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Notes</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                New Note Name:
                                                            </Form.Label>
                                                            <Col sm="4">
                                                                <Form.Control name="noteName" value={this.state.noteName} onChange={this.handleChange} />
                                                            </Col>
                                                            <Col sm="3">
                                                                <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewNote} type="button">Add</Button>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                        </Form.Group>

                                                        {this.state.noteRows.map((item, index) => (
                                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="3">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                                                        onChange={(e) => this.onChangeNote(index, e)}
                                                                        checked={item.checked}>{item.title}</Checkbox>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <Form.Control name={item.title} value={item.description}
                                                                        onChange={(e) => this.handleChangeNote(index, e)} />
                                                                </Col>
                                                            </Form.Group>
                                                        ))}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Required Documents</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Col sm="1">
                                                                <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                                    checked={this.state.invoiceCheck}
                                                                    onChange={(e) => { this.setState({ invoiceCheck: e.target.checked }); }}>
                                                                </Checkbox>
                                                            </Col>
                                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                                Invoice
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Col sm="1">
                                                                <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                                    checked={this.state.deliveryNoteCheck}
                                                                    onChange={(e) => { this.setState({ deliveryNoteCheck: e.target.checked }); }}>
                                                                </Checkbox>
                                                            </Col>
                                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                                Delivery Note
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Col sm="1">
                                                                <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                                    checked={this.state.packingListCheck}
                                                                    onChange={(e) => { this.setState({ packingListCheck: e.target.checked }); }}>
                                                                </Checkbox>
                                                            </Col>
                                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                                Packing List
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Col sm="1">
                                                                <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                                    checked={this.state.testReportCheck}
                                                                    onChange={(e) => { this.setState({ testReportCheck: e.target.checked }); }}>
                                                                </Checkbox>
                                                            </Col>
                                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                                Test Report
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Col sm="1">
                                                                <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                                    checked={this.state.manufacturerManualCheck}
                                                                    onChange={(e) => { this.setState({ manufacturerManualCheck: e.target.checked }); }}>
                                                                </Checkbox>
                                                            </Col>
                                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                                Manufacturer Manual
                                                            </Form.Label>
                                                            <Col sm="1">
                                                                <AntButton type="primary" onClick={this.addNewRequiredDocument} icon={<PlusOutlined />}></AntButton>
                                                            </Col>
                                                        </Form.Group>

                                                        {this.state.requiredDocumentRows.map((item, index) => (
                                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="1">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                                        onChange={(e) => this.onChangeRequiredDocument(index, e)}
                                                                        checked={item.checked}>{item.title}</Checkbox>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Form.Control name={item.description} value={item.description}
                                                                        onChange={(e) => this.handleChangeRequiredDocument(index, e)} />
                                                                </Col>
                                                                <Col sm="1">
                                                                    <AntButton type="primary" danger onClick={this.removeRequiredDocument} icon={<CloseOutlined />}></AntButton>
                                                                </Col>
                                                            </Form.Group>
                                                        ))}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                        <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="6">
                                                <Button type="button" style={{ width: '100%' }} onClick={this.saveQuote} variant="success">Save</Button>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Form.Group>

                                    </Container>
                                </Card.Body>
                            </Card>
                        </div>
                        }

                        {(this.state.selectedRow !== null && this.state.selectedRow.length !== 0) && <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                            <Card border="light">
                                <Card.Body>
                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Purchase Order No:</Form.Label></Col>
                                            <Col xs={4}><Form.Control name="quoteNo" value={this.state.quoteNo} onChange={this.handleChange} /></Col>
                                        </Row>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Vendor Info</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Company's Full Name:
                                  </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.vendorFullName}
                                                            </Form.Label>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Company's Short Name:
                                      </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.vendorShortName}
                                                            </Form.Label>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Company Logo:
                                      </Form.Label>
                                                            <Col sm="2">
                                                                {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div>No Image</div>}
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ fontWeight: 'bold', color: 'black' }} column sm="3">
                                                                Head Office
                                  </Form.Label>
                                                            <Col sm="4">
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Address:
                                  </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.address}
                                                            </Form.Label>
                                                        </Form.Group>


                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Country:
                                      </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.country}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Phone:
                                      </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.phone}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Fax:
                                      </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.fax}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                Web:
                                      </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.web}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                                                                E-Mail:
                                      </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.email}
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Vendor Representative</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Name
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.name}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Midname
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.midname}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Surname
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.surname}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Position
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.position}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Phone
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.rPhone}
                                                            </Form.Label>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                Email
                                                            </Form.Label>
                                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                                {this.state.rEmail}
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Materials and Price</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <RowAnt>
                                                            <Space size="large" >
                                                                <Space direction="vertical">
                                                                    <div>Material</div>
                                                                    <Select style={{ width: "200px" }} value={this.state.material} placeholder="Select material"
                                                                        onChange={this.selectMaterial} >
                                                                        <Select.Option value="">Select Material</Select.Option>
                                                                        {optionsMaterials}
                                                                    </Select>
                                                                </Space>
                                                                <Space direction="vertical">
                                                                    <div>Quantity </div>
                                                                    <InputNumber min={1} value={this.state.quantity} onChange={(value) => { this.setState({ quantity: value }) }} />
                                                                </Space>

                                                                <Space direction="vertical">
                                                                    <div>Unit</div>
                                                                    <Input value={this.state.unit} style={{ width: "100px" }} onChange={(e) => { this.setState({ unit: e.target.value }) }} />

                                                                </Space>

                                                                <Space direction="vertical">
                                                                    <div>Price</div>
                                                                    <InputNumber min={1} value={this.state.price} onChange={(value) => { this.setState({ price: value }) }} />
                                                                </Space>

                                                                <Space direction="vertical">
                                                                    <div>Currency</div>
                                                                    <Input value={this.state.currency} style={{ width: "100px" }} onChange={(e) => { this.setState({ currency: e.target.value }) }} />

                                                                </Space>

                                                                <Space direction="vertical">
                                                                    <div>Discount</div>
                                                                    <InputNumber min={0} formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                                        value={this.state.discount} onChange={(value) => { this.setState({ discount: value }) }} />
                                                                </Space>

                                                                <Space direction="vertical">
                                                                    <div>Delivery Date</div>
                                                                    <DatePicker value={moment(this.state.date, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeDate} />
                                                                </Space>

                                                                <Space style={{ display: this.state.productAddButtonHidden }} direction="vertical">
                                                                    <div></div>
                                                                    <Button style={{ width: '100%', marginTop: '1rem' }} onClick={this.addProduct} type="button">Add</Button>
                                                                </Space>

                                                                <Space style={{ display: this.state.productUpdateButtonHidden }} direction="vertical">
                                                                    <div></div>
                                                                    <Button style={{ width: '100%', marginTop: '1rem' }} variant="warning" onClick={this.updateProduct} type="button">Update</Button>
                                                                </Space>

                                                                <Space style={{ display: this.state.productUpdateButtonHidden }} direction="vertical">
                                                                    <div></div>
                                                                    <Button style={{ width: '100%', marginTop: '1rem' }} variant="secondary" onClick={this.cancelProduct} type="button">Cancel</Button>
                                                                </Space>
                                                            </Space>
                                                        </RowAnt>
                                                        {this.state.serviceList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.serviceList} bordered >
                                                            <Column
                                                                width='50px'
                                                                title="Action"
                                                                key="action"
                                                                render={(text, record) => (
                                                                    <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteProductModal.bind(this)} onEditRow={this.editService.bind(this)} />   </React.Fragment>
                                                                )}
                                                            />
                                                            <Column title="Material Name" key="materialName" render={(text, record) => {
                                                                var list = this.state.materials;
                                                                var filteredList = list.filter(p => p.value === record.material);

                                                                if (filteredList.length > 0) {
                                                                    return filteredList[0].materialName;
                                                                }
                                                                else return "";
                                                            }} />
                                                            <Column title="Material Code" key="materialCode" render={(text, record) => {
                                                                var list = this.state.materials;
                                                                var filteredList = list.filter(p => p.value === record.material);

                                                                if (filteredList.length > 0) {
                                                                    return filteredList[0].materialCode;
                                                                }
                                                                else return "";
                                                            }} />
                                                            <Column title="Quantity" key="quantity" dataIndex="quantity" />
                                                            <Column title="Unit" key="unit" dataIndex="unit" />
                                                            <Column title="Price" key="price" dataIndex="price" />
                                                            <Column title="Discount (%)" key="discount" dataIndex="discount" />
                                                            <Column title="Delivery Date" key="date" dataIndex="date" />
                                                        </Table>}

                                                        <Form>

                                                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                                <Col sm="5">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeSubTotalCheck}
                                                                        checked={this.state.subTotalCheck}>Sub Total</Checkbox>
                                                                </Col>
                                                                {this.state.subTotals.length > 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        {this.state.subTotals[0].total} {this.state.subTotals[0].currency}
                                                                    </Form.Label>
                                                                }
                                                                {this.state.subTotals.length === 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        N/A Total / Unit
                                                                </Form.Label>
                                                                }
                                                                <Col sm="3">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.subTotals.map((item, index) => (
                                                                (item.index !== 1) && (
                                                                    <Form.Group key={index} as={Row}>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                            {this.state.subTotals[item.index - 1].total} {this.state.subTotals[item.index - 1].currency}
                                                                        </Form.Label>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}

                                                            <Form.Group as={Row}>
                                                                <Col sm="2">
                                                                </Col>
                                                                <Col sm="1" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.subDiscount}
                                                                        formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                                        onChange={(value) => { this.setState({ subDiscount: value }) }} />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.applyDiscount} type="button">Apply Discount</Button>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeDiscountCheck}
                                                                        checked={this.state.discountCheck}>Discount</Checkbox>
                                                                </Col>
                                                                {this.state.subTotalDiscount.length > 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        {this.state.subTotalDiscount[0].total} {this.state.subTotalDiscount[0].currency}
                                                                    </Form.Label>
                                                                }
                                                                {this.state.subTotalDiscount.length === 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        N/A Total / Unit
                                                                </Form.Label>
                                                                }
                                                                <Col sm="3">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.subTotalDiscount.map((item, index) => (
                                                                (item.index !== 1) && (
                                                                    <Form.Group key={index} as={Row}>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                            {this.state.subTotalDiscount[item.index - 1].total} {this.state.subTotalDiscount[item.index - 1].currency}
                                                                        </Form.Label>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}

                                                            <Form.Group as={Row}>
                                                                <Col sm="5">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeTotalCheck}
                                                                        checked={this.state.totalCheck}>Total</Checkbox>
                                                                </Col>
                                                                {this.state.total.length > 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        {this.state.total[0].total} {this.state.total[0].currency}
                                                                    </Form.Label>
                                                                }
                                                                {this.state.total.length === 0 &&
                                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                                        N/A Total / Unit
                                                                </Form.Label>
                                                                }
                                                                <Col sm="3">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.total.map((item, index) => (
                                                                (item.index !== 1) && (
                                                                    <Form.Group key={index} as={Row}>
                                                                        <Col sm="7">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                            {this.state.total[item.index - 1].total} {this.state.total[item.index - 1].currency}
                                                                        </Form.Label>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}
                                                        </Form>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Terms and Conditions</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form>
                                                            <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                                                                <Form.Label style={{ color: 'black' }} column sm="3">
                                                                    New Terms/Conditions Name:
                                                                </Form.Label>
                                                                <Col sm="4">
                                                                    <Form.Control name="termCondName" value={this.state.termCondName} onChange={this.handleChange} />
                                                                </Col>
                                                                <Col sm="3">
                                                                    <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewTermCond} type="button">Add</Button>
                                                                </Col>
                                                                <Col sm="2">
                                                                </Col>
                                                            </Form.Group>

                                                            {/*<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeValidityCB} checked={this.state.quoteValidityCheck}>Quote Validty</Checkbox>
                                                                </Col>
                                                                <Col sm="2" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.quoteValidityValue}
                                                                        onChange={(value) => { this.setState({ quoteValidityValue: value }) }} />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Form.Control name="quoteValidityTimeType" value={this.state.quoteValidityTimeType}
                                                                        onChange={this.handleChange} as="select">
                                                                        <option>Select</option>
                                                                        <option>Day</option>
                                                                        <option>Week</option>
                                                                        <option>Month</option>
                                                                        <option>Year</option>
                                                                    </Form.Control>
                                                                </Col>
                                                                </Form.Group>*/}

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangePaymentCB} checked={this.state.paymentCheck}>Payment Terms</Checkbox>
                                                                </Col>
                                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                    {this.state.paymentRows[0].title}
                                                                </Form.Label>
                                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                    Description:
                                                        </Form.Label>
                                                                <Col sm="2">
                                                                    <Form.Control name={this.state.paymentRows[0].title} value={this.state.paymentRows[0].description}
                                                                        onChange={(e) => this.handleChangePaymentDesc(0, e)} />
                                                                </Col>
                                                                <Col sm="1" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.paymentRows[0].paymentValue}
                                                                        onChange={(value) => this.handleChangePaymentValue(0, value)} />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Form.Control name={this.state.paymentRows[0].title} value={this.state.paymentRows[0].paymentValueType}
                                                                        onChange={(e) => this.handleChangePaymentValueType(0, e)} as="select">
                                                                        <option>Select</option>
                                                                        <option>Currency</option>
                                                                        <option>%</option>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.paymentRows.map((item, index) => (
                                                                (index !== 0) && (
                                                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                                        <Col sm="2">
                                                                        </Col>
                                                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                            {item.title}
                                                                        </Form.Label>
                                                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                                            Description:
                                                                </Form.Label>
                                                                        <Col sm="2">
                                                                            <Form.Control name={item.title} value={item.description}
                                                                                onChange={(e) => this.handleChangePaymentDesc(index, e)} />
                                                                        </Col>
                                                                        <Col sm="1" style={{ textAlign: 'right' }}>
                                                                            <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.paymentRows[index].paymentValue}
                                                                                onChange={(value) => this.handleChangePaymentValue(index, value)} />
                                                                        </Col>
                                                                        <Col sm="2">
                                                                            <Form.Control name={item.title} value={item.paymentValueType}
                                                                                onChange={(e) => this.handleChangePaymentValueType(index, e)} as="select">
                                                                                <option>Select</option>
                                                                                <option>Currency</option>
                                                                                <option>%</option>
                                                                            </Form.Control>
                                                                        </Col>
                                                                    </Form.Group>
                                                                )
                                                            ))}

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="4">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <AntButton
                                                                        type="dashed"
                                                                        onClick={this.removeLastPaymentLine}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <MinusOutlined /> Remove A Line
                                          </AntButton>
                                                                </Col>
                                                                <Col sm="2">
                                                                    <AntButton
                                                                        type="dashed"
                                                                        onClick={this.addNewPaymentLine}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <PlusOutlined /> Add New Line
                                          </AntButton>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="2">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeVATTaxCB} checked={this.state.VATTaxCheck}>VAT Tax</Checkbox>
                                                                </Col>
                                                                <Col sm="2" style={{ textAlign: 'right' }}>
                                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.VATTaxValue}
                                                                        formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                                        onChange={(value) => { this.setState({ VATTaxValue: value }) }} />
                                                                </Col>
                                                                <Col sm="2">
                                                                </Col>
                                                            </Form.Group>

                                                            {this.state.termCondRows.map((item, index) => (
                                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                                    <Col sm="3">
                                                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                                                            onChange={(e) => this.onChangeTermCond(index, e)}
                                                                            checked={item.checked}>{item.title}</Checkbox>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <Form.Control name={item.title} value={item.description}
                                                                            onChange={(e) => this.handleChangeTermCond(index, e)} />
                                                                    </Col>
                                                                </Form.Group>
                                                            ))}

                                                        </Form>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <br />
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Notes</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                                New Note Name:
                                  </Form.Label>
                                                            <Col sm="4">
                                                                <Form.Control name="noteName" value={this.state.noteName} onChange={this.handleChange} />
                                                            </Col>
                                                            <Col sm="3">
                                                                <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewNote} type="button">Add</Button>
                                                            </Col>
                                                            <Col sm="2">
                                                            </Col>
                                                        </Form.Group>

                                                        {this.state.noteRows.map((item, index) => (
                                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                                <Col sm="3">
                                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                                                        onChange={(e) => this.onChangeNote(index, e)}
                                                                        checked={item.checked}>{item.title}</Checkbox>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <Form.Control name={item.title} value={item.description}
                                                                        onChange={(e) => this.handleChangeNote(index, e)} />
                                                                </Col>
                                                            </Form.Group>
                                                        ))}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                        <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="6">
                                                <Button type="button" style={{ width: '100%' }} onClick={this.saveQuote2} variant="success">Save</Button>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Form.Group>

                                    </Container>
                                </Card.Body>
                            </Card>
                        </div>
                        }

                        {/* table başlangıcı önceki hali böyleydi....
                            <Table style={{ marginTop: '1rem', marginLeft: '5px', marginRight: '5px' }} size={`small`}
                                dataSource={this.state.vendorList} pagination={this.state.pagination}
                                responsive striped bordered hover
                                loading={this.state.loadingTable} onChange={this.handleTableChange}
                                scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
                                rowSelection={{
                                    type: "checkbox",
                                    ...rowSelection,
                                }}
                                expandable={{
                                    expandedRowRender: record => <PurchaseOrderSub row={record} />
                                }}
                            > */}

                        {this.state.vendorList.length > 0 &&
                            <Table style={{ marginTop: '1rem', marginLeft: '5px', marginRight: '5px' }} size={`small`}
                                dataSource={this.state.vendorList} responsive striped bordered hover
                                scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
                                expandable={{
                                    expandedRowRender: record => <PurchaseOrderSub row={record} />
                                }}
                            >
                                <Column title="S/N" key="sn" dataIndex="index" />
                                <Column title="Logo" key="logo" render={(value, row, index) => {
                                    if (row.logoPath != null && row.logoPath !== undefined)
                                        return <Avatar shape="square" size={64} src={`${API_BASE}/VendorLog/download/` + row.id} />;
                                    else return "";
                                }} />
                                <Column title="Vendor Code" key="id" dataIndex="id" />
                                <Column title="Number Of Project" key="nop" render={(record) => (this.numberOfProjects2(record))} />
                                <Column title="Company's Full Name" key="cfn" dataIndex="fullName" />
                                <Column title="Company's Short Name" key="csn" dataIndex="shortName" />
                                <ColumnGroup title="Head Office" key="ho" >
                                    <Column width='30%' title="Address" key="address" render={(value, row, index) => {
                                        if (row.contact !== undefined)
                                            if (row.contact != null)
                                                return row.contact.address;
                                        return '';
                                    }} />
                                    <Column title="Country" key="country" render={(value, row, index) => {
                                        if (row.contact !== undefined)
                                            if (row.contact != null)
                                                return row.contact.country;
                                        return '';
                                    }} />
                                    <Column title="Phones" key="phones" render={(value, row, index) => {
                                        if (row.contact !== undefined)
                                            if (row.contact != null)
                                                return row.contact.phones;
                                        return '';
                                    }} />
                                    <Column title="Faxes" key="faxes" render={(value, row, index) => {
                                        if (row.contact !== undefined)
                                            if (row.contact != null)
                                                return row.contact.faxes;
                                        return '';
                                    }} />
                                    <Column title="Web" key="web" render={(value, row, index) => {
                                        if (row.contact !== undefined)
                                            if (row.contact != null)
                                                return row.contact.web;
                                        return '';
                                    }} />
                                    <Column title="Emails" key="emails" render={(value, row, index) => {
                                        if (row.contact !== undefined)
                                            if (row.contact != null)
                                                return row.contact.mails;
                                        return '';
                                    }} />
                                </ColumnGroup>
                                <ColumnGroup title="Company Representative" key="cr" >
                                    <Column title="Name" key="name" render={(value, row, index) => {
                                        if (row.representative !== undefined)
                                            if (row.representative != null)
                                                return row.representative.name;
                                        return '';
                                    }} />

                                    <Column title="Mid Name" key="midname" render={(value, row, index) => {
                                        if (row.representative !== undefined)
                                            if (row.representative != null)
                                                return row.representative.midname;
                                        return '';
                                    }} />

                                    <Column title="Surname" key="surname" render={(value, row, index) => {
                                        if (row.representative !== undefined)
                                            if (row.representative != null)
                                                return row.representative.lastname;
                                        return '';
                                    }} />
                                    <Column title="Position" key="position" render={(value, row, index) => {
                                        if (row.representative !== undefined)
                                            if (row.representative != null)
                                                return row.representative.position;
                                        return '';
                                    }} />
                                    <Column title="Phone" key="rphone" render={(value, row, index) => {
                                        if (row.representative !== undefined)
                                            if (row.representative != null)
                                                return row.representative.phone;
                                        return '';
                                    }} />
                                    <Column title="Email" key="remail" render={(value, row, index) => {
                                        if (row.representative !== undefined)
                                            if (row.representative != null)
                                                return row.representative.email;
                                        return '';
                                    }} />
                                </ColumnGroup>
                            </Table>}
                    </Card>
               </MenuProvider>
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder)
