import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import MNDTableSettings from '../MND/MNDTableSettings';
import { Redirect } from "react-router-dom";
import * as Mnd from "../../partials/content/CustomComponents";
import { LinkedinOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Card } from "primereact/card";
import { Form, Input, Select, Upload, Button as AntdButton, Table, DatePicker, Checkbox, Divider, TreeSelect } from 'antd';
import { Modal, Button as ReactButton, Col, Form as FormBoot, Row } from 'react-bootstrap';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import { InputText } from "primereact/inputtext";
import moment from 'moment';
import 'jspdf-autotable';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { ShipmentProjectChart } from '../charts/ShipmentProjectChart';
import { ShipmentDriverChart } from '../charts/ShipmentDriverChart';

import { Pqi } from '../quality/Pqi';
import { Audit } from '../quality/Audit';
import { Mrm } from '../quality/Mrm';
import { ProjectProgress } from '../quality/ProjectProgress';
import { Manpower } from '../quality/Manpower';
import { DocumentSubmittal1 } from '../quality/DocumentSubmittal1';
import { DocumentSubmittal2 } from '../quality/DocumentSubmittal2';
import { ViolationSummary1 } from '../quality/ViolationSummary1';
import { ViolationSummary2 } from '../quality/ViolationSummary2';
import { InspectionSummary1 } from '../quality/InspectionSummary1';
import { InspectionSummary2 } from '../quality/InspectionSummary2';
import { InspectionSummary3 } from '../quality/InspectionSummary3';

import { MinusSquareOutlined, PlusSquareOutlined, ZoomInOutlined } from '@ant-design/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import domtoimage from 'dom-to-image';
import { CloseOutlined, ArrowRightOutlined, UpCircleOutlined, DownCircleOutlined, PlusCircleOutlined, LinkOutlined } from '@ant-design/icons';


const { TextArea } = Input;
const { Option } = Select;

const options = {
    showTotal: true,
    sizePerPageList: [{
        text: '10', value: 10
    }, {
        text: '20', value: 20
    }]
};


const staticTableList = [
    {
        key: "pqi",
        value: "Project Quality Index"
    },
    {
        key: "manpower",
        value: "Manpower"
    },
    {
        key: "mrm",
        value: "MRM"
    },
    {
        key: "audit",
        value: "Audit"
    },
    {
        key: "projectProgress",
        value: "Project Progress"
    },
    {
        key: "violationSummary1",
        value: "Violation Summary"
    },
    {
        key: "violationSummary2",
        value: "Violation Weekly Summary"
    },
    {
        key: "inspectionSummary1",
        value: "RFI Status"
    },
    {
        key: "inspectionSummary2",
        value: "MIR Status"
    },
    {
        key: "inspectionSummary3",
        value: "RFI & MIR Summary"
    },

    {
        key: "documentsubmittals1",
        value: "Special Process"
    },

    {
        key: "documentsubmittals2",
        value: "Inspection and Test Plans"
    }
]

function createColums(list) {

    let columns = [];
    if (Boolean(list)) {
        list.forEach(element => {
            if (element.showOnTable) {
                let di = element.attributeName;
                if (element.attributeName.includes("."))
                    di = element.attributeName.substring(0, element.attributeName.indexOf("."));
                columns.push({
                    dataIndex: di,
                    key: "key",
                    title: element.attributeHeader,
                    render: (data) => {
                        if (typeof data === "object") {
                            if (Boolean(data))
                                return data[element.attributeName.substring(element.attributeName.indexOf(".") + 1, element.attributeName.length)];
                            else return "";
                        }
                        return data;
                    }
                })
            }
        });
    }
    console.log(columns);
    return columns;
}

const expandable = { expandedRowRender: record => record.index };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class DocumentedCreator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            deleteModal: false,
            hiddenSave: false,
            hiddenUpdate: true,
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            documentedCreators: [],
            radioGroupTip: 1,
            hiddenDocument: true,
            hiddenReport: false,
            hiddenMeeting: true,
            hiddenDataGiris: false,
            ImageUpload: [],
            fileList: [],
            imagePath: "",
            dynamicTableDataFixed: [],
            tableColumns: [],
            documentedDesciription: [],
            documentedInfoId: -1,
            numPages: null,
            pageNumber: 1,
            startDate: null,
            endDate: null,
            fuelLogsStatic: [],
            fuelLogs: [],
            totalFuel: 0,
            totalPrice: 0,
            totalKm: 0,
            duration: "day",
            customers: [],
            customerOptions: [],
            selectedCustomers: [],
            projects: [],
            projectOptions: [],
            selectedProjects: [],
            chartType1: "bar",
            assignments: [],
            chartData1: {},
            moments1: [],
            dateStrings1: [],
            values: [],
            selectedItems: [],
            nodes: [],
            equipmentLogs: [],
            equipments: [],
            equipmentOptions: [],
            selectedEquipments: [],
            filled: true,
            imageUrl: '',
            modalPhotoShow: false,
            type: null,
            entitySelectItems: [],
            entityFieldList: [],
            entity: '',
            modalLinkDocument: false,
            linkedItemId: null,
            itemId: null,
            itemText: null,
            treeData: null,
            startIndex: 0,
            endIndex: 0,
            documentId: null,

            modalLinkedItemText: false,
            linkedItemText: null,

            array1: [],
            hiddenPdfGenerate: false,
        }

        this.actionTemplate = this.actionTemplate.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "DocumentedCreator" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "DocumentedCreator",
        };
        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const responseTree = await handleRequest("GET", "/DocumentedDocLog/itemTree");
        console.log(responseTree.data);
        if (responseTree.data.length !== 0) {
            this.setState({
                treeData: responseTree.data,
            });
        } else {
            this.setState({
                treeData: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "DocumentedCreator");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {
        }

        await this.getTableProperties();


        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            startDate: today,
            endDate: today
        });

        const response4 = await handleRequest("GET", "/ApplicationSettings/getEntities");

        if (Boolean(response4.data) && response4.data.length > 0) {

            const data = response4.data;
            console.log(data);
            this.setState({
                entitySelectItems: data

            });
        }

        this.setState({
            documentedInfoId: this.props.documentedInfoId,
            type: this.props.type,
        }, this.restartTable);


    }

    getEntityFields = async (entity) => {

        const response = await handleRequest("GET", "/ApplicationSettings/getEntityFieldsWithModel/" + entity);

        console.log(response);
        if (Boolean(response.data) && response.data.length > 0) {

            const data = response.data;
            console.log(data);
            return data;
        }

        return [];

    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    };

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    actionTemplate(rowData, column) {
        if (!this.state.hiddenDocument) {
            if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "UpdateDocument") >= 0 &&
                this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "DeleteDocument") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
                        onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
            } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "UpdateDocument") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuEdit row={rowData}
                        onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
            }
            else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "DeleteDocument") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuDelete row={rowData}
                        onDeleteRow={this.deleteModal.bind(this)} />
                </React.Fragment>;
            }
        }
        else if (!this.state.hiddenReport) {
            if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "UpdateReport") >= 0 &&
                this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "DeleteReport") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
                        onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
            } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "UpdateReport") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuEdit row={rowData}
                        onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
            }
            else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "DeleteReport") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuDelete row={rowData}
                        onDeleteRow={this.deleteModal.bind(this)} />
                </React.Fragment>;
            }
        }
        else if (!this.state.hiddenMeeting) {
            if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "UpdateMeeting") >= 0 &&
                this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "DeleteMeeting") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
                        onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
            } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "UpdateMeeting") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuEdit row={rowData}
                        onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
            }
            else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "DeleteMeeting") >= 0) {
                return <React.Fragment>
                    <CustomMaterialMenuDelete row={rowData}
                        onDeleteRow={this.deleteModal.bind(this)} />
                </React.Fragment>;
            }
        }
    };

    moveUp = (document, item) => {

        const list = document.items;
        const step = item;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.id === step.id);

            if (index > 0) {
                let flag = list[index - 1];
                list[index - 1] = step;
                list[index] = flag;
            }

            list.forEach((element, i) => {
                element.itemOrder = i;
            });

            const documentedDesciription = this.state.documentedDesciription;
            let doc = documentedDesciription.find(p => p.id === document.id);
            doc.items = list;
            this.setState({
                documentedDesciription: documentedDesciription
            })

            this.setState({
                stepList: list
            });
        }
    }

    moveDown = (document, item) => {

        const list = document.items;
        const step = item;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.id === step.id);

            if (index < list.length - 1) {

                let flag = list[index + 1];
                list[index + 1] = step;
                list[index] = flag;

            }

            list.forEach((element, i) => {
                element.itemOrder = i;
            });


            const documentedDesciription = this.state.documentedDesciription;
            let doc = documentedDesciription.find(p => p.id === document.id);
            doc.items = list;
            this.setState({
                documentedDesciription: documentedDesciription
            })

            this.setState({
                stepList: list
            });

        }


    }

    deleteElement = (document, item) => {

        const list = document.items;
        const step = item;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.id === step.id);

            list.splice(index, 1);

            list.forEach((element, i) => {
                element.itemOrder = i;
            });


            const documentedDesciription = this.state.documentedDesciription;
            let doc = documentedDesciription.find(p => p.id === document.id);
            doc.items = list;
            this.setState({
                documentedDesciription: documentedDesciription
            })

            this.setState({
                stepList: list
            });

        }

    };

    linkSelectedText = (document1, item) => {

        const list = document1.items;
        const step = item;

        let textVal = document.getElementById(document1.id + "." + item.id);
        console.log(textVal);
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        console.log(cursorStart);
        console.log(cursorEnd);
        if (cursorStart === 0 && cursorEnd === 0)
            this.showWarning("Please select text");
        else {
            let selected = Boolean(item.itemValue) ? item.itemValue.substring(cursorStart, cursorEnd) : "";

            console.log("Selected Text: ", selected);

            if (Boolean(list) && list.length > 0) {
                this.setState({
                    modalLinkDocument: true,
                    itemId: item.id,
                    itemText: selected,
                    startIndex: cursorStart,
                    endIndex: cursorEnd,
                    documentId: this.state.documentedInfoId

                });
            }

        }

    };




    changeOrder = (rowData, type, order) => {
        if (type === "Up") {
            var index = rowData.elementOrder.findIndex(p => p.element === order);
            var dizi = rowData.elementOrder;

            console.log(index);
            console.log(dizi);
            if (index > 0) {
                var indexData = dizi[index - 1];
                var index1Data = dizi[index];

                dizi[index] = indexData;
                dizi[index - 1] = index1Data;

                this.setState(prevState => ({
                    documentedDesciription: prevState.documentedDesciription.map(
                        el => el.id === rowData.id ? {
                            ...el,
                            elementOrder: dizi
                        } : el
                    )
                }))
            }
        } else if (type === "Down") {
            index = rowData.elementOrder.findIndex(p => p.element === order);
            dizi = rowData.elementOrder;
            console.log(index);
            console.log(dizi);
            if (dizi.length - 1 > index) {
                indexData = dizi[index + 1];
                index1Data = dizi[index];

                dizi[index] = indexData;
                dizi[index + 1] = index1Data;

                this.setState(prevState => ({
                    documentedDesciription: prevState.documentedDesciription.map(
                        el => el.id === rowData.id ? {
                            ...el,
                            elementOrder: dizi
                        } : el
                    )
                }))
            }
        }
    };



    addItemContent(document) {
        if (Boolean(document.itemType)) {

            var dizi = [];
            let items = [];
            let item = { id: 0, itemType: document.itemType, itemValue: null, itemOrder: 0 }

            if (Boolean(document.itemContents) && document.itemContents.length > 0) {
                dizi = document.itemContents;
            }
            else document.itemContents = [];


            if (Boolean(document.items) && document.items.length > 0) {
                items = document.items;
                item.id = document.items.length * -1;
                item.itemOrder = document.items.length;
            }
            items.push(item);

            document.items = items;
            this.setState(prevState => ({
                documentedDesciription: prevState.documentedDesciription.map(
                    el => el.id === document.id ? { ...el, items: items, itemContents: dizi } : el
                )
            }))

        }

    }

    renderElement(document, item, props) {
        var dizi = [];
        const that = this;
        switch (item.itemType) {

            case "text":
                dizi.push(<React.Fragment>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="1" >
                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                            <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="1" >
                            <Checkbox checked={item.hasItemNumber} onChange={(e) => {
                                const documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let element = doc.items.find(p => p.id === item.id);
                                element.hasItemNumber = e.target.checked;
                                if (!e.target.checked)
                                    element.itemNumber = "";
                                this.setState({
                                    documentedDesciription: documentedDesciription
                                })
                            }}>Has Item Number</Checkbox>
                        </Col>
                        {Boolean(item.hasItemNumber) && item.hasItemNumber && <Col sm="1">
                            <Input style={{ width: "100%" }} value={item.itemNumber} onChange={(e) => {
                                const documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let element = doc.items.find(p => p.id === item.id);
                                element.itemNumber = e.target.value;
                                this.setState({
                                    documentedDesciription: documentedDesciription
                                })
                            }}>
                            </Input>
                        </Col>}
                        <Col sm="7">
                            <TextArea style={{ width: '100%' }} rows={10} value={item.itemValue} id={document.id + "." + item.id}
                                autoSize onChange={(e) => {
                                    e.persist();

                                    const documentedDesciription = this.state.documentedDesciription;
                                    let doc = documentedDesciription.find(p => p.id === document.id);
                                    let element = doc.items.find(p => p.id === item.id);
                                    element.itemValue = e.target.value;
                                    this.setState({
                                        documentedDesciription: documentedDesciription
                                    })

                                }} />
                        </Col>
                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.linkSelectedText(document, item)} shape="circle" icon={<LinkOutlined />} />
                        </Col>
                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                        </Col>
                    </FormBoot.Group>

                    {Boolean(item.linkedItems) && item.linkedItems.length > 0 && item.linkedItems.map(i => (

                        <FormBoot.Group style={{ marginBottom: '0rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Col sm="2">
                                {i.itemText}
                            </Col>
                            <Col sm="1">
                                <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" icon={<ArrowRightOutlined style={{ fontSize: '32px' }} />} />
                            </Col>
                            <Col sm="2">
                                <div style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontWeight: "bold",
                                }}
                                    onClick={() => this.showLinkedItem(i.linkedItemId)}>
                                    {"Linked Text"} 
                                </div>

                            </Col>
                            <Col sm="1">
                                <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.removeLink(i)} shape="circle" icon={<CloseOutlined />} />
                            </Col>
                        </FormBoot.Group>
                    ))}

                    <Divider></Divider>
                </React.Fragment>);
                break;
            case "image":
                dizi.push(
                    <React.Fragment>
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="1" >
                                <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                                <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                            </Col>
                            <Col sm="2" >
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={item.fileList}
                                    onChange={({ fileList }) => {

                                        if (fileList.length === 1) {
                                            var imagePath = "";
                                            if (Boolean(fileList[0].response)) {
                                                imagePath = fileList[0].response.url;
                                            }

                                            const documentedDesciription = this.state.documentedDesciription;
                                            let doc = documentedDesciription.find(p => p.id === document.id);
                                            let element = doc.items.find(p => p.id === item.id);
                                            element.itemValue = imagePath;
                                            element.fileList = fileList;
                                            this.setState({
                                                documentedDesciription: documentedDesciription
                                            })
                                        }
                                    }}

                                    beforeUpload={(fileList) => {
                                        if (Boolean(item.itemValue) && item.itemValue.length > 0) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        const documentedDesciription = this.state.documentedDesciription;
                                        let doc = documentedDesciription.find(p => p.id === document.id);
                                        let element = doc.items.find(p => p.id === item.id);
                                        element.itemValue = "";
                                        element.fileList = [];
                                        this.setState({
                                            documentedDesciription: documentedDesciription
                                        })

                                    }
                                    }>
                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> <FormattedMessage id="DCAddImage" defaultMessage="Add Image" />
                                    </AntdButton>
                                </Upload>
                            </Col>
                            <Col sm="2">

                                <Select style={{ width: "100%" }} placeholder="Size" value={item.size} onChange={(value) => {
                                    const documentedDesciription = this.state.documentedDesciription;
                                    let doc = documentedDesciription.find(p => p.id === document.id);
                                    let element = doc.items.find(p => p.id === item.id);
                                    element.size = value;
                                    this.setState({
                                        documentedDesciription: documentedDesciription
                                    })

                                }}>
                                    <Option key={null} value={null}>Select</Option>
                                    <Option key="small" value="small">Small</Option>
                                    <Option key="medium" value="medium">Medium</Option>
                                    <Option key="large" value="large">Large</Option>
                                </Select>
                            </Col>
                            <Col sm="2">
                                {Boolean(item.itemValue) && item.itemValue !== "" ?
                                    <img
                                        width={200}
                                        src={item.itemValue}
                                        alt="avatar"
                                    //   style={{ width: "100%" }}
                                    />
                                    : <div></div>}
                            </Col>
                            <Col sm="4"></Col>
                            <Col sm="1">
                                <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                            </Col>
                        </FormBoot.Group>
                        <Divider></Divider>

                    </React.Fragment>
                );
                break;
            case "table":
                dizi.push(
                    <React.Fragment>
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="1" >
                                <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                                <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                            </Col>
                            <Col sm="10">
                                <Col sm="4">

                                    <Select style={{ width: "100%" }} placeholder="Table" value={item.itemValue} onChange={async (value) => {

                                        var table = value;

                                        this.getTableData3(document, item, table);


                                    }}>
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.entitySelectItems.map(i => (
                                            <Option key={i} value={i}>{i}</Option>
                                        ))}
                                    </Select>
                                </Col>



                            </Col>
                            <Col sm="1">
                                <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                            </Col>
                        </FormBoot.Group>
                        {Boolean(item.itemValue) && item.itemValue.length > 0 && Boolean(item.reportTables) && item.reportTables.filter(p => p.active === true).map((d, i) => (
                            <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                                {
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row} >
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            {!d.typeOfEntity && <div>
                                                <Checkbox onChange={(e) => {
                                                    var reports = item.reportTables;
                                                    var obj = reports.filter(p => p.active === true)[i];
                                                    obj.showOnTable = e.target.checked;
                                                    that.setState(prevState => ({
                                                        documentedDesciription: prevState.documentedDesciription.map(
                                                            el => el.id === document.id ? {
                                                                ...el,
                                                                items: el.items.map(p => p.id === item.id ? { ...p, reportTables: reports } : p)

                                                            } : el
                                                        )
                                                    }))
                                                }} checked={item.reportTables.filter(p => p.active === true)[i].showOnTable}> </Checkbox>
                                            </div>}
                                            {d.typeOfEntity && d.showPlus && <AntdButton style={{ border: '0px', alignContent: 'center' }} type="text" onClick={() => this.showDetailFields(d, document, item, i)} icon={<PlusSquareOutlined style={{ fontSize: '24px' }} />} />}
                                            {d.typeOfEntity && !d.showPlus && <AntdButton style={{ border: '0px', alignContent: 'center' }} type="text" onClick={() => this.hideDetailFiels(d, document, item, i)} icon={<MinusSquareOutlined style={{ fontSize: '24px' }} />} />}
                                        </Col>

                                        <Col sm="4">
                                            <FormBoot.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                {d.attributeName}
                                            </FormBoot.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Input id="todo" value={d.attributeHeader} onChange={(e) => {
                                                var reports = item.reportTables;
                                                var obj = reports.filter(p => p.active === true)[i];
                                                obj.attributeHeader = e.target.value;
                                                that.setState(prevState => ({
                                                    documentedDesciription: prevState.documentedDesciription.map(
                                                        el => el.id === document.id ? {
                                                            ...el,
                                                            items: el.items.map(p => p.id === item.id ? { ...p, reportTables: reports } : p)

                                                        } : el
                                                    )
                                                }))
                                            }} />

                                        </Col>

                                    </FormBoot.Group>

                                }

                            </div>))}

                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="12">
                                {Boolean(item.itemValue) && item.itemValue.length > 0 && Boolean(document.tableColumns) && document.tableColumns.length > 0 && <Table
                                    style={{ marginTop: '3rem' }} size={`small`}
                                    scroll={{ x: 800, scrollToFirstRowOnChange: true }}
                                    showHeader={true}
                                    bordered={true}
                                    pagination={true}
                                    columns={document.tableColumns} dataSource={document.tableData}
                                />}
                            </Col>
                        </FormBoot.Group>
                        <Divider></Divider>

                    </React.Fragment>)
                break;
            case "editableTable":
                dizi.push(<React.Fragment>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="1" >
                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                            <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="1">
                            <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DCRows" defaultMessage="Rows" />
                            </FormBoot.Label>
                        </Col>
                        <Col sm="2">
                            <InputText style={{ width: '100%' }} type="number"
                                keyfilter="pint" value={item.tblRows}
                                onChange={(e) => {
                                    e.persist();
                                    const documentedDesciription = this.state.documentedDesciription;
                                    let doc = documentedDesciription.find(p => p.id === document.id);
                                    let element = doc.items.find(p => p.id === item.id);
                                    element.tblRows = e.target.value;
                                    this.setState({
                                        documentedDesciription: documentedDesciription
                                    })
                                }} />
                        </Col>

                        <Col sm="1">
                            <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DCColumns" defaultMessage="Columns" />
                            </FormBoot.Label>
                        </Col>
                        <Col sm="2">
                            <InputText style={{ width: '100%' }} type="number"
                                keyfilter="pint" value={item.tblColumns}
                                onChange={(e) => {
                                    e.persist();
                                    const documentedDesciription = this.state.documentedDesciription;
                                    let doc = documentedDesciription.find(p => p.id === document.id);
                                    let element = doc.items.find(p => p.id === item.id);
                                    element.tblColumns = e.target.value;
                                    this.setState({
                                        documentedDesciription: documentedDesciription
                                    })
                                }} />
                        </Col>

                        <Col sm="2">
                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.createEditableTable(document, item)} icon={<PlusCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="2"></Col>
                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                        </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="12">

                            {Boolean(item.editableTableColumns) && item.editableTableColumns.length > 0 &&
                                <BootstrapTable style={{ overflowX: 'auto' }} striped hover bordered keyField='id' cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                                    data={item.editableTableData} columns={item.editableTableColumns} pagination={paginationFactory(options)}
                                    {...props.baseProps} />

                            }
                        </Col>
                    </FormBoot.Group>
                    <Divider></Divider>

                </React.Fragment>);

                break;
            case "staticTable":
                dizi.push(<React.Fragment>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="1">

                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                            <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="10">
                            <Select style={{ width: "100%" }} placeholder="Table" value={item.itemValue} onChange={(value) => {
                                const documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let element = doc.items.find(p => p.id === item.id);
                                element.itemValue = value;
                                this.setState({
                                    documentedDesciription: documentedDesciription
                                })
                            }}>
                                <Option key={null} value={null}>Select</Option>
                                {staticTableList.map(i => (
                                    <Option key={i.key} value={i.key}>{i.value}</Option>
                                ))}
                            </Select>
                            <br />

                            {Boolean(item.itemValue) && item.itemValue === "pqi" && <Pqi id={document.id + "" + item.id}></Pqi>}
                            {Boolean(item.itemValue) && item.itemValue === "manpower" && <Manpower id={document.id + "" + item.id}></Manpower>}
                            {Boolean(item.itemValue) && item.itemValue === "mrm" && <Mrm id={document.id + "" + item.id}></Mrm>}
                            {Boolean(item.itemValue) && item.itemValue === "audit" && <Audit id={document.id + "" + item.id}></Audit>}
                            {Boolean(item.itemValue) && item.itemValue === "projectProgress" && <ProjectProgress id={document.id + "" + item.id}></ProjectProgress>}
                            {Boolean(item.itemValue) && item.itemValue === "violationSummary1" && <ViolationSummary1 id={document.id + "" + item.id}></ViolationSummary1>}
                            {Boolean(item.itemValue) && item.itemValue === "violationSummary2" && <ViolationSummary2 id={document.id + "" + item.id}></ViolationSummary2>}
                            {Boolean(item.itemValue) && item.itemValue === "inspectionSummary1" && <InspectionSummary1 id={document.id + "" + item.id}></InspectionSummary1>}
                            {Boolean(item.itemValue) && item.itemValue === "inspectionSummary2" && <InspectionSummary2 id={document.id + "" + item.id}></InspectionSummary2>}
                            {Boolean(item.itemValue) && item.itemValue === "inspectionSummary3" && <InspectionSummary3 id={document.id + "" + item.id}></InspectionSummary3>}
                            {Boolean(item.itemValue) && item.itemValue === "documentsubmittals1" && <DocumentSubmittal1 id={document.id + "" + item.id}></DocumentSubmittal1>}
                            {Boolean(item.itemValue) && item.itemValue === "documentsubmittals2" && <DocumentSubmittal2 id={document.id + "" + item.id}></DocumentSubmittal2>}

                        </Col>

                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                        </Col>
                    </FormBoot.Group>
                    <Divider></Divider>

                </React.Fragment>);

                break;
            case "chart":
                dizi.push(<React.Fragment>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="1" >
                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                            <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="8">
                            <Select style={{ width: "100%" }} placeholder="Table" value={item.itemValue} onChange={(value) => {
                                const documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let element = doc.items.find(p => p.id === item.id);
                                element.itemValue = value;
                                this.setState({
                                    documentedDesciription: documentedDesciription
                                })
                            }}>
                                <Option key={null} value={null}>Select Chart</Option>
                                <Option key="ShipmentProjectChart" value="ShipmentProjectChart">Shipment Project Chart</Option>
                                <Option key="ShipmentDriverChart" value="ShipmentDriverChart">Shipment Diriver Chart</Option>
                            </Select>

                            <br />
                            {Boolean(item.itemValue) && item.itemValue === "ShipmentProjectChart" && <ShipmentProjectChart id={"" + document.id + "" + item.id}></ShipmentProjectChart>}
                            {Boolean(item.itemValue) && item.itemValue === "ShipmentDriverChart" && <ShipmentDriverChart id={"" + document.id + "" + item.id}></ShipmentDriverChart>}

                        </Col>
                        <Col sm="2" >

                            <Select style={{ width: "100%" }} placeholder="Size" value={item.size} onChange={(value) => {
                                const documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let element = doc.items.find(p => p.id === item.id);
                                element.size = value;
                                this.setState({
                                    documentedDesciription: documentedDesciription
                                })

                            }}>
                                <Option key={null} value={null}>Select</Option>
                                <Option key="small" value="small">Small</Option>
                                <Option key="medium" value="medium">Medium</Option>
                                <Option key="large" value="large">Large</Option>
                            </Select>
                        </Col>
                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                        </Col>
                    </FormBoot.Group>
                    <Divider></Divider>

                </React.Fragment>);

                break;
            case "extraPages":
                dizi.push(<React.Fragment>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="1" >
                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                            <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="2">

                            <Upload {...props}
                                showUploadList={true}
                                fileList={item.fileList}
                                onChange={({ fileList }) => {

                                    if (fileList.length === 1) {
                                        var imagePath = "";
                                        if (Boolean(fileList[0].response)) {
                                            imagePath = fileList[0].response.url;
                                        }

                                        const documentedDesciription = this.state.documentedDesciription;
                                        let doc = documentedDesciription.find(p => p.id === document.id);
                                        let element = doc.items.find(p => p.id === item.id);
                                        element.itemValue = imagePath;
                                        element.fileList = fileList;
                                        this.setState({
                                            documentedDesciription: documentedDesciription
                                        })
                                    }
                                }}

                                beforeUpload={(file, fileList) => {
                                    if (Boolean(item.itemValue) && item.itemValue.length > 0) {
                                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                        return false;
                                    }
                                    else {
                                        const isPdf = file.type === 'application/pdf';
                                        if (!isPdf) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning2" defaultMessage="You can only upload PDF file!" />);
                                            return false;
                                        }

                                    }
                                    return true;
                                }}
                                onRemove={({ fileList }) => {
                                    const documentedDesciription = this.state.documentedDesciription;
                                    let doc = documentedDesciription.find(p => p.id === document.id);
                                    let element = doc.items.find(p => p.id === item.id);
                                    element.itemValue = "";
                                    element.fileList = [];
                                    this.setState({
                                        documentedDesciription: documentedDesciription
                                    })
                                }
                                }>
                                <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                    <UploadOutlined /> <FormattedMessage id="DCAddImage" defaultMessage="Add Page(s)" />
                                </AntdButton>
                            </Upload>
                        </Col>

                        <Col sm="1">

                            {Boolean(item.itemValue) && item.itemValue.length > 0 && <AntdButton onClick={() => this.showAttachment(item.itemValue)} type="primary" icon={<ZoomInOutlined style={{ fontSize: '24px' }} />}  >  <FormattedMessage id="DCShowAttachment"
                                defaultMessage="Show" />
                            </AntdButton>}

                        </Col>
                        <Col sm="7" ></Col>
                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                        </Col>
                    </FormBoot.Group>
                    <Divider></Divider>

                </React.Fragment>);

                break;
            case "attachment":
                dizi.push(<React.Fragment>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="1" >
                            <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveUp(document, item)} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />
                            <AntdButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={() => this.moveDown(document, item)} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                        </Col>
                        <Col sm="2">

                            <Upload {...props}
                                showUploadList={true}
                                fileList={item.fileList}
                                onChange={({ fileList }) => {

                                    if (fileList.length === 1) {
                                        var imagePath = "";
                                        if (Boolean(fileList[0].response)) {
                                            imagePath = fileList[0].response.url;
                                        }

                                        const documentedDesciription = this.state.documentedDesciription;
                                        let doc = documentedDesciription.find(p => p.id === document.id);
                                        let element = doc.items.find(p => p.id === item.id);
                                        element.itemValue = imagePath;
                                        element.fileList = fileList;
                                        this.setState({
                                            documentedDesciription: documentedDesciription
                                        })
                                    }
                                }}

                                beforeUpload={(file, fileList) => {
                                    if (Boolean(item.itemValue) && item.itemValue.length > 0) {
                                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                        return false;
                                    }
                                    else {
                                        const isPdfOrisImage = file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png';
                                        if (!isPdfOrisImage) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning2" defaultMessage="You can only upload PDF/JPG/PNG file!" />);
                                            return false;
                                        }

                                    }
                                    return true;
                                }}
                                onRemove={({ fileList }) => {
                                    const documentedDesciription = this.state.documentedDesciription;
                                    let doc = documentedDesciription.find(p => p.id === document.id);
                                    let element = doc.items.find(p => p.id === item.id);
                                    element.itemValue = "";
                                    element.fileList = [];
                                    this.setState({
                                        documentedDesciription: documentedDesciription
                                    })
                                }
                                }>
                                <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                    <UploadOutlined /> <FormattedMessage id="DCAddImage" defaultMessage="Add Attachment" />
                                </AntdButton>
                            </Upload>
                        </Col>

                        <Col sm="2" >

                            <Input style={{ width: "100%" }} placeholder="Attachment Name" value={item.attachmentName} onChange={(e) => {
                                const documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let element = doc.items.find(p => p.id === item.id);
                                element.attachmentName = e.target.value;
                                this.setState({
                                    documentedDesciription: documentedDesciription
                                })
                            }}>
                            </Input>
                        </Col>

                        <Col sm="1">

                            {Boolean(item.itemValue) && item.itemValue.length > 0 && <AntdButton onClick={() => this.showAttachment(item.itemValue)} type="primary" icon={<ZoomInOutlined style={{ fontSize: '24px' }} />}  >  <FormattedMessage id="DCShowAttachment"
                                defaultMessage="Show" />
                            </AntdButton>}

                        </Col>
                        <Col sm="5" ></Col>
                        <Col sm="1">
                            <AntdButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.deleteElement(document, item)} shape="circle" icon={<CloseOutlined />} />
                        </Col>
                    </FormBoot.Group>
                    <Divider></Divider>

                </React.Fragment>);

                break;
            default:
                break;

        }

        return dizi;

    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedDocumentedCreator: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    addLink = async () => {

        const linkedItem = {

            itemId: this.state.itemId,
            itemText: this.state.itemText,
            startIndex: this.state.startIndex,
            endIndex: this.state.endIndex,
            linkedItemId: this.state.linkedItemId,
            //documentId: this.state.documentId,
            itemNo: this.state.itemNo


        }

        var response = await handleRequest("POST", "/LinkedReportItems/add", linkedItem);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }

    };

    removeLink = async (i) => {

        const obj = {
            id: i.id

        }

        var response = await handleRequest("POST", "/LinkedReportItems/delete", obj);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }

    };


    saveDocumentedCreator = async () => {
        // if (!this.state.hiddenDocument) {
        //     const newDocumentedDocCreator = {
        //         typeOfDocument: this.state.typeOfDocument,
        //         documentFormat: this.state.documentFormat,
        //         codeFormat: this.state.codeFormat,
        //         tableOfContentsFormat: this.state.tableOfContentsFormat,
        //         itemNo: this.state.itemNo,
        //         description: this.state.description,
        //         pageNo: this.state.pageNo,
        //     }
        //     var response = await handleRequest("POST", "/DocumentedDocCreator/add", newDocumentedDocCreator);
        //     if (response.data !== null && response.data !== undefined) {
        //         if (response.data === "SUCCESS") {
        //             this.resetInputs();
        //             this.restartTable();
        //             this.showSuccess();
        //         }
        //         else {
        //             this.showError();
        //         }
        //     }
        // } else if (!this.state.hiddenReport) {
        const { type, documentedInfoId } = this.state;

        const newDocumentedReportCreator = {

            typeOfReport: this.state.typeOfReport,
            nameOfReport: this.state.nameOfReport,
            reportFormat: this.state.reportFormat,
            reportNo: this.state.reportNo,
            periodForReport: this.state.periodForReport,
            tableOfContentsFormat: this.state.tableOfContentsFormat,
            itemNo: this.state.itemNo,
            description: this.state.description,
            pageNo: this.state.pageNo,
            documentedInfoId: Boolean(type) && type === "Report" ? documentedInfoId : null,
            documentedDocId: Boolean(type) && type === "Document" ? documentedInfoId : null,
            documentedMeetingId: Boolean(type) && type === "Meeting" ? documentedInfoId : null,

        }

        var response = await handleRequest("POST", "/DocumentedReportCreator/add", newDocumentedReportCreator);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }
        //}
        // else if (!this.state.hiddenMeeting) {
        //     const newDocumentedMeetingCreator = {
        //         nameOfMeeting: this.state.nameOfMeeting,
        //         meetingFormat: this.state.meetingFormat,
        //         meetingNo: this.state.meetingNo,
        //         periodForMeeting: this.state.periodForMeeting,
        //         prepareMeeting: this.state.prepareMeeting,
        //         tableOfContentsFormat: this.state.tableOfContentsFormat,
        //         itemNo: this.state.itemNo,
        //         description: this.state.description,
        //         pageNo: this.state.pageNo,
        //     }
        //     var response = await handleRequest("POST", "/DocumentedMeetingCreator/add", newDocumentedMeetingCreator);
        //     if (response.data !== null && response.data !== undefined) {
        //         if (response.data === "SUCCESS") {
        //             this.resetInputs();
        //             this.restartTable();
        //             this.showSuccess();
        //         }
        //         else {
        //             this.showError();
        //         }
        //     }
        // }
    };

    updateDocumentedCreator = async () => {
        // if (!this.state.hiddenDocument) {
        //     const updatedDocumentedDocCreator = {
        //         id: this.state.selectedDocumentedCreator.id,
        //         typeOfDocument: this.state.typeOfDocument,
        //         documentFormat: this.state.documentFormat,
        //         codeFormat: this.state.codeFormat,
        //         tableOfContentsFormat: this.state.tableOfContentsFormat,
        //         itemNo: this.state.itemNo,
        //         description: this.state.description,
        //         pageNo: this.state.pageNo,
        //     }

        //     var response = await handleRequest("POST", "/DocumentedDocCreator/update", updatedDocumentedDocCreator);
        //     if (response.data !== null && response.data !== undefined) {
        //         if (response.data === "SUCCESS") {
        //             this.resetInputs();
        //             this.restartTable();
        //             this.showSuccess();
        //         }
        //         else {
        //             this.showError();
        //         }
        //     }
        // }
        // else if (!this.state.hiddenReport) {
        const { type, documentedInfoId } = this.state;

        const updatedDocumentedReportCreator = {
            id: this.state.selectedDocumentedCreator.id,
            typeOfReport: this.state.typeOfReport,
            nameOfReport: this.state.nameOfReport,
            reportFormat: this.state.reportFormat,
            reportNo: this.state.reportNo,
            periodForReport: this.state.periodForReport,
            tableOfContentsFormat: this.state.tableOfContentsFormat,
            itemNo: this.state.itemNo,
            description: this.state.description,
            pageNo: this.state.pageNo,
            documentedInfoId: Boolean(type) && type === "Report" ? documentedInfoId : null,
            documentedDocId: Boolean(type) && type === "Document" ? documentedInfoId : null,
            documentedMeetingId: Boolean(type) && type === "Meeting" ? documentedInfoId : null,

        }

        var response = await handleRequest("POST", "/DocumentedReportCreator/update", updatedDocumentedReportCreator);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }
        // }
        // else if (!this.state.hiddenMeeting) {
        //     const updatedDocumentedMeetingCreator = {
        //         id: this.state.selectedDocumentedCreator.id,
        //         nameOfMeeting: this.state.nameOfMeeting,
        //         meetingFormat: this.state.meetingFormat,
        //         meetingNo: this.state.meetingNo,
        //         periodForMeeting: this.state.periodForMeeting,
        //         prepareMeeting: this.state.prepareMeeting,
        //         tableOfContentsFormat: this.state.tableOfContentsFormat,
        //         itemNo: this.state.itemNo,
        //         description: this.state.description,
        //         pageNo: this.state.pageNo,
        //     }

        //     var response = await handleRequest("POST", "/DocumentedMeetingCreator/update", updatedDocumentedMeetingCreator);
        //     if (response.data !== null && response.data !== undefined) {
        //         if (response.data === "SUCCESS") {
        //             this.resetInputs();
        //             this.restartTable();
        //             this.showSuccess();
        //         }
        //         else {
        //             this.showError();
        //         }
        //     }
        // }
    };

    restartTable = async () => {
        this.getDocumentedReportCreator();
    }

    deleteDocumentedCreatorOK = async () => {
        const newDocumentedCreator = {
            id: this.state.selectedDocumentedCreator.id
        }
        var response = [];
        if (!this.state.hiddenDocument) {
            response = await handleRequest("POST", "/DocumentedDocCreator/delete", newDocumentedCreator);
        }
        else if (!this.state.hiddenReport) {
            response = await handleRequest("POST", "/DocumentedReportCreator/delete", newDocumentedCreator);
        }
        else if (!this.state.hiddenMeeting) {
            response = await handleRequest("POST", "/DocumentedMeetingCreator/delete", newDocumentedCreator);
        }
        if (response.length !== 0 && response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        else {
            this.showError();
        }

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    changeButtonUpdate = (row) => {
        if (!this.state.hiddenDocument) {
            this.formRef.current.setFieldsValue({
                TypeOfDocument: row.typeOfDocument,
                DocumentFormat: row.documentFormat,
                CodeFormat: row.codeFormat,

                TableOfContentsFormat: row.tableOfContentsFormat,
                ItemNo: row.itemNo,
                Description: row.description,
            });

            this.setState({
                typeOfDocument: row.typeOfDocument,
                documentFormat: row.documentFormat,
                codeFormat: row.codeFormat,
                tableOfContentsFormat: row.tableOfContentsFormat,
                itemNo: row.itemNo,
                description: row.description,
                pageNo: row.pageNo,
                hiddenSave: true,
                hiddenUpdate: false,
                selectedDocumentedCreator: row,
            });
        }
        else if (!this.state.hiddenReport) {
            this.formRef.current.setFieldsValue({

                ReportNo: row.reportNo,
                PeriodForReport: row.periodForReport,

                TableOfContentsFormat: row.tableOfContentsFormat,
                ItemNo: row.itemNo,
                Description: row.description,
            });

            this.setState({
                typeOfReport: row.typeOfReport,
                nameOfReport: row.nameOfReport,
                reportFormat: row.reportFormat,
                reportNo: row.reportNo,
                periodForReport: row.periodForReport,
                tableOfContentsFormat: row.tableOfContentsFormat,
                itemNo: row.itemNo,
                description: row.description,
                pageNo: row.pageNo,
                hiddenSave: true,
                hiddenUpdate: false,
                selectedDocumentedCreator: row,
            });
        }
        else if (!this.state.hiddenMeeting) {
            this.formRef.current.setFieldsValue({
                NameOfMeeting: row.nameOfMeeting,
                MeetingFormat: row.meetingFormat,
                MeetingNo: row.meetingNo,
                PeriodForMeeting: row.periodForMeeting,

                TableOfContentsFormat: row.tableOfContentsFormat,
                ItemNo: row.itemNo,
                Description: row.description,
            });

            this.setState({
                nameOfMeeting: row.nameOfMeeting,
                meetingFormat: row.meetingFormat,
                meetingNo: row.meetingNo,
                periodForMeeting: row.periodForMeeting,
                prepareMeeting: row.prepareMeeting,
                tableOfContentsFormat: row.tableOfContentsFormat,
                itemNo: row.itemNo,
                description: row.description,
                pageNo: row.pageNo,
                hiddenSave: true,
                hiddenUpdate: false,
                selectedDocumentedCreator: row,
            });
        }
    };

    resetInputs = async () => {
        this.formRef.current.setFieldsValue({
            TypeOfDocument: "",
            DocumentFormat: "",
            CodeFormat: "",


            ReportNo: "",
            PeriodForReport: "",

            NameOfMeeting: "",
            MeetingFormat: "",
            MeetingNo: "",
            PeriodForMeeting: "",

            TableOfContentsFormat: "",
            ItemNo: "",
            Description: "",
        });

        this.setState({
            typeOfDocument: "",
            documentFormat: "",
            codeFormat: "",

            typeOfReport: "",
            nameOfReport: "",
            reportFormat: "",
            reportNo: "",
            periodForReport: "",

            nameOfMeeting: "",
            meetingFormat: "",
            meetingNo: "",
            periodForMeeting: "",
            prepareMeeting: "",

            tableOfContentsFormat: "",
            itemNo: "",
            description: "",
            pageNo: "",

            modalLinkDocument: false,
            linkedItemId: null,
            itemId: null,
            itemText: null,
            startIndex: 0,
            endIndex: 0,
            documentId: null,

            hiddenSave: false,
            hiddenUpdate: true,
            selectedDocumentedCreator: null,
            hiddenPdfGenerate: false,
        });
    };

    changeButtonCancel = () => {
        this.resetInputs();
    };

    showOrHideModalDeleteMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
        await this.getTableProperties();
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    getTableProperties = async () => {
        //MNDTableSettings.getTableProperties("documentedCreatorTable");

        this.documentedCreatorTable = JSON.parse(localStorage.getItem('documentedCreatorTable'));
        console.log("GETTABLE", this.documentedCreatorTable);
        if (this.documentedCreatorTable !== null && this.documentedCreatorTable !== undefined && this.documentedCreatorTable !== "") {

            this.setState({
                bordered: this.documentedCreatorTable.bordered,
                title: this.documentedCreatorTable.titleDrm === true ? title : undefined,
                titleDrm: this.documentedCreatorTable.titleDrm,
                showHeader: this.documentedCreatorTable.showHeader,
                footer: this.documentedCreatorTable.footerDrm === true ? footer : undefined,
                footerDrm: this.documentedCreatorTable.footerDrm,
                expandable: this.documentedCreatorTable.expandableDrm === true ? expandable : {},
                expandableDrm: this.documentedCreatorTable.expandableDrm,
                rowSelection: this.documentedCreatorTable.rowSelection,
                ellipsis: this.documentedCreatorTable.ellipsis,
                tableLayout: this.documentedCreatorTable.tableLayout,
                size: this.documentedCreatorTable.size,
                top: this.documentedCreatorTable.top,
                bottom: this.documentedCreatorTable.bottom,
                editBgColor: this.documentedCreatorTable.editBgColor,
                deleteBgColor: this.documentedCreatorTable.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.documentedCreatorTable.yScroll,
                xScroll: this.documentedCreatorTable.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.documentedCreators.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.documentedCreators.length, showQuickJumper: true,
                    position: [this.documentedCreatorTable.top, this.documentedCreatorTable.bottom]
                },
                kontrol: true,
            });
        } else {
            this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    getDocumentedReportCreator = async () => {
        var _documentedDesciription = [];
        const { documentedInfoId, type } = this.state;

        const response = await handleRequest("POST", "/DocumentedReportCreator/getallByCompany/" + documentedInfoId, { type });
        if (response.data.length === 0) {
            this.setState({
                documentedCreators: [],
                documentedDesciription: _documentedDesciription
            });
        } else {
            const result = response.data;

            console.log(result);

            result.forEach(document => {


                if (Boolean(document.items) && document.items.length > 0) {
                    document.items.forEach(item => {
                        if (item.itemType === "table")
                            item.oldReportTables = [...item.reportTables];
                        if (item.itemType === "editableTable") {
                            item.editableTableColumns = this.getManualColumns(document, item.manualTables, item.id);
                            item.editableTableData = this.getManualTableData(item.tblRows, item.manualTables);
                        }
                    });
                }
            });

            this.setState({
                documentedCreators: response.data,
                documentedDesciription: response.data
            });
        }
    }


    getTableData3 = async (document, item, table) => {
        let fields = await this.getEntityFields(table);
        let reportTables = [];

        var response = await handleRequest("GET", "/" + table + "/getAllByCompany");
        if (response.data.length === 0)
            response = await handleRequest("GET", "/" + table + "/getallByCompany");


        reportTables = Boolean(item.reportTables) ? item.reportTables : [];


        if (Boolean(item.oldReportTables) && item.oldReportTables.length > 0 && item.oldReportTables[0].entityName === table) {
            reportTables = item.oldReportTables;
        }
        else
            if (Boolean(fields) && fields.length > 0) {

                fields.forEach(element => {
                    let obj = {
                        entityName: table, attributeName: element.fieldName, attributeHeader: element.fieldName, typeOfEntity: element.typeOfEntity,
                        entity: element.entityName, parentEntityName: element.parentEntityName, active: true, showOnTable: false, showPlus: true
                    };
                    reportTables.push(obj);
                });
            }

        const documentedDesciription = this.state.documentedDesciription;
        let doc = documentedDesciription.find(p => p.id === document.id);
        let element = doc.items.find(p => p.id === item.id);
        element.tableColumns = Boolean(reportTables) ? createColums(reportTables) : [];
        element.tableData = response.data;
        element.itemValue = table;
        element.reportTables = reportTables;
        console.log(reportTables);
        this.setState({
            documentedDesciription: documentedDesciription
        })
    }

    showDetailFields = async (d, document, item, i) => {

        let fields = await this.getEntityFields(d.entity);

        var reports = item.reportTables;
        var list = reports.filter(p => p.active === true);

        var s = list[i];
        var index = reports.findIndex(p => p.active === true && p.entityName === s.entityName && p.attributeName === s.attributeName && p.parentEntityName === s.parentEntityName);
        s.showPlus = false;

        if (Boolean(fields) && fields.length > 0) {

            fields.forEach((element, j) => {
                let obj = {
                    entityName: d.entityName, attributeName: d.attributeName + '.' + element.fieldName, attributeHeader: d.attributeName + '.' + element.fieldName, typeOfEntity: element.typeOfEntity,
                    entity: element.entityName, parentEntityName: element.parentEntityName, active: true, showOnTable: false, showPlus: true
                };
                reports.splice(index + j + 1, 0, obj);
            });
        }

        console.log(list);

        this.setState(prevState => ({
            documentedDesciription: prevState.documentedDesciription.map(
                el => el.id === document.id ? {
                    ...el,
                    items: el.items.map(p => p.id === item.id ? { ...p, reportTables: reports } : p)
                } : el
            )
        }))


    }

    hideDetailFiels = async (d, document, item, i) => {

        var sizeOfDeleteItems = 0;
        var reports = item.reportTables;
        var list = reports.filter(p => p.active === true);
        var s = list[i];
        var index = reports.findIndex(p => p.active === true && p.entityName === s.entityName && p.attributeName === s.attributeName && p.parentEntityName === s.parentEntityName);

        s.showPlus = true;
        var flagList = list.filter(p => p.parentEntityName === d.entity && p.attributeName.startsWith(d.attributeName + "."));
        if (Boolean(flagList)) {
            sizeOfDeleteItems = flagList.length;
        }

        reports.splice(index + 1, sizeOfDeleteItems);

        this.setState(prevState => ({
            documentedDesciription: prevState.documentedDesciription.map(
                el => el.id === document.id ? {
                    ...el,
                    items: el.items.map(p => p.id === item.id ? { ...p, reportTables: reports } : p)
                } : el
            )
        }))

    }

    handleChangeTypeOfReport = (e) => {
        this.setState({
            typeOfReport: e,
        });
    };


    createEditableTable = (document, item) => {

        let tblColumns = [{
            dataField: 'id',
            id: 0,
            lang: "",
            showOnLayout: false,
            text: 'ID',
            hidden: true,

            editable: false
        }];

        for (let index = 0; index < item.tblColumns; index++) {

            let col =
            {
                dataField: "" + index,
                id: item.id,
                index: index,
                lang: "",
                showOnLayout: false,
                text: 'Header' + index,
                headerStyle: {
                    fontWeight: 'bold',
                    textAlign: 'center'
                },
                editable: true,
                headerFormatter: (column, colIndex) => {

                    return <div style={{ marginBottom: "0.75rem" }} key="checkbox">
                        <InputText placeholder="Header" style={{ width: '100%', font: 'bold' }} id="header" value={column.text} onChange={(e) => {

                            let documentedDesciription = this.state.documentedDesciription;
                            let doc = documentedDesciription.find(p => p.id === document.id);
                            let editableTableColumns = doc.items.find(p => p.id === column.id).editableTableColumns;
                            let findedColumn = editableTableColumns[column.index + 1];
                            findedColumn.text = e.target.value;
                            this.setState(
                                {
                                    documentedDesciription: documentedDesciription
                                })

                        }} />

                    </div>

                }
            };

            tblColumns.push(col);
        }

        let table = [];
        for (let index = 0; index < item.tblRows; index++) {
            let obj = { id: index };
            for (let j = 0; j < item.tblColumns; j++) {

                obj["" + j] = "";

            }
            table.push(obj);

        }

        this.setState(prevState => ({
            documentedDesciription: prevState.documentedDesciription.map(
                el => el.id === document.id ? {
                    ...el,
                    items: el.items.map(p => p.id === item.id ? {
                        ...p, editableTableData: table,
                        editableTableColumns: tblColumns
                    } : p)

                } : el
            )
        }))


    };



    getImageStr = async (node) => {

        let imageStr = null;
        await domtoimage.toPng(node)
            .then(function (dataUrl) {
                imageStr = dataUrl;
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
        console.log(imageStr);
        return imageStr;
    }

    saveAll = async () => {
        const { documentedDesciription } = this.state;

        documentedDesciription.forEach((doc) => {

            doc.items.forEach((item) => {
                if (item.itemType === "chart") {
                    let can = document.getElementById('Chart' + doc.id + "" + item.id).childNodes[1];
                    if (Boolean(can)) {
                        let url_base64 = can.toDataURL('image/png');
                        item.itemImageStr = url_base64;
                    }
                }

                if (item.itemType === "staticTable") {
                    let node = document.getElementById('Table' + doc.id + "" + item.id);
                    //  console.log("IMAGE NODE", node);

                    item.itemImageStr = this.getImageStr(node);


                }

                if (item.itemType === "editableTable") {
                    this.setManualTable(item, item);
                }

                if (Boolean(item.oldReportTables) && item.oldReportTables.length > 0) {
                    if (Boolean(item.reportTables) && item.reportTables.length > 0) {
                        if (item.reportTables[0].entityName !== item.oldReportTables[0].entityName)
                            item.oldReportTables.forEach(element => {
                                element.active = false;
                                item.reportTables.push(element);

                            });
                    }
                    else {
                        item.oldReportTables.forEach(element => {
                            element.active = false;
                            item.reportTables.push(element);

                        });
                    }

                }



            });

        });

        console.log(documentedDesciription);
        setTimeout(async () => {
            let response = await handleRequest("POST", "/DocumentedReportCreator/saveAll", documentedDesciription);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess();
                }
                else {
                    this.showError();
                }
            }
        }, 500);


    }

    getId = (row, column, list) => {

        var id = 0;
        if (Boolean(list) && list.length > 0) {
            let result = list.find(p => p.rowId === row && p.columnId === column);
            if (Boolean(result)) {
                result.active = true;
                return result.id;

            }
        }

        return id;
    }

    getManualColumns = (document, manualTables, id) => {

        if (Boolean(manualTables) && manualTables.length > 0) {

            let tblColumns = [{
                dataField: 'id',
                id: 0,
                lang: "",
                showOnLayout: false,
                text: 'ID',
                hidden: true,

                editable: false
            }];
            let headerList = manualTables.filter(p => p.rowId === 0);
            for (let index = 0; index < headerList.length; index++) {

                let col =
                {
                    dataField: "" + index,
                    id: id,
                    index: index,
                    lang: "",
                    showOnLayout: false,
                    text: headerList[index].description,
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: true,
                    headerFormatter: (column, colIndex) => {

                        return <div style={{ marginBottom: "0.75rem" }} key="checkbox">
                            <InputText placeholder="Header" style={{ width: '100%', font: 'bold' }} id="header" value={column.text} onChange={(e) => {

                                let documentedDesciription = this.state.documentedDesciription;
                                let doc = documentedDesciription.find(p => p.id === document.id);
                                let editableTableColumns = doc.items.find(p => p.id === column.id).editableTableColumns;
                                let findedColumn = editableTableColumns[column.index + 1];
                                findedColumn.text = e.target.value;
                                this.setState(
                                    {
                                        documentedDesciription: documentedDesciription
                                    })
                            }} />

                        </div>

                    }
                };

                tblColumns.push(col);
            }

            return tblColumns;
        }

        return [];
    }

    getManualTableData = (rowCount, manualTables) => {
        let table = [];
        if (Boolean(manualTables) && manualTables.length > 0)
            for (let index = 0; index < rowCount; index++) {
                let element = { id: index };
                let row = manualTables.filter(p => p.rowId === index + 1);
                for (let j = 0; j < row.length; j++) {

                    element["" + j] = row[j].description;

                }
                table.push(element);

            }

        return table;
    }

    setManualTable = (rowData, item) => {

        if (Boolean(rowData.manualTables)) {
            rowData.manualTables.forEach(element => {
                element.active = false;
            });
        }
        else item.manualTables = [];

        if (Boolean(rowData.editableTableColumns) && rowData.editableTableColumns.length > 0 &&
            Boolean(rowData.editableTableData) && rowData.editableTableData.length > 0 &&
            Boolean(rowData.tblRows) && rowData.tblRows > 0 &&
            Boolean(rowData.tblColumns) && rowData.tblColumns > 0) {

            for (let j = 0; j < rowData.tblColumns; j++) {

                let element = rowData.editableTableColumns[j + 1];
                let obj = {
                    rowId: 0, columnId: j, description: element.text, id: this.getId(0, j, rowData.manualTables)
                }
                item.manualTables.push(obj);

            }

            for (let index = 0; index < rowData.tblRows; index++) {
                let element = rowData.editableTableData[index];
                for (let j = 0; j < rowData.tblColumns; j++) {

                    let obj = {
                        rowId: index + 1, columnId: j, description: element["" + j], id: this.getId(index + 1, j, rowData.manualTables)
                    }
                    item.manualTables.push(obj);
                }


            }

        }

        if (Boolean(rowData.manualTables) && rowData.manualTables.length > 0) {
            let willBeDeletedList = rowData.manualTables.filter(p => p.active === false);
            if (Boolean(willBeDeletedList))
                willBeDeletedList.forEach(element => {
                    item.manualTables.push(element);
                });
        }

    }

    raporCreate = async () => {
        const { documentedInfoId, type } = this.state;
       

        const response = await handleRequest("POST", "/RaporController/raporCreate/" + documentedInfoId, type);

        if (response.data.url !== null && response.data.url !== undefined && response.data.url !== "") {
            console.log(response.data);
            this.setState({

                pdfFile: response.data.url,
                modalPdfViewer: true,
            });
        } else {
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
        }

    }

    showAttachment = async (url) => {

        if (Boolean(url)) {
            if (url.toLowerCase().endsWith("pdf"))
                this.setState({
                    pdfFile: url,
                    modalPdfViewer: true,
                });
            else this.setState({
                imageUrl: url,
                modalPhotoShow: true,
            });
        } else {
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
        }

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages
        });
        //setNumPages(numPages);
    }

    onChangeTreeSelect = (value, label, extra) => {



        this.setState({
            linkedItemId: value

        });
    };

    showLinkedItem = async (id) => {

        var response = await handleRequest("GET", "/DocumentedReportItem/" + id);
        if (response.data !== null && response.data !== undefined) {

            this.setState({
                modalLinkedItemText: true,
                linkedItemText: response.data.itemValue
            })

        } else {
            this.showError();
        }
    }


    render() {
        const ref = React.createRef();

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 10 },
        };


        const columns = [
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },
            {

                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
                render: (value, row, index) => {
                    return index + 1;
                }

            },
            {
                dataIndex: "itemNo",
                key: "key",
                title: <FormattedMessage id="DCItemNo" defaultMessage="Item No" />,
            },
            {
                dataIndex: "description",
                key: "key",
                title: <FormattedMessage id="DCDescription" defaultMessage="Description" />,
            },
            {
                dataIndex: "pageNo",
                key: "key",
                title: <FormattedMessage id="DCPageNo" defaultMessage="Page No" />,
            },
        ];

        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        return <div>
            {
                <Form
                    {...layout}
                    initialValues={{ remember: false }}
                    onFinish={this.state.hiddenUpdate === false ? this.updateDocumentedCreator : this.saveDocumentedCreator}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef} >

                    <br />
                    <div className="mb5">
                        <AntdButton onClick={this.raporCreate} type="primary"> <FormattedMessage id="DCExportReportButton"
                            defaultMessage="Export Report" />
                        </AntdButton>
                    </div>

                    <br />
                    <div>




                        <div >
                            <br />
                            <br />
                            <h5 ><FormattedMessage id="DCTableOfContentsFormatHeader" defaultMessage="Table Of Contents" /></h5>
                            <Card>
                                {/* style={{background: "#1abdef", height: "50px", color: "white"}}x */}


                                {
                                    <Form.Item
                                        label={<FormattedMessage id="DCItemNo" defaultMessage="Item No" />}
                                        name="ItemNo"
                                    >
                                        <Input id="ItemNo" value={this.state.itemNo} onChange={(e) => this.setState({ itemNo: e.target.value })} />
                                    </Form.Item>}

                                {
                                    <Form.Item
                                        label={<FormattedMessage id="DCDescription" defaultMessage="Description" />}
                                        name="Description" >
                                        <Input id="Description" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
                                    </Form.Item>}
                            </Card>
                            <br />
                            <br />

                            <Card>
                                <div hidden={this.state.hiddenSave}>
                                    {
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="5">
                                                <ReactButton id="DocumentedCreatorSaveButton"
                                                    style={{ width: '100%' }} type="submit"
                                                    variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="1">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2"></Col>
                                            <Col sm="2">
                                                <ReactButton id="DocumentedCreatorCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="DocumentedCreatorUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="1">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <br />
                                <Row>
                                    <Col xs={11}></Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>
                                {<Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumns} dataSource={this.state.documentedCreators}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll === "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />}
                            </Card>
                            <br />
                            <br />

                            <div id={"divToExport"}>
                                {
                                    this.state.documentedDesciription.map((document) =>
                                        <div>
                                            <br />
                                            <br />
                                            <h5> {document.itemNo + " " + document.description} </h5>
                                            {/* <Card>  */}

                                            <div style={this.state.hiddenPdfGenerate === false ? { padding: "10px", border: '1px solid #1ab394' } : { padding: "10px" }}>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="4">
                                                    </Col>
                                                    <Col sm="3">
                                                        <Select style={{ width: "100%" }} placeholder="Size" value={document.itemType} onChange={(value) => {

                                                            this.setState(prevState => ({
                                                                documentedDesciription: prevState.documentedDesciription.map(
                                                                    el => el.id === document.id ? {
                                                                        ...el,
                                                                        itemType: value,
                                                                    } : el
                                                                )
                                                            }))
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            <Option key="text" value="text">Text</Option>
                                                            <Option key="image" value="image">Image</Option>
                                                            <Option key="table" value="table">Table</Option>
                                                            <Option key="editableTable" value="editableTable">Editable Table</Option>
                                                            <Option key="staticTable" value="staticTable">Static Table</Option>
                                                            <Option key="chart " value="chart">Chart</Option>
                                                            <Option key="extraPages" value="extraPages">Extra Pages</Option>
                                                            <Option key="attachment" value="attachment">Attachment</Option>
                                                        </Select>
                                                    </Col>

                                                    <Col sm="1">
                                                        <AntdButton type="primary" onClick={() => this.addItemContent(document)} icon={<PlusOutlined />}></AntdButton>
                                                    </Col>

                                                </FormBoot.Group>

                                                {document.items.map((item) =>
                                                    this.renderElement(document, item, props)
                                                )}


                                                <br /><br />

                                            </div>
                                        </div>
                                    )}
                                {Boolean(this.state.documentedDesciription) && this.state.documentedDesciription.length > 0 && <FormBoot.Group hidden={this.state.hiddenPdfGenerate} style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="8">
                                        {
                                            <ReactButton id="DocumentedCreatorSaveButton"
                                                style={{ width: '100%' }} onClick={() => this.saveAll()}
                                                variant="success"><FormattedMessage id="GeneralButtonSave"
                                                    defaultMessage="Save All" />
                                            </ReactButton>
                                        }
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>}
                                <br />
                                <br />
                            </div>

                        </div>

                    </div>

                </Form>
            }

            <Modal
                show={this.state.modalMessage}
                onHide={this.showOrHideModalDeleteMessage}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Row >
                            <Col sm="12">
                                {this.state.messageTip === "Success" &&
                                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                {this.state.messageTip === "Warning" &&
                                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                {this.state.messageTip === "Error" &&
                                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row >
                        <Col sm="12">
                            {this.state.message}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.deleteModal}
                onHide={this.showOrHideModalDelete}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                    </p>
                    <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </ReactButton>
                    <ReactButton variant="danger" onClick={this.deleteDocumentedCreatorOK}>
                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                    </ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.modalTable}
                onHide={this.showOrHideModalTable}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered size="lg">

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Row >
                            <Col sm="12">
                                <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row >
                        <Col sm="12">
                            <MNDTableSettings id="documentedCreatorTable" />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                size="xl"
                show={this.state.modalPdfViewer}
                onHide={this.showOrHideModalPdfViewer}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PDF Report Viewer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                        <div style={{ height: "900px" }}>
                            <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                        </div>
                    </Worker>
                    {/* <PDFViewer pdfFile = {this.state.pdfFile}></PDFViewer>  */}

                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalPdfViewer}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.modalPhotoShow}
                onHide={() => {
                    this.setState({
                        modalPhotoShow: false
                    })
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Boolean(this.state.imageUrl) && this.state.imageUrl !== "" ? (
                        <img
                            src={this.state.imageUrl}
                            alt="avatar" l
                            style={{ width: "100%" }}
                        />
                    ) : (
                        <p>Photo has not been uploaded.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="info" onClick={() => {
                        this.setState({
                            modalPhotoShow: false
                        })
                    }} >
                        Close
                    </ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.modalLinkDocument}
                onHide={() => {
                    this.setState({
                        modalLinkDocument: false
                    })
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Link Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TreeSelect
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        value={this.state.linkedItemId}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={this.state.treeData}
                        placeholder="Please select"
                        treeDefaultExpandAll
                        onChange={this.onChangeTreeSelect}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="info" onClick={() => {
                        this.setState({
                            modalLinkDocument: false
                        })
                    }} >
                        Close
                    </ReactButton>

                    <ReactButton variant="success" onClick={this.addLink} >
                        Link
                    </ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.modalLinkedItemText}
                onHide={() => {
                    this.setState({
                        modalLinkedItemText: false,
                        linkedItemText:null,
                    })
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Linked Text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: "center", color: "black" }}>
                       {this.state.linkedItemText}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="info" onClick={() => {
                        this.setState({
                            modalLinkedItemText: false,
                        linkedItemText:null,
                        })
                    }} >
                        Close
                    </ReactButton>
                    
                </Modal.Footer>
            </Modal>

        </div>

    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentedCreator)