
/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button as PrimeButton } from "primereact/button";
import { Card, Button } from 'react-bootstrap';
import { Container, Col, Form, Row, Modal } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import "../../pages/settings/css/DataTable.scss";
import $ from 'jquery';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { Table, Input, Checkbox, Upload, Button as AntdButton, Select, InputNumber, Cascader, Radio } from 'antd';
import { UploadOutlined, PlusOutlined, UndoOutlined, CloseCircleOutlined, RightCircleOutlined, CloseOutlined } from '@ant-design/icons';

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.value, lineage: node.data.lineage });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

const alphabetUpper = [
  {
    aIndex: 1,
    letter: "A",
  },
  {
    aIndex: 2,
    letter: "B",
  },
  {
    aIndex: 3,
    letter: "C",
  },
  {
    aIndex: 4,
    letter: "D",
  },
  {
    aIndex: 5,
    letter: "E",
  },
  {
    aIndex: 6,
    letter: "F",
  },
  {
    aIndex: 7,
    letter: "G",
  },
  {
    aIndex: 8,
    letter: "H",
  },
  {
    aIndex: 9,
    letter: "I",
  },
  {
    aIndex: 10,
    letter: "J",
  },
  {
    aIndex: 11,
    letter: "K",
  },
  {
    aIndex: 12,
    letter: "L",
  },
  {
    aIndex: 13,
    letter: "M",
  },
  {
    aIndex: 14,
    letter: "N",
  },
  {
    aIndex: 15,
    letter: "O",
  },
  {
    aIndex: 16,
    letter: "P",
  },
  {
    aIndex: 17,
    letter: "Q",
  },
  {
    aIndex: 18,
    letter: "R",
  },
  {
    aIndex: 19,
    letter: "S",
  },
  {
    aIndex: 20,
    letter: "T",
  },
  {
    aIndex: 21,
    letter: "U",
  },
  {
    aIndex: 22,
    letter: "V",
  },
  {
    aIndex: 23,
    letter: "W",
  },
  {
    aIndex: 24,
    letter: "X",
  },
  {
    aIndex: 25,
    letter: "Y",
  },
  {
    aIndex: 26,
    letter: "Z",
  },
];

const _DurationTypes = [
  { label: "Day", value: 3 },
  { label: "Hour", value: 4 },
  { label: "Minute", value: 5 },
];

class MaintenanceChecklist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hiddenSave: false,
      hiddenUpdate: true,
      tablehidden: false,
      createNewButton: "Create New",
      createNewButtonActivity: "Create New",
      hiddenAmendDocument: false,
      lastButton: "Save",
      createNewButtonVariant: "info",
      createNewButtonActivityVariant: "info",

      discipline: "",
      disciplineVal: 0,

      itpname: "",
      itpcode: "",
      approvaldate: "",
      approvalDateVal: null,
      revno: 0,

      legendAddItems: false,
      activityAddItems: false,
      datatableValue: [],

      documentLogid: -1,

      itemNo: 1,
      maintenancePlanTreeTable: [],
      id: -1,

      popup: false,
      messageTip: "",

      itemGroup: false,
      hiddenItemGroup: false,
      redirect: false,
      selectMaintenanceChecklistLog: [],
      MaintenanceChecklistLogTreeTableKayit: 0,
      PhotoFileUpload: [],
      photoFilePath: "",
      fileList: [],
      disciplines: [],
      equipments: [],
      MaintChecklistCriterias: [],
      MaintChecklistAcceptances: [],
      MaintChecklistActions: [],
      durationObj: {},
      duration: '',
      previousDuration: '',
      durationNumber: 0,
      durationType: null,
      durationTypes: _DurationTypes.slice(),

      MaintenanceChecklistItem: [],
      MaintenanceChecklistItemKayit: 0,
      hiddenDataGiris: false,
      hiddenDocument: false,
      parentId: 0,
      dtNodes: [],
      values: [],

      itemGroupTypeHidden: true,
      itemNoHidden: true,
      itemGroupNameHidden: true,
      letterItemGroupIndex: 0,
      itemGroupType: "number",
      itemGroupTypeNumber: 0,

    }

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  componentDidMount = async () => {
    await this.deleteMaintenanceChecklistLogTemp();

    this.restartTable();

    $(".p-filter-column").on("change paste keyup", function () {

      $("tr[id^='idd']").remove();
    });

    $(".p-sortable-column").on("click", function () {

      $("tr[id^='idd']").remove();
    });
  }

  restartTable = async () => {
    var deger = 0;
    if (this.props.location != null && this.props.location != undefined && this.props.location != "") {
      deger = this.props.location.pathname.split('/')[2];
      this.setState({
        popup: false
      });
    } else if (this.props.id != null && this.props.id != 0 && this.props.id != "") {
      deger = this.props.id;
      this.setState({
        popup: true
      });
    } else {
      deger = -1;
      this.setState({
        popup: true
      });
    }
    await this.setState({
      documentLogid: deger,
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenAmendDocument: false,
    });

    if (deger > 0) {
      this.getMaintenanceChecklistLog(deger);
    } else {
      this.getMaintenanceChecklistLogTemp();
    }
    this.createNewClientButton();
    this.getDocumentLog(deger)
    this.getCodesMaintChecklistCriteria();
    this.getCodesMaintChecklistAcceptance();
    this.getCodesMaintChecklistAction();
    this.getDiscipline();
    this.getEquipment();
    this.getTableProperties();
    this.getParentItemGroup();
  }

  deleteMaintenanceChecklistLogTemp = async () => {
    await handleRequest("GET", "/MaintenanceChecklistLog/deleteTemp");
  };

  getMaintenanceChecklistLog = async (deger) => {
    if (deger == null || deger == undefined || deger == 0) {
      deger = -1;
    }
    const newObj = {
      documentLogid: deger
    }
    const responseTree = await handleRequest("POST", "/MaintenanceChecklistLog/getTreeMaintenanceChecklistLog", newObj);
    console.log("AAA", responseTree.data);
    if (responseTree.data.length !== 0 && responseTree.data.treeTable.length !== 0) {

      responseTree.data.treeTable.forEach(record => {
        this.deleteChildren(record);
      });
      this.setState({
        maintenancePlanTreeTable: responseTree.data.treeTable,
        MaintenanceChecklistLogTreeTableKayit: responseTree.data.treeTable[responseTree.data.treeTable.length - 1].data.key,
      });
    } else {
      this.setState({
        maintenancePlanTreeTable: [],
        MaintenanceChecklistLogTreeTableKayit: 0,
      });
    }
  }

  getMaintenanceChecklistLogTemp = async () => {
    const responseTree = await handleRequest("GET", "/MaintenanceChecklistLogTemp/getTreeMaintenanceChecklistLogTemp");
    console.log("AAA", responseTree.data);
    if (responseTree.data.length !== 0 && responseTree.data.treeTable.length !== 0) {

      responseTree.data.treeTable.forEach(record => {
        this.deleteChildren(record);
      });
      this.setState({
        maintenancePlanTreeTable: responseTree.data.treeTable,
        MaintenanceChecklistLogTreeTableKayit: responseTree.data.treeTable[responseTree.data.treeTable.length - 1].data.key,
      });
    } else {
      this.setState({
        maintenancePlanTreeTable: [],
        MaintenanceChecklistLogTreeTableKayit: 0,
      });
    }
  }

  getTableProperties = async () => {
    this.MaintenanceChecklistLog = JSON.parse(localStorage.getItem('MaintenanceChecklistLog'));
    if (this.MaintenanceChecklistLog != null && this.MaintenanceChecklistLog != undefined && this.MaintenanceChecklistLog != "") {

      await this.setState({
        bordered: this.MaintenanceChecklistLog.bordered,
        title: this.MaintenanceChecklistLog.titleDrm == true ? title : undefined,
        titleDrm: this.MaintenanceChecklistLog.titleDrm,
        showHeader: this.MaintenanceChecklistLog.showHeader,
        footer: this.MaintenanceChecklistLog.footerDrm == true ? footer : undefined,
        footerDrm: this.MaintenanceChecklistLog.footerDrm,
        expandable: this.MaintenanceChecklistLog.expandableDrm == true ? expandable : {},
        expandableDrm: this.MaintenanceChecklistLog.expandableDrm,
        rowSelection: this.MaintenanceChecklistLog.rowSelection,
        ellipsis: this.MaintenanceChecklistLog.ellipsis,
        tableLayout: this.MaintenanceChecklistLog.tableLayout,
        size: this.MaintenanceChecklistLog.size,
        top: this.MaintenanceChecklistLog.top,
        bottom: this.MaintenanceChecklistLog.bottom,
        editBgColor: this.MaintenanceChecklistLog.editBgColor,
        deleteBgColor: this.MaintenanceChecklistLog.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.MaintenanceChecklistLog.yScroll,
        xScroll: this.MaintenanceChecklistLog.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.maintenancePlanTreeTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.maintenancePlanTreeTable.length, showQuickJumper: true,
          position: [this.MaintenanceChecklistLog.top, this.MaintenanceChecklistLog.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  deleteChildren = (element) => {
    if (Boolean(element.children) && element.children.length > 0) {
      element.children.forEach(el => {
        this.deleteChildren(el);
      });
    } else {
      delete element["children"];
    }
  }

  getDocumentLog = async (deger) => {
    const newObj = {
      id: deger
    }
    const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj);
    if (response.data.length !== 0) {
      var revno = 0;
      var documentCode = "";
      var maintenanceDescription = "";
      if (this.state.hiddenAmendDocument == true) {
        if (this.state.popup == false) {
          revno = response.data[0].revno;
        } else {
          revno = response.data[0].revno + 1;
        }
      } else {
        revno = response.data[0].revno;
      }
      documentCode = response.data[0].code;
      maintenanceDescription = response.data[0].description;
      this.setState({
        hiddenDocument: false,
        revno: revno,
        documentCode: documentCode,
        maintenanceDescription: maintenanceDescription,
      });
    }
    else {
      this.setState({
        hiddenDocument: true,
        revno: 0,
        documentCode: "",
        maintenanceDescription: "",
      });
    }
  }

  getDiscipline = async () => {
    const response = await handleRequest("GET", "/Discipline/getallByCompany");
    if (response.data.length !== 0) {
      this.setState({ disciplines: response.data });
    } else { this.setState({ disciplines: [] }); }
  }

  getEquipment = async () => {
    const response = await handleRequest("GET", "/EquipmentLog/getAll");
    if (response.data.length !== 0) {
      this.setState({ equipments: response.data });
    } else { this.setState({ equipments: [] }); }
  }

  getParentItemGroup = async () => {
    var responseNodes = [];
    if (this.state.documentLogid > 0) {
      var newObj = {
        documentLogid: this.state.documentLogid,
      }
      responseNodes = await handleRequest("POST", "/MaintenanceChecklistLog/getNodesMaintenanceChecklistLogs", newObj);
    } else {
      responseNodes = await handleRequest("GET", "/MaintenanceChecklistLogTemp/getNodesMaintenanceChecklistLogTemps", newObj);
    }
    console.log("BBB", responseNodes.data);
    if (responseNodes.data.length !== 0) {
      generateList(responseNodes.data);
      this.setState({
        dtNodes: responseNodes.data
      });
    } else {
      this.setState({
        dtNodes: []
      });
    }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);

    if (this.state.redirect && this.state.popup == false && (!(this.state.documentLogid != null && this.state.documentLogid != undefined && this.state.documentLogid > 0))) {
      this.setState({
        redirect: false,
      });
      window.location.href = "/DocumentLog";
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  resetInputs = () => {
    this.setState({
      selectMaintenanceChecklistLog: [],
      MaintenanceChecklistItem: [],
      itemNo: "",
      itemGroup: false,
      hiddenItemGroup: false,

      itemDescription: "",
      photoFilePath: "",
      typeOfCheck: "",

      byId: "",
      byIdAck: "",
      device: "",
      deviceAck: "",
      criteria: "",
      criteriaAck: "",
      acceptance: "",
      acceptanceAck: "",
      action: "",
      actionAck: "",

      durationObj: {},
      duration: '',
      previousDuration: '',
      durationNumber: 0,
      durationType: null,
      durationTypes: _DurationTypes.slice(),

      itemGroupTypeHidden: true,
      itemNoHidden: true,
      itemGroupNameHidden: true,
      letterItemGroupIndex: 0,
      itemGroupType: "number",
      itemGroupTypeNumber: 0,
      parentId: 0,
      values: [],
      photoFilePath: "",
      PhotoFileUpload: [],
    });
  }

  createNewClientButton = () => {
    this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
    }
    else {
      this.setState({
        createNewButton: "Create New",
        createNewButtonActivity: "Create New",
        activityAddItems: false,
        lastButton: "Save",
        createNewButtonVariant: "info",
        createNewButtonActivityVariant: "info"

      });
    }
  }

  addActivityItem = () => {
    this.state.MaintenanceChecklistItem.forEach(element => {
      this.setState(state => ({
        MaintenanceChecklistLogTreeTableKayit: this.state.MaintenanceChecklistLogTreeTableKayit + 1,
      }));
      this.setState(prevState => ({
        maintenancePlanTreeTable: [...prevState.maintenancePlanTreeTable, { key: this.state.MaintenanceChecklistLogTreeTableKayit, data: element }]
      }));
    });
  }

  addActivityShow = () => {
    this.resetInputs();
    if (this.state.createNewButtonActivity == "Create New") {
      this.setState({
        createNewButtonActivity: "Cancel",
        createNewButtonActivityVariant: "danger",
        activityAddItems: true,
        hiddenSave: false,
        hiddenUpdate: true,
      });
    } else {
      this.setState({
        createNewButtonActivity: "Create New",
        createNewButtonActivityVariant: "info",
        activityAddItems: false,
        hiddenSave: true,
        hiddenUpdate: true,
      });
    }
  }

  addMaintenanceChecklistItems = async () => {
    var itemNoKnt = true;
    if (this.state.itemGroup) {
      if (!(this.state.itemNo != null && this.state.itemNo != undefined && this.state.itemNo != "")) {
        itemNoKnt = false;
      }
    }
    if (itemNoKnt) {
      if (this.state.itemDescription != null && this.state.itemDescription != undefined && this.state.itemDescription != "") {
        console.log(this.state.MaintenanceChecklistItem);
        if (Boolean(this.state.MaintenanceChecklistItem) && this.state.MaintenanceChecklistItem != null && this.state.MaintenanceChecklistItem != undefined && this.state.MaintenanceChecklistItem.length > 0) {
          this.state.MaintenanceChecklistItem.forEach(element => {
            element.itemGroup = this.state.itemGroup;
            element.itemNo = this.state.itemNo;
            element.parentId = this.state.parentId;
            element.itemDescription = this.state.itemDescription;
            element.photoFilePath = this.state.photoFilePath;
            element.typeOfCheck = this.state.typeOfCheck;
          });
          var response = await handleRequest("POST", "/MaintenanceChecklistLog/saveAll", this.state.MaintenanceChecklistItem);
          if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
              this.restartTable();
              this.resetInputs();
              this.showSuccess();
              this.createNewClientButton();
            }
            else {
              this.showError();
            }
          }
        } else {
          const addMaintenance = {
            key: this.state.MaintenanceChecklistItemKayit,
            index: this.state.MaintenanceChecklistItemKayit,
            id: 0,
            itemNo: this.state.itemNo,
            itemGroup: this.state.itemGroup,
            itemDescription: this.state.itemDescription,
            photoFilePath: this.state.photoFilePath,
            typeOfCheck: this.state.typeOfCheck,
            parentId: this.state.parentId,

            byId: this.state.byId,
            byIdAck: this.state.byIdAck,
            device: this.state.device,
            deviceAck: this.state.deviceAck,
            criteria: this.state.criteria,
            criteriaAck: this.state.criteriaAck,
            acceptance: this.state.acceptance,
            acceptanceAck: this.state.acceptanceAck,
            action: this.state.action,
            actionAck: this.state.actionAck,
            duration: this.state.duration,
            durationAck: this.state.duration,
            documentLogid: this.state.documentLogid,
            durationTime: this.state.durationObj
          }
          console.log("ADD", addMaintenance);
          var response = await handleRequest("POST", "/MaintenanceChecklistLog/add", addMaintenance);
          if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
              this.restartTable();
              this.resetInputs();
              this.showSuccess();
              this.createNewClientButton();
            }
            else {
              this.showError();
            }
          }
        }
      } else {
        this.showWarning(<FormattedMessage id="MaintenanceChecklistLogDescriptionMessage1" defaultMessage="Please input your Item Description" />);
      }
    } else {
      this.showWarning(<FormattedMessage id="MaintenanceChecklistLogItemNoMessage1" defaultMessage="Please input your Item No" />);
    }
  }

  updateDone = async () => {
    const updatedMaintenance = {
      id: this.state.selectMaintenanceChecklistLog.id,

      parentId: this.state.parentId,
      itemNo: this.state.itemNo,
      itemGroup: this.state.itemGroup,
      itemDescription: this.state.itemDescription,
      documentLogid: this.state.documentLogid,

      photoFilePath: this.state.photoFilePath,
      typeOfCheck: this.state.typeOfCheck,

      byId: this.state.byId,
      byIdAck: this.state.byIdAck,
      device: this.state.device,
      deviceAck: this.state.deviceAck,
      criteria: this.state.criteria,
      criteriaAck: this.state.criteriaAck,
      acceptance: this.state.acceptance,
      acceptanceAck: this.state.acceptanceAck,
      action: this.state.action,
      actionAck: this.state.actionAck,
      duration: this.state.durationAck,
      durationAck: this.state.durationAck,
      documentLogid: this.state.documentLogid,
      durationTime: this.state.durationObj,
      durationId: this.state.durationId,
    }
    console.log("UPD", updatedMaintenance);

    if (this.state.documentLogid > 0) {
      var response = await handleRequest("POST", "/MaintenanceChecklistLog/update", updatedMaintenance);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    } else {
      var response = await handleRequest("POST", "/MaintenanceChecklistLogTemp/update", updatedMaintenance);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    }
  };

  saveMaintenanceChecklistLog = async () => {
    var maintenancePlans = [];
    this.state.maintenancePlanTreeTable.forEach(element => {
      if (element.data != null && element.data != undefined) {
        maintenancePlans.push(element.data);
      }
    });
    const response = await handleRequest("POST", "/MaintenanceChecklistLog/saveAll", maintenancePlans);
    if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        if (this.state.popup == false) {
          this.setState({
            redirect: true,
          });
        } else {
          this.setState({
            redirect: false,
          });
        }
        if (!(this.state.documentLogid != null && this.state.documentLogid != undefined && this.state.documentLogid > 0)) {
          this.setState({
            hiddenDataGiris: true,
          });
        } else {
          this.setState({
            hiddenDataGiris: false,
          });
        }
        this.createNewClientButton();
        await this.setState({
          hiddenSave: false,
          hiddenUpdate: true,
          hiddenAmendDocument: false,
        });

        if (this.state.documentLogid > 0) {
          this.getMaintenanceChecklistLog(this.state.documentLogid);
        } else {
          this.getMaintenanceChecklistLogTemp();
        }

        this.showSuccess();
      } else {
        this.showError();
      }
    } else {
      this.showError();
    }
  }

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }

  actionTemplate(rowData, column) {
    return <React.Fragment>
      <CustomMaterialMenu row={rowData}
        onDeleteRow={this.changeButtonDeleteModal.bind(this)}
        onEditRow={this.changeButtonUpdate.bind(this)} />
    </React.Fragment>;
  };

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonDeleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectMaintenanceChecklistLog: row.data
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  deleteMaintenanceChecklistLogOK = async () => {
    if (this.state.selectMaintenanceChecklistLog != null && this.state.selectMaintenanceChecklistLog != undefined && this.state.selectMaintenanceChecklistLog != "" && this.state.selectMaintenanceChecklistLog.key > 0) {
      const deleteMaintenance = {
        id: this.state.selectMaintenanceChecklistLog.id
      }
      if (this.state.documentLogid > 0) {
        var response = await handleRequest("POST", "/MaintenanceChecklistLog/delete", deleteMaintenance);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.restartTable();
            this.showSuccess();
            this.showOrHideModalDelete();
          }
          else {
            this.showError();
          }
        }
      }
      else {
        var response = await handleRequest("POST", "/MaintenanceChecklistLogTemp/delete", deleteMaintenance);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.restartTable();
            this.showSuccess();
            this.showOrHideModalDelete();
          }
          else {
            this.showError();
          }
        }
      }
    } else {
      this.showWarning();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  changeButtonUpdate = (rowData) => {
    console.log("RRR", rowData);

    this.setDuration(rowData.data.durationTime);
    const list = this.state.maintenancePlanTreeTable.filter(p => p.id === rowData.data.id);
    let index = list.findIndex(p => p.id === rowData.data.id);
    let str = '';
    if (index > 0) {
      if (Boolean(list[index - 1].durationTime))
        str = this.getDurationDesc(list[index - 1].durationTime);
    }

    var hiddenItemGroup = false;
    if (rowData.data.itemGroup) {
      hiddenItemGroup = true;
    }

    var deger = dataList.filter(p => p.key == rowData.data.parentId)
    if (deger != null && deger != 'undefined' && deger != '') {
      this.setState({
        values: deger[0].lineage
      });
    } else {
      this.setState({
        values: []
      });
    }

    this.setState({
      hiddenUpdate: false,
      hiddenSave: true,
      hiddenItemGroup: hiddenItemGroup,

      selectMaintenanceChecklistLog: rowData.data,
      MaintenanceChecklistItemKayit: rowData.data.id,
      MaintenanceChecklistItem: [],
      parentId: rowData.data.parentId,
      itemNo: rowData.data.itemNo,
      itemGroup: rowData.data.itemGroup,
      itemDescription: rowData.data.itemDescription,
      documentLogid: rowData.data.documentLogid,

      photoFilePath: rowData.data.photoFilePath,
      typeOfCheck: rowData.data.typeOfCheck,

      byId: rowData.data.byId,
      byIdAck: rowData.data.byIdAck,
      device: rowData.data.device,
      deviceAck: rowData.data.deviceAck,
      criteria: rowData.data.criteria,
      criteriaAck: rowData.data.criteriaAck,
      acceptance: rowData.data.acceptance,
      acceptanceAck: rowData.data.acceptanceAck,
      action: rowData.data.action,
      actionAck: rowData.data.actionAck,
      documentLogid: rowData.data.documentLogid,

      durationId: rowData.data.durationId,
      previousDuration: str,

      // durationObj: {},
      // duration: "",
      // durationNumber: "",
      // durationTypes: _DurationTypes.slice(),
      // durationType: null,

      createNewButtonActivity: "Cancel",
      createNewButtonActivityVariant: "danger",
      activityAddItems: true,
    });
  };

  changeButtonCancel = () => {
    this.setState({
      hiddenUpdate: true,
      hiddenSave: true,
      createNewButtonActivity: "Create New",
      createNewButtonActivityVariant: "info",
      activityAddItems: false,
    });
  };

  findMaintenancePlanTreeTable = (element) => {
    if (element.key == this.state.selectMaintenanceChecklistLog.key) {
      return element;
    } else {
      if (Boolean(element.children) && element.children.length > 0) {
        var knt = true;
        var MaintenanceChecklistLog = null;
        element.children.forEach(children => {
          if (knt) {
            if (children.key == this.state.selectMaintenanceChecklistLog.key) {
              knt = false;
              MaintenanceChecklistLog = children;
            } else {
              if (!(children != null && children != undefined && children.data != null && children.data != undefined)) {
                children.forEach(el => {
                  this.findMaintenancePlanTreeTable(el);
                });
              } else {
                // this.findMaintenancePlanTreeTable(children);
              }
            }
          }
        });
        return MaintenanceChecklistLog;
      } else {
        // this.findMaintenancePlanTreeTable(element);
      }
    }
  }

  onChangeBy = (value) => {
    var deger = "";
    for (var z = 0; z < this.state.disciplines.length; z++) {
      if (this.state.disciplines[z].id == value) {
        deger = this.state.disciplines[z].disciplineName;
      }
    }
    this.setState({
      byIdAck: deger,
      byId: value,
    });
  }

  onChangeDevice = (value) => {
    var deger = "";
    for (var z = 0; z < this.state.equipments.length; z++) {
      if (this.state.equipments[z].id == value) {
        deger = this.state.equipments[z].equipmentCode + "-" + this.state.equipments[z].equipmentDescription;
      }
    }
    this.setState({
      deviceAck: deger,
      device: value,
    });
  }

  handleChangeMaintChecklistCriteria = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.MaintChecklistCriterias.length; z++) {
      if (this.state.MaintChecklistCriterias[z].id == e) {
        deger = this.state.MaintChecklistCriterias[z].name;
      }
    }
    this.setState({
      criteriaAck: deger,
      criteria: e,
    });
  };

  addMaintChecklistCriterias = async () => {
    const { itemMaintChecklistCriterias } = this.state;
    if (itemMaintChecklistCriterias != null && itemMaintChecklistCriterias != "" && itemMaintChecklistCriterias != undefined) {
      const newItem = {
        name: itemMaintChecklistCriterias,
        type: "MaintChecklistCriteria"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemMaintChecklistCriterias: '',
      });
      this.getCodesMaintChecklistCriteria();
    } else {
      this.showWarning();
    }
  };

  getCodesMaintChecklistCriteria = async () => {
    const response = await handleRequest("GET", "/Code/type/MaintChecklistCriteria");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        MaintChecklistCriterias: response.data
      });
    }
  }

  handleChangeMaintChecklistAcceptance = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.MaintChecklistAcceptances.length; z++) {
      if (this.state.MaintChecklistAcceptances[z].id == e) {
        deger = this.state.MaintChecklistAcceptances[z].name;
      }
    }
    this.setState({
      acceptanceAck: deger,
      acceptance: e,
    });
  };

  addMaintChecklistAcceptances = async () => {
    const { itemMaintChecklistAcceptances } = this.state;
    if (itemMaintChecklistAcceptances != null && itemMaintChecklistAcceptances != "" && itemMaintChecklistAcceptances != undefined) {
      const newItem = {
        name: itemMaintChecklistAcceptances,
        type: "MaintChecklistAcceptance"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemMaintChecklistAcceptances: '',
      });
      this.getCodesMaintChecklistAcceptance();
    } else {
      this.showWarning();
    }
  };

  getCodesMaintChecklistAcceptance = async () => {
    const response = await handleRequest("GET", "/Code/type/MaintChecklistAcceptance");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        MaintChecklistAcceptances: response.data
      });
    }
  }

  handleChangeMaintChecklistAction = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.MaintChecklistActions.length; z++) {
      if (this.state.MaintChecklistActions[z].id == e) {
        deger = this.state.MaintChecklistActions[z].name;
      }
    }
    this.setState({
      actionAck: deger,
      action: e,
    });
  };

  addMaintChecklistActions = async () => {
    const { itemMaintChecklistActions } = this.state;
    if (itemMaintChecklistActions != null && itemMaintChecklistActions != "" && itemMaintChecklistActions != undefined) {
      const newItem = {
        name: itemMaintChecklistActions,
        type: "MaintChecklistAction"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemMaintChecklistActions: '',
      });
      this.getCodesMaintChecklistAction();
    } else {
      this.showWarning();
    }
  };

  getCodesMaintChecklistAction = async () => {
    const response = await handleRequest("GET", "/Code/type/MaintChecklistAction");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        MaintChecklistActions: response.data
      });
    }
  }

  calculateDuration = () => {
    var myObj = this.state.durationObj;
    var types = this.state.durationTypes;
    const index = this.state.durationTypes.findIndex(p => p.value === this.state.durationType);
    if (index >= 0 && this.state.durationNumber > 0) {
      var durationDesc = this.state.duration + this.state.durationNumber + " " + types[index].label + "s ";
      myObj[types[index].label.toLowerCase()] = this.state.durationNumber;
      types.splice(0, index + 1);
      this.setState({
        durationObj: myObj,
        duration: durationDesc,
        durationTypes: types,
        durationType: null,
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }
  }

  undoDuration = () => {
    if (this.state.duration.trim() !== "") {
      var pObj = this.state.durationObj;
      if (pObj !== null) {
        if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else pObj = {};
      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.minute && pObj.minute > 0)
          index = 2;
        else if (pObj.hour && pObj.hour > 0)
          index = 1;
        else if (pObj.day && pObj.day > 0)
          index = 0;
      }
      types.splice(0, index + 1);
      this.setState({
        durationObj: pObj,
        duration: durationDesc,
        durationTypes: types
      });
    }
    else {
      this.showError("Please firstly type any duration!");
    }
  }

  removeDuration = () => {
    this.setState({
      durationObj: {},
      duration: "",
      durationNumber: "",
      durationTypes: _DurationTypes.slice(),
      durationType: null
    });
  }

  setDuration = (pObj) => {
    var result = "";

    if (pObj !== undefined && pObj !== null) {
      if (pObj.day > 0)
        result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
        result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
        result = result + pObj.minute + "Minutes ";
    }
    else { pObj = {} }

    this.setState({
      duration: result,
      durationObj: pObj
    });
  }

  getDurationDesc = (pObj) => {
    var result = "";
    if (pObj !== undefined && pObj !== null) {
      if (pObj.day > 0)
        result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
        result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
        result = result + pObj.minute + "Minutes ";
    }
    return result;
  }

  addNewMaintenanceChecklistItem = () => {
    var itemNoKnt = true;
    if (this.state.itemGroup) {
      if (!(this.state.itemNo != null && this.state.itemNo != undefined && this.state.itemNo != "")) {
        itemNoKnt = false;
      }
    }
    if (itemNoKnt) {
      if (this.state.itemDescription != null && this.state.itemDescription != undefined && this.state.itemDescription != "") {
        this.setState({
          MaintenanceChecklistItemKayit: this.state.MaintenanceChecklistItemKayit + 1,
        });
        this.setState(prevState => ({
          MaintenanceChecklistItem: [...prevState.MaintenanceChecklistItem, {
            key: this.state.MaintenanceChecklistItemKayit,
            index: this.state.MaintenanceChecklistItemKayit,
            id: 0,
            itemNo: this.state.itemNo,
            itemGroup: this.state.itemGroup,
            itemDescription: this.state.itemDescription,
            photoFilePath: this.state.photoFilePath,
            typeOfCheck: this.state.typeOfCheck,
            parentId: this.state.parentId,

            byId: this.state.byId,
            byIdAck: this.state.byIdAck,
            device: this.state.device,
            deviceAck: this.state.deviceAck,
            criteria: this.state.criteria,
            criteriaAck: this.state.criteriaAck,
            acceptance: this.state.acceptance,
            acceptanceAck: this.state.acceptanceAck,
            action: this.state.action,
            actionAck: this.state.actionAck,
            duration: this.state.duration,
            durationAck: this.state.duration,
            documentLogid: this.state.documentLogid,
            durationTime: this.state.durationObj
          }]
        }));
        this.setState({
          criteria: "",
          criteriaAck: "",
          acceptance: "",
          acceptanceAck: "",
          action: "",
          actionAck: "",
          duration: "",
          durationAck: "",
          durationTime: "",

          durationObj: {},
          duration: "",
          durationNumber: "",
          durationTypes: _DurationTypes.slice(),
          durationType: null,
        });
      } else {
        this.showWarning(<FormattedMessage id="MaintenanceChecklistLogDescriptionMessage1" defaultMessage="Please input your Item Description" />);
      }
    } else {
      this.showWarning(<FormattedMessage id="MaintenanceChecklistLogItemNoMessage1" defaultMessage="Please input your Item No" />);
    }
  };

  removeMaintenanceChecklistItem = async (index) => {
    if (index > 0) {
      var newItem = this.state.MaintenanceChecklistItem.filter(p => p.index != index)
      var newRemoveItem = this.state.MaintenanceChecklistItem.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        var MaintenanceChecklistLog = this.state.maintenancePlanTreeTable.filter(p => p.key != this.state.selectMaintenanceChecklistLog.key);

        this.setState({
          MaintenanceChecklistItem: newItem,
          maintenancePlanTreeTable: MaintenanceChecklistLog,
        })
      } else {
        this.setState({
          MaintenanceChecklistItem: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeParent = (value, data) => {
    if (data.length > 0) {
      this.setState({
        values: value,
        parentId: data[data.length - 1].value,
      })
    } else {
      this.setState({
        values: [],
        parentId: 0,
      })
    }
  }

  handleItemGroupDownChange = (e) => {
    e.preventDefault();
    let sayi =
      this.state.itemGroupType === "number" &&
        this.state.itemGroupTypeNumber > 1
        ? this.state.itemGroupTypeNumber - 1
        : this.state.itemGroupTypeNumber;
    let harfindex =
      this.state.itemGroupType === "letter" &&
        alphabetUpper[this.state.letterItemGroupIndex].letter !== "A"
        ? this.state.letterItemGroupIndex - 1
        : this.state.letterItemGroupIndex;
    this.setState({
      itemNo:
        this.state.itemGroupType === "letter"
          ? alphabetUpper[harfindex].letter
          : sayi,
      letterItemGroupIndex: harfindex,
      itemGroupTypeNumber: sayi,
    });
  };

  handleItemGroupUpChange = (e) => {
    e.preventDefault();
    let sayi =
      this.state.itemGroupType === "number"
        ? this.state.itemGroupTypeNumber + 1
        : this.state.itemGroupTypeNumber;
    let harfindex =
      this.state.itemGroupType === "letter" &&
        alphabetUpper[this.state.letterItemGroupIndex].letter !== "Z"
        ? this.state.letterItemGroupIndex + 1
        : this.state.letterItemGroupIndex;
    this.setState({
      itemNo:
        this.state.itemGroupType === "letter"
          ? alphabetUpper[harfindex].letter
          : sayi,
      letterItemGroupIndex: harfindex,
      itemGroupTypeNumber: sayi,
    });
  };


  render() {
    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };

    const columns = [
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("MaintenanceChecklistLog")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogItemNo" defaultMessage="Item No" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.itemNo;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogItemDescription" defaultMessage="Item Description" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.itemDescription;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogTypeOfCheck" defaultMessage="Type of Check" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.typeOfCheck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogBy" defaultMessage="By" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.byIdAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogDevice" defaultMessage="Device" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.deviceAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogCriteria" defaultMessage="Criteria" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.criteriaAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogAcceptance" defaultMessage="Acceptance" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.acceptanceAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogAction" defaultMessage="Action" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.actionAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenanceChecklistLogDuration" defaultMessage="Duration" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.durationAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    return (
      <div>
        <div hidden={this.state.hiddenDataGiris} className="col-md-12" style={{ background: "white" }}>
          <br />
          <Card.Body>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col xs={8}></Col>
                <Col xs={3}>
                  <Button onClick={this.addActivityShow} style={{ width: '100%' }}
                    variant={this.state.createNewButtonActivityVariant}> {this.state.createNewButtonActivity}</Button>
                </Col>
                <Col xs={1}>
                  <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("MaintenanceChecklistLog")} icon="pi pi-cog"></PrimeButton>
                </Col>
              </Row>
              <br />

              <div style={{ display: this.state.activityAddItems ? 'block' : 'none' }}>
                <Form.Group hidden={this.state.hiddenDocument} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridMaintenanceDescription">
                  <Form.Label column sm="2" >Maintenance Description</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.maintenanceDescription} style={{ border: '0px' }} readOnly={true} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenDocument} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocumentCode">
                  <Form.Label column sm="2" >Document Code</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.documentCode} style={{ border: '0px' }} readOnly={true} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenDocument} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridRevNo">
                  <Form.Label column sm="2" >Rev No</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.revno} style={{ border: '0px' }} readOnly={true} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridItemno">
                  <Col sm="2"></Col>
                  <Col sm="2">
                    <Checkbox checked={this.state.itemGroup} onChange={(e) => {
                      var hiddenItemGroup = false;
                      if (e.target.checked) {
                        hiddenItemGroup = true;
                        this.setState({
                          values: [],
                          parentId: 0,
                          photoFilePath: "",
                          PhotoFileUpload: [],
                          typeOfCheck: "",
                          byId: "",
                          device: "",
                          criteria: "",
                          acceptance: "",
                          action: "",
                          durationObj: {},
                          duration: '',
                          previousDuration: '',
                          durationNumber: 0,
                          durationType: null,
                          durationTypes: _DurationTypes.slice(),
                          MaintenanceChecklistItem: [],
                        });
                      } else {
                        this.setState({
                          letterItemGroupIndex: 0,
                          itemGroupType: "number",
                          itemGroupTypeNumber: 0,
                          itemNo: "",
                        });
                      }
                      this.setState({
                        itemGroup: e.target.checked,
                        hiddenItemGroup: hiddenItemGroup,
                        itemGroupTypeHidden: !e.target.checked,
                        itemNoHidden: !e.target.checked,
                        itemGroupNameHidden: !e.target.checked,
                      });
                    }}>Item Group</Checkbox>
                  </Col>

                  <Col sm="3">
                    <div hidden={this.state.itemGroupTypeHidden}>
                      <Radio.Group onChange={(e) => {
                        this.setState({
                          itemGroupType: e.target.value,
                          itemNo:
                            e.target.value === "letter"
                              ? alphabetUpper[this.state.letterItemGroupIndex]
                                .letter
                              : this.state.itemGroupTypeNumber,
                        });
                      }}
                        value={this.state.itemGroupType}>
                        <Radio value={"number"}><FormattedMessage id="MaintenanceChecklistLogNumber" defaultMessage="number" /></Radio>
                        <Radio value={"letter"}><FormattedMessage id="MaintenanceChecklistLogLetter" defaultMessage="letter" /></Radio>
                      </Radio.Group>
                    </div>
                  </Col>

                  <Col xs="2">
                    <div className="p-inputgroup">
                      <PrimeButton icon="pi pi-minus" className="p-button-secondary" hidden={this.state.itemGroupNameHidden}
                        type="button"
                        onClick={this.handleItemGroupDownChange} />
                      <Form.Control hidden={this.state.itemNoHidden}
                        onChange={(e) =>
                          this.setState({
                            itemNo: e.target.value,
                          })
                        }
                        value={this.state.itemNo}
                        placeholder=""
                        style={{ width: "100%" }} />

                      <PrimeButton icon="pi pi-plus" className="p-button-secondary" hidden={this.state.itemGroupNameHidden}
                        type="button" onClick={this.handleItemGroupUpChange}
                      />
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridParent">
                  <Form.Label column sm="2" >Parent Item Group </Form.Label>
                  <Col sm="4">
                    <Cascader
                      style={{ width: "100%" }}
                      options={this.state.dtNodes}
                      value={this.state.values}
                      onChange={this.onChangeParent} changeOnSelect />
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridItemDescription">
                  <Form.Label column sm="2" >Item Description</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.itemDescription} onChange={(e) =>
                      this.setState({ itemDescription: e.target.value })} />
                  </Col>
                  <Col hidden={this.state.hiddenItemGroup} sm="2">
                    <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.PhotoFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {
                          var photoFilePath = "";
                          if (Boolean(fileList[0].response)) {
                            photoFilePath = fileList[0].response.url;
                          }
                          this.setState({
                            PhotoFileUpload: fileList,
                            photoFilePath: photoFilePath,
                          });
                        }
                      }}
                      beforeUpload={(fileList) => {
                        if (this.state.PhotoFileUpload.length > 0) {
                          this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                          return false;
                        }
                        return true;
                      }}
                      onRemove={({ fileList }) => {
                        this.setState({
                          PhotoFileUpload: [],
                          photoFilePath: "",
                        })
                      }
                      }>
                      <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                        <UploadOutlined /> <FormattedMessage id="GeneralUploadPhoto" defaultMessage="Photo" />
                      </AntdButton>
                    </Upload>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridITypeOfCheck">
                  <Col sm="2">
                    <Form.Label hidden={this.state.hiddenItemGroup}>Type of check</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Input hidden={this.state.hiddenItemGroup} value={this.state.typeOfCheck} onChange={(e) =>
                      this.setState({ typeOfCheck: e.target.value })} />
                  </Col>
                  <Col sm="2">
                    {/* <Button hidden={this.state.hiddenSave} onClick={this.addMaintenanceChecklistItems} style={{ width: '100%' }} variant="info">Add New</Button> */}
                    {/* <Button onClick={this.addMaintenanceChecklistItems} style={{ width: '100%' }} variant="info">Add New</Button> */}
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridITypeOfCheck">
                  <Col sm="2">
                    <Form.Label> <FormattedMessage id="MaintChecklistBy" defaultMessage="By" /> </Form.Label>
                  </Col>
                  <Col sm="2">
                    <Form.Label> <FormattedMessage id="MaintChecklistDevice" defaultMessage="Device" /> </Form.Label>
                  </Col>
                  <Col sm="2">
                    <Form.Label> <FormattedMessage id="MaintChecklistCriteria" defaultMessage="Criteria" /> </Form.Label>
                  </Col>
                  <Col sm="2">
                    <Form.Label> <FormattedMessage id="MaintChecklistAcceptance" defaultMessage="Acceptance" /> </Form.Label>
                  </Col>
                  <Col sm="2" >
                    <Form.Label> <FormattedMessage id="MaintChecklistAction" defaultMessage="Action" /> </Form.Label>
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridITypeOfCheck">
                  <Col sm="2">
                    <Select size='large'
                      style={{ width: '100%' }}
                      value={this.state.byId}
                      onChange={this.onChangeBy}
                    >
                      {this.state.disciplines.map(byId => (
                        <Option key={byId.id} value={byId.id}>{
                          byId.disciplineName
                        }</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col sm="2">
                    <Select size='large'
                      style={{ width: '100%' }}
                      value={this.state.device}
                      onChange={this.onChangeDevice}
                    >
                      {this.state.equipments.map(device => (
                        <Option key={device.id} value={device.id}>{
                          device.equipmentCode + "-" + device.equipmentDescription
                        }</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col sm="2">
                    <Select
                      name="criteria"
                      style={{ width: '100%' }}
                      value={this.state.criteria}
                      onChange={this.handleChangeMaintChecklistCriteria}

                    >
                      {this.state.MaintChecklistCriterias.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                      ))}
                    </Select>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemMaintChecklistCriterias}
                        onChange={(event) => { this.setState({ itemMaintChecklistCriterias: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={this.addMaintChecklistCriterias}><FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                    </div>
                  </Col>
                  <Col sm="2">
                    <Select
                      name="acceptance"
                      style={{ width: '100%' }}
                      value={this.state.acceptance}
                      onChange={this.handleChangeMaintChecklistAcceptance}

                    >
                      {this.state.MaintChecklistAcceptances.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                      ))}
                    </Select>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemMaintChecklistAcceptances}
                        onChange={(event) => { this.setState({ itemMaintChecklistAcceptances: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={this.addMaintChecklistAcceptances}><FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                    </div>
                  </Col>
                  <Col sm="2">
                    <Select
                      name="action"
                      style={{ width: '100%' }}
                      value={this.state.action}
                      onChange={this.handleChangeMaintChecklistAction}

                    >
                      {this.state.MaintChecklistActions.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                      ))}
                    </Select>
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemMaintChecklistActions}
                        onChange={(event) => { this.setState({ itemMaintChecklistActions: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                        onClick={this.addMaintChecklistActions}><FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '0rem' }} as={Row}>
                  {this.state.previousDuration.length > 0 && <Col sm="2">
                    <Input style={{ width: '100%' }} value={this.state.previousDuration} />
                  </Col>}
                  {this.state.previousDuration.length > 0 && <Col sm="1">
                    To
                  </Col>}
                  <Col sm="1">
                    <InputNumber min={0} value={this.state.durationNumber}
                      onChange={(value) => {
                        this.setState({ durationNumber: value })
                      }}
                    > </InputNumber></Col>
                  <Col sm="2">
                    <Select placeholder="" value={this.state.durationType} onChange={(value) => {
                      this.setState({
                        durationType: value,
                      })
                    }}
                    >
                      <Option key={null} value={null}>Select</Option>
                      {this.state.durationTypes.map(i => (
                        <Option key={i.value} value={i.value}>{i.label}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col sm="2">
                    <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.calculateDuration} icon={<RightCircleOutlined style={{ fontSize: '32px' }} />} />
                    <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.undoDuration} icon={<UndoOutlined style={{ fontSize: '32px' }} />} />
                  </Col>
                  <Col sm="2">
                    <Input style={{ width: '100%' }} id="todo" value={this.state.duration} />
                  </Col>
                  <Col sm="1">
                    <AntdButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.removeDuration} icon={<CloseCircleOutlined style={{ fontSize: '32px' }} />} />
                  </Col>
                  <Col sm="1">
                    {this.state.hiddenUpdate && <AntdButton type="primary" onClick={this.addNewMaintenanceChecklistItem} icon={<PlusOutlined />}></AntdButton>}
                  </Col>
                </Form.Group>
                <br />
                <br />
                {this.state.hiddenUpdate && this.state.hiddenItemGroup == false && this.state.MaintenanceChecklistItem.map((item, index) => (
                  <Form.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2">
                      <Form.Label>{item.byIdAck}</Form.Label>
                    </Col>
                    <Col sm="2">
                      <Form.Label>{item.deviceAck}</Form.Label>
                    </Col>
                    <Col sm="2">
                      <Form.Label>{item.criteriaAck}</Form.Label>
                    </Col>
                    <Col sm="2">
                      <Form.Label>{item.acceptanceAck}</Form.Label>
                    </Col>
                    <Col sm="1">
                      <Form.Label>{item.actionAck}</Form.Label>
                    </Col>
                    <Col sm="2">
                      <Form.Label>{item.durationAck}</Form.Label>
                    </Col>
                    <Col sm="1">
                      {((item.byIdAck != null && item.byIdAck != undefined && item.byIdAck != "") || (item.deviceAck != null && item.deviceAck != undefined && item.deviceAck != "") ||
                        (item.criteriaAck != null && item.criteriaAck != undefined && item.criteriaAck != "") || (item.acceptanceAck != null && item.acceptanceAck != undefined && item.acceptanceAck != "") ||
                        (item.actionAck != null && item.actionAck != undefined && item.actionAck != "") || (item.durationAck != null && item.durationAck != undefined && item.durationAck != "")) &&
                        <AntdButton type="primary" onClick={() => this.removeMaintenanceChecklistItem(item.index)} danger icon={<CloseOutlined />}></AntdButton>
                      }</Col>
                  </Form.Group>
                ))}
                <br />

                <div hidden={this.state.hiddenSave}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="4">
                      <Button
                        style={{ width: '100%' }} onClick={this.addMaintenanceChecklistItems}
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdate}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </Form.Group>
                </div>
              </div>
              <br />
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.maintenancePlanTreeTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
              <br />
              {/* <Row >
                <Col sm="2"></Col>
                <Col sm="4">
                  <Button onClick={this.saveMaintenanceChecklistLog} style={{ width: '100%' }} variant="success">{this.state.lastButton}</Button>
                </Col>
              </Row> */}
            </Container>
          </Card.Body>
          {/* </Accordion.Collapse>
            </Card>
          </Accordion> */}
        </div>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteMaintenanceChecklistLogOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>


      </div >
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceChecklist)
