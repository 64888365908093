import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { Form, Col, Row } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Select, Table } from 'antd';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Modal, Button as ReactButton } from 'react-bootstrap';
import { FormattedMessage } from "react-intl";


const { Option } = Select;

function turkishtoEnglish(param) {

    return param.replaceAll('Ğ', 'g')
        .replaceAll('Ü', 'u')
        .replaceAll('Ş', 's')
        .replaceAll('I', 'i')
        .replaceAll('İ', 'i')
        .replaceAll('Ö', 'o')
        .replaceAll('Ç', 'c')
        .replaceAll('ğ', 'g')
        .replaceAll('ü', 'u')
        .replaceAll('ş', 's')
        .replaceAll('ı', 'i')
        .replaceAll('ö', 'o')
        .replaceAll('ç', 'c');
};

export class User extends Component {

    constructor() {
        super();
        this.state = {
            staffName: "",
            staffId: null,
            Staffs: [],
            modalDeleteShow: false,

            userId: null,
            userList: [],
            userName: "",
            email: "",

            selectedRow: {},

            hiddenUpdate: true,
            hiddenSave: false
        };

       
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/Staff/getCompanyItems");

        if (response.data.length === 0) {
            this.setState({
                Staffs: []
            });
        }

        if (response.data.length !== 0) {

            this.setState({
                Staffs: response.data
            });

        }


        this.restartTable();

    };

    changeButtonCancel = () => {
        this.setState({
            staffName: "Select Personnel",

            userId: null,

            userName: "",
            email: "",

            selectedRow: {},
            staffId: null,
            hiddenUpdate: true,
            hiddenSave: false
        });
    };

    changeButtonUpdate = (rowData) => {
        console.log(rowData);
        this.setState({
            hiddenUpdate: false,
            hiddenSave: true,
            userId: rowData.id,

            userName: rowData.username,
            email: rowData.email


        });
        if (rowData.staff !== null)
            this.setState({ staffId: rowData.staff.id })

    };




    actionTemplate(rowData, column) {

        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
        </React.Fragment>;

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            userId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    deleteUser = async () => {

        const deletedItem = {
            id: this.state.userId
        }

        await handleRequest("POST", "/User/delete", deletedItem);

        this.restartTable();
        this.showSuccess();

        this.setState({
            userId : null,
            modalDeleteShow:false 
        }); 
    } 

    restartTable = async () => {

        const response = await handleRequest("GET", "/User/getCompanyItems");

        if (response.data.length === 0) {
            this.setState({
                userList: []

            });
        }

        if (response.data.length !== 0) {
            this.setState({
                userList: response.data
            });
        }
    }

    saveUser = async () => {

        if (this.state.userName.trim() !== "" && this.state.email.trim() !== "") {

            const newItem = {
                id: this.state.userId,
                username: this.state.userName,
                email: this.state.email,
                staffId: this.state.staffId

            }

            if (newItem.id === null) {
                const response2 = await handleRequest("GET", "/User/controlEmail/" + newItem.email);
                console.log(response2.data);
                if (response2.data === 0) {
                    await handleRequest("POST", "/User/add", newItem);
                }
                else {
                    this.showError("This email added before please change email.")
                    return;
                }

            }
            else await handleRequest("POST", "/User/update", newItem);

            this.changeButtonCancel();
            this.restartTable();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    generateUserName = async () => {

        const response = await handleRequest("GET", "/AutoUsernameRule/getAllByCompany");
        let userName = "";
        if (Boolean(response.data) && response.data.length > 0) {

            const _usernameRules = response.data.filter(p => p.type === "username");


            if (Boolean(_usernameRules) && _usernameRules.length > 0) {

                console.log(_usernameRules);
                let j = 0;
                //  while (userName === "") {
                for (let i = 0; i <= 5; i++) {  // maksimum 6 tane rule tanımına izin verdigimiz için <= 5 

                    var index = _usernameRules.findIndex(p => p.ruleIndex === i);

                    if (index >= 0) {
                        let u = this.getUserNameText(_usernameRules.filter(p => p.ruleIndex === i));
                        if (j > 0)
                            u = u + '' + j;
                        const response2 = await handleRequest("GET", "/User/controlUserName/" + u);
                        console.log(response2.data);
                        if (response2.data === 0) {
                            userName = u;
                            break;
                        }

                    }
                }

                j++;
                //}
            }
            else {
                this.showError("There is no defined auto username rules.");
            }


            this.setState({
                userName: userName
            });


        } else {

            this.showError("There is no defined auto username rules.")
        }
    }

    getUserNameText = (ruleList) => {

        var result = "";
        var staff = this.state.Staffs.find(p => p.id === this.state.staffId);
        console.log(ruleList);
        ruleList.forEach(element => {
            switch (element.rule) {
                case "1":
                    result = result + staff.name.toLowerCase().trim() + element.delimeter;
                    break;
                case "3":
                    if (Boolean(staff.midName) && staff.midName.trim().length > 0)
                        result = result + staff.midName.toLowerCase().trim() + element.delimeter;
                    break;
                case "2":
                    result = result + staff.lastName.toLowerCase().trim() + element.delimeter;
                    break;
                case "4":
                    result = result + staff.name.toLowerCase().trim().charAt(0) + element.delimeter;
                    break;
                case "5":
                    result = result + staff.name.toLowerCase().trim().charAt(0) + (staff.name.length > 1 ? staff.name.toLowerCase().trim().charAt(1) : '') + element.delimeter;
                    break;
                case "6":
                    result = result + staff.lastName.toLowerCase().trim().charAt(0) + element.delimeter;
                    break;
                case "7":
                    result = result + staff.lastName.toLowerCase().trim().charAt(0) + (staff.lastName.length > 1 ? staff.lastName.toLowerCase().trim().charAt(1) : '') + element.delimeter;

                    break;
                case "8":
                    if (Boolean(staff.midName) && staff.midName.trim().length > 0)
                        result = result + staff.midName.toLowerCase().trim().charAt(0) + element.delimeter;
                    break;

                case "9":
                    if (Boolean(staff.midName) && staff.midName.trim().length > 0)
                        result = result + staff.midName.toLowerCase().trim().charAt(0) + (staff.midName.length > 1 ? staff.midName.toLowerCase().trim().charAt(1) : '') + element.delimeter;

                    break;

                default:
                    break;
            }
        });

        console.log(turkishtoEnglish(result));

        return turkishtoEnglish(result);

    }

    render() {

        const columns = [

            {
                title: 'S/N', key: 'name', render: (value, row, index) => {

                    return index + 1;

                }
            },
            {
                title: 'Actions', key: 'name', render: (record) => {
                    return this.actionTemplate(record);
                }
            },
            {
                title: 'Personnel Name', key: 'name', render: (record) => {
                    if (Boolean(record.staff))
                        return record.staff.fullName;
                }
            },
            {
                title: 'User Name', key: 'name', render: (record) => {

                    return record.username;
                }
            },
            {
                title: 'Email', key: 'name', render: (record) => {

                    return record.email;
                }
            }


        ];


        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;

        return <div>
            <Card>
                <Row>
                    <Col sm="3"></Col>
                    <Col sm="9">
                        <Form>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Personnel
                                </Form.Label>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="Designation" id="Designation" value={this.state.staffId} onChange={(value) => {

                                        this.setState({ staffId: value, }, this.generateUserName)
                                    }}>
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.Staffs.map(i => (
                                            <Option key={i.id} value={i.id}>{i.fullName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    User Name
                            </Form.Label>
                                <Col sm="4">
                                    <InputText style={{ width: '100%' }} value={this.state.userName} placeholder="User Name" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Email
                           </Form.Label>
                                <Col sm="4">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} placeholder="Email" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>


                        </Form>

                    </Col>
                </Row>
                <Row hidden={this.state.hiddenSave}>
                    <Col sm="3">
                    </Col>
                    <Col sm="9">
                        <Row>
                            <Col sm="2">
                            </Col>
                            <Col sm="4">
                                {
                                    <ReactButton id="OrganizationalLevelSaveButton" type="button" onClick={this.saveUser} style={{ width: '100%', marginTop: '10px' }}
                                        variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                }
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row hidden={this.state.hiddenUpdate}>
                    <Col sm="3">
                    </Col>
                    <Col sm="9">
                        <Row>
                            <Col sm="2">
                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%', marginTop: '10px' }} variant="secondary" onClick={this.changeButtonCancel}>
                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                            </Col>
                            <Col sm="4">
                              {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                              <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%', marginTop: '10px' }} variant="warning" type="button" onClick={this.saveUser} >
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                   
                            </Col>
                        </Row>
                    </Col>

                </Row>



                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Toast ref={(el) => this.toast = el} />
                    <Table

                        bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
                        columns={columns}

                        dataSource={this.state.userList}
                    />
                    {/* <DataTable ref={(el) => this.dt = el} value={this.state.userList} paginator={true} responsive={true}
                        rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-departments" selectionMode="single"
                        selection={this.state.selectedRow} onSelectionChange={e => this.setState({ selectedRow: e.value })}>
                        <Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable header="Id" />
                        <Column field="staff.id" style={{ textAlign: 'center', display: 'none' }} sortable header="staffId" />
                        <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                        <Column field="staff.name" header="Personnel Name" filter sortable />
                        <Column field="username" header="User Name" filter sortable />
                        <Column field="email" style={{ textAlign: 'center' }} header="Email" filter sortable />


                        <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                    </DataTable> */}
                </div>

            </Card>

            <Modal
                show={this.state.modalDeleteShow}
                onHide={this.showOrHideDeleteModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                    <ReactButton variant="danger" onClick={this.deleteUser} >Delete</ReactButton>
                </Modal.Footer>
            </Modal>

        </div>

    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(User)