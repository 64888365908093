
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox } from 'antd';
import moment from 'moment'

const { Column } = Table;
const { Option } = Select;
export class ArchivingVolume extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,


            archiveDocumentSelectItems: [],
            archivingVolumeId: 0,
            archiveDocumentId: null,
            volumeNumber: 0,
            startSerialNo: 0,
            endSerialNo: 0,
            volumeClosed: false,
            volumeText: "",
            folderName: "",

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.setComboboxes();
        this.restartTable();


    }

    save = async () => {



        const obj = {
            id: this.state.archivingVolumeId,
            archiveDocumentId: this.state.archiveDocumentId,
            volumeNumber: this.state.volumeNumber,
            startSerialNo: this.state.startSerialNo,
            endSerialNo: this.state.endSerialNo,
            volumeClosed: this.state.volumeClosed
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/ArchiveVolume/add", obj);
        else
            await handleRequest("POST", "/ArchiveVolume/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {


        this.setState({
            archivingVolumeId: row.id,
            archiveDocumentId: row.archiveDocumentId,
            volumeNumber: row.volumeNumber,
            startSerialNo: row.startSerialNo,
            endSerialNo: row.endSerialNo,
            volumeClosed: row.volumeClosed,
            folderName: row.folderName,
            volumeText: row.volume,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.archivingVolumeId
        }

        await handleRequest("POST", "/ArchiveVolume/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            archivingVolumeId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/ArchiveVolume/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                archivingVolumeList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                archivingVolumeList: [],
            });
        }
    }


    setComboboxes = async () => {
        const resDepList = await handleRequest("GET", "/ArchiveDocument/getAllByCompany");
        if (resDepList.data.length > 0) {
            const list = resDepList.data;
            list.forEach(element => {
                if (Boolean(element.documentLog)) {
                    element.description = element.documentLog.description
                }
            });
            this.setState({
                archiveDocumentSelectItems: list
            });
        } else {
            this.setState({
                archiveDocumentSelectItems: []
            });
        }


    }
    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ArchivingVolume" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "ArchivingVolume"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ArchivingVolume");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            archivingVolumeId: 0,
            archiveDocumentId: null,
            volumeNumber: 0,
            startSerialNo: 0,
            endSerialNo: 0,
            volumeClosed: false,
            volumeText: "",
            folderName: "",
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            archivingVolumeId: 0,
            archiveDocumentId: null,
            volumeNumber: 0,
            startSerialNo: 0,
            endSerialNo: 0,
            volumeClosed: false,
            volumeText: "",
            folderName: "",
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            archivingVolumeId: 0,
            archiveDocumentId: null,
            volumeNumber: 0,
            startSerialNo: 0,
            endSerialNo: 0,
            volumeClosed: false,
            volumeText: "",
            folderName: "",
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }



    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onChangeDocumentedInfo = async (value) => {

        const obj = this.state.archiveDocumentSelectItems.find(p => p.id === value);

        const response = await handleRequest("GET", "/ArchiveDocument/" + value);
        var code = 1;
        console.log(response);
        if (response.data.length !== 0) {
            const list = response.data.archiveVolumes;
            if (Boolean(list) && list.length > 0) {
                let closedList = list.filter(p => p.volumeClosed === true);
                let objc = list.find(p => p.volumeClosed === false);
                if (Boolean(closedList) && closedList.length > 0) {
                    code = closedList.length + 1;
                }

                if (Boolean(objc)) {
                    this.setState({
                        archivingVolumeId: objc.id,
                        startSerialNo: objc.startSerialNo,
                        endSerialNo: objc.endSerialNo
                    })
                }
            }
        }

        this.setState({
            archiveDocumentId: value,
            folderName: Boolean(obj) ? obj.folderName : '',
            volumeText: Boolean(obj) && Boolean(obj.folderCode) && obj.folderCode.length > 0 ? obj.folderCode + " - " + code : code,
            volumeNumber: code
        })
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen material/equipment will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Documented Info" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.archiveDocumentId} onChange={this.onChangeDocumentedInfo}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.archiveDocumentSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.description}</Option>
                                                    ))}
                                                </Select>


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="File Name" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="todo" value={this.state.folderName} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Volume" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="todo" value={this.state.volumeText} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Serial No" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="2">
                                                <InputNumber min={1} value={this.state.startSerialNo}
                                                    onChange={(value) => {
                                                        this.setState({ startSerialNo: value })
                                                    }}
                                                > </InputNumber>
                                            </Col>
                                            <Col sm="1">
                                                to
                                            </Col>
                                            <Col sm="2">
                                                <InputNumber min={1} value={this.state.endSerialNo}
                                                    onChange={(value) => {
                                                        this.setState({ endSerialNo: value })
                                                    }}
                                                > </InputNumber>
                                            </Col>
                                            <Col sm="3">
                                                <div>
                                                    <Checkbox onChange={(e) => {
                                                        this.setState({
                                                            volumeClosed: e.target.checked
                                                        })
                                                    }} checked={this.state.volumeClosed}> Volume closed </Checkbox>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }





                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row>
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.archivingVolumeList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.archivingVolumeList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            {/* <Column title="Folder Name" render={(value, row, index) => {

                                if (Boolean(row.archiveDocument))
                                    return row.archiveDocument.folderName;

                            }} />
                            <Column title="Volume" render={(value, row, index) => {
                                if (Boolean(row.archiveDocument) && Boolean(row.archiveDocument.folderCode))
                                    return row.archiveDocument.folderCode + "-" + row.volumeNumber;
                                else return row.volumeNumber;

                            }} />

                            <Column title="Document Info" render={(value, row, index) => {

                                if (Boolean(row.archiveDocument) && Boolean(row.archiveDocument.documentLog))
                                    return row.archiveDocument.documentLog.description;
                            }} /> */}
                            <Column title="Folder Name" dataIndex="folderName"

                            />
                            <Column title="Volume" dataIndex="volume" />

                            <Column title="Document Info" dataIndex="documentInfo" />
                            <Column title="Serial No" render={(value, row, index) => {
                                return row.startSerialNo + "-" + row.endSerialNo;

                            }} />

                            <Column title="Detention Date" render={(value, row, index) => {

                                if (row.volumeClosedDate) {
                                    var fd = moment(new Date(row.volumeClosedDate));

                                    let type = row.detentionPeriodType;
                                    switch (type) {
                                        case "year":
                                            fd.add(row.detentionPeriod, "years");
                                            break;
                                        case "month":
                                            fd.add(row.detentionPeriod, "months");
                                            break;
                                        case "week":
                                            fd.add(row.detentionPeriod, "weeks");
                                            break;
                                        case "day":
                                            fd.add(row.detentionPeriod, "days"); 
                                            break;

                                        default:
                                            break;
                                    }
                                    
                                    return fd.format("DD/MM/YYYY HH:mm");
                                }


                            }} />



                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />

                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
      
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivingVolume)