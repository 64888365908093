import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Divider, Button as AntButton, Select, InputNumber } from 'antd';
import { CloseCircleOutlined, PlusOutlined, RightCircleOutlined, UndoOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Column } = Table;

export class DisciplinaryActions extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            tableListScoring: [],
            tableListConsecutive: [],
            tableListTotal: [],
            tableList: [{ id: 1, key: 1, name: "Scoring" }, { id: 2, key: 2, name: "Consecutive Absent Days" }, { id: 3, key: 3, name: "Total of Absent Days" }],
            scoreId: 0,
            consecutiveId: 0,
            totalId: 0,
            score: 0,
            previousScore: 0,
            disciplinaryActionSelectItems: [],
            disciplinaryActionIdScoring: null,
            disciplinaryActionIdConsecutive: null,
            disciplinaryActionIdTotal: null,
            maxDaysConsecutive: 0,
            maxDaysPeriodConsecutive: null,
            maxRepetation: 0,
            maxRepetationPeriod: null,
            maxDaysTotal: 0,
            maxDaysPeriodTotal: null,
            codeName: '',

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.fillCodes();
        this.restartTable();


    }

    addScore = async () => {

        const obj = {
            id: this.state.scoreId,
            score: this.state.score,
            disciplinaryActionId: this.state.disciplinaryActionIdScoring
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/AttendanceScoring/add", obj);
        else
            await handleRequest("POST", "/AttendanceScoring/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    editScore = (row) => {

        const list = this.state.tableListScoring;
        let index = list.findIndex(p => p.id === row.id);
        let result = 0;
        if (index > 0) {
            if (Boolean(list[index - 1].absentTime))
                result = list[index - 1].score
        }

        this.setState({

            id: this.state.scoreId,
            score: row.score,
            disciplinaryActionIdScoring: row.disciplinaryActionId,
            previousScore: result,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    deleteScore = async () => {
        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/AttendanceScoring/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    addConsecutive = async () => {

        const obj = {
            id: this.state.consecutiveId,
            maxDays: this.state.maxDaysConsecutive,
            maxDaysPeriod: this.state.maxDaysPeriodConsecutive,
            maxRepetation: this.state.maxRepetation,
            maxRepetationPeriod: this.state.maxRepetationPeriod,
            disciplinaryActionId: this.state.disciplinaryActionIdConsecutive

        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/AttendanceConsecutiveAbsent/add", obj);
        else
            await handleRequest("POST", "/AttendanceConsecutiveAbsent/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    editConsecutive = (row) => {

        this.setState({

            id: row.consecutiveId,
            maxDaysConsecutive: row.maxDays,
            maxDaysPeriodConsecutive: row.maxDaysPeriod,
            maxRepetation: row.maxRepetation,
            maxRepetationPeriod: row.maxRepetationPeriod,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    deleteConsecutive = async () => {
        const obj = {
            id: this.state.consecutiveId
        }

        await handleRequest("POST", "/AttendanceConsecutiveAbsent/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    addTotal = async () => {

        const obj = {
            id: this.state.consecutiveId,
            maxDays: this.state.maxDaysTotal,
            maxDaysPeriod: this.state.maxDaysPeriodTotal,
            disciplinaryActionId: this.state.disciplinaryActionIdTotal
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/AttendanceTotalAbsent/add", obj);
        else
            await handleRequest("POST", "/AttendanceTotalAbsent/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    editTotal = (row) => {

        this.setState({

            id: row.consecutiveId,
            maxDaysTotal: row.maxDays,
            maxDaysPeriodTotal: row.maxDaysPeriod,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    deleteTotal = async () => {
        const obj = {
            id: this.state.totalId
        }

        await handleRequest("POST", "/AttendanceTotalAbsent/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }


    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/AttendanceScoring/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableListScoring: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableListScoring: [],
            });
        }

        const response2 = await handleRequest("GET", "/AttendanceConsecutiveAbsent/getAllByCompany");

        if (response2.data.length !== 0) {
            const list = response2.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableListConsecutive: list,
            });
        }

        const response3 = await handleRequest("GET", "/AttendanceTotalAbsent/getAllByCompany");

        if (response3.data.length !== 0) {
            const list = response3.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableListTotal: list,
            });
        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "DisciplinaryActions" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "DisciplinaryActions"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "DisciplinaryActions");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            id: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            id: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            status: '',
            abbreviation: '',
            id: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    addCode = async () => {
        const { codeName } = this.state;
        let pType = "disciplinaryAction";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/disciplinaryAction");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                disciplinaryActionSelectItems: response.data
            });
        }
    }

    expand = (record) => {

        var data = [];
        var columns = [];
        if (record.id === 1) {


            data = this.state.tableListScoring;
            columns = [


                { title: 'Index', key: 'Index', dataIndex: 'index' },
                { title: 'Score', key: 'score', dataIndex: 'score' },
                {
                    title: 'Disciplinary Action', key: 'disciplinaryAction', render: (record) => {
                        if (Boolean(record.disciplinaryAction))
                            return record.disciplinaryAction.name;
                    }
                },
            ];
        }
        if (record.id === 2) {
            data = this.state.tableListConsecutive;
            console.log(data);
            columns = [


                { title: 'Index', key: 'Index', dataIndex: 'index' },
                {
                    title: 'Maximum Day', key: 'score', render: (record) => {
                        if (Boolean(record.maxDays))
                            return record.maxDays + ' ' + (Boolean(record.maxDaysPeriod) ? record.maxDaysPeriod : '');
                    }
                },
                {
                    title: 'Disciplinary Action', key: 'disciplinaryAction', render: (record) => {
                        if (Boolean(record.disciplinaryAction))
                            return record.disciplinaryAction.name;
                    }
                },
                {
                    title: 'Representative Day', key: 'score', render: (record) => {
                        if (Boolean(record.maxRepetation))
                            return record.maxRepetation + ' ' + (Boolean(record.maxRepetationPeriod) ? record.maxRepetationPeriod : '');
                    }
                },
            ];
        }
        if (record.id === 3) {
            data = this.state.tableListTotal;

            columns = [


                { title: 'Index', key: 'Index', dataIndex: 'index' },
                {
                    title: 'Maximum Day', key: 'score', render: (record) => {
                        if (Boolean(record.maxDays))
                            return record.maxDays + ' ' + (Boolean(record.maxDaysPeriod) ? record.maxDaysPeriod : '');
                    }
                },
                {
                    title: 'Disciplinary Action', key: 'disciplinaryAction', render: (record) => {
                        if (Boolean(record.disciplinaryAction))
                            return record.disciplinaryAction.name;
                    }
                },
            ];
        }




        return <Table columns={columns} dataSource={data} pagination={false} />;



    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const columns = [
            { title: 'Disciplinary Action', dataIndex: 'name', key: 'name' },
        ];

        return <div>

                <MenuProvider id="menu_id">
                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                   
                                     {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Scoring" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">
                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Score" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                {this.state.previousScore > 0 && <Col sm="2">
                                                    <Input style={{ width: '100%' }} id="todo" value={this.state.previousScore} />
                                                </Col>}
                                                {this.state.previousScore > 0 && <Col sm="1">
                                                    To
                                            </Col>}
                                                <Col sm="1">
                                                    <InputNumber min={0} value={this.state.score}
                                                        onChange={(value) => {
                                                            this.setState({ score: value })
                                                        }}
                                                    > </InputNumber>  </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Disciplinary Action" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Unit"
                                                        value={this.state.disciplinaryActionIdScoring} onChange={(value) => {

                                                            this.setState({ disciplinaryActionIdScoring: value })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCode}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.disciplinaryActionSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                    <AntButton type="primary" onClick={this.addScore} icon={<PlusOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                </div>
                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Consecutive Absent Days" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">
                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Maximum Days" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="2">
                                                    <InputNumber min={0} value={this.state.maxDaysConsecutive}
                                                        onChange={(value) => {
                                                            this.setState({ maxDaysConsecutive: value })
                                                        }}
                                                    > </InputNumber>
                                                </Col>
                                                <Col sm="1">
                                                    in
                                            </Col>
                                                <Col sm="3">
                                                    <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.maxDaysPeriodConsecutive} onChange={(value) => {
                                                        this.setState({
                                                            maxDaysPeriodConsecutive: value
                                                        })
                                                    }}>
                                                        <Option key={null} value={null}>{"Select"}</Option>
                                                        <Option key={"week"} value={"week"}>{"Week"}</Option>
                                                        <Option key={"month"} value={"month"}>{"Month"}</Option>
                                                        <Option key={"year"} value={"year"}>{"Year"}</Option>

                                                    </Select> </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Disciplinary Action" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Unit"
                                                        value={this.state.disciplinaryActionIdConsecutive} onChange={(value) => {

                                                            this.setState({ disciplinaryActionIdConsecutive: value })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCode}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.disciplinaryActionSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>

                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Maximum Repetation" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="2">
                                                    <InputNumber min={0} value={this.state.maxRepetation}
                                                        onChange={(value) => {
                                                            this.setState({ maxRepetation: value })
                                                        }}
                                                    > </InputNumber>
                                                </Col>
                                                <Col sm="1">
                                                    in
                                            </Col>
                                                <Col sm="3">
                                                    <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.maxRepetationPeriod} onChange={(value) => {
                                                        this.setState({
                                                            maxRepetationPeriod: value
                                                        })
                                                    }}>
                                                        <Option key={null} value={null}>{"Select"}</Option>
                                                        <Option key={"week"} value={"week"}>{"Week"}</Option>
                                                        <Option key={"month"} value={"month"}>{"Month"}</Option>
                                                        <Option key={"year"} value={"year"}>{"Year"}</Option>

                                                    </Select> </Col>
                                                <Col sm="6">
                                                    <AntButton type="primary" onClick={this.addConsecutive} icon={<PlusOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                </div>
                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>


                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Total Absent Days" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">
                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Maximum Days" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="2">
                                                    <InputNumber min={0} value={this.state.maxDaysTotal}
                                                        onChange={(value) => {
                                                            this.setState({ maxDaysTotal: value })
                                                        }}
                                                    > </InputNumber>
                                                </Col>
                                                <Col sm="1">
                                                    in
                                            </Col>
                                                <Col sm="3">
                                                    <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.maxDaysPeriodTotal} onChange={(value) => {
                                                        this.setState({
                                                            maxDaysPeriodTotal: value
                                                        })
                                                    }}>
                                                        <Option key={null} value={null}>{"Select"}</Option>
                                                        <Option key={"week"} value={"week"}>{"Week"}</Option>
                                                        <Option key={"month"} value={"month"}>{"Month"}</Option>
                                                        <Option key={"year"} value={"year"}>{"Year"}</Option>

                                                    </Select> </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Disciplinary Action" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Unit"
                                                        value={this.state.disciplinaryActionIdTotal} onChange={(value) => {

                                                            this.setState({ disciplinaryActionIdTotal: value })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCode}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.disciplinaryActionSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                    <AntButton type="primary" onClick={this.addTotal} icon={<PlusOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                </div>

                                <div hidden={true}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={true}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        <Table
                            className="components-table-demo-nested"
                            columns={columns}
                            expandable={{ expandedRowRender: record => this.expand(record) }}
                            dataSource={this.state.tableList}
                        />
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DisciplinaryActions)