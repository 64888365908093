/* eslint-disable */

import React, { Component } from 'react'

export default class StaffRevision extends Component {

  constructor(props) {
    super(props)
    this.state = {
        personelLogId: 0,
        tds:'', 
    }; 
};


  componentDidMount = async () => {

    console.log(this.props.row) 
    console.log(this.props.row2)
    var row1=this.props.row;
    var row2=this.props.row2;
    //const obj1Keys = Object.keys(this.props.row);
      

    var tds='';
    if(row2.name!=row1.name)  {tds=tds+'<tr><td>Name</td><td>'+row2.name+'</td><td>'+row1.name+'</td></tr>';}
    if(row2.midName!=row1.midName)  {tds=tds+'<tr><td>Middle Name</td><td>'+row2.midName+'</td><td>'+row1.midName+'</td></tr>';}
    if(row2.lastName!=row1.lastName)  {tds=tds+'<tr><td>Last Name</td><td>'+row2.lastName+'</td><td>'+row1.lastName+'</td></tr>';}
    if(row2.fatherName!=row1.fatherName)  {tds=tds+'<tr><td>Father Name</td><td>'+row2.fatherName+'</td><td>'+row1.fatherName+'</td></tr>';}
    if(row2.motherName!=row1.motherName)  {tds=tds+'<tr><td>Mother Name</td><td>'+row2.motherName+'</td><td>'+row1.motherName+'</td></tr>';}
    if(row2.departmentId!=row1.departmentId)  {tds=tds+'<tr><td>Department</td><td>'+row2.department.departmentName+'</td><td>'+row1.department.departmentName+'</td></tr>';}
    if(row2.disciplineId!=row1.disciplineId)  {tds=tds+'<tr><td>Discipline</td><td>'+row2.discipline.disciplineName+'</td><td>'+row1.discipline.disciplineName+'</td></tr>';}
    if(row2.designationId!=row1.designationId)  {tds=tds+'<tr><td>Designation </td><td>'+row2.designation.designationName+'</td><td>'+row1.designation.designationName+'</td></tr>';}
    if(row2.roleGroupId!=row1.roleGroupId)  {tds=tds+'<tr><td>Role Group </td><td>'+row2.roleGroup.roleName+'</td><td>'+row1.roleGroup.roleName+'</td></tr>';}
    if(row2.drivingLicence!=row1.drivingLicence)  {tds=tds+'<tr><td>Driving Licence </td><td>'+row2.drivingLicence+'</td><td>'+row1.drivingLicence+'</td></tr>';}
    if(row2.drivingExpirityDate!=row1.drivingExpirityDate)  {tds=tds+'<tr><td>Driving Expirity Date </td><td>'+row2.drivingExpirityDate+'</td><td>'+row1.drivingExpirityDate+'</td></tr>';}
    if(row2.drivingExpirityDate!=row1.drivingExpirityDate)  {tds=tds+'<tr><td>Driving Expirity Date </td><td>'+row2.drivingExpirityDate+'</td><td>'+row1.drivingExpirityDate+'</td></tr>';}
    if(row2.gender!=row1.gender)  {tds=tds+'<tr><td>gender</td><td>'+row2.genderCode.name+'</td><td>'+row1.genderCode.name+'</td></tr>';}
    if(row2.placeOfBirth!=row1.placeOfBirth)  {tds=tds+'<tr><td>placeOfBirth</td><td>'+row2.placeOfBirth+'</td><td>'+row1.placeOfBirth+'</td></tr>';}
    if(row2.nationalId!=row1.nationalId)  {tds=tds+'<tr><td>nationalId</td><td>'+row2.nationalId+'</td><td>'+row1.nationalId+'</td></tr>';}

    if(row2.birthDate!=row1.birthDate)  {tds=tds+'<tr><td>birthDate</td><td>'+row2.birthDate+'</td><td>'+row1.birthDate+'</td></tr>';}
    if(row2.country!=row1.country)  {tds=tds+'<tr><td>country</td><td>'+row2.country+'</td><td>'+row1.country+'</td></tr>';}
    if(row2.countryName!=row1.countryName)  {tds=tds+'<tr><td>countryName</td><td>'+row2.countryName+'</td><td>'+row1.countryName+'</td></tr>';}
    if(row2.passportNo!=row1.passportNo)  {tds=tds+'<tr><td>passportNo</td><td>'+row2.passportNo+'</td><td>'+row1.passportNo+'</td></tr>';}
    if(row2.passportExpirityDate!=row1.passportExpirityDate)  {tds=tds+'<tr><td>Passport Expirity Date</td><td>'+row2.passportExpirityDate+'</td><td>'+row1.passportExpirityDate+'</td></tr>';}
    if(row2.bloodGroup!=row1.bloodGroup)  {tds=tds+'<tr><td>blood Group</td><td>'+row2.bloodGroupCode.type+'</td><td>'+row1.bloodGroupCode.type+'</td></tr>';}
     if(row2.maritalStatus!=row1.maritalStatus)  {tds=tds+'<tr><td>marital Status  </td><td>'+row2.maritalStatusCode.name+'</td><td>'+row1.maritalStatusCode.name+'</td></tr>';}
    if(row2.smokeStatus!=row1.smokeStatus)  {tds=tds+'<tr><td>smoke Status </td><td>'+row2.smokeStatusCode.name+'</td><td>'+row1.smokeStatusCode.name+'</td></tr>';}
    if(row2.staffType!=row1.staffType)  {tds=tds+'<tr><td>staff Type  </td><td>'+row2.staffTypeCode.name+'</td><td>'+row1.staffTypeCode.name+'</td></tr>';}
    if(row2.militaryObligation!=row1.militaryObligation)  {tds=tds+'<tr><td>military Obligation  </td><td>'+row2.militaryObligationcode.name+'</td><td>'+row1.militaryObligationcode.name+'</td></tr>';}
    if(row2.numberOfChild!=row1.numberOfChild)  {tds=tds+'<tr><td>number Of Child</td><td>'+row2.numberOfChild+'</td><td>'+row1.numberOfChild+'</td></tr>';}

    if(row2.nationalExpirityDate!=row1.nationalExpirityDate)  {tds=tds+'<tr><td>national Expirity Date</td><td>'+row2.nationalExpirityDate+'</td><td>'+row1.nationalExpirityDate+'</td></tr>';}
    if(row2.residencePersonelPhone!=row1.residencePersonelPhone)  {tds=tds+'<tr><td>residence Personel Phone</td><td>'+row2.residencePersonelPhone+'</td><td>'+row1.residencePersonelPhone+'</td></tr>';}
    if(row2.residenceCompanyEmail!=row1.residenceCompanyEmail)  {tds=tds+'<tr><td>residence Company Email</td><td>'+row2.residenceCompanyEmail+'</td><td>'+row1.residenceCompanyEmail+'</td></tr>';}
    console.log(tds)
    this.setState({   tds: tds });
 
  };

  render() {

    const {tds}= this.state;
    return (
      <div>
        <table className="table table-striped table-bordered "  >
               <thead>
								 	<tr> 
									 	<th>Items</th> 
										 <th>Now</th>
										 <th>Before</th> 
									 </tr>
							 </thead>
               <tbody dangerouslySetInnerHTML={{__html:tds}}> 
                   
               </tbody>
            </table>
      </div>
    )
  }
}
