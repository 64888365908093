import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Button, Accordion, InputGroup } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from "prop-types";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
//import AddItemToSelectForm from "../settings/AddItemToSelects/AddItemToSelect";
//import { onNewSelectItemSubmit } from "../../redux/actions/newSelectItemAction";
import { fetchCustomerLogs } from "../../redux/actions/customerLogAction";
//import CustomerLogContact from './CustomerLogs/CustomerLogContact';

class ClientLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      hidden: true,
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",
      clientNameText: "",
      clientCodeText: "",
      itemsContacts: [],
      location: "Select",
      address: "",
      phones: [],
      faxes: [],
      webs: [],
      emails: [],
      socials: [],
      inputP: '',
      inputF: '',
      inputW: '',
      inputE: '',
      inputS: '',
      itemsRepres: [],
      repName: "",
      repDesignation: "",
      repAddresses: [],
      repMobiles: [],
      repOfficePhones: [],
      repEmails: [],
      inputRA: '',
      inputRM: '',
      inputRO: '',
      inputRE: '',
      itemsProductServ: [],
      productServ: "Select",
      productDesc: "",
      productUnit: "m3",
      productPrice: "",
      productPriceUnit: "SR",
      productSpec: "",
      productPhoto: "",
      clientId: ""
    };

    // #region tags functions

    this.handleInputChangeP = this.handleInputChangeP.bind(this);
    this.handleInputKeyDownP = this.handleInputKeyDownP.bind(this);
    this.handleRemoveItemP = this.handleRemoveItemP.bind(this);

    this.handleInputChangeF = this.handleInputChangeF.bind(this);
    this.handleInputKeyDownF = this.handleInputKeyDownF.bind(this);
    this.handleRemoveItemF = this.handleRemoveItemF.bind(this);

    this.handleInputChangeW = this.handleInputChangeW.bind(this);
    this.handleInputKeyDownW = this.handleInputKeyDownW.bind(this);
    this.handleRemoveItemW = this.handleRemoveItemW.bind(this);

    this.handleInputChangeE = this.handleInputChangeE.bind(this);
    this.handleInputKeyDownE = this.handleInputKeyDownE.bind(this);
    this.handleRemoveItemE = this.handleRemoveItemE.bind(this);

    this.handleInputChangeS = this.handleInputChangeS.bind(this);
    this.handleInputKeyDownS = this.handleInputKeyDownS.bind(this);
    this.handleRemoveItemS = this.handleRemoveItemS.bind(this);

    this.handleInputChangeRA = this.handleInputChangeRA.bind(this);
    this.handleInputKeyDownRA = this.handleInputKeyDownRA.bind(this);
    this.handleRemoveItemRA = this.handleRemoveItemRA.bind(this);

    this.handleInputChangeRM = this.handleInputChangeRM.bind(this);
    this.handleInputKeyDownRM = this.handleInputKeyDownRM.bind(this);
    this.handleRemoveItemRM = this.handleRemoveItemRM.bind(this);

    this.handleInputChangeRO = this.handleInputChangeRO.bind(this);
    this.handleInputKeyDownRO = this.handleInputKeyDownRO.bind(this);
    this.handleRemoveItemRO = this.handleRemoveItemRO.bind(this);

    this.handleInputChangeRE = this.handleInputChangeRE.bind(this);
    this.handleInputKeyDownRE = this.handleInputKeyDownRE.bind(this);
    this.handleRemoveItemRE = this.handleRemoveItemRE.bind(this);

    // #endregion tags functions
  }

  static propTypes = {
    customerLogReducer: PropTypes.shape({ selectItemList: PropTypes.array.isRequired })
  }

  componentDidMount() {
    this.props.fetchCustomerLogs();
   
  }

  // #region tags

  handleInputChangeP(evt) {
    this.setState({ inputP: evt.target.value });
  }

  handleInputKeyDownP(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        phones: [...state.phones, value],
        inputP: ''
      }));
    }

    if (this.state.phones.length && evt.keyCode === 8 && !this.state.inputP.length) {
      this.setState(state => ({
        phones: state.phones.slice(0, state.phones.length - 1)
      }));
    }
  }

  handleRemoveItemP(index) {
    return () => {
      this.setState(state => ({
        phones: state.phones.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeF(evt) {
    this.setState({ inputF: evt.target.value });
  }

  handleInputKeyDownF(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        faxes: [...state.faxes, value],
        inputF: ''
      }));
    }

    if (this.state.faxes.length && evt.keyCode === 8 && !this.state.inputF.length) {
      this.setState(state => ({
        faxes: state.faxes.slice(0, state.faxes.length - 1)
      }));
    }
  }

  handleRemoveItemF(index) {
    return () => {
      this.setState(state => ({
        faxes: state.faxes.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeW(evt) {
    this.setState({ inputW: evt.target.value });
  }

  handleInputKeyDownW(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        webs: [...state.webs, value],
        inputW: ''
      }));
    }

    if (this.state.webs.length && evt.keyCode === 8 && !this.state.inputW.length) {
      this.setState(state => ({
        webs: state.webs.slice(0, state.webs.length - 1)
      }));
    }
  }

  handleRemoveItemW(index) {
    return () => {
      this.setState(state => ({
        webs: state.webs.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeE(evt) {
    this.setState({ inputE: evt.target.value });
  }

  handleInputKeyDownE(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        emails: [...state.emails, value],
        inputE: ''
      }));
    }

    if (this.state.emails.length && evt.keyCode === 8 && !this.state.inputE.length) {
      this.setState(state => ({
        emails: state.emails.slice(0, state.emails.length - 1)
      }));
    }
  }

  handleRemoveItemE(index) {
    return () => {
      this.setState(state => ({
        emails: state.emails.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeS(evt) {
    this.setState({ inputS: evt.target.value });
  }

  handleInputKeyDownS(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        socials: [...state.socials, value],
        inputS: ''
      }));
    }

    if (this.state.socials.length && evt.keyCode === 8 && !this.state.inputS.length) {
      this.setState(state => ({
        socials: state.socials.slice(0, state.socials.length - 1)
      }));
    }
  }

  handleRemoveItemS(index) {
    return () => {
      this.setState(state => ({
        socials: state.socials.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeRA(evt) {
    this.setState({ inputRA: evt.target.value });
  }

  handleInputKeyDownRA(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        repAddresses: [...state.repAddresses, value],
        inputRA: ''
      }));
    }

    if (this.state.repAddresses.length && evt.keyCode === 8 && !this.state.inputRA.length) {
      this.setState(state => ({
        repAddresses: state.repAddresses.slice(0, state.repAddresses.length - 1)
      }));
    }
  }

  handleRemoveItemRA(index) {
    return () => {
      this.setState(state => ({
        repAddresses: state.repAddresses.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeRM(evt) {
    this.setState({ inputRM: evt.target.value });
  }

  handleInputKeyDownRM(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        repMobiles: [...state.repMobiles, value],
        inputRM: ''
      }));
    }

    if (this.state.repMobiles.length && evt.keyCode === 8 && !this.state.inputRM.length) {
      this.setState(state => ({
        repMobiles: state.repMobiles.slice(0, state.repMobiles.length - 1)
      }));
    }
  }

  handleRemoveItemRM(index) {
    return () => {
      this.setState(state => ({
        repMobiles: state.repMobiles.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeRO(evt) {
    this.setState({ inputRO: evt.target.value });
  }

  handleInputKeyDownRO(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        repOfficePhones: [...state.repOfficePhones, value],
        inputRO: ''
      }));
    }

    if (this.state.repOfficePhones.length && evt.keyCode === 8 && !this.state.inputRO.length) {
      this.setState(state => ({
        repOfficePhones: state.repOfficePhones.slice(0, state.repOfficePhones.length - 1)
      }));
    }
  }

  handleRemoveItemRO(index) {
    return () => {
      this.setState(state => ({
        repOfficePhones: state.repOfficePhones.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeRE(evt) {
    this.setState({ inputRE: evt.target.value });
  }

  handleInputKeyDownRE(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        repEmails: [...state.repEmails, value],
        inputRE: ''
      }));
    }

    if (this.state.repEmails.length && evt.keyCode === 8 && !this.state.inputRE.length) {
      this.setState(state => ({
        repEmails: state.repEmails.slice(0, state.repEmails.length - 1)
      }));
    }
  }

  handleRemoveItemRE(index) {
    return () => {
      this.setState(state => ({
        repEmails: state.repEmails.filter((item, i) => i !== index)
      }));
    }
  }

  // #endregion tags

  showOrHideModal = () => {
    this.setState({
      modalShow: !this.state.modalShow
    });
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  createNewClientButton = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Adding New",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
      this.refreshAccordions();
    }
    else {
      this.setState({
        hidden: true,
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info"
      });
      this.refreshAccordions();
    }
  }

  updateClientButton = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Client",
        lastButton: "Update",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
    else if (this.state.createNewButton === "Stop Adding New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Client",
        lastButton: "Update",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
    else if (this.state.createNewButton === "Stop Updating Client") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Client",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
  }

  // #region Refresh Accordions

  refreshAccordions = () => {
    this.setState({
      clientNameText: "",
      clientCodeText: "",
      itemsContacts: [],
      location: "Select",
      address: "",
      phones: [],
      faxes: [],
      webs: [],
      emails: [],
      socials: [],
      inputP: '',
      inputF: '',
      inputW: '',
      inputE: '',
      inputS: '',
      itemsRepres: [],
      repName: "",
      repDesignation: "",
      repAddresses: [],
      repMobiles: [],
      repOfficePhones: [],
      repEmails: [],
      inputRA: '',
      inputRM: '',
      inputRO: '',
      inputRE: '',
      itemsProductServ: [],
      productServ: "Select",
      productDesc: "",
      productUnit: "m3",
      productPrice: "",
      productPriceUnit: "SR",
      productSpec: "",
      productPhoto: "",
      clientId: ""
    });

    if (document.getElementById('client-data-status').checked === false)
      document.getElementById('client-data-status').click();
    if (document.getElementById('contact-data-status').checked === true)
      document.getElementById('contact-data-status').click();
    if (document.getElementById('repres-data-status').checked === true)
      document.getElementById('repres-data-status').click();
    if (document.getElementById('product-data-status').checked === true)
      document.getElementById('product-data-status').click();
    document.getElementById('overallAccordion').className = "collapse show";
    document.getElementById('contactAccordion').className = "collapse";
    document.getElementById('represAccordion').className = "collapse";
    document.getElementById('productAccordion').className = "collapse";
  }

  // #endregion Refresh Accordions

  onEditClick(e) {
    if (e.target.value === undefined)
      e.stopPropagation();
  }

  addContact = () => {
    this.addContactItem();
    this.setState({
      location: "Select",
      address: "",
      phones: [],
      faxes: [],
      webs: [],
      emails: [],
      socials: []
    });
  }

  addContactItem = () => {
    this.setState(state => ({
      itemsContacts: [...state.itemsContacts, {
        contactId: this.state.itemsContacts.length,
        location: this.state.location,
        address: this.state.address,
        phone: this.state.phones,
        fax: this.state.faxes,
        web: this.state.webs,
        email: this.state.emails,
        soccAcc: this.state.socials
      }]
    }));
  }

  addClientRepresentative = () => {
    this.addRepresentativeItem();
    this.setState({
      repName: "",
      repDesignation: "",
      repAddresses: [],
      repMobiles: [],
      repOfficePhones: [],
      repEmails: []
    });
  }

  addRepresentativeItem = () => {
    this.setState(state => ({
      itemsRepres: [...state.itemsRepres, {
        representiveId: this.state.itemsRepres.length,
        name: this.state.repName,
        designation: this.state.repDesignation,
        address: this.state.repAddresses,
        mobile: this.state.repMobiles,
        officePhoneNo: this.state.repOfficePhones,
        email: this.state.repEmails
      }]
    }));
  }

  addProductsService = () => {
    this.addProductServiceItem();
    this.setState({
      productServ: "Select",
      productDesc: "",
      productUnit: "m3",
      productPrice: "",
      productPriceUnit: "SR",
      productSpec: "",
      productPhoto: ""
    });
  }

  addProductServiceItem = () => {
    this.setState(state => ({
      itemsProductServ: [...state.itemsProductServ, {
        productItemId: this.state.itemsProductServ.length,
        productServ: this.state.productServ,
        description: this.state.productDesc,
        unit: this.state.productUnit,
        price: this.state.productPrice,
        priceUnit: this.state.productPriceUnit,
        spec: this.state.productSpec,
        photo: this.state.productPhoto
      }]
    }));
  }

  filenameSpecChange = e => {
    this.setState({
      productSpec: e.target.files[0].name
    });
  }

  filenamePhotoChange = e => {
    this.setState({
      productPhoto: e.target.files[0].name
    });
  }

  test = () => {
    console.log(this.state.location);
    this.addContact();
  }












  addClient = () => {
    //var clientIdFlag = 0;

    //Bu şekilde json'a çevirip veritabanına kaydedilecek.

    let state = {
      name: "Example",
      age: this.state.itemsContacts,
      description: "Some text here."
    }

    console.log(state);
    console.log(JSON.stringify(state));  //String'e atanacak.

    // if (document.getElementById('customer-data-status').checked) {
    //     await fetch(`http://localhost:4000/customers/add?name=${values.name}&code=${values.code}`)
    //         .catch(err => console.error(err));

    //     await fetch(`http://localhost:4000/customers/get?name=${values.name}&code=${values.code}`)
    //         .then(response => response.json())
    //         .then(response => customerIdFlag = response.data[0].customerId)
    //         .catch(err => console.error(err));
    // }

    // if (itemsContacts.length !== 0 && document.getElementById('contact-data-status').checked) {
    //     for (var j = 0; j < itemsContacts.length; j++) {
    //         await fetch(`http://localhost:4000/contacts/add?location=${itemsContacts[j].location}&address=${itemsContacts[j].address}&customerId=${customerIdFlag}`)
    //             .catch(err => console.error(err));
    //     }
    // }

    // if (itemsRepres.length !== 0 && document.getElementById('repres-data-status').checked) {
    //     for (var k = 0; k < itemsRepres.length; k++) {
    //         await fetch(`http://localhost:4000/representives/add?name=${itemsRepres[k].name}&designation=${itemsRepres[k].designation}&customerId=${customerIdFlag}`)
    //             .catch(err => console.error(err));
    //     }
    // }

    // if (itemsProductServ.length !== 0 && document.getElementById('product-data-status').checked) {
    //     for (k = 0; k < itemsProductServ.length; k++) {
    //         await fetch(`http://localhost:4000/productsservices/add?productService=${itemsProductServ[k].productServ}
    //             &description=${itemsProductServ[k].description}&unit=${itemsProductServ[k].unit}&price=${itemsProductServ[k].price}
    //             &priceUnit=${itemsProductServ[k].priceUnit}&spec=${itemsProductServ[k].spec}&photo=${itemsProductServ[k].photo}
    //             &customerId=${customerIdFlag}`)
    //             .catch(err => console.error(err));
    //     }
    // }

    //// getCustomers(); bunun yerine aşağıdakini yapacak. Bunu yapmayabilir de
    //// componentDidMount kendi de çalışabilir. Test et ekstra çalıştırma.
    // this.props.fetchCustomerLogs();
    // refreshAccordions();
    // createNewCustomerButton();
  }

  deleteClient = () => {

    console.log(this.state.clientId);

    // await fetch(`http://localhost:4000/deletecustomer?customerId=${customerId}`)
    //     .then(response => console.log(customerId))
    //     .catch(err => console.error(err));
    //// getCustomers(); bunun yerine aşağıdakini yapacak. Bunu yapmayabilir de
    //// componentDidMount kendi de çalışabilir. Test et ekstra çalıştırma.
    // this.props.fetchCustomerLogs();
    // refreshAccordions();
    // showOrHideModal();
  }

  updateClient = () => {

    console.log(this.state.clientId);  //Bu id üzerinden update yapılacak.
    console.log(JSON.parse('[{"id":1,"description":"TestDescription","photourl":"www.1.com","price":"500","serviceproduct":"Product","spec":1,"unit":1},{"id":2,"description":"TestDescription-2","photourl":"www.2.com","price":"600","serviceproduct":"Service","spec":1,"unit":1}]')); console.log(JSON.parse('[{"id":1,"description":"TestDescription","photourl":"www.1.com","price":"500","serviceproduct":"Product","spec":1,"unit":1},{"id":2,"description":"TestDescription-2","photourl":"www.2.com","price":"600","serviceproduct":"Service","spec":1,"unit":1}]'));

    //contact info için?

    this.updateClientButton();

    // await fetch(`http://localhost:4000/customers/getone?customerId=${id}`)
    //   .then(response => response.json())
    //   .then(response => arrayTest = response.data)
    //   .catch(err => console.error(err));

    // setValues({ ...values, ['name']: arrayTest[0].customerName, ['code']: arrayTest[0].customerCode });

    // await fetch(`http://localhost:4000/contactids?customerId=${id}`)
    //   .then(response => response.json())
    //   .then(response => arrayTest = response.data)
    //   .catch(err => console.error(err));

    // if (arrayTest.length > 0) {
    //   document.getElementById('contact-data-status').click();
    // }

    // setItemsContacts([...itemsContacts, {
    //     contactId: itemsContacts.length,
    //     location: itemContactLocation,
    //     address: itemContactAddress,
    //     phone: itemContactPhones,
    //     fax: itemContactFaxes,
    //     web: itemContactWebs,
    //     email: itemContactEmails,
    //     soccAcc: itemContactSocs
    // }]);

    // itemsContacts.map(itemsContact =>
    //     console.log(itemsContact.location)
    // )
  }












  render() {
    const selectOptions = {
      0: 'N/A',
      1: 'Service',
      2: 'Product'
    };
    const products = [
      { id: 1, name: "apples", price: `Trader Joe's`, type: 0 },
      { id: 2, name: "oranges", price: `Safeway`, type: 1 },
      { id: 3, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 4, name: "grapes", price: `Lucky's`, type: 2 },
      { id: 5, name: "oranges", price: `Safeway`, type: 0 },
      { id: 6, name: "bananas", price: `Trader Joe's`, type: 2 },
      { id: 7, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 8, name: "oranges", price: `Safeway`, type: 0 },
      { id: 9, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 10, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 11, name: "oranges", price: `Safeway`, type: 1 },
      { id: 12, name: "bananas", price: `Trader Joe's`, type: 2 },
      { id: 13, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 14, name: "grapes", price: `Lucky's`, type: 0 },
      { id: 15, name: "oranges", price: `Safeway`, type: 2 },
      { id: 16, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 17, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 18, name: "grapes", price: `Lucky's`, type: 2 },
      { id: 19, name: "oranges", price: `Safeway`, type: 1 },
      { id: 20, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 21, name: "bananas", price: `Trader Joe's`, type: 2 },
      { id: 22, name: "grapes", price: `Lucky's`, type: 0 },
      { id: 23, name: "oranges", price: `Trader Joe's`, type: 1 },
      { id: 24, name: "bananas", price: `Lucky's`, type: 2 },
      { id: 25, name: "grapes", price: `Trader Joe's`, type: 1 },
      { id: 26, name: "oranges", price: `Trader Joe's`, type: 0 }];
    const columns = [{
      dataField: 'id',
      text: 'Product ID',
      footer: 'Product ID',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'inStock',
      text: 'Actions',
      footer: 'Actions',
      headerStyle: {
        verticalAlign: 'top',
        cursor: 'zoom-in'
      },
      headerEvents: {
        onClick: () => {
          if (document.getElementById('text-filter-column-Product Name').style.display === "none") {
            document.getElementById('text-filter-column-Product ID').style.display = "block";
            document.getElementById('text-filter-column-Product Name').style.display = "block";
            document.getElementById('text-filter-column-Product Price').style.display = "block";
            document.getElementById('select-filter-column-Product Type').style.display = "block";
          }
          else {
            document.getElementById('text-filter-column-Product ID').style.display = "none";
            document.getElementById('text-filter-column-Product Name').style.display = "none";
            document.getElementById('text-filter-column-Product Price').style.display = "none";
            document.getElementById('select-filter-column-Product Type').style.display = "none";
          }
        }
      },
      formatter: () => (
        <DropdownButton
          size="sm"
          variant="secondary"
          title="..."
          id={`dropdown-button-drop`}
        >
          <Dropdown.Item onClick={this.updateClient} eventKey="1">Edit</Dropdown.Item>
          <Dropdown.Item onClick={this.showOrHideModal} eventKey="2">Delete</Dropdown.Item>
        </DropdownButton>
      )
    },
    {
      dataField: 'name',
      text: 'Product Name',
      footer: 'Product Name',
      sort: true,
      filter: textFilter()
    }, {
      dataField: 'price',
      text: 'Product Price',
      footer: 'Product Price',
      sort: true,
      filter: textFilter()
    }, {
      dataField: 'type',
      text: 'Product Type',
      footer: 'Product Type',
      sort: true,
      formatter: cell => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions
      })
    }];
    const { SearchBar } = Search;
    const options = {
      showTotal: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: products.length
      }]
    };
    const styles = {
      container: {
        border: '1px solid #ddd',
        padding: '5px',
        borderRadius: '5px',
      },
      items: {
        display: 'inline-block',
        padding: '2px',
        border: '1px solid blue',
        fontFamily: 'Helvetica, sans-serif',
        borderRadius: '5px',
        marginRight: '5px',
        cursor: 'pointer'
      },
      input: {
        outline: 'none',
        border: 'none',
        fontSize: '14px',
        fontFamily: 'Helvetica, sans-serif'
      },
    };
    return (
      <div>
        {/* <div>
          <h1>Merhaba!</h1>
          <AddItemToSelectForm onNewSelectItemSubmit={this.props.onNewSelectItemSubmit} />
        </div>
        */}
        <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
          <Row>
            <Col xs={2}>
              <Modal
                show={this.state.modalShow}
                onHide={this.showOrHideModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">Delete All of Selected Client Logs?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>All logs of this client will be deleted. Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                  <Button variant="danger" onClick={this.deleteClient}>Delete</Button>
                </Modal.Footer>
              </Modal></Col>
            <Col xs={10}></Col>
          </Row>
        </Container>

        <ToolkitProvider
          keyField="id"
          data={products}
          columns={columns}
          search
          bootstrap4
        >
          {
            props => (
              <div style={{ backgroundColor: "white", padding: "10px" }}>
                <Row>
                  <Col xs={3}><SearchBar {...props.searchProps} /></Col>
                  <Col xs={6}></Col>
                  <Col xs={3}><Button onClick={this.createNewClientButton} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                </Row>
                <BootstrapTable striped hover bordered keyField='id' data={products} columns={columns} filter={filterFactory()} pagination={paginationFactory(options)}
                  {...props.baseProps} />
              </div>
            )
          }
        </ToolkitProvider>

        <br />

        {/*<Table responsive striped bordered hover>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>S/N</th>
              <th>Actions</th>
              <th>Client Code</th>
              <th>Client Name</th>
              <th>Contact</th>
              <th>Representative</th>
              <th>Driver</th>
              <th>Item No</th>
              <th>Product/Item Description</th>
              <th>Item Specification</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            {this.props.customerLogReducer.selectItemList.map((e, key) => (
              <tr key={e.id}>
                <td>{e.id}</td>
                <td>
                  <DropdownButton
                    size="sm"
                    variant="secondary"
                    title="..."
                    id={`dropdown-button-drop`}
                    onClick={() => this.setState({
                      clientId: e.id
                    })}
                  >
                    {/*<Dropdown.Item onClick={() => updateCustomer(customer.customerId)} eventKey="1">Edit</Dropdown.Item>
                    <Dropdown.Item onClick={this.updateClient} eventKey="1">Edit</Dropdown.Item>
                    <Dropdown.Item onClick={this.showOrHideModal} eventKey="2">Delete</Dropdown.Item>
                  </DropdownButton>
                </td>
                <td>{e.text}</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
            ))}
          </tbody>
        </Table>*/}

        <div hidden={this.state.hidden} className="col-md-12">
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Overall</Card.Header></Col>
                  <Col xs={2}>
                    <Toggle
                      defaultChecked={true}
                      icons={false}
                      id='client-data-status' />
                    <label htmlFor='client-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="overallAccordion" eventKey="0" >
                <Card.Body>
                  <Form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Client Name:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="clientNameText" required value={this.state.clientNameText}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Client Code:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="clientCodeText" required value={this.state.clientCodeText}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Contact Info</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='contact-data-status' />
                    <label htmlFor='contact-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="contactAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={10}></Col>
                      <Col xs={2}><Button onClick={this.addContact} style={{ width: '100%' }} variant="info">Add New...</Button></Col>
                    </Row>
                    <br />
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>Location</th>
                          <th>Address</th>
                          <th>Phone</th>
                          <th>Fax</th>
                          <th>Web</th>
                          <th>E-mail</th>
                          <th>Social Accounts</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.itemsContacts.map((itemsContact, index) =>
                          <tr key={index}>
                            <td>{itemsContact.location}</td>
                            <td>{itemsContact.address}</td>
                            <td>{itemsContact.phone}</td>
                            <td>{itemsContact.fax}</td>
                            <td>{itemsContact.web}</td>
                            <td>{itemsContact.email}</td>
                            <td>{itemsContact.soccAcc}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <br />
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridLocation">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }} >Location</Form.Label>
                        </div>
                        <Form.Control name="location" value={this.state.location}
                          onChange={this.handleChange} as="select">
                          <option>Select</option>
                          <option>Add New</option>
                          <option>Head Office</option>
                          <option>Branche</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridAddress">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Address</Form.Label>
                        </div>
                        <Form.Control name="address" value={this.state.address}
                          onChange={this.handleChange} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridPhone">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Phone</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.phones.map((phone, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemP(i)}>
                                {phone}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputP}
                              onChange={this.handleInputChangeP}
                              onKeyDown={this.handleInputKeyDownP} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridFax">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Fax</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.faxes.map((fax, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemF(i)}>
                                {fax}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputF}
                              onChange={this.handleInputChangeF}
                              onKeyDown={this.handleInputKeyDownF} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridWeb">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Web</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.webs.map((web, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemW(i)}>
                                {web}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputW}
                              onChange={this.handleInputChangeW}
                              onKeyDown={this.handleInputKeyDownW} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>E-Mail</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.emails.map((email, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemE(i)}>
                                {email}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputE}
                              onChange={this.handleInputChangeE}
                              onKeyDown={this.handleInputKeyDownE} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridFax">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Social Accounts</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.socials.map((social, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemS(i)}>
                                {social}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputS}
                              onChange={this.handleInputChangeS}
                              onKeyDown={this.handleInputKeyDownS} />
                          </ul>
                        </label>
                      </Form.Group>
                    </Form.Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Client Representative</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='repres-data-status' />
                    <label htmlFor='repres-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="represAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={10}></Col>
                      <Col xs={2}><Button onClick={this.addClientRepresentative} style={{ width: '100%' }} variant="info">Add New...</Button></Col>
                    </Row>
                  </Container>
                  <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Address</th>
                        <th>Mobile</th>
                        <th>Office Phone No</th>
                        <th>E-mail</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.itemsRepres.map(itemsRepre => (
                        <tr key={itemsRepre.representiveId}>
                          <td>{itemsRepre.name}</td>
                          <td>{itemsRepre.designation}</td>
                          <td>{itemsRepre.address}</td>
                          <td>{itemsRepre.mobile}</td>
                          <td>{itemsRepre.officePhoneNo}</td>
                          <td>{itemsRepre.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridRepName">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Name</Form.Label>
                      </div>
                      <Form.Control name="repName" value={this.state.repName}
                        onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridRepDesignation">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>Designation</Form.Label>
                      </div>
                      <Form.Control name="repDesignation" value={this.state.repDesignation}
                        onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridRepAddress">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>Address</Form.Label>
                      </div>
                      <label style={{ width: '100%' }}>
                        <ul style={styles.container}>
                          {this.state.repAddresses.map((repAddress, i) =>
                            <li key={i} style={styles.items} onClick={this.handleRemoveItemRA(i)}>
                              {repAddress}
                              <span>(x)</span>
                            </li>
                          )}
                          <input
                            style={styles.input}
                            value={this.state.inputRA}
                            onChange={this.handleInputChangeRA}
                            onKeyDown={this.handleInputKeyDownRA} />
                        </ul>
                      </label>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridRepMobileAddress">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>Mobile</Form.Label>
                      </div>
                      <label style={{ width: '100%' }}>
                        <ul style={styles.container}>
                          {this.state.repMobiles.map((repMobile, i) =>
                            <li key={i} style={styles.items} onClick={this.handleRemoveItemRM(i)}>
                              {repMobile}
                              <span>(x)</span>
                            </li>
                          )}
                          <input
                            style={styles.input}
                            value={this.state.inputRM}
                            onChange={this.handleInputChangeRM}
                            onKeyDown={this.handleInputKeyDownRM} />
                        </ul>
                      </label>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridRepOfficePhone">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>Office Phone</Form.Label>
                      </div>
                      <label style={{ width: '100%' }}>
                        <ul style={styles.container}>
                          {this.state.repOfficePhones.map((repOfficePhone, i) =>
                            <li key={i} style={styles.items} onClick={this.handleRemoveItemRO(i)}>
                              {repOfficePhone}
                              <span>(x)</span>
                            </li>
                          )}
                          <input
                            style={styles.input}
                            value={this.state.inputRO}
                            onChange={this.handleInputChangeRO}
                            onKeyDown={this.handleInputKeyDownRO} />
                        </ul>
                      </label>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddress">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>E-mail</Form.Label>
                      </div>
                      <label style={{ width: '100%' }}>
                        <ul style={styles.container}>
                          {this.state.repEmails.map((repEmail, i) =>
                            <li key={i} style={styles.items} onClick={this.handleRemoveItemRE(i)}>
                              {repEmail}
                              <span>(x)</span>
                            </li>
                          )}
                          <input
                            style={styles.input}
                            value={this.state.inputRE}
                            onChange={this.handleInputChangeRE}
                            onKeyDown={this.handleInputKeyDownRE} />
                        </ul>
                      </label>
                    </Form.Group>

                  </Form.Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">About Product/Service</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='product-data-status' />
                    <label htmlFor='product-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="productAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={10}></Col>
                      <Col xs={2}><Button onClick={this.addProductsService} style={{ width: '100%' }} variant="info">Add New...</Button></Col>
                    </Row>
                  </Container>
                  <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>Product/Service</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Price</th>
                        <th>Spec</th>
                        <th>Photo</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.itemsProductServ.map(itemsProductSer => (
                        <tr key={itemsProductSer.productItemId}>
                          <td>{itemsProductSer.productServ}</td>
                          <td>{itemsProductSer.description}</td>
                          <td>{itemsProductSer.unit}</td>
                          <td>{itemsProductSer.price}&nbsp;{itemsProductSer.priceUnit}</td>
                          <td>{itemsProductSer.spec}</td>
                          <td>{itemsProductSer.photo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                  <Form.Row>

                    <Form.Group as={Col} controlId="formGridProductServ">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Product/Service</Form.Label>
                      </div>
                      <Form.Control name="productServ" value={this.state.productServ}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Product</option>
                        <option>Service</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridRepDescription">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Description</Form.Label>
                      </div>
                      <Form.Control name="productDesc" value={this.state.productDesc}
                        onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridUnit">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Unit</Form.Label>
                      </div>
                      <Form.Control name="productUnit" value={this.state.productUnit}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Liter</option>
                        <option>Ton</option>
                        <option>kg</option>
                        <option>m3</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPrice">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Price</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control name="productPrice" value={this.state.productPrice}
                          onChange={this.handleChange} />
                        <Form.Control style={{ marginLeft: '10px' }} name="productPriceUnit" value={this.state.productPriceUnit}
                          onChange={this.handleChange} as="select" >
                          <option>SR</option>
                          <option>USD</option>
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddSpec">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>Add Spec</Form.Label>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <div className='custom-file'>
                          <input
                            type='file'
                            className='custom-file-input'
                            id='customFile'
                            onChange={this.filenameSpecChange}
                          />
                          <label className='custom-file-label' htmlFor='customFile'>
                            {this.state.productSpec}
                          </label>
                        </div>
                      </div>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAddPhoto">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }}>Add Photo</Form.Label>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <div className='custom-file'>
                          <input
                            type='file'
                            className='custom-file-input'
                            id='customFile'
                            onChange={this.filenamePhotoChange}
                          />
                          <label className='custom-file-label' htmlFor='customFile'>
                            {this.state.productPhoto}
                          </label>
                        </div>
                      </div>
                    </Form.Group>
                  </Form.Row>



                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
            <Row>
              <Button onClick={this.addClient} style={{ width: '100%' }} variant="info">{this.state.lastButton}</Button>
            </Row>
          </Container>
        </div>

      </div>


    )
  }
}

const mapStateToProps = ({ customerLogReducer }) => {
  return {
    customerLogReducer
  }
}

const mapDispatchToProps = {
  fetchCustomerLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientLog)