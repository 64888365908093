/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Card } from "primereact/card";
import { Checkbox, Select, InputNumber, Table, message } from 'antd';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import $ from 'jquery';

const { Option } = Select;
const { Column } = Table;

export class RentalTruckAgreement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            rentalTruckAgreements: [],
            rentalCost: 0,
            rentalCostCurrency: "SR",
            rentalCostSelect: "Km",
            fuelCB: false,
            fuel: 0,
            fuelSelect: "Km",
            maintenanceCB: false,
            maintenance: 0,
            maintenanceSelect: "Km",
            repairsCB: false,
            repairs: 0,
            repairsSelect: "Km",
            insuranceCB: false,
            insurance: 0,
            insuranceSelect: "Km",
            taxesCB: false,
            taxes: 0,
            taxesSelect: "Km",
            feesCB: false,
            fees: 0,
            feesSelect: "Km",
            driverCB: false,
            driver: 0,
            driverSelect: "Km",
            driverCostsHidden: true,
            salaryCB: false,
            salary: 0,
            salarySelect: "Km",
            mealCB: false,
            meal: 0,
            mealSelect: "Km",
            accomCB: false,
            accom: 0,
            accomSelect: "Km",
            driverFeesCB: false,
            driverFees: 0,
            driverFeesSelect: "Km",
            driverInsuranceCB: false,
            driverInsurance: 0,
            driverInsuranceSelect: "Km",
            rentalTruckAgreementId: 0,
            editedRentalTruckAgreementId: 0,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        };
    }

    componentDidMount = async () => {
        const responseRentalTruckAgreement = await handleRequest("GET", "/RentalTruckAgreement/getAll");

        if (responseRentalTruckAgreement.data.length === 0) {
            this.setState({
                rentalTruckAgreements: []
            });
        }

        if (responseRentalTruckAgreement.data.length !== 0) {
            this.setState({
                rentalTruckAgreements: responseRentalTruckAgreement.data,
            });
        }
    }

    deleteModal = (row) => {
        this.setState({
            rentalTruckAgreementId: row.id,
            modalShow: true
        });
    }

    delete = async () => {
        const deletedRentalTruckAgreement = {
            id: this.state.rentalTruckAgreementId
        }

        await handleRequest("POST", "/RentalTruckAgreement/delete", deletedRentalTruckAgreement);

        this.restartTable();
        message.success(`The operation is successful.`);
        this.showOrHideModal();
    }

    showOrHideModal = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    edit = async (rowData) => {
        this.setState({
            rentalCost: rowData.rentalCost,
            rentalCostCurrency: rowData.rentalCostCurrency,
            rentalCostSelect: rowData.rentalCostSelect,
            fuelCB: rowData.fuelCB,
            fuel: rowData.fuel,
            fuelSelect: rowData.fuelSelect,
            maintenanceCB: rowData.maintenanceCB,
            maintenance: rowData.maintenance,
            maintenanceSelect: rowData.maintenanceSelect,
            repairsCB: rowData.repairsCB,
            repairs: rowData.repairs,
            repairsSelect: rowData.repairsSelect,
            insuranceCB: rowData.insuranceCB,
            insurance: rowData.insurance,
            insuranceSelect: rowData.insuranceSelect,
            taxesCB: rowData.taxesCB,
            taxes: rowData.taxes,
            taxesSelect: rowData.taxesSelect,
            feesCB: rowData.feesCB,
            fees: rowData.fees,
            feesSelect: rowData.feesSelect,
            driverCB: rowData.driverCB,
            driver: rowData.driver,
            driverSelect: rowData.driverSelect,
            salaryCB: rowData.salaryCB,
            salary: rowData.salary,
            salarySelect: rowData.salarySelect,
            mealCB: rowData.mealCB,
            meal: rowData.meal,
            mealSelect: rowData.mealSelect,
            accomCB: rowData.accomCB,
            accom: rowData.accom,
            accomSelect: rowData.accomSelect,
            driverFeesCB: rowData.driverFeesCB,
            driverFees: rowData.driverFees,
            driverFeesSelect: rowData.driverFeesSelect,
            driverInsuranceCB: rowData.driverInsuranceCB,
            driverInsurance: rowData.driverInsurance,
            driverInsuranceSelect: rowData.driverInsuranceSelect,
            editedRentalTruckAgreementId: rowData.id,
            lastButtonName: "Update",
            lastButtonVariant: "warning"
        });
    }

    saveAll = async () => {
        if (this.state.editedRentalTruckAgreementId === 0) {
            const newRentalTruckAgreement = {
                rentalCost: this.state.rentalCost,
                rentalCostCurrency: this.state.rentalCostCurrency,
                rentalCostSelect: this.state.rentalCostSelect,
                fuelCB: this.state.fuelCB,
                fuel: this.state.fuel,
                fuelSelect: this.state.fuelSelect,
                maintenanceCB: this.state.maintenanceCB,
                maintenance: this.state.maintenance,
                maintenanceSelect: this.state.maintenanceSelect,
                repairsCB: this.state.repairsCB,
                repairs: this.state.repairs,
                repairsSelect: this.state.repairsSelect,
                insuranceCB: this.state.insuranceCB,
                insurance: this.state.insurance,
                insuranceSelect: this.state.insuranceSelect,
                taxesCB: this.state.taxesCB,
                taxes: this.state.taxes,
                taxesSelect: this.state.taxesSelect,
                feesCB: this.state.feesCB,
                fees: this.state.fees,
                feesSelect: this.state.feesSelect,
                driverCB: this.state.driverCB,
                driver: this.state.driver,
                driverSelect: this.state.driverSelect,
                salaryCB: this.state.salaryCB,
                salary: this.state.salary,
                salarySelect: this.state.salarySelect,
                mealCB: this.state.mealCB,
                meal: this.state.meal,
                mealSelect: this.state.mealSelect,
                accomCB: this.state.accomCB,
                accom: this.state.accom,
                accomSelect: this.state.accomSelect,
                driverFeesCB: this.state.driverFeesCB,
                driverFees: this.state.driverFees,
                driverFeesSelect: this.state.driverFeesSelect,
                driverInsuranceCB: this.state.driverInsuranceCB,
                driverInsurance: this.state.driverInsurance,
                driverInsuranceSelect: this.state.driverInsuranceSelect
            }

            await handleRequest("POST", "/RentalTruckAgreement/add", newRentalTruckAgreement);

            message.success(`The operation is successful.`);
            this.restartTable();
            this.refreshValues();
        }
        else {
            const updatedRentalTruckAgreement = {
                id: this.state.editedRentalTruckAgreementId,
                rentalCost: this.state.rentalCost,
                rentalCostCurrency: this.state.rentalCostCurrency,
                rentalCostSelect: this.state.rentalCostSelect,
                fuelCB: this.state.fuelCB,
                fuel: this.state.fuel,
                fuelSelect: this.state.fuelSelect,
                maintenanceCB: this.state.maintenanceCB,
                maintenance: this.state.maintenance,
                maintenanceSelect: this.state.maintenanceSelect,
                repairsCB: this.state.repairsCB,
                repairs: this.state.repairs,
                repairsSelect: this.state.repairsSelect,
                insuranceCB: this.state.insuranceCB,
                insurance: this.state.insurance,
                insuranceSelect: this.state.insuranceSelect,
                taxesCB: this.state.taxesCB,
                taxes: this.state.taxes,
                taxesSelect: this.state.taxesSelect,
                feesCB: this.state.feesCB,
                fees: this.state.fees,
                feesSelect: this.state.feesSelect,
                driverCB: this.state.driverCB,
                driver: this.state.driver,
                driverSelect: this.state.driverSelect,
                salaryCB: this.state.salaryCB,
                salary: this.state.salary,
                salarySelect: this.state.salarySelect,
                mealCB: this.state.mealCB,
                meal: this.state.meal,
                mealSelect: this.state.mealSelect,
                accomCB: this.state.accomCB,
                accom: this.state.accom,
                accomSelect: this.state.accomSelect,
                driverFeesCB: this.state.driverFeesCB,
                driverFees: this.state.driverFees,
                driverFeesSelect: this.state.driverFeesSelect,
                driverInsuranceCB: this.state.driverInsuranceCB,
                driverInsurance: this.state.driverInsurance,
                driverInsuranceSelect: this.state.driverInsuranceSelect
            }

            await handleRequest("POST", "/RentalTruckAgreement/update", updatedRentalTruckAgreement);

            message.success(`The operation is successful.`);
            this.restartTable();
            this.refreshValues();
        }
    }

    restartTable = async () => {
        const responseRentalTruckAgreement = await handleRequest("GET", "/RentalTruckAgreement/getAll");

        if (responseRentalTruckAgreement.data.length === 0) {
            this.setState({
                rentalTruckAgreements: []
            });
        }

        if (responseRentalTruckAgreement.data.length !== 0) {
            this.setState({
                rentalTruckAgreements: responseRentalTruckAgreement.data,
            });
        }
    }

    refreshValues = () => {
        this.setState({
            rentalCost: 0,
            rentalCostCurrency: "SR",
            rentalCostSelect: "Km",
            fuelCB: false,
            fuel: 0,
            fuelSelect: "Km",
            maintenanceCB: false,
            maintenance: 0,
            maintenanceSelect: "Km",
            repairsCB: false,
            repairs: 0,
            repairsSelect: "Km",
            insuranceCB: false,
            insurance: 0,
            insuranceSelect: "Km",
            taxesCB: false,
            taxes: 0,
            taxesSelect: "Km",
            feesCB: false,
            fees: 0,
            feesSelect: "Km",
            driverCostsHidden: true,
            driverCB: false,
            driver: 0,
            driverSelect: "Km",
            salaryCB: false,
            salary: 0,
            salarySelect: "Km",
            mealCB: false,
            meal: 0,
            mealSelect: "Km",
            accomCB: false,
            accom: 0,
            accomSelect: "Km",
            driverFeesCB: false,
            driverFees: 0,
            driverFeesSelect: "Km",
            driverInsuranceCB: false,
            driverInsurance: 0,
            driverInsuranceSelect: "Km",
            editedRentalTruckAgreementId: 0,
            selectedRow: null,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        });
    }

    onChangeFuelCB = (e) => {
        this.setState({
            fuelCB: e.target.checked
        });
    }

    onChangeMaintenanceCB = (e) => {
        this.setState({
            maintenanceCB: e.target.checked
        });
    }

    onChangeRepairsCB = (e) => {
        this.setState({
            repairsCB: e.target.checked
        });
    }

    onChangeInsuranceCB = (e) => {
        this.setState({
            insuranceCB: e.target.checked
        });
    }

    onChangeTaxesCB = (e) => {
        this.setState({
            taxesCB: e.target.checked
        });
    }

    onChangeFeesCB = (e) => {
        this.setState({
            feesCB: e.target.checked
        });
    }

    onChangeDriverCB = (e) => {
        this.setState({
            driverCB: e.target.checked,
            driverCostsHidden: !e.target.checked
        });

        if (!e.target.checked) {
            this.setState({
                driver: 0,
                salary: 0,
                salaryCB: false,
                salarySelect: "Km",
                meal: 0,
                mealCB: false,
                mealSelect: "Km",
                accom: 0,
                accomCB: false,
                accomSelect: "Km",
                accom: 0,
                accomCB: false,
                accomSelect: "Km",
                driverFees: 0,
                driverFeesCB: false,
                driverFeesSelect: "Km",
                driverInsurance: 0,
                driverInsuranceCB: false,
                driverInsuranceSelect: "Km"
            })
        }
    }

    onChangeSalaryCB = (e) => {
        this.setState({
            salaryCB: e.target.checked
        });

        let driverCost = Number(this.state.driver);

        if (e.target.checked) {
            driverCost += Number(this.state.salary);
        } else {
            driverCost -= Number(this.state.salary);
        }

        this.setState({
            driver: driverCost
        });
    }

    onChangeMealCB = (e) => {
        this.setState({
            mealCB: e.target.checked
        });

        let driverCost = Number(this.state.driver);

        if (e.target.checked) {
            driverCost += Number(this.state.meal);
        } else {
            driverCost -= Number(this.state.meal);
        }

        this.setState({
            driver: driverCost
        });
    }

    onChangeAccomCB = (e) => {
        this.setState({
            accomCB: e.target.checked
        });

        let driverCost = Number(this.state.driver);

        if (e.target.checked) {
            driverCost += Number(this.state.accom);
        } else {
            driverCost -= Number(this.state.accom);
        }

        this.setState({
            driver: driverCost
        });
    }

    onChangeDriverFeesCB = (e) => {
        this.setState({
            driverFeesCB: e.target.checked
        });

        let driverCost = Number(this.state.driver);

        if (e.target.checked) {
            driverCost += Number(this.state.driverFees);
        } else {
            driverCost -= Number(this.state.driverFees);
        }

        this.setState({
            driver: driverCost
        });
    }

    onChangeDriverInsuranceCB = (e) => {
        this.setState({
            driverInsuranceCB: e.target.checked
        });

        let driverCost = Number(this.state.driver);

        if (e.target.checked) {
            driverCost += Number(this.state.driverInsurance);

            this.setState({
                driver: driverCost
            });
        } else {
            driverCost -= Number(this.state.driverInsurance);

            this.setState({
                driver: driverCost
            });
        }
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Rental Truck Agreement Data?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>All informations about rental truck agreement data will be deleted. Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Rental Cost:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.rentalCost}
                                onChange={(value) => {
                                    this.setState({
                                        rentalCost: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="SR" value={this.state.rentalCostCurrency} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        rentalCostCurrency: value
                                    });
                                }}>
                                <Option value="SR">SR</Option>
                                <Option value="USD">USD</Option>
                            </Select>
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.rentalCostSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        rentalCostSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={5}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Rental Agreement Includes Belows:</Form.Label>
                        </Col>
                        <Col xs={1}>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeFuelCB} checked={this.state.fuelCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Fuel:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.fuel}
                                onChange={(value) => {
                                    this.setState({
                                        fuel: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.fuelSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        fuelSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeMaintenanceCB} checked={this.state.maintenanceCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Maintenance:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.maintenance}
                                onChange={(value) => {
                                    this.setState({
                                        maintenance: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.maintenanceSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        maintenanceSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeRepairsCB} checked={this.state.repairsCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Repairs:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.repairs}
                                onChange={(value) => {
                                    this.setState({
                                        repairs: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.repairsSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        repairsSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeInsuranceCB} checked={this.state.insuranceCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Insurance:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.insurance}
                                onChange={(value) => {
                                    this.setState({
                                        insurance: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.insuranceSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        insuranceSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeTaxesCB} checked={this.state.taxesCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Taxes:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.taxes}
                                onChange={(value) => {
                                    this.setState({
                                        taxes: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.taxesSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        taxesSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeFeesCB} checked={this.state.feesCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Fees:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.fees}
                                onChange={(value) => {
                                    this.setState({
                                        fees: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.feesSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        feesSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={2}>
                        </Col>
                        <Col xs={2} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                            <Checkbox textAlign="left" onChange={this.onChangeDriverCB} checked={this.state.driverCB} ></Checkbox>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Driver:</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.driver}
                                onChange={(value) => {
                                    this.setState({
                                        driver: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.driverSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        driverSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <div hidden={this.state.driverCostsHidden} >
                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={4}>
                            </Col>
                            <Col xs={1} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                <Checkbox textAlign="left" onChange={this.onChangeSalaryCB} checked={this.state.salaryCB} ></Checkbox>
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Salary:</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <InputNumber style={{ width: '100%' }} min={0} value={this.state.salary}
                                    onChange={(value) => {
                                        let driverCost = Number(this.state.driver);

                                        if (this.state.salaryCB) {
                                            driverCost += Number(value);

                                            this.setState({
                                                driver: driverCost
                                            });
                                        }

                                        this.setState({
                                            salary: value
                                        });
                                    }} />
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <Select defaultValue="Km" value={this.state.salarySelect} style={{ width: '100%' }}
                                    onChange={(value) => {
                                        this.setState({
                                            salarySelect: value
                                        });
                                    }}>
                                    <Option value="Km">Km</Option>
                                    <Option value="Trip">Trip</Option>
                                    <Option value="Day">Day</Option>
                                    <Option value="Week">Week</Option>
                                    <Option value="Month">Month</Option>
                                    <Option value="Year">Year</Option>
                                    <Option value="m3">m3</Option>
                                    <Option value="Ton">Ton</Option>
                                </Select>
                            </Col>
                            <Col xs={4}>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={4}>
                            </Col>
                            <Col xs={1} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                <Checkbox textAlign="left" onChange={this.onChangeMealCB} checked={this.state.mealCB} ></Checkbox>
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Meal:</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <InputNumber style={{ width: '100%' }} min={0} value={this.state.meal}
                                    onChange={(value) => {
                                        let driverCost = Number(this.state.driver);

                                        if (this.state.mealCB) {
                                            driverCost += Number(value);

                                            this.setState({
                                                driver: driverCost
                                            });
                                        }

                                        this.setState({
                                            meal: value
                                        });
                                    }} />
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <Select defaultValue="Km" value={this.state.mealSelect} style={{ width: '100%' }}
                                    onChange={(value) => {
                                        this.setState({
                                            mealSelect: value
                                        });
                                    }}>
                                    <Option value="Km">Km</Option>
                                    <Option value="Trip">Trip</Option>
                                    <Option value="Day">Day</Option>
                                    <Option value="Week">Week</Option>
                                    <Option value="Month">Month</Option>
                                    <Option value="Year">Year</Option>
                                    <Option value="m3">m3</Option>
                                    <Option value="Ton">Ton</Option>
                                </Select>
                            </Col>
                            <Col xs={4}>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={4}>
                            </Col>
                            <Col xs={1} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                <Checkbox textAlign="left" onChange={this.onChangeAccomCB} checked={this.state.accomCB} ></Checkbox>
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Accom.:</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <InputNumber style={{ width: '100%' }} min={0} value={this.state.accom}
                                    onChange={(value) => {
                                        let driverCost = Number(this.state.driver);

                                        if (this.state.accomCB) {
                                            driverCost += Number(value);

                                            this.setState({
                                                driver: driverCost
                                            });
                                        }

                                        this.setState({
                                            accom: value
                                        });
                                    }} />
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <Select defaultValue="Km" value={this.state.accomSelect} style={{ width: '100%' }}
                                    onChange={(value) => {
                                        this.setState({
                                            accomSelect: value
                                        });
                                    }}>
                                    <Option value="Km">Km</Option>
                                    <Option value="Trip">Trip</Option>
                                    <Option value="Day">Day</Option>
                                    <Option value="Week">Week</Option>
                                    <Option value="Month">Month</Option>
                                    <Option value="Year">Year</Option>
                                    <Option value="m3">m3</Option>
                                    <Option value="Ton">Ton</Option>
                                </Select>
                            </Col>
                            <Col xs={4}>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={4}>
                            </Col>
                            <Col xs={1} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                <Checkbox textAlign="left" onChange={this.onChangeDriverFeesCB} checked={this.state.driverFeesCB} ></Checkbox>
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Fees:</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <InputNumber style={{ width: '100%' }} min={0} value={this.state.driverFees}
                                    onChange={(value) => {
                                        let driverCost = Number(this.state.driver);

                                        if (this.state.driverFeesCB) {
                                            driverCost += Number(value);

                                            this.setState({
                                                driver: driverCost
                                            });
                                        }

                                        this.setState({
                                            driverFees: value
                                        });
                                    }} />
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <Select defaultValue="Km" value={this.state.driverFeesSelect} style={{ width: '100%' }}
                                    onChange={(value) => {
                                        this.setState({
                                            driverFeesSelect: value
                                        });
                                    }}>
                                    <Option value="Km">Km</Option>
                                    <Option value="Trip">Trip</Option>
                                    <Option value="Day">Day</Option>
                                    <Option value="Week">Week</Option>
                                    <Option value="Month">Month</Option>
                                    <Option value="Year">Year</Option>
                                    <Option value="m3">m3</Option>
                                    <Option value="Ton">Ton</Option>
                                </Select>
                            </Col>
                            <Col xs={4}>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={4}>
                            </Col>
                            <Col xs={1} style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                <Checkbox textAlign="left" onChange={this.onChangeDriverInsuranceCB} checked={this.state.driverInsuranceCB} ></Checkbox>
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Insurance:</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <InputNumber style={{ width: '100%' }} min={0} value={this.state.driverInsurance}
                                    onChange={(value) => {
                                        let driverCost = Number(this.state.driver);

                                        if (this.state.driverInsuranceCB) {
                                            driverCost += Number(value);

                                            this.setState({
                                                driver: driverCost
                                            });
                                        }

                                        this.setState({
                                            driverInsurance: value
                                        });
                                    }} />
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                            </Col>
                            <Col xs={1}>
                                <Select defaultValue="Km" value={this.state.driverInsuranceSelect} style={{ width: '100%' }}
                                    onChange={(value) => {
                                        this.setState({
                                            driverInsuranceSelect: value
                                        });
                                    }}>
                                    <Option value="Km">Km</Option>
                                    <Option value="Trip">Trip</Option>
                                    <Option value="Day">Day</Option>
                                    <Option value="Week">Week</Option>
                                    <Option value="Month">Month</Option>
                                    <Option value="Year">Year</Option>
                                    <Option value="m3">m3</Option>
                                    <Option value="Ton">Ton</Option>
                                </Select>
                            </Col>
                            <Col xs={4}>
                            </Col>
                        </Row>
                    </div>
                    <Row style={{ marginTop: "20px", width: "100%" }}>
                        <Col xs={4}>
                        </Col>
                        <Col xs={4}>
                            <Button style={{ width: '100%' }} variant={this.state.lastButtonVariant} onClick={this.saveAll} >{this.state.lastButtonName}</Button>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                </Card>
                <hr />
                <Card>
                    <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.rentalTruckAgreements} bordered>
                        <Column
                            width='50px'
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <div style={{ textAlign: "center" }}>
                                    <React.Fragment key={record.id} > <CustomMaterialMenu row={record}
                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
                                    </React.Fragment>
                                </div>
                            )}
                        />
                        <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                        <Column title="Rental Cost" key="rentalCost" render={(row) => {
                            return row.rentalCost + " " + row.rentalCostCurrency + " per " + row.rentalCostSelect;
                        }} />
                        <Column title="Fuel" key="fuel" render={(row) => {
                            if (row.fuelCB)
                                return row.fuel + " " + row.rentalCostCurrency + " per " + row.fuelSelect;
                            else
                                return "";
                        }} />
                        <Column title="Maintenance" key="maintenance" render={(row) => {
                            if (row.maintenanceCB)
                                return row.maintenance + " " + row.rentalCostCurrency + " per " + row.maintenanceSelect;
                            else
                                return "";
                        }} />
                        <Column title="Repairs" key="repairs" render={(row) => {
                            if (row.repairsCB)
                                return row.repairs + " " + row.rentalCostCurrency + " per " + row.repairsSelect;
                            else
                                return "";
                        }} />
                        <Column title="Insurance" key="insurance" render={(row) => {
                            if (row.insuranceCB)
                                return row.insurance + " " + row.rentalCostCurrency + " per " + row.insuranceSelect;
                            else
                                return "";
                        }} />
                        <Column title="Taxes" key="taxes" render={(row) => {
                            if (row.taxesCB)
                                return row.taxes + " " + row.rentalCostCurrency + " per " + row.taxesSelect;
                            else
                                return "";
                        }} />
                        <Column title="Fees" key="fees" render={(row) => {
                            if (row.feesCB)
                                return row.fees + " " + row.rentalCostCurrency + " per " + row.feesSelect;
                            else
                                return "";
                        }} />
                        <Column title="Driver" key="fees" render={(row) => {
                            if (row.driverCB)
                                return row.driver + " " + row.rentalCostCurrency + " per " + row.driverSelect;
                            else
                                return "";
                        }} />
                    </Table>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RentalTruckAgreement)