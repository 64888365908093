/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Accordion, Modal } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { InputNumber, Space, Row as RowAnt, DatePicker } from 'antd';
import { Table, Select, Switch } from 'antd';
import { Toast } from 'primereact/toast';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import { handleRequest } from '../../redux/config/env';
import moment from 'moment';

const { Column } = Table;

export class OrderList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            immutableOrderList: [],
            orderList: [],
            orderId: null,
            modalDelete: false,
            modalError: false,
            modalDeleteProduct: false,
            modalDeleteTimeline: false,
            hidden: true,
            createNewButton: "Create New Order",
            createNewButtonVariant: "info",
            lastButton: "Save",
            lastButtonVariant: "success",
            customers: [],
            customerList: [],
            projects: [],
            projectList: [],
            locationList: [],
            products: [],
            productList: [],
            serviceList: [],
            orderNo: "",
            orderDate: "",
            customer: "",
            customerId: null,
            project: "",
            projectId: null,
            location: "",
            locationId: 0,
            productCode: "",
            productId: null,
            productUnit: "",
            productUnitId: null,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            productIndex: null,
            quantity: "",
            timelineList: [],
            timelineUpdateButtonHidden: "none",
            timelineAddButtonHidden: "block",
            timelineProductIds: [],
            numberOfTrucks: "",
            timelineQuantity: "",
            oneTimeDate: null,
            timelineIndex: null,
            deliveryTime: true,
            deliveryTimeHidden: "none",
            deliveryTime2: true,
            oneTimeTimeline: "block",
            fixedTimeTimeline: "none",
            rotatingTimeTimeline: "none",
            fixedPeriod: "Select Period",
            fixedStartDate: null,
            fixedEndDate: null,
            products2: [],
            productId2: null,
            product2: "",
            keyTimeline: 0,
            keyOrder: 0,
            oldOrderList: [],
            oldTimelineList: []
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount = async () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            oneTimeDate: today,
            fixedStartDate: today,
            fixedEndDate: today
        });

        const response = await handleRequest("GET", "/CompanyOrder/getAllByCompany");
        console.log(response.data);

        if (response.data.length === 0) {
            this.setState({
                immutableOrderList: [],
                orderList: []
            });
        }

        if (response.data.length !== 0) {

            let mainOrderList = response.data;
            let flagOrderList = [];
            let keyValue = 0;

            for (let index = 0; index < mainOrderList.length; index++) {
                if (mainOrderList[index].orders.length !== 0 && mainOrderList[index].orders !== null) {
                    for (let index1 = 0; index1 < mainOrderList[index].orders.length; index1++) {

                        keyValue = keyValue + 1;

                        flagOrderList.push({
                            keyValue: keyValue,
                            active: mainOrderList[index].active,
                            companyId: mainOrderList[index].companyId,
                            customerId: mainOrderList[index].customerId,
                            customerLog: mainOrderList[index].customerLog,
                            customerProject: mainOrderList[index].customerProject,
                            customerProjectId: mainOrderList[index].customerProjectId,
                            customerProjectLocation: mainOrderList[index].customerProjectLocation,
                            customerLocationId: mainOrderList[index].customerLocationId,
                            deliveryTime1: mainOrderList[index].deliveryTime1,
                            deliveryTime2: mainOrderList[index].deliveryTime2,
                            id: mainOrderList[index].id,
                            index: mainOrderList[index].index,
                            orderNo: mainOrderList[index].orderNo,
                            order: mainOrderList[index].orders[index1],
                            projectId: mainOrderList[index].projectId,
                            timelines: mainOrderList[index].timelines
                        });
                    }
                }
            }

            this.setState({
                immutableOrderList: mainOrderList,
                orderList: flagOrderList
            });
        }

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data
            });

            const options7 = responseCustomer.data.map(function (row) {
                return { name: row.fullName.toString(), id: row.id.toString() }
            })

            this.setState({
                customers: options7,
            });
        }
    }

    fillProjects = () => {
        var customerList = this.state.customerList;
        var customerId = this.state.customerId;

        var index = customerList.findIndex(p => p.id === customerId)

        if (index >= 0 && customerList[index].projects !== null && customerList[index].projects !== undefined && customerList[index].projects.length > 0) {
            this.setState({
                projectList: customerList[index].projects
            });

            const options8 = customerList[index].projects.map(function (row) {
                return { name: row.projectName.toString(), id: row.id.toString() }
            })

            this.setState({
                projects: options8,
            });
        }
    }

    fillServices = () => {

        var projectList = this.state.projectList;
        var projectId = this.state.projectId;

        var index = projectList.findIndex(p => p.id.toString() === projectId.toString());

        if (index >= 0 && projectList[index].services !== null && projectList[index].services !== undefined && projectList[index].services.length > 0) {

            this.setState({
                productList: projectList[index].services
            });

            let options = projectList[index].services.map(function (row) {
                return {
                    code: row.companyService.code.toString(),
                    description: row.companyService.description.toString(),
                    id: row.companyService.id.toString(),
                    unitCode: row.companyService.unitCode
                }
            });

            this.setState({
                products: options
            });
        }
    }

    fillLocations = () => {
        var projectList = this.state.projectList;
        var projectId = this.state.projectId;

        var index = projectList.findIndex(p => p.id.toString() === projectId.toString());

        if (index >= 0 && projectList[index].locations !== null && projectList[index].locations !== undefined && projectList[index].locations.length > 0) {
            this.setState({
                locationList: projectList[index].locations
            });
        }
    }

    handleChangeCustomer = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.customerList.length; z++) {
            if (this.state.customerList[z].fullName === e.target.value) {
                idValue = this.state.customerList[z].id
            }
        }

        let flagOrderNo = "";
        let dbOrderNoString = "";
        let dbOrderNoSlash = [];
        let dbOrderNo = 1;

        for (let index = 0; index < this.state.orderList.length; index++) {
            if (this.state.orderList[index].customerId.toString() === idValue) {
                dbOrderNoString = this.state.orderList[index].orderNo;
            }
        }

        if (dbOrderNoString !== "") {
            dbOrderNoSlash = dbOrderNoString.split("/");
            dbOrderNo = parseInt(dbOrderNoSlash[dbOrderNoSlash.length - 1]) + 1;
        }

        if (this.state.projectId !== null) {
            flagOrderNo = idValue.toString() + "/" + this.state.projectId.toString() + "/" + dbOrderNo;
        }

        this.setState({
            [e.target.name]: e.target.value,
            customerId: idValue,
            orderNo: flagOrderNo,
            locationList: [],
            location: "",
            locationId: 0
        });

        setTimeout(() => {
            this.fillProjects();
        }, 500);

    }

    handleChangeProject = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.projects.length; z++) {
            if (this.state.projects[z].name === e.target.value) {
                idValue = this.state.projects[z].id
            }
        }

        let flagOrderNo = "";
        let dbOrderNoString = "";
        let dbOrderNoSlash = [];
        let dbOrderNo = 1;

        for (let index = 0; index < this.state.orderList.length; index++) {
            if (this.state.orderList[index].customerProjectId.toString() === idValue) {
                dbOrderNoString = this.state.orderList[index].orderNo;
            }
        }

        if (dbOrderNoString !== "") {
            dbOrderNoSlash = dbOrderNoString.split("/");
            dbOrderNo = parseInt(dbOrderNoSlash[dbOrderNoSlash.length - 1]) + 1;
        }

        if (this.state.customerId !== null) {
            flagOrderNo = this.state.customerId.toString() + "/" + idValue.toString() + "/" + dbOrderNo;
        }

        this.setState({
            [e.target.name]: e.target.value,
            projectId: idValue,
            orderNo: flagOrderNo,
            locationList: [],
            location: "",
            locationId: 0
        });

        setTimeout(() => {
            this.fillLocations();
        }, 500);

        setTimeout(() => {
            this.fillServices();
        }, 500);
    }

    handleChangeLocation = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.locationList.length; z++) {
            if (this.state.locationList[z].locationName === e.target.value) {
                idValue = this.state.locationList[z].id
            }
        }

        console.log(idValue);

        this.setState({
            [e.target.name]: e.target.value,
            locationId: idValue
        });
    }

    handleChangeProduct = e => {
        e.preventDefault();
        var idValue;
        let productCode;
        let productUnitId;
        let productUnit;

        for (var z = 0; z < this.state.products.length; z++) {
            if (this.state.products[z].description === e.target.value) {
                idValue = this.state.products[z].id;
                productCode = this.state.products[z].code;
                productUnitId = this.state.products[z].unitCode.id;
                productUnit = this.state.products[z].unitCode.name;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            productCode: productCode,
            productId: idValue,
            productUnit: productUnit,
            productUnitId: productUnitId
        });
    }

    handleChangeProductCode = e => {
        e.preventDefault();
        var idValue;
        let product;
        let productUnitId;
        let productUnit;

        for (var z = 0; z < this.state.products.length; z++) {
            if (this.state.products[z].code === e.target.value) {
                idValue = this.state.products[z].id;
                product = this.state.products[z].description;
                productUnitId = this.state.products[z].unitCode.id;
                productUnit = this.state.products[z].unitCode.name;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            product: product,
            productId: idValue,
            productUnit: productUnit,
            productUnitId: productUnitId
        });
    }

    showOrHideModalDelete = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }

    showOrHideModalError = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalError: !this.state.modalError
        });
    }

    createNew = () => {
        if (this.state.createNewButton === "Create New Order") {
            this.setState({
                hidden: false,
                createNewButton: "Stop Adding New Order",
                createNewButtonVariant: "danger",
                lastButton: "Save",
                lastButtonVariant: "success"
            });
        } else {
            this.setState({
                hidden: true,
                createNewButton: "Create New Order",
                createNewButtonVariant: "info",
                lastButton: "Save",
                lastButtonVariant: "success"
            });

            this.resetInputs();
        }
    }

    saveOrder = async () => {

        if (this.state.lastButton === "Save") {

            let deliveryTime1 = "";
            let deliveryTime2 = "";

            if (this.state.deliveryTime) {
                deliveryTime1 = "One Time";
                deliveryTime2 = "None";
            } else {
                deliveryTime1 = "Multiple"
                if (this.state.deliveryTime2) {
                    deliveryTime2 = "Fixed";
                } else {
                    deliveryTime2 = "Rotating";
                }
            }

            let orders = [];

            for (let index = 0; index < this.state.serviceList.length; index++) {
                orders.push(
                    {
                        companyServiceId: this.state.serviceList[index].productId,
                        quantity: this.state.serviceList[index].quantity,
                        deliveredQty: 0,
                        balance: this.state.serviceList[index].quantity
                    }
                );
            }

            let timelines = [];

            for (let index = 0; index < this.state.timelineList.length; index++) {
                timelines.push(
                    {
                        companyServiceId: this.state.timelineList[index].product.productId,
                        numberOfTrucks: this.state.timelineList[index].numberOfTrucks,
                        orderDate: this.state.timelineList[index].date,
                        quantity: this.state.timelineList[index].quantity
                    }
                );
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            const newCompanyOrder = {
                customerId: this.state.customerId,
                customerProjectId: this.state.projectId,
                customerLocationId: this.state.locationId,
                orderNo: this.state.orderNo,
                orderDate: today,
                deliveryTime1: deliveryTime1,
                deliveryTime2: deliveryTime2,
                orders: orders,
                timelines: timelines
            }

            await handleRequest("POST", "/CompanyOrder/add", newCompanyOrder);

            this.restartTable();
            this.resetInputs();
            this.showSuccess();

        } else if (this.state.lastButton === "Update") {

            let deliveryTime1 = "";
            let deliveryTime2 = "";

            if (this.state.deliveryTime) {
                deliveryTime1 = "One Time";
                deliveryTime2 = "None";
            } else {
                deliveryTime1 = "Multiple"
                if (this.state.deliveryTime2) {
                    deliveryTime2 = "Fixed";
                } else {
                    deliveryTime2 = "Rotating";
                }
            }

            let orders = [];

            for (let index = 0; index < this.state.serviceList.length; index++) {
                orders.push(
                    {
                        id: this.state.serviceList[index].id,
                        companyServiceId: this.state.serviceList[index].productId,
                        quantity: this.state.serviceList[index].quantity,
                        deliveredQty: 0,
                        balance: this.state.serviceList[index].quantity
                    }
                );
            }

            for (let index = 0; index < this.state.oldOrderList.length; index++) {

                let whichIndexOrder = this.state.serviceList.findIndex(i => i.id === this.state.oldOrderList[index].id);

                if (whichIndexOrder === -1) {
                    orders.push(
                        {
                            id: this.state.oldOrderList[index].id,
                            active: false,
                            companyServiceId: this.state.oldOrderList[index].productId,
                            quantity: this.state.oldOrderList[index].quantity,
                            deliveredQty: 0,
                            balance: this.state.oldOrderList[index].quantity
                        }
                    );
                }
            }

            let timelines = [];

            for (let index = 0; index < this.state.timelineList.length; index++) {
                timelines.push(
                    {
                        id: this.state.timelineList[index].id,
                        companyServiceId: this.state.timelineList[index].product.productId,
                        numberOfTrucks: this.state.timelineList[index].numberOfTrucks,
                        orderDate: this.state.timelineList[index].date,
                        quantity: this.state.timelineList[index].quantity
                    }
                );
            }

            for (let index = 0; index < this.state.oldTimelineList.length; index++) {

                let whichIndexTimeline = this.state.timelineList.findIndex(i => i.id === this.state.oldTimelineList[index].id);

                if (whichIndexTimeline === -1) {
                    console.log(index);
                    console.log(this.state.oldTimelineList[index]);
                    timelines.push(
                        {
                            id: this.state.oldTimelineList[index].id,
                            active: false,
                            companyServiceId: this.state.oldTimelineList[index].product.productId,
                            numberOfTrucks: this.state.oldTimelineList[index].numberOfTrucks,
                            orderDate: this.state.oldTimelineList[index].date,
                            quantity: this.state.oldTimelineList[index].quantity
                        }
                    );
                }
            }

            const updatedCompanyOrder = {
                id: this.state.orderId,
                customerId: this.state.customerId,
                customerProjectId: this.state.projectId,
                customerLocationId: this.state.locationId,
                orderNo: this.state.orderNo,
                orderDate: this.state.orderDate,
                deliveryTime1: deliveryTime1,
                deliveryTime2: deliveryTime2,
                orders: orders,
                timelines: timelines
            }

            await handleRequest("POST", "/CompanyOrder/update", updatedCompanyOrder);

            this.restartTable();
            this.resetInputs();
            this.showSuccess();
        }
    }

    edit = (row) => {

        let selectedRow = null;

        for (let index = 0; index < this.state.immutableOrderList.length; index++) {
            if (row.id === this.state.immutableOrderList[index].id) {
                selectedRow = this.state.immutableOrderList[index];
            }
        }

        if (selectedRow !== null) {

            this.setState({
                customer: selectedRow.customerLog.fullName,
                customerId: selectedRow.customerId
            });

            if(selectedRow.orderDate === null){
                this.setState({
                    orderDate: ""
                });
            } else {
                this.setState({
                    orderDate: selectedRow.orderDate
                });
            }

            setTimeout(() => {
                this.fillProjects();
            }, 500);

            this.setState({
                project: selectedRow.customerProject.projectName,
                projectId: selectedRow.customerProjectId
            });

            setTimeout(() => {
                this.fillLocations();
            }, 500);

            if (selectedRow.customerProjectLocation !== undefined && selectedRow.customerProjectLocation !== null)
                this.setState({
                    location: selectedRow.customerProjectLocation.locationName,
                    locationId: selectedRow.customerLocationId
                });
            else {
                this.setState({
                    location: "",
                    locationId: 0
                });
            }

            setTimeout(() => {
                this.fillServices();
            }, 500);

            if (selectedRow.deliveryTime1 === "One Time") {
                this.setState({
                    deliveryTime: true,
                    deliveryTime2: false
                });
            } else {
                if (selectedRow.deliveryTime2 === "Fixed") {
                    this.setState({
                        deliveryTime: false,
                        deliveryTime2: true
                    });
                } else {
                    this.setState({
                        deliveryTime: false,
                        deliveryTime2: false
                    });
                }
            };

            let customerProducts = [];

            for (let index2 = 0; index2 < selectedRow.customerLog.projects.length; index2++) {
                if (selectedRow.customerLog.projects[index2].id === selectedRow.customerProjectId) {
                    customerProducts = selectedRow.customerLog.projects[index2].services;
                }
            }

            let orderList = [];
            let oldOrderList = [];

            for (let index = 0; index < selectedRow.orders.length; index++) {
                const newItem = {
                    key: selectedRow.orders[index].id,
                    id: selectedRow.orders[index].id,
                    productId: selectedRow.orders[index].companyService.id,
                    product: selectedRow.orders[index].companyService.description,
                    productCode: selectedRow.orders[index].companyService.code,
                    quantity: selectedRow.orders[index].quantity,
                    productUnit: selectedRow.orders[index].companyService.unitCode.name
                }

                const newItem1 = {
                    key: selectedRow.orders[index].id,
                    id: selectedRow.orders[index].id,
                    active: false,
                    productId: selectedRow.orders[index].companyService.id,
                    product: selectedRow.orders[index].companyService.description,
                    productCode: selectedRow.orders[index].companyService.code,
                    quantity: selectedRow.orders[index].quantity,
                    productUnit: selectedRow.orders[index].companyService.unitCode.name
                }

                orderList.push(newItem);
                oldOrderList.push(newItem1);
            }

            var timelineList = [];
            var oldTimelineList = [];
            var _key = this.state.keyTimeline;

            for (let index = 0; index < selectedRow.timelines.length; index++) {

                let oneTimeTimelineProduct = null;

                for (let index1 = 0; index1 < customerProducts.length; index1++) {
                    if (customerProducts[index1].companyService.id === selectedRow.timelines[index].companyServiceId) {

                        _key = _key + 1;

                        oneTimeTimelineProduct = {
                            key: _key,
                            productId: customerProducts[index1].companyService.id,
                            product: customerProducts[index1].companyService.description,
                            productCode: customerProducts[index1].companyService.code,
                            quantity: selectedRow.timelines[index].quantity,
                            productUnit: customerProducts[index1].companyService.unitCode.name
                        }
                    }
                }

                const newItem = {
                    key: selectedRow.timelines[index].id,
                    id: selectedRow.timelines[index].id,
                    product: oneTimeTimelineProduct,
                    date: selectedRow.timelines[index].orderDate,
                    numberOfTrucks: selectedRow.timelines[index].numberOfTrucks,
                    quantity: selectedRow.timelines[index].quantity
                }

                const newItem1 = {
                    key: selectedRow.timelines[index].id,
                    id: selectedRow.timelines[index].id,
                    active: false,
                    product: oneTimeTimelineProduct,
                    date: selectedRow.timelines[index].orderDate,
                    numberOfTrucks: selectedRow.timelines[index].numberOfTrucks,
                    quantity: selectedRow.timelines[index].quantity
                }


                timelineList.push(newItem);
                oldTimelineList.push(newItem1);
            }

            this.setState({
                hidden: false,
                orderId: selectedRow.id,
                createNewButton: "Stop Updating Order",
                createNewButtonVariant: "warning",
                lastButton: "Update",
                lastButtonVariant: "warning",
                orderNo: selectedRow.orderNo,
                timelineList: timelineList.slice(),
                serviceList: orderList.slice(),
                keyTimeline: _key,
                oldOrderList: oldOrderList.slice(),
                oldTimelineList: oldTimelineList.slice()
            });

            document.getElementById('kt_scrolltop').click();
        }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/CompanyOrder/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                immutableOrderList: [],
                orderList: []
            });
        }

        if (response.data.length !== 0) {

            let mainOrderList = response.data;
            let flagOrderList = [];
            let keyValue = 0;

            for (let index = 0; index < mainOrderList.length; index++) {
                if (mainOrderList[index].orders.length !== 0 && mainOrderList[index].orders !== null) {
                    for (let index1 = 0; index1 < mainOrderList[index].orders.length; index1++) {

                        keyValue = keyValue + 1;

                        flagOrderList.push({
                            keyValue: keyValue,
                            active: mainOrderList[index].active,
                            companyId: mainOrderList[index].companyId,
                            customerId: mainOrderList[index].customerId,
                            customerLog: mainOrderList[index].customerLog,
                            customerProject: mainOrderList[index].customerProject,
                            customerProjectId: mainOrderList[index].customerProjectId,
                            customerProjectLocation: mainOrderList[index].customerProjectLocation,
                            customerLocationId: mainOrderList[index].customerLocationId,
                            deliveryTime1: mainOrderList[index].deliveryTime1,
                            deliveryTime2: mainOrderList[index].deliveryTime2,
                            id: mainOrderList[index].id,
                            index: mainOrderList[index].index,
                            orderNo: mainOrderList[index].orderNo,
                            order: mainOrderList[index].orders[index1],
                            projectId: mainOrderList[index].projectId,
                            timelines: mainOrderList[index].timelines
                        });
                    }
                }
            }

            this.setState({
                immutableOrderList: mainOrderList,
                orderList: flagOrderList
            });
        }
    }

    resetInputs = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            hidden: true,
            createNewButton: "Create New Order",
            createNewButtonVariant: "info",
            lastButton: "Save",
            lastButtonVariant: "success",
            serviceList: [],
            orderNo: "",
            orderDate: "",
            customer: "",
            customerId: null,
            project: "",
            projectId: null,
            location: "",
            locationId: 0,
            productCode: "",
            productId: null,
            productUnit: "",
            productUnitId: null,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            productIndex: null,
            quantity: "",
            timelineList: [],
            timelineUpdateButtonHidden: "none",
            timelineAddButtonHidden: "block",
            timelineProductIds: [],
            numberOfTrucks: "",
            timelineQuantity: "",
            oneTimeDate: today,
            timelineIndex: null,
            deliveryTime: true,
            deliveryTimeHidden: "none",
            deliveryTime2: true,
            oneTimeTimeline: "block",
            fixedTimeTimeline: "none",
            rotatingTimeTimeline: "none",
            fixedPeriod: "Select Period",
            fixedStartDate: today,
            fixedEndDate: today,
            products: [],
            productId: null,
            products2: [],
            productId2: null,
            product2: "",
            keyTimeline: 0,
            keyOrder: 0,
            oldOrderList: [],
            oldTimelineList: []
        });
    }

    showSuccess = () => {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError = (message) => {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    }

    addProduct = () => {
        var list = this.state.serviceList;
        var flagProducts2 = [];

        if (this.state.productId !== null && this.state.quantity !== "") {

            var _key = this.state.keyOrder + 1;

            const newItem = {
                key: _key,
                id: 0,
                productId: this.state.productId,
                product: this.state.product,
                productCode: this.state.productCode,
                quantity: this.state.quantity,
                productUnit: this.state.productUnit
            }

            list.push(newItem);

            for (let index = 0; index < list.length; index++) {
                let listHasSameProduct = false;

                for (let index1 = 0; index1 < flagProducts2.length; index1++) {
                    if (flagProducts2[index1].id === list[index].productId) {
                        listHasSameProduct = true;
                        break;
                    }
                }

                if (!listHasSameProduct) {
                    flagProducts2.push(
                        {
                            id: list[index].productId,
                            description: list[index].product,
                            productCode: list[index].productCode,
                            productUnit: list[index].productUnit
                        }
                    );
                }
            }

            this.setState({
                serviceList: list.slice(),
                productCode: "",
                product: "",
                productId: null,
                quantity: "",
                productUnit: "",
                products2: flagProducts2,
                keyOrder: _key
            })
        } else {
            this.setState({
                modalError: true
            });
        }
    }

    showOrHideModalDeleteProduct = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalDeleteProduct: !this.state.modalDeleteProduct
        });
    }

    deleteProductModal = (row) => {
        this.setState({
            productIndex: row.key,
            modalDeleteProduct: true
        });
    }

    deleteProductModalOK = () => {
        let list = this.state.serviceList;
        let flagProductIndex = this.state.productIndex;

        list.splice(list.findIndex(function (i) {
            return i.key === flagProductIndex;
        }), 1);

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            serviceList: list.slice(),
            modalDeleteProduct: false,
            productId: null,
            productIndex: null
        });
    }

    editService = (row) => {
        this.setState({
            productIndex: row.key,
            productId: row.productId,
            product: row.product,
            productCode: row.productCode,
            quantity: row.quantity,
            productUnit: row.productUnit,
            productUpdateButtonHidden: "block",
            productAddButtonHidden: "none"
        });
    }

    updateProduct = () => {
        let list = this.state.serviceList;
        let flagProductIndex = this.state.productIndex;

        let objIndex = list.findIndex((obj => obj.key === flagProductIndex));

        list[objIndex].productId = this.state.productId;
        list[objIndex].product = this.state.product;
        list[objIndex].productCode = this.state.productCode;
        list[objIndex].quantity = this.state.quantity;
        list[objIndex].productUnit = this.state.productUnit;

        this.setState({
            serviceList: list.slice(),
            productIndex: null,
            productId: null,
            product: "",
            productCode: "",
            quantity: "",
            productUnit: "",
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block"
        });
    }

    cancelProduct = () => {
        this.setState({
            productId: null,
            product: "",
            productCode: "",
            quantity: "1",
            productUnit: "",
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block"
        });
    }

    onChangeOneTimeDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                oneTimeDate: dateString
            });
        } else {
            this.setState({
                oneTimeDate: today
            });
        }
    }

    onChangeFixedStartDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                fixedStartDate: dateString
            });
        } else {
            this.setState({
                fixedStartDate: today
            });
        }
    }

    onChangeFixedEndDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                fixedEndDate: dateString
            });
        } else {
            this.setState({
                fixedEndDate: today
            });
        }
    }

    disabledDate(current) {
        return current < moment().endOf('day');
    }

    handleChangeProduct2 = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.products2.length; z++) {
            if (this.state.products2[z].description === e.target.value) {
                idValue = this.state.products2[z].id;
            }
        }

        let selectedProductQuantity = "";

        console.log(this.state.serviceList);
        console.log(idValue);

        for (let index1 = 0; index1 < this.state.serviceList.length; index1++) {
            if (parseInt(this.state.serviceList[index1].productId) === parseInt(idValue)) {
                selectedProductQuantity = this.state.serviceList[index1].quantity;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            productId2: idValue,
            timelineQuantity: selectedProductQuantity
        });
    }

    addTimeline = () => {
        var list = this.state.timelineList;

        if (this.state.product2 !== null && this.state.numberOfTrucks !== "" && this.state.timelineQuantity !== "") {

            var _key = this.state.keyTimeline;

            let oneTimeTimelineProduct = null;

            for (let index1 = 0; index1 < this.state.serviceList.length; index1++) {
                if (parseInt(this.state.serviceList[index1].productId) === parseInt(this.state.productId2)) {
                    oneTimeTimelineProduct = this.state.serviceList[index1];
                }
            }

            let flagProducts = this.state.products2;

            for (let index = 0; index < this.state.products2.length; index++) {
                if (this.state.products2[index].id === this.state.productId2) {
                    flagProducts.splice(index, 1);
                }
            }

            const newItem = {
                key: _key,
                id: 0,
                product: oneTimeTimelineProduct,
                date: this.state.oneTimeDate,
                numberOfTrucks: this.state.numberOfTrucks,
                quantity: this.state.timelineQuantity
            }

            list.push(newItem);

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            this.setState({
                timelineList: list.slice(),
                products2: flagProducts.slice(),
                oneTimeDate: today,
                numberOfTrucks: "",
                timelineQuantity: "",
                keyTimeline: _key + 1
            })
        } else {
            this.setState({
                modalError: true
            });
        }
    }

    showOrHideModalDeleteTimeline = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalDeleteTimeline: !this.state.modalDeleteTimeline
        });
    }

    deleteTimelineModal = (row) => {
        let list = this.state.timelineList;

        this.setState({
            timelineIndex: row.key,
            modalDeleteTimeline: true
        });
    }

    deleteTimelineModalOK = () => {

        let list = this.state.timelineList;
        let flagTimelineIndex = this.state.timelineIndex;

        let flagIndex = 0;

        for (let index = 0; index < list.length; index++) {
            if (list[index].key === flagTimelineIndex) {
                flagIndex = index;
            }
        }

        let flagProductId = list[flagIndex].product.productId;
        let flagProducts = this.state.products2;

        for (let index1 = 0; index1 < this.state.products.length; index1++) {
            if (parseInt(this.state.products[index1].id) === flagProductId) {
                flagProducts.push(this.state.products[index1]);
            }
        }

        list.splice(list.findIndex(function (i) {
            return i.key === flagTimelineIndex;
        }), 1);

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            timelineList: list.slice(),
            modalDeleteTimeline: false,
            products2: flagProducts,
            product2: "",
            productId2: null,
            timelineIndex: null
        });
    }

    editTimeline = (row) => {

        let list = this.state.timelineList;

        let flagIndex = 0;

        for (let index = 0; index < list.length; index++) {
            if (list[index].key === row.key) {
                flagIndex = index;
            }
        }

        let flagProductId = list[flagIndex].product.productId;
        let flagProducts = this.state.products2;

        for (let index1 = 0; index1 < this.state.products.length; index1++) {
            if (parseInt(this.state.products[index1].id) === flagProductId) {
                flagProducts.push(this.state.products[index1]);
            }
        }

        this.setState({
            timelineIndex: row.key,
            products2: flagProducts,
            product2: row.product.product,
            productId2: row.product.productId,
            oneTimeDate: row.date,
            numberOfTrucks: row.numberOfTrucks,
            timelineQuantity: row.quantity,
            timelineUpdateButtonHidden: "block",
            timelineAddButtonHidden: "none"
        });
    }

    updateTimeline = () => {
        let list = this.state.timelineList;
        let flagTimelineIndex = this.state.timelineIndex;

        let objIndex = list.findIndex((obj => obj.key === flagTimelineIndex));

        let oneTimeTimelineProduct = null;

        for (let index1 = 0; index1 < this.state.serviceList.length; index1++) {
            if (parseInt(this.state.serviceList[index1].productId) === this.state.productId2) {
                oneTimeTimelineProduct = this.state.serviceList[index1];
            }
        }

        list[objIndex].product = oneTimeTimelineProduct;
        list[objIndex].date = this.state.oneTimeDate;
        list[objIndex].quantity = this.state.timelineQuantity;
        list[objIndex].numberOfTrucks = this.state.numberOfTrucks;

        let flagProducts = this.state.products2;

        for (let index = 0; index < flagProducts.length; index++) {
            if (parseInt(flagProducts[index].id) === this.state.productId2) {
                flagProducts.splice(index, 1);
            }
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            timelineList: list.slice(),
            timelineIndex: null,
            oneTimeDate: today,
            numberOfTrucks: "",
            timelineQuantity: "",
            timelineUpdateButtonHidden: "none",
            timelineAddButtonHidden: "block"
        });
    }

    cancelTimeline = () => {

        let flagProducts = this.state.products2;

        for (let index = 0; index < flagProducts.length; index++) {
            if (parseInt(flagProducts[index].id) === this.state.productId2) {
                flagProducts.splice(index, 1);
            }
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            timelineIndex: null,
            product2: "",
            productId2: null,
            oneTimeDate: today,
            numberOfTrucks: "",
            timelineQuantity: "",
            timelineUpdateButtonHidden: "none",
            timelineAddButtonHidden: "block"
        });
    }

    handleChangeFixedPeriod = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    deleteModal = (row) => {
        this.setState({
            modalDelete: true,
            orderId: row.id
        });
    }

    deleteModalOK = async () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        const deletedItem = {
            id: this.state.orderId
        }

        await handleRequest("POST", "/CompanyOrder/delete", deletedItem);

        this.setState({
            modalDelete: false,
            orderId: null
        });

        this.restartTable();
        this.resetInputs();
    }

    render() {

        let optionsCustomers = this.state.customers.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsProjects = this.state.projectList.map((data) =>
            <option
                key={data.id}
                value={data.projectName}
            >
                {data.projectName}
            </option>
        );

        let optionsLocations = this.state.locationList.map((data) =>
            <option
                key={data.id}
                value={data.locationName}
            >
                {data.locationName}
            </option>
        );

        let optionsProductCodes = this.state.products.map((data) =>
            <option
                key={data.id}
                value={data.code}
            >
                {data.code}
            </option>
        );

        let optionsProducts = this.state.products.map((data) =>
            <option
                key={data.id}
                value={data.description}
            >
                {data.description}
            </option>
        );

        let optionsProducts2 = this.state.products2.map((data) =>
            <option
                key={data.id}
                value={data.description}
            >
                {data.description}
            </option>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Modal
                    show={this.state.modalDelete}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Order Record?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>Close</Button>
                        <Button variant="danger" onClick={this.deleteModalOK} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDeleteProduct}
                    onHide={this.showOrHideModalDeleteProduct}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Product Record?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDeleteProduct}>Close</Button>
                        <Button variant="danger" onClick={this.deleteProductModalOK} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDeleteTimeline}
                    onHide={this.showOrHideModalDeleteTimeline}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This Timeline Record?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDeleteTimeline}>Close</Button>
                        <Button variant="danger" onClick={this.deleteTimelineModalOK} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalError}
                    onHide={this.showOrHideModalError}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Missing Values!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please, be sure to enter all values.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalError}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <div style={{ padding: "4px 16px 4px 16px" }}>
                    <Row>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                    </Row>
                </div>

                <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Accordion defaultActiveKey="1">
                                    <Card>
                                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                            <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Customer</Card.Header>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse id="customerAccordion" eventKey="0">
                                            <Card.Body>
                                                <div style={{ padding: "4px 16px 4px 16px" }}>
                                                    <Row>
                                                        <Col xs={6}></Col>
                                                        <Col xs={2} style={{ textAlign: 'right' }}>
                                                            <Form.Label style={{ color: 'black', marginTop: '0.75rem' }}>
                                                                Order No:
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <Form.Control readOnly name="orderNo" value={this.state.orderNo} />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        Customer:
                                                        </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control name="customer" value={this.state.customer}
                                                            onChange={this.handleChangeCustomer} as="select">
                                                            <option value="">
                                                                Select Customer
                                                        </option>
                                                            {optionsCustomers}
                                                        </Form.Control>
                                                    </Col>
                                                    <Col sm="7">
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        Project:
                                                        </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control name="project" value={this.state.project}
                                                            onChange={this.handleChangeProject} as="select">
                                                            <option value="">
                                                                Select Project
                                                        </option>
                                                            {optionsProjects}
                                                        </Form.Control>
                                                    </Col>
                                                    <Col sm="7">
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        Location:
                                                        </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control name="location" value={this.state.location}
                                                            onChange={this.handleChangeLocation} as="select">
                                                            <option value="">
                                                                Select Location
                                                        </option>
                                                            {optionsLocations}
                                                        </Form.Control>
                                                    </Col>
                                                    <Col sm="7">
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                <br />
                                <Accordion defaultActiveKey="1">
                                    <Card>
                                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                            <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Order</Card.Header>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                            <Card.Body>
                                                <RowAnt>
                                                    <Space size="large" >
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }} >Product Code</div>
                                                            <Form.Control name="productCode" value={this.state.productCode}
                                                                onChange={this.handleChangeProductCode} as="select">
                                                                <option value="">
                                                                    Select Product Code
                                                                </option>
                                                                {optionsProductCodes}
                                                            </Form.Control>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Product</div>
                                                            <Form.Control name="product" value={this.state.product}
                                                                onChange={this.handleChangeProduct} as="select">
                                                                <option value="">
                                                                    Select Product
                                                                </option>
                                                                {optionsProducts}
                                                            </Form.Control>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Quantity </div>
                                                            <InputNumber min={1} value={this.state.quantity} onChange={(value) => { this.setState({ quantity: value }) }} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div></div>
                                                            <Form.Label style={{ color: 'black', marginTop: '1rem' }} >
                                                                {this.state.productUnit}
                                                            </Form.Label>
                                                        </Space>

                                                        <Space style={{ display: this.state.productAddButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} onClick={this.addProduct} type="button">Add</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.productUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="warning" onClick={this.updateProduct} type="button">Update</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.productUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="secondary" onClick={this.cancelProduct} type="button">Cancel</Button>
                                                        </Space>
                                                    </Space>
                                                </RowAnt>
                                                <Table style={{ marginTop: '2rem' }} size={`small`} dataSource={this.state.serviceList} bordered >
                                                    <Column
                                                        width='50px'
                                                        title="Action"
                                                        key="action"
                                                        render={(text, record) => (
                                                            <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteProductModal.bind(this)} onEditRow={this.editService.bind(this)} />   </React.Fragment>
                                                        )}
                                                    />
                                                    <Column title="Product Code" key="productId" dataIndex="productCode" />
                                                    <Column title="Product" key="product" dataIndex="product" />
                                                    <Column title="Quantity" key="quantity" render={(text, record) => {
                                                        let quantityString = record.quantity.toString() + " " + record.productUnit.toString();
                                                        return quantityString
                                                    }} />
                                                </Table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                <br />
                                <Accordion defaultActiveKey="1">
                                    <Card>
                                        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                            <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Timeline</Card.Header>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                            <Card.Body>
                                                <RowAnt style={{ marginTop: '1rem' }}>
                                                    <Space size="large" >
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }} >Delivery Time</div>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <Switch checkedChildren="One Time" unCheckedChildren="Multiple"
                                                                checked={this.state.deliveryTime}
                                                                defaultChecked onChange={(value) => {
                                                                    if (value === true) {
                                                                        this.setState({
                                                                            deliveryTimeHidden: "none",
                                                                            oneTimeTimeline: "block",
                                                                            fixedTimeTimeline: "none",
                                                                            rotatingTimeTimeline: "none",
                                                                            deliveryTime2: false
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            deliveryTimeHidden: "block",
                                                                            oneTimeTimeline: "none",
                                                                            fixedTimeTimeline: "block",
                                                                            rotatingTimeTimeline: "none",
                                                                            deliveryTime2: true
                                                                        });
                                                                    }

                                                                    this.setState({
                                                                        deliveryTime: value
                                                                    });
                                                                }} />
                                                        </Space>
                                                        <Space style={{ display: this.state.deliveryTimeHidden }} direction="vertical">
                                                            <Switch checkedChildren="Fixed" unCheckedChildren="Rotating"
                                                                checked={this.state.deliveryTime2}
                                                                defaultChecked onChange={(value) => {
                                                                    if (value === true) {
                                                                        this.setState({
                                                                            fixedTimeTimeline: "block",
                                                                            rotatingTimeTimeline: "none",
                                                                            oneTimeTimeline: "none"
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            fixedTimeTimeline: "none",
                                                                            rotatingTimeTimeline: "block",
                                                                            oneTimeTimeline: "none"
                                                                        });
                                                                    }

                                                                    this.setState({
                                                                        deliveryTime2: value
                                                                    });
                                                                }} />
                                                        </Space>
                                                    </Space>
                                                </RowAnt>

                                                <RowAnt style={{ marginTop: '2rem', display: this.state.oneTimeTimeline }}>
                                                    <Space size="large" >
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }} >Product</div>
                                                            <Form.Control name="product2" value={this.state.product2}
                                                                onChange={this.handleChangeProduct2} as="select">
                                                                <option value="">
                                                                    Select Product
                                                                </option>
                                                                {optionsProducts2}
                                                            </Form.Control>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Date</div>
                                                            <DatePicker disabledDate={this.disabledDate} value={moment(this.state.oneTimeDate, 'DD/MM/YYYY')}
                                                                format="DD/MM/YYYY" onChange={this.onChangeOneTimeDate} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Number of Truck(s)</div>
                                                            <InputNumber min={1} value={this.state.numberOfTrucks} onChange={(value) => { this.setState({ numberOfTrucks: value }) }} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Quantity</div>
                                                            <InputNumber min={1} disabled={true} value={this.state.timelineQuantity} onChange={(value) => { this.setState({ timelineQuantity: value }) }} />
                                                        </Space>
                                                        <Space style={{ display: this.state.timelineAddButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} onClick={this.addTimeline} type="button">Add</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.timelineUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="warning" onClick={this.updateTimeline} type="button">Update</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.timelineUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="secondary" onClick={this.cancelTimeline} type="button">Cancel</Button>
                                                        </Space>
                                                    </Space>
                                                </RowAnt>

                                                <Table style={{ marginTop: '2rem', display: this.state.oneTimeTimeline }} size={`small`} dataSource={this.state.timelineList} bordered >
                                                    <Column
                                                        width='50px'
                                                        title="Action"
                                                        key="action"
                                                        render={(text, record) => (
                                                            <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteTimelineModal.bind(this)} onEditRow={this.editTimeline.bind(this)} />   </React.Fragment>
                                                        )}
                                                    />
                                                    <Column title="Product(s)" key="product2" render={(text, record) => {
                                                        return record.product.product;
                                                    }} />
                                                    <Column title="Date" key="date" dataIndex="date" />
                                                    <Column title="Number of Truck(s)" key="numberOfTrucks" dataIndex="numberOfTrucks" />
                                                    <Column title="Quantity" key="quantity" render={(text, record) => {
                                                        let quantityString = record.quantity.toString() + " " + record.product.productUnit.toString();
                                                        return quantityString;
                                                    }} />
                                                </Table>

                                                <RowAnt style={{ marginTop: '2rem', display: this.state.fixedTimeTimeline }}>
                                                    <Space size="large" >
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }} >Product</div>
                                                            <Form.Control name="product2" value={this.state.product2}
                                                                onChange={this.handleChangeProduct2} as="select">
                                                                <option value="">
                                                                    Select Product
                                                                </option>
                                                                {optionsProducts2}
                                                            </Form.Control>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }} >Period</div>
                                                            <Form.Control style={{ width: '90%' }} name="fixedPeriod" value={this.state.fixedPeriod}
                                                                onChange={this.handleChangeFixedPeriod} as="select">
                                                                <option>Select Period</option>
                                                                <option>Daily</option>
                                                                <option>Weekly</option>
                                                                <option>Monthly</option>
                                                            </Form.Control>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Start Date</div>
                                                            <DatePicker disabledDate={this.disabledDate} value={moment(this.state.fixedStartDate, 'DD/MM/YYYY')}
                                                                format="DD/MM/YYYY" onChange={this.onChangeFixedStartDate} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}> End Date</div>
                                                            <DatePicker disabledDate={this.disabledDate} value={moment(this.state.fixedEndDate, 'DD/MM/YYYY')}
                                                                format="DD/MM/YYYY" onChange={this.onChangeFixedEndDate} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Number of Truck(s)</div>
                                                            <InputNumber min={1} value={this.state.numberOfTrucks} onChange={(value) => { this.setState({ numberOfTrucks: value }) }} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Quantity</div>
                                                            <InputNumber min={1} value={this.state.timelineQuantity} onChange={(value) => { this.setState({ timelineQuantity: value }) }} />
                                                        </Space>
                                                        <Space style={{ display: this.state.timelineAddButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} onClick={this.addTimeline} type="button">Add</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.timelineUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="warning" onClick={this.updateTimeline} type="button">Update</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.timelineUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="secondary" onClick={this.cancelTimeline} type="button">Cancel</Button>
                                                        </Space>
                                                    </Space>
                                                </RowAnt>

                                                <Table style={{ marginTop: '2rem', display: this.state.fixedTimeTimeline }} size={`small`} dataSource={this.state.timelineList} bordered >
                                                    <Column
                                                        width='50px'
                                                        title="Action"
                                                        key="action"
                                                        render={(text, record) => (
                                                            <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteTimelineModal.bind(this)} onEditRow={this.editTimeline.bind(this)} />   </React.Fragment>
                                                        )}
                                                    />
                                                    <Column title="Product(s)" key="product2" render={(text, record) => {
                                                        return record.product.product;
                                                    }} />
                                                    <Column title="Period" key="period" dataIndex="period" />
                                                    <Column title="Start Date" key="startDate" dataIndex="startDate" />
                                                    <Column title="End Date" key="endDate" dataIndex="endDate" />
                                                    <Column title="Number of Truck(s)" key="numberOfTrucks" dataIndex="numberOfTrucks" />
                                                    <Column title="Quantity" key="quantity" render={(text, record) => {
                                                        let quantityString = record.quantity.toString() + " " + record.product.productUnit.toString();
                                                        return quantityString;
                                                    }} />
                                                </Table>

                                                <RowAnt style={{ marginTop: '2rem', display: this.state.rotatingTimeTimeline }}>
                                                    <Space size="large" >
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }} >Product</div>
                                                            <Form.Control name="product2" value={this.state.product2}
                                                                onChange={this.handleChangeProduct2} as="select">
                                                                <option value="">
                                                                    Select Product
                                                                </option>
                                                                {optionsProducts2}
                                                            </Form.Control>
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Date</div>
                                                            <DatePicker disabledDate={this.disabledDate} value={moment(this.state.oneTimeDate, 'DD/MM/YYYY')}
                                                                format="DD/MM/YYYY" onChange={this.onChangeOneTimeDate} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Number of Truck(s)</div>
                                                            <InputNumber min={1} value={this.state.numberOfTrucks} onChange={(value) => { this.setState({ numberOfTrucks: value }) }} />
                                                        </Space>
                                                        <Space direction="vertical">
                                                            <div style={{ color: 'black', fontWeight: 'bold' }}>Quantity</div>
                                                            <InputNumber min={1} value={this.state.timelineQuantity} onChange={(value) => { this.setState({ timelineQuantity: value }) }} />
                                                        </Space>
                                                        <Space style={{ display: this.state.timelineAddButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} onClick={this.addTimeline} type="button">Add</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.timelineUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="warning" onClick={this.updateTimeline} type="button">Update</Button>
                                                        </Space>

                                                        <Space style={{ display: this.state.timelineUpdateButtonHidden }} direction="vertical">
                                                            <div></div>
                                                            <Button style={{ width: '100%', marginTop: '1rem' }} variant="secondary" onClick={this.cancelTimeline} type="button">Cancel</Button>
                                                        </Space>
                                                    </Space>
                                                </RowAnt>

                                                <Table style={{ marginTop: '2rem', display: this.state.rotatingTimeTimeline }} size={`small`} dataSource={this.state.timelineList} bordered >
                                                    <Column
                                                        width='50px'
                                                        title="Action"
                                                        key="action"
                                                        render={(text, record) => (
                                                            <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteTimelineModal.bind(this)} onEditRow={this.editTimeline.bind(this)} />   </React.Fragment>
                                                        )}
                                                    />
                                                    <Column title="Product(s)" key="product2" render={(text, record) => {
                                                        return record.product.product;
                                                    }} />
                                                    <Column title="Date" key="date" dataIndex="date" />
                                                    <Column title="Number of Truck(s)" key="numberOfTrucks" dataIndex="numberOfTrucks" />
                                                    <Column title="Quantity" key="quantity" render={(text, record) => {
                                                        let quantityString = record.quantity.toString() + " " + record.product.productUnit.toString();
                                                        return quantityString;
                                                    }} />
                                                </Table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="6">
                                        <Button type="button" style={{ width: '100%' }} onClick={this.saveOrder} variant={this.state.lastButtonVariant}>{this.state.lastButton}</Button>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                            </Container>
                        </Card.Body>
                    </Card>
                </div>

                <Table
                    dataSource={this.state.orderList}
                    style={{ marginTop: "2rem" }}
                    bordered
                >
                    <Column
                        width='50px'
                        title="Action"
                        key="action"
                        render={(text, record) => (
                            <div style={{ textAlign: "center" }}>
                                <React.Fragment key={record.id} > <CustomMaterialMenu row={record}
                                    onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
                                </React.Fragment>
                            </div>
                        )}
                    />
                    <Column title="S/N" key="sn" dataIndex="keyValue" />
                    <Column title="Customer" key="customer" render={(text, record) => {
                        return record.customerLog.fullName;
                    }} />
                    <Column title="Project" key="project" render={(text, record) => {
                        return record.customerProject.projectName;
                    }} />
                    <Column title="Order No" key="orderNo" dataIndex="orderNo" />
                    <Column title="Location" key="location" render={(text, record) => {
                        if (record.customerProjectLocation !== undefined)
                            if (record.customerProjectLocation !== null)
                                return record.customerProjectLocation.locationName;
                            else return "";
                        else return "";
                    }} />
                    <Column title="Product Code" key="productCode" render={(text, record) => {
                        return record.order.companyService.code;
                    }} />
                    <Column title="Product" key="product" render={(text, record) => {
                        return record.order.companyService.description;
                    }} />
                    <Column title="Ordered Quantity" key="orderQuantity" render={(text, record) => {
                        return record.order.quantity;
                    }} />
                    <Column title="Unit" key="unit" render={(text, record) => {
                        return record.order.companyService.unitCode.name;
                    }} />
                    <Column title="Delivered Qty" key="deliveredQty" render={(text, record) => {
                        return record.order.deliveredQty;
                    }} />
                    <Column title="Balance" key="balance" render={(text, record) => {
                        return record.order.balance;
                    }} />
                </Table>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)