import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table } from 'antd';
import { TreeSelect, Button as AntButton, Select, Timeline, Input } from 'antd';
import { Link } from "react-router-dom";
import { CloseOutlined, UpCircleOutlined, DownCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Column } = Table;
const { Option } = Select;


export class TransmittalWorkflow extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: false,
            hideSave: false,
            hideUpdate: true,

            stepName: '',
            stepList: [],
            selectedStep: null,
            actionId: null,
            menuLabel:'',
            stepMenuId:0,
            actionList:[],
            activationStepId: null,
            activationList: [],
            nodes: [],
            activation: '',
            step: '',

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentWillMount = async () => {

        try {
            this.setState({
                menuList: this.props.auth.user.authList.filter(p => p.action === "view")
            });

        } catch (error) {

        }


     
        // const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTreeDynamicMenu");

        // var items = responseDynamicMenu.data;

        const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTree");  // getDynamicMenuNodes

        var items = responseDynamicMenu.data;

        console.log(this.state.menuList);
        console.log(items);

        if (Boolean(items) && items.length > 0) {
            items[0].disabled = true;
            var root = [];
            root.push(Object.assign({}, items[0]));
            root[0].children = [];
            items[0].children.forEach((element, i) => {
                if (this.state.menuList.findIndex(el => el.menuId == element.menuId || el.form === "All") >= 0) {
                    element.disabled = true;
                    this.getMenu(element, root[0].children);
                }

            });
        }
        // const _actionList = ActionsList.map(function (row) {
        //   return { label: row.label.toString(), key: row.key.toString(), title: row.label.toString() }
        // })

        console.log(root);

        this.setState({
            nodes: root
        });

    }


    getMenu = async (element, list) => {

        if (Boolean(element)) {

            if (Boolean(element.children) && element.children.length > 0)
                element.disabled = true;

            var obj = Object.assign({}, element);
            obj.children = [];
            list.push(obj);

            if (element.children) {
                element.children.forEach(el => {

                    if (this.state.menuList.findIndex(p => p.menuId == el.menuId || p.form === "All") >= 0)
                        this.getMenu(el, obj.children);
                    //  list.push(obj);
                });

            }


        }


    }

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();

        this.restartTable();


    }

    save = async () => {

        const list = this.state.stepList;



        list.forEach((element, index) => {
            element.stepOrder = index;
        });

        await handleRequest("POST", "/TransmittalWorkflow/saveAll", list);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.setState({
            statusId: row.id,
            status: row.status,
            abbreviation: row.abbreviation,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }



    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        const response = await handleRequest("GET", "/TransmittalWorkflow/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id;
                element.value = element.stepName;

                if (Boolean(element.activationList) && element.activationList.length > 0) {
                    element.activationList.forEach(a => {
                        a.activation = Boolean(a.action) ? a.action.actionValue : '';
                        var stepObj = list.find(p => p.id === a.activationStepId);
                        a.step = Boolean(stepObj) ? stepObj.stepName : '';
                    });
                }

            });
            this.setState({
                stepList: list,
                selectedStep: Boolean(list) && list.length > 0 ? list[list.length - 1] : null
            });


        }

        if (response.data.length === 0) {
            this.setState({
                stepList: [],
            });

        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "TransmittalWorkflow" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "TransmittalWorkflow"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "TransmittalWorkflow");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    addStep = async () => {


        var list = this.state.stepList.filter(p => p.active === true);

        if (this.state.stepName.trim().length > 0 && this.state.stepMenuId>0) {

            let isThere = list.find(p => p.value.toUpperCase() === this.state.stepName.toUpperCase());
            if (Boolean(isThere)) {
                this.showError("This workflow added before as a step !")
                return; 
            } 

            var fObj = {
                stepName: this.state.stepName,
                stepOrder: this.state.stepList.length,
                menuId: this.state.stepMenuId,
            };

            // list.push(fObj); 
            await handleRequest("POST", "/TransmittalWorkflow/add", fObj);

            this.showSuccess();
            this.resetInputs();
            this.restartTable();
        }
        else this.showError("Please type step name ! or select menu") ; 



    }

    addActivation = () => {

        if (Boolean(this.state.actionId) && Boolean(this.state.activationStepId) && Boolean(this.state.selectedStep)) {
            //  const list = this.state.selectedStep.activationList.filter(p => p.active === true); 
            const list = this.state.activationList.filter(p => p.active === true);
            let isThere = list.find(p => p.actionId === this.state.actionId);

            if (Boolean(isThere)) {
                this.showError("This activation added before !")
                return;
            }
            var stepObj = this.state.stepList.find(p => p.id === this.state.activationStepId);
            var fObj = {};
            fObj.activation = this.state.activation;
            fObj.step = Boolean(stepObj) ? stepObj.stepName : '';
            fObj.actionId = this.state.actionId;
            fObj.activationStepId = this.state.activationStepId;
            fObj.active = true;
            fObj.id = 0;
            list.push(fObj);

            console.log(list);



            this.setState(prevState => ({
                stepList: prevState.stepList.map(
                    el => el.id === this.state.selectedStep.id && el.value === this.state.selectedStep.value ? {
                        ...el,
                        activationList: list
                    } : el
                )
            }));

            this.setState({
                activationList: list
            })

        }
        else this.showError("Please type step name !")

        console.log(this.state.stepList);

        console.log(this.state.selectedStep);

        console.log(this.state.selectedStep.activationList);

    }


    moveUp = () => {

        const list = this.state.stepList;
        const step = this.state.selectedStep;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.id == step.id && p.value == step.value);

            if (index > 0) {

                let flag = list[index - 1];
                list[index - 1] = step;
                list[index] = flag;

            }

            this.setState({
                stepList: list
            });

        }

    }

    moveDown = () => {

        var list = this.state.stepList;
        const step = this.state.selectedStep;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.id == step.id && p.value == step.value);

            if (index < list.length - 1) {

                let flag = list[index + 1];
                list[index + 1] = step;
                list[index] = flag;

            }

            this.setState({
                stepList: list
            });

        }

    }

    removeStep = (step) => {

        this.setState(prevState => ({
            stepList: prevState.stepList.map(
                el => el.id === step.id && el.value === step.value ? { ...el, active: false } : el
            )
        }))
    }

    removeActivation = (act) => {

        this.setState(prevState => ({
            activationList: prevState.activationList.map(
                el => el.id === act.id && el.actionId === act.actionId ? { ...el, active: false } : el
            )
        }));

        setTimeout(() => {
            this.setState(prevState => ({
                stepList: prevState.stepList.map(
                    el => el.id === this.state.selectedStep.id && el.value === this.state.selectedStep.value ? {
                        ...el,
                        activationList: this.state.activationList
                    } : el
                )
            }));
        }, 500);

    }

    clickStepValue = (step) => {
       
        this.fillActions(step.menuId);
        this.setState({
            activationList: step.activationList
        })

        this.setState({ selectedStep: step });
    }


    onChangeTreeSelect = (value, label, extra) => {

        var menuId =  parseInt(value);
        this.fillActions(menuId);  

        this.setState({
            stepMenuId: menuId ,
            menuLabel:label.toString()
           
        });
    };

    fillActions = async (menuId) => { 

        var obj = {dynamicMenuId:menuId }
        const response = await handleRequest("POST", "/DynamicAction/getActions",obj);

        if (response.data.length !== 0) { 

            this.setState({
                actionList: response.data
            })
        }
        else this.setState({
            actionList: []
        })

    }



    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>



                        <div style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Step Name" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="4">
                                                <Input id="todo" value={this.state.stepName} onChange={(e) => this.setState({ stepName: e.target.value })} />

                                            </Col>
                                            <Col sm="4">
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    value={this.state.stepMenuId !== null && this.state.stepMenuId>0 && this.state.stepMenuId.toString()}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={this.state.nodes}
                                                    placeholder="Please select"
                                                    treeDefaultExpandAll
                                                    onChange={this.onChangeTreeSelect}
                                                />
                                            </Col>
                                            <Col sm="1">
                                                <ReactButton id="OrganizationalLevelSaveButton" type="button" onClick={this.addStep} style={{ width: '100%' }}
                                                    variant="outline-info" ><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                            </Col>
                                            <Col sm="2">
                                                <Form.Row hidden={!Boolean(this.state.selectedStep)} >
                                                    <Col sm="8">

                                                    </Col>
                                                    <Col sm="4">
                                                        <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.moveUp} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />

                                                    </Col>
                                                    <Col sm="8">

                                                    </Col>
                                                    <Col sm="4">
                                                        <AntButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={this.moveDown} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                            <Col sm="4">
                                                <Timeline mode='left'>

                                                    {this.state.stepList.filter(p => p.active === true).map((opt, j) => (
                                                        <Timeline.Item label={opt.label}> <a
                                                            style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                                                            onClick={() => this.clickStepValue(opt)}
                                                        >
                                                            {opt.value}
                                                            <AntButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.removeStep(opt)} shape="circle" icon={<CloseOutlined />} />
                                                        </a>
                                                        </Timeline.Item>

                                                    ))}

                                                </Timeline>
                                            </Col>

                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.selectedStep) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Step Name" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                {Boolean(this.state.selectedStep) && <Form.Label style={{ color: 'black', font: 'bold', fontSize: '16pt' }} >
                                                    {this.state.selectedStep.value}
                                                </Form.Label>}

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.selectedStep) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Activation - Step Define" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="4">

                                            <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.actionId} onChange={(value) => {

                                                    let label = this.state.menuLabel + ' / ' + this.state.actionList.find(p=>p.id===value).actionValue ;
                                                    this.setState({
                                                        actionId: value,
                                                        activation:label
                                                    })
                                                }}>
                                                    {this.state.actionList.filter(p => p.active === true).map(i => (
                                                        <Option key={i.id} value={i.id}>{i.actionValue}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="4">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.activationStepId} onChange={(value) => {
                                                    this.setState({
                                                        activationStepId: value
                                                    })
                                                }}>
                                                    {this.state.stepList.filter(p => p.active === true).map(i => (
                                                        <Option key={i.id} value={i.id}>{i.value}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="1">
                                                <ReactButton id="OrganizationalLevelSaveButton" style={{ width: '100%' }} onClick={this.addActivation}
                                                    variant="outline-info" ><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                            </Col>
                                            <Col sm="3">

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.activationList) && this.state.activationList.length > 0 &&
                                    <FormAnt.Item

                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >

                                        {this.state.activationList.filter(p => p.active === true).map(i => (

                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                </Col>
                                                <Col sm="2">
                                                    {i.activation}
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" icon={<ArrowRightOutlined style={{ fontSize: '32px' }} />} />
                                                </Col>
                                                <Col sm="2">
                                                    {i.step}
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.removeActivation(i)} shape="circle" icon={<CloseOutlined />} />
                                                </Col>
                                            </Form.Group>
                                        ))}

                                    </FormAnt.Item>
                                }


                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>


                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TransmittalWorkflow)

