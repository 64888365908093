/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { Modal, Button as ReactButton, Col, Form as FormBoot, Row } from 'react-bootstrap';
import { Card, Cascader, Checkbox, Select, Radio, InputNumber, DatePicker, Spin, Divider } from 'antd';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { Redirect } from "react-router-dom";
import { MultiSelect } from 'primereact/multiselect';
import { GoogleMap, LoadScript, Polyline, Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';
//import debounce from 'lodash/debounce';
import SearchUser from './components/SearchUser';

const { RangePicker } = DatePicker;
const { Option } = Select;

var intervalId;
var selectedUserOptions = null;
var departmentId = null;
var disciplineId = null;
var designationId = null;
var nameLastName = "";

export class InspectorLocation extends Component {
    constructor() {
        super();
        //this.fetchUser = debounce(this.fetchUser, 800);
        this.state = {
            frequency: "",
            alertTime: "",
            frequencyUnit: "",
            alertTimeUnit: "",
            modalILSettings: false,
            trackOrTrace: "Track",
            selectedInspectors: [],
            inspectors: [],
            dateShow: false,
            startDate: null,
            endDate: null,
            intervalId: null,
            settings: null,
            settingsId: null,
            centerPosition: { lat: 26.515690046332118, lng: 49.17807951249998 },

            staffDeviceData: [],
            followedDeviceIds: [],
            showAll: true,
            deviceIds: [],
            drivers: [],
            driverIds: [],
            driverDeviceIds: [],
            driverName: "",
            selectedTruck: false,
            centerLat: 26.515690046332118,
            centerLong: 49.17807951249998,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            departmentCascader: [],
            values: [],
            selectedUserOptions: [],
            selectedInspectors2: [],
            inspectors2: [],
            inspectors3: [],
            fetching: false,
            value: [],
        };

        this.onInspectorChange = this.onInspectorChange.bind(this);
        this.onInspector2Change = this.onInspector2Change.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    };

    formRef = React.createRef();

    componentDidMount = async () => {

        this.getUsers(null, null, null);

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' 23:59:59';
        var today1 = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

        this.setState({
            startDate: today1,
            endDate: today
        });

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InspectorLocation" });

        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "InspectorLocation",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InspectorLocation");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {

        }

        const responseStaff = await handleRequest("GET", "/Staff/getCompanyItems");

        if (responseStaff.data.length !== 0) {

            var columns = responseStaff.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].designation.designationName.toString() === "Inspector")
                    options.push({ label: columns[index].name.toString() + " " + columns[index].lastName.toString(), value: columns[index].id.toString() });
            }

            this.setState({
                inspectors: options
            });
        }

        if (responseStaff.data.length === 0) {
            this.setState({
                inspectors: []
            });
        }

        const responseStaffDevice = await handleRequest("GET", "/StaffDevice/getAll");

        if (responseStaffDevice.data.length !== 0) {
            this.setState({
                staffDeviceData: responseStaffDevice.data
            });
        } else if (responseStaffDevice.data.length === 0) {
            this.setState({
                staffDeviceData: []
            });
        }


        const responseCascader = await handleRequest("GET", "/Department/getNodes");

        console.log(responseCascader.data);

        if (responseCascader.data.length !== 0) {
            this.setState({
                departmentCascader: responseCascader.data
            });
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalId);
    }

    onChangeTrackTrace = e => {
        this.setState({
            trackOrTrace: e.target.value,
        });

        if (e.target.value === "Trace") {
            this.setState({
                dateShow: true
            });
            clearInterval(this.state.intervalId);
        } else {
            intervalId = setInterval(this.getLocations, 5000);
            this.setState({
                dateShow: false,
                intervalId: intervalId
            });
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs. Inputs can't be empty or 0." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    showOrHideModalILSettings = () => {
        this.setState({
            modalILSettings: !this.state.modalILSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    settingsOK = async () => {
        if (this.state.frequency !== null && this.state.frequency !== "" && this.state.frequency !== 0 && this.state.frequencyUnit !== ""
            && this.state.alertTime !== null && this.state.alertTime !== "" && this.state.alertTime !== 0 && this.state.alertTimeUnit !== "") {

            var responseUpdate;

            if (this.state.settingsId === null) {
                const inspectorPageSettingsAdd = {
                    frequency: Number(this.state.frequency),
                    frequencyUnit: this.state.frequencyUnit,
                    alertTime: Number(this.state.alertTime),
                    alertTimeUnit: this.state.alertTimeUnit
                };

                responseUpdate = await handleRequest("POST", `/InspectorLocationFrequency/add`, inspectorPageSettingsAdd);
            } else {
                const inspectorPageSettingsUpdate = {
                    id: this.state.settingsId,
                    frequency: Number(this.state.frequency),
                    frequencyUnit: this.state.frequencyUnit,
                    alertTime: Number(this.state.alertTime),
                    alertTimeUnit: this.state.alertTimeUnit
                };

                responseUpdate = await handleRequest("POST", `/InspectorLocationFrequency/update`, inspectorPageSettingsUpdate);
            }

            if (responseUpdate.data !== null && responseUpdate.data !== undefined) {
                if (responseUpdate.data === "SUCCESS") {
                    this.showSuccess("Settings have been saved successfully.");
                    this.restartSettings();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    onFrequencyUnitChange = value => {
        this.setState({
            frequencyUnit: value
        });
    }

    onAlertTimeUnitChange = value => {
        this.setState({
            alertTimeUnit: value
        });
    }

    onInspectorChange(event) {
        this.setState({ selectedInspectors: event.value });

        var staffDeviceDataFlag = this.state.staffDeviceData;
        let followedDeviceIds = [];

        for (let index = 0; index < staffDeviceDataFlag.length; index++) {
            for (let index1 = 0; index1 < event.value.length; index1++) {
                if ((staffDeviceDataFlag[index].staffId.toString()) === event.value[index1] && !(followedDeviceIds.includes(staffDeviceDataFlag[index].deviceId))) {
                    followedDeviceIds.push(staffDeviceDataFlag[index].deviceId);
                }
            }
        }

        this.setState({
            followedDeviceIds: followedDeviceIds
        });
    }

    onInspector2Change(event) {
        this.setState({ selectedInspectors2: event.value });
        console.log(event.value);

        // var staffDeviceDataFlag = this.state.staffDeviceData;
        // let followedDeviceIds = [];

        // for (let index = 0; index < staffDeviceDataFlag.length; index++) {
        //     for (let index1 = 0; index1 < event.value.length; index1++) {
        //         if ((staffDeviceDataFlag[index].staffId.toString()) === event.value[index1] && !(followedDeviceIds.includes(staffDeviceDataFlag[index].deviceId))) {
        //             followedDeviceIds.push(staffDeviceDataFlag[index].deviceId);
        //         }
        //     }
        // }

        // this.setState({
        //     followedDeviceIds: followedDeviceIds
        // });
    }

    disabledDate = (current) => {
        return current > moment().endOf('day');
    }

    onChangeDate = (date, dateString) => {
        if (date !== null) {

            date[0]._d.setSeconds(0);
            date[1]._d.setSeconds(59);

            this.setState({
                startDate: date[0]._d,
                endDate: date[1]._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 23:59:59';
            var today1 = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                startDate: today1,
                endDate: today
            });
        }

        if (this.state.dateShow === true) {
            this.getLocations();
        }
    }

    showLocations = async () => {
        if (this.state.intervalId !== null) {
            clearInterval(this.state.intervalId);
        }

        if (this.state.trackOrTrace === "Trace") {
            this.getLocations();
        } else if (this.state.trackOrTrace === "Track") {
            this.restartSettings();
        }
    }

    restartSettings = async () => {

        this.getLocations();

        const responseFrequency = await handleRequest("GET", "/InspectorLocationFrequency/getCompanyItems");

        if (responseFrequency.data.length > 0) {

            this.setState({
                settings: responseFrequency.data,
                settingsId: responseFrequency.data[0].id,
                frequency: responseFrequency.data[0].frequency,
                frequencyUnit: responseFrequency.data[0].frequencyUnit,
                alertTime: responseFrequency.data[0].alertTime,
                alertTimeUnit: responseFrequency.data[0].alertTimeUnit,
                modalILSettings: false
            });

            var runTimeFrequency;

            if (responseFrequency.data[0].frequencyUnit === "Second") {
                runTimeFrequency = 1000 * Number(responseFrequency.data[0].frequency);
            } else if (responseFrequency.data[0].frequencyUnit === "Minute") {
                runTimeFrequency = 60000 * Number(responseFrequency.data[0].frequency);
            } else if (responseFrequency.data[0].frequencyUnit === "Hour") {
                runTimeFrequency = 3600000 * Number(responseFrequency.data[0].frequency);
            } else if (responseFrequency.data[0].frequencyUnit === "Day") {
                runTimeFrequency = 86400000 * Number(responseFrequency.data[0].frequency);
            }

            intervalId = setInterval(this.getLocations, runTimeFrequency);
            this.setState({ intervalId: intervalId });

        } else {
            this.setState({
                settings: null,
                modalILSettings: false
            });

            intervalId = setInterval(this.getLocations, 5000);
            this.setState({ intervalId: intervalId });
        }
    }

    getLocations = async () => {
        var startDateFlag = moment(this.state.startDate, 'DD/MM/YYYY HH:mm:ss')._d;
        var endDateFlag = moment(this.state.endDate, 'DD/MM/YYYY HH:mm:ss')._d;

        const responseLocation = await handleRequest("GET", "/TrailerLocation/getAll");
        const responseStaffDevice = await handleRequest("GET", "/StaffDevice/getAll");

        const uniqueIds = [];
        const driverIds = [];
        const drivers = [];
        const driverDeviceIds = [];

        const columns1 = responseStaffDevice.data;

        if (responseLocation.data.length !== 0) {
            const columns = responseLocation.data;

            columns.map(row => {
                if (uniqueIds.indexOf(row.deviceId) === -1) {
                    for (let index = 0; index < columns1.length; index++) {
                        if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                            driverIds.push(columns1[index].staffId);
                            driverDeviceIds.push(row.deviceId);
                            drivers.push(columns1[index].staff);
                        }
                    }
                    uniqueIds.push(row.deviceId);
                }
            });

            this.setState({
                deviceIds: uniqueIds,
                driverIds: driverIds,
                driverDeviceIds: driverDeviceIds,
                drivers: drivers
            });

            for (let index = 0; index < uniqueIds.length; index++) {
                this.setState({
                    [`${uniqueIds}`]: []
                });
            }

            for (let index = 0; index < uniqueIds.length; index++) {

                var flagLangLat = [];

                if (this.state.trackOrTrace === "Track") {
                    for (let index1 = 0; index1 < responseLocation.data.length; index1++) {
                        if (uniqueIds[index] === responseLocation.data[index1].deviceId) {
                            var val = { lat: parseFloat(responseLocation.data[index1].latitude), lng: parseFloat(responseLocation.data[index1].longitude) };
                            //flagLangLat.push(val);  hepsini göstermek yerine son lokasyonu göstermek istiyoruz.
                            flagLangLat[0] = val;
                        }
                    }
                } else if (this.state.trackOrTrace === "Trace") {
                    for (let index1 = 0; index1 < responseLocation.data.length; index1++) {
                        let flagCreateDate = new Date(Date.parse(responseLocation.data[index1].createDate));
                        flagCreateDate.setTime(flagCreateDate.getTime() - (3 * 60 * 60 * 1000));  //Bu 3 saat sunucudan kaynaklı, java backend 3 saat fazla çekiyor.
                        if (uniqueIds[index] === responseLocation.data[index1].deviceId && (flagCreateDate >= startDateFlag) && (flagCreateDate <= endDateFlag)) {
                            var val = { lat: parseFloat(responseLocation.data[index1].latitude), lng: parseFloat(responseLocation.data[index1].longitude) };
                            flagLangLat.push(val);
                        }
                    }
                }

                var randomColor = this.randDarkColor();

                const arrow = {
                    path: 'M 0,0 3,9 -3,9 0,0 z',
                    fillColor: 'red',
                    fillOpacity: 1.0,
                    strokeColor: 'red',
                    strokeWeight: 1,
                };

                const symbolOne = {
                    path: 'M -2,-2 2,2 M 2,-2 -2,2',
                    strokeColor: '#292',
                    strokeWeight: 4
                };

                var symbolTwo = {
                    path: 'M -2,0 0,-2 2,0 0,2 z',
                    strokeColor: '#F00',
                    fillColor: '#F00',
                    fillOpacity: 1
                };

                this.setState({
                    [`${uniqueIds[index]}`]: flagLangLat,
                    [`${uniqueIds[index]}_option`]: {
                        strokeColor: randomColor,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: randomColor,
                        fillOpacity: 0.35,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: 30000,
                        paths: flagLangLat,
                        zIndex: 1,
                        icons: [{
                            icon: symbolOne,
                            offset: '0%'
                        }, {
                            icon: arrow,
                            offset: '50%',
                            repeat: '200px'
                        },
                        {
                            icon: symbolTwo,
                            offset: '100%'
                        }]
                    }
                });
            }
        }
    }

    randDarkColor() {
        var lum = -0.25;
        var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }
        return rgb;
    }

    onDepartmentCascaderChange = (value, selectedOptions) => {
        this.setState({
            values: value,
            selectedUserOptions: selectedOptions
        });
        selectedUserOptions = selectedOptions;

        if (value.length === 0) {
            departmentId = null;
            disciplineId = null;
            designationId = null;
            this.getUsers();
        }
    }

    cascaderClose = (value) => {
        if (!value && this.state.selectedUserOptions.length !== 0 && this.state.values.length !== 0) {
            console.log(selectedUserOptions);
            for (let index = 0; index < selectedUserOptions.length; index++) {
                if (selectedUserOptions[index].key.toString() === "department")
                    departmentId = selectedUserOptions[index].value;
                if (selectedUserOptions[index].key.toString() === "discipline")
                    disciplineId = selectedUserOptions[index].value;
                if (selectedUserOptions[index].key.toString() === "designation")
                    designationId = selectedUserOptions[index].value;
            }
            this.getUsers();
        }

        if (!value && (this.state.values.length === 0 || this.state.selectedUserOptions.length === 0)) {
            this.getUsers();
        }
    }

    getUsers = async () => {
        const staffModel = {
            departmentId: departmentId,
            disciplineId: disciplineId,
            designationId: designationId,
            nameLastName: ""
        };

        const responseStaffs = await handleRequest("POST", "/Staff/getCompanyItemsBySearch", staffModel);

        if (responseStaffs.data.length !== 0) {
            var columns = responseStaffs.data;
            var options = [];
            for (let index = 0; index < columns.length; index++) {
                options.push({ text: columns[index].name.toString() + " " + columns[index].lastName.toString(), value: columns[index].id.toString() });
            }

            this.setState({
                inspectors2: options,
                inspectors3: options
            });
        }

        if (responseStaffs.data.length === 0) {
            this.setState({
                inspectors2: [],
                inspectors3: []
            });
        }
    }

    handleChangeUserSearch = (value) => {
        console.log(value);

        this.setState({
            value,
            fetching: false,
        });
    }

    selectUsers = async (value) => {
        console.log(value);
    }

    render() {

        const { fetching, value } = this.state;

        const mapContainerStyle = {
            height: "500px",
            width: "100%"
        };

        const center = this.state.centerPosition;

        const onLoad = polyline => {
            //console.log('polyline: ', polyline)
        };

        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        var devices = [];

        for (let index = 0; index < this.state.deviceIds.length; index++) {
            if (this.state.deviceIds.length !== 0) {
                var lengthFlag;
                if (this.state[`${this.state.deviceIds[index]}`] !== undefined && this.state.followedDeviceIds.includes(this.state.deviceIds[index])) {
                    lengthFlag = this.state[`${this.state.deviceIds[index]}`].length;
                    devices.push(<div key={`${this.state.deviceIds[index]}`}>
                        <Polyline
                            onLoad={onLoad}
                            path={this.state[`${this.state.deviceIds[index]}`]}
                            options={this.state[`${this.state.deviceIds[index]}_option`]}
                        />
                        <Marker
                            icon="https://i.ibb.co/60Sk1mW/inspector.png"
                            position={this.state[`${this.state.deviceIds[index]}`][lengthFlag - 1]}
                            onClick={() => {

                                let flagLength = this.state[`${this.state.deviceIds[index]}`].length;
                                let driverFound = false;

                                for (let index1 = 0; index1 < this.state.driverDeviceIds.length; index1++) {
                                    if (this.state.deviceIds[index] === this.state.driverDeviceIds[index1]) {
                                        driverFound = true;
                                        this.setState({
                                            driverName: this.state.drivers[index1].name + " " + this.state.drivers[index1].lastName
                                        })
                                    }
                                }
                                if (!driverFound) {
                                    this.setState({
                                        driverName: "No Driver Data"
                                    })
                                }
                                this.setState({
                                    selectedTruck: true,
                                    selectedTruckPosition: this.state[`${this.state.deviceIds[index]}`][flagLength - 1],
                                    centerPosition: this.state[`${this.state.deviceIds[index]}`][flagLength - 1]
                                })
                            }}
                        />
                        {this.state.selectedTruck &&
                            <InfoWindow
                                position={this.state.selectedTruckPosition}
                                onCloseClick={() => {
                                    this.setState({
                                        selectedTruck: false,
                                        selectedTruckPosition: null,
                                        driverName: ""
                                    })
                                }}
                            >
                                <div style={{ background: "white" }}>
                                    {this.state.driverName}
                                </div>
                            </InfoWindow>
                        }
                    </div>);
                }
            }

            if (this.state.followedDeviceIds.length !== 0) {
                let lengthFlag0 = this.state[`${this.state.followedDeviceIds[0]}`];
                if (lengthFlag0 !== 0 && lengthFlag0 !== undefined) {
                    let lastIndex = lengthFlag0.length - 1;
                    let flagPosition = lengthFlag0[lastIndex];
                    if (this.state.centerPosition !== flagPosition) {
                        this.setState({
                            centerPosition: flagPosition
                        });
                    }
                }
            }
        }

        return <div>

                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalILSettings}
                        onHide={this.showOrHideModalILSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Settings
                       </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row style={{ marginTop: "1rem", width: "100%" }}>
                                <Col sm="4">
                                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                                        Frequency:
                                </FormBoot.Label>
                                </Col>
                                <Col sm="4">
                                    <InputNumber id="InspectorLocationFrequency" value={this.state.frequency} onChange={(value) => this.setState({ frequency: value })} />
                                </Col>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="" value={this.state.frequencyUnit} onChange={this.onFrequencyUnitChange} >
                                        <Option value="Second">Second</Option>
                                        <Option value="Minute">Minute</Option>
                                        <Option value="Hour">Hour</Option>
                                        <Option value="Day">Day</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem", width: "100%" }}>
                                <Col sm="4">
                                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                                        Alert Time:
                                </FormBoot.Label>
                                </Col>
                                <Col sm="4">
                                    <InputNumber id="InspectorLocationAlertTime" value={this.state.alertTime} onChange={(value) => this.setState({ alertTime: value })} />
                                </Col>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="" value={this.state.alertTimeUnit} onChange={this.onAlertTimeUnitChange} >
                                        <Option value="Second">Second</Option>
                                        <Option value="Minute">Minute</Option>
                                        <Option value="Hour">Hour</Option>
                                        <Option value="Day">Day</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalILSettings}> Close </ReactButton>
                            <ReactButton variant="primary" onClick={this.settingsOK}> Save Settings </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <div>
                        <Row>
                            <Col sm="1">
                                {this.state.isAdmin ?
                                    <IconButton color="primary" onClick={this.showMNDSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                    :
                                    <IconButton color="primary" onClick={this.showSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                }
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                    <HelpIcon />
                                </IconButton>
                                }
                            </Col>
                        </Row>
                    </div>

                    <Card>
                        <Row style={{ marginTop: "1rem", width: "100%" }}>
                            <Col sm="2">
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                                    Inspector:
                            </FormBoot.Label>
                            </Col>
                            <Col sm="3">
                                <MultiSelect style={{ width: '100%' }} id="InspectorLocationInspectors" value={this.state.selectedInspectors} options={this.state.inspectors}
                                    onChange={this.onInspectorChange} />
                            </Col>
                            <Col sm="2">
                                <ReactButton id="InspectorLocationFrequency" style={{ width: '100%' }} onClick={this.showOrHideModalILSettings} variant="info">
                                    <FormattedMessage id="GeneralButtonSettings" defaultMessage="Settings" />
                                </ReactButton>
                            </Col>
                            <Col sm="5">
                            </Col>
                        </Row>

                        {/*<Row style={{ marginTop: "1rem", width: "100%" }}>
                            <Col sm="2">
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                                    Test User Select:
                            </FormBoot.Label>
                            </Col>
                            <Col sm="3">
                                <Cascader
                                    id="InspectorLocationUser"
                                    style={{ width: '100%' }}
                                    options={this.state.departmentCascader}
                                    value={this.state.values}
                                    onChange={this.onDepartmentCascaderChange}
                                    onPopupVisibleChange={this.cascaderClose}
                                    changeOnSelect
                                />
                            </Col>
                            <Col sm="3">
                                <MultiSelect style={{ width: '100%' }} id="InspectorLocationInspectors2" value={this.state.selectedInspectors2} options={this.state.inspectors2}
                                    filter onChange={this.onInspector2Change} />
                            </Col>
                            <Col sm="3">
                            </Col>
                            </Row>*/}

                        <SearchUser selectUsers={this.selectUsers.bind(this)} />

                        <Row style={{ marginTop: "1rem", width: "100%" }}>
                            <Col sm="2">
                            </Col>
                            <Col sm="3">
                                <Radio.Group onChange={this.onChangeTrackTrace} value={this.state.trackOrTrace} size="large">
                                    <Radio value="Track">Track</Radio>
                                    <Radio value="Trace">Trace</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        {(this.state.dateShow) && <Row style={{ marginTop: "2rem", width: "100%" }}>
                            <Col sm="2">
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                                    Date:
                                </FormBoot.Label>
                            </Col>
                            <Col sm="3">
                                <RangePicker showTime={{ format: 'HH:mm' }} value={[moment(this.state.startDate, 'DD/MM/YYYY HH:mm:ss'),
                                moment(this.state.endDate, 'DD/MM/YYYY HH:mm:ss')]} disabledDate={this.disabledDate}
                                    format="DD/MM/YYYY HH:mm:ss" onChange={this.onChangeDate} />
                            </Col>
                        </Row>}

                        <Row style={{ marginTop: "1rem", width: "100%" }}>
                            <Col sm="2">
                            </Col>
                            <Col sm="3">
                                <ReactButton id="InspectorLocationFrequency" style={{ width: '100%' }} onClick={this.showLocations} variant="primary">
                                    <FormattedMessage id="GeneralButtonShow" defaultMessage="Show Inspectors" />
                                </ReactButton>
                            </Col>
                            <Col sm="7">
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "1rem", width: "100%" }}>
                            <Col sm="12">
                                <LoadScript
                                    id="script-loader"
                                    googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc"
                                >
                                    <GoogleMap
                                        id="marker-example"
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={8}
                                        center={center}
                                    >
                                        {devices}
                                    </GoogleMap>
                                </LoadScript>
                            </Col>
                        </Row>

                    </Card>
               </MenuProvider>
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectorLocation)