/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { InputNumber, Space, Row as RowAnt, Checkbox } from 'antd';
import { Table, Avatar, Button as AntButton } from 'antd';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { MultiSelect } from 'primereact/multiselect';
import SalesQuoteSub from './SalesQuoteSub';
// Import the main component
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
// Import the CSS
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
//import { pdfjs } from 'react-pdf';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import moment from 'moment';

const { Column, ColumnGroup } = Table;

//var a = 0;

const _paymentRows = [
  {
    paymentIndex: 1,
    title: "1. payment",
    description: "",
    paymentValue: "",
    paymentValueType: "Select"
  }
];

var _termCondRows = [];

var _noteRows = [];

export class SalesQuote extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customerList: [],
      hidden: true,
      createNewButton: "Create New Quote",
      createNewButtonVariant: "info",
      itemsContacts: [],
      address: "",
      phone: [],
      fax: [],
      email: [],
      social: [],
      validity: "",
      respDepartment: "",
      certificate: "",
      loading: false,
      imageUrl: null,
      products: [],
      productDetail: [],
      projectList: [],
      locationList: [],
      name: "",
      midname: "",
      surname: "",
      position: "",
      rPhone: "",
      rEmail: "",
      country: "",
      serviceList: [],
      code: "",
      unit: '',
      unitCodes: [],
      currencyCodes: [],
      selectedRow: null,

      modalDelete: false,
      modalEdit: false,
      discountCheck: false,
      quoteValidityCheck: false,
      quoteValidityValue: '',
      quoteValidityTimeType: "Select",
      paymentCheck: false,
      VATTaxCheck: false,
      VATTaxValue: '',
      paymentRows: [
        {
          paymentIndex: 1,
          title: "1. payment",
          description: "",
          paymentValue: "",
          paymentValueType: "Select"
        }
      ],
      termCondRows: [],
      termCondName: "",
      noteRows: [],
      noteName: "",
      totalDiscountInput: "",
      web: "",
      modalError: false,
      modalErrorNewQuote: false,
      description: "",
      price: "",
      unit: "",
      quantity: 1,
      currency: "",
      discount: "",
      deliverySchedule: "",
      modalDeleteProduct: false,
      productId: null,
      productUpdateButtonHidden: "none",
      productAddButtonHidden: "block",
      subTotals: [],
      subTotalCheck: false,
      totalCheck: false,
      subDiscount: 0,
      subTotalDiscount: [],
      total: [],
      lastSalesQuotes: [],
      projectId: 0,
      location: "",
      locationId: 0,
      project: "",
      quoteNo: "",
      modalPriceDetail: false,
      overallCost: 0,
      productPrice: 0,
      modalShipmentPriceRecalc: false,
      yWorkingDays: 300,
      mWorkingDays: 26,
      wWorkingDays: 6,
      dWorkingHours: 10,
      equipments: [],
      equipmentOptions: [],
      selectedEquipments: [],
      locationDistance: 0,
      cantCalculate: false,
      modalPdfViewer: false,
      pdfFile: ""
    };

    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
    //this.selectedTruckTemplate = this.selectedTruckTemplate.bind(this);
  }


  componentDidMount = async () => {

    const responseEquipmentLog = await handleRequest("GET", "/EquipmentLog/getAll");

    if (responseEquipmentLog.data.length === 0) {
      this.setState({
        equipments: [],
        equipmentOptions: [],
        equipmentLogs: []
      });
    } else if (responseEquipmentLog.data.length > 0) {
      let equipmentLogs = responseEquipmentLog.data;
      let options = [];
      let _selectedEquipments = [];  //başta hepsi seçili olarak gelecek.

      for (let index = 0; index < equipmentLogs.length; index++) {
        if (equipmentLogs[index].equipmentVehicleLicenceList !== null && equipmentLogs[index].equipmentVehicleLicenceList.length !== 0) {
          options.push({ label: equipmentLogs[index].equipmentDescription.toString() + " - " + equipmentLogs[index].equipmentVehicleLicenceList[0].plateNo.toString(), value: equipmentLogs[index].id.toString() });
          _selectedEquipments.push(equipmentLogs[index].id);
        } else {
          options.push({ label: equipmentLogs[index].equipmentDescription.toString(), value: equipmentLogs[index].id.toString() });
          _selectedEquipments.push(equipmentLogs[index].id);
        }
      }

      this.setState({
        equipments: equipmentLogs,
        equipmentOptions: options,
        equipmentLogs: responseEquipmentLog.data,
        selectedEquipments: _selectedEquipments
      });
    }

    this.fillCodes();
    await this.restartTable();
    await this.getProducts();
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showReport = (a) => {
    try {
      const fileRealUrl = `${API_BASE}/SalesQuote/download1/` + a;

      console.log("fileUrl: " + fileRealUrl);

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });

    } catch (error) {
      console.log(error);
    }
  };

  getProducts = async () => {
    const response = await handleRequest("GET", "/CompanyService/getAllByCompany");
    if (response.data.length > 0) {
      this.setState({
        products: response.data,
      });
    }
  }

  selectProductDetails = (e) => {
    e.preventDefault();
    var list = this.state.products;

    var filteredList = list.filter(p => p.description === e.target.value)

    if (filteredList.length > 0) {

      this.setState({
        [e.target.name]: e.target.value,
        unit: filteredList[0].unitCode.name,
        productPrice: filteredList[0].price,
        currency: filteredList[0].currencyCode.name,
      });

      if (!(filteredList[0].unitCode.name === "m3" || filteredList[0].unitCode.name === "M3" || filteredList[0].unitCode.name === "Ton" || filteredList[0].unitCode.name === "ton")) {
        this.setState({
          cantCalculate: true,
          price: Number.parseFloat(filteredList[0].price + 0).toFixed(2)
        });
      } else {
        this.setState({
          cantCalculate: false,
          price: Number.parseFloat(filteredList[0].price + this.state.overallCost).toFixed(2)
        });
      }

    }
  }

  fillCodes = async () => {
    const response = await handleRequest("GET", "/Code/type/unit");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        unitCodes: response.data
      });
    }

    const response2 = await handleRequest("GET", "/Code/type/currency");

    if (response2.data !== null && response2.data !== undefined) {
      this.setState({
        currencyCodes: response2.data
      });
    }
  }

  restartTable = async () => {

    const response = await handleRequest("GET", "/CustomerLog/getAllByCompany");

    if (response.data.length === 0) {
      this.setState({
        customerList: []
      });
    }

    if (response.data.length !== 0) {

      // const _contactDefault = [{ id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] }];
      // const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };

      var rData = response.data;

      // if (!rData.contact)
      //   rData.contacts = _contactDefault;
      // if (!rData.representative)
      //   rData.representative = _representativeDefault;

      // if (!rData.projects)
      //   rData.projects = this.state.projectList;

      rData.forEach(element => {
        element.key = element.id;
      });

      this.setState({
        customerList: rData
      });
    }
  }

  resetInputs = () => {
    this.setState({
      hidden: true,
      createNewButton: "Create New Quote",
      createNewButtonVariant: "info",
      serviceList: [],
      quoteValidityCheck: false,
      quoteValidityValue: '',
      quoteValidityTimeType: "Select",
      paymentCheck: false,
      VATTaxCheck: false,
      VATTaxValue: '',
      paymentRows: [
        {
          paymentIndex: 1,
          title: "1. payment",
          description: "",
          paymentValue: "",
          paymentValueType: "Select"
        }
      ],
      termCondRows: [],
      termCondName: "",
      noteRows: [],
      noteName: "",
      totalDiscountInput: "",
      modalError: false,
      description: "",
      price: "",
      unit: "",
      quantity: 1,
      currency: "",
      discount: "",
      deliverySchedule: "",
      modalDeleteProduct: false,
      productId: null,
      productUpdateButtonHidden: "none",
      productAddButtonHidden: "block",
      subTotals: [],
      subTotalCheck: false,
      subDiscount: 0,
      subTotalDiscount: [],
      total: [],
      projectId: 0,
      project: "",
      quoteNo: "",
      location: "",
      locationId: 0,
      overallCost: 0,
      productPrice: 0,
      yWorkingDays: 300,
      mWorkingDays: 26,
      wWorkingDays: 6,
      dWorkingHours: 10,
      locationDistance: 0
    });
  }

  createNew = async () => {

    if (this.state.selectedRow !== null) {

      this.resetInputs();

      const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
      const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
      var _representative;
      var _contact;
      var _phones = [];
      var _faxes = [];
      var _mails = [];

      if (this.state.selectedRow[0].contact !== null && this.state.selectedRow[0].contact !== undefined) {
        _contact = this.state.selectedRow[0].contact;
        if (_contact.phones !== null && _contact.phones !== undefined) {
          _phones = _contact.phones.replace(",", ", ");
        }
        if (_contact.faxes !== null && _contact.faxes !== undefined)
          _faxes = _contact.faxes.replace(",", ", ");
        if (_contact.mails !== null && _contact.mails !== undefined)
          _mails = _contact.mails.replace(",", ", ");
      }
      else _contact = _contactDefault;

      if (this.state.selectedRow[0].representative !== null && this.state.selectedRow[0].representative !== undefined)
        _representative = this.state.selectedRow[0].representative;
      else _representative = _representativeDefault;

      var _imageUrl = "";

      if (this.state.selectedRow[0].logoPath !== null && this.state.selectedRow[0].logoPath !== undefined && this.state.selectedRow[0].logoPath.length > 0) {
        _imageUrl = `${API_BASE}/CustomerLog/download/` + this.state.selectedRow[0].id
      }

      this.setState({
        paymentRows: _paymentRows,
        imageUrl: _imageUrl,
        address: _contact.address,
        country: _contact.country,
        phone: _phones,
        fax: _faxes,
        email: _mails,
        web: _contact.web,
        name: _representative.name,
        midname: _representative.midname,
        surname: _representative.lastname,
        position: _representative.position,
        rPhone: _representative.phone,
        rEmail: _representative.email,
      });

      const response = await handleRequest("GET", "/SalesQuote/getAllByCompany");

      if (response.data.length === 0) {
        this.setState({
          lastSalesQuotes: []
        });
      }

      if (response.data.length !== 0) {
        let flagData = response.data;

        console.log(flagData[flagData.length - 1]);

        let qvCheck = false;
        if (flagData[flagData.length - 1].quoteValidityCheck === 1) {
          qvCheck = true;
        }

        let vCheck = false;
        if (flagData[flagData.length - 1].vatCheck === 1) {
          vCheck = true;
        }

        _termCondRows = flagData[flagData.length - 1].salesQuoteNewTermList;
        _noteRows = flagData[flagData.length - 1].salesQuoteNoteList;

        this.setState({
          lastSalesQuotes: flagData[flagData.length - 1],
          quoteValidityCheck: qvCheck,
          quoteValidityValue: flagData[flagData.length - 1].quoteValidityValue,
          quoteValidityTimeType: flagData[flagData.length - 1].quoteValidityDateType,
          VATTaxCheck: vCheck,
          VATTaxValue: flagData[flagData.length - 1].vatValue,
          termCondRows: flagData[flagData.length - 1].salesQuoteNewTermList,
          noteRows: flagData[flagData.length - 1].salesQuoteNoteList
        });
      }

      if (this.state.createNewButton === "Create New Quote") {
        this.setState({
          hidden: false,
          createNewButton: "Stop Adding New Quote",
          createNewButtonVariant: "danger"
        });
      } else {
        this.setState({
          hidden: true,
          createNewButton: "Create New Quote",
          createNewButtonVariant: "info"
        });
      }
    } else {
      this.setState({
        modalErrorNewQuote: true
      })
    }
  }

  showSuccess = () => {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
  }

  showError = (message) => {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  numberOfProjects2(record) {
    var rowData = record;
    var result = 0;
    if (rowData.projects !== null && rowData.projects !== undefined) {
      result = rowData.projects.length;
    }
    return result;
  }

  phones(value) {
    var rowData = value;
    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].phones !== null && rowData[0].phones !== undefined) {
      rowData[0].phones.forEach(e => {
        result.push(e.phone);
      });
    }
    return result.toString();
  }

  faxes(value) {
    var rowData = value;
    var result = [];

    if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].faxes !== null && rowData[0].faxes !== undefined) {
      rowData[0].faxes.forEach(e => {
        result.push(e.fax);
      });
    }
    return result.toString();
  }

  mails(value) {
    var rowData = value;
    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].mails !== null && rowData[0].mails !== undefined) {
      rowData[0].mails.forEach(e => {
        result.push(e.mail);
      });
    }
    return result.toString();
  }

  createNewCustomer = () => {
    window.location.href = '/CustomerLog/0';
  }

  addProduct = () => {
    var list = this.state.serviceList;

    if (this.state.description.trim() !== "" && this.state.unit !== "" && this.state.price !== ""
      && this.state.currency !== "" && this.state.deliverySchedule !== "") {

      var _key = list.length + 1;

      let discountValue = 0;

      if (this.state.discount === null || this.state.discount === "") {
        discountValue = 0
      } else {
        discountValue = this.state.discount
      }

      const newItem = {
        key: _key,
        description: this.state.description,
        quantity: this.state.quantity,
        unit: this.state.unit,
        price: this.state.price,
        currency: this.state.currency,
        discount: discountValue,
        deliverySchedule: this.state.deliverySchedule,
      }

      list.push(newItem);

      let diffCurrencies = [];

      for (let index = 0; index < list.length; index++) {
        if (!diffCurrencies.includes(list[index].currency)) {
          diffCurrencies.push(list[index].currency);
        }
      }

      let flagSubTotals = Array(diffCurrencies.length).fill(0);

      for (let index = 0; index < diffCurrencies.length; index++) {
        for (let index1 = 0; index1 < list.length; index1++) {
          if (diffCurrencies[index] === list[index1].currency) {
            flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
          }
        }
      }

      let subTotalsState = [];
      let subDiscountTotalState = [];
      let totalState = [];

      for (let index = 0; index < diffCurrencies.length; index++) {
        subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
        if (this.state.subDiscount != "") {
          let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
          subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
          totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
        } else {
          subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
          totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
        }
      }

      this.setState({
        serviceList: list.slice(),
        description: "",
        quantity: "1",
        unit: "",
        price: "",
        productPrice: 0,
        currency: "",
        discount: "",
        deliverySchedule: "",
        subTotals: subTotalsState,
        subTotalDiscount: subDiscountTotalState,
        total: totalState,
        cantCalculate: false
      })
    } else {
      this.setState({
        modalError: true
      });
    }
  }

  updateProduct = () => {
    let list = this.state.serviceList;
    let flagProductId = this.state.productId;

    let objIndex = list.findIndex((obj => obj.key === flagProductId));

    let discountValue = 0;

    if (this.state.discount === null || this.state.discount === "") {
      discountValue = 0
    } else {
      discountValue = this.state.discount
    }

    list[objIndex].description = this.state.description;
    list[objIndex].quantity = this.state.quantity;
    list[objIndex].unit = this.state.unit;
    list[objIndex].price = this.state.price;
    list[objIndex].currency = this.state.currency;
    list[objIndex].discount = discountValue;
    list[objIndex].deliverySchedule = this.state.deliverySchedule;

    let diffCurrencies = [];

    for (let index = 0; index < list.length; index++) {
      if (!diffCurrencies.includes(list[index].currency)) {
        diffCurrencies.push(list[index].currency);
      }
    }

    let flagSubTotals = Array(diffCurrencies.length).fill(0);

    for (let index = 0; index < diffCurrencies.length; index++) {
      for (let index1 = 0; index1 < list.length; index1++) {
        if (diffCurrencies[index] === list[index1].currency) {
          flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
        }
      }
    }

    let subTotalsState = [];
    let subDiscountTotalState = [];
    let totalState = [];

    for (let index = 0; index < diffCurrencies.length; index++) {
      subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
      if (this.state.subDiscount != "") {
        let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
        subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
        totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
      } else {
        subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
        totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
      }
    }

    this.setState({
      serviceList: list.slice(),
      productId: null,
      description: "",
      quantity: "1",
      unit: "",
      price: "",
      productPrice: 0,
      currency: "",
      discount: "",
      deliverySchedule: "",
      productUpdateButtonHidden: "none",
      productAddButtonHidden: "block",
      subTotals: subTotalsState,
      subTotalDiscount: subDiscountTotalState,
      total: totalState,
      cantCalculate: false
    });
  }

  cancelProduct = () => {
    this.setState({
      productId: null,
      description: "",
      quantity: "1",
      unit: "",
      price: "",
      productPrice: 0,
      currency: "",
      discount: "",
      deliverySchedule: "",
      cantCalculate: false,
      productUpdateButtonHidden: "none",
      productAddButtonHidden: "block"
    });
  }

  deleteProductModal = (row) => {
    this.setState({
      productId: row.key,
      modalDeleteProduct: true
    });
  }

  deleteProductModalOK = () => {
    let list = this.state.serviceList;
    let flagProductId = this.state.productId;

    list.splice(list.findIndex(function (i) {
      return i.key === flagProductId;
    }), 1);

    let diffCurrencies = [];

    for (let index = 0; index < list.length; index++) {
      if (!diffCurrencies.includes(list[index].currency)) {
        diffCurrencies.push(list[index].currency);
      }
    }

    let flagSubTotals = Array(diffCurrencies.length).fill(0);

    for (let index = 0; index < diffCurrencies.length; index++) {
      for (let index1 = 0; index1 < list.length; index1++) {
        if (diffCurrencies[index] === list[index1].currency) {
          flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
        }
      }
    }

    let subTotalsState = [];
    let subDiscountTotalState = [];
    let totalState = [];

    for (let index = 0; index < diffCurrencies.length; index++) {
      subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
      if (this.state.subDiscount != "") {
        let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
        subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
        totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
      } else {
        subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
        totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
      }
    }

    this.setState({
      serviceList: list.slice(),
      productId: null,
      modalDeleteProduct: false,
      description: "",
      quantity: "1",
      unit: "",
      price: "",
      productPrice: 0,
      currency: "",
      discount: "",
      deliverySchedule: "",
      productUpdateButtonHidden: "none",
      productAddButtonHidden: "block",
      subTotals: subTotalsState,
      subTotalDiscount: subDiscountTotalState,
      total: totalState
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  editService = (row) => {
    this.setState({
      productId: row.key,
      description: row.description,
      quantity: row.quantity,
      unit: row.unit,
      price: row.price,
      currency: row.currency,
      discount: row.discount,
      deliverySchedule: row.deliverySchedule,
      productUpdateButtonHidden: "block",
      productAddButtonHidden: "none"
    });
  }

  onChangeSubTotalCheck = (e) => {
    this.setState({
      subTotalCheck: e.target.checked
    });
  }

  onChangeValidityCB = (e) => {
    this.setState({
      quoteValidityCheck: e.target.checked
    });
  }

  onChangeDiscountCheck = (e) => {
    this.setState({
      discountCheck: e.target.checked
    });
  }

  onChangeTotalCheck = (e) => {
    this.setState({
      totalCheck: e.target.checked
    });
  }

  onChangePaymentCB = (e) => {
    this.setState({
      paymentCheck: e.target.checked
    });
  }

  onChangeVATTaxCB = (e) => {
    this.setState({
      VATTaxCheck: e.target.checked
    });
  }

  applyDiscount = () => {
    let list = this.state.serviceList;
    let diffCurrencies = [];

    for (let index = 0; index < list.length; index++) {
      if (!diffCurrencies.includes(list[index].currency)) {
        diffCurrencies.push(list[index].currency);
      }
    }

    let flagSubTotals = Array(diffCurrencies.length).fill(0);

    for (let index = 0; index < diffCurrencies.length; index++) {
      for (let index1 = 0; index1 < list.length; index1++) {
        if (diffCurrencies[index] === list[index1].currency) {
          flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
        }
      }
    }

    let subTotalsState = [];
    let subDiscountTotalState = [];
    let totalState = [];

    for (let index = 0; index < diffCurrencies.length; index++) {
      subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
      if (this.state.subDiscount != "") {
        let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
        subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
        totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
      } else {
        subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
        totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
      }
    }

    this.setState({
      subTotals: subTotalsState,
      subTotalDiscount: subDiscountTotalState,
      total: totalState
    });
  }

  addNewTermCond = () => {
    _termCondRows.push(
      {
        title: this.state.termCondName,
        description: "",
        checked: false
      }
    );

    this.setState({
      termCondRows: _termCondRows,
      termCondName: ""
    });
  }

  handleChangeTermCond = (index, e) => {
    e.preventDefault();
    let list = this.state.termCondRows;
    list[index].description = e.target.value;

    this.setState({
      termCondRows: list.slice()
    });
  }

  onChangeTermCond = (index, e) => {
    let list = this.state.termCondRows;
    list[index].checked = e.target.checked;

    this.setState({
      termCondRows: list.slice()
    });
  }

  addNewNote = () => {
    _noteRows.push(
      {
        title: this.state.noteName,
        description: "",
        checked: false
      }
    );

    this.setState({
      noteRows: _noteRows,
      noteName: ""
    });
  }

  handleChangeNote = (index, e) => {
    e.preventDefault();
    let list = this.state.noteRows;
    list[index].description = e.target.value;

    this.setState({
      noteRows: list.slice()
    });
  }

  onChangeNote = (index, e) => {
    let list = this.state.noteRows;
    list[index].checked = e.target.checked;

    this.setState({
      noteRows: list.slice()
    });
  }

  showOrHideModalDeleteProduct = () => {
    this.setState({
      modalDeleteProduct: !this.state.modalDeleteProduct
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  showOrHideModalError = () => {
    this.setState({
      modalError: !this.state.modalError
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  showOrHideModalErrorNewQuote = () => {
    this.setState({
      modalErrorNewQuote: !this.state.modalErrorNewQuote
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  addNewPaymentLine = () => {
    _paymentRows.push(
      {
        paymentIndex: _paymentRows.length + 1,
        title: (_paymentRows.length + 1).toString() + ". payment",
        description: "",
        paymentValue: "",
        paymentValueType: "Select"
      }
    );

    this.setState({
      paymentRows: _paymentRows
    });
  }

  removeLastPaymentLine = () => {

    if (_paymentRows.length !== 1) {
      _paymentRows.pop();
    }

    this.setState({
      paymentRows: _paymentRows
    });
  }

  handleChangePaymentDesc = (index, e) => {
    e.preventDefault();
    let list = this.state.paymentRows;
    list[index].description = e.target.value;

    this.setState({
      paymentRows: list.slice()
    });
  }

  handleChangePaymentValue = (index, value) => {
    let list = this.state.paymentRows;
    list[index].paymentValue = value;

    this.setState({
      paymentRows: list.slice()
    });
  }

  handleChangePaymentValueType = (index, e) => {
    e.preventDefault();
    let list = this.state.paymentRows;
    list[index].paymentValueType = e.target.value;

    this.setState({
      paymentRows: list.slice()
    });
  }

  saveQuote = async () => {

    let discountValue = 0;

    if (this.state.subDiscount === null || this.state.subDiscount === "") {
      discountValue = 0;
    } else {
      discountValue = this.state.subDiscount;
    }

    let list = this.state.serviceList;
    let flagProducts = this.state.products;
    let flagUnits = this.state.unitCodes;
    let flagCurrencies = this.state.currencyCodes;
    let flagProductList = [];

    for (let index = 0; index < list.length; index++) {
      let flagProduct = flagProducts.find(product => product.description === list[index].description);
      let flagUnit = flagUnits.find(unit => unit.name === list[index].unit);
      let flagCurrency = flagCurrencies.find(currency => currency.name === list[index].currency);

      flagProductList.push(
        {
          key1: list[index].key,
          companyProductId: flagProduct.id,
          quantity: list[index].quantity,
          unit: flagUnit.id,
          price: list[index].price,
          currency: flagCurrency.id,
          discount: list[index].discount,
          deliverySchedule: list[index].deliverySchedule
        }
      )
    }

    let qvCheck = 0;

    if (this.state.quoteValidityCheck === true) { qvCheck = 1; }

    let pCheck = 0;

    if (this.state.paymentCheck === true) { pCheck = 1; }

    let vCheck = 0;

    if (this.state.VATTaxCheck === true) { vCheck = 1; }

    let stCheck = 0;

    if (this.state.subTotalCheck === true) { stCheck = 1; }

    let dCheck = 0;

    if (this.state.discountCheck === true) { dCheck = 1; }

    let tCheck = 0;

    if (this.state.totalCheck === true) { tCheck = 1; }

    let paymentList = this.state.paymentRows;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    const newSalesQuote = {
      customerId: this.state.selectedRow[0].id,
      customerProjectId: this.state.projectId,
      customerProjectLocationId: this.state.locationId,
      shipmentPrice: this.state.overallCost,
      salesQuoteProductList: flagProductList,
      discount: discountValue,
      subTotalCheck: stCheck,
      discountCheck: dCheck,
      totalCheck: tCheck,
      quoteDate: today,
      quoteValidityCheck: qvCheck,
      quoteValidityValue: this.state.quoteValidityValue,
      quoteValidityDateType: this.state.quoteValidityTimeType,
      paymentCheck: pCheck,
      salesQuotePaymentList: paymentList,
      vatCheck: vCheck,
      vatValue: this.state.VATTaxValue,
      salesQuoteNewTermList: this.state.termCondRows,
      salesQuoteNoteList: this.state.noteRows,
      quoteNo: this.state.quoteNo,
      revNo: 0,
      quotePdfPath: "",
      responseDate: "",
      needsForContract: false,
      status: "Waiting"
    }

    var a = await handleRequest("POST", "/SalesQuote/add", newSalesQuote);

    this.restartTable();
    this.resetInputs();
    this.showSuccess();
    this.showReport(a.data);
  }

  selectProject = (e) => {
    e.preventDefault();
    var list = this.state.projectList;

    var filteredList = list.filter(p => p.projectName === e.target.value);

    if (filteredList.length > 0) {
      this.setState({
        [e.target.name]: e.target.value,
        projectId: filteredList[0].id
      });
    }

    setTimeout(() => {
      this.fillLocations();
    }, 500);
  }

  fillLocations = () => {
    var projectList = this.state.projectList;
    var projectId = this.state.projectId;

    var index = projectList.findIndex(p => p.id.toString() === projectId.toString());

    console.log(projectList);

    if (index >= 0 && projectList[index].locations !== null && projectList[index].locations !== undefined && projectList[index].locations.length > 0) {

      this.setState({
        locationList: projectList[index].locations
      });

      let options = projectList[index].locations.map(function (row) {
        return { name: row.locationName.toString(), id: row.id.toString() }
      });

      this.setState({
        locations: options,
      });
    }
  }

  selectLocation = async (e) => {
    e.preventDefault();
    var list = this.state.locationList;

    var filteredList = list.filter(p => p.locationName === e.target.value);

    if (filteredList.length > 0) {
      this.setState({
        [e.target.name]: e.target.value,
        locationId: filteredList[0].id,
        locationDistance: Number(filteredList[0].locationDistance)
      });
    }

    if (filteredList.length > 0) {
      const responseEquipmentTruckCosts = await handleRequest("GET", "/EquipmentTruckCost/getAll");

      if (responseEquipmentTruckCosts.data.length === 0) {
        this.setState({
          overallCost: 0
        });
      }

      if (responseEquipmentTruckCosts.data.length !== 0) {

        let _selectedEquipments = [];

        for (let index = 0; index < responseEquipmentTruckCosts.data.length; index++) {
          _selectedEquipments.push(responseEquipmentTruckCosts.data[index].equipmentLogId);
        }

        let _selectedEquipmentTruckCosts = responseEquipmentTruckCosts.data;

        //Diesel hesaplaması için

        const response = await handleRequest("GET", "/FuelRefill/getAll");
        let fuelLogs = response.data;

        let equipmentsLastFuelUnitPrices = new Array(_selectedEquipments.length).fill(0);

        for (let index = 0; index < _selectedEquipments.length; index++) {
          for (let index1 = 0; index1 < fuelLogs.length; index1++) {
            if (_selectedEquipments[index] === fuelLogs[index1].equipmentLogId) {
              equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[index1].unitPrice);
            }
          }
        }

        for (let index = 0; index < _selectedEquipments.length; index++) {
          if (equipmentsLastFuelUnitPrices[index] === 0) {
            equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[fuelLogs.length - 1].unitPrice);
          }
        }

        let totalAverageDiesel = 0;

        for (let index = 0; index < equipmentsLastFuelUnitPrices.length; index++) {
          totalAverageDiesel += equipmentsLastFuelUnitPrices[index];
        }

        totalAverageDiesel = (totalAverageDiesel / equipmentsLastFuelUnitPrices.length);

        //Diesel hesaplaması için

        let _averageSpeed = 0;
        let _capacity = 0;
        let _capitalCost = 0;
        let _redemptionRate = 0;
        let _diesel = 0;
        let _maintenance = 0;
        let _repairs = 0;
        let _insuranceG = 0;
        let _insuranceP = 0;
        let _taxes = 0;
        let _fees = 0;
        let _salary = 0;
        let _food = 0;
        let _accom = 0;
        let _feesDriver = 0;
        let _insurance = 0;

        let _equipmentLogs = this.state.equipmentLogs;
        let foundedCapacityNumber = 0;

        for (let index = 0; index < _equipmentLogs.length; index++) {
          if (_selectedEquipments.includes(_equipmentLogs[index].id)) {
            if (_equipmentLogs[index].equipmentOperatingList !== null) {
              _capacity += Number(_equipmentLogs[index].equipmentOperatingList[0].capacity);
              foundedCapacityNumber++;
            }
          }
        }

        for (let index = 0; index < _selectedEquipmentTruckCosts.length; index++) {
          _averageSpeed += _selectedEquipmentTruckCosts[index].averageSpeed;

          //date of purchasing üzerinden 10 sene geçmişse capital cost hesaplamaya dahil edilmiyor.
          if (_selectedEquipmentTruckCosts[index].dateOfPurchasing !== null) {
            //sonradan eklendiği için null kontrolü yapıldı.
            let flagDate = moment(_selectedEquipmentTruckCosts[index].dateOfPurchasing, 'DD/MM/YYYY');
            flagDate = flagDate._d;
            flagDate.setFullYear(flagDate.getFullYear() + 10);
            let nowDate = new Date();

            if (flagDate > nowDate)
              _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
          } else {
            _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
          }

          _redemptionRate += _selectedEquipmentTruckCosts[index].redemptionRate;
          _diesel += _selectedEquipmentTruckCosts[index].diesel;
          _maintenance += _selectedEquipmentTruckCosts[index].maintenance;
          _repairs += _selectedEquipmentTruckCosts[index].repairs;
          _insuranceG += _selectedEquipmentTruckCosts[index].insuranceG;
          _insuranceP += _selectedEquipmentTruckCosts[index].insuranceP;
          _taxes += _selectedEquipmentTruckCosts[index].taxes;
          _fees += _selectedEquipmentTruckCosts[index].fees;
          _salary += _selectedEquipmentTruckCosts[index].salary;
          _food += _selectedEquipmentTruckCosts[index].food;
          _accom += _selectedEquipmentTruckCosts[index].accom;
          _feesDriver += _selectedEquipmentTruckCosts[index].feesDriver;
          _insurance += _selectedEquipmentTruckCosts[index].insurance;
        }

        if (_selectedEquipments.length !== 0) {
          _capacity = _capacity / foundedCapacityNumber;
          _averageSpeed = _averageSpeed / _selectedEquipmentTruckCosts.length;
          _capitalCost = _capitalCost / _selectedEquipmentTruckCosts.length;
          _redemptionRate = _redemptionRate / _selectedEquipmentTruckCosts.length;
          _diesel = ((_diesel / _selectedEquipmentTruckCosts.length) / 100) * (totalAverageDiesel);
          _maintenance = _maintenance / _selectedEquipmentTruckCosts.length;
          _repairs = _repairs / _selectedEquipmentTruckCosts.length;
          _insuranceG = _insuranceG / _selectedEquipmentTruckCosts.length;
          _insuranceP = _insuranceP / _selectedEquipmentTruckCosts.length;
          _taxes = _taxes / _selectedEquipmentTruckCosts.length;
          _fees = _fees / _selectedEquipmentTruckCosts.length;
          _salary = _salary / _selectedEquipmentTruckCosts.length;
          _food = _food / _selectedEquipmentTruckCosts.length;
          _accom = _accom / _selectedEquipmentTruckCosts.length;
          _feesDriver = _feesDriver / _selectedEquipmentTruckCosts.length;
          _insurance = _insurance / _selectedEquipmentTruckCosts.length;
        }

        console.log(filteredList[0].locationDistance);

        let NoT = ((Number(filteredList[0].locationDistance) * 2) / _averageSpeed) / 10;
        let capitalCostCost = _capitalCost / _redemptionRate / 300 / (parseInt(NoT) * _capacity);
        let dieselCost = _diesel * 2 * Number(filteredList[0].locationDistance) / (parseInt(NoT) * _capacity);
        let maintenanceCost = _maintenance / 300 / (parseInt(NoT) * _capacity);
        let repairsCost = _repairs / 300 / (parseInt(NoT) * _capacity);
        let insuranceGCost = _insuranceG / 300 / (parseInt(NoT) * _capacity);
        let insurancePCost = _insuranceP / 300 / (parseInt(NoT) * _capacity);
        let taxesCost = _taxes / 300 / (parseInt(NoT) * _capacity);
        let feesCost = _fees / 300 / (parseInt(NoT) * _capacity);
        let driverCost = (12 * (_salary + _food + _accom) + _feesDriver + _insurance + _salary / 3 + 13 * _salary / 12) / 300 / (parseInt(NoT) * _capacity);

        let _overallCost = capitalCostCost + dieselCost + maintenanceCost + repairsCost + insuranceGCost + insurancePCost + taxesCost + feesCost + driverCost;

        console.log(_overallCost);

        this.setState({
          overallCost: _overallCost
        });

        if (this.state.productPrice !== 0) {
          this.setState({
            price: Number.parseFloat(this.state.productPrice + this.state.overallCost).toFixed(2),
          });
        }
      }
    }
  }

  onEquipmentChange = async (e) => {

    const _selectedEquipments = e.value;

    this.setState({
      selectedEquipments: _selectedEquipments
    });

    const responseEquipmentTruckCosts = await handleRequest("GET", "/EquipmentTruckCost/getAll");

    if (responseEquipmentTruckCosts.data.length === 0) {
      this.setState({
        overallCost: 0
      });
    }

    if (responseEquipmentTruckCosts.data.length !== 0) {
      let _equipmentTruckCosts = responseEquipmentTruckCosts.data;
      let _selectedEquipmentTruckCosts = [];

      for (let index = 0; index < _equipmentTruckCosts.length; index++) {
        if (_selectedEquipments.includes(_equipmentTruckCosts[index].equipmentLogId)) {
          _selectedEquipmentTruckCosts.push(_equipmentTruckCosts[index]);
        }
      }

      //Diesel hesaplaması için

      const response = await handleRequest("GET", "/FuelRefill/getAll");
      let fuelLogs = response.data;

      let equipmentsLastFuelUnitPrices = new Array(e.value.length).fill(0);

      for (let index = 0; index < _selectedEquipments.length; index++) {
        for (let index1 = 0; index1 < fuelLogs.length; index1++) {
          if (_selectedEquipments[index] === fuelLogs[index1].equipmentLogId) {
            equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[index1].unitPrice);
          }
        }
      }

      for (let index = 0; index < _selectedEquipments.length; index++) {
        if (equipmentsLastFuelUnitPrices[index] === 0) {
          equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[fuelLogs.length - 1].unitPrice);
        }
      }

      let totalAverageDiesel = 0;

      for (let index = 0; index < equipmentsLastFuelUnitPrices.length; index++) {
        totalAverageDiesel += equipmentsLastFuelUnitPrices[index];
      }

      totalAverageDiesel = (totalAverageDiesel / equipmentsLastFuelUnitPrices.length);

      //Diesel hesaplaması için

      let _averageSpeed = 0;
      let _capacity = 0;
      let _capitalCost = 0;
      let _redemptionRate = 0;
      let _diesel = 0;
      let _maintenance = 0;
      let _repairs = 0;
      let _insuranceG = 0;
      let _insuranceP = 0;
      let _taxes = 0;
      let _fees = 0;
      let _salary = 0;
      let _food = 0;
      let _accom = 0;
      let _feesDriver = 0;
      let _insurance = 0;

      let _equipmentLogs = this.state.equipmentLogs;
      let foundedCapacityNumber = 0;

      for (let index = 0; index < _equipmentLogs.length; index++) {
        if (_selectedEquipments.includes(_equipmentLogs[index].id)) {
          if (_equipmentLogs[index].equipmentOperatingList !== null) {
            _capacity += Number(_equipmentLogs[index].equipmentOperatingList[0].capacity);
            foundedCapacityNumber++;
          }
        }
      }

      for (let index = 0; index < _selectedEquipmentTruckCosts.length; index++) {
        _averageSpeed += _selectedEquipmentTruckCosts[index].averageSpeed;

        //date of purchasing üzerinden 10 sene geçmişse capital cost hesaplamaya dahil edilmiyor.
        if (_selectedEquipmentTruckCosts[index].dateOfPurchasing !== null) {
          //sonradan eklendiği için null kontrolü yapıldı.
          let flagDate = moment(_selectedEquipmentTruckCosts[index].dateOfPurchasing, 'DD/MM/YYYY');
          flagDate = flagDate._d;
          flagDate.setFullYear(flagDate.getFullYear() + 10);
          let nowDate = new Date();

          if (flagDate > nowDate)
            _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
        } else {
          _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
        }

        _redemptionRate += _selectedEquipmentTruckCosts[index].redemptionRate;
        _diesel += _selectedEquipmentTruckCosts[index].diesel;
        _maintenance += _selectedEquipmentTruckCosts[index].maintenance;
        _repairs += _selectedEquipmentTruckCosts[index].repairs;
        _insuranceG += _selectedEquipmentTruckCosts[index].insuranceG;
        _insuranceP += _selectedEquipmentTruckCosts[index].insuranceP;
        _taxes += _selectedEquipmentTruckCosts[index].taxes;
        _fees += _selectedEquipmentTruckCosts[index].fees;
        _salary += _selectedEquipmentTruckCosts[index].salary;
        _food += _selectedEquipmentTruckCosts[index].food;
        _accom += _selectedEquipmentTruckCosts[index].accom;
        _feesDriver += _selectedEquipmentTruckCosts[index].feesDriver;
        _insurance += _selectedEquipmentTruckCosts[index].insurance;
      }

      if (_selectedEquipments.length !== 0) {
        _capacity = _capacity / foundedCapacityNumber;
        _averageSpeed = _averageSpeed / _selectedEquipmentTruckCosts.length;
        _capitalCost = _capitalCost / _selectedEquipmentTruckCosts.length;
        _redemptionRate = _redemptionRate / _selectedEquipmentTruckCosts.length;
        _diesel = ((_diesel / _selectedEquipmentTruckCosts.length) / 100) * (totalAverageDiesel);
        _maintenance = _maintenance / _selectedEquipmentTruckCosts.length;
        _repairs = _repairs / _selectedEquipmentTruckCosts.length;
        _insuranceG = _insuranceG / _selectedEquipmentTruckCosts.length;
        _insuranceP = _insuranceP / _selectedEquipmentTruckCosts.length;
        _taxes = _taxes / _selectedEquipmentTruckCosts.length;
        _fees = _fees / _selectedEquipmentTruckCosts.length;
        _salary = _salary / _selectedEquipmentTruckCosts.length;
        _food = _food / _selectedEquipmentTruckCosts.length;
        _accom = _accom / _selectedEquipmentTruckCosts.length;
        _feesDriver = _feesDriver / _selectedEquipmentTruckCosts.length;
        _insurance = _insurance / _selectedEquipmentTruckCosts.length;
      }

      let NoT = ((Number(this.state.locationDistance) * 2) / _averageSpeed) / Number(this.state.dWorkingHours);
      let capitalCostCost = _capitalCost / _redemptionRate / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let dieselCost = _diesel * 2 * Number(this.state.locationDistance) / (parseInt(NoT) * _capacity);
      let maintenanceCost = _maintenance / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let repairsCost = _repairs / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let insuranceGCost = _insuranceG / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let insurancePCost = _insuranceP / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let taxesCost = _taxes / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let feesCost = _fees / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let driverCost = (12 * (_salary + _food + _accom) + _feesDriver + _insurance + _salary / 3 + 13 * _salary / 12) / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);

      let _overallCost = capitalCostCost + dieselCost + maintenanceCost + repairsCost + insuranceGCost + insurancePCost + taxesCost + feesCost + driverCost;

      console.log(_overallCost);

      this.setState({
        overallCost: _overallCost
      });

      if (this.state.productPrice !== 0) {
        this.setState({
          price: Number.parseFloat(this.state.productPrice + this.state.overallCost).toFixed(2),
        });
      }
    }
  }

  showOrHideModalPriceDetail = () => {
    this.setState({
      modalPriceDetail: !this.state.modalPriceDetail
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  showOrHideModalShipmentPriceRecalc = () => {
    this.setState({
      modalShipmentPriceRecalc: !this.state.modalShipmentPriceRecalc
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  recalcShipmentPrice = async () => {
    const _selectedEquipments = this.state.selectedEquipments;

    const responseEquipmentTruckCosts = await handleRequest("GET", "/EquipmentTruckCost/getAll");

    if (responseEquipmentTruckCosts.data.length === 0) {
      this.setState({
        overallCost: 0
      });
    }

    if (responseEquipmentTruckCosts.data.length !== 0) {
      let _equipmentTruckCosts = responseEquipmentTruckCosts.data;
      let _selectedEquipmentTruckCosts = [];

      for (let index = 0; index < _equipmentTruckCosts.length; index++) {
        if (_selectedEquipments.includes(_equipmentTruckCosts[index].equipmentLogId)) {
          _selectedEquipmentTruckCosts.push(_equipmentTruckCosts[index]);
        }
      }

      //Diesel hesaplaması için

      const response = await handleRequest("GET", "/FuelRefill/getAll");
      let fuelLogs = response.data;

      let equipmentsLastFuelUnitPrices = new Array(_selectedEquipments.length).fill(0);

      for (let index = 0; index < _selectedEquipments.length; index++) {
        for (let index1 = 0; index1 < fuelLogs.length; index1++) {
          if (_selectedEquipments[index] === fuelLogs[index1].equipmentLogId) {
            equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[index1].unitPrice);
          }
        }
      }

      for (let index = 0; index < _selectedEquipments.length; index++) {
        if (equipmentsLastFuelUnitPrices[index] === 0) {
          equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[fuelLogs.length - 1].unitPrice);
        }
      }

      let totalAverageDiesel = 0;

      for (let index = 0; index < equipmentsLastFuelUnitPrices.length; index++) {
        totalAverageDiesel += equipmentsLastFuelUnitPrices[index];
      }

      totalAverageDiesel = (totalAverageDiesel / equipmentsLastFuelUnitPrices.length);

      //Diesel hesaplaması için

      let _averageSpeed = 0;
      let _capacity = 0;
      let _capitalCost = 0;
      let _redemptionRate = 0;
      let _diesel = 0;
      let _maintenance = 0;
      let _repairs = 0;
      let _insuranceG = 0;
      let _insuranceP = 0;
      let _taxes = 0;
      let _fees = 0;
      let _salary = 0;
      let _food = 0;
      let _accom = 0;
      let _feesDriver = 0;
      let _insurance = 0;

      let _equipmentLogs = this.state.equipmentLogs;
      let foundedCapacityNumber = 0;

      for (let index = 0; index < _equipmentLogs.length; index++) {
        if (_selectedEquipments.includes(_equipmentLogs[index].id)) {
          if (_equipmentLogs[index].equipmentOperatingList !== null) {
            _capacity += Number(_equipmentLogs[index].equipmentOperatingList[0].capacity);
            foundedCapacityNumber++;
          }
        }
      }

      for (let index = 0; index < _selectedEquipmentTruckCosts.length; index++) {
        _averageSpeed += _selectedEquipmentTruckCosts[index].averageSpeed;

        //date of purchasing üzerinden 10 sene geçmişse capital cost hesaplamaya dahil edilmiyor.
        if (_selectedEquipmentTruckCosts[index].dateOfPurchasing !== null) {
          //sonradan eklendiği için null kontrolü yapıldı.
          let flagDate = moment(_selectedEquipmentTruckCosts[index].dateOfPurchasing, 'DD/MM/YYYY');
          flagDate = flagDate._d;
          flagDate.setFullYear(flagDate.getFullYear() + 10);
          let nowDate = new Date();

          if (flagDate > nowDate)
            _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
        } else {
          _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
        }

        _redemptionRate += _selectedEquipmentTruckCosts[index].redemptionRate;
        _diesel += _selectedEquipmentTruckCosts[index].diesel;
        _maintenance += _selectedEquipmentTruckCosts[index].maintenance;
        _repairs += _selectedEquipmentTruckCosts[index].repairs;
        _insuranceG += _selectedEquipmentTruckCosts[index].insuranceG;
        _insuranceP += _selectedEquipmentTruckCosts[index].insuranceP;
        _taxes += _selectedEquipmentTruckCosts[index].taxes;
        _fees += _selectedEquipmentTruckCosts[index].fees;
        _salary += _selectedEquipmentTruckCosts[index].salary;
        _food += _selectedEquipmentTruckCosts[index].food;
        _accom += _selectedEquipmentTruckCosts[index].accom;
        _feesDriver += _selectedEquipmentTruckCosts[index].feesDriver;
        _insurance += _selectedEquipmentTruckCosts[index].insurance;
      }

      if (_selectedEquipments.length !== 0) {
        _averageSpeed = _averageSpeed / _selectedEquipmentTruckCosts.length;
        _capacity = _capacity / foundedCapacityNumber;
        _capitalCost = _capitalCost / _selectedEquipmentTruckCosts.length;
        _redemptionRate = _redemptionRate / _selectedEquipmentTruckCosts.length;
        _diesel = ((_diesel / _selectedEquipmentTruckCosts.length) / 100) * (totalAverageDiesel);
        _maintenance = _maintenance / _selectedEquipmentTruckCosts.length;
        _repairs = _repairs / _selectedEquipmentTruckCosts.length;
        _insuranceG = _insuranceG / _selectedEquipmentTruckCosts.length;
        _insuranceP = _insuranceP / _selectedEquipmentTruckCosts.length;
        _taxes = _taxes / _selectedEquipmentTruckCosts.length;
        _fees = _fees / _selectedEquipmentTruckCosts.length;
        _salary = _salary / _selectedEquipmentTruckCosts.length;
        _food = _food / _selectedEquipmentTruckCosts.length;
        _accom = _accom / _selectedEquipmentTruckCosts.length;
        _feesDriver = _feesDriver / _selectedEquipmentTruckCosts.length;
        _insurance = _insurance / _selectedEquipmentTruckCosts.length;
      }

      let NoT = ((Number(this.state.locationDistance) * 2) / _averageSpeed) / Number(this.state.dWorkingHours);
      let capitalCostCost = _capitalCost / _redemptionRate / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let dieselCost = _diesel * 2 * Number(this.state.locationDistance) / (parseInt(NoT) * _capacity);
      let maintenanceCost = _maintenance / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let repairsCost = _repairs / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let insuranceGCost = _insuranceG / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let insurancePCost = _insuranceP / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let taxesCost = _taxes / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let feesCost = _fees / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);
      let driverCost = (12 * (_salary + _food + _accom) + _feesDriver + _insurance + _salary / 3 + 13 * _salary / 12) / Number(this.state.yWorkingDays) / (parseInt(NoT) * _capacity);

      let _overallCost = capitalCostCost + dieselCost + maintenanceCost + repairsCost + insuranceGCost + insurancePCost + taxesCost + feesCost + driverCost;

      console.log(_overallCost);

      this.setState({
        overallCost: _overallCost
      });

      if (this.state.productPrice !== 0) {
        this.setState({
          price: Number.parseFloat(this.state.productPrice + this.state.overallCost).toFixed(2),
        });
      }
    }
  }

  // selectedTruckTemplate = (option) => {
  //   if (this.state.selectedEquipments.length === this.state.equipmentLogs.length) {
  //     if (a === 0) {
  //       a = 1;
  //       return (
  //         <span>All Trucks</span>
  //       );
  //     }
  //     else return "";
  //   }
  //   else if (option) {
  //     a = 1;
  //     return (
  //         <span>{option.label}</span>
  //     );
  //   } else {
  //     a = 1;
  //     return <span>Choose</span>
  //   }
  // }

  render() {

    let optionsProjects = this.state.projectList.map((data) =>
      <option
        key={data.id}
        value={data.projectName}
      >
        {data.projectName}
      </option>
    );

    let optionsLocations = this.state.locationList.map((data) =>
      <option
        key={data.id}
        value={data.locationName}
      >
        {data.locationName}
      </option>
    );

    let optionsProducts = this.state.products.map((data) =>
      <option
        key={data.id}
        value={data.description}
      >
        {data.code} - {data.description}
      </option>
    );

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {

        this.setState({
          selectedRow: selectedRows,
          projectList: selectedRows[0].projects
        });

        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    };

    return (
      <div>

        <Modal
          show={this.state.modalErrorNewQuote}
          onHide={this.showOrHideModalErrorNewQuote}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Select a Customer!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please, be sure to select a customer.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalErrorNewQuote}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalDeleteProduct}
          onHide={this.showOrHideModalDeleteProduct}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Delete Product Record?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDeleteProduct}>Close</Button>
            <Button variant="danger" onClick={this.deleteProductModalOK} >Delete</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalError}
          onHide={this.showOrHideModalError}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Missing Values!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please, be sure to enter all values.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalError}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalPriceDetail}
          onHide={this.showOrHideModalPriceDetail}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Price Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={6}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Plant Price:</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label style={{ marginTop: '0.5rem' }}>{this.state.productPrice} SR</Form.Label>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={6}>
                <Form.Label onClick={this.showOrHideModalShipmentPriceRecalc} style={{ cursor: 'pointer', color: 'black', textDecoration: "underline", marginTop: '0.5rem', fontWeight: 'bold' }}>Shipment Price:</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Label style={{ marginTop: '0.5rem' }}>{Number.parseFloat(this.state.overallCost).toFixed(4)} SR</Form.Label>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPriceDetail}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShipmentPriceRecalc}
          onHide={this.showOrHideModalShipmentPriceRecalc}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Shipment Price Recalculation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={3}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Equipments:</Form.Label>
              </Col>
              <Col xs={6}>
                <MultiSelect value={this.state.selectedEquipments} options={this.state.equipmentOptions} onChange={this.onEquipmentChange}
                  style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" selectedItemTemplate={this.selectedTruckTemplate} placeholder="Choose" maxSelectedLabels="2" />
              </Col>
              <Col xs={2}></Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={3}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Yearly Working Days:</Form.Label>
              </Col>
              <Col xs={6}>
                <InputNumber style={{ width: '60%' }} min={0} value={this.state.yWorkingDays}
                  onChange={(value) => {
                    this.setState({
                      yWorkingDays: value
                    });
                  }} />
              </Col>
              <Col xs={2}></Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={3}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Monthly Working Days:</Form.Label>
              </Col>
              <Col xs={6}>
                <InputNumber style={{ width: '60%' }} min={0} value={this.state.mWorkingDays} onChange={(value) => {
                  this.setState({
                    mWorkingDays: value
                  });
                }} />
              </Col>
              <Col xs={2}></Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={3}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Weekly Working Days:</Form.Label>
              </Col>
              <Col xs={6}>
                <InputNumber style={{ width: '60%' }} min={0} value={this.state.wWorkingDays} onChange={(value) => {
                  this.setState({
                    wWorkingDays: value
                  });
                }} />
              </Col>
              <Col xs={2}></Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={3}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Daily Working Hours:</Form.Label>
              </Col>
              <Col xs={6}>
                <InputNumber style={{ width: '60%' }} min={0} value={this.state.dWorkingHours} onChange={(value) => {
                  this.setState({
                    dWorkingHours: value
                  });
                }} />
              </Col>
              <Col xs={2}></Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={8}>
                <Button style={{ width: '100%' }} onClick={this.recalcShipmentPrice} variant="success">Calculate</Button>
              </Col>
              <Col xs={3}></Col>
            </Row>
            <Row style={{ marginTop: "10px", width: "100%" }}>
              <Col xs={1}></Col>
              <Col xs={3}>
                <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>New Shipment Price:</Form.Label>
              </Col>
              <Col xs={6}>
                <InputNumber style={{ width: '60%' }} disabled min={0} value={this.state.overallCost} />
              </Col>
              <Col xs={2}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalShipmentPriceRecalc}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
               </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
              <FacebookShareButton url={this.state.pdfFile} quote="Sales Quote PDF File Link">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Sales Quote PDF File Link is here: " subject="Sales Quote PDF File Link">
                <EmailIcon size={32} round />
              </EmailShareButton>
              <WhatsappShareButton
                style={{ marginLeft: "1rem" }}
                url={this.state.pdfFile}
                title="Sales Quote PDF File Link"
                separator=": "
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} />
              </div>
            </Worker>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
               </Button>
          </Modal.Footer>
        </Modal>

        <div style={{ padding: "4px 16px 4px 16px" }}>
          <Row>
            <Col xs={3}><Toast ref={(el) => this.toast = el} /></Col>
            <Col xs={3}></Col>
            <Col xs={3}><Button onClick={this.createNewCustomer} style={{ width: '100%' }} variant="info" >Create New Customer</Button></Col>
            <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
          </Row>
        </div>

        {this.state.selectedRow !== null && <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
          <Card>
            <Card.Body>
              <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                <Row>
                  <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Quote No:</Form.Label></Col>
                  <Col xs={4}><Form.Control name="quoteNo" value={this.state.quoteNo} onChange={this.handleChange} /></Col>
                </Row>
                <br />
                <Accordion defaultActiveKey="1">
                  <Card>
                    <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                      <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Customer Info</Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                      <Card.Body>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Company's Full Name:
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.selectedRow[0].fullName}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Company's Short Name:
                                      </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.selectedRow[0].shortName}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Company Logo:
                                      </Form.Label>
                          <Col sm="2">
                            {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div>No Image</div>}
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ fontWeight: 'bold', color: 'black' }} column sm="3">
                            Head Office
                                  </Form.Label>
                          <Col sm="4">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                            Address:
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.address}
                          </Form.Label>
                        </Form.Group>


                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                            Country:
                                      </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.country}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                            Phone:
                                      </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.phone}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                            Fax:
                                      </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.fax}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                            Web:
                                      </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.web}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ textAlign: 'right', color: 'black' }} column sm="3">
                            E-Mail:
                                      </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.email}
                          </Form.Label>
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                  <Card>
                    <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                      <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Customer Representative</Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                      <Card.Body>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Name
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.name}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Midname
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.midname}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Surname
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.surname}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Position
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.position}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Phone
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.rPhone}
                          </Form.Label>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Email
                                  </Form.Label>
                          <Form.Label style={{ color: 'black' }} column sm="4">
                            {this.state.rEmail}
                          </Form.Label>
                        </Form.Group>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                  <Card>
                    <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                      <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Project, Products and Price</Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                      <Card.Body>
                        <Form>
                          <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                            <Form.Label style={{ color: 'black' }} column sm="3">
                              Project:
                            </Form.Label>
                            <Col sm="4">
                              <Form.Control name="project" value={this.state.project}
                                onChange={this.selectProject} as="select">
                                <option value="">
                                  Select Project
                                </option>
                                {optionsProjects}
                              </Form.Control>
                            </Col>
                            <Col sm="5">
                            </Col>
                          </Form.Group>
                          <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                            <Form.Label style={{ color: 'black' }} column sm="3">
                              Location:
                            </Form.Label>
                            <Col sm="4">
                              <Form.Control name="location" value={this.state.location}
                                onChange={this.selectLocation} as="select">
                                <option value="">
                                  Select Location
                                </option>
                                {optionsLocations}
                              </Form.Control>
                            </Col>
                            <Col sm="5">
                            </Col>
                          </Form.Group>
                        </Form>

                        {this.state.cantCalculate && <div style={{ color: 'red', marginBottom: '1rem' }}> Shipment price can't calculate for this product. </div>}

                        <RowAnt>
                          <Space size="large" >
                            <Space direction="vertical">
                              <div>Product</div>
                              <Form.Control name="description" value={this.state.description}
                                onChange={this.selectProductDetails} as="select">
                                <option value="">
                                  Select Product
                                              </option>
                                {optionsProducts}
                              </Form.Control>
                            </Space>
                            <Space direction="vertical">
                              <div>Quantity </div>
                              <InputNumber min={1} value={this.state.quantity} onChange={(value) => { this.setState({ quantity: value }) }} />
                            </Space>

                            <Space direction="vertical">
                              <div></div>
                              <Form.Label style={{ color: 'black', marginTop: '1rem' }} >
                                {this.state.unit}
                              </Form.Label>
                            </Space>

                            <Space direction="vertical">
                              <div onClick={this.showOrHideModalPriceDetail} style={{ cursor: 'pointer', textDecoration: "underline", fontWeight: "bold" }}>Price</div>
                              <InputNumber min={1} value={this.state.price} onChange={(value) => { this.setState({ price: value }) }} />
                            </Space>

                            <Space direction="vertical">
                              <div></div>
                              <Form.Label style={{ color: 'black', marginTop: '1rem' }} >
                                {this.state.currency}
                              </Form.Label>
                            </Space>

                            <Space direction="vertical">
                              <div>Discount</div>
                              <InputNumber min={0} formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                value={this.state.discount} onChange={(value) => { this.setState({ discount: value }) }} />
                            </Space>

                            <Space direction="vertical">
                              <div>Delivery Schedule</div>
                              <Form.Control name="deliverySchedule" value={this.state.deliverySchedule}
                                onChange={this.handleChange} />
                            </Space>

                            <Space style={{ display: this.state.productAddButtonHidden }} direction="vertical">
                              <div></div>
                              <Button style={{ width: '100%', marginTop: '1rem' }} onClick={this.addProduct} type="button">Add</Button>
                            </Space>

                            <Space style={{ display: this.state.productUpdateButtonHidden }} direction="vertical">
                              <div></div>
                              <Button style={{ width: '100%', marginTop: '1rem' }} variant="warning" onClick={this.updateProduct} type="button">Update</Button>
                            </Space>

                            <Space style={{ display: this.state.productUpdateButtonHidden }} direction="vertical">
                              <div></div>
                              <Button style={{ width: '100%', marginTop: '1rem' }} variant="secondary" onClick={this.cancelProduct} type="button">Cancel</Button>
                            </Space>
                          </Space>
                        </RowAnt>
                        {this.state.serviceList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.serviceList} bordered >
                          <Column
                            width='50px'
                            title="Action"
                            key="action"
                            render={(text, record) => (
                              <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteProductModal.bind(this)} onEditRow={this.editService.bind(this)} />   </React.Fragment>
                            )}
                          />
                          {/*<Column title="S/N" key="sn" dataIndex="key" />*/}
                          <Column title="Product" key="description" dataIndex="description" />
                          <Column title="Quantity" key="quantity" dataIndex="quantity" />
                          <Column title="Unit" key="unit" dataIndex="unit" />
                          <Column title="Price" key="price" dataIndex="price" />
                          <Column title="Discount (%)" key="discount" dataIndex="discount" />
                          <Column title="Delivery Schedule" key="deliverySchedule" dataIndex="deliverySchedule" />
                        </Table>}

                        <Form>

                          <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="5">
                            </Col>
                            <Col sm="2">
                              <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeSubTotalCheck}
                                checked={this.state.subTotalCheck}>Sub Total</Checkbox>
                            </Col>
                            {this.state.subTotals.length > 0 &&
                              <Form.Label style={{ color: 'black' }} column sm="2">
                                {this.state.subTotals[0].total} {this.state.subTotals[0].currency}
                              </Form.Label>
                            }
                            {this.state.subTotals.length === 0 &&
                              <Form.Label style={{ color: 'black' }} column sm="2">
                                N/A Total / Unit
                                              </Form.Label>
                            }
                            <Col sm="3">
                            </Col>
                          </Form.Group>

                          {this.state.subTotals.map((item, index) => (
                            (item.index !== 1) && (
                              <Form.Group key={index} as={Row}>
                                <Col sm="7">
                                </Col>
                                <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                  {this.state.subTotals[item.index - 1].total} {this.state.subTotals[item.index - 1].currency}
                                </Form.Label>
                                <Col sm="3">
                                </Col>
                              </Form.Group>
                            )
                          ))}

                          <Form.Group as={Row}>
                            <Col sm="2">
                            </Col>
                            <Col sm="1" style={{ textAlign: 'right' }}>
                              <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.subDiscount}
                                formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                onChange={(value) => { this.setState({ subDiscount: value }) }} />
                            </Col>
                            <Col sm="2">
                              <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.applyDiscount} type="button">Apply Discount</Button>
                            </Col>
                            <Col sm="2">
                              <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeDiscountCheck}
                                checked={this.state.discountCheck}>Discount</Checkbox>
                            </Col>
                            {this.state.subTotalDiscount.length > 0 &&
                              <Form.Label style={{ color: 'black' }} column sm="2">
                                {this.state.subTotalDiscount[0].total} {this.state.subTotalDiscount[0].currency}
                              </Form.Label>
                            }
                            {this.state.subTotalDiscount.length === 0 &&
                              <Form.Label style={{ color: 'black' }} column sm="2">
                                N/A Total / Unit
                                          </Form.Label>
                            }
                            <Col sm="3">
                            </Col>
                          </Form.Group>

                          {this.state.subTotalDiscount.map((item, index) => (
                            (item.index !== 1) && (
                              <Form.Group key={index} as={Row}>
                                <Col sm="7">
                                </Col>
                                <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                  {this.state.subTotalDiscount[item.index - 1].total} {this.state.subTotalDiscount[item.index - 1].currency}
                                </Form.Label>
                                <Col sm="3">
                                </Col>
                              </Form.Group>
                            )
                          ))}

                          <Form.Group as={Row}>
                            <Col sm="5">
                            </Col>
                            <Col sm="2">
                              <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeTotalCheck}
                                checked={this.state.totalCheck}>Total</Checkbox>
                            </Col>
                            {this.state.total.length > 0 &&
                              <Form.Label style={{ color: 'black' }} column sm="2">
                                {this.state.total[0].total} {this.state.total[0].currency}
                              </Form.Label>
                            }
                            {this.state.total.length === 0 &&
                              <Form.Label style={{ color: 'black' }} column sm="2">
                                N/A Total / Unit
                                              </Form.Label>
                            }
                            <Col sm="3">
                            </Col>
                          </Form.Group>

                          {this.state.total.map((item, index) => (
                            (item.index !== 1) && (
                              <Form.Group key={index} as={Row}>
                                <Col sm="7">
                                </Col>
                                <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                  {this.state.total[item.index - 1].total} {this.state.total[item.index - 1].currency}
                                </Form.Label>
                                <Col sm="3">
                                </Col>
                              </Form.Group>
                            )
                          ))}
                        </Form>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                  <Card>
                    <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                      <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Terms and Conditions</Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                      <Card.Body>
                        <Form>
                          <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                            <Form.Label style={{ color: 'black' }} column sm="3">
                              New Terms/Conditions Name:
                                      </Form.Label>
                            <Col sm="4">
                              <Form.Control name="termCondName" value={this.state.termCondName} onChange={this.handleChange} />
                            </Col>
                            <Col sm="3">
                              <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewTermCond} type="button">Add</Button>
                            </Col>
                            <Col sm="2">
                            </Col>
                          </Form.Group>

                          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="2">
                              <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeValidityCB} checked={this.state.quoteValidityCheck}>Quote Validty</Checkbox>
                            </Col>
                            <Col sm="2" style={{ textAlign: 'right' }}>
                              <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.quoteValidityValue}
                                onChange={(value) => { this.setState({ quoteValidityValue: value }) }} />
                            </Col>
                            <Col sm="2">
                              <Form.Control name="quoteValidityTimeType" value={this.state.quoteValidityTimeType}
                                onChange={this.handleChange} as="select">
                                <option>Select</option>
                                <option>Day</option>
                                <option>Week</option>
                                <option>Month</option>
                                <option>Year</option>
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="2">
                              <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangePaymentCB} checked={this.state.paymentCheck}>Payment Terms</Checkbox>
                            </Col>
                            <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                              {this.state.paymentRows[0].title}
                            </Form.Label>
                            <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                              Description:
                                                        </Form.Label>
                            <Col sm="2">
                              <Form.Control name={this.state.paymentRows[0].title} value={this.state.paymentRows[0].description}
                                onChange={(e) => this.handleChangePaymentDesc(0, e)} />
                            </Col>
                            <Col sm="1" style={{ textAlign: 'right' }}>
                              <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.paymentRows[0].paymentValue}
                                onChange={(value) => this.handleChangePaymentValue(0, value)} />
                            </Col>
                            <Col sm="2">
                              <Form.Control name={this.state.paymentRows[0].title} value={this.state.paymentRows[0].paymentValueType}
                                onChange={(e) => this.handleChangePaymentValueType(0, e)} as="select">
                                <option>Select</option>
                                <option>Currency</option>
                                <option>%</option>
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          {this.state.paymentRows.map((item, index) => (
                            (index !== 0) && (
                              <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                  {item.title}
                                </Form.Label>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                  Description:
                                    </Form.Label>
                                <Col sm="2">
                                  <Form.Control name={item.title} value={item.description}
                                    onChange={(e) => this.handleChangePaymentDesc(index, e)} />
                                </Col>
                                <Col sm="1" style={{ textAlign: 'right' }}>
                                  <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.paymentRows[index].paymentValue}
                                    onChange={(value) => this.handleChangePaymentValue(index, value)} />
                                </Col>
                                <Col sm="2">
                                  <Form.Control name={item.title} value={item.paymentValueType}
                                    onChange={(e) => this.handleChangePaymentValueType(index, e)} as="select">
                                    <option>Select</option>
                                    <option>Currency</option>
                                    <option>%</option>
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                            )
                          ))}

                          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="4">
                            </Col>
                            <Col sm="2">
                              <AntButton
                                type="dashed"
                                onClick={this.removeLastPaymentLine}
                                style={{ width: '100%' }}
                              >
                                <MinusOutlined /> Remove A Line
                                          </AntButton>
                            </Col>
                            <Col sm="2">
                              <AntButton
                                type="dashed"
                                onClick={this.addNewPaymentLine}
                                style={{ width: '100%' }}
                              >
                                <PlusOutlined /> Add New Line
                                          </AntButton>
                            </Col>
                          </Form.Group>

                          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="2">
                              <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeVATTaxCB} checked={this.state.VATTaxCheck}>VAT Tax</Checkbox>
                            </Col>
                            <Col sm="2" style={{ textAlign: 'right' }}>
                              <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.VATTaxValue}
                                formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                onChange={(value) => { this.setState({ VATTaxValue: value }) }} />
                            </Col>
                            <Col sm="2">
                            </Col>
                          </Form.Group>

                          {this.state.termCondRows.map((item, index) => (
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                              <Col sm="3">
                                <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                  onChange={(e) => this.onChangeTermCond(index, e)}
                                  checked={item.checked}>{item.title}</Checkbox>
                              </Col>
                              <Col sm="4">
                                <Form.Control name={item.title} value={item.description}
                                  onChange={(e) => this.handleChangeTermCond(index, e)} />
                              </Col>
                            </Form.Group>
                          ))}

                        </Form>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <br />
                <Accordion defaultActiveKey="1">
                  <Card>
                    <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                      <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Notes</Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                      <Card.Body>
                        <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            New Note Name:
                                  </Form.Label>
                          <Col sm="4">
                            <Form.Control name="noteName" value={this.state.noteName} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                            <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewNote} type="button">Add</Button>
                          </Col>
                          <Col sm="2">
                          </Col>
                        </Form.Group>

                        {this.state.noteRows.map((item, index) => (
                          <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="3">
                              <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                onChange={(e) => this.onChangeNote(index, e)}
                                checked={item.checked}>{item.title}</Checkbox>
                            </Col>
                            <Col sm="4">
                              <Form.Control name={item.title} value={item.description}
                                onChange={(e) => this.handleChangeNote(index, e)} />
                            </Col>
                          </Form.Group>
                        ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                  <Col sm="3">
                  </Col>
                  <Col sm="6">
                    <Button type="button" style={{ width: '100%' }} onClick={this.saveQuote} variant="success">Save</Button>
                  </Col>
                  <Col sm="3">
                  </Col>
                </Form.Group>

              </Container>
            </Card.Body>
          </Card>
        </div>
        }

        {this.state.customerList.length > 0 &&
          <Table style={{ marginTop: '3rem' }} size={`small`}
            dataSource={this.state.customerList} bordered
            scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            expandable={{
              expandedRowRender: record => <SalesQuoteSub row={record} />
            }}
          >
            {/*<Column
            title="Action"
            key="action"
            render={(text, record) => (
              //sonra lazım olacak, alttak quote kayıtları için.  BU MENÜYE GEREK VAR MI?
              //<React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} /></React.Fragment>
              <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onEditRow={this.edit.bind(this)} />   </React.Fragment>
            )}
            />*/}
            <Column title="S/N" key="sn" dataIndex="index" />
            <Column title="Logo" key="logo" render={(value, row, index) => {
              if (row.logoPath != null && row.logoPath !== undefined)
                return <Avatar shape="square" size={64} src={`${API_BASE}/CustomerLog/download/` + row.id} />;
              else return "";
            }} />
            <Column title="Customer Code" key="id" dataIndex="id" />
            <Column title="Number Of Project" key="nop" render={(record) => (this.numberOfProjects2(record))} />
            <Column title="Company's Full Name" key="cfn" dataIndex="fullName" />
            <Column title="Company's short Name" key="csn" dataIndex="shortName" />
            <ColumnGroup title="Head Office" key="ho" >
              <Column width='30%' title="Address" key="address" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.address;
                return '';
              }} />
              <Column title="Country" key="country" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.country;
                return '';
              }} />
              <Column title="Phones" key="phones" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.phones;
                return '';
              }} />
              <Column title="Faxes" key="faxes" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.faxes;
                return '';
              }} />
              <Column title="Web" key="web" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.web;
                return '';
              }} />
              <Column title="Emails" key="emails" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.mails;
                return '';
              }} />
            </ColumnGroup>
            <ColumnGroup title="Company Representative" key="cr" >
              <Column title="Name" key="name" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.name;
                return '';
              }} />

              <Column title="MidName" key="midname" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.midname;
                return '';
              }} />

              <Column title="Surname" key="surname" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.lastname;
                return '';
              }} />
              <Column title="Position" key="position" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.position;
                return '';
              }} />
              <Column title="Phone" key="rphone" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.phone;
                return '';
              }} />
              <Column title="Email" key="remail" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.email;
                return '';
              }} />
            </ColumnGroup>

          </Table>}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SalesQuote)
