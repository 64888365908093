/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';

class HowtoUse extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            howToUseThis:'' ,
            modalHowToUse: false,
        }
    }

    componentDidMount() {
        
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.props.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }
        

    render() {

        const howToUseThis = this.state.howToUseThis;

        return (
            <div>
                  <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {
  
    }
  }
  
  const mapDispatchToProps = {
  
  } 
  
  export default connect(mapStateToProps, mapDispatchToProps)(HowtoUse) 
