import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Calendar from 'rc-year-calendar';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Switch } from 'antd';
import moment from 'moment'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const currentYear = new Date().getFullYear();
const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

export class HolidayLog extends Component {

    constructor() {
        super();
        this.state = {
            
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            dataSource: [
                {
                    id: 0,
                    name: 'Google I/O',
                    location: 'San Francisco, CA',
                    startDate: new Date(currentYear, 4, 28),
                    endDate: new Date(currentYear, 4, 29)
                },
                {
                    id: 1,
                    name: 'Microsoft Convergence',
                    location: 'New Orleans, LA',
                    startDate: new Date(currentYear, 2, 16),
                    endDate: new Date(currentYear, 2, 19)
                    //test
                },
                {
                    id: 2,
                    name: 'Microsoft Build Developer Conference',
                    location: 'San Francisco, CA',
                    startDate: new Date(currentYear, 3, 29),
                    endDate: new Date(currentYear, 4, 1)
                },
                {
                    id: 3,
                    name: 'Apple Special Event',
                    location: 'San Francisco, CA',
                    startDate: new Date(currentYear, 8, 1),
                    endDate: new Date(currentYear, 8, 1)
                },
                {
                    id: 4,
                    name: 'Apple Keynote',
                    location: 'San Francisco, CA',
                    startDate: new Date(currentYear, 8, 9),
                    endDate: new Date(currentYear, 8, 9)
                },
                {
                    id: 5,
                    name: 'Chrome Developer Summit',
                    location: 'Mountain View, CA',
                    startDate: new Date(currentYear, 10, 17),
                    endDate: new Date(currentYear, 10, 18)
                },
                {
                    id: 6,
                    name: 'F8 2015',
                    location: 'San Francisco, CA',
                    startDate: new Date(currentYear, 2, 25),
                    endDate: new Date(currentYear, 2, 26)
                },
                {
                    id: 7,
                    name: 'Yahoo Mobile Developer Conference',
                    location: 'New York',
                    startDate: new Date(currentYear, 7, 25),
                    endDate: new Date(currentYear, 7, 26)
                },
                {
                    id: 8,
                    name: 'Android Developer Conference',
                    location: 'Santa Clara, CA',
                    startDate: new Date(currentYear, 11, 1),
                    endDate: new Date(currentYear, 11, 4)
                },
                {
                    id: 9,
                    name: 'LA Tech Summit',
                    location: 'Los Angeles, CA',
                    startDate: new Date(currentYear, 10, 17),
                    endDate: new Date(currentYear, 10, 17)
                }
            ],
            holidayLogId: 0,
            currentEvent: null,
            fiscalYear: null,
            moments: [],
            dateStrings: [],
            codeName: '',
            holidayDescriptionId: null,
            holidayDescriptionSelectItems: [],
            fixed: true,
            offDay: true,
            makeCelebration: false,
            messageToId: null,
            messagesToSelectItems: [{ id: 1, name: "All" }, { id: 2, name: "Nationality" }],
            applicableDepartmentId: null,
            departmentSelectItems: [],
            requiredCrewId: null,
            requiredCrewSelectItems: [],
            sendNotice: false,
            remindDaysAgo: 0,
            departments: [],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.setComboboxes();
        this.fillCodes();
        this.restartTable();


    }

    save = async () => {



        const obj = {
            id: this.state.holidayLogId,
            fiscalYear: this.state.fiscalYear,
            startDate: this.state.moments[0].toDate(),
            endDate: this.state.moments[1].toDate(),
            holidayDescriptionId: this.state.holidayDescriptionId,
            fixed: this.state.fixed,
            offDay: this.state.offDay,
            makeCelebration: this.state.makeCelebration,
            messageToId: this.state.messageToId,
            applicableDepartmentId: this.state.applicableDepartmentId,
            requiredCrewId: this.state.requiredCrewId,
            sendNotice: this.state.sendNotice,
            remindDaysAgo: this.state.remindDaysAgo,
            departments: this.state.departments,
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/HolidayLog/add", obj);
        else
            await handleRequest("POST", "/HolidayLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        if (!row.fiscalYear)
            row = this.state.holidayLogList.find(p => p.id === row.id);

        this.setState({
            holidayLogId: row.id,
            fiscalYear: row.fiscalYear,
            moments: [moment(new Date(row.startDate)), moment(new Date(row.endDate))],
            currentEvent: { startDate: row.startDate, endDate: row.endDate },
            holidayDescriptionId: row.holidayDescriptionId,
            fixed: row.fixed,
            offDay: row.offDay,
            makeCelebration: row.makeCelebration,
            messageToId: row.messageToId,
            applicableDepartmentId: row.applicableDepartmentId,
            requiredCrewId: row.requiredCrewId,
            sendNotice: row.sendNotice,
            remindDaysAgo: row.remindDaysAgo,
            departments: row.dapartments,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.holidayLogId
        }

        await handleRequest("POST", "/HolidayLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            holidayLogId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    hideHolidayModal = () => {
        this.resetInputs();

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {

        const resDepList = await handleRequest("GET", "/HolidayLog/getAllByCompany");
        if (resDepList.data.length > 0) {
            const list = resDepList.data;
            const ds = [];

            list.forEach(element => {
                let obj = { id: element.id, name: Boolean(element.holidayDescription) ? element.holidayDescription.name : '', startDate: new Date(element.startDate), endDate: new Date(element.endDate) }
                ds.push(obj);
            });

            this.setState({
                holidayLogList: list,
                dataSource: ds
            });
        } else {
            this.setState({
                holidayLogList: []
            });
        }
    }


    setComboboxes = async () => {


        const response = await handleRequest("GET", "/Department/getallByCompany");
        if (response.data.length > 0) {
            const list = response.data;

            this.setState({
                departmentSelectItems: list
            });
        } else {
            this.setState({
                departmentSelectItems: []
            });
        }


    }
    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ArchivingVolume" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "ArchivingVolume"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ArchivingVolume");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            holidayLogId: 0,
            currentEvent: null,
            fiscalYear: null,
            moments: [],
            dateStrings: [],
            codeName: '',
            holidayDescriptionId: null,
            fixed: true,
            offDay: true,
            makeCelebration: false,
            messageToId: null,
            applicableDepartmentId: null,
            requiredCrewId: null,
            sendNotice: false,
            remindDaysAgo: 0,
            departments: [],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            holidayLogId: 0,
            currentEvent: null,
            fiscalYear: null,
            moments: [],
            dateStrings: [],
            codeName: '',
            holidayDescriptionId: null,
            fixed: true,
            offDay: true,
            makeCelebration: false,
            messageToId: null,
            applicableDepartmentId: null,
            requiredCrewId: null,
            sendNotice: false,
            remindDaysAgo: 0,
            departments: [],
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            holidayLogId: 0,
            currentEvent: null,
            fiscalYear: null,
            moments: [],
            dateStrings: [],
            codeName: '',
            holidayDescriptionId: null,
            fixed: true,
            offDay: true,
            makeCelebration: false,
            messageToId: null,
            applicableDepartmentId: null,
            requiredCrewId: null,
            sendNotice: false,
            remindDaysAgo: 0,
            departments: [],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }



    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    saveCurrentEvent() {
        if (this.state.currentEvent.id === undefined) {

            this.setState({ dataSource: this.state.dataSource.concat([this.state.currentEvent]), currentEvent: null });
        }
        else {
            // Update event
            var ds = this.state.dataSource;
            var index = ds.findIndex(evt => evt.id == this.state.currentEvent.id);
            ds[index] = this.state.currentEvent;
            this.setState({ dataSource: ds, currentEvent: null });
        }

        this.setState({ currentEvent: null });
    }

    addHolidayDescription = async () => {
        const { codeName } = this.state;
        let pType = "holidayDescription";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addRequiredCrew = async () => {
        const { codeName } = this.state;
        let pType = "requiredCrew";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/holidayDescription");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                holidayDescriptionSelectItems: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/requiredCrew");

        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                requiredCrewSelectItems: response2.data
            });
        }


    }
    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>
                <Modal
                    show={this.state.modalDeleteShow}
                    onHide={this.showOrHideDeleteModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Chosen holiday log will be deleted !</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                        <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Card>

                    <div>
                        <Row>
                            <Col sm="1">
                                <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                }
                            </Col>
                        </Row>
                    </div>



                    <Calendar
                        enableRangeSelection={true}
                        enableContextMenu={true}
                        contextMenuItems={[
                            { text: "Update", click: (evt) => this.edit(evt) },
                            { text: "Delete", click: (evt) => this.deleteModal(evt) }
                        ]}
                        onRangeSelected={e => {
                            let moments = [];
                            moments.push(moment(e.startDate));
                            moments.push(moment(e.endDate));

                            this.setState({ currentEvent: { startDate: e.startDate, endDate: e.endDate }, fiscalYear: moment(e.startDate).format("YYYY"), moments: moments })

                        }}
                        dataSource={this.state.dataSource}
                    />
                    <Modal size="xl" show={this.state.currentEvent} onHide={() => this.setState({ currentEvent: null })}
                        onHide={this.hideHolidayModal}
                    >
                        {this.state.currentEvent && (
                            <div>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.state.currentEvent.id !== undefined ? "Update event" : "Add event"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <div style={{ marginBottom: '2rem' }}>

                                        <FormAnt
                                            {...layout}
                                            initialValues={{ remember: false }}
                                            onFinish={this.save}
                                            onFinishFailed={onFinishFailed}
                                            ref={this.formRef}
                                        >

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Fiscal Year" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document type" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Input id="todo" value={this.state.fiscalYear} />
                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }


                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Holiday Description" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select
                                                                size="large"
                                                                style={{ width: "100%" }}
                                                                placeholder="Unit"
                                                                value={this.state.holidayDescriptionId} onChange={(value) => {
                                                                    this.setState({
                                                                        holidayDescriptionId: value
                                                                    })
                                                                }}
                                                             >
                                                                {this.state.holidayDescriptionSelectItems.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>
                                                        <Col sm="6">
                                                            <div>
                                                                <div style={{ marginLeft: "3px", display: 'flex', flexWrap: 'nowrap' }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value
                                                                        });
                                                                    }} />
                                                                    <div
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addHolidayDescription}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Date and hours" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document type" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <RangePicker showTime={{ format: 'HH:mm' }} value={this.state.moments}
                                                                format="YYYY-MM-DD HH:mm" onChange={(value, dateString) => {
                                                                    console.log(value);
                                                                    console.log(dateString);
                                                                    this.setState({
                                                                        moments: value,
                                                                        dateStrings: dateString
                                                                    })

                                                                }} />
                                                        </Col>
                                                        <Col sm="2">

                                                            <div>
                                                                <Switch checkedChildren="Fixed" unCheckedChildren="Not Fixed" checked={this.state.fixed} onChange={(value) => { this.setState({ fixed: value }) }} />

                                                            </div>


                                                        </Col>
                                                        <Col sm="2">
                                                            <span>Remind </span>    <InputNumber min={1} value={this.state.remindDaysAgo}
                                                                onChange={(value) => {
                                                                    this.setState({ remindDaysAgo: value })
                                                                }}
                                                            > </InputNumber>  <span>days ago. </span>
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={""}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type document type" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                        </Col>
                                                        <Col sm="6">
                                                            <div>
                                                                <Switch checkedChildren="Off day" unCheckedChildren="Working day" checked={this.state.offDay} onChange={(value) => { this.setState({ offDay: value }) }} />
                                                            </div>
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }
                                            {
                                                <FormAnt.Item
                                                    label={""}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                        </Col>
                                                        <Col sm="6">

                                                            <div>
                                                                <Checkbox onChange={(e) => {
                                                                    this.setState({
                                                                        makeCelebration: e.target.checked
                                                                    })
                                                                }} checked={this.state.makeCelebration}> Make celebration </Checkbox>
                                                            </div>


                                                        </Col>

                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {this.state.makeCelebration &&
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Message To" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select placeholder="" value={this.state.messageToId} onChange={(value) => {
                                                                this.setState({
                                                                    messageToId: value
                                                                })
                                                            }}
                                                            >
                                                                <Option key={0} value={0}>Select</Option>
                                                                {this.state.messagesToSelectItems.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                ))}
                                                            </Select>

                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {this.state.makeCelebration &&
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Applicable For" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select placeholder="" value={this.state.applicableDepartmentId} onChange={(value) => {
                                                                this.setState({
                                                                    applicableDepartmentId: value
                                                                })
                                                            }}
                                                            >
                                                                <Option key={0} value={0}>Select</Option>
                                                                {this.state.departmentSelectItems.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }
                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Required Crew" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                placeholder="Unit"
                                                                value={this.state.requiredCrewId} onChange={(value) => {
                                                                    this.setState({
                                                                        requiredCrewId: value
                                                                    })
                                                                }}

                                                            >
                                                                {this.state.requiredCrewSelectItems.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>
                                                        <Col sm="6">
                                                            <div>
                                                                <div style={{ marginLeft: "3px", display: 'flex', flexWrap: 'nowrap' }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value
                                                                        });
                                                                    }} />
                                                                    <div
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addRequiredCrew}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={""}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                        </Col>
                                                        <Col sm="6">

                                                            <div>
                                                                <Checkbox onChange={(e) => {
                                                                    this.setState({
                                                                        sendNotice: e.target.checked
                                                                    })
                                                                }} checked={this.state.sendNotice}> Send notice to managers </Checkbox>
                                                            </div>


                                                        </Col>

                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Applicable For" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type description" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select mode="tags" placeholder="" value={this.state.departments} onChange={(value) => {
                                                                this.setState({
                                                                    departments: value
                                                                })
                                                            }}
                                                            >
                                                                <Option key={0} value={0}>Select</Option>
                                                                {this.state.departmentSelectItems.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }


                                        </FormAnt>

                                    </div>

                                </Modal.Body>

                                <Modal.Footer>
                                    {/* <ReactButton variant="secondary" onClick={() => this.setState({ currentEvent: null })}>Cancel</ReactButton>
                                                <ReactButton variant="primary" onClick={() => this.saveCurrentEvent()}>Save event</ReactButton> */}

                                    <div hidden={this.state.hideSave}>
                                        {
                                            <ReactButton id="OrganizationalLevelSaveButton" type="button" onClick={this.save} style={{ width: '100%' }}
                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                        }
                                    </div>



                                    <div hidden={this.state.hideUpdate}>
                                        {<>
                                            <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '40%' }} variant="secondary" onClick={this.cancel}>
                                                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>

                                            <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '40%', marginLeft: '3px' }} variant="warning" type="button" onClick={this.save}>
                                                <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                        </>
                                        }
                                    </div>
                                </Modal.Footer>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    {this.state.holidayLogList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.holidayLogList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                        <Column title="S/N" dataIndex="index" />
                        {/* <Column title="Folder Name" render={(value, row, index) => {

                                if (Boolean(row.archiveDocument))
                                    return row.archiveDocument.folderName;

                            }} />
                            <Column title="Volume" render={(value, row, index) => {
                                if (Boolean(row.archiveDocument) && Boolean(row.archiveDocument.folderCode))
                                    return row.archiveDocument.folderCode + "-" + row.volumeNumber;
                                else return row.volumeNumber;

                            }} />

                            <Column title="Document Info" render={(value, row, index) => {

                                if (Boolean(row.archiveDocument) && Boolean(row.archiveDocument.documentLog))
                                    return row.archiveDocument.documentLog.description;
                            }} /> */}
                        <Column title="Year" dataIndex="fiscalYear"

                        />

                        <Column title="Start Date" render={(value, row, index) => {

                            if (Boolean(row.startDate))
                                return moment(new Date(row.startDate)).format("DD/MM/YYYY HH:mm");
                        }} />

                        <Column title="End Date" render={(value, row, index) => {

                            if (Boolean(row.endDate))
                                return moment(new Date(row.endDate)).format("DD/MM/YYYY HH:mm");
                        }} />

                        <Column title="Holiday Description" render={(value, row, index) => {

                            if (Boolean(row.holidayDescription))
                                return row.holidayDescription.name;
                        }} />

                        <Column title="Fixed" render={(value, row, index) => {

                            return row.fixed.toString();
                        }} />
                        <Column title="Off Days" render={(value, row, index) => {

                            return row.offDay.toString();
                        }} />

                        <Column title="Required Crew" render={(value, row, index) => {

                            if (Boolean(row.requiredCrew))
                                return row.requiredCrew.name;
                        }} />



                        <Column title="Action" render={(value, row, index) => {

                            return this.actionTemplate(row);

                        }} />

                    </Table>}
                </Card>


                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(HolidayLog)
