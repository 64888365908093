import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchSelectItem } from "../../../redux/actions/selectItemAction";
import { FormattedMessage } from 'react-intl';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export class ItemTest extends Component {
  static propTypes = {
    selectItemReducer: PropTypes.shape({ selectItemList: PropTypes.array.isRequired })
  };

  componentDidMount() {
    this.props.fetchSelectItem();
  }

  render() {
    console.log("XXX", this.props.selectItemReducer.selectItemList);
    return <div>SELECT TEST PAGE
      <br />
      <DropdownButton id='dropdown-item-button' title={<FormattedMessage id="SELECT.TEXT" defaultMessage="Choose"></FormattedMessage>}>
        {this.props.selectItemReducer.selectItemList.map((e, key) => {
          return <FormattedMessage id={e.name} key={e.id} defaultMessage={e.text}>
            {(message) => <Dropdown.Item value={e.id}>{message}</Dropdown.Item>}
          </FormattedMessage>;
        })}
      </DropdownButton>

    </div>;
  }
}

const mapStateToProps = ({ selectItemReducer }) => {
  return {
    selectItemReducer
  };
};

const mapDispatchToProps = {
  fetchSelectItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemTest);
