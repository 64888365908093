import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, DatePicker, Upload, Radio, Button as AntdButton } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class InspectionResultNotification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      inspectionDate: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      inspectionResultNotificationTable: [],
      inspectionAssignmentPackages: [],
      inspectionAssignmentPackage: 0,
      PimReportFileUpload: [],
      pimReport: "",
      fileList: [],
      hiddenJustification: true,
      inspections: [],
      inspectionsDates: [],
      inspectionNotificationTable: []
    };

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InspectionResultNotification" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "InspectionResultNotification",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InspectionResultNotification");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
  }

  getTableProperties = async () => {
    this.inspectionResultNotification = JSON.parse(localStorage.getItem('inspectionResultNotification'));
    if (this.inspectionResultNotification != null && this.inspectionResultNotification != undefined && this.inspectionResultNotification != "") {

      await this.setState({
        bordered: this.inspectionResultNotification.bordered,
        title: this.inspectionResultNotification.titleDrm == true ? title : undefined,
        titleDrm: this.inspectionResultNotification.titleDrm,
        showHeader: this.inspectionResultNotification.showHeader,
        footer: this.inspectionResultNotification.footerDrm == true ? footer : undefined,
        footerDrm: this.inspectionResultNotification.footerDrm,
        expandable: this.inspectionResultNotification.expandableDrm == true ? expandable : {},
        expandableDrm: this.inspectionResultNotification.expandableDrm,
        rowSelection: this.inspectionResultNotification.rowSelection,
        ellipsis: this.inspectionResultNotification.ellipsis,
        tableLayout: this.inspectionResultNotification.tableLayout,
        size: this.inspectionResultNotification.size,
        top: this.inspectionResultNotification.top,
        bottom: this.inspectionResultNotification.bottom,
        editBgColor: this.inspectionResultNotification.editBgColor,
        deleteBgColor: this.inspectionResultNotification.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.inspectionResultNotification.yScroll,
        xScroll: this.inspectionResultNotification.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.inspectionResultNotificationTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.inspectionResultNotificationTable.length, showQuickJumper: true,
          position: [this.inspectionResultNotification.top, this.inspectionResultNotification.bottom]
        },
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
    }
  }

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  }

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedInspectionResultNotification: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveInspectionResultNotification = async () => {
    var knt = true;
    if (this.state.inspectionResult == "Not Satisfied") {
      if (!(this.state.justification != null && this.state.justification != undefined && this.state.justification != "")) {
        knt = false;
      }
    }
    if (knt) {
      const addInspectionResultNotification = {
        inspectionAssignmentPackage: this.state.inspectionAssignmentPackage,
        inspection: this.state.inspection,
        inspectionDate: moment(this.state.inspectionDate, 'DD/MM/YYYY'),
        inspectionDateAck: this.state.inspectionDate,
        pimReport: this.state.pimReport,
        inspectionResult: this.state.inspectionResult,
        justification: this.state.justification,
        remarks: this.state.remarks,
      }
      var response = await handleRequest("POST", "/InspectionResultNotification/add", addInspectionResultNotification);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.resetInputs();
          this.restartTable();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      } else {
        this.showError();
      }
    } else {
      this.showWarning(<FormattedMessage id="InspectionResultNotificationWarningMessage1" defaultMessage="Please input your Justification" />);
    }
  }

  updateInspectionResultNotification = async () => {
    var knt = true;
    if (this.state.inspectionResult == "Not Satisfied") {
      if (!(this.state.justification != null && this.state.justification != undefined && this.state.justification != "")) {
        knt = false;
      }
    }
    if (knt) {
      const updatedInspectionResultNotification = {
        id: this.state.selectedInspectionResultNotification.id,
        inspectionAssignmentPackage: this.state.inspectionAssignmentPackage,
        inspection: this.state.inspection,
        inspectionDate: moment(this.state.inspectionDate, 'DD/MM/YYYY'),
        inspectionDateAck: this.state.inspectionDate,
        pimReport: this.state.pimReport,
        inspectionResult: this.state.inspectionResult,
        justification: this.state.justification,
        remarks: this.state.remarks,
      }
      var response2 = await handleRequest("POST", "/InspectionResultNotification/update", updatedInspectionResultNotification);
      if (response2.data !== null && response2.data !== undefined) {
        if (response2.data === "SUCCESS") {
          this.resetInputs();
          this.restartTable();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      } else {
        this.showError();
      }
    } else {
      this.showWarning(<FormattedMessage id="InspectionResultNotificationWarningMessage1" defaultMessage="Please input your Justification" />);
    }
  }

  restartTable = async () => {
    const responseIAP = await handleRequest("GET", "/InspectionAssignmentPackage/getallByCompany");
    if (responseIAP.data.length === 0) {
      this.setState({
        inspectionAssignmentPackages: [],
      });
    }

    if (responseIAP.data.length !== 0) {
      this.setState({
        inspectionAssignmentPackages: responseIAP.data,
      });
    }

    const response = await handleRequest("GET", "/InspectionNotification/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        inspectionNotificationTable: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        inspectionNotificationTable: response.data,
      });
    }

    const responseNotification = await handleRequest("GET", "/InspectionResultNotification/getallByCompany");
    if (responseNotification.data.length === 0) {
      this.setState({
        inspectionResultNotificationTable: [],
      });
    }

    if (responseNotification.data.length !== 0) {
      this.setState({
        inspectionResultNotificationTable: responseNotification.data,
      });
    }
    //this.getinspectionAssignmentPackages(0);
  }

  getinspectionAssignmentPackages = async (inspectionAssignmentPackage) => {
    var newObj = {
      id: inspectionAssignmentPackage
    }
    const response = await handleRequest("POST", "/InspectionAssignmentPackage/getInspectionResultNotification", newObj);
    if (response.data.length === 0) {
      this.setState({
        inspectionAssignmentPackages: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        inspectionAssignmentPackages: response.data,
      });
    }
  }

  getUserSettings = async () => {
    const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
    if (responseUserSettings.data.length !== 0) {
      this.setState({
        userSettings: responseUserSettings.data,
        howToUseSetting: responseUserSettings.data.howToUseSetting,
        whatIsSetting: responseUserSettings.data.whatIsSetting
      });
    } else {
      this.setState({

        howToUseSetting: false,
        whatIsSetting: false
      });
    }
  }

  deleteInspectionResultNotificationOK = async () => {
    const deleteInspectionResultNotification = {
      id: this.state.selectedInspectionResultNotification.id
    }
    var response = await handleRequest("POST", "/InspectionResultNotification/delete", deleteInspectionResultNotification);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    this.formRef.current.setFieldsValue({
      InspectionAssignmentPackage: row.inspectionAssignmentPackage,
      Inspection: row.inspection,
      InspectionDate: (row.inspectionDate != null && row.inspectionDate != undefined && row.inspectionDate != "") && moment(row.inspectionDate, 'DD/MM/YYYY'),
      PimReport: row.pimReport,
      InspectionResult: row.inspectionResult,
      Justification: row.justification,
      Remarks: row.remarks,
    });

    var insPackage = this.state.inspectionAssignmentPackages.filter(m => m.id == row.inspectionAssignmentPackage);
    if (!(insPackage != null && insPackage != undefined && insPackage.length > 0)) {
      this.getinspectionAssignmentPackages(row.inspectionAssignmentPackage);
    }

    await this.setState({
      selectedInspectionResultNotification: row,
      inspectionAssignmentPackage: row.inspectionAssignmentPackage,
      inspection: row.inspection,
      inspectionDate: row.inspectionDate,
      pimReport: row.pimReport,
      inspectionResult: row.inspectionResult,
      justification: row.justification,
      remarks: row.remarks,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      InspectionAssignmentPackage: "",
      Inspection: "",
      InspectionDate: "",
      PimReport: "",
      InspectionResult: "",
      Justification: "",
      Remarks: "",
    });

    this.setState({
      selectedInspectionResultNotification: [],
      inspectionAssignmentPackage: "",
      inspection: "",
      inspectionDate: "",
      pimReport: "",
      inspectionResult: "",
      justification: "",
      remarks: "",

      PimReportFileUpload: [],
      pimReport: "",
      fileList: [],

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
    //this.getinspectionAssignmentPackages(0);
  }

  changeButtonCancel = () => {
    this.formRef.current.setFieldsValue({
      InspectionAssignmentPackage: "",
      Inspection: "",
      InspectionDate: "",
      PimReport: "",
      InspectionResult: "",
      Justification: "",
      Remarks: "",
    });

    this.setState({
      selectedInspectionResultNotification: [],
      inspectionAssignmentPackage: "",
      inspection: "",
      inspectionDate: "",
      pimReport: "",
      inspectionResult: "",
      justification: "",
      remarks: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenCreateNewButton: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenCreateNewButton: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeInspectionDate = (value, dateString) => {
    this.setState({
      inspectionDate: dateString,
    });
  }

  handleChangeInspectionAssignmentPackage = async (e) => {
    // if (Boolean(e) && e > 0) {
    //   const obj = {
    //     yayinlanmaDrm: 1,
    //     yayinlanmaDrmKnt: 1,
    //     dateNowKnt: 1,
    //     inspectionAssignmentPackage: e
    //   };
    //   const response = await handleRequest("POST", "/InspectionNotification/getResult", obj);
    //   if (response.data.length !== 0) {
    //     data = response.data;
    //   }
    // }

    // var inspectionDate = "";
    // if (data.length != 0) {
    //   inspectionDate = data[data.length - 1].inspectionDate;
    // }

    // this.setState({
    //   inspectionAssignmentPackage: e,
    //   inspectionDate: inspectionDate,
    //   inspection: "PIM"
    // });

    // this.formRef.current.setFieldsValue({
    //   InspectionDate: (inspectionDate != null && inspectionDate != undefined && inspectionDate != "") ? moment(inspectionDate, 'DD/MM/YYYY') : null,
    //   Inspection: "PIM"
    // });

    var dataNotifications = [];
    let _inspections = [];
    let _inspectionsDates = [];
    let dataNotificationInspections = [];

    if (Boolean(e) && e > 0) {
      for (let index = 0; index < this.state.inspectionResultNotificationTable.length; index++) {
        if (this.state.inspectionResultNotificationTable[index].inspectionAssignmentPackage === e) {
          dataNotificationInspections.push(this.state.inspectionResultNotificationTable[index].inspection);
        }
      }

      for (let index = 0; index < this.state.inspectionNotificationTable.length; index++) {
        if (this.state.inspectionNotificationTable[index].inspectionAssignmentPackage === e) {
          if (!(dataNotificationInspections.includes(this.state.inspectionNotificationTable[index].inspection)))
            dataNotifications.push(this.state.inspectionNotificationTable[index]);
        }
      }
    }

    for (let index = 0; index < dataNotifications.length; index++) {
      _inspections.push(dataNotifications[index].inspection);
      _inspectionsDates.push(dataNotifications[index].inspectionDate);
    }

    this.setState({
      inspectionAssignmentPackage: e,
      inspections: _inspections,
      inspectionsDates: _inspectionsDates
    });
  }

  handleChangeInspection = (e) => {
    let flagDate = null;

    for (let index = 0; index < this.state.inspections.length; index++) {
      if (this.state.inspections[index] === e) {
        let _flagInspectionDate = this.state.inspectionsDates[index].split("-");
        flagDate = _flagInspectionDate[2] + "/" + _flagInspectionDate[1] + "/" + _flagInspectionDate[0];
      }
    }

    this.setState({
      inspection: e,
      inspectionDate: flagDate
    });

    this.formRef.current.setFieldsValue({
      InspectionDate: (flagDate != null && flagDate != undefined && flagDate != "") ? moment(flagDate, 'DD/MM/YYYY') : null,
      Inspection: e
    });
  }

  showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.pdf')) {
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true,
        });
      } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      } else {
        window.location.href = fileRealUrl;
      }
    } catch (error) {
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
    }
  }

  render() {
    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };

    var itemInspectionAssignmentPackageAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionAssignmentPackage");
    var itemInspectionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Inspection");
    var itemInspectionDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionDate");
    var itemPimReportAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PimReport");
    var itemInspectionResultAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionResult");
    var itemJustificationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Justification");
    var itemRemarksAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Remarks");

    var itemInspectionAssignmentPackageRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionAssignmentPackage");
    var itemInspectionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Inspection");
    var itemInspectionDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionDate");
    var itemPimReportRequired = this.state.dynamicInputs.find(p => p.inputKey === "PimReport");
    var itemInspectionResultRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionResult");
    var itemJustificationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Justification");
    var itemRemarksRequired = this.state.dynamicInputs.find(p => p.inputKey === "Remarks");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("inspectionResultNotification")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "assignmentPackage",
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationInspectionAssignmentPackage" defaultMessage="Inspection Assignment Package" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.requestForQuotation !== undefined)
              if (data.requestForQuotation !== null)
                return data.iap + "-" + data.requestForQuotation.projectTakeOffList.description;
          }
          return "";
        }
      },
      {
        dataIndex: "inspection",
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationInspection" defaultMessage="Inspection" />,
      },
      {
        dataIndex: "inspectionDate",
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationInspectionDate" defaultMessage="Inspection Date" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationPimReport" defaultMessage="PIM Report" />,
        render: (data) => {
          if (data != null && data != undefined && data.pimReport != null && data.pimReport != undefined && data.pimReport != "") {
            return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(data.pimReport)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
          }
          return "";
        }
      },
      {
        dataIndex: "inspectionResult",
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationInspectionResult" defaultMessage="Inspection Result" />,
      },
      {
        dataIndex: "justification",
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationJustification" defaultMessage="Justification" />,
      },
      {
        dataIndex: "remarks",
        key: "key",
        title: <FormattedMessage id="InspectionResultNotificationRemarks" defaultMessage="Remarks" />,
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    let radioGroupOptions = (
      <FormBoot.Group as={Row}  >
        <Col sm="5">
          <Radio value={"Satisfied"}><FormattedMessage id="InspectionResultNotificationSatisfied" defaultMessage="Satisfied" /></Radio>
        </Col>
        <Col sm="2">
        </Col>
        <Col sm="5">
          <Radio value={"Not Satisfied"}><FormattedMessage id="InspectionResultNotificationNotSatisfied" defaultMessage="Not Satisfied" /></Radio>
        </Col>
      </FormBoot.Group>
    );

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateInspectionResultNotification : this.saveInspectionResultNotification}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("inspectionResultNotification")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />
              {(itemInspectionAssignmentPackageAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationInspectionAssignmentPackage" defaultMessage="Inspection Assignment Package" />}
                  name="InspectionAssignmentPackage"
                  rules={[{ required: itemInspectionAssignmentPackageRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionAssignmentPackageRequired.translateRequired) == true ? itemInspectionAssignmentPackageRequired.translateRequired : -1} defaultMessage="Please input your Inspection Assignment Package!" /> }]} >
                  <Select name="InspectionAssignmentPackage"
                    style={{ width: '100%' }}
                    value={this.state.inspectionAssignmentPackage}
                    onChange={this.handleChangeInspectionAssignmentPackage}>

                    {this.state.inspectionAssignmentPackages.map(i => (
                      <Option key={i.id} value={i.id}>{i.iap + "-" + i.requestForQuotation.projectTakeOffList.description}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemInspectionAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationInspection" defaultMessage="Inspection" />}
                  name="Inspection"
                  rules={[{ required: itemInspectionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionRequired.translateRequired) == true ? itemInspectionRequired.translateRequired : -1} defaultMessage="Please input your Inspection!" /> }]} >
                  <Select name="Inspection"
                    style={{ width: '100%' }}
                    value={this.state.inspection}
                    onChange={this.handleChangeInspection}>
                    {this.state.inspectionAssignmentPackage > 0 &&
                      this.state.inspections.map(i => (
                        <Option key={i} value={i}>{i}</Option>
                      ))}
                  </Select>
                </Form.Item>}

              {(itemInspectionDateAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationInspectionDate" defaultMessage="Inspection Date" />}
                  name="InspectionDate"
                  rules={[{ required: itemInspectionDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionDateRequired.translateRequired) == true ? itemInspectionDateRequired.translateRequired : -1} defaultMessage="Please input your Inspection Date!" /> }]} >
                  <DatePicker disabled={true} value={moment(this.state.inspectionDate, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeInspectionDate} />
                </Form.Item>}

              {(itemPimReportAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationPimReport" defaultMessage="PIM Report" />}
                  name="PimReport"
                  rules={[{ required: itemPimReportRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPimReportRequired.translateRequired) == true ? itemPimReportRequired.translateRequired : -1} defaultMessage="Please input your PIM Report!" /> }]} >
                  <Upload {...props}
                    showUploadList={true}
                    fileList={this.state.PimReportFileUpload}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        var pimReport = "";
                        if (Boolean(fileList[0].response)) {
                          pimReport = fileList[0].response.url;
                        }
                        this.setState({
                          PimReportFileUpload: fileList,
                          pimReport: pimReport,
                        });
                      }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.PimReportFileUpload.length > 0) {
                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        PimReportFileUpload: [],
                        pimReport: "",
                      })
                    }
                    }>
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                    </AntdButton>
                  </Upload>

                </Form.Item>}

              {(itemInspectionResultAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationInspectionResult" defaultMessage="Inspection Result" />}
                  name="InspectionResult"
                  rules={[{ required: itemInspectionResultRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionResultRequired.translateRequired) == true ? itemInspectionResultRequired.translateRequired : -1} defaultMessage="Please input your Inspection Result!" /> }]} >
                  <Mnd.MNDRadioGroup
                    onChange={(e) => {
                      var hiddenJustification = true;
                      if (e.target.value == "Not Satisfied") {
                        hiddenJustification = false;
                      } else {
                        this.setState({
                          justification: "",
                        });
                      }
                      this.setState({
                        inspectionResult: e.target.value,
                        hiddenJustification: hiddenJustification,
                      });
                    }}
                    radios={radioGroupOptions}
                  />
                </Form.Item>}

              {(itemJustificationAttributes.visible && !this.state.hiddenJustification) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationJustification" defaultMessage="Justification" />}
                  name="Justification"
                  rules={[{ required: itemJustificationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemJustificationRequired.translateRequired) == true ? itemJustificationRequired.translateRequired : -1} defaultMessage="Please input your Justification!" /> }]} >
                  <Input id="InspectionResultNotificationJustification" value={this.state.justification} onChange={(e) =>
                    this.setState({ justification: e.target.value })
                  } />
                </Form.Item>}

              {(itemRemarksAttributes.visible && !this.state.hiddenRemarks) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionResultNotificationRemarks" defaultMessage="Remarks" />}
                  name="Remarks"
                  rules={[{ required: itemRemarksRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRemarksRequired.translateRequired) == true ? itemRemarksRequired.translateRequired : -1} defaultMessage="Please input your Remarks!" /> }]} >
                  <Input id="InspectionResultNotificationRemarks" value={this.state.remarks} onChange={(e) =>
                    this.setState({ remarks: e.target.value })
                  } />
                </Form.Item>}

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="5">
                      <Button id="InspectionResultNotificationSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button id="InspectionResultNotificationCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="3">
                      <Button id="InspectionResultNotificationUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.inspectionResultNotificationTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>
        }

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteInspectionResultNotificationOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionResultNotification)