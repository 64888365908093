/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
//import { DataTable } from "primereact/datatable";
//import { Column } from "primereact/column";
import { FormattedMessage } from "react-intl";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import classNames from "classnames";
import { handleRequest, API_BASE } from "../../redux/config/env";
import { Button, Modal, Container, Col, Form, Row } from "react-bootstrap";
import "./css/ShipmentLog.scss";
import { Select, Input, InputNumber, Upload, message, Button as ButtonA, Table, Checkbox, Form as FormAnt } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
//import { pdfjs } from 'react-pdf';
import {
    FacebookShareButton, WhatsappShareButton, EmailShareButton,
    FacebookIcon, WhatsappIcon, EmailIcon
} from "react-share";
//import Loader from 'react-loader-spinner';
import QrReader from 'react-qr-scanner';
import $ from 'jquery';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';

const { Column } = Table;
const { Option } = Select;

const renderToolbar = (toolbarSlot) => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                }}
            >
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                </div>
            </div>
        </div>
    );
};

const layout1 = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar,
) => {
    return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
    );
};

export class ShipmentLog extends Component {
    constructor() {
        super();
        this.state = {
            selectedConfirmation: null,
            globalFilter: null,
            showGlobalFilter: null,
            modalPhotoShow: false,
            confirmations: [
                { label: "Confirmed", value: "Confirmed" },
                { label: "Under Evaluation", value: "Under Evaluation" },
                { label: "Not Confirmed", value: "Not Confirmed" },
            ],
            shipmentLogs: [],
            imageUrl: "",

            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            lastButton: "Save",
            lastButtonVariant: "success",
            customer: "",
            customerList: [],
            project: "",
            projectList: [],
            truck: "",
            truckList: [],
            driver: "",
            driverList: [],
            product: "",
            productList: [],
            volume: "",
            invoiceNo: "",
            ticketNo: "",
            invoicePhotoPath: "",
            ticketPhotoPath: "",
            assignments: [],
            pdfFile: "",
            truckWeight: "",
            delay: 100,
            delay2: 100,
            result: 'No result',
            modalQRShow: false,
            camera: false,
            modalPdfViewer: false,
            isGetting1: false,
            modalUpdate: false,
            selectedShipment: null,
            confirmationValue: "Under Evaluation",

            pagination: {
                current: 1,
                pageSize: 10,
            },

            total: 0,
            loading: false,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.onConfirmationChange = this.onConfirmationChange.bind(this);
        this.confirmationEditor = this.confirmationEditor.bind(this);

        this.confirmationBodyTemplate = this.confirmationBodyTemplate.bind(this);
        this.lTicketBodyTemplate = this.lTicketBodyTemplate.bind(this);
        this.cTicketBodyTemplate = this.cTicketBodyTemplate.bind(this);
        this.confirmationItemTemplate = this.confirmationItemTemplate.bind(this);

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.checkShipmentReport = this.checkShipmentReport.bind(this);

        this.handleScan = this.handleScan.bind(this);
        this.handleScan2 = this.handleScan2.bind(this);
        this.openImageDialog = this.openImageDialog.bind(this);

        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ShipmentLog" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "ShipmentLog",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ShipmentLog");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        const { pagination } = this.state;
        this.fetch({ pagination });

        //this.restartTable();
        const responseCustomer = await handleRequest(
            "GET",
            "/CustomerLog/getAllByCompany"
        );

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data,
            });
        }

        const response0 = await handleRequest("GET", "/Assignment/getAll");

        if (response0.data.length > 0) {
            this.setState({
                assignments: response0.data,
            });
        }

        const responseEquipment = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipment.data.length !== 0) {
            var list = responseEquipment.data;

            var _truckList = list.filter((p) => p.equipmentType != null && p.equipmentType.equipmentTypeName === "Truck");

            for (let index = 0; index < _truckList.length; index++) {
                if (_truckList[index].equipmentVehicleLicenceList !== null && _truckList[index].equipmentVehicleLicenceList.length !== 0) {
                    _truckList[index].plateNo = _truckList[index].equipmentVehicleLicenceList[0].plateNo.toString();
                } else {
                    _truckList[index].plateNo = "";
                }
            }

            this.setState({
                truckList: _truckList,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    restartTable = async () => {
        // const response = await handleRequest("GET", "/Shipment/getAll");

        // console.log(response.data);

        // if (response.data.length === 0) {
        //   this.setState({
        //     shipmentLogs: [],
        //   });
        // } else {
        //   this.setState({
        //     shipmentLogs: response.data,
        //   });
        // }

        //eskiden �steki �ekilde �al���yordu.
        const { pagination } = this.state;
        this.fetch({ pagination });

        // this.setState({ loading: true });

        // const response = await handleRequest("GET", "/Shipment/getAll");
        // this.datasource = response.data;

        // setTimeout(() => {

        //   if (response.data.length === 0) {
        //     this.setState({
        //       shipmentLogs: [],
        //       totalRecords: 0,
        //       loading: false
        //     });
        //   } else {
        //     this.setState({
        //       shipmentLogs: this.datasource.slice(0, 10),
        //       totalRecords: response.data.length,
        //       loading: false
        //     });
        //   }
        // }, 500);
    }

    fillProjects = async () => {
        var customerList = this.state.customerList;
        var customerId = this.state.customer;

        var index = customerList.findIndex((p) => p.id === customerId);

        if (
            index >= 0 &&
            customerList[index].projects !== null &&
            customerList[index].projects !== undefined &&
            customerList[index].projects.length > 0
        ) {
            this.setState({
                projectList: customerList[index].projects,
            });
        }
    };

    fillServices = async () => {
        var projectList = this.state.projectList;
        var projectId = this.state.project;

        var index = projectList.findIndex((p) => p.id === projectId);

        if (
            index >= 0 &&
            projectList[index].services !== null &&
            projectList[index].services !== undefined &&
            projectList[index].services.length > 0
        ) {
            this.setState({
                productList: projectList[index].services,
            });
        }
    };

    fillDrivers = async () => {
        var list = this.state.assignments;
        var truck = this.state.truck;

        var filteredList = list.filter((p) => p.equipmentLogId == truck);

        if (filteredList.length > 0) {
            this.setState({
                driverList: filteredList,
                driver: filteredList[0].staff.id
            });
        }
    };

    saveShipment = async () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        if (this.state.customer !== "" && this.state.project !== "" && this.state.invoicePhotoPath !== "" &&
            this.state.truck !== "" && this.state.driver !== "" && this.state.truckWeight !== "" &&
            this.state.invoiceNo !== "" && !isNaN(this.state.volume) && this.state.volume !== "") {

            const newTrip = {
                flagData: "2000-02-17",
                projectId: "Project-1",
                equipmentLogId: this.state.truck,
                staffId: this.state.driver,
                volume: Number(this.state.volume) - Number(this.state.truckWeight),
                checkVolume: "", //this.state.volume,
                invoiceNo: this.state.invoiceNo,
                invoicePhotoPath: this.state.invoicePhotoPath,
                ticketPhotoPath: "", //this.state.ticketPhotoPath,
                ticketNo: "", //this.state.ticketNo,
                customer: this.state.customer,
                project: this.state.project,
                product: this.state.product,
                checkStatus: true,
                shipmentDay: today,
                confirmation: "Under Evaluation",
                truckWeight: this.state.truckWeight
            };

            await handleRequest("POST", "/Shipment/add1", newTrip);
            this.resetInputs();
            this.restartTable();
            this.showSuccess("All data has been saved successfully.");
        } else {
            this.showError();
        }
    };

    createNew = () => {
        //if (this.state.createNewButton === "Create New") {
        this.setState({
            hidden: !this.state.hidden,
            createNewButton: "Stop Adding New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "danger",
            customer: "",
            project: "",
            truck: "",
            driver: "",
            driverList: [],
            product: "",
            projectList: [],
            productList: [],
            volume: "",
            invoiceNo: "",
            ticketNo: "",
            invoicePhotoPath: "",
            ticketPhotoPath: "",
        });

        $(".ant-upload-list").empty();

        // } else {
        //   this.setState({
        //     hidden: true,
        //     createNewButton: "Create New",
        //     lastButton: "Save",
        //     lastButtonVariant: "success",
        //     createNewButtonVariant: "info",
        //     customer: "",
        //     project: "",
        //     truck: "",
        //     driver: "",
        //     driverList: [],
        //     product: "",
        //     projectList: [],
        //     productList: [],
        //     volume: "",
        //     invoiceNo: "",
        //     ticketNo: "",
        //     invoicePhotoPath: "",
        //     ticketPhotoPath: "",
        //   });
        // }
    };

    resetInputs = () => {
        this.setState({
            hidden: true,
            modalShow: false,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",
            customer: "",
            project: "",
            truck: "",
            driver: "",
            driverList: [],
            product: "",
            projectList: [],
            productList: [],
            volume: "",
            invoiceNo: "",
            ticketNo: "",
            invoicePhotoPath: "",
            ticketPhotoPath: "",
            truckWeight: "",
        });

        $(".ant-upload-list").empty();
    }

    onConfirmationChange(event) {
        this.dt.filter(event.value, "confirmation", "equals");
        this.setState({ selectedConfirmation: event.value });
    }

    renderHeader() {
        let displayGlobalFilter = this.state.showGlobalFilter;
        return (
            <div
                style={{
                    border: "0 none",
                    padding: "12px",
                    textAlign: "left",
                    fontSize: "20px",
                }}
            >
                Shipment Logs
                <div
                    style={{
                        float: "right",
                        width: "200px",
                        display: displayGlobalFilter,
                    }}
                    className="p-datatable-globalfilter-container"
                >
                    <InputText
                        type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })}
                        placeholder="Global Search"
                    />
                </div>
            </div>
        );
    }

    onEditorValueChange = async (props, value) => {
        let updatedShipments = [...props.value];
        updatedShipments[props.rowIndex][props.field] = value;
        this.setState({ shipmentLogs: updatedShipments });

        const updatedTrip = {
            id: props.rowData.id,
            flagData: props.rowData.flagData,
            projectId: props.rowData.projectId,
            equipmentLogId: props.rowData.equipmentLogId,
            staffId: props.rowData.staffId,
            volume: props.rowData.volume,
            invoiceNo: props.rowData.invoiceNo,
            invoicePhotoPath: props.rowData.invoicePhotoPath,
            company: props.rowData.company,
            checkVolume: props.rowData.checkVolume,
            ticketNo: props.rowData.ticketNo,
            ticketPhotoPath: props.rowData.ticketPhotoPath,
            checkStatus: props.rowData.checkStatus,
            shipmentDay: props.rowData.shipmentDay,
            confirmation: value,
            customer: props.rowData.customer,
            project: props.rowData.project,
            product: props.rowData.product,
            voucherPdfPath: props.rowData.voucherPdfPath,
            truckWeight: props.rowData.truckWeight
        };

        await handleRequest("PUT", `/Shipment/update`, updatedTrip);

        const response = await handleRequest(
            "GET",
            "/CompanyOrder/getAllByCompany"
        );

        if (response.data.length !== 0) {
            let mainOrderList = response.data;
            let flagOrder = null;

            dance: for (let index = 0; index < mainOrderList.length; index++) {
                if (
                    mainOrderList[index].orders.length !== 0 &&
                    mainOrderList[index].orders !== null
                ) {
                    if (
                        parseInt(mainOrderList[index].customerId) ===
                        props.rowData.customer &&
                        parseInt(mainOrderList[index].customerProjectId) ===
                        props.rowData.customerProject.id
                    ) {
                        for (
                            let index1 = 0;
                            index1 < mainOrderList[index].orders.length;
                            index1++
                        ) {
                            if (
                                parseInt(
                                    mainOrderList[index].orders[index1].companyServiceId
                                ) === props.rowData.product &&
                                parseInt(mainOrderList[index].orders[index1].balance) > 0
                            )
                                flagOrder = mainOrderList[index].orders[index1];
                            break dance;
                        }
                    }
                }
            }

            if (flagOrder !== null) {
                if (value === "Confirmed") {
                    let flagBalance =
                        Number(flagOrder.balance) - Number(props.rowData.checkVolume);
                    let deliveredQty =
                        Number(flagOrder.deliveredQty) + Number(props.rowData.checkVolume);

                    const updatedCompanyOrder = {
                        id: flagOrder.id,
                        companyServiceId: props.rowData.product,
                        quantity: flagOrder.quantity,
                        balance: flagBalance,
                        deliveredQty: deliveredQty,
                    };

                    await handleRequest(
                        "POST",
                        "/CompanyOrderList/update",
                        updatedCompanyOrder
                    );
                }

                if (value === "Under Evaluation" || value === "Not Confirmed") {
                    if (Number(flagOrder.deliveredQty) > 0) {
                        let flagBalance =
                            Number(flagOrder.balance) + Number(props.rowData.checkVolume);
                        let deliveredQty =
                            Number(flagOrder.deliveredQty) -
                            Number(props.rowData.checkVolume);

                        const updatedCompanyOrder = {
                            id: flagOrder.id,
                            companyServiceId: props.rowData.product,
                            quantity: flagOrder.quantity,
                            balance: flagBalance,
                            deliveredQty: deliveredQty,
                        };

                        await handleRequest(
                            "POST",
                            "/CompanyOrderList/update",
                            updatedCompanyOrder
                        );
                    }
                }
            }
        }
    }

    renderConfirmationFilter() {
        return (
            <Dropdown
                style={{ width: "100%" }}
                placeholder="Select Confirmation"
                value={this.state.selectedConfirmation}
                options={this.state.confirmations}
                itemTemplate={this.confirmationItemTemplate}
                onChange={this.onConfirmationChange}
                showClear={true}
            />
        );
    }

    confirmationEditor(props) {
        return (
            <Dropdown
                value={props.value[props.rowIndex].confirmation}
                options={this.state.confirmations}
                itemTemplate={this.confirmationItemTemplate}
                onChange={(e) => this.onEditorValueChange(props, e.value)}
                style={{ width: "100%" }}
            />
        );
    }

    confirmationItemTemplate(option) {
        var color;
        if (option.value === "Confirmed") color = "qualified";
        else if (option.value === "Not Confirmed") color = "unqualified";
        else color = "new";
        return (
            <span className={classNames("customer-badge", "status-" + color)}>
                {option.value}
            </span>
        );
    }

    confirmationBodyTemplate(rowData) {
        var color;
        if (rowData.confirmation === "Confirmed") color = "qualified";
        else if (rowData.confirmation === "Not Confirmed") color = "unqualified";
        else color = "new";
        return (
            <span className={classNames("customer-badge", "status-" + color)}>
                {rowData.confirmation}
            </span>
        );
    }

    lTicketBodyTemplate(rowData) {
        if (rowData.voucherPdfPath !== "" && rowData.voucherPdfPath !== null) {
            return (
                <React.Fragment>
                    <div style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                        onClick={(e) => this.showVoucherReport(rowData)}>
                        {rowData.invoiceNo}
                    </div>
                </React.Fragment>
            );
        } else if (rowData.invoicePhotoPath !== "" && rowData.invoicePhotoPath !== null) {
            return (
                <div
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                    onClick={() =>
                        this.setState({
                            imageUrl: `${API_BASE}/Shipment/download/` + rowData.id,
                            modalPhotoShow: true,
                        })
                    }
                >
                    {rowData.invoiceNo}
                </div>
            );
        } else {
            if (rowData.invoiceNo === null)
                return "";
            else
                return rowData.invoiceNo;
        }
    }

    cTicketBodyTemplate(rowData) {
        if (rowData.ticketPhotoPath === "" || rowData.ticketPhotoPath === null) {
            if (rowData.ticketNo === null) return "";
            else return rowData.ticketNo;
        } else {
            return (
                <div
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                    onClick={() =>
                        this.setState({
                            imageUrl: `${API_BASE}/Shipment/download2/` + rowData.id,
                            modalPhotoShow: true,
                        })
                    }
                >
                    {rowData.ticketNo}
                </div>
            );
        }
    }

    nameTemplate(rowData, column) {
        return rowData.staff.name + " " + rowData.staff.lastName;
    }

    checkVolumeTemplate(rowData, column) {
        if (rowData.ticketNo === null) return "";
        else return rowData.checkVolume;
    }

    checkShipmentReport(rowData) {
        if (rowData.voucherPdfPath !== null && rowData.voucherPdfPath !== "")
            return (
                <React.Fragment>
                    <Button onClick={(e) => this.showVoucherReport(rowData)} type="button">
                        Show
        </Button>
                </React.Fragment>
            );
        else
            return;
    }

    showVoucherReport = async (rowData) => {
        try {
            const fileRealUrl = `${API_BASE}/Shipment/download1/` + rowData.id;

            console.log("fileUrl: " + fileRealUrl);

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            console.log(error);
            alert("File cant found.");
        }
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
    }

    uploadTicket = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    //ticketPhotoPath: info.file.response[0],
                    ticketPhotoPath: ""
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    uploadInvoice = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    invoicePhotoPath: info.file.response[0],
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
    }

    printPdf = () => {
        // var divToPrint = document.getElementsByClassName('viewer-text-layer');
        // console.log(divToPrint);
        // var newWin = window.open('', 'Print-Window');
        // newWin.document.open();
        // newWin.document.write('<html><body onload="window.print()">' + divToPrint[0].innerHTML + '</body></html>');
        // newWin.document.close();
        // setTimeout(function () { newWin.close(); }, 10);

        window.open(this.state.pdfFile);
    }

    handleScan(result) {
        if (result) {
            this.setState({ result: result, truck: result });
            console.log(result);
        }
    }

    handleError(err) {
        console.error(err);
    }

    openImageDialog() {
        this.refs.qrReader1.openImageDialog();
    }

    openCamera = () => {
        this.setState({
            camera: !this.state.camera
        });
    }

    handleScan2(result) {
        if (result) {
            this.setState({ result: result, truck: result, driver: "" });
            console.log(result);

            this.setState({
                camera: false
            });

            setTimeout(() => {
                this.fillDrivers();
            }, 500);
        }
    }

    handleError2(err) {
        console.error(err);
    }

    showQrCode = () => {
        this.setState({
            modalQRShow: !this.state.modalQRShow,
        });
    }

    showOrHideQRCodePhoto = () => {
        this.setState({
            modalQRShow: !this.state.modalQRShow,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalUpdate = () => {
        this.setState({
            modalUpdate: !this.state.modalUpdate
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    changeButtonUpdate2(row) {
        this.setState({
            modalUpdate: true,
            selectedShipment: row,
            confirmationValue: row.confirmation
        });

        console.log(row);

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onConfirmationChange = value => {
        this.setState({
            confirmationValue: value
        });
    }

    confirmationOK = async () => {
        if (this.state.selectedShipment !== null) {

            var selectedShipment = this.state.selectedShipment;

            const updatedTrip = {
                id: selectedShipment.id,
                flagData: selectedShipment.flagData,
                projectId: selectedShipment.projectId,
                equipmentLogId: selectedShipment.equipmentLogId,
                staffId: selectedShipment.staffId,
                volume: selectedShipment.volume,
                invoiceNo: selectedShipment.invoiceNo,
                invoicePhotoPath: selectedShipment.invoicePhotoPath,
                company: selectedShipment.company,
                checkVolume: selectedShipment.checkVolume,
                ticketNo: selectedShipment.ticketNo,
                ticketPhotoPath: selectedShipment.ticketPhotoPath,
                checkStatus: selectedShipment.checkStatus,
                shipmentDay: selectedShipment.shipmentDay,
                confirmation: this.state.confirmationValue,
                customer: selectedShipment.customer,
                project: selectedShipment.project,
                product: selectedShipment.product,
                voucherPdfPath: selectedShipment.voucherPdfPath,
                truckWeight: selectedShipment.truckWeight
            };

            var responseUpdate = await handleRequest("PUT", `/Shipment/update`, updatedTrip);
            if (responseUpdate.data !== null && responseUpdate.data !== undefined) {
                if (responseUpdate.data === "SUCCESS") {
                    this.showSuccess("All data has been saved successfully.");
                    this.restartTable();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }

        const response = await handleRequest("GET", "/CompanyOrder/getAllByCompany");

        if (response.data.length !== 0) {
            let mainOrderList = response.data;
            let flagOrder = null;

            dance: for (let index = 0; index < mainOrderList.length; index++) {
                if (mainOrderList[index].orders.length !== 0 && mainOrderList[index].orders !== null) {
                    if (parseInt(mainOrderList[index].customerId) === selectedShipment.customer && parseInt(mainOrderList[index].customerProjectId) === selectedShipment.customerProject.id) {
                        for (let index1 = 0; index1 < mainOrderList[index].orders.length; index1++) {
                            if (parseInt(mainOrderList[index].orders[index1].companyServiceId) === selectedShipment.product && parseInt(mainOrderList[index].orders[index1].balance) > 0)
                                flagOrder = mainOrderList[index].orders[index1];
                            break dance;
                        }
                    }
                }
            }

            if (flagOrder !== null) {
                if (this.state.confirmationValue === "Confirmed") {
                    let flagBalance = Number(flagOrder.balance) - Number(selectedShipment.checkVolume);
                    let deliveredQty = Number(flagOrder.deliveredQty) + Number(selectedShipment.checkVolume);

                    const updatedCompanyOrder = {
                        id: flagOrder.id,
                        companyServiceId: selectedShipment.product,
                        quantity: flagOrder.quantity,
                        balance: flagBalance,
                        deliveredQty: deliveredQty,
                    };

                    await handleRequest("POST", "/CompanyOrderList/update", updatedCompanyOrder);
                }

                if (this.state.confirmationValue === "Under Evaluation" || this.state.confirmationValue === "Not Confirmed") {
                    if (Number(flagOrder.deliveredQty) > 0) {
                        let flagBalance = Number(flagOrder.balance) + Number(selectedShipment.checkVolume);
                        let deliveredQty = Number(flagOrder.deliveredQty) - Number(selectedShipment.checkVolume);

                        const updatedCompanyOrder = {
                            id: flagOrder.id,
                            companyServiceId: selectedShipment.product,
                            quantity: flagOrder.quantity,
                            balance: flagBalance,
                            deliveredQty: deliveredQty,
                        };

                        await handleRequest("POST", "/CompanyOrderList/update", updatedCompanyOrder);
                    }
                }
            }
        }

        this.setState({
            modalUpdate: false,
            selectedShipment: null,
            confirmationValue: "Under Evaluation"
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters });
    }

    fetch = async (params = {}) => {
        this.setState({
            loading: true
        });

        const newObj = {
            id: 0,
            firstResult: params.pagination.current,
            maxResults: params.pagination.pageSize,
        }

        var response = await handleRequest("POST", "/Shipment/getShipmentLogPaging", newObj);

        //console.log("AAA", response.data);

        if (response.data.length !== 0) {
            this.setState({
                loading: false,
                shipmentLogs: response.data.data,
                pagination: {
                    ...params.pagination,
                    total: response.data.totalRecords,
                },
            });
        } else {
            this.setState({
                loading: false,
                shipmentLogs: [],
                pagination: {
                    ...params.pagination,
                    total: 0,
                },
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage={msg} />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    render() {
        //const header = this.renderHeader();
        //const confirmationFilter = this.renderConfirmationFilter();
        const previewStyle = { height: 240, width: 320, display: "none" };
        const previewStyle2 = { height: 240, width: 320 };
        const { selectID } = this.state;

        var itemCustomerAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogCustomer");
        var itemProjectAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogProject");
        var itemProductAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogProduct");
        var itemTruckAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogTruck");
        var itemDriverAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogDriver");
        var itemTruckWeightAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogTruckWeight");
        var itemLoadedWeightAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogLoadedWeight");
        var itemWeightbridgeTicketAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogWeightbridgeTicket");
        var itemDeliveryVoucherAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ShipmentLogDeliveryVoucher");

        var itemCustomerRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogCustomer");
        var itemProjectRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogProject");
        var itemProductRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogProduct");
        var itemTruckRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogTruck");
        var itemDriverRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogDriver");
        var itemTruckWeightRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogTruckWeight");
        var itemLoadedWeightRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogLoadedWeight");
        var itemWeightbridgeTicketRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogWeightbridgeTicket");
        var itemDeliveryVoucherRequired = this.state.dynamicInputs.find(p => p.inputKey === "ShipmentLogDeliveryVoucher");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        };

        const howToUseThis = this.state.howToUseThis;

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (

            <div className="datatable-doc-demo">

                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalPhotoShow}
                        onHide={this.showOrHidePhotoModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.imageUrl !== "" ? (
                                <img
                                    src={this.state.imageUrl}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                    <p>Photo has not been uploaded.</p>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHidePhotoModal}>
                                Close
            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="xl"
                        show={this.state.modalPdfViewer}
                        onHide={this.showOrHideModalPdfViewer}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                PDF Report Viewer
               </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                                <Button style={{ marginLeft: "1rem" }}
                                    onClick={this.printPdf}
                                >
                                    Print
             </Button>
                                <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Voucher PDF File Link">
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Voucher PDF File Link is here: " subject="Voucher PDF File Link">
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                                <WhatsappShareButton
                                    style={{ marginLeft: "1rem" }}
                                    url={this.state.pdfFile}
                                    title="Voucher PDF File Link"
                                    separator=": "
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                                <div style={{ height: "900px" }}>
                                    <Viewer fileUrl={this.state.pdfFile} layout={layout1} />
                                </div>
                            </Worker>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalPdfViewer}
                            >
                                Close
               </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalUpdate}
                        onHide={this.showOrHideModalUpdate}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Change Confirmation
                   </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row style={{ marginTop: "10px", width: "100%" }}>
                                <Col xs={6}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Confirmation Status:
                   </Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Select style={{ width: "100%" }} placeholder="Change confirmation" value={this.state.confirmationValue} onChange={this.onConfirmationChange} >
                                        <Option value="Under Evaluation">Under Evaluation</Option>
                                        <Option value="Confirmed">Confirmed</Option>
                                        <Option value="Not Confirmed">Not Confirmed</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalUpdate}> Close </Button>
                            <Button variant="primary" onClick={this.confirmationOK}> Save Confirmation </Button>
                        </Modal.Footer>
                    </Modal>

                    {/*<Modal
          show={this.state.modalQRShow}
          onHide={this.showOrHideQRCodePhoto}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QrReader
              ref="qrReader1"
              delay={this.state.delay}
              style={previewStyle}
              onError={this.handleError}
              onScan={this.handleScan}
              legacyMode
            />
            {/*{this.state.result !== "No result" ? (
              <QrReader
                ref="qrReader1"
                delay={this.state.delay}
                style={previewStyle}
                onError={this.handleError}
                onScan={this.handleScan}
                legacyMode
              />
            ) : (
                <p>QR code has not been uploaded.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHideQRCodePhoto}>
              Close
            </Button>
          </Modal.Footer>
          </Modal> 
        */}

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <div>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        {/*<div style={{ padding: "4px 16px 4px 24px" }}>
              <Row>
                <Col xs={3}>
                  {" "}
                  <Toast ref={(el) => (this.toast = el)} />
                </Col>
                <Col xs={3}></Col>
                <Col xs={3}></Col>
                <Col xs={3}>
                  <Button
                    onClick={this.createNew}
                    style={{ width: "100%" }}
                    variant={this.state.createNewButtonVariant}
                  >
                    {this.state.createNewButton}
                  </Button>
                </Col>
              </Row>
                </div>*/}

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <Button id="ShipmentLogCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></Button>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <Card>
                            <div hidden={this.state.hidden} style={{ marginLeft: "2rem", marginRight: "2rem", marginTop: "1rem" }} >

                                <FormAnt
                                    {...layout}
                                    initialValues={{ remember: false }}
                                    onFinish={this.saveShipment}
                                    onFinishFailed={onFinishFailed}
                                    ref={this.formRef}
                                >

                                    {(itemCustomerAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogCustomer" label={<FormattedMessage id="ShipmentLogCustomer" defaultMessage="Customer" />}
                                            rules={[{ required: itemCustomerRequired.canBeRequired, message: <FormattedMessage id={itemCustomerRequired.translateRequired} defaultMessage="Please select a customer!" /> }]} >
                                            <Select id="ShipmentLogCustomer" getPopupContainer={trigger => trigger.parentElement} style={{ width: "250px" }} placeholder="Customer" value={this.state.customer}
                                                onChange={(value) => {
                                                    this.setState({ customer: value, project: "" });
                                                    setTimeout(() => {
                                                        this.fillProjects();
                                                    }, 500);
                                                }}
                                            > {this.state.customerList.map((i) => (
                                                <Option key={i.id} value={i.id}>
                                                    {i.fullName}
                                                </Option>
                                            ))}
                                            </Select>
                                        </FormAnt.Item>}

                                    {(itemProjectAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogProject" label={<FormattedMessage id="ShipmentLogProject" defaultMessage="Project" />}
                                            rules={[{ required: itemProjectRequired.canBeRequired, message: <FormattedMessage id={itemProjectRequired.translateRequired} defaultMessage="Please select a project!" /> }]} >
                                            <Select id="ShipmentLogProject" getPopupContainer={trigger => trigger.parentElement} style={{ width: "250px" }} placeholder="Project" value={this.state.project}
                                                onChange={(value) => {
                                                    this.setState({ project: value, product: "" });
                                                    setTimeout(() => {
                                                        this.fillServices();
                                                    }, 500);
                                                }}
                                            > {this.state.projectList.map((i) => (
                                                <Option key={i.id} value={i.id}>
                                                    {i.projectName}
                                                </Option>
                                            ))}
                                            </Select>
                                        </FormAnt.Item>}

                                    {(itemProductAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogProduct" label={<FormattedMessage id="ShipmentLogProduct" defaultMessage="Product" />}
                                            rules={[{ required: itemProductRequired.canBeRequired, message: <FormattedMessage id={itemProductRequired.translateRequired} defaultMessage="Please select a product!" /> }]} >
                                            <Select id="ShipmentLogProduct" getPopupContainer={trigger => trigger.parentElement} style={{ width: "250px" }} placeholder="Product" value={this.state.product}
                                                onChange={(value) => {
                                                    this.setState({ product: value });
                                                }}
                                            > {this.state.productList.map((i) => (
                                                <Option
                                                    key={i.companyService.id}
                                                    value={i.companyService.id}
                                                >
                                                    {i.companyService.description}
                                                </Option>
                                            ))}
                                            </Select>
                                        </FormAnt.Item>}

                                    {(itemTruckAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogTruck" label={<FormattedMessage id="ShipmentLogTruck" defaultMessage="Truck" />}
                                            rules={[{ required: itemTruckRequired.canBeRequired, message: <FormattedMessage id={itemTruckRequired.translateRequired} defaultMessage="Please select a truck!" /> }]} >
                                            <Select id="ShipmentLogTruck" getPopupContainer={trigger => trigger.parentElement} style={{ width: "250px" }} placeholder="Truck" value={this.state.truck}
                                                onChange={(value) => {
                                                    this.setState({ truck: value, driver: "" });
                                                    setTimeout(() => {
                                                        this.fillDrivers();
                                                    }, 500);
                                                }}
                                            > {this.state.truckList.map((i) => (
                                                <Option key={i.id} value={i.id}>
                                                    {i.equipmentDescription} - {i.plateNo}
                                                </Option>
                                            ))}
                                            </Select>
                                        </FormAnt.Item>}

                                    <Row style={{ marginBottom: "1rem" }}>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                            <Button id="ShipmentLogQRCodeReaderButton" style={{ marginTop: "1rem" }} variant="primary" onClick={this.openImageDialog}><FormattedMessage id="ShipmentLogQRCodeReaderButton" defaultMessage="QR Code Reader" /></Button>
                                        </Col>
                                        <Col sm="2">
                                            <Button id="ShipmentLogQRCodeScannerButton" style={{ marginTop: "1rem" }} variant="primary" onClick={this.openCamera}><FormattedMessage id="ShipmentLogQRCodeScannerButton" defaultMessage="QR Code Scanner" /></Button>
                                        </Col>
                                    </Row>

                                    <Row style={{ marginBottom: "1rem" }}>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="4">
                                            <QrReader
                                                ref="qrReader1"
                                                delay={this.state.delay}
                                                style={previewStyle}
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                                legacyMode
                                            />
                                            {this.state.camera && <QrReader
                                                ref="qrReader2"
                                                delay={this.state.delay2}
                                                style={previewStyle2}
                                                onError={this.handleError2}
                                                onScan={this.handleScan2}
                                            />
                                            }
                                        </Col>
                                    </Row>

                                    {(itemDriverAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogDriver" label={<FormattedMessage id="ShipmentLogDriver" defaultMessage="Driver" />}
                                            rules={[{ required: itemDriverRequired.canBeRequired, message: <FormattedMessage id={itemDriverRequired.translateRequired} defaultMessage="Please select a driver!" /> }]} >
                                            <Select id="ShipmentLogDriver" getPopupContainer={trigger => trigger.parentElement} style={{ width: "250px" }} placeholder="Driver" value={this.state.driver}
                                                onChange={(value) => {
                                                    this.setState({ driver: value });
                                                }}
                                            > {this.state.driverList.map((i) => (
                                                <Option key={i.staff.id} value={i.staff.id}>
                                                    {i.staff.name + ` ` + i.staff.lastName}
                                                </Option>
                                            ))}
                                            </Select>
                                        </FormAnt.Item>}

                                    {(itemTruckWeightAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogTruckWeight" label={<FormattedMessage id="ShipmentLogTruckWeight" defaultMessage="Truck Weight" />}
                                            rules={[{ required: itemTruckWeightRequired.canBeRequired, message: <FormattedMessage id={itemTruckWeightRequired.translateRequired} defaultMessage="Please input truck weight!" /> }]} >
                                            <InputNumber id="ShipmentLogTruckWeight" min={0} onChange={(value) => { this.setState({ truckWeight: value }); }} value={this.state.truckWeight} />
                                        </FormAnt.Item>}

                                    {(itemLoadedWeightAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogLoadedWeight" label={<FormattedMessage id="ShipmentLogLoadedWeight" defaultMessage="Loaded Weight" />}
                                            rules={[{ required: itemLoadedWeightRequired.canBeRequired, message: <FormattedMessage id={itemLoadedWeightRequired.translateRequired} defaultMessage="Please input loaded weight!" /> }]} >
                                            <InputNumber id="ShipmentLogLoadedWeight" min={0} onChange={(value) => { this.setState({ volume: value }); }} value={this.state.volume} />
                                        </FormAnt.Item>}

                                    {(itemWeightbridgeTicketAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogWeightbridgeTicket" label={<FormattedMessage id="ShipmentLogWeightbridgeTicket" defaultMessage="Weightbridge Ticket" />}
                                            rules={[{ required: itemWeightbridgeTicketRequired.canBeRequired, message: <FormattedMessage id={itemWeightbridgeTicketRequired.translateRequired} defaultMessage="Please input weightbridge ticket!" /> }]} >
                                            <Input id="ShipmentLogWeightbridgeTicket" style={{ width: "250px" }} value={this.state.ticketNo} onChange={(e) => this.setState({ ticketNo: e.target.value })} />
                                            {" "}
                                            <Upload name="avatar" showUploadList={true} action={`${API_BASE}/Shipment/upload`} onChange={this.uploadTicket}>
                                                <ButtonA> <UploadOutlined />
                                                    Upload
                        </ButtonA>
                                            </Upload>
                                        </FormAnt.Item>}

                                    {(itemDeliveryVoucherAttributes.visible) &&
                                        <FormAnt.Item name="ShipmentLogDeliveryVoucher" label={<FormattedMessage id="ShipmentLogDeliveryVoucher" defaultMessage="Delivery Voucher" />}
                                            rules={[{ required: itemDeliveryVoucherRequired.canBeRequired, message: <FormattedMessage id={itemDeliveryVoucherRequired.translateRequired} defaultMessage="Please input weightbridge ticket!" /> }]} >
                                            <Input id="ShipmentLogDeliveryVoucher" style={{ width: "250px" }} value={this.state.invoiceNo} onChange={(e) => this.setState({ invoiceNo: e.target.value })} />
                                            {" "}
                                            <Upload name="avatar" showUploadList={true} action={`${API_BASE}/Shipment/upload`} onChange={this.uploadInvoice}>
                                                <ButtonA> <UploadOutlined />
                                                    Upload
                          </ButtonA>
                                            </Upload>
                                        </FormAnt.Item>}

                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <Row style={{ marginBottom: "1rem" }}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                <Button id="ShipmentLogSaveButton" type="submit" style={{ width: '100%' }} variant="success" onClick={this.saveShipment}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </FormAnt>

                                {/*<Container style={{ maxWidth: "none", marginBottom: "15px" }}>
                  <Form>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                      controlId="controlCompanyFullName"
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Customer
                  </Form.Label>
                      <Col sm="4">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Customer"
                          value={this.state.customer}
                          onChange={(value) => {
                            this.setState({ customer: value, project: "" });
                            setTimeout(() => {
                              this.fillProjects();
                            }, 500);
                          }}
                        >
                          {this.state.customerList.map((i) => (
                            <Option key={i.id} value={i.id}>
                              {i.fullName}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                      controlId="controlCompanyFullName"
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Project
                  </Form.Label>
                      <Col sm="4">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Project"
                          value={this.state.project}
                          onChange={(value) => {
                            this.setState({ project: value, product: "" });
                            setTimeout(() => {
                              this.fillServices();
                            }, 500);
                          }}
                        >
                          {this.state.projectList.map((i) => (
                            <Option key={i.id} value={i.id}>
                              {i.projectName}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                      controlId="controlCompanyFullName"
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Product
                  </Form.Label>
                      <Col sm="4">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Product"
                          value={this.state.product}
                          onChange={(value) => {
                            this.setState({ product: value });
                          }}
                        >
                          {this.state.productList.map((i) => (
                            <Option
                              key={i.companyService.id}
                              value={i.companyService.id}
                            >
                              {i.companyService.description}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "0rem" }}
                      as={Row}
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Truck
                  </Form.Label>
                      <Col sm="4">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Truck"
                          value={this.state.truck}
                          onChange={(value) => {
                            this.setState({ truck: value, driver: "" });
                            setTimeout(() => {
                              this.fillDrivers();
                            }, 500);
                          }}
                        >
                          {this.state.truckList.map((i) => (
                            <Option key={i.id} value={i.id}>
                              {i.equipmentDescription} - {i.plateNo}
                            </Option>
                          ))}
                        </Select>
                      </Col>*/}
                                {/*<Col sm="3" >
                        <Button style={{ textAlign: "left" }} onClick={this.showQrCode} >
                          Show Uploaded QR Code
                        </Button>
                          </Col>*/}
                                {/*</Form.Group>
                    <Form.Group as={Row} style={{ marginBottom: "0rem" }} >
                      <Col sm="3"></Col>
                      <Col sm="2" style={{ marginTop: "1rem" }}>
                        <Button onClick={this.openImageDialog} >
                          QR Code Reader
                        </Button>
                      </Col>
                      <Col sm="2" style={{ marginTop: "1rem" }}>
                        <Button onClick={this.openCamera} >
                          QR Code Scanner
                        </Button>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      as={Row}
                    >
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <QrReader
                          ref="qrReader1"
                          delay={this.state.delay}
                          style={previewStyle}
                          onError={this.handleError}
                          onScan={this.handleScan}
                          legacyMode
                        />
                        {this.state.camera && <QrReader
                          ref="qrReader2"
                          delay={this.state.delay2}
                          style={previewStyle2}
                          onError={this.handleError2}
                          onScan={this.handleScan2}
                        />
                        }
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                      controlId="controlCompanyFullName"
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Driver
                  </Form.Label>
                      <Col sm="4">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Driver"
                          value={this.state.driver}
                          onChange={(value) => {
                            this.setState({ driver: value });
                          }}
                        >
                          {this.state.driverList.map((i) => (
                            <Option key={i.staff.id} value={i.staff.id}>
                              {i.staff.name + ` ` + i.staff.lastName}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Truck Weight
                    </Form.Label>
                      <Col sm="4">
                        <InputNumber
                          min={0}
                          onChange={(value) => {
                            this.setState({ truckWeight: value });
                          }}
                          value={this.state.truckWeight}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Loaded Weight
                  </Form.Label>
                      <Col sm="4">
                        <InputNumber
                          min={0}
                          onChange={(value) => {
                            this.setState({ volume: value });
                          }}
                          value={this.state.volume}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Weightbridge Ticket
                  </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          value={this.state.ticketNo}
                          onChange={(e) =>
                            this.setState({ ticketNo: e.target.value })
                          }
                        />
                      </Col>
                      <Col sm="2">
                        {" "}
                        <Upload
                          name="avatar"
                          showUploadList={true}
                          action={`${API_BASE}/Shipment/upload`}
                          onChange={this.uploadTicket}
                        >
                          <ButtonA>
                            <UploadOutlined />
                        Upload
                      </ButtonA>
                        </Upload>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                      controlId="controlCompanyFullName"
                    >
                      <Form.Label style={{ color: "black" }} column sm="3">
                        Delivery Voucher
                  </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          value={this.state.invoiceNo}
                          onChange={(e) =>
                            this.setState({ invoiceNo: e.target.value })
                          }
                        />
                      </Col>
                      <Col sm="2">
                        {" "}
                        <Upload
                          name="avatar"
                          showUploadList={true}
                          action={`${API_BASE}/Shipment/upload`}
                          onChange={this.uploadInvoice}
                        >
                          <ButtonA>
                            <UploadOutlined />
                        Upload
                      </ButtonA>
                        </Upload>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                      controlId="controlVision"
                    >
                      <Col sm="3"></Col>
                      <Col sm="4">
                        <Button
                          type="button"
                          style={{ width: "100%" }}
                          onClick={this.saveShipment}
                          variant={this.state.lastButtonVariant}
                        >
                          {" "}
                          {this.state.lastButton}
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Container>*/}
                            </div>

                            <div>
                                <Table size={`small`} dataSource={this.state.shipmentLogs} pagination={this.state.pagination}
                                    scroll={{ x: 1300, scrollToFirstRowOnChange: true }} responsive striped bordered hover
                                    loading={this.state.loading} onChange={this.handleTableChange} >
                                    <Column width='50px' title={<FormattedMessage id="GeneralIndexNo" defaultMessage="S/N" />} dataIndex="index" />
                                    <Column title={<FormattedMessage id="GeneralDate" defaultMessage="Date" />} dataIndex="shipmentDay" />
                                    <Column title={<FormattedMessage id="ShipmentLogTableCustomer" defaultMessage="Customer" />} render={(text, record) => {
                                        if (record.customerLog !== undefined)
                                            if (record.customerLog !== null)
                                                return record.customerLog.fullName;
                                            else return "";
                                        else return "";
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableProject" defaultMessage="Project" />} render={(text, record) => {
                                        if (record.customerProject !== undefined)
                                            if (record.customerLog !== null)
                                                return record.customerProject.projectName;
                                            else return "";
                                        else return "";
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableLocation" defaultMessage="Location" />} dataIndex="location" />
                                    <Column title={<FormattedMessage id="ShipmentLogTableProduct" defaultMessage="Product" />} render={(text, record) => {
                                        if (record.companyService !== undefined)
                                            if (record.companyService !== null)
                                                return record.companyService.description;
                                            else return "";
                                        else return "";
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableProductCode" defaultMessage="Product Code" />} render={(text, record) => {
                                        if (record.companyService !== undefined)
                                            if (record.companyService !== null)
                                                return record.companyService.code;
                                            else return "";
                                        else return "";
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableTruck" defaultMessage="Truck" />} render={(text, record) => {
                                        if (record.equipmentLog !== undefined)
                                            if (record.equipmentLog !== null)
                                                return record.equipmentLog.equipmentDescription;
                                            else return "";
                                        else return "";
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableDriver" defaultMessage="Driver" />} render={(text, record) => {
                                        if (record.staff !== undefined)
                                            if (record.staff !== null)
                                                return record.staff.name + " " + record.staff.lastName;
                                            else return "";
                                        else return "";
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableLoadQty" defaultMessage="Load Qty" />} dataIndex="volume" />
                                    <Column title={<FormattedMessage id="ShipmentLogTableLTicketNo" defaultMessage="L. Ticket No" />} render={(text, record) => {
                                        if (record.voucherPdfPath !== "" && record.voucherPdfPath !== null) {
                                            return (
                                                <React.Fragment>
                                                    <div style={{
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                        fontWeight: "bold",
                                                    }}
                                                        onClick={(e) => this.showVoucherReport(record)}>
                                                        {record.invoiceNo}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        } else if (record.invoicePhotoPath !== "" && record.invoicePhotoPath !== null) {
                                            return (
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                        fontWeight: "bold",
                                                    }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Shipment/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    }
                                                >
                                                    {record.invoiceNo}
                                                </div>
                                            );
                                        } else {
                                            if (record.invoiceNo === null)
                                                return "";
                                            else
                                                return record.invoiceNo;
                                        }
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableCheckedQty" defaultMessage="Checked Qty" />} render={(text, record) => {
                                        if (record.ticketNo === null)
                                            return "";
                                        else
                                            return record.checkVolume;
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableCTicketNo" defaultMessage="C. Ticket No" />} render={(text, record) => {
                                        if (record.ticketPhotoPath === "" || record.ticketPhotoPath === null) {
                                            if (record.ticketNo === null) return "";
                                            else return record.ticketNo;
                                        } else {
                                            return (
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                        fontWeight: "bold",
                                                    }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Shipment/download2/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    }
                                                >
                                                    {record.ticketNo}
                                                </div>
                                            );
                                        }
                                    }} />
                                    <Column title={<FormattedMessage id="ShipmentLogTableConfirmation" defaultMessage="Confirmation" />} render={(text, record) => {
                                        if (record.confirmation === "Confirmed") {
                                            return <p onClick={() => this.changeButtonUpdate2(record)} style={{ marginBottom: "0px", textAlign: "center", color: "MediumSeaGreen", cursor: "pointer" }}>{record.confirmation}</p>
                                        } else if (record.confirmation === "Not Confirmed") {
                                            return <p onClick={() => this.changeButtonUpdate2(record)} style={{ marginBottom: "0px", textAlign: "center", color: "Tomato", cursor: "pointer" }}>{record.confirmation}</p>
                                        } else if (record.confirmation === "Under Evaluation") {
                                            return <p onClick={() => this.changeButtonUpdate2(record)} style={{ marginBottom: "0px", textAlign: "center", color: "Orange", cursor: "pointer" }}>{record.confirmation}</p>
                                        }
                                    }} />
                                </Table>
                            </div>
                        </Card>
                    </div>
                    }
               </MenuProvider> 
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        i18n: state.i18n,
        auth: state.auth,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentLog);
