
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Select, Table, InputNumber, Checkbox } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import VendorEvaluationTemplate from "./VendorEvaluationTemplate";
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { useContext, useState, useEffect, useRef } from 'react';
import moment from 'moment'

const { Option } = Select;

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && record.childDurum) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing && record.childDurum ? (
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} min={0} max={record.weight} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
        record.itemGroup ?
          <div
            className="editable-cell-value-wrap"
            style={{
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "larger",
            }}
            onClick={toggleEdit}
          >
            {children}
          </div> :

          record.childDurum ?
            <div
              className="editable-cell-value-wrap"
              style={{
                fontWeight: "lighter",
                textAlign: "left",
                fontSize: "smaller",
                color: "red",
                // border: '1px solid red',
              }}
              onClick={toggleEdit}
            >
              {children}
            </div> :
            <div
              className="editable-cell-value-wrap"
              style={{
                fontWeight: "lighter",
                textAlign: "left",
                fontSize: "smaller",
              }}
              onClick={toggleEdit}
            >
              {children}
            </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.value, lineage: node.data.lineage });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class VendorEvaluation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      vendorEvaluationTable: [],
      labelDescription: "Item Description",
      hiddenItemForVendor: false,
      hiddenParentId: false,
      parentVendorEvaluations: [],
      parentId: 0,
      dtNodes: [],
      values: [],
      selectedRowKeys: [],
      VendorScoring: [],
      VendorScoringAll: [],
      columns: [],
      dates: [],

    };

    this.onChangeCheck = this.onChangeCheck.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    await this.getDynamicMenu();

    this.setStandardizations();
    this.restartTable();
    this.getNodes();
    this.getTableProperties();
  }

  restartTable = async () => {
    this.resetInputs();

    const responseDate = await handleRequest("GET", "/VendorEvaluation/getDates");
    var date = null;
    if (responseDate.data.length === 0) {
      this.setState({
        dates: [],
        date: date,
      });
    }
    if (responseDate.data.length !== 0) {
      date = responseDate.data[0].date;
      this.setState({
        dates: responseDate.data,
        date: date,
      });
    }

    var newObj = {
      date: date,
    }
    const response = await handleRequest("POST", "/VendorEvaluation/getallByCompanyMaxDate", newObj);
    console.log("AAA", response.data);
    if (response.data.data !== undefined) {
      if (response.data.data.length === 0) {
        this.setState({
          vendorEvaluationTable: [],
          parentVendorEvaluations: [],
          selectedRowKeys: [],
        });
      }
      if (response.data.data.length !== 0) {
        var selectedRowKeys = [];
        response.data.data.forEach(element => {
          if (element.hesaplamaDrm) {
            selectedRowKeys.push(element.id);
          }
        });
        this.setState({
          vendorEvaluationTable: response.data.data,
          parentVendorEvaluations: response.data.data.filter(p => p.parentId == 0),
          totalWeight: response.data.totalWeight,
          totalPercent: response.data.totalPercent,
          selectedRowKeys: selectedRowKeys,
        });
      }
    } else {
      this.setState({
        vendorEvaluationTable: [],
        parentVendorEvaluations: [],
        selectedRowKeys: [],
      });

    }

    //this.getVendorScoring();   
  }

  getVendorScoring = async () => {
    var newObj = {
      date: this.state.date,
    }
    if (this.state.date != null && this.state.date != undefined && this.state.date != 0 && this.state.date != "") {
      const response = await handleRequest("POST", "/VendorScoring/getVendorScoring", newObj);
      const responseVendorScoring = await handleRequest("POST", "/VendorScoring/getAllByCompanyDate", newObj);

      console.log("XXX", response.data);
      console.log("YYY", responseVendorScoring.data);

      this.state.vendorEvaluationTable.forEach(element => {
        response.data.forEach(vendorScoring => {
          var veri = responseVendorScoring.data.filter(m => m.vendorEvaluationId == element.id && m.vendorLogId == vendorScoring.vendorLogId);
          if (veri != null && veri != undefined && veri.length != 0) {
            element[vendorScoring.vendorLogId + ""] = veri[0].vendorWeight;
          }
        });
      });

      if (response.data.length !== 0) {
        this.setState({
          VendorScoring: response.data,
          VendorScoringAll: responseVendorScoring.data,
        });
        response.data.forEach(element => {
          if (element.vendorLog != null && element.vendorLog != undefined) {
            this.setState({
              columns: [...this.state.columns, {
                key: "key",
                editable: true,
                title: <Checkbox id={element.vendorLog.id} checked={element.selected} onChange={this.onChangeCheck} >{element.vendorLog.fullName}</Checkbox>,
                dataIndex: element.vendorLog.id + "",
              }
              ]
            });
          }
        });
      }
    }
  }

  onChangeCheck = async (e) => {
    if (this.state.date != null && this.state.date != undefined && this.state.date != "" && this.state.date != 0) {
      const newObj = {
        vendorLogId: e.target.id,
        selected: e.target.checked,
        date: this.state.date
      }
      var response = await handleRequest("POST", "/VendorLog/updateSelected", newObj);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.showSuccess();
        }
        else {
          this.showError();
        }
      } else {
        this.showError();
      }
    } else {
      this.showWarning("Please select your Date!");
    }
  }

  setStandardizations = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "VendorEvaluation" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "VendorEvaluation",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "VendorEvaluation");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
  }

  getDynamicMenu = async () => {
    const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
    if (responseTree.data.length !== 0) {
      var element = responseTree.data.treeTable;

      for (let index = 0; index < element.length; index++) {
        var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

        if (result !== null)
          break;
      }

      this.setState({
        formId: result.data.id
      });

      this.getUserSettings();
    } else {
      this.setState({
        formId: null
      });
    }
  }

  searchTree = (element, matchingTitle) => {
    if (element.data.page == matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  }

  getUserSettings = async () => {
    const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
    if (responseUserSettings.data.length !== 0) {
      this.setState({
        userSettings: responseUserSettings.data,
        howToUseSetting: responseUserSettings.data.howToUseSetting,
        whatIsSetting: responseUserSettings.data.whatIsSetting
      });
    } else {
      this.setState({
        howToUseSetting: false,
        whatIsSetting: false
      });
    }
  }

  getNodes = async () => {
    var response = [];
    response = await handleRequest("GET", "/VendorEvaluation/getNodesVendorEvaluations");
    if (response.data.length !== 0) {
      generateList(response.data);
      this.setState({
        dtNodes: response.data
      });
    }
  }

  getTableProperties = async () => {
    this.vendorEvaluation = JSON.parse(localStorage.getItem('vendorEvaluation'));
    if (this.vendorEvaluation != null && this.vendorEvaluation != undefined && this.vendorEvaluation != "") {
      await this.setState({
        bordered: this.vendorEvaluation.bordered,
        title: this.vendorEvaluation.titleDrm == true ? title : undefined,
        titleDrm: this.vendorEvaluation.titleDrm,
        showHeader: this.vendorEvaluation.showHeader,
        footer: this.vendorEvaluation.footerDrm == true ? footer : undefined,
        footerDrm: this.vendorEvaluation.footerDrm,
        expandable: this.vendorEvaluation.expandableDrm == true ? expandable : {},
        expandableDrm: this.vendorEvaluation.expandableDrm,
        //rowSelection: this.vendorEvaluation.rowSelection,
        ellipsis: this.vendorEvaluation.ellipsis,
        tableLayout: this.vendorEvaluation.tableLayout,
        size: this.vendorEvaluation.size,
        top: this.vendorEvaluation.top,
        bottom: this.vendorEvaluation.bottom,
        editBgColor: this.vendorEvaluation.editBgColor,
        deleteBgColor: this.vendorEvaluation.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.vendorEvaluation.yScroll,
        xScroll: this.vendorEvaluation.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.vendorEvaluationTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.vendorEvaluationTable.length, showQuickJumper: true,
          position: [this.vendorEvaluation.top, this.vendorEvaluation.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        //rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedVendorEvaluation: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  resetInputs = () => {
    this.setState({
      columns: [
        {
          key: "key",
          title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("vendorEvaluation")} icon="pi pi-cog"></PrimeButton>,
          render: (data) => {
            if (data.itemGroup) {
              return (
                <React.Fragment>
                  <div style={{
                    fontWeight: "bold",
                  }}>
                    {data.itemNo}
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <div style={{
                    fontWeight: "lighter",
                  }}>
                    {data.itemNo}
                  </div>
                </React.Fragment>
              );
            }
          }
        },
        {
          key: "key",
          title: <FormattedMessage id="VendorEvaluationItem" defaultMessage="Item" />,
          render: (data) => {
            if (data.itemGroup) {
              return (
                <React.Fragment>
                  <div style={{
                    fontWeight: "bold",
                  }}>
                    {data.description}
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <div style={{
                    fontWeight: "lighter",
                  }}>
                    {data.description}
                  </div>
                </React.Fragment>
              );
            }
          }
        },
        {
          key: "key",
          title: <FormattedMessage id="VendorEvaluationWeight" defaultMessage="Weight" />,
          render: (data) => {
            if (data.itemGroup) {
              return (
                <React.Fragment>
                  <div style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "larger"
                  }}>
                    {data.weight}
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <div style={{
                    fontWeight: "lighter",
                    textAlign: "left",
                    fontSize: "smaller"
                  }}>
                    {data.weight}
                  </div>
                </React.Fragment>
              );
            }
          }
        },
      ],
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  handleChangeVendorEvaluation = (e) => {
    this.setState({
      parentId: e,
    });
  }

  onChangeParent = (value, data) => {
    if (data.length > 0) {
      this.setState({
        values: value,
        parentId: data[data.length - 1].value,
      })
    } else {
      this.setState({
        values: [],
        parentId: 0,
      })
    }
  }

  editTemplateClick = async () => {
    this.setState({
      modalVendorEvaluationTemplate: true,
    });
  }

  showOrHideModalVendorEvaluationTemplate = () => {
    if (this.state.modalVendorEvaluationTemplate) {
      this.restartTable();
    }
    this.setState({
      modalVendorEvaluationTemplate: !this.state.modalVendorEvaluationTemplate,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onSelectChange = selectedRowKeys => {
    console.log("selectedRowKeys", selectedRowKeys);
    if (selectedRowKeys.length > 1) {
      const lastSelectedRowIndex = [...selectedRowKeys].pop();
      this.setState({ selectedRowKeys: lastSelectedRowIndex });
    }
    this.setState({ selectedRowKeys });
    this.hesaplaPercent(selectedRowKeys);
  };

  hesaplaPercent = async (selectedRowKeys) => {
    var selected = [];
    this.state.vendorEvaluationTable.forEach(element => {
      var select = selectedRowKeys.filter(p => p == element.id);
      if (!(select != null && select != undefined && select.length != 0)) {
        element.hesaplamaDrm = false;
        selected.push(element);
      } else {
        element.hesaplamaDrm = true;
        selected.push(element);
      }
    });
    console.log("SSS", selected.filter(p => p.id > 0));
    var response = await handleRequest("POST", "/VendorEvaluation/hesaplaPercent", selected.filter(p => p.id > 0));
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.showSuccess();
        this.restartTable();
      }
      else {
        this.showError();
      }
    } else {
      this.showError();
    }
  }

  handleSave = (row) => {
    const newData = [...this.state.vendorEvaluationTable];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      vendorEvaluationTable: newData,
    });

    this.saveAllVendorScoring()
  };

  saveAllVendorScoring = async () => {
    if (this.state.date != null && this.state.date != undefined) {
      var dizi = [];
      var VendorScoringAll = this.state.VendorScoringAll;
      this.state.vendorEvaluationTable.filter(p => this.state.selectedRowKeys.includes(p.id)).forEach(element => {
        var keyList = this.state.VendorScoring;
        keyList.forEach(key => {
          var obj = VendorScoringAll.find(m => m.vendorLog.id == key.vendorLogId && m.vendorEvaluationId == element.id);
          if (Boolean(obj)) {
            const newObj = {
              id: obj.id,
              vendorWeight: parseInt(element[key.vendorLogId + ""]),
              vendorLogId: obj.vendorLogId,
              vendorEvaluationId: element.id,
              date: this.state.date,
            }
            dizi.push(newObj);
          }
        });
      });
      console.log("DDD", dizi);
      if (dizi != null && dizi.length > 0) {
        var response = await handleRequest("POST", "/VendorScoring/updateAll", dizi);
        if (response.data) {
          if (response.data === "ERROR") {
            this.showError();
          } else if (response.data === "SUCCESS") {
            //this.showSuccess();
            this.restartTable();
          }
        }
      } else {
        this.showWarning();
      }
    } else {
      this.showWarning();
    }
  }

  onChangeDate = async (value) => {
    console.log("VVVV", value);
    this.setState({
      date: value,
    });
    this.resetInputs();

    var newObj = {
      date: value
    }

    const response = await handleRequest("POST", "/VendorEvaluation/getallByCompanyMaxDate", newObj);
    console.log("AAA", response.data);
    if (response.data.data.length === 0) {
      this.setState({
        vendorEvaluationTable: [],
        parentVendorEvaluations: [],
        selectedRowKeys: [],
      });
    }
    if (response.data.data.length !== 0) {
      var selectedRowKeys = [];
      response.data.data.forEach(element => {
        if (element.hesaplamaDrm) {
          selectedRowKeys.push(element.id);
        }
      });
      this.setState({
        vendorEvaluationTable: response.data.data,
        parentVendorEvaluations: response.data.data.filter(p => p.parentId == 0),
        totalWeight: response.data.totalWeight,
        totalPercent: response.data.totalPercent,
        selectedRowKeys: selectedRowKeys,
      });
    }
    //this.getVendorScoring();  
  }

  render() {

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <MenuProvider id="menu_id">
            <div>
              <Row>
                <Col sm="1">
                  <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>
                </Col>
                <Col sm="10">
                </Col>
                <Col sm="1" style={{ textAlign: "right" }}>
                  {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>
                  }
                </Col>
              </Row>
            </div>
            <br />
            <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
              <FormBoot.Label style={{ color: 'black' }} column sm="1">
                <FormattedMessage id="VendorEvaluationDate" defaultMessage="Date" />
              </FormBoot.Label>
              <Col sm="3">
                <Select size="large" mode="single" style={{ width: '100%' }} value={(this.state.date!=null && this.state.date!=undefined && this.state.date!="") ? moment(this.state.date).format("DD-MM-YYYY HH:mm:ss") : ""}
                  onChange={this.onChangeDate}>
                  {this.state.dates.map(i => (
                    (i.date != null && i.date != undefined && i.date != "") &&
                    <Option key={i.date} value={i.date}>{moment(i.date).format("DD-MM-YYYY HH:mm:ss")}</Option>
                  ))}
                </Select>
              </Col>
              <Col sm="5">
              </Col>
              <Col sm="3">
                <Button id="MRChecklistEditTemplateButton"
                  style={{ width: '100%' }} onClick={this.editTemplateClick}
                  variant="info"><FormattedMessage id="MRChecklistEditTemplateButton" defaultMessage="Edit Template" /></Button>
              </Col>
            </FormBoot.Group>
            <br />

            <Form
              {...layout}
              initialValues={{ remember: false }}
              onFinish={this.state.hiddenUpdate == false ? this.updateVendorEvaluation : this.saveVendorEvaluation}
              onFinishFailed={onFinishFailed}
              ref={this.formRef} >

              <Row>
                <Col xs={11}></Col>
                <Col xs={1}>
                  <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("vendorEvaluation")} icon="pi pi-cog"></PrimeButton>
                </Col>
              </Row>
              <br />

              <div className="datatable-doc-demo">
                <div className="content-section implementation">
                  <Table
                    rowSelection={rowSelection}
                    state={this.state}
                    dataSource={this.state.vendorEvaluationTable}
                    pagination={false}
                    bordered={true}
                    expandable={this.state.expandable}
                    footer={this.state.footer}
                    title={this.state.title}
                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    columns={columns}
                  />
                </div>
              </div>
              <br />
            </Form>
         </MenuProvider>
        }
        {!this.state.whatIsSetting && <Whatis  ></Whatis>
        }

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteVendorEvaluationOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalVendorEvaluationTemplate}
          onHide={this.showOrHideModalVendorEvaluationTemplate}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="VendorEvaluationTemplateHeader" defaultMessage="Vendor Evaluation Template" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VendorEvaluationTemplate date={this.state.date} />
          </Modal.Body>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(VendorEvaluation)