import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, InputNumber } from 'antd';
import QRCode from 'qrcode.react';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';

const pageName = "TimelineQRGenerator";

export class TimelineQRGenerator extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            qrCodeList: [],
            qrCode: "",
            qrCodeSize: 200,
            qrCodeFile: "",
            qrCodeDate: "",
            printedById: 0,
            columns: [
                {
                    key: "key",
                    title: "Action",
                    render: (value, row, index) => {
                        return this.actionTemplate(row);
                    }
                },
                {
                    key: "key",
                    title: "QR Code",
                    dataIndex: "qrCode"
                },
                {
                    key: "key",
                    title: "Date, time",
                    dataIndex: "qrCodeDate"
                },
                {
                    key: "key",
                    title: "Printed by",
                    render: (data) => {
                        if (data != null && data != undefined) {
                            if (data.user !== undefined && data.user !== null)
                                return data.user.name + " " + data.user.middleName + " " + data.user.lastName;
                        }
                        return "";
                    }
                }
            ],

            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            dynamicAttributes: [],
            formId: 0
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
    }

    getUserSettings = async (formId) => {
        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {
        this.uploadAction();

        var dataUrl = document.getElementById('123456').toDataURL();
        var windowContent = '<!DOCTYPE html>';
        windowContent += '<html>'
        windowContent += '<head><title>Print QR Code</title></head>';
        windowContent += '<body>'
        windowContent += '<img src="' + dataUrl + '">';
        windowContent += '</body>';
        windowContent += '</html>';
        var printWin = window.open('', '', 'width=' + this.state.qrCodeSize.toString() + ',height=' + this.state.qrCodeSize.toString() + '');
        printWin.document.open();
        printWin.document.write(windowContent);
        printWin.document.close();
        printWin.focus();
        printWin.print();
        printWin.close();

        const obj = {
            qrCode: this.state.qrCode,
            qrCodeSize: this.state.qrCodeSize,
            qrCodeFile: `${API_BASE}/File/download/` + this.state.qrCode.toString() + ".png",
            qrCodeDate: this.state.qrCodeDate,
            printedById: this.props.auth.user.id
        }

        var responseAdd = await handleRequest("POST", "/TimelineQRGenerator/add", obj);
        if (responseAdd.data !== null && responseAdd.data !== undefined) {
            if (responseAdd.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
            }
            else {
                this.showError();
            }
        }
    }

    uploadAction = async () => {
        var data = new FormData();
        const canvas = document.getElementById("123456");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

        var blobBin = atob(pngUrl.split(',')[1]);
        var array = [];

        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }

        var file = new Blob([new Uint8Array(array)], { type: 'image/png' });
        data.append("file", file, this.state.qrCode.toString() + ".png");

        await fetch(`${API_BASE}/File/upload`, {
            mode: 'no-cors',
            method: "POST",
            body: data
        }).then(function (res) {
            // if (res.ok) {
            //     console.log(res);
            // } else if (res.status == 401) {
            //     console.log(res);
            // }
        }, function (e) {
            console.log("error file upload");
        });
    }

    getQRCode = (template) => {
        var r = '', ch, n;
        for (var i = 0; i < template.length; i++) {
            ch = template.substr(i, 1);
            if (ch == "n") {
                r += parseInt(Math.random() * 10);
            } else if (ch == "A") {
                r += String.fromCharCode(65 + parseInt(Math.random() * 26));
            } else if (ch == "e") {
                n = parseInt(Math.random() * 36);
                if (n > 9) {
                    r += String.fromCharCode(55 + n);
                } else {
                    r += n;
                }
            } else {
                r += ch;
            }
        }
        return r;
    }

    delete = async () => {
        const obj = {
            id: this.state.qrId
        };
        await handleRequest("POST", "/TimelineQRGenerator/delete", obj);
        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            qrId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successful" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenuDelete row={rowData} onDeleteRow={this.deleteModal.bind(this)} />
        </React.Fragment>;
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/TimelineQRGenerator/getAllByCompany");

        if (response.data.length !== 0) {
            this.setState({
                qrCodeList: response.data,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                qrCodeList: [],
            });
        }
    }

    createNew = () => {
        let _today = new Date();
        var HH = String(_today.getHours()).padStart(2, '0');
        var minute = String(_today.getMinutes()).padStart(2, '0');
        var dd = String(_today.getDate()).padStart(2, '0');
        var mm = String(_today.getMonth() + 1).padStart(2, '0');
        var yyyy = _today.getFullYear();

        let today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
        let qrCode = this.getQRCode('Ann-eeee-AAA-nnA');

        this.formRef.current.setFieldsValue({
            TimelineQRGeneratorSize: 200
        });

        this.setState({
            hideInputs: !this.state.hideInputs,
            qrId: 0,
            qrCode: qrCode,
            qrCodeDate: today,
            qrCodeSize: 200,
            modalDeleteShow: false,
            hideSave: false
        });
    }

    stop = () => {
        this.formRef.current.setFieldsValue({
            TimelineQRGeneratorSize: 200
        });

        this.setState({
            qrId: 0,
            qrCode: "",
            qrCodeSize: 200,
            qrCodeFile: "",
            qrCodeDate: "",
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false
        });
    }

    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            TimelineQRGeneratorSize: 200
        });

        this.setState({
            qrId: 0,
            qrCode: "",
            qrCodeSize: 200,
            qrCodeFile: "",
            qrCodeDate: "",
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    render() {
        var sizeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Size");

        var sizeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Size");

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        };

        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">

                            <Modal
                                show={this.state.modalMessage}
                                onHide={this.showOrHideModalMessage}
                                backdrop="static"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>

                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <Row >
                                            <Col sm="12">
                                                {this.state.messageTip == "Success" &&
                                                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                {this.state.messageTip == "Warning" &&
                                                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                {this.state.messageTip == "Error" &&
                                                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                            </Col>
                                        </Row>
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <Row >
                                        <Col sm="12">
                                            {this.state.message}
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '1rem' }}>
                                    <FormAnt
                                        {...layout}
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {(sizeAttributes.visible) && <FormAnt.Item
                                            label={<FormattedMessage id="TimelineQRGeneratorSize" defaultMessage="Size" />}
                                            name="TimelineQRGeneratorSize"
                                            rules={[{ required: sizeRequired.canBeRequired, message: <FormattedMessage id={Boolean(sizeRequired.translateRequired) == true ? sizeRequired.translateRequired : -1} defaultMessage="Please input a Size!" /> }]}>
                                            <InputNumber id="TimelineQRGeneratorSize" min={200} style={{ width: 200 }}
                                                onChange={(e) => this.setState({ qrCodeSize: e })} value={this.state.qrCodeSize} />
                                        </FormAnt.Item>}

                                        {(sizeAttributes.visible) && <FormAnt.Item
                                            label={<FormattedMessage id="TimelineQRGeneratorQRCodePreview" defaultMessage="QR Code Preview" />}>
                                            <QRCode
                                                id="123456"
                                                value={this.state.qrCode}
                                                size={this.state.qrCodeSize}
                                                level={"L"}
                                            />
                                        </FormAnt.Item>}

                                        <div hidden={this.state.hideSave}>
                                            <Row>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="4">
                                                    <ReactButton id="TimelineQRGeneratorPrintButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                        variant="success" ><FormattedMessage id="GeneralButtonPrint" defaultMessage="Print" /></ReactButton>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Row>
                                        </div>

                                    </FormAnt>
                                </div>

                                <Table style={{ marginTop: '3rem' }} columns={this.state.columns} dataSource={this.state.qrCodeList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }}></Table>

                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis></Whatis>}
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (<div></div>);
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineQRGenerator) 