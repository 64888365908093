/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Button, Modal, Form } from 'react-bootstrap';
import { Card } from "primereact/card";
import { MultiSelect } from 'primereact/multiselect';
import { Row, Col, Cascader, Select, InputNumber, Table, message } from 'antd';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import moment from 'moment';
import $ from 'jquery';

const { Option } = Select;
const { Column, ColumnGroup } = Table;

export class TruckCostCalculation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            values: [],
            nodes: [],
            equipmentLogs: [],
            equipments: [],
            equipmentOptions: [],
            selectedEquipments: [],
            showMessage: false,
            duration: "Km",
            dForOneTrip: "",
            averageSpeed: "",
            yWorkingDays: 300,
            mWorkingDays: 26,
            wWorkingDays: 6,
            dWorkingHours: 10,
            capacity: "",
            capitalCost: "",
            capitalCostCost: "",
            redemptionRate: "",
            diesel: "",
            dieselCost: "",
            maintenance: "",
            maintenanceCost: "",
            repairs: "",
            repairsCost: "",
            insuranceG: "",
            insuranceGCost: "",
            insuranceP: "",
            insurancePCost: "",
            taxes: "",
            taxesCost: "",
            fees: "",
            feesCost: "",
            driverCost: "",
            salary: "",
            food: "",
            accom: "",
            feesDriver: "",
            insurance: "",
            overallCost: "",
            truckCostCalculationList: [],
            costCalculationId: null,
            numberOfTrips: null,
            numberOfTripsWarning: null,
            editedTruckCostCalculationId: 0,
            equipmentTruckCosts: [],
            selectedRow: null,
            useAfterDForTrip: 0,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        };
    }

    componentDidMount = async () => {

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data
            });

            const options7 = responseCustomer.data.map(function (row) {
                return { name: row.fullName.toString(), id: row.id.toString() }
            })

            this.setState({
                customers: options7,
            });
        }

        const responseEquipmentType = await handleRequest("GET", "/EquipmentType/getNodes");

        if (responseEquipmentType.length === 0 || responseEquipmentType.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (responseEquipmentType.data.length !== 0) {
            this.setState({
                nodes: responseEquipmentType.data
            });
        }

        const responseEquipmentLog = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipmentLog.data.length === 0) {
            this.setState({
                equipmentLogs: []
            });
        }

        if (responseEquipmentLog.data.length !== 0) {
            this.setState({
                equipmentLogs: responseEquipmentLog.data,
            });
        }

        const responseEquipmentTruckCosts = await handleRequest("GET", "/EquipmentTruckCost/getAll");

        if (responseEquipmentTruckCosts.data.length === 0) {
            this.setState({
                equipmentTruckCosts: []
            });
        }

        if (responseEquipmentTruckCosts.data.length !== 0) {
            this.setState({
                equipmentTruckCosts: responseEquipmentTruckCosts.data,
            });
        }

        const responseTruckCostCalculation = await handleRequest("GET", "/TruckCostCalculation/getAll");

        if (responseTruckCostCalculation.data.length === 0) {
            this.setState({
                truckCostCalculationList: []
            });
        }

        if (responseTruckCostCalculation.data.length !== 0) {
            this.setState({
                truckCostCalculationList: responseTruckCostCalculation.data,
            });
        }

        // const responseCustomerLog = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        // if (responseCustomerLog.data.length === 0) {
        //     this.setState({
        //         dForOneTrip: ""
        //     });
        // }

        // if (responseCustomerLog.data.length !== 0) {
        //     let averageDForOneTrip = 0;
        //     let totalDistance = 0;
        //     let projectCount = 0;

        //     var rData = responseCustomerLog.data;

        //     for (let index = 0; index < rData.length; index++) {
        //         if (rData[index].projects !== undefined && rData[index].projects !== null)
        //             if (rData[index].projects.length !== 0)
        //                 for (let index1 = 0; index1 < rData[index].projects.length; index1++) {
        //                     if (rData[index].projects[index1].distance !== null) {
        //                         totalDistance += Number(rData[index].projects[index1].distance)
        //                         projectCount++;
        //                     }
        //                 }
        //     }

        //     averageDForOneTrip = totalDistance / projectCount;

        //     this.setState({
        //         dForOneTrip: averageDForOneTrip,
        //         useAfterDForTrip: averageDForOneTrip
        //     });
        // }

    }

    onChange = (value, data) => {
        let equipmentTypeIdFlag = value[value.length - 1];

        if (this.state.equipmentLogs.length === 0) {
            this.setState({
                equipments: [],
                equipmentOptions: []
            });
        } else if (this.state.equipmentLogs.length > 0) {

            let equipmentLogs = this.state.equipmentLogs;
            let options = [];

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    if (equipmentLogs[index].equipmentVehicleLicenceList !== null && equipmentLogs[index].equipmentVehicleLicenceList.length !== 0)
                        options.push({ label: equipmentLogs[index].equipmentDescription.toString() + " - " + equipmentLogs[index].equipmentVehicleLicenceList[0].plateNo.toString(), value: equipmentLogs[index].id.toString() });
                    else
                        options.push({ label: equipmentLogs[index].equipmentDescription.toString(), value: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipments: equipmentLogs,
                equipmentOptions: options
            });
        }

        this.setState({
            values: value,
            selectedItems: data
        })
    }

    onEquipmentChange = async (e) => {

        const _selectedEquipments = e.value;

        this.setState({
            selectedEquipments: _selectedEquipments
        });

        let _equipmentTruckCosts = this.state.equipmentTruckCosts;
        let _selectedEquipmentTruckCosts = [];

        for (let index = 0; index < _equipmentTruckCosts.length; index++) {
            if (_selectedEquipments.includes(_equipmentTruckCosts[index].equipmentLogId)) {
                _selectedEquipmentTruckCosts.push(_equipmentTruckCosts[index]);
            }
        }

        //Diesel hesaplaması için

        const response = await handleRequest("GET", "/FuelRefill/getAll");
        let fuelLogs = response.data;

        let equipmentsLastFuelUnitPrices = new Array(e.value.length).fill(0);

        for (let index = 0; index < _selectedEquipments.length; index++) {
            for (let index1 = 0; index1 < fuelLogs.length; index1++) {
                if (_selectedEquipments[index] === fuelLogs[index1].equipmentLogId) {
                    equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[index1].unitPrice);
                }
            }
        }

        for (let index = 0; index < _selectedEquipments.length; index++) {
            if (equipmentsLastFuelUnitPrices[index] === 0) {
                equipmentsLastFuelUnitPrices[index] = Number(fuelLogs[fuelLogs.length - 1].unitPrice);
            }
        }

        let totalAverageDiesel = 0;

        for (let index = 0; index < equipmentsLastFuelUnitPrices.length; index++) {
            totalAverageDiesel += equipmentsLastFuelUnitPrices[index];
        }

        totalAverageDiesel = (totalAverageDiesel / equipmentsLastFuelUnitPrices.length);

        //Diesel hesaplaması için

        let _averageSpeed = 0;
        let _capacity = 0;
        let _capitalCost = 0;
        let _capitalCostCost = 0;
        let _redemptionRate = 0;
        let _diesel = 0;
        let _dieselCost = 0;
        let _maintenance = 0;
        let _maintenanceCost = 0;
        let _repairs = 0;
        let _repairsCost = 0;
        let _insuranceG = 0;
        let _insuranceGCost = 0;
        let _insuranceP = 0;
        let _insurancePCost = 0;
        let _taxes = 0;
        let _taxesCost = 0;
        let _fees = 0;
        let _feesCost = 0;
        let _driverCost = 0;
        let _salary = 0;
        let _food = 0;
        let _accom = 0;
        let _feesDriver = 0;
        let _insurance = 0;

        let _equipmentLogs = this.state.equipmentLogs;
        let foundedCapacityNumber = 0;

        for (let index = 0; index < _equipmentLogs.length; index++) {
            if (_selectedEquipments.includes(_equipmentLogs[index].id)) {
                if (_equipmentLogs[index].equipmentOperatingList !== null) {
                    _capacity += Number(_equipmentLogs[index].equipmentOperatingList[0].capacity);
                    foundedCapacityNumber++;
                }
            }
        }

        for (let index = 0; index < _selectedEquipmentTruckCosts.length; index++) {
            _averageSpeed += _selectedEquipmentTruckCosts[index].averageSpeed;

            //date of purchasing üzerinden 10 sene geçmişse capital cost hesaplamaya dahil edilmiyor.
            if (_selectedEquipmentTruckCosts[index].dateOfPurchasing !== null) {
                //sonradan eklendiği için null kontrolü yapıldı.
                let flagDate = moment(_selectedEquipmentTruckCosts[index].dateOfPurchasing, 'DD/MM/YYYY');
                flagDate = flagDate._d;
                flagDate.setFullYear(flagDate.getFullYear() + 10);
                let nowDate = new Date();

                if (flagDate > nowDate)
                    _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
            } else {
                _capitalCost += _selectedEquipmentTruckCosts[index].capitalCost;
            }

            _redemptionRate += _selectedEquipmentTruckCosts[index].redemptionRate;
            _diesel += _selectedEquipmentTruckCosts[index].diesel;
            _maintenance += _selectedEquipmentTruckCosts[index].maintenance;
            _repairs += _selectedEquipmentTruckCosts[index].repairs;
            _insuranceG += _selectedEquipmentTruckCosts[index].insuranceG;
            _insuranceP += _selectedEquipmentTruckCosts[index].insuranceP;
            _taxes += _selectedEquipmentTruckCosts[index].taxes;
            _fees += _selectedEquipmentTruckCosts[index].fees;
            _salary += _selectedEquipmentTruckCosts[index].salary;
            _food += _selectedEquipmentTruckCosts[index].food;
            _accom += _selectedEquipmentTruckCosts[index].accom;
            _feesDriver += _selectedEquipmentTruckCosts[index].feesDriver;
            _insurance += _selectedEquipmentTruckCosts[index].insurance;
        }

        if (_selectedEquipments.length !== 0) {
            _capacity = _capacity / foundedCapacityNumber;
            _averageSpeed = _averageSpeed / _selectedEquipmentTruckCosts.length;
            _capitalCost = _capitalCost / _selectedEquipmentTruckCosts.length;
            _redemptionRate = _redemptionRate / _selectedEquipmentTruckCosts.length;
            _diesel = ((_diesel / _selectedEquipmentTruckCosts.length) / 100) * (totalAverageDiesel);
            _maintenance = _maintenance / _selectedEquipmentTruckCosts.length;
            _repairs = _repairs / _selectedEquipmentTruckCosts.length;
            _insuranceG = _insuranceG / _selectedEquipmentTruckCosts.length;
            _insuranceP = _insuranceP / _selectedEquipmentTruckCosts.length;
            _taxes = _taxes / _selectedEquipmentTruckCosts.length;
            _fees = _fees / _selectedEquipmentTruckCosts.length;
            _salary = _salary / _selectedEquipmentTruckCosts.length;
            _food = _food / _selectedEquipmentTruckCosts.length;
            _accom = _accom / _selectedEquipmentTruckCosts.length;
            _feesDriver = _feesDriver / _selectedEquipmentTruckCosts.length;
            _insurance = _insurance / _selectedEquipmentTruckCosts.length;
        }

        this.setState({
            averageSpeed: _averageSpeed,
            capacity: _capacity,
            capitalCost: _capitalCost,
            capitalCostCost: _capitalCostCost,
            redemptionRate: _redemptionRate,
            diesel: _diesel,
            dieselCost: _dieselCost,
            maintenance: _maintenance,
            maintenanceCost: _maintenanceCost,
            repairs: _repairs,
            repairsCost: _repairsCost,
            insuranceG: _insuranceG,
            insuranceGCost: _insuranceGCost,
            insuranceP: _insuranceP,
            insurancePCost: _insurancePCost,
            taxes: _taxes,
            taxesCost: _taxesCost,
            fees: _fees,
            feesCost: _feesCost,
            driverCost: _driverCost,
            salary: _salary,
            food: _food,
            accom: _accom,
            feesDriver: _feesDriver,
            insurance: _insurance,
        });
    }

    onChangedDForOneTrip = (value) => {
        if (this.state.averageSpeed !== "" && this.state.dWorkingHours !== "" && value !== "") {
            let NoT = ((Number(value) * 2) / Number(this.state.averageSpeed)) / Number(this.state.dWorkingHours);

            if (NoT > 1) {
                this.setState({
                    numberOfTrips: 1,
                    numberOfTripsWarning: parseInt(NoT),
                    showMessage: true
                });
            } else {
                this.setState({
                    numberOfTrips: parseInt(Number(this.state.dWorkingHours) / ((Number(value) * 2) / Number(this.state.averageSpeed))),
                    showMessage: false
                });
            }

            setTimeout(() => {
                this.calculateDriverCost();
            }, 500);
        }

        this.setState({
            dForOneTrip: value
        });
    }

    onChangeAverageSpeed = (value) => {
        if (this.state.dForOneTrip !== "" && this.state.dWorkingHours !== "" && value !== "") {
            let NoT = ((Number(this.state.dForOneTrip) * 2) / Number(value)) / Number(this.state.dWorkingHours);

            if (NoT > 1) {
                this.setState({
                    numberOfTrips: 1,
                    numberOfTripsWarning: parseInt(NoT),
                    showMessage: true
                });
            } else {
                this.setState({
                    numberOfTrips: parseInt(Number(this.state.dWorkingHours) / ((Number(this.state.dForOneTrip) * 2) / Number(value))),
                    showMessage: false
                });
            }

            setTimeout(() => {
                this.calculateDriverCost();
            }, 500);
        }

        this.setState({
            averageSpeed: value
        });
    }

    onChangeYWorkingDays = (value) => {
        this.setState({
            yWorkingDays: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    onChangeMWorkingDays = (value) => {
        this.setState({
            mWorkingDays: value
        });
    }

    onChangeWWorkingDays = (value) => {
        this.setState({
            wWorkingDays: value
        });
    }

    onChangeDWorkingHours = (value) => {
        if (this.state.dForOneTrip !== "" && this.state.averageSpeed !== "" && value !== "") {
            let NoT = ((Number(this.state.dForOneTrip) * 2) / Number(this.state.averageSpeed)) / Number(value);

            if (NoT > 1) {
                this.setState({
                    numberOfTrips: 1,
                    numberOfTripsWarning: parseInt(NoT),
                    showMessage: true
                });
            } else {
                this.setState({
                    numberOfTrips: parseInt(Number(value) / ((Number(this.state.dForOneTrip) * 2) / Number(this.state.averageSpeed))),
                    showMessage: false
                });
            }

            setTimeout(() => {
                this.calculateDriverCost();
            }, 500);
        }

        this.setState({
            dWorkingHours: value
        });
    }

    onChangeCapacity = (value) => {
        this.setState({
            capacity: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    onChangeCapitalCost = (value) => {
        this.setState({
            capitalCost: value
        });
    }

    onChangeRedemptionRate = (value) => {
        this.setState({
            redemptionRate: value
        });
    }

    onChangeDiesel = (value) => {
        this.setState({
            diesel: value
        });
    }

    onChangeMaintenance = (value) => {
        this.setState({
            maintenance: value
        });
    }

    onChangeRepairs = (value) => {
        this.setState({
            repairs: value
        });
    }

    onChangeInsuranceG = (value) => {
        this.setState({
            insuranceG: value
        });
    }

    onChangeInsuranceP = (value) => {
        this.setState({
            insuranceP: value
        });
    }

    onChangeTaxes = (value) => {
        this.setState({
            taxes: value
        });
    }

    onChangeFees = (value) => {
        this.setState({
            fees: value
        });
    }

    onChangeSalary = (value) => {
        this.setState({
            salary: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    onChangeFood = (value) => {
        this.setState({
            food: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    onChangeAccom = (value) => {
        this.setState({
            accom: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    onChangeFeesDriver = (value) => {
        this.setState({
            feesDriver: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    onChangeInsurance = (value) => {
        this.setState({
            insurance: value
        });

        setTimeout(() => {
            this.calculateDriverCost();
        }, 500);
    }

    deleteModal = (row) => {
        this.setState({
            costCalculationId: row.id,
            modalShow: true
        });
    }

    delete = async () => {
        const deletedCalculation = {
            id: this.state.costCalculationId
        }

        await handleRequest("POST", "/TruckCostCalculation/delete", deletedCalculation);

        this.restartTable();
        message.success(`The operation is successful.`);
        this.showOrHideModal();
    }

    showOrHideModal = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    edit = async (rowData) => {

        var flagValues = [];
        var flagEquipmentTypeId = null;

        if (rowData.equipmentType !== null) {

            flagEquipmentTypeId = rowData.equipmentType.id;

            flagValues = getParents(this.state.nodes, rowData.equipmentType.id).parents;

            flagValues.push(rowData.equipmentType.id);

            function getParentsHelper(tree, id, parents) {

                if (tree.data.id == id) {
                    return {
                        found: true,
                        parents: parents
                    };
                }
                var result = {
                    found: false,
                }
                if (tree.children) {
                    $.each(tree.children, function (index, subtree) {
                        var maybeParents = $.merge([], parents);
                        if (tree.data.id != undefined) {
                            maybeParents.push(tree.data.id);
                        }
                        var maybeResult = getParentsHelper(subtree, id, maybeParents);
                        if (maybeResult.found) {
                            result = maybeResult;
                            return false;
                        }
                    });
                }
                return result;
            }

            function getParents(data1, id) {
                var tree = {
                    data: {},
                    children: data1
                }
                return getParentsHelper(tree, id, []);
            }
        }

        let equipmentTypeIdFlag = flagEquipmentTypeId;
        let equipmentLogs = this.state.equipmentLogs;

        console.log(equipmentLogs);

        if (equipmentLogs.length === 0 || flagEquipmentTypeId === null) {
            this.setState({
                equipmentOptions: []
            });
        } else if (equipmentLogs.length !== 0) {
            let options = [];
            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    if (equipmentLogs[index].equipmentVehicleLicenceList !== null && equipmentLogs[index].equipmentVehicleLicenceList.length !== 0)
                        options.push({ label: equipmentLogs[index].equipmentDescription.toString() + " - " + equipmentLogs[index].equipmentVehicleLicenceList[0].plateNo.toString(), value: equipmentLogs[index].id.toString() });
                    else
                        options.push({ label: equipmentLogs[index].equipmentDescription.toString(), value: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipmentOptions: options
            });
        }

        let selectedEquipments = [];

        for (let index = 0; index < rowData.truckCostCalculationEquipmentList.length; index++) {
            selectedEquipments.push(rowData.truckCostCalculationEquipmentList[index].equipmentLogId)
        }

        this.setState({
            selectedRow: rowData,
            values: flagValues,
            selectedEquipments: selectedEquipments,
            duration: rowData.perChoice,
            dForOneTrip: rowData.dForOneTrip,
            averageSpeed: rowData.averageSpeed,
            yWorkingDays: rowData.yWorkingDays,
            mWorkingDays: rowData.mWorkingDays,
            wWorkingDays: rowData.wWorkingDays,
            dWorkingHours: rowData.dWorkingHours,
            capacity: rowData.capacity,
            capitalCost: rowData.capitalCost,
            capitalCostCost: rowData.capitalCostCost,
            redemptionRate: rowData.redemptionRate,
            diesel: rowData.diesel,
            dieselCost: rowData.dieselCost,
            maintenance: rowData.maintenance,
            maintenanceCost: rowData.maintenanceCost,
            repairs: rowData.repairs,
            repairsCost: rowData.repairsCost,
            insuranceG: rowData.insuranceG,
            insuranceGCost: rowData.insuranceGCost,
            insuranceP: rowData.insuranceP,
            insurancePCost: rowData.insurancePCost,
            taxes: rowData.taxes,
            taxesCost: rowData.taxesCost,
            fees: rowData.fees,
            feesCost: rowData.feesCost,
            driverCost: rowData.driverCost,
            salary: rowData.salary,
            food: rowData.food,
            accom: rowData.accom,
            feesDriver: rowData.feesDriver,
            insurance: rowData.insurance,
            overallCost: rowData.overallCost,
            editedTruckCostCalculationId: rowData.id,
            lastButtonName: "Update",
            lastButtonVariant: "warning"
        });
    }

    calculateCapitalCost = () => {
        if (this.state.duration === "Year") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "") {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate);
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "") {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate) / 12;
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "") {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate) / 52;
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "" && this.state.yWorkingDays !== "") {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate) / Number(this.state.yWorkingDays);
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.capitalCost !== "" && this.state.redemptionRate !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let capitalCost = Number(this.state.capitalCost) / Number(this.state.redemptionRate) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    capitalCostCost: capitalCost.toString()
                });
            }
        }
    }

    calculateDiesel = () => {
        if (this.state.duration === "Year") {
            if (this.state.diesel !== "" && this.state.dForOneTrip !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.yWorkingDays !== "") {
                let dieselCost = Number(this.state.diesel) * 2 * Number(this.state.dForOneTrip) * Number(this.state.numberOfTrips) * Number(this.state.yWorkingDays);
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.diesel !== "" && this.state.dForOneTrip !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.mWorkingDays !== "") {
                let dieselCost = Number(this.state.diesel) * 2 * Number(this.state.dForOneTrip) * Number(this.state.numberOfTrips) * Number(this.state.mWorkingDays);
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.diesel !== "" && this.state.dForOneTrip !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.wWorkingDays !== "") {
                let dieselCost = Number(this.state.diesel) * 2 * Number(this.state.dForOneTrip) * Number(this.state.numberOfTrips) * Number(this.state.wWorkingDays);
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.diesel !== "" && this.state.dForOneTrip !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.wWorkingDays !== "") {
                let dieselCost = Number(this.state.diesel) * 2 * Number(this.state.dForOneTrip) * Number(this.state.numberOfTrips);
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.diesel !== "" && this.state.dForOneTrip !== "") {
                let dieselCost = Number(this.state.diesel) * 2 * Number(this.state.dForOneTrip);
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            //TODO: Bu kısım Fuel Log sayfasından 
            if (this.state.diesel !== "") {
                let dieselCost = Number(this.state.diesel);
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === " Ton") {
            if (this.state.diesel !== "" && this.state.dForOneTrip !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let dieselCost = Number(this.state.diesel) * 2 * Number(this.state.dForOneTrip) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    dieselCost: dieselCost.toString()
                });
            }
        }
    }

    calculateMaintenance = () => {
        if (this.state.duration === "Year") {
            if (this.state.maintenance !== "") {
                let maintenanceCost = Number(this.state.maintenance);
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.maintenance !== "") {
                let maintenanceCost = Number(this.state.maintenance) / 12;
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.maintenance !== "") {
                let maintenanceCost = Number(this.state.maintenance) / 52;
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.maintenance !== "" && this.state.yWorkingDays !== "") {
                let maintenanceCost = Number(this.state.maintenance) / Number(this.state.yWorkingDays);
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.maintenance !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let maintenanceCost = Number(this.state.maintenance) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.maintenance !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let maintenanceCost = Number(this.state.maintenance) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.maintenance !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let maintenanceCost = Number(this.state.maintenance) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    maintenanceCost: maintenanceCost.toString()
                });
            }
        }
    }

    calculateRepairs = () => {
        if (this.state.duration === "Year") {
            if (this.state.repairs !== "") {
                let repairsCost = Number(this.state.repairs);
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.repairs !== "") {
                let repairsCost = Number(this.state.repairs) / 12;
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.repairs !== "") {
                let repairsCost = Number(this.state.repairs) / 52;
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.repairs !== "" && this.state.yWorkingDays !== "") {
                let repairsCost = Number(this.state.repairs) / Number(this.state.yWorkingDays);
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.repairs !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let repairsCost = Number(this.state.repairs) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.repairs !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let repairsCost = Number(this.state.repairs) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.repairs !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let repairsCost = Number(this.state.repairs) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    repairsCost: repairsCost.toString()
                });
            }
        }
    }

    calculateInsuranceG = () => {
        if (this.state.duration === "Year") {
            if (this.state.insuranceG !== "") {
                let insuranceGCost = Number(this.state.insuranceG);
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.insuranceG !== "") {
                let insuranceGCost = Number(this.state.insuranceG) / 12;
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.insuranceG !== "") {
                let insuranceGCost = Number(this.state.insuranceG) / 52;
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.insuranceG !== "" && this.state.yWorkingDays !== "") {
                let insuranceGCost = Number(this.state.insuranceG) / Number(this.state.yWorkingDays);
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.insuranceG !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let insuranceGCost = Number(this.state.insuranceG) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.insuranceG !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let insuranceGCost = Number(this.state.insuranceG) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.insuranceG !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let insuranceGCost = Number(this.state.insuranceG) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    insuranceGCost: insuranceGCost.toString()
                });
            }
        }
    }

    calculateInsuranceP = () => {
        if (this.state.duration === "Year") {
            if (this.state.insuranceP !== "") {
                let insurancePCost = Number(this.state.insuranceP);
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.insuranceP !== "") {
                let insurancePCost = Number(this.state.insuranceP) / 12;
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.insuranceP !== "") {
                let insurancePCost = Number(this.state.insuranceP) / 52;
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.insuranceP !== "" && this.state.yWorkingDays !== "") {
                let insurancePCost = Number(this.state.insuranceP) / Number(this.state.yWorkingDays);
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.insuranceP !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let insurancePCost = Number(this.state.insuranceP) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.insuranceP !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let insurancePCost = Number(this.state.insuranceP) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.insuranceP !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let insurancePCost = Number(this.state.insuranceP) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    insurancePCost: insurancePCost.toString()
                });
            }
        }
    }

    calculateTaxes = () => {
        if (this.state.duration === "Year") {
            if (this.state.taxes !== "") {
                let taxesCost = Number(this.state.taxes);
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.taxes !== "") {
                let taxesCost = Number(this.state.taxes) / 12;
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.taxes !== "") {
                let taxesCost = Number(this.state.taxes) / 52;
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.taxes !== "" && this.state.yWorkingDays !== "") {
                let taxesCost = Number(this.state.taxes) / Number(this.state.yWorkingDays);
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.taxes !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let taxesCost = Number(this.state.taxes) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.taxes !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let taxesCost = Number(this.state.taxes) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.taxes !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let taxesCost = Number(this.state.taxes) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    taxesCost: taxesCost.toString()
                });
            }
        }
    }

    calculateFees = () => {
        if (this.state.duration === "Year") {
            if (this.state.fees !== "") {
                let feesCost = Number(this.state.fees);
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.fees !== "") {
                let feesCost = Number(this.state.fees) / 12;
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.fees !== "") {
                let feesCost = Number(this.state.fees) / 52;
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.fees !== "" && this.state.yWorkingDays !== "") {
                let feesCost = Number(this.state.fees) / Number(this.state.yWorkingDays);
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.fees !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0) {
                let feesCost = Number(this.state.fees) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.fees !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.dForOneTrip !== "") {
                let feesCost = Number(this.state.fees) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.fees !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== null && this.state.numberOfTrips !== 0 && this.state.capacity !== "") {
                let feesCost = Number(this.state.fees) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    feesCost: feesCost.toString()
                });
            }
        }
    }

    calculateDriverCost = () => {
        if (this.state.duration === "Year") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "") {
                let driverCost = 12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12;
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        } else if (this.state.duration === "Month") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "") {
                let driverCost = (12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12) / 12;
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        } else if (this.state.duration === "Week") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "") {
                let driverCost = (12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12) / 52;
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        } else if (this.state.duration === "Day") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "" && this.state.yWorkingDays !== "") {
                let driverCost = (12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12) / Number(this.state.yWorkingDays);
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        } else if (this.state.duration === "Trip") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== 0 && this.state.numberOfTrips !== null) {
                let driverCost = (12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12) / Number(this.state.yWorkingDays) / Number(this.state.numberOfTrips);
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        } else if (this.state.duration === "Km") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== 0 && this.state.numberOfTrips !== null && this.state.dForOneTrip !== "") {
                let driverCost = (12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * 2 * Number(this.state.dForOneTrip));
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        } else if (this.state.duration === "m3" || this.state.duration === "Ton") {
            if (this.state.salary !== "" && this.state.food !== "" && this.state.accom !== "" && this.state.feesDriver !== "" && this.state.insurance !== "" && this.state.yWorkingDays !== "" && this.state.numberOfTrips !== 0 && this.state.numberOfTrips !== null && this.state.capacity !== "") {
                let driverCost = (12 * (Number(this.state.salary) + Number(this.state.food) + Number(this.state.accom)) + Number(this.state.feesDriver) + Number(this.state.insurance) + Number(this.state.salary) / 3 + 13 * Number(this.state.salary) / 12) / Number(this.state.yWorkingDays) / (Number(this.state.numberOfTrips) * Number(this.state.capacity));
                this.setState({
                    driverCost: driverCost.toString()
                });
            }
        }
    }

    calculateOverallCost = () => {
        if (this.state.capitalCostCost !== "" && this.state.dieselCost !== "" && this.state.maintenanceCost !== "" && this.state.repairsCost !== null
            && this.state.insuranceGCost !== "" && this.state.insurancePCost !== "" && this.state.taxesCost !== "" && this.state.feesCost !== ""
            && this.state.driverCost !== "") {

            let overallCost = Number(this.state.capitalCostCost) + Number(this.state.dieselCost) + Number(this.state.maintenanceCost) + Number(this.state.repairsCost) + Number(this.state.insuranceGCost) + Number(this.state.insurancePCost) + Number(this.state.taxesCost) + Number(this.state.feesCost) + Number(this.state.driverCost);

            this.setState({
                overallCost: overallCost.toString()
            });
        }
    }

    calculateAll = () => {
        this.calculateCapitalCost();
        this.calculateDiesel();
        this.calculateMaintenance();
        this.calculateRepairs();
        this.calculateInsuranceG();
        this.calculateInsuranceP();
        this.calculateTaxes();
        this.calculateFees();

        setTimeout(() => {
            this.calculateOverallCost();
        }, 500);
    }

    saveAll = async () => {
        if (this.state.editedTruckCostCalculationId === 0) {
            const newTruckCostCalculation = {
                equipments: this.state.selectedEquipments,
                equipmentTypeId: this.state.values[this.state.values.length - 1],
                perChoice: this.state.duration,
                dForOneTrip: this.state.dForOneTrip,
                averageSpeed: this.state.averageSpeed,
                yWorkingDays: this.state.yWorkingDays,
                mWorkingDays: this.state.mWorkingDays,
                wWorkingDays: this.state.wWorkingDays,
                dWorkingHours: this.state.dWorkingHours,
                capacity: this.state.capacity,
                capitalCost: this.state.capitalCost,
                capitalCostCost: this.state.capitalCostCost,
                redemptionRate: this.state.redemptionRate,
                diesel: this.state.diesel,
                dieselCost: this.state.dieselCost,
                maintenance: this.state.maintenance,
                maintenanceCost: this.state.maintenanceCost,
                repairs: this.state.repairs,
                repairsCost: this.state.repairsCost,
                insuranceG: this.state.insuranceG,
                insuranceGCost: this.state.insuranceGCost,
                insuranceP: this.state.insuranceP,
                insurancePCost: this.state.insurancePCost,
                taxes: this.state.taxes,
                taxesCost: this.state.taxesCost,
                fees: this.state.fees,
                feesCost: this.state.feesCost,
                driverCost: this.state.driverCost,
                salary: this.state.salary,
                food: this.state.food,
                accom: this.state.accom,
                feesDriver: this.state.feesDriver,
                insurance: this.state.insurance,
                overallCost: this.state.overallCost
            }

            console.log(newTruckCostCalculation);

            await handleRequest("POST", "/TruckCostCalculation/add", newTruckCostCalculation);

            message.success(`The operation is successful.`);
            this.restartTable();
            this.refreshValues();
        }
        else {
            const updatedEquipmentTruckCost = {
                id: this.state.editedTruckCostCalculationId,
                equipments: this.state.selectedEquipments,
                equipmentTypeId: this.state.values[this.state.values.length - 1],
                perChoice: this.state.duration,
                dForOneTrip: this.state.dForOneTrip,
                averageSpeed: this.state.averageSpeed,
                yWorkingDays: this.state.yWorkingDays,
                mWorkingDays: this.state.mWorkingDays,
                wWorkingDays: this.state.wWorkingDays,
                dWorkingHours: this.state.dWorkingHours,
                capacity: this.state.capacity,
                capitalCost: this.state.capitalCost,
                capitalCostCost: this.state.capitalCostCost,
                redemptionRate: this.state.redemptionRate,
                diesel: this.state.diesel,
                dieselCost: this.state.dieselCost,
                maintenance: this.state.maintenance,
                maintenanceCost: this.state.maintenanceCost,
                repairs: this.state.repairs,
                repairsCost: this.state.repairsCost,
                insuranceG: this.state.insuranceG,
                insuranceGCost: this.state.insuranceGCost,
                insuranceP: this.state.insuranceP,
                insurancePCost: this.state.insurancePCost,
                taxes: this.state.taxes,
                taxesCost: this.state.taxesCost,
                fees: this.state.fees,
                feesCost: this.state.feesCost,
                driverCost: this.state.driverCost,
                salary: this.state.salary,
                food: this.state.food,
                accom: this.state.accom,
                feesDriver: this.state.feesDriver,
                insurance: this.state.insurance,
                overallCost: this.state.overallCost
            }

            await handleRequest("POST", "/TruckCostCalculation/update", updatedEquipmentTruckCost);

            message.success(`The operation is successful.`);
            this.restartTable();
            this.refreshValues();
        }
    }

    restartTable = async () => {
        const responseTruckCostCalculation = await handleRequest("GET", "/TruckCostCalculation/getAll");

        if (responseTruckCostCalculation.data.length === 0) {
            this.setState({
                truckCostCalculationList: []
            });
        }

        if (responseTruckCostCalculation.data.length !== 0) {
            this.setState({
                truckCostCalculationList: responseTruckCostCalculation.data,
            });
        }

        console.log(responseTruckCostCalculation.data);
    }

    refreshValues = () => {
        this.setState({
            values: [],
            selectedItems: null,
            selectedEquipments: [],
            showMessage: false,
            duration: "Km",
            dForOneTrip: "",
            averageSpeed: "",
            yWorkingDays: 300,
            mWorkingDays: 26,
            wWorkingDays: 6,
            dWorkingHours: 10,
            capacity: "",
            capitalCost: "",
            capitalCostCost: "",
            redemptionRate: "",
            diesel: "",
            dieselCost: "",
            maintenance: "",
            maintenanceCost: "",
            repairs: "",
            repairsCost: "",
            insuranceG: "",
            insuranceGCost: "",
            insuranceP: "",
            insurancePCost: "",
            taxes: "",
            taxesCost: "",
            fees: "",
            feesCost: "",
            driverCost: "",
            salary: "",
            food: "",
            accom: "",
            feesDriver: "",
            insurance: "",
            overallCost: "",
            numberOfTrips: null,
            editedTruckCostCalculationId: 0,
            selectedRow: null,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        });
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Truck Cost Calculation?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>All informations about truck cost calculation will be deleted. Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', fontWeight: 'bold' }}> Equipment Type:</div></Col>
                        <Col span={4}><div style={{ color: 'black', fontWeight: 'bold' }}> Equipment(s):</div></Col>
                        <Col span={11}></Col>
                    </Row>
                    <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        <Col span={3}></Col>
                        <Col span={5}>
                            <Cascader defaultValue={this.state.values} value={this.state.values} size="large" options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                        </Col>
                        <Col span={4}>
                            <MultiSelect value={this.state.selectedEquipments} options={this.state.equipmentOptions} onChange={this.onEquipmentChange}
                                style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />
                        </Col>
                        <Col span={1}>
                            <div style={{ color: 'black', fontWeight: 'bold', marginTop: '0.5rem' }}>per:</div>
                        </Col>
                        <Col span={3}>
                            <Select defaultValue="Km" value={this.state.duration} size="large" style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        duration: value
                                    });

                                    setTimeout(() => {
                                        this.calculateDriverCost();
                                    }, 500);

                                    setTimeout(() => {
                                        this.calculateAll();
                                    }, 500);
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col span={7}>
                        </Col>
                    </Row>
                    <hr />
                    <Row style={{ marginTop: '1rem' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Distance for One Trip (One Way):</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} min={0} value={this.state.dForOneTrip} onChange={this.onChangedDForOneTrip} /></Col>
                        <Col span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>km.</div></Col>
                        <Col span={12}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Average Speed:</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.averageSpeed} onChange={this.onChangeAverageSpeed} /></Col>
                        <Col span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>km/hour</div></Col>
                        <Col span={12}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Yearly Working Days:</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} min={0} value={this.state.yWorkingDays} onChange={this.onChangeYWorkingDays} /></Col>
                        <Col span={14}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Monthly Working Days:</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} min={0} value={this.state.mWorkingDays} onChange={this.onChangeMWorkingDays} /></Col>
                        <Col span={14}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Weekly Working Days:</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} min={0} value={this.state.wWorkingDays} onChange={this.onChangeWWorkingDays} /></Col>
                        <Col span={14}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Daily Working Hours:</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} min={0} value={this.state.dWorkingHours} onChange={this.onChangeDWorkingHours} /></Col>
                        <Col span={14}></Col>
                    </Row>
                    <div hidden={!this.state.showMessage}>
                        <Row style={{ marginTop: '10px' }}>
                            <Col span={3}></Col>
                            <Col span={6}><div style={{ color: 'red', marginTop: '0.5rem' }}>The total of {this.state.numberOfTripsWarning} trips excesses daily working hours.</div></Col>
                            <Col span={14}></Col>
                        </Row>
                    </div>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Capacity:</div></Col>
                        <Col span={1}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.capacity} onChange={this.onChangeCapacity} /></Col>
                        <Col span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>m3 or Ton</div></Col>
                        <Col span={12}></Col>
                    </Row>
                    <hr />
                    <Row style={{ marginTop: '1rem' }}>
                        <Col span={7}></Col>
                        <Col span={5}><div style={{ color: 'black', fontWeight: 'bold' }}>INPUTS</div></Col>
                        <Col span={4}></Col>
                        <Col span={4}><div style={{ color: 'black', fontWeight: 'bold' }}>COST (SR/{this.state.duration}):</div></Col>
                        <Col span={3}></Col>
                    </Row>
                    <hr />
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Capital Cost:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.capitalCost} onChange={this.onChangeCapitalCost} /></Col>
                        <Col span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SAR</div></Col>
                        <Col span={2}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.capitalCostCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Redemption Rate:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.redemptionRate} onChange={this.onChangeRedemptionRate} /></Col>
                        <Col span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>Year</div></Col>
                        <Col span={2}></Col>
                        <Col span={4}></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Diesel:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.diesel} onChange={this.onChangeDiesel} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>liter per 100 Km</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.dieselCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Maintenance:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.maintenance} onChange={this.onChangeMaintenance} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.maintenanceCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Repairs:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.repairs} onChange={this.onChangeRepairs} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.repairsCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Insurance - Government:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.insuranceG} onChange={this.onChangeInsuranceG} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.insuranceGCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Insurance - Private:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.insuranceP} onChange={this.onChangeInsuranceP} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.insurancePCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Taxes:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.taxes} onChange={this.onChangeTaxes} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.taxesCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Fees:</div></Col>
                        <Col span={3}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.fees} onChange={this.onChangeFees} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={1}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.feesCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={3}></Col>
                        <Col span={5}><div style={{ color: 'black', marginTop: '0.5rem' }}>Driver:</div></Col>
                        <Col span={7}></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.driverCost} /></Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={6}></Col>
                        <Col span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Salary:</div></Col>
                        <Col span={2}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.salary} onChange={this.onChangeSalary} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Month</div></Col>
                        <Col span={11}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={6}></Col>
                        <Col span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Food:</div></Col>
                        <Col span={2}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.food} onChange={this.onChangeFood} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Month</div></Col>
                        <Col span={11}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={6}></Col>
                        <Col span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Accom.:</div></Col>
                        <Col span={2}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.accom} onChange={this.onChangeAccom} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Month</div></Col>
                        <Col span={11}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={6}></Col>
                        <Col span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Fees:</div></Col>
                        <Col span={2}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.feesDriver} onChange={this.onChangeFeesDriver} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={11}></Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={6}></Col>
                        <Col span={3}><div style={{ color: 'black', marginTop: '0.5rem' }}>Insurance:</div></Col>
                        <Col span={2}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.insurance} onChange={this.onChangeInsurance} /></Col>
                        <Col span={3}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR per Year</div></Col>
                        <Col span={11}></Col>
                    </Row>
                    <hr />
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={6}></Col>
                        <Col span={5}><Button style={{ width: '100%' }} onClick={this.calculateAll} variant="success">Calculate All Costs</Button></Col>
                        <Col span={2}></Col>
                        <Col span={2}><div style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Overall Cost:</div></Col>
                        <Col span={4}><InputNumber style={{ width: '100%' }} disabled min={0} value={this.state.overallCost} /></Col>
                        <Col span={2}><div style={{ color: 'black', marginLeft: '10px', textAlign: 'left', marginTop: '0.5rem' }}>SR/{this.state.duration}</div></Col>
                        <Col span={4}></Col>
                    </Row>
                    <hr />
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={5}></Col>
                        <Col span={13}><Button style={{ width: '100%' }} variant={this.state.lastButtonVariant} onClick={this.saveAll} >{this.state.lastButtonName}</Button></Col>
                        <Col span={5}></Col>
                    </Row>
                </Card>
                <hr />
                <Card>
                    <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.truckCostCalculationList} bordered>
                        <Column
                            width='50px'
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <div style={{ textAlign: "center" }}>
                                    <React.Fragment key={record.id} > <CustomMaterialMenu row={record}
                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
                                    </React.Fragment>
                                </div>
                            )}
                        />
                        <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                        <Column title="Equipment Type" key="id" render={(value, row, index) => {
                            if (row.equipmentType != null && row.equipmentType !== undefined) {
                                return row.equipmentType.equipmentTypeName;
                            } else
                                return '';
                        }} />
                        <Column title="Equipment(s)" key="cfn" render={(value, row, index) => {
                            if (row.truckCostCalculationEquipmentList != null && row.truckCostCalculationEquipmentList !== undefined
                                && row.truckCostCalculationEquipmentList.length > 0) {

                                let equipmentNames = "";

                                for (let index = 0; index < row.truckCostCalculationEquipmentList.length; index++) {
                                    if (index !== (row.truckCostCalculationEquipmentList.length - 1))
                                        equipmentNames += (row.truckCostCalculationEquipmentList[index].equipmentLog.equipmentDescription + ", ");
                                    else
                                        equipmentNames += row.truckCostCalculationEquipmentList[index].equipmentLog.equipmentDescription;
                                }
                                return equipmentNames;
                            } else
                                return '';
                        }} />
                        <Column title="Cost Calculation Type" key="csn" dataIndex="perChoice" />
                        <ColumnGroup title="Reference Values" key="ho" >
                            <Column title="Distance for One Trip" key="dForOneTrip" dataIndex="dForOneTrip" />
                            <Column title="Average Speed" key="averageSpeed" dataIndex="averageSpeed" />
                            <Column title="Yearly W.D." key="yWorkingDays" dataIndex="yWorkingDays" />
                            <Column title="Monthly W.D." key="mWorkingDays" dataIndex="mWorkingDays" />
                            <Column title="Weekly W.D." key="wWorkingDays" dataIndex="wWorkingDays" />
                            <Column title="Daily W.H." key="dWorkingHours" dataIndex="dWorkingHours" />
                            <Column title="Capacity" key="capacity" dataIndex="capacity" />
                        </ColumnGroup>
                        <Column title="Overall Cost" key="overallCost" dataIndex="overallCost" />
                    </Table>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckCostCalculation)