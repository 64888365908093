import React, { Component } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomReportInfo from '../settings/ForComponents/CustomReportInfo';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import DocumentedCreator from './DocumentedCreator';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "ReportInfoLog";

export class ReportInfoLog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            documentType: null,
            typeOfReportId: null,
            nameOfReport: null,
            format: null,
            reportCode: null,
            revisionApplicable: null,
            periodForReport: null,
            startPreparationDate: null,
            lastPreparationDate: null,
            coveragePeriod: null,
            coveragePeriodType: null,

            codeName: "",
            typeOfReportSelectItems: [],
            redirect:null,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillTypeOfReports();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            documentType: this.state.documentType,
            typeOfReportId: this.state.typeOfReportId,
            nameOfReport: this.state.nameOfReport,
            format: this.state.format,
            reportCode: this.state.reportCode,
            revisionApplicable: this.state.revisionApplicable,
            periodForReport: this.state.periodForReport,
            startPreparationDate: this.state.startPreparationDate,
            lastPreparationDate: this.state.lastPreparationDate,
            coveragePeriod: this.state.coveragePeriod,
            coveragePeriodType: this.state.coveragePeriodType,

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/DocumentedInfoLog/add", obj);
        else
            await handleRequest("POST", "/DocumentedInfoLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }
    template = (row) => {
        console.log("object;");
        this.setState({
            redirect:row.id 
        })


    }


    edit = (row) => {

        this.formRef.current.setFieldsValue({
            documentType: row.documentType,
            typeOfReportId: row.typeOfReportId,
            nameOfReport: row.nameOfReport,
            format: row.format,
            reportCode: row.reportCode,
            revisionApplicable: row.revisionApplicable,
            periodForReport: row.periodForReport,
            startPreparationDate: Boolean(row.startPreparationDate) ? moment(row.startPreparationDate) : null,
            lastPreparationDate: Boolean(row.lastPreparationDate) ? moment(row.lastPreparationDate) : null,
            coveragePeriod: row.coveragePeriod,
            coveragePeriodType: row.coveragePeriodType,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            documentType: row.documentType,
            typeOfReportId: row.typeOfReportId,
            nameOfReport: row.nameOfReport,
            format: row.format,
            reportCode: row.reportCode,
            revisionApplicable: row.revisionApplicable,
            periodForReport: row.periodForReport,
            startPreparationDate: Boolean(row.startPreparationDate) ? moment(row.startPreparationDate) : null,
            lastPreparationDate: Boolean(row.lastPreparationDate) ? moment(row.lastPreparationDate) : null,
            coveragePeriod: row.coveragePeriod,
            coveragePeriodType: row.coveragePeriodType,


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/DocumentedInfoLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomReportInfo row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onTemplate={this.template.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/DocumentedInfoLog/report/");

        if (response.data.length !== 0) {
            const list = response.data; 
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            documentType: null,
            typeOfReportId: null,
            nameOfReport: null,
            format: null,
            reportCode: null,
            revisionApplicable: null,
            periodForReport: null,
            startPreparationDate: null,
            lastPreparationDate: null,
            coveragePeriod: null,
            coveragePeriodType: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            documentType: null,
            typeOfReportId: null,
            nameOfReport: null,
            format: null,
            reportCode: null,
            revisionApplicable: null,
            periodForReport: null,
            startPreparationDate: null,
            lastPreparationDate: null,
            coveragePeriod: null,
            coveragePeriodType: null,



        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addCodetypeOfReportId = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "typeOfReport"
            }

            var response = await handleRequest("POST", "/Code/add", newItem);
            if (response.type === "ERROR") {
                this.error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillTypeOfReports();
            }
        } else {
            this.showError("Please enter department");
        }
    };

    fillTypeOfReports = async () => {
        const response = await handleRequest("GET", "/Code/type/typeOfReport");
        if (Boolean(response.data)) {
            this.setState({
                typeOfReportSelectItems: response.data
            });
        }
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        if (this.state.redirect) {
             return <DocumentedCreator documentedInfoId={this.state.redirect} type="Report" ></DocumentedCreator> 
        }


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.documentType").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.documentType" defaultMessage="Document Type" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="documentType"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.documentType")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.documentType").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.documentType")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.documentType").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Radio.Group id="DocumentedInfoLog.documentType" onChange={(e) => {
                                                            this.setState({
                                                                documentType: e.target.value
                                                            })
                                                        }} value={this.state.documentType}>
                                                            <Radio value={"Document"}>Document</Radio>
                                                            <Radio value={"Report"}>Report</Radio>
                                                            <Radio value={"Meeting"}>Minutes of Meeting</Radio> 
                                                        </Radio.Group>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.typeOfReport").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.typeOfReport" defaultMessage="Type Of Report" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="typeOfReportId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.typeOfReport")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.typeOfReport").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.typeOfReport")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.typeOfReport").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Type Of Report"
                                                            value={this.state.typeOfReportId} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    typeOfReportId: value
                                                                });
                                                                this.setState({
                                                                    typeOfReportId: value
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodetypeOfReportId}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.typeOfReportSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.nameOfReport").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.nameOfReport" defaultMessage="Name Of Report" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="nameOfReport"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.nameOfReport")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.nameOfReport").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.nameOfReport")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.nameOfReport").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Name Of Report" style={{ width: "100%" }} id="DocumentedInfoLog.nameOfReport" value={this.state.nameOfReport} onChange={(e) => { this.setState({ nameOfReport: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.Format").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.Format" defaultMessage="Format" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="format"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.Format")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.Format").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.Format")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.Format").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Format" id="DocumentedInfoLog.Format" value={this.state.format} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                format: value
                                                            });
                                                            this.setState({ format: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option> </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.ReportCode").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.ReportCode" defaultMessage="Report Code" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="reportCode"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.ReportCode")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.ReportCode").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.ReportCode")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.ReportCode").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Report Code" style={{ width: "100%" }} id="DocumentedInfoLog.ReportCode" value={this.state.reportCode} onChange={(e) => { this.setState({ reportCode: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.revisionApplicable").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.revisionApplicable" defaultMessage="Revision Applicable" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="revisionApplicable"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.revisionApplicable")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.revisionApplicable").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.revisionApplicable")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.revisionApplicable").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Checkbox id="DocumentedInfoLog.revisionApplicable" onChange={(e) => {
                                                            this.setState({
                                                                revisionApplicable: e.target.checked
                                                            })
                                                        }} checked={this.state.revisionApplicable}> <FormattedMessage id="DocumentedInfoLog.revisionApplicable" defaultMessage="Revision Applicable" />  </Checkbox>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.periodForReport").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.periodForReport" defaultMessage="Period For Report" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="periodForReport"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.periodForReport")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.periodForReport").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.periodForReport")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.periodForReport").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Radio.Group id="DocumentedInfoLog.periodForReport" onChange={(e) => {
                                                            this.setState({
                                                                periodForReport: e.target.value
                                                            })
                                                        }} value={this.state.periodForReport}>
                                                            <Radio value={"On Call"}>On Call</Radio>
                                                            <Radio value={"Periodic"}>Periodic</Radio>
                                                        </Radio.Group>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {Boolean(this.state.periodForReport) && this.state.periodForReport === "Periodic" && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.startPreparationDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.startPreparationDate" defaultMessage="Start Preparation" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="startPreparationDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.startPreparationDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.startPreparationDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.startPreparationDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.startPreparationDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker showTime id="DocumentedInfoLog.startPreparationDate" format="DD-MM-YYYY HH:mm:ss" value={this.state.startPreparationDate} onOk={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                startPreparationDate: value
                                                            });
                                                            this.setState({
                                                                startPreparationDate: value
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {Boolean(this.state.periodForReport) && this.state.periodForReport === "Periodic" && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.lastPreparationDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.lastPreparationDate" defaultMessage="Last Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="lastPreparationDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.lastPreparationDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.lastPreparationDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.lastPreparationDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.lastPreparationDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker showTime id="DocumentedInfoLog.lastPreparationDate" format="DD-MM-YYYY HH:mm:ss" value={this.state.lastPreparationDate} onOk={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                lastPreparationDate: value
                                                            });
                                                            this.setState({
                                                                lastPreparationDate: value
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {Boolean(this.state.periodForReport) && this.state.periodForReport === "Periodic" && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.coveragePeriod").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DocumentedInfoLog.coveragePeriod" defaultMessage="Coverage Period" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="coveragePeriod"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.coveragePeriod").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="coveragePeriod"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriod").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <InputNumber style={{width:'100%'}} id="DocumentedInfoLog.coveragePeriod" placeholder="Coverage Period"
                                                                    min={0} value={this.state.coveragePeriod} onChange={(value) => {
                                                                        this.formRef.current.setFieldsValue({
                                                                            coveragePeriod: value
                                                                        });
                                                                        this.setState({ coveragePeriod: value })
                                                                    }} />
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>

                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DocumentedInfoLog.coveragePeriodType").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="coveragePeriodType"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriodType")) ? this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriodType").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriodType")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DocumentedInfoLog.coveragePeriodType").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <Select style={{ width: "100%" }} placeholder="Coverage Period Type" id="DocumentedInfoLog.coveragePeriodType" value={this.state.coveragePeriodType} onChange={(value) => {
                                                                    this.formRef.current.setFieldsValue({
                                                                        coveragePeriodType: value
                                                                    });
                                                                    this.setState({ coveragePeriodType: value })
                                                                }}>
                                                                    <Option key={null} value={null}>Select</Option>
                                                                    <Option value="day">Day</Option>
                                                                    <Option value="week">Week</Option>
                                                                    <Option value="month">Month</Option>
                                                                    <Option value="year">Year</Option>
                                                                </Select>
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="S/N" dataIndex="index" />

                                    <Column title='Document Type' dataIndex='documentType' render={(value, row, index) => { return value; }} />
                                    <Column title='Report Type' dataIndex='typeOfReport' render={(value, row, index) => { return Boolean(value) ? value.name : ""; }} />
                                    <Column title='Name Of Report' dataIndex='nameOfReport' render={(value, row, index) => { return value; }} />
                                    <Column title='Format' dataIndex='format' render={(value, row, index) => { return value; }} />
                                    <Column title='Report code' dataIndex='reportCode' render={(value, row, index) => { return value; }} />
                                    <Column title='Revision Applicable' dataIndex='revisionApplicable' render={(value, row, index) => { return Boolean(value) ? "Yes" : "No"; }} />
                                    <Column title='Period For Report' dataIndex='periodForReport' render={(value, row, index) => { return value; }} />
                                    <Column title='Status' dataIndex='documentedReportCreator' render={(value, row, index) => { return Boolean(value) && value.length > 0 ? "Template Completed" : "Template Not Complated"; }} />

                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />

                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportInfoLog)
