/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { API_BASE } from '../../redux/config/env';
import { handleRequest } from '../../redux/config/env'; 
import { Card, Form, Col, Row, Button as ReactButton, Modal, Container } from 'react-bootstrap';
import { Button as PrimeButton} from 'primereact/button';
import { Button as AntdButton, Upload, Checkbox as AntCheckbox, Table } from 'antd';
import { InputText } from "primereact/inputtext";
import { UploadOutlined } from '@ant-design/icons';
import { Editor } from 'primereact/editor';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { FormattedMessage } from "react-intl";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

class TextToPara extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            hiddenSave: false,
            hiddenUpdate: true,
            FileUpload: [],
            fileList: [],
            filePath:"",
            textareaVal:"",
            editorText:"",
            editorSonuc:"",
            itemGroup: false,
            itemNo:"",
            hiddenItemNo:false,
            dtNodes:[],
            selectedItems: [],

            pagination: {
                current: 1,
                pageSize: 5,
            },

            total:0,
            loading: false,
            TextToParas:[],
            itemAckModal:"",
            documentLogid:-1,
            popup:false,

            code: "",
            description: "",
            documentType: "",
            hiddenVisible:true,
        } 
        this.handleRight = this.handleRight.bind(this);  
        this.handleDelete = this.handleDelete.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this); 
    }

    componentDidMount = async () => { 
        var deger=-1;
        if(this.props.location!=null && this.props.location!=undefined && this.props.location!=""){
          deger=this.props.location.pathname.split('/')[2]; 
        //   this.setState({
        //     popup:false
        //   });
        }else if(this.props.id!=null && this.props.id!=0 && this.props.id!=""){
          deger=this.props.id; 
        //   this.setState({
        //     popup:true
        //   });
        }else{
          deger=-1;
        //   this.setState({
        //     popup:true
        //   });
        } 

        if(deger!=null && deger!=undefined && deger!="" && deger!=0 ){
            await this.setState({
                documentLogid:deger
              });
        }else{
            await this.setState({
                documentLogid:-1
            });
        }
        this.getDocumentLog();
 
        const { pagination } = this.state;
        this.fetch({ pagination });

        this.getEditorSonuc();
    }

    getDocumentLog = async () => {
        const newObj = { 
            id:this.state.documentLogid,
        }
        var response = []; 
        response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj);
        console.log("DOC", response.data);
        if (response.data.length !== 0) {
            this.setState({
                code: response.data[0].code,
                description:response.data[0].description,
                documentType:response.data[0].documentTypeAck,
                hiddenVisible:false,
            });
        } else{
            this.setState({
                code: "",
                description: "",
                documentType: "",
                hiddenVisible:true,
            });
        }
    }

    getEditorSonuc = async () => {
        const newObj = { 
            documentLogid:this.state.documentLogid,
        } 

        var response;
        if(this.state.documentLogid!=-1){    
            response = await handleRequest("POST", "/TextToPara/getTextToParaResult", newObj);
        }else{
            response = await handleRequest("POST", "/TextToPara/getTextToParaTempResult", newObj);
        }

        // var response = await handleRequest("POST", "/TextToPara/getTextToParaResult", newObj);
        if (response.data.length !== 0) {
            var array =[];
            var deger="";
            array=response.data; 
            array.forEach(element => {
                deger+= element.itemNoitemAck;
            });

            this.setState({
                editorSonuc:deger,
            });
        } else{
            this.setState({
                editorSonuc:"",
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg =  <FormattedMessage id="GeneralMessageError1" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Error"
        });
    };

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Warning"
        });
    };

    handleRight() {
        let textVal = this.refs.myTextarea;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd;
        
        let selected = this.state.textareaVal.substring(cursorStart,cursorEnd);
        if (selected!=null && selected!=undefined && selected!=""){
            var deger="";
            deger = selected 

            var replaceDeger="";
            replaceDeger=this.state.textareaVal.replace(selected, ""); 

            this.setState({ 
                editorText:deger,
                textareaVal:replaceDeger
            }); 
        }
    }

    handleDelete() {
        let textVal = this.refs.myTextarea;
        let cursorStart = textVal.selectionStart;
        let cursorEnd = textVal.selectionEnd; 

        let selected = this.state.textareaVal.substring(cursorStart,cursorEnd);
        if (selected!=null && selected!=undefined && selected!=""){
            var deger="";
            deger=this.state.textareaVal.replace(selected, ""); 

            this.setState({ 
                textareaVal:deger
            }); 
        }
    }

    addItems = async () => {
        if(this.state.editorText!=null && this.state.editorText!=undefined && this.state.editorText!=""){
            var item = {
                filePath:this.state.filePath,
                itemNo:this.state.itemNo,
                itemGroup:this.state.itemGroup,
                itemAck:this.state.editorText,
                documentLogid:this.state.documentLogid,
            } 
            var response =  await handleRequest("POST", "/TextToPara/add", item);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.restartTable();
                    this.showSuccess();
                    this.resetInputs();
                }
                else  {
                    this.showError();
                }  
            }  
            else  {
                this.showError();
            } 
        }else{
            this.showWarning();
        }
    }

    resetInputs = () => {
        this.setState({ 
            editorText:"",
            itemGroup:false,
            itemNo:"",
            hiddenItemNo:false,
            itemAck:"",
        });
    }

    resetInputsAll = () => {
        this.setState({ 
            editorText:"",
            itemGroup:false,
            itemNo:"",
            hiddenItemNo:false,
            itemAck:"",
            
            filePath:"",
            FileUpload: [],
            fileList: [],
        });
    }

    handleChange = e => {  
        e.preventDefault();
        this.setState({
          [e.target.name]: e.target.value
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters, });
    };

    fetch = async (params = {}) => {
        this.setState({ 
            loading: true 
        });
        const newObj = {
            id: 0,
            firstResult: params.pagination.current,
            maxResults: params.pagination.pageSize,
            documentLogid:this.state.documentLogid,
        }
 
    var response = await handleRequest("POST", "/TextToPara/getTextToParaPaging", newObj);
    if (response.data.length !== 0) { 
        this.setState({
            loading: false,
            TextToParas: response.data.data,
            pagination: {
                ...params.pagination,
                total: response.data.totalRecords,
            },
        });
    } else {
        this.setState({
            loading: false,
            TextToParas: [],
            pagination: {
                ...params.pagination,
                total: 0,
            },
        });
    }
    };
  
    restartTable = async () => {
        const { pagination } = this.state;
        this.fetch({ pagination });
        this.getEditorSonuc();
    };

    actionTemplate(rowData) {
        return <React.Fragment> 
            <CustomMaterialMenu row={rowData} 
                onDeleteRow={this.deleteTextToPara.bind(this)} 
                onEditRow={this.editTextToPara.bind(this)} />   
            </React.Fragment>;
    };

    showOrHideModalDeleteTextToPara = () => {
        this.setState({
          deleteModal: !this.state.deleteModal,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    deleteTextToPara = (rowData) => {
        this.setState({
          deleteModal: true,
          textToParaId: rowData.id,
        });
    };
    
    deleteTextToParaOK = async () => {
    const deletedTextToPara = {
        id: this.state.textToParaId,
    };

    var response = await handleRequest("POST", "/TextToPara/delete", deletedTextToPara);

    if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
        this.setState({
            deleteModal: false,
        });
        this.restartTable();
        this.showSuccess();
        }
        else {
        this.showError();
        }
    } else {
        this.showError();
    }
    setTimeout(() => {
        document.body.style.overflow = "auto";
    }, 500);
    };

    editTextToPara = async (row) => {
        await this.setState({
            addModal: false,
            hiddenSave: true,
            hiddenUpdate: false,

            id: row.id,
            textToParaId: row.id, 
            filePath:row.filePath,
            itemNo:row.itemNo,
            itemGroup:row.itemGroup,
            editorText:row.itemAck,
        });
    };

    changeButtonCancel = () => {
        this.resetInputsAll();
        this.setState({
            hiddenSave: false,
            hiddenUpdate: true, 
        });
    };
    
    updateDone = async () => {
        if(this.state.editorText!=null && this.state.editorText!=undefined && this.state.editorText!=""){
           
            if(this.state.documentLogid!=null && this.state.documentLogid!=undefined && this.state.documentLogid!="" && this.state.documentLogid>0){
                var updateTextToPara = {
                    id:this.state.textToParaId, 
                    filePath:this.state.filePath,
                    itemNo:this.state.itemNo,
                    itemGroup:this.state.itemGroup,
                    itemAck:this.state.editorText,
                    documentLogid:this.state.documentLogid,
                } 
        
                var response = await handleRequest("POST", "/TextToPara/update", updateTextToPara);
        
                if (response.data !== null && response.data !== undefined) {
                    if (response.data == "SUCCESS") {
                        this.setState({
                            hiddenSave:false,
                            hiddenUpdate:true,
                        });
                        this.resetInputsAll();
                        this.showSuccess();
                        this.restartTable();
                    }
                    else {
                        this.showError();
                    }
                }
                else {
                    this.showError();
                }

            }else{ 
                this.showError(<FormattedMessage id="DocumentNotFound" />);
            } 
        }
        else {
            this.showError();
        }
    };
    
    showReport = async (rowData) => {
        try {
            if(rowData.filePath!=null && rowData.filePath!=undefined && rowData.filePath!=""){
                const fileRealUrl = rowData.filePath;
        
                if(fileRealUrl.endsWith('.pdf')){
                this.setState({
                    pdfFile: fileRealUrl,
                    modalPdfViewer: true,
                });
                }else if(fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
                    || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')){
        
                this.setState({
                    imageUrl: fileRealUrl,
                    modalPhotoShow: true,
                })
                }else{
                window.location.href = fileRealUrl;
                }
            }else{
                this.showError(<FormattedMessage id="FileErrorMessage1" />);
            }
        } catch (error) {
        this.showError(<FormattedMessage id="FileErrorMessage1" />);
        }
    };
  
    showOrHideModalPdfViewer = () => {
        this.setState({
        modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };
  
    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHideTextEditorModal = () => {
        this.setState({
            modalTextEditor: !this.state.modalTextEditor,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };
    
    showTextEditor = async (rowData) => {
        try { 
            this.setState({
                itemAckModal:rowData.itemNoitemAck,
                modalTextEditor: true,
            })
        } catch (error) {
            this.showError(<FormattedMessage id="FileErrorMessage1" />);
        }
    };

    render() {

        const layout = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
          ) => {
            return defaultLayout(
              isSidebarOpened,
              container,
              main,
              toolbar(renderToolbar),
              sidebar,
            );
          };

          const renderToolbar = (toolbarSlot) => {
            return (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                  </div>
                </div>
              </div>
            );
          };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        }; 

        const columns = [  
        {
            dataIndex: "index",
            key: "key",
            title: <FormattedMessage id="GeneralTableSN" />,
        },
        {
            dataIndex: "documentTypeAck",
            key: "key",
            title: <FormattedMessage id="DocumentType" />,
        },
        {
            dataIndex: "data",
            key: "key",
            title: <FormattedMessage id="DocumentDescription" />,
            render: (description, data) =>  
            <React.Fragment>
              {data.documentLog.description}
            </React.Fragment>
        },
        {
            dataIndex: "data",
            key: "key",
            title: <FormattedMessage id="DocumentCode" />, 
            render: (code, data) =>  
            <React.Fragment>
            <div style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
              onClick={(e) => this.showReport(data)}>
              {data.documentLog.code}
            </div>
          </React.Fragment>
        }, 
        {
            dataIndex: "data",
            key: "key",
            title: <FormattedMessage id="TTPItemNo" />, 
            render: (itemNo, data) =>  
            <React.Fragment>
            <div style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
              onClick={(e) => this.showTextEditor(data)}>
              {(data.itemNo!=null && data.itemNo!=undefined && data.itemNo!="") ? data.itemNo : 0}
            </div>
          </React.Fragment>
        },
        {
            dataIndex: "itemGroup",
            key: "key",
            title: <FormattedMessage id="TTPItemGroup" />, 
            render: (record) =>  
            {
                if(record){
                    return <FormattedMessage id="GeneralTextYes" />;
                }else{
                    return <FormattedMessage id="GeneralTextNo" />;
                }
            }
        }, 
        {
            key: "key",
            title: "",
            render: this.actionTemplate
        }, 
        ];

        return (
            <div>
                <Form>
                  
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteTextToPara}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage2" />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton
                        variant="secondary"
                        onClick={this.showOrHideModalDeleteTextToPara}
                        >
                            <FormattedMessage id="GeneralTextClose" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteTextToParaOK}>
                            <FormattedMessage id="GeneralButtonDelete" />
                        </ReactButton>
                    </Modal.Footer>
                    </Modal>

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                            <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                            
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                            </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row > 
                        <Col sm="12">
                            {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <AntdButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" /></AntdButton>
                    </Modal.Footer>
                </Modal>
                  
                <Modal
                    show={this.state.modalTextEditor}
                    onHide={this.showOrHideTextEditorModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered 
                    size="lg">
                    <Modal.Header closeButton>
                    {/* <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="TTPItemNo" /> : {this.state.itemNoModal}</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                    {(this.state.itemAckModal !== null && this.state.itemAckModal !== undefined && this.state.itemAckModal !== "") ? (
                        <Editor style={{ height: '250px' }} value={this.state.itemAckModal} readOnly={true} /> 
                    ) : (
                        <p> <FormattedMessage id="FileErrorMessage1" /></p>
                    )}
                    </Modal.Body>
                    <Modal.Footer>
                    <AntdButton variant="info" onClick={this.showOrHideTextEditorModal}>
                        <FormattedMessage id="GeneralTextClose" />
                    </AntdButton>
                    </Modal.Footer>
                </Modal>
             
                <Modal
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="ModalPhotoText" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.imageUrl !== "" ? (
                        <img
                        src={this.state.imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                        />
                    ) : (
                        <p> <FormattedMessage id="UploadedPhotoMessage1" /></p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                    <AntdButton variant="info" onClick={this.showOrHidePhotoModal}>
                        <FormattedMessage id="GeneralTextClose" />
                    </AntdButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="lg"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                         <FormattedMessage id="ModalPDFReportText" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                        <div style={{ height: "900px" }}>
                        <Viewer fileUrl={this.state.pdfFile} layout={layout} />
                        </div>
                    </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                    <AntdButton
                        variant="secondary"
                        onClick={this.showOrHideModalPdfViewer}
                    >
                        <FormattedMessage id="GeneralTextClose" />
                        </AntdButton>
                    </Modal.Footer>
                </Modal>
            
                <div hidden={this.state.hiddenVisible}>
                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGriddocumenttype">
                        <Col sm="2">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                            <FormattedMessage id="DocumentType" />
                        </Form.Label>
                        <Col sm="5"> 
                            <InputText readOnly={true} style={{ width: '100%' }} value={this.state.documentType}/>
                        </Col>
                        <Col sm="2">
                            {/* <AntdButton onClick={this.showModal} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}>Add New</AntdButton>  */}
                        </Col> 
                    </Form.Group> 

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocumentDescription">
                        <Col sm="2">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                            <FormattedMessage id="DocumentDescription" />
                        </Form.Label>
                        <Col sm="5"> 
                            <InputText readOnly={true} style={{ width: '100%' }} value={this.state.description}/>
                        </Col>
                        <Col sm="2">
                        </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                            <Col sm="2">
                            </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    <FormattedMessage id="DocumentCode" />
                                </Form.Label>
                            <Col sm="5">
                                <InputText readOnly={true} style={{ width: '100%' }} value={this.state.code}/>
                            </Col>
                            <Col sm="2">
                            </Col>
                    </Form.Group>
                </div>  

                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridUser">
                    <Col sm="4">
                    </Col>
                    <Col sm="3"> 
                        <Upload {...props} 
                            showUploadList={true}
                            fileList={this.state.FileUpload}
                            onChange={({ fileList }) => {
                            if (fileList.length == 1) {  
                                var filePath="";
                                if(Boolean(fileList[0].response)){
                                    filePath=fileList[0].response.url;  
                                    this.showSuccess("file uploaded successfully");
                                }
                                this.setState({
                                    FileUpload:fileList,
                                    filePath:filePath,
                                });
                            }
                        }}
                        beforeUpload={(fileList) => {
                            if (this.state.FileUpload.length > 0) {
                                this.showError("You have been already choose a file! For change firstly remove the uploaded file."); 
                                return false;
                            }
                            return true;
                        }}
                        onRemove={({ fileList }) => {
                            this.setState( {
                                FileUpload:[], 
                                filePath:"",
                            })
                        }
                        }>
                        <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                            <UploadOutlined /><FormattedMessage id="GeneralUploadFile" />
                        </AntdButton>
                        </Upload>
                    </Col>
                    <Col sm="3"> 
                     </Col>
                </Form.Group>    

                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Col sm="5">
                        <Form.Control as="textarea" rows="10"  
                        ref='myTextarea' 
                        value={this.state.textareaVal}  
                        onChange={(event)=>{
                            this.setState({
                                textareaVal:event.target.value
                            }); }}
                        />  
                    </Col> 

                    <Col sm="1">
                        <br/>
                        <br/>
                        <PrimeButton type="Button" icon="pi pi-arrow-right" onClick={this.handleRight} />
                        <br/>
                        <br/>
                        <br/>
                        <PrimeButton type="Button" icon="pi pi-trash" onClick={this.handleDelete} className="p-button-danger" />
                    </Col> 
                    <Col sm="6">
                        <Editor style={{ height: '140px' }} value={this.state.editorText} 
                        onTextChange={(e) => this.setState({ editorText: e.htmlValue })} /> 
                    </Col> 
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Col sm="6"> 
                    </Col>
                    <Col sm="2" style={{ marginTop: '1rem' }}> 
                    <div hidden={this.state.hiddenItemNo}>
                        <Form.Label style={{ color: 'black' }}>
                            <FormattedMessage id="TTPItemNo" />  
                        </Form.Label>
                        </div>
                    </Col>
                    <Col sm="2">
                    <div hidden={this.state.hiddenItemNo}>
                        <Form.Control name="itemNo" value={this.state.itemNo} onChange={this.handleChange} />
                    </div>
                    </Col>
                    <Col sm="2" style={{ marginTop: '1rem' }}>
                    <AntCheckbox checked={this.state.itemGroup} onChange={(e) => { 
                        if(e.target.checked){
                        this.setState({ 
                            itemGroup: e.target.checked,
                            // hiddenItemNo:true,
                            // itemNo:"",
                        });
                        }else{
                        this.setState({ 
                            itemGroup: e.target.checked,
                            // hiddenItemNo:false
                        })  
                        }
                        }}> <FormattedMessage id="TTPItemGroup" /> </AntCheckbox>
                    </Col> 
                </Form.Group>  
                
                <div hidden={this.state.hiddenSave}>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                            <Col sm="6"> 
                            </Col>
                            <Col sm="6"> 
                                <ReactButton onClick={this.addItems} 
                                style={{ width: '100%' }}  
                                variant="success"><FormattedMessage id="GeneralButtonAdd" /></ReactButton>
                            </Col> 
                    </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdate}>
                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col sm="6"> 
                        </Col>
                        <Col sm="3">
                        <ReactButton onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" /></ReactButton>
                        </Col>
                        <Col sm="3">
                        <ReactButton onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" /></ReactButton>
                        </Col>
                    </Row>
                    </Container>
                </div>
            
                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode"> 
                    <Editor style={{ height: '140px' }} readOnly={true} value={this.state.editorSonuc} /> 

                {/* <Form.Control as="textarea" rows="10"  
                        ref='myTextarea' 
                        value={this.state.textareaSonuc}  
                        onChange={(event)=>{
                            this.setState({
                                textareaSonuc:event.target.value
                            }); }}
                        />   */}

                </Form.Group>  
                 <div hidden={this.state.hiddenVisible}>
                    <div className="datatable-doc-demo">
                        <Card>
                            <div className="content-section implementation">
                            <Table columns={columns} dataSource={this.state.TextToParas} pagination={this.state.pagination}
                                responsive striped bordered hover
                                loading={this.state.loading} onChange={this.handleTableChange} 
                            />
                            </div>
                        </Card>
                    </div>
                </div>

                </Form>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {
  
    }
  }
  
  const mapDispatchToProps = {
  
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(TextToPara)
