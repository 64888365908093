import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Divider, Button as AntButton, Select, InputNumber } from 'antd';
import { CloseCircleOutlined, PlusOutlined, RightCircleOutlined, UndoOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Column } = Table;

const _DurationTypes = [

    { label: "Day", value: 3 },
    { label: "Hour", value: 4 },
    { label: "Minute", value: 5 },

];

export class AbsenceInfraction extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,


            id: 0,
            tableList: [],
            attandencyActionId: null,
            attandencyActionSelectItems: [],
            score: 0,
            deductionSalary: 0,
            deductionType: null,

            durationObj: {},
            duration: '',
            previousDuration: '',
            durationNumber: 0,
            durationType: null,
            durationTypes: _DurationTypes.slice(),
            codeName: '',

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.fillCodes();
        this.restartTable();


    }

    save = async () => {

        const obj = {
            id: this.state.id,
            attandencyActionId: this.state.attandencyActionId,
            score: this.state.score,
            deductionSalary: this.state.deductionSalary,
            deductionType: this.state.deductionType,
            absentTime: this.state.durationObj
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/AttendanceAbsenceInfraction/add", obj);
        else
            await handleRequest("POST", "/AttendanceAbsenceInfraction/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.setDuration(row.absentTime);
        const list = this.state.tableList.filter(p=>p.attandencyActionId===row.attandencyActionId);
        let index = list.findIndex(p=>p.id===row.id);
        let str= '';
        if(index>0)
         { 
             if(Boolean(list[index-1].absentTime)) 
            str= this.getDurationDesc(list[index-1].absentTime); 
         }

        this.setState({
            id: row.id,
            attandencyActionId: row.attandencyActionId,
            score: row.score,
            deductionSalary: row.deductionSalary,
            deductionType: row.deductionType,
            previousDuration:str,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/AttendanceAbsenceInfraction/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        const response = await handleRequest("GET", "/AttendanceAbsenceInfraction/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "AbsenceInfraction" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "AbsenceInfraction"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "AbsenceInfraction");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            id: 0,
            attandencyActionId: null,
            score: 0,
            deductionSalary: 0,
            deductionType: null,
            durationObj: {},
            previousDuration: '',
            duration: '',
            durationNumber: 0,
            durationType: null,
            durationTypes: _DurationTypes.slice(),
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            id: 0,
            attandencyActionId: null,
            score: 0,
            deductionSalary: 0,
            deductionType: null,
            durationObj: {},
            previousDuration: '',
            duration: '',
            durationNumber: 0,
            durationType: null,
            durationTypes: _DurationTypes.slice(),
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({

            id: 0,
            attandencyActionId: null,
            score: 0,
            deductionSalary: 0,
            deductionType: null,
            durationObj: {},
            previousDuration: '',
            duration: '',
            durationNumber: 0,
            durationType: null,
            durationTypes: _DurationTypes.slice(),
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    calculateDuration = () => {

        var myObj = this.state.durationObj;
        var types = this.state.durationTypes;
        const index = this.state.durationTypes.findIndex(p => p.value === this.state.durationType);
        if (index >= 0 && this.state.durationNumber > 0) {
            var durationDesc = this.state.duration + this.state.durationNumber + " " + types[index].label + "s ";

            myObj[types[index].label.toLowerCase()] = this.state.durationNumber;

            types.splice(0, index + 1);

            this.setState({
                durationObj: myObj,
                duration: durationDesc,
                durationTypes: types,
                durationType:null,
            });
        }
        else {
            this.showError("Please type duration inputs !");
        }


    }

    undoDuration = () => {
        if (this.state.duration.trim() !== "") {
            var pObj = this.state.durationObj;

            if (pObj !== null) {

                if (pObj.minute && pObj.minute > 0)
                    pObj.minute = 0;
                else if (pObj.hour && pObj.hour > 0)
                    pObj.hour = 0;
                else if (pObj.day && pObj.day > 0)
                    pObj.day = 0;

                else pObj = {};

            }
            var types = _DurationTypes.slice();
            var durationDesc = this.getDurationDesc(pObj);
            var index = -1;

            if (pObj !== {}) {
                if (pObj.minute && pObj.minute > 0)
                    index = 2;
                else if (pObj.hour && pObj.hour > 0)
                    index = 1;
                else if (pObj.day && pObj.day > 0)
                    index = 0;


            }

            types.splice(0, index + 1);

            this.setState({
                durationObj: pObj,
                duration: durationDesc,
                durationTypes: types
            });
        }
        else { this.showError("Please firstly type any duration!"); }


    }

    removeDuration = () => {

        this.setState({
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationTypes: _DurationTypes.slice(),
            durationType: null
        });
    }

    setDuration = (pObj) => {
        var result = "";

        if (pObj !== undefined && pObj !== null) {

            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";

        }
        else { pObj = {} }

        this.setState({
            duration: result,
            durationObj: pObj
        })
    }

    getDurationDesc = (pObj) => {
        var result = "";
        if (pObj !== undefined && pObj !== null) {

            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";

        }

        return result;
    }

    addCode = async () => {
        const { codeName } = this.state;
        let pType = "attandencyAction";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/attandencyAction");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                attandencyActionSelectItems: response.data
            });
        }
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Attandency action" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Unit"
                                                    value={this.state.attandencyActionId} onChange={(value) => {

                                                       let list = this.state.tableList.filter(p=>p.attandencyActionId===value);
                                                       let str= '';
                                                       if(Boolean(list) && list.length>0)
                                                        {
                                                            if(Boolean(list[list.length-1].absentTime)) 
                                                           str= this.getDurationDesc(list[list.length-1].absentTime);
                                                        }
                                                        this.setState({ attandencyActionId: value , previousDuration:str})
                                                    }}


                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                    this.setState({
                                                                        codeName: event.target.value,
                                                                    });
                                                                }} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addCode}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {this.state.attandencyActionSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Abbreviation" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            {this.state.previousDuration.length>0 && <Col sm="2">
                                                <Input style={{width:'100%'}} id="todo" value={this.state.previousDuration} />
                                            </Col>}
                                            {this.state.previousDuration.length>0 && <Col sm="1">
                                                To
                                            </Col>} 
                                            <Col sm="1">
                                                <InputNumber min={0} value={this.state.durationNumber}
                                                    onChange={(value) => {
                                                        this.setState({ durationNumber: value })
                                                    }}
                                                > </InputNumber>  </Col>
                                            <Col sm="2">
                                                <Select placeholder="" value={this.state.durationType} onChange={(value) => {
                                                    this.setState({
                                                        durationType: value,
                                                    })
                                                }}
                                                >
                                                    <Option key={null} value={null}>Select</Option> 
                                                    {this.state.durationTypes.map(i => (
                                                        <Option key={i.value} value={i.value}>{i.label}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="2">
                                                <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.calculateDuration} icon={<RightCircleOutlined style={{ fontSize: '32px' }} />} />
                                                <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.undoDuration} icon={<UndoOutlined style={{ fontSize: '32px' }} />} />

                                            </Col>
                                            <Col sm="2">
                                                <Input style={{width:'100%'}} id="todo" value={this.state.duration} />
                                            </Col>
                                            <Col sm="1">
                                                <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.removeDuration} icon={<CloseCircleOutlined style={{ fontSize: '32px' }} />} />

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Score" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <InputNumber min={0} value={this.state.score}
                                                    onChange={(value) => {
                                                        this.setState({ score: value })
                                                    }}
                                                > </InputNumber>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Deduction from salary" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="2">
                                                <InputNumber min={0} value={this.state.deductionSalary} 
                                                    onChange={(value) => {
                                                        this.setState({ deductionSalary: value })
                                                    }}
                                                > </InputNumber>
                                            </Col>
                                            <Col sm="2">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.deductionType} onChange={(value) => {
                                                    this.setState({
                                                        deductionType: value
                                                    })
                                                }}>
                                                    <Option key={null} value={null}>{"Select"}</Option>
                                                    <Option key={"minute"} value={"minute"}>{"Minute"}</Option>
                                                    <Option key={"hour"} value={"hour"}>{"Hour"}</Option>
                                                    <Option key={"day"} value={"day"}>{"Day"}</Option>

                                                </Select>
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }




                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Attendancy Action" render={(value, row, index) => {

                                if (Boolean(row.attandencyAction))
                                    return row.attandencyAction.name;

                            }} />
                            <Column title="Absent Time" render={(value, row, index) => {

                                if (index === 0)
                                    return "0 - " + (Boolean(row.absentTime) ? this.getDurationDesc(row.absentTime) : '0');
                                if (index > 0)
                                    return (Boolean(this.state.tableList[index - 1].absentTime) && this.state.tableList[index - 1].attandencyActionId===row.attandencyActionId  ? this.getDurationDesc(this.state.tableList[index - 1].absentTime) : '0') +' - ' + (Boolean(row.absentTime) ? this.getDurationDesc(row.absentTime) : '0');

                            }} />

                            <Column title="Score"  dataIndex="score" />

                            <Column title="Deduction From Salary" render={(value, row, index) => {
                                return (Boolean(row.deductionSalary)? row.deductionSalary : '')+ " " + (Boolean(row.deductionType)?row.deductionType:'') 
                            }} />

                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
      
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceInfraction) 