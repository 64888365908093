import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined, EditOutlined, ZoomInOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings, getCodeSelectItems } from '../../redux/config/imu.js';
import XLSX from 'xlsx'

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "PressureInjury";
const durationTypes = [

    { label: "Year", value: "year" },
    { label: "Month", value: "month" },
    { label: "Day", value: "day" },
    { label: "Hour", value: "hour" },
    { label: "Minute", value: "minute" }

];

function getProtocols(protocols) {

    let result = [];

    protocols.forEach(element => {
        if (Boolean(element.active) && Boolean(element.protocol) && element.active === true)
            result.push(element.protocol.name+ '\r\n'); 
    });


    return result.toString();
}

export class PressureInjury extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            modalDeleteShow2: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            injuryId: null,
            hidePatientModal: true,
            tableList: [],
            filePath: '',
            fileList: [],
            patientName: '',
            mrn: '',
            patientId: null,
            dateAcquired: moment(),
            roomNoId: null,
            areaAcquiredId: null,
            dateTransferred: moment(),
            statusId: null,
            injuries: [],
            siteId: null,
            date: moment(),
            stageId: null,
            length: 0,
            width: 0,
            depth: 0,
            reviewDuration: 0,
            reviewDurationType: null,
            protocols: [],
            remarks: '',
            roomSelectItems: [],
            areaAcquiredSelectItems: [],
            siteSelectItems: [],
            stageSelectItems: [],
            protocolSelectItems: [],
            statusSelectItems: [],
            patientSelectItems: [],
            code: '',

            injuryDetailList: [],
            siteDetailList: [],
            showSiteDetailModal: false,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };


    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillPatients();
        this.fillComboBoxes();


    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        let injuries = this.state.injuries;
        if (Boolean(injuries)) {
            injuries = injuries.filter(p => p.active == true || p.id > 0)
        }

        // injuries.forEach(element => {
        //     if (Boolean(element.protocols))
        //         element.protocols.forEach(o => {
        //             o.active = false;
        //         });

        //     else element.protocols = [];

        //     if (Boolean(element.newProtocols))
        //         element.newProtocols.forEach(u => {
        //             let index = element.protocols.findIndex(p => p.protocolId === u.protocolId);

        //             if (index >= 0)
        //                 element.protocols[index].active = true;
        //             else element.protocols.push(u);

        //         });



        // });



        let obj = {
            id: this.state.id,
            patientId: this.state.patientId,
            dateAcquired: moment(new Date(this.state.dateAcquired)),
            roomNoId: this.state.roomNoId,
            areaAcquiredId: this.state.areaAcquiredId,
            dateTransferred: moment(new Date(this.state.dateTransferred)),
            statusId: this.state.statusId,
            injuries: injuries,
        };

        console.log(obj);

        if (obj.id === null)
            await handleRequest("POST", "/PresureInjuryHospitalization/add", obj);
        else
            await handleRequest("POST", "/PresureInjuryHospitalization/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        // if (Boolean(row.injuries))
        //     row.injuries.forEach(element => {
        //         if (Boolean(element.protocols))
        //         {element.oldProtocols=[];
        //             element.protocols.slice().forEach(i => {
        //                  let o = Object.assign({},i);
        //                  element.oldProtocols.push(o); 
        //             });
        //         }

        //     });

        this.formRef.current.setFieldsValue({
            patientId: row.patientId,
            dateAcquired: moment(new Date(row.dateAcquired)),
            roomNoId: row.roomNoId,
            areaAcquiredId: row.areaAcquiredId,
            dateTransferred: moment(new Date(row.dateTransferred)),
            statusId: row.statusId,
        });

        this.setState({
            id: row.id,
            patientId: row.patientId,
            dateAcquired: moment(new Date(row.dateAcquired)),
            roomNoId: row.roomNoId,
            areaAcquiredId: row.areaAcquiredId,
            dateTransferred: moment(new Date(row.dateTransferred)),
            statusId: row.statusId,
            injuries: row.injuries,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/PresureInjuryHospitalization/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }


    editInjury = (row) => {

        let protocols = [];
        if (Boolean(row.protocols)) {
            row.protocols.forEach(element => {
                protocols.push(element.protocolId);
            });
        }

        this.formRef.current.setFieldsValue({
            injuryId: row.id,
            siteId: row.siteId,
            date: Boolean(row.date) ? moment(row.date) : moment(),
            stageId: row.stageId,
            protocols: protocols,
            length: row.length,
            width: row.width,
            depth: row.depth,
            reviewDuration: row.reviewDuration,
            reviewDurationType: row.reviewDurationType,
            remarks: row.remarks,
        });
        this.setState({

            injuryId: row.id,
            siteId: row.siteId,
            date: Boolean(row.date) ? moment(row.date) : moment(),
            stageId: row.stageId,
            protocols: protocols,
            length: row.length,
            width: row.width,
            depth: row.depth,
            reviewDuration: row.reviewDuration,
            reviewDurationType: row.reviewDurationType,
            remarks: row.remarks,
            active: row.active

        });

    }

    deleteModalInjury = (row) => {
        this.setState({
            modalDeleteShow2: true,
            injuryId: row.id
        });
    }


    deleteInjury = async () => {

        var list = this.state.injuries;
        let index = list.findIndex(p => p.id === this.state.injuryId);
        list[index].active = false;
        this.setState({
            injuries: list
        })

        this.showSuccess();
        this.showOrHideDeleteModal();
    }



    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideDeleteModal2 = () => {
        this.setState({
            modalDeleteShow2: !this.state.modalDeleteShow2
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {

        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }
    actionTemplate2(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModalInjury.bind(this)} onEditRow={this.editInjury.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/PresureInjuryHospitalization/getAllByCompany");
        var injuryDetailList = [];
        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });


            list.forEach(record => {


                var data = [];
                if (Boolean(record.injuries) && record.injuries.length > 0) {
                    record.injuries.forEach(element => {

                        if (Boolean(injuryDetailList) && injuryDetailList.length > 0) {
                            let i = injuryDetailList.findIndex(p => p.id === record.id && p.siteId === element.siteId)

                            if (i >= 0) {
                                injuryDetailList[i].siteDetails.push(element);
                            }
                            else {
                                let obj = {
                                    id: record.id,
                                    siteId: element.siteId,
                                    siteDetails: [],
                                }
                                obj.siteDetails.push(element);
                                injuryDetailList.push(obj);
                            }
                        }
                        else {
                            let obj = {
                                id: record.id,
                                siteId: element.siteId,
                                siteDetails: [],
                            }
                            obj.siteDetails.push(element);
                            injuryDetailList.push(obj);
                        }


                    });
                }

                if (Boolean(injuryDetailList) && injuryDetailList.length > 0) {
                    let l = injuryDetailList.filter(p => p.id === record.id);

                    if (Boolean(l) && l.length > 0) {
                        l.forEach(element => {
                            if (Boolean(element.siteDetails) && element.siteDetails.length > 0) {
                                element.siteDetails[element.siteDetails.length - 1].siteSize = element.siteDetails.length;
                                data.push(element.siteDetails[element.siteDetails.length - 1]);
                            }
                        });
                    }
                }
                record.data = data;
            });

            this.setState({
                tableList: list,
                injuryDetailList: injuryDetailList
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            modalDeleteShow2: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            modalDeleteShow2: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {

        this.formRef.current.setFieldsValue({
            patientId: null,
            dateAcquired: moment(),
            roomNoId: null,
            areaAcquiredId: null,
            dateTransferred: moment(),
            statusId: null,
        });



        this.setState({
            id: null,
            patientId: null,
            dateAcquired: moment(),
            roomNoId: null,
            areaAcquiredId: null,
            dateTransferred: moment(),
            statusId: null,
            injuries: [],
            modalDeleteShow: false,
            modalDeleteShow2: false,
            hideSave: false,
            hideUpdate: true,
        });
    }


    cancel = () => {

        this.resetInputs();
    }



    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    addCodeRoom = async () => {
        const { codeName } = this.state;
        let pType = "PressureInjuryRoom";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);

            let result = await getCodeSelectItems(pType);

            this.setState({
                roomSelectItems: result,
                codeName: '',
            });
        }

    };

    addCodeAreaAcquired = async () => {
        const { codeName } = this.state;
        let pType = "PressureInjuryAreaAcquired";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);

            let result = await getCodeSelectItems(pType);

            this.setState({
                areaAcquiredSelectItems: result,
                codeName: '',
            });
        }

    };


    addCodeStatus = async () => {
        const { codeName } = this.state;
        let pType = "PressureInjuryStatus";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);

            let result = await getCodeSelectItems(pType);

            this.setState({
                statusSelectItems: result,
                codeName: '',
            });
        }

    };

    addCodeSite = async () => {
        const { codeName } = this.state;
        let pType = "PressureInjurySite";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);

            let result = await getCodeSelectItems(pType);

            this.setState({
                siteSelectItems: result,
                codeName: '',
            });
        }

    };

    addCodeStage = async () => {
        const { codeName } = this.state;
        let pType = "PressureInjuryStage";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);

            let result = await getCodeSelectItems(pType);

            this.setState({
                stageSelectItems: result,
                codeName: '',
            });
        }

    };

    addCodeProtocol = async () => {
        const { codeName } = this.state;
        let pType = "PressureInjuryProtocol";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);

            let result = await getCodeSelectItems(pType);

            this.setState({
                protocolSelectItems: result,
                codeName: '',
            });
        }

    };



    fillPatients = async () => {
        const response = await handleRequest("GET", "/PresureInjuryPatient/getAllByCompany");
        var list = [];
        if (Boolean(response) && Boolean(response.data)) {
            list = response.data;
        }
        else {
            list = []
        }
        this.setState({
            patientSelectItems: list,
        });
    }

    fillComboBoxes = async () => {

        const roomSelectItems = await getCodeSelectItems('PressureInjuryRoom');
        const areaAcquiredSelectItems = await getCodeSelectItems('PressureInjuryAreaAcquired');
        const siteSelectItems = await getCodeSelectItems('PressureInjurySite');
        const stageSelectItems = await getCodeSelectItems('PressureInjuryStage');
        const protocolSelectItems = await getCodeSelectItems('PressureInjuryProtocol');
        const statusSelectItems = await getCodeSelectItems('PressureInjuryStatus');

        this.setState({
            roomSelectItems: roomSelectItems,
            areaAcquiredSelectItems: areaAcquiredSelectItems,
            siteSelectItems: siteSelectItems,
            stageSelectItems: stageSelectItems,
            protocolSelectItems: protocolSelectItems,
            statusSelectItems: statusSelectItems


        });
    }

    addPatient = async () => {

        const obj = {

            mrn: this.state.mrn,
            patientName: this.state.patientName,

        }


        await handleRequest("POST", "/PresureInjuryPatient/add", obj);


        this.showSuccess();

        this.setState({
            patientName: '',
            mrn: '',
            hidePatientModal: true

        })

        this.fillPatients();

    }

    addInjury = () => {

        let list = Boolean(this.state.injuries) ? this.state.injuries : [];
        let filteredProtocols = [];
        let protocols = [];
        if (Boolean(this.state.siteId) && Boolean(this.state.length) && this.state.length > 0 && Boolean(this.state.width) && this.state.width > 0) {
            let site = this.state.siteSelectItems.find(p => p.id === this.state.siteId);
            let stage = this.state.stageSelectItems.find(p => p.id === this.state.stageId);
            if (Boolean(this.state.protocols)) {
                filteredProtocols = this.state.protocolSelectItems.filter(p => this.state.protocols.includes(p.id));

                filteredProtocols.forEach(element => {
                    let obj = { protocolId: element.id, protocol: element, active: true }
                    protocols.push(obj);
                });


            }

            var obj = {
                id: Boolean(this.state.injuryId) ? this.state.injuryId : list.length * -1,
                siteId: this.state.siteId,
                date: this.state.date,
                site: site,
                stageId: this.state.stageId,
                stage: stage,
                newProtocols: protocols,
                length: this.state.length,
                width: this.state.width,
                depth: this.state.depth,
                reviewDuration: this.state.reviewDuration,
                reviewDurationType: this.state.reviewDurationType,
                remarks: this.state.remarks,
                active: true
            }

            if (obj.id <= 0) {
                obj.protocols = protocols;
                list.push(obj);
            }
            else {

                let i = list.findIndex(p => p.id === obj.id);

                if (Boolean(list[i].protocols))
                    list[i].protocols.forEach(o => {
                        o.active = false;
                    });

                else list[i].protocols = [];
                obj.protocols = list[i].protocols;

                if (Boolean(protocols))
                    protocols.forEach(u => {
                        let j = obj.protocols.findIndex(p => p.protocolId === u.protocolId);

                        if (j >= 0)
                            obj.protocols[j].active = true;
                        else obj.protocols.push(u);

                    });


                list[i] = obj;
            }

            this.setState({
                injuries: list,
                injuryId: null,
                date: moment(),
                siteId: null,
                stageId: null,
                length: 0,
                width: 0,
                depth: 0,
                reviewDuration: 0,
                reviewDurationType: null,
                protocols: [],
                remarks: '',
            });

            this.formRef.current.setFieldsValue({
                siteId: null,
                stageId: null,
                date: moment(),
                length: 0,
                width: 0,
                depth: 0,
                reviewDuration: 0,
                reviewDurationType: null,
                protocols: [],
                remarks: '',
            });
        }
        else this.showError("Please select site and type length and width informations.")
    }

    expand = (record) => {
        var data = record.data;

        return <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={data} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


            <Column title="S/N" render={(value, row, index) => {

                return index + 1;

            }} />

            <Column title="Detail" render={(value, row, index) => {

                if (Boolean(row.siteSize) && row.siteSize > 1) {
                    return <AntButton style={{ border: '0px', alignContent: 'center' }} type="text" onClick={() => this.showDetailSites(row)} icon={<ZoomInOutlined style={{ fontSize: '24px' }} />} />

                }

            }} />

            <Column title="Site" render={(value, row, index) => {

                if (Boolean(row.site)) {
                    return row.site.name;
                }

            }} />

            <Column title="Date" render={(value, row, index) => {

                if (Boolean(row.date)) {
                    return moment(row.date).format("DD/MM/YYYY HH:mm");
                }

            }} />

            <Column title="Stage" render={(value, row, index) => {

                if (Boolean(row.stage)) {
                    return row.stage.name;
                }

            }} />
            <Column title="Size (cm)" render={(value, row, index) => {

                if (Boolean(row.depth) && row.depth > 0) {
                    return row.length + ' x ' + row.width + ' x ' + row.depth;

                }
                else return row.length + ' x ' + row.width;

            }} />

            <Column title="Duartion" render={(value, row, index) => {

                if (Boolean(row.reviewDuration) && Boolean(row.reviewDurationType)) {
                    return row.reviewDuration + ' ' + row.reviewDurationType + '(s)';
                }

            }} />

            <Column title="Protocols" render={(value, row, index) => {
                let result = [];
                if (Boolean(row.protocols) && row.protocols.length > 0) {
                    row.protocols.forEach(element => {
                        if (Boolean(element.protocol))
                            result.push(element.protocol.name);
                    });
                }

                return result.toString();
            }} />

            <Column title="Remarks" render={(value, row, index) => {

                return row.remarks;
            }} />


        </Table>



    }

    showDetailSites = (row) => {

        console.log(row);
        var list = [];
        var injuryDetailList = this.state.injuryDetailList;
        console.log(injuryDetailList);

        if (Boolean(injuryDetailList) && injuryDetailList.length > 0) {

            let f = injuryDetailList.find(p => p.siteId === row.siteId && p.id === row.hospitalizationId);

            if (Boolean(f)) {
                list = f.siteDetails;
            }
        }

        console.log(list);

        this.setState({
            siteDetailList: list,
            showSiteDetailModal: true
        })

    }

    exportFile = () => {
        let users = [["NO.", "MRN NO.", "PATIENT NAME", "DATE ACQUIRED", "AREA ACQUIRED", "DATE TRANSFERRED", "STATUS", "SITE", "DATE", "STAGE", "SIZE(cm)", "REVIEW DURATION", "PROTOCOL", "REMARKS"]];
        let patients = this.state.patientSelectItems;
        let patientCellMerge = [1, 0];
        let hospitalizationCellMerge = [1, 0];
        let siteCellMerge = [1, 0];
        var merge = [];
        let injuryDetailList = this.state.injuryDetailList;
        let i = 0 ;
        if (Boolean(patients) && patients.length > 0) {
            patients.forEach(per => {
                let tableList = this.state.tableList.filter(p => p.patientId === per.id);
                if(Boolean(tableList) && tableList.length>0)
                {
                    i = i + 1; 
                    tableList.forEach((record, index) => {

                    // let userArray = [index + 1, Boolean(record.patient) ? record.patient.mrn : '', Boolean(record.patient) ? record.patient.patientName : '',
                    // Boolean(record.dateAcquired) ? moment(record.dateAcquired).format("DD/MM/YYYY HH:mm") : '', Boolean(record.areaAcquired) ? record.areaAcquired.name : '',
                    // Boolean(record.dateTransferred) ? moment(record.dateTransferred).format("DD/MM/YYYY HH:mm") : '', Boolean(record.status) ? record.status.name : ''];

                    if (Boolean(record.injuries) && record.injuries.length > 0) {
                        record.injuries.forEach((element, j) => {
                            let arr = [i, Boolean(record.patient) ? record.patient.mrn : '', Boolean(record.patient) ? record.patient.patientName : '',
                            Boolean(record.dateAcquired) ? moment(record.dateAcquired).format("DD/MM/YYYY HH:mm") : '', Boolean(record.areaAcquired) ? record.areaAcquired.name : '',
                            Boolean(record.dateTransferred) ? moment(record.dateTransferred).format("DD/MM/YYYY HH:mm") : '', Boolean(record.status) ? record.status.name : '',
                            Boolean(element.site) ? element.site.name : '',
                            Boolean(element.date) ? moment(element.date).format("DD/MM/YYYY HH:mm") : '',
                            Boolean(element.stage) ? element.stage.name : '',
                            (Boolean(element.depth) && element.depth > 0) ? element.length + ' x ' + element.width + ' x ' + element.depth : element.length + ' x ' + element.width,
                            (Boolean(element.reviewDuration) && Boolean(element.reviewDurationType)) ? element.reviewDuration + ' ' + element.reviewDurationType + '(s)' : '',
                            (Boolean(element.protocols) && element.protocols.length > 0) ? getProtocols(element.protocols) : '',
                            Boolean(element.remarks) ? element.remarks : ''
                            ];


                            users.push(arr);

                        });
                        patientCellMerge[1] = patientCellMerge[1] + record.injuries.length;
                        hospitalizationCellMerge[1] = hospitalizationCellMerge[1] + record.injuries.length;

                        if (patientCellMerge[1] > 0) {
                            for (let u = 3; u < 7; u++) {
                                merge.push({ s: { r: hospitalizationCellMerge[0], c: u }, e: { r: hospitalizationCellMerge[1], c: u } });
                            }
                            hospitalizationCellMerge[0] = hospitalizationCellMerge[1] + 1;
                        }

                        if (Boolean(record.data) && record.data.length > 0 && Boolean(injuryDetailList)) {
                            record.data.forEach(d => {

                                let f = injuryDetailList.findIndex(p => p.id === record.id && p.siteId === d.siteId);
                                if (f >= 0) {
                                    if (Boolean(injuryDetailList[f].siteDetails) && injuryDetailList[f].siteDetails.length > 1) {
                                        siteCellMerge[1] = siteCellMerge[1] + injuryDetailList[f].siteDetails.length;
                                        merge.push({ s: { r: siteCellMerge[0], c: 7 }, e: { r: siteCellMerge[1], c: 7 } });

                                        siteCellMerge[0] = siteCellMerge[1] + 1;
                                    }
                                    else {
                                        siteCellMerge[1] = siteCellMerge[1] + 1;

                                        siteCellMerge[0] = siteCellMerge[1] + 1;
                                    }
                                }

                                else {
                                    siteCellMerge[1] = siteCellMerge[1] + 1;

                                    siteCellMerge[0] = siteCellMerge[1] + 1;
                                }

                            });
                        }


                    }
                    else {
                        patientCellMerge[1] = patientCellMerge[1] + 1;
                        hospitalizationCellMerge[1] = hospitalizationCellMerge[1] + 1;

                        patientCellMerge[0] = patientCellMerge[1] + 1;
                        hospitalizationCellMerge[0] = hospitalizationCellMerge[1] + 1;
                    }

                });

                if (patientCellMerge[1] > 0) {
                    for (let u = 0; u < 3; u++) {
                        merge.push({ s: { r: patientCellMerge[0], c: u }, e: { r: patientCellMerge[1], c: u } });
                    }
                    patientCellMerge[0] = patientCellMerge[1] + 1;
                }}


            });


            console.log(merge);
            const wb = XLSX.utils.book_new();
            //const wsAll = XLSX.utils.aoa_to_sheet(users)
            const wsAll = XLSX.utils.aoa_to_sheet(users);
            wsAll["!merges"] = merge;
            XLSX.utils.book_append_sheet(wb, wsAll, "All Patients")
            XLSX.writeFile(wb, "Pressure Injury.xlsx")
        }
        else this.showError("There are no patients registered.")
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };
        const layout3 = {
            labelCol: { span: 0 },
            wrapperCol: { span: 20 }
        };
        const layout4 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const columns = [
            { title: 'NO.', dataIndex: 'index', key: 'name' },
            {
                title: 'Actions', key: 'name', render: (record) => {
                    return this.actionTemplate(record);
                }
            },
            {
                title: 'MRN No.', key: 'name', render: (record) => {
                    if (Boolean(record.patient))
                        return record.patient.mrn;
                }
            },
            {
                title: 'Patient Name', key: 'name', render: (record) => {
                    if (Boolean(record.patient))
                        return record.patient.patientName;
                }
            },
            {
                title: 'Date Acquired', key: 'name', render: (record) => {
                    if (Boolean(record.dateAcquired))
                        return moment(record.dateAcquired).format("DD/MM/YYYY HH:mm");
                }
            },

            {
                title: 'Area Acquired', key: 'name', render: (record) => {
                    if (Boolean(record.areaAcquired))
                        return record.areaAcquired.name;
                }
            },

            {
                title: 'Date Transfered', key: 'name', render: (record) => {
                    if (Boolean(record.dateTransferred))
                        return moment(record.dateTransferred).format("DD/MM/YYYY HH:mm");
                }
            },

            {
                title: 'Status', key: 'name', render: (record) => {
                    if (Boolean(record.status))
                        return record.status.name;
                }
            },

        ];

        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="2">

                                            <ReactButton id="ExportButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.exportFile}><FormattedMessage id="ExportButton" defaultMessage="Export" /></ReactButton>

                                        </Col>
                                        <Col sm="8">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryMrnNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout2}

                                                label={<FormattedMessage id="PressureInjuryMrnNo" defaultMessage="Mrn No." />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="patientId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryMrnNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryMrnNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryMrnNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryMrnNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <Select style={{ width: "100%" }} placeholder="Mrn No." id="PressureInjuryMrnNo" value={this.state.patientId} onChange={(value) => {

                                                    this.setState({ patientId: value })
                                                }}>
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.patientSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.mrn}</Option>
                                                    ))}
                                                </Select>


                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryPatientName").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout4}

                                                label={<FormattedMessage id="PressureInjuryPatientName" defaultMessage="Patient Name" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="patientId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <FormAnt.Item

                                                    style={{
                                                        marginBottom: 0, marginRight: 5, display: 'inline-block', width: 'calc(50%)'
                                                    }}
                                                    name="patientId"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryPatientName").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                    <Select style={{ width: "100%" }} placeholder="Patient Name" id="PressureInjuryPatientName" value={this.state.patientId} onChange={(value) => {

                                                        this.setState({ patientId: value })
                                                    }}>
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.patientSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.patientName}</Option>
                                                        ))}
                                                    </Select>

                                                </FormAnt.Item>

                                                <FormAnt.Item

                                                    style={{
                                                        marginBottom: 0, display: 'inline-block', width: 'calc(30%)'
                                                    }}>


                                                    <AntButton style={{ width: '100%' }} onClick={() => {
                                                        this.setState({
                                                            hidePatientModal: false
                                                        })
                                                    }} > + Add New </AntButton>

                                                </FormAnt.Item>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryRoomNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout2}

                                                label={<FormattedMessage id="PressureInjuryRoomNo" defaultMessage="Room No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="roomNoId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryRoomNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryRoomNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryRoomNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryRoomNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Room No"
                                                    value={this.state.roomNoId} onChange={(value) => {

                                                        this.setState({
                                                            roomNoId: value
                                                        })
                                                    }}


                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                                                                    this.setState({
                                                                        codeName: event.target.value,
                                                                    });
                                                                }} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addCodeRoom}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.roomSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>


                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryDateAcquired").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout2}

                                                label={<FormattedMessage id="PressureInjuryDateAcquired" defaultMessage="Date Acquired" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="dateAcquired"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateAcquired")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateAcquired").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateAcquired")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateAcquired").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <DatePicker showTime id="PressureInjuryDateAcquired" format="DD-MM-YYYY HH:mm" value={this.state.dateAcquired} onOk={(value) => {

                                                    this.setState({
                                                        dateAcquired: value
                                                    })
                                                }} />


                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryAreaAcquired").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout2}

                                                label={<FormattedMessage id="PressureInjuryAreaAcquired" defaultMessage="Area Acquired" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="areaAcquiredId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryAreaAcquired")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryAreaAcquired").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryAreaAcquired")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryAreaAcquired").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Area Acquired"
                                                    value={this.state.areaAcquiredId} onChange={(value) => {

                                                        this.setState({
                                                            areaAcquiredId: value
                                                        })
                                                    }}


                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                                                                    this.setState({
                                                                        codeName: event.target.value,
                                                                    });
                                                                }} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addCodeAreaAcquired}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.areaAcquiredSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>


                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryDateTransferred").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout2}

                                                label={<FormattedMessage id="PressureInjuryDateTransferred" defaultMessage="Date Transferred" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="dateTransferred"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateTransferred")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateTransferred").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateTransferred")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryDateTransferred").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <DatePicker showTime id="PressureInjuryDateTransferred" format="DD-MM-YYYY HH:mm" value={this.state.dateTransferred} onOk={(value) => {

                                                    this.setState({
                                                        dateTransferred: value
                                                    })
                                                }} />


                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PressureInjuryStatus").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout2}

                                                label={<FormattedMessage id="PressureInjuryStatus" defaultMessage="Status" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="statusId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryStatus")) ? this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryStatus").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryStatus")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "PressureInjuryStatus").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Status"
                                                    value={this.state.statusId} onChange={(value) => {

                                                        this.setState({
                                                            statusId: value
                                                        })
                                                    }}


                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                                                                    this.setState({
                                                                        codeName: event.target.value,
                                                                    });
                                                                }} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addCodeStatus}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.statusSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </FormAnt.Item>
                                        }

                                        <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                            {
                                                <FormAnt.Item

                                                    name="todo"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                    <RowAnt gutter={[8, 8]}>
                                                        <ColAnt span={6}>
                                                        </ColAnt>

                                                        <ColAnt span={12}>
                                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                                                <FormattedMessage id="pressureInjuries" defaultMessage="Pressure Injuries" />
                                                            </Form.Label>
                                                        </ColAnt>
                                                    </RowAnt>

                                                </FormAnt.Item>}
                                            {
                                                <FormAnt.Item
                                                    {...layout2}

                                                    label={<FormattedMessage id="InjuryDate" defaultMessage="Date" />}

                                                    style={{
                                                        marginBottom: 0,
                                                    }}
                                                    name="date"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                    <DatePicker showTime id="InjuryDate" format="DD-MM-YYYY HH:mm" value={this.state.date} onChange={(value) => {

                                                        this.setState({
                                                            date: value
                                                        })
                                                    }} />

                                                </FormAnt.Item>
                                            }
                                            {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjurySite").visible) || this.state.dynamicAttributes.length === 0) &&
                                                <FormAnt.Item
                                                    {...layout2}

                                                    label={<FormattedMessage id="InjurySite" defaultMessage="Site" />}

                                                    style={{
                                                        marginBottom: 0,
                                                    }}
                                                    name="siteId"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjurySite")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjurySite").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjurySite")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjurySite").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Site"
                                                        value={this.state.siteId} onChange={(value) => {

                                                            this.setState({
                                                                siteId: value
                                                            })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCodeSite}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.siteSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>


                                                </FormAnt.Item>
                                            }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryStage").visible) || this.state.dynamicAttributes.length === 0) &&
                                                <FormAnt.Item
                                                    {...layout2}

                                                    label={<FormattedMessage id="InjuryStage" defaultMessage="Stage" />}

                                                    style={{
                                                        marginBottom: 0,
                                                    }}
                                                    name="stageId"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryStage")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryStage").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryStage")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryStage").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Stage"
                                                        value={this.state.stageId} onChange={(value) => {

                                                            this.setState({
                                                                stageId: value
                                                            })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCodeStage}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                                            </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.stageSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>


                                                </FormAnt.Item>
                                            }
                                            <FormAnt.Item {...layout2} label="Size (cm)" style={{ marginBottom: 0 }}>


                                                {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryLength").visible) || this.state.dynamicAttributes.length === 0) &&
                                                    <FormAnt.Item
                                                        {...layout3}
                                                        name="length"
                                                        style={{ display: 'inline-block', width: 'calc(33%)', marginBottom: 0 }}
                                                        rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryLength")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryLength").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryLength")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryLength").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                        <InputNumber id="InjuryLength" placeholder="Length" style={{ width: "100%" }}
                                                            min={0} value={this.state.length} onChange={(value) => {

                                                                this.setState({ length: value })
                                                            }} />
                                                    </FormAnt.Item>
                                                }

                                                {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryWidth").visible) || this.state.dynamicAttributes.length === 0) &&
                                                    <FormAnt.Item
                                                        {...layout3}
                                                        style={{ display: 'inline-block', width: 'calc(34%)', marginBottom: 0 }}
                                                        name="width"
                                                        rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryWidth")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryWidth").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryWidth")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryWidth").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                        <InputNumber id="InjuryWidth" placeholder="Width" style={{ width: "100%" }}
                                                            min={0} value={this.state.width} onChange={(value) => {

                                                                this.setState({ width: value })
                                                            }} />
                                                    </FormAnt.Item>
                                                }

                                                {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryDepth").visible) || this.state.dynamicAttributes.length === 0) &&
                                                    <FormAnt.Item

                                                        style={{ display: 'inline-block', width: 'calc(33%)', marginBottom: 0 }}
                                                        name="depth"
                                                        rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryDepth")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryDepth").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryDepth")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryDepth").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                        <InputNumber id="InjuryDepth" placeholder="Depth" style={{ width: "100%" }}
                                                            min={0} value={this.state.depth} onChange={(value) => {

                                                                this.setState({ depth: value })
                                                            }} />
                                                    </FormAnt.Item>
                                                }
                                            </FormAnt.Item>
                                            {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryReviewDuration").visible) || this.state.dynamicAttributes.length === 0) &&
                                                <FormAnt.Item
                                                    {...layout2}

                                                    label={<FormattedMessage id="InjuryReviewDuration" defaultMessage="Review Duration" />}

                                                    style={{
                                                        marginBottom: 0,
                                                    }}
                                                    name="reviewDuration"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                    {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryReviewDuration").visible) || this.state.dynamicAttributes.length === 0) &&
                                                        <FormAnt.Item
                                                            {...layout3}
                                                            style={{ display: 'inline-block', width: 'calc(50%)', marginBottom: 0 }}
                                                            name="reviewDuration"
                                                            rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDuration").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                            <InputNumber id="InjuryReviewDuration" style={{ width: "100%" }} placeholder="Review Duration"
                                                                min={0} value={this.state.reviewDuration} onChange={(value) => {

                                                                    this.setState({ reviewDuration: value })
                                                                }} />
                                                        </FormAnt.Item>
                                                    }
                                                    {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryReviewDurationType").visible) || this.state.dynamicAttributes.length === 0) &&
                                                        <FormAnt.Item


                                                            style={{ display: 'inline-block', width: 'calc(50%)', marginBottom: 0 }}
                                                            name="reviewDurationType"
                                                            rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDurationType")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDurationType").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDurationType")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryReviewDurationType").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                            <Select style={{ width: "100%" }} placeholder="Review Duration Type" id="InjuryReviewDurationType" value={this.state.reviewDurationType} onChange={(value) => {

                                                                this.setState({ reviewDurationType: value })
                                                            }}>
                                                                <Option key={null} value={null}>Select</Option>
                                                                {durationTypes.map(i => (
                                                                    <Option key={i.value} value={i.value}>{i.label}</Option>
                                                                ))}
                                                            </Select>
                                                        </FormAnt.Item>
                                                    }

                                                </FormAnt.Item>
                                            }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryProtocol").visible) || this.state.dynamicAttributes.length === 0) &&
                                                <FormAnt.Item
                                                    {...layout2}

                                                    label={<FormattedMessage id="InjuryProtocol" defaultMessage="Protocol" />}

                                                    style={{
                                                        marginBottom: 0,
                                                    }}
                                                    name="protocols"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryProtocol")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryProtocol").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryProtocol")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryProtocol").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        placeholder="Protocol"
                                                        value={this.state.protocols} onChange={(value) => {

                                                            this.setState({
                                                                protocols: value
                                                            })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCodeProtocol}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.protocolSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>


                                                </FormAnt.Item>
                                            }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InjuryRemarks").visible) || this.state.dynamicAttributes.length === 0) &&
                                                <FormAnt.Item
                                                    {...layout2}

                                                    label={<FormattedMessage id="InjuryRemarks" defaultMessage="Remarks" />}

                                                    style={{
                                                        marginBottom: 0,
                                                    }}
                                                    name="remarks"
                                                    rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryRemarks")) ? this.state.dynamicInputs.find(p => p.inputKey === "InjuryRemarks").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InjuryRemarks")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InjuryRemarks").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                    <Input placeholder="Remarks" style={{ width: "100%" }} id="InjuryRemarks" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />

                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item

                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row} >

                                                        <Col sm="0" md="4" lg="4">

                                                        </Col>
                                                        {!Boolean(this.state.injuryId) && <Col sm="12" md="1" lg="1">
                                                            <AntButton type="primary" style={{ width: '100%' }} onClick={this.addInjury} icon={<PlusOutlined />}> <FormattedMessage id="GeneralAddButton" defaultMessage="Add" /> </AntButton>

                                                        </Col>}
                                                        {Boolean(this.state.injuryId) && <Col sm="12" md="1" lg="1">
                                                            <AntButton style={{ width: '100%' }} type="primary" onClick={this.addInjury} icon={<EditOutlined />}> <FormattedMessage id="GeneralUpdateButton" defaultMessage="Update" /> </AntButton>

                                                        </Col>}

                                                    </Form.Group>

                                                </FormAnt.Item>}

                                            {this.state.injuries.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.injuries.filter(p => p.active === true)} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                                <Column title="S/N" render={(value, row, index) => {

                                                    return index + 1;

                                                }} />

                                                <Column title="Action" render={(value, row, index) => {

                                                    return this.actionTemplate2(row);

                                                }} />

                                                <Column title="Site" render={(value, row, index) => {

                                                    if (Boolean(row.site)) {
                                                        return row.site.name;
                                                    }

                                                }} />

                                                <Column title="Date" render={(value, row, index) => {

                                                    if (Boolean(row.date)) {
                                                        return moment(row.date).format("DD/MM/YYYY HH:mm");
                                                    }

                                                }} />

                                                <Column title="Stage" render={(value, row, index) => {

                                                    if (Boolean(row.stage)) {
                                                        return row.stage.name;
                                                    }

                                                }} />
                                                <Column title="Size (cm)" render={(value, row, index) => {

                                                    if (Boolean(row.depth) && row.depth > 0) {
                                                        return row.length + ' x ' + row.width + ' x ' + row.depth;

                                                    }
                                                    else return row.length + ' x ' + row.width;

                                                }} />

                                                <Column title="Duartion" render={(value, row, index) => {

                                                    if (Boolean(row.reviewDuration) && Boolean(row.reviewDurationType)) {
                                                        return row.reviewDuration + ' ' + row.reviewDurationType + '(s)';
                                                    }

                                                }} />

                                                <Column title="Protocols" render={(value, row, index) => {
                                                    let result = [];
                                                    if (Boolean(row.protocols) && row.protocols.length > 0) {
                                                        row.protocols.forEach(element => {
                                                            if (Boolean(element.active) && Boolean(element.protocol) && element.active === true)
                                                                result.push(element.protocol.name);
                                                        });
                                                    }

                                                    return result.toString();
                                                }} />

                                                <Column title="Remarks" render={(value, row, index) => {

                                                    return row.remarks;
                                                }} />


                                            </Table>}

                                        </div>



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <Row>
                                                    <Col sm="0" lg={4} md={4}>
                                                    </Col>

                                                    <Col sm="12" lg={4} md={4}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </Col>
                                                </Row>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="0" lg={4} md={4}>
                                                    </Col>
                                                    <Col sm="6" lg={2} md={2}>
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%', marginTop: '10px' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="6" lg={2} md={2}>
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%', marginTop: '10px' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>

                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                <Table
                                    className="components-table-demo-nested"
                                    bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }}
                                    columns={columns}
                                    expandable={{ expandedRowRender: record => this.expand(record) }}
                                    dataSource={this.state.tableList}
                                />
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalDeleteShow2}
                            onHide={this.showOrHideDeleteModal2}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal2}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.deleteInjury} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={!this.state.hidePatientModal}
                            onHide={() => {
                                this.setState({
                                    hidePatientModal: true,
                                    mrn: '',
                                    patientName: ''

                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Add Patient</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                    <Col sm="12">
                                        <Input style={{ width: "100%", marginBottom: "1rem" }} placeholder="MRN NO." value={this.state.mrn} onChange={(e) => this.setState({ mrn: e.target.value })} />

                                    </Col>
                                    <Col sm="12">
                                        <Input style={{ width: "100%", marginBottom: "1rem" }} placeholder="Patient Name" value={this.state.patientName} onChange={(e) => this.setState({ patientName: e.target.value })} />

                                    </Col>

                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        hidePatientModal: true,
                                        mrn: '',
                                        patientName: ''

                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addPatient} >Add</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            size="lg"
                            show={this.state.showSiteDetailModal}
                            onHide={() => {
                                this.setState({
                                    showSiteDetailModal: false,
                                    siteDetailList: []

                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Injury Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.siteDetailList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                    <Column title="S/N" render={(value, row, index) => {

                                        return index + 1;

                                    }} />


                                    <Column title="Site" render={(value, row, index) => {

                                        if (Boolean(row.site)) {
                                            return row.site.name;
                                        }

                                    }} />

                                    <Column title="Date" render={(value, row, index) => {

                                        if (Boolean(row.date)) {
                                            return moment(row.date).format("DD/MM/YYYY HH:mm");
                                        }

                                    }} />

                                    <Column title="Stage" render={(value, row, index) => {

                                        if (Boolean(row.stage)) {
                                            return row.stage.name;
                                        }

                                    }} />
                                    <Column title="Size (cm)" render={(value, row, index) => {

                                        if (Boolean(row.depth) && row.depth > 0) {
                                            return row.length + ' x ' + row.width + ' x ' + row.depth;

                                        }
                                        else return row.length + ' x ' + row.width;

                                    }} />

                                    <Column title="Duartion" render={(value, row, index) => {

                                        if (Boolean(row.reviewDuration) && Boolean(row.reviewDurationType)) {
                                            return row.reviewDuration + ' ' + row.reviewDurationType + '(s)';
                                        }

                                    }} />

                                    <Column title="Protocols" render={(value, row, index) => {
                                        let result = [];
                                        if (Boolean(row.protocols) && row.protocols.length > 0) {
                                            row.protocols.forEach(element => {
                                                if (Boolean(element.protocol))
                                                    result.push(element.protocol.name);
                                            });
                                        }

                                        return result.toString();
                                    }} />

                                    <Column title="Remarks" render={(value, row, index) => {

                                        return row.remarks;
                                    }} />


                                </Table>


                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showSiteDetailModal: false,
                                        siteDetailList: []

                                    })
                                }}>Close</ReactButton>

                            </Modal.Footer>
                        </Modal>


                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PressureInjury) 
