/* eslint-disable */

import React, { Component } from 'react';
import classes from './LocationActivityStyle.module.scss';
import { TreeTable } from 'primereact/treetable';
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Button as PrimeButton } from "primereact/button";
import { SplitButton } from 'primereact/splitbutton';
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { connect } from 'react-redux';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Card, Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { OrganizationChart } from 'primereact/organizationchart';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import $ from 'jquery';
import classNames from 'classnames';
import { ToggleButton } from 'primereact/togglebutton';
import './OrganizationChartCustomStyle.css';
import { API_BASE } from '../../redux/config/env';
import { ExcelReader } from '../excel/ExcelReaderAntButton';
import { InputText } from 'primereact/inputtext';
import { Tree, Input, InputNumber } from 'antd';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Upload, message, Button as AntButton, DatePicker, Checkbox } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { findDOMNode } from 'react-dom';
import { OrderList } from 'primereact/orderlist';
import CreateIcon from '@material-ui/icons/AddCircle';
import ExportIcon from '@material-ui/icons/CloudDownloadRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';

const { Search } = Input;

var dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        console.log(node);
        dataList.push({ key: node.key, title: node.title });
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class LocationActivity2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
            globalFilter: null,
            expandedKeys: {},
            loadedKeys: [],
            selectedNodeKey: null,
            selectedNodeKey2: null,
            selectedNode: null,
            parentLocations: [],
            parentActivities: [],
            parentFCActivities: [],
            activityFCParent: "Select Parent Activity",
            hideActivityFCParent: true,
            locationParentWillChangeId: 0,
            activityParentWillChangeId: 0,
            leftX: 0,
            topY: 0,
            disableParentLocation: false,
            disableParentActivity: false,
            showCode: true,
            showLocations: true,
            showActivities: true,

            displayOrderDialog: false,
            orderChildLocationList: [],

            locationId: 0,
            locationCode: "",
            locationName: "",
            locationParentId: 0,
            locationParent: "",

            selectedActivityNodeKeys: [],
            halfCheckedActivityKeys: [],
            activityNodes: [],

            FCActivityId: 0,
            activityId: 0,
            activityCode: "",
            activityName: "",
            activityParentId: 0,
            activityFCParentId: 0,
            activityParent: "",

            activityType: 0,
            activity: 0,
            discipline: 0,
            activityList: [],
            disciplineOptions: [],
            activityTypeOptions: [],
            activityOptions: [],

            displayDialog: false,
            displayActivityDialog: false,
            displayFlowchartDialog: false,

            displayCopyDialog: false,
            copyNumber: 1,

            hideLocationInputs: true,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",

            flowChartType: "",
            flowChartBelongId: 0,

            flowChartData: [],
            modalActivityDeleteShow: false,
            modalDeleteNoteShow: false,

            expandedKeysAnt: [],
            searchValueAnt: '',
            autoExpandParent: true,
            searchValue: '',

            displayNoteDialog: false,
            displayFileDialog: false,
            note: '',
            lastUpdatedBy: '',
            lastUpdatedTime: '',
            noteFilePath: null,
            processType: null,
            filePath: null,
            fileName: '',
            searchFlowchart: false,
            locationActivityCode: '',

            displayTimeDialog: '',
            startingTime: null,

            data: [{
                label: '',
                className: classNames(classes.departmentCto),
                expanded: true,
                children: [

                ]
            }],

            menu: [
                {
                    label: 'Create',
                    icon: 'pi pi-fw pi-file',
                    command: (event) => {
                        console.log(event);
                        this.openLocationChildDialog();

                    },
                    items: [
                        {
                            label: 'Child',
                            data: 5,
                            icon: 'pi pi-fw pi-plus',
                            command: (event) => {
                                console.log(event);


                                this.openLocationChildDialog();

                                setTimeout(
                                    function () {
                                        document.getElementById("ModalLocationName").focus();
                                    }
                                    ,
                                    500
                                );


                            }

                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {

                                this.openLocationParentDialog();
                                setTimeout(
                                    function () {
                                        document.getElementById("ModalLocationName").focus();
                                    }
                                    ,
                                    500
                                );

                            }

                        },
                        {
                            label: 'Activity',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {

                                this.openLocationActivityDialog();

                            }

                        },
                        {
                            label: 'FlowChart',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {

                                this.openLocationFlowchartDialog();

                            }

                        }

                    ]

                },

                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {

                        this.openLocationEditDialog();
                        setTimeout(
                            function () {
                                document.getElementById("ModalLocationName").focus();
                            }
                            ,
                            500
                        );

                    }
                },
                {
                    label: 'Add/Show Note',
                    icon: 'pi pi-fw pi-id-card',
                    command: () => {

                        this.openLocationNoteDialog();


                    }
                },
                {
                    label: 'Add/Show File',
                    icon: 'pi pi-fw pi-paperclip',
                    command: () => {

                        this.openLocationFileDialog();


                    }
                },
                {
                    separator: true
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        //  this.toast.show({severity: 'success', summary: 'Node Key', detail: this.state.selectedNodeKey});
                        this.deleteModal();
                    }
                },
                {
                    label: 'Coppy',
                    icon: 'pi pi-copy',
                    command: (event) => {
                        console.log(event);
                        this.openLocationCopyDialog();
                    }
                },
                {
                    label: 'Order Children',
                    icon: 'pi pi-copy',
                    command: (event) => {
                        console.log(event);
                        this.openOrderChildrenDialog();
                    }
                }
            ]
            ,

            menu2: [
                {
                    label: 'Create FlowChart',
                    icon: 'pi pi-fw pi-plus',

                    command: () => {

                        this.openActivityFlowchartDialog();

                    }

                },

                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {

                        this.openActivityEditDialog();

                    }
                },
                {
                    label: 'Add/Show Note',
                    icon: 'pi pi-fw pi-id-card',
                    command: () => {

                        this.openActivityNoteDialog();


                    }
                },
                {
                    label: 'Add/Show File',
                    icon: 'pi pi-fw pi-paperclip',
                    command: () => {

                        this.openActivityFileDialog();


                    }
                },
                {
                    label: 'Starting Time',
                    icon: 'pi pi-fw pi-calendar',
                    command: () => {

                        this.openActivityTimeDialog();


                    }
                },
                {
                    separator: true
                },

                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        //  this.toast.show({severity: 'success', summary: 'Node Key', detail: this.state.selectedNodeKey});
                        this.deleteActivityModal();
                    }
                }

            ]
        };

        this.codeNameTemplate = this.codeNameTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
    }

    componentDidMount = async () => {
        this.restartTable();

    }


    componentWillMount = async () => {


        var flagMenu = this.state.menu;
        this.restartTable();

        this.setState({
            flowChartData: this.state.data,
            menu: flagMenu
        });

        document.addEventListener('contextmenu', this._handleContextMenu);

        // this.getFCParentActivitties();

        const responseDiscipline = await handleRequest("GET", "/Discipline/getallByCompany");


        if (responseDiscipline !== undefined && responseDiscipline !== null && responseDiscipline.data.length !== 0) {

            const options = responseDiscipline.data.map(function (row) {
                return { label: row.disciplineName.toString(), value: row.id }
            })
            console.log(options);

            this.setState({
                disciplineOptions: options,
            });

        }

        const activityTypes = await handleRequest("GET", "/Code/type/ACTIVITYTYPE");

        if (activityTypes !== undefined && activityTypes !== null && activityTypes.data.length !== 0) {

            const options = activityTypes.data.map(function (row) {
                return { label: row.name.toString(), value: row.id }
            })
            console.log(options);

            this.setState({
                activityTypeOptions: options,
            });

        }

        /* const responseActivity = await handleRequest("GET", "/WFActivity/getAllByCompany");
 
 
         if (responseActivity !== undefined && responseActivity !== null && responseActivity.data.length !== 0) {
 
 
 
             this.setState({
                 activityList: responseActivity.data,
             });
 
         }*/


    }

    fillActivityList = async () => {
        const responseActivity = await handleRequest("GET", "/WFActivity/getAllByCompany");

        console.log(responseActivity); 
        if (responseActivity !== undefined && responseActivity !== null && responseActivity.data.length !== 0) {

            this.setState({
                activityList: responseActivity.data,
                parentActivities: responseActivity.data
            });

        }
    }

    _handleContextMenu = (event) => {

        event.stopPropagation();
        event.preventDefault();

        /*this.setState({ leftX: event.clientX-264 , topY:event.clientY-141 }); 
         console.log(document.getElementsByClassName('p-contextmenu p-component')[0].style);   
         document.getElementsByClassName('p-contextmenu p-component')[0].style.left=event.clientX-264+"px";//this.state.leftX;
         document.getElementsByClassName('p-contextmenu p-component')[0].style.top= event.clientY-141+"px" ;//this.state.topY; 
         console.log(document.getElementsByClassName('p-contextmenu p-component')[0].style);   
      
         console.log($(".p-contextmenu"));  */

        setTimeout(function () {
            $(".p-contextmenu").css("left", event.clientX - 264 + "px");
            $(".p-contextmenu").css("top", event.clientY - 141 + "px");
        }, 50);
        // event.clientX= event.clientX - 264 ;
        //event.clientX= event.clientX - 141 ;


    }

    handleExcelData = async (excelJson) => {
        var list = [];
        if (excelJson === null)
            this.showError("Select an excel file");
        else {

            excelJson.forEach(element => {
                list.push({ code: element.Code, name: element.Name, type: element.Type });
            });

            await handleRequest("POST", "/WFLocation/import", list);
            this.showSuccess();
            this.restartTable();
            this.resetInputs();
        }


    };

    fillLocations = async () => {

        const response1 = await handleRequest("GET", "/WFLocation/getAllByCompany");

        if (response1.data.length === 0) {
            this.setState({
                parentLocations: []

            });
        }

        if (response1.data.length !== 0) {
            console.log(response1.data);
            const options = response1.data.map(function (row) {
                return { name: row.value, id: row.key.toString() }
            })

            this.setState({
                parentLocations: options,
            });

        }

    }

    restartTable = async () => {

        await handleRequest("GET", "/WFLocation/refresh");

        this.fillLocations();
        this.fillActivityList();
        /* const response2 = await handleRequest("GET", "/WFActivity/getAll");
  
          if (response2.data.length === 0) {
              this.setState({
                  parentActivities: []
  
              });
          }
  
          if (response2.data.length !== 0) {
  
              const options = response2.data.map(function (row) {
                  return { name: row.activityName.toString(), id: row.id.toString() }
              })
  
              this.setState({
                  parentActivities: options,
              });
  
  
          }*/


        var response = [];
        if (this.state.showLocations && this.state.showActivities) {
            response = await handleRequest("GET", "/WFLocation/getRootLocations");
            //response = await handleRequest("GET", "/WFLocation/getNodes");
        }
        else
            if (this.state.showLocations) {
                response = await handleRequest("GET", "/WFLocation/getNodesLocations");
            } else
                if (this.state.showActivities) {
                    response = await handleRequest("GET", "/WFLocation/getNodesActivities");
                }
                else response = [];


        if (response.data !== undefined && response.data !== null && response.data.length !== 0) {
            //var treeNode = { root: response.data };
            generateList(response.data);
            console.log(response.data);
            this.setState({
                nodes: response.data
            });


        }


    }

    createNew = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hideLocationInputs: false,
                createNewButton: "Stop Adding New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "danger",
                locationId: 0,
                locationCode: "",
                locationName: "",
                locationParentId: 0

            });
            //this.refreshAccordions();
        }
        else {
            this.setState({
                hideLocationInputs: true,
                createNewButton: "Create New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "info",
                locationId: 0,
                locationCode: "",
                locationName: "",
                locationParentId: 0,
            });
            //this.refreshAccordions();
        }
    };

    saveLocation = async () => {



        if (this.state.locationName.trim() !== "") {


            const newItem = {
                id: this.state.locationId,
                locationName: this.state.locationName,
                parentId: this.state.locationParentId,
                parentWillChangeId: this.state.locationParentWillChangeId

            }

            if (newItem.id === 0) {

                if (newItem.parentWillChangeId === 0) {
                    await handleRequest("POST", "/WFLocation/add", newItem);
                } else {
                    await handleRequest("POST", "/WFLocation/addParent", newItem);
                }
            }
            else await handleRequest("POST", "/WFLocation/update", newItem);


            // this.restartTable(); 
            this.expandSelectedNode();
            this.fillLocations();
            this.showSuccess();
            this.resetInputs();
        } else {
            this.showError();
        }
    };

    saveLocation2 = async () => {



        if (this.state.locationName.trim() !== "") {


            const newItem = {
                id: this.state.locationId,
                locationName: this.state.locationName,
                parentId: this.state.locationParentId,
                parentWillChangeId: this.state.locationParentWillChangeId

            }

            if (newItem.id === 0) {

                if (newItem.parentWillChangeId === 0) {
                    await handleRequest("POST", "/WFLocation/add", newItem);
                } else {
                    await handleRequest("POST", "/WFLocation/addParent", newItem);
                }
            }
            else await handleRequest("POST", "/WFLocation/update", newItem);


             this.restartTable();             
       
            this.showSuccess();
            this.resetInputs();
        } else {
            this.showError();
        }
    };

    copyLocation = async () => {

        const newItem = {
            id: this.state.locationId,
            copyNumber: this.state.copyNumber
        }

        await handleRequest("POST", "/WFLocation/copy", newItem);

        this.resetInputs();
        this.restartTable();
        this.expandSelectedNode();
        this.showSuccess();

    };

    saveNote = async () => {
        if (this.state.note.trim() !== "") {

            if (this.state.processType !== null && this.state.processType === "location") {
                const newItem = {
                    id: this.state.locationId,
                    note: this.state.note,
                    noteFilePath: this.state.filePath


                }

                await handleRequest("POST", "/WFLocation/saveNote", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
            }

            if (this.state.processType !== null && this.state.processType === "activity") {
                const newItem = {
                    id: this.state.activityId,
                    note: this.state.note,
                    noteFilePath: this.state.filePath

                }

                await handleRequest("POST", "/WFActivity/saveNote", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
            }


        }
        else {
            this.showError("Please type any note.");
        }

    };

    deleteNoteModal = () => {
        this.setState({
            modalDeleteNoteShow: true
        })
    }

    deleteNote = async () => {


        if (this.state.note.trim() !== "") {

            if (this.state.processType !== null && this.state.processType === "location") {
                const newItem = {
                    id: this.state.locationId,
                    note: "",
                    noteFilePath: ''



                }

                await handleRequest("POST", "/WFLocation/saveNote", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
                this.setState({
                    note: '',
                    modalDeleteNoteShow: false
                })
            }

            if (this.state.processType !== null && this.state.processType === "activity") {
                const newItem = {
                    id: this.state.activityId,
                    note: "",
                    noteFilePath: ''

                }

                await handleRequest("POST", "/WFActivity/saveNote", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
                this.setState({
                    note: '',
                    modalDeleteNoteShow: false
                })
            }


        }
        else {
            this.showError("There is no note detected for delete.");
        }

    }

    SaveFile = async () => {



        if (this.state.filePath !== null && this.state.filePath.trim() !== "") {

            if (this.state.processType !== null && this.state.processType === "location") {
                const newItem = {

                    filePath: this.state.filePath,
                    fileName: this.state.fileName,
                    locationId: this.state.locationId

                }

                await handleRequest("POST", "/WFLocationFile/add", newItem);


                this.restartFileTable(newItem.locationId);
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
                this.setState({

                    filePath: null,
                });
            }

            if (this.state.processType !== null && this.state.processType === "activity") {
                const newItem = {
                    filePath: this.state.filePath,
                    fileName: this.state.fileName,
                    activityId: this.state.activityId
                }

                await handleRequest("POST", "/WFActivityFile/add", newItem);


                this.restartFileTable(newItem.activityId);
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
                this.setState({

                    filePath: null,
                });
            }


        }
        else {
            this.showError();
        }

    };

    restartFileTable = async (pId) => {


        if (this.state.processType !== null && this.state.processType === "location") {

            var _fileList = await handleRequest("GET", "/WFLocation/" + pId);

            console.log(pId);
            console.log(_fileList);

            if (_fileList !== undefined && _fileList.data !== null && _fileList.data !== undefined && _fileList.data.files !== null && _fileList.data.files !== undefined) {
                this.setState({
                    fileList: _fileList.data.files
                })
            }

        }

        if (this.state.processType !== null && this.state.processType === "activity") {

            _fileList = await handleRequest("GET", "/WFActivity/" + pId);

            if (_fileList !== undefined && _fileList.data !== null && _fileList.data !== undefined && _fileList.data.files !== null && _fileList.data.files !== undefined) {
                this.setState({
                    fileList: _fileList.data.files
                })
            }

        }
    }

    saveStartingTime = async () => {



        const newItem = {
            id: this.state.activityId,
            startDate: this.state.startingTime.toDate()
        }

        await handleRequest("POST", "/WFLocationActivity/updateStartDate", newItem);

        this.resetInputs();
        this.restartTable();
        this.expandSelectedNode();
        this.showSuccess();


    }

    saveActivity = async () => {

        if (this.state.activity !== 0) {

            let list = [];

            this.state.selectedActivityNodeKeys.forEach(element => {


                const newItem = {

                    locationId: this.state.locationId,
                    activityId: element,
                    code: this.state.locationActivityCode,
                    parent: false

                }

                if (element == this.state.activity) {
                    console.log("girdi ");
                    newItem.parent = true;
                }

                list.push(newItem);
            });

            this.state.halfCheckedActivityKeys.forEach(element => {


                const newItem = {

                    locationId: this.state.locationId,
                    activityId: element,
                    code: this.state.locationActivityCode,
                    parent: false

                }

                if (element == this.state.activity) {
                    console.log("girdi ");
                    newItem.parent = true;
                }

                list.push(newItem);
            });


            // if (newItem.id === 0) {

            //     if (newItem.parentWillChangeId === 0) {
            //         await handleRequest("POST", "/WFActivity/add", newItem);
            //     } else {
            //         await handleRequest("POST", "/WFActivity/addParent", newItem);
            //     }
            // }
            // else

            await handleRequest("POST", "/WFLocationActivity/saveAll", list);

            this.resetInputs();
           // this.restartTable();
            this.expandSelectedNode();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    addFlowchart = async () => {

        console.log(this.state.selectedNodeKey2);

        if (this.state.activityName.trim() !== "" && !(this.state.parentFCActivities.length > 0 && this.state.activityFCParentId === 0)
            && !(this.state.FCActivityId === 0 && this.state.flowChartType === "activity" && this.state.parentFCActivities.length > 0 && this.state.activityFCParent.trim() === "Select Parent Activity")) {

            const newItem = {
                id: this.state.FCActivityId,
                activityName: this.state.activityName,
                code: this.state.activityCode,
                parentId: this.state.activityFCParentId,
                locationId: this.state.locationId,
                parentWillChangeId: this.state.activityParentWillChangeId,
                flowChartType: this.state.flowChartType,
                flowChartBelongId: this.state.flowChartBelongId,
                flowChartItem: true

            }


            if (this.state.parentFCActivities.length > 0) {
                this.setState({ locationId: 0 })
            }

            if (newItem.id > 0)
                await await handleRequest("POST", "/WFActivity/update", newItem);
            else
                await await handleRequest("POST", "/WFActivity/add", newItem);


            //else await await handleRequest("POST", "/WFActivity/update", newItem); 
            this.getFCParentActivitties();
            this.resetInputs();
            // this.restartTable();
            this.showSuccess();
        } else {
            this.showError("Please type inputs and select parent activity !");
        }
    };

    resetInputs = () => {

        this.setState({
            modalDeleteShow: false,
            displayDialog: false,
            hideLocationInputs: true,
            displayNoteDialog: false,
            displayCopyDialog: false,
            displayTimeDialog: false,
            displayOrderDialog: false,
            orderChildLocationList: [],
            loadedKeys: [],
            copyNumber: 1,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",
            locationId: 0,
            locationCode: "",
            locationName: "",
            locationParentId: 0,
            locationParentWillChangeId: 0,
            activityParentWillChangeId: 0,
            activityId: 0,
            modalActivityDeleteShow: false,
            FCActivityId: 0,
            displayActivityDialog: false,
            filePath: null,
            noteFilePath: null,
            selectedActivityNodeKeys: [],
            activity: 0,
            locationActivityCode: '',


        });
    };

    openOrderChildrenDialog = async () => {
        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');
        var list = [];

        let obj = { id: node.split(".")[1] };

        var response = await handleRequest("POST", "/WFLocation/getSubLocations", obj);


        if (response.data != null) {
            const data = response.data;
            console.log(data);
            data.forEach(element => {
                let o = { key: element.key, title: element.title };
                list.push(o);
            });
            console.log(list);

            this.setState({
                displayOrderDialog: true,
                locationId: parseInt(arr[1]),
                orderChildLocationList: list,
                selectedNodeKey2: node
            });

        }
    }

    expandSelectedNode = () => {

        console.log(this.state.selectedNodeKey2);
        console.log("expandSelectedNode", this.state.selectedNodeKey2);
        let key = this.state.selectedNodeKey2 + "";
        let node = this.state.selectedNode;
        // let expandedKeys = { ...this.state.expandedKeys };

        // if (!expandedKeys[this.state.selectedNodeKey2])
        //     expandedKeys[this.state.selectedNodeKey2] = true;

        // console.log("expandSelectedNode", expandedKeys); 
        // this.setState({ expandedKeys: expandedKeys });

        let expandedKeysAnt = Boolean(this.state.expandedKeysAnt) ? this.state.expandedKeysAnt : [];
        if (Boolean(expandedKeysAnt.find(p => p === key)))
            this.loadData(node);
        else
            this.setState({
                expandedKeysAnt: [...expandedKeysAnt, key]
            });
    }


    openLocationEditDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');

        //  this.fillParentLocations();
        var response = await await handleRequest("GET", "/WFLocation/" + arr[1]);
        var item;
        if (response !== undefined && response !== null)
            item = response.data;

        var parent = "Select Parent Location";
        if (item.parentId > 0) {
            var _selectedNod = this.state.parentLocations.find(p => p.id == item.parentId);
            parent = _selectedNod.name;
        }


        this.setState({
            displayDialog: true,
            locationParent: parent,
            locationParentId: item.parentId,
            locationId: arr[1],
            locationName: item.locationName,
            locationCode: item.code,
            selectedNodeKey2: node
        })


    }

    openLocationNoteDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');


        var response = await handleRequest("GET", "/WFLocation/" + arr[1]);
        var _note = '';
        var _noteFilePath = null;
        var _lastUpdatedBy = '';
        var _lastUpdatedTime = '';

        if (response !== undefined && response !== null) {
            if (response.data !== null && response.data !== undefined && response.data.note !== null && response.data.note.length > 0) {

                _note = response.data.note;
                _noteFilePath = response.data.noteFilePath;

                if (Boolean(response.data.lastNoteUpdatedUser))
                    _lastUpdatedBy = response.data.lastNoteUpdatedUser.staff.name + ' ' + response.data.lastNoteUpdatedUser.staff.midName + ' ' + response.data.lastNoteUpdatedUser.staff.lastName;
                if (Boolean(response.data.lastNoteUpdatedTime))
                    _lastUpdatedTime = moment(response.data.lastNoteUpdatedTime).format("DD-MM-YYYY HH:mm:ss");
            }
        }

        this.setState({
            displayNoteDialog: true,
            processType: 'location',
            note: _note,
            noteFilePath: _noteFilePath,
            lastUpdatedBy: _lastUpdatedBy,
            lastUpdatedTime: _lastUpdatedTime,
            locationId: arr[1],
            selectedNodeKey2: node
        })


    }


    openActivityNoteDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');


        var response = await handleRequest("GET", "/WFActivity/" + arr[1]);
        var _note = '';
        var _noteFilePath = null;
        var _lastUpdatedBy = '';
        var _lastUpdatedTime = '';

        if (response !== undefined && response !== null) {
            if (response.data !== null && response.data !== undefined && response.data.note !== null && response.data.note.length > 0) {
                _note = response.data.note;
                _noteFilePath = response.data.noteFilePath;

                if (Boolean(response.data.lastNoteUpdatedUser))
                    _lastUpdatedBy = response.data.lastNoteUpdatedUser.staff.name + ' ' + response.data.lastNoteUpdatedUser.staff.midName + ' ' + response.data.lastNoteUpdatedUser.staff.lastName;
                if (Boolean(response.data.lastNoteUpdatedTime))
                    _lastUpdatedTime = moment(response.data.lastNoteUpdatedTime).format("DD-MM-YYYY HH:mm:ss");
            }
        }

        this.setState({
            displayNoteDialog: true,
            processType: 'activity',
            note: _note,
            lastUpdatedBy: _lastUpdatedBy,
            lastUpdatedTime: _lastUpdatedTime,
            noteFilePath: _noteFilePath,
            activityId: arr[1],
            selectedNodeKey2: node
        })


    }

    openLocationFileDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');


        var response = await await handleRequest("GET", "/WFLocation/" + arr[1]);
        console.log(response);
        var _fileList = [];
        if (response !== undefined && response !== null) {
            if (response.data !== null && response.data !== undefined && response.data.files !== null && response.data.files !== undefined && response.data.files.length > 0) {
                _fileList = response.data.files;
            }
        }

        this.setState({
            displayFileDialog: true,
            processType: 'location',
            fileList: _fileList,
            locationId: arr[1],
            selectedNodeKey2: node
        })

        //fileList
    }

    openActivityFileDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');


        var response = await await handleRequest("GET", "/WFActivity/" + arr[1]);
        var _fileList = [];
        if (response !== undefined && response !== null) {
            if (response.data !== null && response.data !== undefined && response.data.note !== null && response.data.files.length > 0) {
                _fileList = response.data.files;
            }
        }

        this.setState({
            displayFileDialog: true,
            processType: 'activity',
            fileList: _fileList,
            activityId: arr[1],
            selectedNodeKey2: node
        })

        //fileList
    }

    openActivityTimeDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');


        this.setState({
            displayTimeDialog: true,
            processType: 'activity',
            activityId: arr[1],
            selectedNodeKey2: node
        })

        //fileList
    }

    openLocationChildDialog = () => {
        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');
        var _selectedNod = { name: "Select Parent Location", id: 0 };

        var flag = this.state.parentLocations.find(p => p.id == arr[1]);

        if (flag !== undefined)
            _selectedNod = flag;

        this.setState({
            displayDialog: true,
            disableParentLocation: true,
            locationParent: _selectedNod.name,
            locationParentId: _selectedNod.id,
            locationId: 0,
            locationName: "",
            locationCode: "",
            selectedNodeKey2: node
        })


    }


    openLocationCopyDialog = () => {
        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');
        var _selectedNod = { name: "Select Parent Location", id: 0 };
        var flag = this.state.parentLocations.find(p => p.id == arr[1]);

        if (flag !== undefined)
            _selectedNod = flag;

        this.setState({
            displayCopyDialog: true,
            locationId: _selectedNod.id,
            locationName: "",
            locationCode: "",
            selectedNodeKey2: node
        })


    }

    openLocationParentDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');
        var _selectedNod = { name: '' };

        var response = await await handleRequest("GET", "/WFLocation/" + arr[1]);
        var item;
        if (response !== undefined || response !== null)
            item = response.data;

        if (item.parentId > 0)
            _selectedNod = this.state.parentLocations.find(p => p.id == item.parentId);

        this.setState({
            displayDialog: true,
            disableParentLocation: true,
            locationParent: _selectedNod.name,
            locationParentId: item.parentId,
            locationId: 0,
            locationName: "",
            locationCode: "",
            locationParentWillChangeId: item.id,
            selectedNodeKey2: node
        })


    }

    openLocationActivityDialog = async () => {

        var node = this.state.selectedNodeKey;

        var arr = this.state.selectedNodeKey.split('.');
        // this.fillActivityList();
        this.setState({
            displayActivityDialog: true,
            disableParentActivity: true,
            locationId: arr[1],
            activityName: "",
            activityCode: "",
            activityParentId: 0,
            selectedNodeKey2: node

        })


    }

    openLocationFlowchartDialog = async () => {

        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');
        // this.fillActivityList();

        this.setState({
            //displayFlowchartDialog: true,
            displayActivityDialog: true,
            locationId: arr[1],
            activityFCParentId: 0,
            activityName: "",
            activityCode: "",
            activityParentId: 0,
            flowChartType: "location",
            flowChartBelongId: arr[1],
            selectedNodeKey2: node,
            searchFlowchart: true,

        })


        this.getFCParentActivitties();



    }

    openActivityFlowchartDialog = async () => {
        var node = this.state.selectedNodeKey;
        var arr = this.state.selectedNodeKey.split('.');
        this.setState({
            displayFlowchartDialog: true,
            activityFCParentId: arr[1],
            locationId: 0,
            activityName: "",
            activityCode: "",
            activityParentId: 0,
            flowChartType: "activity",
            selectedNodeKey2: node,
            flowChartBelongId: arr[1]

        })


        this.getFCParentActivitties();



    }

    openActivityEditDialog = async () => {

        var arr = this.state.selectedNodeKey.split('.');
        var node = this.state.selectedNodeKey;

        // this.fillActivityList();

        var response = await await handleRequest("GET", "/WFActivity/" + arr[1]);
        var item;
        if (response !== undefined && response !== null)
            item = response.data;

        if (item.flowChartItem) {

            this.setState({
                FCActivityId: item.id,
                displayFlowchartDialog: true,
                activityFCParentId: item.parentId,
                locationId: item.locationId,
                activityName: item.activityName,
                activityCode: item.code,
                flowChartType: item.flowChartType,
                flowChartBelongId: item.flowChartBelongId,
                selectedNodeKey2: node
            })

            this.getFCParentActivitties();

        } else {
            var parent = "Select Parent Activity";
            if (item.parentId > 0) {
                var _selectedNod = this.state.parentActivities.find(p => p.id == item.parentId);
                parent = _selectedNod.name;
            }


            this.setState({
                displayActivityDialog: true,
                locationId: item.locationId,
                activityParent: parent,
                activityParentId: item.parentId,
                activityId: arr[1],
                activityName: item.activityName,
                activityCode: item.code
            })

        }


    }

    getFCParentActivitties = async () => {

        var item = {
            flowChartType: this.state.flowChartType,
            flowChartBelongId: this.state.flowChartBelongId
        };

        const response = await handleRequest("POST", "/WFActivity/getFlowChart", item);
        if (response.data.length !== 0) {
            this.setState({
                flowChartData: response.data

            });
        }


        const response2 = await handleRequest("POST", "/WFActivity/getResults", item);

        if (response2.data.length === 0) {
            this.setState({
                parentFCActivities: [],
                hideActivityFCParent: true


            });
        }

        if (response2.data.length !== 0) {

            const options = response2.data.map(function (row) {
                return { name: row.activityName.toString(), id: row.id.toString() }
            })

            this.setState({
                parentFCActivities: options,
                hideActivityFCParent: false
            });


        }

        if (this.state.activityFCParentId > 0 && this.state.parentFCActivities.length > 0) {
            var _selectedVal = this.state.parentFCActivities.find(p => p.id == this.state.activityFCParentId);

            if (_selectedVal !== undefined)
                this.setState({
                    activityFCParent: _selectedVal.name

                });
        }




    }

    edit = (row) => {

        this.setState({
            hideLocationInputs: false,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info"


        });
    }

    deleteModal = (row) => {


        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            modalDeleteShow: true,
            locationId: arr[1]
        });
    }

    deleteActivityModal = (row) => {

        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            modalActivityDeleteShow: true,
            activityId: arr[1]
        });
    }

    deleteActivity = async () => {


        const deletedItem = {
            id: this.state.activityId

        }
        console.log(deletedItem);
        await handleRequest("POST", "/WFLocationActivity/delete", deletedItem);



        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }

    deleteLocation = async () => {


        const deletedItem = {
            id: this.state.locationId

        }

        await handleRequest("POST", "/WFLocation/delete", deletedItem);



        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }

    delete = async () => {
        console.log(this.state.selectedNodeKey);
        var arr = this.state.selectedNodeKey.split('.');
        const deletedItem = {
            id: arr[1]

        }
        if (arr[0] === "L") {
            await handleRequest("POST", "/WFLocation/delete", deletedItem);
        }

        if (arr[0] === "A") {
            await handleRequest("POST", "/WFActivity/delete", deletedItem);
        }



        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }

    showOrHideModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });
    }



    handleLocationChange = e => {
        e.preventDefault();

        var _selectedVal;

        _selectedVal = this.state.parentLocations.find(p => p.name == e.target.value);

        this.setState({
            [e.target.name]: e.target.value

        });
        if (_selectedVal !== undefined) {
            this.setState({
                locationParentId: _selectedVal.id

            });
        }

    };

    handleActivityChange = e => {
        e.preventDefault();

        var _selectedVal;

        _selectedVal = this.state.parentActivities.find(p => p.name == e.target.value);

        this.setState({
            [e.target.name]: e.target.value

        });

        if (_selectedVal.id !== undefined) {
            this.setState({
                activityParentId: _selectedVal.id

            });
        }

    };

    handleFlowchartChange = e => {
        e.preventDefault();

        var _selectedVal = null;

        _selectedVal = this.state.parentFCActivities.find(p => p.name == e.target.value);

        this.setState({
            [e.target.name]: e.target.value

        });

        if (_selectedVal != null && _selectedVal.id !== undefined) {
            this.setState({
                activityFCParentId: _selectedVal.id,
                FCActivityId: 0

            });
        } else {
            this.setState({
                activityFCParentId: 0


            });
        }

    };

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    handleChange = e => {
       
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleFlowchartHide = async () => {
        let node = this.state.selectedNodeKey2;
        this.setState({
            displayFlowchartDialog: false,
            flowChartBelongId: 0,
            flowChartType: "",
            flowChartData: this.state.data,
            selectedNodeKey2: node

        });
        this.restartTable();
        this.expandSelectedNode();
    }

    codeNameTemplate = (rowData) => {

        console.log(rowData);
        rowData = rowData.data;
        return <> {(rowData.type === "location") ? (this.state.showCode ? <span className="customerBadgeNew" >{rowData.code} . {rowData.name}</span> : <span className={classNames(classes.customerBadgeNew)}>{rowData.name}</span>) :
            ((rowData.type === "flowchart") ? (this.state.showCode ? <span className={classNames(classes.customerBadgeQualified)}>{rowData.code} . {rowData.name}</span> : <span className={classNames(classes.customerBadgeQualified)}>{rowData.name}</span>)
                : (this.state.showCode ? <span className={classNames(classes.customerBadgeUnqualified)}>{rowData.code} . {rowData.name}</span> : <span className={classNames(classes.customerBadgeUnqualified)}>{rowData.name}</span>))}</>;

    }

    actionBodyTemplate(rowData) {

        return (
            <SplitButton type="button" icon="pi pi-cog" className="p-button-secondary" model={(rowData.key !== null && rowData.key.split(".")[0] === "L") ? this.state.menu : this.state.menu2} ></SplitButton>
        );
    }

    export = async () => {

        setTimeout(() => {
            const response = {
                file: `${API_BASE}/WFLocation/export/`+this.props.auth.user.id
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
        // window.location.href = `${API_BASE}/WFLocation/export`;  
        //await axios.get(`${API_BASE}/LanguageType/exportLanguage`); 

    }

    import = async () => {



    }

    _handleKeyDown = (e) => {
       
       
        if (e.key === 'Enter') {

            this.saveLocation();

        }
    }

    _handleKeyDown2 = (e) => {
        if (e.key === 'Enter') {

            this.saveActivity();

        }
    }

    _handleKeyDown3 = (e) => {
        if (e.key === 'Enter') {

            this.addFlowchart();

        }
    }

    _handleKeyDown4 = (e) => {
        if (e.key === 'Enter') {

            this.search();

        }
    }

    handleShowAllLocations = (e) => {
        this.setState({ showLocations: e.value })
        this.restartTable();
    }

    handleShowAllActivity = (e) => {
        this.setState({ showActivities: e.value })
        this.restartTable();
    }


    onExpand = expandedKeys => {
        console.log("onexpand data", expandedKeys);
        this.setState({
            expandedKeysAnt: expandedKeys,
            autoExpandParent: false,
        });
    };

    onLoadData = async (loadedKeys) => {
        console.log("onLoadData data", loadedKeys);
        console.log(loadedKeys);

        this.setState({

            loadedKeys: loadedKeys

        });
    }


    loadData = async (treeNode) => {

        console.log("load data");

        var nodes = this.state.nodes;
        console.log(treeNode);
        let obj = { id: treeNode.key.split(".")[1] };


        if (treeNode.key.split(".")[0] === "L")
            var response = await handleRequest("POST", "/WFLocation/getSubLocations", obj);

        if (treeNode.key.split(".")[0] === "A")
            response = await handleRequest("POST", "/WFLocation/getSubActivities", obj);


        if (response.data != null) {
            const data = response.data;
            data.sort(function (a, b) {
                if (a.orderNo < b.orderNo) {
                    return -1;
                }
                if (a.orderNo > b.orderNo) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
            treeNode.children = data;
            this.findAndSetNode(nodes, treeNode);

            this.setState({
                nodes: nodes
            });
        }



    };


    findAndSetNode = (nodes, treeNode) => {

        if (Boolean(nodes) && nodes.length > 0) {
            let node = nodes.find(p => p.key === treeNode.key);
            if (Boolean(node)) {
                node.children = treeNode.children;
                console.log(nodes);
                return true;
            }
            else {
                nodes.forEach(element => {
                    if (this.findAndSetNode(element.children, treeNode))
                        return;
                });
            }
        }

        return false;
    }


    search = async () => {

        var response = [];
        const value = this.state.searchValue;

        var obj = {
            locationName: value
        }

        this.setState({
            expandedKeysAnt: [],
            nodes: [],
            loadedKeys: []
        });

        if (value.length > 0)
            response = await handleRequest("POST", "/WFLocation/getSearchNodes", obj);
        else
            response = await handleRequest("GET", "/WFLocation/getRootLocations");

        if (response.data !== undefined && response.data !== null && response.data.length !== 0) {
            dataList = [];
            generateList(response.data);
            this.setState({
                nodes: response.data
            });

        }
        const expandedKeys = [];

        if (value.length > 0) {
            dataList.forEach(element => {
                expandedKeys.push(element.key);
            });

            this.setState({
                expandedKeysAnt: expandedKeys,

            });
        }

        /*  const expandedKeys = dataList
              .map(item => {
                  if (item.title.indexOf(value) > -1) {
                      return this.getParentKey(item.key, this.state.nodes);
                  }
                  return null;
              })
              .filter((item, i, self) => item && self.indexOf(item) === i);*/


    };


    onChangeSearch = async (e) => {

        const { value } = e.target;
        this.setState({
            searchValue: value
        });
    };

    clear = async () => {

        this.setState({
            searchValue: "",
            expandedKeysAnt: [],
            nodes: []
        });

        var response = [];

        response = await handleRequest("GET", "/WFLocation/getRootLocations");

        if (response.data !== undefined && response.data !== null && response.data.length !== 0) {
            dataList = [];
            generateList(response.data);
            this.setState({
                nodes: response.data
            });

        }
    };


    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    onRightClick = (event, node) => {

        console.log(node);
        this.setState(
            {
                selectedNodeKey: node.key,
                selectedNode: node
            }
        );

        console.log(this.state.activityList); 

        this.cm.show(event);

    }
    onDrop = async (info) => {

        console.log(info);

        if (info.dragNode.key.split(".")[0] === 'L' && info.node.key.split(".")[0] === "A") {
            this.showError();
        }
        else {
            if (info.dragNode.key.split(".")[0] === 'L' && info.node.key.split(".")[0] === "L") {

                var res = await handleRequest("GET", "/WFLocation/" + info.dragNode.key.split(".")[1]);

                let newItem = res.data;
                newItem.parentId = info.node.key.split(".")[1];

                await handleRequest("POST", "/WFLocation/update", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();
            }

            if (info.dragNode.key.split(".")[0] === 'A' && info.node.key.split(".")[0] === "A") {

                res = await handleRequest("GET", "/WFActivity/" + info.dragNode.key.split(".")[1]);
                if (res.data.flowChartItem) {
                    var obj = { flowChartBelongId: info.node.key.split(".")[1], flowChartItem: true, flowChartType: "activity" }
                } else obj = { flowChartBelongId: 0, flowChartItem: false, flowChartType: null }

                let newItem = res.data;
                newItem.parentId = info.node.key.split(".")[1];
                newItem.flowChartBelongId = obj.flowChartBelongId;
                newItem.flowChartType = obj.flowChartType;
                newItem.flowChartItem = obj.flowChartItem;


                await handleRequest("POST", "/WFActivity/update", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();

            }

            if (info.dragNode.key.split(".")[0] === 'A' && info.node.key.split(".")[0] === "L") {

                res = await handleRequest("GET", "/WFActivity/" + info.dragNode.key.split(".")[1]);
                if (res.data.flowChartItem) {
                    obj = { flowChartBelongId: info.node.key.split(".")[1], flowChartItem: true, flowChartType: "location" }
                } else obj = { flowChartBelongId: 0, flowChartItem: false, flowChartType: null }

                let newItem = res.data;
                newItem.parentId = 0;
                newItem.locationId = info.node.key.split(".")[1];
                newItem.flowChartBelongId = obj.flowChartBelongId;
                newItem.flowChartType = obj.flowChartType;
                newItem.flowChartItem = obj.flowChartItem;

                await handleRequest("POST", "/WFActivity/update", newItem);

                this.resetInputs();
                this.restartTable();
                this.expandSelectedNode();
                this.showSuccess();

            }

        }



    }

    onChangeActivityType = (e) => {


        const _activityType = e.value;

        if (Boolean(this.state.discipline) && this.state.discipline > 0) 
            var _activityList = this.state.activityList.filter(p => p.activityTypeId === _activityType && p.disciplineId === this.state.discipline);
        else
            _activityList = this.state.activityList.filter(p => p.activityTypeId === _activityType);

        if (this.state.searchFlowchart)
            _activityList = _activityList.filter(p => p.flowChartItem === true)
        else _activityList = _activityList.filter(p => p.flowChartItem === false)

        console.log(_activityList);  
        if (_activityList !== null && _activityList !== undefined && _activityList.length > 0) {

            var options = _activityList.map(function (row) {
                return { label: row.activityName.toString(), value: row.id }
            })


        }

        this.setState({
            activityType: _activityType,
            activityOptions: options,
            activityNodes: []
        });




    }

    onChangeActivity = (e) => {

        this.setState({ activity: e.value });
        this.setActivityNodes(e.value);

    }

    setActivityNodes = async (pActivityId) => {

        var response = [];
        response = await handleRequest("GET", "/WFActivity/getSingleActivityNodes/" + pActivityId);

        if (response.data !== undefined && response.data !== null && response.data.length !== 0) {


            this.setState({
                activityNodes: response.data
            });


        }

    }

    onChangeDiscipline = (e) => {
        const _discipline = e.value;

        if (Boolean(this.state.activityType ) && this.state.activityType > 0) 
            var _activityList = this.state.activityList.filter(p => p.activityTypeId === this.state.activityType && p.disciplineId === _discipline);
        else
            _activityList = this.state.activityList.filter(p => p.disciplineId === _discipline);

        if (this.state.searchFlowchart)
            _activityList = _activityList.filter(p => p.flowChartItem === true)
        else _activityList = _activityList.filter(p => p.flowChartItem === false)

        if (_activityList !== null && _activityList !== undefined && _activityList.length > 0) {

            var options = _activityList.map(function (row) {
                return { label: row.activityName.toString(), value: row.id }
            })


        }

        this.setState({
            discipline: _discipline, activityOptions: options,
            activityNodes: []
        });

    }


    onChangeUpload = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    fileName: info.file.name,
                    filePath: info.file.response[0]
                })
            }

        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    downloadFile = async (rowData) => {


        if (this.state.processType !== null && this.state.processType === 'location')
            window.location.href = `${API_BASE}/WFLocationFile/download/` + rowData.id;

        if (this.state.processType !== null && this.state.processType === 'action')
            window.location.href = `${API_BASE}/WFActivityFile/download/` + rowData.id;

    }

    downloadNoteFile = async () => {

        if (this.state.processType !== null && this.state.processType === 'location')
            window.location.href = `${API_BASE}/WFLocation/download/` + this.state.locationId;

        if (this.state.processType !== null && this.state.processType === 'action')
            window.location.href = `${API_BASE}/WFActivity/download/` + this.state.activityId;
    }

    deleteFile = async (rowData) => {

        if (rowData !== null && rowData !== undefined && rowData.id > 0) {

            console.log(rowData);

            const newItem = {
                id: rowData.id
            }

            if (this.state.processType === 'location') {
                await handleRequest("POST", "/WFLocationFile/delete", newItem);
                this.restartFileTable(rowData.locationId);
                this.showSuccess();
                this.restartTable();
            }

            if (this.state.processType === 'activity') {
                await handleRequest("POST", "/WFActivityFile/delete", newItem);
                this.restartFileTable(rowData.activityId);
                this.showSuccess();
                this.restartTable();
            }





        }

    }

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <AntButton style={{ marginLeft: '2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData)} type="primary" shape="round" icon={<DownloadOutlined />} >
                Download
          </AntButton>

        </React.Fragment>
    };

    deleteTemplate(rowData, column) {
        return <React.Fragment>

            <AntButton style={{ marginLeft: '2px', alignContent: 'center' }} danger onClick={() => this.deleteFile(rowData)} >Delete</AntButton>
        </React.Fragment>
    };

    renderMenu = () => {

        console.log(dataList);
        const { menu, menu2 } = this.state;
        if (Boolean(this.state.selectedNodeKey)) {
            return (this.state.selectedNodeKey !== null && this.state.selectedNodeKey.split(".")[0] === "L") ? menu : menu2;
        }
        else return menu2;

    }

    handleSelectionChange = (checkedKeys, e) => {


        console.log('onCheck', checkedKeys);
        console.log('halfCheckedKeys', e.halfCheckedKeys);
        console.log(e);
        this.setState({
            selectedActivityNodeKeys: checkedKeys,
            halfCheckedActivityKeys: e.halfCheckedKeys
        });
    }

    orderChildren = async () => {


        const list = this.state.orderChildLocationList;
        const locationId = this.state.locationId;

        list.forEach((element, i) => {

            element.orderNo = i;
            element.parentId = locationId;

        });

        await handleRequest("POST", "/WFLocation/updateOrder", list);

        this.resetInputs();
        this.restartTable();
        this.expandSelectedNode();
        this.showSuccess();

    };

    template(item) {

        return (

            <div className="p-clearfix">
                <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}> {item.title} </div>
            </div>
        );
    }

    render() {
        const { searchValue } = this.state;
        const loop = (data) =>
            data.length > 0 ?
                data.map(item => {
                    let index = -1;
                    if (searchValue.length > 0)
                        index = item.title.indexOf(searchValue);
                    const beforeStr = item.title.substr(0, index);
                    const afterStr = item.title.substr(index + searchValue.length);
                    const title =
                        index > -1 ? (
                            <span>
                                {beforeStr}
                                <span className="site-tree-search-value">{searchValue}</span>
                                {afterStr}
                            </span>
                            // <> {(item.data.type === "location") ? (this.state.showCode ? <span className={classNames(classes.customerBadgeNew)}>{item.data.code} . {item.data.name}</span> : <span className={classNames(classes.customerBadgeNew)}>{item.data.name}</span>) :
                            // ((item.data.type === "flowchart") ? (this.state.showCode ? <span className={classNames(classes.customerBadgeQualified)}>{item.data.code} . {item.data.name}</span> : <span className={classNames(classes.customerBadgeQualified)}>{item.data.name}</span>)
                            //     : (this.state.showCode ? <span className={classNames(classes.customerBadgeUnqualified)}>{item.data.code} . {item.data.name}</span> : <span className={classNames(classes.customerBadgeUnqualified)}>{item.data.name}</span>))}</>

                        ) : (
                            <> {(item.data.type === "location") ? (this.state.showCode ? <span className={classNames(classes.customerBadgeNew)}>{item.data.code} . {item.data.name} {item.data.haveNote && <i className="pi pi-fw pi-id-card"></i>} {item.data.haveFile && <i className="pi pi-fw pi-paperclip"></i>}</span> : <span className={classNames(classes.customerBadgeNew)}>{item.data.name} {item.data.haveNote && <i className="pi pi-fw pi-id-card"></i>} {item.data.haveFile && <i className="pi pi-fw pi-paperclip"></i>}</span>) :
                                ((item.data.type === "flowchart") ? (this.state.showCode ? <span className={classNames(classes.customerBadgeQualified)}>{item.data.code} . {item.data.name} {item.data.haveNote && <i className="pi pi-fw pi-id-card"></i>} {item.data.haveFile && <i className="pi pi-fw pi-paperclip"></i>}</span> : <span className={classNames(classes.customerBadgeQualified)}>{item.data.name}{item.data.haveNote && <i className="pi pi-fw pi-id-card"></i>} {item.data.haveFile && <i className="pi pi-fw pi-paperclip"></i>}</span>)
                                    : (this.state.showCode ? <span className={classNames(classes.customerBadgeUnqualified)}>{item.data.code} . {item.data.name} {item.data.haveNote && <i className="pi pi-fw pi-id-card"></i>} {item.data.haveFile && <i className="pi pi-fw pi-paperclip"></i>}</span> : <span className={classNames(classes.customerBadgeUnqualified)}>{item.data.name} {item.data.haveNote && <i className="pi pi-fw pi-id-card"></i>} {item.data.haveFile && <i className="pi pi-fw pi-paperclip"></i>}</span>))}</>

                        );
                    if (item.children) {
                        return { title, key: item.key, children: loop(item.children) };
                    }

                    return {
                        title,
                        key: item.key,
                        isLeaf: false
                    };
                }) : [];

        let header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
        </div>;

        let optionsParentLocations = this.state.parentLocations.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );
        let optionsParentActivities = this.state.parentActivities.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsFCParentActivities = this.state.parentFCActivities.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let dialogFlowchartFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.addFlowchart}>Save</Button>
        </div>;
        let dialogLocationOrderFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.orderChildren}>Save</Button>
        </div>;
        let dialogLocationFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.saveLocation}>Save</Button>
        </div>;

        let dialogLocationCopyFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.copyLocation}>Copy</Button>
        </div>;

        let dialogActivityFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.saveActivity}>Save</Button>
        </div>;
        let dialogTimeFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.saveStartingTime}>Save</Button>
        </div>;
        let dialogNoteFooter = <div className="ui-dialog-buttonpane p-clearfix">

            <Button variant="danger" onClick={this.deleteNoteModal}>Delete Note</Button>
            <Button style={{ marginLeft: '5px' }} onClick={this.saveNote}>Save</Button>
        </div>;
        let dialogFileFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button onClick={this.SaveFile}>Save</Button>
        </div>;

        return (
            <div>

                <div style={{ padding: "4px 16px 4px 24px" }}>

                    <Row>
                        <Toast ref={(el) => this.toast = el} />
                        <Col xs={6}>

                        </Col>

                        <Col xs={2}>

                        </Col>
                    </Row>
                </div>
                <div style={{ padding: "4px 16px 4px 24px" }}>

                    <Row>
                        <Col xs={9}>

                        </Col>
                        <Col xs={1}>
                            <ExcelReader onCreateJson={this.handleExcelData.bind(this)}></ExcelReader>
                        </Col>
                        <Col xs={1}>
                            <AntButton style={{ border: "0px" }} onClick={this.export} icon={<ExportIcon color="action" />}></AntButton>

                        </Col>


                        <Col xs={1}>
                            <AntButton style={{ border: "0px" }} hidden={!this.state.hideLocationInputs} onClick={this.createNew} icon={<CreateIcon color="primary" />}></AntButton>
                            <AntButton style={{ border: "0px" }} hidden={this.state.hideLocationInputs} onClick={this.createNew} icon={<RemoveCircle color="secondary" />}></AntButton>
                        </Col>
                    </Row>
                </div>
                <div hidden={this.state.hideLocationInputs} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Parent Location :
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="locationParent" value={this.state.locationParent}
                                                onChange={this.handleLocationChange} as="select">
                                                <option value="">
                                                    Select Parent Location
                                                 </option>
                                                {optionsParentLocations}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="locationName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Location :
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="locationName" value={this.state.locationName}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>



                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">

                                        <Col sm="3">


                                        </Col>
                                        <Col sm="4">
                                            <Button type="button" style={{ width: '100%' }} onClick={this.saveLocation2} variant={this.state.lastButtonVariant} >{this.state.lastButton}</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>

                            </Container></Card.Body>
                    </Card>
                </div>
                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row> <Col xs={2}>
                        <Checkbox checked={this.state.showCode} onChange={(e) => this.setState({ showCode: e.target.checked })}>Show Codes</Checkbox>
                    </Col>

                        <Col xs={2}>
                            <Checkbox checked={this.state.showLocations} onChange={(e) => this.setState({ showLocations: e.target.checked }, this.restartTable)}>Show Locations</Checkbox>
                        </Col>
                        <Col xs={3}>
                            <Checkbox checked={this.state.showActivities} onChange={(e) => this.setState({ showActivities: e.target.checked }, this.restartTable)}>Show Activities</Checkbox>


                        </Col>
                    </Row>
                </div>
                {/* <div style={{ left: `${this.state.leftX}`, top: `${this.state.topY}`, marginTop: '3rem' }} className="content-section implementation">
                    <Toast ref={(el) => this.toast = el} />

                    <ContextMenu model={(this.state.selectedNodeKey !== null && this.state.selectedNodeKey.split(".")[0] === "L") ? this.state.menu : this.state.menu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />
                  
                    <TreeTable globalFilter={this.state.globalFilter} header={header} value={this.state.nodes} expandedKeys={this.state.expandedKeys} onToggle={e => this.setState({ expandedKeys: e.value })}
                        contextMenuSelectionKey={this.state.selectedNodeKey} onContextMenuSelectionChange={event => this.setState({ selectedNodeKey: event.value })}
                        onContextMenu={event => this.cm.show(event.originalEvent)} >                      
                        <Column body={this.codeNameTemplate}  header="Location-Activity Name" expander ></Column> 
                        <Column  field="code" style={{ textAlign: 'center', display: 'none' }}   header="kod"   filter={true}></Column>
                        <Column  field="name" style={{ textAlign: 'center', display: 'none' }}  header="name" filter={true}></Column> 

                    </TreeTable>
                </div> */}

                <div>
                    <ContextMenu model={(this.state.selectedNodeKey !== null && this.state.selectedNodeKey.split(".")[0] === "L") ? this.state.menu : this.state.menu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />

                    <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={this.onChangeSearch} onKeyDown={this._handleKeyDown4} />
                    {/* <Button variant="primary" onClick={this.clear}>Clear</Button>    */}
                    <Tree
                        className="draggable-tree"
                        onDrop={this.onDrop}
                        draggable
                        showLine
                        loadedKeys={this.state.loadedKeys}
                        onLoad={this.onLoadData}
                        loadData={this.loadData}
                        onRightClick={({ event, node }) => this.onRightClick(event, node)}
                        onExpand={this.onExpand}
                        expandedKeys={this.state.expandedKeysAnt}
                        autoExpandParent={false}
                        treeData={loop(this.state.nodes)}
                    />
                </div>

                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}>
                            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={() => this.setState({ modalDeleteShow: false, locationId: 0 })}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Location ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>All informations about location will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.setState({ modalDeleteShow: false, locationId: 0 })}>Close</Button>
                                    <Button variant="danger" onClick={this.deleteLocation} >Delete</Button>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>

                    </Row>
                </Container>
                <Modal
                    show={this.state.modalDeleteNoteShow}
                    onHide={() => this.setState({ modalDeleteNoteShow: false })}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Note ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Note content will be deleted. Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ modalDeleteNoteShow: false })}>Close</Button>
                        <Button variant="danger" onClick={this.deleteNote} >Delete</Button>
                    </Modal.Footer>
                </Modal>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}>
                            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.modalActivityDeleteShow}
                                onHide={() => this.setState({ modalActivityDeleteShow: false, activityId: 0 })}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Activity ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>All informations about activity will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.setState({ modalActivityDeleteShow: false, activityId: 0 })}>Close</Button>
                                    <Button variant="danger" onClick={this.deleteActivity} >Delete</Button>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>

                    </Row>
                </Container>
                <Modal show={this.state.displayDialog} centered onHide={() => this.setState({ displayDialog: false, locationId: 0 })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Location Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Body>
                                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                    <Form >
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                Parent Location :
                                                    </Form.Label>
                                            <Col sm="8">
                                                <Form.Control name="locationParent" value={this.state.locationParent} disabled={this.state.disableParentLocation}
                                                    onChange={this.handleLocationChange} as="select">
                                                    <option value="">
                                                        Select Parent Location
                                                     </option>
                                                    {optionsParentLocations}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                Location :
                                                    </Form.Label>
                                            <Col sm="8">
                                                <Form.Control type="text" name="locationName" id="ModalLocationName" value={this.state.locationName} 
                                                    onChange={this.handleChange} /> 
                                            
                                            </Col>
                                        </Form.Group>



                                    </Form>

                                </Container></Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        {dialogLocationFooter}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.displayOrderDialog} centered onHide={() => this.setState({ displayOrderDialog: false, locationId: 0, orderChildLocationList: [] })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Order Children</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Body>
                                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">

                                            <Col sm="8">

                                                {this.state.orderChildLocationList.length > 0 && <OrderList value={this.state.orderChildLocationList} responsive={true} itemTemplate={this.template}
                                                    onChange={(e) => this.setState({ orderChildLocationList: e.value })}></OrderList>}

                                            </Col>
                                        </Form.Group>




                                    </Form>

                                </Container></Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        {dialogLocationOrderFooter}
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.displayCopyDialog} centered onHide={() => this.setState({ displayCopyDialog: false, locationId: 0 })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Copy Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Body>
                                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                Number of copies :
                                         </Form.Label>
                                            <Col sm="8">
                                                <InputNumber min={1} onChange={(value) => this.setState({ copyNumber: value })} value={this.state.copyNumber} />

                                            </Col>
                                        </Form.Group>




                                    </Form>

                                </Container></Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        {dialogLocationCopyFooter}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.displayTimeDialog} centered onHide={() => this.setState({ displayTimeDialog: false, activityId: 0 })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Select Date and Time</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Body>
                                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                            <Form.Label style={{ color: 'black' }} column sm="4">
                                                Starting Time :
                     </Form.Label>
                                            <Col sm="8">
                                                <DatePicker showTime onOk={(value) => {
                                                    this.setState({
                                                        startingTime: value
                                                    })
                                                }} value={this.state.startingTime}

                                                    format="DD-MM-YYYY HH:mm"
                                                />
                                            </Col>
                                        </Form.Group>




                                    </Form>

                                </Container></Card.Body>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        {dialogTimeFooter}
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.displayActivityDialog} centered onHide={() => this.setState({ displayActivityDialog: false, activityId: 0, searchFlowchart: false, discipline: 0, activityType: 0, activity: 0, locationActivityCode: '', selectedActivityNodeKeys: [] })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Activity Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>


                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                <Form.Label style={{ color: 'black' }} column sm="4">
                                    Discipline
                                        </Form.Label>
                                <Col sm="8">
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.discipline} options={this.state.disciplineOptions} onChange={this.onChangeDiscipline}
                                        placeholder="Select ..." />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                <Form.Label style={{ color: 'black' }} column sm="4">
                                    Activity Type
                                        </Form.Label>
                                <Col sm="8">
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.activityType} options={this.state.activityTypeOptions} onChange={this.onChangeActivityType}
                                        placeholder="Select ..." />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                <Form.Label style={{ color: 'black' }} column sm="4">
                                    Activity
                                         </Form.Label>
                                <Col sm="8">
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.activity} options={this.state.activityOptions} onChange={this.onChangeActivity}
                                        placeholder="Select ..." />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                <Form.Label style={{ color: 'black' }} column sm="4">
                                    Code
                               </Form.Label>
                                <Col sm="8">
                                    <Input style={{ width: '100%' }} placeholder="Code" value={this.state.locationActivityCode} onChange={(e) => this.setState({ locationActivityCode: e.target.value })} />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                <Form.Label style={{ color: 'black' }} column sm="4">

                                </Form.Label>
                                <Col sm="8">

                                    <Tree style={{ width: '100%' }}
                                        checkable
                                        showLine={true}
                                        onCheck={this.handleSelectionChange}
                                        checkedKeys={this.state.selectedActivityNodeKeys}
                                        treeData={this.state.activityNodes}
                                    />
                                </Col>
                            </Form.Group>



                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {dialogActivityFooter}
                    </Modal.Footer>


                </Modal>

                <Modal show={this.state.displayFlowchartDialog} centered size="lg" onHide={this.handleFlowchartHide}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Flowchart Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Toast ref={(el) => this.toast = el} />
                            <Card.Body>
                                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                    <Form>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                        </Form.Group>

                                    </Form>

                                </Container>
                                <Container>

                                    <OrganizationChart value={this.state.flowChartData} selectionMode="single" selection={this.state.selectedNode} onSelectionChange={event => this.setState({ selectedNode: event.data })}></OrganizationChart>

                                </Container>
                            </Card.Body>
                        </Card>

                    </Modal.Body>
                    <Modal.Footer>
                        {dialogFlowchartFooter}
                    </Modal.Footer>

                </Modal>
                <Modal show={this.state.displayNoteDialog} centered size="lg" onHide={() => this.setState({ displayNoteDialog: false, activityId: 0, locationId: 0, processType: null })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Note
                                        </Form.Label>
                                <Col sm="10">
                                    <Form.Control as="textarea" rows="5" value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                <Form.Label style={{ color: 'black' }} column sm="3">
                                    Last Updated By
                                        </Form.Label>

                                <Form.Label style={{ color: 'black' }} column sm="3">
                                    {this.state.lastUpdatedBy}
                                </Form.Label>


                                <Form.Label style={{ color: 'black' }} column sm="3">
                                    Last Updated Time
                                        </Form.Label>

                                <Form.Label style={{ color: 'black' }} column sm="3">
                                    {this.state.lastUpdatedTime}
                                </Form.Label>


                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                <Col sm="2">

                                </Col>
                                <Col sm="3">
                                    <Upload
                                        name="file"
                                        showUploadList={true}
                                        action={`${API_BASE}/File/uploadSingle`}
                                        onChange={this.onChangeUpload}
                                    >
                                        <AntButton>
                                            <UploadOutlined />Upload File
                                    </AntButton>
                                    </Upload>
                                </Col>
                                <Col sm="2">

                                    {Boolean(this.state.noteFilePath) && <AntButton style={{ marginLeft: '2px', alignContent: 'center' }} onClick={this.downloadNoteFile} type="primary" shape="round" icon={<DownloadOutlined />} >
                                        Download
                                     </AntButton>}
                                </Col>

                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        {dialogNoteFooter}
                    </Modal.Footer>


                </Modal>
                <Modal show={this.state.displayFileDialog} centered size='lg' onHide={() => this.setState({ displayFileDialog: false, activityId: 0, locationId: 0, processType: null })}>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Files </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    File
                            </Form.Label>
                                <Col sm="4">
                                    <Upload
                                        name="file"
                                        showUploadList={true}
                                        action={`${API_BASE}/WFLocation/upload`}
                                        onChange={this.onChangeUpload}
                                    >
                                        <AntButton>
                                            <UploadOutlined />Upload File
                                    </AntButton>
                                    </Upload>
                                </Col>
                                <Col sm="4">
                                    {dialogFileFooter}
                                </Col>
                            </Form.Group>


                        </Form>

                        <DataTable value={this.state.fileList}>

                            <Column field="fileName" header="File Name" style={{ width: '50%' }} />
                            <Column header="Download" body={this.actionTemplate} style={{ width: '25%' }} />
                            <Column header="Delete" body={this.deleteTemplate} style={{ width: '25%' }} />

                        </DataTable>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>


                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}


const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(LocationActivity2)
