/* eslint-disable */

import DataTable from 'react-data-table-component';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { IconButton  } from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import { Button as ReactButton, Form, Col, Row, Modal } from 'react-bootstrap';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu2';
import { handleRequest } from '../../redux/config/env';
import memoize from 'memoize-one';
import PersonelSubLog from './PersonelSubLog';
import StaffRevision from './StaffRevision';
import "./css/DataTable.scss";
import { ExcelReader } from '../excel/ExcelReader';
import { API_BASE } from '../../redux/config/env';
import { Button as PrimeButton } from "primereact/button";
import { Toast } from 'primereact/toast';
import { Avatar, Switch, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { NATIONALITIES, COUNTRIES } from './JsonDatas/Datas';
import { ToggleButton } from 'primereact/togglebutton';
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";

const sortIcon = <ArrowDownward />;

const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton>
);

function test(row) {
    let rowCell;

    if (row.department && row.department.disciplineDepartmentList.length !== 0) {

        var disciplines = row.department.disciplineDepartmentList[0].discipline.disciplineName;

        for (let index = 1; index < row.department.disciplineDepartmentList.length; index++) {
            disciplines += "," + row.department.disciplineDepartmentList[index].discipline.disciplineName;
        }

        rowCell = <div>{disciplines}</div>;

        return rowCell;
    }
}

const columnsPersonel = memoize((editHandler, deleteHandler2, Handler3) => [
    {
        cell: row => <CustomMaterialMenu row={row} onEditRow={editHandler} onDeleteRow2={deleteHandler2} onRevision={Handler3} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "S/N",
        selector: "index",
        sortable: true,
        width: '56px',
    },

    {
        name: "Photo",
        selector: "photoPath",
        cell: row => <> {row.photoPath ? <Avatar size={64} src={`${API_BASE}/Staff/download/photo/` + row.id} /> : ""}</>
        // <img src={`${API_BASE}/Staff/download/photo/`+row.id } alt="avatar" style={{ width: '100%' }} /> : ""} </>

    },
    {
        name: "Name",
        cell: row => <div style={{ fontWeight: 700 }}>{row.name}{" " + row.midName + " "}{row.lastName}</div>,
        selector: "name",
        sortable: true,
        grow: 2,
    },
    {
        name: 'Department',
        selector: 'department.departmentName',
        sortable: true,
    },
    {
        name: 'Discipline',
        cell: row => test(row),
        selector: 'department.disciplines',
        sortable: true,
    },
    {
        name: 'Role Group',
        selector: 'designation.roleGroup.roleName',
        sortable: true
    },
    {
        name: 'Designation',
        selector: 'designation.designationName',
        sortable: true
    },
    {
        name: 'Iqama No/National ID',
        selector: 'nationalId',
        sortable: true
    },
    {
        name: 'Nationality',
        selector: 'countryName',
        sortable: true
    },
    {
        name: 'Phone No',
        selector: 'residencePersonelPhone',
        sortable: true
    },
    {
        name: 'Email',
        selector: 'residenceCompanyEmail',
        sortable: true
    }
]);
var isira = 1;
const columnsRev = [
    {
        name: "Rev No",
        cell: row => <div >{isira++} </div>,
        selector: "name",
        sortable: true,

    },
    {
        name: "Log Date",
        selector: "logdate",
        sortable: true,
    },
    {
        name: "Who ",
        selector: "username",
        sortable: true
    },
];

class PersonelLog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            personelLogId: 0,
            redirect: true,
            hidden: true,
            deleteModal: false,
            datas: [],
            personels: [],
            newPersonalCreate: "/PersonalInfo",
            historyModal: false,
            datatableValue: [],
            selectRowDATA: [],

            departments: [],
            disciplines: [],
            roleGroups: [],
            designations: [],
            codes: [],
            addModal: false,
            promptModal: false,
            companyStaff: true,
            budgeNo: "",
            genderTextValue: "Gender:",
            genderCheck: true,
            loading: false,
            photoPath: "",
            photoImageUrl: "",
            nationalityTextValue: "Nationality:",
            nationality: "Select Nationality",
            nationalityId: null,
            nameTextValue: "Name:",
            midNameTextValue: "Mid Name:",
            lastNameTextValue: "Last Name:",
            name: "",
            midName: "",
            lastName: "",
            department1: "Select Department",
            departmentId1: null,
            departments1: [],
            roleGroup1: "Select Role Group",
            roleGroupId1: null,
            roleGroups1: [],
            designation1: "Select Designation",
            designationId1: null,
            designations1: [],
            discipline1: "Select Discipline",
            disciplineId1: null,
            disciplines1: [],
            genders: [],
            nationalId: "",
            modalShow: false
        };

        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    };

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/Staff/getCompanyItems");

        if (response.data.length === 0) {
            this.setState({
                personels: [],
            });
        }

        if (response.data.length !== 0) {
            console.log(response.data);
            this.setState({
                personels: response.data
            });
        }

        const responseDep = await handleRequest("GET", "/Department/getAll");

        if (responseDep.data.length !== 0) {

            this.setState({
                departments: responseDep.data
            });
        }

        //const response2 = await axios.get(`${API_BASE}/Discipline/getAll`,config);
        const responseDis = await handleRequest("GET", "/Discipline/getAll");


        if (responseDis.data.length !== 0) {
            this.setState({
                disciplines: responseDis.data
            });
        }

        // const responseRG = await axios.get(`${API_BASE}/RoleGroup/getAll`,config);
        const responseRG = await handleRequest("GET", "/RoleGroup/getAll");

        if (responseRG.data.length !== 0) {
            this.setState({
                roleGroups: responseRG.data
            });
        }

        //const responseDes = await axios.get(`${API_BASE}/Designation/getAll`,config);
        const responseDes = await handleRequest("GET", "/Designation/getAll");

        if (responseDes.data.length !== 0) {
            this.setState({
                designations: responseDes.data
            });
        }

        const responseCode = await handleRequest("GET", "/Code/getAll");

        if (responseCode.data.length !== 0) {

            this.setState({
                codes: responseCode.data
            });

            const columns = responseCode.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "personelType") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                personelTypes: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "bloodGroup") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                bloodGroups: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "maritalStatus") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                maritalStatuses: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "militaryObligation") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                militaryObligations: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "gender") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                genders: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "smokeStatus") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                smokeStatuses: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "periodForCalibration") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                contractDurations: options
            });

        }

        const response3 = await handleRequest("GET", "/Department/getallByCompany");

        if (response3.data.length === 0) {
            this.setState({
                departments1: []
            });
        }

        if (response3.data.length !== 0) {
            this.setState({
                departments1: response3.data,
            });

            const columns = response3.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() }
            })

            this.setState({
                departments1: options,
            });
        }

        const response4 = await handleRequest("GET", "/Designation/getallByCompany");

        if (response4.data.length === 0) {
            this.setState({
                designations1: []
            });
        }

        if (response4.data.length !== 0) {

            const columns2 = response4.data;

            const options2 = columns2.map(function (row) {
                return { name: row.designationName.toString(), id: row.id.toString() }
            })

            this.setState({
                designations1: options2,
            });
        }

    }

    restartTable = async () => {

        const response = await handleRequest("GET", "/Staff/getCompanyItems");

        if (response.data.length !== 0) {
            this.setState({
                personels: response.data,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                personels: [],
            });
        }
    }

    showRevision = async (row) => {

        isira = 1;
        this.setState({
            historyModal: true,
            personelLogId: row.id,
            selectRowDATA: row
        });

        var param = { id: row.id }
        const response4 = await handleRequest("POST", "/Staff/getAllRevision", param);

        if (response4.data.length !== 0) {
            console.log(response4.data);
            this.setState({
                datatableValue: response4.data,
            });
        }
        else {
            this.setState({
                datatableValue: [],
            });
        }

    }

    deletePersonelLog = row => {
        this.setState({
            deleteModal: true,
            personelLogId: row.id
        });
    }

    deletePersonelLogOK = async () => {
        const deletedPersonelLog = {
            id: this.state.personelLogId
        }

        await handleRequest("POST", "/Staff/delete", deletedPersonelLog);

        const response0 = await handleRequest("GET", "/Assignment/getAll");
        let assignments = [];
        let flagAssignmentId = null;

        if (response0.data.length === 0) {
            assignments = [];
        }

        if (response0.data.length !== 0) {

            assignments = response0.data;

            for (let index = 0; index < assignments.length; index++) {
                if (assignments[index].staffId === this.state.personelLogId) {
                    flagAssignmentId = assignments[index].id;
                }
            }
        }

        const deletedAssignment = {
            id: flagAssignmentId
        }

        await handleRequest("POST", "/Assignment/delete", deletedAssignment);

        this.setState({
            deleteModal: false
        });
        this.restartTable();
    }

    editPersonelLog = (row) => {
        // return <Redirect to={`/PersonalInfo/${row.id}`} />
        // window.location.href = '/PersonalInfo/' + row.id;
    }

    showOrHideModalDeletePersonelLog = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    rowExpansionTemplate(data) {
        console.log(data);
        return (<div>ags</div>);
    }

    handleExcelData = async (excelJson) => {

        var list = [];
        if (excelJson === null)
            this.showError("Select an excel file");
        else {


            var rowIndexes = [];
            excelJson.forEach(element => {
                var _departmentId = this.findDepartment(element["Department"]);
                var _designationId = this.findDesignation(element["Designation"]);
                var _disciplineId = this.findDiscipline(element["Discipline"]);
                var _roleGroupId = this.findRoleGroup(element["Role Group"]);
                var _staffType = this.findCode(element["Staff Type"]);

                var _maritalStatus = this.findCode(element["Marital Status"]);
                var _gender = this.findCode(element["Gender"]);
                var _bloodGroup = this.findCode(element["Blood Group"]);
                var _militaryObligation = this.findCode(element["Military Obligation"]);
                var _smokeStatus = this.findCode(element["Smoke Status"]);


                if (_departmentId > 0 && _designationId > 0 && _disciplineId > 0 && _roleGroupId > 0 && _staffType > 0 && _maritalStatus > 0 && _gender > 0 && _bloodGroup > 0 && _militaryObligation > 0) {
                    const newPersonel = {
                        name: element["Name"],
                        midName: element["Mid Name"],
                        lastName: element["Last Name"],
                        departmentId: _departmentId,
                        disciplineId: _disciplineId,
                        roleGroupId: _roleGroupId,
                        designationId: _designationId,
                        staffType: _staffType,
                        maritalStatus: _maritalStatus,
                        gender: _gender,
                        bloodGroup: _bloodGroup,
                        militaryObligation: _militaryObligation,
                        nationalId: element["National ID"],
                        countryName: element["Nationality"],
                        residencePersonelPhone: element["Phone No"],
                        residenceCompanyEmail: element["Email"],
                        smokeStatus: _smokeStatus

                    }
                    console.log(newPersonel);
                    list.push(newPersonel);
                } else rowIndexes.push(element.__rowNum__ + 1);

            });

            if (rowIndexes.length === 0) {

                var response = await handleRequest("POST", "/Staff/import", list);
                if (response.data) {

                    if (response.data === "ERROR-PERSONELLOG-001") {
                        this.showError("An error was occured !");
                    }
                    if (response.data === "SUCCESS-PERSONELLOG-001") {
                        this.showSuccess();
                        this.restartTable();
                    }

                }

            } else this.showError("Incorrect inputs detected on " + rowIndexes + " line(s).");

        }


    }

    export = async () => {

        // window.location.href = `${API_BASE}/Staff/export`;

        var id = this.props.auth.user.id;
        console.log(id);
        const response = await handleRequest("GETBLOB", "/Staff/export/" + id, { method: 'GET', responseType: 'blob' });
        try {
            const file = new Blob([response.data], { type: 'application/vnd.ms-excel' });
            // const fileURL = URL.createObjectURL(file);
            console.log(file);

            var url = window.URL.createObjectURL(file);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = "newfile.xls";
            a.click();
            //adding some delay in removing the dynamically created link solved the problem in FireFox
            setTimeout(function () { window.URL.revokeObjectURL(url); }, 0);

            //window.open(fileURL);
        } catch (error) {

        }

    }

    findDepartment = (name) => {

        console.log(this.state.departments);
        console.log(name);

        var index = this.state.departments.findIndex(p => p.departmentName.trim() === name.trim());
        console.log(index);

        if (index >= 0) {
            return this.state.departments[index].id;
        }
        else return 0;
    }

    findDiscipline = (name) => {

        var index = this.state.disciplines.findIndex(p => p.disciplineName === name);
        if (index >= 0) {
            return this.state.disciplines[index].id;
        }
        else return 0;
    }

    findDesignation = (name) => {

        var index = this.state.designations.findIndex(p => p.designationName === name);
        if (index >= 0) {
            return this.state.designations[index].id;
        }
        else return 0;
    }

    findRoleGroup = (name) => {

        var index = this.state.roleGroups.findIndex(p => p.roleName === name);
        if (index >= 0) {
            return this.state.roleGroups[index].id;
        }
        else return 0;
    }

    findCode = (name) => {

        var index = this.state.codes.findIndex(p => p.name === name);
        if (index >= 0) {
            return this.state.codes[index].id;
        }
        else return 0;
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    }

    showOrHideModalPrompt = () => {
        this.setState({
            addModal: !this.state.addModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalPromptRecord = () => {
        this.setState({
            addModal: false,
            promptModal: !this.state.promptModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            if (info.file.response.length > 0) {
                this.setState({
                    photoPath: info.file.response[0]
                })
            }
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    photoImageUrl: imageUrl,
                    loading: false,
                }),
            );
        }
    }

    handleChange2 = e => {
        e.preventDefault();
        var nationalityId;

        for (var z = 0; z < NATIONALITIES.length; z++) {
            if (NATIONALITIES[z].nationality === e.target.value) {
                nationalityId = NATIONALITIES[z].num_code
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            nationalityId: nationalityId
        });
    }

    handleChangeDepartment = async (e) => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.departments1.length; z++) {
            if (this.state.departments1[z].name === e.target.value) {
                idValue = this.state.departments1[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            departmentId1: idValue
        });

        const response = await handleRequest("GET", "/Department/" + idValue);

        const disciplineData = response.data.disciplineDepartmentList;

        if (disciplineData.length === 0) {
            this.setState({
                disciplines1: []
            });
        }

        if (disciplineData.length !== 0) {

            const columns = disciplineData;

            const options = columns.map(function (row) {
                return { name: row.discipline.disciplineName.toString(), id: row.discipline.id.toString() }
            })

            this.setState({
                disciplines1: options,
            });
        }
    }

    handleChangeRoleGroup = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.roleGroups1.length; z++) {
            if (this.state.roleGroups1[z].name === e.target.value) {
                idValue = this.state.roleGroups1[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            roleGroupId1: idValue
        });
    }

    handleChangeDesignation = async (e) => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.designations1.length; z++) {
            if (this.state.designations1[z].name === e.target.value) {
                idValue = this.state.designations1[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            designationId1: idValue
        });

        const response = await handleRequest("GET", "/Designation/" + idValue);

        const roleGroupData = response.data.roleGroup;

        console.log(roleGroupData);

        if (roleGroupData.length === 0) {
            this.setState({
                roleGroups1: []
            });
        }

        if (roleGroupData.length !== 0) {

            const columns = roleGroupData;

            var options = [];
            options.push({ name: columns.roleName.toString(), id: columns.id.toString() })

            this.setState({
                roleGroups1: options,
            });
        }
    }

    handleChangeDiscipline = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.disciplines1.length; z++) {
            if (this.state.disciplines1[z].name === e.target.value) {
                idValue = this.state.disciplines1[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            disciplineId1: idValue
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    refreshValues = () => {
        this.setState({
            companyStaff: true,
            budgeNo: "",
            genderCheck: true,
            loading: false,
            photoPath: "",
            photoImageUrl: "",
            nationality: "Select Nationality",
            nationalityId: null,
            name: "",
            midName: "",
            lastName: "",
            department1: "Select Department",
            departmentId1: null,
            roleGroup1: "Select Role Group",
            roleGroupId1: null,
            designation1: "Select Designation",
            designationId1: null,
            discipline1: "Select Discipline",
            disciplineId1: null,
            nationalId: "",
            residencePersonelPhone: "",
            residenceCompanyEmail: ""
        });
    }

    savePersonnelLogOK = async () => {
        if (this.state.nationality !== "Select Nationality" && this.state.name !== "" && this.state.lastName !== "" && this.state.nationalId !== ""
            && this.state.photoPath !== "" && this.state.residencePersonelPhone !== "" && this.state.residenceCompanyEmail !== ""
            && this.state.departmentId1 !== null && this.state.designationId1 !== null && this.state.disciplineId !== null
            && this.state.roleGroupId1 !== null && this.state.budgeNo !== "") {

            var genderValue = "";

            if (this.state.genderCheck === true)
                genderValue = this.state.genders[0].id
            else
                genderValue = this.state.genders[1].id

            const newPersonel = {
                birthDate: null,
                bloodGroup: 16,
                country: this.state.nationalityId,
                countryName: this.state.nationality,
                drivingExpirityDate: null,
                drivingLicence: "",
                drivingLicencePath: "",
                fatherName: "",
                gender: genderValue,
                lastName: this.state.lastName,
                maritalStatus: 27,
                midName: this.state.midName,
                militaryObligation: 32,
                motherName: "",
                name: this.state.name,
                nationalExpirityDate: null,
                nationalId: this.state.nationalId,
                nationalIdPath: "",
                numberOfChild: 0,
                passportExpirityDate: null,
                passportNo: "",
                passportPath: "",
                photoPath: this.state.photoPath,
                placeOfBirth: "",
                smokeStatus: this.state.smokeStatuses[0].id,
                residencePersonelPhone: this.state.residencePersonelPhone,
                residenceCompanyEmail: this.state.residenceCompanyEmail,
                departmentId: this.state.departmentId1,
                designationId: this.state.designationId1,
                disciplineId: this.state.disciplineId1,
                roleGroupId: this.state.roleGroupId1,
                budgeNo: this.state.budgeNo,
                companyStaff: this.state.companyStaff,
                staffType: "1",
                workingStatus: "Waiting For Start"
            }

            await handleRequest("POST", "/Staff/add", newPersonel);

            this.setState({
                promptModal: false
            });
            this.restartTable();
            this.refreshValues();
        } else {
            this.setState({
                modalShow: true
            });
        }
    }

    render() {
        isira = 1;

        const ExpanableComponent = ({ data }) => <PersonelSubLog row={data} />;

        const ExpanableComponent2 = ({ data }) => <StaffRevision row={data} row2={this.state.selectRowDATA} />;

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Attach Photo</div>
            </div>
        );

        let optionsNationalities = NATIONALITIES.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDepartments = this.state.departments1.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsRoleGroups = this.state.roleGroups1.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDesignations = this.state.designations1.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDisciplines = this.state.disciplines1.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>
                <Toast ref={(el) => this.toast = el} />

                <Modal
                    show={this.state.historyModal} size="lg"
                    onHide={() => this.setState({ historyModal: !this.state.historyModal })}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered  >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Revisions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <DataTable
                            noHeader
                            columns={columnsRev}
                            data={this.state.datatableValue}
                            sortIcon={sortIcon}
                            pagination
                            expandableRows
                            onRowExpandToggled={(e) => this.setState({ selectRevDATA: e.data })}
                            expandableRowsComponent={<ExpanableComponent2 />}
                            persistTableHead
                            fixedHeader
                            striped
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={() => this.setState({ historyModal: !this.state.historyModal })} >Close</ReactButton>

                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeletePersonelLog}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Personal Data?
                            </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related datas.
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeletePersonelLog}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deletePersonelLogOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalPrompt}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">New Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactButton onClick={this.showOrHideModalPromptRecord} style={{ width: '100%', marginBottom: '2rem' }} variant="success">Prompt Record</ReactButton>
                        <Link to={`/PersonalInfo`} >
                            <ReactButton style={{ width: '100%', marginBottom: '1rem' }} variant="warning">Normal Record</ReactButton>
                        </Link>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.promptModal}
                    onHide={this.showOrHideModalPromptRecord}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">New Personnel</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label> {this.state.genderTextValue}</Form.Label>
                            </Col>

                            <Col xs={3}>
                                <ToggleButton style={{ width: '150px' }} onLabel="Female" offLabel="Male" onIcon="pi pi-user" offIcon="pi pi-user"
                                    checked={this.state.genderCheck} onChange={(e) => this.setState({ genderCheck: e.value })} />
                            </Col>

                            <Col xs={3}>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={`${API_BASE}/Staff/upload`}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handlePhotoChange}
                                >
                                    {this.state.photoImageUrl ? <img src={this.state.photoImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Col>

                            <Col xs={1}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>Budge No:</Form.Label>
                            </Col>

                            <Col xs={2} style={{ textAlign: 'center' }}>
                                <Switch checkedChildren="Company" unCheckedChildren="Rental" checked={this.state.companyStaff} defaultChecked onChange={(value) => {
                                    this.setState({ companyStaff: value })
                                }} />
                            </Col>

                            <Col xs={3}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ budgeNo: e.target.value })} value={this.state.budgeNo} />
                            </Col>

                            <Col xs={1}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>{this.state.nationalityTextValue}</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <Form.Control name="nationality" value={this.state.nationality}
                                    onChange={this.handleChange2} as="select">
                                    <option value="">
                                        Select Nationality
                                    </option>
                                    {optionsNationalities}
                                </Form.Control>
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>{this.state.nameTextValue}</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>{this.state.midNameTextValue}</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ midName: e.target.value })} value={this.state.midName} />
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>{this.state.lastNameTextValue}</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ lastName: e.target.value })} value={this.state.lastName} />
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>Iqama No/national Id:</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ nationalId: e.target.value })} value={this.state.nationalId} />
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>Personal Phone:</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ residencePersonelPhone: e.target.value })} value={this.state.residencePersonelPhone} />
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>Company Email:</Form.Label>
                            </Col>

                            <Col xs={5}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ residenceCompanyEmail: e.target.value })} value={this.state.residenceCompanyEmail} />
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>
                                    Department:
                                </Form.Label>
                            </Col>

                            <Col xs={5}>
                                <Form.Control name="department1" value={this.state.department1}
                                    onChange={this.handleChangeDepartment} as="select">
                                    <option value="">
                                        Select Department
                                    </option>
                                    {optionsDepartments}
                                </Form.Control>
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>
                                    Discipline:
                                </Form.Label>
                            </Col>

                            <Col xs={5}>
                                <Form.Control name="discipline1" value={this.state.discipline1}
                                    onChange={this.handleChangeDiscipline} as="select">
                                    <option value="">
                                        Select Discipline
                                        </option>
                                    {optionsDisciplines}
                                </Form.Control>
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>
                                    Designation:
                                </Form.Label>
                            </Col>

                            <Col xs={5}>
                                <Form.Control name="designation1" value={this.state.designation1}
                                    onChange={this.handleChangeDesignation} as="select">
                                    <option value="">
                                        Select Designation
                                    </option>
                                    {optionsDesignations}
                                </Form.Control>
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={2}>
                            </Col>

                            <Col xs={3}>
                                <Form.Label>
                                    Role Group:
                                </Form.Label>
                            </Col>

                            <Col xs={5}>
                                <Form.Control name="roleGroup1" value={this.state.roleGroup1}
                                    onChange={this.handleChangeRoleGroup} as="select">
                                    <option value="">
                                        Select Role Group
                                    </option>
                                    {optionsRoleGroups}
                                </Form.Control>
                            </Col>

                            <Col xs={2}>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalPromptRecord}>Close</ReactButton>
                        <ReactButton variant="info" onClick={this.savePersonnelLogOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">There are missing personnel information values.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Can you check all the values you entered?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="info" onClick={this.showOrHideModal}>Close</ReactButton>
                    </Modal.Footer>
                </Modal>

                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={2}>
                            <ExcelReader onCreateJson={this.handleExcelData.bind(this)}></ExcelReader>
                        </Col>
                        <Col xs={2}>
                            <PrimeButton onClick={this.export} icon="pi pi-cloud-download" type='button' style={{ width: '150px' }} id='update-lang' className="p-button-info" label="Export Data" />
                        </Col>
                        <Col xs={5}></Col>
                        <Col xs={3}><ReactButton onClick={this.showOrHideModalPrompt} style={{ width: '100%' }} variant="info" >Create New</ReactButton></Col>
                    </Row>
                </div>

                <DataTable
                    noHeader
                    columns={columnsPersonel(this.editPersonelLog, this.deletePersonelLog, this.showRevision)}
                    data={this.state.personels}
                    sortIcon={sortIcon}
                    pagination
                    expandableRows
                    persistTableHead
                    expandableRowsComponent={<ExpanableComponent />}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    striped
                    actions={actions}
                />
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PersonelLog)