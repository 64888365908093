/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import { Avatar } from 'antd';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "ClientPersonnelLog";

export class ClientPersonnelLog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            //[STATE_FIELDS],
            name: null,
            surname: null,
            email: null,
            photoPath: null,
            departmentId: null,
            gender: null,
            designation: null,
            phoneNo: null,
            status: null,

            codeName: null,
            departmentSelectItems: [],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillDepartments();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            photoPath: this.state.filePath,
            departmentId: this.state.departmentId,
            gender: this.state.gender,
            designation: this.state.designation,
            phoneNo: this.state.phoneNo,
            status: this.state.status,
        };//SAVE_OBJECT kodlandığında kaldırılacak
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ClientPersonnelLog/add", obj);
        else
            await handleRequest("POST", "/ClientPersonnelLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            name: row.name,
            surname: row.surname,
            email: row.email,
            photoPath: row.photoPath,
            departmentId: row.departmentId,
            gender: row.gender,
            designation: row.designation,
            phoneNo: row.filePath,
            status: row.status,
        });

        this.setState({
            //  [EDIT_STATE_FIELDS], 
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

            id: row.id,
            name: row.name,
            surname: row.surname,
            email: row.email,
            photoPath: row.photoPath,
            departmentId: row.departmentId,
            gender: row.gender,
            designation: row.designation,
            phoneNo: row.filePath,
            status: row.status,
            filePath:row.photoPath,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ClientPersonnelLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/ClientPersonnelLog/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            name: null,
            surname: null,
            email: null,
            photoPath: null,
            departmentId: null,
            gender: null,
            designation: null,
            phoneNo: null,
            status: null,

        });

        this.setState({
            // [RESETINPUTS_STATES],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            name: null,
            surname: null,
            email: null,
            photoPath: null,
            departmentId: null,
            gender: null,
            designation: null,
            phoneNo: null,
            status: null,

        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addCodedepartmentId = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "department"
            }

            var response = await handleRequest("POST", "/Code/add", newItem);
            if (response.type === "ERROR") {
                this.error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDepartments();
            }
        } else {
            this.showError("Please enter department");
        }
    };

    fillDepartments = async () => {
        const response = await handleRequest("GET", "/Code/type/department");
        if (Boolean(response.data)) {
            this.setState({
                departmentSelectItems: response.data
            });
        }
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.Gender").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.Gender" defaultMessage="Gender" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="gender"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Gender")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Gender").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Gender")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Gender").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Radio.Group id="ClientPersonnelLog.Gender" onChange={(e) => {
                                                            this.setState({
                                                                gender: e.target.value
                                                            })
                                                        }} value={this.state.gender}>
                                                            <Radio value={"Male"}>Male</Radio>
                                                            <Radio value={"Female"}>Female</Radio>
                                                        </Radio.Group>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.Name").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.Name" defaultMessage="Name" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="name"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Name")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Name").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Name")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Name").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Name" style={{ width: "100%" }} id="ClientPersonnelLog.Name" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.Surname").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.Surname" defaultMessage="Surname" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="surname"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Surname")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Surname").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Surname")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Surname").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Surname" style={{ width: "100%" }} id="ClientPersonnelLog.Surname" value={this.state.surname} onChange={(e) => { this.setState({ surname: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        } 
                                         {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.photo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.photo" defaultMessage="Photo" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="photoPath"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.photo")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.photo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.photo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.photo").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Upload {...props} id="ClientPersonnelLog.photo"
                                                            showUploadList={true}
                                                            fileList={this.state.fileList}
                                                            onChange={({ fileList }) => {
                                                                if (fileList.length == 1) {
                                                                    var filePath = "";
                                                                    if (Boolean(fileList[0].response)) {
                                                                        filePath = fileList[0].response.url;
                                                                        this.showSuccess("file uploaded successfully");
                                                                        // fileName: info.file.name, 
                                                                    }
                                                                    this.setState({
                                                                        fileList: fileList,
                                                                        filePath: filePath,
                                                                    });
                                                                }
                                                            }}
                                                            beforeUpload={(fileList) => {
                                                                if (this.state.fileList.length > 0) {
                                                                    this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                                    return false;
                                                                }
                                                                return true;
                                                            }}
                                                            onRemove={({ fileList }) => {
                                                                this.setState({
                                                                    fileList: [],
                                                                    filePath: "",
                                                                })
                                                            }
                                                            }>
                                                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                                <UploadOutlined /> Upload
                            </AntButton>
                                                        </Upload>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }
                                         {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.Department").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.Department" defaultMessage="Department" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="departmentId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Department")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Department").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Department")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.Department").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Department"
                                                            value={this.state.departmentId} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    departmentId: value
                                                                });
                                                                this.setState({
                                                                    departmentId: value
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodedepartmentId}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.departmentSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.designation").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.designation" defaultMessage="Designation" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="designation"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.designation")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.designation").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.designation")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.designation").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Designation" style={{ width: "100%" }} id="ClientPersonnelLog.designation" value={this.state.designation} onChange={(e) => { this.setState({ designation: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.phoneno").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.phoneno" defaultMessage="Phone No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="phoneNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.phoneno")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.phoneno").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.phoneno")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.phoneno").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Phone No" style={{ width: "100%" }} id="ClientPersonnelLog.phoneno" value={this.state.phoneNo} onChange={(e) => { this.setState({ phoneNo: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.email").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.email" defaultMessage="Email" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="email"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.email")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.email").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.email")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.email").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Email" style={{ width: "100%" }} id="ClientPersonnelLog.email" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientPersonnelLog.status").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ClientPersonnelLog.status" defaultMessage="Status" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="status"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.status")) ? this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.status").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.status")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ClientPersonnelLog.status").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Status" id="ClientPersonnelLog.status" value={this.state.status} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                status: value
                                                            });
                                                            this.setState({ status: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option> 
                                                            <Option key={"Active"} value={"Active"}>Active</Option> 
                                                            <Option key={"Released"} value={"Released"}>Select</Option> 
                                                            
                                                            </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        } 



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                    <Column title="S/N" dataIndex="index" />
                                    <Column title="Photo" render={(value, row, index) => {
                                        if (Boolean(row.photoPath))
                                        return <Avatar shape="square" size={64} src={row.photoPath} />;
                                    else return ""; 
                                    }} />
                                    <Column title="Gender" dataIndex="gender" />
                                    <Column title="Name" dataIndex="name" />
                                    <Column title="Surname" dataIndex="surname" />

                                    <Column title="Email" dataIndex="email" />
                                    <Column title="Phone" dataIndex="phoneNo" />
                                    <Column title="Department" render={(value, row, index) => {
                                        if (Boolean(row.department))
                                            return row.department.name;
                                    }} />
                                    <Column title="Designation" dataIndex="designation" />
                                    <Column title="Status" dataIndex="status" />
                                    
                                    <Column title="Action" render={(value, row, index) => {

                                        return this.actionTemplate(row);

                                    }} />
                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPersonnelLog)
