/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Col, Row, Form, Container } from 'react-bootstrap';
//import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { ToggleButton } from 'primereact/togglebutton';
import { DataTable } from 'primereact/datatable';
//import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Button as ReactButton } from 'react-bootstrap';
import HorizontalTimeline from "react-horizontal-timeline";
import { Modal } from 'react-bootstrap';
//import { Dropdown, DropdownButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import "./css/ShipmentLog.scss";
import { TimePicker } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { Tree } from 'primereact/tree';
import { Toast } from 'primereact/toast';
import { Button as PrimeButton } from "primereact/button";
import { DatePicker } from 'antd';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';

const { RangePicker } = TimePicker;
const { Option } = Select;

export class Shifts extends Component {

    constructor() {
        super();
        this.state = {
            date: null,
            date1: null,
            hidden: true,
            shiftId: 0,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            hiddenUpdateButton: true,
            hiddenSaveButton: false,
            shifts: [],
            shiftDescription: "",
            startDate: null,
            endDate: null,
            disableCheckBoxes: true,
            workingDays: [],
            firstWorkingDay: "Select First Working Day",
            days: [
                { label: "Monday", value: "Monday" },
                { label: "Tuesday", value: "Tuesday" },
                { label: "Wednesday", value: "Wednesday" },
                { label: "Thursday", value: "Thursday" },
                { label: "Friday", value: "Friday" },
                { label: "Saturday", value: "Saturday" },
                { label: "Sunday", value: "Sunday" }
            ],
            //selectedWorkingDays: [],
            shiftPlan: true,
            hourMonday: null,
            hourMonday1: "00:00",
            hourMonday2: "00:00",
            hourTuesday: null,
            hourTuesday1: "00:00",
            hourTuesday2: "00:00",
            hourWednesday: null,
            hourWednesday1: "00:00",
            hourWednesday2: "00:00",
            hourThursday: null,
            hourThursday1: "00:00",
            hourThursday2: "00:00",
            hourFriday: null,
            hourFriday1: "00:00",
            hourFriday2: "00:00",
            hourSaturday: null,
            hourSaturday1: "00:00",
            hourSaturday2: "00:00",
            hourSunday: null,
            hourSunday1: "00:00",
            hourSunday2: "00:00",
            mondayAction: null,
            tuesdayAction: null,
            wednesdayAction: null,
            thursdayAction: null,
            fridayAction: null,
            saturdayAction: null,
            sundayAction: null,
            mondayIndex: 0,
            timelineHoursMonday: [],
            hiddenActionMonday: true,
            hiddenAddMonday: true,
            hiddenUpdateMonday: true,
            hiddenDeleteMonday: true,
            hiddenCancelMonday: true,
            hiddenHourMonday: 'hidden',
            hiddenApplyMonday: true,
            hiddenTimelineMonday: true,
            tuesdayIndex: 0,
            timelineHoursTuesday: [],
            hiddenActionTuesday: true,
            hiddenAddTuesday: true,
            hiddenUpdateTuesday: true,
            hiddenDeleteTuesday: true,
            hiddenCancelTuesday: true,
            hiddenHourTuesday: 'hidden',
            hiddenApplyTuesday: true,
            hiddenTimelineTuesday: true,
            wednesdayIndex: 0,
            timelineHoursWednesday: [],
            hiddenActionWednesday: true,
            hiddenAddWednesday: true,
            hiddenUpdateWednesday: true,
            hiddenDeleteWednesday: true,
            hiddenCancelWednesday: true,
            hiddenHourWednesday: 'hidden',
            hiddenApplyWednesday: true,
            hiddenTimelineWednesday: true,
            thursdayIndex: 0,
            timelineHoursThursday: [],
            hiddenActionThursday: true,
            hiddenAddThursday: true,
            hiddenUpdateThursday: true,
            hiddenDeleteThursday: true,
            hiddenCancelThursday: true,
            hiddenHourThursday: 'hidden',
            hiddenApplyThursday: true,
            hiddenTimelineThursday: true,
            fridayIndex: 0,
            timelineHoursFriday: [],
            hiddenActionFriday: true,
            hiddenAddFriday: true,
            hiddenUpdateFriday: true,
            hiddenDeleteFriday: true,
            hiddenCancelFriday: true,
            hiddenHourFriday: 'hidden',
            hiddenApplyFriday: true,
            hiddenTimelineFriday: true,
            saturdayIndex: 0,
            timelineHoursSaturday: [],
            hiddenActionSaturday: true,
            hiddenAddSaturday: true,
            hiddenUpdateSaturday: true,
            hiddenDeleteSaturday: true,
            hiddenCancelSaturday: true,
            hiddenHourSaturday: 'hidden',
            hiddenApplySaturday: true,
            hiddenTimelineSaturday: true,
            sundayIndex: 0,
            timelineHoursSunday: [],
            hiddenActionSunday: true,
            hiddenAddSunday: true,
            hiddenUpdateSunday: true,
            hiddenDeleteSunday: true,
            hiddenCancelSunday: true,
            hiddenHourSunday: 'hidden',
            hiddenApplySunday: true,
            hiddenTimelineSunday: true,
            infoModal: false,
            nodes: [],
            selectedNodeKeys: null,
            deleteModal: false,
            actions: [],
            newAction: "",
            action: ""
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        this.onWorkingDayChange = this.onWorkingDayChange.bind(this);
        this.onWorkingDayChange0 = this.onWorkingDayChange0.bind(this);

        this.actionTemplate = this.actionTemplate.bind(this);
        this.workingDaysTemplate = this.workingDaysTemplate.bind(this);
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong. Please check inputs.' });
    }

    componentDidMount = async () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: today,
            date1: today
        });

        this.fillCodes();

        var response1 = [];
        response1 = await handleRequest("GET", "/WFLocation/getNodesLocations");


        if (response1.length === 0 || response1.data.length === 0) {
            this.setState({
                nodes: []

            });
        } else {
            if (response1.data.length !== 0) {

                this.setState({
                    nodes: response1.data
                });
            }
        }

        var response = await handleRequest("GET", "/Shift/getAll");

        console.log(response.data);

        if (response.data.length === 0) {
            this.setState({
                shifts: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                shifts: response.data,
            });
        }

    }

    onWorkingDayChange0(e) {

        this.setState({ workingDays: e.value });

        if (e.value.includes("Monday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Monday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursMonday: [],
                    hiddenActionMonday: false,
                    hiddenHourMonday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursMonday: [],
                    hiddenActionMonday: false,
                    hiddenHourMonday: 'visible'
                });
            }
        }

        if (!e.value.includes("Monday")) {
            this.setState({
                hiddenActionMonday: true,
                hiddenTimelineMonday: true,
                hiddenHourMonday: 'hidden',
                hiddenUpdateMonday: true,
                hiddenCancelMonday: true,
                hiddenDeleteMonday: true,
                hiddenApplyMonday: true
            });
        }

        if (e.value.includes("Tuesday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Tuesday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursTuesday: [],
                    hiddenActionTuesday: false,
                    hiddenHourTuesday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursTuesday: [],
                    hiddenActionTuesday: false,
                    hiddenHourTuesday: 'visible'
                });
            }
        }

        if (!e.value.includes("Tuesday")) {
            this.setState({
                hiddenActionTuesday: true,
                hiddenHourTuesday: 'hidden',
                hiddenTimelineTuesday: true,
                hiddenApplyTuesday: true,
                hiddenUpdateTuesday: true,
                hiddenCancelTuesday: true,
                hiddenDeleteTuesday: true,
            });
        }

        if (e.value.includes("Wednesday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Wednesday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursWednesday: [],
                    hiddenActionWednesday: false,
                    hiddenHourWednesday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursWednesday: [],
                    hiddenActionWednesday: false,
                    hiddenHourWednesday: 'visible'
                });
            }
        }

        if (!e.value.includes("Wednesday")) {
            this.setState({
                hiddenActionWednesday: true,
                hiddenHourWednesday: 'hidden',
                hiddenTimelineWednesday: true,
                hiddenApplyWednesday: true,
                hiddenUpdateWednesday: true,
                hiddenCancelWednesday: true,
                hiddenDeleteWednesday: true,
            });
        }

        if (e.value.includes("Thursday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Thursday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursThursday: [],
                    hiddenActionThursday: false,
                    hiddenHourThursday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursThursday: [],
                    hiddenActionThursday: false,
                    hiddenHourThursday: 'visible'
                });
            }
        }

        if (!e.value.includes("Thursday")) {
            this.setState({
                hiddenActionThursday: true,
                hiddenHourThursday: 'hidden',
                hiddenTimelineThursday: true,
                hiddenApplyThursday: true,
                hiddenUpdateThursday: true,
                hiddenCancelThursday: true,
                hiddenDeleteThursday: true,
            });
        }

        if (e.value.includes("Friday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Friday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursFriday: [],
                    hiddenActionFriday: false,
                    hiddenHourFriday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursFriday: [],
                    hiddenActionFriday: false,
                    hiddenHourFriday: 'visible'
                });
            }
        }

        if (!e.value.includes("Friday")) {
            this.setState({
                hiddenActionFriday: true,
                hiddenHourFriday: 'hidden',
                hiddenTimelineFriday: true,
                hiddenApplyFriday: true,
                hiddenUpdateFriday: true,
                hiddenCancelFriday: true,
                hiddenDeleteFriday: true,
            });
        }

        if (e.value.includes("Saturday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Saturday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursSaturday: [],
                    hiddenActionSaturday: false,
                    hiddenHourSaturday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursSaturday: [],
                    hiddenActionSaturday: false,
                    hiddenHourSaturday: 'visible'
                });
            }
        }

        if (!e.value.includes("Saturday")) {
            this.setState({
                hiddenActionSaturday: true,
                hiddenHourSaturday: 'hidden',
                hiddenTimelineSaturday: true,
                hiddenApplySaturday: true,
                hiddenUpdateSaturday: true,
                hiddenCancelSaturday: true,
                hiddenDeleteSaturday: true,
            });
        }

        if (e.value.includes("Sunday")) {
            if (this.state.shiftPlan === true && this.state.firstWorkingDay === "Sunday" && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursSunday: [],
                    hiddenActionSunday: false,
                    hiddenHourSunday: 'visible'
                });
            }
            if (this.state.shiftPlan === false && this.state.shiftId === 0) {
                this.setState({
                    timelineHoursSunday: [],
                    hiddenActionSunday: false,
                    hiddenHourSunday: 'visible'
                });
            }
        }

        if (!e.value.includes("Sunday")) {
            this.setState({
                hiddenActionSunday: true,
                hiddenHourSunday: 'hidden',
                hiddenTimelineSunday: true,
                hiddenApplySunday: true,
                hiddenUpdateSunday: true,
                hiddenCancelSunday: true,
                hiddenDeleteSunday: true,
            });
        }
    }

    onWorkingDayChange(e) {

        let selectedworkingDays = [...this.state.workingDays];

        if (e.checked)
            selectedworkingDays.push(e.value);
        else
            selectedworkingDays.splice(selectedworkingDays.indexOf(e.value), 1);

        this.setState({ workingDays: selectedworkingDays });

        if (e.value === 'Monday' && e.checked) {
            this.setState({
                timelineHoursMonday: [],
                hiddenActionMonday: false,
                hiddenHourMonday: 'visible'
            });
        }

        if (e.value === 'Monday' && !e.checked) {
            this.setState({
                hiddenActionMonday: true,
                hiddenTimelineMonday: true,
                hiddenHourMonday: 'hidden',
                hiddenUpdateMonday: true,
                hiddenCancelMonday: true,
                hiddenDeleteMonday: true,
                hiddenApplyMonday: true
            });
        }

        if (e.value === 'Tuesday' && e.checked) {
            this.setState({
                timelineHoursTuesday: [],
                hiddenActionTuesday: false,
                hiddenHourTuesday: 'visible'
            });
        }

        if (e.value === 'Tuesday' && !e.checked) {
            this.setState({
                hiddenActionTuesday: true,
                hiddenHourTuesday: 'hidden',
                hiddenTimelineTuesday: true,
                hiddenApplyTuesday: true
            });
        }

        if (e.value === 'Wednesday' && e.checked) {
            this.setState({
                timelineHoursWednesday: [],
                hiddenActionWednesday: false,
                hiddenHourWednesday: 'visible'
            });
        }

        if (e.value === 'Wednesday' && !e.checked) {
            this.setState({
                hiddenActionWednesday: true,
                hiddenHourWednesday: 'hidden',
                hiddenTimelineWednesday: true,
                hiddenApplyWednesday: true
            });
        }

        if (e.value === 'Thursday' && e.checked) {
            this.setState({
                timelineHoursThursday: [],
                hiddenActionThursday: false,
                hiddenHourThursday: 'visible'
            });
        }

        if (e.value === 'Thursday' && !e.checked) {
            this.setState({
                hiddenActionThursday: true,
                hiddenHourThursday: 'hidden',
                hiddenTimelineThursday: true,
                hiddenApplyThursday: true
            });
        }

        if (e.value === 'Friday' && e.checked) {
            this.setState({
                timelineHoursFriday: [],
                hiddenActionFriday: false,
                hiddenHourFriday: 'visible'
            });
        }

        if (e.value === 'Friday' && !e.checked) {
            this.setState({
                hiddenActionFriday: true,
                hiddenHourFriday: 'hidden',
                hiddenTimelineFriday: true,
                hiddenApplyFriday: true
            });
        }

        if (e.value === 'Saturday' && e.checked) {
            this.setState({
                timelineHoursSaturday: [],
                hiddenActionSaturday: false,
                hiddenHourSaturday: 'visible'
            });
        }

        if (e.value === 'Saturday' && !e.checked) {
            this.setState({
                hiddenActionSaturday: true,
                hiddenHourSaturday: 'hidden',
                hiddenTimelineSaturday: true,
                hiddenApplySaturday: true
            });
        }

        if (e.value === 'Sunday' && e.checked) {
            this.setState({
                timelineHoursSunday: [],
                hiddenActionSunday: false,
                hiddenHourSunday: 'visible'
            });
        }

        if (e.value === 'Sunday' && !e.checked) {
            this.setState({
                hiddenActionSunday: true,
                hiddenHourSunday: 'hidden',
                hiddenTimelineSunday: true,
                hiddenApplySunday: true
            });
        }
    }

    createNewShift = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hidden: false,
                createNewButton: "Stop Adding New",
                createNewButtonVariant: "danger",
                hiddenSaveButton: false,
                hiddenUpdateButton: true,
            });
            this.refreshNewCreate();
        } else {
            this.refreshFirstCreate();
        }
    }

    refreshNewCreate = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: today,
            date1: today
        });

        this.setState({
            shiftId: 0,
            shiftDescription: "",
            startDate: today,
            endDate: today,
            disableCheckBoxes: true,
            workingDays: [],
            firstWorkingDay: "Select First Working Day",
            shiftPlan: true,
            hourMonday: null,
            hourMonday1: "00:00",
            hourMonday2: "00:00",
            hourTuesday: null,
            hourTuesday1: "00:00",
            hourTuesday2: "00:00",
            hourWednesday: null,
            hourWednesday1: "00:00",
            hourWednesday2: "00:00",
            hourThursday: null,
            hourThursday1: "00:00",
            hourThursday2: "00:00",
            hourFriday: null,
            hourFriday1: "00:00",
            hourFriday2: "00:00",
            hourSaturday: null,
            hourSaturday1: "00:00",
            hourSaturday2: "00:00",
            hourSunday: null,
            hourSunday1: "00:00",
            hourSunday2: "00:00",
            mondayAction: null,
            tuesdayAction: null,
            wednesdayAction: null,
            thursdayAction: null,
            fridayAction: null,
            saturdayAction: null,
            sundayAction: null,
            mondayIndex: 0,
            timelineHoursMonday: [],
            hiddenActionMonday: true,
            hiddenAddMonday: true,
            hiddenUpdateMonday: true,
            hiddenDeleteMonday: true,
            hiddenCancelMonday: true,
            hiddenHourMonday: 'hidden',
            hiddenApplyMonday: true,
            hiddenTimelineMonday: true,
            tuesdayIndex: 0,
            timelineHoursTuesday: [],
            hiddenActionTuesday: true,
            hiddenAddTuesday: true,
            hiddenUpdateTuesday: true,
            hiddenDeleteTuesday: true,
            hiddenCancelTuesday: true,
            hiddenHourTuesday: 'hidden',
            hiddenApplyTuesday: true,
            hiddenTimelineTuesday: true,
            wednesdayIndex: 0,
            timelineHoursWednesday: [],
            hiddenActionWednesday: true,
            hiddenAddWednesday: true,
            hiddenUpdateWednesday: true,
            hiddenDeleteWednesday: true,
            hiddenCancelWednesday: true,
            hiddenHourWednesday: 'hidden',
            hiddenApplyWednesday: true,
            hiddenTimelineWednesday: true,
            thursdayIndex: 0,
            timelineHoursThursday: [],
            hiddenActionThursday: true,
            hiddenAddThursday: true,
            hiddenUpdateThursday: true,
            hiddenDeleteThursday: true,
            hiddenCancelThursday: true,
            hiddenHourThursday: 'hidden',
            hiddenApplyThursday: true,
            hiddenTimelineThursday: true,
            fridayIndex: 0,
            timelineHoursFriday: [],
            hiddenActionFriday: true,
            hiddenAddFriday: true,
            hiddenUpdateFriday: true,
            hiddenDeleteFriday: true,
            hiddenCancelFriday: true,
            hiddenHourFriday: 'hidden',
            hiddenApplyFriday: true,
            hiddenTimelineFriday: true,
            saturdayIndex: 0,
            timelineHoursSaturday: [],
            hiddenActionSaturday: true,
            hiddenAddSaturday: true,
            hiddenUpdateSaturday: true,
            hiddenDeleteSaturday: true,
            hiddenCancelSaturday: true,
            hiddenHourSaturday: 'hidden',
            hiddenApplySaturday: true,
            hiddenTimelineSaturday: true,
            sundayIndex: 0,
            timelineHoursSunday: [],
            hiddenActionSunday: true,
            hiddenAddSunday: true,
            hiddenUpdateSunday: true,
            hiddenDeleteSunday: true,
            hiddenCancelSunday: true,
            hiddenHourSunday: 'hidden',
            hiddenApplySunday: true,
            hiddenTimelineSunday: true,
            selectedNodeKeys: null
        });
    }

    refreshFirstCreate = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: today,
            date1: today
        });

        this.setState({
            shiftId: 0,
            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            hiddenUpdateButton: true,
            hiddenSaveButton: false,
            shiftDescription: "",
            startDate: today,
            endDate: today,
            disableCheckBoxes: true,
            workingDays: [],
            firstWorkingDay: "Select First Working Day",
            shiftPlan: true,
            hourMonday: null,
            hourMonday1: "00:00",
            hourMonday2: "00:00",
            hourTuesday: null,
            hourTuesday1: "00:00",
            hourTuesday2: "00:00",
            hourWednesday: null,
            hourWednesday1: "00:00",
            hourWednesday2: "00:00",
            hourThursday: null,
            hourThursday1: "00:00",
            hourThursday2: "00:00",
            hourFriday: null,
            hourFriday1: "00:00",
            hourFriday2: "00:00",
            hourSaturday: null,
            hourSaturday1: "00:00",
            hourSaturday2: "00:00",
            hourSunday: null,
            hourSunday1: "00:00",
            hourSunday2: "00:00",
            mondayAction: null,
            tuesdayAction: null,
            wednesdayAction: null,
            thursdayAction: null,
            fridayAction: null,
            saturdayAction: null,
            sundayAction: null,
            mondayIndex: 0,
            timelineHoursMonday: [],
            hiddenActionMonday: true,
            hiddenAddMonday: true,
            hiddenUpdateMonday: true,
            hiddenDeleteMonday: true,
            hiddenCancelMonday: true,
            hiddenHourMonday: 'hidden',
            hiddenApplyMonday: true,
            hiddenTimelineMonday: true,
            tuesdayIndex: 0,
            timelineHoursTuesday: [],
            hiddenActionTuesday: true,
            hiddenAddTuesday: true,
            hiddenUpdateTuesday: true,
            hiddenDeleteTuesday: true,
            hiddenCancelTuesday: true,
            hiddenHourTuesday: 'hidden',
            hiddenApplyTuesday: true,
            hiddenTimelineTuesday: true,
            wednesdayIndex: 0,
            timelineHoursWednesday: [],
            hiddenActionWednesday: true,
            hiddenAddWednesday: true,
            hiddenUpdateWednesday: true,
            hiddenDeleteWednesday: true,
            hiddenCancelWednesday: true,
            hiddenHourWednesday: 'hidden',
            hiddenApplyWednesday: true,
            hiddenTimelineWednesday: true,
            thursdayIndex: 0,
            timelineHoursThursday: [],
            hiddenActionThursday: true,
            hiddenAddThursday: true,
            hiddenUpdateThursday: true,
            hiddenDeleteThursday: true,
            hiddenCancelThursday: true,
            hiddenHourThursday: 'hidden',
            hiddenApplyThursday: true,
            hiddenTimelineThursday: true,
            fridayIndex: 0,
            timelineHoursFriday: [],
            hiddenActionFriday: true,
            hiddenAddFriday: true,
            hiddenUpdateFriday: true,
            hiddenDeleteFriday: true,
            hiddenCancelFriday: true,
            hiddenHourFriday: 'hidden',
            hiddenApplyFriday: true,
            hiddenTimelineFriday: true,
            saturdayIndex: 0,
            timelineHoursSaturday: [],
            hiddenActionSaturday: true,
            hiddenAddSaturday: true,
            hiddenUpdateSaturday: true,
            hiddenDeleteSaturday: true,
            hiddenCancelSaturday: true,
            hiddenHourSaturday: 'hidden',
            hiddenApplySaturday: true,
            hiddenTimelineSaturday: true,
            sundayIndex: 0,
            timelineHoursSunday: [],
            hiddenActionSunday: true,
            hiddenAddSunday: true,
            hiddenUpdateSunday: true,
            hiddenDeleteSunday: true,
            hiddenCancelSunday: true,
            hiddenHourSunday: 'hidden',
            hiddenApplySunday: true,
            hiddenTimelineSunday: true,
            selectedNodeKeys: null
        });
    }

    saveShift = async () => {
        if (this.state.shiftDescription !== "" && this.state.endDate !== null && this.state.startDate !== null && this.state.selectedNodeKeys !== null) {

            var workingDaysText = "";

            if (this.state.workingDays.length !== 0) {
                for (let index = 0; index < this.state.workingDays.length; index++) {
                    workingDaysText += this.state.workingDays[index] + ","
                }
            }

            if ((workingDaysText.charAt(workingDaysText.length - 1)) === ",") {
                workingDaysText = workingDaysText.slice(0, -1);
            }

            var list = [];
            var flagArray = Object.keys(this.state.selectedNodeKeys);  //Bu kısımda nesnenin içine göre checked, half checked tarzı bir şey oluşturulmalı.

            flagArray.forEach(element => {
                list.push(element.split(".")[1]);
            });

            if (this.state.shiftPlan === false) {

                // #region Create shiftDays from timelines

                var shiftDays = [];

                for (let index = 0; index < this.state.timelineHoursMonday.length; index++) {

                    var actionId = 0;

                    for (var z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursMonday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursMonday",
                        flagData: this.state.timelineHoursMonday[index].flagData,
                        hour: this.state.timelineHoursMonday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursTuesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursTuesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursTuesday",
                        flagData: this.state.timelineHoursTuesday[index].flagData,
                        hour: this.state.timelineHoursTuesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursWednesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursWednesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursWednesday",
                        flagData: this.state.timelineHoursWednesday[index].flagData,
                        hour: this.state.timelineHoursWednesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursThursday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursThursday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursThursday",
                        flagData: this.state.timelineHoursThursday[index].flagData,
                        hour: this.state.timelineHoursThursday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursFriday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursFriday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursFriday",
                        flagData: this.state.timelineHoursFriday[index].flagData,
                        hour: this.state.timelineHoursFriday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursSaturday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursSaturday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSaturday",
                        flagData: this.state.timelineHoursSaturday[index].flagData,
                        hour: this.state.timelineHoursSaturday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursSunday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursSunday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSunday",
                        flagData: this.state.timelineHoursSunday[index].flagData,
                        hour: this.state.timelineHoursSunday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                // #endregion

                const newShift = {
                    shiftDescription: this.state.shiftDescription,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    workingDays: workingDaysText,
                    shiftDays: shiftDays,
                    shiftPlan: 0,
                    firstWorkingDayOfWeek: this.state.firstWorkingDay,
                    workLocations: list,
                }

                await handleRequest("POST", "/Shift/add", newShift);
                this.showSuccess();
                this.refreshFirstCreate();
            }

            var timelineHoursMonday = [];
            var timelineHoursTuesday = [];
            var timelineHoursWednesday = [];
            var timelineHoursThursday = [];
            var timelineHoursFriday = [];
            var timelineHoursSaturday = [];
            var timelineHoursSunday = [];

            if (this.state.shiftPlan === true) {

                //#region Apply to All Other Selected Days

                if (this.state.firstWorkingDay === "Monday") {
                    var flagData = this.state.timelineHoursMonday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }

                } else if (this.state.firstWorkingDay === "Tuesday") {

                    flagData = this.state.timelineHoursTuesday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }

                } else if (this.state.firstWorkingDay === "Wednesday") {
                    flagData = this.state.timelineHoursWednesday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Thursday") {
                    flagData = this.state.timelineHoursThursday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Friday") {
                    flagData = this.state.timelineHoursFriday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Saturday") {
                    flagData = this.state.timelineHoursSaturday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Sunday") {
                    flagData = this.state.timelineHoursSunday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                }

                // #endregion

                // #region Create shiftDays from timelines

                shiftDays = [];

                for (let index = 0; index < timelineHoursMonday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursMonday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursMonday",
                        flagData: timelineHoursMonday[index].flagData,
                        hour: timelineHoursMonday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursTuesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursTuesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursTuesday",
                        flagData: timelineHoursTuesday[index].flagData,
                        hour: timelineHoursTuesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursWednesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursWednesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursWednesday",
                        flagData: timelineHoursWednesday[index].flagData,
                        hour: timelineHoursWednesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursThursday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursThursday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursThursday",
                        flagData: timelineHoursThursday[index].flagData,
                        hour: timelineHoursThursday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursFriday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursFriday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursFriday",
                        flagData: timelineHoursFriday[index].flagData,
                        hour: timelineHoursFriday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursSaturday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursSaturday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSaturday",
                        flagData: timelineHoursSaturday[index].flagData,
                        hour: timelineHoursSaturday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursSunday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursSunday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSunday",
                        flagData: timelineHoursSunday[index].flagData,
                        hour: timelineHoursSunday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                // #endregion

                const newShift = {
                    shiftDescription: this.state.shiftDescription,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    workingDays: workingDaysText,
                    shiftDays: shiftDays,
                    shiftPlan: 1,
                    firstWorkingDayOfWeek: this.state.firstWorkingDay,
                    workLocations: list
                }

                await handleRequest("POST", "/Shift/add", newShift);
                this.showSuccess();
                this.refreshFirstCreate();
                this.restartShiftTable();
            }
        }
    }

    updateShift = async () => {
        if (this.state.shiftDescription !== "" && this.state.endDate !== null && this.state.startDate !== null && this.state.selectedNodeKeys !== null) {

            var workingDaysText = "";

            if (this.state.workingDays.length !== 0) {
                for (let index = 0; index < this.state.workingDays.length; index++) {
                    workingDaysText += this.state.workingDays[index] + ","
                }
            }

            if ((workingDaysText.charAt(workingDaysText.length - 1)) === ",") {
                workingDaysText = workingDaysText.slice(0, -1);
            }

            var list = [];
            var flagArray = Object.keys(this.state.selectedNodeKeys);

            flagArray.forEach(element => {
                list.push(element.split(".")[1]);
            });

            if (this.state.shiftPlan === false) {

                // #region Create shiftDays from timelines

                var shiftDays = [];

                for (let index = 0; index < this.state.timelineHoursMonday.length; index++) {

                    var actionId = 0;

                    for (var z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursMonday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursMonday",
                        flagData: this.state.timelineHoursMonday[index].flagData,
                        hour: this.state.timelineHoursMonday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursTuesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursTuesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursTuesday",
                        flagData: this.state.timelineHoursTuesday[index].flagData,
                        hour: this.state.timelineHoursTuesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursWednesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursWednesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursWednesday",
                        flagData: this.state.timelineHoursWednesday[index].flagData,
                        hour: this.state.timelineHoursWednesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursThursday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursThursday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursThursday",
                        flagData: this.state.timelineHoursThursday[index].flagData,
                        hour: this.state.timelineHoursThursday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursFriday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursFriday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursFriday",
                        flagData: this.state.timelineHoursFriday[index].flagData,
                        hour: this.state.timelineHoursFriday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursSaturday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursSaturday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSaturday",
                        flagData: this.state.timelineHoursSaturday[index].flagData,
                        hour: this.state.timelineHoursSaturday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < this.state.timelineHoursSunday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (this.state.timelineHoursSunday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSunday",
                        flagData: this.state.timelineHoursSunday[index].flagData,
                        hour: this.state.timelineHoursSunday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                // #endregion

                const updatedShift = {
                    id: this.state.shiftId,
                    shiftDescription: this.state.shiftDescription,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    workingDays: workingDaysText,
                    shiftDays: shiftDays,
                    shiftPlan: 0,
                    firstWorkingDayOfWeek: this.state.firstWorkingDay,
                    workLocations: list,
                }

                await handleRequest("POST", "/Shift/update", updatedShift);
                this.showSuccess();
                this.refreshFirstCreate();
            }

            var timelineHoursMonday = [];
            var timelineHoursTuesday = [];
            var timelineHoursWednesday = [];
            var timelineHoursThursday = [];
            var timelineHoursFriday = [];
            var timelineHoursSaturday = [];
            var timelineHoursSunday = [];

            if (this.state.shiftPlan === true) {

                //#region Apply to All Other Selected Days

                if (this.state.firstWorkingDay === "Monday") {
                    var flagData = this.state.timelineHoursMonday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }

                } else if (this.state.firstWorkingDay === "Tuesday") {

                    flagData = this.state.timelineHoursTuesday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }

                } else if (this.state.firstWorkingDay === "Wednesday") {
                    flagData = this.state.timelineHoursWednesday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Thursday") {
                    flagData = this.state.timelineHoursThursday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Friday") {
                    flagData = this.state.timelineHoursFriday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Saturday") {
                    flagData = this.state.timelineHoursSaturday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                } else if (this.state.firstWorkingDay === "Sunday") {
                    flagData = this.state.timelineHoursSunday.slice();

                    if (this.state.workingDays.indexOf('Monday') !== -1) {
                        timelineHoursMonday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Tuesday') !== -1) {
                        timelineHoursTuesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Wednesday') !== -1) {
                        timelineHoursWednesday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Thursday') !== -1) {
                        timelineHoursThursday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Friday') !== -1) {
                        timelineHoursFriday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Saturday') !== -1) {
                        timelineHoursSaturday = flagData;
                    }

                    if (this.state.workingDays.indexOf('Sunday') !== -1) {
                        timelineHoursSunday = flagData;
                    }
                }

                // #endregion

                // #region Create shiftDays from timelines

                shiftDays = [];

                for (let index = 0; index < timelineHoursMonday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursMonday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursMonday",
                        flagData: timelineHoursMonday[index].flagData,
                        hour: timelineHoursMonday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursTuesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursTuesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursTuesday",
                        flagData: timelineHoursTuesday[index].flagData,
                        hour: timelineHoursTuesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursWednesday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursWednesday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursWednesday",
                        flagData: timelineHoursWednesday[index].flagData,
                        hour: timelineHoursWednesday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursThursday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursThursday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursThursday",
                        flagData: timelineHoursThursday[index].flagData,
                        hour: timelineHoursThursday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursFriday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursFriday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursFriday",
                        flagData: timelineHoursFriday[index].flagData,
                        hour: timelineHoursFriday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursSaturday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursSaturday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSaturday",
                        flagData: timelineHoursSaturday[index].flagData,
                        hour: timelineHoursSaturday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                for (let index = 0; index < timelineHoursSunday.length; index++) {

                    actionId = 0;

                    for (z = 0; z < this.state.actions.length; z++) {
                        if (timelineHoursSunday[index].action === this.state.actions[z].name) {
                            actionId = this.state.actions[z].id;
                        }
                    }

                    const newFlagArray = {
                        day: "timelineHoursSunday",
                        flagData: timelineHoursSunday[index].flagData,
                        hour: timelineHoursSunday[index].hour,
                        action: actionId
                    }

                    shiftDays.push(newFlagArray);
                }

                // #endregion

                const updatedShift = {
                    id: this.state.shiftId,
                    shiftDescription: this.state.shiftDescription,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    workingDays: workingDaysText,
                    shiftDays: shiftDays,
                    shiftPlan: 1,
                    firstWorkingDayOfWeek: this.state.firstWorkingDay,
                    workLocations: list
                }

                await handleRequest("POST", "/Shift/update", updatedShift);
                this.showSuccess();
                this.refreshFirstCreate();
                this.restartShiftTable();
            }
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'mondayAction' && this.state.hiddenUpdateMonday === true) {
            this.setState({
                hiddenAddMonday: false
            });
        }

        if (e.target.name === 'tuesdayAction' && this.state.hiddenUpdateTuesday === true) {
            this.setState({
                hiddenAddTuesday: false
            });
        }

        if (e.target.name === 'wednesdayAction' && this.state.hiddenUpdateWednesday === true) {
            this.setState({
                hiddenAddWednesday: false
            });
        }

        if (e.target.name === 'thursdayAction' && this.state.hiddenUpdateThursday === true) {
            this.setState({
                hiddenAddThursday: false
            });
        }

        if (e.target.name === 'fridayAction' && this.state.hiddenUpdateFriday === true) {
            this.setState({
                hiddenAddFriday: false
            });
        }

        if (e.target.name === 'saturdayAction' && this.state.hiddenUpdateSaturday === true) {
            this.setState({
                hiddenAddSaturday: false
            });
        }

        if (e.target.name === 'sundayAction' && this.state.hiddenUpdateSunday === true) {
            this.setState({
                hiddenAddSunday: false
            });
        }
    }

    // #region Add-Update-Delete-Cancel Action to Timelines of Week Days 

    addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    addMondayTimeline = () => {

        if (this.state.hourMonday1 !== "" && this.state.hourMonday2 !== "" && this.state.mondayAction !== "" && this.state.mondayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourMonday1;
            hourAndMinute2 = this.state.hourMonday2;

            if (this.state.mondayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.mondayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.mondayAction
                }

                var flagTimelineHours = this.state.timelineHoursMonday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursMonday: flagTimelineHours,
                    hourMonday: null,
                    hourMonday1: "00:00",
                    hourMonday2: "00:00",
                    mondayAction: null,
                    hiddenAddMonday: true,
                    hiddenTimelineMonday: false,
                    hiddenApplyMonday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.mondayAction
                }

                flagTimelineHours = this.state.timelineHoursMonday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursMonday: flagTimelineHours,
                    hourMonday: null,
                    hourMonday1: "00:00",
                    hourMonday2: "00:00",
                    mondayAction: null,
                    hiddenAddMonday: true,
                    hiddenTimelineMonday: false,
                    hiddenApplyMonday: false
                });
            }
        }
    }

    updateMondayTimeline = () => {

        if (this.state.hourMonday1 !== "" && this.state.hourMonday2 !== "" && this.state.mondayAction !== "" && this.state.mondayAction !== "Select Action") {

            let selectedTimelineHoursMonday = this.state.timelineHoursMonday;

            if (selectedTimelineHoursMonday[this.state.mondayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursMonday.length; index++) {
                    if (selectedTimelineHoursMonday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursMonday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursMonday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursMonday.splice((this.state.mondayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourMonday1;
            hourAndMinute2 = this.state.hourMonday2;

            if (this.state.mondayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.mondayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.mondayAction
                }

                var flagTimelineHours = selectedTimelineHoursMonday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursMonday: flagTimelineHours,
                    hourMonday: null,
                    hourMonday1: "00:00",
                    hourMonday2: "00:00",
                    mondayAction: null,
                    hiddenAddMonday: true,
                    hiddenDeleteMonday: true,
                    hiddenUpdateMonday: true,
                    hiddenCancelMonday: true,
                    hiddenTimelineMonday: false,
                    hiddenApplyMonday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.mondayAction
                }

                flagTimelineHours = selectedTimelineHoursMonday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursMonday: flagTimelineHours,
                    hourMonday: null,
                    hourMonday1: "00:00",
                    hourMonday2: "00:00",
                    mondayAction: null,
                    hiddenAddMonday: true,
                    hiddenDeleteMonday: true,
                    hiddenUpdateMonday: true,
                    hiddenCancelMonday: true,
                    hiddenTimelineMonday: false,
                    hiddenApplyMonday: false
                });
            }
        }
    }

    deleteMondayTimeline = () => {
        let selectedTimelineHoursMonday = this.state.timelineHoursMonday;

        if (selectedTimelineHoursMonday[this.state.mondayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursMonday.length; index++) {
                if (selectedTimelineHoursMonday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursMonday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursMonday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursMonday.splice((this.state.mondayIndex), 1);

        }

        this.setState({
            timelineHoursMonday: selectedTimelineHoursMonday,
            hourMonday: null,
            hourMonday1: "00:00",
            hourMonday2: "00:00",
            mondayAction: null,
            hiddenAddMonday: true,
            hiddenDeleteMonday: true,
            hiddenUpdateMonday: true,
            hiddenCancelMonday: true,
            hiddenTimelineMonday: false,
            hiddenApplyMonday: false
        });
    }

    cancelMondayTimeline = () => {
        this.setState({
            hourMonday: null,
            hourMonday1: "00:00",
            hourMonday2: "00:00",
            mondayAction: null,
            hiddenAddMonday: true,
            hiddenDeleteMonday: true,
            hiddenUpdateMonday: true,
            hiddenCancelMonday: true,
            hiddenTimelineMonday: false,
            hiddenApplyMonday: false
        });
    }

    addTuesdayTimeline = () => {

        if (this.state.hourTuesday1 !== "" && this.state.hourTuesday2 !== "" && this.state.tuesdayAction !== "" && this.state.tuesdayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourTuesday1;
            hourAndMinute2 = this.state.hourTuesday2;

            if (this.state.tuesdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.tuesdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.tuesdayAction
                }

                var flagTimelineHours = this.state.timelineHoursTuesday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursTuesday: flagTimelineHours,
                    hourTuesday: null,
                    hourTuesday1: "00:00",
                    hourTuesday2: "00:00",
                    tuesdayAction: null,
                    hiddenAddTuesday: true,
                    hiddenTimelineTuesday: false,
                    hiddenApplyTuesday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.tuesdayAction
                }

                flagTimelineHours = this.state.timelineHoursTuesday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursTuesday: flagTimelineHours,
                    hourTuesday: null,
                    hourTuesday1: "00:00",
                    hourTuesday2: "00:00",
                    tuesdayAction: null,
                    hiddenAddTuesday: true,
                    hiddenTimelineTuesday: false,
                    hiddenApplyTuesday: false
                });
            }
        }
    }

    updateTuesdayTimeline = () => {

        if (this.state.hourTuesday1 !== "" && this.state.hourTuesday2 !== "" && this.state.tuesdayAction !== "" && this.state.tuesdayAction !== "Select Action") {

            let selectedTimelineHoursTuesday = this.state.timelineHoursTuesday;

            if (selectedTimelineHoursTuesday[this.state.tuesdayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursTuesday.length; index++) {
                    if (selectedTimelineHoursTuesday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursTuesday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursTuesday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursTuesday.splice((this.state.tuesdayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourTuesday1;
            hourAndMinute2 = this.state.hourTuesday2;

            if (this.state.tuesdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.tuesdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.tuesdayAction
                }

                var flagTimelineHours = selectedTimelineHoursTuesday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursTuesday: flagTimelineHours,
                    hourTuesday: null,
                    hourTuesday1: "00:00",
                    hourTuesday2: "00:00",
                    tuesdayAction: null,
                    hiddenAddTuesday: true,
                    hiddenDeleteTuesday: true,
                    hiddenUpdateTuesday: true,
                    hiddenCancelTuesday: true,
                    hiddenTimelineTuesday: false,
                    hiddenApplyTuesday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.tuesdayAction
                }

                flagTimelineHours = selectedTimelineHoursTuesday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursTuesday: flagTimelineHours,
                    hourTuesday: null,
                    hourTuesday1: "00:00",
                    hourTuesday2: "00:00",
                    tuesdayAction: null,
                    hiddenAddTuesday: true,
                    hiddenDeleteTuesday: true,
                    hiddenUpdateTuesday: true,
                    hiddenCancelTuesday: true,
                    hiddenTimelineTuesday: false,
                    hiddenApplyTuesday: false
                });
            }
        }
    }

    deleteTuesdayTimeline = () => {
        let selectedTimelineHoursTuesday = this.state.timelineHoursTuesday;

        if (selectedTimelineHoursTuesday[this.state.tuesdayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursTuesday.length; index++) {
                if (selectedTimelineHoursTuesday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursTuesday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursTuesday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursTuesday.splice((this.state.tuesdayIndex), 1);

        }

        this.setState({
            timelineHoursMonday: selectedTimelineHoursTuesday,
            hourTuesday: null,
            hourTuesday1: "00:00",
            hourTuesday2: "00:00",
            tuesdayAction: null,
            hiddenAddTuesday: true,
            hiddenDeleteTuesday: true,
            hiddenUpdateTuesday: true,
            hiddenCancelTuesday: true,
            hiddenTimelineTuesday: false,
            hiddenApplyTuesday: false
        });
    }

    cancelTuesdayTimeline = () => {
        this.setState({
            hourTuesday: null,
            hourTuesday1: "00:00",
            hourTuesday2: "00:00",
            tuesdayAction: null,
            hiddenAddTuesday: true,
            hiddenDeleteTuesday: true,
            hiddenUpdateTuesday: true,
            hiddenCancelTuesday: true,
            hiddenTimelineTuesday: false,
            hiddenApplyTuesday: false
        });
    }

    addWednesdayTimeline = () => {

        if (this.state.hourWednesday1 !== "" && this.state.hourWednesday2 !== "" && this.state.wednesdayAction !== "" && this.state.wednesdayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourWednesday1;
            hourAndMinute2 = this.state.hourWednesday2;

            if (this.state.wednesdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.wednesdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.wednesdayAction
                }

                var flagTimelineHours = this.state.timelineHoursWednesday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursMonday: flagTimelineHours,
                    hourWednesday: null,
                    hourWednesday1: "00:00",
                    hourWednesday2: "00:00",
                    wednesdayAction: null,
                    hiddenAddWednesday: true,
                    hiddenTimelineWednesday: false,
                    hiddenApplyWednesday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.wednesdayAction
                }

                flagTimelineHours = this.state.timelineHoursWednesday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursWednesday: flagTimelineHours,
                    hourWednesday: null,
                    hourWednesday1: "00:00",
                    hourWednesday2: "00:00",
                    wednesdayAction: null,
                    hiddenAddWednesday: true,
                    hiddenTimelineWednesday: false,
                    hiddenApplyWednesday: false
                });
            }
        }
    }

    updateWednesdayTimeline = () => {

        if (this.state.hourWednesday1 !== "" && this.state.hourWednesday2 !== "" && this.state.wednesdayAction !== "" && this.state.wednesdayAction !== "Select Action") {

            let selectedTimelineHoursWednesday = this.state.timelineHoursWednesday;

            if (selectedTimelineHoursWednesday[this.state.wednesdayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursWednesday.length; index++) {
                    if (selectedTimelineHoursWednesday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursWednesday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursWednesday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursWednesday.splice((this.state.wednesdayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourWednesday1;
            hourAndMinute2 = this.state.hourWednesday2;

            if (this.state.wednesdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.wednesdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.wednesdayAction
                }

                var flagTimelineHours = selectedTimelineHoursWednesday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursWednesday: flagTimelineHours,
                    hourWednesday: null,
                    hourWednesday1: "00:00",
                    hourWednesday2: "00:00",
                    wednesdayAction: null,
                    hiddenAddWednesday: true,
                    hiddenDeleteWednesday: true,
                    hiddenUpdateWednesday: true,
                    hiddenCancelWednesday: true,
                    hiddenTimelineWednesday: false,
                    hiddenApplyWednesday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.wednesdayAction
                }

                flagTimelineHours = selectedTimelineHoursWednesday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursWednesday: flagTimelineHours,
                    hourWednesday: null,
                    hourWednesday1: "00:00",
                    hourWednesday2: "00:00",
                    wednesdayAction: null,
                    hiddenAddWednesday: true,
                    hiddenDeleteWednesday: true,
                    hiddenUpdateWednesday: true,
                    hiddenCancelWednesday: true,
                    hiddenTimelineWednesday: false,
                    hiddenApplyWednesday: false
                });
            }
        }

    }

    deleteWednesdayTimeline = () => {

        let selectedTimelineHoursWednesday = this.state.timelineHoursWednesday;

        if (selectedTimelineHoursWednesday[this.state.wednesdayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursWednesday.length; index++) {
                if (selectedTimelineHoursWednesday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursWednesday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursWednesday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursWednesday.splice((this.state.wednesdayIndex), 1);

        }

        this.setState({
            timelineHoursWednesday: selectedTimelineHoursWednesday,
            hourWednesday: null,
            hourWednesday1: "00:00",
            hourWednesday2: "00:00",
            wednesdayAction: null,
            hiddenAddWednesday: true,
            hiddenDeleteWednesday: true,
            hiddenUpdateWednesday: true,
            hiddenCancelWednesday: true,
            hiddenTimelineWednesday: false,
            hiddenApplyWednesday: false
        });

    }

    cancelWednesdayTimeline = () => {
        this.setState({
            hourWednesday: null,
            hourWednesday1: "00:00",
            hourWednesday2: "00:00",
            wednesdayAction: null,
            hiddenAddWednesday: true,
            hiddenDeleteWednesday: true,
            hiddenUpdateWednesday: true,
            hiddenCancelWednesday: true,
            hiddenTimelineWednesday: false,
            hiddenApplyWednesday: false
        });
    }

    addThursdayTimeline = () => {

        if (this.state.hourThursday1 !== "" && this.state.hourThursday2 !== "" && this.state.thursdayAction !== "" && this.state.thursdayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourThursday1;
            hourAndMinute2 = this.state.hourThursday2;

            if (this.state.thursdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.thursdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.thursdayAction
                }

                var flagTimelineHours = this.state.timelineHoursThursday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursMonday: flagTimelineHours,
                    hourThursday: null,
                    hourThursday1: "00:00",
                    hourThursday2: "00:00",
                    thursdayAction: null,
                    hiddenAddThursday: true,
                    hiddenTimelineThursday: false,
                    hiddenApplyThursday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.thursdayAction
                }

                flagTimelineHours = this.state.timelineHoursThursday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursThursday: flagTimelineHours,
                    hourThursday: null,
                    hourThursday1: "00:00",
                    hourThursday2: "00:00",
                    thursdayAction: null,
                    hiddenAddThursday: true,
                    hiddenTimelineThursday: false,
                    hiddenApplyThursday: false
                });
            }
        }
    }

    updateThursdayTimeline = () => {

        if (this.state.hourThursday1 !== "" && this.state.hourThursday2 !== "" && this.state.thursdayAction !== "" && this.state.thursdayAction !== "Select Action") {

            let selectedTimelineHoursThursday = this.state.timelineHoursThursday;

            if (selectedTimelineHoursThursday[this.state.thursdayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursThursday.length; index++) {
                    if (selectedTimelineHoursThursday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursThursday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursThursday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursThursday.splice((this.state.thursdayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourThursday1;
            hourAndMinute2 = this.state.hourThursday2;

            if (this.state.thursdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.thursdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.thursdayAction
                }

                var flagTimelineHours = selectedTimelineHoursThursday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursThursday: flagTimelineHours,
                    hourThursday: null,
                    hourThursday1: "00:00",
                    hourThursday2: "00:00",
                    thursdayAction: null,
                    hiddenAddThursday: true,
                    hiddenDeleteThursday: true,
                    hiddenUpdateThursday: true,
                    hiddenCancelThursday: true,
                    hiddenTimelineThursday: false,
                    hiddenApplyThursday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.thursdayAction
                }

                flagTimelineHours = selectedTimelineHoursThursday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursThursday: flagTimelineHours,
                    hourThursday: null,
                    hourThursday1: "00:00",
                    hourThursday2: "00:00",
                    thursdayAction: null,
                    hiddenAddThursday: true,
                    hiddenDeleteThursday: true,
                    hiddenUpdateThursday: true,
                    hiddenCancelThursday: true,
                    hiddenTimelineThursday: false,
                    hiddenApplyThursday: false
                });
            }
        }

    }

    deleteThursdayTimeline = () => {

        let selectedTimelineHoursThursday = this.state.timelineHoursThursday;

        if (selectedTimelineHoursThursday[this.state.thusdayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursThursday.length; index++) {
                if (selectedTimelineHoursThursday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursThursday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursThursday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursThursday.splice((this.state.thusdayIndex), 1);

        }

        this.setState({
            timelineHoursThursday: selectedTimelineHoursThursday,
            hourThursday: null,
            hourThursday1: "00:00",
            hourThursday2: "00:00",
            thursdayAction: null,
            hiddenAddThursday: true,
            hiddenDeleteThursday: true,
            hiddenUpdateThursday: true,
            hiddenCancelThursday: true,
            hiddenTimelineThursday: false,
            hiddenApplyThursday: false
        });
    }

    cancelThursdayTimeline = () => {
        this.setState({
            hourThursday: null,
            hourThursday1: "00:00",
            hourThursday2: "00:00",
            thursdayAction: null,
            hiddenAddThursday: true,
            hiddenDeleteThursday: true,
            hiddenUpdateThursday: true,
            hiddenCancelThursday: true,
            hiddenTimelineThursday: false,
            hiddenApplyThursday: false
        });
    }

    addFridayTimeline = () => {

        if (this.state.hourFriday1 !== "" && this.state.hourFriday2 !== "" && this.state.fridayAction !== "" && this.state.fridayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourFriday1;
            hourAndMinute2 = this.state.hourFriday2;

            if (this.state.fridayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.fridayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.fridayAction
                }

                var flagTimelineHours = this.state.timelineHoursFriday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursFriday: flagTimelineHours,
                    hourFriday: null,
                    hourFriday1: "00:00",
                    hourFriday2: "00:00",
                    fridayAction: null,
                    hiddenAddFriday: true,
                    hiddenTimelineFriday: false,
                    hiddenApplyFriday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.fridayAction
                }

                flagTimelineHours = this.state.timelineHoursFriday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursFriday: flagTimelineHours,
                    hourFriday: null,
                    hourFriday1: "00:00",
                    hourFriday2: "00:00",
                    fridayAction: null,
                    hiddenAddFriday: true,
                    hiddenTimelineFriday: false,
                    hiddenApplyFriday: false
                });
            }
        }
    }

    updateFridayTimeline = () => {
        if (this.state.hourFriday1 !== "" && this.state.hourFriday2 !== "" && this.state.fridayAction !== "" && this.state.fridayAction !== "Select Action") {

            let selectedTimelineHoursFriday = this.state.timelineHoursFriday;

            if (selectedTimelineHoursFriday[this.state.fridayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursFriday.length; index++) {
                    if (selectedTimelineHoursFriday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursFriday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursFriday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursFriday.splice((this.state.fridayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourFriday1;
            hourAndMinute2 = this.state.hourFriday2;

            if (this.state.fridayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.fridayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.fridayAction
                }

                var flagTimelineHours = selectedTimelineHoursFriday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursFriday: flagTimelineHours,
                    hourFriday: null,
                    hourFriday1: "00:00",
                    hourFriday2: "00:00",
                    fridayAction: null,
                    hiddenAddFriday: true,
                    hiddenDeleteFriday: true,
                    hiddenUpdateFriday: true,
                    hiddenCancelFriday: true,
                    hiddenTimelineFriday: false,
                    hiddenApplyFriday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.fridayAction
                }

                flagTimelineHours = selectedTimelineHoursFriday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursFriday: flagTimelineHours,
                    hourFriday: null,
                    hourFriday1: "00:00",
                    hourFriday2: "00:00",
                    fridayAction: null,
                    hiddenAddFriday: true,
                    hiddenDeleteFriday: true,
                    hiddenUpdateFriday: true,
                    hiddenCancelFriday: true,
                    hiddenTimelineFriday: false,
                    hiddenApplyFriday: false
                });
            }
        }
    }

    deleteFridayTimeline = () => {

        let selectedTimelineHoursFriday = this.state.timelineHoursFriday;

        if (selectedTimelineHoursFriday[this.state.fridayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursFriday.length; index++) {
                if (selectedTimelineHoursFriday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursFriday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursFriday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursFriday.splice((this.state.fridayIndex), 1);

        }

        this.setState({
            timelineHoursFriday: selectedTimelineHoursFriday,
            hourFriday: null,
            hourFriday1: "00:00",
            hourFriday2: "00:00",
            fridayAction: null,
            hiddenAddFriday: true,
            hiddenDeleteFriday: true,
            hiddenUpdateFriday: true,
            hiddenCancelFriday: true,
            hiddenTimelineFriday: false,
            hiddenApplyFriday: false
        });

    }

    cancelFridayTimeline = () => {
        this.setState({
            hourFriday: null,
            hourFriday1: "00:00",
            hourFriday2: "00:00",
            fridayAction: null,
            hiddenAddFriday: true,
            hiddenDeleteFriday: true,
            hiddenUpdateFriday: true,
            hiddenCancelFriday: true,
            hiddenTimelineFriday: false,
            hiddenApplyFriday: false
        });
    }

    addSaturdayTimeline = () => {

        if (this.state.hourSaturday1 !== "" && this.state.hourSaturday2 !== "" && this.state.saturdayAction !== "" && this.state.saturdayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourSaturday1;
            hourAndMinute2 = this.state.hourSaturday2;

            if (this.state.saturdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.saturdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.saturdayAction
                }

                var flagTimelineHours = this.state.timelineHoursSaturday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursSaturday: flagTimelineHours,
                    hourSaturday: null,
                    hourSaturday1: "00:00",
                    hourSaturday2: "00:00",
                    saturdayAction: null,
                    hiddenAddSaturday: true,
                    hiddenTimelineSaturday: false,
                    hiddenApplySaturday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.saturdayAction
                }

                flagTimelineHours = this.state.timelineHoursSaturday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursSaturday: flagTimelineHours,
                    hourSaturday: null,
                    hourSaturday1: "00:00",
                    hourSaturday2: "00:00",
                    saturdayAction: null,
                    hiddenAddSaturday: true,
                    hiddenTimelineSaturday: false,
                    hiddenApplySaturday: false
                });
            }
        }
    }

    updateSaturdayTimeline = () => {
        if (this.state.hourSaturday1 !== "" && this.state.hourSaturday2 !== "" && this.state.saturdayAction !== "" && this.state.saturdayAction !== "Select Action") {

            let selectedTimelineHoursSaturday = this.state.timelineHoursSaturday;

            if (selectedTimelineHoursSaturday[this.state.saturdayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursSaturday.length; index++) {
                    if (selectedTimelineHoursSaturday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursSaturday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursSaturday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursSaturday.splice((this.state.saturdayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourSaturday1;
            hourAndMinute2 = this.state.hourSaturday2;

            if (this.state.saturdayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.saturdayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.saturdayAction
                }

                var flagTimelineHours = selectedTimelineHoursSaturday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursSaturday: flagTimelineHours,
                    hourSaturday: null,
                    hourSaturday1: "00:00",
                    hourSaturday2: "00:00",
                    saturdayAction: null,
                    hiddenAddSaturday: true,
                    hiddenDeleteSaturday: true,
                    hiddenUpdateSaturday: true,
                    hiddenCancelSaturday: true,
                    hiddenTimelineSaturday: false,
                    hiddenApplySaturday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.saturdayAction
                }

                flagTimelineHours = selectedTimelineHoursSaturday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursSaturday: flagTimelineHours,
                    hourSaturday: null,
                    hourSaturday1: "00:00",
                    hourSaturday2: "00:00",
                    saturdayAction: null,
                    hiddenAddSaturday: true,
                    hiddenDeleteSaturday: true,
                    hiddenUpdateSaturday: true,
                    hiddenCancelSaturday: true,
                    hiddenTimelineSaturday: false,
                    hiddenApplySaturday: false
                });
            }
        }
    }

    deleteSaturdayTimeline = () => {

        let selectedTimelineHoursSaturday = this.state.timelineHoursSaturday;

        if (selectedTimelineHoursSaturday[this.state.saturdayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursSaturday.length; index++) {
                if (selectedTimelineHoursSaturday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursSaturday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursSaturday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursSaturday.splice((this.state.saturdayIndex), 1);

        }

        this.setState({
            timelineHoursSaturday: selectedTimelineHoursSaturday,
            hourSaturday: null,
            hourSaturday1: "00:00",
            hourSaturday2: "00:00",
            saturdayAction: null,
            hiddenAddSaturday: true,
            hiddenDeleteSaturday: true,
            hiddenUpdateSaturday: true,
            hiddenCancelSaturday: true,
            hiddenTimelineSaturday: false,
            hiddenApplySaturday: false
        });

    }

    cancelSaturdayTimeline = () => {
        this.setState({
            hourSaturday: null,
            hourSaturday1: "00:00",
            hourSaturday2: "00:00",
            saturdayAction: null,
            hiddenAddSaturday: true,
            hiddenDeleteSaturday: true,
            hiddenUpdateSaturday: true,
            hiddenCancelSaturday: true,
            hiddenTimelineSaturday: false,
            hiddenApplySaturday: false
        });
    }

    addSundayTimeline = () => {

        if (this.state.hourSunday1 !== "" && this.state.hourSunday2 !== "" && this.state.sundayAction !== "" && this.state.sundayAction !== "Select Action") {

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourSunday1;
            hourAndMinute2 = this.state.hourSunday2;

            if (this.state.sundayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.sundayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.sundayAction
                }

                var flagTimelineHours = this.state.timelineHoursSunday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursSunday: flagTimelineHours,
                    hourSunday: null,
                    hourSunday1: "00:00",
                    hourSunday2: "00:00",
                    sundayAction: null,
                    hiddenAddSunday: true,
                    hiddenTimelineSunday: false,
                    hiddenApplySunday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.sundayAction
                }

                flagTimelineHours = this.state.timelineHoursSunday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    timelineHoursSunday: flagTimelineHours,
                    hourSunday: null,
                    hourSunday1: "00:00",
                    hourSunday2: "00:00",
                    sundayAction: null,
                    hiddenAddSunday: true,
                    hiddenTimelineSunday: false,
                    hiddenApplySunday: false
                });
            }
        }
    }

    updateSundayTimeline = () => {
        if (this.state.hourSunday1 !== "" && this.state.hourSunday2 !== "" && this.state.sundayAction !== "" && this.state.sundayAction !== "Select Action") {

            let selectedTimelineHoursSunday = this.state.timelineHoursSunday;

            if (selectedTimelineHoursSunday[this.state.sundayIndex].action === "Start-End of Work") {

                var startEndIndexes = [];

                for (let index = 0; index < selectedTimelineHoursSunday.length; index++) {
                    if (selectedTimelineHoursSunday[index].action === "Start-End of Work")
                        startEndIndexes.push(index);
                }

                selectedTimelineHoursSunday.splice((startEndIndexes[0]), 1);
                selectedTimelineHoursSunday.splice((startEndIndexes[1] - 1), 1);

            } else {

                selectedTimelineHoursSunday.splice((this.state.sundayIndex), 1);

            }

            var hourAndMinute1 = "";
            var hourAndMinute2 = "";

            hourAndMinute1 = this.state.hourSunday1;
            hourAndMinute2 = this.state.hourSunday2;

            if (this.state.sundayAction === "Start-End of Work") {

                const startTrip = {
                    flagData: "2000-02-17",
                    hour: "00:00-" + hourAndMinute1,
                    action: this.state.sundayAction
                }

                const endTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute2 + "-23:59",
                    action: this.state.sundayAction
                }

                var flagTimelineHours = selectedTimelineHoursSunday;
                flagTimelineHours.push(startTrip);
                flagTimelineHours.push(endTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursSunday: flagTimelineHours,
                    hourSunday: null,
                    hourSunday1: "00:00",
                    hourSunday2: "00:00",
                    sundayAction: null,
                    hiddenAddSunday: true,
                    hiddenDeleteSunday: true,
                    hiddenUpdateSunday: true,
                    hiddenCancelSunday: true,
                    hiddenTimelineSunday: false,
                    hiddenApplySunday: false
                });
            } else {

                const newTrip = {
                    flagData: "2000-02-17",
                    hour: hourAndMinute1 + "-" + hourAndMinute2,
                    action: this.state.sundayAction
                }

                flagTimelineHours = selectedTimelineHoursSunday;
                flagTimelineHours.push(newTrip);

                function compare(a, b) {
                    var timeFlag1 = a.hour.split("-");
                    var timeFlag2 = b.hour.split("-");
                    var time1 = parseFloat(timeFlag1[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    var time2 = parseFloat(timeFlag2[0].replace(':', '.').replace(/[^\d.-]/g, ''));
                    if (timeFlag1[0].match(/.*pm/)) time1 += 12; if (timeFlag2[0].match(/.*pm/)) time2 += 12;
                    if (time1 < time2) return -1;
                    if (time1 > time2) return 1;
                    return 0;
                }

                flagTimelineHours.sort(compare);

                this.setState({
                    selectedTimelineHoursSunday: flagTimelineHours,
                    hourSunday: null,
                    hourSunday1: "00:00",
                    hourSunday2: "00:00",
                    sundayAction: null,
                    hiddenAddSunday: true,
                    hiddenDeleteSunday: true,
                    hiddenUpdateSunday: true,
                    hiddenCancelSunday: true,
                    hiddenTimelineSunday: false,
                    hiddenApplySunday: false
                });
            }
        }
    }

    deleteSundayTimeline = () => {

        let selectedTimelineHoursSunday = this.state.timelineHoursSunday;

        if (selectedTimelineHoursSunday[this.state.sundayIndex].action === "Start-End of Work") {

            var startEndIndexes = [];

            for (let index = 0; index < selectedTimelineHoursSunday.length; index++) {
                if (selectedTimelineHoursSunday[index].action === "Start-End of Work")
                    startEndIndexes.push(index);
            }

            selectedTimelineHoursSunday.splice((startEndIndexes[0]), 1);
            selectedTimelineHoursSunday.splice((startEndIndexes[1] - 1), 1);

        } else {

            selectedTimelineHoursSunday.splice((this.state.sundayIndex), 1);

        }

        this.setState({
            timelineHoursSunday: selectedTimelineHoursSunday,
            hourSunday: null,
            hourSunday1: "00:00",
            hourSunday2: "00:00",
            sundayAction: null,
            hiddenAddSunday: true,
            hiddenDeleteSunday: true,
            hiddenUpdateSunday: true,
            hiddenCancelSunday: true,
            hiddenTimelineSunday: false,
            hiddenApplySunday: false
        });

    }

    cancelSundayTimeline = () => {
        this.setState({
            hourSunday: null,
            hourSunday1: "00:00",
            hourSunday2: "00:00",
            sundayAction: null,
            hiddenAddSunday: true,
            hiddenDeleteSunday: true,
            hiddenUpdateSunday: true,
            hiddenCancelSunday: true,
            hiddenTimelineSunday: false,
            hiddenApplySunday: false
        });
    }

    // #endregion

    // #region Apply to All Timelines of Week Days
    // Fixed ise bunu en sonda first day'den bütün planlara uygulamak için kullan.

    // applyToAllMonday = () => {

    //     var flagData = this.state.timelineHoursMonday.slice();

    //     if (this.state.workingDays.indexOf('Tuesday') !== -1) {
    //         this.setState({
    //             timelineHoursTuesday: flagData.slice(),
    //             //hiddenTimelineTuesday: false,
    //             //hiddenApplyTuesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Wednesday') !== -1) {
    //         console.log("Ok");
    //         this.setState({
    //             timelineHoursWednesday: flagData.slice(),
    //             //hiddenTimelineWednesday: false,
    //             //hiddenApplyWednesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Thursday') !== -1) {
    //         this.setState({
    //             timelineHoursThursday: flagData.slice(),
    //             //hiddenTimelineThursday: false,
    //             //hiddenApplyThursday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Friday') !== -1) {
    //         this.setState({
    //             timelineHoursFriday: flagData.slice(),
    //             //hiddenTimelineFriday: false,
    //             //hiddenApplyFriday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Saturday') !== -1) {
    //         this.setState({
    //             timelineHoursSaturday: flagData.slice(),
    //             //hiddenTimelineSaturday: false,
    //             //hiddenApplySaturday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Sunday') !== -1) {
    //         this.setState({
    //             timelineHoursSunday: flagData.slice(),
    //             //hiddenTimelineSunday: false,
    //             //hiddenApplySunday: false
    //         })
    //     }
    // }

    // applyToAllTuesday = () => {

    //     var flagData = this.state.timelineHoursTuesday.slice();

    //     if (this.state.workingDays.indexOf('Monday') !== -1) {
    //         this.setState({
    //             timelineHoursMonday: flagData.slice(),
    //             //hiddenTimelineMonday: false,
    //             //hiddenApplyMonday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Wednesday') !== -1) {
    //         this.setState({
    //             timelineHoursWednesday: flagData.slice(),
    //             //hiddenTimelineWednesday: false,
    //             //hiddenApplyWednesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Thursday') !== -1) {
    //         this.setState({
    //             timelineHoursThursday: flagData.slice(),
    //             //hiddenTimelineThursday: false,
    //             //hiddenApplyThursday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Friday') !== -1) {
    //         this.setState({
    //             timelineHoursFriday: flagData.slice(),
    //             //hiddenTimelineFriday: false,
    //             //hiddenApplyFriday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Saturday') !== -1) {
    //         this.setState({
    //             timelineHoursSaturday: flagData.slice(),
    //             //hiddenTimelineSaturday: false,
    //             //hiddenApplySaturday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Sunday') !== -1) {
    //         this.setState({
    //             timelineHoursSunday: flagData.slice(),
    //             //hiddenTimelineSunday: false,
    //             //hiddenApplySunday: false
    //         })
    //     }
    // }

    // applyToAllWednesday = () => {

    //     var flagData = this.state.timelineHoursWednesday.slice();

    //     if (this.state.workingDays.indexOf('Monday') !== -1) {
    //         this.setState({
    //             timelineHoursMonday: flagData.slice(),
    //             //hiddenTimelineMonday: false,
    //             //hiddenApplyMonday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Tuesday') !== -1) {
    //         this.setState({
    //             timelineHoursTuesday: flagData.slice(),
    //             //hiddenTimelineTuesday: false,
    //             //hiddenApplyTuesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Thursday') !== -1) {
    //         this.setState({
    //             timelineHoursThursday: flagData.slice(),
    //             //hiddenTimelineThursday: false,
    //             //hiddenApplyThursday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Friday') !== -1) {
    //         this.setState({
    //             timelineHoursFriday: flagData.slice(),
    //             //hiddenTimelineFriday: false,
    //             //hiddenApplyFriday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Saturday') !== -1) {
    //         this.setState({
    //             timelineHoursSaturday: flagData.slice(),
    //             //hiddenTimelineSaturday: false,
    //             //hiddenApplySaturday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Sunday') !== -1) {
    //         this.setState({
    //             timelineHoursSunday: flagData.slice(),
    //             //hiddenTimelineSunday: false,
    //             //hiddenApplySunday: false
    //         })
    //     }
    // }

    // applyToAllThursday = () => {

    //     var flagData = this.state.timelineHoursThursday.slice();

    //     if (this.state.workingDays.indexOf('Monday') !== -1) {
    //         this.setState({
    //             timelineHoursMonday: flagData.slice(),
    //             //hiddenTimelineMonday: false,
    //             //hiddenApplyMonday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Tuesday') !== -1) {
    //         this.setState({
    //             timelineHoursTuesday: flagData.slice(),
    //             //hiddenTimelineTuesday: false,
    //             //hiddenApplyTuesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Wednesday') !== -1) {
    //         this.setState({
    //             timelineHoursWednesday: flagData.slice(),
    //             //hiddenTimelineWednesday: false,
    //             //hiddenApplyWednesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Friday') !== -1) {
    //         this.setState({
    //             timelineHoursFriday: flagData.slice(),
    //             //hiddenTimelineFriday: false,
    //             //hiddenApplyFriday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Saturday') !== -1) {
    //         this.setState({
    //             timelineHoursSaturday: flagData.slice(),
    //             //hiddenTimelineSaturday: false,
    //             //hiddenApplySaturday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Sunday') !== -1) {
    //         this.setState({
    //             timelineHoursSunday: flagData.slice(),
    //             //hiddenTimelineSunday: false,
    //             //hiddenApplySunday: false
    //         })
    //     }
    // }

    // applyToAllFriday = () => {

    //     var flagData = this.state.timelineHoursFriday.slice();

    //     if (this.state.workingDays.indexOf('Monday') !== -1) {
    //         this.setState({
    //             timelineHoursMonday: flagData.slice(),
    //             //hiddenTimelineMonday: false,
    //             //hiddenApplyMonday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Tuesday') !== -1) {
    //         this.setState({
    //             timelineHoursTuesday: flagData.slice(),
    //             //hiddenTimelineTuesday: false,
    //             //hiddenApplyTuesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Wednesday') !== -1) {
    //         this.setState({
    //             timelineHoursWednesday: flagData.slice(),
    //             //hiddenTimelineWednesday: false,
    //             //hiddenApplyWednesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Thursday') !== -1) {
    //         this.setState({
    //             timelineHoursThursday: flagData.slice(),
    //             //hiddenTimelineThursday: false,
    //             //hiddenApplyThursday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Saturday') !== -1) {
    //         this.setState({
    //             timelineHoursSaturday: flagData.slice(),
    //             //hiddenTimelineSaturday: false,
    //             //hiddenApplySaturday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Sunday') !== -1) {
    //         this.setState({
    //             timelineHoursSunday: flagData.slice(),
    //             //hiddenTimelineSunday: false,
    //             //hiddenApplySunday: false
    //         })
    //     }
    // }

    // applyToAllSaturday = () => {

    //     var flagData = this.state.timelineHoursSaturday.slice();

    //     if (this.state.workingDays.indexOf('Monday') !== -1) {
    //         this.setState({
    //             timelineHoursMonday: flagData.slice(),
    //             //hiddenTimelineMonday: false,
    //             //hiddenApplyMonday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Tuesday') !== -1) {
    //         this.setState({
    //             timelineHoursTuesday: flagData.slice(),
    //             //hiddenTimelineTuesday: false,
    //             //hiddenApplyTuesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Wednesday') !== -1) {
    //         this.setState({
    //             timelineHoursWednesday: flagData.slice(),
    //             //hiddenTimelineWednesday: false,
    //             //hiddenApplyWednesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Thursday') !== -1) {
    //         this.setState({
    //             timelineHoursThursday: flagData.slice(),
    //             //hiddenTimelineThursday: false,
    //             //hiddenApplyThursday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Friday') !== -1) {
    //         this.setState({
    //             timelineHoursFriday: flagData.slice(),
    //             //hiddenTimelineFriday: false,
    //             //hiddenApplyFriday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Sunday') !== -1) {
    //         this.setState({
    //             timelineHoursSunday: flagData.slice(),
    //             //hiddenTimelineSunday: false,
    //             //hiddenApplySunday: false
    //         })
    //     }
    // }

    // applyToAllSunday = () => {

    //     var flagData = this.state.timelineHoursSunday.slice();

    //     if (this.state.workingDays.indexOf('Monday') !== -1) {
    //         this.setState({
    //             timelineHoursMonday: flagData.slice(),
    //             //hiddenTimelineMonday: false,
    //             //hiddenApplyMonday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Tuesday') !== -1) {
    //         this.setState({
    //             timelineHoursTuesday: flagData.slice(),
    //             //hiddenTimelineTuesday: false,
    //             //hiddenApplyTuesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Wednesday') !== -1) {
    //         this.setState({
    //             timelineHoursWednesday: flagData.slice(),
    //             //hiddenTimelineWednesday: false,
    //             //hiddenApplyWednesday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Thursday') !== -1) {
    //         this.setState({
    //             timelineHoursThursday: flagData.slice(),
    //             //hiddenTimelineThursday: false,
    //             //hiddenApplyThursday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Friday') !== -1) {
    //         this.setState({
    //             timelineHoursFriday: flagData.slice(),
    //             //hiddenTimelineFriday: false,
    //             //hiddenApplyFriday: false
    //         })
    //     }

    //     if (this.state.workingDays.indexOf('Saturday') !== -1) {
    //         this.setState({
    //             timelineHoursSaturday: flagData.slice(),
    //             //hiddenTimelineSaturday: false,
    //             //hiddenApplySaturday: false
    //         })
    //     }
    // }

    // #endregion

    // #region Clear Timelines of Week Days

    clearMonday = () => {
        this.setState({
            timelineHoursMonday: [],
            hiddenDeleteMonday: true,
            hiddenUpdateMonday: true,
            hiddenCancelMonday: true,
        });
    }

    clearTuesday = () => {
        this.setState({
            timelineHoursTuesday: [],
            hiddenDeleteTuesday: true,
            hiddenUpdateTuesday: true,
            hiddenCancelTuesday: true,
        });
    }

    clearWednesday = () => {
        this.setState({
            timelineHoursWednesday: [],
            hiddenDeleteWednesday: true,
            hiddenUpdateWednesday: true,
            hiddenCancelWednesday: true,
        });
    }

    clearThursday = () => {
        this.setState({
            timelineHoursThursday: [],
            hiddenDeleteThursday: true,
            hiddenUpdateThursday: true,
            hiddenCancelThursday: true,
        });
    }

    clearFriday = () => {
        this.setState({
            timelineHoursFriday: [],
            hiddenDeleteFriday: true,
            hiddenUpdateFriday: true,
            hiddenCancelFriday: true,
        });
    }

    clearSaturday = () => {
        this.setState({
            timelineHoursSaturday: [],
            hiddenDeleteSaturday: true,
            hiddenUpdateSaturday: true,
            hiddenCancelSaturday: true,
        });
    }

    clearSunday = () => {
        this.setState({
            timelineHoursSunday: [],
            hiddenDeleteSunday: true,
            hiddenUpdateSunday: true,
            hiddenCancelSunday: true,
        });
    }

    // #endregion

    showOrHideInfoModal = () => {
        this.setState({
            infoModal: !this.state.infoModal
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    handleChangeFirstWorkingDay = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });

        if (this.state.workingDays.includes("Monday")) {
            if (this.state.shiftPlan === true && e.target.value === "Monday") {
                this.setState({
                    timelineHoursMonday: [],
                    hiddenActionMonday: false,
                    hiddenHourMonday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Monday")) {
                this.setState({
                    hiddenActionMonday: true,
                    hiddenTimelineMonday: true,
                    hiddenHourMonday: 'hidden',
                    hiddenUpdateMonday: true,
                    hiddenCancelMonday: true,
                    hiddenDeleteMonday: true,
                    hiddenApplyMonday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursMonday: [],
                    hiddenActionMonday: false,
                    hiddenHourMonday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Monday")) {
            this.setState({
                hiddenActionMonday: true,
                hiddenTimelineMonday: true,
                hiddenHourMonday: 'hidden',
                hiddenUpdateMonday: true,
                hiddenCancelMonday: true,
                hiddenDeleteMonday: true,
                hiddenApplyMonday: true
            });
        }

        if (this.state.workingDays.includes("Tuesday")) {
            if (this.state.shiftPlan === true && e.target.value === "Tuesday") {
                this.setState({
                    timelineHoursTuesday: [],
                    hiddenActionTuesday: false,
                    hiddenHourTuesday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Tuesday")) {
                this.setState({
                    hiddenActionTuesday: true,
                    hiddenHourTuesday: 'hidden',
                    hiddenTimelineTuesday: true,
                    hiddenApplyTuesday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursTuesday: [],
                    hiddenActionTuesday: false,
                    hiddenHourTuesday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Tuesday")) {
            this.setState({
                hiddenActionTuesday: true,
                hiddenHourTuesday: 'hidden',
                hiddenTimelineTuesday: true,
                hiddenApplyTuesday: true
            });
        }

        if (this.state.workingDays.includes("Wednesday")) {
            if (this.state.shiftPlan === true && e.target.value === "Wednesday") {
                this.setState({
                    timelineHoursWednesday: [],
                    hiddenActionWednesday: false,
                    hiddenHourWednesday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Wednesday")) {
                this.setState({
                    hiddenActionWednesday: true,
                    hiddenHourWednesday: 'hidden',
                    hiddenTimelineWednesday: true,
                    hiddenApplyWednesday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursWednesday: [],
                    hiddenActionWednesday: false,
                    hiddenHourWednesday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Wednesday")) {
            this.setState({
                hiddenActionWednesday: true,
                hiddenHourWednesday: 'hidden',
                hiddenTimelineWednesday: true,
                hiddenApplyWednesday: true
            });
        }

        if (this.state.workingDays.includes("Thursday")) {
            if (this.state.shiftPlan === true && e.target.value === "Thursday") {
                this.setState({
                    timelineHoursThursday: [],
                    hiddenActionThursday: false,
                    hiddenHourThursday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Thursday")) {
                this.setState({
                    hiddenActionThursday: true,
                    hiddenHourThursday: 'hidden',
                    hiddenTimelineThursday: true,
                    hiddenApplyThursday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursThursday: [],
                    hiddenActionThursday: false,
                    hiddenHourThursday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Thursday")) {
            this.setState({
                hiddenActionThursday: true,
                hiddenHourThursday: 'hidden',
                hiddenTimelineThursday: true,
                hiddenApplyThursday: true
            });
        }

        if (this.state.workingDays.includes("Friday")) {
            if (this.state.shiftPlan === true && e.target.value === "Friday") {
                this.setState({
                    timelineHoursFriday: [],
                    hiddenActionFriday: false,
                    hiddenHourFriday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Friday")) {
                this.setState({
                    hiddenActionFriday: true,
                    hiddenHourFriday: 'hidden',
                    hiddenTimelineFriday: true,
                    hiddenApplyFriday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursFriday: [],
                    hiddenActionFriday: false,
                    hiddenHourFriday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Friday")) {
            this.setState({
                hiddenActionFriday: true,
                hiddenHourFriday: 'hidden',
                hiddenTimelineFriday: true,
                hiddenApplyFriday: true
            });
        }

        if (this.state.workingDays.includes("Saturday")) {
            if (this.state.shiftPlan === true && e.target.value === "Saturday") {
                this.setState({
                    timelineHoursSaturday: [],
                    hiddenActionSaturday: false,
                    hiddenHourSaturday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Saturday")) {
                this.setState({
                    hiddenActionSaturday: true,
                    hiddenHourSaturday: 'hidden',
                    hiddenTimelineSaturday: true,
                    hiddenApplySaturday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursSaturday: [],
                    hiddenActionSaturday: false,
                    hiddenHourSaturday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Saturday")) {
            this.setState({
                hiddenActionSaturday: true,
                hiddenHourSaturday: 'hidden',
                hiddenTimelineSaturday: true,
                hiddenApplySaturday: true
            });
        }

        if (this.state.workingDays.includes("Sunday")) {
            if (this.state.shiftPlan === true && e.target.value === "Sunday") {
                this.setState({
                    timelineHoursSunday: [],
                    hiddenActionSunday: false,
                    hiddenHourSunday: 'visible'
                });
            }
            if (this.state.shiftPlan === true && !(e.target.value === "Sunday")) {
                this.setState({
                    hiddenActionSunday: true,
                    hiddenHourSunday: 'hidden',
                    hiddenTimelineSunday: true,
                    hiddenApplySunday: true
                });
            }
            if (this.state.shiftPlan === false) {
                this.setState({
                    //timelineHoursSunday: [],
                    hiddenActionSunday: false,
                    hiddenHourSunday: 'visible'
                });
            }
        }

        if (!this.state.workingDays.includes("Sunday")) {
            this.setState({
                hiddenActionSunday: true,
                hiddenHourSunday: 'hidden',
                hiddenTimelineSunday: true,
                hiddenApplySunday: true
            });
        }
    }

    shiftPlanChange = (e) => {

        this.setState({
            shiftPlan: e.value,
            disableCheckBoxes: !this.state.disableCheckBoxes
        });

        if (e.value === false) {
            this.setState({
                firstWorkingDay: "Select First Working Day"
            });
        }

        if (e.value === true) {
            if (this.state.workingDays.includes("Monday")) {
                if (this.state.firstWorkingDay === "Monday") {
                    this.setState({
                        timelineHoursMonday: [],
                        hiddenActionMonday: false,
                        hiddenHourMonday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionMonday: true,
                        hiddenTimelineMonday: true,
                        hiddenHourMonday: 'hidden',
                        hiddenUpdateMonday: true,
                        hiddenCancelMonday: true,
                        hiddenDeleteMonday: true,
                        hiddenApplyMonday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Monday")) {
                this.setState({
                    hiddenActionMonday: true,
                    hiddenTimelineMonday: true,
                    hiddenHourMonday: 'hidden',
                    hiddenUpdateMonday: true,
                    hiddenCancelMonday: true,
                    hiddenDeleteMonday: true,
                    hiddenApplyMonday: true
                });
            }

            if (this.state.workingDays.includes("Tuesday")) {
                if (this.state.firstWorkingDay === "Tuesday") {
                    this.setState({
                        timelineHoursTuesday: [],
                        hiddenActionTuesday: false,
                        hiddenHourTuesday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionTuesday: true,
                        hiddenHourTuesday: 'hidden',
                        hiddenTimelineTuesday: true,
                        hiddenApplyTuesday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Tuesday")) {
                this.setState({
                    hiddenActionTuesday: true,
                    hiddenHourTuesday: 'hidden',
                    hiddenTimelineTuesday: true,
                    hiddenApplyTuesday: true
                });
            }

            if (this.state.workingDays.includes("Wednesday")) {
                if (this.state.firstWorkingDay === "Wednesday") {
                    this.setState({
                        timelineHoursWednesday: [],
                        hiddenActionWednesday: false,
                        hiddenHourWednesday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionWednesday: true,
                        hiddenHourWednesday: 'hidden',
                        hiddenTimelineWednesday: true,
                        hiddenApplyWednesday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Wednesday")) {
                this.setState({
                    hiddenActionWednesday: true,
                    hiddenHourWednesday: 'hidden',
                    hiddenTimelineWednesday: true,
                    hiddenApplyWednesday: true
                });
            }

            if (this.state.workingDays.includes("Thursday")) {
                if (this.state.firstWorkingDay === "Thursday") {
                    this.setState({
                        timelineHoursThursday: [],
                        hiddenActionThursday: false,
                        hiddenHourThursday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionThursday: true,
                        hiddenHourThursday: 'hidden',
                        hiddenTimelineThursday: true,
                        hiddenApplyThursday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Thursday")) {
                this.setState({
                    hiddenActionThursday: true,
                    hiddenHourThursday: 'hidden',
                    hiddenTimelineThursday: true,
                    hiddenApplyThursday: true
                });
            }

            if (this.state.workingDays.includes("Friday")) {
                if (this.state.firstWorkingDay === "Friday") {
                    this.setState({
                        timelineHoursFriday: [],
                        hiddenActionFriday: false,
                        hiddenHourFriday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionFriday: true,
                        hiddenHourFriday: 'hidden',
                        hiddenTimelineFriday: true,
                        hiddenApplyFriday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Friday")) {
                this.setState({
                    hiddenActionFriday: true,
                    hiddenHourFriday: 'hidden',
                    hiddenTimelineFriday: true,
                    hiddenApplyFriday: true
                });
            }

            if (this.state.workingDays.includes("Saturday")) {
                if (this.state.firstWorkingDay === "Saturday") {
                    this.setState({
                        timelineHoursSaturday: [],
                        hiddenActionSaturday: false,
                        hiddenHourSaturday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionSaturday: true,
                        hiddenHourSaturday: 'hidden',
                        hiddenTimelineSaturday: true,
                        hiddenApplySaturday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Saturday")) {
                this.setState({
                    hiddenActionSaturday: true,
                    hiddenHourSaturday: 'hidden',
                    hiddenTimelineSaturday: true,
                    hiddenApplySaturday: true
                });
            }

            if (this.state.workingDays.includes("Sunday")) {
                if (this.state.firstWorkingDay === "Sunday") {
                    this.setState({
                        timelineHoursSunday: [],
                        hiddenActionSunday: false,
                        hiddenHourSunday: 'visible'
                    });
                } else {
                    this.setState({
                        hiddenActionSunday: true,
                        hiddenHourSunday: 'hidden',
                        hiddenTimelineSunday: true,
                        hiddenApplySunday: true
                    });
                }
            }

            if (!this.state.workingDays.includes("Sunday")) {
                this.setState({
                    hiddenActionSunday: true,
                    hiddenHourSunday: 'hidden',
                    hiddenTimelineSunday: true,
                    hiddenApplySunday: true
                });
            }
        }

        if (e.value === false) {
            if (this.state.workingDays.includes("Monday")) {
                this.setState({
                    timelineHoursMonday: [],
                    hiddenActionMonday: false,
                    hiddenHourMonday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Monday")) {
                this.setState({
                    hiddenActionMonday: true,
                    hiddenTimelineMonday: true,
                    hiddenHourMonday: 'hidden',
                    hiddenUpdateMonday: true,
                    hiddenCancelMonday: true,
                    hiddenDeleteMonday: true,
                    hiddenApplyMonday: true
                });
            }

            if (this.state.workingDays.includes("Tuesday")) {
                this.setState({
                    timelineHoursTuesday: [],
                    hiddenActionTuesday: false,
                    hiddenHourTuesday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Tuesday")) {
                this.setState({
                    hiddenActionTuesday: true,
                    hiddenHourTuesday: 'hidden',
                    hiddenTimelineTuesday: true,
                    hiddenApplyTuesday: true
                });
            }

            if (this.state.workingDays.includes("Wednesday")) {
                this.setState({
                    timelineHoursWednesday: [],
                    hiddenActionWednesday: false,
                    hiddenHourWednesday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Wednesday")) {
                this.setState({
                    hiddenActionWednesday: true,
                    hiddenHourWednesday: 'hidden',
                    hiddenTimelineWednesday: true,
                    hiddenApplyWednesday: true
                });
            }

            if (this.state.workingDays.includes("Thursday")) {
                this.setState({
                    timelineHoursThursday: [],
                    hiddenActionThursday: false,
                    hiddenHourThursday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Thursday")) {
                this.setState({
                    hiddenActionThursday: true,
                    hiddenHourThursday: 'hidden',
                    hiddenTimelineThursday: true,
                    hiddenApplyThursday: true
                });
            }

            if (this.state.workingDays.includes("Friday")) {
                this.setState({
                    timelineHoursFriday: [],
                    hiddenActionFriday: false,
                    hiddenHourFriday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Friday")) {
                this.setState({
                    hiddenActionFriday: true,
                    hiddenHourFriday: 'hidden',
                    hiddenTimelineFriday: true,
                    hiddenApplyFriday: true
                });
            }

            if (this.state.workingDays.includes("Saturday")) {
                this.setState({
                    timelineHoursSaturday: [],
                    hiddenActionSaturday: false,
                    hiddenHourSaturday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Saturday")) {
                this.setState({
                    hiddenActionSaturday: true,
                    hiddenHourSaturday: 'hidden',
                    hiddenTimelineSaturday: true,
                    hiddenApplySaturday: true
                });
            }

            if (this.state.workingDays.includes("Sunday")) {
                this.setState({
                    timelineHoursSunday: [],
                    hiddenActionSunday: false,
                    hiddenHourSunday: 'visible'
                });
            }

            if (!this.state.workingDays.includes("Sunday")) {
                this.setState({
                    hiddenActionSunday: true,
                    hiddenHourSunday: 'hidden',
                    hiddenTimelineSunday: true,
                    hiddenApplySunday: true
                });
            }
        }
    }

    hourChangeMonday = (time, timeString) => {
        this.setState({
            hourMonday1: timeString[0],
            hourMonday2: timeString[1]
        });
    }

    hourChangeTuesday = (time, timeString) => {
        this.setState({
            hourTuesday1: timeString[0],
            hourTuesday2: timeString[1]
        });
    }

    hourChangeWednesday = (time, timeString) => {
        this.setState({
            hourWednesday1: timeString[0],
            hourWednesday2: timeString[1]
        });
    }

    hourChangeThursday = (time, timeString) => {
        this.setState({
            hourThursday1: timeString[0],
            hourThursday2: timeString[1]
        });
    }

    hourChangeFriday = (time, timeString) => {
        this.setState({
            hourFriday1: timeString[0],
            hourFriday2: timeString[1]
        });
    }

    hourChangeSaturday = (time, timeString) => {
        this.setState({
            hourSaturday1: timeString[0],
            hourSaturday2: timeString[1]
        });
    }

    hourChangeSunday = (time, timeString) => {
        this.setState({
            hourSunday1: timeString[0],
            hourSunday2: timeString[1]
        });
    }

    actionTemplate(rowData, column) {
        return <React.Fragment><CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.editShift.bind(this)} /></React.Fragment>;
    }

    workingDaysTemplate(rowData, column) {
        let flagRowData = rowData.workingDays.split(",").join(", ");
        return flagRowData;
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            editId: row.id
        });
    }

    deleteShiftOK = async () => {
        const deletedShift = {
            id: this.state.editId
        }

        await handleRequest("POST", "/Shift/delete", deletedShift);

        this.setState({
            deleteModal: false
        });
        this.restartShiftTable();
    }

    editShift = async (row) => {

        this.refreshFirstCreate();

        if (this.state.createNewButton === "Create New" || this.state.createNewButton === "Stop Updating" || this.state.createNewButton === "Stop Adding New") {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var dd1 = String(today.getDate() + 1).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            var today1 = dd1 + '/' + mm + '/' + yyyy;

            var flagStartDate = today;

            if (row.startDate !== "") {
                flagStartDate = row.startDate;
            }

            var flagEndDate = today1;

            if (row.endDate !== "") {
                flagEndDate = row.endDate;
            }

            var shiftPlanValue = false;

            if (row.shiftPlan === 1)
                shiftPlanValue = true;
            else
                shiftPlanValue = false;

            var flagWorkingDays = row.workingDays.split(",");

            //TODO  TİMELİNE KISIMLARINI EKLE.

            if (shiftPlanValue) {
                if (row.firstWorkingDayOfWeek === "Monday") {

                    let flagTimeHoursMonday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursMonday") {
                            flagTimeHoursMonday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionMonday: false,
                        hiddenHourMonday: 'visible',
                        hiddenTimelineMonday: false,
                        timelineHoursMonday: flagTimeHoursMonday
                    });

                } else if (row.firstWorkingDayOfWeek === "Tuesday") {

                    let flagTimeHoursTuesday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursTuesday") {
                            flagTimeHoursTuesday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionTuesday: false,
                        hiddenHourTuesday: 'visible',
                        hiddenTimelineTuesday: false,
                        timelineHoursTuesday: flagTimeHoursTuesday
                    });

                } else if (row.firstWorkingDayOfWeek === "Wednesday") {

                    let flagTimeHoursWednesday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursWednesday") {
                            flagTimeHoursWednesday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionWednesday: false,
                        hiddenHourWednesday: 'visible',
                        hiddenTimelineWednesday: false,
                        timelineHoursWednesday: flagTimeHoursWednesday
                    });

                } else if (row.firstWorkingDayOfWeek === "Thursday") {

                    let flagTimeHoursThursday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursThursday") {
                            flagTimeHoursThursday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionThursday: false,
                        hiddenHourThursday: 'visible',
                        hiddenTimelineThursday: false,
                        timelineHoursThursday: flagTimeHoursThursday
                    });

                } else if (row.firstWorkingDayOfWeek === "Friday") {

                    let flagTimeHoursFriday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursFriday") {
                            flagTimeHoursFriday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionFriday: false,
                        hiddenHourFriday: 'visible',
                        hiddenTimelineFriday: false,
                        timelineHoursFriday: flagTimeHoursFriday
                    });

                } else if (row.firstWorkingDayOfWeek === "Saturday") {

                    let flagTimeHoursSaturday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursSaturday") {
                            flagTimeHoursSaturday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionSaturday: false,
                        hiddenHourSaturday: 'visible',
                        hiddenTimelineSaturday: false,
                        timelineHoursSaturday: flagTimeHoursSaturday
                    });

                } else if (row.firstWorkingDayOfWeek === "Sunday") {

                    let flagTimeHoursSunday = [];

                    for (let index = 0; index < row.shiftDaysList.length; index++) {
                        if (row.shiftDaysList[index].day === "timelineHoursSunday") {
                            flagTimeHoursSunday.push({
                                action: row.shiftDaysList[index].actionCode.name,
                                hour: row.shiftDaysList[index].hour,
                                flagData: row.shiftDaysList[index].flagData,
                            });
                        }
                    }

                    this.setState({
                        hiddenActionSunday: false,
                        hiddenHourSunday: 'visible',
                        hiddenTimelineSunday: false,
                        timelineHoursSunday: flagTimeHoursSunday
                    });

                }
            }
            else {

                let flagTimeHoursMonday1 = [];
                let flagTimeHoursTuesday1 = [];
                let flagTimeHoursWednesday1 = [];
                let flagTimeHoursThursday1 = [];
                let flagTimeHoursFriday1 = [];
                let flagTimeHoursSaturday1 = [];
                let flagTimeHoursSunday1 = [];

                if (flagWorkingDays.includes("Monday")) {
                    this.setState({
                        hiddenActionMonday: false,
                        hiddenHourMonday: 'visible',
                        hiddenTimelineMonday: false,
                    });
                }

                if (flagWorkingDays.includes("Tuesday")) {
                    this.setState({
                        hiddenActionTuesday: false,
                        hiddenHourTuesday: 'visible',
                        hiddenTimelineTuesday: false,
                    });
                }

                if (flagWorkingDays.includes("Wednesday")) {
                    this.setState({
                        hiddenActionWednesday: false,
                        hiddenHourWednesday: 'visible',
                        hiddenTimelineWednesday: false,
                    });
                }

                if (flagWorkingDays.includes("Thursday")) {
                    this.setState({
                        hiddenActionThursday: false,
                        hiddenHourThursday: 'visible',
                        hiddenTimelineThursday: false,
                    });
                }

                if (flagWorkingDays.includes("Friday")) {
                    this.setState({
                        hiddenActionFriday: false,
                        hiddenHourFriday: 'visible',
                        hiddenTimelineFriday: false,
                    });
                }

                if (flagWorkingDays.includes("Saturday")) {
                    this.setState({
                        hiddenActionSaturday: false,
                        hiddenHourSaturday: 'visible',
                        hiddenTimelineSaturday: false,
                    });
                }

                if (flagWorkingDays.includes("Sunday")) {
                    this.setState({
                        hiddenActionSunday: false,
                        hiddenHourSunday: 'visible',
                        hiddenTimelineSunday: false,
                    });
                }

                for (let index = 0; index < row.shiftDaysList.length; index++) {

                    if (row.shiftDaysList[index].day === "timelineHoursMonday") {
                        flagTimeHoursMonday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                    if (row.shiftDaysList[index].day === "timelineHoursTuesday") {
                        flagTimeHoursTuesday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                    if (row.shiftDaysList[index].day === "timelineHoursWednesday") {
                        flagTimeHoursWednesday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                    if (row.shiftDaysList[index].day === "timelineHoursThursday") {
                        flagTimeHoursThursday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                    if (row.shiftDaysList[index].day === "timelineHoursFriday") {
                        flagTimeHoursFriday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                    if (row.shiftDaysList[index].day === "timelineHoursSaturday") {
                        flagTimeHoursSaturday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                    if (row.shiftDaysList[index].day === "timelineHoursSunday") {
                        flagTimeHoursSunday1.push({
                            action: row.shiftDaysList[index].actionCode.name,
                            hour: row.shiftDaysList[index].hour,
                            flagData: row.shiftDaysList[index].flagData,
                        });
                    }

                }

                this.setState({
                    timelineHoursMonday: flagTimeHoursMonday1,
                    timelineHoursTuesday: flagTimeHoursTuesday1,
                    timelineHoursWednesday: flagTimeHoursWednesday1,
                    timelineHoursThursday: flagTimeHoursThursday1,
                    timelineHoursFriday: flagTimeHoursFriday1,
                    timelineHoursSaturday: flagTimeHoursSaturday1,
                    timelineHoursSunday: flagTimeHoursSunday1
                });

            }

            let response = row.workLocations;

            if (response.length === 0) {
                this.setState({
                    selectedNodeKeys: null
                });
            } else if (response.length !== 0) {

                const obj = {};

                for (let key of response) {
                    key = "L." + key;
                    obj[key] = { checked: true };
                }

                this.setState({
                    selectedNodeKeys: obj
                });
            }

            this.setState({
                hidden: false,
                shiftId: row.id,
                shiftDescription: row.shiftDescription,
                startDate: flagStartDate,
                endDate: flagEndDate,
                shiftPlan: shiftPlanValue,
                disableCheckBoxes: shiftPlanValue,
                firstWorkingDay: row.firstWorkingDayOfWeek,
                workingDays: flagWorkingDays,
                createNewButton: "Stop Updating",
                createNewButtonVariant: "danger",
                hiddenSaveButton: true,
                hiddenUpdateButton: false,
            });
        }
        else {
            this.setState({
                hidden: true,
                equipmentLogId: 0,
                createNewButton: "Create New",
                createNewButtonVariant: "info",
                hiddenSaveButton: false,
                hiddenUpdateButton: true,
            });
        }
    }

    restartShiftTable = async () => {

        let response = await handleRequest("GET", "/Shift/getAll");

        if (response.data.length === 0) {
            this.setState({
                shifts: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                shifts: response.data,
            });
        }

    }

    onChangeDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date: dateString,
                startDate: dateString
            });
        } else {
            this.setState({
                date: today,
                startDate: today
            });
        }
    }

    onChangeDate1 = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date1: dateString,
                endDate: dateString
            });
        } else {
            this.setState({
                date1: today,
                endDate: today
            });
        }
    }

    fillCodes = async () => {
        const response = await handleRequest("GET", "/Code/type/shiftActions");
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                actions: response.data
            });
        }
    }

    addActionItem = async () => {
        const { newAction } = this.state;

        if (newAction.length > 0) {
            const newItem = {
                name: newAction,
                type: "shiftActions"
            }

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newAction: '',
            });

            this.fillCodes();
        }
    }

    onNewActionChange = event => {
        this.setState({
            newAction: event.target.value,
        });
    }

    render() {

        const stateConst = this.state;

        //#region Timelines of Week

        let timelineMonday;
        let timelineTuesday;
        let timelineWednesday;
        let timelineThursday;
        let timelineFriday;
        let timelineSaturday;
        let timelineSunday;

        if (stateConst.timelineHoursMonday.length !== 0) {
            timelineMonday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={200}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursMonday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursMonday[index].hour + " / " + stateConst.timelineHoursMonday[index].action;

                        } else if (stateConst.timelineHoursMonday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursMonday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursMonday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursMonday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                mondayIndex: index,
                                mondayAction: stateConst.timelineHoursMonday[index].action,
                                hourMonday1: hourString[0],
                                hourMonday2: hourString[1],
                                hiddenAddMonday: true,
                                hiddenUpdateMonday: false,
                                hiddenDeleteMonday: false,
                                hiddenCancelMonday: false
                            });
                        } else if (stateConst.timelineHoursMonday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursMonday.length; index1++) {
                                if (stateConst.timelineHoursMonday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursMonday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursMonday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                mondayIndex: index,
                                mondayAction: "Start-End of Work",
                                hourMonday1: hourString1,
                                hourMonday2: hourString2,
                                hiddenAddMonday: true,
                                hiddenUpdateMonday: false,
                                hiddenDeleteMonday: false,
                                hiddenCancelMonday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursMonday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineMonday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        if (stateConst.timelineHoursTuesday.length !== 0) {
            timelineTuesday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={200}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursTuesday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursTuesday[index].hour + " / " + stateConst.timelineHoursTuesday[index].action;

                        } else if (stateConst.timelineHoursTuesday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursTuesday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursTuesday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursTuesday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                tuesdayIndex: index,
                                tuesdayAction: stateConst.timelineHoursTuesday[index].action,
                                hourTuesday1: hourString[0],
                                hourTuesday2: hourString[1],
                                hiddenAddTuesday: true,
                                hiddenUpdateTuesday: false,
                                hiddenDeleteTuesday: false,
                                hiddenCancelTuesday: false
                            });
                        } else if (stateConst.timelineHoursTuesday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursTuesday.length; index1++) {
                                if (stateConst.timelineHoursTuesday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursTuesday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursTuesday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                tuesdayIndex: index,
                                tuesdayAction: "Start-End of Work",
                                hourTuesday1: hourString1,
                                hourTuesday2: hourString2,
                                hiddenAddTuesday: true,
                                hiddenUpdateTuesday: false,
                                hiddenDeleteTuesday: false,
                                hiddenCancelTuesday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursTuesday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineTuesday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        if (stateConst.timelineHoursWednesday.length !== 0) {
            timelineWednesday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={200}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursWednesday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursWednesday[index].hour + " / " + stateConst.timelineHoursWednesday[index].action;

                        } else if (stateConst.timelineHoursWednesday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursWednesday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursWednesday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursWednesday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                wednesdayIndex: index,
                                wednesdayAction: stateConst.timelineHoursWednesday[index].action,
                                hourWednesday1: hourString[0],
                                hourWednesday2: hourString[1],
                                hiddenAddWednesday: true,
                                hiddenUpdateWednesday: false,
                                hiddenDeleteWednesday: false,
                                hiddenCancelWednesday: false
                            });
                        } else if (stateConst.timelineHoursWednesday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursWednesday.length; index1++) {
                                if (stateConst.timelineHoursWednesday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursWednesday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursWednesday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                wednesdayIndex: index,
                                wednesdayAction: "Start-End of Work",
                                hourWednesday1: hourString1,
                                hourWednesday2: hourString2,
                                hiddenAddWednesday: true,
                                hiddenUpdateWednesday: false,
                                hiddenDeleteWednesday: false,
                                hiddenCancelWednesday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursWednesday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineWednesday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        if (stateConst.timelineHoursThursday.length !== 0) {
            timelineThursday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={230}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursThursday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursThursday[index].hour + " / " + stateConst.timelineHoursThursday[index].action;

                        } else if (stateConst.timelineHoursThursday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursThursday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursThursday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursThursday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                thursdayIndex: index,
                                thursdayAction: stateConst.timelineHoursThursday[index].action,
                                hourThursday1: hourString[0],
                                hourThursday2: hourString[1],
                                hiddenAddThursday: true,
                                hiddenUpdateThursday: false,
                                hiddenDeleteThursday: false,
                                hiddenCancelThursday: false
                            });
                        } else if (stateConst.timelineHoursThursday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursThursday.length; index1++) {
                                if (stateConst.timelineHoursThursday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursThursday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursThursday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                thursdayIndex: index,
                                thursdayAction: "Start-End of Work",
                                hourThursday1: hourString1,
                                hourThursday2: hourString2,
                                hiddenAddThursday: true,
                                hiddenUpdateThursday: false,
                                hiddenDeleteThursday: false,
                                hiddenCancelThursday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursThursday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineThursday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        if (stateConst.timelineHoursFriday.length !== 0) {
            timelineFriday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={200}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursFriday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursFriday[index].hour + " / " + stateConst.timelineHoursFriday[index].action;

                        } else if (stateConst.timelineHoursFriday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursFriday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursFriday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursFriday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                fridayIndex: index,
                                fridayAction: stateConst.timelineHoursFriday[index].action,
                                hourFriday1: hourString[0],
                                hourFriday2: hourString[1],
                                hiddenAddFriday: true,
                                hiddenUpdateFriday: false,
                                hiddenDeleteFriday: false,
                                hiddenCancelFriday: false
                            });
                        } else if (stateConst.timelineHoursFriday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursFriday.length; index1++) {
                                if (stateConst.timelineHoursFriday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursFriday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursFriday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                fridayIndex: index,
                                thursdayAction: "Start-End of Work",
                                hourFriday1: hourString1,
                                hourFriday2: hourString2,
                                hiddenAddFriday: true,
                                hiddenUpdateFriday: false,
                                hiddenDeleteFriday: false,
                                hiddenCancelFriday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursFriday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineFriday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        if (stateConst.timelineHoursSaturday.length !== 0) {
            timelineSaturday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={200}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursSaturday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursSaturday[index].hour + " / " + stateConst.timelineHoursSaturday[index].action;

                        } else if (stateConst.timelineHoursSaturday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursSaturday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursSaturday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursSaturday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                saturdayIndex: index,
                                saturdayAction: stateConst.timelineHoursSaturday[index].action,
                                hourSaturday1: hourString[0],
                                hourSaturday2: hourString[1],
                                hiddenAddSaturday: true,
                                hiddenUpdateSaturday: false,
                                hiddenDeleteSaturday: false,
                                hiddenCancelSaturday: false
                            });
                        } else if (stateConst.timelineHoursSaturday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursSaturday.length; index1++) {
                                if (stateConst.timelineHoursSaturday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursSaturday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursSaturday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                saturdayIndex: index,
                                saturdayAction: "Start-End of Work",
                                hourSaturday1: hourString1,
                                hourSaturday2: hourString2,
                                hiddenAddSaturday: true,
                                hiddenUpdateSaturday: false,
                                hiddenDeleteSaturday: false,
                                hiddenCancelSaturday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursSaturday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineSaturday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        if (stateConst.timelineHoursSunday.length !== 0) {
            timelineSunday = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#FFFFFF",
                        foreground: "#7B9D6F",
                        outline: "#DFDFDF"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={0}
                    labelWidth={200}
                    getLabel={function (date, index) {
                        if (!(stateConst.timelineHoursSunday[index].action === "Start-End of Work")) {

                            return stateConst.timelineHoursSunday[index].hour + " / " + stateConst.timelineHoursSunday[index].action;

                        } else if (stateConst.timelineHoursSunday[index].action === "Start-End of Work") {

                            var flagHourForTimeline = stateConst.timelineHoursSunday[index].hour.split("-")

                            if (flagHourForTimeline[0] === "00:00") {
                                return flagHourForTimeline[1] + " / Start of Work";
                            } else if (flagHourForTimeline[1] === "23:59") {
                                return flagHourForTimeline[0] + " / End of Work";
                            }
                        }
                    }}
                    indexClick={index => {
                        if (!(stateConst.timelineHoursSunday[index].action === "Start-End of Work")) {

                            var hourString = stateConst.timelineHoursSunday[index].hour.split("-");  //12:00 - 12:30

                            this.setState({
                                sundayIndex: index,
                                sundayAction: stateConst.timelineHoursSunday[index].action,
                                hourSunday1: hourString[0],
                                hourSunday2: hourString[1],
                                hiddenAddSunday: true,
                                hiddenUpdateSunday: false,
                                hiddenDeleteSunday: false,
                                hiddenCancelSunday: false
                            });
                        } else if (stateConst.timelineHoursSunday[index].action === "Start-End of Work") {

                            var startEndWorkActionsIndex = [];

                            for (let index1 = 0; index1 < stateConst.timelineHoursSunday.length; index1++) {
                                if (stateConst.timelineHoursSunday[index1].action === "Start-End of Work") {
                                    startEndWorkActionsIndex.push(index1);
                                }
                            }

                            var flagHourString1 = stateConst.timelineHoursSunday[startEndWorkActionsIndex[0]].hour.split("-");  //10:00
                            var hourString1 = flagHourString1[1];

                            var flagHourString2 = stateConst.timelineHoursSunday[startEndWorkActionsIndex[1]].hour.split("-");  //22:00
                            var hourString2 = flagHourString2[0];

                            this.setState({
                                sundayIndex: index,
                                sundayAction: "Start-End of Work",
                                hourSunday1: hourString1,
                                hourSunday2: hourString2,
                                hiddenAddSunday: true,
                                hiddenUpdateSunday: false,
                                hiddenDeleteSunday: false,
                                hiddenCancelSunday: false
                            });
                        }

                    }}
                    values={this.state.timelineHoursSunday.map(x => x.flagData)}
                />
            </div>;
        } else {
            timelineSunday = <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No new action added yet.</h4>;
        }

        //#endregion

        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>

        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>
                <Toast ref={(el) => this.toast = el} />
                <Modal
                    show={this.state.infoModal}
                    onHide={this.showOrHideInfoModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Apply All Working Days</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', fontSize: '16px', color: 'black' }}>
                            Applied to all working days.
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="success" onClick={this.showOrHideInfoModal}>OK</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Shift data?
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteShiftOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <div style={{ padding: "8px 16px 8px 24px" }}>
                    <Row>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><ReactButton onClick={this.createNewShift} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</ReactButton></Col>
                    </Row>
                </div>

                <div className="datatable-doc-demo">
                    <div className="content-section implementation">
                        <DataTable ref={(el) => this.dt = el} responsive={true} style={{ textAlign: 'center' }} value={this.state.shifts} paginator={true}
                            emptyMessage="No shift data found" rowsPerPageOptions={[5, 10, 20]} rows={10}>
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '5em' }} />
                            <Column field="shiftDescription" header="Shift Description" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="startDate" header="Start Date" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="endDate" header="End Date" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column body={this.workingDaysTemplate} header="Working days" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="workLocationNames" header="Work Locations" sortable={true} filter={true} filterMatchMode="contains" />
                        </DataTable>
                    </div>
                </div>

                <div hidden={this.state.hidden} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '4rem' }} className="content-section implementation">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Shift Description:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                {/*<PrimeDropdown value={this.state.shiftDescription} options={this.state.shiftDescriptions} onChange={this.onShiftDescriptionChange}
                                    editable={true} placeholder="Select Shift Description" />*/}

                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ shiftDescription: e.target.value })} value={this.state.shiftDescription} />
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Start Date:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <DatePicker value={moment(this.state.date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeDate} />
                                {/*<Calendar dateFormat="dd/mm/yy" value={this.state.startDate} onChange={(e) => this.setState({ startDate: e.value })}
                                monthNavigator={true} yearNavigator={true} yearRange="2000:2050" /> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>End Date:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <DatePicker value={moment(this.state.date1, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeDate1} />
                                {/*<Calendar dateFormat="dd/mm/yy" value={this.state.endDate} onChange={(e) => this.setState({ endDate: e.value })} 
                                monthNavigator={true} yearNavigator={true} yearRange="2000:2050" />*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Shift Plan:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ToggleButton style={{ width: '100%' }} onLabel="Fixed Working Hours" offLabel="Flexible Working Hours"
                                    onIcon="pi pi-bars" offIcon="pi pi-align-center" checked={this.state.shiftPlan} onChange={this.shiftPlanChange} />
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Working Days:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <MultiSelect value={this.state.workingDays} options={this.state.days} onChange={this.onWorkingDayChange0}
                                    style={{ width: '90%' }} filter={true} filterPlaceholder="Search" placeholder="Select Working Days" />
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>First Working Day of Week:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Control style={{ width: '90%' }} name="firstWorkingDay" value={this.state.firstWorkingDay}
                                    onChange={this.handleChangeFirstWorkingDay} as="select">
                                    <option>Select First Working Day</option>
                                    <option>Monday</option>
                                    <option>Tuesday</option>
                                    <option>Wednesday</option>
                                    <option>Thursday</option>
                                    <option>Friday</option>
                                    <option>Saturday</option>
                                    <option>Sunday</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', fontSize: '18px', color: '#000000', fontWeight: 'bold' }}>Working Days and Hours</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Days</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Hour</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Action</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Monday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Monday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Monday</label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <RangePicker style={{ visibility: this.state.hiddenHourMonday }} size="large"
                                    value={[moment(this.state.hourMonday1, 'HH:mm'), moment(this.state.hourMonday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeMonday} />
                                {/*<Calendar style={{ visibility: this.state.hiddenHourMonday }} value={this.state.hourMonday}  onChange={(e) => this.setState({ hourMonday: e.value })} 
                            timeOnly={true} hourFormat="24" />*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionMonday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.mondayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateMonday === true) {
                                                this.setState({
                                                    hiddenAddMonday: false
                                                });
                                            }

                                            this.setState({
                                                mondayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                                               </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {/*<div hidden={this.state.hiddenActionMonday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.mondayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeMonday} editable={true} placeholder="Select Action" />
                                </div>*/}
                                {/*<Form.Control hidden={this.state.hiddenActionMonday} name="mondayAction" value={this.state.mondayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                </Form.Control> */}
                            </Form.Group>

                            <Form.Group as={Col}  >
                                <ReactButton hidden={this.state.hiddenAddMonday} type="button" onClick={this.addMondayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateMonday} type="button" onClick={this.updateMondayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteMonday} type="button" onClick={this.deleteMondayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyMonday} type="button" onClick={this.applyToAllMonday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelMonday} type="button" onClick={this.cancelMondayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyMonday} type="button" onClick={this.clearMonday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineMonday}>
                            <Form.Group as={Col} sm="10">
                                {timelineMonday}
                            </Form.Group>

                            {/* <Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplyMonday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllMonday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearMonday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                            */}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearMonday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Tuesday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Tuesday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Tuesday</label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <RangePicker style={{ visibility: this.state.hiddenHourTuesday }} size="large"
                                    value={[moment(this.state.hourTuesday1, 'HH:mm'), moment(this.state.hourTuesday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeTuesday} />

                                {/*<Calendar style={{ visibility: this.state.hiddenHourTuesday }} value={this.state.hourTuesday} 
                                            onChange={(e) => this.setState({ hourTuesday: e.value })} timeOnly={true} hourFormat="24" /> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionTuesday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.tuesdayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateTuesday === true) {
                                                this.setState({
                                                    hiddenAddTuesday: false
                                                });
                                            }

                                            this.setState({
                                                tuesdayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                                       </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {/*<div hidden={this.state.hiddenActionTuesday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.tuesdayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeTuesday} editable={true} placeholder="Select Action" />
                                </div>*/}

                                {/*<Form.Control hidden={this.state.hiddenActionTuesday} name="tuesdayAction" value={this.state.tuesdayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                </Form.Control>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenAddTuesday} type="button" onClick={this.addTuesdayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateTuesday} type="button" onClick={this.updateTuesdayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteTuesday} type="button" onClick={this.deleteTuesdayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyTuesday} type="button" onClick={this.applyToAllTuesday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelTuesday} type="button" onClick={this.cancelTuesdayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyTuesday} type="button" onClick={this.clearTuesday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineTuesday}>
                            <Form.Group as={Col} sm="10">
                                {timelineTuesday}
                            </Form.Group>

                            {/*<Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplyTuesday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllTuesday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearTuesday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>*/}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearTuesday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Wednesday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Wednesday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Wednesday</label>
                            </Form.Group>

                            <Form.Group as={Col} >

                                <RangePicker style={{ visibility: this.state.hiddenHourWednesday }} size="large"
                                    value={[moment(this.state.hourWednesday1, 'HH:mm'), moment(this.state.hourWednesday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeWednesday} />

                                {/*<Calendar style={{ visibility: this.state.hiddenHourWednesday }} value={this.state.hourWednesday}
                                    onChange={(e) => this.setState({ hourWednesday: e.value })} timeOnly={true} hourFormat="24" /> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionWednesday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.wednesdayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateWednesday === true) {
                                                this.setState({
                                                    hiddenAddWednesday: false
                                                });
                                            }

                                            this.setState({
                                                wednesdayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                               </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>

                                {/*<div hidden={this.state.hiddenActionWednesday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.wednesdayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeWednesday} editable={true} placeholder="Select Action" />
                                </div>*/}
                                {/* <Form.Control hidden={this.state.hiddenActionWednesday} name="wednesdayAction" value={this.state.wednesdayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                </Form.Control> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenAddWednesday} type="button" onClick={this.addWednesdayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateWednesday} type="button" onClick={this.updateWednesdayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteWednesday} type="button" onClick={this.deleteWednesdayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyWednesday} type="button" onClick={this.applyToAllWednesday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelWednesday} type="button" onClick={this.cancelWednesdayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyWednesday} type="button" onClick={this.clearWednesday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineWednesday}>
                            <Form.Group as={Col} sm="10">
                                {timelineWednesday}
                            </Form.Group>

                            {/*<Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplyWednesday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllWednesday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearWednesday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>*/}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearWednesday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Thursday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Thursday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Thursday</label>
                            </Form.Group>

                            <Form.Group as={Col} >

                                <RangePicker style={{ visibility: this.state.hiddenHourThursday }} size="large"
                                    value={[moment(this.state.hourThursday1, 'HH:mm'), moment(this.state.hourThursday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeThursday} />

                                {/*<Calendar style={{ visibility: this.state.hiddenHourThursday }} value={this.state.hourThursday}
                                        onChange={(e) => this.setState({ hourThursday: e.value })} timeOnly={true} hourFormat="24" />*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionThursday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.thursdayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateThursday === true) {
                                                this.setState({
                                                    hiddenAddThursday: false
                                                });
                                            }

                                            this.setState({
                                                thursdayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                               </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {/*<div hidden={this.state.hiddenActionThursday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.thursdayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeThursday} editable={true} placeholder="Select Action" />
                                    </div>*/}
                                {/*<Form.Control hidden={this.state.hiddenActionThursday} name="thursdayAction" value={this.state.thursdayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                    </Form.Control>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenAddThursday} type="button" onClick={this.addThursdayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateThursday} type="button" onClick={this.updateThursdayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteThursday} type="button" onClick={this.deleteThursdayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyThursday} type="button" onClick={this.applyToAllThursday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelThursday} type="button" onClick={this.cancelThursdayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyThursday} type="button" onClick={this.clearThursday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineThursday}>
                            <Form.Group as={Col} sm="10">
                                {timelineThursday}
                            </Form.Group>

                            {/*<Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplyThursday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllThursday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearThursday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>*/}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearThursday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Friday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Friday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Friday</label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <RangePicker style={{ visibility: this.state.hiddenHourFriday }} size="large"
                                    value={[moment(this.state.hourFriday1, 'HH:mm'), moment(this.state.hourFriday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeFriday} />

                                {/*<Calendar style={{ visibility: this.state.hiddenHourFriday }} value={this.state.hourFriday} 
                        onChange={(e) => this.setState({ hourFriday: e.value })} timeOnly={true} hourFormat="24" /> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionFriday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.fridayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateFriday === true) {
                                                this.setState({
                                                    hiddenAddFriday: false
                                                });
                                            }

                                            this.setState({
                                                fridayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                               </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {/*<div hidden={this.state.hiddenActionFriday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.fridayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeFriday} editable={true} placeholder="Select Action" />
                                </div>*/}
                                {/*<Form.Control hidden={this.state.hiddenActionFriday} name="fridayAction" value={this.state.fridayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                </Form.Control>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenAddFriday} type="button" onClick={this.addFridayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateFriday} type="button" onClick={this.updateFridayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteFriday} type="button" onClick={this.deleteFridayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyFriday} type="button" onClick={this.applyToAllFriday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelFriday} type="button" onClick={this.cancelFridayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplyFriday} type="button" onClick={this.clearFriday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineFriday}>
                            <Form.Group as={Col} sm="10">
                                {timelineFriday}
                            </Form.Group>

                            {/*<Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplyFriday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllFriday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearFriday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>*/}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearFriday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Saturday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Saturday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Saturday</label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <RangePicker style={{ visibility: this.state.hiddenHourSaturday }} size="large"
                                    value={[moment(this.state.hourSaturday1, 'HH:mm'), moment(this.state.hourSaturday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeSaturday} />

                                {/*<Calendar style={{ visibility: this.state.hiddenHourSaturday }} value={this.state.hourSaturday} 
                                    onChange={(e) => this.setState({ hourSaturday: e.value })} timeOnly={true} hourFormat="24" /> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionSaturday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.saturdayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateSaturday === true) {
                                                this.setState({
                                                    hiddenAddSaturday: false
                                                });
                                            }

                                            this.setState({
                                                saturdayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                               </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {/*<div hidden={this.state.hiddenActionSaturday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.saturdayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeSaturday} editable={true} placeholder="Select Action" />
                                </div>*/}
                                {/*<Form.Control hidden={this.state.hiddenActionSaturday} name="saturdayAction" value={this.state.saturdayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                </Form.Control>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenAddSaturday} type="button" onClick={this.addSaturdayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateSaturday} type="button" onClick={this.updateSaturdayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteSaturday} type="button" onClick={this.deleteSaturdayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplySaturday} type="button" onClick={this.applyToAllSaturday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelSaturday} type="button" onClick={this.cancelSaturdayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplySaturday} type="button" onClick={this.clearSaturday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineSaturday}>
                            <Form.Group as={Col} >
                                {timelineSaturday}
                            </Form.Group>

                            {/*<Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplySaturday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllSaturday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearSaturday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group> */}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearSaturday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                                <div style={{ textAlign: 'right', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" disabled={this.state.disableCheckBoxes} value="Sunday" onChange={this.onWorkingDayChange} checked={this.state.workingDays.indexOf('Sunday') !== -1}></Checkbox>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: '#000000', marginTop: '0.5rem' }}>
                                <label htmlFor="cb1" className="p-checkbox-label">Sunday</label>
                            </Form.Group>

                            <Form.Group as={Col} >

                                <RangePicker style={{ visibility: this.state.hiddenHourSunday }} size="large"
                                    value={[moment(this.state.hourSunday1, 'HH:mm'), moment(this.state.hourSunday2, 'HH:mm')]} format="HH:mm" onChange={this.hourChangeSunday} />

                                {/*<Calendar style={{ visibility: this.state.hiddenHourSunday }} value={this.state.hourSunday} 
                                    onChange={(e) => this.setState({ hourSunday: e.value })} timeOnly={true} hourFormat="24" /> */}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <div hidden={this.state.hiddenActionSunday}>
                                    <Select
                                        style={{ width: '180px' }}
                                        placeholder="Action"
                                        value={this.state.sundayAction}
                                        onChange={(value) => {
                                            if (this.state.hiddenUpdateSunday === true) {
                                                this.setState({
                                                    hiddenAddSunday: false
                                                });
                                            }

                                            this.setState({
                                                sundayAction: value
                                            })
                                        }}

                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={this.state.newAction} onChange={this.onNewActionChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addActionItem}
                                                    >
                                                        <PlusOutlined /> Add Action
                                               </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.actions.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {/*<div hidden={this.state.hiddenActionSunday}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.sundayAction} options={this.state.actions}
                                        onChange={this.onHandleChangeSunday} editable={true} placeholder="Select Action" />
                                </div>*/}
                                {/*<Form.Control hidden={this.state.hiddenActionSunday} name="sundayAction" value={this.state.sundayAction}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Action
                                    </option>
                                    {optionsActions}
                                </Form.Control>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenAddSunday} type="button" onClick={this.addSundayTimeline} variant="success" style={{ width: '100%' }} >Add to Timeline</ReactButton>
                                <ReactButton hidden={this.state.hiddenUpdateSunday} type="button" onClick={this.updateSundayTimeline} variant="warning" style={{ width: '100%' }} >Update</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenDeleteSunday} type="button" onClick={this.deleteSundayTimeline} variant="danger" style={{ width: '100%' }} >Delete</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplySunday} type="button" onClick={this.applyToAllSunday} variant="outline-success" style={{ width: '100%' }} >Apply to All Working Days</ReactButton>*/}
                            </Form.Group>

                            <Form.Group as={Col} >
                                <ReactButton hidden={this.state.hiddenCancelSunday} type="button" onClick={this.cancelSundayTimeline} variant="secondary" style={{ width: '100%' }} >Cancel</ReactButton>
                                {/*<ReactButton hidden={this.state.hiddenApplySunday} type="button" onClick={this.clearSunday} variant="outline-danger" style={{ width: '100%' }} >Clear Timeline</ReactButton>*/}
                            </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ border: '1px solid black', marginBottom: '1rem' }} hidden={this.state.hiddenTimelineSunday}>
                            <Form.Group as={Col} sm="10">
                                {timelineSunday}
                            </Form.Group>

                            {/*<Form.Group as={Col} sm="2">
                                <DropdownButton style={{ marginTop: '2.5rem' }} hidden={this.state.hiddenApplySunday} variant="info" id="dropdown-item-button" title="Actions for Timeline">
                                    <Dropdown.Item onClick={this.applyToAllSunday} type="button" as="button">Apply to All Working Days</Dropdown.Item>
                                    <Dropdown.Item onClick={this.clearSunday} type="button" as="button">Clear Timeline</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>*/}

                            <Form.Group as={Col} sm="2">
                                <ReactButton type="button" onClick={this.clearSunday} style={{ marginTop: '3rem', width: '100%' }} variant="danger" >Clear Timeline</ReactButton>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', fontSize: '18px', color: '#000000', fontWeight: 'bold' }}>Applicable for</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                                <Form.Label style={{ marginTop: '0.5rem', color: '#000000', fontWeight: 'bold' }}>Work Location:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} >
                                {/*<MultiSelect value={this.state.selectedWorkLocations} options={this.state.workLocations} onChange={(e) => this.setState({ selectedWorkLocations: e.value })}
                                        style={{ width: '100%' }} filter={true} filterPlaceholder="Search" placeholder="Choose Work Location" />*/}
                                <Tree value={this.state.nodes} selectionMode="checkbox" dragdropScope="demo" selectionKeys={this.state.selectedNodeKeys} onSelectionChange={e => this.setState({ selectedNodeKeys: e.value })} />

                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>

                            <Form.Group as={Col} >
                            </Form.Group>
                        </Form.Row>

                    </Form>

                    <Container hidden={this.state.hiddenSaveButton} style={{ maxWidth: 'none', marginBottom: '15px' }}>
                        <Row>
                            <Col>
                                <ReactButton type="button" onClick={this.saveShift} style={{ width: '100%' }} variant="success" >Save</ReactButton>
                            </Col>
                        </Row>
                    </Container>

                    <Container hidden={this.state.hiddenUpdateButton} style={{ maxWidth: 'none', marginBottom: '15px' }}>
                        <Row>
                            <Col>
                                <ReactButton type="button" onClick={this.refreshFirstCreate} style={{ width: '100%' }} variant="danger" >Cancel</ReactButton>
                            </Col>
                            <Col>
                                <ReactButton type="button" onClick={this.updateShift} style={{ width: '100%' }} variant="warning" >Update</ReactButton>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Shifts)