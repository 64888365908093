/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Toast } from 'primereact/toast';
import { Button as PrimeBotton } from "primereact/button";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { InputText } from 'primereact/inputtext';
import { Button } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import DynamicDeleteButton from '../styles/DynamicDeleteButton.scss';

const { Option } = Select;


const _ruleOptions = [
    { label: "Name", value: 1 },
    { label: "Surname", value: 2 },
    { label: "Midname", value: 3 },
    { label: "First Letter of Name", value: 4 },
    { label: "First Two Letter of Name", value: 5 },
    { label: "First Letter of Surname", value: 6 },
    { label: "First Two Letter of Surname", value: 7 },
    { label: "First Letter of Midname", value: 8 },
    { label: "First Two Letter of Midname", value: 9 }
];

const _emailRows = [
    {
        ruleIndex: 0,
        title: "Email Account",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: true,
        ruleList: []
    },
    {
        ruleIndex: 1,
        title: "First Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: true,
        ruleList: []
    },
    {
        ruleIndex: 2,
        title: "Second Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: true,
        ruleList: []
    },
    {
        ruleIndex: 3,
        title: "Third Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: false,
        ruleList: []
    },
    {
        ruleIndex: 4,
        title: "Fourth Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: false,
        ruleList: []
    },
    {
        ruleIndex: 5,
        title: "Fifth Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: false,
        ruleList: []
    }
];

const _usernameRows = [
    {
        ruleIndex: 0,
        title: "Username",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: true,
        ruleList: []
    },
    {
        ruleIndex: 1,
        title: "First Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: true,
        ruleList: []
    },
    {
        ruleIndex: 2,
        title: "Second Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: true,
        ruleList: []
    },
    {
        ruleIndex: 3,
        title: "Third Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: false,
        ruleList: []
    },
    {
        ruleIndex: 4,
        title: "Fourth Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: false,
        ruleList: []
    },
    {
        ruleIndex: 5,
        title: "Fifth Alternative",
        delimeter: '',
        rule: '',
        ruleText: '',
        show: false,
        ruleList: []
    }
];


export class AutoUsername extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteModalShow: false,
            ruleList: [],
            usernameRules: [],
            usernameRows: [],
            emailRules: [],
            emailRows: [],
            items: [
                { key: ".", value: "Dot" },
                { key: "_", value: "_" },
                { key: "-", value: "-" },
                { key: "@", value: "@" }
            ],
            name: ''


        };

        // #region tags functions


        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

        // #endregion tags functions
        this.fillRules();
    }

    fillRules = async () => {

        const response = await handleRequest("GET", "/AutoUsernameRule/getAllByCompany");


        var _defEmailRows = _emailRows;
        var _defUsernameRows = _usernameRows;

        if (response.data.length !== 0) {

            const _usernameRules = response.data.filter(p => p.type === "username");
            const _emailRules = response.data.filter(p => p.type === "email");


            if (_emailRules !== null && _emailRules !== undefined && _emailRules.length > 0) {

                console.log(_emailRules);


                _defEmailRows.forEach(element => {
                    var index = _emailRules.findIndex(p => p.ruleIndex === element.ruleIndex);

                    if (index >= 0) {
                        element.ruleText = this.getRuletext(_emailRules.filter(p => p.ruleIndex === element.ruleIndex));
                        element.ruleList = _emailRules.filter(p => p.ruleIndex === element.ruleIndex);
                    }

                });


            }

            if (_usernameRules !== null && _usernameRules !== undefined && _usernameRules.length > 0) {

                console.log(_usernameRules);


                _defUsernameRows.forEach(element => {
                    var index = _usernameRules.findIndex(p => p.ruleIndex === element.ruleIndex);

                    if (index >= 0) {
                        element.ruleText = this.getRuletext(_usernameRules.filter(p => p.ruleIndex === element.ruleIndex));
                        element.ruleList = _usernameRules.filter(p => p.ruleIndex === element.ruleIndex); 
                    }

                });


            }


            this.setState({
                ruleList: response.data,
                emailRows: _defEmailRows,
                usernameRows: _defUsernameRows
            });


        } else {

            this.setState({

                emailRows: _defEmailRows,
                usernameRows: _defUsernameRows
            });
        }
    }

    getRuletext = (ruleList) => {

        var result = "";
        ruleList.forEach(element => {
            result = result + _ruleOptions.find(p => p.value == element.rule).label + element.delimeter;
        });

        return result;

    }

    saveItem = async (item) => {

        console.log(item);
        console.log(item.ruleList)

        if (item.ruleList.length > 0) {

            var response = await handleRequest("POST", "/AutoUsernameRule/save", item.ruleList);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS-AutoUsernameRule-001")   // Buradaki mesaj aynı standartta backenddeki değere göre güncellenmeli.
                {
                    this.showSuccess();
                }
                else if (response.data === "ERROR-AutoUsernameRule-001") {
                    this.showError("An error was occured please try again later !");
                }
            }
            else this.showError("An error was occured. Please try again later !");

        } else {
            this.showError();
        }
    };

    resetInputs = () => {
        this.setState({
            hideInputs: true,
            deleteModalShow: false,
            createNewButton: "Create New",
            actionButton: "Save",
            actionButtonVariant: "success",
            createNewButtonVariant: "info",




        });
    };


    edit = (row) => {

        console.log(row);

        this.setState({
            hideInputs: false,
            createNewButton: "Stop Updating",
            actionButton: "Update",
            actionButtonVariant: "warning",
            createNewButtonVariant: "danger",


        });


        document.getElementById('kt_scrolltop').click();
    }

    deleteModal = (row) => {

        this.setState({
            companyId: row.id,
            deleteModalShow: true
        });
    }

    delete = async () => {

        console.log(this.companyId);

        const deletedItem = {
            // id: this.state.companyId

        }

        await handleRequest("POST", "/Company/delete", deletedItem);

        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }


    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };


    showOrHideDeleteModal = () => {
        this.setState({
            deleteModalShow: !this.state.deleteModalShow
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }




    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
    };


    onNameChange = event => {
        this.setState({
            name: event.target.value,
        });
    };

    addItem = () => {
        console.log('addItem');
        const { items, name } = this.state;
        if (name.length > 0) {
            const obj = { key: name, value: name };
            this.setState({
                items: [...items, obj],
                name: '',
            });


        }

    };

    addRightEmail = (item) => {

        console.log(item);
        if (item.rule > 0) {
            var obj = { rule: item.rule, delimeter: item.delimeter, ruleIndex: item.ruleIndex, type: "email" };
            var list = item.ruleList;
            list.push(obj);
            var _ruleText = this.getRuletext(list);
            this.setState(prevState => ({
                emailRows: prevState.emailRows.map(
                    el => el.ruleIndex === item.ruleIndex ? { ...el, ruleList: list, ruleText: _ruleText } : el
                )
            }))

        }
        else this.showError("Please select options!");

    };

    addRightUser = (item) => {

        console.log(item);
        if (item.rule > 0 ) {
            var obj = { rule: item.rule, delimeter: item.delimeter, ruleIndex: item.ruleIndex, type: "username" };
            var list = item.ruleList;
            list.push(obj);
            var _ruleText = this.getRuletext(list);
            this.setState(prevState => ({
                usernameRows: prevState.usernameRows.map(
                    el => el.ruleIndex === item.ruleIndex ? { ...el, ruleList: list, ruleText: _ruleText } : el
                )
            }))

        }
        else this.showError("Please select options!");

    };


    deleteRule1 = async(item) => { 

        console.log(item.ruleList);
 
        if (item.ruleList.length > 0 && item.ruleList[0].id ) {
           
             await handleRequest("POST", "/AutoUsernameRule/delete", item.ruleList);
        

        }

        var list = [];

        this.setState(prevState => ({
            emailRows: prevState.emailRows.map(
                el => el.ruleIndex === item.ruleIndex ? { ...el, ruleList: list, ruleText: '' } : el
            )
        }))


        this.showSuccess();


    };

    deleteRule = async(item) => { 

        console.log(item.ruleList);

        if (item.ruleList.length > 0 && item.ruleList[0].id ) {
           
            await handleRequest("POST", "/AutoUsernameRule/delete", item.ruleList);
       

       }

        var list = [];

        this.setState(prevState => ({
            usernameRows: prevState.usernameRows.map(
                el => el.ruleIndex === item.ruleIndex ? { ...el, ruleList: list, ruleText: '' } : el
            )
        }))



    };
    addNewRuleEmail = () => {



        var list = this.state.emailRows;

        var index = list.findIndex(p => p.show === false);
        console.log(index);
        if (index >= 0) {
            var item = list[index];

            this.setState(prevState => ({
                emailRows: prevState.emailRows.map(
                    el => el.ruleIndex === item.ruleIndex ? { ...el, show: true } : el
                )
            }))
        }

        else this.showError("All rules openned !");


    };

    addNewRuleUsername = () => {

        var list = this.state.usernameRows;

        var index = list.findIndex(p => p.show === false);
        console.log(index);
        if (index >= 0) {
            var item = list[index];

            this.setState(prevState => ({
                usernameRows: prevState.usernameRows.map(
                    el => el.ruleIndex === item.ruleIndex ? { ...el, show: true } : el
                )
            }))
        }

        else this.showError("All rules openned !");


    };

  
    undoUser = async(item) => { 
 
 
        if (item.ruleList.length > 0 && !item.ruleList[item.ruleList.length-1].id ) { 
           
            var list = item.ruleList;
            list.pop();
        
            this.setState(prevState => ({
                usernameRows: prevState.usernameRows.map(
                    el => el.ruleIndex === item.ruleIndex ? { ...el, ruleList: list, ruleText: this.getRuletext(list) } : el
                )
            }))   
        

        }  
        else this.showError("Database record can't be undo ");   

    };

    undoEmail = async(item) => {  
 
        if (item.ruleList.length > 0 && !item.ruleList[item.ruleList.length-1].id ) { 
           
            var list = item.ruleList;
            list.pop();
        
            this.setState(prevState => ({
                emailRows: prevState.emailRows.map(
                    el => el.ruleIndex === item.ruleIndex ? { ...el, ruleList: list, ruleText: this.getRuletext(list) } : el
                )
            }))   
        

        }  
        else this.showError("Database record can't be undo ");   
    };
    




    render() {
        return (
            <div>
                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={3}> <h3>Email</h3> </Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Toast ref={(el) => this.toast = el} /></Col>
                    </Row>
                </div>
                <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>

                                    {this.state.emailRows.map(item => (

                                        item.show && (
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="1">
                                                    {item.ruleIndex > 2 ? (
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            style={{ marginRight: '4px' }}
                                                            onClick={() => {
                                                                this.setState(prevState => ({
                                                                    emailRows: prevState.emailRows.map(
                                                                        el => el.ruleIndex === item.ruleIndex ? { ...el, show: false } : el
                                                                    )
                                                                }))
                                                            }}
                                                        />
                                                    ) : null}
                                                </Col>

                                                <Form.Label style={{ color: 'black' }} column sm="1">
                                                    {item.title}
                                                </Form.Label>

                                                <Col sm="2">
                                                    <PrimeDropdown style={{ width: '100%', marginRight: '1rem' }} value={item.rule} options={_ruleOptions} onChange={(e) => {
                                                        this.setState(prevState => ({
                                                            emailRows: prevState.emailRows.map(
                                                                el => el.ruleIndex === item.ruleIndex ? { ...el, rule: e.target.value } : el
                                                            )
                                                        }))
                                                    }}

                                                        placeholder="Select ..." />

                                                </Col>
                                                <Col sm="2">
                                                    <Select
                                                     
                                                        style={{ width: '100%' }}
                                                        placeholder="Delimeter"
                                                        onChange={(value) => {
                                                            this.setState(prevState => ({
                                                                emailRows: prevState.emailRows.map(
                                                                    el => el.ruleIndex === item.ruleIndex ? { ...el, delimeter: value } : el
                                                                )
                                                            }))
                                                        }}

                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.name} onChange={this.onNameChange} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addItem}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                                  </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.items.map(i => (
                                                            <Option key={i.key} value={i.key}>{i.value}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>

                                                <Col sm="1">
                                                    <PrimeBotton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right"
                                                        onClick={() => this.addRightEmail(item)}
                                                    /> 
                                                    <PrimeBotton type="Button" icon="pi pi-undo"  onClick={() => this.undoEmail(item)}/>
                                                </Col>

                                                <Col sm="3">
                                                    <InputText style={{ width: '100%', marginRight: '1rem' }} type="text" value={item.ruleText} />

                                                </Col>
                                                <Col sm="1">
                                                    <PrimeBotton type="Button" icon="pi pi-times" className="p-button-danger" onClick={() => this.deleteRule1(item)} />
                                                </Col>

                                                <Col sm="1">
                                                    <PrimeBotton type="Button" label="Save" className="p-button-success" style={{ width: '100%' }} onClick={() => this.saveItem(item)}  > </PrimeBotton>
                                                </Col>

                                            </Form.Group>)
                                    ))}
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                        <Form.Label style={{ color: 'black' }} column sm="2">

                                        </Form.Label>

                                        <Col sm="2">
                                            <Button
                                                type="dashed"
                                                onClick={this.addNewRuleEmail}
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add new alternative
                                 </Button>

                                        </Col>
                                    </Form.Group>


                                </Form>

                            </Container></Card.Body>
                    </Card>
                </div>
                <div style={{ padding: "4px 16px 4px 24px", marginTop: '2rem' }}>
                    <Row>
                        <Col xs={3}> <h3>Username</h3> </Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Toast ref={(el) => this.toast = el} /></Col>
                    </Row>
                </div>
                <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>

                                    {this.state.usernameRows.map(item => (
                                        item.show && (
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                            <Col sm="1">
                                                {item.ruleIndex > 2 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        style={{ marginRight: '4px' }}
                                                        onClick={() => {
                                                            this.setState(prevState => ({
                                                                usernameRows: prevState.usernameRows.map(
                                                                    el => el.ruleIndex === item.ruleIndex ? { ...el, show: false } : el
                                                                )
                                                            }))
                                                        }}
                                                    />
                                                ) : null}
                                            </Col>
                                            <Form.Label style={{ color: 'black' }} column sm="1">
                                                {item.title}
                                            </Form.Label>

                                            <Col sm="2">
                                                <PrimeDropdown style={{ width: '100%', marginRight: '1rem' }} value={item.rule} options={_ruleOptions} onChange={(e) => {
                                                    this.setState(prevState => ({
                                                        usernameRows: prevState.usernameRows.map(
                                                            el => el.ruleIndex === item.ruleIndex ? { ...el, rule: e.target.value } : el
                                                        )
                                                    }))
                                                }}

                                                placeholder="Select ..." />

                                            </Col>
                                            <Col sm="2">
                                                <Select
                                                   style={{ width: '100%' }}
                                                    placeholder="Delimeter"
                                                    onChange={(value) => {
                                                        this.setState(prevState => ({
                                                            usernameRows: prevState.usernameRows.map(
                                                                el => el.ruleIndex === item.ruleIndex ? { ...el, delimeter: value } : el
                                                            )
                                                        }))
                                                    }}

                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.name} onChange={this.onNameChange} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addItem}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                                  </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {this.state.items.map(i => (
                                                        <Option key={i.key} value={i.key}>{i.value}</Option>
                                                    ))}
                                                </Select>
                                            </Col>

                                            <Col sm="1">
                                                <PrimeBotton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right"
                                                    onClick={() => this.addRightUser(item)}
                                                />
                                                <PrimeBotton type="Button" icon="pi pi-undo" onClick={() => this.undoUser(item)}/>
                                            </Col>

                                            <Col sm="3">
                                                <InputText style={{ width: '100%', marginRight: '1rem' }} type="text" value={item.ruleText} />

                                            </Col>
                                            <Col sm="1">
                                                <PrimeBotton type="Button" icon="pi pi-times" className="p-button-danger" onClick={() => this.deleteRule(item)} />
                                            </Col>

                                            <Col sm="1">
                                                <PrimeBotton type="Button" label="Save" className="p-button-success" style={{ width: '100%' }} onClick={() => this.saveItem(item)}  > </PrimeBotton>
                                            </Col>

                                        </Form.Group>)
                                    ))}
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                        <Form.Label style={{ color: 'black' }} column sm="2">

                                        </Form.Label>

                                        <Col sm="2">
                                            <Button
                                                type="dashed"
                                                onClick={this.addNewRuleUsername}
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Add new alternative
                                 </Button>

                                        </Col>
                                    </Form.Group>

                                </Form>

                            </Container></Card.Body>
                    </Card>
                </div>




                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}>
                            {/* <Button onClick={this.showOrHideDeleteModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.deleteModalShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Rule ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>All informations about rule will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Close</Button>
                                    <Button variant="danger" onClick={this.delete} >Delete</Button>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>

                    </Row>
                </Container>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(AutoUsername)  
