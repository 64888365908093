/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import { Table, Button as AntdButton } from 'antd';
import { DownloadOutlined  } from '@ant-design/icons';

class MaintenanceChecklistSubLog extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            pagination: {
                current: 1,
                pageSize: 5,
            },
            loading: false,
            equipmentLogId:"",
            MaintenanceChecklistLogs: [],
        }
    }

    componentDidMount  = async () => {
      console.log("HHH",this.props.row);
        await this.setState({
          equipmentLogId: this.props.row.equipmentLog.id,
        });
        // const { pagination } = this.state;
        // this.fetch({ pagination });

        var equipmentLogId="-1";
        if(this.props.row.equipmentLog.id!=null && this.props.row.equipmentLog.id!=undefined && this.props.row.equipmentLog.id!=""){
          equipmentLogId=this.props.row.equipmentLog.id;
        }

        const newObj = {
          id: 0,
          equipmentLogId:equipmentLogId,
          maintenanceType:this.props.row.maintenanceType,
        }
        const response = await handleRequest("POST", "/MaintenanceChecklist/getMaintenanceChecklistPaging", newObj);
        if (response.length === 0 || response.data.length === 0) {
            this.setState({
              MaintenanceChecklistLogs: []
            });
        } else if (response.data.length !== 0) {
            this.setState({
              MaintenanceChecklistLogs: response.data.data
            });
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters, });
      };
    
    fetch = async (params = {}) => {
      this.setState({ loading: true });

      var equipmentLogId="-1";
      if(this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!=""){
        equipmentLogId=this.state.equipmentLogId;
      }

      const newObj = {
        id: 0,
        firstResult: params.pagination.current,
        maxResults: params.pagination.pageSize,
        equipmentLogId:equipmentLogId,
      }
      const response = await handleRequest("POST", "/MaintenanceChecklist/getMaintenanceChecklistPaging", newObj);
      console.log("RRR", response.data.data);
      if (response.data.length !== 0) {
        this.setState({
          loading: false,
          MaintenanceChecklistLogs: response.data.data,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords,
          },
        });
      } else {
        this.setState({
          loading: false,
          MaintenanceChecklistLogs: [],
          pagination: {
            ...params.pagination,
            total: 0,
          },
        });
      }
    };

    downloadFile = async (url) => {
        window.location.href = url;
    }
        
    render() {
      const renderDownloadFileRender = (photoFilePath) => {
        if (photoFilePath !== null && photoFilePath !== "" && photoFilePath !== undefined) {
            return <React.Fragment>
            <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(photoFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
            </React.Fragment>
        }
        return "";
      }

      const durationTemplate= (row) => { 
        console.log("PPP",row);
        var result = "";
        if(row!=null && row!=undefined){
        var pObj = row;
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
          }
        }
          return <> {result}</>;
      }

        const columns = [ 
            {
              dataIndex: "index",
              key: "index",
              title: "S/N",
            },  
            {
                dataIndex: "equipmentPartList",
                key: "key",
                title: <FormattedMessage id="EQLUnitGroup" />,
                render: (data) =>   
                   {  
                    if(data!=null && data!=undefined && data.codeUnitGroup!=null && data.codeUnitGroup!=undefined){
                       return data.codeUnitGroup.name;
                    } 
                    return "";
                }
            },
            {
              dataIndex: "equipmentPartList",
              key: "key",
              title: <FormattedMessage id="EQLPartCodeName" />,
              render: (data) =>   
                 {  
                  if(data!=null && data!=undefined){
                     return data.partCode + "-" + data.partName ;
                  } 
                  return "";
              }
            },
            {
              dataIndex: "codeCheckItem",
              key: "key",
              title: <FormattedMessage id="MSLCheckItem" />,
              render: (data) =>   
                 {  
                  if(data!=null && data!=undefined){
                    return data.name;
                 } 
                  return "";
              }
            },
            {
              dataIndex: "codeCriteria",
              key: "key",
              title: <FormattedMessage id="MSLCriteria" />,
              render: (data) =>   
                 {  
                  if(data!=null && data!=undefined){
                    return data.name;
                 } 
                  return "";
              }
            },
            {
              dataIndex: "codeAction",
              key: "key",
              title: <FormattedMessage id="MSLAction" />,
              render: (data) =>   
                 {  
                  if(data!=null && data!=undefined){
                    return data.name;
                 } 
                  return "";
              }
            },
            {
              dataIndex: "equipmentPartList",
              key: "key",
              title: <FormattedMessage id="ModalPhotoText" />,
              render: (record) => (renderDownloadFileRender(record.photoFilePath))
            },
            {
              dataIndex: "durationMaintenanceChecklist",
              key: "key",
              title: <FormattedMessage id="MSLDuration" />,
              render: (record) => (durationTemplate(record))
            },
          ];

        return (
            <div>
                    <Table columns={columns} dataSource={this.state.MaintenanceChecklistLogs} 
                // pagination={this.state.pagination}
                    pagination={{
                      pageSize: 5,
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20", "30"],
                      total: this.state.MaintenanceChecklistLogs.length,
                      showQuickJumper: true,
                      position: "bottomLeft",
                    }}
                    responsive striped hover bordered scroll={{ x: 100, scrollToFirstRowOnChange: true }} 
                    //loading={this.state.loading} onChange={this.handleTableChange} 
                />
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {
  
    }
  }
  
  const mapDispatchToProps = {
  
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceChecklistSubLog)
