import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Accordion } from 'react-bootstrap';
import { Button } from "primereact/button";
import { Form, Col, Row } from 'react-bootstrap';
import "react-toggle/style.css";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Table, Select, Switch, Modal } from 'antd';
import { Column } from 'primereact/components/column/Column';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { PickList } from 'primereact/picklist'
import { Tree as Tree2 } from 'antd';
import moment from 'moment';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu5';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker; 

class Auditlog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            auditlogList: [],
        }
    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/Auditlog/getAll");

        if (Boolean(response.data) && response.data.length > 0) {

            const data = response.data;
            console.log(data); 
            this.setState({
                auditlogList: data

            });
        }


    }

 

    render() {
        return ( 
            <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                
                {
                                      <FormAnt.Item
                                      label={<FormattedMessage id="todo" defaultMessage="Filter" />}
                                      name="OrganizationalLevelDesignation"
                                      rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                      <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                          <Col sm="6">

                                          <RangePicker picker={"day"}  onChange={(value, dateString) => {
                                        console.log(value);
                                        console.log(dateString);
                                        this.setState({ 
                                            moments: value,
                                            dateStrings: dateString 
                                        })
        
                                      // this.restartTable(value); 
        
                                       
        
                                    }} />

                                          </Col>
                                          <Col sm="6">
                                          </Col>
                                      </Form.Group>
                                  </FormAnt.Item>
                                   
                                }
                <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.auditlogList} bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }}>

                   
                   
                    <Column title="Date"  render={(value, row, index) => {
                        if (Boolean(row.timestamps))
                            return moment(row.timestamps,"YYYYMMDD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") 
                        else return ""; 

                    }} />
                    <Column title="DB Server" dataIndex="serverhost" />
                    <Column title="User Name" dataIndex="username" />
                    <Column title="Host" dataIndex="host" /> 
                    <Column title="Connection ID" dataIndex="connectionid" /> 
                    <Column title="Query ID" dataIndex="queryid" /> 
                    <Column title="Operation" dataIndex="operation" /> 
                    <Column title="Database" dataIndex="database" /> 
                    <Column title="Query" dataIndex="query" /> 
                   
                      {/* <Column title="Created Date,Time" render={(value, row, index) => {
                        if (Boolean(row.time))
                            return moment(new Date(row.time)).format("DD/MM/YYYY HH:mm:ss") 
                        else return "";

                    }} />  */}
                    


                </Table>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Auditlog)
