/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Radio, Switch, Form as AntdForm } from 'antd';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { ColorPicker } from 'primereact/colorpicker';
import { FormattedMessage } from "react-intl";

class MNDTableSettings extends Component {

constructor(props) {
    super(props)

    this.state = {
        localStorageTableName:"",
    }
}

componentDidMount = async () => {
    if(this.props.id!=null && this.props.id!=undefined && this.props.id!=0 && this.props.id!=""){
        await this.setState({
            localStorageTableName:this.props.id
        });
        this.getTableProperties(this.props.id); 
    }else{
        await this.setState({
            localStorageTableName:""
        });
    } 
    console.log("PRP",this.props.id);
}

showSuccess(msg) {
    if (!msg) {
        msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="The settings made have been saved successfully." />;
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Success"
    });
};

showError(msg) {
    if (!msg) {
        msg =  <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!"/>;
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Error"
    });
};

showWarning(msg) {
    if (!msg) {
        msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs."/>;
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Warning"
    });
};

showOrHideModalMessage = () => {
    this.setState({
        modalMessage: !this.state.modalMessage,
    }); 
    setTimeout(() => {
        document.body.style.overflow = "auto";
    }, 500);
}

getTableProperties = async (deger) => {
    this.table = JSON.parse(localStorage.getItem(deger));
    console.log("KKK", this.table);
    if(this.table!=null && this.table!=undefined && this.table!=""){
        this.setState({
            bordered: this.table.bordered,
            title: this.table.title, 
            titleDrm: this.table.titleDrm, 
            showHeader: this.table.showHeader, 
            footer: this.table.footer,  
            footerDrm: this.table.footerDrm, 
            expandable: this.table.expandable, 
            expandableDrm: this.table.expandableDrm, 
            rowSelection: this.table.rowSelection, 
            ellipsis: this.table.ellipsis, 
            tableLayout: this.table.tableLayout, 
            size: this.table.size, 
            top: this.table.top, 
            bottom: this.table.bottom, 
            editBgColor: this.table.editBgColor, 
            deleteBgColor: this.table.deleteBgColor,   
            selectedNodeKey: null,
            yScroll:this.table.yScroll,
            xScroll:this.table.xScroll,
        });
    }else{
        // --- yeni default ayarlar
        this.setState({
            bordered: true,
            title: undefined,
            titleDrm:false,
            showHeader: true,
            footer: undefined,
            footerDrm:false,
            expandable: false,
            expandableDrm:false,
            rowSelection: undefined,
            ellipsis:false,
            tableLayout: undefined,
            size: 'default',
            top: 'none',
            bottom: 'bottomLeft',
            editBgColor: '#fff',
            deleteBgColor: '#fff',
            selectedNodeKey: null,
            xScroll: 'scroll'
        });
        // --- önceki default ayarlar
        // this.setState({
        //     bordered: false,
        //     title: undefined,
        //     titleDrm:false,
        //     showHeader: undefined,
        //     footer: undefined,
        //     footerDrm:false,
        //     expandable: false,
        //     expandableDrm:false,
        //     rowSelection: undefined,
        //     ellipsis:false,
        //     tableLayout: undefined,
        //     size: 'default',
        //     top: 'none',
        //     bottom: 'bottomRight',
        //     editBgColor: '#fff',
        //     deleteBgColor: '#fff',
        //     selectedNodeKey: null,
        // });  
    }
} 

saveTableSettings = async () => {
    var newTableName = {
        bordered: this.state.bordered,
        title: this.state.title,
        titleDrm: this.state.titleDrm,
        showHeader: this.state.showHeader,
        expandableDrm:this.state.expandableDrm,
        expandable:this.state.expandable,
        footer: this.state.footer,
        footerDrm: this.state.footerDrm,
        rowSelection: this.state.rowSelection,
        yScroll: this.state.yScroll,
        ellipsis: this.state.ellipsis,
        tableLayout: this.state.tableLayout,
        size: this.state.size,
        xScroll: this.state.xScroll,
        top: this.state.top,
        bottom: this.state.bottom,
        editBgColor: this.state.editBgColor,
        deleteBgColor: this.state.deleteBgColor,
    };

    console.log("VVV", newTableName);

    localStorage.setItem(this.state.localStorageTableName, JSON.stringify(newTableName));
    
    this.showSuccess();
}; 

handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
};

handleSizeChange = e => {
    this.setState({ size: e.target.value });
};

handleTableLayoutChange = e => {
    this.setState({ tableLayout: e.target.value });
};

handleExpandChange = enable => {
    this.setState({ 
        expandable: enable ? this.state.expandable : undefined,
        expandableDrm:enable,
    });
};

handleEllipsisChange = enable => {
    this.setState({ ellipsis: enable });
};

handleTitleChange = enable => {
    this.setState({ 
        title: enable ? this.state.title : undefined,
        titleDrm:enable,
    });
};

handleHeaderChange = enable => {
    this.setState({ 
        showHeader: enable,
     });
};

handleFooterChange = enable => {
    this.setState({ 
        footer: enable ? this.state.footer : undefined ,
        footerDrm:enable,
    });
};

handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
};

handleYScrollChange = enable => {
    this.setState({ yScroll: enable, paginationDisabled: false });
    if (enable == false) {
        this.setState({
            paginationDisabled: { pageSize: 5, defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'], total: 0, showQuickJumper: true, position: [this.state.top, this.state.bottom] }
        })
    }
};

handleXScrollChange = e => {
    this.setState({ xScroll: e.target.value });
};

handleDataChange = hasData => {
    this.setState({ hasData });
};

render() {
    const { xScroll, yScroll, ...state } = this.state;
    const scroll = {};
    if (yScroll) {
        scroll.y = 240;
    }
    if (xScroll) {
        scroll.x = '100vw';
    }
    console.log("X", xScroll);
    console.log("Y", yScroll);

return (
    <div>
        {(this.state.localStorageTableName!=null && this.state.localStorageTableName!=undefined && this.state.localStorageTableName!=0 && this.state.localStorageTableName!="") &&  <Form>
            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="3">
                    <Switch  
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Bordered"
                        unCheckedChildren="Non-Bordered"
                        checked={state.bordered} onChange={this.handleToggle('bordered')} />
                </Col>
                <Col sm="1"></Col>
                <Col sm="3">
                    <Switch 
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Title"
                        unCheckedChildren="Non-Title"
                        checked={this.state.titleDrm} onChange={this.handleTitleChange} />
                </Col>
                <Col sm="1"></Col>
                <Col sm="3">
                    <Switch
                    style={{ width: "100%", marginTop: "0.2rem", height: "100%"}}
                    checkedChildren="Column Header"
                    unCheckedChildren="Non-Column Header"
                    checked={this.state.showHeader} onChange={this.handleHeaderChange} />
                </Col> 
            </Form.Group>

            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="3">
                    <Switch 
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Footer"
                        unCheckedChildren="Non-Footer"
                        checked={state.footerDrm} onChange={this.handleFooterChange} />
                </Col>
                <Col sm="1"></Col>
                <Col sm="3">
                <Switch    
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Expandable"
                        unCheckedChildren="Non-Expandable"
                        checked={state.expandableDrm} onChange={this.handleExpandChange} />
                </Col>
                <Col sm="1"></Col>
                <Col sm="3">
                <Switch   
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Checkbox"
                        unCheckedChildren="Non-Checkbox"
                        checked={state.rowSelection} onChange={this.handleRowSelectionChange} />
                </Col> 
            </Form.Group>

            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="3">
                <Switch   
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Fixed Header"
                        unCheckedChildren="Non-Fixed Header"
                        checked={yScroll} onChange={this.handleYScrollChange} />
                </Col>
                <Col sm="1"></Col>
                <Col sm="3">
                    <Switch  
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Ellipsis"
                        unCheckedChildren="Non-Ellipsis"
                        checked={state.ellipsis} onChange={this.handleEllipsisChange} />
                </Col>
                <Col sm="4"> 
                    <AntdForm.Item label="Table Layout">
                        <Radio.Group value={state.tableLayout} onChange={this.handleTableLayoutChange}>
                            <Radio.Button value={undefined}>Unset</Radio.Button>
                            <Radio.Button value="fixed">Fixed</Radio.Button>
                        </Radio.Group>
                    </AntdForm.Item>
                </Col> 
            </Form.Group>    
            
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="6">
                    <AntdForm.Item label="Size">
                        <Radio.Group value={this.state.size} onChange={this.handleSizeChange}>
                            <Radio.Button value="default">Default</Radio.Button>
                            <Radio.Button value="middle">Middle</Radio.Button>
                            <Radio.Button value="small">Small</Radio.Button>
                        </Radio.Group>
                    </AntdForm.Item>
                </Col> 
                
                <Col sm="6">
                    <AntdForm.Item label="Table Scroll">
                        <Radio.Group value={xScroll} onChange={this.handleXScrollChange}>
                            <Radio.Button value={undefined}>Unset</Radio.Button>
                            <Radio.Button value="scroll">Scroll</Radio.Button>
                            <Radio.Button value="fixed">Fixed Columns</Radio.Button>
                        </Radio.Group>
                    </AntdForm.Item>
                </Col>
            </Form.Group> 

            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="12">
                <AntdForm.Item label="Pagination Top">
                    <Radio.Group
                        value={this.state.top}
                        onChange={e => {
                            this.setState({ top: e.target.value });
                        }}>
                        <Radio.Button value="topLeft">TopLeft</Radio.Button>
                        <Radio.Button value="topCenter">TopCenter</Radio.Button>
                        <Radio.Button value="topRight">TopRight</Radio.Button>
                        <Radio.Button value="none">None</Radio.Button>
                    </Radio.Group>
                </AntdForm.Item>
                </Col> 
            </Form.Group>

            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="12">
                    <AntdForm.Item label="Pagination Bottom">
                        <Radio.Group
                            value={this.state.bottom}
                            onChange={e => {
                                this.setState({ bottom: e.target.value });
                            }}>
                            <Radio.Button value="bottomLeft">BottomLeft</Radio.Button>
                            <Radio.Button value="bottomCenter">BottomCenter</Radio.Button>
                            <Radio.Button value="bottomRight">BottomRight</Radio.Button>
                            {/* <Radio.Button value="none">None</Radio.Button> */}
                        </Radio.Group>
                    </AntdForm.Item>
                </Col> 
            </Form.Group>

            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="6">
                <AntdForm.Item label="Set Edit Button Background Color">
                    <ColorPicker style={{ width: '10%' }} value={this.state.editBgColor} onChange={(e) => this.setState({ editBgColor: e.value })} />
                </AntdForm.Item>
                </Col>
                <Col sm="6">
                <AntdForm.Item label="Set Delete Button Background Color">
                    <ColorPicker style={{ width: '10%' }} value={this.state.deleteBgColor} onChange={(e) => this.setState({ deleteBgColor: e.value })} />
                </AntdForm.Item>
                </Col>
            </Form.Group>
            
            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Col sm="8"> 
                </Col>
                <Col sm="4">
                    <Button variant="success" style={{ width: '100%'}} onClick={this.saveTableSettings}>
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </Button>
                </Col>
            </Form.Group>

    <Modal
        show={this.state.modalMessage}
        onHide={this.showOrHideModalMessage}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >

        <Modal.Header style={{backgroundColor: "#f3f3f3"}} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <Row > 
                <Col sm="12">
                {this.state.messageTip=="Success" &&  
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                
                {this.state.messageTip=="Warning" &&  
                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                {this.state.messageTip=="Error" &&  
                <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                </Col>
            </Row>
            </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{backgroundColor: "#f3f3f3"}}>
            <Row > 
            <Col sm="12">
                {this.state.message} 
            </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "#f3f3f3"}}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff"}} onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></Button>
        </Modal.Footer>
        </Modal>

    </Form>}
    
</div>
)
} 
}

const mapStateToProps = () => {
    return {
  
    }
  }

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MNDTableSettings)