/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import DatePicker from 'react-date-picker';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { handleRequest } from '../../redux/config/env';
import memoize from 'memoize-one';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { IconButton  } from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { COUNTRIES } from '../settings/JsonDatas/Datas';
import { API_BASE } from '../../redux/config/env';
import { Chips } from 'primereact/chips';
import { OrganizationChart } from 'primereact/organizationchart';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { Switch, Space, InputNumber } from 'antd';
import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Row as RowAnt } from 'antd';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const { Option } = Select;

const sortIcon = <ArrowDownward />;
const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton>
);


function phones(value) {

    console.log(value);
    var rowData = value;

    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.phones !== null && rowData.phones !== undefined) {

        rowData.phones.forEach(e => {

            result.push(e.phone);

        });

    }

    return result.toString();
};

function faxes(value) {

    console.log(value);
    var rowData = value;

    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.faxes !== null && rowData.faxes !== undefined) {

        rowData.faxes.forEach(e => {

            result.push(e.fax);

        });

    }

    console.log(result);
    return result.toString();
};

function mails(value) {

    console.log(value);
    var rowData = value;

    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.mails !== null && rowData.mails !== undefined) {

        rowData.mails.forEach(e => {

            result.push(e.mail);

        });

    }

    return result.toString();
};

function socials(value) {

    console.log(value);
    var rowData = value;

    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.socials !== null && rowData.socials !== undefined) {

        rowData.socials.forEach(e => {

            if (e.socialAccount !== null)
                result.push(e.socialAccount);

        });

    }

    return result.toString();
};

function products(row) {

    var result = [];
    if (row !== null && row !== undefined && row.products !== null && row.products !== undefined) {

        console.log(row.products);
        row.products.forEach(e => {

            result.push(e.product.productName);

        });

    }

    return result.toString();

}

function getPrice(row) {

    var result = '';
    if (row !== null && row !== undefined && row.price !== null && row.currencyCode !== null && row.currencyCode !== undefined) {


        result = row.price + " " + row.currencyCode.name;

    }

    return result;
}


const columnsAbout = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "About",
        selector: "about",
        sortable: true
    },
    {
        name: "Mission",
        selector: "mision",
        sortable: true,

    },
    {
        name: 'Vission',
        selector: 'vision',
        sortable: true,

    }
]);

const columnsContact = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Location",
        selector: "location",
        sortable: true,
        //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Address",
        selector: "address",
        sortable: true,
        //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Web",
        selector: "web",
        sortable: true,
        //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Phones",
        cell: row => phones(row),
        sortable: true
    },
    {
        name: "Fax",
        cell: row => faxes(row),
        sortable: true
    },
    {
        name: "E-Mail",
        cell: row => mails(row),
        sortable: true,

    },
    {
        name: 'Social',
        cell: row => socials(row),
        sortable: true,

    }
]);

const columnsCertificates = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Name of Certificate",
        selector: "certificate",
        sortable: true,
        //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Validity",
        selector: "validity",
        sortable: true
    },
    {
        name: "Responsible Department",
        selector: "responsibleDepartment",
        sortable: true
    }
]);

const columnsProjects = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Full name of Project",
        selector: "fullname",
        sortable: true
    },
    {
        name: "Short name of Project",
        selector: "shortname",
        sortable: true,

    },
    {
        name: 'Company Project Code',
        selector: 'companyprojectcode',
        sortable: true,

    },
    {
        name: 'Project\'s Client',
        selector: 'client',
        sortable: true,

    },
    {
        name: 'Contract No',
        selector: 'contractno',
        sortable: true,

    },
    {
        name: 'Address',
        selector: 'address',
        sortable: true,

    },
    {
        name: 'Country',
        selector: 'country',
        sortable: true,

    },
    {
        name: 'Software Products',
        cell: row => products(row),
        sortable: true,

    }
]);

const columnsServices = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Product/Service",
        selector: "productServiceType",
        sortable: true
    },
    {
        name: "Code",
        selector: "code",
        sortable: true,

    },
    {
        name: 'Sketch',
        selector: row => {
            if(Boolean(row.itemProduct) && Boolean(row.itemProduct.itemMaterialEquipment) && Boolean(row.itemProduct.itemMaterialEquipment.sketchPath))
            return  <div
            style={{
                cursor: "pointer"
            }}
            onClick={() =>
                this.setState({
                    imageUrl: row.itemProduct.itemMaterialEquipment.sketchPath,
                    modalPhotoShow: true, 
                })
            }
        >
             <Avatar shape="square" size={100} src={row.itemProduct.itemMaterialEquipment.sketchPath} /> </div>;
            else return "";
        },
        sortable: true,

    },
    {
        name: 'Description',
        selector: 'description',
        sortable: true,

    },
    {
        name: 'Dimensions',
        selector: row => {
            let result = [];

            if(Boolean(row.itemProduct) && Boolean(row.itemProduct.itemProductDimensions)  && row.itemProduct.itemProductDimensions.length>0 && Boolean(row.itemProduct.itemMaterialEquipment) && Boolean(row.itemProduct.itemMaterialEquipment.itemDimensions) && row.itemProduct.itemMaterialEquipment.itemDimensions.length>0)
            {   row.itemProduct.itemMaterialEquipment.itemDimensions.forEach(element => {
               let obj =  row.itemProduct.itemProductDimensions.find(p=>p.dimensionId===element.dimensionId);
               if(Boolean(obj) && Boolean(element.dimension))
               {    let s = element.dimension.description + " : " + obj.maxValue + " - " + obj.minValue + " " + (Boolean(element.unit)? element.unit.name : '') ;
                    result.push(s)} 
            });

            }

            return result.toString(); 

        },
        sortable: true,

    },
    {
        name: 'Unit',
        selector: 'unitCode.name',
        sortable: true,

    },
    {
        name: 'Plant Price',
        selector: row => getPrice(row),
        sortable: true,
    }
]);

export class CompaniesDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            certificatesList: [],
            contactList: [],
            aboutList: [],
            projectsList: [],
            modalAboutShow: false,
            modalContactShow: false,
            modalCertificatesShow: false,
            modalConfirmShow: false,
            modalProjectsShow: false,

            modalPhotoShow:false,
            imageUrl:'',

            projectId: 0,
            projectsFullName: "",
            projectsShortName: "",
            companyProjectCode: "",
            projectsClient: "",
            projectsContractNo: "",
            projectsCountry: "",
            projectsCity: "",
            projectsAddress: "",

            aboutId: 0,
            companyId: 0,
            companyLogoPath: "",
            companyFullName: "",
            companyShortName: "",
            aboutCompanyName: "",
            aboutCompanyText: "",
            missionName: "",
            missionText: "",
            visionName: "",
            visionText: "",

            contactId: 0,
            location: "Select",
            address: "",

            web: "",
            oldContact: {},
            phone: [],
            fax: [],
            email: [],
            social: [],

            certificatesId: 0,
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            cities: [
                { name: 'London', id: '1' },
                { name: 'Istanbul', id: '2' },
                { name: 'New York', id: '3' }
            ],
            orgChartNodes: [{
                label: '',
                className: "",
                expanded: true,
                children: []
            }],
            date: new Date(),
            userList: [],
            projectManager: "",
            projectManagerId: 0,

            serviceList: [],
            serviceId: 0,
            productServiceType: "Product",
            code: "",
            description: "",
            unit: '',
            unitCodes: [],
            price: '',
            currency: '',
            currencyCodes: [],
            itemProductId:null,

            newCurrency: "",
            newUnit: "",


            markerPosition: { lat: 26.2844367, lng: 50.1888217 },
            
     columnsServices : memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Product/Service",
        selector: "productServiceType",
        sortable: true
    },
    {
        name: "Code",
        selector: "code",
        sortable: true,

    },
    {
        name: 'Sketch',
        selector: row => {
            if(Boolean(row.itemProduct) && Boolean(row.itemProduct.itemMaterialEquipment) && Boolean(row.itemProduct.itemMaterialEquipment.sketchPath))
            return  <div
            style={{
                cursor: "pointer"
            }}
            onClick={() =>
                this.setState({
                    imageUrl: row.itemProduct.itemMaterialEquipment.sketchPath,
                    modalPhotoShow: true, 
                })
            }
        >
             <Avatar shape="square" size={100} src={row.itemProduct.itemMaterialEquipment.sketchPath} /> </div>;
            else return "";
        },
        sortable: true,

    },
    {
        name: 'Description',
        selector: 'description',
        sortable: true,

    },
    {
        name: 'Dimensions',
        selector: row => {
            let result = [];

            if(Boolean(row.itemProduct) && Boolean(row.itemProduct.itemProductDimensions)  && row.itemProduct.itemProductDimensions.length>0 && Boolean(row.itemProduct.itemMaterialEquipment) && Boolean(row.itemProduct.itemMaterialEquipment.itemDimensions) && row.itemProduct.itemMaterialEquipment.itemDimensions.length>0)
            {   row.itemProduct.itemMaterialEquipment.itemDimensions.forEach(element => {
               let obj =  row.itemProduct.itemProductDimensions.find(p=>p.dimensionId===element.dimensionId);
               if(Boolean(obj) && Boolean(element.dimension))
               {    let s = element.dimension.description + " : " + obj.maxValue + " - " + obj.minValue + " " + (Boolean(element.unit)? element.unit.name : '') ;
                    result.push(s)} 
            });

            }

            return result.toString(); 

        },
        sortable: true,

    },
    {
        name: 'Unit',
        selector: 'unitCode.name',
        sortable: true,

    },
    {
        name: 'Plant Price',
        selector: row => getPrice(row),
        sortable: true,
    }
])
        };

        // #region tags functions

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        // #endregion tags functions
        //  this.restartAboutTable();
        this.fillAboutCompany();
        this.restartContactTable();
        this.restartCertificatesTable();
        this.restartProjetsTable();

        this.fillCodes();
        this.fillServiceList();
        // #endregion tags functions
    }

    // #region tags
    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    componentDidMount = async () => {


        const responseUser = await handleRequest("GET", "/User/getCompanyItems");



        if (responseUser.data.length !== 0) {


            var filteredUsers = responseUser.data.filter(p => p.staffId > 0);

            const options = filteredUsers.map(function (row) {
                return { name: row.staff.fullName, id: row.id.toString() }
            })

            this.setState({
                userList: options,
            });

        }

        const responseTree = await handleRequest("GET", "/Department/getTreeModel");

        console.log(responseTree);
        if (responseTree.data !== null && responseTree.data !== undefined && responseTree.data.cascader != null && responseTree.data.cascader !== undefined && responseTree.data.cascader.length > 0) {
            this.setState({
                orgChartNodes: responseTree.data.cascader
            });


        }


    }



    fillAboutCompany = async () => {

        const response = await handleRequest("GET", "/Company/getCompanyByUser");

        console.log(response.data);
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                companyId: response.data.id,
                companyFullName: response.data.fullName,
                companyShortName: response.data.shortName,
                aboutCompanyText: response.data.about == null ? "" : response.data.about,
                missionText: response.data.mision == null ? "" : response.data.mision,
                visionText: response.data.vision == null ? "" : response.data.vision,


            });
        }
    }

    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/unit");

        console.log(response.data);
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                unitCodes: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/currency");

        console.log(response.data);
        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                currencyCodes: response2.data
            });
        }
    }

    restartAboutTable = async () => {

        const response = await handleRequest("GET", "/CompanyAbout/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                aboutList: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                aboutList: response.data
            });
        }
    }

    addAbout = () => {
        console.log("About Geldi");
        this.setState({
            aboutId: 0,
            aboutCompanyName: "",
            aboutCompanyText: "",
            missionName: "",
            missionText: "",
            visionName: "",
            visionText: "",
            modalAboutShow: true
        });
    }

    saveAbout = async () => {
        if (this.state.aboutCompanyText.trim() !== "") {



            const newItem = {
                id: this.state.companyId,
                fullName: this.state.companyFullName,
                shortName: this.state.companyShortName,
                logoPath: this.state.companyLogoPath,
                about: this.state.aboutCompanyText,
                mision: this.state.missionText,
                vision: this.state.visionText
            }

            // if (newItem.id === 0)
            //     await await handleRequest("POST", "/CompanyAbout/add", newItem);
            // else 
            await await handleRequest("POST", "/Company/update2", newItem);


            this.showSuccess();
        } else {
            this.showError();
        }
    };

    saveService = async () => {

        if (this.state.description.trim() !== "") {
            const newItem = {
                id: this.state.serviceId,
                productServiceType: this.state.productServiceType,
                code: this.state.code,
                description: this.state.description,
                unit: this.state.unit,
                price: this.state.price,
                currency: this.state.currency,
                itemProductId:this.state.itemProductId
            }

            console.log(newItem); 

            if (newItem.id === 0)
                 await handleRequest("POST", "/CompanyService/add", newItem);
            else
                 await handleRequest("POST", "/CompanyService/update", newItem);
            this.showSuccess();
            this.resetServiceInputs();
            this.fillServiceList();
        } else {
            this.showError();
        }
    };

    resetServiceInputs = () => {
        this.setState({

            serviceId: 0,
            productServiceType: "",
            code: "",
            description: "",
            unit: '',
            price: '',
            currency: '',
            itemProductId:null,

        });
    };

    fillServiceList = async () => {

        const response = await handleRequest("GET", "/CompanyService/getAllByCompany");

        console.log(response.data);
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                serviceList: response.data
            });
        }
    }

    editService = (row) => {
        console.log(row);

        this.setState({
            serviceId: row.id,
            productServiceType: row.productServiceType,
            itemProductId:row.itemProductId,
            code: row.code,
            description: row.description,
            unit: row.unit,
            price: row.price,
            currency: row.currency
        });
    }

    deleteServiceModal = (row) => {

        this.setState({
            serviceId: row.id,
            modalConfirmShow: true
        });
    }

    onChangeProductServiceType = (value) => {
        var result = "Product";
        if (!value)
            result = "Service";
        this.setState({ productServiceType: result });

    }

    onNewUnitChange = event => {
        this.setState({
            newUnit: event.target.value,
        });
    };


    onNewCurrencyChange = event => {
        this.setState({
            newCurrency: event.target.value,
        });
    };

    addUnitItem = async () => {
        const { newUnit } = this.state;


        if (newUnit.length > 0) {

            const newItem = {
                name: newUnit,
                type: "unit"
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                newUnit: '',
            });

            this.fillCodes();
        }

    };

    addCurrencyItem = async () => {
        const { newCurrency } = this.state;


        if (newCurrency.length > 0) {
            const newItem = {
                name: newCurrency,
                type: "currency"
            }

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newCurrency: '',
            });

            this.fillCodes();
        }

    };



    resetAboutInputs = () => {
        this.setState({
            aboutId: 0,
            aboutCompanyText: "",
            missionText: "",
            visionText: "",
            modalAboutShow: false,
            modalConfirmShow: false
        });
    };

    editAbout = (row) => {
        console.log(row);
        this.setState({
            aboutId: row.id,
            aboutCompanyText: row.about,
            visionText: row.vision,
            missionText: row.mision,
            modalAboutShow: true,

        });
    }

    deleteAboutModal = (row) => {

        this.setState({
            aboutId: row.id,
            modalConfirmShow: true
        });
    }

    restartContactTable = async () => {

        const response = await handleRequest("GET", "/CompanyContact/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                contactList: []

            });
        }

        if (response.data.length !== 0) {
            this.setState({
                contactList: response.data
            });
        }
    }

    addContact = () => {
        console.log("Geldi");
        this.setState({

            contactId: 0,
            location: "Select",
            address: "",
            web: "",

            phone: [],
            fax: [],
            email: [],
            social: [],
            modalContactShow: true


        });
    }

    saveContact = async () => {
        if (this.state.location.trim() !== "Select") {

            var _phoneList = [];

            this.state.phone.forEach(element => {
                const item = { phone: element };
                _phoneList.push(item);
            });

            var _faxList = [];

            this.state.fax.forEach(element => {
                const item = { fax: element };
                _faxList.push(item);
            });

            var _mailList = [];

            this.state.email.forEach(element => {
                const item = { mail: element };
                _mailList.push(item);
            });

            var _socialList = [];

            this.state.social.forEach(element => {
                const item = { socialAccount: element };
                _socialList.push(item);
            });


            const newItem = {
                id: this.state.contactId,
                location: this.state.location,
                address: this.state.address,
                phones: _phoneList,
                faxes: _faxList,
                web: this.state.web,
                mails: _mailList,
                socials: _socialList,
                locationCenterLat: this.state.markerPosition.lat.toString(),
                locationCenterLng: this.state.markerPosition.lng.toString(),
                oldCompanyContact: this.state.oldContact
            }

            if (newItem.id === 0)
                await await handleRequest("POST", "/CompanyContact/add", newItem);
            else await await handleRequest("POST", "/CompanyContact/update", newItem);

            this.resetContactInputs();
            this.restartContactTable();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetContactInputs = () => {
        this.setState({
            contactId: 0,
            location: "Select",
            address: "",
            phone: "",
            fax: "",
            web: "",
            email: "",
            social: "",
            modalContactShow: false,
            modalConfirmShow: false
        });
    };


    editContact = (row) => {

        console.log(row);

        var _phones = [];
        var _faxes = [];
        var _mails = [];
        var _socials = [];

        if (row.phones !== null && row.phones !== undefined)
            row.phones.forEach(element => {
                _phones.push(element.phone);
            });

        if (row.faxes !== null && row.faxes !== undefined)
            row.faxes.forEach(element => {
                _faxes.push(element.fax);
            });

        if (row.mails !== null && row.mails !== undefined)
            row.mails.forEach(element => {
                _mails.push(element.mail);
            });

        if (row.socials !== null && row.socials !== undefined)
            row.socials.forEach(element => {
                _socials.push(element.socialAccount);
            });

        let flagLocationCenterLat;
        let flagLocationCenterLng;

        if (row.locationCenterLat !== null && row.locationCenterLat !== undefined
            && row.locationCenterLng !== null && row.locationCenterLng !== undefined) {
            flagLocationCenterLat = parseFloat(row.locationCenterLat);
            flagLocationCenterLng = parseFloat(row.locationCenterLng);

            this.setState({
                markerPosition: { lat: flagLocationCenterLat, lng: flagLocationCenterLng }
            });
        }

        this.setState({
            contactId: row.id,
            location: row.location,
            address: row.address,
            phone: _phones,
            fax: _faxes,
            web: row.web,
            email: _mails,
            social: _socials,
            modalContactShow: true,
            oldContact: row
        });
    }

    deleteContactModal = (row) => {

        this.setState({
            contactId: row.id,
            modalConfirmShow: true
        });
    }

    delete = async () => {

        if (this.state.aboutId > 0) {

            const deletedItem = {
                id: this.state.aboutId

            }

            await handleRequest("POST", "/CompanyAbout/delete", deletedItem);

            this.restartAboutTable();
            this.resetAboutInputs();
            this.showSuccess();
        }

        if (this.state.contactId > 0) {

            const deletedItem = {
                id: this.state.contactId

            }

            await handleRequest("POST", "/CompanyContact/delete", deletedItem);

            this.restartContactTable();
            this.resetContactInputs();
            this.showSuccess();
        }

        if (this.state.certificatesId > 0) {

            const deletedItem = {
                id: this.state.certificatesId

            }

            await handleRequest("POST", "/CompanyCertificates/delete", deletedItem);

            this.restartCertificatesTable();
            this.resetCertificatesInputs();
            this.showSuccess();
        }

        if (this.state.projectsId > 0) {

            const deletedItem = {
                id: this.state.projectsId

            }

            await handleRequest("POST", "/CompanyProjects/delete", deletedItem);

            this.restartProjetsTable();
            this.resetProjetsInputs();
            this.showSuccess();
        }

        if (this.state.serviceId > 0) {

            const deletedItem = {
                id: this.state.serviceId

            }

            await handleRequest("POST", "/CompanyService/delete", deletedItem);

            this.fillServiceList();
            this.resetServiceInputs();
            this.showSuccess();
        }


    }

    restartCertificatesTable = async () => {

        const response = await handleRequest("GET", "/CompanyCertificates/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                certificatesList: []

            });
        }

        if (response.data.length !== 0) {
            this.setState({
                certificatesList: response.data
            });
        }
    }

    addCertificates = () => {

        this.setState({

            certificatesId: 0,
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
            modalCertificatesShow: true


        });
    }

    saveCertificates = async () => {

        if (this.state.nameOfCertificate.trim() !== "Select") {

            const newItem = {
                id: this.state.certificatesId,
                certificate: this.state.nameOfCertificate,
                validity: this.state.date,
                responsibleDepartment: this.state.respDepartment

            }

            if (newItem.id === 0)
                await await handleRequest("POST", "/CompanyCertificates/add", newItem);
            else await await handleRequest("POST", "/CompanyCertificates/update", newItem);

            this.resetCertificatesInputs();
            this.restartCertificatesTable();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetCertificatesInputs = () => {
        this.setState({
            certificatesId: 0,
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
            modalCertificatesShow: false,
            modalConfirmShow: false

        });
    };

    editCertificates = (row) => {

        this.setState({

            certificatesId: row.id,
            nameOfCertificate: row.certificate,
            date: new Date(row.validity),
            respDepartment: row.responsibleDepartment,
            modalCertificatesShow: true

        });
    }

    deleteCertificatesModal = (row) => {

        this.setState({
            contactId: row.id,
            modalConfirmShow: true
        });
    }

    restartProjetsTable = async () => {

        const response = await handleRequest("GET", "/CompanyProjects/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                projectsList: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                projectsList: response.data
            });
        }
    }

    addProjects = () => {
        console.log("project Geldi");
        this.setState({
            projectId: 0,
            projectsFullName: "",
            projectsShortName: "",
            companyProjectCode: "",
            projectsClient: "",
            projectsContractNo: "",
            projectsCountry: "",
            projectsCity: "",
            projectsAddress: "",
            modalProjectsShow: true
        });
    }

    saveProjects = async () => {
        if (this.state.projectsFullName.trim() !== "") {

            const newItem = {
                id: this.state.projectId,
                fullname: this.state.projectsFullName,
                shortname: this.state.projectsShortName,
                companyprojectcode: this.state.companyProjectCode,
                client: this.state.projectsClient,
                contractno: this.state.projectsContractNo,
                country: this.state.projectsCountry,
                city: this.state.projectsCity,
                address: this.state.projectsAddress,
                projectManagerId: this.state.projectManagerId,
                locationCenterLat: this.state.markerPosition.lat.toString(),
                locationCenterLng: this.state.markerPosition.lng.toString(),
            }

            if (newItem.id === 0)
                await await handleRequest("POST", "/CompanyProjects/add", newItem);
            else await await handleRequest("POST", "/CompanyProjects/update", newItem);

            this.resetProjectsInputs();
            this.restartProjetsTable();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetProjectsInputs = () => {
        this.setState({
            projectId: 0,
            projectsFullName: "",
            projectsShortName: "",
            companyProjectCode: "",
            projectsClient: "",
            projectsContractNo: "",
            projectsCountry: "",
            projectsCity: "",
            projectsAddress: "",
            modalProjectsShow: false,
            modalConfirmShow: false
        });
    };



    editProjects = (row) => {
        console.log(row);

        let flagLocationCenterLat;
        let flagLocationCenterLng;

        if (row.locationCenterLat !== null && row.locationCenterLat !== undefined
            && row.locationCenterLng !== null && row.locationCenterLng !== undefined) {
            flagLocationCenterLat = parseFloat(row.locationCenterLat);
            flagLocationCenterLng = parseFloat(row.locationCenterLng);

            this.setState({
                markerPosition: { lat: flagLocationCenterLat, lng: flagLocationCenterLng }
            });
        }

        this.setState({
            projectId: row.id,
            projectsFullName: row.fullname,
            projectsShortName: row.shortname,
            companyProjectCode: row.companyprojectcode,
            projectsClient: row.client,
            projectsCountry: row.country,
            projectsContractNo: row.contractno,
            projectsCity: row.city,
            modalProjectsShow: true
        });
    }

    deleteProjectsModal = (row) => {

        this.setState({
            projectsId: row.id,
            modalConfirmShow: true
        });
    }

    handleInputChangeP(evt) {
        this.setState({ inputP: evt.target.value });
    }

    handleInputKeyDownP(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                phones: [...state.phones, value],
                inputP: ''
            }));
        }

        if (this.state.phones.length && evt.keyCode === 8 && !this.state.inputP.length) {
            this.setState(state => ({
                phones: state.phones.slice(0, state.phones.length - 1)
            }));
        }
    }

    handleRemoveItemP(index) {
        return () => {
            this.setState(state => ({
                phones: state.phones.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeF(evt) {
        this.setState({ inputF: evt.target.value });
    }

    handleInputKeyDownF(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                faxes: [...state.faxes, value],
                inputF: ''
            }));
        }

        if (this.state.faxes.length && evt.keyCode === 8 && !this.state.inputF.length) {
            this.setState(state => ({
                faxes: state.faxes.slice(0, state.faxes.length - 1)
            }));
        }
    }

    handleRemoveItemF(index) {
        return () => {
            this.setState(state => ({
                faxes: state.faxes.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeW(evt) {
        this.setState({ inputW: evt.target.value });
    }

    handleInputKeyDownW(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                webs: [...state.webs, value],
                inputW: ''
            }));
        }

        if (this.state.webs.length && evt.keyCode === 8 && !this.state.inputW.length) {
            this.setState(state => ({
                webs: state.webs.slice(0, state.webs.length - 1)
            }));
        }
    }

    handleRemoveItemW(index) {
        return () => {
            this.setState(state => ({
                webs: state.webs.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeE(evt) {
        this.setState({ inputE: evt.target.value });
    }

    handleInputKeyDownE(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                emails: [...state.emails, value],
                inputE: ''
            }));
        }

        if (this.state.emails.length && evt.keyCode === 8 && !this.state.inputE.length) {
            this.setState(state => ({
                emails: state.emails.slice(0, state.emails.length - 1)
            }));
        }
    }

    handleRemoveItemE(index) {
        return () => {
            this.setState(state => ({
                emails: state.emails.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeS(evt) {
        this.setState({ inputS: evt.target.value });
    }

    handleInputKeyDownS(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                socials: [...state.socials, value],
                inputS: ''
            }));
        }

        if (this.state.socials.length && evt.keyCode === 8 && !this.state.inputS.length) {
            this.setState(state => ({
                socials: state.socials.slice(0, state.socials.length - 1)
            }));
        }
    }

    handleRemoveItemS(index) {
        return () => {
            this.setState(state => ({
                socials: state.socials.filter((item, i) => i !== index)
            }));
        }
    }

    // #endregion tags

    onChange = date => this.setState({ date })

    showOrHideConfirmModal = () => {
        this.setState({
            modalConfirmShow: !this.state.modalConfirmShow
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    showOrHideAboutModal = () => {
        this.setState({
            modalAboutShow: !this.state.modalAboutShow
        });
        this.resetContactInputs();
    }

    showOrHideContactModal = () => {
        this.setState({
            modalContactShow: !this.state.modalContactShow
        });
        this.resetContactInputs();
    }

    showOrHideCertificatesModal = () => {
        this.setState({
            modalCertificatesShow: !this.state.modalCertificatesShow
        });
        this.resetCertificatesInputs();

    }

    showOrHideProjectsModal = () => {
        this.setState({
            modalProjectsShow: !this.state.modalProjectsShow
        });
        this.resetProjectsInputs();

    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeProjectManager = e => {




        this.setState({
            [e.target.name]: e.target.value,
            projectManagerId: e.target.value
        });

    }

    onEditClick(e) {
        if (e.target.value === undefined)
            e.stopPropagation();
    }

    companyLogoChange = e => {
        this.setState({
            companyLogoName: e.target.files[0].name
        });
    }

    aboutCompanyChange = e => {
        this.setState({
            aboutCompanyName: e.target.files[0].name
        });
    }

    missionChange = e => {
        this.setState({
            missionName: e.target.files[0].name
        });
    }

    visionChange = e => {
        this.setState({
            visionName: e.target.files[0].name
        });
    }

    certificateChange = e => {
        this.setState({
            certificate: e.target.files[0].name
        });
    }

    onMarkerPositionChanged = (e) => {
        //console.log(e.latLng.lat());
        this.setState({
            markerPosition: { lat: e.latLng.lat(), lng: e.latLng.lng() }
        });
    }

    render() {

        const mapContainerStyle = {
            height: "300px",
            width: "100%"
        };

        let optionsCountry = COUNTRIES.map((data) =>
            <option
                key={data.value}
                value={data.name}
            >
                {data.name}
            </option>
        );
        let optionsCity = this.state.cities.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsProjectManager = this.state.userList.map((data) =>
            <option
                key={data.id}
                value={data.id}
            >
                {data.name}
            </option>
        );

        return (
            <div style={{ padding: "15px" }} className="row">
                <Toast ref={(el) => this.toast = el} />

                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>

                        {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                        <Modal
                            show={this.state.modalConfirmShow}
                            onHide={this.showOrHideConfirmModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Delete </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>All informations about selected item will be deleted. Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.showOrHideConfirmModal}>Close</Button>
                                <Button variant="danger" onClick={this.delete} >Delete</Button>
                            </Modal.Footer>
                        </Modal>

                    </Row>
                </Container>
                <Modal
                        show={this.state.modalPhotoShow}
                        onHide={() => {
                            this.setState({
                                modalPhotoShow: false
                            })
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Boolean(this.state.imageUrl) && this.state.imageUrl !== "" ? (
                                <img
                                    src={this.state.imageUrl}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                    <p>Photo has not been uploaded.</p>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={() => {
                                this.setState({
                                    modalPhotoShow: false
                                })
                            }} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                <div className="col-md-12">
                    <Accordion defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                <Row >
                                    <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">About Company</Card.Header></Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse id="aboutAccordion" eventKey="0">
                                <Card.Body>
                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                Company's Full Name:
                                                    </Form.Label>
                                            <Col sm="4">
                                                <Form.Control name="companyFullName" value={this.state.companyFullName}
                                                    onChange={this.handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                Company's Short Name:
                                                    </Form.Label>
                                            <Col sm="4">
                                                <Form.Control name="companyShortName" value={this.state.companyShortName}
                                                    onChange={this.handleChange} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyLogo">
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                Company Logo:
                                                 </Form.Label>
                                            <Col sm="4">
                                                {this.state.logoPath !== null ? <Avatar shape="square" size={64} src={`${API_BASE}/Company/download/` + this.state.companyId} /> : ""}
                                            </Col>

                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlAboutCompany">
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                About Company:
                                            </Form.Label>
                                            <Col sm="4">
                                                <Form.Control as="textarea" rows="3" value={this.state.aboutCompanyText} onChange={(e) => this.setState({ aboutCompanyText: e.target.value })}
                                                />
                                            </Col>

                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlMission">
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                Mission:
                                            </Form.Label>
                                            <Col sm="4">
                                                <Form.Control as="textarea" rows="3" value={this.state.missionText} onChange={(e) => this.setState({ missionText: e.target.value })}
                                                />
                                            </Col>

                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                Vision:
                                            </Form.Label>
                                            <Col sm="4">
                                                <Form.Control as="textarea" rows="3" value={this.state.visionText} onChange={(e) => this.setState({ visionText: e.target.value })}
                                                />
                                            </Col>

                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="saveAbout">
                                            <Form.Label style={{ color: 'black' }} column sm="3">

                                            </Form.Label>

                                            <Col sm="4">
                                                <Button variant="info" onClick={this.saveAbout} >Save</Button>
                                            </Col>


                                        </Form.Group>



                                    </Container>


                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={12}>

                                                <Modal
                                                    show={this.state.modalAboutShow}
                                                    onHide={this.showOrHideAboutModal}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    size="xl"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">Company About Detail</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>

                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlAboutCompany">
                                                                <Form.Label style={{ color: 'black' }} column sm="3">
                                                                    About Company:
                                            </Form.Label>
                                                                <Col sm="4">
                                                                    <Form.Control as="textarea" rows="3" value={this.state.aboutCompanyText} onChange={(e) => this.setState({ aboutCompanyText: e.target.value })}
                                                                    />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                id='customFile'
                                                                                onChange={this.aboutCompanyChange}
                                                                            />
                                                                            <label className='custom-file-label' htmlFor='customFile'>
                                                                                {this.state.aboutCompanyName}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlMission">
                                                                <Form.Label style={{ color: 'black' }} column sm="3">
                                                                    Mission:
                                            </Form.Label>
                                                                <Col sm="4">
                                                                    <Form.Control as="textarea" rows="3" value={this.state.missionText} onChange={(e) => this.setState({ missionText: e.target.value })}
                                                                    />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                id='customFile'
                                                                                onChange={this.missionChange}
                                                                            />
                                                                            <label className='custom-file-label' htmlFor='customFile'>
                                                                                {this.state.missionName}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">
                                                                <Form.Label style={{ color: 'black' }} column sm="3">
                                                                    Vision:
                                            </Form.Label>
                                                                <Col sm="4">
                                                                    <Form.Control as="textarea" rows="3" value={this.state.visionText} onChange={(e) => this.setState({ visionText: e.target.value })}
                                                                    />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                id='customFile'
                                                                                onChange={this.visionChange}
                                                                            />
                                                                            <label className='custom-file-label' htmlFor='customFile'>
                                                                                {this.state.visionName}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                        </Form>

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={this.showOrHideAboutModal}>Close</Button>
                                                        <Button variant="info" onClick={this.saveAbout} >Save</Button>
                                                    </Modal.Footer>
                                                </Modal></Col>


                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <br />
                    <Accordion defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                <Row >
                                    <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Contact Info</Card.Header></Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse id="contactAccordion" eventKey="0">
                                <Card.Body>
                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={10}></Col>
                                            <Col xs={2}><Button onClick={this.addContact} style={{ width: '100%' }} variant="info">Add New</Button></Col>
                                        </Row>

                                    </Container>
                                    <DataTable style={{ marginTop: '3rem' }}
                                        noHeader
                                        columns={columnsContact(this.deleteContactModal, this.editContact)}
                                        data={this.state.contactList}
                                        sortIcon={sortIcon}
                                        pagination
                                        persistTableHead
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        // subHeader
                                        // subHeaderComponent={
                                        //     (
                                        //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                        //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                                        //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                                        //             <Icon1 style={{ margin: '5px' }} color="action" />
                                        //             <Icon2 style={{ margin: '5px' }} color="action" />
                                        //             <Icon3 style={{ margin: '5px' }} color="action" />
                                        //         </div>
                                        //     )
                                        // }
                                        actions={actions}
                                        onRowClicked={this.handleRowClicked}
                                        onSelectedRowsChange={this.handleChangeRow}
                                    />

                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={12}>

                                                <Modal
                                                    show={this.state.modalContactShow}
                                                    onHide={this.showOrHideContactModal}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    size="xl"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">Company Contact Detail</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>

                                                            <Form.Group as={Row} controlId="formGridLocation">
                                                                <Col sm="2">

                                                                    <Form.Label style={{ color: 'black' }}  >Location</Form.Label>

                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="location" value={this.state.location}
                                                                        onChange={this.handleChange} as="select">
                                                                        <option>Select</option>
                                                                        <option>Head Office</option>
                                                                        <option>Branche</option>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridAddress">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}  >Address</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control as="textarea" rows="3" name="address" value={this.state.address}
                                                                        onChange={this.handleChange} />
                                                                </Col>

                                                            </Form.Group>

                                                            <Form.Group as={Row} controlId="formGridLocationCenter">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Location Center</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <LoadScript
                                                                        id="script-loader"
                                                                        googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc"
                                                                    >
                                                                        <GoogleMap
                                                                            id="marker-example"
                                                                            mapContainerStyle={mapContainerStyle}
                                                                            zoom={8}
                                                                            center={this.state.markerPosition}
                                                                        >
                                                                            <Marker
                                                                                position={this.state.markerPosition}
                                                                                draggable={true}
                                                                                onDragEnd={this.onMarkerPositionChanged}
                                                                            />
                                                                        </GoogleMap>
                                                                    </LoadScript>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} controlId="formGridPhone">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Phone</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className="content-section implementation p-fluid">
                                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.phone} onChange={(e) => this.setState({ phone: e.value })}></Chips>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridFax">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Fax</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className="content-section implementation p-fluid">
                                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.fax} onChange={(e) => this.setState({ fax: e.value })}></Chips>

                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridWeb">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Web</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control value={this.state.web} onChange={(e) => this.setState({ web: e.target.value })}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridEmail">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>E-Mail</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className="content-section implementation p-fluid">
                                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.email} onChange={(e) => this.setState({ email: e.value })}></Chips>

                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridFax">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Social Accounts</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className="content-section implementation p-fluid">
                                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.social} onChange={(e) => this.setState({ social: e.value })}></Chips>

                                                                    </div>
                                                                </Col>
                                                            </Form.Group>
                                                        </Form>

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={this.showOrHideContactModal}>Close</Button>
                                                        <Button variant="info" onClick={this.saveContact} >Save</Button>
                                                    </Modal.Footer>
                                                </Modal></Col>


                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <br />
                    <Accordion defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                <Row >
                                    <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Certificates</Card.Header></Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse id="certificateAccordion" eventKey="0">
                                <Card.Body>
                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={10}></Col>
                                            <Col xs={2}><Button onClick={this.addCertificates} style={{ width: '100%' }} variant="info">Add New</Button></Col>
                                        </Row>


                                    </Container>

                                    <DataTable style={{ marginTop: '3rem' }}
                                        noHeader
                                        columns={columnsCertificates(this.deleteCertificatesModal, this.editCertificates)}
                                        data={this.state.certificatesList}
                                        sortIcon={sortIcon}
                                        pagination
                                        persistTableHead
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        // subHeader
                                        // subHeaderComponent={
                                        //     (
                                        //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                        //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                                        //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                                        //             <Icon1 style={{ margin: '5px' }} color="action" />
                                        //             <Icon2 style={{ margin: '5px' }} color="action" />
                                        //             <Icon3 style={{ margin: '5px' }} color="action" />
                                        //         </div>
                                        //     )
                                        // }
                                        actions={actions}
                                        onRowClicked={this.handleRowClicked}
                                        onSelectedRowsChange={this.handleChangeRow}
                                    />

                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={12}>

                                                <Modal
                                                    show={this.state.modalCertificatesShow}
                                                    onHide={this.showOrHideCertificatesModal}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    size="xl"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">Company Certificate Detail</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>

                                                            <Form.Group as={Row} controlId="formGridNameOfCertificate">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }} >Name of Certificate</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="nameOfCertificate" value={this.state.nameOfCertificate}
                                                                        onChange={this.handleChange} as="select">
                                                                        <option>Select</option>
                                                                        <option>ISO 9001</option>
                                                                        <option>ISO 14001</option>
                                                                        <option>ISO 18001</option>
                                                                    </Form.Control>
                                                                </Col>

                                                            </Form.Group>


                                                            <Form.Group as={Row} controlId="formGridValidity">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Validity</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Calendar dateFormat="dd/mm/yy" value={this.state.date} showButtonBar={true} onChange={(e) => this.setState({ date: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1950:2025" />
                                                                        {/* <DatePicker
                                    onChange={this.onChange}
                                    value={this.state.date}
                                /> */}

                                                                    </div>
                                                                </Col>
                                                            </Form.Group>


                                                            <Form.Group as={Row} controlId="formGridRespDepartment">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Responsible Department</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="respDepartment" value={this.state.respDepartment}
                                                                        onChange={(e) => this.setState({ respDepartment: e.target.value })} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} controlId="formGridCertificate">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Certificate</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <div className='custom-file'>
                                                                            <input
                                                                                type='file'
                                                                                className='custom-file-input'
                                                                                id='customFile'
                                                                                onChange={this.certificateChange}
                                                                            />
                                                                            <label className='custom-file-label' htmlFor='customFile'>
                                                                                {this.state.certificate}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Form.Group>


                                                        </Form>

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={this.showOrHideCertificatesModal}>Close</Button>
                                                        <Button variant="info" onClick={this.saveCertificates} >Save</Button>
                                                    </Modal.Footer>
                                                </Modal></Col>


                                        </Row>
                                    </Container>
                                </Card.Body>


                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <br />
                    <Accordion defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                <Row>
                                    <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Organization</Card.Header></Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse id="organization" eventKey="0">
                                <Card.Body>
                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                                        <Row>
                                            <Col xs={12}>

                                                <OrganizationChart value={this.state.orgChartNodes} selectionMode="single" selection={this.state.selectedNode} onSelectionChange={event => this.setState({ selectedNode: event.data })}></OrganizationChart>

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xs={3}></Col>
                                            <Col xs={6}>
                                                <Link
                                                    to={{
                                                        pathname: "/Department"
                                                    }}
                                                >     <Button style={{ width: '100%' }} variant="info"> Create Organization Chart</Button>
                                                </Link>
                                            </Col>
                                            <Col xs={3}></Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <br />
                    <Accordion defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                <Row >
                                    <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Projects</Card.Header></Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse id="projectsAccordion" eventKey="0">
                                <Card.Body>

                                    <DataTable style={{ marginTop: '3rem' }}
                                        noHeader
                                        columns={columnsProjects(this.deleteProjectsModal, this.editProjects)}
                                        data={this.state.projectsList}
                                        sortIcon={sortIcon}
                                        pagination
                                        persistTableHead
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        // subHeader
                                        // subHeaderComponent={
                                        //     (
                                        //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                        //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                                        //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                                        //             <Icon1 style={{ margin: '5px' }} color="action" />
                                        //             <Icon2 style={{ margin: '5px' }} color="action" />
                                        //             <Icon3 style={{ margin: '5px' }} color="action" />
                                        //         </div>
                                        //     )
                                        // }
                                        actions={actions}
                                        onRowClicked={this.handleRowClicked}
                                        onSelectedRowsChange={this.handleChangeRow}
                                    />

                                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                        <Row>
                                            <Col xs={12}>

                                                <Modal
                                                    show={this.state.modalProjectsShow}
                                                    onHide={this.showOrHideProjectsModal}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    size="xl"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">Projects Detail</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>
                                                            <Form.Group as={Row} controlId="formGridLocation">
                                                                <Col sm="2">

                                                                    <Form.Label style={{ color: 'black' }}  >Project's Full Name</Form.Label>

                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="projectsFullName" value={this.state.projectsFullName}
                                                                        onChange={this.handleChange} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridAddress">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}  >Project's Short Name</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="projectsShortName" value={this.state.projectsShortName}
                                                                        onChange={this.handleChange} />
                                                                </Col>

                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridPhone">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Company Project Code</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="companyProjectCode" value={this.state.companyProjectCode}
                                                                        onChange={this.handleChange} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridClient">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Client</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="projectsClient" value={this.state.projectsClient}
                                                                        onChange={this.handleChange} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridContractNo">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Contract No</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="projectsContractNo" value={this.state.projectsContractNo}
                                                                        onChange={this.handleChange} />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridLocation">
                                                                <Col sm="2">

                                                                    <Form.Label style={{ color: 'black' }}  >Address</Form.Label>

                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control type="textarea" name="projectsAddress" value={this.state.projectsAddress}
                                                                        onChange={this.handleChange} />
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} controlId="formGridLocationCenter2">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Location Center</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <LoadScript
                                                                        id="script-loader"
                                                                        googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc"
                                                                    >
                                                                        <GoogleMap
                                                                            id="marker-example"
                                                                            mapContainerStyle={mapContainerStyle}
                                                                            zoom={8}
                                                                            center={this.state.markerPosition}
                                                                        >
                                                                            <Marker
                                                                                position={this.state.markerPosition}
                                                                                draggable={true}
                                                                                onDragEnd={this.onMarkerPositionChanged}
                                                                            />
                                                                        </GoogleMap>
                                                                    </LoadScript>
                                                                </Col>
                                                            </Form.Group>

                                                            <Form.Group as={Row} controlId="formGridCountry">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Country</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">

                                                                    <Form.Control name="projectsCountry" value={this.state.projectsCountry}
                                                                        onChange={this.handleChange} as="select">
                                                                        <option value="">
                                                                            Select Country
                                                                         </option>
                                                                        {optionsCountry}
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridCity">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>City</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="projectsCity" value={this.state.projectsCity}
                                                                        onChange={this.handleChange} as="select">
                                                                        <option value="">
                                                                            Select City
                                                                         </option>
                                                                        {optionsCity}
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group as={Row} controlId="formGridCity">
                                                                <Col sm="2">
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        <Form.Label style={{ color: 'black' }}>Project Manager</Form.Label>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Form.Control name="projectManager" value={this.state.projectManager}
                                                                        onChange={this.handleChangeProjectManager} as="select">
                                                                        <option value="">
                                                                            Select Project Manager
                                                                         </option>
                                                                        {optionsProjectManager}
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={this.showOrHideProjectsModal}>Close</Button>
                                                        <Button variant="info" onClick={this.saveProjects} >Save</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <br />
                    <Accordion defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                <Row >
                                    <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Products and Services</Card.Header></Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>

                            </Accordion.Toggle>
                            <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                <Card.Body>
                                    <RowAnt>
                                        <Space size="large" >
                                            <Space direction="vertical">
                                                <div></div>
                                                <Switch checkedChildren="Product" unCheckedChildren="Service" defaultChecked checked={this.state.productServiceType === "Product"} onChange={this.onChangeProductServiceType} />

                                            </Space>
                                            <Space direction="vertical">
                                                <div>Code </div>
                                                <Form.Control name="code" value={this.state.code}
                                                    onChange={this.handleChange} />
                                            </Space>
                                            <Space direction="vertical">
                                                <div>Description </div>
                                                <Form.Control name="description" value={this.state.description}
                                                    onChange={this.handleChange} />
                                            </Space>

                                            <Space direction="vertical">
                                                <div>Unit</div>
                                                <Select
                                                    style={{ width: '180px' }}
                                                    placeholder="Unit"
                                                    value={this.state.unit}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            unit: value
                                                        })
                                                    }}

                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.newUnit} onChange={this.onNewUnitChange} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addUnitItem}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {this.state.unitCodes.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </Space>
                                            <Space direction="vertical">
                                                <div>Plant Price</div>
                                                <div>  <InputNumber min={0} value={this.state.price} onChange={(value) => { this.setState({ price: value }) }} />
                                                    <Select

                                                        style={{ width: '180px' }}
                                                        placeholder="Currency"
                                                        value={this.state.currency}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                currency: value
                                                            })
                                                        }}

                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.newCurrency} onChange={this.onNewCurrencyChange} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCurrencyItem}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.currencyCodes.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>

                                                </div>
                                            </Space>
                                            <Space direction="vertical">
                                                <div></div>
                                                <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.saveService} type="button">Save</Button>

                                            </Space>
                                        </Space>

                                    </RowAnt>

                                    <DataTable style={{ marginTop: '3rem' }}
                                        noHeader
                                        columns={this.state.columnsServices(this.deleteServiceModal, this.editService)}
                                        data={this.state.serviceList}
                                        sortIcon={sortIcon}
                                        pagination
                                        persistTableHead
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        // subHeader
                                        // subHeaderComponent={
                                        //     (
                                        //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                        //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                                        //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                                        //             <Icon1 style={{ margin: '5px' }} color="action" />
                                        //             <Icon2 style={{ margin: '5px' }} color="action" />
                                        //             <Icon3 style={{ margin: '5px' }} color="action" />
                                        //         </div>
                                        //     )
                                        // }
                                        actions={actions}
                                        onRowClicked={this.handleRowClicked}
                                        onSelectedRowsChange={this.handleChangeRow}
                                    />


                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>



                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesDetail)
