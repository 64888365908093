import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { Table, Checkbox as AntCheckbox } from "antd";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Container, Col, Form, Row } from "react-bootstrap";
import { Modal, Button as ButtonBoot } from "react-bootstrap";
import { Column } from "primereact/column";
import "react-toggle/style.css";
import { Toast } from "primereact/toast";
import { handleRequest } from "../../redux/config/env";
import QuestionnaryExpand from "./QuestionnaryExpand";
import moment from "moment";
import CustomMaterialMenu from "../settings/ForComponents/CustomMaterialMenuQuestionnary";
import * as Mnd from "../../partials/content/CustomComponents";
import { Editor } from 'primereact/editor';

const showHeader = true;
const pagination = { position: ["top", "bottom"] };

class Questionnary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bordered: false,
      loading: false,
      pagination,
      size: "small",
      expandable: false,
      title: undefined,
      paginationDisabled: "",
      showHeader,
      rowSelection: undefined,
      scroll: undefined,
      hasData: true,
      tableLayout: undefined,
      top: "topRight",
      bottom: "bottomRight",

      modalShow: false,
      hidden: true,
      cancelhidden: true,
      tablehidden: false,
      createNewButton: "Create New",
      lastButton: "Save",
      addButton: "Add",
      buttonClassName: "p-button-success",
      createNewButtonVariant: "p-button-info",
      displayHiddenDialog: false,
      displayLastButton: true,

      questionnaryId: 0,
      questionnaryDescription: "",
      areaOfUse: "",
      pageFormat: "",
      pageFormats: [],
      dateOfPreparation: "",
      dateOfImplemantation: "",
      dataTableValue: [],

      checkHeader: false,
      checkFooter: false,
      header: '',
      footerText: '',
      derivedQuestionnaryId: 0,
      derivedQuestions: []
    };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }

  showSuccess() {
    this.toast.show({
      severity: "success",
      summary: "Success Message",
      detail: "Successful.",
    });
  }

  showError(msg) {
    if (!msg) {
      msg = "Something went wrong. Please check inputs.";
    }
    this.toast.show({
      severity: "error",
      summary: "Error Message",
      detail: msg,
    });
  }

  componentDidMount = async () => {
    this.getQuestionnary();

    const response = await handleRequest("GET", "/Code/getAll");

    if (response.data.length !== 0) {
      const columns = response.data;

      var options = [];

      for (let index = 0; index < columns.length; index++) {
        if (columns[index].type === "pageFormat") {
          options.push({
            name: columns[index].name.toString(),
            id: columns[index].id.toString(),
          });
        }
      }

      this.setState({
        pageFormats: options, 
      });
    }
  };

  getQuestionnary = async () => {
    const response = await handleRequest("GET", "/Questionnary/getAll");
    console.log("datatableValuexxx", response);
    if (response.data.length !== 0) {
      //moment(period.startDate).format("DD/MM/YYYY HH:mm");
      if (Boolean(response.data)) {
        const data = response.data;
        const list = [];
        data.forEach((element) => {
          const obj = Object.assign({}, element);
          obj.key = element.id;
          if (
            element.dateOfPreparation !== null &&
            element.dateOfPreparation !== ""
          ) {
            obj.dateOfPreparation = moment(element.dateOfPreparation).format(
              "DD/MM/YYYY HH:mm"
            );
          }
          if (
            element.dateOfImplemantation !== null &&
            element.dateOfImplemantation !== ""
          ) {
            obj.dateOfImplemantation = moment(
              element.dateOfImplemantation
            ).format("DD/MM/YYYY HH:mm");
          }
          list.push(obj);
        });

        this.setState({ dataTableValue: list });
      }
    } else {
      this.setState({ dataTableValue: [] });
    }
  };

  createButtonClick = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        cancelhidden: true,
        tablehidden: false,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "p-button-danger",
      });
    } else {
      this.cancelButtonClick();
    }
  };

  cancelButtonClick = () => {
    this.setState({
      hidden: true,
      tablehidden: false,
      addButton: "Add",
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "p-button-info",
      questionnaryId:0,
      derivedQuestions:[],
      derivedQuestionnaryId:0,

    });
  };

  openFlowchartDialog = async () => {
    if (this.state.workFlowName !== "") {
      this.setState({
        displayHiddenDialog: true,
      });
    } else {
    }
  };

  saveQuestionnary = async () => {
    if (
      this.state.addButton === "Add" &&
      this.state.questionnaryDescription !== ""
    ) {
      const newQuestionnary = {
        questionnaryDescription: this.state.questionnaryDescription,
        areaOfUse: this.state.areaOfUse,
        pageFormat: this.state.pageFormat,
        dateOfPreparation: this.state.dateOfPreparation,
        dateOfImplemantation: this.state.dateOfImplemantation,
        header: this.state.header,
        footer: this.state.footerText
      };

      //await axios.post(`${API_BASE}/Discipline/add`, newDiscipline);
      var response = await handleRequest(
        "POST",
        "/Questionnary/add",
        newQuestionnary
      );

      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS-QUESTIONNARY-001") {
          this.changeButtonSave();
          this.getQuestionnary();
          this.showSuccess();
          this.cancelButtonClick();
        } else if (response.data === "ERROR-QUESTIONNARY-001") {
          this.showError(
            "An error was occured while adding please try again later !"
          );
        }
      }
    } else if (
      this.state.addButton === "Update" &&
      this.state.workFlowName !== ""
    ) {
      const updateQuestionnary = {
        questionnaryDescription: this.state.questionnaryDescription,
        areaOfUse: this.state.areaOfUse,
        pageFormat: this.state.pageFormat,
        dateOfPreparation: this.state.dateOfPreparation,
        dateOfImplemantation: this.state.dateOfImplemantation,
        id: this.state.questionnaryId,
        header: this.state.header,
        footer: this.state.footerText
      };

      var response2 = await handleRequest(
        "POST",
        "/Questionnary/update",
        updateQuestionnary
      );

      if (response2.data !== null && response2.data !== undefined) {
        console.log(response2.data);
        //if (response2.data === "SUCCESS-QUESTIONNARY-UPDATE-001") {
        this.changeButtonSave();
        this.getQuestionnary();
        this.showSuccess();
        this.cancelButtonClick();
        //}
        //else if (response2.data === "ERROR-QUESTIONNARY-UPDATE-001") {
        //  this.showError("An error was occured while updating please try again later !");
        //}
      }
    } else if (
      this.state.addButton === "Derive" 
      
    ) {
      const updateQuestionnary = {
        questionnaryDescription: this.state.questionnaryDescription,
        areaOfUse: this.state.areaOfUse,
        pageFormat: this.state.pageFormat,
        dateOfPreparation: this.state.dateOfPreparation,
        dateOfImplemantation: this.state.dateOfImplemantation,       
        header: this.state.header,
        footer: this.state.footerText,
        questions:this.state.derivedQuestions 

      };

       response2 = await handleRequest(
        "POST",
        "/Questionnary/derive", 
        updateQuestionnary
      );

      if (response2.data !== null && response2.data !== undefined) {
        console.log(response2.data);
        //if (response2.data === "SUCCESS-QUESTIONNARY-UPDATE-001") {
        this.changeButtonSave();
        this.getQuestionnary();
        this.showSuccess();
        this.cancelButtonClick();
        //}
        //else if (response2.data === "ERROR-QUESTIONNARY-UPDATE-001") {
        //  this.showError("An error was occured while updating please try again later !");
        //}
      }
    } else {
      this.showError();
    }
  };

  rowExpansionTemplate(data) {
    console.log(data);
    return <QuestionnaryExpand row={data} />;
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleQuestionnaryHide = async () => {
    this.setState({
      displayHiddenDialog: false,
      questionnaryDescription: "",
      areaOfUse: "",
      pageFormat: "",
      dateOfPreparation: "",
      dateOfImplemantation: "",
    });
    this.getQuestionnary();
  };

  actionTemplate(rowData) {
    return (
      <React.Fragment>
        <CustomMaterialMenu
          row={rowData}
          onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.edit.bind(this)}
          onDeriveRow={this.derive.bind(this)}
        />
      </React.Fragment>
    );
  }

  renderTakeQuestionaire = (record) => {

    return <ButtonBoot variant="warning" onClick={() => this.goToPage(record)} > Take Questionaire </ButtonBoot>;
  }

  goToPage = (record) => {
    window.location.href = '/TakeQuestionnaire/' + record.id;
  }

  edit = async (row) => {

    console.log(row);
    this.setState({
      hidden: false,
      cancelhidden: false,
      tablehidden: false,
      createNewButton: "Cancel",
      addButton: "Update",
      displayLastButton: false,
      createNewButtonVariant: "p-button-danger",
      questionnaryDescription: row.questionnaryDescription,
      areaOfUse: row.areaOfUse,
      pageFormat: row.pageFormat,
      dateOfPreparation: moment(row.dateOfPreparation, 'DD/MM/YYYY HH:mm').toDate(),
      dateOfImplemantation: moment(row.dateOfImplemantation, 'DD/MM/YYYY HH:mm').toDate(),
      questionnaryId: row.id,
      header: row.header,
      footerText: row.footer,
      checkFooter: Boolean(row.footer),
      checkHeader: Boolean(row.header)
    });

  };

  derive = async (row) => {

    console.log(row);
    this.setState({
      hidden: false,
      cancelhidden: false,
      tablehidden: false,
      createNewButton: "Cancel",
      addButton: "Derive",
      displayLastButton: false,
      createNewButtonVariant: "p-button-danger",
      questionnaryDescription: row.questionnaryDescription,
      areaOfUse: row.areaOfUse,
      pageFormat: row.pageFormat,
      dateOfPreparation: moment(row.dateOfPreparation, 'DD/MM/YYYY HH:mm').toDate(),
      dateOfImplemantation: moment(row.dateOfImplemantation, 'DD/MM/YYYY HH:mm').toDate(),
      questionnaryId: 0,
      derivedQuestionnaryId: row.id,
      derivedQuestions: row.questions,
      header: row.header,
      footerText: row.footer,
      checkFooter: Boolean(row.footer),
      checkHeader: Boolean(row.header)
    });

  };


  changeButtonUpdate = async (row) => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        cancelhidden: false,
        tablehidden: false,
        createNewButton: "Cancel",
        addButton: "Update",
        displayLastButton: false,
        createNewButtonVariant: "p-button-danger",
        questionnaryDescription: row.questionnaryDescription,
        areaOfUse: row.areaOfUse,
        pageFormat: row.pageFormat,
        dateOfPreparation: row.dateOfPreparation,
        dateOfImplemantation: row.dateOfImplemantation,
        questionnaryId: row.id,
      });
    } else {
      this.cancelButtonUpdate();
    }
  };

  cancelButtonUpdate = async (row) => {
    this.setState({
      hidden: true,
      cancelhidden: true,
      tablehidden: false,
      createNewButton: "Create New",
      addButton: "Add",
      displayLastButton: true,
      createNewButtonVariant: "p-button-info",
      questionnaryDescription: "",
      areaOfUse: "",
      pageFormat: "",
      dateOfPreparation: "",
      dateOfImplemantation: "",
    });
  };

  changeButtonSave = () => {
    this.setState({
      questionnaryDescription: "",
      areaOfUse: "",
      pageFormat: "",
      dateOfPreparation: "",
      dateOfImplemantation: "",
      hidden: true,
      buttonName: "Add",
      buttonClassName: "p-button-success",
    });
  };

  deleteModal = (row) => {
    this.setState({
      modalShow: true,
      //  selectedDiscipline: row
    });
  };

  render() {
    let smClose = () => this.setState({ smShow: false });

    let dialogFooterTemplate = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button>Save</Button>
      </div>
    );

    let pageFormatOptions = this.state.pageFormats.map((data) => (
      <option key={data.id} value={data.name}>
        {data.name}
      </option>
    ));
    const expandedRowRender = (data) => {
      return <QuestionnaryExpand row={data} />;
    }
    return (
      <div>
        <Modal
          size="sm"
          show={this.state.smShow}
          onHide={smClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              {this.state.messagesHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.messages}</Modal.Body>
        </Modal>

        <div style={{ backgroundColor: "white", padding: "10px" }}>
          <Row style={{ marginBottom: "1rem" }} as={Row}>
            <Col sm="12" md={{ size: 2, offset: 10 }}>
              <Mnd.MNDButton
                btnId="custom-btn-createnew"
                style={{ backgroundColor: "#254834", width: "100%" }}
                onClickFunc={this.createButtonClick}
                btnLabel={this.state.createNewButton}
              />
            </Col>
          </Row>
        </div>
        <br />
        <Toast ref={(el) => (this.toast = el)}></Toast>
        <div hidden={this.state.hidden}>
          <div
            style={{ backgroundColor: "white", padding: "10px" }}
            className="p-grid p-fluid"
          >
            <div className="p-col-12 p-md-4">
              <Form>
                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="3">Questionnary Description</Col>
                  <Col xs="3">
                    <Mnd.MNDInputText
                      onChange={(e) =>
                        this.setState({
                          questionnaryDescription: e.target.value,
                        })
                      }
                      value={this.state.questionnaryDescription}
                      placeholder="Questionnary Description"
                    />
                  </Col>
                  <Col xs="3">Date of Implemantation</Col>
                  <Col xs="3">
                    <Mnd.MNDCalendar
                      value={this.state.dateOfImplemantation}
                      onChange={(e) =>
                        this.setState({ dateOfImplemantation: e.value })
                      }
                      showTime={true}
                      showSeconds={true}
                      dateFormat="dd/mm/yy"
                    />
                  </Col>

                </Form.Group>
                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="3">Page Format</Col>
                  <Col xs="3">
                    <Mnd.MNDDropDown
                      name="pageFormat"
                      value={this.state.pageFormat}
                      onChange={this.handleChange}
                      options={pageFormatOptions}
                    />
                  </Col>
                  <Col xs="3">Area of Use</Col>
                  <Col xs="3">
                    <Mnd.MNDInputText
                      onChange={(e) =>
                        this.setState({
                          areaOfUse: e.target.value,
                        })
                      }
                      value={this.state.areaOfUse}
                      placeholder="Area of Use"
                    />
                  </Col>
                </Form.Group>
                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="3"> <AntCheckbox checked={this.state.checkHeader} onChange={(e) => { this.setState({ checkHeader: e.target.checked }) }} > Header </AntCheckbox> </Col>
                  <Col xs="9" hidden={!this.state.checkHeader}>
                    <Editor style={{ height: '150px' }} value={this.state.header} onTextChange={(e) => this.setState({ header: e.htmlValue })} />
                  </Col>

                </Form.Group>
                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="3"><AntCheckbox checked={this.state.checkFooter} onChange={(e) => { this.setState({ checkFooter: e.target.checked }) }} > Footer </AntCheckbox></Col>
                  <Col xs="9" hidden={!this.state.checkFooter}>
                    <Editor style={{ height: '150px' }} value={this.state.footerText} onTextChange={(e) => this.setState({ footerText: e.htmlValue })} />
                  </Col>

                </Form.Group>
              </Form>
              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col sm="12" md={{ size: 2, offset: 10 }}>
                  <Mnd.MNDButton
                    btnId="save-questionnary"
                    btnClassName={this.state.buttonClassName}
                    onClickFunc={this.saveQuestionnary}
                    btnLabel={this.state.addButton}
                  />
                </Col>
              </Form.Group>
            </div>
          </div>

          <Dialog
            visible={this.state.displayHiddenDialog}
            style={{ width: "700px", height: "700px" }}
            header="Questionnary"
            modal={true}
            footer={dialogFooterTemplate}
            onHide={this.handleQuestionnaryHide}
          >
            {
              <Card style={{ width: "700px", height: "700px" }}>
                <Toast ref={(el) => (this.toast = el)} />
                <Card.Body>
                  <Container></Container>
                </Card.Body>
              </Card>
            }
          </Dialog>
        </div>
        <br />
        {/* <WFStart stepId="1" workFlowName="wfn" /> */}
        {/* {this.props.auth.user.username + " - "+this.props.auth.user.email}
        <br /> */}
        <div
          hidden={this.state.tablehidden}
          className="datatable-doc-demo"
          style={{
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <div
            style={{ marginTop: "30px", marginBottom: "30px" }}
            className="content-section implementation "
          >
            <Table
              {...this.state}
              responsive
              striped
              bordered
              hover
              dataSource={this.state.dataTableValue}
              pagination={{
                pageSize: 5,
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "30"],
                total: this.state.dataTableValue.length,
                showQuickJumper: true,
                position: "bottomLeft",
              }}
              expandable={{ expandedRowRender }}
            >
              <Column
                selectionMode="single"
                style={{ width: "3em" }}
                expander={true}
              />
              <Column
                title=""
                key="id"
                render={(record) => this.actionTemplate(record)}
              />
              <Column
                dataIndex="id"
                key="id"
                style={{ textAlign: "center" }}
                sortable
                filter
                title="S/N"
              />
              <Column
                dataIndex="questionnaryDescription"
                key="questionnaryDescription"
                style={{ textAlign: "center" }}
                sortable
                filter
                title="Questionnary Description"
              />
              <Column
                dataIndex="areaOfUse"
                key="areaOfUse"
                style={{ textAlign: "center" }}
                sortable
                filter
                title="Area of Use"
              />
              <Column
                dataIndex="pageFormat"
                key="pageFormat"
                style={{ textAlign: "center" }}
                sortable
                filter
                title="Page Format"
              />
              <Column
                dataIndex="dateOfPreparation"
                key="dateOfPreparation"
                style={{ textAlign: "center" }}
                sortable
                filter
                title="Date of Preparetation"
              />
              <Column
                dataIndex="dateOfImplemantation"
                key="dateOfImplemantation"
                style={{ textAlign: "center" }}
                sortable
                filter
                title="Date of Implemantation"
              />
              <Column
                style={{ textAlign: "center" }}
                render={(record) => (this.renderTakeQuestionaire(record))}
                title="Take Questionaire"
              />

            </Table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Questionnary);
