import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Button as ReactButton, Col, Row, Modal, Form as FormBoot } from 'react-bootstrap';
import { CloseOutlined, PlusOutlined, RightCircleOutlined, UndoOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Checkbox, Button as AntButton, Divider, DatePicker, Input } from 'antd';
import { InputNumber, Space, Select, Table, Form, Switch, TimePicker } from 'antd';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import moment from 'moment';

const { Option } = Select;
const { Column } = Table;

const _DurationTypes = [
    { label: "Day", value: 3 },
    { label: "Hour", value: 4 },
    { label: "Minute", value: 5 },
];

export class OvertimeRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedClinic: null,
            overtimeRequests: [],
            selectedOvertimeRequests: [],
            overtimeDurationType: true,
            staffs: [],
            equipmentTypes: [],
            transportationList: [],
            places: [],
            requester: ["Individual"],
            location: "",
            activity: "",
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationType: null,
            durationTypes: _DurationTypes.slice(),
            overtimeRequestEmployeeObjects: [],
            overtimeRequestAdditionalEmployeeObjects: [],
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "OvertimeRequest" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "OvertimeRequest",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "OvertimeRequest");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {

        }

        this.getUsers();
        this.getCodesPlace();
        this.restartTable();
        const responseEquipmentTypes = await handleRequest("GET", "/EquipmentType/getAll");
        let listIds = [];

        if (responseEquipmentTypes.data.length !== 0) {
            var list = responseEquipmentTypes.data;
            var _transportationTypeList = list.filter((p) => (p.equipmentTypeName === "Midibus" || p.equipmentTypeName === "4x4 Car" || p.equipmentTypeName === "Saloon"
                || p.equipmentTypeName === "Sport" || p.equipmentTypeName === "Minibus" || p.equipmentTypeName === "Bus"));
            for (let index = 0; index < _transportationTypeList.length; index++) {
                listIds.push(_transportationTypeList[index].id);
            }
        }

        const responseEquipment = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipment.data.length !== 0) {
            var list = responseEquipment.data;
            var _transportationList = [];

            for (let index = 0; index < responseEquipment.data.length; index++) {
                if (listIds.includes(responseEquipment.data[index].equipmentTypeId))
                    _transportationList.push(responseEquipment.data[index]);
            }

            this.setState({
                transportationList: _transportationList,
            });
        }

        this.formRef.current.setFieldsValue({
            OvertimeRequestRequester: "Individual"
        });
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/OvertimeRequest/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                overtimeRequests: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                overtimeRequests: response.data,
            });
        }
    }

    resetInputs = () => {
        this.formRef.current.resetFields();

        this.formRef.current.setFieldsValue({
            OvertimeRequestRequester: "Individual"
        });

        this.setState({
            hidden: true,
            overtimeDurationType: true,
            requester: ["Individual"],
            location: "",
            activity: "",
            staff: [],
            additionalStaff: [],
            duration: "",
            durationNumber: "",
            durationType: null,
            durationObj: {},
            durationTypes: _DurationTypes.slice(),
            startDateTime: null,
            endDateTime: null,
            justification: "",
            transportation: false,
            vehicleId: "",
            shuttle: false,
            timeOfDepart: null,
            food: false,
            timeForDining: null,
            placeId: ""
        });
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedOvertimeRequest: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteOvertimeRequestOK = async () => {
        const deleteOvertimeRequest = {
            id: this.state.selectedOvertimeRequest.id
        }
        var response = await handleRequest("POST", "/OvertimeRequest/delete", deleteOvertimeRequest);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    createNew = async () => {
        if (this.state.hidden) {
            this.formRef.current.resetFields();

            this.formRef.current.setFieldsValue({
                OvertimeRequestRequester: "Individual"
            });

            this.setState({
                hidden: false,
                overtimeDurationType: true,
                requester: ["Individual"],
                location: "",
                activity: "",
                staff: [],
                additionalStaff: [],
                duration: "",
                durationNumber: "",
                durationType: null,
                durationObj: {},
                durationTypes: _DurationTypes.slice(),
                startDateTime: null,
                endDateTime: null,
                justification: "",
                transportation: false,
                vehicleId: "",
                shuttle: false,
                timeOfDepart: null,
                food: false,
                timeForDining: null,
                placeId: ""
            });
        } else {
            this.resetInputs();
        }
    }

    stopProcess = () => {
        this.resetInputs();
    }

    saveOvertimeRequest = async () => {
        let flagOvertimeRequestEmployees = [];
        let flagOvertimeRequestAdditionalEmployees = [];

        for (let index = 0; index < this.state.staff.length; index++) {
            flagOvertimeRequestEmployees.push({
                staffId: this.state.staff[index]
            });
        }

        for (let index = 0; index < this.state.additionalStaff.length; index++) {
            flagOvertimeRequestAdditionalEmployees.push({
                staffId: this.state.additionalStaff[index]
            });
        }

        const addOvertimeRequest = {
            requester: this.state.requester.join(),
            location: this.state.location,
            activity: this.state.activity,
            overtimeRequestEmployees: flagOvertimeRequestEmployees,
            overtimeRequestAdditionalEmployees: flagOvertimeRequestAdditionalEmployees,
            overtimeDuration: this.state.durationObj,
            overtimeDurationType: this.state.overtimeDurationType,
            overtimeRequestStartDate: this.state.startDateTime,
            overtimeRequestEndDate: this.state.endDateTime,
            justification: this.state.justification,
            transportation: this.state.transportation,
            vehicleId: this.state.vehicleId,
            shuttle: this.state.shuttle,
            timeOfDepart: this.state.timeOfDepart,
            food: this.state.food,
            timeForDining: this.state.timeForDining,
            placeId: this.state.placeId
        }

        var response = await handleRequest("POST", "/OvertimeRequest/add", addOvertimeRequest);
        if (response.data !== null && response.data !== undefined) {
            if (response.data !== "0" && response.data !== 0) {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }
    }

    updateOvertimeRequest = async () => {
        if (this.state.selectedOvertimeRequests !== null) {

            let flagOvertimeRequestEmployees = [];
            let oreValues = [];
            let flagOvertimeRequestAdditionalEmployees = [];
            let oraeValues = [];

            for (let index = 0; index < this.state.overtimeRequestEmployeeObjects.length; index++) {
                oreValues.push(this.state.overtimeRequestEmployeeObjects[index].staffId);
            }

            for (let index = 0; index < this.state.overtimeRequestEmployeeObjects.length; index++) {
                if (this.state.staff.includes(oreValues[index])) {
                    flagOvertimeRequestEmployees.push({
                        id: this.state.overtimeRequestEmployeeObjects[index].id,
                        active: true,
                        staffId: this.state.overtimeRequestEmployeeObjects[index].staffId,
                    });
                } else {
                    flagOvertimeRequestEmployees.push({
                        id: this.state.overtimeRequestEmployeeObjects[index].id,
                        active: false,
                        staffId: this.state.overtimeRequestEmployeeObjects[index].staffId,
                    });
                }
            }

            for (let index = 0; index < this.state.staff.length; index++) {
                if (!oreValues.includes(this.state.staff[index])) {
                    flagOvertimeRequestEmployees.push({
                        staffId: this.state.staff[index],
                    });
                }
            }

            for (let index = 0; index < this.state.overtimeRequestAdditionalEmployeeObjects.length; index++) {
                oraeValues.push(this.state.overtimeRequestAdditionalEmployeeObjects[index].staffId);
            }

            for (let index = 0; index < this.state.overtimeRequestAdditionalEmployeeObjects.length; index++) {
                if (this.state.additionalStaff.includes(oraeValues[index])) {
                    flagOvertimeRequestAdditionalEmployees.push({
                        id: this.state.overtimeRequestAdditionalEmployeeObjects[index].id,
                        active: true,
                        staffId: this.state.overtimeRequestAdditionalEmployeeObjects[index].staffId,
                    });
                } else {
                    flagOvertimeRequestAdditionalEmployees.push({
                        id: this.state.overtimeRequestAdditionalEmployeeObjects[index].id,
                        active: false,
                        staffId: this.state.overtimeRequestAdditionalEmployeeObjects[index].staffId,
                    });
                }
            }

            for (let index = 0; index < this.state.additionalStaff.length; index++) {
                if (!oraeValues.includes(this.state.additionalStaff[index])) {
                    flagOvertimeRequestAdditionalEmployees.push({
                        staffId: this.state.additionalStaff[index],
                    });
                }
            }

            const updatedOvertimeRequests = {
                id: this.state.selectedOvertimeRequests.id,
                requester: this.state.requester.join(),
                location: this.state.location,
                activity: this.state.activity,
                overtimeRequestEmployees: flagOvertimeRequestEmployees,
                overtimeRequestAdditionalEmployees: flagOvertimeRequestAdditionalEmployees,
                overtimeDuration: this.state.durationObj,
                overtimeDurationType: this.state.overtimeDurationType,
                overtimeRequestStartDate: this.state.startDateTime,
                overtimeRequestEndDate: this.state.endDateTime,
                justification: this.state.justification,
                transportation: this.state.transportation,
                vehicleId: this.state.vehicleId,
                shuttle: this.state.shuttle,
                timeOfDepart: this.state.timeOfDepart,
                food: this.state.food,
                timeForDining: this.state.timeForDining,
                placeId: this.state.placeId
            }

            console.log(updatedOvertimeRequests);

            var response2 = await handleRequest("POST", "/OvertimeRequest/update", updatedOvertimeRequests);
            if (response2.data !== null && response2.data !== undefined) {
                if (response2.data === "SUCCESS") {
                    this.resetInputs();
                    this.setState({
                        selectedClinic: null,
                        hiddenSave: true,
                        hiddenUpdate: false
                    });
                    this.restartTable();
                    this.showSuccess();
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    changeButtonUpdate = (row) => {

        console.log(row);

        let _employees = [];
        let _employeesValue = [];
        let _additionalEmployees = [];
        let _additionalEmployeesValue = [];

        if (row.overtimeRequestEmployees !== undefined)
            if (row.overtimeRequestEmployees.length > 0) {
                for (let index = 0; index < row.overtimeRequestEmployees.length; index++) {
                    _employees.push(row.overtimeRequestEmployees[index].overtimeRequestEmployee.name +
                        " " + row.overtimeRequestEmployees[index].overtimeRequestEmployee.midName +
                        " " + row.overtimeRequestEmployees[index].overtimeRequestEmployee.lastName);
                    _employeesValue.push(row.overtimeRequestEmployees[index].staffId);
                }
            }

        if (row.overtimeRequestAdditionalEmployees !== undefined)
            if (row.overtimeRequestAdditionalEmployees.length > 0) {
                for (let index = 0; index < row.overtimeRequestAdditionalEmployees.length; index++) {
                    _additionalEmployees.push(row.overtimeRequestAdditionalEmployees[index].overtimeRequestAdditionalEmployee.name +
                        " " + row.overtimeRequestAdditionalEmployees[index].overtimeRequestAdditionalEmployee.midName +
                        " " + row.overtimeRequestAdditionalEmployees[index].overtimeRequestAdditionalEmployee.lastName);
                    _additionalEmployeesValue.push(row.overtimeRequestAdditionalEmployees[index].staffId);
                }
            }

        this.setDuration(row.overtimeDuration);

        this.formRef.current.setFieldsValue({
            OvertimeRequestRequester: row.requester.split(","),
            Location: row.location,
            Activity: row.activity,
            StartDateTime: moment(row.overtimeRequestStartDate),
            Justification: row.justification,
            Transportation: row.transportation,
            Vehicle: row.vehicle.equipmentDescription,
            Shuttle: row.shuttle,
            TimeOfDepart: moment(row.timeOfDepart, "HH:mm"),
            Food: row.food,
            TimeForDining: moment(row.timeForDining, "HH:mm"),
            Place: row.codePlace.name
        });

        if (row.overtimeRequestEmployees !== undefined) {
            this.formRef.current.setFieldsValue({
                Employee: _employeesValue
            });
        }

        if (row.overtimeRequestAdditionalEmployees !== undefined) {
            this.formRef.current.setFieldsValue({
                AdditionalEmployee: _additionalEmployeesValue
            });
        }

        if (row.overtimeRequestEndDate !== null) {
            this.formRef.current.setFieldsValue({
                EndDateTime: moment(row.overtimeRequestEndDate),
            });

            this.setState({
                endDateTime: moment(row.overtimeRequestEndDate)._d,
            });
        }

        else {
            this.formRef.current.setFieldsValue({
                EndDateTime: null,
            });

            this.setState({
                endDateTime: null,
            });
        }


        this.setState({
            hidden: false,
            hiddenSave: true,
            hiddenUpdate: false,
            selectedOvertimeRequests: row,
            overtimeDurationType: true,
            requester: row.requester.split(","),
            location: row.location,
            activity: row.activity,
            staff: _employeesValue,
            overtimeRequestEmployeeObjects: row.overtimeRequestEmployees,
            overtimeRequestAdditionalEmployeeObjects: row.overtimeRequestAdditionalEmployees,
            additionalStaff: _additionalEmployeesValue,
            durationObj: row.overtimeDuration,
            durationTypes: _DurationTypes.slice(),
            startDateTime: moment(row.overtimeRequestStartDate)._d,
            justification: row.justification,
            transportation: row.transportation,
            vehicleId: row.vehicleId,
            shuttle: row.shuttle,
            timeOfDepart: row.timeOfDepart,
            food: row.food,
            timeForDining: row.timeForDining,
            placeId: row.placeId
        });
    }

    changeButtonCancel = () => {
        this.formRef.current.resetFields();

        this.formRef.current.setFieldsValue({
            OvertimeRequestRequester: "Individual"
        });

        this.setState({
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedOvertimeRequests: null,
            overtimeDurationType: true,
            requester: ["Individual"],
            location: "",
            activity: "",
            staff: [],
            additionalStaff: [],
            duration: "",
            durationNumber: "",
            durationType: null,
            durationObj: {},
            durationTypes: _DurationTypes.slice(),
            startDateTime: null,
            endDateTime: null,
            justification: "",
            transportation: false,
            vehicleId: "",
            shuttle: false,
            timeOfDepart: null,
            food: false,
            timeForDining: null,
            placeId: ""
        });
    }

    onStaffChange = (value) => {
        this.setState({
            staff: value
        });
    }

    onAdditionalStaffChange = (value) => {
        this.setState({
            additionalStaff: value
        });
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    getUsers = async () => {
        const responseStaffs = await handleRequest("GET", "/Staff/getCompanyItems");

        if (responseStaffs.data.length !== 0) {
            this.setState({
                staffs: responseStaffs.data
            });
        }

        if (responseStaffs.data.length === 0) {
            this.setState({
                staffs: []
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeLocation = (value) => {
        this.setState({
            location: value
        });
    }

    handleChangeActivity = (value) => {
        this.setState({
            activity: value
        });
    }

    onChangeStartDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                startDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                startDateTime: today
            });
        }
    }

    onChangeEndDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                endDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                endDateTime: today
            });
        }
    }

    onVehicleChange = (value) => {
        this.setState({
            vehicleId: value
        });

        let flagVehicle = this.state.transportationList;

        for (let index = 0; index < flagVehicle.length; index++) {
            if (flagVehicle[index].id === value) {
                this.formRef.current.setFieldsValue({
                    Vehicle: flagVehicle[index].id
                });
            }
        }
    }

    onChangeTimeOfDepartDateTime = async (time) => {
        let timeString = "";

        if (time !== null) {
            timeString = time._d.getHours() + ":" + time._d.getMinutes()

            this.setState({
                timeOfDepart: timeString
            });
        } else {
            var today = new Date();
            timeString = today.getHours() + ":" + today.getMinutes();

            this.setState({
                timeOfDepart: timeString
            });
        }
    }

    onChangeTimeForDiningDateTime = async (time) => {
        let timeString = "";

        if (time !== null) {
            timeString = time._d.getHours() + ":" + time._d.getMinutes()

            this.setState({
                timeForDining: timeString
            });
        } else {
            var today = new Date();
            timeString = today.getHours() + ":" + today.getMinutes();

            this.setState({
                timeForDining: timeString
            });
        }
    }

    handleChangePlace = (e) => {
        var value = "";
        for (var z = 0; z < this.state.places.length; z++) {
            if (this.state.places[z].id == e) {
                value = this.state.places[z].name;
            }
        }
        this.setState({
            place: value,
            placeId: e,
        });
    }

    addNewPlace = async () => {
        const { newPlace } = this.state;
        if (newPlace != null && newPlace != "" && newPlace != undefined) {
            const newItem = {
                name: newPlace,
                type: "OvertimeRequestPlace"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                newPlace: "",
            });
            this.getCodesPlace();
        } else {
            this.showWarning();
        }
    }

    getCodesPlace = async () => {
        const response = await handleRequest("GET", "/Code/type/OvertimeRequestPlace");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                places: response.data
            });
        }
    }

    calculateDuration = () => {
        var myObj = this.state.durationObj;
        var types = this.state.durationTypes;
        const index = this.state.durationTypes.findIndex(p => p.value === this.state.durationType);
        if (index >= 0 && this.state.durationNumber > 0) {
            var durationDesc = this.state.duration + this.state.durationNumber + " " + types[index].label + "s ";
            myObj[types[index].label.toLowerCase()] = this.state.durationNumber;
            types.splice(0, index + 1);

            this.setState({
                durationObj: myObj,
                duration: durationDesc,
                durationTypes: types,
                durationType: null,
            });
        }
        else {
            this.showWarning("Please type duration inputs!");
        }
    }

    undoDuration = () => {
        if (this.state.duration.trim() !== "") {
            var pObj = this.state.durationObj;
            if (pObj !== null) {
                if (pObj.minute && pObj.minute > 0)
                    pObj.minute = 0;
                else if (pObj.hour && pObj.hour > 0)
                    pObj.hour = 0;
                else if (pObj.day && pObj.day > 0)
                    pObj.day = 0;
                else pObj = {};
            }

            var types = _DurationTypes.slice();
            var durationDesc = this.getDurationDesc(pObj);
            var index = -1;

            if (pObj !== {}) {
                if (pObj.minute && pObj.minute > 0)
                    index = 2;
                else if (pObj.hour && pObj.hour > 0)
                    index = 1;
                else if (pObj.day && pObj.day > 0)
                    index = 0;
            }

            types.splice(0, index + 1);

            this.setState({
                durationObj: pObj,
                duration: durationDesc,
                durationTypes: types
            });
        }
        else { this.showWarning("Please firstly type any duration!"); }
    }

    removeDuration = () => {
        this.setState({
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationTypes: _DurationTypes.slice(),
            durationType: null
        });
    }

    setDuration = (pObj) => {
        var result = "";

        if (pObj !== undefined && pObj !== null) {
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
        }
        else { pObj = {} }

        this.setState({
            duration: result,
            durationObj: pObj
        })
    }

    getDurationDesc = (pObj) => {
        var result = "";
        if (pObj !== undefined && pObj !== null) {
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
        }
        return result;
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        var requesterAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Requester");
        var locationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Location");
        var activityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Activity");
        var employeeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Employee");
        var additionalEmployeeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AdditionalEmployee");
        var ovetimeDurationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OvetimeDuration");
        var startDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "StartDate");
        var endDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EndDate");
        var justificationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Justification");
        var transportationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Transportation");
        var vehicleAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Vehicle");
        var shuttleAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Shuttle");
        var timeOfDepartAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimeOfDepart");
        var foodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Food");
        var timeForDiningAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimeForDining");
        var placeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Place");

        var requesterRequired = this.state.dynamicInputs.find(p => p.inputKey === "Requester");
        var locationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Location");
        var activityRequired = this.state.dynamicInputs.find(p => p.inputKey === "Activity");
        var employeeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Employee");
        var additionalEmployeeRequired = this.state.dynamicInputs.find(p => p.inputKey === "AdditionalEmployee");
        var ovetimeDurationRequired = this.state.dynamicInputs.find(p => p.inputKey === "OvetimeDuration");
        var startDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "StartDate");
        var endDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "EndDate");
        var justificationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Justification");
        var transportationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Transportation");
        var vehicleRequired = this.state.dynamicInputs.find(p => p.inputKey === "Vehicle");
        var shuttleRequired = this.state.dynamicInputs.find(p => p.inputKey === "Shuttle");
        var timeOfDepartRequired = this.state.dynamicInputs.find(p => p.inputKey === "TimeOfDepart");
        var foodRequired = this.state.dynamicInputs.find(p => p.inputKey === "Food");
        var timeForDiningRequired = this.state.dynamicInputs.find(p => p.inputKey === "TimeForDining");
        var placeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Place");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 },
        };

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteOvertimeRequestOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="OvertimeRequestCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="OvertimeRequestStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                            {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Form
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdate == false ? this.updateOvertimeRequest : this.saveOvertimeRequest}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef} >

                                {(requesterAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestRequester" defaultMessage="Requester" />}
                                        name="OvertimeRequestRequester"
                                        valuePropName="checked"
                                        rules={[{ required: requesterRequired.canBeRequired, message: <FormattedMessage id={Boolean(requesterRequired.translateRequired) == true ? requesterRequired.translateRequired : -1} defaultMessage="Please select a Requester!" /> }]} >
                                        <Checkbox.Group onChange={(checkedValues) => {
                                            this.setState({
                                                requester: checkedValues
                                            });
                                        }}
                                            value={this.state.requester}>
                                            <Checkbox value={"Individual"}><FormattedMessage id="OvertimeRequestIndividual" defaultMessage="Individual" /></Checkbox>
                                            <Checkbox value={"For Employees"}><FormattedMessage id="OvertimeRequestForEmployees" defaultMessage="For Employees" /></Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>}

                                {(locationAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestLocation" defaultMessage="Location" />}
                                        name="Location"
                                        rules={[{ required: locationRequired.canBeRequired, message: <FormattedMessage id={Boolean(locationRequired.translateRequired) == true ? locationRequired.translateRequired : -1} defaultMessage="Please select a Location!" /> }]} >
                                        <Select name="Location"
                                            style={{ width: '100%' }}
                                            value={this.state.location}
                                            onChange={this.handleChangeLocation}>
                                            <Option key={"Test Location"} value={"Test Location"}>{"Test Location"}</Option>
                                        </Select>
                                    </Form.Item>}

                                {(activityAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestActivity" defaultMessage="Activity" />}
                                        name="Activity"
                                        rules={[{ required: activityRequired.canBeRequired, message: <FormattedMessage id={Boolean(activityRequired.translateRequired) == true ? activityRequired.translateRequired : -1} defaultMessage="Please select a Activity!" /> }]} >
                                        <Select name="Activity"
                                            style={{ width: '100%' }}
                                            value={this.state.activity}
                                            onChange={this.handleChangeActivity}>
                                            <Option key={"Test Activity"} value={"Test Activity"}>{"Test Activity"}</Option>
                                        </Select>
                                    </Form.Item>}

                                {(this.state.requester.includes("For Employees")) && (employeeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestEmployee" defaultMessage="Employee(s)" />}
                                        name="Employee"
                                        rules={[{ required: employeeRequired.canBeRequired, message: <FormattedMessage id={Boolean(employeeRequired.translateRequired) == true ? employeeRequired.translateRequired : -1} defaultMessage="Please select an Employee!" /> }]} >
                                        <Select size='large'
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.staff}
                                            onChange={this.onStaffChange}
                                        >
                                            {this.state.staffs.map(staff => (
                                                <Option key={staff.id} value={staff.id}>{staff.name + " " + staff.midName + " " + staff.lastName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(additionalEmployeeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestAdditionalEmployee" defaultMessage="Additional Employee(s)" />}
                                        name="AdditionalEmployee"
                                        rules={[{ required: additionalEmployeeRequired.canBeRequired, message: <FormattedMessage id={Boolean(additionalEmployeeRequired.translateRequired) == true ? additionalEmployeeRequired.translateRequired : -1} defaultMessage="Please select an Additional Employee!" /> }]} >
                                        <Select size='large'
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.additionalStaff}
                                            onChange={this.onAdditionalStaffChange}
                                        >
                                            {this.state.staffs.map(staff => (
                                                <Option key={staff.id} value={staff.id}>{staff.name + " " + staff.midName + " " + staff.lastName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(ovetimeDurationAttributes.visible) && <FormBoot.Group as={Row}>
                                    <FormBoot.Label style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '14px', textAlign: 'right' }} column sm="3">
                                        <FormattedMessage id="OvertimeRequestOvetimeDuration" defaultMessage="Ovetime Duration: " />
                                    </FormBoot.Label>
                                    <Col sm="1">
                                        <InputNumber style={{ width: '100%' }} min={0} value={this.state.durationNumber} onChange={(value) => { this.setState({ durationNumber: value }) }} />
                                    </Col>
                                    <Col sm="1">
                                        <Select style={{ width: '100%' }} placeholder="" value={this.state.durationType} onChange={(value) => { this.setState({ durationType: value }) }}>
                                            <Option key={null} value={null}>Select</Option>
                                            {this.state.durationTypes.map(i => (
                                                <Option key={i.value} value={i.value}>{i.label}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col sm="1">
                                        <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.calculateDuration} icon={<RightCircleOutlined style={{ fontSize: '24px' }} />} />
                                        <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.undoDuration} icon={<UndoOutlined style={{ fontSize: '24px' }} />} />
                                    </Col>
                                    <Col sm="2">
                                        <Input style={{ width: '100%' }} id="todo" value={this.state.duration} />
                                    </Col>
                                    <Col sm="3">
                                        <Space>
                                            <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.removeDuration} icon={<CloseCircleOutlined style={{ fontSize: '24px' }} />} />
                                            <Switch value={this.state.overtimeDurationType} checkedChildren="Fixed" unCheckedChildren="Estimated" onChange={(checked) =>
                                                this.setState({ overtimeDurationType: checked })
                                            } />
                                        </Space>
                                    </Col>
                                </FormBoot.Group>}

                                {(startDateAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestStartDateTime" defaultMessage="Start Date - Time" />}
                                        name="StartDateTime"
                                        rules={[{ required: startDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(startDateRequired.translateRequired) == true ? startDateRequired.translateRequired : -1} defaultMessage="Please select a Start Date/Time!" /> }]} >
                                        <DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.startDateTime, 'DD/MM/YYYY HH:mm:ss')}
                                            disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeStartDateTime(date, dateString) }} />
                                    </Form.Item>}

                                {(endDateAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestEndDateTime" defaultMessage="End Date - Time" />}
                                        name="EndDateTime"
                                        rules={[{ required: endDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(endDateRequired.translateRequired) == true ? endDateRequired.translateRequired : -1} defaultMessage="Please select a End Date/Time!" /> }]} >
                                        <DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.endDateTime, 'DD/MM/YYYY HH:mm:ss')}
                                            disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeEndDateTime(date, dateString) }} />
                                    </Form.Item>}

                                {(justificationAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestJustification" defaultMessage="Justification" />}
                                        name="Justification"
                                        rules={[{ required: justificationRequired.canBeRequired, message: <FormattedMessage id={Boolean(justificationRequired.translateRequired) == true ? justificationRequired.translateRequired : -1} defaultMessage="Please input Justification!" /> }]} >
                                        <Input id="OvertimeRequestJustification" value={this.state.justification} onChange={(e) =>
                                            this.setState({ justification: e.target.value })
                                        } />
                                    </Form.Item>}

                                {(transportationAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestTransportation" defaultMessage="Transportation" />}
                                        name="Transportation">
                                        <Switch id="OvertimeRequestTransportation" checked={this.state.transportation} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) =>
                                            this.setState({ transportation: checked })
                                        } />
                                    </Form.Item>}

                                {(this.state.transportation) && (vehicleAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestVehicle" defaultMessage="Vehicle" />}
                                        name="Vehicle"
                                        rules={[{ required: vehicleRequired.canBeRequired, message: <FormattedMessage id={Boolean(vehicleRequired.translateRequired) == true ? vehicleRequired.translateRequired : -1} defaultMessage="Please select a Vehicle!" /> }]} >
                                        <Select size='large'
                                            style={{ width: '100%' }}
                                            showSearch
                                            value={this.state.vehicleId}
                                            onChange={this.onVehicleChange}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.transportationList.map(transportation => (
                                                <Option key={transportation.id} value={transportation.id}>{transportation.equipmentDescription}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(shuttleAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestShuttle" defaultMessage="Shuttle" />}
                                        name="Shuttle">
                                        <Switch id="OvertimeRequestShuttle" checked={this.state.shuttle} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) =>
                                            this.setState({ shuttle: checked })
                                        } />
                                    </Form.Item>}

                                {(this.state.shuttle) && (timeOfDepartAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestTimeOfDepart" defaultMessage="Time of Depart" />}
                                        name="TimeOfDepart"
                                        rules={[{ required: timeOfDepartRequired.canBeRequired, message: <FormattedMessage id={Boolean(timeOfDepartRequired.translateRequired) == true ? timeOfDepartRequired.translateRequired : -1} defaultMessage="Please select a Time of Depart!" /> }]} >
                                        {/*<DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.timeOfDepart, 'DD/MM/YYYY HH:mm:ss')}
                                        disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeTimeOfDepartDateTime(date, dateString) }} />*/}
                                        <TimePicker value={moment(this.state.timeOfDepart, "HH:mm")} format={"HH:mm"} onChange={this.onChangeTimeOfDepartDateTime} />
                                    </Form.Item>}

                                {(foodAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestFood" defaultMessage="Food" />}
                                        name="Food">
                                        <Switch id="OvertimeRequestFood" checked={this.state.food} checkedChildren="Yes" unCheckedChildren="No" onChange={(checked) =>
                                            this.setState({ food: checked })
                                        } />
                                    </Form.Item>}

                                {(this.state.food) && (timeForDiningAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestTimeForDining" defaultMessage="Time for Dining" />}
                                        name="TimeForDining"
                                        rules={[{ required: timeForDiningRequired.canBeRequired, message: <FormattedMessage id={Boolean(timeForDiningRequired.translateRequired) == true ? timeForDiningRequired.translateRequired : -1} defaultMessage="Please select a Time for Dining!" /> }]} >
                                        {/*<DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.timeForDining, 'DD/MM/YYYY HH:mm:ss')}
                                        disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeTimeForDiningDateTime(date, dateString) }} />*/}
                                        <TimePicker value={moment(this.state.timeForDining, "HH:mm")} format={"HH:mm"} onChange={this.onChangeTimeForDiningDateTime} />
                                    </Form.Item>}

                                {(placeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="OvertimeRequestPlace" defaultMessage="Place" />}
                                        name="Place"
                                        rules={[{ required: placeRequired.canBeRequired, message: <FormattedMessage id={Boolean(placeRequired.translateRequired) == true ? placeRequired.translateRequired : -1} defaultMessage="Please select a Place!" /> }]} >
                                        <Select
                                            name="placeId"
                                            style={{ width: '100%' }}
                                            value={this.state.place}
                                            onChange={this.handleChangePlace}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newPlace}
                                                            onChange={(event) => { this.setState({ newPlace: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addNewPlace} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Place" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.places.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Button id="OvertimeRequestSaveButton" style={{ width: '100%' }} type="submit"
                                                    variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row}>
                                            <Col sm="3"></Col>
                                            <Col sm="2">
                                                <Button id="OvertimeRequestCancelButton" onClick={this.changeButtonCancel}
                                                    style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                            </Col>
                                            <Col sm="2">
                                                <Button id="OvertimeRequestUpdateButton" type="submit"
                                                    style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                            </Form>}
                        </div>

                        <Table style={{ padding: "4px 1rem 0px 4px" }} dataSource={this.state.overtimeRequests} bordered >
                            <Column title="Date, hours" key="dateHours" render={(value, row, index) => {
                                return "";
                            }} />
                            <Column title="Location-Activity" key="locationActivity" render={(value, row, index) => {
                                return row.location + " - " + row.activity;
                            }} />
                            <Column title="Employee(s)" key="employee" render={(value, row, index) => {
                                if (row.overtimeRequestEmployees !== undefined)
                                    if (row.overtimeRequestEmployees !== null) {
                                        let _employees = [];
                                        for (let index = 0; index < row.overtimeRequestEmployees.length; index++) {
                                            _employees.push(row.overtimeRequestEmployees[index].overtimeRequestEmployee.name +
                                                " " + row.overtimeRequestEmployees[index].overtimeRequestEmployee.midName +
                                                " " + row.overtimeRequestEmployees[index].overtimeRequestEmployee.lastName);
                                        }
                                        return _employees.join(", ");
                                    } else
                                        return '';
                                return '';
                            }} />
                            <Column title="Justifications" key="justification" dataIndex="justification" />
                            <Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => {
                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                        if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                            (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                            return (<div style={{ textAlign: "center" }}>
                                                <React.Fragment key={record.id} >
                                                    <CustomMaterialMenuDeleteEdit row={record}
                                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
                                                </React.Fragment>
                                            </div>);
                                        }
                                        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuEdit row={record}
                                                    onEditRow={this.changeButtonUpdate.bind(this)} />
                                            </React.Fragment>);
                                        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuDelete row={record}
                                                    onDeleteRow={this.deleteModal.bind(this)} />
                                            </React.Fragment>);
                                        }
                                    }
                                    else {
                                        return (<div style={{ textAlign: "center" }}>
                                        </div>);
                                    }
                                }}
                            />
                        </Table>

                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        ));
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(OvertimeRequest)