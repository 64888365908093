/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { Table, Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row, Modal as ModalReact } from 'react-bootstrap';
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column"; 
import { ToggleButton } from 'primereact/togglebutton';
import "react-toggle/style.css"; 
import { fetchCustomerLogs } from "../../redux/actions/customerLogAction";  
import "./css/DataTable.scss"; 
import { handleRequest } from '../../redux/config/env';  
import { InputText } from "primereact/inputtext";
import ChecklistLogExpand from './ChecklistLogExpand';
import { InputNumber, TreeSelect, Cascader, Checkbox as AntCheckbox, Modal } from 'antd';
import moment from 'moment';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu'; 
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { SHOW_PARENT } = TreeSelect;
const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.value, lineage: node.data.lineage });
        if (node.children) {  
            generateList(node.children); 
        }
    }
};

class ChecklistLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      modalMessage:false,
      hidden: true, 
      hiddenSave: false,
      hiddenUpdate: true,
      tablehidden:false,
      workpermitreqCheck:true,
      
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",

      butonbirDesc:"Add New",
      butonbirVariant: "info", 
      butonEquipDesc:"Add New",
      butonEquipVariant: "info",
      butonChecklistItemsDesc:"Add New",
      butonChecklistItemsVariant: "info",

      Checklists:[],
      itemsChecklistItems:[],
      itemsEquipments:[],

      checklistKayitKismiGoster:true,
      equipmentKayitKismiGoster:true,
      checkitemKayitKismiGoster:true,
      documentsKayitKismiGoster:false,

      hiddenUpdateDocuments: true,
      hiddenSaveDocuments: false,

      hiddenUpdateEquipments: true,
      hiddenSaveEquipments: false,

      iclistno:"",
      iclist:"",
      revno:0,
      optionReferenceDocs:[],
      referencedoc:[],
      referencedocNames:[],
      layoutdrawnumber:[],
      layoutdrawnumberNames:[],
      workpermitreqCheck:true,

      equipmentType:"",
      equipmentName:"",
      equipmentCode:"",

      checklistItemsitemno:"",
      checklistItemsAcceptanceCriteria:"", 
      checklistItemsReference:"",
      StateItemsChecklistItems:[],

      checklistGroup: false,
      hiddenReference: false,
      AcceptanceCriteria:"Acceptance Criteria",

      disciplinesOfDepartments:[],
      itpAll:[],
      itpCode:[],
      itpName:[],
      iptCodeVal:null,
      iptNameVal:null,
 
      itpcheckid:-1,
      messagesHeader:"",
      datatableValue:[],

      documentLogid:-1,

      discipline:"",
      disciplineVal: 0,

      itpcode:"",
      itpname:"",
      approvaldateAck:"",
      approvaldate:null,

      nodes: [],
      values: [],
      selectedItems:[],
      EquipmentCodeName:[],
      ItemsEquipmentsKayit:0,
      equipmentTypeName:"",

      index: "",
      equipmentCodeName: "",
      quantity: "",
      equipment:"",
      equipmentId:0,

      hiddenAmendDocument:false,
      popup:false,
      messageTip:"",

      referenceDocData: [],
      selectedReferenceDoc: [],
      checkedReferenceDoc: [],

      layoutDrawingNumberData: [],
      selectedLayoutDrawingNumber: [],
      checkedLayoutDrawingNumber: [],
 
    };

    this.onChangeItpCode = this.onChangeItpCode.bind(this);
    this.onChangeItpName = this.onChangeItpName.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this); 
    this.actionChecklistItems = this.actionChecklistItems.bind(this);   
  }

componentDidMount = async () => {
  var deger=0;
  if(this.props.location!=null && this.props.location!=undefined && this.props.location!=""){
    deger=this.props.location.pathname.split('/')[2];
    this.setState({
      popup:false
    });
  }else if(this.props.id!=null && this.props.id!=0 && this.props.id!=""){
    deger=this.props.id;
    this.setState({
      popup:true
    });
  }else{
    deger=-1;
    this.setState({
      popup:true
    });
  }

  await this.setState({
    documentLogid:deger
  });

  await this.getCheckListLog(deger); 
  this.createButtonClick();
  //this.butonbirClick();
  this.getDocumentLog(deger);
  this.getReferenceDocs();
  this.getEquipmentType(); 
  this.getITP();
  //this.getDsicipline();
  this.getItpEquipments();
  this.getItpChecklists();
  this.getReferenceDocDatas();
  this.getLayoutDrawingNumberDatas();
} 

getReferenceDocDatas = async () => {
  var response = [];
  var item = { page: "" };
  response = await handleRequest("POST", "/DocumentLog/getResourceDocuments", item)
  console.log("TTT", response.data);
  if (response.data.length !== 0) {
    this.setState({
      referenceDocData: response.data
    });
  }
}

getLayoutDrawingNumberDatas = async () => {
  var response = [];
  var item = { page: "Drawing" };
  response = await handleRequest("POST", "/DocumentLog/getResourceDocuments", item)
  if (response.data.length !== 0) {
    this.setState({
      layoutDrawingNumberData: response.data
    });
  }
}

redirectDocument() {
  if(this.state.popup==false){
    window.setTimeout(function(){
      window.location.href = "/DocumentLog";
  }, 2000);
  }
};

showSuccess(msg) {
  this.setState({
    modalMessage:true,
    message:msg,
    messageTip:"Success"
  });
  this.redirectDocument();
};

showError(msg) {
  console.log("msg", msg);
  if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
  }
  this.setState({
    modalMessage:true,
    message:msg,
    messageTip:"Error"
  });
};

showWarning(msg) {
  if (!msg) {
    msg = 'Something went wrong. Please check inputs.';
  }
  this.setState({
    modalMessage:true,
    message:msg,
    messageTip:"Warning"
  });
};

getDocumentLog = async (deger) => {
      const newObj = {
        id: deger
      } 
      const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj); 
      if (response.data.length !== 0) {    
        var revno=0;
        if(this.state.hiddenAmendDocument==true){
          if(this.state.popup==false){
            revno=response.data[0].revno;
          }else{
            revno=response.data[0].revno + 1;
          }
        }else{
          revno=response.data[0].revno;
        }

        this.setState({
          discipline:response.data[0].discipline==null ? "Nill" :  response.data[0].discipline.disciplineName,
          disciplineVal:response.data[0].discipline==null ? 0 :  response.data[0].discipline.id,

          iclistno:response.data[0].code,
          iclist:response.data[0].description,
          approvaldateAck: this.setDateFormat(new Date),
          approvaldate:new Date(response.data[0].date),
          revno:revno,
       }); 
      }  
      else { 
        this.setState({
          discipline:"",
          disciplineVal: 0,
 
          iclistno:"",
          iclist:"",
          approvaldateAck:"",
          approvaldate:null,
          revno:0
        })
     }
}

deleteCheckListLog =async (pid)=>{

    const prm = { id: pid  };
    const response = await handleRequest("POST", "/CheckListLog/delete", prm);
    
    if(response.data.includes('Successfull'))
    {
      this.showSuccess(response.data);
      this.getCheckListLog(this.state.documentLogid);
    } 
    else  {
      if(response.data.length>0){
        this.showError(response.data);
      }else{
        this.showError("An error was occured please try again later !");
      }
    }
}
  
getITP = async () => { 
    const newObj = {
      documentLogid: this.state.documentLogid
    }  
    const response = await handleRequest("POST", "/Itp/getParentDocument", newObj); 
    if (response.data.length !== 0) {  
      await this.setState({ 
        itpcode:response.data[0].code,
        itpname:response.data[0].description,
        itpid:response.data[0].itpid,
      }); 
    }  
    else { 
      await this.setState({ 
        itpcode:"",
        itpname:"",
        itpid:0
      });
    }
}

getEquipmentCodeName= async (equipmentTypeId) => { 
    const newObj = {
      equipmentTypeId: equipmentTypeId
    } 
   const response = await handleRequest("POST", "/EquipmentLog/getCodeName", newObj); 
    if (response.data.length !== 0) {
        const columns = response.data;
        const options = columns.map(function (row) {
            return { name: row.equipmentCodeName.toString(), id: row.id.toString() }
        })
        this.setState({
            EquipmentCodeName: options,
            equipmentTypeName:response.data[0].equipmentType.equipmentTypeName
        });
    }else{
      this.setState({
        EquipmentCodeName: [],
        equipmentTypeName:""
    });
    }
} 

getEquipmentType= async () => { 
  const response = await handleRequest("GET", "/EquipmentType/getNodes");

  if (response.length === 0 || response.data.length === 0) {
      this.setState({
          nodes: []
      });
  } else if (response.data.length !== 0) {
    generateList(response.data); 
      this.setState({
          nodes: response.data
      });
  }
} 

onChangeEquipmentType = async (value, data) => {    
  console.log("val", value);
  console.log(data);
  await this.setState({
      values: value,
      selectedItems: data,
      equipmentId:0,
      equipment:"" 
  })

  if(Boolean(value) && value!=null && value!=undefined && value!=""){
    if (value.length != 0) {
      this.getEquipmentCodeName(value[value.length - 1] + "");
    } else {
      this.getEquipmentCodeName("-1");
    }
  }else{
    this.getEquipmentCodeName("-1");
  }
}  

handleChangeEquipment = e => {
  e.preventDefault();
  var IdValue;

  for (var z = 0; z < this.state.EquipmentCodeName.length; z++) {
      if (this.state.EquipmentCodeName[z].name === e.target.value) {
          IdValue = this.state.EquipmentCodeName[z].id
      }
  }

  this.setState({
      [e.target.name]: e.target.value,
      equipmentId: IdValue,
  });
};

 getCheckListLog= async (deger) => { 
    const newObj = {
      documentLogid: deger
    }  
    const response = await handleRequest("POST", "/CheckListLog/getAll2", newObj);  
    if (response.data.length !== 0) {   
      await this.setState({ 
        datatableValue:response.data,
        id: response.data[0].id,
        itpcheckid: response.data[0].id,
        hiddenSave:true,
        hiddenUpdate:false,
        hiddenAmendDocument:true,
       //revno:response.data[0].revno + 1,
       }); 
    }  
    else { 
      await this.setState({ 
        datatableValue: [] ,
        id: -1,
        hiddenSave:false,
        hiddenUpdate:true,
        hiddenAmendDocument:false,
       // revno:0,
      });
   }
} 

getDsicipline= async () => { 
const response = await handleRequest("GET", "/Discipline/getAll");    
  if (response.data.length !== 0)
  { 
    var rows = []; 
    for (var i = 0; i < response.data.length; i++) {
      rows.push({label:response.data[i].disciplineName,value:response.data[i].id}); 
    }

    this.setState({ disciplinesOfDepartments:rows }); 
  }  else { this.setState({ disciplinesOfDepartments: []  });}
} 

getItpChecklistItems = async () => { 
    const newObj = {
    itpcheckid: this.state.itpcheckid
    }     
    const responseTree = await handleRequest("POST", "/CheckListLog/getTreeItpchecklistitems", newObj);

    if (responseTree.data.length !== 0) {
        this.setState({
        itemsChecklistItems: responseTree.data.treeTable,
        });
    }else{
        this.setState({
        itemsChecklistItems: [],
        });
    }   
}

getItpEquipments = async () => { 
  const newObj = {
   itpcheckid: this.state.itpcheckid 
  }     
  const response = await handleRequest("POST", "/CheckListLog/getItpEquipments", newObj);

  if (response.data.length !== 0) {
      this.setState({
        itemsEquipments: response.data,
        ItemsEquipmentsKayit:response.data.length,
      });
  }else{
      this.setState({
        itemsEquipments: [],
        ItemsEquipmentsKayit:0,
      });
  }   
}

getItpChecklists = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid 
  }     
  const response = await handleRequest("POST", "/CheckListLog/getItpChecklists", newObj);

  if (response.data.length !== 0) {
    var deger=false;
    if(!(response.data[0].layoutdrawnumberNames.length==0 && response.data[0].referencedocNames.length==0)){
      deger=true;
    }
    this.setState({
      Checklists: response.data,
      documentsKayitKismiGoster:deger,
    });
    // this.addChecklist();
  }else{
    this.setState({
      Checklists: [],
      documentsKayitKismiGoster:false,
    });
    this.addChecklist();
  }   
}

butonbirClick = () => {
  if (this.state.butonbirDesc === "Add New") {
    this.setState({ checklistKayitKismiGoster:false,  butonbirDesc: "Cancel",  butonbirVariant: "danger"  });   }
  else {  this.setState({   checklistKayitKismiGoster:true,  butonbirDesc: "Add New",    butonbirVariant: "info"  });  } 
}

butonEquipClick = () => {
  if (this.state.butonEquipDesc === "Add New") {
    this.setState({ 
      equipmentKayitKismiGoster:false,  
      hiddenSaveEquipments:false,
      hiddenUpdateEquipments:true,
      butonEquipDesc: "Cancel",  
      butonEquipVariant: "danger",
    });   
  }
  else {  this.setState({   
    equipmentKayitKismiGoster:true,  
    butonEquipDesc: "Add New",    
    butonEquipVariant: "info"  
  });  
} 
}

butonikiSkip = () => {
  document.getElementById('equipmentAccordion').className = "collapse "; 
  document.getElementById('cheklistitemsAccordion').className = "collapse show"; 
}

butonChecklistItemsClick = () => {
  if (this.state.butonChecklistItemsDesc === "Add New") {
    this.setState({ 
      checkitemKayitKismiGoster:false,
      hiddenSaveChecklistItems:false,
      hiddenUpdateChecklistItems:true,
      butonChecklistItemsDesc: "Cancel",  
      butonChecklistItemsVariant: "danger"  
    });   
  }
  else {  
    this.setState({   
    checkitemKayitKismiGoster:true,  
    hiddenSaveChecklistItems:false,
    hiddenUpdateChecklistItems:true,
    butonChecklistItemsDesc: "Add New",    
    butonChecklistItemsVariant: "info"  
  });  
}
}

createButtonClick = () => {
  if (this.state.createNewButton === "Create New") {
    this.setState({
      hidden: false, 
      createNewButton: "Cancel",
      lastButton: "Save",
      createNewButtonVariant: "danger"
    });
    this.refreshAccordions();
  }
  else {
    this.setState({
      hidden: true, 
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info"
    });
    this.refreshAccordions();
    }
    this.getItpChecklistItems();
}

refreshAccordions = () => {  
    document.getElementById('cheklistAccordion').className = "collapse show"; 
    document.getElementById('documentsAccordion').className = "collapse ";  
    document.getElementById('equipmentAccordion').className = "collapse "; 
    document.getElementById('cheklistitemsAccordion').className = "collapse ";  
}

handleChange = e => { 
    
  e.preventDefault();
  this.setState({
    [e.target.name]: e.target.value
  });
};

setDateFormat(d) { //01/02/2011 ->2011-02-01
  if(d!=null)
  {var dt = new Date(d);
  return ('' +dt.getFullYear().toString().padStart(4, '0')+'-'   +(dt.getMonth()+1).toString().padStart(2, '0') +'-' +dt.getDate().toString().padStart(2, '0')   ) ;
  }else return'';
} 
 
//  onChangedisciplineVal= e => {
 
//   const { itpAll } = this.state;  

//   this.setState({   disciplineVal: e.target.value  });

//   var dt= itpAll.filter(x=>x.disciplineid==e.target.value )
   
//   var rows = []; 
//   var rows2 = []; 
//       for (var i = 0; i < dt.length; i++) {

//         rows.push({label:dt[i].itpcode,value:dt[i].id}); 
//         rows2.push({label:dt[i].itpname,value:dt[i].id}); 
//       }
 
//    this.setState({ itpCode:rows ,itpName:rows2}); 


// }

onChangeItpCode= e => {
  console.log(e.target.value); 
  this.setState({  iptCodeVal: e.target.value ,iptNameVal: e.target.value,itpcheckid:e.target.value});
 

}

onChangeItpName= e => {
  console.log(e.target.value);   
  this.setState({  iptCodeVal: e.target.value ,iptNameVal: e.target.value,itpcheckid:e.target.value});
 
}

rowExpansionTemplate(data) {
  console.log(data); 
 return  <ChecklistLogExpand row={data} />; 
}

// convertdateR(rowData, column) {
  
//   var d=null;
//   try { 
//   if(column.field=='approvaldate')
//    d = rowData.approvaldate;
//    if(column.field=='itp.approvaldate')
//     d = rowData.itp.approvaldate; 
//   } catch (error) {
//       d=null;
//   } 
//   if (d != null) {
//       var dt = new Date(d);
//       return (''
//           + dt.getDate().toString().padStart(2, '0') + '/'
//           + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
//           + dt.getFullYear().toString().padStart(4, '0') + ' '
//           + dt.getHours().toString().padStart(2, '0') + ':'
//           + dt.getMinutes().toString().padStart(2, '0') + ':'
//           + dt.getSeconds().toString().padStart(2, '0'));
//   } else return '';
// }

getReferenceDocs = async () => {
  const response = await handleRequest("GET", "/DocumentLog/getAll");
  if (response.data.length !== 0) {
    const columns = response.data;
    const options = columns.map(function (row) {
      return { label: row.description, value: row.id }
    })
    this.setState({
      optionReferenceDocs: options
    });
    console.log("KKK");
    console.log(options);
    console.log(this.state.optionReferenceDocs);
  } else {
    this.setState({
      optionReferenceDocs: [],
    });
  }
}
 
actionChecklistItems(rowData, column) {
  return <React.Fragment> 
    <CustomMaterialMenu row={rowData} 
      onDeleteRow={this.deleteChecklistItems.bind(this)} 
      onEditRow={this.changeButtonChecklistItems.bind(this)} />   
    </React.Fragment>;
};

actionDocuments(rowData, column) {
  return <React.Fragment> 
    <CustomMaterialMenu row={rowData} 
      onDeleteRow={this.deleteDocuments.bind(this)} 
      onEditRow={this.changeButtonDocuments.bind(this)} />   
    </React.Fragment>;
};

actionEquipments(rowData, column) {
  return <React.Fragment> 
    <CustomMaterialMenu row={rowData} 
      onDeleteRow={this.deleteEquipments.bind(this)} 
      onEditRow={this.changeButtonEquipments.bind(this)} />   
    </React.Fragment>;
};
 
deleteModal = (row) => { 
  const that = this; 
    confirm({
        title: 'Are you sure delete this Checklist ?',
        icon: <ExclamationCircleOutlined />,
        content: 'Selected Checklist will be deleted !',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() { that.deleteCheckListLog(row.id) }, 
        onCancel() {
        },
    }); 
}  

renderDocumentDesc(rowData) {
  if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
      return rowData.documentLog.description;
  }
  return "";
}; 

renderDisciplineName(rowData) {
  if (rowData.dis !== null && rowData.dis !== undefined) {
      return rowData.dis.disciplineName;
  }
  return "Nill";
}; 

renderItpCode(rowData) {
  if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
      return rowData.documentLog.code;
  }
  return "";
}; 

renderApprovaldate(rowData) {
  if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
    return moment(rowData.documentLog.date).format("DD/MM/YYYY");
 }
 return "";
}; 

showOrHideModalMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  }); 
}

addAllCheckList = async() => {
  const { Checklists } = this.state;  
  const prm = Checklists[0];
  var flag= true;  

  console.log("add",prm);

  if(flag && Checklists.length==1 )
  {
      const response = await handleRequest("POST", "/CheckListLog/add", prm);

      if(response.data.includes('Successfull'))
      {
        this.showSuccess(response.data);
        this.createButtonClick();
        this.setState({ Checklists:[ ]});
        this.getCheckListLog(this.state.documentLogid);
      } 
      else  {
        if(response.data.length>0){
          this.showError(response.data);
        }else{
          this.showError("An error was occured please try again later !");
        }
      } 
   }
   else {
      this.showWarning("Please Add Check List items");
      document.getElementById('cheklistAccordion').className = "collapse"; 
      document.getElementById('documentsAccordion').className = "collapse ";  
      document.getElementById('equipmentAccordion').className = "collapse "; 
      document.getElementById('cheklistitemsAccordion').className = "collapse show";  
  } 
} 

addChecklist=()=>{  
  const documentLog=[]; 
  const dis=[]; 
  documentLog.push({
    code: this.state.itpcode,
    description:this.state.itpname,
    revno:this.state.revno,
    date:this.state.approvaldate,
  }); 

  dis.push({
    disciplineName: this.state.discipline, 
  }); 

  this.setState(state => ({
    Checklists:[...state.Checklists, { 
    id:this.state.id, 
    approvaldate:new Date(this.state.approvaldateAck),
    disciplineid:this.state.disciplineVal,
    itpcheckid:this.state.itpcheckid,
    iclistno:this.state.iclistno ,
    iclist:this.state.iclist , 
    revno:this.state.revno,
    itpcode:this.state.itpcode,
    itpname: this.state.itpname,
    itpid: this.state.itpid,
    referencedoc: [] , 
    referencedocNames: [],
    workpermitreq: this.state.workpermitreqCheck,
    layoutdrawnumber: [] ,
    layoutdrawnumberNames: [],
    documentLogid:this.state.documentLogid,
    documentLog:documentLog[0],
    dis:dis[0],
    checkitem:[],
    equip:[],
    }]
  }));  

  if(!(this.state.layoutdrawnumberNames=="" && this.state.referencedocNames=="")){
    this.setState({
      documentsKayitKismiGoster:true
    });
  }

  this.setState({
    referencedoc:[],
    layoutdrawnumber:[], 
    referencedocNames:[],
    layoutdrawnumberNames:[],
    workpermitreqCheck:true,
  });  
  this.butonbirClick(); 
}
 
updateDone = async () => {
  const { Checklists } = this.state;  
 
  Checklists.forEach(element => {
    element.revno=this.state.revno;
    element.disciplineid=this.state.disciplineVal;
    element.iclistno=this.state.iclistno;
    element.iclist=this.state.iclist;
    element.approvaldate=this.state.approvaldate;
    element.itpcode=this.state.itpcode;
    element.itpname=this.state.itpname;
    element.itpid=this.state.itpid;
  }); 

  const prm = Checklists[0];
  var flag= true;  

  console.log("update", prm);
 
  if(flag && Checklists.length==1 && this.state.documentLogid>0 )
  { 
      const response = await handleRequest("POST", "/CheckListLog/update", prm);

      if(response.data.includes('Successfull'))
      {
        this.showSuccess(response.data);
        this.createButtonClick();
        this.setState({ Checklists:[ ]});
        this.getCheckListLog(this.state.documentLogid);
      } 
      else  {
        if(response.data.length>0){
          this.showError(response.data);
        }else{
          this.showError("An error was occured please try again later !");
        }
      } 
   }
   else {
      this.showWarning("Please Add Check List items");
      document.getElementById('cheklistAccordion').className = "collapse"; 
      document.getElementById('documentsAccordion').className = "collapse ";  
      document.getElementById('equipmentAccordion').className = "collapse "; 
      document.getElementById('cheklistitemsAccordion').className = "collapse show";  
  } 
};

changeButtonUpdate = async (rowData) => {
  await this.setState({
    hidden: false,
    hiddenUpdate: false,
    hiddenSave: true,
    hiddenCreateNew: false,
    
    id: rowData.id,
    itpcheckid:rowData.id,
    disciplineVal: rowData.dis==null ? 0 : rowData.dis.id,
    discipline:  rowData.dis==null ? "Nill" : rowData.dis.disciplineName,
    itpcode: rowData.documentLog== null ? "" : rowData.documentLog.code,
    itpname: rowData.documentLog== null ? "" : rowData.documentLog.description,
    approvaldate: rowData.documentLog== null ? null : new Date(rowData.documentLog.date),
    approvaldateAck: rowData.documentLog== null ? "" : moment(rowData.documentLog.date).format("MM/DD/YYYY"), 
    //revno: rowData.documentLog== null ? 0 : rowData.documentLog.revno,

    itemsEquipments:rowData.equip,

    createNewButton: "Cancel",
    createNewButtonVariant: "danger"
  });

  this.getItpChecklistItems();
  this.getItpChecklists();

};

changeButtonCancel = () => {
  this.setState({
    hiddenSave: true,
    hiddenUpdate:false,
    hidden: true,
    createNewButton: "Create New",
    lastButton: "Save",
    createNewButtonVariant: "info",
  });
  this.refreshAccordions();
  this.redirectDocument();
};  

addCheckListItems= async ()=>{ 
  const { Checklists } = this.state;
  const i=Checklists.length;
  if(i>0)
  {
    if(Boolean(this.state.checklistItemsitemno) && this.state.checklistItemsitemno!=""&& this.state.checklistItemsAcceptanceCriteria!="") {
      const itemsChecklistItems={  
      key: this.state.checklistItemsitemno + "" + this.state.checklistItemsAcceptanceCriteria+ "" + this.state.checklistItemsReference,
      checklistItemsitemno:this.state.checklistItemsitemno ,
      checklistItemsAcceptanceCriteria:this.state.checklistItemsAcceptanceCriteria , 
      checklistItemsReference:this.state.checklistItemsReference,
      checklistGroup: this.state.checklistGroup   } ;

      Checklists[i-1].checkitem.push(itemsChecklistItems);
      // await this.setState({itemsChecklistItems:Checklists[i-1].checkitem });

    await this.setState(state => ({
     StateItemsChecklistItems: [...state.StateItemsChecklistItems,{
        key: this.state.checklistItemsitemno + "" + this.state.checklistItemsAcceptanceCriteria+ "" + this.state.checklistItemsReference,
        checklistItemsitemno:this.state.checklistItemsitemno ,
        checklistItemsAcceptanceCriteria:this.state.checklistItemsAcceptanceCriteria,
        checklistItemsReference:this.state.checklistItemsReference,
        checklistGroup: this.state.checklistGroup
      }]
    }));

    this.setState(prevState => ({
      itemsChecklistItems: [...prevState.itemsChecklistItems, {key: this.state.checklistItemsitemno + "" + this.state.checklistItemsAcceptanceCriteria+ "" + this.state.checklistItemsReference, data: this.state.StateItemsChecklistItems[this.state.StateItemsChecklistItems.length-1], children:[] }]
    }))

    await this.setState( {
      checklistItemsitemno: "",
      checklistItemsAcceptanceCriteria: "",
      checklistItemsReference: "",
      key:"",
      checklistGroup:false,
    });
    this.butonEquipClick();
  }else{
    this.showWarning();
  } 
  }else{
    this.showWarning("Please Add Check List");
  }
  this.setState({Checklists:Checklists  });   
}  

changeButtonChecklistItems = async (rowData) => {
  console.log("Chc", rowData);
  if(rowData.data.checklistGroup){
    this.setState({ 
      hiddenReference:true,
      checklistItemsReference:"",
      AcceptanceCriteria:"Group Description",
    });
  }else{
    this.setState({ 
      hiddenReference:false,
      AcceptanceCriteria:"Acceptance Criteria",
     })  
  }

  await this.setState({
    hiddenUpdateChecklistItems: false,
    hiddenSaveChecklistItems: true,
    hiddenCreateNewChecklistItems: true,
    checkitemKayitKismiGoster:false,
    
    keyChecklistItem: rowData.data.key,
    checklistItemsitemno: rowData.data.checklistItemsitemno,
    checklistItemsAcceptanceCriteria: rowData.data.checklistItemsAcceptanceCriteria,
    checklistItemsReference: rowData.data.checklistItemsReference,
    checklistGroup: rowData.data.checklistGroup,
  }); 
};

updateDoneChecklistItems = async () => {
  const { Checklists } = this.state;  
  const { itemsChecklistItems } = this.state;  
  const i=Checklists.length;
  if(i>0) {
    if(Boolean(this.state.checklistItemsitemno) && this.state.checklistItemsitemno!=""&& this.state.checklistItemsAcceptanceCriteria!="") {
  
  var Checkitem = Checklists[0].checkitem.filter(p => p.key == this.state.keyChecklistItem);
  var Table = itemsChecklistItems.filter(p => p.key == this.state.keyChecklistItem);

  Checkitem.forEach(element => {
    element.checklistItemsitemno=this.state.checklistItemsitemno;
    element.checklistItemsAcceptanceCriteria=this.state.checklistItemsAcceptanceCriteria;
    element.checklistItemsReference=this.state.checklistItemsReference;
    element.checklistGroup=this.state.checklistGroup;
  }); 

  Table.forEach(element => {
    element.data.checklistItemsitemno=this.state.checklistItemsitemno;
    element.data.checklistItemsAcceptanceCriteria=this.state.checklistItemsAcceptanceCriteria;
    element.data.checklistItemsReference=this.state.checklistItemsReference;
    element.data.checklistGroup=this.state.checklistGroup;
  }); 

  this.setState({
    itemsChecklistItems:itemsChecklistItems,
    checklistItemsitemno:"",
    checklistItemsAcceptanceCriteria:"",
    checklistItemsReference:"",
    hiddenCreateNewChecklistItems:false,
    checkitemKayitKismiGoster:true,
    butonChecklistItemsDesc:"Add New",
    butonChecklistItemsVariant: "info",
    checklistGroup:false,
  });

}else{
  this.showWarning();
} 
}else{
  this.showWarning("Please Add Check List");
}
};

changeButtonCancelChecklistItems = async () => {
  this.setState({
    checklistItemsitemno:"",
    checklistItemsAcceptanceCriteria:"",
    checklistItemsReference:"",
    hiddenCreateNewChecklistItems:false,
    checkitemKayitKismiGoster:true,
    butonChecklistItemsDesc:"Add New",
    butonChecklistItemsVariant: "info",
    checklistGroup:false,
  });

};

deleteChecklistItems = async (rowData) => {
  console.log("Chc", rowData);

  const { Checklists } = this.state;  
  const { itemsChecklistItems } = this.state;   
  let flagkeyChecklistItem = rowData.key;

  Checklists[0].checkitem.splice(Checklists[0].checkitem.findIndex(function (i) {
    return i.key + "" === flagkeyChecklistItem;
  }), 1);

  itemsChecklistItems.splice(itemsChecklistItems.findIndex(function (i) {
    return i.key === flagkeyChecklistItem;
  }), 1);

  console.log("Chc", this.state.Checklists);
  console.log(this.state.itemsChecklistItems);
  console.log(Checklists);
  console.log(itemsChecklistItems);

  this.setState({
    itemsChecklistItems:itemsChecklistItems,
  });
  
};
  
addChecklistDocument= async()=>{ 
  if(!(this.state.layoutdrawnumberNames=="" && this.state.referencedocNames=="")){

    const { Checklists } = this.state;
    const i=Checklists.length;
    var Documents = Checklists.filter(p => p.id == this.state.Checklists[i-1].id);
    Documents.forEach(element => {
      element.layoutdrawnumber=this.state.layoutdrawnumber;
      element.layoutdrawnumberNames=this.state.layoutdrawnumberNames;
      element.referencedoc=this.state.referencedoc;
      element.referencedocNames=this.state.referencedocNames; 
      element.workpermitreq=this.state.workpermitreqCheck;
      element.islTip="";
    }); 

    this.setState({
      hiddenUpdateDocuments: false,
      hiddenSaveDocuments: true,
      Checklists:Checklists,
      documentsKayitKismiGoster:true,
      referencedoc:[],
      layoutdrawnumber:[], 
      referencedocNames:[],
      layoutdrawnumberNames:[],
      workpermitreqCheck:true,
    });  

    console.log("doc", Checklists);

    this.butonbirClick(); 
    }
    else{
      this.showWarning();
    }
} 

changeButtonDocuments = async (rowData) => {
  console.log("Doc", rowData);
 
  var selectDoc = [];
  if(rowData.referencedoc!=null && rowData.referencedoc!=undefined){
    var flagValue = rowData.referencedoc;
    flagValue.forEach(element => {
      selectDoc.push(element + "");
    });
  }

  var selectLayoutDrawing = [];
  if(rowData.layoutdrawnumber!=null && rowData.layoutdrawnumber!=undefined){
    var flagValue = rowData.layoutdrawnumber;
    flagValue.forEach(element => {
      selectLayoutDrawing.push(element + "");
    });
  }

  await this.setState({
    hiddenUpdateDocuments: false,
    hiddenSaveDocuments: true,
    documentsKayitKismiGoster:false,
    
    idDocuments: rowData.id,
    layoutdrawnumberNames: rowData.layoutdrawnumberNames,
    layoutdrawnumber: rowData.layoutdrawnumber,
    referencedocNames:rowData.referencedocNames,
    referencedoc:rowData.referencedoc,

    selectedReferenceDoc: selectDoc,
    selectedLayoutDrawingNumber: selectLayoutDrawing,

    workpermitreq: rowData.workpermitreq,
    workpermitreqCheck: rowData.workpermitreq
  }); 
};

updateDocumentsDone = async () => {
  const { Checklists } = this.state;
  
  var Documents = Checklists.filter(p => p.id == this.state.idDocuments);
  console.log("DOC", Documents);

  Documents.forEach(element => {
    element.layoutdrawnumber=this.state.layoutdrawnumber;
    element.layoutdrawnumberNames=this.state.layoutdrawnumberNames;
    element.referencedoc=this.state.referencedoc;
    element.referencedocNames=this.state.referencedocNames;
    element.workpermitreq=this.state.workpermitreqCheck;
    if(this.state.referencedoc.length==0 || this.state.layoutdrawnumber.length==0){
      element.islTip="Sil";
    }else{
      element.islTip="";
    }
  }); 
  
  this.setState({
      hiddenUpdateDocuments: false,
      hiddenSaveDocuments: true,
      Checklists:Checklists,
      documentsKayitKismiGoster:true,
      referencedoc:[],
      layoutdrawnumber:[], 
      referencedocNames:[],
      layoutdrawnumberNames:[],

      selectedReferenceDoc: [],
      checkedReferenceDoc: [],

      selectedLayoutDrawingNumber: [],
      checkedLayoutDrawingNumber: [],

      workpermitreqCheck:true,
  }); 

  console.log("docc", Checklists);
};

changeButtonDocumentsCancel = async () => {
  this.setState({
    hiddenUpdateDocuments: true,
    hiddenSaveDocuments: false,
    documentsKayitKismiGoster:true,
    
    idDocuments: 0,
    layoutdrawnumber: [],
    referencedoc:[],
    selectedReferenceDoc: [],
    checkedReferenceDoc: [],
    selectedLayoutDrawingNumber: [],
    checkedLayoutDrawingNumber: [],
    workpermitreq: true,
    workpermitreqCheck: true
  });
};

deleteDocuments = async (rowData) => {
  const { Checklists } = this.state;  

  var Documents = Checklists.filter(p => p.id == rowData.id);

  Documents.forEach(element => {
    element.layoutdrawnumber=[];
    element.layoutdrawnumberNames= [];
    element.referencedoc= [];
    element.referencedocNames= [];
    element.workpermitreq= true;
    element.islTip="Sil";
  }); 
  
  this.setState({
      Checklists:Checklists,
      documentsKayitKismiGoster:false,
  }); 
  console.log("Doc", Checklists);
  console.log(this.state.Checklists);
  console.log(Documents);
};

addEquipment= async ()=>{ 
  const { Checklists } = this.state;
  const i=Checklists.length;
    if(i>0)
    {
      if(this.state.values.length > 0 && this.state.equipmentId!=0 && this.state.equipment!="" && Boolean(this.state.quantity)  && this.state.quantity!=0 && this.state.quantity!=""){
        const equipmentType=[];

        await this.setState({
          ItemsEquipmentsKayit:this.state.ItemsEquipmentsKayit+1,
        })

        equipmentType.push({equipmentTypeName: this.state.equipmentTypeName}); 

        await this.setState(state => ({
          itemsEquipments:[...state.itemsEquipments, { 
          key:this.state.ItemsEquipmentsKayit,
          index:this.state.ItemsEquipmentsKayit,
          equipmentType: equipmentType[0],
          equipmentCodeName: this.state.equipment,
          quantity:this.state.quantity
          }]
        }));

        const itemsEquipments={ 
          index:this.state.ItemsEquipmentsKayit,
          equipmentTypeId: this.state.values[this.state.values.length - 1] + "", 
          equipmentId: this.state.equipmentId,
          quantity:this.state.quantity 
        };

        Checklists[i-1].equip.push(itemsEquipments);

        await this.setState({
          values:[],
          index: "",
          equipmentCodeName: "",
          quantity: "",
          equipment:"",
          selectedItems:[],
          equipmentId:0,
        });

        console.log("LLL");
        console.log(Checklists[i-1]);
        console.log(itemsEquipments);
    
      }else{
        this.showWarning();
      } 
    }else{
      this.showWarning("Please Add Check List items");
    }
  this.setState({Checklists:Checklists  });
} 

changeButtonEquipments = async (rowData) => {
  console.log("Eq", rowData); 

  var deger = dataList.filter(p=>p.key==rowData.equipmentTypeId)
  if(deger!=null && deger!='undefined' && deger!=''){
    console.log("deger", deger);
      this.setState({
          values:deger[0].lineage
      });
  }else{
      this.setState({
          values:[]
      });
  } 
  this.getEquipmentCodeName(rowData.equipmentTypeId);

  await this.setState({
    hiddenUpdateEquipments: false,
    hiddenSaveEquipments: true,
    hiddenCreateNewEquipments: true,
    equipmentKayitKismiGoster:false,
    
    keyEquipments: rowData.index,
    equipment: rowData.equipmentCodeName,
    equipmentId: rowData.equipmentId,
    equipmentTypeId: rowData.equipmentTypeId,
    quantity: rowData.quantity,
  }); 
};

updateDoneEquipments = async () => {
  const { Checklists } = this.state;  
  const { itemsEquipments } = this.state;  

  console.log("Checklists", Checklists);
  console.log("itemsEquipments", itemsEquipments);

  const i=Checklists.length;
  if(i>0) {
    if(this.state.values.length > 0 && this.state.equipmentId!=0 && this.state.equipment!="" && Boolean(this.state.quantity)  && this.state.quantity!=0 && this.state.quantity!=""){
     
  var Equip = Checklists[0].equip.filter(p => p.index == this.state.keyEquipments);
  var Table = itemsEquipments.filter(p => p.index == this.state.keyEquipments);

  Equip.forEach(element => {
    element.equipmentTypeId=this.state.values[this.state.values.length - 1] + "";
    element.equipmentId=this.state.equipmentId;
    element.quantity=this.state.quantity;
  }); 

  Table.forEach(element => {
    element.equipmentTypeId=this.state.values[this.state.values.length - 1] + "";
    element.equipmentId=this.state.equipmentId;
    element.quantity=this.state.quantity;
    element.equipmentCodeName=this.state.equipment;
    element.equipmentType.equipmentTypeName=this.state.selectedItems[this.state.selectedItems.length - 1].label;
  }); 

  console.log("eq", itemsEquipments);
  console.log(this.state.selectedItems[this.state.selectedItems.length - 1]);
  console.log(Equip);
  console.log(Table);
  console.log(Checklists);

  this.setState({
    itemsEquipments:itemsEquipments,
    equipment:"",
    equipmentId:"",
    quantity:"",
    values:[],
    hiddenCreateNewEquipments:false,
    equipmentKayitKismiGoster:true,
    butonEquipDesc:"Add New",
    butonEquipVariant: "info",
  });

}else{
  this.showWarning();
} 
}else{
  this.showWarning("Please Add Check List");
}
};

changeButtonCancelEquipments = async () => {
  this.setState({
    equipment:"",
    equipmentId:"",
    quantity:"",
    values:[],
    hiddenCreateNewEquipments:false,
    equipmentKayitKismiGoster:true,
    butonEquipDesc:"Add New",
    butonEquipVariant: "info",
  });

};

deleteEquipments = async (rowData) => {
  console.log("Chc", rowData);

  const { Checklists } = this.state;  
  const { itemsEquipments } = this.state;   
  let flagkeyEquipments = rowData.index;

  console.log(Checklists);
  console.log(itemsEquipments);

  Checklists[0].equip.splice(Checklists[0].equip.findIndex(function (i) {
    return i.index === flagkeyEquipments;
  }), 1);

  itemsEquipments.splice(itemsEquipments.findIndex(function (i) {
    return i.index === flagkeyEquipments;
  }), 1);

  var ItpEquipments = Checklists.filter(p => p.id == rowData.itpcheckid);

    ItpEquipments.forEach(element => {
      element.islTip="Sil";
    }); 
  
  this.setState({
    Checklists:Checklists,
    itemsEquipments:itemsEquipments,
  });
  
};

onReferenceDocChange = async (value, label, extra) => { 
  if(Boolean(value) && value.length>0){
    var selectNames = [];
    var flagArray = label;
    flagArray.forEach(element => {
          selectNames.push(element + ", ");
    });

    var selectDoc = [];
    var flagValue = value;
    flagValue.forEach(element => {
      selectDoc.push(parseInt(element, 10));
    });

    this.setState({
      selectedReferenceDoc: value,
      checkedReferenceDoc: label,
      referencedoc: selectDoc,
      referencedocNames: selectNames
    });
  }else{
    this.setState({
      selectedReferenceDoc: value,
      checkedReferenceDoc: label,
      referencedoc: value,
      referencedocNames: label
    });
  }
};

onLayoutDrawingNumberChange = async (value, label, extra) => { 
  if(Boolean(value) && value.length>0){
    var selectNames = [];
    var flagArray = label;
    flagArray.forEach(element => {
      selectNames.push(element + ", ");
    });

    var selectDoc = [];
    var flagValue = value;
    flagValue.forEach(element => {
      selectDoc.push(parseInt(element, 10));
    });

    this.setState({
      selectedLayoutDrawingNumber: value,
      checkedLayoutDrawingNumber: label,
      layoutdrawnumber: selectDoc,
      layoutdrawnumberNames: selectNames
    });
  }else{
    this.setState({
      selectedLayoutDrawingNumber: value,
      checkedLayoutDrawingNumber: label,
      layoutdrawnumber: value,
      layoutdrawnumberNames: label
    });
  }
};

render() {

    const referenceDocProps = {
      treeData: this.state.referenceDocData,
      value: this.state.selectedReferenceDoc,
      onChange: this.onReferenceDocChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Please select',
      style: {
        width: '100%',
      },
    };

    const layoutDrawingNumberProps = {
      treeData: this.state.layoutDrawingNumberData,
      value: this.state.selectedLayoutDrawingNumber,
      onChange: this.onLayoutDrawingNumberChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Please select',
      style: {
        width: '100%',
      },
    };

    let optionsEquipment = this.state.EquipmentCodeName.map((data) =>
    <option
        key={data.id}
        value={data.name}
    >
        {data.name}
    </option>
    );

    const expandedRowRender = (data) => {
      return <ChecklistLogExpand row={data} />;
    }

    return (
      <div>  
        <ModalReact
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <ModalReact.Header closeButton>
              <ModalReact.Title id="contained-modal-title-vcenter">
              <Row > 
                <Col sm="12">
                  {this.state.messageTip=="Success" &&  
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                
                  {this.state.messageTip=="Warning" &&  
                  <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip=="Error" &&  
                  <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                </Col>
              </Row>
              </ModalReact.Title>
          </ModalReact.Header>

          <ModalReact.Body>
             <Row > 
              <Col sm="12">
                 {this.state.message} 
              </Col>
              </Row>
          </ModalReact.Body>
          <ModalReact.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" /></Button>
          </ModalReact.Footer>
        </ModalReact>
     
        <div style={{ backgroundColor: "white", padding: "10px" }}  > 
          <Row > 
            <Col sm="12" md={{ size: 2, offset: 10 }}>
              {/* <Button hidden={this.state.hiddenAmendDocument} onClick={this.createButtonClick} style={{ width: '100%' }}
              variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
              </Button> */}
              </Col>
          </Row>
        </div>
        <br />
        <div  hidden={this.state.hidden} >  
        <br/>
        
        <div hidden={!this.state.hiddenAmendDocument} >  
        <Accordion defaultActiveKey="1">
          <Card>
          <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Check List</Card.Header></Col>
                </Row>
              </Accordion.Toggle> 
            <Accordion.Collapse id="cheklistAccordion" eventKey="0">
              <Card.Body>
              <Row>
                {/* <Col sm="12" md={{ size: 2, offset: 10 }}>
                  <Button onClick={this.butonbirClick} style={{ width: '100%' }}
                  variant={this.state.butonbirVariant} >{this.state.butonbirDesc}
                  </Button>
                </Col> */}
              </Row>
              <div style={{ backgroundColor: "white", padding: "10px" }} >
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <form> 
                      
                      <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}  > 
                        <Form.Label style={{ color: 'black' }} column sm="3">  
                          Discipline   
                        </Form.Label>
                        <Col sm="4">
                        {/* <Dropdown id="disciplineID"
                          value={this.state.disciplineVal} options={this.state.disciplinesOfDepartments} 
                          onChange={this.onChangedisciplineVal}  style={{width: '200px'}}
                          filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true}/> */}
                          <InputText readOnly="true" style={{ width: '100%' }} 
                        value={this.state.discipline} placeholder="" />
                        </Col>  
                      </Form.Group>
                  
                      <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}  > 
                        <Form.Label style={{ color: 'black' }} column sm="3">  
                          Itp Code    
                        </Form.Label>
                        <Col sm="4">
                        {/* <Dropdown id="disciplineID"  
                          value={this.state.iptCodeVal} options={this.state.itpCode} 
                          onChange={this.onChangeItpCode}  style={{width: '200px'}}
                          filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true}/> */}
                          <InputText readOnly="true" style={{ width: '100%' }} 
                        value={this.state.itpcode} placeholder="" />
                        </Col>  
                      </Form.Group>
                  
                      <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}  > 
                        <Form.Label style={{ color: 'black' }} column sm="3">  
                          Itp Name    
                        </Form.Label>
                        <Col sm="4">
                        {/* <Dropdown id="disciplineID"
                          value={this.state.iptNameVal} options={this.state.itpName}  
                          onChange={this.onChangeItpName}  style={{width: '200px'}}
                          filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true}/> */}
                            <InputText readOnly="true" style={{ width: '100%' }} 
                        value={this.state.itpname} placeholder="" />
                        </Col>  
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridiclistno">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          I&C List No
                        </Form.Label>
                        <Col sm="4">
                        <Form.Control readOnly="true" name="iclistno" value={this.state.iclistno}
                        onChange={this.handleChange} />
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group> 

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridiclist">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          I&C List Desc
                        </Form.Label>
                        <Col sm="4">
                        <Form.Control readOnly="true" name="iclist" value={this.state.iclist}
                        onChange={this.handleChange} />
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridrevno">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Rev. No
                        </Form.Label>
                        <Col sm="4">
                          <InputText readOnly="true" style={{ width: '100%' }} 
                          value={this.state.revno} placeholder="" />
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridapprovaldate">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Approval Date
                        </Form.Label>
                        <Col sm="4">
                        {/* <Calendar dateFormat="dd/mm/yy" value={this.state.approvaldate} showButtonBar={true} style={{width: '200px'}}
                      onChange={(e) => this.setState({ approvaldate: e.value })} monthNavigator={true}  autocomplete="on"
                      yearNavigator={true} yearRange="2010:2020" /> */}
                          <InputText readOnly="true" style={{ width: '100%' }} 
                        value={this.state.approvaldateAck} placeholder="" />
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group>  
                    </form>
                  </div>
                </div>
              </div> 
              <br/>
        
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        </div> 
         <br />
        <Accordion defaultActiveKey="4">
          <Card>
          <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Documents</Card.Header></Col>
                </Row>
            </Accordion.Toggle> 
            <Accordion.Collapse id="documentsAccordion" eventKey="0">
              <Card.Body>

            <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.documentsKayitKismiGoster} >
              <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridreferencedoc">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                    Reference Doc.
                    </Form.Label>
                    <Col sm="5"> 
                       <TreeSelect  {...referenceDocProps} />
                    </Col>
                    <Col sm="4">
                    </Col>
                  </Form.Group>

              <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridlayoutdrawnumber">
                <Form.Label style={{ color: 'black' }} column sm="3">
                Layout Drawing Number
                </Form.Label>
                <Col sm="5"> 
                  <TreeSelect  {...layoutDrawingNumberProps} />
                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>

              <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridworkpermitreqCheck">
              <Form.Label style={{ color: 'black' }} column sm="3">
                Work Permit Required
                </Form.Label>
                <Col sm="5">
                <ToggleButton style={{ width: '100%' }} onLabel="Yes" offLabel="No" onIcon="pi pi-user" offIcon="pi pi-user" 
                checked={this.state.workpermitreqCheck} onChange={(e) => this.setState({ workpermitreqCheck: e.value })} />
                </Col>
                <Col sm="4">
                </Col> 
              </Form.Group>

            <div hidden={this.state.hiddenSaveDocuments}>
              <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="">
              <Col sm="3">
                </Col>
                <Col sm="5">
                <Button onClick={this.addChecklistDocument} style={{ width: '100%' }} variant="success">Save</Button>
                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>
            </div>

            <div hidden={this.state.hiddenUpdateDocuments}>
              <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                <Row>
                  <Col sm="3">
                  </Col>
                  <Col sm="2">
                    <Button onClick={this.changeButtonDocumentsCancel} style={{ width: '100%' }} variant="danger">Cancel</Button>
                  </Col>
                  <Col sm="3">
                    <Button onClick={this.updateDocumentsDone} style={{ width: '100%' }} variant="warning">Update</Button>
                  </Col>
                  <Col sm="3">
                  </Col>
                </Row>
              </Container>
            </div>

            <br/>
            </div>

              <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th></th>
                        <th>Reference Doc.</th>
                        <th>Layout Drawing Number</th>
                        <th>Work Permit Required</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.Checklists.map((checklist, index) =>
                        !(checklist.referencedocNames=="" && checklist.layoutdrawnumberNames=="") && 
                        <tr key={index}>
                          <td>{this.actionDocuments(checklist)}</td>
                          <td>{checklist.referencedocNames}</td>
                          <td>{checklist.layoutdrawnumberNames}</td>
                          <td>{checklist.workpermitreq==true ? "true" : "false"}</td>
                        </tr>
                      )} 
                    </tbody>
                  </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="3">
          <Card>
          <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Check List Items</Card.Header></Col>
                </Row>
              </Accordion.Toggle> 
            <Accordion.Collapse id="cheklistitemsAccordion" eventKey="0">
              <Card.Body>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                  <Row>
                  <Col sm="12" md={{ size: 2, offset: 10 }}>
                    <Button onClick={this.butonChecklistItemsClick} hidden={this.state.hiddenCreateNewChecklistItems} style={{ width: '100%' }}
                      variant={this.state.butonChecklistItemsVariant} >{this.state.butonChecklistItemsDesc}</Button></Col> 
                  </Row>
                </Container>

                <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.checkitemKayitKismiGoster} >
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <form> 
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridchecklistItemsitemno">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                        Item No
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control name="checklistItemsitemno" value={this.state.checklistItemsitemno} onChange={this.handleChange} />
                        </Col>
                        <Col sm="5">
                        <AntCheckbox checked={this.state.checklistGroup} onChange={(e) => { 
                          if(e.target.checked){
                            this.setState({ 
                              checklistGroup: e.target.checked,
                              hiddenReference:true,
                              checklistItemsReference:"",
                              AcceptanceCriteria:"Group Description",
                            });
                          }else{
                            this.setState({ 
                              checklistGroup: e.target.checked,
                              hiddenReference:false,
                              AcceptanceCriteria:"Acceptance Criteria",
                             })  
                          }
                          }}>Item Group</AntCheckbox>
                        </Col>
                      </Form.Group>   

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridchecklistItemsAcceptanceCriteria">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                        {this.state.AcceptanceCriteria}
                        </Form.Label>
                        <Col sm="4">
                        <Form.Control name="checklistItemsAcceptanceCriteria" value={this.state.checklistItemsAcceptanceCriteria}
                      onChange={this.handleChange} />
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group>   

                      <Form.Group hidden={this.state.hiddenReference} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridchecklistItemsReference">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                        Reference
                        </Form.Label>
                        <Col sm="4">
                        <Form.Control name="checklistItemsReference" value={this.state.checklistItemsReference}
                      onChange={this.handleChange} />
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group>   

                    <div hidden={this.state.hiddenSaveChecklistItems}>
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridlayoutdrawnumber">
                      <Col sm="3">
                        </Col>
                        <Col sm="4">
                        <Button onClick={this.addCheckListItems} style={{ width: '100%' }}  variant="success">Save</Button>
                        </Col>
                        <Col sm="5">
                        </Col>
                      </Form.Group>   
                    </div>

                    <div hidden={this.state.hiddenUpdateChecklistItems}>
                      <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                        <Row>
                          <Col sm="3">
                          </Col>
                          <Col sm="2">
                            <Button onClick={this.changeButtonCancelChecklistItems} style={{ width: '100%' }} variant="danger">Cancel</Button>
                          </Col>
                          <Col sm="3">
                            <Button onClick={this.updateDoneChecklistItems} style={{ width: '100%' }} variant="warning">Update</Button>
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    </form>
                </div>
              </div>
            </div> 
            <br/>

                {/* <Table responsive striped bordered hover>
                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th width={80}>Item No</th>
                      <th>Acceptance Criteria</th>
                      <th>Reference</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    {this.state.itemsChecklistItems.map(itemsChecklistItem => (
                      <tr key={itemsChecklistItem.checklistItemsLength}>
                        <td>{itemsChecklistItem.checklistItemsitemno}</td>
                        <td>{itemsChecklistItem.checklistItemsAcceptanceCriteria}</td>
                        <td>{itemsChecklistItem.checklistItemsReference}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}

                  <TreeTable value={this.state.itemsChecklistItems}>
                      <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="ItpCheckListItems Id" />
                      <Column body={this.actionChecklistItems} style={{ textAlign: 'center', width: '10%' }} />
                      <Column field="checklistItemsitemno" style={{ textAlign: 'center', width: '10%' }} header="Item No." expander></Column> 
                      <Column field="checklistItemsAcceptanceCriteria" style={{ textAlign: 'center', width: '60%' }} header="Acceptance Criteria" filter sortable></Column>
                      <Column field="checklistItemsReference" style={{ textAlign: 'center', width: '20%' }} header="Reference" filter sortable></Column> 
                  </TreeTable>

                <br />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br/>
        <Accordion defaultActiveKey="2">
          <Card>
          <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
              <Row>
                <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                  as="h5">&#x25bc; Equipment</Card.Header></Col>
              </Row>
              </Accordion.Toggle> 
            <Accordion.Collapse id="equipmentAccordion" eventKey="0">
              <Card.Body>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                  <Row>
                    <Col sm="12" md={{ size: 2, offset: 10 }}>
                      <Button onClick={this.butonEquipClick} hidden={this.state.hiddenCreateNewEquipments} style={{ width: '100%' }}
                    variant={this.state.butonEquipVariant} >{this.state.butonEquipDesc}</Button></Col>
                  </Row>
                </Container>
                <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.equipmentKayitKismiGoster} >
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                    <form> 
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridequipmentType">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Equipment Type
                        </Form.Label>
                        <Col sm="5">
                            <Cascader style={{ width: '100%' }} value={this.state.values} options={this.state.nodes} onChange={this.onChangeEquipmentType} changeOnSelect />
                        </Col>
                        <Col sm="4">
                        </Col>
                      </Form.Group>   
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridequipmentName">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                        Equipment
                        </Form.Label>
                        <Col sm="5">
                          <Form.Control name="equipment" value={this.state.equipment}
                            onChange={this.handleChangeEquipment} as="select"  >
                            <option value="">
                                Select Equipment
                            </option>
                            {optionsEquipment}
                          </Form.Control>
                        </Col>
                        <Col sm="4">
                        </Col>
                      </Form.Group>   
                        
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridQuantity">
                        <Form.Label style={{ color: 'black' }} column sm="3">
                        Quantity
                        </Form.Label>
                        <Col sm="5"> 
                            <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ quantity: e })} value={this.state.quantity} placeholder="Quantity" />
                        </Col>
                        <Col sm="4">
                        </Col>
                      </Form.Group> 

                      <div hidden={this.state.hiddenSaveEquipments}>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridlayoutdrawnumber"> 
                        <Col sm="3">
                          </Col>
                          <Col sm="5">
                          <Button onClick={this.addEquipment} style={{ width: '100%' }}  variant="success">Save</Button>
                          </Col>
                          <Col sm="4">
                          </Col>
                        </Form.Group>   
                      </div>

                        <div hidden={this.state.hiddenUpdateEquipments}>
                        <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                          <Row>
                            <Col sm="3">
                            </Col>
                            <Col sm="2">
                              <Button onClick={this.changeButtonCancelEquipments} style={{ width: '100%' }} variant="danger">Cancel</Button>
                            </Col>
                            <Col sm="3">
                              <Button onClick={this.updateDoneEquipments} style={{ width: '100%' }} variant="warning">Update</Button>
                            </Col>
                            <Col sm="3">
                            </Col>
                          </Row>
                        </Container>
                      </div>
                        
                      </form>
                  </div>
                </div>
              </div> 
              <br/>

                <Table responsive striped bordered hover>
                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th></th>
                      <th width={80}>S/N</th>
                      <th>Equipment Type</th>
                      <th>Equipment</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    {this.state.itemsEquipments.map(itemsEquipment => (
                      <tr key={itemsEquipment.index}>
                        <td>{this.actionEquipments(itemsEquipment)}</td>
                        <td>{itemsEquipment.index}</td>
                        <td>{itemsEquipment.equipmentType.equipmentTypeName}</td>
                        <td>{itemsEquipment.equipmentCodeName}</td>
                        <td>{itemsEquipment.quantity}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <br /> 
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
         
          <div hidden={this.state.hiddenSave}>
          <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
            <Row>
              <Button onClick={this.addAllCheckList} style={{ width: '100%' }} variant="success">{this.state.lastButton}</Button>
            </Row>
          </Container>
        </div>
        
          <div hidden={this.state.hiddenUpdate}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="3">
                </Col>
                <Col sm="2">
                  <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</Button>
                </Col>
                <Col sm="3">
                  <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</Button>
                </Col>
                <Col sm="3">
                </Col>
              </Row>
            </Container>
          </div>
        
        </div>

        <br /> 
        <div hidden={this.state.tablehidden} className="col-md-12" style={{ marginTop: '10px' }}>
          <div className="datatable-doc-demo" style={{ backgroundColor: 'white', padding: '10px' }}  >
            <div style={{ marginTop: '30px', marginBottom: '30px' }} className="content-section implementation ">

             {/* <TableAntd columns={dataTablecolumns}  
              responsive
              striped
              bordered
              hover
              scroll={{ x: 1300 }}  
              dataSource={this.state.datatableValue}
              pagination={{
                pageSize: 5,
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "30"],
                total: this.state.datatableValue.length,
                showQuickJumper: true,
                position: "bottomLeft",
              }}
              expandable={{ expandedRowRender }}
              /> */}

                {/* <DataTable ref={(el) => this.dt = el} value={this.state.datatableValue}   
                      style={{minWidth:'1000px'}} virtualScroll={true} id="itptable"
                        className="p-datatable-customers" dataKey="id" rowHover globalFilter={this.state.globalFilter} 
                         expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({expandedRows:e.data})}
                         rowExpansionTemplate={this.rowExpansionTemplate} 
                        paginator rows={10} emptyMessage="No customers found"   rowsPerPageOptions={[10,25,50]}>
                    <Column selectionMode="single" style={{width:'3em'}} expander={true}/>
                    <Column field="index" style={{ textAlign: 'center' }} sortable filter header="S/N" /> 
                    <Column field="documentLog.description" style={{ textAlign: 'center' }} sortable filter header="Document" /> 
                    <Column field="dis.disciplineName" style={{ textAlign: 'center' }} sortable filter header="Discipline" />
                    <Column field="itpcode" style={{ textAlign: 'center' }} sortable filter header="Itp Code" />
                    <Column field="itpname" style={{ textAlign: 'center' }} sortable filter header="Itp Name" />
                    <Column field="revno" style={{ textAlign: 'center' }} sortable filter header="Rev. No" />
                    <Column field="approvaldate"  body={this.convertdateR} style={{ textAlign: 'center' }} sortable filter header="Approval Date" />
                    <Column field="iclistno" style={{ textAlign: 'center' }} filter sortable header="I&C List No" />
                    <Column field="iclist" style={{ textAlign: 'center' }} filter sortable header="I&C List Decsription" />
                    <Column header="..." body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                 </DataTable> */}
                 
            </div> 
            </div> 
         </div>
       
      </div>
    )
  }
}

const mapStateToProps = ({ customerLogReducer }) => {
  return {
    customerLogReducer
  }
}

const mapDispatchToProps = {
  fetchCustomerLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistLog)
