/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { GoogleMap, LoadScript, Polyline, Marker, InfoWindow } from '@react-google-maps/api';
import { handleRequest } from '../../redux/config/env';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { Checkbox } from "antd";
import { Button, Modal, Col, Form, Row } from "react-bootstrap";

class TrailerLocation2 extends Component {

    constructor() {
        super();
        this.state = {
            followedDeviceIds: [],
            showAll: true,
            deviceIds: [],
            drivers: [],
            driverIds: [],
            driverDeviceIds: [],
            driverName: "",
            selectedTruck: false,
            centerLat: 26.515690046332118,
            centerLong: 49.17807951249998,
            centerPosition: { lat: 26.515690046332118, lng: 49.17807951249998 },
            startDate: null,
            endDate: null,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalMessage: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        }

        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "TrailerLocation2" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "TrailerLocation2",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "TrailerLocation2");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        let flagStartDate = null;
        let flagEndDate = null;

        if (this.props.location.pathname.split('/')[2] !== undefined && this.props.location.pathname.split('/')[2] !== null) {
            let urlParser = this.props.location.pathname.split('/')[2];
            let res = urlParser.split("&");

            for (let index = 0; index < res.length; index++) {
                if (res[index].length == 13) {
                    flagStartDate = new Date(parseInt(res[index]));
                    flagEndDate = new Date(parseInt(res[index + 1]));
                    break;
                }
            }

            let response2 = await handleRequest("GET", "/StaffDevice/getAll");
            let data2 = response2.data;

            let followedDeviceIds = [];

            for (let index = 0; index < data2.length; index++) {
                for (let index1 = 0; index1 < res.length; index1++) {
                    if ((data2[index].staffId.toString()) === res[index1] && !(followedDeviceIds.includes(data2[index].deviceId))) {
                        followedDeviceIds.push(data2[index].deviceId);
                    }
                }
            }

            this.setState({
                followedDeviceIds: followedDeviceIds,
                showAll: false,
                startDate: flagStartDate,
                endDate: flagEndDate
            });
        }

        if (flagStartDate === null || flagEndDate === null) {
            this.interval = window.setInterval(this.testTimer, 5000);
        } else {
            const response = await handleRequest("GET", "/TrailerLocation/getAll");
            const response1 = await handleRequest("GET", "/StaffDevice/getAll");

            const uniqueIds = [];
            const driverIds = [];
            const drivers = [];
            const driverDeviceIds = [];

            const columns1 = response1.data;

            if (response.data.length !== 0) {
                const columns = response.data;

                columns.map(row => {
                    if (uniqueIds.indexOf(row.deviceId) === -1) {
                        for (let index = 0; index < columns1.length; index++) {
                            if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                                driverIds.push(columns1[index].staffId);
                                driverDeviceIds.push(row.deviceId);
                                drivers.push(columns1[index].staff);
                            }
                        }
                        uniqueIds.push(row.deviceId)
                    }
                });

                let projectResponse = await handleRequest("GET", "/CompanyProjects/" + columns1[0].staff.projectId.toString());

                console.log(projectResponse);

                if (projectResponse.data.length !== 0) {
                    if (projectResponse.data.locationCenterLat !== null && projectResponse.data.locationCenterLat !== undefined
                        && projectResponse.data.locationCenterLng !== null && projectResponse.data.locationCenterLng !== undefined) {

                        let flagLocationCenterLat = parseFloat(projectResponse.data.locationCenterLat);
                        let flagLocationCenterLng = parseFloat(projectResponse.data.locationCenterLng);

                        this.setState({
                            centerPosition: { lat: flagLocationCenterLat, lng: flagLocationCenterLng }
                        });
                    }
                }

                this.setState({
                    deviceIds: uniqueIds,
                    driverIds: driverIds,
                    driverDeviceIds: driverDeviceIds,
                    drivers: drivers
                });

                for (let index = 0; index < uniqueIds.length; index++) {
                    this.setState({
                        [`${uniqueIds}`]: []
                    });
                }

                for (let index = 0; index < uniqueIds.length; index++) {

                    var flagLangLat = [];

                    for (let index1 = 0; index1 < response.data.length; index1++) {

                        let flagCreateDate = new Date(Date.parse(response.data[index1].createDate));
                        flagCreateDate.setTime(flagCreateDate.getTime() - (3 * 60 * 60 * 1000));  //Bu 3 saat sunucudan kaynaklı sanırım, java'dan 3 saat fazla çekiyor.

                        if ((uniqueIds[index] === response.data[index1].deviceId) && (flagCreateDate >= flagStartDate) && (flagCreateDate <= flagEndDate)) {
                            var val = { lat: parseFloat(response.data[index1].latitude), lng: parseFloat(response.data[index1].longitude) };

                            flagLangLat.push(val);
                        }
                    }

                    //var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

                    var randomColor = this.randDarkColor();

                    const arrow = {
                        path: 'M 0,0 3,9 -3,9 0,0 z', // 0,0 is the tip of the arrow
                        fillColor: 'red',
                        fillOpacity: 1.0,
                        strokeColor: 'red',
                        strokeWeight: 1,
                    };

                    const symbolOne = {
                        path: 'M -2,-2 2,2 M 2,-2 -2,2',
                        strokeColor: '#292',
                        strokeWeight: 4
                    };

                    var symbolTwo = {
                        path: 'M -2,0 0,-2 2,0 0,2 z',
                        strokeColor: '#F00',
                        fillColor: '#F00',
                        fillOpacity: 1
                    };

                    this.setState({
                        [`${uniqueIds[index]}`]: flagLangLat,
                        [`${uniqueIds[index]}_option`]: {
                            strokeColor: randomColor,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: randomColor,
                            fillOpacity: 0.35,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            visible: true,
                            radius: 30000,
                            paths: flagLangLat,
                            zIndex: 1,
                            icons: [{
                                icon: symbolOne,
                                offset: '0%'
                            }, {
                                icon: arrow,
                                offset: '50%',
                                repeat: '200px'
                            },
                            {
                                icon: symbolTwo,
                                offset: '100%'
                            }]
                        }
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    randDarkColor() {
        var lum = -0.25;
        var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        var rgb = "#",
            c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }
        return rgb;
    }

    testTimer = async () => {
        //var date = new Date();
        //console.log(date.toISOString());

        const response = await handleRequest("GET", "/TrailerLocation/getAll");
        const response1 = await handleRequest("GET", "/StaffDevice/getAll");

        const uniqueIds = [];
        const driverIds = [];
        const drivers = [];
        const driverDeviceIds = [];

        const columns1 = response1.data;

        if (response.data.length !== 0) {
            const columns = response.data;

            columns.map(row => {
                if (uniqueIds.indexOf(row.deviceId) === -1) {
                    for (let index = 0; index < columns1.length; index++) {
                        if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                            driverIds.push(columns1[index].staffId);
                            driverDeviceIds.push(row.deviceId);
                            drivers.push(columns1[index].staff);
                        }
                    }
                    uniqueIds.push(row.deviceId)
                }
            });

            this.setState({
                deviceIds: uniqueIds,
                driverIds: driverIds,
                driverDeviceIds: driverDeviceIds,
                drivers: drivers
            });

            for (let index = 0; index < uniqueIds.length; index++) {
                this.setState({
                    [`${uniqueIds}`]: []
                });
            }

            for (let index = 0; index < uniqueIds.length; index++) {

                var flagLangLat = [];

                for (let index1 = 0; index1 < response.data.length; index1++) {
                    if (uniqueIds[index] === response.data[index1].deviceId) {
                        var val = { lat: parseFloat(response.data[index1].latitude), lng: parseFloat(response.data[index1].longitude) };
                        flagLangLat[0] = val;       //sadece son lokasyonunu getirir.
                        //flagLangLat.push(val);   //bütün ilgili dataları bağlar ve bir path oluşturur.
                    }
                }

                //var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

                var randomColor = this.randDarkColor();

                const arrow = {
                    path: 'M 0,0 3,9 -3,9 0,0 z', // 0,0 is the tip of the arrow
                    fillColor: 'red',
                    fillOpacity: 1.0,
                    strokeColor: 'red',
                    strokeWeight: 1,
                };

                const symbolOne = {
                    path: 'M -2,-2 2,2 M 2,-2 -2,2',
                    strokeColor: '#292',
                    strokeWeight: 4
                };

                var symbolTwo = {
                    path: 'M -2,0 0,-2 2,0 0,2 z',
                    strokeColor: '#F00',
                    fillColor: '#F00',
                    fillOpacity: 1
                };

                this.setState({
                    [`${uniqueIds[index]}`]: flagLangLat,
                    [`${uniqueIds[index]}_option`]: {
                        strokeColor: randomColor,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: randomColor,
                        fillOpacity: 0.35,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                        radius: 30000,
                        paths: flagLangLat,
                        zIndex: 1,
                        icons: [{
                            icon: symbolOne,
                            offset: '0%'
                        }, {
                            icon: arrow,
                            offset: '50%',
                            repeat: '200px'
                        },
                        {
                            icon: symbolTwo,
                            offset: '100%'
                        }]
                    }
                });
            }
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess();
    }

    render() {
        const howToUseThis = this.state.howToUseThis;
        const { selectID } = this.state;

        const mapContainerStyle = {
            height: "700px",
            width: "100%"
        };

        // const center = {
        //     lat: 0,
        //     lng: -180
        // };

        // const center = {
        //     lat: this.state.centerLat,
        //     lng: this.state.centerLong
        // };

        const center = this.state.centerPosition;

        const onLoad = polyline => {
            //console.log('polyline: ', polyline)
        };

        var devices = [];

        if (this.state.showAll) {
            for (let index = 0; index < this.state.deviceIds.length; index++) {
                if (this.state.deviceIds.length !== 0) {
                    var lengthFlag;
                    if (this.state[`${this.state.deviceIds[index]}`] !== undefined) {
                        lengthFlag = this.state[`${this.state.deviceIds[index]}`].length;
                        devices.push(<div key={`${this.state.deviceIds[index]}`}>
                            <Polyline
                                onLoad={onLoad}
                                path={this.state[`${this.state.deviceIds[index]}`]}
                                options={this.state[`${this.state.deviceIds[index]}_option`]}
                            />

                            <Marker
                                icon="https://i.ibb.co/NyQ91RQ/truck1.png"
                                position={this.state[`${this.state.deviceIds[index]}`][lengthFlag - 1]}
                                onClick={() => {
                                    let flagLength = this.state[`${this.state.deviceIds[index]}`].length;
                                    let driverFound = false;
                                    for (let index1 = 0; index1 < this.state.driverDeviceIds.length; index1++) {
                                        if (this.state.deviceIds[index] === this.state.driverDeviceIds[index1]) {
                                            driverFound = true;
                                            this.setState({
                                                driverName: this.state.drivers[index1].name + " " + this.state.drivers[index1].lastName
                                            })
                                        }
                                    }

                                    if (!driverFound) {
                                        this.setState({
                                            driverName: "No Driver Data"
                                        })
                                    }

                                    this.setState({
                                        selectedTruck: true,
                                        selectedTruckPosition: this.state[`${this.state.deviceIds[index]}`][flagLength - 1],
                                        centerPosition: this.state[`${this.state.deviceIds[index]}`][flagLength - 1]
                                    })
                                }}
                            />

                            {this.state.selectedTruck &&
                                <InfoWindow
                                    position={this.state.selectedTruckPosition}
                                    onCloseClick={() => {
                                        this.setState({
                                            selectedTruck: false,
                                            selectedTruckPosition: null,
                                            driverName: ""
                                        })
                                    }}
                                >
                                    <div style={{ background: "white" }}>
                                        {this.state.driverName}
                                    </div>
                                </InfoWindow>
                            }
                        </div>);
                    }
                }
            }
        } else {
            for (let index = 0; index < this.state.deviceIds.length; index++) {
                if (this.state.deviceIds.length !== 0) {
                    var lengthFlag;
                    if (this.state[`${this.state.deviceIds[index]}`] !== undefined && this.state.followedDeviceIds.includes(this.state.deviceIds[index])) {
                        lengthFlag = this.state[`${this.state.deviceIds[index]}`].length;
                        devices.push(<div key={`${this.state.deviceIds[index]}`}>
                            <Polyline
                                onLoad={onLoad}
                                path={this.state[`${this.state.deviceIds[index]}`]}
                                options={this.state[`${this.state.deviceIds[index]}_option`]}
                            />

                            <Marker
                                icon="https://i.ibb.co/NyQ91RQ/truck1.png"
                                position={this.state[`${this.state.deviceIds[index]}`][lengthFlag - 1]}
                                onClick={() => {

                                    let flagLength = this.state[`${this.state.deviceIds[index]}`].length;
                                    let driverFound = false;

                                    for (let index1 = 0; index1 < this.state.driverDeviceIds.length; index1++) {
                                        if (this.state.deviceIds[index] === this.state.driverDeviceIds[index1]) {
                                            driverFound = true;
                                            this.setState({
                                                driverName: this.state.drivers[index1].name + " " + this.state.drivers[index1].lastName
                                            })
                                        }
                                    }

                                    if (!driverFound) {
                                        this.setState({
                                            driverName: "No Driver Data"
                                        })
                                    }

                                    this.setState({
                                        selectedTruck: true,
                                        selectedTruckPosition: this.state[`${this.state.deviceIds[index]}`][flagLength - 1],
                                        centerPosition: this.state[`${this.state.deviceIds[index]}`][flagLength - 1]
                                    })
                                }}
                            />

                            {this.state.selectedTruck &&
                                <InfoWindow
                                    position={this.state.selectedTruckPosition}
                                    onCloseClick={() => {
                                        this.setState({
                                            selectedTruck: false,
                                            selectedTruckPosition: null,
                                            driverName: ""
                                        })
                                    }}
                                >
                                    <div style={{ background: "white" }}>
                                        {this.state.driverName}
                                    </div>
                                </InfoWindow>
                            }
                        </div>);
                    }
                }
            }

            if (this.state.followedDeviceIds.length !== 0 && this.state.startDate === null && this.state.endDate === null) {
                let lengthFlag0 = this.state[`${this.state.followedDeviceIds[0]}`];
                if (lengthFlag0 !== 0 && lengthFlag0 !== undefined) {
                    let lastIndex = lengthFlag0.length - 1;
                    let flagPosition = lengthFlag0[lastIndex];
                    if (this.state.centerPosition !== flagPosition) {
                        this.setState({
                            centerPosition: flagPosition
                        });
                    }
                }
            }
        }

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ?
            (<div><Redirect to="/error/error-v6" /></div>)
            : (<div>

                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <div>
                        <Row>
                            <Col sm="1">
                                {this.state.isAdmin ?
                                    <IconButton color="primary" onClick={this.showMNDSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                    :
                                    <IconButton color="primary" onClick={this.showSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                }
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                    <HelpIcon />
                                </IconButton>
                                }
                            </Col>
                        </Row>
                    </div>

                    <LoadScript googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={8}
                            center={center}
                        >
                            {devices}
                        </GoogleMap>
                    </LoadScript>

               </MenuProvider>
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TrailerLocation2)