/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Form, DatePicker } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import { ExcelReader } from '../excel/ExcelReader';

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class RegulatedVendor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenUpdate: true,
      pdfFiles: [],
      regulatedVendorTable: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: "",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    var today = new Date();
    var todayDate;
    var HH = String(today.getHours()).padStart(2, '0');
    var minute = String(today.getMinutes()).padStart(2, '0');
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
    todayDate = dd + '/' + mm + '/' + yyyy;

    await this.setState({
      date: todayDate,
    });

    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "RegulatedVendor" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "RegulatedVendor",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "RegulatedVendor");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }

    this.getTableProperties();
    this.getRegulatedVendor();
  }

  getTableProperties = async () => {
    this.regulatedVendor = JSON.parse(localStorage.getItem('regulatedVendor'));
    if (this.regulatedVendor != null && this.regulatedVendor != undefined && this.regulatedVendor != "") {

      await this.setState({
        bordered: this.regulatedVendor.bordered,
        title: this.regulatedVendor.titleDrm == true ? title : undefined,
        titleDrm: this.regulatedVendor.titleDrm,
        showHeader: this.regulatedVendor.showHeader,
        footer: this.regulatedVendor.footerDrm == true ? footer : undefined,
        footerDrm: this.regulatedVendor.footerDrm,
        expandable: this.regulatedVendor.expandableDrm == true ? expandable : {},
        expandableDrm: this.regulatedVendor.expandableDrm,
        rowSelection: this.regulatedVendor.rowSelection,
        ellipsis: this.regulatedVendor.ellipsis,
        tableLayout: this.regulatedVendor.tableLayout,
        size: this.regulatedVendor.size,
        top: this.regulatedVendor.top,
        bottom: this.regulatedVendor.bottom,
        editBgColor: this.regulatedVendor.editBgColor,
        deleteBgColor: this.regulatedVendor.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.regulatedVendor.yScroll,
        xScroll: this.regulatedVendor.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.regulatedVendorTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.regulatedVendorTable.length, showQuickJumper: true,
          position: [this.regulatedVendor.top, this.regulatedVendor.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  getRegulatedVendor = async () => {
    if (this.state.date != null && this.state.date != undefined && this.state.date != "") {
      const newObj = {
        date: moment(this.state.date),
      };
      const response = await handleRequest("POST", "/RegulatedVendor/getResult", newObj);
      if (response.data.length === 0) {
        this.setState({
          regulatedVendorTable: [],
          modalHistory: false,
          modalImportfromExcel: false,
        });
      }

      if (response.data.length !== 0) {
        this.setState({
          regulatedVendorTable: response.data,
          modalHistory: false,
          modalImportfromExcel: false,
        });
      }
    } else {
      this.showWarning();
    }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalImportfromExcel = () => {
    this.setState({
      modalImportfromExcel: !this.state.modalImportfromExcel,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalExportExcel = () => {
    this.setState({
      modalExportExcel: !this.state.modalExportExcel,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalHistory = () => {
    this.setState({
      modalHistory: !this.state.modalHistory,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeDate = (value, dateString) => {
    this.setState({
      date: dateString,
    });
  }

  handleExcelData = async (excelJson) => {
    if (this.state.date != null && this.state.date != undefined && this.state.date != "") {
      var list = [];
      if (excelJson === null)
        this.showError("Select an excel file");
      else {
        excelJson.forEach(element => {
          const obj = {
            material: element["Material"],
            materialDescription: element["Material Description"],
            ctrlKey: element["Ctrl Key"],
            primaryInspPlan: element["Primary Insp.Plan"],
            insLevel: element["Ins. Level"],
            pim: element["PIM"],
            manufacturer: element["Manufacturer"],
            manufacturerName: element["Manufacturer Name"],
            vendorApprovStat: element["Vendor Approv. Stat"],
            country: element["Country"],
            city: element["City"],
            materialApprovStat: element["Material Approv.Stat"],
            xPlantMaterialSta: element["X-Plant Material Sta"],
            rvlInd: element["RVL Ind."],
            qInfoLimitation: element["Q-Info Limitation"],
            inspectionOffice: element["Inspection Office"],
            purchBlockStatus: element["Purch Block status"],
            materialCode: element["Material Code"],
            matSvcGr: element["Mat/SvcGr"],
            date: moment(this.state.date),
          }
          list.push(obj);
        });
        if (Boolean(list) && list.length > 0) {
          var response = await handleRequest("POST", "/RegulatedVendor/importRegulatedVendor", list);
          if (response.data) {
            switch (response.data) {
              case "SUCCESS":
                this.showSuccess();
                this.getRegulatedVendor();
                break;
              case "ERROR1":
                this.showError("There is some material records that starts with different character from 6.");
                break;
              case "ERROR2":
                this.showError("There is some material records that length is not 10.");
                break;

              case "ERROR3":
                this.showError("There is some manufacturer records that starts with different character from 3.");
                break;

              case "ERROR4":
                this.showError("There is some manufacturer records that length is not 8.");
                break;

              case "ERROR5":
                this.showError("No data detected for import");
                break;
              case "ERROR":
                this.showError("Unknown error has occurred !");
                break;


              default:
                break;
            }
           
          }
        } else {
          this.showError();
          this.showOrHideModalImportfromExcel();
        }
      }
    }
    else {
      this.showWarning();
      this.showOrHideModalImportfromExcel();
    }
  }

  exportRegulatedVendor = async () => {
    if (this.state.date != null && this.state.date != undefined && this.state.date != "") {
      var newObj = {
        date: moment(this.state.date),
      }
      var response = await handleRequest("POST", "/RegulatedVendor/exportRegulatedVendor", newObj);
      if (response.data != null && response.data != undefined && response.data.url != null && response.data.url != undefined && response.data.url != "") {
        window.location.href = response.data.url;
      } else {
        this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
      }
    }
    else {
      this.showWarning();
    }
    this.showOrHideModalExportExcel();
  }

  render() {
    const columns = [
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("regulatedVendor")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "date",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorDate" defaultMessage="Date" />,
      },
      {
        dataIndex: "material",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorMaterial" defaultMessage="Material" />,
      },
      {
        dataIndex: "materialDescription",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorMaterialDescription" defaultMessage="Material Description" />,
      },
      {
        dataIndex: "ctrlKey",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorCtrlKey" defaultMessage="Ctrl Key" />,
      },
      {
        dataIndex: "primaryInspPlan",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorPrimaryInspPlan" defaultMessage="Primary Insp.Plan" />,
      },
      {
        dataIndex: "insLevel",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorInsLevel" defaultMessage="Ins. Level" />,
      },
      {
        dataIndex: "pim",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorPIM" defaultMessage="PIM" />,
      },
      {
        dataIndex: "manufacturer",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorManufacturer" defaultMessage="Manufacturer" />,
      },
      {
        dataIndex: "manufacturerName",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorManufacturerName" defaultMessage="Manufacturer Name" />,
      },
      {
        dataIndex: "vendorApprovStat",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorVendorApprovStat" defaultMessage="Vendor Approv. Stat" />,
      },
      {
        dataIndex: "country",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorCountry" defaultMessage="Country" />,
      },
      {
        dataIndex: "city",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorCity" defaultMessage="City" />,
      },
      {
        dataIndex: "materialApprovStat",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorMaterialApprovStat" defaultMessage="Material Approv.Stat" />,
      },
      {
        dataIndex: "xPlantMaterialSta",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorXPlantMaterialSta" defaultMessage="X-Plant Material Sta" />,
      },
      {
        dataIndex: "rvlInd",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorRVLInd" defaultMessage="RVL Ind." />,
      },
      {
        dataIndex: "qInfoLimitation",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorQInfoLimitation" defaultMessage="Q-Info Limitation" />,
      },
      {
        dataIndex: "inspectionOffice",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorInspectionOffice" defaultMessage="Inspection Office" />,
      },
      {
        dataIndex: "purchBlockStatus",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorPurchBlockStatus" defaultMessage="Purch Block status" />,
      },
      {
        dataIndex: "materialCode",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorMaterialCode" defaultMessage="Material Code" />,
      }, {
        dataIndex: "matSvcGr",
        key: "key",
        title: <FormattedMessage id="RegulatedVendorMatSvcGr" defaultMessage="Mat/ SvcGr" />,
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        <Form
          {...layout}
          initialValues={{ remember: false }}
          onFinish={this.state.hiddenUpdate == false ? this.updateRegulatedVendor : this.saveRegulatedVendor}
          onFinishFailed={onFinishFailed}
          ref={this.formRef} >

          <Row>
            <Col xs={5}></Col>
            <Col xs={2}>
              <Button variant="success" block onClick={this.showOrHideModalExportExcel}><FormattedMessage id="GeneralTextExportData" defaultMessage="Export Data" /></Button>
            </Col>
            <Col xs={2}>
              <Button onClick={this.showOrHideModalImportfromExcel} style={{ width: '100%' }}
                variant="info" ><FormattedMessage id="RegulatedVendorImportfromExcel" defaultMessage="Import from Excel" />
              </Button>
            </Col>
            <Col xs={2}>
              <Button onClick={this.showOrHideModalHistory} style={{ width: '100%' }}
                variant="danger" ><FormattedMessage id="RegulatedVendorHistory" defaultMessage="History" />
              </Button>
            </Col>
            <Col xs={1}>
              <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("regulatedVendor")} icon="pi pi-cog"></PrimeButton>
            </Col>
          </Row>
          <br />
          <br />

          <div className="datatable-doc-demo">
            <div className="content-section implementation">
              <Mnd.MNDTable
                state={this.state}
                columns={tableColumns} dataSource={this.state.regulatedVendorTable}
                pagination={true}
                expandable={this.state.expandable}
                footer={this.state.footer}
                title={this.state.title}
                scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>

        </Form>

        <Modal
          show={this.state.modalExportExcel} size="md"
          onHide={this.showOrHideModalExportExcel}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="GeneralTextExportData" defaultMessage="Export Data" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <DatePicker value={this.state.date != "" ? moment(this.state.date, 'DD/MM/YYYY') : ""} format="DD/MM/YYYY" onChange={this.onChangeDate} />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalExportExcel}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
            <Button variant="success" onClick={this.exportRegulatedVendor}><FormattedMessage id="GeneralTextExportData" defaultMessage="Export Data" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalImportfromExcel} size="md"
          onHide={this.showOrHideModalImportfromExcel}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="RegulatedVendorImportfromExcel" defaultMessage="Import from Excel" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <DatePicker value={this.state.date != "" ? moment(this.state.date, 'DD/MM/YYYY') : ""} format="DD/MM/YYYY" onChange={this.onChangeDate} />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalImportfromExcel}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
            <ExcelReader onCreateJson={this.handleExcelData.bind(this)}></ExcelReader>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalHistory} size="md"
          onHide={this.showOrHideModalHistory}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="RegulatedVendorHistory" defaultMessage="History" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <DatePicker value={this.state.date != "" ? moment(this.state.date, 'DD/MM/YYYY') : ""} format="DD/MM/YYYY" onChange={this.onChangeDate} />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalHistory}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
            <Button variant="danger" onClick={this.getRegulatedVendor}><FormattedMessage id="RegulatedVendorHistory" defaultMessage="History" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteRegulatedVendorOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RegulatedVendor)