import React, { Component } from 'react';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { Button as AntButton } from 'antd';

export class ExcelReader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            excelData: [],
            cols: []
        }
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    handleChange(e) {
        console.log(e);
        const files = e.target.files;

        if (files && files[0]) this.setState({ file: files[0] });
        console.log(this.state.file);

        setTimeout(
            function () {
                this.inputButton.click();
            }
                .bind(this),
            100
        );

    };

    removeFile(e) {
        console.log("remove file");
        this.setState({ file: {} });
    };

    clickButton = (e) => {
        document.getElementsByClassName('myCustomUploadButton').click();
        // this.inputButton.click(); 
    }

    handleClick = (e) => {
        this.inputElement.click();
    }

    handleFile() {

        /* Boilerplate to set up FileReader */
        console.log("handle file");
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        if (this.state.file === null) {
            this.props.onCreateJson(null);
            return;
        }

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const _data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            this.setState({ excelData: _data, cols: make_cols(ws['!ref']) }, () => {
                console.log(JSON.stringify(this.state.excelData, null, 2));
            });

            //const excelJson = this.state.data;  
            this.props.onCreateJson(this.state.excelData);

        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };
    }

    render() {
        return (
            <div>
                <div className='custom-file' style={{ width: '100% ', display: 'none' }}>
                    <input ref={input => this.inputElement = input} style={{ width: '100% ' }}
                        type='file'
                        placeholder='Upload an Excel '
                        className='custom-file-input'
                        id='customFile'

                        onChange={this.handleChange}
                    />
                    <input type='submit' className='myCustomUploadButton' ref={input => this.inputButton = input}
                        value="Process Triggers"
                        onClick={this.handleFile} />
                    <label className='custom-file-label' htmlFor='customFile'>
                        {this.state.file !== null && this.state.file.name}
                    </label>
                </div>
                <AntButton style={{border:"0px"}} onClick={() => this.handleClick()} icon={<UploadIcon color="primary"/>}></AntButton>
            </div>
        )
    }
}
export default ExcelReader;