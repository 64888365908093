import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, TimePicker, Switch, Button as AntButton } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const placeSelectItems = [{ id: 1, value: "Saudi Arabia" }, { id: 2, value: "Home Country" }, { id: 3, value: "Other" }];

export class Status extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            requestList: [],
            leaveGroupList: [],
            selectedGroup: null,
            groupId: null,
            requestId: 0,
            medicalCenterSelectItems: [],
            relationSelectItems: [],
            codeName: '',

            medicalCenterId: null,
            dateOfSick: null,

            nameOfDeceased: '',
            dateOfDeath: null,
            relationId: null,
            spouseRelative: false,

            userId: null,
            reasonForLeave: '',
            startOfLeave: null,
            endOfLeave: null,
            placeId: null,
            leaveAddress: '',
            leavePhoneNumber: '',
            personalEmail: '',
            emergencyPersonName: '',
            emergencyPersonPhone: '',
            emergencyPersonEmail: '',

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.setComboboxes();
        this.fillCodes();
        this.restartTable();


    }

    save = async () => {

        if (Boolean(this.state.selectedGroup)) {
            var sick = null, death = null;
            if (this.state.selectedGroup.sickLeave)
                sick = {
                    medicalCenterId: this.state.medicalCenterId,
                    dateOfSick: this.state.dateOfSick
                }
            if (this.state.selectedGroup.deathLeave)
                death = {
                    nameOfDeceased: this.state.nameOfDeceased,
                    dateOfDeath: this.state.dateOfDeath,
                    relationId: this.state.relationId,
                    spouseRelative: this.state.spouseRelative,
                }

            const obj = {
                id: this.state.requestId,
                groupId: this.state.groupId,
                sick: sick,
                death: death,
                userId: this.state.userId,
                reasonForLeave: this.state.reasonForLeave,
                startOfLeave: this.state.startOfLeave,
                endOfLeave: this.state.endOfLeave,
                placeId: this.state.placeId,
                leaveAddress: this.state.leaveAddress,
                leavePhoneNumber: this.state.leavePhoneNumber,
                personalEmail: this.state.personalEmail,
                emergencyPersonName: this.state.emergencyPersonName,
                emergencyPersonPhone: this.state.emergencyPersonPhone,
                emergencyPersonEmail: this.state.emergencyPersonEmail
            }

            console.log(obj);
            if (obj.id === 0)
                await handleRequest("POST", "/LeaveRequest/add", obj);
            else
                await handleRequest("POST", "/LeaveRequest/update", obj);

            this.showSuccess();
            this.resetInputs();
            this.restartTable();
        }
        else this.showError("Please Select Leave Request")

    }

    edit = (row) => {

        console.log(row); 

        if (Boolean(row.sick))
            this.setState({
                medicalCenterId: row.sick.medicalCenterId,
                dateOfSick: Boolean(row.sick.dateOfSick)? moment(row.sick.dateOfSick):null
            })
        if (Boolean(row.death))
            this.setState({
                nameOfDeceased: row.death.nameOfDeceased,
                dateOfDeath: Boolean(row.death.dateOfDeath)? moment(row.death.dateOfDeath):null,
                relationId: row.death.relationId,
                spouseRelative: row.death.spouseRelative,
            })

        this.setState({
            requestId: row.id,
            groupId: row.groupId,
            selectedGroup:Boolean(row.groupId)?this.state.leaveGroupList.find(p=>p.id===row.groupId):null,
            userId: row.userId,
            reasonForLeave: row.reasonForLeave,
            startOfLeave: Boolean(row.startOfLeave)? moment(row.startOfLeave):null,
            endOfLeave: Boolean(row.endOfLeave)? moment(row.endOfLeave):null,
            placeId: row.placeId,
            leaveAddress: row.leaveAddress,
            leavePhoneNumber: row.leavePhoneNumber,
            personalEmail: row.personalEmail,
            emergencyPersonName: row.emergencyPersonName,
            emergencyPersonPhone: row.emergencyPersonPhone,
            emergencyPersonEmail: row.emergencyPersonEmail,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.requestId
        }

        await handleRequest("POST", "/LeaveRequest/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            requestId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        const response = await handleRequest("GET", "/LeaveRequest/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                requestList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                requestList: [],
            });
        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Status" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Status"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Status");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            medicalCenterId: null,
            dateOfSick: null,
            groupId:null,
            selectedGroup:null,
            nameOfDeceased: '',
            dateOfDeath: null,
            relationId: null,
            spouseRelative: false,

            userId: null,
            reasonForLeave: '',
            startOfLeave: null,
            endOfLeave: null,
            placeId: null,
            leaveAddress: '',
            leavePhoneNumber: '',
            personalEmail: '',
            emergencyPersonName: '',
            emergencyPersonPhone: '',
            emergencyPersonEmail: '',
            requestId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            medicalCenterId: null,
            dateOfSick: null,
            groupId:null,
            selectedGroup:null,
            nameOfDeceased: '',
            dateOfDeath: null,
            relationId: null,
            spouseRelative: false,

            userId: null,
            reasonForLeave: '',
            startOfLeave: null,
            endOfLeave: null,
            placeId: null,
            leaveAddress: '',
            leavePhoneNumber: '',
            personalEmail: '',
            emergencyPersonName: '',
            emergencyPersonPhone: '',
            emergencyPersonEmail: '',
            requestId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            
            requestId: 0,
            medicalCenterId: null,
            dateOfSick: null,
            groupId:null,
            selectedGroup:null,
            nameOfDeceased: '',
            dateOfDeath: null,
            relationId: null,
            spouseRelative: false,

            userId: null,
            reasonForLeave: '',
            startOfLeave: null,
            endOfLeave: null,
            placeId: null,
            leaveAddress: '',
            leavePhoneNumber: '',
            personalEmail: '',
            emergencyPersonName: '',
            emergencyPersonPhone: '',
            emergencyPersonEmail: '',
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    addCodeMedicalCenter = async () => {
        const { codeName } = this.state;
        let pType = "medicalCenter";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addCodeRelation = async () => {
        const { codeName } = this.state;
        let pType = "relation";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };



    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/medicalCenter");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                medicalCenterSelectItems: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/relation");

        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                relationSelectItems: response2.data
            });
        }
    }

    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/User/getCompanyItems");
        if (resDepList.data.length > 0) {
            var filteredUsers = resDepList.data.filter(p => p.staffId > 0);

            filteredUsers.forEach(element => {
                if (Boolean(element.staff)) {
                    element.fullName = element.staff.fullName;
                }
            });
            this.setState({
                userSelectItems: filteredUsers
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }

        const response2 = await handleRequest("GET", "/LeavePolicyGroup/getAllByCompany");
        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                leaveGroupList: response2.data
            });
        }

    }
    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Leave Request" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Unit"
                                                    value={this.state.groupId} onChange={(value) => {
                                                        let obj = this.state.leaveGroupList.find(p => p.id === value);
                                                        this.setState({
                                                            groupId: value,
                                                            selectedGroup: obj
                                                        })
                                                    }}

                                                >
                                                    <Option key={null} value={null}>Please Select Your Leave Request</Option>
                                                    {this.state.leaveGroupList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.groupName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                <Divider></Divider>
                                {Boolean(this.state.selectedGroup) && <div>
                                    {Boolean(this.state.selectedGroup) &&
                                        <FormAnt.Item
                                            label={""}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="2">

                                                </Col>
                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {this.state.selectedGroup.groupName}
                                                    </Form.Label>
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {Boolean(this.state.selectedGroup) && Boolean(this.state.selectedGroup.leavePolicy) && this.state.selectedGroup.leavePolicy.anotherEmployee &&
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Name of Employee" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select
                                                        style={{ width: '180px' }}
                                                        placeholder="Unit"
                                                        value={this.state.userId} onChange={(value) => {
                                                            this.setState({
                                                                userId: value
                                                            })
                                                        }}

                                                    >
                                                        {this.state.userSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.fullName}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Reason For Leave" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Input id="todo" value={this.state.reasonForLeave} onChange={(e) => this.setState({ reasonForLeave: e.target.value })} />

                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {Boolean(this.state.selectedGroup) && this.state.selectedGroup.deathLeave &&
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Name of Deceased" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Input id="todo" value={this.state.nameOfDeceased} onChange={(e) => this.setState({ nameOfDeceased: e.target.value })} />

                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {Boolean(this.state.selectedGroup) && this.state.selectedGroup.deathLeave &&
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Date of death" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <DatePicker showTime onOk={(value) => {
                                                        this.setState({
                                                            dateOfDeath: value
                                                        })

                                                    }} value={this.state.dateOfDeath}

                                                        format="DD-MM-YYYY"
                                                    />
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {Boolean(this.state.selectedGroup) && this.state.selectedGroup.deathLeave &&
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Relation" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="3">
                                                    <Select
                                                        style={{ width: '180px' }}
                                                        placeholder="Unit"
                                                        value={this.state.relationId} onChange={(value) => {
                                                            this.setState({
                                                                relationId: value
                                                            })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCodeRelation}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.relationSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="3">
                                                    <div>
                                                        <Checkbox onChange={(e) => {
                                                            this.setState({
                                                                spouseRelative: e.target.checked
                                                            })
                                                        }} checked={this.state.spouseRelative}> Spouse's Relative </Checkbox>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }


                                    {Boolean(this.state.selectedGroup) && this.state.selectedGroup.sickLeave &&
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Hospitalization day" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <DatePicker showTime onOk={(value) => {
                                                        this.setState({
                                                            dateOfSick: value
                                                        })

                                                    }} value={this.state.dateOfSick}

                                                        format="DD-MM-YYYY"
                                                    />

                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {Boolean(this.state.selectedGroup) && this.state.selectedGroup.sickLeave &&
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Medical Center" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select
                                                        style={{ width: '180px' }}
                                                        placeholder="Unit"
                                                        value={this.state.medicalCenterId} onChange={(value) => {
                                                            this.setState({
                                                                medicalCenterId: value
                                                            })
                                                        }}


                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                        this.setState({
                                                                            codeName: event.target.value,
                                                                        });
                                                                    }} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addCodeMedicalCenter}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                               </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.medicalCenterSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }


                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Date of Leave" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <DatePicker showTime onOk={(value) => {
                                                        console.log(value); 
                                                        this.setState({
                                                            startOfLeave: value
                                                        })

                                                    }} value={this.state.startOfLeave}

                                                        format="DD-MM-YYYY HH:mm"
                                                    />

                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="End Date of Leave" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <DatePicker showTime onOk={(value) => {
                                                        this.setState({
                                                            endOfLeave: value
                                                        })

                                                    }} value={this.state.endOfLeave}

                                                        format="DD-MM-YYYY HH:mm"
                                                    />

                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Place to be in during leave days" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select
                                                        style={{ width: '180px' }}
                                                        placeholder="Unit"
                                                        value={this.state.placeId} onChange={(value) => {
                                                            this.setState({
                                                                placeId: value
                                                            })
                                                        }}

                                                    >
                                                        {placeSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.value}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Address" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.leaveAddress} onChange={(e) => this.setState({ leaveAddress: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Phone" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.leavePhoneNumber} onChange={(e) => this.setState({ leavePhoneNumber: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Personal Email" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.personalEmail} onChange={(e) => this.setState({ personalEmail: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Emergency Person Name" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.emergencyPersonName} onChange={(e) => this.setState({ emergencyPersonName: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Emergency Person Phone" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.emergencyPersonPhone} onChange={(e) => this.setState({ emergencyPersonPhone: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Emergency Person Email" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.emergencyPersonEmail} onChange={(e) => this.setState({ emergencyPersonEmail: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                </div>}
                                {Boolean(this.state.selectedGroup) && this.state.selectedGroup.businessTripLeave &&
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="2">

                                            </Col>
                                            <Col sm="6">
                                                <Link
                                                    to={{
                                                        pathname: "/BusinessTripRequest"
                                                    }}
                                                >     <ReactButton variant="info">Go to business trip request</ReactButton>
                                                </Link>
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }



                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Post" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.requestList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.requestList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Leave Request" render={(value, row, index) => {

                                if (Boolean(row.leavePolicyGroup))
                                    return row.leavePolicyGroup.groupName

                            }} />
                            <Column title="Leave Start Date" render={(value, row, index) => {

                                if (Boolean(row.startOfLeave))
                                    return moment(row.startOfLeave).format("DD/MM/YYYY HH:mm")

                            }} />
                            <Column title="Leave End Date" render={(value, row, index) => {

                                if (Boolean(row.endOfLeave))
                                    return moment(row.endOfLeave).format("DD/MM/YYYY HH:mm")

                            }} />
                            <Column title="Name Surname" render={(value, row, index) => {

                                if (Boolean(row.user) && Boolean(row.user.staff))
                                    return row.user.staff.fullName;

                            }} />
                            <Column title="Reason For Leave" dataIndex="reasonForLeave" />
                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
      
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Status)