
import React, { useContext, useState, useEffect, useRef, Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import { PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';

const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;

const { Option } = Select;
const pageName = "ViolationSummary";


export class ViolationSummary1 extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            weeklySummary: [],
            filePath: '',
            fileList: [],
            //[STATE_FIELDS],
            userSelectItems: [],

            department: null,
            designation: null,
            phone: null,
            email: null,
            columns: [],
            date: moment(),


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();

    }


    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/Staff/getallByCompany");
        if (resDepList.data.length > 0) {

            this.setState({
                userSelectItems: resDepList.data
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }



    }
    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            staffId: this.state.staffId,
            plannedAssignmentDate: this.state.plannedAssignmentDate,
            transmittalNo: this.state.transmittalNo,
            assignmentDate: this.state.assignmentDate,
            plannedReleaseDate: this.state.plannedReleaseDate,
            releaseDate: this.state.releaseDate,
            plannedVocationDate: this.state.plannedVocationDate,
            vacationDate: this.state.vacationDate,
            vacationDuration: this.state.vacationDuration,
            vacationReturnDate: this.state.vacationReturnDate,
            approvalStatus: this.state.approvalStatus,
            approvalDuration: this.state.approvalDuration,
            approvalType: this.state.approvalType,
        };//SAVE_OBJECT kodlandığında kaldırılacak
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ManpowerLog/add", obj);
        else
            await handleRequest("POST", "/ManpowerLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {
        let item = this.state.userSelectItems.find(p => p.id === row.staffId);
        if (Boolean(item)) {
            this.formRef.current.setFieldsValue({

                department: item.department.departmentName,
                designation: item.discipline.designation,
                phone: item.residencePersonelPhone,
                email: item.residenceCompanyEmail,
            });
            this.setState({
                department: item.department.departmentName,
                designation: item.discipline.designation,
                phone: item.residencePersonelPhone,
                email: item.residenceCompanyEmail,
            })
        }


        this.formRef.current.setFieldsValue({
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: row.staffId,
            plannedAssignmentDate: Boolean(row.plannedAssignmentDate) ? moment(row.plannedAssignmentDate) : null,
            transmittalNo: row.transmittalNo,
            assignmentDate: Boolean(row.assignmentDate) ? moment(row.assignmentDate) : null,
            plannedReleaseDate: Boolean(row.plannedReleaseDate) ? moment(row.plannedReleaseDate) : null,
            releaseDate: Boolean(row.releaseDate) ? moment(row.releaseDate) : null,
            plannedVocationDate: Boolean(row.plannedVocationDate) ? moment(row.plannedVocationDate) : null,
            vacationDate: Boolean(row.vacationDate) ? moment(row.vacationDate) : null,
            vacationDuration: row.vacationDuration,
            vacationReturnDate: Boolean(row.vacationReturnDate) ? moment(row.vacationReturnDate) : null,
            approvalStatus: row.approvalStatus,
            approvalDuration: row.approvalDuration,
            approvalType: row.approvalType,
        });

        this.setState({
            //  [EDIT_STATE_FIELDS], 
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            department: null,
            designation: null,
            phone: null,
            email: null,

            id: row.id,
            staffId: row.staffId,
            plannedAssignmentDate: Boolean(row.plannedAssignmentDate) ? moment(row.plannedAssignmentDate) : null,
            transmittalNo: row.transmittalNo,
            assignmentDate: Boolean(row.assignmentDate) ? moment(row.assignmentDate) : null,
            plannedReleaseDate: Boolean(row.plannedReleaseDate) ? moment(row.plannedReleaseDate) : null,
            releaseDate: Boolean(row.releaseDate) ? moment(row.releaseDate) : null,
            plannedVocationDate: Boolean(row.plannedVocationDate) ? moment(row.plannedVocationDate) : null,
            vacationDate: Boolean(row.vacationDate) ? moment(row.vacationDate) : null,
            vacationDuration: row.vacationDuration,
            vacationReturnDate: Boolean(row.vacationReturnDate) ? moment(row.vacationReturnDate) : null,
            approvalStatus: row.approvalStatus,
            approvalDuration: row.approvalDuration,
            approvalType: row.approvalType,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ManpowerLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {


        let violationTypes = [];
        let obj = {
            date: this.state.date
        }

        let columns = [

            {
                title: '', key: 'title', align: "center", render: (record) => {
                    if (Boolean(record.tag))
                        return (
                            <Tag color="volcano" style={{ fontSize: '16pt' }} key={record.tag}>
                                {record.tag.toUpperCase()}
                            </Tag>
                        );
                    else
                        return <div style={{ fontWeight: 800 }}>{record.title}</div>;
                }
            }

        ];

        let weeklySummary = [
            {
                title: "Issued",
                key: "Issued"
            },
            {
                title: "Closed",
                key: "Closed"

            },
            { title: 'Total Open', tag: "Total Open", required: 0, mobilized: 0, onVacation: 0, deviation: 0 }

        ];

        const response = await handleRequest("POST", "/ViolationLog/summary", obj);

        if (Boolean(response.data) && response.data.length > 0) {
            const list = response.data;
            console.log(list);



            list.forEach(element => {
                element.key = element.violationType;

                let attribute = "Wk#" + element.weekNumber;
                weeklySummary[0][attribute] = element.issued;
                weeklySummary[1][attribute] = element.closed;
                weeklySummary[2][attribute] = element.issued - element.closed;
                let col = {
                    title: attribute, key: element.key, align: "center", dataIndex: attribute, render: (value, row, index) => {
                        return value;
                    }
                }

                columns.push(col);

                if (violationTypes.length > 0) {
                    let index = violationTypes.findIndex(p => p.violationType === element.violationType);
                    if (index === -1) {
                        violationTypes.push({
                            key: element.violationType,
                            violationType: element.violationType,
                            issued: 0,
                            closed: 0,
                            open: 0,
                            lastIssued: 0,
                            lastClosed: 0,
                            lastOpen: 0
                        })
                    }
                }
                else violationTypes.push({
                    key: element.violationType,
                    violationType: element.violationType,
                    issued: 0,
                    closed: 0,
                    open: 0,
                    lastIssued: 0,
                    lastClosed: 0,
                    lastOpen: 0,
                });
            });

            let thisWeekNumber = moment(obj.date).week();
            console.log("thisWeekNumber", thisWeekNumber);

            let thisWeek = list.filter(p => p.weekNumber === thisWeekNumber - 1);

            let lastWeek = list.filter(p => p.weekNumber !== thisWeekNumber - 1);

            violationTypes.forEach(vt => {

                if (Boolean(thisWeek)) {
                    let thisWeekItem = thisWeek.find(p => p.violationType === vt.violationType);
                    if (Boolean(thisWeekItem)) {
                        vt.issued = thisWeekItem.issued;
                        vt.closed = thisWeekItem.closed;
                        vt.open = thisWeekItem.issued - thisWeekItem.closed;
                    }
                }

                if (Boolean(lastWeek)) {
                    let lastviolationType = lastWeek.filter(p => p.violationType === vt.violationType);

                    if (Boolean(lastviolationType)) {
                        lastviolationType.forEach(item => {
                            vt.lastIssued += item.issued
                            vt.lastClosed += item.closed

                        });
                        vt.lastOpen = vt.lastIssued - vt.lastClosed;
                    }
                }
                vt.overallIssued = vt.lastIssued + vt.issued;
                vt.overallClosed = vt.lastClosed + vt.closed;
                vt.overallOpen = vt.lastOpen + vt.open;
            });


            this.setState({
                tableList: violationTypes,
                weeklySummary: weeklySummary,
                columns: columns
            });
        }

        else {
            this.setState({
                tableList: [],
                weeklySummary: [],

            });
        }
    }

    calc = (list) => {

        let total = { designationName: 'Total', tag: "Total", required: 0, mobilized: 0, onVacation: 0, deviation: 0 };
        list.forEach(element => {
            if (element.tag) {
                element.required = total.required;
                element.mobilized = total.mobilized;
                element.onVacation = total.onVacation;
                element.deviation = total.deviation;
            }
            else {
                element.deviation = element.required - element.mobilized + element.onVacation;
                total.required += element.required;
                total.mobilized += element.mobilized;
                total.onVacation += element.onVacation;
                total.deviation += element.deviation;
            }

        });

        console.log(total);

    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,
        });

        this.setState({
            // [RESETINPUTS_STATES],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,

        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleSave = (row) => {
        const newData = [...this.state.tableList];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.calc(newData);
        this.setState({
            tableList: newData,
        });
    };


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };



        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>



                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {<FormAnt.Item
                                            {...layout}

                                            label={<FormattedMessage id="Manpower.date" defaultMessage="Date" />}

                                            style={{
                                                marginBottom: 0,
                                            }}
                                            name="date"
                                        >
                                            <RowAnt gutter={[8, 8]}>
                                                <ColAnt span={12}>
                                                    <DatePicker id="ManpowerLog.pad" format="DD-MM-YYYY" value={this.state.date} onChange={(date, dateString) => {
                                                        this.formRef.current.setFieldsValue({
                                                            date: date
                                                        });
                                                        this.setState({
                                                            date: date
                                                        }, this.restartTable)
                                                    }} />
                                                </ColAnt>
                                            </RowAnt>

                                        </FormAnt.Item>}

                                    </FormAnt>
                                </div>

                                
                                   
                                        {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }}  id={"Table" + this.props.id} 
                                        size={`small`} dataSource={this.state.tableList} bordered >

                                            <Column title="" align="center" dataIndex="violationType" render={(value, row, index) => {

                                                return <div style={{ fontWeight: 800 }}>{value}</div>;
                                            }} />
                                            <ColumnGroup title="Last Week" key="lw" >
                                                <Column title='Issued' dataIndex='lastIssued' render={(value, row, index) => { return value; }} />
                                                <Column title='closed' dataIndex='lastClosed' render={(value, row, index) => { return value; }} />
                                                <Column title='Open' dataIndex='lastOpen' render={(value, row, index) => { return value; }} />
                                            </ColumnGroup>
                                            <ColumnGroup title="This Week" key="tw" >
                                                <Column title='Issued' dataIndex='issued' render={(value, row, index) => { return value; }} />
                                                <Column title='closed' dataIndex='closed' render={(value, row, index) => { return value; }} />
                                                <Column title='Open' dataIndex='open' render={(value, row, index) => { return value; }} />
                                            </ColumnGroup>
                                            <ColumnGroup title="Overall" key="overAll" >
                                                <Column title='Issued' dataIndex='overallIssued' render={(value, row, index) => { return value; }} />
                                                <Column title='closed' dataIndex='overallClosed' render={(value, row, index) => { return value; }} />
                                                <Column title='Open' dataIndex='overallOpen' render={(value, row, index) => { return value; }} />
                                            </ColumnGroup>


                                            {/* <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} /> */}

                                        </Table>}
                                       

                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ViolationSummary1)
