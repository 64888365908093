/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl"; 
import MaintenanceChecklistSubLog from './MaintenanceChecklistSubLog';
import { Button as PrimeButton } from "primereact/button";
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import { Col, Row, Modal } from 'react-bootstrap';

const expandable = { expandedRowRender: record => <MaintenanceChecklistSubLog row={record} /> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class MaintenanceChecklistLog extends Component {

    constructor(props) {
        super(props)
       
        this.state = {
            pagination: {
                current: 1,
                pageSize: 5,
            },
            loading: false,
            MaintenanceChecklistLogs:[],
            kontrol:false,
        }
    }

    componentDidMount  = async () => {
        // const { pagination } = this.state;
        // this.fetch({ pagination }); 

        const newObj = {
          id: 0,
        }
        const response = await handleRequest("POST", "/MaintenanceChecklist/getMaintenanceChecklistPaging", newObj);
        if (response.length === 0 || response.data.length === 0) {
            this.setState({
              MaintenanceChecklistLogs: []
            });
        } else if (response.data.length !== 0) {
            this.setState({
              MaintenanceChecklistLogs: response.data.data
            });
        } 
        await this.getTableProperties();  
    }

    handleTableChange = (pagination, filters, sorter) => {
      this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters, });
    };
  
    fetch = async (params = {}) => {
      this.setState({ loading: true });
      const newObj = {
        id: 0,
        firstResult: params.pagination.current,
        maxResults: params.pagination.pageSize,
      }
      const response = await handleRequest("POST", "/MaintenanceChecklist/getMaintenanceChecklistPaging", newObj);
      if (response.data.length !== 0) {
        this.setState({
          loading: false,
          MaintenanceChecklistLogs: response.data.data,
          pagination: {
            ...params.pagination,
            total: response.data.totalRecords,
          },
        });
      } else {
        this.setState({
          loading: false,
          MaintenanceChecklistLogs: [],
          pagination: {
            ...params.pagination,
            total: 0,
          },
        });
      }
    };
   
    showOrHideModalTable  = async () => {
      this.setState({
          modalTable: !this.state.modalTable,
      }); 
      setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
     await this.getTableProperties(); 
    }

    getTableProperties = async () => {
      //MNDTableSettings.getTableProperties("maintenanceChecklistLog");

      this.maintenanceChecklistLog = JSON.parse(localStorage.getItem('maintenanceChecklistLog'));
      console.log("GETTABLE", this.maintenanceChecklistLog);
      if(this.maintenanceChecklistLog!=null && this.maintenanceChecklistLog!=undefined && this.maintenanceChecklistLog!=""){
          
        this.setState({
              bordered: this.maintenanceChecklistLog.bordered,
              title: this.maintenanceChecklistLog.titleDrm ==true ? title : undefined,
              titleDrm: this.maintenanceChecklistLog.titleDrm, 
              showHeader: this.maintenanceChecklistLog.showHeader, 
              footer: this.maintenanceChecklistLog.footerDrm ==true ? footer : undefined, 
              footerDrm: this.maintenanceChecklistLog.footerDrm, 
              expandable: this.maintenanceChecklistLog.expandableDrm ==true ? expandable : {}, 
              expandableDrm: this.maintenanceChecklistLog.expandableDrm, 
              rowSelection: this.maintenanceChecklistLog.rowSelection, 
              ellipsis: this.maintenanceChecklistLog.ellipsis, 
              tableLayout: this.maintenanceChecklistLog.tableLayout, 
              size: this.maintenanceChecklistLog.size, 
              top: this.maintenanceChecklistLog.top, 
              bottom: this.maintenanceChecklistLog.bottom, 
              editBgColor: this.maintenanceChecklistLog.editBgColor, 
              deleteBgColor: this.maintenanceChecklistLog.deleteBgColor,   
              selectedNodeKey: null,
              yScroll:this.maintenanceChecklistLog.yScroll,
              xScroll:this.maintenanceChecklistLog.xScroll,
              paginationDisabled: {  defaultPageSize: 5, total: this.state.MaintenanceChecklistLogs.length, 
              showQuickJumper: true, showSizeChanger: true, 
              pageSizeOptions: ['5', '10', '25', '50'], total: this.state.MaintenanceChecklistLogs.length, showQuickJumper: true, 
              position: [this.maintenanceChecklistLog.top, this.maintenanceChecklistLog.bottom] },
              kontrol:true,
          });
      }else{
          this.setState({
              bordered: false,
              title: undefined,
              titleDrm:false,
              showHeader: true,
              footer: undefined,
              footerDrm: false,
              expandable: false,
              expandableDrm: false,
              rowSelection: undefined,
              ellipsis:false,
              tableLayout: undefined,
              size: 'default',
              top: 'none',
              bottom: 'bottomRight',
              editBgColor: '#fff',
              deleteBgColor: '#fff',
              selectedNodeKey: null,
              xScroll:undefined,
              paginationDisabled: false,
              kontrol:true,
          });
      }
    } 

    onChangeStockCode = async (event) => {

  };
        
    render() {
        const columns = [ 
            {
              dataIndex: "index",
              key: "index",
              title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                dataIndex: "equipmentLog",
                key: "key",
                title: <FormattedMessage id="EQLEquipmentName" />,
                render: (data) =>   
                {  
                 if(data!=null && data!=undefined){
                    return data.equipmentDescription;
                 } 
                 return "";
             }
            },
            {  
                dataIndex: "maintenanceType",
                key: "key",
                title: <FormattedMessage id="EQLMaintenanceType" />,
            }, 
          ];

          const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
          if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
          }
    
        return (
            <div>
                 {/* <Table columns={columns} dataSource={this.state.MaintenanceChecklistLogs} 
                // pagination={this.state.pagination}
                    pagination={{
                      pageSize: 5,
                      defaultPageSize: 5,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20", "30"],
                      total: this.state.MaintenanceChecklistLogs.length,
                      showQuickJumper: true,
                      position: "bottomLeft",
                    }}
                    responsive striped hover bordered scroll={{ x: 100, scrollToFirstRowOnChange: true }} 
                    //loading={this.state.loading} onChange={this.handleTableChange} 
                    expandable={{ expandedRowRender }} 
                /> */}

                  <Row>
                    <Col xs={11}></Col>
                    <Col xs={1}>
                      <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                    </Col>
                  </Row>

                   {this.state.kontrol===true && <Mnd.MNDTable
                      state={this.state}
                      columns={tableColumns} dataSource={this.state.MaintenanceChecklistLogs}
                      pagination={this.state.paginationDisabled} 
                      expandable={this.state.expandable}
                      footer={this.state.footer}
                      title={this.state.title}
                      scroll= { this.state.xScroll=="scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    />}
                

                <Modal
                  show={this.state.modalTable}
                  onHide={this.showOrHideModalTable}
                  backdrop="static"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered size="lg">

                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                      <Row > 
                          <Col sm="12">
                            <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                          </Col>
                      </Row>
                      </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                      <Row > 
                      <Col sm="12">
                          <MNDTableSettings id="maintenanceChecklistLog"/>
                      </Col>
                      </Row>
                  </Modal.Body> 
                  </Modal>

            </div>
        )
    }
}
 
const mapStateToProps = () => {
    return {
  
    }
  }
  
  const mapDispatchToProps = {
  
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceChecklistLog)
