/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Card } from "primereact/card";
import { SplitButton } from 'primereact/splitbutton';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Col, Modal, Form } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { ContextMenu } from 'primereact/contextmenu';
import $ from 'jquery';

export class MaintenanceTypes extends Component {

    constructor() {
        super();
        this.state = {
            nodes: [],
            expandedKeys: {},
            addModal: false,
            addModal2: false,
            deleteModal: false,
            editModal: false,
            maintenanceTypeId: null,
            maintenanceTypeParentId: null,
            modalMaintenanceTypeName: "",
            modalMaintenanceCodeName: "",
            modalEditMaintenanceTypeName: "",
            modalEditMaintenanceCodeName: "",
            rootMaintenanceTypeName: "",
            rootMaintenanceCode: "",
            selectedNodeKey: null,
            globalFilter: null,
            splitButtonMenu: [
                {
                    label: 'Add Child',
                    icon: 'pi pi-fw pi-plus',
                    command: () => {
                        this.addChildMaintenanceType();
                    }
                },
                {
                    label: 'Add Parent',
                    icon: 'pi pi-fw pi-plus',
                    command: () => {
                        this.addParentMaintenanceType();
                    }
                },
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editMaintenanceType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteMaintenanceType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleMaintenanceType();
                    }
                }
            ],
            leftX: 0,
            topY: 0,
            splitButtonMenu2: [
                {
                    label: 'Add',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'Child',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addChildMaintenanceType();
                            }
                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addParentMaintenanceType();
                            }
                        }
                    ]
                },
                {
                    separator: true
                },
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editMaintenanceType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteMaintenanceType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleMaintenanceType();
                    }
                }
            ]
        };

        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount = async () => {
        const response = await handleRequest("GET", "/MaintenanceType/getNodes");

        if (response.data.length === 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }

        document.addEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        event.preventDefault();
        setTimeout(function () {
            $(".p-contextmenu").css("left", event.clientX - 264 + "px");
            $(".p-contextmenu").css("top", event.clientY - 141 + "px");
        }, 50);
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/MaintenanceType/getNodes");

        if (response.data.length === 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }
    }

    actionTemplate(rowData, column) {
        return <SplitButton type="button" icon="pi pi-cog" className="p-button-secondary" model={this.state.splitButtonMenu} ></SplitButton>;
    }

    toggleMaintenanceType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        let expandedKeys = { ...this.state.expandedKeys };

        if (expandedKeys[arr[0]])
            delete expandedKeys[arr[0]];
        else
            expandedKeys[arr[0]] = true;

        this.setState({ expandedKeys: expandedKeys });
    }

    addChildMaintenanceType = () => {

        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal: true,
            maintenanceTypeParentId: arr[0]
        });
    }

    addChildMaintenanceTypeOK = async () => {
        var item = {
            parentId: this.state.maintenanceTypeParentId,
            maintenanceCode: this.state.modalMaintenanceCodeName,
            maintenanceTypeName: this.state.modalMaintenanceTypeName,
        }

        await handleRequest("POST", "/MaintenanceType/add", item);

        this.setState({
            addModal: false
        });

        this.restartControls();
        this.restartTable();
    }

    addParentMaintenanceType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal2: true,
            maintenanceTypeParentId: arr[0]  //seçili olanın id'si bu.
        });
    }

    addParentMaintenanceTypeOK = async () => {

        var response = await handleRequest("GET", "/MaintenanceType/" + this.state.maintenanceTypeParentId);
        var item;

        if (response !== undefined || response !== null)
            item = response.data;

        var itemNew = {
            parentWillChangeId: this.state.maintenanceTypeParentId,
            maintenanceCode: this.state.modalMaintenanceCodeName,
            maintenanceTypeName: this.state.modalMaintenanceTypeName,
            parentId: item.parentId
        }

        await handleRequest("POST", "/MaintenanceType/addParent", itemNew);

        this.setState({
            addModal2: false
        });

        this.restartControls();
        this.restartTable();
    }

    editMaintenanceType = async () => {
        var arr = this.state.selectedNodeKey.split('.');

        var response = await handleRequest("GET", "/MaintenanceType/" + arr[0]);

        this.setState({
            editModal: true,
            maintenanceTypeId: response.data.id,
            maintenanceTypeParentId: response.data.parentId,
            modalEditMaintenanceTypeName: response.data.maintenanceTypeName,
            modalEditMaintenanceCodeName: response.data.maintenanceCode,
        });
    }

    editMaintenanceTypeOK = async () => {
        var item = {
            parentId: this.state.maintenanceTypeParentId,
            id: this.state.maintenanceTypeId,
            maintenanceTypeName: this.state.modalEditMaintenanceTypeName,
            maintenanceCode: this.state.modalEditMaintenanceCodeName
        }

        await handleRequest("POST", "/MaintenanceType/update", item);

        this.setState({
            editModal: false
        });

        this.restartTable();
        this.restartControls();
    }

    deleteMaintenanceType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            deleteModal: true,
            maintenanceTypeId: arr[0]
        });
    }

    deleteMaintenanceTypeOK = async () => {
        const deletedItem = {
            id: this.state.maintenanceTypeId
        }

        await handleRequest("POST", "/MaintenanceType/delete", deletedItem);

        this.setState({
            deleteModal: false
        });

        this.restartControls();
        this.restartTable();
    }

    showOrHideModalDeleteMaintenanceType = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    showOrHideModalAddMaintenanceType = () => {
        this.setState({
            addModal: !this.state.addModal
        });
    }

    showOrHideModalAddMaintenanceType2 = () => {
        this.setState({
            addModal2: !this.state.addModal2
        });
    }

    showOrHideModalEditMaintenanceType = () => {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    saveMaintenanceType = async () => {

        if (this.state.maintenanceTypeId === null && this.state.rootMaintenanceTypeName !== ""
            && this.state.rootMaintenanceCode !== "") {
            var item = {
                maintenanceTypeName: this.state.rootMaintenanceTypeName,
                maintenanceCode: this.state.rootMaintenanceCode
            }

            await handleRequest("POST", "/MaintenanceType/add", item);
        }

        this.restartTable();
        this.restartControls();
    }

    restartControls = () => {
        this.setState({
            maintenanceTypeId: null,
            maintenanceTypeParentId: null,
            rootMaintenanceTypeName: "",
            rootMaintenanceCode: "",
            modalMaintenanceTypeName: "",
            modalMaintenanceCodeName: "",
            modalEditMaintenanceTypeName: "",
            modalEditMaintenanceCodeName: ""
        });
    }

    handleSplitMenuChange = e => {
        this.setState({ selectedNodeKey: e.value });
    }

    codeNameTemplate(rowData, column) {
        var rowData1 = rowData.data;
        return rowData1.maintenanceCode + " . " + rowData1.maintenanceTypeName;
    }

    render() {
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        let header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
        </div>;

        return (
            <div>
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteMaintenanceType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Maintenance Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Maintenance Type?
                    </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related types.
                    </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteMaintenanceType}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteMaintenanceTypeOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalAddMaintenanceType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Child Maintenance Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Child Maintenance Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <InputText onChange={(e) => this.setState({ modalMaintenanceTypeName: e.target.value })}
                                        value={this.state.modalMaintenanceTypeName} placeholder="Maintenance Type Name" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Child Maintenance Code:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <InputText onChange={(e) => this.setState({ modalMaintenanceCodeName: e.target.value })}
                                        value={this.state.modalMaintenanceCodeName} placeholder="Maintenance Code" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddMaintenanceType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addChildMaintenanceTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal2}
                    onHide={this.showOrHideModalAddMaintenanceType2}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Parent Maintenance Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Parent Maintenance Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalMaintenanceTypeName: e.target.value })}
                                        value={this.state.modalMaintenanceTypeName} placeholder="Maintenance Type Name" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Parent Maintenance Code:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <InputText onChange={(e) => this.setState({ modalMaintenanceCodeName: e.target.value })}
                                        value={this.state.modalMaintenanceCodeName} placeholder="Maintenance Code" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddMaintenanceType2}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addParentMaintenanceTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.editModal}
                    onHide={this.showOrHideModalEditMaintenanceType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Edit Maintenance Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Maintenance Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalEditMaintenanceTypeName: e.target.value })}
                                        value={this.state.modalEditMaintenanceTypeName} placeholder="Maintenance Type Name" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Maintenance Code:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <InputText onChange={(e) => this.setState({ modalEditMaintenanceCodeName: e.target.value })}
                                        value={this.state.modalEditMaintenanceCodeName} placeholder="Maintenance Code" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalEditMaintenanceType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.editMaintenanceTypeOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Card style={{ backgroundColor: "white", width: '700px' }}>
                    <Form style={{ padding: '5px 0px 5px 5px' }}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label style={{ marginTop: '0.3rem', fontWeight: 'bold', fontSize: '14px' }}>Maintenance Type Name:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ rootMaintenanceTypeName: e.target.value })} value={this.state.rootMaintenanceTypeName} placeholder="Maintenance Type Name" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label style={{ marginTop: '0.3rem', fontWeight: 'bold', fontSize: '14px' }}>Maintenance Code:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ rootMaintenanceCode: e.target.value })} value={this.state.rootMaintenanceCode} placeholder="Maintenance Code" />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <ReactButton type="button" style={{ width: '100%' }} onClick={this.saveMaintenanceType} variant="info">Add</ReactButton>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    <div style={{ left: `${this.state.leftX}`, top: `${this.state.topY}` }} className="content-section implementation">

                        <ContextMenu model={this.state.splitButtonMenu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />

                        <TreeTable value={this.state.nodes} expandedKeys={this.state.expandedKeys} emptyMessage="No Maintenance Types Found"
                            selectionMode="single" selectionKeys={this.state.selectedNodeKey} onSelectionChange={this.handleSplitMenuChange}
                            onToggle={e => this.setState({ expandedKeys: e.value })} globalFilter={this.state.globalFilter} header={header}
                            contextMenuSelection={this.state.selectedNodeKey} onContextMenuSelectionChange={e => this.setState({ selectedNodeKey: e.value })}
                            onContextMenu={e => this.cm.show(e.originalEvent)}>
                            {/*<Column header={actionHeader} body={this.actionTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />*/}
                            <Column body={this.codeNameTemplate} header="Maintenance Type" bodyStyle={{ fontWeight: 'bold' }} expander />
                        </TreeTable>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceTypes)