/* eslint-disable */

import React, { Component } from 'react';
import { Form, Row, Col, Button, Jumbotron, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Upload, message, Button as AntButton } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Checkbox } from 'primereact/checkbox';
import { Switch, InputNumber } from 'antd';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import $ from 'jquery';

export class FuelRefill extends Component {

    constructor(props) {
        super(props)
        this.state = {
            date: null,
            modalShow: false,
            modalShowAdd: false,
            project: "",
            company: "Test Company",
            trailer: "",
            trailerId: null,
            driver: "",
            driverId: null,
            lateDecleration: false,
            amountOfFuel: null,
            amountOfFuelShow: null,
            unitPrice: null,
            unitPriceShow: null,
            price: null,
            priceShow: null,
            kmOfTruck: null,
            sourceOfFuel: "Select Source of Fuel",
            sourceOfFuelId: null,
            sourceOfFuels: [],
            newSourceFuelName: "",
            invoicePhoto: "",
            meterPhoto: "",
            datePickerDisable: true,
            VAT: true,
            fuelRefills: [],
            fuelTankCapacity: null,
            project: "",
            projectId: null,
            customerId: null
        };
    }

    componentDidMount = async () => {
        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            date: today
        });

        const response = await handleRequest("GET", "/Code/getAll");

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "sourceOfFuel") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                sourceOfFuels: options
            });

        }

        if (this.props.auth.user.staff !== null) {
            const responseAssignment = await handleRequest("GET", "/Assignment/getEmployee/" + this.props.auth.user.staffId);

            if (responseAssignment.data.length !== 0) {
                const columns = responseAssignment.data;
                if (columns.equipmentLog !== null && columns.equipmentLog.length !== 0) {
                    if (columns.equipmentLog.equipmentVehicleLicenceList !== null && columns.equipmentLog.equipmentVehicleLicenceList.length !== 0
                        && columns.customerProject !== null && columns.customerProject.length !== 0
                        && columns.customerLogId !== null) {
                        this.setState({
                            driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
                            driverId: this.props.auth.user.staffId,
                            trailer: columns.equipmentLog.equipmentCode + ", " + columns.equipmentLog.equipmentVehicleLicenceList[0].plateNo,
                            trailerId: columns.equipmentLog.id,
                            projectId: columns.customerProjectId,
                            project: columns.customerProject.projectName,
                            customerId: columns.customerLogId
                        });
                    }
                    else {
                        this.setState({
                            driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
                            driverId: this.props.auth.user.staffId,
                            trailer: columns.equipmentLog.equipmentCode,
                            trailerId: columns.equipmentLog.id
                        });
                    }
                    if (columns.equipmentLog.equipmentOperatingList !== null && columns.equipmentLog.equipmentOperatingList.length !== 0) {
                        this.setState({
                            fuelTankCapacity: columns.equipmentLog.equipmentOperatingList[0].fuelTankCapacity
                        });
                    }
                    else {
                        this.setState({
                            fuelTankCapacity: null,
                        });
                    }
                }
            }
        }

        //Hızlandırma çalışmasından öncesi;

        // const response2 = await handleRequest("GET", "/Assignment/getAll");

        // if (response2.data.length !== 0) {

        //     const columns = response2.data;

        //     for (let index = 0; index < columns.length; index++) {
        //         if (columns[index].staffId === this.props.auth.user.staffId) {
        //             if (columns[index].equipmentLog !== null && columns[index].equipmentLog.length !== 0) {
        //                 if (columns[index].equipmentLog.equipmentVehicleLicenceList !== null && columns[index].equipmentLog.equipmentVehicleLicenceList.length !== 0
        //                     && columns[index].customerProject !== null && columns[index].customerProject.length !== 0
        //                     && columns[index].customerLogId !== null) {
        //                     this.setState({
        //                         driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
        //                         driverId: this.props.auth.user.staffId,
        //                         trailer: columns[index].equipmentLog.equipmentCode + ", " + columns[index].equipmentLog.equipmentVehicleLicenceList[0].plateNo,
        //                         trailerId: columns[index].equipmentLog.id,
        //                         projectId: columns[index].customerProjectId,
        //                         project: columns[index].customerProject.projectName,
        //                         customerId: columns[index].customerLogId
        //                     });
        //                 }
        //                 else {
        //                     this.setState({
        //                         driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
        //                         driverId: this.props.auth.user.staffId,
        //                         trailer: columns[index].equipmentLog.equipmentCode,
        //                         trailerId: columns[index].equipmentLog.id
        //                     });
        //                 }
        //                 if (columns[index].equipmentLog.equipmentOperatingList !== null && columns[index].equipmentLog.equipmentOperatingList.length !== 0) {
        //                     this.setState({
        //                         fuelTankCapacity: columns[index].equipmentLog.equipmentOperatingList[0].fuelTankCapacity
        //                     });
        //                 }
        //                 else {
        //                     this.setState({
        //                         fuelTankCapacity: null,
        //                     });
        //                 }
        //             }
        //         }
        //     }
        // }

        const response3 = await handleRequest("GET", "/FuelRefill/getDriverLast/" + this.props.auth.user.staffId);

        var flagFuelRefills = response3.data;

        if (flagFuelRefills.length !== 0) {
            let flagValue = "";
            try {
                flagValue = parseFloat(flagFuelRefills[flagFuelRefills.length - 1].unitPrice).toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                unitPrice: flagFuelRefills[flagFuelRefills.length - 1].unitPrice,
                unitPriceShow: flagValue
            });
        }

        //Hızlandırma çalışmasından öncesi;

        // const response3 = await handleRequest("GET", "/FuelRefill/getAll");

        // var flagFuelRefills = [];

        // if (response3.data.length === 0) {
        //     this.setState({
        //         fuelRefills: []
        //     });
        // }

        // if (response3.data.length !== 0) {

        //     for (let index = 0; index < response3.data.length; index++) {
        //         if ((this.props.auth.user.staffId === response3.data[index].staffId)) {
        //             flagFuelRefills.push(response3.data[index]);
        //         }
        //     }

        //     this.setState({
        //         fuelRefills: flagFuelRefills,
        //     });
        // }

        // if (flagFuelRefills.length !== 0) {
        //     let flagValue = "";
        //     try {
        //         flagValue = parseFloat(flagFuelRefills[flagFuelRefills.length - 1].unitPrice).toFixed(2);
        //     }
        //     catch (err) {
        //         flagValue = "";
        //     }
        //     this.setState({
        //         unitPrice: flagFuelRefills[flagFuelRefills.length - 1].unitPrice,
        //         unitPriceShow: flagValue
        //     });
        // }
    }

    addFuelRefill = async () => {
        var flagFuelTankCapacity = 0;
        var fuelTankCapacityFloat;
        var amountOfFuelFloat;
        if (this.state.fuelTankCapacity !== null && this.state.amountOfFuel !== null) {

            fuelTankCapacityFloat = parseFloat(this.state.fuelTankCapacity);
            amountOfFuelFloat = parseFloat(this.state.amountOfFuel);

            if (amountOfFuelFloat > fuelTankCapacityFloat) {
                flagFuelTankCapacity = 1;
            }
        }

        if (this.state.price !== null && this.state.unitPrice !== null && this.state.amountOfFuel !== null
            && this.state.sourceOfFuelId !== null && this.state.invoicePhoto !== "" && this.state.meterPhoto !== ""
            && this.state.kmOfTruck !== null && flagFuelTankCapacity == 0) {

            const newFuelRefill = {
                projectId: this.state.project,
                company: this.state.company,
                equipmentLogId: this.state.trailerId,
                staffId: this.state.driverId,
                fuelRefillTime: this.state.date,
                lateDecleration: this.state.lateDecleration,
                vatInclude: this.state.VAT,
                amountOfFuel: this.state.amountOfFuel,
                unitPrice: this.state.unitPrice,
                price: this.state.price,
                sourceOfFuel: this.state.sourceOfFuelId,
                kmOfTruck: this.state.kmOfTruck,
                invoicePhotoPath: this.state.invoicePhoto,
                meterPhotoPath: this.state.meterPhoto,
                customer: this.state.customerId,
                project: this.state.projectId
            }

            await handleRequest("POST", "/FuelRefill/add", newFuelRefill);

            message.success(`The operation is successful.`);
            this.restartForm();
        }
        else {
            this.showOrHideModal();
        }
    }

    restartForm = async () => {

        const response3 = await handleRequest("GET", "/FuelRefill/getDriverLast/" + this.props.auth.user.staffId);

        var flagFuelRefills = response3.data;

        if (flagFuelRefills.length !== 0) {
            let flagValue = "";
            try {
                flagValue = parseFloat(flagFuelRefills[flagFuelRefills.length - 1].unitPrice).toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                unitPrice: flagFuelRefills[flagFuelRefills.length - 1].unitPrice,
                unitPriceShow: flagValue
            });
        }

        //Hızlandırmadan önce kullanılan;

        // const response3 = await handleRequest("GET", "/FuelRefill/getAll");

        // var flagFuelRefills = [];

        // if (response3.data.length === 0) {
        //     this.setState({
        //         fuelRefills: []
        //     });
        // }

        // if (response3.data.length !== 0) {

        //     for (let index = 0; index < response3.data.length; index++) {
        //         if ((this.props.auth.user.staffId === response3.data[index].staffId)) {
        //             flagFuelRefills.push(response3.data[index]);
        //         }
        //     }

        //     this.setState({
        //         fuelRefills: flagFuelRefills,
        //     });
        // }

        // if (flagFuelRefills.length !== 0) {
        //     this.setState({
        //         unitPrice: flagFuelRefills[flagFuelRefills.length - 1].unitPrice
        //     });
        // }

        this.setState({
            lateDecleration: false,
            amountOfFuel: null,
            price: null,
            kmOfTruck: null,
            sourceOfFuel: "Select Source of Fuel",
            sourceOfFuelId: null,
            invoicePhoto: "",
            meterPhoto: "",
            datePickerDisable: true,
            VAT: true
        });
        $(".ant-upload-list").empty();
    }

    // addNewSourceOfFuel = async () => {
    //     const newSourceFuel = {
    //         name: this.state.newSourceFuelName,
    //         type: "sourceOfFuel"
    //     }

    //     await handleRequest("POST", "/Code/add", newSourceFuel);

    //     const response = await handleRequest("GET", "/Code/getAll");

    //     if (response.data.length !== 0) {

    //         const columns = response.data;

    //         var options = [];

    //         for (let index = 0; index < columns.length; index++) {
    //             if (columns[index].type === "sourceOfFuel") {
    //                 options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
    //             }
    //         }

    //         this.setState({
    //             sourceOfFuels: options
    //         });
    //     }

    //     this.showOrHideModal();
    // }

    // showOrHideAddModal = () => {
    //     this.setState({
    //         modalShowAdd: !this.state.modalShowAdd
    //     });
    // }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    invoicePhoto: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    transformFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const canvas = document.createElement('canvas');
                const img = document.createElement('img');
                img.src = reader.result;
                img.onload = () => {
                    let width = img.width
                    let height = img.height

                    canvas.width = width
                    canvas.height = height

                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    canvas.toBlob(resolve, 'image/jpeg', 0.5);
                };
            }
        });
    }

    handlePhotoChange2 = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    meterPhoto: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    onChangeDate = (value, dateString) => {
        this.setState({
            date: dateString
        });
    }

    handleChange = e => {
        e.preventDefault();
        try {
            var flagFloat = parseFloat(e.target.value);
            this.setState({
                [e.target.name]: flagFloat
            });
        } catch (error) {
            this.setState({
                [e.target.name]: null
            });
        }
    }

    handleChangeSourceFuel = e => {
        e.preventDefault();
        var sourceOfFuelIdVaule;

        for (var z = 0; z < this.state.sourceOfFuels.length; z++) {
            if (this.state.sourceOfFuels[z].name === e.target.value) {
                sourceOfFuelIdVaule = this.state.sourceOfFuels[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            sourceOfFuelId: sourceOfFuelIdVaule
        });
    }

    onChangeAmountOfFuel = (value) => {
        let flagValue = "";
        try {
            flagValue = value.toFixed(2);
        }
        catch (err) {
            flagValue = "";
        }
        this.setState({
            amountOfFuel: value,
            amountOfFuelShow: flagValue
        });

        setTimeout(() => {
            this.calculatePriceAOF();
        }, 1000);
    }

    onChangeUnitPrice = (value) => {
        let flagValue = "";
        try {
            flagValue = value.toFixed(2);
        }
        catch (err) {
            flagValue = "";
        }
        this.setState({
            unitPrice: value,
            unitPriceShow: flagValue
        });
        setTimeout(() => {
            this.calculatePriceUnit();
        }, 1000);
    }

    onChangePrice = (value) => {
        let flagValue = "";
        try {
            flagValue = value.toFixed(2);
        }
        catch (err) {
            flagValue = "";
        }
        this.setState({
            price: value,
            priceShow: flagValue
        });
        setTimeout(() => {
            this.calculatePricePrice();
        }, 1000);
    }

    onChangekmOfTruck = (value) => {
        this.setState({
            kmOfTruck: value
        });
    }

    calculatePriceAOF = () => {
        if (this.state.amountOfFuel !== null && this.state.unitPrice !== null) {
            var flagPrice = this.state.amountOfFuel * this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                price: flagPrice,
                priceShow: flagValue
            });
        } else if (this.state.amountOfFuel !== null && this.state.price !== null) {
            var flagUnitPrice = this.state.price / this.state.amountOfFuel;
            let flagValue = "";
            try {
                flagValue = flagUnitPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                unitPrice: flagUnitPrice,
                unitPriceShow: flagValue
            });
        }
    }

    calculatePriceUnit = () => {
        if (this.state.unitPrice !== null && this.state.price !== null) {
            var flagAmountOfFuel = this.state.price / this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagAmountOfFuel.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                amountOfFuel: flagAmountOfFuel,
                amountOfFuelShow: flagValue
            });
        } else if (this.state.amountOfFuel !== null && this.state.unitPrice !== null) {
            var flagPrice = this.state.amountOfFuel * this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                price: flagPrice,
                priceShow: flagValue
            });
        }
    }

    calculatePricePrice = () => {
        console.log(this.state.price);
        if (this.state.unitPrice !== null && this.state.price !== null) {
            var flagAmountOfFuel = this.state.price / this.state.unitPrice;
            let flagValue = "";
            try {
                flagValue = flagAmountOfFuel.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                amountOfFuel: flagAmountOfFuel,
                amountOfFuelShow: flagValue
            });
        } else if (this.state.amountOfFuel !== null && this.state.price !== null) {
            var flagUnitPrice = this.state.price / this.state.amountOfFuel;
            let flagValue = "";
            try {
                flagValue = flagUnitPrice.toFixed(2);
            }
            catch (err) {
                flagValue = "";
            }
            this.setState({
                unitPrice: flagUnitPrice,
                unitPriceShow: flagValue
            });
        }
    }

    disabledDate(current) {
        return current > moment().endOf('day');
    }

    render() {
        let optionsSourceFuels = this.state.sourceOfFuels.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        return (
            <div>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">There are missing or wrong fuel refill information values.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ color: 'black' }}>Can you check all the values you entered?</p>
                        <p style={{ color: 'black' }}>Also, do not forget that you cannot enter more fuel than fuel tank capacity.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHideModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

                {/*<Jumbotron style={{ padding: '20px' }}>
                    <Form>
                        <Form.Row>
                            <Col xs={2}>
                            </Col>
                            <Col xs={2}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >Project:</Form.Label>
                                <Form.Label style={{ color: 'black', marginLeft: '10px' }} >{this.state.project}</Form.Label>
                            </Col>
                            <Col xs={2}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >Truck:</Form.Label>
                                <Form.Label style={{ color: 'black', marginLeft: '10px' }} >{this.state.trailer}</Form.Label>
                            </Col>
                            <Col xs={2}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >Driver:</Form.Label>
                                <Form.Label style={{ color: 'black', marginLeft: '10px' }} >{this.state.driver}</Form.Label>
                            </Col>
                            <Col xs={2}>
                            </Col>
                            <Col xs={2}>
                            </Col>
                        </Form.Row>
                    </Form>
                </Jumbotron>*/}
                <div>

                    <Jumbotron style={{ padding: '20px' }}>
                        <Row style={{ marginTop: "10px", width: "100%" }}>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >Project:</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.project}</Form.Label>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >Truck:</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.trailer}</Form.Label>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >Driver:</Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.driver}</Form.Label>
                            </Col>
                        </Row>
                    </Jumbotron>

                    <Jumbotron style={{ padding: '20px' }}>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Date:
                            </Form.Label>
                            <Col sm="3">
                                <DatePicker disabledDate={this.disabledDate} value={moment(this.state.date, 'DD/MM/YYYY HH:mm')}
                                    disabled={this.state.datePickerDisable} showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDate} />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Col sm="3">
                            </Col>
                            <Col sm="3">
                                <div style={{ textAlign: 'left', marginTop: '0.5rem' }}>
                                    <Checkbox inputId="cb1" checked={this.state.lateDecleration}
                                        onChange={(e) => {
                                            var today = new Date();
                                            var HH = String(today.getHours()).padStart(2, '0');
                                            var minute = String(today.getMinutes()).padStart(2, '0');
                                            var dd = String(today.getDate()).padStart(2, '0');
                                            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                            var yyyy = today.getFullYear();

                                            var flagToday = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
                                            this.setState({ lateDecleration: e.checked, datePickerDisable: !e.checked, date: flagToday });
                                        }}></Checkbox>
                                    <label htmlFor="cb1" style={{ color: 'black' }} className="p-checkbox-label">Late Decleration</label>
                                </div>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Price:
                            </Form.Label>
                            <Col sm="3">
                                <InputNumber style={{ width: '85%' }} min={0} value={this.state.priceShow} onChange={this.onChangePrice} /> SR
                        </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Col sm="3">
                            </Col>
                            <Col sm="3">
                                <Switch checkedChildren="VAT included" unCheckedChildren="VAT excluded" checked={this.state.VAT} defaultChecked onChange={(value) => {
                                    this.setState({
                                        VAT: value
                                    })
                                }} />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Unit Price:
                            </Form.Label>
                            <Col sm="3">
                                <InputNumber style={{ width: '85%' }} min={0} value={this.state.unitPriceShow} onChange={this.onChangeUnitPrice} /> SR
                        </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Amount of Fuel:
                            </Form.Label>
                            <Col sm="3">
                                <InputNumber style={{ width: '85%' }} min={0} value={this.state.amountOfFuelShow} onChange={this.onChangeAmountOfFuel} /> liter
                        </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Source of Fuel:
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control name="sourceOfFuel" value={this.state.sourceOfFuel}
                                    onChange={this.handleChangeSourceFuel} as="select">
                                    <option value="">
                                        Select Source of Fuel
                            </option>
                                    {optionsSourceFuels}
                                </Form.Control>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Invoice Photo:
                            </Form.Label>
                            <Col sm="3">
                                <Upload
                                    name="avatar"
                                    className="avatar-uploader"
                                    action={`${API_BASE}/FuelRefill/upload`}
                                    beforeUpload={this.beforeUpload}
                                    transformFile={this.transformFile}
                                    onChange={this.handlePhotoChange}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Photo of Invoice
                                </AntButton>
                                </Upload>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Km of Truck:
                            </Form.Label>
                            <Col sm="3">
                                <InputNumber style={{ width: '100%' }} min={0} value={this.state.kmOfTruck} onChange={this.onChangekmOfTruck} />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Photo of Dashboard:
                            </Form.Label>
                            <Col sm="3">
                                <Upload
                                    name="avatar"
                                    className="avatar-uploader"
                                    action={`${API_BASE}/FuelRefill/upload`}
                                    beforeUpload={this.beforeUpload}
                                    transformFile={this.transformFile}
                                    onChange={this.handlePhotoChange2}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Photo of Meter
                                </AntButton>
                                </Upload>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: "2rem" }} as={Row} >
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <Button id='save-fuel' style={{ width: '100%' }} onClick={this.addFuelRefill} variant="info">Save</Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </Jumbotron>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelRefill)