/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Accordion } from 'react-bootstrap';
import { Button } from "primereact/button";
import { Form, Col, Row } from 'react-bootstrap';
import "react-toggle/style.css";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Table, Select, Switch, Modal } from 'antd';
import { Column } from 'primereact/components/column/Column';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { PickList } from 'primereact/picklist'
import { Tree as Tree2 } from 'antd';
import moment from 'moment';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu5';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { ThemeProvider } from '@material-ui/styles';

const { confirm } = Modal;
const { Option } = Select;

const ActionsList = require('../settings/JsonDatas/Actions.json');
const FormsList = require('../settings/JsonDatas/PageNames.json');

class WorkFlowExpand extends Component {


  constructor(props) {
    super(props)

    var formsFlag = [];
    formsFlag = FormsList;

    formsFlag.sort(function (a, b) {
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      if (b.name.toUpperCase() > a.name.toUpperCase()) {
        return -1;
      }
      return 0;
    });


    var newArray = [{ name: "All", items: [] }];

    formsFlag.forEach(element => {
      newArray.push(element);
    });

    this.state = {
      hidden: true,
      createNewButton: "Create New",
      createNewButtonVariant: "p-button-info",
      buttonClassName: "p-button-success",
      actiontype: "",
      actiontypeText: "",
      actiontypeId: 0,
      steporder: 0,
      addButton: "Add",
      workFlowName: "",
      workFlowId: 0,
      documentedinfo: true,
      documentedinfoText: "",
      documentReport: "",
      attachment: "",
      deadline: "",
      topurpose: "",
      topurposemail: "",
      topurposemailDizi: [],

      yearhidden: true,
      monthhidden: true,
      weekhidden: true,
      dayhidden: true,
      fsDiv: true,
      daynumberhidden: true,
      specificDayhidden: true,
      weekdays2hidden: true,
      flWorkingDayhidden: true,
      firstWorkingDay: false,
      lastWorkingDay: false,

      startTime: null,
      yearDateStart: "",
      yearHourStart: "",
      monthTimeStart: "",
      fsDate: "",
      weekTimeStart: "",
      dayTimeStart: "",

      monthDaysStart: "",
      flSelect: "",

      WorkFlows: [],
      WorkFlowStepAll: [],
      source: [],
      target: [],
      documentLogs: [],
      personel: [],
      personels: [],
      items: [],


      departmentName: "",
      departmentId: 0,
      departments: [],

      disciplineName: "",
      disciplineId: 0,
      disciplines: [],

      roleGroupName: "",
      roleGroupId: 0,
      roleGroups: [],

      designationName: "",
      designationId: 0,
      designations: [],

      userName: "Select User",
      userId: 0,
      users: [],

      actionNames: [],
      actionKeys: [],
      actions: [],

      formNames: [],
      formId: "",
      forms: newArray,

      selectedRow: {},
      authorizationList: [],

      selectedNodeKeys: [],
      checkedKeys: [],
      halfCheckedKeys: [],
      nodes: [],
      userKeys: [],
      menuList: [],
      userList: [],
      defaultExpandedKeys: ["All"],

      attachmentId: 0,
      documentLogId: 0,
      attachmentText: '',
      documentReporttext: '',
      toMessageKeys: [],
      oldMessageList: [],
      oldActionList: [],
      toActionKeys: [],

      duration: 0,
      durationType: '',

      isStartStep: false,
      newStep: '',
      workflowList: [],
      stepWorkflowId: '',
      selectedItems:[] ,
      stepDescription:'',
      stepNameId:null,
      stepWorkflowId:null

    };


    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);

  }

  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
  };

  showError(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
  };

  componentDidMount = async () => {

    this.getWorkFlowStep();
    this.getDocumentLogs();
    this.getPersonels();
    this.fillCodes();
    this.getWorkFlow();

    try {
      this.setState({
        menuList: this.props.auth.user.authList.filter(p => p.action === "view")
      });

    } catch (error) {

    }

    const responseUser = await handleRequest("GET", "/User/getCompanyItems");

    if (responseUser.data.length === 0) {
      this.setState({
        users: []
      });
    }

    if (responseUser.data.length !== 0) {


      this.setState({
        userList: responseUser.data,
      });

      const options = responseUser.data.map(function (row) {
        return { name: row.username.toString(), id: row.id.toString() }
      })

      this.setState({
        users: options,
      });

    }

    const options3 = ActionsList.map(function (row) {
      return { name: row.label.toString(), id: row.key.toString() }
    })

    this.setState({
      actions: options3,
    });



    var root = [{ key: "All", title: "All", label: "All", children: [] }];

    var pageKeys = [
      { key: "action", title: "Action", label: "Action" },
      { key: "download", title: "Download", label: "Download" },
      { key: "upload", title: "Upload", label: "Upload" },
      { key: "document", title: "Document", label: "Document" },
      { key: "deadline", title: "Deadline", label: "Deadline" },
      { key: "correctResubmit", title: "Correct and Resubmit", label: "Correct and Resubmit" },
      { key: "confirmed", title: "Confirmed", label: "Confirmed" },
      { key: "comments", title: "Comments", label: "Comments" },
      { key: "justifications", title: "Justification(s)", label: "Justification(s)" },
      { key: "post", title: "Post", label: "Post" },
    ];


    pageKeys.forEach(element => {
      root[0].children.push(element);
    });



    this.setState({
      nodes: root
    });

  }

  getWorkFlow = async () => {

    const response = await handleRequest("GET", "/WorkFlow/getAll");

    if (Boolean(response.data)) {
      const data = response.data;

      this.setState({ workflowList: data });
    }

  }

  saveSteps = async () => {

    var list = [];

    console.log(this.state.target); 

    this.state.target.forEach(element => {

      if (element.workFlowName)
        var obj = {
          stepWorkflowId: element.id, 
          workFlowId: this.state.workFlowId
        }
      else
        obj = {
          stepNameId: element.id,
          workFlowId: this.state.workFlowId
        }

      list.push(obj);
    });
    //await axios.post(`${API_BASE}/Discipline/add`, newDiscipline);
    var response = await handleRequest("POST", "/WorkFlowStep/addAll", list);

    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS-WORKFLOWSTEP-001") {
        this.getWorkFlowStep();
        this.showSuccess();
      }
      else if (response.data === "ERROR-WORKFLOWSTEP-001") {
        this.showError("An error was occured while adding please try again later !");
      }
    }


  }

  addStep = async () => {
    const { newStep } = this.state;


    if (newStep.length > 0) {

      const newItem = {
        name: newStep,
        type: "wfStep"
      }

      await handleRequest("POST", "/Code/add", newItem);


      this.setState({

        newStep: '',
      });

      this.fillCodes();
    }

  };

  updateCode = async () => {
    const { selectedItems } = this.state;


    if (selectedItems.length > 0) {

      

      await handleRequest("POST", "/Code/update", selectedItems[0]); 



      this.fillCodes();
    }

  };

  deleteCode = async () => {
    const { selectedItems } = this.state;


    if (selectedItems.length > 0) {

      

      await handleRequest("POST", "/Code/delete", selectedItems[0]); 



      this.fillCodes();
    }


  };

  addStep2 = async () => {
    const { stepWorkflowId, target } = this.state;

    var wf = this.state.workflowList.find(p => p.id == stepWorkflowId)

    console.log(wf);
    console.log(stepWorkflowId);  
    console.log(target);  
    if (Boolean(wf)) {
      wf.name = wf.workFlowName;
      target.push(wf);
      this.setState({
        target: target
      });

    }

  };

  fillCodes = async () => {

    const response = await handleRequest("GET", "/Code/type/wfStep");

    console.log(response.data);
    if (response.data !== null && response.data !== undefined) {
      this.setState({
        source: response.data,
        selectedItems:[] 
      });
    }

  }



  getWorkFlowStep = async () => {

    const response = await handleRequest("GET", "/WorkFlow/" + this.props.row.id);
    if (Boolean(response.data)) {
      //console.log(response.data);
      const steps = response.data.steps;

      if (Boolean(steps)) {
        var period = response.data.periods.filter(p => p.id == this.props.row.periodId);
        if (Boolean(period)) {
          const first = Object.assign({}, period[0]);
          var flagDate = moment(first.startDate);
          var flagDate2 = moment(first.startDate);
          var startStepIndex = steps.findIndex(p => p.startStep === true);

          var index = 0;
          var index2 = -1;

          if (startStepIndex > -1) {
            index = startStepIndex;
            index2 = startStepIndex - 1;
          }


          while (index < steps.length) {
            // steps.forEach(element => {
            var element = steps[index];
            console.log(element);
            element.index = index + 1;
            if (index === 0 || index === startStepIndex) {

              let eDate = flagDate.toDate();
              element.startDate = eDate;
            }
            else {
              element.startDate = steps[index - 1].endDate;
            }

            // flagDate = flagDate.add(1, 'years');
            // let eDate = flagDate.toDate(); 
            // element.endDate = eDate;

            switch (element.durationType) {
              case 'year':
                if (Boolean(element.startDate)) {
                  flagDate = flagDate.add(element.duration, 'years');
                  let eDate = flagDate.toDate();
                  element.endDate = eDate;
                }
                break;

              case 'month':

                if (Boolean(element.startDate)) {
                  flagDate = flagDate.add(element.duration, 'months');
                  let eDate = flagDate.toDate();
                  element.endDate = eDate;
                }

                break;
              case 'week':
                if (Boolean(element.startDate)) {
                  flagDate = flagDate.add(element.duration, 'weeks');
                  let eDate = flagDate.toDate();
                  element.endDate = eDate;
                }
                break;
              case 'day':
                if (Boolean(element.startDate)) {
                  flagDate = moment(element.startDate).add(element.duration, 'days');
                  let eDate = flagDate.toDate();
                  element.endDate = eDate;
                }
                break;
              case 'hour':
                if (Boolean(element.startDate)) {
                  flagDate = moment(element.startDate).add(element.duration, 'hours');
                  let eDate = flagDate.toDate();
                  element.endDate = eDate;
                }
                break;
              case 'minute':
                if (Boolean(element.startDate)) {
                  flagDate = flagDate.add(element.duration, 'minutes');
                  let eDate = flagDate.toDate();
                  element.endDate = eDate;
                }
                break;

              default:
                element.endDate = element.startDate;
                break;
            }


            index++;
          }


          while (index2 >= 0) {
            // steps.forEach(element => {
            var element = steps[index2];
            console.log(element);
            element.index = index2 + 1;

            element.endDate = steps[index2 + 1].startDate;

            // flagDate = flagDate.add(1, 'years');
            // let eDate = flagDate.toDate(); 
            // element.endDate = eDate;

            switch (element.durationType) {
              case 'year':
                if (Boolean(element.endDate)) {
                  flagDate2 = flagDate2.subtract(element.duration, 'years');
                  let eDate = flagDate2.toDate();
                  element.startDate = eDate;
                }
                break;

              case 'month':

                if (Boolean(element.endDate)) {
                  flagDate2 = flagDate2.subtract(element.duration, 'months');
                  let eDate = flagDate2.toDate();
                  element.startDate = eDate;
                }

                break;
              case 'week':
                if (Boolean(element.endDate)) {
                  flagDate2 = flagDate2.subtract(element.duration, 'weeks');
                  let eDate = flagDate2.toDate();
                  element.startDate = eDate;
                }
                break;
              case 'day':
                if (Boolean(element.endDate)) {
                  flagDate2 = moment(element.endDate).subtract(element.duration, 'days');
                  let eDate = flagDate2.toDate();
                  element.startDate = eDate;
                }
                break;
              case 'hour':
                if (Boolean(element.endDate)) {
                  flagDate2 = moment(element.endDate).subtract(element.duration, 'hours');
                  let eDate = flagDate2.toDate();
                  element.startDate = eDate;
                }
                break;
              case 'minute':
                if (Boolean(element.endDate)) {
                  flagDate2 = flagDate2.subtract(element.duration, 'minutes');
                  let eDate = flagDate2.toDate();
                  element.startDate = eDate;
                }
                break;

              default:
                element.startDate = element.endDate;
                break;
            }


            index2--;
          }



        }

      }
      const rightSteps = [] ; 
      steps.forEach(element => {
        const e = Object.assign({},element);
        if(Boolean(element.stepWf))
       { e.name = element.stepWf.workFlowName ;
        e.id=element.stepWorkflowId}
        if(Boolean(element.stepName))  
        {
          e.name = element.stepName.name ; 
          e.id = element.stepNameId; 
        }
        rightSteps.push(e); 
      });

      this.setState({ WorkFlowStepAll: steps , target:rightSteps }); 
    }
  }

  getPersonels = async () => {

    const responseUser = await handleRequest("GET", "/User/getCompanyItems");

    if (responseUser.data.length !== 0) {


      var filteredUsers = responseUser.data.filter(p => p.staffId > 0);

      const options = filteredUsers.map(function (row) {
        return { name: row.staff.name.toString() + " " + row.staff.midName.toString() + " " + row.staff.lastName.toString(), id: row.id }
      })

      this.setState({
        personels: options,
      });

    }

  }

  getDocumentLogs = async () => {
    const response = await handleRequest("GET", "/DocumentLog/getAll");

    if (response.data.length === 0) {
      this.setState({
        documentLogs: [],
      });
    }

    if (response.data.length !== 0) {

      const columns = response.data;

      const options = columns.map(function (row) {
        return { name: row.description, id: row.id }
      })

      this.setState({
        documentLogs: options,
      });
    }
  }

  getMenu = (element, list, actList) => {

    if (element.title) {
      var obj = { key: element.title, title: element.title, label: element.title, data: "", children: [] }


      list.push(obj);

      if (element.submenu) {
        element.submenu.forEach(el => {
          if (this.state.menuList.findIndex(p => p.formTitle === el.title || p.form === "All") >= 0)
            this.getMenu(el, obj.children, actList);
          //  list.push(obj);
        });

      }
      else {
        let _list = actList.slice();
        _list.forEach(act => {
          const obj2 = { key: act.key + "~" + obj.key, label: act.label, title: act.label, data: "", children: [] }
          obj.children.push(obj2);
        });

      }

    }


  }





  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(e.target.value);
  };



  bodyBy(rowData, column) {

    console.log(rowData);

  }

  openWorkflowModal = async (row) => {
    this.setState({
      displayFlowchartDialog: true,
      workFlowId: this.props.row.id,
    });
  };



  addFlowchartName = async () => {
    const newWorkFlowName = {
      actiontype: this.state.actiontype,
      workFlowId: this.state.workFlowId
    }
    console.log(newWorkFlowName.workFlowId);
    //await axios.post(`${API_BASE}/Discipline/add`, newDiscipline);
    var response = await handleRequest("POST", "/WorkFlowStep/add", newWorkFlowName);

    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS-WORKFLOWSTEP-001") {
        this.getWorkFlowStep();
        this.showSuccess();
      }
      else if (response.data === "ERROR-WORKFLOWSTEP-001") {
        this.showError("An error was occured while adding please try again later !");
      }
    }
  };

  edit = async (row) => {

    var _list = [];
    var _oldActionList = [];
    var _oldMessageList = [];
    var _messages = [];
    var _actions = [];

    if (Boolean(row.pageAttributes)) {
      _oldActionList = row.pageAttributes;
      row.pageAttributes.forEach(element => {
        _list.push(element.attributeKey);
      });
    }

    if (Boolean(row.messages)) {
      _oldMessageList = row.messages;
      row.messages.forEach(element => {
        if (element.action)
          _actions.push(element.userId);
        else
          _messages.push(element.userId);

      });
    }



    this.setState({
      hidden: false,
      actiontypeId: row.id,
      steporder: row.steporder,
      actiontypeText: row.actiontype,
      documentLogId: row.documentLogId,
      attachmentId: row.attachmentId,
      bywho: row.byWho,
      checkedKeys: _list,
      toMessageKeys: _messages,
      toActionKeys: _actions,
      deadline: row.deadline,
      oldMessageList: _oldMessageList,
      oldActionList: _oldActionList,
      duration: row.duration,
      durationType: row.durationType,
      isStartStep: row.startStep,
      stepDescription:row.stepDescription,
      stepNameId:row.stepNameId,
      stepWorkflowId:row.stepWorkflowId,

    });


  };

  cancelButtonUpdate = async (row) => {
    this.setState({
      hidden: true,
      actiontypeText: "",
    });
  };



  wfStepTemplate(wfs) {
    //var imageSource = 'showcase/resources/demo/images/car/' + car.brand + '.png';
    //<img src={imageSource} alt={car.brand} style={{ display: 'inline-block', margin: '2px 0 2px 2px', width: 48 }} />

    return (
      <div className="p-clearfix">
        <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}>{wfs.name}</div>
      </div>
    );
  }

  handleFlowchartHide = async () => {
    this.setState({
      displayFlowchartDialog: false,
    });
    this.getWorkFlowStep();
  }



  onDocumentReportChange = event => {

    var docName = { name: '' };
    var index = this.state.documentLogs.findIndex(p => p.id == event.target.value);
    if (index >= 0)
      docName = this.state.documentLogs[index];
    console.log(docName);
    this.setState({
      documentReporttext: docName.name,
      documentLogId: event.target.value,
    });
  };

  onAttachmentChange = event => {

    var docName = { name: '' };
    var index = this.state.documentLogs.findIndex(p => p.id == event.target.value);
    if (index >= 0)
      docName = this.state.documentLogs[index];
    this.setState({
      attachmentText: docName.name,
      attachmentId: event.target.value,
    });
  };



  onStartTimeChange(value, dateString) {
    this.setState({ startTime: dateString });
    console.log('Formatted Selected Time: ', dateString);
  }



  addWorkflow = async () => {

    var _messages = this.state.oldMessageList;
    var actionList = this.state.oldActionList;

    actionList.forEach(a => {
      a.active = false;
    });

    _messages.forEach(m => {
      m.active = false;
    });

    this.state.checkedKeys.forEach(element => {

      var obj = { attributeKey: element };
      let index = actionList.findIndex(p => p.attributeKey === element);
      if (index === -1)
        actionList.push(obj);
      else
        actionList[index].active = true;

    });

    this.state.toMessageKeys.forEach(element => {

      var obj = { userId: element };
      let index = _messages.findIndex(p => p.userId === element);
      if (index === -1)
        _messages.push(obj);
      else
        _messages[index].active = true;

    });

    this.state.toActionKeys.forEach(element => {

      var obj = { userId: element, action: true };
      let index = _messages.findIndex(p => p.userId === element);
      if (index === -1)
        _messages.push(obj);
      else {
        _messages[index].active = true;
        _messages[index].action = true;
      }


    });

    const param = {
      workFlowId: this.props.row.id,
      id: this.state.actiontypeId,
      actiontype: this.state.actiontypeText,
      attachmentId: this.state.attachmentId,
      documentLogId: this.state.documentLogId,
      deadline: this.state.deadline,
      steporder: this.state.steporder,
      topurpose: this.state.topurpose,
      topurposemail: this.state.topurposemailDizi,
      byWho: this.state.bywho,
      toPurpose: null,
      pageAttributes: actionList,
      messages: _messages,
      duration: this.state.duration,
      durationType: this.state.durationType,
      startStep: this.state.isStartStep,
      stepNameId:this.state.stepNameId,
      stepWorkflowId:this.state.stepWorkflowId 
    }

    await handleRequest("POST", "/WorkFlowStep/update", param);

    this.getWorkFlowStep();

    this.showSuccess();
    this.resetInputs();

  }

  resetInputs = () => {

    this.setState({
      hidden: true,
      actiontypeId: 0,
      steporder: 0,
      actiontypeText: '',
      documentLogId: 0,
      attachmentId: 0,
      bywho: 0,
      checkedKeys: [],
      toMessageKeys: [],
      deadline: '',
      oldMessageList: [],
      oldActionList: [],
      isStartStep: false,
      stepDescription:'',
      stepNameId:null,
      stepWorkflowId:null
    });


  }

  onCheck = (checkedKeys, e) => {
    console.log('onCheck', checkedKeys);
    console.log('halfCheckedKeys', e.halfCheckedKeys);
    console.log(e.checkedNodes);
    this.setState({
      checkedKeys: checkedKeys,
      halfCheckedKeys: e.halfCheckedKeys
    });

  };
  onChangeToMessage = (value) => {

    this.setState({
      toMessageKeys: value
    });

  }

  onChangeToAction = (value) => {

    this.setState({
      toActionKeys: value
    });

  }

  deleteStep = async (pId) => {
    const item = {
      id: pId
    }

    await handleRequest("POST", "/WorkFlowStep/delete", item);
    this.getWorkFlowStep();
    this.showSuccess();


    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);


  }

  deleteModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this step?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected step will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteStep(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  render() {
    let dialogFlowchartHeader = <div>
      {this.props.row.workFlowName} Work Steps
    </div>;
    let dialogFlowchartFooter = <div className="ui-dialog-buttonpane p-clearfix">
      <Button id='save-workFlow' onClick={this.saveSteps} className={this.state.buttonClassName} label="Save" />
    </div>;

   
    let wfList = this.state.workflowList.map((data) =>
      <option
        id={data.id}
        value={data.id}
      >
        {data.workFlowName}
      </option>
    );


    let optionsBy = this.state.personels.map((data) =>
      <option
        id={data.id}
        value={data.id}
      >
        {data.name}
      </option>
    );

    let optionsdocumentReport = this.state.documentLogs.map((data) =>
      <option
        id={data.id}
        value={data.id}
      >
        {data.name}
      </option>
    ); let optionsDepartments = this.state.departments.map((data) =>
      <option
        id={data.id}
        value={data.name}
      >
        {data.name}
      </option>
    );

  
    let optionsRolegroups = this.state.roleGroups.map((data) =>
      <option
        key={data.id}
        value={data.name}
      >
        {data.name}
      </option>
    );
    let optionsDesignations = this.state.designations.map((data) =>
      <option
        key={data.id}
        value={data.name}
      >
        {data.name}
      </option>
    );

    let optionsUsers = this.state.users.map((data) =>
      <option
        key={data.id}
        value={data.name}
      >
        {data.name}
      </option>
    );


    let optionsForms = [];
    this.state.forms.forEach(data => {

      optionsForms.push({ label: data.name, value: data.name });
    });

    let optionsActions = [];
    this.state.actions.forEach(data => {

      optionsActions.push({ label: data.name, value: data.id });
    });

    return (
      <div>

        <div className="col-md-12">
          <div style={{ backgroundColor: "white", padding: "10px" }} >
            <Row >
              <Col sm="12" md={{ size: 2, offset: 10 }}>
                <Button id='btn-createnewstep' style={{ width: '100%' }} className={this.state.createNewButtonVariant} onClick={this.openWorkflowModal.bind(this)} label="Steps" /> 
              </Col>
            </Row>

          </div>
          <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.hidden} >
            <form>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Form.Label>Step Name</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">

                <Form.Label>{this.state.stepDescription}</Form.Label>  
                 
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                  <Form.Label>By</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                  <Form.Control name="bywho" value={this.state.bywho}
                    onChange={this.handleChange} as="select">
                    <option value="">
                      Select By
                                </option>
                    {optionsBy}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Form.Label> Is Start Step  </Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" checked={this.state.isStartStep} defaultChecked onChange={(value) => {
                    this.setState({
                      isStartStep: value,
                    });
                  }} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">

                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">

                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Form.Label></Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Switch checkedChildren="Document" unCheckedChildren="Report" checked={this.state.documentedinfo} defaultChecked onChange={(value) => {
                    this.setState({
                      documentedinfo: value,
                    });
                  }} />
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                  <Form.Label>Document/Report</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                  <Form.Control name="documentReporttext" value={this.state.documentLogId}
                    onChange={this.onDocumentReportChange} as="select">
                    <option value="">
                      Select Document/Report
                    </option>
                    {optionsdocumentReport}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                  Attachment
                      </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                  <Form.Control name="attachmentText" value={this.state.attachmentId}
                    onChange={this.onAttachmentChange} as="select">
                    <option value="">
                      Select Attachment
                    </option>
                    {optionsdocumentReport}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                  Deadline
                      </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">

                  <Form.Control name="deadline" value={this.state.deadline}
                    onChange={this.handleChange} as="select">
                    <option value="0">Select Deadline</option>
                    <option value="Legal">Legal</option>
                    <option value="Contract">Contract</option>
                    <option value="Internal">Internal</option>
                    <option value="Management">Management</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                  Duration
                      </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                  <InputNumber min={0} style={{ width: '100%' }} value={this.state.duration} onChange={(value) => { this.setState({ duration: value }) }} />

                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                  <Form.Control name="durationType" value={this.state.durationType}
                    onChange={this.handleChange} as="select">
                    <option>Select Duration</option>
                    <option value="year">Year</option>
                    <option value="month">Month</option>
                    <option value="week">Week</option>
                    <option value="day">Day</option>
                    <option value="hour">Hour</option>
                    <option value="minute">Minute</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Form.Label>Page</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Tree2 style={{ width: '100%' }}
                    checkable
                    showLine={true}
                    onCheck={this.onCheck}
                    selectedKeys={this.state.checkedKeys}
                    checkedKeys={this.state.checkedKeys}
                    treeData={this.state.nodes}
                    defaultExpandedKeys={['All']}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Form.Label>To Purpose/Action</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">

                  <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.toActionKeys} onChange={this.onChangeToAction}>
                    {this.state.personels.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">

                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">
                  <Form.Label>To Message</Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignWpsno">

                  <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.toMessageKeys} onChange={this.onChangeToMessage}>
                    {this.state.personels.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGriJointdesignRevno">

                </Form.Group>
                <Form.Group as={Col} controlId="formGridJointdesignRevno">
                </Form.Group>
              </Form.Row>
            </form>
            <Row >
              <Col sm="12" md={{ size: 2, offset: 10 }}>
                <Button id='btn-updatestep' style={{ width: '100%' }} className={this.state.buttonClassName} onClick={this.addWorkflow} label="Update" />
              </Col>
            </Row>
          </div>
          <br />
          <Toast ref={(el) => this.toast = el}></Toast>
          <div  >
            <Accordion defaultActiveKey="1">
              <Card>
                <Table
                  {...this.state}
                  responsive striped bordered hover
                  dataSource={this.state.WorkFlowStepAll} pagination={{ pageSize: 5, defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'], total: this.state.WorkFlowStepAll.length, showQuickJumper: true, position: "bottomLeft" }}
                >
                  <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                      <React.Fragment> <CustomMaterialMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onWorkflowRow={this.openWorkflowModal.bind(this)} />   </React.Fragment>
                    )}
                  />
                  <Column title="S/N" dataIndex="index" style={{ textAlign: 'center', width: '10%' }} sorter={(a, b) => a.steporder - b.steporder} />
                  <Column title="Step Name" style={{ textAlign: 'center' }} render={(text, record) => {
                    
                      
                    if(Boolean(record.stepWf))
                     return record.stepWf.workFlowName ; 

                    if (Boolean(record.stepName))
                      return record.stepName.name
                  

                  }
                  } style={{ textAlign: 'center' }} />
                  <Column title="By" render={(text, record) => {
                    if (Boolean(record.userByWho))
                      return record.userByWho.staff.name + ' ' + record.userByWho.staff.lastName
                  }
                  } style={{ textAlign: 'center' }} />
                  <Column title="Documented Info" render={(text, record) => {
                    if (record.document !== null)
                      return record.document.description;
                  }
                  } style={{ textAlign: 'center' }} />
                  <Column title="Attachment" render={(text, record) => {
                    if (record.docAttachment !== null)
                      return record.docAttachment.description
                  }
                  } style={{ textAlign: 'center' }} />

                  <Column title="Period" render={(text, record) => {
                    if (Boolean(record.duration) && Boolean(record.durationType))
                      return record.duration + ' ' + record.durationType + "(s)";
                  }
                  } style={{ textAlign: 'center' }} />

                  {/* <Column title="Start Date"
                    render={(text, record) => {
                      if (record.startDate !== null)
                        return moment(record.startDate).format('DD/MM/YYYY HH:mm')
                    }
                    }
                    style={{ textAlign: 'center' }} />
                  <Column title="End Date"
                    render={(text, record) => {
                      if (record.endDate !== null)
                        return moment(record.endDate).format('DD/MM/YYYY HH:mm')
                    }
                    }
                    style={{ textAlign: 'center' }} /> */}

                  <Column title="To, Purpose and Message"
                    render={(text, record) => {
                      let result = [];
                      if (record.messages !== null && record.messages !== undefined && record.messages.length > 0)
                        record.messages.forEach(e => {
                          if (Boolean(e.user))
                            result.push(e.user.staff.name + ' ' + e.user.staff.lastName);
                        });
                      return result.toString();
                    }
                    }
                    style={{ textAlign: 'center' }} />

                </Table>

              </Card>
            </Accordion>
            <br />
            <Dialog visible={this.state.displayFlowchartDialog} style={{ width: "50vw", height: "500px" }} header={dialogFlowchartHeader} modal={true} footer={dialogFlowchartFooter} onHide={this.handleFlowchartHide}>
              {
                <Card style={{ width: "50vw", height: "450px" }}>
                  <Toast ref={(el) => this.toast = el} />
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <PickList source={this.state.source} target={this.state.target} itemTemplate={this.wfStepTemplate} onSourceSelect={(e)=> this.setState({ selectedItems : e.value}) } 
                          onChange={(e) => this.setState({ source: e.source, target: e.target })}  sourceHeader="Workflow Steps" targetHeader="Selection" responsive={true} />
                      </Col>
                    </Row>

                   {Boolean(this.state.selectedItems) && this.state.selectedItems.length>0 && <Row style={{ marginTop: '10px' }}>
                      <Col sm="6">

                        <InputText style={{ width: '100%' }} onChange={(e) => {
                          var old = this.state.selectedItems ; 
                          old[0].name = e.target.value ;  
                          this.setState({ selectedItems: old})}} value={this.state.selectedItems[0].name} placeholder="Step Name" /> 
                      </Col>

                      <Col sm="2">
                      <Button style={{ width: '100%' }} className="p-button-warning" onClick={this.updateCode} label="Update" /> 
                      </Col>
                      <Col sm="2"> 
                      <Button style={{ width: '100%' }} className="p-button-danger" onClick={this.deleteCode} label="Delete" />  
                      </Col>
                    
                    </Row>}

                    <Row style={{ marginTop: '10px' }}>
                      <Col sm="6">

                        <InputText style={{ width: '80%' }} onChange={(e) => this.setState({ newStep: e.target.value })} value={this.state.newStep} placeholder="Step Name" />
                        <Button style={{ width: '20%' }} id='save-workFlow' className={this.state.buttonClassName} onClick={this.addStep} label={this.state.addButton} />

                      </Col>
                      <Col sm="4">

                        <Form.Control  style={{ width: '100%' }} name="stepWorkflowId" value={this.state.stepWorkflowId}
                          as="select" onChange={(e) => {
                            e.persist();
                            let val = e.target.value;

                            this.setState({
                              stepWorkflowId: val
                            })
                          }}  >
                          <option value="">
                            Select Workflow as Step
                                </option>
                          {wfList}
                        </Form.Control>                          

                      </Col> 
                      <Col sm="2">
                      <Button  style={{ width: '100%' }} id='save-workFlow' className={this.state.buttonClassName} onClick={this.addStep2} label={this.state.addButton} />
                      </Col>

                    </Row>
                  </Card.Body>
                </Card>
              }
            </Dialog>

          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WorkFlowExpand)
