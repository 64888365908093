import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import { useSelector } from "react-redux";

export const userTokn={
 
}

export const actionList= [{id: 0, form: "", action: "", userId: 1, active: false,index:0}] ; 
  


export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "v706-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        console.log("AUTH REDUX authToken",authToken); 
             
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        var { user } = action.payload;
        userTokn.authToken=user;
        userTokn.config = { headers: { 'accessToken':user.accessToken,'userId':user.id} };
        console.log("xxxxxxxx",userTokn); 
        return { ...state, user };
      }

      default:
        try {
          userTokn.authToken=user;
          userTokn.config = { headers: { 'accessToken':user.accessToken,'userId':user.id} };
        } catch (error) {
          
        }
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user  } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    try {
   
      userTokn.authToken=user;
      userTokn.config = { headers: { 'accessToken':user.accessToken,'userId':user.id} };

      console.log("xxxxxxxx",userTokn); 
    } catch (error) {
      
    }
    yield put(actions.fulfillUser(user));
  });
}
