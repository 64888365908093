/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "EmployeeHiring";

export class EmployeeHiring extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            departmentId: null,
            disciplineId: null,
            designationId: null,
            employeeHiringSteps: [],
            hiringSteps: [],
            preparationSelectItems: [],
            evaluationSelectItems: [],
            codeName: '',
            hasEvaluation: true,
            stepName: '',
            departments: [],
            disciplines: [],
            designations: [],
            oldEmployeeHiring: null,
            hideStepModal: true,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })
        this.getDepartment();
        this.getDiscipline();
        this.getDesignation();
        // this.restartTable();
        this.getHiringSteps();
        this.fillCodes();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    getDepartment = async () => {
        const response = await handleRequest("GET", "/Department/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                departments: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                departments: response.data,
            });
        }
    }

    getDiscipline = async () => {
        const response = await handleRequest("GET", "/Discipline/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                disciplines: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                disciplines: response.data,
            });
        }
    }

    getDesignation = async () => {
        const response = await handleRequest("GET", "/Designation/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                designations: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                designations: response.data,
            });
        }
    }

    save = async () => {

        const { oldEmployeeHiring, hiringSteps } = this.state;
        let eh = {
            departmentId: this.state.departmentId,
            disciplineId: this.state.disciplineId,
            designationId: this.state.designationId,
            employeeHiringSteps: []
        };

        if (Boolean(hiringSteps) && hiringSteps.length > 0) {
            let list = hiringSteps.filter(p => p.check === true);
            list.forEach(element => {
                let ehs = { hiringStepId: element.id, preparations: [], evaluations: [] };

                if (Boolean(element.preparations) && element.preparations.length > 0) {
                    element.preparations.forEach(p => {
                        ehs.preparations.push({ preparationId: p })
                    });
                }

                if (Boolean(element.evaluations) && element.evaluations.length > 0) {
                    element.evaluations.forEach(e => {
                        ehs.evaluations.push({ evaluationId: e })
                    });
                }
                eh.employeeHiringSteps.push(ehs);
            });
        }



        if (Boolean(oldEmployeeHiring)) {

            if (Boolean(oldEmployeeHiring.employeeHiringSteps)) {
                oldEmployeeHiring.employeeHiringSteps.forEach(element => {
                    element.active = false;
                    if (Boolean(element.preparations))
                        element.preparations.forEach(p => {
                            p.active = false;
                        });

                    if (Boolean(element.evaluations))
                        element.evaluations.forEach(e => {
                            e.active = false;
                        });
                });
            }

            if (Boolean(eh.employeeHiringSteps) && eh.employeeHiringSteps.length > 0) {

                eh.employeeHiringSteps.forEach(element => {

                    if (oldEmployeeHiring.employeeHiringSteps != null && oldEmployeeHiring.employeeHiringSteps.length > 0) {
                        let index = oldEmployeeHiring.employeeHiringSteps.findIndex(p => p.hiringStepId === element.hiringStepId);
                        if (index >= 0) {
                            oldEmployeeHiring.employeeHiringSteps[index].active = true;

                            if (Boolean(element.preparations)) {
                                element.preparations.forEach(preparation => {

                                    if (Boolean(oldEmployeeHiring.employeeHiringSteps[index].preparations) && oldEmployeeHiring.employeeHiringSteps[index].preparations.length > 0) {
                                        let j = oldEmployeeHiring.employeeHiringSteps[index].preparations.findIndex(p => p.preparationId === preparation.preparationId);
                                        if (j >= 0)
                                            oldEmployeeHiring.employeeHiringSteps[index].preparations[j].active = true;
                                        else oldEmployeeHiring.employeeHiringSteps[index].preparations.push(preparation);
                                    } else {
                                        oldEmployeeHiring.employeeHiringSteps[index].preparations.push(preparation);
                                    }

                                });
                            }

                            if (Boolean(element.evaluations)) {
                                element.evaluations.forEach(evaluation => {

                                    if (Boolean(oldEmployeeHiring.employeeHiringSteps[index].evaluations) && oldEmployeeHiring.employeeHiringSteps[index].evaluations.length > 0) {
                                        let j = oldEmployeeHiring.employeeHiringSteps[index].evaluations.findIndex(p => p.evaluationId === evaluation.evaluationId);
                                        if (j >= 0)
                                            oldEmployeeHiring.employeeHiringSteps[index].evaluations[j].active = true;
                                        else oldEmployeeHiring.employeeHiringSteps[index].evaluations.push(evaluation);
                                    } else {
                                        oldEmployeeHiring.employeeHiringSteps[index].evaluations.push(evaluation);
                                    }

                                });
                            }

                        }
                        else oldEmployeeHiring.employeeHiringSteps.push(element);
                    }
                    else oldEmployeeHiring.employeeHiringSteps.push(element);
                });
            }

            var obj = oldEmployeeHiring;
        }
        else {
            obj = eh;
        }


        await handleRequest("POST", "/EmployeeHiring/save", obj);


        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            //  [EDIT_FORM_FIELDS]
        });

        this.setState({
            //  [EDIT_STATE_FIELDS], 
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/EmployeeHiring/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/EmployeeHiring/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            //[RESETINPUTS_FORMITEMS] 

        });

        this.setState({
            // [RESETINPUTS_STATES],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addCodePreparation = async () => {
        const { codeName } = this.state;
        let pType = "HiringStepPreparation";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addCodeEvaluation = async () => {
        const { codeName } = this.state;
        let pType = "HiringStepEvaluation";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };




    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/HiringStepPreparation");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                preparationSelectItems: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/HiringStepEvaluation");

        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                evaluationSelectItems: response2.data
            });
        }
    }

    addStep = async () => {

        const obj = {

            stepName: this.state.stepName,
            hasEvaluation: this.state.hasEvaluation,

        }


        await handleRequest("POST", "/HiringStep/add", obj);


        this.showSuccess();

        this.setState({
            stepName: '',
            hasEvaluation: true,
            hideStepModal: true

        })

        this.getHiringSteps();

    }

    getHiringSteps = async () => {
        const response = await handleRequest("GET", "/HiringStep/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id,
                    element.check = false;
                element.preparations = [];
                element.evaluations = [];
            });
           
            this.setState({    
                hiringSteps: [...list],
            });
        }

        if (response.data.length === 0) {
            this.setState({
                hiringSteps: [],
            });
        }
    }

    refreshHiringSteps = async () => {

        const { departmentId, disciplineId, designationId, hiringSteps,initializeHiringSteps } = this.state;
        if (Boolean(departmentId) && Boolean(disciplineId) && Boolean(designationId)) {
            let obj = {
                departmentId: departmentId,
                disciplineId: disciplineId,
                designationId: designationId
            }
            const response = await handleRequest("POST", "/EmployeeHiring/getEmployeeHiring", obj);

            if (Boolean(response.data) && response.data.length > 0) {
                const list = response.data;
                const eh = list[0];
                console.log(eh);
                if (Boolean(hiringSteps) && hiringSteps.length > 0 && Boolean(eh.employeeHiringSteps) && eh.employeeHiringSteps.length > 0) {
                    hiringSteps.forEach(element => {
                        let fo = eh.employeeHiringSteps.find(p => p.hiringStepId === element.id);
                        console.log(fo);
                        if (Boolean(fo)) {
                            element.check = true;
                            element.preparations = [];
                            element.evaluations = [];
                            if (Boolean(fo.preparations) && fo.preparations.length > 0) {
                                fo.preparations.forEach(p => {
                                    element.preparations.push(p.preparationId);
                                });
                            }


                            if (Boolean(fo.evaluations) && fo.evaluations.length > 0) {
                                fo.evaluations.forEach(e => {
                                    element.evaluations.push(e.evaluationId);
                                });
                            }

                        }
                        else {
                            element.check = false;
                            element.preparations = [];
                            element.evaluations = [];
                        }
                    });
                }

                this.setState({
                    hiringSteps: hiringSteps,
                    id: eh.id,
                    oldEmployeeHiring: eh
                });
            }

            if (!Boolean(response.data) || response.data.length === 0) {
                hiringSteps.forEach(element => {
                   
                     element.check = false;
                    element.preparations = [];
                    element.evaluations = [];
                });
                this.setState({
                    hiringSteps: hiringSteps, 
                    id: null,
                    oldEmployeeHiring: null 
                });
            }
        }
    }




    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };
        const layout2 = {
            labelCol: { span: 8 },
            wrapperCol: { span: 8 }
        };


        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>



                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Department").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Department" defaultMessage="Department" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="departmentId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Department")) ? this.state.dynamicInputs.find(p => p.inputKey === "Department").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Department")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Department").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Department" id="Department" value={this.state.departmentId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                departmentId: value
                                                            });
                                                            this.setState({ departmentId: value }, this.refreshHiringSteps)
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.departments.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Discipline" defaultMessage="Discipline" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="disciplineId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Discipline")) ? this.state.dynamicInputs.find(p => p.inputKey === "Discipline").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Discipline")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Discipline").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Discipline" id="Discipline" value={this.state.disciplineId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                disciplineId: value
                                                            });
                                                            this.setState({ disciplineId: value }, this.refreshHiringSteps)
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.disciplines.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Designation").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Designation" defaultMessage="Designation" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="designationId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Designation")) ? this.state.dynamicInputs.find(p => p.inputKey === "Designation").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Designation")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Designation").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Designation" id="Designation" value={this.state.designationId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                designationId: value
                                                            });
                                                            this.setState({ designationId: value }, this.refreshHiringSteps)
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.designations.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.designationName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }

                                        {
                                            <FormAnt.Item
                                                label={""}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >

                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={6}>

                                                    </ColAnt>
                                                    <ColAnt span={2}>
                                                        <ReactButton variant="info" onClick={() => {
                                                            this.setState({
                                                                hideStepModal: false
                                                            })
                                                        }} > + Add New Step</ReactButton>
                                                    </ColAnt>
                                                </RowAnt>


                                            </FormAnt.Item>
                                        }
                                        <Divider></Divider>

                                        {this.state.hiringSteps.map((step, i) => (
                                            <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                                {
                                                    <FormAnt.Item

                                                        name="todo"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                        <RowAnt gutter={[8, 8]}>
                                                            <ColAnt span={5}>
                                                            </ColAnt>
                                                            <ColAnt span={1}>
                                                                <Checkbox onChange={(e) => {
                                                                    let list = this.state.hiringSteps;
                                                                    let obj = list[i];
                                                                    obj.check = e.target.checked;
                                                                    this.setState({
                                                                        hiringSteps: list
                                                                    })
                                                                }} checked={step.check}> </Checkbox>
                                                            </ColAnt>
                                                            <ColAnt span={12}>
                                                                <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '12pt' }} >
                                                                    {step.stepName}
                                                                </Form.Label>
                                                            </ColAnt>
                                                        </RowAnt>

                                                    </FormAnt.Item>}

                                                {
                                                    <FormAnt.Item
                                                        {...layout2}

                                                        label={<FormattedMessage id="Preparations" defaultMessage="Preparations" />}

                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                        <Select
                                                            style={{ width: '100%' }}
                                                            mode="tags"
                                                            placeholder="Select"
                                                            value={step.preparations} onChange={(value) => {
                                                                let list = this.state.hiringSteps;
                                                                let obj = list[i];
                                                                obj.preparations = value;
                                                                this.setState({
                                                                    hiringSteps: list
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodePreparation}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.preparationSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>

                                                    </FormAnt.Item>}

                                                {step.hasEvaluation &&
                                                    <FormAnt.Item
                                                        {...layout2}

                                                        label={<FormattedMessage id="Evaluations" defaultMessage="Evaluations" />}

                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                        <Select
                                                            style={{ width: '100%' }}
                                                            mode="tags"
                                                            placeholder="Select"
                                                            value={step.evaluations} onChange={(value) => {
                                                                let list = this.state.hiringSteps;
                                                                let obj = list[i];
                                                                obj.evaluations = value;
                                                                this.setState({
                                                                    hiringSteps: list
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodeEvaluation}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.evaluationSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>

                                                    </FormAnt.Item>}

                                            </div>))}



                                        <div>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: "10px" }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>




                                    </FormAnt>
                                </div>


                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={!this.state.hideStepModal}
                            onHide={() => {
                                this.setState({
                                    hideStepModal: true,
                                    stepName: '',
                                    hasEvaluation: true

                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Add Group</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                    <Col sm="12">
                                        <Input style={{ width: "100%", marginBottom: "1rem" }} placeholder="Group Name" value={this.state.stepName} onChange={(e) => this.setState({ stepName: e.target.value })} />

                                    </Col>
                                    <Col sm="6">

                                        <div>
                                            <Checkbox onChange={(e) => {
                                                this.setState({
                                                    hasEvaluation: e.target.checked
                                                })
                                            }} checked={this.state.hasEvaluation}> <FormattedMessage id="hasEvaluation" defaultMessage="Has Evaluation " />  </Checkbox>
                                        </div>


                                    </Col>


                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        hideStepModal: true,
                                        stepName: '',
                                        hasEvaluation: true

                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addStep} >Add</ReactButton>
                            </Modal.Footer>
                        </Modal>



                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeHiring)
