import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Switch, Button as AntButton, Cascader } from 'antd';
import { Avatar } from 'antd';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization,getDynamicMenu,getUserSettings } from '../../redux/config/imu.js';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const itemTypeSelectItems = [{ id: 1, value: "Material" }, { id: 2, value: "Equipment" }];
var t = "import { DownloadOutlined } from '@ant-design/icons';";
const pageName = "ProductDefine";

export class ProductDefine extends Component {

    constructor() {
        super();
        console.log(t);
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            itemGroupSelectItems: [],
            itemList: [],
            itemId: 0,
            itemType: null,
            groupId: null,
            itemName: '',
            sketchPath: '',
            itemGroupIds: [],
            itemGroups: [],
            nodes: [],
            allNodes: [],

            itemListSelectItems: [],
            selectedItemId: null,
            selectedItem: null,
            productCode: '',
            unitId: null,
            codeName: '',
            unitCodes: [],


            columns: [
                {
                    key: "key",
                    title: "Action",
                    render: (value, row, index) => {

                        return this.actionTemplate(row);

                    }
                },
                {
                    key: "key",
                    title: "Product",
                    dataIndex: "itemName"
                },
                {
                    key: "key",
                    title: "Product Code",
                    dataIndex: "productCode"
                },
                {
                    key: "key",
                    title: "Shape",
                    render: (value, row, index) => {

                        if (Boolean(row.sketchPath))
                            return <Avatar shape="square" size={100} src={row.sketchPath} />;
                        else return "";

                    }
                },
                {
                    key: "unit",
                    title: "Unit",
                    render: (value, row, index) => {

                        if (Boolean(row.unit))
                            return row.unit.name;
                        else return "";

                    }
                }

            ],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
      

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        });

        this.fillNodes();
        this.fillCodes();
        this.getDimensionList();
        this.setComboboxes();
        this.restartTable();
        this.fillMaterials();


    }

    getUserSettings = async (formId) => {

        let userSettings =  await getUserSettings(formId);
         this.setState(
          {
              howToUseSetting: userSettings.howToUseSetting,
              whatIsSetting: userSettings.whatIsSetting 
          }
         )
      }

    fillNodes = async () => {
        const response3 = await handleRequest("GET", "/ItemGroup/getNodes");

        if (response3.data.length === 0) {
            this.setState({
                nodes: [],
                allNodes: [],

            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data,
                allNodes: response3.data,

            });
        }

    }


    refreshNodes = () => {

        var list = this.state.allNodes;

        if (Boolean(list) && list.length > 0 && Boolean(this.state.itemType)) {
            list = list.filter(p => p.itemType === this.state.itemType)
        }

        this.setState({
            nodes: list
        })

    }


    onChangeItemGroup = (value, data) => {
        this.setState({
            itemGroupIds: value,
            itemGroups: data,
        }, this.fillMaterials);
    };

    save = async () => {

        var _productDimensionList = [];

        if (Boolean(this.state.selectedItem) && Boolean(this.state.selectedItem.itemDimensions) && this.state.selectedItem.itemDimensions.length > 0) {

            this.state.selectedItem.itemDimensions.forEach(element => {
                let o = { id: element.id, dimensionId: element.dimensionId, maxValue: element.maxValue, minValue: element.minValue }
                _productDimensionList.push(o);
            });


            const obj = {
                id: this.state.itemId,
                itemId: this.state.selectedItemId,
                productCode: this.state.productCode,
                unitId: this.state.unitId,
                itemProductDimensions: _productDimensionList,
                itemMaterialEquipment: this.state.selectedItem
            }

            console.log(obj);
            if (obj.id === 0)
                await handleRequest("POST", "/ItemProduct/add", obj);
            else
                await handleRequest("POST", "/ItemProduct/update", obj);

            this.showSuccess();
            this.resetInputs();
            this.restartTable();
        }
        else this.showError("Please Select Item");

    }

    edit = (row) => {

        var _selectedItem = this.state.itemListSelectItems.find(p => p.id === row.itemId);

        if (Boolean(row.itemProductDimensions) && row.itemProductDimensions.length > 0) {
            row.itemProductDimensions.forEach(element => {

                let index = _selectedItem.itemDimensions.findIndex(p => p.dimensionId === element.dimensionId);
                if (index >= 0) {
                    _selectedItem.itemDimensions[index].id = element.id;
                    _selectedItem.itemDimensions[index].maxValue = element.maxValue;
                    _selectedItem.itemDimensions[index].minValue = element.minValue;
                }

            });
        }

        let ids = [];
        if (Boolean(_selectedItem.itemGroup)) {

            if (Boolean(_selectedItem.itemGroup.hierarchyCode) && _selectedItem.itemGroup.hierarchyCode.length > 0) {
                if (_selectedItem.itemGroup.hierarchyCode.includes(","))
                    ids = _selectedItem.itemGroup.hierarchyCode.split(",");
                else ids.push(_selectedItem.itemGroup.hierarchyCode);
            }
            ids.push(_selectedItem.groupId.toString());
        }


        this.setState({
            itemId: row.id,
            itemType: _selectedItem.itemType,
            itemGroupIds: ids,
            selectedItemId: row.itemId,
            unitId: row.unitId,
            productCode: row.productCode,
            selectedItem: _selectedItem,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.itemId
        }

        await handleRequest("POST", "/ItemProduct/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            itemId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/ItemProduct/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id;

                if (Boolean(element.itemMaterialEquipment)) {
                    element.itemName = element.itemMaterialEquipment.itemName;
                    element.sketchPath = element.itemMaterialEquipment.sketchPath;
                }

                if (Boolean(element.itemProductDimensions) && element.itemProductDimensions.length > 0) {
                    element.itemProductDimensions.forEach(dimension => {

                        let snc = element.itemMaterialEquipment.itemDimensions.find(p => p.dimensionId === dimension.dimensionId);
                        if (Boolean(snc)) {
                            element[dimension.dimensionId + ""] = dimension.minValue + '-' + dimension.maxValue + ' ' + (Boolean(snc.unit) ? snc.unit.name : '');
                        }
                    });
                }
            });
            console.log(list);
            this.setState({
                itemList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                itemList: [],
            });
        }
    }


  



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            selectedItemId: null,
            selectedItem: null,
            productCode: '',
            unitId: null,
            itemGroupIds: [],
            itemType: null,
            itemId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.getDimensionList();
    }


    stop = () => {

        this.setState({
            selectedItemId: null,
            selectedItem: null,
            productCode: '',
            unitId: null,
            itemGroupIds: [],
            itemType: null,
            itemId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.getDimensionList();
    }



    resetInputs = () => {
        this.setState({
            selectedItemId: null,
            selectedItem: null,
            productCode: '',
            unitId: null,
            itemGroupIds: [],
            itemType: null,
            itemId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.getDimensionList();
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    fillCodes = async () => {

        const response = await handleRequest("GET", "/ItemGroup/type/" + this.state.itemType);
        console.log("geldi fillCodes")
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                itemGroupSelectItems: response.data
            });
        }
    }

    addDimension = async () => {
        if (this.state.description.length > 0 && Boolean(this.state.unitTypeId)) {
            const obj = {

                description: this.state.description,
                abbreviation: this.state.abbreviation,
                unitTypeId: this.state.unitTypeId,
            }

            console.log(obj);

            await handleRequest("POST", "/Dimension/add", obj);

            // await handleRequest("POST", "/Dimension/update", obj);

            this.showSuccess();

            this.setState({
                description: '',
                abbreviation: '',
                unitTypeId: null,
            })

            this.getDimensionList();
        }
        else this.showError("Please type dimension inputs for adding new dimension.")

    }


    getDimensionList = async () => {

        const response = await handleRequest("GET", "/Dimension/getAllByCompany");

        if (response.data !== null && response.data !== undefined) {
            const list = response.data;
            const columns = this.state.columns;
            list.forEach(element => {
                element.select = false;
                if (Boolean(columns) && !Boolean(columns.find(p => p.key === element.id))) {
                    let col = {
                        key: element.id,
                        title: element.description,
                        dataIndex: element.id + ''
                    }
                    columns.push(col);
                }
            });

            this.setState({
                dimensionList: list,
                columns: columns
            });
        }
    }

    setComboboxes = async () => {

        const response = await handleRequest("GET", "/UnitType/getallByCompany");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                unitTypeSelectItems: response.data
            });
        }
    }

    fillMaterials = async () => {
        var response;

        var groupId = null;
        if (Boolean(this.state.itemGroupIds) && this.state.itemGroupIds.length > 0) {
            groupId = this.state.itemGroupIds[this.state.itemGroupIds.length - 1];
        }
        response = await handleRequest("GET", "/ItemMaterialEquipment/getAllByCompany");

        if (response.data.length !== 0) {
            var list = response.data;
            if (Boolean(groupId))
                list = list.filter(p => p.groupId == groupId);

            list.forEach(element => {

                element.minValue = 0;
                element.maxValue = 0;

            });
            this.setState({
                itemListSelectItems: list
            });
        }
    }


    addUnitItem = async () => {
        const { codeName } = this.state;
        let pType = "methodOfPayment";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };



    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/unit");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                unitCodes: response.data
            });
        }
    }


    render() {



        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>

                        <MenuProvider id="menu_id">


                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        {...layout}
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Item Type" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Select disabled={Boolean(this.state.itemId) && this.state.itemId > 0} placeholder="" value={this.state.itemType} onChange={(value) => {
                                                            this.setState({
                                                                itemType: value
                                                            },
                                                                this.refreshNodes
                                                            )
                                                        }}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {itemTypeSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.value}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Parent" />}
                                                name="OrganizationalLevelRoleGroup"
                                                id="OrganizationalLevelRoleGroup"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Cascader disabled={Boolean(this.state.itemId) && this.state.itemId > 0}
                                                            style={{ width: "100%" }}
                                                            value={this.state.itemGroupIds}
                                                            options={this.state.nodes}
                                                            onChange={this.onChangeItemGroup}
                                                            changeOnSelect
                                                        />
                                                    </Col>

                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {/* {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Parent" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Unit"
                                                    value={this.state.groupId} onChange={(value) => {
                                                        this.setState({
                                                            groupId: value
                                                        }, this.fillMaterials)
                                                    }}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.itemGroupSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.groupName}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="1">

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                } */}

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Product" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Select disabled={Boolean(this.state.itemId) && this.state.itemId > 0} placeholder="" value={this.state.selectedItemId} onChange={(value) => {
                                                            let obj = this.state.itemListSelectItems.find(p => p.id === value);
                                                            if (Boolean(obj.itemDimensions))
                                                                obj.itemDimensions.forEach(element => {
                                                                    element.id = 0;
                                                                });
                                                            this.setState({
                                                                selectedItemId: value,
                                                                selectedItem: obj
                                                            })
                                                        }}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.itemListSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.itemName}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Product Code" />}
                                                name="OrganizationalLevelRoleGroup"
                                                id="OrganizationalLevelRoleGroup"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Input id="todo" value={this.state.productCode} onChange={(e) => this.setState({ productCode: e.target.value })} />
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Unit" />}
                                                name="OrganizationalLevelRoleGroup"
                                                id="OrganizationalLevelRoleGroup"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Select
                                                            style={{ width: '180px' }}
                                                            placeholder="Unit"
                                                            value={this.state.unitId}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    unitId: value
                                                                })
                                                            }}

                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addUnitItem}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.unitCodes.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }




                                        {Boolean(this.state.selectedItem) && Boolean(this.state.selectedItem.itemDimensions) && this.state.selectedItem.itemDimensions.length > 0 && this.state.selectedItem.itemDimensions.filter(p => p.active === true).map((d, i) => (
                                            <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                                                {
                                                    <FormAnt.Item

                                                        name="OrganizationalLevelDesignation"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                            <Col sm="4">

                                                            </Col>

                                                            <Col sm="2">
                                                                <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                                    {d.dimension.description + ", " + d.dimension.abbreviation}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="1">
                                                                <Form.Label >
                                                                    Min:
                                                        </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                                <InputNumber min={0} value={d.minValue}
                                                                    onChange={(value) => {
                                                                        var s = this.state.selectedItem;
                                                                        var obj = s.itemDimensions[i];
                                                                        obj.minValue = value;
                                                                        this.setState({
                                                                            selectedItem: s
                                                                        })
                                                                    }} />
                                                            </Col>

                                                            <Col sm="1">
                                                                <Form.Label >
                                                                    Max:
                                                        </Form.Label>
                                                            </Col>
                                                            <Col sm="2">
                                                                <InputNumber min={0} value={d.maxValue}
                                                                    onChange={(value) => {
                                                                        var s = this.state.selectedItem;
                                                                        var obj = s.itemDimensions[i];
                                                                        obj.maxValue = value;
                                                                        this.setState({
                                                                            selectedItem: s
                                                                        })
                                                                    }} />
                                                            </Col>

                                                        </Form.Group>

                                                    </FormAnt.Item>}

                                            </div>))}



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="5">
                                                        {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                        <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                            variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%', marginTop: '10px' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%', marginTop: '10px' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                        <Modal
                                            show={this.state.modalMessage}
                                            onHide={this.showOrHideModalMessage}
                                            backdrop="static"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>

                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <Row >
                                                        <Col sm="12">
                                                            {this.state.messageTip == "Success" &&
                                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                            {this.state.messageTip == "Warning" &&
                                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                            {this.state.messageTip == "Error" &&
                                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                        </Col>
                                                    </Row>
                                                </Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.message}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                            </Modal.Footer>
                                        </Modal>
                                    </FormAnt>
                                </div>

                                {this.state.itemList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} columns={this.state.columns} dataSource={this.state.itemList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                </Table>}

                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>


                        }
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDefine) 