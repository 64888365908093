import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Switch, Button as AntButton, Collapse } from 'antd';
import { Avatar } from 'antd';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { useContext, useState, useEffect, useRef } from 'react';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
const pageName = "ItemDescription";


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const { Panel } = Collapse;


const itemTypeSelectItems = [{ id: 1, value: "Material" }, { id: 2, value: "Equipment" }];

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.value, lineage: node.lineage });
        if (node.children) {
            generateList(node.children);
        }
    }
};


const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = FormAnt.useForm();
    return (
        <FormAnt form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </FormAnt>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
           Boolean(inputRef) && Boolean(inputRef.current) && inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async (e) => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        console.log([dataIndex]);
        console.log({ dataIndex });
        console.log(record)

        childNode = editing && Boolean(record.itemDimensions.find(p => p.dimensionId == dataIndex)) ? (<FormAnt.Item
            name={dataIndex}

        >

            <Select style={{ width: "100%" }} placeholder="" ref={inputRef} onChange={save}
            >
                <Option key={null} value={null}>Select</Option>

                {record.itemDimensions.find(p => p.dimensionId == dataIndex).dimension.unitType.unitTypes.map(i => (
                    <Option key={i.id} value={i.id}>{i.name}</Option>
                ))}
            </Select>
            {/* <InputNumber style={{ width: '100%' }} min={0} max={record.weight} ref={inputRef} onPressEnter={save} onBlur={save} /> */}

        </FormAnt.Item>) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {Boolean(record.itemDimensions.find(p => p.dimensionId == dataIndex)) ? (Boolean(record.itemDimensions.find(p => p.dimensionId == dataIndex).unit) ? record.itemDimensions.find(p => p.dimensionId == dataIndex).unit.name : 'click') : children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};

export class ItemDescription extends Component {

    constructor() {
        super();

        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            modalPhotoShow: false,
            imageUrl: null,

            propertyGroupList: [],
            groupInputs: {},
            columns: {},
            dataList: {},
            itemGroupSelectItems: [],
            selectedItemId: null,
            hideTemplateModal: true,
            templateItemList: [],
            ItemSelectList: [],
            unitSelecItems: [],

            itemList: [],
            itemId: null,
            itemType: null,
            groupId: null,
            itemName: '',
            sketchPath: '',

            filePath: '',
            fileList: [],
            dimensionList: [],
            oldDimensions: [],
            description: '',
            abbreviation: '',
            unitTypeId: null,
            rootColumns: [
                // {
                //     key: "key",
                //     title: "Action",
                //     render: (value, row, index) => {

                //         return this.actionTemplate(row);

                //     }
                // },
                {
                    key: "key",
                    title: "Item Description",
                    dataIndex: "itemName"
                },
                {
                    key: "key",
                    title: "Shape",
                    align: "center",
                    render: (value, row, index) => {

                        if (Boolean(row.sketchPath))

                            return <div
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() =>
                                    this.setState({
                                        imageUrl: row.sketchPath,
                                        modalPhotoShow: true,
                                    })
                                }
                            >
                                <Avatar shape="square" size={100} src={row.sketchPath} />

                            </div>;

                        else return "";

                    }
                }

            ],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {


        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        });

        this.getDimensionList();
        this.setComboboxes();
        this.restartTable();
    }


    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }


    getPropertyGroupList = async () => {



    }

    save = async () => {

        var _itemDimensions = Boolean(this.state.oldDimensions) ? this.state.oldDimensions : [];

        if (Boolean(_itemDimensions) && _itemDimensions.length > 0) {
            _itemDimensions.forEach(element => {
                element.active = false;
            });
        }

        if (Boolean(this.state.dimensionList)) {
            var filteredList = this.state.dimensionList.filter(p => p.select === true);
            filteredList.forEach(element => {
                let index = -1;
                if (_itemDimensions.length > 0)
                    index = _itemDimensions.findIndex(p => p.dimensionId === element.id);
                if (index >= 0) {
                    _itemDimensions[index].active = true;
                }
                else {
                    let obj = { dimensionId: element.id, active: true };
                    _itemDimensions.push(obj);
                }

            });
        }
        const obj = {
            id: this.state.itemId,
            itemType: this.state.itemType,
            groupId: this.state.groupId,
            itemName: this.state.itemName,
            sketchPath: this.state.filePath,
            itemDimensions: _itemDimensions
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/ItemMaterialEquipment/add", obj);
        else
            await handleRequest("POST", "/ItemMaterialEquipment/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    addToLocalItems = async () => {

        var templateItemList = this.state.templateItemList;
        var list = [];

        if (Boolean(templateItemList) && templateItemList.length > 0) {
            templateItemList.filter(p => p.select === true).forEach(element => {
                list.push(element);
            });
        }


        await handleRequest("POST", "/ItemMaterialEquipment/addToLocalItems", list);


        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        var dimensionList = this.state.dimensionList;

        if (Boolean(row.itemDimensions) && Boolean(dimensionList) && dimensionList.length > 0 && row.itemDimensions.length > 0) {
            row.itemDimensions.forEach(element => {

                let index = dimensionList.findIndex(p => p.id === element.dimensionId);
                if (index >= 0) {
                    dimensionList[index].select = true;
                }

            });
        }

        let fileList = [{
            uid: '1',
            name: row.itemName + '.png',
            status: 'done',
            url: row.sketchPath,
        }]

        this.setState({
            itemId: row.id,
            itemType: row.itemType,
            groupId: row.groupId,
            itemName: row.itemName,
            sketchPath: row.sketchPath,
            filePath: row.sketchPath,
            fileList: fileList,
            oldDimensions: row.itemDimensions,
            dimensionList: dimensionList,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.itemId
        }

        await handleRequest("POST", "/ItemMaterialEquipment/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            itemId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/ItemMaterialEquipment/getAllByCompany");

        if (response.data.length !== 0) {
            const list2 = response.data;
            await this.setState({
                itemList: list2,
            }

            );

            list2.forEach((element, index) => {
                element.key = element.id


                if (Boolean(element.itemDimensions) && element.itemDimensions.length > 0) {
                    element.itemDimensions.forEach((dimension, k) => {
                        element[dimension.dimensionId + ""] = dimension.unitId;// Boolean(this.state.itemList[index].itemDimensions[k].unit)? this.state.itemList[index].itemDimensions[k].unit.name:''
                        //  <Select style={{ width: "100%" }} placeholder="" value={this.state.itemList[index].itemDimensions[k].unitId} onChange={(value) => {
                        //     var l = this.state.itemList;
                        //     l[index].itemDimensions[k].unitId = value;
                        //     this.setState({ itemList: l })
                        // }}
                        // >
                        //     <Option key={null} value={null}>Select</Option>
                        //     {this.state.unitSelecItems.map(i => (
                        //         <Option key={i.id} value={i.id}>{i.name}</Option>
                        //     ))}
                        // </Select>;
                    });
                }
            })

            console.log(list2);


            console.log("geldi grouplist", list2)
            var dataList = {};
            var list = [];
            const response2 = await handleRequest("GET", "/PropertyGroup/getAllByCompany");

            if (response2.data !== null && response2.data !== undefined) {
                list = response2.data;
                const columns = {}, groupInputs = {};
                list.forEach(element => {
                    columns[element.id + ""] = [];
                    dataList[element.id + ""] = [];
                    groupInputs[element.id + ""] = { propertyGroupId: element.id, propertyDescription: '', explanation: '', fileList: [], sketchPath: '', unitTypeId: null };
                    columns[element.id + ""].push({
                        key: "key",
                        title: "Action",
                        render: (value, row, index) => {
                            return this.actionTemplate(row);
                        }
                    });
                    columns[element.id + ""].push({
                        key: "key",
                        title: "Material Name",
                        dataIndex: "itemName"
                    })
                    if (Boolean(element.properties) && element.properties.length > 0)
                        element.properties.forEach(property => {
                            property.select = false;
                            columns[element.id + ""].push({
                                key: "key",
                                title: property.propertyDescription,
                                dataIndex: property.id + ""
                            })
                        });

                });

                this.setState({
                    propertyGroupList: response2.data,
                    columns: columns,
                    groupInputs: groupInputs
                });
            }
            // var response;
            // if (Boolean(this.state.selectedItemId))
            //     response = await handleRequest("GET", "/ItemMaterialEquipment/getById/" + this.state.selectedItemId);
            // else
            //     response = await handleRequest("GET", "/ItemMaterialEquipment/getAllMaterials");

            if (Boolean(list2) && list2.length !== 0) {

                list2.forEach(element => {
                    element.key = element.id;

                    if (Boolean(element.itemProperties) && element.itemProperties.length > 0) {
                        list.forEach(g => {
                            let obj = { id: element.id, itemName: element.itemName };
                            element.itemProperties.forEach(p => {
                                if (Boolean(p.property) && p.property.propertyGroupId === g.id) {
                                    obj[p.propertyId + ""] = <CheckOutlined style={{ fontSize: '24px', color: '#08c' }} ></CheckOutlined>;
                                }


                            });
                            dataList[g.id + ""].push(obj);
                        });

                    }
                });
                this.setState({

                    dataList: dataList
                });
            }


        }

        if (response.data.length === 0) {
            this.setState({
                itemList: [],
            });
        }



    }






    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            itemType: null,
            groupId: null,
            itemName: '',
            sketchPath: '',
            fileList: [],
            filePath: '',
            oldDimensions: [],
            itemId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.getDimensionList();
    }


    stop = () => {

        this.setState({
            itemType: null,
            groupId: null,
            itemName: '',
            sketchPath: '',
            fileList: [],
            filePath: '',
            oldDimensions: [],
            itemId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.getDimensionList();
    }



    resetInputs = () => {
        this.setState({
            itemType: null,
            groupId: null,
            itemName: '',
            sketchPath: '',
            fileList: [],
            filePath: '',
            oldDimensions: [],
            itemId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.getDimensionList();
    }


    cancel = () => {

        this.resetInputs();
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }




    fillTemplateGroups = async () => {

        const response = await handleRequest("GET", "/ItemGroup/template/type/" + this.state.itemType);
        console.log("geldi fillCodes")
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                itemGroupSelectItems: response.data
            });
        }
    }

    fillTemplateItems = async () => {

        const response = await handleRequest("GET", "/ItemMaterialEquipment/getAllTemplate");

        if (response.data !== null && response.data !== undefined) {
            var list = response.data;
            if (Boolean(this.state.groupId))
                list = list.filter(p => p.groupId === this.state.groupId);

            list.forEach(element => {
                element.select = false;
            });

            this.setState({
                templateItemList: list,
                ItemSelectList: list
            });
        }
    }

    findTemplateItem = async () => {

        const response = await handleRequest("GET", "/ItemMaterialEquipment/getAllTemplate");

        if (response.data !== null && response.data !== undefined) {
            var list = response.data;
            if (Boolean(this.state.itemId))
                list = list.filter(p => p.id === this.state.itemId);

            this.setState({
                templateItemList: list
            });
        }
    }

    addDimension = async () => {
        if (this.state.description.length > 0 && Boolean(this.state.unitTypeId)) {
            const obj = {

                description: this.state.description,
                abbreviation: this.state.abbreviation,
                unitTypeId: this.state.unitTypeId,
            }

            console.log(obj);

            await handleRequest("POST", "/Dimension/add", obj);

            // await handleRequest("POST", "/Dimension/update", obj);

            this.showSuccess();

            this.setState({
                description: '',
                abbreviation: '',
                unitTypeId: null,
            })

            this.getDimensionList();
        }
        else this.showError("Please type dimension inputs for adding new dimension.")

    }


    getDimensionList = async () => {

        const response = await handleRequest("GET", "/Dimension/getAllByCompany");

        if (response.data !== null && response.data !== undefined) {
            const list = response.data;
            const rootColumns = this.state.rootColumns;
            list.forEach(element => {
                element.select = false;
                if (Boolean(rootColumns) && !Boolean(rootColumns.find(p => p.key === element.id))) {
                    let col = {
                        key: element.id,
                        title: element.description,
                        align: "center",
                        dataIndex: element.id + '',
                        editable: true,
                    }
                    rootColumns.push(col);
                }
            });

            console.log(rootColumns);
            this.setState({
                dimensionList: list,
                rootColumns: rootColumns
            });
        }
    }

    setComboboxes = async () => {

        const response = await handleRequest("GET", "/UnitType/getallByCompany");

        if (response.data !== null && response.data !== undefined && response.data.length > 0) {
            console.log(response.data);
            this.setState({
                unitTypeSelectItems: response.data,
                unitSelecItems: response.data[0].unitTypes

            });
        }



    }

    expand = (record) => {



        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        // return <Table columns={columns} dataSource={data} pagination={false} />;

        return <Collapse defaultActiveKey={0} expandIconPosition={this.state.expandIconPosition}>
            {this.state.propertyGroupList.filter(p => p.active === true).map((group, i) => (

                <Panel style={{ fontWeight: 'bold' }} header={group.propertyGroupName} key={i}  >
                    {
                        <FormAnt.Item
                            label={""}
                            name="OrganizationalLevelDesignation"
                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Input id="todo" placeholder="Description" value={this.state.groupInputs[group.id + ""].propertyDescription} onChange={(e) => {
                                        var obj = this.state.groupInputs;
                                        obj[group.id + ""].propertyDescription = e.target.value;
                                        this.setState({ groupInputs: obj })
                                    }} />


                                </Col>
                                <Col sm="2">
                                    <Input id="todo" placeholder="Explanation" value={this.state.groupInputs[group.id + ""].explanation} onChange={(e) => {
                                        var obj = this.state.groupInputs;
                                        obj[group.id + ""].explanation = e.target.value;
                                        this.setState({ groupInputs: obj })
                                    }} />

                                </Col>

                                <Col sm="2">
                                    <Select placeholder="" value={this.state.groupInputs[group.id + ""].unitTypeId} onChange={(value) => {
                                        var obj = this.state.groupInputs;
                                        obj[group.id + ""].unitTypeId = value;
                                        this.setState({ groupInputs: obj })
                                    }}
                                    >
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.unitTypeSelectItems.map(i => (
                                            <Option key={i.id} value={i.id}>{i.codeParentUnit.name}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col sm="2">
                                    <Upload {...props}
                                        showUploadList={true}
                                        fileList={this.state.groupInputs[group.id + ""].fileList}
                                        onChange={({ fileList }) => {
                                            if (fileList.length == 1) {
                                                var filePath = "";
                                                if (Boolean(fileList[0].response)) {
                                                    filePath = fileList[0].response.url;
                                                    this.showSuccess("file uploaded successfully");
                                                    // fileName: info.file.name, 
                                                }

                                                var obj = this.state.groupInputs;
                                                obj[group.id + ""].sketchPath = filePath;
                                                obj[group.id + ""].fileList = fileList;
                                                this.setState({ groupInputs: obj })

                                            }
                                        }}
                                        beforeUpload={(fileList) => {
                                            if (this.state.groupInputs[group.id + ""].fileList.length > 0) {
                                                this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                return false;
                                            }
                                            return true;
                                        }}
                                        onRemove={({ fileList }) => {
                                            var obj = this.state.groupInputs;
                                            obj[group.id + ""].sketchPath = "";
                                            obj[group.id + ""].fileList = [];
                                            this.setState({ groupInputs: obj })
                                        }
                                        }>
                                        <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                            <UploadOutlined /> Add Sketch
                                        </AntButton>
                                    </Upload>
                                </Col>

                                <Col sm="2">
                                    <AntButton type="primary" onClick={() => this.addProperty(group)} icon={<PlusOutlined />}></AntButton>

                                </Col>

                            </Form.Group>
                        </FormAnt.Item>
                    }
                    {Boolean(group.properties) && group.properties.length > 0 && group.properties.filter(p => p.active === true).map((property, j) => (
                        <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                            {
                                <FormAnt.Item

                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                        <Col sm="2">

                                        </Col>
                                        <Col sm="2">
                                            <div>
                                                <Checkbox onChange={(e) => {
                                                    var propertyGroupList = this.state.propertyGroupList;
                                                    var obj = propertyGroupList[i];
                                                    var p = obj.properties[j];
                                                    p.select = e.target.checked;
                                                    this.setState({
                                                        propertyGroupList: propertyGroupList
                                                    })
                                                }} checked={property.select}> </Checkbox>
                                            </div>
                                        </Col>

                                        <Col sm="2">
                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                {property.propertyDescription}
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label style={{ font: 'bold', fontSize: '11pt' }} >
                                                {property.explanation}
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label style={{ font: 'bold', fontSize: '11pt' }} >
                                                <Avatar shape="square" size={100} src={property.sketchPath} />
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label style={{ font: 'bold', fontSize: '11pt' }} >
                                                {Boolean(property.unitType) && Boolean(property.unitType.codeParentUnit) ? property.unitType.codeParentUnit.name : ''}
                                            </Form.Label>
                                        </Col>

                                    </Form.Group>

                                </FormAnt.Item>}

                        </div>))}
                    {
                        <FormAnt.Item
                            label={""}
                            name="OrganizationalLevelDesignation"
                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                <Col sm="4">
                                </Col>

                                <Col sm="2">
                                    <AntButton type="primary" onClick={() => this.saveItepProperty(record, group)} >Save All</AntButton>

                                </Col>

                            </Form.Group>
                        </FormAnt.Item>
                    }




                    {Boolean(this.state.dataList[group.id + ""]) && this.state.dataList[group.id + ""].length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} columns={this.state.columns[group.id + ""]} dataSource={this.state.dataList[group.id + ""].filter(p => p.id === record.id)} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                    </Table>}


                </Panel>))}



        </Collapse>


    }

    saveAllDimensionUnits = async () => {


        var list = this.state.itemList;
        console.log(list);
        var dl = [];

        list.forEach(element => {

            if (Boolean(element.itemDimensions) && element.itemDimensions.length > 0) {

                element.itemDimensions.forEach(dim => {
                    dim.unitId = element[dim.dimensionId + ''];
                    dl.push(dim);
                });

            }

        });
        console.log(dl);
        await handleRequest("POST", "/ItemDimension/saveAll", dl);


        //  this.showSuccess();
        this.resetInputs();
        this.restartTable();


    }


    handleSave = (row) => {
        const itemList = [...this.state.itemList];
        console.log(itemList);
        console.log(row);
        const index = itemList.findIndex((item) => row.id === item.id);
        const item = itemList[index];
        itemList.splice(index, 1, { ...item, ...row });
        this.setState({
            itemList: itemList,
        }, this.saveAllDimensionUnits);
    };

    render() {

        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };

        const columns = this.state.rootColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });


        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>

                        <MenuProvider id="menu_id">

                            <Modal
                                show={!this.state.hideTemplateModal}
                                onHide={() => {
                                    this.setState({
                                        hideTemplateModal: true,
                                        itemType: '',
                                        groupId: null,
                                        itemId: false,


                                    })
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Select Items</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                <FormattedMessage id="todo" defaultMessage="Item Type" />
                                            </Form.Label>
                                        </Col>
                                        <Col sm="6">
                                            <Select placeholder="" style={{ width: "100%" }} value={this.state.itemType} onChange={(value) => {
                                                this.setState({
                                                    itemType: value,
                                                    groupId: null,
                                                    itemId: null

                                                },
                                                    this.fillTemplateGroups
                                                )
                                            }}
                                            >
                                                <Option key={null} value={null}>Select</Option>
                                                {itemTypeSelectItems.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                ))}
                                            </Select>
                                        </Col>

                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>

                                        <Col sm="2">
                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                <FormattedMessage id="todo" defaultMessage="Parent Product" />
                                            </Form.Label>
                                        </Col>
                                        <Col sm="6">
                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder="Unit"
                                                value={this.state.groupId} onChange={(value) => {
                                                    this.setState({
                                                        groupId: value,
                                                        itemId: null
                                                    },
                                                        this.fillTemplateItems
                                                    )
                                                }}

                                            >
                                                <Option key={null} value={null}>Select</Option>
                                                {this.state.itemGroupSelectItems.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.groupName}</Option>
                                                ))}
                                            </Select>

                                        </Col>

                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                <FormattedMessage id="todo" defaultMessage=" Product" />
                                            </Form.Label>
                                        </Col>
                                        <Col sm="6">
                                            <Select placeholder="" style={{ width: "100%" }} value={this.state.itemId} onChange={(value) => {
                                                this.setState({
                                                    itemId: value
                                                },
                                                    this.findTemplateItem
                                                )
                                            }}
                                            >
                                                <Option key={null} value={null}>Select</Option>
                                                {this.state.ItemSelectList.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.itemName}</Option>
                                                ))}
                                            </Select>
                                        </Col>

                                    </Form.Group>

                                    {this.state.templateItemList.filter(p => p.active === true).map((d, i) => (
                                        <div style={{ backgroundColor: "white", marginTop: "1px" }}>


                                            <Form.Group style={{ marginBottom: '2rem' }}>


                                                <Col sm="2">
                                                    <div>
                                                        <Checkbox onChange={(e) => {
                                                            var templateItemList = this.state.templateItemList;
                                                            var obj = templateItemList[i];
                                                            obj.select = e.target.checked;
                                                            this.setState({
                                                                templateItemList: templateItemList
                                                            })
                                                        }} checked={d.select}> </Checkbox>
                                                    </div>
                                                </Col>

                                                <Col sm="4">

                                                    <Avatar shape="square" size={100} src={d.sketchPath} />

                                                </Col>


                                            </Form.Group>


                                        </div>))}

                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={() => {
                                        this.setState({
                                            hideTemplateModal: true,
                                            itemType: '',
                                            groupId: null,
                                            itemId: false
                                        })
                                    }}>Cancel</ReactButton>
                                    <ReactButton variant="info" onClick={this.addToLocalItems} >Add</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.modalPhotoShow}
                                onHide={() => {
                                    this.setState({
                                        modalPhotoShow: false
                                    })
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {Boolean(this.state.imageUrl) && this.state.imageUrl !== "" ? (
                                        <img
                                            src={this.state.imageUrl}
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                            <p>Photo has not been uploaded.</p>
                                        )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="info" onClick={() => {
                                        this.setState({
                                            modalPhotoShow: false
                                        })
                                    }} >
                                        Close
                            </ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>

                                    </Row>
                                </div>

                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        {...layout}
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >


                                        {
                                            <FormAnt.Item
                                                label={""}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>

                                                    <Col sm="6">
                                                        <ReactButton variant="info" onClick={() => {
                                                            this.setState({
                                                                hideTemplateModal: false
                                                            })
                                                        }} > + Fetch From Template</ReactButton>
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }
                                        <Divider></Divider>



                                        <Modal
                                            show={this.state.modalMessage}
                                            onHide={this.showOrHideModalMessage}
                                            backdrop="static"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>

                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <Row >
                                                        <Col sm="12">
                                                            {this.state.messageTip == "Success" &&
                                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                            {this.state.messageTip == "Warning" &&
                                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                            {this.state.messageTip == "Error" &&
                                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                        </Col>
                                                    </Row>
                                                </Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.message}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                            </Modal.Footer>
                                        </Modal>



                                        {this.state.itemList.length > 0 && <Table
                                            className="components-table-demo-nested"
                                            columns={columns}
                                            expandable={{ expandedRowRender: record => this.expand(record) }}
                                            dataSource={this.state.itemList}
                                            style={{ marginTop: '3rem' }} size={`small`}
                                            components={components}
                                            rowClassName={() => 'editable-row'}
                                            bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }}
                                        >

                                        </Table>}
                                        {/* <Divider></Divider>
                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>

                                            <Col sm="6">
                                                <ReactButton variant="info" onClick={this.saveAllDimensionUnits} > Save All</ReactButton>
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                } */}
                                    </FormAnt>
                                </div>

                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>


                        }
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemDescription) 