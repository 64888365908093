/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Cascader } from 'antd';
import { Switch } from 'antd';
import { Collapse, Table } from 'antd';
import 'antd/dist/antd.css';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Space, TreeSelect, InputNumber } from 'antd';
import { handleRequest } from '../../redux/config/env';
import { SettingOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { TimePicker } from 'antd';
import moment from 'moment';
import HorizontalTimeline from "react-horizontal-timeline";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Form, Modal as ModalReact, Button as ButtonReact } from 'react-bootstrap';
import { Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
// import GSTC from "react-gantt-schedule-timeline-calendar";
// import CalendarScroll from "gantt-schedule-timeline-calendar/dist/CalendarScroll.plugin.js";
// import ItemMovement from "gantt-schedule-timeline-calendar/dist/ItemMovement.plugin.js";
// import Selection from "gantt-schedule-timeline-calendar/dist/Selection.plugin.js";
// import WeekendHighlight from "gantt-schedule-timeline-calendar/dist/WeekendHighlight.plugin.js";
// import "gantt-schedule-timeline-calendar/dist/style.css";
import { Button as PrimeButton } from 'primereact/button';


const { confirm } = Modal;
const { Option } = Select;

const { RangePicker } = TimePicker;
const { Panel } = Collapse;
const { SHOW_PARENT } = TreeSelect;

function callback(key) {
  console.log(key);
}

const text = `Parent : `;

const pallete = [
  '#E74C3C',
  '#DA3C78',
  '#7E349D',
  '#0077C0',
  '#07ABA0',
  '#0EAC51',
  '#F1892D',
  '#E3724B',
  '#AE7C5B',
  '#6C7A89',
  '#758586',
  '#707070',
  '#E3F4BF',
  '#BEF7C8',
  '#86E6C8',
  '#36CFC9',
  '#209BDD',
  '#1581E6',
  '#0860BF'
];
var sonuc = null;

const _DurationTypes = [
  { label: "Year", value: 1 },
  { label: "Month", value: 2 },
  { label: "Day", value: 3 },
  { label: "Hour", value: 4 },
  { label: "Minute", value: 5 },
  { label: "Second", value: 6 }
];

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.key, lineage: node.data.lineage });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const dataListMaterial = [];
const generateListMaterial = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataListMaterial.push({ key: node.key, lineage: node.data.lineage });
    if (node.children) {
      generateListMaterial(node.children); 
    }
  }
};


const depDisciplines = [];
const generateDisciplines = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    depDisciplines.push({ key: node.value, disciplines: node.children });

  }
};

const depList = [];
const generateDepList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    depList.push({ key: node.key, lineage: node.data.lineage });
    if (node.children) {
      generateDepList(node.children);
    }
  }
};

const genExtra = (event) => (

  <Switch
    checkedChildren={<CheckOutlined />}
    unCheckedChildren={<CloseOutlined />}

  />

);

const genExtra2 = () => (
  <SettingOutlined
    onClick={event => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    }}
  />
);

//

export class Resources extends Component {

  constructor(props) {
    super(props);


    this.state = {
      modalShow: false,
      expandIconPosition: 'left',
      loading: false,
      visible: false,
      nodes: [],
      values: [],
      activityId: 0,
      activityCode: '',
      activityName: '',

      gelenParam:{},
      equipmentId: 0,
      equipmentTypes: [],
      equipmentTypeValues: [],
      equipment: 0,
      equipments: [],
      equipmentCode: 0,
      equipmentCodes: [],
      equipmentQuantity: 0,
      equipmentData: [],
      equipmentTime: [],
      equipmentTimeString: [],
      operatorRequirement: true,
      searchText: '',
      searchedColumn: '',
      eti: 0,
      etp: 0,
      mti: 0,
      mtp: 0,

      numberOfPerson: 0,
      manpowerId: 0,
      manpowerTeam: false,
      manpowerDesignations: [],
      manpowerDesignationValues: [],
      manpowerTime: [],
      manpowerTimeString: [],
      manpowerResponsibility: '',
      manpowerData: [],
      individual: true,

      materialTypes: [],
      materialTypeValues: [],
      materialId: 0,
      material: 0,
      materials: [],
      materialCode: 0,
      materialCodes: [],
      materialQuantity: 0,
      materialData: [],
      materialUsage: true,

      documentTreeData: [],
      documentId: 0,
      documentTypes: [],
      documents: [],
      selectedDocuments: [],
      checkedNodes: [],
      document: [],
      documentTableData: [],

      selectedItems: [],
      newItem: '',
      parentId: 0,
      departmentCascader: [],
      selectedDepartments: [],

      alternativeOfId: 0,
      alternatives: [],

      disciplines: [],
      disciplineId: 0,

      designationId: 0,
      designations: [],

      newTeam: '',
      teams: [],
      teamId: '',

      modifications: [],
      newModification: '',
      modificationId: '',

      modalTitle: "",

      documentEdit: false,
      documentLogId: -1,
      documentTypeId: -1,
      modalMessage: false,


      durationTypesEquipment: _DurationTypes.slice(),
      durationTypesEquipmentStart: _DurationTypes.slice(),
      durationTypesManpower: _DurationTypes.slice(),
      durationTypesManpowerStart: _DurationTypes.slice(),
      durationTypesMaterial: _DurationTypes.slice(),
      durationTypesMaterialStart: _DurationTypes.slice(),

      durationObjEquipment: {},
      durationNumberEquipment: '',
      durationTypeEquipment: '',
      durationEquipment: '',

      durationObjEquipmentStart: {},
      durationNumberEquipmentStart: '',
      durationTypeEquipmentStart: '',
      durationEquipmentStart: '',

      durationObjManpower: {},
      durationNumberManpower: '',
      durationTypeManpower: '',
      durationManpower: '',

      durationObjManpowerStart: {},
      durationNumberManpowerStart: '',
      durationTypeManpowerStart: '',
      durationManpowerStart: '',

      durationObjMaterial: {},
      durationNumberMaterial: '',
      durationTypeMaterial: '',
      durationMaterial: '',

      durationObjMaterialStart: {},
      durationNumberMaterialStart: '',
      durationTypeMaterialStart: '',
      durationMaterialStart: '',

      materialsAll:[],

      activityList: [],
      config: {
        plugins: [ItemMovement({
          moveable: true,
          resizable: true,
          collisionDetection: true
        }),
        Selection({
          selected(data, type) {
            console.log(data, type);
          }
        }),
        CalendarScroll(),
        WeekendHighlight()],
        height: 500,
        list: {
          rows: {},
          columns: {
            data: {
              id: {
                id: "id",
                data: "id",
                width: 50,
                header: {
                  content: "ID"
                }
              },
              label: {
                id: "label",
                data: "label",
                width: 200,
                header: {
                  content: "Activity Name"
                },
                expander: true,
              },

            }
          }
        },
        chart: {
          time: {
            period: 'day',
            additionalSpaces: {
              hour: { before: 24, after: 24, period: 'hour' },
              day: { before: 1, after: 20, period: 'day' },
              week: { before: 2, after: 10, period: 'week' },
              month: { before: 6, after: 6, period: 'month' },
              year: { before: 1, after: 2, period: 'year' }
            }
          }
        }
      }
    }

    this.onselectChangeVal2 = this.onselectChangeVal2.bind(this);

    // if(data!== null && data!==undefined)
    // this.setState({
    //   activityId: data.id,
    //   activityCode:data.code,
    //   activityName:data.activityName,
    // });
  }



  onDepartmentCascaderChange = (value) => {

    console.log(value);
    var _options = [];

    if (value !== null && value !== undefined && value.length > 0) {
      _options = depDisciplines.filter(p => p.key == value[value.length - 1])[0].disciplines;
    }
    this.setState({
      selectedDepartments: value,
      disciplines: _options

    })

  }

  onChange = (value, data) => {

    this.setState({
      values: value,
      selectedItems: data
    })
    console.log(value);
    console.log(data);
  }


  onEquipmentTypeChange = (value) => {

    this.setState({
      equipmentTypeValues: value

    })

  }

  onMaterialTypeChange = (value) => {

 
    this.setState({
      materialTypeValues: value
     
    })

  }

  onDesignationChange = (value) => {

    console.log(value);
    this.setState({
      manpowerDesignationValues: value

    })

  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };



  handleOk = () => {
    this.setState({ loading: true });
    const length = this.state.values.length;

    if (length > 0)
      this.setState({ parentId: this.state.values[length - 1] });

    setTimeout(() => {

      this.setState({ loading: false, visible: false });
      this.saveItem();

    }, 1000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.resetInputs();
  };



  componentWillMount() {

    const { data } = this.props.location;

    console.log(data);
    if (data !== null && data !== undefined)
      this.setState({
        activityId: data.id,
        activityCode: data.code,
        activityName: data.activityName,
        gelenParam:data,
      });



  }




  componentDidMount = async () => {

    const responseTree = await handleRequest("GET", "/Department/getTreeModel");


    if (responseTree.data.length !== 0) {

      generateDepList(responseTree.data.treeTable);
      this.setState({

        departmentCascader: responseTree.data.cascader
      });

      console.log(this.state.departmentCascader);

    }

    this.getNodes();
    this.getEquipmentTable();
    this.getEquipmentDatas();

    this.getManpowerTable();
    this.getManpowerDatas();

    this.getDocumentTable();
    this.getDocumentDatas();

    this.getMaterialTable(); 

    this.fillCodes();
    this.getDesignations();
    this.getDisciplines();
    this.fillChart();
    // this.getMaterialTable();
    // this.getMaterialDatas(); 


  }

  fillChart = async () => {

    const response = await handleRequest("GET", "/WFActivity/getAll");
    const config = this.state.config;
    if (response.data.length !== 0) {
      const result = response.data;
      let _items = {};
      let _rows = {};
      result.forEach((element, i) => {
        let obj = {
          id: element.id.toString(),
          rowId: element.id.toString(),
          label: element.activityName,
          moveable: true,
          resizeable: true,
          time: {
            start: new Date().getTime(),
            end: this.getEndTime(element.duration)
          },
          style: { background: pallete[Math.floor(Math.random() * pallete.length)] }

        };
        let attr = element.id.toString();
        _items[attr + ""] = obj;

        element.label = element.activityName;
        element.id = element.id.toString();

        if (!Boolean(element.parentId) || element.parentId == '0')
          delete element["parentId"];

        _rows[attr + ""] = element;


      });
      config.chart.items = _items;
      config.list.rows = _rows;

      console.log(result);

      this.setState({

        config: config,
        activityList: result

      });

    }
  }

  getEndTime = (duration) => {

    console.log(moment());
    console.log(duration);
    var startDate = moment();

    if (Boolean(duration)) {
      if (Boolean(duration.year)) {

        startDate = startDate.add(duration.year, 'years');
      }

      if (Boolean(duration.month)) {

        startDate = startDate.add(duration.month, 'months');
      }
      if (Boolean(duration.day)) {

        startDate = startDate.add(duration.day, 'days');
      }
      if (Boolean(duration.hour)) {

        startDate = startDate.add(duration.hour, 'hours');
      }
      if (Boolean(duration.minute)) {

        startDate = startDate.add(duration.minute, 'minutes');
      }

      if (Boolean(duration.second)) {

        startDate = startDate.add(duration.second, 'seconds');
      }
    }

    console.log(startDate);
    console.log(startDate.toDate().getTime());
    return startDate.toDate().getTime();


  }


  getEquipmentTable = async () => {

    var response = [];

    var item = { activityId: this.state.activityId };

    response = await handleRequest("POST", "/WFResources/getEquipments", item);

    console.log(response);

    if (response.length === 0 || response.data.length === 0) {
      this.setState({
        equipmentData: []

      });
    } else
      if (response.data.length !== 0) {

        const options = response.data.map(function (row) {
          return { label: row.equipmentLog.equipmentDescription.toString(), value: row.id.toString() }
        });
        //row.equipmentCode.toString() + " - " +
        console.log(options);

        this.setState({
          equipmentData: response.data,
          alternatives: options,
        });
      }

  }

  getDesignations = async () => {

    var response = [];
    response = await handleRequest("GET", "/Designation/getAll");

    console.log(response);

    if (response.data.length !== 0) {

      const options = response.data.map(function (row) {
        return { label: row.designationName.toString(), value: row.id.toString() }
      });


      this.setState({
        designations: options,
      });
    }

  }

  getDisciplines = async () => {

    var response = [];
    response = await handleRequest("GET", "/Discipline/getAll");

    console.log(response);

    if (response.data.length !== 0) {

      const options = response.data.map(function (row) {
        return { label: row.disciplineName.toString(), value: row.id.toString() }
      });


      this.setState({
        disciplines: options,
      });
    }

  }

  getEquipmentDatas = async () => {

    var response = [], response2 = [];

    var item = { activityId: this.state.activityId };

    response = await handleRequest("GET", "/EquipmentType/getNodes", item);


    if (response.data.length !== 0) {
      generateList(response.data);
      this.setState({
        equipmentTypes: response.data
      });
    }

    response2 = await handleRequest("GET", "/EquipmentLog/getAll");


    console.log(response2);


    if (response2.data.length !== 0) {

      const options = response2.data.map(function (row) {
        return { label: row.equipmentDescription.toString(), value: row.id.toString() }
      });
      //row.equipmentCode.toString() + " - " +
      console.log(options);

      this.setState({
        equipments: options
      });
    }

    const response3 = await handleRequest("GET", "/MaterialType/getNodes");

    if (response3.data.length == 0) {
      this.setState({
        nodes: [],
      });
    } else if (response3.data.length != 0) {
      generateListMaterial(response3.data);
      this.setState({
        materialTypes: response3.data,
      });
    }


    const response4 = await handleRequest("GET", "/MaterialIdentification/getAll");

    if (Boolean(response4) && Boolean(response4.data) && response4.data.length > 0) {

      const options2 = response4.data.map(function (row) {
        return { label: row.materialName.toString(), value: row.id.toString() }
      });
      this.setState({
        materials: options2,
        materialsAll:options2 
      });
    }


  }

  getManpowerDatas = async () => {

    var response = [];

    var item = { activityId: this.state.activityId };

    response = await handleRequest("GET", "/Department/getNodes", item);


    if (response.data.length !== 0) {
      generateDisciplines(response.data);
      this.setState({
        manpowerDesignations: response.data
      });
    }



  }

  getDocumentDatas = async () => {
    var response = [];
    var item = { page: "" };

    response = await handleRequest("POST", "/DocumentLog/getResourceDocuments", item)
    if (response.data.length !== 0) {
      this.setState({
        documentTreeData: response.data
      });
    }

    // var response2 = [];

    // response2 = await handleRequest("GET", "/DocumentLog/getAll", item);

    // if (response2.data.length !== 0) {

    //   this.setState({
    //     documents: response2.data
    //   });
    // }

  }


  addEquipment = async () => {

    const length = this.state.selectedDepartments.length;
    var pId;

    if (length > 0)
      pId = this.state.selectedDepartments[length - 1];

    var sizeOfET = this.state.equipmentTypeValues.length;
    var sizeOfTime = this.state.equipmentTime.length;
    if (sizeOfET > 0 && length > 0) {

      var item = {
        activityId: this.state.activityId,
        id: this.state.equipmentId,
        equipmentTypeId: this.state.equipmentTypeValues[sizeOfET - 1],
        equipmentLogId: this.state.equipment,
        quantity: this.state.equipmentQuantity,
        operatorRequirement: this.state.operatorRequirement,
        // startTime: this.state.equipmentTime[0]._d,
        // endTime: this.state.equipmentTime[1]._d,
        //  startTimeStr: this.state.equipmentTimeString[0],
        //   endTimeStr: this.state.equipmentTimeString[1],
        departmentId: pId,
        alternativeOfId: this.state.alternativeOfId,
        duration: this.state.durationObjEquipment,
        durationStart: this.state.durationObjEquipmentStart
      };


      if (item.id === 0)
        await handleRequest("POST", "/WFResources/addEquipment", item);
      else await handleRequest("POST", "/WFResources/updateEquipment", item);
      this.resetEquipmentInputs();
      this.getEquipmentTable();
      this.showSuccess("Equipment added successfully to resources");
    }
    else
      this.showWarning("Please check inputs");
  }

  addMaterial = async () => {

    const length = this.state.selectedDepartments.length;
    var pId;

    if (length > 0)
      pId = this.state.selectedDepartments[length - 1];

    var sizeOfET = this.state.materialTypeValues.length;
    if (sizeOfET > 0 && length > 0) {

      var item = {
        activityId: this.state.activityId,
        id: this.state.materialId,
        materialTypeId: this.state.materialTypeValues[sizeOfET - 1],
        materialId: this.state.material,
        quantity: this.state.materialQuantity,
       
        departmentId: pId,
        asis: this.state.materialUsage,
        modificationId: this.state.materialUsage ? null : this.state.modificationId, 
        duration: this.state.durationObjMaterial,
        durationStart: this.state.durationObjMaterialStart
      };


      if (item.id === 0)
        await handleRequest("POST", "/WFResources/addMaterial", item);
      else await handleRequest("POST", "/WFResources/updateMaterial", item);
      this.resetMaterialInputs(); 
      this.getMaterialTable();
      this.showSuccess("Equipment added successfully to resources");
    }
    else
      this.showWarning("Please check inputs");
  }

  addManpower = async () => {

    
    const length = this.state.selectedDepartments.length;
    var pId;

    if (length > 0)
      pId = this.state.selectedDepartments[length - 1];

    if (this.state.manpowerResponsibility !== null && this.state.manpowerResponsibility.trim() !== '' && length > 0) {
      const newObj = {
        id: this.state.manpowerId,
        activityId: this.state.activityId,
        numberOfPerson: this.state.numberOfPerson,
        responsibility: this.state.manpowerResponsibility,
        individual: this.state.individual,
      
        departmentId: pId,
        teamId: this.state.teamId,
        disciplineId: this.state.disciplineId,
        designationId: this.state.designationId,
        duration: this.state.durationObjManpower,
        durationStart: this.state.durationObjManpowerStart

      }

      /*  switch (sizeDesignations) {
          case 1:
            newObj.departmentId = this.state.manpowerDesignationValues[0];
            break;
          case 2:
            newObj.departmentId = this.state.manpowerDesignationValues[0];
            newObj.disciplineId = this.state.manpowerDesignationValues[1];
            break;
          case 3:
            newObj.departmentId = this.state.manpowerDesignationValues[0];
            newObj.disciplineId = this.state.manpowerDesignationValues[1];
            newObj.designationId = this.state.manpowerDesignationValues[2];
  
            break;
  
          default:
            break;
        }*/


      if (newObj.id === 0)
        await handleRequest("POST", "/WFResources/addManpower", newObj);
      else await handleRequest("POST", "/WFResources/updateManpower", newObj);

      this.showSuccess("New item added successfully");
      this.getManpowerTable();
      this.resetManpowerInputs();
    }
    else
      this.showWarning("Please check inputs");
  }

  resetManpowerInputs = () => {

    this.setState({
      numberOfPerson: 0,
      manpowerResponsibility: '',
      individual: true,
      equipmentTime: [],
      equipmentTimeString: [],
      manpowerDesignationValues: [],
      teamId: '',
      manpowerId:0, 

    });

    this.removeDurationManpower();
    this.removeDurationManpowerStart(); 

  }

  addDocument = async () => {

    var size = this.state.selectedDocuments.length;

    const length = this.state.selectedDepartments.length;
    var pId;

    if (length > 0)
      pId = this.state.selectedDepartments[length - 1];

    if (size > 0) {
      var list = [];

      this.state.selectedDocuments.forEach((element, index) => {

        var item = {
          activityId: this.state.activityId,
          // documentLogId: element,
          documentLogId: this.state.checkedNodes[index].node.props.data.documentLogId,
          documentTypeId: this.state.checkedNodes[index].node.props.data.documentTypeId,
          departmentId: pId,
          checklistItemId: 0,
        };
        list.push(item);
      });

      var response = await handleRequest("POST", "/WFResources/addDocuments", list);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.getDocumentTable();
          this.showSuccess("Documents added successfully to resources");
        } else if (response.data === "WARNING") {
          this.showWarning("No document to add to resources found");
        }
        else {
          this.showError("An error was occured please try again later !");
        }
      } else {
        this.showError();
      }
    }
    else {
      this.showWarning("Please select document");
    }
    this.resetDocumentInputs();

  }

  saveDocument = async () => {
    var size = this.state.selectedDocuments.length;
    const length = this.state.selectedDepartments.length;
    var pId;
    if (length > 0)
      pId = this.state.selectedDepartments[length - 1];
    if (size > 0) {
      var list = [];
      this.state.selectedDocuments.forEach((element, index) => {

        const { selectedRowVal2 } = this.state;
        var dt = [];
        for (let index = 0; index < selectedRowVal2.length; index++) {
          dt.push(selectedRowVal2[index].id);
        }

        var documentLogId = 0;
        var documentTypeId = 0;
        var documentResourcesId = 0;
        if (this.state.documentEdit == true) {
          documentLogId = this.state.documentLogId;
          documentTypeId = this.state.documentTypeId;
          documentResourcesId = this.state.documentResourcesId;

          this.setState({
            documentEdit: false,
            documentLogId: -1,
            documentTypeId: -1,
            documentResourcesId: -1,
          });

        } else {
          documentLogId = this.state.checkedNodes[index].node.props.data.documentLogId;
          documentTypeId = this.state.checkedNodes[index].node.props.data.documentTypeId;
        }

        var item = {
          id: documentResourcesId,
          activityId: this.state.activityId,
          // documentLogId: element,
          documentLogId: documentLogId,
          documentTypeId: documentTypeId,
          departmentId: pId,
          itpchecklistitemLists: dt + "",
        };
        list.push(item);
      });

      var response = await handleRequest("POST", "/WFResources/addDocuments", list);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.getDocumentTable();
          this.showSuccess("Successfully");
        } else if (response.data === "WARNING") {
          this.showWarning("No document to add-update to resources found");
        }
        else {
          this.showError("An error was occured please try again later !");
        }
      } else {
        this.showError();
      }
    }
    else {
      this.showWarning("Please select document");
    }
    this.resetDocumentInputs();
  }


  deleteEquipment = async (key) => {
    var pId = key;
    if (pId > 0) {

      var item = {
        id: pId
      };

      await handleRequest("POST", "/WFResources/deleteEquipment", item);
      this.getEquipmentTable();
      this.showSuccess("Equipment deleted successfully from resources");
    }
    else
      this.showWarning("Please select equipment.");


  }

  deleteMaterial = async (key) => {
    var pId = key;
    if (pId > 0) {

      var item = {
        id: pId
      };

      await handleRequest("POST", "/WFResources/deleteMaterial", item);
      this.getMaterialTable();
      this.showSuccess("Material deleted successfully from resources");
    }
    else
      this.showWarning("Please select material."); 


  }

  deleteManpower = async (key) => {
    var pId = key;
    if (pId > 0) {

      var item = {
        id: pId
      };

      await handleRequest("POST", "/WFResources/deleteManpower", item);
      this.getManpowerTable();
      this.showSuccess("Manpower deleted successfully from resources");
    }
    else
      this.showWarning("Please select manpower.");
  }

  deleteDocument = async (row) => {
    if (row.id > 0) {
      var item = {
        id: row.id,
        documentLogId: row.documentLogId,
        activityId: row.activityId
      };

      var response = await handleRequest("POST", "/WFResources/deleteDocument", item);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.getDocumentTable();
          this.showSuccess("Document deleted successfully from resources");
        }
        else {
          this.showError("An error was occured please try again later !");
        }
      } else {
        this.showError();
      }
    }
    else {
      this.showWarning("Please select document.");
    }
  }

  editDocument = async (rowData) => {
    console.log("XXX", rowData);
    var pId = rowData.id;
    if (pId > 0) {

      if (Boolean(rowData.activityId) && rowData.activityId > 0 && Boolean(rowData.documentLogId) && rowData.documentLogId > 0) {
        const newObj = {
          documentLogId: rowData.documentLogId,
          activityId: rowData.activityId
        }

        const responseTree = await handleRequest("POST", "/CheckListLog/getItpChecklistItems", newObj);
        if (responseTree.data.table.length !== 0) {
          var array = [];
          array.push(rowData.documentLogId)

          this.setState({
            itemsChecklistItems: responseTree.data.table,
            modalShow: true,
            selectedRowVal2: responseTree.data.selected,
            //  modalTitle:label[label.length-1],
            selectedDocuments: array,
            //  checkedNodes: extra.allCheckedNodes,
            documentEdit: true,
            documentLogId: rowData.documentLogId,
            documentTypeId: rowData.documentTypeId,
            documentResourcesId: rowData.id,
          });
        } else {
          this.setState({
            documentEdit: false,
            documentLogId: -1,
            documentTypeId: -1,
            documentResourcesId: -1,
            modalTitle: "",
            itemsChecklistItems: [],
            modalShow: false,
            selectedRowVal2: [],
            // selectedDocuments: value,
            // checkedNodes: extra.allCheckedNodes,
          });
        }
      } else {
        this.showError("Activity not found");
      }
    }
    else {
      this.showWarning("Please select document.");
    }
  }

  resetEquipmentInputs = (value) => {

    this.setState({
      equipmentTypeValues: [],
      equipment: 0,
      equipmentQuantity: 0,
      operatorRequirement: true,
      equipmentTime: [],
      equipmentTimeString: [],
      equipmentId:0


    });

    this.removeDurationEquipment();
    this.removeDurationEquipmentStart();

  }

  resetMaterialInputs = (value) => {

    this.setState({
      materialTypeValues: [],
      material: 0,
      materialQuantity: 0,
      materialUsage: true,
      modificationId:'',
      materialId:0,


    });

    this.removeDurationMaterial();
    this.removeDurationMaterialStart();

  }

  resetDocumentInputs = () => {
    this.setState({
      selectedDocuments: [],
      checkedNodes: [],
      modalShow: false,
      documentId:0
    });
  }

  getManpowerTable = async () => {

    var response = [];

    var item = { activityId: this.state.activityId };

    response = await handleRequest("POST", "/WFResources/getManpowers", item);


    if (response.length === 0 || response.data.length === 0) {
      this.setState({
        manpowerData: []

      });
    } else
      if (response.data.length !== 0) {

        this.setState({
          manpowerData: response.data
        });
      }

  }

  getDocumentTable = async () => {
    if (Boolean(this.state.activityId) && this.state.activityId > 0) {
      var response = [];
      var item = { activityId: this.state.activityId };
      response = await handleRequest("POST", "/WFResources/getDocuments", item);
      if (response.length === 0 || response.data.length === 0) {
        this.setState({
          documentTableData: []

        });
      } else if (response.data.length !== 0) {
        console.log("XXX", response.data);
        this.setState({
          documentTableData: response.data
        });
      }
    } else {
      this.showError("Activity not found");
    }
  }

  getMaterialTable = async () => {

    var response = [];

    var item = { activityId: this.state.activityId };

    response = await handleRequest("POST", "/WFResources/getMaterials", item);

    console.log(response);

    if (response.length === 0 || response.data.length === 0) {
      this.setState({
        materialData: []

      });
    } else
      if (response.data.length !== 0) {


        this.setState({
          materialData: response.data,
        
        });
      }

  }

  getNodes = async () => {

    var response = [];


    response = await handleRequest("GET", "/WFLocation/getNodesLocations");


    if (response.length === 0 || response.data.length === 0) {
      this.setState({
        nodes: []

      });
    } else
      if (response.data.length !== 0) {

        this.setState({
          nodes: response.data
        });
      }

  }

  saveItem = async () => {



    if (this.state.newItem.trim() !== "") {



      const newObj = {

        locationName: this.state.newItem,
        parentId: this.state.parentId

      }


      await handleRequest("POST", "/WFLocation/add", newObj);


      this.showSuccess("New item added successfully");
      this.getNodes();
      this.resetInputs();
    } else {
      this.showWarning("Please type input.");
    }
  };

  onChangeInput = ({ target: { value } }) => {
    console.log(value);
    this.setState({ newItem: value });
  };

  onChangeResponsibilityInput = ({ target: { value } }) => {
    console.log(value);
    this.setState({ manpowerResponsibility: value });
  };

  afterCloseModal = () => {
    console.log("object")
    this.resetInputs();
  }

  resetInputs = () => {
    this.setState({ parentId: 0, newItem: '' });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onDocumentTreeChange = async (value, label, extra) => {
    if (Boolean(value) && value.length > 0) {
      if (Boolean(this.state.activityId) && this.state.activityId > 0) {
        const newObj = {
          documentLogId: value[value.length - 1],
          activityId: this.state.activityId
        }

        const responseTree = await handleRequest("POST", "/CheckListLog/getItpChecklistItems", newObj);
        if (responseTree.data.table.length !== 0) {
          var array = [];
          array.push(value[value.length - 1])

          this.setState({
            itemsChecklistItems: responseTree.data.table,
            modalShow: true,
            selectedRowVal2: responseTree.data.selected,
            modalTitle: label[label.length - 1],
            selectedDocuments: array,
            checkedNodes: extra.allCheckedNodes,
          });
        } else {
          this.setState({
            modalTitle: "",
            itemsChecklistItems: [],
            modalShow: false,
            selectedRowVal2: [],
            selectedDocuments: value,
            checkedNodes: extra.allCheckedNodes,
          });
        }
      } else {
        this.showError("Activity not found");
      }
    } else {
      this.setState({
        modalTitle: "",
        itemsChecklistItems: [],
        modalShow: false,
        selectedRowVal2: [],
        selectedDocuments: value,
        checkedNodes: extra.allCheckedNodes,
      });
    }
  };


  onChangeEquipmentTime = (time, timeString) => {
    console.log(time, timeString);
    this.setState({
      equipmentTime: time,
      equipmentTimeString: timeString
    });
  }

  onChangeManpowerTime = (time, timeString) => {
    console.log(time, timeString);
    this.setState({
      manpowerTime: time,
      manpowerTimeString: timeString
    });
  }

  onChangeEquipmentQuantity = (value) => {
    console.log('changed', value);
    this.setState({ equipmentQuantity: value });
  }

  onChangeMaterialQuantity = (value) => {
    console.log('changed', value);
    this.setState({ materialQuantity: value }); 
  }

  onChangeNumberOfPerson = (value) => {

    this.setState({ numberOfPerson: value })

  }

  onChangeOperatorReq = (value) => {
    console.log('changed', value);
    this.setState({ operatorRequirement: value });

  }

  onNewTeamChange = event => {
    this.setState({
      newTeam: event.target.value,
    });
  };

  addTeam = async () => {

    const { newTeam } = this.state;

    if (newTeam.length > 0) {

      const newItem = {
        name: newTeam,
        type: "team"
      }

      await handleRequest("POST", "/Code/add", newItem);


      this.setState({

        newTeam: '',
      });

      this.fillCodes();
    }

  };

  onNewModificationChange = event => {
    this.setState({
      newModification: event.target.value,
    });
  };

  addModification = async () => {

    const { newModification } = this.state;

    if (newModification.length > 0) {

      const newItem = {
        name: newModification,
        type: "modification"
      }

      await handleRequest("POST", "/Code/add", newItem);


      this.setState({

        newTeam: '',
      });

      this.fillCodes();
    }

  };

  fillCodes = async () => {

    const response = await handleRequest("GET", "/Code/type/team");

    console.log(response.data);
    if (response.data !== null && response.data !== undefined) {
      this.setState({
        teams: response.data
      });
    }

    const response2 = await handleRequest("GET", "/Code/type/modification");

    console.log(response2.data);
    if (response2.data !== null && response2.data !== undefined) {
      this.setState({
        modifications: response2.data
      });
    }

  }

  deleteEquipmentModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this Equipment?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected equipment will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteEquipment(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });

  }
  deleteMaterialModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this Material?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected material will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteMaterial(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  editEquipment = (row) => {

    console.log(row);
    var _depValues = depList.filter(p => p.key == row.departmentId)[0].lineage;
    var _equipmentTypeValues = dataList.filter(p => p.key === row.equipmentTypeId)[0].lineage;
    var _equipmentTime = [];
    var _equipmentTimeString = [];
    //_equipmentTypeValues.push( row.equipmentTypeId) ;

    //  _equipmentTime.push(moment(row.startTimeStr, 'HH:mm'));
    //  _equipmentTime.push(moment(row.endTimeStr, 'HH:mm'));
    // _equipmentTimeString.push(row.startTimeStr);
    //  _equipmentTimeString.push(row.endTimeStr);

    this.setDurationEquipment(row.duration);
    this.setDurationEquipmentStart(row.durationStart);

    console.log(_equipmentTime);

    this.setState({
      equipmentId: row.id,
      activityId: row.activityId,
      equipmentTypeValues: _equipmentTypeValues,
      equipment: row.equipmentLogId,
      equipmentQuantity: row.quantity,
      operatorRequirement: row.operatorRequirement,
      alternativeOfId: row.alternativeOfId,
      equipmentTime: _equipmentTime,
      equipmentTimeString: _equipmentTimeString,
      selectedDepartments: _depValues

    })

  }

  editMaterial = (row) => {

    console.log(row);
    var _depValues = [];
    if(Boolean(row.departmentId) && row.departmentId>0) 
    _depValues=depList.filter(p => p.key == row.departmentId)[0].lineage; 

    var _materialTypeValues = dataListMaterial.filter(p => p.key === row.materialTypeId)[0].lineage;
   

    this.setDurationMaterial(row.duration);
    this.setDurationMaterialStart(row.durationStart);


    this.setState({
      materialId: row.id,
      activityId: row.activityId,
      materialTypeValues: _materialTypeValues,
      material: row.materialId,
      materialQuantity: row.quantity,
      materialUsage: row.asis,
      modificationId: Boolean(row.modificationId) ? row.modificationId : '',
      selectedDepartments: _depValues 

    })

  }

  deleteManpowerModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this Manpower?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected manpower will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteManpower(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });

  }

  editManpower = (row) => {

    console.log(row);
    console.log(depList);
    var _depValues = depList.filter(p => p.key == row.departmentId)[0].lineage;
    console.log(_depValues);
    var _manpowerTime = [];
    var _manpowerTimeString = [];
    //_equipmentTypeValues.push( row.equipmentTypeId) ;
    // _manpowerTime.push(moment(row.startTimeStr, 'HH:mm'));
    // _manpowerTime.push(moment(row.endTimeStr, 'HH:mm'));
    // _manpowerTimeString.push(row.startTimeStr);
    // _manpowerTimeString.push(row.endTimeStr);

    console.log(_manpowerTime);
    this.setDurationManpower(row.duration);
    this.setDurationManpowerStart(row.durationStart);

    
    this.setState({
      manpowerId: row.id,
      activityId: row.activityId,
      individual: row.individual,
      disciplineId: row.disciplineId.toString(),
      designationId: row.designationId.toString(),
      departmentId: row.departmentId,
      manpowerResponsibility: row.responsibility,
      manpowerTime: _manpowerTime,
      manpowerTimeString: _manpowerTimeString,
      teamId: row.teamId,
      selectedDepartments: _depValues

    })

  }

  deleteDocumentModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this Document?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected document will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        that.deleteDocument(row);
        setTimeout(() => {
          document.body.style.overflow = "auto";
        }, 500);
      },
      onCancel() {
        setTimeout(() => {
          document.body.style.overflow = "auto";
        }, 500);
      },
    });

  }

  showOrHideModal = async () => {
    if (this.state.modalShow) {
      this.setState({
        selectedDocuments: [],
        checkedNodes: [],
        modalShow: false,
        selectedRowVal2: [],
      });
    } else {
      this.setState({
        selectedDocuments: [],
        checkedNodes: [],
        modalShow: true,
        selectedRowVal2: [],
      });
    }
  };

  onselectChangeVal2(event) {
    this.setState({ selectedRowVal2: event.value });
    console.log("selectedRowVal2", event.value);
    console.log("selectedRowVal2", event);
  }

  showReport = async (rowData) => {
    try {

      if (rowData.filePath != null && rowData.filePath != undefined && rowData.filePath != "") {
        const fileRealUrl = rowData.filePath;

        if (fileRealUrl.endsWith('.pdf')) {
          this.setState({
            pdfFile: fileRealUrl,
            modalPdfViewer: true,
          });
        } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
          || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {

          this.setState({
            imageUrl: fileRealUrl,
            modalPhotoShow: true,
          })
        } else {
          window.location.href = fileRealUrl;
        }
      } else {
        this.showError("File cant found.");
      }
    } catch (error) {
      this.showError("File cant found.");
    }
  };

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
  };

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
  };

  onState(state) {


    console.log(state);

    let subs = [];
    // state.update("config.chart.items.1", item1 => {
    //     item1.label = "Gantt schedule timeline calendar";
    //     item1.time.end = item1.time.end + 2 * 24 * 60 * 60 * 1000;
    //     return item1;
    // });
    subs.push(
      state.subscribe("config.chart.items", items => {
        console.log(state);
        console.log("items changed", items);
        sonuc = items;
        //    if(items) 
        //    state.update("config.chart.items", items);  
      })
    );
    subs.push(
      state.subscribe("config.list.rows", rows => {
        // console.log("rows changed", rows);
      })
    );
    subs.push(
      state.subscribe('config.chart.time.period', period => {
        //  console.log("period changed", period);


      }));

    subs.push(
      state.subscribe('config.chart.time.zoom', zoom => {
        //  console.log("zoom changed", zoom);

      }));


    subs.push(
      state.subscribe('config.plugin.ItemMovement', itemMovement => {
        //  console.log("itemMovement changed", itemMovement); 
        if (!itemMovement || !itemMovement.item) return;
        state.update(`config.chart.items.${itemMovement.item.id}.isResizing`, itemMovement.item.resizing);
      }))

    console.log(subs);
    // return () => {
    //     subs.forEach(unsub => unsub());
    // };


  }

  calculateDurationEquipment = () => {

    var myObj = this.state.durationObjEquipment;
    var types = this.state.durationTypesEquipment;
    const index = this.state.durationTypesEquipment.findIndex(p => p.value === this.state.durationTypeEquipment);
    console.log(index);
    if (index >= 0 && this.state.durationNumberEquipment > 0) {

      var durationDesc = this.state.durationEquipment + this.state.durationNumberEquipment + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberEquipment;

      types.splice(0, index + 1);

      this.setState({
        durationObjEquipment: myObj,
        durationEquipment: durationDesc,
        durationTypesEquipment: types,
        durationTypeEquipment: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationEquipment = () => {
    if (this.state.durationEquipment.trim() !== "") {
      var pObj = this.state.durationObjEquipment;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjEquipment: pObj,
        durationEquipment: durationDesc,
        durationTypesEquipment: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationEquipment = () => {

    this.setState({
      durationObjEquipment: {},
      durationEquipment: "",
      durationNumberEquipment: "",
      durationTypesEquipment: _DurationTypes.slice(),
      durationTypeEquipment: ''
    });
  }

 

  calculateDurationEquipmentStart = () => {

    var myObj = this.state.durationObjEquipmentStart;
    var types = this.state.durationTypesEquipmentStart;
    const index = this.state.durationTypesEquipmentStart.findIndex(p => p.value === this.state.durationTypeEquipmentStart);
    console.log(index);
    if (index >= 0 && this.state.durationNumberEquipmentStart > 0) {

      var durationDesc = this.state.durationEquipmentStart + this.state.durationNumberEquipmentStart + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberEquipmentStart;

      types.splice(0, index + 1);

      this.setState({
        durationObjEquipmentStart: myObj,
        durationEquipmentStart: durationDesc,
        durationTypesEquipmentStart: types,
        durationTypeEquipmentStart: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationEquipmentStart = () => {
    if (this.state.durationEquipmentStart.trim() !== "") {
      var pObj = this.state.durationObjEquipmentStart;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjEquipmentStart: pObj,
        durationEquipmentStart: durationDesc,
        durationTypesEquipmentStart: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationEquipmentStart = () => {

    this.setState({
      durationObjEquipmentStart: {},
      durationEquipmentStart: "",
      durationNumberEquipmentStart: "",
      durationTypesEquipmentStart: _DurationTypes.slice(),
      durationTypeEquipmentStart: ''
    });
  }

  setDurationEquipment = (pObj) => {

    //update isleminde kullanilacak.

    var result = this.getDurationDesc(pObj);

    let types = _DurationTypes.slice();
    let index = this.getMaxPeriod(pObj);
    types.splice(0, index);

    this.setState({
      durationEquipment: result,
      durationObjEquipment: Boolean(pObj) ? pObj : {},
      durationTypesEquipment: types
    })
  }

  setDurationEquipmentStart = (pObj) => {

    //update isleminde kullanilacak.

    var result = this.getDurationDesc(pObj);
    var types = _DurationTypes.slice();
    const index = this.getMaxPeriod(pObj);
    console.log(types);
    console.log(index);
    types.splice(0, index);

    this.setState({
      durationEquipmentStart: result,
      durationObjEquipmentStart: Boolean(pObj) ? pObj : {},
      durationTypesEquipmentStart: types
    })
  }

  calculateDurationManpower = () => {

    var myObj = this.state.durationObjManpower;
    var types = this.state.durationTypesManpower;
    const index = this.state.durationTypesManpower.findIndex(p => p.value === this.state.durationTypeManpower);
    console.log(index);
    if (index >= 0 && this.state.durationNumberManpower > 0) {

      var durationDesc = this.state.durationManpower + this.state.durationNumberManpower + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberManpower;

      types.splice(0, index + 1);

      this.setState({
        durationObjManpower: myObj,
        durationManpower: durationDesc,
        durationTypesManpower: types,
        durationTypeManpower: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationManpower = () => {
    if (this.state.durationManpower.trim() !== "") {
      var pObj = this.state.durationObjManpower;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjManpower: pObj,
        durationManpower: durationDesc,
        durationTypesManpower: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationManpower = () => {

    this.setState({
      durationObjManpower: {},
      durationManpower: "",
      durationNumberManpower: "",
      durationTypesManpower: _DurationTypes.slice(),
      durationTypeManpower: ''
    });
  }

 

  calculateDurationManpowerStart = () => {

    var myObj = this.state.durationObjManpowerStart;
    var types = this.state.durationTypesManpowerStart;
    const index = this.state.durationTypesManpowerStart.findIndex(p => p.value === this.state.durationTypeManpowerStart);
    console.log(index);
    if (index >= 0 && this.state.durationNumberManpowerStart > 0) {

      var durationDesc = this.state.durationManpowerStart + this.state.durationNumberManpowerStart + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberManpowerStart;

      types.splice(0, index + 1);

      this.setState({
        durationObjManpowerStart: myObj,
        durationManpowerStart: durationDesc,
        durationTypesManpowerStart: types,
        durationTypeManpowerStart: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationManpowerStart = () => {
    if (this.state.durationManpowerStart.trim() !== "") {
      var pObj = this.state.durationObjManpowerStart;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjManpowerStart: pObj,
        durationManpowerStart: durationDesc,
        durationTypesManpowerStart: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationManpowerStart = () => {

    this.setState({
      durationObjManpowerStart: {},
      durationManpowerStart: "",
      durationNumberManpowerStart: "",
      durationTypesManpowerStart: _DurationTypes.slice(),
      durationTypeManpowerStart: ''
    });
  }

  setDurationManpower = (pObj) => {

    //update isleminde kullanilacak.

    var result = this.getDurationDesc(pObj);

    let types = _DurationTypes.slice();
    let index = this.getMaxPeriod(pObj);
    types.splice(0, index);

    this.setState({
      durationManpower: result,
      durationObjManpower: Boolean(pObj) ? pObj : {},
      durationTypesManpower: types
    })
  }

  setDurationManpowerStart = (pObj) => {

    //update isleminde kullanilacak.

    var result = this.getDurationDesc(pObj);
    var types = _DurationTypes.slice();
    const index = this.getMaxPeriod(pObj);
    console.log(types);
    console.log(index);
    types.splice(0, index);

    this.setState({
      durationManpowerStart: result,
      durationObjManpowerStart: Boolean(pObj) ? pObj : {},
      durationTypesManpowerStart: types
    })
  }

  calculateDurationMaterial = () => {

    var myObj = this.state.durationObjMaterial;
    var types = this.state.durationTypesMaterial;
    const index = this.state.durationTypesMaterial.findIndex(p => p.value === this.state.durationTypeMaterial);
    console.log(index);
    if (index >= 0 && this.state.durationNumberMaterial > 0) {

      var durationDesc = this.state.durationMaterial + this.state.durationNumberMaterial + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberMaterial;

      types.splice(0, index + 1);

      this.setState({
        durationObjMaterial: myObj,
        durationMaterial: durationDesc,
        durationTypesMaterial: types,
        durationTypeMaterial: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationMaterial = () => {
    if (this.state.durationMaterial.trim() !== "") {
      var pObj = this.state.durationObjMaterial;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjMaterial: pObj,
        durationMaterial: durationDesc,
        durationTypesMaterial: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationMaterial = () => {

    this.setState({
      durationObjMaterial: {},
      durationMaterial: "",
      durationNumberMaterial: "",
      durationTypesMaterial: _DurationTypes.slice(),
      durationTypeMaterial: ''
    });
  }

 

  calculateDurationMaterialStart = () => {

    var myObj = this.state.durationObjMaterialStart;
    var types = this.state.durationTypesMaterialStart;
    const index = this.state.durationTypesMaterialStart.findIndex(p => p.value === this.state.durationTypeMaterialStart);
    console.log(index);
    if (index >= 0 && this.state.durationNumberMaterialStart > 0) {

      var durationDesc = this.state.durationMaterialStart + this.state.durationNumberMaterialStart + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberMaterialStart;

      types.splice(0, index + 1);

      this.setState({
        durationObjMaterialStart: myObj,
        durationMaterialStart: durationDesc,
        durationTypesMaterialStart: types,
        durationTypeMaterialStart: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationMaterialStart = () => {
    if (this.state.durationMaterialStart.trim() !== "") {
      var pObj = this.state.durationObjMaterialStart;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjMaterialStart: pObj,
        durationMaterialStart: durationDesc,
        durationTypesMaterialStart: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationMaterialStart = () => {

    this.setState({
      durationObjMaterialStart: {},
      durationMaterialStart: "",
      durationNumberMaterialStart: "",
      durationTypesMaterialStart: _DurationTypes.slice(),
      durationTypeMaterialStart: ''
    });
  }

  setDurationMaterial = (pObj) => {

    //update isleminde kullanilacak.

    var result = this.getDurationDesc(pObj);

    let types = _DurationTypes.slice();
    let index = this.getMaxPeriod(pObj);
    types.splice(0, index);

    this.setState({
      durationMaterial: result,
      durationObjMaterial: Boolean(pObj) ? pObj : {},
      durationTypesMaterial: types
    })
  }

  setDurationMaterialStart = (pObj) => {

    //update isleminde kullanilacak.

    var result = this.getDurationDesc(pObj);
    var types = _DurationTypes.slice();
    const index = this.getMaxPeriod(pObj);
    console.log(types);
    console.log(index);
    types.splice(0, index);

    this.setState({
      durationMaterialStart: result,
      durationObjMaterialStart: Boolean(pObj) ? pObj : {},
      durationTypesMaterialStart: types
    })
  }

  durationTemplate(duration) {

    var result = "";
    var pObj = duration;
    if (pObj !== undefined && pObj !== null) {
      if (pObj.year > 0)
        result = result + pObj.year + "Years ";
      if (pObj.month > 0)
        result = result + pObj.month + "Months ";
      if (pObj.day > 0)
        result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
        result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
        result = result + pObj.minute + "Minutes ";
      if (pObj.second > 0)
        result = result + pObj.second + "Seconds ";
    }

    return <> {result}</>;
  }


  getDurationDesc = (pObj) => {
    var result = "";
    if (Boolean(pObj)) {
      if (pObj.year > 0)
        result = result + pObj.year + "Years ";
      if (pObj.month > 0)
        result = result + pObj.month + "Months ";
      if (pObj.day > 0)
        result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
        result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
        result = result + pObj.minute + "Minutes ";
      if (pObj.second > 0)
        result = result + pObj.second + "Seconds ";
    }

    return result;
  }

  getMaxPeriod = (pObj) => {
    var result = 0;
    if (Boolean(pObj)) {

      if (pObj.second > 0)
        return 6;
      if (pObj.minute > 0)
        return 5;
      if (pObj.hour > 0)
        return 4;
      if (pObj.day > 0)
        return 3;
      if (pObj.month > 0)
        return 2;
      if (pObj.year > 0)
        return 1;

    }

    return result;
  }


  render() {

    const layout = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    var VALUES = [moment('08:00:00', 'HH:mm'), moment('09:00:00', 'HH:mm')];

    const equipmentColumns = [
      {
        title: "Action",
        key: "action",
        render: (value, row, index) => {
          return <React.Fragment> <CustomMaterialMenuDeleteEdit row={row} onDeleteRow={this.deleteEquipmentModal.bind(this)} onEditRow={this.editEquipment.bind(this)} />   </React.Fragment>
        }
      },
      {
        title: 'Equipment Type',
        dataIndex: 'equipmentType',
        key: 'equipmentType',
        render: (value, row, index) => {
          if (value != null && value !== undefined) {
            return value.equipmentTypeName;
          }

        }

      },
      {
        title: 'Equipment',
        dataIndex: 'equipmentLog',
        key: 'equipmentLog',
        render: (value, row, index) => {
          if (value != null && value !== undefined) {
            return value.equipmentDescription;
          }

        },

      },
      {
        title: 'Equipment Code',
        dataIndex: 'equipmentLog',
        key: 'equipmentLog',
        render: (value, row, index) => {
          if (value != null && value !== undefined) {
            return value.equipmentCode;
          }

        },

      },
      {
        title: 'Alternative Of',
        dataIndex: 'resourcesEquipment',
        key: 'resourcesEquipment',
        render: (value, row, index) => {
          if (value != null && value !== undefined) {
            return value.equipmentLog.equipmentDescription;
          }

        },

      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        ...this.getColumnSearchProps('quantity')
      },
      {
        title: 'Operator Requirement',
        dataIndex: 'operatorRequirement',
        key: 'operatorRequirement',
        render: (value, row, index) => {

          if (value)
            return 'Yes';
          else return 'No';
        },

      },
      {
        title: 'Starting Point',
        key: 'startingPoint',
        render: (value, row, index) => {

          if (Boolean(row.durationStart))
            return this.durationTemplate(row.durationStart);
          else return '';
        }
      },
      {
        title: 'Duration',
        key: 'duration',
        render: (value, row, index) => {

          if (Boolean(row.duration))
            return this.durationTemplate(row.duration);
          else return '';
        }

      },
      // {
      //   title: 'Time Line',
      //   width: '500px',
      //   render: (value, row, index) => {
      //     VALUES[0] = moment(row.startTimeStr, 'HH:mm');
      //     VALUES[1] = moment(row.endTimeStr, 'HH:mm');
      //     return <div style={{ width: '100%', height: '70px', margin: '0 auto' }}>
      //       <HorizontalTimeline
      //         index={this.state.eti}
      //         getLabel={function (date, index2) {
      //           return moment(VALUES[index2]).format("HH:mm");
      //         }}
      //         indexClick={(index) => {
      //           this.setState({ eti: index, etp: this.state.eti });
      //         }}
      //         values={VALUES} />
      //     </div>
      //   }
      // },

    ];

    const materialColumns = [
      {
        title: "Action",
        key: "action",
        render: (value, row, index) => {
          return <React.Fragment> <CustomMaterialMenuDeleteEdit row={row} onDeleteRow={this.deleteMaterialModal.bind(this)} onEditRow={this.editMaterial.bind(this)} />   </React.Fragment>
        }
      },
      {
        title: 'MAterial Type',
        dataIndex: 'materialType',
        key: 'materialType',
        render: (value, row, index) => {
          if (Boolean(value)) {
            return value.materialTypeName;
          }

        }

      },
      {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
        render: (value, row, index) => {
          if (Boolean(value)) {
            return value.materialName;
          }

        },

      },
      {
        title: 'Material Code',
        dataIndex: 'material',
        key: 'material',
        render: (value, row, index) => {
          if (Boolean(value)) {
            return value.materialCode;
          }

        },

      },
      
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        ...this.getColumnSearchProps('quantity')
      },
      {
        title: 'As is (Usage)',
        dataIndex: 'asis',
        key: 'asis',
        render: (value, row, index) => {

          if (value)
            return 'Yes';
          else return 'No';
        },

      },
      {
        title: 'Modification',
        dataIndex: 'codeModification',
        key: 'codeModification',
        render: (value, row, index) => {
          if (Boolean(value)) {
            return value.name; 
          }

        },

      },
      {
        title: 'Starting Point',
        key: 'startingPoint',
        render: (value, row, index) => {

          if (Boolean(row.durationStart))
            return this.durationTemplate(row.durationStart);
          else return '';
        }
      },
      {
        title: 'Duration',
        key: 'duration',
        render: (value, row, index) => {

          if (Boolean(row.duration))
            return this.durationTemplate(row.duration);
          else return '';
        }

      },
    ];

    const documentColumns = [
      {
        title: 'Document Type',
        dataIndex: 'documentType',
        key: 'documentType',
        render: (value, row, index) => {
          if (value != null && value !== undefined)
            return value.documentType;

          return '';
        },
      },
      {
        title: 'Doc. Code',
        dataIndex: 'documentLog',
        key: 'documentLog',
        render: (record) =>
          <React.Fragment>
            <div style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
              // fontWeight: "bold",
            }}
              onClick={(e) => this.showReport(record)}>
              {record.code}
            </div>
          </React.Fragment>
      },
      {
        title: 'Document',
        dataIndex: 'documentLog',
        key: 'documentLog',
        render: (value, row, index) => {
          if (value != null && value !== undefined)
            return value.description;

          return '';
        },
      },
      {
        title: 'Detail',
        dataIndex: 'itpchecklistitemnos',
        key: 'itpchecklistitemnos',
      },
      {
        title: 'Action',
        render: (text, record) => {
          if (record != null && record !== undefined) {
            if (record.itpchecklistitems != null && record.itpchecklistitems !== undefined) {
              return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={record}
                  onDeleteRow={this.deleteDocumentModal.bind(this)}
                  onEditRow={this.editDocument.bind(this)} />
              </React.Fragment>
            } else {
              return <React.Fragment>
                <CustomMaterialMenuDelete row={record}
                  onDeleteRow={this.deleteDocumentModal.bind(this)} />
              </React.Fragment>
            }
          }
          return "";
        },
      },



    ];

    const manpowerColumns = [
      {
        title: "Action",
        key: "action",
        render: (value, row, index) => {
          return <React.Fragment> <CustomMaterialMenuDeleteEdit row={row} onDeleteRow={this.deleteManpowerModal.bind(this)} onEditRow={this.editManpower.bind(this)} />   </React.Fragment>
        }
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        render: (value, row, index) => {
          if (value != null && value !== undefined)
            return value.departmentName;

          return '';
        },

      },
      {
        title: 'Discipline',
        dataIndex: 'discipline',
        key: 'discipline',

        render: (value, row, index) => {
          if (value != null && value !== undefined)
            return value.disciplineName;

          return '';
        },
      }
      ,
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',

        render: (value, row, index) => {
          if (value !== null && value !== undefined)
            return value.designationName;

          return '';
        },
      },
      {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',

        render: (value, row, index) => {
          if (value !== null && value !== undefined)
            return value.name;

          return '';
        },
      },
      {
        title: 'Number Of Person',

        render: (value, row, index) => {
          console.log(row);
          if (row.individual)
            return 1;
          else {
            let list = this.state.manpowerData.filter(p => p.teamId === row.teamId);

            if (list !== null && list !== undefined) {
              return list.length;
            }
            else return '';
          }

        }


      },
      {
        title: 'Responsibility',
        dataIndex: 'responsibility',
        key: 'reponsibility',
        ...this.getColumnSearchProps('reponsibility'),
      },
      {
        title: 'Starting Point',
        key: 'startingPoint',
        render: (value, row, index) => {

          if (Boolean(row.durationStart))
            return this.durationTemplate(row.durationStart);
          else return '';
        }
      },
      {
        title: 'Duration',
        key: 'duration',
        render: (value, row, index) => {

          if (Boolean(row.duration))
            return this.durationTemplate(row.duration);
          else return '';
        }

      }


    ];

    const tDocumentProps = {
      treeData: this.state.documentTreeData,
      value: this.state.selectedDocuments,
      onChange: this.onDocumentTreeChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Please select',
      style: {
        width: '100%',
      },
    };

    return (
      <div>

        {/* <Cascader options={this.state.nodes} onChange={this.onChange} changeOnSelect /> <Button onClick={this.showModal} type="primary">Add New</Button> */}

        <h3>{this.state.activityCode}  {this.state.activityName}</h3>
        <br></br>
        {/* <div>
          {this.state.activityList.length > 0 && <GSTC config={this.state.config} onState={this.onState} />}

        </div> */}
        <Form>

          <ModalReact show={this.state.modalShow} size="lg"
            onHide={this.showOrHideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered  >
            <ModalReact.Header closeButton>
              <ModalReact.Title id="contained-modal-title-vcenter">{this.state.modalTitle}</ModalReact.Title>
            </ModalReact.Header>
            <ModalReact.Body>
              <DataTable ref={(el) => this.dt = el} value={this.state.itemsChecklistItems}
                virtualScroll={true} responsive
                className="p-datatable-customers" dataKey="id" rowHover
                selection={this.state.selectedRowVal2}
                onSelectionChange={this.onselectChangeVal2}
                paginator rows={10} emptyMessage="No data found" >
                <Column selectionMode="multiple" style={{ textAlign: 'center', width: '10%' }} />
                <Column field="iclistnoRevno" style={{ textAlign: 'center', width: '10%' }} sortable header="Code/ Revno" />
                <Column field="checklistItemsitemno" style={{ textAlign: 'center', width: '10%' }} sortable header="Item No" />
                <Column field="checklistItemsAcceptanceCriteria" style={{ textAlign: 'center', width: '50%' }} sortable header="Acceptance Criteria" />
                <Column field="checklistItemsReference" style={{ textAlign: 'center', width: '20%' }} sortable header="Reference" />
              </DataTable>
            </ModalReact.Body>
            <ModalReact.Footer>

              <ButtonReact variant="secondary" onClick={this.showOrHideModal}  >Close</ButtonReact> &nbsp;&nbsp;&nbsp;
                <ButtonReact variant="success" onClick={this.saveDocument} >Save</ButtonReact>
            </ModalReact.Footer>
          </ModalReact>


          <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">

            <Form.Label style={{ color: 'black' }} column sm="2">
              Department:
                </Form.Label>
            <Col sm="6">
              <Cascader style={{ width: '100%' }} value={this.state.selectedDepartments} options={this.state.departmentCascader} onChange={this.onDepartmentCascaderChange} changeOnSelect />
            </Col>
            <Col sm="3">
            </Col>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
            <Col sm="12">
            <Link
                            to={{
                              pathname: "/ResourceTimeline",
                              data: this.state.gelenParam
                            }}
                          >  <Button style={{ width: '100%', marginLeft: "5px" }} type="primary">View Timeline</Button>
                          </Link>
            </Col>

          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">


            <Col sm="12">
              <Collapse

                defaultActiveKey={['1']}
                onChange={callback}
                expandIconPosition={this.state.expandIconPosition}
              >


                <Panel header="Equipment" key="1"  >
                  <div>
                    <Row>
                      <Space size="large" >
                        <Space direction="vertical">
                          <div>Equipment Type</div>
                          <Cascader options={this.state.equipmentTypes} onChange={this.onEquipmentTypeChange} value={this.state.equipmentTypeValues} changeOnSelect />

                        </Space>
                        <Space direction="vertical">
                          <div>Equipment </div>
                          <Dropdown style={{ width: '100%', marginLeft: "5px" }} value={this.state.equipment} options={this.state.equipments} onChange={(e) => { this.setState({ equipment: e.value }) }} placeholder="Select ... " />

                        </Space>

                        {/* <Space direction="vertical">
    <div>Equipment Code</div>
    <Dropdown optionLabel="name" style={{ width: '100%' , marginLeft:"5px" }} alue={this.state.equipmentCode} options={this.state.equipmentCodes} onChange={(e) => {this.setState({equipmentCode: e.value})}} placeholder="Select ... "/>

    </Space> */}
                        <Space direction="vertical">
                          <div>Quantity</div>
                          <InputNumber min={1} onChange={this.onChangeEquipmentQuantity} value={this.state.equipmentQuantity} />

                        </Space>
                        <Space direction="vertical">
                          <div>Operator requirement</div>
                          <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked checked={this.state.operatorRequirement} onChange={this.onChangeOperatorReq} />
                        </Space>
                        {/* <Space direction="vertical">
                    <div></div>
                    
                     <Button style={{ width: '100%' , marginLeft:"5px" }}  type="primary">TimeLine</Button> 
                     <RangePicker format="HH:mm" onChange={this.onChangeEquipmentTime} value={this.state.equipmentTime} /> 
                  </Space> */}

                        <Space direction="vertical">
                          <div>Alternative Of </div>
                          <Dropdown style={{ width: '100%', marginLeft: "5px" }} value={this.state.alternativeOfId} options={this.state.alternatives} onChange={(e) => { this.setState({ alternativeOfId: e.value }) }} placeholder="Select ... " />

                        </Space>

                        <Space direction="vertical">
                          <div></div>
                          <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addEquipment} type="primary">Save</Button>

                        </Space>

                        <Space direction="vertical">
                          <div></div>
                          <Link
                            to={{
                              pathname: "/EquipmentLog"
                            }}
                          >  <Button style={{ width: '100%', marginLeft: "5px" }} type="primary">View The Equipment</Button>
                          </Link>
                        </Space>
                      </Space>
                    </Row>
                    <br></br>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                      <Form.Label style={{ color: 'black' }} column sm="1">
                        Starting Point:
                          </Form.Label>
                      <Col sm="1">
                        <Input style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberEquipmentStart} onChange={(e) => this.setState({ durationNumberEquipmentStart: e.target.value })} />
                      </Col>

                      <Col sm="2">

                        <Select size="middle" style={{ width: '100px' }} placeholder="Select Period" value={this.state.durationTypeEquipmentStart} onChange={(value) => {
                          this.setState({
                            durationTypeEquipmentStart: value
                          });
                        }}>
                          {this.state.durationTypesEquipmentStart.map(i => (
                            <Option key={i.value} value={i.value}>{i.label}</Option>
                          ))}
                        </Select>

                      </Col>
                      <Col sm="2">
                        <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationEquipmentStart} />
                        <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDurationEquipmentStart} />
                      </Col>


                      <Col sm="4">
                        <Input style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationEquipmentStart} />

                      </Col>
                      <Col sm="1">
                        <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDurationEquipmentStart} className="p-button-danger" />
                      </Col>
                    </Form.Group>

                    <br></br>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                      <Form.Label style={{ color: 'black' }} column sm="1">
                        Period:
                          </Form.Label>
                      <Col sm="1">
                        <Input style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberEquipment} onChange={(e) => this.setState({ durationNumberEquipment: e.target.value })} />
                      </Col>

                      <Col sm="2">

                        <Select size="middle" style={{ width: '100px' }} placeholder="Select Period" value={this.state.durationTypeEquipment} onChange={(value) => {
                          this.setState({
                            durationTypeEquipment: value
                          });
                        }}>
                          {this.state.durationTypesEquipment.map(i => (
                            <Option key={i.value} value={i.value}>{i.label}</Option>
                          ))}
                        </Select>

                      </Col>
                      <Col sm="2">
                        <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationEquipment} />
                        <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDurationEquipment} />
                      </Col>


                      <Col sm="4">
                        <Input style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationEquipment} />

                      </Col>
                      <Col sm="1">
                        <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDurationEquipment} className="p-button-danger" />
                      </Col>
                    </Form.Group>

                    <br></br>
                    <Row>
                      <Table columns={equipmentColumns} dataSource={this.state.equipmentData} />
                    </Row>

                  </div>
                </Panel>

                <Panel header="Manpower" key="2"  >
                  <div>
                    <Row>
                      <Space size="large" >
                        <Space direction="vertical">
                          <div></div>
                          <Switch style={{ width: "100px", background: this.state.individual ? "#096dd9" : "#08979c" }} checkedChildren="Individual" unCheckedChildren="Team" checked={this.state.individual} defaultChecked onChange={(value) => { this.setState({ individual: value }) }} />

                        </Space>

                        {this.state.individual === false && <Space direction="vertical">
                          <div>Team Name </div>
                          <Select
                            style={{ width: '180px' }}
                            placeholder="Team Name"
                            value={this.state.teamId}
                            onChange={(value) => {
                              this.setState({
                                teamId: value
                              })
                            }}

                            dropdownRender={menu => (
                              <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                  <Input style={{ flex: 'auto' }} value={this.state.newTeam} onChange={this.onNewTeamChange} />
                                  <a
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={this.addTeam}
                                  >
                                    <PlusOutlined /> Add item
                                                               </a>
                                </div>
                              </div>
                            )}
                          >
                            {this.state.teams.map(i => (
                              <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                          </Select>
                        </Space>}
                        <Space direction="vertical">
                          <div>Discipline </div>
                          <Dropdown style={{ width: '100%', marginLeft: "5px" }} value={this.state.disciplineId} options={this.state.disciplines} onChange={(e) => { this.setState({ disciplineId: e.value }) }} placeholder="Select ... " />


                        </Space>

                        <Space direction="vertical">
                          <div>Designation </div>
                          <Dropdown style={{ width: '100%', marginLeft: "5px" }} value={this.state.designationId} options={this.state.designations} onChange={(e) => { this.setState({ designationId: e.value }) }} placeholder="Select ... " />

                          {/* <Cascader options={this.state.manpowerDesignations} onChange={this.onDesignationChange} changeOnSelect /> */}

                        </Space>

                        {/* <Space direction="vertical">
                    <div>Number of Person</div>
                    <InputNumber min={1} onChange={this.onChangeNumberOfPerson} />
                  </Space> */}
                        <Space direction="vertical">
                          <div>Responsibility</div>
                          <Input value={this.state.manpowerResponsibility} onChange={this.onChangeResponsibilityInput} placeholder="Responsibility" />
                        </Space>

                        {/* <Space direction="vertical">
                          <div></div>
                        <Button style={{ width: '100%' , marginLeft:"5px" }}  type="primary">TimeLine</Button>  
                          <RangePicker format="HH:mm" onChange={this.onChangeManpowerTime} value={this.state.manpowerTime} />
                        </Space> */}

                        <Space direction="vertical">
                          <div></div>
                          <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addManpower} type="primary">Save</Button>

                        </Space>
                      </Space>
                    </Row>
                    <br></br>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                      <Form.Label style={{ color: 'black' }} column sm="1">
                        Starting Point:
                          </Form.Label>
                      <Col sm="1">
                        <Input style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberManpowerStart} onChange={(e) => this.setState({ durationNumberManpowerStart: e.target.value })} />
                      </Col>

                      <Col sm="2">

                        <Select size="middle" style={{ width: '100px' }} placeholder="Select Period" value={this.state.durationTypeManpowerStart} onChange={(value) => {
                          this.setState({
                            durationTypeManpowerStart: value
                          });
                        }}>
                          {this.state.durationTypesManpowerStart.map(i => (
                            <Option key={i.value} value={i.value}>{i.label}</Option>
                          ))}
                        </Select>

                      </Col>
                      <Col sm="2">
                        <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationManpowerStart} />
                        <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDurationManpowerStart} />
                      </Col>


                      <Col sm="4">
                        <Input style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationManpowerStart} />

                      </Col>
                      <Col sm="1">
                        <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDurationManpowerStart} className="p-button-danger" />
                      </Col>
                    </Form.Group>

                    <br></br>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                      <Form.Label style={{ color: 'black' }} column sm="1">
                        Period:
                          </Form.Label>
                      <Col sm="1">
                        <Input style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberManpower} onChange={(e) => this.setState({ durationNumberManpower: e.target.value })} />
                      </Col>

                      <Col sm="2">

                        <Select size="middle" style={{ width: '100px' }} placeholder="Select Period" value={this.state.durationTypeManpower} onChange={(value) => {
                          this.setState({
                            durationTypeManpower: value
                          });
                        }}>
                          {this.state.durationTypesManpower.map(i => (
                            <Option key={i.value} value={i.value}>{i.label}</Option>
                          ))}
                        </Select>

                      </Col>
                      <Col sm="2">
                        <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationManpower} />
                        <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDurationManpower} />
                      </Col>


                      <Col sm="4">
                        <Input style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationManpower} />

                      </Col>
                      <Col sm="1">
                        <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDurationManpower} className="p-button-danger" />
                      </Col>
                    </Form.Group>

                    <br></br>
                    <Row>
                      <Table columns={manpowerColumns} dataSource={this.state.manpowerData} />
                    </Row>

                  </div>
                </Panel>
                <Panel header="Material" key="3"  >
                  <div>
                    <Row>
                      <Space size="large" >
                        <Space direction="vertical">
                          <div>Material Type</div>
                          <Cascader options={this.state.materialTypes} onChange={this.onMaterialTypeChange} value={this.state.materialTypeValues} changeOnSelect />
                        </Space>
                        <Space direction="vertical">
                          <div>Material </div>
                          <Dropdown style={{ width: '100%', marginLeft: "5px" }} value={this.state.material} options={this.state.materials} onChange={(e) => { this.setState({ material: e.value }) }} placeholder="Select ... " />

                        </Space>

                        {/* <Space direction="vertical">
                          <div>Material Code</div>
                          <Dropdown optionLabel="name" style={{ width: '100%', marginLeft: "5px" }} alue={this.state.materialCode} options={this.state.materialCodes} onChange={(e) => { this.setState({ materialCode: e.value }) }} placeholder="Select ... " />

                        </Space> */}
                        <Space direction="vertical">
                          <div>Quantity</div>
                          <InputNumber min={1} onChange={this.onChangeMaterialQuantity} value={this.state.materialQuantity} /> 

                        </Space>
                        <Space direction="vertical">
                          <div>Usage</div>
                          <Switch style={{ width: "105px", background: this.state.materialUsage ? "#096dd9" : "#08979c" }} checkedChildren="As is" unCheckedChildren="Modification" checked={this.state.materialUsage} onChange={(value) => { this.setState({ materialUsage: value }) }} />
                        </Space>
                        {this.state.materialUsage === false && <Space direction="vertical">
                          <div>Modification </div>
                          <Select
                            style={{ width: '180px' }}
                            placeholder="Team Name"
                            value={this.state.modificationId}
                            onChange={(value) => {
                              this.setState({
                                modificationId: value
                              })
                            }}

                            dropdownRender={menu => (
                              <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                  <Input style={{ flex: 'auto' }} value={this.state.newModification} onChange={this.onNewModificationChange} />
                                  <a
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={this.addModification}
                                  >
                                    <PlusOutlined /> Add item
                                                               </a>
                                </div>
                              </div>
                            )}
                          >
                            {this.state.modifications.map(i => (
                              <Option key={i.id} value={i.id}>{i.name}</Option>
                            ))}
                          </Select>
                        </Space>}
                        
                        <Space direction="vertical">
                          <div></div>
                          <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addMaterial} type="primary">Save</Button>

                        </Space>

                        <Space direction="vertical">
                          <div></div>
                          <Link
                            to={{
                              pathname: "/MaterialLog"
                            }}
                          >  <Button style={{ width: '100%', marginLeft: "5px" }} type="primary">View The Material</Button>
                          </Link>
                          
                        </Space>
                       
                      </Space>
                    </Row>
                    <br></br>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                      <Form.Label style={{ color: 'black' }} column sm="1">
                        Starting Point:
                          </Form.Label>
                      <Col sm="1">
                        <Input style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberMaterialStart} onChange={(e) => this.setState({ durationNumberMaterialStart: e.target.value })} />
                      </Col>

                      <Col sm="2">

                        <Select size="middle" style={{ width: '100px' }} placeholder="Select Period" value={this.state.durationTypeMaterialStart} onChange={(value) => {
                          this.setState({
                            durationTypeMaterialStart: value
                          });
                        }}>
                          {this.state.durationTypesMaterialStart.map(i => (
                            <Option key={i.value} value={i.value}>{i.label}</Option>
                          ))}
                        </Select>

                      </Col>
                      <Col sm="2">
                        <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationMaterialStart} />
                        <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDurationMaterialStart} />
                      </Col>


                      <Col sm="4">
                        <Input style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationMaterialStart} />

                      </Col>
                      <Col sm="1">
                        <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDurationMaterialStart} className="p-button-danger" />
                      </Col>
                    </Form.Group>

                    <br></br>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                      <Form.Label style={{ color: 'black' }} column sm="1">
                        Period:
                          </Form.Label>
                      <Col sm="1">
                        <Input style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberMaterial} onChange={(e) => this.setState({ durationNumberMaterial: e.target.value })} />
                      </Col>

                      <Col sm="2">

                        <Select size="middle" style={{ width: '100px' }} placeholder="Select Period" value={this.state.durationTypeMaterial} onChange={(value) => {
                          this.setState({
                            durationTypeMaterial: value
                          });
                        }}>
                          {this.state.durationTypesMaterial.map(i => (
                            <Option key={i.value} value={i.value}>{i.label}</Option>
                          ))}
                        </Select>

                      </Col>
                      <Col sm="2">
                        <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationMaterial} />
                        <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDurationMaterial} />
                      </Col>


                      <Col sm="4">
                        <Input style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationMaterial} />

                      </Col>
                      <Col sm="1">
                        <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDurationMaterial} className="p-button-danger" />
                      </Col>
                    </Form.Group>

                    <br></br>
                    <Row>
                      <Table columns={materialColumns} dataSource={this.state.materialData} />
                    </Row>

                  </div>
                </Panel>
                <Panel header="Document" key="4" >
                  {/*   */}
                  <div>
                    <Row>

                      <Col span={22}>

                        <TreeSelect  {...tDocumentProps} />

                      </Col>
                      <Col span={2}>
                        <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addDocument} type="primary">Add</Button>
                      </Col>
                      {/* <Space direction="vertical">
                    <div>Document Type</div>
                    <Dropdown style={{ width: '100%', marginLeft: "5px" }} value={this.state.equipment} options={this.state.equipments} onChange={(e) => { this.setState({ equipment: e.value }) }} placeholder="Select ... " />

                  </Space> */}



                    </Row>
                    <br></br>
                    <Row>
                      <Table columns={documentColumns}
                        dataSource={this.state.documentTableData}
                        responsive
                        striped
                        bordered
                        hover
                        pagination={{
                          pageSize: 5,
                          defaultPageSize: 5,
                          showSizeChanger: true,
                          pageSizeOptions: ["5", "10", "20", "30"],
                          total: this.state.documentTableData.length,
                          showQuickJumper: true,
                          position: "bottomLeft",
                        }}
                      />
                    </Row>

                  </div>
                </Panel>
              </Collapse>
            </Col>

          </Form.Group>
        </Form>
        <ModalReact
          visible={this.state.visible}
          title="Add New Item"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk}>
              Add
            </Button>,
          ]}
        >
          {this.state.values.length > 0 && <p> {text} {this.state.selectedItems[this.state.values.length - 1].label} </p>}
          <Input value={this.state.newItem} onChange={this.onChangeInput} placeholder="Type new item here .." />
        </ModalReact>


        <ModalReact
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <ModalReact.Header closeButton>
            <ModalReact.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </ModalReact.Title>
          </ModalReact.Header>

          <ModalReact.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </ModalReact.Body>
        </ModalReact>

        <ModalReact
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalReact.Header closeButton>
            <ModalReact.Title id="contained-modal-title-vcenter">Photo</ModalReact.Title>
          </ModalReact.Header>
          <ModalReact.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </ModalReact.Body>
          <ModalReact.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
          </Button>
          </ModalReact.Footer>
        </ModalReact>

        <ModalReact
          size="lg"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalReact.Header closeButton>
            <ModalReact.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
            </ModalReact.Title>
          </ModalReact.Header>
          <ModalReact.Body>
            {/* <div style={{ textAlign: "right", marginBottom: "1rem" }}>
            <Button style={{ marginLeft: "1rem" }}
              onClick={this.printPdf}
            >
              Print
          </Button>
            <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Voucher PDF File Link">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Voucher PDF File Link is here: " subject="Voucher PDF File Link">
              <EmailIcon size={32} round />
            </EmailShareButton>
            <WhatsappShareButton
              style={{ marginLeft: "1rem" }}
              url={this.state.pdfFile}
              title="Voucher PDF File Link"
              separator=": "
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div> */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layout} />
              </div>
            </Worker>
          </ModalReact.Body>
          <ModalReact.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
            </Button>
          </ModalReact.Footer>
        </ModalReact>


      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Resources) 
