/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';

export class OnlineUsers extends Component {

    constructor() {
        super();
        this.state = { 
            onlineUSer: [] ,
            
            selectedRow: {}
            
        };
 
    }

    componentDidMount = async () => {
 
        this.restartTable();

    };
 
  
   
 
    

    restartTable = async () => {

        const response = await handleRequest("GET", "/User/getAllSession");
        console.log('AAAAAAAAAAAAAAAAAAAA',response)
        if (response.data.length === 0) {
            this.setState({
                onlineUSer: []
            });
        }

        if (response.data.length !== 0) {
  
            this.setState({   onlineUSer: response.data });

        }

    }
 
    brandTemplate(rowData, column) {
 
        var d=null;
        try {
            
        
        if(column.field=='ses.expiry_TIME')
         d = rowData.ses.expiry_TIME;
         if(column.field=='ses.creation_TIME')
          d = rowData.ses.creation_TIME;
          if(column.field=='ses.last_ACCESS_TIME')
           d = rowData.ses.last_ACCESS_TIME;
        } catch (error) {
            
        } 
        if (d != null) {
            var dt = new Date(d);
            return (''
                + dt.getDate().toString().padStart(2, '0') + '/'
                + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
                + dt.getFullYear().toString().padStart(4, '0') + ' '
                + dt.getHours().toString().padStart(2, '0') + ':'
                + dt.getMinutes().toString().padStart(2, '0') + ':'
                + dt.getSeconds().toString().padStart(2, '0'));
        } else return '';
    }
 
    render() {

       


 

        return <div>
            <Card>
                  
                <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Toast ref={(el) => this.toast = el} />
                    <DataTable ref={(el) => this.dt = el} value={this.state.onlineUSer} paginator={true} responsive={true}
                        rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-departments" selectionMode="single"
                        selection={this.state.selectedRow} onSelectionChange={e => this.setState({ selectedRow: e.value })}>
                        <Column field="username" style={{ textAlign: 'center'  }} filter sortable header="User" />
                        <Column field="email" style={{ textAlign: 'center' }} filter sortable header="Email" /> 
                        <Column field="ses.expiry_TIME"  body={this.brandTemplate}  header="Expiry Time" filter sortable />
                        <Column field="ses.creation_TIME" body={this.brandTemplate} header="Login Time" filter sortable />
                        <Column field="ses.last_ACCESS_TIME" body={this.brandTemplate} header="Last Access Time" filter sortable />
                        <Column field="ses.max_INACTIVE_INTERVAL" style={{ textAlign: 'center' }} header="Time" filter sortable /> 
                        <Column field="ses.session_ID" style={{ textAlign: 'center' }} header="Session Id" filter sortable /> 
                    </DataTable>
                </div>

            </Card>

        </div>

    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineUsers)