/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Table, Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import { Toast } from 'primereact/toast';
import "./css/DataTable.scss";
import { Column } from 'primereact/components/column/Column';
import { TreeTable } from 'primereact/treetable';

export class ItpLogExpand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",
      itemno: "",
      projectphaseactivitycode: "",
      taskactivity: [],
      qualityprocedure: [],
      qualityrecord: [],
      contractor: [],
      client: [],
      remarks: [],
      itemsGnotes: [],
      gnNote: "",
      itemsLegends: [],
      legendAbbravitaion: "",
      legendExplanation: "",

      disciplinesOfDepartments: [],
      disciplineVal: '',
      iptNameVal: '',
      iptCodeVal: '',
      approvalDateVal: '',
      revNoVal: "01",
      legendAddItems: false,
      generalNoteAddItems: false,
      activityAddItems: false,
      datatableValue: [],
      selectedRowVal: null,
      expnd: false,

      itpActivityTreeTable: [],
      }


    this.addLegendsTable = this.addLegendsTable.bind(this);
    this.addGeneralNotesTable = this.addGeneralNotesTable.bind(this);

  }

    componentDidMount = async () => { 

    this.getLegend();
    this.getGeneralNotes();
    this.getItpActivity();
  }

  getLegend = async () => {

    const response = await handleRequest("GET", "/Itp/getLegendAll");
    if (response.data.length !== 0) {
      this.setState({ itemsLegends: response.data });
    } else { this.setState({ itemsLegends: [] }); }
  }

  getGeneralNotes = async () => {
    //const response = await handleRequest("GET", "/Itp/getItpgeneralnotesAll");
    const newObj = {
      itpId: this.props.row.id
    } 
    const response = await handleRequest("POST", "/Itp/getItpNotesResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ itemsGnotes: response.data });
    } else { this.setState({ itemsGnotes: [] }); }
  }

  getItpActivity = async () => { 
     const newObj = {
       itpid: this.props.row.id
     }     
     const responseTree = await handleRequest("POST", "/ItpActivity/getTreeItpActivity", newObj);
 
     if (responseTree.data.length !== 0) {
         this.setState({
           itpActivityTreeTable: responseTree.data.treeTable,
         });
     }else{
         this.setState({
           itpActivityTreeTable: [],
         });
     }  
 }

  addGeneralNotes = () => {
    this.setState({
      gnNote: "",
      generalNoteAddItems: true
    });
  }

  addGeneralNotesTable = async () => {

    const { gnNote } = this.state;
    const prm = { description: gnNote };
    const response = await handleRequest("POST", "/Itp/addItpgeneralnotes", prm);

    this.showSuccess('success', response.data, 'Ok');
    this.setState({ gnNote: '', generalNoteAddItems: false });
    this.getGeneralNotes();

  }

  addLegendsTable = async () => {
    const { legendAbbravitaion } = this.state;
    const { legendExplanation } = this.state;

    const prm = { code: legendAbbravitaion, description: legendExplanation };
    const response = await handleRequest("POST", "/Itp/addLegend", prm);

    this.showSuccess('success', response.data, 'Ok');
    this.setState({ legendAbbravitaion: '', legendExplanation: '', legendAddItems: false });
    this.getLegend();

  }



  addLegends = () => {
    this.setState({
      legendAbbravitaion: "",
      legendExplanation: "",
      legendAddItems: true
    });
  }

  addActivityShow = () => {
    this.setState({
      activityAddItems: true
    });
  }


  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }




  render() {



    return (
      <div>
        <Toast ref={(el) => this.toast = el} />


        <div className="col-md-12">

          <br />
          <Accordion defaultActiveKey="2">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Activity</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="activityAcord" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <TreeTable value={this.state.itpActivityTreeTable}>
                            <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="ItpActivity Id" />
                            <Column field="itemno" header="Item No." expander></Column> 
                            <Column field="activitycode" header="Project Phase , Activity Code" filter sortable></Column>
                            <Column field="activity" header="Task / Activity" filter sortable></Column> 
                            <Column field="qprocedureAck" header="Quality Procedure" filter sortable></Column> 
                            <Column field="qrecordAck" header="Quality Record" filter sortable></Column> 
                            <Column field="level1Ack" header={this.state.level1GridBsl} filter sortable></Column> 
                            <Column field="contractorAck" header="Contractor" filter sortable></Column> 
                            <Column field="clientAck" header="Client" filter sortable></Column> 
                            <Column field="level2Ack" header={this.state.level2GridBsl} filter sortable></Column> 
                            <Column field="remarks" header="Remarks" filter sortable></Column> 
                        </TreeTable>
                    </div>  
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="3">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; General Notes</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="notAcord" eventKey="0">
                <Card.Body>

                  <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.itemsGnotes.map(itemsGnote => (
                        <tr key={itemsGnote.id}>
                          <td>{itemsGnote.index}</td>
                          <td>{itemsGnote.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
{/*          
          <Accordion defaultActiveKey="4">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Legend</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="legendAcord" eventKey="0">
                <Card.Body>

                  <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Abbravitaion</th>
                        <th>Explanation</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.itemsLegends.map(itemsLegend => (
                        <tr key={itemsLegend.id}>
                          <td>{itemsLegend.id}</td>
                          <td>{itemsLegend.code}</td>
                          <td>{itemsLegend.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />

                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        */}
          <br />


        </div>

      </div>


    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ItpLogExpand)
