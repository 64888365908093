/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../../redux/config/env';
import { Col, Form as FormBoot, Row } from 'react-bootstrap';
import { Cascader, Select, Spin, Divider } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

const { Option } = Select;

var selectedUserOptions = null;
var departmentId = null;
var disciplineId = null;
var designationId = null;

class SearchUser extends Component {

    constructor(props) {
        super(props);
        this.fetchUser = debounce(this.fetchUser, 800);
        this.state = {
            departmentCascader: [],
            values: [],
            selectedUserOptions: [],
            selectedInspectors2: [],
            inspectors2: [],
            inspectors3: [],
            fetching: false,
            value: [],
        }
    }

    componentDidMount = async () => {
        this.getUsers(null, null, null);

        const responseCascader = await handleRequest("GET", "/Department/getNodes");

        console.log(responseCascader.data);

        if (responseCascader.data.length !== 0) {
            this.setState({
                departmentCascader: responseCascader.data
            });
        }
    }

    onDepartmentCascaderChange = (value, selectedOptions) => {
        this.setState({
            values: value,
            selectedUserOptions: selectedOptions
        });
        selectedUserOptions = selectedOptions;

        if (value.length === 0) {
            departmentId = null;
            disciplineId = null;
            designationId = null;
            this.getUsers();
        }
    }

    cascaderClose = (value) => {
        if (!value && this.state.selectedUserOptions.length !== 0 && this.state.values.length !== 0) {
            for (let index = 0; index < selectedUserOptions.length; index++) {
                if (selectedUserOptions[index].key.toString() === "department")
                    departmentId = selectedUserOptions[index].value;
                if (selectedUserOptions[index].key.toString() === "discipline")
                    disciplineId = selectedUserOptions[index].value;
                if (selectedUserOptions[index].key.toString() === "designation")
                    designationId = selectedUserOptions[index].value;
            }
            this.getUsers();
        }

        if (!value && (this.state.values.length === 0 || this.state.selectedUserOptions.length === 0)) {
            this.getUsers();
        }
    }

    getUsers = async () => {
        const staffModel = {
            departmentId: departmentId,
            disciplineId: disciplineId,
            designationId: designationId,
            nameLastName: ""
        };

        const responseStaffs = await handleRequest("POST", "/Staff/getCompanyItemsBySearch", staffModel);

        if (responseStaffs.data.length !== 0) {
            var columns = responseStaffs.data;
            var options = [];
            for (let index = 0; index < columns.length; index++) {
                options.push({ text: columns[index].name.toString() + " " + columns[index].lastName.toString(), value: columns[index].id.toString() });
            }

            this.setState({
                inspectors2: options,
                inspectors3: options
            });
        }

        if (responseStaffs.data.length === 0) {
            this.setState({
                inspectors2: [],
                inspectors3: []
            });
        }
    }

    handleChangeUserSearch = (value) => {

        console.log(value);

        this.setState({
            value,
            fetching: false,
        });

        this.props.selectUsers(value);
    }

    fetchUser = async (value) => {
        console.log('fetching user', value);

        this.setState({
            fetching: true
        });

        const staffModel = {
            departmentId: departmentId,
            disciplineId: disciplineId,
            designationId: designationId,
            nameLastName: value
        };

        handleRequest("POST", "/Staff/getCompanyItemsBySearch", staffModel).then(response => {
            var columns = response.data;
            var options = [];
            for (let index = 0; index < columns.length; index++) {
                options.push({ text: columns[index].name + " " + columns[index].lastName, value: columns[index].id });
            }

            console.log(options);

            this.setState({
                inspectors3: options,
                fetching: false
            });
        });
    }

    selectAll = () => {
        var allUsers = this.state.inspectors3;
        var flagSelectAllUser = [];

        for (let index = 0; index < allUsers.length; index++) {
            flagSelectAllUser.push({
                value: allUsers[index].value,
                label: allUsers[index].text,
                key: allUsers[index].value
            });
        }

        this.setState({
            value: flagSelectAllUser,
            fetching: false,
        });

        this.props.selectUsers(flagSelectAllUser);
    }

    clearAll = () => {
        this.setState({
            value: [],
            fetching: false,
        });

        var flagValue = [];

        this.props.selectUsers(flagValue);
    }

    render() {

        const { fetching, value } = this.state;

        return (
            <Row style={{ marginTop: "1rem", width: "100%" }}>
                <Col sm="2">
                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem" }}>
                        Test User Select:
                    </FormBoot.Label>
                </Col>
                <Col sm="3">
                    <Cascader
                        id="InspectorLocationUser"
                        style={{ width: '100%' }}
                        options={this.state.departmentCascader}
                        value={this.state.values}
                        onChange={this.onDepartmentCascaderChange}
                        onPopupVisibleChange={this.cascaderClose}
                        changeOnSelect
                    />
                </Col>
                <Col sm="3">
                    <Select
                        mode="multiple"
                        labelInValue
                        value={value}
                        placeholder="Select users"
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSearch={this.fetchUser}
                        onChange={this.handleChangeUserSearch}
                        style={{ width: '100%' }}
                        dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider style={{ margin: 0 }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 4 }}>
                                    <a
                                        style={{ flex: 'none', display: 'block' }}
                                        onClick={this.clearAll}
                                    >
                                        {/*<CloseCircleOutlined />*/}
                                        <FormBoot.Label style={{ color: "DodgerBlue", marginLeft: "5px", marginRight: "15px", cursor: 'pointer' }}>
                                            Clear
                                        </FormBoot.Label>
                                    </a>
                                    <a
                                        style={{ flex: 'none', display: 'block' }}
                                        onClick={this.selectAll}
                                    >
                                        {/*<CheckCircleOutlined />*/}
                                        <FormBoot.Label style={{ color: "DodgerBlue", marginLeft: "5px", cursor: 'pointer' }}>
                                            Select All
                                        </FormBoot.Label>
                                    </a>
                                </div>
                            </div>
                        )}
                    >
                        {this.state.inspectors3.map(d => (
                            <Option key={d.value}>{d.text}</Option>
                        ))}
                    </Select>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SearchUser) 
