import React, { forwardRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Form } from "react-bootstrap";
import { Checkbox } from "primereact/checkbox";
import { Radio, Table } from "antd";

export const MNDButton = forwardRef(
         (
           {
             hidden = false,
             btnId,
             style,
             btnClassName,
             onClickFunc,
             btnLabel,
             icon,
           },
           ref
         ) => (
           <Button
             ref={ref}
             hidden={hidden}
             id={btnId}
             style={style}
             className={btnClassName}
             onClick={onClickFunc}
             label={btnLabel}
             icon={icon}
           />
         )
       );

export const MNDInputText = forwardRef(
         ({ hidden = false, value, onChange, placeholder, style }, ref) => (
           <InputText
             ref={ref}
             hidden={hidden}
             onChange={onChange}
             value={value}
             placeholder={placeholder}
             style={style}
           />
         )
       );

export const MNDCalendar = forwardRef(
  (
    { hidden = false, value, onChange, showTime, showSeconds, dateFormat },
    ref
  ) => (
    <Calendar
      ref={ref}
      hidden={hidden}
      value={value}
      onChange={onChange}
      showTime={showTime}
      showSeconds={showSeconds}
      dateFormat={dateFormat}
    />
  )
);

export const MNDDropDown = forwardRef(
         ({ name, value, onChange, options, style }, ref) => (
           <Form.Control
             ref={ref}
             name={name}
             value={value}
             onChange={onChange}
             as="select"
             style={style}
           >
             <option key="0" value="">
               Select
             </option>
             {options}
           </Form.Control>
         )
       );

export const MNDCheckbox = forwardRef(
  ({ hidden = false, onChange, checked, label }, ref) => (
    <div hidden={hidden}>
      <Checkbox ref={ref} onChange={onChange} checked={checked}></Checkbox>
      <label htmlFor="chkItemGroup" className="p-checkbox-label">
        {label}
      </label>
    </div>
  )
);

export const MNDRadioGroup = forwardRef(({ onChange, radios }, ref) => (
  <Radio.Group onChange={onChange}>{radios}</Radio.Group>
));


export const MNDTable = forwardRef(
  ({ hidden = false, columns, dataSource, pagination, state, expandable, scroll }, ref) => (
    <div hidden={hidden}>
       <Table
          {...state}
          columns={columns} dataSource={dataSource}
          pagination={pagination} 
          expandable={expandable} 
          scroll={scroll}
          />
    </div>
  )
);
