/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import MNDTableSettings from '../MND/MNDTableSettings';
import * as Mnd from "../../partials/content/CustomComponents";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Collapse, Button as AntdButton, Input } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Form, Col, Row, Modal, Button as ReactButton } from 'react-bootstrap';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";

const { Panel } = Collapse;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

function Graduation(graduation) {
    if (graduation == 1) {
        return "Elementary School";
    } else if (graduation == 2) {
        return "Middle School";
    } else if (graduation == 3) {
        return "High School";
    } else if (graduation == 4) {
        return "College";
    } else if (graduation == 5) {
        return "University";
    }
    return "";
}

function Degree(degree) {
    if (degree == 1) {
        return "Bachelor (BSc/BA)";
    } else if (degree == 2) {
        return "Master (MSc/MA)";
    } else if (degree == 3) {
        return "Doctorate (PhD)";
    } else if (degree == 4) {
        return "MBA";
    }
    return "";
}

function Language(language) {
    if (language == 1) {
        return "English";
    } else if (language == 2) {
        return "Arabic";
    } else if (language == 3) {
        return "French";
    } else if (language == 4) {
        return "Spanish";
    } else if (language == 5) {
        return "Turkish";
    }
    return "";
}

function LanguageLevel(languageLevel) {
    if (languageLevel == 1) {
        return "Native";
    } else if (languageLevel == 2) {
        return "Fluent";
    } else if (languageLevel == 3) {
        return "Working knowledge";
    } else if (languageLevel == 4) {
        return "Basic";
    }
    return "";
}

function LanguageTest(languageTest) {
    if (languageTest == 1) {
        return "Test1";
    } else if (languageTest == 2) {
        return "Test2";
    } else if (languageTest == 3) {
        return "Test3";
    } else if (languageTest == 4) {
        return "Test4";
    }
    return "";
}

function BankName(bankName) {
    if (bankName == 1) {
        return "Bank1";
    } else if (bankName == 2) {
        return "Bank2";
    } else if (bankName == 3) {
        return "Bank3";
    } else if (bankName == 4) {
        return "Bank4";
    }
    return "";
}

function Branch(branch) {
    if (branch == 1) {
        return "Branch1";
    } else if (branch == 2) {
        return "Branch2";
    } else if (branch == 3) {
        return "Branch3";
    }
    return "";
}

export class PersonelSubLog extends Component {
    constructor() {
        super();
        this.state = {

        };
    };

    componentDidMount = async () => {
        console.log("RRR", this.props);

        var staff = this.props.row;
        if (staff != null && staff != undefined) {
            var staffId = staff.id;
            if (staffId == null || staffId == undefined || staffId == "" || staffId == 0) {
                staffId = -1;
            }
            var personalInfoOverallTable = [];
            var newPersonalInfoOverall = {
                id: staff.id,
                key: staff.id,
                budgeNo: staff.budgeNo,
                companyStaff: staff.companyStaff,
                genderCode: staff.genderCode,
                fatherName: staff.fatherName,
                motherName: staff.motherName,
                placeOfBirth: staff.placeOfBirth,
                birthDate: staff.birthDate,
                nationalIdExpDate: staff.nationalIdExpDate,
                attachIqamaNo: staff.attachIqamaNo,
                drivingLicence: staff.drivingLicence,
                drivingLicenceExpDate: staff.drivingLicenceExpDate,
                attachDrivingLicenceNo: staff.attachDrivingLicenceNo,
                passportNo: staff.passportNo,
                passportExpDate: staff.passportExpDate,
                attachPassportNo: staff.attachPassportNo,
                bloodGroupCode: staff.bloodGroupCode,
                maritalStatusCode: staff.maritalStatusCode,
                numberOfChild: staff.numberOfChild,
                militaryObligationcode: staff.militaryObligationcode,
                smokeStatus: staff.smokeStatus,
            }
            personalInfoOverallTable.push({ data: newPersonalInfoOverall });

            var personalInfoContactTable = [];
            var newPersonalInfoContact = {
                id: staff.id,
                key: staff.id,
                residenceCountry: staff.residenceCountry,
                residenceCity: staff.residenceCity,
                residenceAddress: staff.residenceAddress,
                residenceCompany: staff.residenceCompany,
                residencePersonelPhone: staff.residencePersonelPhone,
                residenceCompanyEmail: staff.residenceCompanyEmail,
                residencePersonalEmail: staff.residencePersonalEmail,
                residenceEmergencyPhone: staff.residenceEmergencyPhone,
                residenceEmergencyEmail: staff.residenceEmergencyEmail,
                homeCountry: staff.homeCountry,
                homeCity: staff.homeCity,
                homeAddress: staff.homeAddress,
                homePersonalPhone: staff.homePersonalPhone,
                homeEmergencyPhone: staff.homeEmergencyPhone,
                homeEmergencyEmail: staff.homeEmergencyEmail,
                socialMediaCode: staff.socialMediaCode,
                socialAccount: staff.socialAccount,
            }
            personalInfoContactTable.push({ data: newPersonalInfoContact });

            this.setState({
                social: staff.social,
                hobbies: staff.hobbies,
                comments: staff.comments,
                healthDescription: staff.healthDescription,
                personalInfoOverallTable: personalInfoOverallTable,
                personalInfoContactTable: personalInfoContactTable,
            });
        } else {
            staffId = -1;
            this.setState({
                social: "",
                hobbies: "",
                comments: "",
                healthDescription: "",
                personalInfoOverallTable: [],
                personalInfoContactTable: [],
            });
        }
        var newObj = {
            staffId: staffId
        }
        const responseStaffTraining = await handleRequest("POST", "/StaffTraining/getStaffTraining", newObj);
        if (responseStaffTraining.data != null && responseStaffTraining.data != undefined && responseStaffTraining.data.length !== 0) {
            responseStaffTraining.data.forEach(element => {
                element.key = element.id;
            });

            this.setState({
                personalInfoTrainingTable: responseStaffTraining.data,
                staffTrainingKayit: responseStaffTraining.data[responseStaffTraining.data.length - 1].id,
            });
        }
        if (responseStaffTraining.data.length === 0) {
            this.setState({
                personalInfoTrainingTable: [],
                staffTrainingKayit: 0,
            });
        }

        const responseStaffContract = await handleRequest("POST", "/StaffContract/getStaffContract", newObj);
        if (responseStaffContract.data != null && responseStaffContract.data != undefined && responseStaffContract.data.length !== 0) {
            responseStaffContract.data.forEach(element => {
                element.key = element.id;
            });

            console.log("TTT", responseStaffContract.data);
            this.setState({
                personalInfoContractTable: responseStaffContract.data,
                staffContractKayit: responseStaffContract.data[responseStaffContract.data.length - 1].id,
            });
        }
        if (responseStaffContract.data.length === 0) {
            this.setState({
                personalInfoContractTable: [],
                staffContractKayit: 0,
            });
        }

        const responseStaffExperiences = await handleRequest("POST", "/StaffExperiences/getStaffExperiences", newObj);
        if (responseStaffExperiences.data != null && responseStaffExperiences.data != undefined && responseStaffExperiences.data.length !== 0) {
            responseStaffExperiences.data.forEach(element => {
                element.key = element.id;
            });
            this.setState({
                personalInfoExperiencesTable: responseStaffExperiences.data,
                staffExperiencesKayit: responseStaffExperiences.data[responseStaffExperiences.data.length - 1].id,
            });
        }
        if (responseStaffExperiences.data.length === 0) {
            this.setState({
                personalInfoExperiencesTable: [],
                staffExperiencesKayit: 0,
            });
        }

        const responseStaffEducation = await handleRequest("POST", "/StaffEducation/getStaffEducation", newObj);
        if (responseStaffEducation.data != null && responseStaffEducation.data != undefined && responseStaffEducation.data.length !== 0) {
            responseStaffEducation.data.forEach(element => {
                element.key = element.id;
            });
            this.setState({
                personalInfoEducationTable: responseStaffEducation.data,
                staffEducationKayit: responseStaffEducation.data[responseStaffEducation.data.length - 1].id,
            });
        }
        if (responseStaffEducation.data.length === 0) {
            this.setState({
                personalInfoEducationTable: [],
                staffEducationKayit: 0,
            });
        }

        const responseStaffLanguageSkills = await handleRequest("POST", "/StaffLanguageSkills/getStaffLanguageSkills", newObj);
        if (responseStaffLanguageSkills.data != null && responseStaffLanguageSkills.data != undefined && responseStaffLanguageSkills.data.length !== 0) {
            responseStaffLanguageSkills.data.forEach(element => {
                element.key = element.id;
            });
            this.setState({
                personalInfoLanguageSkillsTable: responseStaffLanguageSkills.data,
                staffLanguageSkillsKayit: responseStaffLanguageSkills.data[responseStaffLanguageSkills.data.length - 1].id,
            });
        }
        if (responseStaffLanguageSkills.data.length === 0) {
            this.setState({
                personalInfoLanguageSkillsTable: [],
                staffLanguageSkillsKayit: 0,
            });
        }

        const responseStaffBankAccounts = await handleRequest("POST", "/StaffBankAccounts/getStaffBankAccounts", newObj);
        if (responseStaffBankAccounts.data != null && responseStaffBankAccounts.data != undefined && responseStaffBankAccounts.data.length !== 0) {
            responseStaffBankAccounts.data.forEach(element => {
                element.key = element.id;
            });
            this.setState({
                personalInfoBankAccountsTable: responseStaffBankAccounts.data,
                staffBankAccountsKayit: responseStaffBankAccounts.data[responseStaffBankAccounts.data.length - 1].id,
            });
        }
        if (responseStaffBankAccounts.data.length === 0) {
            this.setState({
                personalInfoBankAccountsTable: [],
                staffBankAccountsKayit: 0,
            });
        }

        const responseStaffReferences = await handleRequest("POST", "/StaffReferences/getStaffReferences", newObj);
        if (responseStaffReferences.data != null && responseStaffReferences.data != undefined && responseStaffReferences.data.length !== 0) {
            responseStaffReferences.data.forEach(element => {
                element.key = element.id;
            });
            this.setState({
                personalInfoReferencesTable: responseStaffReferences.data,
                staffReferencesKayit: responseStaffReferences.data[responseStaffReferences.data.length - 1].id,
            });
        }
        if (responseStaffReferences.data.length === 0) {
            this.setState({
                personalInfoReferencesTable: [],
                staffReferencesKayit: 0,
            });
        }
        this.getTableProperties();
    };

    getTableProperties = async () => {
        this.PersonelSubLog = JSON.parse(localStorage.getItem('PersonelSubLog'));
        if (this.PersonelSubLog != null && this.PersonelSubLog != undefined && this.PersonelSubLog != "") {

            await this.setState({
                bordered: this.PersonelSubLog.bordered,
                title: this.PersonelSubLog.titleDrm == true ? title : undefined,
                titleDrm: this.PersonelSubLog.titleDrm,
                showHeader: this.PersonelSubLog.showHeader,
                footer: this.PersonelSubLog.footerDrm == true ? footer : undefined,
                footerDrm: this.PersonelSubLog.footerDrm,
                expandable: this.PersonelSubLog.expandableDrm == true ? expandable : {},
                expandableDrm: this.PersonelSubLog.expandableDrm,
                rowSelection: this.PersonelSubLog.rowSelection,
                ellipsis: this.PersonelSubLog.ellipsis,
                tableLayout: this.PersonelSubLog.tableLayout,
                size: this.PersonelSubLog.size,
                top: this.PersonelSubLog.top,
                bottom: this.PersonelSubLog.bottom,
                editBgColor: this.PersonelSubLog.editBgColor,
                deleteBgColor: this.PersonelSubLog.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.PersonelSubLog.yScroll,
                xScroll: this.PersonelSubLog.xScroll,
                // paginationDisabled: {
                //     defaultPageSize: 5, total: this.state.table.length,
                //     showQuickJumper: true, showSizeChanger: true,
                //     pageSizeOptions: ['5', '10', '25', '50'], total: this.state.table.length, showQuickJumper: true,
                //     position: [this.PersonelSubLog.top, this.PersonelSubLog.bottom]
                // },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
            tableTip: "PersonelSubLog",
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showReport = async (fileRealUrl) => {
        try {
            if (fileRealUrl.endsWith('.pdf')) {
                this.setState({
                    pdfFile: fileRealUrl,
                    modalPdfViewer: true,
                });
            } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
                || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
                this.setState({
                    imageUrl: fileRealUrl,
                    modalPhotoShow: true,
                })
            } else {
                window.location.href = fileRealUrl;
            }
        } catch (error) {
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
        }
    };

    render() {

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const columnsOverall = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBudgeNo" defaultMessage="Budge No" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.budgeNo != null && record.data.budgeNo != undefined) {
                        return record.data.budgeNo;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCompany" defaultMessage="Company" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.companyStaff != null && record.data.companyStaff != undefined) {
                        if (record.data.companyStaff) {
                            return "Company";
                        } else {
                            return "Rental";
                        }
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoGender" defaultMessage="Gender" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.genderCode != null && record.data.genderCode != undefined) {
                        return record.data.genderCode.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoFatherName" defaultMessage="Father Name" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.fatherName != null && record.data.fatherName != undefined) {
                        return record.data.fatherName;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoMotherName" defaultMessage="Mother Name" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.motherName != null && record.data.motherName != undefined) {
                        return record.data.motherName;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPlaceOfBirth" defaultMessage="Place of Birth" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.placeOfBirth != null && record.data.placeOfBirth != undefined) {
                        return record.data.placeOfBirth;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBirthDate" defaultMessage="Birth Date" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.birthDate != null && record.data.birthDate != undefined && record.data.birthDate != "") {
                        return moment(record.data.birthDate, 'DD-MM-YYYY');
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoExpiryDate" defaultMessage="Expiry Date" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.nationalIdExpDate != null && record.data.nationalIdExpDate != undefined && record.data.nationalIdExpDate != "") {
                        return moment(record.data.nationalIdExpDate, 'DD-MM-YYYY');
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAttachId" defaultMessage="Attach ID" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data.attachIqamaNo != null && record.data.attachIqamaNo != undefined && record.data.attachIqamaNo != "") {
                        return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(record.data.attachIqamaNo)} type="primary" shape="round" icon={<DownloadOutlined />} >
                        </AntdButton>
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDrivingLicence" defaultMessage="Driving Licence No" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.drivingLicence != null && record.data.drivingLicence != undefined) {
                        return record.data.drivingLicence;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoExpiryDate" defaultMessage="Expiry Date" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.drivingLicenceExpDate != null && record.data.drivingLicenceExpDate != undefined && record.data.drivingLicenceExpDate != "") {
                        return moment(record.data.drivingLicenceExpDate, 'DD-MM-YYYY');
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAttachPassport" defaultMessage="Attach Passport" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data.attachDrivingLicenceNo != null && record.data.attachDrivingLicenceNo != undefined && record.data.attachDrivingLicenceNo != "") {
                        return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(record.data.attachDrivingLicenceNo)} type="primary" shape="round" icon={<DownloadOutlined />} >
                        </AntdButton>
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPassportNo" defaultMessage="Passport No" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.passportNo != null && record.data.passportNo != undefined) {
                        return record.data.passportNo;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoExpiryDate" defaultMessage="Expiry Date" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.passportExpDate != null && record.data.passportExpDate != undefined && record.data.passportExpDate != "") {
                        return moment(record.data.passportExpDate, 'DD-MM-YYYY');
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAttachPassport" defaultMessage="Attach Passport" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data.attachPassportNo != null && record.data.attachPassportNo != undefined && record.data.attachPassportNo != "") {
                        return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(record.data.attachPassportNo)} type="primary" shape="round" icon={<DownloadOutlined />} >
                        </AntdButton>
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBloodGroup" defaultMessage="Blood Group" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.bloodGroupCode != null && record.data.bloodGroupCode != undefined) {
                        return record.data.bloodGroupCode.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoMaritalStatus" defaultMessage="Marital Status" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.maritalStatusCode != null && record.data.maritalStatusCode != undefined) {
                        return record.data.maritalStatusCode.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoNumberOfChild" defaultMessage="Number of Child" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.numberOfChild != null && record.data.numberOfChild != undefined) {
                        return record.data.numberOfChild;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoMilitaryObligation" defaultMessage="Military Obligation" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.militaryObligationcode != null && record.data.militaryObligationcode != undefined) {
                        return record.data.militaryObligationcode.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoSmokingStatus" defaultMessage="Smoking Status" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.smokeStatusCode != null && record.data.smokeStatusCode != undefined) {
                        return record.data.smokeStatusCode.name;
                    }
                    return "";
                }
            },

        ];

        const tableColumnsOverall = columnsOverall.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsOverall[0].fixed = true;
            tableColumnsOverall[tableColumnsOverall.length - 1].fixed = 'right';
        }

        const columnsContract = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoContractDate" defaultMessage="Contract Date" />,
                render: (data) => {
                    if (data != null && data != undefined && data.contractDate != null && data.contractDate != undefined && data.contractDate != "") {
                        return moment(data.contractDate).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "contractDuration",
                key: "key",
                title: <FormattedMessage id="PersonalInfoContractDuration" defaultMessage="Contract Duration" />,
            },
            {
                dataIndex: "annualVacationDays",
                key: "key",
                title: <FormattedMessage id="PersonalInfoAnnualVacationDays" defaultMessage="Annual Vacation Days" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAnnualVacationPeriod" defaultMessage="Annual Vacation Period" />,
                render: (data) => {
                    if (data != null && data != undefined && data.annualVacationPeriodType != null && data.annualVacationPeriodType != undefined && data.annualVacationPeriodType != "") {
                        if (data.annualVacationPeriodType == 1) {
                            return data.annualVacationPeriod + " Year"
                        } else if (data.annualVacationPeriodType == 2) {
                            return data.annualVacationPeriod + " Month"
                        }
                    }
                    return "";
                }
            },
            {
                dataIndex: "basicSalary",
                key: "key",
                title: <FormattedMessage id="PersonalInfoBasicSalary" defaultMessage="Basic Salary" />,
            },
            {
                dataIndex: "homeAllowance",
                key: "key",
                title: <FormattedMessage id="PersonalInfoHomeAllowance" defaultMessage="Home Allowance" />,
            },
            {
                dataIndex: "transportationAllowance",
                key: "key",
                title: <FormattedMessage id="PersonalInfoTransportationAllowance" defaultMessage="Transportation Allowance" />,
            },
            {
                dataIndex: "foodAllowance",
                key: "key",
                title: <FormattedMessage id="PersonalInfoFoodAllowance" defaultMessage="Food Allowance" />,
            },
            {
                dataIndex: "otherPayments",
                key: "key",
                title: <FormattedMessage id="PersonalInfoOtherPayments" defaultMessage="Other Payments" />,
            },
        ];


        const tableColumnsContract = columnsContract.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsContract[0].fixed = true;
            tableColumnsContract[tableColumnsContract.length - 1].fixed = 'right';
        }

        const columnsContact = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCountry" defaultMessage="Country" />,
                render: (record) => {
                    console.log("ABCD", record);
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceCountry != null && record.data.residenceCountry != undefined) {
                        return record.data.residenceCountry;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCity" defaultMessage="City" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceCity != null && record.data.residenceCity != undefined) {
                        return record.data.residenceCity;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAddress" defaultMessage="Address" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceAddress != null && record.data.residenceAddress != undefined) {
                        return record.data.residenceAddress;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCompany" defaultMessage="Company" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceCompany != null && record.data.residenceCompany != undefined) {
                        return record.data.residenceCompany;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPhone" defaultMessage="Phone" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residencePersonelPhone != null && record.data.residencePersonelPhone != undefined) {
                        return record.data.residencePersonelPhone;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCompanyEmail" defaultMessage="Company Email" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceCompanyEmail != null && record.data.residenceCompanyEmail != undefined) {
                        return record.data.residenceCompanyEmail;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPersonelEmail" defaultMessage="Personel Email" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residencePersonalEmail != null && record.data.residencePersonalEmail != undefined) {
                        return record.data.residencePersonalEmail;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoEmergencyPhone" defaultMessage="Emergency Phone" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceEmergencyPhone != null && record.data.residenceEmergencyPhone != undefined) {
                        return record.data.residenceEmergencyPhone;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoEmergencyEmail" defaultMessage="Emergency Email" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.residenceEmergencyEmail != null && record.data.residenceEmergencyEmail != undefined) {
                        return record.data.residenceEmergencyEmail;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCountry" defaultMessage="Country" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.homeCountry != null && record.data.homeCountry != undefined) {
                        return record.data.homeCountry;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCity" defaultMessage="City" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.homeCity != null && record.data.homeCity != undefined) {
                        return record.data.homeCity;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAddress" defaultMessage="Address" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.homeAddress != null && record.data.homeAddress != undefined) {
                        return record.data.homeAddress;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPhone" defaultMessage="Phone" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.homePersonalPhone != null && record.data.homePersonalPhone != undefined) {
                        return record.data.homePersonalPhone;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoEmergencyPhone" defaultMessage="Emergency Phone" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.homeEmergencyPhone != null && record.data.homeEmergencyPhone != undefined) {
                        return record.data.homeEmergencyPhone;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoEmergencyEmail" defaultMessage="Emergency Email" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.homeEmergencyEmail != null && record.data.homeEmergencyEmail != undefined) {
                        return record.data.homeEmergencyEmail;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoSocialMedia" defaultMessage="Social Media" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.socialMediaCode != null && record.data.socialMediaCode != undefined) {
                        return record.data.socialMediaCode.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoSocialMediaAccounts" defaultMessage="Social Media Accounts" />,
                render: (record) => {
                    if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.socialAccount != null && record.data.socialAccount != undefined) {
                        return record.data.socialAccount;
                    }
                    return "";
                }
            },
        ];

        const tableColumnsContact = columnsContact.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsContact[0].fixed = true;
            tableColumnsContact[tableColumnsContact.length - 1].fixed = 'right';
        }

        const columnsTraining = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDate" defaultMessage="Date" />,
                render: (data) => {
                    if (data != null && data != undefined && data.dateOfTrainingBsl != null && data.dateOfTrainingBsl != undefined && data.dateOfTrainingBsl != "" && data.dateOfTrainingBts != null && data.dateOfTrainingBts != undefined && data.dateOfTrainingBts != "") {
                        return moment(data.dateOfTrainingBsl).format("DD-MM-YYYY") + "/" + moment(data.dateOfTrainingBts).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "nameOfTraining",
                key: "key",
                title: <FormattedMessage id="PersonalInfoTraining" defaultMessage="Training" />,
            },
            {
                dataIndex: "organizer",
                key: "key",
                title: <FormattedMessage id="PersonalInfoOrganizer" defaultMessage="Organizer" />,
            },
            {
                dataIndex: "certificateValidity",
                key: "key",
                title: <FormattedMessage id="PersonalInfoCertificateValidity" defaultMessage="Certificate Validity" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAttachTrainingCertificate" defaultMessage="Attach Training Certificate" />,
                render: (data) => {
                    if (data != null && data != undefined && data.attachTrainingCertificate != null && data.attachTrainingCertificate != undefined && data.attachTrainingCertificate != "") {
                        return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(data.attachTrainingCertificate)} type="primary" shape="round" icon={<DownloadOutlined />} >
                        </AntdButton>
                    }
                    return "";
                }
            },
        ];

        const tableColumnsTraining = columnsTraining.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsTraining[0].fixed = true;
            tableColumnsTraining[tableColumnsTraining.length - 1].fixed = 'right';
        }

        const columnsExperiences = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoWorkPeriod" defaultMessage="Work Period" />,
                render: (data) => {
                    if (data != null && data != undefined && data.workPeriodStart != null && data.workPeriodStart != undefined && data.workPeriodStart != "" && data.workPeriodEnd != null && data.workPeriodEnd != undefined && data.workPeriodEnd != "") {
                        return moment(data.workPeriodStart).format("DD-MM-YYYY") + "/" + moment(data.workPeriodEnd).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "expCompany",
                key: "key",
                title: <FormattedMessage id="PersonalInfoCompany" defaultMessage="Company" />,
            },
            {
                dataIndex: "expPosition",
                key: "key",
                title: <FormattedMessage id="PersonalInfoPosition" defaultMessage="Position" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCountryCity" defaultMessage="Country/City" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.expCountry != null && data.expCountry != undefined && data.expCountry != "" && data.expCity != null && data.expCity != undefined && data.expCity != "") {
                            return data.expCountry + "/" + data.expCity;
                        } else if (data.expCountry != null && data.expCountry != undefined && data.expCountry != "") {
                            return data.expCountry;
                        } else if (data.expCity != null && data.expCity != undefined && data.expCity != "") {
                            return data.expCity;
                        }
                    }
                    return "";
                }
            },
            {
                dataIndex: "expProject",
                key: "key",
                title: <FormattedMessage id="PersonalInfoProject" defaultMessage="Project" />,
            },
            {
                dataIndex: "expClient",
                key: "key",
                title: <FormattedMessage id="PersonalInfoClient" defaultMessage="Client" />,
            },
        ];

        const tableColumnsExperiences = columnsExperiences.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsExperiences[0].fixed = true;
            tableColumnsExperiences[tableColumnsExperiences.length - 1].fixed = 'right';
        }

        const columnsEducation = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDateOfGraduation" defaultMessage="Date of Graduation" />,
                render: (data) => {
                    if (data != null && data != undefined && data.dateOfGraduation != null && data.dateOfGraduation != undefined && data.dateOfGraduation != "") {
                        return moment(data.dateOfGraduation).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "nameOfSchool",
                key: "key",
                title: <FormattedMessage id="PersonalInfoSchool" defaultMessage="School" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoGraduation" defaultMessage="Graduation" />,
                render: (data) => {
                    if (data != null && data != undefined && data.graduation != null && data.graduation != undefined && data.graduation != "" && data.graduation != 0) {
                        return Graduation(data.graduation);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDegree" defaultMessage="Degree" />,
                render: (data) => {
                    if (data != null && data != undefined && data.degree != null && data.degree != undefined && data.degree != "" && data.degree != 0) {
                        return Degree(data.degree);
                    }
                    return "";
                }
            },
            {
                dataIndex: "eduCountry",
                key: "key",
                title: <FormattedMessage id="PersonalInfoEduCountry" defaultMessage="Country" />,
            },
        ];

        const tableColumnsEducation = columnsEducation.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsEducation[0].fixed = true;
            tableColumnsEducation[tableColumnsEducation.length - 1].fixed = 'right';
        }

        const columnsLanguageSkills = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoLanguage" defaultMessage="Language" />,
                render: (data) => {
                    if (data != null && data != undefined && data.language != null && data.language != undefined && data.language != "" && data.language != 0) {
                        return Language(data.language);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoUnderstanding" defaultMessage="Understanding" />,
                render: (data) => {
                    if (data != null && data != undefined && data.understanding != null && data.understanding != undefined && data.understanding != "" && data.understanding != 0) {
                        return LanguageLevel(data.understanding);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoReading" defaultMessage="Reading" />,
                render: (data) => {
                    if (data != null && data != undefined && data.reading != null && data.reading != undefined && data.reading != "" && data.reading != 0) {
                        return LanguageLevel(data.reading);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoWriting" defaultMessage="Writing" />,
                render: (data) => {
                    if (data != null && data != undefined && data.writing != null && data.writing != undefined && data.writing != "" && data.writing != 0) {
                        return LanguageLevel(data.writing);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoTest" defaultMessage="Test" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.internationalLanguageTest != null && data.internationalLanguageTest != undefined && data.internationalLanguageTest != "" && data.score != null && data.score != undefined && data.score != "") {
                            return LanguageTest(data.internationalLanguageTest) + "/" + data.score;
                        } else if (data.internationalLanguageTest != null && data.internationalLanguageTest != undefined && data.internationalLanguageTest != "") {
                            return LanguageTest(data.internationalLanguageTest);
                        } else if (data.score != null && data.score != undefined && data.score != "") {
                            return data.score;
                        }
                    }
                    return "";
                }
            },
        ];

        const tableColumnsLanguageSkills = columnsLanguageSkills.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsLanguageSkills[0].fixed = true;
            tableColumnsLanguageSkills[tableColumnsLanguageSkills.length - 1].fixed = 'right';
        }

        const columnsBankAccounts = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAccountType" defaultMessage="Account Type" />,
                render: (data) => {
                    if (data != null && data != undefined && data.accountType != null && data.accountType != undefined && data.accountType != "" && data.accountType != 0) {
                        return data.accountType;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBank" defaultMessage="Bank" />,
                render: (data) => {
                    if (data != null && data != undefined && data.bankName != null && data.bankName != undefined && data.bankName != "" && data.bankName != 0) {
                        return BankName(data.bankName);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBranch" defaultMessage="Branch" />,
                render: (data) => {
                    if (data != null && data != undefined && data.branch != null && data.branch != undefined && data.branch != "" && data.branch != 0) {
                        return Branch(data.branch);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAccountNo" defaultMessage="Account No" />,
                render: (data) => {
                    if (data != null && data != undefined && data.accountNo != null && data.accountNo != undefined && data.accountNo != "" && data.accountNo != 0) {
                        return data.accountNo;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoIBAN" defaultMessage="IBAN" />,
                render: (data) => {
                    if (data != null && data != undefined && data.ibanNo != null && data.ibanNo != undefined && data.ibanNo != "" && data.ibanNo != 0) {
                        return data.ibanNo;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCutOff" defaultMessage="Cut Off" />,
                render: (data) => {
                    if (data != null && data != undefined && data.cutOffDate != null && data.cutOffDate != undefined && data.cutOffDate != "") {
                        return moment(data.cutOffDate).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPayment" defaultMessage="Payment" />,
                render: (data) => {
                    if (data != null && data != undefined && data.paymentDate != null && data.paymentDate != undefined && data.paymentDate != "") {
                        return moment(data.paymentDate).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "status",
                key: "key",
                title: <FormattedMessage id="PersonalInfoStatus" defaultMessage="Status" />,
            },
        ];

        const tableColumnsBankAccounts = columnsBankAccounts.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsBankAccounts[0].fixed = true;
            tableColumnsBankAccounts[tableColumnsBankAccounts.length - 1].fixed = 'right';
        }

        const columnsReferences = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefName" defaultMessage="Name" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refName != null && data.refName != undefined && data.refName != "" && data.refName != 0) {
                        return data.refName;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefJobDescription" defaultMessage="Job Description" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refjobDescription != null && data.refjobDescription != undefined && data.refjobDescription != "" && data.refjobDescription != 0) {
                        return data.refjobDescription;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefCompany" defaultMessage="Company" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refCompany != null && data.refCompany != undefined && data.refCompany != "" && data.refCompany != 0) {
                        return data.refCompany;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefCountry" defaultMessage="Country" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refCountry != null && data.refCountry != undefined && data.refCountry != "" && data.refCountry != 0) {
                        return data.refCountry;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefEmail" defaultMessage="E-mail" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refEmail != null && data.refEmail != undefined && data.refEmail != "" && data.refEmail != 0) {
                        return data.refEmail;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefPhone" defaultMessage="Phone" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refPhone != null && data.refPhone != undefined && data.refPhone != "" && data.refPhone != 0) {
                        return data.refPhone;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefRelation" defaultMessage="Relation" />,
                render: (data) => {
                    if (data != null && data != undefined && data.codeRelation != null && data.codeRelation != undefined) {
                        return data.codeRelation.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefRemarks" defaultMessage="Remarks" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refRemarks != null && data.refRemarks != undefined && data.refRemarks != "" && data.refRemarks != 0) {
                        return data.refRemarks;
                    }
                    return "";
                }
            },

        ];

        const tableColumnsReferences = columnsReferences.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsReferences[0].fixed = true;
            tableColumnsReferences[tableColumnsReferences.length - 1].fixed = 'right';
        }

        return (
            <div style={{ padding: "15px" }}>
                <Collapse expandIconPosition={this.state.expandIconPosition}>
                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogOverall" defaultMessage="Overall" />} key="1"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsOverall} dataSource={this.state.personalInfoOverallTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogContact" defaultMessage="Contact" />} key="2"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsContact} dataSource={this.state.personalInfoContactTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogTraining" defaultMessage="Training" />} key="3"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsTraining} dataSource={this.state.personalInfoTrainingTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogContract" defaultMessage="Contract" />} key="4"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsContract} dataSource={this.state.personalInfoContractTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogExperience" defaultMessage="Experience" />} key="5"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsExperiences} dataSource={this.state.personalInfoExperiencesTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogEducation" defaultMessage="Education" />} key="6"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsEducation} dataSource={this.state.personalInfoEducationTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogLanguageSkills" defaultMessage="Language Skills" />} key="7"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsLanguageSkills} dataSource={this.state.personalInfoLanguageSkillsTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogBankAccount" defaultMessage="Bank Account" />} key="8"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsBankAccounts} dataSource={this.state.personalInfoBankAccountsTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogSocialHobbies" defaultMessage="Social Hobbies" />} key="9"  >
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                            <Col sm="2">
                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                    <FormattedMessage id="PersonalInfoSocial" defaultMessage="Social: " />
                                </Form.Label>
                            </Col>
                            <Col sm="4">
                                <Input style={{ width: '100%' }} value={this.state.social} readOnly={true} />
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                            <Col sm="2">
                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                    <FormattedMessage id="PersonalInfoHobbies" defaultMessage="Hobbies: " />
                                </Form.Label>
                            </Col>
                            <Col sm="4">
                                <Input style={{ width: '100%' }} value={this.state.hobbies} readOnly={true} />
                            </Col>
                        </Form.Group>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogHealth" defaultMessage="Health" />} key="10"  >
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                            <Col sm="2">
                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                    <FormattedMessage id="PersonalInfoHealth" defaultMessage="Health: " />
                                </Form.Label>
                            </Col>
                            <Col sm="6">
                                <Form.Control as="textarea" rows="4"
                                    ref='myTextarea' value={this.state.healthDescription} readOnly={true} />
                            </Col>
                        </Form.Group>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogReferences" defaultMessage="References" />} key="11"  >
                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumnsReferences} dataSource={this.state.personalInfoReferencesTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="PersonelSubLogComments" defaultMessage="Comments" />} key="12"  >
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                            <Col sm="2">
                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                    <FormattedMessage id="PersonalInfoComments" defaultMessage="Comments: " />
                                </Form.Label>
                            </Col>
                            <Col sm="6">
                                <Form.Control as="textarea" rows="4" readOnly={true} ref='myTextarea' value={this.state.comments} />
                            </Col>
                        </Form.Group>
                    </Panel>

                </Collapse>


                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id={this.state.tableTip} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.imageUrl !== "" ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                            />
                        ) : (
                                <p>Photo has not been uploaded.</p>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="info" onClick={this.showOrHidePhotoModal}>
                            Close
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = () => {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonelSubLog)