/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";
import { ToggleButton } from 'primereact/togglebutton';
import "react-toggle/style.css"; 
import { fetchCustomerLogs } from "../../redux/actions/customerLogAction"; 
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';  
import { Calendar } from 'primereact/calendar'; 

class ChecklistLogExpand extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      hidden: true, 
      tablehidden:false,
      workpermitreqCheck:true,
      
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",

      butonbirDesc:"Add New",
      butonbirVariant: "info", 
      butonikiDesc:"Add New",
      butonikiVariant: "info",
      butonucDesc:"Add New",
      butonucVariant: "info",

      Checklists:[],
      itemsChecklistItems:[],
      itemsEquipments:[],

      checklistKayitKismiGoster:true,
      equipmentKayitKismiGoster:true,
      checkitemKayitKismiGoster:true,

      iclistno:"",
      iclist:"",
      revno:"",
      approvaldate:null,
      referencedoc:"",
      layoutdrawnumber:"",
      workpermitreqCheck:true,


      equipmentType:"",
      equipmentName:"",
      equipmentCode:"",
      equipmentIdNumber:"",

      checklistItemsitemno:"",
      checklistItemsAcceptanceCriteria:"", 
      checklistItemsReference:"",

      gelenID:"",

    };

    //this.addChecklist = this.addChecklist.bind(this);
     
  }

  componentDidMount = async () => {
    console.log(this.props.row);
    console.log('PPP',this.props.row);
    await this.setState({ 
      gelenID:this.props.row.id,
      Checklists:this.props.row,
      itemsEquipments:this.props.row.equip,
      itemsChecklistItems:this.props.row.checkitem
    });

    console.log("OOO");
    console.log(this.state.Checklists);
    this.getItpChecklistItems();
  } 

  getItpChecklistItems = async () => { 
    const newObj = {
      itpcheckid: this.props.row.id
    }     
    const responseTree = await handleRequest("POST", "/CheckListLog/getTreeItpchecklistitems", newObj);

    if (responseTree.data.length !== 0) {
        this.setState({
          itemsChecklistItems: responseTree.data.treeTable,
        });
    }else{
        this.setState({
          itemsChecklistItems: [],
        });
    }  
}
  
  addAllCheckList = async() => {
    
    const { Checklists } = this.state;  
     

    const prm = { id:0, itpname:"", checklist:Checklists };
    console.log(prm)
   const response = await handleRequest("POST", "/CheckListLog/add", prm);

    if(response.data.includes('Successfull'))
    {
       this.toast.show({ severity: 'success', summary: ' Message', detail:response.data });
    }
    
     
  }


  butonbirClick = () => {
    if (this.state.butonbirDesc === "Add New") {
      this.setState({ checklistKayitKismiGoster:false,  butonbirDesc: "Cancel",  butonbirVariant: "danger"  });   }
    else {  this.setState({   checklistKayitKismiGoster:true,  butonbirDesc: "Add New",    butonbirVariant: "info"  });  } 
  }

  butonikiClick = () => {
    if (this.state.butonikiDesc === "Add New") {
      this.setState({ equipmentKayitKismiGoster:false,  butonikiDesc: "Cancel",  butonikiVariant: "danger"  });   }
    else {  this.setState({   equipmentKayitKismiGoster:true,  butonikiDesc: "Add New",    butonikiVariant: "info"  });  } 
  }

  butonikiSkip = () => {
   
    document.getElementById('equipmentAccordion').className = "collapse "; 
    document.getElementById('cheklistitemsAccordion').className = "collapse show"; 
  }

  butonucClick = () => {
    if (this.state.butonucDesc === "Add New") {
      this.setState({ checkitemKayitKismiGoster:false,  butonucDesc: "Cancel",  butonucVariant: "danger"  });   }
    else {  this.setState({   checkitemKayitKismiGoster:true,  butonucDesc: "Add New",    butonucVariant: "info"  });  } 
  }

  createButtonClick = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false, 
        tablehidden:true,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
      this.refreshAccordions();
    }
    else {
      this.setState({
        hidden: true, 
         tablehidden:false,
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info"
      });
      this.refreshAccordions();
    }


  }

  
  refreshAccordions = () => {
    
     
      document.getElementById('cheklistAccordion').className = "collapse show"; 
      document.getElementById('equipmentAccordion').className = "collapse "; 
      document.getElementById('cheklistitemsAccordion').className = "collapse ";  
 }

 handleChange = e => { 
    
  e.preventDefault();
  this.setState({
    [e.target.name]: e.target.value
  });
};

setDateFormat(d) { //01/02/2011 ->2011-02-01
  if(d!=null)
  {var dt = new Date(d);
  return ('' +dt.getFullYear().toString().padStart(4, '0')+'-'   +(dt.getMonth()+1).toString().padStart(2, '0') +'-' +dt.getDate().toString().padStart(2, '0')   ) ;
  }else return'';
  }


 addChecklist=()=>{

  var flag= true;
  const { Checklists } = this.state;
  if(Checklists.length>0) flag= true;

  for (let index = 0; index < Checklists.length; index++) {
    
     if(Checklists[index].checkitem.length>0){ flag= true;}else {flag= false;break;}
    
  }

  if(flag)
  {
  this.setState(state => ({
    Checklists:[...state.Checklists, { 
      id:this.state.gelenID,
      iclistno:this.state.iclistno ,
      iclist:this.state.iclist , 
      revno:this.state.revno ,
      approvaldate:this.setDateFormat( this.state.approvaldate ),
      referencedoc: this.state.referencedoc , 
      workpermitreq: this.state.workpermitreqCheck ? 'true' : 'false',
      layoutdrawnumber: this.state.layoutdrawnumber ,
      equip:[],
      checkitem:[]
     }]
    }));
    this.setState({itemsEquipments:[] ,itemsChecklistItems:[]});

    document.getElementById('cheklistAccordion').className = "collapse "; 
    document.getElementById('equipmentAccordion').className = "collapse show "; 
    this.butonbirClick();
   }
   else {
     
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please Add Check List İtems' });
     document.getElementById('cheklistAccordion').className = "collapse"; 
     document.getElementById('equipmentAccordion').className = "collapse "; 
     document.getElementById('cheklistitemsAccordion').className = "collapse show";  
   }

 }
  
 addEquipment=()=>{ 
                    
      const { Checklists } = this.state;
      const i=Checklists.length;
          if(i>0)
          {
          const itemsEquipments={ equipmentSnno:Checklists[i-1].equip.length+1,
            equipmentType:this.state.equipmentType ,
            equipmentName:this.state.equipmentName , 
            equipmentCode:this.state.equipmentCode ,
            equipmentIdNumber: this.state.equipmentIdNumber  } ;


            Checklists[i-1].equip.push(itemsEquipments);
            this.setState({itemsEquipments:Checklists[i-1].equip });
          } 
      this.setState({Checklists:Checklists  });

          console.log(Checklists);
      

          document.getElementById('equipmentAccordion').className = "collapse "; 
          document.getElementById('cheklistitemsAccordion').className = "collapse show "; 
          this.butonikiClick();
 } 

 addCheckListItems=()=>{ 

      const { Checklists } = this.state;
      const i=Checklists.length;
          if(i>0)
          {
             const itemsChecklistItems={  
            checklistItemsitemno:this.state.checklistItemsitemno ,
            checklistItemsAcceptanceCriteria:this.state.checklistItemsAcceptanceCriteria , 
            checklistItemsReference:this.state.checklistItemsReference   } ;


            Checklists[i-1].checkitem.push(itemsChecklistItems);
            this.setState({itemsChecklistItems:Checklists[i-1].checkitem });
          } 
      this.setState({Checklists:Checklists  });

      console.log(Checklists);


 }  

  render() {
   

    return (
      <div> 

        <div className="col-md-12"> 
        <div style={{ backgroundColor: "white", padding: "10px" }}   hidden={this.state.hidden}> 
                <Row > 
                  <Col sm="12" md={{ size: 2, offset: 10 }}><Button onClick={this.createButtonClick} style={{ width: '100%' }}
                   variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                </Row>

        </div>
        <br /> 
        <Toast ref={(el) => this.toast = el}></Toast>  
        <div  >
          <Accordion defaultActiveKey="1">
            <Card>
            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                 <Row>
                    <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                     as="h5">&#x25bc; Check List</Card.Header></Col>
                    </Row>
               </Accordion.Toggle> 
              <Accordion.Collapse id="cheklistAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row  hidden={true}> 
                      <Col sm="12" md={{ size: 2, offset: 10 }}>
                        <Button onClick={this.butonbirClick} style={{ width: '100%' }}
                       variant={this.state.butonbirVariant} >{this.state.butonbirDesc}</Button></Col>
                   
                    </Row>
                    <br /> 
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>I&C List No</th>
                          <th>I&C List</th>
                          <th>Rev. No</th>
                          <th>Approval Date</th>
                          <th>Reference Doc.</th>
                          <th>Layout Drawing Number</th>
                          <th>Work Permit Required</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                      {this.state.Checklists.length!=0 ? 
                        this.state.Checklists.map((checklist, index) =>
                          <tr key={index}>
                            <td>{checklist.iclistno}</td>
                            <td>{checklist.iclist}</td>
                            <td>{checklist.revno}</td>
                            <td>{checklist.approvaldate}</td>
                            <td>{checklist.referencedoc}</td>
                            <td>{checklist.layoutdrawnumber}</td>
                            <td>{checklist.workpermitreq}</td>
                          </tr>
                        ) : ""}
                      </tbody>
                    </Table>
                    <br />
                    <div hidden={this.state.checklistKayitKismiGoster}>
                    
                    <Form.Row>

                      <Form.Group as={Col} controlId="formGridiclistno"> 
                          <Form.Label style={{ color: 'black' }}>I&C List No</Form.Label> 
                        <Form.Control name="iclistno" value={this.state.iclistno}
                          onChange={this.handleChange} />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridiclist"> 
                          <Form.Label style={{ color: 'black' }}>I&C List Desc.</Form.Label> 
                        <Form.Control name="iclist" value={this.state.iclist}
                          onChange={this.handleChange} />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridrevno"> 
                          <Form.Label style={{ color: 'black' }}>Rev. No</Form.Label> 
                        <Form.Control name="revno" value={this.state.revno}
                          onChange={this.handleChange} />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridapprovaldate"> 
                          <Form.Label style={{ color: 'black' }} >Approval Date</Form.Label>  
                      <Col sm="10">
                      <Calendar dateFormat="dd/mm/yy" value={this.state.approvaldate} showButtonBar={true} style={{width: '200px'}}
                       onChange={(e) => this.setState({ approvaldate: e.value })} monthNavigator={true}  autocomplete="on"
                       yearNavigator={true} yearRange="2010:2020" />
                       </Col>  
                      </Form.Group>
 
                    </Form.Row>

                    <Form.Row> 

                      <Form.Group as={Col} controlId="formGridreferencedoc"> 
                          <Form.Label style={{ color: 'black' }} >Reference Doc.</Form.Label> 
                        <Form.Control name="referencedoc" value={this.state.referencedoc}
                          onChange={this.handleChange} as="select">
                          <option>Select</option>
                          <option>Add New</option>
                          <option>Head Office</option>
                          <option>Branche</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridlayoutdrawnumber"> 
                          <Form.Label style={{ color: 'black' }} >Layout Drawing Number</Form.Label> 
                        <Form.Control name="layoutdrawnumber" value={this.state.layoutdrawnumber}
                          onChange={this.handleChange} as="select">
                          <option>Select</option>
                          <option>Add New</option>
                          <option>Head Office</option>
                          <option>Branche</option>
                        </Form.Control>
                      </Form.Group> 

                      <Form.Group as={Col} controlId="formGridworkpermitreqCheck"> 
                          <Form.Label style={{ color: 'black' }} >Work Permit Required</Form.Label> 
                        <ToggleButton style={{ width: '200px'  }} onLabel="Yes" offLabel="No" onIcon="pi pi-user" offIcon="pi pi-user" 
                          checked={this.state.workpermitreqCheck} onChange={(e) => this.setState({ workpermitreqCheck: e.value })} />
                      </Form.Group>

                     

                      <Form.Group as={Col} controlId="formGridlayoutdrawnumber"> 
                          <Form.Label style={{ color: 'black' }} >&nbsp;</Form.Label> 
                          <Button onClick={this.addChecklist} style={{ width: '100%' }} variant="success">Save</Button>
                      </Form.Group> 
                      

                      </Form.Row>
                    </div>

                    </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        <br />
          <Accordion defaultActiveKey="2">
            <Card>
            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                    <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Equipment</Card.Header></Col>
                    </Row>
              </Accordion.Toggle> 
              <Accordion.Collapse id="equipmentAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row hidden={true}>
                        <Col xs={8}></Col>
                      <Col xs={2}><Button onClick={this.butonikiSkip} style={{ width: '100%' }}
                        variant="outline-dark" >Skip</Button></Col>
                      <Col xs={2}><Button onClick={this.butonikiClick} style={{ width: '100%' }}
                            variant={this.state.butonikiVariant} >{this.state.butonikiDesc}</Button></Col>
                        
                    </Row>
                  </Container>
                  <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th width={80}>S/N</th>
                        <th>Equipment Type</th>
                        <th>Equipment Name</th>
                        <th>Equipment Code</th>
                        <th>Equipment ID Number(s)</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.itemsEquipments.map(itemsEquipment => (
                        <tr key={itemsEquipment.equipmentLength}>
                          <td>{itemsEquipment.equipmentSnno}</td>
                          <td>{itemsEquipment.equipmentType}</td>
                          <td>{itemsEquipment.equipmentName}</td>
                          <td>{itemsEquipment.equipmentCode}</td>
                          <td>{itemsEquipment.equipmentIdNumber}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                  <div hidden={this.state.equipmentKayitKismiGoster}> 
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridequipmentType">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Equipment Type</Form.Label>
                      </div>
                      <Form.Control name="equipmentType" value={this.state.equipmentType}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Head Office</option>
                        <option>Branche</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridequipmentName">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Equipment Name</Form.Label>
                      </div>
                      <Form.Control name="equipmentName" value={this.state.equipmentName}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Head Office</option>
                        <option>Branche</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridequipmentCode">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Equipment Code</Form.Label>
                      </div>
                      <Form.Control name="equipmentCode" value={this.state.equipmentCode}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Head Office</option>
                        <option>Branche</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridequipmentIdNumber">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Equipment ID Number(s)</Form.Label>
                      </div>
                      <Form.Control name="equipmentIdNumber" value={this.state.equipmentIdNumber}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Head Office</option>
                        <option>Branche</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridlayoutdrawnumber"> 
                          <Form.Label style={{ color: 'black' }} >&nbsp;</Form.Label> 
                          <Button onClick={this.addEquipment} style={{ width: '100%' }}  variant="success">Save</Button>
                      </Form.Group> 
                  </Form.Row>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="3">
            <Card>
            <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                    <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Check List Items</Card.Header></Col>
                    </Row>
              </Accordion.Toggle> 
              <Accordion.Collapse id="cheklistitemsAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row hidden={true}>
                    <Col sm="12" md={{ size: 2, offset: 10 }}><Button onClick={this.butonucClick} style={{ width: '100%' }}
                      variant={this.state.butonucVariant} >{this.state.butonucDesc}</Button></Col> 
                    </Row>
                  </Container>
                 
                  <TreeTable value={this.state.itemsChecklistItems}>
                        <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="ItpCheckListItems Id" />
                        <Column field="checklistItemsitemnoAck" header="Item No." expander></Column> 
                        <Column field="checklistItemsAcceptanceCriteria" header="Acceptance Criteria" filter sortable></Column>
                        <Column field="checklistItemsReference" header="Reference" filter sortable></Column> 
                    </TreeTable>
                    
                  <br />
                  <div hidden={this.state.checkitemKayitKismiGoster}>
                  <Form.Row>

                    <Form.Group as={Col} controlId="formGridchecklistItemsitemno">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Item No</Form.Label>
                      </div>
                      <Form.Control name="checklistItemsitemno" value={this.state.checklistItemsitemno}
                        onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridchecklistItemsAcceptanceCriteria">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Acceptance Criteria</Form.Label>
                      </div>
                      <Form.Control name="checklistItemsAcceptanceCriteria" value={this.state.checklistItemsAcceptanceCriteria}
                        onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridchecklistItemsReference">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Reference</Form.Label>
                      </div>
                      <Form.Control name="checklistItemsReference" value={this.state.checklistItemsReference}
                        onChange={this.handleChange} />
                    </Form.Group>
                    
                    <Form.Group as={Col} controlId="formGridlayoutdrawnumber"> 
                          <Form.Label style={{ color: 'black' }} >&nbsp;</Form.Label> 
                          <Button onClick={this.addCheckListItems} style={{ width: '100%' }}  variant="success">Save</Button>
                      </Form.Group> 

                  </Form.Row>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Container hidden={true} style={{ maxWidth: 'none', marginBottom: '15px' }}>
            <Row>
              <Button onClick={this.addAllCheckList} style={{ width: '100%' }} variant="success">{this.state.lastButton}</Button>
            </Row>
            </Container>
          <br />
           
          
        </div>

      </div>
    
      <br /> 
      <br /> 
      </div> 
    )
  }
}

const mapStateToProps = ({ customerLogReducer }) => {
  return {
    customerLogReducer
  }
}

const mapDispatchToProps = {
  fetchCustomerLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistLogExpand)
