/* eslint-disable */


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import { Button as PrimeButton } from "primereact/button";
import moment from 'moment';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form as FormBoot, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { PlusOutlined, MinusOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';

const { Column } = Table;
const { Option } = Select;
const pageName = "EmployeeRequest";

export class EmployeeRequest extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            modalDeleteShowQualifications: false,
            modalDeleteShowScoreSetting: false,
            hideInputs: true,
            hiddenQualifications: true,
            hideInputsQualifications: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            //[STATE_FIELDS],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0,
            EmployeeRequestContractTypes: [],
            hiringSteps: [],
            employeeHiringSteps: [],
            departments: [],
            disciplines: [],
            designations: [],
            qualificationsTable: [],
            qualificationsKayit: 0,
            scoreSettingsTable: [],
            scoreSettingsKayit: 0,
        };

        this.actionTemplate = this.actionTemplate.bind(this);
        this.actionTemplateQualifications = this.actionTemplateQualifications.bind(this);
        this.actionTemplateScoreSetting = this.actionTemplateScoreSetting.bind(this);

    }

    formRef = React.createRef();
    formRefQualifications = React.createRef();
    formRefScoreSetting = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.getDepartment();
        this.getDiscipline();
        this.getDesignation();
        this.getHiringStep();
        this.restartTable();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }


    getHiringStep = async () => {
        const response = await handleRequest("GET", "/HiringStep/getAllByCompany");
        if (response.data.length === 0) {
            this.setState({
                hiringSteps: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                hiringSteps: response.data,
            });
        }
    }

    getQualifications = async (employeeRequestId) => {
        var newObj = {
            employeeRequestId: employeeRequestId
        }
        const response = await handleRequest("POST", "/EmployeeRequestQualification/getEmployeeRequestQualification", newObj);
        if (response.data.length === 0) {
            this.setState({
                qualificationsTable: [],
                qualificationsKayit: 0,
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                qualificationsTable: response.data,
                qualificationsKayit: response.data[response.data.length - 1].id,
            });
        }
    }

    getScoreSettings = async (employeeRequestId) => {
        var newObj = {
            employeeRequestId: employeeRequestId
        }
        const response = await handleRequest("POST", "/EmployeeRequestScoreSetting/getEmployeeRequestScoreSetting", newObj);
        if (response.data.length === 0) {
            this.setState({
                scoreSettingsTable: [],
                scoreSettingsKayit: 0,
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                scoreSettingsTable: response.data,
                scoreSettingsKayit: response.data[response.data.length - 1].id,
            });
        }
    }

    getDepartment = async () => {
        const response = await handleRequest("GET", "/Department/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                departments: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                departments: response.data,
            });
        }
    }

    getDiscipline = async () => {
        const response = await handleRequest("GET", "/Discipline/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                disciplines: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                disciplines: response.data,
            });
        }
    }

    getDesignation = async () => {
        const response = await handleRequest("GET", "/Designation/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                designations: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                designations: response.data,
            });
        }
    }

    refreshHiringSteps = async () => {
        const { departmentId, disciplineId, designationId, hiringSteps, initializeHiringSteps } = this.state;
        var employeeHiringSteps = [];
        if (Boolean(departmentId) && Boolean(disciplineId) && Boolean(designationId)) {
            let obj = {
                departmentId: departmentId,
                disciplineId: disciplineId,
                designationId: designationId
            }
            const response = await handleRequest("POST", "/EmployeeHiring/getEmployeeHiring", obj);
            if (Boolean(response.data) && response.data.length > 0) {
                const list = response.data;
                const eh = list[0];
                if (Boolean(eh.employeeHiringSteps) && eh.employeeHiringSteps.length > 0) {
                    eh.employeeHiringSteps.forEach(element => {
                        if (Boolean(element.hiringStep) && element.hiringStep.hasEvaluation) {
                            element.hiringStep.hiddenHiringStep = true;
                            element.hiringStep.hideInputsScoreSetting = true;
                            element.hiringStep.hideSaveScoreSetting = true;
                            element.hiringStep.hideUpdateScoreSetting = true;
                            element.hiringStep.hiringStepId = element.hiringStepId;
                            employeeHiringSteps.push(element.hiringStep);
                        }
                    });
                }
            }
        }
        this.setState({
            employeeHiringSteps: employeeHiringSteps
        });
    }


    save = async () => {
        const obj = {
            id: this.state.employeeRequestId,
            departmentId: this.state.departmentId,
            disciplineId: this.state.disciplineId,
            designationId: this.state.designationId,
            numberOfRequestedPerson: this.state.numberOfRequestedPerson,
            dateToJoin: this.state.dateToJoin,
            duration: this.state.duration,
            durationType: this.state.durationType,
            contractTypeId: this.state.contractTypeId,
            qualifications: this.state.qualificationsTable,
            scoreSettings: this.state.scoreSettingsTable,
        };
        var response = [];
        if (!(Boolean(obj.id) && obj.id > 0))
            response = await handleRequest("POST", "/EmployeeRequest/add", obj);
        else {
            response = await handleRequest("POST", "/EmployeeRequest/update", obj);
        }

        if (Boolean(response) && Boolean(response.data)) {
            if (response.data === "SUCCESS") {
                this.showSuccess();
                this.resetInputs();
                this.restartTable();
                this.stop();
            }
            else {
                this.showError();
            }
        }
    }

    edit = async (row) => {
        var employeeRequestId = -1;
        if (Boolean(row) && Boolean(row.id)) {
            employeeRequestId = row.id;
        }

        this.formRef.current.setFieldsValue({
            departmentId: row.departmentId,
            disciplineId: row.disciplineId,
            designationId: row.designationId,
            numberOfRequestedPerson: row.numberOfRequestedPerson,
            dateToJoin: Boolean(row.dateToJoin) ? moment(row.dateToJoin) : null,
            duration: row.duration,
            durationType: row.durationType,
            contractTypeId: row.contractTypeId,
        });

        await this.setState({
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            employeeRequestId: employeeRequestId,
            departmentId: row.departmentId,
            disciplineId: row.disciplineId,
            designationId: row.designationId,
            numberOfRequestedPerson: row.numberOfRequestedPerson,
            dateToJoin: Boolean(row.dateToJoin) ? moment(row.dateToJoin) : null,
            duration: row.duration,
            durationType: row.durationType,
            contractTypeId: row.contractTypeId,
        });

        this.getQualifications(employeeRequestId);
        this.getScoreSettings(employeeRequestId);
        this.refreshHiringSteps();
    }

    delete = async () => {
        if (Boolean(this.state.id) && this.state.id > 0) {
            const obj = {
                id: this.state.id
            }

            await handleRequest("POST", "/EmployeeRequest/delete", obj);

            this.restartTable();
            this.showSuccess();
            this.showOrHideDeleteModal();
        } else {
            this.showWarning();
        }
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    saveScoreSetting = async (hiringStepId) => {
        if (Boolean(this.state.employeeRequestQualificationId) && this.state.employeeRequestQualificationId > 0) {
            if (!(Boolean(this.state.employeeRequestScoreSettingId) && this.state.employeeRequestScoreSettingId > 0)) {
                await this.setState({
                    scoreSettingsKayit: this.state.scoreSettingsKayit + 1,
                })
                var answerFormat = {
                    name: this.state.answerFormat,
                }
                var expectedAnswer = {
                    name: this.state.expectedAnswer,
                }
                var newScoreSettings = {
                    id: this.state.scoreSettingsKayit,
                    key: this.state.scoreSettingsKayit,
                    index: this.state.scoreSettingsKayit,
                    employeeRequestQualificationId: this.state.employeeRequestQualificationId,
                    hiringStepId: hiringStepId,
                    answerFormatId: this.state.answerFormatId,
                    expectedAnswerId: this.state.expectedAnswerId,
                    compulsory: this.state.compulsory,
                    linkedQuestionId: this.state.linkedQuestionId,
                    score: this.state.score,
                    item: this.state.itemCodeItemDescription,
                    answerFormat: answerFormat,
                    expectedAnswer: expectedAnswer,
                };

                this.setState(prevState => ({
                    scoreSettingsTable: [...prevState.scoreSettingsTable, newScoreSettings]
                }))
            } else {
                var answerFormat = {
                    name: this.state.answerFormat,
                }
                var expectedAnswer = {
                    name: this.state.expectedAnswer,
                }
                var ScoreSetting = this.state.scoreSettingsTable.find(p => p.id == this.state.employeeRequestScoreSettingId);

                ScoreSetting.employeeRequestQualificationId = this.state.employeeRequestQualificationId;
                ScoreSetting.hiringStepId = hiringStepId;
                ScoreSetting.answerFormatId = this.state.answerFormatId;
                ScoreSetting.expectedAnswerId = this.state.expectedAnswerId;
                ScoreSetting.compulsory = this.state.compulsory;
                ScoreSetting.linkedQuestionId = this.state.linkedQuestionId;
                ScoreSetting.score = this.state.score;
                ScoreSetting.item = this.state.itemCodeItemDescription;
                ScoreSetting.answerFormat = answerFormat;
                ScoreSetting.expectedAnswer = expectedAnswer;
            }
            this.resetInputsScoreSetting();
        } else {
            this.showWarning("Please select Item");
        }
    }

    editScoreSetting = (row) => {
        var answerFormat = "";
        var expectedAnswer = "";

        if (Boolean(row.answerFormat)) {
            answerFormat = row.answerFormat.name;
        }

        if (Boolean(row.expectedAnswer)) {
            expectedAnswer = row.expectedAnswer.name;
        }

        this.formRefScoreSetting.current.setFieldsValue({
            employeeRequestQualificationId: row.employeeRequestQualificationId,
            answerFormatId: row.answerFormatId,
            expectedAnswerId: row.expectedAnswerId,
            compulsory: row.compulsory,
            score: row.score,
            linkedQuestionId: row.linkedQuestionId,
        });

        var step = this.state.employeeHiringSteps.find(p => p.id == row.hiringStepId);
        if (Boolean(step)) {
            step.hideSaveScoreSetting = true;
            step.hideUpdateScoreSetting = false;
            step.hideInputsScoreSetting = false;
        }

        this.setState({
            employeeRequestScoreSettingId: row.id,
            employeeRequestQualificationId: row.employeeRequestQualificationId,
            answerFormatId: row.answerFormatId,
            expectedAnswerId: row.expectedAnswerId,
            compulsory: row.compulsory,
            score: row.score,
            linkedQuestionId: row.linkedQuestionId,
            itemCodeItemDescription: row.item,
            answerFormat: answerFormat,
            expectedAnswer: expectedAnswer,
        });
    }

    deleteScoreSetting = async () => {
        if (Boolean(this.state.employeeRequestScoreSettingId) && this.state.employeeRequestScoreSettingId > 0) {
            var ScoreSetting = this.state.scoreSettingsTable.filter(p => p.id != this.state.employeeRequestScoreSettingId);
            this.setState({
                scoreSettingsTable: ScoreSetting,
                modalDeleteShowScoreSetting: false,
            });
        }
        else {
            this.showWarning();
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteModalScoreSetting = (row) => {
        this.setState({
            modalDeleteShowScoreSetting: true,
            employeeRequestScoreSettingId: row.id
        });
    }

    showOrHideDeleteModalScoreSetting = () => {
        this.setState({
            modalDeleteShowScoreSetting: !this.state.modalDeleteShowScoreSetting
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideDeleteModalQualifications = () => {
        this.setState({
            modalDeleteShowQualifications: !this.state.modalDeleteShowQualifications
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    saveQualifications = async () => {
        if (!(Boolean(this.state.requestQualificationId) && this.state.requestQualificationId > 0)) {
            await this.setState({
                qualificationsKayit: this.state.qualificationsKayit + 1,
            })
            var newQualifications = {
                id: this.state.qualificationsKayit,
                key: this.state.qualificationsKayit,
                index: this.state.qualificationsKayit,
                roleDocumentItemId: null,
                itemCode: this.state.itemCode,
                itemDescription: this.state.itemDescription,
            };

            this.setState(prevState => ({
                qualificationsTable: [...prevState.qualificationsTable, newQualifications]
            }))
        } else {
            var Qualifications = this.state.qualificationsTable.find(p => p.id == this.state.requestQualificationId);

            Qualifications.itemCode = this.state.itemCode;
            Qualifications.itemDescription = this.state.itemDescription;
        }
        this.stopQualifications();
    }

    // saveQualifications = async () => {
    //     var obj = {
    //         id: this.state.requestQualificationId,
    //         itemCode: this.state.itemCode,
    //         itemDescription: this.state.itemDescription,
    //     };

    //     var response = [];
    //     if (!(Boolean(obj.id) && obj.id > 0))
    //         response = await handleRequest("POST", "/EmployeeRequestQualification/add", obj);
    //     else {
    //         response = await handleRequest("POST", "/EmployeeRequestQualification/update", obj);
    //     }

    //     if (Boolean(response) && Boolean(response.data)) {
    //         if (response.data === "SUCCESS") {
    //             this.stopQualifications();
    //             this.getQualifications();
    //         }
    //         else {
    //             this.showError();
    //         }
    //     }
    // }

    editQualifications = (row) => {
        this.formRefQualifications.current.setFieldsValue({
            itemCode: row.itemCode,
            itemDescription: row.itemDescription,
        });

        this.setState({
            hideSaveQualifications: true,
            hideUpdateQualifications: false,
            hideInputsQualifications: false,
            requestQualificationId: row.id,
            itemCode: row.itemCode,
            itemDescription: row.itemDescription,
        });
    }

    deleteQualifications = async () => {
        if (Boolean(this.state.requestQualificationId) && this.state.requestQualificationId > 0) {
            var Qualifications = this.state.qualificationsTable.filter(p => p.id != this.state.requestQualificationId);
            this.setState({
                qualificationsTable: Qualifications,
                modalDeleteShowQualifications: false,
            });
        }
        else {
            this.showWarning();
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteModalQualifications = (row) => {
        this.setState({
            modalDeleteShowQualifications: true,
            requestQualificationId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShowQualifications: !this.state.modalDeleteShowQualifications
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;
    }

    actionTemplateScoreSetting(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModalScoreSetting.bind(this)} onEditRow={this.editScoreSetting.bind(this)} />
        </React.Fragment>;
    }

    actionTemplateQualifications(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModalQualifications.bind(this)} onEditRow={this.editQualifications.bind(this)} />
        </React.Fragment>;
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/EmployeeRequest/getAllByCompany");
        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }

        this.getCodesEmployeeRequestContractType();
        this.getCodesEmployeeRequestCodeanswerFormatId();
        this.getCodesEmployeeRequestCodeexpectedAnswerId();
        this.getCodesEmployeeRequestCodelinkedQuestion();
    }

    createNew = () => {
        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();
    }

    stop = () => {
        this.setState({
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();
    }

    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            departmentId: "",
            disciplineId: "",
            designationId: "",
            numberOfRequestedPerson: "",
            dateToJoin: "",
            duration: "",
            durationType: "",
            contractTypeId: "",
        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,

            employeeRequestId: -1,
            departmentId: "",
            disciplineId: "",
            designationId: "",
            numberOfRequestedPerson: "",
            dateToJoin: "",
            duration: "",
            durationType: "",
            contractTypeId: "",

            hiddenQualifications: true,
            qualificationsTable: [],
            scoreSettingsTable: [],
        });

        this.refreshHiringSteps();
    }

    cancel = () => {
        this.stop();
    }

    createNewScoreSetting = (step) => {
        step.hideInputsScoreSetting = !step.hideInputsScoreSetting;
        step.hideSaveScoreSetting = false;
        step.hideUpdateScoreSetting = true;

        this.setState({
            modalDeleteShowScoreSetting: false,
            employeeHiringSteps: this.state.employeeHiringSteps
        });
        this.resetInputsScoreSetting();
    }

    stopScoreSetting = (step) => {
        step.hideInputsScoreSetting = true;
        step.hideSaveScoreSetting = false;
        step.hideUpdateScoreSetting = true;

        this.setState({
            modalDeleteShowScoreSetting: false,
        });
        this.resetInputsScoreSetting();
    }

    resetInputsScoreSetting = () => {
        this.formRefScoreSetting.current.setFieldsValue({
            employeeRequestQualificationId: "",
            answerFormatId: "",
            expectedAnswerId: "",
            compulsory: false,
            score: "",
            linkedQuestionId: "",
        });

        this.setState({
            modalDeleteShowScoreSetting: false,
            hideUpdateScoreSetting: true,

            employeeRequestQualificationId: "",
            answerFormatId: "",
            expectedAnswerId: "",
            compulsory: false,
            score: "",
            linkedQuestionId: "",
            employeeRequestScoreSettingId: -1,
        });
    }

    cancelScoreSetting = () => {
        this.resetInputsScoreSetting();
    }

    createNewQualifications = () => {
        this.setState({
            hideInputsQualifications: !this.state.hideInputsQualifications,
            modalDeleteShowQualifications: false,
            hideSaveQualifications: false,
            hideUpdateQualifications: true,
        });
        this.resetInputsQualifications();
    }

    stopQualifications = () => {
        this.setState({
            modalDeleteShowQualifications: false,
            hideInputsQualifications: true,
            hideSaveQualifications: false,
            hideUpdateQualifications: true,
        });
        this.resetInputsQualifications();

    }

    resetInputsQualifications = () => {
        this.formRefQualifications.current.setFieldsValue({
            itemCode: "",
            itemDescription: "",
        });

        this.setState({
            modalDeleteShowQualifications: false,
            hideSaveQualifications: false,
            hideUpdateQualifications: true,
            requestQualificationId: -1,
            itemCode: "",
            itemDescription: "",
        });
    }

    cancelQualifications = () => {
        this.stopQualifications();
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeEmployeeRequestContractType = (e) => {
        this.setState({
            contractTypeId: e,
        });
    }

    addEmployeeRequestContractTypes = async () => {
        const { itemEmployeeRequestContractTypes } = this.state;
        if (Boolean(itemEmployeeRequestContractTypes)) {
            const newItem = {
                name: itemEmployeeRequestContractTypes,
                type: "EmployeeRequestContractType"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemEmployeeRequestContractTypes: '',
            });
            this.getCodesEmployeeRequestContractType();
        } else {
            this.showWarning();
        }
    }

    getCodesEmployeeRequestContractType = async () => {
        const response = await handleRequest("GET", "/Code/type/EmployeeRequestContractType");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                EmployeeRequestContractTypes: response.data
            });
        }
    }

    addEmployeeRequestScoreSetting = async (step) => {
        step.hiddenHiringStep = !step.hiddenHiringStep;

        this.setState({
            employeeHiringSteps: this.state.employeeHiringSteps
        });
    };

    addEmployeeRequestQualifications = async () => {
        this.setState({
            hiddenQualifications: !this.state.hiddenQualifications
        });
    };

    handleChangeEmployeeRequestCodeanswerFormatId = (e) => {
        this.setState({
            answerFormatId: e,
        });
    }

    addEmployeeRequestCodeanswerFormatIds = async () => {
        const { itemEmployeeRequestCodeanswerFormatIds } = this.state;
        if (Boolean(itemEmployeeRequestCodeanswerFormatIds)) {
            const newItem = {
                name: itemEmployeeRequestCodeanswerFormatIds,
                type: "EmployeeRequestCodeanswerFormatId"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemEmployeeRequestCodeanswerFormatIds: '',
            });
            this.getCodesEmployeeRequestCodeanswerFormatId();
        } else {
            this.showWarning();
        }
    }

    getCodesEmployeeRequestCodeanswerFormatId = async () => {
        const response = await handleRequest("GET", "/Code/type/EmployeeRequestCodeanswerFormatId");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                EmployeeRequestCodeanswerFormatIds: response.data
            });
        }
    }

    handleChangeEmployeeRequestCodeexpectedAnswerId = (e) => {
        this.setState({
            expectedAnswerId: e,
        });
    }

    addEmployeeRequestCodeexpectedAnswerIds = async () => {
        const { itemEmployeeRequestCodeexpectedAnswerIds } = this.state;
        if (Boolean(itemEmployeeRequestCodeexpectedAnswerIds)) {
            const newItem = {
                name: itemEmployeeRequestCodeexpectedAnswerIds,
                type: "EmployeeRequestCodeexpectedAnswerId"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemEmployeeRequestCodeexpectedAnswerIds: '',
            });
            this.getCodesEmployeeRequestCodeexpectedAnswerId();
        } else {
            this.showWarning();
        }
    }

    getCodesEmployeeRequestCodeexpectedAnswerId = async () => {
        const response = await handleRequest("GET", "/Code/type/EmployeeRequestCodeexpectedAnswerId");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                EmployeeRequestCodeexpectedAnswerIds: response.data
            });
        }
    }


    handleChangeEmployeeRequestCodelinkedQuestion = (e) => {
        this.setState({
            linkedQuestionId: e,
        });
    }

    addEmployeeRequestCodelinkedQuestions = async () => {
        const { itemEmployeeRequestCodelinkedQuestions } = this.state;
        if (Boolean(itemEmployeeRequestCodelinkedQuestions)) {
            const newItem = {
                name: itemEmployeeRequestCodelinkedQuestions,
                type: "EmployeeRequestCodelinkedQuestion"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemEmployeeRequestCodelinkedQuestions: '',
            });
            this.getCodesEmployeeRequestCodelinkedQuestion();
        } else {
            this.showWarning();
        }
    }

    getCodesEmployeeRequestCodelinkedQuestion = async () => {
        const response = await handleRequest("GET", "/Code/type/EmployeeRequestCodelinkedQuestion");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                EmployeeRequestCodelinkedQuestions: response.data
            });
        }
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const columns = [
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EmployeeRequest")} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestDepartment" defaultMessage="Department" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.department)) {
                        return data.department.departmentName;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestDiscipline" defaultMessage="Discipline" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.discipline)) {
                        return data.discipline.disciplineName;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestDesignation" defaultMessage="Designation" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.designation)) {
                        return data.designation.designationName;
                    }
                    return "";
                }
            },
            {
                dataIndex: "numberOfRequestedPerson",
                key: "key",
                title: <FormattedMessage id="EmployeeRequestNumberOfRequestedPerson" defaultMessage="Number of Requested Person" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestDateToJoin" defaultMessage="Date to join" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.dateToJoin)) {
                        return moment(data.dateToJoin).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestDurationOfHiring" defaultMessage="Duration of hiring" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.duration) && Boolean(data.durationType)) {
                        return data.duration + " " + data.durationType;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestContractType" defaultMessage="Contract type" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.contractType)) {
                        return data.contractType.name;
                    }
                    return "";
                }
            },

        ];

        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const columnsScoreSetting = [
            {
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EmployeeRequest")} icon="pi pi-cog"></PrimeButton>,
                render: this.actionTemplateScoreSetting
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestItem" defaultMessage="Item" />,
                render: (data) => {
                    if (Boolean(data)) {
                        return data.item;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestAnswers" defaultMessage="Answers" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.answerFormat)) {
                        return data.answerFormat.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="EmployeeRequestExpectedAnswer" defaultMessage="Expected Answer" />,
                render: (data) => {
                    if (Boolean(data) && Boolean(data.expectedAnswer)) {
                        return data.expectedAnswer.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "score",
                key: "key",
                title: <FormattedMessage id="EmployeeRequestScore" defaultMessage="Score" />,
            },
        ];

        const tableColumnsScoreSetting = columnsScoreSetting.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsScoreSetting[0].fixed = true;
            tableColumnsScoreSetting[tableColumnsScoreSetting.length - 1].fixed = 'right';
        }

        const columnsQualifications = [
            {
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EmployeeRequest")} icon="pi pi-cog"></PrimeButton>,
                render: this.actionTemplateQualifications
            },
            {
                dataIndex: "itemCode",
                key: "key",
                title: <FormattedMessage id="EmployeeRequestItemNo" defaultMessage="Item no" />,
            },
            {
                dataIndex: "itemDescription",
                key: "key",
                title: <FormattedMessage id="EmployeeRequestItem" defaultMessage="Item" />,
            },
        ];

        const tableColumnsQualifications = columnsQualifications.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsQualifications[0].fixed = true;
            tableColumnsQualifications[tableColumnsQualifications.length - 1].fixed = 'right';
        }

        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>
                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Department").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Department" defaultMessage="Department" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="departmentId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Department")) ? this.state.dynamicInputs.find(p => p.inputKey === "Department").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Department")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Department").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Department" id="Department" value={this.state.departmentId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                departmentId: value
                                                            });
                                                            this.setState({ departmentId: value }, this.refreshHiringSteps)
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.departments.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Discipline" defaultMessage="Discipline" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="disciplineId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Discipline")) ? this.state.dynamicInputs.find(p => p.inputKey === "Discipline").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Discipline")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Discipline").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Discipline" id="Discipline" value={this.state.disciplineId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                disciplineId: value
                                                            });
                                                            this.setState({ disciplineId: value }, this.refreshHiringSteps)
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.disciplines.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Designation").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Designation" defaultMessage="Designation" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="designationId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Designation")) ? this.state.dynamicInputs.find(p => p.inputKey === "Designation").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Designation")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Designation").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Designation" id="Designation" value={this.state.designationId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                designationId: value
                                                            });
                                                            this.setState({ designationId: value }, this.refreshHiringSteps)
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.designations.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.designationName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NumberOfRequestedPerson").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="NumberOfRequestedPerson" defaultMessage="Number of requested person" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="numberOfRequestedPerson"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "NumberOfRequestedPerson")) ? this.state.dynamicInputs.find(p => p.inputKey === "NumberOfRequestedPerson").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "NumberOfRequestedPerson")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "NumberOfRequestedPerson").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="NumberOfRequestedPerson" min={1} value={this.state.numberOfRequestedPerson} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                numberOfRequestedPerson: value
                                                            });
                                                            this.setState({ numberOfRequestedPerson: value })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DateToJoin").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DateToJoin" defaultMessage="Date to join" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="dateToJoin"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DateToJoin")) ? this.state.dynamicInputs.find(p => p.inputKey === "DateToJoin").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DateToJoin")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DateToJoin").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="DateToJoin" format="DD-MM-YYYY" value={this.state.dateToJoin} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                dateToJoin: date
                                                            });
                                                            this.setState({
                                                                dateToJoin: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DurationOfHiring").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="DurationOfHiring" defaultMessage="Duration of hiring" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="duration"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring")) ? this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DurationOfHiring").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="duration"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring")) ? this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiring").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <InputNumber id="DurationOfHiring" min={0} value={this.state.duration} onChange={(value) => {
                                                                    this.formRef.current.setFieldsValue({
                                                                        duration: value
                                                                    });
                                                                    this.setState({ duration: value })
                                                                }} />
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>

                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DurationOfHiringType").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="durationType"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiringType")) ? this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiringType").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiringType")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DurationOfHiringType").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <Select style={{ width: "100%" }} placeholder="Duration of hiring type" id="DurationOfHiringType" value={this.state.durationType} onChange={(value) => {
                                                                    this.formRef.current.setFieldsValue({
                                                                        durationType: value
                                                                    });
                                                                    this.setState({ durationType: value })
                                                                }}>
                                                                    <Option key={null} value={null}>Select</Option>
                                                                    <Option key="Day" value="Day">Day</Option>
                                                                    <Option key="Week" value="Week">Week</Option>
                                                                    <Option key="Month" value="Month">Month</Option>
                                                                    <Option key="Year" value="Year">Year</Option>
                                                                </Select>
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ContractType").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ContractType" defaultMessage="Contract type" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="contractTypeId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ContractType")) ? this.state.dynamicInputs.find(p => p.inputKey === "ContractType").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ContractType")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ContractType").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Contract type"
                                                            value={this.state.contractTypeId} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    contractTypeId: value
                                                                });
                                                                this.setState({
                                                                    contractTypeId: value
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.itemEmployeeRequestContractTypes}
                                                                            onChange={(event) => { this.setState({ itemEmployeeRequestContractTypes: event.target.value }) }} />
                                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addEmployeeRequestContractTypes} >
                                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.EmployeeRequestContractTypes.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }

                                        <br />

                                        <FormAnt
                                            {...layout}
                                            initialValues={{ remember: false }}
                                            onFinish={this.saveQualifications}
                                            onFinishFailed={onFinishFailed}
                                            ref={this.formRefQualifications} >
                                            <div>
                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="1"></Col>
                                                    <Col sm="1">
                                                        <AntButton type="primary" hidden={!this.state.hiddenQualifications} onClick={() => this.addEmployeeRequestQualifications()} icon={<PlusOutlined />}></AntButton>
                                                        <AntButton type="primary" hidden={this.state.hiddenQualifications} onClick={() => this.addEmployeeRequestQualifications()} danger icon={<MinusOutlined />}></AntButton>
                                                    </Col>
                                                    <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                                                        Qualifications
                                                    </FormBoot.Label>
                                                </FormBoot.Group>

                                                <div hidden={this.state.hiddenQualifications}>

                                                    <div>
                                                        <Row>
                                                            <Col sm="10">
                                                            </Col>
                                                            <Col sm="2">
                                                                {this.state.hideInputsQualifications &&
                                                                    <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={() => this.createNewQualifications(this.state)}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                                                }

                                                                {!this.state.hideInputsQualifications &&
                                                                    <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={() => this.stopQualifications(this.state)}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                                                }

                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div hidden={this.state.hideInputsQualifications} style={{ marginBottom: '2rem' }}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "QualificationsItemCode").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="ItemNoItemNo" defaultMessage="Item No" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="itemCode"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemCode")) ? this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemCode").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemCode")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemCode").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={12}>
                                                                        <Input style={{ width: '100%' }} value={this.state.itemCode} onChange={(e) => this.setState({ itemCode: e.target.value })} />
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                        }

                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "QualificationsItemDescription").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="QualificationsItemDescription" defaultMessage="Item" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="itemDescription"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemDescription")) ? this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemDescription").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemDescription")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "QualificationsItemDescription").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={12}>
                                                                        <Input style={{ width: '100%' }} value={this.state.itemDescription} onChange={(e) => this.setState({ itemDescription: e.target.value })} />
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                        }

                                                        <div hidden={this.state.hideSaveQualifications}>
                                                            {
                                                                <RowAnt gutter={8}>
                                                                    <ColAnt span={8}></ColAnt>

                                                                    <ColAnt span={8}>
                                                                        {
                                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                                        }
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            }
                                                        </div>

                                                        <div hidden={this.state.hideUpdateQualifications}>
                                                            {
                                                                <Row>
                                                                    <Col sm="3">
                                                                    </Col>
                                                                    <Col sm="2">
                                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancelQualifications}>
                                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                                    </Col>
                                                                    <Col sm="3">
                                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                                    </Col>
                                                                    <Col sm="3">
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>

                                                    </div>

                                                    <div className="datatable-doc-demo">
                                                        <div className="content-section implementation">
                                                            <Mnd.MNDTable
                                                                state={this.state}
                                                                columns={tableColumnsQualifications} dataSource={this.state.qualificationsTable}
                                                                pagination={this.state.paginationDisabled}
                                                                expandable={this.state.expandable}
                                                                footer={this.state.footer}
                                                                title={this.state.title}
                                                                scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </FormAnt>

                                        {this.state.employeeHiringSteps.map((step) =>
                                            <FormAnt
                                                {...layout}
                                                initialValues={{ remember: false }}
                                                onFinish={() => this.saveScoreSetting(step.hiringStepId)}
                                                onFinishFailed={onFinishFailed}
                                                ref={this.formRefScoreSetting}>

                                                <div>
                                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                        <Col sm="1"></Col>
                                                        <Col sm="1">
                                                            <AntButton type="primary" hidden={!step.hiddenHiringStep} onClick={() => this.addEmployeeRequestScoreSetting(step)} icon={<PlusOutlined />}></AntButton>
                                                            <AntButton type="primary" hidden={step.hiddenHiringStep} onClick={() => this.addEmployeeRequestScoreSetting(step)} danger icon={<MinusOutlined />}></AntButton>
                                                        </Col>
                                                        <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                                                            {step.stepName}
                                                        </FormBoot.Label>
                                                    </FormBoot.Group>

                                                    <div hidden={step.hiddenHiringStep}>
                                                        <div>
                                                            <Row>
                                                                <Col sm="10">
                                                                </Col>
                                                                <Col sm="2">
                                                                    {step.hideInputsScoreSetting &&
                                                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={() => this.createNewScoreSetting(step)}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                                                    }

                                                                    {!step.hideInputsScoreSetting &&
                                                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={() => this.stopScoreSetting(step)}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div hidden={step.hideInputsScoreSetting} style={{ marginBottom: '2rem' }}>
                                                            {/* {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemNoItem").visible) || this.state.dynamicAttributes.length === 0) && */}
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="ItemNoItem" defaultMessage="Item" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                // name={`employeeRequestQualificationId${step.hiringStepId}`}
                                                                // name="employeeRequestQualificationId"
                                                                // name={[step.hiringStepId, 'employeeRequestQualificationId']}
                                                                // fieldKey={[step.hiringStepId, 'employeeRequestQualificationId']}
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ItemNoItem")) ? this.state.dynamicInputs.find(p => p.inputKey === "ItemNoItem").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ItemNoItem")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ItemNoItem").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={12}>
                                                                        <Select style={{ width: "100%" }} placeholder="Item" id="ItemNoItem" value={this.state.employeeRequestQualificationId} onChange={(value) => {
                                                                            var itemCodeItemDescription = "";
                                                                            var data = this.state.qualificationsTable.find(p => p.id == value);
                                                                            if (Boolean(data)) {
                                                                                itemCodeItemDescription = data.itemCode + "/" + data.itemDescription;
                                                                            }
                                                                            this.formRefScoreSetting.current.setFieldsValue({
                                                                                employeeRequestQualificationId: value
                                                                            });
                                                                            this.setState({
                                                                                employeeRequestQualificationId: value,
                                                                                itemCodeItemDescription: itemCodeItemDescription,
                                                                            })
                                                                        }}>
                                                                            <Option key={null} value={null}>Select</Option>
                                                                            {this.state.qualificationsTable.map(i => (
                                                                                <Option key={i.id} value={i.id}>{i.itemCode + "/" + i.itemDescription}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                            {/* } */}

                                                            {/* {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AnswerFormat").visible) || this.state.dynamicAttributes.length === 0) && */}
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="AnswerFormat" defaultMessage="Answer format" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                // name="answerFormatId"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "AnswerFormat")) ? this.state.dynamicInputs.find(p => p.inputKey === "AnswerFormat").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "AnswerFormat")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "AnswerFormat").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={12}>
                                                                        <Select
                                                                            style={{ width: '100%' }}
                                                                            placeholder="Answer format"
                                                                            value={this.state.answerFormatId} onChange={(value) => {
                                                                                var answerFormat = "";
                                                                                var answer = this.state.EmployeeRequestCodeanswerFormatIds.find(p => p.id == value);
                                                                                if (Boolean(answer)) {
                                                                                    answerFormat = answer.name;
                                                                                }
                                                                                this.formRefScoreSetting.current.setFieldsValue({
                                                                                    answerFormatId: value
                                                                                });
                                                                                this.setState({
                                                                                    answerFormatId: value,
                                                                                    answerFormat: answerFormat,
                                                                                })
                                                                            }}


                                                                            dropdownRender={menu => (
                                                                                <div>
                                                                                    {menu}
                                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                        <Input style={{ flex: 'auto' }} value={this.state.itemEmployeeRequestCodeanswerFormatIds} onChange={(event) => {
                                                                                            this.setState({
                                                                                                itemEmployeeRequestCodeanswerFormatIds: event.target.value,
                                                                                            });
                                                                                        }} />
                                                                                        <a
                                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                            onClick={this.addEmployeeRequestCodeanswerFormatIds}
                                                                                        >
                                                                                            <PlusOutlined /> Add item
                   </a>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        >
                                                                            <Option key={null} value={null}>Select</Option>
                                                                            {this.state.EmployeeRequestCodeanswerFormatIds.map(i => (
                                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                            {/* } */}

                                                            {/* {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ExpectedAnswer").visible) || this.state.dynamicAttributes.length === 0) && */}
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="ExpectedAnswer" defaultMessage="Expected answer" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                // name="expectedAnswerId"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer")) ? this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={12}>
                                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ExpectedAnswer").visible) || this.state.dynamicAttributes.length === 0) &&
                                                                            <FormAnt.Item


                                                                                style={{
                                                                                    marginBottom: 0,
                                                                                }}
                                                                                name="expectedAnswerId"
                                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer")) ? this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ExpectedAnswer").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    placeholder="Expected answer"
                                                                                    value={this.state.expectedAnswerId} onChange={(value) => {

                                                                                        var expectedAnswer = "";
                                                                                        var expected = this.state.EmployeeRequestCodeexpectedAnswerIds.find(p => p.id == value);
                                                                                        if (Boolean(expected)) {
                                                                                            expectedAnswer = expected.name;
                                                                                        }

                                                                                        this.formRefScoreSetting.current.setFieldsValue({
                                                                                            expectedAnswerId: value
                                                                                        });
                                                                                        this.setState({
                                                                                            expectedAnswerId: value,
                                                                                            expectedAnswer: expectedAnswer,
                                                                                        })
                                                                                    }}


                                                                                    dropdownRender={menu => (
                                                                                        <div>
                                                                                            {menu}
                                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                                <Input style={{ flex: 'auto' }} value={this.state.itemEmployeeRequestCodeexpectedAnswerIds} onChange={(event) => {
                                                                                                    this.setState({
                                                                                                        itemEmployeeRequestCodeexpectedAnswerIds: event.target.value,
                                                                                                    });
                                                                                                }} />
                                                                                                <a
                                                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                                    onClick={this.addEmployeeRequestCodeexpectedAnswerIds}
                                                                                                >
                                                                                                    <PlusOutlined /> Add item
                   </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                >
                                                                                    <Option key={null} value={null}>Select</Option>
                                                                                    {this.state.EmployeeRequestCodeexpectedAnswerIds.map(i => (
                                                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormAnt.Item>
                                                                        }
                                                                    </ColAnt>

                                                                    <ColAnt span={6}>
                                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Compulsory").visible) || this.state.dynamicAttributes.length === 0) &&
                                                                            <FormAnt.Item


                                                                                style={{
                                                                                    marginBottom: 0,
                                                                                }}
                                                                                name="compulsory"
                                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Compulsory")) ? this.state.dynamicInputs.find(p => p.inputKey === "Compulsory").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Compulsory")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Compulsory").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                                <Checkbox id="Compulsory" onChange={(e) => {
                                                                                    this.setState({
                                                                                        compulsory: e.target.checked
                                                                                    })
                                                                                }} checked={this.state.compulsory}> <FormattedMessage id="Compulsory" defaultMessage="Compulsory" />  </Checkbox>
                                                                            </FormAnt.Item>
                                                                        }
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                            {/* } */}

                                                            {/* {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Score").visible) || this.state.dynamicAttributes.length === 0) && */}
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="Score" defaultMessage="Score" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="score"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Score")) ? this.state.dynamicInputs.find(p => p.inputKey === "Score").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Score")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Score").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={6}>
                                                                        <InputNumber id="Score" min={0} value={this.state.score} onChange={(value) => {
                                                                            this.formRefScoreSetting.current.setFieldsValue({
                                                                                score: value
                                                                            });
                                                                            this.setState({ score: value })
                                                                        }} />
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                            {/* } */}

                                                            {/* {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "LinkedQuestion").visible) || this.state.dynamicAttributes.length === 0) && */}
                                                            <FormAnt.Item
                                                                {...layout}

                                                                label={<FormattedMessage id="LinkedQuestion" defaultMessage="linked question" />}

                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                // name="linkedQuestionId"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "LinkedQuestion")) ? this.state.dynamicInputs.find(p => p.inputKey === "LinkedQuestion").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "LinkedQuestion")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "LinkedQuestion").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <RowAnt gutter={[8, 8]}>
                                                                    <ColAnt span={12}>
                                                                        <Select
                                                                            style={{ width: '100%' }}
                                                                            placeholder="linked question"
                                                                            value={this.state.linkedQuestionId} onChange={(value) => {
                                                                                this.formRefScoreSetting.current.setFieldsValue({
                                                                                    linkedQuestionId: value
                                                                                });
                                                                                this.setState({
                                                                                    linkedQuestionId: value
                                                                                })
                                                                            }}


                                                                            dropdownRender={menu => (
                                                                                <div>
                                                                                    {menu}
                                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                        <Input style={{ flex: 'auto' }} value={this.state.itemEmployeeRequestCodelinkedQuestions} onChange={(event) => {
                                                                                            this.setState({
                                                                                                itemEmployeeRequestCodelinkedQuestions: event.target.value,
                                                                                            });
                                                                                        }} />
                                                                                        <a
                                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                            onClick={this.addEmployeeRequestCodelinkedQuestions}
                                                                                        >
                                                                                            <PlusOutlined /> Add item
                   </a>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        >
                                                                            <Option key={null} value={null}>Select</Option>
                                                                            {this.state.EmployeeRequestCodelinkedQuestions.map(i => (
                                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    </ColAnt>
                                                                </RowAnt>

                                                            </FormAnt.Item>
                                                            {/* } */}

                                                            <div hidden={step.hideSaveScoreSetting}>
                                                                {
                                                                    <RowAnt gutter={8}>
                                                                        <ColAnt span={8}></ColAnt>

                                                                        <ColAnt span={8}>
                                                                            {
                                                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                                            }
                                                                        </ColAnt>
                                                                    </RowAnt>

                                                                }
                                                            </div>

                                                            <div hidden={step.hideUpdateScoreSetting}>
                                                                {
                                                                    <Row>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                        <Col sm="2">
                                                                            {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                                            <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancelScoreSetting}>
                                                                                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                                        </Col>
                                                                        <Col sm="3">
                                                                            {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                                            <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                                                <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                                        </Col>
                                                                        <Col sm="3">
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </div>

                                                        </div>

                                                        <div className="datatable-doc-demo">
                                                            <div className="content-section implementation">
                                                                <Mnd.MNDTable
                                                                    state={this.state}
                                                                    columns={tableColumnsScoreSetting} dataSource={this.state.scoreSettingsTable.filter(p => p.hiringStepId == step.hiringStepId)}
                                                                    pagination={this.state.paginationDisabled}
                                                                    expandable={this.state.expandable}
                                                                    footer={this.state.footer}
                                                                    title={this.state.title}
                                                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </FormAnt>
                                        )}

                                        <br />
                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>

                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 &&
                                    <Mnd.MNDTable
                                        state={this.state}
                                        columns={tableColumns} dataSource={this.state.tableList}
                                        pagination={this.state.paginationDisabled}
                                        expandable={this.state.expandable}
                                        footer={this.state.footer}
                                        title={this.state.title}
                                        scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                    />
                                }
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalDeleteShowScoreSetting}
                            onHide={this.showOrHideDeleteModalScoreSetting}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModalScoreSetting}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.deleteScoreSetting} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalDeleteShowQualifications}
                            onHide={this.showOrHideDeleteModalQualifications}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModalQualifications}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.deleteQualifications} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRequest)
