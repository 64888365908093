import { API_BASE } from "../config/env";
import axios from "axios";

export const FETCH_SELECTITEM_PENDING = "FETCH_SELECTITEM_PENDING";
export const FETCH_SELECTITEM_FULFILLED = "FETCH_SELECTITEM_FULFILLED";
export const FETCH_SELECTITEM_REJECTED = "FETCH_SELECTITEM_REJECTED";

export const DELETE_SELECTITEM_PENDING = "DELETE_SELECTITEM_PENDING";
export const DELETE_SELECTITEM_FULFILLED = "DELETE_SELECTITEM_FULFILLED";
export const DELETE_SELECTITEM_REJECTED = "DELETE_SELECTITEM_REJECTED";

export function fetchSelectItem() {
  return dispatch => {
    axios.get(`${API_BASE}/selectsanditems/parent/1`)
    .then(result => result.data)
    .then(data=>dispatch(
        {
            type:FETCH_SELECTITEM_FULFILLED,
            payload:data
        }))
        .catch(error=>console.log("WEB SERVİSTE HATA ALINDI"))
  };
}
