import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, Checkbox, DatePicker, Radio, Divider } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class InspectionAssignmentPackage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      pimdate: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      materials: [],
      inspectionAssignmentTable: [],
      hiddenAdress: true,
      leadInspectorCompanies: [],
      leadInspectorCompany: "",
      leadInspectorCompanyId: null,
      newLeadInspectorCompany: "",
      leadInspectorNames: [],
      leadInspectorName: "",
      leadInspectorNameId: null,
      newLeadInspectorName: "",
      backUpInspectorCompanies: [],
      backUpInspectorCompany: "",
      backUpInspectorCompanyId: null,
      newBackUpInspectorCompany: "",
      backUpInspectorNames: [],
      backUpInspectorName: "",
      backUpInspectorNameId: null,
      newBackUpInspectorName: "",
      clientSairNames: [],
      clientSairName: "",
      clientSairNameId: null,
      newClientSairName: "",
      clientMaterialSpecialistNames: [],
      clientMaterialSpecialistName: "",
      clientMaterialSpecialistNameId: null,
      newClientMaterialSpecialistName: "",
      clientSapmtNames: [],
      clientSapmtName: "",
      clientSapmtNameId: null,
      newClientSapmtName: "",
      rfqList: [],
      mrList: [],
      PIMCheck: false
    };

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InspectionAssignmentPackage" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "InspectionAssignmentPackage",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InspectionAssignmentPackage");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }

    this.restartTable();
    this.getCodesLeadInspectorCompany();
    this.getCodesLeadInspectorName();
    this.getCodesBackUpInspectorCompany();
    this.getCodesBackUpInspectorName();
    this.getCodesClientSairName();
    this.getCodesClientMaterialSpecialistName();
    this.getCodesClientSapmtName();
    this.getTableProperties();
  }

  getTableProperties = async () => {
    this.inspectionAssignmentPackage = JSON.parse(localStorage.getItem('inspectionAssignmentPackage'));
    if (this.inspectionAssignmentPackage != null && this.inspectionAssignmentPackage != undefined && this.inspectionAssignmentPackage != "") {

      await this.setState({
        bordered: this.inspectionAssignmentPackage.bordered,
        title: this.inspectionAssignmentPackage.titleDrm == true ? title : undefined,
        titleDrm: this.inspectionAssignmentPackage.titleDrm,
        showHeader: this.inspectionAssignmentPackage.showHeader,
        footer: this.inspectionAssignmentPackage.footerDrm == true ? footer : undefined,
        footerDrm: this.inspectionAssignmentPackage.footerDrm,
        expandable: this.inspectionAssignmentPackage.expandableDrm == true ? expandable : {},
        expandableDrm: this.inspectionAssignmentPackage.expandableDrm,
        rowSelection: this.inspectionAssignmentPackage.rowSelection,
        ellipsis: this.inspectionAssignmentPackage.ellipsis,
        tableLayout: this.inspectionAssignmentPackage.tableLayout,
        size: this.inspectionAssignmentPackage.size,
        top: this.inspectionAssignmentPackage.top,
        bottom: this.inspectionAssignmentPackage.bottom,
        editBgColor: this.inspectionAssignmentPackage.editBgColor,
        deleteBgColor: this.inspectionAssignmentPackage.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.inspectionAssignmentPackage.yScroll,
        xScroll: this.inspectionAssignmentPackage.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.inspectionAssignmentTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.inspectionAssignmentTable.length, showQuickJumper: true,
          position: [this.inspectionAssignmentPackage.top, this.inspectionAssignmentPackage.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  }

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  }

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedInspectionAssignmentPackage: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveInspectionAssignmentPackage = async () => {
    const addInspectionAssignmentPackage = {
      date: moment(this.state.date, 'YYYY-MM-DD'),
      dateAck: moment(this.state.date, 'YYYY-MM-DD'),
      iap: this.state.iap,
      revNo: this.state.revNo,
      rfqId: this.state.rfqId,
      iapLotNo: this.state.iapLotNo,
      pimdate: moment(this.state.pimdate, 'YYYY-MM-DD'),
      pimdateAck: this.state.pimdate,
      pimdateConfirmed: this.state.pimdateConfirmed,
      pimlocation: this.state.pimlocation,
      address: this.state.address,
      leadInspectorCompanyId: this.state.leadInspectorCompanyId,
      leadInspectorNameId: this.state.leadInspectorNameId,
      backUpInspectorCompanyId: this.state.backUpInspectorCompanyId,
      backUpInspectorNameId: this.state.backUpInspectorNameId,
      clientSairNameId: this.state.clientSairNameId,
      clientMaterialSpecialistNameId: this.state.clientMaterialSpecialistNameId,
      clientSapmtNameId: this.state.clientSapmtNameId
    }
    console.log("XXX", addInspectionAssignmentPackage);
    var response = await handleRequest("POST", "/InspectionAssignmentPackage/add", addInspectionAssignmentPackage);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  updateInspectionAssignmentPackage = async () => {
    const updatedInspectionAssignmentPackage = {
      id: this.state.selectedInspectionAssignmentPackage.id,
      date: moment(this.state.date, 'YYYY-MM-DD'),
      dateAck: moment(this.state.date, 'YYYY-MM-DD'),
      iap: this.state.iap,
      revNo: this.state.revNo,
      rfqId: this.state.rfqId,
      iapLotNo: this.state.iapLotNo,
      pimdate: moment(this.state.pimdate, 'YYYY-MM-DD'),
      pimdateAck: this.state.pimdate,
      pimdateConfirmed: this.state.pimdateConfirmed,
      pimlocation: this.state.pimlocation,
      address: this.state.address,
      leadInspectorCompanyId: this.state.leadInspectorCompanyId,
      leadInspectorNameId: this.state.leadInspectorNameId,
      backUpInspectorCompanyId: this.state.backUpInspectorCompanyId,
      backUpInspectorNameId: this.state.backUpInspectorNameId,
      clientSairNameId: this.state.clientSairNameId,
      clientMaterialSpecialistNameId: this.state.clientMaterialSpecialistNameId,
      clientSapmtNameId: this.state.clientSapmtNameId
    }
    console.log("XXX", updatedInspectionAssignmentPackage);
    var response2 = await handleRequest("POST", "/InspectionAssignmentPackage/update", updatedInspectionAssignmentPackage);
    if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/InspectionAssignmentPackage/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        inspectionAssignmentTable: []
      });
    }

    if (response.data.length !== 0) {
      console.log(response.data);
      this.setState({
        inspectionAssignmentTable: response.data
      });
    }

    //this.getMaterials();
    this.getRFQs();
    this.getMRs();
  }

  getMaterials = async () => {
    // const response = await handleRequest("GET", "/ProjectTakeOffList/getProjectTakeOffList");
    // if (response.data.length === 0) {
    //   this.setState({
    //     materials: [],
    //   });
    // }

    // if (response.data.length !== 0) {
    //   this.setState({
    //     materials: response.data,
    //   });
    // }
  }

  getRFQs = async () => {
    let purchasedRFQs = [];
    let preparedRFQs = [];
    let flagRfqList = [];

    const response = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");

    if (response.data.length !== 0) {
      for (let index = 0; index < response.data.length; index++) {
        if (response.data[index].status === "Approved" || response.data[index].status === "Approved with")
          purchasedRFQs.push(response.data[index].rfqId);
      }
    }

    const responseIAP = await handleRequest("GET", "/InspectionAssignmentPackage/getallByCompany");

    if (responseIAP.data.length !== 0) {
      for (let index = 0; index < responseIAP.data.length; index++) {
        preparedRFQs.push(responseIAP.data[index].rfqId);
      }
    }

    console.log(purchasedRFQs);
    console.log(preparedRFQs);

    const responseRFQ = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");

    if (responseRFQ.data.length === 0) {
      this.setState({
        rfqList: []
      });
    } else {
      for (let index = 0; index < responseRFQ.data.length; index++) {
        if (purchasedRFQs.includes(responseRFQ.data[index].id) && !(preparedRFQs.includes(responseRFQ.data[index].id))) {
          flagRfqList.push(responseRFQ.data[index]);
        }
      }

      this.setState({
        rfqList: flagRfqList
      });
    }
  }

  getMRs = async () => {
    const response = await handleRequest("GET", "/MaterialRequisition/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        mrList: []
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        mrList: response.data
      });
    }
  }

  deleteInspectionAssignmentPackageOK = async () => {
    const deleteInspectionAssignmentPackage = {
      id: this.state.selectedInspectionAssignmentPackage.id
    }
    var response = await handleRequest("POST", "/InspectionAssignmentPackage/delete", deleteInspectionAssignmentPackage);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = (row) => {
    this.formRef.current.setFieldsValue({
      IAP: row.iap,
      Rev: row.revNo,
      Material: row.requestForQuotation.projectTakeOffList.description,
      IAPLotNo: (row.iapLotNo != null && row.iapLotNo != undefined && row.iapLotNo != "") && row.iapLotNo,
      PIMDate: (row.pimdate != null && row.pimdate != undefined && row.pimdate != "") && moment(row.pimdate, 'YYYY-MM-DD'),
      Date: (row.date != null && row.date != undefined && row.date != "") && moment(row.date, 'YYYY-MM-DD'),
      PIMLocation: row.pimlocation,
      DateConfirmed: row.pimdateConfirmed
    });

    this.setState({
      selectedInspectionAssignmentPackage: row,
      date: row.date,
      iap: row.iap,
      revNo: row.revNo,
      rfqId: row.rfqId,
      iapLotNo: row.iapLotNo,
      pimdate: row.pimdate,
      pimdateConfirmed: row.pimdateConfirmed,
      pimlocation: row.pimlocation,
      address: row.address,
      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });

    if (row.codeLeadInspectorCompany !== undefined && row.codeLeadInspectorCompany !== null) {
      this.setState({
        leadInspectorCompany: row.codeLeadInspectorCompany.name,
        leadInspectorCompanyId: row.codeLeadInspectorCompany.id
      });

      this.formRef.current.setFieldsValue({
        LeadInspectorCompany: row.codeLeadInspectorCompany.name
      });
    }

    if (row.codeLeadInspectorName !== undefined && row.codeLeadInspectorName !== null) {
      this.setState({
        leadInspectorName: row.codeLeadInspectorName.name,
        leadInspectorNameId: row.codeLeadInspectorName.id
      });

      this.formRef.current.setFieldsValue({
        LeadInspectorName: row.codeLeadInspectorName.name
      });
    }

    if (row.codeBackUpInspectorCompany !== undefined && row.codeBackUpInspectorCompany !== null) {
      this.setState({
        backUpInspectorCompany: row.codeBackUpInspectorCompany.name,
        backUpInspectorCompanyId: row.codeBackUpInspectorCompany.id
      });

      this.formRef.current.setFieldsValue({
        BackUpInspectorCompany: row.codeBackUpInspectorCompany.name
      });
    }

    if (row.codeBackUpInspectorName !== undefined && row.codeBackUpInspectorName !== null) {
      this.setState({
        backUpInspectorName: row.codeBackUpInspectorName.name,
        backUpInspectorNameId: row.codeBackUpInspectorName.id
      });

      this.formRef.current.setFieldsValue({
        BackUpInspectorName: row.codeBackUpInspectorName.name
      });
    }

    if (row.codeClientSairName !== undefined && row.codeClientSairName !== null) {
      this.setState({
        clientSairName: row.codeClientSairName.name,
        clientSairNameId: row.codeClientSairName.id
      });

      this.formRef.current.setFieldsValue({
        ClientSAIRName: row.codeClientSairName.name
      });
    }

    if (row.codeClientMaterialSpecialistName !== undefined && row.codeClientMaterialSpecialistName !== null) {
      this.setState({
        clientMaterialSpecialistName: row.codeClientMaterialSpecialistName.name,
        clientMaterialSpecialistNameId: row.codeClientMaterialSpecialistName.id
      });

      this.formRef.current.setFieldsValue({
        ClientMaterialSpecialistName: row.codeClientMaterialSpecialistName.name
      });
    }

    if (row.codeClientSapmtName !== undefined && row.codeClientSapmtName !== null) {
      this.setState({
        clientSapmtName: row.codeClientSapmtName.name,
        clientSapmtNameId: row.codeClientSapmtName.id
      });

      this.formRef.current.setFieldsValue({
        ClientSAPMTName: row.codeClientSapmtName.name
      });
    }

    let rfqIndex = this.state.rfqList.findIndex(rfq => rfq.id === row.rfqId);
    if (rfqIndex !== -1) {
      let flagRfq = this.state.rfqList[rfqIndex];
      let mrIndex = this.state.mrList.findIndex(mr => mr.projectTakeOffList.id === flagRfq.projectTakeOffList.id);
      if (mrIndex !== -1) {
        let flagMr = this.state.mrList[mrIndex];
        if (flagMr.pim === "Yes") {
          this.setState({
            PIMCheck: true,
          });
        } else {
          this.setState({
            PIMCheck: false,
          });
        }
      }
    }
  }

  resetInputs = () => {
    this.formRef.current.resetFields();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    if (this.state.inspectionAssignmentTable.length > 0) {
      this.setState({
        iap: this.state.inspectionAssignmentTable[this.state.inspectionAssignmentTable.length - 1].id + 1
      });

      this.formRef.current.setFieldsValue({
        IAP: (this.state.inspectionAssignmentTable[this.state.inspectionAssignmentTable.length - 1].id + 1).toString()
      });
    } else {
      this.setState({
        iap: "1"
      });

      this.formRef.current.setFieldsValue({
        IAP: "1"
      });
    }

    this.formRef.current.setFieldsValue({
      Date: moment(today, 'YYYY-MM-DD'),
      Rev: "0",
      PIMLocation: "",
      Material: "",
      IAPLotNo: "",
      PIMDate: "",
      DateConfirmed: false
    });

    this.setState({
      leadInspectorCompany: "",
      leadInspectorCompanyId: null,
      newLeadInspectorCompany: "",
      leadInspectorName: "",
      leadInspectorNameId: null,
      newLeadInspectorName: "",
      backUpInspectorCompany: "",
      backUpInspectorCompanyId: null,
      newBackUpInspectorCompany: "",
      backUpInspectorName: "",
      backUpInspectorNameId: null,
      newBackUpInspectorName: "",
      clientSairName: "",
      clientSairNameId: null,
      newClientSairName: "",
      clientMaterialSpecialistName: "",
      clientMaterialSpecialistNameId: null,
      newClientMaterialSpecialistName: "",
      clientSapmtName: "",
      clientSapmtNameId: null,
      newClientSapmtName: "",
      selectedInspectionAssignmentPackage: [],
      date: moment(today, 'YYYY-MM-DD'),
      revNo: "0",
      rfqId: "",
      iapLotNo: "",
      pimdate: "",
      pimdateConfirmed: false,
      pimlocation: "",
      address: "",
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
  }

  changeButtonCancel = () => {
    this.formRef.current.resetFields();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    if (this.state.inspectionAssignmentTable.length > 0) {
      this.setState({
        iap: this.state.inspectionAssignmentTable[this.state.inspectionAssignmentTable.length - 1].id + 1
      });

      this.formRef.current.setFieldsValue({
        IAP: (this.state.inspectionAssignmentTable[this.state.inspectionAssignmentTable.length - 1].id + 1).toString()
      });
    } else {
      this.setState({
        iap: "1"
      });

      this.formRef.current.setFieldsValue({
        IAP: "1"
      });
    }

    this.formRef.current.setFieldsValue({
      Date: moment(today, 'YYYY-MM-DD'),
      Rev: "0",
      PIMLocation: "",
      Material: "",
      IAPLotNo: "",
      PIMDate: "",
      DateConfirmed: false
    });

    this.setState({
      leadInspectorCompany: "",
      leadInspectorCompanyId: null,
      newLeadInspectorCompany: "",
      leadInspectorName: "",
      leadInspectorNameId: null,
      newLeadInspectorName: "",
      backUpInspectorCompany: "",
      backUpInspectorCompanyId: null,
      newBackUpInspectorCompany: "",
      backUpInspectorName: "",
      backUpInspectorNameId: null,
      newBackUpInspectorName: "",
      clientSairName: "",
      clientSairNameId: null,
      newClientSairName: "",
      clientMaterialSpecialistName: "",
      clientMaterialSpecialistNameId: null,
      newClientMaterialSpecialistName: "",
      clientSapmtName: "",
      clientSapmtNameId: null,
      newClientSapmtName: "",
      selectedInspectionAssignmentPackage: [],
      date: "",
      iap: "",
      revNo: "",
      rfqId: "",
      iapLotNo: "",
      pimdate: "",
      pimdateConfirmed: false,
      pimlocation: "",
      address: "",
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenCreateNewButton: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenCreateNewButton: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeDate = (value, dateString) => {
    this.setState({
      date: dateString,
    });
  }

  onChangePIMDate = (value, dateString) => {
    this.setState({
      pimdate: dateString,
    });
  }

  handleChangeMaterial = (e) => {
    this.setState({
      rfqId: e,
    });

    let rfqIndex = this.state.rfqList.findIndex(rfq => rfq.id === e);
    if (rfqIndex !== -1) {
      let flagRfq = this.state.rfqList[rfqIndex];
      let mrIndex = this.state.mrList.findIndex(mr => mr.projectTakeOffList.id === flagRfq.projectTakeOffList.id);
      if (mrIndex !== -1) {
        let flagMr = this.state.mrList[mrIndex];
        if (flagMr.pim === "Yes") {
          this.setState({
            PIMCheck: true,
          });
        } else {
          this.setState({
            PIMCheck: false,
          });
        }
      }
    }
  }

  handleChangeLeadInspectorCompany = (e) => {
    var value = "";
    for (var z = 0; z < this.state.leadInspectorCompanies.length; z++) {
      if (this.state.leadInspectorCompanies[z].id == e) {
        value = this.state.leadInspectorCompanies[z].name;
      }
    }
    this.setState({
      leadInspectorCompany: value,
      leadInspectorCompanyId: e,
    });
  }

  addLeadInspectorCompany = async () => {
    const { newLeadInspectorCompany } = this.state;
    if (newLeadInspectorCompany != null && newLeadInspectorCompany != "" && newLeadInspectorCompany != undefined) {
      const newItem = {
        name: newLeadInspectorCompany,
        type: "LeadInspectorCompany"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newLeadInspectorCompany: ""
      });

      this.getCodesLeadInspectorCompany();
    } else {
      this.showWarning();
    }
  }

  getCodesLeadInspectorCompany = async () => {
    const response = await handleRequest("GET", "/Code/type/LeadInspectorCompany");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        leadInspectorCompanies: response.data
      });
    }
  }

  handleChangeLeadInspectorName = (e) => {
    var value = "";
    for (var z = 0; z < this.state.leadInspectorNames.length; z++) {
      if (this.state.leadInspectorNames[z].id == e) {
        value = this.state.leadInspectorNames[z].name;
      }
    }
    this.setState({
      leadInspectorName: value,
      leadInspectorNameId: e,
    });
  }

  addLeadInspectorName = async () => {
    const { newLeadInspectorName } = this.state;
    if (newLeadInspectorName != null && newLeadInspectorName != "" && newLeadInspectorName != undefined) {
      const newItem = {
        name: newLeadInspectorName,
        type: "LeadInspectorName"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newLeadInspectorName: ""
      });

      this.getCodesLeadInspectorName();
    } else {
      this.showWarning();
    }
  }

  getCodesLeadInspectorName = async () => {
    const response = await handleRequest("GET", "/Code/type/LeadInspectorName");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        leadInspectorNames: response.data
      });
    }
  }

  handleChangeBackUpInspectorCompany = (e) => {
    var value = "";
    for (var z = 0; z < this.state.backUpInspectorCompanies.length; z++) {
      if (this.state.backUpInspectorCompanies[z].id == e) {
        value = this.state.backUpInspectorCompanies[z].name;
      }
    }
    this.setState({
      backUpInspectorCompany: value,
      backUpInspectorCompanyId: e,
    });
  }

  addBackUpInspectorCompany = async () => {
    const { newBackUpInspectorCompany } = this.state;
    if (newBackUpInspectorCompany != null && newBackUpInspectorCompany != "" && newBackUpInspectorCompany != undefined) {
      const newItem = {
        name: newBackUpInspectorCompany,
        type: "BackUpInspectorCompany"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newBackUpInspectorCompany: ""
      });

      this.getCodesBackUpInspectorCompany();
    } else {
      this.showWarning();
    }
  }

  getCodesBackUpInspectorCompany = async () => {
    const response = await handleRequest("GET", "/Code/type/BackUpInspectorCompany");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        backUpInspectorCompanies: response.data
      });
    }
  }

  handleChangeBackUpInspectorName = (e) => {
    var value = "";
    for (var z = 0; z < this.state.backUpInspectorNames.length; z++) {
      if (this.state.backUpInspectorNames[z].id == e) {
        value = this.state.backUpInspectorNames[z].name;
      }
    }
    this.setState({
      backUpInspectorName: value,
      backUpInspectorNameId: e,
    });
  }

  addBackUpInspectorName = async () => {
    const { newBackUpInspectorName } = this.state;
    if (newBackUpInspectorName != null && newBackUpInspectorName != "" && newBackUpInspectorName != undefined) {
      const newItem = {
        name: newBackUpInspectorName,
        type: "BackUpInspectorName"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newBackUpInspectorName: ""
      });

      this.getCodesBackUpInspectorName();
    } else {
      this.showWarning();
    }
  }

  getCodesBackUpInspectorName = async () => {
    const response = await handleRequest("GET", "/Code/type/BackUpInspectorName");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        backUpInspectorNames: response.data
      });
    }
  }

  handleChangeClientSairName = (e) => {
    var value = "";
    for (var z = 0; z < this.state.clientSairNames.length; z++) {
      if (this.state.clientSairNames[z].id == e) {
        value = this.state.clientSairNames[z].name;
      }
    }
    this.setState({
      clientSairName: value,
      clientSairNameId: e,
    });
  }

  addClientSairName = async () => {
    const { newClientSairName } = this.state;
    if (newClientSairName != null && newClientSairName != "" && newClientSairName != undefined) {
      const newItem = {
        name: newClientSairName,
        type: "ClientSairName"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newClientSairName: ""
      });

      this.getCodesClientSairName();
    } else {
      this.showWarning();
    }
  }

  getCodesClientSairName = async () => {
    const response = await handleRequest("GET", "/Code/type/ClientSairName");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        clientSairNames: response.data
      });
    }
  }

  handleChangeClientMaterialSpecialistName = (e) => {
    var value = "";
    for (var z = 0; z < this.state.clientMaterialSpecialistNames.length; z++) {
      if (this.state.clientMaterialSpecialistNames[z].id == e) {
        value = this.state.clientMaterialSpecialistNames[z].name;
      }
    }
    this.setState({
      clientMaterialSpecialistName: value,
      clientMaterialSpecialistNameId: e,
    });
  }

  addClientMaterialSpecialistName = async () => {
    const { newClientMaterialSpecialistName } = this.state;
    if (newClientMaterialSpecialistName != null && newClientMaterialSpecialistName != "" && newClientMaterialSpecialistName != undefined) {
      const newItem = {
        name: newClientMaterialSpecialistName,
        type: "ClientMaterialSpecialistName"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newClientMaterialSpecialistName: ""
      });

      this.getCodesClientMaterialSpecialistName();
    } else {
      this.showWarning();
    }
  }

  getCodesClientMaterialSpecialistName = async () => {
    const response = await handleRequest("GET", "/Code/type/ClientMaterialSpecialistName");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        clientMaterialSpecialistNames: response.data
      });
    }
  }

  handleChangeClientSapmtName = (e) => {
    var value = "";
    for (var z = 0; z < this.state.clientSapmtNames.length; z++) {
      if (this.state.clientSapmtNames[z].id == e) {
        value = this.state.clientSapmtNames[z].name;
      }
    }
    this.setState({
      clientSapmtName: value,
      clientSapmtNameId: e,
    });
  }

  addClientSapmtName = async () => {
    const { newClientSapmtName } = this.state;
    if (newClientSapmtName != null && newClientSapmtName != "" && newClientSapmtName != undefined) {
      const newItem = {
        name: newClientSapmtName,
        type: "ClientSapmtName"
      };

      await handleRequest("POST", "/Code/add", newItem);

      this.setState({
        newClientSapmtName: ""
      });

      this.getCodesClientSapmtName();
    } else {
      this.showWarning();
    }
  }

  getCodesClientSapmtName = async () => {
    const response = await handleRequest("GET", "/Code/type/ClientSapmtName");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        clientSapmtNames: response.data
      });
    }
  }

  render() {
    var itemDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Date");
    var itemIAPAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "IAP");
    var itemIAPLotNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "IAPLotNo");
    var itemRevAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Rev");
    var itemPIMLocationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PIMLocation");
    var itemDateConfirmedAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DateConfirmed");
    var itemPIMDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PIMDate");
    var itemMaterialAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Material");
    var itemLeadInspectorCompanyAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "LeadInspectorCompany");
    var itemLeadInspectorNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "LeadInspectorName");
    var itemBackUpInspectorCompanyAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BackUpInspectorCompany");
    var itemBackUpInspectorNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BackUpInspectorName");
    var itemClientSAIRNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientSAIRName");
    var itemClientMaterialSpecialistNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientMaterialSpecialistName");
    var itemClientSAPMTNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientSAPMTName");

    var itemDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "Date");
    var itemIAPRequired = this.state.dynamicInputs.find(p => p.inputKey === "IAP");
    var itemIAPLotNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "IAPLotNo");
    var itemRevRequired = this.state.dynamicInputs.find(p => p.inputKey === "Rev");
    var itemPIMLocationRequired = this.state.dynamicInputs.find(p => p.inputKey === "PIMLocation");
    var itemDateConfirmedRequired = this.state.dynamicInputs.find(p => p.inputKey === "DateConfirmed");
    var itemPIMDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "PIMDate");
    var itemMaterialRequired = this.state.dynamicInputs.find(p => p.inputKey === "Material");
    var itemLeadInspectorCompanyRequired = this.state.dynamicInputs.find(p => p.inputKey === "LeadInspectorCompany");
    var itemLeadInspectorNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "LeadInspectorName");
    var itemBackUpInspectorCompanyRequired = this.state.dynamicInputs.find(p => p.inputKey === "BackUpInspectorCompany");
    var itemBackUpInspectorNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "BackUpInspectorName");
    var itemClientSAIRNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "ClientSAIRName");
    var itemClientMaterialSpecialistNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "ClientMaterialSpecialistName");
    var itemClientSAPMTNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "ClientSAPMTName");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("inspectionAssignmentPackage")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "date",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentDate" defaultMessage="Date" />,
      },
      {
        dataIndex: "iap",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentIAP" defaultMessage="IAP" />,
      },
      {
        dataIndex: "revNo",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentRevNo" defaultMessage="Rev." />,
      },
      {
        dataIndex: "requestForQuotation",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentItem" defaultMessage="Item" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.projectTakeOffList.code + "-" + data.projectTakeOffList.description;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "iapLotNo",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentIAPLotNo" defaultMessage="IAP Lot No" />,
      },
      {
        dataIndex: "pimdate",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentPIMDate" defaultMessage="PIM Date" />,
      },
      {
        dataIndex: "pimdateConfirmed",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentPIMDateConfirmed" defaultMessage="Date confirmed" />,
        render: (data) => {
          if (data != null && data != undefined && data == 1) {
            return "Date confirmed";
          }
          return "";
        }
      },
      {
        dataIndex: "pimlocation",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentPIMLocation" defaultMessage="PIM Location" />,
      },
      {
        dataIndex: "address",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentAddress" defaultMessage="Address" />,
      },
      {
        dataIndex: "codeLeadInspectorCompany",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentLeadInspectorCompany" defaultMessage="Lead Inspector Company" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "codeLeadInspectorName",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentLeadInspectorName" defaultMessage="Lead Inspector Name" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "codeBackUpInspectorCompany",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentBackUpInspectorCompany" defaultMessage="Back Up Inspector Company" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "codeBackUpInspectorName",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentBackUpInspectorName" defaultMessage="Back Up Inspector Name" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "codeClientSairName",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentClientSAIRName" defaultMessage="Client SAIR Name" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "codeClientMaterialSpecialistName",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentClientMaterialSpecialistName" defaultMessage="Client Material Specialist Name" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },
      {
        dataIndex: "codeClientSapmtName",
        key: "key",
        title: <FormattedMessage id="InspectionAssignmentClientSAPMTName" defaultMessage="Client SAPMT Name" />,
        render: (data) => {
          if (data !== undefined) {
            if (data !== null) {
              return data.name;
            }
            else
              return "";
          }
          else
            return "";
        }
      },

    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    let radioGroupOptions = (
      <FormBoot.Group as={Row}  >
        <Col sm="3">
          <Radio value={"Supplier"}><FormattedMessage id="InspectionAssignmentSupplier" defaultMessage="Supplier" /></Radio>
        </Col>
        <Col sm="2">
        </Col>
        <Col sm="3">
          <Radio value={"Manufacturer"}><FormattedMessage id="InspectionAssignmentManufacturer" defaultMessage="Manufacturer" /></Radio>
        </Col>
        <Col sm="2">
        </Col>
        <Col sm="2">
          <Radio value={"Other"}><FormattedMessage id="InspectionAssignmentOther" defaultMessage="Other" /></Radio>
        </Col>
      </FormBoot.Group>
    );

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateInspectionAssignmentPackage : this.saveInspectionAssignmentPackage}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("inspectionAssignmentPackage")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />

              {(itemDateAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentDate" defaultMessage="Date" />}
                  name="Date"
                  rules={[{ required: itemDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateRequired.translateRequired) == true ? itemDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                  <DatePicker value={moment(this.state.date, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDate} />
                </Form.Item>}

              {(itemIAPAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentIAP" defaultMessage="IAP#" />}
                  name="IAP"
                  rules={[{ required: itemIAPRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemIAPRequired.translateRequired) == true ? itemIAPRequired.translateRequired : -1} defaultMessage="Please input your IAP!" /> }]} >
                  <Input id="InspectionAssignmentIAP" value={this.state.iap} onChange={(e) =>
                    this.setState({ iap: e.target.value })
                  } />
                </Form.Item>}

              {(itemRevAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentRev" defaultMessage="Rev." />}
                  name="Rev"
                  rules={[{ required: itemRevRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRevRequired.translateRequired) == true ? itemRevRequired.translateRequired : -1} defaultMessage="Please input your Rev.!" /> }]} >
                  <Input id="InspectionAssignmentRev" value={this.state.revNo} onChange={(e) =>
                    this.setState({ revNo: e.target.value })
                  } />
                </Form.Item>}

              {(itemMaterialAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentItem" defaultMessage="Item" />}
                  name="Material"
                  rules={[{ required: itemMaterialRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMaterialRequired.translateRequired) == true ? itemMaterialRequired.translateRequired : -1} defaultMessage="Please input your Item!" /> }]} >
                  <Select name="Material"
                    style={{ width: '100%' }}
                    value={this.state.rfqId}
                    onChange={this.handleChangeMaterial}>
                    {this.state.rfqList.map(i => (
                      <Option key={i.id} value={i.id}>{i.projectTakeOffList.description}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemIAPLotNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentIAPLotNo" defaultMessage="IAP Lot No" />}
                  name="IAPLotNo"
                  rules={[{ required: itemIAPLotNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemIAPLotNoRequired.translateRequired) == true ? itemIAPLotNoRequired.translateRequired : -1} defaultMessage="Please input your IAP Lot No!" /> }]} >
                  <Input id="InspectionAssignmentIAP" value={this.state.iapLotNo} onChange={(e) =>
                    this.setState({ iapLotNo: e.target.value })
                  } />
                </Form.Item>}

              {(itemPIMDateAttributes.visible) && (this.state.PIMCheck) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentPIMDate" defaultMessage="PIM Date" />}
                  name="PIMDate"
                  rules={[{ required: itemPIMDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPIMDateRequired.translateRequired) == true ? itemPIMDateRequired.translateRequired : -1} defaultMessage="Please input your PIM Date!" /> }]} >
                  <DatePicker value={moment(this.state.pimdate, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangePIMDate} />
                </Form.Item>}

              {(itemDateConfirmedAttributes.visible) && (this.state.PIMCheck) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentDateConfirmed" defaultMessage="Date Confirmed" />}
                  name="DateConfirmed"
                  rules={[{ required: itemDateConfirmedRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateConfirmedRequired.translateRequired) == true ? itemDateConfirmedRequired.translateRequired : -1} defaultMessage="Please input your Date Confirmed!" /> }]} >
                  <Checkbox style={{ marginLeft: "10px" }} onChange={(e) => this.setState({ pimdateConfirmed: e.target.checked })} checked={this.state.pimdateConfirmed}><FormattedMessage id="InspectionAssignmentDateConfirmed" defaultMessage="Yes" /></Checkbox>
                </Form.Item>}

              {(itemPIMLocationAttributes.visible) && (this.state.PIMCheck) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentPIMLocation" defaultMessage="PIM Location" />}
                  name="PIMLocation"
                  rules={[{ required: itemPIMLocationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPIMLocationRequired.translateRequired) == true ? itemPIMLocationRequired.translateRequired : -1} defaultMessage="Please input your PIM Location!" /> }]} >
                  <Mnd.MNDRadioGroup value={this.state.pimlocation}
                    onChange={(e) => {
                      var hiddenAdress = true;
                      var address = this.state.address;
                      if (e.target.value == "Other") {
                        hiddenAdress = false;
                      } else {
                        address = "";
                      }
                      this.setState({
                        pimlocation: e.target.value,
                        hiddenAdress: hiddenAdress,
                        address: address,
                      })
                    }}
                    radios={radioGroupOptions} />
                </Form.Item>}

              <FormBoot.Group hidden={this.state.hiddenAdress} style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="2"></Col>
                <FormBoot.Label column sm="1">
                  <FormattedMessage id="InspectionAssignmentAddress" defaultMessage="Address" />
                </FormBoot.Label>
                <Col sm="5">
                  <TextArea rows={3} style={{ width: '100%' }} value={this.state.address}
                    onChange={(e) => {
                      e.persist();
                      this.setState({
                        address: e.target.value
                      })
                    }} />
                </Col>
              </FormBoot.Group>

              {(itemLeadInspectorCompanyAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentLeadInspectorCompany" defaultMessage="Lead Inspector Company" />}
                  name="LeadInspectorCompany"
                  rules={[{ required: itemLeadInspectorCompanyRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLeadInspectorCompanyRequired.translateRequired) == true ? itemLeadInspectorCompanyRequired.translateRequired : -1} defaultMessage="Please input your Lead Inspector Company!" /> }]} >
                  <Select
                    name="LeadInspectorCompany"
                    style={{ width: '100%' }}
                    value={this.state.leadInspectorCompany}
                    onChange={this.handleChangeLeadInspectorCompany}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newLeadInspectorCompany}
                            onChange={(event) => { this.setState({ newLeadInspectorCompany: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addLeadInspectorCompany} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Lead Inspector Company" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.leadInspectorCompanies.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemLeadInspectorNameAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentLeadInspectorName" defaultMessage="Lead Inspector Name" />}
                  name="LeadInspectorName"
                  rules={[{ required: itemLeadInspectorNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLeadInspectorNameRequired.translateRequired) == true ? itemLeadInspectorNameRequired.translateRequired : -1} defaultMessage="Please input your Lead Inspector Name!" /> }]} >
                  <Select
                    name="LeadInspectorName"
                    style={{ width: '100%' }}
                    value={this.state.leadInspectorName}
                    onChange={this.handleChangeLeadInspectorName}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newLeadInspectorName}
                            onChange={(event) => { this.setState({ newLeadInspectorName: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addLeadInspectorName} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Lead Inspector Name" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.leadInspectorNames.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemBackUpInspectorCompanyAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentBackUpInspectorCompany" defaultMessage="Back Up Inspector Company" />}
                  name="BackUpInspectorCompany"
                  rules={[{ required: itemBackUpInspectorCompanyRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBackUpInspectorCompanyRequired.translateRequired) == true ? itemBackUpInspectorCompanyRequired.translateRequired : -1} defaultMessage="Please input your Back Up Inspector Company!" /> }]} >
                  <Select
                    name="BackUpInspectorCompany"
                    style={{ width: '100%' }}
                    value={this.state.backUpInspectorCompany}
                    onChange={this.handleChangeBackUpInspectorCompany}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newBackUpInspectorCompany}
                            onChange={(event) => { this.setState({ newBackUpInspectorCompany: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addBackUpInspectorCompany} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Back Up Inspector Company" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.backUpInspectorCompanies.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemBackUpInspectorNameAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentBackUpInspectorName" defaultMessage="Back Up Inspector Name" />}
                  name="BackUpInspectorName"
                  rules={[{ required: itemBackUpInspectorNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBackUpInspectorNameRequired.translateRequired) == true ? itemBackUpInspectorNameRequired.translateRequired : -1} defaultMessage="Please input your Back Up Inspector Name!" /> }]} >
                  <Select
                    name="BackUpInspectorName"
                    style={{ width: '100%' }}
                    value={this.state.backUpInspectorName}
                    onChange={this.handleChangeBackUpInspectorName}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newBackUpInspectorName}
                            onChange={(event) => { this.setState({ newBackUpInspectorName: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addBackUpInspectorName} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Back Up Inspector Name" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.backUpInspectorNames.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemClientSAIRNameAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentClientSAIRName" defaultMessage="Client SAIR Name" />}
                  name="ClientSAIRName"
                  rules={[{ required: itemClientSAIRNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemClientSAIRNameRequired.translateRequired) == true ? itemClientSAIRNameRequired.translateRequired : -1} defaultMessage="Please input your Client SAIR Name!" /> }]} >
                  <Select
                    name="ClientSAIRName"
                    style={{ width: '100%' }}
                    value={this.state.clientSairName}
                    onChange={this.handleChangeClientSairName}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newClientSairName}
                            onChange={(event) => { this.setState({ newClientSairName: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addClientSairName} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Client SAIR Name" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.clientSairNames.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemClientMaterialSpecialistNameAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentClientMaterialSpecialistName" defaultMessage="Client Material Specialist Name" />}
                  name="ClientMaterialSpecialistName"
                  rules={[{ required: itemClientMaterialSpecialistNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemClientMaterialSpecialistNameRequired.translateRequired) == true ? itemClientMaterialSpecialistNameRequired.translateRequired : -1} defaultMessage="Please input your Client Material Specialist Name!" /> }]} >
                  <Select
                    name="ClientMaterialSpecialistName"
                    style={{ width: '100%' }}
                    value={this.state.clientMaterialSpecialistName}
                    onChange={this.handleChangeClientMaterialSpecialistName}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newClientMaterialSpecialistName}
                            onChange={(event) => { this.setState({ newClientMaterialSpecialistName: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addClientMaterialSpecialistName} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Client Material Specialist Name" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.clientMaterialSpecialistNames.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemClientSAPMTNameAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentClientSAPMTName" defaultMessage="Client SAPMT Name" />}
                  name="ClientSAPMTName"
                  rules={[{ required: itemClientSAPMTNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemClientSAPMTNameRequired.translateRequired) == true ? itemClientSAPMTNameRequired.translateRequired : -1} defaultMessage="Please input your Client SAPMT Name!" /> }]} >
                  <Select
                    name="ClientSAPMTName"
                    style={{ width: '100%' }}
                    value={this.state.clientSapmtName}
                    onChange={this.handleChangeClientSapmtName}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newClientSapmtName}
                            onChange={(event) => { this.setState({ newClientSapmtName: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addClientSapmtName} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Client SAPMT Name" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.clientSapmtNames.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="5">
                      <Button id="InspectionAssignmentPackageSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button id="InspectionAssignmentPackageCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="3">
                      <Button id="InspectionAssignmentPackageUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.inspectionAssignmentTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>}

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteInspectionAssignmentPackageOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionAssignmentPackage)