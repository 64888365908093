/* eslint-disable */

import React, { Component } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import HorizontalTimeline from "react-horizontal-timeline";
import { handleRequest } from '../../redux/config/env';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Popover, Button as AntButton, DatePicker, InputNumber, message } from 'antd';
//import Loader from 'react-loader-spinner';
import moment from 'moment';

const { RangePicker } = DatePicker;

export class DailyShipments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            date: null,
            visible: false,
            trackDisplay: 'none',
            traceDisplay: 'none',
            assignmentLogs: [],
            shipmentLogs: [],
            drivers: [],
            driverDatas: [],
            locationAlertDatas: [],
            curIdx: 0,
            prevIdx: -1,
            showAssignmentLogs: false,
            driversCheckBox: [],
            startDate: null,
            endDate: null,
            frequencyGetLocation: "",
            frequencyGetLocationId: null,
            idleTimeAlert: "",
            idleTimeAlertId: null,
            deviceIds: [],
            driverIds: [],
            driverDeviceIds: []
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount = async () => {
        var today = new Date();
        var todayForDB = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        //var dd1 = String(today.getDate() - 1).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        // today = dd + '/' + mm + '/' + yyyy;
        // var today1 = dd1 + '/' + mm + '/' + yyyy;

        today = dd + '/' + mm + '/' + yyyy + ' 23:59:59';
        var today1 = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

        var todayX = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: todayX,
            startDate: today1,
            endDate: today
        });

        const response2 = await handleRequest("GET", "/TrailerLocationFrequency/getCompanyItems");

        if (response2.data.length === 0) {
            this.setState({
                frequencyGetLocationId: null,
                frequencyGetLocation: ""
            });
        }

        if (response2.data.length !== 0) {
            for (let index = 0; index < response2.data.length; index++) {
                let flagFreqValue = parseFloat(response2.data[index].minValue);
                let flagFreqId = response2.data[index].id;
                this.setState({
                    frequencyGetLocationId: flagFreqId,
                    frequencyGetLocation: flagFreqValue
                });
            }
        }

        const response3 = await handleRequest("GET", "/TrailerLocationAlert/getCompanyItems");

        if (response3.data.length === 0) {
            this.setState({
                idleTimeAlert: "",
                idleTimeAlertDB: 0,
                idleTimeAlertId: null
            });
        }

        if (response3.data.length !== 0) {
            for (let index = 0; index < response3.data.length; index++) {
                let flagAlertTimeValue = "";
                let flagAlertTimeId = response3.data[index].id;

                if (response3.data[index].alertTime !== null) {
                    flagAlertTimeValue = parseFloat(response3.data[index].alertTime);
                }

                this.setState({
                    idleTimeAlert: flagAlertTimeValue,
                    idleTimeAlertDB: flagAlertTimeValue,
                    idleTimeAlertId: flagAlertTimeId
                });
            }
        }

        const response4 = await handleRequest("GET", "/TrailerLocation/getSelectedDate/" + todayForDB.getTime());

        const response5 = await handleRequest("GET", "/StaffDevice/getAll");

        const uniqueIds = [];
        const driverIds = [];
        const driverDeviceIds = [];

        const columns1 = response5.data;

        if (response4.data.length !== 0) {
            const columns = response4.data;
            columns.map(row => {
                if (uniqueIds.indexOf(row.deviceId) === -1) {
                    for (let index = 0; index < columns1.length; index++) {
                        if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                            driverIds.push(columns1[index].staffId);
                            driverDeviceIds.push(row.deviceId);
                        }
                    }
                    uniqueIds.push(row.deviceId)
                }
            });

            this.setState({
                deviceIds: uniqueIds,
                driverIds: driverIds,
                driverDeviceIds: driverDeviceIds
            });
        }

        let driverLastLocationTimes = new Array(driverIds.length);

        for (let index = 0; index < driverDeviceIds.length; index++) {
            for (let index1 = 0; index1 < response4.data.length; index1++) {
                if (response4.data[index1].deviceId === driverDeviceIds[index]) {
                    let flagCreateDate = new Date(Date.parse(response4.data[index1].createDate));
                    //Bu 3 saat sunucudan kaynaklı sanırım, java'dan 3 saat fazla çekiyor.
                    flagCreateDate.setTime(flagCreateDate.getTime());
                    //her sürücünün son görülme saatleri
                    driverLastLocationTimes[index] = { lastSeenDate: flagCreateDate, driverId: driverIds[index] };
                }
            }
        }

        const response = await handleRequest("GET", "/Assignment/getCompanyItems");

        var driverAssignmentData = [];

        if (response.data.length === 0) {
            this.setState({
                showAssignmentLogs: false,
                trackDisplay: 'none',
                traceDisplay: 'none',
                assignmentLogs: [],
                shipmentLogs: [],
                drivers: [],
                driverDatas: [],
            });
        }

        let dateString = todayX;

        let date = new Date();

        if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {

                let flagDate = response.data[index].startDate.split(" ")[0];
                const [day, month, year] = flagDate.split("/");
                let flagstartDate = new Date(year, month - 1, day);

                flagDate = response.data[index].endDate.split(" ")[0];
                const [day1, month1, year1] = flagDate.split("/");
                let flagEndDate = new Date(year1, month1 - 1, day1);

                if (date >= flagstartDate && date <= flagEndDate) {
                    if (response.data[index].roleGroupId !== null) {
                        if (response.data[index].equipmentLog !== null) {
                            if (response.data[index].equipmentLog.equipmentVehicleLicenceList.length > 0) {
                                driverAssignmentData.push(response.data[index]);
                            }
                        }
                    }
                }

            }

            if (driverAssignmentData.length > 0) {
                this.setState({
                    showAssignmentLogs: true
                });
            } else {
                this.setState({
                    showAssignmentLogs: false
                });
            }

            let drivers = [];

            for (let index = 0; index < driverAssignmentData.length; index++) {
                if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                    drivers.push(driverAssignmentData[index].staffId);
                }
            }

            let locationAlertDatas = new Array(drivers.length);
            let locationAlertForPage = new Array(drivers.length);

            for (let index = 0; index < drivers.length; index++) {
                for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                    if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                        locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                    }
                }
            }

            var today2 = new Date();
            for (let index = 0; index < locationAlertDatas.length; index++) {
                if (locationAlertDatas[index] !== null && locationAlertDatas[index] !== undefined) {
                    if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                        let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                        locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                    } else {
                        locationAlertForPage[index] = "";
                    }
                }
            }

            var driverDatas = new Array(drivers.length);

            for (let index = 0; index < drivers.length; index++) {
                driverDatas[index] = [];
            }

            const response1 = await handleRequest("GET", "/Shipment/getSelectedDate/" + todayForDB.getTime());

            var driverShipmentData = [];

            if (response1.data.length !== 0) {
                for (let index = 0; index < response1.data.length; index++) {
                    if (dateString === response1.data[index].shipmentDay) {
                        driverShipmentData.push(response1.data[index]);
                    }
                }

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverShipmentData.length; index1++) {
                        if (drivers[index] === driverShipmentData[index1].staffId) {
                            driverDatas[index].push(driverShipmentData[index1]);
                        }
                    }
                }
            }

            this.setState({
                trackDisplay: 'block',
                traceDisplay: 'block',
                assignmentLogs: driverAssignmentData,
                shipmentLogs: driverShipmentData,
                drivers: drivers,
                driverDatas: driverDatas,
                locationAlertDatas: locationAlertForPage
            });
        }
    }

    onChangeDate = async (date, dateString) => {
        this.setState({
            trackDisplay: 'none',
            traceDisplay: 'none',
            assignmentLogs: [],
            shipmentLogs: [],
            drivers: [],
            driverDatas: [],
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date: dateString
            });
        } else {
            this.setState({
                date: today
            });
        }

        var today1 = new Date();

        const response3 = await handleRequest("GET", "/TrailerLocationAlert/getCompanyItems");

        if (response3.data.length === 0) {
            this.setState({
                idleTimeAlert: "",
                idleTimeAlertDB: 0,
                idleTimeAlertId: null
            });
        }

        if (response3.data.length !== 0) {
            for (let index = 0; index < response3.data.length; index++) {
                let flagAlertTimeValue = "";
                let flagAlertTimeId = response3.data[index].id;

                if (response3.data[index].alertTime !== null) {
                    flagAlertTimeValue = parseFloat(response3.data[index].alertTime);
                }

                this.setState({
                    idleTimeAlert: flagAlertTimeValue,
                    idleTimeAlertDB: flagAlertTimeValue,
                    idleTimeAlertId: flagAlertTimeId
                });
            }
        }

        const response4 = await handleRequest("GET", "/TrailerLocation/getSelectedDate/" + date._d.getTime());
        const response5 = await handleRequest("GET", "/StaffDevice/getAll");

        const uniqueIds = [];
        const driverIds = [];
        const driverDeviceIds = [];

        const columns1 = response5.data;

        if (response4.data.length !== 0) {
            const columns = response4.data;
            columns.map(row => {
                if (uniqueIds.indexOf(row.deviceId) === -1) {
                    for (let index = 0; index < columns1.length; index++) {
                        if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                            driverIds.push(columns1[index].staffId);
                            driverDeviceIds.push(row.deviceId);
                        }
                    }
                    uniqueIds.push(row.deviceId)
                }
            });

            this.setState({
                deviceIds: uniqueIds,
                driverIds: driverIds,
                driverDeviceIds: driverDeviceIds
            });
        }

        let driverLastLocationTimes = new Array(driverIds.length);

        for (let index = 0; index < driverDeviceIds.length; index++) {
            for (let index1 = 0; index1 < response4.data.length; index1++) {
                if (response4.data[index1].deviceId === driverDeviceIds[index]) {
                    let flagCreateDate = new Date(Date.parse(response4.data[index1].createDate));
                    //Bu 3 saat sunucudan kaynaklı sanırım, java'dan 3 saat fazla çekiyor.
                    flagCreateDate.setTime(flagCreateDate.getTime());
                    //her sürücünün son görülme saatleri
                    driverLastLocationTimes[index] = { lastSeenDate: flagCreateDate, driverId: driverIds[index] };
                }
            }
        }

        if (date <= today1) {

            const response = await handleRequest("GET", "/Assignment/getCompanyItems");

            var driverAssignmentData = [];

            if (response.data.length === 0) {
                this.setState({
                    showAssignmentLogs: false,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    assignmentLogs: [],
                    shipmentLogs: [],
                    drivers: [],
                    driverDatas: [],
                });
            }

            var flagDate1 = new Date();
            dd = "";
            mm = "";
            yyyy = "";
            if (date !== null) {
                dd = String(date._d.getDate()).padStart(2, '0');
                mm = String(date._d.getMonth() + 1).padStart(2, '0'); //January is 0!
                yyyy = date._d.getFullYear();
            } else {
                dd = String(flagDate1.getDate()).padStart(2, '0');
                mm = String(flagDate1.getMonth() + 1).padStart(2, '0'); //January is 0!
                yyyy = flagDate1.getFullYear();
            }

            flagDate1 = dd + '/' + mm + '/' + yyyy;

            let dateString = flagDate1;

            if (response.data.length !== 0) {
                for (let index = 0; index < response.data.length; index++) {

                    let flagDate = response.data[index].startDate.split(" ")[0];
                    const [day, month, year] = flagDate.split("/");
                    let flagstartDate = new Date(year, month - 1, day);

                    flagDate = response.data[index].endDate.split(" ")[0];
                    const [day1, month1, year1] = flagDate.split("/");
                    let flagEndDate = new Date(year1, month1 - 1, day1);

                    if (date >= flagstartDate && date <= flagEndDate) {
                        if (response.data[index].roleGroupId !== null) {
                            if (response.data[index].equipmentLog !== null) {
                                if (response.data[index].equipmentLog.equipmentVehicleLicenceList.length > 0) {
                                    driverAssignmentData.push(response.data[index]);
                                }
                            }
                        }
                    }
                }

                if (driverAssignmentData.length > 0) {
                    this.setState({
                        showAssignmentLogs: true
                    });
                } else {
                    this.setState({
                        showAssignmentLogs: false
                    });
                }

                let drivers = [];

                for (let index = 0; index < driverAssignmentData.length; index++) {
                    if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                        drivers.push(driverAssignmentData[index].staffId);
                    }
                }

                let locationAlertDatas = new Array(drivers.length);
                let locationAlertForPage = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                        if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                            locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                        }
                    }
                }

                var today2 = new Date();
                for (let index = 0; index < locationAlertDatas.length; index++) {
                    if (locationAlertDatas[index] !== null && locationAlertDatas[index] !== undefined) {
                        if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                            let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                            locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                        } else {
                            locationAlertForPage[index] = "";
                        }
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                const response1 = await handleRequest("GET", "/Shipment/getSelectedDate/" + date._d.getTime());

                var driverShipmentData = [];  //TODO

                if (response1.data.length !== 0) {
                    for (let index = 0; index < response1.data.length; index++) {
                        if (dateString === response1.data[index].shipmentDay) {
                            driverShipmentData.push(response1.data[index]);
                        }
                    }

                    for (let index = 0; index < drivers.length; index++) {
                        for (let index1 = 0; index1 < driverShipmentData.length; index1++) {
                            if (drivers[index] === driverShipmentData[index1].staffId) {
                                driverDatas[index].push(driverShipmentData[index1]);
                            }
                        }
                    }
                }

                this.setState({
                    trackDisplay: 'block',
                    traceDisplay: 'block',
                    assignmentLogs: driverAssignmentData,
                    shipmentLogs: driverShipmentData,
                    drivers: drivers,
                    driverDatas: driverDatas,
                    locationAlertDatas: locationAlertForPage
                });
            }
        } else {

            const response2 = await handleRequest("GET", "/Assignment/getCompanyItems");

            var driverAssignmentData = [];

            if (response2.data.length === 0) {
                this.setState({
                    showAssignmentLogs: false,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    assignmentLogs: [],
                    shipmentLogs: [],
                    drivers: [],
                    driverDatas: [],
                });
            }

            if (response2.data.length !== 0) {

                for (let index = 0; index < response2.data.length; index++) {

                    let flagDate = response2.data[index].startDate.split(" ")[0];
                    const [day, month, year] = flagDate.split("/");
                    let flagstartDate = new Date(year, month - 1, day);

                    flagDate = response2.data[index].endDate.split(" ")[0];
                    const [day1, month1, year1] = flagDate.split("/");
                    let flagEndDate = new Date(year1, month1 - 1, day1);

                    if (date._d >= flagstartDate && date._d <= flagEndDate) {
                        driverAssignmentData.push(response2.data[index]);
                    }
                }

                if (driverAssignmentData.length > 0) {
                    this.setState({
                        showAssignmentLogs: true
                    });
                } else {
                    this.setState({
                        showAssignmentLogs: false
                    });
                }

                let drivers = [];

                for (let index = 0; index < driverAssignmentData.length; index++) {
                    if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                        drivers.push(driverAssignmentData[index].staffId);
                    }
                }

                let locationAlertDatas = new Array(drivers.length);
                let locationAlertForPage = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                        if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                            locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                        }
                    }
                }

                var today2 = new Date();
                for (let index = 0; index < locationAlertDatas.length; index++) {
                    if (locationAlertDatas[index] !== null && locationAlertDatas[index] !== undefined) {
                        if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                            let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                            locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                        } else {
                            locationAlertForPage[index] = "";
                        }
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                this.setState({
                    assignmentLogs: driverAssignmentData,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    shipmentLogs: [],
                    drivers: drivers,
                    driverDatas: driverDatas,
                    locationAlertDatas: locationAlertForPage
                });
            }
        }
    }

    onDriverChange = (e) => {

        let selectedDrivers = [...this.state.driversCheckBox];

        if (e.checked)
            selectedDrivers.push(e.value);
        else
            selectedDrivers.splice(selectedDrivers.indexOf(e.value), 1);

        this.setState({ driversCheckBox: selectedDrivers });

    }

    gotoLocationPageTrack = () => {
        let urlParser = "";

        for (let index = 0; index < this.state.driversCheckBox.length; index++) {
            urlParser += this.state.driversCheckBox[index].toString() + "&";
        }

        if (urlParser.length > 1) {
            urlParser = urlParser.slice(0, -1);
        }

        if (urlParser !== "") {
            window.location.href = '/TrailerLocation2/' + urlParser;
        }
        else {
            this.showError();
        }
    }

    gotoLocationPageTrace = () => {
        let urlParser = "";

        for (let index = 0; index < this.state.driversCheckBox.length; index++) {
            urlParser += this.state.driversCheckBox[index].toString() + "&";
        }

        if (urlParser.length > 1) {
            urlParser = urlParser.slice(0, -1);
        }

        let startDate = new Date(this.state.startDate);
        let endDate = new Date(this.state.endDate);

        if (isNaN(startDate.getTime())) {
            startDate = new Date();
        }

        if (isNaN(endDate.getTime())) {
            let now = new Date();
            let defaultDate = now - 1000 * 60 * 60 * 24 * 1;
            endDate = new Date(defaultDate);
        }

        if (urlParser !== "" && !(isNaN(startDate.getTime())) && !(isNaN(endDate.getTime()))) {
            window.location.href = '/TrailerLocation2/' + urlParser + '&' + startDate.getTime() + '&' + endDate.getTime();
        }
        else {
            this.showError();
        }
    }

    getWorkingHours = () => {

        console.log(this.state.driversCheckBox);  //TODO: staff id'leri geliyor buradan.

        let driverIds = this.state.driversCheckBox;

        for (let index = 0; index < driverIds.length; index++) {

            //const element = driverIds[index];

        }  //Buradan staff'lar çekilerek shift'lerine ulaşılabilir.

        //Kesişim kümeleri en büyük olacak şekilde tarih kısmını set et...

        // if (date !== null) {
        //     this.setState({
        //         startDate: date[0]._d,
        //         endDate: date[1]._d
        //     });
        // } else {
        //     var today = new Date();
        //     var dd = String(today.getDate()).padStart(2, '0');
        //     var dd1 = String(today.getDate() - 1).padStart(2, '0');
        //     var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        //     var yyyy = today.getFullYear();

        //     today = dd + '/' + mm + '/' + yyyy;
        //     var today1 = dd1 + '/' + mm + '/' + yyyy;

        //     this.setState({
        //         startDate: today1,
        //         endDate: today
        //     });
        // }
    }

    hide = () => {
        this.setState({
            visible: false,
        });
    }

    handleVisibleChange = visible => {
        this.setState({ visible });
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Please choose a driver/drivers.' });
    }

    disabledDate = (current) => {
        return current > moment().endOf('day');
    }

    onChange2 = (date, dateString) => {
        if (date !== null) {

            date[0]._d.setSeconds(0);
            date[1]._d.setSeconds(59);

            this.setState({
                startDate: date[0]._d,
                endDate: date[1]._d
            });
        } else {
            var today = new Date();
            // var ss = String(today.setSeconds(0));
            // var ss1 = String(today.setSeconds(59));

            var dd = String(today.getDate()).padStart(2, '0');

            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 23:59:59';
            var today1 = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                startDate: today1,
                endDate: today
            });
        }
    }

    changePrevDay = async () => {

        var dd = "";
        var mm = "";
        var yyyy = "";

        this.setState({
            trackDisplay: 'none',
            traceDisplay: 'none',
            assignmentLogs: [],
            shipmentLogs: [],
            drivers: [],
            driverDatas: [],
        });

        var flagDay = (Number(moment(this.state.date, 'DD/MM/YYYY')._d) - (1000 * 60 * 60 * 24));
        var dayForDate = new Date(flagDay);

        this.setState({
            date: dayForDate
        });

        var today1 = new Date();

        const response4 = await handleRequest("GET", "/TrailerLocation/getSelectedDate/" + dayForDate.getTime());
        const response5 = await handleRequest("GET", "/StaffDevice/getAll");

        const uniqueIds = [];
        const driverIds = [];
        const driverDeviceIds = [];

        const columns1 = response5.data;

        if (response4.data.length !== 0) {
            const columns = response4.data;
            columns.map(row => {
                if (uniqueIds.indexOf(row.deviceId) === -1) {
                    for (let index = 0; index < columns1.length; index++) {
                        if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                            driverIds.push(columns1[index].staffId);
                            driverDeviceIds.push(row.deviceId);
                        }
                    }
                    uniqueIds.push(row.deviceId)
                }
            });

            this.setState({
                deviceIds: uniqueIds,
                driverIds: driverIds,
                driverDeviceIds: driverDeviceIds
            });
        }

        let driverLastLocationTimes = new Array(driverIds.length);

        for (let index = 0; index < driverDeviceIds.length; index++) {
            for (let index1 = 0; index1 < response4.data.length; index1++) {
                if (response4.data[index1].deviceId === driverDeviceIds[index]) {
                    let flagCreateDate = new Date(Date.parse(response4.data[index1].createDate));
                    //Bu 3 saat sunucudan kaynaklı sanırım, java'dan 3 saat fazla çekiyor.
                    flagCreateDate.setTime(flagCreateDate.getTime());
                    //her sürücünün son görülme saatleri
                    driverLastLocationTimes[index] = { lastSeenDate: flagCreateDate, driverId: driverIds[index] };
                }
            }
        }

        if (dayForDate <= today1) {

            const response = await handleRequest("GET", "/Assignment/getCompanyItems");

            var driverAssignmentData = [];

            if (response.data.length === 0) {
                this.setState({
                    showAssignmentLogs: false,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    assignmentLogs: [],
                    shipmentLogs: [],
                    drivers: [],
                    driverDatas: [],
                });
            }

            var flagDate1 = new Date();
            dd = "";
            mm = "";
            yyyy = "";
            if (dayForDate !== null) {
                dd = String(dayForDate.getDate()).padStart(2, '0');
                mm = String(dayForDate.getMonth() + 1).padStart(2, '0'); //January is 0!
                yyyy = dayForDate.getFullYear();
            } else {
                dd = String(flagDate1.getDate()).padStart(2, '0');
                mm = String(flagDate1.getMonth() + 1).padStart(2, '0'); //January is 0!
                yyyy = flagDate1.getFullYear();
            }

            flagDate1 = dd + '/' + mm + '/' + yyyy;

            let dateString = flagDate1;

            if (response.data.length !== 0) {
                for (let index = 0; index < response.data.length; index++) {

                    let flagDate = response.data[index].startDate.split(" ")[0];
                    const [day, month, year] = flagDate.split("/");
                    let flagstartDate = new Date(year, month - 1, day);

                    flagDate = response.data[index].endDate.split(" ")[0];
                    const [day1, month1, year1] = flagDate.split("/");
                    let flagEndDate = new Date(year1, month1 - 1, day1);

                    if (dayForDate >= flagstartDate && dayForDate <= flagEndDate) {
                        if (response.data[index].roleGroupId !== null) {
                            if (response.data[index].equipmentLog !== null) {
                                if (response.data[index].equipmentLog.equipmentVehicleLicenceList.length > 0) {
                                    driverAssignmentData.push(response.data[index]);
                                }
                            }
                        }
                    }
                }

                if (driverAssignmentData.length > 0) {
                    this.setState({
                        showAssignmentLogs: true
                    });
                } else {
                    this.setState({
                        showAssignmentLogs: false
                    });
                }

                let drivers = [];

                for (let index = 0; index < driverAssignmentData.length; index++) {
                    if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                        drivers.push(driverAssignmentData[index].staffId);
                    }
                }

                let locationAlertDatas = new Array(drivers.length);
                let locationAlertForPage = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                        if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                            locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                        }
                    }
                }

                var today2 = new Date();
                for (let index = 0; index < locationAlertDatas.length; index++) {
                    if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                        let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                        locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                    } else {
                        locationAlertForPage[index] = "";
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                const response1 = await handleRequest("GET", "/Shipment/getSelectedDate/" + dayForDate.getTime());

                var driverShipmentData = [];  //TODO

                if (response1.data.length !== 0) {
                    for (let index = 0; index < response1.data.length; index++) {
                        if (dateString === response1.data[index].shipmentDay) {
                            driverShipmentData.push(response1.data[index]);
                        }
                    }

                    for (let index = 0; index < drivers.length; index++) {
                        for (let index1 = 0; index1 < driverShipmentData.length; index1++) {
                            if (drivers[index] === driverShipmentData[index1].staffId) {
                                driverDatas[index].push(driverShipmentData[index1]);
                            }
                        }
                    }
                }

                this.setState({
                    trackDisplay: 'block',
                    traceDisplay: 'block',
                    assignmentLogs: driverAssignmentData,
                    shipmentLogs: driverShipmentData,
                    drivers: drivers,
                    driverDatas: driverDatas,
                    locationAlertDatas: locationAlertForPage
                });
            }
        } else {

            const response2 = await handleRequest("GET", "/Assignment/getCompanyItems");

            var driverAssignmentData = [];

            if (response2.data.length === 0) {
                this.setState({
                    showAssignmentLogs: false,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    assignmentLogs: [],
                    shipmentLogs: [],
                    drivers: [],
                    driverDatas: [],
                });
            }

            if (response2.data.length !== 0) {

                for (let index = 0; index < response2.data.length; index++) {

                    let flagDate = response2.data[index].startDate.split(" ")[0];
                    const [day, month, year] = flagDate.split("/");
                    let flagstartDate = new Date(year, month - 1, day);

                    flagDate = response2.data[index].endDate.split(" ")[0];
                    const [day1, month1, year1] = flagDate.split("/");
                    let flagEndDate = new Date(year1, month1 - 1, day1);

                    if (dayForDate >= flagstartDate && dayForDate <= flagEndDate) {
                        driverAssignmentData.push(response2.data[index]);
                    }
                }

                if (driverAssignmentData.length > 0) {
                    this.setState({
                        showAssignmentLogs: true
                    });
                } else {
                    this.setState({
                        showAssignmentLogs: false
                    });
                }

                let drivers = [];

                for (let index = 0; index < driverAssignmentData.length; index++) {
                    if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                        drivers.push(driverAssignmentData[index].staffId);
                    }
                }

                let locationAlertDatas = new Array(drivers.length);
                let locationAlertForPage = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                        if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                            locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                        }
                    }
                }

                var today2 = new Date();
                for (let index = 0; index < locationAlertDatas.length; index++) {
                    if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                        let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                        locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                    } else {
                        locationAlertForPage[index] = "";
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                this.setState({
                    assignmentLogs: driverAssignmentData,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    shipmentLogs: [],
                    drivers: drivers,
                    driverDatas: driverDatas,
                    locationAlertDatas: locationAlertForPage
                });
            }
        }
    }

    changeNextDay = async () => {

        var dd = "";
        var mm = "";
        var yyyy = "";

        this.setState({
            trackDisplay: 'none',
            traceDisplay: 'none',
            assignmentLogs: [],
            shipmentLogs: [],
            drivers: [],
            driverDatas: [],
        });

        var flagDay = (Number(moment(this.state.date, 'DD/MM/YYYY')._d) + (1000 * 60 * 60 * 24));
        var dayForDate = new Date(flagDay);

        this.setState({
            date: dayForDate
        });

        var today1 = new Date();

        const response4 = await handleRequest("GET", "/TrailerLocation/getSelectedDate/" + dayForDate.getTime());
        const response5 = await handleRequest("GET", "/StaffDevice/getAll");

        const uniqueIds = [];
        const driverIds = [];
        const driverDeviceIds = [];

        const columns1 = response5.data;

        if (response4.data.length !== 0) {
            const columns = response4.data;
            columns.map(row => {
                if (uniqueIds.indexOf(row.deviceId) === -1) {
                    for (let index = 0; index < columns1.length; index++) {
                        if ((row.deviceId === columns1[index].deviceId) && (driverIds.indexOf(columns1[index].staffId) === -1)) {
                            driverIds.push(columns1[index].staffId);
                            driverDeviceIds.push(row.deviceId);
                        }
                    }
                    uniqueIds.push(row.deviceId)
                }
            });

            this.setState({
                deviceIds: uniqueIds,
                driverIds: driverIds,
                driverDeviceIds: driverDeviceIds
            });
        }

        let driverLastLocationTimes = new Array(driverIds.length);

        for (let index = 0; index < driverDeviceIds.length; index++) {
            for (let index1 = 0; index1 < response4.data.length; index1++) {
                if (response4.data[index1].deviceId === driverDeviceIds[index]) {
                    let flagCreateDate = new Date(Date.parse(response4.data[index1].createDate));
                    //Bu 3 saat sunucudan kaynaklı sanırım, java'dan 3 saat fazla çekiyor.
                    flagCreateDate.setTime(flagCreateDate.getTime());
                    //her sürücünün son görülme saatleri
                    driverLastLocationTimes[index] = { lastSeenDate: flagCreateDate, driverId: driverIds[index] };
                }
            }
        }

        if (dayForDate <= today1) {

            const response = await handleRequest("GET", "/Assignment/getCompanyItems");

            var driverAssignmentData = [];

            if (response.data.length === 0) {
                this.setState({
                    showAssignmentLogs: false,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    assignmentLogs: [],
                    shipmentLogs: [],
                    drivers: [],
                    driverDatas: [],
                });
            }

            var flagDate1 = new Date();
            dd = "";
            mm = "";
            yyyy = "";
            if (dayForDate !== null) {
                dd = String(dayForDate.getDate()).padStart(2, '0');
                mm = String(dayForDate.getMonth() + 1).padStart(2, '0'); //January is 0!
                yyyy = dayForDate.getFullYear();
            } else {
                dd = String(flagDate1.getDate()).padStart(2, '0');
                mm = String(flagDate1.getMonth() + 1).padStart(2, '0'); //January is 0!
                yyyy = flagDate1.getFullYear();
            }

            flagDate1 = dd + '/' + mm + '/' + yyyy;

            let dateString = flagDate1;

            if (response.data.length !== 0) {
                for (let index = 0; index < response.data.length; index++) {

                    let flagDate = response.data[index].startDate.split(" ")[0];
                    const [day, month, year] = flagDate.split("/");
                    let flagstartDate = new Date(year, month - 1, day);

                    flagDate = response.data[index].endDate.split(" ")[0];
                    const [day1, month1, year1] = flagDate.split("/");
                    let flagEndDate = new Date(year1, month1 - 1, day1);

                    if (dayForDate >= flagstartDate && dayForDate <= flagEndDate) {
                        if (response.data[index].roleGroupId !== null) {
                            if (response.data[index].equipmentLog !== null) {
                                if (response.data[index].equipmentLog.equipmentVehicleLicenceList.length > 0) {
                                    driverAssignmentData.push(response.data[index]);
                                }
                            }
                        }
                    }
                }

                if (driverAssignmentData.length > 0) {
                    this.setState({
                        showAssignmentLogs: true
                    });
                } else {
                    this.setState({
                        showAssignmentLogs: false
                    });
                }

                let drivers = [];

                for (let index = 0; index < driverAssignmentData.length; index++) {
                    if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                        drivers.push(driverAssignmentData[index].staffId);
                    }
                }

                let locationAlertDatas = new Array(drivers.length);
                let locationAlertForPage = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                        if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                            locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                        }
                    }
                }

                var today2 = new Date();
                for (let index = 0; index < locationAlertDatas.length; index++) {
                    if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                        let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                        locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                    } else {
                        locationAlertForPage[index] = "";
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                const response1 = await handleRequest("GET", "/Shipment/getSelectedDate/" + dayForDate.getTime());

                var driverShipmentData = [];  //TODO

                if (response1.data.length !== 0) {
                    for (let index = 0; index < response1.data.length; index++) {
                        if (dateString === response1.data[index].shipmentDay) {
                            driverShipmentData.push(response1.data[index]);
                        }
                    }

                    for (let index = 0; index < drivers.length; index++) {
                        for (let index1 = 0; index1 < driverShipmentData.length; index1++) {
                            if (drivers[index] === driverShipmentData[index1].staffId) {
                                driverDatas[index].push(driverShipmentData[index1]);
                            }
                        }
                    }
                }

                this.setState({
                    trackDisplay: 'block',
                    traceDisplay: 'block',
                    assignmentLogs: driverAssignmentData,
                    shipmentLogs: driverShipmentData,
                    drivers: drivers,
                    driverDatas: driverDatas,
                    locationAlertDatas: locationAlertForPage
                });
            }
        } else {

            const response2 = await handleRequest("GET", "/Assignment/getAll");

            var driverAssignmentData = [];

            if (response2.data.length === 0) {
                this.setState({
                    showAssignmentLogs: false,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    assignmentLogs: [],
                    shipmentLogs: [],
                    drivers: [],
                    driverDatas: [],
                });
            }

            if (response2.data.length !== 0) {

                for (let index = 0; index < response2.data.length; index++) {

                    let flagDate = response2.data[index].startDate.split(" ")[0];
                    const [day, month, year] = flagDate.split("/");
                    let flagstartDate = new Date(year, month - 1, day);

                    flagDate = response2.data[index].endDate.split(" ")[0];
                    const [day1, month1, year1] = flagDate.split("/");
                    let flagEndDate = new Date(year1, month1 - 1, day1);

                    if (dayForDate >= flagstartDate && dayForDate <= flagEndDate) {
                        driverAssignmentData.push(response2.data[index]);
                    }
                }

                if (driverAssignmentData.length > 0) {
                    this.setState({
                        showAssignmentLogs: true
                    });
                } else {
                    this.setState({
                        showAssignmentLogs: false
                    });
                }

                let drivers = [];

                for (let index = 0; index < driverAssignmentData.length; index++) {
                    if (!(drivers.includes(driverAssignmentData[index].staffId))) {
                        drivers.push(driverAssignmentData[index].staffId);
                    }
                }

                let locationAlertDatas = new Array(drivers.length);
                let locationAlertForPage = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverLastLocationTimes.length; index1++) {
                        if (drivers[index] === driverLastLocationTimes[index1].driverId) {
                            locationAlertDatas[index] = driverLastLocationTimes[index1].lastSeenDate;
                        }
                    }
                }

                var today2 = new Date();
                for (let index = 0; index < locationAlertDatas.length; index++) {
                    if (((today2.getTime() - locationAlertDatas[index].getTime()) / 60000) > (this.state.idleTimeAlert)) {
                        let htmlString = ((today2.getTime() - locationAlertDatas[index].getTime()) / 60000).toFixed(0);
                        locationAlertForPage[index] = "No any signal since " + htmlString + " min.";
                    } else {
                        locationAlertForPage[index] = "";
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                this.setState({
                    assignmentLogs: driverAssignmentData,
                    trackDisplay: 'none',
                    traceDisplay: 'none',
                    shipmentLogs: [],
                    drivers: drivers,
                    driverDatas: driverDatas,
                    locationAlertDatas: locationAlertForPage
                });
            }
        }
    }

    onChangeFrequencyGetLocation = (value) => {
        this.setState({
            frequencyGetLocation: value
        });
    }

    setFreq = async () => {
        if (this.state.frequencyGetLocationId === null) {
            const newFreq = {
                minValue: this.state.frequencyGetLocation.toString()
            }

            await handleRequest("POST", "/TrailerLocationFrequency/add", newFreq);

            this.success();
        } else {
            const updatedFreq = {
                id: this.state.frequencyGetLocationId,
                minValue: this.state.frequencyGetLocation.toString()
            }

            await handleRequest("POST", "/TrailerLocationFrequency/update", updatedFreq);
            this.success();
        }
    }

    onChangeIdleAlertTime = (value) => {
        this.setState({
            idleTimeAlert: value
        });
    }

    setAlertTime = async () => {
        if (this.state.idleTimeAlertId === null) {
            const newFreq = {
                minValue: this.state.idleTimeAlert.toString()
            }

            await handleRequest("POST", "/TrailerLocationAlert/add", newFreq);

            this.success2();
        } else {
            const updatedFreq = {
                id: this.state.idleTimeAlertId,
                alertTime: this.state.idleTimeAlert.toString()
            }

            await handleRequest("POST", "/TrailerLocationAlert/update", updatedFreq);
            this.success2();
        }
    }

    success = () => {
        message.success("The Frequency of location data has been set.");
    }

    success2 = () => {
        message.success("The Idle Time Alert data has been set.");
    }

    render() {
        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>
                <Toast ref={(el) => this.toast = el} />
                <div style={{ padding: "8px 16px 8px 24px" }}>
                    <Row>
                        <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Date:</Form.Label></Col>
                        <Col xs={2}><DatePicker value={moment(this.state.date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeDate} /></Col>
                        <Col xs={1} style={{ textAlign: 'left' }} >
                            <AntButton onClick={this.changePrevDay} type="primary">Previous Day</AntButton>
                        </Col>
                        <Col xs={1} style={{ textAlign: 'left' }} >
                            <AntButton onClick={this.changeNextDay} type="primary">Next Day</AntButton>
                        </Col>
                        <Col xs={2} style={{ textAlign: 'center' }}>
                            <AntButton style={{ width: '90%', display: this.state.trackDisplay }} onClick={this.gotoLocationPageTrack} type="primary">Track</AntButton>
                        </Col>
                        <Col xs={2} style={{ textAling: 'center' }}>
                            <Popover
                                content={
                                    <Form>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <RangePicker showTime={{ format: 'HH:mm' }} value={[moment(this.state.startDate, 'DD/MM/YYYY HH:mm:ss'),
                                                moment(this.state.endDate, 'DD/MM/YYYY HH:mm:ss')]} disabledDate={this.disabledDate}
                                                    format="DD/MM/YYYY HH:mm:ss" onChange={this.onChange2} />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row style={{ textAlign: 'right' }}>
                                            <Form.Group as={Col}>
                                                <AntButton onClick={this.getWorkingHours} type="primary">Get Working Hours</AntButton>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <AntButton style={{ width: '40%' }} onClick={this.gotoLocationPageTrace} type="primary">Show</AntButton>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                }
                                title="Choose Start & End Date"
                                trigger="click"
                                visible={this.state.visible}
                                onVisibleChange={this.handleVisibleChange}
                            >
                                <AntButton style={{ width: '90%', display: this.state.traceDisplay }} type="primary">Trace</AntButton>
                            </Popover>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>
                    <Row style={{ marginTop: '15px' }}>
                        <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>The Freq. of Location Data (min.):</Form.Label></Col>
                        <Col xs={2}><InputNumber style={{ width: '90%' }} min={0} value={this.state.frequencyGetLocation} onChange={this.onChangeFrequencyGetLocation} /></Col>
                        <Col xs={1} style={{ textAlign: 'left' }} >
                            <AntButton onClick={this.setFreq} type="primary">Set Frequency</AntButton>
                        </Col>
                        <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Idle Time Alert (min.):</Form.Label></Col>
                        <Col xs={2}><InputNumber style={{ width: '90%' }} min={0} value={this.state.idleTimeAlert} onChange={this.onChangeIdleAlertTime} /></Col>
                        <Col xs={1} style={{ textAlign: 'left' }} >
                            <AntButton onClick={this.setAlertTime} type="primary">Set Idle Time Alert</AntButton>
                        </Col>
                    </Row>
                </div>

                <div style={{ marginTop: '2rem' }}>
                    <Form>
                        <Form.Row>
                            <Col xs={1}>
                            </Col>
                            <Col xs={1}>
                                <Form.Label style={{ marginTop: '0.5rem', color: 'black', fontWeight: 'bold' }}>Driver</Form.Label>
                            </Col>
                            <Col xs={2}>
                                <Form.Label style={{ marginTop: '0.5rem', color: 'black', fontWeight: 'bold' }}>Equipment</Form.Label>
                            </Col>
                            <Col xs={1}>
                            </Col>
                            <Col xs={5}>
                                <Form.Label style={{ marginTop: '0.5rem', color: 'black', fontWeight: 'bold' }}>Trips</Form.Label>
                            </Col>
                            <Col xs={2}>
                            </Col>
                        </Form.Row>

                        {this.state.showAssignmentLogs ? (
                            <div>
                                {this.state.assignmentLogs.map(data1 => (
                                    <Form.Row key={data1.id}>
                                        <Col xs={1}>
                                            <div style={{ textAlign: 'right', marginTop: '4rem' }}>
                                                <Checkbox inputId="cb1" value={data1.staff.id} onChange={this.onDriverChange} checked={this.state.driversCheckBox.indexOf(data1.staff.id) !== -1}></Checkbox>
                                            </div>
                                        </Col>

                                        <Col xs={1}>
                                            <Form.Label style={{ marginTop: '4rem', color: 'black' }}>{data1.staff.name}&nbsp;{data1.staff.lastName}</Form.Label>
                                        </Col>

                                        <Col xs={2}>
                                            <Form.Label style={{ marginTop: '4rem', color: 'black' }}>{data1.equipmentLog.equipmentCode}&nbsp;{data1.equipmentLog.equipmentVehicleLicenceList[0].plateNo}</Form.Label>
                                        </Col>

                                        {this.state.driverDatas.map(data => (
                                            (data.length > 0) &&
                                            (data[0].staffId === data1.staffId) &&
                                            <Col xs={6} key={data[0].id}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "100px",
                                                        margin: "0 auto",
                                                        marginTop: "10px",
                                                        fontSize: "15px"
                                                    }}
                                                >
                                                    <HorizontalTimeline
                                                        styles={{
                                                            background: "#FFFFFF",
                                                            foreground: "#7B9D6F",
                                                            outline: "#dfdfdf"
                                                        }}
                                                        isOpenEnding={false}
                                                        isOpenBeginning={false}
                                                        index={data.length - 1}
                                                        labelWidth={170}
                                                        getLabel={function (date, index) {
                                                            var s = ["th", "st", "nd", "rd"],
                                                                v = (index + 1) % 100;

                                                            var total = 0;
                                                            var total1 = 0;

                                                            for (var i = 0; i <= index; i++) {
                                                                total += parseInt(data[i].checkVolume);
                                                            }

                                                            total1 = total + parseInt(data[data.length - 1].volume);

                                                            //return (index + 1) + (s[(v - 20) % 10] || s[v] || s[0]) + " (" + data[index].volume + "m3 / " + total + "m3)";

                                                            if ((index === (data.length - 1)) && data[data.length - 1].checkVolume === 0)
                                                                return <div style={{ color: "gray" }}> {(index + 1) + (s[(v - 20) % 10] || s[v] || s[0]) + " trip (" + data[index].volume + "m3 / " + total1 + "m3)"}</div>;
                                                            else
                                                                return <div style={{ color: "black" }}> {(index + 1) + (s[(v - 20) % 10] || s[v] || s[0]) + " trip (" + data[index].checkVolume + "m3 / " + total + "m3)"}</div>;
                                                        }}
                                                        indexClick={index => {

                                                        }}
                                                        values={data.map(x => x.flagData)}
                                                    />
                                                </div>
                                            </Col>
                                        ))
                                        }

                                        {this.state.locationAlertDatas.map(data => (
                                            (this.state.idleTimeAlertDB !== 0) &&
                                            <Col xs={2}>
                                                <Form.Label style={{ marginTop: '4rem', marginLeft: '10px', color: 'red' }}>{data}</Form.Label>
                                            </Col>
                                        ))
                                        }
                                    </Form.Row>
                                ))}
                            </div>
                        ) : (
                                <div>
                                    <h4 style={{ width: "90%", textAlign: "center", marginBottom: "30px", marginTop: '2.5rem' }}>No drivers found for selected day.</h4>
                                </div>
                            )}

                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyShipments)