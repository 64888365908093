import React, { Component } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import InlineError from './InlineError'
import PropTypes from 'prop-types'
import ItemTable from './ItemsTable'

export default class extends Component {
  state = {
    text: "",
    errors: {}
  };


  static propTypes = {
    onNewSelectItemSubmit:PropTypes.func.isRequired
  }
  
  handleChange = e => {
    
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();
    this.setState({errors});//hatayı yukarıdaki state yazdık
    if (Object.keys(errors).length===0) {
      this.props.onNewSelectItemSubmit(this.state); //yani yukarıdaki text i gönderdik.   
      console.log("test");   
    }
    
  };

  validate = () => {
    const errors = {};
    if (!this.state.text) errors.text = "Can't be blank.";
    return errors;
  };
  render() {
      const {errors}=this.state;
    return (
      <div>
          
          
        <Row>
          <Col md={6}>
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <div className="kt-portlet__head-title">Item Add/Edit</div>
                </div>
              </div>
              <div className="kt-portlet__body">
                <Form onSubmit={this.onSubmit}>
                  <Form.Group >
                    <Form.Label>Item Text</Form.Label>
                    <Form.Control
                      id="text"
                      name="text"
                      type="text"
                      value={this.state.text}
                      onChange={this.handleChange}
                      placeholder="Enter new item text..."
                    />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>                    
                    <Form.Text className="text-danger">
                    {errors.text && <InlineError message={errors.text}/>}
                    </Form.Text>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <div className="kt-portlet__head-title">Items</div>
                </div>
              </div>
              <div className="kt-portlet__body">
                <ItemTable/>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
