/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Accordion } from 'react-bootstrap';
import { Button } from "primereact/button";
import { Form, Col, Row } from 'react-bootstrap';
import "react-toggle/style.css";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Table, Select, Switch, Modal } from 'antd';
import { Column } from 'primereact/components/column/Column';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { PickList } from 'primereact/picklist'
import { Tree as Tree2 } from 'antd';
import moment from 'moment';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu5';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';


class TimelineLog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            timeLineList: [],
        }
    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/Notification/getAll");

        if (Boolean(response.data) && response.data.length > 0) {

            const data = response.data;
            console.log(data); 
            this.setState({
                timeLineList: data

            });
        }


    }


    render() {
        return ( 
            <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.timeLineList} bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }}>

                   
                    <Column title="S/N" dataIndex="index" />
                    <Column title="Resource" dataIndex="resource" />
                    <Column title="Resource Name, Code" dataIndex="resourceName" />
                    <Column title="Action" dataIndex="action" />
                    <Column title="Detail" dataIndex="detail" /> 
                    <Column title="Activation Date/Time" render={(value, row, index) => {
                        if (Boolean(row.activationTime ))
                            return moment(new Date(row.activationTime)).format("DD/MM/YYYY HH:mm:ss") 
                        else return "";

                    }} /> 
                      <Column title="Page" dataIndex="page" /> 
                      {/* <Column title="By/From" dataIndex="page" />  */}
                      <Column title="To" render={(value, row, index) => {
                        if (Boolean(row.toUser))
                            return row.toUser.staff.name + ' ' + row.toUser.staff.midName + ' ' + row.toUser.staff.lastName ;  
                        else return "";

                    }} /> 
                      <Column title="Message" dataIndex="message" /> 
                      <Column title="Purpose" dataIndex="purpose" /> 
                      <Column title="Go to page" dataIndex="pageUrl" /> 
                      <Column title="Status" dataIndex="status" /> 
                      <Column title="Created Date,Time" render={(value, row, index) => {
                        if (Boolean(row.time))
                            return moment(new Date(row.time)).format("DD/MM/YYYY HH:mm:ss") 
                        else return "";

                    }} /> 
                    


                </Table>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineLog)
