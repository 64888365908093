import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Button as ReactButton, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, DatePicker } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class InspectionNotification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      inspectionDate: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      inspectionNotificationTable: [],
      inspectionAssignmentPackages: [],
      inspectionAssignmentPackage: 0,
      inspections: [],
      inspectionsDates: [],
      inspectionNotificationDates: [],
      dateCheck: false,
      earliestDate: "",
      modalDateMessage: false,
      minimumDaysForNotification: 0
    };

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InspectionNotification" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "InspectionNotification",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InspectionNotification");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
  }

  getTableProperties = async () => {
    this.inspectionNotification = JSON.parse(localStorage.getItem('inspectionNotification'));
    if (this.inspectionNotification != null && this.inspectionNotification != undefined && this.inspectionNotification != "") {

      await this.setState({
        bordered: this.inspectionNotification.bordered,
        title: this.inspectionNotification.titleDrm == true ? title : undefined,
        titleDrm: this.inspectionNotification.titleDrm,
        showHeader: this.inspectionNotification.showHeader,
        footer: this.inspectionNotification.footerDrm == true ? footer : undefined,
        footerDrm: this.inspectionNotification.footerDrm,
        expandable: this.inspectionNotification.expandableDrm == true ? expandable : {},
        expandableDrm: this.inspectionNotification.expandableDrm,
        rowSelection: this.inspectionNotification.rowSelection,
        ellipsis: this.inspectionNotification.ellipsis,
        tableLayout: this.inspectionNotification.tableLayout,
        size: this.inspectionNotification.size,
        top: this.inspectionNotification.top,
        bottom: this.inspectionNotification.bottom,
        editBgColor: this.inspectionNotification.editBgColor,
        deleteBgColor: this.inspectionNotification.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.inspectionNotification.yScroll,
        xScroll: this.inspectionNotification.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.inspectionNotificationTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.inspectionNotificationTable.length, showQuickJumper: true,
          position: [this.inspectionNotification.top, this.inspectionNotification.bottom]
        },
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  }

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  }

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedInspectionNotification: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveInspectionNotification = async () => {
    if (!(this.state.dateCheck)) {
      const addInspectionNotification = {
        inspectionAssignmentPackage: this.state.inspectionAssignmentPackage,
        inspection: this.state.inspection,
        inspectionDate: moment(this.state.inspectionDate, 'DD/MM/YYYY'),
        inspectionDateAck: this.state.inspectionDate,
        thirdPartyInspector: this.state.thirdPartyInspector,
        sapNo: this.state.sapNo,
        thirdPartyCompany: this.state.thirdPartyCompany,
      }
      var response = await handleRequest("POST", "/InspectionNotification/add", addInspectionNotification);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.resetInputs();
          this.restartTable();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    } else {
      this.showOrHideModalDateMessage();
    }
  }

  updateInspectionNotification = async () => {
    const updatedInspectionNotification = {
      id: this.state.selectedInspectionNotification.id,
      inspectionAssignmentPackage: this.state.inspectionAssignmentPackage,
      inspection: this.state.inspection,
      inspectionDate: moment(this.state.inspectionDate, 'DD/MM/YYYY'),
      inspectionDateAck: this.state.inspectionDate,
      thirdPartyInspector: this.state.thirdPartyInspector,
      sapNo: this.state.sapNo,
      thirdPartyCompany: this.state.thirdPartyCompany,
    }
    var response2 = await handleRequest("POST", "/InspectionNotification/update", updatedInspectionNotification);
    if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/InspectionNotification/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        inspectionNotificationTable: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        inspectionNotificationTable: response.data,
      });
    }

    const responseDates = await handleRequest("GET", "/InspectionDates/getAllByCompany");
    if (responseDates.data.length === 0) {
      this.setState({
        inspectionDates: [],
      });
    }

    if (responseDates.data.length !== 0) {
      this.setState({
        inspectionDates: responseDates.data,
      });
    }

    //this.getinspectionAssignmentPackages(0);
    const responseIAP = await handleRequest("GET", "/InspectionAssignmentPackage/getallByCompany");
    if (responseIAP.data.length === 0) {
      this.setState({
        inspectionAssignmentPackages: [],
      });
    }

    if (responseIAP.data.length !== 0) {
      this.setState({
        inspectionAssignmentPackages: responseIAP.data,
      });
    }
  }

  handleChangeInspectionAssignmentPackage = (e) => {
    var data = [];
    var dataDates = [];
    var dataNotifications = [];
    let _inspectionDate = null;
    let _inspection = "";
    let _inspections = [];
    let _inspectionsDates = [];

    if (Boolean(e) && e > 0) {
      data = this.state.inspectionAssignmentPackages.filter(p => p.id == e);
      dataDates = this.state.inspectionDates.filter(p => p.iapId == e);

      for (let index = 0; index < this.state.inspectionNotificationTable.length; index++) {
        if (this.state.inspectionNotificationTable[index].inspectionAssignmentPackage === e) {
          dataNotifications.push(this.state.inspectionNotificationTable[index]);
        }
      }
    }

    if (dataDates.length > 0) {
      if (dataDates[0].deliveryOfGoods === "One Time") {

        if ((dataNotifications.length === 0) && (dataDates[0].iap.pimdate !== null)) {  //PIM var ve henüz PIM girilmemiş demektir
          let _flagInspectionDate = data[0].pimdate.split("-");
          _inspectionDate = _flagInspectionDate[2] + "/" + _flagInspectionDate[1] + "/" + _flagInspectionDate[0];
          _inspection = "PIM";
          _inspections.push("PIM");

          this.setState({
            inspectionDate: _inspectionDate,
            inspection: _inspection
          });

          this.formRef.current.setFieldsValue({
            InspectionDate: (_inspectionDate != null && _inspectionDate != undefined && _inspectionDate != "") && moment(_inspectionDate, 'DD/MM/YYYY'),
            Inspection: _inspection
          });

          if (dataDates[0].minimumDaysForNotification !== undefined) {
            if (data[0].pimdate !== null) {
              var flagDate = moment(data[0].pimdate, "YYYY/MM/DD")._d;
              var dd = String(flagDate.getDate()).padStart(2, '0');
              var mm = String(flagDate.getMonth() + 1).padStart(2, '0');
              var yyyy = flagDate.getFullYear();
              flagDate = dd + '/' + mm + '/' + yyyy;

              let today1 = new Date();
              let date1 = moment(data[0].pimdate, "YYYY/MM/DD")._d;
              today1.setDate(today1.getDate() + dataDates[0].minimumDaysForNotification);

              let earliestDate = new Date();
              earliestDate.setDate(earliestDate.getDate() + dataDates[0].minimumDaysForNotification + 1);
              let dd1 = String(earliestDate.getDate()).padStart(2, '0');
              let mm1 = String(earliestDate.getMonth() + 1).padStart(2, '0');
              let yyyy1 = earliestDate.getFullYear();
              earliestDate = dd1 + '/' + mm1 + '/' + yyyy1;

              console.log(earliestDate);

              if (date1 < today1)
                this.setState({
                  dateCheck: true
                });
              else
                this.setState({
                  dateCheck: false
                });

              this.setState({
                minimumDaysForNotification: dataDates[0].minimumDaysForNotification,
                earliestDate: earliestDate
              });
            } else {
              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
              var yyyy = today.getFullYear();
              today = dd + '/' + mm + '/' + yyyy;

              this.setState({
                dateCheck: false
              });
            }
          }
        }

        if ((dataNotifications.length > 0) || (dataDates[0].iap.pimdate === null)) {  //PIM'i yoksa ya da PIM girildikten sonra
          let dataNotificationsInspections = [];
          for (let index = 0; index < dataNotifications.length; index++) {
            dataNotificationsInspections.push(dataNotifications[index].inspection);
          }

          let numberOfIpi = Number(dataDates[0].numberOfIpi);

          if (numberOfIpi > 0) {
            let flagDates = dataDates[0].inspections[0].ipiDates.split(",");

            for (let index = 0; index < numberOfIpi; index++) {
              let flagString = "IPI " + (index + 1);

              if (!(dataNotificationsInspections.includes(flagString))) {
                _inspections.push(flagString);
                _inspectionsDates.push(flagDates[index]);
              }
            }

            if (!(dataNotificationsInspections.includes("FAT"))) {
              _inspections.push("FAT");
              _inspectionsDates.push(dataDates[0].inspections[0].fatDate);
            }
          } else {
            let dataNotificationsInspections = [];
            for (let index = 0; index < dataNotifications.length; index++) {
              dataNotificationsInspections.push(dataNotifications[index].inspection);
            }

            if (!(dataNotificationsInspections.includes("FAT"))) {
              _inspections.push("FAT");
              _inspectionsDates.push(dataDates[0].inspections[0].fatDate);
            }
          }
        }

        this.setState({
          inspectionAssignmentPackage: e,
          inspections: (_inspections.length > 0) ? [_inspections[0]] : [],
          inspectionsDates: (_inspectionsDates.length > 0) ? [_inspectionsDates[0]] : []
        });

      } else if (dataDates[0].deliveryOfGoods === "Multiple") {

        if ((dataNotifications.length === 0) && (dataDates[0].iap.pimdate !== null)) {  //PIM var ve henüz PIM girilmemiş demektir
          let _flagInspectionDate = data[0].pimdate.split("-");
          _inspectionDate = _flagInspectionDate[2] + "/" + _flagInspectionDate[1] + "/" + _flagInspectionDate[0];
          _inspection = "PIM";
          _inspections.push("PIM");

          this.setState({
            inspectionDate: _inspectionDate,
            inspection: _inspection
          });

          this.formRef.current.setFieldsValue({
            InspectionDate: (_inspectionDate != null && _inspectionDate != undefined && _inspectionDate != "") && moment(_inspectionDate, 'DD/MM/YYYY'),
            Inspection: _inspection
          });

          this.setState({
            inspectionAssignmentPackage: e,
            inspections: (_inspections.length > 0) ? [_inspections[0]] : [],
            inspectionsDates: (_inspectionsDates.length > 0) ? [_inspectionsDates[0]] : []
          });

          if (dataDates[0].minimumDaysForNotification !== undefined) {
            if (data[0].pimdate !== null) {
              var flagDate = moment(data[0].pimdate, "YYYY/MM/DD")._d;
              var dd = String(flagDate.getDate()).padStart(2, '0');
              var mm = String(flagDate.getMonth() + 1).padStart(2, '0');
              var yyyy = flagDate.getFullYear();
              flagDate = dd + '/' + mm + '/' + yyyy;

              let today1 = new Date();
              let date1 = moment(data[0].pimdate, "YYYY/MM/DD")._d;
              today1.setDate(today1.getDate() + dataDates[0].minimumDaysForNotification);

              let earliestDate = new Date();
              earliestDate.setDate(earliestDate.getDate() + dataDates[0].minimumDaysForNotification + 1);
              let dd1 = String(earliestDate.getDate()).padStart(2, '0');
              let mm1 = String(earliestDate.getMonth() + 1).padStart(2, '0');
              let yyyy1 = earliestDate.getFullYear();
              earliestDate = dd1 + '/' + mm1 + '/' + yyyy1;

              console.log(earliestDate);

              if (date1 < today1)
                this.setState({
                  dateCheck: true
                });
              else
                this.setState({
                  dateCheck: false
                });

              this.setState({
                minimumDaysForNotification: dataDates[0].minimumDaysForNotification,
                earliestDate: earliestDate
              });
            } else {
              var today = new Date();
              var dd = String(today.getDate()).padStart(2, '0');
              var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
              var yyyy = today.getFullYear();
              today = dd + '/' + mm + '/' + yyyy;

              this.setState({
                dateCheck: false
              });
            }
          }
        }

        if ((dataNotifications.length > 0) || (dataDates[0].iap.pimdate === null)) {  //PIM'i yoksa ya da PIM girildikten sonra
          let dataNotificationsInspections = [];
          for (let index = 0; index < dataNotifications.length; index++) {
            dataNotificationsInspections.push(dataNotifications[index].inspection);
          }

          let numberOfDelivery = Number(dataDates[0].numberOfDelivery);
          let inspectionArray = new Array(numberOfDelivery);
          let inspectionDateArray = new Array(numberOfDelivery);
          let numberOfIpi = Number(dataDates[0].numberOfIpi);

          if (numberOfIpi > 0) {
            for (let index = 0; index < numberOfDelivery; index++) {
              let flagDates = dataDates[0].inspections[index].ipiDates.split(",");

              for (let index1 = 0; index1 < numberOfIpi; index1++) {
                let flagString = this.getNumberWithOrdinal((index + 1)) + " Party IPI " + (index1 + 1);

                if (!(dataNotificationsInspections.includes(flagString))) {

                  if (inspectionArray[index] === undefined) {
                    inspectionArray[index] = flagString;
                    inspectionDateArray[index] = flagDates[index1];
                  }

                  _inspections.push(flagString);
                  _inspectionsDates.push(flagDates[index1]);
                }
              }

              let flagText = this.getNumberWithOrdinal((index + 1)) + " Party FAT"
              if (!(dataNotificationsInspections.includes(flagText))) {

                if (inspectionArray[index] === undefined) {
                  inspectionArray[index] = flagText;
                  inspectionDateArray[index] = dataDates[0].inspections[index].fatDate;
                }

                _inspections.push(flagText);
                _inspectionsDates.push(dataDates[0].inspections[index].fatDate);
              }
            }

            let newInspectionArray = [];
            let newInspectionDateArray = [];

            for (let index2 = 0; index2 < inspectionArray.length; index2++) {
              if (inspectionArray[index2] !== undefined) {
                newInspectionArray.push(inspectionArray[index2]);
                newInspectionDateArray.push(inspectionDateArray[index2]);
              }
            }

            this.setState({
              inspectionAssignmentPackage: e,
              inspections: (newInspectionArray.length > 0) ? newInspectionArray : [],
              inspectionsDates: (newInspectionDateArray.length > 0) ? newInspectionDateArray : []
            });

          } else {
            let dataNotificationsInspections = [];
            for (let index = 0; index < dataNotifications.length; index++) {
              dataNotificationsInspections.push(dataNotifications[index].inspection);
            }

            for (let index = 0; index < numberOfDelivery; index++) {
              let flagText = this.getNumberWithOrdinal((index + 1)) + " Party FAT"
              if (!(dataNotificationsInspections.includes(flagText))) {
                _inspections.push(flagText);
                _inspectionsDates.push(dataDates[0].inspections[index].fatDate);
              }
            }

            this.setState({
              inspectionAssignmentPackage: e,
              inspections: (_inspections.length > 0) ? _inspections : [],
              inspectionsDates: (_inspectionsDates.length > 0) ? _inspectionsDates : []
            });

          }
        }
      }
    }
  }

  getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  handleChangeInspection = (e) => {
    this.setState({
      inspection: e,
    });

    var dataDates = [];
    dataDates = this.state.inspectionDates.filter(p => p.iapId == this.state.inspectionAssignmentPackage);

    for (let index = 0; index < this.state.inspections.length; index++) {
      if (this.state.inspections[index] === e && e !== "PIM") {
        let _inspectionDate = this.state.inspectionsDates[index]

        this.setState({
          inspectionDate: this.state.inspectionsDates[index]
        });

        this.formRef.current.setFieldsValue({
          InspectionDate: (_inspectionDate != null && _inspectionDate != undefined && _inspectionDate != "") && moment(_inspectionDate, 'DD/MM/YYYY'),
          Inspection: e
        });

        if (dataDates[0].minimumDaysForNotification !== undefined) {
          var flagDate = moment(_inspectionDate, "DD/MM/YYYY")._d;
          var dd = String(flagDate.getDate()).padStart(2, '0');
          var mm = String(flagDate.getMonth() + 1).padStart(2, '0');
          var yyyy = flagDate.getFullYear();
          flagDate = dd + '/' + mm + '/' + yyyy;

          let today1 = new Date();
          let date1 = moment(_inspectionDate, "DD/MM/YYYY")._d;
          today1.setDate(today1.getDate() + dataDates[0].minimumDaysForNotification);

          let earliestDate = new Date();
          earliestDate.setDate(earliestDate.getDate() + dataDates[0].minimumDaysForNotification + 1);
          let dd1 = String(earliestDate.getDate()).padStart(2, '0');
          let mm1 = String(earliestDate.getMonth() + 1).padStart(2, '0');
          let yyyy1 = earliestDate.getFullYear();
          earliestDate = dd1 + '/' + mm1 + '/' + yyyy1;

          console.log(today1);
          console.log(date1);
          console.log(earliestDate);

          if (date1 < today1)
            this.setState({
              dateCheck: true
            });
          else
            this.setState({
              dateCheck: false
            });

          this.setState({
            minimumDaysForNotification: dataDates[0].minimumDaysForNotification,
            earliestDate: earliestDate
          });
        }
      }
    }
  }

  getinspectionAssignmentPackages = async (inspectionAssignmentPackage) => {
    var newObj = {
      id: inspectionAssignmentPackage
    }
    const response = await handleRequest("POST", "/InspectionAssignmentPackage/getInspectionNotification", newObj);
    if (response.data.length === 0) {
      this.setState({
        inspectionAssignmentPackages: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        inspectionAssignmentPackages: response.data,
      });
    }
  }

  getUserSettings = async () => {
    const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
    if (responseUserSettings.data.length !== 0) {
      this.setState({
        userSettings: responseUserSettings.data,
        howToUseSetting: responseUserSettings.data.howToUseSetting,
        whatIsSetting: responseUserSettings.data.whatIsSetting
      });
    } else {
      this.setState({

        howToUseSetting: false,
        whatIsSetting: false
      });
    }
  }

  deleteInspectionNotificationOK = async () => {
    const deleteInspectionNotification = {
      id: this.state.selectedInspectionNotification.id
    }
    var response = await handleRequest("POST", "/InspectionNotification/delete", deleteInspectionNotification);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    this.formRef.current.setFieldsValue({
      InspectionAssignmentPackage: row.inspectionAssignmentPackage,
      Inspection: row.inspection,
      InspectionDate: (row.inspectionDate != null && row.inspectionDate != undefined && row.inspectionDate != "") && moment(row.inspectionDate, 'YYYY-MM-DD'),
      ThirdPartyInspector: row.thirdPartyInspector,
      SapNo: row.sapNo,
      ThirdPartyCompany: row.thirdPartyCompany,
    });

    var insPackage = this.state.inspectionAssignmentPackages.filter(m => m.id == row.inspectionAssignmentPackage);
    if (!(insPackage != null && insPackage != undefined && insPackage.length > 0)) {
      this.getinspectionAssignmentPackages(row.inspectionAssignmentPackage);
    }

    await this.setState({
      selectedInspectionNotification: row,
      inspectionAssignmentPackage: row.inspectionAssignmentPackage,
      inspection: row.inspection,
      inspectionDate: row.inspectionDate,
      thirdPartyInspector: row.thirdPartyInspector,
      sapNo: row.sapNo,
      thirdPartyCompany: row.thirdPartyCompany,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      InspectionAssignmentPackage: "",
      Inspection: "",
      InspectionDate: "",
      ThirdPartyInspector: "",
      SapNo: "",
      ThirdPartyCompany: "",
    });

    this.setState({
      selectedInspectionNotification: [],
      inspectionAssignmentPackage: "",
      inspection: "",
      inspectionDate: "",
      thirdPartyInspector: "",
      sapNo: "",
      thirdPartyCompany: "",
      dateCheck: false,
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
    //this.getinspectionAssignmentPackages(0);
  }

  changeButtonCancel = () => {
    this.formRef.current.setFieldsValue({
      InspectionAssignmentPackage: "",
      Inspection: "",
      InspectionDate: "",
      ThirdPartyInspector: "",
      SapNo: "",
      ThirdPartyCompany: "",
    });

    this.setState({
      selectedInspectionNotification: [],
      inspectionAssignmentPackage: "",
      inspection: "",
      inspectionDate: "",
      thirdPartyInspector: "",
      sapNo: "",
      thirdPartyCompany: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenCreateNewButton: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenCreateNewButton: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeInspectionDate = (value, dateString) => {
    this.setState({
      inspectionDate: dateString
    });

    if (dateString !== "") {
      let today1 = new Date();
      let date1 = moment(dateString, "DD/MM/YYYY")._d;
      today1.setDate(today1.getDate() + this.state.minimumDaysForNotification);

      if (date1 < today1)
        this.setState({
          dateCheck: true,
          modalDateMessage: true
        });
      else
        this.setState({
          dateCheck: false
        });
    }
  }

  yesToDate = () => {
    this.setState({
      dateCheck: true,
      modalDateMessage: false
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  noToDate = () => {
    this.setState({
      dateCheck: false,
      modalDateMessage: false
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalDateMessage = () => {
    this.setState({
      modalDateMessage: !this.state.modalDateMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  render() {
    var itemInspectionAssignmentPackageAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionAssignmentPackage");
    var itemInspectionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Inspection");
    var itemInspectionDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionDate");
    var itemThirdPartyInspectorAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ThirdPartyInspector");
    var itemSapNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SapNo");
    var itemThirdPartyCompanyAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ThirdPartyCompany");

    var itemInspectionAssignmentPackageRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionAssignmentPackage");
    var itemInspectionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Inspection");
    var itemInspectionDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionDate");
    var itemThirdPartyInspectorRequired = this.state.dynamicInputs.find(p => p.inputKey === "ThirdPartyInspector");
    var itemSapNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "SapNo");
    var itemThirdPartyCompanyRequired = this.state.dynamicInputs.find(p => p.inputKey === "ThirdPartyCompany");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("inspectionNotification")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "assignmentPackage",
        key: "key",
        title: <FormattedMessage id="InspectionNotificationInspectionAssignmentPackage" defaultMessage="Inspection Assignment Package" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.requestForQuotation !== undefined)
              if (data.requestForQuotation !== null)
                return data.iap + "-" + data.requestForQuotation.projectTakeOffList.description;
          }
          return "";
        }
      },
      {
        dataIndex: "inspection",
        key: "key",
        title: <FormattedMessage id="InspectionNotificationInspection" defaultMessage="Inspection" />,
      },
      {
        dataIndex: "inspectionDate",
        key: "key",
        title: <FormattedMessage id="InspectionNotificationInspectionDate" defaultMessage="Inspection Date" />,
      },
      {
        dataIndex: "thirdPartyInspector",
        key: "key",
        title: <FormattedMessage id="InspectionNotificationThirdPartyInspector" defaultMessage="Third Party Inspector" />,
      },
      {
        dataIndex: "sapNo",
        key: "key",
        title: <FormattedMessage id="InspectionNotificationSapNo" defaultMessage="SAP No" />,
      },
      {
        dataIndex: "thirdPartyCompany",
        key: "key",
        title: <FormattedMessage id="InspectionNotificationThirdPartyCompany" defaultMessage="Third Party Company" />,
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&

          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateInspectionNotification : this.saveInspectionNotification}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("inspectionNotification")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />
              {(itemInspectionAssignmentPackageAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionNotificationInspectionAssignmentPackage" defaultMessage="Inspection Assignment Package" />}
                  name="InspectionAssignmentPackage"
                  rules={[{ required: itemInspectionAssignmentPackageRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionAssignmentPackageRequired.translateRequired) == true ? itemInspectionAssignmentPackageRequired.translateRequired : -1} defaultMessage="Please input your Inspection Assignment Package!" /> }]} >
                  <Select name="InspectionAssignmentPackage"
                    style={{ width: '100%' }}
                    value={this.state.inspectionAssignmentPackage}
                    onChange={this.handleChangeInspectionAssignmentPackage}>

                    {this.state.inspectionAssignmentPackages.map(i => (
                      <Option key={i.id} value={i.id}>{i.iap + "-" + i.requestForQuotation.projectTakeOffList.description}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemInspectionAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionNotificationInspection" defaultMessage="Inspection" />}
                  name="Inspection"
                  rules={[{ required: itemInspectionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionRequired.translateRequired) == true ? itemInspectionRequired.translateRequired : -1} defaultMessage="Please input your Inspection!" /> }]} >
                  <Select name="Inspection"
                    style={{ width: '100%' }}
                    value={this.state.inspection}
                    onChange={this.handleChangeInspection}>
                    {this.state.inspectionAssignmentPackage > 0 &&
                      this.state.inspections.map(i => (
                        <Option key={i} value={i}>{i}</Option>
                      ))}
                  </Select>
                </Form.Item>}

              {(itemInspectionDateAttributes.visible) &&
                <Form.Item label={<FormattedMessage id="InspectionNotificationInspectionDate" defaultMessage="Inspection Date" />}>
                  <Form.Item name="InspectionDate" noStyle
                    rules={[{
                      required: itemInspectionDateRequired.canBeRequired, message: <FormattedMessage
                        id={Boolean(itemInspectionDateRequired.translateRequired) == true ? itemInspectionDateRequired.translateRequired : -1}
                        defaultMessage="Please input your Inspection Date!" />
                    }]}
                  >
                    <DatePicker value={moment(this.state.inspectionDate, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeInspectionDate} />
                  </Form.Item>
                  {(this.state.dateCheck) && <FormBoot.Label style={{ marginTop: "0.5rem", marginLeft: "0.5rem", color: "red" }}>
                    This inspection date is less than {this.state.minimumDaysForNotification} days.
                  </FormBoot.Label>}
                </Form.Item>}

              {(itemThirdPartyInspectorAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionNotificationThirdPartyInspector" defaultMessage="Third Party Inspector" />}
                  name="ThirdPartyInspector"
                  rules={[{ required: itemThirdPartyInspectorRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemThirdPartyInspectorRequired.translateRequired) == true ? itemThirdPartyInspectorRequired.translateRequired : -1} defaultMessage="Please input your Third Party Inspector!" /> }]} >
                  <Input id="InspectionNotificationThirdPartyInspector" value={this.state.thirdPartyInspector} onChange={(e) =>
                    this.setState({ thirdPartyInspector: e.target.value })
                  } />
                </Form.Item>}

              {(itemSapNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionNotificationSapNo" defaultMessage="SAP No" />}
                  name="SapNo"
                  rules={[{ required: itemSapNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSapNoRequired.translateRequired) == true ? itemSapNoRequired.translateRequired : -1} defaultMessage="Please input your SAP No!" /> }]} >
                  <Input id="InspectionNotificationSapNo" value={this.state.sapNo} onChange={(e) =>
                    this.setState({ sapNo: e.target.value })
                  } />
                </Form.Item>}

              {(itemThirdPartyCompanyAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionNotificationThirdPartyCompany" defaultMessage="Third Party Company" />}
                  name="ThirdPartyCompany"
                  rules={[{ required: itemThirdPartyCompanyRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemThirdPartyCompanyRequired.translateRequired) == true ? itemThirdPartyCompanyRequired.translateRequired : -1} defaultMessage="Please input your Third Party Company!" /> }]} >
                  <Input id="InspectionNotificationThirdPartyCompany" value={this.state.thirdPartyCompany} onChange={(e) =>
                    this.setState({ thirdPartyCompany: e.target.value })
                  } />
                </Form.Item>}

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="5">
                      <Button id="InspectionNotificationSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button id="InspectionNotificationCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="3">
                      <Button id="InspectionNotificationUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.inspectionNotificationTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>
        }

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteInspectionNotificationOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalDateMessage}
          onHide={this.showOrHideModalDateMessage}
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row>
                <Col sm="12">
                  <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormBoot>
              <Row>
                <Col sm="12">
                  The inspection date can't be proceed. The easliest inspection date can be {this.state.earliestDate}. Do you want to change the date?
                </Col>
              </Row>
              <FormBoot.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <ReactButton style={{ width: '100%' }} onClick={this.yesToDate}>Yes</ReactButton>
                </Col>
                <Col sm="4">
                  <ReactButton style={{ width: '100%' }} onClick={this.noToDate}>No, proceed the date</ReactButton>
                </Col>
                <Col sm="4">
                  <ReactButton style={{ width: '100%' }} onClick={this.showOrHideModalDateMessage}>Cancel</ReactButton>
                </Col>
              </FormBoot.Group>
            </FormBoot>
          </Modal.Body>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionNotification)