/* eslint-disable */

import React, { Component } from 'react' 
import { connect } from 'react-redux'; 
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot , Col, Modal, Row } from 'react-bootstrap'; 
import { Form, Input, InputNumber, Checkbox, Cascader, Table } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import { ToggleButton } from 'primereact/togglebutton';
  
const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.value, lineage: node.data.lineage });
    if (node.children) {  
      generateList(node.children); 
    }
  }
};
   
const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class VendorEvaluationTemplate extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
      hiddenSave: false,
      hiddenDataGiris:true,
      hiddenUpdate: true,
      pdfFiles:[], 
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      createNewButtonVariant: "info",
      createNewButton: "Create New", 
      vendorEvaluationTemplateTable:[],
      labelDescription:"Item Description",
      hiddenItemForVendor:false,
      hiddenParentId:false,
      parentVendorEvaluationTemplates: [],
      parentId:0,
      dtNodes: [], 
      values: [],
    };
   
    this.actionTemplate = this.actionTemplate.bind(this);  
  }

  formRef = React.createRef();

componentDidMount = async () => { 
  var date=null;
  if(this.props.date!=null && this.props.date!=undefined && this.props.date!=0 && this.props.date!=""){
    date=this.props.date; 
  }

  const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "VendorEvaluation" });
  if (responseDP.data.length > 0) {
    this.setState({
      dynamicAttributes: responseDP.data
    });
  } else {
    this.setState({
      dynamicAttributes: []
    });
  }

  const obj = {
    pageName: "VendorEvaluation",
  };
  const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
  if (responseDynamicInputs.data.length !== 0) {
    this.setState({
      dynamicInputs: responseDynamicInputs.data,
    });
  } else {
    this.setState({
      dynamicInputs: [],
    });
  }
  
  const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "VendorEvaluation");
  try {
    this.setState({
      authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
    });
  } catch (error) {
  }
  this.restartTable(); 
  this.getTableProperties();  
} 

restartTable = async () => {
  const response = await handleRequest("GET", "/VendorEvaluationTemplate/getallByCompany");
  console.log("AAA", response.data);
  if (response.data.data.length === 0) {
    this.setState({
      vendorEvaluationTemplateTable: [],
      parentVendorEvaluationTemplates: [],
    });
  }
  if (response.data.data.length !== 0) {
    this.setState({
      vendorEvaluationTemplateTable: response.data.data,
      parentVendorEvaluationTemplates: response.data.data.filter(p => p.parentId==0),
      totalWeight: response.data.totalWeight,
      totalPercent: response.data.totalPercent,
    });
  }

  var responseNodes = [];
  responseNodes = await handleRequest("GET", "/VendorEvaluationTemplate/getNodesVendorEvaluationTemplates");
  if (responseNodes.data.length !== 0) {
    generateList(responseNodes.data); 
    this.setState({
      dtNodes: responseNodes.data 
    });
  }else{
    this.setState({
      dtNodes: [] 
    });
  } 
} 

getTableProperties = async () => {
  this.vendorEvaluationTemplate = JSON.parse(localStorage.getItem('vendorEvaluationTemplate'));
  if (this.vendorEvaluationTemplate != null && this.vendorEvaluationTemplate != undefined && this.vendorEvaluationTemplate != "") {

      await this.setState({
          bordered: this.vendorEvaluationTemplate.bordered,
          title: this.vendorEvaluationTemplate.titleDrm == true ? title : undefined,
          titleDrm: this.vendorEvaluationTemplate.titleDrm,
          showHeader: this.vendorEvaluationTemplate.showHeader,
          footer: this.vendorEvaluationTemplate.footerDrm == true ? footer : undefined,
          footerDrm: this.vendorEvaluationTemplate.footerDrm,
          expandable: this.vendorEvaluationTemplate.expandableDrm == true ? expandable : {},
          expandableDrm: this.vendorEvaluationTemplate.expandableDrm,
          rowSelection: this.vendorEvaluationTemplate.rowSelection,
          ellipsis: this.vendorEvaluationTemplate.ellipsis,
          tableLayout: this.vendorEvaluationTemplate.tableLayout,
          size: this.vendorEvaluationTemplate.size,
          top: this.vendorEvaluationTemplate.top,
          bottom: this.vendorEvaluationTemplate.bottom,
          editBgColor: this.vendorEvaluationTemplate.editBgColor,
          deleteBgColor: this.vendorEvaluationTemplate.deleteBgColor,
          selectedNodeKey: null,
          yScroll: this.vendorEvaluationTemplate.yScroll,
          xScroll: this.vendorEvaluationTemplate.xScroll,
          paginationDisabled: {
              defaultPageSize: 5, total: this.state.vendorEvaluationTemplateTable.length,
              showQuickJumper: true, showSizeChanger: true,
              pageSizeOptions: ['5', '10', '25', '50'], total: this.state.vendorEvaluationTemplateTable.length, showQuickJumper: true,
              position: [this.vendorEvaluationTemplate.top, this.vendorEvaluationTemplate.bottom]
          },
          kontrol: true,
      });
  } else {
      await this.setState({
          bordered: false,
          title: undefined,
          titleDrm: false,
          showHeader: true,
          footer: undefined,
          footerDrm: false,
          expandable: false,
          expandableDrm: false,
          rowSelection: undefined,
          ellipsis: false,
          tableLayout: undefined,
          size: 'default',
          top: 'none',
          bottom: 'bottomRight',
          editBgColor: '#fff',
          deleteBgColor: '#fff',
          selectedNodeKey: null,
          xScroll: undefined,
          paginationDisabled: false,
          kontrol: true,
      });
    }
}
 
showOrHideModalPdfMultiViewer = () => {
    if(this.state.pdfFiles.length>0){
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer 
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    }else{
      this.showError("File cant found.");
    }
};

actionTemplate(rowData) { 
  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
        onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
  } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuEdit row={rowData}
        onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
  }
  else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDelete row={rowData}
        onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
  }
};

deleteModal = (row) => {
  this.setState({ 
    deleteModal: true,
    selectedVendorEvaluationTemplate: row
  });
}

showOrHideModalDelete = () => {
  this.setState({
    deleteModal: !this.state.deleteModal
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

saveVendorEvaluationTemplate = async () => {
    var itemForVendor="Company";
    if(this.state.itemForVendor){
        itemForVendor="Vendor"
    }
  const addVendorEvaluationTemplate = {
    itemNo: this.state.itemNo,
    itemGroup: this.state.itemGroup,
    materialId: this.state.materialId,
    description: this.state.description,
    weight: this.state.weight,
    itemForVendor: itemForVendor,
    parentId: this.state.parentId,
  }
  var response = await handleRequest("POST", "/VendorEvaluationTemplate/add", addVendorEvaluationTemplate);
  if (response.data !== null && response.data !== undefined) {
    if (response.data === "SUCCESS") {
      this.resetInputs();
      this.restartTable();
      this.showSuccess();
      this.createNewClientButton();
    }
    else {
      this.showError();
    }
  }
}
 
updateVendorEvaluationTemplate = async () => {
    var itemForVendor="Company";
    if(this.state.itemForVendor){
        itemForVendor="Vendor"
    }
  const updatedVendorEvaluationTemplate = {
    id: this.state.selectedVendorEvaluationTemplate.id,
    itemNo: this.state.itemNo,
    itemGroup: this.state.itemGroup,
    materialId: this.state.materialId,
    description: this.state.description,
    weight: this.state.weight,
    itemForVendor: itemForVendor,
    parentId: this.state.parentId,
  }
  var response2 = await handleRequest("POST", "/VendorEvaluationTemplate/update", updatedVendorEvaluationTemplate);
  if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else{
        this.showError();
      }
  }
}

deleteVendorEvaluationTemplateOK = async () => {
  const deleteVendorEvaluationTemplate = {
      id: this.state.selectedVendorEvaluationTemplate.id
  }
  var response = await handleRequest("POST", "/VendorEvaluationTemplate/delete", deleteVendorEvaluationTemplate);
  if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
          this.showError();
      }
  }
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

changeButtonUpdate = async (row) => { 
    var itemForVendor=false;
    if(row.itemForVendor=="Vendor"){
        itemForVendor=true;
    }

    var parentId=[];
    if(row.parentId!=0){
        parentId.push(row.parentId + "");
    }

  this.formRef.current.setFieldsValue({
    ItemNo: row.itemNo,
    ItemGroup: row.itemGroup,
    Description: row.description,
    ParentId: parentId,
    Weight: row.weight,
    ItemForVendor: itemForVendor,
  }); 
        
 await this.setState({
    selectedVendorEvaluationTemplate: row,
    
    itemNo: row.itemNo,
    itemGroup: row.itemGroup,
    description: row.description,
    parentId: row.parentId,
    weight: row.weight,
    itemForVendor: itemForVendor,

    hiddenSave: true,
    hiddenUpdate: false,
    hiddenDataGiris: false,
    createNewButton: "Cancel",
    createNewButtonVariant: "danger",
  });
}

resetInputs = () => {
  this.formRef.current.setFieldsValue({
    ItemNo: "",
    ItemGroup: false,
    Description: "",
    ParentId: "",
    Weight: "",
    itemForVendor: "",
  });

  this.setState({
    selectedVendorEvaluationTemplate: [],
    itemNo: "",
    itemGroup: false,
    description: "",
    parentId: 0,
    weight: "",
    itemForVendor: "",

    labelDescription:"Item Description",
    hiddenItemForVendor:false,
    hiddenParentId:false,

    hiddenSave: false,
    hiddenUpdate: true,
    hiddenDataGiris:true,
  });
}
  
changeButtonCancel = () => {
  this.formRef.current.setFieldsValue({
    ItemNo: "",
    ItemGroup: false,
    Description: "",
    ParentId: "",
    Weight: "",
    itemForVendor: "",
  });

  this.setState({
    selectedVendorEvaluationTemplate: [],
    itemNo: "",
    itemGroup: false,
    description: "",
    parentId: 0,
    weight: "",
    itemForVendor: "",
    
    hiddenSave: false,
    hiddenUpdate: true,
    hiddenDataGiris:true,
    createNewButton: "Create New",
    createNewButtonVariant: "info", 
  });
}

showOrHideModalDeleteMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

createNewClientButton = async () => {
  await this.resetInputs();  

   if (this.state.createNewButton=="Create New") {
     this.setState({ 
      hiddenDataGiris: false, 
      hiddenSave:false,
      hiddenUpdate:true,
      hiddenCreateNewButton:false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
     });
  }
  else {
     this.setState({ 
      hiddenDataGiris: true,
      hiddenSave:true,
      hiddenUpdate:false,
      hiddenCreateNewButton:false,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
     });
  }
}
 
showOrHideModalPdfViewer = () => {
  this.setState({
    modalPdfViewer: !this.state.modalPdfViewer,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

showSuccess(msg) {
    if (!msg) {
        msg = 'Successful';
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Success"
    });
};

showError(msg) {
    if (!msg) {
        msg = 'An error was occured please try again later !';
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Error"
    });
};

showWarning(msg) {
  if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Warning"
  });
};

showOrHideModalMessage = () => {
  this.setState({
    modalMessage: !this.state.modalMessage,
  }); 
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHidePhotoModal = () => {
  this.setState({
    modalPhotoShow: !this.state.modalPhotoShow,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

showOrHideModalTable = async (tableTip) => {
  this.setState({
      modalTable: !this.state.modalTable,
      tableTip:tableTip,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
} 

handleChangeVendorEvaluationTemplate = (e) => { 
  this.setState({
    parentId: e,
  });
}

onChangeParent = (value, data) => { 
  if (data.length > 0){
    this.setState({
      values: value,
      parentId: data[data.length - 1].value,
    }) 
  }else{
    this.setState({
      values: [],
      parentId: 0,
    }) 
  }
}

onSelectChange = selectedRowKeys => {
  if (selectedRowKeys.length > 1) {
    const lastSelectedRowIndex = [...selectedRowKeys].pop();
    this.setState({ selectedRowKeys: lastSelectedRowIndex });
  }
  this.setState({ selectedRowKeys });
  console.log('selectedRowKeys changed: ', selectedRowKeys);
};

saveAllCheckVendor = async () => {
  const list = this.state.selectedRowKeys;  
  console.log("WWW", list);
 
  var selected=[];

  if(list!=null && list!=undefined && list.length>0){
    list.forEach(element => {
      var select = this.state.vendorEvaluationTemplateTable.filter(p => p.id == element);
 
       if(select!=null && select!=undefined && select.length==1){
          selected.push(select[0]);
       }
   });
  
   if(selected!=null && selected!=undefined && selected.length!=0){
       var response = await handleRequest("POST", "/VendorEvaluation/addNewVendorEvaluation", selected);
       if (response.data !== null && response.data !== undefined) {
           if (response.data === "SUCCESS") {
               this.showSuccess();
           }
           else{
               this.showWarning("No records to be added!");
           }
       }else{
           this.showError();
       }
   }else{
       this.showWarning();
   } 
  }else{
    this.showWarning();
  }
}

render() {
  const { selectedRowKeys } = this.state;
  const rowSelection = {
    selectedRowKeys,
    onChange: this.onSelectChange,
  };

  var itemItemNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemNo");
  var itemItemGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemGroup"); 
  var itemDescriptionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Description"); 
  var itemParentIdAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ParentId"); 
  var itemWeightAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Weight"); 
  var itemItemForVendorAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemForVendor"); 

  var itemItemNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "ItemNo");
  var itemItemGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "ItemGroup");
  var itemDescriptionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Description");
  var itemParentIdRequired = this.state.dynamicInputs.find(p => p.inputKey === "ParentId");
  var itemWeightRequired = this.state.dynamicInputs.find(p => p.inputKey === "Weight");
  var itemItemForVendorRequired = this.state.dynamicInputs.find(p => p.inputKey === "ItemForVendor");
  
const columns = [
    {
      key: "key",
      title: "",
      render: this.actionTemplate
    },        
    { 
      key: "key",
      title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("vendorEvaluationTemplate")} icon="pi pi-cog"></PrimeButton>,
      render: (data) =>   
      {  
          if(data.itemGroup){
              return (
                  <React.Fragment>
                      <div style={{
                          fontWeight: "bold",
                      }}>
                       {data.itemNo}
                      </div>
                  </React.Fragment>
              );
          }else  {
              return (
                  <React.Fragment>
                      <div style={{
                          fontWeight: "lighter",
                      }}>
                     {data.itemNo}
                      </div>
                  </React.Fragment>
              );
          }
      }
    },  
    { 
      key: "key",
      title: <FormattedMessage id="VendorEvaluationTemplateItem" defaultMessage="Item" />,
      render: (data) =>   
      {  
          if(data.itemGroup){
              return (
                  <React.Fragment>
                      <div style={{
                          fontWeight: "bold",
                      }}>
                       {data.description}
                      </div>
                  </React.Fragment>
              );
          }else  {
              return (
                  <React.Fragment>
                      <div style={{
                          fontWeight: "lighter",
                      }}>
                     {data.description}
                      </div>
                  </React.Fragment>
              );
          }
      }
    }, 
    { 
      key: "key",
      title: <FormattedMessage id="VendorEvaluationTemplateWeight" defaultMessage="Weight" />,
      render: (data) =>   
      {  
          if(data.itemGroup){
              return (
                  <React.Fragment>
                      <div style={{
                          fontWeight: "bold",
                          textAlign:"center",
                          fontSize:"larger"
                      }}>
                       {data.weight}
                      </div>
                  </React.Fragment>
              );
          }else  {
              return (
                  <React.Fragment>
                      <div style={{
                          fontWeight: "lighter",
                          textAlign:"center",
                          fontSize:"smaller"
                      }}>
                     {data.weight}
                      </div>
                  </React.Fragment>
              );
          }
      }
    },
    {
        key: "key",
        title: <FormattedMessage id="VendorEvaluationTemplatePercent" defaultMessage="% in Total" />,
        render: (data) =>   
        {  
            if(data.itemGroup){
                return (
                    <React.Fragment>
                        <div style={{
                            fontWeight: "bold",
                            textAlign:"center",
                            fontSize:"larger"
                        }}>
                         {data.percent}
                        </div>
                    </React.Fragment>
                );
            }else  {
                return (
                    <React.Fragment>
                        <div style={{
                            fontWeight: "lighter",
                            textAlign:"center",
                            fontSize:"smaller"
                        }}>
                       {data.percent}
                        </div>
                    </React.Fragment>
                );
            }
        }
    },
    {
        key: "key",
        title: <FormattedMessage id="VendorEvaluationTemplateBy" defaultMessage="By" />,
        render: (data) =>   
        {  
          if(data.itemGroup!=true){   //Item group değilse gösterilecek
            return data.itemForVendor;
          } 
          return "";
        }
    },
];

const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
if (this.state.xScroll === 'fixed') {
  tableColumns[0].fixed = true;
  tableColumns[tableColumns.length - 1].fixed = 'right';
}

const layoutView = (
  isSidebarOpened,
  container,
  main,
  toolbar,
  sidebar,
) => {
  return defaultLayout(
    isSidebarOpened,
    container,
    main,
    toolbar(renderToolbar),
    sidebar,
  );
};

const onFinish = values => {
  console.log('Success:', values);
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8 },
};

const renderToolbar = (toolbarSlot) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: 'center',
        }}
      >
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.previousPageButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.nextPageButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomOutButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomPopover}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomInButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.downloadButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.moreActionsPopover}
        </div>
      </div>
    </div>
  );
};

return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
  <div>
      {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && 
        <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateVendorEvaluationTemplate : this.saveVendorEvaluationTemplate}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

          <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                  <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                      variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                  </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("vendorEvaluationTemplate")} icon="pi pi-cog"></PrimeButton>
              </Col>
          </Row>
    
          <div hidden={this.state.hiddenDataGiris}>
            <br/>
              {(itemItemNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="VendorEvaluationTemplateItemNo" defaultMessage="Item no" />}
                  name="ItemNo"
                  rules={[{ required: itemItemNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemItemNoRequired.translateRequired)==true ? itemItemNoRequired.translateRequired : -1} defaultMessage="Please input your Item No!" /> }]} >
                  <Input id="VendorEvaluationTemplateItemNo" value={this.state.itemNo} onChange={(e) =>
                    this.setState({ itemNo: e.target.value })
                  } />
                </Form.Item>}  
             
              {(itemItemGroupAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="VendorEvaluationTemplateItemGroup" defaultMessage="Item Group" />}
                  name="ItemGroup"
                  rules={[{ required: itemItemGroupRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemItemGroupRequired.translateRequired)==true ? itemItemGroupRequired.translateRequired : -1} defaultMessage="Please input your Item Group!" /> }]} >
                   <Checkbox checked={this.state.itemGroup} onChange={(e) => {
                        var labelDescription="Item Description";
                        var hiddenItemForVendor=false;
                        var hiddenParentId=false;
                        if(e.target.checked){
                            labelDescription="Group Description";
                            hiddenItemForVendor=true;
                            hiddenParentId=true;

                            this.setState({
                              parentId:0,
                            });
                        }
                        this.setState({
                            itemGroup:e.target.checked,
                            labelDescription:labelDescription,
                            hiddenItemForVendor:hiddenItemForVendor,
                            hiddenParentId:hiddenParentId,
                        });
                    }} style={{ color: 'black', marginTop: '0.25rem' }}>{<FormattedMessage id="VendorEvaluationTemplateItemGroup" defaultMessage="Item Group" />}</Checkbox>
                </Form.Item>}  
             
                {(itemDescriptionAttributes.visible) &&
                <Form.Item
                  label={this.state.labelDescription}
                  name="Description"
                  rules={[{ required: itemDescriptionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDescriptionRequired.translateRequired)==true ? itemDescriptionRequired.translateRequired : -1} defaultMessage="Please input your Description!" /> }]} >
                  <Input id="VendorEvaluationTemplateDescription" value={this.state.description} onChange={(e) =>
                    this.setState({ description: e.target.value })
                  } />
                </Form.Item>}    
                
                {(itemParentIdAttributes.visible && !this.state.hiddenParentId) &&
                <Form.Item
                  label={<FormattedMessage id="VendorEvaluationTemplateParentId" defaultMessage="Parent Vendor Evaluation" />}
                  name="ParentId"
                  rules={[{ required: itemParentIdRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemParentIdRequired.translateRequired)==true ? itemParentIdRequired.translateRequired : -1} defaultMessage="Please input your Parent Vendor Evaluation!" /> }]} >
                    <Cascader 
                        style={{ width: "100%" }}
                        options={this.state.dtNodes} 
                        value={this.state.values} 
                        onChange={this.onChangeParent} changeOnSelect />
                </Form.Item>}  
                
                {(itemWeightAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="VendorEvaluationTemplateWeight" defaultMessage="Weight" />}
                  name="Weight"
                  rules={[{ required: itemWeightRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemWeightRequired.translateRequired)==true ? itemWeightRequired.translateRequired : -1} defaultMessage="Please input your Weight!" /> }]} >
                  <InputNumber id="VendorEvaluationTemplateWeight" min={1} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ weight: e })} value={this.state.weight}/>
                </Form.Item>}   
                
                {(itemItemForVendorAttributes.visible && !this.state.hiddenItemForVendor) &&
                <Form.Item
                  label={<FormattedMessage id="VendorEvaluationTemplateItemForVendor" defaultMessage="Item For Vendor?" />}
                  name="ItemForVendor"
                  rules={[{ required: itemItemForVendorRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemItemForVendorRequired.translateRequired)==true ? itemItemForVendorRequired.translateRequired : -1} defaultMessage="Please input your Item For Vendor!" /> }]} >
                    <ToggleButton style={{ width: '100%' }} onLabel="Yes" offLabel="No"  
                    checked={this.state.itemForVendor} onChange={(e) => this.setState({ itemForVendor: e.value })} />
                </Form.Item>}  

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="3">
                      </Col>
                      <Col sm="4">
                          <Button id="VendorEvaluationTemplateSaveButton"
                            style={{ width: '100%' }} type="submit"
                            variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                      </Col>
                      <Col sm="2">
                      </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button id="VendorEvaluationTemplateCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button id="VendorEvaluationTemplateUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>
        
          </div> 
          <br />
          <br />

          <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Table
                  rowSelection={rowSelection}
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.vendorEvaluationTemplateTable}
                  pagination={false}
                  bordered={true}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                /> 
                </div>
            </div>
            <br/>
           { this.state.vendorEvaluationTemplateTable.length > 0 &&
            <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                </Col>
                <FormBoot.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="VendorEvaluationTemplateTotalScore" defaultMessage="Total Score" />
                </FormBoot.Label>
                <Col sm="2">
                    <Input id="VendorEvaluationTemplateTotalWeight" readOnly={true} value={this.state.totalWeight} />
                </Col>
                <Col sm="2">
                    <Input id="VendorEvaluationTemplateTotalPercent" readOnly={true} value={this.state.totalPercent} />
                </Col>
            </FormBoot.Group>}

            <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="3">
                </Col>
                <Col sm="6">
                    <Button id="VendorEvaluationTemplateSaveCheckButton"
                        style={{ width: '100%' }} onClick={() => this.saveAllCheckVendor()}  
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                </Col>
                <Col sm="3">
                </Col>
            </FormBoot.Group>
  
      </Form> }

    <Modal
        show={this.state.modalMessage}
        onHide={this.showOrHideModalMessage}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered >

        <Modal.Header style={{backgroundColor: "#f3f3f3"}} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <Row > 
              <Col sm="12">
                {this.state.messageTip=="Success" &&  
                  <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
              
                {this.state.messageTip=="Warning" &&  
                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                {this.state.messageTip=="Error" &&  
                <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
              </Col>
            </Row>
            </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{backgroundColor: "#f3f3f3"}}>
          <Row > 
            <Col sm="12">
              {this.state.message} 
            </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: "#f3f3f3"}}>
          <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff"}} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
      </Modal.Footer>
      </Modal>

    <Modal
      size="xl"
      show={this.state.modalPhotoShow}
      onHide={this.showOrHidePhotoModal}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.imageUrl !== "" ? (
          <img
            src={this.state.imageUrl}
            alt="avatar"
            style={{ width: "100%" }}
          />
        ) : (
            <p>Photo has not been uploaded.</p>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" onClick={this.showOrHidePhotoModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal
      size="xl"
      show={this.state.modalPdfViewer}
      onHide={this.showOrHideModalPdfViewer}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          PDF Report Viewer
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
          <div style={{ height: "900px" }}>
            <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
          </div>
        </Worker> 

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={this.showOrHideModalPdfViewer}
        >
          Close
          </Button>
      </Modal.Footer>
    </Modal>

    <Modal 
      show={this.state.modalShowPdfMultiViewer} size="xl" 
      onHide={this.showOrHideModalPdfMultiViewer} 
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered  >
      <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
      </Modal.Header>
        <Modal.Body>

          <PdfMultiViewer
            pdfs={this.state.pdfFiles}
            i18nData={{
              download: 'Download',
              scaleDown: 'Zoom Out',
              scaleUp: 'Zoom In',
              originalSize: 'Original Size',
              pages: 'Pages',
              zoom: 'Automatic Zoom',
            }}
          />  

        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button> 
      </Modal.Footer>
  </Modal> 

    <Modal
      show={this.state.modalTable}
      onHide={this.showOrHideModalTable}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered size="lg">

      <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                  <Col sm="12">
                      <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                  </Col>
              </Row>
          </Modal.Title>
      </Modal.Header>

      <Modal.Body>
          <Row >
              <Col sm="12">
                  <MNDTableSettings id={this.state.tableTip} />
              </Col>
          </Row>
      </Modal.Body>
  </Modal>

    <Modal
      show={this.state.deleteModal}
      onHide={this.showOrHideModalDelete}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered >
      <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
          </p>
          <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
          </p>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
          </Button>
          <Button variant="danger" onClick={this.deleteVendorEvaluationTemplateOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  ));
  }
}

const mapStateToProps = state => {
  return {
      auth: state.auth,
      i18n: state.i18n
  }
}

const mapDispatchToProps = {
   
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorEvaluationTemplate)