export const COUNTRIES = [
{
    name: 'Afghanistan',
    value: '3'
},
{
    name: 'Albania',
    value: '6'
}, 
{
    name: 'Aland Islands',
    value: '16'
},
{
    name: 'Algeria',
    value: '64'
},
{
    name: 'American Samoa',
    value: '12'
}, 

{
    name: 'Andorra',
    value: '1'
},   
{
    name: 'Antigua and Barbuda',
    value: '4'
}, 
{
    name: 'Anguilla',
    value: '5'
}, 
{
    name: 'Angola',
    value: '9'
}, 
{
    name: 'Antarctica',
    value: '10'
}, 
{
    name: 'Austria',
    value: '13'
}, 
{
    name: 'Australia',
    value: '14'
}, 
{
    name: 'Argentina',
    value: '11'
}, 
{
    name: 'Armenia',
    value: '7'
}, 
{
    name: 'Aruba',
    value: '15'
}, 
{
    name: 'Azerbaijan',
    value: '17'
}, 
{
    name: 'Bahamas',
    value: '33'
},
{
    name: 'Bahrain',
    value: '24'
}, 
{
    name: 'Barbados',
    value: '19'
}, 
{
    name: 'Bangladesh',
    value: '20'
}, 
{
    name: 'Belarus',
    value: '37'
}, 
{
    name: 'Belgium',
    value: '21'
}, 
{
    name: 'Belize',
    value: '38'
},
{
    name: 'Benin',
    value: '26'
}, 
{
    name: 'Bermuda',
    value: '28'
}, 
{
    name: 'Bhutan',
    value: '34'
},
{
    name: 'Bosnia and Herzegovina',
    value: '18'
}, 
{
    name: 'Brunei',
    value: '29'
}, 
{
    name: 'Burkina Faso',
    value: '22'
}, 
{
    name: 'Bulgaria',
    value: '23'
}, 
{
    name: 'Burundi',
    value: '25'
},  
{
    name: 'Bolivia',
    value: '30'
}, 
{
    name: 'Bonaire, Saint Eustatius and Saba ',
    value: '31'
}, 
{
    name: 'Bouvet Island',
    value: '35'
}, {
    name: 'Botswana',
    value: '36'
}, 
{
    name: 'Brazil',
    value: '32'
},
{
    name: 'British Indian Ocean Territory',
    value: '108'
},
{
    name: 'British Virgin Islands',
    value: '241'
},
{
    name: 'Cameroon',
    value: '48'
},
{
    name: 'Canada',
    value: '39'
}, 
{
    name: 'Cambodia',
    value: '119'
}, 
{
    name: 'Cayman Islands',
    value: '126'
},
{
    name: 'Central African Republic',
    value: '42'
}, 
{
    name: 'Chad',
    value: '217'
}, 
{
    name: 'Chile',
    value: '47'
}, 
{
    name: 'China',
    value: '49'
}, 
{
    name: 'Cocos Islands',
    value: '40'
}, 
{
    name: 'Cook Islands',
    value: '46'
}, 
{
    name: 'Colombia',
    value: '50'
}, 
{
    name: 'Costa Rica',
    value: '51'
},  
{
    name: 'Cuba',
    value: '53'
}, 
{
    name: 'Cape Verde',
    value: '54'
}, 
{
    name: 'Curaçao',
    value: '55'
}, 
{
    name: 'Christmas Island',
    value: '56'
}, 
{
    name: 'Croatia',
    value: '100'
},   
{
    name: 'Comoros',
    value: '121'
},
{
    name: 'Cyprus',
    value: '57'
}, 
{
    name: 'Czech Republic',
    value: '58'
}, 
{
    name: 'Democratic Republic of the Congo',
    value: '41'
},
{
    name: 'Djibouti',
    value: '60'
}, 
{
    name: 'Denmark',
    value: '61'
}, 
{
    name: 'Dominica',
    value: '62'
}, 
{
    name: 'Dominican Republic',
    value: '63'
},  
{
    name: 'East Timor',
    value: '223'
}, 
{
    name: 'Ecuador',
    value: '65'
}, 
{
    name: 'Egypt',
    value: '67'
},
{
    name: 'El Salvador',
    value: '212'
},
{
    name: 'Equatorial Guinea',
    value: '90'
}, 
{
    name: 'Estonia',
    value: '66'
},  {
    name: 'Western Sahara',
    value: '68'
}, {
    name: 'Eritrea',
    value: '69'
},  {
    name: 'Ethiopia',
    value: '71'
}, 
{
    name: 'Falkland Islands',
    value: '74'
},
{
    name: 'Faroe Islands',
    value: '76'
},
{
    name: 'Finland',
    value: '72'
}, {
    name: 'Fiji',
    value: '73'
},{
    name: 'France',
    value: '77'
}, 
{
    name: 'French Southern Territories',
    value: '218'
}, {
    name: 'French Polynesia',
    value: '177'
}, 
{
    name: 'French Guiana',
    value: '82'
}, 
{
    name: 'Gabon',
    value: '78'
}, 
{
    name: 'Germany',
    value: '59'
},  {
    name: 'Grenada',
    value: '80'
}, {
    name: 'Georgia',
    value: '81'
}, {
    name: 'Guernsey',
    value: '83'
}, {
    name: 'Ghana',
    value: '84'
}, {
    name: 'Gibraltar',
    value: '85'
}, {
    name: 'Greenland',
    value: '86'
}, {
    name: 'Gambia',
    value: '87'
}, {
    name: 'Guinea',
    value: '88'
}, {
    name: 'Guadeloupe',
    value: '89'
},  {
    name: 'Greece',
    value: '91'
},  {
    name: 'Guatemala',
    value: '93'
}, {
    name: 'Guam',
    value: '94'
}, {
    name: 'Guinea-Bissau',
    value: '95'
}, {
    name: 'Guyana',
    value: '96'
}, {
    name: 'Hong Kong',
    value: '97'
}, {
    name: 'Heard Island and McDonald Islands',
    value: '98'
}, {
    name: 'Honduras',
    value: '99'
}, {
    name: 'Haiti',
    value: '101'
}, {
    name: 'Hungary',
    value: '102'
}, {
    name: 'Indonesia',
    value: '103'
}, {
    name: 'Ireland',
    value: '104'
}, {
    name: 'Israel',
    value: '105'
}, {
    name: 'Isle of Man',
    value: '106'
}, {
    name: 'India',
    value: '107'
}, 
{
    name: 'Ivory Coast',
    value: '45'
}, 
{
    name: 'Iraq',
    value: '109'
}, {
    name: 'Iran',
    value: '110'
}, {
    name: 'Iceland',
    value: '111'
}, {
    name: 'Italy',
    value: '112'
}, {
    name: 'Jersey',
    value: '113'
}, {
    name: 'Jamaica',
    value: '114'
}, {
    name: 'Jordan',
    value: '115'
}, {
    name: 'Japan',
    value: '116'
}, 
{
    name: 'Kazakhstan',
    value: '127'
},
{
    name: 'Kenya',
    value: '117'
}, {
    name: 'Kyrgyzstan',
    value: '118'
}, 
{
    name: 'Kiribati',
    value: '120'
},  
{
    name: 'Kosovo',
    value: '247'
}, 
{
    name: 'Kuwait',
    value: '125'
}, 
  {
    name: 'Laos',
    value: '128'
}, {
    name: 'Lebanon',
    value: '129'
}, {
    name: 'Liechtenstein',
    value: '131'
}, {
    name: 'Liberia',
    value: '133'
}, {
    name: 'Lesotho',
    value: '134'
}, {
    name: 'Lithuania',
    value: '135'
}, {
    name: 'Luxembourg',
    value: '136'
}, {
    name: 'Latvia',
    value: '137'
}, {
    name: 'Libya',
    value: '138'
}, {
    name: 'Morocco',
    value: '139'
}, {
    name: 'Monaco',
    value: '140'
}, {
    name: 'Moldova',
    value: '141'
}, {
    name: 'Montenegro',
    value: '142'
},  {
    name: 'Madagascar',
    value: '144'
}, {
    name: 'Marshall Islands',
    value: '145'
}, {
    name: 'Macedonia',
    value: '146'
}, {
    name: 'Mali',
    value: '147'
}, {
    name: 'Myanmar',
    value: '148'
}, {
    name: 'Mongolia',
    value: '149'
}, {
    name: 'Macao',
    value: '150'
}, {
    name: 'Martinique',
    value: '152'
}, {
    name: 'Mauritania',
    value: '153'
}, {
    name: 'Montserrat',
    value: '154'
}, {
    name: 'Malta',
    value: '155'
}, {
    name: 'Mauritius',
    value: '156'
}, {
    name: 'Maldives',
    value: '157'
}, {
    name: 'Malawi',
    value: '158'
}, {
    name: 'Mexico',
    value: '159'
}, {
    name: 'Malaysia',
    value: '160'
}, {
    name: 'Mozambique',
    value: '161'
}, 
{
    name: 'Micronesia',
    value: '75'
},  {
    name: 'Mayotte',
    value: '249'
},
{
    name: 'Namibia',
    value: '162'
}, 
{
    name: 'North Korea',
    value: '123'
},  {
    name: 'Northern Mariana Islands',
    value: '151'
}, 
{
    name: 'New Caledonia',
    value: '163'
}, {
    name: 'Niger',
    value: '164'
}, {
    name: 'Norfolk Island',
    value: '165'
}, {
    name: 'Nigeria',
    value: '166'
}, {
    name: 'Nicaragua',
    value: '167'
}, 
{
    name: 'Netherlands',
    value: '168'
}, 
{
    name: 'Netherlands Antilles',
    value: '8'
},
{
    name: 'Norway',
    value: '169'
}, {
    name: 'Nepal',
    value: '170'
}, {
    name: 'Nauru',
    value: '171'
}, {
    name: 'Niue',
    value: '172'
}, {
    name: 'New Zealand',
    value: '173'
}, {
    name: 'Oman',
    value: '174'
}, {
    name: 'Panama',
    value: '175'
}, {
    name: 'Peru',
    value: '176'
}, {
    name: 'Papua New Guinea',
    value: '178'
}, {
    name: 'Philippines',
    value: '179'
}, {
    name: 'Pakistan',
    value: '180'
}, {
    name: 'Poland',
    value: '181'
},  {
    name: 'Pitcairn',
    value: '183'
}, {
    name: 'Puerto Rico',
    value: '184'
}, {
    name: 'Palestinian Territory',
    value: '185'
}, {
    name: 'Portugal',
    value: '186'
}, {
    name: 'Palau',
    value: '187'
}, {
    name: 'Paraguay',
    value: '188'
}, {
    name: 'Qatar',
    value: '189'
}, {
    name: 'Reunion',
    value: '190'
}, 
{
    name: 'Republic of the Congo',
    value: '43'
}, 
{
    name: 'Romania',
    value: '191'
}, {
    name: 'Russia',
    value: '193'
}, {
    name: 'Rwanda',
    value: '194'
}, 
{
    name: 'Saint Barthélemy',
    value: '27'
},
{
    name: 'Saint Kitts and Nevis',
    value: '122'
}, 
{
    name: 'Saint Lucia',
    value: '130'
},
{
    name: 'Saint Martin',
    value: '143'
},
{
    name: 'Saint Vincent and the Grenadines',
    value: '239'
},
{
    name: 'Saint Pierre and Miquelon',
    value: '182'
},
{
    name: 'Samoa',
    value: '246'
},
{
    name: 'Saudi Arabia',
    value: '195'
}, 
{
    name: 'Serbia',
    value: '192'
},
{
    name: 'Serbia and Montenegro',
    value: '52'
},
 {
    name: 'Seychelles',
    value: '197'
}, 
{
    name: 'Solomon Islands',
    value: '196'
},
{
    name: 'Sri Lanka',
    value: '132'
},
{
    name: 'Sudan',
    value: '198'
}, {
    name: 'Sweden',
    value: '199'
}, {
    name: 'Singapore',
    value: '200'
}, {
    name: 'Saint Helena',
    value: '201'
}, {
    name: 'Slovenia',
    value: '202'
}, {
    name: 'Svalbard and Jan Mayen',
    value: '203'
}, {
    name: 'Slovakia',
    value: '204'
}, {
    name: 'Sierra Leone',
    value: '205'
}, {
    name: 'San Marino',
    value: '206'
}, {
    name: 'Senegal',
    value: '207'
}, {
    name: 'Somalia',
    value: '208'
}, {
    name: 'Suriname',
    value: '209'
}, 
{
    name: 'South Africa',
    value: '250'
},
{
    name: 'South Korea',
    value: '124'
},
{
    name: 'South Georgia and the South Sandwich Islands',
    value: '92'
},
{
    name: 'South Sudan',
    value: '210'
}, 
{
    name: 'Spain',
    value: '70'
},
{
    name: 'Sao Tome and Principe',
    value: '211'
},  {
    name: 'Sint Maarten',
    value: '213'
}, {
    name: 'Syria',
    value: '214'
}, {
    name: 'Swaziland',
    value: '215'
}, 
{
    name: 'Switzerland',
    value: '44'
},
{
    name: 'Turks and Caicos Islands',
    value: '216'
},
{
    name: 'Togo',
    value: '219'
}, {
    name: 'Thailand',
    value: '220'
}, {
    name: 'Tajikistan',
    value: '221'
}, {
    name: 'Tokelau',
    value: '222'
}, {
    name: 'Turkmenistan',
    value: '224'
}, {
    name: 'Tunisia',
    value: '225'
}, {
    name: 'Tonga',
    value: '226'
}, {
    name: 'Turkey',
    value: '227'
}, {
    name: 'Trinidad and Tobago',
    value: '228'
}, {
    name: 'Tuvalu',
    value: '229'
}, {
    name: 'Taiwan',
    value: '230'
}, {
    name: 'Tanzania',
    value: '231'
}, {
    name: 'Ukraine',
    value: '232'
}, {
    name: 'Uganda',
    value: '233'
},
{
    name: 'United Kingdom',
    value: '79'
}, 
{
    name: 'United Arab Emirates',
    value: '2'
},
{
    name: 'United States Minor Outlying Islands',
    value: '234'
}, {
    name: 'United States',
    value: '235'
}, {
    name: 'Uruguay',
    value: '236'
},
{
    name: 'U.S. Virgin Islands',
    value: '242'
}, 
{
    name: 'Uzbekistan',
    value: '237'
}, {
    name: 'Vatican',
    value: '238'
},  {
    name: 'Venezuela',
    value: '240'
}, {
    name: 'Vietnam',
    value: '243'
}, {
    name: 'Vanuatu',
    value: '244'
}, {
    name: 'Wallis and Futuna',
    value: '245'
},  {
    name: 'Yemen',
    value: '248'
},   {
    name: 'Zambia',
    value: '251'
}, {
    name: 'Zimbabwe',
    value: '252'
}
];

export const NATIONALITIES = [
    {
        id: "4",
        "alpha_2_code": "AF",
        "alpha_3_code": "AFG",
        "en_short_name": "Afghanistan",
        name: "Afghan"
    },
    {
        id: "8",
        "alpha_2_code": "AL",
        "alpha_3_code": "ALB",
        "en_short_name": "Albania",
        name: "Albanian"
    },
    {
        id: "12",
        "alpha_2_code": "DZ",
        "alpha_3_code": "DZA",
        "en_short_name": "Algeria",
        name: "Algerian"
    },
    {
        id: "840",
        "alpha_2_code": "US",
        "alpha_3_code": "USA",
        "en_short_name": "United States of America",
        name: "American"
    },
    {
        id: "581",
        "alpha_2_code": "UM",
        "alpha_3_code": "UMI",
        "en_short_name": "United States Minor Outlying Islands",
        name: "American"
    },
    {
        id: "16",
        "alpha_2_code": "AS",
        "alpha_3_code": "ASM",
        "en_short_name": "American Samoa",
        name: "American Samoan"
    },
    {
        id: "20",
        "alpha_2_code": "AD",
        "alpha_3_code": "AND",
        "en_short_name": "Andorra",
        name: "Andorran"
    },
    {
        id: "24",
        "alpha_2_code": "AO",
        "alpha_3_code": "AGO",
        "en_short_name": "Angola",
        name: "Angolan"
    },
    {
        id: "660",
        "alpha_2_code": "AI",
        "alpha_3_code": "AIA",
        "en_short_name": "Anguilla",
        name: "Anguillan"
    },
    {
        id: "10",
        "alpha_2_code": "AQ",
        "alpha_3_code": "ATA",
        "en_short_name": "Antarctica",
        name: "Antarctic"
    },
    {
        id: "28",
        "alpha_2_code": "AG",
        "alpha_3_code": "ATG",
        "en_short_name": "Antigua and Barbuda",
        name: "Antiguan or Barbudan"
    },
    {
        id: "32",
        "alpha_2_code": "AR",
        "alpha_3_code": "ARG",
        "en_short_name": "Argentina",
        name: "Argentine"
    },
    {
        id: "51",
        "alpha_2_code": "AM",
        "alpha_3_code": "ARM",
        "en_short_name": "Armenia",
        name: "Armenian"
    },
    {
        id: "533",
        "alpha_2_code": "AW",
        "alpha_3_code": "ABW",
        "en_short_name": "Aruba",
        name: "Aruban"
    },
    {
        id: "36",
        "alpha_2_code": "AU",
        "alpha_3_code": "AUS",
        "en_short_name": "Australia",
        name: "Australian"
    },
    {
        id: "40",
        "alpha_2_code": "AT",
        "alpha_3_code": "AUT",
        "en_short_name": "Austria",
        name: "Austrian"
    },
    {
        id: "31",
        "alpha_2_code": "AZ",
        "alpha_3_code": "AZE",
        "en_short_name": "Azerbaijan",
        name: "Azerbaijani, Azeri"
    },
    {
        id: "248",
        "alpha_2_code": "AX",
        "alpha_3_code": "ALA",
        "en_short_name": "\u00c5land Islands",
        name: "\u00c5land Island"
    },
    {
        id: "44",
        "alpha_2_code": "BS",
        "alpha_3_code": "BHS",
        "en_short_name": "Bahamas",
        name: "Bahamian"
    },
    {
        id: "48",
        "alpha_2_code": "BH",
        "alpha_3_code": "BHR",
        "en_short_name": "Bahrain",
        name: "Bahraini"
    },
    {
        id: "50",
        "alpha_2_code": "BD",
        "alpha_3_code": "BGD",
        "en_short_name": "Bangladesh",
        name: "Bangladeshi"
    },
    {
        id: "52",
        "alpha_2_code": "BB",
        "alpha_3_code": "BRB",
        "en_short_name": "Barbados",
        name: "Barbadian"
    },
    {
        id: "426",
        "alpha_2_code": "LS",
        "alpha_3_code": "LSO",
        "en_short_name": "Lesotho",
        name: "Basotho"
    },
    {
        id: "112",
        "alpha_2_code": "BY",
        "alpha_3_code": "BLR",
        "en_short_name": "Belarus",
        name: "Belarusian"
    },
    {
        id: "56",
        "alpha_2_code": "BE",
        "alpha_3_code": "BEL",
        "en_short_name": "Belgium",
        name: "Belgian"
    },
    {
        id: "84",
        "alpha_2_code": "BZ",
        "alpha_3_code": "BLZ",
        "en_short_name": "Belize",
        name: "Belizean"
    },
    {
        id: "204",
        "alpha_2_code": "BJ",
        "alpha_3_code": "BEN",
        "en_short_name": "Benin",
        name: "Beninese, Beninois"
    },
    {
        id: "60",
        "alpha_2_code": "BM",
        "alpha_3_code": "BMU",
        "en_short_name": "Bermuda",
        name: "Bermudian, Bermudan"
    },
    {
        id: "64",
        "alpha_2_code": "BT",
        "alpha_3_code": "BTN",
        "en_short_name": "Bhutan",
        name: "Bhutanese"
    },
    {
        id: "68",
        "alpha_2_code": "BO",
        "alpha_3_code": "BOL",
        "en_short_name": "Bolivia (Plurinational State of)",
        name: "Bolivian"
    },
    {
        id: "535",
        "alpha_2_code": "BQ",
        "alpha_3_code": "BES",
        "en_short_name": "Bonaire, Sint Eustatius and Saba",
        name: "Bonaire"
    },
    {
        id: "70",
        "alpha_2_code": "BA",
        "alpha_3_code": "BIH",
        "en_short_name": "Bosnia and Herzegovina",
        name: "Bosnian or Herzegovinian"
    },
    {
        id: "72",
        "alpha_2_code": "BW",
        "alpha_3_code": "BWA",
        "en_short_name": "Botswana",
        name: "Botswanan, Motswana"
    },
    {
        id: "74",
        "alpha_2_code": "BV",
        "alpha_3_code": "BVT",
        "en_short_name": "Bouvet Island",
        name: "Bouvet Island"
    },
    {
        id: "76",
        "alpha_2_code": "BR",
        "alpha_3_code": "BRA",
        "en_short_name": "Brazil",
        name: "Brazilian"
    },
    {
        id: "826",
        "alpha_2_code": "GB",
        "alpha_3_code": "GBR",
        "en_short_name": "United Kingdom of Great Britain and Northern Ireland",
        name: "British, UK"
    },
    {
        id: "92",
        "alpha_2_code": "VG",
        "alpha_3_code": "VGB",
        "en_short_name": "Virgin Islands (British)",
        name: "British Virgin Island"
    },
    {
        id: "86",
        "alpha_2_code": "IO",
        "alpha_3_code": "IOT",
        "en_short_name": "British Indian Ocean Territory",
        name: "BIOT"
    },
    {
        id: "96",
        "alpha_2_code": "BN",
        "alpha_3_code": "BRN",
        "en_short_name": "Brunei Darussalam",
        name: "Bruneian"
    },
    {
        id: "100",
        "alpha_2_code": "BG",
        "alpha_3_code": "BGR",
        "en_short_name": "Bulgaria",
        name: "Bulgarian"
    },
    {
        id: "854",
        "alpha_2_code": "BF",
        "alpha_3_code": "BFA",
        "en_short_name": "Burkina Faso",
        name: "Burkinab\u00e9"
    },
    {
        id: "108",
        "alpha_2_code": "BI",
        "alpha_3_code": "BDI",
        "en_short_name": "Burundi",
        name: "Burundian"
    },
    {
        id: "652",
        "alpha_2_code": "BL",
        "alpha_3_code": "BLM",
        "en_short_name": "Saint Barth\u00e9lemy",
        name: "Barth\u00e9lemois"
    },
    {
        id: "104",
        "alpha_2_code": "MM",
        "alpha_3_code": "MMR",
        "en_short_name": "Myanmar",
        name: "Burmese"
    },
    {
        id: "132",
        "alpha_2_code": "CV",
        "alpha_3_code": "CPV",
        "en_short_name": "Cabo Verde",
        name: "Cabo Verdean"
    },
    {
        id: "116",
        "alpha_2_code": "KH",
        "alpha_3_code": "KHM",
        "en_short_name": "Cambodia",
        name: "Cambodian"
    },
    {
        id: "120",
        "alpha_2_code": "CM",
        "alpha_3_code": "CMR",
        "en_short_name": "Cameroon",
        name: "Cameroonian"
    },
    {
        id: "124",
        "alpha_2_code": "CA",
        "alpha_3_code": "CAN",
        "en_short_name": "Canada",
        name: "Canadian"
    },
    {
        id: "136",
        "alpha_2_code": "KY",
        "alpha_3_code": "CYM",
        "en_short_name": "Cayman Islands",
        name: "Caymanian"
    },
    {
        id: "140",
        "alpha_2_code": "CF",
        "alpha_3_code": "CAF",
        "en_short_name": "Central African Republic",
        name: "Central African"
    },
    {
        id: "158",
        "alpha_2_code": "TW",
        "alpha_3_code": "TWN",
        "en_short_name": "Taiwan, Province of China",
        name: "Chinese, Taiwanese"
    },
    {
        id: "148",
        "alpha_2_code": "TD",
        "alpha_3_code": "TCD",
        "en_short_name": "Chad",
        name: "Chadian"
    },
    {
        id: "831",
        "alpha_2_code": "GG",
        "alpha_3_code": "GGY",
        "en_short_name": "Guernsey",
        name: "Channel Island"
    },
    {
        id: "832",
        "alpha_2_code": "JE",
        "alpha_3_code": "JEY",
        "en_short_name": "Jersey",
        name: "Channel Island"
    },
    {
        id: "152",
        "alpha_2_code": "CL",
        "alpha_3_code": "CHL",
        "en_short_name": "Chile",
        name: "Chilean"
    },
    {
        id: "156",
        "alpha_2_code": "CN",
        "alpha_3_code": "CHN",
        "en_short_name": "China",
        name: "Chinese"
    },
    {
        id: "162",
        "alpha_2_code": "CX",
        "alpha_3_code": "CXR",
        "en_short_name": "Christmas Island",
        name: "Christmas Island"
    },
    {
        id: "166",
        "alpha_2_code": "CC",
        "alpha_3_code": "CCK",
        "en_short_name": "Cocos (Keeling) Islands",
        name: "Cocos Island"
    },
    {
        id: "170",
        "alpha_2_code": "CO",
        "alpha_3_code": "COL",
        "en_short_name": "Colombia",
        name: "Colombian"
    },
    {
        id: "174",
        "alpha_2_code": "KM",
        "alpha_3_code": "COM",
        "en_short_name": "Comoros",
        name: "Comoran, Comorian"
    },
    {
        id: "178",
        "alpha_2_code": "CG",
        "alpha_3_code": "COG",
        "en_short_name": "Congo (Republic of the)",
        name: "Congolese"
    },
    {
        id: "180",
        "alpha_2_code": "CD",
        "alpha_3_code": "COD",
        "en_short_name": "Congo (Democratic Republic of the)",
        name: "Congolese"
    },
    {
        id: "184",
        "alpha_2_code": "CK",
        "alpha_3_code": "COK",
        "en_short_name": "Cook Islands",
        name: "Cook Island"
    },
    {
        id: "188",
        "alpha_2_code": "CR",
        "alpha_3_code": "CRI",
        "en_short_name": "Costa Rica",
        name: "Costa Rican"
    }, 
    {
        id: "191",
        "alpha_2_code": "HR",
        "alpha_3_code": "HRV",
        "en_short_name": "Croatia",
        name: "Croatian"
    },
    {
        id: "192",
        "alpha_2_code": "CU",
        "alpha_3_code": "CUB",
        "en_short_name": "Cuba",
        name: "Cuban"
    },
    {
        id: "531",
        "alpha_2_code": "CW",
        "alpha_3_code": "CUW",
        "en_short_name": "Cura\u00e7ao",
        name: "Cura\u00e7aoan"
    },
    {
        id: "196",
        "alpha_2_code": "CY",
        "alpha_3_code": "CYP",
        "en_short_name": "Cyprus",
        name: "Cypriot"
    },
    {
        id: "203",
        "alpha_2_code": "CZ",
        "alpha_3_code": "CZE",
        "en_short_name": "Czech Republic",
        name: "Czech"
    },
    {
        id: "208",
        "alpha_2_code": "DK",
        "alpha_3_code": "DNK",
        "en_short_name": "Denmark",
        name: "Danish"
    },
    {
        id: "262",
        "alpha_2_code": "DJ",
        "alpha_3_code": "DJI",
        "en_short_name": "Djibouti",
        name: "Djiboutian"
    },
    {
        id: "212",
        "alpha_2_code": "DM",
        "alpha_3_code": "DMA",
        "en_short_name": "Dominica",
        name: "Dominican"
    },
    {
        id: "214",
        "alpha_2_code": "DO",
        "alpha_3_code": "DOM",
        "en_short_name": "Dominican Republic",
        name: "Dominican"
    },
    {
        id: "528",
        "alpha_2_code": "NL",
        "alpha_3_code": "NLD",
        "en_short_name": "Netherlands",
        name: "Dutch, Netherlandic"
    },
    {
        id: "218",
        "alpha_2_code": "EC",
        "alpha_3_code": "ECU",
        "en_short_name": "Ecuador",
        name: "Ecuadorian"
    },
    {
        id: "818",
        "alpha_2_code": "EG",
        "alpha_3_code": "EGY",
        "en_short_name": "Egypt",
        name: "Egyptian"
    },
    {
        id: "784",
        "alpha_2_code": "AE",
        "alpha_3_code": "ARE",
        "en_short_name": "United Arab Emirates",
        name: "Emirati, Emirian, Emiri"
    }, 
    {
        id: "226",
        "alpha_2_code": "GQ",
        "alpha_3_code": "GNQ",
        "en_short_name": "Equatorial Guinea",
        name: "Equatorial Guinean, Equatoguinean"
    },
    {
        id: "232",
        "alpha_2_code": "ER",
        "alpha_3_code": "ERI",
        "en_short_name": "Eritrea",
        name: "Eritrean"
    },
    {
        id: "233",
        "alpha_2_code": "EE",
        "alpha_3_code": "EST",
        "en_short_name": "Estonia",
        name: "Estonian"
    },
    {
        id: "231",
        "alpha_2_code": "ET",
        "alpha_3_code": "ETH",
        "en_short_name": "Ethiopia",
        name: "Ethiopian"
    },
    {
        id: "238",
        "alpha_2_code": "FK",
        "alpha_3_code": "FLK",
        "en_short_name": "Falkland Islands (Malvinas)",
        name: "Falkland Island"
    },
    {
        id: "234",
        "alpha_2_code": "FO",
        "alpha_3_code": "FRO",
        "en_short_name": "Faroe Islands",
        name: "Faroese"
    },
    {
        id: "242",
        "alpha_2_code": "FJ",
        "alpha_3_code": "FJI",
        "en_short_name": "Fiji",
        name: "Fijian"
    },
    {
        id: "246",
        "alpha_2_code": "FI",
        "alpha_3_code": "FIN",
        "en_short_name": "Finland",
        name: "Finnish"
    },
    {
        id: "250",
        "alpha_2_code": "FR",
        "alpha_3_code": "FRA",
        "en_short_name": "France",
        name: "French"
    },
    {
        id: "254",
        "alpha_2_code": "GF",
        "alpha_3_code": "GUF",
        "en_short_name": "French Guiana",
        name: "French Guianese"
    },
    {
        id: "258",
        "alpha_2_code": "PF",
        "alpha_3_code": "PYF",
        "en_short_name": "French Polynesia",
        name: "French Polynesian"
    },
    {
        id: "260",
        "alpha_2_code": "TF",
        "alpha_3_code": "ATF",
        "en_short_name": "French Southern Territories",
        name: "French Southern Territories"
    },
    {
        id: "266",
        "alpha_2_code": "GA",
        "alpha_3_code": "GAB",
        "en_short_name": "Gabon",
        name: "Gabonese"
    },
    {
        id: "270",
        "alpha_2_code": "GM",
        "alpha_3_code": "GMB",
        "en_short_name": "Gambia",
        name: "Gambian"
    },
    {
        id: "268",
        "alpha_2_code": "GE",
        "alpha_3_code": "GEO",
        "en_short_name": "Georgia",
        name: "Georgian"
    },
    {
        id: "276",
        "alpha_2_code": "DE",
        "alpha_3_code": "DEU",
        "en_short_name": "Germany",
        name: "German"
    },
    {
        id: "288",
        "alpha_2_code": "GH",
        "alpha_3_code": "GHA",
        "en_short_name": "Ghana",
        name: "Ghanaian"
    },
    {
        id: "292",
        "alpha_2_code": "GI",
        "alpha_3_code": "GIB",
        "en_short_name": "Gibraltar",
        name: "Gibraltar"
    },
    {
        id: "300",
        "alpha_2_code": "GR",
        "alpha_3_code": "GRC",
        "en_short_name": "Greece",
        name: "Greek, Hellenic"
    },
    {
        id: "304",
        "alpha_2_code": "GL",
        "alpha_3_code": "GRL",
        "en_short_name": "Greenland",
        name: "Greenlandic"
    },
    {
        id: "308",
        "alpha_2_code": "GD",
        "alpha_3_code": "GRD",
        "en_short_name": "Grenada",
        name: "Grenadian"
    },
    {
        id: "312",
        "alpha_2_code": "GP",
        "alpha_3_code": "GLP",
        "en_short_name": "Guadeloupe",
        name: "Guadeloupe"
    },
    {
        id: "316",
        "alpha_2_code": "GU",
        "alpha_3_code": "GUM",
        "en_short_name": "Guam",
        name: "Guamanian, Guambat"
    },
    {
        id: "320",
        "alpha_2_code": "GT",
        "alpha_3_code": "GTM",
        "en_short_name": "Guatemala",
        name: "Guatemalan"
    }, 
    {
        id: "324",
        "alpha_2_code": "GN",
        "alpha_3_code": "GIN",
        "en_short_name": "Guinea",
        name: "Guinean"
    },
    {
        id: "624",
        "alpha_2_code": "GW",
        "alpha_3_code": "GNB",
        "en_short_name": "Guinea-Bissau",
        name: "Guinean-Bissau"
    },
    {
        id: "328",
        "alpha_2_code": "GY",
        "alpha_3_code": "GUY",
        "en_short_name": "Guyana",
        name: "Guyanese"
    },
    {
        id: "332",
        "alpha_2_code": "HT",
        "alpha_3_code": "HTI",
        "en_short_name": "Haiti",
        name: "Haitian"
    },
    {
        id: "334",
        "alpha_2_code": "HM",
        "alpha_3_code": "HMD",
        "en_short_name": "Heard Island and McDonald Islands",
        name: "Heard Island or McDonald Islands"
    },
    {
        id: "340",
        "alpha_2_code": "HN",
        "alpha_3_code": "HND",
        "en_short_name": "Honduras",
        name: "Honduran"
    },
    {
        id: "344",
        "alpha_2_code": "HK",
        "alpha_3_code": "HKG",
        "en_short_name": "Hong Kong",
        name: "Hong Kong, Hong Kongese"
    },
    {
        id: "348",
        "alpha_2_code": "HU",
        "alpha_3_code": "HUN",
        "en_short_name": "Hungary",
        name: "Hungarian, Magyar"
    },
    {
        id: "352",
        "alpha_2_code": "IS",
        "alpha_3_code": "ISL",
        "en_short_name": "Iceland",
        name: "Icelandic"
    },
    {
        id: "356",
        "alpha_2_code": "IN",
        "alpha_3_code": "IND",
        "en_short_name": "India",
        name: "Indian"
    },
    {
        id: "360",
        "alpha_2_code": "ID",
        "alpha_3_code": "IDN",
        "en_short_name": "Indonesia",
        name: "Indonesian"
    },
    {
        id: "364",
        "alpha_2_code": "IR",
        "alpha_3_code": "IRN",
        "en_short_name": "Iran",
        name: "Iranian, Persian"
    },
    {
        id: "368",
        "alpha_2_code": "IQ",
        "alpha_3_code": "IRQ",
        "en_short_name": "Iraq",
        name: "Iraqi"
    },
    {
        id: "372",
        "alpha_2_code": "IE",
        "alpha_3_code": "IRL",
        "en_short_name": "Ireland",
        name: "Irish"
    }, 
    {
        id: "376",
        "alpha_2_code": "IL",
        "alpha_3_code": "ISR",
        "en_short_name": "Israel",
        name: "Israeli"
    },
    {
        id: "380",
        "alpha_2_code": "IT",
        "alpha_3_code": "ITA",
        "en_short_name": "Italy",
        name: "Italian"
    },
    {
        id: "384",
        "alpha_2_code": "CI",
        "alpha_3_code": "CIV",
        "en_short_name": "C\u00f4te d'Ivoire",
        name: "Ivorian"
    },
    {
        id: "296",
        "alpha_2_code": "KI",
        "alpha_3_code": "KIR",
        "en_short_name": "Kiribati",
        name: "I-Kiribati"
    },
    {
        id: "388",
        "alpha_2_code": "JM",
        "alpha_3_code": "JAM",
        "en_short_name": "Jamaica",
        name: "Jamaican"
    },
    {
        id: "392",
        "alpha_2_code": "JP",
        "alpha_3_code": "JPN",
        "en_short_name": "Japan",
        name: "Japanese"
    }, 
    {
        id: "400",
        "alpha_2_code": "JO",
        "alpha_3_code": "JOR",
        "en_short_name": "Jordan",
        name: "Jordanian"
    },
    {
        id: "398",
        "alpha_2_code": "KZ",
        "alpha_3_code": "KAZ",
        "en_short_name": "Kazakhstan",
        name: "Kazakhstani, Kazakh"
    },
    {
        id: "404",
        "alpha_2_code": "KE",
        "alpha_3_code": "KEN",
        "en_short_name": "Kenya",
        name: "Kenyan"
    },
    {
        id: "659",
        "alpha_2_code": "KN",
        "alpha_3_code": "KNA",
        "en_short_name": "Saint Kitts and Nevis",
        name: "Kittitian or Nevisian"
    }, 
    {
        id: "414",
        "alpha_2_code": "KW",
        "alpha_3_code": "KWT",
        "en_short_name": "Kuwait",
        name: "Kuwaiti"
    },
    {
        id: "417",
        "alpha_2_code": "KG",
        "alpha_3_code": "KGZ",
        "en_short_name": "Kyrgyzstan",
        name: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
    },
    {
        id: "418",
        "alpha_2_code": "LA",
        "alpha_3_code": "LAO",
        "en_short_name": "Lao People's Democratic Republic",
        name: "Lao, Laotian"
    },
    {
        id: "428",
        "alpha_2_code": "LV",
        "alpha_3_code": "LVA",
        "en_short_name": "Latvia",
        name: "Latvian"
    },
    {
        id: "422",
        "alpha_2_code": "LB",
        "alpha_3_code": "LBN",
        "en_short_name": "Lebanon",
        name: "Lebanese"
    }, 
    {
        id: "430",
        "alpha_2_code": "LR",
        "alpha_3_code": "LBR",
        "en_short_name": "Liberia",
        name: "Liberian"
    },
    {
        id: "434",
        "alpha_2_code": "LY",
        "alpha_3_code": "LBY",
        "en_short_name": "Libya",
        name: "Libyan"
    },
    {
        id: "438",
        "alpha_2_code": "LI",
        "alpha_3_code": "LIE",
        "en_short_name": "Liechtenstein",
        name: "Liechtenstein"
    },
    {
        id: "440",
        "alpha_2_code": "LT",
        "alpha_3_code": "LTU",
        "en_short_name": "Lithuania",
        name: "Lithuanian"
    },
    {
        id: "442",
        "alpha_2_code": "LU",
        "alpha_3_code": "LUX",
        "en_short_name": "Luxembourg",
        name: "Luxembourg, Luxembourgish"
    },
    {
        id: "446",
        "alpha_2_code": "MO",
        "alpha_3_code": "MAC",
        "en_short_name": "Macao",
        name: "Macanese, Chinese"
    },
    {
        id: "807",
        "alpha_2_code": "MK",
        "alpha_3_code": "MKD",
        "en_short_name": "Macedonia (the former Yugoslav Republic of)",
        name: "Macedonian"
    },
    {
        id: "450",
        "alpha_2_code": "MG",
        "alpha_3_code": "MDG",
        "en_short_name": "Madagascar",
        name: "Malagasy"
    },
    {
        id: "454",
        "alpha_2_code": "MW",
        "alpha_3_code": "MWI",
        "en_short_name": "Malawi",
        name: "Malawian"
    },
    {
        id: "458",
        "alpha_2_code": "MY",
        "alpha_3_code": "MYS",
        "en_short_name": "Malaysia",
        name: "Malaysian"
    },
    {
        id: "462",
        "alpha_2_code": "MV",
        "alpha_3_code": "MDV",
        "en_short_name": "Maldives",
        name: "Maldivian"
    },
    {
        id: "466",
        "alpha_2_code": "ML",
        "alpha_3_code": "MLI",
        "en_short_name": "Mali",
        name: "Malian, Malinese"
    },
    {
        id: "470",
        "alpha_2_code": "MT",
        "alpha_3_code": "MLT",
        "en_short_name": "Malta",
        name: "Maltese"
    },
    {
        id: "833",
        "alpha_2_code": "IM",
        "alpha_3_code": "IMN",
        "en_short_name": "Isle of Man",
        name: "Manx"
    }, 
    {
        id: "584",
        "alpha_2_code": "MH",
        "alpha_3_code": "MHL",
        "en_short_name": "Marshall Islands",
        name: "Marshallese"
    },
    {
        id: "474",
        "alpha_2_code": "MQ",
        "alpha_3_code": "MTQ",
        "en_short_name": "Martinique",
        name: "Martiniquais, Martinican"
    },
    {
        id: "478",
        "alpha_2_code": "MR",
        "alpha_3_code": "MRT",
        "en_short_name": "Mauritania",
        name: "Mauritanian"
    },
    {
        id: "480",
        "alpha_2_code": "MU",
        "alpha_3_code": "MUS",
        "en_short_name": "Mauritius",
        name: "Mauritian"
    },
    {
        id: "175",
        "alpha_2_code": "YT",
        "alpha_3_code": "MYT",
        "en_short_name": "Mayotte",
        name: "Mahoran"
    },
    {
        id: "484",
        "alpha_2_code": "MX",
        "alpha_3_code": "MEX",
        "en_short_name": "Mexico",
        name: "Mexican"
    },
    {
        id: "583",
        "alpha_2_code": "FM",
        "alpha_3_code": "FSM",
        "en_short_name": "Micronesia (Federated States of)",
        name: "Micronesian"
    },
    {
        id: "498",
        "alpha_2_code": "MD",
        "alpha_3_code": "MDA",
        "en_short_name": "Moldova (Republic of)",
        name: "Moldovan"
    },
    {
        id: "492",
        "alpha_2_code": "MC",
        "alpha_3_code": "MCO",
        "en_short_name": "Monaco",
        name: "Mon\u00e9gasque, Monacan"
    },
    {
        id: "496",
        "alpha_2_code": "MN",
        "alpha_3_code": "MNG",
        "en_short_name": "Mongolia",
        name: "Mongolian"
    },
    {
        id: "499",
        "alpha_2_code": "ME",
        "alpha_3_code": "MNE",
        "en_short_name": "Montenegro",
        name: "Montenegrin"
    },
    {
        id: "500",
        "alpha_2_code": "MS",
        "alpha_3_code": "MSR",
        "en_short_name": "Montserrat",
        name: "Montserratian"
    },
    {
        id: "504",
        "alpha_2_code": "MA",
        "alpha_3_code": "MAR",
        "en_short_name": "Morocco",
        name: "Moroccan"
    },
    {
        id: "508",
        "alpha_2_code": "MZ",
        "alpha_3_code": "MOZ",
        "en_short_name": "Mozambique",
        name: "Mozambican"
    },
    
    {
        id: "516",
        "alpha_2_code": "NA",
        "alpha_3_code": "NAM",
        "en_short_name": "Namibia",
        name: "Namibian"
    },
    {
        id: "520",
        "alpha_2_code": "NR",
        "alpha_3_code": "NRU",
        "en_short_name": "Nauru",
        name: "Nauruan"
    },
    {
        id: "524",
        "alpha_2_code": "NP",
        "alpha_3_code": "NPL",
        "en_short_name": "Nepal",
        name: "Nepali, Nepalese"
    }, 
    {
        id: "540",
        "alpha_2_code": "NC",
        "alpha_3_code": "NCL",
        "en_short_name": "New Caledonia",
        name: "New Caledonian"
    },
    {
        id: "554",
        "alpha_2_code": "NZ",
        "alpha_3_code": "NZL",
        "en_short_name": "New Zealand",
        name: "New Zealand, NZ"
    },
    {
        id: "558",
        "alpha_2_code": "NI",
        "alpha_3_code": "NIC",
        "en_short_name": "Nicaragua",
        name: "Nicaraguan"
    },
    {
        id: "562",
        "alpha_2_code": "NE",
        "alpha_3_code": "NER",
        "en_short_name": "Niger",
        name: "Nigerien"
    },
    {
        id: "566",
        "alpha_2_code": "NG",
        "alpha_3_code": "NGA",
        "en_short_name": "Nigeria",
        name: "Nigerian"
    },
    {
        id: "570",
        "alpha_2_code": "NU",
        "alpha_3_code": "NIU",
        "en_short_name": "Niue",
        name: "Niuean"
    },
    {
        id: "548",
        "alpha_2_code": "VU",
        "alpha_3_code": "VUT",
        "en_short_name": "Vanuatu",
        name: "Ni-Vanuatu, Vanuatuan"
    },
    {
        id: "574",
        "alpha_2_code": "NF",
        "alpha_3_code": "NFK",
        "en_short_name": "Norfolk Island",
        name: "Norfolk Island"
    },
    {
        id: "408",
        "alpha_2_code": "KP",
        "alpha_3_code": "PRK",
        "en_short_name": "Korea (Democratic People's Republic of)",
        name: "North Korean"
    },
    {
        id: "580",
        "alpha_2_code": "MP",
        "alpha_3_code": "MNP",
        "en_short_name": "Northern Mariana Islands",
        name: "Northern Marianan"
    },
    {
        id: "578",
        "alpha_2_code": "NO",
        "alpha_3_code": "NOR",
        "en_short_name": "Norway",
        name: "Norwegian"
    },
    {
        id: "512",
        "alpha_2_code": "OM",
        "alpha_3_code": "OMN",
        "en_short_name": "Oman",
        name: "Omani"
    },
    {
        id: "586",
        "alpha_2_code": "PK",
        "alpha_3_code": "PAK",
        "en_short_name": "Pakistan",
        name: "Pakistani"
    },
    {
        id: "585",
        "alpha_2_code": "PW",
        "alpha_3_code": "PLW",
        "en_short_name": "Palau",
        name: "Palauan"
    },
    {
        id: "275",
        "alpha_2_code": "PS",
        "alpha_3_code": "PSE",
        "en_short_name": "Palestine, State of",
        name: "Palestinian"
    },
    {
        id: "591",
        "alpha_2_code": "PA",
        "alpha_3_code": "PAN",
        "en_short_name": "Panama",
        name: "Panamanian"
    },
    {
        id: "598",
        "alpha_2_code": "PG",
        "alpha_3_code": "PNG",
        "en_short_name": "Papua New Guinea",
        name: "Papua New Guinean, Papuan"
    },
    {
        id: "600",
        "alpha_2_code": "PY",
        "alpha_3_code": "PRY",
        "en_short_name": "Paraguay",
        name: "Paraguayan"
    },
    {
        id: "604",
        "alpha_2_code": "PE",
        "alpha_3_code": "PER",
        "en_short_name": "Peru",
        name: "Peruvian"
    },
    {
        id: "608",
        "alpha_2_code": "PH",
        "alpha_3_code": "PHL",
        "en_short_name": "Philippines",
        name: "Philippine, Filipino"
    },
    {
        id: "612",
        "alpha_2_code": "PN",
        "alpha_3_code": "PCN",
        "en_short_name": "Pitcairn",
        name: "Pitcairn Island"
    },
    {
        id: "616",
        "alpha_2_code": "PL",
        "alpha_3_code": "POL",
        "en_short_name": "Poland",
        name: "Polish"
    },
    {
        id: "620",
        "alpha_2_code": "PT",
        "alpha_3_code": "PRT",
        "en_short_name": "Portugal",
        name: "Portuguese"
    },
    {
        id: "630",
        "alpha_2_code": "PR",
        "alpha_3_code": "PRI",
        "en_short_name": "Puerto Rico",
        name: "Puerto Rican"
    },
    {
        id: "634",
        "alpha_2_code": "QA",
        "alpha_3_code": "QAT",
        "en_short_name": "Qatar",
        name: "Qatari"
    },
    {
        id: "638",
        "alpha_2_code": "RE",
        "alpha_3_code": "REU",
        "en_short_name": "R\u00e9union",
        name: "R\u00e9unionese, R\u00e9unionnais"
    },
    {
        id: "642",
        "alpha_2_code": "RO",
        "alpha_3_code": "ROU",
        "en_short_name": "Romania",
        name: "Romanian"
    },
    {
        id: "643",
        "alpha_2_code": "RU",
        "alpha_3_code": "RUS",
        "en_short_name": "Russian Federation",
        name: "Russian"
    },
    {
        id: "646",
        "alpha_2_code": "RW",
        "alpha_3_code": "RWA",
        "en_short_name": "Rwanda",
        name: "Rwandan"
    },
   
    {
        id: "732",
        "alpha_2_code": "EH",
        "alpha_3_code": "ESH",
        "en_short_name": "Western Sahara",
        name: "Sahrawi, Sahrawian, Sahraouian"
    },
    {
        id: "654",
        "alpha_2_code": "SH",
        "alpha_3_code": "SHN",
        "en_short_name": "Saint Helena, Ascension and Tristan da Cunha",
        name: "Saint Helenian"
    }, 
    {
        id: "662",
        "alpha_2_code": "LC",
        "alpha_3_code": "LCA",
        "en_short_name": "Saint Lucia",
        name: "Saint Lucian"
    },
    {
        id: "663",
        "alpha_2_code": "MF",
        "alpha_3_code": "MAF",
        "en_short_name": "Saint Martin (French part)",
        name: "Saint-Martinoise"
    },
    {
        id: "666",
        "alpha_2_code": "PM",
        "alpha_3_code": "SPM",
        "en_short_name": "Saint Pierre and Miquelon",
        name: "Saint-Pierrais or Miquelonnais"
    },
    {
        id: "670",
        "alpha_2_code": "VC",
        "alpha_3_code": "VCT",
        "en_short_name": "Saint Vincent and the Grenadines",
        name: "Saint Vincentian, Vincentian"
    },
    {
        id: "222",
        "alpha_2_code": "SV",
        "alpha_3_code": "SLV",
        "en_short_name": "El Salvador",
        name: "Salvadoran"
    },
    {
        id: "882",
        "alpha_2_code": "WS",
        "alpha_3_code": "WSM",
        "en_short_name": "Samoa",
        name: "Samoan"
    },
    {
        id: "674",
        "alpha_2_code": "SM",
        "alpha_3_code": "SMR",
        "en_short_name": "San Marino",
        name: "Sammarinese"
    },
    {
        id: "678",
        "alpha_2_code": "ST",
        "alpha_3_code": "STP",
        "en_short_name": "Sao Tome and Principe",
        name: "S\u00e3o Tom\u00e9an"
    },
    {
        id: "682",
        "alpha_2_code": "SA",
        "alpha_3_code": "SAU",
        "en_short_name": "Saudi Arabia",
        name: "Saudi, Saudi Arabian"
    },
    {
        id: "686",
        "alpha_2_code": "SN",
        "alpha_3_code": "SEN",
        "en_short_name": "Senegal",
        name: "Senegalese"
    },
    {
        id: "688",
        "alpha_2_code": "RS",
        "alpha_3_code": "SRB",
        "en_short_name": "Serbia",
        name: "Serbian"
    },
    {
        id: "690",
        "alpha_2_code": "SC",
        "alpha_3_code": "SYC",
        "en_short_name": "Seychelles",
        name: "Seychellois"
    },
    {
        id: "694",
        "alpha_2_code": "SL",
        "alpha_3_code": "SLE",
        "en_short_name": "Sierra Leone",
        name: "Sierra Leonean"
    },
    {
        id: "702",
        "alpha_2_code": "SG",
        "alpha_3_code": "SGP",
        "en_short_name": "Singapore",
        name: "Singaporean"
    },
    {
        id: "534",
        "alpha_2_code": "SX",
        "alpha_3_code": "SXM",
        "en_short_name": "Sint Maarten (Dutch part)",
        name: "Sint Maarten"
    },
    {
        id: "703",
        "alpha_2_code": "SK",
        "alpha_3_code": "SVK",
        "en_short_name": "Slovakia",
        name: "Slovak"
    },
    {
        id: "705",
        "alpha_2_code": "SI",
        "alpha_3_code": "SVN",
        "en_short_name": "Slovenia",
        name: "Slovenian, Slovene"
    },
    {
        id: "90",
        "alpha_2_code": "SB",
        "alpha_3_code": "SLB",
        "en_short_name": "Solomon Islands",
        name: "Solomon Island"
    },
    {
        id: "706",
        "alpha_2_code": "SO",
        "alpha_3_code": "SOM",
        "en_short_name": "Somalia",
        name: "Somali, Somalian"
    },
    {
        id: "710",
        "alpha_2_code": "ZA",
        "alpha_3_code": "ZAF",
        "en_short_name": "South Africa",
        name: "South African"
    },
    {
        id: "239",
        "alpha_2_code": "GS",
        "alpha_3_code": "SGS",
        "en_short_name": "South Georgia and the South Sandwich Islands",
        name: "South Georgia or South Sandwich Islands"
    },
    {
        id: "410",
        "alpha_2_code": "KR",
        "alpha_3_code": "KOR",
        "en_short_name": "Korea (Republic of)",
        name: "South Korean"
    },
    {
        id: "728",
        "alpha_2_code": "SS",
        "alpha_3_code": "SSD",
        "en_short_name": "South Sudan",
        name: "South Sudanese"
    },
    {
        id: "724",
        "alpha_2_code": "ES",
        "alpha_3_code": "ESP",
        "en_short_name": "Spain",
        name: "Spanish"
    },
    {
        id: "144",
        "alpha_2_code": "LK",
        "alpha_3_code": "LKA",
        "en_short_name": "Sri Lanka",
        name: "Sri Lankan"
    },
    {
        id: "729",
        "alpha_2_code": "SD",
        "alpha_3_code": "SDN",
        "en_short_name": "Sudan",
        name: "Sudanese"
    },
    {
        id: "740",
        "alpha_2_code": "SR",
        "alpha_3_code": "SUR",
        "en_short_name": "Suriname",
        name: "Surinamese"
    },
    {
        id: "744",
        "alpha_2_code": "SJ",
        "alpha_3_code": "SJM",
        "en_short_name": "Svalbard and Jan Mayen",
        name: "Svalbard"
    },
    {
        id: "748",
        "alpha_2_code": "SZ",
        "alpha_3_code": "SWZ",
        "en_short_name": "Swaziland",
        name: "Swazi"
    },
    {
        id: "752",
        "alpha_2_code": "SE",
        "alpha_3_code": "SWE",
        "en_short_name": "Sweden",
        name: "Swedish"
    },
    {
        id: "756",
        "alpha_2_code": "CH",
        "alpha_3_code": "CHE",
        "en_short_name": "Switzerland",
        name: "Swiss"
    },
    {
        id: "760",
        "alpha_2_code": "SY",
        "alpha_3_code": "SYR",
        "en_short_name": "Syrian Arab Republic",
        name: "Syrian"
    },
    
    {
        id: "762",
        "alpha_2_code": "TJ",
        "alpha_3_code": "TJK",
        "en_short_name": "Tajikistan",
        name: "Tajikistani"
    },
    {
        id: "834",
        "alpha_2_code": "TZ",
        "alpha_3_code": "TZA",
        "en_short_name": "Tanzania, United Republic of",
        name: "Tanzanian"
    },
    {
        id: "764",
        "alpha_2_code": "TH",
        "alpha_3_code": "THA",
        "en_short_name": "Thailand",
        name: "Thai"
    },
    {
        id: "626",
        "alpha_2_code": "TL",
        "alpha_3_code": "TLS",
        "en_short_name": "Timor-Leste",
        name: "Timorese"
    },
    {
        id: "768",
        "alpha_2_code": "TG",
        "alpha_3_code": "TGO",
        "en_short_name": "Togo",
        name: "Togolese"
    },
    {
        id: "772",
        "alpha_2_code": "TK",
        "alpha_3_code": "TKL",
        "en_short_name": "Tokelau",
        name: "Tokelauan"
    },
    {
        id: "776",
        "alpha_2_code": "TO",
        "alpha_3_code": "TON",
        "en_short_name": "Tonga",
        name: "Tongan"
    },
    {
        id: "780",
        "alpha_2_code": "TT",
        "alpha_3_code": "TTO",
        "en_short_name": "Trinidad and Tobago",
        name: "Trinidadian or Tobagonian"
    },
    {
        id: "788",
        "alpha_2_code": "TN",
        "alpha_3_code": "TUN",
        "en_short_name": "Tunisia",
        name: "Tunisian"
    },
    {
        id: "792",
        "alpha_2_code": "TR",
        "alpha_3_code": "TUR",
        "en_short_name": "Turkey",
        name: "Turkish"
    },
    {
        id: "795",
        "alpha_2_code": "TM",
        "alpha_3_code": "TKM",
        "en_short_name": "Turkmenistan",
        name: "Turkmen"
    },
    {
        id: "796",
        "alpha_2_code": "TC",
        "alpha_3_code": "TCA",
        "en_short_name": "Turks and Caicos Islands",
        name: "Turks and Caicos Island"
    },
    {
        id: "798",
        "alpha_2_code": "TV",
        "alpha_3_code": "TUV",
        "en_short_name": "Tuvalu",
        name: "Tuvaluan"
    },
    {
        id: "800",
        "alpha_2_code": "UG",
        "alpha_3_code": "UGA",
        "en_short_name": "Uganda",
        name: "Ugandan"
    },
    {
        id: "804",
        "alpha_2_code": "UA",
        "alpha_3_code": "UKR",
        "en_short_name": "Ukraine",
        name: "Ukrainian"
    },
    {
        id: "858",
        "alpha_2_code": "UY",
        "alpha_3_code": "URY",
        "en_short_name": "Uruguay",
        name: "Uruguayan"
    },
    {
        id: "860",
        "alpha_2_code": "UZ",
        "alpha_3_code": "UZB",
        "en_short_name": "Uzbekistan",
        name: "Uzbekistani, Uzbek"
    }, 
    {
        id: "850",
        "alpha_2_code": "VI",
        "alpha_3_code": "VIR",
        "en_short_name": "Virgin Islands (U.S.)",
        name: "U.S. Virgin Island"
    },
    {
        id: "336",
        "alpha_2_code": "VA",
        "alpha_3_code": "VAT",
        "en_short_name": "Vatican City State",
        name: "Vatican"
    },
    {
        id: "862",
        "alpha_2_code": "VE",
        "alpha_3_code": "VEN",
        "en_short_name": "Venezuela (Bolivarian Republic of)",
        name: "Venezuelan"
    },
    {
        id: "704",
        "alpha_2_code": "VN",
        "alpha_3_code": "VNM",
        "en_short_name": "Vietnam",
        name: "Vietnamese"
    }, 
    {
        id: "876",
        "alpha_2_code": "WF",
        "alpha_3_code": "WLF",
        "en_short_name": "Wallis and Futuna",
        name: "Wallis and Futuna, Wallisian or Futunan"
    },
    {
        id: "887",
        "alpha_2_code": "YE",
        "alpha_3_code": "YEM",
        "en_short_name": "Yemen",
        name: "Yemeni"
    },
    {
        id: "894",
        "alpha_2_code": "ZM",
        "alpha_3_code": "ZMB",
        "en_short_name": "Zambia",
        name: "Zambian"
    },
    {
        id: "716",
        "alpha_2_code": "ZW",
        "alpha_3_code": "ZWE",
        "en_short_name": "Zimbabwe",
        name: "Zimbabwean"
    }
];