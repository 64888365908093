/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { InputText } from "primereact/inputtext";
import { Col, Form, Row, Card,  Container, Button, Modal } from 'react-bootstrap';
import { Select, Divider, Input, Collapse, Table, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { handleRequest } from '../../redux/config/env'; 
import { Calendar } from 'primereact/calendar'; 
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import moment from 'moment';

const { Option } = Select;
const { Panel } = Collapse;

class VehicleRegistration extends Component {

constructor(props) {
    super(props)

    this.state = {
        id: -1,
        vehicleRegistrationId: -1, 
        plateNo: "",
        make: 0,
        modelYear: 0,
        grade: 0,
        type: 0,
        colour: 0,
        engineNo: "",
        identificationNumber: "",
        cylinderVolume: "",
        enginePowerHP: "",
        fuelType: 0,
        vehicleClass: "",
        weight: 0,
        maxLoadCapacity: 0,
        maxGrossWeight: 0,
        kerbWeight: 0,
        trailerMaxLoadedWeight: 0,
        seatingCapacity: 0,
        typeOfBody: 0,
        issuingProvinceCountry: "",
        theDateOfUsageOnTraffic: null,
        firstRegistrationDate: null,
        registrationSerialNo: "",
        registrationDate: null,
        tradeName: "",
        value: "",
        nameOfOwner: "",
        ownerIqamaNo: "",
        vehicleNo: "",
        registrationNo: "",
        sequenceNo: "",
        expiryDate: null,
        firstTestExpiryDate: "",
        kmYear: "",
        mobilizationOrder: "",

        expandIconPosition: 'left',
        VRMakes:[],   
        VRModelYears:[],   
        VRGrades:[],   
        VRTypes:[],   
        VRColours:[],   
        VRFuelTypes:[],   

        VehicleInfo:[],

        hidden:true,
        hiddenSave:true,
        hiddenUpdate:true,
        hiddenCreateNewButton:false,

        createNewButtonVariant: "info",
        createNewButton: "Create New",

        pagination: {
            current: 1,
            pageSize: 5,
        },

        total:0,
        loading: false,

    }

    this.actionTemplate = this.actionTemplate.bind(this); 
}

componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });

    this.getCodesVRMake();
    this.getCodesVRModelYear();
    this.getCodesVRGrade();
    this.getCodesVRType();
    this.getCodesVRColour();
    this.getCodesVRFuelType();
} 

actionTemplate(rowData) {
    return <React.Fragment> 
        <CustomMaterialMenu row={rowData} 
            onDeleteRow={this.deleteVehicleRegistration.bind(this)} 
            onEditRow={this.editVehicleRegistration.bind(this)} />   
        </React.Fragment>;
};

editVehicleRegistration = async (row) => {
    await this.setState({
        hidden: false,
        hiddenSave: true,
        hiddenUpdate: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
        hiddenCreateNewButton:true,
        
        id: row.id,
        vehicleRegistrationId: row.id, 
        plateNo: row.plateNo,
        make: row.make, 
        makeAck: (row.codeMake!=null && row.codeMake!=undefined  ) ? row.codeMake.name : "" ,
        modelYear: row.modelYear, 
        modelYearAck: (row.codeModelYear!=null && row.codeModelYear!=undefined  ) ? row.codeModelYear.name : "" ,
        grade:row.grade,
        gradeAck: (row.codeGrade!=null && row.codeGrade!=undefined  ) ? row.codeGrade.name : "" ,
        type:row.type,
        typeAck: (row.codeType!=null && row.codeType!=undefined  ) ? row.codeType.name : "" ,
        colour:row.colour,
        colourAck: (row.codeColour!=null && row.codeColour!=undefined  ) ? row.codeColour.name : "" ,
        engineNo: row.engineNo,
        identificationNumber: row.identificationNumber,
        cylinderVolume: row.cylinderVolume,
        enginePowerHP: row.enginePowerHP,
        fuelType:row.fuelType,
        fuelTypeAck: (row.codeFuelType!=null && row.codeFuelType!=undefined  ) ? row.codeFuelType.name : "" ,
        vehicleClass: row.vehicleClass,
        weight: row.weight,
        maxLoadCapacity: row.maxLoadCapacity,
        maxGrossWeight: row.maxGrossWeight,
        kerbWeight: row.kerbWeight,
        trailerMaxLoadedWeight: row.trailerMaxLoadedWeight,
        seatingCapacity: row.seatingCapacity,
        typeOfBody: row.typeOfBody,
        issuingProvinceCountry: row.issuingProvinceCountry,
        theDateOfUsageOnTraffic: new Date(row.theDateOfUsageOnTraffic),
        firstRegistrationDate: new Date(row.firstRegistrationDate),
        registrationSerialNo: row.registrationSerialNo,
        registrationDate: new Date(row.registrationDate),
        tradeName: row.tradeName,
        value: row.value,
        nameOfOwner: row.nameOfOwner,
        ownerIqamaNo: row.ownerIqamaNo,
        vehicleNo: row.vehicleNo,
        registrationNo: row.registrationNo,
        sequenceNo: row.sequenceNo,
        expiryDate: new Date(row.expiryDate),
        firstTestExpiryDate: row.firstTestExpiryDate,
        kmYear: row.kmYear,
        mobilizationOrder: row.mobilizationOrder,
    });
};

changeButtonCancel = () => {
    this.setState({
        hidden: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenDataGiris: true,
        hiddenCreateNewButton:false,
        createNewButton: "Create New",
        createNewButtonVariant: "info", 
    });
    this.resetInputs();
};

updateDone = async () => {
    if (this.state.vehicleRegistrationId > 0) {

      var updateVehicleRegistration = {
        id: this.state.id,
        vehicleRegistrationId: this.state.id, 
        plateNo: this.state.plateNo,
        make: this.state.make,
        modelYear: this.state.modelYear,
        grade: this.state.grade,
        type: this.state.type,
        colour: this.state.colour,
        engineNo: this.state.engineNo,
        identificationNumber: this.state.identificationNumber,
        cylinderVolume: this.state.cylinderVolume,
        enginePowerHP: this.state.enginePowerHP,
        fuelType: this.state.fuelType,
        vehicleClass: this.state.vehicleClass,
        weight: this.state.weight,
        maxLoadCapacity: this.state.maxLoadCapacity,
        maxGrossWeight: this.state.maxGrossWeight,
        kerbWeight: this.state.kerbWeight,
        trailerMaxLoadedWeight: this.state.trailerMaxLoadedWeight,
        seatingCapacity: this.state.seatingCapacity,
        typeOfBody: this.state.typeOfBody,
        issuingProvinceCountry: this.state.issuingProvinceCountry,
        theDateOfUsageOnTraffic: this.state.theDateOfUsageOnTraffic,
        firstRegistrationDate: this.state.firstRegistrationDate,
        registrationSerialNo: this.state.registrationSerialNo,
        registrationDate: this.state.registrationDate,
        tradeName: this.state.tradeName,
        value: this.state.value,
        nameOfOwner: this.state.nameOfOwner,
        ownerIqamaNo: this.state.ownerIqamaNo,
        vehicleNo: this.state.vehicleNo,
        registrationNo: this.state.registrationNo,
        sequenceNo: this.state.sequenceNo,
        expiryDate: this.state.expiryDate,
        firstTestExpiryDate: this.state.firstTestExpiryDate,
        kmYear: this.state.kmYear,
        mobilizationOrder: this.state.mobilizationOrder,
      };

        var response = await handleRequest("POST", "/VehicleRegistration/update", updateVehicleRegistration);

      if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.createNewClientButton();
          this.resetInputs();
          this.showSuccess();
          this.restartTable();
        }
        else {
          this.showError();
        }
      }
      else {
        this.showWarning();
      }
    }
    else {
      this.showWarning();
    }
};

deleteVehicleRegistration = (rowData) => {
    this.setState({
      deleteModal: true,
      vehicleRegistrationId: rowData.id,
    });
};

deleteVehicleRegistrationOK = async () => {
    const deletedVehicleRegistration = {
        id: this.state.vehicleRegistrationId,
    };

var response = await handleRequest("POST", "/VehicleRegistration/delete", deletedVehicleRegistration);

if (response.data !== null && response.data !== undefined) {
    if (response.data === "SUCCESS") {
    this.setState({
        deleteModal: false,
    });
    this.restartTable();
    this.showSuccess();
    }
    else {
    this.showError();
    }
} else {
    this.showError();
}
setTimeout(() => {
    document.body.style.overflow = "auto";
}, 500);
};

handleChangeVRMake = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.VRMakes.length; z++) {
      if (this.state.VRMakes[z].id == e) {
        deger = this.state.VRMakes[z].name;
      }
    }
    this.setState({
      makeAck: deger,
      make: e,
    });
};

addVRMakes = async () => {
    const { itemVRMakes } = this.state;
    if (itemVRMakes != null && itemVRMakes != "" && itemVRMakes != undefined) {
        const newItem = {
        name: itemVRMakes,
        type: "VRMake"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemVRMakes: '',
        });
        this.getCodesVRMake();
    } else {
        this.showError();
    }
};

getCodesVRMake = async () => {
    const response = await handleRequest("GET", "/Code/type/VRMake"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        VRMakes: response.data
      });
    }
} 

handleChangeVRModelYear = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.VRModelYears.length; z++) {
      if (this.state.VRModelYears[z].id == e) {
        deger = this.state.VRModelYears[z].name;
      }
    }
    this.setState({
        modelYearAck: deger,
        modelYear: e,
    });
};

addVRModelYears = async () => {
    const { itemVRModelYears } = this.state;
    if (itemVRModelYears != null && itemVRModelYears != "" && itemVRModelYears != undefined) {
        const newItem = {
        name: itemVRModelYears,
        type: "VRModelYear"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemVRModelYears: '',
        });
        this.getCodesVRModelYear();
    } else {
        this.showError();
    }
};

getCodesVRModelYear = async () => {
    const response = await handleRequest("GET", "/Code/type/VRModelYear"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        VRModelYears: response.data
      });
    }
}
  
handleChangeVRGrade = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.VRGrades.length; z++) {
      if (this.state.VRGrades[z].id == e) {
        deger = this.state.VRGrades[z].name;
      }
    }
    this.setState({
      gradeAck: deger,
      grade: e,
    });
};

addVRGrades = async () => {
    const { itemVRGrades } = this.state;
    if (itemVRGrades != null && itemVRGrades != "" && itemVRGrades != undefined) {
        const newItem = {
        name: itemVRGrades,
        type: "VRGrade"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemVRGrades: '',
        });
        this.getCodesVRGrade();
    } else {
        this.showError();
    }
};

getCodesVRGrade = async () => {
    const response = await handleRequest("GET", "/Code/type/VRGrade"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        VRGrades: response.data
      });
    }
}
 
handleChangeVRType = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.VRTypes.length; z++) {
      if (this.state.VRTypes[z].id == e) {
        deger = this.state.VRTypes[z].name;
      }
    }
    this.setState({
        typeAck: deger,
        type: e,
    });
};

addVRTypes = async () => {
    const { itemVRTypes } = this.state;
    if (itemVRTypes != null && itemVRTypes != "" && itemVRTypes != undefined) {
        const newItem = {
        name: itemVRTypes,
        type: "VRType"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemVRTypes: '',
        });
        this.getCodesVRType();
    } else {
        this.showError();
    }
};

getCodesVRType = async () => {
    const response = await handleRequest("GET", "/Code/type/VRType"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        VRTypes: response.data
      });
    }
} 

handleChangeVRColour = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.VRColours.length; z++) {
      if (this.state.VRColours[z].id == e) {
        deger = this.state.VRColours[z].name;
      }
    }
    this.setState({
      colourAck: deger,
      colour: e,
    });
};

addVRColours = async () => {
    const { itemVRColours } = this.state;
    if (itemVRColours != null && itemVRColours != "" && itemVRColours != undefined) {
        const newItem = {
        name: itemVRColours,
        type: "VRColour"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemVRColours: '',
        });
        this.getCodesVRColour();
    } else {
        this.showError();
    }
};

getCodesVRColour = async () => {
    const response = await handleRequest("GET", "/Code/type/VRColour"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        VRColours: response.data
      });
    }
} 

handleChangeVRFuelType = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.VRFuelTypes.length; z++) {
      if (this.state.VRFuelTypes[z].id == e) {
        deger = this.state.VRFuelTypes[z].name;
      }
    }
    this.setState({
        fuelTypeAck: deger,
        fuelType: e,
    });
};

addVRFuelTypes = async () => {
    const { itemVRFuelTypes } = this.state;
    if (itemVRFuelTypes != null && itemVRFuelTypes != "" && itemVRFuelTypes != undefined) {
        const newItem = {
        name: itemVRFuelTypes,
        type: "VRFuelType"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemVRFuelTypes: '',
        });
        this.getCodesVRFuelType();
    } else {
        this.showError();
    }
};

getCodesVRFuelType = async () => {
    const response = await handleRequest("GET", "/Code/type/VRFuelType"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        VRFuelTypes: response.data
      });
    }
} 

handleTableChange = (pagination, filters, sorter) => {
    this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters, });
};

fetch = async (params = {}) => {
    this.setState({ 
        loading: true 
    });
    const newObj = {
        id: 0,
        firstResult: params.pagination.current,
        maxResults: params.pagination.pageSize,
    }

var response = await handleRequest("POST", "/VehicleRegistration/getVehicleRegistrationPaging", newObj);

console.log("AAA", response.data.data);

if (response.data.length !== 0) {
        this.setState({
            loading: false,
            VehicleInfos: response.data.data,
            pagination: {
                ...params.pagination,
                total: response.data.totalRecords,
            },
        });
    } else {
        this.setState({
            loading: false,
            VehicleInfos: [],
            pagination: {
                ...params.pagination,
                total: 0,
            },
        });
    }
};

createNewClientButton = async () => {
    await this.resetInputs();  

     if (this.state.createNewButton=="Create New") {
       this.setState({ 
        hidden: false,
        hiddenSave:false,
        hiddenUpdate:true,
        hiddenCreateNewButton:false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
       });
    }
    else {
       this.setState({ 
         hidden: true,
         hiddenSave:true,
         hiddenUpdate:false,
         hiddenCreateNewButton:false,
         createNewButton: "Create New",
         createNewButtonVariant: "info",
       });
    }
}

resetInputs = () => {
    this.setState({ 
        id: -1,
        vehicleRegistrationId: -1, 
        plateNo: "",
        make: 0,
        makeAck:"",
        modelYear: 0,
        modelYearAck: "",
        grade: 0,
        gradeAck: "",
        type: 0,
        typeAck: "",
        colour: 0,
        colourAck: "",
        engineNo: "",
        identificationNumber: "",
        cylinderVolume: "",
        enginePowerHP: "",
        fuelType: 0,
        fuelTypeAck: "",
        vehicleClass: "",
        weight: 0,
        maxLoadCapacity: 0,
        maxGrossWeight: 0,
        kerbWeight: 0,
        trailerMaxLoadedWeight: 0,
        seatingCapacity: 0,
        typeOfBody: 0,
        issuingProvinceCountry: "",
        theDateOfUsageOnTraffic: null,
        firstRegistrationDate: null,
        registrationSerialNo: "",
        registrationDate: null,
        tradeName: "",
        value: "",
        nameOfOwner: "",
        ownerIqamaNo: "",
        vehicleNo: "",
        registrationNo: "",
        sequenceNo: "",
        expiryDate: null,
        firstTestExpiryDate: "",
        kmYear: "",
        mobilizationOrder: "",
  });
}

restartTable = async () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

showSuccess(msg) {
    if (!msg) {
        msg = <FormattedMessage id="GeneralMessageSuccess1" />;
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Success"
    });
};

showError(msg) {
    if (!msg) {
        msg =  <FormattedMessage id="GeneralMessageError1" />;
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Error"
    });
};

showWarning(msg) {
    if (!msg) {
        msg = <FormattedMessage id="GeneralMessageCheckInputs" />;
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Warning"
    });
};

showOrHideModalMessage = () => {
    this.setState({
        modalMessage: !this.state.modalMessage,
    }); 
    setTimeout(() => {
        document.body.style.overflow = "auto";
    }, 500);
}

  showOrHideModalDeleteVehicleRegistration = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
    setTimeout(() => {
        document.body.style.overflow = "auto";
    }, 500);
};

  addVehicleRegistration = async () => {
      const newVehicleRegistration = {
        plateNo: this.state.plateNo,
        make: this.state.make,
        modelYear: this.state.modelYear,
        grade: this.state.grade,
        type: this.state.type,
        colour: this.state.colour,
        engineNo: this.state.engineNo,
        identificationNumber: this.state.identificationNumber,
        cylinderVolume: this.state.cylinderVolume,
        enginePowerHP: this.state.enginePowerHP,
        fuelType: this.state.fuelType,
        vehicleClass: this.state.vehicleClass,
        weight: this.state.weight,
        maxLoadCapacity: this.state.maxLoadCapacity,
        maxGrossWeight: this.state.maxGrossWeight,
        kerbWeight: this.state.kerbWeight,
        trailerMaxLoadedWeight: this.state.trailerMaxLoadedWeight,
        seatingCapacity: this.state.seatingCapacity,
        typeOfBody: this.state.typeOfBody,
        issuingProvinceCountry: this.state.issuingProvinceCountry,
        theDateOfUsageOnTraffic: this.state.theDateOfUsageOnTraffic,
        firstRegistrationDate: this.state.firstRegistrationDate,
        registrationSerialNo: this.state.registrationSerialNo,
        registrationDate: this.state.registrationDate,
        tradeName: this.state.tradeName,
        value: this.state.value,
        nameOfOwner: this.state.nameOfOwner,
        ownerIqamaNo: this.state.ownerIqamaNo,
        vehicleNo: this.state.vehicleNo,
        registrationNo: this.state.registrationNo,
        sequenceNo: this.state.sequenceNo,
        expiryDate: this.state.expiryDate,
        firstTestExpiryDate: this.state.firstTestExpiryDate,
        kmYear: this.state.kmYear,
        mobilizationOrder: this.state.mobilizationOrder,
      };

      var response = await handleRequest("POST", "/VehicleRegistration/add", newVehicleRegistration);

      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.resetInputs();
          this.showSuccess();
          this.restartTable();
        }
        else {
          this.showError();
        }
      } else {
        this.showError();
      }
}; 

render() {
 
    const columns = [  
        {
            key: "key",
            title: "",
            render: this.actionTemplate
        }, 
        {
            dataIndex: "index",
            key: "key",
            title: <FormattedMessage id="GeneralTableSN" />,
        }, 
        {
            dataIndex: "plateNo",
            key: "key",
            title: <FormattedMessage id="VRPlateNo" />,
        },
        {
            dataIndex: "codeMake",
            key: "key",
            title: <FormattedMessage id="VRMake" />,
            render: (data) =>   
               {  
                if(data!=null && data!=undefined){
                   return data.name;
                } 
                return "";
            }
        },
        {
            dataIndex: "codeModelYear",
            key: "key",
            title: <FormattedMessage id="VRModelYear" />,
            render: (data) =>   
            {  
             if(data!=null && data!=undefined){
                return data.name;
             } 
             return "";
            }
        },
        {
            dataIndex: "codeGrade",
            key: "key",
            title: <FormattedMessage id="VRGrade" />,
            render: (data) =>   
            {  
             if(data!=null && data!=undefined){
                return data.name;
             } 
             return "";
            }
        },
        {
            dataIndex: "codeType",
            key: "key",
            title: <FormattedMessage id="VRType" />,
            render: (data) =>   
            {  
             if(data!=null && data!=undefined){
                return data.name;
             } 
             return "";
            }
        },
        {
            dataIndex: "codeColour",
            key: "key",
            title: <FormattedMessage id="VRColour" />,
            render: (data) =>   
            {  
             if(data!=null && data!=undefined){
                return data.name;
             } 
             return "";
            }
        },
        {
            dataIndex: "engineNo",
            key: "key",
            title: <FormattedMessage id="VREngineNo" />,
        },  
        {
            dataIndex: "identificationNumber",
            key: "key",
            title: <FormattedMessage id="VRIdentificationNumber" />,
        },
        {
            dataIndex: "cylinderVolume",
            key: "key",
            title: <FormattedMessage id="VRCylinderVolume" />,
        },
        {
            dataIndex: "enginePowerHP",
            key: "key",
            title: <FormattedMessage id="VREnginePowerHP" />,
        },
        {
            dataIndex: "codeFuelType",
            key: "key",
            title: <FormattedMessage id="VRFuelType" />,
            render: (data) =>   
            {  
             if(data!=null && data!=undefined){
                return data.name;
             } 
             return "";
            }
        },
        {
            dataIndex: "vehicleClass",
            key: "key",
            title: <FormattedMessage id="VRVehicleClass" />,
        },
        {
            dataIndex: "weight",
            key: "key",
            title: <FormattedMessage id="VRWeight" />,
        },
        {
            dataIndex: "maxLoadCapacity",
            key: "key",
            title: <FormattedMessage id="VRMaxLoadCapacity" />,
        },
        {
            dataIndex: "maxGrossWeight",
            key: "key",
            title: <FormattedMessage id="VRMaxGrossWeight" />,
        },
        {
            dataIndex: "kerbWeight",
            key: "key",
            title: <FormattedMessage id="VRKerbWeight" />,
        },
        {
            dataIndex: "trailerMaxLoadedWeight",
            key: "key",
            title: <FormattedMessage id="VRTrailerMaxLoadedWeight" />,
        },
        {
            dataIndex: "seatingCapacity",
            key: "key",
            title: <FormattedMessage id="VRSeatingCapacity" />,
        },
        {
            dataIndex: "typeOfBody",
            key: "key",
            title: <FormattedMessage id="VRTypeOfBody" />,
        },
        {
            dataIndex: "issuingProvinceCountry",
            key: "key",
            title: <FormattedMessage id="VRIssuingProvinceCountry" />,
        },
        {
            dataIndex: "theDateOfUsageOnTraffic",
            key: "key",
            title: <FormattedMessage id="VRTheDateOfUsageOnTraffic" />,
            render: (record) =>  
            {
                var _date = '';
                if (record !== null && record !== undefined) { 
                    _date = moment(record).format("DD/MM/YYYY");
                    return _date;
                } 
                return _date; 
            }
        },
        {
            dataIndex: "firstRegistrationDate",
            key: "key",
            title: <FormattedMessage id="VRFirstRegistrationDate" />,
            render: (record) =>  
            {
                var _date = '';
                if (record !== null && record !== undefined) { 
                    _date = moment(record).format("DD/MM/YYYY");
                    return _date;
                } 
                return _date; 
            }
        },
        {
            dataIndex: "registrationSerialNo",
            key: "key",
            title: <FormattedMessage id="VRRegistrationSerialNo" />,
        },
        {
            dataIndex: "registrationDate",
            key: "key",
            title: <FormattedMessage id="VRRegistrationDate" />,
            render: (record) =>  
            {
                var _date = '';
                if (record !== null && record !== undefined) { 
                    _date = moment(record).format("DD/MM/YYYY");
                    return _date;
                } 
                return _date; 
            }
        },
        {
            dataIndex: "tradeName",
            key: "key",
            title: <FormattedMessage id="VRTradeName" />,
        },
        {
            dataIndex: "value",
            key: "key",
            title: <FormattedMessage id="VRValue" />,
        },
        {
            dataIndex: "nameOfOwner",
            key: "key",
            title: <FormattedMessage id="VRNameOfOwner" />,
        },
        {
            dataIndex: "ownerIqamaNo",
            key: "key",
            title: <FormattedMessage id="VROwnerIqamaNo" />,
        },
        {
            dataIndex: "vehicleNo",
            key: "key",
            title: <FormattedMessage id="VRVehicleNo" />,
        },
        {
            dataIndex: "registrationNo",
            key: "key",
            title: <FormattedMessage id="VRRegistrationNo" />,
        },
        {
            dataIndex: "sequenceNo",
            key: "key",
            title: <FormattedMessage id="VRSequenceNo" />,
        },
        {
            dataIndex: "expiryDate",
            key: "key",
            title: <FormattedMessage id="VRExpiryDate" />,
            render: (record) =>  
            {
                var _date = '';
                if (record !== null && record !== undefined) { 
                    _date = moment(record).format("DD/MM/YYYY");
                    return _date;
                } 
                return _date; 
            }
        },
        {
            dataIndex: "firstTestExpiryDate",
            key: "key",
            title: <FormattedMessage id="VRFirstTestExpiryDate" />, 
        },
        {
            dataIndex: "kmYear",
            key: "key",
            title: <FormattedMessage id="VRKmYear" />,
        },
        {
            dataIndex: "mobilizationOrder",
            key: "key",
            title: <FormattedMessage id="VRMobilizationOrder" />,
        },
    ];

    return (
        <div>
            <Modal
            show={this.state.deleteModal}
            onHide={this.showOrHideModalDeleteVehicleRegistration}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="GeneralButtonDelete" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ textAlign: "center", color: "black" }}>
                    <FormattedMessage id="DeleteMessage1" />
                </p>
                <p style={{ textAlign: "center", color: "black" }}>
                <FormattedMessage id="DeleteMessage2" />
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                variant="secondary"
                onClick={this.showOrHideModalDeleteVehicleRegistration}
                >
                    <FormattedMessage id="GeneralTextClose" />
                </Button>
                <Button variant="danger" onClick={this.deleteVehicleRegistrationOK}>
                    <FormattedMessage id="GeneralButtonDelete" />
                </Button>
            </Modal.Footer>
        </Modal>

            <Modal
            show={this.state.modalMessage}
            onHide={this.showOrHideModalMessage}
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <Row > 
                    <Col sm="12">
                    {this.state.messageTip=="Success" &&  
                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                    
                    {this.state.messageTip=="Warning" &&  
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                    {this.state.messageTip=="Error" &&  
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                    </Col>
                </Row>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row > 
                <Col sm="12">
                    {this.state.message} 
                </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></Button>
            </Modal.Footer>
            </Modal>

            <Row>
            <Col xs={9}></Col>
            <Col xs={3}>
                <Button hidden={this.state.hiddenCreateNewButton} onClick={this.createNewClientButton} style={{ width: '100%' }} 
                variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
            </Col>
            </Row>
            <br/>
            <br/>
            <div hidden={this.state.hidden} className="col-md-12"> 
               <Collapse defaultActiveKey={['1']}  
                    expandIconPosition={this.state.expandIconPosition}>

                    <Panel style={{ fontWeight: 'bold' }}  header={<FormattedMessage id="VehicleInfo" />} key="1"  >
                        <div>
                            <Form>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRPlateNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.plateNo}  
                                        onChange={(e) =>
                                        this.setState({
                                        plateNo: e.target.value,
                                      }) } />
                                    </Col>
                                </Form.Group>
    
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRMake" /> </Form.Label>
                                    <Col sm="4">
                                        <Select
                                            name="make"
                                            style={{ width: '100%' }}
                                            value={this.state.makeAck}
                                            onChange={this.handleChangeVRMake}

                                            dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemVRMakes}
                                                    onChange={(event) => { this.setState({ itemVRMakes: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addVRMakes} >
                                                    <PlusOutlined /> Add item  </a>
                                                </div>
                                            </div>
                                            )}>
                                            {this.state.VRMakes.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                            </Select>
                                    </Col>
                                </Form.Group>
                            
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRModelYear" /> </Form.Label>
                                    <Col sm="4">
                                        <Select
                                            name="modelYear"
                                            style={{ width: '100%' }}
                                            value={this.state.modelYearAck}
                                            onChange={this.handleChangeVRModelYear}

                                            dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemVRModelYears}
                                                    onChange={(event) => { this.setState({ itemVRModelYears: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addVRModelYears} >
                                                    <PlusOutlined /> Add item  </a>
                                                </div>
                                            </div>
                                            )}>
                                            {this.state.VRModelYears.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Form.Group>
                            
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRGrade" /> </Form.Label>
                                    <Col sm="4">
                                        <Select
                                            name="make"
                                            style={{ width: '100%' }}
                                            value={this.state.gradeAck}
                                            onChange={this.handleChangeVRGrade}

                                            dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemVRGrades}
                                                    onChange={(event) => { this.setState({ itemVRGrades: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addVRGrades} >
                                                    <PlusOutlined /> Add item  </a>
                                                </div>
                                            </div>
                                            )}>
                                            {this.state.VRGrades.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                            </Select>
                                    </Col>
                                </Form.Group>
                            
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRType" /> </Form.Label>
                                    <Col sm="4">
                                        <Select
                                            name="make"
                                            style={{ width: '100%' }}
                                            value={this.state.typeAck}
                                            onChange={this.handleChangeVRType}

                                            dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemVRTypes}
                                                    onChange={(event) => { this.setState({ itemVRTypes: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addVRTypes} >
                                                    <PlusOutlined /> Add item  </a>
                                                </div>
                                            </div>
                                            )}>
                                            {this.state.VRTypes.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                            </Select>
                                    </Col>
                                </Form.Group>
                            
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRColour" /> </Form.Label>
                                    <Col sm="4">
                                        <Select
                                            name="make"
                                            style={{ width: '100%' }}
                                            value={this.state.colourAck}
                                            onChange={this.handleChangeVRColour}

                                            dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemVRColours}
                                                    onChange={(event) => { this.setState({ itemVRColours: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addVRColours} >
                                                    <PlusOutlined /> Add item  </a>
                                                </div>
                                            </div>
                                            )}>
                                            {this.state.VRColours.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                            </Select>
                                    </Col>
                                </Form.Group>
                            
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VREngineNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.engineNo} 
                                           onChange={(e) =>
                                            this.setState({
                                            engineNo: e.target.value,
                                          }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRIdentificationNumber" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.identificationNumber}
                                        onChange={(e) =>
                                            this.setState({
                                            identificationNumber: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRCylinderVolume" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.cylinderVolume} 
                                        onChange={(e) =>
                                        this.setState({
                                        cylinderVolume: e.target.value,
                                    }) } />
                                    </Col>
                                </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VREnginePowerHP" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.enginePowerHP}
                                        onChange={(e) =>
                                            this.setState({
                                            enginePowerHP: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRFuelType" /> </Form.Label>
                                    <Col sm="4">
                                        <Select
                                            name="make"
                                            style={{ width: '100%' }}
                                            value={this.state.fuelTypeAck}
                                            onChange={this.handleChangeVRFuelType}

                                            dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemVRFuelTypes}
                                                    onChange={(event) => { this.setState({ itemVRFuelTypes: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addVRFuelTypes} >
                                                    <PlusOutlined /> Add item  </a>
                                                </div>
                                            </div>
                                            )}>
                                            {this.state.VRFuelTypes.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                            </Select>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRVehicleClass" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.vehicleClass} 
                                        onChange={(e) =>
                                        this.setState({
                                            vehicleClass: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>
                            
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRWeight" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ weight: e })} value={this.state.weight} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRMaxLoadCapacity" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ maxLoadCapacity: e })} value={this.state.maxLoadCapacity} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRMaxGrossWeight" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ maxGrossWeight: e })} value={this.state.maxGrossWeight} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRKerbWeight" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ kerbWeight: e })} value={this.state.kerbWeight} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRTrailerMaxLoadedWeight" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ trailerMaxLoadedWeight: e })} value={this.state.trailerMaxLoadedWeight} />
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRSeatingCapacity" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ seatingCapacity: e })} value={this.state.seatingCapacity} />
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRTypeOfBody" /> </Form.Label>
                                    <Col sm="4">
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ typeOfBody: e })} value={this.state.typeOfBody} />
                                    </Col>
                                </Form.Group> 
                            </Form>
                        </div>
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="VRRegistrationInfo" />} key="2"  >
                        <div>
                            <Form>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRIssuingProvinceCountry" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.issuingProvinceCountry} 
                                        onChange={(e) =>
                                        this.setState({
                                            issuingProvinceCountry: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRTheDateOfUsageOnTraffic" /> </Form.Label>
                                    <Col sm="4">
                                        <Calendar style={{ width: '100%' }}  dateFormat="dd/mm/yy" value={this.state.theDateOfUsageOnTraffic} onChange={(e) => this.setState({ theDateOfUsageOnTraffic: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1000:3000"/>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRFirstRegistrationDate" /> </Form.Label>
                                    <Col sm="4">
                                        <Calendar style={{ width: '100%' }}  dateFormat="dd/mm/yy" value={this.state.firstRegistrationDate} onChange={(e) => this.setState({ firstRegistrationDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1000:3000"/>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRRegistrationSerialNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.registrationSerialNo} 
                                        onChange={(e) =>
                                        this.setState({
                                            registrationSerialNo: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRRegistrationDate" /> </Form.Label>
                                    <Col sm="4">
                                        <Calendar style={{ width: '100%' }}  dateFormat="dd/mm/yy" value={this.state.registrationDate} onChange={(e) => this.setState({ registrationDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1000:3000"/>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRTradeName" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.tradeName} 
                                        onChange={(e) =>
                                        this.setState({
                                            tradeName: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRValue" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.value} 
                                        onChange={(e) =>
                                        this.setState({
                                            value: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRNameOfOwner" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.nameOfOwner} 
                                        onChange={(e) =>
                                        this.setState({
                                            nameOfOwner: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VROwnerIqamaNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.ownerIqamaNo} 
                                        onChange={(e) =>
                                        this.setState({
                                            ownerIqamaNo: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRVehicleNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.vehicleNo}
                                        onChange={(e) =>
                                        this.setState({
                                            vehicleNo: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRRegistrationNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.registrationNo} 
                                        onChange={(e) =>
                                        this.setState({
                                            registrationNo: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRSequenceNo" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.sequenceNo} 
                                        onChange={(e) =>
                                        this.setState({
                                            sequenceNo: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRExpiryDate" /> </Form.Label>
                                    <Col sm="4">
                                        <Calendar style={{ width: '100%' }}  dateFormat="dd/mm/yy" value={this.state.expiryDate} onChange={(e) => this.setState({ expiryDate: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1000:3000"/>
                                    </Col>
                                </Form.Group>

                            </Form>
                        </div>
                    </Panel>
                    
                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="VROthers" />} key="3"  >
                        <div>
                            <Form>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRFirstTestExpiryDate" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.firstTestExpiryDate} 
                                        onChange={(e) =>
                                        this.setState({
                                            firstTestExpiryDate: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRKmYear" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.kmYear} 
                                        onChange={(e) =>
                                        this.setState({
                                            kmYear: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">  <FormattedMessage id="VRMobilizationOrder" /> </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} value={this.state.mobilizationOrder} 
                                        onChange={(e) =>
                                        this.setState({
                                            mobilizationOrder: e.target.value,
                                        }) } />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </Panel>
                </Collapse>

                <br />
                <div hidden={this.state.hiddenSave}>
                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                        <Row>
                            <Button onClick={this.addVehicleRegistration} style={{ width: '100%' }} variant="success"><FormattedMessage id="GeneralButtonSave"/></Button>
                        </Row>
                    </Container>
                </div>

                <div hidden={this.state.hiddenUpdate}>
                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col sm="3">
                        </Col>
                        <Col sm="2">
                        <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel"/></Button>
                        </Col>
                        <Col sm="3">
                        <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate"/></Button>
                        </Col>
                        <Col sm="3">
                        </Col>
                    </Row>
                    </Container>
                </div>   

            </div>
            <br />
            <div className="datatable-doc-demo">
                <Card>
                    <div className="content-section implementation">
                    <Table columns={columns} dataSource={this.state.VehicleInfos} pagination={this.state.pagination}
                        scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
                        responsive striped bordered hover
                        loading={this.state.loading} onChange={this.handleTableChange} 
                    />
                    </div>
                </Card>
            </div>
        </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}
  
const mapDispatchToProps = {

}
  
export default connect(mapStateToProps, mapDispatchToProps)(VehicleRegistration)
