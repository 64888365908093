
import React, { useContext, useState, useEffect, useRef, Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import { PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';

const EditableContext = React.createContext(null);
const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;

const { Option } = Select;
const pageName = "DocumentSubmittal";

export class DocumentSubmittal1 extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList1: [],
            tableList2: [],
            
            filePath: '',
            fileList: [],
            //[STATE_FIELDS],
            userSelectItems: [],

            department: null,
            designation: null,
            phone: null,
            email: null,
            columns1: [],
            columns2: [], 
            date: moment(),


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();

    }


    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/Staff/getallByCompany");
        if (resDepList.data.length > 0) {

            this.setState({
                userSelectItems: resDepList.data
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }



    }
    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            staffId: this.state.staffId,
            plannedAssignmentDate: this.state.plannedAssignmentDate,
            transmittalNo: this.state.transmittalNo,
            assignmentDate: this.state.assignmentDate,
            plannedReleaseDate: this.state.plannedReleaseDate,
            releaseDate: this.state.releaseDate,
            plannedVocationDate: this.state.plannedVocationDate,
            vacationDate: this.state.vacationDate,
            vacationDuration: this.state.vacationDuration,
            vacationReturnDate: this.state.vacationReturnDate,
            approvalStatus: this.state.approvalStatus,
            approvalDuration: this.state.approvalDuration,
            approvalType: this.state.approvalType,
        };//SAVE_OBJECT kodlandığında kaldırılacak
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ManpowerLog/add", obj);
        else
            await handleRequest("POST", "/ManpowerLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {
        let item = this.state.userSelectItems.find(p => p.id === row.staffId);
        if (Boolean(item)) {
            this.formRef.current.setFieldsValue({

                department: item.department.departmentName,
                designation: item.discipline.designation,
                phone: item.residencePersonelPhone,
                email: item.residenceCompanyEmail,
            });
            this.setState({
                department: item.department.departmentName,
                designation: item.discipline.designation,
                phone: item.residencePersonelPhone,
                email: item.residenceCompanyEmail,
            })
        }


        this.formRef.current.setFieldsValue({
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: row.staffId,
            plannedAssignmentDate: Boolean(row.plannedAssignmentDate) ? moment(row.plannedAssignmentDate) : null,
            transmittalNo: row.transmittalNo,
            assignmentDate: Boolean(row.assignmentDate) ? moment(row.assignmentDate) : null,
            plannedReleaseDate: Boolean(row.plannedReleaseDate) ? moment(row.plannedReleaseDate) : null,
            releaseDate: Boolean(row.releaseDate) ? moment(row.releaseDate) : null,
            plannedVocationDate: Boolean(row.plannedVocationDate) ? moment(row.plannedVocationDate) : null,
            vacationDate: Boolean(row.vacationDate) ? moment(row.vacationDate) : null,
            vacationDuration: row.vacationDuration,
            vacationReturnDate: Boolean(row.vacationReturnDate) ? moment(row.vacationReturnDate) : null,
            approvalStatus: row.approvalStatus,
            approvalDuration: row.approvalDuration,
            approvalType: row.approvalType,
        });

        this.setState({
            //  [EDIT_STATE_FIELDS], 
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            department: null,
            designation: null,
            phone: null,
            email: null,

            id: row.id,
            staffId: row.staffId,
            plannedAssignmentDate: Boolean(row.plannedAssignmentDate) ? moment(row.plannedAssignmentDate) : null,
            transmittalNo: row.transmittalNo,
            assignmentDate: Boolean(row.assignmentDate) ? moment(row.assignmentDate) : null,
            plannedReleaseDate: Boolean(row.plannedReleaseDate) ? moment(row.plannedReleaseDate) : null,
            releaseDate: Boolean(row.releaseDate) ? moment(row.releaseDate) : null,
            plannedVocationDate: Boolean(row.plannedVocationDate) ? moment(row.plannedVocationDate) : null,
            vacationDate: Boolean(row.vacationDate) ? moment(row.vacationDate) : null,
            vacationDuration: row.vacationDuration,
            vacationReturnDate: Boolean(row.vacationReturnDate) ? moment(row.vacationReturnDate) : null,
            approvalStatus: row.approvalStatus,
            approvalDuration: row.approvalDuration,
            approvalType: row.approvalType,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ManpowerLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {


        let data1 = [];
        let data2 = [];
        

        let obj = {
            inspectionDate: this.state.date
        }

        let columns1 = [

            {
                title: '', key: 'title', align: "center", render: (record) => {
                    if (Boolean(record.tag))
                        return (
                            <Tag color="volcano" style={{ fontSize: '16pt' }} key={record.tag}>
                                {record.tag.toUpperCase()}
                            </Tag>
                        );
                    else
                        return <div style={{ fontWeight: 800 }}>{record.approvalStatus}</div>;
                }
            }

        ];

        let columns2 = [

            {
                title: '', key: 'title', align: "center", render: (record) => {
                    if (Boolean(record.tag))
                        return (
                            <Tag color="volcano" style={{ fontSize: '16pt' }} key={record.tag}>
                                {record.tag.toUpperCase()}
                            </Tag>
                        );
                    else
                        return <div style={{ fontWeight: 800 }}>{record.approvalStatus}</div>;
                }
            }

        ];

      

        const response = await handleRequest("POST", "/SpecialProcess/summary", obj);
        const response2 = await handleRequest("POST", "/QualityItp/summary", obj);

            const list1 = response.data;
            const list2 = response2.data;
            console.log(list1);

            if (Boolean(list1)) {
                list1.forEach(element => {

                    if (data1.length > 0) {
                        let index = data1.findIndex(p => p.approvalStatus === element.approvalStatus);
                        if (index === -1) {
                            data1.push({
                                key: element.approvalStatus,
                                approvalStatus: element.approvalStatus,
                               
                             
                            })
                        }
                    }
                    else data1.push({
                        key: element.approvalStatus,                     
                        approvalStatus: element.approvalStatus, 
                       
                    });
                });

                data1.forEach(element => {
                     let disciplines = list1.filter(p=>p.approvalStatus===element.approvalStatus);
                     let total = 0 ;
                     disciplines.forEach(d => {
                         let attribute =  d.disciplineId+"";
                         element[attribute]=d.issued; 
                         total+=total+d.issued; 
                         let col = {
                            title: d.disciplineName, key: attribute, align: "center", dataIndex: attribute, render: (value, row, index) => {
                                return value;
                            }
                        }    
                        if(columns1.findIndex(p=>p.key===attribute)===-1)
                        columns1.push(col);
                     });
                     element["total"]=total; 
                    
                });

                let col = {
                    title: "Total", key: "total", align: "center", dataIndex: "total", render: (value, row, index) => {
                        return value;
                    }
                }        
                columns1.push(col);
            }

            if (Boolean(list2)) {
                list2.forEach(element => {

                    if (data2.length > 0) {
                        let index = data2.findIndex(p => p.approvalStatus === element.approvalStatus);
                        if (index === -1) {
                            data2.push({
                                key: element.approvalStatus,
                                approvalStatus: element.approvalStatus,
                               
                             
                            })
                        }
                    }
                    else data2.push({
                        key: element.approvalStatus,                     
                        approvalStatus: element.approvalStatus, 
                       
                    });
                });

                data2.forEach(element => {
                     let disciplines = list2.filter(p=>p.approvalStatus===element.approvalStatus);
                     let total = 0 ;
                     disciplines.forEach(d => {
                         let attribute =  d.disciplineId+"";
                         element[attribute]=d.issued; 
                         total+=total+d.issued; 
                         let col = {
                            title: d.disciplineName, key: attribute, align: "center", dataIndex: attribute, render: (value, row, index) => {
                                return value;
                            }
                        }    
                        if(columns2.findIndex(p=>p.key===attribute)===-1)
                        columns2.push(col);
                     });
                     element["total"]=total; 
                    
                });

                let col = {
                    title: "Total", key: "total", align: "center", dataIndex: "total", render: (value, row, index) => {
                        return value;
                    }
                }        
                columns2.push(col); 
            }

            this.setState({
                tableList1: data1,
                tableList2: data2,           
                columns1: columns1,
                columns2:columns2  
            });
       
    }

    calc = (list) => {

        let total = { designationName: 'Total', tag: "Total", required: 0, mobilized: 0, onVacation: 0, deviation: 0 };
        list.forEach(element => {
            if (element.tag) {
                element.required = total.required;
                element.mobilized = total.mobilized;
                element.onVacation = total.onVacation;
                element.deviation = total.deviation;
            }
            else {
                element.deviation = element.required - element.mobilized + element.onVacation;
                total.required += element.required;
                total.mobilized += element.mobilized;
                total.onVacation += element.onVacation;
                total.deviation += element.deviation;
            }

        });

        console.log(total);

    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,
        });

        this.setState({
            // [RESETINPUTS_STATES],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,

        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleSave = (row) => {
        const newData = [...this.state.tableList];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        this.calc(newData);
        this.setState({
            tableList: newData,
        });
    };


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>



                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {<FormAnt.Item
                                            {...layout}

                                            label={<FormattedMessage id="Manpower.date" defaultMessage="Date" />}

                                            style={{
                                                marginBottom: 0,
                                            }}
                                            name="date"
                                        >
                                            <RowAnt gutter={[8, 8]}>
                                                <ColAnt span={12}>
                                                    <DatePicker id="ManpowerLog.pad" format="DD-MM-YYYY" value={this.state.date} onChange={(date, dateString) => {
                                                        this.formRef.current.setFieldsValue({
                                                            date: date
                                                        });
                                                        this.setState({
                                                            date: date
                                                        }, this.restartTable)
                                                    }} />
                                                </ColAnt>
                                            </RowAnt>

                                        </FormAnt.Item>}

                                    </FormAnt>
                                </div>
                           
                                {this.state.tableList1.length > 0 && <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                    {"Special Process"}
                                </Form.Label>}
                                {this.state.tableList1.length > 0 && <Table id={"Table" + this.props.id} style={{ marginTop: '3rem' }} size={`small`}
                                    columns={this.state.columns1}
                                    dataSource={this.state.tableList1}
                                    bordered   >
                                </Table>}

                                
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSubmittal1)
