/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { Form, Col, Row } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Modal, Button as BootButton } from 'react-bootstrap';
import { Tree as Tree2 } from 'antd';
import { Select } from 'antd';

const { Option } = Select;

const ActionsList = require('./JsonDatas/Actions.json');
const FormsList = require('./JsonDatas/PageNames.json');

export class Authorization extends Component {

    constructor() {
        super();

        var formsFlag = [];
        formsFlag = FormsList;

        formsFlag.sort(function (a, b) {
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
                return 1;
            }
            if (b.name.toUpperCase() > a.name.toUpperCase()) {
                return -1;
            }
            return 0;
        });

        { /* formsFlag.sort(function (a, b) {
            return a.name.toUpperCase() > b.name.toUpperCase();
        });*/}
        var newArray = [{ name: "All", items: [] }];

        formsFlag.forEach(element => {
            newArray.push(element);
        });



        this.state = {
            modalShow: false,
            departmentName: "",
            departmentId: null,
            departments: [],

            disciplineName: "",
            disciplineId: null,
            disciplines: [],

            roleGroupName: "",
            roleGroupId: null,
            roleGroups: [],

            designationName: "",
            designationId: null,
            designations: [],

            userName: "Select User",
            userId: 0,
            users: [],

            actionNames: [],
            actionKeys: [],
            actions: [],

            formNames: [],
            formId: "",
            forms: newArray,

            selectedRow: {},
            authorizationList: [],

            selectedNodeKeys: [],
            checkedKeys: [],
            halfCheckedKeys: [],
            nodes: [],
            userKeys: [],
            menuList: [],
            userList: [],
            allUsers: [],
            defaultExpandedKeys: ["All"],
            checkedNodes: []

        };

        console.log("constructure", this.state.forms);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount = async () => {


        //const response = await axios.get(`${API_BASE}/Department/getAll`,config);
        try {
            this.setState({
                menuList: this.props.auth.user.authList.filter(p => p.action === "view")
            });

        } catch (error) {

        }


        const response = await handleRequest("GET", "/Department/getallByCompany");


        if (response.data.length === 0) {
            this.setState({
                departments: []
            });
        }

        if (response.data.length !== 0) {


            this.setState({
                departments: response.data,
            });

        }

        this.getDisciplines();
        this.getRoleGoups();
        this.getDesignations();
        this.getUsers();

        const responseTreeDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTreeDynamicMenu");
        if (responseTreeDynamicMenu.data.length !== 0) {
            this.setState({
                nodes: responseTreeDynamicMenu.data
            });
        } else {
            this.setState({
                nodes: []
            });
        }

        console.log(responseTreeDynamicMenu.data);


        //   this.restartTable();
    }

    handleUserChange2 = (value) => {

        this.setState({
            userKeys: value,
        }, this.setCheckedNodes);
    };

    setCheckedNodes = () => {

        var list = [];
        var nodes = [];
        var half = [];
        const userKeys = this.state.userKeys;
        console.log(userKeys);

        if (userKeys.length > 0) {

            for (let i = 0; i < userKeys.length; i++) {

                var index = this.state.allUsers.findIndex(p => p.id === userKeys[i]);
                console.log(i);
                let userList = [];
                var user = this.state.allUsers[index];
                if (Boolean(user.authList) && user.authList.length > 0) {
                    user.authList.forEach(element => {
                        if (element.action === "view") {
                            if (i === 0)
                            { 
                            if((Boolean(element.dynamicMenu) && Boolean(element.dynamicMenu.childDynamicActions) && element.dynamicMenu.childDynamicActions.length>0) ||
                            (Boolean(element.dynamicMenu) && Boolean(element.dynamicMenu.childDynamicMenus) && element.dynamicMenu.childDynamicMenus.length>0))    {

                                half.push(element.menuId + "MENU");

                            }else {
                                list.push(element.menuId + "MENU");
                            }
                            // 
                             
                            
                            }
                            else {
                                userList.push(element.menuId + "MENU");
                            }

                        }
                        else if (element.actionId > 0) {
                            if (i === 0) {
                                list.push(element.actionId + "ACT");
                                let obj = { key: element.actionId + "ACT", menuId: element.menuId }
                                nodes.push(obj);
                            }
                            else userList.push(element.actionId + "ACT");
                        }
                    });
                    if (i > 0) {
                        list.forEach((element, i) => {
                            let k = userList.findIndex(p => p === element);
                            if (k === -1) {
                                list[i] = "";
                                let kNode = nodes.findIndex(p => p.key === element);
                                if (kNode >= 0)
                                    nodes.splice(kNode, 1);
                            }
                        });

                        half.forEach((element, i) => {
                            let k = userList.findIndex(p => p === element);
                            if (k === -1) {
                                half[i] = "";
                              
                            }
                        });

                    }

                } else {
                    list = [];
                    nodes = [];
                    half = []; 
                    break;
                }
            }

        }

        console.log(half); 
        this.setState({
            checkedKeys: list.length > 0 ? list.filter(p => p != "") : [],
            halfCheckedKeys: half.length>0 ?  half.filter(p => p != "") : [],
            checkedNodes: nodes
        });

    }



    handleFormChange = e => {

        console.log(e.value);
        if (e.value.includes("All")) {
            this.setState({
                formNames: ["All"]
            });
            return;
        }

        this.setState({
            formNames: e.value
        });

    };



    actionTemplate2(rowData, column) {
        return <React.Fragment>
            <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>
            <Dialog header="Delete This  Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
                <p>This  record will be deleted. Are you sure?</p>
            </Dialog>

        </React.Fragment>;
    };

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true
        });
    };

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    };


    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteAuthorization(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    deleteAuthorization = async (name) => {

        const deletedItem = {
            id: this.state.selectedRow.id
        }

        // await axios.post(`${API_BASE}/Authorization/delete`, deletedItem,config);
        await handleRequest("POST", "/Authorization/delete", deletedItem);

        //  this.restartTable();
        this.showSuccess();
        this.onHideModal(name);
    }

    restartTable = async () => {

        //const response = await axios.get(`${API_BASE}/Authorization/getAll`,config);

        const response = await handleRequest("GET", "/Authorization/getCompanyItems");

        if (response.data.length === 0) {
            this.setState({
                authorizationList: []

            });
        }

        if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {
                var element = response.data[index];
                var findedItem = ActionsList.find(p => p.key === response.data[index].action);
                if (findedItem !== undefined) {
                    element.actionDesc = findedItem.value;
                }
                else element.actionDesc = response.data[index].action;

                response.data[index] = element;
            }
            this.setState({
                authorizationList: response.data,
            });


        }
    }

    saveAuthorization = async () => {
        if (this.state.checkedKeys.length > 0 && Boolean(this.state.userKeys) && this.state.userKeys.length > 0) {
            var list = [];

            this.state.checkedKeys.forEach(f => {
                this.state.userKeys.forEach(userId => {
                    if (f.includes('MENU')) {
                        if (f !== 'All' && list.findIndex(p => p.menuId == f.split('MENU')[0] && p.action === 'view' && p.userId === userId) === -1) {
                            const newItem = {
                                menuId: f.split('MENU')[0],
                                actionId: 0,
                                action: 'view',
                                userId: userId

                            };
                            list.push(newItem);

                        }
                    }
                    else if (f.includes('ACT')) {
                        if (f !== 'All' && list.findIndex(p => p.actionId == f.split('ACT')[0] && p.userId === userId) === -1) {

                            let parentMenu = this.state.checkedNodes.find(p => p.key == f);
                            console.log(parentMenu);
                            const newItem = {
                                actionId: f.split('ACT')[0],
                                menuId: parentMenu.menuId,
                                userId: userId,
                                action: '',

                            };
                            list.push(newItem);
                        }
                    }

                });



            });
            if (this.state.halfCheckedKeys !== null && this.state.halfCheckedKeys.length > 0)
                this.state.halfCheckedKeys.forEach(f => {

                    this.state.userKeys.forEach(userId => {
                        if (f.includes('MENU')) {
                            if (f !== 'All' && list.findIndex(p => p.menuId == f.split('MENU')[0] && p.action === 'view' && p.userId === userId) === -1) {
                                const newItem = {
                                    menuId: f.split('MENU')[0],
                                    action: 'view',
                                    actionId: 0,
                                    userId: userId

                                };
                                list.push(newItem);

                            }
                        }
                    });


                });

            //await axios.post(`${API_BASE}/Authorization/add`, newItem,config);  
            // await handleRequest("POST", "/Authorization/add",newItem);    
            var r = await handleRequest("POST", "/Authorization/saveAll", list);
            if (r.data === "ERROR-002") {
                this.showError("This record added before! Please change your selections.");
            }
            else {
                if (r.data === "ERROR-001") {
                    this.showError("There is no value for save !");
                } else {
                    // this.restartTable();
                    this.showSuccess();
                    this.getUsers();
                }

            }

        } else {
            this.showError();
        }
    };

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };

    handleSelectionChange = (e) => {
        console.log(e);
        console.log(e.value);

        this.setState({ selectedNodeKeys: e.value })
    }

    onCheck = (checkedKeys, e) => {
        console.log('onCheck', checkedKeys);
        console.log('halfCheckedKeys', e.halfCheckedKeys);
        console.log(e);
        this.setState({
            checkedKeys: checkedKeys,
            halfCheckedKeys: e.halfCheckedKeys,
            checkedNodes: e.checkedNodes
        });


        var uIds = [];

        console.log(this.state.userList);

        /*  this.state.userList.forEach(user => {
              var size = 0;
              checkedKeys.forEach(ck => {
                  if (user.authList !== null && user.authList !== undefined && user.authList.length > 0) {
                      if (ck.includes('MENU')) {
  
                          if (user.authList.findIndex(p => p.menuId == ck.split('MENU')[0]) >= 0)
                              size++;
                      }
                      else
                          if (ck.includes('ACT')) {
  
                              if (user.authList.findIndex(p => p.actionId == ck.split('ACT')[0]) >= 0)
                                  size++;
                          }
  
                  }
  
  
              });
  
              if (checkedKeys.findIndex(p => p === "All") >= 0) {
                  size++;
              }
  
              if (size > 0 && size === checkedKeys.length) {
                  uIds.push(user.id.toString())
              }
  
          });
  
          this.setState({
              userKeys: uIds
          });
          */


    };

    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} />   </React.Fragment>;
    };

    deleteModal = (row) => {

        this.setState({
            modalShow: true,
            selectedRow: row
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }


    refreshDisciplineDesignationRoleGroups = () => {

        const departmentId = this.state.departmentId;
        if (Boolean(departmentId)) {
            let selectedDepartment = this.state.departments.find(p => p.id === departmentId);
            let disciplines = [];
            let designations = [];
            let roleGroups = [];
            let userList = [];
            let userKeys = [];
            if (Boolean(selectedDepartment) && Boolean(selectedDepartment.disciplineDepartmentList) && selectedDepartment.disciplineDepartmentList.length > 0) {
                selectedDepartment.disciplineDepartmentList.forEach(element => {
                    disciplines.push(element.discipline);
                });
            }

            if (Boolean(selectedDepartment) && Boolean(selectedDepartment.departmentDesignationList) && selectedDepartment.departmentDesignationList.length > 0) {
                console.log(selectedDepartment.departmentDesignationList);
                selectedDepartment.departmentDesignationList.forEach(element => {
                    designations.push(element.designation);
                    roleGroups.push(element.designation.roleGroup);
                });
            }

            if (Boolean(selectedDepartment)) {
                let result = this.state.allUsers.filter(p => Boolean(p.staff) && p.staff.departmentId === departmentId);
                if (Boolean(result)) {
                    userList = result;
                    result.forEach(element => {
                        userKeys.push(element.id);
                    });

                }
            }


            this.setState({
                disciplines: disciplines,
                designations: designations,
                roleGroups: roleGroups,
                userList: userList,
                userKeys: userKeys,
            }, this.setCheckedNodes)
        }
        else {
            this.getDisciplines();
            this.getDesignations();
            this.getRoleGoups();
            this.getUsers();
        }
    }

    getUsers = async () => {

        const responseUser = await handleRequest("GET", "/User/getCompanyItems");

        if (responseUser.data.length === 0) {
            this.setState({
                users: []
            });
        }

        if (responseUser.data.length !== 0) {


            this.setState({
                userList: responseUser.data,
                allUsers: responseUser.data
            });


        }


    }

    getRoleGoups = async () => {

        const responseRG = await handleRequest("GET", "/RoleGroup/getallByCompany");


        if (responseRG.data.length === 0) {
            this.setState({
                roleGroups: []
            });
        }

        if (responseRG.data.length !== 0) {

            this.setState({
                roleGroups: responseRG.data,
            });

        }

    }

    getDesignations = async () => {

        const responseDes = await handleRequest("GET", "/Designation/getallByCompany");

        if (responseDes.data.length === 0) {
            this.setState({
                designations: []
            });
        }

        if (responseDes.data.length !== 0) {


            this.setState({
                designations: responseDes.data,
            });

        }
    }

    getDisciplines = async () => {

        const response2 = await handleRequest("GET", "/Discipline/getallByCompany");


        if (response2.data.length === 0) {
            this.setState({
                disciplines: []
            });
        }

        if (response2.data.length !== 0) {

            this.setState({
                disciplines: response2.data,
            });

        }
    }

    refreshDesignationRoleGroups = () => {

        const disciplineId = this.state.disciplineId;
        const departmentId = this.state.departmentId;
        if (Boolean(disciplineId)) {
            let selectedDepartment = null;
            if (Boolean(departmentId));
            selectedDepartment = this.state.departments.find(p => p.id === departmentId);

            let designations = this.state.designations;
            let roleGroups = this.state.roleGroups;
            let userList = [];
            let userKeys = [];


            if (Boolean(selectedDepartment) && Boolean(selectedDepartment.departmentDesignationList) && selectedDepartment.departmentDesignationList.length > 0) {
                let result = selectedDepartment.departmentDesignationList.filter(p => p.disciplineId === disciplineId);
                if (Boolean(result)) {
                    designations = result;
                    let rg = [];
                    let dsg = [];
                    result.forEach(element => {
                        dsg.push(element.designation);
                        rg.push(element.designation.roleGroup);
                    });
                    roleGroups = rg;
                    designations = dsg;
                }

            }
            let resultUser = [];
            if (Boolean(selectedDepartment)) {
                resultUser = this.state.allUsers.filter(p => Boolean(p.staff) && p.staff.departmentId === departmentId && p.staff.disciplineId === disciplineId);
            }
            else resultUser = this.state.allUsers.filter(p => Boolean(p.staff) && p.staff.disciplineId === disciplineId);

            if (Boolean(resultUser)) {
                userList = resultUser;
                resultUser.forEach(element => {
                    userKeys.push(element.id);
                });

            }


            this.setState({
                designations: designations,
                roleGroups: roleGroups,
                userList: userList,
                userKeys: userKeys,
            }, this.setCheckedNodes)
        }
        else {
            this.refreshDisciplineDesignationRoleGroups();
        }
    }

    refreshRoleGroups = () => {

        const disciplineId = this.state.disciplineId;
        const departmentId = this.state.departmentId;
        const designationId = this.state.designationId;

        if (Boolean(designationId)) {

            let designations = this.state.designations.filter(p => p.id === designationId);
            let roleGroups = this.state.roleGroups;
            let userList = [];
            let userKeys = [];


            if (Boolean(designations)) {

                let rg = [];
                designations.forEach(element => {

                    rg.push(element.roleGroup);
                });
                roleGroups = rg;

            }
            let resultUser = this.state.allUsers.filter(p => Boolean(p.staff) && p.staff.designationId === designationId);

            if (Boolean(resultUser) && Boolean(departmentId)) {
                resultUser = resultUser.filter(p => Boolean(p.staff) && p.staff.departmentId === departmentId);
            }

            if (Boolean(resultUser) && Boolean(disciplineId)) {
                resultUser = resultUser.filter(p => Boolean(p.staff) && p.staff.disciplineId === disciplineId);
            }


            if (Boolean(resultUser)) {
                userList = resultUser;
                resultUser.forEach(element => {
                    userKeys.push(element.id);
                });

            }


            this.setState({

                roleGroups: roleGroups,
                userList: userList,
                userKeys: userKeys,
            }, this.setCheckedNodes)
        }
        else {
            this.refreshDesignationRoleGroups();
        }
    }

    refreshUsers = () => {

        const disciplineId = this.state.disciplineId;
        const departmentId = this.state.departmentId;
        const designationId = this.state.designationId;
        const roleGroupId = this.state.roleGroupId;
        if (Boolean(roleGroupId)) {


            let userList = [];
            let userKeys = [];

            let resultUser = this.state.allUsers.filter(p => Boolean(p.staff) && p.staff.roleGroupId === roleGroupId);

            if (Boolean(resultUser) && Boolean(departmentId)) {
                resultUser = resultUser.filter(p => Boolean(p.staff) && p.staff.departmentId === departmentId);
            }

            if (Boolean(resultUser) && Boolean(disciplineId)) {
                resultUser = resultUser.filter(p => Boolean(p.staff) && p.staff.disciplineId === disciplineId);
            }

            if (Boolean(resultUser) && Boolean(designationId)) {
                resultUser = resultUser.filter(p => Boolean(p.staff) && p.staff.designationId === designationId);
            }


            if (Boolean(resultUser)) {
                userList = resultUser;
                resultUser.forEach(element => {
                    userKeys.push(element.id);
                });

            }


            this.setState({

                userList: userList,
                userKeys: userKeys,
            }, this.setCheckedNodes)
        }
        else {
            this.refreshRoleGroups();
        }
    }

    render() {

        let optionsUsers = this.state.users.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );


        let optionsForms = [];
        this.state.forms.forEach(data => {

            optionsForms.push({ label: data.name, value: data.name });
        });

        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;

        return <div>
            <Card>
                <Row>

                    <Col sm="6">
                        <Form>

                            <Form.Group style={{ marginTop: '2rem' }} as={Row} >
                                <Col sm="3"></Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Form/Action
                            </Form.Label>
                                <Col sm="7">

                                    {/* <Tree value={this.state.nodes} selectionMode="checkbox" dragdropScope="demo" selectionKeys={this.state.selectedNodeKeys} onSelectionChange={this.handleSelectionChange} /> */}

                                    <Tree2 style={{ width: '100%' }}
                                        checkable
                                        showLine={true}
                                        onCheck={this.onCheck}
                                        checkedKeys={this.state.checkedKeys}
                                        halfCheckedKeys={this.state.halfCheckedKeys} 
                                        treeData={this.state.nodes}
                                        defaultExpandedKeys={['All']}


                                    />

                                    {/* <MultiSelect value={this.state.formNames} options={optionsForms} onChange={this.handleFormChange}
                                        style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" /> */}

                                </Col>

                            </Form.Group>

                            {/* <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                <Col sm="3"></Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Action
                        </Form.Label>
                                <Col sm="4">
                                    <MultiSelect value={this.state.actionNames} options={optionsActions} onChange={(e) => this.setState({ actionNames: e.value })}
                                        style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />

                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group> */}

                        </Form>
                    </Col>
                    <Col sm="6">
                        <Form>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Department
                            </Form.Label>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="Department" id="Department" value={this.state.departmentId} onChange={(value) => {

                                        this.setState({ departmentId: value, disciplineId: null, designationId: null, roleGroupId: null, userKeys: [] }, this.refreshDisciplineDesignationRoleGroups)
                                    }}>
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.departments.map(i => (
                                            <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Discipline
                        </Form.Label>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="Discipline" id="Discipline" value={this.state.disciplineId} onChange={(value) => {

                                        this.setState({ disciplineId: value, designationId: null, roleGroupId: null, userKeys: [] }, this.refreshDesignationRoleGroups)
                                    }}>
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.disciplines.map(i => (
                                            <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Designation
                            </Form.Label>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="Designation" id="Designation" value={this.state.designationId} onChange={(value) => {

                                        this.setState({ designationId: value, roleGroupId: null, userKeys: [] }, this.refreshRoleGroups)
                                    }}>
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.designations.map(i => (
                                            <Option key={i.id} value={i.id}>{i.designationName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Role Group
                        </Form.Label>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} placeholder="Designation" id="Designation" value={this.state.roleGroupId} onChange={(value) => {

                                        this.setState({ roleGroupId: value, userKeys: [] }, this.refreshUsers)
                                    }}>
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.roleGroups.map(i => (
                                            <Option key={i.id} value={i.id}>{i.roleName}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    User
                                    </Form.Label>
                                <Col sm="4">
                                    <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.userKeys} onChange={this.handleUserChange2}>
                                        {this.state.userList.map(i => (
                                            <Option key={i.id} value={i.id}>{Boolean(i.staff) ? i.staff.fullName + " ( " + i.username + " ) " : i.username}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm="3"></Col>
                            </Form.Group>


                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                    </Col>
                    <Col sm="3">
                        <Button style={{ width: '100%' }} id='add-authorization' className="p-button-success" onClick={this.saveAuthorization} label="Save All" />
                    </Col>

                </Row>
                <Toast ref={(el) => this.toast = el} />
                {/* <div style={{ marginTop: '2rem', marginBottom: '1rem' }} className="content-section implementation">
                    <Toast ref={(el) => this.toast = el} />
                    <DataTable ref={(el) => this.dt = el} value={this.state.authorizationList} paginator={true} responsive={true}
                        rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-departments" selectionMode="single"
                        selection={this.state.selectedRow} onSelectionChange={e => this.setState({ selectedRow: e.value })}>
                        <Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable header="Id" />
                        <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                        <Column field="userName" header="User Name" filter sortable />
                        <Column field="formTitle" style={{ textAlign: 'center' }} header="Form" filter sortable />
                        <Column field="action" header="Action" filter sortable />

                    
                        <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                    </DataTable>
                </div> */}

                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete  Authorization ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Selected authorization will be deleted. Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <BootButton variant="secondary" onClick={this.showOrHideModal}>Cancel</BootButton>
                        <BootButton variant="danger" onClick={this.deleteAuthorization} >Delete</BootButton>
                    </Modal.Footer>
                </Modal>

            </Card>

        </div>

    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Authorization)