/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button as PrimeButton } from "primereact/button";
import { Card } from "primereact/card";
import { handleRequest } from '../../redux/config/env';
import { Modal, Button as ReactButton, Col, Form as FormBoot, Row } from 'react-bootstrap';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import { Form, Input, Checkbox } from 'antd';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { Redirect } from "react-router-dom";
//import MNDSettings from './components/MNDSettings';   --component i�in �al���lacak.

export class Discipline extends Component {
    constructor() {
        super();
        this.state = {
            deleteModal: false,
            hiddenSave: false,
            hiddenUpdate: true,
            disciplineNameText: "",
            code: "",
            selectedDiscipline: null,
            disciplines: [],
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    };

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const response = await handleRequest("GET", "/Discipline/getallByCompany");

        if (response.data.length === 0) {
            this.setState({
                disciplines: [],
            });
        } else {
            this.setState({
                disciplines: response.data,
            });
        }

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Discipline" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Discipline",
        };
        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }
        
        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Discipline");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    actionTemplate(rowData, column) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
                </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.deleteModal.bind(this)} />
                </React.Fragment>;
        }

    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedDiscipline: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    saveDiscipline = async () => {
        // if (this.state.disciplineNameText !== "" && this.state.code!==""){ 
        const newDiscipline = {
            disciplineName: this.state.disciplineNameText,
            code: this.state.code
        }
        var response = await handleRequest("POST", "/Discipline/add", newDiscipline);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS-DISCIPLINE-001") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else if (response.data === "ERROR-DISCIPLINE-001") {
                this.showError("An error was occured please try again later !");
            } else if (response.data === "ERROR-DISCIPLINE-002") {
                this.showError("This record added before please change discipline name !");
            }
        }
        // }
        // else{
        //     this.showWarning();
        // }
    }

    updateDiscipline = async () => {
        //    if(this.state.disciplineNameText !== ""){
        const updatedDiscipline = {
            disciplineName: this.state.disciplineNameText,
            id: this.state.selectedDiscipline.id,
            active: this.state.selectedDiscipline.active,
            code: this.state.code
        }

        var response2 = await handleRequest("PUT", "/Discipline/update", updatedDiscipline);
        if (response2.data !== null && response2.data !== undefined) {
            if (response2.data === "SUCCESS-DISCIPLINE-001") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else if (response2.data === "ERROR-DISCIPLINE-001") {
                this.showError("An error was occured please try again later !");
            } else if (response2.data === "ERROR-DISCIPLINE-002") {
                this.showError("This record added before please change discipline name !");
            }
        }
        // }else{
        //     this.showWarning();
        // }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/Discipline/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                disciplines: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                disciplines: response.data,
            });
        }
    }

    deleteDisciplineOK = async () => {
        const newDiscipline = {
            id: this.state.selectedDiscipline.id
        }
        await handleRequest("PUT", "/Discipline/delete", newDiscipline);

        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    changeButtonUpdate = (row) => {

        this.formRef.current.setFieldsValue({
            DisciplineDiscipline: row.disciplineName,
            DisciplineCode: row.code
        });

        this.setState({
            disciplineNameText: row.disciplineName,
            selectedDiscipline: row,
            code: row.code,
            hiddenSave: true,
            hiddenUpdate: false,
        });
    }

    resetInputs = async () => {

        this.formRef.current.setFieldsValue({
            DisciplineDiscipline: "",
            DisciplineCode: ""
        });

        await this.setState({
            disciplineNameText: "",
            code: "",
            hiddenSave: false,
            hiddenUpdate: true,
        });
    }

    changeButtonCancel = () => {

        this.formRef.current.setFieldsValue({
            DisciplineDiscipline: "",
            DisciplineCode: ""
        });

        this.setState({
            disciplineNameText: "",
            code: "",
            hiddenSave: false,
            hiddenUpdate: true,
        });
    }

    showOrHideModalDeleteMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess();
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>
        var itemDisciplineCodeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DisciplineCode");
        var itemDisciplineAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DisciplineDiscipline");

        var itemDisciplineCodeRequired = this.state.dynamicInputs.find(p => p.inputKey === "DisciplineCode");
        var itemDisciplineRequired = this.state.dynamicInputs.find(p => p.inputKey === "DisciplineDiscipline");


        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        };

        return <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    {/*<MNDSettings modalShow={this.state.modalMNDSettings}/> component'e �evirme �al��mas� yap�labilir. */}

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Card style={{ width: '700px' }}>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <Form
                            {...layout}
                            initialValues={{ remember: false }}
                            onFinish={this.state.hiddenUpdate == false ? this.updateDiscipline : this.saveDiscipline}
                            onFinishFailed={onFinishFailed}
                            ref={this.formRef}
                        >
                            {(itemDisciplineCodeAttributes.visible) &&
                                <Form.Item
                                    label={<FormattedMessage id="DisciplineCode" defaultMessage="Code" />}
                                    name="DisciplineCode"
                                    rules={[{ required: itemDisciplineCodeRequired.canBeRequired, message: <FormattedMessage id={itemDisciplineCodeRequired.translateRequired} defaultMessage="Please input your code!" /> }]} >
                                    <Input id="DisciplineCode" value={this.state.code} onChange={(e) =>
                                        this.setState({ code: e.target.value })
                                    } />
                                </Form.Item>}

                            {(itemDisciplineAttributes.visible) &&
                                <Form.Item
                                    label={<FormattedMessage id="DisciplineDiscipline" defaultMessage="Discipline" />}
                                    name="DisciplineDiscipline"
                                    rules={[{ required: itemDisciplineRequired.canBeRequired, message: <FormattedMessage id={itemDisciplineRequired.translateRequired} defaultMessage="Please input your discipline!" /> }]} >
                                    <Input id="DisciplineDiscipline" value={this.state.disciplineNameText} onChange={(e) => this.setState({ disciplineNameText: e.target.value })} />
                                </Form.Item>}

                            <div hidden={this.state.hiddenSave}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="8">
                                            <ReactButton id="DisciplineSaveButton"
                                                style={{ width: '100%' }} type="submit"
                                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </FormBoot.Group>
                                }
                            </div>

                            <div hidden={this.state.hiddenUpdate}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2"></Col>
                                        <Col sm="4">
                                            <ReactButton id="DisciplineCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                        </Col>
                                        <Col sm="4">
                                            <ReactButton id="DisciplineUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </FormBoot.Group>
                                }
                            </div>

                            <div className="content-section implementation">
                                <DataTable ref={(el) => this.dt = el} value={this.state.disciplines} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-disciplines"
                                    selectionMode="single" selection={this.state.selectedDiscipline} onSelectionChange={e => this.setState({ selectedDiscipline: e.value })}>
                                    <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                                    {(itemDisciplineCodeAttributes.visible) &&
                                        <Column field="code" header="Code" filter={true} sortable />
                                    }
                                    {(itemDisciplineAttributes.visible) &&
                                        <Column field="disciplineName" header="Discipline" filter={true} sortable />
                                    }
                                    <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="Discipline Id" filter sortable />
                                    <Column field="active" style={{ textAlign: 'center', display: 'none' }} header="Active" filter sortable />
                                    {(this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 || this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) &&
                                        <Column header="" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                    }
                                </DataTable>
                            </div>

                            <Modal
                                show={this.state.modalMessage}
                                onHide={this.showOrHideModalDeleteMessage}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>

                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <Row >
                                            <Col sm="12">
                                                {this.state.messageTip == "Success" &&
                                                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                {this.state.messageTip == "Warning" &&
                                                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                {this.state.messageTip == "Error" &&
                                                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                            </Col>
                                        </Row>
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <Row >
                                        <Col sm="12">
                                            {this.state.message}
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.deleteModal}
                                onHide={this.showOrHideModalDelete}
                                aria-labelledby="contained-modal-title-vcenter"
                                backdrop="static"
                                centered >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p style={{ textAlign: "center", color: "black" }}>
                                        <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                                    </p>
                                    <p style={{ textAlign: "center", color: "black" }}>
                                        <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                                    </ReactButton>
                                    <ReactButton variant="danger" onClick={this.deleteDisciplineOK}>
                                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                                    </ReactButton>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    </Card>}
               </MenuProvider>
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Discipline)