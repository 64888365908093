/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import MNDTableSettings from '../MND/MNDTableSettings';
import * as Mnd from "../../partials/content/CustomComponents";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button as PrimeButton } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { Form, Col, Row, Modal, Button as ReactButton } from 'react-bootstrap';
import { Form as FormAntd, Upload, Input, Select, Switch, DatePicker, Divider, Button as AntdButton, Checkbox, InputNumber, Row as RowAnt, Col as ColAnt } from 'antd';
import HorizontalTimeline from "react-horizontal-timeline";
import { NATIONALITIES } from './JsonDatas/Datas';
import { handleRequest } from '../../redux/config/env';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { API_BASE } from '../../redux/config/env';
import { LoadingOutlined, PlusOutlined, UploadOutlined, DownloadOutlined, UserOutlined, SolutionOutlined, SmileOutlined } from '@ant-design/icons';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import startsWith from 'lodash.startswith';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import * as EmailValidator from 'email-validator';
import { Redirect } from "react-router-dom";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";

const { RangePicker } = DatePicker;
const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

function Graduation(graduation) {
    if (graduation == 1) {
        return "Elementary School";
    } else if (graduation == 2) {
        return "Middle School";
    } else if (graduation == 3) {
        return "High School";
    } else if (graduation == 4) {
        return "College";
    } else if (graduation == 5) {
        return "University";
    }
    return "";
}

function Degree(degree) {
    if (degree == 1) {
        return "Bachelor (BSc/BA)";
    } else if (degree == 2) {
        return "Master (MSc/MA)";
    } else if (degree == 3) {
        return "Doctorate (PhD)";
    } else if (degree == 4) {
        return "MBA";
    }
    return "";
}

function Language(language) {
    if (language == 1) {
        return "English";
    } else if (language == 2) {
        return "Arabic";
    } else if (language == 3) {
        return "French";
    } else if (language == 4) {
        return "Spanish";
    } else if (language == 5) {
        return "Turkish";
    }
    return "";
}

function LanguageLevel(languageLevel) {
    if (languageLevel == 1) {
        return "Native";
    } else if (languageLevel == 2) {
        return "Fluent";
    } else if (languageLevel == 3) {
        return "Working knowledge";
    } else if (languageLevel == 4) {
        return "Basic";
    }
    return "";
}

function LanguageTest(languageTest) {
    if (languageTest == 1) {
        return "Test1";
    } else if (languageTest == 2) {
        return "Test2";
    } else if (languageTest == 3) {
        return "Test3";
    } else if (languageTest == 4) {
        return "Test4";
    }
    return "";
}

function BankName(bankName) {
    if (bankName == 1) {
        return "Bank1";
    } else if (bankName == 2) {
        return "Bank2";
    } else if (bankName == 3) {
        return "Bank3";
    } else if (bankName == 4) {
        return "Bank4";
    }
    return "";
}

function Branch(branch) {
    if (branch == 1) {
        return "Branch1";
    } else if (branch == 2) {
        return "Branch2";
    } else if (branch == 3) {
        return "Branch3";
    }
    return "";
}

export class PersonalInfo extends Component {
    constructor() {
        super();
        this.state = {
            photoPath: "",
            photoImageUrl: "",
            budgeNo: "",
            companyStaff: true,
            timelinePages: ["Personal", "Contact", "Training", "Contract", "Experiences",
                "Education", "Language Skills", "Bank Accounts", "Social and Hobbies", "Health",
                "References", "Comments"],
            timelinePagesHidden: [false, true, true, true, true, true, true,
                true, true, true, true, true],
            timelinePagesData: ["2000-02-17", "2000-02-17", "2000-02-17", "2000-02-17",
                "2000-02-17", "2000-02-17", "2000-02-17", "2000-02-17", "2000-02-17",
                "2000-02-17", "2000-02-17", "2000-02-17"],
            curIdx: 0,
            prevIdx: -1,
            color: "#7B9D6F",
            modalShow: false,
            newPersonelType: "",
            hiddenSave: false,
            hiddenUpdate: true,
            hiddenSaveTraining: true,
            hiddenUpdateTraining: true,
            hiddenDataGirisTraining: true,
            hiddenSaveContract: true,
            hiddenUpdateContract: true,
            hiddenDataGirisContract: true,
            hiddenSavePersonal: true,
            hiddenUpdatePersonal: true,
            hiddenDataGirisPersonal: false,
            hiddenSaveContact: true,
            hiddenUpdateContact: true,
            hiddenDataGirisContact: false,
            hiddenSaveContract: true,
            hiddenUpdateContract: true,
            hiddenSaveExperiences: true,
            hiddenUpdateExperiences: true,
            hiddenDataGirisExperiences: true,
            hiddenSaveEducation: true,
            hiddenUpdateEducation: true,
            hiddenDataGirisEducation: true,
            hiddenSaveLanguageSkills: true,
            hiddenUpdateLanguageSkills: true,
            hiddenDataGirisLanguageSkills: true,
            hiddenSaveReferences: true,
            hiddenUpdateReferences: true,
            hiddenDataGirisReferences: true,
            hiddenSaveBankAccounts: true,
            hiddenUpdateBankAccounts: true,
            hiddenDataGirisBankAccounts: true,
            hiddenContact: true,
            hiddenContract: true,
            hiddenTraining: true,
            hiddenPersonal: true,
            hiddenExperiences: true,
            hiddenEducation: true,
            hiddenLanguageSkills: true,
            hiddenReferences: true,
            hiddenBankAccounts: true,
            hiddenLanguage: true,
            hiddenBank: true,
            hiddenSocial: true,
            hiddenReferences: true,
            hiddenComments: true,
            personel: [],
            selectedPersonel: null,
            personelId: null,
            personelType: "",
            personelTypes: [],
            genderCheck: true,
            nationality: "",
            name: "",
            midName: "",
            lastName: "",
            fatherName: "",
            motherName: "",
            birthDate: null,
            placeOfBirth: "",
            nationalId: "",
            nationalIdExpDate: null,
            drivingLicence: "",
            drivingLicenceExpDate: null,
            passportNo: "",
            passportExpDate: null,
            bloodGroup: "",
            maritalStatus: "",
            numberOfChild: "",
            militaryObligation: "",
            smokerCheck: true,
            personelTypeId: null,
            nationalityId: null,
            bloodGroupId: null,
            maritalStatusId: null,
            militaryObligationId: null,
            workPeriodStart: null,
            workPeriodEnd: null,
            expCompany: "",
            expPosition: "",
            expCountry: "",
            expCity: "",
            expProject: "",
            expClient: "",
            approvalOfClientCheck: false,
            approvalOfClient: "",
            AttachApproveFileUpload: [],
            hiddenApprovalOfClient: true,
            expBrief: "",
            graduation: "",
            degree: "",
            nameOfSchool: "",
            attachGraduationCertificate: "",
            eduCountry: "",
            dateOfGraduation: null,
            graduationScore: "",
            graduationMaxScore: "",
            language: "",
            understanding: "",
            accountType: "",
            reading: "",
            writing: "",
            internationalLanguageTest: "",
            score: "",
            bankName: "",
            branch: "",
            accountNo: "",
            ibanNo: "",
            IBANNo: "",
            cutOffDate: null,
            paymentDate: null,
            social: "",
            hobbies: "",
            refName: "",
            refjobDescription: "",
            refCompany: "",
            refCountry: "",
            refPhone: "",
            refEmail: "",
            refRelation: "",
            refRemarks: "",
            comments: "",
            activeIndex: 0,
            bloodGroups: [],
            maritalStatuses: [],
            militaryObligations: [],
            genders: [],
            smokeStatuses: [],
            graduations: [
                { name: 'Elementary School', id: '1' },
                { name: 'Middle School', id: '2' },
                { name: 'High School', id: '3' },
                { name: 'College', id: '4' },
                { name: 'University', id: '5' }
            ],
            degrees: [
                { name: 'Bachelor (BSc/BA)', id: '1' },
                { name: 'Master (MSc/MA)', id: '2' },
                { name: 'Doctorate (PhD)', id: '3' },
                { name: 'MBA', id: '4' }
            ],
            languages: [
                { name: 'English', id: '1' },
                { name: 'Arabic', id: '2' },
                { name: 'French', id: '3' },
                { name: 'Spanish', id: '4' },
                { name: 'Turkish', id: '5' },
            ],
            languageLevels: [
                { name: 'Native', id: '1' },
                { name: 'Fluent', id: '2' },
                { name: 'Working knowledge', id: '3' },
                { name: 'Basic', id: '4' }
            ],
            languageTests: [
                { name: 'Test1', id: '1' },
                { name: 'Test2', id: '2' },
                { name: 'Test3', id: '3' },
                { name: 'Test4', id: '4' }
            ],
            banks: [
                { name: 'Bank1', id: '1' },
                { name: 'Bank2', id: '2' },
                { name: 'Bank3', id: '3' },
                { name: 'Bank4', id: '4' }
            ],
            branches: [
                { name: 'Branch1', id: '1' },
                { name: 'Branch2', id: '2' },
                { name: 'Branch3', id: '3' }
            ],
            residenceCountry: "",
            residenceCity: "",
            residenceAddress: "",
            residenceCompany: "",
            residencePersonelPhone: "",
            residencePersonelPhoneDrm: false,
            residenceCompanyEmail: "",
            residencePersonalEmail: "",
            residenceEmergencyPhone: "",
            residenceEmergencyPhoneDrm: false,
            residenceEmergencyEmail: "",
            homeCountry: "",
            homeCity: "",
            homeAddress: "",
            homePersonalPhone: "",
            homePersonalPhoneDrm: false,
            homeEmergencyPhone: "",
            homeEmergencyPhoneDrm: false,
            refPhoneDrm: false,
            homeEmergencyEmail: "",
            socialMedia: "",
            socialAccount: "",
            nameOfTraining: "",
            dateOfTraining: null,
            durationTraining: "",
            certificateValidity: "",
            department: "",
            departmentId: null,
            departments: [],
            roleGroup: "",
            roleGroupId: null,
            roleGroups: [],
            designation: "",
            designationId: null,
            designations: [],
            discipline: "",
            disciplineId: null,
            disciplines: [],
            contractDurationText: "",
            contractDuration: "",
            contractDurationId: null,
            contractDurations: [],
            jobDescription: "",
            contractDate: null,
            annualVacationDays: "",
            annualVacationPeriod: "",
            annualVacationPeriodType: "",
            basicSalary: "",
            homeAllowance: "",
            transportationAllowance: "",
            foodAllowance: "",
            otherPayments: "",
            allowanceName: "",
            allowanceType: "",
            workingStatus: "",
            nationalIdIqamaNo: "Iqama No",
            hiddenPassportExpDate: true,
            hiddenDrivingLicenceExpDate: true,
            hiddenNationalIdExpDate: true,
            hiddenBirthDate: true,
            PersonalInfoSocialMedias: [],
            PersonalInfoRefRelations: [],
            residenceCountry: '',
            residenceCity: '',
            hiddenResidenceCompanyEmail: true,
            hiddenRefEmail: true,
            hiddenResidencePersonelEmail: true,
            hiddenResidenceEmergencyEmail: true,
            hiddenHomeEmergencyEmail: true,
            AttachTrainingCertificateFileUpload: [],
            attachTrainingCertificate: "",
            fileList: [],
            dateOfTrainingBsl: "",
            workPeriodStart: "",
            dateOfTrainingBts: "",
            workPeriodEnd: "",
            createNewButtonTrainingVariant: "info",
            createNewButtonTraining: "Create New",
            createNewButtonContractVariant: "info",
            createNewButtonContract: "Create New",
            createNewButtonExperiencesVariant: "info",
            createNewButtonExperiences: "Create New",
            createNewButtonEducationVariant: "info",
            createNewButtonEducation: "Create New",
            createNewButtonLanguageSkillsVariant: "info",
            createNewButtonLanguageSkills: "Create New",
            createNewButtonReferencesVariant: "info",
            createNewButtonReferences: "Create New",
            createNewButtonBankAccountsVariant: "info",
            createNewButtonBankAccounts: "Create New",
            createNewButtonPersonalVariant: "info",
            createNewButtonPersonal: "Create New",
            dynamicAttributes: [],
            dynamicInputs: [],
            authorizations: [],
            personalInfoTrainingTable: [],
            staffTrainingKayit: 0,
            personalInfoContractTable: [],
            staffContractKayit: 0,
            personalInfoExperiencesTable: [],
            staffExperiencesKayit: 0,
            personalInfoEducationTable: [],
            staffEducationKayit: 0,
            personalInfoLanguageSkillsTable: [],
            staffLanguageSkillsKayit: 0,
            personalInfoReferencesTable: [],
            staffReferencesKayit: 0,
            personalInfoBankAccountsTable: [],
            staffBankAccountsKayit: 0,
            AttachGraduationCertificateFileUpload: [],
            attachGraduationCertificate: "",
            AttachLanguageTestResultFileUpload: [],
            attachLanguageTestResult: "",
            AttachIqamaNoFileUpload: [],
            attachIqamaNo: "",
            AttachDrivingLicenceNoFileUpload: [],
            attachDrivingLicenceNo: "",
            AttachPassportNoFileUpload: [],
            attachPassportNo: "",

            redirect: false,
            hiddenAccountTypeBank: true,
            hiddenAccountTypeCredit: true,
            healthDrm: false,
            healthDescription: "",
            social: "",
            hobbies: "",
            comments: "",
            checkPersonalSetValue: true,
            checkContactSetValue: true,
        };

        this.actionTemplateTraining = this.actionTemplateTraining.bind(this);
        this.actionTemplateContract = this.actionTemplateContract.bind(this);
        this.actionTemplateExperiences = this.actionTemplateExperiences.bind(this);
        this.actionTemplateEducation = this.actionTemplateEducation.bind(this);
        this.actionTemplateLanguageSkills = this.actionTemplateLanguageSkills.bind(this);
        this.actionTemplateReferences = this.actionTemplateReferences.bind(this);
        this.actionTemplateBankAccounts = this.actionTemplateBankAccounts.bind(this);
    };

    formRefPersonal = React.createRef();
    formRefContact = React.createRef();
    formRefContract = React.createRef();
    formRefTraining = React.createRef();
    formRefExperiences = React.createRef();
    formRefBankAccounts = React.createRef();
    formRefEducation = React.createRef();
    formRefLanguageSkills = React.createRef();
    formRefReferences = React.createRef();

    componentDidMount = async () => {
        if (this.props.location.pathname.split('/')[2] == null) {
            this.setState({
                hiddenUpdate: true,
                hiddenSave: false,
                personalInfoTrainingTable: [],
                staffTrainingKayit: 0,
                personalInfoContractTable: [],
                staffContractKayit: 0,
                personalInfoExperiencesTable: [],
                staffExperiencesKayit: 0,
                personalInfoEducationTable: [],
                staffEducationKayit: 0,
                personalInfoLanguageSkillsTable: [],
                staffLanguageSkillsKayit: 0,
                personalInfoBankAccountsTable: [],
                staffBankAccountsKayit: 0,
                personalInfoReferencesTable: [],
                staffReferencesKayit: 0,
                hiddenSavePersonal: false,
                hiddenUpdatePersonal: true,
                hiddenSaveContact: false,
                hiddenUpdateContact: true,
                hiddenSaveContract: false,
                hiddenUpdateContract: true,
            });
        } else {
            var staffId = this.props.location.pathname.split('/')[2];
            if (staffId == null || staffId == undefined || staffId == "" || staffId == 0) {
                staffId = -1;
            }

            this.getStaff(staffId);
        }

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "PersonalInfo" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "PersonalInfo",
        };
        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "PersonalInfo");
        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {
            console.log("EEE", error);
        }

        this.getLanguageType();
        this.getCodeAll();
        this.getDepartment();
        this.getDesignation();
        this.getCodesPersonalInfoSocialMedia();
        this.getCodesPersonalInfoRefRelation();
        this.getTableProperties();
    }

    getStaff = async (staffId) => {

        const response2 = await handleRequest("GET", "/Staff/" + staffId);
        var personelFlag;

        if (response2.data.length !== 0) {
            personelFlag = response2.data;
        }
        console.log("ABCD", response2.data);
        if (response2.data.length !== 0) {
            if (personelFlag.staffTrainingList != null && personelFlag.staffTrainingList != undefined && personelFlag.staffTrainingList.length !== 0) {
                personelFlag.staffTrainingList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoTrainingTable: personelFlag.staffTrainingList,
                    staffTrainingKayit: personelFlag.staffTrainingList[personelFlag.staffTrainingList.length - 1].id,
                });
            }
            if (personelFlag.staffTrainingList.length === 0) {
                this.setState({
                    personalInfoTrainingTable: [],
                    staffTrainingKayit: 0,
                });
            }

            if (personelFlag.staffContractList != null && personelFlag.staffContractList != undefined && personelFlag.staffContractList.length !== 0) {
                personelFlag.staffContractList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoContractTable: personelFlag.staffContractList,
                    staffContractKayit: personelFlag.staffContractList[personelFlag.staffContractList.length - 1].id,
                });
            }
            if (personelFlag.staffContractList.length === 0) {
                this.setState({
                    personalInfoContractTable: [],
                    staffContractKayit: 0,
                });
            }

            if (personelFlag.staffExperiencesList != null && personelFlag.staffExperiencesList != undefined && personelFlag.staffExperiencesList.length !== 0) {
                personelFlag.staffExperiencesList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoExperiencesTable: personelFlag.staffExperiencesList,
                    staffExperiencesKayit: personelFlag.staffExperiencesList[personelFlag.staffExperiencesList.length - 1].id,
                });
            }
            if (personelFlag.staffExperiencesList.length === 0) {
                this.setState({
                    personalInfoExperiencesTable: [],
                    staffExperiencesKayit: 0,
                });
            }

            if (personelFlag.staffEducationList != null && personelFlag.staffEducationList != undefined && personelFlag.staffEducationList.length !== 0) {
                personelFlag.staffEducationList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoEducationTable: personelFlag.staffEducationList,
                    staffEducationKayit: personelFlag.staffEducationList[personelFlag.staffEducationList.length - 1].id,
                });
            }
            if (personelFlag.staffEducationList.length === 0) {
                this.setState({
                    personalInfoEducationTable: [],
                    staffEducationKayit: 0,
                });
            }

            if (personelFlag.staffLanguageSkillsList != null && personelFlag.staffLanguageSkillsList != undefined && personelFlag.staffLanguageSkillsList.length !== 0) {
                personelFlag.staffLanguageSkillsList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoLanguageSkillsTable: personelFlag.staffLanguageSkillsList,
                    staffLanguageSkillsKayit: personelFlag.staffLanguageSkillsList[personelFlag.staffLanguageSkillsList.length - 1].id,
                });
            }
            if (personelFlag.staffLanguageSkillsList.length === 0) {
                this.setState({
                    personalInfoLanguageSkillsTable: [],
                    staffLanguageSkillsKayit: 0,
                });
            }

            if (personelFlag.staffBankAccountsList != null && personelFlag.staffBankAccountsList != undefined && personelFlag.staffBankAccountsList.length !== 0) {
                personelFlag.staffBankAccountsList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoBankAccountsTable: personelFlag.staffBankAccountsList,
                    staffBankAccountsKayit: personelFlag.staffBankAccountsList[personelFlag.staffBankAccountsList.length - 1].id,
                });
            }
            if (personelFlag.staffBankAccountsList.length === 0) {
                this.setState({
                    personalInfoBankAccountsTable: [],
                    staffBankAccountsKayit: 0,
                });
            }

            if (personelFlag.staffReferencesList != null && personelFlag.staffReferencesList != undefined && personelFlag.staffReferencesList.length !== 0) {
                personelFlag.staffReferencesList.forEach((element, index) => {
                    element.index = index + 1;
                    element.key = element.id;
                });
                this.setState({
                    personalInfoReferencesTable: personelFlag.staffReferencesList,
                    staffReferencesKayit: personelFlag.staffReferencesList[personelFlag.staffReferencesList.length - 1].id,
                });
            }
            if (personelFlag.staffReferencesList.length === 0) {
                this.setState({
                    personalInfoReferencesTable: [],
                    staffReferencesKayit: 0,
                });
            }

            var genderValue = false;
            var smokeStatusValue = false;
            var workingStatusValue = "";

            if (personelFlag.genderCode.name === "Female")
                genderValue = true;
            else
                genderValue = false;

            if (personelFlag.smokeStatus === 25)
                smokeStatusValue = true;
            else
                smokeStatusValue = false;

            if (personelFlag.workingStatus !== undefined && personelFlag.workingStatus !== null)
                workingStatusValue = personelFlag.workingStatus;
            else
                workingStatusValue = "Waiting For Start"


            const response = await handleRequest("GET", "/Designation/" + personelFlag.designationId);

            const roleGroupData = response.data.roleGroup;

            if (roleGroupData.length === 0) {
                this.setState({
                    roleGroups: []
                });
            }

            if (roleGroupData.length !== 0) {

                const columns = roleGroupData;

                var options = [];
                options.push({ name: columns.roleName.toString(), id: columns.id.toString() })

                this.setState({
                    roleGroups: options,
                });
            }

            const response1 = await handleRequest("GET", "/Department/" + personelFlag.departmentId);

            const disciplineData = response1.data.disciplineDepartmentList;

            if (disciplineData.length === 0) {
                this.setState({
                    disciplines: []
                });
            }

            if (disciplineData.length !== 0) {

                const columns = disciplineData;

                const options = columns.map(function (row) {
                    return { name: row.discipline.disciplineName.toString(), id: row.discipline.id.toString() }
                })

                this.setState({
                    disciplines: options,
                });
            }

            var _imageUrl = "";

            if (personelFlag.photoPath !== null && personelFlag.photoPath !== undefined && personelFlag.photoPath.length > 0) {
                _imageUrl = `${API_BASE}/Staff/download/photo/` + personelFlag.id
            }


            this.setState({
                hiddenUpdate: false,
                hiddenSave: true,
                genderCheck: genderValue,
                nationality: personelFlag.countryName,
                nationalityId: personelFlag.country,
                personelId: personelFlag.id,
                name: personelFlag.name,
                midName: personelFlag.midName,
                lastName: personelFlag.lastName,
                fatherName: personelFlag.fatherName,
                motherName: personelFlag.motherName,
                birthDate: new Date(personelFlag.birthDate),
                placeOfBirth: personelFlag.placeOfBirth,
                nationalId: personelFlag.nationalId,
                nationalIdExpDate: new Date(personelFlag.nationalExpirityDate),
                drivingLicence: personelFlag.drivingLicence,
                drivingLicenceExpDate: new Date(personelFlag.drivingExpirityDate),
                passportNo: personelFlag.passportNo,
                passportExpDate: new Date(personelFlag.passportExpirityDate),
                bloodGroup: personelFlag.bloodGroupCode.name,
                bloodGroupId: personelFlag.bloodGroupCode.id,
                maritalStatus: personelFlag.maritalStatusCode.name,
                maritalStatusId: personelFlag.maritalStatusCode.id,
                numberOfChild: personelFlag.numberOfChild,
                militaryObligation: personelFlag.militaryObligationcode.name,
                militaryObligationId: personelFlag.militaryObligationcode.id,
                personelType: personelFlag.staffTypeCode.name,
                personelTypeId: personelFlag.staffTypeCode.id,
                smokerCheck: smokeStatusValue,
                departmentId: personelFlag.departmentId,
                department: personelFlag.department.departmentName,
                disciplineId: personelFlag.disciplineId,
                discipline: personelFlag.discipline == null ? "" : personelFlag.discipline.disciplineName,
                designationId: personelFlag.designationId,
                designation: personelFlag.designation.designationName,
                roleGroupId: personelFlag.roleGroupId,
                roleGroup: personelFlag.roleGroup == null ? "" : personelFlag.roleGroup.roleName,
                photoPath: personelFlag.photoPath == null ? "" : personelFlag.photoPath,
                photoImageUrl: _imageUrl,
                companyStaff: personelFlag.companyStaff,
                budgeNo: personelFlag.budgeNo == null ? "" : personelFlag.budgeNo,
                workingStatus: workingStatusValue,
                healthDrm: personelFlag.healthDrm,
                healthDescription: personelFlag.healthDescription,
                social: personelFlag.social,
                hobbies: personelFlag.hobbies,
                comments: personelFlag.comments,
                attachIqamaNo: personelFlag.attachIqamaNo,
                attachDrivingLicenceNo: personelFlag.attachDrivingLicenceNo,
                attachPassportNo: personelFlag.attachPassportNo,
                refPhone: personelFlag.refPhone,
                residenceCountry: personelFlag.residenceCountry,
                residenceCity: personelFlag.residenceCity,
                residenceAddress: personelFlag.residenceAddress,
                residenceCompany: personelFlag.residenceCompany,
                residencePersonelPhone: personelFlag.residencePersonelPhone,
                residenceCompanyEmail: personelFlag.residenceCompanyEmail,
                residencePersonalEmail: personelFlag.residencePersonalEmail,
                residenceEmergencyPhone: personelFlag.residenceEmergencyPhone,
                residenceEmergencyEmail: personelFlag.residenceEmergencyEmail,
                homeCountry: personelFlag.homeCountry,
                homeCity: personelFlag.homeCity,
                homeAddress: personelFlag.homeAddress,
                homePersonalPhone: personelFlag.homePersonalPhone,
                homeEmergencyPhone: personelFlag.homeEmergencyPhone,
                homeEmergencyEmail: personelFlag.homeEmergencyEmail,
                socialMedia: personelFlag.socialMedia,
                socialAccount: personelFlag.socialAccount,

                hiddenSavePersonal: true,
                hiddenUpdatePersonal: false,
                hiddenSaveContact: true,
                hiddenUpdateContact: false,
                hiddenSaveContract: true,
                hiddenUpdateContract: false,
            });
        } else {
            this.setState({
                personalInfoContractTable: [],
                staffContractKayit: 0,

                personalInfoExperiencesTable: [],
                staffExperiencesKayit: 0,

                personalInfoEducationTable: [],
                staffEducationKayit: 0,

                personalInfoLanguageSkillsTable: [],
                staffLanguageSkillsKayit: 0,

                personalInfoBankAccountsTable: [],
                staffBankAccountsKayit: 0,

                personalInfoReferencesTable: [],
                staffReferencesKayit: 0,

                personalInfoTrainingTable: [],
                staffTrainingKayit: 0,
            });
        }

  
  
    }

    getTableProperties = async () => {
        this.PersonalInfo = JSON.parse(localStorage.getItem('PersonalInfo'));
        if (this.PersonalInfo != null && this.PersonalInfo != undefined && this.PersonalInfo != "") {

            await this.setState({
                bordered: this.PersonalInfo.bordered,
                title: this.PersonalInfo.titleDrm == true ? title : undefined,
                titleDrm: this.PersonalInfo.titleDrm,
                showHeader: this.PersonalInfo.showHeader,
                footer: this.PersonalInfo.footerDrm == true ? footer : undefined,
                footerDrm: this.PersonalInfo.footerDrm,
                expandable: this.PersonalInfo.expandableDrm == true ? expandable : {},
                expandableDrm: this.PersonalInfo.expandableDrm,
                rowSelection: this.PersonalInfo.rowSelection,
                ellipsis: this.PersonalInfo.ellipsis,
                tableLayout: this.PersonalInfo.tableLayout,
                size: this.PersonalInfo.size,
                top: this.PersonalInfo.top,
                bottom: this.PersonalInfo.bottom,
                editBgColor: this.PersonalInfo.editBgColor,
                deleteBgColor: this.PersonalInfo.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.PersonalInfo.yScroll,
                xScroll: this.PersonalInfo.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: 0,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: 0, showQuickJumper: true,
                    position: [this.PersonalInfo.top, this.PersonalInfo.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow,
            newPersonelType: ""
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
            tableTip: "PersonalInfo",
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showOrHideModalTrainingDelete = () => {
        this.setState({
            deleteModalTraining: !this.state.deleteModalTraining
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalContractDelete = () => {
        this.setState({
            deleteModalContract: !this.state.deleteModalContract
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalExperiencesDelete = () => {
        this.setState({
            deleteModalExperiences: !this.state.deleteModalExperiences
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalEducationDelete = () => {
        this.setState({
            deleteModalEducation: !this.state.deleteModalEducation
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalLanguageSkillsDelete = () => {
        this.setState({
            deleteModalLanguageSkills: !this.state.deleteModalLanguageSkills
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalReferencesDelete = () => {
        this.setState({
            deleteModalReferences: !this.state.deleteModalReferences
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalBankAccountsDelete = () => {
        this.setState({
            deleteModalBankAccounts: !this.state.deleteModalBankAccounts
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    async getLanguageType() {
        const response200 = await handleRequest("GET", "/LanguageType/getAll");
        var filteredData = response200.data.filter(p => p.lang === this.props.i18n.lang && p.showOnLayout === true);
        var flagFilteredData = 0;
        if (filteredData === null || filteredData.length === 0)
            flagFilteredData = 0;
        else
            flagFilteredData = filteredData[0].id;
        const language = {
            form: "personelInfo",
            languageTypeId: flagFilteredData
        };
        const response100 = await handleRequest("POST", "/Language/getFormLanguages", language);
        for (let index = 0; index < response100.data.length; index++) {
            this.setState({
                [response100.data[index].stateId]: response100.data[index].stateValue
            });
        }
    }

    async getCodeAll() {
        const response = await handleRequest("GET", "/Code/getAll");
        if (response.data.length !== 0) {
            const columns = response.data;
            var options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "personelType") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                personelTypes: options
            });
            options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "bloodGroup") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                bloodGroups: options
            });
            options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "maritalStatus") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                maritalStatuses: options
            });
            options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "militaryObligation") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                militaryObligations: options
            });
            options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "gender") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                genders: options
            });
            options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "smokeStatus") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                smokeStatuses: options
            });
            options = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "periodForCalibration") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }
            this.setState({
                contractDurations: options
            });
            if (this.props.location.pathname.split('/')[2] == null) {
                this.setState({
                    hiddenUpdate: true,
                    hiddenSave: false,
                    personalInfoTrainingTable: [],
                    staffTrainingKayit: 0,
                    personalInfoContractTable: [],
                    staffContractKayit: 0,
                    personalInfoExperiencesTable: [],
                    staffExperiencesKayit: 0,
                    personalInfoEducationTable: [],
                    staffEducationKayit: 0,
                    personalInfoLanguageSkillsTable: [],
                    staffLanguageSkillsKayit: 0,
                    personalInfoBankAccountsTable: [],
                    staffBankAccountsKayit: 0,
                    personalInfoReferencesTable: [],
                    staffReferencesKayit: 0,
                    hiddenSavePersonal: false,
                    hiddenUpdatePersonal: true,
                    hiddenSaveContact: false,
                    hiddenUpdateContact: true,
                    hiddenSaveContract: false,
                    hiddenUpdateContract: true,
                });
            }
        }
    }

    async getDepartment() {
        const response3 = await handleRequest("GET", "/Department/getallByCompany");
        if (response3.data.length === 0) {
            this.setState({
                departments: []
            });
        }
        if (response3.data.length !== 0) {
            this.setState({
                departments: response3.data,
            });
            const columns = response3.data;
            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() };
            });
            this.setState({
                departments: options,
            });
        }
    }

    async getDesignation() {
        const response4 = await handleRequest("GET", "/Designation/getallByCompany");
        if (response4.data.length === 0) {
            this.setState({
                designations: []
            });
        }
        if (response4.data.length !== 0) {
            const columns2 = response4.data;
            const options2 = columns2.map(function (row) {
                return { name: row.designationName.toString(), id: row.id.toString() };
            });
            this.setState({
                designations: options2,
            });
        }
    }

    actionTemplateTraining(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalTraining.bind(this)}
                    onEditRow={this.changeButtonUpdateTraining.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateTraining.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalTraining.bind(this)} />
            </React.Fragment>;
        }
    };

    actionTemplateContract(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalContract.bind(this)}
                    onEditRow={this.changeButtonUpdateContract.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateContract.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalContract.bind(this)} />
            </React.Fragment>;
        }
    };

    actionTemplateExperiences(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalExperiences.bind(this)}
                    onEditRow={this.changeButtonUpdateExperiences.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateExperiences.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalExperiences.bind(this)} />
            </React.Fragment>;
        }
    };

    actionTemplateEducation(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalEducation.bind(this)}
                    onEditRow={this.changeButtonUpdateEducation.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateEducation.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalEducation.bind(this)} />
            </React.Fragment>;
        }
    };

    actionTemplateLanguageSkills(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalLanguageSkills.bind(this)}
                    onEditRow={this.changeButtonUpdateLanguageSkills.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateLanguageSkills.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalLanguageSkills.bind(this)} />
            </React.Fragment>;
        }
    };

    actionTemplateBankAccounts(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalBankAccounts.bind(this)}
                    onEditRow={this.changeButtonUpdateBankAccounts.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateBankAccounts.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalBankAccounts.bind(this)} />
            </React.Fragment>;
        }
    };

    actionTemplateReferences(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.changeButtonDeleteModalReferences.bind(this)}
                    onEditRow={this.changeButtonUpdateReferences.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdateReferences.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.changeButtonDeleteModalReferences.bind(this)} />
            </React.Fragment>;
        }
    };

    createNewClientButtonTraining = async () => {
        await this.resetInputsTraining();

        if (this.state.createNewButtonTraining == "Create New") {
            this.setState({
                hiddenDataGirisTraining: false,
                hiddenSaveTraining: false,
                hiddenUpdateTraining: true,
                createNewButtonTraining: "Cancel",
                createNewButtonTrainingVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisTraining: true,
                hiddenSaveTraining: true,
                hiddenUpdateTraining: false,
                createNewButtonTraining: "Create New",
                createNewButtonTrainingVariant: "info",
            });
        }
    }

    createNewClientButtonContract = async () => {
        await this.resetInputsContract();

        if (this.state.createNewButtonContract == "Create New") {
            this.setState({
                hiddenDataGirisContract: false,
                hiddenSaveContract: false,
                hiddenUpdateContract: true,
                createNewButtonContract: "Cancel",
                createNewButtonContractVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisContract: true,
                hiddenSaveContract: true,
                hiddenUpdateContract: false,
                createNewButtonContract: "Create New",
                createNewButtonContractVariant: "info",
            });
        }
    }

    setPersonalSetValue = () => {
        if (this.state.checkPersonalSetValue && this.formRefPersonal.current != null && this.formRefPersonal.current != undefined) {
            this.formRefPersonal.current.setFieldsValue({
                Department: this.state.department,
                Discipline: this.state.discipline,
                Designation: this.state.designation,
                RoleGroup: this.state.roleGroup,
                BudgeNo: this.state.budgeNo,
                Photo: this.state.photoPath,
                CompanyRental: this.state.companyStaff,
                Gender: this.state.genderCheck,
                Nationality: this.state.nationality,
                Name: this.state.name,
                MidName: this.state.midName,
                LastName: this.state.lastName,
                FatherName: this.state.fatherName,
                MotherName: this.state.motherName,
                BirthDate: (this.state.birthDate != null && this.state.birthDate != undefined && this.state.birthDate != "") ? moment(this.state.birthDate, 'YYYY-MM-DD') : null,
                PlaceOfBirth: this.state.placeOfBirth,
                NationalIdIqamaNo: this.state.nationalId,
                ExpiryDate: (this.state.nationalIdExpDate != null && this.state.nationalIdExpDate != undefined && this.state.nationalIdExpDate != "") ? moment(this.state.nationalIdExpDate, 'YYYY-MM-DD') : null,
                DrivingLicence: this.state.drivingLicence,
                DrivingLicenceExpDate: (this.state.drivingLicenceExpDate != null && this.state.drivingLicenceExpDate != undefined && this.state.drivingLicenceExpDate != "") ? moment(this.state.drivingLicenceExpDate, 'YYYY-MM-DD') : null,
                PassportNo: this.state.passportNo,
                PassportExpDate: (this.state.passportExpDate != null && this.state.passportExpDate != undefined && this.state.passportExpDate != "") ? moment(this.state.passportExpDate, 'YYYY-MM-DD') : null,
                BloodGroup: this.state.bloodGroup,
                MaritalStatus: this.state.maritalStatus,
                NumberOfChild: this.state.numberOfChild,
                MilitaryObligation: this.state.militaryObligation,
                SmokingStatus: this.state.smokerCheck,
            });
            this.setState({
                checkPersonalSetValue: false,
            });
        }
    }

    setContactSetValue = () => {
        if (this.state.checkContactSetValue && this.formRefContact.current != null && this.formRefContact.current != undefined) {
            this.formRefContact.current.setFieldsValue({
                ResidenceCountry: this.state.residenceCountry,
                ResidenceCity: this.state.residenceCity,
                ResidenceAddress: this.state.residenceAddress,
                ResidenceCompany: this.state.residenceCompany,
                ResidencePersonelPhone: this.state.residencePersonelPhone,
                ResidenceCompanyEmail: this.state.residenceCompanyEmail,
                ResidencePersonalEmail: this.state.residencePersonalEmail,
                ResidenceEmergencyPhone: this.state.residenceEmergencyPhone,
                ResidenceEmergencyEmail: this.state.residenceEmergencyEmail,
                HomeCountry: this.state.homeCountry,
                HomeCity: this.state.homeCity,
                HomeAddress: this.state.homeAddress,
                HomePersonalPhone: this.state.homePersonalPhone,
                HomeEmergencyPhone: this.state.homeEmergencyPhone,
                HomeEmergencyEmail: this.state.homeEmergencyEmail,
                SocialMedia: this.state.socialMedia,
                SocialAccount: this.state.socialAccount,
            });
            this.setState({
                checkContactSetValue: false,
            });
        }
    }

    resetInputsTraining = () => {
        this.formRefTraining.current.setFieldsValue({
            NameOfTraining: "",
            DateOfTraining: "",
            Duration: "",
            Organizer: "",
            CertificateValidity: "",
            AttachTrainingCertificate: "",
        });

        this.setState({
            selectedStaffTraining: [],
            nameOfTraining: "",
            dateOfTrainingBsl: "",
            dateOfTrainingBslAck: "",
            dateOfTrainingBts: "",
            dateOfTrainingBtsAck: "",
            durationTraining: "",
            organizer: "",
            certificateValidity: "",
            attachTrainingCertificate: "",

            hiddenSaveTraining: false,
            hiddenUpdateTraining: true,
            hiddenDataGirisTraining: true,
        });
    }

    resetInputsContract = () => {
        this.formRefContract.current.setFieldsValue({
            ContractDate: "",
            ContractDuration: "",
            AnnualVacationDays: "",
            AnnualVacationPeriod: "",
            AnnualVacationPeriodType: "",
            BasicSalary: "",
            HomeAllowance: "",
            TransportationAllowance: "",
            FoodAllowance: "",
            OtherPayments: "",
        });

        this.setState({
            selectedStaffContract: [],
            contractDate: "",
            contractDuration: "",
            annualVacationDays: "",
            annualVacationPeriod: "",
            annualVacationPeriodType: "",
            basicSalary: "",
            homeAllowance: "",
            transportationAllowance: "",
            foodAllowance: "",
            otherPayments: "",

            hiddenSaveContract: false,
            hiddenUpdateContract: true,
            hiddenDataGirisContract: true,
        });
    }

    createNewClientButtonExperiences = async () => {
        await this.resetInputsExperiences();

        if (this.state.createNewButtonExperiences == "Create New") {
            this.setState({
                hiddenDataGirisExperiences: false,
                hiddenSaveExperiences: false,
                hiddenUpdateExperiences: true,
                createNewButtonExperiences: "Cancel",
                createNewButtonExperiencesVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisExperiences: true,
                hiddenSaveExperiences: true,
                hiddenUpdateExperiences: false,
                createNewButtonExperiences: "Create New",
                createNewButtonExperiencesVariant: "info",
            });
        }
    }

    resetInputsExperiences = () => {
        this.formRefExperiences.current.setFieldsValue({
            WorkPeriod: "",
            Company: "",
            Position: "",
            Country: "",
            City: "",
            Project: "",
            Client: "",
            Brief: "",
        });

        this.setState({
            workPeriodStart: "",
            workPeriodStartAck: "",
            workPeriodEnd: "",
            workPeriodEndAck: "",
            expCompany: "",
            expPosition: "",
            expCountry: "",
            expCity: "",
            expProject: "",
            expClient: "",
            expBrief: "",
            approvalOfClientCheck: false,
            approvalOfClient: "",
            AttachApproveFileUpload: [],

            hiddenSaveExperiences: false,
            hiddenUpdateExperiences: true,
            hiddenDataGirisExperiences: true,
        });
    }

    createNewClientButtonEducation = async () => {
        await this.resetInputsEducation();

        if (this.state.createNewButtonEducation == "Create New") {
            this.setState({
                hiddenDataGirisEducation: false,
                hiddenSaveEducation: false,
                hiddenUpdateEducation: true,
                createNewButtonEducation: "Cancel",
                createNewButtonEducationVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisEducation: true,
                hiddenSaveEducation: true,
                hiddenUpdateEducation: false,
                createNewButtonEducation: "Create New",
                createNewButtonEducationVariant: "info",
            });
        }
    }

    resetInputsEducation = () => {
        this.formRefEducation.current.setFieldsValue({
            Graduation: "",
            Degree: "",
            NameOfSchool: "",
            EduCountry: "",
            DateOfGraduation: "",
        });

        this.setState({
            graduation: "",
            degree: "",
            nameOfSchool: "",
            eduCountry: "",
            dateOfGraduation: "",
            attachGraduationCertificate: "",
            graduationScore: "",
            graduationMaxScore: "",

            hiddenSaveEducation: false,
            hiddenUpdateEducation: true,
            hiddenDataGirisEducation: true,
        });
    }

    createNewClientButtonLanguageSkills = async () => {
        await this.resetInputsLanguageSkills();

        if (this.state.createNewButtonLanguageSkills == "Create New") {
            this.setState({
                hiddenDataGirisLanguageSkills: false,
                hiddenSaveLanguageSkills: false,
                hiddenUpdateLanguageSkills: true,
                createNewButtonLanguageSkills: "Cancel",
                createNewButtonLanguageSkillsVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisLanguageSkills: true,
                hiddenSaveLanguageSkills: true,
                hiddenUpdateLanguageSkills: false,
                createNewButtonLanguageSkills: "Create New",
                createNewButtonLanguageSkillsVariant: "info",
            });
        }
    }

    resetInputsLanguageSkills = () => {
        this.formRefLanguageSkills.current.setFieldsValue({
            Language: "",
            Understanding: "",
            Reading: "",
            Writing: "",
            InternationalLanguageTest: "",
            Score: "",

        });

        this.setState({
            language: "",
            understanding: "",
            reading: "",
            writing: "",
            internationalLanguageTest: "",
            score: "",
            attachLanguageTestResult: "",


            hiddenSaveLanguageSkills: false,
            hiddenUpdateLanguageSkills: true,
            hiddenDataGirisLanguageSkills: true,
        });
    }

    createNewClientButtonBankAccounts = async () => {
        await this.resetInputsBankAccounts();

        if (this.state.createNewButtonBankAccounts == "Create New") {
            this.setState({
                hiddenDataGirisBankAccounts: false,
                hiddenSaveBankAccounts: false,
                hiddenUpdateBankAccounts: true,
                createNewButtonBankAccounts: "Cancel",
                createNewButtonBankAccountsVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisBankAccounts: true,
                hiddenSaveBankAccounts: true,
                hiddenUpdateBankAccounts: false,
                createNewButtonBankAccounts: "Create New",
                createNewButtonBankAccountsVariant: "info",
            });
        }
    }

    resetInputsBankAccounts = () => {
        this.formRefBankAccounts.current.setFieldsValue({
            AccountType: "",
            NameOfBank: "",
            Branch: "",
            AccountNo: "",
            IBANNo: "",
            NameOfBankCredit: "",
            CreditCardNo: "",
            CutOffDate: "",
            PaymentDate: "",

        });

        this.setState({
            accountType: "",
            bankName: "",
            branch: "",
            accountNo: "",
            ibanNo: "",
            cutOffDate: "",
            paymentDate: "",

            hiddenAccountTypeBank: true,
            hiddenAccountTypeCredit: true,

            hiddenSaveBankAccounts: false,
            hiddenUpdateBankAccounts: true,
            hiddenDataGirisBankAccounts: true,
        });
    }

    createNewClientButtonReferences = async () => {
        await this.resetInputsReferences();

        if (this.state.createNewButtonReferences == "Create New") {
            this.setState({
                hiddenDataGirisReferences: false,
                hiddenSaveReferences: false,
                hiddenUpdateReferences: true,
                createNewButtonReferences: "Cancel",
                createNewButtonReferencesVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGirisReferences: true,
                hiddenSaveReferences: true,
                hiddenUpdateReferences: false,
                createNewButtonReferences: "Create New",
                createNewButtonReferencesVariant: "info",
            });
        }
    }

    resetInputsReferences = () => {
        this.formRefReferences.current.setFieldsValue({
            RefName: "",
            RefJobDescription: "",
            RefCompany: "",
            RefCountry: "",
            RefEmail: "",
            RefPhone: "",
            RefRelation: "",
            RefRemarks: "",

        });

        this.setState({
            refName: "",
            refjobDescription: "",
            refCompany: "",
            refCountry: "",
            refEmail: "",
            refPhone: "",
            refRelation: "",
            refRemarks: "",

            hiddenSaveReferences: false,
            hiddenUpdateReferences: true,
            hiddenDataGirisReferences: true,
        });
    }

    addNewPersonelType = async () => {

        const newPersonelType = {
            name: this.state.newPersonelType,
            type: "personelType"
        }

        await handleRequest("POST", "/Code/add", newPersonelType);

        const response3 = await handleRequest("GET", "/Code/getAll");

        if (response3.data.length !== 0) {

            const columns = response3.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "personelType") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                personelTypes: options
            });
        }

        this.showOrHideModal();
    };

    savePersonal = async () => {
        // if (this.state.nationality !== "" && this.state.bloodGroup !== ""
        //     && this.state.maritalStatus !== "" && this.state.militaryObligation !== ""
        //     && this.state.name !== "" && this.state.lastName !== "" && this.state.fatherName !== ""
        //     && this.state.motherName !== "" && this.state.placeOfBirth !== "" && this.state.birthDate !== null && this.state.nationalId !== ""
        //     && this.state.nationalIdExpDate !== null && this.state.drivingLicence !== "" && this.state.drivingLicenceExpDate !== null
        //     && this.state.passportNo !== "" && this.state.passportExpDate !== null && this.state.numberOfChild !== "") {

        var genderValue = "";
        var smokeValue = "";

        if (this.state.genderCheck === true)
            genderValue = this.state.genders[0].id
        else
            genderValue = this.state.genders[1].id

        if (this.state.smokerCheck === true)
            smokeValue = this.state.smokeStatuses[0].id
        else
            smokeValue = this.state.smokeStatuses[1].id

        var residencePersonelPhone = "";
        if (this.state.residencePersonelPhoneDrm) {
            residencePersonelPhone = this.state.residencePersonelPhone;
        }

        var residenceEmergencyPhone = "";
        if (this.state.residenceEmergencyPhoneDrm) {
            residenceEmergencyPhone = this.state.residenceEmergencyPhone;
        }

        var homePersonalPhone = "";
        if (this.state.homePersonalPhoneDrm) {
            homePersonalPhone = this.state.homePersonalPhone;
        }

        var homeEmergencyPhone = "";
        if (this.state.homeEmergencyPhoneDrm) {
            homeEmergencyPhone = this.state.homeEmergencyPhone;
        }

        var refPhone = "";
        if (this.state.refPhoneDrm) {
            refPhone = this.state.refPhone;
        }

        const newPersonel = {
            birthDate: moment(this.state.birthDate, 'DD-MM-YYYY'),
            bloodGroup: this.state.bloodGroupId,
            country: this.state.nationalityId,
            countryName: this.state.nationality,
            drivingExpirityDate: moment(this.state.drivingLicenceExpDate, 'DD-MM-YYYY'),
            drivingLicence: this.state.drivingLicence,
            drivingLicencePath: "None",
            fatherName: this.state.fatherName,
            gender: genderValue,
            lastName: this.state.lastName,
            maritalStatus: this.state.maritalStatusId,
            midName: this.state.midName,
            militaryObligation: this.state.militaryObligationId,
            motherName: this.state.motherName,
            name: this.state.name,
            nationalExpirityDate: moment(this.state.nationalIdExpDate, 'DD-MM-YYYY'),
            nationalId: this.state.nationalId,
            nationalIdPath: "None",
            numberOfChild: this.state.numberOfChild,
            passportExpirityDate: moment(this.state.passportExpDate, 'DD-MM-YYYY'),
            passportNo: this.state.passportNo,
            passportPath: "None",
            photoPath: this.state.photoPath,
            placeOfBirth: this.state.placeOfBirth,
            smokeStatus: smokeValue,
            departmentId: this.state.departmentId,
            designationId: this.state.designationId,
            disciplineId: this.state.disciplineId,
            roleGroupId: this.state.roleGroupId,
            budgeNo: this.state.budgeNo,
            companyStaff: this.state.companyStaff,
            staffType: "1", // this.state.personelTypeId //bu kısım şimdilik 1, personel log sayfasına kadar.
            workingStatus: "Waiting For Start", //bu trailer'ların mobile dashboard'ta kullandığı sayfa için. ("Waiting For Start", "Started", "Ended")
            staffTrainings: this.state.personalInfoTrainingTable,
            staffContracts: this.state.personalInfoContractTable,
            staffExperiencess: this.state.personalInfoExperiencesTable,
            staffEducations: this.state.personalInfoEducationTable,
            staffLanguageSkills: this.state.personalInfoLanguageSkillsTable,
            staffBankAccounts: this.state.personalInfoBankAccountsTable,
            healthDescription: this.state.healthDescription,
            healthDrm: this.state.healthDrm,
            staffReferences: this.state.personalInfoReferencesTable,
            refPhone: refPhone,
            social: this.state.social,
            hobbies: this.state.hobbies,
            comments: this.state.comments,
            attachIqamaNo: this.state.attachIqamaNo,
            attachDrivingLicenceNo: this.state.attachDrivingLicenceNo,
            attachPassportNo: this.state.attachPassportNo,
            socialMediaCode: this.state.socialMediaCode,
            residenceCountry: this.state.residenceCountry,
            residenceCity: this.state.residenceCity,
            residenceAddress: this.state.residenceAddress,
            residenceCompany: this.state.residenceCompany,
            residencePersonelPhone: this.state.residencePersonelPhone,
            residenceCompanyEmail: this.state.residenceCompanyEmail,
            residencePersonalEmail: this.state.residencePersonalEmail,
            residenceEmergencyPhone: this.state.residenceEmergencyPhone,
            residenceEmergencyEmail: this.state.residenceEmergencyEmail,
            homeCountry: this.state.homeCountry,
            homeCity: this.state.homeCity,
            homeAddress: this.state.homeAddress,
            homePersonalPhone: this.state.homePersonalPhone,
            homeEmergencyPhone: this.state.homeEmergencyPhone,
            homeEmergencyEmail: this.state.homeEmergencyEmail,
            socialMedia: this.state.socialMedia,
            socialAccount: this.state.socialAccount,
        }
        console.log("ADD", newPersonel);
        var response = await handleRequest("POST", "/Staff/add", newPersonel);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    redirect: true,
                });
                this.showSuccess();
                this.changeButtonSave();
                //this.restartTable();
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        // } else {
        //     this.showWarning();
        // }
    };

    restartTable = async () => {
        const response = await handleRequest("GET", "/Code/getAll");

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "personelType") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                personelTypes: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "bloodGroup") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                bloodGroups: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "maritalStatus") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                maritalStatuses: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "militaryObligation") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                militaryObligations: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "gender") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                genders: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "smokeStatus") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                smokeStatuses: options
            });
        }
    };

    changeButtonUpdate = (rowData) => {

        var genderValue = false;
        var smokeStatusValue = false;

        if (rowData.genderCode.name === "Female")
            genderValue = true;
        else
            genderValue = false;

        if (rowData.smokeStatus === 25)
            smokeStatusValue = true;
        else
            smokeStatusValue = false;

        this.setState({
            hiddenUpdate: false,
            hiddenSave: true,
            genderCheck: genderValue,
            nationality: rowData.countryName,
            nationalityId: rowData.country,
            personelId: rowData.id,
            name: rowData.name,
            midName: rowData.midName,
            lastName: rowData.lastName,
            fatherName: rowData.fatherName,
            motherName: rowData.motherName,
            birthDate: new Date(rowData.birthDate),
            placeOfBirth: rowData.placeOfBirth,
            nationalId: rowData.nationalId,
            nationalIdExpDate: new Date(rowData.nationalExpirityDate),
            drivingLicence: rowData.drivingLicence,
            drivingLicenceExpDate: new Date(rowData.drivingExpirityDate),
            passportNo: rowData.passportNo,
            passportExpDate: new Date(rowData.passportExpirityDate),
            bloodGroup: rowData.bloodGroupCode.name,
            bloodGroupId: rowData.bloodGroupCode.id,
            maritalStatus: rowData.maritalStatusCode.name,
            maritalStatusId: rowData.maritalStatusCode.id,
            numberOfChild: rowData.numberOfChild,
            militaryObligation: rowData.militaryObligationcode.name,
            militaryObligationId: rowData.militaryObligationcode.id,
            personelType: rowData.staffTypeCode.name,
            personelTypeId: rowData.staffTypeCode.id,
            smokerCheck: smokeStatusValue,
            workingStatus: rowData.workingStatus,
            healthDescription: rowData.healthDescription,
            healthDrm: rowData.healthDrm,
            social: rowData.social,
            hobbies: rowData.hobbies,
            comments: rowData.comments,
            attachIqamaNo: rowData.attachIqamaNo,
            attachDrivingLicenceNo: rowData.attachDrivingLicenceNo,
            attachPassportNo: rowData.attachPassportNo,
        });
    };

    changeButtonUpdateTraining = (rowData) => {
        var dateOfTraining = [];
        if (rowData.dateOfTrainingBsl != null && rowData.dateOfTrainingBsl != undefined && rowData.dateOfTrainingBsl != "" && rowData.dateOfTrainingBts != null && rowData.dateOfTrainingBts != undefined && rowData.dateOfTrainingBts != "") {
            dateOfTraining.push(moment(rowData.dateOfTrainingBsl, 'YYYY-MM-DD'), moment(rowData.dateOfTrainingBts, 'YYYY-MM-DD'));
        }

        this.formRefTraining.current.setFieldsValue({
            NameOfTraining: rowData.nameOfTraining,
            DateOfTraining: dateOfTraining,
            Duration: rowData.durationTraining,
            Organizer: rowData.organizer,
            CertificateValidity: rowData.certificateValidity,
            AttachTrainingCertificate: rowData.attachTrainingCertificate,
        });

        this.setState({
            selectedStaffTraining: rowData,
            nameOfTraining: rowData.nameOfTraining,
            dateOfTrainingBsl: rowData.dateOfTrainingBsl,
            dateOfTrainingBslAck: rowData.dateOfTrainingBslAck,
            dateOfTrainingBts: rowData.dateOfTrainingBts,
            dateOfTrainingBtsAck: rowData.dateOfTrainingBtsAck,
            durationTraining: rowData.durationTraining,
            organizer: rowData.organizer,
            certificateValidity: rowData.certificateValidity,
            attachTrainingCertificate: rowData.attachTrainingCertificate,

            hiddenSaveTraining: true,
            hiddenUpdateTraining: false,
            hiddenDataGirisTraining: false,
            createNewButtonTraining: "Cancel",
            createNewButtonTrainingVariant: "danger",
        });
    };

    changeButtonDeleteModalTraining = (row) => {
        this.setState({
            deleteModalTraining: true,
            selectedStaffTraining: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdateContract = (rowData) => {
        console.log("RRR", rowData);
        var annualVacationPeriodType = "";
        if (rowData.annualVacationPeriodType == 1) {
            annualVacationPeriodType = "Year";
        } else if (rowData.annualVacationPeriodType == 2) {
            annualVacationPeriodType = "Month";
        }
        this.formRefContract.current.setFieldsValue({
            ContractDate: Boolean(rowData.contractDate) ? moment(rowData.contractDate) : null,
            ContractDuration: rowData.contractDuration,
            AnnualVacationDays: rowData.annualVacationDays,
            AnnualVacationPeriod: rowData.annualVacationPeriod,
            AnnualVacationPeriodType: annualVacationPeriodType,
            BasicSalary: rowData.basicSalary,
            HomeAllowance: rowData.homeAllowance,
            TransportationAllowance: rowData.transportationAllowance,
            FoodAllowance: rowData.foodAllowance,
            OtherPayments: rowData.otherPayments,
        });

        this.setState({
            selectedStaffContract: rowData,
            contractDate: Boolean(rowData.contractDate) ? moment(rowData.contractDate) : null,
            contractDuration: rowData.contractDuration,
            annualVacationDays: rowData.annualVacationDays,
            annualVacationPeriod: rowData.annualVacationPeriod,
            annualVacationPeriodType: rowData.annualVacationPeriodType,
            basicSalary: rowData.basicSalary,
            homeAllowance: rowData.homeAllowance,
            transportationAllowance: rowData.transportationAllowance,
            foodAllowance: rowData.foodAllowance,
            otherPayments: rowData.otherPayments,

            hiddenSaveContract: true,
            hiddenUpdateContract: false,
            hiddenDataGirisContract: false,
            createNewButtonContract: "Cancel",
            createNewButtonContractVariant: "danger",
        });
    };

    changeButtonDeleteModalContract = (row) => {
        console.log("RRR", row);
        this.setState({
            deleteModalContract: true,
            selectedStaffContract: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    changeButtonUpdateExperiences = (rowData) => {
        var workPeriod = [];
        if (rowData.workPeriodStart != null && rowData.workPeriodStart != undefined && rowData.workPeriodStart != "" && rowData.workPeriodEnd != null && rowData.workPeriodEnd != undefined && rowData.workPeriodEnd != "") {
            workPeriod.push(moment(rowData.workPeriodStart, 'YYYY-MM-DD'), moment(rowData.workPeriodEnd, 'YYYY-MM-DD'));
        }
        this.formRefExperiences.current.setFieldsValue({
            WorkPeriod: workPeriod,
            Company: rowData.expCompany,
            Position: rowData.expPosition,
            Country: rowData.expCountry,
            City: rowData.expCity,
            Project: rowData.expProject,
            Client: rowData.expClient,
            Brief: rowData.expBrief,
        });

        this.setState({
            selectedStaffExperiences: rowData,
            workPeriodStart: rowData.workPeriodStart,
            workPeriodStartAck: rowData.workPeriodStartAck,
            workPeriodEnd: rowData.workPeriodEnd,
            workPeriodEndAck: rowData.workPeriodEndAck,
            expCompany: rowData.expCompany,
            expPosition: rowData.expPosition,
            expCountry: rowData.expCountry,
            expCity: rowData.expCity,
            expProject: rowData.expProject,
            expClient: rowData.expClient,
            expBrief: rowData.expBrief,
            approvalOfClientCheck: rowData.approvalOfClientCheck,
            hiddenApprovalOfClient: !rowData.approvalOfClientCheck,
            approvalOfClient: rowData.approvalOfClient,

            hiddenSaveExperiences: true,
            hiddenUpdateExperiences: false,
            hiddenDataGirisExperiences: false,
            createNewButtonExperiences: "Cancel",
            createNewButtonExperiencesVariant: "danger",
        });
    };

    changeButtonDeleteModalExperiences = (row) => {
        this.setState({
            deleteModalExperiences: true,
            selectedStaffExperiences: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdateEducation = (rowData) => {
        this.formRefEducation.current.setFieldsValue({
            Graduation: Graduation(rowData.graduation),
            Degree: Degree(rowData.degree),
            NameOfSchool: rowData.nameOfSchool,
            EduCountry: rowData.eduCountry,
            DateOfGraduation: (rowData.dateOfGraduation != null && rowData.dateOfGraduation != undefined && rowData.dateOfGraduation != "") && moment(rowData.dateOfGraduation, 'YYYY-MM-DD'),
        });

        this.setState({
            selectedStaffEducation: rowData,
            graduation: rowData.graduation,
            degree: rowData.degree,
            nameOfSchool: rowData.nameOfSchool,
            eduCountry: rowData.eduCountry,
            dateOfGraduation: moment(rowData.dateOfGraduation, "DD-MM-YYYY"),
            dateOfGraduationAck: moment(rowData.dateOfGraduation).format("DD-MM-YYYY"),
            attachGraduationCertificate: rowData.attachGraduationCertificate,
            graduationScore: rowData.graduationScore,
            graduationMaxScore: rowData.graduationMaxScore,


            hiddenSaveEducation: true,
            hiddenUpdateEducation: false,
            hiddenDataGirisEducation: false,
            createNewButtonEducation: "Cancel",
            createNewButtonEducationVariant: "danger",
        });
    };

    changeButtonDeleteModalEducation = (row) => {
        this.setState({
            deleteModalEducation: true,
            selectedStaffEducation: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdateLanguageSkills = (rowData) => {
        this.formRefLanguageSkills.current.setFieldsValue({
            Language: Language(rowData.language),
            Understanding: LanguageLevel(rowData.understanding),
            Reading: LanguageLevel(rowData.reading),
            Writing: LanguageLevel(rowData.writing),
            InternationalLanguageTest: LanguageTest(rowData.internationalLanguageTest),
            Score: rowData.score,
        });

        this.setState({
            selectedStaffLanguageSkills: rowData,
            language: rowData.language,
            understanding: rowData.understanding,
            reading: rowData.reading,
            writing: rowData.writing,
            internationalLanguageTest: rowData.internationalLanguageTest,
            score: rowData.score,
            attachLanguageTestResult: rowData.attachLanguageTestResult,


            hiddenSaveLanguageSkills: true,
            hiddenUpdateLanguageSkills: false,
            hiddenDataGirisLanguageSkills: false,
            createNewButtonLanguageSkills: "Cancel",
            createNewButtonLanguageSkillsVariant: "danger",
        });
    };

    changeButtonDeleteModalLanguageSkills = (row) => {
        this.setState({
            deleteModalLanguageSkills: true,
            selectedStaffLanguageSkills: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdateBankAccounts = (rowData) => {
        this.formRefBankAccounts.current.setFieldsValue({
            AccountType: rowData.accountType,
            NameOfBank: BankName(rowData.bankName),
            Branch: Branch(rowData.branch),
            AccountNo: rowData.accountNo,
            IBANNo: rowData.ibanNo,
            NameOfBankCredit: BankName(rowData.bankName),
            CreditCardNo: rowData.accountNo,
            CutOffDate: (rowData.cutOffDate != null && rowData.cutOffDate != undefined && rowData.cutOffDate != "") && moment(rowData.cutOffDate, 'YYYY-MM-DD'),
            PaymentDate: (rowData.paymentDate != null && rowData.paymentDate != undefined && rowData.paymentDate != "") && moment(rowData.paymentDate, 'YYYY-MM-DD'),
        });

        var hiddenAccountTypeBank = true;
        var hiddenAccountTypeCredit = true;
        if (rowData.accountType == "Debit" || rowData.accountType == "Loan") {
            hiddenAccountTypeBank = false;
        } else if (rowData.accountType == "Credit Card") {
            hiddenAccountTypeCredit = false;
        }

        this.setState({
            selectedStaffBankAccounts: rowData,
            accountType: rowData.accountType,
            bankName: rowData.bankName,
            branch: rowData.branch,
            accountNo: rowData.accountNo,
            ibanNo: rowData.ibanNo,
            cutOffDate: (rowData.cutOffDate != null && rowData.cutOffDate != undefined && rowData.cutOffDate != "") ? moment(rowData.cutOffDate, "DD-MM-YYYY") : "",
            cutOffDateAck: (rowData.cutOffDate != null && rowData.cutOffDate != undefined && rowData.cutOffDate != "") ? rowData.cutOffDate : "",
            paymentDate: (rowData.paymentDate != null && rowData.paymentDate != undefined && rowData.paymentDate != "") ? moment(rowData.paymentDate, "DD-MM-YYYY") : "",
            paymentDateAck: (rowData.paymentDate != null && rowData.paymentDate != undefined && rowData.paymentDate != "") ? rowData.paymentDate : "",

            hiddenAccountTypeBank: hiddenAccountTypeBank,
            hiddenAccountTypeCredit: hiddenAccountTypeCredit,
            hiddenSaveBankAccounts: true,
            hiddenUpdateBankAccounts: false,
            hiddenDataGirisBankAccounts: false,
            createNewButtonBankAccounts: "Cancel",
            createNewButtonBankAccountsVariant: "danger",
        });
    };

    changeButtonDeleteModalBankAccounts = (row) => {
        this.setState({
            deleteModalBankAccounts: true,
            selectedStaffBankAccounts: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdateReferences = (rowData) => {
        this.formRefReferences.current.setFieldsValue({
            RefName: rowData.refName,
            RefJobDescription: rowData.refjobDescription,
            RefCompany: rowData.refCompany,
            RefCountry: rowData.refCountry,
            RefEmail: rowData.refEmail,
            RefPhone: rowData.refPhone,
            RefRelation: rowData.refRelation,
            RefRemarks: rowData.refRemarks,

        });

        this.setState({
            selectedStaffReferences: rowData,
            refName: rowData.refName,
            refjobDescription: rowData.refjobDescription,
            refCompany: rowData.refCompany,
            refCountry: rowData.refCountry,
            refEmail: rowData.refEmail,
            refPhone: rowData.refPhone,
            refRelation: rowData.refRelation,
            refRemarks: rowData.refRemarks,

            hiddenSaveReferences: true,
            hiddenUpdateReferences: false,
            hiddenDataGirisReferences: false,
            createNewButtonReferences: "Cancel",
            createNewButtonReferencesVariant: "danger",
        });
    };

    changeButtonDeleteModalReferences = (row) => {
        this.setState({
            deleteModalReferences: true,
            selectedStaffReferences: row
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    updatePersonal = async () => {
        // if (this.state.nationality !== "" && this.state.bloodGroup !== ""
        //     && this.state.maritalStatus !== "" && this.state.militaryObligation !== ""
        //     && this.state.name !== "" && this.state.lastName !== "" && this.state.fatherName !== ""
        //     && this.state.motherName !== "" && this.state.placeOfBirth !== "" && this.state.birthDate !== null && this.state.nationalId !== ""
        //     && this.state.nationalIdExpDate !== null && this.state.drivingLicence !== "" && this.state.drivingLicenceExpDate !== null
        //     && this.state.passportNo !== "" && this.state.passportExpDate !== null && this.state.numberOfChild !== "") {

        var genderValue = "";
        var smokeValue = "";

        if (this.state.genderCheck === true)
            genderValue = this.state.genders[0].id
        else
            genderValue = this.state.genders[1].id

        if (this.state.smokerCheck === true)
            smokeValue = this.state.smokeStatuses[0].id
        else
            smokeValue = this.state.smokeStatuses[1].id

        var residencePersonelPhone = "";
        if (this.state.residencePersonelPhoneDrm) {
            residencePersonelPhone = this.state.residencePersonelPhone;
        }

        var residenceEmergencyPhone = "";
        if (this.state.residenceEmergencyPhoneDrm) {
            residenceEmergencyPhone = this.state.residenceEmergencyPhone;
        }

        var homePersonalPhone = "";
        if (this.state.homePersonalPhoneDrm) {
            homePersonalPhone = this.state.homePersonalPhone;
        }

        var homeEmergencyPhone = "";
        if (this.state.homeEmergencyPhoneDrm) {
            homeEmergencyPhone = this.state.homeEmergencyPhone;
        }

        var refPhone = "";
        if (this.state.refPhoneDrm) {
            refPhone = this.state.refPhone;
        }

        const updatedPersonel = {
            id: this.state.personelId,
            birthDate: moment(this.state.birthDate, 'DD-MM-YYYY'),
            bloodGroup: this.state.bloodGroupId,
            country: this.state.nationalityId,
            countryName: this.state.nationality,
            drivingExpirityDate: moment(this.state.drivingLicenceExpDate, 'DD-MM-YYYY'),
            drivingLicence: this.state.drivingLicence,
            drivingLicencePath: "None",
            fatherName: this.state.fatherName,
            gender: genderValue,
            lastName: this.state.lastName,
            maritalStatus: this.state.maritalStatusId,
            midName: this.state.midName,
            militaryObligation: this.state.militaryObligationId,
            motherName: this.state.motherName,
            name: this.state.name,
            nationalExpirityDate: moment(this.state.nationalIdExpDate, 'DD-MM-YYYY'),
            nationalId: this.state.nationalId,
            nationalIdPath: "None",
            numberOfChild: this.state.numberOfChild,
            passportExpirityDate: moment(this.state.passportExpDate, 'DD-MM-YYYY'),
            passportNo: this.state.passportNo,
            passportPath: "None",
            photoPath: this.state.photoPath,
            placeOfBirth: this.state.placeOfBirth,
            smokeStatus: smokeValue,
            departmentId: this.state.departmentId,
            designationId: this.state.designationId,
            disciplineId: this.state.disciplineId,
            roleGroupId: this.state.roleGroupId,
            budgeNo: this.state.budgeNo,
            companyStaff: this.state.companyStaff,
            staffType: "1", // this.state.personelTypeId //bu kısım şimdilik 1, personel log sayfasına kadar.
            workingStatus: "Waiting For Start", //bu trailer'ların mobile dashboard'ta kullandığı sayfa için. ("Waiting For Start", "Started", "Ended")
            staffTrainings: this.state.personalInfoTrainingTable,
            staffContracts: this.state.personalInfoContractTable,
            staffExperiencess: this.state.personalInfoExperiencesTable,
            staffEducations: this.state.personalInfoEducationTable,
            staffLanguageSkills: this.state.personalInfoLanguageSkillsTable,
            staffBankAccounts: this.state.personalInfoBankAccountsTable,
            healthDescription: this.state.healthDescription,
            healthDrm: this.state.healthDrm,
            staffReferences: this.state.personalInfoReferencesTable,
            refPhone: refPhone,
            social: this.state.social,
            hobbies: this.state.hobbies,
            comments: this.state.comments,
            attachIqamaNo: this.state.attachIqamaNo,
            attachDrivingLicenceNo: this.state.attachDrivingLicenceNo,
            attachPassportNo: this.state.attachPassportNo,
            socialMediaCode: this.state.socialMediaCode,
            residenceCountry: this.state.residenceCountry,
            residenceCity: this.state.residenceCity,
            residenceAddress: this.state.residenceAddress,
            residenceCompany: this.state.residenceCompany,
            residencePersonelPhone: this.state.residencePersonelPhone,
            residenceCompanyEmail: this.state.residenceCompanyEmail,
            residencePersonalEmail: this.state.residencePersonalEmail,
            residenceEmergencyPhone: this.state.residenceEmergencyPhone,
            residenceEmergencyEmail: this.state.residenceEmergencyEmail,
            homeCountry: this.state.homeCountry,
            homeCity: this.state.homeCity,
            homeAddress: this.state.homeAddress,
            homePersonalPhone: this.state.homePersonalPhone,
            homeEmergencyPhone: this.state.homeEmergencyPhone,
            homeEmergencyEmail: this.state.homeEmergencyEmail,
            socialMedia: this.state.socialMedia,
            socialAccount: this.state.socialAccount,
        }
        console.log("UPD", updatedPersonel);
        var response = await handleRequest("POST", "/Staff/update", updatedPersonel);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    redirect: true,
                });
                this.showSuccess();
                this.changeButtonSave();
                // this.restartTable(); 
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        // } else {
        //     this.showWarning();
        // }
    };

    changeButtonSave = () => {
        this.setState({
            hiddenUpdate: true,
            hiddenSave: false,
            genderCheck: true,
            personelType: "",
            nationality: "",
            name: "",
            midName: "",
            lastName: "",
            fatherName: "",
            motherName: "",
            birthDate: null,
            placeOfBirth: "",
            nationalId: "",
            nationalIdExpDate: null,
            drivingLicence: "",
            drivingLicenceExpDate: null,
            passportNo: "",
            passportExpDate: null,
            bloodGroup: "",
            maritalStatus: "",
            numberOfChild: "",
            militaryObligation: "",
            smokerCheck: true,
            residenceCountry: "",
            residenceCity: "",
            residenceAddress: "",
            residenceCompany: "",
            residencePersonelPhone: "",
            residenceCompanyEmail: "",
            residencePersonalEmail: "",
            residenceEmergencyPhone: "",
            residenceEmergencyEmail: "",
            homeAddress: "",
            homePersonalPhone: "",
            homeEmergencyPhone: "",
            homeEmergencyEmail: "",
            homeCountry: "",
            homeCity: "",
            socialMedia: "",
            socialAccount: "",
            departmentId: 0,
            department: "",
            disciplineId: 0,
            discipline: "",
            roleGroupId: 0,
            roleGroup: "",
            designationId: 0,
            designation: "",
            refPhone: "",
            healthDescription: "",
            healthDrm: false,
            social: "",
            hobbies: "",
            comments: "",
            attachIqamaNo: "",
            attachDrivingLicenceNo: "",
            attachPassportNo: "",
        });

    };

    changeButtonCancel = () => {
        window.location.href = "/PersonelLog";
    };

    changeButtonCancelTraining = () => {
        this.formRefTraining.current.setFieldsValue({
            NameOfTraining: "",
            DateOfTraining: "",
            Duration: "",
            Organizer: "",
            CertificateValidity: "",
            AttachTrainingCertificate: "",
        });

        this.setState({
            nameOfTraining: "",
            dateOfTrainingBsl: "",
            dateOfTrainingBslAck: "",
            dateOfTrainingBts: "",
            dateOfTrainingBtsAck: "",
            durationTraining: "",
            organizer: "",
            certificateValidity: "",
            attachTrainingCertificate: "",

            hiddenSaveTraining: false,
            hiddenUpdateTraining: true,
            hiddenDataGirisTraining: true,
            createNewButtonTraining: "Create New",
            createNewButtonTrainingVariant: "info",
        });
    };

    changeButtonCancelContract = () => {
        this.formRefContract.current.setFieldsValue({
            NameOfContract: "",
            DateOfContract: "",
            Duration: "",
            Organizer: "",
            CertificateValidity: "",
            AttachContractCertificate: "",
        });

        this.setState({
            nameOfContract: "",
            dateOfContractBsl: "",
            dateOfContractBslAck: "",
            dateOfContractBts: "",
            dateOfContractBtsAck: "",
            durationContract: "",
            organizer: "",
            certificateValidity: "",
            attachContractCertificate: "",

            hiddenSaveContract: false,
            hiddenUpdateContract: true,
            hiddenDataGirisContract: true,
            createNewButtonContract: "Create New",
            createNewButtonContractVariant: "info",
        });
    };

    changeButtonCancelExperiences = () => {
        this.formRefExperiences.current.setFieldsValue({
            WorkPeriod: "",
            Company: "",
            Position: "",
            Country: "",
            City: "",
            Project: "",
            Client: "",
            Brief: "",

        });

        this.setState({
            workPeriodStart: "",
            workPeriodStartAck: "",
            workPeriodEnd: "",
            workPeriodEndAck: "",
            expCompany: "",
            expPosition: "",
            expCountry: "",
            expCity: "",
            expProject: "",
            expClient: "",
            expBrief: "",
            approvalOfClientCheck: false,
            approvalOfClient: "",
            AttachApproveFileUpload: [],

            hiddenSaveExperiences: false,
            hiddenUpdateExperiences: true,
            hiddenDataGirisExperiences: true,
            createNewButtonExperiences: "Create New",
            createNewButtonExperiencesVariant: "info",
        });
    };

    changeButtonCancelEducation = () => {
        this.formRefEducation.current.setFieldsValue({
            Graduation: "",
            Degree: "",
            NameOfSchool: "",
            EduCountry: "",
            DateOfGraduation: "",

        });

        this.setState({
            graduation: "",
            degree: "",
            nameOfSchool: "",
            eduCountry: "",
            dateOfGraduation: "",
            attachGraduationCertificate: "",
            graduationScore: "",
            graduationMaxScore: "",

            hiddenSaveEducation: false,
            hiddenUpdateEducation: true,
            hiddenDataGirisEducation: true,
            createNewButtonEducation: "Create New",
            createNewButtonEducationVariant: "info",
        });
    };

    changeButtonCancelLanguageSkills = () => {
        this.formRefLanguageSkills.current.setFieldsValue({
            Language: "",
            Understanding: "",
            Reading: "",
            Writing: "",
            InternationalLanguageTest: "",
            Score: "",

        });

        this.setState({
            language: "",
            understanding: "",
            reading: "",
            writing: "",
            internationalLanguageTest: "",
            score: "",
            attachLanguageTestResult: "",

            hiddenSaveLanguageSkills: false,
            hiddenUpdateLanguageSkills: true,
            hiddenDataGirisLanguageSkills: true,
            createNewButtonLanguageSkills: "Create New",
            createNewButtonLanguageSkillsVariant: "info",
        });
    };

    changeButtonCancelBankAccounts = () => {
        this.formRefBankAccounts.current.setFieldsValue({
            AccountType: "",
            NameOfBank: "",
            Branch: "",
            AccountNo: "",
            IBANNo: "",
            NameOfBankCredit: "",
            CreditCardNo: "",
            CutOffDate: "",
            PaymentDate: "",

        });

        this.setState({
            accountType: "",
            bankName: "",
            branch: "",
            accountNo: "",
            ibanNo: "",
            cutOffDate: "",
            paymentDate: "",

            hiddenSaveBankAccounts: false,
            hiddenUpdateBankAccounts: true,
            hiddenDataGirisBankAccounts: true,
            createNewButtonBankAccounts: "Create New",
            createNewButtonBankAccountsVariant: "info",
        });
    };

    changeButtonCancelReferences = () => {
        this.formRefReferences.current.setFieldsValue({
            RefName: "",
            RefJobDescription: "",
            RefCompany: "",
            RefCountry: "",
            RefEmail: "",
            RefPhone: "",
            RefRelation: "",
            RefRemarks: "",

        });

        this.setState({
            refName: "",
            refjobDescription: "",
            refCompany: "",
            refCountry: "",
            refEmail: "",
            refPhone: "",
            refRelation: "",
            refRemarks: "",

            hiddenSaveReferences: false,
            hiddenUpdateReferences: true,
            hiddenDataGirisReferences: true,
            createNewButtonReferences: "Create New",
            createNewButtonReferencesVariant: "info",
        });
    };

    handleChangeDepartment = async (e) => {
        // var idValue;
        // for (var z = 0; z < this.state.departments.length; z++) {
        //     if (this.state.departments[z].name === e) {
        //         idValue = this.state.departments[z].id
        //     }
        // }

        this.formRefPersonal.current.setFieldsValue({
            Discipline: "",
        });

        this.setState({
            department: e,
            departmentId: e,
            discipline: "",
        });

        const response = await handleRequest("GET", "/Department/" + e);

        const disciplineData = response.data.disciplineDepartmentList;

        if (disciplineData != null && disciplineData != undefined && disciplineData.length === 0) {
            this.setState({
                disciplines: []
            });
        }

        if (disciplineData != null && disciplineData != undefined && disciplineData.length !== 0) {

            const columns = disciplineData;

            const options = columns.map(function (row) {
                return { name: row.discipline.disciplineName.toString(), id: row.discipline.id.toString() }
            })

            this.setState({
                disciplines: options,
            });
        }
    };

    handleChangeRoleGroup = e => {
        // var idValue;

        // for (var z = 0; z < this.state.roleGroups.length; z++) {
        //     if (this.state.roleGroups[z].name === e) {
        //         idValue = this.state.roleGroups[z].id
        //     }
        // }

        this.setState({
            roleGroup: e,
            roleGroupId: e
        });
    };

    handleChangeDesignation = async (e) => {
        // var idValue;

        // for (var z = 0; z < this.state.designations.length; z++) {
        //     if (this.state.designations[z].name === e) {
        //         idValue = this.state.designations[z].id
        //     }
        // }

        this.formRefPersonal.current.setFieldsValue({
            RoleGroup: "",
        });

        this.setState({
            designation: e,
            designationId: e,
            roleGroup: "",
        });

        const response = await handleRequest("GET", "/Designation/" + e);

        const roleGroupData = response.data.roleGroup;

        if (roleGroupData != null && roleGroupData != undefined && roleGroupData.length === 0) {
            this.setState({
                roleGroups: []
            });
        }

        if (roleGroupData != null && roleGroupData != undefined && roleGroupData.length !== 0) {

            const columns = roleGroupData;

            var options = [];
            options.push({ name: columns.roleName.toString(), id: columns.id.toString() })

            this.setState({
                roleGroups: options,
            });
        }
    };

    handleChangeDiscipline = e => {
        // var idValue;

        // for (var z = 0; z < this.state.disciplines.length; z++) {
        //     if (this.state.disciplines[z].name === e) {
        //         idValue = this.state.disciplines[z].id
        //     }
        // }

        this.setState({
            discipline: e,
            disciplineId: e
        });
    };

    handleChangeContractDuration = e => {
        // var idValue;

        // for (var z = 0; z < this.state.contractDurations.length; z++) {
        //     if (this.state.contractDurations[z].name === e) {
        //         idValue = this.state.contractDurations[z].id
        //     }
        // }

        this.setState({
            contractDuration: e,
            contractDurationId: e
        });
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            this.showWarning('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            this.showWarning('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    photoPath: info.file.response[0]
                })
            }
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    photoImageUrl: imageUrl,
                    loading: false,
                }),
            );
        }
    };

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);

        if (this.state.redirect) {
            this.setState({
                redirect: false,
            });
            window.location.href = "/PersonelLog";
        }
    }

    disabledDate(current) {
        return moment().endOf('day') < current;
    }

    onChangeBirthDate = (value, dateString) => {
        var hiddenBirthDate = true;
        if (value != null && value != undefined && value._d != null && value._d != undefined) {
            var todayDate = new Date();
            var todayYear = todayDate.getFullYear();
            var todayMonth = todayDate.getMonth();
            var todayDay = todayDate.getDate();

            var birthDate = value._d;
            var birthYear = birthDate.getFullYear();
            var birthMonth = birthDate.getMonth();
            var birthDay = birthDate.getDate();

            var age = todayYear - birthYear;
            if (todayMonth < birthMonth - 1) {
                age--;
            }
            if (birthMonth - 1 == todayMonth && todayDay < birthDay) {
                age--;
            }
            if (age < 18) {
                hiddenBirthDate = false;
            }
        }
        this.setState({
            birthDate: dateString,
            hiddenBirthDate: hiddenBirthDate,
        });
    }

    onChangeNationalIdExpDate = (value, dateString) => {
        var hiddenNationalIdExpDate = true;
        if (moment().startOf('day') > value) {
            hiddenNationalIdExpDate = false;
        }

        this.setState({
            nationalIdExpDate: dateString,
            hiddenNationalIdExpDate: hiddenNationalIdExpDate,
        });
    }

    onChangeDrivingLicenceExpDate = (value, dateString) => {
        var hiddenDrivingLicenceExpDate = true;
        if (moment().startOf('day') > value) {
            hiddenDrivingLicenceExpDate = false;
        }
        this.setState({
            drivingLicenceExpDate: dateString,
            hiddenDrivingLicenceExpDate: hiddenDrivingLicenceExpDate,
        });
    }

    onChangePassportExpDate = (value, dateString) => {
        var hiddenPassportExpDate = true;
        if (moment().startOf('day') > value) {
            hiddenPassportExpDate = false;
        }
        this.setState({
            passportExpDate: dateString,
            hiddenPassportExpDate: hiddenPassportExpDate,
        });
    }

    handleChangePersonalInfoSocialMedia = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.PersonalInfoSocialMedias.length; z++) {
            if (this.state.PersonalInfoSocialMedias[z].id == e) {
                deger = this.state.PersonalInfoSocialMedias[z].name;
            }
        }
        this.setState({
            socialMediaAck: deger,
            socialMedia: e,
        });
    };

    addPersonalInfoSocialMedias = async () => {
        const { itemPersonalInfoSocialMedias } = this.state;
        if (itemPersonalInfoSocialMedias != null && itemPersonalInfoSocialMedias != "" && itemPersonalInfoSocialMedias != undefined) {
            const newItem = {
                name: itemPersonalInfoSocialMedias,
                type: "PersonalInfoSocialMedia"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemPersonalInfoSocialMedias: '',
            });
            this.getCodesPersonalInfoSocialMedia();
        } else {
            this.showWarning();
        }
    };

    getCodesPersonalInfoSocialMedia = async () => {
        const response = await handleRequest("GET", "/Code/type/PersonalInfoSocialMedia");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                PersonalInfoSocialMedias: response.data
            });
        }
    }

    handleChangePersonalInfoRefRelation = (e) => {
        var deger = "";
        var ack = this.state.PersonalInfoRefRelations.find(p => p.id == e);
        if (ack != null && ack != undefined) {
            deger = ack.name;
        }
        this.setState({
            refRelationAck: deger,
            refRelation: e,
        });
    };

    addPersonalInfoRefRelations = async () => {
        const { itemPersonalInfoRefRelations } = this.state;
        if (itemPersonalInfoRefRelations != null && itemPersonalInfoRefRelations != "" && itemPersonalInfoRefRelations != undefined) {
            const newItem = {
                name: itemPersonalInfoRefRelations,
                type: "PersonalInfoRefRelation"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemPersonalInfoRefRelations: '',
            });
            this.getCodesPersonalInfoRefRelation();
        } else {
            this.showWarning();
        }
    };

    getCodesPersonalInfoRefRelation = async () => {
        const response = await handleRequest("GET", "/Code/type/PersonalInfoRefRelation");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                PersonalInfoRefRelations: response.data
            });
        }
    }

    selectResidenceCountry(val) {
        this.setState({ residenceCountry: val });
    }

    selectResidenceExpCountry(val) {
        this.setState({ expCountry: val });
    }

    selectResidenceEduCountry(val) {
        this.setState({ eduCountry: val });
    }

    selectRefCountry(val) {
        this.setState({ refCountry: val });
    }

    selectResidenceRegion(val) {
        this.setState({ residenceCity: val });
    }

    selectResidenceExRegion(val) {
        this.setState({ expCity: val });
    }

    selectHomeCountry(val) {
        this.setState({ homeCountry: val });
    }

    selectHomeRegion(val) {
        this.setState({ homeCity: val });
    }

    handleChangeCompanyEmail = e => {
        var hiddenResidenceCompanyEmail = true;
        if (e.target.value != null && e.target.value != undefined && e.target.value != "") {
            hiddenResidenceCompanyEmail = EmailValidator.validate(e.target.value);
        }
        this.setState({
            residenceCompanyEmail: e.target.value,
            hiddenResidenceCompanyEmail: hiddenResidenceCompanyEmail,
        })
    };

    handleChangeRefEmail = e => {
        var hiddenRefEmail = true;
        if (e.target.value != null && e.target.value != undefined && e.target.value != "") {
            hiddenRefEmail = EmailValidator.validate(e.target.value);
        }
        this.setState({
            refEmail: e.target.value,
            hiddenRefEmail: hiddenRefEmail,
        })
    };

    handleChangePersonelEmail = e => {
        var hiddenResidencePersonelEmail = true;
        if (e.target.value != null && e.target.value != undefined && e.target.value != "") {
            hiddenResidencePersonelEmail = EmailValidator.validate(e.target.value);
        }
        this.setState({
            residencePersonalEmail: e.target.value,
            hiddenResidencePersonelEmail: hiddenResidencePersonelEmail,
        })
    };

    handleChangeResidenceEmergencyEmail = e => {
        var hiddenResidenceEmergencyEmail = true;
        if (e.target.value != null && e.target.value != undefined && e.target.value != "") {
            hiddenResidenceEmergencyEmail = EmailValidator.validate(e.target.value);
        }
        this.setState({
            residenceEmergencyEmail: e.target.value,
            hiddenResidenceEmergencyEmail: hiddenResidenceEmergencyEmail,
        })
    };

    handleChangeHomeEmergencyEmail = e => {
        var hiddenHomeEmergencyEmail = true;
        if (e.target.value != null && e.target.value != undefined && e.target.value != "") {
            hiddenHomeEmergencyEmail = EmailValidator.validate(e.target.value);
        }
        this.setState({
            homeEmergencyEmail: e.target.value,
            hiddenHomeEmergencyEmail: hiddenHomeEmergencyEmail,
        })
    };

    onChangeDateTraining = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString[0] !== "" || dateString[1] !== "") {
            this.setState({
                dateOfTrainingBsl: dateString[0],
                dateOfTrainingBts: dateString[1]
            });
        } else {
            this.setState({
                dateOfTrainingBsl: "",
                dateOfTrainingBts: ""
            });
        }
    }

    onChangeDateExperiences = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString[0] !== "" || dateString[1] !== "") {
            this.setState({
                workPeriodStart: dateString[0],
                workPeriodEnd: dateString[1]
            });
        } else {
            this.setState({
                workPeriodStart: "",
                workPeriodEnd: ""
            });
        }
    }

    onChangeDateEducation = async (date, dateString) => {
        this.setState({
            dateOfGraduation: dateString,
        });
    }

    onChangeDateCutOffDate = async (date, dateString) => {
        this.setState({
            cutOffDate: dateString,
        });
    }

    onChangeDatePaymentDate = async (date, dateString) => {
        this.setState({
            paymentDate: dateString,
        });
    }

    showReport = async (fileRealUrl) => {
        try {
            if (fileRealUrl.endsWith('.pdf')) {
                this.setState({
                    pdfFile: fileRealUrl,
                    modalPdfViewer: true,
                });
            } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
                || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
                this.setState({
                    imageUrl: fileRealUrl,
                    modalPhotoShow: true,
                })
            } else {
                window.location.href = fileRealUrl;
            }
        } catch (error) {
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
        }
    };

    addPersonalInfoTraining = async () => {
        await this.setState({
            staffTrainingKayit: this.state.staffTrainingKayit + 1,
        })
        var newStaffTraining = {
            key: this.state.staffTrainingKayit,
            index: this.state.staffTrainingKayit,
            dateOfTrainingBsl: (this.state.dateOfTrainingBsl != null && this.state.dateOfTrainingBsl != undefined && this.state.dateOfTrainingBsl != "") ? moment(this.state.dateOfTrainingBsl, "DD-MM-YYYY") : "",
            dateOfTrainingBslAck: this.state.dateOfTrainingBsl,
            dateOfTrainingBts: (this.state.dateOfTrainingBts != null && this.state.dateOfTrainingBts != undefined && this.state.dateOfTrainingBts != "") ? moment(this.state.dateOfTrainingBts, "DD-MM-YYYY") : "",
            dateOfTrainingBtsAck: this.state.dateOfTrainingBts,

            nameOfTraining: this.state.nameOfTraining,
            durationTraining: this.state.durationTraining,
            organizer: this.state.organizer,
            certificateValidity: this.state.certificateValidity,
            attachTrainingCertificate: this.state.attachTrainingCertificate,
        };

        this.setState(prevState => ({
            personalInfoTrainingTable: [...prevState.personalInfoTrainingTable, newStaffTraining]
        }))

        this.createNewClientButtonTraining();
        this.resetInputsTraining();
    };

    updatePersonalInfoTraining = async () => {
        var PersonalInfoTraining = this.state.personalInfoTrainingTable.find(p => p.key == this.state.selectedStaffTraining.key);

        PersonalInfoTraining.dateOfTrainingBsl = (this.state.dateOfTrainingBsl != null && this.state.dateOfTrainingBsl != undefined && this.state.dateOfTrainingBsl != "") ? moment(this.state.dateOfTrainingBsl, "DD-MM-YYYY") : "";
        PersonalInfoTraining.dateOfTrainingBslAck = this.state.dateOfTrainingBsl;
        PersonalInfoTraining.dateOfTrainingBts = (this.state.dateOfTrainingBts != null && this.state.dateOfTrainingBts != undefined && this.state.dateOfTrainingBts != "") ? moment(this.state.dateOfTrainingBts, "DD-MM-YYYY") : "";
        PersonalInfoTraining.dateOfTrainingBtsAck = this.state.dateOfTrainingBts;

        PersonalInfoTraining.nameOfTraining = this.state.nameOfTraining;
        PersonalInfoTraining.durationTraining = this.state.durationTraining;
        PersonalInfoTraining.organizer = this.state.organizer;
        PersonalInfoTraining.certificateValidity = this.state.certificateValidity;
        PersonalInfoTraining.attachTrainingCertificate = this.state.attachTrainingCertificate;
        this.createNewClientButtonTraining();
        this.resetInputsTraining();
    };

    deleteStaffTrainingOK = async () => {
        if (this.state.selectedStaffTraining != null && this.state.selectedStaffTraining != undefined && this.state.selectedStaffTraining != "" && this.state.selectedStaffTraining.key > 0) {
            var PersonalInfoTraining = this.state.personalInfoTrainingTable.filter(p => p.key != this.state.selectedStaffTraining.key);
            this.setState({
                personalInfoTrainingTable: PersonalInfoTraining,
                deleteModalTraining: false,
            });
        } else {
            this.showWarning();
        }
    };

    addPersonalInfoContract = async () => {
        await this.setState({
            staffContractKayit: this.state.staffContractKayit + 1,
        })
        var newStaffContract = {
            key: this.state.staffContractKayit,
            index: this.state.staffContractKayit,
            contractDate: this.state.contractDate,
            contractDurationText: this.state.contractDurationText,
            contractDuration: this.state.contractDurationId,
            annualVacationDays: this.state.annualVacationDays,
            annualVacationPeriod: this.state.annualVacationPeriod,
            annualVacationPeriodType: this.state.annualVacationPeriodType,
            basicSalary: this.state.basicSalary,
            homeAllowance: this.state.homeAllowance,
            transportationAllowance: this.state.transportationAllowance,
            foodAllowance: this.state.foodAllowance,
            otherPayments: this.state.otherPayments,
        };

        this.setState(prevState => ({
            personalInfoContractTable: [...prevState.personalInfoContractTable, newStaffContract]
        }))

        this.createNewClientButtonContract();
        this.resetInputsContract();
    };

    updatePersonalInfoContract = async () => {
        var PersonalInfoContract = this.state.personalInfoContractTable.find(p => p.key == this.state.selectedStaffContract.key);

        PersonalInfoContract.contractDate = this.state.contractDate;
        PersonalInfoContract.contractDurationText = this.state.contractDurationText;
        PersonalInfoContract.contractDuration = this.state.contractDurationId;
        PersonalInfoContract.annualVacationDays = this.state.annualVacationDays;
        PersonalInfoContract.annualVacationPeriod = this.state.annualVacationPeriod;
        PersonalInfoContract.annualVacationPeriodType = this.state.annualVacationPeriodType;
        PersonalInfoContract.basicSalary = this.state.basicSalary;
        PersonalInfoContract.homeAllowance = this.state.homeAllowance;
        PersonalInfoContract.transportationAllowance = this.state.transportationAllowance;
        PersonalInfoContract.foodAllowance = this.state.foodAllowance;
        PersonalInfoContract.otherPayments = this.state.otherPayments;

        this.createNewClientButtonContract();
        this.resetInputsContract();
    };

    deleteStaffContractOK = async () => {
        if (this.state.selectedStaffContract != null && this.state.selectedStaffContract != undefined && this.state.selectedStaffContract != "" && this.state.selectedStaffContract.key > 0) {
            var PersonalInfoContract = this.state.personalInfoContractTable.filter(p => p.key != this.state.selectedStaffContract.key);
            this.setState({
                personalInfoContractTable: PersonalInfoContract,
                deleteModalContract: false,
            });
        } else {
            this.showWarning();
        }
    };

    addPersonalInfoPersonal = () => {
        this.setTimelinePage();
    };

    updatePersonalInfoPersonal = async () => {
        this.setTimelinePage();
    };

    addPersonalInfoContact = async () => {
        this.setTimelinePage();
    };

    updatePersonalInfoContact = async () => {
        this.setTimelinePage();
    };

    // addPersonalInfoContract = async () => {
    //     this.setTimelinePage();
    // };

    // updatePersonalInfoContract = async () => {
    //     this.setTimelinePage();
    // };

    setTimelinePage = () => {
        const curIdx = this.state.curIdx + 1;
        var flagArray = [];
        for (let index = 0; index < this.state.timelinePagesHidden.length; index++) {
            if (curIdx === index) {
                flagArray.push(false);
            }
            else {
                flagArray.push(true);
            }
        }
        this.setState({ timelinePagesHidden: flagArray, curIdx: curIdx });
    }

    addPersonalInfoExperiences = async () => {
        await this.setState({
            staffExperiencesKayit: this.state.staffExperiencesKayit + 1,
        })
        var newStaffExperiences = {
            key: this.state.staffExperiencesKayit,
            index: this.state.staffExperiencesKayit,
            workPeriodStart: (this.state.workPeriodStart != null && this.state.workPeriodStart != undefined && this.state.workPeriodStart != "") ? moment(this.state.workPeriodStart, "DD-MM-YYYY") : "",
            workPeriodStartAck: this.state.workPeriodStart,
            workPeriodEnd: (this.state.workPeriodEnd != null && this.state.workPeriodEnd != undefined && this.state.workPeriodEnd != "") ? moment(this.state.workPeriodEnd, "DD-MM-YYYY") : "",
            workPeriodEndAck: this.state.workPeriodEnd,
            expCompany: this.state.expCompany,
            expPosition: this.state.expPosition,
            expCountry: this.state.expCountry,
            expCity: this.state.expCity,
            expProject: this.state.expProject,
            expClient: this.state.expClient,
            expBrief: this.state.expBrief,
            approvalOfClient: this.state.approvalOfClient,
            approvalOfClientCheck: this.state.approvalOfClientCheck,
        };

        this.setState(prevState => ({
            personalInfoExperiencesTable: [...prevState.personalInfoExperiencesTable, newStaffExperiences]
        }))

        this.createNewClientButtonExperiences();
        this.resetInputsExperiences();
    };

    updatePersonalInfoExperiences = async () => {
        var Experiences = this.state.personalInfoExperiencesTable.find(p => p.key == this.state.selectedStaffExperiences.key);

        Experiences.workPeriodStart = (this.state.workPeriodStart != null && this.state.workPeriodStart != undefined && this.state.workPeriodStart != "") ? moment(this.state.workPeriodStart, "DD-MM-YYYY") : "";
        Experiences.workPeriodStartAck = this.state.workPeriodStart;
        Experiences.workPeriodEnd = (this.state.workPeriodEnd != null && this.state.workPeriodEnd != undefined && this.state.workPeriodEnd != "") ? moment(this.state.workPeriodEnd, "DD-MM-YYYY") : "";
        Experiences.workPeriodEndAck = this.state.workPeriodEnd;
        Experiences.expCompany = this.state.expCompany;
        Experiences.expPosition = this.state.expPosition;
        Experiences.expCountry = this.state.expCountry;
        Experiences.expCity = this.state.expCity;
        Experiences.expProject = this.state.expProject;
        Experiences.expClient = this.state.expClient;
        Experiences.expBrief = this.state.expBrief;
        Experiences.approvalOfClient = this.state.approvalOfClient;
        Experiences.approvalOfClientCheck = this.state.approvalOfClientCheck;

        this.createNewClientButtonExperiences();
        this.resetInputsExperiences();
    };

    deleteStaffExperiencesOK = async () => {
        if (this.state.selectedStaffExperiences != null && this.state.selectedStaffExperiences != undefined && this.state.selectedStaffExperiences != "" && this.state.selectedStaffExperiences.key > 0) {
            var PersonalInfoExperiences = this.state.personalInfoExperiencesTable.filter(p => p.key != this.state.selectedStaffExperiences.key);
            this.setState({
                personalInfoExperiencesTable: PersonalInfoExperiences,
                deleteModalExperiences: false,
            });
        } else {
            this.showWarning();
        }
    };

    addPersonalInfoEducation = async () => {
        await this.setState({
            staffEducationKayit: this.state.staffEducationKayit + 1,
        })
        var newStaffEducation = {
            key: this.state.staffEducationKayit,
            index: this.state.staffEducationKayit,
            graduation: this.state.graduation,
            degree: this.state.degree,
            nameOfSchool: this.state.nameOfSchool,
            eduCountry: this.state.eduCountry,
            dateOfGraduation: (this.state.dateOfGraduation != null && this.state.dateOfGraduation != undefined && this.state.dateOfGraduation != "") ? moment(this.state.dateOfGraduation, "DD-MM-YYYY") : "",
            dateOfGraduationAck: this.state.dateOfGraduation,
            attachGraduationCertificate: this.state.attachGraduationCertificate,
            graduationScore: this.state.graduationScore,
            graduationMaxScore: this.state.graduationMaxScore,

        };

        this.setState(prevState => ({
            personalInfoEducationTable: [...prevState.personalInfoEducationTable, newStaffEducation]
        }))

        this.createNewClientButtonEducation();
        this.resetInputsEducation();
    };

    updatePersonalInfoEducation = async () => {
        var Education = this.state.personalInfoEducationTable.find(p => p.key == this.state.selectedStaffEducation.key);

        Education.graduation = this.state.graduation;
        Education.degree = this.state.degree;
        Education.nameOfSchool = this.state.nameOfSchool;
        Education.eduCountry = this.state.eduCountry;
        Education.dateOfGraduation = (this.state.dateOfGraduation != null && this.state.dateOfGraduation != undefined && this.state.dateOfGraduation != "") ? moment(this.state.dateOfGraduation, "DD-MM-YYYY") : "";
        Education.dateOfGraduationAck = moment(this.state.dateOfGraduation).format("DD-MM-YYYY");

        Education.attachGraduationCertificate = this.state.attachGraduationCertificate;
        Education.graduationScore = this.state.graduationScore;
        Education.graduationMaxScore = this.state.graduationMaxScore;

        this.createNewClientButtonEducation();
        this.resetInputsEducation();
    };

    deleteStaffEducationOK = async () => {
        if (this.state.selectedStaffEducation != null && this.state.selectedStaffEducation != undefined && this.state.selectedStaffEducation != "" && this.state.selectedStaffEducation.key > 0) {
            var PersonalInfoEducation = this.state.personalInfoEducationTable.filter(p => p.key != this.state.selectedStaffEducation.key);
            this.setState({
                personalInfoEducationTable: PersonalInfoEducation,
                deleteModalEducation: false,
            });
        } else {
            this.showWarning();
        }
    };

    addPersonalInfoLanguageSkills = async () => {
        await this.setState({
            staffLanguageSkillsKayit: this.state.staffLanguageSkillsKayit + 1,
        })
        var newStaffLanguageSkills = {
            key: this.state.staffLanguageSkillsKayit,
            index: this.state.staffLanguageSkillsKayit,
            language: this.state.language,
            understanding: this.state.understanding,
            reading: this.state.reading,
            writing: this.state.writing,
            internationalLanguageTest: this.state.internationalLanguageTest,
            score: this.state.score,
            attachLanguageTestResult: this.state.attachLanguageTestResult,

        };

        this.setState(prevState => ({
            personalInfoLanguageSkillsTable: [...prevState.personalInfoLanguageSkillsTable, newStaffLanguageSkills]
        }))

        this.createNewClientButtonLanguageSkills();
        this.resetInputsLanguageSkills();
    };

    updatePersonalInfoLanguageSkills = async () => {
        var LanguageSkills = this.state.personalInfoLanguageSkillsTable.find(p => p.key == this.state.selectedStaffLanguageSkills.key);

        LanguageSkills.language = this.state.language;
        LanguageSkills.understanding = this.state.understanding;
        LanguageSkills.reading = this.state.reading;
        LanguageSkills.writing = this.state.writing;
        LanguageSkills.internationalLanguageTest = this.state.internationalLanguageTest;
        LanguageSkills.score = this.state.score;
        LanguageSkills.attachLanguageTestResult = this.state.attachLanguageTestResult;

        this.createNewClientButtonLanguageSkills();
        this.resetInputsLanguageSkills();
    };

    deleteStaffLanguageSkillsOK = async () => {
        if (this.state.selectedStaffLanguageSkills != null && this.state.selectedStaffLanguageSkills != undefined && this.state.selectedStaffLanguageSkills != "" && this.state.selectedStaffLanguageSkills.key > 0) {
            var PersonalInfoLanguageSkills = this.state.personalInfoLanguageSkillsTable.filter(p => p.key != this.state.selectedStaffLanguageSkills.key);
            this.setState({
                personalInfoLanguageSkillsTable: PersonalInfoLanguageSkills,
                deleteModalLanguageSkills: false,
            });
        } else {
            this.showWarning();
        }
    };

    addPersonalInfoBankAccounts = async () => {
        await this.setState({
            staffBankAccountsKayit: this.state.staffBankAccountsKayit + 1,
        })
        var newStaffBankAccounts = {
            key: this.state.staffBankAccountsKayit,
            index: this.state.staffBankAccountsKayit,
            accountType: this.state.accountType,
            bankName: this.state.bankName,
            branch: this.state.branch,
            accountNo: this.state.accountNo,
            ibanNo: this.state.ibanNo,
            cutOffDate: (this.state.cutOffDate != null && this.state.cutOffDate != undefined && this.state.cutOffDate != "") ? moment(this.state.cutOffDate, "DD-MM-YYYY") : "",
            cutOffDateAck: this.state.cutOffDate,
            paymentDate: (this.state.paymentDate != null && this.state.paymentDate != undefined && this.state.paymentDate != "") ? moment(this.state.paymentDate, "DD-MM-YYYY") : "",
            paymentDateAck: this.state.paymentDate,
        };

        this.setState(prevState => ({
            personalInfoBankAccountsTable: [...prevState.personalInfoBankAccountsTable, newStaffBankAccounts]
        }))

        this.createNewClientButtonBankAccounts();
        this.resetInputsBankAccounts();
    };

    updatePersonalInfoBankAccounts = async () => {
        var BankAccounts = this.state.personalInfoBankAccountsTable.find(p => p.key == this.state.selectedStaffBankAccounts.key);

        BankAccounts.accountType = this.state.accountType;
        BankAccounts.bankName = this.state.bankName;
        BankAccounts.branch = this.state.branch;
        BankAccounts.accountNo = this.state.accountNo;
        BankAccounts.ibanNo = this.state.ibanNo;
        BankAccounts.cutOffDate = (this.state.cutOffDate != null && this.state.cutOffDate != undefined && this.state.cutOffDate != "") ? moment(this.state.cutOffDate, "DD-MM-YYYY") : "";
        BankAccounts.cutOffDateAck = this.state.cutOffDate;
        BankAccounts.paymentDate = (this.state.paymentDate != null && this.state.paymentDate != undefined && this.state.paymentDate != "") ? moment(this.state.paymentDate, "DD-MM-YYYY") : "";
        BankAccounts.paymentDateAck = this.state.paymentDate;

        this.createNewClientButtonBankAccounts();
        this.resetInputsBankAccounts();
    };

    deleteStaffBankAccountsOK = async () => {
        if (this.state.selectedStaffBankAccounts != null && this.state.selectedStaffBankAccounts != undefined && this.state.selectedStaffBankAccounts != "" && this.state.selectedStaffBankAccounts.key > 0) {
            var PersonalInfoBankAccounts = this.state.personalInfoBankAccountsTable.filter(p => p.key != this.state.selectedStaffBankAccounts.key);
            this.setState({
                personalInfoBankAccountsTable: PersonalInfoBankAccounts,
                deleteModalBankAccounts: false,
            });
        } else {
            this.showWarning();
        }
    };

    addPersonalInfoReferences = async () => {
        await this.setState({
            staffReferencesKayit: this.state.staffReferencesKayit + 1,
        })

        var codeRelation = [];
        var ack = "";
        if (this.state.refRelation != null && this.state.refRelation != undefined && this.state.refRelation != 0 && this.state.refRelation != "") {
            ack = this.state.refRelationAck;
        }
        codeRelation.push({ name: ack });

        var newStaffReferences = {
            key: this.state.staffReferencesKayit,
            index: this.state.staffReferencesKayit,
            refName: this.state.refName,
            refjobDescription: this.state.refjobDescription,
            refCompany: this.state.refCompany,
            refCountry: this.state.refCountry,
            refEmail: this.state.refEmail,
            refPhone: this.state.refPhone,
            refRelation: this.state.refRelation,
            refRemarks: this.state.refRemarks,
            codeRelation: codeRelation[0],
        };

        this.setState(prevState => ({
            personalInfoReferencesTable: [...prevState.personalInfoReferencesTable, newStaffReferences]
        }))

        this.createNewClientButtonReferences();
        this.resetInputsReferences();
    };

    updatePersonalInfoReferences = async () => {
        var References = this.state.personalInfoReferencesTable.find(p => p.key == this.state.selectedStaffReferences.key);

        References.refName = this.state.refName;
        References.refjobDescription = this.state.refjobDescription;
        References.refCompany = this.state.refCompany;
        References.refCountry = this.state.refCountry;
        References.refEmail = this.state.refEmail;
        References.refPhone = this.state.refPhone;
        References.refRelation = this.state.refRelation;
        References.refRemarks = this.state.refRemarks;

        var codeRelation = [];
        var ack = "";
        if (this.state.refRelation != null && this.state.refRelation != undefined && this.state.refRelation != 0 && this.state.refRelation != "") {
            ack = this.state.refRelationAck;
        }
        codeRelation.push({ name: ack });

        References.codeRelation = codeRelation[0];

        this.createNewClientButtonReferences();
        this.resetInputsReferences();
    };

    deleteStaffReferencesOK = async () => {
        if (this.state.selectedStaffReferences != null && this.state.selectedStaffReferences != undefined && this.state.selectedStaffReferences != "" && this.state.selectedStaffReferences.key > 0) {
            var PersonalInfoReferences = this.state.personalInfoReferencesTable.filter(p => p.key != this.state.selectedStaffReferences.key);
            this.setState({
                personalInfoReferencesTable: PersonalInfoReferences,
                deleteModalReferences: false,
            });
        } else {
            this.showWarning();
        }
    };

    handleChangeGraduation = async (e) => {
        this.setState({
            graduation: e,
        });
    };

    handleChangeAnnualVacationPeriodType = async (e) => {
        this.setState({
            annualVacationPeriodType: e,
        });
    };

    handleChangeDegree = async (e) => {
        this.setState({
            degree: e,
        });
    };

    handleChangeLanguage = async (e) => {
        this.setState({
            language: e,
        });
    };

    handleChangeUnderstanding = async (e) => {
        this.setState({
            understanding: e,
        });
    };

    handleChangeReading = async (e) => {
        this.setState({
            reading: e,
        });
    };

    handleChangeWriting = async (e) => {
        this.setState({
            writing: e,
        });
    };

    handleChangeInternationalLanguageTest = async (e) => {
        this.setState({
            internationalLanguageTest: e,
        });
    };

    handleChangeAccountType = async (e) => {
        var hiddenAccountTypeBank = true;
        var hiddenAccountTypeCredit = true;

        if (e == "Debit" || e == "Loan") {
            hiddenAccountTypeBank = false;
        } else if (e == "Credit Card") {
            hiddenAccountTypeCredit = false;
        }

        this.setState({
            accountType: e,
            hiddenAccountTypeBank: hiddenAccountTypeBank,
            hiddenAccountTypeCredit: hiddenAccountTypeCredit,
        });
    };

    handleChangeNameOfBank = async (e) => {
        this.setState({
            bankName: e,
        });
    };

    handleChangeBranch = async (e) => {
        this.setState({
            branch: e,
        });
    };

    render() {
        var itemNameOfTrainingAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NameOfTraining");
        var itemDateOfTrainingAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DateOfTraining");
        var itemDurationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Duration");
        var itemOrganizerAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Organizer");
        var itemCertificateValidityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CertificateValidity");
        var itemAttachTrainingCertificateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AttachTrainingCertificate");
        var itemCompanyAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Company");
        var itemPositionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Position");
        var itemProjectAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Project");
        var itemBriefAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Brief");
        var itemClientAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Client");
        var itemApprovalOfClientAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ApprovalOfClient");
        var itemCountryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Country");
        var itemCityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "City");
        var itemWorkPeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "WorkPeriod");
        var itemGraduationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Graduation");
        var itemDegreeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Degree");
        var itemNameOfSchoolAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NameOfSchool");
        var itemEduCountryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EduCountry");
        var itemDateOfGraduationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DateOfGraduation");
        var itemGraduationScoreAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "GraduationScore");
        var itemAttachGraduationCertificateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AttachGraduationCertificate");
        var itemLanguageAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Language");
        var itemUnderstandingAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Understanding");
        var itemReadingAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Reading");
        var itemWritingAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Writing");
        var itemInternationalLanguageTestAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InternationalLanguageTest");
        var itemScoreAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Score");
        var itemAttachLanguageTestResultAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AttachLanguageTestResult");
        var itemAccountTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AccountType");
        var itemNameOfBankAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NameOfBank");
        var itemBranchAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Branch");
        var itemAccountNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AccountNo");
        var itemIBANNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "IBANNo");
        var itemNameOfBankCreditAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NameOfBankCredit");
        var itemCreditCardNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CreditCardNo");
        var itemCutOffDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CutOffDate");
        var itemPaymentDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PaymentDate");
        var itemRefNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefName");
        var itemRefJobDescriptionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefJobDescription");
        var itemRefCompanyAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefCompany");
        var itemRefCountryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefCountry");
        var itemRefEmailAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefEmail");
        var itemRefPhoneAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefPhone");
        var itemRefRelationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefRelation");
        var itemRefRemarksAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefRemarks");
        var itemBudgeNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BudgeNo");
        var itemCompanyRentalAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CompanyRental");
        var itemGenderAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Gender");
        var itemPhotoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Photo");
        var itemNationalityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Nationality");
        var itemNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Name");
        var itemMidNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MidName");
        var itemLastNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "LastName");
        var itemFatherNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "FatherName");
        var itemMotherNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MotherName");
        var itemPlaceOfBirthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PlaceOfBirth");
        var itemBirthDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BirthDate");
        var itemNationalIdIqamaNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NationalIdIqamaNo");
        var itemExpiryDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ExpiryDate");
        var itemAttachIDAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AttachID");
        var itemDrivingLicenceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DrivingLicence");
        var itemDrivingLicenceExpDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DrivingLicenceExpDate");
        var itemAttachPassportAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AttachPassport");
        var itemPassportNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PassportNo");
        var itemPassportExpDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PassportExpDate");
        var itemPassportNoFileAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PassportNoFile");
        var itemBloodGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BloodGroup");
        var itemMaritalStatusAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MaritalStatus");
        var itemNumberOfChildAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NumberOfChild");
        var itemMilitaryObligationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MilitaryObligation");
        var itemSmokingStatusAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SmokingStatus");
        var itemResidenceCountryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceCountry");
        var itemResidenceCityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceCity");
        var itemResidenceAddressAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceAddress");
        var itemResidenceCompanyAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceCompany");
        var itemResidencePersonelPhoneAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidencePersonelPhone");
        var itemResidenceCompanyEmailAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceCompanyEmail");
        var itemResidencePersonalEmailAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidencePersonalEmail");
        var itemResidenceEmergencyPhoneAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceEmergencyPhone");
        var itemResidenceEmergencyEmailAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ResidenceEmergencyEmail");
        var itemHomeCountryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeCountry");
        var itemHomeCityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeCity");
        var itemHomeAddressAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeAddress");
        var itemHomePersonalPhoneAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomePersonalPhone");
        var itemHomeEmergencyPhoneAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeEmergencyPhone");
        var itemHomeEmergencyEmailAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeEmergencyEmail");
        var itemSocialMediaAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SocialMedia");
        var itemSocialAccountAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SocialAccount");
        var itemDepartmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Department");
        var itemDisciplineAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline");
        var itemDesignationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Designation");
        var itemRoleGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RoleGroup");
        var itemContractDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ContractDate");
        var itemContractDurationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ContractDuration");
        var itemAnnualVacationDaysAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AnnualVacationDays");
        var itemAnnualVacationPeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AnnualVacationPeriod");
        var itemAnnualVacationPeriodTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AnnualVacationPeriodType");
        var itemBasicSalaryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BasicSalary");
        var itemHomeAllowanceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeAllowance");
        var itemTransportationAllowanceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TransportationAllowance");
        var itemFoodAllowanceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "FoodAllowance");
        var itemOtherPaymentsAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OtherPayments");

        var itemNameOfTrainingRequired = this.state.dynamicInputs.find(p => p.inputKey === "NameOfTraining");
        var itemDateOfTrainingRequired = this.state.dynamicInputs.find(p => p.inputKey === "DateOfTraining");
        var itemDurationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Duration");
        var itemOrganizerRequired = this.state.dynamicInputs.find(p => p.inputKey === "Organizer");
        var itemCertificateValidityRequired = this.state.dynamicInputs.find(p => p.inputKey === "CertificateValidity");
        var itemAttachTrainingCertificateRequired = this.state.dynamicInputs.find(p => p.inputKey === "AttachTrainingCertificate");
        var itemCompanyRequired = this.state.dynamicInputs.find(p => p.inputKey === "Company");
        var itemPositionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Position");
        var itemProjectRequired = this.state.dynamicInputs.find(p => p.inputKey === "Project");
        var itemBriefRequired = this.state.dynamicInputs.find(p => p.inputKey === "Brief");
        var itemClientRequired = this.state.dynamicInputs.find(p => p.inputKey === "Client");
        var itemApprovalOfClientRequired = this.state.dynamicInputs.find(p => p.inputKey === "ApprovalOfClient");
        var itemCountryRequired = this.state.dynamicInputs.find(p => p.inputKey === "Country");
        var itemCityRequired = this.state.dynamicInputs.find(p => p.inputKey === "City");
        var itemWorkPeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "WorkPeriod");
        var itemGraduationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Graduation");
        var itemDegreeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Degree");
        var itemNameOfSchoolRequired = this.state.dynamicInputs.find(p => p.inputKey === "NameOfSchool");
        var itemEduCountryRequired = this.state.dynamicInputs.find(p => p.inputKey === "EduCountry");
        var itemDateOfGraduationRequired = this.state.dynamicInputs.find(p => p.inputKey === "DateOfGraduation");
        var itemGraduationScoreRequired = this.state.dynamicInputs.find(p => p.inputKey === "GraduationScore");
        var itemAttachGraduationCertificateRequired = this.state.dynamicInputs.find(p => p.inputKey === "AttachGraduationCertificate");
        var itemLanguageRequired = this.state.dynamicInputs.find(p => p.inputKey === "Language");
        var itemUnderstandingRequired = this.state.dynamicInputs.find(p => p.inputKey === "Understanding");
        var itemReadingRequired = this.state.dynamicInputs.find(p => p.inputKey === "Reading");
        var itemWritingRequired = this.state.dynamicInputs.find(p => p.inputKey === "Writing");
        var itemInternationalLanguageTestRequired = this.state.dynamicInputs.find(p => p.inputKey === "InternationalLanguageTest");
        var itemScoreRequired = this.state.dynamicInputs.find(p => p.inputKey === "Score");
        var itemAttachLanguageTestResultRequired = this.state.dynamicInputs.find(p => p.inputKey === "AttachLanguageTestResult");
        var itemAccountTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "AccountType");
        var itemNameOfBankRequired = this.state.dynamicInputs.find(p => p.inputKey === "NameOfBank");
        var itemBranchRequired = this.state.dynamicInputs.find(p => p.inputKey === "Branch");
        var itemAccountNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "AccountNo");
        var itemIBANNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "IBANNo");
        var itemNameOfBankCreditRequired = this.state.dynamicInputs.find(p => p.inputKey === "NameOfBankCredit");
        var itemCreditCardNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "CreditCardNo");
        var itemCutOffDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "CutOffDate");
        var itemPaymentDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "PaymentDate");
        var itemRefNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefName");
        var itemRefJobDescriptionRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefJobDescription");
        var itemRefCompanyRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefCompany");
        var itemRefCountryRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefCountry");
        var itemRefEmailRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefEmail");
        var itemRefPhoneRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefPhone");
        var itemRefRelationRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefRelation");
        var itemRefRemarksRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefRemarks");
        var itemBudgeNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "BudgeNo");
        var itemCompanyRentalRequired = this.state.dynamicInputs.find(p => p.inputKey === "CompanyRental");
        var itemGenderRequired = this.state.dynamicInputs.find(p => p.inputKey === "Gender");
        var itemPhotoRequired = this.state.dynamicInputs.find(p => p.inputKey === "Photo");
        var itemNationalityRequired = this.state.dynamicInputs.find(p => p.inputKey === "Nationality");
        var itemNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "Name");
        var itemMidNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "MidName");
        var itemLastNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "LastName");
        var itemFatherNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "FatherName");
        var itemMotherNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "MotherName");
        var itemPlaceOfBirthRequired = this.state.dynamicInputs.find(p => p.inputKey === "PlaceOfBirth");
        var itemBirthDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "BirthDate");
        var itemNationalIdIqamaNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "NationalIdIqamaNo");
        var itemExpiryDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "ExpiryDate");
        var itemAttachIDRequired = this.state.dynamicInputs.find(p => p.inputKey === "AttachID");
        var itemDrivingLicenceRequired = this.state.dynamicInputs.find(p => p.inputKey === "DrivingLicence");
        var itemDrivingLicenceExpDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "DrivingLicenceExpDate");
        var itemAttachPassportRequired = this.state.dynamicInputs.find(p => p.inputKey === "AttachPassport");
        var itemPassportNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "PassportNo");
        var itemPassportExpDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "PassportExpDate");
        var itemPassportNoFileRequired = this.state.dynamicInputs.find(p => p.inputKey === "PassportNoFile");
        var itemBloodGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "BloodGroup");
        var itemMaritalStatusRequired = this.state.dynamicInputs.find(p => p.inputKey === "MaritalStatus");
        var itemNumberOfChildRequired = this.state.dynamicInputs.find(p => p.inputKey === "NumberOfChild");
        var itemMilitaryObligationRequired = this.state.dynamicInputs.find(p => p.inputKey === "MilitaryObligation");
        var itemSmokingStatusRequired = this.state.dynamicInputs.find(p => p.inputKey === "SmokingStatus");
        var itemResidenceCountryRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceCountry");
        var itemResidenceCityRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceCity");
        var itemResidenceAddressRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceAddress");
        var itemResidenceCompanyRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceCompany");
        var itemResidencePersonelPhoneRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidencePersonelPhone");
        var itemResidenceCompanyEmailRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceCompanyEmail");
        var itemResidencePersonalEmailRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidencePersonalEmail");
        var itemResidenceEmergencyPhoneRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceEmergencyPhone");
        var itemResidenceEmergencyEmailRequired = this.state.dynamicInputs.find(p => p.inputKey === "ResidenceEmergencyEmail");
        var itemHomeCountryRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeCountry");
        var itemHomeCityRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeCity");
        var itemHomeAddressRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeAddress");
        var itemHomePersonalPhoneRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomePersonalPhone");
        var itemHomeEmergencyPhoneRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeEmergencyPhone");
        var itemHomeEmergencyEmailRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeEmergencyEmail");
        var itemSocialMediaRequired = this.state.dynamicInputs.find(p => p.inputKey === "SocialMedia");
        var itemSocialAccountRequired = this.state.dynamicInputs.find(p => p.inputKey === "SocialAccount");
        var itemDepartmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "Department");
        var itemDisciplineRequired = this.state.dynamicInputs.find(p => p.inputKey === "Discipline");
        var itemDesignationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Designation");
        var itemRoleGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "RoleGroup");
        var itemContractDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "ContractDate");
        var itemContractDurationRequired = this.state.dynamicInputs.find(p => p.inputKey === "ContractDuration");
        var itemAnnualVacationDaysRequired = this.state.dynamicInputs.find(p => p.inputKey === "AnnualVacationDays");
        var itemAnnualVacationPeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "AnnualVacationPeriod");
        var itemAnnualVacationPeriodTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "AnnualVacationPeriodType");
        var itemBasicSalaryRequired = this.state.dynamicInputs.find(p => p.inputKey === "BasicSalary");
        var itemHomeAllowanceRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeAllowance");
        var itemTransportationAllowanceRequired = this.state.dynamicInputs.find(p => p.inputKey === "TransportationAllowance");
        var itemFoodAllowanceRequired = this.state.dynamicInputs.find(p => p.inputKey === "FoodAllowance");
        var itemOtherPaymentsRequired = this.state.dynamicInputs.find(p => p.inputKey === "OtherPayments");

        const columnsTraining = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateTraining
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDate" defaultMessage="Date" />,
                render: (data) => {
                    if (data != null && data != undefined && data.dateOfTrainingBsl != null && data.dateOfTrainingBsl != undefined && data.dateOfTrainingBsl != "" && data.dateOfTrainingBts != null && data.dateOfTrainingBts != undefined && data.dateOfTrainingBts != "") {
                        return moment(data.dateOfTrainingBsl).format("DD-MM-YYYY") + "/" + moment(data.dateOfTrainingBts).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "nameOfTraining",
                key: "key",
                title: <FormattedMessage id="PersonalInfoTraining" defaultMessage="Training" />,
            },
            {
                dataIndex: "organizer",
                key: "key",
                title: <FormattedMessage id="PersonalInfoOrganizer" defaultMessage="Organizer" />,
            },
            {
                dataIndex: "certificateValidity",
                key: "key",
                title: <FormattedMessage id="PersonalInfoCertificateValidity" defaultMessage="Certificate Validity" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAttachTrainingCertificate" defaultMessage="Attach Training Certificate" />,
                render: (data) => {
                    if (data != null && data != undefined && data.attachTrainingCertificate != null && data.attachTrainingCertificate != undefined && data.attachTrainingCertificate != "") {
                        return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(data.attachTrainingCertificate)} type="primary" shape="round" icon={<DownloadOutlined />} >
                        </AntdButton>
                    }
                    return "";
                }
            },
        ];

        const tableColumnsTraining = columnsTraining.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsTraining[0].fixed = true;
            tableColumnsTraining[tableColumnsTraining.length - 1].fixed = 'right';
        }

        const columnsContract = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateContract
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoContractDate" defaultMessage="Contract Date" />,
                render: (data) => {
                    if (data != null && data != undefined && data.contractDate != null && data.contractDate != undefined && data.contractDate != "") {
                        return moment(data.contractDate).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "contractDuration",
                key: "key",
                title: <FormattedMessage id="PersonalInfoContractDuration" defaultMessage="Contract Duration" />,
            },
            {
                dataIndex: "annualVacationDays",
                key: "key",
                title: <FormattedMessage id="PersonalInfoAnnualVacationDays" defaultMessage="Annual Vacation Days" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAnnualVacationPeriod" defaultMessage="Annual Vacation Period" />,
                render: (data) => {
                    if (data != null && data != undefined && data.annualVacationPeriodType != null && data.annualVacationPeriodType != undefined && data.annualVacationPeriodType != "") {
                        if (data.annualVacationPeriodType == 1) {
                            return data.annualVacationPeriod + " Year"
                        } else if (data.annualVacationPeriodType == 2) {
                            return data.annualVacationPeriod + " Month"
                        }
                    }
                    return "";
                }
            },
            {
                dataIndex: "basicSalary",
                key: "key",
                title: <FormattedMessage id="PersonalInfoBasicSalary" defaultMessage="Basic Salary" />,
            },
            {
                dataIndex: "homeAllowance",
                key: "key",
                title: <FormattedMessage id="PersonalInfoHomeAllowance" defaultMessage="Home Allowance" />,
            },
            {
                dataIndex: "transportationAllowance",
                key: "key",
                title: <FormattedMessage id="PersonalInfoTransportationAllowance" defaultMessage="Transportation Allowance" />,
            },
            {
                dataIndex: "foodAllowance",
                key: "key",
                title: <FormattedMessage id="PersonalInfoFoodAllowance" defaultMessage="Food Allowance" />,
            },
            {
                dataIndex: "otherPayments",
                key: "key",
                title: <FormattedMessage id="PersonalInfoOtherPayments" defaultMessage="Other Payments" />,
            },
        ];

        const tableColumnsContract = columnsContract.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsContract[0].fixed = true;
            tableColumnsContract[tableColumnsContract.length - 1].fixed = 'right';
        }

        const columnsExperiences = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateExperiences
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoWorkPeriod" defaultMessage="Work Period" />,
                render: (data) => {
                    if (data != null && data != undefined && data.workPeriodStart != null && data.workPeriodStart != undefined && data.workPeriodStart != "" && data.workPeriodEnd != null && data.workPeriodEnd != undefined && data.workPeriodEnd != "") {
                        return moment(data.workPeriodStart).format("DD-MM-YYYY") + "/" + moment(data.workPeriodEnd).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "expCompany",
                key: "key",
                title: <FormattedMessage id="PersonalInfoCompany" defaultMessage="Company" />,
            },
            {
                dataIndex: "expPosition",
                key: "key",
                title: <FormattedMessage id="PersonalInfoPosition" defaultMessage="Position" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCountryCity" defaultMessage="Country/City" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.expCountry != null && data.expCountry != undefined && data.expCountry != "" && data.expCity != null && data.expCity != undefined && data.expCity != "") {
                            return data.expCountry + "/" + data.expCity;
                        } else if (data.expCountry != null && data.expCountry != undefined && data.expCountry != "") {
                            return data.expCountry;
                        } else if (data.expCity != null && data.expCity != undefined && data.expCity != "") {
                            return data.expCity;
                        }
                    }
                    return "";
                }
            },
            {
                dataIndex: "expProject",
                key: "key",
                title: <FormattedMessage id="PersonalInfoProject" defaultMessage="Project" />,
            },
            {
                dataIndex: "expClient",
                key: "key",
                title: <FormattedMessage id="PersonalInfoClient" defaultMessage="Client" />,
            },
        ];

        const tableColumnsExperiences = columnsExperiences.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsExperiences[0].fixed = true;
            tableColumnsExperiences[tableColumnsExperiences.length - 1].fixed = 'right';
        }

        const columnsEducation = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateEducation
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDateOfGraduation" defaultMessage="Date of Graduation" />,
                render: (data) => {
                    if (data != null && data != undefined && data.dateOfGraduation != null && data.dateOfGraduation != undefined && data.dateOfGraduation != "") {
                        return moment(data.dateOfGraduation).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "nameOfSchool",
                key: "key",
                title: <FormattedMessage id="PersonalInfoSchool" defaultMessage="School" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoGraduation" defaultMessage="Graduation" />,
                render: (data) => {
                    if (data != null && data != undefined && data.graduation != null && data.graduation != undefined && data.graduation != "" && data.graduation != 0) {
                        return Graduation(data.graduation);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoDegree" defaultMessage="Degree" />,
                render: (data) => {
                    if (data != null && data != undefined && data.degree != null && data.degree != undefined && data.degree != "" && data.degree != 0) {
                        return Degree(data.degree);
                    }
                    return "";
                }
            },
            {
                dataIndex: "eduCountry",
                key: "key",
                title: <FormattedMessage id="PersonalInfoEduCountry" defaultMessage="Country" />,
            },
        ];

        const tableColumnsEducation = columnsEducation.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsEducation[0].fixed = true;
            tableColumnsEducation[tableColumnsEducation.length - 1].fixed = 'right';
        }

        const columnsLanguageSkills = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateLanguageSkills
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoLanguage" defaultMessage="Language" />,
                render: (data) => {
                    if (data != null && data != undefined && data.language != null && data.language != undefined && data.language != "" && data.language != 0) {
                        return Language(data.language);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoUnderstanding" defaultMessage="Understanding" />,
                render: (data) => {
                    if (data != null && data != undefined && data.understanding != null && data.understanding != undefined && data.understanding != "" && data.understanding != 0) {
                        return LanguageLevel(data.understanding);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoReading" defaultMessage="Reading" />,
                render: (data) => {
                    if (data != null && data != undefined && data.reading != null && data.reading != undefined && data.reading != "" && data.reading != 0) {
                        return LanguageLevel(data.reading);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoWriting" defaultMessage="Writing" />,
                render: (data) => {
                    if (data != null && data != undefined && data.writing != null && data.writing != undefined && data.writing != "" && data.writing != 0) {
                        return LanguageLevel(data.writing);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoTest" defaultMessage="Test" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.internationalLanguageTest != null && data.internationalLanguageTest != undefined && data.internationalLanguageTest != "" && data.score != null && data.score != undefined && data.score != "") {
                            return LanguageTest(data.internationalLanguageTest) + "/" + data.score;
                        } else if (data.internationalLanguageTest != null && data.internationalLanguageTest != undefined && data.internationalLanguageTest != "") {
                            return LanguageTest(data.internationalLanguageTest);
                        } else if (data.score != null && data.score != undefined && data.score != "") {
                            return data.score;
                        }
                    }
                    return "";
                }
            },
        ];

        const tableColumnsLanguageSkills = columnsLanguageSkills.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsLanguageSkills[0].fixed = true;
            tableColumnsLanguageSkills[tableColumnsLanguageSkills.length - 1].fixed = 'right';
        }

        const columnsBankAccounts = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateBankAccounts
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAccountType" defaultMessage="Account Type" />,
                render: (data) => {
                    if (data != null && data != undefined && data.accountType != null && data.accountType != undefined && data.accountType != "" && data.accountType != 0) {
                        return data.accountType;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBank" defaultMessage="Bank" />,
                render: (data) => {
                    if (data != null && data != undefined && data.bankName != null && data.bankName != undefined && data.bankName != "" && data.bankName != 0) {
                        return BankName(data.bankName);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoBranch" defaultMessage="Branch" />,
                render: (data) => {
                    if (data != null && data != undefined && data.branch != null && data.branch != undefined && data.branch != "" && data.branch != 0) {
                        return Branch(data.branch);
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoAccountNo" defaultMessage="Account No" />,
                render: (data) => {
                    if (data != null && data != undefined && data.accountNo != null && data.accountNo != undefined && data.accountNo != "" && data.accountNo != 0) {
                        return data.accountNo;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoIBAN" defaultMessage="IBAN" />,
                render: (data) => {
                    if (data != null && data != undefined && data.ibanNo != null && data.ibanNo != undefined && data.ibanNo != "" && data.ibanNo != 0) {
                        return data.ibanNo;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoCutOff" defaultMessage="Cut Off" />,
                render: (data) => {
                    if (data != null && data != undefined && data.cutOffDate != null && data.cutOffDate != undefined && data.cutOffDate != "") {
                        return moment(data.cutOffDate).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoPayment" defaultMessage="Payment" />,
                render: (data) => {
                    if (data != null && data != undefined && data.paymentDate != null && data.paymentDate != undefined && data.paymentDate != "") {
                        return moment(data.paymentDate).format("DD-MM-YYYY");
                    }
                    return "";
                }
            },
            {
                dataIndex: "status",
                key: "key",
                title: <FormattedMessage id="PersonalInfoStatus" defaultMessage="Status" />,
            },
        ];

        const tableColumnsBankAccounts = columnsBankAccounts.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsBankAccounts[0].fixed = true;
            tableColumnsBankAccounts[tableColumnsBankAccounts.length - 1].fixed = 'right';
        }

        const columnsReferences = [
            {
                key: "key",
                title: "",
                render: this.actionTemplateReferences
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefName" defaultMessage="Name" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refName != null && data.refName != undefined && data.refName != "" && data.refName != 0) {
                        return data.refName;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefJobDescription" defaultMessage="Job Description" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refjobDescription != null && data.refjobDescription != undefined && data.refjobDescription != "" && data.refjobDescription != 0) {
                        return data.refjobDescription;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefCompany" defaultMessage="Company" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refCompany != null && data.refCompany != undefined && data.refCompany != "" && data.refCompany != 0) {
                        return data.refCompany;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefCountry" defaultMessage="Country" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refCountry != null && data.refCountry != undefined && data.refCountry != "" && data.refCountry != 0) {
                        return data.refCountry;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefEmail" defaultMessage="E-mail" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refEmail != null && data.refEmail != undefined && data.refEmail != "" && data.refEmail != 0) {
                        return data.refEmail;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefPhone" defaultMessage="Phone" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refPhone != null && data.refPhone != undefined && data.refPhone != "" && data.refPhone != 0) {
                        return data.refPhone;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefRelation" defaultMessage="Relation" />,
                render: (data) => {
                    if (data != null && data != undefined && data.codeRelation != null && data.codeRelation != undefined) {
                        return data.codeRelation.name;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="PersonalInfoRefRemarks" defaultMessage="Remarks" />,
                render: (data) => {
                    if (data != null && data != undefined && data.refRemarks != null && data.refRemarks != undefined && data.refRemarks != "" && data.refRemarks != 0) {
                        return data.refRemarks;
                    }
                    return "";
                }
            },

        ];

        const tableColumnsReferences = columnsReferences.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumnsReferences[0].fixed = true;
            tableColumnsReferences[tableColumnsReferences.length - 1].fixed = 'right';
        }

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
        };

        const layoutContract = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 },
        };

        const layoutContractPeriod = {
            labelCol: { span: 18 },
            wrapperCol: { span: 6 },
        };


        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            this.showWarning();
            console.log('Failed:', errorInfo);
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const stateConst = this.state;
        let timeline;
        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Attach Photo</div>
            </div>
        );

        if (this.state.timelinePages.length !== 0) {
            timeline = <div
                style={{
                    width: "100%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#ffffff",
                        foreground: this.state.color,
                        outline: "#dfdfdf",
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={this.state.curIdx}
                    labelWidth={150}
                    getLabel={function (date, index) {
                        return stateConst.timelinePages[index];
                    }}
                    indexClick={index => {
                        if (this.state.curIdx > 1) {
                            const curIdx = index;
                            var flagArray = [];
                            for (let index = 0; index < this.state.timelinePagesHidden.length; index++) {
                                if (curIdx === index) {
                                    flagArray.push(false);
                                }
                                else {
                                    flagArray.push(true);
                                }
                            }
                            this.setState({ timelinePagesHidden: flagArray, curIdx: index });
                        } else if (this.state.curIdx > index) {
                            const curIdx = index;
                            var flagArray = [];
                            for (let index = 0; index < this.state.timelinePagesHidden.length; index++) {
                                if (curIdx === index) {
                                    flagArray.push(false);
                                }
                                else {
                                    flagArray.push(true);
                                }
                            }
                            this.setState({ timelinePagesHidden: flagArray, curIdx: index });
                        } else {
                            this.showWarning("Please use the Next button.");
                        }
                    }}
                    values={this.state.timelinePagesData.map(x => x)}
                />
            </div>;
        }

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <Card>
                    <div className="content-section implementation">
                        {/*<Steps model={items} activeIndex={this.state.activeIndex} onSelect={(e) => this.setState({ activeIndex: e.index })} readOnly={false} />*/}
                        {timeline}
                    </div>

                    {/*<div hidden={this.state.hiddenPersonal} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    <div hidden={this.state.timelinePagesHidden[0]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && (Boolean(this.state.budgeNo) && this.state.budgeNo.length>0 || !this.state.hiddenSave) && 
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdatePersonal == false ? this.updatePersonalInfoPersonal : this.addPersonalInfoPersonal}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefPersonal} >

                                {this.setPersonalSetValue()}

                                <div hidden={this.state.hiddenDataGirisPersonal}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="9">
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PersonalInfoNextPersonalButton"
                                                    style={{ width: '100%' }} type="submit"
                                                    variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                            </Col>
                                        </Form.Group>
                                    }

                                    {(itemBudgeNoAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoBudgeNo" defaultMessage="Budge No" />}
                                            name="BudgeNo"
                                            rules={[{ required: itemBudgeNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBudgeNoRequired.translateRequired) == true ? itemBudgeNoRequired.translateRequired : -1} defaultMessage="Please input your Budge No!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.budgeNo} onChange={(e) => this.setState({ budgeNo: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemCompanyRentalAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCompanyRental" defaultMessage="Company/Rental" />}
                                            name="CompanyRental"
                                            rules={[{ required: itemCompanyRentalRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCompanyRentalRequired.translateRequired) == true ? itemCompanyRentalRequired.translateRequired : -1} defaultMessage="Please input your Company/Rental!" /> }]} >
                                            <Switch checkedChildren="Company" unCheckedChildren="Rental" checked={this.state.companyStaff} defaultChecked onChange={(value) => {
                                                this.setState({ companyStaff: value })
                                            }} />
                                        </FormAntd.Item>}

                                    {(itemGenderAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoGender" defaultMessage="Gender" />}
                                            name="Gender"
                                            rules={[{ required: itemGenderRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemGenderRequired.translateRequired) == true ? itemGenderRequired.translateRequired : -1} defaultMessage="Please input your Gender!" /> }]} >
                                            <Switch checkedChildren="Male" unCheckedChildren="Female" checked={this.state.genderCheck} defaultChecked onChange={(value) => {
                                                this.setState({ genderCheck: value })
                                            }} />
                                        </FormAntd.Item>}

                                    {(itemPhotoAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPhoto" defaultMessage="Photo" />}
                                            name="Photo"
                                            rules={[{ required: itemPhotoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPhotoRequired.translateRequired) == true ? itemPhotoRequired.translateRequired : -1} defaultMessage="Please input your Photo!" /> }]} >
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}

                                                action={`${API_BASE}/Staff/upload`}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handlePhotoChange}>
                                                {this.state.photoImageUrl ? <img src={this.state.photoImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </FormAntd.Item>}

                                    {(itemDepartmentAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDepartment" defaultMessage="Department" />}
                                            name="Department"
                                            rules={[{ required: itemDepartmentRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDepartmentRequired.translateRequired) == true ? itemDepartmentRequired.translateRequired : -1} defaultMessage="Please input your Department!" /> }]} >
                                            <Select value={this.state.department} onChange={this.handleChangeDepartment}
                                            >
                                                {this.state.departments.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemDisciplineAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDiscipline" defaultMessage="Discipline" />}
                                            name="Discipline"
                                            rules={[{ required: itemDisciplineRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDisciplineRequired.translateRequired) == true ? itemDisciplineRequired.translateRequired : -1} defaultMessage="Please input your Discipline!" /> }]} >
                                            <Select value={this.state.discipline} onChange={this.handleChangeDiscipline}
                                            >
                                                {this.state.disciplines.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemDesignationAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDesignation" defaultMessage="Designation" />}
                                            name="Designation"
                                            rules={[{ required: itemDesignationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDesignationRequired.translateRequired) == true ? itemDesignationRequired.translateRequired : -1} defaultMessage="Please input your Designation!" /> }]} >
                                            <Select value={this.state.designation} onChange={this.handleChangeDesignation}
                                            >
                                                {this.state.designations.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemRoleGroupAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRoleGroup" defaultMessage="Role Group" />}
                                            name="RoleGroup"
                                            rules={[{ required: itemRoleGroupRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRoleGroupRequired.translateRequired) == true ? itemRoleGroupRequired.translateRequired : -1} defaultMessage="Please input your Role Group!" /> }]} >
                                            <Select value={this.state.roleGroup} onChange={this.handleChangeRoleGroup}
                                            >
                                                {this.state.roleGroups.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemNationalityAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoNationality" defaultMessage="Nationality" />}
                                            name="Nationality"
                                            rules={[{ required: itemNationalityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNationalityRequired.translateRequired) == true ? itemNationalityRequired.translateRequired : -1} defaultMessage="Please input your Nationality!" /> }]} >

                                            <Select value={this.state.nationality} onChange={(value) => {
                                                this.setState({
                                                    nationality: value
                                                })
                                            }}
                                            >
                                                {NATIONALITIES.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemNameAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoName" defaultMessage="Name" />}
                                            name="Name"
                                            rules={[{ required: itemNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNameRequired.translateRequired) == true ? itemNameRequired.translateRequired : -1} defaultMessage="Please input your Name!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemMidNameAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoMidName" defaultMessage="Mid Name" />}
                                            name="MidName"
                                            rules={[{ required: itemMidNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMidNameRequired.translateRequired) == true ? itemMidNameRequired.translateRequired : -1} defaultMessage="Please input your Mid Name!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.midName} onChange={(e) => this.setState({ midName: e.target.value })} />

                                        </FormAntd.Item>}

                                    {(itemLastNameAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoLastName" defaultMessage="Last Name" />}
                                            name="LastName"
                                            rules={[{ required: itemLastNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLastNameRequired.translateRequired) == true ? itemLastNameRequired.translateRequired : -1} defaultMessage="Please input your Last Name!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemFatherNameAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoFatherName" defaultMessage="Father Name" />}
                                            name="FatherName"
                                            rules={[{ required: itemFatherNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemFatherNameRequired.translateRequired) == true ? itemFatherNameRequired.translateRequired : -1} defaultMessage="Please input your Father Name!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.fatherName} onChange={(e) => this.setState({ fatherName: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemMotherNameAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoMotherName" defaultMessage="Mother Name" />}
                                            name="MotherName"
                                            rules={[{ required: itemMotherNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMotherNameRequired.translateRequired) == true ? itemMotherNameRequired.translateRequired : -1} defaultMessage="Please input your Mother Name!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.motherName} onChange={(e) => this.setState({ motherName: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemPlaceOfBirthAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPlaceOfBirth" defaultMessage="Place of Birth" />}
                                            name="PlaceOfBirth"
                                            rules={[{ required: itemPlaceOfBirthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPlaceOfBirthRequired.translateRequired) == true ? itemPlaceOfBirthRequired.translateRequired : -1} defaultMessage="Please input your Place of Birth!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.placeOfBirth} onChange={(e) => this.setState({ placeOfBirth: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemBirthDateAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoBirthDate" defaultMessage="Birth Date" />}
                                            name="BirthDate"
                                            rules={[{ required: itemBirthDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBirthDateRequired.translateRequired) == true ? itemBirthDateRequired.translateRequired : -1} defaultMessage="Please input your Birth Date!" /> }]} >
                                            <DatePicker value={(this.state.birthDate != null && this.state.birthDate != undefined && this.state.birthDate != "")
                                                ? moment(this.state.birthDate, 'DD-MM-YYYY') : ""} disabledDate={this.disabledDate} format="DD-MM-YYYY" onChange={this.onChangeBirthDate} />
                                        </FormAntd.Item>}

                                    {(itemBirthDateAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2" ></Col>
                                            <Col sm="5" hidden={this.state.hiddenBirthDate}>
                                                <Form.Label style={{ color: 'red' }}>
                                                    <FormattedMessage id="PersonalInfoMessageBirthDate" defaultMessage="Employee age is less than 18. Are you sure about birth date ?" />
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>}

                                    {(itemNationalIdIqamaNoAttributes.visible) &&
                                        <FormAntd.Item
                                            label={this.state.nationalIdIqamaNo}
                                            name="NationalIdIqamaNo"
                                            rules={[{ required: itemNationalIdIqamaNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNationalIdIqamaNoRequired.translateRequired) == true ? itemNationalIdIqamaNoRequired.translateRequired : -1} defaultMessage={this.state.nationalIdIqamaNo} /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.nationalId} maxLength="10" onChange={(e) => this.setState({ nationalId: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemExpiryDateAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoExpiryDate" defaultMessage="Expiry Date" />}
                                            name="ExpiryDate"
                                            rules={[{ required: itemExpiryDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemExpiryDateRequired.translateRequired) == true ? itemExpiryDateRequired.translateRequired : -1} defaultMessage="Please input your Expiry Date!" /> }]} >
                                            <DatePicker value={(this.state.nationalIdExpDate != null && this.state.nationalIdExpDate != undefined && this.state.nationalIdExpDate != "")
                                                ? moment(this.state.nationalIdExpDate, 'DD-MM-YYYY') : ""} format="DD-MM-YYYY" onChange={this.onChangeNationalIdExpDate} />
                                        </FormAntd.Item>}

                                    {(itemExpiryDateAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2" ></Col>
                                            <Col sm="5" hidden={this.state.hiddenNationalIdExpDate}>
                                                <Form.Label style={{ color: 'red' }}>
                                                    <FormattedMessage id="PersonalInfoMessageValidate" defaultMessage="Validate date expired. Are you sure for expiry date ?" />
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>}

                                    {(itemAttachIDAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoAttachID" defaultMessage="Attach ID" />}
                                            name="AttachID"
                                            rules={[{ required: itemAttachIDRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAttachIDRequired.translateRequired) == true ? itemAttachIDRequired.translateRequired : -1} defaultMessage="Please input your Attach ID!" /> }]} >
                                            <Upload {...props}
                                                showUploadList={true}
                                                fileList={this.state.AttachIqamaNoFileUpload}
                                                onChange={({ fileList }) => {
                                                    if (fileList.length == 1) {
                                                        var attachIqamaNo = "";
                                                        if (Boolean(fileList[0].response)) {
                                                            attachIqamaNo = fileList[0].response.url;
                                                        }
                                                        this.setState({
                                                            AttachIqamaNoFileUpload: fileList,
                                                            attachIqamaNo: attachIqamaNo,
                                                        });
                                                    }
                                                }}
                                                beforeUpload={(fileList) => {
                                                    if (this.state.AttachIqamaNoFileUpload.length > 0) {
                                                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                                onRemove={({ fileList }) => {
                                                    this.setState({
                                                        AttachIqamaNoFileUpload: [],
                                                        attachIqamaNo: "",
                                                    })
                                                }
                                                }>
                                                <ReactButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                    <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachID" defaultMessage="Attach ID" />
                                                </ReactButton>
                                            </Upload>
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3"></Col>
                                        <Col sm="1" hidden={(this.state.attachIqamaNo != null && this.state.attachIqamaNo != undefined && this.state.attachIqamaNo != "") ? false : true}>
                                            <AntdButton style={{ width: '100%', marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(this.state.attachIqamaNo)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                            </AntdButton>
                                        </Col>
                                    </Form.Group>

                                    {(itemDrivingLicenceAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDrivingLicence" defaultMessage="Driving Licence No" />}
                                            name="DrivingLicence"
                                            rules={[{ required: itemDrivingLicenceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDrivingLicenceRequired.translateRequired) == true ? itemDrivingLicenceRequired.translateRequired : -1} defaultMessage="Please input your Driving Licence No!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.drivingLicence} maxLength="10" onChange={(e) => this.setState({ drivingLicence: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemDrivingLicenceExpDateAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoExpiryDate" defaultMessage="Expiry Date" />}
                                            name="DrivingLicenceExpDate"
                                            rules={[{ required: itemDrivingLicenceExpDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDrivingLicenceExpDateRequired.translateRequired) == true ? itemDrivingLicenceExpDateRequired.translateRequired : -1} defaultMessage="Please input your Expiry Date!" /> }]} >
                                            <DatePicker value={(this.state.drivingLicenceExpDate != null && this.state.drivingLicenceExpDate != undefined && this.state.drivingLicenceExpDate != "")
                                                ? moment(this.state.drivingLicenceExpDate, 'DD-MM-YYYY') : ""} format="DD-MM-YYYY" onChange={this.onChangeDrivingLicenceExpDate} />
                                        </FormAntd.Item>}

                                    {(itemAttachPassportAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoAttachPassport" defaultMessage="Attach Passport" />}
                                            name="AttachPassport"
                                            rules={[{ required: itemAttachPassportRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAttachPassportRequired.translateRequired) == true ? itemAttachPassportRequired.translateRequired : -1} defaultMessage="Please input your Attach Passport!" /> }]} >
                                            <Upload {...props}
                                                showUploadList={true}
                                                fileList={this.state.AttachDrivingLicenceNoFileUpload}
                                                onChange={({ fileList }) => {
                                                    if (fileList.length == 1) {
                                                        var attachDrivingLicenceNo = "";
                                                        if (Boolean(fileList[0].response)) {
                                                            attachDrivingLicenceNo = fileList[0].response.url;
                                                        }
                                                        this.setState({
                                                            AttachDrivingLicenceNoFileUpload: fileList,
                                                            attachDrivingLicenceNo: attachDrivingLicenceNo,
                                                        });
                                                    }
                                                }}
                                                beforeUpload={(fileList) => {
                                                    if (this.state.AttachDrivingLicenceNoFileUpload.length > 0) {
                                                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                                onRemove={({ fileList }) => {
                                                    this.setState({
                                                        AttachDrivingLicenceNoFileUpload: [],
                                                        attachDrivingLicenceNo: "",
                                                    })
                                                }
                                                }>
                                                <ReactButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                    <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachPassport" defaultMessage="Attach Passport" />
                                                </ReactButton>
                                            </Upload>
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3"></Col>
                                        <Col sm="1" hidden={(this.state.attachDrivingLicenceNo != null && this.state.attachDrivingLicenceNo != undefined && this.state.attachDrivingLicenceNo != "") ? false : true}>
                                            <AntdButton style={{ width: '100%', marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(this.state.attachDrivingLicenceNo)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                            </AntdButton>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="5" hidden={this.state.hiddenDrivingLicenceExpDate}>
                                            <Form.Label style={{ color: 'red' }}>
                                                <FormattedMessage id="PersonalInfoMessageValidate" defaultMessage="Validate date expired. Are you sure for expiry date ?" />
                                            </Form.Label>
                                        </Col>
                                    </Form.Group>

                                    {(itemPassportNoAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPassportNo" defaultMessage="Passport No" />}
                                            name="PassportNo"
                                            rules={[{ required: itemPassportNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPassportNoRequired.translateRequired) == true ? itemPassportNoRequired.translateRequired : -1} defaultMessage="Please input your Passport No!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.passportNo} onChange={(e) => this.setState({ passportNo: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemPassportExpDateAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPassportExpDate" defaultMessage="Expiry Date" />}
                                            name="PassportExpDate"
                                            rules={[{ required: itemPassportExpDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPassportExpDateRequired.translateRequired) == true ? itemPassportExpDateRequired.translateRequired : -1} defaultMessage="Please input your Expiry Date!" /> }]} >
                                            <DatePicker value={(this.state.passportExpDate != null && this.state.passportExpDate != undefined && this.state.passportExpDate != "")
                                                ? moment(this.state.passportExpDate, 'DD-MM-YYYY') : ""} format="DD-MM-YYYY" onChange={this.onChangePassportExpDate} />
                                        </FormAntd.Item>}

                                    {(itemPassportExpDateAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2"></Col>
                                            <Col sm="5" hidden={this.state.hiddenPassportExpDate}>
                                                <Form.Label style={{ color: 'red' }}>
                                                    <FormattedMessage id="PersonalInfoMessageValidate" defaultMessage="Validate date expired. Are you sure for expiry date ?" />
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>}

                                    {(itemPassportNoFileAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPassportNoFile" defaultMessage="Attach Passport" />}
                                            name="PassportNoFile"
                                            rules={[{ required: itemPassportNoFileRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPassportNoFileRequired.translateRequired) == true ? itemPassportNoFileRequired.translateRequired : -1} defaultMessage="Please input your Attach Passport!" /> }]} >
                                            <Upload {...props}
                                                showUploadList={true}
                                                fileList={this.state.AttachPassportNoFileUpload}
                                                onChange={({ fileList }) => {
                                                    if (fileList.length == 1) {
                                                        var attachPassportNo = "";
                                                        if (Boolean(fileList[0].response)) {
                                                            attachPassportNo = fileList[0].response.url;
                                                        }
                                                        this.setState({
                                                            AttachPassportNoFileUpload: fileList,
                                                            attachPassportNo: attachPassportNo,
                                                        });
                                                    }
                                                }}
                                                beforeUpload={(fileList) => {
                                                    if (this.state.AttachPassportNoFileUpload.length > 0) {
                                                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                                onRemove={({ fileList }) => {
                                                    this.setState({
                                                        AttachPassportNoFileUpload: [],
                                                        attachPassportNo: "",
                                                    })
                                                }
                                                }>
                                                <ReactButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                    <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachPassport" defaultMessage="Attach Passport" />
                                                </ReactButton>
                                            </Upload>
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3"></Col>
                                        <Col sm="1" hidden={(this.state.attachPassportNo != null && this.state.attachPassportNo != undefined && this.state.attachPassportNo != "") ? false : true}>
                                            <AntdButton style={{ width: '100%', marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(this.state.attachPassportNo)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                            </AntdButton>
                                        </Col>
                                    </Form.Group>

                                    {(itemBloodGroupAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoBloodGroup" defaultMessage="Blood Group" />}
                                            name="BloodGroup"
                                            rules={[{ required: itemBloodGroupRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBloodGroupRequired.translateRequired) == true ? itemBloodGroupRequired.translateRequired : -1} defaultMessage="Please input your Blood Group!" /> }]} >
                                            <Select value={this.state.bloodGroupId} onChange={(value) => {
                                                this.setState({
                                                    bloodGroupId: value
                                                })
                                            }}
                                            >
                                                {this.state.bloodGroups.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemMaritalStatusAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoMaritalStatus" defaultMessage="Marital Status" />}
                                            name="MaritalStatus"
                                            rules={[{ required: itemMaritalStatusRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMaritalStatusRequired.translateRequired) == true ? itemMaritalStatusRequired.translateRequired : -1} defaultMessage="Please input your Marital Status!" /> }]} >
                                            <Select value={this.state.maritalStatusId} onChange={(value) => {
                                                this.setState({
                                                    maritalStatusId: value
                                                })
                                            }}
                                            >
                                                {this.state.maritalStatuses.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemNumberOfChildAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoNumberOfChild" defaultMessage="Number of Child" />}
                                            name="NumberOfChild"
                                            rules={[{ required: itemNumberOfChildRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNumberOfChildRequired.translateRequired) == true ? itemNumberOfChildRequired.translateRequired : -1} defaultMessage="Please input your Number of Child!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.numberOfChild} onChange={(e) => this.setState({ numberOfChild: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemMilitaryObligationAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoMilitaryObligation" defaultMessage="Military Obligation" />}
                                            name="MilitaryObligation"
                                            rules={[{ required: itemMilitaryObligationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMilitaryObligationRequired.translateRequired) == true ? itemMilitaryObligationRequired.translateRequired : -1} defaultMessage="Please input your Military Obligation!" /> }]} >
                                            <Select value={this.state.militaryObligationId} onChange={(value) => {
                                                this.setState({
                                                    militaryObligationId: value
                                                })
                                            }}
                                            >
                                                {this.state.militaryObligations.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemSmokingStatusAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoSmokingStatus" defaultMessage="Smoking Status" />}
                                            name="SmokingStatus"
                                            rules={[{ required: itemSmokingStatusRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSmokingStatusRequired.translateRequired) == true ? itemSmokingStatusRequired.translateRequired : -1} defaultMessage="Please input your Smoking Status!" /> }]} >
                                            <Switch checkedChildren="Not Smoker" unCheckedChildren="Smoker" checked={this.state.smokerCheck} defaultChecked onChange={(value) => {
                                                this.setState({ smokerCheck: value })
                                            }} />
                                        </FormAntd.Item>}
                                </div>
                                <br />
                                <br />
                            </FormAntd>
                        }
                    </div>

                    {/*<div hidden={this.state.hiddenContact} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    <div hidden={this.state.timelinePagesHidden[1]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && (Boolean(this.state.budgeNo) && this.state.budgeNo.length>0 || !this.state.hiddenSave) && 
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateContact == false ? this.updatePersonalInfoContact : this.addPersonalInfoContact}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefContact} >

                                {this.setContactSetValue()}

                                <div hidden={this.state.hiddenDataGirisContact}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="9">
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PersonalInfoNextContactButton"
                                                    style={{ width: '100%' }} type="submit"
                                                    variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                            </Col>
                                        </Form.Group>
                                    }

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Form.Label column sm="3" style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage id="PersonalInfoResidenceCountry" defaultMessage="Residence Country" />
                                        </Form.Label>
                                    </Form.Group>

                                    {(itemResidenceCountryAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCountry" defaultMessage="Country" />}
                                            name="ResidenceCountry"
                                            rules={[{ required: itemResidenceCountryRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceCountryRequired.translateRequired) == true ? itemResidenceCountryRequired.translateRequired : -1} defaultMessage="Please input your Country!" /> }]} >
                                            <CountryDropdown
                                                value={this.state.residenceCountry}
                                                onChange={(val) => this.selectResidenceCountry(val)} />
                                        </FormAntd.Item>}

                                    {(itemResidenceCityAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCity" defaultMessage="City" />}
                                            name="ResidenceCity"
                                            rules={[{ required: itemResidenceCityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceCityRequired.translateRequired) == true ? itemResidenceCityRequired.translateRequired : -1} defaultMessage="Please input your City!" /> }]} >
                                            <RegionDropdown
                                                country={this.state.residenceCountry}
                                                value={this.state.residenceCity}
                                                onChange={(val) => this.selectResidenceRegion(val)} />
                                        </FormAntd.Item>}

                                    {(itemResidenceAddressAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoAddress" defaultMessage="Address" />}
                                            name="ResidenceAddress"
                                            rules={[{ required: itemResidenceAddressRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceAddressRequired.translateRequired) == true ? itemResidenceAddressRequired.translateRequired : -1} defaultMessage="Please input your Address!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.residenceAddress} onChange={(e) => this.setState({ residenceAddress: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemResidenceCompanyAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCompany" defaultMessage="Company" />}
                                            name="ResidenceCompany"
                                            rules={[{ required: itemResidenceCompanyRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceCompanyRequired.translateRequired) == true ? itemResidenceCompanyRequired.translateRequired : -1} defaultMessage="Please input your Company!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.residenceCompany} onChange={(e) => this.setState({ residenceCompany: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemResidencePersonelPhoneAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPhone" defaultMessage="Phone" />}
                                            name="ResidencePersonelPhone"
                                            rules={[{ required: itemResidencePersonelPhoneRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidencePersonelPhoneRequired.translateRequired) == true ? itemResidencePersonelPhoneRequired.translateRequired : -1} defaultMessage="Please input your Phone!" /> }]} >
                                            <PhoneInput
                                                country={"tr"}
                                                value={this.state.residencePersonelPhone}
                                                onChange={residencePersonelPhone => this.setState({ residencePersonelPhone })}
                                                isValid={(inputNumber, country, countries) => {
                                                    //var drm = countries.some((country) => {
                                                    //  var def = startsWith(inputNumber, country.dialCode);
                                                    //  return def;
                                                    //});  
                                                    var drm = false;
                                                    var obj = countries.find(p => p.countryCode == country.countryCode);
                                                    if (obj != null && obj != undefined) {
                                                        drm = startsWith(inputNumber, obj.dialCode);
                                                    }

                                                    // if(Boolean(drm) && drm==true){
                                                    //     if(country.name=="Saudi Arabia"){
                                                    //         if(inputNumber.length!=15){
                                                    //             drm = false;
                                                    //         }
                                                    //     }else{
                                                    //         if(inputNumber!=country.dialCode){
                                                    //             if(inputNumber.length!=country.format.split(".").length-1){
                                                    //                 drm = false;
                                                    //             }
                                                    //         }
                                                    //     }
                                                    // }

                                                    if (drm) {
                                                        if (!this.state.residencePersonelPhoneDrm) {
                                                            this.setState({
                                                                residencePersonelPhoneDrm: drm,
                                                            });
                                                        }
                                                    } else {
                                                        if (this.state.residencePersonelPhoneDrm) {
                                                            this.setState({
                                                                residencePersonelPhoneDrm: drm,
                                                            });
                                                        }
                                                    }

                                                    return drm;
                                                }}
                                                defaultErrorMessage="Invalid Phone Number"
                                            />
                                        </FormAntd.Item>}

                                    {(itemResidenceCompanyEmailAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCompanyEmail" defaultMessage="Company Email" />}
                                            name="ResidenceCompanyEmail"
                                            rules={[{ required: itemResidenceCompanyEmailRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceCompanyEmailRequired.translateRequired) == true ? itemResidenceCompanyEmailRequired.translateRequired : -1} defaultMessage="Please input your Company Email!" /> }]} >
                                            <Input style={{ width: '100%' }} onChange={this.handleChangeCompanyEmail}
                                                value={this.state.residenceCompanyEmail} />
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2"></Col>
                                        <Col sm="5" hidden={this.state.hiddenResidenceCompanyEmail}>
                                            <Form.Label style={{ color: 'red' }} column sm="10">
                                                Invalid Company Email
                                        </Form.Label>
                                        </Col>
                                    </Form.Group>

                                    {(itemResidencePersonalEmailAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPersonelEmail" defaultMessage="Personel Email" />}
                                            name="ResidencePersonalEmailEmail"
                                            rules={[{ required: itemResidencePersonalEmailRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidencePersonalEmailRequired.translateRequired) == true ? itemResidencePersonalEmailRequired.translateRequired : -1} defaultMessage="Please input your Personel Email!" /> }]} >
                                            <Input style={{ width: '100%' }} onChange={this.handleChangePersonelEmail}
                                                value={this.state.residencePersonalEmail} />
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2"></Col>
                                        <Col sm="5" hidden={this.state.hiddenResidencePersonelEmail}>
                                            <Form.Label style={{ color: 'red' }} column sm="10">
                                                Invalid Personel Email
                                        </Form.Label>
                                        </Col>
                                    </Form.Group>

                                    {(itemResidenceEmergencyPhoneAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoEmergencyPhone" defaultMessage="Emergency Phone" />}
                                            name="ResidenceEmergencyPhone"
                                            rules={[{ required: itemResidenceEmergencyPhoneRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceEmergencyPhoneRequired.translateRequired) == true ? itemResidenceEmergencyPhoneRequired.translateRequired : -1} defaultMessage="Please input your Emergency Phone!" /> }]} >
                                            <PhoneInput
                                                country={"tr"}
                                                value={this.state.residenceEmergencyPhone}
                                                onChange={val => this.setState({ residenceEmergencyPhone: val })}
                                                isValid={(inputNumber, country, countries) => {
                                                    var drm = false;
                                                    var obj = countries.find(p => p.countryCode == country.countryCode);
                                                    if (obj != null && obj != undefined) {
                                                        drm = startsWith(inputNumber, obj.dialCode);
                                                    }
                                                    if (drm) {
                                                        if (!this.state.residenceEmergencyPhoneDrm) {
                                                            this.setState({
                                                                residenceEmergencyPhoneDrm: drm,
                                                            });
                                                        }
                                                    } else {
                                                        if (this.state.residenceEmergencyPhoneDrm) {
                                                            this.setState({
                                                                residenceEmergencyPhoneDrm: drm,
                                                            });
                                                        }
                                                    }

                                                    return drm;
                                                }}
                                                defaultErrorMessage="Invalid Emergency Phone Number"
                                            />
                                        </FormAntd.Item>}

                                    {(itemResidenceEmergencyEmailAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoEmergencyEmail" defaultMessage="Emergency Email" />}
                                            name="ResidenceEmergencyEmail"
                                            rules={[{ required: itemResidenceEmergencyEmailRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemResidenceEmergencyEmailRequired.translateRequired) == true ? itemResidenceEmergencyEmailRequired.translateRequired : -1} defaultMessage="Please input your Emergency Email!" /> }]} >
                                            <Input style={{ width: '100%' }} onChange={this.handleChangeResidenceEmergencyEmail}
                                                value={this.state.residenceEmergencyEmail} />
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2"></Col>
                                        <Col sm="5" hidden={this.state.hiddenResidenceEmergencyEmail}>
                                            <Form.Label style={{ color: 'red' }} column sm="10">
                                                Invalid Emergency Email
                                        </Form.Label>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Form.Label column sm="3" style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage id="PersonalInfoHomeCountry" defaultMessage="Home Country" />
                                        </Form.Label>
                                    </Form.Group>

                                    {(itemHomeCountryAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoHomeCountry" defaultMessage="Home Country" />}
                                            name="HomeCountry"
                                            rules={[{ required: itemHomeCountryRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeCountryRequired.translateRequired) == true ? itemHomeCountryRequired.translateRequired : -1} defaultMessage="Please input your Home Country!" /> }]} >
                                            <CountryDropdown
                                                value={this.state.homeCountry}
                                                onChange={(val) => this.selectHomeCountry(val)} />
                                        </FormAntd.Item>}

                                    {(itemHomeCityAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoHomeCity" defaultMessage="Home City" />}
                                            name="HomeCity"
                                            rules={[{ required: itemHomeCityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeCityRequired.translateRequired) == true ? itemHomeCityRequired.translateRequired : -1} defaultMessage="Please input your Home City!" /> }]} >
                                            <RegionDropdown
                                                country={this.state.homeCountry}
                                                value={this.state.homeCity}
                                                onChange={(val) => this.selectHomeRegion(val)} />
                                        </FormAntd.Item>}

                                    {(itemHomeAddressAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoAddress" defaultMessage="Address" />}
                                            name="HomeAddress"
                                            rules={[{ required: itemHomeAddressRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeAddressRequired.translateRequired) == true ? itemHomeAddressRequired.translateRequired : -1} defaultMessage="Please input your Address!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.homeAddress} onChange={(e) => this.setState({ homeAddress: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemHomePersonalPhoneAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPhone" defaultMessage="Phone" />}
                                            name="HomePersonalPhone"
                                            rules={[{ required: itemHomePersonalPhoneRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomePersonalPhoneRequired.translateRequired) == true ? itemHomePersonalPhoneRequired.translateRequired : -1} defaultMessage="Please input your Phone!" /> }]} >
                                            <PhoneInput
                                                country={"tr"}
                                                value={this.state.homePersonalPhone}
                                                onChange={homePersonalPhone => this.setState({ homePersonalPhone })}
                                                isValid={(inputNumber, country, countries) => {
                                                    var drm = false;
                                                    var obj = countries.find(p => p.countryCode == country.countryCode);
                                                    if (obj != null && obj != undefined) {
                                                        drm = startsWith(inputNumber, obj.dialCode);
                                                    }
                                                    if (drm) {
                                                        if (!this.state.homePersonalPhoneDrm) {
                                                            this.setState({
                                                                homePersonalPhoneDrm: drm,
                                                            });
                                                        }
                                                    } else {
                                                        if (this.state.homePersonalPhoneDrm) {
                                                            this.setState({
                                                                homePersonalPhoneDrm: drm,
                                                            });
                                                        }
                                                    }

                                                    return drm;
                                                }}
                                                defaultErrorMessage="Invalid Phone Number"
                                            />
                                        </FormAntd.Item>}

                                    {(itemHomeEmergencyPhoneAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoEmergencyPhone" defaultMessage="Emergency Phone" />}
                                            name="HomeEmergencyPhone"
                                            rules={[{ required: itemHomeEmergencyPhoneRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeEmergencyPhoneRequired.translateRequired) == true ? itemHomeEmergencyPhoneRequired.translateRequired : -1} defaultMessage="Please input your Emergency Phone!" /> }]} >
                                            <PhoneInput
                                                country={"tr"}
                                                value={this.state.homeEmergencyPhone}
                                                onChange={homeEmergencyPhone => this.setState({ homeEmergencyPhone })}
                                                isValid={(inputNumber, country, countries) => {
                                                    var drm = false;
                                                    var obj = countries.find(p => p.countryCode == country.countryCode);
                                                    if (obj != null && obj != undefined) {
                                                        drm = startsWith(inputNumber, obj.dialCode);
                                                    }
                                                    if (drm) {
                                                        if (!this.state.homeEmergencyPhoneDrm) {
                                                            this.setState({
                                                                homeEmergencyPhoneDrm: drm,
                                                            });
                                                        }
                                                    } else {
                                                        if (this.state.homeEmergencyPhoneDrm) {
                                                            this.setState({
                                                                homeEmergencyPhoneDrm: drm,
                                                            });
                                                        }
                                                    }

                                                    return drm;
                                                }}
                                                defaultErrorMessage="Invalid Emergency Phone Number"
                                            />
                                        </FormAntd.Item>}

                                    {(itemHomeEmergencyEmailAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoEmergencyEmail" defaultMessage="Emergency Email" />}
                                            name="HomeEmergencyEmail"
                                            rules={[{ required: itemHomeEmergencyEmailRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeEmergencyEmailRequired.translateRequired) == true ? itemHomeEmergencyEmailRequired.translateRequired : -1} defaultMessage="Please input your Emergency Email!" /> }]} >
                                            <Input style={{ width: '100%' }} onChange={this.handleChangeHomeEmergencyEmail}
                                                value={this.state.homeEmergencyEmail} />
                                        </FormAntd.Item>}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2"></Col>
                                        <Col sm="5" hidden={this.state.hiddenHomeEmergencyEmail}>
                                            <Form.Label style={{ color: 'red' }} column sm="10">
                                                Invalid Emergency Email
                                        </Form.Label>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Form.Label column sm="3" style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage id="PersonalInfoSocialMediaAccounts" defaultMessage="Social Media Accounts" />
                                        </Form.Label>
                                    </Form.Group>

                                    {(itemSocialMediaAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoSocialMedia" defaultMessage="Social Media" />}
                                            name="SocialMedia"
                                            rules={[{ required: itemSocialMediaRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSocialMediaRequired.translateRequired) == true ? itemSocialMediaRequired.translateRequired : -1} defaultMessage="Please input your Social Media!" /> }]} >
                                            <Select
                                                name="socialMedia"
                                                style={{ width: '100%' }}
                                                value={this.state.socialMedia}
                                                onChange={this.handleChangePersonalInfoSocialMedia}

                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Input style={{ flex: 'auto' }} value={this.state.itemPersonalInfoSocialMedias}
                                                                onChange={(event) => { this.setState({ itemPersonalInfoSocialMedias: event.target.value }) }} />
                                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                onClick={this.addPersonalInfoSocialMedias} >
                                                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                                                        </div>
                                                    </div>
                                                )}>
                                                {this.state.PersonalInfoSocialMedias.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>
                                    }

                                    {(itemSocialAccountAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoSocialAccount" defaultMessage="Social Account" />}
                                            name="SocialAccount"
                                            rules={[{ required: itemSocialAccountRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSocialAccountRequired.translateRequired) == true ? itemSocialAccountRequired.translateRequired : -1} defaultMessage="Please input your Social Account!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.socialAccount} onChange={(e) => this.setState({ socialAccount: e.target.value })} />
                                        </FormAntd.Item>}

                                </div>
                                <br />
                                <br />
                            </FormAntd>
                        }
                    </div>

                    {/*<div hidden={this.state.hiddenTraining} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 2) && <div hidden={this.state.timelinePagesHidden[2]} style={{ backgroundColor: "white", marginTop: '3rem' }} className="content-section implementation">
                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateTraining == false ? this.updatePersonalInfoTraining : this.addPersonalInfoTraining}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefTraining} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextTrainingButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }
                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonTraining} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonTrainingVariant} >{this.state.createNewButtonTraining}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisTraining}>
                                    <br />
                                    {(itemNameOfTrainingAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoNameOfTraining" defaultMessage="Name of Training" />}
                                            name="NameOfTraining"
                                            rules={[{ required: itemNameOfTrainingRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNameOfTrainingRequired.translateRequired) == true ? itemNameOfTrainingRequired.translateRequired : -1} defaultMessage="Please input your Name of Training!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.nameOfTraining}
                                                onChange={(e) => this.setState({ nameOfTraining: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemDateOfTrainingAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDateOfTraining" defaultMessage="Date of Training" />}
                                            name="DateOfTraining"
                                            rules={[{ required: itemDateOfTrainingRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateOfTrainingRequired.translateRequired) == true ? itemDateOfTrainingRequired.translateRequired : -1} defaultMessage="Please input your Date of Training!" /> }]} >
                                            <RangePicker value={(this.state.dateOfTrainingBsl != null && this.state.dateOfTrainingBsl != undefined && this.state.dateOfTrainingBsl != ""
                                                && this.state.dateOfTrainingBts != null && this.state.dateOfTrainingBts != undefined && this.state.dateOfTrainingBts != "")
                                                ? [moment(this.state.dateOfTrainingBsl, 'DD-MM-YYYY'), moment(this.state.dateOfTrainingBts, 'DD-MM-YYYY')]
                                                : ""} format="DD-MM-YYYY" onChange={this.onChangeDateTraining} />
                                        </FormAntd.Item>}

                                    {(itemDurationAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDuration" defaultMessage="Duration" />}
                                            name="Duration"
                                            rules={[{ required: itemDurationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDurationRequired.translateRequired) == true ? itemDurationRequired.translateRequired : -1} defaultMessage="Please input your Duration!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.durationTraining} onChange={(e) => this.setState({ durationTraining: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemOrganizerAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoOrganizer" defaultMessage="Organizer" />}
                                            name="Organizer"
                                            rules={[{ required: itemOrganizerRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOrganizerRequired.translateRequired) == true ? itemOrganizerRequired.translateRequired : -1} defaultMessage="Please input your Organizer!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.organizer} onChange={(e) => this.setState({ organizer: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemCertificateValidityAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCertificateValidity" defaultMessage="Certificate Validity" />}
                                            name="CertificateValidity"
                                            rules={[{ required: itemCertificateValidityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCertificateValidityRequired.translateRequired) == true ? itemCertificateValidityRequired.translateRequired : -1} defaultMessage="Please input your Certificate Validity!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.certificateValidity} onChange={(e) => this.setState({ certificateValidity: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemAttachTrainingCertificateAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoAttachTrainingCertificate" defaultMessage="Attach Training Certificate" />}
                                            name="AttachTrainingCertificate"
                                            rules={[{ required: itemAttachTrainingCertificateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAttachTrainingCertificateRequired.translateRequired) == true ? itemAttachTrainingCertificateRequired.translateRequired : -1} defaultMessage="Please input your Attach Training Certificate!" /> }]} >
                                            <Upload {...props}
                                                showUploadList={true}
                                                fileList={this.state.AttachTrainingCertificateFileUpload}
                                                onChange={({ fileList }) => {
                                                    if (fileList.length == 1) {
                                                        var attachTrainingCertificate = "";
                                                        if (Boolean(fileList[0].response)) {
                                                            attachTrainingCertificate = fileList[0].response.url;
                                                        }
                                                        this.setState({
                                                            AttachTrainingCertificateFileUpload: fileList,
                                                            attachTrainingCertificate: attachTrainingCertificate,
                                                        });
                                                    }
                                                }}
                                                beforeUpload={(fileList) => {
                                                    if (this.state.AttachTrainingCertificateFileUpload.length > 0) {
                                                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                                onRemove={({ fileList }) => {
                                                    this.setState({
                                                        AttachTrainingCertificateFileUpload: [],
                                                        attachTrainingCertificate: "",
                                                    })
                                                }
                                                }>
                                                <ReactButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                    <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachTrainingCertificate" defaultMessage="Attach Training Certificate" />
                                                </ReactButton>
                                            </Upload>
                                        </FormAntd.Item>}

                                    <div hidden={this.state.hiddenSaveTraining}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    <ReactButton id="PersonalInfoAddTrainingButton"
                                                        style={{ width: '100%' }} type="submit"
                                                        variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div hidden={this.state.hiddenUpdateTraining}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3"></Col>
                                                <Col sm="2">
                                                    <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelTraining} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsTraining} dataSource={this.state.personalInfoTrainingTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }
                    </div>}

                    {/*<div hidden={this.state.hiddenContract} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 3) && <div hidden={this.state.timelinePagesHidden[3]} style={{ marginTop: '3rem' }} className="content-section implementation">

                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateContract == false ? this.updatePersonalInfoContract : this.addPersonalInfoContract}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefContract} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextContractButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }
                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonContract} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonContractVariant} >{this.state.createNewButtonContract}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisContract}>
                                    <br />
                                    {(itemContractDateAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoContractDate" defaultMessage="Contract Date" />}
                                            name="ContractDate"
                                            rules={[{ required: itemContractDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemContractDateRequired.translateRequired) == true ? itemContractDateRequired.translateRequired : -1} defaultMessage="Please input your Contract Date!" /> }]} >
                                            <DatePicker disabledDate={this.disabledDate} value={(this.state.contractDate != null && this.state.contractDate != undefined && this.state.contractDate != "")
                                                ? moment(this.state.contractDate, 'DD-MM-YYYY') : ""} format="DD-MM-YYYY" onChange={(value) => {
                                                    this.setState({
                                                        contractDate: value
                                                    });
                                                }} />
                                        </FormAntd.Item>}

                                    {(itemContractDurationAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoContractDuration" defaultMessage="Contract Duration" />}
                                            name="ContractDuration"
                                            rules={[{ required: itemContractDurationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemContractDurationRequired.translateRequired) == true ? itemContractDurationRequired.translateRequired : -1} defaultMessage="Please input your Contract Duration!" /> }]} >
                                            <Select value={this.state.contractDuration} onChange={this.handleChangeContractDuration}
                                            >
                                                {this.state.contractDurations.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemAnnualVacationDaysAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoAnnualVacationDays" defaultMessage="Annual Vacation Days" />}
                                            name="AnnualVacationDays"
                                            rules={[{ required: itemAnnualVacationDaysRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAnnualVacationDaysRequired.translateRequired) == true ? itemAnnualVacationDaysRequired.translateRequired : -1} defaultMessage="Please input your Annual Vacation Days!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }}
                                                onChange={(e) => this.setState({ annualVacationDays: e })} value={this.state.annualVacationDays} />
                                        </FormAntd.Item>}

                                    <RowAnt gutter={8}>
                                      
                                        <ColAnt span={8}>
                                            {(itemAnnualVacationPeriodAttributes.visible) &&
                                                <FormAntd.Item
                                                    {...layoutContractPeriod} 
                                                    label={<FormattedMessage id="PersonalInfoAnnualVacationPeriod" defaultMessage="Annual Vacation Period" />}
                                                    name="AnnualVacationPeriod"
                                                    rules={[{ required: itemAnnualVacationPeriodRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAnnualVacationPeriodRequired.translateRequired) == true ? itemAnnualVacationPeriodRequired.translateRequired : -1} defaultMessage="Please input your Annual Vacation Period!" /> }]} >
                                                    <InputNumber min={0} style={{ width: '100%' }}
                                                        onChange={(e) => this.setState({ annualVacationPeriod: e })} value={this.state.annualVacationPeriod} />
                                                </FormAntd.Item>}
                                        </ColAnt>
                                        <ColAnt span={4}>
                                            {(itemAnnualVacationPeriodTypeAttributes.visible) &&
                                                <FormAntd.Item
                                                
                                                    // label={<FormattedMessage id="PersonalInfoAnnualVacationPeriodType" defaultMessage="Annual Vacation Period" />}
                                                    name="AnnualVacationPeriodType"
                                                    rules={[{ required: itemAnnualVacationPeriodTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAnnualVacationPeriodTypeRequired.translateRequired) == true ? itemAnnualVacationPeriodTypeRequired.translateRequired : -1} defaultMessage="Please input your Annual Vacation Period!" /> }]} >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        value={this.state.annualVacationPeriodType}
                                                        onChange={this.handleChangeAnnualVacationPeriodType}>

                                                        <Option key={1} value={1}>{"Year"}</Option>
                                                        <Option key={2} value={2}>{"Month"}</Option>
                                                    </Select>
                                                </FormAntd.Item>}
                                        </ColAnt>
                                    </RowAnt>

                                    {(itemBasicSalaryAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoBasicSalary" defaultMessage="Basic Salary" />}
                                            name="BasicSalary"
                                            rules={[{ required: itemBasicSalaryRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBasicSalaryRequired.translateRequired) == true ? itemBasicSalaryRequired.translateRequired : -1} defaultMessage="Please input your Basic Salary!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }}
                                                onChange={(e) => this.setState({ basicSalary: e })} value={this.state.basicSalary} />
                                        </FormAntd.Item>}

                                    {(itemHomeAllowanceAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoHomeAllowance" defaultMessage="Home Allowance" />}
                                            name="HomeAllowance"
                                            rules={[{ required: itemHomeAllowanceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeAllowanceRequired.translateRequired) == true ? itemHomeAllowanceRequired.translateRequired : -1} defaultMessage="Please input your Home Allowance!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }}
                                                onChange={(e) => this.setState({ homeAllowance: e })} value={this.state.homeAllowance} />
                                        </FormAntd.Item>}

                                    {(itemTransportationAllowanceAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoTransportationAllowance" defaultMessage="Transportation Allowance" />}
                                            name="TransportationAllowance"
                                            rules={[{ required: itemTransportationAllowanceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTransportationAllowanceRequired.translateRequired) == true ? itemTransportationAllowanceRequired.translateRequired : -1} defaultMessage="Please input your Transportation Allowance!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }}
                                                onChange={(e) => this.setState({ transportationAllowance: e })} value={this.state.transportationAllowance} />
                                        </FormAntd.Item>}

                                    {(itemFoodAllowanceAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoFoodAllowance" defaultMessage="Food Allowance" />}
                                            name="FoodAllowance"
                                            rules={[{ required: itemFoodAllowanceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemFoodAllowanceRequired.translateRequired) == true ? itemFoodAllowanceRequired.translateRequired : -1} defaultMessage="Please input your Food Allowance!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }}
                                                onChange={(e) => this.setState({ foodAllowance: e })} value={this.state.foodAllowance} />
                                        </FormAntd.Item>}

                                    {(itemOtherPaymentsAttributes.visible) &&
                                        <FormAntd.Item
                                            {...layoutContract}
                                            label={<FormattedMessage id="PersonalInfoOtherPayments" defaultMessage="Other Payments" />}
                                            name="OtherPayments"
                                            rules={[{ required: itemOtherPaymentsRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOtherPaymentsRequired.translateRequired) == true ? itemOtherPaymentsRequired.translateRequired : -1} defaultMessage="Please input your Other Payments!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }}
                                                onChange={(e) => this.setState({ otherPayments: e })} value={this.state.otherPayments} />
                                        </FormAntd.Item>}

                                    <div hidden={this.state.hiddenSaveContract}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    <ReactButton id="PersonalInfoAddContractButton"
                                                        style={{ width: '100%' }} type="submit"
                                                        variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div hidden={this.state.hiddenUpdateContract}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3"></Col>
                                                <Col sm="2">
                                                    <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelContract} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsContract} dataSource={this.state.personalInfoContractTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }
                    </div>}

                    {/*<div hidden={this.state.hiddenExperiences} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 4) && <div hidden={this.state.timelinePagesHidden[4]} style={{ marginTop: '3rem' }} className="content-section implementation">

                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateExperiences == false ? this.updatePersonalInfoExperiences : this.addPersonalInfoExperiences}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefExperiences} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextExperiencesButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }
                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonExperiences} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonExperiencesVariant} >{this.state.createNewButtonExperiences}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisExperiences}>
                                    <br />
                                    {(itemWorkPeriodAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoWorkPeriod" defaultMessage="Work Period" />}
                                            name="WorkPeriod"
                                            rules={[{ required: itemWorkPeriodRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemWorkPeriodRequired.translateRequired) == true ? itemWorkPeriodRequired.translateRequired : -1} defaultMessage="Please input your Work Period!" /> }]} >
                                            <RangePicker value={(this.state.workPeriodStart != null && this.state.workPeriodStart != undefined && this.state.workPeriodStart != ""
                                                && this.state.workPeriodEnd != null && this.state.workPeriodEnd != undefined && this.state.workPeriodEnd != "")
                                                ? [moment(this.state.workPeriodStart, 'DD-MM-YYYY'), moment(this.state.workPeriodEnd, 'DD-MM-YYYY')]
                                                : ""} format="DD-MM-YYYY" onChange={this.onChangeDateExperiences} />
                                        </FormAntd.Item>}

                                    {(itemCompanyAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCompany" defaultMessage="Company" />}
                                            name="Company"
                                            rules={[{ required: itemCompanyRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCompanyRequired.translateRequired) == true ? itemCompanyRequired.translateRequired : -1} defaultMessage="Please input your Company!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.expCompany} onChange={(e) => this.setState({ expCompany: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemPositionAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoPosition" defaultMessage="Position" />}
                                            name="Position"
                                            rules={[{ required: itemPositionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPositionRequired.translateRequired) == true ? itemPositionRequired.translateRequired : -1} defaultMessage="Please input your Position!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.expPosition} onChange={(e) => this.setState({ expPosition: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemCountryAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCountry" defaultMessage="Country" />}
                                            name="Country"
                                            rules={[{ required: itemCountryRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCountryRequired.translateRequired) == true ? itemCountryRequired.translateRequired : -1} defaultMessage="Please input your Country!" /> }]} >
                                            <CountryDropdown
                                                value={this.state.expCountry}
                                                onChange={(val) => this.selectResidenceExpCountry(val)} />
                                        </FormAntd.Item>}

                                    {(itemCityAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoCity" defaultMessage="City" />}
                                            name="City"
                                            rules={[{ required: itemCityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCityRequired.translateRequired) == true ? itemCityRequired.translateRequired : -1} defaultMessage="Please input your City!" /> }]} >
                                            <RegionDropdown
                                                country={this.state.expCountry}
                                                value={this.state.expCity}
                                                onChange={(val) => this.selectResidenceExRegion(val)} />
                                        </FormAntd.Item>}

                                    {(itemProjectAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoProject" defaultMessage="Project" />}
                                            name="Project"
                                            rules={[{ required: itemProjectRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemProjectRequired.translateRequired) == true ? itemProjectRequired.translateRequired : -1} defaultMessage="Please input your Project!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.expProject} onChange={(e) => this.setState({ expProject: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemClientAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoClient" defaultMessage="Client" />}
                                            name="Client"
                                            rules={[{ required: itemClientRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemClientRequired.translateRequired) == true ? itemClientRequired.translateRequired : -1} defaultMessage="Please input your Client!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.expClient} onChange={(e) => this.setState({ expClient: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemApprovalOfClientAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                            <Col sm="3"></Col>
                                            <Col sm="3">
                                                <Checkbox checked={this.state.approvalOfClientCheck} onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        this.setState({
                                                            AttachApproveFileUpload: [],
                                                            approvalOfClient: "",
                                                        })
                                                    }
                                                    this.setState({
                                                        approvalOfClientCheck: e.target.checked,
                                                        hiddenApprovalOfClient: !e.target.checked,
                                                    });
                                                }}><FormattedMessage id="PersonalInfoApprovalOfClient" defaultMessage="Approval of Client" /></Checkbox>
                                            </Col>
                                            <Col hidden={this.state.hiddenApprovalOfClient} sm="2">
                                                <Upload {...props}
                                                    showUploadList={true}
                                                    fileList={this.state.AttachApproveFileUpload}
                                                    onChange={({ fileList }) => {
                                                        if (fileList.length == 1) {
                                                            var approvalOfClient = "";
                                                            if (Boolean(fileList[0].response)) {
                                                                approvalOfClient = fileList[0].response.url;
                                                            }
                                                            this.setState({
                                                                AttachApproveFileUpload: fileList,
                                                                approvalOfClient: approvalOfClient,
                                                            });
                                                        }
                                                    }}
                                                    beforeUpload={(fileList) => {
                                                        if (this.state.AttachApproveFileUpload.length > 0) {
                                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                            return false;
                                                        }
                                                        return true;
                                                    }}
                                                    onRemove={({ fileList }) => {
                                                        this.setState({
                                                            AttachApproveFileUpload: [],
                                                            approvalOfClient: "",
                                                        })
                                                    }
                                                    }>
                                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                        <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachApprove" defaultMessage="Attach Approve" />
                                                    </AntdButton>
                                                </Upload>
                                            </Col>
                                            <Col sm="1" hidden={(this.state.approvalOfClient != null && this.state.approvalOfClient != undefined && this.state.approvalOfClient != "") ? false : true}>
                                                <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(this.state.approvalOfClient)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                                </AntdButton>
                                            </Col>
                                        </Form.Group>}

                                    {(itemBriefAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoBrief" defaultMessage="Brief Description of Work Done" />}
                                            name="Brief"
                                            rules={[{ required: itemBriefRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBriefRequired.translateRequired) == true ? itemBriefRequired.translateRequired : -1} defaultMessage="Please input your Brief Description of Work Done!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.expBrief} onChange={(e) => this.setState({ expBrief: e.target.value })} />
                                        </FormAntd.Item>}

                                    <div hidden={this.state.hiddenSaveExperiences}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    <ReactButton id="PersonalInfoAddExperiencesButton"
                                                        style={{ width: '100%' }} type="submit"
                                                        variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div hidden={this.state.hiddenUpdateExperiences}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3"></Col>
                                                <Col sm="2">
                                                    <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelExperiences} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsExperiences} dataSource={this.state.personalInfoExperiencesTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }

                    </div>}

                    {/*<div hidden={this.state.hiddenEducation} style={{ marginTop: '3rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 5) && <div hidden={this.state.timelinePagesHidden[5]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateEducation == false ? this.updatePersonalInfoEducation : this.addPersonalInfoEducation}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefEducation} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextEducationButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }
                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonEducation} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonEducationVariant} >{this.state.createNewButtonEducation}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisEducation}>
                                    <br />
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                        <Form.Label style={{ textAlign: 'center', color: 'black', fontSize: '1.25rem' }} column sm="11">
                                            Please start to encode from your latest graduation to back.
                                    </Form.Label>
                                    </Form.Group>

                                    {(itemGraduationAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoGraduation" defaultMessage="Graduation" />}
                                            name="Graduation"
                                            rules={[{ required: itemGraduationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemGraduationRequired.translateRequired) == true ? itemGraduationRequired.translateRequired : -1} defaultMessage="Please input your Graduation!" /> }]} >
                                            <Select name="PersonalInfoGraduation"
                                                style={{ width: '100%' }}
                                                value={this.state.graduation}
                                                onChange={this.handleChangeGraduation}>

                                                {this.state.graduations.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemDegreeAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDegree" defaultMessage="Degree" />}
                                            name="Degree"
                                            rules={[{ required: itemDegreeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDegreeRequired.translateRequired) == true ? itemDegreeRequired.translateRequired : -1} defaultMessage="Please input your Degree!" /> }]} >
                                            <Select name="PersonalInfoDegree"
                                                style={{ width: '100%' }}
                                                value={this.state.degree}
                                                onChange={this.handleChangeDegree}>

                                                {this.state.degrees.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemNameOfSchoolAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoNameOfSchool" defaultMessage="Name of School" />}
                                            name="NameOfSchool"
                                            rules={[{ required: itemNameOfSchoolRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNameOfSchoolRequired.translateRequired) == true ? itemNameOfSchoolRequired.translateRequired : -1} defaultMessage="Please input your Name of School!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.nameOfSchool} onChange={(e) => this.setState({ nameOfSchool: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemEduCountryAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoEduCountry" defaultMessage="Country" />}
                                            name="EduCountry"
                                            rules={[{ required: itemEduCountryRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemEduCountryRequired.translateRequired) == true ? itemEduCountryRequired.translateRequired : -1} defaultMessage="Please input your Country!" /> }]} >
                                            <CountryDropdown
                                                value={this.state.eduCountry}
                                                onChange={(val) => this.selectResidenceEduCountry(val)} />
                                        </FormAntd.Item>}

                                    {(itemDateOfGraduationAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoDateOfGraduation" defaultMessage="Date of Graduation" />}
                                            name="DateOfGraduation"
                                            rules={[{ required: itemDateOfGraduationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateOfGraduationRequired.translateRequired) == true ? itemDateOfGraduationRequired.translateRequired : -1} defaultMessage="Please input your Date of Graduation!" /> }]} >
                                            <DatePicker value={moment(this.state.dateOfGraduation, 'DD-MM-YYYY')} format="DD-MM-YYYY" onChange={this.onChangeDateEducation} />
                                        </FormAntd.Item>}

                                    {(itemGraduationScoreAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                            <Col sm="1"></Col>
                                            <Col sm="2">
                                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1rem' }}>
                                                    <FormattedMessage id="PersonalInfoGraduationScore" defaultMessage="Graduation Score:" />
                                                </Form.Label>
                                            </Col>

                                            <Col sm="1">
                                                <InputNumber min={0} style={{ width: '100%' }}
                                                    onChange={(e) => this.setState({ graduationScore: e })} value={this.state.graduationScore} />
                                            </Col>
                                            <p style={{ marginTop: '0.5rem' }}>of</p>
                                            <Col sm="1">
                                                <InputNumber min={0} style={{ width: '100%' }}
                                                    onChange={(e) => this.setState({ graduationMaxScore: e })} value={this.state.graduationMaxScore} />
                                            </Col>
                                            <Col sm="4">
                                            </Col>
                                        </Form.Group>}

                                    {(itemAttachGraduationCertificateAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                            <Col sm="3"></Col>
                                            <Col sm="4">
                                                <Upload {...props}
                                                    showUploadList={true}
                                                    fileList={this.state.AttachGraduationCertificateFileUpload}
                                                    onChange={({ fileList }) => {
                                                        if (fileList.length == 1) {
                                                            var attachGraduationCertificate = "";
                                                            if (Boolean(fileList[0].response)) {
                                                                attachGraduationCertificate = fileList[0].response.url;
                                                            }
                                                            this.setState({
                                                                AttachGraduationCertificateFileUpload: fileList,
                                                                attachGraduationCertificate: attachGraduationCertificate,
                                                            });
                                                        }
                                                    }}
                                                    beforeUpload={(fileList) => {
                                                        if (this.state.AttachGraduationCertificateFileUpload.length > 0) {
                                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                            return false;
                                                        }
                                                        return true;
                                                    }}
                                                    onRemove={({ fileList }) => {
                                                        this.setState({
                                                            AttachGraduationCertificateFileUpload: [],
                                                            attachGraduationCertificate: "",
                                                        })
                                                    }
                                                    }>
                                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                        <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachGraduationCertificate" defaultMessage="Attach Graduation Certificate" />
                                                    </AntdButton>
                                                </Upload>
                                            </Col>
                                            <Col sm="1" hidden={(this.state.attachGraduationCertificate != null && this.state.attachGraduationCertificate != undefined && this.state.attachGraduationCertificate != "") ? false : true}>
                                                <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(this.state.attachGraduationCertificate)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                                </AntdButton>
                                            </Col>
                                        </Form.Group>}

                                    <div hidden={this.state.hiddenSaveEducation}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    <ReactButton id="PersonalInfoAddEducationButton"
                                                        style={{ width: '100%' }} type="submit"
                                                        variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div hidden={this.state.hiddenUpdateEducation}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3"></Col>
                                                <Col sm="2">
                                                    <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelEducation} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsEducation} dataSource={this.state.personalInfoEducationTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }
                    </div>}

                    {/*<div hidden={this.state.hiddenLanguage} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 6) && <div hidden={this.state.timelinePagesHidden[6]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateLanguageSkills == false ? this.updatePersonalInfoLanguageSkills : this.addPersonalInfoLanguageSkills}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefLanguageSkills} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextLanguageSkillsButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }
                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonLanguageSkills} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonLanguageSkillsVariant} >{this.state.createNewButtonLanguageSkills}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisLanguageSkills}>
                                    <br />
                                    {(itemLanguageAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoLanguage" defaultMessage="Language" />}
                                            name="Language"
                                            rules={[{ required: itemLanguageRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLanguageRequired.translateRequired) == true ? itemLanguageRequired.translateRequired : -1} defaultMessage="Please input your Language!" /> }]} >
                                            <Select name="PersonalInfoLanguage"
                                                style={{ width: '100%' }}
                                                value={this.state.language}
                                                onChange={this.handleChangeLanguage}>

                                                {this.state.languages.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemUnderstandingAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoUnderstanding" defaultMessage="Understanding" />}
                                            name="Understanding"
                                            rules={[{ required: itemUnderstandingRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemUnderstandingRequired.translateRequired) == true ? itemUnderstandingRequired.translateRequired : -1} defaultMessage="Please input your Understanding!" /> }]} >
                                            <Select name="PersonalInfoUnderstanding"
                                                style={{ width: '100%' }}
                                                value={this.state.understanding}
                                                onChange={this.handleChangeUnderstanding}>

                                                {this.state.languageLevels.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemReadingAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoReading" defaultMessage="Reading" />}
                                            name="Reading"
                                            rules={[{ required: itemReadingRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemReadingRequired.translateRequired) == true ? itemReadingRequired.translateRequired : -1} defaultMessage="Please input your Reading!" /> }]} >
                                            <Select name="PersonalInfoReading"
                                                style={{ width: '100%' }}
                                                value={this.state.reading}
                                                onChange={this.handleChangeReading}>

                                                {this.state.languageLevels.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemWritingAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoWriting" defaultMessage="Writing" />}
                                            name="Writing"
                                            rules={[{ required: itemWritingRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemWritingRequired.translateRequired) == true ? itemWritingRequired.translateRequired : -1} defaultMessage="Please input your Writing!" /> }]} >
                                            <Select name="PersonalInfoWriting"
                                                style={{ width: '100%' }}
                                                value={this.state.writing}
                                                onChange={this.handleChangeWriting}>

                                                {this.state.languageLevels.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemInternationalLanguageTestAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoInternationalLanguageTest" defaultMessage="International Language Test" />}
                                            name="InternationalLanguageTest"
                                            rules={[{ required: itemInternationalLanguageTestRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInternationalLanguageTestRequired.translateRequired) == true ? itemInternationalLanguageTestRequired.translateRequired : -1} defaultMessage="Please input your International Language Test!" /> }]} >
                                            <Select name="PersonalInfoInternationalLanguageTest"
                                                style={{ width: '100%' }}
                                                value={this.state.internationalLanguageTest}
                                                onChange={this.handleChangeInternationalLanguageTest}>

                                                {this.state.languageTests.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemScoreAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoScore" defaultMessage="Score" />}
                                            name="Score"
                                            rules={[{ required: itemScoreRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemScoreRequired.translateRequired) == true ? itemScoreRequired.translateRequired : -1} defaultMessage="Please input your Score!" /> }]} >
                                            <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ score: e })} value={this.state.score} />
                                        </FormAntd.Item>}

                                    {(itemAttachLanguageTestResultAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                            <Col sm="3"></Col>
                                            <Col sm="4">
                                                <Upload {...props}
                                                    showUploadList={true}
                                                    fileList={this.state.AttachLanguageTestResultFileUpload}
                                                    onChange={({ fileList }) => {
                                                        if (fileList.length == 1) {
                                                            var attachLanguageTestResult = "";
                                                            if (Boolean(fileList[0].response)) {
                                                                attachLanguageTestResult = fileList[0].response.url;
                                                            }
                                                            this.setState({
                                                                AttachLanguageTestResultFileUpload: fileList,
                                                                attachLanguageTestResult: attachLanguageTestResult,
                                                            });
                                                        }
                                                    }}
                                                    beforeUpload={(fileList) => {
                                                        if (this.state.AttachLanguageTestResultFileUpload.length > 0) {
                                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                            return false;
                                                        }
                                                        return true;
                                                    }}
                                                    onRemove={({ fileList }) => {
                                                        this.setState({
                                                            AttachLanguageTestResultFileUpload: [],
                                                            attachLanguageTestResult: "",
                                                        })
                                                    }
                                                    }>
                                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                        <UploadOutlined /> <FormattedMessage id="PersonalInfoAttachLanguageTestResult" defaultMessage="Attach Graduation Certificate" />
                                                    </AntdButton>
                                                </Upload>
                                            </Col>
                                            <Col sm="1" hidden={(this.state.attachLanguageTestResult != null && this.state.attachLanguageTestResult != undefined && this.state.attachLanguageTestResult != "") ? false : true}>
                                                <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(this.state.attachLanguageTestResult)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                                </AntdButton>
                                            </Col>
                                        </Form.Group>}

                                    <div hidden={this.state.hiddenSaveLanguageSkills}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    <ReactButton id="PersonalInfoAddLanguageSkillsButton"
                                                        style={{ width: '100%' }} type="submit"
                                                        variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div hidden={this.state.hiddenUpdateLanguageSkills}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3"></Col>
                                                <Col sm="2">
                                                    <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelLanguageSkills} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsLanguageSkills} dataSource={this.state.personalInfoLanguageSkillsTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }
                    </div>}

                    {/*<div hidden={this.state.hiddenBank} style={{ marginTop: '2rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 7) && <div hidden={this.state.timelinePagesHidden[7]} style={{ marginTop: '2rem' }} className="content-section implementation">

                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateBankAccounts == false ? this.updatePersonalInfoBankAccounts : this.addPersonalInfoBankAccounts}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefBankAccounts} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextBankAccountsButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }

                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonBankAccounts} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonBankAccountsVariant} >{this.state.createNewButtonBankAccounts}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisBankAccounts}>
                                    <br />
                                    {(itemAccountTypeAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoAccountType" defaultMessage="Account Type" />}
                                            name="AccountType"
                                            rules={[{ required: itemAccountTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAccountTypeRequired.translateRequired) == true ? itemAccountTypeRequired.translateRequired : -1} defaultMessage="Please input your Account Type!" /> }]} >
                                            <Select name="PersonalInfoAccountType"
                                                style={{ width: '100%' }}
                                                value={this.state.accountType}
                                                onChange={this.handleChangeAccountType}>
                                                <Option key={"Debit"} value={"Debit"}>{"Debit"}</Option>
                                                <Option key={"Loan"} value={"Loan"}>{"Loan"}</Option>
                                                <Option key={"Credit Card"} value={"Credit Card"}>{"Credit Card"}</Option>
                                            </Select>
                                        </FormAntd.Item>}

                                    <div hidden={this.state.hiddenAccountTypeBank}>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                            <Col sm="1"></Col>
                                            <Col sm="2">
                                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                                    <FormattedMessage id="PersonalInfoBankAccounts" defaultMessage="Bank Accounts:" />
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>

                                        {(itemNameOfBankAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoNameOfBank" defaultMessage="Name of Bank" />}
                                                name="NameOfBank"
                                                rules={[{ required: this.state.hiddenAccountTypeBank == false ? itemNameOfBankRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemNameOfBankRequired.translateRequired) == true ? itemNameOfBankRequired.translateRequired : -1} defaultMessage="Please input your Name of Bank!" /> }]} >
                                                <Select name="PersonalInfoNameOfBank"
                                                    style={{ width: '100%' }}
                                                    value={this.state.bankName}
                                                    onChange={this.handleChangeNameOfBank}>

                                                    {this.state.banks.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>
                                            </FormAntd.Item>}

                                        {(itemBranchAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoBranch" defaultMessage="Branch" />}
                                                name="Branch"
                                                rules={[{ required: this.state.hiddenAccountTypeBank == false ? itemBranchRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemBranchRequired.translateRequired) == true ? itemBranchRequired.translateRequired : -1} defaultMessage="Please input your Branch!" /> }]} >
                                                <Select name="PersonalInfoBranch"
                                                    style={{ width: '100%' }}
                                                    value={this.state.branch}
                                                    onChange={this.handleChangeBranch}>

                                                    {this.state.branches.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>
                                            </FormAntd.Item>}

                                        {(itemAccountNoAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoAccountNo" defaultMessage="Account No" />}
                                                name="AccountNo"
                                                rules={[{ required: this.state.hiddenAccountTypeBank == false ? itemAccountNoRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemAccountNoRequired.translateRequired) == true ? itemAccountNoRequired.translateRequired : -1} defaultMessage="Please input your Account No!" /> }]} >
                                                <Input style={{ width: '100%' }} value={this.state.accountNo} onChange={(e) => this.setState({ accountNo: e.target.value })} />
                                            </FormAntd.Item>}

                                        {(itemIBANNoAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoIBANNo" defaultMessage="IBAN No" />}
                                                name="IBANNo"
                                                rules={[{ required: this.state.hiddenAccountTypeBank == false ? itemIBANNoRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemIBANNoRequired.translateRequired) == true ? itemIBANNoRequired.translateRequired : -1} defaultMessage="Please input your IBAN No!" /> }]} >
                                                <Input style={{ width: '100%' }} value={this.state.ibanNo} onChange={(e) => this.setState({ ibanNo: e.target.value })} />
                                            </FormAntd.Item>}

                                    </div>

                                    <div hidden={this.state.hiddenAccountTypeCredit}>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                            <Col sm="3">
                                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                                    <FormattedMessage id="PersonalInfoCreditCardAccounts" defaultMessage="Credit Card Accounts:" />
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>

                                        {(itemNameOfBankCreditAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoNameOfBankCredit" defaultMessage="Name of Bank" />}
                                                name="NameOfBankCredit"
                                                rules={[{ required: this.state.hiddenAccountTypeCredit == false ? itemNameOfBankCreditRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemNameOfBankCreditRequired.translateRequired) == true ? itemNameOfBankCreditRequired.translateRequired : -1} defaultMessage="Please input your Name of Bank!" /> }]} >
                                                <Select name="PersonalInfoNameOfBankCredit"
                                                    style={{ width: '100%' }}
                                                    value={this.state.bankName}
                                                    onChange={this.handleChangeNameOfBank}>

                                                    {this.state.banks.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>
                                            </FormAntd.Item>}

                                        {(itemCreditCardNoAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoCreditCardNo" defaultMessage="Credit Card No" />}
                                                name="CreditCardNo"
                                                rules={[{ required: this.state.hiddenAccountTypeCredit == false ? itemCreditCardNoRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemCreditCardNoRequired.translateRequired) == true ? itemCreditCardNoRequired.translateRequired : -1} defaultMessage="Please input your Credit Card No!" /> }]} >
                                                <Input style={{ width: '100%' }} value={this.state.accountNo} onChange={(e) => this.setState({ accountNo: e.target.value })} />
                                            </FormAntd.Item>}

                                        {(itemCutOffDateAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoCutOffDate" defaultMessage="Cut Off Date" />}
                                                name="CutOffDate"
                                                rules={[{ required: this.state.hiddenAccountTypeCredit == false ? itemCutOffDateRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemCutOffDateRequired.translateRequired) == true ? itemCutOffDateRequired.translateRequired : -1} defaultMessage="Please input your Cut Off Date!" /> }]} >
                                                <DatePicker value={moment(this.state.cutOffDate, 'DD-MM-YYYY')} format="DD-MM-YYYY" onChange={this.onChangeDateCutOffDate} />
                                            </FormAntd.Item>}

                                        {(itemPaymentDateAttributes.visible) &&
                                            <FormAntd.Item
                                                label={<FormattedMessage id="PersonalInfoPaymentDate" defaultMessage="Payment Date" />}
                                                name="PaymentDate"
                                                rules={[{ required: this.state.hiddenAccountTypeCredit == false ? itemPaymentDateRequired.canBeRequired : false, message: <FormattedMessage id={Boolean(itemPaymentDateRequired.translateRequired) == true ? itemPaymentDateRequired.translateRequired : -1} defaultMessage="Please input your Payment Date!" /> }]} >
                                                <DatePicker value={moment(this.state.paymentDate, 'DD-MM-YYYY')} format="DD-MM-YYYY" onChange={this.onChangeDatePaymentDate} />
                                            </FormAntd.Item>}
                                    </div>

                                    {(this.state.hiddenAccountTypeCredit == false || this.state.hiddenAccountTypeBank == false) &&
                                        <div hidden={this.state.hiddenSaveBankAccounts}>
                                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="5">
                                                        <ReactButton id="PersonalInfoAddBankAccountsButton"
                                                            style={{ width: '100%' }} type="submit"
                                                            variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                    </Col>
                                                    <Col sm="4">
                                                    </Col>
                                                </Form.Group>
                                            }
                                        </div>
                                    }
                                    {(this.state.hiddenAccountTypeCredit == false || this.state.hiddenAccountTypeBank == false) &&
                                        <div hidden={this.state.hiddenUpdateBankAccounts}>
                                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="3"></Col>
                                                    <Col sm="2">
                                                        <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelBankAccounts} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="4">
                                                    </Col>
                                                </Form.Group>
                                            }
                                        </div>
                                    }
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsBankAccounts} dataSource={this.state.personalInfoBankAccountsTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }
                    </div>}

                    {/*<div hidden={this.state.hiddenSocial} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 8) && <div hidden={this.state.timelinePagesHidden[8]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        <Form>

                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="9">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton id="PersonalInfoNextSocialButton"
                                            style={{ width: '100%' }} onClick={this.setTimelinePage}
                                            variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                    </Col>
                                </Form.Group>
                            }

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                <Col sm="1"></Col>
                                <Col sm="2">
                                    <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                        <FormattedMessage id="PersonalInfoSocial" defaultMessage="Social: " />
                                    </Form.Label>
                                </Col>
                                <Col sm="4">
                                    <Input style={{ width: '100%' }} value={this.state.social} onChange={(e) => this.setState({ social: e.target.value })} />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                <Col sm="1"></Col>
                                <Col sm="2">
                                    <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                        <FormattedMessage id="PersonalInfoHobbies" defaultMessage="Hobbies: " />
                                    </Form.Label>
                                </Col>
                                <Col sm="4">
                                    <Input style={{ width: '100%' }} value={this.state.hobbies} onChange={(e) => this.setState({ hobbies: e.target.value })} />
                                </Col>
                            </Form.Group>
                            <br />
                        </Form>
                    </div>}

                    {/*<div hidden={this.state.hiddenHealt} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 9) && <div hidden={this.state.timelinePagesHidden[9]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        <Form>
                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="9">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton id="PersonalInfoNextHealtButton"
                                            style={{ width: '100%' }} onClick={this.setTimelinePage}
                                            variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                    </Col>
                                </Form.Group>
                            }

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                <Col sm="4">
                                    <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                        <FormattedMessage id="PersonalInfoHealtMessage1" defaultMessage="Do you have any serious health problem ?" />
                                    </Form.Label>
                                </Col>
                                <Col sm="1">
                                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />} checked={this.state.healthDrm} defaultChecked
                                        onChange={(value) => {
                                            if (!value) {
                                                this.setState({ healthDescription: "" })
                                            }
                                            this.setState({ healthDrm: value })
                                        }} />
                                </Col>
                            </Form.Group>

                            <Form.Group hidden={!this.state.healthDrm} style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                <Col sm="4">
                                    <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                        <FormattedMessage id="PersonalInfoHealtMessage2" defaultMessage="Please write down your explanation:" />
                                    </Form.Label>
                                </Col>
                                <Col sm="6">
                                    <Form.Control as="textarea" rows="4"
                                        ref='myTextarea' value={this.state.healthDescription} onChange={(event) => {
                                            this.setState({
                                                healthDescription: event.target.value
                                            });
                                        }} />
                                </Col>
                            </Form.Group>
                            <br />
                        </Form>
                    </div>}

                    {/*<div hidden={this.state.hiddenReferences} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 10) && <div hidden={this.state.timelinePagesHidden[10]} style={{ marginTop: '3rem' }} className="content-section implementation">

                        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                            <FormAntd
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdateReferences == false ? this.updatePersonalInfoReferences : this.addPersonalInfoReferences}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRefReferences} >

                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="9">
                                        </Col>
                                        <Col sm="3">
                                            <ReactButton id="PersonalInfoNextReferencesButton"
                                                style={{ width: '100%' }} onClick={this.setTimelinePage}
                                                variant="success"><FormattedMessage id="GeneralButtonNext" defaultMessage="Next" /></ReactButton>
                                        </Col>
                                    </Form.Group>
                                }
                                <br />
                                <Row>
                                    <Col xs={8}></Col>
                                    <Col xs={3}>
                                        <ReactButton onClick={this.createNewClientButtonReferences} style={{ width: '100%' }}
                                            variant={this.state.createNewButtonReferencesVariant} >{this.state.createNewButtonReferences}
                                        </ReactButton>
                                    </Col>
                                    <Col xs={1}>
                                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                                    </Col>
                                </Row>

                                <div hidden={this.state.hiddenDataGirisReferences}>
                                    <br />

                                    {(itemRefNameAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefName" defaultMessage="Name" />}
                                            name="RefName"
                                            rules={[{ required: itemRefNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefNameRequired.translateRequired) == true ? itemRefNameRequired.translateRequired : -1} defaultMessage="Please input your Name!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.refName} onChange={(e) => this.setState({ refName: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemRefJobDescriptionAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefJobDescription" defaultMessage="Job Description" />}
                                            name="RefJobDescription"
                                            rules={[{ required: itemRefJobDescriptionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefJobDescriptionRequired.translateRequired) == true ? itemRefJobDescriptionRequired.translateRequired : -1} defaultMessage="Please input your Job Description!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.refjobDescription} onChange={(e) => this.setState({ refjobDescription: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemRefCompanyAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefCompany" defaultMessage="Company" />}
                                            name="RefCompany"
                                            rules={[{ required: itemRefCompanyRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefCompanyRequired.translateRequired) == true ? itemRefCompanyRequired.translateRequired : -1} defaultMessage="Please input your Company!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.refCompany} onChange={(e) => this.setState({ refCompany: e.target.value })} />
                                        </FormAntd.Item>}

                                    {(itemRefCountryAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefCountry" defaultMessage="Country" />}
                                            name="RefCountry"
                                            rules={[{ required: itemRefCountryRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefCountryRequired.translateRequired) == true ? itemRefCountryRequired.translateRequired : -1} defaultMessage="Please input your Country!" /> }]} >
                                            <CountryDropdown
                                                value={this.state.refCountry}
                                                onChange={(val) => this.selectRefCountry(val)} />
                                        </FormAntd.Item>}

                                    {(itemRefEmailAttributes.visible) &&
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="1"></Col>
                                            <Col sm="2">
                                                <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                                    <FormattedMessage id="PersonalInfoEmail" defaultMessage="Email" />
                                                </Form.Label>
                                            </Col>
                                            <Col sm="4">
                                                <Input style={{ width: '100%' }} onChange={this.handleChangeRefEmail}
                                                    value={this.state.refEmail} />
                                            </Col>
                                            <Col sm="5" hidden={this.state.hiddenRefEmail}>
                                                <Form.Label style={{ color: 'red' }} column sm="10">
                                                    Invalid Email
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>}

                                    {(itemRefPhoneAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefPhone" defaultMessage="Phone" />}
                                            name="RefPhone"
                                            rules={[{ required: itemRefPhoneRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefPhoneRequired.translateRequired) == true ? itemRefPhoneRequired.translateRequired : -1} defaultMessage="Please input your Country!" /> }]} >
                                            <PhoneInput
                                                country={"tr"}
                                                value={this.state.refPhone}
                                                onChange={refPhone => this.setState({ refPhone })}
                                                isValid={(inputNumber, country, countries) => {
                                                    var drm = false;
                                                    var obj = countries.find(p => p.countryCode == country.countryCode);
                                                    if (obj != null && obj != undefined) {
                                                        drm = startsWith(inputNumber, obj.dialCode);
                                                    }
                                                    if (drm) {
                                                        if (!this.state.refPhoneDrm) {
                                                            this.setState({
                                                                refPhoneDrm: drm,
                                                            });
                                                        }
                                                    } else {
                                                        if (this.state.refPhoneDrm) {
                                                            this.setState({
                                                                refPhoneDrm: drm,
                                                            });
                                                        }
                                                    }

                                                    return drm;
                                                }}
                                                defaultErrorMessage="Invalid Phone Number"
                                            />
                                        </FormAntd.Item>}

                                    {(itemRefRelationAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefRelation" defaultMessage="Relation" />}
                                            name="RefRelation"
                                            rules={[{ required: itemRefRelationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefRelationRequired.translateRequired) == true ? itemRefRelationRequired.translateRequired : -1} defaultMessage="Please input your Relation!" /> }]} >
                                            <Select
                                                name="refRelation"
                                                style={{ width: '100%' }}
                                                value={this.state.refRelation}
                                                onChange={this.handleChangePersonalInfoRefRelation}

                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Input style={{ flex: 'auto' }} value={this.state.itemPersonalInfoRefRelations}
                                                                onChange={(event) => { this.setState({ itemPersonalInfoRefRelations: event.target.value }) }} />
                                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                onClick={this.addPersonalInfoRefRelations} >
                                                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                                                        </div>
                                                    </div>
                                                )}>
                                                {this.state.PersonalInfoRefRelations.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </FormAntd.Item>}

                                    {(itemRefRemarksAttributes.visible) &&
                                        <FormAntd.Item
                                            label={<FormattedMessage id="PersonalInfoRefRemarks" defaultMessage="Remarks" />}
                                            name="RefRemarks"
                                            rules={[{ required: itemRefRemarksRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefRemarksRequired.translateRequired) == true ? itemRefRemarksRequired.translateRequired : -1} defaultMessage="Please input your Company!" /> }]} >
                                            <Input style={{ width: '100%' }} value={this.state.refRemarks} onChange={(e) => this.setState({ refRemarks: e.target.value })} />
                                        </FormAntd.Item>}

                                    <div hidden={this.state.hiddenSaveReferences}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    <ReactButton id="PersonalInfoAddReferencesButton"
                                                        style={{ width: '100%' }} type="submit"
                                                        variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div hidden={this.state.hiddenUpdateReferences}>
                                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3"></Col>
                                                <Col sm="2">
                                                    <ReactButton id="PersonalInfoCancelButton" onClick={this.changeButtonCancelReferences} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    <ReactButton id="PersonalInfoUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        }
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="datatable-doc-demo">
                                    <div className="content-section implementation">
                                        <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableColumnsReferences} dataSource={this.state.personalInfoReferencesTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />
                                    </div>
                                </div>

                            </FormAntd>
                        }
                    </div>}

                    {/*<div hidden={this.state.hiddenComments} style={{ marginTop: '5rem' }} className="content-section implementation"> */}
                    {(this.state.curIdx == 11) && <div hidden={this.state.timelinePagesHidden[11]} style={{ marginTop: '3rem' }} className="content-section implementation">
                        <Form>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                <Col sm="1"> </Col>
                                <Col sm="2">
                                    <Form.Label style={{ color: 'black', fontWeight: 'bold', float: 'right', fontSize: '1.25rem' }}>
                                        <FormattedMessage id="PersonalInfoComments" defaultMessage="Comments: " />
                                    </Form.Label>
                                </Col>
                                <Col sm="6">
                                    <Form.Control as="textarea" rows="4"
                                        ref='myTextarea' value={this.state.comments} onChange={(event) => {
                                            this.setState({
                                                comments: event.target.value
                                            });
                                        }} />
                                </Col>
                            </Form.Group>
                            <br />
                        </Form>
                    </div>}


                    {this.state.curIdx > 1 && <div hidden={this.state.hiddenSave} style={{ marginTop: '1rem' }}>
                        <Row>
                            <Col sm="3">
                            </Col>
                            <Col sm="6">
                                <PrimeButton style={{ width: '100%' }} id='save-personel' className="p-button-success" onClick={this.savePersonal} label="Save" />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Row>
                    </div>}

                    {this.state.curIdx > 1 && <div hidden={this.state.hiddenUpdate} style={{ marginTop: '1rem' }}>
                        <Row>
                            <Col sm="3">
                            </Col>
                            <Col sm="3">
                                <PrimeButton style={{ width: '100%' }} id='cancel-personel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                            </Col>
                            <Col sm="3">
                                <PrimeButton style={{ width: '100%' }} id='update-personel' onClick={this.updatePersonal} className="p-button-warning" label="Update" />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Row>
                    </div>}
                </Card>

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >

                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    {this.state.messageTip == "Success" &&
                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                    {this.state.messageTip == "Warning" &&
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                    {this.state.messageTip == "Error" &&
                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <Row >
                            <Col sm="12">
                                {this.state.message}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                        <ReactButton style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.imageUrl !== "" ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                            />
                        ) : (
                                <p>Photo has not been uploaded.</p>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="info" onClick={this.showOrHidePhotoModal}>
                            Close
                            </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                            </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalTraining}
                    onHide={this.showOrHideModalTrainingDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalTrainingDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffTrainingOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalContract}
                    onHide={this.showOrHideModalContractDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalContractDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffContractOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalExperiences}
                    onHide={this.showOrHideModalExperiencesDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalExperiencesDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffExperiencesOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalEducation}
                    onHide={this.showOrHideModalEducationDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalEducationDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffEducationOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalLanguageSkills}
                    onHide={this.showOrHideModalLanguageSkillsDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalLanguageSkillsDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffLanguageSkillsOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalBankAccounts}
                    onHide={this.showOrHideModalBankAccountsDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalBankAccountsDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffBankAccountsOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModalReferences}
                    onHide={this.showOrHideModalReferencesDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalReferencesDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteStaffReferencesOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id={this.state.tableTip} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

            </div>
        ));
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)