/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card"; 
import { Toast } from 'primereact/toast';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';

export class TableSettings extends Component {

    constructor() {
        super();
        this.state = {
            sortable: true,
            filterable: true,
            displayAction: null,
            globalFilter: null,
            showGlobalFilter: null,
            displayHeader: true,
            hidden: true,
            displayBasic: false,
            modalShow: false,
            position: 'center',
            roleNameText: "",
            selectedRole: null,
            buttonName: "Add",
            buttonClassName: "p-button-success",
            roleGroups: []
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Role Name can\'t be blank' });
    };

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true,
            selectedRole: rowData
        });

        console.log(rowData.roleName);
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteRoleGroup(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    };

    actionTemplate(rowData, column) {
        return <DropdownButton
                title=""
                size="sm"
                variant="success"
                id={`dropdown-button-drop`}
            >
                <Dropdown.Item eventKey="1">Update</Dropdown.Item>
                <Dropdown.Item eventKey="2">Delete</Dropdown.Item>
            </DropdownButton>;

        // <React.Fragment>
        //     <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>
        //     <Dialog header="Delete This Role Group Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
        //         <p>This discipline record will be deleted. Are you sure?</p>
        //     </Dialog>
        //     <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>
        // </React.Fragment>;
    };

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/RoleGroup/getAll");

        if (response.data.length === 0) {
            this.setState({
                roleGroups: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                roleGroups: response.data,
            });
        }
    };

    saveRoleGroup = async () => {
        if (this.state.buttonName === "Add" && this.state.roleNameText !== "") {

            const newRoleGroup = {
                roleName: this.state.roleNameText
            }

            await handleRequest("POST", "/RoleGroup/add", newRoleGroup);

            this.changeButtonSave();
            this.restartTable();
        }
        else if (this.state.buttonName === "Update" && this.state.roleNameText !== "") {

            const updatedRoleGroup = {
                roleName: this.state.roleNameText,
                id: this.state.selectedRole.id,
                active: this.state.selectedRole.active
            }

            await handleRequest("POST", "/RoleGroup/update", updatedRoleGroup);
            // await axios.post(`${API_BASE}/RoleGroup/update`, updatedRoleGroup);

            this.changeButtonSave();
            this.restartTable();
        }
        else {
            this.showError();
        }
    };

    restartTable = async () => {

        const response = await handleRequest("GET", "/RoleGroup/getAll");
        //const response = await axios.get(`${API_BASE}/RoleGroup/getAll`);

        if (response.data.length === 0) {
            this.setState({
                roleGroups: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                roleGroups: response.data,
            });
        }
    }

    deleteRoleGroup = async (name) => {

        const deletedRoleGroup = {
            id: this.state.selectedRole.id
        }

        await handleRequest("POST", "/RoleGroup/delete", deletedRoleGroup);
        // await axios.post(`${API_BASE}/RoleGroup/delete`, deletedRoleGroup);

        console.log(deletedRoleGroup);

        this.restartTable();
        this.showSuccess();
        this.onHideModal(name);
    };

    changeButtonUpdate = (rowData) => {
        this.setState({
            roleNameText: rowData.roleName,
            hidden: false,
            buttonName: "Update",
            buttonClassName: "p-button-warning"
        });
        console.log(rowData.roleName);
    };

    changeButtonSave = () => {
        this.setState({
            roleNameText: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
        this.showSuccess();
    };

    changeButtonCancel = () => {
        this.setState({
            roleNameText: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
        //this.showError();
    };

    changeSortable = () => {
        this.setState({
            sortable: !this.state.sortable
        });
    };

    changeFilterable = () => {
        this.setState({
            filterable: !this.state.filterable
        });
    };

    changeActionRow = () => {
        if (this.state.displayAction === null)
            this.setState({
                displayAction: 'none'
            });

        if (this.state.displayAction === 'none')
            this.setState({
                displayAction: null
            });
    };

    changeGlobalFilter = () => {
        if (this.state.showGlobalFilter === null)
            this.setState({
                showGlobalFilter: 'none'
            });

        if (this.state.showGlobalFilter === 'none')
            this.setState({
                showGlobalFilter: null
            });
    };

    changeTableHeader = () => {
        if (this.state.displayHeader === true) {
            document.getElementsByClassName('p-datatable-thead')[0].style.display = "none";
            this.setState({
                displayHeader: false
            });
        } else if (this.state.displayHeader === false) {
            document.getElementsByClassName('p-datatable-thead')[0].style.display = null;
            this.setState({
                displayHeader: true
            });
        }
    };

    renderHeader() {
        let displayGlobalFilter = this.state.showGlobalFilter;
        return (
            <div style={{ border: '0 none', padding: '12px', textAlign: 'left', fontSize: '20px' }}>
                List of Role Groups
                <div style={{ float: 'right', width: '200px', display: displayGlobalFilter }} className="p-datatable-globalfilter-container">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                </div>
            </div>
        );
    };


    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>
        let displayAction = this.state.displayAction;
        const header = this.renderHeader();
        return (
            <div>
                <Card style={{ width: '500px' }}>
                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable header={header} ref={(el) => this.dt = el} value={this.state.roleGroups} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-rolegroups"
                            selectionMode="single" globalFilter={this.state.globalFilter} responsive selection={this.state.selectedRole} onSelectionChange={e => this.setState({ selectedRole: e.value })}>
                            <Column field="index" style={{ textAlign: 'center' }} sortable={this.state.sortable} header="S/N" />
                            <Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable={this.state.sortable} header="S/N" />
                            <Column field="roleName" header="Role Group" filter={this.state.filterable} sortable={this.state.sortable} />
                            <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="Role Group Id" filter={this.state.filterable} sortable={this.state.sortable} />
                            <Column field="active" style={{ textAlign: 'center', display: 'none' }} header="Active" filter={this.state.filterable} sortable={this.state.sortable} />
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em', display: 'none' }} />
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em', display: displayAction }} />
                        </DataTable>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <InputText onChange={(e) => this.setState({ roleNameText: e.target.value })} value={this.state.roleNameText} placeholder="Role Group" />
                                <Button id='save-rolegroup' className={this.state.buttonClassName} onClick={this.saveRoleGroup} label={this.state.buttonName} />
                                <Button id='cancel-rolegroup' hidden={this.state.hidden} onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <Button id='test-sort' style={{ marginLeft: '10px' }} className="testSort" onClick={this.changeSortable} label="Test Sortable" />
                                <Button id='test-filter' style={{ marginLeft: '10px' }} className="testFilter" onClick={this.changeFilterable} label="Test Filter" />
                                <Button id='test-action' style={{ marginLeft: '10px' }} className="testAction" onClick={this.changeActionRow} label="Test Actions" />
                                <Button id='test-globalfilter' style={{ marginLeft: '10px' }} className="testGlobalFilter" onClick={this.changeGlobalFilter} label="Test Global Search" />
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <Button id='test-capture' style={{ marginLeft: '10px' }} className="testCapture" onClick={this.changeTableHeader} label="Test Capture" />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TableSettings)