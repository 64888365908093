import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
//import { getUserByToken } from "../../crud/auth.crud";

export const userTokn={
  user: undefined,
  authToken: undefined,
  config :null
}

export const actionList= [{id: 0, form: "", action: "", userId: 1, active: false,index:0}] ; 
  



export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        console.log("authDuck-Reducer içinde Login case", action.payload); 
        try {
          authToken.authList.forEach(element => {
            actionList.push(element); 
          }); 
        } catch (error) {
          
        }
        

        console.log("authDuck-Reducer içinde Login case 2", actionList);  
       // actionList = [...actionList,] ;    
        userTokn.authToken=authToken;
        userTokn.config = { headers: { 'accessToken':authToken.accessToken,'userId':authToken.id} };
        return { authToken, user: "" };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload; 

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: { 
      
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload; 

        return { ...state, user };
      }

      default:
       { //console.log("Reducer içinde default case");
        //console.log("authDuck-state", state);
        try {
          userTokn.authToken= state.authToken;   
          userTokn.config = { headers: { 'accessToken':state.authToken.accessToken,'userId':state.authToken.id} };
           console.log("sayfa yenilendi ama authtoken ok",state.authToken);
        } catch (error) { }
         
        return state;}
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  //console.log("authDuck-Sagadan 1");
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });
  //console.log("authDuck-Sagadan 2");

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });
  //console.log("authDuck-Sagadan 3");

  /*yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    console.log("authDuck-KOLO I AM HERE", user);
    yield put(actions.fulfillUser(user));
  });*/
}
