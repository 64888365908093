/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
//import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Row, Modal, Button as ReactButton } from 'react-bootstrap';
import { Form as FormAnt, Input, Checkbox } from 'antd';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';

export class Designation extends Component {

    constructor() {
        super();
        this.state = {
            modalShow: false,
            designationName: "",
            hiddenCreateNew: true,
            hiddenSave: false,
            hiddenUpdate: true,
            designationId: null,
            shortDesc: "",
            roleGroup: "Select Role Group",
            roleGroupId: null,
            selectedDesignation: null,
            designations: [],
            roleGroups: [],
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            modalMessage: false
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    formRef = React.createRef();

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage={msg} />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false,
            modalShow: false,
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteDesignation(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    actionTemplate2(rowData, column) {
        return <React.Fragment>
            <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>
            <Dialog header="Delete This Designation Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
                <p>This designation record will be deleted. Are you sure?</p>
            </Dialog>
            <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>
        </React.Fragment>;
    }

    actionTemplate(rowData, column) {
        // return <React.Fragment>
        //     <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
        // </React.Fragment>;

        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.deleteModal.bind(this)} />
            </React.Fragment>;
        }
    }

    deleteModal = (row) => {
        this.setState({
            modalShow: true,
            selectedDesignation: row
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    componentDidMount = async () => {

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Designation" });
        if (responseDP.data.length > 0) {
            console.log(responseDP.data);
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Designation",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);

        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Designation");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        const response = await handleRequest("GET", "/Designation/getallByCompany");

        if (response.data.length === 0) {
            this.setState({
                designations: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                designations: response.data
            });
        }

        const response2 = await handleRequest("GET", "/RoleGroup/getallByCompany");

        if (response2.data.length !== 0) {
            const columns2 = response2.data;
            const options2 = columns2.map(function (row) {
                return { name: row.roleName.toString(), id: row.id.toString() }
            })

            this.setState({
                roleGroups: options2,
            });
        }
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa tıklamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    saveDesignation = async () => {
        if (this.state.roleGroup !== "Select Role Group" && this.state.designationName !== "" && this.state.shortDesc !== "") {
            const newDesignation = {

                designationName: this.state.designationName,
                roleId: this.state.roleGroupId,
                shortDescription: this.state.shortDesc
            }
            await handleRequest("POST", "/Designation/add", newDesignation);

            this.changeButtonSave();
            this.restartTable();
        } else {
            this.showError();
        }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/Designation/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                designations: []
            });
        }

        if (response.data.length !== 0) {

            this.setState({
                designations: response.data,
            });
        }

        const response2 = await handleRequest("GET", "/RoleGroup/getallByCompany");

        if (response2.data.length !== 0) {
            const columns2 = response2.data;
            const options2 = columns2.map(function (row) {
                return { name: row.roleName.toString(), id: row.id.toString() }
            });

            this.setState({
                roleGroups: options2,
            });
        }
    }

    deleteDesignation = async (name) => {
        const deletedDesignation = {
            id: this.state.selectedDesignation.id
        }

        await handleRequest("POST", "/Designation/delete", deletedDesignation);
        this.restartTable();
        this.showSuccess("Data has been deleted successfully.");
        this.onHideModal(name);
    }

    changeButtonUpdate = (row) => {
        this.setState({
            hiddenUpdate: false,
            hiddenSave: true,
            hiddenCreateNew: false,
            designationId: row.id,
            designationName: row.designationName,
            roleGroup: row.roleGroup.roleName,
            roleGroupId: row.roleGroup.id,
            shortDesc: row.shortDescription
        });
    }

    createNew = () => {
        if (this.state.hiddenCreateNew) {
            this.setState({
                hiddenCreateNew: false
            });
        }
        else {
            this.setState({
                hiddenCreateNew: true,
                designationName: "",
                hiddenSave: false,
                hiddenUpdate: true,
                shortDesc: "",
                roleGroup: "Select Role Group",
                roleGroupId: null,
                selectedDesignation: null,
            });
        }
    }

    changeButtonSave = () => {
        this.setState({
            designationName: "",
            hiddenSave: false,
            hiddenUpdate: true,
            shortDesc: "",
            roleGroup: "Select Role Group",
            roleGroupId: null,
            selectedDesignation: null,
        });
        this.showSuccess("All data has been saved successfully.");
    }

    changeButtonCancel = () => {
        this.setState({
            designationName: "",
            hiddenSave: false,
            hiddenCreateNew: true,
            hiddenUpdate: true,
            shortDesc: "",
            roleGroup: "Select Role Group",
            roleGroupId: null,
            selectedDesignation: null,
        });
    }

    updateDone = async () => {
        if (this.state.roleGroup !== "Select Role Group" && this.state.designationName !== "" && this.state.shortDesc !== "") {
            const newDesignation = {
                id: this.state.designationId,
                designationName: this.state.designationName,
                roleId: this.state.roleGroupId,
                shortDescription: this.state.shortDesc
            }

            await handleRequest("POST", "/Designation/update", newDesignation);
            this.changeButtonSave();
            this.restartTable();
        }
        else {
            this.showError();
        }
    }

    handleChange2 = e => {
        e.preventDefault();
        var idValue2;

        for (var z = 0; z < this.state.roleGroups.length; z++) {
            if (this.state.roleGroups[z].name === e.target.value) {
                idValue2 = this.state.roleGroups[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            roleGroupId: idValue2
        });
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    render() {
        const { selectID } = this.state;

        var itemDesignationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DesignationDesignation");
        var itemShortDescriptionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DesignationShortDescription");
        var itemRoleGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DesignationRoleGroup");

        var itemDesignationRequired = this.state.dynamicInputs.find(p => p.inputKey === "DesignationDesignation");
        var itemNShortDescriptionRequired = this.state.dynamicInputs.find(p => p.inputKey === "DesignationShortDescription");
        var itemRoleGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "DesignationRoleGroup");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const howToUseThis = this.state.howToUseThis;

        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;

        let optionsRoleGroup = this.state.roleGroups.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        return <div>

                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShow}
                        onHide={this.showOrHideModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete  Designation ?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Selected designation will be deleted. Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteDesignation} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="9">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <ReactButton id="DesignationCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hiddenCreateNew} style={{ marginBottom: '1rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdate == false ? this.updateDone : this.saveDesignation}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {(itemDesignationAttributes.visible) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="DesignationDesignation" defaultMessage="Designation" />}
                                        name="DesignationDesignation"
                                        rules={[{ required: itemDesignationRequired.canBeRequired, message: <FormattedMessage id={itemDesignationRequired.translateRequired} defaultMessage="Please input the name of designation!" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="DesignationDesignation" value={this.state.designationName} onChange={(e) => this.setState({ designationName: e.target.value })} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {(itemShortDescriptionAttributes.visible) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="DesignationShortDescription" defaultMessage="Short Description" />}
                                        name="DesignationShortDescription"
                                        rules={[{ required: itemNShortDescriptionRequired.canBeRequired, message: <FormattedMessage id={itemNShortDescriptionRequired.translateRequired} defaultMessage="Please input short description of designation!" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="DesignationShortDescription" value={this.state.shortDesc} onChange={(e) => this.setState({ shortDesc: e.target.value })} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {(itemRoleGroupAttributes.visible) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="DesignationRoleGroup" defaultMessage="Role Group" />}
                                        name="DesignationRoleGroup"
                                        rules={[{ required: itemRoleGroupRequired.canBeRequired, message: <FormattedMessage id={itemRoleGroupRequired.translateRequired} defaultMessage="Please select a role group!" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Form.Control id="DesignationRoleGroup" name="roleGroup" value={this.state.roleGroup}
                                                    onChange={this.handleChange2} as="select">
                                                    <option value="">
                                                        Select Role Group
                                                    </option>
                                                    {optionsRoleGroup}
                                                </Form.Control>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-deparment' className="p-button-success" onClick={this.saveDepartment} label="Save" /> */}
                                                <ReactButton id="DesignationSaveButton" type="submit" style={{ width: '100%' }} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                <ReactButton id="DesignationCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.changeButtonCancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="DesignationUpdateButton" style={{ width: '100%' }} variant="warning" onClick={this.updateDone}>
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </FormAnt>

                            {/*<Form>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Designation:
                            </Form.Label>
                                    <Col sm="3">
                                        <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ designationName: e.target.value })} value={this.state.designationName} placeholder="Designation" />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Short Description:
                            </Form.Label>
                                    <Col sm="3">
                                        <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ shortDesc: e.target.value })} value={this.state.shortDesc} placeholder="Short Description" />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Role Group:
                            </Form.Label>
                                    <Col sm="3">
                                        <Form.Control name="roleGroup" value={this.state.roleGroup}
                                            onChange={this.handleChange2} as="select">
                                            <option value="">
                                                Select Role Group
                                    </option>
                                            {optionsRoleGroup}
                                        </Form.Control>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>
                            </Form>

                            <div hidden={this.state.hiddenSave}>
                                <Row>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="5">
                                        <Button style={{ width: '100%' }} id='save-rolegroup' className="p-button-success" onClick={this.saveDesignation} label="Save" />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Row>
                            </div>

                            <div hidden={this.state.hiddenUpdate}>
                                <Row>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="2">
                                        <Button style={{ width: '100%' }} id='cancel-rolegroup' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                                    </Col>
                                    <Col sm="3">
                                        <Button style={{ width: '100%' }} id='update-rolegroup' onClick={this.updateDone} className="p-button-warning" label="Update" />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Row>
                            </div>*/}
                        </div>

                        <div className="content-section implementation">
                            <Toast ref={(el) => this.toast = el} />
                            <DataTable ref={(el) => this.dt = el} value={this.state.designations} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-rolegroups"
                                selectionMode="single" selection={this.state.selectedDesignation} onSelectionChange={e => this.setState({ selectedDesignation: e.value })}>
                                <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                                {/*<Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable header="Id" />*/}
                                <Column field="designationName" header="Designation" filter sortable />
                                <Column field="shortDescription" header="Short Description" filter sortable />
                                <Column field="roleGroup.roleName" header="Role Group" filter sortable />
                                <Column field="roleGroup.roleGroupId" style={{ textAlign: 'center', display: 'none' }} header="Role Group Id" filter sortable />
                                {(this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 || this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) &&
                                    <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                }
                            </DataTable>
                        </div>
                    </Card>
                    }
               </MenuProvider>
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Designation)