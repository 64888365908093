import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Chart } from 'primereact/chart';
import { Card } from "primereact/card";
import { MultiSelect } from 'primereact/multiselect';
import { handleRequest } from '../../redux/config/env';
import { Table,  Row, Col } from 'antd';
import moment from 'moment'
import { DatePicker } from 'antd';
import { Select } from 'antd';

const { Option } = Select;
const { RangePicker } = DatePicker;

function getDayLabels(moments) {

    console.log(moments);
    const arr = moments;

    var first = arr[0].format();
    first = moment(first);;
    var result = [];
    while (first.isSameOrBefore(arr[1])) {
        var obj = {
            shipmentDay: first.format('DD/MM/YYYY'),
            year: first.get('year'),
            month: first.get('month') + 1,
            day: first.date()
        }
        result.push(obj);
        first = first.add(1, 'days')
        console.log(first);
    }
    console.log(result);
    return result;

}

export class ShipmentDriverChart extends Component {

    constructor() {
        super();
        this.state = {
            allShipments: [],
            shipmentLogs: [],
            duration: "day",
            duration2: "day",
            customers: [],
            customerOptions: [],
            selectedCustomers: [],
            projects: [],
            projectOptions: [],
            selectedProjects: [],
            products: [],
            selectedProducts: [],
            chartType1: "bar",
            chartType2: "bar",
            trucks: [],
            selectedTrucks: [],
            assignments: [],
            drivers: [],
            selectedDrivers: [],
            chartData1: {},
            chartData2: {},
            moments1: [],
            dateStrings1: [],
            moments2: [],
            dateStrings2: [],
            filled: true,
            filled1: true,
            urlBase64: null,
        };


    }

    componentDidMount = async () => {

        //const response = await handleRequest("GET", "/Shipment/getShipmentReport");

       // console.log(response.data);
        //this.drawChart();
        this.drawChart2();

        // if (response.data.length > 0) {
        //     var list = response.data;

        //     var index = 0;
        //     list.forEach(element => {
        //         element.key = index;
        //         index++;
        //     });

        //     this.setState({
        //         shipmentLogs: list
        //     });
        // }

        // const response2 = await handleRequest("GET", "/Shipment/getAll");




        // if (response2.data.length > 0) {
        //     var list2 = response2.data;

        //     var index2 = 0;

        //     list2.forEach(element => {
        //         element.key = index2;
        //         index2++;
        //     });

        //     this.setState({
        //         allShipments: list2
        //     });
        // }

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");


        if (responseCustomer.data.length > 0) {


            const columns2 = responseCustomer.data;

            console.log(columns2);

            const options2 = columns2.map(function (row) {
                return { label: row.fullName.toString(), value: row.id }
            })

            this.setState({
                customers: responseCustomer.data,
                customerOptions: options2
            });
        }

        const response0 = await handleRequest("GET", "/Assignment/getAll");

        console.log(response0);
        if (response0.data.length > 0) {

            this.setState({
                assignments: response0.data,
            });
        }

        const responseEquipment = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipment.data.length !== 0) {

            var list = responseEquipment.data;

            var _truckList = list.filter(p => p.equipmentType != null && p.equipmentType.equipmentTypeName === "Truck");

            const options = _truckList.map(function (row) {
                return { label: row.equipmentDescription.toString() + " - " + row.equipmentCode.toString(), value: row.id }
            })

            this.setState({

                trucks: options
            });
        }



    }


    getWeekLabels = (moments) => {

        var first = moments[0].format();
        first = moment(first);
        var result = [];

        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.year() + '/ W' + first.week(),
                year: first.get('year'),
                week: first.week()
            }
            result.push(obj);
            first = first.add(1, 'weeks')
            console.log(first);
        }

        return result;

    }

    getMonthLabels = (moments) => {

        var first = moments[0].format();
        first = moment(first);
        var result = [];
        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.format("YYYY-MM"),
                year: first.get('year'),
                month: first.month() + 1
            }
            result.push(obj);
            first = first.add(1, 'months')
            console.log(first);
        }

        return result;

    }

    getYearLabels = (moments) => {

        var first = moments[0].format();
        first = moment(first);
        var result = [];
        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.format("YYYY"),
                year: first.get('year'),

            }
            result.push(obj);
            first = first.add(1, 'years')
            console.log(first);
        }

        return result;

    }



    drawChart = async () => {

        const colorMap = [
            '#E3F4BF',
            '#BEF7C8',
            '#86E6C8',
            '#36CFC9',
            '#209BDD',
            '#1581E6',
            '#0860BF'
        ]

        const chart1Duration = this.state.duration;
        var _chartLabels = [];
        var _datasets = [];

        var _customers = [];
        var _projects = [];
        var _products = [];
        var _labels = [];
        var customData;

        switch (chart1Duration) {

            case 'day':
                const responseDay1 = await handleRequest("GET", "/Shipment/getShipmentDay");

                console.log(responseDay1);
                if (responseDay1 !== null && responseDay1.data !== null && responseDay1.data.customers !== undefined) {

                    var rData = responseDay1.data;


                    if (this.state.selectedCustomers.length > 0) {

                        console.log("girdi");
                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                        console.log(_customers);

                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {

                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));

                    }
                    else _projects = rData.projects;

                    if (this.state.selectedProducts.length > 0) {

                        _products = rData.products.filter(p => this.state.selectedProducts.includes(p.product));

                    }
                    else _products = rData.products;




                    const moments = this.state.moments1;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = getDayLabels(moments);
                    }
                    else
                        _labels = responseDay1.data.labels;



                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _products.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'product' && p.id === element.product);
                        if (index === -1) {
                            var obj = {
                                customType: 'product',
                                id: element.product,
                                label: element.productName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 2'+element.product, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });


                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.shipmentDay);

                            _datasets.forEach(e => {

                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (i >= 0)
                                        e.data.push(_customers[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(_projects[j].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'product') {
                                    var k = _products.findIndex(p => p.product === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (k >= 0)
                                        e.data.push(_products[k].volume);
                                    else e.data.push(0);

                                }

                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData1: customData

                    })

                }



                break;

            case 'week':

                const responseWeek = await handleRequest("GET", "/Shipment/getShipmentWeek");

                console.log(responseWeek);
                if (responseWeek.data !== null) {

                    rData = responseWeek.data;


                    if (this.state.selectedCustomers.length > 0) {


                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                        console.log(_customers);

                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {

                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));

                    }
                    else _projects = rData.projects;

                    if (this.state.selectedProducts.length > 0) {

                        _products = rData.products.filter(p => this.state.selectedProducts.includes(p.product));

                    }
                    else _products = rData.products;


                    const moments = this.state.moments1;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = this.getWeekLabels(moments);
                    }




                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _products.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'product' && p.id === element.product);
                        if (index === -1) {
                            var obj = {
                                customType: 'product',
                                id: element.product,
                                label: element.productName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 2'+element.product, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });


                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.period);

                            _datasets.forEach(e => {

                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year && p.week === element.week);
                                    if (i >= 0)
                                        e.data.push(_customers[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year && p.week === element.week);
                                    if (j >= 0)
                                        e.data.push(_projects[j].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'product') {
                                    var k = _products.findIndex(p => p.product === e.id && p.year === element.year && p.week === element.week);
                                    if (k >= 0)
                                        e.data.push(_products[k].volume);
                                    else e.data.push(0);

                                }

                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData1: customData

                    })

                }
                break;

            case 'month':
                const responseMonth = await handleRequest("GET", "/Shipment/getShipmentMonth");

                console.log(responseMonth);
                if (responseMonth.data !== null) {

                    rData = responseMonth.data;



                    if (this.state.selectedCustomers.length > 0) {


                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                        console.log(_customers);

                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {

                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));

                    }
                    else _projects = rData.projects;

                    if (this.state.selectedProducts.length > 0) {

                        _products = rData.products.filter(p => this.state.selectedProducts.includes(p.product));

                    }
                    else _products = rData.products;




                    const moments = this.state.moments1;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = this.getMonthLabels(moments);
                    }




                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _products.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'product' && p.id === element.product);
                        if (index === -1) {
                            var obj = {
                                customType: 'product',
                                id: element.product,
                                label: element.productName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 2'+element.product, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });



                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.period);

                            _datasets.forEach(e => {

                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year && p.month === element.month);
                                    if (i >= 0)
                                        e.data.push(_customers[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year && p.month === element.month);
                                    if (j >= 0)
                                        e.data.push(_projects[j].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'product') {
                                    var k = _products.findIndex(p => p.product === e.id && p.year === element.year && p.month === element.month);
                                    if (k >= 0)
                                        e.data.push(_products[k].volume);
                                    else e.data.push(0);

                                }

                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData1: customData

                    })

                }



                break;

            case 'year':
                const responseYear = await handleRequest("GET", "/Shipment/getShipmentYear");

                console.log(responseYear);
                if (responseYear.data !== null) {

                    rData = responseYear.data;



                    if (this.state.selectedCustomers.length > 0) {


                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                        console.log(_customers);

                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {

                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));

                    }
                    else _projects = rData.projects;

                    if (this.state.selectedProducts.length > 0) {

                        _products = rData.products.filter(p => this.state.selectedProducts.includes(p.product));

                    }
                    else _products = rData.products;




                    const moments = this.state.moments1;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = this.getYearLabels(moments);
                    }




                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _products.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'product' && p.id === element.product);
                        if (index === -1) {
                            var obj = {
                                customType: 'product',
                                id: element.product,
                                label: element.productName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 2'+element.product, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });



                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.period);

                            _datasets.forEach(e => {

                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year);
                                    if (i >= 0)
                                        e.data.push(_customers[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year);
                                    if (j >= 0)
                                        e.data.push(_projects[j].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'product') {
                                    var k = _products.findIndex(p => p.product === e.id && p.year === element.year);
                                    if (k >= 0)
                                        e.data.push(_products[k].volume);
                                    else e.data.push(0);

                                }

                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData1: customData

                    })

                }



                break;

            default:
                break;
        }

        this.setState({

            chartData1: customData

        })



    }

    createChartImage = () => {


        // var ctx = can.getContext('2d');
        // ctx.fillRect(50,50,50,50);

        if (Boolean(document.getElementById('chart1'))) {
            var can = document.getElementById('chart1').childNodes[1];
            console.log(can); 
            var url_base64 = can.toDataURL('image/png');
            var a = window.document.createElement("a");
            a.href =url_base64;
            a.download = "exportData" + new Date().toDateString() + ".png";
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
        }



    }

    drawChart2 = async () => {

        const colorMap = [
            '#E3F4BF',
            '#BEF7C8',
            '#86E6C8',
            '#36CFC9',
            '#209BDD',
            '#1581E6',
            '#0860BF'
        ]

        var _trucks = [];
        var _drivers = [];
        var _chartLabels = [];
        var _datasets = [];
        var _labels = [];
        var rData = [];
        var customData;
        const chart2Duration = this.state.duration2;
        switch (chart2Duration) {
            case 'day':
                const responseDay1 = await handleRequest("GET", "/Shipment/getShipmentDay");

                console.log(responseDay1);
                if (responseDay1 !== null && responseDay1.data !== null && responseDay1.data.trucks !== undefined && responseDay1.data.trucks !== null) {

                    rData = responseDay1.data;


                    if (this.state.selectedTrucks.length > 0) {


                        _trucks = rData.trucks.filter(p => this.state.selectedTrucks.includes(p.equipmentLogId));


                    }
                    else _trucks = rData.trucks;

                    if (this.state.selectedDrivers.length > 0) {

                        _drivers = rData.drivers.filter(p => this.state.selectedDrivers.includes(p.staffId));

                    }
                    else _drivers = rData.drivers;


                    const moments = this.state.moments2;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = getDayLabels(moments);
                    }
                    else
                        _labels = responseDay1.data.labels;

                    _trucks.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'truck' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'truck',
                                id: element.equipmentLogId,
                                label: element.truckName,
                                fill: this.state.filled1,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _drivers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'driver' && p.id === element.staffId);
                        if (index === -1) {
                            var obj = {
                                customType: 'driver',
                                id: element.staffId,
                                label: element.driverName,
                                fill: this.state.filled1,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });




                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.shipmentDay);

                            _datasets.forEach(e => {

                                if (e.customType === 'truck') {
                                    var i = _trucks.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (i >= 0)
                                        e.data.push(_trucks[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'driver') {
                                    var j = _drivers.findIndex(p => p.staffId === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(_drivers[j].volume);
                                    else e.data.push(0);

                                }



                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData2: customData

                    })

                }



                break;

            case 'week':

                const responseWeek = await handleRequest("GET", "/Shipment/getShipmentWeek");

                console.log(responseWeek);
                if (responseWeek.data !== null) {

                    rData = responseWeek.data;


                    if (this.state.selectedTrucks.length > 0) {


                        _trucks = rData.trucks.filter(p => this.state.selectedTrucks.includes(p.equipmentLogId));


                    }
                    else _trucks = rData.trucks;

                    if (this.state.selectedDrivers.length > 0) {

                        _drivers = rData.drivers.filter(p => this.state.selectedDrivers.includes(p.staffId));

                    }
                    else _drivers = rData.drivers;





                    const moments = this.state.moments2;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = this.getWeekLabels(moments);
                    }


                    _trucks.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'truck' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'truck',
                                id: element.equipmentLogId,
                                label: element.truckName,
                                fill: this.state.filled1,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _drivers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'driver' && p.id === element.staffId);
                        if (index === -1) {
                            var obj = {
                                customType: 'driver',
                                id: element.staffId,
                                label: element.driverName,
                                fill: this.state.filled1,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });





                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.period);

                            _datasets.forEach(e => {

                                if (e.customType === 'truck') {
                                    var i = _trucks.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.week === element.week);
                                    if (i >= 0)
                                        e.data.push(_trucks[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'driver') {
                                    var j = _drivers.findIndex(p => p.staffId === e.id && p.year === element.year && p.week === element.week);
                                    if (j >= 0)
                                        e.data.push(_drivers[j].volume);
                                    else e.data.push(0);

                                }



                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData2: customData

                    })

                }

                break;
            case 'month':

                const responseMonth = await handleRequest("GET", "/Shipment/getShipmentMonth");

                console.log(responseMonth);
                if (responseMonth.data !== null) {

                    rData = responseMonth.data;


                    if (this.state.selectedTrucks.length > 0) {


                        _trucks = rData.trucks.filter(p => this.state.selectedTrucks.includes(p.equipmentLogId));


                    }
                    else _trucks = rData.trucks;

                    if (this.state.selectedDrivers.length > 0) {

                        _drivers = rData.drivers.filter(p => this.state.selectedDrivers.includes(p.staffId));

                    }
                    else _drivers = rData.drivers;





                    const moments = this.state.moments2;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = this.getMonthLabels(moments);
                    }


                    _trucks.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'truck' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'truck',
                                id: element.equipmentLogId,
                                label: element.truckName,
                                fill: this.state.filled1,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _drivers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'driver' && p.id === element.staffId);
                        if (index === -1) {
                            var obj = {
                                customType: 'driver',
                                id: element.staffId,
                                label: element.driverName,
                                fill: this.state.filled1,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });





                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.period);

                            _datasets.forEach(e => {

                                if (e.customType === 'truck') {
                                    var i = _trucks.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.month === element.month);
                                    if (i >= 0)
                                        e.data.push(_trucks[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'driver') {
                                    var j = _drivers.findIndex(p => p.staffId === e.id && p.year === element.year && p.month === element.month);
                                    if (j >= 0)
                                        e.data.push(_drivers[j].volume);
                                    else e.data.push(0);

                                }



                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData2: customData

                    })

                }

                break;
            case 'year':

                const responseYear = await handleRequest("GET", "/Shipment/getShipmentYear");

                console.log(responseYear);
                if (responseYear.data !== null) {

                    rData = responseYear.data;


                    if (this.state.selectedTrucks.length > 0) {


                        _trucks = rData.trucks.filter(p => this.state.selectedTrucks.includes(p.equipmentLogId));


                    }
                    else _trucks = rData.trucks;

                    if (this.state.selectedDrivers.length > 0) {

                        _drivers = rData.drivers.filter(p => this.state.selectedDrivers.includes(p.staffId));

                    }
                    else _drivers = rData.drivers;





                    const moments = this.state.moments2;
                    if (moments !== null && moments !== undefined && moments.length > 0) {

                        _labels = this.getYearLabels(moments);
                    }


                    _trucks.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'truck' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'truck',
                                id: element.equipmentLogId,
                                label: element.truckName,
                                fill: this.state.filled1,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                // backgroundColor: colorMap[randomInt(0, 6)],
                                //   stack:'Stack 0'+element.customer,
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });

                    _drivers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'driver' && p.id === element.staffId);
                        if (index === -1) {
                            var obj = {
                                customType: 'driver',
                                id: element.staffId,
                                label: element.driverName,
                                fill: this.state.filled1,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                // stack:'Stack 1'+element.project, 
                                data: []
                            }
                            _datasets.push(obj);
                        }


                    });





                    if (_labels.length > 0) {

                        _labels.forEach(element => {

                            _chartLabels.push(element.period);

                            _datasets.forEach(e => {

                                if (e.customType === 'truck') {
                                    var i = _trucks.findIndex(p => p.equipmentLogId === e.id && p.year === element.year);
                                    if (i >= 0)
                                        e.data.push(_trucks[i].volume);
                                    else e.data.push(0);

                                }

                                if (e.customType === 'driver') {
                                    var j = _drivers.findIndex(p => p.staffId === e.id && p.year === element.year);
                                    if (j >= 0)
                                        e.data.push(_drivers[j].volume);
                                    else e.data.push(0);

                                }



                            });



                        });


                    }

                    console.log(_chartLabels);
                    console.log(_datasets);

                    customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({

                        chartData2: customData

                    })

                }

                break;

            default:
                break;
        }


    }



  

    onCustomerChange = (e) => {

        const _selectedCustomers = e.value;
        var _projectList = [];
        var customerList = this.state.customers;

        console.log(e.value);

        _selectedCustomers.forEach(element => {

            var index = customerList.findIndex(p => p.id == element)


            if (index >= 0 && customerList[index].projects !== null && customerList[index].projects !== undefined && customerList[index].projects.length > 0) {

                customerList[index].projects.forEach(element => {
                    _projectList.push(element);
                });

            }

            console.log(_projectList);

        });

        console.log(_projectList);

        var options = _projectList.map(function (row, index) {

            return { label: row.projectName.toString(), value: row.id }
        })



        this.setState({
            selectedCustomers: _selectedCustomers,
            projects: _projectList === null ? [] : _projectList,
            projectOptions: options === null ? [] : options
        })

        if (_selectedCustomers == null || _selectedCustomers.length === 0) {
            this.setState({
                selectedProjects: [],
                selectedProducts: []
            })
        }

        setTimeout(() => {

           // this.drawChart();

        }, 500);

    }

    onProjectChange = (e) => {

        const _selectesProjects = e.value;
        var projectList = this.state.projects;
        var _productList = [];

        _selectesProjects.forEach(element => {

            var index = projectList.findIndex(p => p.id == element)


            if (index >= 0 && projectList[index].services !== null && projectList[index].services !== undefined && projectList[index].services.length > 0) {

                projectList[index].services.forEach(element => {
                    _productList.push(element);
                });


            }

        });

        var options = _productList.map(function (row) {

            return { label: row.companyService.description.toString(), value: row.companyService.id }
        })



        this.setState({
            selectedProjects: _selectesProjects,
            products: options == null ? [] : options,

        })

        if (_selectesProjects == null || _selectesProjects.length === 0) {
            this.setState({
                selectedProducts: []
            })
        }

        setTimeout(() => {

            //this.drawChart();

        }, 500);

    }

    onProductChange = (e) => {

        this.setState({
            selectedProducts: e.value,

        })

        setTimeout(() => {

           // this.drawChart();

        }, 500);

    }

    onTruckChange = (e) => {

        var list = this.state.assignments;
        var _selectedTrucks = e.value;
        var _driverList = [];


        _selectedTrucks.forEach(element => {

            var filteredList = list.filter(p => p.equipmentLogId == element)


            if (filteredList.length > 0) {

                filteredList.forEach(element => {
                    _driverList.push(element);
                });

            }


        });

        var options = _driverList.map(function (row) {

            return { label: row.staff.name.toString() + ' ' + row.staff.midName.toString() + ' ' + row.staff.lastName.toString(), value: row.staff.id }

        })

        this.setState({
            selectedTrucks: e.value,
            drivers: options
        })

        if (_selectedTrucks == null || _selectedTrucks.length === 0) {
            this.setState({
                selectedDrivers: []
            })
        }

        setTimeout(() => {

            this.drawChart2();

        }, 500);

    }

    onDriverChange = (e) => {

        this.setState({
            selectedDrivers: e.value

        })

        setTimeout(() => {

            this.drawChart2();

        }, 500);

    }




    render() {

        const customData = {
            labels: ['1.Nis.20', '2.Nis.20', '3.Nis.20', '4.Nis.20', '5.Nis.20', '6.Nis.20', '7.Nis.20'],
            datasets: [{
                label: 'Project 1',
                backgroundColor: '#42A5F5',
                stack: 'Stack 0',
                data: [317, 250, 0, 0, 0, 0, 0]
            }, {
                label: 'Truck 1',
                backgroundColor: '#FC4E03',
                stack: 'Stack 1',
                data: [217, 190, 0, 0, 0, 0, 0]
            }, {
                label: 'Truck 2',
                backgroundColor: '#4CAF50',
                stack: 'Stack 2',
                data: [100, 60, 0, 0, 0, 0, 0]
            }, {
                label: 'Driver 1',
                backgroundColor: '#FF6384',
                stack: 'Stack 3',
                data: [97, 100, 0, 0, 0, 0, 0]
            }
            ]
        }

        const customOptions = {
            title: {
                display: true,
                text: 'You are looking at the Shipment Logs',
                fontSize: 18,
                fontColor: '#333333'
            },
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                x: {
                    stacked: false,
                },
                y: {
                    stacked: false
                }
            }
        }

        const columns = [
            { title: 'Date', dataIndex: 'shipmentDay', key: 'shipmentDay' },
            { title: 'Customer', dataIndex: 'customerName', key: 'versicustomerNameon' },
            { title: 'Project', dataIndex: 'projectName', key: 'projectName' },
            { title: 'Product', dataIndex: 'productName', key: 'productName' },
            { title: 'Quantity', dataIndex: 'volume', key: 'volume' },
            { title: 'Unit', dataIndex: 'unit', key: 'unit' },
            { title: 'Price', dataIndex: 'price', key: 'price' }
        ];

        return (
            <div>
                
                {/* <Card className="text-center" style={{ width: '700px', height: '450px' }} > */}
                 <Card>
                    <Row>
                        <Col span={8}><div style={{ color: 'black', font: 'bold' }}> Period Selection </div></Col>
                        <Col span={4}><div style={{ color: 'black', font: 'bold' }}> Truck</div></Col>
                        <Col span={4}><div style={{ color: 'black', font: 'bold' }}> Driver</div></Col>

                        <Col span={4}><div style={{ color: 'black', font: 'bold' }}> Chart Selection</div></Col>

                    </Row>
                    <Row style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                        <Col span={2}>
                            <Select defaultValue="day" style={{ width: '100%' }}
                                onChange={(value) => {

                                    this.setState({ duration2: value });


                                }}>
                                <Option value="day">Day</Option>
                                <Option value="week">Week</Option>
                                <Option value="month">Month</Option>
                                <Option value="year">Year</Option>
                            </Select>

                        </Col>
                        <Col span={6}>
                            <RangePicker picker={this.state.duration2} onChange={(value, dateString) => {
                                console.log(value);
                                console.log(dateString);
                                this.setState({
                                    moments2: value,
                                    dateStrings2: dateString
                                })

                                setTimeout(() => {

                                    this.drawChart2();

                                }, 500);
                            }} />
                        </Col>
                        <Col span={4}>  <MultiSelect value={this.state.selectedTrucks} options={this.state.trucks} onChange={this.onTruckChange}
                            style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />

                        </Col>
                        <Col span={4}>
                            <MultiSelect value={this.state.selectedDrivers} options={this.state.drivers} onChange={this.onDriverChange}
                                style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />
                        </Col>

                        <Col span={4}> <Select defaultValue="bar"
                            onChange={(value) => {
                                if (value === "line") {
                                    this.setState({ filled1: false, chartType2: "line" });
                                } else if (value === "filled line") {
                                    this.setState({ filled1: true, chartType2: "line" });
                                } else if (value === "bar") {
                                    this.setState({ filled1: false, chartType2: "bar" });
                                }
                                // else if (value === "pie") {
                                //     this.setState({ filled1: false, chartType2: "pie" });
                                // } else if (value === "radar") {
                                //     this.setState({ filled1: false, chartType2: "radar" });
                                // }

                                this.drawChart2();
                            }}>
                            <Option value="bar">Bar</Option>
                            <Option value="line">Line</Option>
                            <Option value="filled line">Filled Line</Option>
                            {/*<Option value="pie">Pie</Option>
                            <Option value="radar">Radar</Option>*/}
                        </Select></Col>
                    </Row>
                    <div className="content-section implementation">
                        <Chart id={"Chart"+this.props.id}  type={this.state.chartType2} data={this.state.chartData2} />
                    </div>
                </Card>
                {/* </Card> */}
                
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDriverChart) 