/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chips } from 'primereact/chips';
import { Card, Button, Container, Col, Form, Row, Modal } from 'react-bootstrap';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { InputNumber, Upload, message, Table, Select, Avatar, Space, Row as RowAnt, Collapse } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { COUNTRIES } from '../settings/JsonDatas/Datas';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";

const { Option } = Select;
const { Column, ColumnGroup } = Table;
const { Panel } = Collapse;

export class VendorLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      modalShowProject: false,
      companyId: 0,
      selectedRow: {},
      customerList: [],
      hidden: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
      lastButton: "Save",
      lastButtonVariant: "success",
      companyFullName: "",
      companyShortName: "",
      companyLogoName: "",
      aboutCompanyName: "",
      aboutCompanyText: "",
      missionName: "",
      missionText: "",
      visionName: "",
      visionText: "",
      itemsContacts: [],
      location: "Select",
      address: "",
      phone: [],
      fax: [],
      web: "",
      email: [],
      social: [],
      itemsCertificates: [],
      nameOfCertificate: "Select",
      validity: "",
      respDepartment: "",
      certificate: "",
      loading: false,
      imageUrl: null,
      logoPath: "",

      products: [],
      projectProducts: [],

      projectKey: 0,
      projectList: [],
      projectAddress: "",
      distance: "",
      projectName: "",

      contactId: 0,
      representativeId: 0,

      name: "",
      midname: "",
      surname: "",
      position: "",
      rPhone: "",
      rEmail: "",
      country: "",
      projectNames: [],

      oldCompany: {},

      selectOption: '',

      pRepName: "",
      pRepMidName: "",
      pRepSurname: "",
      pRepPosition: "",
      pRepPhone: "",
      pRepEmail: "",
      projectId: "",
      oldProjectList: [],
      projectUpdateButtonHidden: "none",
      projectAddButtonHidden: "block",

      pRepList: [],
      pRepId: 0,
      modalDeletePRep: false,
      projectRepUpdateButtonHidden: "none",
      projectRepAddButtonHidden: "block",

      modalError: false,

      locationId: 0,
      locationName: "",
      locationDistance: "",
      locationAddress: "",
      lRepName: "",
      lRepMidName: "",
      lRepSurname: "",
      lRepPosition: "",
      lRepPhone: "",
      lRepEmail: "",
      lRepList: [],
      lRepId: 0,
      modalDeleteLRep: false,
      locationRepUpdateButtonHidden: "none",
      locationRepAddButtonHidden: "block",
      locationUpdateButtonHidden: "none",
      locationAddButtonHidden: "block",

      locationKey: 0,
      locationList: [],
      modalLocationRep: false,
      modalShowLocation: false,

      pagination: {
        current: 1,
        pageSize: 5,
      },

    };

    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.location.pathname.split('/')[2] != null) {
      this.setState({
        hidden: false
      });
    }
    await this.restartTable();
    await this.getProducts();
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters, });
  };

  fetch = async (params = {}) => {
    this.setState({
      loading: true
    });
    const newObj = {
      id: 0,
      firstResult: params.pagination.current,
      maxResults: params.pagination.pageSize,
    }

    const response = await handleRequest("POST", "/VendorLog/getVendorLogPaging", newObj);

    if (response.data.length !== 0) {
      this.setState({
        loading: false,
        customerList: response.data.data,
        pagination: {
          ...params.pagination,
          total: response.data.totalRecords,
        },
      });
    } else {
      this.setState({
        loading: false,
        customerList: [],
        pagination: {
          ...params.pagination,
          total: 0,
        },
      });
    }
  };

  getProducts = async () => {
    const response = await handleRequest("GET", "/CompanyService/getAllByCompany");

    if (response.data.length > 0) {
      this.setState({
        products: response.data,
      });
    }
  }

  restartTable = async () => {
    const { pagination } = this.state;
    await this.fetch({ pagination });

    if (this.state.customerList.length !== 0) {
      const _contactDefault = [{ id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] }];
      const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };

      var rData = this.state.customerList;
      console.log("RRR", rData);

      if (!rData.contact)
        rData.contacts = _contactDefault;
      if (!rData.representative)
        rData.representative = _representativeDefault;

      if (!rData.projects)
        rData.projects = this.state.projectList;

      rData.forEach(element => {
        element.key = element.id;
      });
    }
  }

  createNew = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Adding New",
        lastButton: "Save",
        lastButtonVariant: "success",
        createNewButtonVariant: "danger",
        companyId: 0,
        companyFullName: "",
        companyShortName: "",
        aboutCompanyText: "",
        logoPath: "",
        projectProducts: [],
        projectKey: 0,
        projectList: [],
        projectAddress: "",
        distance: "",
        projectName: "",
        pRepName: "",
        pRepMidName: "",
        pRepSurname: "",
        pRepPosition: "",
        pRepPhone: "",
        projectUpdateButtonHidden: "none",
        projectAddButtonHidden: "block",
        projectId: ""
      });
      //this.refreshAccordions();
    } else {
      this.getProducts();
      this.setState({
        hidden: true,
        createNewButton: "Create New",
        lastButton: "Save",
        lastButtonVariant: "success",
        createNewButtonVariant: "info",
        companyId: 0,
        companyFullName: "",
        companyShortName: "",
        aboutCompanyText: "",
        visionText: "",
        missionText: "",
        address: "",
        country: "Select Country ",
        phone: [],
        fax: [],
        email: [],
        web: "",
        name: "",
        midname: "",
        surname: "",
        position: "",
        rPhone: "",
        rEmail: "",
        logoPath: "",
        oldCompany: {},
        contactId: 0,
        representativeId: 0,
        projectProducts: [],
        projectKey: 0,
        projectList: [],
        projectAddress: "",
        distance: "",
        projectName: "",
        pRepName: "",
        pRepMidName: "",
        pRepSurname: "",
        pRepPosition: "",
        pRepPhone: "",
        projectUpdateButtonHidden: "none",
        projectAddButtonHidden: "block",
        projectId: ""
      });
      //this.refreshAccordions();
    }

  };

  saveCustomer = async () => {
    if (this.state.companyFullName.trim() !== "") {

      var _phoneList = [];
      this.state.phone.forEach(element => {
        _phoneList.push(element);
      });

      var _faxList = [];
      this.state.fax.forEach(element => {
        _faxList.push(element);
      });

      var _mailList = [];
      this.state.email.forEach(element => {
        _mailList.push(element);
      });

      const _contact = { id: this.state.contactId, location: "Head Office", country: this.state.country, address: this.state.address, web: this.state.web, phones: _phoneList.toString(), faxes: _faxList.toString(), mails: _mailList.toString() };
      const _representative = { id: this.state.representativeId, name: this.state.name, midname: this.state.midname, lastname: this.state.surname, position: this.state.position, phone: this.state.rPhone, email: this.state.rEmail };

      let projectListDB = this.state.projectList;

      const newItem = {
        id: this.state.companyId,
        fullName: this.state.companyFullName,
        shortName: this.state.companyShortName,
        contact: _contact,
        representative: _representative,
        projects: projectListDB,
        logoPath: this.state.logoPath
      }

      if (newItem.id === 0) {
        console.log("ADD", newItem);
        var response = await handleRequest("POST", "/VendorLog/add", newItem);
        if (response.data !== null && response.data !== undefined) {
          if (response.data == "SUCCESS") {
            this.resetInputs();
            this.restartTable();
            this.showSuccess();
          }
          else {
            this.showError();
          }
        }
        else {
          this.showWarning();
        }
      }
      else {
        let projectListDB2 = [];

        for (let index = 0; index < this.state.projectList.length; index++) {

          let projectRepresentativeList = [];
          let projectLocationList = [];

          for (let index1 = 0; index1 < this.state.projectList[index].representatives.length; index1++) {
            const newRepres = {
              id: this.state.projectList[index].representatives[index1].id,
              pRepName: this.state.projectList[index].representatives[index1].pRepName,
              pRepMidName: this.state.projectList[index].representatives[index1].pRepMidName,
              pRepSurname: this.state.projectList[index].representatives[index1].pRepSurname,
              pRepPosition: this.state.projectList[index].representatives[index1].pRepPosition,
              pRepPhone: this.state.projectList[index].representatives[index1].pRepPhone,
              pRepEmail: this.state.projectList[index].representatives[index1].pRepEmail
            }
            projectRepresentativeList.push(newRepres);
          }

          if (this.state.oldProjectList.length > 0) {
            for (let index1 = 0; index1 < this.state.oldProjectList[index].representatives.length; index1++) {

              let whichIndexProjectRepresentative = this.state.projectList[index].representatives.findIndex(i => i.id === this.state.oldProjectList[index].representatives[index1].id);

              if (whichIndexProjectRepresentative === -1) {
                const newRepres = {
                  id: this.state.oldProjectList[index].representatives[index1].id,
                  active: false,
                  pRepName: this.state.oldProjectList[index].representatives[index1].pRepName,
                  pRepMidName: this.state.oldProjectList[index].representatives[index1].pRepMidName,
                  pRepSurname: this.state.oldProjectList[index].representatives[index1].pRepSurname,
                  pRepPosition: this.state.oldProjectList[index].representatives[index1].pRepPosition,
                  pRepPhone: this.state.oldProjectList[index].representatives[index1].pRepPhone,
                  pRepEmail: this.state.oldProjectList[index].representatives[index1].pRepEmail
                }
                projectRepresentativeList.push(newRepres);
              }
            }
          }


          for (let index1 = 0; index1 < this.state.projectList[index].locations.length; index1++) {

            let projectLocationRepresentativeList = [];

            for (let index2 = 0; index2 < this.state.projectList[index].locations[index1].locationRepresentatives.length; index2++) {
              const newRepres = {
                id: this.state.projectList[index].locations[index1].locationRepresentatives[index2].id,
                lRepName: this.state.projectList[index].locations[index1].locationRepresentatives[index2].lRepName,
                lRepMidName: this.state.projectList[index].locations[index1].locationRepresentatives[index2].lRepMidName,
                lRepSurname: this.state.projectList[index].locations[index1].locationRepresentatives[index2].lRepSurname,
                lRepPosition: this.state.projectList[index].locations[index1].locationRepresentatives[index2].lRepPosition,
                lRepPhone: this.state.projectList[index].locations[index1].locationRepresentatives[index2].lRepPhone,
                lRepEmail: this.state.projectList[index].locations[index1].locationRepresentatives[index2].lRepEmail
              }
              projectLocationRepresentativeList.push(newRepres);
            }

            if (this.state.oldProjectList.length > 0) {
              for (let index2 = 0; index2 < this.state.oldProjectList[index].locations[index1].locationRepresentatives.length; index2++) {

                let whichIndexProjectLocationRepresentative = this.state.projectList[index].locations[index1].locationRepresentatives.findIndex(i => i.id === this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].id);

                if (whichIndexProjectLocationRepresentative === -1) {
                  const newRepres = {
                    id: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].id,
                    active: false,
                    lRepName: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].lRepName,
                    lRepMidName: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].lRepMidName,
                    lRepSurname: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].lRepSurname,
                    lRepPosition: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].lRepPosition,
                    lRepPhone: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].lRepPhone,
                    lRepEmail: this.state.oldProjectList[index].locations[index1].locationRepresentatives[index2].lRepEmail
                  }
                  projectLocationRepresentativeList.push(newRepres);
                }
              }
            }

            const newRepres = {
              id: this.state.projectList[index].locations[index1].id,
              locationName: this.state.projectList[index].locations[index1].locationName,
              locationAddress: this.state.projectList[index].locations[index1].locationAddress,
              locationDistance: this.state.projectList[index].locations[index1].locationDistance,
              locationRepresentatives: projectLocationRepresentativeList
            }
            projectLocationList.push(newRepres);
          }

          if (this.state.oldProjectList.length > 0) {
            for (let index1 = 0; index1 < this.state.projectList[index].locations.length; index1++) {
              let whichIndexProjectLocation = this.state.projectList[index].locations.findIndex(i => i.id === this.state.oldProjectList[index].locations[index1].id);
              if (whichIndexProjectLocation === -1) {
                const newRepres = {
                  id: this.state.oldProjectList[index].locations[index1].id,
                  active: false,
                  locationName: this.state.oldProjectList[index].locations[index1].locationName,
                  locationAddress: this.state.oldProjectList[index].locations[index1].locationAddress,
                  locationDistance: this.state.oldProjectList[index].locations[index1].locationDistance,
                  locationRepresentatives: this.state.oldProjectList[index].locations[index1].locationRepresentatives
                }
                projectLocationList.push(newRepres);
              }
            }
          }

          projectListDB2.push(
            {
              id: this.state.projectList[index].id,
              projectName: this.state.projectList[index].projectName,
              distance: this.state.projectList[index].distance,
              address: this.state.projectList[index].address,
              services: this.state.projectList[index].services,
              representatives: projectRepresentativeList,
              locations: projectLocationList
            }
          );
        }

        for (let index = 0; index < this.state.oldProjectList.length; index++) {

          let whichIndexProject = this.state.projectList.findIndex(i => i.id === this.state.oldProjectList[index].id);

          if (whichIndexProject === -1) {
            projectListDB2.push(
              {
                id: this.state.oldProjectList[index].id,
                active: false,
                projectName: this.state.oldProjectList[index].projectName,
                distance: this.state.oldProjectList[index].distance,
                address: this.state.oldProjectList[index].address,
                services: this.state.oldProjectList[index].services,
                representatives: this.state.oldProjectList[index].representatives,
                locations: this.state.oldProjectList[index].locations
              }
            );
          }
        }

        const newItem = {
          id: this.state.companyId,
          fullName: this.state.companyFullName,
          shortName: this.state.companyShortName,
          contact: _contact,
          representative: _representative,
          projects: projectListDB2,
          logoPath: this.state.logoPath
        }
        console.log("ADD", newItem);
        var response = await handleRequest("POST", "/VendorLog/update", newItem);
        if (response.data !== null && response.data !== undefined) {
          if (response.data == "SUCCESS") {
            this.resetInputs();
            this.restartTable();
            this.showSuccess();
          }
          else {
            this.showError();
          }
        }
        else {
          this.showWarning();
        }

        // const response = await handleRequest("GET", "/User/getCompanyItems");
        // let companyUsers = [];

        // if (response.data.length !== 0) {
        //   companyUsers = response.data;

        //   let updatedEmailUserIds = [];

        //   for (let index = 0; index < this.state.oldProjectList.length; index++) {
        //     let whichIndexUser = projectListDB.findIndex(i => i.id === this.state.oldProjectList[index].id);

        //     if (whichIndexUser !== -1)
        //       if (projectListDB[whichIndexUser].email !== this.state.oldProjectList[index].email && projectListDB[whichIndexUser].active !== false) {
        //         let whichUserWillDelete = companyUsers.findIndex(i => i.email === this.state.oldProjectList[whichIndexUser].email)

        //         if (whichUserWillDelete !== -1) {

        //           const updatedUser = {
        //             id: companyUsers[whichUserWillDelete].id,
        //             username: projectListDB[whichIndexUser].name + "" + projectListDB[whichIndexUser].surname,
        //             name: projectListDB[whichIndexUser].name,
        //             middleName: projectListDB[whichIndexUser].middleName,
        //             lastName: projectListDB[whichIndexUser].lastName,
        //             password: companyUsers[whichUserWillDelete].password,
        //             email: projectListDB[whichIndexUser].email,
        //             accessToken: projectListDB[whichIndexUser].accessToken,
        //             companyId: companyUsers[whichUserWillDelete].companyId,
        //             projectId: companyUsers[whichUserWillDelete].projectId,
        //             userType: companyUsers[whichUserWillDelete].userType
        //           }

        //           updatedEmailUserIds.push(companyUsers[whichUserWillDelete].id);

        //           //await handleRequest("POST", "/User/update", updatedUser);
        //         }
        //       }
        //   }

        //   for (let index = 0; index < projectListDB.length; index++) {
        //     let whichIndexUser = companyUsers.findIndex(i => i.email === projectListDB[index].email);

        //     if (whichIndexUser === -1)
        //       if (!updatedEmailUserIds.includes(projectListDB[index].id)) {
        //         const newUser = {
        //           email: projectListDB[index].email,
        //           username: projectListDB[index].name + "" + projectListDB[index].surname,
        //           password: "demo",
        //           userType: 0
        //         }

        //         //await handleRequest("POST", "/User/add", newUser);
        //       }
        //   }

        //   for (let index = 0; index < this.state.oldProjectList.length; index++) {
        //     let whichIndexUser = projectListDB.findIndex(i => i.email === this.state.oldProjectList[index].email);

        //     if (whichIndexUser !== -1)
        //       if (projectListDB[whichIndexUser].active === false) {
        //         let whichUserWillDelete = companyUsers.findIndex(i => i.email === projectListDB[whichIndexUser].email)

        //         if (whichUserWillDelete !== -1) {

        //           const deletedUser = {
        //             id: companyUsers[whichUserWillDelete].id,
        //             active: false,
        //             username: companyUsers[whichUserWillDelete].username,
        //             name: companyUsers[whichUserWillDelete].name,
        //             middleName: companyUsers[whichUserWillDelete].middleName,
        //             lastName: companyUsers[whichUserWillDelete].lastName,
        //             password: companyUsers[whichUserWillDelete].password,
        //             email: companyUsers[whichUserWillDelete].email,
        //             accessToken: companyUsers[whichUserWillDelete].accessToken,
        //             companyId: companyUsers[whichUserWillDelete].companyId,
        //             projectId: companyUsers[whichUserWillDelete].projectId,
        //             userType: companyUsers[whichUserWillDelete].userType
        //           }

        //           //await handleRequest("POST", "/User/update", deletedUser);
        //         }
        //       }
        //   }
        // }

        //Üstteki User update ve alttaki customer log update üzerinde çalışılacak!
      }
    } else {
      this.showWarning();
    }
  }

  resetInputs = () => {
    this.getProducts();
    this.setState({
      hidden: true,
      modalShow: false,
      modalShowProject: false,
      createNewButton: "Create New",
      lastButton: "Save",
      lastButtonVariant: "success",
      createNewButtonVariant: "info",
      companyId: 0,
      companyFullName: "",
      companyShortName: "",
      address: "",
      country: "Select Country ",
      phone: [],
      fax: [],
      email: [],
      web: "",
      name: "",
      midname: "",
      surname: "",
      position: "",
      rPhone: "",
      rEmail: "",
      oldCompany: {},
      contactId: 0,
      representativeId: 0,
      projectProducts: [],
      projectKey: 0,
      projectList: [],
      projectAddress: "",
      distance: "",
      projectName: "",
      pRepName: "",
      pRepMidName: "",
      pRepSurname: "",
      pRepPosition: "",
      pRepPhone: "",
      projectUpdateButtonHidden: "none",
      projectAddButtonHidden: "block",
      projectId: "",
      oldProjectList: []
    });
  }

  edit = async (row) => {
    const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
    const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
    var _representative;
    var _contact;
    var _phones = [];
    var _faxes = [];
    var _mails = [];
    if (row.contact !== null && row.contact !== undefined) {
      _contact = row.contact;

      if (_contact.phones !== null && _contact.phones !== undefined && _contact.phones !== "") {
        _phones = _contact.phones.split(',');
      }

      if (_contact.faxes !== null && _contact.faxes !== undefined && _contact.faxes !== "")
        _faxes = _contact.faxes.split(',');

      if (_contact.mails !== null && _contact.mails !== undefined && _contact.mails !== "")
        _mails = _contact.mails.split(',');
    }
    else _contact = _contactDefault;

    if (row.representative !== null && row.representative !== undefined)
      _representative = row.representative;
    else _representative = _representativeDefault;

    var _imageUrl = "";

    if (row.logoPath !== null && row.logoPath !== undefined && row.logoPath.length > 0) {
      _imageUrl = `${API_BASE}/VendorLog/download/` + row.id
    }

    var j = 1;
    row.projects.forEach(element => {
      element.key = j;
      j++;
    });

    let oldProjectList = [];

    for (let index = 0; index < row.projects.length; index++) {

      let oldProjectRepresentativeList = [];
      let oldProjectLocationList = [];

      for (let index1 = 0; index1 < row.projects[index].representatives.length; index1++) {
        const newRepres = {
          key: row.projects[index].representatives[index1].id,
          id: row.projects[index].representatives[index1].id,
          active: false,
          pRepName: row.projects[index].representatives[index1].pRepName,
          pRepMidName: row.projects[index].representatives[index1].pRepMidName,
          pRepSurname: row.projects[index].representatives[index1].pRepSurname,
          pRepPosition: row.projects[index].representatives[index1].pRepPosition,
          pRepPhone: row.projects[index].representatives[index1].pRepPhone,
          pRepEmail: row.projects[index].representatives[index1].pRepEmail
        }
        oldProjectRepresentativeList.push(newRepres);
      }

      for (let index2 = 0; index2 < row.projects[index].locations.length; index2++) {

        let oldProjectLocationRepresentativeList = [];

        for (let index3 = 0; index3 < row.projects[index].locations[index2].locationRepresentatives.length; index3++) {
          const newLocationRepres = {
            key: row.projects[index].locations[index2].locationRepresentatives[index3].id,
            id: row.projects[index].locations[index2].locationRepresentatives[index3].id,
            active: false,
            lRepName: row.projects[index].locations[index2].locationRepresentatives[index3].lRepName,
            lRepMidName: row.projects[index].locations[index2].locationRepresentatives[index3].lRepMidName,
            lRepSurname: row.projects[index].locations[index2].locationRepresentatives[index3].lRepSurname,
            lRepPosition: row.projects[index].locations[index2].locationRepresentatives[index3].lRepPosition,
            lRepPhone: row.projects[index].locations[index2].locationRepresentatives[index3].lRepPhone,
            lRepEmail: row.projects[index].locations[index2].locationRepresentatives[index3].lRepEmail
          }

          oldProjectLocationRepresentativeList.push(newLocationRepres);
        }

        const newLocation = {
          key: row.projects[index].locations[index2].id,
          id: row.projects[index].locations[index2].id,
          active: false,
          locationName: row.projects[index].locations[index2].locationName,
          locationDistance: row.projects[index].locations[index2].locationDistance,
          locationAddress: row.projects[index].locations[index2].locationAddress,
          locationRepresentatives: oldProjectLocationRepresentativeList
        }
        oldProjectLocationList.push(newLocation);
      }

      const newItem = {
        key: row.projects[index].id,
        id: row.projects[index].id,
        active: false,
        projectName: row.projects[index].projectName,
        distance: row.projects[index].distance,
        address: row.projects[index].address,
        services: row.projects[index].services,
        representatives: oldProjectRepresentativeList,
        locations: oldProjectLocationList
      }

      oldProjectList.push(newItem);
    }

    console.log("OLD", oldProjectList);

    this.setState({
      hidden: false,
      createNewButton: "Stop Updating",
      lastButton: "Update Customer Log",
      lastButtonVariant: "warning",
      createNewButtonVariant: "danger",
      companyId: row.id,
      companyFullName: row.fullName,
      companyShortName: row.shortName,
      aboutCompanyText: row.about,
      visionText: row.vision,
      missionText: row.mision,
      contactId: _contact.id,
      address: _contact.address,
      country: _contact.country,
      phone: _phones,
      fax: _faxes,
      email: _mails,
      web: _contact.web,
      representativeId: _representative.id,
      name: _representative.name,
      midname: _representative.midname,
      surname: _representative.lastname,
      position: _representative.position,
      rPhone: _representative.phone,
      rEmail: _representative.email,
      imageUrl: _imageUrl,
      logoPath: row.logoPath,
      oldCompany: row,
      projectList: row.projects,
      oldProjectList: oldProjectList,
      projectUpdateButtonHidden: "none",
      projectAddButtonHidden: "block"
    });
    document.getElementById('kt_scrolltop').click();
  }

  deleteProjectModal = (row) => {
    this.setState({
      projectKey: row.key,
      modalShowProject: true
    });
  }

  deleteProject = async () => {
    let list = this.state.projectList;
    let flagProjectKey = this.state.projectKey;

    list.splice(list.findIndex(function (i) {
      return i.key === flagProjectKey;
    }), 1);

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      projectList: list.slice(),
      projectKey: 0,
      modalShowProject: false
    });
  }

  deleteModal = (row) => {
    this.setState({
      companyId: row.id,
      modalShow: true
    });
  }

  delete = async () => {
    const deletedItem = {
      id: this.state.companyId
    }

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);



    var response = await handleRequest("POST", "/VendorLog/delete", deletedItem);
    if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        this.restartTable();
        this.resetInputs();
        this.showSuccess();
      }
      else {
        this.showError();
      }
    }
    else {
      this.showWarning();
    }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  addContact = () => {
    this.addContactItem();
    this.setState({
      location: "Select",
      address: "",
      phone: [],
      fax: [],
      webs: [],
      email: [],
      socials: []
    });
  }

  addContactItem = () => {
    this.setState(state => ({
      itemsContacts: [...state.itemsContacts, {
        contactId: this.state.itemsContacts.length,
        location: this.state.location,
        address: this.state.address,
        phone: this.state.phone,
        fax: this.state.fax,
        web: this.state.webs,
        email: this.state.email,
        soccAcc: this.state.socials
      }]
    }));
  }

  addCertificate = () => {
    this.addCertificateItem();
    this.setState({
      nameOfCertificate: "Select",
      validity: "",
      respDepartment: "",
      certificate: ""
    });
  }

  addCertificateItem = () => {
    this.setState(state => ({
      itemsCertificates: [...state.itemsCertificates, {
        certificateId: this.state.itemsCertificates.length,
        name: this.state.nameOfCertificate,
        validity: this.state.validity,
        respDepartment: this.state.respDepartment,
        certificate: this.state.certificate
      }]
    }));
  }

  showOrHideModal = () => {

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalShow: !this.state.modalShow
    });
  }

  showOrHideModalProject = () => {

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalShowProject: !this.state.modalShowProject
    });
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onEditClick(e) {
    if (e.target.value === undefined)
      e.stopPropagation();
  }

  companyLogoChange = e => {
    this.setState({
      companyLogoName: e.target.files[0].name
    });
  }

  aboutCompanyChange = e => {
    this.setState({
      aboutCompanyName: e.target.files[0].name
    });
  }

  missionChange = e => {
    this.setState({
      missionName: e.target.files[0].name
    });
  }

  visionChange = e => {
    this.setState({
      visionName: e.target.files[0].name
    });
  }

  certificateChange = e => {
    this.setState({
      certificate: e.target.files[0].name
    });
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error('Image must smaller than 4MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handlePhotoChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {

      if (info.file.response.length > 0) {
        this.setState({
          logoPath: info.file.response[0]
        })
      }

      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  }

  actionTemplate(rowData, column) {
    return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
  }

  numberOfProjects(rowData, column) {
    var result = 0;
    if (rowData.products !== null && rowData.products !== undefined) {
      rowData.products.forEach(element => {
        if (element.product)
          if (element.single) {
            result = result + 1;
          }
          else result = result + element.projectCount
      });
    }
    return result;
  }

  numberOfProjects2(record) {
    var rowData = record;
    var result = 0;
    if (rowData.projects !== null && rowData.projects !== undefined) {
      result = rowData.projects.length;
    }
    return result;
  }

  productList(rowData, column) {
    var result = [];
    if (rowData.products !== null && rowData.products !== undefined) {
      rowData.products.forEach(element => {
        if (element.product)
          result.push(element.name);
      });
    }
    return result.toString();
  }

  getProjectProducts(record) {
    var rowData = record;
    var result = [];
    if (rowData.services !== null && rowData.services !== undefined) {
      rowData.services.forEach(element => {
        var index = this.state.products.findIndex(p => p.id === element.companyServiceId);
        if (index >= 0)
          result.push(this.state.products[index].description);
      });
    }
    return result.toString();
  }

  phones(value) {
    var rowData = value;
    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].phones !== null && rowData[0].phones !== undefined) {
      rowData[0].phones.forEach(e => {
        result.push(e.phone);
      });
    }
    return result.toString();
  }

  faxes(value) {
    var rowData = value;
    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].faxes !== null && rowData[0].faxes !== undefined) {
      rowData[0].faxes.forEach(e => {
        result.push(e.fax);
      });
    }
    return result.toString();
  }

  mails(value) {
    var rowData = value;
    var result = [];
    if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].mails !== null && rowData[0].mails !== undefined) {
      rowData[0].mails.forEach(e => {
        result.push(e.mail);
      });
    }
    return result.toString();
  }

  onSelectOptionChange = event => {
    this.setState({
      selectOption: event.target.value,
    });
  }

  addItem = () => {
    const { projectNames, selectOption } = this.state;
    if (selectOption.length > 0) {
      const obj = { key: selectOption, value: selectOption };
      this.setState({
        projectNames: [...projectNames, obj],
        selectOption: '',
      });
    }
  }

  deletePRepModal = (row) => {
    this.setState({
      pRepId: row.key,
      modalDeletePRep: true
    });
  }

  showOrHideModalDeletePRep = () => {
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalDeletePRep: !this.state.modalDeletePRep
    });
  }

  deletePRepModalOK = () => {
    let list = this.state.pRepList;
    let flagpRepId = this.state.pRepId;

    list.splice(list.findIndex(function (i) {
      return i.key === flagpRepId;
    }), 1);

    this.setState({
      pRepList: list.slice(),
      pRepId: 0,
      modalDeletePRep: false,
      pRepName: "",
      pRepMidName: "",
      pRepSurname: "",
      pRepPosition: "",
      pRepPhone: "",
      pRepEmail: "",
      projectRepUpdateButtonHidden: "none",
      projectRepAddButtonHidden: "block"
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  addProjectRep = () => {
    var list = this.state.pRepList;

    if (this.state.pRepName !== "" && this.state.pRepMidName !== "" && this.state.pRepSurname !== ""
      && this.state.pRepEmail !== "" && this.state.pRepPhone !== "" && this.state.pRepPosition !== "") {

      var _key = list.length + 1;

      const newItem = {
        key: _key,
        pRepName: this.state.pRepName,
        pRepMidName: this.state.pRepMidName,
        pRepSurname: this.state.pRepSurname,
        pRepPosition: this.state.pRepPosition,
        pRepPhone: this.state.pRepPhone,
        pRepEmail: this.state.pRepEmail
      }

      list.push(newItem);

      this.setState({
        pRepList: list.slice(),
        pRepName: "",
        pRepMidName: "",
        pRepSurname: "",
        pRepPosition: "",
        pRepPhone: "",
        pRepEmail: ""
      })
    } else {
      this.setState({
        modalError: true
      });
    }
  }

  editPRep = (row) => {

    console.log(row.key);

    this.setState({
      pRepId: row.key,
      pRepName: row.pRepName,
      pRepMidName: row.pRepMidName,
      pRepSurname: row.pRepSurname,
      pRepPosition: row.pRepPosition,
      pRepPhone: row.pRepPhone,
      pRepEmail: row.pRepEmail,
      projectRepUpdateButtonHidden: "block",
      projectRepAddButtonHidden: "none"
    });
  }

  updateProjectRep = () => {
    let list = this.state.pRepList;
    let flagPRepId = this.state.pRepId;

    let objIndex = list.findIndex((obj => obj.key === flagPRepId));

    list[objIndex].pRepName = this.state.pRepName;
    list[objIndex].pRepMidName = this.state.pRepMidName;
    list[objIndex].pRepSurname = this.state.pRepSurname;
    list[objIndex].pRepPosition = this.state.pRepPosition;
    list[objIndex].pRepPhone = this.state.pRepPhone;
    list[objIndex].pRepEmail = this.state.pRepEmail;

    this.setState({
      pRepList: list.slice(),
      pRepName: "",
      pRepMidName: "",
      pRepSurname: "",
      pRepPosition: "",
      pRepPhone: "",
      pRepEmail: "",
      projectRepUpdateButtonHidden: "none",
      projectRepAddButtonHidden: "block"
    });
  }

  cancelProjectRep = () => {
    this.setState({
      pRepId: 0,
      modalDeletePRep: false,
      pRepName: "",
      pRepMidName: "",
      pRepSurname: "",
      pRepPosition: "",
      pRepPhone: "",
      pRepEmail: "",
      projectRepUpdateButtonHidden: "none",
      projectRepAddButtonHidden: "block"
    });
  }

  showOrHideModalError = () => {
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalError: !this.state.modalError
    });
  }

  deleteLRepModal = (row) => {
    this.setState({
      lRepId: row.key,
      modalDeleteLRep: true
    });
  }

  showOrHideModalDeleteLRep = () => {
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalDeleteLRep: !this.state.modalDeleteLRep
    });
  }

  deleteLRepModalOK = () => {
    let list = this.state.lRepList;
    let flagLRepId = this.state.lRepId;

    list.splice(list.findIndex(function (i) {
      return i.key === flagLRepId;
    }), 1);

    this.setState({
      lRepList: list.slice(),
      lRepId: 0,
      modalDeleteLRep: false,
      lRepName: "",
      lRepMidName: "",
      lRepSurname: "",
      lRepPosition: "",
      lRepPhone: "",
      lRepEmail: "",
      locationRepUpdateButtonHidden: "none",
      locationRepAddButtonHidden: "block"
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  showOrHideModalLocationRep = () => {
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalLocationRep: !this.state.modalLocationRep
    });
  }

  addProject = () => {
    var list = this.state.projectList;
    if (this.state.projectName.trim() !== "") {

      var _services = [];
      this.state.projectProducts.forEach(element => {
        var obj = { id: 0, companyServiceId: element }
        _services.push(obj);
      });

      var _projectReps = [];
      this.state.pRepList.forEach(element => {
        var obj = {
          id: 0,
          pRepName: element.pRepName,
          pRepMidName: element.pRepMidName,
          pRepSurname: element.pRepSurname,
          pRepPosition: element.pRepPosition,
          pRepPhone: element.pRepPhone,
          pRepEmail: element.pRepEmail
        }
        _projectReps.push(obj);
      });

      var _locations = [];
      this.state.locationList.forEach(element => {
        var obj = {
          id: 0,
          locationName: element.locationName,
          locationDistance: element.locationDistance,
          locationAddress: element.locationAddress,
          locationRepresentatives: element.locationRepresentatives
        }
        _locations.push(obj);
      });

      var _key = list.length + 1;

      const newItem = {
        key: _key,
        projectName: this.state.projectName,
        address: this.state.projectAddress,
        distance: this.state.distance,
        representatives: _projectReps,
        services: _services,
        locations: _locations
      }

      list.push(newItem);

      this.setState({
        projectList: list.slice(),
        projectName: "",
        projectAddress: "",
        distance: "",
        pRepList: [],
        lRepList: [],
        locationList: [],
        projectProducts: []
      });
    } else {
      this.showWarning();
    }
  }

  editProject = (row) => {
    let projectServices = [];

    for (let index = 0; index < row.services.length; index++) {
      projectServices.push(row.services[index].companyServiceId);
    }

    let projectRepresentativeList = [];

    for (let index = 0; index < row.representatives.length; index++) {
      const projectRepres = {
        key: row.representatives[index].id,
        id: row.representatives[index].id,
        pRepName: row.representatives[index].pRepName,
        pRepMidName: row.representatives[index].pRepMidName,
        pRepSurname: row.representatives[index].pRepSurname,
        pRepPosition: row.representatives[index].pRepPosition,
        pRepPhone: row.representatives[index].pRepPhone,
        pRepEmail: row.representatives[index].pRepEmail
      }
      projectRepresentativeList.push(projectRepres);
    }

    let projectLocationList = [];

    for (let index = 0; index < row.locations.length; index++) {

      let projectLocationRepList = [];

      for (let index1 = 0; index1 < row.locations[index].locationRepresentatives.length; index1++) {
        const projectLocationRep = {
          key: row.locations[index].locationRepresentatives[index1].id,
          id: row.locations[index].locationRepresentatives[index1].id,
          lRepName: row.locations[index].locationRepresentatives[index1].lRepName,
          lRepMidName: row.locations[index].locationRepresentatives[index1].lRepMidName,
          lRepSurname: row.locations[index].locationRepresentatives[index1].lRepSurname,
          lRepPosition: row.locations[index].locationRepresentatives[index1].lRepPosition,
          lRepPhone: row.locations[index].locationRepresentatives[index1].lRepPhone,
          lRepEmail: row.locations[index].locationRepresentatives[index1].lRepEmail
        }
        projectLocationRepList.push(projectLocationRep);
      }

      const projectLocation = {
        key: row.locations[index].id,
        id: row.locations[index].id,
        locationName: row.locations[index].locationName,
        locationDistance: row.locations[index].locationDistance,
        locationAddress: row.locations[index].locationAddress,
        locationRepresentatives: projectLocationRepList
      }
      projectLocationList.push(projectLocation);
    }

    this.setState({
      projectKey: row.key,
      projectId: row.id,
      projectName: row.projectName,
      distance: row.distance,
      projectAddress: row.address,
      projectProducts: projectServices,
      pRepList: projectRepresentativeList,
      locationList: projectLocationList,
      projectUpdateButtonHidden: "block",
      projectAddButtonHidden: "none"
    });
  }

  updateProject = () => {
    let list = this.state.projectList;
    let flagProjectKey = this.state.projectKey;

    let objIndex = list.findIndex((obj => obj.key === flagProjectKey));

    let _servicesUpdate = [];

    this.state.projectProducts.forEach(element => {
      var obj = { id: 0, companyServiceId: element }
      _servicesUpdate.push(obj);
    });

    list[objIndex].projectName = this.state.projectName;
    list[objIndex].distance = this.state.distance;
    list[objIndex].address = this.state.projectAddress;
    list[objIndex].projectProducts = _servicesUpdate;
    list[objIndex].representatives = this.state.pRepList;
    list[objIndex].locations = this.state.locationList;

    this.setState({
      projectId: "",
      projectName: "",
      distance: "",
      projectAddress: "",
      projectProducts: [],
      locationList: [],
      pRepList: [],
      projectUpdateButtonHidden: "none",
      projectAddButtonHidden: "block"
    })
  }

  cancelProject = () => {
    this.setState({
      projectId: "",
      projectName: "",
      distance: "",
      projectAddress: "",
      projectProducts: [],
      locationList: [],
      pRepList: [],
      projectUpdateButtonHidden: "none",
      projectAddButtonHidden: "block"
    });
  }

  addLocation = () => {
    var list = this.state.locationList;

    if (this.state.locationName.trim() !== "") {

      var _locationReps = [];
      this.state.lRepList.forEach(element => {
        var obj = {
          id: 0,
          lRepName: element.lRepName,
          lRepMidName: element.lRepMidName,
          lRepSurname: element.lRepSurname,
          lRepPosition: element.lRepPosition,
          lRepPhone: element.lRepPhone,
          lRepEmail: element.lRepEmail
        }
        _locationReps.push(obj);
      });

      var _key = list.length + 1;

      const newItem = {
        key: _key,
        locationName: this.state.locationName,
        locationAddress: this.state.locationAddress,
        locationDistance: this.state.locationDistance,
        locationRepresentatives: _locationReps
      }

      list.push(newItem);

      this.setState({
        locationList: list.slice(),
        locationName: "",
        locationAddress: "",
        locationDistance: "",
        lRepName: "",
        lRepMidName: "",
        lRepSurname: "",
        lRepPosition: "",
        lRepPhone: "",
        lRepEmail: "",
        lRepList: []
      });
    } else {
      this.showWarning();
    }
  }

  editLocation = (row) => {
    let locationReps = [];

    console.log(row);

    for (let index = 0; index < row.locationRepresentatives.length; index++) {
      const projectLocationReps = {
        key: row.locationRepresentatives[index].id,
        id: row.locationRepresentatives[index].id,
        lRepName: row.locationRepresentatives[index].lRepName,
        lRepMidName: row.locationRepresentatives[index].lRepMidName,
        lRepSurname: row.locationRepresentatives[index].lRepSurname,
        lRepPosition: row.locationRepresentatives[index].lRepPosition,
        lRepEmail: row.locationRepresentatives[index].lRepEmail,
        lRepPhone: row.locationRepresentatives[index].lRepPhone,
      }
      locationReps.push(projectLocationReps);
    }

    this.setState({
      locationKey: row.key,
      locationId: row.id,
      locationName: row.locationName,
      locationDistance: row.locationDistance,
      locationAddress: row.locationAddress,
      lRepList: locationReps,
      locationUpdateButtonHidden: "block",
      locationAddButtonHidden: "none"
    });
  }

  updateLocation = () => {
    let list = this.state.locationList;
    let flagLocationKey = this.state.locationKey;

    let objIndex = list.findIndex((obj => obj.key === flagLocationKey));

    // var _locationReps = [];
    // this.state.lRepList.forEach(element => {
    //   var obj = {
    //     id: 0,
    //     name: element.lRepName,
    //     midname: element.lRepMidName,
    //     surname: element.lRepSurname,
    //     position: element.lRepPosition,
    //     phone: element.lRepPhone,
    //     email: element.lRepEmail
    //   }
    //   _locationReps.push(obj);
    // });

    list[objIndex].locationName = this.state.locationName;
    list[objIndex].locationDistance = this.state.locationDistance;
    list[objIndex].locationAddress = this.state.locationAddress;
    list[objIndex].locationRepresentatives = this.state.lRepList;

    this.setState({
      locationKey: 0,
      locationId: 0,
      locationName: "",
      locationDistance: "",
      locationAddress: "",
      lRepList: [],
      locationUpdateButtonHidden: "none",
      locationAddButtonHidden: "block"
    })
  }

  cancelLocation = () => {
    this.setState({
      locationId: 0,
      locationKey: 0,
      locationName: "",
      locationDistance: "",
      locationAddress: "",
      lRepList: [],
      locationUpdateButtonHidden: "none",
      locationAddButtonHidden: "block"
    });
  }

  showOrHideModalLocation = () => {
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      modalShowLocation: !this.state.modalShowLocation
    });
  }

  deleteLocationModal = (row) => {
    this.setState({
      locationKey: row.key,
      modalShowLocation: true
    });
  }

  deleteLocation = async () => {
    let list = this.state.locationList;
    let flagLocationKey = this.state.locationKey;

    list.splice(list.findIndex(function (i) {
      return i.key === flagLocationKey;
    }), 1);

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({
      locationList: list.slice(),
      locationKey: 0,
      modalShowLocation: false
    });
  }

  addLocationRep = () => {
    var list = this.state.lRepList;

    if (this.state.lRepName !== "" && this.state.lRepMidName !== "" && this.state.lRepSurname !== ""
      && this.state.lRepEmail !== "" && this.state.lRepPhone !== "" && this.state.lRepPosition !== "") {

      var _key = list.length + 1;

      const newItem = {
        key: _key,
        lRepName: this.state.lRepName,
        lRepMidName: this.state.lRepMidName,
        lRepSurname: this.state.lRepSurname,
        lRepPosition: this.state.lRepPosition,
        lRepPhone: this.state.lRepPhone,
        lRepEmail: this.state.lRepEmail
      }

      list.push(newItem);

      this.setState({
        lRepList: list.slice(),
        lRepName: "",
        lRepMidName: "",
        lRepSurname: "",
        lRepPosition: "",
        lRepPhone: "",
        lRepEmail: ""
      })
    } else {
      this.setState({
        modalError: true
      });
    }
  }

  editLRep = (row) => {
    this.setState({
      lRepId: row.key,
      lRepName: row.lRepName,
      lRepMidName: row.lRepMidName,
      lRepSurname: row.lRepSurname,
      lRepPosition: row.lRepPosition,
      lRepPhone: row.lRepPhone,
      lRepEmail: row.lRepEmail,
      locationRepUpdateButtonHidden: "block",
      locationRepAddButtonHidden: "none"
    });
  }

  updateLocationRep = () => {
    let list = this.state.lRepList;
    let flagLRepId = this.state.lRepId;

    let objIndex = list.findIndex((obj => obj.key === flagLRepId));

    list[objIndex].lRepName = this.state.lRepName;
    list[objIndex].lRepMidName = this.state.lRepMidName;
    list[objIndex].lRepSurname = this.state.lRepSurname;
    list[objIndex].lRepPosition = this.state.lRepPosition;
    list[objIndex].lRepPhone = this.state.lRepPhone;
    list[objIndex].lRepEmail = this.state.lRepEmail;

    this.setState({
      lRepList: list.slice(),
      lRepId: 0,
      lRepName: "",
      lRepMidName: "",
      lRepSurname: "",
      lRepPosition: "",
      lRepPhone: "",
      lRepEmail: "",
      locationRepUpdateButtonHidden: "none",
      locationRepAddButtonHidden: "block"
    });
  }

  cancelLocationRep = () => {
    this.setState({
      lRepId: 0,
      modalDeleteLRep: false,
      lRepName: "",
      lRepMidName: "",
      lRepSurname: "",
      lRepPosition: "",
      lRepPhone: "",
      lRepEmail: "",
      locationRepUpdateButtonHidden: "none",
      locationRepAddButtonHidden: "block"
    });
  }

  render() {
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    let optionsCountry = COUNTRIES.map((data) =>
      <option
        key={data.value}
        value={data.name}
      >
        {data.name}
      </option>
    );

    return (
      <div>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShow}
          onHide={this.showOrHideModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"> <FormattedMessage id="GeneralButtonDelete" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModal}><FormattedMessage id="GeneralTextClose" /></Button>
            <Button variant="danger" onClick={this.delete} > <FormattedMessage id="GeneralButtonDelete" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowProject}
          onHide={this.showOrHideModalProject}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="GeneralButtonDelete" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalProject}><FormattedMessage id="GeneralTextClose" /></Button>
            <Button variant="danger" onClick={this.deleteProject} > <FormattedMessage id="GeneralButtonDelete" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowLocation}
          onHide={this.showOrHideModalLocation}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="GeneralButtonDelete" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalLocation}><FormattedMessage id="GeneralTextClose" /></Button>
            <Button variant="danger" onClick={this.deleteLocation} ><FormattedMessage id="GeneralButtonDelete" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalError}
          onHide={this.showOrHideModalError}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="GeneralMissingValues" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><FormattedMessage id="GeneralSureAllValue" /></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalError}><FormattedMessage id="GeneralTextClose" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalDeletePRep}
          onHide={this.showOrHideModalDeletePRep}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="GeneralButtonDelete" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDeletePRep}><FormattedMessage id="GeneralTextClose" /></Button>
            <Button variant="danger" onClick={this.deletePRepModalOK}><FormattedMessage id="GeneralButtonDelete" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalDeleteLRep}
          onHide={this.showOrHideModalDeleteLRep}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="GeneralButtonDelete" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDeleteLRep}><FormattedMessage id="GeneralTextClose" /></Button>
            <Button variant="danger" onClick={this.deleteLRepModalOK}><FormattedMessage id="GeneralButtonDelete" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalLocationRep}
          onHide={this.showOrHideModalLocationRep}
          aria-labelledby="contained-modal-title-vcenter"
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="VLLocationRepresentatives" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                <FormattedMessage id="VLLocationRepresentatives" />
              </Form.Label>
              <Col sm="9">
              </Col>
            </Form.Group>

            <RowAnt>
              <Space size="large" >
                <Space direction="vertical">
                  <div><FormattedMessage id="VLName" /></div>
                  <Form.Control value={this.state.lRepName} onChange={(e) => this.setState({ lRepName: e.target.value })} />
                </Space>

                <Space direction="vertical">
                  <div><FormattedMessage id="VLMidname" /></div>
                  <Form.Control value={this.state.lRepMidName} onChange={(e) => this.setState({ lRepMidName: e.target.value })} />
                </Space>

                <Space direction="vertical">
                  <div><FormattedMessage id="VLSurname" /></div>
                  <Form.Control value={this.state.lRepSurname} onChange={(e) => this.setState({ lRepSurname: e.target.value })} />
                </Space>

                <Space direction="vertical">
                  <div><FormattedMessage id="VLPosition" /></div>
                  <Form.Control value={this.state.lRepPosition} onChange={(e) => this.setState({ lRepPosition: e.target.value })} />
                </Space>

                <Space direction="vertical">
                  <div><FormattedMessage id="VLPhone" /></div>
                  <Form.Control value={this.state.lRepPhone} onChange={(e) => this.setState({ lRepPhone: e.target.value })} />
                </Space>

                <Space direction="vertical">
                  <div><FormattedMessage id="VLEMail" /></div>
                  <Form.Control value={this.state.lRepEmail} onChange={(e) => this.setState({ lRepEmail: e.target.value })} />
                </Space>

                <Space style={{ display: this.state.locationRepAddButtonHidden }} direction="vertical">
                  <div></div>
                  <Button style={{ width: '100%', marginTop: '1.25rem' }} onClick={this.addLocationRep} type="button"><FormattedMessage id="GeneralButtonAdd" /></Button>
                </Space>

                <Space style={{ display: this.state.locationRepUpdateButtonHidden }} direction="vertical">
                  <div></div>
                  <Button style={{ width: '100%', marginTop: '1.25rem' }} variant="warning" onClick={this.updateLocationRep} type="button"><FormattedMessage id="GeneralButtonUpdate" /></Button>
                </Space>

                <Space style={{ display: this.state.locationRepUpdateButtonHidden }} direction="vertical">
                  <div></div>
                  <Button style={{ width: '100%', marginTop: '1.25rem' }} variant="secondary" onClick={this.cancelLocationRep} type="button"><FormattedMessage id="GeneralButtonCancel" /></Button>
                </Space>
              </Space>
            </RowAnt>

            <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.lRepList} bordered >
              <Column
                width='50px'
                title=""
                key="action"
                render={(text, record) => (
                  <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteLRepModal.bind(this)} onEditRow={this.editLRep.bind(this)} /></React.Fragment>
                )}
              />
              <Column title={<FormattedMessage id="VLName" />} key="lRepName" dataIndex="lRepName" />
              <Column title={<FormattedMessage id="VLMidname" />} key="lRepMidName" dataIndex="lRepMidName" />
              <Column title={<FormattedMessage id="VLSurname" />} key="lRepSurname" dataIndex="lRepSurname" />
              <Column title={<FormattedMessage id="VLPosition" />} key="lRepPosition" dataIndex="lRepPosition" />
              <Column title={<FormattedMessage id="VLPhone" />} key="lRepPhone" dataIndex="lRepPhone" />
              <Column title={<FormattedMessage id="VLEMail" />} key="lRepEmail" dataIndex="lRepEmail" />
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalLocationRep}><FormattedMessage id="GeneralTextClose" /></Button>
            <Button variant="success" onClick={this.showOrHideModalLocationRep}><FormattedMessage id="GeneralButtonSave" /></Button>
          </Modal.Footer>
        </Modal>

        <div style={{ padding: "4px 16px 4px 24px" }}>
          <Row>
            <Col xs={9}></Col>
            <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
          </Row>
        </div>
        <div hidden={this.state.hidden} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
          <Card>
            <Card.Body>
              <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                <Form>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlFullName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLFullName" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control name="companyFullName" value={this.state.companyFullName}
                        onChange={this.handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLShortName" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control name="companyShortName" value={this.state.companyShortName}
                        onChange={this.handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlFullName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLLogo" />
                    </Form.Label>
                    <Col sm="2">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${API_BASE}/VendorLog/upload`}
                        beforeUpload={this.beforeUpload}
                        onChange={this.handlePhotoChange}
                      >
                        {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload></Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                      <FormattedMessage id="VLHeadOffice" />
                    </Form.Label>
                    <Col sm="4">
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLAddress" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control as="textarea" rows="3" name="address" value={this.state.address}
                        onChange={this.handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLCountry" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control name="country" value={this.state.country}
                        onChange={this.handleChange} as="select">
                        <option value="">Select country</option>
                        {optionsCountry}
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLPhone" />
                    </Form.Label>
                    <Col sm="4">
                      <div className="content-section implementation p-fluid">
                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.phone} onChange={(e) => this.setState({ phone: e.value })}></Chips>
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLFax" />
                    </Form.Label>
                    <Col sm="4">
                      <div className="content-section implementation p-fluid">
                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.fax} onChange={(e) => this.setState({ fax: e.value })}></Chips>
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLWeb" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.web} onChange={(e) => this.setState({ web: e.target.value })}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLEMail" />
                    </Form.Label>
                    <Col sm="4">
                      <div className="content-section implementation p-fluid">
                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.email} onChange={(e) => this.setState({ email: e.value })}></Chips>
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                      <FormattedMessage id="VLVendorRepresentative" />
                    </Form.Label>
                    <Col sm="4">
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLName" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLMidname" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.midname} onChange={(e) => this.setState({ midname: e.target.value })} />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLSurname" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.surname} onChange={(e) => this.setState({ surname: e.target.value })}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLPosition" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.position} onChange={(e) => this.setState({ position: e.target.value })}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLPhone" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.rPhone} onChange={(e) => this.setState({ rPhone: e.target.value })}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '2rem' }} as={Row} controlId="controlShortName">
                    <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="VLEMail" />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control value={this.state.rEmail} onChange={(e) => this.setState({ rEmail: e.target.value })} />
                    </Col>
                  </Form.Group>


                  <Collapse defaultActiveKey={['1']} expandIconPosition={this.state.expandIconPosition}>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="VLProductsAndProjectTypes" />} key="1"  >

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                          <FormattedMessage id="VLProject" />
                        </Form.Label>
                        <Col sm="9">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLProjectName" />
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control value={this.state.projectName} onChange={(e) => this.setState({ projectName: e.target.value })} />
                        </Col>
                        <Col sm="7">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLDistance" />
                        </Form.Label>
                        <Col sm="3">
                          <InputNumber style={{ width: '100%' }} min={1} onChange={(value) => {
                            this.setState({ distance: value })
                          }} value={this.state.distance} />
                        </Col>
                        <Form.Label style={{ fontAlign: 'left', color: 'black' }} column sm="1">
                          km.
                        </Form.Label>
                        <Col sm="6">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLProjectAddress" />
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control as="textarea" rows="3" name="projectAddress" value={this.state.projectAddress}
                            onChange={this.handleChange} />
                        </Col>
                        <Col sm="7">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLProducts" />
                        </Form.Label>
                        <Col sm="3">
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            value={this.state.projectProducts}
                            onChange={(value) => {
                              console.log(value);
                              this.setState({ projectProducts: value })
                            }}
                          >
                            {this.state.products.map(i => (
                              <Option key={i.id} value={i.id}>{i.description}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col sm="7">
                        </Col>
                      </Form.Group>


                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                          <FormattedMessage id="VLProjectRepresentatives" />
                        </Form.Label>
                        <Col sm="9">
                        </Col>
                      </Form.Group>

                      <RowAnt style={{ marginBottom: '1rem' }}>
                        <Space size="large" >
                          <Space direction="vertical">
                            <div><FormattedMessage id="VLName" /></div>
                            <Form.Control value={this.state.pRepName} onChange={(e) => this.setState({ pRepName: e.target.value })} />
                          </Space>

                          <Space direction="vertical">
                            <div><FormattedMessage id="VLMidname" /></div>
                            <Form.Control value={this.state.pRepMidName} onChange={(e) => this.setState({ pRepMidName: e.target.value })} />
                          </Space>

                          <Space direction="vertical">
                            <div><FormattedMessage id="VLSurname" /></div>
                            <Form.Control value={this.state.pRepSurname} onChange={(e) => this.setState({ pRepSurname: e.target.value })} />
                          </Space>

                          <Space direction="vertical">
                            <div><FormattedMessage id="VLPosition" /></div>
                            <Form.Control value={this.state.pRepPosition} onChange={(e) => this.setState({ pRepPosition: e.target.value })} />
                          </Space>

                          <Space direction="vertical">
                            <div><FormattedMessage id="VLPhone" /></div>
                            <Form.Control value={this.state.pRepPhone} onChange={(e) => this.setState({ pRepPhone: e.target.value })} />
                          </Space>

                          <Space direction="vertical">
                            <div><FormattedMessage id="VLEMail" /></div>
                            <Form.Control value={this.state.pRepEmail} onChange={(e) => this.setState({ pRepEmail: e.target.value })} />
                          </Space>

                          <Space style={{ display: this.state.projectRepAddButtonHidden }} direction="vertical">
                            <div></div>
                            <Button style={{ width: '100%', marginTop: '1.25rem' }} onClick={this.addProjectRep} type="button"><FormattedMessage id="GeneralButtonAdd" /></Button>
                          </Space>

                          <Space style={{ display: this.state.projectRepUpdateButtonHidden }} direction="vertical">
                            <div></div>
                            <Button style={{ width: '100%', marginTop: '1.25rem' }} variant="warning" onClick={this.updateProjectRep} type="button"><FormattedMessage id="GeneralButtonUpdate" /></Button>
                          </Space>

                          <Space style={{ display: this.state.projectRepUpdateButtonHidden }} direction="vertical">
                            <div></div>
                            <Button style={{ width: '100%', marginTop: '1.25rem' }} variant="secondary" onClick={this.cancelProjectRep} type="button"><FormattedMessage id="GeneralButtonCancel" /></Button>
                          </Space>
                        </Space>
                      </RowAnt>

                      {this.state.pRepList.length > 0 && <Table style={{ marginTop: '1rem', marginBottom: '2rem' }} size={`small`} dataSource={this.state.pRepList} bordered >
                        <Column
                          width='50px'
                          title=""
                          key="action"
                          render={(text, record) => (
                            <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deletePRepModal.bind(this)} onEditRow={this.editPRep.bind(this)} /></React.Fragment>
                          )}
                        />
                        <Column title={<FormattedMessage id="VLName" />} key="pRepName" dataIndex="pRepName" />
                        <Column title={<FormattedMessage id="VLMidname" />} key="pRepMidName" dataIndex="pRepMidName" />
                        <Column title={<FormattedMessage id="VLSurname" />} key="pRepSurname" dataIndex="pRepSurname" />
                        <Column title={<FormattedMessage id="VLPosition" />} key="pRepPosition" dataIndex="pRepPosition" />
                        <Column title={<FormattedMessage id="VLPhone" />} key="pRepPhone" dataIndex="pRepPhone" />
                        <Column title={<FormattedMessage id="VLEMail" />} key="pRepEmail" dataIndex="pRepEmail" />
                      </Table>}
                    </Panel>

                    <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="VLProjectLocations" />} key="2"  >

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                          <FormattedMessage id="VLLocation" />
                        </Form.Label>
                        <Col sm="9">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLLocation1" />
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control value={this.state.locationName} onChange={(e) => this.setState({ locationName: e.target.value })} />
                        </Col>
                        <Col sm="7">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLDistance" />
                        </Form.Label>
                        <Col sm="3">
                          <InputNumber style={{ width: '100%' }} min={1} onChange={(value) => {
                            this.setState({ locationDistance: value })
                          }} value={this.state.locationDistance} />
                        </Col>
                        <Form.Label style={{ fontAlign: 'left', color: 'black' }} column sm="1">
                          km.
                    </Form.Label>
                        <Col sm="6">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                          <FormattedMessage id="VLLocationAddress" />
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control as="textarea" rows="3" name="locationAddress" value={this.state.locationAddress}
                            onChange={this.handleChange} />
                        </Col>
                        <Col sm="7">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                        <Col sm="3">
                        </Col>
                        <Col sm="6">
                          <Button style={{ width: '100%', marginLeft: "5px" }} variant="warning" onClick={this.showOrHideModalLocationRep} type="button"><FormattedMessage id="VLCreateEditLocationRepresentatives" /></Button>
                        </Col>
                        <Col sm="3">
                        </Col>
                      </Form.Group>

                      <div style={{ display: this.state.locationAddButtonHidden, marginTop: '2rem' }} >
                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                          <Col sm="3">
                          </Col>
                          <Col sm="6">
                            <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addLocation} type="button"><FormattedMessage id="VLAddLocation" /></Button>
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>
                      </div>

                      <div style={{ display: this.state.locationUpdateButtonHidden }} >
                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                          <Col sm="3">
                          </Col>
                          <Col sm="2">
                            <Button style={{ width: '100%', marginLeft: "5px" }} variant="secondary" onClick={this.cancelLocation} type="button"><FormattedMessage id="GeneralButtonCancel" /></Button>
                          </Col>
                          <Col sm="4">
                            <Button style={{ width: '100%', marginLeft: "5px" }} variant="warning" onClick={this.updateLocation} type="button"><FormattedMessage id="VLUpdateLocation" /></Button>
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>
                      </div>

                      {this.state.locationList.length > 0 && <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.locationList} bordered >
                        <Column
                          title=""
                          key="action"
                          render={(text, record) => (
                            <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteProjectModal.bind(this)} onEditRow={this.editLocation.bind(this)} />   </React.Fragment>
                          )}
                        />
                        <Column title={<FormattedMessage id="VLLocation" />} key="locationName" dataIndex="locationName" />
                        <Column title={<FormattedMessage id="VLDistance" />} key="locationDistance" dataIndex="locationDistance" />
                        <Column title={<FormattedMessage id="VLLocationAddress" />} key="locationAddress" dataIndex="locationAddress" />
                      </Table>}

                    </Panel>

                  </Collapse>

                  <div style={{ display: this.state.projectAddButtonHidden, marginTop: '2rem' }} >
                    <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                      <Col sm="3">
                      </Col>
                      <Col sm="6">
                        <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addProject} type="button"><FormattedMessage id="VLAddProject" /></Button>
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>
                  </div>

                  <div style={{ display: this.state.projectUpdateButtonHidden }} >
                    <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                      <Col sm="3">
                      </Col>
                      <Col sm="2">
                        <Button style={{ width: '100%', marginLeft: "5px" }} variant="secondary" onClick={this.cancelProject} type="button"><FormattedMessage id="GeneralButtonCancel" /></Button>
                      </Col>
                      <Col sm="4">
                        <Button style={{ width: '100%', marginLeft: "5px" }} variant="warning" onClick={this.updateProject} type="button"><FormattedMessage id="VLUpdateProject" /></Button>
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>
                  </div>

                  {this.state.projectList.length > 0 && <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.projectList} bordered >
                    <Column
                      title=""
                      key="action"
                      render={(text, record) => (
                        <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteProjectModal.bind(this)} onEditRow={this.editProject.bind(this)} />   </React.Fragment>
                      )}
                    />
                    <Column title={<FormattedMessage id="VLProjectName" />} key="projectName" dataIndex="projectName" />
                    <Column title={<FormattedMessage id="VLDistance" />} key="distance" dataIndex="distance" />
                    <Column title={<FormattedMessage id="VLProjectAddress" />} key="address" dataIndex="address" />
                    <Column title={<FormattedMessage id="VLProducts" />} key="pc" render={(record) => (this.getProjectProducts(record))} />
                  </Table>}

                  <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="controlVision">
                    <Col sm="3">
                    </Col>
                    <Col sm="6">
                      <Button type="button" style={{ width: '100%' }} onClick={this.saveCustomer} variant={this.state.lastButtonVariant} >{this.state.lastButton}</Button>
                    </Col>
                    <Col sm="3">
                    </Col>
                  </Form.Group>
                </Form>

              </Container>
            </Card.Body>
          </Card>
        </div>

        {this.state.customerList.length > 0 &&
          <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.customerList}
            bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
            pagination={this.state.pagination}
            responsive striped bordered hover
            loading={this.state.loading} onChange={this.handleTableChange}>
            <Column
              title=""
              key="action"
              render={(text, record) => (
                <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>
              )}
            />
            <Column title={<FormattedMessage id="GeneralTableSN" />} key="sn" dataIndex="index" />
            <Column title={<FormattedMessage id="VLLogo" />} key="logo" render={(value, row, index) => {
              if (row.logoPath != null && row.logoPath !== undefined)
                return <Avatar shape="square" size={64} src={`${API_BASE}/VendorLog/download/` + row.id} />;
              else return "";

            }} />
            <Column title={<FormattedMessage id="VLCustomerCode" />} key="id" dataIndex="id" />
            <Column title={<FormattedMessage id="VLNumberOfProject" />} key="nop" render={(record) => (this.numberOfProjects2(record))} />
            <Column title={<FormattedMessage id="VLFullName" />} key="cfn" dataIndex="fullName" />
            <Column title={<FormattedMessage id="VLShortName" />} key="csn" dataIndex="shortName" />
            <ColumnGroup title={<FormattedMessage id="VLHeadOffice" />} key="ho" >
              <Column width='30%' title={<FormattedMessage id="VLAddress" />} key="address" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.address;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLCountry" />} key="country" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.country;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLPhone" />} key="phones" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.phones;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLFax" />} key="faxes" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.faxes;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLWeb" />} key="web" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.web;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLEMail" />} key="emails" render={(value, row, index) => {
                if (row.contact != null && row.contact !== undefined)
                  return row.contact.mails;

                return '';
              }} />
            </ColumnGroup>
            <ColumnGroup title={<FormattedMessage id="VLVendorRepresentative" />} key="cr" >
              <Column title={<FormattedMessage id="VLName" />} key="name" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.name;

                return '';
              }} />

              <Column title={<FormattedMessage id="VLMidname" />} key="midname" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.midname;

                return '';
              }} />

              <Column title={<FormattedMessage id="VLSurname" />} key="surname" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.lastname;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLPosition" />} key="position" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.position;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLPhone" />} key="rphone" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.phone;

                return '';
              }} />
              <Column title={<FormattedMessage id="VLEMail" />} key="remail" render={(value, row, index) => {
                if (row.representative != null && row.representative !== undefined)
                  return row.representative.email;

                return '';
              }} />
            </ColumnGroup>

          </Table>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(VendorLog)
