/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Modal, Row, Container } from 'react-bootstrap'; 
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column'; 
import {MultiSelect} from 'primereact/components/multiselect/MultiSelect'; 
import {InputText} from 'primereact/components/inputtext/InputText';
import {ProgressBar} from 'primereact/components/progressbar/ProgressBar'; 
import {Calendar} from 'primereact/components/calendar/Calendar';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
import "./css/DataTable.scss"
import {Dropdown} from 'primereact/dropdown';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';



function convertdate(d) {
    if (d != null) {
      var dt = new Date(d);
      return (''
          + dt.getDate().toString().padStart(2, '0') + '/'
          + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
          + dt.getFullYear().toString().padStart(4, '0') + ' '
          + dt.getHours().toString().padStart(2, '0') + ':'
          + dt.getMinutes().toString().padStart(2, '0') + ':'
          + dt.getSeconds().toString().padStart(2, '0'));
  } else return '';
  } 

  function setDateFormat(d) { //01/02/2011 ->2011-02-01
    if(d!=null)
    {var dt = new Date(d);
    return ('' +dt.getFullYear().toString().padStart(4, '0')+'-'  
     +(dt.getMonth()+1).toString().padStart(2, '0') +'-'
      +dt.getDate().toString().padStart(2, '0'));/* + ' '
      + dt.getHours().toString().padStart(2, '0') + ':'
      + dt.getMinutes().toString().padStart(2, '0') + ':'
      + dt.getSeconds().toString().padStart(2, '0'));*/
    }else return'';
    }
export default class InspectionRequest extends Component {

    constructor() {
        super();
        this.state = {
            customers: null,
            selectedRowVal: null,
            globalFilter: null,
            selectedKolon1: null,
            selectedKolon2: null,
            kolon1DropValue: [], 
            kolon2DropValue: [], 
            dateFilter: null,
            selectedStatus: null,
            datatableValue:[],
            modalShow:false,
            InspectionDate:null,
            DATA:[],

            disciplineVal:null,
            disciplinesOfDepartments:[],
            Staffs: [],
            staffVal:null,

            smShow: false ,messagesHeader:"",messages:"",
            
        };

        this.kolon1BodyTemplate = this.kolon1BodyTemplate.bind(this);
        this.kolon1ItemTemplate = this.kolon1ItemTemplate.bind(this);
        this.onKolon1FilterChange = this.onKolon1FilterChange.bind(this);

        this.onKolon1FilterChange2 = this.onKolon1FilterChange2.bind(this);
        this.onKolon2FilterChange2 = this.onKolon2FilterChange2.bind(this);


        this.kolon2BodyTemplate = this.kolon2BodyTemplate.bind(this);
        this.kolon2ItemTemplate = this.kolon2ItemTemplate.bind(this);
        this.onKolon2FilterChange = this.onKolon2FilterChange.bind(this);

        this.onselectChangeVal = this.onselectChangeVal.bind(this);


        this.onChangedisciplineVal = this.onChangedisciplineVal.bind(this);


          }

    componentDidMount() {
      
        this.getLocation();
        this.getActivity();
        this.getCheckListLog();
        this.getDsicipline();
        this.getStaff();
       
   
    }
 

    getDsicipline= async () => { 
    
        const response = await handleRequest("GET", "/Discipline/getAll");    
            console.log(response)
          if (response.data.length !== 0)
          { 
            var rows = []; 
            for (var i = 0; i < response.data.length; i++) {
    
                  rows.push({label:response.data[i].disciplineName,value:response.data[i].id}); 
            }
       
            this.setState({ disciplinesOfDepartments:rows }); 
          }  else { this.setState({ disciplinesOfDepartments: []  });}
        } 

        
    getCheckListLog= async () => { 
    
        const response = await handleRequest("GET", "/CheckListLog/getAll");    
         console.log('datatableValuexxx',response)
         if (response.data.length !== 0)
          {  
            this.setState({ datatableValue:response.data,DATA:response.data }); 
          }  else { this.setState({ datatableValue: [],DATA: []  });} 
      }
    
    getLocation = async () => {
        
      
        const response = await handleRequest("GET", "/Location/getAll");    

        if (response.data.length === 0){
            this.setState({
                kolon1DropValue: [],
            });
        }

        if (response.data.length !== 0){

            var rows = []; 
                for (var i = 0; i < response.data.length; i++) {
                    rows.push({name:response.data[i].description,value:response.data[i].id}); 
                }
            this.setState({
                kolon1DropValue: rows,
            });
        }
    }

    getActivity = async () => {
        
      
        const response = await handleRequest("GET", "/Activity/getAll");    

        if (response.data.length === 0){
            this.setState({
                kolon2DropValue: [],
            });
        }

        if (response.data.length !== 0){

            var rows = [];
                for (var i = 0; i < response.data.length; i++) {
                    rows.push({name:response.data[i].description,value:response.data[i].id});
                }
            this.setState({
                kolon2DropValue: rows,
            });
        }
    }
   
    getStaff =  async () => {
    const response = await handleRequest("GET", "/Staff/getAll");

    if (response.data.length === 0) {
        this.setState({
            Staffs: []
        });
    }

    if (response.data.length>0) {


        const options = response.data.map(function (row) {
            return { name: row.name.toString() + " " + row.midName + " " + row.lastName, id: row.id.toString() }
        })

        this.setState({
            Staffs: options
        });

    }
}
    renderHeader() {
        return (
            <div>
                <div  className="p-datatable-globalfilter-container">
                    <InputText type="search" onChange={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" />
                </div>
            </div>
        );
    }


    onChangedisciplineVal= e => {
        console.log(e.target.value);
        const{DATA}=this.state;

        this.setState({ disciplineVal: e.target.value });
        if(e.target.value!=null )
        {
            var dt= DATA.filter(x=>x.itp.disciplineid== e.target.value);

            this.setState({ datatableValue: dt });
        }
        else {
            this.setState({ datatableValue: DATA });
        }
    }


    kolon1BodyTemplate(rowData) { 
        return (
            <> 
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.Location}</span>
            </>
        );
    }
    kolon2BodyTemplate(rowData) { 
        return (
            <> 
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.Activity}</span>
            </>
        );
    }

    renderkolonFilter1() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedKolon1} options={this.state.kolon1DropValue}
                onChange={this.onKolon1FilterChange} itemTemplate={this.kolon1ItemTemplate} placeholder="All" optionLabel="name" optionValue="name" />
        );
    }
    renderkolonFilter2() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedKolon2} options={this.state.kolon2DropValue}
                onChange={this.onKolon2FilterChange} itemTemplate={this.kolon2ItemTemplate} placeholder="All" optionLabel="name" optionValue="name" />
        );
    }

    kolon1ItemTemplate(option) { 

        return (
            <div className="p-multiselect-representative-option"> 
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{option.name}</span>
            </div>
        );
    }
    kolon2ItemTemplate(option) { 

        return (
            <div className="p-multiselect-representative-option"> 
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{option.name}</span>
            </div>
        );
    } 

    onKolon1FilterChange(event) {

        console.log(event)
        this.dt.filter(event.value ,'Location', 'in')
        this.setState({selectedKolon1: event.value});
    }

    onKolon1FilterChange2(e) {

        var a=[e.target.value]

        if(a=="0")a=[];

        this.dt.filter(a ,'Location', 'in')

    }

    onKolon2FilterChange(event) {

        console.log(event)
        this.dt.filter(event.value ,'Activity', 'in')
        this.setState({selectedKolon2: event.value});
    }

    onKolon2FilterChange2(e) {

        var a=[e.target.value]

        if(a=="0")a=[];

        this.dt.filter(a ,'Activity', 'in')

    } 
   onselectChangeVal(event) {

    if(event.value!=null)
     {
         console.log(event) 
         this.setState({selectedRowVal: event.value})
         this.setState({modalShow: true})
    } else{
        
        this.setState({selectedRowVal:null})
    }

    }

    handleStaffChange = e => {  

     this.setState({ staffVal: e.target.value  });
     console.log(this.state.staffVal)

    };
   
  
    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow 
        });
    };

    saveModal= async() => {

        const { selectedRowVal} =this.state;
        const { staffVal} =this.state;
        const { InspectionDate} =this.state;
        selectedRowVal.consRep=staffVal;
        selectedRowVal.consRepDate=setDateFormat( InspectionDate);

        console.log(selectedRowVal)
       // const prm = {id: selectedRowVal.id, consRep: staffVal,consRepDate:setDateFormat( InspectionDate) };
       
        const response = await handleRequest("POST", "/inspectionRequest/update", selectedRowVal );
        
        if(response.data.includes('Successfull'))
        { 
          this.setState({ smShow: true ,messagesHeader:"Successfull",messages:response.data});
          this.setState({ modalShow: !this.state.modalShow  });
        } 
    };

    renderITP(rowData, column) { 
        return rowData.itp.itpcode + ", " + rowData.itp.itpname;
    };
  
    renderChecklist(rowData, column) { 
        return rowData.iclistno + ", " + rowData.iclist;
    };

    actionTemplate(rowData, column) {
        return <React.Fragment> 
                <CustomMaterialMenu row={rowData} 
                // onDeleteRow={this.deleteDocumentModal.bind(this)} 
                // onEditRow={this.changeButtonUpdate.bind(this)}
                />   
            </React.Fragment>; 
        return ''; 
    };

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;
 
        let locationOpt = this.state.kolon1DropValue.map((data) =>  <option key={data.name} value={data.name} >  {data.name} </option>   );
        let activityOpt = this.state.kolon2DropValue.map((data) =>  <option key={data.name} value={data.name} >  {data.name} </option>   );
        let optionsStaffs = this.state.Staffs.map((data) =>  <option  key={data.id} value={data.id}  > {data.name}  </option> );
        let smClose = () => this.setState({ smShow: false });
        const header = this.renderHeader();
        const kolonFilter1 = this.renderkolonFilter1();
        const kolonFilter2 = this.renderkolonFilter2();
        return (
            <div>

            <Modal
            size="sm"
            show={this.state.smShow}
            onHide={smClose}
            aria-labelledby="example-modal-sizes-title-sm"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                {this.state.messagesHeader}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.messages}</Modal.Body>
            </Modal>
            <div style={{backgroundColor:'white'}}>

                <div className="col-md-12 " style={{ paddingBottom: '20px',overflow:'auto' }} > 
                <div className="datatable-doc-demo">
                <div style={{ marginTop: '40px' }} className="content-section implementation">
                <Toast ref={(el) => this.toast = el} />
             
                <DataTable ref={(el) => this.dt = el} value={this.state.datatableValue}   style={{minWidth:'1000px'}} virtualScroll={true}
                        header={header}  className="p-datatable-customers" dataKey="id" rowHover globalFilter={this.state.globalFilter}
                        selection={this.state.selectedRowVal} onSelectionChange={this.onselectChangeVal}
                        paginator rows={10} emptyMessage="No customers found" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
                    <Column selectionMode="multiple" style={{width:'3em'}}/>
                    <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                    <Column field="index" style={{ textAlign: 'center' }} sortable filter header="S/N" />
                    <Column sortField="Location"  filterField="Location" body={this.kolon1BodyTemplate} sortable filter filterElement={kolonFilter1}  header="Location" />
                    <Column field="itp.dis.disciplineName" style={{ textAlign: 'center' }} filter sortable header="Discipline" />
                    <Column sortField="Activity"  filterField="Activity" body={this.kolon2BodyTemplate}  sortable filter filterElement={kolonFilter2}  header="Activity" />
                    <Column body={this.renderITP} style={{ textAlign: 'center' }} filter sortable header="ITP" />
                    <Column body={this.renderChecklist} style={{ textAlign: 'center' }} filter sortable header="Checklist" />
                    <Column field="ChecklistItems" style={{ textAlign: 'center' }} filter sortable header="Checklist Items" />
                    <Column body={''} style={{ textAlign: 'center' }} filter sortable header="Construction Start Date" />
                    <Column body={''} style={{ textAlign: 'center' }} filter sortable header="Internal Inspection Date" />
                    <Column body={''} style={{ textAlign: 'center' }} filter sortable header="External Inspection Date" />
                    <Column body={''} style={{ textAlign: 'center' }} filter sortable header="Status" />
                 </DataTable>
               
                   <Modal show={this.state.modalShow}
                                onHide={this.showOrHideModal} 
                                aria-labelledby="contained-modal-title-vcenter"
                                centered  >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Select Person And Datetime  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                          
                                             <div className="col-md-12"> 
                                                <label className=" control-label">Construction Representative</label>
                                                <div className="p-grid p-fluid">  
                                                    <Form.Control name="staffName" value={this.state.staffVal}
                                                        onChange={this.handleStaffChange} as="select">
                                                        <option value="">
                                                            Select Personnel
                                                    </option>
                                                        {optionsStaffs}
                                                    </Form.Control> 
                                              </div>
                                              </div>
                                              <div className="col-md-12 " style={{marginTop:'10px'}}> 
                                              <label className=" control-label">Inspection Date</label>
                                              <div className="p-grid p-fluid">
                                                   <Calendar   value={this.state.InspectionDate} onChange={(e) => this.setState({InspectionDate: e.value})} showTime={true} showSeconds={true}  />
                                                  </div>        
                                             </div>                              
                                        </Form.Group> 
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                   <Button onClick={this.showOrHideModal} label="Close" className="p-button-secondary" /> &nbsp;&nbsp;&nbsp;
                                   <Button onClick={this.saveModal} label="Save" className="p-button-success" /> 
                                </Modal.Footer>
                            </Modal> 
                 </div>
                 </div>
                </div>
             

                </div>
            </div>
        )
    }
}
/* style={{color:'#333333' , backgroundColor: '#f4f4f4'}} */