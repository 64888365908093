import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";
import { MultiSelect } from 'primereact/multiselect';
import MaintenanceSubLog from './MaintenanceSubLog';
import { handleRequest } from '../../redux/config/env';
import { Cascader } from 'antd';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "./css/ShipmentLog.scss";
import 'antd/dist/antd.css';

class MaintenanceLog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedDepartments: null,
            selectedDisciplines: null,
            disciplinesForFilter: [],
            departmentsForFilter: [],
            equipmentLogs: [],
            expandedRows: null,
            nodes: [],
            values: [],
            selectedItems: [],
            selectedEquipment: null
        };

        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.onDepartmentFilterChange = this.onDepartmentFilterChange.bind(this);
        this.onDisciplineFilterChange = this.onDisciplineFilterChange.bind(this);
        this.onEquipmentTypeFilterChange = this.onEquipmentTypeFilterChange.bind(this);
    }

    componentDidMount = async () => {
        const response4 = await handleRequest("GET", "/EquipmentMaintenance/getAll");
        console.log("RRR",response4.data);
        if (response4.data.length === 0) {
            this.setState({
                equipmentLogs: [],
            });
        }

        if (response4.data.length !== 0) {
            this.setState({
                equipmentLogs: response4.data,
            });
        }

        const response = await handleRequest("GET", "/Department/getAll");

        if (response.data.length === 0) {
            this.setState({
                departments: []
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() }
            })

            this.setState({
                departmentsForFilter: options,
            });
        }

        const response2 = await handleRequest("GET", "/Discipline/getAll");

        if (response2.data.length !== 0) {

            const columns2 = response2.data;

            const options2 = columns2.map(function (row) {
                return { name: row.disciplineName.toString(), id: row.id.toString() }
            })

            this.setState({
                disciplinesForFilter: options2,
            });
        }

        const response3 = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response3.length === 0 || response3.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data
            });
        }
    }

    rowExpansionTemplate(data) {
        return (
            <MaintenanceSubLog row={data} />
        );
    }

    renderEquipmentTypeFilter() {
        return (
            <Cascader options={this.state.nodes} onChange={this.onEquipmentTypeFilterChange} changeOnSelect />
        );
    }

    onEquipmentTypeFilterChange(value, data) {
        //-------------------------------------------------------
        //ÇALIŞAN KISIM -- Seçili tüm equipmentTypeName'lere göre filtre yapar.
        var flagNamesArray = data.map(o => o.label);
        if (flagNamesArray.length !== 0) {
            this.dt.filter(flagNamesArray, 'equipmentTypeName', 'in');
        } else {
            this.dt.filter('','equipmentTypeName', 'in');
        }
        //-------------------------------------------------------
    }

    renderDepartmentFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDepartments} options={this.state.departmentsForFilter}
                onChange={this.onDepartmentFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDepartmentFilterChange(event) {
        this.dt.filter(event.value, 'department.departmentName', 'in');
        this.setState({ selectedDepartments: event.value });
    }

    renderDisciplineFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDisciplines} options={this.state.disciplinesForFilter}
                onChange={this.onDisciplineFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDisciplineFilterChange(event) {
        this.dt.filter(event.value, 'discipline.disciplineName', 'in');
        this.setState({ selectedDisciplines: event.value });
    }

    onChange = (value, data) => {
        this.setState({
            values: value,
            selectedItems: data
        })
    }

    render() {

        const equipmentTypeFilter = this.renderEquipmentTypeFilter();
        const departmentFilter = this.renderDepartmentFilter();

        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>

                <div className="datatable-doc-demo">
                    <Card>
                        <div className="content-section implementation">
                            <DataTable ref={(el) => this.dt = el} responsive={true} value={this.state.equipmentLogs} paginator={true}
                                emptyMessage="No Maintenance Logs Found" rowsPerPageOptions={[5, 10, 20]} rows={10} expandedRows={this.state.expandedRows}
                                onRowToggle={(e) => this.setState({ expandedRows: e.data })} rowExpansionTemplate={this.rowExpansionTemplate} selectionMode="single"
                                selection={this.state.selectedEquipment} onSelectionChange={e => this.setState({ selectedEquipment: e.value })}  style={{ textAlign: 'center' }}>
                                <Column expander={true} style={{ textAlign: 'center', width: '4em' }} />
                                {/*<Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '5em' }} />*/}
                                <Column field="index" header="S/N" style={{ textAlign: 'center', width: '5em' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentLog.equipmentTypeName" header="Equipment Type" sortable={true} filter={true} filterElement={equipmentTypeFilter} />
                                <Column field="equipmentLog.equipmentDescription" header="E. Description" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentLog.equipmentCode" header="Equipment Code" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentLog.department.departmentName" header="Department" sortable filter filterElement={departmentFilter} />
                                 
                                <Column header="Coming Maintenance Date" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column header="Maintenance Type" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column header="Duration of Maintenance" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column header="Maintenance Location" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column header="Past Maintenances Status" sortable={true} filter={true} filterMatchMode="contains" />
                            </DataTable>
                        </div>
                    </Card>
                </div>

            </div>
        )
    }
};

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLog)