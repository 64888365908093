import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Col, Form, Row, Accordion, Card } from 'react-bootstrap';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Upload, message, Button as AntButton, Table, Radio, Space, Input, DatePicker } from 'antd';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import { UploadOutlined } from '@ant-design/icons';
import Loader from 'react-loader-spinner';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import {
    FacebookShareButton, WhatsappShareButton, EmailShareButton,
    FacebookIcon, WhatsappIcon, EmailIcon
} from "react-share";
import moment from 'moment';
import $ from 'jquery';

const { Column } = Table;
const { TextArea } = Input;

const renderToolbar = (toolbarSlot) => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                }}
            >
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                </div>
            </div>
        </div>
    );
};

const layout = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar,
) => {
    return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
    );
};

export class ComplaintsRespond extends Component {

    constructor(props) {
        super(props)
        this.state = {
            date: null,
            isGetting: true,
            complaintList: [],
            createNewButton: "Respond",
            createNewButtonVariant: "info",
            lastButton: "Send",
            lastButtonVariant: "success",
            hidden: true,
            hiddenPlan: false,
            attachedFile: null,
            complaintPhoto: null,
            complaintId: null,
            pdfFile: "",
            modalPdfViewer: false,
            modalPhotoShow: false,
            imageUrl: "",
            modalErrorRespond: false,
            selectedRow: null,
            admittedOrNot: "Admitted",
            justification: "",
            actionList: [],
            actionId: null,
            actionAddButtonHidden: "block",
            actionUpdateButtonHidden: "none",
            modalDeleteAction: false,
            modalError: false
        };
    }

    componentDidMount = async () => {

        this.setState({
            isGetting: true
        });

        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            date: today
        });

        const response = await handleRequest("GET", "/Complaint/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                complaintList: []
            });
        }

        if (response.data.length !== 0) {

            var rData = response.data;

            rData.forEach(element => {
                element.key = element.id;
            });

            this.setState({
                complaintList: rData
            });
        }

        console.log(response.data);

        const responseCode = await handleRequest("GET", "/Code/getAll");

        if (responseCode.data.length !== 0) {

            const columns = responseCode.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "complaintGroup") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                complaintGroups: options
            });
        }

        this.setState({
            isGetting: false
        });
    }

    respond = () => {

        console.log(this.state.selectedRow);

        if (this.state.selectedRow !== null) {

            if (this.state.createNewButton === "Respond") {
                this.setState({
                    hidden: false,
                    createNewButton: "Stop Responding",
                    createNewButtonVariant: "danger",
                    lastButton: "Send",
                    lastButtonVariant: "success"
                });
            } else {
                this.resetInputs();
            }
        } else {
            this.setState({
                modalErrorRespond: true
            });
        }
    }

    resetInputs = () => {
        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            hidden: true,
            createNewButton: "Respond",
            createNewButtonVariant: "info",
            lastButton: "Send",
            lastButtonVariant: "success",
            attachedFile: "",
            complaintPhoto: "",
            justification: "",
            admittedOrNot: "Admitted",
            actionList: [],
            action: "",
            actionId: null,
            date: today
        });

        setTimeout(() => {
            $(".ant-upload-list").empty();
        }, 200);
    }

    restartTable = async () => {
        this.setState({
            isGetting: true
        });

        const response = await handleRequest("GET", "/Complaint/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                complaintList: []
            });
        }

        if (response.data.length !== 0) {
            var rData = response.data;

            rData.forEach(element => {
                element.key = element.id;
            });

            this.setState({
                complaintList: rData
            });
        }

        this.setState({
            isGetting: false
        });
    }

    respondComplaint = async () => {
        if (this.state.lastButton === "Send" && this.state.selectedRow !== null) {

            var dbActionList = [];

            for (let index = 0; index < this.state.actionList.length; index++) {
                const newItem = {
                    complaintId: this.state.selectedRow[0].id,
                    action: this.state.actionList[index].action,
                    deadline: this.state.actionList[index].deadline
                }

                dbActionList.push(newItem);
            }

            const respondedComplaint = {
                id: this.state.selectedRow[0].id,
                complaintNo: this.state.selectedRow[0].complaintNo,
                complaintGroup: this.state.selectedRow[0].complaintGroupTypeCode.name,
                titleOfComplaint: this.state.selectedRow[0].titleOfComplaint,
                message: this.state.selectedRow[0].message,
                attachedFilePath: this.state.selectedRow[0].attachedFilePath,
                complaintPhotoPath: this.state.selectedRow[0].complaintPhotoPath,

                admittedOrNot: this.state.admittedOrNot,
                justification: this.state.justification,
                justAttachedFilePath: this.state.attachedFile,
                justComplaintPhotoPath: this.state.complaintPhoto,
                complaintActionList: dbActionList,
                respondTime: Date.now(),

                status: "Under Evaluation",
                userId: this.state.selectedRow[0].user.id,
                customerId: this.state.selectedRow[0].customerLog.id,
                customerProjectId: this.state.selectedRow[0].customerProject.id
            }

            await handleRequest("POST", "/Complaint/update", respondedComplaint);

            message.success(`Complaint has been responded successfully.`);
            this.restartTable();
            this.resetInputs();
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    beforeUpload = (file) => {
        const isPDF = file.type === 'application/pdf';

        if (!isPDF) {
            message.error('You can only upload PDF file!');
            console.log("OK1");

            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt4M = file.size / 1024 / 1024 < 4;
        if (!isLt4M) {
            message.error('File must smaller than 4MB!');
            console.log("OK2");

            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isPDF && isLt4M;
    }

    handleAttachChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    attachedFile: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    beforeUpload1 = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');

            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isJpgOrPng && isLt2M;
    }

    transformFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const canvas = document.createElement('canvas');
                const img = document.createElement('img');
                img.src = reader.result;
                img.onload = () => {
                    let width = img.width
                    let height = img.height
                    canvas.width = width
                    canvas.height = height

                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    canvas.toBlob(resolve, 'image/jpeg', 0.5);
                };
            }
        });
    }

    handlePhotoChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            // console.log(info.file);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    complaintPhoto: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    showAttachedFile = async (rowData) => {
        try {
            const fileRealUrl = `${API_BASE}/Complaint/download1/` + rowData.id;

            console.log("fileUrl: " + fileRealUrl);

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            console.log(error);
            message.error(`File can't found.`);
        }
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
    }

    printPdf = () => {
        window.open(this.state.pdfFile);
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalErrorRespond = () => {
        this.setState({
            modalErrorRespond: !this.state.modalErrorRespond,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onChangeRadioButton = e => {
        this.setState({
            admittedOrNot: e.target.value,
        });

        if (e.target.value === "Admitted") {
            this.setState({
                hiddenPlan: false
            });
        } else {
            this.setState({
                hiddenPlan: true
            });
        }
    }

    onChangeJustification = e => {
        this.setState({
            justification: e.target.value,
        });
    }

    onChangeAction = e => {
        this.setState({
            action: e.target.value,
        });
    }

    disabledDate(current) {
        return current < Date.now();
    }

    onChangeDate = (value, dateString) => {
        if (dateString === "") {
            var today = new Date();
            var HH = String(today.getHours()).padStart(2, '0');
            var minute = String(today.getMinutes()).padStart(2, '0');
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

            this.setState({
                date: today
            });
        }
        else {
            this.setState({
                date: dateString
            });
        }
    }

    addAction = () => {
        var list = this.state.actionList;

        if (this.state.action !== "") {

            var _key = list.length + 1;
            var dbDate = moment(this.state.date, 'DD/MM/YYYY HH:mm');

            const newItem = {
                key: _key,
                action: this.state.action,
                deadline: dbDate._d
            }

            list.push(newItem);

            var today = new Date();
            var HH = String(today.getHours()).padStart(2, '0');
            var minute = String(today.getMinutes()).padStart(2, '0');
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

            this.setState({
                actionList: list.slice(),
                action: "",
                date: today,
            });
        } else {
            this.setState({
                modalError: true
            });
        }
    }

    updateAction = () => {
        let list = this.state.actionList;
        let flagActionId = this.state.actionId;

        let objIndex = list.findIndex((obj => obj.key === flagActionId));

        var dbDate = moment(this.state.date, 'DD/MM/YYYY HH:mm');

        list[objIndex].action = this.state.action;
        list[objIndex].deadline = dbDate;

        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            actionList: list.slice(),
            action: "",
            date: today,
            actionUpdateButtonHidden: "none",
            actionAddButtonHidden: "block"
        });
    }

    cancelAction = () => {
        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            actionId: null,
            action: "",
            date: today,
            actionUpdateButtonHidden: "none",
            actionAddButtonHidden: "block"
        });
    }

    deleteActionModal = (row) => {
        this.setState({
            actionId: row.key,
            modalDeleteAction: true
        });
    }

    deleteActionModalOK = () => {
        let list = this.state.actionList;
        let flagActionId = this.state.actionId;

        list.splice(list.findIndex(function (i) {
            return i.key === flagActionId;
        }), 1);

        this.setState({
            actionList: list.slice(),
            actionId: null,
            modalDeleteAction: false,
            actionUpdateButtonHidden: "none",
            actionAddButtonHidden: "block"
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    editAction = (row) => {
        this.setState({
            actionId: row.key,
            action: row.action,
            date: row.deadline,
            actionUpdateButtonHidden: "block",
            actionAddButtonHidden: "none"
        });
    }

    showOrHideModalError = () => {
        this.setState({
            modalError: !this.state.modalError
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalDeleteAction = () => {
        this.setState({
            modalDeleteAction: !this.state.modalDeleteAction
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRow: selectedRows
                });
            },
        };

        return (
            <div>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                            <Button style={{ marginLeft: "1rem" }}
                                onClick={this.printPdf}
                            >
                                Print
                            </Button>
                            <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Complaint PDF File Link">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Complaint PDF File Link is here: " subject="Complaint PDF File Link">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <WhatsappShareButton
                                style={{ marginLeft: "1rem" }}
                                url={this.state.pdfFile}
                                title="Complaint PDF File Link"
                                separator=": "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layout} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.imageUrl !== "" ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                            />
                        ) : (
                                <p>Photo has not been uploaded.</p>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHidePhotoModal}>
                            Close
            </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalErrorRespond}
                    onHide={this.showOrHideModalErrorRespond}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Select a Complaint!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please, be sure to select a complaint.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalErrorRespond}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalError}
                    onHide={this.showOrHideModalError}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Missing Values!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please, be sure to enter all values.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalError}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDeleteAction}
                    onHide={this.showOrHideModalDeleteAction}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Action Record?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDeleteAction}>Close</Button>
                        <Button variant="danger" onClick={this.deleteActionModalOK} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                {this.state.isGetting ? <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div> :
                    <div>
                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}><Button onClick={this.respond} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="4">
                                </Col>
                                <Col sm="4">
                                    <Radio.Group size="large" onChange={this.onChangeRadioButton} defaultValue="Admitted" buttonStyle="solid">
                                        <Space size="large">
                                            <Radio.Button value="Admitted">Admitted</Radio.Button>
                                            <Radio.Button value="Not Admitted">Not Admitted</Radio.Button>
                                        </Space>
                                    </Radio.Group>
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="2">
                                    Justification:
                                </Form.Label>
                                <Col sm="3">
                                    <TextArea
                                        value={this.state.justification}
                                        onChange={this.onChangeJustification}
                                        placeholder="Write complaint's justification"
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="2">
                                    Attach:
                            </Form.Label>
                                <Col sm="3">
                                    <Upload
                                        name="avatar"
                                        className="avatar-uploader"
                                        action={`${API_BASE}/Complaint/upload2`}
                                        beforeUpload={this.beforeUpload}
                                        onChange={this.handleAttachChange}
                                        style={{ width: '100%' }}
                                    >
                                        <AntButton>
                                            <UploadOutlined /> Upload File
                                    </AntButton>
                                    </Upload>
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="2">
                                    Photo:
                            </Form.Label>
                                <Col sm="3">
                                    <Upload
                                        name="avatar"
                                        className="avatar-uploader"
                                        action={`${API_BASE}/Complaint/upload3`}
                                        beforeUpload={this.beforeUpload1}
                                        transformFile={this.transformFile}
                                        onChange={this.handlePhotoChange}
                                        style={{ width: '100%' }}
                                    >
                                        <AntButton>
                                            <UploadOutlined /> Upload Photo
                                    </AntButton>
                                    </Upload>
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Form.Group>

                            <div hidden={this.state.hiddenPlan} style={{ marginTop: '1rem' }}>

                                <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="5">
                                        <Accordion defaultActiveKey="1">
                                            <Card>
                                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">&#x25bc; Plan for Action</Card.Header>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                                    <Card.Body>
                                                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="4">
                                                                Action
                                                            </Form.Label>
                                                            <Col sm="8">
                                                                <Input placeholder="Write action" value={this.state.action} onChange={this.onChangeAction} />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="4">
                                                                Deadline
                                                            </Form.Label>
                                                            <Col sm="8">
                                                                <DatePicker disabledDate={this.disabledDate} value={moment(this.state.date, 'DD/MM/YYYY HH:mm')}
                                                                    showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDate} />
                                                            </Col>
                                                        </Form.Group>

                                                        <div style={{ display: this.state.actionAddButtonHidden }}>
                                                            <Form.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row} >
                                                                <Col sm="2">
                                                                </Col>
                                                                <Col sm="8">
                                                                    <Button type="button" style={{ width: '100%' }} onClick={this.addAction} variant="info">Add</Button>
                                                                </Col>
                                                                <Col sm="2">
                                                                </Col>
                                                            </Form.Group>
                                                        </div>

                                                        <div style={{ display: this.state.actionUpdateButtonHidden }}>
                                                            <Form.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row} >
                                                                <Col sm="2">
                                                                </Col>
                                                                <Col sm="3">
                                                                    <Button type="button" style={{ width: '100%' }} onClick={this.cancelAction} variant="secondary">Cancel</Button>
                                                                </Col>
                                                                <Col sm="1">
                                                                </Col>
                                                                <Col sm="4">
                                                                    <Button type="button" style={{ width: '100%' }} onClick={this.updateAction} variant="warning">Update</Button>
                                                                </Col>
                                                                <Col sm="2">
                                                                </Col>
                                                            </Form.Group>
                                                        </div>

                                                        <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.actionList} bordered >
                                                            <Column
                                                                width='50px'
                                                                title=""
                                                                key="action"
                                                                render={(text, record) => (
                                                                    <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteActionModal.bind(this)} onEditRow={this.editAction.bind(this)} />   </React.Fragment>
                                                                )}
                                                            />
                                                            <Column title="S/N" key="sn" dataIndex="key" />
                                                            <Column title="Action" key="action" dataIndex="action" />
                                                            <Column title="Deadline" key="deadline" render={(text, record) => {
                                                                var myDate = new Date(record.deadline);
                                                                return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear() + ' ' + String(myDate.getHours()).padStart(2, "0") + ':' + String(myDate.getMinutes()).padStart(2, "0");

                                                            }} />
                                                        </Table>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                    </Col>
                                    <Col sm="4">
                                    </Col>
                                </Form.Group>
                            </div>


                            <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                                <Col sm="3">
                                </Col>
                                <Col sm="5">
                                    <Button type="button" style={{ width: '100%' }} onClick={this.respondComplaint} variant={this.state.lastButtonVariant}>{this.state.lastButton}</Button>
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Form.Group>
                        </div>

                        <Table
                            dataSource={this.state.complaintList}
                            style={{ marginTop: "2rem" }} bordered
                            scroll={{ x: 1300, scrollToFirstRowOnChange: true }}
                            rowSelection={{
                                type: "radio",
                                ...rowSelection,
                            }}
                        >
                            <Column title="S/N" key="sn" dataIndex="index" />
                            <Column title="Date" key="date" render={(text, record) => {
                                var myDate = new Date(record.time);
                                return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear();

                            }} />
                            <Column title="Company" key="company" render={(text, record) => {
                                return record.customerLog.fullName;
                            }} />
                            <Column title="Project" key="project" render={(text, record) => {
                                return record.customerProject.projectName;
                            }} />
                            <Column title="Complaint No" key="complaintNo" dataIndex="complaintNo" />
                            <Column title="Complaint By" key="complaintBy" render={(text, record) => {
                                return record.user.username;
                            }} />
                            <Column title="Group" key="group" render={(text, record) => {
                                return record.complaintGroupTypeCode.name;
                            }} />
                            <Column title="Complaint Title" key="complaintTitle" dataIndex="titleOfComplaint" />
                            <Column title="Message" key="message" dataIndex="message" />
                            <Column title="Attachment" key="attachment" width='140px' render={(text, record) => {
                                if (record.attachedFilePath !== null && record.attachedFilePath !== ""
                                    && record.complaintPhotoPath !== null && record.complaintPhotoPath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                    onClick={(e) => this.showAttachedFile(record)} column sm="6">
                                                    File
                                                </Form.Label>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Complaint/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    } column sm="6">
                                                    Photo
                                                </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                } else if (record.attachedFilePath !== null && record.attachedFilePath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                    onClick={(e) => this.showAttachedFile(record)} column sm="12">
                                                    File
                                                </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                } else if (record.complaintPhotoPath !== null && record.complaintPhotoPath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Complaint/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    } column sm="12">
                                                    Photo
                                            </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                }
                                else
                                    return "";
                            }} />
                            {/* Bu zaten respond içinde olmalı...
                                <Column title="Respond Attachment" key="respondAttachment" width='140px' render={(text, record) => {
                                if (record.justattachedFilePath !== null && record.justattachedFilePath !== ""
                                    && record.justComplaintPhotoPath !== null && record.justComplaintPhotoPath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                    onClick={(e) => this.showAttachedFile(record)} column sm="6">
                                                    File
                                                </Form.Label>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Complaint/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    } column sm="6">
                                                    Photo
                                                </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                } else if (record.justattachedFilePath !== null && record.justattachedFilePath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                    onClick={(e) => this.showAttachedFile(record)} column sm="12">
                                                    File
                                                </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                } else if (record.justComplaintPhotoPath !== null && record.justComplaintPhotoPath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Complaint/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    } column sm="12">
                                                    Photo
                                            </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                }
                                else
                                    return "";
                            }} /> */}
                            <Column title="To" key="to" />
                            <Column title="Respond Date" key="respondTime" render={(text, record) => {
                                if (record.respondTime !== null) {
                                    var myDate = new Date(record.respondTime);
                                    return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear() + ' ' + String(myDate.getHours()).padStart(2, "0") + ':' + String(myDate.getMinutes()).padStart(2, "0");
                                } else return "";
                            }} />
                            <Column title="Respond" key="respond" dataIndex="respond" />
                            <Column title="Status" key="status" dataIndex="status" />
                        </Table>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsRespond)