import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Column as PrimeColumn } from 'primereact/components/column/Column';
//import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import { handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { TreeTable } from 'primereact/treetable';
import { Input, Form as FormAnt, Cascader, Table, Checkbox, Select } from 'antd';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuRevision';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import moment from 'moment';
import $ from 'jquery';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';

const { Column } = Table;
const pageName = "Department2";
const { Option } = Select;

function getParentsHelper(tree, id, parents) {

    if (tree.value == id) {
        return {
            found: true,
            parents: parents
        };
    }
    var result = {
        found: false,
    }
    if (tree.children) {
        $.each(tree.children, function (index, subtree) {
            var maybeParents = $.merge([], parents);
            if (tree.value != undefined) {
                maybeParents.push(tree.value);
            }
            var maybeResult = getParentsHelper(subtree, id, maybeParents);
            if (maybeResult.found) {
                result = maybeResult;
                return false;
            }
        });
    }
    return result;
}

function getParents(data1, id) {
    var tree = {
        data: {},
        children: data1
    }
    return getParentsHelper(tree, id, []);
}
export class Department extends Component {

    constructor() {
        super();
        this.state = {
            nameOfDepartment: "",
            nodState: false,
            hiddenSave: false,
            hiddenCreateNew: true,
            hiddenUpdate: true,
            parentDepartment: "Select Parent Department",
            parentDepartmentId: 0,
            departmentId: null,
            departmentHead: "Select Department Head",
            departmentHeadId: null,
            discipline: "Select Discipline",
            createButtonLabel: "Create New",
            createButtonClass: "p-button-info",
            createButtonIcon: "pi pi-plus",
            disciplines: [],
            disciplineId: null,
            selectedDepartment: null,
            selectedDepartmentId: null,
            departments: [],
            parentDepartments: [],
            departmentHeads: [],
            managementLevels: [],
            managementLevel: "",
            managementLevelId: null,
            disciplinesOfDepartments: [],

            departmentTreeTable: [],
            departmentCascader: [],
            values: [],

            modalShowRevisions: false,
            revisionDatas: [],

            topX: 0,
            leftY: 0,

            userSettings: null,

            departmentDesignationId: null,
            modalDesignations: false,
            modalParentDesignations: [],
            designationCascader: [],
            modalDesignationId: null,
            modalDsiciplineId: null,
            designationTreeTable: [],
            modalDesignationDeleteShow:false,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0,

            modalMessage: false
        };

        this.onDisciplineChange = this.onDisciplineChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

    }

    formRef = React.createRef();

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage={msg} />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    onDisciplineChange(event) {
        this.setState({ disciplines: event.value });
    }

    actionTemplate2(rowData, column) {
        return <React.Fragment>
            {this.state.authorizations.findIndex(p => p.action === "delete") >= 0 && <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>}
            <Dialog header="Delete This Department Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
                <p>This deparment record will be deleted. Are you sure?</p>
            </Dialog>
            {this.state.authorizations.findIndex(p => p.action === "update") >= 0 && <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>}
        </React.Fragment>;
    }

    actionTemplate(rowData, column) {
        // return <React.Fragment>
        //     <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} onRevision={this.showRevisions.bind(this)} />
        // </React.Fragment>;

        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} onRevision={this.showRevisions.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.deleteModal.bind(this)} />
            </React.Fragment>;
        }
    }

    deleteModal = (row) => {
        this.setState({
            modalShow: true,
            selectedDepartment: row
        });
    }

    deleteModalDesignation = (row) => {
        this.setState({
            modalDesignationDeleteShow: true,
            departmentDesignationId: row.key
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showRevisions = async (row) => {
        const responseRevisions = await handleRequest("GET", "/Department/getLogs/" + row.data.id);
        if (responseRevisions.data.length > 0) {
            this.setState({
                revisionDatas: responseRevisions.data
            });

            console.log(responseRevisions.data);
        } else {
            this.setState({
                revisionDatas: []
            });
        }

        this.setState({
            modalShowRevisions: true
        });
    }

    showOrHideModalRevisions = () => {
        this.setState({
            modalShowRevisions: !this.state.modalShowRevisions,
            revisionDatas: []
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    componentDidMount = async () => {

        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        console.log(standardization);
        console.log(dynamicMenu);

        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        document.addEventListener('contextmenu', this._handleContextMenu);
        this.restartTable();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    // componentWillUnmount() {
    //     document.removeEventListener('contextmenu', this._handleContextMenu);
    // }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa tıklamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }





    onDepartmentCascaderChange = (value) => {
        this.setState({
            values: value
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteDepartment(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    saveDepartment = async () => {
        const length = this.state.values.length;
        var pId;

        if (length > 0)
            pId = this.state.values[length - 1];

        if (this.state.departmentHead !== "Select Department Head" && this.state.nameOfDepartment !== "") {

            const newDepartment = {
                parentId: pId,
                designationId: this.state.departmentHeadId,
                departmentName: this.state.nameOfDepartment,
                managementLevelId: this.state.managementLevelId,
                disciplines: this.state.disciplines
            }

            var response = await handleRequest("POST", "/Department/add", newDepartment);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS-DEPARTMENT-001") {
                    this.changeButtonSave();
                    this.restartTable();
                    this.showSuccess("All data has been saved successfully.");
                }
                else if (response.data === "ERROR-DEPARTMENT-001") {
                    this.showError("An error was occured please try again later !");
                } else if (response.data === "ERROR-DEPARTMENT-002") {
                    this.showError("This record added before please change discipline name !");
                }
            }
        } else {
            this.showError();
        }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/Department/getallByCompany");

        if (response.data.length === 0) {
            this.setState({
                departments: [],
                parentDepartments: [],
                nodState: false
            });
        } else {
            this.setState({
                nodState: true,
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                departments: response.data,
            });

            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName, id: row.id }
            })

            this.setState({
                parentDepartments: options,
            });
        }

        const responseTree = await handleRequest("GET", "/Department/getTreeModel");

        if (responseTree.data.length !== 0) {
            this.setState({
                departmentTreeTable: responseTree.data.treeTable,
                departmentCascader: responseTree.data.cascader
            });
        }

        const response2 = await handleRequest("GET", "/Discipline/getallByCompany");

        if (response2.data.length !== 0) {

            const columns2 = response2.data;

            const options2 = columns2.map(function (row) {
                return { label: row.disciplineName.toString(), value: row.id.toString() }
            })

            this.setState({
                disciplinesOfDepartments: options2,
            });
        }

        const response3 = await handleRequest("GET", "/Designation/getallByCompany");
        if (response3.data.length !== 0) {

            const columns3 = response3.data;

            const options3 = columns3.map(function (row) {
                return { name: row.designationName.toString(), id: row.id }
            })

            this.setState({
                departmentHeads: options3,
            });
        }

        const response4 = await handleRequest("GET", "/ManagementLevel/getallByCompany");

        if (response4.data.length !== 0) {

            const columns3 = response4.data;

            const options3 = columns3.map(function (row) {
                return { name: row.managementLevelName.toString(), id: row.id.toString() }
            })

            this.setState({
                managementLevels: options3,
            });
        }
    }

    delete = async () => {
        const deletedDepartment = {
            id: this.state.selectedDepartmentId
        }

        await handleRequest("POST", "/Department/delete", deletedDepartment);

        this.restartTable();
        this.showSuccess("Data has been deleted successfully.");
        this.showOrHideModal();
    }

    deleteDesignation = async () => {
        const obj = {
            id: this.state.departmentDesignationId
        }

        await handleRequest("POST", "/DepartmentDesignation/delete", obj);

        this.getDepartmentDesignations();
        this.showSuccess("Data has been deleted successfully.");
        this.setState({
            modalDesignationDeleteShow:false 
        })
    }


    changeButtonUpdate = (row) => {

        row = row.data;

        var ml = { id: 0, managementLevelName: "Select Management Level" };
        var flagValues = [];


        if (row.managementLevel !== null && row.managementLevel !== undefined)
            ml = row.managementLevel;

        if (row.lineage.length > 1) {
            flagValues = row.lineage;
            flagValues.pop();
        };

        console.log(flagValues);
        // flagValues = getParents(this.state.departmentCascader, row.id).parents;
        // flagValues.push(row.id.toString());
        this.setState({
            hiddenUpdate: false,
            hiddenSave: true,
            hiddenCreateNew: false,
            nodState: false,
            selectedDepartment: row,
            nameOfDepartment: row.departmentName,
            parentDepartment: row.parentDepartmentName,
            parentDepartmentId: row.parentId,
            departmentHead: row.designationName,
            departmentHeadId: row.designationId,
            disciplines: row.disciplines,
            managementLevel: ml.managementLevelName,
            managementLevelId: ml.id,
            values: flagValues,
            createButtonLabel: "Stop Updating",
            createButtonClass: "p-button-danger",
            createButtonIcon: ""
        });
    }
 
   

    createNew = () => {

        if (this.state.hiddenCreateNew) {
            this.setState({
                hiddenCreateNew: false,
                createButtonLabel: "Stop Adding New",
                createButtonClass: "p-button-danger",
                createButtonIcon: ""
            });
        }
        else {
            this.setState({
                hiddenUpdate: true,
                hiddenSave: false,
                hiddenCreateNew: true,
                nameOfDepartment: "",
                nodState: true,
                parentDepartment: "Select Parent Department",
                parentDepartmentId: null,
                departmentId: null,
                departmentHead: "Select Department Head",
                departmentHeadId: null,
                discipline: "Select Discipline",
                disciplines: [],
                disciplineId: null,
                selectedDepartment: null,
                values: [],
                createButtonLabel: "Create New",
                createButtonClass: "p-button-info",
                createButtonIcon: "pi pi-plus",

            });
        }


    }

    changeButtonSave = () => {
        this.setState({
            hiddenUpdate: true,
            hiddenSave: false,
            nameOfDepartment: "",
            nodState: true,
            parentDepartment: "Select Parent Department",
            parentDepartmentId: null,
            departmentId: null,
            departmentHead: "Select Department Head",
            departmentHeadId: null,
            discipline: "Select Discipline",
            disciplineId: null,
            disciplines: [],
            managementLevelId: null,
            managementLevel: "Select Management Level",
            selectedDepartment: null,
            values: []
        });

    }

    changeButtonCancel = () => {
        this.setState({
            hiddenUpdate: true,
            hiddenSave: false,
            hiddenCreateNew: true,
            nameOfDepartment: "",
            nodState: true,
            parentDepartment: "Select Parent Department",
            parentDepartmentId: null,
            departmentId: null,
            departmentHead: "Select Department Head",
            departmentHeadId: null,
            discipline: "Select Discipline",
            disciplines: [],
            disciplineId: null,
            managementLevelId: null,
            managementLevel: "Select Management Level",
            selectedDepartment: null
        });
    }

    updateDone = async () => {
        if (this.state.departmentHead !== "Select Department Head" && this.state.nameOfDepartment !== "") {

            const updateDepartment = {
                id: this.state.selectedDepartmentId,
                parentId: this.state.parentDepartmentId,
                designationId: this.state.departmentHeadId,
                departmentName: this.state.nameOfDepartment,
                managementLevelId: this.state.managementLevelId,
                disciplines: this.state.disciplines
            }

            var response = await handleRequest("POST", "/Department/update", updateDepartment);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS-DEPARTMENT-001") {
                    this.setState({
                        hiddenUpdate: true,
                        hiddenSave: false,
                        nameOfDepartment: "",
                        nodState: true,
                        parentDepartment: "Select Parent Department",
                        parentDepartmentId: null,
                        departmentId: null,
                        departmentHead: "Select Department Head",
                        departmentHeadId: null,
                        discipline: "Select Discipline",
                        disciplineId: null,
                        selectedDepartment: null,
                        createButtonLabel: "Stop Adding New",
                        createButtonClass: "p-button-danger",
                        createButtonIcon: "",
                        managementLevelId: null,
                        disciplines: [],
                        managementLevel: "Select Management Level",
                        values: []
                    });
                    this.restartTable();
                    this.showSuccess("All data has been updated successfully.");
                }
                else if (response.data === "ERROR-DEPARTMENT-001") {
                    this.showError("An error was occured please try again later !");
                } else if (response.data === "ERROR-DEPARTMENT-002") {
                    this.showError("This record added before please change department name !");
                }
            }
        } else {
            this.showError();
        }
    }

    handleChange2 = e => {
        e.preventDefault();
        var headIdValue;

        for (var z = 0; z < this.state.departmentHeads.length; z++) {
            if (this.state.departmentHeads[z].name === e.target.value) {
                headIdValue = this.state.departmentHeads[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            departmentHeadId: headIdValue
        });
    }

    handleChange3 = e => {

        e.preventDefault();
        var headIdValue;

        for (var z = 0; z < this.state.managementLevels.length; z++) {

            if (this.state.managementLevels[z].name === e.target.value) {
                headIdValue = this.state.managementLevels[z].id
            }

        }

        this.setState({
            [e.target.name]: e.target.value,
            managementLevelId: headIdValue
        });

    }



    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getDepartmentDesignations = async () => {

        const response = await handleRequest("GET", "/Department/getDesignations/" + this.state.selectedDepartment.id);
        console.log(response.data);
        if (Boolean(response.data)) {
            this.setState({
                designationCascader: response.data,
                designationTreeTable: response.data

            });


        } else {
            this.setState({
                designationCascader: [],
                designationTreeTable: []
            });
        }


    }

    saveDesignation = async () => {
        const parentDesignations = this.state.modalParentDesignations;
        let parentId = null;
        if (Boolean(parentDesignations) && parentDesignations.length > 1)
            parentId = parentDesignations[parentDesignations.length - 1];

        const obj = {
            id: this.state.departmentDesignationId,
            parentId: parentId,
            departmentId: this.state.selectedDepartment.id,
            designationId: this.state.modalDesignationId,
            disciplineId: this.state.modalDsiciplineId
        }

        if (Boolean(parentDesignations) && Boolean(obj.designationId)) {
            console.log(obj);
            if (obj.id === null)
                await handleRequest("POST", "/DepartmentDesignation/add", obj);
            else
                await handleRequest("POST", "/DepartmentDesignation/update", obj);

            this.showSuccess();
            this.resetDesignationInputs();
            this.getDepartmentDesignations();
        }
        else {
            this.showError("Please select parent designation and designation !");
        }

    }

    resetDesignationInputs = () => {

        this.setState({
            departmentDesignationId: null,
            modalParentDesignations: [],
            designationCascader: [],
            modalDesignationId: null,
            modalDsiciplineId: null,
            designationTreeTable: []
        });

    }

    canceUpdateDesignation = async () => {

        this.resetDesignationInputs();
    }


    actionTemplate3(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenuDelete row={rowData} onDeleteRow={this.deleteModalDesignation.bind(this)} />
        </React.Fragment>;


    }

    render() {


        var itemParentDepartmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DepartmentParentDepartment");
        var itemNameOfDepartmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DepartmentNameOfDepartment");
        var itemManagmentLevelAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DepartmentManagmentLevel");
        var itemDepartmentHeadAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DepartmentDepartmentHead");
        var itemDisciplinesOfDepartmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DepartmentDisciplinesOfDepartment");

        var itemParentDepartmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "DepartmentParentDepartment");
        var itemNameOfDepartmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "DepartmentNameOfDepartment");
        var itemManagmentLevelRequired = this.state.dynamicInputs.find(p => p.inputKey === "DepartmentManagmentLevel");
        var itemDepartmentHeadRequired = this.state.dynamicInputs.find(p => p.inputKey === "DepartmentDepartmentHead");
        var itemDisciplinesOfDepartmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "DepartmentDisciplinesOfDepartment");

        const columns = [

            {
                title: 'Designation',
                dataIndex: 'label',
                key: 'label'

            },
            {
                title: 'Discipline',
                dataIndex: 'discipline',
                key: 'discipline'

            },
            {
                title: 'Action', 
                key: 'name', render: (record) => {
                    return this.actionTemplate3(record);
                }

            }
        ]
        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;
        let optionsDepartmentHeads = this.state.departmentHeads.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsManagementLevels = this.state.managementLevels.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        console.log(this.state.authorizations);
        console.log(this.state.formId);

        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">

                            <Modal
                                size="lg"
                                show={this.state.modalDesignations}
                                onHide={() => {
                                    this.setState({
                                        modalDesignations: false,
                                        selectedDepartment: null,
                                    }, this.resetDesignationInputs)
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Designations of Departments </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {Boolean(this.state.selectedDepartment) ? (
                                        <>  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="4">
                                                <Form.Label> {<FormattedMessage id="Department.ParentDesignation" defaultMessage="Parent Designation" />} </Form.Label>
                                            </Col>
                                            <Col sm="4">
                                                <Cascader id="Department.ModalParentDesignationId" style={{ width: '100%' }} value={this.state.modalParentDesignations} options={this.state.designationCascader} onChange={(value) => {
                                                    this.setState({
                                                        modalParentDesignations: value
                                                    });
                                                }} changeOnSelect />

                                            </Col>
                                        </Form.Group>
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="4">
                                                    <Form.Label> {<FormattedMessage id="Department.ParentDesignation" defaultMessage="Designation" />} </Form.Label>
                                                </Col>
                                                <Col sm="4">
                                                    <Select style={{ width: "100%" }} placeholder="Designation" id="Department.ModalDesignationId" value={this.state.modalDesignationId} onChange={(value) => {

                                                        this.setState({ modalDesignationId: value })
                                                    }}>
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.departmentHeads.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="4">
                                                    <Form.Label>
                                                        {<FormattedMessage id="Department.ModalDiscipline" defaultMessage="Discipline" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="4">
                                                    <Select style={{ width: "100%" }} placeholder="Discipline" id="Department.ModalDisciplineId" value={this.state.modalDsiciplineId} onChange={(value) => {

                                                        this.setState({ modalDsiciplineId: value })
                                                    }}>
                                                        <Option key={null} value={null}>Select</Option>
                                                        {Boolean(this.state.selectedDepartment.disciplineDepartmentList) && this.state.selectedDepartment.disciplineDepartmentList.map(i => (
                                                            <Option key={i.disciplineId} value={i.disciplineId}>{i.discipline.disciplineName}</Option>
                                                        ))}
                                                    </Select>

                                                </Col>
                                            </Form.Group>
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="4">

                                                </Col>
                                                <Col sm="4">
                                                    <div hidden={Boolean(this.state.departmentDesignationId) && this.state.departmentDesignationId > 0}>

                                                        <ReactButton id="DepartmentModalSaveButton" type="button" onClick={this.saveDesignation} style={{ width: '100%' }} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>

                                                    </div>
{/* 
                                                    <div hidden={!(Boolean(this.state.departmentDesignationId) && this.state.departmentDesignationId > 0)}>

                                                        <Col sm="12">
                                                            <ReactButton id="DepartmentCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.canceUpdateDesignation}>
                                                                <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                        </Col>
                                                        <Col sm="12">
                                                            <ReactButton id="DepartmentUpdateButton" style={{ width: '100%' }} variant="warning" onClick={this.saveDesignation}>
                                                                <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                        </Col>

                                                    </div> */}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group>
                                                <Row>
                                                    <Col sm="12">
                                                        <Table
                                                            className="components-table-demo-nested"
                                                            bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }}
                                                            columns={columns}
                                                            dataSource={this.state.designationTreeTable}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </>
                                    ) : <div>
                                            Please select defined department head or define a department head before add designations.
                                </div>}

                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={() => {
                                        this.setState({
                                            modalDesignations: false,
                                            selectedDepartment: null,
                                        }, this.resetDesignationInputs)
                                    }}>Close</ReactButton>

                                </Modal.Footer>
                            </Modal>



                            <Modal
                                show={this.state.modalDesignationDeleteShow}
                                onHide={() => {
                                    this.setState({
                                        modalDesignationDeleteShow: false,
                                        departmentDesignationId:null
                                    })
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete  Designation ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Selected designation will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={() => { 
                                        this.setState({
                                            modalDesignationDeleteShow: false ,
                                            departmentDesignationId:null
                                        }) 
                                    }}> Cancel</ReactButton> 
                                    <ReactButton variant="danger" onClick={this.deleteDesignation} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.modalShow}
                                onHide={this.showOrHideModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete  Department ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Selected department will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>


                            <Modal
                                show={this.state.modalShowRevisions}
                                onHide={this.showOrHideModalRevisions}
                                aria-labelledby="contained-modal-title-vcenter"
                                size="xl"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Revisions</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table dataSource={this.state.revisionDatas} bordered>
                                        <Column title="Date" key="date" render={(text, record) => {
                                            var sqlDate = new Date(record.changeDate);
                                            var now = moment(sqlDate).format('DD/MM/YY HH:mm:ss');
                                            return now;
                                        }} />
                                        <Column title="Department Name" key="departmentName" dataIndex="departmentName" />
                                        <Column title="Management Level" key="managmentLevel" render={(text, record) => {
                                            if (record.managementLevel !== undefined)
                                                return record.managementLevel.managementLevelName
                                            else
                                                return "";
                                        }} />
                                        <Column title="Department Head" key="designationName" dataIndex="designationName" />
                                        <Column title="Role Group" key="roleGroup" render={(text, record) => {
                                            if (record.designation.roleGroup !== undefined)
                                                return record.designation.roleGroup.roleName
                                            else
                                                return "";
                                        }} />
                                        <Column title="Disciplines" key="disciplineNames" dataIndex="disciplineNames" />
                                        <Column title="Who Changed" key="user" render={(text, record) => {
                                            if (record.user !== undefined)
                                                return record.user.name + " " + record.user.lastName;
                                            else
                                                return "";
                                        }} />
                                        {/*<Column title="IP Address" key="date" dataIndex="ipAddress" />*/}
                                        <Column title="Operation Type" key="operationType" dataIndex="operationType" />
                                    </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="info" onClick={this.showOrHideModalRevisions}>Close</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.modalMessage}
                                onHide={this.showOrHideModalMessage}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>

                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <Row >
                                            <Col sm="12">
                                                {this.state.messageTip == "Success" &&
                                                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                {this.state.messageTip == "Warning" &&
                                                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                {this.state.messageTip == "Error" &&
                                                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                            </Col>
                                        </Row>
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <Row >
                                        <Col sm="12">
                                            {this.state.message}
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                </Modal.Footer>
                            </Modal>

                            {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Card>

                                <div>
                                    <Row>
                                        <Col sm="6" md="2" lg="2" >
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="0" md="8" lg="8"  >
                                        </Col>
                                        <Col sm="6" md="2" lg="2" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%', marginBottom: '2rem' }} id='create-new' className={this.state.createButtonClass} icon={this.state.createButtonIcon} onClick={this.createNew} label={this.state.createButtonLabel} />*/}
                                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                                <ReactButton id="DepartmentCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hiddenCreateNew} style={{ marginBottom: '2rem' }}>
                                    <FormAnt
                                        {...layout}
                                        initialValues={{ remember: false }}
                                        onFinish={this.state.hiddenUpdate == false ? this.updateDone : this.saveDepartment}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DepartmentParentDepartment").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                label={<FormattedMessage id="DepartmentParentDepartment" defaultMessage="Parent Department" />}
                                                name="DepartmentParentDepartment"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DepartmentParentDepartment")) ? this.state.dynamicInputs.find(p => p.inputKey === "DepartmentParentDepartment").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "DepartmentParentDepartment")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "DepartmentParentDepartment").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        {/* <Cascader id="DepartmentParentDepartment" style={{ width: '100%' }} value={this.state.values} defaultValue={this.state.values} options={this.state.departmentCascader} onChange={this.onDepartmentCascaderChange} changeOnSelect /> */}
                                                        <Cascader id="DepartmentParentDepartment" style={{ width: '100%' }} value={this.state.values} options={this.state.departmentCascader} onChange={this.onDepartmentCascaderChange} changeOnSelect />
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {(itemNameOfDepartmentAttributes.visible) &&
                                            <FormAnt.Item
                                                label={<FormattedMessage id="DepartmentNameOfDepartment" defaultMessage="Name Of Department" />}
                                                name="DepartmentNameOfDepartment"
                                                rules={[{ required: itemNameOfDepartmentRequired.canBeRequired, message: <FormattedMessage id={itemNameOfDepartmentRequired.translateRequired} defaultMessage="Please input the name of department!" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Input id="DepartmentNameOfDepartment" value={this.state.nameOfDepartment} onChange={(e) => this.setState({ nameOfDepartment: e.target.value })} />
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {(itemManagmentLevelAttributes.visible) &&
                                            <FormAnt.Item
                                                label={<FormattedMessage id="DepartmentManagmentLevel" defaultMessage="Management Level" />}
                                                name="DepartmentManagmentLevel"
                                                rules={[{ required: itemManagmentLevelRequired.canBeRequired, message: <FormattedMessage id={itemManagmentLevelRequired.translateRequired} defaultMessage="Please select a managment level!" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Form.Control id="DepartmentManagmentLevel" name="managementLevel" value={this.state.managementLevel}
                                                            onChange={this.handleChange3} as="select">
                                                            <option value="">
                                                                Select Management Level
                                                    </option>
                                                            {optionsManagementLevels}
                                                        </Form.Control>
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {(itemDepartmentHeadAttributes.visible) &&
                                            <FormAnt.Item
                                                label={<FormattedMessage id="DepartmentDepartmentHead" defaultMessage="Department Head" />}
                                                name="DepartmentDepartmentHead"
                                                rules={[{ required: itemDepartmentHeadRequired.canBeRequired, message: <FormattedMessage id={itemDepartmentHeadRequired.translateRequired} defaultMessage="Please select a department head!" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Form.Control id="DepartmentDepartmentHead" name="departmentHead" value={this.state.departmentHead}
                                                            onChange={this.handleChange2} as="select">
                                                            <option value="">
                                                                Select Department Head
                                            </option>
                                                            {optionsDepartmentHeads}
                                                        </Form.Control>
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {(itemDisciplinesOfDepartmentAttributes.visible) &&
                                            <FormAnt.Item
                                                label={<FormattedMessage id="DepartmentDisciplinesOfDepartment" defaultMessage="Disciplines of Department" />}
                                                name="DepartmentDisciplinesOfDepartment"
                                                rules={[{ required: itemDisciplinesOfDepartmentRequired.canBeRequired, message: <FormattedMessage id={itemDisciplinesOfDepartmentRequired.translateRequired} defaultMessage="Please select a managment level!" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <MultiSelect id="DepartmentDisciplinesOfDepartment" className="p-column-filter" value={this.state.disciplines}
                                                            options={this.state.disciplinesOfDepartments} onChange={this.onDisciplineChange} />
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        <div hidden={this.state.hiddenSave}>
                                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="5">
                                                        {/*<Button style={{ width: '100%' }} id='save-deparment' className="p-button-success" onClick={this.saveDepartment} label="Save" /> */}
                                                        <ReactButton id="DepartmentSaveButton" type="submit" style={{ width: '100%' }} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>

                                        <div hidden={this.state.hiddenUpdate}>
                                            {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        <ReactButton id="DepartmentCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.changeButtonCancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        <ReactButton id="DepartmentUpdateButton" style={{ width: '100%' }} variant="warning" onClick={this.updateDone}>
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>


                                    {/*
                            <Form>
                                {this.state.dynamicAttributes.findIndex(p => p.attributeKey === "parentId" && p.visible) >= 0 && <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Parent Department:
                                    </Form.Label>
                                    <Col sm="3">
                                        <Cascader style={{ width: '100%' }} defaultValue={this.state.values} options={this.state.departmentCascader} onChange={this.onDepartmentCascaderChange} changeOnSelect />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>}
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Name of Department:
                                    </Form.Label>
                                    <Col sm="3">
                                        <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ nameOfDepartment: e.target.value })} value={this.state.nameOfDepartment} placeholder="Name of Department" />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Management Level:
                        </Form.Label>
                                    <Col sm="3">
                                        <Form.Control name="managementLevel" value={this.state.managementLevel}
                                            onChange={this.handleChange3} as="select">
                                            <option value="">
                                                Select Management Level
                                </option>
                                            {optionsManagementLevels}
                                        </Form.Control>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Department Head:
                                    </Form.Label>
                                    <Col sm="3">
                                        <Form.Control name="departmentHead" value={this.state.departmentHead}
                                            onChange={this.handleChange2} as="select">
                                            <option value="">
                                                Select Department Head
                                </option>
                                            {optionsDepartmentHeads}
                                        </Form.Control>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>
                                {this.state.dynamicAttributes.findIndex(p => p.attributeKey === "disciplineId" && p.visible === true) >= 0 && <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                    <Col sm="3">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Disciplines of Department:
                            </Form.Label>
                                    <Col sm="3">
                                        <MultiSelect className="p-column-filter" value={this.state.disciplines} options={this.state.disciplinesOfDepartments} onChange={this.onDisciplineChange} />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>}

                            </Form>

                            //Butonların başladığı kısım üste alındı.
*/}
                                </div>

                                {/* { this.state.authorizations.findIndex(p=>p.action==="getAll")>=0 &&   
                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable ref={(el) => this.dt = el} value={this.state.departments} paginator={true} responsive={true}
                            rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-departments" selectionMode="single"
                            selection={this.state.selectedDepartment} onSelectionChange={e => this.setState({ selectedDepartment: e.value })}>
                            <Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable header="Id" />
                            <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                            { this.state.dynamicAttributes.findIndex(p=>p.attributeKey==="parentId" && p.visible)>=0 &&   <Column field="parentDepartmentName" header="Parent Department" filter sortable />}
                            <Column field="parentId" style={{ textAlign: 'center', display: 'none' }} header="Parent Department Id" filter sortable />
                            <Column field="departmentName" header="Department" filter sortable />
                            <Column field="managementLevel.managementLevelName" header="Management Level" filter sortable />
                            <Column field="designationName" header="Department Head" filter sortable />
                            <Column field="designationId" style={{ textAlign: 'center', display: 'none' }} header="Department Head Id" filter sortable />                          
                            <Column field="disciplines" style={{ textAlign: 'center', display: 'none' }} header="Discipline Ids" filter sortable />
                            <Column field="designation.roleGroup.roleName" style={{ textAlign: 'center'}} header="Role Group" filter sortable />
                            { this.state.dynamicAttributes.findIndex(p=>p.attributeKey==="disciplineId" && p.visible)>=0 && <Column field="disciplineNames" style={{ textAlign: 'center' }} header="Disciplines" filter sortable />}
                        
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        </DataTable>
                        
                 </div> } */}

                                <div className="content-section implementation">
                                    <Toast ref={(el) => this.toast = el} />
                                    <TreeTable value={this.state.departmentTreeTable} selectionMode="single"
                                        selection={this.state.selectedDepartmentId} onSelectionChange={e => this.setState({ selectedDepartmentId: e.value })}>
                                        <PrimeColumn field="departmentName" header="Department" expander filter sortable />
                                        <PrimeColumn field="managementLevel.managementLevelName" header="Management Level" filter sortable />
                                        <PrimeColumn body={(row) => {
                                            return <div
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                onClick={() => {
                                                    let s = null;
                                                    if (Boolean(row.data.designation)) {
                                                        s = row.data;
                                                        console.log(s);
                                                    }
                                                    this.setState({
                                                        selectedDepartment: s,
                                                        modalDesignations: true,
                                                    }, this.getDepartmentDesignations)
                                                }
                                                }
                                            >
                                                {row.data.designationName}
                                            </div>
                                        }} header="Department Head" />
                                        {/*
                                <PrimeColumn field="designationId" style={{ textAlign: 'center', display: 'none' }} header="Department Head Id" filter sortable />
                                <PrimeColumn field="disciplines" style={{ textAlign: 'center', display: 'none' }} header="Discipline Ids" filter sortable />
                                */}
                                        <PrimeColumn field="designation.roleGroup.roleName" style={{ textAlign: 'center' }} header="Role Group" filter sortable />
                                        <PrimeColumn field="disciplineNames" style={{ textAlign: 'center' }} header="Disciplines" filter sortable />
                                        {(this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 || this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) &&
                                            <PrimeColumn header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                        }
                                    </TreeTable>
                                </div>
                            </Card>
                            }
                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>}
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Department)  