/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Modal, Row } from 'react-bootstrap';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { InputText } from "primereact/inputtext";
import { Button as ReactButton } from 'react-bootstrap';
import { Button as AntdButton, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuLanguage';
import { Cascader, Checkbox } from 'antd';
import { FormattedMessage } from "react-intl";

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.data.id, lineage: node.data.lineage });
        if (node.children) {  
            generateList(node.children); 
        }
    }
};

function formIdBul(formId){
    if(formId=="General"){
        return -1;
    }else if(formId=="What Is"){
        return -2;
    }else if(formId=="Required"){
        return -3;
    }else if(formId=="Login"){
        return -4;
    }else if(formId=="Text To Para"){
        return -5;
    }else if(formId=="WPS"){
        return -6;
    } else if(formId=="Personal Info"){
        return -7;
    }
    return formId;
}

function formAckBul(element){
    if(element.formId==-1){
        return "General";
    }else if(element.formId==-2){
        return "What Is";
    }else if(element.formId==-3){
        return "Required";
    }else if(element.formId==-4){
        return "Login";
    }else if(element.formId==-5){
        return "Text To Para";
    }else if(element.formId==-6){
        return "WPS";
    }
    else if(element.formId==-7){
        return "Personal Info";
    }
    return element.dynamicMenu!=null ? element.dynamicMenu.menuTitle : element.form;
}

export class LanguageSettings extends Component {
    constructor() {
        super();
        this.state = {
            modalShow: false,
            modalCreateNewShow: false,
            newLanguageName: "", 
            displayName: "", 
            languageTypeId: 0,
            languageTypeIds: [],
            LanguageTypeForOptions: [],
            languageTitle: "Add New Language",
            addUpdateButtonVariant: "info",
            addUpdateButton: "Add",
            hiddenDisplayNameKnt: true, 
            hiddenLangKnt: true,
            hiddenKeyKnt: true,
            IconUpload: [],
            fileList: [],
            iconPath: "",

            languageType: "",
            stateId: "",
            stateValue: "",

            oldLanguageList: [],
            columns: [
                {
                    dataField: 'id',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'ID',
                    hidden: true,
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: false
                },
                {
                    dataField: 'page',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'Page',
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: false,
                    headerFormatter: this.priceFormatter1
                },
                {
                    dataField: 'stateId',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'State Id',
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    hidden: true,
                    headerFormatter: this.priceFormatter1
                },
                {
                    dataField: 'stateValue',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'State Value',
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: false,
                    headerFormatter: this.priceFormatter1
                }
            ],
            ornekler: [],
            authorizations: [],
            dynamicMenuCascader: [],
            formId: [],
            filterPage: [],

        }; 
        this.onChangeCheck = this.onChangeCheck.bind(this);
    };

    componentDidMount = async () => {
        this.getLanguageDisplayName();
        this.getDynamicMenu();

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "LanguageSettings");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        this.restartTable(0);
    } 

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModelLanguage");
        console.log("DDD", responseTree.data);
        if (responseTree.data.length !== 0) {
            generateList(responseTree.data.treeTable); 
            this.setState({
                dynamicMenuCascader: responseTree.data.cascader
            });
        } else {
            this.setState({
                dynamicMenuCascader: []
            });
        }
    } 

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    };
    
    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    };
    
    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow,
            newLanguageName: "",  
            displayName: "",
            iconPath: "",
            lang: "",
            languageTypeId: 0,
            IconUpload: [],
            fileList: [],
            iconPath: "",
            hiddenDisplayNameKnt: true,
            hiddenLangKnt: true,
            languageTitle: "Add New Language",
            addUpdateButtonVariant: "info",
            addUpdateButton: "Add",
        });
    };  
    
    showOrHideCreateNewModal = () => {
        this.setState({
            modalCreateNewShow: !this.state.modalCreateNewShow,
            formId: [],
            languageTypeId: 0,
            languageType: "",
            stateId: "",
            stateValue: "",
            hiddenKeyKnt: true
        });
    };

    exportLanguage = async () => { 
        window.location.href = `${API_BASE}/LanguageType/export`;  
    }

    addUpdateNewLanguage = async () => { 
        if (this.state.displayName != "" && this.state.iconPath != "") {
            if (this.state.hiddenDisplayNameKnt == true) {
                if (this.state.hiddenLangKnt == true) {
                    const addUpdateNewLanguage = {
                        id: this.state.languageTypeId,
                        name: this.state.newLanguageName,
                        lang: this.state.lang,
                        iconPath: this.state.iconPath,
                        displayName: this.state.displayName, 
                        showOnLayout: false
                    }
        
                    if (addUpdateNewLanguage.id === 0) {  
                        if (addUpdateNewLanguage.name == "") {
                            addUpdateNewLanguage.name = addUpdateNewLanguage.displayName;
                        }
                        var response = await handleRequest("POST", "/LanguageType/add", addUpdateNewLanguage);
            
                        if (response.data !== null && response.data !== undefined) {
                            if (response.data == "SUCCESS") {
                            this.restartTable(0);
                            this.showOrHideModal();
                            this.showSuccess();
                            this.getLanguageDisplayName();
                            }
                            else {
                                this.showError();
                            }
                        }
                        else {
                            this.showError();
                        }
                    }
                    else {
                        if (addUpdateNewLanguage.name != "") {
                            var response = await handleRequest("POST", "/LanguageType/update", addUpdateNewLanguage);
            
                            if (response.data !== null && response.data !== undefined) {
                              if (response.data == "SUCCESS") {
                                this.restartTable(0);
                                this.showOrHideModal();
                                this.showSuccess();
                              }
                              else {
                                this.showError();
                              }
                            }
                            else {
                              this.showError();
                            }
                        }
                        else {
                            this.showWarning();
                        }
                    }
                } else {
                    this.showWarning(" The Display Short Name you want to add has been used before. Please use a different Display Short Name !");
                }
            } else {
                this.showWarning(" The Display Name you want to add has been used before. Please use a different Display Name !");
            }
        } else {
            this.showWarning();
        }
    };

    createNewLanguage = async () => { 
        if(this.state.formId.length!=0 && this.state.languageTypeId !=0 && this.state.languageType!="" && this.state.stateId != "" && this.state.stateValue != "") {
            if(this.state.hiddenKeyKnt==true){
                var formId=0
                if(this.state.formId.length>0){
                    formId=this.state.formId[this.state.formId.length - 1];
                }

                formId = formIdBul(formId);

                const addNewLanguage = {
                    form: "",
                    formId: formId,
                    languageTypeId: this.state.languageTypeId,
                    stateId: this.state.stateId,
                    stateValue: this.state.stateValue,
                }
                var response = await handleRequest("POST", "/Language/add", addNewLanguage);
                if (response.data !== null && response.data !== undefined) {
                    if (response.data == "SUCCESS") {
                    this.showSuccess();
                    this.restartTable(0);
                    this.showOrHideCreateNewModal();
                    }
                    else {
                    this.showError();
                    }
                }
                else {
                    this.showError();
                }
            } else {
                this.showWarning("The Key you want to add has been used before. Please use a different Key !");
            }
        } else {
            this.showWarning();
        }
    };

    onClickModal(name, column) {
        this.setState({
            [`${name}`]: true,
            languageTypeId: parseInt(column.id, 10),
        });
    }; 

    deleteLanguageType = (rowData) => {
        this.setState({
            deleteModal: true,
            languageTypeId: rowData.id,
        });
    }; 

    deleteLanguageTypeOK = async () => {
        const deletedLangType = {
            id: this.state.languageTypeId
        } 

        if (this.state.languageTypeId != 0) {
        var response = await handleRequest("POST", "/LanguageType/delete", deletedLangType);
        
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.restartTable(0);
                    this.showSuccess(); 
                }
                else if (response.data == "WARNING") {
                    this.showWarning("The last Language Type cannot be deleted !");
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            } 
        }
        else {
          this.showError("Please try again later !");
        } 
    } 
        
    showOrHideModalDeleteLanguageType = () => {
        this.setState({
        deleteModal: !this.state.deleteModal,
        });
    };  

    editLanguageType = async (rowData) => { 
       await this.setState({
            languageTypeId: rowData.id,
            displayName: rowData.displayName,
            iconPath: rowData.iconPath,
            lang: rowData.lang,
            newLanguageName: rowData.name,
            IconUpload: [],
            fileList: [],
            hiddenDisplayNameKnt: true,
            hiddenLangKnt: true,
            languageTitle: "Edit Language",
            addUpdateButtonVariant: "warning",
            addUpdateButton: "Update",
            modalShow: !this.state.modalShow,
        });
    }; 

    restartTable = async (formId) => {
        this.setState({
            languageTypeId: 0,
            deleteModal: false,
            languageTypeIds: [],
            columns: [
                {
                    dataField: 'id',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'ID',
                    hidden: true,
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: false
                },
                {
                    dataField: 'page',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'Page',
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: false,
                    headerFormatter: this.priceFormatter1,
                }, 
                {
                    dataField: 'stateId',
                    id: 0,
                    lang: "",
                    showOnLayout: false,
                    text: 'Key',
                    headerStyle: {
                        fontWeight: 'bold',
                        textAlign: 'center'
                    },
                    editable: false,
                    headerFormatter: this.priceFormatter1
                } 
            ]
        });

        formId= formIdBul(formId);

        var response100 =[];
        var response = response = await handleRequest("GET", "/LanguageType/getAll");
        if (formId != 0) {
            const newObj = {
                formId: formId,
            }
            response100 = await handleRequest("POST", "/Language/getResult", newObj); 
        } else {
            response100 = await handleRequest("GET", "/Language/getAll");
        }

        const list = response100.data; 
        this.setState({
            oldLanguageList: list
        });
        const langTypes = response.data; 

        const stateIds = [];
        list.forEach(element => {
            if (!stateIds.includes(element.stateId))
            stateIds.push(element.stateId);
        });
        const data=[];

        stateIds.forEach((stateId, index) => {
            const filterList = list.filter(p => p.stateId == stateId);

            var obj={stateId:stateId};
            obj.id=index;
 
            filterList.forEach((element) => { 
                obj.page= formAckBul(element); 
                obj.formId=element.formId;
                obj[element.languageTypeId + ""] = element.stateValue;
            }); 
            
            langTypes.forEach(element => {
               if (!Boolean(obj[element.id + ""])) {
                    obj[element.id + ""] = "";
                } 
            }); 

            data.push(obj);
        });

        console.log(data);
       
 
        // const ornekler = await handleRequest("GET", "/Language/getDefaultValues");
        // const response = await handleRequest("GET", "/LanguageType/getAll");

        // console.log("MMM");
        // console.log(ornekler.data);

        this.setState({
            ornekler: data
        });

        var _myLength = this.state.columns.length;
        for (let index = 0; index < response.data.length; index++) {

            this.setState({
                languageTypeIds: [...this.state.languageTypeIds, response.data[index].id],
                columns: [...this.state.columns, {
                    dataField: response.data[index].id, lang: response.data[index].lang, id: response.data[index].id, showOnLayout: response.data[index].showOnLayout, text: response.data[index].displayName, headerStyle: { fontWeight: 'bold', textAlign: 'center' }, headerFormatter: (column, colIndex) => {
                        return <div style={{ marginBottom: "0.75rem" }} key="checkbox">

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <React.Fragment>
                                <Form.Check 
                                    style={{ marginTop: "12px", marginLeft: "10px" }}
                                    onChange={this.onChangeCheck}
                                    type="checkbox"
                                    id={"LanguageCheckId-" + `${column.id}`}
                                    label={`${column.text}`} />

                                    <CustomMaterialMenu row={response.data[index]} onDeleteRow={this.deleteLanguageType.bind(this)}
                                     onEditRow={this.editLanguageType.bind(this)} />  
                                </React.Fragment>
                            </Form.Group>
                        </div>;
                    }
                }]
            });
        }

        for (let index = _myLength; index < this.state.columns.length; index++) {
            if (this.state.columns[index].showOnLayout)
            document.getElementById("LanguageCheckId-" + this.state.columns[index].id).checked = true
        }

    }  

    priceFormatter1(column, colIndex) {
        return (
            <div style={{ marginTop: "1.5rem" }}>
                <Form.Label>
                    {`${column.text}`}
                </Form.Label>
            </div>
        );
    }

    onChangeCheck = async (e) => {
        var items = this.state.columns;
        items[items.findIndex(el => el.id == e.target.id.split('-')[1])].showOnLayout = e.target.checked;
        this.setState({ columns: items });
        document.getElementById(e.target.id).checked = e.target.checked;

        var checkDeger = e.target.checked;
        var idDeger = e.target.id;

        var dizi = items.filter(p => p.id > 0);
        var updateList = [];
        dizi.forEach(element => {
            updateList.push({
                id: element.id,
                showOnLayout: element.showOnLayout
            });
        });
        
        var model = {
             willBeUpdateId: e.target.id.split('-')[1],
             currentLang: this.props.i18n.lang,
             langList: updateList  
        } 

        var response = await handleRequest("POST", "/LanguageType/update2", model);

        if (response.data !== null && response.data !== undefined) {
          if (response.data == "SUCCESS") {
            this.showSuccess(); 
          } 
          else if (response.data == "WARNING-01") {
            document.getElementById(idDeger).checked = !checkDeger;
            this.showWarning("The last Language Type cannot be unchecked !");
          }
          else if (response.data == "WARNING-02") {
            document.getElementById(idDeger).checked = !checkDeger;
            this.showWarning("There is an active language for this record. Please check !");
          }
          else {
            this.showError();
          }
        }
        else {
          this.showError();
        } 
    }

    handleChangeLanguageName = (e) => {
        e.preventDefault();
        var idValue = 0;
    
        for (var z = 0; z < this.state.LanguageTypeForOptions.length; z++) {
          if (this.state.LanguageTypeForOptions[z].name === e.target.value) {
            idValue = this.state.LanguageTypeForOptions[z].id;
          }
        }

        this.setState({
          [e.target.name]: e.target.value,
         // newLanguageNameId: idValue,
        });
    };

    saveLanguage = async () => {
        var data = this.state.ornekler; 
        const oldLanguageList = this.state.oldLanguageList;
         
        var birDizi = []; 

        data.forEach(element => {
            var keyList = Object.keys(element);

            keyList.forEach(key => {
                if (key != "stateId" && key != "page" && key != "id" && key != "formId") {

                    var obj = oldLanguageList.find(p => p.stateId == element.stateId && p.formId == element.formId && p.languageTypeId == key);

                    var formId=0;
                    formId= formIdBul(element.formId);

                    const newLanguageEntry = {
                        id: Boolean(obj) ? parseInt(obj.id) : 0,
                        form: element.page,
                        formId: formId,
                        stateId: element.stateId,
                        stateValue: element[key + ""],
                        languageTypeId: parseInt(key)
                    } 
                    birDizi.push(newLanguageEntry); 
                }
            });
        });
       
        console.log("DDDD", birDizi);
       
        var response = await handleRequest("POST", "/Language/saveAll", birDizi);
        if (response.data !== null && response.data !== undefined) {
            if (response.data == "SUCCESS") {
                this.showSuccess();
            }
            else {
            this.showError();
            }
        }
        else {
            this.showError();
        } 
    }
    
    getLanguageDisplayName = async () => {
        const response = await handleRequest("GET", "/LanguageType/getAllDistinct");
        if (response.data.length !== 0) {
            const columns = response.data;
      
            const options = columns.map(function (row) {
              return { name: row.name.toString(), id: row.id };
            });
      
            this.setState({
                LanguageTypeForOptions: options,
            });
          } else {
            this.setState({
                LanguageTypeForOptions: [],
            });
          }
    }

    handleChangeDisplayName = async (e) => {
        e.preventDefault(); 
        this.setState({ 
            displayName: e.target.value
        });

        if (Boolean(e.target.value)) {
        const kntDisplayName = {
            displayName: e.target.value 
        };

        var response = await handleRequest("POST", "/LanguageType/KontrolDisplayNameLang", kntDisplayName);
    
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") { 
            this.setState({ 
                hiddenDisplayNameKnt: true
            }); 
           } else if (response.data === "WARNING") { 
            this.setState({ 
                hiddenDisplayNameKnt: false
            }); 
           } 
           else {
            this.setState({ 
                hiddenDisplayNameKnt: false
            }); 
             this.showError(); 
           }  
        } else {
            this.setState({ 
                hiddenDisplayNameKnt: false
            }); 
            this.showError(); 
       }  
       } else {
        this.setState({ 
            hiddenDisplayNameKnt: true
        }); 
       }  
    };

    handleChangeLang = async (e) => {
        e.preventDefault(); 
        this.setState({ 
            lang: e.target.value
        });

        if (Boolean(e.target.value)) {
        const kntLang = {
            lang: e.target.value 
        };

        var response = await handleRequest("POST", "/LanguageType/KontrolDisplayNameLang", kntLang);
    
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") { 
            this.setState({ 
                hiddenLangKnt: true
            }); 
           } else if (response.data === "WARNING") { 
            this.setState({ 
                hiddenLangKnt: false
            }); 
           } 
           else {
            this.setState({ 
                hiddenLangKnt: false
            }); 
             this.showError(); 
           }  
        } else {
            this.setState({ 
                hiddenLangKnt: false
            }); 
            this.showError(); 
       }  
       } else {
        this.setState({ 
            hiddenLangKnt: true
        }); 
       }  
    };

    handleChangeForm = async (e) => {
        e.preventDefault(); 
        this.setState({ 
            form: e.target.value
        });   
    };

    handleChangeLanguageTypeId = (e) => {
        e.preventDefault();
        var idValue = 0;
    
        for (var z = 0; z < this.state.LanguageTypeForOptions.length; z++) {
          if (this.state.LanguageTypeForOptions[z].name === e.target.value) {
            idValue = this.state.LanguageTypeForOptions[z].id;
          }
        }

        this.setState({
          [e.target.name]: e.target.value,
           languageTypeId: idValue,
        });
    };

    handleChangeStateId = async (e) => {
        e.preventDefault(); 
        this.setState({ 
            stateId: e.target.value
        }); 

        if (Boolean(e.target.value)) {
            const kntFormKey = {
                stateId: e.target.value,
            };

        var response = await handleRequest("POST", "/Language/KontrolFormKey", kntFormKey);
        if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") { 
                this.setState({ 
                    hiddenKeyKnt: true
                }); 
               } else if (response.data === "WARNING") { 
                this.setState({ 
                    hiddenKeyKnt: false
                }); 
               } 
               else {
                this.setState({ 
                    hiddenKeyKnt: false
                }); 
                 this.showError(); 
               }  
            } else {
                this.setState({ 
                    hiddenKeyKnt: false
                }); 
                this.showError(); 
           }  
        }
        else {
            this.setState({ 
                hiddenKeyKnt: true
            }); 
        }
    };

    handleChangeStateValue = async (e) => {
        e.preventDefault(); 
        this.setState({ 
            stateValue: e.target.value
        }); 
    };

    downloadFile = async (url) => {
        window.location.href = url;
    }

    onDynamicMenuCascaderChange = (value) => {
        this.setState({
            formId: value
        })
    }

    onDynamicMenuFilterCascaderChange = (value) => {
        this.setState({
            filterPage: value,
        }) 

        if (Boolean(value) && value.length > 0) {
            this.restartTable(value[value.length - 1]);
        } else {
            this.restartTable(0);
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
    }

    render() {
        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
          };

        const options = {
            showTotal: true,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '20', value: 20
            }, {
                text: 'All', value: this.state.ornekler.length
            }]
        };

        let optionsLanguages = this.state.LanguageTypeForOptions.map((data) =>
            <option key={data.id} value={data.name}>
                 {data.name}
            </option>
        );
        
        return <div>
       
        <Modal
            show={this.state.modalMessage}
            onHide={this.showOrHideModalMessage}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <Row > 
                    <Col sm="12">
                    {this.state.messageTip == "Success" &&  
                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}
                    
                    {this.state.messageTip == "Warning" &&  
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                    {this.state.messageTip == "Error" &&  
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                    </Col>
                </Row>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row > 
                <Col sm="12">
                    {this.state.message} 
                </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
            </Modal.Footer>
        </Modal>
              
        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDeleteLanguageType}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
            This language record will be deleted. Are you sure?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteLanguageType}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteLanguageTypeOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>
         
        <Modal
            show={this.state.modalShow}
            onHide={this.showOrHideModal}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.languageTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="6">
                            Language Name:
                    </Form.Label>
                        <Col sm="6">
                            <Form.Control name="newLanguageName" value={this.state.newLanguageName} onChange={this.handleChangeLanguageName}
                                as="select">
                                <option value="">
                                    Select Language
                                </option>
                                {optionsLanguages}
                            </Form.Control>
                            {/*<InputText style={{ width: '100%' }} onChange={(e) => this.setState({ newLanguageName: e.target.value })} value={this.state.newLanguageName} />*/}
                        </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="6">
                            Display Name:
                    </Form.Label>
                        <Col sm="6">
                            <InputText style={{ width: '100%' }} onChange={this.handleChangeDisplayName} value={this.state.displayName} />
                        </Col>
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenDisplayNameKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Form.Label style={{ color: 'red' }} column sm="12">
                        The Display Name you want to add has been used before. Please use a different Display Name !
                    </Form.Label>
                    </Form.Group>

                    {/*  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ color: 'black' }} column sm="6">
                            Language Icon:
                    </Form.Label>
                        <Col sm="6">
                            <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ langIcon: e.target.value })} value={this.state.langIcon} />
                        </Col>
                    </Form.Group>*/}

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label style={{ color: 'black' }} column sm="6">
                            Display Short Name:
                    </Form.Label>
                        <Col sm="6">
                            <InputText style={{ width: '100%' }} maxLength="2" onChange={this.handleChangeLang} value={this.state.lang} />
                        </Col>
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenLangKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Form.Label style={{ color: 'red' }} column sm="12">
                        The Display Short Name you want to add has been used before. Please use a different Display Short Name !
                    </Form.Label>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="6"></Col>
                        <Col sm="2">
                        {this.state.iconPath != "" ? <img src={this.state.iconPath} alt={this.state.displayName} style={{ width: '100%' }} /> : <div>No Icon</div>}
                        </Col>
                    <Col sm="4">
                        <Upload {...props}
                        showUploadList={true}
                        fileList={this.state.IconUpload}
                        onChange={({ fileList }) => {
                            if (fileList.length == 1) {
                            var iconPath = "";
                            if (Boolean(fileList[0].response)) {
                                iconPath = fileList[0].response.url;
                            }
                            this.setState({
                                IconUpload: fileList,
                                iconPath: iconPath,
                            });
                            }
                        }}
                        beforeUpload={(fileList) => {
                            if (this.state.IconUpload.length > 0) {
                                this.showWarning("You have been already choose a icon! For change firstly remove the uploaded icon.");
                            return false;
                            }
                            return true;
                        }}
                        onRemove={({ fileList }) => {
                            this.setState({
                            IconUpload: [],
                            iconPath: "",
                            })
                        }
                        }>
                        <AntdButton>
                            <UploadOutlined /> Attach Icon
                        </AntdButton>
                        </Upload>
                    </Col>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideModal}>Close</ReactButton>
                <ReactButton variant={this.state.addUpdateButtonVariant} onClick={this.addUpdateNewLanguage}>{this.state.addUpdateButton}</ReactButton>
            </Modal.Footer>
        </Modal>
                
        <Modal
            show={this.state.modalCreateNewShow}
            onHide={this.showOrHideCreateNewModal}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            >

            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add New</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                            Form:
                    </Form.Label>
                        <Col sm="5">
                            <Cascader style={{ width: '100%' }} value={this.state.formId} 
                                options={this.state.dynamicMenuCascader} 
                                onChange={this.onDynamicMenuCascaderChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                        <Form.Label style={{ color: 'black' }} column sm="2">
                            Language:
                    </Form.Label>
                        <Col sm="5">
                        <Form.Control name="languageType" value={this.state.languageType} onChange={this.handleChangeLanguageTypeId}
                            as="select">
                            <option value="">
                                Select Language
                            </option>
                            {optionsLanguages}
                        </Form.Control>
                        </Col>
                    </Form.Group> 

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Form.Label style={{ color: 'black' }} column sm="2">
                            Key:
                    </Form.Label>
                        <Col sm="5">
                            <InputText style={{ width: '100%' }} onChange={this.handleChangeStateId} value={this.state.stateId} />
                        </Col>
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenKeyKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Form.Label style={{ color: 'red' }} column sm="12">
                        The Key you want to add has been used before. Please use a different Key !
                    </Form.Label>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Form.Label style={{ color: 'black' }} column sm="2">
                        Value:
                    </Form.Label>
                        <Col sm="5">
                            <InputText style={{ width: '100%' }} onChange={this.handleChangeStateValue} value={this.state.stateValue} />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideCreateNewModal}>Close</ReactButton>
                <ReactButton variant="info" onClick={this.createNewLanguage}>Add</ReactButton>
            </Modal.Footer>
        </Modal>
 
        <Form>
            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="2">
                    <Cascader style={{ width: '100%', marginTop: "1rem" }} value={this.state.filterPage} 
                        options={this.state.dynamicMenuCascader} 
                        onChange={this.onDynamicMenuFilterCascaderChange} />
                </Col>
                <Col sm="2">
                     <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.exportLanguage}>Export Data</ReactButton>
                </Col>  
                <Col sm="2">
                      <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block >Import Data</ReactButton>
                </Col> 
                <Col sm="2">
                    <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.showOrHideCreateNewModal}>Create New</ReactButton>
                </Col>
                <Col sm="2">
                    <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.showOrHideModal}>Add New Language</ReactButton>
                </Col>
                <Col sm="2">
                    <ReactButton variant="outline-success" style={{ marginTop: "1rem" }} block onClick={this.saveLanguage}>Save Changes</ReactButton>
                </Col> 
            </Form.Group>
        </Form>
        
        <ToolkitProvider
            keyField="id"
            data={this.state.ornekler}
            columns={this.state.columns}
            bootstrap4>
            {
                props => (
                    <div style={{ backgroundColor: "white", padding: "10px" }}>
                        {/* <Form>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="6"></Col>   
                                <Col sm="2">
                                <PrimeButton icon="pi pi-plus" type='button' onClick={this.showOrHideCreateNewModal} style={{ width: '100%' }} id='add-language' className="p-button-info" label="Create New" />
                                </Col>
                                <Col sm="2">
                                    <PrimeButton icon="pi pi-plus" type='button' onClick={this.showOrHideModal} style={{ width: '100%' }} id='add-language' className="p-button-info" label="Add New Language" />
                                    </Col>
                                <Col sm="1"></Col>
                            </Form.Group>
                        </Form> */}
                        <BootstrapTable style={{ overflowX: 'auto' }} striped hover bordered keyField='id' cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })} data={this.state.ornekler} columns={this.state.columns} pagination={paginationFactory(options)}
                            {...props.baseProps} />

                    </div>
                )
            }
        </ToolkitProvider> 
    
            </div> 
          
    }
}

const mapStateToProps = state => {
    return {
    auth: state.auth,
    i18n: state.i18n
    }
}
  
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings) 