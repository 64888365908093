import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Cascader } from 'antd';
import 'antd/dist/antd.css';
import { Button, Modal, Input, message } from 'antd';
import { handleRequest } from '../../redux/config/env';



const text = `Parent : `;
export class CascaderEditText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandIconPosition: 'left',
            loading: false,
            visible: false,
            nodes: [],
            values: [],
            selectedItems: [],
            newItem: '',
            parentId: 0
        }
    }

    onChange = (value, data) => {

        this.setState({
            values: value,
            selectedItems: data
        })
        console.log(value);
        console.log(data);
    }

    showModal = () => {
        this.setState({
            visible: true,
            newItem: ''
        });
    };

    handleOk = () => {
        this.setState({ loading: true });
        const length = this.state.values.length;

        if (length > 0)
            this.setState({ parentId: this.state.values[length - 1] });

        setTimeout(() => {

            this.setState({ loading: false, visible: false });
            this.saveItem();

        }, 1000);
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.resetInputs();
    };

    componentDidMount = async () => {

        this.getNodes();

    }

    getNodes = async () => {

        var response = [];

        response = await handleRequest("GET", "/DocumentLog/getNodesDocumentLogs");

        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                nodes: []

            });
        } else
            if (response.data.length !== 0) {

                this.setState({
                    nodes: response.data
                });
            }

    }

    saveItem = async () => {

        if (this.state.newItem.trim() !== "") {

            const newObj = {

                documentType: this.state.newItem,
                parentId: this.state.parentId

            }

            await handleRequest("POST", "/DocumentLog/add", newObj);


            message.success('New item added successfully');
            this.getNodes();
            this.resetInputs();
        } else {
            message.error('Please type input.');
        }
    };

    onChangeInput = ({ target: { value } }) => {
        console.log(value);
        this.setState({ newItem: value });
    };

    resetInputs = () => {
        this.setState({ parentId: 0, newItem: '' });
    }

    render() {
        return (
            <div>
                <Cascader options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                <Button onClick={this.showModal} type="primary">Add New</Button>

                <Modal
                    visible={this.state.visible}
                    title="Add New Item"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk}>
                            Add
                         </Button>
                    ]}
                >
                    {this.state.values.length > 0 && <p> {text} {this.state.selectedItems[this.state.values.length - 1].label} </p>}
                    <Input value={this.state.newItem} onChange={this.onChangeInput} placeholder="Type new item here .." />
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CascaderEditText) 
