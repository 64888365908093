/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "EmployeesSalary";


export class EmployeesSalary extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: 0,
            tableList: [],
            filePath: '',
            fileList: [],


            userSelectItems: [],
            staffId: null,
            date: null,
            year: null,
            month: null,
            timeIn: null,
            timeOut: null,
            wokingHours: null,
            overtime: null,
            pagination: {
                current: 1,
                pageSize: 10,
            },
            modalStaff: false,
            selectedContract: null,
            //[STATE_FIELDS], 

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();
    formRefModal = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.setComboboxes();
        const { pagination } = this.state;
        this.restartTable({ pagination });


    }

    disabledTimeIn = (current) => {
        // Can not select days before today and today
        if (Boolean(this.state.date))
            return current < this.state.date;
        else return false;

    }

    disabledTimeOut = (current) => {
        // Can not select days before today and today
        if (Boolean(this.state.timeIn))
            return current < this.state.timeIn;
        else return false;

    }


    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/Staff/getallByCompany");
        if (resDepList.data.length > 0) {

            this.setState({
                userSelectItems: resDepList.data
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }



    }

    save = async (fieldsValue) => {

    }

    edit = (row) => {


    }


    delete = async () => {


    }

    deleteModal = (row) => {

    }

    showOrHideDeleteModal = () => {

    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }

    handleTableChange = (pagination, filters, sorter) => {
        this.restartTable({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters });
    }

    restartTable = async (params = {}) => {

        const obj = {
            current: params.pagination.current,
            pageSize: params.pagination.pageSize,
        }

        const response = await handleRequest("POST", "/SalarySetting/getEmployeesSalary", obj);
        var year = this.state.year;
        var month = this.state.month;
        var staffId = this.state.staffId;
        if (response.data.length !== 0) {
            var list = response.data;

            list.forEach((element, index) => {
                element.key = index;
                element.index = (obj.current - 1) * obj.pageSize + index + 1;
            });
            console.log(list);

            this.setState({
                tableList: list,
                pagination: {
                    ...params.pagination,
                    total: list[0].total,
                },
            });
        }
        // if (response.data.length !== 0 && Boolean(year) && Boolean(month) && Boolean(staffId)) {
        //     var list = response.data;

        //     console.log(year);
        //     console.log(month);

        //     list = list.filter(p=>moment(p.date).year == year.year && moment(p.date).month==month.month && p.staffId==staffId ) 
        //     list.forEach((element,index) => {
        //         element.key = element.id;
        //         element.index = index+1; 
        //     });
        //     this.setState({
        //         tableList: list,
        //     });
        // }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: pageName });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: pageName
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }


        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            staffId: null,
            date: null,
            timeIn: null,
            timeOut: null,
            wokingHours: null,
            overtime: null,
        });

        this.setState({
            id: 0,
            staffId: null,
            date: null,
            timeIn: null,
            timeOut: null,
            wokingHours: null,
            overtime: null,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    render() {





        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return  <div>

                <MenuProvider id="menu_id">
                    <Modal
                        show={this.state.modalStaff}
                        onHide={() => {
                            this.setState({
                                modalStaff: false,
                                selectedContract: null,
                            })
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Contract Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Boolean(this.state.selectedContract) ? (
                                <>  <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="4">
                                        <Form.Label> {<FormattedMessage id="EmployeeSalary.BasicSalary" defaultMessage="Basic Salary" />} </Form.Label>
                                    </Col>
                                    <Col sm="2">
                                        <Form.Label>   {this.state.selectedContract.basicSalary}  </Form.Label>

                                    </Col>
                                </Form.Group>
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="4">
                                            <Form.Label>{<FormattedMessage id="EmployeeSalary.homeAllowance" defaultMessage="Home Allowance" />} </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label>   {this.state.selectedContract.homeAllowance}  </Form.Label>

                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="4">
                                            <Form.Label>
                                                {<FormattedMessage id="EmployeeSalary.transportationAllowance" defaultMessage="Transportation Allowance" />}
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label>   {this.state.selectedContract.transportationAllowance}  </Form.Label>

                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="4">
                                            <Form.Label>
                                                {<FormattedMessage id="EmployeeSalary.foodAllowance" defaultMessage="Food Allowance" />}
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2"> 
                                            <Form.Label>   {this.state.selectedContract.foodAllowance}  </Form.Label>

                                        </Col>
                                    </Form.Group>
                                </>
                            ) : <div>
                                    No contract defined for selected user.
                                </div>}

                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={() => {
                                this.setState({
                                    modalStaff: false,
                                    selectedContract: null,
                                })
                            }}>Close</ReactButton>

                        </Modal.Footer>
                    </Modal>


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                </Col>
                            </Row>
                        </div>


                        <div style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {<Table style={{ marginTop: '3rem' }} size={`small`} pagination={this.state.pagination} onChange={this.handleTableChange} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Year" dataIndex="year" />
                            <Column title="Month" render={(value, row, index) => {

                                return moment().month(row.month - 1).format('MMMM');

                            }} />
                            <Column title="Period" render={(value, row, index) => {

                                var dayFirst = moment().month(row.month - 1).format('MMM'); //  MonthBulKst(row.month);          
                                return row.firstDay + " " + dayFirst + " - " + row.lastDay + " " + dayFirst;

                            }} />
                            <Column title="Department" render={(value, row, index) => {

                                if (Boolean(row.staff) && Boolean(row.staff.department))
                                    return row.staff.department.departmentName;

                            }} />
                            <Column title="Designation" render={(value, row, index) => {

                                if (Boolean(row.staff) && Boolean(row.staff.designation))
                                    return row.staff.designation.designationName;


                            }} />

                            <Column title="Name" render={(value, row, index) => {

                                if (Boolean(row.staff))
                                    return <div
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                            let contract = null;
                                            if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
                                                contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];

                                            }
                                            this.setState({
                                                selectedContract: contract,
                                                modalStaff: true,
                                            })
                                        }
                                        }
                                    >
                                        {row.staff.fullName}
                                    </div>


                            }} />

                            <Column title="Working Days" render={(value, row, index) => {

                                return row.countTimesheetLog;

                            }} />

                            <Column title="Salary" render={(value, row, index) => {

                                let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
                                if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
                                    let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
                                    if (Boolean(contract.basicSalary))
                                        return (contract.basicSalary * x).toFixed(2);
                                }

                            }} />



                            <Column title="Home Allowance" render={(value, row, index) => {

                                let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
                                if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
                                    let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
                                    if (Boolean(contract.homeAllowance))
                                        return (contract.homeAllowance * x).toFixed(2);
                                }

                            }} />

                            <Column title="Transportation Allowance" render={(value, row, index) => {

                                let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
                                if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
                                    let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
                                    if (Boolean(contract.transportationAllowance))
                                        return (contract.transportationAllowance * x).toFixed(2);
                                }

                            }} />

                            <Column title="Food Allowance" render={(value, row, index) => {

                                let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
                                if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
                                    let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
                                    if (Boolean(contract.foodAllowance))
                                        return (contract.foodAllowance * x).toFixed(2);
                                }

                            }} />

                            <Column title="Overtime(hours)" render={(value, row, index) => {

                                if (Boolean(row.sumOvertime))
                                    return row.sumOvertime;

                            }} />
                            <Column title="Overtime" render={(value, row, index) => {

                                let A = (row.lastDay - row.firstDay + 1);
                                if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0 && Boolean(row.sumOvertime)) {
                                    let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
                                    if (Boolean(contract.basicSalary))
                                        return (row.sumOvertime * 1.5 * (contract.basicSalary / (A * 8))).toFixed(2);
                                }

                            }} />

                            <Column title="Total of Payment" render={(value, row, index) => {

                                let A = (row.lastDay - row.firstDay + 1);
                                let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
                                if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0 && Boolean(row.sumOvertime)) {
                                    let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
                                    if (Boolean(contract.basicSalary) && Boolean(contract.homeAllowance) && Boolean(contract.transportationAllowance) && Boolean(contract.foodAllowance))
                                        return ((contract.foodAllowance * x) + (contract.transportationAllowance * x) + (contract.homeAllowance * x) + (contract.basicSalary * x) + (row.sumOvertime * 1.5 * (contract.basicSalary / (A * 8)))).toFixed(2);
                                }

                            }} />



                            {/* 
                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} /> */}
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesSalary) 
