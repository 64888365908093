import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Button as ReactButton, Col, Row, Modal, Form as FormBoot } from 'react-bootstrap';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Button as AntButton, Divider, DatePicker, Input, Cascader } from 'antd';
import { InputNumber, Space, Select, Table, Radio, Form, Collapse } from 'antd';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import moment from 'moment';

const { Option } = Select;
const { Column } = Table;
const { Panel } = Collapse;

var selectedUserOptions = null;
var departmentId = null;
var designationId = null;

var _companyExpensesRows = [
    {
        companyExpensesIndex: 1,
        nameOfExpense: "Company Support",
        amount: "",
        currency: ""
    }
];

export class BereavementNoticeForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedBereavementNoticeForm: null,
            bereavements: [],
            reasonOfDeaths: [],
            departmentCascader: [],
            personelType: "",
            values: [],
            selectedUserOptions: [],
            staffs: [],
            staffId: null,
            idNo: null,
            nameOfDeceased: "",
            relativeType: "",
            relation: "",
            dateOfDeath: null,
            reasonOfDeath: "",
            reasonOfDeathId: null,
            newReasonOfDeath: "",
            viewingDateTime: null,
            viewingPlace: "",
            viewingAddress: "",
            wakeDateTime: null,
            wakePlace: "",
            wakeAddress: "",
            intermentDateTime: null,
            intermentPlace: "",
            intermentAddress: "",
            funeralDateTime: null,
            funeralPlace: "",
            funeralAddress: "",
            funeralComments: "",
            expressionOfSympathyOrganization: "",
            expressionOfSympathyAddress: "",
            expressionOfSympathyContact: "",
            companyExpensesRows: [],
            companyExpensesRowsBackup: [],
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "BereavementNoticeForm" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "BereavementNoticeForm",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "BereavementNoticeForm");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {

        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            companyExpensesRows: _companyExpensesRows,
            viewingDateTime: today,
            wakeDateTime: today,
            intermentDateTime: today,
            funeralDateTime: today
        });

        const responseCascader = await handleRequest("GET", "/Department/getNodes3");

        if (responseCascader.data.length !== 0) {
            this.setState({
                departmentCascader: responseCascader.data
            });
        }

        this.getUsers();
        this.getCodesReasonOfDeath();
        this.restartTable();
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/BereavementNoticeForm/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                bereavements: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                bereavements: response.data,
            });
        }
    }

    resetInputs = () => {
        this.formRef.current.resetFields();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _companyExpensesRows = [
            {
                companyExpensesIndex: 1,
                nameOfExpense: "Company Support",
                amount: "",
                currency: ""
            }
        ];

        this.setState({
            hidden: true,
            personelType: "",
            values: [],
            selectedUserOptions: [],
            staffs: [],
            staffId: null,
            idNo: null,
            nameOfDeceased: "",
            relativeType: "",
            relation: "",
            dateOfDeath: today,
            reasonOfDeath: "",
            reasonOfDeathId: null,
            newReasonOfDeath: "",
            viewingDateTime: today,
            viewingPlace: "",
            viewingAddress: "",
            wakeDateTime: today,
            wakePlace: "",
            wakeAddress: "",
            intermentDateTime: today,
            intermentPlace: "",
            intermentAddress: "",
            funeralDateTime: today,
            funeralPlace: "",
            funeralAddress: "",
            funeralComments: "",
            expressionOfSympathyOrganization: "",
            expressionOfSympathyAddress: "",
            expressionOfSympathyContact: "",
            companyExpensesRows: _companyExpensesRows,
            companyExpensesRowsBackup: []
        });
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedBereavementNoticeForm: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteBereavementNoticeFormOK = async () => {
        const deleteBereavementNoticeForm = {
            id: this.state.selectedBereavementNoticeForm.id
        }
        var response = await handleRequest("POST", "/BereavementNoticeForm/delete", deleteBereavementNoticeForm);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    createNew = async () => {
        if (this.state.hidden) {
            this.formRef.current.resetFields();

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            _companyExpensesRows = [
                {
                    companyExpensesIndex: 1,
                    nameOfExpense: "Company Support",
                    amount: "",
                    currency: ""
                }
            ];

            this.setState({
                hidden: false,
                personelType: "",
                values: [],
                selectedUserOptions: [],
                staffs: [],
                staffId: null,
                idNo: null,
                nameOfDeceased: "",
                relativeType: "",
                relation: "",
                dateOfDeath: today,
                reasonOfDeath: "",
                reasonOfDeathId: null,
                newReasonOfDeath: "",
                viewingDateTime: today,
                viewingPlace: "",
                viewingAddress: "",
                wakeDateTime: today,
                wakePlace: "",
                wakeAddress: "",
                intermentDateTime: today,
                intermentPlace: "",
                intermentAddress: "",
                funeralDateTime: today,
                funeralPlace: "",
                funeralAddress: "",
                funeralComments: "",
                expressionOfSympathyOrganization: "",
                expressionOfSympathyAddress: "",
                expressionOfSympathyContact: "",
                companyExpensesRows: _companyExpensesRows
            });
        } else {
            this.resetInputs();
        }
    }

    stopProcess = () => {
        this.resetInputs();
    }

    saveBereavementNotice = async () => {
        let flagCompanyExpenses = [];

        for (let index = 0; index < this.state.companyExpensesRows.length; index++) {
            flagCompanyExpenses.push({
                companyExpensesType: this.state.companyExpensesRows[index].nameOfExpense,
                companyExpensesValue: this.state.companyExpensesRows[index].amount,
                companyExpensesCurrency: this.state.companyExpensesRows[index].currency,
            });
        }

        const addBereavementNoticeForm = {
            personelType: this.state.personelType,
            cascaderValue: this.state.values.join(),
            staffId: this.state.staffId,
            nameOfDeceased: this.state.nameOfDeceased,
            relativeType: this.state.relativeType,
            relation: this.state.relation,
            dateOfDeath: this.state.dateOfDeath,
            reasonOfDeathId: this.state.reasonOfDeathId,
            viewingDateTime: this.state.viewingDateTime,
            viewingPlace: this.state.viewingPlace,
            viewingAddress: this.state.viewingAddress,
            wakeDateTime: this.state.wakeDateTime,
            wakePlace: this.state.wakePlace,
            wakeAddress: this.state.wakeAddress,
            intermentDateTime: this.state.intermentDateTime,
            intermentPlace: this.state.intermentPlace,
            intermentAddress: this.state.intermentAddress,
            funeralDateTime: this.state.funeralDateTime,
            funeralPlace: this.state.funeralPlace,
            funeralAddress: this.state.funeralAddress,
            funeralComments: this.state.funeralComments,
            expressionOfSympathyOrganization: this.state.expressionOfSympathyOrganization,
            expressionOfSympathyAddress: this.state.expressionOfSympathyAddress,
            expressionOfSympathyContact: this.state.expressionOfSympathyContact,
            bereavementNoticeFormCompanyExpenses: flagCompanyExpenses
        }
        var response = await handleRequest("POST", "/BereavementNoticeForm/add", addBereavementNoticeForm);
        if (response.data !== null && response.data !== undefined) {
            if (response.data !== "0" && response.data !== 0) {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }
    }

    updateBereavementNotice = async () => {
        if (this.state.selectedBereavementNoticeForm !== null) {
            let flagCompanyExpenses = [];

            for (let index = 0; index < this.state.companyExpensesRows.length; index++) {
                if (this.state.companyExpensesRows[index].id !== undefined) {
                    if (this.state.companyExpensesRows[index].id !== null) {
                        flagCompanyExpenses.push({
                            id: this.state.companyExpensesRows[index].id,
                            active: true,
                            companyExpensesType: this.state.companyExpensesRows[index].nameOfExpense,
                            companyExpensesValue: this.state.companyExpensesRows[index].amount,
                            companyExpensesCurrency: this.state.companyExpensesRows[index].currency
                        });
                    }
                } else {
                    flagCompanyExpenses.push({
                        companyExpensesType: this.state.companyExpensesRows[index].nameOfExpense,
                        companyExpensesValue: this.state.companyExpensesRows[index].amount,
                        companyExpensesCurrency: this.state.companyExpensesRows[index].currency
                    });
                }
            }

            for (let index = 0; index < this.state.companyExpensesRows.length; index++) {
                let whichIndexCompanyExpense = this.state.companyExpensesRows.findIndex(i => Boolean(i.id) && i.id === this.state.companyExpensesRowsBackup[index].id);

                if (whichIndexCompanyExpense === -1) {
                    flagCompanyExpenses.push({
                        id: this.state.companyExpensesRowsBackup[index].id,
                        active: false,
                        companyExpensesType: this.state.companyExpensesRows[index].companyExpensesType,
                        companyExpensesValue: this.state.companyExpensesRows[index].companyExpensesValue,
                        companyExpensesCurrency: this.state.companyExpensesRows[index].companyExpensesCurrency
                    });
                }
            }

            const updatedBereavementNoticeForm = {
                id: this.state.selectedBereavementNoticeForm.id,
                personelType: this.state.personelType,
                cascaderValue: this.state.values.join(),
                staffId: this.state.staffId,
                nameOfDeceased: this.state.nameOfDeceased,
                relativeType: this.state.relativeType,
                relation: this.state.relation,
                dateOfDeath: this.state.dateOfDeath,
                reasonOfDeathId: this.state.reasonOfDeathId,
                viewingDateTime: this.state.viewingDateTime,
                viewingPlace: this.state.viewingPlace,
                viewingAddress: this.state.viewingAddress,
                wakeDateTime: this.state.wakeDateTime,
                wakePlace: this.state.wakePlace,
                wakeAddress: this.state.wakeAddress,
                intermentDateTime: this.state.intermentDateTime,
                intermentPlace: this.state.intermentPlace,
                intermentAddress: this.state.intermentAddress,
                funeralDateTime: this.state.funeralDateTime,
                funeralPlace: this.state.funeralPlace,
                funeralAddress: this.state.funeralAddress,
                funeralComments: this.state.funeralComments,
                expressionOfSympathyOrganization: this.state.expressionOfSympathyOrganization,
                expressionOfSympathyAddress: this.state.expressionOfSympathyAddress,
                expressionOfSympathyContact: this.state.expressionOfSympathyContact,
                bereavementNoticeFormCompanyExpenses: flagCompanyExpenses
            }
            var response2 = await handleRequest("POST", "/BereavementNoticeForm/update", updatedBereavementNoticeForm);
            if (response2.data !== null && response2.data !== undefined) {
                if (response2.data === "SUCCESS") {
                    this.resetInputs();
                    this.setState({
                        selectedBereavementNoticeForm: null,
                        hiddenSave: true,
                        hiddenUpdate: false
                    });
                    this.restartTable();
                    this.showSuccess();
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    changeButtonUpdate = (row) => {
        this.formRef.current.setFieldsValue({
            BereavementNoticeFormType: row.personelType,
            DepartmentDesignation: row.cascaderValue.split(","),
            NameOfDeceased: row.nameOfDeceased,
            RelativeType: row.relativeType,
            Relation: row.relation,
            DateOfDeath: moment(row.dateOfDeath, 'DD/MM/YYYY'),
            ReasonOfDeath: row.codeReasonOfDeath.name
        });

        if (row.staff !== undefined) {
            this.formRef.current.setFieldsValue({
                EmployeeRetiree: row.staff.name + " " + row.staff.midName + " " + row.staff.lastName,
                IdNo: row.staff.nationalId
            });
        }

        let flagCompanyExpenses = [];

        for (let index = 0; index < row.bereavementNoticeFormCompanyExpenses.length; index++) {
            flagCompanyExpenses.push({
                id: row.bereavementNoticeFormCompanyExpenses[index].id,
                active: row.bereavementNoticeFormCompanyExpenses[index].active,
                companyExpensesIndex: index + 1,
                nameOfExpense: row.bereavementNoticeFormCompanyExpenses[index].companyExpensesType,
                amount: row.bereavementNoticeFormCompanyExpenses[index].companyExpensesValue,
                currency: row.bereavementNoticeFormCompanyExpenses[index].companyExpensesCurrency
            });
        }

        if (flagCompanyExpenses.length === 0) {
            flagCompanyExpenses.push({
                companyExpensesIndex: 1,
                nameOfExpense: "Company Support",
                amount: "",
                currency: ""
            });
        }

        _companyExpensesRows = flagCompanyExpenses;

        this.setState({
            selectedBereavementNoticeForm: row,
            personelType: row.personelType,
            values: row.cascaderValue.split(","),
            staffId: row.staffId,
            idNo: row.staffId,
            nameOfDeceased: row.nameOfDeceased,
            relativeType: row.relativeType,
            relation: row.relation,
            dateOfDeath: row.dateOfDeath,
            reasonOfDeathId: row.codeReasonOfDeath.id,
            reasonOfDeath: row.codeReasonOfDeath.name,
            viewingDateTime: moment(row.viewingDateTime),
            viewingPlace: row.viewingPlace,
            viewingAddress: row.viewingAddress,
            wakeDateTime: moment(row.wakeDateTime),
            wakePlace: row.wakePlace,
            wakeAddress: row.wakeAddress,
            intermentDateTime: moment(row.intermentDateTime),
            intermentPlace: row.intermentPlace,
            intermentAddress: row.intermentAddress,
            funeralDateTime: moment(row.funeralDateTime),
            funeralPlace: row.funeralPlace,
            funeralAddress: row.funeralAddress,
            funeralComments: row.funeralComments,
            expressionOfSympathyOrganization: row.expressionOfSympathyOrganization,
            expressionOfSympathyAddress: row.expressionOfSympathyAddress,
            expressionOfSympathyContact: row.expressionOfSympathyContact,
            companyExpensesRowsBackup: row.bereavementNoticeFormCompanyExpenses,
            companyExpensesRows: flagCompanyExpenses,
            hiddenSave: true,
            hiddenUpdate: false,
            hidden: false
        });
    }

    changeButtonCancel = () => {
        this.formRef.current.resetFields();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _companyExpensesRows = [
            {
                companyExpensesIndex: 1,
                nameOfExpense: "Company Support",
                amount: "",
                currency: ""
            }
        ];

        this.setState({
            hidden: true,
            selectedBereavementNoticeForm: null,
            personelType: "",
            values: [],
            selectedUserOptions: [],
            staffs: [],
            staffId: null,
            idNo: null,
            nameOfDeceased: "",
            relativeType: "",
            relation: "",
            dateOfDeath: today,
            reasonOfDeath: "",
            reasonOfDeathId: null,
            newReasonOfDeath: "",
            viewingDateTime: today,
            viewingPlace: "",
            viewingAddress: "",
            wakeDateTime: today,
            wakePlace: "",
            wakeAddress: "",
            intermentDateTime: today,
            intermentPlace: "",
            intermentAddress: "",
            funeralDateTime: today,
            funeralPlace: "",
            funeralAddress: "",
            funeralComments: "",
            expressionOfSympathyOrganization: "",
            expressionOfSympathyAddress: "",
            expressionOfSympathyContact: "",
            companyExpensesRows: _companyExpensesRows,
            hiddenSave: false,
            hiddenUpdate: true,
            hidden: true
        });
    }

    onStaffChange = (value) => {
        this.setState({
            staffId: value,
            idNo: value
        });

        let flagStaff = this.state.staffs;

        for (let index = 0; index < flagStaff.length; index++) {
            if (flagStaff[index].id === value) {
                this.formRef.current.setFieldsValue({
                    EmployeeRetiree: flagStaff[index].name + " " + flagStaff[index].midName + " " + flagStaff[index].lastName,
                    IdNo: flagStaff[index].nationalId
                });
            }
        }
    }

    onIdNoChange = (value) => {
        this.setState({
            EmployeeRetiree: value,
            IdNo: value
        });

        let flagStaff = this.state.staffs;

        for (let index = 0; index < flagStaff.length; index++) {
            if (flagStaff[index].id === value) {
                this.formRef.current.setFieldsValue({
                    EmployeeRetiree: flagStaff[index].name + " " + flagStaff[index].midName + " " + flagStaff[index].lastName,
                    IdNo: flagStaff[index].nationalId
                });
            }
        }
    }

    handleChangeRelation = (value) => {
        this.setState({
            relation: value
        });
    }

    onChangeDateOfDeath = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                dateOfDeath: dateString
            });
        } else {
            this.setState({
                dateOfDeath: today
            });
        }
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    disabledDate1 = (current) => {
        return current > moment().endOf('day');
    }

    onChangeViewingDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                viewingDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                viewingDateTime: today
            });
        }
    }

    onChangeWakeDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                wakeDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                wakeDateTime: today
            });
        }
    }

    onChangeIntermentDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                intermentDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                intermentDateTime: today
            });
        }
    }

    onChangeFuneralDateTime = async (date, dateString) => {
        if (date !== null) {
            date._d.setSeconds(0);

            this.setState({
                funeralDateTime: date._d
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy + ' 00:00:00';

            this.setState({
                funeralDateTime: today
            });
        }
    }

    handleChangeReasonOfDeath = (e) => {
        var value = "";
        for (var z = 0; z < this.state.reasonOfDeaths.length; z++) {
            if (this.state.reasonOfDeaths[z].id == e) {
                value = this.state.reasonOfDeaths[z].name;
            }
        }
        this.setState({
            reasonOfDeath: value,
            reasonOfDeathId: e,
        });
    }

    addReasonOfDeath = async () => {
        const { newReasonOfDeath } = this.state;
        if (newReasonOfDeath != null && newReasonOfDeath != "" && newReasonOfDeath != undefined) {
            const newItem = {
                name: newReasonOfDeath,
                type: "ReasonOfDeath"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newReasonOfDeath: ""
            });

            this.getCodesReasonOfDeath();
        } else {
            this.showWarning();
        }
    }

    getCodesReasonOfDeath = async () => {
        const response = await handleRequest("GET", "/Code/type/ReasonOfDeath");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                reasonOfDeaths: response.data
            });
        }
    }

    addNewCompanyExpensesLine = () => {
        if (this.state.companyExpensesRows.length > 1)
            if (this.state.companyExpensesRows[this.state.companyExpensesRows.length - 1].nameOfExpense !== ""
                && this.state.companyExpensesRows[this.state.companyExpensesRows.length - 1].amount.toString() !== ""
                && this.state.companyExpensesRows[this.state.companyExpensesRows.length - 1].currency !== "") {
                _companyExpensesRows.push(
                    {
                        companyExpensesIndex: _companyExpensesRows.length + 1,
                        nameOfExpense: "",
                        amount: "",
                        currency: ""
                    }
                );
            }

        if (this.state.companyExpensesRows.length === 1) {
            _companyExpensesRows.push(
                {
                    companyExpensesIndex: _companyExpensesRows.length + 1,
                    nameOfExpense: "",
                    amount: "",
                    currency: ""
                }
            );
        }

        this.setState({
            companyExpensesRows: _companyExpensesRows
        });
    }

    removeCompanyExpensesLine = (index, e) => {
        _companyExpensesRows.splice(index, 1);

        this.setState({
            companyExpensesRows: _companyExpensesRows
        });
    }

    handleChangeCompanyExpensesNameOfExpense = (index, e) => {
        let list = this.state.companyExpensesRows;
        list[index].nameOfExpense = e.target.value;

        this.setState({
            companyExpensesRows: list.slice()
        });
    }

    handleChangeCompanyExpensesAmount = (index, value) => {
        let list = this.state.companyExpensesRows;
        list[index].amount = value;

        this.setState({
            companyExpensesRows: list.slice()
        });
    }

    handleChangeCompanyExpensesCurrency = (index, value) => {
        let list = this.state.companyExpensesRows;
        list[index].currency = value;

        this.setState({
            companyExpensesRows: list.slice()
        });
    }

    getUsers = async () => {
        const staffModel = {
            departmentId: departmentId,
            disciplineId: "",
            designationId: designationId,
            nameLastName: ""
        };

        const responseStaffs = await handleRequest("POST", "/Staff/getCompanyItemsBySearch2", staffModel);

        console.log(responseStaffs);

        if (responseStaffs.data.length !== 0) {
            this.setState({
                staffs: responseStaffs.data
            });
        }

        if (responseStaffs.data.length === 0) {
            this.setState({
                staffs: []
            });
        }
    }

    onDepartmentCascaderChange = (value, selectedOptions) => {
        this.setState({
            values: value,
            selectedUserOptions: selectedOptions
        });
        selectedUserOptions = selectedOptions;

        if (value.length === 0) {
            departmentId = null;
            designationId = null;
            this.getUsers();
        }
    }

    cascaderClose = (value) => {
        if (!value && this.state.selectedUserOptions.length !== 0 && this.state.values.length !== 0) {
            for (let index = 0; index < selectedUserOptions.length; index++) {
                if (selectedUserOptions[index].key.toString() === "department")
                    departmentId = selectedUserOptions[index].value;
                if (selectedUserOptions[index].key.toString() === "designation")
                    designationId = selectedUserOptions[index].value;
            }
            this.getUsers();
        }

        if (!value && (this.state.values.length === 0 || this.state.selectedUserOptions.length === 0)) {
            this.getUsers();
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        var personelTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PersonelType");
        var departmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Department");
        var employeeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Employee");
        var idNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "IdNo");
        var nameOfDeceasedAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NameOfDeceased");
        var relativeTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RelativeType");
        var relationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Relation");
        var dateOfDeathAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DateOfDeath");
        var reasonOfDeathAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ReasonOfDeath");

        var personelTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "PersonelType");
        var departmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "Department");
        var employeeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Employee");
        var idNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "IdNo");
        var nameOfDeceasedRequired = this.state.dynamicInputs.find(p => p.inputKey === "NameOfDeceased");
        var relativeTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "RelativeType");
        var relationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Relation");
        var dateOfDeathRequired = this.state.dynamicInputs.find(p => p.inputKey === "DateOfDeath");
        var reasonOfDeathRequired = this.state.dynamicInputs.find(p => p.inputKey === "ReasonOfDeath");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 },
        };

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteBereavementNoticeFormOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="BereavementNoticeFormCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="BereavementNoticeFormStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                            {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Form
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdate == false ? this.updateBereavementNotice : this.saveBereavementNotice}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef} >

                                {(personelTypeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormType" defaultMessage="Personel Type" />}
                                        name="BereavementNoticeFormType"
                                        rules={[{ required: personelTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(personelTypeRequired.translateRequired) == true ? personelTypeRequired.translateRequired : -1} defaultMessage="Please select a Personel Type!" /> }]} >
                                        <Radio.Group onChange={(e) => {
                                            this.setState({
                                                personelType: e.target.value
                                            });
                                        }}
                                            value={this.state.personelType}>
                                            <Radio value={"Employee"}><FormattedMessage id="BereavementNoticeFormEmployee" defaultMessage="Employee" /></Radio>
                                            <Radio value={"Retiree"}><FormattedMessage id="BereavementNoticeFormRetiree" defaultMessage="Retiree" /></Radio>
                                        </Radio.Group>
                                    </Form.Item>}

                                {(departmentAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormDepartment" defaultMessage="Department/Designation" />}
                                        name="DepartmentDesignation"
                                        rules={[{ required: departmentRequired.canBeRequired, message: <FormattedMessage id={Boolean(departmentRequired.translateRequired) == true ? departmentRequired.translateRequired : -1} defaultMessage="Please select a Department/Designation!" /> }]} >
                                        {/** <Select size='large'
                                            style={{ width: '100%' }}
                                            value={this.state.departmentId}
                                            onChange={this.onDepartmentChange}
                                        >
                                            {this.state.departments.map(department => (
                                                <Option key={department.id} value={department.id}>{
                                                    department.departmentName
                                                }</Option>
                                            ))}
                                            </Select> **/}

                                        <Cascader
                                            id="DepartmentDesignation"
                                            style={{ width: '100%' }}
                                            options={this.state.departmentCascader}
                                            value={this.state.values}
                                            onChange={this.onDepartmentCascaderChange}
                                            onPopupVisibleChange={this.cascaderClose}
                                            changeOnSelect
                                        />


                                    </Form.Item>}

                                {(employeeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormEmployee" defaultMessage="Name of Employee/Retiree" />}
                                        name="EmployeeRetiree"
                                        rules={[{ required: employeeRequired.canBeRequired, message: <FormattedMessage id={Boolean(employeeRequired.translateRequired) == true ? employeeRequired.translateRequired : -1} defaultMessage="Please select an Eemployee/Retiree!" /> }]} >
                                        <Select size='large'
                                            style={{ width: '100%' }}
                                            showSearch
                                            value={this.state.staffId}
                                            onChange={this.onStaffChange}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.staffs.map(staff => (
                                                <Option key={staff.id} value={staff.id}>{staff.name + " " + staff.midName + " " + staff.lastName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(idNoAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormIdNo" defaultMessage="ID No" />}
                                        name="IdNo"
                                        rules={[{ required: idNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(idNoRequired.translateRequired) == true ? idNoRequired.translateRequired : -1} defaultMessage="Please select an ID!" /> }]} >
                                        <Select size='large'
                                            style={{ width: '100%' }}
                                            showSearch
                                            value={this.state.idNo}
                                            onChange={this.onIdNoChange}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.staffs.map(staff => (
                                                <Option key={staff.id} value={staff.id}>{staff.nationalId}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(nameOfDeceasedAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormNameOfDeceased" defaultMessage="Name of Deceased" />}
                                        name="NameOfDeceased"
                                        rules={[{ required: nameOfDeceasedRequired.canBeRequired, message: <FormattedMessage id={Boolean(nameOfDeceasedRequired.translateRequired) == true ? nameOfDeceasedRequired.translateRequired : -1} defaultMessage="Please input Name Of Deceased!" /> }]} >
                                        <Input id="BereavementNoticeFormNameOfDeceased" value={this.state.nameOfDeceased} onChange={(e) =>
                                            this.setState({ nameOfDeceased: e.target.value })
                                        } />
                                    </Form.Item>}

                                {(relativeTypeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormRelativeType" defaultMessage="Relative Type" />}
                                        name="RelativeType"
                                        rules={[{ required: relativeTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(relativeTypeRequired.translateRequired) == true ? relativeTypeRequired.translateRequired : -1} defaultMessage="Please select a Relative Type!" /> }]} >
                                        <Radio.Group onChange={(e) => {
                                            this.setState({
                                                relativeType: e.target.value
                                            });
                                        }}
                                            value={this.state.relativeType}>
                                            <Radio value={"Employee's Relative"}><FormattedMessage id="BereavementNoticeFormEmployeeRelative" defaultMessage="Employee's Relative" /></Radio>
                                            <Radio value={"Spouse's Relative"}><FormattedMessage id="BereavementNoticeFormSpousesRelative" defaultMessage="Spouse's Relative" /></Radio>
                                        </Radio.Group>
                                    </Form.Item>}

                                {(relationAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormRelation" defaultMessage="Relation" />}
                                        name="Relation"
                                        rules={[{ required: relationRequired.canBeRequired, message: <FormattedMessage id={Boolean(relationRequired.translateRequired) == true ? relationRequired.translateRequired : -1} defaultMessage="Please select a Relation!" /> }]} >
                                        <Select name="Relation"
                                            style={{ width: '100%' }}
                                            value={this.state.relation}
                                            onChange={this.handleChangeRelation}>
                                            <Option key={"Spouse"} value={"Spouse"}>{"Spouse"}</Option>
                                            <Option key={"Mother"} value={"Mother"}>{"Mother"}</Option>
                                            <Option key={"Father"} value={"Father"}>{"Father"}</Option>
                                            <Option key={"Daughter"} value={"Daughter"}>{"Daughter"}</Option>
                                            <Option key={"Son"} value={"Son"}>{"Son"}</Option>
                                            <Option key={"Sister"} value={"Sister"}>{"Sister"}</Option>
                                            <Option key={"Brother"} value={"Brother"}>{"Brother"}</Option>
                                            <Option key={"Daughter-in-law"} value={"Daughter-in-law"}>{"Daughter-in-law"}</Option>
                                            <Option key={"Son-in-law"} value={"Son-in-law"}>{"Son-in-law"}</Option>
                                            <Option key={"Sister-in-law"} value={"Sister-in-law"}>{"Sister-in-law"}</Option>
                                            <Option key={"Brother-in-law"} value={"Brother-in-law"}>{"Brother-in-law"}</Option>
                                            <Option key={"Grand Mother"} value={"Grand Mother"}>{"Grand Mother"}</Option>
                                            <Option key={"Grand Father"} value={"Grand Father"}>{"Grand Father"}</Option>
                                            <Option key={"Grand Daughter"} value={"Grand Daughter"}>{"Grand Daughter"}</Option>
                                            <Option key={"Grand Son"} value={"Grand Son"}>{"Grand Son"}</Option>
                                            <Option key={"Grand Daughter-in-law"} value={"Grand Daughter-in-law"}>{"Grand Daughter-in-law"}</Option>
                                            <Option key={"Grand Son-in-law"} value={"Grand Son-in-law"}>{"Grand Son-in-law"}</Option>
                                        </Select>
                                    </Form.Item>}

                                {(dateOfDeathAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormDateOfDeath" defaultMessage="Date of Death" />}
                                        name="DateOfDeath"
                                        rules={[{ required: dateOfDeathRequired.canBeRequired, message: <FormattedMessage id={Boolean(dateOfDeathRequired.translateRequired) == true ? dateOfDeathRequired.translateRequired : -1} defaultMessage="Please input Date!" /> }]} >
                                        <DatePicker value={moment(this.state.dateOfDeath, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeDateOfDeath} disabledDate={this.disabledDate1} />
                                    </Form.Item>}

                                {(reasonOfDeathAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="BereavementNoticeFormReasonOfDeath" defaultMessage="Reason of Death" />}
                                        name="ReasonOfDeath"
                                        rules={[{ required: reasonOfDeathRequired.canBeRequired, message: <FormattedMessage id={Boolean(reasonOfDeathRequired.translateRequired) == true ? reasonOfDeathRequired.translateRequired : -1} defaultMessage="Please select a Reason of Death!" /> }]} >
                                        <Select
                                            name="reasonOfDeathId"
                                            style={{ width: '100%' }}
                                            value={this.state.reasonOfDeath}
                                            onChange={this.handleChangeReasonOfDeath}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newReasonOfDeath}
                                                            onChange={(event) => { this.setState({ newReasonOfDeath: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addReasonOfDeath} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Reason" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.reasonOfDeaths.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="6">
                                        <Collapse>
                                            <Panel header="Viewing" key="1">
                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Date and Time:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.viewingDateTime, 'DD/MM/YYYY HH:mm:ss')}
                                                            disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeViewingDateTime(date, dateString) }} />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Place:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.viewingPlace}
                                                            onChange={(e) =>
                                                                this.setState({ viewingPlace: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Address:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.viewingAddress}
                                                            onChange={(e) =>
                                                                this.setState({ viewingAddress: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>
                                            </Panel>
                                            <Panel header="Wake" key="2">
                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Date and Time:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.wakeDateTime, 'DD/MM/YYYY HH:mm:ss')}
                                                            disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeWakeDateTime(date, dateString) }} />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Place:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.wakePlace}
                                                            onChange={(e) =>
                                                                this.setState({ wakePlace: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Address:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.wakeAddress}
                                                            onChange={(e) =>
                                                                this.setState({ wakeAddress: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>
                                            </Panel>
                                            <Panel header="Interment" key="3">
                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Date and Time:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.intermentDateTime, 'DD/MM/YYYY HH:mm:ss')}
                                                            disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeIntermentDateTime(date, dateString) }} />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Place:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.intermentPlace}
                                                            onChange={(e) =>
                                                                this.setState({ intermentPlace: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Address:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.intermentAddress}
                                                            onChange={(e) =>
                                                                this.setState({ intermentAddress: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>
                                            </Panel>
                                            <Panel header="Funeral" key="4">
                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Date and Time:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <DatePicker showTime={{ format: 'HH:mm' }} value={moment(this.state.funeralDateTime, 'DD/MM/YYYY HH:mm:ss')}
                                                            disabledDate={this.disabledDate} format="DD/MM/YYYY HH:mm:ss" onChange={(date, dateString) => { this.onChangeFuneralDateTime(date, dateString) }} />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Place:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.funeralPlace}
                                                            onChange={(e) =>
                                                                this.setState({ funeralPlace: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Address:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.funeralAddress}
                                                            onChange={(e) =>
                                                                this.setState({ funeralAddress: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Comments:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.funeralComments}
                                                            onChange={(e) =>
                                                                this.setState({ funeralComments: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>
                                            </Panel>
                                            <Panel header="Expression of Sympathy" key="5">
                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Name of Organization:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.expressionOfSympathyOrganization}
                                                            onChange={(e) =>
                                                                this.setState({ expressionOfSympathyOrganization: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Address:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.expressionOfSympathyAddress}
                                                            onChange={(e) =>
                                                                this.setState({ expressionOfSympathyAddress: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>

                                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <FormBoot.Label style={{ color: 'black' }} column sm="2">
                                                        Contact:
                                                    </FormBoot.Label>
                                                    <Col sm="4">
                                                        <Input style={{ width: "100%" }} value={this.state.expressionOfSympathyContact}
                                                            onChange={(e) =>
                                                                this.setState({ expressionOfSympathyContact: e.target.value })
                                                            } />
                                                    </Col>
                                                </FormBoot.Group>
                                            </Panel>
                                            <Panel header="Company Expenses" key="6">
                                                {this.state.companyExpensesRows.map((item, index) => (
                                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Col sm="4">
                                                            <Input value={item.nameOfExpense} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompanyExpensesNameOfExpense(index, e)} />
                                                        </Col>
                                                        <Col sm="4">
                                                            <Space>
                                                                <InputNumber value={item.amount} style={{ width: "100%" }} onChange={(value) => this.handleChangeCompanyExpensesAmount(index, value)} />
                                                                <Select value={item.currency} placeholder="Select a Currency" onChange={(value) => this.handleChangeCompanyExpensesCurrency(index, value)} >
                                                                    <Option value="$">$</Option>
                                                                    <Option value="€">€</Option>
                                                                    <Option value="SR">SR</Option>
                                                                </Select>
                                                            </Space>
                                                        </Col>
                                                        <Col sm="1">
                                                            <Space>
                                                                {(index !== 0) && (
                                                                    <AntButton type="primary" danger onClick={(e) => this.removeCompanyExpensesLine(index, e)} icon={<CloseOutlined />}></AntButton>)}
                                                                {(this.state.companyExpensesRows.length > 0) && (this.state.companyExpensesRows.length === (index + 1)) && (
                                                                    <AntButton type="primary" onClick={this.addNewCompanyExpensesLine} icon={<PlusOutlined />}></AntButton>)}
                                                            </Space>
                                                        </Col>
                                                    </FormBoot.Group>
                                                ))}
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </FormBoot.Group>

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Button id="BereavementNoticeFormSaveButton" style={{ width: '100%' }} type="submit"
                                                    variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row}>
                                            <Col sm="3"></Col>
                                            <Col sm="2">
                                                <Button id="BereavementNoticeFormCancelButton" onClick={this.changeButtonCancel}
                                                    style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                            </Col>
                                            <Col sm="2">
                                                <Button id="BereavementNoticeFormUpdateButton" type="submit"
                                                    style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                            </Form>}
                        </div>

                        <Table style={{ padding: "4px 1rem 0px 4px" }} dataSource={this.state.bereavements} bordered>
                            <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                            <Column title="ID No" key="IdNo" render={(value, row, index) => {
                                if (row.staff !== undefined)
                                    if (row.staff !== null)
                                        return row.staff.nationalId;
                                return '';
                            }} />
                            <Column title="Name of Employee/Retiree" key="employeeName" render={(value, row, index) => {
                                if (row.staff !== undefined)
                                    if (row.staff !== null)
                                        return row.staff.name + " " + row.staff.midName + " " + row.staff.lastName;
                                return '';
                            }} />
                            <Column title="Personel Type" key="personelType" dataIndex="personelType" />
                            <Column title="Name of Deceased" key="nameOfDeceased" dataIndex="nameOfDeceased" />
                            <Column title="Relative Type" key="relativeType" dataIndex="relativeType" />
                            <Column title="Relation" key="relation" dataIndex="relation" />
                            <Column title="Date of Death" key="dateOfDeath" dataIndex="dateOfDeath" />
                            <Column title="Reason of Death" key="reasonOfDeath" render={(value, row, index) => {
                                if (row.codeReasonOfDeath !== undefined)
                                    if (row.codeReasonOfDeath !== null)
                                        return row.codeReasonOfDeath.name;
                                return '';
                            }} />
                            <Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => {
                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                        if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                            (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                            return (<div style={{ textAlign: "center" }}>
                                                <React.Fragment key={record.id} >
                                                    <CustomMaterialMenuDeleteEdit row={record}
                                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
                                                </React.Fragment>
                                            </div>);
                                        }
                                        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuEdit row={record}
                                                    onEditRow={this.changeButtonUpdate.bind(this)} />
                                            </React.Fragment>);
                                        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuDelete row={record}
                                                    onDeleteRow={this.deleteModal.bind(this)} />
                                            </React.Fragment>);
                                        }
                                    }
                                    else {
                                        return (<div style={{ textAlign: "center" }}>
                                        </div>);
                                    }
                                }}
                            />
                        </Table>

                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        ));
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BereavementNoticeForm)