import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import ViewListTwoTone from '@material-ui/icons/ViewListTwoTone';
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default ({ row, onInventoryRow, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const inventoryRow = () => {
    if (onInventoryRow) {
      onInventoryRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
      size={size}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="menu"
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose} >

      <MenuItem onClick={inventoryRow}>
        <ListItemIcon>
        <ViewListTwoTone fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography variant="inherit">
        <FormattedMessage id="CustomMaterialMenuRelease" defaultMessage="Release"/>
        </Typography>
      </MenuItem>
    </Menu>
  </div>

  );
};