import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Divider} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/CreateNewFolderTwoTone';
import ViewListTwoTone from '@material-ui/icons/ViewListTwoTone';

export default ({ row, onDeleteRow, onAmendRow, onViewRow, onEditDocRow, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const viewRow = () => {
    if (onViewRow) {
      onViewRow(row);
      setAnchorEl(null);
    }
  };

  const amendRow = () => {
    if (onAmendRow) {
      onAmendRow(row);
      setAnchorEl(null);
    }
  };

  const editDocRow = () => {
    if (onEditDocRow) {
      onEditDocRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}        
      >
        <i className={`kt-menu__link-icon  flaticon-layer`} />
        {/* <MoreVertIcon /> */}
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem onClick={editDocRow} style={{ backgroundColor: '#' + editBgColor }}>
        <ListItemIcon>
            <CreateIcon fontSize="small" color="secondary" /> 
          </ListItemIcon>
          <Typography variant="inherit">
            Edit Doc.
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={amendRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Amend
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={viewRow} style={{ backgroundColor: '#' + editBgColor }}>
        <ListItemIcon>
            <ViewListTwoTone fontSize="small" color="secondary" /> 
          </ListItemIcon>
          <Typography variant="inherit">
            View
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
            Delete
          </Typography>
        </MenuItem>  
    
      </Menu>
    </div>
  );
};