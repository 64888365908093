/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Switch, Button as AntButton } from 'antd';
import moment from 'moment'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const periods = [{ id: "hour", value: "Hour" }, { id: "day", value: "Day" }, { id: "week", value: "Week" }, { id: "month", value: "Month" }, { id: "year", value: "Year" }]

export class LeavePolicy extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            leavePolicyList: [],
            groupList: [],
            documentSelectItems: [],

            groupName: '',
            groupId: null,
            deathLeave: false,
            sickLeave: false,
            businessTripLeave: false,
            havePeriod: false,
            hideGroupModal: true,


            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();

        this.restartTable();
        this.setGroups();
        this.setComboboxes();


    }

    save = async () => {

        const leavePolicyList = this.state.leavePolicyList;

        if (Boolean(leavePolicyList) && leavePolicyList.length > 0) {

            leavePolicyList.forEach(element => {

                if (element.documentList && element.documentList.length > 0) {
                    element.forEach(d => {
                        d.active = false;
                    });
                }
                else element.documentList = [];

                if (Boolean(element.selectedDocuments) && element.selectedDocuments.length > 0) {

                    element.selectedDocuments.forEach(s => {
                        let obj = { documentId: s, active: true };
                        let f = element.documentList.find(p => p.documentId === obj.documentId);
                        if (Boolean(f))
                            f.active = true;
                        else element.documentList.push(obj);

                    });

                }


            });



            await handleRequest("POST", "/LeavePolicy/saveAll", leavePolicyList);

            this.showSuccess();
            this.resetInputs();
            this.restartTable();
        }
        else
            this.showError("Please create leave type");

    }



    setComboboxes = async () => {
        const resDepList = await handleRequest("GET", "/DocumentLog/getAll");
        if (resDepList.data.length > 0) {
            this.setState({
                documentSelectItems: resDepList.data
            });
        } else {
            this.setState({
                documentSelectItems: []
            });
        }

    }

    addGroup = async () => {

        const obj = {

            groupName: this.state.groupName,
            deathLeave: this.state.deathLeave,
            sickLeave: this.state.sickLeave,
            businessTripLeave: this.state.businessTripLeave,
            havePeriod: this.state.havePeriod,

        }


        await handleRequest("POST", "/LeavePolicyGroup/add", obj);


        this.showSuccess();

        this.setState({
            groupName: '',
            deathLeave: false,
            sickLeave: false,
            businessTripLeave: false,
            havePeriod: false,
            hideGroupModal: true

        })

        this.setGroups();

    }

    addItem = async () => {

        const obj = {

            groupId: this.state.groupId,
            itemName: this.state.itemName
        }


        await handleRequest("POST", "/BusinessTripItem/add", obj);


        this.showSuccess();

        this.setState({

            itemName: '',
            hideItemModal: true,
            groupId: null

        })

        this.setGroups();

    }

    setGroups = async () => {

        const resDepList = await handleRequest("GET", "/LeavePolicyGroup/getAllByCompany");
        if (resDepList.data.length > 0) {
            const list = resDepList.data;
            const _leavePolicyList = [];
            list.forEach(group => {
                if (Boolean(group.leavePolicy)) {
                    var item = group.leavePolicy;
                    item.selectedDocuments = [];
                    if (Boolean(item.documentList) && item.documentList.length > 0) {
                        item.documentList.forEach(element => {
                            item.selectedDocuments.push(element.documentId);
                        });
                    }
                    _leavePolicyList.push(item);
                }
                else {
                    let obj = {
                        id: 0, groupId: group.id,
                        anotherEmployee: false,
                        mindaysForApplication: 0,
                        legalDaysFixed: false,
                        legalDaysType: null, // Depend on approval , As written in contract 
                        legalDaysOff: 0,
                        periodNumberFrom: 0,
                        periodTypeFrom: '',
                        periodNumberTo: 0,
                        periodTypeTo: '',
                        additionalDays: false,
                        additionalBeforeStart: false,
                        additionalAfterEnd: false,
                        additionalUnpaid: false,
                        excessAnotherRequest: false,
                        excessUnpaid: false,
                        messages: '',
                        payment: false,
                        selectedDocuments: []
                    }
                    _leavePolicyList.push(obj);
                }
            });

            this.setState({
                groupList: list,
                leavePolicyList: _leavePolicyList
            });
        } else {
            this.setState({
                groupList: []
            });
        }

    }

    edit = (row) => {

        this.setState({
            statusId: row.id,
            status: row.status,
            abbreviation: row.abbreviation,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.statusId
        }

        await handleRequest("POST", "/LeavePolicy/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        // const response = await handleRequest("GET", "/LeavePolicy/getAllByCompany");

        // if (response.data.length !== 0) {
        //     const list = response.data;
        //     list.forEach(element => {
        //         element.key = element.id
        //     });
        //     this.setState({
        //         leavePolicyList: list,
        //     });
        // }

        // if (response.data.length === 0) {
        //     this.setState({
        //         leavePolicyList: [],
        //     });
        // }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "LeavePolicy" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "BusinessTripPolicy"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "BusinessTripPolicy");


    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id,
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === result.data.id || p.form === "All")
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: 0,
                authorizations: [],
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    deleteGroup = async (group) => {

        const obj = {

            id: group.id
        }

        await handleRequest("POST", "/LeavePolicyGroup/delete", obj);

        this.showSuccess();

        this.setGroups();

    }


    changePeriod = async (value) => {
        this.setGroups();
        this.setState({
            periodId: value,
            selectedDesignations: [],
            havePolicyDesignationId: null

        })

    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>

                        <MenuProvider id="menu_id">


                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>



                            <Modal
                                show={!this.state.hideGroupModal}
                                onHide={() => {
                                    this.setState({
                                        hideGroupModal: true,
                                        groupName: '',
                                        deathLeave: false,
                                        sickLeave: false,
                                        businessTripLeave: false,
                                        havePeriod: false,

                                    })
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Add Group</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="12">
                                            <Input style={{ width: "100%" }} placeholder="Group Name" value={this.state.groupName} onChange={(e) => this.setState({ groupName: e.target.value })} />

                                        </Col>
                                        <Col sm="6">

                                            <div>
                                                <Checkbox onChange={(e) => {
                                                    this.setState({
                                                        deathLeave: e.target.checked
                                                    })
                                                }} checked={this.state.deathLeave}> <FormattedMessage id="todo" defaultMessage="Death Leave" />  </Checkbox>
                                            </div>


                                        </Col>
                                        <Col sm="6">

                                            <div>
                                                <Checkbox onChange={(e) => {
                                                    this.setState({
                                                        sickLeave: e.target.checked
                                                    })
                                                }} checked={this.state.sickLeave}> <FormattedMessage id="todo" defaultMessage="Sick Leave" />  </Checkbox>
                                            </div>


                                        </Col>
                                        <Col sm="6">

                                            <div>
                                                <Checkbox onChange={(e) => {
                                                    this.setState({
                                                        businessTripLeave: e.target.checked
                                                    })
                                                }} checked={this.state.businessTripLeave}> <FormattedMessage id="todo" defaultMessage="Business Trip Leave" />  </Checkbox>
                                            </div>


                                        </Col>
                                        <Col sm="6">

                                            <div>
                                                <Checkbox onChange={(e) => {
                                                    this.setState({
                                                        havePeriod: e.target.checked
                                                    })
                                                }} checked={this.state.havePeriod}> <FormattedMessage id="todo" defaultMessage="Have Period" />  </Checkbox>
                                            </div>

                                        </Col>

                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={() => {
                                        this.setState({
                                            hideGroupModal: true,
                                            groupName: '',
                                            deathLeave: false,
                                            sickLeave: false,
                                            businessTripLeave: false,
                                            havePeriod: false,

                                        })
                                    }}>Cancel</ReactButton>
                                    <ReactButton variant="info" onClick={this.addGroup} >Add</ReactButton>
                                </Modal.Footer>
                            </Modal>


                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        {/* <Col sm="2">
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col> */}
                                    </Row>
                                </div>

                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        {...layout}
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >


                                        {
                                            <FormAnt.Item
                                                label={""}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>

                                                    <Col sm="6">
                                                        <ReactButton variant="info" onClick={() => {
                                                            this.setState({
                                                                hideGroupModal: false
                                                            })
                                                        }} > + Add New Leave Type</ReactButton>
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }
                                        <Divider></Divider>

                                        {this.state.groupList.filter(p => p.active === true).map((group, i) => (
                                            <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                                {
                                                    <FormAnt.Item

                                                        name="OrganizationalLevelDesignation"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                            <Col sm="6">
                                                                <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                                    {group.groupName}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col sm="5">

                                                            </Col>
                                                            <Col sm="1">
                                                                <AntButton type="dashed" onClick={() => this.deleteGroup(group)} shape="circle" icon={<CloseOutlined />} />
                                                            </Col>

                                                        </Form.Group>

                                                    </FormAnt.Item>}

                                                {<FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Application of another employee" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="6">
                                                            <Switch checkedChildren="Yes" unCheckedChildren="No" checked={this.state.leavePolicyList.find(p => p.groupId === group.id).anotherEmployee}
                                                                onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, anotherEmployee: value } : el
                                                                        )
                                                                    }))
                                                                }} />

                                                        </Col>

                                                    </Form.Group>

                                                </FormAnt.Item>}

                                                {<FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Min day(s) for application" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="6">  <InputNumber min={0} value={this.state.leavePolicyList.find(p => p.groupId === group.id).mindaysForApplication}
                                                            onChange={(value) => {
                                                                this.setState(prevState => ({
                                                                    leavePolicyList: prevState.leavePolicyList.map(
                                                                        el => el.groupId === group.id ? { ...el, mindaysForApplication: value } : el
                                                                    )
                                                                }))
                                                            }} />

                                                        </Col>

                                                    </Form.Group>

                                                </FormAnt.Item>}

                                                {!group.havePeriod && <FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Legal Days Off" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="2">
                                                            <div>
                                                                <Checkbox onChange={(e) => {
                                                                    let exVal = this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysApproval;
                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, legalDaysContract: e.target.checked, legalDaysApproval: e.target.checked ? false : exVal } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysContract}> <FormattedMessage id="todo" defaultMessage="As written in contract" />  </Checkbox>
                                                            </div>
                                                        </Col>
                                                        <Col sm="2">
                                                            <div>
                                                                <Checkbox onChange={(e) => {
                                                                    let exVal = this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysContract;
                                                                    this.setState(prevState => ({

                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, legalDaysApproval: e.target.checked, legalDaysContract: e.target.checked ? false : exVal } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysApproval}> <FormattedMessage id="todo" defaultMessage="Will depend on approval of request" />  </Checkbox>
                                                            </div>
                                                        </Col>
                                                        <Col sm="2">  <InputNumber disabled={this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysApproval || this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysContract} min={0} value={this.state.leavePolicyList.find(p => p.groupId === group.id).legalDaysOff}
                                                            onChange={(value) => {
                                                                this.setState(prevState => ({
                                                                    leavePolicyList: prevState.leavePolicyList.map(
                                                                        el => el.groupId === group.id ? { ...el, legalDaysOff: value } : el
                                                                    )
                                                                }))
                                                            }} />

                                                        </Col>

                                                    </Form.Group>

                                                </FormAnt.Item>}

                                                {group.havePeriod && <FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Period" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="2">
                                                            <div>
                                                                <InputNumber min={0} value={this.state.leavePolicyList.find(p => p.groupId === group.id).periodNumberFrom}
                                                                    onChange={(value) => {
                                                                        this.setState(prevState => ({
                                                                            leavePolicyList: prevState.leavePolicyList.map(
                                                                                el => el.groupId === group.id ? { ...el, periodNumberFrom: value } : el
                                                                            )
                                                                        }))
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                        <Col sm="2">
                                                            <Select
                                                                placeholder="Select"
                                                                value={this.state.leavePolicyList.find(p => p.groupId === group.id).periodTypeFrom} onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, periodTypeFrom: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            >
                                                                {periods.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>

                                                        </Col>

                                                        <Col sm="2">
                                                            <span>To</span>
                                                            <div>
                                                                <InputNumber min={0} value={this.state.leavePolicyList.find(p => p.groupId === group.id).periodNumberTo}
                                                                    onChange={(value) => {
                                                                        this.setState(prevState => ({
                                                                            leavePolicyList: prevState.leavePolicyList.map(
                                                                                el => el.groupId === group.id ? { ...el, periodNumberTo: value } : el
                                                                            )
                                                                        }))
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                        <Col sm="2">
                                                            <Select
                                                                placeholder="Select"
                                                                value={this.state.leavePolicyList.find(p => p.groupId === group.id).periodTypeTo} onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, periodTypeTo: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            >
                                                                {periods.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>

                                                        </Col>


                                                    </Form.Group>

                                                </FormAnt.Item>}

                                                {<FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Additional days allowed on request" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="6">
                                                            <Switch checkedChildren="Yes" unCheckedChildren="No" checked={this.state.leavePolicyList.find(p => p.groupId === group.id).additionalDays}
                                                                onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, additionalDays: value } : el
                                                                        )
                                                                    }))
                                                                }} />

                                                        </Col>
                                                        <Col sm="6"> </Col>

                                                        <Col sm="3">
                                                            <div style={{ marginTop: '1rem' }}>
                                                                <Checkbox onChange={(e) => {

                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, additionalBeforeStart: e.target.checked } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).additionalBeforeStart}> <FormattedMessage id="todo" defaultMessage="Before start date of vacation" />  </Checkbox>
                                                            </div>
                                                        </Col>
                                                        <Col sm="3">
                                                            <div style={{ marginTop: '1rem' }}>
                                                                <Checkbox onChange={(e) => {

                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, additionalAfterEnd: e.target.checked } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).additionalAfterEnd}> <FormattedMessage id="todo" defaultMessage="After last date of vacation" />  </Checkbox>
                                                            </div>
                                                        </Col>
                                                        <Col sm="3">
                                                            <div style={{ marginTop: '1rem' }}>
                                                                <Checkbox onChange={(e) => {

                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, additionalUnpaid: e.target.checked } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).additionalUnpaid}> <FormattedMessage id="todo" defaultMessage="Addidtional off days are unpaid" />  </Checkbox>
                                                            </div>
                                                        </Col>

                                                    </Form.Group>

                                                </FormAnt.Item>}

                                                {<FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Excess off days" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="6">
                                                            <div>
                                                                <Checkbox onChange={(e) => {

                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, excessAnotherRequest: e.target.checked } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).excessAnotherRequest}> <FormattedMessage id="todo" defaultMessage="Employee has to make another leave request" />  </Checkbox>
                                                            </div>

                                                        </Col>

                                                    </Form.Group>
                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >

                                                        <Col sm="6">
                                                            <div>
                                                                <Checkbox onChange={(e) => {

                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, excessUnpaid: e.target.checked } : el
                                                                        )
                                                                    }))
                                                                }} checked={this.state.leavePolicyList.find(p => p.groupId === group.id).excessUnpaid}> <FormattedMessage id="todo" defaultMessage=" If not applied for a request, off days will be evaluated as Unpaid days" />  </Checkbox>
                                                            </div>

                                                        </Col>
                                                    </Form.Group>

                                                </FormAnt.Item>}

                                                {<FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Required Documents" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >
                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="6">
                                                            <Select
                                                                placeholder="Select" mode="tags"
                                                                value={this.state.leavePolicyList.find(p => p.groupId === group.id).selectedDocuments} onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        leavePolicyList: prevState.leavePolicyList.map(
                                                                            el => el.groupId === group.id ? { ...el, selectedDocuments: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            >
                                                                {this.state.documentSelectItems.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.description}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>}

                                                {<FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage="Messages" />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="6">
                                                            <Input style={{ width: "100%" }} placeholder="Message" value={this.state.leavePolicyList.find(p => p.groupId === group.id).messages} onChange={(e) => {
                                                                var t = e.target.value;
                                                                this.setState(prevState => ({
                                                                    leavePolicyList: prevState.leavePolicyList.map(
                                                                        el => el.groupId === group.id ? { ...el, messages: t } : el
                                                                    )
                                                                }))
                                                            }}
                                                            />
                                                        </Col>

                                                    </Form.Group>


                                                </FormAnt.Item>}


                                            </div>
                                        ))}


                                        <div >
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="5">
                                                        {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                        <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                            variant="success" ><FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>



                                        <div hidden={true}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                        <Modal
                                            show={this.state.modalMessage}
                                            onHide={this.showOrHideModalMessage}
                                            backdrop="static"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>

                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <Row >
                                                        <Col sm="12">
                                                            {this.state.messageTip == "Success" &&
                                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                            {this.state.messageTip == "Warning" &&
                                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                            {this.state.messageTip == "Error" &&
                                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                        </Col>
                                                    </Row>
                                                </Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.message}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                            </Modal.Footer>
                                        </Modal>
                                    </FormAnt>
                                </div>

                                {/* {this.state.leavePolicyList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.leavePolicyList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="BusinessTripPolicy" dataIndex="BusinessTripPolicy" />
                            <Column title="Abbreviation" dataIndex="abbreviation" />

                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>} */}
                            </Card>

                       </MenuProvider>
                        {
                            !this.state.whatIsSetting && <Whatis  ></Whatis>


                        }
                    </div >
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LeavePolicy)