/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import 'antd/es/cascader/style/index.css';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Row } from 'react-bootstrap';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { Steps } from 'antd';
import { Progress } from 'antd';
import { Upload, message } from 'antd';
import { RightCircleOutlined, HistoryOutlined, InfoOutlined, ReadOutlined, CheckOutlined, LoadingOutlined, AntCloudOutlined } from '@ant-design/icons';
import { Toast } from 'primereact/toast';
import { Card } from 'react-bootstrap';
import { red } from '@material-ui/core/colors';
import { API_BASE } from '../../redux/config/env';

const { Step } = Steps;

function onChange(value) {
    console.log(value);
}

function setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
        var dt = new Date(d);
        return ('' + dt.getDate().toString().padStart(2, '0') + '/' + (dt.getMonth() + 1).toString().padStart(2, '0') + '/' + dt.getFullYear().toString().padStart(4, '0'));
    } else return '';
}

export class WFReview extends Component {

    constructor() {
        super();
        this.state = {
            startModalHidden: false,
            preparationModalHidden: false,
            informativeModalHidden: false,
            reviewModalHidden: false,
            confirmativeModalHidden: false,

            current: 3,
            action: "",
            deadline: "",
            remindme: false,
            reminderDay: "",
            period: "",

            chkCommentPreparation: false,
            commentPreparation: "",

            chkCommentInformative: false,
            commentInformative: "",

            chkJustificationReview: false,
            justificationReview: "",

            chkJustificationConfirmation: false,
            justificationConfirmation: "",

            hiddenRemindme: true,
            hiddenCommentPreparation: true,
            hiddenCommentInformative: true,
            hiddenJustificationReview: true,
            hiddenChkJustificationReview: true,
            hiddenJustificationConfirmation: true,

            WorkFlowStepAll: [],

            loading: false,
            imageUrl: null,

        };
    }

    componentDidMount = () => {
        this.getWorkFlowStep();
        console.log(this.state.WorkFlowStepAll);

    };

    getWorkFlowStep = async () => {
        const response = await handleRequest("GET", "/WorkFlowStep/getAll");
        if (response.data.length !== 0) {
            // console.log(response.data);
            // this.setState({ WorkFlowStepAll: response.data });
            this.setState({ WorkFlowStepAll: response.data.filter(p => p.workFlowId === 1) });
        } else {
            this.setState({ WorkFlowStepAll: [] });
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    };

    handleCorrectResubmit = e => {
        e.preventDefault();
        if (this.state.hiddenChkJustificationReview) {
            this.setState({
                hiddenChkJustificationReview: false
            });
        }
        if (this.state.hiddenChkJustificationReview === false) {
            this.setState({
                hiddenChkJustificationReview: true
            });
        }
    };

    handleModalHide = async () => {
        this.setState({
            startModalHidden: true,
            preparationModalHidden: true,
            informativeModalHidden: true,
            reviewModalHidden: true,
            confirmativeModalHidden: true,
        });
    }

    onStepChange(value) {
        this.setState({ current: value });
        if (value === 0) {
            this.props.history.push('/WFStart');;
        }
        if (value === 1) {
            this.props.history.push('/WFPreparation');;
        }
        if (value === 2) {
            this.props.history.push('/WFInformative');;
        }
        if (value === 3) {
            //this.props.history.push('/WFReview');;
        }
        if (value === 4) {
            this.props.history.push('/WFConfirmation');;
        }

        console.log(value);
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    logoPath: info.file.response[0]
                })
            }

            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };


    render() {
        const { current } = this.state;
        let cardHeader = <div style={{ color: "red", fontWeight: "bold" }}>
            Step 1: {this.state.WorkFlowStepAll.actiontype}
        </div>;
        let dialogModalFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button id='save-start' className="p-button-success" label="Save" />
            <Button id='cancel-start' className="p-button-danger" label="Close" onClick={this.handleModalHide} />
        </div>;

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <AntCloudOutlined style={{ fontSize: '48px', color: '#08c' }} />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>
                <Steps current={current} size="small" onChange={e => this.onStepChange(e)} >
                    {
                        this.state.WorkFlowStepAll.map(function (row) {
                            const sDate = setDateFormat(row.startdate);
                            const eDate = setDateFormat(row.duedate);
                            return <Step title={row.actiontype} subTitle={"Start Date: " + sDate} description={"End Date: " + eDate} />
                        })
                    }
                    {/* <Step title="Start" subTitle="Start Date: " description="Start Step Of >Workflow" />
                    <Step title="Preparation" description="Preparation Step Of Workflow" />
                    <Step title="Informative" description="Informative Step Of Workflow" />
                    <Step title="Review" description="Review Step Of Workflow" />
                    <Step title="Confirmation" description="Confirmation Step Of Workflow" /> */}

                </Steps>
                <Progress percent={100 / this.state.WorkFlowStepAll.length * (current + 1)} successPercent={100 / this.state.WorkFlowStepAll.length * (current + 1)} />

                <br />
                <Card hidden={this.state.reviewModalHidden} style={{ width: "100%" }}>
                    <Toast ref={(el) => this.toast = el} />
                    <Card.Header>
                        {cardHeader}
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="12" md="12" lg="12">
                                    <Button id='cancel-start' className="p-button-warning" style={{ flex: "right" }} label="Comments" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="12" md="12" lg="12">
                                    <Form.Label>
                                        Below (workflow) have been (action-V3) on (date, hour) by (User) for your review.
                                </Form.Label>
                                    <br />
                                    <Form.Label>
                                        There is comment of xxxx
                                </Form.Label>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="4" md="4" lg="4">
                                    <Button id='cancel-start' className="p-button-warning" label="Correct and resubmit" onClick={this.handleCorrectResubmit} />
                                </Form.Group>
                                <Form.Group as={Col} sm="4" md="4" lg="4">

                                </Form.Group>
                                <Form.Group as={Col} sm="4" md="4" lg="4">
                                    <Button id='cancel-start' className="p-button-success" label="Confirmed" />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="4" md="2" lg="2">
                                    <div hidden={this.state.hiddenChkJustificationReview}>
                                        <Checkbox onChange={(e) => this.setState({ chkJustificationReview: e.checked, hiddenJustificationReview: e.checked ? false : true })} checked={this.state.chkJustificationReview}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label">Justification(s)</label>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} sm="3" md="6" lg="6">
                                    <InputText hidden={this.state.hiddenJustificationReview} onChange={(e) => this.setState({ justificationReview: e.target.value })} value={this.state.justificationReview} style={{ width: "100%" }} placeholder="" />
                                </Form.Group>
                                <Form.Group as={Col} sm="5" md="4" lg="4">
                                    <div hidden={this.state.hiddenJustificationReview}>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            action={`${API_BASE}/Workflow/upload`}
                                            beforeUpload={this.beforeUpload}
                                            onChange={this.handlePhotoChange}
                                        >
                                            {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                        </Upload>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <Row>
                            <Col sm="12">
                                <Button id='save-start' style={{ width: "100%" }} className="p-button-success" label="Post" />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        {dialogModalFooter}
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WFReview)