import { API_BASE } from "../config/env";
import axios from "axios";

export const FETCH_CUSTOMERLOGS_PENDING = "FETCH_CUSTOMERLOGS_PENDING";
export const FETCH_CUSTOMERLOGS_FULFILLED = "FETCH_CUSTOMERLOGS_FULFILLED";
export const FETCH_CUSTOMERLOGS_REJECTED = "FETCH_CUSTOMERLOGS_REJECTED";

export const DELETE_CUSTOMERLOGS_PENDING = "DELETE_CUSTOMERLOGS_PENDING";
export const DELETE_CUSTOMERLOGS_FULFILLED = "DELETE_CUSTOMERLOGS_FULFILLED";
export const DELETE_CUSTOMERLOGS_REJECTED = "DELETE_CUSTOMERLOGS_REJECTED";

export function fetchCustomerLogs() {
  return dispatch => {
    axios.get(`${API_BASE}/selectsanditems/parent/1`)
    .then(result => result.data)
    .then(data=>dispatch(
        {
            type:FETCH_CUSTOMERLOGS_FULFILLED,
            payload:data
        }))
        .catch(error=>console.log("Web servis sorunu oluştu."))
  };
}