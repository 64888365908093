/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomViolationMenu from '../settings/ForComponents/CustomViolationMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "ViolationLog";

export class ViolationLog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            logType: null,
            date: null,
            disciplineId: null,
            location: null,
            epmNo: null,
            subject: null,
            violationType: null,
            description: null,
            inspectorId: null,
            clientInspectorId: null,
            correction: null,
            rootCause: null,
            correctiveAction: null,
            acd: null,
            actionById: null,
            remarks: null,

            departmentSelectItems: [],
            disciplineSelectItems: [],
            inspectorClientItems: [],
            inspectorCompanyItems: [],

            acdExtension: null,
            signOffDate: null,
            showRemarksModal: false,
            showAcdModal: false,
            showSignoffModal: false,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();

    componentDidMount = async () => {
        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillComboboxes();
    }

    fillComboboxes = async () => {

        const response2 = await handleRequest("GET", "/Discipline/getallByCompany");


        if (response2.data.length !== 0) {

            this.setState({
                disciplineSelectItems: response2.data
            });

        }

        const responseDep = await handleRequest("GET", "/Department/getallByCompany");


        if (responseDep.data.length !== 0) {

            this.setState({
                departmentSelectItems: responseDep.data
            });

        }

        const resClientPersonnel = await handleRequest("GET", "/ClientPersonnelLog/getAllByCompany");


        if (resClientPersonnel.data.length !== 0) {

            this.setState({
                inspectorClientItems: resClientPersonnel.data
            });

        }

        const resManpower = await handleRequest("GET", "/ManpowerLog/getAllByCompany");


        if (resManpower.data.length !== 0) {

            this.setState({
                inspectorCompanyItems: resManpower.data
            });

        }
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            logType: this.state.logType,
            date: this.state.date,
            disciplineId: this.state.disciplineId,
            location: this.state.location,
            epmNo: this.state.epmNo,
            subject: this.state.subject,
            violationType: this.state.violationType,
            description: this.state.description,
            inspectorId: this.state.inspectorId,
            clientInspectorId: this.state.clientInspectorId,
            correction: this.state.correction,
            rootCause: this.state.rootCause,
            correctiveAction: this.state.correctiveAction,
            acd: this.state.acd,
            actionById: this.state.actionById,
            remarks: this.state.remarks,

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ViolationLog/add", obj);
        else
            await handleRequest("POST", "/ViolationLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            logType: row.logType,
            date: Boolean(row.date) ? moment(row.date) : null,
            disciplineId: row.disciplineId,
            location: row.location,
            epmNo: row.epmNo,
            subject: row.subject,
            violationType: row.violationType,
            description: row.description,
            inspectorId: row.inspectorId,
            clientInspectorId: row.clientInspectorId,
            correction: row.correction,
            rootCause: row.rootCause,
            correctiveAction: row.correctiveAction,
            acd: Boolean(row.acd) ? moment(row.acd) : null,
            actionById: row.actionById,
            remarks: row.remarks,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            logType: row.logType,
            date: Boolean(row.date) ? moment(row.date) : null,
            disciplineId: row.disciplineId,
            location: row.location,
            epmNo: row.epmNo,
            subject: row.subject,
            violationType: row.violationType,
            description: row.description,
            inspectorId: row.inspectorId,
            clientInspectorId: row.clientInspectorId,
            correction: row.correction,
            rootCause: row.rootCause,
            correctiveAction: row.correctiveAction,
            acd: Boolean(row.acd) ? moment(row.acd) : null,
            actionById: row.actionById,
            remarks: row.remarks,


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ViolationLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    remarksModal = (row) => {
        this.setState({
            showRemarksModal: true,
            id: row.id
        });
    }

    acdModal = (row) => {
        this.setState({
            showAcdModal: true,
            id: row.id
        });
    }

    signOffModal = (row) => {
        this.setState({
            showSignoffModal: true,
            id: row.id
        });
    }

    addRemarks = async () => {

        const obj = {
            id: this.state.id,
            remarks: this.state.remarks
        }

        await handleRequest("POST", "/ViolationLog/addRemarks", obj);

        this.restartTable();
        this.showSuccess();
        this.resetInputs();
    }

    signOff = async () => {

        const obj = {
            id: this.state.id,
            signOffDate: this.state.signOffDate
        }

        await handleRequest("POST", "/ViolationLog/signOff", obj);

        this.restartTable();
        this.showSuccess();
        this.resetInputs();

    }

    addAcdExtension = async () => {

        const obj = {
            id: this.state.id,
            acdExtension: this.state.acdExtension
        }

        await handleRequest("POST", "/ViolationLog/addAcdExtension", obj);

        this.restartTable();
        this.showSuccess();
        this.resetInputs();

    }





    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomViolationMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onAddRemarks={this.remarksModal.bind(this)}
                onAcdExtension={this.acdModal.bind(this)} onSignOff={this.signOffModal.bind(this)}
            />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/ViolationLog/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            logType: null,
            date: null,
            disciplineId: null,
            location: null,
            epmNo: null,
            subject: null,
            violationType: null,
            description: null,
            inspectorId: null,
            clientInspectorId: null,
            correction: null,
            rootCause: null,
            correctiveAction: null,
            acd: null,
            actionById: null,
            remarks: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            logType: null,
            date: null,
            disciplineId: null,
            location: null,
            epmNo: null,
            subject: null,
            violationType: null,
            description: null,
            inspectorId: null,
            clientInspectorId: null,
            correction: null,
            rootCause: null,
            correctiveAction: null,
            acd: null,
            actionById: null,
            remarks: null,

            acdExtension: null,
            signOffDate: null,
            showRemarksModal: false,
            showAcdModal: false,
            showSignoffModal: false


        });
    }


    cancel = () => {

        this.resetInputs();
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    getAllByFilterForClient = () => {
        //await handleRequest("GET", "/ViolationLog/getAllByFilterForClient");
        window.location.href = `${API_BASE}/ViolationLog/getAllByFilterForClient/` + this.props.auth.user.id;
    }

    getAllByFilterForCompany = () => {
        window.location.href = `${API_BASE}/ViolationLog/getAllByFilterForCompany/` + this.props.auth.user.id;
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col sm="6">
                                        </Col>
                                        <Col sm="2">
                                            <ReactButton id="TestButton1" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.getAllByFilterForClient}><FormattedMessage id="TestButtonViolation1" defaultMessage="Test LBE Report" /></ReactButton>
                                        </Col>
                                        <Col sm="2">
                                            <ReactButton id="TestButton2" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.getAllByFilterForCompany}><FormattedMessage id="TestButtonViolation2" defaultMessage="Test NCR Report" /></ReactButton>
                                        </Col>
                                        <Col sm="2">
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>
                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >
                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.clientcompany").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.clientcompany" defaultMessage="Client/Company" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="logType"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.clientcompany")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.clientcompany").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.clientcompany")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.clientcompany").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Radio.Group id="violationlog.clientcompany" onChange={(e) => {
                                                            this.setState({
                                                                logType: e.target.value
                                                            })
                                                        }} value={this.state.logType}>
                                                            <Radio value={"Client"}>Client</Radio>
                                                            <Radio value={"Company"}>Company</Radio>
                                                        </Radio.Group>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }
                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.epmNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}
                                                label={<FormattedMessage id="violationlog.epmNo" defaultMessage="EPM No" />}
                                                style={{ marginBottom: 0 }}
                                                name="epmNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.epmNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.epmNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.epmNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.epmNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="EPM No" style={{ width: "100%" }} id="violationlog.epmNo" value={this.state.epmNo} onChange={(e) => { this.setState({ epmNo: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>
                                            </FormAnt.Item>
                                        }
                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.date").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.date" defaultMessage="Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="date"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.date")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.date").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.date")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.date").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="violationlog.date" format="DD-MM-YYYY" value={this.state.date} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                date: date
                                                            });
                                                            this.setState({
                                                                date: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.discipline").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.discipline" defaultMessage="Discipline" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="disciplineId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.discipline")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.discipline").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.discipline")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.discipline").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Discipline" id="violationlog.discipline" value={this.state.disciplineId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                disciplineId: value
                                                            });
                                                            this.setState({ disciplineId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.disciplineSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.location").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.location" defaultMessage="Location" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="location"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.location")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.location").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.location")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.location").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Location" style={{ width: "100%" }} id="violationlog.location" value={this.state.location} onChange={(e) => { this.setState({ location: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.Subject").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.Subject" defaultMessage="Subject" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="subject"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.Subject")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.Subject").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.Subject")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.Subject").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Subject" style={{ width: "100%" }} id="violationlog.Subject" value={this.state.subject} onChange={(e) => { this.setState({ subject: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.ViolationType").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.ViolationType" defaultMessage="Violation Type" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="violationType"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.ViolationType")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.ViolationType").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.ViolationType")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.ViolationType").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Violation Type" id="violationlog.ViolationType" value={this.state.violationType} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                violationType: value
                                                            });
                                                            this.setState({ violationType: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {Boolean(this.state.logType) && this.state.logType === "Client" && <Option key={"General Comment"} value={"General Comment"}>General Comment</Option>}
                                                            <Option key={"Proactive Notification"} value={"Proactive Notification"}>Proactive Notification</Option>
                                                            {Boolean(this.state.logType) && this.state.logType === "Client" && <Option key={"Standard Violation"} value={"Standard Violation"}>Standard Violation</Option>}
                                                            <Option key={"NCR"} value={"NCR"}>NCR</Option>
                                                            {Boolean(this.state.logType) && this.state.logType === "Client" && <Option key={"Work Sheet"} value={"Work Sheet"}>Work Sheet</Option>}


                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.description").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.description" defaultMessage="Description" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="description"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.description")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.description").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.description")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.description").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Description" style={{ width: "100%" }} id="violationlog.description" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {Boolean(this.state.logType) && this.state.logType === "Company" && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.inspector").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.inspector" defaultMessage="Inspector" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="inspectorId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Inspector" id="violationlog.inspector" value={this.state.inspectorId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                inspectorId: value
                                                            });
                                                            this.setState({ inspectorId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.inspectorCompanyItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{Boolean(i.staff) ? i.staff.fullName : ""}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }
                                        {
                                            Boolean(this.state.logType) && this.state.logType === "Client" &&
                                            ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.inspector").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.inspector" defaultMessage="Client Inspector" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="clientInspectorId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.inspector").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Inspector" id="violationlog.inspector" value={this.state.clientInspectorId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                clientInspectorId: value
                                                            });
                                                            this.setState({ clientInspectorId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.inspectorClientItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name + " " + i.surname}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }
                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.correction").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.correction" defaultMessage="Correction" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="correction"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correction")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correction").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correction")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correction").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Correction" style={{ width: "100%" }} id="violationlog.correction" value={this.state.correction} onChange={(e) => { this.setState({ correction: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.rootcause").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.rootcause" defaultMessage="Root Cause" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="rootCause"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.rootcause")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.rootcause").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.rootcause")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.rootcause").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Root Cause" style={{ width: "100%" }} id="violationlog.rootcause" value={this.state.rootCause} onChange={(e) => { this.setState({ rootCause: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.correctiveaction").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.correctiveaction" defaultMessage="Corrective Action" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="correctiveAction"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correctiveaction")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correctiveaction").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correctiveaction")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.correctiveaction").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Corrective Action" style={{ width: "100%" }} id="violationlog.correctiveaction" value={this.state.correctiveAction} onChange={(e) => { this.setState({ correctiveAction: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.acd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.acd" defaultMessage="Action Close Date (ACD)" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="acd"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.acd")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.acd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.acd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.acd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="violationlog.acd" format="DD-MM-YYYY" value={this.state.acd} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                acd: date
                                                            });
                                                            this.setState({
                                                                acd: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.actionby").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.actionby" defaultMessage="Action By" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="actionById"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.actionby")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.actionby").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.actionby")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.actionby").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Action By" id="violationlog.actionby" value={this.state.actionById} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                actionById: value
                                                            });
                                                            this.setState({ actionById: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.departmentSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "violationlog.remarks").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="violationlog.remarks" defaultMessage="Remarks" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="remarks"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.remarks")) ? this.state.dynamicInputs.find(p => p.inputKey === "violationlog.remarks").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "violationlog.remarks")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "violationlog.remarks").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Remarks" style={{ width: "100%" }} id="violationlog.remarks" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />
                                    <Column title="S/N" dataIndex="index" />
                                    <Column title='EPM No' dataIndex='epmNo' render={(value, row, index) => { return value; }} />
                                    <Column title='Client/Company' dataIndex='logType' render={(value, row, index) => { return value; }} />
                                    <Column title='Date' dataIndex='date' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />
                                    <Column title='Discipline' dataIndex='discipline' render={(value, row, index) => { return Boolean(value) ? value.disciplineName : ""; }} />
                                    <Column title='Location' dataIndex='location' render={(value, row, index) => { return value; }} />
                                    <Column title='Subject' dataIndex='subject' render={(value, row, index) => { return value; }} />
                                    <Column title='Violation Type' dataIndex='violationType' render={(value, row, index) => { return value; }} />
                                    <Column title='Description' dataIndex='description' render={(value, row, index) => { return value; }} />
                                    <Column title='Inspector' render={(value, row, index) => {
                                        if (row.logType === "Client")
                                            return Boolean(row.clientPersonnelLog) ? row.clientPersonnelLog.name + " " + row.clientPersonnelLog.surname : "";
                                        else Boolean(row.manpowerLog) && Boolean(row.manpowerLog.staff) ? row.manpowerLog.staff.fullName : "";
                                    }} />
                                    <Column title='Correction' dataIndex='correction' render={(value, row, index) => { return value; }} />
                                    <Column title='Root Cause' dataIndex='rootCause' render={(value, row, index) => { return value; }} />
                                    <Column title='Corrective Action' dataIndex='correctiveAction' render={(value, row, index) => { return value; }} />
                                    <Column title='ACD' dataIndex='acd' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />
                                    <Column title='ACD Extension' dataIndex='acdExtension' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />
                                    <Column title='Action By' dataIndex='actionBy' render={(value, row, index) => { return Boolean(value) ? value.departmentName : ""; }} />
                                    <Column title='remarks' dataIndex='remarks' render={(value, row, index) => { return value; }} />
                                    <Column title='Status' dataIndex='status' render={(value, row, index) => { return value; }} />

                                    <Column title='Sign Off Date' dataIndex='signOffDate' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />




                                </Table>}
                            </Card>

                        </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showRemarksModal}
                            onHide={() => {
                                this.setState({
                                    showRemarksModal: false
                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Add remarks</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Input placeholder="Remarks" style={{ width: "100%" }} id="violationlog.remarks.modal" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />

                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showRemarksModal: false
                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addRemarks} >Save</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showAcdModal}
                            onHide={() => {
                                this.setState({
                                    showAcdModal: false
                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Add ACD Extension</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <DatePicker format="DD-MM-YYYY" value={this.state.acdExtension} onChange={(date, dateString) => {

                                    this.setState({
                                        acdExtension: date
                                    })
                                }} />
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showAcdModal: false
                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addAcdExtension} >Save</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showSignoffModal}
                            onHide={() => {
                                this.setState({
                                    showSignoffModal: false
                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Sign Off</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <DatePicker format="DD-MM-YYYY" value={this.state.signOffDate} onChange={(date, dateString) => {

                                    this.setState({
                                        signOffDate: date
                                    })
                                }} />
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showSignoffModal: false
                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.signOff} >Save</ReactButton>
                            </Modal.Footer>
                        </Modal>



                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ViolationLog)
