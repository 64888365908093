/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Helmet } from "react-helmet";
import '../settings/assets/css/animate.min.css';
import '../settings/assets/css/bootstrap.min.css';
import '../settings/assets/css/diamonds.css';
import '../settings/assets/css/flexslider.css';
import '../settings/assets/css/font-awesome.css';
import '../settings/assets/css/overwrite.css';
import '../settings/assets/css/owl.carousel.css';
import '../settings/assets/css/owl.theme.css';
import '../settings/assets/css/owl.transitions.css';
import '../settings/assets/css/simple-line-icons.css';
import '../settings/assets/css/style.css';
import '../settings/assets/css/style.ie.css';
import '../settings/assets/skins/default.css';
import imgLogo from "../settings/assets/img/logo.png";
import imgLogo1 from "../settings/assets/img/logo01.png";
import img1 from "../settings/assets/img/img-video.png";
import img2 from "../settings/assets/img/arrow.png";
import img3 from "../settings/assets/img/img-app01.png";
import img4 from "../settings/assets/img/clients/logo01.png";
import img5 from "../settings/assets/img/clients/logo02.png";
import img6 from "../settings/assets/img/clients/logo03.png";
import img7 from "../settings/assets/img/clients/logo04.png";
import img8 from "../settings/assets/img/clients/logo05.png";
import img9 from "../settings/assets/img/team/team1.jpg";
import img10 from "../settings/assets/img/people/1.png";
import img11 from "../settings/assets/img/imac-slide/slide1.jpg";
import img12 from "../settings/assets/img/imac-slide/slide2.jpg";
import img13 from "../settings/assets/img/imac-slide/slide3.jpg";
import img14 from "../settings/assets/img/imac-slider.png";
import img15 from "../settings/assets/img/blog/thumbnail/thumb01.jpg";
import img16 from "../settings/assets/img/blog/thumbnail/thumb02.jpg";

export class VendorPortalTest2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            deleteModal: false
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    componentDidMount = async () => {
        document.body.className = document.body.className + " kt-aside--minimize";
        document.getElementById("kt_aside").remove();
        document.getElementById("kt_footer").style.left = "0px";
        document.getElementById("kt_footer").remove();
        document.getElementById("kt_scrolltop").remove();
        document.getElementById("kt_header").style.left = "0px";
        document.getElementById("kt_subheader").style.left = "0px";
        document.getElementById("kt_wrapper").style.paddingLeft = "0px";
        document.getElementById("kt_content").style.padding = "0px";
        document.getElementById("container").style.padding = "0px";

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "VendorPortalTest" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "VendorPortalTest",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "VendorPortalTest");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalEdit = () => {
        this.resetInputs();
        this.setState({
            modalEdit: !this.state.modalEdit,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        const { current } = this.state;

        return (
            <div>

                <Helmet>
                    <script src="/assets/js/jquery.min.js" type="text/javascript" />
                    <script src="/assets/js/bootstrap.min.js" type="text/javascript" />
                    <script src="/assets/js/custom.js" type="text/javascript" />
                    <script src="/assets/js/ie10-viewport-bug-workaround.js" type="text/javascript" />
                    <script src="/assets/js/ie-emulation-modes-warning.js" type="text/javascript" />
                    <script src="/assets/js/navigation/waypoints.min.js" type="text/javascript" />
                    <script src="/assets/js/parallax/jquery.parallax-1.1.3.js" type="text/javascript" />
                    <script src="/assets/js/parallax/setting.js" type="text/javascript" />
                    <script src="/assets/js/owlcarousel/owl.carousel.js" type="text/javascript" />
                    <script src="/assets/js/owlcarousel/setting.js" type="text/javascript" />
                    <script src="/assets/js/flexslider/jquery.flexslider.js" type="text/javascript" />
                    <script src="/assets/js/diamonds/jquery.diamonds.js" type="text/javascript" />
                    <script src="/assets/js/diamonds/setting.js" type="text/javascript" />
                    <script src="/assets/js/tweecool/tweecool.js" type="text/javascript" />
                    <script src="/assets/js/tweecool/setting.jss" type="text/javascript" />
                    <script src="/assets/js/validation.js" type="text/javascript" />
                    <script src="/assets/js/map/jquery.tekmap.0.7.min.js" type="text/javascript" />
                    <script src="/assets/js/map/setting.js" type="text/javascript" />
                    <script src="/assets/js/jquery.easing.1.3.js" type="text/javascript" />
                    <script src="/assets/js/totop/jquery.ui.totop.js" type="text/javascript" />
                    <script src="/assets/js/totop/setting.js" type="text/javascript" />
                    <script src="/assets/js/theme-option/demosetting.js" type="text/javascript" />
                </Helmet>

                <header>
                    <nav className="navbar2 navbar2-default navbar2-fixed-top top-nav-collapse">
                        <div className="container">
                            <div className="navbar2-header">
                                <button type="button" className="navbar2-toggle collapsed" data-toggle="collapse" data-target="#navbar2">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a style={{ marginBottom: "1rem" }} className="navbar2-brand" href="#">
                                    <img src={imgLogo1} className="scroller-logo" alt="" />
                                    {/*<img src={imgLogo} className="default-logo" alt="" />*/}
                                </a>
                            </div>
                            <div id="navbar2" className="navbar2-collapse collapse">
                                <ul style={{ marginTop: "8px" }} className="nav2 navbar2-nav2 navbar2-right">
                                    <li><a href="#home" id="GoToHome" className="selected">Home</a></li>
                                    <li><a href="#description" id="GoToDescription">Description</a></li>
                                    <li><a href="#about" id="GoToAbout">About</a></li>
                                    <li><a href="#features" id="GoToFeatures">Features</a></li>
                                    <li><a href="#pricing" id="GoToPricing">Pricing</a></li>
                                    <li><a href="blog.html">Blog</a></li>
                                    <li><a href="#" className="login">Login</a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <section id="home" className="home-section default-bg">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-6 col-md-offset-3 headline">
                                <h1>Creat your own website</h1>
                                <p>Simple, easy, drug and play</p>
                            </div>
                            <div className="col-md-10 col-md-offset-1">
                                <p>Play video and learned in five minutes</p>
                                <img src={img2} className="arrow-down" alt="" />
                                <div className="video-wrapper">
                                    <div className="row">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="video-container">
                                                <div id="ytplayer" className="player"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="start-video"><i className="fa fa-play"></i></button>
                                    <div className="video-image"><img src={img1} className="img-responsive" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="intro" className="dark-section">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-10 col-md-offset-1">
                                <h4 className="headtitle">
                                    Sugan is simple landing page, fresh and clean, suitable for software website, mobile app, and personal website
					</h4>
                                <p>Visual bookmark software for managing template</p>
                                <a href="#" className="btn btn-primary btn-lg">REGISTER FOR FREE</a>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="description" className="half-wrapper">
                    <div className="container half-full">
                        <div className="row">
                            <div className="col-md-6 half-column set-left">
                                <div className="half-col-containt">
                                    <div className="heading">
                                        <h5>Description</h5>
                                        <p>
                                            Great landing page for download software website
							</p>
                                        <span></span>
                                    </div>
                                    <p>
                                        Ad tale tollit eum, eos an tota iriure, sea nulla vulputate id. Eu eum odio meis postea, cum ea essent quaeque, aperiri accusata iracundia duo ex. Adhuc veritus vis cu, at cum idque aliquam recusabo, putant delicatissimi vis ne. Veritus praesent incorrupte eu eos.
						</p>
                                    <ul className="check-list">
                                        <li><i className="fa fa-check"></i> Drag and play system</li>
                                        <li><i className="fa fa-check"></i> Look great on any device</li>
                                        <li><i className="fa fa-check"></i> Clean and modern design</li>
                                        <li><i className="fa fa-check"></i> Documentation included</li>
                                        <li><i className="fa fa-check"></i> Full support 24 hours</li>
                                    </ul>
                                    <p className="btn-horizontal">
                                        <a href="#" className="btn btn-primary">DOWNLOAD</a>
                                        <a href="#" className="btn btn-default">LIVE DEMO</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 half-column set-right">
                                <div className="fluid">
                                    <img src={img3} className="img-responsive" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="clearfix"></div>

                <div id="client">
                    <div className="parallax text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="heading centered">
                                        <h5>Our clients</h5>
                                        <p>
                                            Our amazing clients
							</p>
                                        <span></span>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div id="owl-client" className="owl-carousel">
                                        <div className="client-logo">
                                            <a href="#"><img src={img4} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img5} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img6} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img7} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img8} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img4} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img5} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img6} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img7} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img8} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img4} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img5} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img6} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img7} alt="" /></a>
                                        </div>
                                        <div className="client-logo">
                                            <a href="#"><img src={img8} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="about" className="wrapper">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="heading centered">
                                    <h5>About us</h5>
                                    <p>
                                        About Sugan theme
						                </p>
                                    <span></span>
                                </div>

                                <div className="diamondswrap">
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Onah Sakonah</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Ana Maryani</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Oneng Bulaga</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Tuti Murni</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Eman Suherman</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Yayan Subyan</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={img9} className="img-responsive" alt="" />
                                        <div className="content">
                                            <h6 className="name"><a href="#">Asep Gejul</a></h6>
                                            <ul className="link">
                                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <p>
                                    Ridens eirmod duo eu, cu consul liberavisse pro. Eu vel reque eripuit sanctus. Mei ei erant soleat percipitur. Eam ipsum feugait eu, nec vero verear aliquid ex. Ad esse electram pro, eam te odio dictas maluisset, usu solet causae euripidis in. Et liber ancillae voluptatibus vis. Ex etiam timeam honestatis eam, erant verterem delicatissimi no vix.
					</p>
                                <a href="#" className="btn btn-primary">GET IN TOUCH WITH US</a>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="testimoni" className="dark-section">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-8 col-md-offset-2">
                                <p className="icon-testimoni"><i className="fa fa-quote-left"></i></p>
                                <div id="sync1" className="owl-carousel content-testimoni">
                                    <div className="item">
                                        <p>Ad esse <strong>electram</strong> pro, eam te odio dictas maluisset, usu solet causae euripidis in. Et liber <strong>ancillae voluptatibus</strong> vis etiam timeam honestatis eam erant verterem.</p>
                                        <p className="name">Asep Marno - <a href="#">CEO CV.Marno</a></p>
                                    </div>
                                    <div className="item">
                                        <p>Nobis epicurei efficiantur est ex, ius nisl <strong>neglegentur</strong> in. Vel te diam legere voluptatum, vim et habeo urbanitas scribentur. Vis an <strong>hinc deseruisse</strong> delicatissimi.</p>
                                        <p className="name">Anna Mariana - <a href="#">CFO Dodol</a></p>
                                    </div>
                                    <div className="item">
                                        <p>Lorem ipsum dolor sit amet, sint animal te vix, probo <strong>consectetuer</strong> mel ut. Ad copiosae pertinax duo, vix at choro partem intellegebat.</p>
                                        <p className="name">Botakin - <a href="#">CEO Botak</a></p>
                                    </div>
                                    <div className="item">
                                        <p>accusata dissentias, ne erat minim scaevola sed <strong>consequuntur</strong> no. Duis nostro vel ut. Mel putant discere <strong>splendide</strong> eu vel tantas satpol lalulintas. </p>
                                        <p className="name">Wiwit Anggi - <a href="#">CFO Wiwit</a></p>
                                    </div>
                                    <div className="item">
                                        <p>Per id error ceteros conclusionemque. Qui no partem complectitur. An usu paulo option tincidunt. Ekek Sabun Giv</p>
                                        <p className="name">Bi Isah - <a href="#">CEO Isah</a></p>
                                    </div>
                                </div>
                                <div className="author-testimoni">
                                    <div id="sync2" className="owl-carousel">
                                        <div className="item">
                                            <img src={img10} width="40" height="40" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src={img10} width="40" height="40" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src={img10} width="40" height="40" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src={img10} width="40" height="40" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src={img10} width="40" height="40" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="features" className="half-wrapper">
                    <div className="container half-full">
                        <div className="row">
                            <div className="col-md-6 half-column set-left">
                                <div className="fluid">
                                    <div className="imac-wrapper">
                                        <div className="imac-slider">
                                            <div className="imac-device">
                                                <ul className="slides">
                                                    <li><img src={img11} className="img-responsive" alt="" /></li>
                                                    <li><img src={img12} className="img-responsive" alt="" /></li>
                                                    <li><img src={img13} className="img-responsive" alt="" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <img src={img14} className="img-responsive" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 half-column set-right">
                                <div className="half-col-containt">
                                    <div className="heading">
                                        <h5>Features</h5>
                                        <p>
                                            Key of features, this a reason why choose sugan
							</p>
                                        <span></span>
                                    </div>
                                    <ul className="feature-list">
                                        <li>
                                            <span className="icon-screen-desktop icon-2x icon-color icon-left"></span>
                                            <h5>Fully responsive</h5>
                                            <p>
                                                Eu eum odio meis postea, cum ea essent quaeque, aperiri accusata iracundia duo ex adhuc.
								</p>
                                        </li>
                                        <li>
                                            <span className="icon-wrench icon-2x icon-color icon-left"></span>
                                            <h5>Easy to customize</h5>
                                            <p>
                                                Utroque facilis mentitum in per, ut sea veri docendi complectitur. Ex viderer aliquando est.
								</p>
                                        </li>
                                        <li>
                                            <span className="icon-chemistry icon-2x icon-color icon-left"></span>
                                            <h5>Simple and clean</h5>
                                            <p>
                                                Ad alia recteque nam, nam id fabellas imperdiet, aeterno alienum voluptatibus usu et eirmod.
								</p>
                                        </li>
                                        <li>
                                            <span className="icon-envelope-letter icon-2x icon-color icon-left"></span>
                                            <h5>Working form</h5>
                                            <p>
                                                Solet gloriatur ad pri, usu cu dolore inimicus perpetua ut pri quot fabulas, ne duo tibique.
								</p>
                                        </li>
                                        <li>
                                            <span className="icon-notebook icon-2x icon-color icon-left"></span>
                                            <h5>Documentation</h5>
                                            <p>
                                                mmo nobis in mei, sale praesent dignissim ut mei, eu mel harum impedit diam definitionem ne.
								</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="workit">
                    <div className="parallax">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center marginbot20">
                                    <h5>How it work...?</h5>
                                    <p>Mel ad eius omittantur, dico wisi mnesarchum pro. Eam aperiam nostrum</p>
                                </div>
                                <div className="col-md-4 workit-box">
                                    <span className="icon-present icon-2x icon-color icon-left"></span>
                                    <h6>Download</h6>
                                    <p>Reformidans conclusionemque vim at, in nec saepe consulatu meis recteque.</p>
                                    <a href="#">learn more</a>
                                </div>
                                <div className="col-md-4 workit-box">
                                    <span className="icon-settings icon-2x icon-color icon-left"></span>
                                    <h6>Instal app</h6>
                                    <p>Reformidans conclusionemque vim at, in nec saepe consulatu meis recteque.</p>
                                    <a href="#">learn more</a>
                                </div>
                                <div className="col-md-4 workit-box">
                                    <span className="icon-cup icon-2x icon-color icon-left"></span>
                                    <h6>Ready to use</h6>
                                    <p>Reformidans conclusionemque vim at, in nec saepe consulatu meis recteque.</p>
                                    <a href="#">learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="pricing" className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="heading centered">
                                    <h5>Pricing</h5>
                                    <p>
                                        Choose download package
						</p>
                                    <span></span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-wrapper">
                                    <div className="pricing-head">
                                        <sup>$</sup>
                                        <span className="pricing-price">0</span>
                                        <h4>Free</h4>
                                    </div>
                                    <div className="pricing-containt">
                                        <ul>
                                            <li><i className="fa fa-check"></i> Demo file</li>
                                            <li><i className="fa fa-check"></i> Update</li>
                                            <li><i className="fa fa-check"></i> File compressed</li>
                                            <li><i className="fa fa-remove"></i> Commercial use</li>
                                            <li><i className="fa fa-remove"></i> Support</li>
                                            <li><i className="fa fa-remove"></i> Documetation</li>
                                        </ul>
                                    </div>
                                    <div className="pricing-bottom">
                                        <a href="#" className="btn btn-primary">DOWNLOAD</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-wrapper">
                                    <div className="pricing-head">
                                        <sup>$</sup>
                                        <span className="pricing-price">14</span>
                                        <h4>Personal</h4>
                                    </div>
                                    <div className="pricing-containt">
                                        <ul>
                                            <li><i className="fa fa-check"></i> Demo file</li>
                                            <li><i className="fa fa-check"></i> Update</li>
                                            <li><i className="fa fa-remove"></i> File compressed</li>
                                            <li><i className="fa fa-remove"></i> Commercial use</li>
                                            <li><i className="fa fa-check"></i> Support</li>
                                            <li><i className="fa fa-check"></i> Documetation</li>
                                        </ul>
                                    </div>
                                    <div className="pricing-bottom">
                                        <a href="#" className="btn btn-primary">DOWNLOAD</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-wrapper">
                                    <div className="pricing-head">
                                        <sup>$</sup>
                                        <span className="pricing-price">99</span>
                                        <h4>Business</h4>
                                    </div>
                                    <div className="pricing-containt">
                                        <ul>
                                            <li><i className="fa fa-check"></i> Demo file</li>
                                            <li><i className="fa fa-check"></i> Update</li>
                                            <li><i className="fa fa-remove"></i> File compressed</li>
                                            <li><i className="fa fa-check"></i> Commercial use</li>
                                            <li><i className="fa fa-check"></i> Support</li>
                                            <li><i className="fa fa-check"></i> Documetation</li>
                                        </ul>
                                    </div>
                                    <div className="pricing-bottom">
                                        <a href="#" className="btn btn-primary">DOWNLOAD</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="subscribe" className="dark-section">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-8 col-md-offset-2">
                                <h5>Stay update with us</h5>
                                <p>
                                    Reformidans conclusionemque vim at, in nec saepe consulatu. Meis recteque vituperatoribus an mea, pri ex illud delectus. Ex ius paulo sapientem, graece alterum menandri ut vim.
					            </p>
                                <form>
                                    <fieldset className="subscribe-form">
                                        <input className="subscribe" type="text" placeholder="Enter your email address" />
                                        <button className="btn-subscribe" type="button"><i className="fa fa-paper-plane"></i></button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>

                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 widget">
                                <h5>About us</h5>
                                <p>
                                    Est quis urbanitas at honestatis sea in. Utamur offendit ei nec, eos an dolor facete rationibus. Reformidan conclusionemque vim at, in nec saepe consulatu. Meis recteque.
					            </p>
                                <ul className="social-link">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-md-2 widget">
                                <h5>Browse</h5>
                                <ul className="footer-link">
                                    <li><a href="#">About us</a></li>
                                    <li><a href="#">Featues</a></li>
                                    <li><a href="#">Testimoni</a></li>
                                    <li><a href="#">Our team</a></li>
                                    <li><a href="#">Affiliate</a></li>
                                    <li><a href="#">Help center</a></li>
                                    <li><a href="#">Site map</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <h5>Last from blog</h5>
                                <ul className="recent">
                                    <li>
                                        <a href="#"><img src={img15} className="alignleft" alt="" /></a>
                                        <h6><a href="#">Est quis urbanitas at</a></h6>
                                        <p>
                                            Est quis urbanitas at honestatis sea in utamur offendit ei nec.
							</p>
                                    </li>
                                    <li>
                                        <a href="#"><img src={img16} className="alignleft" alt="" /></a>
                                        <h6><a href="#">Sumo nobis sit ut sed</a></h6>
                                        <p>
                                            Rebum possit nec cu, graeci lobortis ad vix in modus zril per.
							</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 widget">
                                <h5>latest tweet</h5>
                                <div id="tweecool"></div>
                            </div>
                        </div>
                    </div>
                    <div className="subfooter">
                        <p>2020 &copy; Copyright <a href="https://mndsoftware.mooo.com/">MND Sofware</a> All rights Reserved.</p>
                    </div>
                </footer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(VendorPortalTest2)