/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import { Chips } from 'primereact/chips';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "RoleDocumentTemplate";

export class RoleDocumentTemplate extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            showTemplateModal: false,
            templateDescription: '',
            templateCode: '',
            templateSelectItems: [],
            itemGroupCode: null,
            roleDocumentTemplateId: null,
            itemGroupDescriptions: [],
            oldRoleDocumentDescriptions:[],
            //[STATE_FIELDS],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.setComboboxes();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        let roleDocumentDescriptions = [];
        let oldRoleDocumentDescriptions = this.state.oldRoleDocumentDescriptions
        if(Boolean(oldRoleDocumentDescriptions)){
            oldRoleDocumentDescriptions.forEach(element => {
                element.active=false;
            });
        }

        if (Boolean(this.state.itemGroupDescriptions) && this.state.itemGroupDescriptions.length > 0) {
            this.state.itemGroupDescriptions.forEach(element => {
                let e = oldRoleDocumentDescriptions.find(p=>p.itemGroupDescription === element);
                if(Boolean(e)){
                    e.active = true ;
                }
                else {
                    let obj = { itemGroupDescription: element };
                    oldRoleDocumentDescriptions.push(obj);
                }                
               
            });
        }

        let obj = {
            id:this.state.id,
            itemGroupCode: this.state.itemGroupCode,
            roleDocumentTemplateId: this.state.roleDocumentTemplateId,
            roleDocumentDescriptions: oldRoleDocumentDescriptions,
        };

        if (obj.id === null)
          {  
              await handleRequest("POST", "/RoleDocumentItemGroup/add", obj);
        
          }
        else
            await handleRequest("POST", "/RoleDocumentItemGroup/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {
        let roleDocumentDescriptions = []; 
        if(Boolean(row.roleDocumentDescriptions) && row.roleDocumentDescriptions.length>0){
            row.roleDocumentDescriptions.forEach(element => {
                roleDocumentDescriptions.push(element.itemGroupDescription); 
            });
           
        }

        this.formRef.current.setFieldsValue({
            itemGroupCode: row.itemGroupCode,
            roleDocumentTemplateId: row.roleDocumentTemplateId,
            itemGroupDescriptions: roleDocumentDescriptions,
        });

        this.setState({
            id:row.id,
            itemGroupCode: row.itemGroupCode,
            roleDocumentTemplateId: row.roleDocumentTemplateId,
            itemGroupDescriptions: roleDocumentDescriptions,
            oldRoleDocumentDescriptions: Boolean(row.roleDocumentDescriptions) ? row.roleDocumentDescriptions : [] ,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/RoleDocumentItemGroup/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/RoleDocumentItemGroup/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }

    setComboboxes = async () => {
        const response = await handleRequest("GET", "/RoleDocumentTemplate/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id,
                    element.value = element.templateCode + ' / ' + element.templateDescription;
            });
            this.setState({
                templateSelectItems: list,
            });
        }


    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            itemGroupCode:null,
            roleDocumentTemplateId: null,
            itemGroupDescriptions: [], 

        });

        this.setState({
            id:null,
            itemGroupCode:null,
            roleDocumentTemplateId: null,
            itemGroupDescriptions: [], 
            oldRoleDocumentDescriptions: [] ,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addRoleTemplate = async () => {
        let obj = {
            templateCode: this.state.templateCode,
            templateDescription: this.state.templateDescription
        };

        if (Boolean(obj.templateCode) && obj.templateCode.length > 0 && Boolean(obj.templateDescription) && obj.templateDescription.length > 0) {
            
           await handleRequest("POST", "/RoleDocumentTemplate/add", obj);


            this.setState({
                templateCode: "",
                templateDescription: "",
                showTemplateModal: false
            })
            this.setComboboxes();
            this.showSuccess();
        }
        else this.showError("Please type all inputs!");
    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Template").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Template" defaultMessage="Template" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="roleDocumentTemplateId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Template")) ? this.state.dynamicInputs.find(p => p.inputKey === "Template").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Template")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Template").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={6}>
                                                        <Select style={{ width: "100%" }} placeholder="Template" id="Template" value={this.state.roleDocumentTemplateId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                roleDocumentTemplateId: value
                                                            });
                                                            this.setState({ roleDocumentTemplateId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.templateSelectItems.map(i => (
                                                                <Option key={i.key} value={i.key}>{i.value}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                    <ColAnt>
                                                        <AntButton onClick={() => {
                                                            this.setState({
                                                                showTemplateModal: true
                                                            })
                                                        }} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}>
                                                            Add New
                                                      </AntButton>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "itemGroupCode").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="itemGroupCode" defaultMessage="Item Group Code" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="itemGroupCode"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "itemGroupCode")) ? this.state.dynamicInputs.find(p => p.inputKey === "itemGroupCode").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "itemGroupCode")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "itemGroupCode").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Item Group Code" style={{ width: "100%" }} id="itemGroupCode" value={this.state.itemGroupCode} onChange={(e) => { this.setState({ itemGroupCode: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemGroupDescription").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ItemGroupDescription" defaultMessage="Item Group Description" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="itemGroupDescriptions"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ItemGroupDescription")) ? this.state.dynamicInputs.find(p => p.inputKey === "ItemGroupDescription").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ItemGroupDescription")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ItemGroupDescription").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <div className="content-section implementation p-fluid">

                                                            <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.itemGroupDescriptions}
                                                                onChange={(e) => {
                                                                    this.formRef.current.setFieldsValue({
                                                                        itemGroupDescriptions: e.value
                                                                    });
                                                                    this.setState({ itemGroupDescriptions: e.value })
                                                                }}></Chips>
                                                        </div>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                    <Column title="S/N" dataIndex="index" />
                                    <Column title="Template" render={(value, row, index) => {

                                        if (Boolean(row.roleDocumentTemplate))
                                            return row.roleDocumentTemplate.templateCode + ' / ' + row.roleDocumentTemplate.templateDescription;

                                    }} />
                                    <Column title="Item Group Code" dataIndex="itemGroupCode" />
                                    <Column title="Item Group Descriptions" render={(value, row, index) => {
                                        let result = [];
                                    if (Boolean(row.roleDocumentDescriptions))
                                        row.roleDocumentDescriptions.forEach(element => {
                                            result.push(element.itemGroupDescription); 
                                        });
                                        return result.toString(); 

                                    }} />
                                    <Column title="Action" render={(value, row, index) => {

                                        return this.actionTemplate(row);

                                    }} />
                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showTemplateModal}
                            onHide={() => {
                                this.setState({
                                    showTemplateModal: false,
                                    templateCode: '',
                                    templateDescription: ''

                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Select Items</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>

                                    <Col sm="12">
                                        <Input id="todo" placeholder="Template Code" value={this.state.templateCode} onChange={(e) => this.setState({ templateCode: e.target.value })} />

                                    </Col>

                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>

                                    <Col sm="12">
                                        <Input id="todo" placeholder="Template Description" value={this.state.templateDescription} onChange={(e) => this.setState({ templateDescription: e.target.value })} />

                                    </Col>

                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showTemplateModal: false,
                                        templateCode: '',
                                        templateDescription: ''

                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addRoleTemplate} >Add</ReactButton>
                            </Modal.Footer>
                        </Modal>



                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleDocumentTemplate)
