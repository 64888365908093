/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "itp"; 

export class Itp extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            disciplineId: null,
            documentNo: null,
            revNo: null,
            description: null,
            workStartDate: null,
            tentativeSubmittalDate: null,
            transmittalNo: null,
            transmittalDate: null,
            approvalStatus: null,
            responseDate: null,
            remarks: null,

            disciplineSelectItems: [],


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillComboboxes();
    }

    fillComboboxes = async () => {

        const response2 = await handleRequest("GET", "/Discipline/getallByCompany");


        if (response2.data.length !== 0) {

            this.setState({
                disciplineSelectItems: response2.data
            });

        }
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            disciplineId: this.state.disciplineId,
            documentNo: this.state.documentNo,
            revNo: this.state.revNo,
            description: this.state.description,
            workStartDate: this.state.workStartDate,
            tentativeSubmittalDate: this.state.tentativeSubmittalDate,
            transmittalNo: this.state.transmittalNo,
            transmittalDate: this.state.transmittalDate,
            approvalStatus: this.state.approvalStatus,
            responseDate: this.state.responseDate,
            remarks: this.state.remarks,

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/QualityItp/add", obj);
        else
            await handleRequest("POST", "/QualityItp/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({

            disciplineId: row.disciplineId,
            documentNo: row.documentNo,
            revNo: row.revNo,
            description: row.description,
            workStartDate:Boolean(row.workStartDate) ? moment(row.workStartDate) : null, 
            tentativeSubmittalDate:Boolean(row.tentativeSubmittalDate) ? moment(row.tentativeSubmittalDate) : null, 
            transmittalNo: row.transmittalNo,
            transmittalDate: Boolean(row.transmittalDate) ? moment(row.transmittalDate) : null,
            approvalStatus: row.approvalStatus,
            responseDate: Boolean(row.responseDate) ? moment(row.responseDate) : null, 
            remarks: row.remarks,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            disciplineId: row.disciplineId,
            documentNo: row.documentNo,
            revNo: row.revNo,
            description: row.description,
            workStartDate:Boolean(row.workStartDate) ? moment(row.workStartDate) : null, 
            tentativeSubmittalDate:Boolean(row.tentativeSubmittalDate) ? moment(row.tentativeSubmittalDate) : null, 
            transmittalNo: row.transmittalNo,
            transmittalDate: Boolean(row.transmittalDate) ? moment(row.transmittalDate) : null,
            approvalStatus: row.approvalStatus,
            responseDate: Boolean(row.responseDate) ? moment(row.responseDate) : null, 
            remarks: row.remarks,


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/QualityItp/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/QualityItp/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            disciplineId: null,
            documentNo: null,
            revNo: null,
            description: null,
            workStartDate: null,
            tentativeSubmittalDate: null,
            transmittalNo: null,
            transmittalDate: null,
            approvalStatus: null,
            responseDate: null,
            remarks: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            disciplineId: null,
            documentNo: null,
            revNo: null,
            description: null,
            workStartDate: null,
            tentativeSubmittalDate: null,
            transmittalNo: null,
            transmittalDate: null,
            approvalStatus: null,
            responseDate: null,
            remarks: null,



        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.Discipline").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.Discipline" defaultMessage="Discipline" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="disciplineId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Discipline")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Discipline").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Discipline")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Discipline").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Discipline" id="SpecialProcess.Discipline" value={this.state.disciplineId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                disciplineId: value
                                                            });
                                                            this.setState({ disciplineId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.disciplineSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.DocumentNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.DocumentNo" defaultMessage="Document No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="documentNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.DocumentNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.DocumentNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.DocumentNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.DocumentNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Document No" style={{ width: "100%" }} id="SpecialProcess.DocumentNo" value={this.state.documentNo} onChange={(e) => { this.setState({ documentNo: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.RevNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.RevNo" defaultMessage="Rev. No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="revNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.RevNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.RevNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.RevNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.RevNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="SpecialProcess.RevNo" placeholder="Rev. No"
                                                            min={0} value={this.state.revNo} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    revNo: value
                                                                });
                                                                this.setState({ revNo: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.Description").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.Description" defaultMessage="Description" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="description"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Description")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Description").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Description")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Description").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Description" style={{ width: "100%" }} id="SpecialProcess.Description" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.WorkStartDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.WorkStartDate" defaultMessage="Work Start Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="workStartDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.WorkStartDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.WorkStartDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.WorkStartDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.WorkStartDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="SpecialProcess.WorkStartDate" format="DD-MM-YYYY" value={this.state.workStartDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                workStartDate: date
                                                            });
                                                            this.setState({
                                                                workStartDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.TentativeSubmittalDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.TentativeSubmittalDate" defaultMessage="Tentative Submittal Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="tentativeSubmittalDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TentativeSubmittalDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TentativeSubmittalDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TentativeSubmittalDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TentativeSubmittalDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="SpecialProcess.TentativeSubmittalDate" format="DD-MM-YYYY" value={this.state.tentativeSubmittalDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                tentativeSubmittalDate: date
                                                            });
                                                            this.setState({
                                                                tentativeSubmittalDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.TransmittalNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.TransmittalNo" defaultMessage="Transmittal No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="transmittalNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Transmittal No" style={{ width: "100%" }} id="SpecialProcess.TransmittalNo" value={this.state.transmittalNo} onChange={(e) => { this.setState({ transmittalNo: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.TransmittalDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.TransmittalDate" defaultMessage="Transmittal Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="transmittalDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.TransmittalDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="SpecialProcess.TransmittalDate" format="DD-MM-YYYY" value={this.state.transmittalDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                transmittalDate: date
                                                            });
                                                            this.setState({
                                                                transmittalDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.ApprovalStatus").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.ApprovalStatus" defaultMessage="Approval Status" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="approvalStatus"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ApprovalStatus")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ApprovalStatus").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ApprovalStatus")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ApprovalStatus").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Approval Status" id="SpecialProcess.ApprovalStatus" value={this.state.approvalStatus} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                approvalStatus: value
                                                            });
                                                            this.setState({ approvalStatus: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            <Option key={"Approved"} value={"Approved"}>Approved</Option>
                                                            <Option key={"U/R"} value={"U/R"}>U/R</Option>
                                                            <Option key={"C&R"} value={"C&R"}>{"C&R"}</Option>  
                                                            <Option key={"Cancelled"} value={"Cancelled"}>Cancelled</Option> 

                                                            
                                                             </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.ResponseDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.ResponseDate" defaultMessage="Response Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="responseDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ResponseDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ResponseDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ResponseDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.ResponseDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="SpecialProcess.ResponseDate" format="DD-MM-YYYY" value={this.state.responseDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                responseDate: date
                                                            });
                                                            this.setState({
                                                                responseDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SpecialProcess.Remarks").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SpecialProcess.Remarks" defaultMessage="Remarks" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="remarks"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Remarks")) ? this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Remarks").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Remarks")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SpecialProcess.Remarks").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Remarks" style={{ width: "100%" }} id="SpecialProcess.Remarks" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="S/N" dataIndex="index" />

                                    <Column title='Discipline' dataIndex='discipline' render={(value, row, index) => { return Boolean(value) ? value.disciplineName : ""; }} />

                                    <Column title='Document No' dataIndex='documentNo' render={(value, row, index) => { return value; }} />
                                    <Column title='Rev. No' dataIndex='revNo' render={(value, row, index) => { return value; }} />
                                    <Column title='Description' dataIndex='description' render={(value, row, index) => { return value; }} />
                                    <Column title='workStartDate' dataIndex='workStartDate' render={(value, row, index) =>  { return  Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />  
                                    <Column title='Tentative Submittal Date' dataIndex='tentativeSubmittalDate' render={(value, row, index) =>  { return  Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />  
                                    <Column title='Transmittal No' dataIndex='transmittalNo' render={(value, row, index) => { return value; }} />
                                    <Column title='Transmittal Date' dataIndex='transmittalDate' render={(value, row, index) =>  { return  Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />  
                                    <Column title='Approval Status' dataIndex='approvalStatus' render={(value, row, index) => { return value; }} />
                                    <Column title='Response Date' dataIndex='responseDate' render={(value, row, index) =>  { return  Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />  
                                    <Column title='Remarks' dataIndex='remarks' render={(value, row, index) => { return value; }} />


                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />

                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Itp)
