import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { BuilderPage } from "./pages/BuilderPage";

import AddItemToSelects from './pages/settings/AddItemToSelects/AddItemToSelect';
import MoviesPage from './pages/settings/MoviesPage';
import CustomerLog from './pages/settings/CustomerLog';
import ClientLog from './pages/settings/ClientLog';
import VendorLog from './pages/settings/VendorLog';
import Loading from './pages/settings/Loading';
import LoadingMobile from './pages/settings/LoadingMobile';
import CheckPoint from './pages/settings/CheckPoint';
import CheckPointMobile from './pages/settings/CheckPointMobile';
import FuelRefill from './pages/settings/FuelRefill';
import FuelRefillMobile from './pages/settings/FuelRefillMobile';
import FuelLog from './pages/settings/FuelLog';
import Breakdown from './pages/settings/Breakdown';
import BreakdownMobile from './pages/settings/BreakdownMobile';
import BreakdownTypes from './pages/settings/BreakdownTypes';
import ShipmentLog from './pages/settings/ShipmentLog';
import DailyShipments from './pages/settings/DailyShipments';
import ShipmentReport from './pages/settings/ShipmentReport';
import PersonalInfo from './pages/settings/PersonalInfo';
import CodeDefine from './pages/administration/CodeDefine';
import Shifts from "./pages/settings/Shifts";
import Department from './pages/settings/Department';
import User from './pages/settings/User';
import OrganizationalLevel from './pages/settings/OrganizationalLevel';
import Discipline from './pages/settings/Discipline';
import RoleGroup from './pages/settings/RoleGroup';
import RoleGroupTemplate from './pages/Template/RoleGroupTemplate';
import Designation from './pages/settings/Designation';
import ItemTest from './pages/settings/AddItemToSelects/ItemTest';
import Company from "./pages/company/Company";
import MNDClientLog from "./pages/company/MNDClientLog";
import MNDProducts from "./pages/MND/MNDProducts";
import DynamicForm from "./pages/MND/DynamicForm";
import CompanyDetail from "./pages/company/CompanyDetail";
import TrailerLocation2 from "./pages/settings/TrailerLocation2";
import ProjectProfiles from "./pages/settings/ProjectProfiles";
import LanguageSettings from "./pages/settings/LanguageSettings";
import Whatis from "./pages/settings/Whatis";
import Logging from "./pages/settings/Logging";
import Authorization from "./pages/settings/Authorization";
import ItpLog from "./pages/settings/ItpLog";
import ChecklistLog from "./pages/settings/ChecklistLog";
import TableSettings from "./pages/settings/TableSettings";
import OnlineUsers from "./pages/settings/OnlineUsers";
import DocumentTypes from "./pages/settings/DocumentTypes";
import DocumentLog from "./pages/settings/DocumentLog";
import DocumentedCreator from "./pages/Documentation/DocumentedCreator";
import DocumentedInfoLog from "./pages/Documentation/DocumentedInfoLog";
import ReportInfoLog from "./pages/Documentation/ReportInfoLog";
import LinkedText from "./pages/Documentation/LinkedText"; 
import FileFolders from "./pages/Documentation/FileFolders";
import InventoryLog from "./pages/InventoryLog/InventoryLog";
import TextToPara from "./pages/settings/TextToPara";
import InspectionRequest from "./pages/settings/InspectionRequest";
import EquipmentLog from "./pages/settings/EquipmentLog";
import MaintenanceLog from "./pages/settings/MaintenanceLog";
import MaintenanceChecklistLog from "./pages/settings/MaintenanceChecklistLog";
import PersonelLog from "./pages/settings/PersonelLog";
import DynamicPage from "./pages/settings/DynamicPage";
import ManagementLevel from "./pages/settings/ManagementLevel";
import Location from "./pages/settings/Location";
import Activity from "./pages/settings/Activity";
import ItpLogExpand from "./pages/settings/ItpLogExpand";
import Wps from "./pages/settings/Wps";
import CompaniesDetail from "./pages/company/CompaniesDetail";
import ChecklistReport from "./pages/settings/ChecklistReport";
import ITPChecklistPopup from "./pages/settings/ITPChecklistPopup";
import TestMetronic from "./pages/settings/TestMetronic";
import CascaderEditText from "./pages/settings/CascaderEditText";
import InspectionResult from "./pages/settings/InspectionResult";
import EquipmentTypes from "./pages/settings/EquipmentTypes";
import MaintenanceTypes from "./pages/settings/MaintenanceTypes";
import VehicleRegistration from "./pages/settings/VehicleRegistration";
import Assignment from "./pages/settings/Assignment";
import PersonelDevice from "./pages/settings/PersonelDevice";
import LocationActivity from "./pages/location/LocationActivity";
import ResourceTimeline from "./pages/location/ResourceTimeline";
import LocationAuthorization from "./pages/location/LocationAuthorization";
import ActivityLog from "./pages/location/ActivityLog";
import Resources from "./pages/location/Resources";
import LocationDepartment from "./pages/location/LocationDepartment";
import AutoUsername from "./pages/authorization/AutoUsername";
import DynamicTable from "./pages/settings/DynamicTable";
import CompanyProjects from "./pages/settings/CompanyProjects";
import MobileDashboard from "./pages/settings/MobileDashboard";
import SalesQuote from "./pages/settings/SalesQuote";
import OrderList from "./pages/settings/OrderList";
import CustomerOrderList from "./pages/settings/CustomerOrderList";
import WorkFlow from "./pages/workflow/WorkFlow";
import WorkFlowPeriod from "./pages/workflow/WorkFlowPeriod";
import TimelineLog from "./pages/notifications/TimelineLog";
import WeldList from "./pages/settings/WeldList";
import DynamicMenu from "./pages/settings/DynamicMenu";
import WFStart from "./pages/workflow/WFStart";
import WFPreparation from "./pages/settings/WFPreparation";
import WFInformative from "./pages/settings/WFInformative";
import WFReview from "./pages/settings/WFReview";
import WFConfirmation from "./pages/settings/WFConfirmation";
import Questionnary from "./pages/Questionnaire/Questionnary";
import TakeQuestionnaire from "./pages/Questionnaire/TakeQuestionnaire";
import MaterialLog from "./pages/settings/MaterialLog";
import MaterialTypes from "./pages/settings/MaterialTypes";
import { InspectionLevel } from "./pages/settings/InspectionLevel";
import StoreLog from "./pages/Procurement/StoreLog";
import RfqWorkflow from "./pages/Procurement/RfqWorkflow"
import StockGroup from "./pages/Procurement/StockGroup";
import StockSectionLog from "./pages/Procurement/StockSectionLog";
import MaterialRequisitionTemplate from "./pages/Procurement/MaterialRequisitionTemplate";
import TruckCostCalculation from "./pages/settings/TruckCostCalculation";
import RentedEquipmentAverages from "./pages/settings/RentedEquipmentAverages";
import RentalTruckAgreement from "./pages/settings/RentalTruckAgreement";
import ActivityEvent from "./pages/location/ActivityEvent"
import GSTCActivityEvent from "./pages/location/GSTCActivityEvent"
import IBMActivityEvent from "./pages/location/IBMActivityEvent"
import OrganizationalChart from "./pages/settings/OrganizationalChart"
import QRCodeGenerator from "./pages/settings/QRCodeGenerator"
import Complaints from "./pages/settings/Complaints"
import ComplaintsRespond from "./pages/settings/ComplaintsRespond"
import InvoiceLog from "./pages/settings/InvoiceLog"
import HowToUse from "./pages/settings/HowToUse"
import UnitsConverter from "./pages/settings/UnitsConverter"
import Units from "./pages/settings/Units"
import InspectorLocation from "./pages/settings/InspectorLocation"
import ActionType from "./pages/transmittal/ActionType"
import Deliverables from "./pages/transmittal/Deliverables"
import OutgoingTransmittal from "./pages/transmittal/OutgoingTransmittal"
import ReplyType from "./pages/transmittal/ReplyType"
import Status from "./pages/transmittal/Status"
import StatusMatrix from "./pages/transmittal/StatusMatrix"
import SubmittalLog from "./pages/transmittal/SubmittalLog"
import TransmittalDocumentType from "./pages/transmittal/TransmittalDocumentType"
import TransmittalMaterialEquipment from "./pages/transmittal/TransmittalMaterialEquipment"
import TransmittalWorkflow from "./pages/transmittal/TransmittalWorkflow"
import WeldMap from "./pages/settings/WeldMap"
import Start from "./pages/workflowActivityPages/Start"
import Preparation from "./pages/workflowActivityPages/Preparation"
import Informative from "./pages/workflowActivityPages/Informative"
import Review from "./pages/workflowActivityPages/Review"
import Confirmation from "./pages/workflowActivityPages/Confirmation"
import PurchaseOrder from "./pages/settings/PurchaseOrder"
import LocationTimeline from "./pages/location/LocationTimeline"
import LocationActivity2 from "./pages/location/LocationActivity2";
import ToDoList from "./pages/ToDoList/ToDoList"
import ProjectItemsTakeOffList from "./pages/Sales/ProjectItemsTakeOffList"
import ComListing from "./pages/RegulatedVendor/ComListing"
import RegulatedVendor from "./pages/RegulatedVendor/RegulatedVendor"
import SatipLog from "./pages/SaicSatipLog/SatipLog"
import SaicLog from "./pages/SaicSatipLog/SaicLog"
import RequestForQuotation from "./pages/settings/RequestForQuotation"
import InspectionAssignmentPackage from "./pages/Procurement/InspectionAssignmentPackage"
import MaterialRequisition from "./pages/Procurement/MaterialRequisition"

import VendorRecord from "./pages/settings/VendorRecord"
import VendorPortal from "./pages/settings/VendorPortal"
import VendorPortalTest from "./pages/settings/VendorPortalTest"
import VendorPortalTest2 from "./pages/settings/VendorPortalTest2"
import MRChecklist from "./pages/Procurement/MRChecklist"
import IAPChecklist from "./pages/Procurement/IAPChecklist"
import PurchasingAuthority from "./pages/settings/PurchasingAuthority"
import VendorEvaluation from "./pages/Procurement/VendorEvaluation"
import VendorEvaluationSelect from "./pages/Procurement/VendorEvaluationSelect"
import VendorResponse from "./pages/Procurement/VendorResponse"
import InspectionNotification from "./pages/Inspection/InspectionNotification"
import InspectionResultNotification from "./pages/Inspection/InspectionResultNotification"
import InspectionResultClient from "./pages/Inspection/InspectionResultClient"
import VendorScoring from "./pages/Procurement/VendorScoring"
import ShippingRelease from "./pages/Procurement/ShippingRelease"
import Nmr from "./pages/Documentation/Nmr";
import NonMaterialRequirements from "./pages/Documentation/NonMaterialRequirements";
import SaEngineeringProcedures from "./pages/Documentation/SaEngineeringProcedures";
import SaEngineeringStandards from "./pages/Documentation/SaEngineeringStandards";
import SaMaterialsSystemSpecifications from "./pages/Documentation/SaMaterialsSystemSpecifications";
import Sa175Forms from "./pages/Documentation/Sa175Forms";

import MaintenancePlan from "./pages/Equipment/MaintenancePlan";
import RoleDocumentItem from "./pages/Documentation/RoleDocumentItem";
import MaintenanceChecklist from "./pages/Equipment/MaintenanceChecklist";
import InspectionDates from "./pages/settings/InspectionDates"

import Submittal from "./pages/workflowActivityPages/Submittal"
import Approval from "./pages/workflowActivityPages/Approval"
import ClientReply from "./pages/workflowActivityPages/ClientReply"
import ContractSettings from "./pages/ContractSettings/ContractSettings"

import ArchivingWorkflow from "./pages/archive/ArchivingWorkflow"
import ArchivingVolume from "./pages/archive/ArchivingVolume"
import Release from "./pages/archive/Release"
import Receiving from "./pages/archive/Receiving"
import Auditlog from "./pages/MND/Auditlog";
import HolidayLog from "./pages/HumanResources/HolidayLog"
import EmployeeHiring from "./pages/HumanResources/EmployeeHiring"
import TimesheetWorkflow from "./pages/HumanResources/TimesheetWorkflow"
import TimesheetLog from "./pages/HumanResources/TimesheetLog"
import PersonalTimesheet from "./pages/HumanResources/PersonalTimesheet"
import PressureInjury from "./pages/PresureInjury/PressureInjury"
import AbsenceInfraction from "./pages/HumanResources/AbsenceInfraction"
import DisciplinaryActions from "./pages/HumanResources/DisciplinaryActions"
import BusinessTripPolicy from "./pages/HumanResources/BusinessTripPolicy"
import BusinessTripRequest from "./pages/HumanResources/BusinessTripRequest"
import ItemDimension from "./pages/properties/ItemDimension"
import ItemDescription from "./pages/properties/ItemDescription"
import PhysicalProperties from "./pages/properties/PhysicalProperties"
import ProductDefine from "./pages/properties/ProductDefine"
import LeavePolicy from "./pages/HumanResources/LeavePolicy"
import LeaveRequest from "./pages/HumanResources/LeaveRequest"
import RoleDocument from "./pages/HumanResources/RoleDocument"
import RoleDocumentTemplate from "./pages/HumanResources/RoleDocumentTemplate"
import PaymentSchdule from "./pages/PaymentSchdule/PaymentSchdule"
import CreateJavaClass from "./pages/MND/CreateJavaClass";
import generated from "./pages/dynamicForm/generated";



import BereavementNoticeForm from "./pages/settings/BereavementNoticeForm"
import Clinic from "./pages/settings/Clinic"
import OvertimeRequest from "./pages/settings/OvertimeRequest"
import UnitsConverterMND from "./pages/settings/UnitsConverterMND"
import SalarySetting from "./pages/Finance/SalarySetting"
import EndOfServiceCalculation from "./pages/Finance/EndOfServiceCalculation"
import EmployeesSalary from "./pages/Finance/EmployeesSalary"
import PersonalSalary from "./pages/Finance/PersonalSalary"
import ItemReceiving from "./pages/Procurement/ItemReceiving";
import RequestForPurchasing from "./pages/Procurement/RequestForPurchasing"
import TimelineQRGenerator from "./pages/settings/TimelineQRGenerator";
import TimelineQRSetting from "./pages/settings/TimelineQRSetting";
import EmployeeRequest from "./pages/HumanResources/EmployeeRequest";
import TimelineQRScan from "./pages/settings/TimelineQRScan";
import ClientPersonnelLog from "./pages/quality/ClientPersonnelLog";
import ManpowerLog from "./pages/quality/ManpowerLog";
import Manpower from "./pages/quality/Manpower";
import Mrm from "./pages/quality/Mrm";
import Audit from "./pages/quality/Audit";

import Pqi from "./pages/quality/Pqi";
import ProjectProgress from "./pages/quality/ProjectProgress";
import ViolationLog from "./pages/quality/ViolationLog";
import InspectionLog from "./pages/quality/InspectionLog";
import SpecialProcess from "./pages/quality/SpecialProcess";
import Itp from "./pages/quality/Itp";
import ViolationSummary from "./pages/quality/ViolationSummary";
import InspectionSummary from "./pages/quality/InspectionSummary";
import DocumentSubmittal from "./pages/quality/DocumentSubmittal";
import NotificationWorkflow from "./pages/notifications/NotificationWorkflow";




import { DashboardPage } from "./pages/DashboardPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
let isMobile;

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    useEffect(() => {
        //console.log('Home page');
        isMobile = /iPhone|webOS|Windows Phone|BlackBerry|iPad|iPod|Android/i.test(navigator.userAgent);
        //console.log(isMobile);

    }, [])

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {isMobile ? (
                    <Redirect exact from="/" to="/MobileDashboard" />
                ) : (
                    <Redirect exact from="/" to="/dashboard" />
                )}
                {isMobile && <Redirect exact from="/dashboard" to="/MobileDashboard" />}
                {isMobile && <Redirect exact from="/Loading" to="/LoadingMobile" />}
                {isMobile && (
                    <Redirect exact from="/CheckPoint" to="/CheckPointMobile" />
                )}
                {isMobile && (
                    <Redirect exact from="/FuelRefill" to="/FuelRefillMobile" />
                )}
                {isMobile && (
                    <Redirect exact from="/Breakdown1" to="/BreakdownMobile1" />
                )}
            
                <ContentRoute path="/CompanyProjects" component={CompanyProjects} />
                <ContentRoute path="/ClientPersonnelLog" component={ClientPersonnelLog} />
                <ContentRoute path="/pqi" component={Pqi} />
                <ContentRoute path="/ProjectProgress" component={ProjectProgress} />
                <ContentRoute path="/ViolationLog" component={ViolationLog} />
                <ContentRoute path="/inspectionlog" component={InspectionLog} />
                <ContentRoute path="/SpecialProcess" component={SpecialProcess} />
                <ContentRoute path="/itp" component={Itp} />
                <ContentRoute path="/ViolationSummary" component={ViolationSummary} />
                <ContentRoute path="/InspectionSummary" component={InspectionSummary} />
                <ContentRoute path="/DocumentSubmittal" component={DocumentSubmittal} />


                <ContentRoute path="/Mrm" component={Mrm} />
                <ContentRoute path="/ManpowerLog" component={ManpowerLog} />
                <ContentRoute path="/Manpower" component={Manpower} />
                <ContentRoute path="/Audit" component={Audit} />
                <ContentRoute path="/DynamicTable" component={DynamicTable} />
              
                <ContentRoute path="/TestMetronic" component={TestMetronic} />
                {/* <ContentRoute path="/dashboard" component={Dashboard} /> */}
                <ContentRoute path="/MobileDashboard" component={MobileDashboard} />
                {/* <ContentRoute path="/docs" component={DocsPage} /> */}
                <ContentRoute path="/addItemToSelects" component={AddItemToSelects} />
                <ContentRoute path="/moviesPage" component={MoviesPage} />
                <ContentRoute path="/ItemTest" component={ItemTest} />
                <ContentRoute path="/LanguageSettings" component={LanguageSettings} />
                <ContentRoute path="/Whatis" component={Whatis} />
                <ContentRoute path="/User" component={User} />
                <ContentRoute path="/Authorization" component={Authorization} />
                <ContentRoute path="/Company" component={Company} />
                <ContentRoute path="/NotificationWorkflow" component={NotificationWorkflow} />
                <ContentRoute path="/CompanyDetail" component={CompanyDetail} />
                <ContentRoute path="/MNDClientLog" component={MNDClientLog} />
                <ContentRoute path="/ProjectProfiles" component={ProjectProfiles} />
                <ContentRoute path="/ClientLog" component={ClientLog} />
                <ContentRoute path="/VendorLog" component={VendorLog} />
                <ContentRoute path="/CustomerLog" component={CustomerLog} />
                <ContentRoute path="/PersonalInfo" component={PersonalInfo} />
                <ContentRoute path="/Shifts" component={Shifts} />
                <ContentRoute path="/Department2" component={Department} />
                <ContentRoute path="/OrganizationalLevel" component={OrganizationalLevel} />
                <ContentRoute path="/Discipline" component={Discipline} />
                <ContentRoute path="/RoleGroup" component={RoleGroup} />
                <ContentRoute path="/RoleGroupTemplate" component={RoleGroupTemplate} />
                <ContentRoute path="/Designation" component={Designation} />
                <ContentRoute path="/Loading" component={Loading} />
                <ContentRoute path="/LoadingMobile" component={LoadingMobile} />
                <ContentRoute path="/CheckPoint" component={CheckPoint} />
                <ContentRoute path="/CheckPointMobile" component={CheckPointMobile} />
                <ContentRoute path="/FuelRefill" component={FuelRefill} />
                <ContentRoute path="/FuelRefillMobile" component={FuelRefillMobile} />
                <ContentRoute path="/Breakdown1" component={Breakdown} />
                <ContentRoute path="/BreakdownMobile1" component={BreakdownMobile} />
                <ContentRoute path="/FuelLog" component={FuelLog} />
                <ContentRoute path="/ShipmentLog" component={ShipmentLog} />
                <ContentRoute path="/DailyShipments" component={DailyShipments} />
                <ContentRoute path="/ShipmentReport" component={ShipmentReport} />
                <ContentRoute path="/TrailerLocation2" component={TrailerLocation2} />
                <ContentRoute path="/ChecklistLog" component={ChecklistLog} />
                <ContentRoute path="/Logging" component={Logging} />
                <ContentRoute path="/TableSettings" component={TableSettings} />
                <ContentRoute path="/OnlineUsers" component={OnlineUsers} />
                <ContentRoute path="/DocumentTypes" component={DocumentTypes} />
                <ContentRoute path="/ManagementLevel" component={ManagementLevel} />
                <ContentRoute path="/DocumentLog" component={DocumentLog} />
                <ContentRoute path="/DocumentedCreator" component={DocumentedCreator} />
                <ContentRoute path="/DocumentedInfoLog" component={DocumentedInfoLog} />
                <ContentRoute path="/ReportInfoLog" component={ReportInfoLog} /> 
                <ContentRoute path="/LinkedText" component={LinkedText} /> 

                <ContentRoute path="/FileFolders" component={FileFolders} />
                <ContentRoute path="/InventoryLog" component={InventoryLog} />
                <ContentRoute path="/TextToPara" component={TextToPara} />
                <ContentRoute path="/InspectionRequest" component={InspectionRequest} />
                <ContentRoute path="/TruckCostCalculation" component={TruckCostCalculation} />
                <ContentRoute path="/RentedEquipmentAverages" component={RentedEquipmentAverages} />
                <ContentRoute path="/RentalTruckAgreement" component={RentalTruckAgreement} />
                <ContentRoute path="/EquipmentLog" component={EquipmentLog} />
                <ContentRoute path="/EquipmentTypes" component={EquipmentTypes} />
                <ContentRoute path="/BreakdownTypes" component={BreakdownTypes} />
                <ContentRoute path="/MaintenanceLog" component={MaintenanceLog} />
                <ContentRoute path="/MaintenanceChecklistLog" component={MaintenanceChecklistLog} />
                <ContentRoute path="/MaintenanceTypes" component={MaintenanceTypes} />
                <ContentRoute path="/VehicleRegistration" component={VehicleRegistration} />
                <ContentRoute path="/PersonelLog" component={PersonelLog} />
                <ContentRoute path="/Assignment" component={Assignment} />
                <ContentRoute path="/PersonelDevice" component={PersonelDevice} />
                <ContentRoute path="/DynamicPage" component={DynamicPage} />
                <ContentRoute path="/Location" component={Location} />
                <ContentRoute path="/Activity" component={Activity} />
                <ContentRoute path="/ItpLog" component={ItpLog} />
                <ContentRoute path="/CascaderEditText" component={CascaderEditText} />
                <ContentRoute path="/CompaniesDetail" component={CompaniesDetail} />
                <ContentRoute path="/ItpLogExpand" component={ItpLogExpand} />
                <ContentRoute path="/ChecklistReport" component={ChecklistReport} />
                <ContentRoute path="/ITPChecklistPopup/:id?" component={ITPChecklistPopup} />
                <ContentRoute path="/InspectionResult" component={InspectionResult} />
                <ContentRoute path="/InspectionLevel" component={InspectionLevel} />
                <ContentRoute path="/Wps" component={Wps} />
                <ContentRoute path="/MNDProducts" component={MNDProducts} />
                <ContentRoute path="/CodeDefine" component={CodeDefine} />
                <ContentRoute path="/LocationActivity" component={LocationActivity} />
                <ContentRoute path="/ResourceTimeline" component={ResourceTimeline} />
                <ContentRoute path="/LocationAuthorization" component={LocationAuthorization} />
                <ContentRoute path="/ActivityLog" component={ActivityLog} />
                <ContentRoute path="/LocationDepartment" component={LocationDepartment} />
                <ContentRoute path="/AutoUsername" component={AutoUsername}/>
                <ContentRoute path="/SalesQuote" component={SalesQuote}/>
                <ContentRoute path="/OrderList1" component={OrderList}/>
                <ContentRoute path="/CustomerOrderList" component={CustomerOrderList}/>
                <ContentRoute path="/WorkFlow2" component={WorkFlow} />
                <ContentRoute path="/WorkFlowPeriod" component={WorkFlowPeriod} />
                <ContentRoute path="/TimelineLog" component={TimelineLog} />
                <ContentRoute path="/WeldList" component={WeldList} />
                <ContentRoute path="/DynamicMenu" component={DynamicMenu} />
                <ContentRoute path="/WFStart" component={WFStart} />
                <ContentRoute path="/WFPreparation" component={WFPreparation} />
                <ContentRoute path="/WFInformative" component={WFInformative} />
                <ContentRoute path="/WFReview" component={WFReview} />
                <ContentRoute path="/WFConfirmation" component={WFConfirmation} />
                <ContentRoute path="/Resources" component={Resources}/>
                <ContentRoute path="/Questionnary" component={Questionnary} />
                <ContentRoute path="/MaterialLog" component={MaterialLog} />
                <ContentRoute path="/MaterialTypes" component={MaterialTypes} />
                <ContentRoute path="/TakeQuestionnaire" component={TakeQuestionnaire}/>
                <ContentRoute path="/ActivityEvent" component={ActivityEvent} />
                <ContentRoute path="/StoreLog" component={StoreLog} />
                <ContentRoute path="/StockGroup" component={StockGroup} />
                <ContentRoute path="/StockSectionLog" component={StockSectionLog} />

                <ContentRoute path="/FrappeActivityEvent" component={ActivityEvent} />
                <ContentRoute path="/GSTCActivityEvent" component={GSTCActivityEvent} />
                <ContentRoute path="/IBMActivityEvent" component={IBMActivityEvent} />
                <ContentRoute path="/QRCodeGenerator" component={QRCodeGenerator} />
                <ContentRoute path="/OrganizationalChart" component={OrganizationalChart} />
                <ContentRoute path="/Complaints1" component={Complaints} />
                <ContentRoute path="/ComplaintsRespond" component={ComplaintsRespond} />
                <ContentRoute path="/InvoiceLog" component={InvoiceLog} />
                <ContentRoute path="/HowToUse" component={HowToUse} />
                <ContentRoute path="/UnitsConverter" component={UnitsConverter} />
                <ContentRoute path="/Units" component={Units} />
                <ContentRoute path="/InspectorLocation" component={InspectorLocation} />

                <ContentRoute path="/ActionType" component={ActionType} />
                <ContentRoute path="/Deliverables" component={Deliverables} />
                <ContentRoute path="/OutgoingTransmittal" component={OutgoingTransmittal} />
                <ContentRoute path="/ReplyType" component={ReplyType} />
                <ContentRoute path="/Status" component={Status} />
                <ContentRoute path="/StatusMatrix" component={StatusMatrix} />
                <ContentRoute path="/SubmittalLog" component={SubmittalLog} />
                <ContentRoute path="/TransmittalDocumentType" component={TransmittalDocumentType} />
                <ContentRoute path="/TransmittalMaterialEquipment" component={TransmittalMaterialEquipment} />
                <ContentRoute path="/TransmittalWorkflow" component={TransmittalWorkflow} />
                <ContentRoute path="/Start" component={Start} />
                <ContentRoute path="/Preparation" component={Preparation} />
                <ContentRoute path="/Informative" component={Informative} />
                <ContentRoute path="/Review" component={Review} />
                <ContentRoute path="/Confirmation" component={Confirmation} />


                <ContentRoute path="/WeldMap" component={WeldMap} />
                <ContentRoute path="/PurchaseOrder" component={PurchaseOrder} />
                <ContentRoute path="/LocationTimeline" component={LocationTimeline}/>
                <ContentRoute path="/LocationActivity2" component={LocationActivity2} />

                <ContentRoute path="/ToDoList" component={ToDoList} />
                <ContentRoute path="/ProjectItemsTakeOffList" component={ProjectItemsTakeOffList} />
                <ContentRoute path="/RegulatedVendor" component={RegulatedVendor} />
                <ContentRoute path="/ComListing" component={ComListing} />
                <ContentRoute path="/SatipLog" component={SatipLog} />
                <ContentRoute path="/SaicLog" component={SaicLog} />
                <ContentRoute path="/RequestForQuotation" component={RequestForQuotation} />
                <ContentRoute path="/InspectionAssignmentPackage" component={InspectionAssignmentPackage} />
                <ContentRoute path="/MaterialRequisition" component={MaterialRequisition} />
                <ContentRoute path="/RequestForQuotation" component={RequestForQuotation} />
                <ContentRoute path="/VendorRecord" component={VendorRecord} />
                <ContentRoute path="/VendorPortal" component={VendorPortal} />
                <ContentRoute path="/VendorPortalTest" component={VendorPortalTest} />
                <ContentRoute path="/VendorPortalTest2" component={VendorPortalTest2} />
                <ContentRoute path="/MRChecklist" component={MRChecklist} />
                <ContentRoute path="/IAPChecklist" component={IAPChecklist} />
                <ContentRoute path="/PurchasingAuthority" component={PurchasingAuthority} />
                <ContentRoute path="/VendorEvaluation" component={VendorEvaluation} />
                <ContentRoute path="/VendorEvaluationSelect" component={VendorEvaluationSelect} />
                <ContentRoute path="/InspectionNotification" component={InspectionNotification} />
                <ContentRoute path="/InspectionResultNotification" component={InspectionResultNotification} />
                <ContentRoute path="/InspectionResultClient" component={InspectionResultClient} />

                <ContentRoute path="/ClientReply" component={ClientReply} />
                <ContentRoute path="/Submittal" component={Submittal} />
                <ContentRoute path="/Approval" component={Approval} />

                <ContentRoute path="/ArchivingWorkflow" component={ArchivingWorkflow} />
                <ContentRoute path="/ArchivingVolume" component={ArchivingVolume} />
                <ContentRoute path="/Receiving" component={Receiving} />
                <ContentRoute path="/Release" component={Release} />
                <ContentRoute path="/Auditlog" component={Auditlog} />
                <ContentRoute path="/HolidayLog" component={HolidayLog}/>
                <ContentRoute path="/EmployeeHiring" component={EmployeeHiring}/>
                <ContentRoute path="/TimesheetWorkflow" component={TimesheetWorkflow}/>
                <ContentRoute path="/TimesheetLog" component={TimesheetLog}/>
                <ContentRoute path="/PersonalTimesheet" component={PersonalTimesheet}/>
                <ContentRoute path="/AbsenceInfraction" component={AbsenceInfraction}/>
                <ContentRoute path="/DisciplinaryActions" component={DisciplinaryActions}/>
                <ContentRoute path="/BusinessTripPolicy" component={BusinessTripPolicy}/>
                <ContentRoute path="/ItemDimension" component={ItemDimension}/>
                <ContentRoute path="/ItemDescription" component={ItemDescription}/>
                <ContentRoute path="/PhysicalProperties" component={PhysicalProperties}/>
                <ContentRoute path="/ProductDefine" component={ProductDefine}/>
                <ContentRoute path="/CreateJavaClass" component={CreateJavaClass} />
                <ContentRoute path="/BusinessTripRequest" component={BusinessTripRequest} />
                <ContentRoute path="/LeavePolicy" component={LeavePolicy} />
                <ContentRoute path="/RoleDocument" component={RoleDocument} />
                <ContentRoute path="/RoleDocumentTemplate" component={RoleDocumentTemplate} />
                <ContentRoute path="/LeaveRequest" component={LeaveRequest} />
                <ContentRoute path="/PaymentSchdule" component={PaymentSchdule} />
                <ContentRoute path="/VendorResponse" component={VendorResponse} />
                <ContentRoute path="/PressureInjury" component={PressureInjury} />
                <ContentRoute path="/NonMaterialRequirements" component={NonMaterialRequirements} />
                <ContentRoute path="/Nmr" component={Nmr} />
                <ContentRoute path="/SAEngineeringProcedures" component={SaEngineeringProcedures} />
                <ContentRoute path="/SAEngineeringStandards" component={SaEngineeringStandards} />
                <ContentRoute path="/SAMaterialsSystemSpecifications" component={SaMaterialsSystemSpecifications} />
                <ContentRoute path="/SA175Forms" component={Sa175Forms} />
                <ContentRoute path="/MaterialRequisitionTemplate" component={MaterialRequisitionTemplate} />


                <ContentRoute path="/InspectionDates" component={InspectionDates} />
                <ContentRoute path="/VendorScoring" component={VendorScoring} />
                <ContentRoute path="/ShippingRelease" component={ShippingRelease} />
                <ContentRoute path="/MaintenancePlan" component={MaintenancePlan} />
                <ContentRoute path="/RoleDocumentItem" component={RoleDocumentItem} />
                <ContentRoute path="/MaintenanceChecklist" component={MaintenanceChecklist} />
                <ContentRoute path="/BereavementNoticeForm" component={BereavementNoticeForm} />
                <ContentRoute path="/Clinic" component={Clinic} />
                <ContentRoute path="/OvertimeRequest" component={OvertimeRequest} />
                <ContentRoute path="/ContractSettings" component={ContractSettings} />
                <ContentRoute path="/UnitsConverterMND" component={UnitsConverterMND} />
                <ContentRoute path="/DynamicForm" component={DynamicForm} />
                <ContentRoute path="/Generated" component={generated} />
                <ContentRoute path="/SalarySetting" component={SalarySetting} />
                <ContentRoute path="/EndOfServiceCalculation" component={EndOfServiceCalculation} />
                <ContentRoute path="/ItemReceiving" component={ItemReceiving} />
                <ContentRoute path="/RequestForPurchasing" component={RequestForPurchasing} />
                <ContentRoute path="/EmployeesSalary" component={EmployeesSalary} />
                <ContentRoute path="/TimelineQRGenerator" component={TimelineQRGenerator} />
                <ContentRoute path="/TimelineQRSetting" component={TimelineQRSetting} />
                <ContentRoute path="/EmployeeRequest" component={EmployeeRequest}/>
                <ContentRoute path="/TimelineQRScan" component={TimelineQRScan} />
                <ContentRoute path="/RfqWorkflow" component={RfqWorkflow} />
                {/* <ContentRoute path="/Messaging" component={Messaging} />   */}
                <Redirect to="/error/error-v1" />

                {/* <ContentRoute path="/google-material" component={GoogleMaterialPage}/>
                <ContentRoute path="/react-bootstrap" component={ReactBootstrapPage}/>
                <ContentRoute path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to="error/error-v1" />

            </Switch>
        </Suspense>
    );
}
