import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, TimePicker, Switch, Button as AntButton } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Col as ColAnt, Row as RowAnt } from 'antd';
import { Cascader } from 'antd';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const inputTypeSelectItems = [
    { id: 1, value: "Input", component: '<Input placeholder="[PLACE_HOLDER]" style={{ width: "100%" }} id="[INPUT_ID]" value={this.state.[VALUE]} onChange={(e) => {this.setState({ [VALUE]: e.target.value })}} /> ' },
    {
        id: 2, value: "Select", component: '<Select style={{ width: "100%" }} placeholder="[PLACE_HOLDER]" id="[INPUT_ID]" ' +
            ' value={this.state.[VALUE]} onChange={(value) => { ' +
            '                                                   this.formRef.current.setFieldsValue({\r\n' +
            '                                                    [VALUE]: value \r\n' +
            '                                                       });\r\n' +
            'this.setState({[VALUE]: value })}}> \r\n' +
            '<Option key={null} value={null}>Select</Option>'+
            // +'  {this.state.leaveGroupList.map(i => ('
            //  +'  <Option key={i.id} value={i.id}>{i.groupName}</Option>'
            // +'      ))}'
             ' </Select>'
    },

    {//TODO
        id: 3, value: "Select Editable", component: "<Select\r\n" +
            "                                                style={{ width: '100%' }}\r\n" +
            "                                                placeholder=\"[PLACE_HOLDER]\"\r\n" +
            "                                                value={this.state.[VALUE]} onChange={(value) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: value \r\n" +
            "                                                       });\r\n" +
            "                                                    this.setState({\r\n" +
            "                                                        [VALUE]: value\r\n" +
            "                                                    })\r\n" +
            "                                                }}\r\n" +
            "\r\n" +
            "\r\n" +
            "                                                dropdownRender={menu => (\r\n" +
            "                                                    <div>\r\n" +
            "                                                        {menu}\r\n" +
            "                                                        <Divider style={{ margin: '4px 0' }} />\r\n" +
            "                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>\r\n" +
            "                                                            <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {\r\n" +
            "                                                                this.setState({\r\n" +
            "                                                                    codeName: event.target.value,\r\n" +
            "                                                                });\r\n" +
            "                                                            }} />\r\n" +
            "                                                            <a\r\n" +
            "                                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}\r\n" +
            "                                                                onClick={this.addCode[VALUE]}\r\n" +
            "                                                            >\r\n" +
            "                                                                <PlusOutlined /> Add item\r\n" +
            "                                                               </a>\r\n" +
            "                                                        </div>\r\n" +
            "                                                    </div>\r\n" +
            "                                                )}\r\n" +
            "                                            >\r\n" +
            "                                          <Option key={null} value={null}>Select</Option>\r\n" +
            "                                            </Select>"
    },
    {
        id: 4, value: "Input Number", component: '<InputNumber id="[INPUT_ID]"   placeholder="[PLACE_HOLDER]"\r\n min={0} value={this.state.[VALUE]} onChange={(value) => {' +
            '                                                   this.formRef.current.setFieldsValue({\r\n' +
            '                                                     [VALUE]: value \r\n' +
            '                                                       });\r\n' +

            '     this.setState({'
            + '      [VALUE]: value '
            + ' })'
            + '   }} />'
    },
    {
        id: 5, value: "Date Picker", component: " <DatePicker id=\"[INPUT_ID]\" format=\"DD-MM-YYYY\" value={this.state.[VALUE]} onChange={(date, dateString) => {\r\n" +

            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: date \r\n" +
            "                                                       });\r\n" +

            "        this.setState({\r\n" +
            "            [VALUE]:date\r\n" +
            "        })\r\n" +
            "      }} />"
    },
    {
        id: 6, value: "Date Picker With Time", component: " <DatePicker showTime id=\"[INPUT_ID]\" format=\"DD-MM-YYYY HH:mm:ss\" value={this.state.[VALUE]} onOk={(value) => {\r\n" +

            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: value \r\n" +
            "                                                       });\r\n" +
            "        this.setState({\r\n" +
            "            [VALUE]:value\r\n" +
            "        })\r\n" +
            "      }} />"
    },
    {
        id: 7, value: "Time Picker", component: " <TimePicker  id=\"[INPUT_ID]\"  value={this.state.[VALUE]} onChange={(value) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: value \r\n" +
            "                                                       });\r\n" +

            "        this.setState({\r\n" +
            "            [VALUE]:value\r\n" +
            "        })\r\n" +
            "      }}  />"
    },
    {
        id: 8, value: "Year Picker", component: " <DatePicker id=\"[INPUT_ID]\"   picker=\"year\" value={this.state.[VALUE]} onChange={(value) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: value \r\n" +
            "                                                       });\r\n" +

            "        this.setState({\r\n" +
            "            [VALUE]:value\r\n" +
            "        })\r\n" +
            "      }} />"
    },
    {
        id: 9, value: "Month Picker", component: " <DatePicker id=\"[INPUT_ID]\"   picker=\"month\" value={this.state.[VALUE]} onChange={(value) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: value \r\n" +
            "                                                       });\r\n" +

            "        this.setState({\r\n" +
            "            [VALUE]:value\r\n" +
            "        })\r\n" +
            "      }} />"
    },
    {
        id: 10, value: "Range Picker", component: " <RangePicker  id=\"[INPUT_ID]\" value={this.state.[VALUE]} \r\n" +
            "      ranges={{\r\n" +
            "        Today: [moment(), moment()],\r\n" +
            "        'This Month': [moment().startOf('month'), moment().endOf('month')],\r\n" +
            "      }}\r\n" +
            " onChange={(date, dateString) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: date \r\n" +
            "                                                       });\r\n" +

            "        this.setState({\r\n" +
            "            [VALUE]:date\r\n" +
            "        })\r\n" +
            "      }} />"
    },
    {
        id: 11, value: "Range Picker With Time", component: "<RangePicker   id=\"[INPUT_ID]\" value={this.state.[VALUE]} \r\n" +
            "      ranges={{\r\n" +
            "        Today: [moment(), moment()],\r\n" +
            "        'This Month': [moment().startOf('month'), moment().endOf('month')],\r\n" +
            "      }}\r\n" +
            " showTime \r\n" +
            "  format=\"YYYY/MM/DD HH:mm:ss\" \r\n" +
            " onChange={(date, dateString) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: date \r\n" +
            "                                                       });\r\n" +

            "        this.setState({\r\n" +
            "            [VALUE]:date\r\n" +
            "        })\r\n" +
            "      }} />"
    },
    {
        id: 12, value: "Check Box", component: "  <Checkbox  id=\"[INPUT_ID]\" onChange={(e) => {\r\n" +


            "                                            this.setState({\r\n" +
            "                                                [VALUE]: e.target.checked\r\n" +
            "                                            })\r\n" +
            "                                        }} checked={this.state.[VALUE]}> <FormattedMessage id=\"[INPUT_ID]\" defaultMessage=\"[PLACE_HOLDER]\" />  </Checkbox>"
    },

    {
        id: 13, value: "Radio Button", component: "<Radio.Group  id=\"[INPUT_ID]\" onChange={(e) => {\r\n" +
            "                                                    this.setState({\r\n" +
            "                                                        [VALUE]: e.target.value\r\n" +
            "                                                    })\r\n" +
            "                                                }} value={this.state.[VALUE]}>\r\n" +
            "                                                    <Radio value={1}>Option 1</Radio>\r\n" +
            "                                                    <Radio value={2}>Option 2</Radio>\r\n" +
            "                                                </Radio.Group>"

    },

    {
        id: 14, value: "Cascader", component: "   <Cascader  id=\"[INPUT_ID]\" \r\n" +
            "                                                    style={{ width: \"100%\" }}\r\n" +
            "                                                    value={this.state.[VALUE]}\r\n" +
            "                                                    options={this.state.nodes}\r\n" +
            "                                                    onChange={(value, data) => {\r\n" +
            "                                                   this.formRef.current.setFieldsValue({\r\n" +
            "                                                     [VALUE]: date \r\n" +
            "                                                       });\r\n" +
            "                                                        this.setState({\r\n" +
            "                                                            [VALUE]: value,\r\n" +
            "                                                        });\r\n" +
            "                                                    }}\r\n" +
            "                                                    \r\n" +
            "                                                />"

    },

    {
        id: 15, value: "File Upload", component: "<Upload {...props} id=\"[INPUT_ID]\"\r\n" +
            "                                                    showUploadList={true}\r\n" +
            "                                                    fileList={this.state.fileList}\r\n" +
            "                                                    onChange={({ fileList }) => {\r\n" +
            "                                                        if (fileList.length == 1) {\r\n" +
            "                                                            var filePath = \"\";\r\n" +
            "                                                            if (Boolean(fileList[0].response)) {\r\n" +
            "                                                                filePath = fileList[0].response.url;\r\n" +
            "                                                                this.showSuccess(\"file uploaded successfully\");\r\n" +
            "                                                                // fileName: info.file.name, \r\n" +
            "                                                            }\r\n" +
            "                                                            this.setState({\r\n" +
            "                                                                fileList: fileList,\r\n" +
            "                                                                filePath: filePath,\r\n" +
            "                                                            });\r\n" +
            "                                                        }\r\n" +
            "                                                    }}\r\n" +
            "                                                    beforeUpload={(fileList) => {\r\n" +
            "                                                        if (this.state.fileList.length > 0) {\r\n" +
            "                                                            this.showError(\"You have been already choose a file! For change firstly remove the uploaded file.\");\r\n" +
            "                                                            return false;\r\n" +
            "                                                        }\r\n" +
            "                                                        return true;\r\n" +
            "                                                    }}\r\n" +
            "                                                    onRemove={({ fileList }) => {\r\n" +
            "                                                        this.setState({\r\n" +
            "                                                            fileList: [],\r\n" +
            "                                                            filePath: \"\",\r\n" +
            "                                                        })\r\n" +
            "                                                    }\r\n" +
            "                                                    }>\r\n" +
            "                                                    <AntButton style={{ width: \"100%\", marginTop: \"0.2rem\" }}>\r\n" +
            "                                                        <UploadOutlined /> Upload \r\n" +
            "                            </AntButton>\r\n" +
            "                                                </Upload>"

    },




];

export class DynamicForm extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            statusList: [],
            status: '',
            abbreviation: '',
            statusId: 0,

            dynamicMenuIds: null,
            dynamicMenuId: null,
            dynamicInputList: [],
            dynamicMenuCascader: [],
            dynamicFormList: [],
            entity: '',
            entitySelectItems: [],
            entityFielsSelectItems: [],
            bindField: '',

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0,
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        await this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        const response = await handleRequest("GET", "/ApplicationSettings/getEntitiesFromLocal");

        console.log(response);
        if (Boolean(response.data) && response.data.length > 0) {

            const data = response.data;
            console.log(data);
            this.setState({
                entitySelectItems: data

            });
        }


        this.setStandardizations();
        this.getDynamicMenu2();



    }

    getEntityFields = async () => {

        const response = await handleRequest("GET", "/ApplicationSettings/getEntityFields/" + this.state.entity);

        console.log(response);
        if (Boolean(response.data) && response.data.length > 0) {

            const data = response.data;
            console.log(data);
            this.setState({
                entityFielsSelectItems: data

            });
        }

    }

    getDynamicMenu2 = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            // generateList(responseTree.data.treeTable);
            console.log(responseTree.data.cascader);
            this.setState({
                dynamicMenuCascader: responseTree.data.cascader
            });
        }
    }

    onDynamicMenuCascaderChange = async (value) => {

        if (Boolean(value) && value.length > 0) {
            const response = await handleRequest("GET", "/DynamicInputs/getAllByMenuId/" + value[value.length - 1])
            const response2 = await handleRequest("GET", "/DynamicForm/getForm/" + value[value.length - 1]);
            var dynamicFormList = [];

            this.setState({
                dynamicMenuId: value[value.length - 1]
            }); 

            if (Boolean(response2.data) && response2.data.length > 0) {
                dynamicFormList = response2.data;
                this.setState({
                    entity : dynamicFormList[dynamicFormList.length-1].controller 
                })
            }

            if (Boolean(response.data) && response.data.length > 0) {
                const list = response.data;
                console.log(list);
                console.log(dynamicFormList);
                const formList = [];
                list.forEach(element => {
                    let index = -1;
                    if (dynamicFormList.length > 0) {
                        index = dynamicFormList.findIndex(p => p.dynamicInputsId === element.id);
                    }
                    if (index === -1) {
                        let obj = { id: 0, dynamicMenuId: value[value.length - 1], active: true, dynamicInputsId: element.id, inputKey: element.inputKey, inputValue: element.inputValue, inputTypeId: null, inputComponentCode: '', row1: formList.length, rowOffsetLabel: true, rowOffset: 0, col1: 1, colSpan: 8, showLabel: true, dynamicInputs: element, bindField: '' }
                        formList.push(obj);
                    }
                    else {
                        let c = inputTypeSelectItems.find(p=>p.id===dynamicFormList[index].inputTypeId);
                        if(Boolean(c)){
                            dynamicFormList[index].inputComponentCode=c.component;
                        } 
                        formList.push(dynamicFormList[index]);}

                });
                console.log(formList);
                this.setState({
                    dynamicFormList: formList
                })
            }
        }

        this.setState({
            dynamicMenuIds: value
        })
    }

    save = async () => {

        const list = this.state.dynamicFormList;
        if(Boolean(this.state.entity) && this.state.entity.length>0){
            list.forEach(element => { 
                element.controller = this.state.entity;
            });
        }
        await handleRequest("POST", "/DynamicForm/saveAll", list);

        this.showSuccess();
        this.onDynamicMenuCascaderChange(this.state.dynamicMenuIds);

    }

    createForm = async () => {

        const dynamicMenuIds = this.state.dynamicMenuIds;
        if (Boolean(dynamicMenuIds) && dynamicMenuIds.length > 0) {
            await handleRequest("GET", "/DynamicForm/createFormFile/" + dynamicMenuIds[dynamicMenuIds.length - 1]);

            this.showSuccess();
        }
        else this.showError();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({ status: row.status, abbreviation: row.abbreviation });

        this.setState({
            statusId: row.id,
            status: row.status,
            abbreviation: row.abbreviation,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.statusId
        }

        await handleRequest("POST", "/DynamicForm/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }

    restartTable = async () => {

    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "DynamicForm" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "DynamicForm"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }
    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }
            console.log("XXX", result);
            this.setState({
                formId: result.data.id,
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === result.data.id || p.form === "All")
            });

            await this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: 0,
                authorizations: [],
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({ status: '', abbreviation: '' });

        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    render() {


        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>

                        <MenuProvider id="menu_id">


                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>



                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        <Cascader style={{ width: '100%', marginBottom: '16px' }} value={this.state.dynamicMenuIds}
                                            options={this.state.dynamicMenuCascader}
                                            onChange={this.onDynamicMenuCascaderChange} />
                                        <RowAnt>
                                            <ColAnt span={2}>
                                             Controller : 
                                            </ColAnt>
                                            <ColAnt span={4}>
                                                <Select 
                                                 showSearch
                                                 optionFilterProp="children"
                                                placeholder="" style={{width:'100%'}} value={this.state.entity} onChange={(value) => {
                                                    this.setState({
                                                        entity: value
                                                    }, this.getEntityFields 
                                                    )
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.entitySelectItems.map(i => (
                                                        <Option key={i} value={i}>{i}</Option>
                                                    ))}
                                                </Select>
                                            </ColAnt>
                                        </RowAnt>

                                        <RowAnt gutter={[16, 16]}>
                                            <ColAnt span={1}>
                                                Row
                                    </ColAnt>
                                            <ColAnt span={3}>
                                                Row Offset
                                    </ColAnt>
                                            <ColAnt span={1}>
                                                Row Offset
                                    </ColAnt>
                                            <ColAnt span={1}>
                                                Col
                                    </ColAnt>
                                            <ColAnt span={1}>
                                                Col Span
                                    </ColAnt>
                                            <ColAnt span={3}>
                                                Input Name
                                    </ColAnt>
                                            <ColAnt span={3}>
                                                Input Type
                                    </ColAnt>
                                    <ColAnt span={3}>
                                               Value
                                    </ColAnt>
                                            <ColAnt span={3}>
                                                Show Label
                                    </ColAnt>

                                        </RowAnt>

                                        {this.state.dynamicFormList.filter(p => p.active === true).map((item, i) => (
                                            <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                                                <RowAnt gutter={[16, 16]}>
                                                    <ColAnt span={1}>
                                                        <InputNumber placeholder="Row" style={{ width: '100%' }} id="todo" min={0} max={this.state.dynamicFormList.length} value={item.row1} onChange={(value) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.row1 = value;
                                                            this.setState({ dynamicFormList: list })
                                                        }} />
                                                    </ColAnt>
                                                    <ColAnt span={3}>
                                                        <Checkbox onChange={(e) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.rowOffsetLabel = e.target.checked;
                                                            if (e.target.checked)
                                                                obj.rowOffset = 0;
                                                            this.setState({ dynamicFormList: list })
                                                        }} checked={item.rowOffsetLabel}> As the size of the label </Checkbox>


                                                    </ColAnt>
                                                    {<ColAnt span={1}>


                                                        <InputNumber disabled={item.rowOffsetLabel} placeholder="Row Offset" style={{ width: '100%' }} id="todo" min={0} value={item.rowOffset} onChange={(value) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.rowOffset = value;
                                                            this.setState({ dynamicFormList: list })
                                                        }} />
                                                    </ColAnt>}
                                                    <ColAnt span={1}>
                                                        <InputNumber placeholder="Col" style={{ width: '100%' }} id="todo" min={0} value={item.col1} onChange={(value) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.col1 = value;
                                                            this.setState({ dynamicFormList: list })
                                                        }} />
                                                    </ColAnt>
                                                    <ColAnt span={1}>
                                                        <InputNumber placeholder="Col Span" style={{ width: '100%' }} id="todo" min={0} value={item.colSpan} onChange={(value) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.colSpan = value;
                                                            this.setState({ dynamicFormList: list })
                                                        }} />
                                                    </ColAnt>
                                                    <ColAnt span={3}>
                                                        {item.inputValue}
                                                    </ColAnt>
                                                    <ColAnt span={3}>
                                                        <Select placeholder="" style={{ width: '100%' }} value={item.inputTypeId} onChange={(value) => {
                                                            let list = this.state.dynamicFormList;
                                                            let inputType = inputTypeSelectItems.find(p => p.id === value);
                                                            let obj = list[i];
                                                            obj.inputTypeId = value;
                                                            obj.inputComponentCode = inputType.component;
                                                            this.setState({ dynamicFormList: list })
                                                        }}
                                                        >
                                                            {inputTypeSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.value}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                    <ColAnt span={3}>

                                                        <Select placeholder="" style={{ width: '100%' }}  value={item.bindField} onChange={(value) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.bindField = value;

                                                            this.setState({ dynamicFormList: list })
                                                        }}
                                                        >
                                                            <Option key={0} value={0}>Select</Option>
                                                            {this.state.entityFielsSelectItems.map(i => (
                                                                <Option key={i} value={i}>{i}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>

                                                    <ColAnt span={3}>
                                                        <Checkbox onChange={(e) => {
                                                            let list = this.state.dynamicFormList;
                                                            let obj = list[i];
                                                            obj.showLabel = e.target.checked;

                                                            this.setState({ dynamicFormList: list })
                                                        }} checked={item.showLabel}> Show Label </Checkbox>

                                                    </ColAnt>

                                                </RowAnt>

                                            </div>))}


                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" >Save All</ReactButton>
                                                        }
                                                    </ColAnt>

                                                    <ColAnt span={8}>
                                                        {Boolean(this.state.dynamicFormList) && this.state.dynamicFormList.length > 0 && this.state.dynamicFormList[0].id > 0 &&
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="button" onClick={this.createForm} style={{ width: '100%' }}
                                                                variant="success"> Create Form</ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                        <Modal
                                            show={this.state.modalMessage}
                                            onHide={this.showOrHideModalMessage}
                                            backdrop="static"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>

                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <Row >
                                                        <Col sm="12">
                                                            {this.state.messageTip == "Success" &&
                                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                            {this.state.messageTip == "Warning" &&
                                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                            {this.state.messageTip == "Error" &&
                                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                        </Col>
                                                    </Row>
                                                </Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.message}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                            </Modal.Footer>
                                        </Modal>
                                    </FormAnt>
                                </div>


                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>


                        }
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm)