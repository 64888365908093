
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {  Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row, Modal as ModalReact} from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env'; 
import { Button as PrimeButton } from 'primereact/button';
import { Column } from 'primereact/components/column/Column';
import "./css/DataTable.scss";
import $ from 'jquery';
import { ItpLogExpand } from './ItpLogExpand';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu'; 
import { Table, Modal, Checkbox as AntCheckbox, Button as AntButton, Input} from 'antd';
import { TreeTable } from 'primereact/treetable';
import moment from 'moment';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const columns = [{
  title: 'S/N',
  dataIndex: 'index',
}, {
  title: 'NOTE',
  dataIndex: 'description',
  }
];

class ItpLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      hiddenSave: false,
      hiddenUpdate: true,
      tablehidden: false,
      createNewButton: "Create New",
      createNewButtonActivity: "Create New",
      hiddenAmendDocument:false,
      lastButton: "Save",
      createNewButtonVariant: "info",
      createNewButtonActivityVariant: "info",
      Activities: [],
      projectphaseactivitycode: "",
      taskactivity: [],
      qualityprocedure: "",
      qualityrecord: "",
      contractor: "",
      client: "",
      remarks: "",
      itemsGnotes: [],
      gnNote: "",
      itemsLegends: [],
      itemsProcedureRecords: [],
      legendAbbravitaion: "",
      legendExplanation: "",

      disciplinesOfDepartments: [],
      discipline:"", 
      disciplineVal: 0, 

      itpname:"",
      itpcode:"",
      approvaldate:"",
      approvalDateVal:null,
      revizyonno:0,

      legendAddItems: false,
      activityAddItems: false,
      datatableValue: [],
      selectedRowVal: null,
      expnd: false,
      expandedRows: null,

      documentLogid:-1,

      itemno: "",
      itpActivityTreeTable: [],
      level1GridBsl:"",
      level2GridBsl:"",
      level1:0,
      level2:0,
      qprocedureAck:"",
      qrecordAck:"",
      clientAck:"",
      contractorAck:"",
      level1Ack:"",
      level2Ack:"",
      id:-1,

      popup:false,
      messageTip:"",
      
      itemGroup: false,
      hiddenItemGroup: false,
      ItpInspectionLevel:[],
      ItpInspectionLevelKayit:0,
  }

  this.addLegendsTable = this.addLegendsTable.bind(this);
  this.addGeneralNotesTable = this.addGeneralNotesTable.bind(this);
  this.onselectChangeVal = this.onselectChangeVal.bind(this);
  this.expandTableRender = this.expandTableRender.bind(this);
  this.actionTemplate = this.actionTemplate.bind(this);
  this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
}

componentDidMount = async () => { 
  var deger=0;
  if(this.props.location!=null && this.props.location!=undefined && this.props.location!=""){
    deger=this.props.location.pathname.split('/')[2]; 
    this.setState({
      popup:false
    });
  }else if(this.props.id!=null && this.props.id!=0 && this.props.id!=""){
    deger=this.props.id; 
    this.setState({
      popup:true
    });
  }else{
    deger=-1;
    this.setState({
      popup:true
    });
  } 
  await this.setState({
    documentLogid:deger
  });

  await this.getITP();
  this.getItpActivity(this.state.id);
  this.getGeneralNotes();
  this.createNewClientButton();
  this.getDocumentLog(deger)
  this.getProcedureRecord();
  this.getLegend();
  
  $(".p-filter-column").on("change paste keyup", function () {

    $("tr[id^='idd']").remove();
  });

  $(".p-sortable-column").on("click", function () {

    $("tr[id^='idd']").remove();
  });  
}

redirectDocument() {
  if(this.state.popup==false){
    window.setTimeout(function(){
      window.location.href = "/DocumentLog";
  }, 2000);
  }
};

getItpActivity = async (deger) => { 
    const newObj = {
      itpid: deger
    }     
    const responseTree = await handleRequest("POST", "/ItpActivity/getTreeItpActivity", newObj);

    console.log("Tree" , responseTree.data.treeTable);

    if (responseTree.data.length !== 0) {
        this.setState({
          itpActivityTreeTable: responseTree.data.treeTable,
        });
    }else{
        this.setState({
          itpActivityTreeTable: [],
        });
    }   
}

getDocumentLog = async (deger) => {  
  const newObj = {
    id: deger
  } 
  const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj); 
  if (response.data.length !== 0) {   
    var revizyonno=0;
    if(this.state.hiddenAmendDocument==true){
      if(this.state.popup==false){
        revizyonno=response.data[0].revno;
      }else{
        revizyonno=response.data[0].revno + 1;
      }
    }else{
      revizyonno=response.data[0].revno;
    }

    this.setState({
      discipline:response.data[0].discipline==null ? "Nill" :  response.data[0].discipline.disciplineName,
      disciplineVal:response.data[0].discipline==null ? 0 :  response.data[0].discipline.id,
      itpcode:response.data[0].code,
      itpname:response.data[0].description,
      approvaldate: this.setDateFormat(response.data[0].date),
      approvalDateVal:new Date(response.data[0].date),
      revizyonno:revizyonno,
    }); 
  }  
  else { 
    this.setState({
      discipline:"",
      disciplineVal: 0,

      itpcode:"",
      itpname:"",
      approvaldate:"",
      approvalDateVal:null,
      revizyonno:0,
    })
  }
}

getITP = async () => { 
    const newObj = {
      documentLogid: this.state.documentLogid
    }  
    const response = await handleRequest("POST", "/Itp/getAll2", newObj); 
    if (response.data.length !== 0) {  
      console.log("get", response.data);
      await this.setState({ 
        datatableValue:response.data,
        id: response.data[0].id,
        hiddenSave:true,
        hiddenUpdate:false,
        hiddenAmendDocument:true,
      }); 
    }  
    else { 
      await this.setState({ 
        datatableValue: [],
        id: -1,
        hiddenSave:false,
        hiddenUpdate:true,
        hiddenAmendDocument:false,
      });
    }
}

getDiscipline = async () => {

  const response = await handleRequest("GET", "/Discipline/getAll");
  if (response.data.length !== 0) {
    var rows = [];
    for (var i = 0; i < response.data.length; i++) {
      rows.push({ label: response.data[i].disciplineName, value: response.data[i].id });
    }

    this.setState({ disciplinesOfDepartments: rows });
  } else { this.setState({ disciplinesOfDepartments: [] }); }
}

getLegend = async () => {
  const response = await handleRequest("GET", "/IncpectionLevel/getAll");
  if (response.data.length !== 0) {
    this.setState({ itemsLegends: response.data });
  } else { this.setState({ itemsLegends: [] }); }
}

getProcedureRecord = async () => {
  const response = await handleRequest("GET", "/Itp/getProcedureRecord");
  if (response.data.length !== 0) {
    this.setState({ itemsProcedureRecords: response.data });
  } else { this.setState({ itemsProcedureRecords: [] }); }
}

getGeneralNotes = async () => {
  if(this.state.hiddenSave==false){  //ADD işlemi ise
    const response = await handleRequest("GET", "/Itp/getItpgeneralnotesAll");

    const columns = response.data;
    const options = columns.map(function (row) {
        return row.notDurum 
    })
    
    this.setState({ selectedRowKeys: options });

    if (response.data.length !== 0) {
      this.setState({ itemsGnotes: response.data });
    } else { 
      this.setState({ itemsGnotes: [] });
    }
  } 
  else
  {
    if(this.state.id>0){  
        const newObj = { itpId: this.state.id } 
        const response = await handleRequest("POST", "/Itp/getItpgeneralnotesResult", newObj); 

        const columns = response.data;
        const options = columns.map(function (row) {
            return row.notDurum 
        })
        
        this.setState({ selectedRowKeys: options });
        if (response.data.length !== 0) {
          this.setState({ itemsGnotes: response.data });
        } else { this.setState({ itemsGnotes: [] }); }
  }else{
    this.setState({ 
      itemsGnotes: [],
    });
    }
  }
}

getItpInspectionLevel = async (itpactivityId) => {
  var newObj={
    itpactivityId:itpactivityId,
  }
  const response = await handleRequest("POST", "/Itp/getItpInspectionLevel", newObj); 
  if (response.data.length === 0) {
    this.setState({
      ItpInspectionLevel: [],
      ItpInspectionLevelKayit:0,
    });
  } 
  if (response.data.length !== 0) {
    this.setState({
      ItpInspectionLevel: response.data,
      ItpInspectionLevelKayit:response.data[response.data.length - 1].id
    });
  }
}

showSuccess(msg) {
  if (!msg) {
    msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
  }
  this.setState({
    modalMessage: true,
    message: msg,
    messageTip: "Success"
  });
}

showError(msg) {
  if (!msg) {
    msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
  }
  this.setState({
    modalMessage: true,
    message: msg,
    messageTip: "Error"
  });
}

showWarning(msg) {
  if (!msg) {
    msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
  }
  this.setState({
    modalMessage: true,
    message: msg,
    messageTip: "Warning"
  });
};

showOrHideModalMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  }); 
}

handleChange = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name]: e.target.value
  });
};

handleQProcedureChange = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name] : e.target.value,
    qprocedureAck : e.target.selectedOptions[0].text
  });
};

handleQRecordChange = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name] : e.target.value,
    qrecordAck :e.target.selectedOptions[0].text
  });
};

handleClientChange = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name] : e.target.value,
    clientAck :e.target.selectedOptions[0].text
  });
};

handleContractorChange = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name] : e.target.value,
    contractorAck :e.target.selectedOptions[0].text
  });
};

handleScmLevel1Change = e => { 
  e.preventDefault(); 
  this.setState({ 
    [e.target.name] : e.target.value,
    level1Ack :e.target.selectedOptions[0].text
  });
};
  
handleLevel1Change = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name]: e.target.value
  });
};

handleLevel2Change = e => { 
  e.preventDefault(); 
  this.setState({
    [e.target.name]: e.target.value
  });
};

  resetInputs = () => {
    this.setState({ 
    Activities: [],
    projectphaseactivitycode: "",
    taskactivity: [],
    qualityprocedure: "",
    qualityrecord: "",
    contractor: "",
    client: "",
    gnNote: "",
    legendAbbravitaion: "",
    legendExplanation: "",

    disciplineVal: 0,

    itemno: "",
    selectedRowKeys:[],
    level1GridBsl:"",
    level2GridBsl:"",
    level1:0,
    level2:0,
    qprocedureAck:"",
    qrecordAck:"",
    clientAck:"",
    contractorAck:"",
    level1Ack:"",
    level2Ack:"",
  });
}

  createNewClientButton = async () => {
   await this.resetInputs();  
 
    if (this.state.createNewButton == "Create New") {
      this.setState({ 
        hidden: false,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
      this.refreshAccordions();
    }
    else {
      this.setState({ 
        hidden: true,
        createNewButton: "Create New",
        createNewButtonActivity: "Create New",
        activityAddItems: false,
        lastButton: "Save",
        createNewButtonVariant: "info",
        createNewButtonActivityVariant: "info"

      });
      this.refreshAccordions();
    }

    this.getGeneralNotes();
    // this.getItpActivity(-1);
  }

  refreshAccordions = () => {

    /*if (document.getElemendocumenttById('client-data-status').checked === false)
      document.getElementById('client-data-status').click(); */
    document.getElementById('itpAcord').className = "collapse show";
    document.getElementById('activityAcord').className = "collapse ";
    document.getElementById('notAcord').className = "collapse ";
    // document.getElementById('legendAcord').className = "collapse ";
  }

  onEditClick(e) {
    if (e.target.value === undefined)
      e.stopPropagation();
  }

  addActivity = async () => {
    if (this.state.itemno!=''){
       this.addActivityItem(); 
 
       await this.setState({
        itemno: "",
        projectphaseactivitycode: "",
        taskactivity: [],
        qualityprocedure: "",
        qualityrecord: "",
        contractor: "",
        client: "",
        remarks: "", 
        level1:0,
        level2:0,
        qprocedureAck:"",
        qrecordAck:"",
        clientAck:"",
        contractorAck:"",
        level1Ack:"",
        level2Ack:"",
      }); 
    }else{
      this.showWarning();
    }
  }

  addActivityItem = async () => { 
     await this.setState(state => ({
      Activities: [...state.Activities,{
        itemno: this.state.itemno,
        activitycode: this.state.projectphaseactivitycode,
        activity: this.state.taskactivity,
        qprocedure: this.state.qualityprocedure,
        qrecord: this.state.qualityrecord,
        contractor: this.state.contractor,
        client: this.state.client,
        remarks: this.state.remarks,
        level1:this.state.level1,
        level2:this.state.level2,
        qprocedureAck:this.state.qprocedureAck,
        qrecordAck:this.state.qrecordAck,
        clientAck:this.state.clientAck,
        contractorAck:this.state.contractorAck,
        level1Ack:this.state.level1Ack,
        level2Ack:this.state.level2Ack,
        itpInspectionLevels:this.state.ItpInspectionLevel,
      }]
    }));  
 
    this.setState(prevState => ({
      itpActivityTreeTable: [...prevState.itpActivityTreeTable, {key:this.state.itemno, data: this.state.Activities[this.state.Activities.length-1], children:[] }]
    }));

    this.setState({
      ItpInspectionLevel:[],
    });
  }
 
  addGeneralNotesTable = async () => {
    const { gnNote } = this.state;
    const prm = { description: gnNote };
    
    if(gnNote!=""){   
      var response = await handleRequest("POST", "/Itp/addItpgeneralnotes", prm);

      if (response.data !== null && response.data !== undefined) {
        if (response.data == "Add Successfull") {
          this.showSuccess();
        }
        else  {
          this.showError();
        }  
      } 
      this.setState({ gnNote: '' });
      this.getGeneralNotes();  

    }else {
      this.showWarning();
    } 
  }

  addLegendsTable = async () => {
    const { legendAbbravitaion } = this.state;
    const { legendExplanation } = this.state;

    const prm = { code: legendAbbravitaion, description: legendExplanation };
    const response = await handleRequest("POST", "/Itp/addLegend", prm);

    this.showSuccess();
    this.setState({ legendAbbravitaion: '', legendExplanation: '', legendAddItems: false });
    this.getLegend();
  }

  addLegends = () => {
    this.setState({
      legendAbbravitaion: "",
      legendExplanation: "",
      legendAddItems: true
    });
  }

  addActivityShow = () => {
    if(this.state.createNewButtonActivity=="Create New"){ 
      this.setState({
        createNewButtonActivity: "Cancel", 
        createNewButtonActivityVariant: "danger",
        activityAddItems: true,
      });
    }else{
      this.setState({
        createNewButtonActivity: "Create New",
        createNewButtonActivityVariant: "info",
        activityAddItems: false,
      });
    } 
  }

  addITP = async () => {
    var itpNotIDs;
    if(this.state.selectedRowKeys!="undefined"){
      itpNotIDs=this.state.selectedRowKeys
    }else{
      itpNotIDs=""
    }

    const prm = {
      disciplineid: this.state.disciplineVal,
      itpname: this.state.itpname,
      itpcode: this.state.itpcode,
      approvaldate: this.state.approvalDateVal,
      revizyonno: this.state.revizyonno,
      activity: this.state.Activities,
      documentLogid:this.state.documentLogid,
      itpNotIDs: itpNotIDs.toString(),
      itpNots:this.state.selectedRowKeys,
    }

    console.log("add", prm);

    const response = await handleRequest("POST", "/Itp/add", prm);
    if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        this.createNewClientButton();
        this.getITP();
        this.showSuccess();
      }else {
        this.showError();  
      }
    }else{
      this.showError();  
    }  
  }

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }

  onselectChangeVal(event) {
    this.setState({ selectedRowVal: event.value })
  } 

  expandTableRender() {
    return (
      <div>
        List of Inpection Request
        <div className="p-datatable-globalfilter-container">
          <InputText type="search" onChange={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        List of Inpection Request
        <div className="p-datatable-globalfilter-container">
          <InputText type="search" onChange={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
        </div>
      </div>
    );
  }

  actionTemplate(rowData, column) {
    return <React.Fragment> 
      <CustomMaterialMenu row={rowData} 
        onDeleteRow={this.deleteModal.bind(this)} 
        onEditRow={this.changeButtonUpdate.bind(this)} />   
      </React.Fragment>;
  };

  deleteModal = (row) => { 
    const that = this; 
      confirm({
          title: 'Are you sure delete this ITP ?',
          icon: <ExclamationCircleOutlined />,
          content: 'Selected ITP will be deleted !',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() { that.deleteITPLog(row.id) }, 
          onCancel() {
          },
      }); 
  } 

  deleteITPLog = async (pId) => {
    const deleteITP = {
        id: pId
    } 
    await handleRequest("POST", "/Itp/delete", deleteITP);

    this.createNewClientButton();
    this.getITP();
}

  changeButtonUpdate = async (rowData) => {
    await this.setState({
      hidden: false,
      hiddenUpdate: false,
      hiddenSave: true,
      hiddenCreateNew: false,
      id: rowData.id,
      disciplineVal: rowData.dis==null ? 0 : rowData.dis.id,
      disciplineName:  rowData.dis==null ? "Nill" : rowData.dis.disciplineName,
      itpcode: rowData.itpcode,
      itpname: rowData.itpname,
      approvaldate: moment(rowData.approvaldate).format("DD/MM/YYYY"), 
      approvalDateVale: new Date(rowData.approvaldate), 
      revizyonno: rowData.revizyonno,
      Activities:[],
      createNewButton: "Cancel",
      createNewButtonVariant: "danger"
    });
    this.getGeneralNotes();  
    this.getItpActivity(rowData.id);
  };

  changeButtonCancel = () => {
    this.setState({
      hiddenSave: true,
      hiddenUpdate:false,
      hidden: true,
      createNewButton: "Create New",
      createNewButtonActivity: "Create New",
      activityAddItems: false,
      lastButton: "Save",
      createNewButtonVariant: "info",
      createNewButtonActivityVariant: "info",
      itpActivityTreeTable:[],
      selectedRowKeys:[],
      Activities:[], 
    });
    this.refreshAccordions();
    this.getGeneralNotes();
    this.redirectDocument();
  };

  updateDone = async () => {
    if (this.state.itpname.trim() != "" && this.state.documentLogid!=0 ) {  
      var itpNotIDs;
      if(this.state.selectedRowKeys!="undefined"){
        itpNotIDs=this.state.selectedRowKeys
      }else{
        itpNotIDs=""
      }

      const updatedItpLogLog = {
        id: this.state.id,
        disciplineid: this.state.disciplineVal,
        itpname: this.state.itpname.trim(),
        itpcode: this.state.itpcode,
        approvaldate: this.state.approvalDateVal,
        revizyonno: this.state.revizyonno,
        activity: this.state.Activities,
        documentLogid: this.state.documentLogid,
        itpNotIDs: itpNotIDs.toString(),
        itpNots:this.state.selectedRowKeys, 
    }

    console.log("update", updatedItpLogLog);

    var response =   await handleRequest("POST", "/Itp/update", updatedItpLogLog);
    if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.createNewClientButton();
          this.getITP();
          this.showSuccess();
        }
        else   {
            this.showError();
        } 
      } 
      else {
        this.showError();  
      }   
    }
    else {
      this.showWarning();
    } 
  };

  rowExpansionTemplate(data) {
    return <ItpLogExpand row={data} />;
  }
 
  state = {
    selectedRowKeys: [],
  };
  selectRow = (record) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys }); 
  } 

  onSelectedRowKeysChange = (selectedRowKeys) => { 
     this.setState({ selectedRowKeys });
   }

   renderApprovaldate(rowData) {
    if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
      return moment(rowData.documentLog.date).format("DD/MM/YYYY");
   }
   return "";
  }; 

  renderDisciplineName(rowData) {
    if (rowData.dis !== null && rowData.dis !== undefined) {
        return rowData.dis.disciplineName;
    }
    return "";
  }; 

  renderDocumentDesc(rowData) {
    if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
        return rowData.documentLog.description;
    }
    return "";
  }; 

  renderItpCode(rowData) {
    if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
        return rowData.documentLog.code;
    }
    return "";
  }; 

  renderRevNo(rowData) {
    if (rowData.documentLog !== null && rowData.documentLog !== undefined) {
        return rowData.documentLog.revno;
    }
    return "";
  }; 

  addNewItemItpInspectionLevel = async () => {
    await this.setState({
      ItpInspectionLevelKayit:this.state.ItpInspectionLevelKayit + 1,
    });
    this.setState(prevState => ({
      ItpInspectionLevel: [...prevState.ItpInspectionLevel, {
        key:this.state.ItpInspectionLevelKayit, 
        id: 0 , 
        index: this.state.ItpInspectionLevelKayit,
        textName:"",
        textField:0,
       }]
    }))
  };

  removeItemItpInspectionLevel = async (index) => {
    if(index>0){
        var newItem=this.state.ItpInspectionLevel.filter(p => p.index != index)
        var newRemoveItem=this.state.ItpInspectionLevel.filter(p => p.index == index)

        if(newRemoveItem!=null && newRemoveItem!=undefined && newRemoveItem.length==1){
          this.setState({
            ItpInspectionLevel:newItem,
          })
        }else{
          this.setState({
            ItpInspectionLevel:newItem,
          })
        }
    }else{
      this.showWarning();
    }
  } 
 

  render() {

    const columns = [ 
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogItemNo" defaultMessage="Item No" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.itemno;
          }
          return "";
        }
      },
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogActivityCode" defaultMessage="Project Phase , Activity Code" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.activitycode;
          }
          return "";
        }
      },
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogQualityProcedure" defaultMessage="Quality Procedure" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.qprocedureAck;
          }
          return "";
        }
      },
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogQualityRecord" defaultMessage="Quality Record" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.qrecordAck;
          }
          return "";
        }
      },
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogContractor" defaultMessage="Contractor" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.contractorAck;
          }
          return "";
        }
      },
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogClient" defaultMessage="Client" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.clientAck;
          }
          return "";
        }
      },
      { 
        key: "key",
        title: <FormattedMessage id="ItpLogRemarks" defaultMessage="Remarks" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.remarks;
          }
          return "";
        }
      },
      // {
      //   key: "key",
      //   title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("itpLogActivity")} icon="pi pi-cog"></PrimeButton>,
      //   render: this.actionTemplate
      // },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const columnsInspectionLevels = [ 
      { 
        dataIndex:"textName",
        key: "key",
        title: <FormattedMessage id="ItpLogName" defaultMessage="Name" />,
      }, 
      { 
        dataIndex:"incpectionLevel",
        key: "key",
        title: <FormattedMessage id="ItpLogValue" defaultMessage="Value" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return record.abbraviation;
          }
          return "";
        }
      }, 
    ];

    const tableColumnsInspectionLevels = columnsInspectionLevels.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsInspectionLevels[0].fixed = true;
      tableColumnsInspectionLevels[tableColumnsInspectionLevels.length - 1].fixed = 'right';
    }

    let legendOpt = this.state.itemsLegends.map((data) =>
      <option key={data.id} value={data.id} >  {data.abbraviation} </option>);
  
    let ProcedureRecordOpt = this.state.itemsProcedureRecords.map((data) =>
      <option key={data.id} value={data.id} >  {data.description} </option>);
  
    const header = this.renderHeader(); 

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };

    const expandedRowRender = (data) => {
      return <ItpLogExpand row={data} />;
    }

    const dataTablecolumns = [
      {
        key: "id",
        title: "",
        render: this.actionTemplate,
        expander: true, 
      },
      {
        dataIndex: "index",
        key: "index",
        title: "S/N",
      },
      {
        key: "documentLogid",
        title: "Document",
        render: this.renderDocumentDesc
      },
      { 
        key: "disciplineid",
        title: "Discipline",
        render: this.renderDisciplineName
      },
      { 
        key: "itpcode",
        title: "Itp Code",
        render: this.renderItpCode
      },
      { 
        key: "itpname",
        title: "Itp Name",
        render: this.renderDocumentDesc
      },
      { 
        key: "itpname",
        title: "Approval Date",
        render: this.renderApprovaldate
      },
      { 
        key: "revizyonno",
        title: "Rev. No",
        render: this.renderRevNo
      },
    ];

    return (
      <div>

      <ModalReact
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <ModalReact.Header closeButton>
              <ModalReact.Title id="contained-modal-title-vcenter">
              <Row > 
                <Col sm="12">
                  {this.state.messageTip=="Success" &&  
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                
                  {this.state.messageTip=="Warning" &&  
                  <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip=="Error" &&  
                  <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                </Col>
              </Row>
              </ModalReact.Title>
          </ModalReact.Header>

          <ModalReact.Body>
             <Row > 
              <Col sm="12">
                 {this.state.message} 
              </Col>
              </Row>
          </ModalReact.Body>
        </ModalReact>
     

        <Row>
          <Col xs={9}></Col>
          <Col xs={3}>
            {/* <Button hidden={this.state.hiddenAmendDocument} onClick={this.createNewClientButton} style={{ width: '100%' }} 
            variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
            </Button> */}
          </Col>
        </Row>
        <div hidden={this.state.hidden} className="col-md-12">
          <br />
          <Accordion hidden="true" defaultActiveKey="0">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Itp Info</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="itpAcord" eventKey="0" >
                <Card.Body>

                  <Form>
                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                      <Form.Label style={{ color: 'black' }} column sm="2">  Discipline   </Form.Label>
                      <Col sm="4">
                        {/* <Dropdown id="disciplineID"
                          value={this.state.disciplineVal} options={this.state.disciplinesOfDepartments}
                          onChange={this.onChangedisciplineVal} style={{ width: '200px' }}
                          filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true} /> */}
                            <InputText readOnly="true" style={{ width: '100%' }} 
                          value={this.state.discipline} placeholder="" />
                      </Col>
                    </Form.Group>


                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                      <Form.Label style={{ color: 'black' }} column sm="2">  Itp Code </Form.Label>
                      <Col sm="4">
                        {/* <InputText onChange={(e) => this.setState({ iptCodeVal: e.target.value })} id="itpcodeID" style={{ width: '200px' }}
                          value={this.state.iptCodeVal} placeholder="..." /> */}
                        <InputText readOnly="true" style={{ width: '100%' }} 
                          value={this.state.itpcode} placeholder="" />
                      </Col>
                    </Form.Group>


                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                      <Form.Label style={{ color: 'black' }} column sm="2">  Itp Name </Form.Label>
                      <Col sm="4">
                        {/* <InputText onChange={(e) => this.setState({ itpNameVal: e.target.value })} id="itpnameID" style={{ width: '200px' }}
                          value={this.state.itpNameVal} placeholder="..." /> */}
                          <InputText readOnly="true" style={{ width: '100%' }} 
                          value={this.state.itpname} placeholder="" />
                      </Col>
                    </Form.Group>


                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                      <Form.Label style={{ color: 'black' }} column sm="2">  Approval Date </Form.Label>
                      <Col sm="4">
                        {/* <Calendar dateFormat="dd/mm/yy" value={this.state.approvalDateVal} showButtonBar={true} style={{ width: '200px' }}
                          onChange={(e) => this.setState({ approvalDateVal: e.value })} monthNavigator={true} autocomplete="on"
                          yearNavigator={true} yearRange="2010:2020" /> */}
                      <InputText readOnly="true" style={{ width: '100%' }} 
                          value={this.state.approvaldate} placeholder="" />
                      </Col>
                    </Form.Group>


                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                      <Form.Label style={{ color: 'black' }} column sm="2"> Rev. No </Form.Label>
                      <Col sm="4">
                          <InputText readOnly="true" style={{ width: '100%' }} 
                          value={this.state.revizyonno} placeholder="" />
                      </Col>
                    </Form.Group>

                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="2">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Activity</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="activityAcord" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                    <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <Button onClick={this.addActivityShow} style={{ width: '100%' }}
                        variant={this.state.createNewButtonActivityVariant}> {this.state.createNewButtonActivity}</Button>
                        </Col>
                    </Row>
                    <br />
                   
                    <div style={{ display: this.state.activityAddItems ? 'block' : 'none' }}>
 
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridItemno">
                        <Form.Label column sm="2" >Item No</Form.Label>
                      <Col sm="4">
                      <Form.Control name="itemno" value={this.state.itemno}
                            onChange={this.handleChange} />
                      </Col>
                      <Col sm="6">
                      <AntCheckbox checked={this.state.itemGroup} onChange={(e) => { 
                          if(e.target.checked){
                            this.setState({ 
                              itemGroup: e.target.checked,
                              hiddenItemGroup:true,
                              taskactivity:"",
                              qualityprocedure:"",
                              qualityrecord:"",
                              level1GridBsl:"",
                              contractor:"",
                              client:"",
                              level2GridBsl:"",
                              remarks:"",

                            });
                          }else{
                            this.setState({ 
                              itemGroup: e.target.checked,
                              hiddenItemGroup:false,
                              projectphaseactivitycode:"",
                             })  
                          }
                          }}>Item Group</AntCheckbox>
                      </Col>
                    </Form.Group>

                    <Form.Group hidden={!this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridprojectphaseactivitycode">
                        <Form.Label column sm="2" >Project Phase , Activity Code</Form.Label>
                      <Col sm="4">
                      <Form.Control name="projectphaseactivitycode" value={this.state.projectphaseactivitycode}
                            onChange={this.handleChange} />
                      </Col>
                      <Col sm="2">
                      </Col>
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridTaskActivity">
                        <Form.Label column sm="2" >Task / Activity</Form.Label>
                      <Col sm="4">
                      <Form.Control name="taskactivity" value={this.state.taskactivity}
                            onChange={this.handleChange} />
                      </Col>
                      <Col sm="2">
                      </Col>
                    </Form.Group> 

                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridQualityprocedure">
                        <Form.Label column sm="2" >Quality Procedure</Form.Label>
                      <Col sm="4">
                      <Form.Control name="qualityprocedure" value={this.state.qualityprocedure}
                            onChange={this.handleQProcedureChange} as="select"   >
                            <option value="0">Select ..</option>
                            {ProcedureRecordOpt}
                          </Form.Control>
                      </Col> 
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridContractor">
                      <Form.Label column sm="2" >Quality Record</Form.Label>
                      <Col sm="4">
                      <Form.Control name="qualityrecord" value={this.state.qualityrecord}
                            onChange={this.handleQRecordChange} as="select"   >
                            <option value="0">Select ..</option>
                            {ProcedureRecordOpt}
                          </Form.Control>
                      </Col> 
                    </Form.Group>
  
                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridLevel1">
                      <Form.Label column sm="2" >Contractor</Form.Label>
                        <Col sm="4">
                        <Form.Control name="contractor" value={this.state.contractor}
                              onChange={this.handleContractorChange} as="select"   >
                              <option value="0">Select ..</option>
                              {legendOpt}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridLevel1">
                        <Form.Label column sm="2" >Client</Form.Label>
                        <Col sm="4">
                          <Form.Control name="client" value={this.state.client}
                            onChange={this.handleClientChange} as="select"   >
                            <option value="0">Select ..</option>
                            {legendOpt}
                          </Form.Control>
                        </Col>
                    </Form.Group> 
                  
                    

                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridLevel1">
                      <Form.Label column sm="2" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ItpLogInspectionLevel" defaultMessage="Inspection level" />
                      </Form.Label>
                      <Col sm="4"> 
                      </Col> 
                      <Col sm="1">
                        <AntButton type="primary" onClick={this.addNewItemItpInspectionLevel} icon={<PlusOutlined />}></AntButton>
                      </Col>
                    </Form.Group>


                    {this.state.ItpInspectionLevel.map((item, index) => (
                    <Form.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}> 
                      {item.id==0 ?     
                        <Col sm="2">
                          <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                            e.persist();
                            this.setState(prevState => ({
                              ItpInspectionLevel: prevState.ItpInspectionLevel.map(
                                el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))}}/>
                        </Col> :    
                        <Form.Label column sm="2">
                          {item.textName}
                        </Form.Label>
                      }
                      
                      <Col sm="4">
                        <Form.Control name="client" value={item.textField} as="select" 
                          onChange={(e) => {
                            e.persist();
                            this.setState(prevState => ({
                              ItpInspectionLevel: prevState.ItpInspectionLevel.map(
                                el => el.index === item.index ? { ...el, textField: e.target.value } : el
                              )
                            })) 
                          }}>
                          <option value="0">Select ..</option>
                          {legendOpt}
                        </Form.Control>
                      </Col>    
                      <Col sm="1">
                        <AntButton type="primary" onClick={() => this.removeItemItpInspectionLevel(item.index)} danger icon={<CloseOutlined />}></AntButton>
                      </Col> 
                    </Form.Group>
                    ))}



                    <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridIRemarks">
                        <Form.Label column sm="2">Remarks</Form.Label>
                      <Col sm="4">
                      <Form.Control name="remarks" value={this.state.remarks}
                            onChange={this.handleChange} />
                      </Col>
                      <Col sm="2">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="8">
                      <Button onClick={this.addActivity} style={{ width: '100%' }} variant="success">Add</Button>
                      </Col>
                      <Col sm="2">
                      </Col>
                    </Form.Group>
                     </div>
                    <br/>

                    <div className="content-section implementation">
                      {/* <TreeTable value={this.state.itpActivityTreeTable}>
                        <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="ItpActivity Id" />
                        <Column field="itemno" header="Item No." expander></Column> 
                        <Column field="activitycode" header="Project Phase , Activity Code" sortable></Column>
                        <Column field="activity" header="Task / Activity" sortable></Column> 
                        <Column field="qprocedureAck" header="Quality Procedure" sortable></Column> 
                        <Column field="qrecordAck" header="Quality Record" sortable></Column> 
                        <Column field="level1Ack" header={this.state.level1GridBsl} sortable></Column> 
                        <Column field="contractorAck" header="Contractor" sortable></Column> 
                        <Column field="clientAck" header="Client" sortable></Column> 
                        <Column field="level2Ack" header={this.state.level2GridBsl} sortable></Column> 
                        <Column field="remarks" header="Remarks" sortable></Column> 
                      </TreeTable> */}

                      <Table columns={tableColumns} dataSource={this.state.itpActivityTreeTable} pagination={this.state.pagination}
                        responsive striped bordered hover
                        loading={this.state.loading}
                        style={{ padding: "4px 1rem 4px 4px" }}
                        expandable={{
                          expandedRowRender: record =>
                          {
                            if(record.data.itpInspectionLevels!=null && record.data.itpInspectionLevels!=undefined && record.data.itpInspectionLevels.length>0){
                              return <Table columns={tableColumnsInspectionLevels} dataSource={record.data.itpInspectionLevels} pagination={false} responsive striped bordered hover loading={this.state.loading} 
                              style={{ padding: "4px 1rem 4px 4px" }} 
                              />;
                            }
                          }
                          , rowExpandable: record => (record.data!=null && record.data!=undefined && record.data.itpInspectionLevels!=null && record.data.itpInspectionLevels!=undefined) == true ? true : false,
                        }}
                      />
                    </div>  

                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="3">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; General Notes</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="notAcord" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                  <Form.Row>
                      <Col sm="2">
                        <Form.Label style={{ color: 'black' }} >Note</Form.Label>
                      </Col>
                      <Col sm="7">
                          <InputText onChange={(e) => this.setState({ gnNote: e.target.value })} id="itpcodeID" style={{ width: '100%' }} name="gNote"
                            value={this.state.gnNote} placeholder="..." />
                      </Col>
                      <Col sm="3">
                          <Button onClick={this.addGeneralNotesTable} style={{ width: '100%' }} variant="success">Add New</Button>
                      </Col>
                      </Form.Row>
                  </Container>
                  <Table rowSelection={rowSelection} columns={columns}  dataSource={this.state.itemsGnotes}  onRow={(record) => ({
                    onClick: () => {
                      this.selectRow(record);
                    },
                  })} />
                  <br /> 
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
           <br />
          <div hidden={this.state.hiddenSave}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="2"></Col>
                <Col sm="4">
                  <Button onClick={this.addITP} style={{ width: '100%' }} variant="success">{this.state.lastButton}</Button>
                </Col>
              </Row>
            </Container>

          </div>

          <div hidden={this.state.hiddenUpdate}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="2">
                </Col>
                <Col sm="2">
                  <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</Button>
                </Col>
                <Col sm="2">
                  <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div hidden={this.state.tablehidden} className="col-md-12" style={{ marginTop: '10px' }}>
          <div className="datatable-doc-demo" style={{ backgroundColor: 'white', padding: '10px' }}  >
            <div style={{ marginTop: '30px', marginBottom: '30px' }} className="content-section implementation ">

            {/* <Table columns={dataTablecolumns}  
              responsive
              striped
              bordered
              hover
              scroll={{ x: 1300 }}
              dataSource={this.state.datatableValue}
              pagination={{
                pageSize: 5,
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "30"],
                total: this.state.datatableValue.length,
                showQuickJumper: true,
                position: "bottomLeft",
              }}
              expandable={{ expandedRowRender }}
              /> */}

              {/* <DataTable ref={(el) => this.dt = el} value={this.state.datatableValue} style={{ minWidth: '1000px' }} virtualScroll={true} id="itptable"
                className="p-datatable-customers" dataKey="id" rowHover globalFilter={this.state.globalFilter}
                expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                rowExpansionTemplate={this.rowExpansionTemplate}
                paginator rows={10} emptyMessage="No customers found" rowsPerPageOptions={[10, 25, 50]}>
                <Column selectionMode="single" style={{ width: '3em' }} expander={true} />
                <Column header="..." body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                <Column field="index" style={{ textAlign: 'center' }} sortable filter header="S/N" />
                <Column field="documentLog.description" style={{ textAlign: 'center' }} sortable filter header="Document" /> 
                <Column field="dis.disciplineName" style={{ textAlign: 'center' }} filter sortable header="Discipline" />
                <Column field="itpcode" style={{ textAlign: 'center' }} filter sortable header="Itp Code" />
                <Column field="itpname" style={{ textAlign: 'center' }} filter sortable header="ITP name" />
                <Column body={this.renderApprovaldate} style={{ textAlign: 'center' }} filter sortable header="Approval Date" />
                <Column field="revizyonno" style={{ textAlign: 'center' }} filter sortable header="Rev. No" />
              </DataTable> */}

            </div>
          </div>
        </div>


      </div>


    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ItpLog)
