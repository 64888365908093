/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card } from 'react-bootstrap';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Column } from "primereact/column";
import "react-toggle/style.css";
import { DataTable } from 'primereact/components/datatable/DataTable';
import "../settings/css/DataTable.scss";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import WorkFlowExpand from './WorkFlowExpand';
import { InputText } from "primereact/inputtext";
import { PickList } from 'primereact/picklist';
import { Link } from "react-router-dom";
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import WFStart from './WFStart'; 
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';
import { Modal as AntModal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = AntModal;


function convertdate(d) {
  if (d != null) {
    var dt = new Date(d);
    return (''
      + dt.getDate().toString().padStart(2, '0') + '/'
      + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
      + dt.getFullYear().toString().padStart(4, '0') + ' '
      + dt.getHours().toString().padStart(2, '0') + ':'
      + dt.getMinutes().toString().padStart(2, '0') + ':'
      + dt.getSeconds().toString().padStart(2, '0'));
  } else return '';
}

class WorkFlow extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      hidden: true,
      cancelhidden: true,
      tablehidden: false,
      createNewButton: "Create New",
      lastButton: "Save",
      addButton: "Add",
      buttonClassName: "p-button-success",
      createNewButtonVariant: "p-button-info",
      displayFlowchartDialog: false,
      displayLastButton: true,

      workFlowName: "",
      workFlowId: 0,
      dataTableValue: [],
      WorkFlowStepAll: [],

      departments: [],
      departmentId: 0,
      sequence: 0,
      every: 1,
      period: '',
      periodDate: null,
      monthWeekStyle: '',
      periodPoint: 1,
      periodTime: null,
      firstLast: '',
      firstLastPoint: '1',

    };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);

  }

  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
  };

  showError(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
  };

  componentDidMount = async () => {

    this.getWorkFlow();

    const response = await handleRequest("GET", "/Department/getAll");

    if (response.data.length !== 0) {

      const columns = response.data;

      const options = columns.map(function (row) {
        return { name: row.departmentName.toString(), id: row.id }
      })

      this.setState({
        departments: options,

      });
    }

  }

  getWorkFlow = async () => {

    const response = await handleRequest("GET", "/WorkFlow/getAll");
    console.log('datatableValuexxx', response)
    if (Boolean(response.data)) {
      const data = response.data;
      const list = [];
      data.forEach(element => {

        if (Boolean(element.periods))
          element.periods.forEach(period => {
            const obj = Object.assign({}, element);
            obj.key = element.id + '.' + period.id;
            obj.periodId = period.id;
            obj.periodStartDate = moment(period.startDate).format("DD/MM/YYYY HH:mm");
            obj.periodOrder = period.periodOrder;
            list.push(obj);
          });

      });

      this.setState({ dataTableValue: list });
    }

  }

  getWorkFlowStep = async () => {

    const response = await handleRequest("GET", "/WorkFlowStep/getAll");
    console.log(response)
    if (response.data.length !== 0) {
      this.setState({ WorkFlowStepAll: response.data });
    } else {
      this.setState({ WorkFlowStepAll: [] });
    }
  }

  createButtonClick = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        cancelhidden: true,
        tablehidden: true,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "p-button-danger",
      });
    }
    else {
      this.cancelButtonClick();
    }
  }

  cancelButtonClick = () => {
    this.setState({
      hidden: true,
      tablehidden: false,
      addButton: "Add",
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "p-button-info",
      workFlowName: ""
    });
  }

  openFlowchartDialog = async () => {
    if (this.state.workFlowName !== "") {
      this.setState({
        displayFlowchartDialog: true
      })
    }
    else {

    }
  }

  saveWorkFlow = async () => {
    var result = true;
    var errorMessage = [];

    console.log(this.state.periodTime);
    if (!Boolean(this.state.workFlowName)) {
      result = false;
      errorMessage.push('Please type workflow name! ')
    }
    if (!Boolean(this.state.sequence)) {
      result = false;
      errorMessage.push('Please select sequence! ')
    }
    if (!Boolean(this.state.startDate)) {
      result = false;
      errorMessage.push('Please select start date!')
    }

    if (this.state.sequence === '2' && !Boolean(this.state.endDate)) {
      result = false;
      errorMessage.push('Please select end date!')
    }

    if (this.state.sequence === '2' && !Boolean(this.state.period)) {
      result = false;
      errorMessage.push('Please select period!')
    }

    /* getMonthLabels = (moments) => {

        var first = moments[0];
        var result = [];
        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.format("YYYY-MM"),
                year: first.get('year'),
                month: first.month() + 1
            }
            result.push(obj);
            first = first.add(1, 'months')
            console.log(first);
        }

        return result;

    }*/

    if (result) {
      const _periods = [];

      if (this.state.sequence === '1') {
        let obj = { startDate: this.state.startDate };
        _periods.push(obj);
      }
      else
        switch (this.state.period) {
          case 'year':
            let sd = this.state.startDate;
            let first = moment(sd);
            let end = moment(this.state.endDate);
            let every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'years');

              console.log(first);

            }
            break;
          case 'month':
            sd = this.state.startDate;
            let periodTime = moment(this.state.periodTime).format('HH:mm:ss');
            if (this.state.monthWeekStyle === '1') {
              first = moment(sd).date(this.state.periodPoint);
              if (first.isBefore(sd))
                first.add(1, 'months');
            }
            if (this.state.monthWeekStyle === '2') {
              if (this.state.firstLast === 'first') {
                first = moment(sd).startOf('month');
                first = first.day(parseInt(this.state.firstLastPoint));
                if (first.date() > 7)
                  first.add(7, 'd');

                if (first.isBefore(sd)) {
                  first.add(1, 'months');
                  first = first.startOf('month');
                  first = first.day(this.state.firstLastPoint);
                  if (first.date() > 7)
                    first.add(7, 'd');
                }


              }

              if (this.state.firstLast === 'last') {

                first = first.endOf('month');
                first = first.day(this.state.firstLastPoint);
                if (first.isBefore(sd)) {
                  first.add(1, 'months');
                  first = first.endOf('month');
                  first = first.day(this.state.firstLastPoint);
                }
              }

            }

            console.log(periodTime);
            console.log(first);
            let time = periodTime.split(':');
            first.set({
              hours: time[0],
              minutes: time[1],
              seconds: time[2]
            })
            console.log(first);
            end = moment(this.state.endDate);
            every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'months');

              if (this.state.monthWeekStyle === '2') {
                if (this.state.firstLast === 'first') {
                  first = first.startOf('month');
                  first = first.day(this.state.firstLastPoint);
                  if (first.date() > 7)
                    first.add(7, 'd');
                }

                if (this.state.firstLast === 'last') {

                  first = first.endOf('month');
                  first = first.day(this.state.firstLastPoint);

                }

                time = periodTime.split(':');
                first.set({
                  hours: time[0],
                  minutes: time[1],
                  seconds: time[2]
                })

              }


              console.log(first);

            }

            break;
          case 'week':

            sd = this.state.startDate;
            first = moment(sd);
            periodTime = moment(this.state.periodTime).format('HH:mm:ss');
            if (this.state.monthWeekStyle === '1') {
              first = moment(sd).day(this.state.periodPoint);
              if (first.isBefore(sd))
                first.add(1, 'weeks');
            }

            time = periodTime.split(':');
            first.set({
              hours: time[0],
              minutes: time[1],

            })

            if (first.isBefore(sd))
              first.add(1, 'weeks');

            end = moment(this.state.endDate);
            every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'weeks');

              console.log(first);

            }

            break;
          case 'day':
            sd = this.state.startDate;
            first = moment(sd);

            periodTime = moment(this.state.periodTime).format('HH:mm:ss');
            time = periodTime.split(':');
            first.set({
              hours: time[0],
              minutes: time[1],

            })

            if (first.isBefore(sd))
              first.add(1, 'days');

            end = moment(this.state.endDate);
            every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'days');

              console.log(first);

            }
            break;

          default:
            break;
        }

      console.log(_periods);
      const item = {
        id: this.state.workFlowId,
        workFlowName: this.state.workFlowName,
        departmentId: this.state.departmentId,
        sequence: this.state.sequence,
        every: this.state.every,
        period: this.state.period,
        periodDate: this.state.startDate,
        periodPoint: this.state.periodPoint,
        periodTime: this.state.periodTime, 
        monthWeekStyle: this.state.monthWeekStyle,
        firstLast: this.state.firstLast,
        firstLastPoint: this.state.firstLastPoint,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        periods: _periods
      }

      console.log(item);

      if (item.id === 0)
        var response = await handleRequest("POST", "/WorkFlow/add", item);
      else
        response = await handleRequest("POST", "/WorkFlow/update", item);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS-WORKFLOW-001") {
          this.changeButtonSave();
          this.getWorkFlow();
          this.showSuccess();
          this.cancelButtonClick();
        }
       if (response.data === "ERROR-WORKFLOW-001") {
          this.showError("An error was occured please try again later !");
        }
        if (response.data === "ERROR-UPDATE-001") {
          this.showError("This workflow has already been started steps!");
        }
      }
      else if (response.data === "ERROR-WORKFLOW-001") {
        this.showError("An error was occured please try again later !");
      }

    }
    else this.showError(errorMessage.toString());

  };

  convertdateR(rowData, column) {

    var d = null;
    try {
      if (column.field == 'approvaldate')
        d = rowData.approvaldate;
    } catch (error) {
      d = null;
    }
    if (d != null) {
      var dt = new Date(d);
      return (''
        + dt.getDate().toString().padStart(2, '0') + '/'
        + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
        + dt.getFullYear().toString().padStart(4, '0') + ' '
        + dt.getHours().toString().padStart(2, '0') + ':'
        + dt.getMinutes().toString().padStart(2, '0') + ':'
        + dt.getSeconds().toString().padStart(2, '0'));
    } else return '';
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleFlowchartHide = async () => {
    let node = this.state.selectedNodeKey2;
    this.setState({
      displayFlowchartDialog: false,
      workFlowName: "",
    });
    this.getWorkFlow();
  }

  actionTemplate(rowData, column) {
    return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
  };

  linkTemplate(rowData, column) {
    console.log(rowData);
    return <React.Fragment> <Link
      to={{
        pathname: "/WFStart",
        data: rowData
      }}>{rowData.workFlowName}</Link>   </React.Fragment>;
  };

  edit = async (row) => {
    console.log(row);

    let _periodTime = ''; 
    if (Boolean(row.periodTime) && row.periodTime.includes(':')) {
      _periodTime = moment(new Date());
      var time = row.periodTime.split(':');
      _periodTime.set({
        hours: time[0],
        minutes: time[1],
  
      });
      _periodTime.add(1,'hours'); 
    }

   

      this.setState({
        hidden: false,
        cancelhidden: false,
        tablehidden: false,
        createNewButton: "Cancel",
        addButton: "Update",
        displayLastButton: false,
        createNewButtonVariant: "p-button-danger",
        workFlowName: row.workFlowName,
        workFlowId: row.id,

        sequence: row.sequence.toString(),
        startDate: new Date(row.startDate),
        endDate: Boolean(row.endDate) ? new Date(row.endDate) : '',
        departmentId: row.departmentId,
        every: row.every.toString(),
        period: row.period,
        periodDate: new Date(row.periodDate),
        periodPoint: row.periodPoint.toString(),  
        periodTime:   Boolean(row.endDate) && Boolean(_periodTime) ? new Date (_periodTime.format()) : '', //new Date(),// _periodTime.format('HH:mm') ,// moment(row.periodTime).format('HH:mm'),
        monthWeekStyle: row.monthWeekStyle.toString(), 
        firstLastPoint: row.firstLastPoint.toString()

      });

      document.getElementById('kt_scrolltop').click();
  

  };

  cancelButtonUpdate = async (row) => {
    this.setState({
      hidden: true,
      cancelhidden: true,
      tablehidden: false,
      createNewButton: "Create New",
      addButton: "Add",
      displayLastButton: true,
      createNewButtonVariant: "p-button-info",
      workFlowName: ""
    });
  };

  changeButtonSave = () => {
    this.setState({
      workFlowName: "",
      hidden: true,
      buttonName: "Add",
      buttonClassName: "p-button-success",
      workFlowId: 0 ,
      sequence: '0', 
      startDate: null,
      endDate: null,
      departmentId: 0 ,
      every: 0,
      period: '',
      periodDate: null,
      periodPoint: '',
      periodTime:  null ,
      monthWeekStyle: '',
      firstLastPoint: '1' 
    });

  
  };



  wfStepTemplate(wfs) {
    //var imageSource = 'showcase/resources/demo/images/car/' + car.brand + '.png';
    //<img src={imageSource} alt={car.brand} style={{ display: 'inline-block', margin: '2px 0 2px 2px', width: 48 }} />

    return (
      <div className="p-clearfix">
        <div style={{ fontSize: '14px', float: 'right', margin: '15px 5px 0 0' }}>{wfs.actiontype}</div>
      </div>
    );
  }


  deleteWorkflow = async (pId) => {
    const item = {
      id: pId
    }

    await handleRequest("POST", "/WorkFlow/delete", item);

    this.getWorkFlow();
    this.showSuccess();

  }

  deleteModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this workflow?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected workflow will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteWorkflow(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });


  }

  render() {
    let actionHeader = <Button type="button" icon="pi pi-cog"></Button>

    let smClose = () => this.setState({ smShow: false });


    let dialogFlowchartFooter = <div className="ui-dialog-buttonpane p-clearfix">
      <Button>Save</Button>
    </div>;



    return (
      <div>

        <Modal
          size="sm"
          show={this.state.smShow}
          onHide={smClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              {this.state.messagesHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.messages}</Modal.Body>
        </Modal>

        <Toast ref={(el) => this.toast = el}></Toast>
      
        <br />
        {/* <WFStart stepId="1" workFlowName="wfn" /> */}
        {/* <br /> */}
        <div hidden={this.state.tablehidden} className="col-md-12" >
          <div className="datatable-doc-demo" style={{ backgroundColor: 'white'}}  >
            <div style={{ marginTop: '30px', marginBottom: '30px' }} className="content-section implementation ">
              <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue}
                style={{ minWidth: '1000px' }} virtualScroll={true} id="itptable" 
                className="p-datatable-customers" dataKey="key" rowHover globalFilter={this.state.globalFilter}            
                paginator rows={25} emptyMessage="No customers found" rowsPerPageOptions={[10, 25, 50]}>
              
                <Column header="" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                <Column field="index" style={{ textAlign: 'center' }} sortable filter header="S/N" />
                <Column field="periodOrder" style={{ textAlign: 'center' }} sortable filter header="Period No" />
                <Column field="workFlowName" header="Workflow Name" body={this.linkTemplate} style={{ textAlign: 'center' }} sortable filter />
                <Column field="periodStartDate" style={{ textAlign: 'center' }} sortable filter header="Period Start Date" /> 
                <Column field="department.departmentName" style={{ textAlign: 'center' }} sortable filter header="Custodian Department" />
                <Column field="every" style={{ textAlign: 'center' }} sortable filter header="Every" />
                <Column field="period" style={{ textAlign: 'center' }} sortable filter header="Period" />
                <Column field="status" style={{ textAlign: 'center' }} sortable filter header="Status" />
              </DataTable>

            </div>
          </div>
        </div>
      </div>


    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WorkFlow)
