/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chips } from 'primereact/chips';
import { Card, Button, Button as ReactButton, Col, Form, Row, Modal, Form as FormBoot } from 'react-bootstrap';
import { CloseOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Checkbox, Button as AntButton, DatePicker, message, Input, InputNumber, Upload, Space, Select, Table } from 'antd';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { COUNTRIES } from '../settings/JsonDatas/Datas';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import moment from 'moment';
import $ from "jquery";

const { Column } = Table;
const { Option } = Select;
const { TextArea } = Input;

var _manufacturerRows = [
    { manufacturerIndex: 1, description: "Mechanical", checked: false },
    { manufacturerIndex: 2, description: "Electrical", checked: false },
    { manufacturerIndex: 3, description: "Electrical & Instrumentation", checked: false },
    { manufacturerIndex: 4, description: "Structural", checked: false },
];
var _supplierRows = [
    { supplierIndex: 1, description: "Mechanical", checked: false },
    { supplierIndex: 2, description: "Electrical", checked: false },
    { supplierIndex: 3, description: "Electrical & Instrumentation", checked: false },
    { supplierIndex: 4, description: "Structural", checked: false },
];
var _subcontractingRows = [
    { supplierIndex: 1, description: "Welding", checked: false },
    { supplierIndex: 2, description: "Engineering", checked: false },
    { supplierIndex: 3, description: "Construction", checked: false },
    { supplierIndex: 4, description: "Service", checked: false },
];
var _companyDocumentRows = [
    { companyDocumentIndex: 1, companyDocumentPath: "", description: "", checked: false }
];
var _brancheRows = [];
var _manufacturingRows = [];
var _projectRows = [];

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;

var _clientRegistrationRows = [
    {
        clientRegistrationIndex: 1, clientRegistrationDescription: "",
        clientRegistrationManufacturerVendorNo: "", clientRegistrationSupplierVendorNo: "",
        clientRegistrationItem: "", clientRegistrationDate: today, checked: false
    }
];
var _financialPositionRows = [
    { financialPositionIndex: 1, description: "Authorized Capital", value: "" },
    { financialPositionIndex: 2, description: "Present paid-up Capital", value: "" },
    { financialPositionIndex: 3, description: "Accumulated Capital", value: "" },
];
var _taxInformationRows = [
    { taxInformationIndex: 1, description: "Tax Number", value: "" },
    { taxInformationIndex: 2, description: "VAT Number", value: "" },
];
var _bankersInformationRows = [
    { bankersInformationIndex: 1, bankName: "", IBANNo: "" },
];
var _completedProjectsRows = [
    {
        completedProjectsIndex: 1,
        nameOfCustomer: "",
        projectName: "",
        location: "",
        years: "",
        totalValue: "",
        totalValueCurrency: ""
    }
];

export class VendorRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vendorNo: "",
            manufacturer: false,
            manufacturerRows: [],
            supplier: false,
            supplierRows: [],
            subcontracting: false,
            subcontractingRows: [],
            fullnameCompany: "",
            shortnameCompany: "",
            loading: false,
            imageUrl: null,
            logoPath: "",
            aboutCompany: "",
            vision: "",
            mission: "",
            webPage: "",
            employeeListPath: "",
            inhouseResourcesPath: "",
            organizationalChartPath: "",
            certificateOfIncorporation: false,
            certificateOfIncorporationPath: "",
            isoQms: false,
            isoQmsPath: "",
            isoQmsDate: null,
            isoEms: false,
            isoEmsPath: "",
            isoEmsDate: null,
            isoOhsas: false,
            isoOhsasPath: "",
            isoOhsasDate: null,
            financialReports: false,
            financialReportsPath: "",
            companyGroupStructure: false,
            companyGroupStructurePath: "",
            totalManpower: false,
            totalManpowerPath: "",
            listOfMachinery: false,
            listOfMachineryPath: "",
            companyDocumentRows: [{ companyDocumentIndex: 1, companyDocumentPath: "", description: "", checked: false }],
            headOffice: false,
            headOfficeAddress: "",
            headOfficeLocation: "",
            headOfficeCountry: "",
            headOfficeCity: "",
            headOfficePhone: [],
            headOfficeFax: [],
            headOfficeEmail: [],
            headOfficeKeyName: "",
            headOfficeKeyEmail: "",
            headOfficeKeyPhone: "",
            branche: false,
            brancheAddress: "",
            brancheLocation: "",
            brancheCountry: "",
            brancheCity: "",
            branchePhone: [],
            brancheFax: [],
            brancheEmail: [],
            brancheKeyName: "",
            brancheKeyEmail: "",
            brancheKeyPhone: "",
            brancheRows: [],
            manufacturing: false,
            manufacturingAddress: "",
            manufacturingLocation: "",
            manufacturingCountry: "",
            manufacturingCity: "",
            manufacturingPhone: [],
            manufacturingFax: [],
            manufacturingEmail: [],
            manufacturingKeyName: "",
            manufacturingKeyEmail: "",
            manufacturingKeyPhone: "",
            manufacturingRows: [],
            project: false,
            projectAddress: "",
            projectLocation: "",
            projectCountry: "",
            projectCity: "",
            projectPhone: [],
            projectFax: [],
            projectEmail: [],
            projectKeyName: "",
            projectKeyEmail: "",
            projectKeyPhone: "",
            projectRows: [],
            ARAMCO: false,
            ARAMCOManufacturerVendorNo: "",
            ARAMCOSupplierVendorNo: "",
            ARAMCO9com: "",
            ARAMCODate: null,
            SABIC: false,
            SABICManufacturerVendorNo: "",
            SABICSupplierVendorNo: "",
            SABICItem: "",
            SABICDate: null,
            SEC: false,
            SECManufacturerVendorNo: "",
            SECSupplierVendorNo: "",
            SECItem: "",
            SECDate: null,
            royalCommisioning: false,
            royalCommisioningManufacturerVendorNo: "",
            royalCommisioningSupplierVendorNo: "",
            royalCommisioningItem: "",
            royalCommisioningDate: null,
            clientRegistrationRows: [{
                clientRegistrationIndex: 1, clientRegistrationDescription: "",
                clientRegistrationManufacturerVendorNo: "", clientRegistrationSupplierVendorNo: "",
                clientRegistrationItem: "", clientRegistrationDate: today, checked: false
            }],
            financialPosition: false,
            financialPositionRows: [],
            taxInformation: false,
            taxInformationRows: [],
            bankersInformation: false,
            bankersInformationRows: [],
            completedProjectsRows: [],
            productList: [],
            productSave: true,
            productId: null,
            productCode: "",
            productDescription: "",
            productPrice: "",
            productPriceCurrency: "",
            modalDeleteProduct: false,
            serviceList: [],
            serviceSave: true,
            serviceId: null,
            serviceCode: "",
            serviceDescription: "",
            modalDeleteService: false,



            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            deleteModal: false
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    componentDidMount = async () => {

        if (this.props.auth !== undefined) {
            if (this.props.auth !== null) {
                var str = this.props.auth.user.email;
                //var n = str.includes("vendor");
                //one sample vendor e-mail: abc_vendor01@CT.com
                var s = str.split("vendor");

                if (s.length > 1) {
                    var s2 = s[1].split("@");
                    var vendorNo = Number(s2[0]);

                    this.setState({
                        vendorNo: vendorNo
                    });

                    if (vendorNo > 0) {
                        console.log(vendorNo);
                        const responseVendorRecord = await handleRequest("GET", "/VendorRecord/getFindByVendorNo/" + vendorNo.toString());

                        console.log("Vendor Kayıtları");
                        console.log(responseVendorRecord);

                        if (responseVendorRecord.data.length !== 0) {
                            window.location.href = '/VendorPortalTest2';
                        } else {
                            document.body.className = document.body.className + " kt-aside--minimize";
                            // document.getElementById("kt_aside").remove();
                            // document.getElementById("kt_footer").style.left = "0px";
                            // document.getElementById("kt_footer").remove();
                            // document.getElementById("kt_scrolltop").remove();
                            // document.getElementById("kt_header").style.left = "0px";
                            // document.getElementById("kt_subheader").style.left = "0px";
                            // document.getElementById("kt_wrapper").style.paddingLeft = "0px";
                            // document.getElementById("kt_content").style.padding = "0px";
                            // document.getElementById("container").style.padding = "0px";
                        }
                    }
                }
            }
        }

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "VendorRecord" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "VendorRecord",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "VendorRecord");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (this.props.auth !== undefined) {
            if (this.props.auth !== null) {
                var str = this.props.auth.user.username;
                var n = str.includes("vendor");

                var s = str.split("vendor");
                if (s.length > 1) {
                    var s2 = s[1].split("@");
                    var vendorNo = Number(s2[0]);
                    this.setState({
                        vendorNo: vendorNo,
                    });
                }
            }
        }

        this.setState({
            manufacturerRows: _manufacturerRows,
            supplierRows: _supplierRows,
            subcontractingRows: _subcontractingRows,
            isoQmsDate: today,
            isoEmsDate: today,
            isoOhsasDate: today,
            companyDocumentRows: _companyDocumentRows,
            brancheRows: _brancheRows,
            manufacturingRows: _manufacturingRows,
            projectRows: _projectRows,
            clientRegistrationRows: _clientRegistrationRows,
            ARAMCODate: today,
            SABICDate: today,
            SECDate: today,
            royalCommisioningDate: today,
            financialPositionRows: _financialPositionRows,
            taxInformationRows: _taxInformationRows,
            bankersInformationRows: _bankersInformationRows,
            completedProjectsRows: _completedProjectsRows,

        });
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    resetInputs = () => {


    }

    saveVendorRecord = async () => {
        if (this.state.vendorNo !== "") {

            var flagManufacturerLines = [];
            var flagSupplierLines = [];
            var flagSubcontractingLines = [];
            var flagCompanyDocuments = [];
            var flagBranches = [];
            var flagManufacturings = [];
            var flagProjects = [];
            var flagRegistrations = [];
            var flagFinancialPositions = [];
            var flagTaxInformations = [];
            var flagBankersInformations = [];
            var flagCompletedProjects = [];
            var flagProducts = [];
            var flagServices = [];

            if (this.state.manufacturer)
                for (let index = 4; index < this.state.manufacturerRows.length; index++) {
                    flagManufacturerLines.push({
                        manufacturerLineCheck: this.state.manufacturerRows[index].checked,
                        manufacturerLine: this.state.manufacturerRows[index].description
                    });
                }

            if (this.state.supplier)
                for (let index = 4; index < this.state.supplierRows.length; index++) {
                    flagSupplierLines.push({
                        supplierLineCheck: this.state.supplierRows[index].checked,
                        supplierLine: this.state.supplierRows[index].description
                    });
                }

            if (this.state.subcontracting)
                for (let index = 4; index < this.state.subcontractingRows.length; index++) {
                    flagSubcontractingLines.push({
                        subContractingLineCheck: this.state.subcontractingRows[index].checked,
                        subContractingLine: this.state.subcontractingRows[index].description
                    });
                }

            for (let index = 0; index < this.state.companyDocumentRows.length; index++) {
                flagCompanyDocuments.push({
                    companyDocumentLineCheck: this.state.companyDocumentRows[index].checked,
                    companyDocumentLine: this.state.companyDocumentRows[index].description,
                    companyDocumentLinePath: this.state.companyDocumentRows[index].companyDocumentPath
                });
            }

            for (let index = 0; index < this.state.brancheRows.length; index++) {
                flagBranches.push({
                    brancheLineCheck: this.state.brancheRows[index].checked,
                    brancheLineAddress: this.state.brancheRows[index].brancheAddress,
                    brancheLineLocation: this.state.brancheRows[index].brancheLocation,
                    brancheLineCountry: this.state.brancheRows[index].brancheCountry,
                    brancheLineCity: this.state.brancheRows[index].brancheCity,
                    brancheLinePhone: this.state.brancheRows[index].branchePhone.join(),
                    brancheLineFax: this.state.brancheRows[index].brancheFax.join(),
                    brancheLineEmail: this.state.brancheRows[index].brancheEmail.join(),
                    brancheLineKeyContactName: this.state.brancheRows[index].brancheKeyName,
                    brancheLineKeyContactPhone: this.state.brancheRows[index].brancheKeyPhone,
                    brancheLineKeyContactEmail: this.state.brancheRows[index].brancheKeyEmail
                });
            }

            for (let index = 0; index < this.state.manufacturingRows.length; index++) {
                flagManufacturings.push({
                    manufacturingLineCheck: this.state.manufacturingRows[index].checked,
                    manufacturingLineAddress: this.state.manufacturingRows[index].manufacturingAddress,
                    manufacturingLineLocation: this.state.manufacturingRows[index].manufacturingLocation,
                    manufacturingLineCountry: this.state.manufacturingRows[index].manufacturingCountry,
                    manufacturingLineCity: this.state.manufacturingRows[index].manufacturingCity,
                    manufacturingLinePhone: this.state.manufacturingRows[index].manufacturingPhone.join(),
                    manufacturingLineFax: this.state.manufacturingRows[index].manufacturingFax.join(),
                    manufacturingLineEmail: this.state.manufacturingRows[index].manufacturingEmail.join(),
                    manufacturingLineKeyContactName: this.state.manufacturingRows[index].manufacturingKeyName,
                    manufacturingLineKeyContactPhone: this.state.manufacturingRows[index].manufacturingKeyPhone,
                    manufacturingLineKeyContactEmail: this.state.manufacturingRows[index].manufacturingKeyEmail
                });
            }

            for (let index = 0; index < this.state.projectRows.length; index++) {
                flagProjects.push({
                    projectLineCheck: this.state.projectRows[index].checked,
                    projectLineAddress: this.state.projectRows[index].projectAddress,
                    projectLineLocation: this.state.projectRows[index].projectLocation,
                    projectLineCountry: this.state.projectRows[index].projectCountry,
                    projectLineCity: this.state.projectRows[index].projectCity,
                    projectLinePhone: this.state.projectRows[index].projectPhone.join(),
                    projectLineFax: this.state.projectRows[index].projectFax.join(),
                    projectLineEmail: this.state.projectRows[index].projectEmail.join(),
                    projectLineKeyContactName: this.state.projectRows[index].projectKeyName,
                    projectLineKeyContactPhone: this.state.projectRows[index].projectKeyPhone,
                    projectLineKeyContactEmail: this.state.projectRows[index].projectKeyEmail
                });
            }

            for (let index = 0; index < this.state.clientRegistrationRows.length; index++) {
                flagRegistrations.push({
                    registrationLineCheck: this.state.clientRegistrationRows[index].checked,
                    registrationLine: this.state.clientRegistrationRows[index].clientRegistrationDescription,
                    registrationLineManufacturerVendorNo: this.state.clientRegistrationRows[index].clientRegistrationManufacturerVendorNo,
                    registrationLineSupplierVendorNo: this.state.clientRegistrationRows[index].clientRegistrationSupplierVendorNo,
                    registrationLineItemNo: this.state.clientRegistrationRows[index].clientRegistrationItem,
                    registrationLineValidityDate: this.state.clientRegistrationRows[index].clientRegistrationDate
                });
            }

            for (let index = 3; index < this.state.financialPositionRows.length; index++) {
                flagFinancialPositions.push({
                    financialPositionLine: this.state.financialPositionRows[index].description,
                    financialPositionValueLine: this.state.financialPositionRows[index].value
                });
            }

            for (let index = 2; index < this.state.taxInformationRows.length; index++) {
                flagTaxInformations.push({
                    taxInformationLine: this.state.taxInformationRows[index].description,
                    taxInformationValueLine: this.state.taxInformationRows[index].value
                });
            }

            for (let index = 1; index < this.state.bankersInformationRows.length; index++) {
                flagBankersInformations.push({
                    bankersInformationLineBankName: this.state.bankersInformationRows[index].bankName,
                    bankersInformationLineIbanNo: this.state.bankersInformationRows[index].IBANNo
                });
            }

            for (let index = 0; index < this.state.completedProjectsRows.length; index++) {
                flagCompletedProjects.push({
                    nameOfCustomer: this.state.completedProjectsRows[index].nameOfCustomer,
                    projectName: this.state.completedProjectsRows[index].projectName,
                    location: this.state.completedProjectsRows[index].location,
                    years: this.state.completedProjectsRows[index].years,
                    totalValue: this.state.completedProjectsRows[index].totalValue,
                    totalValueCurrency: this.state.completedProjectsRows[index].totalValueCurrency
                });
            }

            for (let index = 0; index < this.state.productList.length; index++) {
                flagProducts.push({
                    productCode: this.state.productList[index].productCode,
                    productDescription: this.state.productList[index].productDescription,
                    productPrice: this.state.productList[index].productPrice,
                    productPriceCurrency: this.state.productList[index].productPriceCurrency,
                    productPhotoPath: this.state.productList[index].productPhoto
                });
            }

            for (let index = 0; index < this.state.serviceList.length; index++) {
                flagServices.push({
                    serviceCode: this.state.productList[index].serviceCode,
                    serviceDescription: this.state.productList[index].serviceDescription
                });
            }

            const newVendorRecord = {
                vendorNo: this.state.vendorNo,
                manufacturer: this.state.manufacturer,
                manufacturerMechanical: this.state.manufacturerRows[0].checked,
                manufacturerElectrical: this.state.manufacturerRows[1].checked,
                manufacturerElectricalAndIns: this.state.manufacturerRows[2].checked,
                manufacturerStructural: this.state.manufacturerRows[3].checked,
                manufacturers: flagManufacturerLines,
                supplier: this.state.supplier,
                supplierrMechanical: this.state.supplierRows[0].checked,
                supplierElectrical: this.state.supplierRows[1].checked,
                supplierElectricalAndIns: this.state.supplierRows[2].checked,
                supplierStructural: this.state.supplierRows[3].checked,
                suppliers: flagSupplierLines,
                subContracting: this.state.subcontracting,
                subContractingWelding: this.state.subcontractingRows[0].checked,
                subContractingEngineering: this.state.subcontractingRows[1].checked,
                subContractingConstruction: this.state.subcontractingRows[2].checked,
                subContractingService: this.state.subcontractingRows[3].checked,
                subContractings: flagSubcontractingLines,
                fullNameCompany: this.state.fullnameCompany,
                shortNameCompany: this.state.shortnameCompany,
                logoPath: this.state.logoPath,
                aboutCompany: this.state.aboutCompany,
                vision: this.state.vision,
                mission: this.state.mission,
                webPage: this.state.webPage,
                employeeListPath: this.state.employeeListPath,
                inHouseResourcesPath: this.state.inhouseResourcesPath,
                organizationalChartPath: this.state.organizationalChartPath,
                certificateOfIncorporation: this.state.certificateOfIncorporation,
                certificateOfIncorporationPath: this.state.certificateOfIncorporationPath,
                isoQms: this.state.isoQms,
                isoQmsValidityDate: this.state.isoQmsDate,
                isoQmsPath: this.state.isoQmsPath,
                isoEms: this.state.isoEms,
                isoEmsValidityDate: this.state.isoEmsDate,
                isoEmsPath: this.state.isoEmsPath,
                isoOhsas: this.state.isoOhsas,
                isoOhsasValidityDate: this.state.isoOhsasDate,
                isoOhsasPath: this.state.isoOhsasPath,
                financialReport: this.state.financialReports,
                financialReportPath: this.state.financialReportsPath,
                companyGroup: this.state.companyGroupStructure,
                companyGroupPath: this.state.companyGroupStructurePath,
                totalManpower: this.state.totalManpower,
                totalManpowerPath: this.state.totalManpowerPath,
                listOfMachinery: this.state.listOfMachinery,
                listOfMachineryPath: this.state.listOfMachineryPath,
                companyDocuments: flagCompanyDocuments,
                headOfficeCheck: this.state.headOffice,
                headOfficeAddress: this.state.headOfficeAddress,
                headOfficeLocation: this.state.headOfficeLocation,
                headOfficeCountry: this.state.headOfficeCountry,
                headOfficeCity: this.state.headOfficeCity,
                headOfficePhone: this.state.headOfficePhone.join(),
                headOfficeFax: this.state.headOfficeFax.join(),
                headOfficeEmail: this.state.headOfficeEmail.join(),
                headOfficeKeyContactName: this.state.headOfficeKeyName,
                headOfficeKeyContactPhone: this.state.headOfficeKeyPhone,
                headOfficeKeyContactEmail: this.state.headOfficeKeyEmail,
                brancheCheck: this.state.brancheCheck,
                brancheAddress: this.state.brancheAddress,
                brancheLocation: this.state.brancheLocation,
                brancheCountry: this.state.brancheCountry,
                brancheCity: this.state.brancheCity,
                branchePhone: this.state.branchePhone.join(),
                brancheFax: this.state.brancheFax.join(),
                brancheEmail: this.state.brancheEmail.join(),
                brancheKeyContactName: this.state.brancheKeyName,
                brancheKeyContactPhone: this.state.brancheKeyPhone,
                brancheKeyContactEmail: this.state.brancheKeyContactEmail,
                branches: flagBranches,
                manufacturingCheck: this.state.manufacturing,
                manufacturingAddress: this.state.manufacturingAddress,
                manufacturingLocation: this.state.manufacturingLocation,
                manufacturingCountry: this.state.manufacturingCountry,
                manufacturingCity: this.state.manufacturingCity,
                manufacturingPhone: this.state.manufacturingPhone.join(),
                manufacturingFax: this.state.manufacturingFax.join(),
                manufacturingEmail: this.state.manufacturingEmail.join(),
                manufacturingKeyContactName: this.state.manufacturingKeyContactName,
                manufacturingKeyContactPhone: this.state.manufacturingKeyPhone,
                manufacturingKeyContactEmail: this.state.manufacturingKeyContactEmail,
                manufacturings: flagManufacturings,
                projectCheck: this.state.project,
                projectAddress: this.state.projectAddress,
                projectLocation: this.state.projectLocation,
                projectCountry: this.state.projectCountry,
                projectCity: this.state.projectCity,
                projectPhone: this.state.projectPhone.join(),
                projectFax: this.state.projectFax.join(),
                projectEmail: this.state.projectEmail.join(),
                projectKeyContactName: this.state.projectKeyName,
                projectKeyContactPhone: this.state.projectKeyPhone,
                projectKeyContactEmail: this.state.projectKeyEmail,
                projects: flagProjects,
                // aramcoCheck: this.state.ARAMCO,
                // aramcoManufacturerVendorNo: this.state.ARAMCOManufacturerVendorNo,
                // aramcoSupplierVendorNo: this.state.ARAMCOSupplierVendorNo,
                // aramcoComNo: this.state.ARAMCO9com,
                // aramcoValidityDate: this.state.ARAMCODate,
                // sabicCheck: this.state.SABIC,
                // sabicManufacturerVendorNo: this.state.SABICManufacturerVendorNo,
                // sabicSupplierVendorNo: this.state.SABICSupplierVendorNo,
                // sabicItemNo: this.state.SABICItem,
                // sabicValidityDate: this.state.SABICDate,
                // secCheck: this.state.SEC,
                // secManufacturerVendorNo: this.state.SECManufacturerVendorNo,
                // secSupplierVendorNo: this.state.SECSupplierVendorNo,
                // secItemNo: this.state.SECItem,
                // secValidityDate: this.state.SECDate,
                // royalCommisioningCheck: this.state.royalCommisioning,
                // royalCommisioningManufacturerVendorNo: this.state.royalCommisioningManufacturerVendorNo,
                // royalCommisioningSupplierVendorNo: this.state.royalCommisioningSupplierVendorNo,
                // royalCommisioningItemNo: this.state.royalCommisioningItem,
                // royalCommisioningValidityDate: this.state.royalCommisioningDate,
                registrations: flagRegistrations,
                // financialPositionCheck: this.state.financialPosition,
                // authorizedCapital: this.state.financialPositionRows[0].value,
                // paidUpCapital: this.state.financialPositionRows[1].value,
                // accumulatedCapital: this.state.financialPositionRows[2].value,
                financialPositions: flagFinancialPositions,
                // taxInformationCheck: this.state.taxInformation,
                // taxInformationTaxNumber: this.state.taxInformationRows[0].value,
                // taxInformationVatNumber: this.state.taxInformationRows[1].value,
                taxInformations: flagTaxInformations,
                bankersInformationCheck: this.state.bankersInformation,
                bankersInformationBankName: this.state.bankersInformationRows[0].bankName,
                bankersInformationIbanNo: this.state.bankersInformationRows[0].IBANNo,
                bankersInformations: flagBankersInformations,
                completedProjects: flagCompletedProjects,
                products: flagProducts,
                services: flagServices
            }

            var response = await handleRequest("POST", "/VendorRecord/add", newVendorRecord);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.showSuccess("All data has been saved successfully.");
                    window.location.href = '/VendorPortalTest2';
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalEdit = () => {
        this.resetInputs();
        this.setState({
            modalEdit: !this.state.modalEdit,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeManufacturerChekbox = (index, e) => {
        let list = this.state.manufacturerRows;
        list[index].checked = e.target.checked;

        this.setState({
            manufacturerRows: list.slice()
        });
    }

    handleChangeManufacturerDescription = (index, e) => {
        let list = this.state.manufacturerRows;
        list[index].description = e.target.value;

        this.setState({
            manufacturerRows: list.slice()
        });
    }

    addNewManufacturerLine = () => {
        _manufacturerRows.push(
            {
                manufacturerIndex: _manufacturerRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            manufacturerRows: _manufacturerRows
        });
    }

    removeManufacturerLine = (index, e) => {
        _manufacturerRows.splice(index, 1);

        this.setState({
            manufacturerRows: _manufacturerRows
        });
    }

    handleChangeSupplierChekbox = (index, e) => {
        let list = this.state.supplierRows;
        list[index].checked = e.target.checked;

        this.setState({
            supplierRows: list.slice()
        });
    }

    handleChangeSupplierDescription = (index, e) => {
        let list = this.state.supplierRows;
        list[index].description = e.target.value;

        this.setState({
            supplierRows: list.slice()
        });
    }

    addNewSupplierLine = () => {
        _supplierRows.push(
            {
                supplierIndex: _supplierRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            supplierRows: _supplierRows
        });
    }

    removeSupplierLine = (index, e) => {
        _supplierRows.splice(index, 1);

        this.setState({
            supplierRows: _supplierRows
        });
    }

    handleChangeSubcontractingChekbox = (index, e) => {
        let list = this.state.subcontractingRows;
        list[index].checked = e.target.checked;

        this.setState({
            subcontractingRows: list.slice()
        });
    }

    handleChangeSubcontractingDescription = (index, e) => {
        let list = this.state.subcontractingRows;
        list[index].description = e.target.value;

        this.setState({
            subcontractingRows: list.slice()
        });
    }

    addNewSubcontractingLine = () => {
        _subcontractingRows.push(
            {
                subcontractingIndex: _subcontractingRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            subcontractingRows: _subcontractingRows
        });
    }

    removeSubcontractingLine = (index, e) => {
        _subcontractingRows.splice(index, 1);

        this.setState({
            subcontractingRows: _subcontractingRows
        });
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    logoPath: info.file.response[0]
                })
            }

            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    }

    beforeUpload2 = (file) => {
        const isPDF = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
        if (!isPDF) {
            message.error("You can only upload JPEG, PNG or PDF file!");
            $(".ant-upload-list").empty();
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("Image must smaller than 10MB!");
            $(".ant-upload-list").empty();
        }
        return isPDF && isLt10M;
    }

    handleEmployeeListFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    employeeListPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleInHouseResourcesFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    inhouseResourcesPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleOrganizationalChartFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    organizationalChartPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleCertificateOfIncorporationFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    certificateOfIncorporationPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    onChangeISOQMSDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                isoQmsDate: dateString
            });
        } else {
            this.setState({
                isoQmsDate: today
            });
        }
    }

    onChangeISOEMSDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                isoEmsDate: dateString
            });
        } else {
            this.setState({
                isoEmsDate: today
            });
        }
    }

    onChangeISOOHSASDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                isoOhsasDate: dateString
            });
        } else {
            this.setState({
                isoOhsasDate: today
            });
        }
    }

    disabledDate = (current) => {
        return current < moment().endOf('day');
    }

    handleISOQMSFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    isoQmsPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleISOEMSFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    isoEmsPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleISOOHSASFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    isoOhsasPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleFinancialReportFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    financialReportsPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleCompanyGroupStructureFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    companyGroupStructurePath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleTotalManpowerFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    totalManpowerPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleListOfMachineryFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    listOfMachineryPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    addNewCompanyDocumentLine = () => {
        _companyDocumentRows.push(
            {
                companyDocumentIndex: _companyDocumentRows.length + 1,
                companyDocumentPath: "",
                description: "",
                checked: false
            }
        );

        this.setState({
            companyDocumentRows: _companyDocumentRows
        });
    }

    removeCompanyDocumentLine = (index, e) => {
        _companyDocumentRows.splice(index, 1);

        this.setState({
            companyDocumentRows: _companyDocumentRows
        });
    }

    handleChangeCompanyDocumentChekbox = (index, e) => {
        let list = this.state.companyDocumentRows;
        list[index].checked = e.target.checked;

        this.setState({
            companyDocumentRows: list.slice()
        });
    }

    handleChangeCompanyDocumentDescription = (index, e) => {
        let list = this.state.companyDocumentRows;
        list[index].description = e.target.value;

        this.setState({
            companyDocumentRows: list.slice()
        });
    }

    handleCompanyDocumentFileChange = (index, info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                let list = this.state.companyDocumentRows;
                list[index].companyDocumentPath = info.file.response[0];

                console.log(list);

                this.setState({
                    companyDocumentRows: list.slice()
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    addNewBrancheLine = () => {
        _brancheRows.push(
            {
                brancheIndex: _brancheRows.length + 1,
                brancheAddress: "",
                brancheLocation: "",
                brancheCountry: "",
                brancheCity: "",
                branchePhone: [],
                brancheFax: [],
                brancheEmail: [],
                brancheKeyName: "",
                brancheKeyPhone: "",
                brancheKeyEmail: "",
                checked: false
            }
        );

        this.setState({
            brancheRows: _brancheRows
        });
    }

    removeBrancheLine = (index, e) => {
        _brancheRows.splice(index, 1);

        this.setState({
            brancheRows: _brancheRows
        });
    }

    handleChangeBrancheChekbox = (index, e) => {
        let list = this.state.brancheRows;
        list[index].checked = e.target.checked;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheAddress = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheAddress = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheLocation = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheLocation = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheCountry = (index, e) => {
        e.preventDefault();
        let list = this.state.brancheRows;
        list[index].brancheCountry = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheCity = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheCity = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBranchePhone = (index, e) => {
        let list = this.state.brancheRows;
        list[index].branchePhone = e.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheFax = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheFax = e.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheEmail = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheEmail = e.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheKeyName = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheKeyName = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheKeyPhoneNo = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheKeyPhone = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheKeyEmail = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheKeyEmail = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    addNewManufacturingLine = () => {
        _manufacturingRows.push(
            {
                manufacturingIndex: _manufacturingRows.length + 1,
                manufacturingAddress: "",
                manufacturingLocation: "",
                manufacturingCountry: "",
                manufacturingCity: "",
                manufacturingPhone: [],
                manufacturingFax: [],
                manufacturingEmail: [],
                manufacturingKeyName: "",
                manufacturingKeyPhone: "",
                manufacturingKeyEmail: "",
                checked: false
            }
        );

        this.setState({
            manufacturingRows: _manufacturingRows
        });
    }

    removeManufacturingLine = (index, e) => {
        _manufacturingRows.splice(index, 1);

        this.setState({
            manufacturingRows: _manufacturingRows
        });
    }

    handleChangeManufacturingChekbox = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].checked = e.target.checked;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingAddress = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingAddress = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingLocation = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingLocation = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingCountry = (index, e) => {
        e.preventDefault();
        let list = this.state.manufacturingRows;
        list[index].manufacturingCountry = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingCity = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingCity = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingPhone = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingPhone = e.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingFax = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingFax = e.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingEmail = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingEmail = e.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingKeyName = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingKeyName = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingKeyPhoneNo = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingKeyPhone = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingKeyEmail = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingKeyEmail = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    addNewProjectLine = () => {
        _projectRows.push(
            {
                projectIndex: _projectRows.length + 1,
                projectAddress: "",
                projectLocation: "",
                projectCountry: "",
                projectCity: "",
                projectPhone: [],
                projectFax: [],
                projectEmail: [],
                projectKeyName: "",
                projectKeyPhone: "",
                projectKeyEmail: "",
                checked: false
            }
        );

        this.setState({
            projectRows: _projectRows
        });
    }

    removeProjectLine = (index, e) => {
        _projectRows.splice(index, 1);

        this.setState({
            projectRows: _projectRows
        });
    }

    handleChangeProjectChekbox = (index, e) => {
        let list = this.state.projectRows;
        list[index].checked = e.target.checked;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectAddress = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectAddress = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectLocation = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectLocation = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectCountry = (index, e) => {
        e.preventDefault();
        let list = this.state.projectRows;
        list[index].projectCountry = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectCity = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectCity = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectPhone = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectPhone = e.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectFax = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectFax = e.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectEmail = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectEmail = e.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectKeyName = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectKeyName = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectKeyPhoneNo = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectKeyPhone = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectKeyEmail = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectKeyEmail = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    onChangeARAMCODate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                ARAMCODate: dateString
            });
        } else {
            this.setState({
                ARAMCODate: today
            });
        }
    }

    onChangeSABICDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                SABICDate: dateString
            });
        } else {
            this.setState({
                SABICDate: today
            });
        }
    }

    onChangeSECDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                SECDate: dateString
            });
        } else {
            this.setState({
                SECDate: today
            });
        }
    }

    onChangeRoyalCommisioningDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                royalCommisioningDate: dateString
            });
        } else {
            this.setState({
                royalCommisioningDate: today
            });
        }
    }

    addNewClientRegistrationLine = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _clientRegistrationRows.push(
            {
                clientRegistrationIndex: _clientRegistrationRows.length + 1,
                clientRegistrationDescription: "",
                clientRegistrationManufacturerVendorNo: "",
                clientRegistrationSupplierVendorNo: "",
                clientRegistrationItem: "",
                clientRegistrationDate: today,
                checked: false
            }
        );

        this.setState({
            clientRegistrationRows: _clientRegistrationRows
        });
    }

    removeClientRegistrationLine = (index, e) => {
        _clientRegistrationRows.splice(index, 1);

        this.setState({
            clientRegistrationRows: _clientRegistrationRows
        });
    }

    handleChangeClientRegistrationChekbox = (index, e) => {
        let list = this.state.clientRegistrationRows;
        list[index].checked = e.target.checked;

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    handleChangeClientRegistrationDescription = (index, e) => {
        let list = this.state.clientRegistrationRows;
        list[index].clientRegistrationDescription = e.target.value;

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    handleChangeClientRegistrationDescription = (index, e) => {
        let list = this.state.clientRegistrationRows;
        list[index].clientRegistrationDescription = e.target.value;

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    handleChangeClientRegistrationMVendorNo = (index, e) => {
        let list = this.state.clientRegistrationRows;
        list[index].clientRegistrationManufacturerVendorNo = e.target.value;

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    handleChangeClientRegistrationSVendorNo = (index, e) => {
        let list = this.state.clientRegistrationRows;
        list[index].clientRegistrationSupplierVendorNo = e.target.value;

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    handleChangeClientRegistrationItem = (index, e) => {
        let list = this.state.clientRegistrationRows;
        list[index].clientRegistrationItem = e.target.value;

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    onChangeClientRegistrationDate = async (index, date, dateString) => {
        let list = this.state.clientRegistrationRows;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            list[index].clientRegistrationDate = dateString;
        } else {
            list[index].clientRegistrationDate = today;
        }

        this.setState({
            clientRegistrationRows: list.slice()
        });
    }

    handleChangeFinancialPositionDescription = (index, e) => {
        let list = this.state.financialPositionRows;
        list[index].description = e.target.value;

        this.setState({
            financialPositionRows: list.slice()
        });
    }

    handleChangeFinancialPositionValue = (index, e) => {
        let list = this.state.financialPositionRows;
        list[index].value = e.target.value;

        this.setState({
            financialPositionRows: list.slice()
        });
    }

    addNewFinancialPositionLine = () => {
        _financialPositionRows.push(
            {
                financialPositionIndex: _financialPositionRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            financialPositionRows: _financialPositionRows
        });
    }

    removeFinancialPositionLine = (index, e) => {
        _financialPositionRows.splice(index, 1);

        this.setState({
            financialPositionRows: _financialPositionRows
        });
    }

    handleChangeTaxInformationDescription = (index, e) => {
        let list = this.state.taxInformationRows;
        list[index].description = e.target.value;

        this.setState({
            taxInformationRows: list.slice()
        });
    }

    handleChangeTaxInformationValue = (index, e) => {
        let list = this.state.taxInformationRows;
        list[index].value = e.target.value;

        this.setState({
            taxInformationRows: list.slice()
        });
    }

    addNewTaxInformationLine = () => {
        _taxInformationRows.push(
            {
                financialPositionIndex: _taxInformationRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            taxInformationRows: _taxInformationRows
        });
    }

    removeTaxInformationLine = (index, e) => {
        _taxInformationRows.splice(index, 1);

        this.setState({
            taxInformationRows: _taxInformationRows
        });
    }

    handleChangeBankersInformationBankName = (index, e) => {
        let list = this.state.bankersInformationRows;
        list[index].bankName = e.target.value;

        this.setState({
            bankersInformationRows: list.slice()
        });
    }

    handleChangeBankersInformationIBANNo = (index, e) => {
        let list = this.state.bankersInformationRows;
        list[index].IBANNo = e.target.value;

        this.setState({
            bankersInformationRows: list.slice()
        });
    }

    addNewBankersInformationLine = () => {
        _bankersInformationRows.push(
            {
                bankersInformationIndex: _bankersInformationRows.length + 1,
                bankName: "",
                IBANNo: ""
            }
        );

        this.setState({
            bankersInformationRows: _bankersInformationRows
        });
    }

    removeBankersInformationLine = (index, e) => {
        _bankersInformationRows.splice(index, 1);

        this.setState({
            bankersInformationRows: _bankersInformationRows
        });
    }

    handleChangeCompletedProjectsNameOfCustomer = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].nameOfCustomer = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsProjectName = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].projectName = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsLocation = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].location = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsYears = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].years = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsTotalValue = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].totalValue = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsTotalValueCurrency = (index, value) => {
        let list = this.state.completedProjectsRows;
        list[index].totalValueCurrency = value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    addNewCompletedProjectsLine = () => {
        _completedProjectsRows.push(
            {
                completedProjectsIndex: _completedProjectsRows.length + 1,
                nameOfCustomer: "",
                projectName: "",
                location: "",
                years: "",
                totalValue: "",
                totalValueCurrency: ""
            }
        );

        this.setState({
            completedProjectsRows: _completedProjectsRows
        });
    }

    removeCompletedProjectsLine = (index, e) => {
        _completedProjectsRows.splice(index, 1);

        this.setState({
            completedProjectsRows: _completedProjectsRows
        });
    }

    addProduct = () => {
        var list = this.state.productList;

        if (this.state.productCode !== "" && this.state.productDescription !== "") {
            const newItem = {
                productIndex: Math.floor(Math.random() * 10001),
                productCode: this.state.productCode,
                productDescription: this.state.productDescription,
                productPrice: this.state.productPrice,
                productPriceCurrency: this.state.productPriceCurrency,
                productPhoto: this.state.productPhotoPath
            }

            list.push(newItem);

            this.setState({
                productList: list.slice(),
                productCode: "",
                productDescription: "",
                productPrice: "",
                productPriceCurrency: "",
                productPhoto: ""
            })
        } else {
            this.showWarning();
        }
    }

    updateProduct = () => {
        let list = this.state.productList;
        let objIndex = list.findIndex((obj => obj.productIndex === this.state.productId));

        list[objIndex].productCode = this.state.productCode;
        list[objIndex].productDescription = this.state.productDescription;
        list[objIndex].productPrice = this.state.productPrice;
        list[objIndex].productPriceCurrency = this.state.productPriceCurrency;
        list[objIndex].productPhoto = this.state.productPhoto;

        this.setState({
            productList: list.slice(),
            productCode: "",
            productDescription: "",
            productPrice: "",
            productPriceCurrency: "",
            productPhoto: "",
            productSave: true
        });
    }

    cancelProduct = () => {
        this.setState({
            productId: null,
            productCode: "",
            productDescription: "",
            productPrice: "",
            productPriceCurrency: "",
            productPhoto: "",
            productSave: true
        });
    }

    deleteProductModal = (row) => {
        this.setState({
            productId: row.key,
            modalDeleteProduct: true
        });
    }

    deleteProductModalOK = () => {
        let list = this.state.productList;
        let flagProductId = this.state.productId;

        list.splice(list.findIndex(function (i) {
            return i.productIndex === flagProductId;
        }), 1);

        this.setState({
            productList: list.slice(),
            productId: null,
            modalDeleteProduct: false,
            productCode: "",
            productDescription: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    editProduct = (row) => {
        this.setState({
            productId: row.productIndex,
            productCode: row.productCode,
            productDescription: row.productDescription,
            productPrice: row.productPrice,
            productPriceCurrency: row.productPriceCurrency,
            productPhoto: row.productPhotoPath,
            productSave: false
        });
    }

    showOrHideModalDeleteProduct = () => {
        this.setState({
            modalDeleteProduct: !this.state.modalDeleteProduct
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addService = () => {
        var list = this.state.serviceList;

        if (this.state.serviceCode !== "" && this.state.serviceDescription !== "") {
            const newItem = {
                serviceIndex: Math.floor(Math.random() * 10001),
                serviceCode: this.state.serviceCode,
                serviceDescription: this.state.serviceDescription,
            }

            list.push(newItem);

            this.setState({
                serviceList: list.slice(),
                serviceCode: "",
                serviceDescription: ""
            })
        } else {
            this.showWarning();
        }
    }

    updateService = () => {
        let list = this.state.serviceList;
        let objIndex = list.findIndex((obj => obj.serviceIndex === this.state.serviceId));

        list[objIndex].serviceCode = this.state.serviceCode;
        list[objIndex].serviceDescription = this.state.serviceDescription;

        this.setState({
            serviceList: list.slice(),
            serviceCode: "",
            serviceDescription: "",
            serviceSave: true
        });
    }

    cancelService = () => {
        this.setState({
            serviceId: null,
            serviceCode: "",
            serviceDescription: "",
            serviceSave: true
        });
    }

    deleteServiceModal = (row) => {
        this.setState({
            serviceId: row.key,
            modalDeleteService: true
        });
    }

    deleteServiceModalOK = () => {
        let list = this.state.serviceList;
        let flagServiceId = this.state.serviceId;

        list.splice(list.findIndex(function (i) {
            return i.serviceIndex === flagServiceId;
        }), 1);

        this.setState({
            serviceList: list.slice(),
            serviceId: null,
            modalDeleteService: false,
            serviceCode: "",
            serviceDescription: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    editService = (row) => {
        this.setState({
            serviceId: row.serviceIndex,
            serviceCode: row.serviceCode,
            serviceDescription: row.serviceDescription,
            serviceSave: false,
        });
    }

    showOrHideModalDeleteService = () => {
        this.setState({
            modalDeleteService: !this.state.modalDeleteService
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleProductPhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    productPhotoPath: info.file.response[0]
                })
            }

            this.getBase64(info.file.originFileObj, imageUrl2 =>
                this.setState({
                    imageUrl2,
                    loading: false,
                }),
            );
        }
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        let optionsCountry = COUNTRIES.map((data) =>
            <option
                key={data.value}
                value={data.name}
            >
                {data.name}
            </option>
        );

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteRFQ}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalDeleteProduct}
                        onHide={this.showOrHideModalDeleteProduct}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete Product Record?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalDeleteProduct}>Close</Button>
                            <Button variant="danger" onClick={this.deleteProductModalOK} >Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalDeleteService}
                        onHide={this.showOrHideModalDeleteService}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete Service Record?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalDeleteService}>Close</Button>
                            <Button variant="danger" onClick={this.deleteServiceModalOK} >Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "10px" }}>
                            <Form>
                                <Form.Label style={{ marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Sector of Company
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ manufacturer: e.target.checked }) }} checked={this.state.manufacturer}>Manufacturer</Checkbox>
                                        </Col>
                                        {(this.state.manufacturer) && (
                                            <Col sm="3">
                                            </Col>)}
                                        {(this.state.manufacturer) && (
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addNewManufacturerLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {this.state.manufacturerRows.map((item, index) => (
                                        (index < 4) && (this.state.manufacturer) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="3">
                                                    <Checkbox onChange={(e) => this.handleChangeManufacturerChekbox(index, e)} checked={item.checked}>{item.description}</Checkbox>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    {this.state.manufacturerRows.map((item, index) => (
                                        (index >= 4) && (this.state.manufacturer) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <Checkbox onChange={(e) => this.handleChangeManufacturerChekbox(index, e)} checked={item.checked} />
                                                        <Input placeholder="Other" value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturerDescription(index, e)} />
                                                    </Space>
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton type="primary" danger onClick={(e) => this.removeManufacturerLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ supplier: e.target.checked }) }} checked={this.state.supplier}>Supplier</Checkbox>
                                        </Col>
                                        {(this.state.supplier) && (
                                            <Col sm="3">
                                            </Col>)}
                                        {(this.state.supplier) && (
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addNewSupplierLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {this.state.supplierRows.map((item, index) => (
                                        (index < 4) && (this.state.supplier) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="3">
                                                    <Checkbox onChange={(e) => this.handleChangeSupplierChekbox(index, e)} checked={item.checked}>{item.description}</Checkbox>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    {this.state.supplierRows.map((item, index) => (
                                        (index >= 4) && (this.state.supplier) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <Checkbox onChange={(e) => this.handleChangeSupplierChekbox(index, e)} checked={item.checked} />
                                                        <Input placeholder="Other" value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeSupplierDescription(index, e)} />
                                                    </Space>
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton type="primary" danger onClick={(e) => this.removeSupplierLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ subcontracting: e.target.checked }) }} checked={this.state.subcontracting}>Sub-Contracting</Checkbox>
                                        </Col>
                                        {(this.state.subcontracting) && (
                                            <Col sm="3">
                                            </Col>)}
                                        {(this.state.subcontracting) && (
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addNewSubcontractingLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {this.state.subcontractingRows.map((item, index) => (
                                        (index < 4) && (this.state.subcontracting) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="3">
                                                    <Checkbox onChange={(e) => this.handleChangeSubcontractingChekbox(index, e)} checked={item.checked}>{item.description}</Checkbox>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    {this.state.subcontractingRows.map((item, index) => (
                                        (index >= 4) && (this.state.subcontracting) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <Checkbox onChange={(e) => this.handleChangeSubcontractingChekbox(index, e)} checked={item.checked} />
                                                        <Input placeholder="Other" value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeSubcontractingDescription(index, e)} />
                                                    </Space>
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton type="primary" danger onClick={(e) => this.removeSubcontractingLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                </div>

                                <Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Company Information
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Form.Label column sm="2" style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'bold', color: '#d35400' }} >
                                            Overall
                                        </Form.Label>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Full  name of Company:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.fullnameCompany} style={{ width: "100%" }} onChange={(e) => { this.setState({ fullnameCompany: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Short name of Company:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.shortnameCompany} style={{ width: "100%" }} onChange={(e) => { this.setState({ shortnameCompany: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Logo:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action={`${API_BASE}/VendorRecord/upload`}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handlePhotoChange}
                                            >
                                                {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            About Company:
                                        </Form.Label>
                                        <Col sm="3">
                                            <TextArea rows={4} value={this.state.aboutCompany} style={{ width: "100%" }} onChange={(e) => { this.setState({ aboutCompany: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Vision:
                                        </Form.Label>
                                        <Col sm="3">
                                            <TextArea rows={4} value={this.state.vision} style={{ width: "100%" }} onChange={(e) => { this.setState({ vision: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Mission:
                                        </Form.Label>
                                        <Col sm="3">
                                            <TextArea rows={4} value={this.state.mission} style={{ width: "100%" }} onChange={(e) => { this.setState({ mission: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Web page:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.webPage} style={{ width: "100%" }} onChange={(e) => { this.setState({ webPage: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Employee List:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                onChange={this.handleEmployeeListFileChange}>
                                                <AntButton><UploadOutlined />Upload File</AntButton>
                                            </Upload>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            In house Resources:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                onChange={this.handleInHouseResourcesFileChange}>
                                                <AntButton><UploadOutlined />Upload File</AntButton>
                                            </Upload>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Organizational Chart:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                onChange={this.handleOrganizationalChartFileChange}>
                                                <AntButton><UploadOutlined />Upload File</AntButton>
                                            </Upload>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Form.Label column sm="2" style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'bold', color: '#d35400' }} >
                                            Company Documents
                                        </Form.Label>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ certificateOfIncorporation: e.target.checked }) }} checked={this.state.certificateOfIncorporation}>Certificate of Incorporation</Checkbox>
                                        </Col>
                                        {(this.state.certificateOfIncorporation) && (
                                            <Col sm="3">
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                    onChange={this.handleCertificateOfIncorporationFileChange}>
                                                    <AntButton><UploadOutlined />Upload File</AntButton>
                                                </Upload>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ isoQms: e.target.checked }) }} checked={this.state.isoQms}>ISO 9001 QMS</Checkbox>
                                        </Col>
                                        {(this.state.isoQms) && (
                                            <Col sm="6">
                                                <Space>
                                                    <div style={{ color: 'black' }} >Validity Date</div>
                                                    <DatePicker value={moment(this.state.isoQmsDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeISOQMSDate} />
                                                    <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                        onChange={this.handleISOQMSFileChange}>
                                                        <AntButton><UploadOutlined />Upload File</AntButton>
                                                    </Upload>
                                                </Space>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ isoEms: e.target.checked }) }} checked={this.state.isoEms}>ISO 14001 EMS</Checkbox>
                                        </Col>
                                        {(this.state.isoEms) && (
                                            <Col sm="6">
                                                <Space>
                                                    <div style={{ color: 'black' }}>Validity Date</div>
                                                    <DatePicker value={moment(this.state.isoEmsDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeISOEMSDate} />
                                                    <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                        onChange={this.handleISOEMSFileChange}>
                                                        <AntButton><UploadOutlined />Upload File</AntButton>
                                                    </Upload>
                                                </Space>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ isoOhsas: e.target.checked }) }} checked={this.state.isoOhsas}>ISO 18001 OHSAS</Checkbox>
                                        </Col>
                                        {(this.state.isoOhsas) && (
                                            <Col sm="6">
                                                <Space>
                                                    <div style={{ color: 'black' }}>Validity Date</div>
                                                    <DatePicker value={moment(this.state.isoOhsasDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeISOOHSASDate} />
                                                    <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                        onChange={this.handleISOOHSASFileChange}>
                                                        <AntButton><UploadOutlined />Upload File</AntButton>
                                                    </Upload>
                                                </Space>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ financialReports: e.target.checked }) }} checked={this.state.financialReports}>Financial Reports of Last Three Years</Checkbox>
                                        </Col>
                                        {(this.state.financialReports) && (
                                            <Col sm="3">
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                    onChange={this.handleFinancialReportFileChange}>
                                                    <AntButton><UploadOutlined />Upload File</AntButton>
                                                </Upload>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ companyGroupStructure: e.target.checked }) }} checked={this.state.companyGroupStructure}>Company Group Structure</Checkbox>
                                        </Col>
                                        {(this.state.companyGroupStructure) && (
                                            <Col sm="3">
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                    onChange={this.handleCompanyGroupStructureFileChange}>
                                                    <AntButton><UploadOutlined />Upload File</AntButton>
                                                </Upload>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ totalManpower: e.target.checked }) }} checked={this.state.totalManpower}>Total Manpower</Checkbox>
                                        </Col>
                                        {(this.state.totalManpower) && (
                                            <Col sm="3">
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                    onChange={this.handleTotalManpowerFileChange}>
                                                    <AntButton><UploadOutlined />Upload File</AntButton>
                                                </Upload>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ listOfMachinery: e.target.checked }) }} checked={this.state.listOfMachinery}>List of Machinery</Checkbox>
                                        </Col>
                                        {(this.state.listOfMachinery) && (
                                            <Col sm="3">
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                    onChange={this.handleListOfMachineryFileChange}>
                                                    <AntButton><UploadOutlined />Upload File</AntButton>
                                                </Upload>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    <Form.Group key="0" style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeCompanyDocumentChekbox(0, e)} checked={this.state.companyDocumentRows[0].checked} />
                                                <Input placeholder="Other" value={this.state.companyDocumentRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompanyDocumentDescription(0, e)} />
                                            </Space>
                                        </Col>
                                        {(this.state.companyDocumentRows[0].checked) && (
                                            <Col sm="3">
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                    onChange={(info) => this.handleCompanyDocumentFileChange(0, info)}>
                                                    <AntButton><UploadOutlined />Upload File</AntButton>
                                                </Upload>
                                            </Col>)}
                                        {(!this.state.companyDocumentRows[0].checked) && (
                                            <Col sm="3">
                                            </Col>)}
                                        <Col sm="3">
                                            <AntButton type="primary" onClick={this.addNewCompanyDocumentLine} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    </Form.Group>

                                    {this.state.companyDocumentRows.map((item, index) => (
                                        (index > 0) && (<Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="1">
                                            </Col>
                                            <Col sm="2">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeCompanyDocumentChekbox(index, e)} checked={item.checked} />
                                                    <Input placeholder="Other" value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompanyDocumentDescription(index, e)} />
                                                </Space>
                                            </Col>
                                            {(item.checked) && (
                                                <Col sm="3">
                                                    <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/VendorRecord/upload`} beforeUpload={this.beforeUpload2}
                                                        onChange={(info) => this.handleCompanyDocumentFileChange(index, info)}>
                                                        <AntButton><UploadOutlined />Upload File</AntButton>
                                                    </Upload>
                                                </Col>
                                            )}
                                            {(!item.checked) && (
                                                <Col sm="3">
                                                </Col>
                                            )}
                                            <Col sm="3">
                                                <AntButton type="primary" danger onClick={(e) => this.removeCompanyDocumentLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                        )))}

                                </div>

                                <Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Contacts
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ headOffice: e.target.checked }) }} checked={this.state.headOffice}>Head Office</Checkbox>
                                        </Col>
                                    </Form.Group>

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.headOfficeAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeAddress: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.headOfficeLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeLocation: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="headOfficeCountry" value={this.state.headOfficeCountry}
                                                onChange={this.handleChange} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.headOfficeCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeCity: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.headOfficePhone} onChange={(e) => this.setState({ headOfficePhone: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.headOfficeFax} onChange={(e) => this.setState({ headOfficeFax: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.headOfficeEmail} onChange={(e) => this.setState({ headOfficeEmail: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                        </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.headOfficeKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeKeyName: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.headOfficeKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeKeyPhone: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.headOffice) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.headOfficeKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeKeyEmail: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ branche: e.target.checked }) }} checked={this.state.branche}>Branche</Checkbox>
                                        </Col>
                                        {(this.state.branche) && (
                                            <Col sm="4">
                                            </Col>
                                        )}
                                        {(this.state.branche) && (this.state.brancheRows.length === 0) && (
                                            <Col sm="3">
                                                <AntButton type="primary" onClick={this.addNewBrancheLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.brancheAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheAddress: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.brancheLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheLocation: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="brancheCountry" value={this.state.brancheCountry}
                                                onChange={this.handleChange} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.brancheCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheCity: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.branchePhone} onChange={(e) => this.setState({ branchePhone: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.brancheFax} onChange={(e) => this.setState({ brancheFax: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.brancheEmail} onChange={(e) => this.setState({ brancheEmail: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                        </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.brancheKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheKeyName: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.brancheKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheKeyPhone: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.brancheKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheKeyEmail: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {this.state.brancheRows.map((item, index) => (
                                        <div>
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                </Col>
                                                <Col sm="2">
                                                    <Checkbox onChange={(e) => this.handleChangeBrancheChekbox(index, e)} checked={item.checked}>Branche-{index + 2}</Checkbox>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <AntButton type="primary" danger onClick={(e) => this.removeBrancheLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                        {(this.state.brancheRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewBrancheLine} icon={<PlusOutlined />}></AntButton>)}
                                                    </Space>
                                                </Col>
                                            </Form.Group>

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Address:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.brancheAddress} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheAddress(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Location:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.brancheLocation} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheLocation(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Country:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control name="brancheCountry" value={item.brancheCountry}
                                                        onChange={(e) => this.handleChangeBrancheCountry(index, e)} as="select">
                                                        <option value="">Select Country</option>
                                                        {optionsCountry}
                                                    </Form.Control>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    City:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.brancheCity} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheCity(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Phone No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.branchePhone} onChange={(e) => this.handleChangeBranchePhone(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Fax:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.brancheFax} onChange={(e) => this.handleChangeBrancheFax(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Email:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.brancheEmail} onChange={(e) => this.handleChangeBrancheEmail(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Key Contact
                                            </Form.Label>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Name:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.brancheKeyName} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheKeyName(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Phone No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.brancheKeyPhone} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheKeyPhoneNo(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Email:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.brancheKeyEmail} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheKeyEmail(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                        </div>
                                    ))}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ manufacturing: e.target.checked }) }} checked={this.state.manufacturing}>Manufacturing</Checkbox>
                                        </Col>
                                        {(this.state.manufacturing) && (
                                            <Col sm="4">
                                            </Col>
                                        )}
                                        {(this.state.manufacturing) && (this.state.manufacturingRows.length === 0) && (
                                            <Col sm="3">
                                                <AntButton type="primary" onClick={this.addNewManufacturingLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.manufacturingAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingAddress: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.manufacturingLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingLocation: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="manufacturingCountry" value={this.state.manufacturingCountry}
                                                onChange={this.handleChange} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.manufacturingCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingCity: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                    </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.manufacturingPhone} onChange={(e) => this.setState({ manufacturingPhone: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                                    </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.manufacturingFax} onChange={(e) => this.setState({ manufacturingFax: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                    </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.manufacturingEmail} onChange={(e) => this.setState({ manufacturingEmail: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                    </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.manufacturingKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingKeyName: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.manufacturingKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingKeyPhone: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                    </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.manufacturingKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingKeyEmail: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {this.state.manufacturingRows.map((item, index) => (
                                        <div>
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                </Col>
                                                <Col sm="2">
                                                    <Checkbox onChange={(e) => this.handleChangeManufacturingChekbox(index, e)} checked={item.checked}>Manufacturing-{index + 2}</Checkbox>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <AntButton type="primary" danger onClick={(e) => this.removeManufacturingLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                        {(this.state.manufacturingRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewManufacturingLine} icon={<PlusOutlined />}></AntButton>)}
                                                    </Space>
                                                </Col>
                                            </Form.Group>

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Address:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.manufacturingAddress} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingAddress(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Location:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.manufacturingLocation} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingLocation(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Country:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control name="manufacturingCountry" value={item.manufacturingCountry}
                                                        onChange={(e) => this.handleChangeManufacturingCountry(index, e)} as="select">
                                                        <option value="">Select Country</option>
                                                        {optionsCountry}
                                                    </Form.Control>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    City:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.manufacturingCity} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingCity(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Phone No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.manufacturingPhone} onChange={(e) => this.handleChangeManufacturingPhone(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Fax:
                                            </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.manufacturingFax} onChange={(e) => this.handleChangeManufacturingFax(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Email:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.manufacturingEmail} onChange={(e) => this.handleChangeManufacturingEmail(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Key Contact
                                                </Form.Label>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Name:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.manufacturingKeyName} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingKeyName(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Phone No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.manufacturingKeyPhone} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingKeyPhoneNo(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Email:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.manufacturingKeyEmail} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingKeyEmail(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                        </div>
                                    ))}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ project: e.target.checked }) }} checked={this.state.project}>Project</Checkbox>
                                        </Col>
                                        {(this.state.project) && (
                                            <Col sm="4">
                                            </Col>
                                        )}
                                        {(this.state.project) && (this.state.projectRows.length === 0) && (
                                            <Col sm="3">
                                                <AntButton type="primary" onClick={this.addNewProjectLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.projectAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectAddress: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.projectLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectLocation: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="projectCountry" value={this.state.projectCountry}
                                                onChange={this.handleChange} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.projectCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectCity: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.projectPhone} onChange={(e) => this.setState({ projectPhone: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.projectFax} onChange={(e) => this.setState({ projectFax: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                        </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.projectEmail} onChange={(e) => this.setState({ projectEmail: e.value })}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                        </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.projectKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectKeyName: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.projectKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectKeyPhone: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.projectKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectKeyEmail: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {this.state.projectRows.map((item, index) => (
                                        <div>
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                </Col>
                                                <Col sm="2">
                                                    <Checkbox onChange={(e) => this.handleChangeProjectChekbox(index, e)} checked={item.checked}>Project-{index + 2}</Checkbox>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <AntButton type="primary" danger onClick={(e) => this.removeProjectLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                        {(this.state.projectRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewProjectLine} icon={<PlusOutlined />}></AntButton>)}
                                                    </Space>
                                                </Col>
                                            </Form.Group>

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Address:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.projectAddress} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectAddress(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Location:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.projectLocation} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectLocation(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Country:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control name="projectCountry" value={item.projectCountry}
                                                        onChange={(e) => this.handleChangeProjectCountry(index, e)} as="select">
                                                        <option value="">Select Country</option>
                                                        {optionsCountry}
                                                    </Form.Control>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    City:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.projectCity} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectCity(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Phone No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.projectPhone} onChange={(e) => this.handleChangeProjectPhone(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Fax:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.projectFax} onChange={(e) => this.handleChangeProjectFax(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Email:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <div className="content-section implementation p-fluid">
                                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.projectEmail} onChange={(e) => this.handleChangeProjectEmail(index, e)}></Chips>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Key Contact
                                                </Form.Label>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Name:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.projectKeyName} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectKeyName(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Phone No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.projectKeyPhone} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectKeyPhoneNo(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                                    Email:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.projectKeyEmail} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectKeyEmail(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                        </div>
                                    ))}

                                </div>

                                <Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Client Registration
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ ARAMCO: e.target.checked }) }} checked={this.state.ARAMCO}>ARAMCO</Checkbox>
                                        </Col>
                                    </Form.Group>

                                    {(this.state.ARAMCO) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Manufacturer Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.ARAMCOManufacturerVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ ARAMCOManufacturerVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.ARAMCO) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Supplier Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.ARAMCOSupplierVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ ARAMCOSupplierVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.ARAMCO) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            9 com #:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.ARAMCO9com} style={{ width: "100%" }} onChange={(e) => { this.setState({ ARAMCO9com: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.ARAMCO) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Validity Date:
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker value={moment(this.state.ARAMCODate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeARAMCODate} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ SABIC: e.target.checked }) }} checked={this.state.SABIC}>SABIC</Checkbox>
                                        </Col>
                                    </Form.Group>

                                    {(this.state.SABIC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Manufacturer Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.SABICManufacturerVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ SABICManufacturerVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.SABIC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Supplier Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.SABICSupplierVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ SABICSupplierVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.SABIC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Item #:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.SABICItem} style={{ width: "100%" }} onChange={(e) => { this.setState({ SABICItem: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.SABIC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Validity Date:
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker value={moment(this.state.SABICDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeSABICDate} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ SEC: e.target.checked }) }} checked={this.state.SEC}>SEC</Checkbox>
                                        </Col>
                                    </Form.Group>

                                    {(this.state.SEC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Manufacturer Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.SECManufacturerVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ SECManufacturerVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.SEC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Supplier Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.SECSupplierVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ SECSupplierVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.SEC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Item #:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.SECItem} style={{ width: "100%" }} onChange={(e) => { this.setState({ SECItem: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.SEC) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Validity Date:
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker value={moment(this.state.SECDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeSECDate} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ royalCommisioning: e.target.checked }) }} checked={this.state.royalCommisioning}>Royal Commisioning</Checkbox>
                                        </Col>
                                    </Form.Group>

                                    {(this.state.royalCommisioning) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Manufacturer Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.royalCommisioningManufacturerVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ royalCommisioningManufacturerVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.royalCommisioning) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Supplier Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.royalCommisioningSupplierVendorNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ royalCommisioningSupplierVendorNo: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.royalCommisioning) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Item #:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.royalCommisioningItem} style={{ width: "100%" }} onChange={(e) => { this.setState({ royalCommisioningItem: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.royalCommisioning) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Validity Date:
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker value={moment(this.state.royalCommisioningDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeRoyalCommisioningDate} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Form.Group key="0" style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeClientRegistrationChekbox(0, e)} checked={this.state.clientRegistrationRows[0].checked} />
                                                <Input placeholder="Other" value={this.state.clientRegistrationRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeClientRegistrationDescription(0, e)} />
                                            </Space>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                        {(this.state.clientRegistrationRows.length === 1) && (<Col sm="3">
                                            <AntButton type="primary" onClick={this.addNewClientRegistrationLine} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                        )}
                                    </Form.Group>

                                    {(this.state.clientRegistrationRows[0].checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Manufacturer Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.clientRegistrationRows[0].clientRegistrationManufacturerVendorNo} style={{ width: "100%" }} onChange={(e) => { this.handleChangeClientRegistrationMVendorNo(0, e) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.clientRegistrationRows[0].checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Supplier Vendor No:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.clientRegistrationRows[0].clientRegistrationSupplierVendorNo} style={{ width: "100%" }} onChange={(e) => { this.handleChangeClientRegistrationSVendorNo(0, e) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.clientRegistrationRows[0].checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Item #:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input value={this.state.clientRegistrationRows[0].clientRegistrationItem} style={{ width: "100%" }} onChange={(e) => { this.handleChangeClientRegistrationItem(0, e) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(this.state.clientRegistrationRows[0].checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Validity Date:
                                        </Form.Label>
                                        <Col sm="3">
                                            <DatePicker value={moment(this.state.clientRegistrationRows[0].clientRegistrationDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={(date, dateString) => { this.onChangeClientRegistrationDate(0, date, dateString) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {this.state.clientRegistrationRows.map((item, index) => (
                                        (index > 0) && (<div>

                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                </Col>
                                                <Col sm="2">
                                                    <Space>
                                                        <Checkbox onChange={(e) => this.handleChangeClientRegistrationChekbox(index, e)} checked={item.checked} />
                                                        <Input placeholder="Other" value={item.clientRegistrationDescription} style={{ width: "100%" }} onChange={(e) => this.handleChangeClientRegistrationDescription(index, e)} />
                                                    </Space>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="3">
                                                    <Space>
                                                        <AntButton type="primary" danger onClick={(e) => this.removeClientRegistrationLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                        {(this.state.clientRegistrationRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewClientRegistrationLine} icon={<PlusOutlined />}></AntButton>)}
                                                    </Space>
                                                </Col>
                                            </Form.Group>

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Manufacturer Vendor No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.clientRegistrationManufacturerVendorNo} style={{ width: "100%" }} onChange={(e) => this.handleChangeClientRegistrationMVendorNo(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Supplier Vendor No:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.clientRegistrationSupplierVendorNo} style={{ width: "100%" }} onChange={(e) => this.handleChangeClientRegistrationSVendorNo(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Item #:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input value={item.clientRegistrationItem} style={{ width: "100%" }} onChange={(e) => this.handleChangeClientRegistrationItem(index, e)} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                            {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Validity Date:
                                                </Form.Label>
                                                <Col sm="3">
                                                    <DatePicker value={moment(item.clientRegistrationDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={(date, dateString) => { this.onChangeClientRegistrationDate(index, date, dateString) }} />
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Form.Group>
                                            )}

                                        </div>
                                        )))}

                                </div>

                                <Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Financial
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ financialPosition: e.target.checked }) }} checked={this.state.financialPosition}>Financial Position</Checkbox>
                                        </Col>
                                        {(this.state.financialPosition) && (
                                            <Col sm="3">
                                            </Col>)}
                                        {(this.state.financialPosition) && (
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addNewFinancialPositionLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {this.state.financialPositionRows.map((item, index) => (
                                        (index < 3) && (this.state.financialPosition) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    {item.description}
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Input value={item.value} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionValue(index, e)} />
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    {this.state.financialPositionRows.map((item, index) => (
                                        (index >= 3) && (this.state.financialPosition) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="2">
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionDescription(index, e)} />
                                                </Col>
                                                <Col sm="2">
                                                    <Input value={item.value} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionValue(index, e)} />
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton type="primary" danger onClick={(e) => this.removeFinancialPositionLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ taxInformation: e.target.checked }) }} checked={this.state.taxInformation}>Tax Information</Checkbox>
                                        </Col>
                                        {(this.state.taxInformation) && (
                                            <Col sm="3">
                                            </Col>)}
                                        {(this.state.taxInformation) && (
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addNewTaxInformationLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {this.state.taxInformationRows.map((item, index) => (
                                        (index < 2) && (this.state.taxInformation) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    {item.description}
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Input value={item.value} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionValue(index, e)} />
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    {this.state.taxInformationRows.map((item, index) => (
                                        (index >= 2) && (this.state.taxInformation) && (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="2">
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeTaxInformationDescription(index, e)} />
                                                </Col>
                                                <Col sm="2">
                                                    <Input value={item.value} style={{ width: "100%" }} onChange={(e) => this.handleChangeTaxInformationValue(index, e)} />
                                                </Col>
                                                <Col sm="1">
                                                    <AntButton type="primary" danger onClick={(e) => this.removeTaxInformationLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>
                                        )
                                    ))}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ bankersInformation: e.target.checked }) }} checked={this.state.bankersInformation}>Bankers Information</Checkbox>
                                        </Col>
                                        {(this.state.bankersInformation) && (
                                            <Col sm="3">
                                            </Col>)}
                                        {(this.state.bankersInformation) && (
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addNewBankersInformationLine} icon={<PlusOutlined />}></AntButton>
                                            </Col>
                                        )}
                                    </Form.Group>

                                    {this.state.bankersInformationRows.map((item, index) => (
                                        (index < 1) && (this.state.bankersInformation) && (
                                            <div>
                                                <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        Bank Name
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Input value={item.bankName} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationBankName(index, e)} />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        IBAN No
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Input value={item.IBANNo} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationIBANNo(index, e)} />
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                        )
                                    ))}

                                    {this.state.bankersInformationRows.map((item, index) => (
                                        (index >= 1) && (this.state.bankersInformation) && (
                                            <div>
                                                <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        Bank Name
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Input value={item.bankName} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationBankName(index, e)} />
                                                    </Col>
                                                    <Col sm="1">
                                                        <AntButton type="primary" danger onClick={(e) => this.removeBankersInformationLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                                        IBAN No
                                                    </Form.Label>
                                                    <Col sm="2">
                                                        <Input value={item.IBANNo} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationIBANNo(index, e)} />
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                        )
                                    ))}

                                </div>

                                <Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    References
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Form.Label column sm="2" style={{ marginLeft: '20px', fontSize: '14px', fontWeight: 'bold', color: '#d35400' }} >
                                            Completed Projects
                                        </Form.Label>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="1">
                                            {(this.state.completedProjectsRows.length === 0) && (<AntButton type="primary" onClick={this.addNewCompletedProjectsLine} icon={<PlusOutlined />}></AntButton>)}
                                        </Col>
                                    </Form.Group>

                                    {this.state.completedProjectsRows.map((item, index) => (
                                        <div>
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Project - {index + 1}
                                                </Form.Label>
                                                <Col sm="2">
                                                </Col>
                                                <Col sm="1">
                                                    <Space>
                                                        <AntButton type="primary" danger onClick={(e) => this.removeCompletedProjectsLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                        {(this.state.completedProjectsRows.length > 0) && (this.state.completedProjectsRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewCompletedProjectsLine} icon={<PlusOutlined />}></AntButton>)}
                                                    </Space>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Name of Customer/Client:
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Input value={item.nameOfCustomer} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsNameOfCustomer(index, e)} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Project Name
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Input value={item.projectName} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsProjectName(index, e)} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Location
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Input value={item.location} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsLocation(index, e)} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Years
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Input value={item.years} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsYears(index, e)} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Total Value
                                                </Form.Label>
                                                <Col sm="2">
                                                    <Space>
                                                        <Input value={item.totalValue} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsTotalValue(index, e)} />
                                                        <Select value={item.totalValueCurrency} placeholder="Select a Currency" onChange={(value) => this.handleChangeCompletedProjectsTotalValueCurrency(index, value)} >
                                                            <Option value="$">$</Option>
                                                            <Option value="€">€</Option>
                                                            <Option value="SR">SR</Option>
                                                        </Select>
                                                    </Space>
                                                </Col>
                                            </Form.Group>

                                        </div>
                                    ))}

                                </div>

                                {(this.state.manufacturer) && (<Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Products
                                </Form.Label>
                                )}

                                {(this.state.manufacturer) && (<div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Code:
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={this.state.productCode} style={{ width: "100%" }} onChange={(e) => { this.setState({ productCode: e.target.value }) }} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Description:
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={this.state.productDescription} style={{ width: "100%" }} onChange={(e) => { this.setState({ productDescription: e.target.value }) }} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Price
                                        </Form.Label>
                                        <Col sm="2">
                                            <Space>
                                                <InputNumber value={this.state.productPrice} onChange={(value) => { this.setState({ productPrice: value }) }} />
                                                <Select placeholder="Select a Currency" value={this.state.productPriceCurrency} onChange={(value) => { this.setState({ productPriceCurrency: value }) }} >
                                                    <Option value="$">$</Option>
                                                    <Option value="€">€</Option>
                                                    <Option value="SR">SR</Option>
                                                </Select>
                                            </Space>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Product Photo
                                        </Form.Label>
                                        <Col sm="2">
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action={`${API_BASE}/VendorRecord/upload`}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handleProductPhotoChange}
                                            >
                                                {this.state.imageUrl2 ? <img src={this.state.imageUrl2} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </Col>
                                    </Form.Group>

                                    {(this.state.productSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Button onClick={this.addProduct} type="button">Add</Button>
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(!this.state.productSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Space>
                                                <Button variant="warning" onClick={this.updateProduct} type="button">Update</Button>
                                                <Button variant="secondary" onClick={this.cancelProduct} type="button">Cancel</Button>
                                            </Space>
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.productList} bordered >
                                        <Column
                                            width='50px'
                                            title="Action"
                                            key="action"
                                            render={(text, record) => (
                                                <React.Fragment key={record.id}>
                                                    <CustomMaterialMenu row={record} onDeleteRow={this.deleteProductModal.bind(this)} onEditRow={this.editProduct.bind(this)} />
                                                </React.Fragment>
                                            )}
                                        />
                                        <Column
                                            width='50px'
                                            title="S/N"
                                            key="sn"
                                            render={(text, record) => {
                                                let objIndex = this.state.productList.findIndex((obj => obj.productIndex === record.productIndex));
                                                return (<div style={{ textAlign: "center" }}>{objIndex + 1}</div>);
                                            }}
                                        />
                                        <Column title="Code" key="productCode" dataIndex="productCode" />
                                        <Column title="Description" key="productDescription" dataIndex="productDescription" />
                                        <Column title="Price" key="productPrice"
                                            render={(text, record) => {
                                                return (<div style={{ textAlign: "center" }}>{record.productPrice} {record.productPriceCurrency}</div>);
                                            }}
                                        />
                                        <Column title="Product Photo" key="productPhoto"
                                            render={(text, record) => {
                                                return (<div style={{ textAlign: "center" }}></div>);
                                            }}
                                        />
                                    </Table>
                                </div>
                                )}

                                {(this.state.supplier) && (<Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Services
                                </Form.Label>
                                )}

                                {(this.state.supplier) && (<div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Code:
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={this.state.serviceCode} style={{ width: "100%" }} onChange={(e) => { this.setState({ serviceCode: e.target.value }) }} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Description:
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={this.state.serviceDescription} style={{ width: "100%" }} onChange={(e) => { this.setState({ serviceDescription: e.target.value }) }} />
                                        </Col>
                                    </Form.Group>

                                    {(this.state.serviceSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Button onClick={this.addService} type="button">Add</Button>
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(!this.state.serviceSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Space>
                                                <Button variant="warning" onClick={this.updateService} type="button">Update</Button>
                                                <Button variant="secondary" onClick={this.cancelService} type="button">Cancel</Button>
                                            </Space>
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.serviceList} bordered >
                                        <Column
                                            width='50px'
                                            title="Action"
                                            key="action"
                                            render={(text, record) => (
                                                <React.Fragment key={record.id}>
                                                    <CustomMaterialMenu row={record} onDeleteRow={this.deleteServiceModal.bind(this)} onEditRow={this.editService.bind(this)} />
                                                </React.Fragment>
                                            )}
                                        />
                                        <Column
                                            width='50px'
                                            title="S/N"
                                            key="sn"
                                            render={(text, record) => {
                                                let objIndex = this.state.serviceList.findIndex((obj => obj.serviceIndex === record.serviceIndex));
                                                return (<div style={{ textAlign: "center" }}>{objIndex + 1}</div>);
                                            }}
                                        />
                                        <Column title="Code" key="serviceCode" dataIndex="serviceCode" />
                                        <Column title="Description" key="serviceDescription" dataIndex="serviceDescription" />
                                    </Table>
                                </div>
                                )}

                                <Form.Label style={{ marginTop: '1rem', marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', color: '#2e86c1' }} >
                                    Questions
                                </Form.Label>

                                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                                    This part will be completed later.
                                </div>

                                <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton id="VendorRecordSaveButton"
                                            style={{ width: '100%' }} onClick={this.saveVendorRecord} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                        </ReactButton>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                </Form.Group>

                            </Form>
                        </div>
                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(VendorRecord)