import React,{Component} from 'react';
import AddItemToSelectForm from './AddItemToSelectForm';
import { connect } from 'react-redux'
import { onNewSelectItemSubmit } from "../../../redux/actions/newSelectItemAction";
class AddItemToSelect extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  
  render() {
    return (
      <div>
        <AddItemToSelectForm onNewSelectItemSubmit={this.props.onNewSelectItemSubmit}/>
      </div>
    )
  }
}

const mapStateToProps = (newItem) => {
  return{
    newItem
  }
}

const mapDispatchToProps = {
  onNewSelectItemSubmit
}

export default connect(mapStateToProps, mapDispatchToProps)(AddItemToSelect)

