/* eslint-disable */

import React, { Component } from 'react';
import { Form, Col, Button, Row, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Upload, message, Button as AntButton } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { InputText } from "primereact/inputtext";
import { Carousel } from 'primereact/carousel';
import { Toast } from 'primereact/toast';
import { DatePicker } from 'antd';
import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'antd/dist/antd.css';
import "./css/Breakdown.scss";
import "./css/CarouselDemo2.scss";
import $ from 'jquery';

const { Option } = Select;

let index = 0;

export class BreakdownMobile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            date: null,
            modalShow: false,
            modalShowAdd: false,
            project: "Project-1",
            company: "Test Company",
            trailer: "",
            trailerId: null,
            driver: "",
            driverId: null,
            itemsAll: [],
            itemsAllIds: [],
            items: [],
            name: '',
            breakdownTypes: [],
            breakdownType: "Select Type of Breakdown",
            breakdownId: null,
            breakdownTypeId: null,
            remarks: "",
            equipmentBreakdownTypes: [],
            itemsForDb: [],
            cars: [{ brand: "Test Brand", year: "1990", color: "blue" },
            { brand: "Test Brand-1", year: "1991", color: "yellow" }],
            breakdownPhoto: "",
            breakdowns: [],
            breakdownTypeDatas: [],
            frequencyGetLocation: null
        };

        this.carTemplate = this.carTemplate.bind(this);

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        this.responsiveSettings = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    testTimer = async () => {
        let latitude = "";
        let longitude = "";
        let flagLocation = "";

        if (navigator.geolocation) {
            const successCallback = async (position) => {
                flagLocation = position.coords.latitude.toString() + "/" + position.coords.longitude.toString();
                latitude = position.coords.latitude.toString();
                longitude = position.coords.longitude.toString();
                this.setState({
                    location: flagLocation
                });
                console.log(position);

                var date = new Date();
                console.log(date);

                if (flagLocation != "") {

                    const newLocationData = {
                        longitude: longitude,
                        latitude: latitude,
                        deviceId: this.props.auth.user.staffId
                    }

                    await handleRequest("POST", "/TrailerLocation/add", newLocationData);
                    this.success();
                } else {
                    this.error();
                }
            };

            const errorCallback = (error) => {
                latitude = "";
                longitude = "";
                flagLocation = "";
                this.setState({
                    location: "Can't detect!"
                });
                console.error(error);
            };

            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        }
    }

    success = () => {
        message.success("Location information was received.");
    }

    error = () => {
        message.error('Location information could not be obtained.');
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    componentDidMount = async () => {

        this.testTimer();

        const response5 = await handleRequest("GET", "/TrailerLocationFrequency/getCompanyItems");

        if (response5.data.length === 0) {
            this.setState({
                frequencyGetLocation: 5
            });
        }

        if (response5.data.length !== 0) {
            for (let index = 0; index < response5.data.length; index++) {
                let flagFreqValue = parseFloat(response5.data[index].minValue);
                console.log(flagFreqValue);
                this.setState({
                    frequencyGetLocation: flagFreqValue
                });
            }
        }

        const urlWithPersonelId = "/Staff/" + this.props.auth.user.staffId;

        const response6 = await handleRequest("GET", urlWithPersonelId);

        var personelFlag;
        let flagWorkingStatus = "";

        if (response6.data.length !== 0) {
            personelFlag = response6.data;
        }

        if (response6.data.length !== 0) {
            if (personelFlag.workingStatus !== undefined && personelFlag.workingStatus !== null)
                flagWorkingStatus = personelFlag.workingStatus;
            else
                flagWorkingStatus = "";
        }

        if (flagWorkingStatus === "Started") {
            this.interval = window.setInterval(this.testTimer, 60000 * this.state.frequencyGetLocation);
        }

        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            date: today
        });

        const response2 = await handleRequest("GET", "/Assignment/getAll");

        console.log(response2.data);

        if (response2.data.length !== 0) {

            const columns = response2.data;

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].staffId === this.props.auth.user.staffId) {
                    if (columns[index].equipmentLog !== null && columns[index].equipmentLog.length !== 0) {

                        let equipmentLogId = columns[index].equipmentLog.id;

                        this.setState({
                            equipmentLogId: columns[index].equipmentLog.id,
                            equipmentType: columns[index].equipmentLog.equipmentType.equipmentTypeName,
                            department: columns[index].equipmentLog.department.departmentName,
                            equipmentCode: columns[index].equipmentLog.equipmentCode,
                            equipmentName: columns[index].equipmentLog.equipmentDescription
                        });

                        if (columns[index].equipmentLog.equipmentVehicleLicenceList !== null && columns[index].equipmentLog.equipmentVehicleLicenceList.length !== 0) {
                            this.setState({
                                driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
                                driverId: this.props.auth.user.staffId,
                                trailer: columns[index].equipmentLog.equipmentCode + ", " + columns[index].equipmentLog.equipmentVehicleLicenceList[0].plateNo,
                                trailerId: columns[index].equipmentLog.id
                            });
                        }
                        else {
                            this.setState({
                                driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
                                driverId: this.props.auth.user.staffId,
                                trailer: columns[index].equipmentLog.equipmentCode,
                                trailerId: columns[index].equipmentLog.id
                            });
                        }

                        const response00 = await handleRequest("GET", "/BreakdownType/getAll");

                        if (response00.data.length === 0) {
                            this.setState({
                                breakdownTypes: []
                            });
                        }

                        if (response00.data.length !== 0) {

                            let equipmentBreakdownTypes = [];
                            const options = [];

                            for (let index = 0; index < response00.data.length; index++) {
                                if (equipmentLogId === response00.data[index].equipmentLogId) {
                                    equipmentBreakdownTypes.push(response00.data[index]);
                                    options.push({ name: response00.data[index].breakdownType1Code.name.toString(), id: response00.data[index].breakdownType1Code.id.toString() });
                                }
                            }

                            //console.log(equipmentBreakdownTypes); //araçla ilgili olan arıza türleri.

                            this.setState({
                                equipmentBreakdownTypes: equipmentBreakdownTypes,
                                breakdownTypes: options
                            });

                        }

                        const response3 = await handleRequest("GET", "/Proposal/getAll");

                        var flagItems = [];
                        var flagIds = [];

                        if (response3.length === 0 || response3.data.length === 0) {
                            this.setState({
                                items: []
                            });
                        } else if (response3.data.length !== 0) {
                            for (let index = 0; index < response3.data.length; index++) {
                                flagItems.push(response3.data[index].proposalName.toString());
                                flagIds.push(response3.data[index].id.toString());
                            }

                            this.setState({
                                itemsAll: flagItems,
                                itemsAllIds: flagIds
                            });
                        }

                        const response4 = await handleRequest("GET", "/Breakdown/getAll");

                        if (response4.data.length === 0) {
                            this.setState({
                                breakdowns: []
                            });
                        }

                        if (response4.data.length !== 0) {
                            this.setState({
                                breakdowns: response4.data
                            });

                            let breakdownFlagTypes = [];

                            for (let index = 0; index < response4.data.length; index++) {
                                if (!(breakdownFlagTypes.includes(response4.data[index].breakdownTypeId))) {
                                    breakdownFlagTypes.push(response4.data[index].breakdownTypeId);
                                }
                            }

                            var breakdownTypeDatas = new Array(breakdownFlagTypes.length);

                            for (let index = 0; index < breakdownFlagTypes.length; index++) {
                                breakdownTypeDatas[index] = [];
                            }

                            for (let index = 0; index < breakdownFlagTypes.length; index++) {
                                for (let index1 = 0; index1 < response4.data.length; index1++) {
                                    if (breakdownFlagTypes[index] === response4.data[index1].breakdownTypeId) {
                                        breakdownTypeDatas[index].push(response4.data[index1]);
                                    }
                                }
                            }

                            breakdownTypeDatas.sort(function (a, b) {
                                return b.length - a.length;
                            });

                            console.log(breakdownTypeDatas);

                            this.setState({
                                breakdownTypeDatas: breakdownTypeDatas
                            });

                        }
                    }
                }
            }
        }

        $(".p-carousel-dots-container").css("display", "none");
        //sınırsız sayıda girdi olacağıdan Carousel alttaki göstergeler kaldırıldı.
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    carTemplate(car) {
        return (
            <div className="car-details">
                <div className="p-grid p-nogutter">
                    <div className="p-col-12 car-data">
                        <div className="car-title">{car[0].breakdownType.breakdownType1Code.name}</div>
                        <div className="car-subtitle">{car.length}</div>
                    </div>
                </div>
            </div>
        );
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
              }, 200);
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
              }, 200);
        }
        return isJpgOrPng && isLt2M;
    }

    transformFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const canvas = document.createElement('canvas');
                const img = document.createElement('img');
                img.src = reader.result;
                img.onload = () => {
                    let width = img.width
                    let height = img.height

                    canvas.width = width
                    canvas.height = height

                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    canvas.toBlob(resolve, 'image/jpeg', 0.5);
                };
            }
        });
    }

    handlePhotoChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    breakdownPhoto: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
              }, 200);
        }
    }

    onChangeDate = (value, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date: dateString
            });
        } else {
            this.setState({
                date: today
            });
        }
    }

    handleChange = (value) => {  //TODO: En son db'ye kaydetme işlemi olmazsa burada kaydet ve id'lerini çek
        this.setState({
            itemsForDb: value,
            editableDropdownValue: value
        });
    }

    disabledDate(current) {
        return current > moment().endOf('day');
    }

    onNameChange = event => {
        this.setState({
            name: event.target.value,
        });
    }

    addItem = () => {
        console.log('addItem');
        const { items, name } = this.state;
        this.setState({
            items: [...items, name || `New item ${index++}`],
            name: '',
        });
    }

    handleChangeBreakdownType = e => {
        e.preventDefault();
        var idValue;
        var idValue2;

        for (var z = 0; z < this.state.breakdownTypes.length; z++) {
            if (this.state.breakdownTypes[z].name === e.target.value) {
                idValue = this.state.breakdownTypes[z].id
            }
        }

        console.log(idValue);

        let proposals;
        let result = [];

        for (let index = 0; index < this.state.equipmentBreakdownTypes.length; index++) {
            if (idValue === this.state.equipmentBreakdownTypes[index].breakdownType1Code.id.toString()) {
                proposals = this.state.equipmentBreakdownTypes[index].proposalNames;
                result = proposals.filter(proposals => proposals != ", ");
                idValue2 = this.state.equipmentBreakdownTypes[index].id;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            breakdownId: idValue,
            breakdownTypeId: idValue2,
            items: result,
            editableDropdownValue: [],
            itemsForDb: []
        });
    }

    saveBreakdown = async () => {

        var flagItems = this.state.itemsAll;
        var flagItems1 = this.state.itemsAll;

        for (let index = 0; index < this.state.itemsForDb.length; index++) {
            if (!(flagItems.includes(this.state.itemsForDb[index]))) {

                var item = {
                    proposalName: this.state.itemsForDb[index],
                }

                await handleRequest("POST", "/Proposal/add", item);

                const response = await handleRequest("GET", "/Proposal/getAll");

                flagItems1 = [];
                var flagIds1 = [];

                if (response.length === 0 || response.data.length === 0) {
                    flagItems1 = [];
                } else if (response.data.length !== 0) {
                    for (let index = 0; index < response.data.length; index++) {
                        flagItems1.push(response.data[index].proposalName.toString());
                        flagIds1.push(response.data[index].id.toString());
                    }
                }

                var itemIdsForDb1 = [];

                for (let index = 0; index < this.state.itemsForDb.length; index++) {
                    for (let index1 = 0; index1 < flagItems1.length; index1++) {
                        if (this.state.itemsForDb[index] === flagItems1[index1]) {
                            itemIdsForDb1.push(flagIds1[index1]);
                        }
                    }
                }

                const urlWithPersonelId = "/BreakdownType/" + this.state.breakdownTypeId;
                const response0 = await handleRequest("GET", urlWithPersonelId);

                const updatedBreakdownType = {
                    id: this.state.breakdownTypeId,
                    equipmentLogId: response0.data.equipmentLogId,
                    equipmentTypeId: response0.data.equipmentTypeId,
                    discipline: response0.data.disciplineTypeCode.name,
                    group: response0.data.groupTypeCode.name,
                    breakdown: response0.data.breakdownType1Code.name,
                    sourceOfDiagnosis: response0.data.sourceOfDiagnosisTypeCode.name,
                    proposals: itemIdsForDb1
                }

                await handleRequest("POST", "/BreakdownType/update", updatedBreakdownType);
            }
        }

        const response = await handleRequest("GET", "/Proposal/getAll");

        flagItems = [];
        var flagIds = [];

        if (response.length === 0 || response.data.length === 0) {
            flagItems = [];
        } else if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {
                flagItems.push(response.data[index].proposalName.toString());
                flagIds.push(response.data[index].id.toString());
            }
        }

        var itemIdsForDb = [];

        for (let index = 0; index < this.state.itemsForDb.length; index++) {
            for (let index1 = 0; index1 < flagItems.length; index1++) {
                if (this.state.itemsForDb[index] === flagItems[index1]) {
                    itemIdsForDb.push(flagIds[index1]);
                }
            }
        }

        //console.log(itemIdsForDb);   //bunu "proposals" olarak vereceğiz.

        const newBreakdown = {
            equipmentLogId: this.state.equipmentLogId,
            breakdownTime: this.state.date,
            breakdownTypeId: this.state.breakdownTypeId,
            breakdownPhotoPath: this.state.breakdownPhoto,
            remarks: this.state.remarks,
            proposals: itemIdsForDb
        }

        console.log(newBreakdown);

        await handleRequest("POST", "/Breakdown/add", newBreakdown);

        this.showSuccess();
        this.refreshStates();
    }

    refreshStates = () => {

        var today = new Date();
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            date: today,
            itemsForDb: [],
            editableDropdownValue: [],
            breakdownType: "Select Type of Breakdown",
            breakdownId: null,
            breakdownTypeId: null,
            remarks: "",
            breakdownPhoto: ""
        });

        $(".ant-upload-list").empty();
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong. Please check inputs.' });
    }

    render() {

        const { items, name } = this.state;

        let optionsBreakdownTypes = this.state.breakdownTypes.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">There are missing or wrong fuel refill information values.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ color: 'black' }}>Can you check all the values you entered?</p>
                        <p style={{ color: 'black' }}>Also, do not forget that you cannot enter more fuel than fuel tank capacity.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHideModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Row style={{ marginTop: "10px", width: "100%" }}>
                    <Col xs={4}>
                    </Col>
                    <Col xs={4} style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.equipmentType}</Form.Label>
                    </Col>
                    <Col xs={4}>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px", width: "100%" }}>
                    <Col xs={4}>
                    </Col>
                    <Col xs={4} style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.department}</Form.Label>
                    </Col>
                    <Col xs={4}>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px", width: "100%" }}>
                    <Col xs={4}>
                    </Col>
                    <Col xs={4} style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.equipmentCode}</Form.Label>
                    </Col>
                    <Col xs={4}>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
                    <Col xs={4}>
                    </Col>
                    <Col xs={4} style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.equipmentName}</Form.Label>
                    </Col>
                    <Col xs={4}>
                    </Col>
                </Row>

                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                        Date:
                            </Form.Label>
                    <Col sm="3">
                        <DatePicker disabledDate={this.disabledDate} value={moment(this.state.date, 'DD/MM/YYYY HH:mm')}
                            disabled={this.state.datePickerDisable} showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDate} />
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                        Type of Breakdown:
                            </Form.Label>
                    <Col sm="3">
                        <Form.Control name="breakdownType" value={this.state.breakdownType}
                            onChange={this.handleChangeBreakdownType} as="select">
                            <option value="">
                                Select Type of Breakdown
                        </option>
                            {optionsBreakdownTypes}
                        </Form.Control>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                        Breakdown:
                            </Form.Label>
                    <Col sm="3">
                        <div className="carousel-demo">
                            <Carousel value={this.state.breakdownTypeDatas} itemTemplate={this.carTemplate} numVisible={1} numScroll={1} className="custom-carousel"
                                responsive={this.responsiveSettings} circular={true}></Carousel>
                        </div>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="3">
                    </Col>
                    <Col sm="3">
                        <Upload
                            name="avatar"
                            className="avatar-uploader"
                            action={`${API_BASE}/Breakdown/upload`}
                            transformFile={this.transformFile}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handlePhotoChange}
                        >
                            <AntButton>
                                <UploadOutlined /> Attach Photo
                            </AntButton>
                        </Upload>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                        Remarks:
                            </Form.Label>
                    <Col sm="3">
                        <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ remarks: e.target.value })} value={this.state.remarks} />
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                        Proposal:
                            </Form.Label>
                    <Col sm="3">
                        <Select
                            mode="tags"
                            style={{ width: 255 }}
                            placeholder="Add Proposal"
                            value={this.state.editableDropdownValue}
                            onChange={this.handleChange}
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
                                        <a
                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                            onClick={this.addItem}
                                        >
                                            <PlusOutlined /> Add Proposal
                            </a>
                                    </div>
                                </div>
                            )}
                        >
                            {items.map(item => (
                                <Option key={item}>{item}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: "2rem" }} as={Row} >
                    <Col sm="4">
                    </Col>
                    <Col sm="4">
                        <Button id='save-fuel' style={{ width: '100%' }} onClick={this.saveBreakdown} variant="info">Save</Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                </Form.Group>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakdownMobile)