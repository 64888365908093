/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap'; 
import { ToggleButton } from 'primereact/togglebutton';
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { API_BASE } from '../../redux/config/env';
import { handleRequest } from '../../redux/config/env'; 
import { FormattedMessage } from "react-intl";
import { Collapse, Button as AntdButton, Upload, Checkbox as AntCheckbox, Select, Divider, Input } from 'antd';
import { UploadOutlined, DownloadOutlined, PlusOutlined  } from '@ant-design/icons';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

const { Panel } = Collapse;
const { Option } = Select;

class Wps extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayBasic: false,
      modalShow: false,
      createNewWeldLayersButton: "Create New",
      createNewPassNumbersButton: "Create New",
      lastButton: "Save",
      createNewWeldLayersButtonVariant: "info",
      createNewPassNumbersButtonVariant: "info",
      nodes: {

      },
      FileUpload: [],
      fileList: [],
      jointDetail:"",
      WPSPqrNos:[],
      WPSWeldingProcesss:[],
      WPSTypes:[],
      WPSJointTypes:[],
      WPSBackingMaterialTypes:[],
      WPSRootOpeningRadiuss:[],
      WPSBackgougingMethods:[],
      WPSNumberOfElectrodess:[],
      WPSMaterialSpecifications:[],
      WPSTypeOrGrades:[],
      WPSTOSpecificationTypeAndGrades:[],
      WPSOfBaseMetalGrooves:[],
      WPSOfBaseMetalFillets:[],
      WPSDepositedMetalGrooves:[],
      WPSDepositedMetalFillets:[],
      WPSPipeDiaRangeGrooves:[],
      WPSPipeDiaRangeFillets:[],
      WPSBaseMetalOtherGrooves:[],
      WPSBaseMetalOtherFillets:[],
      WPSTypeOfCoatings:[],
      WPSAWSClassifications:[],
      WPSAWSSpecifications:[],
      WPSElectrodeFluxClasss:[],
      WPSPositionsOfGrooves:[],
      WPSPositionsOfFillets:[],
      WPSWeldProgressions:[],
      WPSGasEsShieldings:[],
      WPSGasEsBackings:[],
      WPSGasEsTrailings:[],
      WPSCompositionShieldings:[],
      WPSCompositionBackings:[],
      WPSCompositionTrailings:[],
      WPSFlowRateShieldings:[],
      WPSFlowRateBackings:[],
      WPSFlowRateTrailings:[],
      WPSGasCupSizeShieldings:[],
      WPSGasCupSizeBackings:[],
      WPSGasCupSizeTrailings:[],
      WPSCurrentTypes:[],
      WPSCurrentPolaritys:[],
      WPSTungstenElectrodeTypes:[],
      WPSOtherVariablesNumberOfElectrodess:[],

      documentLogid:-1,
      id:-1,
      hiddenSave: false,
      hiddenUpdate: true,
      popup:false,
      WPSJointDesign:[],
      stringers:false,
      weaveBead:false,
      multiPass:false,
      singlePass:false,
      numberOfElectrodes:0,
      peening:"",
      interPassCleaning:"",
      other:"", 
      hiddenAmendDocument:false,
      hiddenDataGiris:false,

      baseMetalNo:"",

    };
    
    this.actionTemplate = this.actionTemplate.bind(this);
    this.onClickModal = this.onClickModal.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.onBasicUpload = this.onBasicUpload.bind(this);
  }

componentDidMount = async () => { 
  var deger=-1;
  if(this.props.location!=null && this.props.location!=undefined && this.props.location!=""){
    deger=this.props.location.pathname.split('/')[2]; 
    this.setState({
      popup:false
    });
  }else if(this.props.id!=null && this.props.id!=0 && this.props.id!=""){
    deger=this.props.id; 
    this.setState({
      popup:true
    });
  }else{
    deger=-1;
    this.setState({
      popup:true
    });
  } 

  if(deger!=null && deger!=undefined && deger!="" && deger!=0 ){
      await this.setState({
          documentLogid:deger
        });
  }else{
      await this.setState({
          documentLogid:-1
      });
  }
  this.deleteWPSJointDesignTemp();
  this.deleteWPSTechniqueTemp();
  this.deleteWPSBaseMetalsTemp();
  this.deleteWPSFillerMetalsTemp();
  this.deleteWPSPositionsTemp();
  this.deleteWPSPreheatTemperatureTemp();
  this.deleteWPSPostweldHeatTreatmentTemp();
  this.deleteWPSShieldingTemp();
  this.deleteWPSElectricalCharacteristicsTemp();
  this.deleteWPSOtherVariablesTemp();


  this.getWPSJointDesign();
  this.getWPSTechnique();
  this.getWPSBaseMetals();
  this.getWPSFillerMetals();
  this.getWPSPositions();
  this.getWPSPreheatTemperature();
  this.getWPSPostweldHeatTreatment();
  this.getWPSShielding();
  this.getWPSElectricalCharacteristics();
  this.getWPSOtherVariabless();


  await this.getDocumentLog(deger)

  this.getCodesWPSPqrNo();
  this.getCodesWPSWeldingProcess();
  this.getCodesWPSType();
  this.getCodesWPSJointType();
  this.getCodesWPSBackingMaterialType();
  this.getCodesWPSRootOpeningRadius();
  this.getCodesWPSBackgougingMethod();
  this.getCodesWPSNumberOfElectrodes();
  this.getCodesWPSMaterialSpecifications();
  this.getCodesWPSTypeOrGrades();
  this.getCodesWPSTOSpecificationTypeAndGrades();
  this.getCodesWPSOfBaseMetalGrooves();
  this.getCodesWPSOfBaseMetalFillets();
  this.getCodesWPSDepositedMetalGrooves();
  this.getCodesWPSDepositedMetalFillets();
  this.getCodesWPSPipeDiaRangeGrooves();
  this.getCodesWPSPipeDiaRangeFillets();
  this.getCodesWPSBaseMetalOtherGrooves();
  this.getCodesWPSBaseMetalOtherFillets();
  this.getCodesWPSTypeOfCoatings();
  this.getCodesWPSAWSClassifications();
  this.getCodesWPSAWSSpecifications();
  this.getCodesWPSElectrodeFluxClasss();
  this.getCodesWPSPositionsOfGrooves();
  this.getCodesWPSPositionsOfFillets();
  this.getCodesWPSWeldProgressions();
  this.getCodesWPSGasEsShieldings();
  this.getCodesWPSGasEsBackings();
  this.getCodesWPSGasEsTrailings();
  this.getCodesWPSCompositionShieldings();
  this.getCodesWPSCompositionBackings();
  this.getCodesWPSCompositionTrailings();
  this.getCodesWPSFlowRateShieldings();
  this.getCodesWPSFlowRateBackings();
  this.getCodesWPSFlowRateTrailings();
  this.getCodesWPSGasCupSizeShieldings();
  this.getCodesWPSGasCupSizeBackings();
  this.getCodesWPSGasCupSizeTrailings();
  this.getCodesWPSCurrentTypes();
  this.getCodesWPSCurrentPolaritys();
  this.getCodesWPSTungstenElectrodeTypes();
  this.getCodesWPSOtherVariablesNumberOfElectrodess();

}

getDocumentLog = async (deger) => {  
  const newObj = {
    id: deger
  } 
  const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj); 
  if (response.data.length !== 0) {   
    var revno=0;
    if(this.state.hiddenAmendDocument==true){
      if(this.state.popup==false){
        revno=response.data[0].revno;
      }else{
        revno=response.data[0].revno + 1;
      }
    }else{
      revno=response.data[0].revno;
    }

    this.setState({
      revno:revno,
    }); 
  }  
  else { 
    this.setState({
      revno:0,
    })
  }
}

redirectDocument() {
  if(this.state.popup==false){
    window.setTimeout(function(){
      window.location.href = "/DocumentLog";
  }, 2000);
  }
};

getWPSJointDesign = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSJointDesignResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSJointDesign=response.data[0];

    await this.setState({ 
      WPSJointDesign:WPSJointDesign,
      hiddenSave:true,
      hiddenUpdate:false,
      hiddenAmendDocument:true,
      id: WPSJointDesign.id,
      wpsno:WPSJointDesign.wpsno,
      date:new Date(WPSJointDesign.date),
      pqrNo:WPSJointDesign.pqrNo,
      pqrNoAck:(WPSJointDesign.codePqrNo!=null && WPSJointDesign.codePqrNo!=undefined  ) ? WPSJointDesign.codePqrNo.name : "" ,
      weldingProcess:WPSJointDesign.weldingProcess,
      weldingProcessAck:(WPSJointDesign.codeWeldingProcess!=null && WPSJointDesign.codeWeldingProcess!=undefined  ) ? WPSJointDesign.codeWeldingProcess.name : "" ,
      type:WPSJointDesign.type,
      typeAck:(WPSJointDesign.codeType!=null && WPSJointDesign.codeType!=undefined  ) ? WPSJointDesign.codeType.name : "" ,
      jointType:WPSJointDesign.jointType,
      jointTypeAck:(WPSJointDesign.codeJointType!=null && WPSJointDesign.codeJointType!=undefined  ) ? WPSJointDesign.codeJointType.name : "" ,
      single:WPSJointDesign.single,
      doubleWeld:WPSJointDesign.doubleWeld,
      position:WPSJointDesign.position,
      backing:WPSJointDesign.backing,
      backingMaterialType:WPSJointDesign.backingMaterialType,
      backingMaterialTypeAck:(WPSJointDesign.codeBackingMaterialType!=null && WPSJointDesign.codeBackingMaterialType!=undefined  ) ? WPSJointDesign.codeBackingMaterialType.name : "" ,
      grooveAngle:WPSJointDesign.grooveAngle,
      rootOpening:WPSJointDesign.rootOpening,
      rootFaceDimension:WPSJointDesign.rootFaceDimension,
      rootOpeningRadius:WPSJointDesign.rootOpeningRadius,
      rootOpeningRadiusAck:(WPSJointDesign.codeRootOpeningRadius!=null && WPSJointDesign.codeRootOpeningRadius!=undefined  ) ? WPSJointDesign.codeRootOpeningRadius.name : "" ,
      rootFace:WPSJointDesign.rootFace,
      backgouging:WPSJointDesign.backgouging,
      backgougingMethod:WPSJointDesign.backgougingMethod,
      backgougingMethodAck:(WPSJointDesign.codeBackgougingMethod!=null && WPSJointDesign.codeBackgougingMethod!=undefined  ) ? WPSJointDesign.codeBackgougingMethod.name : "" ,
      jointDetail:WPSJointDesign.jointDetail,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSJointDesign:[],
      id: -1,
      hiddenSave:false,
      hiddenUpdate:true,
      hiddenAmendDocument:false,
    });
  }
}

getWPSTechnique = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSTechniqueResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSTechnique=response.data[0];

    await this.setState({ 
      WPSTechnique:WPSTechnique, 
      stringers:WPSTechnique.stringers,
      weaveBead:WPSTechnique.weaveBead,
      multiPass:WPSTechnique.multiPass,
      singlePass:WPSTechnique.singlePass,
      numberOfElectrodes:WPSTechnique.numberOfElectrodes,
      numberOfElectrodesAck:(WPSTechnique.codeNumberOfElectrodes!=null && WPSTechnique.codeNumberOfElectrodes!=undefined  ) ? WPSTechnique.codeNumberOfElectrodes.name : "" ,
      peening:WPSTechnique.peening,
      interPassCleaning:WPSTechnique.interPassCleaning,
      other:WPSTechnique.other,  
    }); 
  }  
  else { 
    await this.setState({ 
      WPSTechnique:[],  
    });
  }
}

getWPSBaseMetals = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSBaseMetalsResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSBaseMetals=response.data[0];

    await this.setState({ 
      WPSBaseMetals:WPSBaseMetals,
      baseMetalNo:WPSBaseMetals.baseMetalNo, 
      materialSpecifications:WPSBaseMetals.materialSpecifications,
      materialSpecificationsAck:(WPSBaseMetals.codeMaterialSpecifications!=null && WPSBaseMetals.codeMaterialSpecifications!=undefined  ) ? WPSBaseMetals.codeMaterialSpecifications.name : "" ,
      typeOrGrade:WPSBaseMetals.typeOrGrade,
      typeOrGradeAck:(WPSBaseMetals.codeTypeOrGrade!=null && WPSBaseMetals.codeTypeOrGrade!=undefined  ) ? WPSBaseMetals.codeTypeOrGrade.name : "" ,
      groupNo:WPSBaseMetals.groupNo,
      tOMNo:WPSBaseMetals.tOMNo,
      tOGroupNo:WPSBaseMetals.tOGroupNo,
      tOSpecificationTypeAndGrade:WPSBaseMetals.tOSpecificationTypeAndGrade,
      tOSpecificationTypeAndGradeAck:(WPSBaseMetals.codeTOSpecificationTypeAndGrade!=null && WPSBaseMetals.codeTOSpecificationTypeAndGrade!=undefined  ) ? WPSBaseMetals.codeTOSpecificationTypeAndGrade.name : "" ,
      thicknessRangeFrom:WPSBaseMetals.thicknessRangeFrom,
      thicknessRangeTo:WPSBaseMetals.thicknessRangeTo,
      ofBaseMetalGroove:WPSBaseMetals.ofBaseMetalGroove,
      ofBaseMetalGrooveAck:(WPSBaseMetals.codeOfBaseMetalGroove!=null && WPSBaseMetals.codeOfBaseMetalGroove!=undefined  ) ? WPSBaseMetals.codeOfBaseMetalGroove.name : "" ,
      ofBaseMetalFillet:WPSBaseMetals.ofBaseMetalFillet,
      ofBaseMetalFilletAck:(WPSBaseMetals.codeOfBaseMetalFillet!=null && WPSBaseMetals.codeOfBaseMetalFillet!=undefined  ) ? WPSBaseMetals.codeOfBaseMetalFillet.name : "" ,
      depositedMetalGroove:WPSBaseMetals.depositedMetalGroove,
      depositedMetalGrooveAck:(WPSBaseMetals.codeDepositedMetalGroove!=null && WPSBaseMetals.codeDepositedMetalGroove!=undefined  ) ? WPSBaseMetals.codeDepositedMetalGroove.name : "" ,
      depositedMetalFillet:WPSBaseMetals.depositedMetalFillet,
      depositedMetalFilletAck:(WPSBaseMetals.codeDepositedMetalFillet!=null && WPSBaseMetals.codeDepositedMetalFillet!=undefined  ) ? WPSBaseMetals.codeDepositedMetalFillet.name : "" ,
      pipeDiaRangeGroove:WPSBaseMetals.pipeDiaRangeGroove,
      pipeDiaRangeGrooveAck:(WPSBaseMetals.codePipeDiaRangeGroove!=null && WPSBaseMetals.codePipeDiaRangeGroove!=undefined  ) ? WPSBaseMetals.codePipeDiaRangeGroove.name : "" ,
      pipeDiaRangeFillet:WPSBaseMetals.pipeDiaRangeFillet,
      pipeDiaRangeFilletAck:(WPSBaseMetals.codePipeDiaRangeFillet!=null && WPSBaseMetals.codePipeDiaRangeFillet!=undefined  ) ? WPSBaseMetals.codePipeDiaRangeFillet.name : "" ,
      baseMetalOtherGroove:WPSBaseMetals.baseMetalOtherGroove,
      baseMetalOtherGrooveAck:(WPSBaseMetals.codeBaseMetalOtherGroove!=null && WPSBaseMetals.codeBaseMetalOtherGroove!=undefined  ) ? WPSBaseMetals.codeBaseMetalOtherGroove.name : "" ,
      baseMetalOtherFillet:WPSBaseMetals.baseMetalOtherFillet,
      baseMetalOtherFilletAck:(WPSBaseMetals.codeBaseMetalOtherFillet!=null && WPSBaseMetals.codeBaseMetalOtherFillet!=undefined  ) ? WPSBaseMetals.codeBaseMetalOtherFillet.name : "" ,
      maximumCarbonEquivalent:WPSBaseMetals.maximumCarbonEquivalent,
      barSize:WPSBaseMetals.barSize,
      plateThickness:WPSBaseMetals.plateThickness,
      coatedBar:WPSBaseMetals.coatedBar,
      typeOfCoating:WPSBaseMetals.typeOfCoating,
      typeOfCoatingAck:(WPSBaseMetals.codeTypeOfCoating!=null && WPSBaseMetals.codeTypeOfCoating!=undefined  ) ? WPSBaseMetals.codeTypeOfCoating.name : "" ,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSBaseMetals:[],
    });
  }
}

getWPSFillerMetals = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSFillerMetalsResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSFillerMetals=response.data[0];

    await this.setState({ 
      WPSFillerMetals:WPSFillerMetals, 
      fillerMetalFNo:WPSFillerMetals.fillerMetalFNo, 
      awsClassification:WPSFillerMetals.awsClassification,
      awsClassificationAck:(WPSFillerMetals.codeAWSClassification!=null && WPSFillerMetals.codeAWSClassification!=undefined  ) ? WPSFillerMetals.codeAWSClassification.name : "" ,
      awsSpecification:WPSFillerMetals.awsSpecification,
      awsSpecificationAck:(WPSFillerMetals.codeAWSSpecification!=null && WPSFillerMetals.codeAWSSpecification!=undefined  ) ? WPSFillerMetals.codeAWSSpecification.name : "" ,
      weldMetalAnalysisANo:WPSFillerMetals.weldMetalAnalysisANo, 
      fillerMetalSize:WPSFillerMetals.fillerMetalSize, 
      electrodeFluxClass:WPSFillerMetals.electrodeFluxClass,
      electrodeFluxClassAck:(WPSFillerMetals.codeElectrodeFluxClass!=null && WPSFillerMetals.codeElectrodeFluxClass!=undefined  ) ? WPSFillerMetals.codeElectrodeFluxClass.name : "" ,
      fluxTradeName:WPSFillerMetals.fluxTradeName, 
      consumableInsert:WPSFillerMetals.consumableInsert, 
      fillerMetalsOther:WPSFillerMetals.fillerMetalsOther, 
    }); 
  }  
  else { 
    await this.setState({ 
      WPSFillerMetals:[],  
    });
  }
}

getWPSPositions = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSPositionsResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSPositions=response.data[0];

    await this.setState({ 
      WPSPositions:WPSPositions,  
      positionsOfGroove:WPSPositions.positionsOfGroove,
      positionsOfGrooveAck:(WPSPositions.codePositionsOfGroove!=null && WPSPositions.codePositionsOfGroove!=undefined  ) ? WPSPositions.codePositionsOfGroove.name : "" ,
      positionsOfFillet:WPSPositions.positionsOfFillet,
      positionsOfFilletAck:(WPSPositions.codePositionsOfFillet!=null && WPSPositions.codePositionsOfFillet!=undefined  ) ? WPSPositions.codePositionsOfFillet.name : "" ,
      weldProgression:WPSPositions.weldProgression,
      weldProgressionAck:(WPSPositions.codeWeldProgression!=null && WPSPositions.codeWeldProgression!=undefined  ) ? WPSPositions.codeWeldProgression.name : "" ,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSPositions:[],  
    });
  }
}

getWPSPreheatTemperature = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSPreheatInterpassResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSPreheatTemperature=response.data[0];

    await this.setState({ 
      WPSPreheatTemperature:WPSPreheatTemperature,  
      preheatTemperature:WPSPreheatTemperature.preheatTemperature,
      interpassTemperatureMin:WPSPreheatTemperature.interpassTemperatureMin,
      interpassTemperatureMax:WPSPreheatTemperature.interpassTemperatureMax,
      preheatMaintenance:WPSPreheatTemperature.preheatMaintenance,
      heatingOrMaintenance:WPSPreheatTemperature.heatingOrMaintenance,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSPreheatTemperature:[],  
    });
  }
}

getWPSPostweldHeatTreatment = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSPostweldHeatTreatmentResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSPostweldHeatTreatment=response.data[0];

    await this.setState({ 
      WPSPostweldHeatTreatment:WPSPostweldHeatTreatment,  
      temperature:WPSPostweldHeatTreatment.temperature,
      time:WPSPostweldHeatTreatment.time,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSPostweldHeatTreatment:[],  
    });
  }
}

getWPSShielding = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSShieldingResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSShielding=response.data[0];

    await this.setState({ 
      WPSShielding:WPSShielding, 

      gasEsShielding:WPSShielding.gasEsShielding,
      gasEsShieldingAck:(WPSShielding.codeGasEsShielding!=null && WPSShielding.codeGasEsShielding!=undefined  ) ? WPSShielding.codeGasEsShielding.name : "" ,
      gasEsBacking:WPSShielding.gasEsBacking,
      gasEsBackingAck:(WPSShielding.codeGasEsBacking!=null && WPSShielding.codeGasEsBacking!=undefined  ) ? WPSShielding.codeGasEsBacking.name : "" ,
      gasEsTrailing:WPSShielding.gasEsTrailing,
      gasEsTrailingAck:(WPSShielding.codeGasEsTrailing!=null && WPSShielding.codeGasEsTrailing!=undefined  ) ? WPSShielding.codeGasEsTrailing.name : "" ,
     
      compositionShielding:WPSShielding.compositionShielding,
      compositionShieldingAck:(WPSShielding.codeCompositionShielding!=null && WPSShielding.codeCompositionShielding!=undefined  ) ? WPSShielding.codeCompositionShielding.name : "" ,
      compositionBacking:WPSShielding.compositionBacking,
      compositionBackingAck:(WPSShielding.codeCompositionBacking!=null && WPSShielding.codeCompositionBacking!=undefined  ) ? WPSShielding.codeCompositionBacking.name : "" ,
      compositionTrailing:WPSShielding.compositionTrailing,
      compositionTrailingAck:(WPSShielding.codeCompositionTrailing!=null && WPSShielding.codeCompositionTrailing!=undefined  ) ? WPSShielding.codeCompositionTrailing.name : "" ,
      
      flowRateShielding:WPSShielding.flowRateShielding,
      flowRateShieldingAck:(WPSShielding.codeFlowRateShielding!=null && WPSShielding.codeFlowRateShielding!=undefined  ) ? WPSShielding.codeFlowRateShielding.name : "" ,
      flowRateBacking:WPSShielding.flowRateBacking,
      flowRateBackingAck:(WPSShielding.codeFlowRateBacking!=null && WPSShielding.codeFlowRateBacking!=undefined  ) ? WPSShielding.codeFlowRateBacking.name : "" ,
      flowRateTrailing:WPSShielding.flowRateTrailing,
      flowRateTrailingAck:(WPSShielding.codeFlowRateTrailing!=null && WPSShielding.codeFlowRateTrailing!=undefined  ) ? WPSShielding.codeFlowRateTrailing.name : "" ,
      
      gasCupSizeShielding:WPSShielding.gasCupSizeShielding,
      gasCupSizeShieldingAck:(WPSShielding.codeGasCupSizeShielding!=null && WPSShielding.codeGasCupSizeShielding!=undefined  ) ? WPSShielding.codeGasCupSizeShielding.name : "" ,
      gasCupSizeBacking:WPSShielding.gasCupSizeBacking,
      gasCupSizeBackingAck:(WPSShielding.codeGasCupSizeBacking!=null && WPSShielding.codeGasCupSizeBacking!=undefined  ) ? WPSShielding.codeGasCupSizeBacking.name : "" ,
      gasCupSizeTrailing:WPSShielding.gasCupSizeTrailing,
      gasCupSizeTrailingAck:(WPSShielding.codeGasCupSizeTrailing!=null && WPSShielding.codeGasCupSizeTrailing!=undefined  ) ? WPSShielding.codeGasCupSizeTrailing.name : "" ,
      
      flux:WPSShielding.flux,
      electroFluxClass:WPSShielding.electroFluxClass,
      fluxTypeTradeName:WPSShielding.fluxTypeTradeName,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSShielding:[],  
    });
  }
}

getWPSElectricalCharacteristics = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSElectricalCharacteristicsResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSElectricalCharacteristics=response.data[0];

    await this.setState({ 
      WPSElectricalCharacteristics:WPSElectricalCharacteristics,  
      generalCharacteristics:WPSElectricalCharacteristics.generalCharacteristics,
      currentType:WPSElectricalCharacteristics.currentType,
      currentTypeAck:(WPSElectricalCharacteristics.codeCurrentType!=null && WPSElectricalCharacteristics.codeCurrentType!=undefined  ) ? WPSElectricalCharacteristics.codeCurrentType.name : "" ,
      pulsing:WPSElectricalCharacteristics.pulsing,
      currentPolarity:WPSElectricalCharacteristics.currentPolarity,
      currentPolarityAck:(WPSElectricalCharacteristics.codeCurrentPolarity!=null && WPSElectricalCharacteristics.codeCurrentPolarity!=undefined  ) ? WPSElectricalCharacteristics.codeCurrentPolarity.name : "" ,
      currentRange:WPSElectricalCharacteristics.currentRange,
      voltageRange:WPSElectricalCharacteristics.voltageRange,
      wireFeedSpeedRange:WPSElectricalCharacteristics.wireFeedSpeedRange,
      tungstenElectrodeSize:WPSElectricalCharacteristics.tungstenElectrodeSize,
      tungstenElectrodeType:WPSElectricalCharacteristics.tungstenElectrodeType,
      tungstenElectrodeTypeAck:(WPSElectricalCharacteristics.codeTungstenElectrodeType!=null && WPSElectricalCharacteristics.codeTungstenElectrodeType!=undefined  ) ? WPSElectricalCharacteristics.codeTungstenElectrodeType.name : "" ,
      pulsingParameters:WPSElectricalCharacteristics.pulsingParameters,
      electricalcharOther:WPSElectricalCharacteristics.electricalcharOther,
      shortCirquiting:WPSElectricalCharacteristics.shortCirquiting,
      globular:WPSElectricalCharacteristics.globular,
      spray:WPSElectricalCharacteristics.spray,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSElectricalCharacteristics:[],  
    });
  }
}

getWPSOtherVariabless = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/WPSJointDesign/getWPSOtherVariablesResult", newObj); 
  console.log("LLL",response.data);
  if (response.data.length !== 0) {  
    var WPSOtherVariabless=response.data[0];

    await this.setState({ 
      WPSOtherVariabless:WPSOtherVariabless,  
      cupOrNozzleSize:WPSOtherVariabless.cupOrNozzleSize, 
      colletBody:WPSOtherVariabless.colletBody, 
      gasLens:WPSOtherVariabless.gasLens, 
      cleaningMethod:WPSOtherVariabless.cleaningMethod, 
      othervariablesOther:WPSOtherVariabless.othervariablesOther, 
      otherVariablesNumberOfElectrodes:WPSOtherVariabless.otherVariablesNumberOfElectrodes,
      otherVariablesNumberOfElectrodesAck:(WPSOtherVariabless.codeOtherVariablesNumberOfElectrodes!=null && WPSOtherVariabless.codeOtherVariablesNumberOfElectrodes!=undefined  ) ? WPSOtherVariabless.codeOtherVariablesNumberOfElectrodes.name : "" ,
    }); 
  }  
  else { 
    await this.setState({ 
      WPSOtherVariables:[],  
    });
  }
}

deleteWPSJointDesignTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteTemp", deleteTemp);
};

deleteWPSTechniqueTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteTechniqueTemp", deleteTemp);
};

deleteWPSBaseMetalsTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteBaseMetalsTemp", deleteTemp);
};

deleteWPSFillerMetalsTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteFillerMetalsTemp", deleteTemp);
};

deleteWPSPositionsTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deletePositionsTemp", deleteTemp);
};

deleteWPSPreheatTemperatureTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deletePreheatInterpassTemp", deleteTemp);
};

deleteWPSPostweldHeatTreatmentTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deletePostweldHeatTreatmentTemp", deleteTemp);
};

deleteWPSShieldingTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteShieldingTemp", deleteTemp);
};

deleteWPSElectricalCharacteristicsTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteElectricalCharacteristicsTemp", deleteTemp);
};

deleteWPSOtherVariablesTemp = async () => {
  const deleteTemp = {
      id: 0,
  };
  await handleRequest("POST", "/WPSJointDesign/deleteOtherVariablesTemp", deleteTemp);
};

handleChangeWPSPqrNo = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.WPSPqrNos.length; z++) {
      if (this.state.WPSPqrNos[z].id == e) {
        deger = this.state.WPSPqrNos[z].name;
      }
    }
    this.setState({
      pqrNoAck: deger,
      pqrNo: e,
    });
};

addWPSPqrNos = async () => {
    const { itemWPSPqrNos } = this.state;
    if (itemWPSPqrNos != null && itemWPSPqrNos != "" && itemWPSPqrNos != undefined) {
        const newItem = {
        name: itemWPSPqrNos,
        type: "WPSPqrNo"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemWPSPqrNos: '',
        });
        this.getCodesWPSPqrNo();
    } else {
        this.showWarning();
    }
};

getCodesWPSPqrNo = async () => {
    const response = await handleRequest("GET", "/Code/type/WPSPqrNo");   

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        WPSPqrNos: response.data
      });
    }
} 

handleChangeWPSWeldingProcess = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSWeldingProcesss.length; z++) {
    if (this.state.WPSWeldingProcesss[z].id == e) {
      deger = this.state.WPSWeldingProcesss[z].name;
    }
  }
  this.setState({
    weldingProcessAck: deger,
    weldingProcess: e,
  });
};

addWPSWeldingProcesss = async () => {
  const { itemWPSWeldingProcesss } = this.state;
  if (itemWPSWeldingProcesss != null && itemWPSWeldingProcesss != "" && itemWPSWeldingProcesss != undefined) {
      const newItem = {
      name: itemWPSWeldingProcesss,
      type: "WPSWeldingProcess"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSWeldingProcesss: '',
      });
      this.getCodesWPSWeldingProcess();
  } else {
      this.showWarning();
  }
};

getCodesWPSWeldingProcess = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSWeldingProcess");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSWeldingProcesss: response.data
    });
  }
} 

handleChangeWPSType = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.WPSTypes.length; z++) {
      if (this.state.WPSTypes[z].id == e) {
        deger = this.state.WPSTypes[z].name;
      }
    }
    this.setState({
      typeAck: deger,
      type: e,
    });
};

addWPSTypes = async () => {
    const { itemWPSTypes } = this.state;
    if (itemWPSTypes != null && itemWPSTypes != "" && itemWPSTypes != undefined) {
        const newItem = {
        name: itemWPSTypes,
        type: "WPSType"
        }

        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemWPSTypes: '',
        });
        this.getCodesWPSType();
    } else {
        this.showWarning();
    }
};

getCodesWPSType = async () => {
    const response = await handleRequest("GET", "/Code/type/WPSType");   

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        WPSTypes: response.data
      });
    }
} 

handleChangeWPSJointType = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSJointTypes.length; z++) {
    if (this.state.WPSJointTypes[z].id == e) {
      deger = this.state.WPSJointTypes[z].name;
    }
  }
  this.setState({
    jointTypeAck: deger,
    jointType: e,
  });
};

addWPSJointTypes = async () => {
  const { itemWPSJointTypes } = this.state;
  if (itemWPSJointTypes != null && itemWPSJointTypes != "" && itemWPSJointTypes != undefined) {
      const newItem = {
      name: itemWPSJointTypes,
      type: "WPSJointType"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSJointTypes: '',
      });
      this.getCodesWPSJointType();
  } else {
      this.showWarning();
  }
};

getCodesWPSJointType = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSJointType");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSJointTypes: response.data
    });
  }
} 

handleChangeWPSBackingMaterialType = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSBackingMaterialTypes.length; z++) {
    if (this.state.WPSBackingMaterialTypes[z].id == e) {
      deger = this.state.WPSBackingMaterialTypes[z].name;
    }
  }
  this.setState({
    backingMaterialTypeAck: deger,
    backingMaterialType: e,
  });
};

addWPSBackingMaterialTypes = async () => {
  const { itemWPSBackingMaterialTypes } = this.state;
  if (itemWPSBackingMaterialTypes != null && itemWPSBackingMaterialTypes != "" && itemWPSBackingMaterialTypes != undefined) {
      const newItem = {
      name: itemWPSBackingMaterialTypes,
      type: "WPSBackingMaterialType"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSBackingMaterialTypes: '',
      });
      this.getCodesWPSBackingMaterialType();
  } else {
      this.showWarning();
  }
};

getCodesWPSBackingMaterialType = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSBackingMaterialType");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSBackingMaterialTypes: response.data
    });
  }
} 

handleChangeWPSRootOpeningRadius = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSRootOpeningRadiuss.length; z++) {
    if (this.state.WPSRootOpeningRadiuss[z].id == e) {
      deger = this.state.WPSRootOpeningRadiuss[z].name;
    }
  }
  this.setState({
    rootOpeningRadiusAck: deger,
    rootOpeningRadius: e,
  });
};

addWPSRootOpeningRadiuss = async () => {
  const { itemWPSRootOpeningRadiuss } = this.state;
  if (itemWPSRootOpeningRadiuss != null && itemWPSRootOpeningRadiuss != "" && itemWPSRootOpeningRadiuss != undefined) {
      const newItem = {
      name: itemWPSRootOpeningRadiuss,
      type: "WPSRootOpeningRadius"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSRootOpeningRadiuss: '',
      });
      this.getCodesWPSRootOpeningRadius();
  } else {
      this.showWarning();
  }
};

getCodesWPSRootOpeningRadius = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSRootOpeningRadius");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSRootOpeningRadiuss: response.data
    });
  }
}

handleChangeWPSBackgougingMethod = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSBackgougingMethods.length; z++) {
    if (this.state.WPSBackgougingMethods[z].id == e) {
      deger = this.state.WPSBackgougingMethods[z].name;
    }
  }
  this.setState({
    backgougingMethodAck: deger,
    backgougingMethod: e,
  });
};

addWPSBackgougingMethods = async () => {
  const { itemWPSBackgougingMethods } = this.state;
  if (itemWPSBackgougingMethods != null && itemWPSBackgougingMethods != "" && itemWPSBackgougingMethods != undefined) {
      const newItem = {
      name: itemWPSBackgougingMethods,
      type: "WPSBackgougingMethod"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSBackgougingMethods: '',
      });
      this.getCodesWPSBackgougingMethod();
  } else {
      this.showWarning();
  }
};

getCodesWPSBackgougingMethod = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSBackgougingMethod");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSBackgougingMethods: response.data
    });
  }
} 

handleChangeWPSNumberOfElectrodes = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSNumberOfElectrodess.length; z++) {
    if (this.state.WPSNumberOfElectrodess[z].id == e) {
      deger = this.state.WPSNumberOfElectrodess[z].name;
    }
  }
  this.setState({
    numberOfElectrodesAck: deger,
    numberOfElectrodes: e,
  });
};

addWPSNumberOfElectrodess = async () => {
  const { itemWPSNumberOfElectrodess } = this.state;
  if (itemWPSNumberOfElectrodess != null && itemWPSNumberOfElectrodess != "" && itemWPSNumberOfElectrodess != undefined) {
      const newItem = {
      name: itemWPSNumberOfElectrodess,
      type: "WPSNumberOfElectrodes"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSNumberOfElectrodess: '',
      });
      this.getCodesWPSNumberOfElectrodes();
  } else {
      this.showWarning();
  }
};

getCodesWPSNumberOfElectrodes = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSNumberOfElectrodes");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSNumberOfElectrodess: response.data
    });
  }
} 

handleChangeWPSMaterialSpecifications = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSMaterialSpecifications.length; z++) {
    if (this.state.WPSMaterialSpecifications[z].id == e) {
      deger = this.state.WPSMaterialSpecifications[z].name;
    }
  }
  this.setState({
    materialSpecificationsAck: deger,
    materialSpecifications: e,
  });
};

addWPSMaterialSpecifications = async () => {
  const { itemWPSMaterialSpecifications } = this.state;
  if (itemWPSMaterialSpecifications != null && itemWPSMaterialSpecifications != "" && itemWPSMaterialSpecifications != undefined) {
      const newItem = {
      name: itemWPSMaterialSpecifications,
      type: "WPSMaterialSpecifications"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSMaterialSpecifications: '',
      });
      this.getCodesWPSMaterialSpecifications();
  } else {
      this.showWarning();
  }
};

getCodesWPSMaterialSpecifications = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSMaterialSpecifications");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSMaterialSpecifications: response.data
    });
  }
} 

handleChangeWPSTypeOrGrades = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSTypeOrGrades.length; z++) {
    if (this.state.WPSTypeOrGrades[z].id == e) {
      deger = this.state.WPSTypeOrGrades[z].name;
    }
  }
  this.setState({
    typeOrGradeAck: deger,
    typeOrGrade: e,
  });
};

addWPSTypeOrGrades = async () => {
  const { itemWPSTypeOrGrades } = this.state;
  if (itemWPSTypeOrGrades != null && itemWPSTypeOrGrades != "" && itemWPSTypeOrGrades != undefined) {
      const newItem = {
      name: itemWPSTypeOrGrades,
      type: "WPSTypeOrGrades"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSTypeOrGrades: '',
      });
      this.getCodesWPSTypeOrGrades();
  } else {
      this.showWarning();
  }
};

getCodesWPSTypeOrGrades = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSTypeOrGrades");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSTypeOrGrades: response.data
    });
  }
} 

handleChangeWPSTOSpecificationTypeAndGrades = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSTOSpecificationTypeAndGrades.length; z++) {
    if (this.state.WPSTOSpecificationTypeAndGrades[z].id == e) {
      deger = this.state.WPSTOSpecificationTypeAndGrades[z].name;
    }
  }
  this.setState({
    tOSpecificationTypeAndGradeAck: deger,
    tOSpecificationTypeAndGrade: e,
  });
};

addWPSTOSpecificationTypeAndGrades = async () => {
  const { itemWPSTOSpecificationTypeAndGrades } = this.state;
  if (itemWPSTOSpecificationTypeAndGrades != null && itemWPSTOSpecificationTypeAndGrades != "" && itemWPSTOSpecificationTypeAndGrades != undefined) {
      const newItem = {
      name: itemWPSTOSpecificationTypeAndGrades,
      type: "WPSTOSpecificationTypeAndGrades"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSTOSpecificationTypeAndGrades: '',
      });
      this.getCodesWPSTOSpecificationTypeAndGrades();
  } else {
      this.showWarning();
  }
};

getCodesWPSTOSpecificationTypeAndGrades = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSTOSpecificationTypeAndGrades");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSTOSpecificationTypeAndGrades: response.data
    });
  }
} 

handleChangeWPSOfBaseMetalGrooves = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSOfBaseMetalGrooves.length; z++) {
    if (this.state.WPSOfBaseMetalGrooves[z].id == e) {
      deger = this.state.WPSOfBaseMetalGrooves[z].name;
    }
  }
  this.setState({
    ofBaseMetalGrooveAck: deger,
    ofBaseMetalGroove: e,
  });
};

addWPSOfBaseMetalGrooves = async () => {
  const { itemWPSOfBaseMetalGrooves } = this.state;
  if (itemWPSOfBaseMetalGrooves != null && itemWPSOfBaseMetalGrooves != "" && itemWPSOfBaseMetalGrooves != undefined) {
      const newItem = {
      name: itemWPSOfBaseMetalGrooves,
      type: "WPSOfBaseMetalGrooves"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSOfBaseMetalGrooves: '',
      });
      this.getCodesWPSOfBaseMetalGrooves();
  } else {
      this.showWarning();
  }
};

getCodesWPSOfBaseMetalGrooves = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSOfBaseMetalGrooves");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSOfBaseMetalGrooves: response.data
    });
  }
} 

handleChangeWPSOfBaseMetalFillets = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSOfBaseMetalFillets.length; z++) {
    if (this.state.WPSOfBaseMetalFillets[z].id == e) {
      deger = this.state.WPSOfBaseMetalFillets[z].name;
    }
  }
  this.setState({
    ofBaseMetalFilletAck: deger,
    ofBaseMetalFillet: e,
  });
};

addWPSOfBaseMetalFillets = async () => {
  const { itemWPSOfBaseMetalFillets } = this.state;
  if (itemWPSOfBaseMetalFillets != null && itemWPSOfBaseMetalFillets != "" && itemWPSOfBaseMetalFillets != undefined) {
      const newItem = {
      name: itemWPSOfBaseMetalFillets,
      type: "WPSOfBaseMetalFillets"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSOfBaseMetalFillets: '',
      });
      this.getCodesWPSOfBaseMetalFillets();
  } else {
      this.showWarning();
  }
};

getCodesWPSOfBaseMetalFillets = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSOfBaseMetalFillets");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSOfBaseMetalFillets: response.data
    });
  }
} 

handleChangeWPSDepositedMetalGrooves = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSDepositedMetalGrooves.length; z++) {
    if (this.state.WPSDepositedMetalGrooves[z].id == e) {
      deger = this.state.WPSDepositedMetalGrooves[z].name;
    }
  }
  this.setState({
    depositedMetalGrooveAck: deger,
    depositedMetalGroove: e,
  });
};

addWPSDepositedMetalGrooves = async () => {
  const { itemWPSDepositedMetalGrooves } = this.state;
  if (itemWPSDepositedMetalGrooves != null && itemWPSDepositedMetalGrooves != "" && itemWPSDepositedMetalGrooves != undefined) {
      const newItem = {
      name: itemWPSDepositedMetalGrooves,
      type: "WPSDepositedMetalGrooves"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSDepositedMetalGrooves: '',
      });
      this.getCodesWPSDepositedMetalGrooves();
  } else {
      this.showWarning();
  }
};

getCodesWPSDepositedMetalGrooves = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSDepositedMetalGrooves");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSDepositedMetalGrooves: response.data
    });
  }
} 

handleChangeWPSDepositedMetalFillets = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSDepositedMetalFillets.length; z++) {
    if (this.state.WPSDepositedMetalFillets[z].id == e) {
      deger = this.state.WPSDepositedMetalFillets[z].name;
    }
  }
  this.setState({
    depositedMetalFilletAck: deger,
    depositedMetalFillet: e,
  });
};

addWPSDepositedMetalFillets = async () => {
  const { itemWPSDepositedMetalFillets } = this.state;
  if (itemWPSDepositedMetalFillets != null && itemWPSDepositedMetalFillets != "" && itemWPSDepositedMetalFillets != undefined) {
      const newItem = {
      name: itemWPSDepositedMetalFillets,
      type: "WPSDepositedMetalFillets"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSDepositedMetalFillets: '',
      });
      this.getCodesWPSDepositedMetalFillets();
  } else {
      this.showWarning();
  }
};

getCodesWPSDepositedMetalFillets = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSDepositedMetalFillets");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSDepositedMetalFillets: response.data
    });
  }
} 

handleChangeWPSPipeDiaRangeGrooves = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSPipeDiaRangeGrooves.length; z++) {
    if (this.state.WPSPipeDiaRangeGrooves[z].id == e) {
      deger = this.state.WPSPipeDiaRangeGrooves[z].name;
    }
  }
  this.setState({
    pipeDiaRangeGrooveAck: deger,
    pipeDiaRangeGroove: e,
  });
};

addWPSPipeDiaRangeGrooves = async () => {
  const { itemWPSPipeDiaRangeGrooves } = this.state;
  if (itemWPSPipeDiaRangeGrooves != null && itemWPSPipeDiaRangeGrooves != "" && itemWPSPipeDiaRangeGrooves != undefined) {
      const newItem = {
      name: itemWPSPipeDiaRangeGrooves,
      type: "WPSPipeDiaRangeGrooves"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSPipeDiaRangeGrooves: '',
      });
      this.getCodesWPSPipeDiaRangeGrooves();
  } else {
      this.showWarning();
  }
};

getCodesWPSPipeDiaRangeGrooves = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSPipeDiaRangeGrooves");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSPipeDiaRangeGrooves: response.data
    });
  }
} 

handleChangeWPSPipeDiaRangeFillets = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSPipeDiaRangeFillets.length; z++) {
    if (this.state.WPSPipeDiaRangeFillets[z].id == e) {
      deger = this.state.WPSPipeDiaRangeFillets[z].name;
    }
  }
  this.setState({
    pipeDiaRangeFilletAck: deger,
    pipeDiaRangeFillet: e,
  });
};

addWPSPipeDiaRangeFillets = async () => {
  const { itemWPSPipeDiaRangeFillets } = this.state;
  if (itemWPSPipeDiaRangeFillets != null && itemWPSPipeDiaRangeFillets != "" && itemWPSPipeDiaRangeFillets != undefined) {
      const newItem = {
      name: itemWPSPipeDiaRangeFillets,
      type: "WPSPipeDiaRangeFillets"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSPipeDiaRangeFillets: '',
      });
      this.getCodesWPSPipeDiaRangeFillets();
  } else {
      this.showWarning();
  }
};

getCodesWPSPipeDiaRangeFillets = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSPipeDiaRangeFillets");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSPipeDiaRangeFillets: response.data
    });
  }
} 

handleChangeWPSBaseMetalOtherGrooves = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSBaseMetalOtherGrooves.length; z++) {
    if (this.state.WPSBaseMetalOtherGrooves[z].id == e) {
      deger = this.state.WPSBaseMetalOtherGrooves[z].name;
    }
  }
  this.setState({
    baseMetalOtherGrooveAck: deger,
    baseMetalOtherGroove: e,
  });
};

addWPSBaseMetalOtherGrooves = async () => {
  const { itemWPSBaseMetalOtherGrooves } = this.state;
  if (itemWPSBaseMetalOtherGrooves != null && itemWPSBaseMetalOtherGrooves != "" && itemWPSBaseMetalOtherGrooves != undefined) {
      const newItem = {
      name: itemWPSBaseMetalOtherGrooves,
      type: "WPSBaseMetalOtherGrooves"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSBaseMetalOtherGrooves: '',
      });
      this.getCodesWPSBaseMetalOtherGrooves();
  } else {
      this.showWarning();
  }
};

getCodesWPSBaseMetalOtherGrooves = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSBaseMetalOtherGrooves");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSBaseMetalOtherGrooves: response.data
    });
  }
} 

handleChangeWPSBaseMetalOtherFillets = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSBaseMetalOtherFillets.length; z++) {
    if (this.state.WPSBaseMetalOtherFillets[z].id == e) {
      deger = this.state.WPSBaseMetalOtherFillets[z].name;
    }
  }
  this.setState({
    baseMetalOtherFilletAck: deger,
    baseMetalOtherFillet: e,
  });
};

addWPSBaseMetalOtherFillets = async () => {
  const { itemWPSBaseMetalOtherFillets } = this.state;
  if (itemWPSBaseMetalOtherFillets != null && itemWPSBaseMetalOtherFillets != "" && itemWPSBaseMetalOtherFillets != undefined) {
      const newItem = {
      name: itemWPSBaseMetalOtherFillets,
      type: "WPSBaseMetalOtherFillets"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSBaseMetalOtherFillets: '',
      });
      this.getCodesWPSBaseMetalOtherFillets();
  } else {
      this.showWarning();
  }
};

getCodesWPSBaseMetalOtherFillets = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSBaseMetalOtherFillets");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSBaseMetalOtherFillets: response.data
    });
  }
} 

handleChangeWPSTypeOfCoatings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSTypeOfCoatings.length; z++) {
    if (this.state.WPSTypeOfCoatings[z].id == e) {
      deger = this.state.WPSTypeOfCoatings[z].name;
    }
  }
  this.setState({
    typeOfCoatingAck: deger,
    typeOfCoating: e,
  });
};

addWPSTypeOfCoatings = async () => {
  const { itemWPSTypeOfCoatings } = this.state;
  if (itemWPSTypeOfCoatings != null && itemWPSTypeOfCoatings != "" && itemWPSTypeOfCoatings != undefined) {
      const newItem = {
      name: itemWPSTypeOfCoatings,
      type: "WPSTypeOfCoatings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSTypeOfCoatings: '',
      });
      this.getCodesWPSTypeOfCoatings();
  } else {
      this.showWarning();
  }
};

getCodesWPSTypeOfCoatings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSTypeOfCoatings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSTypeOfCoatings: response.data
    });
  }
} 

handleChangeWPSAWSClassifications = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSAWSClassifications.length; z++) {
    if (this.state.WPSAWSClassifications[z].id == e) {
      deger = this.state.WPSAWSClassifications[z].name;
    }
  }
  this.setState({
    awsClassificationAck: deger,
    awsClassification: e,
  });
};

addWPSAWSClassifications = async () => {
  const { itemWPSAWSClassifications } = this.state;
  if (itemWPSAWSClassifications != null && itemWPSAWSClassifications != "" && itemWPSAWSClassifications != undefined) {
      const newItem = {
      name: itemWPSAWSClassifications,
      type: "WPSAWSClassifications"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSAWSClassifications: '',
      });
      this.getCodesWPSAWSClassifications();
  } else {
      this.showWarning();
  }
};

getCodesWPSAWSClassifications = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSAWSClassifications");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSAWSClassifications: response.data
    });
  }
} 

handleChangeWPSAWSSpecifications = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSAWSSpecifications.length; z++) {
    if (this.state.WPSAWSSpecifications[z].id == e) {
      deger = this.state.WPSAWSSpecifications[z].name;
    }
  }
  this.setState({
    awsSpecificationAck: deger,
    awsSpecification: e,
  });
};

addWPSAWSSpecifications = async () => {
  const { itemWPSAWSSpecifications } = this.state;
  if (itemWPSAWSSpecifications != null && itemWPSAWSSpecifications != "" && itemWPSAWSSpecifications != undefined) {
      const newItem = {
      name: itemWPSAWSSpecifications,
      type: "WPSAWSSpecifications"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSAWSSpecifications: '',
      });
      this.getCodesWPSAWSSpecifications();
  } else {
      this.showWarning();
  }
};

getCodesWPSAWSSpecifications = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSAWSSpecifications");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSAWSSpecifications: response.data
    });
  }
} 

handleChangeWPSElectrodeFluxClasss = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSElectrodeFluxClasss.length; z++) {
    if (this.state.WPSElectrodeFluxClasss[z].id == e) {
      deger = this.state.WPSElectrodeFluxClasss[z].name;
    }
  }
  this.setState({
    electrodeFluxClassAck: deger,
    electrodeFluxClass: e,
  });
};

addWPSElectrodeFluxClasss = async () => {
  const { itemWPSElectrodeFluxClasss } = this.state;
  if (itemWPSElectrodeFluxClasss != null && itemWPSElectrodeFluxClasss != "" && itemWPSElectrodeFluxClasss != undefined) {
      const newItem = {
      name: itemWPSElectrodeFluxClasss,
      type: "WPSElectrodeFluxClasss"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSElectrodeFluxClasss: '',
      });
      this.getCodesWPSElectrodeFluxClasss();
  } else {
      this.showWarning();
  }
};

getCodesWPSElectrodeFluxClasss = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSElectrodeFluxClasss");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSElectrodeFluxClasss: response.data
    });
  }
} 

handleChangeWPSPositionsOfGrooves = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSPositionsOfGrooves.length; z++) {
    if (this.state.WPSPositionsOfGrooves[z].id == e) {
      deger = this.state.WPSPositionsOfGrooves[z].name;
    }
  }
  this.setState({
    positionsOfGrooveAck: deger,
    positionsOfGroove: e,
  });
};

addWPSPositionsOfGrooves = async () => {
  const { itemWPSPositionsOfGrooves } = this.state;
  if (itemWPSPositionsOfGrooves != null && itemWPSPositionsOfGrooves != "" && itemWPSPositionsOfGrooves != undefined) {
      const newItem = {
      name: itemWPSPositionsOfGrooves,
      type: "WPSPositionsOfGrooves"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSPositionsOfGrooves: '',
      });
      this.getCodesWPSPositionsOfGrooves();
  } else {
      this.showWarning();
  }
};

getCodesWPSPositionsOfGrooves = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSPositionsOfGrooves");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSPositionsOfGrooves: response.data
    });
  }
} 

handleChangeWPSPositionsOfFillets = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSPositionsOfFillets.length; z++) {
    if (this.state.WPSPositionsOfFillets[z].id == e) {
      deger = this.state.WPSPositionsOfFillets[z].name;
    }
  }
  this.setState({
    positionsOfFilletAck: deger,
    positionsOfFillet: e,
  });
};

addWPSPositionsOfFillets = async () => {
  const { itemWPSPositionsOfFillets } = this.state;
  if (itemWPSPositionsOfFillets != null && itemWPSPositionsOfFillets != "" && itemWPSPositionsOfFillets != undefined) {
      const newItem = {
      name: itemWPSPositionsOfFillets,
      type: "WPSPositionsOfFillets"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSPositionsOfFillets: '',
      });
      this.getCodesWPSPositionsOfFillets();
  } else {
      this.showWarning();
  }
};

getCodesWPSPositionsOfFillets = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSPositionsOfFillets");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSPositionsOfFillets: response.data
    });
  }
} 

handleChangeWPSWeldProgressions = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSWeldProgressions.length; z++) {
    if (this.state.WPSWeldProgressions[z].id == e) {
      deger = this.state.WPSWeldProgressions[z].name;
    }
  }
  this.setState({
    weldProgressionAck: deger,
    weldProgression: e,
  });
};

addWPSWeldProgressions = async () => {
  const { itemWPSWeldProgressions } = this.state;
  if (itemWPSWeldProgressions != null && itemWPSWeldProgressions != "" && itemWPSWeldProgressions != undefined) {
      const newItem = {
      name: itemWPSWeldProgressions,
      type: "WPSWeldProgressions"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSWeldProgressions: '',
      });
      this.getCodesWPSWeldProgressions();
  } else {
      this.showWarning();
  }
};

getCodesWPSWeldProgressions = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSWeldProgressions");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSWeldProgressions: response.data
    });
  }
} 

handleChangeWPSGasEsShieldings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSGasEsShieldings.length; z++) {
    if (this.state.WPSGasEsShieldings[z].id == e) {
      deger = this.state.WPSGasEsShieldings[z].name;
    }
  }
  this.setState({
    gasEsShieldingAck: deger,
    gasEsShielding: e,
  });
};

addWPSGasEsShieldings = async () => {
  const { itemWPSGasEsShieldings } = this.state;
  if (itemWPSGasEsShieldings != null && itemWPSGasEsShieldings != "" && itemWPSGasEsShieldings != undefined) {
      const newItem = {
      name: itemWPSGasEsShieldings,
      type: "WPSGasEsShieldings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSGasEsShieldings: '',
      });
      this.getCodesWPSGasEsShieldings();
  } else {
      this.showWarning();
  }
};

getCodesWPSGasEsShieldings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSGasEsShieldings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSGasEsShieldings: response.data
    });
  }
} 

handleChangeWPSGasEsBackings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSGasEsBackings.length; z++) {
    if (this.state.WPSGasEsBackings[z].id == e) {
      deger = this.state.WPSGasEsBackings[z].name;
    }
  }
  this.setState({
    gasEsBackingAck: deger,
    gasEsBacking: e,
  });
};

addWPSGasEsBackings = async () => {
  const { itemWPSGasEsBackings } = this.state;
  if (itemWPSGasEsBackings != null && itemWPSGasEsBackings != "" && itemWPSGasEsBackings != undefined) {
      const newItem = {
      name: itemWPSGasEsBackings,
      type: "WPSGasEsBackings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSGasEsBackings: '',
      });
      this.getCodesWPSGasEsBackings();
  } else {
      this.showWarning();
  }
};

getCodesWPSGasEsBackings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSGasEsBackings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSGasEsBackings: response.data
    });
  }
} 

handleChangeWPSGasEsTrailings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSGasEsTrailings.length; z++) {
    if (this.state.WPSGasEsTrailings[z].id == e) {
      deger = this.state.WPSGasEsTrailings[z].name;
    }
  }
  this.setState({
    gasEsTrailingAck: deger,
    gasEsTrailing: e,
  });
};

addWPSGasEsTrailings = async () => {
  const { itemWPSGasEsTrailings } = this.state;
  if (itemWPSGasEsTrailings != null && itemWPSGasEsTrailings != "" && itemWPSGasEsTrailings != undefined) {
      const newItem = {
      name: itemWPSGasEsTrailings,
      type: "WPSGasEsTrailings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSGasEsTrailings: '',
      });
      this.getCodesWPSGasEsTrailings();
  } else {
      this.showWarning();
  }
};

getCodesWPSGasEsTrailings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSGasEsTrailings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSGasEsTrailings: response.data
    });
  }
} 

handleChangeWPSCompositionShieldings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSCompositionShieldings.length; z++) {
    if (this.state.WPSCompositionShieldings[z].id == e) {
      deger = this.state.WPSCompositionShieldings[z].name;
    }
  }
  this.setState({
    compositionShieldingAck: deger,
    compositionShielding: e,
  });
};

addWPSCompositionShieldings = async () => {
  const { itemWPSCompositionShieldings } = this.state;
  if (itemWPSCompositionShieldings != null && itemWPSCompositionShieldings != "" && itemWPSCompositionShieldings != undefined) {
      const newItem = {
      name: itemWPSCompositionShieldings,
      type: "WPSCompositionShieldings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSCompositionShieldings: '',
      });
      this.getCodesWPSCompositionShieldings();
  } else {
      this.showWarning();
  }
};

getCodesWPSCompositionShieldings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSCompositionShieldings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSCompositionShieldings: response.data
    });
  }
} 

handleChangeWPSCompositionBackings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSCompositionBackings.length; z++) {
    if (this.state.WPSCompositionBackings[z].id == e) {
      deger = this.state.WPSCompositionBackings[z].name;
    }
  }
  this.setState({
    compositionBackingAck: deger,
    compositionBacking: e,
  });
};

addWPSCompositionBackings = async () => {
  const { itemWPSCompositionBackings } = this.state;
  if (itemWPSCompositionBackings != null && itemWPSCompositionBackings != "" && itemWPSCompositionBackings != undefined) {
      const newItem = {
      name: itemWPSCompositionBackings,
      type: "WPSCompositionBackings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSCompositionBackings: '',
      });
      this.getCodesWPSCompositionBackings();
  } else {
      this.showWarning();
  }
};

getCodesWPSCompositionBackings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSCompositionBackings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSCompositionBackings: response.data
    });
  }
} 

handleChangeWPSCompositionTrailings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSCompositionTrailings.length; z++) {
    if (this.state.WPSCompositionTrailings[z].id == e) {
      deger = this.state.WPSCompositionTrailings[z].name;
    }
  }
  this.setState({
    compositionTrailingAck: deger,
    compositionTrailing: e,
  });
};

addWPSCompositionTrailings = async () => {
  const { itemWPSCompositionTrailings } = this.state;
  if (itemWPSCompositionTrailings != null && itemWPSCompositionTrailings != "" && itemWPSCompositionTrailings != undefined) {
      const newItem = {
      name: itemWPSCompositionTrailings,
      type: "WPSCompositionTrailings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSCompositionTrailings: '',
      });
      this.getCodesWPSCompositionTrailings();
  } else {
      this.showWarning();
  }
};

getCodesWPSCompositionTrailings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSCompositionTrailings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSCompositionTrailings: response.data
    });
  }
} 
 
handleChangeWPSFlowRateShieldings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSFlowRateShieldings.length; z++) {
    if (this.state.WPSFlowRateShieldings[z].id == e) {
      deger = this.state.WPSFlowRateShieldings[z].name;
    }
  }
  this.setState({
    flowRateShieldingAck: deger,
    flowRateShielding: e,
  });
};

addWPSFlowRateShieldings = async () => {
  const { itemWPSFlowRateShieldings } = this.state;
  if (itemWPSFlowRateShieldings != null && itemWPSFlowRateShieldings != "" && itemWPSFlowRateShieldings != undefined) {
      const newItem = {
      name: itemWPSFlowRateShieldings,
      type: "WPSFlowRateShieldings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSFlowRateShieldings: '',
      });
      this.getCodesWPSFlowRateShieldings();
  } else {
      this.showWarning();
  }
};

getCodesWPSFlowRateShieldings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSFlowRateShieldings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSFlowRateShieldings: response.data
    });
  }
} 

handleChangeWPSFlowRateBackings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSFlowRateBackings.length; z++) {
    if (this.state.WPSFlowRateBackings[z].id == e) {
      deger = this.state.WPSFlowRateBackings[z].name;
    }
  }
  this.setState({
    flowRateBackingAck: deger,
    flowRateBacking: e,
  });
};

addWPSFlowRateBackings = async () => {
  const { itemWPSFlowRateBackings } = this.state;
  if (itemWPSFlowRateBackings != null && itemWPSFlowRateBackings != "" && itemWPSFlowRateBackings != undefined) {
      const newItem = {
      name: itemWPSFlowRateBackings,
      type: "WPSFlowRateBackings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSFlowRateBackings: '',
      });
      this.getCodesWPSFlowRateBackings();
  } else {
      this.showWarning();
  }
};

getCodesWPSFlowRateBackings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSFlowRateBackings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSFlowRateBackings: response.data
    });
  }
} 

handleChangeWPSFlowRateTrailings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSFlowRateTrailings.length; z++) {
    if (this.state.WPSFlowRateTrailings[z].id == e) {
      deger = this.state.WPSFlowRateTrailings[z].name;
    }
  }
  this.setState({
    flowRateTrailingAck: deger,
    flowRateTrailing: e,
  });
};

addWPSFlowRateTrailings = async () => {
  const { itemWPSFlowRateTrailings } = this.state;
  if (itemWPSFlowRateTrailings != null && itemWPSFlowRateTrailings != "" && itemWPSFlowRateTrailings != undefined) {
      const newItem = {
      name: itemWPSFlowRateTrailings,
      type: "WPSFlowRateTrailings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSFlowRateTrailings: '',
      });
      this.getCodesWPSFlowRateTrailings();
  } else {
      this.showWarning();
  }
};

getCodesWPSFlowRateTrailings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSFlowRateTrailings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSFlowRateTrailings: response.data
    });
  }
} 
 
handleChangeWPSGasCupSizeShieldings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSGasCupSizeShieldings.length; z++) {
    if (this.state.WPSGasCupSizeShieldings[z].id == e) {
      deger = this.state.WPSGasCupSizeShieldings[z].name;
    }
  }
  this.setState({
    gasCupSizeShieldingAck: deger,
    gasCupSizeShielding: e,
  });
};

addWPSGasCupSizeShieldings = async () => {
  const { itemWPSGasCupSizeShieldings } = this.state;
  if (itemWPSGasCupSizeShieldings != null && itemWPSGasCupSizeShieldings != "" && itemWPSGasCupSizeShieldings != undefined) {
      const newItem = {
      name: itemWPSGasCupSizeShieldings,
      type: "WPSGasCupSizeShieldings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSGasCupSizeShieldings: '',
      });
      this.getCodesWPSGasCupSizeShieldings();
  } else {
      this.showWarning();
  }
};

getCodesWPSGasCupSizeShieldings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSGasCupSizeShieldings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSGasCupSizeShieldings: response.data
    });
  }
} 

handleChangeWPSGasCupSizeBackings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSGasCupSizeBackings.length; z++) {
    if (this.state.WPSGasCupSizeBackings[z].id == e) {
      deger = this.state.WPSGasCupSizeBackings[z].name;
    }
  }
  this.setState({
    gasCupSizeBackingAck: deger,
    gasCupSizeBacking: e,
  });
};

addWPSGasCupSizeBackings = async () => {
  const { itemWPSGasCupSizeBackings } = this.state;
  if (itemWPSGasCupSizeBackings != null && itemWPSGasCupSizeBackings != "" && itemWPSGasCupSizeBackings != undefined) {
      const newItem = {
      name: itemWPSGasCupSizeBackings,
      type: "WPSGasCupSizeBackings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSGasCupSizeBackings: '',
      });
      this.getCodesWPSGasCupSizeBackings();
  } else {
      this.showWarning();
  }
};

getCodesWPSGasCupSizeBackings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSGasCupSizeBackings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSGasCupSizeBackings: response.data
    });
  }
} 

handleChangeWPSGasCupSizeTrailings = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSGasCupSizeTrailings.length; z++) {
    if (this.state.WPSGasCupSizeTrailings[z].id == e) {
      deger = this.state.WPSGasCupSizeTrailings[z].name;
    }
  }
  this.setState({
    gasCupSizeTrailingAck: deger,
    gasCupSizeTrailing: e,
  });
};

addWPSGasCupSizeTrailings = async () => {
  const { itemWPSGasCupSizeTrailings } = this.state;
  if (itemWPSGasCupSizeTrailings != null && itemWPSGasCupSizeTrailings != "" && itemWPSGasCupSizeTrailings != undefined) {
      const newItem = {
      name: itemWPSGasCupSizeTrailings,
      type: "WPSGasCupSizeTrailings"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSGasCupSizeTrailings: '',
      });
      this.getCodesWPSGasCupSizeTrailings();
  } else {
      this.showWarning();
  }
};

getCodesWPSGasCupSizeTrailings = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSGasCupSizeTrailings");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSGasCupSizeTrailings: response.data
    });
  }
} 

handleChangeWPSCurrentTypes = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSCurrentTypes.length; z++) {
    if (this.state.WPSCurrentTypes[z].id == e) {
      deger = this.state.WPSCurrentTypes[z].name;
    }
  }
  this.setState({
    currentTypeAck: deger,
    currentType: e,
  });
};

addWPSCurrentTypes = async () => {
  const { itemWPSCurrentTypes } = this.state;
  if (itemWPSCurrentTypes != null && itemWPSCurrentTypes != "" && itemWPSCurrentTypes != undefined) {
      const newItem = {
      name: itemWPSCurrentTypes,
      type: "WPSCurrentTypes"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSCurrentTypes: '',
      });
      this.getCodesWPSCurrentTypes();
  } else {
      this.showWarning();
  }
};

getCodesWPSCurrentTypes = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSCurrentTypes");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSCurrentTypes: response.data
    });
  }
} 

handleChangeWPSCurrentPolaritys = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSCurrentPolaritys.length; z++) {
    if (this.state.WPSCurrentPolaritys[z].id == e) {
      deger = this.state.WPSCurrentPolaritys[z].name;
    }
  }
  this.setState({
    currentPolarityAck: deger,
    currentPolarity: e,
  });
};

addWPSCurrentPolaritys = async () => {
  const { itemWPSCurrentPolaritys } = this.state;
  if (itemWPSCurrentPolaritys != null && itemWPSCurrentPolaritys != "" && itemWPSCurrentPolaritys != undefined) {
      const newItem = {
      name: itemWPSCurrentPolaritys,
      type: "WPSCurrentPolaritys"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSCurrentPolaritys: '',
      });
      this.getCodesWPSCurrentPolaritys();
  } else {
      this.showWarning();
  }
};

getCodesWPSCurrentPolaritys = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSCurrentPolaritys");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSCurrentPolaritys: response.data
    });
  }
} 

handleChangeWPSTungstenElectrodeTypes = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSTungstenElectrodeTypes.length; z++) {
    if (this.state.WPSTungstenElectrodeTypes[z].id == e) {
      deger = this.state.WPSTungstenElectrodeTypes[z].name;
    }
  }
  this.setState({
    tungstenElectrodeTypeAck: deger,
    tungstenElectrodeType: e,
  });
};

addWPSTungstenElectrodeTypes = async () => {
  const { itemWPSTungstenElectrodeTypes } = this.state;
  if (itemWPSTungstenElectrodeTypes != null && itemWPSTungstenElectrodeTypes != "" && itemWPSTungstenElectrodeTypes != undefined) {
      const newItem = {
      name: itemWPSTungstenElectrodeTypes,
      type: "WPSTungstenElectrodeTypes"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSTungstenElectrodeTypes: '',
      });
      this.getCodesWPSTungstenElectrodeTypes();
  } else {
      this.showWarning();
  }
};

getCodesWPSTungstenElectrodeTypes = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSTungstenElectrodeTypes");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSTungstenElectrodeTypes: response.data
    });
  }
} 

handleChangeWPSOtherVariablesNumberOfElectrodess = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.WPSOtherVariablesNumberOfElectrodess.length; z++) {
    if (this.state.WPSOtherVariablesNumberOfElectrodess[z].id == e) {
      deger = this.state.WPSOtherVariablesNumberOfElectrodess[z].name;
    }
  }
  this.setState({
    otherVariablesNumberOfElectrodesAck: deger,
    otherVariablesNumberOfElectrodes: e,
  });
};

addWPSOtherVariablesNumberOfElectrodess = async () => {
  const { itemWPSOtherVariablesNumberOfElectrodess } = this.state;
  if (itemWPSOtherVariablesNumberOfElectrodess != null && itemWPSOtherVariablesNumberOfElectrodess != "" && itemWPSOtherVariablesNumberOfElectrodess != undefined) {
      const newItem = {
      name: itemWPSOtherVariablesNumberOfElectrodess,
      type: "WPSOtherVariablesNumberOfElectrodess"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemWPSOtherVariablesNumberOfElectrodess: '',
      });
      this.getCodesWPSOtherVariablesNumberOfElectrodess();
  } else {
      this.showWarning();
  }
};

getCodesWPSOtherVariablesNumberOfElectrodess = async () => {
  const response = await handleRequest("GET", "/Code/type/WPSOtherVariablesNumberOfElectrodess");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      WPSOtherVariablesNumberOfElectrodess: response.data
    });
  }
} 

showSuccess(msg) {
  if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" />;
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Success"
  });
  this.redirectDocument();
}; 
  
showError(msg) {
  if (!msg) {
      msg =  <FormattedMessage id="GeneralMessageError1" />;
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Error"
  });
};

showWarning(msg) {
  if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" />;
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Warning"
  });
};

  onClickModal(name, rowData) {
    this.setState({
      [`${name}`]: true,
      selectedRole: rowData
    });

    console.log(rowData.roleName);
  }

  onHideModal(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  showOrHideModal = () => {
    this.setState({
      modalShow: !this.state.modalShow
    });
  }

  onBasicUpload() {
    this.toast.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
  }

  renderFooter(name) {
    return (
      <div>
        <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteRoleGroup(name)} className="p-button-danger" />
        <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
      </div>
    );
  }

  actionTemplate(rowData, column) {
    return <React.Fragment>
      <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>
      <Dialog header="Delete This Role Group Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
        <p>This discipline record will be deleted. Are you sure?</p>
      </Dialog>
      <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>
    </React.Fragment>;
  };
  
  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  changeButtonUpdate = (rowData) => {
    this.setState({
      //satipNameText: rowData.satipName,
      hidden: false,
      buttonName: "Update",
      buttonClassName: "p-button-warning"
    });
    console.log(rowData.satipName);
  };

  updateClientButton = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Client",
        lastButton: "Update",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
    else if (this.state.createNewButton === "Stop Adding New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Client",
        lastButton: "Update",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
    else if (this.state.createNewButton === "Stop Updating Client") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Client",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
  }
  
  refreshAccordions = () => {
    this.setState({

    });
  }
 
  onEditClick(e) {
    if (e.target.value === undefined)
      e.stopPropagation();
  }

showOrHideModalMessage = () => {
    this.setState({
        modalMessage: !this.state.modalMessage,
    }); 
    setTimeout(() => {
        document.body.style.overflow = "auto";
    }, 500);
}

addWPS = async () => {
  if(this.state.wpsno!=null && this.state.wpsno!=undefined && this.state.wpsno!=""){
    var WPSTechnique=[]; 
    if(this.state.stringers==true || this.state.weaveBead==true || this.state.multiPass==true || this.state.singlePass==true ||
      this.state.numberOfElectrodes>0 || this.state.peening!="" || this.state.interPassCleaning!="" ||  this.state.other!="" ){
      var wpsTechnique= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
        stringers:this.state.stringers,
        weaveBead:this.state.weaveBead,
        multiPass:this.state.multiPass,
        singlePass:this.state.singlePass,
        numberOfElectrodes:this.state.numberOfElectrodes,
        peening:this.state.peening,
        interPassCleaning:this.state.interPassCleaning,
        other:this.state.other,
      }
      WPSTechnique.push(wpsTechnique);
    }
 
    
    var WPSBaseMetals=[]; 
    if(this.state.baseMetalNo!=null && this.state.baseMetalNo!=undefined && this.state.baseMetalNo!=""){
      var wpsBaseMetals= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        baseMetalNo:this.state.baseMetalNo, 
        materialSpecifications:this.state.materialSpecifications,
        typeOrGrade:this.state.typeOrGrade,
        groupNo:this.state.groupNo,
        tOMNo:this.state.tOMNo,
        tOGroupNo:this.state.tOGroupNo,
        tOSpecificationTypeAndGrade:this.state.tOSpecificationTypeAndGrade,
        thicknessRangeFrom:this.state.thicknessRangeFrom,
        thicknessRangeTo:this.state.thicknessRangeTo,
        ofBaseMetalGroove:this.state.ofBaseMetalGroove,
        ofBaseMetalFillet:this.state.ofBaseMetalFillet,
        depositedMetalGroove:this.state.depositedMetalGroove,
        depositedMetalFillet:this.state.depositedMetalFillet,
        pipeDiaRangeGroove:this.state.pipeDiaRangeGroove,
        pipeDiaRangeFillet:this.state.pipeDiaRangeFillet,
        baseMetalOtherGroove:this.state.baseMetalOtherGroove,
        baseMetalOtherFillet:this.state.baseMetalOtherFillet,
        maximumCarbonEquivalent:this.state.maximumCarbonEquivalent,
        barSize:this.state.barSize,
        plateThickness:this.state.plateThickness,
        coatedBar:this.state.coatedBar,
        typeOfCoating:this.state.typeOfCoating,
      }
      WPSBaseMetals.push(wpsBaseMetals);
    }

    var WPSFillerMetals=[]; 
    if(this.state.fillerMetalFNo!=null && this.state.fillerMetalFNo!=undefined && this.state.fillerMetalFNo!=""){
      var wpsFillerMetals= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        fillerMetalFNo:this.state.fillerMetalFNo, 
        awsClassification:this.state.awsClassification,
        awsSpecification:this.state.awsSpecification,
        weldMetalAnalysisANo:this.state.weldMetalAnalysisANo, 
        fillerMetalSize:this.state.fillerMetalSize, 
        electrodeFluxClass:this.state.electrodeFluxClass,
        fluxTradeName:this.state.fluxTradeName, 
        consumableInsert:this.state.consumableInsert, 
        fillerMetalsOther:this.state.fillerMetalsOther, 
      }
      WPSFillerMetals.push(wpsFillerMetals);
    }

    var WPSPositions=[]; 
    if(this.state.positionsOfGroove>0 || this.state.positionsOfFillet>0 || this.state.weldProgression>0 ){
      var wpsPositions= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        positionsOfGroove:this.state.positionsOfGroove, 
        positionsOfFillet:this.state.positionsOfFillet,
        weldProgression:this.state.weldProgression, 
      }
      WPSPositions.push(wpsPositions);
    }

    var WPSPreheatTemperature=[]; 
    if((this.state.preheatTemperature!=null && this.state.preheatTemperature!=undefined && this.state.preheatTemperature!="") || 
    (this.state.interpassTemperatureMin!=null && this.state.interpassTemperatureMin!=undefined && this.state.interpassTemperatureMin!="") ||
    (this.state.interpassTemperatureMax!=null && this.state.interpassTemperatureMax!=undefined && this.state.interpassTemperatureMax!="") ||
    (this.state.preheatMaintenance!=null && this.state.preheatMaintenance!=undefined && this.state.preheatMaintenance!="") ||
    (this.state.heatingOrMaintenance!=null && this.state.heatingOrMaintenance!=undefined && this.state.heatingOrMaintenance!="") ){
      var wpsPreheatTemperature= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
        preheatTemperature:this.state.preheatTemperature,
        interpassTemperatureMin:this.state.interpassTemperatureMin,
        interpassTemperatureMax:this.state.interpassTemperatureMax,
        preheatMaintenance:this.state.preheatMaintenance,
        heatingOrMaintenance:this.state.heatingOrMaintenance,
      }
      WPSPreheatTemperature.push(wpsPreheatTemperature);
    }

    var WPSPostweldHeatTreatment=[]; 
    if((this.state.temperature!=null && this.state.temperature!=undefined && this.state.temperature!="") ||
    (this.state.time!=null && this.state.time!=undefined && this.state.time!="")){
      var wpsPostweldHeatTreatments= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        temperature:this.state.temperature, 
        time:this.state.time,
      }
      WPSPostweldHeatTreatment.push(wpsPostweldHeatTreatments);
    }

    var WPSShielding=[]; 
    if(this.state.fluxTypeTradeName!=null && this.state.fluxTypeTradeName!=undefined && this.state.fluxTypeTradeName!=""){
      var wpsShielding= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        positionsOfGroove:this.state.positionsOfGroove, 
        gasEsShielding:this.state.gasEsShielding,
        gasEsBacking:this.state.gasEsBacking,
        gasEsTrailing:this.state.gasEsTrailing,
        compositionShielding:this.state.compositionShielding,
        compositionBacking:this.state.compositionBacking,
        compositionTrailing:this.state.compositionTrailing,
        flowRateShielding:this.state.flowRateShielding,
        flowRateBacking:this.state.flowRateBacking,
        flowRateTrailing:this.state.flowRateTrailing,
        gasCupSizeShielding:this.state.gasCupSizeShielding,
        gasCupSizeBacking:this.state.gasCupSizeBacking,
        gasCupSizeTrailing:this.state.gasCupSizeTrailing,
        flux:this.state.flux,
        electroFluxClass:this.state.electroFluxClass,
        fluxTypeTradeName:this.state.fluxTypeTradeName,
      }
      WPSShielding.push(wpsShielding);
    }

    var WPSElectricalCharacteristics=[]; 
    if(this.state.generalCharacteristics!=null && this.state.generalCharacteristics!=undefined && this.state.generalCharacteristics!=""){
      var wpsElectricalCharacteristics= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        generalCharacteristics:this.state.generalCharacteristics,
        currentType:this.state.currentType,
        pulsing:this.state.pulsing,
        currentPolarity:this.state.currentPolarity,
        currentRange:this.state.currentRange,
        voltageRange:this.state.voltageRange,
        wireFeedSpeedRange:this.state.wireFeedSpeedRange,
        tungstenElectrodeSize:this.state.tungstenElectrodeSize,
        tungstenElectrodeType:this.state.tungstenElectrodeType,
        pulsingParameters:this.state.pulsingParameters,
        electricalcharOther:this.state.electricalcharOther,
        shortCirquiting:this.state.shortCirquiting,
        globular:this.state.globular,
        spray:this.state.spray,
      }
      WPSElectricalCharacteristics.push(wpsElectricalCharacteristics);
    }

    var WPSOtherVariables=[]; 
    if(this.state.positionsOfGroove>0 || this.state.positionsOfFillet>0 || this.state.weldProgression>0 ){
      var wpsOtherVariables= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        cupOrNozzleSize:this.state.cupOrNozzleSize, 
        colletBody:this.state.colletBody, 
        gasLens:this.state.gasLens, 
        cleaningMethod:this.state.cleaningMethod, 
        othervariablesOther:this.state.othervariablesOther, 
        otherVariablesNumberOfElectrodes:this.state.otherVariablesNumberOfElectrodes,
      }
      WPSOtherVariables.push(wpsOtherVariables);
    }

    var WPSJointDesign = {
      documentLogid:this.state.documentLogid,
      wpsno:this.state.wpsno,
      revno:this.state.revno,
      date:new Date(this.state.date),
      pqrNo:this.state.pqrNo,
      weldingProcess:this.state.weldingProcess,
      type:this.state.type,
      jointType:this.state.jointType,
      single:this.state.single,
      doubleWeld:this.state.doubleWeld,
      position:this.state.position,
      backing:this.state.backing,
      backingMaterialType:this.state.backingMaterialType,
      grooveAngle:this.state.grooveAngle,
      rootOpening:this.state.rootOpening,
      rootFaceDimension:this.state.rootFaceDimension,
      rootOpeningRadius:this.state.rootOpeningRadius,
      rootFace:this.state.rootFace,
      backgouging:this.state.backgouging,
      backgougingMethod:this.state.backgougingMethod,
      jointDetail:this.state.jointDetail,
      wpsTechniques:WPSTechnique,
      wpsBaseMetals:WPSBaseMetals,
      wpsFillerMetals:WPSFillerMetals,
      wpsPositions:WPSPositions,
      wpsPreheatInterpasses:WPSPreheatTemperature,
      wpsPostweldHeatTreatments:WPSPostweldHeatTreatment,   
      wpsShieldings:WPSShielding,   
      wpsElectricalCharacteristics:WPSElectricalCharacteristics,   
      wpsOtherVariables:WPSOtherVariables,
    } 
    console.log("WPS", WPSJointDesign);
    var response =  await handleRequest("POST", "/WPSJointDesign/add", WPSJointDesign);
    if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.showSuccess();
          this.setState({
            hiddenDataGiris:true,
          });
        }
        else  {
          this.showError();
        }  
    }  
    else  {
      this.showError();
    } 
  }else{
    this.showWarning();
  }
}

updateDone = async () => {
  console.log("UUU", this.state.wpsno);
  console.log(this.state.revno);
  if(this.state.wpsno!=null && this.state.wpsno!=undefined && this.state.wpsno!=""){
    var WPSTechnique=[]; 
    if(this.state.stringers==true || this.state.weaveBead==true || this.state.multiPass==true || this.state.singlePass==true ||
      this.state.numberOfElectrodes>0 || this.state.peening!="" || this.state.interPassCleaning!="" ||  this.state.other!="" ){
      var wpsTechnique= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
        stringers:this.state.stringers,
        weaveBead:this.state.weaveBead,
        multiPass:this.state.multiPass,
        singlePass:this.state.singlePass,
        numberOfElectrodes:this.state.numberOfElectrodes,
        peening:this.state.peening,
        interPassCleaning:this.state.interPassCleaning,
        other:this.state.other,
      }
      WPSTechnique.push(wpsTechnique);
    }

    var WPSBaseMetals=[]; 
    if(this.state.baseMetalNo!=null && this.state.baseMetalNo!=undefined && this.state.baseMetalNo!=""){
      var wpsBaseMetals= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        baseMetalNo:this.state.baseMetalNo, 
        materialSpecifications:this.state.materialSpecifications,
        typeOrGrade:this.state.typeOrGrade,
        groupNo:this.state.groupNo,
        tOMNo:this.state.tOMNo,
        tOGroupNo:this.state.tOGroupNo,
        tOSpecificationTypeAndGrade:this.state.tOSpecificationTypeAndGrade,
        thicknessRangeFrom:this.state.thicknessRangeFrom,
        thicknessRangeTo:this.state.thicknessRangeTo,
        ofBaseMetalGroove:this.state.ofBaseMetalGroove,
        ofBaseMetalFillet:this.state.ofBaseMetalFillet,
        depositedMetalGroove:this.state.depositedMetalGroove,
        depositedMetalFillet:this.state.depositedMetalFillet,
        pipeDiaRangeGroove:this.state.pipeDiaRangeGroove,
        pipeDiaRangeFillet:this.state.pipeDiaRangeFillet,
        baseMetalOtherGroove:this.state.baseMetalOtherGroove,
        baseMetalOtherFillet:this.state.baseMetalOtherFillet,
        maximumCarbonEquivalent:this.state.maximumCarbonEquivalent,
        barSize:this.state.barSize,
        plateThickness:this.state.plateThickness,
        coatedBar:this.state.coatedBar,
        typeOfCoating:this.state.typeOfCoating,
      }
      WPSBaseMetals.push(wpsBaseMetals);
    }

    var WPSFillerMetals=[]; 
    if(this.state.fillerMetalFNo!=null && this.state.fillerMetalFNo!=undefined && this.state.fillerMetalFNo!=""){
      var wpsFillerMetals= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        fillerMetalFNo:this.state.fillerMetalFNo, 
        awsClassification:this.state.awsClassification,
        awsSpecification:this.state.awsSpecification,
        weldMetalAnalysisANo:this.state.weldMetalAnalysisANo, 
        fillerMetalSize:this.state.fillerMetalSize, 
        electrodeFluxClass:this.state.electrodeFluxClass,
        fluxTradeName:this.state.fluxTradeName, 
        consumableInsert:this.state.consumableInsert, 
        fillerMetalsOther:this.state.fillerMetalsOther, 
      }
      WPSFillerMetals.push(wpsFillerMetals);
    }

    var WPSPositions=[]; 
    if(this.state.positionsOfGroove>0 || this.state.positionsOfFillet>0 || this.state.weldProgression>0 ){
      var wpsPositions= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        positionsOfGroove:this.state.positionsOfGroove, 
        positionsOfFillet:this.state.positionsOfFillet,
        weldProgression:this.state.weldProgression, 
      }
      WPSPositions.push(wpsPositions);
    }

    var WPSPreheatTemperature=[]; 
    if((this.state.preheatTemperature!=null && this.state.preheatTemperature!=undefined && this.state.preheatTemperature!="") || 
    (this.state.interpassTemperatureMin!=null && this.state.interpassTemperatureMin!=undefined && this.state.interpassTemperatureMin!="") ||
    (this.state.interpassTemperatureMax!=null && this.state.interpassTemperatureMax!=undefined && this.state.interpassTemperatureMax!="") ||
    (this.state.preheatMaintenance!=null && this.state.preheatMaintenance!=undefined && this.state.preheatMaintenance!="") ||
    (this.state.heatingOrMaintenance!=null && this.state.heatingOrMaintenance!=undefined && this.state.heatingOrMaintenance!="") ){
      var wpsPreheatTemperature= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
        preheatTemperature:this.state.preheatTemperature,
        interpassTemperatureMin:this.state.interpassTemperatureMin,
        interpassTemperatureMax:this.state.interpassTemperatureMax,
        preheatMaintenance:this.state.preheatMaintenance,
        heatingOrMaintenance:this.state.heatingOrMaintenance,
      }
      WPSPreheatTemperature.push(wpsPreheatTemperature);
    }

    var WPSPostweldHeatTreatment=[]; 
    if((this.state.temperature!=null && this.state.temperature!=undefined && this.state.temperature!="") ||
    (this.state.time!=null && this.state.time!=undefined && this.state.time!="")){
      var wpsPostweldHeatTreatments= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        temperature:this.state.temperature, 
        time:this.state.time,
      }
      WPSPostweldHeatTreatment.push(wpsPostweldHeatTreatments);
    }

    var WPSShielding=[]; 
    if(this.state.fluxTypeTradeName!=null && this.state.fluxTypeTradeName!=undefined && this.state.fluxTypeTradeName!=""){
      var wpsShielding= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        positionsOfGroove:this.state.positionsOfGroove, 
        gasEsShielding:this.state.gasEsShielding,
        gasEsBacking:this.state.gasEsBacking,
        gasEsTrailing:this.state.gasEsTrailing,
        compositionShielding:this.state.compositionShielding,
        compositionBacking:this.state.compositionBacking,
        compositionTrailing:this.state.compositionTrailing,
        flowRateShielding:this.state.flowRateShielding,
        flowRateBacking:this.state.flowRateBacking,
        flowRateTrailing:this.state.flowRateTrailing,
        gasCupSizeShielding:this.state.gasCupSizeShielding,
        gasCupSizeBacking:this.state.gasCupSizeBacking,
        gasCupSizeTrailing:this.state.gasCupSizeTrailing,
        flux:this.state.flux,
        electroFluxClass:this.state.electroFluxClass,
        fluxTypeTradeName:this.state.fluxTypeTradeName,
      }
      WPSShielding.push(wpsShielding);
    }

    var WPSElectricalCharacteristics=[]; 
    if(this.state.generalCharacteristics!=null && this.state.generalCharacteristics!=undefined && this.state.generalCharacteristics!=""){
      var wpsElectricalCharacteristics= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        generalCharacteristics:this.state.generalCharacteristics,
        currentType:this.state.currentType,
        pulsing:this.state.pulsing,
        currentPolarity:this.state.currentPolarity,
        currentRange:this.state.currentRange,
        voltageRange:this.state.voltageRange,
        wireFeedSpeedRange:this.state.wireFeedSpeedRange,
        tungstenElectrodeSize:this.state.tungstenElectrodeSize,
        tungstenElectrodeType:this.state.tungstenElectrodeType,
        pulsingParameters:this.state.pulsingParameters,
        electricalcharOther:this.state.electricalcharOther,
        shortCirquiting:this.state.shortCirquiting,
        globular:this.state.globular,
        spray:this.state.spray,
      }
      WPSElectricalCharacteristics.push(wpsElectricalCharacteristics);
    }

    var WPSOtherVariables=[]; 
    if(this.state.cupOrNozzleSize!=null && this.state.cupOrNozzleSize!=undefined && this.state.cupOrNozzleSize!=""){
      var wpsOtherVariables= {
        revno:this.state.revno,
        documentLogid:this.state.documentLogid,
      
        cupOrNozzleSize:this.state.cupOrNozzleSize, 
        colletBody:this.state.colletBody, 
        gasLens:this.state.gasLens, 
        cleaningMethod:this.state.cleaningMethod, 
        othervariablesOther:this.state.othervariablesOther, 
        otherVariablesNumberOfElectrodes:this.state.otherVariablesNumberOfElectrodes,
      }
      WPSOtherVariables.push(wpsOtherVariables);
    }

    var WPSJointDesign = {
      id:this.state.id,
      documentLogid:this.state.documentLogid,
      wpsno:this.state.wpsno,
      revno:this.state.revno,
      date:new Date(this.state.date),
      pqrNo:this.state.pqrNo,
      weldingProcess:this.state.weldingProcess,
      type:this.state.type,
      jointType:this.state.jointType,
      single:this.state.single,
      doubleWeld:this.state.doubleWeld,
      position:this.state.position,
      backing:this.state.backing,
      backingMaterialType:this.state.backingMaterialType,
      grooveAngle:this.state.grooveAngle,
      rootOpening:this.state.rootOpening,
      rootFaceDimension:this.state.rootFaceDimension,
      rootOpeningRadius:this.state.rootOpeningRadius,
      rootFace:this.state.rootFace,
      backgouging:this.state.backgouging,
      backgougingMethod:this.state.backgougingMethod,
      jointDetail:this.state.jointDetail,
      wpsTechniques:WPSTechnique,
      wpsBaseMetals:WPSBaseMetals, 
      wpsFillerMetals:WPSFillerMetals,
      wpsPositions:WPSPositions,
      wpsPreheatInterpasses:WPSPreheatTemperature,
      wpsPostweldHeatTreatments:WPSPostweldHeatTreatment,
      wpsShieldings:WPSShielding,
      wpsElectricalCharacteristics:WPSElectricalCharacteristics,
      wpsOtherVariables:WPSOtherVariables,
    } 

  console.log("update", WPSJointDesign);

  var response =   await handleRequest("POST", "/WPSJointDesign/update", WPSJointDesign);
  if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        this.showSuccess();
        this.setState({
          hiddenDataGiris:true,
        });
      }
      else {
        this.showError();
      } 
    } 
    else {
      this.showError();  
    }   
  }
  else {
    this.showWarning();
  } 
};

changeButtonCancel = () => {
  this.setState({
    hiddenDataGiris:true,
  });
  this.redirectDocument();
};

showReport = async () => {
  try {
      if(this.state.WPSJointDesign.jointDetail!=null && this.state.WPSJointDesign.jointDetail!=undefined && this.state.WPSJointDesign.jointDetail!=""){
          const fileRealUrl = this.state.WPSJointDesign.jointDetail;
  
          if(fileRealUrl.endsWith('.pdf')){
          this.setState({
              pdfFile: fileRealUrl,
              modalPdfViewer: true,
          });
          }else if(fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
              || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')){
  
          this.setState({
              imageUrl: fileRealUrl,
              modalPhotoShow: true,
          })
          }else{
          window.location.href = fileRealUrl;
          }
      }else{
          this.showError(<FormattedMessage id="FileErrorMessage1" />);
      }
  } catch (error) {
  this.showError(<FormattedMessage id="FileErrorMessage1" />);
  }
};

showOrHideModalPdfViewer = () => {
  this.setState({
  modalPdfViewer: !this.state.modalPdfViewer,
  });
  setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

showOrHidePhotoModal = () => {
  this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
  });
  setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

  render() {
    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    }; 

    const layout = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div>
 
        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              <Row > 
                  <Col sm="12">
                  {this.state.messageTip=="Success" &&  
                      <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                  
                  {this.state.messageTip=="Warning" &&  
                  <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip=="Error" &&  
                  <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                  </Col>
              </Row>
              </Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <Row > 
              <Col sm="12">
                  {this.state.message} 
              </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" /></Button>
          </Modal.Footer>
        </Modal>
 
        <Modal
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="ModalPhotoText" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.imageUrl !== "" ? (
              <img
              src={this.state.imageUrl}
              alt="avatar"
              style={{ width: "100%" }}
              />
          ) : (
              <p> <FormattedMessage id="UploadedPhotoMessage1" /></p>
              )}
          </Modal.Body>
          <Modal.Footer>
          <AntdButton variant="info" onClick={this.showOrHidePhotoModal}>
              <FormattedMessage id="GeneralTextClose" />
          </AntdButton>
          </Modal.Footer>
      </Modal>

       <Modal
        size="lg"
        show={this.state.modalPdfViewer}
        onHide={this.showOrHideModalPdfViewer}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="ModalPDFReportText" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
            <div style={{ height: "900px" }}>
            <Viewer fileUrl={this.state.pdfFile} layout={layout} />
            </div>
        </Worker>
        </Modal.Body>
        <Modal.Footer>
        <AntdButton
            variant="secondary"
            onClick={this.showOrHideModalPdfViewer}
        >
            <FormattedMessage id="GeneralTextClose" />
            </AntdButton>
        </Modal.Footer>
    </Modal>

        <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
          <Row>
            <Col xs={2}>
              <Modal
                show={this.state.modalShow}
                onHide={this.showOrHideModal}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">Delete All of Selected Client Logs?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>All logs of this client will be deleted. Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                  <Button variant="danger" onClick={this.deleteClient}>Delete</Button>
                </Modal.Footer>
              </Modal></Col>
            <Col xs={10}></Col>
          </Row>
        </Container>
        <br />
        <div hidden={this.state.hiddenDataGiris} className="col-md-12">
        <Collapse defaultActiveKey={['1']} expandIconPosition={this.state.expandIconPosition}>

          <Panel style={{ fontWeight: 'bold' }}  header={<FormattedMessage id="WPSJointDesign" />} key="1"  >
              <div>
              <Form>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSWpsNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.wpsno}  
                    onChange={(e) =>
                    this.setState({
                      wpsno: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSDate" /> 
                </Form.Label>
                <Col sm="4">
                <Calendar dateFormat="dd/mm/yy" value={this.state.date} 
                showButtonBar={true} onChange={(e) => this.setState({ date: e.value })} 
                monthNavigator={true} yearNavigator={true} yearRange="1000:3000" />
                </Col>
              </Form.Group>
 
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPqrNo" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="pqrNo"
                  style={{ width: '100%' }}
                  value={this.state.pqrNoAck}
                  onChange={this.handleChangeWPSPqrNo}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSPqrNos}
                          onChange={(event) => { this.setState({ itemWPSPqrNos: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSPqrNos} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSPqrNos.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSWeldingProcess" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="weldingProcess"
                  style={{ width: '100%' }}
                  value={this.state.weldingProcessAck}
                  onChange={this.handleChangeWPSWeldingProcess}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSWeldingProcesss}
                          onChange={(event) => { this.setState({ itemWPSWeldingProcesss: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSWeldingProcesss} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSWeldingProcesss.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSType" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="type"
                  style={{ width: '100%' }}
                  value={this.state.typeAck}
                  onChange={this.handleChangeWPSType}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSTypes}
                          onChange={(event) => { this.setState({ itemWPSTypes: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSTypes} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSTypes.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSJointType" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="jointType"
                  style={{ width: '100%' }}
                  value={this.state.jointTypeAck}
                  onChange={this.handleChangeWPSJointType}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSJointTypes}
                          onChange={(event) => { this.setState({ itemWPSJointTypes: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSJointTypes} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSJointTypes.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                 <Col sm="2">
                    <AntCheckbox checked={this.state.single} onChange={(e) => { 
                      this.setState({ 
                        single: e.target.checked,
                      });
                    }}> <FormattedMessage id="WPSSingle" /> </AntCheckbox>
                 </Col>
              
                <Col sm="2">
                  <AntCheckbox checked={this.state.doubleWeld} onChange={(e) => { 
                      this.setState({ 
                        doubleWeld: e.target.checked,
                      });
                    }}> <FormattedMessage id="WPSDoubleWeld" /> </AntCheckbox>
                 </Col>  
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPosition" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.position}  
                    onChange={(e) =>
                    this.setState({
                      position: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSBacking" /> 
                </Form.Label>
                <Col sm="4">
                <ToggleButton style={{ width: '100%' }} onLabel={ <FormattedMessage id="GeneralTextYes" /> } offLabel={ <FormattedMessage id="GeneralTextNo" /> }
                      checked={this.state.backing} onChange={(e) => this.setState({ backing: e.value })} />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSBackingMaterialType" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="backingMaterialType"
                  style={{ width: '100%' }}
                  value={this.state.backingMaterialTypeAck}
                  onChange={this.handleChangeWPSBackingMaterialType}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSBackingMaterialTypes}
                          onChange={(event) => { this.setState({ itemWPSBackingMaterialTypes: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSBackingMaterialTypes} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSBackingMaterialTypes.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSGrooveAngle" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.grooveAngle}  
                    onChange={(e) =>
                    this.setState({
                      grooveAngle: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRootOpening" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.rootOpening}  
                    onChange={(e) =>
                    this.setState({
                      rootOpening: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRootFaceDimension" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.rootFaceDimension}  
                    onChange={(e) =>
                    this.setState({
                      rootFaceDimension: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRootOpeningRadius" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="rootOpeningRadius"
                  style={{ width: '100%' }}
                  value={this.state.rootOpeningRadiusAck}
                  onChange={this.handleChangeWPSRootOpeningRadius}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSRootOpeningRadiuss}
                          onChange={(event) => { this.setState({ itemWPSRootOpeningRadiuss: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSRootOpeningRadiuss} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSRootOpeningRadiuss.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRootFace" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.rootFace}  
                    onChange={(e) =>
                    this.setState({
                      rootFace: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSBackgouging" /> 
                </Form.Label>
                <Col sm="4">
                <ToggleButton style={{ width: '100%' }} onLabel={ <FormattedMessage id="GeneralTextYes" /> } offLabel={ <FormattedMessage id="GeneralTextNo" /> }
                      checked={this.state.backgouging} onChange={(e) => this.setState({ backgouging: e.value })} />
                </Col>
              </Form.Group>
           
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSBackgougingMethod" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="backgougingMethod"
                  style={{ width: '100%' }}
                  value={this.state.backgougingMethodAck}
                  onChange={this.handleChangeWPSBackgougingMethod}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSBackgougingMethods}
                          onChange={(event) => { this.setState({ itemWPSBackgougingMethods: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSBackgougingMethods} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSBackgougingMethods.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
              </Form.Group>
               
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                        <FormattedMessage id="WPSJointDetail" /> 
                    </Form.Label>
                    <Col sm="2"> 
                        <Upload {...props} 
                            showUploadList={true}
                            fileList={this.state.FileUpload}
                            onChange={({ fileList }) => {
                            if (fileList.length == 1) {  
                                var jointDetail="";
                                if(Boolean(fileList[0].response)){
                                    jointDetail=fileList[0].response.url;  
                                    this.setState({
                                      modalMessage:true,
                                      message:"file uploaded successfully",
                                      messageTip:"Success"
                                  });
                                }
                                this.setState({
                                    FileUpload:fileList,
                                    jointDetail:jointDetail,
                                });
                            }
                        }}
                        beforeUpload={(fileList) => {
                            if (this.state.FileUpload.length > 0) {
                                this.showWarning("You have been already choose a file! For change firstly remove the uploaded file."); 
                                return false;
                            }
                            return true;
                        }}
                        onRemove={({ fileList }) => {
                            this.setState( {
                                FileUpload:[], 
                                jointDetail:"",
                            })
                        }
                        }>
                        <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                            <UploadOutlined /><FormattedMessage id="GeneralUploadFile" />
                        </AntdButton>
                        </Upload>
                    </Col>
                    <Col sm="2"> 
                    { (this.state.WPSJointDesign.jointDetail!=null && this.state.WPSJointDesign.jointDetail!=undefined && this.state.WPSJointDesign.jointDetail!="") &&
                    <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} 
                    onClick={() => this.showReport()} type="primary" shape="round" icon={<DownloadOutlined />} > 
                    </AntdButton>
                    } 
                    </Col>
                </Form.Group>    
              </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSTechnique" />} key="2"  >
            <div>
              <Form>
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Col sm="3">
                  <AntCheckbox checked={this.state.stringers} onChange={(e) => { 
                        this.setState({ 
                          stringers: e.target.checked,
                        });
                      }}> <FormattedMessage id="WPSStringers" /> </AntCheckbox>
                  </Col>

                  <Col sm="3">
                      <AntCheckbox checked={this.state.weaveBead} onChange={(e) => { 
                        this.setState({ 
                          weaveBead: e.target.checked,
                        });
                      }}> <FormattedMessage id="WPSWeaveBead" /> </AntCheckbox>
                  </Col>
                
                  <Col sm="3">
                    <AntCheckbox checked={this.state.multiPass} onChange={(e) => { 
                        this.setState({ 
                          multiPass: e.target.checked,
                        });
                      }}> <FormattedMessage id="WPSMultiPass" /> </AntCheckbox>
                  </Col>  
                  <Col sm="3">
                    <AntCheckbox checked={this.state.singlePass} onChange={(e) => { 
                        this.setState({ 
                          singlePass: e.target.checked,
                        });
                      }}> <FormattedMessage id="WPSSinglePass" /> </AntCheckbox>
                  </Col>  
                </Form.Group>
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSNumberOfElectrodes" /> 
                  </Form.Label>
                  <Col sm="4">
                  <Select
                    name="numberOfElectrodes"
                    style={{ width: '100%' }}
                    value={this.state.numberOfElectrodesAck}
                    onChange={this.handleChangeWPSNumberOfElectrodes}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSNumberOfElectrodess}
                            onChange={(event) => { this.setState({ itemWPSNumberOfElectrodess: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSNumberOfElectrodess} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSNumberOfElectrodess.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
              </Form.Group>
               
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSPeening" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.peening}  
                      onChange={(e) =>
                      this.setState({
                        peening: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSInterPassCleaning" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.interPassCleaning}  
                      onChange={(e) =>
                      this.setState({
                        interPassCleaning: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>
              
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSOther" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.other}  
                      onChange={(e) =>
                      this.setState({
                        other: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>
              </Form>
              </div>
          </Panel>
          
          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSBaseMetals" />} key="3"  >
              <div>
              <Form>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSBaseMetalNo" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.baseMetalNo}  
                      onChange={(e) =>
                      this.setState({
                        baseMetalNo: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSMaterialSpecification" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="materialSpecifications"
                  style={{ width: '100%' }}
                  value={this.state.materialSpecificationsAck}
                  onChange={this.handleChangeWPSMaterialSpecifications}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSMaterialSpecifications}
                          onChange={(event) => { this.setState({ itemWPSMaterialSpecifications: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSMaterialSpecifications} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSMaterialSpecifications.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSTypeOrGrade" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="typeOrGrade"
                  style={{ width: '100%' }}
                  value={this.state.typeOrGradeAck}
                  onChange={this.handleChangeWPSTypeOrGrades}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSTypeOrGrades}
                          onChange={(event) => { this.setState({ itemWPSTypeOrGrades: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSTypeOrGrades} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSTypeOrGrades.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSGroupNo" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.groupNo}  
                      onChange={(e) =>
                      this.setState({
                        groupNo: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSTOMNo" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.tOMNo}  
                      onChange={(e) =>
                      this.setState({
                        tOMNo: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSTOGroupNo" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.tOGroupNo}  
                      onChange={(e) =>
                      this.setState({
                        tOGroupNo: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSTOSpecificationTypeAndGrade" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="tOSpecificationTypeAndGrade"
                  style={{ width: '100%' }}
                  value={this.state.tOSpecificationTypeAndGradeAck}
                  onChange={this.handleChangeWPSTOSpecificationTypeAndGrades}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSTOSpecificationTypeAndGrades}
                          onChange={(event) => { this.setState({ itemWPSTOSpecificationTypeAndGrades: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSTOSpecificationTypeAndGrades} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSTOSpecificationTypeAndGrades.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSThicknessRange" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.thicknessRangeFrom}  
                      onChange={(e) =>
                      this.setState({
                        thicknessRangeFrom: e.target.value,
                    }) } />
                  </Col>
                  <Col sm="1">
                    <Form.Label style={{ color: 'black' }} column sm="2">
                        <FormattedMessage id="WPSTo" /> 
                    </Form.Label>
                  </Col>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.thicknessRangeTo}  
                      onChange={(e) =>
                      this.setState({
                        thicknessRangeTo: e.target.value,
                    }) } />
                  </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Col sm="2"></Col>

                  <Form.Label style={{ color: 'black' }} column sm="4">
                      <FormattedMessage id="WPSGroove" /> 
                  </Form.Label>
                  <Col sm="1"></Col>
                  <Form.Label style={{ color: 'black' }} column sm="4">
                      <FormattedMessage id="WPSFillet" /> 
                  </Form.Label>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSOfBaseMetal" /> 
                  </Form.Label>
                  <Col sm="4">
                    <Select
                    name="ofBaseMetalGroove"
                    style={{ width: '100%' }}
                    value={this.state.ofBaseMetalGrooveAck}
                    onChange={this.handleChangeWPSOfBaseMetalGrooves}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSOfBaseMetalGrooves}
                            onChange={(event) => { this.setState({ itemWPSOfBaseMetalGrooves: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSOfBaseMetalGrooves} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSOfBaseMetalGrooves.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="4">
                    <Select
                    name="ofBaseMetalFillet"
                    style={{ width: '100%' }}
                    value={this.state.ofBaseMetalFilletAck}
                    onChange={this.handleChangeWPSOfBaseMetalFillets}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSOfBaseMetalFillets}
                            onChange={(event) => { this.setState({ itemWPSOfBaseMetalFillets: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSOfBaseMetalFillets} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSOfBaseMetalFillets.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
            </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSDepositedMetal" /> 
                  </Form.Label>
                  <Col sm="4">
                    <Select
                    name="depositedMetalGroove"
                    style={{ width: '100%' }}
                    value={this.state.depositedMetalGrooveAck}
                    onChange={this.handleChangeWPSDepositedMetalGrooves}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSDepositedMetalGrooves}
                            onChange={(event) => { this.setState({ itemWPSDepositedMetalGrooves: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSDepositedMetalGrooves} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSDepositedMetalGrooves.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="4">
                    <Select
                    name="depositedMetalFillet"
                    style={{ width: '100%' }}
                    value={this.state.depositedMetalFilletAck}
                    onChange={this.handleChangeWPSDepositedMetalFillets}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSDepositedMetalFillets}
                            onChange={(event) => { this.setState({ itemWPSDepositedMetalFillets: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSDepositedMetalFillets} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSDepositedMetalFillets.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSPipeDiaRange" /> 
                  </Form.Label>
                  <Col sm="4">
                    <Select
                    name="pipeDiaRangeGroove"
                    style={{ width: '100%' }}
                    value={this.state.pipeDiaRangeGrooveAck}
                    onChange={this.handleChangeWPSPipeDiaRangeGrooves}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSPipeDiaRangeGrooves}
                            onChange={(event) => { this.setState({ itemWPSPipeDiaRangeGrooves: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSPipeDiaRangeGrooves} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSPipeDiaRangeGrooves.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="4">
                    <Select
                    name="pipeDiaRangeFillet"
                    style={{ width: '100%' }}
                    value={this.state.pipeDiaRangeFilletAck}
                    onChange={this.handleChangeWPSPipeDiaRangeFillets}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSPipeDiaRangeFillets}
                            onChange={(event) => { this.setState({ itemWPSPipeDiaRangeFillets: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSPipeDiaRangeFillets} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSPipeDiaRangeFillets.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSBaseMetalOther" /> 
                  </Form.Label>
                  <Col sm="4">
                    <Select
                    name="baseMetalOtherGroove"
                    style={{ width: '100%' }}
                    value={this.state.baseMetalOtherGrooveAck}
                    onChange={this.handleChangeWPSBaseMetalOtherGrooves}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSBaseMetalOtherGrooves}
                            onChange={(event) => { this.setState({ itemWPSBaseMetalOtherGrooves: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSBaseMetalOtherGrooves} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSBaseMetalOtherGrooves.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="4">
                    <Select
                    name="baseMetalOtherFillet"
                    style={{ width: '100%' }}
                    value={this.state.baseMetalOtherFilletAck}
                    onChange={this.handleChangeWPSBaseMetalOtherFillets}

                    dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input style={{ flex: 'auto' }} value={this.state.itemWPSBaseMetalOtherFillets}
                            onChange={(event) => { this.setState({ itemWPSBaseMetalOtherFillets: event.target.value }) }} />
                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addWPSBaseMetalOtherFillets} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                        </div>
                    </div>
                    )}>
                    {this.state.WPSBaseMetalOtherFillets.map(i => (
                        <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    </Select>
                  </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSMaximumCarbonEquivalent" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.maximumCarbonEquivalent}  
                      onChange={(e) =>
                      this.setState({
                        maximumCarbonEquivalent: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSBarSize" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.barSize}  
                    onChange={(e) =>
                    this.setState({
                      barSize: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPlateThickness" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.plateThickness}  
                    onChange={(e) =>
                    this.setState({
                      plateThickness: e.target.value,
                  }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSCoatedBar" /> 
                </Form.Label>
                <Col sm="4">
                  <ToggleButton style={{ width: '100%' }} onLabel={ <FormattedMessage id="GeneralTextYes" /> } offLabel={ <FormattedMessage id="GeneralTextNo" /> }
                      checked={this.state.coatedBar} onChange={(e) => this.setState({ coatedBar: e.value })} />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSTypeOfCoating" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="typeOfCoating"
                  style={{ width: '100%' }}
                  value={this.state.typeOfCoatingAck}
                  onChange={this.handleChangeWPSTypeOfCoatings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSTypeOfCoatings}
                          onChange={(event) => { this.setState({ itemWPSTypeOfCoatings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSTypeOfCoatings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSTypeOfCoatings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSFillerMetals" />} key="4"  >
              <div>
              <Form>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSFillerMetalFNo" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.fillerMetalFNo}  
                      onChange={(e) =>
                      this.setState({
                        fillerMetalFNo: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSAWSClassification" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="awsClassification"
                  style={{ width: '100%' }}
                  value={this.state.awsClassificationAck}
                  onChange={this.handleChangeWPSAWSClassifications}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSAWSClassifications}
                          onChange={(event) => { this.setState({ itemWPSAWSClassifications: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSAWSClassifications} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSAWSClassifications.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSAWSSpecification" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="awsSpecification"
                  style={{ width: '100%' }}
                  value={this.state.awsSpecificationAck}
                  onChange={this.handleChangeWPSAWSSpecifications}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSAWSSpecifications}
                          onChange={(event) => { this.setState({ itemWPSAWSSpecifications: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSAWSSpecifications} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSAWSSpecifications.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSWeldMetalAnalysisANo" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.weldMetalAnalysisANo}  
                      onChange={(e) =>
                      this.setState({
                        weldMetalAnalysisANo: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSFillerMetalSize" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.fillerMetalSize}  
                      onChange={(e) =>
                      this.setState({
                        fillerMetalSize: e.target.value,
                    }) } />
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSElectrodeFluxClass" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="electrodeFluxClass"
                  style={{ width: '100%' }}
                  value={this.state.electrodeFluxClassAck}
                  onChange={this.handleChangeWPSElectrodeFluxClasss}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSElectrodeFluxClasss}
                          onChange={(event) => { this.setState({ itemWPSElectrodeFluxClasss: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSElectrodeFluxClasss} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSElectrodeFluxClasss.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                    <Form.Label style={{ color: 'black' }} column sm="2">
                        <FormattedMessage id="WPSFluxTradeName" /> 
                    </Form.Label>
                    <Col sm="4">
                      <InputText style={{ width: '100%' }} value={this.state.fluxTradeName}  
                        onChange={(e) =>
                        this.setState({
                          fluxTradeName: e.target.value,
                      }) } />
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSConsumableInsert" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.consumableInsert}  
                      onChange={(e) =>
                      this.setState({
                        consumableInsert: e.target.value,
                    }) } />
                  </Col>
              </Form.Group>

               <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSOther" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.fillerMetalsOther}  
                      onChange={(e) =>
                      this.setState({
                        fillerMetalsOther: e.target.value,
                    }) } />
                  </Col>
              </Form.Group>
              </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSPositions" />} key="5"  >
              <div>
              <Form>
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPositionsOfGroove" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="positionsOfGroove"
                  style={{ width: '100%' }}
                  value={this.state.positionsOfGrooveAck}
                  onChange={this.handleChangeWPSPositionsOfGrooves}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSPositionsOfGrooves}
                          onChange={(event) => { this.setState({ itemWPSPositionsOfGrooves: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSPositionsOfGrooves} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSPositionsOfGrooves.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPositionsOfFillet" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="positionsOfFillet"
                  style={{ width: '100%' }}
                  value={this.state.positionsOfFilletAck}
                  onChange={this.handleChangeWPSPositionsOfFillets}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSPositionsOfFillets}
                          onChange={(event) => { this.setState({ itemWPSPositionsOfFillets: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSPositionsOfFillets} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSPositionsOfFillets.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSWeldProgression" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="weldProgression"
                  style={{ width: '100%' }}
                  value={this.state.weldProgressionAck}
                  onChange={this.handleChangeWPSWeldProgressions}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSWeldProgressions}
                          onChange={(event) => { this.setState({ itemWPSWeldProgressions: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSWeldProgressions} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSWeldProgressions.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSPreheatInterpass" />} key="6"  >
              <div>
              <Form>
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSPreheatTemperature" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.preheatTemperature}  
                      onChange={(e) =>
                      this.setState({
                        preheatTemperature: e.target.value,
                    }) } />
                  </Col> 
                  <Col sm="1">
                    Min &deg;C
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSInterpassTemperature" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.interpassTemperatureMin}  
                      onChange={(e) =>
                      this.setState({
                        interpassTemperatureMin: e.target.value,
                    }) } />
                  </Col> 
                  <Col sm="1">
                    Min &deg;C
                  </Col>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.interpassTemperatureMax}  
                      onChange={(e) =>
                      this.setState({
                        interpassTemperatureMax: e.target.value,
                    }) } />
                  </Col> 
                  <Col sm="1">
                    Max &deg;C
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSPreheatMaintenance" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.preheatMaintenance}  
                      onChange={(e) =>
                      this.setState({
                        preheatMaintenance: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSContinuousOrSpecialHeatingOrMaintenance" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.heatingOrMaintenance}  
                      onChange={(e) =>
                      this.setState({
                        heatingOrMaintenance: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group> 
              </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSPostweldHeatTreatment" />} key="7"  >
              <div>
              <Form>
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSTemperature" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.temperature}  
                      onChange={(e) =>
                      this.setState({
                        temperature: e.target.value,
                    }) } />
                  </Col> 
                  <Col sm="1">
                    Min &deg;C
                  </Col>
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSTime" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.time}  
                      onChange={(e) =>
                      this.setState({
                        time: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group>
              </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSShielding" />} key="8"  >
              <div>
              <Form>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="3">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSFluxTypeTradeName" /> 
                  </Form.Label>
                  <Col sm="3">
                    <InputText style={{ width: '100%' }} value={this.state.fluxTypeTradeName}  
                      onChange={(e) =>
                      this.setState({
                        fluxTypeTradeName: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSFlux" /> 
                  </Form.Label>
                  <Col sm="3">
                    <ToggleButton style={{ width: '100%' }} onLabel={ <FormattedMessage id="GeneralTextYes" /> } offLabel={ <FormattedMessage id="GeneralTextNo" /> }
                      checked={this.state.flux} onChange={(e) => this.setState({ flux: e.value })} />
                  </Col> 
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSElectroFluxClass" /> 
                  </Form.Label>
                  <Col sm="3">
                    <InputText style={{ width: '100%' }} value={this.state.electroFluxClass}  
                      onChange={(e) =>
                      this.setState({
                        electroFluxClass: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group>
  
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Col sm="2"></Col>

                  <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="WPSShielding" /> 
                  </Form.Label>
                  <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="WPSBacking" /> 
                  </Form.Label>
                  <Form.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="WPSTrailing" /> 
                  </Form.Label>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSGasEsShielding" /> 
                </Form.Label>
                <Col sm="3">
                <Select
                  name="gasEsShielding"
                  style={{ width: '100%' }}
                  value={this.state.gasEsShieldingAck}
                  onChange={this.handleChangeWPSGasEsShieldings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSGasEsShieldings}
                          onChange={(event) => { this.setState({ itemWPSGasEsShieldings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSGasEsShieldings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSGasEsShieldings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                <Col sm="3">
                <Select
                  name="gasEsBacking"
                  style={{ width: '100%' }}
                  value={this.state.gasEsBackingAck}
                  onChange={this.handleChangeWPSGasEsBackings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSGasEsBackings}
                          onChange={(event) => { this.setState({ itemWPSGasEsBackings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSGasEsBackings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSGasEsBackings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                  <Col sm="3">
                  <Select
                  name="gasEsTrailing"
                  style={{ width: '100%' }}
                  value={this.state.gasEsTrailingAck}
                  onChange={this.handleChangeWPSGasEsTrailings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSGasEsTrailings}
                          onChange={(event) => { this.setState({ itemWPSGasEsTrailings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSGasEsTrailings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSGasEsTrailings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
               </Col>
            </Form.Group>
 
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSCompositionShielding" /> 
                </Form.Label>
                <Col sm="3">
                <Select
                  name="compositionShielding"
                  style={{ width: '100%' }}
                  value={this.state.compositionShieldingAck}
                  onChange={this.handleChangeWPSCompositionShieldings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSCompositionShieldings}
                          onChange={(event) => { this.setState({ itemWPSCompositionShieldings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSCompositionShieldings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSCompositionShieldings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                <Col sm="3">
                <Select
                  name="compositionBacking"
                  style={{ width: '100%' }}
                  value={this.state.compositionBackingAck}
                  onChange={this.handleChangeWPSCompositionBackings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSCompositionBackings}
                          onChange={(event) => { this.setState({ itemWPSCompositionBackings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSCompositionBackings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSCompositionBackings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                  <Col sm="3">
                  <Select
                  name="compositionTrailing"
                  style={{ width: '100%' }}
                  value={this.state.compositionTrailingAck}
                  onChange={this.handleChangeWPSCompositionTrailings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSCompositionTrailings}
                          onChange={(event) => { this.setState({ itemWPSCompositionTrailings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSCompositionTrailings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSCompositionTrailings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
               </Col>
            </Form.Group>
 
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSFlowRateShielding" /> 
                </Form.Label>
                <Col sm="3">
                <Select
                  name="flowRateShielding"
                  style={{ width: '100%' }}
                  value={this.state.flowRateShieldingAck}
                  onChange={this.handleChangeWPSFlowRateShieldings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSFlowRateShieldings}
                          onChange={(event) => { this.setState({ itemWPSFlowRateShieldings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSFlowRateShieldings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSFlowRateShieldings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                <Col sm="3">
                <Select
                  name="flowRateBacking"
                  style={{ width: '100%' }}
                  value={this.state.flowRateBackingAck}
                  onChange={this.handleChangeWPSFlowRateBackings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSFlowRateBackings}
                          onChange={(event) => { this.setState({ itemWPSFlowRateBackings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSFlowRateBackings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSFlowRateBackings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                  <Col sm="3">
                  <Select
                  name="flowRateTrailing"
                  style={{ width: '100%' }}
                  value={this.state.flowRateTrailingAck}
                  onChange={this.handleChangeWPSFlowRateTrailings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSFlowRateTrailings}
                          onChange={(event) => { this.setState({ itemWPSFlowRateTrailings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSFlowRateTrailings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSFlowRateTrailings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
               </Col>
            </Form.Group>
 
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSGasCupSizeShielding" /> 
                </Form.Label>
                <Col sm="3">
                <Select
                  name="gasCupSizeShielding"
                  style={{ width: '100%' }}
                  value={this.state.gasCupSizeShieldingAck}
                  onChange={this.handleChangeWPSGasCupSizeShieldings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSGasCupSizeShieldings}
                          onChange={(event) => { this.setState({ itemWPSGasCupSizeShieldings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSGasCupSizeShieldings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSGasCupSizeShieldings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                <Col sm="3">
                <Select
                  name="gasCupSizeBacking"
                  style={{ width: '100%' }}
                  value={this.state.gasCupSizeBackingAck}
                  onChange={this.handleChangeWPSGasCupSizeBackings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSGasCupSizeBackings}
                          onChange={(event) => { this.setState({ itemWPSGasCupSizeBackings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSGasCupSizeBackings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSGasCupSizeBackings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>

                  <Col sm="3">
                  <Select
                  name="gasCupSizeTrailing"
                  style={{ width: '100%' }}
                  value={this.state.gasCupSizeTrailingAck}
                  onChange={this.handleChangeWPSGasCupSizeTrailings}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSGasCupSizeTrailings}
                          onChange={(event) => { this.setState({ itemWPSGasCupSizeTrailings: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSGasCupSizeTrailings} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSGasCupSizeTrailings.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
               </Col>
            </Form.Group>
 
          </Form>
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSElectricalCharacteristics" />} key="9"  >
              <div>
              <Form>
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSGeneralCharacteristics" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.generalCharacteristics}  
                      onChange={(e) =>
                      this.setState({
                        generalCharacteristics: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSCurrentType" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="currentType"
                  style={{ width: '100%' }}
                  value={this.state.currentTypeAck}
                  onChange={this.handleChangeWPSCurrentTypes}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSCurrentTypes}
                          onChange={(event) => { this.setState({ itemWPSCurrentTypes: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSCurrentTypes} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSCurrentTypes.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPulsing" /> 
                </Form.Label>
                <Col sm="4">
                <ToggleButton style={{ width: '100%' }} onLabel={ <FormattedMessage id="GeneralTextYes" /> } offLabel={ <FormattedMessage id="GeneralTextNo" /> }
                      checked={this.state.pulsing} onChange={(e) => this.setState({ pulsing: e.value })} />
                  </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSCurrentPolarity" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="currentPolarity"
                  style={{ width: '100%' }}
                  value={this.state.currentPolarityAck}
                  onChange={this.handleChangeWPSCurrentPolaritys}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSCurrentPolaritys}
                          onChange={(event) => { this.setState({ itemWPSCurrentPolaritys: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSCurrentPolaritys} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSCurrentPolaritys.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSCurrentRange" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.currentRange}  
                      onChange={(e) =>
                      this.setState({
                        currentRange: e.target.value,
                    }) } />
                  </Col> 
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSVoltageRange" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.voltageRange}  
                      onChange={(e) =>
                      this.setState({
                        voltageRange: e.target.value,
                    }) } />
                  </Col> 
                </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSWireFeedSpeedRange" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.wireFeedSpeedRange}  
                    onChange={(e) =>
                    this.setState({
                      wireFeedSpeedRange: e.target.value,
                  }) } />
                </Col> 
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSTungstenElectrodeSize" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.tungstenElectrodeSize}  
                    onChange={(e) =>
                    this.setState({
                      tungstenElectrodeSize: e.target.value,
                  }) } />
                </Col> 
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSTungstenElectrodeType" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="tungstenElectrodeType"
                  style={{ width: '100%' }}
                  value={this.state.tungstenElectrodeTypeAck}
                  onChange={this.handleChangeWPSTungstenElectrodeTypes}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSTungstenElectrodeTypes}
                          onChange={(event) => { this.setState({ itemWPSTungstenElectrodeTypes: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSTungstenElectrodeTypes} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSTungstenElectrodeTypes.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
               
                </Col>
              </Form.Group>
                
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSPulsingParameters" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText style={{ width: '100%' }} value={this.state.pulsingParameters}  
                    onChange={(e) =>
                    this.setState({
                      pulsingParameters: e.target.value,
                  }) } />
                </Col> 
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSOther" /> 
                </Form.Label>
                <Col sm="4">
                <ToggleButton style={{ width: '100%' }} onLabel={ <FormattedMessage id="GeneralTextYes" /> } offLabel={ <FormattedMessage id="GeneralTextNo" /> }
                      checked={this.state.electricalcharOther} onChange={(e) => this.setState({ electricalcharOther: e.value })} />
                </Col> 
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSTransferModeGMAW" /> 
                </Form.Label>
                <Col sm="3">
                  <AntCheckbox checked={this.state.shortCirquiting} onChange={(e) => { 
                        this.setState({ 
                          shortCirquiting: e.target.checked,
                        });
                    }}> <FormattedMessage id="WPSShortCirquiting" /> </AntCheckbox>
                 </Col> 
                 <Col sm="3">
                 <AntCheckbox checked={this.state.globular} onChange={(e) => { 
                        this.setState({ 
                          globular: e.target.checked,
                        });
                    }}> <FormattedMessage id="WPSGlobular" /> </AntCheckbox>
                </Col> 

                <Col sm="3">
                <AntCheckbox checked={this.state.spray} onChange={(e) => { 
                        this.setState({ 
                          spray: e.target.checked,
                        });
                    }}> <FormattedMessage id="WPSSpray" /> </AntCheckbox>
                </Col> 
              </Form.Group>
              </Form>
          
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSOtherVariables" />} key="10"  >
              <div>
              <Form>
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSRevNo" /> 
                </Form.Label>
                <Col sm="4">
                  <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}  
                        onChange={(e) =>
                        this.setState({
                          revno: e.target.value,
                    }) } />
                </Col>
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSCupOrNozzleSize" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.cupOrNozzleSize}  
                      onChange={(e) =>
                      this.setState({
                        cupOrNozzleSize: e.target.value,
                    }) } />
                  </Col>  
                  <Col sm="3">
                      <AntCheckbox checked={this.state.colletBody} onChange={(e) => { 
                        this.setState({ 
                          colletBody: e.target.checked,
                        });
                    }}> <FormattedMessage id="WPSColletBody" /> </AntCheckbox>
                  </Col> 
                  <Col sm="3">
                      <AntCheckbox checked={this.state.gasLens} onChange={(e) => { 
                        this.setState({ 
                          gasLens: e.target.checked,
                        });
                    }}> <FormattedMessage id="WPSGasLens" /> </AntCheckbox>
                  </Col> 
                </Form.Group>
 
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSCleaningMethod" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.cleaningMethod}  
                      onChange={(e) =>
                      this.setState({
                        cleaningMethod: e.target.value,
                    }) } />
                  </Col>  
              </Form.Group>

              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="WPSOtherVariablesNumberOfElectrodes" /> 
                </Form.Label>
                <Col sm="4">
                <Select
                  name="otherVariablesNumberOfElectrodes"
                  style={{ width: '100%' }}
                  value={this.state.otherVariablesNumberOfElectrodesAck}
                  onChange={this.handleChangeWPSOtherVariablesNumberOfElectrodess}

                  dropdownRender={menu => (
                  <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input style={{ flex: 'auto' }} value={this.state.itemWPSOtherVariablesNumberOfElectrodess}
                          onChange={(event) => { this.setState({ itemWPSOtherVariablesNumberOfElectrodess: event.target.value }) }} />
                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                          onClick={this.addWPSOtherVariablesNumberOfElectrodess} >
                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                      </div>
                  </div>
                  )}>
                  {this.state.WPSOtherVariablesNumberOfElectrodess.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                  ))}
                  </Select>
                </Col>
            </Form.Group>
              
              <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="WPSOther" /> 
                  </Form.Label>
                  <Col sm="4">
                    <InputText style={{ width: '100%' }} value={this.state.othervariablesOther}  
                      onChange={(e) =>
                      this.setState({
                        othervariablesOther: e.target.value,
                    }) } />
                  </Col>  
                </Form.Group>
              </Form>
          
              </div>
          </Panel>

          <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="WPSWeldingParameters" />} key="11"  >
              <div>
              <Form>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}></Col>
                  <Col xs={3}><Button style={{ width: '100%' }} >{this.state.createNewWeldLayersButton}</Button></Col>
                </Row>
                <Table responsive striped bordered hover>
                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th rowSpan={2}>Weld Layers</th>
                      <th rowSpan={2}>Process</th>
                      <th colSpan={2}>Filler Metal</th>
                      <th colSpan={3}>Current</th>
                      <th rowSpan={2}>Travel Speed Range</th>
                      <th rowSpan={2}>Joint Detail</th>
                    </tr>
                    <tr>
                      <th>Class</th>
                      <th>Diameter</th>
                      <th>Type & Polarity</th>
                      <th>Current Range</th>
                      <th>Voltage Range</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    <tr>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                      <td>7</td>
                      <td>8</td>
                      <td>9</td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}></Col>
                  <Col xs={3}><Button style={{ width: '100%' }} >{this.state.createNewPassNumbersButton}</Button></Col>
                </Row>
                <Table responsive striped bordered hover>
                  <thead style={{ textAlign: 'center' }}>
                    <tr>
                      <th rowSpan={2}>Pass Number(s)</th>
                      <th rowSpan={2}>Electrode Diameter</th>
                      <th colSpan={5}>Current</th>
                      <th rowSpan={2}>Travel Speed ipm (mm/min)</th>
                      <th rowSpan={2}>Joint Detail</th>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <th>Amperage Range</th>
                      <th>Volts Range</th>
                      <th>Electrical Stickout</th>
                      <th>.......</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: 'center' }}>
                    <tr>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                      <td>7</td>
                      <td>8</td>
                      <td>9</td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
              </div>
          </Panel>
        </Collapse>
          <br />

          <div hidden={this.state.hiddenSave}>
              <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                <Col sm="12"> 
                    <Button onClick={this.addWPS} 
                    style={{ width: '100%' }}  
                    variant="success"><FormattedMessage id="GeneralButtonSave" /></Button>
                </Col> 
              </Form.Group>
          </div>

          <div hidden={this.state.hiddenUpdate}>
              <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                  <Col sm="2"> 
                  </Col>
                  <Col sm="4">
                    <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" /></Button>
                  </Col>
                  <Col sm="4">
                    <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" /></Button>
                  </Col>
                  <Col sm="2"> 
                  </Col>
              </Row>
              </Container>
          </div>
       
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Wps)
