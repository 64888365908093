import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import ViewListTwoTone from '@material-ui/icons/ViewListTwoTone'; 
import { Divider} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ShowIcon from '@material-ui/icons/ShopTwo';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { FormattedMessage } from "react-intl";

export default ({ row, onIDocumentRow, onIInspectionRow, onEDocumentRow, onEInspectionRow, onDeleteRow, onEditRow, editKnt, deleteKnt, iinspectionKnt, idocumentKnt, einspectionKnt, edocumentKnt, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const idocumentRow = () => {
    if (onIDocumentRow) {
      onIDocumentRow(row);
      setAnchorEl(null);
    }
  };

  const edocumentRow = () => {
    if (onEDocumentRow) {
      onEDocumentRow(row);
      setAnchorEl(null);
    }
  };

  const iinspectionRow = () => {
    if (onIInspectionRow) {
      onIInspectionRow(row);
      setAnchorEl(null);
    }
  };

  const einspectionRow = () => {
    if (onEInspectionRow) {
      onEInspectionRow(row);
      setAnchorEl(null);
    }
  };


  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}        
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

       {iinspectionKnt && <MenuItem onClick={iinspectionRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <ShowIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            {<FormattedMessage id="CMMIRIInspection" defaultMessage="I-Inspection" />}
          </Typography>
        </MenuItem>}

      {idocumentKnt && <Divider /> }

      {idocumentKnt &&  <MenuItem onClick={idocumentRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <ViewListTwoTone fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">
            {<FormattedMessage id="CMMIRIDocuments" defaultMessage="I-Documents" />}
          </Typography>
        </MenuItem>}

        {einspectionKnt && <Divider /> }

        {einspectionKnt && <MenuItem onClick={einspectionRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <ShowIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            {<FormattedMessage id="CMMIRIEnspection" defaultMessage="E-Inspection" />}
          </Typography>
        </MenuItem>}

      {edocumentKnt &&  <Divider /> }

        {edocumentKnt &&  <MenuItem onClick={edocumentRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <ViewListTwoTone fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">
            {<FormattedMessage id="CMMIREDocuments" defaultMessage="E-Documents" />}
          </Typography>
        </MenuItem>}   
        
      {deleteKnt && <Divider /> }

      {deleteKnt && <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuDelete" defaultMessage="Delete"/>
          </Typography>
        </MenuItem>}

      {editKnt && <Divider />} 

      {editKnt && <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuEdit" defaultMessage="Edit"/>
          </Typography>
        </MenuItem>}
      </Menu>
    </div>
  );
};