import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from "react-intl";
import EditIcon from '@material-ui/icons/Edit';

export default ({ row, onEditRow, size, editBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
      size={size}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="menu"
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose} >

      <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
          <FormattedMessage id="CustomMaterialMenuEdit" defaultMessage="Edit"/>
          </Typography>
        </MenuItem>

    </Menu>
  </div>

  );
};