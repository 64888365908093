/* eslint-disable */

import React, { Component } from 'react' 
import { connect } from 'react-redux'; 
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot , Col, Modal, Row } from 'react-bootstrap'; 
import { Form, Input, Select, Divider, Upload, Button as AntdButton, Table, Cascader, DatePicker, Radio, Avatar} from 'antd';
import { Button as PrimeButton } from "primereact/button";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column'; 
import CustomMaterialMenuInspectionResult from '../settings/ForComponents/CustomMaterialMenuInspectionResult';  
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import { PlusOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons'; 
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
  
const columnsReceiving1 = [ 
  {
    dataField: 'materialIdentification.materialTypeName',
    text: <FormattedMessage id="INRReceiving1Type" defaultMessage="Type" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: false,
  }, 
  {
    dataField: 'materialIdentification.materialName',
    text: <FormattedMessage id="INRReceiving1Description" defaultMessage="Description" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: false,
  }, 
  {
    dataField: 'materialIdentification.materialCode',
    text: <FormattedMessage id="INRReceiving1Code" defaultMessage="Code" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: false,
  }, 
  {
    dataField: 'quantity',
    text: <FormattedMessage id="INRReceiving1RequiredQuantity" defaultMessage="Required Quantity" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: false,
  }, 
  {
    dataField: 'unit',
    text: <FormattedMessage id="INRReceiving1Unit" defaultMessage="Unit" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: false,
  }, 
  {
    dataField: 'receivedQuantity',
    text: <FormattedMessage id="INRReceiving1ReceivedQuantity" defaultMessage="Received Quantity" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: true,
  }, 
  {
    dataField: 'acceptedQuantity',
    text: <FormattedMessage id="INRReceiving1AcceptedQuantity" defaultMessage="Accepted Quantity" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: true,
  }, 
  {
    dataField: 'remarks',
    text: <FormattedMessage id="INRReceiving1Remarks" defaultMessage="Remarks" />,
    headerStyle: {
      fontWeight: 'bold',
      textAlign: 'center'
    },
    editable: true,
  }
];

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.value, lineage: node.data.lineage });
        if (node.children) {  
            generateList(node.children); 
        }
    }
};
   
const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

 class InspectionResult extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
      modalInspectionResult: false,
      hiddenSave: false,
      hiddenDataGiris:true,
      hiddenUpdate: true,
      selectedRowVal:null,
      selectedRowVal2:null,
      selectDocument:null,
      passVariant:"outline-success",failVariant:"outline-danger", naVariant:"outline-primary",
      datatableValue:[],
      documentHidden:true,
      remarkHidden:true,
      selectVal:null,
      remarksVal:"",
      selectItemNo:[],
      headerTextModal:"...", 

      documentTableDataInternal:[],
      documentTableDataExternal:[],
      nodes: [],
      pdfFilesInternal:[],
      pdfFilesExternal:[],
      inspectionResultTable:[],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      departments: [],
      inspectionDateTime: null,
      inspectionDate: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      inspectionResultId:0,
      activityId:0,
      INRClientInspectors:[],
      INRDesignations:[],
      PhotoFileUpload:[],
      photoPath:"",
      AttachmentFileUpload:[],
      attachmentPath:"",
      fileList: [],

      FileInvoiceNo:[], 
      invoiceNoPath:"",
      FilePO:[], 
      POPath:"",
      FileDeliveryNoteNo:[], 
      deliveryNoteNoPath:"",
      FilePackingList:[], 
      packingListPath:"",
      FileTestReport:[], 
      testReportPath:"",
      FileManufacturerManual:[], 
      manufacturerManualPath:"",
      FilepackingOfNotGoods:[], 
      packingOfGoodsNotPath:"",
      FileOther:[], 
      otherPath:"",
      addInspectionResult:[],
      selectedReceiving1: [],
      selectedReceiving1Row:[],
      purchaseOrderMaterial:[],
      purchaseOrderNos:[],
      receivingInspection:false,
      inspectionPOMaterial: [],

      cliUpdateList:[],
    };

    // this.onselectChangeVal = this.onselectChangeVal.bind(this);   
    this.onselectChangeVal2 = this.onselectChangeVal2.bind(this); 
    this.onselectChangeDocument = this.onselectChangeDocument.bind(this); 
    this.renderStatus = this.renderStatus.bind(this);      
    this.renderPhoto = this.renderPhoto.bind(this);     
    this.renderAttachment = this.renderAttachment.bind(this);   
    this.placeTemplate = this.placeTemplate.bind(this);
    this.actionTemplateInspection = this.actionTemplateInspection.bind(this);  
  }

  formRef = React.createRef();

  componentDidMount = async () => { 
    var Warehouse=false;
    if(this.props.auth.user.staff!=null && this.props.auth.user.staff!=undefined){
      if(this.props.auth.user.staff.department!=null && this.props.auth.user.staff.department!=undefined){
        if(this.props.auth.user.staff.department.departmentName!=null && this.props.auth.user.staff.department.departmentName!=undefined && this.props.auth.user.staff.department.departmentName=="Warehouse"){
          Warehouse=true;
        }
      } 
    }
    this.setState({
      Warehouse: Warehouse,
    });

    var today = new Date();
    var todayDate;
    var HH = String(today.getHours()).padStart(2, '0');
    var minute = String(today.getMinutes()).padStart(2, '0');
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
    todayDate = dd + '/' + mm + '/' + yyyy;

    const response = await handleRequest("GET", "/Department/getAll");
    if (response.data.length !== 0) {
        this.setState({
          departments: response.data,
          inspectionDateTime: today,
          inspectionDate: todayDate
        });
    }else{
      this.setState({
        departments: [],
        inspectionDateTime: today,
        inspectionDate: todayDate
      });
    }

    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InspectionResult" });
    if (responseDP.data.length > 0) {
        this.setState({
            dynamicAttributes: responseDP.data
        });
    } else {
        this.setState({
            dynamicAttributes: []
        });
    }

    const obj = {
        pageName: "InspectionResult",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
        this.setState({
            dynamicInputs: responseDynamicInputs.data,
        });
    } else {
        this.setState({
            dynamicInputs: [],
        });
    }
    
    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InspectionResult");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
      
    try {
        var discipline=-1;
        var disciplineName="";
        if(this.props.auth.user.staff.discipline.disciplineName){
          discipline=this.props.auth.user.staff.discipline.id;
          disciplineName=this.props.auth.user.staff.discipline.disciplineName;
        }

        this.setState({
          discipline:discipline,
          disciplineName:disciplineName,
          inspectionDateTime: today,
        });

      this.formRef.current.setFieldsValue({
        Discipline: disciplineName,
        InspectionDateTime: moment(today, 'DD/MM/YYYY HH:mm')
    });

    } catch (error) {
      this.setState({
        discipline:discipline,
        disciplineName:disciplineName,
        inspectionDateTime: today,
      });
    }  
    
    const responseOrder = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");
    if (responseOrder.data.length > 0) {
      this.setState({
        purchaseOrderNos: responseOrder.data
      });
    } else {
      this.setState({
        purchaseOrderNos: []
      });
    }

    this.getActivityLog();
    this.restartTable();
    this.getTableDynamicInputsProperties();
    this.getCodesINRDesignation();
    this.getCodesINRClientInspector();
  } 

  getTableDynamicInputsProperties = async () => {
    this.inspectionResult = JSON.parse(localStorage.getItem('inspectionResult'));
    if (this.inspectionResult != null && this.inspectionResult != undefined && this.inspectionResult != "") {

        await this.setState({
            bordered: this.inspectionResult.bordered,
            title: this.inspectionResult.titleDrm == true ? title : undefined,
            titleDrm: this.inspectionResult.titleDrm,
            showHeader: this.inspectionResult.showHeader,
            footer: this.inspectionResult.footerDrm == true ? footer : undefined,
            footerDrm: this.inspectionResult.footerDrm,
            expandable: this.inspectionResult.expandableDrm == true ? expandable : {},
            expandableDrm: this.inspectionResult.expandableDrm,
            rowSelection: this.inspectionResult.rowSelection,
            ellipsis: this.inspectionResult.ellipsis,
            tableLayout: this.inspectionResult.tableLayout,
            size: this.inspectionResult.size,
            top: this.inspectionResult.top,
            bottom: this.inspectionResult.bottom,
            editBgColor: this.inspectionResult.editBgColor,
            deleteBgColor: this.inspectionResult.deleteBgColor,
            selectedNodeKey: null,
            yScroll: this.inspectionResult.yScroll,
            xScroll: this.inspectionResult.xScroll,
            paginationDisabled: {
                defaultPageSize: 5, total: this.state.inspectionResultTable.length,
                showQuickJumper: true, showSizeChanger: true,
                pageSizeOptions: ['5', '10', '25', '50'], total: this.state.inspectionResultTable.length, showQuickJumper: true,
                position: [this.inspectionResult.top, this.inspectionResult.bottom]
            },
            kontrol: true,
        });
    } else {
        await this.setState({
            bordered: false,
            title: undefined,
            titleDrm: false,
            showHeader: true,
            footer: undefined,
            footerDrm: false,
            expandable: false,
            expandableDrm: false,
            rowSelection: undefined,
            ellipsis: false,
            tableLayout: undefined,
            size: 'default',
            top: 'none',
            bottom: 'bottomRight',
            editBgColor: '#fff',
            deleteBgColor: '#fff',
            selectedNodeKey: null,
            xScroll: undefined,
            paginationDisabled: false,
            kontrol: true,
        });
      }
  }
 
  getCheckListLog = async () => { 
    const response = await handleRequest("GET", "/CheckListLog/getAll");    
     if (response.data.length !== 0)
      {  
        this.setState({ datatableValue:response.data }); 
      }  else { this.setState({ datatableValue: []  });} 
  } 

  getActivityLog = async () => { 
    var newObj={};
    if(this.state.Warehouse==false){
      newObj = {
        disciplineId:this.state.discipline
      }  
    }else{
      newObj = {
        departmentWarehouse:true,
      }  
    }
    const response = await handleRequest("POST", "/WFActivity/getNodesResult", newObj);
    if (response.data.length !== 0) {
      generateList(response.data); 
      this.setState({
        nodes: response.data
      });
    }else{
      this.setState({
        nodes: []
      });
    }

    // }else{
    //   var nodes=[];
    //   nodes.push({children:[], value:"-1", label:"Receiving Inspection", key:"A.-1", data:[]});
    //   this.setState({
    //     nodes: nodes
    //   });
    // }
  } 

  onselectChangeVal = async (event) => {
    if(event.value!=null){
      const newObj = {
        activityId:event.value.id
      }  

      const responseTree = await handleRequest("POST", "/CheckListLog/getItpChecklistItemsActivity", newObj);
      if (responseTree.data.selected.length !== 0) {
        this.setState({
          selectedRowVal: event.value,
          datatableValueItems: responseTree.data.selected
        });
      }else{
        this.setState({
          selectedRowVal: event.value,
          datatableValueItems:[]
        });
      }

      var response = [];
      var item = { activityId: event.value.id };
      response = await handleRequest("POST", "/WFResources/getDocuments", item);
      if (response.length === 0 || response.data.length === 0) {
        this.setState({
          documentTableData: []
        });
      } else if (response.data.length !== 0) {
          this.setState({
            documentTableData: response.data
          });
      }
    }else{
      this.setState({
        selectedRowVal: event.value,
        datatableValueItems:[],
        documentTableData: []
      });
    }
    this.setState({selectedRowVal2: null});
  } 
  
  onselectChangeVal2(event) {
    if(this.state.internal){
      if(!(event.value.status!=null && event.value.status!=undefined && event.value.status!="")){
        var dt=[]; 
        dt.push(event.value);
        if(dt.length>0){ 
          this.setState({  
            selectItemNo: dt,
            modalPassFailed: true, 
            //modalInspectionResult: false,
            passVariant:"outline-success",
            failVariant:"outline-danger",
            naVariant:"outline-primary" ,
            documentHidden:true,
            remarkHidden:true,
            PhotoFileUpload:[], 
            photoPath:"",
            AttachmentFileUpload:[], 
            attachmentPath:"",
            remarksVal:"",
          });
          this.getHeaderText(dt[dt.length-1].itpcheckid);
        } 
      } 
    } else if(this.state.external){ 
      if(!(event.value.statusExternal!=null && event.value.statusExternal!=undefined && event.value.statusExternal!="")){
        var dt=[]; 
        dt.push(event.value);
        if(dt.length>0){ 
          this.setState({  
            selectItemNo: dt,
            modalPassFailed: true, 
            //modalInspectionResult: false,
            passVariant:"outline-success",
            failVariant:"outline-danger",
            naVariant:"outline-primary" ,
            documentHidden:true,
            remarkHidden:true,
            PhotoFileUpload:[], 
            photoPath:"",
            AttachmentFileUpload:[], 
            attachmentPath:"",
            remarksVal:"",
          });
          this.getHeaderText(dt[dt.length-1].itpcheckid);
        } 
      } 
    } 
  }
 
  onselectChangeDocument(event) {
    var deger=[];
    event.value.forEach(element => {
      if(!(element.status!=null && element.status!=undefined && element.status!="")){
        deger.push(element);
      }
    });
    this.setState({selectDocument: deger});
  }
  
  modalAcceptInspectionResult = async () => {
    var knt=true;
    if(this.state.datatableValueItems!=null && this.state.datatableValueItems!=undefined && this.state.datatableValueItems.length>0){
      if(this.state.internal){
        this.state.datatableValueItems.forEach(element => {
          if(!(element.status!=null && element.status!=undefined && element.status!="")){
            knt=false;
          }
        });
      }else if(this.state.external){
        this.state.datatableValueItems.forEach(element => {
          if(!(element.statusExternal!=null && element.statusExternal!=undefined && element.statusExternal!="")){
            knt=false;
          }
        });
      }else{
        knt=false;
      }
    }else{
      knt=false;
    }

    if(knt){
      if(this.state.cliUpdateList!=null && this.state.cliUpdateList!=undefined && this.state.cliUpdateList.length>0){
        const response = await handleRequest("POST", "/CheckListLog/cliUpdateList", this.state.cliUpdateList);
        if (response.data !== null && response.data !== undefined) {
          if (response.data == "SUCCESS") {
            this.showSuccess();
            this.showOrHideModalInspectionResult();
            this.restartTable();
          }
          else {
            this.showError();
          } 
        } 
        else {
          this.showError();  
        }
      }else{
        this.showWarning();
      }
    }else{
      this.showWarning();
    }  
  }

  getHeaderText = async (id) => { 
    const newObj = {
      id:id
    }  
    const response = await handleRequest("POST", "/CheckListLog/getAll2", newObj);
    if (response.data.length !== 0) {
      this.setState({  headerTextModal: response.data[0].iclistno + "/" + response.data[0].iclist })
    }else{
      this.setState({  headerTextModal: ""})
    }
  } 

  saveItemNo = () => {
    const {selectItemNo}= this.state;
    const {selectVal}= this.state;
    const {remarksVal}= this.state;
    var dt=selectItemNo[selectItemNo.length-1];
    if(selectVal!=null&&selectVal.length>0)
    {
      //işlem yap
      if(this.state.internal){
        dt.status=selectVal;
        dt.remarks=remarksVal;
        dt.photoPathInternal=this.state.photoPath;
        dt.attachmentPathInternal=this.state.attachmentPath;
      }
      else if(this.state.external){
        dt.statusExternal=selectVal;
        dt.remarksExternal=remarksVal;
        dt.photoPathExternal=this.state.photoPath;
        dt.attachmentPathExternal=this.state.attachmentPath;
      }
      dt.inspectionResultId=this.state.inspectionResultId;
      dt.activityId=this.state.activityId;

      this.setState(prevState => ({
        cliUpdateList: [...prevState.cliUpdateList, dt]
      }))

      var ChcItem = this.state.cliUpdateList.filter(p => p.id == dt.id);
      if(this.state.internal){
        ChcItem.forEach(element => {
          element.status=dt.status;
          element.remarks=dt.remarks;
          element.photoPathInternal=dt.photoPathInternal;
          element.attachmentPathInternal=dt.attachmentPathInternal;
          element.inspectionResultId=dt.inspectionResultId;
          element.activityId=dt.activityId;
        }); 
      }
      else if(this.state.external){
        ChcItem.forEach(element => {
          element.statusExternal=dt.statusExternal;
          element.remarksExternal=dt.remarksExternal;
          element.photoPathExternal=dt.photoPathExternal;
          element.attachmentPathExternal=dt.attachmentPathExternal;
          element.activityId=dt.activityId;
        }); 
      }
      this.setState({ 
        modalPassFailed: false
      })
    }
    else {
      this.showError("Please Select items");
    }
  }

  inpectionClick = async (event) => {
    if(event!=null){
      var internal=false;
      var external=false;
      if(event.inspectionResult=="Approved"){
        external=true;
      }else{
        internal=true;
      }
      
      if(external && (event.designation==null ||event.designation==undefined || event.designation=="" || event.designation==0)){
        this.setState({
            modalEInspection: true,
            selectedInspectionResult: event,
          });
      }else{
        var activityId=-1;
        if(event.activity!=null && event.activity!=undefined && event.activity!=0){
          activityId=event.activity;
        } 
        const newObj = {
          activityId:activityId
        }  
        const responseTree = await handleRequest("POST", "/CheckListLog/getItpChecklistItemsActivity", newObj);
        if (responseTree.data.selected.length !== 0) {
          this.setState({
            selectedRowVal: event,
            datatableValueItems: responseTree.data.selected
          });
        }else{
          this.setState({
            selectedRowVal: event,
            datatableValueItems:[]
          });
        }
  
        var response = [];
        var item = { activityId: activityId };
        response = await handleRequest("POST", "/WFResources/getDocuments", item);
        if (response.length === 0 || response.data.length === 0) {
          this.setState({
            documentTableData: []
          });
        } else if (response.data.length !== 0) {
          this.setState({
            documentTableData: response.data
          });
        }

        const {selectedRowVal}= this.state; 
        if(selectedRowVal!=null){
          await this.setState({ 
            modalInspectionResult: true,
            selectedRowVal2: null,
            inspectionResultId:event.id,
            activityId:activityId,
            internal:internal,
            external:external
          }) 
        }else{
          await this.setState({ 
            inspectionResultId:0,
            activityId:0,
            internal:false,
            external:false
          }) 
        }
      }

    this.setState({
      cliUpdateList:[],
    })
      
    }else{
      this.setState({
        selectedRowVal: event,
        datatableValueItems:[],
        documentTableData: []
      });
    } 
  }

  documentClickInternal = async (event) => {
    if(event!=null){
      var indexInternal=1;
   
      var activityId=-1;
      if(event.activity!=null && event.activity!=undefined && event.activity!=0){
        activityId=event.activity;
      }
      const newObj = {
        activityId:activityId
      }  
      const responseTree = await handleRequest("POST", "/CheckListLog/getItpChecklistItemsActivity", newObj);
      if (responseTree.data.selected.length !== 0) {
        var diziInternal=[];
        var diziExternal=[]; 
        var pdfFilesInternal=[];
        var pdfFilesExternal=[];

        if(Boolean(event.activitys) && Boolean(event.activitys.documents)){
          event.activitys.documents.forEach(element => {  
            if(Boolean(element.documentLog) && diziInternal.findIndex(p=> p.documentId===element.documentLog.id)===-1){
              let obj={key:"D" + element.documentLog.id, index:indexInternal++, documentPath: element.documentLog.documentPdfPath, 
              documentId:element.documentLog.id, documentCode:element.documentLog.code, documentDescription:element.documentLog.description}
              diziInternal.push(obj);
            }
          });
        }
      
        var photoIndex=1;
        var attachmentIndex=1;
        responseTree.data.selected.forEach(element => { 
          if((element.remarks!=null && element.remarks!=undefined && element.remarks!= "") ||
            (element.photoPathInternal!=null && element.photoPathInternal!=undefined && element.photoPathInternal!= "") ||
            (element.attachmentPathInternal!=null && element.attachmentPathInternal!=undefined && element.attachmentPathInternal!= "") 
            ){

              if(element.photoPathInternal!=null && element.photoPathInternal!=undefined && element.photoPathInternal!= ""){
                let element1=Object.assign({}, element); 

                element1.index=indexInternal++;
                element1.documentPath=element.photoPathInternal;
                element1.documentCode="Photo-" + photoIndex++;
                element1.documentDescription= "";

                diziInternal.push(element1); 
              }

              if(element.attachmentPathInternal!=null && element.attachmentPathInternal!=undefined && element.attachmentPathInternal!= ""){
                let element2=Object.assign({}, element); 
               
                element2.index=indexInternal++;
                element2.documentPath=element.attachmentPathInternal;
                element2.documentCode="Attachment-" + attachmentIndex++;
                element2.documentDescription= "";

                diziInternal.push(element2); 
              }

              if(element.attachmentPathInternal!=null && element.attachmentPathInternal!=undefined && element.attachmentPathInternal!= ""){
                if (element.attachmentPathInternal.endsWith('.pdf')) {
                  pdfFilesInternal.push({title:element.iclistnoRevno, source:element.attachmentPathInternal});
                }
              }
          }

          // if((element.remarksExternal!=null && element.remarksExternal!=undefined && element.remarksExternal!="") ||
          //   (element.photoPathExternal!=null && element.photoPathExternal!=undefined && element.photoPathExternal!= "") ||
          //   (element.attachmentPathExternal!=null && element.attachmentPathExternal!=undefined && element.attachmentPathExternal!= "") 
          //   ){
          //     element.index=indexExternal;
          //     diziExternal.push(element);
          //     indexExternal++;

          //     if(element.attachmentPathExternal!=null && element.attachmentPathExternal!=undefined && element.attachmentPathExternal!= ""){
          //       if (element.attachmentPathExternal.endsWith('.pdf')) {
          //         pdfFilesExternal.push({title:element.iclistnoRevno, source:element.attachmentPathExternal});
          //       }
          //     }
          //   }
        });

        this.setState({
          selectedRowVal: event,
          datatableValueItems: responseTree.data.selected,
          documentTableDataInternal: diziInternal,
          documentTableDataExternal: diziExternal,
          internal:true,
          external:false,
          pdfFilesInternal:pdfFilesInternal,
          pdfFilesExternal:pdfFilesExternal,
        });

      }else{
        this.setState({
          selectedRowVal: event,
          datatableValueItems:[],
          documentTableDataInternal:[],
          documentTableDataExternal:[],
          internal:false,
          external:false,
          pdfFilesInternal:[],
          pdfFilesExternal:[],
        });
      }
    }else{
      this.setState({
        selectedRowVal: event,
        datatableValueItems:[],
        documentTableDataInternal:[],
        documentTableDataExternal:[],
        internal:false,
        external:false,
        pdfFilesInternal:[],
        pdfFilesExternal:[],
      });
    }
    this.setState({selectedRowVal2: null});

    const {selectedRowVal}= this.state; 
    if(selectedRowVal!=null)  
    this.setState({ 
      modalShowDocument: true,
      remarksVal:"",
      PhotoFileUpload:[], 
      photoPath:"",
      AttachmentFileUpload:[], 
      attachmentPath:"",
    }) 
  }

  documentClickExternal = async (event) => {
    if(event!=null){
      var activityId=-1;
      if(event.activity!=null && event.activity!=undefined && event.activity!=0){
        activityId=event.activity;
      }
      const newObj = {
        activityId:activityId
      }  
      const responseTree = await handleRequest("POST", "/CheckListLog/getItpChecklistItemsActivity", newObj);
      if (responseTree.data.selected.length !== 0) {

        var diziInternal=[];
        var diziExternal=[]; 
        var indexExternal=1;
        var pdfFilesInternal=[];
        var pdfFilesExternal=[];


        if(Boolean(event.activitys) && Boolean(event.activitys.documents)){
          event.activitys.documents.forEach(element => {  
            if(Boolean(element.documentLog) && diziExternal.findIndex(p=> p.documentId===element.documentLog.id)===-1){
              let obj={key:"D" + element.documentLog.id, index:indexExternal++, documentPath: element.documentLog.documentPdfPath, 
              documentId:element.documentLog.id, documentCode:element.documentLog.code, documentDescription:element.documentLog.description}
              diziExternal.push(obj);
            }
          });
        }

        var photoIndex=1;
        var attachmentIndex=1;
        responseTree.data.selected.forEach(element => {
          // if((element.remarks!=null && element.remarks!=undefined && element.remarks!= "") ||
          //   (element.photoPathInternal!=null && element.photoPathInternal!=undefined && element.photoPathInternal!= "") ||
          //   (element.attachmentPathInternal!=null && element.attachmentPathInternal!=undefined && element.attachmentPathInternal!= "") 
          //   ){
          //     element.index=indexInternal;
          //     diziInternal.push(element);
          //     indexInternal++;

          //     if(element.attachmentPathInternal!=null && element.attachmentPathInternal!=undefined && element.attachmentPathInternal!= ""){
          //       if (element.attachmentPathInternal.endsWith('.pdf')) {
          //         pdfFilesInternal.push({title:element.iclistnoRevno, source:element.attachmentPathInternal});
          //       }
          //   }
          // }

          if((element.remarksExternal!=null && element.remarksExternal!=undefined && element.remarksExternal!="") ||
            (element.photoPathExternal!=null && element.photoPathExternal!=undefined && element.photoPathExternal!= "") ||
            (element.attachmentPathExternal!=null && element.attachmentPathExternal!=undefined && element.attachmentPathExternal!= "") 
            ){

              if(element.photoPathExternal!=null && element.photoPathExternal!=undefined && element.photoPathExternal!= ""){
                let element1=Object.assign({}, element); 

                element1.index=indexExternal++;
                element1.documentPath=element.photoPathExternal;
                element1.documentCode="Photo-" + photoIndex++;
                element1.documentDescription= "";

                diziExternal.push(element1); 
              }

              if(element.attachmentPathExternal!=null && element.attachmentPathExternal!=undefined && element.attachmentPathExternal!= ""){
                let element2=Object.assign({}, element); 
               
                element2.index=indexExternal++;
                element2.documentPath=element.attachmentPathExternal;
                element2.documentCode="Attachment-" + attachmentIndex++;
                element2.documentDescription= "";

                diziExternal.push(element2); 
              }

              if(element.attachmentPathExternal!=null && element.attachmentPathExternal!=undefined && element.attachmentPathExternal!= ""){
                if (element.attachmentPathExternal.endsWith('.pdf')) {
                  pdfFilesExternal.push({title:element.iclistnoRevno, source:element.attachmentPathExternal});
                }
              }
          }
        });
        
        this.setState({
          selectedRowVal: event,
          datatableValueItems: responseTree.data.selected,
          documentTableDataInternal: diziInternal,
          documentTableDataExternal: diziExternal,
          internal:false,
          external:true,
          pdfFilesInternal:pdfFilesInternal,
          pdfFilesExternal:pdfFilesExternal,
        });
      }else{
        this.setState({
          selectedRowVal: event,
          datatableValueItems:[],
          documentTableDataInternal:[],
          documentTableDataExternal:[],
          internal:false,
          external:false,
          pdfFilesInternal:[],
          pdfFilesExternal:[],
        });
      }
    }else{
      this.setState({
        selectedRowVal: event,
        datatableValueItems:[],
        documentTableDataInternal:[],
        documentTableDataExternal:[],
        internal:false,
        external:false,
        pdfFilesInternal:[],
        pdfFilesExternal:[],
      });
    }
    this.setState({selectedRowVal2: null});

    const {selectedRowVal}= this.state; 
    if(selectedRowVal!=null)  
    this.setState({ 
      modalShowDocument: true,
      remarksVal:"",
      PhotoFileUpload:[], 
      photoPath:"",
      AttachmentFileUpload:[], 
      attachmentPath:"",
    }) 
  }

showOrHideModalInspectionResult = () => {
    this.setState({
        modalInspectionResult: !this.state.modalInspectionResult 
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
}, 500);
};

showOrHideModalDocument = () => {
    this.setState({
        modalShowDocument: !this.state.modalShowDocument 
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
}, 500);
};

showOrHideModalPdfMultiViewer = () => {
  if(this.state.internal){
    if(this.state.pdfFilesInternal.length>0){
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer 
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    }else{
      this.showError("File cant found.");
    }
  }else if(this.state.external){
    if(this.state.pdfFilesExternal.length>0){
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer 
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    }else{
      this.showError("File cant found.");
    }
  }
};

showOrHideModalPassFailed = () => {
  this.setState({
      modalPassFailed: !this.state.modalPassFailed 
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

showOrHideModalEInspection = () => {
  this.setState({
      modalEInspection: !this.state.modalEInspection 
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

passClick = () => {
  this.setState({ selectVal:"Pass", passVariant:"success",failVariant:"outline-danger", naVariant:"outline-primary" ,documentHidden:false,remarkHidden:true});
};

failedClick = () => {
  this.setState({ selectVal:"Failed",  passVariant:"outline-success",failVariant:"danger", naVariant:"outline-primary" ,documentHidden:false,remarkHidden:false});
};

naClick = () => {
  this.setState({  selectVal:"N/A", passVariant:"outline-success",failVariant:"outline-danger", naVariant:"primary" ,documentHidden:true,remarkHidden:true});
};

renderStatus(rowData) { 
  if(this.state.internal){
    if(rowData.status!=null&&rowData.status.length>0) {
      if(rowData.status=="Pass") return (  <>   <span className="bg-success">{rowData.status}</span>  </>);
      else if(rowData.status=="Failed") return (  <>   <span className="bg-danger">{rowData.status}</span>  </>);
      else if(rowData.status=="N/A")  return (  <>   <span className="bg-primary">{rowData.status}</span>  </>);
      else return (  <>   <span > </span>  </>);
    }
    else return (  <>   <span > </span>  </>);
  }else if(this.state.external){
    if(rowData.statusExternal!=null&&rowData.statusExternal.length>0) {
      if(rowData.statusExternal=="Pass") return (  <>   <span className="bg-success">{rowData.statusExternal}</span>  </>);
      else if(rowData.statusExternal=="Failed") return (  <>   <span className="bg-danger">{rowData.statusExternal}</span>  </>);
      else if(rowData.statusExternal=="N/A")  return (  <>   <span className="bg-primary">{rowData.statusExternal}</span>  </>);
      else return (  <>   <span > </span>  </>);
    }
    else return (  <>   <span > </span>  </>);
  }
}

renderPhoto(rowData, column) {
  if(this.state.internal){
    if (rowData !== null && rowData !== undefined && rowData.photoPathInternal !== null && rowData.photoPathInternal !== undefined && rowData.photoPathInternal !== "")
      return <Avatar size={64} src={rowData.photoPathInternal} onClick={() => this.showReport(rowData.photoPathInternal)} /> 
    return "";
  }else{
    if (rowData !== null && rowData !== undefined && rowData.photoPathExternal !== null && rowData.photoPathExternal !== undefined && rowData.photoPathExternal !== "")
      return <Avatar size={64} src={rowData.photoPathExternal} onClick={() => this.showReport(rowData.photoPathExternal)} /> 
    return "";
  }
};

renderAttachment(rowData, column) {
  if(this.state.internal){
    if (rowData !== null && rowData !== undefined && rowData.attachmentPathInternal !== null && rowData.attachmentPathInternal !== undefined && rowData.attachmentPathInternal !== "")
    return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(rowData.attachmentPathInternal)} type="primary" shape="round" icon={<DownloadOutlined />} >
    </AntdButton>
    return "";
  }else{
    if (rowData !== null && rowData !== undefined && rowData.attachmentPathExternal !== null && rowData.attachmentPathExternal !== undefined && rowData.attachmentPathExternal !== "")
    return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(rowData.attachmentPathExternal)} type="primary" shape="round" icon={<DownloadOutlined />} >
    </AntdButton>
  }
};

placeTemplate(rowData) {
  const row = rowData.data;
  var place = '';
  if (row.codePlaceOfExecution !== null && row.codePlaceOfExecution !== undefined)
      place = row.codePlaceOfExecution.name;

  return <> {place}</>;

}

durationTemplate(rowData) {
  const row = rowData.data;
  var result = "";
  var pObj = row.duration;
  if (pObj !== undefined && pObj !== null) {
      if (pObj.year > 0)
          result = result + pObj.year + "Years ";
      if (pObj.month > 0)
          result = result + pObj.month + "Months ";
      if (pObj.day > 0)
          result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
          result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
          result = result + pObj.minute + "Minutes ";
      if (pObj.second > 0)
          result = result + pObj.second + "Seconds ";
  }

  return <> {result}</>;
}

idleTemplate(rowData) {
  const row = rowData.data;
  var result = "";
  var pObj = row.idlePeriod;
  if (pObj !== undefined && pObj !== null) {
      if (pObj.year > 0)
          result = result + pObj.year + "Years ";
      if (pObj.month > 0)
          result = result + pObj.month + "Months ";
      if (pObj.day > 0)
          result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
          result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
          result = result + pObj.minute + "Minutes ";
      if (pObj.second > 0)
          result = result + pObj.second + "Seconds ";
  }

  return <> {result}</>;
}

actionTemplateInspection(rowData, column) {
  var editKnt=false;
  var deleteKnt=false;

  var iinspectionKnt=false;
  var idocumentKnt=false;    
  var einspectionKnt=false;
  var edocumentKnt=false;

  if(rowData.inspectionResult=="Approved"){
      einspectionKnt=true;
      edocumentKnt=true;
      idocumentKnt=true;
    }else if(rowData.inspectionResult=="Rejected" || rowData.inspectionResult=="E-Approved" || rowData.inspectionResult=="E-Rejected"){
      edocumentKnt=true;
      idocumentKnt=true;
    }else{
      iinspectionKnt=true;
      idocumentKnt=true;
    }

  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    editKnt=true;
    deleteKnt=true;
  }else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
    editKnt=true;
  }
  else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    deleteKnt=true;
  }
  
  return <React.Fragment> 
    <CustomMaterialMenuInspectionResult row={rowData} 
      onIInspectionRow={this.inpectionClick.bind(this)} 
      onIDocumentRow={this.documentClickInternal.bind(this)} 
      onEInspectionRow={this.inpectionClick.bind(this)} 
      onEDocumentRow={this.documentClickExternal.bind(this)} 
      onDeleteRow={this.deleteModal.bind(this)}
      onEditRow={this.changeButtonUpdate.bind(this)} 
      editKnt={editKnt}
      deleteKnt={deleteKnt}
      iinspectionKnt={iinspectionKnt}
      idocumentKnt={idocumentKnt}      
      einspectionKnt={einspectionKnt}
      edocumentKnt={edocumentKnt}
      />   
    </React.Fragment>;
};

actionTemplateEditDelete(rowData) {
  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
        onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
  } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuEdit row={rowData}
        onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
  }
  else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDelete row={rowData}
        onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
  }
}

deleteModal = (row) => {
  this.setState({ 
    deleteModal: true,
    selectedInspectionResult: row
  });
}

showOrHideModalDelete = () => {
  this.setState({
    deleteModal: !this.state.deleteModal
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHideModalReceivingInspection1 = () => {
  this.setState({
    modalReceivingInspection1: !this.state.modalReceivingInspection1
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHideModalReceivingInspection2 = () => {
  this.setState({
    modalReceivingInspection2: !this.state.modalReceivingInspection2
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

saveInspectionResult = async () => {
  const addInspectionResult = {
    discipline: this.state.discipline,
    activity: this.state.values[this.state.values.length - 1],
    inspectionDateTime: this.state.inspectionDateTime,
  }

  if(this.state.receivingInspection==true){
    this.resetInputsReceivingInspection1();
    this.setState({
      modalReceivingInspection1:true,
      addInspectionResult:addInspectionResult, 
    });
  }else{
    var response = await handleRequest("POST", "/InspectionResult/add", addInspectionResult);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  } 
}

getPurchaseOrderMaterial = async (purchaseOrderId) => {
  var newObj={
    purchaseOrderId: purchaseOrderId
  }
  const response = await handleRequest("POST", "/PurchaseOrderMaterial/getResult", newObj);
  if (response.data.length === 0) {
    this.setState({
      purchaseOrderMaterial: [],
      selectedReceiving1Row:[],
    });
  }

  if (response.data.length !== 0) {
    this.setState({
      purchaseOrderMaterial: response.data, 
      selectedReceiving1Row:[],
    });  
  }
} 

updateInspectionResult = async () => {
  const updatedInspectionResult = {
    id: this.state.selectedInspectionResult.id,
    discipline: this.state.discipline,
    activity: this.state.values[this.state.values.length - 1],
    inspectionDateTime: this.state.inspectionDateTime,
  }
  var response2 = await handleRequest("POST", "/InspectionResult/updateInspectionResult", updatedInspectionResult);
  if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else{
        this.showError();
      }
  }
}

updateEInspection = async () => {
  const updatedInspectionResult = {
    id: this.state.selectedInspectionResult.id,
    designation: this.state.designation,
    clientInspector: this.state.clientInspector,
    inspectionDate: this.state.inspectionDate,
  }

  var response2 = await handleRequest("POST", "/InspectionResult/updateEInspection", updatedInspectionResult);
  if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalEInspection();
      }
      else{
        this.showError();
      }
  }
}

restartTable = async () => {
  const response = await handleRequest("GET", "/InspectionResult/getallByCompany");
  if (response.data.length === 0) {
    this.setState({
      inspectionResultTable: [],
    });
  }

  if (response.data.length !== 0) {
    this.setState({
      inspectionResultTable: response.data,
    });
  }
}

deleteInspectionResultOK = async () => {
  const deleteInspectionResult = {
      id: this.state.selectedInspectionResult.id
  }
  var response = await handleRequest("POST", "/InspectionResult/delete", deleteInspectionResult);
  if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
          this.showError();
      }
  }
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

changeButtonUpdate = (row) => {
  var deger = dataList.filter(p=>p.key==row.activity)

  var inspectionDateTime=null;
  if(row.inspectionDateTime!=null && row.inspectionDateTime!=undefined && row.inspectionDateTime!=null){
    inspectionDateTime=row.inspectionDateTime;
  }

  var values=[];
  if(deger!=null && deger!=undefined && deger!='undefined' && deger!=''){
    values=deger[0].lineage;
  }

  this.formRef.current.setFieldsValue({
    Activity: values,
    InspectionDateTime: inspectionDateTime!=null ? moment(inspectionDateTime, 'DD/MM/YYYY HH:mm') : null,
  });
    
  this.setState({
    selectedInspectionResult: row,
    inspectionDateTime: inspectionDateTime,
    values:values,

    hiddenSave: true,
    hiddenUpdate: false,
    hiddenDataGiris: false,
    createNewButton: "Cancel",
    createNewButtonVariant: "danger",
  });
}

resetInputs = () => {
  this.formRef.current.setFieldsValue({
    Activity: [],
    InspectionDateTime: null,
  });

  this.setState({
    inspectionDateTime: null,
    values:[],
    hiddenSave: false,
    hiddenUpdate: true,
  });
}
  
changeButtonCancel = () => {
  this.formRef.current.setFieldsValue({
    Activity: [],
    InspectionDateTime: null,
  });

  this.setState({
    inspectionDateTime: null,
    values:[],
    hiddenSave: false,
    hiddenUpdate: true,
    hiddenDataGiris:true,
    createNewButton: "Create New",
    createNewButtonVariant: "info", 
  });
}

showOrHideModalDeleteMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

createNewClientButton = async () => {
  await this.resetInputs();  

   if (this.state.createNewButton=="Create New") {
     this.setState({ 
      hiddenDataGiris: false, 
      hiddenSave:false,
      hiddenUpdate:true,
      hiddenCreateNewButton:false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
     });
  }
  else {
     this.setState({ 
      hiddenDataGiris: true,
      hiddenSave:true,
      hiddenUpdate:false,
      hiddenCreateNewButton:false,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
     });
  }
}
 
showReport = async (fileRealUrl, documentId) => {
  try { 
    let fileKontrol=fileRealUrl;

    if(Boolean(documentId) && documentId>0){
      fileRealUrl=`${API_BASE}/DocumentLog/download/` + documentId;
    }
    

    if (fileKontrol.endsWith('.pdf')) {
      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });
    } else if (fileKontrol.endsWith('.png') || fileKontrol.endsWith('.jpg') || fileKontrol.endsWith('.tiff') || fileKontrol.endsWith('.jpeg')
      || fileKontrol.endsWith('.PNG') || fileKontrol.endsWith('.JPG') || fileKontrol.endsWith('.TIFF') || fileKontrol.endsWith('.JPEG')) {

      this.setState({
        imageUrl: fileRealUrl,
        modalPhotoShow: true,
      })
    } else {
      window.location.href = fileRealUrl;
    }
  } catch (error) { 
    this.showError("File cant found.");
  }


  // try {
  //   if(rowData.documentPdfPath!=null && rowData.documentPdfPath!=undefined && rowData.documentPdfPath!=""){
  //     const fileRealUrl = rowData.documentPdfPath;

  //     if(fileRealUrl.endsWith('.pdf')){
  //       this.setState({
  //         pdfFile: fileRealUrl,
  //         modalPdfViewer: true,
  //       });
  //     }else if(fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
  //           || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')){

  //       this.setState({
  //         imageUrl: fileRealUrl,
  //         modalPhotoShow: true,
  //       })
  //     }else{
  //       window.location.href = fileRealUrl;
  //     }
  //   }else{
  //     this.showError("File cant found.");
  //   }
  // } catch (error) {
  //   this.showError("File cant found.");
  // }
};

showOrHideModalPdfViewer = () => {
  this.setState({
    modalPdfViewer: !this.state.modalPdfViewer,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

showSuccess(msg) {
    if (!msg) {
        msg = 'Successful';
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Success"
    });
};

showError(msg) {
    if (!msg) {
        msg = 'An error was occured please try again later !';
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Error"
    });
};

showWarning(msg) {
  if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Warning"
  });
};

showOrHideModalMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  }); 
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHidePhotoModal = () => {
  this.setState({
    modalPhotoShow: !this.state.modalPhotoShow,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

renderDocumentDesc(rowData, column) {
  if (rowData.documentLog !== null && rowData.documentLog !== undefined && 
    rowData.documentLog.description !== null && rowData.documentLog.description !== undefined && rowData.documentLog.description !== "" ){
      return rowData.documentLog.description;
    }
  return "";
};

renderDocumentNo(rowData, column) {
  if (rowData.documentLog !== null && rowData.documentLog !== undefined && 
    rowData.documentLog.code !== null && rowData.documentLog.code !== undefined && rowData.documentLog.code !== "" )
      return <React.Fragment>
      <div style={{
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
        // fontWeight: "bold",
      }}
        onClick={(e) => this.showReport(rowData.documentLog)}>
        {rowData.documentLog.code}
      </div>
    </React.Fragment>;
  return "";
};

showOrHideModalTable = async (tableTip) => {
  this.setState({
      modalTable: !this.state.modalTable,
      tableTip:tableTip,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

handleChangeActivity = (e) => {
  this.setState({
    activity: e,
  });
};

onChangeDateTime = (value, dateString) => {
  this.setState({
    inspectionDateTime: dateString,
  });
}

onChangeDate = (value, dateString) => {
  this.setState({
    inspectionDate: dateString,
  });
}

disabledDate(current) {
  return moment().endOf('day') > current ;
}

onChangeActivity = (value, data) => { 
  var receivingInspection=false;
  if (data.length > 0){
    if(data[0].data.codeActivityType!=null && data[0].data.codeActivityType!=undefined){
      if(data[0].data.codeActivityType.name=="Receiving Inspection" && data[0].data.codeActivityType.type=="ACTIVITYTYPE"){
        receivingInspection=true;
      }
    }
    this.setState({
        values: value,
        selectedItems: data,
        receivingInspection:receivingInspection,
    }) 
  }else{
    this.setState({
        values: [],
        selectedItems: [],
        receivingInspection:receivingInspection,
    }) 
  }
}

handleChangeINRClientInspector = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.INRClientInspectors.length; z++) {
    if (this.state.INRClientInspectors[z].id == e) {
      deger = this.state.INRClientInspectors[z].name;
    }
  }
  this.setState({
      clientInspectorAck: deger,
      clientInspector: e,
  });
};

addINRClientInspectors = async () => {
  const { itemINRClientInspectors } = this.state;
  if (itemINRClientInspectors != null && itemINRClientInspectors != "" && itemINRClientInspectors != undefined) {
      const newItem = {
      name: itemINRClientInspectors,
      type: "INRClientInspector"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemINRClientInspectors: '',
      });
      this.getCodesINRClientInspector();
  } else {
      this.showWarning();
  }
};

getCodesINRClientInspector = async () => {
  const response = await handleRequest("GET", "/Code/type/INRClientInspector");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      INRClientInspectors: response.data
    });
  }
} 

handleChangeINRDesignation = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.INRDesignations.length; z++) {
    if (this.state.INRDesignations[z].id == e) {
      deger = this.state.INRDesignations[z].name;
    }
  }
  this.setState({
    designationAck: deger,
    designation: e,
  });
};

addINRDesignations = async () => {
  const { itemINRDesignations } = this.state;
  if (itemINRDesignations != null && itemINRDesignations != "" && itemINRDesignations != undefined) {
      const newItem = {
      name: itemINRDesignations,
      type: "INRDesignation"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemINRDesignations: '',
      });
      this.getCodesINRDesignation();
  } else {
      this.showWarning();
  }
};

getCodesINRDesignation = async () => {
  const response = await handleRequest("GET", "/Code/type/INRDesignation");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      INRDesignations: response.data
    });
  }
}  

saveReceivingInspection1 = async () => {
  if(this.state.selectedReceiving1Row.length>0){ 
    const newItem = {
      purchaseOrderMaterials: this.state.selectedReceiving1Row
    }
    var response = await handleRequest("POST", "/PurchaseOrderMaterial/updateAll", newItem);   //Backende kontrol ediliyo sadece, veri tabanına yazmıyor şuan.
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.setState({
          inspectionPOMaterial:this.state.selectedReceiving1Row,
        });
        this.resetInputsReceivingInspection2();
        this.showOrHideModalReceivingInspection2();
      }else if (response.data === "ERROR") {
        this.showError();
      }
      else {
        this.showWarning(response.data);
      }
    }
  }else{
    this.setState({
      inspectionPOMaterial: [],
    });
    this.showWarning();
  }
}

saveReceivingInspection2 = async () => {
  var knt=true;
  if(this.state.invoiceNo==null || this.state.invoiceNo==undefined || this.state.invoiceNo==""){
    if(this.state.deliveryNoteNo==null || this.state.deliveryNoteNo==undefined || this.state.deliveryNoteNo==""){
      knt=false;
    }
  }
  if(!knt){
    this.showWarning(<FormattedMessage id="INRRI2MessageWarning1" defaultMessage="You must enter at least one Invoice no or Delivery note no."/>);
  }else{
    var addInspectionResult=[];
    addInspectionResult.push(this.state.addInspectionResult); 

    const newItem = {
      inspectionResultId: 0,  //Kayıt ederken önce InspectionResult tablosuna kayıt yapılır sonrasında o id alınır inspectionResultReceiving2 tablosuna yazılır.
      POId: this.state.POId,
      POPath: this.state.POPath,
      invoiceNo: this.state.invoiceNo,
      invoiceNoPath: this.state.invoiceNoPath,
      deliveryNoteNo: this.state.deliveryNoteNo,
      deliveryNoteNoPath: this.state.deliveryNoteNoPath,
      packingList: this.state.packingList,
      packingListPath: this.state.packingListPath,
      testReport: this.state.testReport,
      testReportPath: this.state.testReportPath,
      manufacturerManualPath: this.state.manufacturerManualPath,
      packingOfGoods: this.state.packingOfGoods,
      packingOfNotGoods: this.state.packingOfNotGoods,
      packingOfGoodsNotPath: this.state.packingOfGoodsNotPath,
      other: this.state.other, 
      otherPath: this.state.otherPath, 
      inspectionResults: addInspectionResult,
      purchaseOrderMaterials: this.state.inspectionPOMaterial,
    }

    var response = await handleRequest("POST", "/InspectionResultReceiving2/add", newItem);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.showSuccess();
        this.showOrHideModalReceivingInspection2();
        this.showOrHideModalReceivingInspection1();
        this.restartTable();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  } 
} 

resetInputsReceivingInspection1 = () => { 
  this.setState({
    purchaseOrderId:"",
    purchaseOrderMaterial:[],
    selectedReceiving1: [],
    selectedReceiving1Row: [],
  });
}

resetInputsReceivingInspection2 = () => { 
  this.setState({
    fileList: [],
    FilePO:[], 
    POPath:"",
    invoiceNo: "",
    FileInvoiceNo:[], 
    invoiceNoPath:"",
    deliveryNoteNo: "", 
    FileDeliveryNoteNo:[], 
    deliveryNoteNoPath:"",
    packingList: "",
    FilePackingList:[], 
    packingListPath:"",
    testReport: "",
    FileTestReport:[], 
    testReportPath:"",
    FileManufacturerManual:[], 
    manufacturerManualPath:"",
    packingOfGoods: "",
    packingOfNotGoods: "",
    FilepackingOfNotGoods:[], 
    packingOfGoodsNotPath:"",
    other: "", 
    FileOther:[], 
    otherPath:"",
  });
}

handleOnSelectReceiving1 = (row, isSelect) => {
  if (isSelect) {
    this.setState(() => ({
      selectedReceiving1: [...this.state.selectedReceiving1, row.id],
      selectedReceiving1Row:[...this.state.selectedReceiving1Row, row],
    }));
  } else {
    this.setState(() => ({
      selectedReceiving1: this.state.selectedReceiving1.filter(x => x !== row.id),
      selectedReceiving1Row:this.state.selectedReceiving1Row.filter(x => x !== row.id),
    }));
  }
}

handleOnSelectAllReceiving1 = (isSelect, rows) => { 
  const ids = rows.map(r => r.id);
  if (isSelect) {
    this.setState(() => ({
      selectedReceiving1: ids,
      selectedReceiving1Row:rows
    }));
  } else {
    this.setState(() => ({
      selectedReceiving1: [],
      selectedReceiving1Row:[],
    }));
  } 
}

onPurchaseOrderNoChange = (value) => {
  this.getPurchaseOrderMaterial(value);

  var purchaseOrderNo = this.state.purchaseOrderNos.filter(x => x.id == value);
  this.setState({
    purchaseOrderId: value, 
    purchaseOrderNo:purchaseOrderNo[0].quoteNo + "/" + purchaseOrderNo[0].revNo.toString(),
  });
}

render() {
  const props = {
    action: `${API_BASE}/File/upload`,
    listType: 'picture',
    defaultFileList: [...this.state.fileList],
  };

  const options = {
    showTotal: true,
    sizePerPageList: [{
        text: '10', value: 10
    }, {
        text: '20', value: 20
    }, {
        text: 'All', value: this.state.purchaseOrderMaterial.length
    }]
};

  var itemDisciplineAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline");
  var itemLocationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Location");
  var itemActivityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Activity");
  var itemInspectionDateTimeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionDateTime");

  var itemDisciplineRequired = this.state.dynamicInputs.find(p => p.inputKey === "Discipline");
  var itemLocationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Location");
  var itemActivityRequired = this.state.dynamicInputs.find(p => p.inputKey === "Activity");
  var itemInspectionDateTimeRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionDateTime");

const selectRow = {
  mode: 'checkbox',
  clickToSelect: false,
  selected: this.state.selectedReceiving1,
  onSelect: this.handleOnSelectReceiving1,
  onSelectAll: this.handleOnSelectAllReceiving1
};
  
const columns = [
    {
      key: "key",
      title: "",
      render: this.actionTemplateInspection
    },        
    {
      dataIndex: "index",
      key: "key",
      title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("inspectionResult")} icon="pi pi-cog"></PrimeButton>,
    },
    {
      dataIndex: "rfiNo",
      key: "key",
      title: <FormattedMessage id="INRRfiNo" defaultMessage="RFI no" />,
    },
    {
      dataIndex: "revNo",
      key: "key",
      title: <FormattedMessage id="INRRevNo" defaultMessage="Rev. no" />,
    },
    {
      dataIndex: "disciplines",
      key: "key",
      title: <FormattedMessage id="INRDiscipline" defaultMessage="Discipline" />,
      render: (data) =>   
      {  
        if(data!=null && data!=undefined){
            return data.disciplineName;
        } 
        return "";
      }
    },
    {
      dataIndex: "issueDate",
      key: "key",
      title: <FormattedMessage id="INRIssueDate" defaultMessage="Issue date" />,
    },
    {
      dataIndex: "activityName",
      key: "key",
      title: <FormattedMessage id="INRActivity" defaultMessage="Activity" />,
    },
    {
      dataIndex: "inspectionDateTime",
      key: "key",
      title: <FormattedMessage id="INRInspectionDateTime" defaultMessage="Inspection Date, Time" />,
    },
    {
      dataIndex: "location",
      key: "key",
      title: <FormattedMessage id="INRLocation" defaultMessage="Location" />,
    },
    {
      dataIndex: "documents",
      key: "key",
      title: <FormattedMessage id="INRDocuments" defaultMessage="Documents" />,
    },
    {
      dataIndex: "constructionRepresentative",
      key: "key",
      title: <FormattedMessage id="INRConstructionRepresentative" defaultMessage="Construction Representative" />,
    },
    {
      dataIndex: "qcInspector",
      key: "key",
      title: <FormattedMessage id="INRQCInspector" defaultMessage="QC Inspector" />,
    },
    {
      dataIndex: "clientRepresentative",
      key: "key",
      title: <FormattedMessage id="INRClientRepresentative" defaultMessage="Client Representative" />,
    },
    {
      dataIndex: "clientQC",
      key: "key",
      title: <FormattedMessage id="INRClientQC" defaultMessage="Client QC" />,
    },
    {
      dataIndex: "inspectionResult",
      key: "key",
      title: <FormattedMessage id="INRInspectionResult" defaultMessage="Inspection Result" />,
    },
    {
      dataIndex: "status",
      key: "key",
      title: <FormattedMessage id="INRStatus" defaultMessage="Status" />,
    },
    {
      dataIndex: "remarks",
      key: "key",
      title: <FormattedMessage id="INRRemarks" defaultMessage="Remarks" />,
    },
    {
      dataIndex: "inspectionDate",
      key: "key",
      title: <FormattedMessage id="INREInspectionDateTime" defaultMessage="E-Inspection Date" />,
    },
    {
      dataIndex: "codeDesignation",
      key: "key",
      title: <FormattedMessage id="INRClientInspector" defaultMessage="Client Inspector" />,
      render: (data) =>   
      {  
        if(data!=null && data!=undefined){
          return data.name;
        } 
        return "";
      }
    },
    {
      dataIndex: "codeClientInspector",
      key: "key",
      title: <FormattedMessage id="INRDesignation" defaultMessage="Designation" />,
      render: (data) =>   
      {  
      if(data!=null && data!=undefined){
          return data.name;
      } 
      return "";
      }
    },
];

const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
if (this.state.xScroll === 'fixed') {
  tableColumns[0].fixed = true;
  tableColumns[tableColumns.length - 1].fixed = 'right';
}

const layoutView = (
  isSidebarOpened,
  container,
  main,
  toolbar,
  sidebar,
) => {
  return defaultLayout(
    isSidebarOpened,
    container,
    main,
    toolbar(renderToolbar),
    sidebar,
  );
};

const onFinish = values => {
  console.log('Success:', values);
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const layoutModal = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const renderToolbar = (toolbarSlot) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: 'center',
        }}
      >
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.previousPageButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.nextPageButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomOutButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomPopover}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomInButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.downloadButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.moreActionsPopover}
        </div>
      </div>
    </div>
  );
};

let radioGroupOptions = (
  <FormBoot.Group  as={Row}  >
      <Col sm="5">
          <Radio style={{ marginTop: "12px"}} value={1}><FormattedMessage id="INRPackinGood" defaultMessage="Good" /></Radio>
      </Col>
      <Col sm="2">
      </Col>
      <Col sm="5">
          <Radio style={{ marginTop: "12px"}} value={2}><FormattedMessage id="INRPackinNotGood" defaultMessage="Not Good" /></Radio>
      </Col>
  </FormBoot.Group>
);

  const documentColumnsInternal = [
    {
      title: <FormattedMessage id="GeneralTableSN" defaultMessage="S/N" />,
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: <FormattedMessage id="INRDocumentCode" defaultMessage="Document Code" />,
      render: (row) =>  
      {  
        if (row.documentPath !== "" && row.documentPath !== null && row.documentPath !== undefined) {
          return (
            <React.Fragment>
            <div style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
              onClick={(e) => this.showReport(row.documentPath, row.documentId)}>
              {row.documentCode}
            </div>
          </React.Fragment>
          );
        } else{
         return row.documentCode
        } 
      }
    },
    {
      title: <FormattedMessage id="INRDocumentDescription" defaultMessage="Description"/>,
      dataIndex: 'documentDescription',
      key: 'documentDescription',
    },  
    {
      title: <FormattedMessage id="INRRemarks" defaultMessage="Remarks" />,
      dataIndex: 'remarks',
      key: 'remarks',
    }, 
  ];

  const documentColumnsExternal = [
    {
      title: <FormattedMessage id="GeneralTableSN" defaultMessage="S/N" />,
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: <FormattedMessage id="INRDocumentCode" defaultMessage="Document Code" />,
      render: (row) =>  
      {  
        if (row.documentPath !== "" && row.documentPath !== null && row.documentPath !== undefined) {
          return (
            <React.Fragment>
            <div style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
              onClick={(e) => this.showReport(row.documentPath, row.documentId)}>
              {row.documentCode}
            </div>
          </React.Fragment>
          );
        } else{
         return row.documentCode
        } 
      }
    },
    {
      title: <FormattedMessage id="INRDocumentDescription" defaultMessage="Description"/>,
      dataIndex: 'documentDescription',
      key: 'documentDescription',
    },  
    {
      title: <FormattedMessage id="INRRemarks" defaultMessage="Remarks" />,
      dataIndex: 'remarksExternal',
      key: 'remarksExternal',
    }, 
  ];
    
  return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
    <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && 
         <Form
                {...layout}
                initialValues={{ remember: false }}
                onFinish={this.state.hiddenUpdate == false ? this.updateInspectionResult : this.saveInspectionResult}
                onFinishFailed={onFinishFailed}
                ref={this.formRef} >
 
            <Row>
                <Col xs={8}></Col>
                <Col xs={3}>
                    <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                        variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                    </Button>
                </Col>
                <Col xs={1}>
                  <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("inspectionResult")} icon="pi pi-cog"></PrimeButton>
                </Col>
            </Row>
     
            <div hidden={this.state.hiddenDataGiris}>
              <br/>
                {(itemDisciplineAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="INRDiscipline" defaultMessage="Discipline" />}
                      name="Discipline"
                      rules={[{ required: itemDisciplineRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDisciplineRequired.translateRequired)==true ? itemDisciplineRequired.translateRequired : -1} defaultMessage="Please input your Discipline!" /> }]} >
                      <Input id="Discipline" value={this.state.disciplineName} readOnly={true}/>
                  </Form.Item>}

                {(itemLocationAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="INRLocation" defaultMessage="Location" />}
                      name="Location"
                      rules={[{ required: itemLocationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLocationRequired.translateRequired)==true ? itemLocationRequired.translateRequired : -1} defaultMessage="Please input your Location!" /> }]} >
                      <Select name="Location"
                              style={{ width: '100%' }}
                              value={this.state.location} >
                      </Select>
                  </Form.Item>}  

                {(itemActivityAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="INRActivity" defaultMessage="Activity" />}
                      name="Activity"
                      rules={[{ required: itemActivityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemActivityRequired.translateRequired)==true ? itemActivityRequired.translateRequired : -1} defaultMessage="Please input your Activity!" /> }]} >
                       <Cascader name="Activity"
                            style={{ width: "100%" }}
                            options={this.state.nodes} 
                            value={this.state.values} 
                            onChange={this.onChangeActivity} changeOnSelect />
                      {/* <Select name="Activity"
                        style={{ width: '100%' }}
                        value={this.state.activity} 
                        onChange={this.handleChangeActivity}>

                        {this.state.departments.map(i => (
                            <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                        ))}
                      </Select> */}
                  </Form.Item>}    
                  
                  {(itemInspectionDateTimeAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="INRInspectionDateTime" defaultMessage="Inspection Date, Time" />}
                      name="InspectionDateTime"
                      rules={[{ required: itemInspectionDateTimeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionDateTimeRequired.translateRequired)==true ? itemInspectionDateTimeRequired.translateRequired : -1} defaultMessage="Please input your Inspection Date Time!" /> }]} >
                       <DatePicker disabledDate={this.disabledDate} value={moment(this.state.inspectionDateTime, 'DD/MM/YYYY HH:mm')}
                          disabled={this.state.datePickerDisable} showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDateTime} />
                  </Form.Item>}  

                  <div hidden={this.state.hiddenSave}>
                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                      <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Col sm="2">
                          </Col>
                          <Col sm="6">
                              <Button id="InspectionResultSaveButton"
                                  style={{ width: '100%' }} type="submit"
                                  variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                          </Col>
                          <Col sm="2">
                          </Col>
                      </FormBoot.Group>
                    }
                </div>

                  <div hidden={this.state.hiddenUpdate}>
                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="2"></Col>
                            <Col sm="3">
                                <Button id="InspectionResultCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                            </Col>
                            <Col sm="3">
                                <Button id="InspectionResultUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                            </Col>
                            <Col sm="2">
                            </Col>
                        </FormBoot.Group>
                    }
                </div>
          
            </div> 
            <br />
            <br />

            <div className="datatable-doc-demo">
                <div className="content-section implementation">
                  <Mnd.MNDTable
                    state={this.state}
                    columns={tableColumns} dataSource={this.state.inspectionResultTable}
                    pagination={this.state.paginationDisabled}
                    expandable={this.state.expandable}
                    footer={this.state.footer}
                    title={this.state.title}
                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                  /> 
                 </div>
              </div>
    
        </Form> }

      <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >

          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              <Row > 
                <Col sm="12">
                  {this.state.messageTip=="Success" &&  
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                
                  {this.state.messageTip=="Warning" &&  
                  <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip=="Error" &&  
                  <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                </Col>
              </Row>
              </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row > 
              <Col sm="12">
                {this.state.message} 
              </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
        </Modal.Footer>
        </Modal>

      <Modal
        size="md"
        show={this.state.modalPhotoShow}
        onHide={this.showOrHidePhotoModal}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.imageUrl !== "" ? (
            <img
              src={this.state.imageUrl}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
              <p>Photo has not been uploaded.</p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={this.showOrHidePhotoModal}>
          <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={this.state.modalPdfViewer}
        onHide={this.showOrHideModalPdfViewer}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            PDF Report Viewer
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
            <div style={{ height: "900px" }}>
              <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
            </div>
          </Worker> 

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.showOrHideModalPdfViewer}>
            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal 
        show={this.state.modalInspectionResult} size="xl" 
        onHide={this.showOrHideModalInspectionResult} 
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered  >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Inspection Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable ref={(el) => this.dt = el} value={this.state.datatableValueItems}   
                  virtualScroll={true} responsive
                  className="p-datatable-customers" dataKey="id" rowHover  
                  selection={this.state.selectedRowVal2} 
                  onSelectionChange={this.onselectChangeVal2}
                  paginator rows={10} emptyMessage="No data found" >
              <Column selectionMode="single" style={{ textAlign: 'center', width: '5%' }}/>
              <Column field="iclistnoRevno" style={{ textAlign: 'center', width: '10%' }} header="Code/ Revno" />
              <Column field="checklistItemsitemno" style={{ textAlign: 'center', width: '10%' }} header="Item No" />
              <Column field="checklistItemsAcceptanceCriteria" style={{ textAlign: 'center', width: '27%' }}  header="Acceptance Criteria" /> 
              <Column field="checklistItemsReference" style={{ textAlign: 'center', width: '15%' }}  header="Reference" />
              { this.state.internal && <Column field="photoPathInternal" body={this.renderPhoto}  style={{ textAlign: 'center', width: '12%' }}   header="Photo" />}
              { this.state.external && <Column field="photoPathExternal" body={this.renderPhoto}  style={{ textAlign: 'center', width: '12%' }}   header="Photo" />}
            
              { this.state.internal && <Column field="attachmentPathInternal" body={this.renderAttachment}  style={{ textAlign: 'center', width: '13%' }}   header="Attachment" />}
              { this.state.external && <Column field="attachmentPathExternal" body={this.renderAttachment}  style={{ textAlign: 'center', width: '13%' }}   header="Attachment" />}

              { this.state.internal && <Column field="status" body={this.renderStatus}  style={{ textAlign: 'center', width: '8%' }}   header="Status" />}
              { this.state.external && <Column field="statusExternal" body={this.renderStatus}  style={{ textAlign: 'center', width: '8%' }}   header="Status" />}
            </DataTable>
        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="secondary" onClick={this.showOrHideModalInspectionResult}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button> &nbsp;&nbsp;&nbsp;
          <Button variant="success" onClick={this.modalAcceptInspectionResult}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button> 
        </Modal.Footer>
      </Modal> 

      <Modal 
          show={this.state.modalShowDocument} size="xl" 
          onHide={this.showOrHideModalDocument} 
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Document</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <Table columns={this.state.external==true ? documentColumnsExternal : documentColumnsInternal } 
                dataSource={this.state.external==true ? this.state.documentTableDataExternal : this.state.documentTableDataInternal }
                responsive
                striped
                bordered
                hover
                pagination={{
                  pageSize: 5,
                  defaultPageSize: 5,
                  showSizeChanger: true,
                  pageSizeOptions: ["5", "10", "20", "30"],
                  total: this.state.external==true ? this.state.documentTableDataExternal.length : this.state.documentTableDataInternal.length,
                  showQuickJumper: true,
                  position: "bottomLeft",
                }} />

              {/* <DataTable ref={(el) => this.dt = el} value={this.state.documentTableData}   
                  virtualScroll={true} responsive
                  className="p-datatable-customers" dataKey="id" rowHover  
                  selection={this.state.selectDocument} 
                  onSelectionChange={this.onselectChangeDocument}
                  paginator rows={10} emptyMessage="No data found" >
                <Column selectionMode="multiple" style={{ textAlign: 'center', width: '10%' }}/>
                <Column field="index" style={{ textAlign: 'center', width: '10%' }} sortable   header="S/N" />
                <Column body={this.renderDocumentNo} header="Doc.No" ></Column>
                <Column body={this.renderDocumentDesc} header="Document" ></Column>
              </DataTable> */}

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDocument}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button> 
            <Button variant="danger" onClick={this.showOrHideModalPdfMultiViewer}><FormattedMessage id="GeneralTextPrint" defaultMessage="Print" /></Button> 
            <Button variant="success">Select</Button> 
          </Modal.Footer>
      </Modal> 

      <Modal 
          show={this.state.modalPassFailed} size="md" 
          onHide={this.showOrHideModalPassFailed} 
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.headerTextModal}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <FormBoot.Group style={{ width:"100%" }} as={Row}  >
              <Col sm="5">
              <Button variant={this.state.passVariant} onClick={this.passClick } style={{ width:"100%" }}>Pass</Button>
              </Col>
              <Col sm="2">
                </Col> 
              <Col sm="5">
              <Button variant={this.state.failVariant} onClick={this.failedClick} style={{ width:"100%" }}>Failed</Button> 
                </Col> 
              {/* <Button variant={this.state.naVariant} onClick={this.naClick} style={{ width:"100%" }}>N/A</Button>   */}
            </FormBoot.Group>

            <FormBoot.Group style={{ width:"100%" }} as={Row} hidden={this.state.documentHidden} >
              <Col sm="2"> </Col>
              <Col sm="3"> 
                <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.PhotoFileUpload}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {  
                      var photoPath="";
                      if(Boolean(fileList[0].response)){
                        photoPath=fileList[0].response.url;
                      }
                        this.setState({
                          PhotoFileUpload:fileList,
                          photoPath:photoPath,
                        });
                    }
                }}
                beforeUpload={(fileList) => {
                  if (this.state.PhotoFileUpload.length > 0) {
                    this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                    return false;
                  }
                  return true;
                }}
                onRemove={({ fileList }) => {
                  this.setState( {
                    PhotoFileUpload:[], 
                    photoPath:"",
                  })
                }
              }>
                <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                  <UploadOutlined /> <FormattedMessage id="INRPhoto" defaultMessage="Photo"/>
                </AntdButton>
              </Upload>
              
              </Col> 
              <Col sm="3"> 
                <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.AttachmentFileUpload}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {  
                      var attachmentPath="";
                      if(Boolean(fileList[0].response)){
                        attachmentPath=fileList[0].response.url;
                      }
                        this.setState({
                          AttachmentFileUpload:fileList,
                          attachmentPath:attachmentPath,
                        });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.AttachmentFileUpload.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      AttachmentFileUpload:[], 
                      attachmentPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> <FormattedMessage id="INRAttachment" defaultMessage="Attachment"/>
                  </AntdButton>
              </Upload>
              </Col>   
            </FormBoot.Group>

            <FormBoot.Group style={{ width:"100%" }} as={Row}  hidden={this.state.remarkHidden}>
              <Col sm="2"> 
                <label>Remarks </label> 
                </Col> 
              <Col sm="6"> 
                  <input type="text" id="worderNumber" onChange={(e) => this.setState({ remarksVal: e.target.value })}
                value={this.state.remarksVal} className="form-control"></input>   
              </Col> 
            </FormBoot.Group> 
          </Modal.Body>
          <Modal.Footer> 
            <Button variant="secondary" onClick={this.showOrHideModalPassFailed} ><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>   
            <Button variant="success" onClick={this.saveItemNo}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>   
          </Modal.Footer>
          </Modal>

      <Modal 
        show={this.state.modalShowPdfMultiViewer} size="xl" 
        onHide={this.showOrHideModalPdfMultiViewer} 
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered  >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
        </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.external==true ? this.state.pdfFilesExternal : this.state.pdfFilesInternal}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />  

          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button> 
        </Modal.Footer>
    </Modal> 

      <Modal
        show={this.state.modalTable}
        onHide={this.showOrHideModalTable}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered size="lg">

        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Row >
                    <Col sm="12">
                        <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                    </Col>
                </Row>
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Row >
                <Col sm="12">
                    <MNDTableSettings id={this.state.tableTip} />
                </Col>
            </Row>
        </Modal.Body>
    </Modal>

      <Modal
        show={this.state.deleteModal}
        onHide={this.showOrHideModalDelete}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteInspectionResultOK}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      <Modal 
          show={this.state.modalEInspection} size="md" 
          onHide={this.showOrHideModalEInspection} 
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
          <Form
            {...layoutModal}
            initialValues={{ remember: false }}
            onFinish={this.updateEInspection}
            onFinishFailed={onFinishFailed}
            ref={this.formRef}
        >
            {/* {(itemDisciplineCodeAttributes.visible) && */}
                <Form.Item
                    label={<FormattedMessage id="INREInspectionDateTime" defaultMessage="E-Inspection Date" />}
                    name="Date"
                    rules={[{ required: true, message: <FormattedMessage id="INRDateRequiredMessage" defaultMessage="Please input your date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} value={(this.state.inspectionDate!=null && this.state.inspectionDate!=undefined && this.state.inspectionDate!="") ? moment(this.state.inspectionDate, 'DD/MM/YYYY HH:mm') : null}
                          disabled={this.state.datePickerDisable} showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDate} />
                </Form.Item>
                 {/* } */}

          {/* {(itemDisciplineCodeAttributes.visible) && */}
                <Form.Item
                    label={<FormattedMessage id="INRClientInspector" defaultMessage="Client Inspector" />}
                    name="clientInspectorSelect"
                    rules={[{ required: true, message: <FormattedMessage id="INRClientInspectorRequiredMessage" defaultMessage="Please input your Client Inspector!" /> }]} >
                     <Select
                        name="clientInspector"
                        style={{ width: '100%' }}
                        value={this.state.clientInspectorAck}
                        onChange={this.handleChangeINRClientInspector}

                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={this.state.itemINRClientInspectors}
                                onChange={(event) => { this.setState({ itemINRClientInspectors: event.target.value }) }} />
                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                onClick={this.addINRClientInspectors} >
                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item"/>  </a>
                            </div>
                        </div>
                        )}>
                        {this.state.INRClientInspectors.map(i => (
                            <Option key={i.id} value={i.id}>{i.name}</Option>
                        ))}
                      </Select>
                  </Form.Item>
                 {/* } */}

                       {/* {(itemDisciplineCodeAttributes.visible) && */}
                <Form.Item
                    label={ <FormattedMessage id="INRDesignation" defaultMessage="Designation" />}
                    name="designationSelect"
                    rules={[{ required: true, message: <FormattedMessage id="INRDesignationRequiredMessage" defaultMessage="Please input your Designation!" /> }]} >
                     
                     <Select
                        name="designation"
                        style={{ width: '100%' }}
                        value={this.state.designationAck}
                        onChange={this.handleChangeINRDesignation}

                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={this.state.itemINRDesignations}
                                onChange={(event) => { this.setState({ itemINRDesignations: event.target.value }) }} />
                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                onClick={this.addINRDesignations} >
                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item" />  </a>
                            </div>
                        </div>
                        )}>
                        {this.state.INRDesignations.map(i => (
                            <Option key={i.id} value={i.id}>{i.name}</Option>
                        ))}
                        </Select>
               
                    </Form.Item>
                 {/* } */}

                 <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="4">
                      <Button variant="secondary" onClick={this.showOrHideModalEInspection} style={{ width: '100%' }}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                      </Button>
                    </Col>
                    <Col sm="4">
                      <Button 
                          id="InspectionResultupdateEInspection" type="submit" style={{ width: '100%' }} 
                          variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                      </Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                </FormBoot.Group>
           </Form>
          </Modal.Body>
          <Modal.Footer> 
        </Modal.Footer>
        </Modal>

      <Modal
        show={this.state.modalReceivingInspection1}
        onHide={this.showOrHideModalReceivingInspection1}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="INRPurchaseOrder" defaultMessage="Purchase Order" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>    
          <Col sm="6">
            <Select size='large'
              style={{ width: '100%' }} 
              value={this.state.purchaseOrderId}
              onChange={this.onPurchaseOrderNoChange}
              >
              {this.state.purchaseOrderNos.map(i => ( 
                <Option key={i.id} value={i.id}>{i.quoteNo + "/" + i.revNo.toString() }</Option>
              ))}
          </Select></Col>
          
          <div style={{ backgroundColor: "white", padding: "10px" }}>
            <BootstrapTable style={{ overflowX: 'auto' }} striped hover bordered keyField='id' cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })} 
            data={this.state.purchaseOrderMaterial} columns={columnsReceiving1} 
            pagination={paginationFactory(options)}
            selectRow={ selectRow } 
            {...props.baseProps} 
            striped
            bordered={false}
            wrapperClasses="table-responsive"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.showOrHideModalReceivingInspection1}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          <Button variant="success" onClick={this.saveReceivingInspection1}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={this.state.modalReceivingInspection2}
        onHide={this.showOrHideModalReceivingInspection2}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        >
        <Modal.Header closeButton>
        </Modal.Header>

        <Modal.Body>
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col> 
            <Col sm="3">
              <FormBoot.Check 
              style={{ marginTop: "12px"}}
              checked={true}
              type="checkbox"
              label={<FormattedMessage id="INRPO" defaultMessage="(P.O.#)" />} />
            </Col>
            <Col sm="4">
              <Input style={{ flex: 'auto' }} value={this.state.purchaseOrderNo} readOnly={true}/>
            </Col>
            <Col sm="3">
              <Upload {...props}
                    showUploadList={true}
                    fileList={this.state.FilePO}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {  
                        var POPath="";
                        if(Boolean(fileList[0].response)){
                          POPath=fileList[0].response.url;
                        }
                          this.setState({
                            FilePO:fileList,
                            POPath:POPath,
                          });
                      }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FilePO.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FilePO:[], 
                      POPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>

          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
              style={{ marginTop: "12px"}}
                onChange={(e) => { 
                  this.setState({ 
                    hiddenInvoiceNo: e.target.checked
                  })
                }}
                type="checkbox"
                label={<FormattedMessage id="INRInvoiceNo" defaultMessage="Invoice no" />} />
            </Col>
            <Col sm="4" hidden={!this.state.hiddenInvoiceNo}>
              <Input style={{ flex: 'auto' }} value={this.state.invoiceNo}
                onChange={(event) => { this.setState({ invoiceNo: event.target.value }) }} />
            </Col>
            <Col sm="3" hidden={!this.state.hiddenInvoiceNo}>
              <Upload {...props}
                    showUploadList={true}
                    fileList={this.state.FileInvoiceNo}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {  
                        var invoiceNoPath="";
                        if(Boolean(fileList[0].response)){
                          invoiceNoPath=fileList[0].response.url;
                        }
                          this.setState({
                            FileInvoiceNo:fileList,
                            invoiceNoPath:invoiceNoPath,
                          });
                      }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FileInvoiceNo.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FileInvoiceNo:[], 
                      invoiceNoPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>
       
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
              style={{ marginTop: "12px"}}
                onChange={(e) => { 
                  this.setState({ 
                    hiddenDeliveryNoteNo: e.target.checked
                  })
                }}
                type="checkbox"
                label={<FormattedMessage id="INRDeliveryNoteNo" defaultMessage="Delivery note no" />} />
            </Col>
            <Col sm="4" hidden={!this.state.hiddenDeliveryNoteNo}>
              <Input style={{ flex: 'auto' }} value={this.state.deliveryNoteNo}
                onChange={(event) => { this.setState({ deliveryNoteNo: event.target.value }) }} />
            </Col>
            <Col sm="3" hidden={!this.state.hiddenDeliveryNoteNo}>
              <Upload {...props}
                    showUploadList={true}
                    fileList={this.state.FileDeliveryNoteNo}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {  
                        var deliveryNoteNoPath="";
                        if(Boolean(fileList[0].response)){
                          deliveryNoteNoPath=fileList[0].response.url;
                        }
                          this.setState({
                            FileDeliveryNoteNo:fileList,
                            deliveryNoteNoPath:deliveryNoteNoPath,
                          });
                      }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FileDeliveryNoteNo.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FileDeliveryNoteNo:[], 
                      deliveryNoteNoPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>
        
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
              style={{ marginTop: "12px"}}
                onChange={(e) => { 
                  this.setState({ 
                    hiddenpackingList: e.target.checked
                  })
                }}
                type="checkbox"
                label={<FormattedMessage id="INRPackingList" defaultMessage="Packing List" />} />
            </Col>
            <Col sm="4" hidden={!this.state.hiddenpackingList}>
              <Input style={{ flex: 'auto' }} value={this.state.packingList}
                onChange={(event) => { this.setState({ packingList: event.target.value }) }} />
            </Col>
            <Col sm="3" hidden={!this.state.hiddenpackingList}>
              <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.FilePackingList}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {  
                      var packingListPath="";
                      if(Boolean(fileList[0].response)){
                        packingListPath=fileList[0].response.url;
                      }
                        this.setState({
                          FilePackingList:fileList,
                          packingListPath:packingListPath,
                        });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FilePackingList.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FilePackingList:[], 
                      packingListPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>
           
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
              style={{ marginTop: "12px"}}
                onChange={(e) => { 
                  this.setState({ 
                    hiddentestReport: e.target.checked
                  })
                }}
                type="checkbox"
                label={<FormattedMessage id="INRTestReport" defaultMessage="Test Report" />} />
            </Col>
            <Col sm="4" hidden={!this.state.hiddentestReport}>
              <Input style={{ flex: 'auto' }} value={this.state.testReport}
                onChange={(event) => { this.setState({ testReport: event.target.value }) }} />
            </Col>
            <Col sm="3" hidden={!this.state.hiddentestReport}>
              <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.FileTestReport}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {  
                      var testReportPath="";
                      if(Boolean(fileList[0].response)){
                        testReportPath=fileList[0].response.url;
                      }
                        this.setState({
                          FileTestReport:fileList,
                          testReportPath:testReportPath,
                        });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FileTestReport.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FileTestReport:[], 
                      testReportPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>
        
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
               style={{ marginTop: "12px"}}
                onChange={(e) => { 
                  this.setState({ 
                    hiddenmanufacturerManual: e.target.checked
                  })
                }}
                type="checkbox"
                label={<FormattedMessage id="INRManufacturerManual" defaultMessage="Manufacturer Manual" />} />
            </Col>
            <Col sm="4" hidden={!this.state.hiddenmanufacturerManual}>
            </Col>
            <Col sm="3" hidden={!this.state.hiddenmanufacturerManual}>
              <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.FileManufacturerManual}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {  
                      var manufacturerManualPath="";
                      if(Boolean(fileList[0].response)){
                        manufacturerManualPath=fileList[0].response.url;
                      }
                        this.setState({
                          FileManufacturerManual:fileList,
                          manufacturerManualPath:manufacturerManualPath,
                        });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FileManufacturerManual.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FileManufacturerManual:[], 
                      manufacturerManualPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>
        
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
               style={{ marginTop: "12px"}}
              checked={true}
              type="checkbox"
              label={<FormattedMessage id="INRPackingOfGoods" defaultMessage="Packing of goods" />} />
            </Col>
            <Col sm="4">
              <Mnd.MNDRadioGroup
                onChange={(e) => {
                  var hiddenPackingOfNotGoods=false;
                  if(e.target.value==2){
                    hiddenPackingOfNotGoods=true;
                  }
                  this.setState({
                    packingOfGoods: e.target.value,
                    hiddenPackingOfNotGoods:hiddenPackingOfNotGoods,
                  });
                }}
                radios={radioGroupOptions}
              />
            </Col>
          </FormBoot.Group>
        
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="5"></Col>
            <Col sm="4" hidden={!this.state.hiddenPackingOfNotGoods}>
              <Input style={{ flex: 'auto' }} value={this.state.packingOfNotGoods}
                onChange={(event) => { this.setState({ packingOfNotGoods: event.target.value }) }} />
            </Col>
            <Col sm="3" hidden={!this.state.hiddenPackingOfNotGoods}>
              <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.FilepackingOfNotGoods}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {  
                      var packingOfGoodsNotPath="";
                      if(Boolean(fileList[0].response)){
                        packingOfGoodsNotPath=fileList[0].response.url;
                      }
                        this.setState({
                          FilepackingOfNotGoods:fileList,
                          packingOfGoodsNotPath:packingOfGoodsNotPath,
                        });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.FilepackingOfNotGoods.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState( {
                      FilepackingOfNotGoods:[], 
                      packingOfGoodsNotPath:"",
                    })
                  }
                }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> 
                  </AntdButton>
              </Upload>
            </Col>
          </FormBoot.Group>
       
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="2"></Col>
            <Col sm="3">
              <FormBoot.Check 
              style={{ marginTop: "12px"}}
                 onChange={(e) => { 
                  this.setState({ 
                    hiddenother: e.target.checked
                  })
                }}
                type="checkbox"
                label={<FormattedMessage id="INROther" defaultMessage="Other" />} />
            </Col>
            <Col sm="4" hidden={!this.state.hiddenother}>
              <Input style={{ flex: 'auto' }} value={this.state.other}
                onChange={(event) => { this.setState({ other: event.target.value }) }} />
            </Col>
            <Col sm="3" hidden={!this.state.hiddenother}>
              <Upload {...props}
                showUploadList={true}
                fileList={this.state.FileOther}
                onChange={({ fileList }) => {
                  if (fileList.length == 1) {  
                    var otherPath="";
                    if(Boolean(fileList[0].response)){
                      otherPath=fileList[0].response.url;
                    }
                      this.setState({
                        FileOther:fileList,
                        otherPath:otherPath,
                      });
                  }
              }}
              beforeUpload={(fileList) => {
                if (this.state.FileOther.length > 0) {
                  this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                  return false;
                }
                return true;
              }}
              onRemove={({ fileList }) => {
                this.setState( {
                  FileOther:[], 
                  otherPath:"",
                })
              }
            }>
              <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                <UploadOutlined /> 
              </AntdButton>
          </Upload>
        </Col>
      </FormBoot.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.showOrHideModalReceivingInspection2}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          <Button variant="success" onClick={this.saveReceivingInspection2}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
        </Modal.Footer>
      </Modal>

      </div>
    ));
    }
}

const mapStateToProps = state => {
  return {
      auth: state.auth,
      i18n: state.i18n
  }
}

const mapDispatchToProps = {
   
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionResult)