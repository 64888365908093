/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Card } from "primereact/card";
import { SplitButton } from 'primereact/splitbutton';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Col, Modal, Form } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { ContextMenu } from 'primereact/contextmenu';
import $ from 'jquery';
import { Toast } from 'primereact/toast';

export class EquipmentTypes extends Component {

    constructor() {
        super();
        this.state = {
            nodes: [],
            expandedKeys: {},
            addModal: false,
            addModal2: false,
            deleteModal: false,
            editModal: false,
            equipmentTypeId: null,
            equipmentTypeParentId: null,
            modalEquipmentChildTypeName: "",
            modalEditEquipmentTypeName: "",
            rootEquipmentTypeName: "",
            selectedNodeKey: null,
            globalFilter: null,
            splitButtonMenu: [ 
                {
                    label: 'Add',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'Child',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addChildEquipmentType();
                            }
                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addParentEquipmentType();
                            }
                        }
                        ]   
                    }, 
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editEquipmentType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteEquipmentType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleEquipmentType();
                    }
                }
            ],
            splitButtonMenu2: [
                {
                    label: 'Add',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'Child',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addChildEquipmentType();
                            }
                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addParentEquipmentType();
                            }
                        }
                        ]   
                    }, 
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editEquipmentType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteEquipmentType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleEquipmentType();
                    }
                }
            ]
        };

        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount = async () => {
        const response = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response.data.length === 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }

        document.addEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        event.preventDefault();
        setTimeout(function () {
            $(".p-contextmenu").css("left", event.clientX - 264 + "px");
            $(".p-contextmenu").css("top", event.clientY - 141 + "px");
        }, 50);
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response.data.length === 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }
    }

    actionTemplate(rowData, column) { 
        return <SplitButton type="button" icon="pi pi-cog" className="p-button-secondary" model={this.state.splitButtonMenu} ></SplitButton>;
    }

    toggleEquipmentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        let expandedKeys = { ...this.state.expandedKeys };

        if (expandedKeys[arr[0]])
            delete expandedKeys[arr[0]];
        else
            expandedKeys[arr[0]] = true;

        this.setState({ expandedKeys: expandedKeys });
    }

    addParentEquipmentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal2: true,
            equipmentTypeParentId: arr[0]  //seçili olanın id'si bu.
        });
    }

    addParentEquipmentTypeOK = async () => {
        var response = await handleRequest("GET", "/EquipmentType/" + this.state.equipmentTypeParentId);
        var item;

        if (response !== undefined || response !== null)
            item = response.data;

        var itemNew = {
            parentWillChangeId: this.state.equipmentTypeParentId,
            equipmentTypeName: this.state.modalEquipmentParentTypeName,
            parentId: item.parentId,
        }
  
        if(this.state.modalEquipmentParentTypeName!=null && this.state.modalEquipmentParentTypeName!=""){

        var responseAdd =  await handleRequest("POST", "/EquipmentType/addParent", itemNew);

        if (responseAdd.data !== null && responseAdd.data !== undefined) {
            if (responseAdd.data == "SUCCESS") {
                this.setState({
                    addModal2: false
                });
        
                this.restartControls();
                this.restartTable();
            }
            else  {
                this.showError("An error was occured please try again later !");
            }  
          } 
          else  {
            this.showError("An error was occured please try again later !");
         } 
        } 
        else  {
            this.showError();
        }    
    }

    addChildEquipmentType = () => { 
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal: true,
            equipmentTypeParentId: arr[0]
        });
    }

    addChildEquipmentTypeOK = async () => {
        var item = {
            parentId: this.state.equipmentTypeParentId,
            equipmentTypeName: this.state.modalEquipmentChildTypeName,
        }

        if(this.state.modalEquipmentChildTypeName!=null && this.state.modalEquipmentChildTypeName!=""){
            await handleRequest("POST", "/EquipmentType/add", item);

            this.setState({
                addModal: false
            });

            this.restartControls();
            this.restartTable();
        }else{
            this.showError();
        }
    }

    editEquipmentType = async () => {
        var arr = this.state.selectedNodeKey.split('.');

        var response = await handleRequest("GET", "/EquipmentType/" + arr[0]);

        this.setState({
            editModal: true,
            equipmentTypeId: response.data.id,
            equipmentTypeParentId: response.data.parentId,
            modalEditEquipmentTypeName: response.data.equipmentTypeName
        });
    }

    editEquipmentTypeOK = async () => {
        var item = {
            parentId: this.state.equipmentTypeParentId,
            id: this.state.equipmentTypeId,
            equipmentTypeName: this.state.modalEditEquipmentTypeName,
        }

        if(this.state.modalEditEquipmentTypeName!=null && this.state.modalEditEquipmentTypeName!=""){
            await handleRequest("POST", "/EquipmentType/update", item);

            this.setState({
                editModal: false
            });
    
            this.restartTable();
            this.restartControls();
        }
        else{
            this.showError();
        }
    }

    deleteEquipmentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            deleteModal: true,
            equipmentTypeId: arr[0]
        });
    }

    deleteEquipmentTypeOK = async () => {
        const deletedItem = {
            id: this.state.equipmentTypeId
        }

        await handleRequest("POST", "/EquipmentType/delete", deletedItem);

        this.setState({
            deleteModal: false
        });

        this.restartControls();
        this.restartTable();
    }

    showOrHideModalDeleteEquipmentType = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    showOrHideModalAddChildEquipmentType = () => {
        this.setState({
            addModal: !this.state.addModal
        });
    } 
    
    showOrHideModalAddParentEquipmentType = () => {
        this.setState({
            addModal2: !this.state.addModal2
        });
    }

    showOrHideModalEditEquipmentType = () => {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    saveEquipmentType = async () => { 
        if (this.state.equipmentTypeId === null && this.state.rootEquipmentTypeName !== "") {
            var item = {
                equipmentTypeName: this.state.rootEquipmentTypeName,
            }

            await handleRequest("POST", "/EquipmentType/add", item);

            this.restartTable();
            this.restartControls();
        }else {
            this.showError();
        }  
    }

    restartControls = () => {
        this.setState({
            equipmentTypeId: null,
            equipmentTypeParentId: null,
            rootEquipmentTypeName: "",
            modalEquipmentChildTypeName: "",
            modalEquipmentParentTypeName:"",
            modalEditEquipmentTypeName: ""
        });
    }

    handleSplitMenuChange = e => {
        this.setState({ selectedNodeKey: e.value });
    };

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
      };
    
      showError(msg) {
        if (!msg) {
          msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
      };

    render() {
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        let header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
        </div>;

        return (
            <div>
            <Toast ref={(el) => this.toast = el}></Toast>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteEquipmentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Equipment Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Equipment Type?
                    </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related types.
                    </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteEquipmentType}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteEquipmentTypeOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalAddChildEquipmentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Child Equipment Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Child Equipment Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalEquipmentChildTypeName: e.target.value })}
                                        value={this.state.modalEquipmentChildTypeName} placeholder="Equipment Type Name" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddChildEquipmentType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addChildEquipmentTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal2}
                    onHide={this.showOrHideModalAddParentEquipmentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                        
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Parent Equipment Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Parent Equipment Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalEquipmentParentTypeName: e.target.value })}
                                        value={this.state.modalEquipmentParentTypeName} placeholder="Equipment Type Name" />
                                </Form.Group>
                            </Form.Row> 
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddParentEquipmentType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addParentEquipmentTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal> 

                <Modal
                    show={this.state.editModal}
                    onHide={this.showOrHideModalEditEquipmentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Edit Equipment Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalEditEquipmentTypeName: e.target.value })}
                                        value={this.state.modalEditEquipmentTypeName} placeholder="Equipment Type Name" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalEditEquipmentType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.editEquipmentTypeOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Card style={{ backgroundColor: "white", width: '700px' }}>
                    <Form style={{ padding: '5px 0px 5px 5px' }}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }}>Equipment Type Name:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <InputText style={{ marginTop: '0.2rem', width: '100%' }} onChange={(e) => this.setState({ rootEquipmentTypeName: e.target.value })} value={this.state.rootEquipmentTypeName} placeholder="Equipment Type Name" />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <ReactButton type="button" style={{ width: '100%' }} onClick={this.saveEquipmentType} variant="info">Add</ReactButton>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    <div className="content-section implementation">

                        <ContextMenu model={this.state.splitButtonMenu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />

                        <TreeTable value={this.state.nodes} expandedKeys={this.state.expandedKeys} emptyMessage="No Equipment Types Found"
                            selectionMode="single" selectionKeys={this.state.selectedNodeKey} onSelectionChange={this.handleSplitMenuChange}
                            onToggle={e => this.setState({ expandedKeys: e.value })} globalFilter={this.state.globalFilter} header={header}
                            contextMenuSelection={this.state.selectedNodeKey} onContextMenuSelectionChange={e => this.setState({ selectedNodeKey: e.value })}
                            onContextMenu={e => this.cm.show(e.originalEvent)}>
                            {/*<Column header={actionHeader} body={this.actionTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />*/}
                            <Column field="equipmentTypeName" header="Equipment Type" bodyStyle={{ fontWeight: 'bold' }} expander />
                        </TreeTable>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTypes)