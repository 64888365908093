/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Modal, Form as FormBoot, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { Input, Button as AntButton, Checkbox} from 'antd';
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { CloseOutlined, PlusOutlined, OrderedListOutlined } from '@ant-design/icons';
import { OrderList } from 'primereact/orderlist';

export class MRChecklistTemplate extends Component {
    constructor() {
        super();
        this.state = {
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            MRChecklistData: [],

            dynamicAttributes: [],
            authorizations: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null,
            index:0,
            orderItemList: [],
            modalOrder: false,
            selectedList:[],
        };
    }
    formRef = React.createRef();

    componentDidMount = async () => {
        await this.getDynamicMenu();

        if (this.props.auth !== undefined){
            if (this.props.auth.user !== null) { 
                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });
            }
        }

        this.setStandardizations();
        this.restartTable();
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    restartTable = async () => {
        const response = await handleRequest("GET", "/MRChecklist/getTemplateMRChecklistResult");
        if (response.data.length !== 0) {
            const list =  response.data;
            list.forEach(element => {
                element.key=element.id;
            });
            this.setState({
                MRChecklistData: list, 
                index:list.length + 2,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                MRChecklistData: [],
                index:0
            });
        }
    }

    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "MRChecklist" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });
        } catch (error) {
            console.log(error)
        }
    }

    getDynamicMenu = async () => {
        try {
            const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], "MRChecklist");

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
        } catch (error) {
        }
    } 

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalOrder = async () => {
        this.setState({
            modalOrder: !this.state.modalOrder,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showOrHideWarningMesaj = () => {
        this.setState({
          modalWarningMesaj: !this.state.modalWarningMesaj,
        }); 
        setTimeout(() => {
          document.body.style.overflow = "auto";
          document.body.style.paddingRight = "0px";
      }, 500);
      }
    
    addNewChecklistLine = async () => {
        var newChecklist={
            name:"",
            index:this.state.index++,
            question:"",
            templateDrm:1,
        }
        this.setState({
            index:this.state.index++
        });
        this.setState(prevState => ({
            MRChecklistData: [...prevState.MRChecklistData, newChecklist]
        }))
    }

    removeChecklistLine = async (index) => {
        if(index>0){
            var newChecklist=this.state.MRChecklistData.filter(p => p.index != index)
            var newRemoveItem=this.state.MRChecklistData.filter(p => p.index == index)

            if(newRemoveItem!=null && newRemoveItem!=undefined && newRemoveItem.length==1){
                if(newRemoveItem[0].id>0){
                    this.setState({
                        deleteModal: true,
                        selectedMRChecklist: newRemoveItem[0]
                    });
                }else{
                    this.setState({
                        MRChecklistData:newChecklist,
                    })
                }
            }else{
                this.setState({
                    MRChecklistData:newChecklist,
                })
            }
        }else{
            this.showWarning();
        }
    }   

    deleteMRChecklistTemplateOK = async () => {
        const newMRChecklist = {
            id: this.state.selectedMRChecklist.id
        }
        var response = await handleRequest("POST", "/MRChecklist/delete", newMRChecklist);

        if (response.length!=0 && response.data != null && response.data != undefined) {
            if (response.data === "SUCCESS") {
                var newChecklist=this.state.MRChecklistData.filter(p => p.index != this.state.selectedMRChecklist.index)
                this.setState({
                    MRChecklistData:newChecklist,
                })
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            } 
        }   
        else {
            this.showError();
        } 
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    orderMRChecklist = async () => {
        const response = await handleRequest("GET", "/MRChecklist/getTemplateMRChecklistResult");
        this.setState({
            orderItemList: response.data,
            modalOrder: true
        })
    }

    saveOrder = async () => {
        const list = this.state.orderItemList;
        list.forEach((element, i) => {
            element.orderNo = i
        }); 
        if(list!=null && list!=undefined && list.length!=0){
            await handleRequest("POST", "/MRChecklist/updateOrder", list);
            this.restartTable();
            this.showSuccess();
            this.showOrHideModalOrder();
        }else{
            this.showWarning();
        }
    }

    template(item) {
        return (
            <div className="p-clearfix">
                <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}>{(item.name!=null && item.name!=undefined && item.name!="") == true ? item.name : item.question }</div>
            </div>
        );
    }   
    
    saveAllCheckList = async (kontrolEt) => {
        const list = this.state.selectedList;  
        var selected=[];
        list.forEach(element => {
           var select = this.state.MRChecklistData.filter(p => p.index == element.index);

            if(select!=null && select!=undefined && select.length==1){
                selected.push(select[0]);
            }
        });

        var _orderNo = 0;
        var res = await handleRequest("GET", "/MRChecklist/getOrder");
        if (Boolean(res.data))
            _orderNo = res.data;

        var knt=true;
        selected.forEach((element, i) => {
            if(!(element.question!=null && element.question!=undefined && element.question!="")){
                if(kontrolEt){
                    knt=false;
                    return;
                }
            }
            element.orderNo = i + _orderNo;
            element.templateDrm=0;
            if(!(element.name!=null && element.name!=undefined && element.name!="")){
                element.name=element.question;
            } 
        }); 

        if(knt){
            if(selected!=null && selected!=undefined && selected.length!=0){
                var response = await handleRequest("POST", "/MRChecklist/addNewMRChecklist", selected);
                if (response.data !== null && response.data !== undefined) {
                    if (response.data === "SUCCESS") {
                        this.showSuccess();
                        this.setState({
                            modalWarningMesaj:false
                        });
                    }
                    else{
                        this.showWarning("No records to be added!");
                    }
                }else{
                    this.showError();
                }
            }else{
                this.showWarning();
            }
        }else{
            this.setState({
                modalWarningMesaj:true
            });
        } 
    }

    render() {
        return <div>
                <MenuProvider id="menu_id"> 
                    <div>
                        <Row>
                            <Col sm="1">
                                <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>
                                }
                            </Col>
                        </Row>
                    </div>  
                    <Col sm="1">
                        <AntButton type="primary" onClick={this.orderMRChecklist} icon={<OrderedListOutlined />}></AntButton>
                    </Col>
                    <br/>
                    {this.state.MRChecklistData.map((item, index) => (
                        <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                            <Col sm="5">
                                <Checkbox onChange={(e) => {
                                    if(e.target.checked){
                                        this.setState(prevState => ({
                                            selectedList: [...prevState.selectedList, item]
                                        }));
                                    }else{
                                        var newChecklist=this.state.selectedList.filter(p => p.index != item.index)
                                        this.setState({
                                            selectedList:newChecklist,
                                        })
                                    }
                                }} style={{ color: 'black', marginTop: '0.25rem' }}>{item.templateDrm==1 ? item.name: ""}</Checkbox>
                            </Col>
                            <Col sm="5">
                                <Input value={item.question} style={{ width: "100%" }} onChange={(e) => {
                                    e.persist();
                                    this.setState(prevState => ({
                                        MRChecklistData: prevState.MRChecklistData.map(
                                        el => el.id === item.id ? { ...el, question: e.target.value } : el
                                    )
                                })) 
                            }}/>
                            </Col>
                            <Col sm="1">
                                {
                                   (item.name=="" || item.name==null || item.name==undefined || item.templateDrm==0) && <AntButton type="primary" onClick={() => this.removeChecklistLine(item.index)} danger icon={<CloseOutlined />}></AntButton>
                                }
                            </Col> 
                           <Col sm="1">
                                {index==this.state.MRChecklistData.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <AntButton type="primary" onClick={this.addNewChecklistLine} icon={<PlusOutlined />}></AntButton>
                                }
                            </Col> 
                        </FormBoot.Group>
                    ))} 

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2">
                        </Col>
                        <Col sm="8">
                            <ReactButton id="MRChecklistSaveButton"
                                style={{ width: '100%' }} onClick={() => this.saveAllCheckList(true)}  
                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                        </Col>
                        <Col sm="2">
                        </Col>
                    </FormBoot.Group>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>
                }
                
            <Modal
                show={this.state.modalMessage}
                onHide={this.showOrHideModalMessage}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    <Row > 
                        <Col sm="12">
                        {this.state.messageTip=="Success" &&  
                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                        
                        {this.state.messageTip=="Warning" &&  
                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                        {this.state.messageTip=="Error" &&  
                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                        </Col>
                    </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row > 
                    <Col sm="12">
                        {this.state.message} 
                    </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                </Modal.Footer>
                </Modal>

            <Modal
                show={this.state.deleteModal}
                onHide={this.showOrHideModalDelete}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                    </p>
                    <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </ReactButton>
                    <ReactButton variant="danger" onClick={this.deleteMRChecklistTemplateOK}>
                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                    </ReactButton>
                </Modal.Footer>
            </Modal>
        
            <Modal
                show={this.state.modalOrder}
                onHide={this.showOrHideModalOrder}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Row >
                            <Col sm="12">
                                <FormattedMessage id="DYNMOrderSettings" defaultMessage="Order Settings" />
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row >
                        <Col sm="12">
                            <FormBoot>
                                <FormBoot.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                    <Col sm="12">
                                        {this.state.orderItemList.length > 0 &&
                                            <OrderList value={this.state.orderItemList} responsive={true}
                                                itemTemplate={this.template}
                                                onChange={(e) => this.setState({ orderItemList: e.value })}></OrderList>}
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton
                        variant="secondary"
                        onClick={this.showOrHideModalOrder} >
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </ReactButton>
                    <ReactButton variant="success" onClick={this.saveOrder}>
                        <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                    </ReactButton>
                </Modal.Footer>
            </Modal>

            <Modal
                show={this.state.modalWarningMesaj}
                onHide={this.showOrHideWarningMesaj}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered size="md">

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Row >
                            <Col sm="12">
                            <FormattedMessage id="MRChecklistGeneralWarningMesaj" defaultMessage="You did not enter a question for the record you selected. Would you like to enter?" />
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Row >
                        <Col sm="3">
                        </Col>
                        <Col sm="3">
                        <ReactButton variant="secondary" onClick={this.showOrHideWarningMesaj} style={{ width: "100%" }}><FormattedMessage id="GeneralTextYes" defaultMessage="Yes" /></ReactButton>
                        </Col>
                        <Col sm="3">
                        <ReactButton variant="danger" onClick={() => this.saveAllCheckList(false)} style={{ width: "100%" }}><FormattedMessage id="GeneralTextNo" defaultMessage="No" /></ReactButton>
                        </Col>
                        <Col sm="3">
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
   
            </div>
         
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MRChecklistTemplate)