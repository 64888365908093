


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button as PrimeButton } from "primereact/button";
import { Card, Button } from 'react-bootstrap';
import { Container, Col, Form, Row, Modal } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import "../../pages/settings/css/DataTable.scss";
import { OrderList } from 'primereact/orderlist';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEditRoleDocItem';
import { Table, Input, Checkbox, Upload, Button as AntButton, Select, InputNumber, Cascader, Radio } from 'antd';
import { UploadOutlined, PlusOutlined, UndoOutlined, CloseCircleOutlined, RightCircleOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class RoleDocumentItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenUpdateRoleDocumentItem: true,
      tablehidden: false,
      hiddenItemGroupCodeDescription: false,
      createNewButton: "Create New",
      createNewButtonActivity: "Create New",
      hiddenAmendDocument: false,
      lastButton: "Save",
      createNewButtonVariant: "info",
      createNewButtonActivityVariant: "info",

      revno: 0,

      activityAddItems: false,
      datatableValue: [],

      documentLogId: -1,

      itemNo: 1,
      roleDocumentTreeTable: [],
      id: -1,

      popup: false,
      messageTip: "",

      itemGroup: false,
      hiddenItemGroup: false,
      redirect: false,
      selectRoleDocument: [],
      selectRoleDocumentItem: [],
      RoleDocumentTreeTableKayit: 0,
      roleDocumentItemGroups: [],
      roleDocumentDescriptions: [],
      MaintChecklistCriterias: [],
      MaintChecklistAcceptances: [],
      MaintChecklistActions: [],

      RoleDocumentItem: [],
      RoleDocumentItemKayit: 0,
      hiddenDataGiris: false,
      hiddenDocument: false,
      parentId: 0,


      orderItemList: [],
      modalOrderDocument: false,
      modalOrderDocumentItem: false,
      itemOrder: 0,
    }

    this.actionTemplate = this.actionTemplate.bind(this);
    this.actionTemplateRoleDocumentItem = this.actionTemplateRoleDocumentItem.bind(this);
    this.OrderDocument = this.OrderDocument.bind(this);
    this.OrderDocumentItem = this.OrderDocumentItem.bind(this);
  }

  componentDidMount = async () => {
    await this.deleteRoleDocumentItemTemp();

    this.restartTable();
  }

  restartTable = async () => {
    var deger = 0;
    if (this.props.location != null && this.props.location != undefined && this.props.location != "") {
      deger = this.props.location.pathname.split('/')[2];
      this.setState({
        popup: false
      });
    } else if (this.props.id != null && this.props.id != 0 && this.props.id != "") {
      deger = this.props.id;
      this.setState({
        popup: true
      });
    } else {
      deger = -1;
      this.setState({
        popup: true
      });
    }
    await this.setState({
      documentLogId: deger,
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenUpdateRoleDocumentItem: true,
      hiddenAmendDocument: false,
    });

    if (deger > 0) {
      this.getRoleDocument(deger);
    } else {
      this.getRoleDocumentTemp();
    }
    this.createNewClientButton();
    this.getDocumentLog(deger)
    this.getRoleDocumentItemGroup();
    this.getTableProperties();
  }

  deleteRoleDocumentItemTemp = async () => {
    await handleRequest("GET", "/RoleDocumentItemTemp/deleteTemp");
  };

  getRoleDocument = async (deger) => {
    if (deger == null || deger == undefined || deger == 0) {
      deger = -1;
    }
    const newObj = {
      documentLogId: deger
    }
    const responseTree = await handleRequest("POST", "/RoleDocument/getTreeRoleDocument", newObj);
    if (Boolean(responseTree.data) && responseTree.data.length !== 0) {
      responseTree.data.forEach(record => {
        this.deleteChildren(record);
      });
      this.setState({
        roleDocumentTreeTable: responseTree.data,
        RoleDocumentTreeTableKayit: responseTree.data[responseTree.data.length - 1].id,
      });
    } else {
      this.setState({
        roleDocumentTreeTable: [],
        RoleDocumentTreeTableKayit: 0,
      });
    }
  }

  getRoleDocumentTemp = async () => {
    const responseTree = await handleRequest("GET", "/RoleDocument/getTreeRoleDocumentTemp");
    if (Boolean(responseTree.data) && responseTree.data.length !== 0) {
      responseTree.data.forEach(record => {
        this.deleteChildren(record);
      });
      this.setState({
        roleDocumentTreeTable: responseTree.data,
        RoleDocumentTreeTableKayit: responseTree.data[responseTree.data.length - 1].id,
      });
    } else {
      this.setState({
        roleDocumentTreeTable: [],
        RoleDocumentTreeTableKayit: 0,
      });
    }
  }

  getTableProperties = async () => {
    this.RoleDocumentItem = JSON.parse(localStorage.getItem('RoleDocumentItem'));
    if (this.RoleDocumentItem != null && this.RoleDocumentItem != undefined && this.RoleDocumentItem != "") {

      await this.setState({
        bordered: this.RoleDocumentItem.bordered,
        title: this.RoleDocumentItem.titleDrm == true ? title : undefined,
        titleDrm: this.RoleDocumentItem.titleDrm,
        showHeader: this.RoleDocumentItem.showHeader,
        footer: this.RoleDocumentItem.footerDrm == true ? footer : undefined,
        footerDrm: this.RoleDocumentItem.footerDrm,
        expandable: this.RoleDocumentItem.expandableDrm == true ? expandable : {},
        expandableDrm: this.RoleDocumentItem.expandableDrm,
        rowSelection: this.RoleDocumentItem.rowSelection,
        ellipsis: this.RoleDocumentItem.ellipsis,
        tableLayout: this.RoleDocumentItem.tableLayout,
        size: this.RoleDocumentItem.size,
        top: this.RoleDocumentItem.top,
        bottom: this.RoleDocumentItem.bottom,
        editBgColor: this.RoleDocumentItem.editBgColor,
        deleteBgColor: this.RoleDocumentItem.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.RoleDocumentItem.yScroll,
        xScroll: this.RoleDocumentItem.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.roleDocumentTreeTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.roleDocumentTreeTable.length, showQuickJumper: true,
          position: [this.RoleDocumentItem.top, this.RoleDocumentItem.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  deleteChildren = (element) => {
    if (Boolean(element.children) && element.children.length > 0) {
      element.children.forEach(el => {
        this.deleteChildren(el);
      });
    } else {
      delete element["children"];
    }
  }

  getDocumentLog = async (deger) => {
    const newObj = {
      id: deger
    }
    const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj);
    if (response.data.length !== 0) {
      var revno = 0;
      var roleDescriptionCode = "";
      var roleDescription = "";
      if (this.state.hiddenAmendDocument == true) {
        if (this.state.popup == false) {
          revno = response.data[0].revno;
        } else {
          revno = response.data[0].revno + 1;
        }
      } else {
        revno = response.data[0].revno;
      }
      roleDescriptionCode = response.data[0].code;
      roleDescription = response.data[0].description;
      this.setState({
        hiddenDocument: false,
        revno: revno,
        roleDescriptionCode: roleDescriptionCode,
        roleDescription: roleDescription,
      });
    }
    else {
      this.setState({
        hiddenDocument: true,
        revno: 0,
        roleDescriptionCode: "",
        roleDescription: "",
      });
    }
  }

  getRoleDocumentItemGroup = async () => {
    const response = await handleRequest("GET", "/RoleDocumentItemGroup/getItemGroup");
    if (response.data.length !== 0) {
      this.setState({ roleDocumentItemGroups: response.data });
    } else { this.setState({ roleDocumentItemGroups: [] }); }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);

    if (this.state.redirect && this.state.popup == false && (!(this.state.documentLogId != null && this.state.documentLogId != undefined && this.state.documentLogId > 0))) {
      this.setState({
        redirect: false,
      });
      window.location.href = "/DocumentLog";
    }
  }

  showOrHideModalOrderDocument = async () => {
    this.setState({
      modalOrderDocument: !this.state.modalOrderDocument,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalOrderDocumentItem = async () => {
    this.setState({
      modalOrderDocumentItem: !this.state.modalOrderDocumentItem,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  resetInputs = () => {
    this.setState({
      selectRoleDocument: [],
      selectRoleDocumentItem: [],
      RoleDocumentItem: [],
      itemGroup: false,
      hiddenItemGroup: false,
      hiddenItemGroupCodeDescription: false,

      roleDocumentItemGroupId: "",
      itemCode: "",
      itemDescription: "",
      itemOrder: 0,
    });
  }

  createNewClientButton = () => {
    this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
    }
    else {
      this.setState({
        createNewButton: "Create New",
        createNewButtonActivity: "Create New",
        activityAddItems: false,
        lastButton: "Save",
        createNewButtonVariant: "info",
        createNewButtonActivityVariant: "info"

      });
    }
  }

  addRoleDocumentShow = () => {
    this.resetInputs();
    if (this.state.createNewButtonActivity == "Create New") {
      this.setState({
        createNewButtonActivity: "Cancel",
        createNewButtonActivityVariant: "danger",
        activityAddItems: true,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenUpdateRoleDocumentItem: true,
      });
    } else {
      this.setState({
        createNewButtonActivity: "Create New",
        createNewButtonActivityVariant: "info",
        activityAddItems: false,
        hiddenSave: true,
        hiddenUpdate: true,
        hiddenUpdateRoleDocumentItem: true,
      });
    }
  }

  addRoleDocumentItems = async () => {
    var roleDocumentItems = [];

    if (Boolean(this.state.RoleDocumentItem) && this.state.RoleDocumentItem.length > 0) {
      roleDocumentItems = this.state.RoleDocumentItem;
    } else {
      if (Boolean(this.state.roleDocumentItemGroupId) && Boolean(this.state.itemCode) && Boolean(this.state.itemDescription)) {
        var newObj = {
          itemCode: this.state.itemCode,
          itemDescription: this.state.itemDescription,
          itemOrder: this.state.itemOrder,
          documentLogId: this.state.documentLogId,
          roleDocumentId: this.state.roleDocumentItemGroupId,
        }
        roleDocumentItems.push(newObj);
      }
    }
    if (Boolean(roleDocumentItems) && roleDocumentItems.length > 0) {
      var response = [];
      if (this.state.documentLogId > 0) {
        const addRoleDocument = {
          roleDocumentItems: roleDocumentItems,
        }
        response = await handleRequest("POST", "/RoleDocumentItem/add", addRoleDocument);
      } else {
        const addRoleDocument = {
          roleDocumentItemTemps: roleDocumentItems,
        }
        response = await handleRequest("POST", "/RoleDocumentItemTemp/add", addRoleDocument);
      }
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    } else {
      this.showWarning();
    }
  }

  updateDone = async () => {
    const updatedRoleDocument = {
      id: this.state.selectRoleDocument.id,
      documentLogId: this.state.documentLogId,

      itemOrder: this.state.itemOrder,
      itemCode: this.state.itemCode,
      itemDescription: this.state.itemDescription,
      roleDocumentId: this.state.roleDocumentItemGroupId,
    }

    if (this.state.documentLogId > 0) {
      var response = await handleRequest("POST", "/RoleDocument/update", updatedRoleDocument);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    } else {
      var response = await handleRequest("POST", "/RoleDocumentItemTemp/update", updatedRoleDocument);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    }
  };

  updateDoneRoleDocumentItem = async () => {
    const updatedRoleDocument = {
      id: this.state.selectRoleDocumentItem.id,
      documentLogId: this.state.documentLogId,

      itemOrder: this.state.itemOrder,
      itemCode: this.state.itemCode,
      itemDescription: this.state.itemDescription,
      roleDocumentId: this.state.roleDocumentItemGroupId,
    }

    if (this.state.documentLogId > 0) {
      var response = await handleRequest("POST", "/RoleDocumentItem/update", updatedRoleDocument);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    } else {
      var response = await handleRequest("POST", "/RoleDocumentItemTemp/update", updatedRoleDocument);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    }
  };

  actionTemplate(rowData, column) {
    return <React.Fragment>
      <CustomMaterialMenu row={rowData}
        onOrderRow={this.OrderDocument.bind(this)}
        editKnt={false}
        deleteKnt={false}
      />
    </React.Fragment>;
  };

  actionTemplateRoleDocumentItem(rowData, column) {
    return <React.Fragment>
      <CustomMaterialMenu row={rowData}
        onDeleteRow={this.changeButtonDeleteModalItem.bind(this)}
        onEditRow={this.changeButtonUpdateRoleDocumentItem.bind(this)}
        onOrderRow={this.OrderDocumentItem.bind(this)}
        editKnt={true}
        deleteKnt={true}
      />
    </React.Fragment>;
  };

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalDeleteItem = () => {
    this.setState({
      deleteModalItem: !this.state.deleteModalItem
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonDeleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectRoleDocument: row
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  deleteRoleDocumentOK = async () => {
    if (Boolean(this.state.selectRoleDocument) && this.state.selectRoleDocument.id > 0) {
      const deleteRoleDocument = {
        id: this.state.selectRoleDocument.id
      }
      var response = await handleRequest("POST", "/RoleDocument/delete", deleteRoleDocument);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.restartTable();
          this.showSuccess();
          this.showOrHideModalDelete();
        }
        else {
          this.showError();
        }
      }
    } else {
      this.showWarning();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  changeButtonDeleteModalItem = (row) => {
    this.setState({
      deleteModalItem: true,
      selectRoleDocumentItem: row
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  deleteRoleDocumentItemOK = async () => {
    if (Boolean(this.state.selectRoleDocumentItem) && this.state.selectRoleDocumentItem.id > 0) {
      const deleteRoleDocument = {
        id: this.state.selectRoleDocumentItem.id
      }
      if (this.state.documentLogId > 0) {
        var response = await handleRequest("POST", "/RoleDocumentItem/delete", deleteRoleDocument);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.restartTable();
            this.showSuccess();
            this.showOrHideModalDeleteItem();
          }
          else {
            this.showError();
          }
        }
      }
      else {
        var response = await handleRequest("POST", "/RoleDocumentItemTemp/delete", deleteRoleDocument);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.restartTable();
            this.showSuccess();
            this.showOrHideModalDeleteItem();
          }
          else {
            this.showError();
          }
        }
      }
    } else {
      this.showWarning();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  changeButtonUpdateRoleDocumentItem = (rowData) => {
    this.setState({
      hiddenUpdate: true,
      hiddenUpdateRoleDocumentItem: false,
      hiddenSave: true,
      hiddenItemGroupCodeDescription: true,

      selectRoleDocumentItem: rowData,

      itemCode: rowData.itemCode,
      itemDescription: rowData.itemDescription,
      roleDocumentItemGroupId: rowData.roleDocumentId,
      documentLogId: rowData.documentLogId,
      itemOrder: rowData.itemOrder,

      createNewButtonActivity: "Cancel",
      createNewButtonActivityVariant: "danger",
      activityAddItems: true,
    });
  };

  changeButtonCancel = () => {
    this.setState({
      hiddenUpdate: true,
      hiddenUpdateRoleDocumentItem: true,
      hiddenSave: true,
      createNewButtonActivity: "Create New",
      createNewButtonActivityVariant: "info",
      activityAddItems: false,
    });
  };

  onChangeroleDocumentItemGroupId = (value) => {
    var deger = "";
    for (var z = 0; z < this.state.roleDocumentItemGroups.length; z++) {
      if (this.state.roleDocumentItemGroups[z].roleDocumentId == value) {
        deger = this.state.roleDocumentItemGroups[z].itemGroupCode + " / " + this.state.roleDocumentItemGroups[z].itemGroupDescription;
      }
    }
    this.setState({
      roleDocumentItemGroupId: value,
    });
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  addNewRoleDocumentItem = async () => {
    if (Boolean(this.state.roleDocumentItemGroupId) && Boolean(this.state.itemCode) && Boolean(this.state.itemDescription)) {
      await this.setState({
        RoleDocumentItemKayit: this.state.RoleDocumentItemKayit + 1,
      });
      this.setState(prevState => ({
        RoleDocumentItem: [...prevState.RoleDocumentItem, {
          key: this.state.RoleDocumentItemKayit,
          id: 0,
          index: this.state.RoleDocumentItemKayit,
          itemCode: this.state.itemCode,
          itemDescription: this.state.itemDescription,
          itemOrder: this.state.itemOrder,
          documentLogId: this.state.documentLogId,
          roleDocumentId: this.state.roleDocumentItemGroupId,
        }],
        itemCode: "",
        itemDescription: "",
        itemOrder: 0,
      }));
    } else {
      this.showWarning();
    }
  };

  OrderDocument = async (rowData) => {
    const response = await handleRequest("GET", "/RoleDocument/getAllByCompany");

    if (Boolean(response.data)) {
      this.setState({
        orderItemList: response.data,
        modalOrderDocument: true
      })
    } else {
      this.setState({
        orderItemList: [],
        modalOrderDocument: true
      })
    }
  }

  OrderDocumentItem = async (rowData) => {
    const obj = {
      roleDocumentId: rowData.roleDocumentId,
      documentLogId: rowData.documentLogId,
    };
    const response = await handleRequest("POST", "/RoleDocumentItem/getResult", obj);

    if (Boolean(response.data)) {
      this.setState({
        orderItemList: response.data,
        modalOrderDocumentItem: true
      })
    } else {
      this.setState({
        orderItemList: [],
        modalOrderDocumentItem: true
      })
    }
  }

  templateDocumentItem(item) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}>{item.itemCode}</div>
      </div>
    );
  }

  templateDocument(item) {
    return (
      <div className="p-clearfix">
        {Boolean(item.roleDocumentItemGroup) && <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}>{item.roleDocumentItemGroup.itemGroupCode}</div>}
      </div>
    );
  }

  saveOrderDocumentItem = async () => {
    const list = this.state.orderItemList;
    list.forEach((element, i) => {
      element.itemOrder = i
    });

    await handleRequest("POST", "/RoleDocumentItem/updateOrder", list);

    this.restartTable();
    this.setState({
      modalOrderDocumentItem: false,
    });
  }

  saveOrderDocument = async () => {
    const list = this.state.orderItemList;
    list.forEach((element, i) => {
      element.itemOrder = i
    });

    await handleRequest("POST", "/RoleDocument/updateOrder", list);

    this.restartTable();
    this.setState({
      modalOrderDocument: false,
    });
  }

  render() {

    const columns = [
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("RoleDocumentItem")} icon="pi pi-cog"></PrimeButton>,
        dataIndex: "index"
      },
      {
        key: "key",
        title: <FormattedMessage id="RoleDocumentItemGroupCode" defaultMessage="Item Group Code" />,
        render: (record) => {
          if (Boolean(record) && Boolean(record.roleDocumentItemGroup)) {
            return record.roleDocumentItemGroup.itemGroupCode;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="RoleDocumentItemGroupDescription" defaultMessage="Item Group Description" />,
        render: (record) => {
          if (Boolean(record) && Boolean(record.roleDocumentDescription)) {
            return record.roleDocumentDescription.itemGroupDescription;
          }
          return "";
        }
      },
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const columnsCodeDescription = [
      {
        title: "",
        key: "key",
        dataIndex: "index",
      },
      {
        key: "key",
        dataIndex: "itemCode",
        title: <FormattedMessage id="RoleDocumentItemCode" defaultMessage="Item Code" />,
      },
      {
        key: "key",
        dataIndex: "itemDescription",
        title: <FormattedMessage id="RoleDocumentItemDescription" defaultMessage="Item Description" />,
      },
      {
        key: "key",
        title: "",
        render: this.actionTemplateRoleDocumentItem
      },
    ];

    const tableColumnsCodeDescription = columnsCodeDescription.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsCodeDescription[0].fixed = true;
      tableColumnsCodeDescription[tableColumnsCodeDescription.length - 1].fixed = 'right';
    }

    return (
      <div>
        <div hidden={this.state.hiddenDataGiris} className="col-md-12" style={{ background: "white" }}>
          <br />
          <Card.Body>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col xs={8}></Col>
                <Col xs={3}>
                  <Button onClick={this.addRoleDocumentShow} style={{ width: '100%' }}
                    variant={this.state.createNewButtonActivityVariant}> {this.state.createNewButtonActivity}</Button>
                </Col>
                <Col xs={1}>
                  <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("RoleDocumentItem")} icon="pi pi-cog"></PrimeButton>
                </Col>
              </Row>
              <br />

              <div style={{ display: this.state.activityAddItems ? 'block' : 'none' }}>

                <Form.Group hidden={this.state.hiddenDocument} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridroleDescriptionCode">
                  <Form.Label column sm="2" >Role Description Code</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.roleDescriptionCode} style={{ border: '0px' }} readOnly={true} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenDocument} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridroleDescription">
                  <Form.Label column sm="2" >Role Description</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.roleDescription} style={{ border: '0px' }} readOnly={true} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenDocument} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridRevNo">
                  <Form.Label column sm="2" >Rev No</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.revno} style={{ border: '0px' }} readOnly={true} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Form.Label column sm="2" >Item Group Code / Description</Form.Label>
                  <Col sm="4">
                    <Select size='large'
                      style={{ width: '100%' }}
                      value={this.state.roleDocumentItemGroupId}
                      onChange={this.onChangeroleDocumentItemGroupId}
                    >
                      {this.state.roleDocumentItemGroups.map(group => (
                        (Boolean(group.itemGroupCode) && Boolean(group.itemGroupDescription)) &&
                        <Option key={group.roleDocumentId} value={group.roleDocumentId}>{
                          group.itemGroupCode + " / " + group.itemGroupDescription
                        }</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Form.Label column sm="2" >Item Code</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.itemCode} onChange={(e) =>
                      this.setState({ itemCode: e.target.value })} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Form.Label column sm="2" >Item Description</Form.Label>
                  <Col sm="4">
                    <Input value={this.state.itemDescription} onChange={(e) =>
                      this.setState({ itemDescription: e.target.value })} />
                  </Col>
                  <Col sm="1">
                    {!this.state.hiddenItemGroupCodeDescription &&
                      <AntButton type="primary" onClick={this.addNewRoleDocumentItem} icon={<PlusOutlined />}></AntButton>
                    }
                  </Col>
                </Form.Group>
                <br />
                <div hidden={this.state.hiddenSave}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2">
                    </Col>
                    <Col sm="4">
                      <Button
                        style={{ width: '100%' }} onClick={this.addRoleDocumentItems}
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdate}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="2">
                      <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdateRoleDocumentItem}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="2">
                      <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button onClick={this.updateDoneRoleDocumentItem} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </Form.Group>
                </div>
              </div>
              <br />
              <div className="content-section implementation">
                {this.state.documentLogId > 0 ?
                  <Table columns={tableColumns} dataSource={this.state.roleDocumentTreeTable} pagination={this.state.pagination}
                    responsive striped bordered hover
                    loading={this.state.loading}
                    style={{ padding: "4px 1rem 4px 4px" }}
                    expandable={{
                      expandedRowRender: record => {
                        if (Boolean(record.roleDocumentItems) && record.roleDocumentItems.length > 0) {
                          return <Table columns={tableColumnsCodeDescription} dataSource={record.roleDocumentItems} pagination={false} responsive striped bordered hover loading={this.state.loading}
                            style={{ padding: "4px 1rem 4px 4px" }}
                          />;
                        }
                      }
                      , rowExpandable: record => (Boolean(record.roleDocumentItems) && record.roleDocumentItems.length > 0) == true ? true : false,
                    }}
                  />
                  :
                  <Table columns={tableColumns} dataSource={this.state.roleDocumentTreeTable} pagination={this.state.pagination}
                    responsive striped bordered hover
                    loading={this.state.loading}
                    style={{ padding: "4px 1rem 4px 4px" }}
                    expandable={{
                      expandedRowRender: record => {
                        if (Boolean(record.roleDocumentItemTemps) && record.roleDocumentItemTemps.length > 0) {
                          return <Table columns={tableColumnsCodeDescription} dataSource={record.roleDocumentItemTemps} pagination={false} responsive striped bordered hover loading={this.state.loading}
                            style={{ padding: "4px 1rem 4px 4px" }}
                          />;
                        }
                      }
                      , rowExpandable: record => (Boolean(record.roleDocumentItemTemps) && record.roleDocumentItemTemps.length > 0) == true ? true : false,
                    }}
                  />
                }
              </div>
              <br />
            </Container>
          </Card.Body>
        </div>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteRoleDocumentOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModalItem}
          onHide={this.showOrHideModalDeleteItem}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteRoleDocumentItemOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.modalOrderDocumentItem}
          onHide={this.showOrHideModalOrderDocumentItem}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="md">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="DYNMOrderSettings" defaultMessage="Order Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <Form>
                  <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Col sm="12">
                      {this.state.orderItemList.length > 0 &&
                        <OrderList value={this.state.orderItemList} responsive={true}
                          itemTemplate={this.templateDocumentItem}
                          onChange={(e) => this.setState({ orderItemList: e.value })}></OrderList>}
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalOrderDocumentItem} >
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="success" onClick={this.saveOrderDocumentItem}>
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalOrderDocument}
          onHide={this.showOrHideModalOrderDocument}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="md">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="DYNMOrderSettings" defaultMessage="Order Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <Form>
                  <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Col sm="12">
                      {this.state.orderItemList.length > 0 &&
                        <OrderList value={this.state.orderItemList} responsive={true}
                          itemTemplate={this.templateDocument}
                          onChange={(e) => this.setState({ orderItemList: e.value })}></OrderList>}
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalOrderDocument} >
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="success" onClick={this.saveOrderDocument}>
              <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div >
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RoleDocumentItem)
