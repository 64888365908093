/* eslint-disable */

import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Divider} from "@material-ui/core";
import { Typography } from "@material-ui/core";

export default ({ row, onDeleteRow, onEditRow, onClose, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const close = () => {
    if (onClose) {
      onClose(row);  
      setAnchorEl(null);
    }
  };
 

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu" // flaticon-layer
        aria-haspopup="true"
        onClick={handleClick}
        size={size}        
      >
        <i className={`kt-menu__link-icon  flaticon-layer`} />
        {/* <MoreVertIcon /> */}
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Edit
          </Typography>
        </MenuItem>

        <Divider /> 

        <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
            Delete
          </Typography>
        </MenuItem>

        <MenuItem onClick={close} style={{ backgroundColor: '#' + editBgColor }}>
          <ListItemIcon>
            <PostAddOutlined fontSize="small" color="info" />
          </ListItemIcon>
          <Typography variant="inherit">  
            Close
          </Typography>
        </MenuItem>

     

      </Menu>
    </div>
  );
};