/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import 'antd/es/cascader/style/index.css';
import { Form, Col, Row } from 'react-bootstrap';
import { Cascader } from 'antd';
import { DatePicker } from 'antd';

const options = [
    {
        value: 'zhejiang',
        label: 'Zhejiang',
        children: [
            {
                value: 'hangzhou',
                label: 'Hangzhou',
                children: [
                    {
                        value: 'xihu',
                        label: 'West Lake',
                    },
                ],
            },
        ],
    },
    {
        value: 'jiangsu',
        label: 'Jiangsu',
        children: [
            {
                value: 'nanjing',
                label: 'Nanjing',
                children: [
                    {
                        value: 'zhonghuamen',
                        label: 'Zhong Hua Men',
                    },
                ],
            },
        ],
    },
];

function onChange(value) {
    console.log(value);
}
function onDpChange(date, dateString) {
    console.log(date, dateString);
}
export class TestMetronic extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount = () => {

    };



    render() {
        return (
            <div>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCascader">
                            <Cascader options={options} onChange={onChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCascader">
                            <DatePicker onChange={onDpChange} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(TestMetronic)