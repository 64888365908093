/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt, Space } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "MaterialRequisitionTemplate";
const defaulltToc = [
  {
    id: 0,
    description: 'Table Of Contents',
    orderNumber: 1,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Material Requisition Technical Approval Sheet',
    orderNumber: 2,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Material Requisition Review Checklist',
    orderNumber: 3,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Saudi Aramco Approved Vendor List',
    orderNumber: 4,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Project Specification',
    orderNumber: 5,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Material Data Sheet',
    orderNumber: 6,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Level of Inspection',
    orderNumber: 7,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Inspection Requirements – SA-175 Form',
    orderNumber: 8,
    seperatePage: true,
    documentId: null,
    documentKey: 'FORM175',
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Reference Standards (ARAMCO, SEC, STC, etc)',
    orderNumber: 9,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Applicable Design Drawings',
    orderNumber: 10,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Packing Requirements',
    orderNumber: 11,
    seperatePage: true,
    documentId: null,
    documentKey: 'SAEP',
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },
  {
    id: 0,
    description: 'Spare Parts Data Package (SPDP)',
    orderNumber: 12,
    seperatePage: true,
    documentId: null,
    documentKey: null,
    showInReport: true,
    allPages: true,
    fromPage: null,
    toPage: null
  },

]
export class MaterialRequisitionTemplate extends Component {

  constructor() {
    super();
    this.state = {
      modalDeleteShow: false,
      hideInputs: true,
      hideSave: false,
      hideUpdate: true,

      id: null,
      tableList: [],
      filePath: '',
      fileList: [],
      headerTemplate: '',
      footerTemplate: '',
      header: '',
      tableOfContents: [],

      seperatePage: false,
      description: '',
      documentId: null,
      documentKey: '',
      allPages: true,
      fromPage: null,
      toPage: null,
      documentSelectItems: [],

      dynamicAttributes: [],
      authorizations: [],
      dynamicInputs: [],
      isAdmin: false,
      howToUseSetting: false,
      whatIsSetting: false,

      formId: 0
    };


  }

  formRef = React.createRef();


  componentDidMount = async () => {

    var isAdmin = false;
    let dynamicMenu = await getDynamicMenu(pageName);
    this.getUserSettings(dynamicMenu.formId);
    if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
      isAdmin = true;
    }

    let standardization = await setStandardization(pageName);
    this.setState({
      dynamicAttributes: standardization.dynamicAttributes,
      dynamicInputs: standardization.dynamicInputs,
      isAdmin: isAdmin,
      formId: dynamicMenu.formId,
      authorizations: dynamicMenu.authorizations
    })

    this.initializeTemplate();
    this.getdocuments();
    //this.restartTable();
  }

  getUserSettings = async (formId) => {

    let userSettings = await getUserSettings(formId);
    this.setState(
      {
        howToUseSetting: userSettings.howToUseSetting,
        whatIsSetting: userSettings.whatIsSetting
      }
    )
  }

  save = async () => {

    let obj = {
      id: this.state.id,
      headerTemplate: this.state.headerTemplate,
      footerTemplate: this.state.footerTemplate,
      header: this.state.header,
      tableOfContents: this.state.tableOfContents
    }

    if (obj.id === null)
      await handleRequest("POST", "/MaterialRequisitionReportTemplate/add", obj);
    else
      await handleRequest("POST", "/MaterialRequisitionReportTemplate/update", obj);

    this.showSuccess();
  }

  edit = (row) => {

    this.formRef.current.setFieldsValue({
      headerTemplate: row.headerTemplate,
      footerTemplate: row.footerTemplate,
      header: row.header,
    });

    this.setState({
      id: row.id,
      headerTemplate: row.headerTemplate,
      footerTemplate: row.footerTemplate,
      header: row.header,
      tableOfContents: Boolean(row.tableOfContents) && row.tableOfContents.length > 0 ? row.tableOfContents : [...defaulltToc],

    });

  }

  addToc = async () => {

    let obj = {
      id: 0,
      seperatePage: this.state.seperatePage,
      description: this.state.description,
      documentId: this.state.documentId,
      documentKey: this.state.documentKey,
      orderNumber: Boolean(this.state.tableOfContents) ? this.state.tableOfContents.length + 1 : 1,
      showInReport: true,
      allPages: this.state.allPages,
      fromPage: this.state.fromPage,
      toPage: this.state.toPage,
    }

    let list = Boolean(this.state.tableOfContents) ? this.state.tableOfContents : [];

    list.push(obj);

    this.setState({
      seperatePage: false,
      description: '',
      documentId: null,
      documentKey: '',
      allPages: true,
      fromPage: null,
      toPage: null,
      tableOfContents: list
    })

  }


  delete = async () => {

    const obj = {
      id: this.state.id
    }

    await handleRequest("POST", "/MaterialRequisitionReportTemplate/delete", obj);

    //this.restartTable();
    this.showSuccess();
    this.showOrHideDeleteModal();
  }

  deleteModal = (row) => {
    this.setState({
      modalDeleteShow: true,
      id: row.id
    });
  }

  showOrHideDeleteModal = () => {
    this.setState({
      modalDeleteShow: !this.state.modalDeleteShow
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }



  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };




  actionTemplate(rowData, column) {


    return <React.Fragment>
      <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
    </React.Fragment>;


  }

  initializeTemplate = async () => {
    const response = await handleRequest("GET", "/MaterialRequisitionReportTemplate/getAllByCompany");

    if (response.data.length !== 0) {
      const list = response.data;
      this.edit(list[0]);
    }

    if (response.data.length === 0) {
      this.setState({
        tableList: [],
        tableOfContents: [...defaulltToc]
      });
    }
  }


  getdocuments = async () => {

    const response = await handleRequest("GET", "/DocumentLog/getAllByCompany");

    this.setState({
      documentSelectItems: Boolean(response.data) ? response.data : []
    })

  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/MaterialRequisitionReportTemplate/getAllByCompany");

    if (response.data.length !== 0) {
      const list = response.data;
      list.forEach(element => {
        element.key = element.id
      });
      this.setState({
        tableList: list,
      });
    }

    if (response.data.length === 0) {
      this.setState({
        tableList: [],
      });
    }
  }



  createNew = () => {

    this.setState({
      hideInputs: !this.state.hideInputs,
      modalDeleteShow: false,
      hideSave: false,
      hideUpdate: true,
    });
    this.resetInputs();

  }


  stop = () => {

    this.setState({

      modalDeleteShow: false,
      hideInputs: true,
      hideSave: false,
      hideUpdate: true,
    });
    this.resetInputs();

  }



  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      //[RESETINPUTS_FORMITEMS] 

    });

    this.setState({
      // [RESETINPUTS_STATES],
      modalDeleteShow: false,
      hideSave: false,
      hideUpdate: true,


    });
  }


  cancel = () => {

    this.resetInputs();
  }






  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }


  render() {

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };

    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };


    return (this.state.formId > 0) ? (
      (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
        ? (
          <div>
            <MenuProvider id="menu_id">
              <Card>
                <div>
                  <Row>
                    <Col sm="1">
                      <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                    </Col>
                    <Col sm="10">
                    </Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                      {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row>
                    <Col sm="10">
                    </Col>
                    <Col sm="2">

                    </Col>
                  </Row>
                </div>

                <div style={{ marginBottom: '2rem' }}>

                  <FormAnt
                    initialValues={{ remember: false }}
                    onFinish={this.save}
                    onFinishFailed={onFinishFailed}
                    ref={this.formRef}
                  >

                    {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MaterialRequisitionTemplate.HeaderTemplate").visible) || this.state.dynamicAttributes.length === 0) &&
                      <FormAnt.Item
                        {...layout}

                        label={<FormattedMessage id="MaterialRequisitionTemplate.HeaderTemplate" defaultMessage="Header Template" />}

                        style={{
                          marginBottom: 0,
                        }}
                        name="headerTemplate"
                        rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.HeaderTemplate")) ? this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.HeaderTemplate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.HeaderTemplate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.HeaderTemplate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                        <RowAnt gutter={[8, 8]}>
                          <ColAnt span={12}>
                            <Select style={{ width: "100%" }} placeholder="Header Template" id="MaterialRequisitionTemplate.HeaderTemplate" value={this.state.headerTemplate} onChange={(value) => {
                              this.formRef.current.setFieldsValue({
                                headerTemplate: value
                              });
                              this.setState({ headerTemplate: value })
                            }}>
                              <Option key={null} value={null}>Select</Option> 
                              <Option key="header1" value="header1" >   <img  src="/media/headerTemplate1.png" alt="" height="50px"  width="100%"></img></Option>
                              <Option key="header2" value="header2" >   <img  src="/media/headerTemplate2.png" alt="" height="50px" width="100%"></img></Option> </Select>
                          </ColAnt>
                        </RowAnt>

                      </FormAnt.Item>
                    }

                    {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MaterialRequisitionTemplate.footerTemplate").visible) || this.state.dynamicAttributes.length === 0) &&
                      <FormAnt.Item
                        {...layout}

                        label={<FormattedMessage id="MaterialRequisitionTemplate.footerTemplate" defaultMessage="Footer Template" />}

                        style={{
                          marginBottom: 0,
                        }}
                        name="footerTemplate"
                        rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.footerTemplate")) ? this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.footerTemplate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.footerTemplate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.footerTemplate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                        <RowAnt gutter={[8, 8]}>
                          <ColAnt span={12}>
                            <Select style={{ width: "100%" }} placeholder="Footer Template" id="MaterialRequisitionTemplate.footerTemplate" value={this.state.footerTemplate} onChange={(value) => {
                              this.formRef.current.setFieldsValue({
                                footerTemplate: value
                              });
                              this.setState({ footerTemplate: value })
                            }}>
                              <Option key={null} value={null}>Select</Option> 
                              <Option key="footer1" value="footer1" >   <img  src="/media/footerTemplate1.png" alt="" height="50px"  width="100%"></img> </Option>
                              <Option key="footer2" value="footer2" >  {"Pagination"} </Option> 
                              </Select> 
                       
                          </ColAnt>
                        </RowAnt>

                      </FormAnt.Item>
                    }

                    {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MaterialRequisitionTemplate.Header").visible) || this.state.dynamicAttributes.length === 0) &&
                      <FormAnt.Item
                        {...layout}

                        label={<FormattedMessage id="MaterialRequisitionTemplate.Header" defaultMessage="Header" />}

                        style={{
                          marginBottom: 0,
                        }}
                        name="header"
                        rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.Header")) ? this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.Header").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.Header")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "MaterialRequisitionTemplate.Header").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                        <RowAnt gutter={[8, 8]}>
                          <ColAnt span={12}>
                            <Input placeholder="Header" style={{ width: "100%" }} id="MaterialRequisitionTemplate.Header" value={this.state.header} onChange={(e) => { this.setState({ header: e.target.value }) }} />
                          </ColAnt>
                        </RowAnt>

                      </FormAnt.Item>
                    }

                    {
                      <FormAnt.Item

                        name="OrganizationalLevelDesignation"
                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                          <Col sm="1">

                          </Col>
                          <Col sm="2">
                            <Input placeholder="Content Name" style={{ width: "100%" }} id="MaterialRequisitionTemplate.contentName" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} />
                          </Col>
                          <Col sm="2">
                            <Select style={{ width: "100%" }} placeholder="Mrn No." id="MaterialRequisitionTemplate.documentId" value={this.state.documentId} onChange={(value) => {

                              this.setState({ documentId: value })
                            }}> 
                              <Option key={null} value={null}>Select Document</Option>
                              {this.state.documentSelectItems.map(i => (
                                <Option key={i.id} value={i.id}>{i.code + ' ' + i.description}</Option>
                              ))}
                            </Select>
                          </Col>
                          <Col sm="2">
                            <Input placeholder="Document Key" style={{ width: "100%" }} id="MaterialRequisitionTemplate.documentKey" value={this.state.documentKey} onChange={(e) => { this.setState({ documentKey: e.target.value }) }} />
                          </Col>
                          <Col sm="2">
                            <Checkbox onChange={(e) => {
                              this.setState({
                                seperatePage: e.target.checked
                              })
                            }} checked={this.state.seperatePage}> <FormattedMessage id="MaterialRequisitionTemplate.SeperatePage" defaultMessage="Seperate Page" /></Checkbox>
                          </Col>

                          <Col sm="2">
                            <Space>
                              <Checkbox onChange={(e) => {
                                this.setState({
                                  allPages: e.target.checked
                                })
                              }} checked={this.state.allPages}> <FormattedMessage id="MaterialRequisitionTemplate.AllPages" defaultMessage="All Pages" /></Checkbox>

                              {!this.state.allPages && <InputNumber placeholder="From"
                                min={1} value={this.state.fromPage} onChange={(value) => {
                                  this.setState({ fromPage: value })
                                }} />}

                              {!this.state.allPages && <InputNumber placeholder="To"
                                min={1} value={this.state.toPage} onChange={(value) => {
                                  this.setState({ toPage: value })
                                }} />}
                            </Space>
                          </Col>


                          <Col sm="1">
                            <AntButton type="primary" onClick={this.addToc} icon={<PlusOutlined />}></AntButton>
                          </Col>


                        </Form.Group>

                      </FormAnt.Item>}

                    {this.state.tableOfContents.map((d, i) => (
                      <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                        {
                          <FormAnt.Item

                            name="OrganizationalLevelDesignation"
                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                              <Col sm="2">

                              </Col>
                              <Col sm="1">
                                <div>
                                  <Checkbox onChange={(e) => {
                                    var tableOfContents = this.state.tableOfContents;
                                    var obj = tableOfContents[i];
                                    obj.showInReport = e.target.checked;
                                    this.setState({
                                      tableOfContents: tableOfContents
                                    })
                                  }} checked={d.showInReport}> </Checkbox>
                                </div>
                              </Col>

                              <Col sm="4">
                                <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                  {d.description}
                                </Form.Label>
                              </Col>
                              <Col sm="2">
                                <div>
                                  <Checkbox onChange={(e) => {
                                    var tableOfContents = this.state.tableOfContents;
                                    var obj = tableOfContents[i];
                                    obj.seperatePage = e.target.checked;
                                    this.setState({
                                      tableOfContents: tableOfContents
                                    })
                                  }} checked={d.seperatePage}> <FormattedMessage id="MaterialRequisitionTemplate.SeperatePage" defaultMessage="Seperate Page" /></Checkbox>
                                </div>
                              </Col>
                              <Col sm="2">
                                <Space>
                                  <Checkbox onChange={(e) => {
                                    var tableOfContents = this.state.tableOfContents;
                                    var obj = tableOfContents[i];
                                    obj.allPages = e.target.checked;
                                    this.setState({
                                      tableOfContents: tableOfContents
                                    })
                                  }} checked={d.allPages}> <FormattedMessage id="MaterialRequisitionTemplate.AllPages" defaultMessage="All Pages" /></Checkbox>

                                  {!d.allPages && <InputNumber placeholder="From"
                                    min={1} value={d.fromPage} onChange={(value) => {
                                      var tableOfContents = this.state.tableOfContents;
                                      var obj = tableOfContents[i];
                                      obj.fromPage = value;
                                      this.setState({
                                        tableOfContents: tableOfContents
                                      })
                                    }} />}

                                  {!d.allPages && <InputNumber placeholder="To"
                                    min={1} value={d.toPage} onChange={(value) => {
                                      var tableOfContents = this.state.tableOfContents;
                                      var obj = tableOfContents[i];
                                      obj.toPage = value;
                                      this.setState({
                                        tableOfContents: tableOfContents
                                      })
                                    }} />}
                                </Space>
                              </Col>


                            </Form.Group>

                          </FormAnt.Item>}

                      </div>))}



                    <div>
                      {
                        <RowAnt gutter={8}>
                          <ColAnt span={8}></ColAnt>

                          <ColAnt span={8}>
                            {
                              <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                            }
                          </ColAnt>
                        </RowAnt>

                      }
                    </div>




                  </FormAnt>
                </div>

                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                  <Column title="S/N" dataIndex="index" />

                  <Column title="Action" render={(value, row, index) => {

                    return this.actionTemplate(row);

                  }} />
                </Table>}
              </Card>

           </MenuProvider>
            {!this.state.whatIsSetting && <Whatis  ></Whatis>

            }

            <Modal
              show={this.state.modalDeleteShow}
              onHide={this.showOrHideDeleteModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure?</p>
              </Modal.Body>
              <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.modalMessage}
              onHide={this.showOrHideModalMessage}
              backdrop="static"
              aria-labelledby="contained-modal-title-vcenter"
              centered>

              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <Row >
                    <Col sm="12">
                      {this.state.messageTip == "Success" &&
                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                      {this.state.messageTip == "Warning" &&
                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                      {this.state.messageTip == "Error" &&
                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                    </Col>
                  </Row>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Row >
                  <Col sm="12">
                    {this.state.message}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
              </Modal.Footer>
            </Modal>

          </div>
        ) : (
          <div>
            <Redirect to="/error/error-v6" />
          </div>
        )
    ) : (
      <div></div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialRequisitionTemplate)
