import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Button as ReactButton, Col, Row, Modal, Form as FormBoot } from 'react-bootstrap';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Button as AntButton, Divider, DatePicker, Input } from 'antd';
import { InputNumber, Space, Select, Table, Form } from 'antd';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import {IconButton} from '@material-ui/core'; 
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import moment from 'moment';

const { Option } = Select;
const { Column } = Table;

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;

var _followUpRows = [
    {
        followUpIndex: 1,
        date: today
    }
];

export class Clinic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedClinic: null,
            clinics: [],
            clinicsTable: [],
            clinicDate: null,
            staffs: [],
            complainOfEmployees: [],
            initialCheckses: [],
            diagnosises: [],
            treatmentses: [],
            recommendations: "",
            medicineses: [],
            medicalCenters: [],
            followUpRows: [{
                followUpIndex: 1,
                date: today
            }],
            period: "",
            periodUnit: "",
            numberOfFollowUp: "",
            periodic: false,
            initialCheckRows: [],
            complainOfEmployee: [],
            complainOfEmployeeObjects: [],
            complainOfEmployeeBackup: [],
            newComplainOfEmployee: "",
            initialChecks: [],
            initialChecksObjects: [],
            initialChecksBackup: [],
            newInitialChecks: "",
            diagnosis: [],
            diagnosisObjects: [],
            diagnosisBackup: [],
            newDiagnosis: "",
            treatments: [],
            treatmentsObjects: [],
            treatmentsBackup: [],
            newTreatment: "",
            medicines: [],
            medicinesObjects: [],
            medicinesBackup: [],
            newMedicine: "",
            medicalCenter: [],
            medicalCenterObjects: [],
            medicalCenterBackup: [],
            newMedicalCenter: "",
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Clinic" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Clinic",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Clinic");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {

        }

        this.setState({
            clinicDate: today,
            followUpRows: _followUpRows
        });

        this.getUsers();
        this.getCodesComplainOfEmployee();
        this.getCodesInitialChecks();
        this.getCodesDiagnosis();
        this.getCodesTreatment();
        this.getCodesMedicine();
        this.getCodesMedicalCenter();
        this.restartTable();
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/Clinic/getAllByCompany");
        var flagStaffs = [];
        var flagClinics = [];
        var flagClinics2 = [];

        if (response.data.length === 0) {
            flagStaffs = [];
            this.setState({
                clinics: [],
                clinicsTable: []
            });
        }

        if (response.data.length !== 0) {
            flagClinics = response.data;

            for (let index = 0; index < flagClinics.length; index++) {
                if (!flagStaffs.includes(flagClinics[index].staffId)) {
                    flagStaffs.push(flagClinics[index].staffId);
                }
            }

            for (let index = 0; index < flagStaffs.length; index++) {
                let flagSubClinics = [];

                for (let index1 = 0; index1 < flagClinics.length; index1++) {
                    var _staff = null;
                    var _company = null;
                    var _project = null;

                    if (flagClinics[index1].staffId === flagStaffs[index]) {
                        _staff = flagClinics[index1].staff;
                        _company = flagClinics[index1].company;
                        _project = flagClinics[index1].project;

                        flagSubClinics.push({
                            id: flagClinics[index1].id,
                            key: flagClinics[index1].id,
                            clinicDate: flagClinics[index1].clinicDate,
                            staffId: flagClinics[index1].staffId,
                            staff: flagClinics[index1].staff,
                            complainOfEmployee: flagClinics[index1].clinicComplainOfEmployees,
                            initialChecks: flagClinics[index1].clinicInitialChecks,
                            diagnosis: flagClinics[index1].clinicDiagnosises,
                            treatments: flagClinics[index1].clinicTreatments,
                            recommendations: flagClinics[index1].recommendations,
                            medicines: flagClinics[index1].clinicMedicines,
                            followUps: flagClinics[index1].followUps,
                            medicalCenter: flagClinics[index1].clinicMedicalCenters,
                            periodValue: flagClinics[index1].periodValue,
                            periodType: flagClinics[index1].periodType,
                            numberOfFollowUp: flagClinics[index1].numberOfFollowUp,
                            periodic: flagClinics[index1].periodic
                        });
                    }
                }

                flagClinics2.push({
                    staff: _staff,
                    company: _company,
                    project: _project,
                    subClinics: flagSubClinics
                });
            }

            this.setState({
                clinics: response.data,
                clinicsTable: flagClinics2
            });
        }
    }

    resetInputs = () => {
        this.formRef.current.resetFields();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _followUpRows = [
            {
                followUpIndex: 1,
                date: today
            }
        ];

        this.setState({
            hidden: true,
            clinicDate: today,
            staffId: null,
            idNo: null,
            complainOfEmployee: [],
            initialChecks: [],
            initialCheckRows: [],
            diagnosis: [],
            treatments: [],
            recommendations: "",
            medicines: [],
            followUpRows: _followUpRows,
            medicalCenter: [],
            period: "",
            periodUnit: "",
            numberOfFollowUp: "",
            periodic: false
        });
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedClinic: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteClinicOK = async () => {
        const deleteClinic = {
            id: this.state.selectedClinic.id
        }
        var response = await handleRequest("POST", "/Clinic/delete", deleteClinic);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    createNew = async () => {
        if (this.state.hidden) {
            this.formRef.current.resetFields();

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            _followUpRows = [
                {
                    followUpIndex: 1,
                    date: today,
                    periodic: false,
                    period: "",
                    periodUnit: ""
                }
            ];

            this.setState({
                hidden: false,
                clinicDate: today,
                staffId: null,
                idNo: null,
                complainOfEmployee: [],
                initialChecks: [],
                initialCheckRows: [],
                diagnosis: [],
                treatments: [],
                recommendations: "",
                medicines: [],
                followUpRows: _followUpRows,
                medicalCenter: [],
                period: "",
                periodUnit: "",
                numberOfFollowUp: "",
                periodic: false
            });
        } else {
            this.resetInputs();
        }
    }

    stopProcess = () => {
        this.resetInputs();
    }

    saveClinic = async () => {
        let flagComplainOfEmployees = [];
        let flagInitialChecks = [];
        let flagDiagnosises = [];
        let flagTreatments = [];
        let flagMedicines = [];
        let flagFollowUps = [];
        let flagMedicalCenters = [];

        for (let index = 0; index < this.state.complainOfEmployee.length; index++) {
            flagComplainOfEmployees.push({
                complainOfEmployeeId: this.state.complainOfEmployee[index],
            });
        }

        for (let index = 0; index < this.state.initialCheckRows.length; index++) {
            flagInitialChecks.push({
                initialCheckId: this.state.initialChecks[index],
                initialCheckValue: this.state.initialCheckRows[index].value
            });
        }

        for (let index = 0; index < this.state.diagnosis.length; index++) {
            flagDiagnosises.push({
                diagnosisId: this.state.diagnosis[index]
            });
        }

        for (let index = 0; index < this.state.treatments.length; index++) {
            flagTreatments.push({
                treatmentId: this.state.treatments[index]
            });
        }

        for (let index = 0; index < this.state.medicines.length; index++) {
            flagMedicines.push({
                medicineId: this.state.medicines[index]
            });
        }

        for (let index = 0; index < this.state.followUpRows.length; index++) {
            flagFollowUps.push(this.state.followUpRows[index].date);
        }

        for (let index = 0; index < this.state.medicalCenter.length; index++) {
            flagMedicalCenters.push({
                medicalCenterId: this.state.medicalCenter[index]
            });
        }

        const addClinic = {
            clinicDate: this.state.clinicDate,
            staffId: this.state.staffId,
            clinicComplainOfEmployees: flagComplainOfEmployees,
            clinicInitialChecks: flagInitialChecks,
            clinicDiagnosises: flagDiagnosises,
            clinicTreatments: flagTreatments,
            recommendations: this.state.recommendations,
            clinicMedicines: flagMedicines,
            followUps: flagFollowUps.join(),
            periodic: this.state.periodic,
            periodValue: this.state.period,
            periodType: this.state.periodUnit,
            numberOfFollowUp: this.state.numberOfFollowUp,
            clinicMedicalCenters: flagMedicalCenters
        }

        var response = await handleRequest("POST", "/Clinic/add", addClinic);
        if (response.data !== null && response.data !== undefined) {
            if (response.data !== "0" && response.data !== 0) {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        }
    }

    updateClinic = async () => {
        if (this.state.selectedClinic !== null) {
            let flagComplainOfEmployees = [];
            let coeValues = [];
            let flagInitialChecks = [];
            let initValues = [];
            let flagDiagnosises = [];
            let diagValues = [];
            let flagTreatments = [];
            let treatValues = [];
            let flagMedicines = [];
            let medValues = [];
            let flagFollowUps = [];
            let flagMedicalCenters = [];
            let medCentValues = [];

            for (let index = 0; index < this.state.complainOfEmployeeObjects.length; index++) {
                coeValues.push(this.state.complainOfEmployeeObjects[index].complainOfEmployeeId);
            }

            for (let index = 0; index < this.state.complainOfEmployeeObjects.length; index++) {
                if (this.state.complainOfEmployee.includes(coeValues[index])) {
                    flagComplainOfEmployees.push({
                        id: this.state.complainOfEmployeeObjects[index].id,
                        active: true,
                        complainOfEmployeeId: this.state.complainOfEmployeeObjects[index].complainOfEmployeeId,
                    });
                } else {
                    flagComplainOfEmployees.push({
                        id: this.state.complainOfEmployeeObjects[index].id,
                        active: false,
                        complainOfEmployeeId: this.state.complainOfEmployeeObjects[index].complainOfEmployeeId,
                    });
                }
            }

            for (let index = 0; index < this.state.complainOfEmployee.length; index++) {
                if (!coeValues.includes(this.state.complainOfEmployee[index])) {
                    flagComplainOfEmployees.push({
                        complainOfEmployeeId: this.state.complainOfEmployee[index],
                    });
                }
            }

            for (let index = 0; index < this.state.initialChecksObjects.length; index++) {
                initValues.push(this.state.initialChecksObjects[index].initialCheckId);
            }

            for (let index = 0; index < this.state.initialChecksObjects.length; index++) {
                if (this.state.initialChecks.includes(initValues[index])) {
                    flagInitialChecks.push({
                        id: this.state.initialChecksObjects[index].id,
                        active: true,
                        initialCheckId: this.state.initialChecksObjects[index].initialCheckId,
                        initialCheckValue: this.state.initialChecksObjects[index].initialCheckValue
                    });
                } else {
                    flagInitialChecks.push({
                        id: this.state.initialChecksObjects[index].id,
                        active: false,
                        initialCheckId: this.state.initialChecksObjects[index].initialCheckId,
                        initialCheckValue: this.state.initialChecksObjects[index].initialCheckValue
                    });
                }
            }

            for (let index = 0; index < this.state.initialChecks.length; index++) {
                if (!initValues.includes(this.state.initialChecks[index])) {
                    flagInitialChecks.push({
                        initialCheckId: this.state.initialChecks[index],
                        initialCheckValue: this.state.initialCheckRows[index].initialCheckValue
                    });
                }
            }

            for (let index = 0; index < this.state.diagnosisObjects.length; index++) {
                diagValues.push(this.state.diagnosisObjects[index].diagnosisId);
            }

            for (let index = 0; index < this.state.diagnosisObjects.length; index++) {
                if (this.state.diagnosis.includes(diagValues[index])) {
                    flagDiagnosises.push({
                        id: this.state.diagnosisObjects[index].id,
                        active: true,
                        diagnosisId: this.state.diagnosisObjects[index].diagnosisId,
                    });
                } else {
                    flagDiagnosises.push({
                        id: this.state.diagnosisObjects[index].id,
                        active: false,
                        diagnosisId: this.state.diagnosisObjects[index].diagnosisId,
                    });
                }
            }

            for (let index = 0; index < this.state.diagnosis.length; index++) {
                if (!diagValues.includes(this.state.diagnosis[index])) {
                    flagDiagnosises.push({
                        diagnosisId: this.state.diagnosis[index],
                    });
                }
            }

            for (let index = 0; index < this.state.treatmentsObjects.length; index++) {
                treatValues.push(this.state.treatmentsObjects[index].treatmentId);
            }

            for (let index = 0; index < this.state.treatmentsObjects.length; index++) {
                if (this.state.treatments.includes(treatValues[index])) {
                    flagTreatments.push({
                        id: this.state.treatmentsObjects[index].id,
                        active: true,
                        treatmentId: this.state.treatmentsObjects[index].treatmentId,
                    });
                } else {
                    flagTreatments.push({
                        id: this.state.treatmentsObjects[index].id,
                        active: false,
                        treatmentId: this.state.treatmentsObjects[index].treatmentId,
                    });
                }
            }

            for (let index = 0; index < this.state.treatments.length; index++) {
                if (!treatValues.includes(this.state.treatments[index])) {
                    flagTreatments.push({
                        treatmentId: this.state.treatments[index],
                    });
                }
            }

            for (let index = 0; index < this.state.medicinesObjects.length; index++) {
                medValues.push(this.state.medicinesObjects[index].medicineId);
            }

            for (let index = 0; index < this.state.medicinesObjects.length; index++) {
                if (this.state.medicines.includes(medValues[index])) {
                    flagMedicines.push({
                        id: this.state.medicinesObjects[index].id,
                        active: true,
                        medicineId: this.state.medicinesObjects[index].medicineId,
                    });
                } else {
                    flagMedicines.push({
                        id: this.state.medicinesObjects[index].id,
                        active: false,
                        medicineId: this.state.medicinesObjects[index].medicineId,
                    });
                }
            }

            for (let index = 0; index < this.state.medicines.length; index++) {
                if (!medValues.includes(this.state.medicines[index])) {
                    flagMedicines.push({
                        medicineId: this.state.medicines[index]
                    });
                }
            }

            for (let index = 0; index < this.state.medicalCenterObjects.length; index++) {
                medCentValues.push(this.state.medicalCenterObjects[index].medicalCenterId);
            }

            for (let index = 0; index < this.state.medicalCenterObjects.length; index++) {
                if (this.state.medicalCenter.includes(medCentValues[index])) {
                    flagMedicalCenters.push({
                        id: this.state.medicalCenterObjects[index].id,
                        active: true,
                        medicalCenterId: this.state.medicalCenterObjects[index].medicalCenterId,
                    });
                } else {
                    flagMedicalCenters.push({
                        id: this.state.medicalCenterObjects[index].id,
                        active: false,
                        medicalCenterId: this.state.medicalCenterObjects[index].medicalCenterId,
                    });
                }
            }

            for (let index = 0; index < this.state.medicalCenter.length; index++) {
                if (!medCentValues.includes(this.state.medicalCenter[index])) {
                    flagMedicalCenters.push({
                        medicalCenterId: this.state.medicalCenter[index]
                    });
                }
            }

            for (let index = 0; index < this.state.followUpRows.length; index++) {
                flagFollowUps.push(this.state.followUpRows[index].date);
            }

            const updatedClinic = {
                id: this.state.selectedClinic.id,
                clinicDate: this.state.clinicDate,
                staffId: this.state.staffId,
                clinicComplainOfEmployees: flagComplainOfEmployees,
                clinicInitialChecks: flagInitialChecks,
                clinicDiagnosises: flagDiagnosises,
                clinicTreatments: flagTreatments,
                recommendations: this.state.recommendations,
                clinicMedicines: flagMedicines,
                followUps: flagFollowUps.join(),
                periodic: this.state.periodic,
                periodValue: this.state.period,
                periodType: this.state.periodUnit,
                numberOfFollowUp: this.state.numberOfFollowUp,
                clinicMedicalCenters: flagMedicalCenters
            }

            var response2 = await handleRequest("POST", "/Clinic/update", updatedClinic);
            if (response2.data !== null && response2.data !== undefined) {
                if (response2.data === "SUCCESS") {
                    this.resetInputs();
                    this.setState({
                        selectedClinic: null,
                        hiddenSave: true,
                        hiddenUpdate: false
                    });
                    this.restartTable();
                    this.showSuccess();
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    changeButtonUpdate = (row) => {
        let _complainOfEmployees = [];
        let _complainOfEmployeesValue = [];
        let _initialChecks = [];
        let _initialChecksValue = [];
        let _initialCheckRows = [];
        let _diagnosis = [];
        let _diagnosisValue = [];
        let _treatments = [];
        let _treatmentsValue = [];
        let _medicines = [];
        let _medicinesValue = [];
        let _medicalCenter = [];
        let _medicalCenterValue = [];
        let _followUps = [];

        _followUpRows = [];

        if (row.followUps !== undefined) {
            _followUps = row.followUps.split(",");

            if (_followUps.length > 0) {
                for (let index = 0; index < _followUps.length; index++) {
                    _followUpRows.push(
                        {
                            followUpIndex: _followUpRows.length + 1,
                            date: _followUps[index]
                        }
                    );
                }
            }
        }

        if (row.complainOfEmployee !== undefined)
            if (row.complainOfEmployee.length > 0) {
                for (let index = 0; index < row.complainOfEmployee.length; index++) {
                    _complainOfEmployees.push(row.complainOfEmployee[index].codeComplainOfEmployee.name);
                    _complainOfEmployeesValue.push(row.complainOfEmployee[index].codeComplainOfEmployee.id);
                }
            }

        if (row.initialChecks !== undefined)
            if (row.initialChecks.length > 0) {
                for (let index = 0; index < row.initialChecks.length; index++) {
                    _initialChecks.push(row.initialChecks[index].codeInitialCheck.name);
                    _initialChecksValue.push(row.initialChecks[index].codeInitialCheck.id);
                    _initialCheckRows.push({
                        initialCheckIndex: _initialCheckRows.length + 1,
                        id: row.initialChecks[index].id,
                        name: row.initialChecks[index].codeInitialCheck.name,
                        value: row.initialChecks[index].initialCheckValue
                    });
                }
            }

        if (row.diagnosis !== undefined)
            if (row.diagnosis.length > 0) {
                for (let index = 0; index < row.diagnosis.length; index++) {
                    _diagnosis.push(row.diagnosis[index].codeDiagnosis.name);
                    _diagnosisValue.push(row.diagnosis[index].codeDiagnosis.id);
                }
            }

        if (row.treatments !== undefined)
            if (row.treatments.length > 0) {
                for (let index = 0; index < row.treatments.length; index++) {
                    _treatments.push(row.treatments[index].codeTreatment.name);
                    _treatmentsValue.push(row.treatments[index].codeTreatment.id);
                }
            }

        if (row.medicines !== undefined)
            if (row.medicines.length > 0) {
                for (let index = 0; index < row.medicines.length; index++) {
                    _medicines.push(row.medicines[index].codeMedicine.name);
                    _medicinesValue.push(row.medicines[index].codeMedicine.id);
                }
            }

        if (row.medicalCenter !== undefined)
            if (row.medicalCenter.length > 0) {
                for (let index = 0; index < row.medicalCenter.length; index++) {
                    _medicalCenter.push(row.medicalCenter[index].codeMedicalCenter.name);
                    _medicalCenterValue.push(row.medicalCenter[index].codeMedicalCenter.id);
                }
            }

        this.formRef.current.setFieldsValue({
            ClinicClinicDate: moment(row.clinicDate, 'DD/MM/YYYY'),
            ComplainOfEmployee: _complainOfEmployeesValue,
            InitialChecks: _initialChecksValue,
            Diagnosis: _diagnosisValue,
            Treatments: _treatmentsValue,
            Recommendations: row.recommendations,
            Medicines: _medicinesValue,
            TransferToMedicalCenter: _medicalCenterValue
        });

        if (row.staff !== undefined) {
            this.formRef.current.setFieldsValue({
                EmployeeRetiree: row.staff.name + " " + row.staff.midName + " " + row.staff.lastName,
                IdNo: row.staff.nationalId
            });
        }

        this.setState({
            hidden: false,
            hiddenSave: true,
            hiddenUpdate: false,
            selectedClinic: row,
            clinicDate: row.clinicDate,
            staffId: row.staffId,
            idNo: row.staffId,
            complainOfEmployee: _complainOfEmployeesValue,
            complainOfEmployeeObjects: row.complainOfEmployee,
            complainOfEmployeeBackup: row.complainOfEmployee,
            initialChecks: _initialChecksValue,
            initialChecksObjects: row.initialChecks,
            initialChecksBackup: row.initialChecks,
            initialCheckRows: _initialCheckRows,
            diagnosis: _diagnosisValue,
            diagnosisObjects: row.diagnosis,
            diagnosisBackup: row.diagnosis,
            treatments: _treatmentsValue,
            treatmentsObjects: row.treatments,
            treatmentsBackup: row.treatments,
            recommendations: row.recommendations,
            medicines: _medicinesValue,
            medicinesObjects: row.medicines,
            medicinesBackup: row.medicines,
            followUpRows: _followUpRows,
            medicalCenter: _medicalCenterValue,
            medicalCenterObjects: row.medicalCenter,
            medicalCenterBackup: row.medicalCenter,
            period: row.periodValue,
            periodUnit: row.periodType,
            numberOfFollowUp: row.numberOfFollowUp,
            periodic: row.periodic
        });
    }

    changeButtonCancel = () => {
        this.formRef.current.resetFields();

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _followUpRows = [
            {
                followUpIndex: 1,
                date: today
            }
        ];

        this.setState({
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedClinic: null,
            clinicDate: today,
            staffId: null,
            idNo: null,
            complainOfEmployee: [],
            initialChecks: [],
            initialCheckRows: [],
            diagnosis: [],
            treatments: [],
            recommendations: "",
            medicines: [],
            followUpRows: _followUpRows,
            medicalCenter: [],
            period: "",
            periodUnit: "",
            numberOfFollowUp: "",
            periodic: false
        });
    }

    onStaffChange = (value) => {
        this.setState({
            staffId: value,
            idNo: value
        });

        let flagStaff = this.state.staffs;

        for (let index = 0; index < flagStaff.length; index++) {
            if (flagStaff[index].id === value) {
                this.formRef.current.setFieldsValue({
                    EmployeeRetiree: flagStaff[index].name + " " + flagStaff[index].midName + " " + flagStaff[index].lastName,
                    IdNo: flagStaff[index].nationalId
                });
            }
        }
    }

    onIdNoChange = (value) => {
        this.setState({
            EmployeeRetiree: value,
            IdNo: value
        });

        let flagStaff = this.state.staffs;

        for (let index = 0; index < flagStaff.length; index++) {
            if (flagStaff[index].id === value) {
                this.formRef.current.setFieldsValue({
                    EmployeeRetiree: flagStaff[index].name + " " + flagStaff[index].midName + " " + flagStaff[index].lastName,
                    IdNo: flagStaff[index].nationalId
                });
            }
        }
    }

    onChangeClinicDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                clinicDate: dateString
            });
        } else {
            this.setState({
                clinicDate: today
            });
        }
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    handleChangeReasonOfDeath = (e) => {
        var value = "";
        for (var z = 0; z < this.state.reasonOfDeaths.length; z++) {
            if (this.state.reasonOfDeaths[z].id == e) {
                value = this.state.reasonOfDeaths[z].name;
            }
        }
        this.setState({
            reasonOfDeath: value,
            reasonOfDeathId: e,
        });
    }

    addReasonOfDeath = async () => {
        const { newReasonOfDeath } = this.state;
        if (newReasonOfDeath != null && newReasonOfDeath != "" && newReasonOfDeath != undefined) {
            const newItem = {
                name: newReasonOfDeath,
                type: "ReasonOfDeath"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newReasonOfDeath: ""
            });

            this.getCodesReasonOfDeath();
        } else {
            this.showWarning();
        }
    }

    getCodesReasonOfDeath = async () => {
        const response = await handleRequest("GET", "/Code/type/ReasonOfDeath");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                reasonOfDeaths: response.data
            });
        }
    }

    getUsers = async () => {
        const responseStaffs = await handleRequest("GET", "/Staff/getCompanyItems");

        if (responseStaffs.data.length !== 0) {
            this.setState({
                staffs: responseStaffs.data
            });
        }

        if (responseStaffs.data.length === 0) {
            this.setState({
                staffs: []
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addNewFollowUpLine = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _followUpRows.push(
            {
                followUpIndex: _followUpRows.length + 1,
                date: today
            }
        );

        this.setState({
            followUpRows: _followUpRows,
            periodic: false
        });
    }

    removeFollowUpLine = (index, e) => {
        _followUpRows.splice(index, 1);

        this.setState({
            followUpRows: _followUpRows
        });
    }

    onChangeFollowUpDate = async (index, date, dateString) => {
        let list = this.state.followUpRows;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            list[index].date = dateString;
        } else {
            list[index].date = today;
        }

        this.setState({
            followUpRows: list.slice()
        });
    }

    handleChangeComplainOfEmployee = (value) => {
        this.setState({
            complainOfEmployee: value
        });
    }

    addComplainOfEmployee = async () => {
        const { newComplainOfEmployee } = this.state;
        if (newComplainOfEmployee != null && newComplainOfEmployee != "" && newComplainOfEmployee != undefined) {
            const newItem = {
                name: newComplainOfEmployee,
                type: "ComplainOfEmployee"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newComplainOfEmployee: ""
            });

            this.getCodesComplainOfEmployee();
        } else {
            this.showWarning();
        }
    }

    getCodesComplainOfEmployee = async () => {
        const response = await handleRequest("GET", "/Code/type/ComplainOfEmployee");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                complainOfEmployees: response.data
            });
        }
    }

    handleChangeInitialChecks = (value) => {
        this.setState({
            initialChecks: value
        });

        let _initialCheckRows = [];

        for (let index = 0; index < value.length; index++) {
            let flagName = "";
            for (var z = 0; z < this.state.initialCheckses.length; z++) {
                if (this.state.initialCheckses[z].id == value[index]) {
                    flagName = this.state.initialCheckses[z].name;
                }
            }

            _initialCheckRows.push(
                {
                    initialCheckIndex: _initialCheckRows.length + 1,
                    name: flagName,
                    value: ""
                }
            );
        }

        this.setState({
            initialCheckRows: _initialCheckRows
        });
    }

    addInitialChecks = async () => {
        const { newInitialChecks } = this.state;
        if (newInitialChecks != null && newInitialChecks != "" && newInitialChecks != undefined) {
            const newItem = {
                name: newInitialChecks,
                type: "InitialChecks"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newInitialChecks: ""
            });

            this.getCodesInitialChecks();
        } else {
            this.showWarning();
        }
    }

    getCodesInitialChecks = async () => {
        const response = await handleRequest("GET", "/Code/type/InitialChecks");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                initialCheckses: response.data
            });
        }
    }

    handleChangeDiagnosis = (value) => {
        this.setState({
            diagnosis: value
        });
    }

    addDiagnosis = async () => {
        const { newDiagnosis } = this.state;
        if (newDiagnosis != null && newDiagnosis != "" && newDiagnosis != undefined) {
            const newItem = {
                name: newDiagnosis,
                type: "Diagnosis"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newDiagnosis: ""
            });

            this.getCodesDiagnosis();
        } else {
            this.showWarning();
        }
    }

    getCodesDiagnosis = async () => {
        const response = await handleRequest("GET", "/Code/type/Diagnosis");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                diagnosises: response.data
            });
        }
    }

    handleChangeTreatments = (value) => {
        this.setState({
            treatments: value
        });
    }

    addTreatment = async () => {
        const { newTreatment } = this.state;
        if (newTreatment != null && newTreatment != "" && newTreatment != undefined) {
            const newItem = {
                name: newTreatment,
                type: "Treatment"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newTreatment: ""
            });

            this.getCodesTreatment();
        } else {
            this.showWarning();
        }
    }

    getCodesTreatment = async () => {
        const response = await handleRequest("GET", "/Code/type/Treatment");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                treatmentses: response.data
            });
        }
    }

    handleChangeMedicines = (value) => {
        this.setState({
            medicines: value
        });
    }

    addMedicine = async () => {
        const { newMedicine } = this.state;
        if (newMedicine != null && newMedicine != "" && newMedicine != undefined) {
            const newItem = {
                name: newMedicine,
                type: "Medicine"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newMedicine: ""
            });

            this.getCodesMedicine();
        } else {
            this.showWarning();
        }
    }

    getCodesMedicine = async () => {
        const response = await handleRequest("GET", "/Code/type/Medicine");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                medicineses: response.data
            });
        }
    }

    handleChangeMedicalCenter = (value) => {
        this.setState({
            medicalCenter: value
        });
    }

    addMedicalCenter = async () => {
        const { newMedicalCenter } = this.state;
        if (newMedicalCenter != null && newMedicalCenter != "" && newMedicalCenter != undefined) {
            const newItem = {
                name: newMedicalCenter,
                type: "MedicalCenter"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newMedicalCenter: ""
            });

            this.getCodesMedicalCenter();
        } else {
            this.showWarning();
        }
    }

    getCodesMedicalCenter = async () => {
        const response = await handleRequest("GET", "/Code/type/MedicalCenter");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                medicalCenters: response.data
            });
        }
    }

    handleChangeInitialCheckValue = (index, e) => {
        let list = this.state.initialCheckRows;
        list[index].value = e.target.value;

        this.setState({
            initialCheckRows: list.slice()
        });
    }

    onChangeFollowUpPeriodicCheck = (e) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (e.target.checked) {
            _followUpRows = [
                {
                    followUpIndex: 1,
                    date: today
                }
            ];

            this.setState({
                followUpRows: _followUpRows,
                modalFollowUp: true
            });
        }

        this.setState({
            periodic: e.target.checked
        });
    }

    showOrHideModalFollowUp = () => {
        this.setState({
            modalFollowUp: !this.state.modalFollowUp
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    periodOK = () => {
        this.setState({
            modalFollowUp: false
        });
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        var clinicDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClinicDate");
        var employeeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NameOfEmployee");
        var idNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "IdOfEmployee");
        var complainOfEmployeeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ComplainOfEmployee");
        var initialChecksAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InitialChecks");
        var diagnosisAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Diagnosis");
        var treatmentsAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Treatments");
        var recommendationsAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Recommendations");
        var medicinesAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Medicines");
        var followUpAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "FollowUp");
        var medicalCenterAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TransferToMedicalCenter");

        var clinicDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "ClinicDate");
        var employeeRequired = this.state.dynamicInputs.find(p => p.inputKey === "NameOfEmployee");
        var idNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "IdOfEmployee");
        var complainOfEmployeeRequired = this.state.dynamicInputs.find(p => p.inputKey === "ComplainOfEmployee");
        var initialChecksRequired = this.state.dynamicInputs.find(p => p.inputKey === "InitialChecks");
        var diagnosisRequired = this.state.dynamicInputs.find(p => p.inputKey === "Diagnosis");
        var treatmentsRequired = this.state.dynamicInputs.find(p => p.inputKey === "Treatments");
        var recommendationsRequired = this.state.dynamicInputs.find(p => p.inputKey === "Recommendations");
        var medicinesRequired = this.state.dynamicInputs.find(p => p.inputKey === "Medicines");
        var followUpRequired = this.state.dynamicInputs.find(p => p.inputKey === "FollowUp");
        var medicalCenterRequired = this.state.dynamicInputs.find(p => p.inputKey === "TransferToMedicalCenter");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 6 },
        };

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteClinicOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalFollowUp}
                        onHide={this.showOrHideModalFollowUp}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="ClinicFollowUpModal" defaultMessage="Please select follow up period" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row style={{ marginTop: "1rem", width: "100%" }}>
                                <Col sm="5">
                                    <FormBoot.Label style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                        <FormattedMessage id="ClinicFollowUpModalEvery" defaultMessage="Every" />
                                    </FormBoot.Label>
                                </Col>
                                <Col sm="4">
                                    <InputNumber id="ClinicFollowUpPeriod" style={{ width: "100%" }} value={this.state.period} onChange={(value) => this.setState({ period: value })} />
                                </Col>
                                <Col sm="3">
                                    <Select style={{ width: "100%" }} value={this.state.periodUnit} onChange={(value) => this.setState({ periodUnit: value })} >
                                        <Option value="Day"><FormattedMessage id="ClinicFollowUpModalDay" defaultMessage="Day" /></Option>
                                        <Option value="Week"><FormattedMessage id="ClinicFollowUpModalWeek" defaultMessage="Week" /></Option>
                                        <Option value="Month"><FormattedMessage id="ClinicFollowUpModalMonth" defaultMessage="Month" /></Option>
                                        <Option value="Year"><FormattedMessage id="ClinicFollowUpModalYear" defaultMessage="Year" /></Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem", width: "100%" }}>
                                <Col sm="5">
                                    <FormBoot.Label style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                        <FormattedMessage id="ClinicFollowUpModalNumberOfFollowUp" defaultMessage="Number of Follow Up" />
                                    </FormBoot.Label>
                                </Col>
                                <Col sm="4">
                                    <InputNumber id="ClinicNumberOfFollowUp" style={{ width: "100%" }} value={this.state.numberOfFollowUp} onChange={(value) => this.setState({ numberOfFollowUp: value })} />
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalFollowUp}>Close</ReactButton>
                            <ReactButton variant="primary" onClick={this.periodOK}>Save Settings</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="ClinicCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="ClinicStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                            {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Form
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdate == false ? this.updateClinic : this.saveClinic}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef} >

                                {(clinicDateAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicClinicDate" defaultMessage="Date" />}
                                        name="ClinicClinicDate"
                                        rules={[{ required: clinicDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(clinicDateRequired.translateRequired) == true ? clinicDateRequired.translateRequired : -1} defaultMessage="Please input Date!" /> }]} >
                                        <DatePicker value={moment(this.state.clinicDate, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeClinicDate} disabledDate={this.disabledDate} />
                                    </Form.Item>}

                                {(employeeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicEmployee" defaultMessage="Name of Employee" />}
                                        name="EmployeeRetiree"
                                        rules={[{ required: employeeRequired.canBeRequired, message: <FormattedMessage id={Boolean(employeeRequired.translateRequired) == true ? employeeRequired.translateRequired : -1} defaultMessage="Please select an Eemployee/Retiree!" /> }]} >
                                        <Select size='large'
                                            style={{ width: '100%' }}
                                            showSearch
                                            value={this.state.staffId}
                                            onChange={this.onStaffChange}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.staffs.map(staff => (
                                                <Option key={staff.id} value={staff.id}>{staff.name + " " + staff.midName + " " + staff.lastName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(idNoAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicIdNo" defaultMessage="ID of Employee" />}
                                        name="IdNo"
                                        rules={[{ required: idNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(idNoRequired.translateRequired) == true ? idNoRequired.translateRequired : -1} defaultMessage="Please select an ID!" /> }]} >
                                        <Select size='large'
                                            style={{ width: '100%' }}
                                            showSearch
                                            value={this.state.idNo}
                                            onChange={this.onIdNoChange}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.staffs.map(staff => (
                                                <Option key={staff.id} value={staff.id}>{staff.nationalId}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(complainOfEmployeeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicComplainOfEmployee" defaultMessage="Complain of Employee" />}
                                        name="ComplainOfEmployee"
                                        rules={[{ required: complainOfEmployeeRequired.canBeRequired, message: <FormattedMessage id={Boolean(complainOfEmployeeRequired.translateRequired) == true ? complainOfEmployeeRequired.translateRequired : -1} defaultMessage="Please select a Complain of Employee!" /> }]} >
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.complainOfEmployee}
                                            onChange={this.handleChangeComplainOfEmployee}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newComplainOfEmployee}
                                                            onChange={(event) => { this.setState({ newComplainOfEmployee: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addComplainOfEmployee} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Complain" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.complainOfEmployees.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(initialChecksAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicInitialChecks" defaultMessage="Initial Checks" />}
                                        name="InitialChecks"
                                        rules={[{ required: initialChecksRequired.canBeRequired, message: <FormattedMessage id={Boolean(initialChecksRequired.translateRequired) == true ? initialChecksRequired.translateRequired : -1} defaultMessage="Please select a Initial Checks!" /> }]} >
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.initialChecks}
                                            onChange={this.handleChangeInitialChecks}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newInitialChecks}
                                                            onChange={(event) => { this.setState({ newInitialChecks: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addInitialChecks} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Initial Checks" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.initialCheckses.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(initialChecksAttributes.visible) && (this.state.initialCheckRows.map((item, index) => (
                                    <Form.Item
                                        label={item.name}>
                                        <Input value={item.value} onChange={(e) => this.handleChangeInitialCheckValue(index, e)} />
                                    </Form.Item>)))}

                                {(diagnosisAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicDiagnosis" defaultMessage="Diagnosis" />}
                                        name="Diagnosis"
                                        rules={[{ required: diagnosisRequired.canBeRequired, message: <FormattedMessage id={Boolean(diagnosisRequired.translateRequired) == true ? diagnosisRequired.translateRequired : -1} defaultMessage="Please select a Diagnosis!" /> }]} >
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.diagnosis}
                                            onChange={this.handleChangeDiagnosis}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newDiagnosis}
                                                            onChange={(event) => { this.setState({ newDiagnosis: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addDiagnosis} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Diagnosis" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.diagnosises.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(treatmentsAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicTreatments" defaultMessage="Treatments" />}
                                        name="Treatments"
                                        rules={[{ required: treatmentsRequired.canBeRequired, message: <FormattedMessage id={Boolean(treatmentsRequired.translateRequired) == true ? treatmentsRequired.translateRequired : -1} defaultMessage="Please select a Treatment!" /> }]} >
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.treatments}
                                            onChange={this.handleChangeTreatments}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newTreatment}
                                                            onChange={(event) => { this.setState({ newTreatment: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addTreatment} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Treatment" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.treatmentses.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(recommendationsAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicRecommendations" defaultMessage="Recommendations" />}
                                        name="Recommendations"
                                        rules={[{ required: recommendationsRequired.canBeRequired, message: <FormattedMessage id={Boolean(recommendationsRequired.translateRequired) == true ? recommendationsRequired.translateRequired : -1} defaultMessage="Please input Recommendations!" /> }]} >
                                        <Input id="ClinicRecommendations" value={this.state.recommendations} onChange={(e) =>
                                            this.setState({ recommendations: e.target.value })
                                        } />
                                    </Form.Item>}

                                {(medicinesAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicMedicines" defaultMessage="Medicines" />}
                                        name="Medicines"
                                        rules={[{ required: medicinesRequired.canBeRequired, message: <FormattedMessage id={Boolean(medicinesRequired.translateRequired) == true ? medicinesRequired.translateRequired : -1} defaultMessage="Please select a Medicine!" /> }]} >
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.medicines}
                                            onChange={this.handleChangeMedicines}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newMedicine}
                                                            onChange={(event) => { this.setState({ newMedicine: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addMedicine} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Medicine" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.medicineses.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {(followUpAttributes.visible) && (
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicFollowUps" defaultMessage="Follow Ups" />}>
                                        <Space>
                                            <DatePicker value={moment(this.state.followUpRows[0].date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={(date, dateString) => { this.onChangeFollowUpDate(0, date, dateString) }} disabledDate={this.disabledDate} />
                                            <AntButton type="primary" onClick={this.addNewFollowUpLine} icon={<PlusOutlined />}></AntButton>
                                            <Checkbox onChange={(e) => this.onChangeFollowUpPeriodicCheck(e)} checked={this.state.periodic}>Periodic</Checkbox>
                                        </Space>
                                    </Form.Item>)}

                                {(followUpAttributes.visible) && (this.state.followUpRows.map((item, index) => (
                                    (index > 0) && <Form.Item
                                        label="  ">
                                        <Space>
                                            <DatePicker value={moment(item.date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={(date, dateString) => { this.onChangeFollowUpDate(index, date, dateString) }} disabledDate={this.disabledDate} />
                                            {(index !== 0) && (<AntButton type="primary" danger onClick={(e) => this.removeFollowUpLine(index, e)} icon={<CloseOutlined />}></AntButton>)}
                                        </Space>
                                    </Form.Item>)))}

                                {(medicalCenterAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="ClinicMedicalCenter" defaultMessage="Transfer to Medical Center" />}
                                        name="TransferToMedicalCenter"
                                        rules={[{ required: medicalCenterRequired.canBeRequired, message: <FormattedMessage id={Boolean(medicalCenterRequired.translateRequired) == true ? medicalCenterRequired.translateRequired : -1} defaultMessage="Please select a Medical Center!" /> }]} >
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            value={this.state.medicalCenter}
                                            onChange={this.handleChangeMedicalCenter}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newMedicalCenter}
                                                            onChange={(event) => { this.setState({ newMedicalCenter: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addMedicalCenter} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Medical Center" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.medicalCenters.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Button id="ClinicSaveButton" style={{ width: '100%' }} type="submit"
                                                    variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row}>
                                            <Col sm="3"></Col>
                                            <Col sm="2">
                                                <Button id="ClinicCancelButton" onClick={this.changeButtonCancel}
                                                    style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                            </Col>
                                            <Col sm="2">
                                                <Button id="ClinicUpdateButton" type="submit"
                                                    style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                            </Form>}
                        </div>

                        <Table style={{ padding: "4px 1rem 0px 4px" }} dataSource={this.state.clinicsTable} bordered
                            expandable={{
                                expandedRowRender: record =>
                                    <Table dataSource={record.subClinics} pagination={false}>
                                        <Column title="Date" key="date" dataIndex="clinicDate" />
                                        <Column title="Complain of Employee" key="complainOfEmployee" render={(value, row, index) => {
                                            if (row.complainOfEmployee !== undefined)
                                                if (row.complainOfEmployee.length > 0) {
                                                    let _complainOfEmployees = [];
                                                    for (let index = 0; index < row.complainOfEmployee.length; index++) {
                                                        _complainOfEmployees.push(row.complainOfEmployee[index].codeComplainOfEmployee.name);
                                                    }
                                                    return _complainOfEmployees.join(", ");
                                                }
                                            return '';
                                        }} />
                                        <Column title="Initial Checks" key="initialChecks" render={(value, row, index) => {
                                            if (row.initialChecks !== undefined)
                                                if (row.initialChecks.length > 0) {
                                                    let _initialChecks = [];
                                                    for (let index = 0; index < row.initialChecks.length; index++) {
                                                        _initialChecks.push(row.initialChecks[index].codeInitialCheck.name);
                                                    }
                                                    return _initialChecks.join(", ");
                                                }
                                            return '';
                                        }} />
                                        <Column title="Diagnosis" key="diagnosis" render={(value, row, index) => {
                                            if (row.diagnosis !== undefined)
                                                if (row.diagnosis.length > 0) {
                                                    let _diagnosis = [];
                                                    for (let index = 0; index < row.diagnosis.length; index++) {
                                                        _diagnosis.push(row.diagnosis[index].codeDiagnosis.name);
                                                    }
                                                    return _diagnosis.join(", ");
                                                }
                                            return '';
                                        }} />
                                        <Column title="Treatments" key="treatments" render={(value, row, index) => {
                                            if (row.treatments !== undefined)
                                                if (row.treatments.length > 0) {
                                                    let _treatments = [];
                                                    for (let index = 0; index < row.treatments.length; index++) {
                                                        _treatments.push(row.treatments[index].codeTreatment.name);
                                                    }
                                                    return _treatments.join(", ");
                                                }
                                            return '';
                                        }} />
                                        <Column title="Recommendations" key="recommendations" dataIndex="recommendations" />
                                        <Column title="Medicines" key="medicines" render={(value, row, index) => {
                                            if (row.medicines !== undefined)
                                                if (row.medicines.length > 0) {
                                                    let _medicines = [];
                                                    for (let index = 0; index < row.medicines.length; index++) {
                                                        _medicines.push(row.medicines[index].codeMedicine.name);
                                                    }
                                                    return _medicines.join(", ");
                                                }
                                            return '';
                                        }} />
                                        <Column title="Follow Ups" key="followUps" render={(value, row, index) => {
                                            return row.followUps.replace(",", ", ");;
                                        }} />
                                        <Column title="Transfer to Medical Center" key="transferToMedicalCenter" render={(value, row, index) => {
                                            if (row.medicalCenter !== undefined)
                                                if (row.medicalCenter.length > 0) {
                                                    let _medicalCenter = [];
                                                    for (let index = 0; index < row.medicalCenter.length; index++) {
                                                        _medicalCenter.push(row.medicalCenter[index].codeMedicalCenter.name);
                                                    }
                                                    return _medicalCenter.join(", ");
                                                }
                                            return '';
                                        }} />
                                        <Column
                                            width='50px'
                                            title="Action"
                                            key="action"
                                            render={(text, record) => {
                                                if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                                    (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                                        return (<div style={{ textAlign: "center" }}>
                                                            <React.Fragment key={record.id} >
                                                                <CustomMaterialMenuDeleteEdit row={record}
                                                                    onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
                                                            </React.Fragment>
                                                        </div>);
                                                    }
                                                    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                                        return (<React.Fragment key={record.id} >
                                                            <CustomMaterialMenuEdit row={record}
                                                                onEditRow={this.changeButtonUpdate.bind(this)} />
                                                        </React.Fragment>);
                                                    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                                        return (<React.Fragment key={record.id} >
                                                            <CustomMaterialMenuDelete row={record}
                                                                onDeleteRow={this.deleteModal.bind(this)} />
                                                        </React.Fragment>);
                                                    }
                                                }
                                                else {
                                                    return (<div style={{ textAlign: "center" }}>
                                                    </div>);
                                                }
                                            }}
                                        />
                                    </Table>
                            }}>
                            <Column title="Company" key="company" render={(value, row, index) => {
                                if (row.company !== undefined)
                                    if (row.company !== null)
                                        return row.company.fullName;
                                return '';
                            }} />
                            <Column title="Project" key="project" render={(value, row, index) => {
                                if (row.project !== undefined)
                                    if (row.project !== null)
                                        return row.project.fullname;
                                return '';
                            }} />
                            <Column title="ID" key="IdNo" render={(value, row, index) => {
                                if (row.staff !== undefined)
                                    if (row.staff !== null)
                                        return row.staff.nationalId;
                                return '';
                            }} />
                            <Column title="Name" key="name" render={(value, row, index) => {
                                if (row.staff !== undefined)
                                    if (row.staff !== null)
                                        return row.staff.name + " " + row.staff.midName + " " + row.staff.lastName;
                                return '';
                            }} />
                            <Column title="Department/Designation" key="designation" render={(value, row, index) => {
                                if (row.staff !== undefined)
                                    if (row.staff !== null)
                                        return row.staff.department.departmentName + " / " + row.staff.designation.designationName;
                                return '';
                            }} />
                        </Table>

                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        ));
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Clinic)