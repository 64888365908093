import { API_BASE } from "../config/env";
import axios from "axios";

export const NEW_ITEM_PENDING = "NEW_ITEM_PENDING";
export const NEW_ITEM_FULFILLED = "NEW_ITEM_FULFILLED";
export const NEW_ITEM_REJECTED = "NEW_ITEM_REJECTED";


export function onNewSelectItemSubmit({text}) {
  const pageid=1;
  const parentid=1;
  return dispatch => {
    dispatch({
      type:"NEW_ITEM",
      payload:axios.post(`${API_BASE}/selectsanditems/add`,{text,pageid,parentid})
      .then(result => result.data)
    })
    
        
  };
}
