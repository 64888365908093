/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Row, Container, Button as ReactButton, Modal } from 'react-bootstrap';
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button as PrimeButton } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { Cascader } from 'antd';

const dataList = [];
var order = 0;
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.data.id, lineage: node.data.lineage });
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class Whatis extends Component {

    constructor() {
        super();
        this.state = {
            hiddenDataGiris: true,
            hiddenSave: true,
            hiddenUpdate: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",

            languageTypeAll: [],
            languageTypeAlls: '',
            selectPageName: '',
            selectPageLang: '',
            itemDescription: '',
            itemIdCode: '',
            datatableValue: [],
            displayBasic: false,
            selectedinfoItem: null,
            updateId: null,
            buttonName: "Save",
            buttonClassName: "p-button-success",
            dynamicMenuCascader: [],
            pageName: [],
            selectPagesItemData:[],
        };
        this.saveItemInfo = this.saveItemInfo.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
    }

    componentDidMount = async () => {
        this.getDynamicMenu();
        this.restartTable();
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            generateList(responseTree.data.treeTable);
            this.setState({
                dynamicMenuCascader: responseTree.data.cascader
            });
        }
    }

    onDynamicMenuCascaderChange = (value) => {
        this.setState({
            pageName: value
        })

        if(Boolean(value) && value.length>0 ){
            this.getDynamicInputs(value[value.length - 1]);
        }else{
            this.getDynamicInputs(0);
        }
    }

    getDynamicInputs = async (dynamicMenuId) => {
        if (dynamicMenuId == null || dynamicMenuId == undefined || dynamicMenuId == "" || dynamicMenuId == 0) {
            dynamicMenuId = -1;
        }
        const obj = {
            dynamicMenuId: dynamicMenuId,
        };
        const response = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        console.log("YYYY", response.data);
        if (response.data.length !== 0) {
            this.setState({
                selectPagesItemData: response.data,
            });
        } else {
            this.setState({
                selectPagesItemData: [],
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Success"
        });
    };
    
    showError(msg) {
        if (!msg) {
            msg =  <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !"/>;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Error"
        });
    };
    
    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs."/>;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Warning"
        });
    };

    createNewClientButton = async () => {
        await this.resetInputs();
        if (this.state.createNewButton == "Create New") {
            this.setState({
                hiddenDataGiris: false,
                createNewButton: "Cancel",
                createNewButtonVariant: "danger",
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenDataGiris: true,
                createNewButton: "Create New",
                createNewButtonVariant: "info",
                hiddenSave: true,
                hiddenUpdate: true,
            });
        }
    }

    resetInputs = () => {
        this.setState({
            id: -1,
           
        });
    }
 
    saveItemInfo = async (e) => {
        const { selectPageLang } = this.state;
        const { selectPageName } = this.state;
        const { itemDescription } = this.state;
        const { itemIdCode } = this.state;
        const { updateId } = this.state;

        if (updateId == null || updateId.length < 1) {
            console.log('save')
            const prm = { itemId: itemIdCode, pageName: selectPageName, lang: "en", description: itemDescription };

            const response = await handleRequest("POST", "/ItemInfo/add", prm);
            if (response.data !== null && response.data !== undefined) {
              if (response.data == "SUCCESS") {
                this.showSuccess();
                this.setState({
                    itemIdCode: '',
                    selectPageName: '0',
                    selectPageLang: '0',
                    itemDescription: '',
                    updateId: null,
                    buttonName: "Save",
                    buttonClassName: "p-button-success"
                });
              }else {
                this.showError();  
              }
            }else{
              this.showError();  
            }  
        }
        else {
            console.log('update')
            const prm = { id: updateId, itemId: itemIdCode, pageName: selectPageName, lang: selectPageLang, description: itemDescription };

            const response = await handleRequest("POST", "/ItemInfo/add", prm);

            this.showSuccess();

            this.setState({
                itemIdCode: '',
                selectPageName: '0',
                selectPageLang: '0',
                itemDescription: '',
                updateId: null,
                buttonName: "Save",
                buttonClassName: "p-button-success"
            });


        }
        this.restartTable();


    };
  
    handleChangeLang = e => {
        console.log(e.target.value);
        this.setState({ selectPageLang: e.target.value });
    };


    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true,
            selectedinfoItem: rowData
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }
 
    renderFooter(name) {
        return (
            <div>
                <PrimeButton label="Yes" icon="pi pi-check" onClick={() => this.deleteItemInfo(name)} className="p-button-danger" />
                <PrimeButton label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }
  
    actionTemplate(rowData) {
        return <React.Fragment> 
                <CustomMaterialMenu row={rowData} 
                    onDeleteRow={this.deleteItemInfo.bind(this)} 
                    onEditRow={this.changeButtonUpdate.bind(this)} />   
                </React.Fragment>;
    };
  
    restartTable = async () => {

        const { selectPageLang } = this.state;
        const { selectPageName } = this.state;
        const { itemIdCode } = this.state;

        const prm = { itemId: itemIdCode, pageName: selectPageName, lang: selectPageLang }

        const response = await handleRequest("POST", "/ItemInfo/getResult", prm);

        console.log(response)
        if (response.data.length !== 0) {
            this.setState({
                datatableValue: response.data,
            });
        }
    }

    changeButtonUpdate = (rowData) => {
        this.setState({
            hiddenDataGiris: false,
            hiddenSave: true,
            hiddenUpdate: false,
            createNewButton: "Cancel",
            createNewButtonVariant: "danger",
            
            itemIdCode: rowData.itemId,
            itemDescription: rowData.description,
            selectPageLang: rowData.lang,
            selectPageName: rowData.pageName,
            updateId: rowData.id,
            hidden: false,
            buttonName: "Update",
            buttonClassName: "p-button-warning"
        });
    };

    changeButtonCancel = () => {
        this.setState({
            hidden: true,
            hiddenSave: true,
            hiddenUpdate: false,
            hiddenDataGiris: true,
            hiddenCreateNewButton:false,
            createNewButton: "Create New",
            createNewButtonVariant: "info", 
        });
        this.resetInputs();
    };
    
    updateDone = async () => {
        if (this.state.ItemInfoId > 0) {
    
          var updateItemInfo = {
            id: this.state.id,
            ItemInfoId: this.state.id, 
            plateNo: this.state.plateNo,
            make: this.state.make,
            modelYear: this.state.modelYear,
            grade: this.state.grade,
            type: this.state.type,
            colour: this.state.colour,
            engineNo: this.state.engineNo,
            identificationNumber: this.state.identificationNumber,
            cylinderVolume: this.state.cylinderVolume,
            enginePowerHP: this.state.enginePowerHP,
            fuelType: this.state.fuelType,
            vehicleClass: this.state.vehicleClass,
            weight: this.state.weight,
            maxLoadCapacity: this.state.maxLoadCapacity,
            maxGrossWeight: this.state.maxGrossWeight,
            kerbWeight: this.state.kerbWeight,
            trailerMaxLoadedWeight: this.state.trailerMaxLoadedWeight,
            seatingCapacity: this.state.seatingCapacity,
            typeOfBody: this.state.typeOfBody,
            issuingProvinceCountry: this.state.issuingProvinceCountry,
            theDateOfUsageOnTraffic: this.state.theDateOfUsageOnTraffic,
            firstRegistrationDate: this.state.firstRegistrationDate,
            registrationSerialNo: this.state.registrationSerialNo,
            registrationDate: this.state.registrationDate,
            tradeName: this.state.tradeName,
            value: this.state.value,
            nameOfOwner: this.state.nameOfOwner,
            ownerIqamaNo: this.state.ownerIqamaNo,
            vehicleNo: this.state.vehicleNo,
            registrationNo: this.state.registrationNo,
            sequenceNo: this.state.sequenceNo,
            expiryDate: this.state.expiryDate,
            firstTestExpiryDate: this.state.firstTestExpiryDate,
            kmYear: this.state.kmYear,
            mobilizationOrder: this.state.mobilizationOrder,
          };
    
            var response = await handleRequest("POST", "/ItemInfo/update", updateItemInfo);
    
          if (response.data !== null && response.data !== undefined) {
            if (response.data == "SUCCESS") {
              this.createNewClientButton();
              this.resetInputs();
              this.showSuccess();
              this.restartTable();
            }
            else {
              this.showError();
            }
          }
          else {
            this.showWarning();
          }
        }
        else {
          this.showWarning();
        }
    };

    deleteItemInfo = (rowData) => {
        this.setState({
          deleteModal: true,
          ItemInfoId: rowData.id,
        });
    };
    
    deleteItemInfoOK = async () => {
        const silinecekid = {
            id: this.state.selectedinfoItem.id
        }
        const response = await handleRequest("POST", "/ItemInfo/delete", silinecekid);
        if (response.data !== null && response.data !== undefined) {
          if (response.data == "SUCCESS") {
            console.log(response.data)
            this.restartTable();
            this.showSuccess();
          }else {
            this.showError();  
          }
        }else{
          this.showError();  
        } 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }
 
    render() { 
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>


        let selectPagesItem = this.state.selectPagesItemData.map((data) =>
        <option key={data.id} value={data.inputDescription}>
             {data.inputDescription}
        </option>
        );


    return (<div>
        <Form>
            <Row>
                    <Col xs={9}></Col>
                    <Col xs={3}>
                        <ReactButton onClick={this.createNewClientButton} style={{ width: '100%' }}
                            variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                        </ReactButton>
                    </Col>
                </Row>

            <div hidden={this.state.hiddenDataGiris}>
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                    <Col sm="2"></Col>
                    <Form.Label style={{ color: 'black' }} column sm="2">
                        <FormattedMessage id="WHATPageName" defaultMessage="Page Name" />
                    </Form.Label>
                    <Col sm="4">
                        <Cascader style={{ width: '100%' }} value={this.state.pageName}
                            options={this.state.dynamicMenuCascader}
                            onChange={this.onDynamicMenuCascaderChange} />
                    </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="itemidID">
                    <Col sm="2">
                    </Col>
                    <Form.Label style={{ color: 'black' }} column sm="2">
                        Item Id :
                            </Form.Label>
                    <Col sm="4">
                        <Form.Control value={this.state.itemIdCode}
                            onChange={(e) => this.setState({ itemIdCode: e.target.value })} as="select">
                            <option value="0"> Select ... </option>
                            {selectPagesItem}
                        </Form.Control>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>
                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                    <Col sm="2">
                    </Col>
                    <Form.Label style={{ color: 'black' }} column sm="2">
                        Description:
                            </Form.Label>
                    <Col sm="4">
                        <InputTextarea style={{ width: '100%' }} id="descId"
                            onChange={(e) => this.setState({ itemDescription: e.target.value })}
                            value={this.state.itemDescription} rows={3} placeholder=" description" />
                    </Col>
                    <Col sm="3">
                    </Col>
                </Form.Group>
                </div>

            <br />
            <div hidden={this.state.hiddenSave}>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col sm="4"> </Col>
                        <Col sm="4">
                            <ReactButton onClick={this.saveItemInfo} style={{ width: '100%' }} 
                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save"/>
                            </ReactButton>
                        </Col>
                        <Col sm="3">
                        </Col>
                    </Row>
                </Container>
            </div>

            <div hidden={this.state.hiddenUpdate}>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                <Row>
                    <Col sm="4">
                    </Col>
                    <Col sm="2">
                    <ReactButton onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></ReactButton>
                    </Col>
                    <Col sm="2">
                    <ReactButton onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></ReactButton>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Row>
                </Container>
            </div>   
            <br />

            <div style={{ marginTop: '40px' }} className="content-section implementation">
                <DataTable ref={(el) => this.dt = el} value={this.state.datatableValue} paginator={true} responsive={true}
                    rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-disciplines"
                    selectionMode="single" selection={this.state.selectedDiscipline} onSelectionChange={e => this.setState({ selectedDiscipline: e.value })}>
                    <Column field="id" style={{ textAlign: 'center' }} sortable header="S/N" />
                    <Column field="pageName" style={{ textAlign: 'center' }} filter sortable header="Page Name" />
                    <Column field="itemId" style={{ textAlign: 'center' }} filter sortable header="Item ID" />
                    <Column field="description" filter sortable header="Description" />
                    <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                </DataTable>
            </div>
            <Dialog header="Delete This Discipline Record?" visible={this.state.displayBasic}
                style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false}
                footer={this.renderFooter('displayBasic')}>
                <p>This Item info record will be deleted. Are you sure?</p>
            </Dialog>

            <Modal
            show={this.state.deleteModal}
            onHide={this.showOrHideModalDeleteItemInfo}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete"/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ textAlign: "center", color: "black" }}>
                    <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                </p>
                <p style={{ textAlign: "center", color: "black" }}>
                    <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                </p>
            </Modal.Body>
            <Modal.Footer>
                <ReactButton
                variant="secondary"
                onClick={this.showOrHideModalDeleteItemInfo}
                >
                    <FormattedMessage id="GeneralTextClose" defaultMessage="Close"/>
                </ReactButton>
                <ReactButton variant="danger" onClick={this.deleteItemInfoOK}>
                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete"/>
                </ReactButton>
            </Modal.Footer>
        </Modal>

            <Modal
            show={this.state.modalMessage}
            onHide={this.showOrHideModalMessage}
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <Row > 
                    <Col sm="12">
                    {this.state.messageTip=="Success" &&  
                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                    
                    {this.state.messageTip=="Warning" &&  
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                    {this.state.messageTip=="Error" &&  
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                    </Col>
                </Row>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row > 
                <Col sm="12">
                    {this.state.message} 
                </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></ReactButton>
            </Modal.Footer>
            </Modal>
        
        </Form>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Whatis)