/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Button, Accordion, InputGroup } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from "prop-types";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import DatePicker from 'react-date-picker';
import { fetchCustomerLogs } from "../../redux/actions/customerLogAction";
import memoize from 'memoize-one';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { IconButton  } from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import { COUNTRIES } from '../settings/JsonDatas/Datas';
import { API_BASE } from '../../redux/config/env';
import { handleRequest } from '../../redux/config/env';
import { Chips } from 'primereact/chips';
import { OrganizationChart } from 'primereact/organizationchart';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import moment from 'moment'
import { Upload, message, Button as ButtonA } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import '../location/OrganizationChartCustomStyle.css';


const sortIcon = <ArrowDownward />;
const actions = (
  <IconButton color="primary">
    <Add />
  </IconButton>
);


function phones(value) {

  console.log(value);
  var rowData = value;

  var result = [];
  if (rowData !== null && rowData !== undefined && rowData.phones !== null && rowData.phones !== undefined) {

    rowData.phones.forEach(e => {

      result.push(e.phone);

    });

  }

  return result.toString();
};

function faxes(value) {

  console.log(value);
  var rowData = value;

  var result = [];
  if (rowData !== null && rowData !== undefined && rowData.faxes !== null && rowData.faxes !== undefined) {

    rowData.faxes.forEach(e => {

      result.push(e.fax);

    });

  }

  console.log(result);
  return result.toString();
};

function mails(value) {

  console.log(value);
  var rowData = value;

  var result = [];
  if (rowData !== null && rowData !== undefined && rowData.mails !== null && rowData.mails !== undefined) {

    rowData.mails.forEach(e => {

      result.push(e.mail);

    });

  }

  return result.toString();
};

const columnsContact = memoize((a, b) => [
  {
    cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />,
    allowOverflow: true,
    button: true,
    width: '56px',
  },
  {
    name: "Location",
    selector: "location",
    sortable: true,
    //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
  },
  {
    name: "Address",
    selector: "address",
    sortable: true,
    //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
  },
  {
    name: "Phones",
    cell: row => phones(row),
    sortable: true
  },
  {
    name: "Fax",
    cell: row => faxes(row),
    sortable: true
  },
  {
    name: "E-Mail",
    cell: row => mails(row),
    sortable: true,

  },
  {
    name: 'Social',
    selector: 'socialAccount',
    sortable: true,

  }
]);
class ProjectProfiles extends Component {
  constructor(props) {
    super(props)
    this.state = {

      modalShow: false,
      contactList: [],
      modalContactShow: false,
      modalConfirmShow: false,
      hidden: true,
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",

      projectId: 0,
      projectCode: "",
      projectContractNo: "",
      projectFullName: "",
      projectShortName: "",
      projectCountry: "Select Country",
      projectAddress: "",
      contractSignDate: new Date(),
      contractPath: null,
      showContractPath:null,
      kickOffDate: new Date(),
      endOfProject: new Date(),
      projectCity: "",
      projectManagerId: 0,
      projectDescriptionPath: null,
      showProjectDescriptionPath:null, 
      projectClient: "",

      itemsContacts: [],
      orgChartNodes: [{
        label: '',
        className: "",
        expanded: true,
        children: [

        ]
      }],

      contactId: 0,
      location: "Select",
      address: "",

      web: "",
      oldContact: {},
      phone: [],
      fax: [],
      email: [],
      social: [],

      inputP: '',
      inputF: '',
      inputW: '',
      inputE: '',
      inputS: '',

      projectManager: "Select",
      quality: "Select",
      safety: "Select",
      admin: "Select",
      itemsProductServ: [],
      productServ: "Select",
      productDesc: "",
      productUnit: "m3",
      productPrice: "",
      productPriceUnit: "SR",
      projectProfileId: ""
    };

    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);

    this.handleInputChangeP = this.handleInputChangeP.bind(this);
    this.handleInputKeyDownP = this.handleInputKeyDownP.bind(this);
    this.handleRemoveItemP = this.handleRemoveItemP.bind(this);

    this.handleInputChangeF = this.handleInputChangeF.bind(this);
    this.handleInputKeyDownF = this.handleInputKeyDownF.bind(this);
    this.handleRemoveItemF = this.handleRemoveItemF.bind(this);

    this.handleInputChangeW = this.handleInputChangeW.bind(this);
    this.handleInputKeyDownW = this.handleInputKeyDownW.bind(this);
    this.handleRemoveItemW = this.handleRemoveItemW.bind(this);

    this.handleInputChangeE = this.handleInputChangeE.bind(this);
    this.handleInputKeyDownE = this.handleInputKeyDownE.bind(this);
    this.handleRemoveItemE = this.handleRemoveItemE.bind(this);

    this.handleInputChangeS = this.handleInputChangeS.bind(this);
    this.handleInputKeyDownS = this.handleInputKeyDownS.bind(this);
    this.handleRemoveItemS = this.handleRemoveItemS.bind(this);

    this.restartContactTable();

  }

  static propTypes = {
    customerLogReducer: PropTypes.shape({ selectItemList: PropTypes.array.isRequired })
  }

  componentDidMount = async () => {
    this.props.fetchCustomerLogs();
    // document.getElementById('text-filter-column-Product ID').style.display = "none";
    // document.getElementById('text-filter-column-Product Name').style.display = "none";
    // document.getElementById('text-filter-column-Product Price').style.display = "none";
    // document.getElementById('select-filter-column-Product Type').style.display = "none";

    const responseTree = await handleRequest("GET", "/Department/getTreeModel");

    console.log(responseTree.data);
    if (responseTree.data.length !== 0) {
      this.setState({
        orgChartNodes: responseTree.data.cascader
      });


    }

    const response = await handleRequest("GET", "/CompanyProjects/getProjectInformation");

    console.log(response.data);
    if (response.data !== null && response.data !== undefined && response.data.length !== 0) {


      var obj = response.data;
      var _contractSignDate = '', _kickOffDate ='', _endOfProject='' ;

      if(obj.contractSignDate!==null && obj.contractSignDate!==undefined)
      {
        _contractSignDate = new Date(obj.contractSignDate) ;
      } 
      if(obj.kickOffDate!==null && obj.kickOffDate!==undefined)
      {
        _kickOffDate = new Date(obj.kickOffDate) ;
      } 
      if(obj.endOfProject!==null && obj.endOfProject!==undefined)
      {
        _endOfProject = new Date(obj.endOfProject) ;  
      } 
      this.setState({
        projectId: obj.id,
        projectCode: obj.companyprojectcode,
        projectContractNo: obj.contractno,
        projectFullName: obj.fullname,
        projectShortName: obj.shortname,
        projectCountry: obj.country,
        projectAddress: obj.address,
        contractSignDate: _contractSignDate,
        contractPath: obj.contractPath,
        showContractPath:obj.contractPath,
        kickOffDate: _kickOffDate,
        endOfProject:_endOfProject, 
        projectCity: obj.city,
        projectManagerId: obj.projectManagerId,
        projectDescriptionPath: obj.projectDescriptionPath,
        showProjectDescriptionPath: obj.projectDescriptionPath,
        projectClient: obj.client

      });


    }

  }

  saveProjectInfo = async () => {
    if (this.state.projectFullName.trim() !== "") {



      const newItem = {

        id: this.state.projectId,
        companyprojectcode: this.state.projectCode,
        contractno: this.state.projectCode,
        fullname: this.state.projectFullName,
        shortname: this.state.projectShortName,
        country: this.state.projectCountry,
        city: this.state.projectCity,
        address: this.state.projectAddress,
        contractSignDate: moment(this.state.contractSignDate).add(3, 'hours'),
        contractPath: this.state.contractPath,
        kickOffDate: moment(this.state.kickOffDate).add(3, 'hours'),
        endOfProject: moment(this.state.endOfProject).add(3, 'hours'),
        projectManagerId: this.state.projectManagerId,
        projectDescriptionPath: this.state.projectDescriptionPath,
        client: this.state.projectClient

      }

      // if (newItem.id === 0)
      //     await await handleRequest("POST", "/CompanyAbout/add", newItem);
      // else 
      await await handleRequest("POST", "/CompanyProjects/update", newItem);


      this.showSuccess();
    } else {
      this.showError();
    }
  };

  saveProjectDescription = async () => {
    if (this.state.projectDescriptionPath.trim() !== "") {

      const newItem = {

        id: this.state.projectId,

        projectDescriptionPath: this.state.projectDescriptionPath,


      }
      // if (newItem.id === 0)
      //     await await handleRequest("POST", "/CompanyAbout/add", newItem);
      // else 
      await await handleRequest("POST", "/CompanyProjects/saveProjectDescription", newItem);


      this.showSuccess();
    } else {
      this.showError();
    }
  };

  // #region tags 
  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
  };

  showError(message) {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
  };


  restartContactTable = async () => {

    const response = await handleRequest("GET", "/CompanyContact/getAllByCompany");

    if (response.data.length === 0) {
      this.setState({
        contactList: []

      });
    }

    if (response.data.length !== 0) {
      this.setState({
        contactList: response.data
      });
    }
  }

  addContact = () => {
    console.log("Geldi");
    this.setState({

      contactId: 0,
      location: "Select",
      address: "",
      web: "",

      phone: [],
      fax: [],
      email: [],
      social: [],
      modalContactShow: true


    });
  }

  resetContactInputs = () => {
    this.setState({
      contactId: 0,
      location: "Select",
      address: "",
      phone: "",
      fax: "",
      web: "",
      email: "",
      social: "",
      modalContactShow: false,
      modalConfirmShow: false
    });
  };



  editContact = (row) => {

    console.log(row);

    var _phones = [];
    var _faxes = [];
    var _mails = [];
    var _socials = [];

    if (row.phones !== null && row.phones !== undefined)
      row.phones.forEach(element => {
        _phones.push(element.phone);
      });

    if (row.faxes !== null && row.faxes !== undefined)
      row.faxes.forEach(element => {
        _faxes.push(element.fax);
      });

    if (row.mails !== null && row.mails !== undefined)
      row.mails.forEach(element => {
        _mails.push(element.mail);
      });

    if (row.socials !== null && row.socials !== undefined)
      row.socials.forEach(element => {
        _socials.push(element.socialAccount);
      });

    this.setState({
      contactId: row.id,
      location: row.location,
      address: row.address,
      phone: _phones,
      fax: _faxes,
      web: row.web,
      email: _mails,
      social: _socials,
      modalContactShow: true,
      oldContact: row,

    });
  }


  deleteContactModal = (row) => {

    this.setState({
      contactId: row.id,
      modalConfirmShow: true
    });
  }

  delete = async () => {

    if (this.state.contactId > 0) {

      const deletedItem = {
        id: this.state.contactId

      }

      await handleRequest("POST", "/CompanyContact/delete", deletedItem);

      this.restartContactTable();
      this.resetContactInputs();
      this.showSuccess();
    }

  }

  showOrHideContactModal = () => {
    this.setState({
      modalContactShow: !this.state.modalContactShow
    });
    this.resetContactInputs();
  }

  saveContact = async () => {
    if (this.state.location.trim() !== "Select") {

      var _phoneList = [];

      this.state.phone.forEach(element => {
        const item = { phone: element };
        _phoneList.push(item);
      });

      var _faxList = [];

      this.state.fax.forEach(element => {
        const item = { fax: element };
        _faxList.push(item);
      });

      var _mailList = [];

      this.state.email.forEach(element => {
        const item = { mail: element };
        _mailList.push(item);
      });

      var _socialList = [];

      this.state.social.forEach(element => {
        const item = { socialAccount: element };
        _socialList.push(item);
      });


      const newItem = {
        id: this.state.contactId,
        location: this.state.location,
        address: this.state.address,
        phones: _phoneList,
        faxes: _faxList,
        web: this.state.web,
        mails: _mailList,
        socials: _socialList,
        oldCompanyContact: this.state.oldContact
      }

      if (newItem.id === 0)
        await await handleRequest("POST", "/CompanyContact/add", newItem);
      else await await handleRequest("POST", "/CompanyContact/update", newItem);

      this.resetContactInputs();
      this.restartContactTable();
      this.showSuccess();
    } else {
      this.showError();
    }
  };


  // #region tags

  handleInputChangeP(evt) {
    this.setState({ inputP: evt.target.value });
  }

  handleInputKeyDownP(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        phones: [...state.phones, value],
        inputP: ''
      }));
    }

    if (this.state.phones.length && evt.keyCode === 8 && !this.state.inputP.length) {
      this.setState(state => ({
        phones: state.phones.slice(0, state.phones.length - 1)
      }));
    }
  }

  handleRemoveItemP(index) {
    return () => {
      this.setState(state => ({
        phones: state.phones.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeF(evt) {
    this.setState({ inputF: evt.target.value });
  }

  handleInputKeyDownF(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        faxes: [...state.faxes, value],
        inputF: ''
      }));
    }

    if (this.state.faxes.length && evt.keyCode === 8 && !this.state.inputF.length) {
      this.setState(state => ({
        faxes: state.faxes.slice(0, state.faxes.length - 1)
      }));
    }
  }

  handleRemoveItemF(index) {
    return () => {
      this.setState(state => ({
        faxes: state.faxes.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeW(evt) {
    this.setState({ inputW: evt.target.value });
  }

  handleInputKeyDownW(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        webs: [...state.webs, value],
        inputW: ''
      }));
    }

    if (this.state.webs.length && evt.keyCode === 8 && !this.state.inputW.length) {
      this.setState(state => ({
        webs: state.webs.slice(0, state.webs.length - 1)
      }));
    }
  }

  handleRemoveItemW(index) {
    return () => {
      this.setState(state => ({
        webs: state.webs.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeE(evt) {
    this.setState({ inputE: evt.target.value });
  }

  handleInputKeyDownE(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        emails: [...state.emails, value],
        inputE: ''
      }));
    }

    if (this.state.emails.length && evt.keyCode === 8 && !this.state.inputE.length) {
      this.setState(state => ({
        emails: state.emails.slice(0, state.emails.length - 1)
      }));
    }
  }

  handleRemoveItemE(index) {
    return () => {
      this.setState(state => ({
        emails: state.emails.filter((item, i) => i !== index)
      }));
    }
  }

  handleInputChangeS(evt) {
    this.setState({ inputS: evt.target.value });
  }

  handleInputKeyDownS(evt) {
    if (evt.keyCode === 13) {
      const { value } = evt.target;

      this.setState(state => ({
        socials: [...state.socials, value],
        inputS: ''
      }));
    }

    if (this.state.socials.length && evt.keyCode === 8 && !this.state.inputS.length) {
      this.setState(state => ({
        socials: state.socials.slice(0, state.socials.length - 1)
      }));
    }
  }

  handleRemoveItemS(index) {
    return () => {
      this.setState(state => ({
        socials: state.socials.filter((item, i) => i !== index)
      }));
    }
  }

  // #endregion tags

  onChangeCSD = contractSignDate => this.setState({ contractSignDate })
  onChangeKOD = kickOffDate => this.setState({ kickOffDate })
  onChangeEOP = endOfProject => this.setState({ endOfProject })

  showOrHideModal = () => {
    this.setState({
      modalShow: !this.state.modalShow
    });
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  createNewProjectProfileButton = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Adding New",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
      this.refreshAccordions();
    }
    else {
      this.setState({
        hidden: true,
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info"
      });
      this.refreshAccordions();
    }
  }

  updateProjectProfileButton = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Project Profile",
        lastButton: "Update",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
    else if (this.state.createNewButton === "Stop Adding New") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Project Profile",
        lastButton: "Update",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
    else if (this.state.createNewButton === "Stop Updating Project Profile") {
      this.setState({
        hidden: false,
        createNewButton: "Stop Updating Project Profile",
        createNewButtonVariant: "warning"
      });
      this.refreshAccordions();
    }
  }

  // #region Refresh Accordions

  refreshAccordions = () => {
    this.setState({
      projectCode: "",
      projectContractNo: "",
      projectFullName: "",
      projectShortName: "",
      projectCountry: "Select Country",
      contractSignDate: new Date(),
      kickOffDate: new Date(),
      endOfProject: new Date(),
      itemsContacts: [],
      location: "Select",
      address: "",
      phones: [],
      faxes: [],
      webs: [],
      emails: [],
      socials: [],
      inputP: '',
      inputF: '',
      inputW: '',
      inputE: '',
      inputS: '',
      projectDescription: "",
      projectManager: "Select",
      quality: "Select",
      safety: "Select",
      admin: "Select",
      itemsProductServ: [],
      productServ: "Select",
      productDesc: "",
      productUnit: "m3",
      productPrice: "",
      productPriceUnit: "SR",
      projectProfileId: ""
    });

    if (document.getElementById('project-profile-data-status').checked === false)
      document.getElementById('project-profile-data-status').click();
    if (document.getElementById('project-desc-data-status').checked === true)
      document.getElementById('project-desc-data-status').click();
    if (document.getElementById('organization-data-status').checked === true)
      document.getElementById('organization-data-status').click();
    if (document.getElementById('contact-data-status').checked === true)
      document.getElementById('contact-data-status').click();
    if (document.getElementById('repres-data-status').checked === true)
      document.getElementById('repres-data-status').click();
    if (document.getElementById('product-data-status').checked === true)
      document.getElementById('product-data-status').click();
    document.getElementById('projectInformationAccordion').className = "collapse show";
    document.getElementById('projectDescAccordion').className = "collapse";
    document.getElementById('contactAccordion').className = "collapse";
    document.getElementById('organizationAccordion').className = "collapse";
    document.getElementById('represAccordion').className = "collapse";
    document.getElementById('productAccordion').className = "collapse";
  }

  // #endregion Refresh Accordions

  onEditClick(e) {
    if (e.target.value === undefined)
      e.stopPropagation();
  }


  addContactItem = () => {
    this.setState(state => ({
      itemsContacts: [...state.itemsContacts, {
        contactId: this.state.itemsContacts.length,
        location: this.state.location,
        address: this.state.address,
        phone: this.state.phones,
        fax: this.state.faxes,
        web: this.state.webs,
        email: this.state.emails,
        soccAcc: this.state.socials
      }]
    }));
  }

  addProductsService = () => {
    this.addProductServiceItem();
    this.setState({
      productServ: "Select",
      productDesc: "",
      productUnit: "m3",
      productPrice: "",
      productPriceUnit: "SR"
    });
  }

  addProductServiceItem = () => {
    this.setState(state => ({
      itemsProductServ: [...state.itemsProductServ, {
        productItemId: this.state.itemsProductServ.length,
        productServ: this.state.productServ,
        description: this.state.productDesc,
        unit: this.state.productUnit,
        price: this.state.productPrice,
        priceUnit: this.state.productPriceUnit
      }]
    }));
  }

  test = () => {
    console.log(this.state.location);
    this.addContact();
  }












  addProjectProfile = () => {
    //var projectProfileIdFlag = 0;

    //Bu şekilde json'a çevirip veritabanına kaydedilecek.

    let state = {
      name: "Example",
      age: this.state.itemsContacts,
      description: "Some text here."
    }

    console.log(state);
    console.log(JSON.stringify(state));  //String'e atanacak.

    // if (document.getElementById('customer-data-status').checked) {
    //     await fetch(`http://localhost:4000/customers/add?name=${values.name}&code=${values.code}`)
    //         .catch(err => console.error(err));

    //     await fetch(`http://localhost:4000/customers/get?name=${values.name}&code=${values.code}`)
    //         .then(response => response.json())
    //         .then(response => customerIdFlag = response.data[0].customerId)
    //         .catch(err => console.error(err));
    // }

    // if (itemsContacts.length !== 0 && document.getElementById('contact-data-status').checked) {
    //     for (var j = 0; j < itemsContacts.length; j++) {
    //         await fetch(`http://localhost:4000/contacts/add?location=${itemsContacts[j].location}&address=${itemsContacts[j].address}&customerId=${customerIdFlag}`)
    //             .catch(err => console.error(err));
    //     }
    // }

    // if (itemsRepres.length !== 0 && document.getElementById('repres-data-status').checked) {
    //     for (var k = 0; k < itemsRepres.length; k++) {
    //         await fetch(`http://localhost:4000/representives/add?name=${itemsRepres[k].name}&designation=${itemsRepres[k].designation}&customerId=${customerIdFlag}`)
    //             .catch(err => console.error(err));
    //     }
    // }

    // if (itemsProductServ.length !== 0 && document.getElementById('product-data-status').checked) {
    //     for (k = 0; k < itemsProductServ.length; k++) {
    //         await fetch(`http://localhost:4000/productsservices/add?productService=${itemsProductServ[k].productServ}
    //             &description=${itemsProductServ[k].description}&unit=${itemsProductServ[k].unit}&price=${itemsProductServ[k].price}
    //             &priceUnit=${itemsProductServ[k].priceUnit}&spec=${itemsProductServ[k].spec}&photo=${itemsProductServ[k].photo}
    //             &customerId=${customerIdFlag}`)
    //             .catch(err => console.error(err));
    //     }
    // }

    //// getCustomers(); bunun yerine aşağıdakini yapacak. Bunu yapmayabilir de
    //// componentDidMount kendi de çalışabilir. Test et ekstra çalıştırma.
    // this.props.fetchCustomerLogs();
    // refreshAccordions();
    // createNewCustomerButton();
  }

  deleteProjectProfile = () => {

    console.log(this.state.projectProfileId);

    // await fetch(`http://localhost:4000/deletecustomer?customerId=${customerId}`)
    //     .then(response => console.log(customerId))
    //     .catch(err => console.error(err));
    //// getCustomers(); bunun yerine aşağıdakini yapacak. Bunu yapmayabilir de
    //// componentDidMount kendi de çalışabilir. Test et ekstra çalıştırma.
    // this.props.fetchCustomerLogs();
    // refreshAccordions();
    // showOrHideModal();
  }

  updateProjectProfile = () => {

    console.log(this.state.projectProfileId);  //Bu id üzerinden update yapılacak.
    console.log(JSON.parse('[{"id":1,"description":"TestDescription","photourl":"www.1.com","price":"500","serviceproduct":"Product","spec":1,"unit":1},{"id":2,"description":"TestDescription-2","photourl":"www.2.com","price":"600","serviceproduct":"Service","spec":1,"unit":1}]')); console.log(JSON.parse('[{"id":1,"description":"TestDescription","photourl":"www.1.com","price":"500","serviceproduct":"Product","spec":1,"unit":1},{"id":2,"description":"TestDescription-2","photourl":"www.2.com","price":"600","serviceproduct":"Service","spec":1,"unit":1}]'));

    //contact info için?

    this.updateProjectProfileButton();

    // await fetch(`http://localhost:4000/customers/getone?customerId=${id}`)
    //   .then(response => response.json())
    //   .then(response => arrayTest = response.data)
    //   .catch(err => console.error(err));

    // setValues({ ...values, ['name']: arrayTest[0].customerName, ['code']: arrayTest[0].customerCode });

    // await fetch(`http://localhost:4000/contactids?customerId=${id}`)
    //   .then(response => response.json())
    //   .then(response => arrayTest = response.data)
    //   .catch(err => console.error(err));

    // if (arrayTest.length > 0) {
    //   document.getElementById('contact-data-status').click();
    // }

    // setItemsContacts([...itemsContacts, {
    //     contactId: itemsContacts.length,
    //     location: itemContactLocation,
    //     address: itemContactAddress,
    //     phone: itemContactPhones,
    //     fax: itemContactFaxes,
    //     web: itemContactWebs,
    //     email: itemContactEmails,
    //     soccAcc: itemContactSocs
    // }]);

    // itemsContacts.map(itemsContact =>
    //     console.log(itemsContact.location)
    // )
  }







  showOrHideConfirmModal = () => {
    this.setState({
      modalConfirmShow: !this.state.modalConfirmShow
    });
  }


  uploadProjectDescription = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);

      if (info.file.response.length > 0) {
        this.setState({
          projectDescriptionPath: info.file.response[0]
        })
      }

    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  downloadProjectDescription = async () => {

    window.location.href = `${API_BASE}/CompanyProjects/download/project/` + this.state.projectId;

  }

  uploadContract = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);

      console.log(info.file);
      if (info.file.response.length > 0) {
        this.setState({
          contractPath: info.file.response[0]
        })
      }

    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  downloadContract = async () => {

    window.location.href = `${API_BASE}/CompanyProjects/download/contract/` + this.state.projectId;

  }


  render() {
    const selectOptions = {
      0: 'N/A',
      1: 'Service',
      2: 'Product'
    };
    const products = [
      { id: 1, name: "apples", price: `Trader Joe's`, type: 0 },
      { id: 2, name: "oranges", price: `Safeway`, type: 1 },
      { id: 3, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 4, name: "grapes", price: `Lucky's`, type: 2 },
      { id: 5, name: "oranges", price: `Safeway`, type: 0 },
      { id: 6, name: "bananas", price: `Trader Joe's`, type: 2 },
      { id: 7, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 8, name: "oranges", price: `Safeway`, type: 0 },
      { id: 9, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 10, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 11, name: "oranges", price: `Safeway`, type: 1 },
      { id: 12, name: "bananas", price: `Trader Joe's`, type: 2 },
      { id: 13, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 14, name: "grapes", price: `Lucky's`, type: 0 },
      { id: 15, name: "oranges", price: `Safeway`, type: 2 },
      { id: 16, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 17, name: "grapes", price: `Lucky's`, type: 1 },
      { id: 18, name: "grapes", price: `Lucky's`, type: 2 },
      { id: 19, name: "oranges", price: `Safeway`, type: 1 },
      { id: 20, name: "bananas", price: `Trader Joe's`, type: 1 },
      { id: 21, name: "bananas", price: `Trader Joe's`, type: 2 },
      { id: 22, name: "grapes", price: `Lucky's`, type: 0 },
      { id: 23, name: "oranges", price: `Trader Joe's`, type: 1 },
      { id: 24, name: "bananas", price: `Lucky's`, type: 2 },
      { id: 25, name: "grapes", price: `Trader Joe's`, type: 1 },
      { id: 26, name: "oranges", price: `Trader Joe's`, type: 0 }];
    const columns = [{
      dataField: 'id',
      text: 'Product ID',
      footer: 'Product ID',
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'inStock',
      text: 'Actions',
      footer: 'Actions',
      headerStyle: {
        verticalAlign: 'top',
        cursor: 'zoom-in'
      },
      headerEvents: {
        onClick: () => {
          if (document.getElementById('text-filter-column-Product Name').style.display === "none") {
            document.getElementById('text-filter-column-Product ID').style.display = "block";
            document.getElementById('text-filter-column-Product Name').style.display = "block";
            document.getElementById('text-filter-column-Product Price').style.display = "block";
            document.getElementById('select-filter-column-Product Type').style.display = "block";
          }
          else {
            document.getElementById('text-filter-column-Product ID').style.display = "none";
            document.getElementById('text-filter-column-Product Name').style.display = "none";
            document.getElementById('text-filter-column-Product Price').style.display = "none";
            document.getElementById('select-filter-column-Product Type').style.display = "none";
          }
        }
      },
      formatter: () => (
        <DropdownButton
          size="sm"
          variant="secondary"
          title="..."
          id={`dropdown-button-drop`}
        >
          <Dropdown.Item onClick={this.updateProjectProfile} eventKey="1">Edit</Dropdown.Item>
          <Dropdown.Item onClick={this.showOrHideModal} eventKey="2">Delete</Dropdown.Item>
        </DropdownButton>
      )
    },
    {
      dataField: 'name',
      text: 'Product Name',
      footer: 'Product Name',
      sort: true,
      filter: textFilter()
    }, {
      dataField: 'price',
      text: 'Product Price',
      footer: 'Product Price',
      sort: true,
      filter: textFilter()
    }, {
      dataField: 'type',
      text: 'Product Type',
      footer: 'Product Type',
      sort: true,
      formatter: cell => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions
      })
    }];
    const { SearchBar } = Search;
    const options = {
      showTotal: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: products.length
      }]
    };
    const styles = {
      container: {
        border: '1px solid #ddd',
        padding: '5px',
        borderRadius: '5px',
      },
      items: {
        display: 'inline-block',
        padding: '2px',
        border: '1px solid blue',
        fontFamily: 'Helvetica, sans-serif',
        borderRadius: '5px',
        marginRight: '5px',
        cursor: 'pointer'
      },
      input: {
        outline: 'none',
        border: 'none',
        fontSize: '14px',
        fontFamily: 'Helvetica, sans-serif'
      },
    };

    let optionsCountry = COUNTRIES.map((data) =>
      <option
        key={data.value}
        value={data.name}
      >
        {data.name}
      </option>
    );

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />
        <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
          <Row>

            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
            <Modal
              show={this.state.modalConfirmShow}
              onHide={this.showOrHideConfirmModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Delete </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>All informations about selected item will be deleted. Are you sure?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.showOrHideConfirmModal}>Close</Button>
                <Button variant="danger" onClick={this.delete} >Delete</Button>
              </Modal.Footer>
            </Modal>

          </Row>
        </Container>

        <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
          <Row>
            <Col xs={2}>
              <Modal
                show={this.state.modalShow}
                onHide={this.showOrHideModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">Delete All of Selected Project Profile Logs?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>All logs of this project profile will be deleted. Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                  <Button variant="danger" onClick={this.deleteProjectProfile}>Delete</Button>
                </Modal.Footer>
              </Modal></Col>
            <Col xs={10}></Col>
          </Row>
        </Container>

        {/* <ToolkitProvider
          keyField="id"
          data={products}
          columns={columns}
          search
          bootstrap4
        >
          {
            props => (
              <div style={{ backgroundColor: "white", padding:"10px" }}>
              <Row>
                  <Col xs={3}><SearchBar {...props.searchProps} /></Col>
                  <Col xs={6}></Col>
                  <Col xs={3}><Button onClick={this.createNewProjectProfileButton} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                </Row>
                <BootstrapTable striped hover bordered keyField='id' data={products} columns={columns} filter={filterFactory()} pagination={paginationFactory(options)}
                  {...props.baseProps} />
              </div>
            )
          }
        </ToolkitProvider> */}

        <br />


        {/*<Table responsive striped bordered hover>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>S/N</th>
              <th>Actions</th>
              <th>Project Code</th>
              <th>Contract No</th>
              <th>Project's Full Name</th>
              <th>Project's Short Name</th>
              <th>Location of Project</th>
              <th>Contract Sign Date</th>
              <th>Kick Off Date</th>
              <th>End of Project</th>
              <th>...</th>
              <th>...</th>
              <th>...</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            {this.props.customerLogReducer.selectItemList.map((e, key) => (
              <tr key={e.id}>
                <td>{e.id}</td>
                <td>
                  <DropdownButton
                    size="sm"
                    variant="secondary"
                    title="..."
                    id={`dropdown-button-drop`}
                    onClick={() => this.setState({
                      projectProfileId: e.id
                    })}
                  >
                    <Dropdown.Item onClick={() => updateCustomer(customer.customerId)} eventKey="1">Edit</Dropdown.Item>
                    <Dropdown.Item onClick={this.updateProjectProfile} eventKey="1">Edit</Dropdown.Item>
                    <Dropdown.Item onClick={this.showOrHideModal} eventKey="2">Delete</Dropdown.Item>
                  </DropdownButton>
                </td>
                <td>{e.text}</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
            ))}
          </tbody>
        </Table>*/}

        <div className="col-md-12">
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Company's Project Information</Card.Header></Col>
                  <Col xs={2}>
                    <Toggle
                      defaultChecked={true}
                      icons={false}
                      id='project-profile-data-status' />
                    <label htmlFor='project-profile-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="projectInformationAccordion" eventKey="0" >
                <Card.Body>
                  <Form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Company's Project Code:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="projectCode" required value={this.state.projectCode}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Contract No:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="projectContractNo" required value={this.state.projectContractNo}
                          onChange={this.handleChange} />
                      </Col>
                      <Col sm="2">
                        <Upload
                          name="file"
                          showUploadList={true}
                          action={`${API_BASE}/CompanyProjects/upload`}
                          onChange={this.uploadContract}
                        >
                          <ButtonA>
                            <UploadOutlined />Upload Contract
                         </ButtonA>
                        </Upload>

                      </Col>
                      <Col sm="2">
                        {this.state.showContractPath !== null && this.state.showContractPath !== undefined && <ButtonA onClick={this.downloadContract} type="primary" shape="round" icon={<DownloadOutlined />} >
                          Download
                        </ButtonA>}
                      </Col>


                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Project's Full Name:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="projectFullName" required value={this.state.projectFullName}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Project's Short Name:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="projectShortName" required value={this.state.projectShortName}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Project's Client :
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control name="projectClient" required value={this.state.projectClient}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formGridCountry">
                      <Col sm="2">
                        <div style={{ textAlign: 'left' }}>
                          <Form.Label style={{ color: 'black' }}>Country</Form.Label>
                        </div>
                      </Col>
                      <Col sm="6">

                        <Form.Control name="projectCountry" value={this.state.projectCountry}
                          onChange={this.handleChange} as="select">
                          <option value="">
                            Select Country
                          </option>
                          {optionsCountry}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formGridLocation">
                      <Col sm="2">

                        <Form.Label style={{ color: 'black' }}  >Address</Form.Label>

                      </Col>
                      <Col sm="6">
                        <Form.Control type="textarea" name="projectAddress" value={this.state.projectAddress}
                          onChange={this.handleChange} />
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Contract Sign Date:
                      </Form.Label>
                      <Col sm="3">
                        <div>

                          <DatePicker
                            onChange={this.onChangeCSD}
                            value={this.state.contractSignDate}
                          />

                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        Kick Off Date:
                      </Form.Label>
                      <Col sm="3">
                        <div>

                          <DatePicker
                            onChange={this.onChangeKOD}
                            value={this.state.kickOffDate}
                          />

                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">
                      <Form.Label style={{ color: 'black' }} column sm="2">
                        End of Project:
                      </Form.Label>
                      <Col sm="3">
                        <div>

                          <DatePicker
                            onChange={this.onChangeEOP}
                            value={this.state.endOfProject}
                          />

                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="saveAbout">
                      <Form.Label style={{ color: 'black' }} column sm="2">

                      </Form.Label>

                      <Col sm="3">
                        <Button variant="info" onClick={this.saveProjectInfo} >Save</Button>
                      </Col>


                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Contact Info</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='contact-data-status' />
                    <label htmlFor='contact-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="contactAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={10}></Col>
                      <Col xs={2}><Button onClick={this.addContact} style={{ width: '100%' }} variant="info">Add New</Button></Col>
                    </Row>

                  </Container>
                  <DataTable style={{ marginTop: '3rem' }}
                    noHeader
                    columns={columnsContact(this.deleteContactModal, this.editContact)}
                    data={this.state.contactList}
                    sortIcon={sortIcon}
                    pagination
                    persistTableHead
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    // subHeader
                    // subHeaderComponent={
                    //     (
                    //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                    //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                    //             <Icon1 style={{ margin: '5px' }} color="action" />
                    //             <Icon2 style={{ margin: '5px' }} color="action" />
                    //             <Icon3 style={{ margin: '5px' }} color="action" />
                    //         </div>
                    //     )
                    // }
                    actions={actions}
                    onRowClicked={this.handleRowClicked}
                    onSelectedRowsChange={this.handleChangeRow}
                  />

                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={12}>

                        <Modal
                          show={this.state.modalContactShow}
                          onHide={this.showOrHideContactModal}
                          aria-labelledby="contained-modal-title-vcenter"
                          size="xl"
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Company Contact Detail</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>

                              <Form.Group as={Row} controlId="formGridLocation">
                                <Col sm="2">

                                  <Form.Label style={{ color: 'black' }}  >Location</Form.Label>

                                </Col>
                                <Col sm="6">
                                  <Form.Control name="location" value={this.state.location}
                                    onChange={this.handleChange} as="select">
                                    <option>Select</option>
                                    <option>Head Office</option>
                                    <option>Branche</option>
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formGridAddress">
                                <Col sm="2">
                                  <div style={{ textAlign: 'left' }}>
                                    <Form.Label style={{ color: 'black' }}  >Address</Form.Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <Form.Control as="textarea" rows="3" name="address" value={this.state.address}
                                    onChange={this.handleChange} />
                                </Col>

                              </Form.Group>
                              <Form.Group as={Row} controlId="formGridPhone">
                                <Col sm="2">
                                  <div style={{ textAlign: 'left' }}>
                                    <Form.Label style={{ color: 'black' }}>Phone</Form.Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="content-section implementation p-fluid">
                                    <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.phone} onChange={(e) => this.setState({ phone: e.value })}></Chips>
                                  </div>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formGridFax">
                                <Col sm="2">
                                  <div style={{ textAlign: 'left' }}>
                                    <Form.Label style={{ color: 'black' }}>Fax</Form.Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="content-section implementation p-fluid">
                                    <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.fax} onChange={(e) => this.setState({ fax: e.value })}></Chips>

                                  </div>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formGridWeb">
                                <Col sm="2">
                                  <div style={{ textAlign: 'left' }}>
                                    <Form.Label style={{ color: 'black' }}>Web</Form.Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <Form.Control value={this.state.web} onChange={(e) => this.setState({ web: e.target.value })}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formGridEmail">
                                <Col sm="2">
                                  <div style={{ textAlign: 'left' }}>
                                    <Form.Label style={{ color: 'black' }}>E-Mail</Form.Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="content-section implementation p-fluid">
                                    <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.email} onChange={(e) => this.setState({ email: e.value })}></Chips>

                                  </div>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row} controlId="formGridFax">
                                <Col sm="2">
                                  <div style={{ textAlign: 'left' }}>
                                    <Form.Label style={{ color: 'black' }}>Social Accounts</Form.Label>
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="content-section implementation p-fluid">
                                    <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.social} onChange={(e) => this.setState({ social: e.value })}></Chips>

                                  </div>
                                </Col>
                              </Form.Group>
                            </Form>

                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideContactModal}>Close</Button>
                            <Button variant="info" onClick={this.saveContact} >Save</Button>
                          </Modal.Footer>
                        </Modal></Col>


                    </Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>

              {/* <Accordion.Collapse id="contactAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={10}></Col>
                      <Col xs={2}><Button onClick={this.addContact} style={{ width: '100%' }} variant="info">Add New...</Button></Col>
                    </Row>
                    <br />
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>Location</th>
                          <th>Address</th>
                          <th>Phone</th>
                          <th>Fax</th>
                          <th>Web</th>
                          <th>E-mail</th>
                          <th>Social Accounts</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.itemsContacts.map((itemsContact, index) =>
                          <tr key={index}>
                            <td>{itemsContact.location}</td>
                            <td>{itemsContact.address}</td>
                            <td>{itemsContact.phone}</td>
                            <td>{itemsContact.fax}</td>
                            <td>{itemsContact.web}</td>
                            <td>{itemsContact.email}</td>
                            <td>{itemsContact.soccAcc}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <br />
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridLocation">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }} >Location</Form.Label>
                        </div>
                        <Form.Control name="location" value={this.state.location}
                          onChange={this.handleChange} as="select">
                          <option>Select</option>
                          <option>Add New</option>
                          <option>Head Office</option>
                          <option>Branche</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridAddress">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Address</Form.Label>
                        </div>
                        <Form.Control name="address" value={this.state.address}
                          onChange={this.handleChange} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridPhone">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Phone</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.phones.map((phone, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemP(i)}>
                                {phone}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputP}
                              onChange={this.handleInputChangeP}
                              onKeyDown={this.handleInputKeyDownP} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridFax">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Fax</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.faxes.map((fax, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemF(i)}>
                                {fax}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputF}
                              onChange={this.handleInputChangeF}
                              onKeyDown={this.handleInputKeyDownF} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridWeb">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Web</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.webs.map((web, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemW(i)}>
                                {web}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputW}
                              onChange={this.handleInputChangeW}
                              onKeyDown={this.handleInputKeyDownW} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>E-Mail</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.emails.map((email, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemE(i)}>
                                {email}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputE}
                              onChange={this.handleInputChangeE}
                              onKeyDown={this.handleInputKeyDownE} />
                          </ul>
                        </label>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridFax">
                        <div style={{ textAlign: 'center' }}>
                          <Form.Label style={{ color: 'black' }}>Social Accounts</Form.Label>
                        </div>
                        <label style={{ width: '100%' }}>
                          <ul style={styles.container}>
                            {this.state.socials.map((social, i) =>
                              <li key={i} style={styles.items} onClick={this.handleRemoveItemS(i)}>
                                {social}
                                <span>(x)</span>
                              </li>
                            )}
                            <input
                              style={styles.input}
                              value={this.state.inputS}
                              onChange={this.handleInputChangeS}
                              onKeyDown={this.handleInputKeyDownS} />
                          </ul>
                        </label>
                      </Form.Group>
                    </Form.Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse> */}
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Project Description</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='project-desc-data-status' />
                    <label htmlFor='project-desc-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="projectDescAccordion" eventKey="0">
                <Card.Body>
                  <Form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">

                      <Col sm="2">
                        <Upload
                          name="file"
                          showUploadList={true}
                          action={`${API_BASE}/CompanyProjects/upload`}
                          onChange={this.uploadProjectDescription}
                        >
                          <ButtonA>
                            <UploadOutlined /> Click to Upload
                         </ButtonA>
                        </Upload>
                       
                      </Col>

                      <Col sm="2">  {this.state.showProjectDescriptionPath !== null && this.state.showProjectDescriptionPath !== undefined && <ButtonA onClick={this.downloadProjectDescription} type="primary" shape="round" icon={<DownloadOutlined />} >
                          Download
                        </ButtonA>} </Col> 
                     
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextPassword">

                     
                      <Col sm="2">
                        <Button variant="info" onClick={this.saveProjectDescription} >Save</Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Organization</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='organization-data-status' />
                    <label htmlFor='organization-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="organization" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                    <Row>
                      <Col xs={12}>

                        { Boolean(this.state.orgChartNodes) && this.state.orgChartNodes.length>0 && <OrganizationChart value={this.state.orgChartNodes} selectionMode="single" selection={this.state.selectedNode} onSelectionChange={event => this.setState({ selectedNode: event.data })}></OrganizationChart>
}
                      </Col>

                    </Row>
                    <Row>
                      <Col xs={3}></Col>
                      <Col xs={6}>
                        <Link
                          to={{
                            pathname: "/Department"
                          }}
                        >     <Button style={{ width: '100%' }} variant="info"> Create Organization Chart</Button>
                        </Link>
                      </Col>
                      <Col xs={3}></Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row onClick={this.onEditClick}>
                  <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">About Product/Service</Card.Header></Col>
                  <Col xs={2}><Toggle
                    defaultChecked={false}
                    icons={false}
                    id='product-data-status' />
                    <label htmlFor='product-data-status'></label>
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="productAccordion" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={10}></Col>
                      <Col xs={2}><Button onClick={this.addProductsService} style={{ width: '100%' }} variant="info">Add New...</Button></Col>
                    </Row>
                  </Container>
                  <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>Product/Service</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.itemsProductServ.map(itemsProductSer => (
                        <tr key={itemsProductSer.productItemId}>
                          <td>{itemsProductSer.productServ}</td>
                          <td>{itemsProductSer.description}</td>
                          <td>{itemsProductSer.unit}</td>
                          <td>{itemsProductSer.price}&nbsp;{itemsProductSer.priceUnit}</td>
                          <td>{itemsProductSer.spec}</td>
                          <td>{itemsProductSer.photo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridProductServ">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Product/Service</Form.Label>
                      </div>
                      <Form.Control name="productServ" value={this.state.productServ}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Product</option>
                        <option>Service</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridRepDescription">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Description</Form.Label>
                      </div>
                      <Form.Control name="productDesc" value={this.state.productDesc}
                        onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridUnit">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Unit</Form.Label>
                      </div>
                      <Form.Control name="productUnit" value={this.state.productUnit}
                        onChange={this.handleChange} as="select">
                        <option>Select</option>
                        <option>Add New</option>
                        <option>Liter</option>
                        <option>Ton</option>
                        <option>kg</option>
                        <option>m3</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPrice">
                      <div style={{ textAlign: 'center' }}>
                        <Form.Label style={{ color: 'black' }} >Price</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control name="productPrice" value={this.state.productPrice}
                          onChange={this.handleChange} />
                        <Form.Control style={{ marginLeft: '10px' }} name="productPriceUnit" value={this.state.productPriceUnit}
                          onChange={this.handleChange} as="select" >
                          <option>SR</option>
                          <option>USD</option>
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
            <Row>
              <Button onClick={this.addProjectProfile} style={{ width: '100%' }} variant="info">{this.state.lastButton}</Button>
            </Row>
          </Container>
        </div>

      </div>


    )
  }
}

const mapStateToProps = ({ customerLogReducer }) => {
  return {
    customerLogReducer
  }
}

const mapDispatchToProps = {
  fetchCustomerLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfiles)