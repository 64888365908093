import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Toast } from 'primereact/toast';
import { Redirect } from "react-router-dom";

export class Logging extends Component {

    constructor() {
        super();
        this.state = {
            datatableValue: [],
            errorStatu: false
        };


    }

    componentDidMount = async () => {


        this.restartTable();

    }

    showSuccess(type, mssg, dtl) {
        this.toast.show({ severity: type, summary: mssg, detail: dtl });
    };


    restartTable = async () => {

        try {
            //const response= await axios.get(`${API_BASE}/ReqResLog/getAll`, config);
            const response = await handleRequest("GET", "/ReqResLog/getAll");
 

            if (response.data.length !== 0) {
                this.setState({
                    datatableValue: response.data,
                });
            }
        } catch (error) {
            this.setState({ errorStatu: true });
        }

    }

    brandTemplate(rowData, column) {

        const d = rowData.create_date;
        if (d != null) {
            var dt = new Date(d);
            return (''
                + dt.getDate().toString().padStart(2, '0') + '/'
                + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
                + dt.getFullYear().toString().padStart(4, '0') + ' '
                + dt.getHours().toString().padStart(2, '0') + ':'
                + dt.getMinutes().toString().padStart(2, '0') + ':'
                + dt.getSeconds().toString().padStart(2, '0'));
        } else return '';
    }



    render() {

        const { errorStatu } = this.state;
        if (errorStatu) {
            return (<div>   <Redirect to="/logout" />  </div>);
        }
        else
            return (<div>


                <div style={{ marginTop: '40px' }} className="content-section implementation">
                    <Toast ref={(el) => this.toast = el} />
                    <DataTable ref={(el) => this.dt = el} value={this.state.datatableValue} paginator={true} responsive={true}
                        rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-disciplines"
                        selectionMode="single" selection={this.state.selectedDiscipline} onSelectionChange={e => this.setState({ selectedDiscipline: e.value })}>
                        <Column field="id" style={{ textAlign: 'center' }} sortable header="S/N" />
                        <Column field="type" style={{ textAlign: 'center' }} filter sortable header="Type" />
                        <Column field="page" style={{ textAlign: 'center' }} filter sortable header="Page Name" />
                        <Column field="action" style={{ textAlign: 'center' }} header="Function" filter sortable />
                        <Column field="user.username" style={{ textAlign: 'center' }} header="User" filter sortable />
                        <Column field="connectIp" style={{ textAlign: 'center' }} header="IP" filter sortable />
                        <Column field="create_date" body={this.brandTemplate} style={{ textAlign: 'center' }} header="Date" filter sortable />
                    </DataTable>
                </div>

            </div>);
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Logging)