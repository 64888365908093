import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { Button as ReactButton, Col, Form as FormBoot, Row } from 'react-bootstrap';
import { Card, Select, Input } from 'antd';
import 'react-contexify/dist/ReactContexify.min.css';

const { Option } = Select;

export class UnitsConverterMND extends Component {
    constructor() {
        super();
        this.state = {
            unitTypeConverterId: null,
            unitTypesForConverter: [],
            unitsQuantity1: [],
            unitsQuantity2: [],
            value1: 0,
            value2: 0,
            value1Suffix: "",
            value2Suffix: "",
            quantity1: null,
            quantity2: null
        }
    }

    componentDidMount = async () => {
        let flagUnitTypes = [];
        const response = await handleRequest("GET", "/UnitType/getallByCompany");

        if (response.data.length === 0) {
            this.setState({
                unitList: [],
            });
        }

        if (response.data.length !== 0) {
            let flagData = response.data;

            for (let index = 0; index < flagData.length; index++) {
                flagUnitTypes.push({
                    id: response.data[index].id,
                    key: response.data[index].id,
                    parentUnitId: response.data[index].parentUnitId,
                    codeParentUnit: response.data[index].codeParentUnit,
                    unitTypes: response.data[index].unitTypes,
                    requestStatus: response.data[index].requestStatus
                });
            }

            this.setState({
                unitList: flagUnitTypes,
            });
        }

        this.getCodesUnitType();
    }

    goToUnits = () => {
        window.location.href = '/Units';
    }

    getCodesUnitType = async () => {
        const response = await handleRequest("GET", "/Code/type/unitTypeForUnits");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                unitTypesForConverter: response.data
            });
        } else {
            this.setState({
                unitTypesForConverter: []
            });
        }
    }

    onUnitTypeConverterChange = (value) => {
        let unitIndex = this.state.unitList.findIndex(p => p.parentUnitId === value);

        if (this.state.unitList[unitIndex] !== undefined) {
            this.setState({
                unitsQuantity1: this.state.unitList[unitIndex].unitTypes,
                unitsQuantity2: this.state.unitList[unitIndex].unitTypes
            });
        } else {
            this.setState({
                unitsQuantity1: [],
                unitsQuantity2: []
            });
        }

        this.setState({
            unitTypeConverterId: value,
            quantity1: null,
            quantity2: null,
            value1: "",
            value2: "",
            value1Suffix: "",
            value2Suffix: ""
        });
    }

    onChangeQuantity1 = (value) => {
        let unitIndex = this.state.unitsQuantity1.findIndex(p => p.id === value);

        if (unitIndex !== undefined && unitIndex !== null) {
            if (this.state.unitsQuantity1[unitIndex].subSuperscript) {
                if (this.state.unitsQuantity1[unitIndex].scriptType === "superscript")
                    this.setState({
                        value1Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity1[unitIndex].abbreviation}<sup>{this.state.unitsQuantity1[unitIndex].script}</sup>
                        </p>,
                    });
                else
                    this.setState({
                        value1Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity1[unitIndex].abbreviation}<sub>{this.state.unitsQuantity1[unitIndex].script}</sub>
                        </p>,
                    });
            } else
                this.setState({
                    value1Suffix: <p style={{ marginBottom: "0px" }}>{this.state.unitsQuantity1[unitIndex].abbreviation}</p>,
                });
        }

        this.setState({
            quantity1: value
        },
            () => this.calculateConvert(2)
        );
    }

    onChangeQuantity2 = (value) => {
        let unitIndex = this.state.unitsQuantity2.findIndex(p => p.id === value);

        if (unitIndex !== undefined && unitIndex !== null) {
            if (this.state.unitsQuantity2[unitIndex].subSuperscript) {
                if (this.state.unitsQuantity2[unitIndex].scriptType === "superscript")
                    this.setState({
                        value2Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity2[unitIndex].abbreviation}<sup>{this.state.unitsQuantity2[unitIndex].script}</sup>
                        </p>,
                    });
                else
                    this.setState({
                        value2Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity2[unitIndex].abbreviation}<sub>{this.state.unitsQuantity2[unitIndex].script}</sub>
                        </p>,
                    });
            } else
                this.setState({
                    value2Suffix: <p style={{ marginBottom: "0px" }}>{this.state.unitsQuantity2[unitIndex].abbreviation}</p>,
                });
        }

        this.setState({
            quantity2: value
        },
            () => this.calculateConvert(1)
        );
    }

    handleChangeValue1 = (e) => {
        this.setState({
            value1: e.target.value
        },
            () => this.calculateConvert(1)
        );
    }

    handleChangeValue2 = (e) => {
        this.setState({
            value2: e.target.value
        },
            () => this.calculateConvert(2)
        );
    }

    calculateConvert = (changedValue) => {
        let calculatedValue1 = 0;
        let calculatedValue2 = 0;

        let unitIndex1 = this.state.unitsQuantity1.findIndex(p => p.id === this.state.quantity1);
        let unitIndex2 = this.state.unitsQuantity2.findIndex(p => p.id === this.state.quantity2);

        if (changedValue === 1 && this.state.quantity1 !== null && this.state.quantity2 !== null &&
            unitIndex1 !== undefined && unitIndex1 !== null && unitIndex1 !== -1 &&
            unitIndex2 !== undefined && unitIndex2 !== null && unitIndex2 !== -1) {

            calculatedValue2 = this.state.value1 * (Number(this.state.unitsQuantity2[unitIndex2].coefficent) / Number(this.state.unitsQuantity1[unitIndex1].coefficent));

            this.setState({
                value2: (Math.round(calculatedValue2 * 100) / 100)
            });
        } else if (changedValue === 2 && this.state.quantity1 !== null && this.state.quantity2 !== null &&
            unitIndex1 !== undefined && unitIndex1 !== null && unitIndex1 !== -1 &&
            unitIndex2 !== undefined && unitIndex2 !== null && unitIndex2 !== -1) {

            calculatedValue1 = this.state.value2 * (Number(this.state.unitsQuantity1[unitIndex1].coefficent) / Number(this.state.unitsQuantity2[unitIndex2].coefficent));

            this.setState({
                value1: (Math.round(calculatedValue1 * 100) / 100)
            });
        } else {
            this.setState({
                value1: 0,
                value2: 0
            });
        }
    }

    render() {
        return (
            <div>

                <Card style={{ width: '800px', marginBottom: '1rem' }}>

                    <div style={{ padding: "4px 16px 4px 16px" }}>
                        <Row>
                            <Col xs={6}></Col>
                            <Col xs={6}>
                                <ReactButton id="UnitCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.goToUnits}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                            </Col>
                        </Row>
                    </div>

                    <FormBoot.Group as={Row} style={{ marginBottom: '1rem' }}>
                        <Col sm="12">
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Measurement"
                                value={this.state.unitTypeConverterId}
                                onChange={this.onUnitTypeConverterChange}
                            >
                                {this.state.unitTypesForConverter.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </FormBoot.Group>

                    <FormBoot.Group as={Row} style={{ marginBottom: '1rem' }}>
                        <Col sm="6">
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Quantity"
                                value={this.state.quantity1}
                                onChange={this.onChangeQuantity1}
                            >
                                {this.state.unitsQuantity1.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col sm="6">
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Quantity"
                                value={this.state.quantity2}
                                onChange={this.onChangeQuantity2}
                            >
                                {this.state.unitsQuantity2.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </FormBoot.Group>

                    <FormBoot.Group as={Row} style={{ marginBottom: '1rem' }}>
                        <Col sm="6">
                            <Input suffix={this.state.value1Suffix} style={{ width: "100%" }} value={this.state.value1} onChange={this.handleChangeValue1} />
                        </Col>
                        <Col sm="6">
                            <Input suffix={this.state.value2Suffix} style={{ width: "100%" }} value={this.state.value2} onChange={this.handleChangeValue2} />
                        </Col>
                    </FormBoot.Group>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitsConverterMND)  