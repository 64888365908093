
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, Table, InputNumber } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { useContext, useState, useEffect, useRef } from 'react';
import moment from 'moment'

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && record.childDurum) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing && record.childDurum ? (
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} min={0} max={record.weight} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
        record.itemGroup ?
          <div
            className="editable-cell-value-wrap"
            style={{
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "larger"
            }}
            onClick={toggleEdit}
          >
            {children}
          </div> :
          record.childDurum ?
            <div
              className="editable-cell-value-wrap"
              style={{
                fontWeight: "lighter",
                textAlign: "left",
                fontSize: "smaller",
                color: "red",
                // border: '1px solid red',
              }}
              onClick={toggleEdit}
            >
              {children}
            </div> :
            <div
              className="editable-cell-value-wrap"
              style={{
                fontWeight: "lighter",
                textAlign: "left",
                fontSize: "smaller",
              }}
              onClick={toggleEdit}
            >
              {children}
            </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.value, lineage: node.data.lineage });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

const { Option } = Select;

class VendorScoring extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      vendorScoringTable: [],
      vendors: [],
      rfqNos: [],
      selectedVendor: "Vendor",
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDate = await handleRequest("GET", "/VendorEvaluation/getDates");
    var date = null;
    if (responseDate.data.length !== 0) {
      date = responseDate.data[0].date;
    }
    await this.setState({
      date: date,
      currentDate: date,
    });

    await this.getDynamicMenu();
    this.setStandardizations();
    this.restartTable();
    this.getTableProperties();
  }

  restartTable = async () => {
    console.log("DATE2", this.state.date);
    var newObj = {
      date: this.state.date
    }
    if (this.state.date != null && this.state.date != undefined && this.state.date != 0 && this.state.date != "") {
      // const response = await handleRequest("POST", "/VendorEvaluation/getallByCompanyActive", newObj);
      // if (response.data != null && response.data != undefined) {
      //   {
      //     if (response.data.data.length === 0) {
      //       this.setState({
      //         vendorScoringTable: [],
      //       });
      //     }
      //     if (response.data.data.length !== 0) {
      //       this.setState({
      //         vendorScoringTable: response.data.data,
      //         totalWeight: response.data.totalWeight,
      //         totalPercent: response.data.totalPercent,
      //       });
      //     }
      //   }
      // } else {
      //   this.setState({
      //     vendorScoringTable: [],
      //   });
      // }
      this.getRfqScoring();
      //this.getVendorLog();
      this.getRFQNo();
    } else {
      this.setState({
        vendorScoringTable: [],
      });
    }
  }

  getRfqScoring = async () => {
    const response = await handleRequest("GET", "/VendorScoring/getVendorRequestForQuotation");
    console.log("RRR", response.data);
    if (response.data.length === 0) {
      this.setState({
        rfqTable: [],
      });
    }
    if (response.data.length !== 0) {
      this.setState({
        rfqTable: response.data,
      });
    }
  }

  getVendorLog = async () => {
    var newObj = {
      date: this.state.date,
    }
    const response = await handleRequest("POST", "/VendorScoring/getVendorLog", newObj);
    if (response.data.length === 0) {
      this.setState({
        vendors: [],
      });
    }
    if (response.data.length !== 0) {
      this.setState({
        vendors: response.data,
      });
    }
  }

  getRfqVendorLog = async (rfqId, date) => {
    if (date != null && date != undefined && date != 0 && date != "") {
      var newObj = {
        rfqId: rfqId,
        date: date,
      }

      console.log("BBB", newObj);
      const response = await handleRequest("POST", "/VendorScoring/getRfqVendorLog", newObj);
      if (response.data.length === 0) {
        this.setState({
          vendors: [],
          vendor: "",
          selectedVendor: "Vendor",
        });
      }
      if (response.data.length !== 0) {
        this.setState({
          vendors: response.data,
          vendor: "",
          selectedVendor: "Vendor",
        });
      }
    }
  }

  getRFQNo = async () => {
    if (this.state.date != null && this.state.date != undefined && this.state.date != 0 && this.state.date != "") {
      var newObj = {
        // date: this.state.date
      }
      const response = await handleRequest("POST", "/VendorScoring/getRequestForQuotation", newObj);
      if (response.data.length === 0) {
        this.setState({
          rfqNos: [],
        });
      }
      if (response.data.length !== 0) {
        this.setState({
          rfqNos: response.data,
        });
      }
    }
  }

  setStandardizations = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "VendorScoring" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "VendorScoring",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "VendorScoring");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
  }

  getDynamicMenu = async () => {
    try {
      const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
      if (responseTree.data.length !== 0) {
        var element = responseTree.data.treeTable;

        for (let index = 0; index < element.length; index++) {
          var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

          if (result !== null)
            break;
        }

        this.setState({
          formId: result.data.id
        });

        this.getUserSettings();
      } else {
        this.setState({
          formId: null
        });
      }
    } catch (error) {
    }
  }

  searchTree = (element, matchingTitle) => {
    if (element.data.page == matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  }

  getUserSettings = async () => {
    const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
    if (responseUserSettings.data.length !== 0) {
      this.setState({
        userSettings: responseUserSettings.data,
        howToUseSetting: responseUserSettings.data.howToUseSetting,
        whatIsSetting: responseUserSettings.data.whatIsSetting
      });
    } else {
      this.setState({

        howToUseSetting: false,
        whatIsSetting: false
      });
    }
  }

  getTableProperties = async () => {
    this.vendorScoring = JSON.parse(localStorage.getItem('vendorScoring'));
    if (this.vendorScoring != null && this.vendorScoring != undefined && this.vendorScoring != "") {

      await this.setState({
        bordered: this.vendorScoring.bordered,
        title: this.vendorScoring.titleDrm == true ? title : undefined,
        titleDrm: this.vendorScoring.titleDrm,
        showHeader: this.vendorScoring.showHeader,
        footer: this.vendorScoring.footerDrm == true ? footer : undefined,
        footerDrm: this.vendorScoring.footerDrm,
        expandable: this.vendorScoring.expandableDrm == true ? expandable : {},
        expandableDrm: this.vendorScoring.expandableDrm,
        rowSelection: this.vendorScoring.rowSelection,
        ellipsis: this.vendorScoring.ellipsis,
        tableLayout: this.vendorScoring.tableLayout,
        size: this.vendorScoring.size,
        top: this.vendorScoring.top,
        bottom: this.vendorScoring.bottom,
        editBgColor: this.vendorScoring.editBgColor,
        deleteBgColor: this.vendorScoring.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.vendorScoring.yScroll,
        xScroll: this.vendorScoring.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.vendorScoringTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.vendorScoringTable.length, showQuickJumper: true,
          position: [this.vendorScoring.top, this.vendorScoring.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedVendorScoring: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalEvaluationMessage = () => {
    this.setState({
      modalEvaluationMessage: !this.state.modalEvaluationMessage
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  handleChangeVendor = (e) => {
    var selectedVendor = "Vendor";
    var obj = this.state.vendors.find(p => p.id == e);
    if (obj != null && obj != undefined) {
      selectedVendor = obj.fullName;
    }
    this.setState({
      vendor: e,
      selectedVendor: selectedVendor,
    });
  };

  handleChangeRFQNo = async (e) => {
    var rfq = this.state.rfqNos.find(p => p.id == e);
    var date = null;
    if (Boolean(rfq) && Boolean(rfq.evaluationDate)) {
      date = rfq.evaluationDate;
    }
    console.log("currentDate", this.state.currentDate);
    console.log(rfq);
    console.log(this.state.rfqTable);

    //Popup 
    if (this.state.currentDate > date) {
      var newObj = {
        rfqId: e
      }
      const response = await handleRequest("POST", "/VendorScoring/getResult", newObj);
      console.log("XXX", response.data);
      if (response.data != null && response.data != undefined) {
        if (response.data.length !== 0) {
          this.setState({
            modalEvaluationMessage: true,
            rfqEvaluationDate: date,
          });
        }else{
          var newObj = {
            date: this.state.currentDate
          }
          await this.getVendorScoring(newObj);
        }
      } else {
        this.showWarning();
      }
    } else {
      var newObj = {
        date: this.state.currentDate
      }
      await this.getVendorScoring(newObj);
    }
    this.setState({
      rfqId: e,
      date: this.state.currentDate,
    });
    this.getRfqVendorLog(e, this.state.currentDate);
  };

  modalEvaluationTemplateYes = async () => {
    console.log("XXX", this.state.currentDate);
    var date = this.state.currentDate;
    var newObj = {
      date: date
    }
    if (date != null && date != undefined && date != 0 && date != "") {
      const response = await handleRequest("POST", "/VendorEvaluation/getallByCompanyActive", newObj);
      if (response.data != null && response.data != undefined) {
        {
          if (response.data.data.length === 0) {
            this.setState({
              vendorScoringTable: [],
            });
          }
          if (response.data.data.length !== 0) {
            this.setState({
              vendorScoringTable: response.data.data,
              totalWeight: response.data.totalWeight,
              totalPercent: response.data.totalPercent,
            });
          }
        }
      } else {
        this.setState({
          vendorScoringTable: [],
        });
      }
      //this.getRfqScoring();
      //this.getVendorLog();
      //this.getRFQNo();

      this.setState({
        date: date,
      });

      this.updateEvaluationDate(true);

    } else {
      this.showWarning();
      this.setState({
        vendorScoringTable: [],
      });
    }
    this.setState({
      modalEvaluationMessage: false,
    });
  }

  modalEvaluationTemplateNo = async () => {
    console.log("XXX", this.state.rfqEvaluationDate);
    var date = this.state.rfqEvaluationDate;
    var newObj = {
      date: date
    }
    if (date != null && date != undefined && date != 0 && date != "") {
      const response = await handleRequest("POST", "/VendorEvaluation/getallByCompanyActive", newObj);
      if (response.data != null && response.data != undefined) {
        {
          if (response.data.data.length === 0) {
            this.setState({
              vendorScoringTable: [],
            });
          }
          if (response.data.data.length !== 0) {
            this.setState({
              vendorScoringTable: response.data.data,
              totalWeight: response.data.totalWeight,
              totalPercent: response.data.totalPercent,
            });
          }
        }
      } else {
        this.setState({
          vendorScoringTable: [],
        });
      }
      //this.getRfqScoring();
      //this.getVendorLog();
      //this.getRFQNo();

      this.setState({
        date: date,
      });

      this.updateEvaluationDate(false);

    } else {
      this.showWarning();
      this.setState({
        vendorScoringTable: [],
      });
    }
    this.setState({
      modalEvaluationMessage: false,
    });
  }

  updateEvaluationDate = async (evaluationDateCheck) => {
    const updateEvaluationDate = {
      id: this.state.rfqId,
      evaluationDateCheck: evaluationDateCheck
    }
    var response = await handleRequest("POST", "/RequestForQuotation/updateEvaluationDate", updateEvaluationDate);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        var rfq = this.state.rfqNos.find(p => p.id == this.state.rfqId);
        if (Boolean(rfq) && rfq != null && rfq != undefined) {
          rfq.evaluationDateCheck = evaluationDateCheck;
        }
      }
    }
  }

  saveVendorScoring = async () => {
    if (this.state.vendor != null && this.state.vendor != undefined && this.state.vendor != "" && this.state.vendor != 0 &&
      this.state.date != null && this.state.date != undefined && this.state.date != "" && this.state.date != 0) {

      if (this.state.rfqId != null && this.state.rfqId != undefined && this.state.rfqId != "" && this.state.rfqId != 0) {
        var knt = "";
        this.state.vendorScoringTable.forEach(element => {
          element.vendor = this.state.vendor;
          element.date = this.state.date;
          element.rfqId = this.state.rfqId;
          if (element.vendorWeight != null && element.vendorWeight != undefined && element.vendorWeight != "" && element.vendorWeight != 0) {
            if (!Number(element.vendorWeight)) {
              knt = "Please enter numerical values !";
              return;
            }
            if (element.weight < parseInt(element.vendorWeight)) {
              knt = "Vendor value cannot be greater than Weight value !";
              return;
            }
          }
        });

        if (knt == "") {
          var response = await handleRequest("POST", "/VendorScoring/saveAll", this.state.vendorScoringTable);
          if (response.data) {
            if (response.data === "ERROR") {
              this.showError();
            } else if (response.data === "SUCCESS") {
              this.showSuccess();
              this.restartTable();
              this.setState({
                vendor: "",
                rfqId: "",
              });
            }
          }
        } else {
          this.showWarning(knt);
        }
      } else {
        this.showWarning("Please select your RFQ No!");
      }
    } else {
      this.showWarning("Please select your Vendor!");
    }
  }

  handleSave = (row) => {
    const newData = [...this.state.vendorScoringTable];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      vendorScoringTable: newData,
    });
  };

  async getVendorScoring(newObj) {
    if (Boolean(this.state.currentDate) && this.state.currentDate != 0 && this.state.currentDate != "") {
      const response = await handleRequest("POST", "/VendorEvaluation/getallByCompanyActive", newObj);
      console.log("XXX", response.data);
      if (response.data != null && response.data != undefined) {
        {
          if (response.data.data.length === 0) {
            this.setState({
              vendorScoringTable: [],
            });
          }
          if (response.data.data.length !== 0) {
            this.setState({
              vendorScoringTable: response.data.data,
              totalWeight: response.data.totalWeight,
              totalPercent: response.data.totalPercent,
            });
          }
        }
      }
      else {
        this.setState({
          vendorScoringTable: [],
        });
      }
      //this.getRfqScoring();
      //this.getVendorLog();
      //this.getRFQNo();
    }
    else {
      this.showWarning();
      this.setState({
        vendorScoringTable: [],
      });
    }
  }

  render() {

    const columnsRfq = [
      {
        key: "key",
        dataIndex: "requestForQuotation",
        title: <FormattedMessage id="VendorScoringRfqNo" defaultMessage="RFQ No" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return record.rfqNo;
          }
          return "";
        }
      },
      {
        key: "key",
        dataIndex: "requestForQuotation",
        title: <FormattedMessage id="VendorScoringItemDescription" defaultMessage="Item Description" />,
        render: (record) => {
          if (record != null && record != undefined && record.projectTakeOffList != null && record.projectTakeOffList != undefined) {
            return record.projectTakeOffList.description;
          }
          return "";
        }
      },
      {
        key: "key",
        dataIndex: "requestForQuotation",
        title: <FormattedMessage id="VendorScoringDate" defaultMessage="Date" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return moment(record.createDate).format("DD/MM/YYYY HH:mm:ss");
          }
          return "";
        }
      },
      {
        key: "key",
        dataIndex: "requestForQuotation",
        title: <FormattedMessage id="VendorScoringQuantity" defaultMessage="Quantity" />,
        render: (record) => {
          if (record != null && record != undefined && record.projectTakeOffLists[0] != null && record.projectTakeOffLists[0] != undefined) {
            return record.projectTakeOffLists[0].quantity;
          }
          return "";
        }
      },
      {
        key: "key",
        dataIndex: "requestForQuotation",
        title: <FormattedMessage id="VendorScoringClosingDate" defaultMessage="Closing Date" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return record.closingDate;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="VendorScoringStatus" defaultMessage="Status" />,
      },

    ];

    const tableColumnsRfq = columnsRfq.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsRfq[0].fixed = true;
      tableColumnsRfq[tableColumnsRfq.length - 1].fixed = 'right';
    }

    const columnsVendor = [
      {
        key: "key",
        title: <FormattedMessage id="VendorScoringVendorNo" defaultMessage="Vendor No" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return record.vendorNo;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="VendorScoringVendor" defaultMessage="Vendor" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return record.vendor;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="VendorScoringDateOfReply" defaultMessage="Date of Reply" />,
        render: (record) => {
          if (record != null && record != undefined) {
            return record.date;
          }
          return "";
        }
      },
    ];

    const tableColumnsVendor = columnsVendor.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsVendor[0].fixed = true;
      tableColumnsVendor[tableColumnsVendor.length - 1].fixed = 'right';
    }

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const tableColumns = [
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("vendorScoring")} icon="pi pi-cog"></PrimeButton>,
        render: (data) => {
          if (data.itemGroup) {
            return (
              <React.Fragment>
                <div style={{
                  fontWeight: "bold",
                }}>
                  {data.itemNo}
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div style={{
                  fontWeight: "lighter",
                }}>
                  {data.itemNo}
                </div>
              </React.Fragment>
            );
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="VendorScoringItem" defaultMessage="Item" />,
        render: (data) => {
          if (data.itemGroup) {
            return (
              <React.Fragment>
                <div style={{
                  fontWeight: "bold",
                }}>
                  {data.description}
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div style={{
                  fontWeight: "lighter",
                }}>
                  {data.description}
                </div>
              </React.Fragment>
            );
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="VendorScoringWeight" defaultMessage="Weight" />,
        render: (data) => {
          if (data.itemGroup) {
            return (
              <React.Fragment>
                <div style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "larger"
                }}>
                  {data.weight}
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div style={{
                  fontWeight: "lighter",
                  textAlign: "left",
                  fontSize: "smaller"
                }}>
                  {data.weight}
                </div>
              </React.Fragment>
            );
          }
        }
      },
      {
        key: "key",
        title: this.state.selectedVendor,
        dataIndex: "vendorWeight",
        editable: true,
      },
    ];

    const columns = tableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    const options = {
      showTotal: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }, {
        text: 'All', value: this.state.vendorScoringTable.length
      }]
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {/* {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&  */}
        <MenuProvider id="menu_id">
          <div>
            <Row>
              <Col sm="1">
                <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>
              </Col>
              <Col sm="10">
              </Col>
              <Col sm="1" style={{ textAlign: "right" }}>
                {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>
                }
              </Col>
            </Row>
          </div>
          <br />
          <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
            <FormBoot.Label style={{ color: 'black' }} column sm="1">
              <FormattedMessage id="VendorScoringRFQNo" defaultMessage="RFQ no" />
            </FormBoot.Label>
            <Col sm="4">
              <Select name="RFQ no"
                style={{ width: '100%' }}
                value={this.state.rfqId}
                onChange={this.handleChangeRFQNo}>

                {this.state.rfqNos.map(m => (
                  (m.projectTakeOffList != null && m.projectTakeOffList != undefined) ?
                    <Option key={m.id} value={m.id}>{m.rfqNo + ", " + m.projectTakeOffList.description}</Option>
                    :
                    <Option key={m.id} value={m.id}>{m.rfqNo}</Option>
                ))}
              </Select>
            </Col>
            <Col sm="2">
            </Col>
            <FormBoot.Label style={{ color: 'black' }} column sm="1">
              <FormattedMessage id="VendorScoringVendor" defaultMessage="Vendor" />
            </FormBoot.Label>
            <Col sm="4">
              <Select name="Vendor"
                style={{ width: '100%' }}
                value={this.state.vendor}
                onChange={this.handleChangeVendor}>

                {this.state.vendors.map(m => (
                  <Option key={m.id} value={m.id}>{m.id + ", " + m.fullName}</Option>
                ))}
              </Select>
            </Col>
          </FormBoot.Group>
          <br />
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateVendorScoring : this.saveVendorScoring}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Table
                  state={this.state}
                  dataSource={this.state.vendorScoringTable}
                  pagination={false}
                  bordered={true}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  columns={columns}
                />
              </div>
            </div>
            <br />

            {this.state.vendorScoringTable.length > 0 &&
              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                </Col>
                <FormBoot.Label style={{ color: 'black' }} column sm="2">
                  <FormattedMessage id="VendorScoringTotalScore" defaultMessage="Total Score" />
                </FormBoot.Label>
                <Col sm="2">
                  <Input id="VendorScoringTotalWeight" readOnly={true} value={this.state.totalWeight} />
                </Col>
                <Col sm="2">
                  <Input id="VendorScoringTotalPercent" readOnly={true} value={this.state.totalPercent} />
                </Col>
              </FormBoot.Group>}

            <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="3">
              </Col>
              <Col sm="6">
                <Button id="VendorScoringSaveCheckButton"
                  style={{ width: '100%' }} onClick={() => this.saveVendorScoring()}
                  variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
              </Col>
              <Col sm="3">
              </Col>
            </FormBoot.Group>
            <br /><br />
            <div className="content-section implementation">
              <Table columns={tableColumnsRfq} dataSource={this.state.rfqTable} pagination={this.state.pagination}
                responsive striped bordered hover
                loading={this.state.loading}
                style={{ padding: "4px 1rem 4px 4px" }}
                expandable={{
                  expandedRowRender: record => {
                    if (record.vendorScoringSubs != null && record.vendorScoringSubs != undefined && record.vendorScoringSubs.length > 0) {
                      return <Table columns={tableColumnsVendor} dataSource={record.vendorScoringSubs} pagination={false} responsive striped bordered hover loading={this.state.loading}
                        style={{ padding: "4px 1rem 4px 4px" }}
                      />;
                    }
                  }
                  , rowExpandable: record => (record.vendorScoringSubs != null && record.vendorScoringSubs != undefined) == true ? true : false,
                }}
              />
            </div>

          </Form>
       </MenuProvider>
        {/* }     */}
        {!this.state.whatIsSetting && <Whatis  ></Whatis>
        }

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteVendorScoringOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalEvaluationMessage}
          onHide={this.showOrHideModalEvaluationMessage}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="The evaluation template has been updated on " /> {moment(this.state.currentDate).format("DD/MM/YYYY HH:mm:ss")}
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="Do you want to use new templates evaluation criterias ?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" onClick={this.modalEvaluationTemplateNo}>
              <FormattedMessage id="GeneralTextNo" defaultMessage="No" />
            </Button>
            <Button variant="danger" onClick={this.modalEvaluationTemplateYes}>
              <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(VendorScoring)