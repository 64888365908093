/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomDocumentedInfo from '../settings/ForComponents/CustomDocumentedInfo';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import DocumentedCreator from './DocumentedCreator';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "DocumentedInfoLog";

export class DocumentedInfoLog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            sourceOfDocument: null,
            originalCodeOfDocument: null,
            documentDescription: null,
            issueDate: null,
            revNo: null,
            typeOfDocumentId: null,
            companyDocumentCode: null,
            departmentId: null,
            documentPath: null,
            typeOfDocumentSelectItems: [],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            redirect: null,

            formId: 0
        };


    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillTypesOfDocuments();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            sourceOfDocument: this.state.sourceOfDocument,
            originalCodeOfDocument: this.state.originalCodeOfDocument,
            documentDescription: this.state.documentDescription,
            issueDate: this.state.issueDate,
            revNo: this.state.revNo,
            typeOfDocumentId: this.state.typeOfDocumentId,
            companyDocumentCode: this.state.companyDocumentCode,
            departmentId: this.state.departmentId,
            documentPath: this.state.filePath
        };


        if (obj.id === null)
            await handleRequest("POST", "/DocumentedDocLog/add", obj);
        else
            await handleRequest("POST", "/DocumentedDocLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            sourceOfDocument: row.sourceOfDocument,
            originalCodeOfDocument: row.originalCodeOfDocument,
            documentDescription: row.documentDescription,
            issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
            revNo: row.revNo,
            typeOfDocumentId: row.typeOfDocumentId,
            companyDocumentCode: row.companyDocumentCode,
            departmentId: row.departmentId,
            documentPath: row.documentPath,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            sourceOfDocument: row.sourceOfDocument,
            originalCodeOfDocument: row.originalCodeOfDocument,
            documentDescription: row.documentDescription,
            issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
            revNo: row.revNo,
            typeOfDocumentId: row.typeOfDocumentId,
            companyDocumentCode: row.companyDocumentCode,
            departmentId: row.departmentId,
            documentPath: row.documentPath,
            filePath: row.documentPath


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/DocumentedDocLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomDocumentedInfo row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onTemplate={this.template.bind(this)} />
        </React.Fragment>;
    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/DocumentedDocLog/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            sourceOfDocument: null,
            originalCodeOfDocument: null,
            documentDescription: null,
            issueDate: null,
            revNo: null,
            typeOfDocumentId: null,
            companyDocumentCode: null,
            departmentId: null,
            documentPath: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            sourceOfDocument: null,
            originalCodeOfDocument: null,
            documentDescription: null,
            issueDate: null,
            revNo: null,
            typeOfDocumentId: null,
            companyDocumentCode: null,
            departmentId: null,
            documentPath: null,



        });
    }


    cancel = () => {

        this.resetInputs();
    }

    
    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    addCodetypeOfDocumentId = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "typeOfdocument"
            }

            var response = await handleRequest("POST", "/Code/add", newItem);
            if (response.type === "ERROR") {
                this.error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillTypesOfDocuments();
            }
        } else {
            this.showError("Please enter department");
        }
    };

    fillTypesOfDocuments = async () => {
        const response = await handleRequest("GET", "/Code/type/typeOfdocument");
        if (Boolean(response.data)) {
            this.setState({
                typeOfDocumentSelectItems: response.data
            });
        }
    }

    downloadFile = async (url) => {
        window.location.href = url;
    }

    template = (row) => {
        console.log("object;");
        this.setState({
            redirect: row.id
        })


    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        if (this.state.redirect) {
            return <DocumentedCreator documentedInfoId={this.state.redirect} type="Document" ></DocumentedCreator>
        }
 
        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "sourceOfDocument").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="sourceOfDocument" defaultMessage="Source Of Document" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="sourceOfDocument"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "sourceOfDocument")) ? this.state.dynamicInputs.find(p => p.inputKey === "sourceOfDocument").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "sourceOfDocument")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "sourceOfDocument").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Source Of Document" style={{ width: "100%" }} id="sourceOfDocument" value={this.state.sourceOfDocument} onChange={(e) => { this.setState({ sourceOfDocument: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "codeOfDocument").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="codeOfDocument" defaultMessage="Original Code Of Document" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="originalCodeOfDocument"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "codeOfDocument")) ? this.state.dynamicInputs.find(p => p.inputKey === "codeOfDocument").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "codeOfDocument")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "codeOfDocument").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Original Code Of Document" style={{ width: "100%" }} id="codeOfDocument" value={this.state.originalCodeOfDocument} onChange={(e) => { this.setState({ originalCodeOfDocument: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "documentDescription").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="documentDescription" defaultMessage="Document Description" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="documentDescription"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "documentDescription")) ? this.state.dynamicInputs.find(p => p.inputKey === "documentDescription").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "documentDescription")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "documentDescription").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Document Description" style={{ width: "100%" }} id="documentDescription" value={this.state.documentDescription} onChange={(e) => { this.setState({ documentDescription: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "issueDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="issueDate" defaultMessage="Issue Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="issueDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "issueDate")) ? this.state.dynamicInputs.find(p => p.inputKey === "issueDate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "issueDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "issueDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="issueDate" format="DD-MM-YYYY" value={this.state.issueDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                issueDate: date
                                                            });
                                                            this.setState({
                                                                issueDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "revNo").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="revNo" defaultMessage="Rev. No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="revNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "revNo")) ? this.state.dynamicInputs.find(p => p.inputKey === "revNo").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "revNo")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "revNo").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="revNo" placeholder="Rev. No"
                                                            min={0} value={this.state.revNo} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    revNo: value
                                                                });
                                                                this.setState({ revNo: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "typeOfDocument").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="typeOfDocument" defaultMessage="Type Of Document" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="typeOfDocumentId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "typeOfDocument")) ? this.state.dynamicInputs.find(p => p.inputKey === "typeOfDocument").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "typeOfDocument")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "typeOfDocument").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Type Of Document"
                                                            value={this.state.typeOfDocumentId} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    typeOfDocumentId: value
                                                                });
                                                                this.setState({
                                                                    typeOfDocumentId: value
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodetypeOfDocumentId}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.typeOfDocumentSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "companyDocumentCode").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="companyDocumentCode" defaultMessage="Company Document Code" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="companyDocumentCode"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "companyDocumentCode")) ? this.state.dynamicInputs.find(p => p.inputKey === "companyDocumentCode").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "companyDocumentCode")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "companyDocumentCode").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Company Document Code" style={{ width: "100%" }} id="companyDocumentCode" value={this.state.companyDocumentCode} onChange={(e) => { this.setState({ companyDocumentCode: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "custodianDepartment").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="custodianDepartment" defaultMessage="Custodian Department" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="departmentId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "custodianDepartment")) ? this.state.dynamicInputs.find(p => p.inputKey === "custodianDepartment").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "custodianDepartment")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "custodianDepartment").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Custodian Department" id="custodianDepartment" value={this.state.departmentId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                departmentId: value
                                                            });
                                                            this.setState({ departmentId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option> </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "text").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="text" defaultMessage="Text" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="documentPath"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "text")) ? this.state.dynamicInputs.find(p => p.inputKey === "text").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "text")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "text").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Upload {...props} id="text"
                                                            showUploadList={true}
                                                            fileList={this.state.fileList}
                                                            onChange={({ fileList }) => {
                                                                if (fileList.length == 1) {
                                                                    var filePath = "";
                                                                    if (Boolean(fileList[0].response)) {
                                                                        filePath = fileList[0].response.url;
                                                                        this.showSuccess("file uploaded successfully");
                                                                        // fileName: info.file.name, 
                                                                    }
                                                                    this.setState({
                                                                        fileList: fileList,
                                                                        filePath: filePath,
                                                                    });
                                                                }
                                                            }}
                                                            beforeUpload={(fileList) => {
                                                                if (this.state.fileList.length > 0) {
                                                                    this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                                    return false;
                                                                }
                                                                return true;
                                                            }}
                                                            onRemove={({ fileList }) => {
                                                                this.setState({
                                                                    fileList: [],
                                                                    filePath: "",
                                                                })
                                                            }
                                                            }>
                                                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                                <UploadOutlined /> Upload
                                                            </AntButton>
                                                        </Upload>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="S/N" dataIndex="index" />

                                    <Column title='Source Of Document' dataIndex='sourceOfDocument' render={(value, row, index) => { return value; }} />
                                    <Column title='Original Code Of Document' dataIndex='originalCodeOfDocument' render={(value, row, index) => { return value; }} />
                                    <Column title='Document Description' dataIndex='documentDescription' render={(value, row, index) => { return value; }} />
                                    <Column title='Issue Date' dataIndex='issueDate' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />
                                    <Column title='Rev. No' dataIndex='revNo' render={(value, row, index) => { return value; }} />
                                    <Column title='Type Of Document' dataIndex='typeOfDocumentId' render={(value, row, index) => { return value; }} />
                                    <Column title='Company Document Code' dataIndex='companyDocumentCode' render={(value, row, index) => { return value; }} />
                                    <Column title='Custodian Departments' dataIndex='departmentId' render={(value, row, index) => { return value; }} />
                                    <Column title='Text' dataIndex='documentPath' render={(value, row, index) => {
                                        if (Boolean(value))
                                            return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(value)} type="primary" shape="round" icon={<DownloadOutlined />} >

                                            </AntButton>
                                    }} />


                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />

                                </Table>}
                            </Card>

                        </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentedInfoLog)
