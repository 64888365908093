/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "ManpowerLog";

export class ManpowerLog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            //[STATE_FIELDS],
            userSelectItems: [],

            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.setComboboxes();
    }


    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/Staff/getallByCompany");
        if (resDepList.data.length > 0) {

            this.setState({
                userSelectItems: resDepList.data
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }



    }
    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            staffId: this.state.staffId,
            plannedAssignmentDate: this.state.plannedAssignmentDate,
            transmittalNo: this.state.transmittalNo,
            assignmentDate: this.state.assignmentDate,
            plannedReleaseDate: this.state.plannedReleaseDate,
            releaseDate: this.state.releaseDate,
            plannedVocationDate: this.state.plannedVocationDate,
            vacationDate: this.state.vacationDate,
            vacationDuration: this.state.vacationDuration,
            vacationReturnDate: this.state.vacationReturnDate,
            approvalStatus: this.state.approvalStatus,
            approvalDuration: this.state.approvalDuration,
            approvalType: this.state.approvalType,
        };//SAVE_OBJECT kodlandığında kaldırılacak
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ManpowerLog/add", obj);
        else
            await handleRequest("POST", "/ManpowerLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {
        let item = this.state.userSelectItems.find(p => p.id === row.staffId);
        if (Boolean(item)) {
            this.formRef.current.setFieldsValue({

                department: item.department.departmentName,
                designation: item.designation.designationName,
                phone: item.residencePersonelPhone,
                email: item.residenceCompanyEmail,
            });
            this.setState({
                department: item.department.departmentName, 
                designation: item.designation.designationName,
                phone: item.residencePersonelPhone,
                email: item.residenceCompanyEmail,
            })
        }


        this.formRef.current.setFieldsValue({
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: row.staffId,
            plannedAssignmentDate: Boolean(row.plannedAssignmentDate) ? moment(row.plannedAssignmentDate) : null,
            transmittalNo: row.transmittalNo,
            assignmentDate: Boolean(row.assignmentDate) ? moment(row.assignmentDate) : null,
            plannedReleaseDate: Boolean(row.plannedReleaseDate) ? moment(row.plannedReleaseDate) : null,
            releaseDate: Boolean(row.releaseDate) ? moment(row.releaseDate) : null,
            plannedVocationDate: Boolean(row.plannedVocationDate) ? moment(row.plannedVocationDate) : null,
            vacationDate: Boolean(row.vacationDate) ? moment(row.vacationDate) : null,
            vacationDuration: row.vacationDuration,
            vacationReturnDate: Boolean(row.vacationReturnDate) ? moment(row.vacationReturnDate) : null,
            approvalStatus: row.approvalStatus,
            approvalDuration: row.approvalDuration,
            approvalType: row.approvalType,
        });

        this.setState({
            //  [EDIT_STATE_FIELDS], 
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            department: null,
            designation: null,
            phone: null,
            email: null,

            id: row.id,
            staffId: row.staffId,
            plannedAssignmentDate: Boolean(row.plannedAssignmentDate) ? moment(row.plannedAssignmentDate) : null,
            transmittalNo: row.transmittalNo,
            assignmentDate: Boolean(row.assignmentDate) ? moment(row.assignmentDate) : null,
            plannedReleaseDate: Boolean(row.plannedReleaseDate) ? moment(row.plannedReleaseDate) : null,
            releaseDate: Boolean(row.releaseDate) ? moment(row.releaseDate) : null,
            plannedVocationDate: Boolean(row.plannedVocationDate) ? moment(row.plannedVocationDate) : null,
            vacationDate: Boolean(row.vacationDate) ? moment(row.vacationDate) : null,
            vacationDuration: row.vacationDuration,
            vacationReturnDate: Boolean(row.vacationReturnDate) ? moment(row.vacationReturnDate) : null,
            approvalStatus: row.approvalStatus,
            approvalDuration: row.approvalDuration,
            approvalType: row.approvalType,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ManpowerLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/ManpowerLog/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,
        });

        this.setState({
            // [RESETINPUTS_STATES],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            department: null,
            designation: null,
            phone: null,
            email: null,

            staffId: null,
            plannedAssignmentDate: null,
            transmittalNo: null,
            assignmentDate: null,
            plannedReleaseDate: null,
            releaseDate: null,
            plannedVocationDate: null,
            vacationDate: null,
            vacationDuration: null,
            vacationReturnDate: null,
            approvalStatus: null,
            approvalDuration: null,
            approvalType: null,

        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.BadgeNumber").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.BadgeNumber" defaultMessage="Badge Number" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="staffId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.BadgeNumber")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.BadgeNumber").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.BadgeNumber")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.BadgeNumber").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Badge Number" id="ManpowerLog.BadgeNumber" value={this.state.staffId} onChange={(value) => {
                                                            let item = this.state.userSelectItems.find(p => p.id === value);

                                                            this.formRef.current.setFieldsValue({
                                                                staffId: value,
                                                                department: item.department.departmentName,
                                                                designation: item.designation.designationName,
                                                                phone: item.residencePersonelPhone,
                                                                email: item.residenceCompanyEmail,
                                                            });
                                                            this.setState({
                                                                staffId: value,
                                                                department: item.department.departmentName,
                                                                designation: item.designation.designationName,
                                                                phone: item.residencePersonelPhone,
                                                                email: item.residenceCompanyEmail,
                                                            })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.userSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.budgeNo}</Option>
                                                            ))}
                                                        </Select>

                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.Name").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.Name" defaultMessage="Name" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="staffId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Name")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Name").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Name")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Name").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Name" id="ManpowerLog.Name" value={this.state.staffId} onChange={(value) => {
                                                            let item = this.state.userSelectItems.find(p => p.id === value);

                                                            this.formRef.current.setFieldsValue({
                                                                staffId: value,
                                                                department: item.department.departmentName,
                                                                designation: item.designation.designationName,
                                                                phone: item.residencePersonelPhone,
                                                                email: item.residenceCompanyEmail,
                                                            });
                                                            this.setState({
                                                                staffId: value,
                                                                department: item.department.departmentName,
                                                                designation: item.designation.designationName,
                                                                phone: item.residencePersonelPhone,
                                                                email: item.residenceCompanyEmail,
                                                            })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.userSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.fullName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.Department").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.Department" defaultMessage="Department" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="department"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Department")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Department").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Department")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Department").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Department" style={{ width: "100%" }} id="ManpowerLog.Department" value={this.state.department} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.Designation").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.Designation" defaultMessage="Designation" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="designation"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Designation")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Designation").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Designation")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.Designation").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Designation" style={{ width: "100%" }} id="ManpowerLog.Designation" value={this.state.designation} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.phone").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.phone" defaultMessage="Phone No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="phone"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.phone")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.phone").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.phone")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.phone").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Phone No" style={{ width: "100%" }} id="ManpowerLog.phone" value={this.state.phone} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.email").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.email" defaultMessage="Email" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="email"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.email")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.email").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.email")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.email").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Email" style={{ width: "100%" }} id="ManpowerLog.email" value={this.state.email} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.pad").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.pad" defaultMessage="Planned Assignment Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="plannedAssignmentDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pad")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pad").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pad")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pad").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.pad" format="DD-MM-YYYY" value={this.state.plannedAssignmentDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                plannedAssignmentDate: date
                                                            });
                                                            this.setState({
                                                                plannedAssignmentDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.tn").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.tn" defaultMessage="Transmittal No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="transmittalNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.tn")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.tn").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.tn")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.tn").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Transmittal No" style={{ width: "100%" }} id="ManpowerLog.tn" value={this.state.transmittalNo} onChange={(e) => { this.setState({ transmittalNo: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.ad").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.ad" defaultMessage="Assignment Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="assignmentDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.ad")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.ad").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.ad")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.ad").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.ad" format="DD-MM-YYYY" value={this.state.assignmentDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                assignmentDate: date
                                                            });
                                                            this.setState({
                                                                assignmentDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.prd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.prd" defaultMessage="Planned Release Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="plannedReleaseDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.prd")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.prd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.prd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.prd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.prd" format="DD-MM-YYYY" value={this.state.plannedReleaseDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                plannedReleaseDate: date
                                                            });
                                                            this.setState({
                                                                plannedReleaseDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.rd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.rd" defaultMessage="Release Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="releaseDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.rd")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.rd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.rd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.rd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.rd" format="DD-MM-YYYY" value={this.state.releaseDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                releaseDate: date
                                                            });
                                                            this.setState({
                                                                releaseDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.pvd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.pvd" defaultMessage="Planned Vacation Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="plannedVocationDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pvd")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pvd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pvd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.pvd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.pvd" format="DD-MM-YYYY" value={this.state.plannedVocationDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                plannedVocationDate: date
                                                            });
                                                            this.setState({
                                                                plannedVocationDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.vd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.vd" defaultMessage="Vacation Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="vacationDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vd")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.vd" format="DD-MM-YYYY" value={this.state.vacationDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                vacationDate: date
                                                            });
                                                            this.setState({
                                                                vacationDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.vdur").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.vdur" defaultMessage="Vacation Duration" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="vacationDuration"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vdur")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vdur").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vdur")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vdur").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="ManpowerLog.vdur" placeholder="Vacation Duration"
                                                            min={0} value={this.state.vacationDuration} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    vacationDuration: value
                                                                });
                                                                this.setState({ vacationDuration: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.vreturndate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.vreturndate" defaultMessage="Vacation Return Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="vacationReturnDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vreturndate")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vreturndate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vreturndate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.vreturndate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="ManpowerLog.vreturndate" format="DD-MM-YYYY" value={this.state.vacationReturnDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                vacationReturnDate: date
                                                            });
                                                            this.setState({
                                                                vacationReturnDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ManpowerLog.appstatus").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ManpowerLog.appstatus" defaultMessage="Approval Status" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="approvalStatus"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.appstatus")) ? this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.appstatus").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.appstatus")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ManpowerLog.appstatus").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Approval Status" id="ManpowerLog.appstatus" value={this.state.approvalStatus} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                approvalStatus: value
                                                            });
                                                            this.setState({
                                                                approvalStatus: value,
                                                                approvalDuration: null,
                                                                approvalType: null
                                                            })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            <Option key={"Approved"} value={"Approved"}>Approved</Option>
                                                            <Option key={"Conditionally approved"} value={"Conditionally approved"}>Conditionally approved</Option>

                                                        </Select>
                                                    </ColAnt>
                                                    <ColAnt span={2}>
                                                        {Boolean(this.state.approvalStatus) && this.state.approvalStatus === "Conditionally approved" && <InputNumber id="ManpowerLog.approvalDuration" placeholder="Duration"
                                                            min={0} value={this.state.approvalDuration} onChange={(value) => {

                                                                this.setState({ approvalDuration: value })
                                                            }} />}
                                                    </ColAnt>
                                                    <ColAnt span={2}>
                                                        {Boolean(this.state.approvalStatus) && this.state.approvalStatus === "Conditionally approved" && <Select style={{ width: "100%" }} placeholder="Duration Type" id="ManpowerLog.approvalType" value={this.state.approvalType} onChange={(value) => {

                                                            this.setState({ approvalType: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            <Option key={"Day"} value={"Day"}>Day</Option>
                                                            <Option key={"Week"} value={"Week"}>Week</Option>
                                                            <Option key={"Month"} value={"Month"}>Month</Option>
                                                            <Option key={"Year"} value={"Year"}>Year</Option>

                                                        </Select>}
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                    <Column title="S/N" dataIndex="index" />
                                    <Column title="Name" render={(value, row, index) => {

                                        if (Boolean(row.staff))
                                            return row.staff.fullName;

                                    }} />

                                    <Column title="Planned Assignment Date" render={(value, row, index) => {
                                        if (Boolean(row.plannedAssignmentDate))
                                            return moment(row.plannedAssignmentDate).format("DD/MM/YYYY");

                                    }} />

                                    <Column title="Transmittal No" render={(value, row, index) => {
                                        if (Boolean(row.transmittalNo))
                                            return row.transmittalNo;

                                    }} />

                                    <Column title="Assignment Date" render={(value, row, index) => {
                                        if (Boolean(row.assignmentDate))
                                            return moment(row.assignmentDate).format("DD/MM/YYYY");

                                    }} />

                                    <Column title="Planned Release Date" render={(value, row, index) => {
                                        if (Boolean(row.plannedReleaseDate))
                                            return moment(row.plannedReleaseDate).format("DD/MM/YYYY");

                                    }} />

                                    <Column title="Release Date" render={(value, row, index) => {
                                        if (Boolean(row.releaseDate))
                                            return moment(row.releaseDate).format("DD/MM/YYYY");

                                    }} />

                                    <Column title="Planned Vacation Date" render={(value, row, index) => {
                                        if (Boolean(row.plannedVocationDate))
                                            return moment(row.plannedVocationDate).format("DD/MM/YYYY");

                                    }} />

                                    <Column title="Vacation Date" render={(value, row, index) => {
                                        if (Boolean(row.vacationDate))
                                            return moment(row.vacationDate).format("DD/MM/YYYY");

                                    }} />
                                    <Column title="Vacation Duration" dataIndex="vacationDuration" />

                                    <Column title="Vacation Return Date" render={(value, row, index) => {
                                        if (Boolean(row.vacationReturnDate))
                                            return moment(row.vacationReturnDate).format("DD/MM/YYYY");

                                    }} />

                                    <Column title="Approval Status" render={(value, row, index) => {
                                        if (Boolean(row.approvalStatus))
                                            return row.approvalStatus; 

                                    }} />



                                    <Column title="Action" render={(value, row, index) => {

                                        return this.actionTemplate(row);

                                    }} />
                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ManpowerLog)
