/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Modal, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Select, message, Cascader } from 'antd';
import { Editor } from 'primereact/editor';

const { Option } = Select;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.data.id, lineage: node.data.lineage });
        if (node.children) {
            generateList(node.children);
        }
    }
};

export class HowToUse extends Component {

    constructor() {
        super();
        this.state = {
            modalHowToUse: false,
            formId: [],
            howToUse: "",
            howToUseId: null,
            howToUseThis: "",
            pagesHowToUses: null,
            dynamicMenuCascader: [],
            dynamicMenuTree: [],
            thisPageId: null
        };
    }

    componentDidMount = async () => {
        this.restartDatas();
        this.getDynamicMenu();
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            generateList(responseTree.data.treeTable);
            this.setState({
                dynamicMenuCascader: responseTree.data.cascader,
                dynamicMenuTree: responseTree.data.treeTable
            });
        } else {
            this.setState({
                dynamicMenuCascader: [],
                dynamicMenuTree: []
            });
        }
    }

    restartDatas = async () => {
        const responseHowToUse = await handleRequest("GET", "/HowToUse/getAllByCompany");

        if (responseHowToUse.data.length > 0) {
            this.setState({
                pagesHowToUses: responseHowToUse.data
            });
        } else {
            this.setState({
                pagesHowToUses: null
            });
        }
    }

    findPage = (howToUse) => {
        if (this.state.formId.length > 0) {
            return howToUse.formId === Number(this.state.formId[this.state.formId.length - 1]);
        }
    }

    fillHowToUse = () => {
        var flagPagesHowToUses = this.state.pagesHowToUses;

        if (flagPagesHowToUses !== null) {
            var flagHowToUse = flagPagesHowToUses.find(this.findPage);

            console.log(flagHowToUse);

            if (flagHowToUse !== undefined) {
                this.setState({
                    howToUse: flagHowToUse.howToUse,
                    howToUseId: flagHowToUse.id
                });
            } else {
                this.setState({
                    howToUse: "",
                    howToUseId: null
                });
            }
        }
    }

    saveHowToUse = async () => {
        if (this.state.howToUseId === null && this.state.formId.length > 0) {
            const newHowToUse = {
                formId: this.state.formId[this.state.formId.length - 1],
                howToUse: this.state.howToUse
            }

            await handleRequest("POST", "/HowToUse/add", newHowToUse);
            message.success(`Page's "How To Use" information has been created successfully.`);
        } else if (this.state.howToUseId !== null && this.state.formId.length > 0) {
            const updatedHowToUse = {
                id: this.state.howToUseId,
                formId: this.state.formId[this.state.formId.length - 1],
                howToUse: this.state.howToUse
            }

            await handleRequest("POST", "/HowToUse/update", updatedHowToUse);
            message.success(`Page's "How To Use" information has been updated successfully.`);
        }

        this.restartDatas();
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        var element = this.state.dynamicMenuTree;

        for (let index = 0; index < element.length; index++) {
            var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

            if (result !== null)
                break;
        }

        if (result !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + result.data.id);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onDynamicMenuCascaderChange = (value) => {
        this.setState({
            formId: value
        });

        setTimeout(() => {
            this.fillHowToUse();
        }, 200);
    }

    render() {

        const howToUseThis = this.state.howToUseThis;

        return (
            <div>
                <Modal
                    show={this.state.modalHowToUse}
                    onHide={this.showOrHideModalHowToUse}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Row style={{ marginTop: "1rem" }}>
                        <Col sm="1"></Col>
                        <Form.Label style={{ fontWeight: "bold", color: "black" }} column sm="2">
                            Form Name
                        </Form.Label>
                        <Col sm="3">
                            {/*<Select style={{ width: "100%" }} placeholder="Form Name" value={this.state.pageName}
                                showSearch
                                onChange={(value) => {
                                    this.setState({ pageName: value });
                                    setTimeout(() => {
                                        this.fillHowToUse();
                                    }, 200);
                                }}
                            >
                                {this.state.pageList.map((i) => (
                                    <Option key={i.name} value={i.name}>
                                        {i.pageName}
                                    </Option>
                                ))}
                                </Select>*/}
                            <Cascader style={{ width: '100%' }} value={this.state.formId}
                                options={this.state.dynamicMenuCascader}
                                onChange={this.onDynamicMenuCascaderChange} changeOnSelect />
                        </Col>
                        <Col sm="5"></Col>
                        <Col sm="1">
                            <Button type="button" style={{ marginRight: '10px' }} onClick={this.showHowToUse} variant="info">?</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1rem", marginLeft: "10px", marginRight: "10px" }}>
                        <Col sm="12">
                            <Editor style={{ height: '320px' }} value={this.state.howToUse} onTextChange={(e) => this.setState({ howToUse: e.htmlValue })} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <Col sm="3"></Col>
                        <Col sm="3">
                            <Button type="button" style={{ width: '100%' }} onClick={this.saveHowToUse} variant="info">Save Changes</Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(HowToUse)