/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Select, InputNumber, DatePicker, Switch, Radio, Checkbox, Row as RowAnt, Col as ColAnt } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

function Month(month) {
  if (month == 1) {
    return "January";
  } else if (month == 2) {
    return "February";
  } else if (month == 3) {
    return "March";
  } else if (month == 4) {
    return "April";
  } else if (month == 5) {
    return "May";
  } else if (month == 6) {
    return "June";
  } else if (month == 7) {
    return "July";
  } else if (month == 8) {
    return "August";
  } else if (month == 9) {
    return "September";
  } else if (month == 10) {
    return "October";
  } else if (month == 11) {
    return "November";
  } else if (month == 12) {
    return "December";
  }
  return "";
}

function MonthBulKst(month) {
  if (month == 1) {
    return "Jan";
  } else if (month == 2) {
    return "Feb";
  } else if (month == 3) {
    return "Mar";
  } else if (month == 4) {
    return "Apr";
  } else if (month == 5) {
    return "May";
  } else if (month == 6) {
    return "Jun";
  } else if (month == 7) {
    return "Jul";
  } else if (month == 8) {
    return "Aug";
  } else if (month == 9) {
    return "Sep";
  } else if (month == 10) {
    return "Oct";
  } else if (month == 11) {
    return "Nov";
  } else if (month == 12) {
    return "Dec";
  }
  return "";
}

class SalarySetting extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      invoiceDate: null,
      voucherDate: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      salarySettingsTable: [],
      ReleasedAuthorizedBys: [],
      InvoiceFileUpload: [],
      invoice: "",
      VoucherFileUpload: [],
      voucher: "",
      fileList: [],
      hiddenHomeAllowanceCheck: true,
      hiddenHomeAllowanceDate: true,
      hiddenTransportationAllowanceCheck: true,
      hiddenTransportationAllowanceDate: true,
      hiddenFoodAllowanceCheck: true,
      hiddenFoodAllowanceDate: true,
      hiddenOvertimeCheck: true,
      hiddenOvertimeDate: true,
      hiddenBusinessTripCheck: true,
      hiddenBusinessTripDate: true,
      hiddenOtherPaymentsCheck: true,
      hiddenOtherPaymentsDate: true,
      salaryPeriodMax: 0,
      months: []
    };
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "SalarySetting" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "SalarySetting",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "SalarySetting");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
  }

  getTableProperties = async () => {
    this.SalarySetting = JSON.parse(localStorage.getItem('SalarySetting'));
    if (this.SalarySetting != null && this.SalarySetting != undefined && this.SalarySetting != "") {

      await this.setState({
        bordered: this.SalarySetting.bordered,
        title: this.SalarySetting.titleDrm == true ? title : undefined,
        titleDrm: this.SalarySetting.titleDrm,
        showHeader: this.SalarySetting.showHeader,
        footer: this.SalarySetting.footerDrm == true ? footer : undefined,
        footerDrm: this.SalarySetting.footerDrm,
        expandable: this.SalarySetting.expandableDrm == true ? expandable : {},
        expandableDrm: this.SalarySetting.expandableDrm,
        rowSelection: this.SalarySetting.rowSelection,
        ellipsis: this.SalarySetting.ellipsis,
        tableLayout: this.SalarySetting.tableLayout,
        size: this.SalarySetting.size,
        top: this.SalarySetting.top,
        bottom: this.SalarySetting.bottom,
        editBgColor: this.SalarySetting.editBgColor,
        deleteBgColor: this.SalarySetting.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.SalarySetting.yScroll,
        xScroll: this.SalarySetting.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.salarySettingsTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.salarySettingsTable.length, showQuickJumper: true,
          position: [this.SalarySetting.top, this.SalarySetting.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  };

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedSalarySetting: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  LastDayBul(month) {
    if (month == 1) {
      return 31;
    } else if (month == 2) {
      if (this.state.year % 4 == 0) {
        return 29;
      } else {
        return 28;
      }
    } else if (month == 3) {
      return 31;
    } else if (month == 4) {
      return 30;
    } else if (month == 5) {
      return 31;
    } else if (month == 6) {
      return 30;
    } else if (month == 7) {
      return 31;
    } else if (month == 8) {
      return 31;
    } else if (month == 9) {
      return 30;
    } else if (month == 10) {
      return 31;
    } else if (month == 11) {
      return 30;
    } else if (month == 12) {
      return 31;
    }
    return "";
  }

  saveSalarySetting = async () => {
    var salarySetting = [];
    this.state.month.forEach(month => {
      var firstDay = 1;
      var lastDay = 1;
      if (this.state.wholePeriod) {
        if (this.state.period == 1) {  //Monthly
          firstDay = 1;
          lastDay = this.LastDayBul(month);
        } else if (this.state.period == 2) {  //Weekly
          firstDay = 1;
          lastDay = 7;
        } else if (this.state.period == 3) {  //Daily
          firstDay = 1;
          lastDay = 1;
        }
      } else {
        firstDay = this.state.firstDay;
        lastDay = this.state.lastDay;
      }
      const addSalarySetting = {
        date: moment(),
        year: this.state.year,
        month: month,
        period: this.state.period,
        wholePeriod: this.state.wholePeriod,
        firstDay: firstDay,
        lastDay: lastDay,
        paymentDay: this.state.paymentDay,
        paymentInFinancialDay: this.state.paymentInFinancialDay,
        homeAllowance: this.state.homeAllowance,
        homeAllowanceNextMonth: this.state.homeAllowanceNextMonth,
        homeAllowanceDate: this.state.homeAllowanceDate,
        transportationAllowance: this.state.transportationAllowance,
        transportationAllowanceNextMonth: this.state.transportationAllowanceNextMonth,
        transportationAllowanceDate: this.state.transportationAllowanceDate,
        foodAllowance: this.state.foodAllowance,
        foodAllowanceNextMonth: this.state.foodAllowanceNextMonth,
        foodAllowanceDate: this.state.foodAllowanceDate,
        overtime: this.state.overtime,
        overtimeNextMonth: this.state.overtimeNextMonth,
        overtimeDate: this.state.overtimeDate,
        businessTrip: this.state.businessTrip,
        businessTripNextMonth: this.state.businessTripNextMonth,
        businessTripDate: this.state.businessTripDate,
        otherPayments: this.state.otherPayments,
        otherPaymentsNextMonth: this.state.otherPaymentsNextMonth,
        otherPaymentsDate: this.state.otherPaymentsDate,
      }
      salarySetting.push(addSalarySetting);
    });

    console.log("ADD", salarySetting);
    var response = await handleRequest("POST", "/SalarySetting/saveAll", salarySetting);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  updateSalarySetting = async () => {
    var firstDay = 1;
    var lastDay = 1;
    if (this.state.wholePeriod) {
      if (this.state.period == 1) {  //Monthly
        firstDay = 1;
        lastDay = this.LastDayBul(this.state.month);
      } else if (this.state.period == 2) {  //Weekly
        firstDay = 1;
        lastDay = 7;
      } else if (this.state.period == 3) {  //Daily
        firstDay = 1;
        lastDay = 1;
      }
    } else {
      firstDay = this.state.firstDay;
      lastDay = this.state.lastDay;
    }
    const updatedSalarySetting = {
      id: this.state.selectedSalarySetting.id,
      date: moment(),
      year: this.state.year,
      month: this.state.month,
      period: this.state.period,
      wholePeriod: this.state.wholePeriod,
      firstDay: firstDay,
      lastDay: lastDay,
      paymentDay: this.state.paymentDay,
      paymentInFinancialDay: this.state.paymentInFinancialDay,
      homeAllowance: this.state.homeAllowance,
      homeAllowanceNextMonth: this.state.homeAllowanceNextMonth,
      homeAllowanceDate: this.state.homeAllowanceDate,
      transportationAllowance: this.state.transportationAllowance,
      transportationAllowanceNextMonth: this.state.transportationAllowanceNextMonth,
      transportationAllowanceDate: this.state.transportationAllowanceDate,
      foodAllowance: this.state.foodAllowance,
      foodAllowanceNextMonth: this.state.foodAllowanceNextMonth,
      foodAllowanceDate: this.state.foodAllowanceDate,
      overtime: this.state.overtime,
      overtimeNextMonth: this.state.overtimeNextMonth,
      overtimeDate: this.state.overtimeDate,
      businessTrip: this.state.businessTrip,
      businessTripNextMonth: this.state.businessTripNextMonth,
      businessTripDate: this.state.businessTripDate,
      otherPayments: this.state.otherPayments,
      otherPaymentsNextMonth: this.state.otherPaymentsNextMonth,
      otherPaymentsDate: this.state.otherPaymentsDate,
    }
    console.log("UPD", updatedSalarySetting);
    var response = await handleRequest("POST", "/SalarySetting/update", updatedSalarySetting);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/SalarySetting/getAllByCompany");
    if (response.data.length === 0) {
      this.setState({
        salarySettingsTable: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        salarySettingsTable: response.data,
      });
    }
  }

  deleteSalarySettingOK = async () => {
    const deleteSalarySetting = {
      id: this.state.selectedSalarySetting.id
    }
    var response = await handleRequest("POST", "/SalarySetting/delete", deleteSalarySetting);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    console.log("RRR", row);
    this.formRef.current.setFieldsValue({
      Date: Boolean(row.date) ? moment(row.date) : null,
      Year: Boolean(row.year) ? moment(row.year, "YYYY") : null,
      Month: row.month,
      Period: row.period,
      WholePeriod: row.wholePeriod,
      PaymentDay: row.paymentDay,
      PaymentInFinancialDay: row.paymentInFinancialDay,
      HomeAllowance: row.homeAllowance,
      HomeAllowanceNextMonth: row.homeAllowanceNextMonth,
      HomeAllowanceDate: Boolean(row.homeAllowanceDate) ? moment(row.homeAllowanceDate) : null,
      TransportationAllowance: row.transportationAllowance,
      TransportationAllowanceNextMonth: row.transportationAllowanceNextMonth,
      TransportationAllowanceDate: Boolean(row.transportationAllowanceDate) ? moment(row.transportationAllowanceDate) : null,
      FoodAllowance: row.foodAllowance,
      FoodAllowanceNextMonth: row.foodAllowanceNextMonth,
      FoodAllowanceDate: Boolean(row.foodAllowanceDate) ? moment(row.foodAllowanceDate) : null,
      Overtime: row.overtime,
      OvertimeNextMonth: row.overtimeNextMonth,
      OvertimeDate: Boolean(row.overtimeDate) ? moment(row.overtimeDate) : null,
      BusinessTrip: row.businessTrip,
      BusinessTripNextMonth: row.businessTripNextMonth,
      BusinessTripDate: Boolean(row.businessTripDate) ? moment(row.businessTripDate) : null,
      OtherPayments: row.otherPayments,
      OtherPaymentsNextMonth: row.otherPaymentsNextMonth,
      OtherPaymentsDate: Boolean(row.otherPaymentsDate) ? moment(row.otherPaymentsDate) : null,
    });

    var hiddenHomeAllowanceDate = true;
    if (row.homeAllowanceNextMonth == false) {
      hiddenHomeAllowanceDate = false;
    }

    var hiddenHomeAllowanceCheck = true;
    if (row.homeAllowance == false) {
      hiddenHomeAllowanceCheck = false;
    } else {
      hiddenHomeAllowanceDate = true;
    }

    var hiddenTransportationAllowanceDate = true;
    if (row.transportationAllowanceNextMonth == false) {
      hiddenTransportationAllowanceDate = false;
    }

    var hiddenTransportationAllowanceCheck = true;
    if (row.transportationAllowance == false) {
      hiddenTransportationAllowanceCheck = false;
    } else {
      hiddenTransportationAllowanceDate = true;
    }

    var hiddenFoodAllowanceDate = true;
    if (row.foodAllowanceNextMonth == false) {
      hiddenFoodAllowanceDate = false;
    }

    var hiddenFoodAllowanceCheck = true;
    if (row.foodAllowance == false) {
      hiddenFoodAllowanceCheck = false;
    } else {
      hiddenFoodAllowanceDate = true;
    }

    var hiddenOvertimeDate = true;
    if (row.overtimeNextMonth == false) {
      hiddenOvertimeDate = false;
    }

    var hiddenOvertimeCheck = true;
    if (row.overtime == false) {
      hiddenOvertimeCheck = false;
    } else {
      hiddenOvertimeDate = true;
    }

    var hiddenBusinessTripDate = true;
    if (row.businessTripNextMonth == false) {
      hiddenBusinessTripDate = false;
    }

    var hiddenBusinessTripCheck = true;
    if (row.businessTrip == false) {
      hiddenBusinessTripCheck = false;
    } else {
      hiddenBusinessTripDate = true;
    }

    var hiddenOtherPaymentsDate = true;
    if (row.otherPaymentsNextMonth == false) {
      hiddenOtherPaymentsDate = false;
    }

    var hiddenOtherPaymentsCheck = true;
    if (row.otherPayments == false) {
      hiddenOtherPaymentsCheck = false;
    } else {
      hiddenOtherPaymentsDate = true;
    }

    await this.setState({
      selectedSalarySetting: row,
      date: Boolean(row.date) ? moment(row.date) : null,
      year: row.year,
      month: row.month,
      period: row.period,
      wholePeriod: row.wholePeriod,
      firstDay: row.firstDay,
      lastDay: row.lastDay,
      paymentDay: row.paymentDay,
      paymentInFinancialDay: row.paymentInFinancialDay,
      homeAllowance: row.homeAllowance,
      homeAllowanceNextMonth: row.homeAllowanceNextMonth,
      homeAllowanceDate: Boolean(row.homeAllowanceDate) ? moment(row.homeAllowanceDate) : null,
      transportationAllowance: row.transportationAllowance,
      transportationAllowanceNextMonth: row.transportationAllowanceNextMonth,
      transportationAllowanceDate: Boolean(row.transportationAllowanceDate) ? moment(row.transportationAllowanceDate) : null,
      foodAllowance: row.foodAllowance,
      foodAllowanceNextMonth: row.foodAllowanceNextMonth,
      foodAllowanceDate: Boolean(row.foodAllowanceDate) ? moment(row.foodAllowanceDate) : null,
      overtime: row.overtime,
      overtimeNextMonth: row.overtimeNextMonth,
      overtimeDate: Boolean(row.overtimeDate) ? moment(row.overtimeDate) : null,
      businessTrip: row.businessTrip,
      businessTripNextMonth: row.businessTripNextMonth,
      businessTripDate: Boolean(row.businessTripDate) ? moment(row.businessTripDate) : null,
      otherPayments: row.otherPayments,
      otherPaymentsNextMonth: row.otherPaymentsNextMonth,
      otherPaymentsDate: Boolean(row.otherPaymentsDate) ? moment(row.otherPaymentsDate) : null,

      hiddenHomeAllowanceCheck: hiddenHomeAllowanceCheck,
      hiddenHomeAllowanceDate: hiddenHomeAllowanceDate,

      hiddenBusinessTripCheck: hiddenBusinessTripCheck,
      hiddenBusinessTripDate: hiddenBusinessTripDate,

      hiddenTransportationAllowanceCheck: hiddenTransportationAllowanceCheck,
      hiddenTransportationAllowanceDate: hiddenTransportationAllowanceDate,

      hiddenFoodAllowanceCheck: hiddenFoodAllowanceCheck,
      hiddenFoodAllowanceDate: hiddenFoodAllowanceDate,

      hiddenOvertimeCheck: hiddenOvertimeCheck,
      hiddenOvertimeDate: hiddenOvertimeDate,

      hiddenOtherPaymentsCheck: hiddenOtherPaymentsCheck,
      hiddenOtherPaymentsDate: hiddenOtherPaymentsDate,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      Month: [],
      HomeAllowance: true,
      TransportationAllowance: true,
      FoodAllowance: true,
      Overtime: true,
      BusinessTrip: true,
      OtherPayments: true,

      Date: "",
      Year: "",
      Period: "",
      WholePeriod: true,
      PaymentDay: 0,
      PaymentInFinancialDay: "",
      HomeAllowanceNextMonth: "",
      HomeAllowanceDate: "",
      TransportationAllowanceNextMonth: "",
      TransportationAllowanceDate: "",
      FoodAllowanceNextMonth: "",
      FoodAllowanceDate: "",
      OvertimeNextMonth: "",
      OvertimeDate: "",
      BusinessTripNextMonth: "",
      BusinessTripDate: "",
      OtherPaymentsNextMonth: "",
      OtherPaymentsDate: "",
    });

    this.setState({
      selectedSalarySetting: [],
      date: "",
      year: "",
      month: [],
      months: [],
      period: "",
      wholePeriod: true,
      firstDay: 1,
      lastDay: 1,
      paymentDay: 0,
      paymentInFinancialDay: "",
      homeAllowance: true,
      homeAllowanceNextMonth: "",
      homeAllowanceDate: "",
      transportationAllowance: true,
      transportationAllowanceNextMonth: "",
      transportationAllowanceDate: "",
      foodAllowance: true,
      foodAllowanceNextMonth: "",
      foodAllowanceDate: "",
      overtime: true,
      overtimeNextMonth: "",
      overtimeDate: "",
      businessTrip: true,
      businessTripNextMonth: "",
      businessTripDate: "",
      otherPayments: true,
      otherPaymentsNextMonth: "",
      otherPaymentsDate: "",

      hiddenHomeAllowanceCheck: true,
      hiddenHomeAllowanceDate: true,

      hiddenBusinessTripCheck: true,
      hiddenBusinessTripDate: true,

      hiddenTransportationAllowanceCheck: true,
      hiddenTransportationAllowanceDate: true,

      hiddenFoodAllowanceCheck: true,
      hiddenFoodAllowanceDate: true,

      hiddenOvertimeCheck: true,
      hiddenOvertimeDate: true,

      hiddenOtherPaymentsCheck: true,
      hiddenOtherPaymentsDate: true,

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
  }

  changeButtonCancel = () => {
    this.resetInputs();

    this.setState({
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeHomeAllowanceDate = (value, dateString) => {
    this.setState({
      homeAllowanceDate: dateString,
    });
  }

  onChangeTransportationAllowanceDate = (value, dateString) => {
    this.setState({
      transportationAllowanceDate: dateString,
    });
  }

  onChangeFoodAllowanceDate = (value, dateString) => {
    this.setState({
      foodAllowanceDate: dateString,
    });
  }

  onChangeOvertimeDate = (value, dateString) => {
    this.setState({
      overtimeDate: dateString,
    });
  }

  onChangeBusinessTripDate = (value, dateString) => {
    this.setState({
      businessTripDate: dateString,
    });
  }

  onChangeOtherPaymentsDate = (value, dateString) => {
    this.setState({
      otherPaymentsDate: dateString,
    });
  }

  disabledDate(current) {
    return moment().add(-1, 'days').endOf('day') > current;
  }

  showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.pdf')) {
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true,
        });
      } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      } else {
        window.location.href = fileRealUrl;
      }
    } catch (error) {
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
    }
  };

  onYear = (date, dateString) => {
    var months = [];
    if (dateString > moment().format("YYYY")) {
      months = [
        { name: 'January', id: '1' },
        { name: 'February', id: '2' },
        { name: 'March', id: '3' },
        { name: 'April', id: '4' },
        { name: 'May', id: '5' },
        { name: 'June', id: '6' },
        { name: 'July', id: '7' },
        { name: 'August', id: '8' },
        { name: 'September', id: '9' },
        { name: 'October', id: '10' },
        { name: 'November', id: '11' },
        { name: 'December', id: '12' },
      ];
    } else if (dateString == moment().format("YYYY")) {
      if (moment().format("M") == 1) {
        months = [
          { name: 'January', id: '1' },
          { name: 'February', id: '2' },
          { name: 'March', id: '3' },
          { name: 'April', id: '4' },
          { name: 'May', id: '5' },
          { name: 'June', id: '6' },
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 2) {
        months = [
          { name: 'February', id: '2' },
          { name: 'March', id: '3' },
          { name: 'April', id: '4' },
          { name: 'May', id: '5' },
          { name: 'June', id: '6' },
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 3) {
        months = [
          { name: 'March', id: '3' },
          { name: 'April', id: '4' },
          { name: 'May', id: '5' },
          { name: 'June', id: '6' },
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      }
      else if (moment().format("M") == 4) {
        months = [
          { name: 'April', id: '4' },
          { name: 'May', id: '5' },
          { name: 'June', id: '6' },
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 5) {
        months = [
          { name: 'May', id: '5' },
          { name: 'June', id: '6' },
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 6) {
        months = [
          { name: 'June', id: '6' },
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 7) {
        months = [
          { name: 'July', id: '7' },
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 8) {
        months = [
          { name: 'August', id: '8' },
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 9) {
        months = [
          { name: 'September', id: '9' },
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 10) {
        months = [
          { name: 'October', id: '10' },
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 11) {
        months = [
          { name: 'November', id: '11' },
          { name: 'December', id: '12' },
        ];
      } else if (moment().format("M") == 12) {
        months = [
          { name: 'December', id: '12' },
        ];
      }
    }
    this.setState({
      year: dateString,
      months: months
    });
  }

  onMonth = (value) => {
    this.setState({
      month: value,
    });
  }

  render() {
    var itemDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Date");
    var itemYearAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Year");
    var itemMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Month");
    var itemPeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Period");
    var itemWholePeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "WholePeriod");
    var itemSalaryPeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SalaryPeriod");
    var itemPaymentDayAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PaymentDay");
    var itemPaymentInFinancialDayAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PaymentInFinancialDay");
    var itemHomeAllowanceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeAllowance");
    var itemHomeAllowanceNextMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeAllowanceNextMonth");
    var itemHomeAllowanceDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "HomeAllowanceDate");
    var itemTransportationAllowanceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TransportationAllowance");
    var itemTransportationAllowanceNextMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TransportationAllowanceNextMonth");
    var itemTransportationAllowanceDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TransportationAllowanceDate");
    var itemFoodAllowanceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "FoodAllowance");
    var itemFoodAllowanceNextMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "FoodAllowanceNextMonth");
    var itemFoodAllowanceDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "FoodAllowanceDate");
    var itemOvertimeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Overtime");
    var itemOvertimeNextMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OvertimeNextMonth");
    var itemOvertimeDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OvertimeDate");
    var itemBusinessTripAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BusinessTrip");
    var itemBusinessTripNextMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BusinessTripNextMonth");
    var itemBusinessTripDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "BusinessTripDate");
    var itemOtherPaymentsAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OtherPayments");
    var itemOtherPaymentsNextMonthAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OtherPaymentsNextMonth");
    var itemOtherPaymentsDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OtherPaymentsDate");

    var itemDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "Date");
    var itemYearRequired = this.state.dynamicInputs.find(p => p.inputKey === "Year");
    var itemMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "Month");
    var itemPeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "Period");
    var itemWholePeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "WholePeriod");
    var itemSalaryPeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "SalaryPeriod");
    var itemPaymentDayRequired = this.state.dynamicInputs.find(p => p.inputKey === "PaymentDay");
    var itemPaymentInFinancialDayRequired = this.state.dynamicInputs.find(p => p.inputKey === "PaymentInFinancialDay");
    var itemHomeAllowanceRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeAllowance");
    var itemHomeAllowanceNextMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeAllowanceNextMonth");
    var itemHomeAllowanceDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "HomeAllowanceDate");
    var itemTransportationAllowanceRequired = this.state.dynamicInputs.find(p => p.inputKey === "TransportationAllowance");
    var itemTransportationAllowanceNextMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "TransportationAllowanceNextMonth");
    var itemTransportationAllowanceDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "TransportationAllowanceDate");
    var itemFoodAllowanceRequired = this.state.dynamicInputs.find(p => p.inputKey === "FoodAllowance");
    var itemFoodAllowanceNextMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "FoodAllowanceNextMonth");
    var itemFoodAllowanceDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "FoodAllowanceDate");
    var itemOvertimeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Overtime");
    var itemOvertimeNextMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "OvertimeNextMonth");
    var itemOvertimeDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "OvertimeDate");
    var itemBusinessTripRequired = this.state.dynamicInputs.find(p => p.inputKey === "BusinessTrip");
    var itemBusinessTripNextMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "BusinessTripNextMonth");
    var itemBusinessTripDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "BusinessTripDate");
    var itemOtherPaymentsRequired = this.state.dynamicInputs.find(p => p.inputKey === "OtherPayments");
    var itemOtherPaymentsNextMonthRequired = this.state.dynamicInputs.find(p => p.inputKey === "OtherPaymentsNextMonth");
    var itemOtherPaymentsDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "OtherPaymentsDate");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("SalarySetting")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingDate" defaultMessage="Setting Date" />,
        render: (record) => {
          if (record != null && record != undefined) {
            if (Boolean(record.date)) {
              return moment(record.date).format("DD-MM-YYYY HH:mm")
            }
          }
          return "";
        }
      },
      {
        dataIndex: "year",
        key: "key",
        title: <FormattedMessage id="SalarySettingYear" defaultMessage="Year" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingMonth" defaultMessage="Month" />,
        render: (data) => {
          if (data != null && data != undefined && data.month != null && data.month != undefined && data.month != "" && data.month != 0) {
            return  moment().month(data.month-1).format('MMMM'); //Month(data.month); 
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingSalaryPeriod" defaultMessage="Salary Period" />,
        render: (data) => {
          if (data != null && data != undefined && data.firstDay != null && data.firstDay && data.firstDay != 0 && data.lastDay != null && data.lastDay != undefined && data.lastDay != 0) {
          
            var dayFirst =  moment().month(data.month-1).format('MMM'); //  MonthBulKst(data.month);          
            return data.firstDay + " " + dayFirst + " - " + data.lastDay + " " + dayFirst;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingPaymentDate" defaultMessage="Salary Date" />,
        render: (data) => {
          if (data != null && data != undefined) { //"Salary Period" un son günü ile "Salary Day" toplamı
            if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
              try {
                var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                  var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                  var day = addDate.format('dddd');
                  if (day == "Friday") {
                    addDate = lastPeriodDay.add(2, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  } else if (day == "Saturday") {
                    addDate = lastPeriodDay.add(1, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  } else {
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                }
              } catch (error) {
              }
            } else {
              try {
                var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                  var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                  var paymentDate = moment(addDate).format("DD-MM-YYYY");
                  if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                    return paymentDate;
                  }
                }
              } catch (error) {
              }
            }
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingHomeAllowance" defaultMessage="Home Allowance" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.homeAllowance) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                } catch (error) {
                }
              }
            } else if (data.homeAllowanceNextMonth) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDay = lastPeriodDay.add(data.paymentDay, 'd');
                    var addMonth = moment(addDay).add(1, 'M');
                    var paymentDateNextMonth = moment(addMonth).format("DD-MM-YYYY");
                    if (Boolean(paymentDateNextMonth) && paymentDateNextMonth != null && paymentDateNextMonth != undefined && paymentDateNextMonth != "Invalid date") {
                      return paymentDateNextMonth;
                    }
                  }
                } catch (error) {
                }
              }
            }
            else if (data.homeAllowance == false && data.homeAllowanceNextMonth == false && data.homeAllowanceDate != null && data.homeAllowanceDate != undefined) {
              if (Boolean(data.homeAllowanceDate)) {
                return moment(data.homeAllowanceDate).format("DD-MM-YYYY HH:mm")
              }
            }
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingTransportationAllowance" defaultMessage="Transportation Allowance" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.transportationAllowance) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                } catch (error) {
                }
              }
            } else if (data.transportationAllowanceNextMonth) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDay = lastPeriodDay.add(data.paymentDay, 'd');
                    var addMonth = moment(addDay).add(1, 'M');
                    var paymentDateNextMonth = moment(addMonth).format("DD-MM-YYYY");
                    if (Boolean(paymentDateNextMonth) && paymentDateNextMonth != null && paymentDateNextMonth != undefined && paymentDateNextMonth != "Invalid date") {
                      return paymentDateNextMonth;
                    }
                  }
                } catch (error) {
                }
              }
            }
            else if (data.transportationAllowance == false && data.transportationAllowanceNextMonth == false && data.transportationAllowanceDate != null && data.transportationAllowanceDate != undefined) {
              if (Boolean(data.transportationAllowanceDate)) {
                return moment(data.transportationAllowanceDate).format("DD-MM-YYYY HH:mm")
              }
            }
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingFoodAllowance" defaultMessage="Food Allowance" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.foodAllowance) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                } catch (error) {
                }
              }
            } else if (data.foodAllowanceNextMonth) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDay = lastPeriodDay.add(data.paymentDay, 'd');
                    var addMonth = moment(addDay).add(1, 'M');
                    var paymentDateNextMonth = moment(addMonth).format("DD-MM-YYYY");
                    if (Boolean(paymentDateNextMonth) && paymentDateNextMonth != null && paymentDateNextMonth != undefined && paymentDateNextMonth != "Invalid date") {
                      return paymentDateNextMonth;
                    }
                  }
                } catch (error) {
                }
              }
            }
            else if (data.foodAllowance == false && data.foodAllowanceNextMonth == false && data.foodAllowanceDate != null && data.foodAllowanceDate != undefined) {
              if (Boolean(data.foodAllowanceDate)) {
                return moment(data.foodAllowanceDate).format("DD-MM-YYYY HH:mm")
              }
            }
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingOvertime" defaultMessage="Overtime" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.overtime) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                } catch (error) {
                }
              }
            } else if (data.overtimeNextMonth) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDay = lastPeriodDay.add(data.paymentDay, 'd');
                    var addMonth = moment(addDay).add(1, 'M');
                    var paymentDateNextMonth = moment(addMonth).format("DD-MM-YYYY");
                    if (Boolean(paymentDateNextMonth) && paymentDateNextMonth != null && paymentDateNextMonth != undefined && paymentDateNextMonth != "Invalid date") {
                      return paymentDateNextMonth;
                    }
                  }
                } catch (error) {
                }
              }
            }
            else if (data.overtime == false && data.overtimeNextMonth == false && data.overtimeDate != null && data.overtimeDate != undefined) {
              if (Boolean(data.overtimeDate)) {
                return moment(data.overtimeDate).format("DD-MM-YYYY HH:mm")
              }
            }
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingBusinessTrip" defaultMessage="Business trip" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.businessTrip) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                } catch (error) {
                }
              }
            } else if (data.businessTripNextMonth) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDay = lastPeriodDay.add(data.paymentDay, 'd');
                    var addMonth = moment(addDay).add(1, 'M');
                    var paymentDateNextMonth = moment(addMonth).format("DD-MM-YYYY");
                    if (Boolean(paymentDateNextMonth) && paymentDateNextMonth != null && paymentDateNextMonth != undefined && paymentDateNextMonth != "Invalid date") {
                      return paymentDateNextMonth;
                    }
                  }
                } catch (error) {
                }
              }
            }
            else if (data.businessTrip == false && data.businessTripNextMonth == false && data.businessTripDate != null && data.businessTripDate != undefined) {
              if (Boolean(data.businessTripDate)) {
                return moment(data.businessTripDate).format("DD-MM-YYYY HH:mm")
              }
            }
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="SalarySettingOtherPayments" defaultMessage="Other Payments" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.otherPayments) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var paymentDate = moment(addDate).format("DD-MM-YYYY");
                    if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                      return paymentDate;
                    }
                  }
                } catch (error) {
                }
              }
            } else if (data.otherPaymentsNextMonth) {
              if (data.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDate = lastPeriodDay.add(data.paymentDay, 'd');
                    var day = addDate.format('dddd');
                    if (day == "Friday") {
                      addDate = lastPeriodDay.add(2, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else if (day == "Saturday") {
                      addDate = lastPeriodDay.add(1, 'd');
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    } else {
                      var paymentDate = moment(addDate).format("DD-MM-YYYY");
                      if (Boolean(paymentDate) && paymentDate != null && paymentDate != undefined && paymentDate != "Invalid date") {
                        return paymentDate;
                      }
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(data.year + "-" + data.month + "-" + data.lastDay);
                  if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                    var addDay = lastPeriodDay.add(data.paymentDay, 'd');
                    var addMonth = moment(addDay).add(1, 'M');
                    var paymentDateNextMonth = moment(addMonth).format("DD-MM-YYYY");
                    if (Boolean(paymentDateNextMonth) && paymentDateNextMonth != null && paymentDateNextMonth != undefined && paymentDateNextMonth != "Invalid date") {
                      return paymentDateNextMonth;
                    }
                  }
                } catch (error) {
                }
              }
            }
            else if (data.otherPayments == false && data.otherPaymentsNextMonth == false && data.otherPaymentsDate != null && data.otherPaymentsDate != undefined) {
              if (Boolean(data.otherPaymentsDate)) {
                return moment(data.otherPaymentsDate).format("DD-MM-YYYY HH:mm")
              }
            }
          }
          return "";
        }
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      this.showWarning();
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateSalarySetting : this.saveSalarySetting}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("SalarySetting")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />

              {/* {(itemDateAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                  name="Date"
                  rules={[{ required: itemDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateRequired.translateRequired) == true ? itemDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                  <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                    this.setState({
                      date: value
                    })
                  }} value={this.state.date}
                    format="DD-MM-YYYY HH:mm"
                  />
                </Form.Item>} */}

              {(itemYearAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingYear" defaultMessage="Year" />}
                  name="Year"
                  rules={[{ required: itemYearRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemYearRequired.translateRequired) == true ? itemYearRequired.translateRequired : -1} defaultMessage="Please input your Year!" /> }]} >
                  <DatePicker value={this.state.year} disabledDate={this.disabledDate} onChange={this.onYear} picker="year" />
                </Form.Item>}

              {(itemMonthAttributes.visible && this.state.hiddenSave == false) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingMonth" defaultMessage="Month" />}
                  name="Month"
                  rules={[{ required: itemMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMonthRequired.translateRequired) == true ? itemMonthRequired.translateRequired : -1} defaultMessage="Please input your Month!" /> }]} >
                  <Select size='middle' mode="multiple"
                    style={{ width: '100%' }}
                    value={this.state.month}
                    onChange={this.onMonth}
                  >

                    {this.state.months.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    ))}
                </Select>
                </Form.Item>}

              {(itemMonthAttributes.visible && this.state.hiddenUpdate == false) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingMonth" defaultMessage="Month" />}
                  name="Month"
                  rules={[{ required: itemMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMonthRequired.translateRequired) == true ? itemMonthRequired.translateRequired : -1} defaultMessage="Please input your Month!" /> }]} >
                  <Select size='middle' mode="single"
                    style={{ width: '100%' }}
                    value={this.state.month}
                    onChange={this.onMonth}
                  >
                    {this.state.months.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                    ))}
                </Select>
                </Form.Item>}

              {(itemPeriodAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingPeriod" defaultMessage="Period" />}
                  name="Period"
                  rules={[{ required: itemPeriodRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPeriodRequired.translateRequired) == true ? itemPeriodRequired.translateRequired : -1} defaultMessage="Please input your Period!" /> }]} >
                  <Radio.Group onChange={(e) => {
                    var salaryPeriodMax = 0;
                    if (e.target.value == 1) {
                      salaryPeriodMax = 31;
                    } else if (e.target.value == 2) {
                      salaryPeriodMax = 7;
                    } else if (e.target.value == 3) {
                      salaryPeriodMax = 1;
                    }
                    this.setState({
                      period: e.target.value,
                      salaryPeriodMax: salaryPeriodMax,
                    });
                  }}
                    value={this.state.period}>
                    <Radio value={1}><FormattedMessage id="SalarySettingMonthly" defaultMessage="Monthly" /></Radio>
                    <Radio value={2}><FormattedMessage id="SalarySettingWeekly" defaultMessage="Weekly" /></Radio>
                    <Radio value={3}><FormattedMessage id="SalarySettingDaily" defaultMessage="Daily" /></Radio>
                  </Radio.Group>
                </Form.Item>}

              {(itemWholePeriodAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingSalaryPeriod" defaultMessage="Salary Period" />}
                  name="WholePeriod"
                  rules={[{ required: itemWholePeriodRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemWholePeriodRequired.translateRequired) == true ? itemWholePeriodRequired.translateRequired : -1} defaultMessage="Please input your Salary Period!" /> }]} >
                  <Checkbox checked={this.state.wholePeriod} onChange={(e) => {
                    if (e.target.checked) {
                      this.setState({
                        firstDay: 1,
                        lastDay: 1,
                      });
                    }
                    this.setState({
                      wholePeriod: e.target.checked,
                    });
                  }}><FormattedMessage id="SalarySettingBetweenFirstAndLastDayOfPeriod" defaultMessage="Between first and last day of month" /></Checkbox>
                </Form.Item>}

              {(itemSalaryPeriodAttributes.visible) &&
                <div hidden={this.state.wholePeriod}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="2">
                      <InputNumber min={1} max={this.state.salaryPeriodMax} style={{ width: '100%' }}
                        onChange={(e) => {
                          if (e == null || e == undefined || e == "") {
                            e = 1;
                          }
                          this.setState({ firstDay: e })
                        }} value={this.state.firstDay} />
                    </Col>
                    <Col sm="2">
                      <InputNumber min={1} max={this.state.salaryPeriodMax} style={{ width: '100%' }}
                        onChange={(e) => {
                          if (e == null || e == undefined || e == "") {
                            e = 1;
                          }
                          this.setState({ lastDay: e });
                        }} value={this.state.lastDay} />
                    </Col>
                  </FormBoot.Group>
                </div>
              }

              <RowAnt gutter={8}>
                <ColAnt span={3}></ColAnt>
                <ColAnt span={12}>
                  {(itemPaymentDayAttributes.visible) &&
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="SalarySettingPaymentDay" defaultMessage="Salary Day" />}
                      name="PaymentDay"
                      rules={[{ required: itemPaymentDayRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPaymentDayRequired.translateRequired) == true ? itemPaymentDayRequired.translateRequired : -1} defaultMessage="Please input your Salary Day!" /> }]} >
                      <InputNumber min={0} max={this.state.salaryPeriodMax} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ paymentDay: e })} value={this.state.paymentDay} />
                    </Form.Item>}
                </ColAnt>
                <ColAnt span={6}>
                  days from last working day of period
                </ColAnt>
              </RowAnt>

              <RowAnt gutter={8}>
                <ColAnt span={6}></ColAnt>
                <ColAnt span={10}>
                  {
                    (itemPaymentInFinancialDayAttributes.visible) &&
                    <Form.Item
                      {...layout}
                      // label={<FormattedMessage id="SalarySettingInFirstFiscalDate" defaultMessage="In first fiscal date" />}
                      name="PaymentInFinancialDay"
                      rules={[{ required: itemPaymentInFinancialDayRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPaymentInFinancialDayRequired.translateRequired) == true ? itemPaymentInFinancialDayRequired.translateRequired : -1} defaultMessage="Please input your In first fiscal date!" /> }]} >
                      <Checkbox checked={this.state.paymentInFinancialDay} onChange={(e) => {
                        var knt = true;
                        var strMessage = "";
                        if (e.target.checked) {   //Tarrihler kontrol edilir, Cuma ve Cumartesi seçili olan varsa izin verilmez, Öncelikle onları düzeltmesi istenir.
                          var homeAllowanceDate = moment(this.state.homeAllowanceDate);
                          if (Boolean(homeAllowanceDate) && homeAllowanceDate != null && homeAllowanceDate != undefined && homeAllowanceDate != "Invalid date") {
                            var day = homeAllowanceDate.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              knt = false;
                              strMessage += "Home Allowance Date, ";
                            }
                          }

                          var transportationAllowanceDate = moment(this.state.transportationAllowanceDate);
                          if (Boolean(transportationAllowanceDate) && transportationAllowanceDate != null && transportationAllowanceDate != undefined && transportationAllowanceDate != "Invalid date") {
                            var day = transportationAllowanceDate.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              knt = false;
                              strMessage += "Transportation Allowance Date, ";
                            }
                          }

                          var foodAllowanceDate = moment(this.state.foodAllowanceDate);
                          if (Boolean(foodAllowanceDate) && foodAllowanceDate != null && foodAllowanceDate != undefined && foodAllowanceDate != "Invalid date") {
                            var day = foodAllowanceDate.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              knt = false;
                              strMessage += "Food Allowance Date, ";
                            }
                          }

                          var overtimeDate = moment(this.state.overtimeDate);
                          if (Boolean(overtimeDate) && overtimeDate != null && overtimeDate != undefined && overtimeDate != "Invalid date") {
                            var day = overtimeDate.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              knt = false;
                              strMessage += "Overtime Date, ";
                            }
                          }

                          var businessTripDate = moment(this.state.businessTripDate);
                          if (Boolean(businessTripDate) && businessTripDate != null && businessTripDate != undefined && businessTripDate != "Invalid date") {
                            var day = businessTripDate.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              knt = false;
                              strMessage += "Business Trip Date, ";
                            }
                          }

                          var otherPaymentsDate = moment(this.state.otherPaymentsDate);
                          if (Boolean(otherPaymentsDate) && otherPaymentsDate != null && otherPaymentsDate != undefined && otherPaymentsDate != "Invalid date") {
                            var day = otherPaymentsDate.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              knt = false;
                              strMessage += "Other Payments Date, ";
                            }
                          }
                        }
                        if (knt) {
                          this.setState({
                            paymentInFinancialDay: e.target.checked,
                          });
                        } else {
                          strMessage = strMessage.substring(0, strMessage.length - 2);
                          strMessage += " is not valid. It cannot be Friday or Saturday.";
                          this.showWarning(strMessage);
                        }
                      }}><FormattedMessage id="SalarySettingInFirstFiscalDate" defaultMessage="In first fiscal date" /></Checkbox>
                    </Form.Item>}
                </ColAnt>
              </RowAnt>

              {(itemHomeAllowanceAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingHomeAllowance" defaultMessage="Home Allowance" />}
                  name="HomeAllowance"
                  rules={[{ required: itemHomeAllowanceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeAllowanceRequired.translateRequired) == true ? itemHomeAllowanceRequired.translateRequired : -1} defaultMessage="Please input your Home Allowance!" /> }]} >
                  <Switch checkedChildren={<FormattedMessage id="SalarySettingIncluded" defaultMessage="Included" />} unCheckedChildren={<FormattedMessage id="SalarySettingNotIncluded" defaultMessage="Not Included" />} checked={this.state.homeAllowance} defaultChecked
                    onChange={(value) => {
                      var hiddenHomeAllowanceCheck = true;
                      var homeAllowanceNextMonth = false;
                      if (value == false) {
                        hiddenHomeAllowanceCheck = false;
                        homeAllowanceNextMonth = true;
                      } else {
                        this.setState({
                          hiddenHomeAllowanceDate: true,
                          homeAllowanceDate: null,
                        });
                        this.formRef.current.setFieldsValue({
                          HomeAllowanceDate: "",
                        });
                      }
                      this.setState({
                        homeAllowance: value,
                        hiddenHomeAllowanceCheck: hiddenHomeAllowanceCheck,
                        homeAllowanceNextMonth: homeAllowanceNextMonth,
                      });
                    }} />
                </Form.Item>}

              {(itemHomeAllowanceNextMonthAttributes.visible) &&
                <div hidden={this.state.hiddenHomeAllowanceCheck}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" />}
                    name="HomeAllowanceNextMonth"
                    rules={[{ required: itemHomeAllowanceNextMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeAllowanceNextMonthRequired.translateRequired) == true ? itemHomeAllowanceNextMonthRequired.translateRequired : -1} defaultMessage="Please input your With following month salary!" /> }]} >
                    <Checkbox checked={this.state.homeAllowanceNextMonth} onChange={(e) => {
                      var hiddenHomeAllowanceDate = true;
                      if (e.target.checked == false) {
                        hiddenHomeAllowanceDate = false;
                      }
                      this.setState({
                        homeAllowanceNextMonth: e.target.checked,
                        hiddenHomeAllowanceDate: hiddenHomeAllowanceDate,
                      });
                    }}><FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" /></Checkbox>
                  </Form.Item>
                </div>
              }

              {(itemHomeAllowanceDateAttributes.visible) &&
                <div hidden={this.state.hiddenHomeAllowanceDate}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                    name="HomeAllowanceDate"
                    rules={[{ required: itemHomeAllowanceDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemHomeAllowanceDateRequired.translateRequired) == true ? itemHomeAllowanceDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                      console.log("XXX", value);
                      if (this.state.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı
                        try {
                          var lastPeriodDay = moment(value);
                          if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                            var day = lastPeriodDay.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              this.showWarning("You cannot select this date because the financial working days option is selected.");
                              this.formRef.current.setFieldsValue({
                                HomeAllowanceDate: "",
                              });
                              this.setState({
                                homeAllowanceDate: null,
                              });
                            } else {
                              this.setState({
                                homeAllowanceDate: value
                              });
                            }
                          } else {
                            this.formRef.current.setFieldsValue({
                              HomeAllowanceDate: "",
                            });
                            this.setState({
                              homeAllowanceDate: null,
                            });
                          }
                        } catch (error) {
                          this.showError();
                        }
                      } else {
                        this.setState({
                          homeAllowanceDate: value
                        });
                      }
                    }} value={this.state.homeAllowanceDate}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              }

              {(itemTransportationAllowanceAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingTransportationAllowance" defaultMessage="Transportation Allowance" />}
                  name="TransportationAllowance"
                  rules={[{ required: itemTransportationAllowanceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTransportationAllowanceRequired.translateRequired) == true ? itemTransportationAllowanceRequired.translateRequired : -1} defaultMessage="Please input your Transportation Allowance!" /> }]} >
                  <Switch checkedChildren={<FormattedMessage id="SalarySettingIncluded" defaultMessage="Included" />} unCheckedChildren={<FormattedMessage id="SalarySettingNotIncluded" defaultMessage="Not Included" />} checked={this.state.transportationAllowance} defaultChecked
                    onChange={(value) => {
                      var hiddenTransportationAllowanceCheck = true;
                      var transportationAllowanceNextMonth = false;
                      if (value == false) {
                        hiddenTransportationAllowanceCheck = false;
                        transportationAllowanceNextMonth = true;
                      } else {
                        this.setState({
                          hiddenTransportationAllowanceDate: true,
                          transportationAllowanceDate: null,
                        });
                        this.formRef.current.setFieldsValue({
                          TransportationAllowanceDate: "",
                        });
                      }
                      this.setState({
                        transportationAllowance: value,
                        hiddenTransportationAllowanceCheck: hiddenTransportationAllowanceCheck,
                        transportationAllowanceNextMonth: transportationAllowanceNextMonth,
                      });
                    }} />
                </Form.Item>}

              {(itemTransportationAllowanceNextMonthAttributes.visible) &&
                <div hidden={this.state.hiddenTransportationAllowanceCheck}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" />}
                    name="TransportationAllowanceNextMonth"
                    rules={[{ required: itemTransportationAllowanceNextMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTransportationAllowanceNextMonthRequired.translateRequired) == true ? itemTransportationAllowanceNextMonthRequired.translateRequired : -1} defaultMessage="Please input your With following month salary!" /> }]} >
                    <Checkbox checked={this.state.transportationAllowanceNextMonth} onChange={(e) => {
                      var hiddenTransportationAllowanceDate = true;
                      if (e.target.checked == false) {
                        hiddenTransportationAllowanceDate = false;
                      }
                      this.setState({
                        transportationAllowanceNextMonth: e.target.checked,
                        hiddenTransportationAllowanceDate: hiddenTransportationAllowanceDate,
                      });
                    }}><FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" /></Checkbox>
                  </Form.Item>
                </div>
              }

              {(itemTransportationAllowanceDateAttributes.visible) &&
                <div hidden={this.state.hiddenTransportationAllowanceDate}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                    name="TransportationAllowanceDate"
                    rules={[{ required: itemTransportationAllowanceDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTransportationAllowanceDateRequired.translateRequired) == true ? itemTransportationAllowanceDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                      if (this.state.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı
                        try {
                          var lastPeriodDay = moment(value);
                          if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                            var day = lastPeriodDay.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              this.showWarning("You cannot select this date because the financial working days option is selected.");
                              this.formRef.current.setFieldsValue({
                                TransportationAllowanceDate: "",
                              });
                              this.setState({
                                transportationAllowanceDate: null,
                              });
                            } else {
                              this.setState({
                                transportationAllowanceDate: value
                              });
                            }
                          }
                        } catch (error) {
                          this.showError();
                        }
                      } else {
                        this.setState({
                          transportationAllowanceDate: value
                        });
                      }
                    }} value={this.state.transportationAllowanceDate}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              }

              {(itemFoodAllowanceAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingFoodAllowance" defaultMessage="Food Allowance" />}
                  name="FoodAllowance"
                  rules={[{ required: itemFoodAllowanceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemFoodAllowanceRequired.translateRequired) == true ? itemFoodAllowanceRequired.translateRequired : -1} defaultMessage="Please input your Food Allowance!" /> }]} >
                  <Switch checkedChildren={<FormattedMessage id="SalarySettingIncluded" defaultMessage="Included" />} unCheckedChildren={<FormattedMessage id="SalarySettingNotIncluded" defaultMessage="Not Included" />} checked={this.state.foodAllowance} defaultChecked
                    onChange={(value) => {
                      var hiddenFoodAllowanceCheck = true;
                      var foodAllowanceNextMonth = false;
                      if (value == false) {
                        hiddenFoodAllowanceCheck = false;
                        foodAllowanceNextMonth = true;
                      } else {
                        this.setState({
                          hiddenFoodAllowanceDate: true,
                          foodAllowanceDate: null,
                        });
                        this.formRef.current.setFieldsValue({
                          FoodAllowanceDate: "",
                        });
                      }
                      this.setState({
                        foodAllowance: value,
                        hiddenFoodAllowanceCheck: hiddenFoodAllowanceCheck,
                        foodAllowanceNextMonth: foodAllowanceNextMonth,
                      });
                    }} />
                </Form.Item>}

              {(itemFoodAllowanceNextMonthAttributes.visible) &&
                <div hidden={this.state.hiddenFoodAllowanceCheck}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" />}
                    name="FoodAllowanceNextMonth"
                    rules={[{ required: itemFoodAllowanceNextMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemFoodAllowanceNextMonthRequired.translateRequired) == true ? itemFoodAllowanceNextMonthRequired.translateRequired : -1} defaultMessage="Please input your With following month salary!" /> }]} >
                    <Checkbox checked={this.state.foodAllowanceNextMonth} onChange={(e) => {
                      var hiddenFoodAllowanceDate = true;
                      if (e.target.checked == false) {
                        hiddenFoodAllowanceDate = false;
                      }
                      this.setState({
                        foodAllowanceNextMonth: e.target.checked,
                        hiddenFoodAllowanceDate: hiddenFoodAllowanceDate,
                      });
                    }}><FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" /></Checkbox>
                  </Form.Item>
                </div>
              }

              {(itemFoodAllowanceDateAttributes.visible) &&
                <div hidden={this.state.hiddenFoodAllowanceDate}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                    name="FoodAllowanceDate"
                    rules={[{ required: itemFoodAllowanceDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemFoodAllowanceDateRequired.translateRequired) == true ? itemFoodAllowanceDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                      if (this.state.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı
                        try {
                          var lastPeriodDay = moment(value);
                          if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                            var day = lastPeriodDay.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              this.showWarning("You cannot select this date because the financial working days option is selected.");
                              this.formRef.current.setFieldsValue({
                                FoodAllowanceDate: "",
                              });
                              this.setState({
                                foodAllowanceDate: null,
                              });
                            } else {
                              this.setState({
                                foodAllowanceDate: value
                              });
                            }
                          }
                        } catch (error) {
                          this.showError();
                        }
                      } else {
                        this.setState({
                          foodAllowanceDate: value
                        });
                      }
                    }} value={this.state.foodAllowanceDate}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              }

              {(itemOvertimeAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingOvertime" defaultMessage="Overtime" />}
                  name="Overtime"
                  rules={[{ required: itemOvertimeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOvertimeRequired.translateRequired) == true ? itemOvertimeRequired.translateRequired : -1} defaultMessage="Please input your Overtime!" /> }]} >
                  <Switch checkedChildren={<FormattedMessage id="SalarySettingIncluded" defaultMessage="Included" />} unCheckedChildren={<FormattedMessage id="SalarySettingNotIncluded" defaultMessage="Not Included" />} checked={this.state.overtime} defaultChecked
                    onChange={(value) => {
                      var hiddenOvertimeCheck = true;
                      var overtimeNextMonth = false;
                      if (value == false) {
                        hiddenOvertimeCheck = false;
                        overtimeNextMonth = true;
                      } else {
                        this.setState({
                          hiddenOvertimeDate: true,
                          overtimeDate: null,
                        });
                        this.formRef.current.setFieldsValue({
                          OvertimeDate: "",
                        });
                      }
                      this.setState({
                        overtime: value,
                        hiddenOvertimeCheck: hiddenOvertimeCheck,
                        overtimeNextMonth: overtimeNextMonth,
                      });
                    }} />
                </Form.Item>}

              {(itemOvertimeNextMonthAttributes.visible) &&
                <div hidden={this.state.hiddenOvertimeCheck}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" />}
                    name="OvertimeNextMonth"
                    rules={[{ required: itemOvertimeNextMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOvertimeNextMonthRequired.translateRequired) == true ? itemOvertimeNextMonthRequired.translateRequired : -1} defaultMessage="Please input your With following month salary!" /> }]} >
                    <Checkbox checked={this.state.overtimeNextMonth} onChange={(e) => {
                      var hiddenOvertimeDate = true;
                      if (e.target.checked == false) {
                        hiddenOvertimeDate = false;
                      }
                      this.setState({
                        overtimeNextMonth: e.target.checked,
                        hiddenOvertimeDate: hiddenOvertimeDate,
                      });
                    }}><FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" /></Checkbox>
                  </Form.Item>
                </div>
              }

              {(itemOvertimeDateAttributes.visible) &&
                <div hidden={this.state.hiddenOvertimeDate}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                    name="OvertimeDate"
                    rules={[{ required: itemOvertimeDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOvertimeDateRequired.translateRequired) == true ? itemOvertimeDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                      if (this.state.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı
                        try {
                          var lastPeriodDay = moment(value);
                          if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                            var day = lastPeriodDay.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              this.showWarning("You cannot select this date because the financial working days option is selected.");
                              this.formRef.current.setFieldsValue({
                                OvertimeDate: "",
                              });
                              this.setState({
                                overtimeDate: null,
                              });
                            } else {
                              this.setState({
                                overtimeDate: value
                              });
                            }
                          }
                        } catch (error) {
                          this.showError();
                        }
                      } else {
                        this.setState({
                          overtimeDate: value
                        });
                      }
                    }} value={this.state.overtimeDate}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              }

              {(itemBusinessTripAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingBusinessTrip" defaultMessage="Business trip" />}
                  name="BusinessTrip"
                  rules={[{ required: itemBusinessTripRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBusinessTripRequired.translateRequired) == true ? itemBusinessTripRequired.translateRequired : -1} defaultMessage="Please input your Business trip!" /> }]} >
                  <Switch checkedChildren={<FormattedMessage id="SalarySettingIncluded" defaultMessage="Included" />} unCheckedChildren={<FormattedMessage id="SalarySettingNotIncluded" defaultMessage="Not Included" />} checked={this.state.businessTrip} defaultChecked
                    onChange={(value) => {
                      var hiddenBusinessTripCheck = true;
                      var businessTripNextMonth = false;
                      if (value == false) {
                        hiddenBusinessTripCheck = false;
                        businessTripNextMonth = true;
                      } else {
                        this.setState({
                          hiddenBusinessTripDate: true,
                          businessTripDate: null,
                        });
                        this.formRef.current.setFieldsValue({
                          BusinessTripDate: "",
                        });
                      }
                      this.setState({
                        businessTrip: value,
                        hiddenBusinessTripCheck: hiddenBusinessTripCheck,
                        businessTripNextMonth: businessTripNextMonth,
                      });
                    }} />
                </Form.Item>}

              {(itemBusinessTripNextMonthAttributes.visible) &&
                <div hidden={this.state.hiddenBusinessTripCheck}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" />}
                    name="BusinessTripNextMonth"
                    rules={[{ required: itemBusinessTripNextMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBusinessTripNextMonthRequired.translateRequired) == true ? itemBusinessTripNextMonthRequired.translateRequired : -1} defaultMessage="Please input your With following month salary!" /> }]} >
                    <Checkbox checked={this.state.businessTripNextMonth} onChange={(e) => {
                      var hiddenBusinessTripDate = true;
                      if (e.target.checked == false) {
                        hiddenBusinessTripDate = false;
                      }
                      this.setState({
                        businessTripNextMonth: e.target.checked,
                        hiddenBusinessTripDate: hiddenBusinessTripDate,
                      });
                    }}><FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" /></Checkbox>
                  </Form.Item>
                </div>
              }

              {(itemBusinessTripDateAttributes.visible) &&
                <div hidden={this.state.hiddenBusinessTripDate}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                    name="BusinessTripDate"
                    rules={[{ required: itemBusinessTripDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemBusinessTripDateRequired.translateRequired) == true ? itemBusinessTripDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                      if (this.state.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı
                        try {
                          var lastPeriodDay = moment(value);
                          if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                            var day = lastPeriodDay.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              this.showWarning("You cannot select this date because the financial working days option is selected.");
                              this.formRef.current.setFieldsValue({
                                BusinessTripDate: "",
                              });
                              this.setState({
                                businessTripDate: null,
                              });
                            } else {
                              this.setState({
                                businessTripDate: value
                              });
                            }
                          }
                        } catch (error) {
                          this.showError();
                        }
                      } else {
                        this.setState({
                          businessTripDate: value
                        });
                      }
                    }} value={this.state.businessTripDate}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              }

              {(itemOtherPaymentsAttributes.visible) &&
                <Form.Item
                  {...layout}
                  label={<FormattedMessage id="SalarySettingOtherPayments" defaultMessage="Other Payments" />}
                  name="OtherPayments"
                  rules={[{ required: itemOtherPaymentsRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOtherPaymentsRequired.translateRequired) == true ? itemOtherPaymentsRequired.translateRequired : -1} defaultMessage="Please input your Other Payments!" /> }]} >
                  <Switch checkedChildren={<FormattedMessage id="SalarySettingIncluded" defaultMessage="Included" />} unCheckedChildren={<FormattedMessage id="SalarySettingNotIncluded" defaultMessage="Not Included" />} checked={this.state.otherPayments} defaultChecked
                    onChange={(value) => {
                      var hiddenOtherPaymentsCheck = true;
                      var otherPaymentsNextMonth = false;
                      if (value == false) {
                        hiddenOtherPaymentsCheck = false;
                        otherPaymentsNextMonth = true;
                      } else {
                        this.setState({
                          hiddenOtherPaymentsDate: true,
                          otherPaymentsDate: null,
                        });
                        this.formRef.current.setFieldsValue({
                          OtherPaymentsDate: "",
                        });
                      }
                      this.setState({
                        otherPayments: value,
                        hiddenOtherPaymentsCheck: hiddenOtherPaymentsCheck,
                        otherPaymentsNextMonth: otherPaymentsNextMonth,
                      });
                    }} />
                </Form.Item>}

              {(itemOtherPaymentsNextMonthAttributes.visible) &&
                <div hidden={this.state.hiddenOtherPaymentsCheck}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" />}
                    name="OtherPaymentsNextMonth"
                    rules={[{ required: itemOtherPaymentsNextMonthRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOtherPaymentsNextMonthRequired.translateRequired) == true ? itemOtherPaymentsNextMonthRequired.translateRequired : -1} defaultMessage="Please input your With following month salary!" /> }]} >
                    <Checkbox checked={this.state.otherPaymentsNextMonth} onChange={(e) => {
                      var hiddenOtherPaymentsDate = true;
                      if (e.target.checked == false) {
                        hiddenOtherPaymentsDate = false;
                      }
                      this.setState({
                        otherPaymentsNextMonth: e.target.checked,
                        hiddenOtherPaymentsDate: hiddenOtherPaymentsDate,
                      });
                    }}><FormattedMessage id="SalarySettingWithFollowingMonthSalary" defaultMessage="With following month salary" /></Checkbox>
                  </Form.Item>
                </div>
              }

              {(itemOtherPaymentsDateAttributes.visible) &&
                <div hidden={this.state.hiddenOtherPaymentsDate}>
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="SalarySettingDate" defaultMessage="Date" />}
                    name="OtherPaymentsDate"
                    rules={[{ required: itemOtherPaymentsDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemOtherPaymentsDateRequired.translateRequired) == true ? itemOtherPaymentsDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} showTime onChange={(value) => {
                      if (this.state.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı
                        try {
                          var lastPeriodDay = moment(value);
                          if (Boolean(lastPeriodDay) && lastPeriodDay != null && lastPeriodDay != undefined && lastPeriodDay != "Invalid date") {
                            var day = lastPeriodDay.format('dddd');
                            if (day == "Friday" || day == "Saturday") {
                              this.showWarning("You cannot select this date because the financial working days option is selected.");
                              this.formRef.current.setFieldsValue({
                                OtherPaymentsDate: "",
                              });
                              this.setState({
                                otherPaymentsDate: null,
                              });
                            } else {
                              this.setState({
                                otherPaymentsDate: value
                              });
                            }
                          }
                        } catch (error) {
                          this.showError();
                        }
                      } else {
                        this.setState({
                          otherPaymentsDate: value
                        });
                      }
                    }} value={this.state.otherPaymentsDate}
                      format="DD-MM-YYYY HH:mm"
                    />
                  </Form.Item>
                </div>
              }

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="5">
                      <Button id="SalarySettingSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button id="SalarySettingCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="3">
                      <Button id="SalarySettingUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.salarySettingsTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>}

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteSalarySettingOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SalarySetting)