/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import 'antd/es/cascader/style/index.css';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Row } from 'react-bootstrap';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { Steps } from 'antd';
import { Progress } from 'antd';
import { RightCircleOutlined, HistoryOutlined, InfoOutlined, ReadOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Toast } from 'primereact/toast';
import { Card } from 'react-bootstrap';
import { Upload, Button as ButtonA, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Modal } from 'antd';
import { Table, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Step } = Steps;
const { Column } = Table;

function onChange(value) {
    console.log(value);
}

function setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
        var dt = new Date(d);
        return ('' + dt.getDate().toString().padStart(2, '0') + '/' + (dt.getMonth() + 1).toString().padStart(2, '0') + '/' + dt.getFullYear().toString().padStart(4, '0'));
    } else return '';
}

export class WFStart extends Component {

    constructor() {
        super();
        this.state = {
            justification: '',
            isJustification: false,
            comment: '',
            isComment: false,
            uploadFilePath: '',
            downloadFilePath: '',

            startModalHidden: false,
            preparationModalHidden: false,
            informativeModalHidden: false,
            reviewModalHidden: false,
            confirmativeModalHidden: false,

            current: 0,
            action: "",
            document: "",
            deadline: "",
            remindme: false,
            reminderDay: "",
            period: "",

            chkCommentPreparation: false,
            commentPreparation: "",

            chkCommentInformative: false,
            commentInformative: "",

            chkJustificationReview: false,
            justificationReview: "",

            chkJustificationConfirmation: false,
            justificationConfirmation: "",

            hiddenRemindme: true,
            hiddenCommentPreparation: true,
            hiddenCommentInformative: true,
            hiddenJustificationReview: true,
            hiddenJustificationConfirmation: true,


            workFlowStep: [],

            workflowId: 0,
            workflowPeriodId:0,
            visible:false,
            historyStepList:[],


        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);



    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
    };

    showError(msg) {
        if (!Boolean(msg)) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };

    componentDidMount = () => {
        this.getWorkFlowStep();
    };

    componentWillMount = () => {
        const { data } = this.props.location;
        console.log("props data: " + data);

        if (Boolean(data)) {
            console.log("workflow data: " + data.workFlowName);
            console.log("workflow id: " + data.id);
            console.log("workflow pId: " + data.periodId);
            
            this.setState({
                workflowId: data.id,
                workflowPeriodId:data.periodId, 
            });
        }
        var pathArray = this.props.location.pathname.split('/');
        console.log(pathArray);
        if (Boolean(pathArray) && pathArray.length >= 5) {

            this.setState({
                workflowId: pathArray[2],
                workflowPeriodId: pathArray[3],
                workflowStepId: pathArray[4] 
            });
        }
    }

    getWorkFlowStep = async () => {
        const response = await handleRequest("GET", "/WorkFlowStep/getAll");
        if (response.data.length !== 0) {
            var steps = response.data.filter(p => p.workFlowId == this.state.workflowId);
            var index = steps.findIndex(p => p.id == this.state.workflowStepId);
            if (index === -1)
                index = 0;
            this.setState({ workFlowStep: steps, current: index });


        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    };

    handleModalHide = async () => {
        this.setState({
            startModalHidden: true,
            preparationModalHidden: true,
            informativeModalHidden: true,
            reviewModalHidden: true,
            confirmativeModalHidden: true,
        });
    }

    onStepChange(value) {
        this.setState({ current: value });

        console.log(value);
    }



    downloadFile = async (row) => {

        window.location.href = `${API_BASE}/WorkFlowStepSummary/download/` + row.id; // this.state.workFlowStep[this.state.current];

    }


    onChangeUpload = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    fileName: info.file.name,
                    filePath: info.file.response[0]
                })
            }

        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    doPost = async () => {


        const newItem = {
            workflowId: this.state.workflowId,
            workflowPeriodId:this.state.workflowPeriodId,
            workflowStepId: this.state.workFlowStep[this.state.current].id,
            comment: this.state.comment,
            justification: this.state.justification,
            filePath: this.state.filePath

        }

        console.log(newItem);

        var response = await await handleRequest("POST", "/WorkFlowStepSummary/confirm", newItem);

        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS-001")// Buradaki mesaj aynı standartta backenddeki değere göre güncellenmeli.
            {

                this.showSuccess();
            }
            if (response.data === "ERROR-WORKFLOWINPROCESS") {
                this.showError("This workflow did not completed yet. It is in progress.");
            }
            if (response.data === "ERROR-NOTAUTHORIZED") {
                this.showError("You are not authorized for this action !");
            }

            if (response.data === "ERROR-NOTCOMPLETED") {
                this.showError("The previous step not completed yet!");
            }

            if (response.data === "ERROR-001") {
                this.showError("An error was occured. Please try again later !");
            } 

            if (response.data === "ERROR-POSTED-BEFORE") { 
                this.showError("You posted before this step!");
            } 
        }
        else this.showError("An error was occured. Please try again later !");



    }

    doCorrect = async () => {

        const newItem = {
            workflowId: this.state.workflowId,
            workflowPeriodId:this.state.workflowPeriodId,
            workflowStepId: this.state.workFlowStep[this.state.current].id,
            comment: this.state.comment,
            justification: this.state.justification

        }

        console.log(newItem);

        var response = await await handleRequest("POST", "/WorkFlowStepSummary/correct", newItem);


        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS-001")// Buradaki mesaj aynı standartta backenddeki değere göre güncellenmeli.
            {

                this.showSuccess();
            }

            if (response.data === "ERROR-001") {
                this.showError("An error was occured. Please try again later !");
            }
            
            if (response.data === "ERROR-WORKFLOWINPROCESS") {
                this.showError("This workflow did not completed yet. It is in progress.");
            }
            if (response.data === "ERROR-NOTAUTHORIZED") {
                this.showError("You are not authorized for this action !");
            }

            if (response.data === "ERROR-NOTCOMPLETED") {
                this.showError("The previous step not completed yet!");
            }
           
            if (response.data === "ERROR-POSTED-BEFORE") { 
                this.showError("You posted before this step!");
            } 
        }
        else this.showError("An error was occured. Please try again later !");

    }

    onOk = async () => {

        this.setState({
          visible:false 
        })
    
      }

      getHistory = async () => {
        if(Boolean(this.state.workflowId)){
    
            const newItem = {
           
                workflowId: this.state.workflowId,
                workflowPeriodId:this.state.workflowPeriodId 
      
          }   
      
         var response = await handleRequest("POST", "/WorkFlowStepSummary/history", newItem);
          if(Boolean(response.data)) {
            this.setState({
                historyStepList:response.data 
              })
          } 

         
 
          this.setState({
            visible:true 
          })
      
          }
          else  message.error(`Please select workflow !`);
      }

      hideModal = () => {

        this.setState({visible:false})
      }

    render() {
        const { current } = this.state;

        return (

           

            <div style={{ backgroundColor: "white", padding: "10px" }}>
                 <Modal
                title="History of Workflow"
                visible={this.state.visible} 
                onOk={this.onOk}
                onCancel={this.hideModal}
                icon={<ExclamationCircleOutlined />}
                okText="OK"
                width={900} 
            >
                {this.state.historyStepList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.historyStepList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                    <Column title="S/N" dataIndex="index" />
                    <Column title="Step Name" render={(value, row, index) => {
                        if (Boolean(row.step))
                            return row.step.actiontype;
                        else return "";

                    }} />
                    <Column title="Comments" dataIndex="comment" />
                    <Column title="Justifications" dataIndex="justification" />
                    <Column title="File" render={(value, row, index) => {
                        if (Boolean(row.filePath))
                            return <ButtonA style={{ marginLeft: '2px', alignContent: 'center' }} onClick={() => this.downloadFile(row)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                Download
                                </ButtonA>; 
                        else return "";

                    }} />
                    <Column title="Action Time" render={(value, row, index) => {
                        if (Boolean(row.processTime ))
                        return moment(row.processTime).format('DD/MM/YYYY HH:mm')
                      
                    return "";  

                    }} />
                     <Column title="Action" render={(value, row, index) => {
                        if (row.status === 1)
                       return <Tag color={'green'}
                        > Confirm and Post
                      </Tag> ;   
                         if (row.status === -1)
                         return <Tag color={'volcano'}
                          >Correct ond Resubmit
                        </Tag> ;   
                    
                    return ""; 

                    }} />
                    <Column title="Action By" render={(value, row, index) => {
                        if (Boolean(row.user) && Boolean(row.user.staff)) 
                        return row.user.staff.name + ' ' + row.user.staff.midName + ' '+ row.user.staff.lastName ;
                      
                    return "";  

                    }} />
                                      

                </Table>}

            </Modal>
                <Steps current={current} size="small" onChange={e => this.onStepChange(e)} >
                    {

                        this.state.workFlowStep.map(function (row) {
                            console.log("Girdi");
                            return <Step title={Boolean(row.stepWf) ? row.stepWf.workFlowName : (Boolean(row.stepName) ? row.stepName.name : '' )  } /> 

                           
                        })
                    }
                    {/* <Step title="Start" subTitle="Start Date: " description="Start Step Of >Workflow" />
                    <Step title="Preparation" description="Preparation Step Of Workflow" />
                    <Step title="Informative" description="Informative Step Of Workflow" />
                    <Step title="Review" description="Review Step Of Workflow" />
                    <Step title="Confirmation" description="Confirmation Step Of Workflow" /> */}

                </Steps>
                <Progress percent={(100 / this.state.workFlowStep.length * (current + 1)).toFixed(2)} successPercent={(100 / this.state.workFlowStep.length * (current + 1)).toFixed(2)} /> 
                <br />
                <Card hidden={this.state.startModalHidden} style={{ width: "100%" }}>
                    <Toast ref={(el) => this.toast = el} />
                    <Card.Header>
                        {<div style={{ color: "red", fontWeight: "bold" }}>
                            Start Date: {this.state.workFlowStep[this.state.current] !== undefined ? setDateFormat(this.state.workFlowStep[this.state.current].startdate) : ""}<br />
                            End Date: {this.state.workFlowStep[this.state.current] !== undefined ? setDateFormat(this.state.workFlowStep[this.state.current].duedate) : ""}<br />
                            Step : {this.state.workFlowStep[this.state.current] !== undefined ? (Boolean(this.state.workFlowStep[this.state.current].stepWf) ? this.state.workFlowStep[this.state.current].stepWf.workFlowName : (Boolean(this.state.workFlowStep[this.state.current].stepName) ? this.state.workFlowStep[this.state.current].stepName.name : '' ) ): '' }
                        </div>}
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="12" md="12" lg="12">
                                    <Form.Label>
                                        Please start to prepare (Workflow) document to sent on time to satisfy the timeline as per above schedule
                                </Form.Label>
                                </Form.Group>
                            </Form.Row>
                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "action") >= 0 && <Form.Row>
                                <Form.Group as={Col} sm="4" md="3" lg="2">
                                    <Form.Label>
                                        Action
                                </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} sm="8" md="9" lg="10">
                                    <InputText value={this.state.workFlowStep[this.state.current + 1] !== undefined ? this.state.workFlowStep[this.state.current + 1].actiontype : ""} style={{ width: "100%" }} placeholder="" />
                                </Form.Group>
                            </Form.Row>}
                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "document") >= 0 && <Form.Row>
                                <Form.Group as={Col} sm="4" md="3" lg="2">
                                    <Form.Label>
                                        Document
                                </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} sm="8" md="9" lg="10">
                                    <InputText value={this.state.workFlowStep[this.state.current] !== undefined && this.state.workFlowStep[this.state.current].document !== null && this.state.workFlowStep[this.state.current].document !== undefined ? this.state.workFlowStep[this.state.current].document.description : ""} style={{ width: "100%" }} placeholder="" />
                                </Form.Group>
                            </Form.Row>}
                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "download") >= 0 &&
                                <Form.Row>
                                    <Form.Group as={Col} sm="4" md="3" lg="2">
                                        <Form.Label>
                                            Download
                                  </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="8" md="9" lg="10">
                                        <ButtonA style={{ marginLeft: '2px', alignContent: 'center' }} onClick={() => this.downloadFile} type="primary" shape="round" icon={<DownloadOutlined />} >
                                            Download
                                     </ButtonA>
                                    </Form.Group>
                                </Form.Row>}
                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "upload") >= 0 &&

                                <Form.Row>
                                    <Form.Group as={Col} sm="4" md="3" lg="2">
                                        <Form.Label>
                                            Upload
                                  </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="8" md="9" lg="10">
                                        <Upload
                                            name="file"
                                            showUploadList={true}
                                            action={`${API_BASE}/WorkFlowStepSummary/uploadb`}
                                            onChange={this.onChangeUpload}
                                        >
                                            <ButtonA>
                                                <UploadOutlined />Upload
                                    </ButtonA>
                                        </Upload>
                                    </Form.Group>
                                </Form.Row>}
                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "deadline") >= 0 &&
                                <Form.Row>
                                    <Form.Group as={Col} sm="4" md="3" lg="2">
                                        <Form.Label>
                                            Deadline
                                </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="8" md="9" lg="10">
                                        <InputText value={this.state.deadline} style={{ width: "100%" }} placeholder="" />
                                    </Form.Group>
                                </Form.Row>}

                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "comments") >= 0 &&

                                <Form.Row>
                                    <Form.Group as={Col} sm="4" md="3" lg="2">
                                        <Checkbox onChange={(e) => this.setState({ isComment: e.checked })} checked={this.state.isComment}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label">Comments</label>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="8" md="9" lg="10">
                                        <InputText style={{ width: "100%" }} hidden={!this.state.isComment} onChange={(e) => this.setState({ comment: e.target.value })} value={this.state.comment} placeholder="" />
                                    </Form.Group>

                                </Form.Row>}
                            {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "justifications") >= 0 &&

                                <Form.Row>
                                    <Form.Group as={Col} sm="4" md="3" lg="2">
                                        <Checkbox onChange={(e) => this.setState({ isJustification: e.checked, hiddenRemindme: e.checked ? false : true })} checked={this.state.isJustification}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label">Justification(s)</label>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="8" md="9" lg="10">
                                        <InputText style={{ width: "100%" }} hidden={!this.state.isJustification} onChange={(e) => this.setState({ justification: e.target.value })} value={this.state.justification} placeholder="" />
                                    </Form.Group>

                                </Form.Row>}

                            <Form.Row>
                                <Form.Group as={Col} sm="4" md="3" lg="2">
                                    <Form.Label>

                                    </Form.Label>
                                </Form.Group>
                                {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "correctResubmit") >= 0 &&

                                    <Form.Group as={Col} sm="2" md="2" lg="2">
                                        <ButtonA type="text" danger shape="round" onClick={this.doCorrect} >
                                            Correct and Resubmit
                                     </ButtonA>
                                    </Form.Group>}
                                {Boolean(this.state.workFlowStep[this.state.current]) && Boolean(this.state.workFlowStep[this.state.current].pageAttributes) && this.state.workFlowStep[this.state.current].pageAttributes.findIndex(p => p.attributeKey === "confirmed") >= 0 &&

                                    <Form.Group as={Col} sm="2" md="2" lg="2">
                                        <ButtonA shape="round" type="primary" onClick={this.doPost} >
                                            Confirm and Post
                                     </ButtonA>
                                    </Form.Group>}
                                <Form.Group>
                                    <ButtonA type="dashed" onClick={this.getHistory} icon={<SearchOutlined />}>
                                        History
                                    </ButtonA> 
                                </Form.Group>
                            </Form.Row>

                        </Form>
                    </Card.Body>

                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WFStart)