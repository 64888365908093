/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import 'antd/es/cascader/style/index.css';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Row } from 'react-bootstrap';
import { Modal as Modal2, Button as ReactButton} from 'react-bootstrap'; 

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Steps } from 'antd';
import { Progress } from 'antd';
import { RightCircleOutlined, HistoryOutlined, InfoOutlined, ReadOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Toast } from 'primereact/toast';
import { Card } from 'react-bootstrap';
import { Upload, Button as ButtonA, message, Checkbox, Select, InputNumber,Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import { Table, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";


const { Option } = Select;
const { Column } = Table;



export class WFStart extends Component {

    constructor() {
        super();
        this.state = {

            historyStepList: [],
            checkReminder: false,
            durationType: "",
            duration: 0,

            type:0,
            transmittalWorkflowId:null,
            outgoingTransmittalId:null,
            submittalLogId:null,
            comment:'',
            
            visible:false,


            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            formId: null

        };


    }

    componentWillMount() {
        
        var pathArray = this.props.location.pathname.split('/');
        console.log(pathArray);
        if (Boolean(pathArray) && pathArray.length >= 5) {

            this.setState({
                submittalLogId: pathArray[2],
                outgoingTransmittalId: pathArray[3],
                type: pathArray[4] 
            });
        }
    }
    

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    componentDidMount = () => {
        this.getDynamicMenu();
    };


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Start" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Start"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Start");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/")[1]); 

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

           // this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }




    doGotit = async () => {
     


        const newItem = {
            type:this.state.type,
            transmittalWorkflowId:this.state.transmittalWorkflowId,
            outgoingTransmittalId:this.state.outgoingTransmittalId,
            submittalLogId:this.state.submittalLogId,
            comment:this.state.comment,
            menuId:this.state.formId

        }

        console.log(newItem);

        var response = await await handleRequest("POST", "/OutgoingStepSummary/getit", newItem);

        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS-OutgoingStepSummary-001")
            {
                this.showSuccess("Workflow step completed succesfully!"); 
            }
            else {

                if (response.data === "ERROR-OutgoingStepSummary-001")
                {
                    this.showError("The workflow step associated with the menu was not found in this page!");
                }

                if (response.data === "ERROR-OutgoingStepSummary-002")
                {
                    this.showError("There is no information about who will make this workflow step. Please associate workflow steps with users in submittal log page."); 
                }

                if (response.data === "ERROR-OutgoingStepSummary-003")
                {
                    this.showError("You are not authorized for doing action."); 
                }

                if (response.data === "ERROR-OutgoingStepSummary-004")
                {
                    this.showError("This workflow is in progress. Please view the history for more information."); 
                }

                if (response.data === "ERROR-OutgoingStepSummary-005")
                {
                    this.showError("An error was occured. Please try again later !");
                }

               
            }
         
        }
        else this.showError("An error was occured. Please try again later !");



    }
    getHistory = async () => {

        if(Boolean(this.state.outgoingTransmittalId)){ 
    
            const newItem = {
           
                type:this.state.type,
                outgoingTransmittalId:this.state.outgoingTransmittalId
              
      
          }   
      
         var response = await handleRequest("POST", "/OutgoingStepSummary/history", newItem);
          if(Boolean(response.data)) {
            this.setState({
                historyStepList:response.data 
              })
          } 

         
 
          this.setState({
            visible:true 
          })
      
          }
          else  message.error(`Please select workflow !`);

    }

    onOk = async () => {

        this.setState({
          visible:false 
        })
    
      }

      hideModal = () => {

        this.setState({visible:false})
      }

      downloadFile = async (row) => {

        window.location.href = row.filePath ; // this.state.workFlowStep[this.state.current];

    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    render() {

        return (

            <div style={{ backgroundColor: "white", padding: "10px" }}>
                
                <Modal2
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal2.Header closeButton>
                                        <Modal2.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal2.Title>
                                    </Modal2.Header>

                                    <Modal2.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal2.Body>
                                    <Modal2.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal2.Footer>
                                </Modal2>
                 <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>

                <Modal
                    title="History of Workflow"
                    visible={this.state.visible}
                    onOk={this.onOk}
                    onCancel={this.hideModal}
                    icon={<ExclamationCircleOutlined />}
                    okText="OK"
                    width={900}
                >
                    {this.state.historyStepList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.historyStepList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                        <Column title="S/N" dataIndex="index" />
                        <Column title="Step Name" render={(value, row, index) => {
                            if (Boolean(row.transmittalWorkflow))
                                return row.transmittalWorkflow.stepName;
                            else return "";

                        }} />
                        <Column title="Comments" dataIndex="comment" />
                        
                        <Column title="File" render={(value, row, index) => {
                            if (Boolean(row.filePath))
                                return <ButtonA style={{ marginLeft: '2px', alignContent: 'center' }} onClick={() => this.downloadFile(row)} type="primary" shape="round" icon={<DownloadOutlined />} >
                                    Download
                                </ButtonA>;
                            else return "";

                        }} />
                        <Column title="Action Time" render={(value, row, index) => {
                            if (Boolean(row.processTime))
                                return moment(row.processTime).format('DD/MM/YYYY HH:mm')

                            return "";

                        }} />
                        <Column title="Action" render={(value, row, index) => {
                            if (row.status === 1)
                                return <Tag color={'green'}
                                > Confirm and Post
                      </Tag>;
                            if (row.status === -1)
                                return <Tag color={'volcano'}
                                >Correct ond Resubmit
                        </Tag>;

                            return "";

                        }} />
                        <Column title="Action By" render={(value, row, index) => {
                            if (Boolean(row.user) && Boolean(row.user.staff))
                                return row.user.staff.name + ' ' + row.user.staff.midName + ' ' + row.user.staff.lastName;

                            return "";

                        }} />


                    </Table>}

                </Modal>


                <Card style={{ width: "100%" }}>
                    <Toast ref={(el) => this.toast = el} />
                    <Card.Header>
                        Start
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="12" md="12" lg="12">
                                    <Form.Label>
                                        Please start to prepare (Workflow) document to sent on time to satisfy the timeline as per above schedule
                                </Form.Label>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="4" md="3" lg="2">
                                    <Form.Label>
                                        Action
                                </Form.Label>
                                </Form.Group>

                            </Form.Row>

                            {<Form.Row>
                                <Form.Group as={Col} sm="4" md="3" lg="2">
                                    <Form.Label>
                                        Document
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} sm="8" md="9" lg="10">
                                    <ButtonA style={{ marginLeft: '2px', alignContent: 'center' }} onClick={() => this.downloadFile} type="primary" shape="round" icon={<DownloadOutlined />} >
                                        Download
                                     </ButtonA>
                                </Form.Group>
                            </Form.Row>}

                        

                            <Form.Row>
                                <Form.Group as={Col} sm="2" > 
                                    <Checkbox checked={this.state.checkReminder} onChange={(e) => {

                                        this.setState({ checkReminder: e.target.checked })
                                    }} > Remind me </Checkbox>
                                </Form.Group>
                               { this.state.checkReminder &&  
                               <Form.Group as={Col}  sm="1" controlId="formGridJointdesignRevno">
                                    <InputNumber min={0} style={{width:'100%'}} value={this.state.duration} onChange={(value) => { this.setState({ duration: value }) }} />

                               </Form.Group> }
                               { this.state.checkReminder &&  <Form.Group as={Col} sm="2"  controlId="formGriJointdesignRevno">
                                    <Select style={{width:'100%'}}  value={this.state.durationType} onChange={(value) => {
                                        this.setState({
                                            durationType: value
                                        })
                                    }}>
                                        <Option value={"year"} >Year</Option>
                                        <Option value={"month"} >Month</Option>
                                        <Option value={"week"} >Week</Option>
                                        <Option value={"day"} >Day</Option>
                                        <Option value={"hour"} >Hour</Option>
                                        <Option value={"minute"} >Minute</Option>
                                    </Select>

                                </Form.Group>}
                                

                                <Form.Group as={Col} sm="2" >
                                    <ButtonA type="text" danger shape="round" onClick={this.doGotit} >
                                        Got it
                                     </ButtonA>
                                </Form.Group>

                                <Form.Group  as={Col} sm="2">  
                                    <ButtonA type="dashed" onClick={this.getHistory} icon={<SearchOutlined />}>
                                        History
                                    </ButtonA>
                                </Form.Group>
                            </Form.Row>

                        </Form>
                    </Card.Body>

                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WFStart)