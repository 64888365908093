import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Switch, Button as AntButton, Collapse, Cascader } from 'antd';
import { Avatar } from 'antd';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization,getDynamicMenu,getUserSettings } from '../../redux/config/imu.js';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const { Panel } = Collapse;
const pageName = "PhysicalProperties";

export class PhysicalProperties extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            propertyGroupList: [],
            itemGroupPropertyList: [],
            groupInputs: {},
            fileList: [],
            columns: {},
            dataList: {},
            hideGroupModal: true,
            propertyGroupName: '',
            itemType: 1,
            itemGroupIds: [],
            itemGroups: [],
            nodes: [],

            selectedItemId: null,
            groupId: null,

            itemList: [],
            itemListSelectItems: [],



            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false; 
        let dynamicMenu = await getDynamicMenu(pageName);          
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {

            isAdmin = true ;         

        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes:standardization.dynamicAttributes,
            dynamicInputs:standardization.dynamicInputs,
            isAdmin:isAdmin,
            formId:dynamicMenu.formId,
            authorizations:dynamicMenu.authorizations
        });

        this.fillNodes();
        this.getPropertyGroupList();
        this.setComboboxes();
        this.restartTable();
        this.fillCodes();
        this.fillMaterials();


    }

    getUserSettings = async (formId) => {

        let userSettings =  await getUserSettings(formId);
         this.setState(
          {
              howToUseSetting: userSettings.howToUseSetting,
              whatIsSetting: userSettings.whatIsSetting 
          }
         )
      }

    fillNodes = async () => {
        const response3 = await handleRequest("GET", "/ItemGroup/getNodes");

        if (response3.data.length === 0) {
            this.setState({
                nodes: [],

            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data.filter(p => p.itemType === this.state.itemType),

            });
        }

    }

    fillItemGroupProperty = async () => {
        if (Boolean(this.state.itemGroupIds) && this.state.itemGroupIds.length > 0) {
            let groupId = this.state.itemGroupIds[this.state.itemGroupIds.length - 1]
            const r = await handleRequest("GET", "/ItemGroupProperty/getAllByItemGroup/" + groupId);
            var list = this.state.propertyGroupList;
            if (Boolean(r.data) && r.data.length > 0) {
                console.log(r.data);
                var listItemGroupProperty = r.data;
                listItemGroupProperty.forEach(element => {
                    if (Boolean(element.propertyGroup))
                        element.propertyGroupName = element.propertyGroup.propertyGroupName
                });

                list.forEach(element => {
                    let index = listItemGroupProperty.findIndex(p => p.propertyGroupId === element.id);
                    if (index === -1) {
                        let obj = {
                            checked: false,
                            itemGroupId: groupId,
                            propertyGroupId: element.id,
                            propertyGroupName: element.propertyGroupName, id: 0
                        };
                        listItemGroupProperty.push(obj);
                    }
                });
                this.setState({
                    itemGroupPropertyList: listItemGroupProperty
                });
            }
            else {
                listItemGroupProperty = [];
                list.forEach(element => {

                    let obj = {
                        checked: false,
                        itemGroupId: groupId,
                        propertyGroupId: element.id,
                        propertyGroupName: element.propertyGroupName,
                        id: 0
                    };
                    listItemGroupProperty.push(obj);

                });
                this.setState({
                    itemGroupPropertyList: listItemGroupProperty
                });
            }
        }
        else {
            this.setState({
                itemGroupPropertyList: []
            });
        }
    }

    onChangeItemGroup = (value, data) => {
        this.setState({
            itemGroupIds: value,
            itemGroups: data,
        }, this.fillMaterials
        );
    };


    save = async () => {



    }

    edit = (row) => {



    }


    delete = async () => {

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            itemId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {

    }


  
    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,

            itemId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.getPropertyGroupList();
    }


    stop = () => {

        this.setState({

            itemId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.getPropertyGroupList();
    }



    resetInputs = () => {
        this.setState({

            itemId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.getPropertyGroupList();
    }


    cancel = () => {

        this.resetInputs();
    }




    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }





    fillCodes = async () => {

        const response = await handleRequest("GET", "/ItemGroup/type/" + this.state.itemType);
        console.log("geldi fillCodes")
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                itemGroupSelectItems: response.data
            });
        }
    }




    getPropertyGroupList = async () => {
        var dataList = {};
        var list = [];
        const response2 = await handleRequest("GET", "/PropertyGroup/getAllByCompany");

        if (response2.data !== null && response2.data !== undefined) {
            list = response2.data;
            const columns = {}, groupInputs = {};
            list.forEach(element => {
                columns[element.id + ""] = [];
                dataList[element.id + ""] = [];
                groupInputs[element.id + ""] = { propertyGroupId: element.id, propertyDescription: '', explanation: '', fileList: [], sketchPath: '', unitTypeId: null };
                columns[element.id + ""].push({
                    key: "key",
                    title: "Action",
                    render: (value, row, index) => {
                        return this.actionTemplate(row);
                    }
                });
                columns[element.id + ""].push({
                    key: "key",
                    title: "Material Name",
                    dataIndex: "itemName"
                })
                if (Boolean(element.properties) && element.properties.length > 0)
                    element.properties.forEach(property => {
                        property.select = false;
                        columns[element.id + ""].push({
                            key: "key",
                            title: property.propertyDescription,
                            dataIndex: property.id + ""
                        })
                    });

            });

            this.setState({
                propertyGroupList: response2.data,
                columns: columns,
                groupInputs: groupInputs
            });
        }


        var response;
        if (Boolean(this.state.selectedItemId))
            response = await handleRequest("GET", "/ItemMaterialEquipment/getById/" + this.state.selectedItemId);
        else
            response = await handleRequest("GET", "/ItemMaterialEquipment/getAllMaterials");

        if (response.data.length !== 0) {
            const list2 = response.data;
            list2.forEach(element => {
                element.key = element.id;

                if (Boolean(element.itemProperties) && element.itemProperties.length > 0) {
                    list.forEach(g => {
                        let obj = { id: element.id, itemName: element.itemName };
                        element.itemProperties.forEach(p => {
                            if (Boolean(p.property) && p.property.propertyGroupId === g.id) {
                                obj[p.propertyId + ""] = <CheckOutlined style={{ fontSize: '24px', color: '#08c' }} ></CheckOutlined>;
                            }


                        });
                        dataList[g.id + ""].push(obj);
                    });

                }
            });
            this.setState({
                itemList: list2,
                dataList: dataList
            });
        }

    }

    fillMaterials = async () => {

        this.fillItemGroupProperty();
        var response;
        var groupId = null;
        if (Boolean(this.state.itemGroupIds) && this.state.itemGroupIds.length > 0) {
            groupId = this.state.itemGroupIds[this.state.itemGroupIds.length - 1];
        }
        response = await handleRequest("GET", "/ItemMaterialEquipment/getAllMaterials");

        if (response.data.length !== 0) {
            var list = response.data;
            if (Boolean(groupId))
                list = list.filter(p => p.groupId == groupId);
            this.setState({
                itemListSelectItems: list
            });
        }
    }

    setComboboxes = async () => {

        const response = await handleRequest("GET", "/UnitType/getallByCompany");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                unitTypeSelectItems: response.data
            });
        }

    }

    addProperty = async (group) => {
        console.log(group);

        if (Boolean(group)) {
            var groupInputs = this.state.groupInputs;
            var obj = groupInputs[group.id + ""];
            console.log(obj);

            await handleRequest("POST", "/Property/add", obj);

            // await handleRequest("POST", "/Dimension/update", obj);

            this.showSuccess();
            // obj = { propertyGroupId: group.id, propertyDescription: '', explanation: '', fileList: [], sketchPath: '', unitTypeId: null };
            this.setState({
                groupInputs: groupInputs
            })

            this.getPropertyGroupList();
        }

    }
    saveItepProperty = async (record, group) => {
        console.log(record);
        console.log(group);

        var propertyGroupList = this.state.propertyGroupList;
        var i = propertyGroupList.findIndex(p => p.id === group.id);
        var obj = propertyGroupList[i];

        const response = await handleRequest("GET", "/ItemMaterialEquipment/" + record.id);
        var oldItemProperties = [];
        if (Boolean(response.data)) {
            this.setState({
                oldItemProperties: Boolean(response.data.itemProperties) ? response.data.itemProperties : []
            });
        }
        else {
            this.showError("An error was reccured!");
            return;
        }
        if (oldItemProperties.length > 0)
            oldItemProperties.forEach(element => {
                element.active = false;
            });

        if (Boolean(obj.properties)) {
            var list = [];
            obj.properties.forEach(element => {
                if (element.select) {
                    let index = oldItemProperties.findIndex(p => p.propertyId === element.id);
                    if (index > 0) {
                        oldItemProperties[index].active = true;
                    }
                    else {
                        let obj = { itemId: record.id, propertyId: element.id }
                        oldItemProperties.push(obj);
                    }
                }
            });
            await handleRequest("POST", "/ItemProperty/saveAll", oldItemProperties);

            // await handleRequest("POST", "/Dimension/update", obj);

            this.showSuccess();
            var groupInputs = this.state.groupInputs;
            groupInputs[group.id + ""] = { propertyGroupId: group.id, propertyDescription: '', explanation: '', fileList: [], sketchPath: '', unitTypeId: null };
            this.setState({
                groupInputs: groupInputs
            })

            this.getPropertyGroupList();
        }

    }


    expand = (record) => {



        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        // return <Table columns={columns} dataSource={data} pagination={false} />;

        return <Collapse defaultActiveKey={0} expandIconPosition={this.state.expandIconPosition}>
            {this.state.propertyGroupList.filter(p => p.active === true).map((group, i) => (

                Boolean(record.itemGroup) && Boolean(record.itemGroup.propertyGroups) && record.itemGroup.propertyGroups.length > 0 && record.itemGroup.propertyGroups.findIndex(p => p.propertyGroupId === group.id && p.checked) >= 0 && <Panel style={{ fontWeight: 'bold' }} header={group.propertyGroupName} key={i}  >
                    {
                        <FormAnt.Item
                            label={""}
                            name="OrganizationalLevelDesignation"
                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Input id="todo" placeholder="Description" value={this.state.groupInputs[group.id + ""].propertyDescription} onChange={(e) => {
                                        var obj = this.state.groupInputs;
                                        obj[group.id + ""].propertyDescription = e.target.value;
                                        this.setState({ groupInputs: obj })
                                    }} />


                                </Col>
                                <Col sm="2">
                                    <Input id="todo" placeholder="Explanation" value={this.state.groupInputs[group.id + ""].explanation} onChange={(e) => {
                                        var obj = this.state.groupInputs;
                                        obj[group.id + ""].explanation = e.target.value;
                                        this.setState({ groupInputs: obj })
                                    }} />

                                </Col>

                                <Col sm="2">
                                    <Select placeholder="" value={this.state.groupInputs[group.id + ""].unitTypeId} onChange={(value) => {
                                        var obj = this.state.groupInputs;
                                        obj[group.id + ""].unitTypeId = value;
                                        this.setState({ groupInputs: obj })
                                    }}
                                    >
                                        <Option key={null} value={null}>Select</Option>
                                        {this.state.unitTypeSelectItems.map(i => (
                                            <Option key={i.id} value={i.id}>{i.codeParentUnit.name}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col sm="2">
                                    <Upload {...props}
                                        showUploadList={true}
                                        fileList={this.state.groupInputs[group.id + ""].fileList}
                                        onChange={({ fileList }) => {
                                            if (fileList.length == 1) {
                                                var filePath = "";
                                                if (Boolean(fileList[0].response)) {
                                                    filePath = fileList[0].response.url;
                                                    this.showSuccess("file uploaded successfully");
                                                    // fileName: info.file.name, 
                                                }

                                                var obj = this.state.groupInputs;
                                                obj[group.id + ""].sketchPath = filePath;
                                                obj[group.id + ""].fileList = fileList;
                                                this.setState({ groupInputs: obj })

                                            }
                                        }}
                                        beforeUpload={(fileList) => {
                                            if (this.state.groupInputs[group.id + ""].fileList.length > 0) {
                                                this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                return false;
                                            }
                                            return true;
                                        }}
                                        onRemove={({ fileList }) => {
                                            var obj = this.state.groupInputs;
                                            obj[group.id + ""].sketchPath = "";
                                            obj[group.id + ""].fileList = [];
                                            this.setState({ groupInputs: obj })
                                        }
                                        }>
                                        <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                            <UploadOutlined /> Add Sketch
                                        </AntButton>
                                    </Upload>
                                </Col>

                                <Col sm="2">
                                    <AntButton type="primary" onClick={() => this.addProperty(group)} icon={<PlusOutlined />}></AntButton>

                                </Col>

                            </Form.Group>
                        </FormAnt.Item>
                    }
                    {Boolean(group.properties) && group.properties.length > 0 && group.properties.filter(p => p.active === true).map((property, j) => (
                        <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                            {
                                <FormAnt.Item

                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select" /> }]} >

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                        <Col sm="2">

                                        </Col>
                                        <Col sm="2">
                                            <div>
                                                <Checkbox onChange={(e) => {
                                                    var propertyGroupList = this.state.propertyGroupList;
                                                    var obj = propertyGroupList[i];
                                                    var p = obj.properties[j];
                                                    p.select = e.target.checked;
                                                    this.setState({
                                                        propertyGroupList: propertyGroupList
                                                    })
                                                }} checked={property.select}> </Checkbox>
                                            </div>
                                        </Col>

                                        <Col sm="2">
                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                {property.propertyDescription}
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label style={{ font: 'bold', fontSize: '11pt' }} >
                                                {property.explanation}
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label style={{ font: 'bold', fontSize: '11pt' }} >
                                                <Avatar shape="square" size={100} src={property.sketchPath} />
                                            </Form.Label>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Label style={{ font: 'bold', fontSize: '11pt' }} >
                                                {Boolean(property.unitType) && Boolean(property.unitType.codeParentUnit) ? property.unitType.codeParentUnit.name : ''}
                                            </Form.Label>
                                        </Col>

                                    </Form.Group>

                                </FormAnt.Item>}

                        </div>))}
                    {
                        <FormAnt.Item
                            label={""}
                            name="OrganizationalLevelDesignation"
                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                <Col sm="4">
                                </Col>

                                <Col sm="2">
                                    <AntButton type="primary" onClick={() => this.saveItepProperty(record, group)} >Save All</AntButton>

                                </Col>

                            </Form.Group>
                        </FormAnt.Item>
                    }


                    {Boolean(this.state.dataList[group.id + ""]) && this.state.dataList[group.id + ""].length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} columns={this.state.columns[group.id + ""]} dataSource={this.state.dataList[group.id + ""].filter(p => p.id === record.id)} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                    </Table>}


                </Panel>))}



        </Collapse>


    }

    addPropertyGroup = async () => {

        const obj = {

            propertyGroupName: this.state.propertyGroupName,

        }


        await handleRequest("POST", "/PropertyGroup/add", obj);


        this.showSuccess();

        this.setState({
            propertyGroupName: '',
            hideGroupModal: true

        })

        this.getPropertyGroupList();

    }

    setCheck = async () => {



        var itemGroupPropertyList = this.state.itemGroupPropertyList;


        if (Boolean(itemGroupPropertyList) && itemGroupPropertyList.length > 0) {
            await handleRequest("POST", "/ItemGroupProperty/saveAll", itemGroupPropertyList);
            this.getPropertyGroupList();
            this.fillItemGroupProperty();

        }
        // this.fillItemGroupProperty();
    }


    render() {



        const rootColumns = [
            { title: 'Material Description', dataIndex: 'itemName', key: 'name' },
        ];



        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>

                        <MenuProvider id="menu_id">


                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={!this.state.hideGroupModal}
                                onHide={() => {
                                    this.setState({
                                        hideGroupModal: true,
                                        groupName: '',
                                        deathLeave: false,
                                        sickLeave: false,
                                        businessTripLeave: false,
                                        havePeriod: false,

                                    })
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Add Group</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="12">
                                            <Input style={{ width: "100%" }} placeholder="Group Name" value={this.state.propertyGroupName} onChange={(e) => this.setState({ propertyGroupName: e.target.value })} />

                                        </Col>


                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={() => {
                                        this.setState({
                                            hideGroupModal: true,
                                            propertyGroupName: '',


                                        })
                                    }}>Cancel</ReactButton>
                                    <ReactButton variant="info" onClick={this.addPropertyGroup} >Add</ReactButton>
                                </Modal.Footer>
                            </Modal>
                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        {/* <Col sm="2">
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col> */}
                                    </Row>
                                </div>

                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        {...layout}
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >
                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Parent Material Type" />}
                                                name="OrganizationalLevelRoleGroup"
                                                id="OrganizationalLevelRoleGroup"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        {/* <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Unit"
                                                    value={this.state.groupId} onChange={(value) => {
                                                        this.setState({
                                                            groupId: value
                                                        },
                                                        this.fillMaterials
                                                        )
                                                    }}

                                                >
                                                <Option key={null} value={null}>Select</Option>
                                                    {this.state.itemGroupSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.groupName}</Option>
                                                    ))}
                                                </Select> */}
                                                        <Cascader
                                                            style={{ width: "100%" }}
                                                            value={this.state.itemGroupIds}
                                                            options={this.state.nodes}
                                                            onChange={this.onChangeItemGroup}
                                                            changeOnSelect
                                                        />

                                                    </Col>
                                                    <Col sm="1">

                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }

                                        {Boolean(this.state.itemGroupPropertyList) && this.state.itemGroupPropertyList.length > 0 && this.state.itemGroupPropertyList.map((d, i) => (
                                            <div style={{ backgroundColor: "white", marginTop: "1px" }}>
                                                {
                                                    <FormAnt.Item

                                                        name="OrganizationalLevelDesignation"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                            <Col sm="6">

                                                            </Col>
                                                            <Col sm="2">
                                                                <div>
                                                                    <Checkbox onChange={(e) => {
                                                                        var itemGroupPropertyList = this.state.itemGroupPropertyList;
                                                                        var obj = itemGroupPropertyList[i];
                                                                        obj.checked = e.target.checked;
                                                                        this.setState({
                                                                            itemGroupPropertyList: itemGroupPropertyList
                                                                        }, this.setCheck)
                                                                    }} checked={this.state.itemGroupPropertyList[i].checked}> </Checkbox>
                                                                </div>
                                                            </Col>

                                                            <Col sm="4">
                                                                <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '11pt' }} >
                                                                    {d.propertyGroupName}
                                                                </Form.Label>
                                                            </Col>

                                                        </Form.Group>

                                                    </FormAnt.Item>}

                                            </div>))}

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Material Description" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Select placeholder="" value={this.state.selectedItemId} onChange={(value) => {
                                                            this.setState({
                                                                selectedItemId: value
                                                            },
                                                                this.getPropertyGroupList
                                                            )
                                                        }}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.itemListSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.itemName}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }



                                        {
                                            <FormAnt.Item
                                                label={""}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>

                                                    <Col sm="6">
                                                        <ReactButton variant="info" onClick={() => {
                                                            this.setState({
                                                                hideGroupModal: false
                                                            })
                                                        }} > + Add New Property Type</ReactButton>
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>
                                        }
                                        <Divider></Divider>


                                        <Modal
                                            show={this.state.modalMessage}
                                            onHide={this.showOrHideModalMessage}
                                            backdrop="static"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>

                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <Row >
                                                        <Col sm="12">
                                                            {this.state.messageTip == "Success" &&
                                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                            {this.state.messageTip == "Warning" &&
                                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                            {this.state.messageTip == "Error" &&
                                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                        </Col>
                                                    </Row>
                                                </Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.message}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                            </Modal.Footer>
                                        </Modal>
                                    </FormAnt>
                                </div>

                                {this.state.itemList.length > 0 && <Table
                                    className="components-table-demo-nested"
                                    columns={rootColumns}
                                    expandable={{ expandedRowRender: record => this.expand(record) }}
                                    dataSource={this.state.itemList}
                                />}
                                {/* 
                        {this.state.itemList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} columns={this.state.columns} dataSource={this.state.itemList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                        </Table>} */}

                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>


                        }
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalProperties) 