import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Button as ReactButton, Col, Form, Row, Modal, Form as FormBoot } from 'react-bootstrap';
import { CloseOutlined, PlusOutlined, UploadOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Checkbox, Button as AntButton, DatePicker, message, Input, InputNumber, Upload, Space, Select, Table } from 'antd';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';

import moment from 'moment';
import $ from "jquery";

var _generalTermsRows = [
    {
        generalTermIndex: 1,
        description: "",
        checked: false
    }
];
var _techReqsRows = [
    {
        techReqIndex: 1,
        description: "",
        checked: false
    }
];
var _warrantyRows = [
    {
        warrantyIndex: 1,
        description: "",
        checked: false
    }
];
var _languageRows = [
    {
        languageIndex: 1,
        description: "",
        checked: false
    }
];
var _requestedDocumentsRows = [
    {
        requestedDocumentIndex: 1,
        description: "",
        checked: false
    }
];
var _otherRows = [
    {
        otherIndex: 1,
        description: "",
        checked: false
    }
];
var _priceRows = [];
var _paymentRows = [];
var _evaCriteriasRows = [];

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;

var _splitRows = [
    {
        splitIndex: 1,
        quantity: "",
        date: today
    }
];

const { Column } = Table;
const { Option } = Select;

export class RequestForQuotation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vendorEvaluationDates: [],
            vendorEvaluationDate: null,
            modalMessage2: false,
            sameDate: false,
            remainingValue: false,
            sameDateOK: false,
            remainingValueOK: false,
            fixModal: false,
            splitQuantityModal: false,
            selectedFixRow: null,
            vendorLogs: [],
            selectedRowKeysUp: [],
            selectedRowKeysSub: [],
            selectedRowKeysVendor: [],
            selectedRowsUp: [],
            selectedRowsSub: [],
            selectedRowsVendor: [],
            selectedItem: null,
            deliveryDateModal: false,
            vendorModal: false,
            selectedRfq: null,
            itemId: "",
            items: [],
            allItems: [],
            lastRfq: [],
            rfqList: [],
            deleteModal: false,
            modalEdit: false,
            hidden: true,
            hiddenSave: false,
            rfqId: null,
            rfqNo: "",
            generalTerms: false,
            generalTermsRows: [
                {
                    generalTermIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            generalTermsRowsBackup: [],
            generalTermFiles: [],
            techReqs: false,
            techReqsRows: [
                {
                    techReqIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            techReqsRowsBackup: [],
            techReqFiles: [],
            price: false,
            yourOfferPrice: true,
            priceRows: [],
            priceRowsBackup: [],
            payment: false,
            yourOfferPayment: true,
            paymentRows: [],
            paymentRowsBackup: [],
            deliveryPlace: false,
            deliveryPlaceText: "",
            deliveryPlaceNecessary: false,
            deliveryPlacePoint: 0,
            quoteValidityPoint: 0,
            quoteValidityNecessary: false,
            markerPosition: { lat: 26.2844367, lng: 50.1888217 },
            validityQuotation: false,
            validityQuotationValue: "",
            validityQuotationType: "",
            evaluationCriterias: false,
            evaDeliveryDate: false,
            evaPrice: false,
            evaCriteriasRows: [],
            evaCriteriasRowsBackup: [],
            receptionOfQuotation: false,
            receptionOfQuotationOptions: ['Email', 'Letter', 'Vendor Portal'],
            receptionOtherCheck: false,
            receptionOther: "",
            selectedReception: [],
            modeOfTransport: false,
            modeOfTransportOptions: ['Road', 'Air', 'Sea'],
            transportOtherCheck: false,
            transportOther: "",
            selectedTransport: [],
            warranty: false,
            warrantyRows: [
                {
                    warrantyIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            warrantyRowsBackup: [],
            language: false,
            languageRows: [
                {
                    languageIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            languageRowsBackup: [],
            requestedDocuments: false,
            requestedDocumentsRows: [
                {
                    requestedDocumentIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            requestedDocumentsRowsBackup: [],
            other: false,
            otherRows: [
                {
                    otherIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            otherRowsBackup: [],
            splitRows: [],
            overvalue: false,

            updatedProjectTakeOffLists: [],
            updatedProjectTakeOffListSubs: [],
            updatedVendorLogs: [],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "RequestForQuotation" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "RequestForQuotation",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "RequestForQuotation");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        const responseCompany = await handleRequest("GET", "/CompanyContact/getAllByCompany");

        let flagLocationCenterLat = null;
        let flagLocationCenterLng = null;

        if (responseCompany.data.length === 0) {
            this.setState({
                markerPosition: { lat: 26.2844367, lng: 50.1888217 }
            });
        } else {
            if (responseCompany.data[0].locationCenterLat !== undefined && responseCompany.data[0].locationCenterLng !== undefined)
                if (responseCompany.data[0].locationCenterLat !== null && responseCompany.data[0].locationCenterLng !== null) {
                    flagLocationCenterLat = parseFloat(responseCompany.data[0].locationCenterLat);
                    flagLocationCenterLng = parseFloat(responseCompany.data[0].locationCenterLng);

                    this.setState({
                        markerPosition: { lat: flagLocationCenterLat, lng: flagLocationCenterLng }
                    });
                }
        }

        const response = await handleRequest("GET", "/VendorLog/getAllByCompany");

        if (response.data.length > 0) {
            var rDataVendor = response.data;

            rDataVendor.forEach(element => {
                element.key = element.id;
            });

            this.setState({
                vendorLogs: rDataVendor
            });
        }

        const responseRFQ = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");

        if (responseRFQ.data.length === 0) {
            this.setState({
                rfqList: [],
                lastRfq: [],
                rfqNo: "RFQ-001"
            });
        } else {
            let flagLength = responseRFQ.data[responseRFQ.data.length - 1].id.toString();
            let flagNumber = flagLength.padStart(3, '0');
            this.setState({
                rfqList: responseRFQ.data,
                lastRfq: responseRFQ.data[responseRFQ.data.length - 1],
                rfqNo: "RFQ-" + flagNumber
            });
        }

        this.setState({
            splitRows: _splitRows
        });

        const responseDate = await handleRequest("GET", "/VendorEvaluation/getDates");
        var date = null;
        if (responseDate.data.length === 0) {
            this.setState({
                vendorEvaluationDates: [],
                vendorEvaluationDate: date
            });
        }
        if (responseDate.data.length !== 0) {
            date = responseDate.data[0].date;
            this.setState({
                vendorEvaluationDates: responseDate.data,
                vendorEvaluationDate: date
            });
        }

        this.getItems();
    }

    getItems = async () => {
        const response = await handleRequest("GET", "/ProjectTakeOffList/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                items: [],
            });
        }

        if (response.data.length !== 0) {
            var rData = response.data;

            rData.forEach(element => {
                element.key = element.id;
            });

            let responseData = rData;
            let selectedResponseData = [];
            let selectedResponseData2 = [];
            for (let index = 0; index < responseData.length; index++) {
                if (responseData[index].spec !== undefined && responseData[index].rfq !== undefined) {
                    if (responseData[index].spec === "Done" && responseData[index].rfq !== "Done") {
                        if (responseData[index].projectTakeOffListSubs.length !== 0) {
                            var rSubDataFlag = responseData[index].projectTakeOffListSubs;
                            rSubDataFlag.forEach(element => {
                                element.key = element.id;
                            });

                            var rDataFlag = responseData[index];
                            rDataFlag.projectTakeOffListSubs = rSubDataFlag;
                            selectedResponseData.push(rDataFlag);
                        } else
                            selectedResponseData.push(responseData[index]);
                    }
                }
            }
            for (let index = 0; index < responseData.length; index++) {
                if (responseData[index].spec !== undefined && responseData[index].rfq !== undefined) {
                    if (responseData[index].spec === "Done") {
                        selectedResponseData2.push(responseData[index]);
                    }
                }
            }
            this.setState({
                items: selectedResponseData,
                allItems: selectedResponseData2
            });
        }
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showWarning2(msg) {
        if (!msg) {
            msg = <FormattedMessage id="RequestForQuotationQuantityWarning1" defaultMessage="Quantity can't exceed the total quantity." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showWarning3(msg) {
        if (!msg) {
            msg = <FormattedMessage id="RequestForQuotationQuantityWarning2" defaultMessage="Are you sure of the same delivery dates?" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showWarning4(msg) {
        if (!msg) {
            msg = <FormattedMessage id="RequestForQuotationQuantityWarning3" defaultMessage="There are remaining values. Do you confirm?" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

        //alttaki kontrol eklenince 2 defa t�klamak gerekiyor.
        // if (this.state.selectID != null && this.state.selectID.length > 0) {
        //     this.setState({
        //         visibleWhatis: true
        //     });
        // }
        // else {
        //     this.setState({
        //         visibleWhatis: false
        //     });
        // }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    resetInputs = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _splitRows = [
            {
                splitIndex: 1,
                quantity: "",
                date: today
            }
        ];

        this.setState({
            hidden: true,
            rfqId: null,
            rfqNo: "",
            itemId: "",
            generalTerms: false,
            generalTermsRows: [
                {
                    generalTermIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            generalTermFiles: [],
            techReqs: false,
            techReqsRows: [
                {
                    techReqIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            techReqFiles: [],
            price: false,
            yourOfferPrice: true,
            priceRows: [],
            payment: false,
            yourOfferPayment: true,
            paymentRows: [],
            deliveryPlace: false,
            deliveryPlaceText: "",
            markerPosition: { lat: 26.2844367, lng: 50.1888217 },
            validityQuotation: false,
            validityQuotationValue: "",
            validityQuotationType: "",
            evaluationCriterias: false,
            evaDeliveryDate: false,
            evaPrice: false,
            evaCriteriasRows: [],
            receptionOfQuotation: false,
            receptionOtherCheck: false,
            receptionOther: "",
            selectedReception: [],
            modeOfTransport: false,
            transportOtherCheck: false,
            transportOther: "",
            selectedTransport: [],
            warranty: false,
            warrantyRows: [
                {
                    warrantyIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            language: false,
            languageRows: [
                {
                    languageIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            requestedDocuments: false,
            requestedDocumentsRows: [
                {
                    requestedDocumentIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            other: false,
            otherRows: [
                {
                    otherIndex: 1,
                    description: "",
                    checked: false
                }
            ],
            updatedProjectTakeOffLists: [],
            updatedProjectTakeOffListSubs: [],
            updatedVendorLogs: [],
            splitRows: [],
        });

        _generalTermsRows = [
            {
                generalTermIndex: 1,
                description: "",
                checked: false
            }
        ];
        _techReqsRows = [
            {
                techReqIndex: 1,
                description: "",
                checked: false
            }
        ];
        _warrantyRows = [
            {
                warrantyIndex: 1,
                description: "",
                checked: false
            }
        ];
        _languageRows = [
            {
                languageIndex: 1,
                description: "",
                checked: false
            }
        ];
        _requestedDocumentsRows = [
            {
                requestedDocumentIndex: 1,
                description: "",
                checked: false
            }
        ];
        _otherRows = [
            {
                otherIndex: 1,
                description: "",
                checked: false
            }
        ];
        _priceRows = [];
        _paymentRows = [];
        _evaCriteriasRows = [];

        $(".ant-upload-list").empty();
    }

    createNew = async () => {
        this.getItems();

        let flagLastRfq = [];
        let flagLength;
        const responseRFQ = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");
        if (responseRFQ.data.length === 0) {
            flagLastRfq = [];
            flagLength = "1";
        } else {
            flagLastRfq = responseRFQ.data[responseRFQ.data.length - 1];
            flagLength = responseRFQ.data[responseRFQ.data.length - 1].id.toString();
        }

        //let flagLength = (responseRFQ.data.length + 1).toString();
        let flagNumber = flagLength.padStart(3, '0');

        if (this.state.hidden) {
            if (flagLastRfq.length === 0) {
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();

                today = dd + '/' + mm + '/' + yyyy;

                _splitRows = [
                    {
                        splitIndex: 1,
                        quantity: "",
                        date: today
                    }
                ];
                _generalTermsRows = [
                    {
                        generalTermIndex: 1,
                        description: "",
                        checked: false
                    }
                ];
                _techReqsRows = [
                    {
                        techReqIndex: 1,
                        description: "",
                        checked: false
                    }
                ];
                _warrantyRows = [
                    {
                        warrantyIndex: 1,
                        description: "",
                        checked: false
                    }
                ];
                _languageRows = [
                    {
                        languageIndex: 1,
                        description: "",
                        checked: false
                    }
                ];
                _requestedDocumentsRows = [
                    {
                        requestedDocumentIndex: 1,
                        description: "",
                        checked: false
                    }
                ];
                _otherRows = [
                    {
                        otherIndex: 1,
                        description: "",
                        checked: false
                    }
                ];
                _priceRows = [];
                _paymentRows = [];
                _evaCriteriasRows = [];

                $(".ant-upload-list").empty();

                this.setState({
                    hidden: false,
                    rfqId: null,
                    itemId: "",
                    rfqNo: "RFQ-" + flagNumber,
                    generalTerms: false,
                    generalTermsRows: _generalTermsRows,
                    techReqsRows: _techReqsRows,
                    warrantyRows: _warrantyRows,
                    languageRows: _languageRows,
                    requestedDocumentsRows: _requestedDocumentsRows,
                    otherRows: _otherRows,
                    priceRows: _priceRows,
                    paymentRows: _paymentRows,
                    evaCriteriasRows: _evaCriteriasRows,
                    generalTermFiles: [],
                    techReqs: false,
                    techReqFiles: [],
                    price: false,
                    yourOfferPrice: true,
                    payment: false,
                    yourOfferPayment: true,
                    deliveryPlace: false,
                    deliveryPlaceText: "",
                    markerPosition: { lat: 26.2844367, lng: 50.1888217 },
                    validityQuotation: false,
                    validityQuotationValue: "",
                    validityQuotationType: "",
                    evaluationCriterias: false,
                    evaDeliveryDate: false,
                    evaPrice: false,
                    receptionOfQuotation: false,
                    receptionOtherCheck: false,
                    receptionOther: "",
                    selectedReception: [],
                    modeOfTransport: false,
                    transportOtherCheck: false,
                    transportOther: "",
                    selectedTransport: [],
                    warranty: false,
                    language: false,
                    requestedDocuments: false,
                    other: false,
                    splitRows: _splitRows
                });
            } else {
                var updatedGeneralTerms = [];
                var updatedGeneralTermFiles = [];
                var updatedTechReqs = [];
                var updatedTechReqFiles = [];
                var updatedPrices = [];
                var updatedPayments = [];
                var updatedEvaCriterias = [];
                var updatedWarranties = [];
                var updatedLanguages = [];
                var updatedRequestedDocuments = [];
                var updatedOthers = [];

                var row = flagLastRfq;

                for (let index = 0; index < row.generalTerms.length; index++) {
                    updatedGeneralTerms.push({
                        id: row.generalTerms[index].id,
                        active: row.generalTerms[index].active,
                        generalTermIndex: index + 1,
                        checked: row.generalTerms[index].rfqGeneralTermCheck,
                        description: row.generalTerms[index].rfqGeneralTerm
                    });
                }

                if (updatedGeneralTerms.length === 0) {
                    updatedGeneralTerms.push({
                        generalTermIndex: 1,
                        description: "",
                        checked: false
                    });
                }

                _generalTermsRows = updatedGeneralTerms;

                for (let index = 0; index < row.generalTermFiles.length; index++) {
                    updatedGeneralTermFiles.push({
                        id: row.generalTermFiles[index].id,
                        active: row.generalTermFiles[index].active,
                        rfqGeneralTermFilePath: row.generalTermFiles[index].rfqGeneralTermFilePath,
                    });
                }

                for (let index = 0; index < row.techRequirements.length; index++) {
                    updatedTechReqs.push({
                        id: row.techRequirements[index].id,
                        active: row.techRequirements[index].active,
                        techReqIndex: index + 1,
                        checked: row.techRequirements[index].rfqTechRequirementCheck,
                        description: row.techRequirements[index].rfqTechRequirement
                    });
                }

                if (updatedTechReqs.length === 0) {
                    updatedTechReqs.push({
                        techReqIndex: 1,
                        description: "",
                        checked: false
                    });
                }

                _techReqsRows = updatedTechReqs;

                for (let index = 0; index < row.techRequirementFiles.length; index++) {
                    updatedTechReqFiles.push({
                        id: row.techRequirementFiles[index].id,
                        active: row.techRequirementFiles[index].active,
                        index: index + 1,
                        rfqTechReqFilePath: row.techRequirementFiles[index].rfqTechReqFilePath,
                    });
                }

                for (let index = 0; index < row.prices.length; index++) {
                    updatedPrices.push({
                        id: row.prices[index].id,
                        active: row.prices[index].active,
                        priceIndex: index + 1,
                        checked: row.prices[index].rfqPriceCheck,
                        description: row.prices[index].rfqPrice
                    });
                }

                _priceRows = updatedPrices;

                for (let index = 0; index < row.paymentTerms.length; index++) {
                    updatedPayments.push({
                        id: row.paymentTerms[index].id,
                        active: row.paymentTerms[index].active,
                        paymentIndex: index + 1,
                        checked: row.paymentTerms[index].rfqPaymentTermCheck,
                        description: row.paymentTerms[index].rfqPaymentTerm
                    });
                }

                _paymentRows = updatedPayments;

                for (let index = 0; index < row.evaCriterias.length; index++) {
                    updatedEvaCriterias.push({
                        id: row.evaCriterias[index].id,
                        active: row.evaCriterias[index].active,
                        evaCriteriaIndex: index + 1,
                        checked: row.evaCriterias[index].rfqEvaCriteriaCheck,
                        description: row.evaCriterias[index].rfqEvaCriteria
                    });
                }

                _evaCriteriasRows = updatedPayments;

                for (let index = 0; index < row.warranties.length; index++) {
                    updatedWarranties.push({
                        id: row.warranties[index].id,
                        active: row.warranties[index].active,
                        warrantyIndex: index + 1,
                        checked: row.warranties[index].rfqWarrantyCheck,
                        description: row.warranties[index].rfqWarranty
                    });
                }

                if (updatedWarranties.length === 0) {
                    updatedWarranties.push({
                        warrantyIndex: 1,
                        description: "",
                        checked: false
                    });
                }

                _warrantyRows = updatedWarranties;

                for (let index = 0; index < row.languages.length; index++) {
                    updatedLanguages.push({
                        id: row.languages[index].id,
                        active: row.languages[index].active,
                        languageIndex: index + 1,
                        checked: row.languages[index].rfqLanguageCheck,
                        description: row.languages[index].rfqLanguage
                    });
                }

                if (updatedLanguages.length === 0) {
                    updatedLanguages.push({
                        languageIndex: 1,
                        description: "",
                        checked: false
                    });
                }

                _languageRows = updatedLanguages;

                for (let index = 0; index < row.requestedDocuments.length; index++) {
                    updatedRequestedDocuments.push({
                        id: row.requestedDocuments[index].id,
                        active: row.requestedDocuments[index].active,
                        requestedDocumentIndex: index + 1,
                        checked: row.requestedDocuments[index].rfqRequestedDocumentCheck,
                        description: row.requestedDocuments[index].rfqRequestedDocument
                    });
                }

                if (updatedRequestedDocuments.length === 0) {
                    updatedRequestedDocuments.push({
                        requestedDocumentIndex: 1,
                        description: "",
                        checked: false
                    });
                }

                _requestedDocumentsRows = updatedRequestedDocuments;

                for (let index = 0; index < row.others.length; index++) {
                    updatedOthers.push({
                        id: row.others[index].id,
                        active: row.others[index].active,
                        otherIndex: index + 1,
                        checked: row.others[index].rfqOtherCheck,
                        description: row.others[index].rfqOther
                    });
                }

                if (updatedOthers.length === 0) {
                    updatedOthers.push({
                        otherIndex: 1,
                        description: "",
                        checked: false
                    });
                }

                _otherRows = updatedOthers;

                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();

                today = dd + '/' + mm + '/' + yyyy;

                _splitRows = [
                    {
                        splitIndex: 1,
                        quantity: "",
                        date: today
                    }
                ];

                this.setState({
                    hidden: false,
                    rfqNo: "RFQ-" + flagNumber,
                    generalTerms: row.generalTermCheck,
                    generalTermsRowsBackup: row.generalTerms,
                    generalTermsRows: updatedGeneralTerms,
                    generalTermFiles: row.generalTermFiles,
                    techReqs: row.techRequirementCheck,
                    techReqsRows: updatedTechReqs,
                    techReqsRowsBackup: row.techRequirements,
                    techReqFiles: row.techRequirementFiles,
                    price: row.priceCheck,
                    yourOfferPrice: row.yourOfferPrice,
                    priceRows: updatedPrices,
                    priceRowsBackup: row.prices,
                    payment: row.paymentTermCheck,
                    yourOfferPayment: row.yourOfferPayment,
                    paymentRows: updatedPayments,
                    paymentRowsBackup: row.paymentTerms,
                    deliveryPlace: row.deliveryPlaceCheck,
                    deliveryPlaceText: row.deliveryPlace,
                    markerPosition: { lat: parseFloat(row.deliveryPlaceLat), lng: parseFloat(row.deliveryPlaceLng) },
                    validityQuotation: row.quoteValidityCheck,
                    validityQuotationValue: row.quoteValidityValue,
                    validityQuotationType: row.quoteValidityDateType,
                    evaluationCriterias: row.evaCriteriaCheck,
                    evaDeliveryDate: row.evaCriteriaDeliveryDateCheck,
                    evaPrice: row.evaCriteriaPriceCheck,
                    evaCriteriasRows: updatedEvaCriterias,
                    evaCriteriasRowsBackup: row.evaCriterias,
                    receptionOfQuotation: row.receptionForQuotationCheck,
                    receptionOtherCheck: row.receptionForQuotationOtherCheck,
                    receptionOther: row.receptionForQuotationOtherOption,
                    selectedReception: row.receptionForQuotationOptions.split(","),
                    modeOfTransport: row.modeOfTranportOtherCheck,
                    transportOtherCheck: row.modeOfTransportCheck,
                    transportOther: row.modeOfTranportOtherOption,
                    selectedTransport: row.receptionForQuotationOptions.split(","),
                    warranty: row.warrantyCheck,
                    warrantyRows: updatedWarranties,
                    warrantyRowsBackup: row.warranties,
                    language: row.languageCheck,
                    languageRows: updatedLanguages,
                    languageRowsBackup: row.languages,
                    requestedDocuments: row.requestedDocumentCheck,
                    requestedDocumentsRows: updatedRequestedDocuments,
                    requestedDocumentsRowsBackup: row.requestedDocuments,
                    other: row.otherCheck,
                    otherRows: updatedOthers,
                    otherRowsBackup: row.others,
                    splitRows: _splitRows
                });
            }
        } else {
            this.resetInputs();
            this.getItems();
        }
    }

    stopProcess = () => {
        this.resetInputs();
        this.getItems();
    }

    handleChangeGeneralTermsChekbox = (index, e) => {
        let list = this.state.generalTermsRows;
        list[index].checked = e.target.checked;

        this.setState({
            generalTermsRows: list.slice()
        });
    }

    handleChangeGeneralTermsDescription = (index, e) => {
        let list = this.state.generalTermsRows;
        list[index].description = e.target.value;

        this.setState({
            generalTermsRows: list.slice()
        });
    }

    addNewGeneralTermLine = () => {
        _generalTermsRows.push(
            {
                generalTermIndex: _generalTermsRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            generalTermsRows: _generalTermsRows
        });
    }

    removeGeneralTermLine = (index, e) => {
        if (_generalTermsRows.length !== 1) {
            _generalTermsRows.splice(index, 1);
        }

        this.setState({
            generalTermsRows: _generalTermsRows
        });
    }

    beforeUpload = (file) => {
        const isPDF =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/pdf";
        if (!isPDF) {
            message.error("You can only upload JPEG, PNG or PDF file!");
            $(".ant-upload-list").empty();
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("Image must smaller than 10MB!");
            $(".ant-upload-list").empty();
        }
        return isPDF && isLt10M;
    }

    handleGeneralTermFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            var flagGeneralTermFiles = [];
            if (info.file.response.length > 0) {
                for (let index = 0; index < this.state.generalTermFiles.length; index++) {
                    if (this.state.generalTermFiles[index].id !== undefined)
                        flagGeneralTermFiles.push({
                            id: this.state.generalTermFiles[index].id,
                            requestForQuotationId: this.state.generalTermFiles[index].requestForQuotationId,
                            rfqGeneralTermFilePath: this.state.generalTermFiles[index].rfqGeneralTermFilePath
                        });
                    else
                        flagGeneralTermFiles.push({
                            id: 0,
                            requestForQuotationId: 0,
                            rfqGeneralTermFilePath: this.state.generalTermFiles[index].rfqGeneralTermFilePath
                        });
                }

                flagGeneralTermFiles.push({
                    id: 0,
                    requestForQuotationId: 0,
                    rfqGeneralTermFilePath: info.file.response[0]
                });

                this.setState({
                    generalTermFiles: flagGeneralTermFiles,
                });
            }
        } else if (info.file.status === "removed") {
            var flagGeneralTermFiles2 = [];
            for (let index = 0; index < this.state.generalTermFiles.length; index++) {
                if (this.state.generalTermFiles[index].id !== undefined)
                    flagGeneralTermFiles2.push({
                        id: this.state.generalTermFiles[index].id,
                        requestForQuotationId: this.state.generalTermFiles[index].requestForQuotationId,
                        rfqGeneralTermFilePath: this.state.generalTermFiles[index].rfqGeneralTermFilePath
                    });
                else
                    flagGeneralTermFiles2.push({
                        id: 0,
                        requestForQuotationId: 0,
                        rfqGeneralTermFilePath: this.state.generalTermFiles[index].rfqGeneralTermFilePath
                    });
            }

            for (let index = 0; index < flagGeneralTermFiles2.length; index++) {
                if (flagGeneralTermFiles2[index].rfqGeneralTermFilePath === info.file.response[0]) {
                    console.log(info.file.response[0]);
                    flagGeneralTermFiles2.splice(index, 1);
                }
            }

            console.log(flagGeneralTermFiles2);

            this.setState({
                generalTermFiles: flagGeneralTermFiles2,
            });
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleChangeTechReqsChekbox = (index, e) => {
        let list = this.state.techReqsRows;
        list[index].checked = e.target.checked;

        this.setState({
            techReqsRows: list.slice()
        });
    }

    handleChangeTechReqsDescription = (index, e) => {
        let list = this.state.techReqsRows;
        list[index].description = e.target.value;

        this.setState({
            techReqsRows: list.slice()
        });
    }

    addNewTechReq = () => {
        _techReqsRows.push(
            {
                techReqIndex: _techReqsRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            techReqsRows: _techReqsRows
        });
    }

    removeTechReqLine = (index, e) => {
        if (_techReqsRows.length !== 1) {
            _techReqsRows.splice(index, 1);
        }

        this.setState({
            techReqsRows: _techReqsRows
        });
    }

    handleTechReqFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            var flagTechReqFiles = [];
            if (info.file.response.length > 0) {
                for (let index = 0; index < this.state.techReqFiles.length; index++) {
                    if (this.state.techReqFiles[index].id !== undefined)
                        flagTechReqFiles.push({
                            id: this.state.techReqFiles[index].id,
                            requestForQuotationId: this.state.techReqFiles[index].requestForQuotationId,
                            rfqTechReqFilePath: this.state.techReqFiles[index].rfqTechReqFilePath
                        });
                    else
                        flagTechReqFiles.push({
                            id: 0,
                            requestForQuotationId: 0,
                            rfqTechReqFilePath: this.state.techReqFiles[index].rfqTechReqFilePath
                        });
                }

                flagTechReqFiles.push({
                    id: 0,
                    requestForQuotationId: 0,
                    rfqTechReqFilePath: info.file.response[0]
                });

                this.setState({
                    techReqFiles: flagTechReqFiles,
                });
            }
        } else if (info.file.status === "removed") {
            var flagTechReqFiles2 = [];
            for (let index = 0; index < this.state.techReqFiles.length; index++) {
                if (this.state.techReqFiles[index].id !== undefined)
                    flagTechReqFiles2.push({
                        id: this.state.techReqFiles[index].id,
                        requestForQuotationId: this.state.techReqFiles[index].requestForQuotationId,
                        rfqTechReqFilePath: this.state.techReqFiles[index].rfqTechReqFilePath
                    });
                else
                    flagTechReqFiles2.push({
                        id: 0,
                        requestForQuotationId: 0,
                        rfqTechReqFilePath: this.state.techReqFiles[index].rfqTechReqFilePath
                    });
            }

            for (let index = 0; index < flagTechReqFiles2.length; index++) {
                if (flagTechReqFiles2[index].rfqTechReqFilePath === info.file.response[0])
                    flagTechReqFiles2.splice(index, 1);
            }

            console.log(flagTechReqFiles2);

            this.setState({
                techReqFiles: flagTechReqFiles2,
            });
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handleChangePriceChekbox = (index, e) => {
        let list = this.state.priceRows;
        list[index].checked = e.target.checked;

        this.setState({
            priceRows: list.slice()
        });
    }

    handleChangePriceDescription = (index, e) => {
        let list = this.state.priceRows;
        list[index].description = e.target.value;

        this.setState({
            priceRows: list.slice()
        });
    }

    addNewPrice = () => {
        _priceRows.push(
            {
                priceIndex: _priceRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            priceRows: _priceRows
        });
    }

    removePriceLine = (index, e) => {
        _priceRows.splice(index, 1);

        this.setState({
            priceRows: _priceRows
        });
    }

    handleChangePaymentChekbox = (index, e) => {
        let list = this.state.paymentRows;
        list[index].checked = e.target.checked;

        this.setState({
            paymentRows: list.slice()
        });
    }

    handleChangePaymentDescription = (index, e) => {
        let list = this.state.paymentRows;
        list[index].description = e.target.value;

        this.setState({
            paymentRows: list.slice()
        });
    }

    addNewPayment = () => {
        _paymentRows.push(
            {
                paymentIndex: _paymentRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            paymentRows: _paymentRows
        });
    }

    removePaymentLine = (index, e) => {
        _paymentRows.splice(index, 1);

        this.setState({
            paymentRows: _paymentRows
        });
    }

    onMarkerPositionChanged = (e) => {
        this.setState({
            markerPosition: { lat: e.latLng.lat(), lng: e.latLng.lng() }
        });
    }

    onChangeDateDelivery = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                deliveryDateModal: dateString
            });
        } else {
            this.setState({
                deliveryDateModal: today
            });
        }
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    handleChangeEvaCriteriasChekbox = (index, e) => {
        let list = this.state.evaCriteriasRows;
        list[index].checked = e.target.checked;

        this.setState({
            evaCriteriasRows: list.slice()
        });
    }

    handleChangeEvaCriteriasDescription = (index, e) => {
        let list = this.state.evaCriteriasRows;
        list[index].description = e.target.value;

        this.setState({
            evaCriteriasRows: list.slice()
        });
    }

    addNewEvaCriterias = () => {
        _evaCriteriasRows.push(
            {
                evaCriteriaIndex: _evaCriteriasRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            evaCriteriasRows: _evaCriteriasRows
        });
    }

    removeEvaCriteriasLine = (index, e) => {
        _evaCriteriasRows.splice(index, 1);

        this.setState({
            evaCriteriasRows: _evaCriteriasRows
        });
    }

    onChangeReceptionOptions = (checkedValues) => {
        this.setState({
            selectedReception: checkedValues
        });
    }

    onChangeModeOfTransportOptions = (checkedValues) => {
        this.setState({
            selectedTransport: checkedValues
        });
    }

    handleChangeWarrantyChekbox = (index, e) => {
        let list = this.state.warrantyRows;
        list[index].checked = e.target.checked;

        this.setState({
            warrantyRows: list.slice()
        });
    }

    handleChangeWarrantyDescription = (index, e) => {
        let list = this.state.warrantyRows;
        list[index].description = e.target.value;

        this.setState({
            warrantyRows: list.slice()
        });
    }

    addNewWarranty = () => {
        _warrantyRows.push(
            {
                warrantyIndex: _warrantyRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            warrantyRows: _warrantyRows
        });
    }

    removeWarrantyLine = (index, e) => {
        if (_warrantyRows.length !== 1) {
            _warrantyRows.splice(index, 1);
        }

        this.setState({
            warrantyRows: _warrantyRows
        });
    }

    handleChangeLanguageChekbox = (index, e) => {
        let list = this.state.languageRows;
        list[index].checked = e.target.checked;

        this.setState({
            languageRows: list.slice()
        });
    }

    handleChangeLanguageDescription = (index, e) => {
        let list = this.state.languageRows;
        list[index].description = e.target.value;

        this.setState({
            languageRows: list.slice()
        });
    }

    addNewLanguage = () => {
        _languageRows.push(
            {
                languageIndex: _languageRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            languageRows: _languageRows
        });
    }

    removeLanguageLine = (index, e) => {
        if (_languageRows.length !== 1) {
            _languageRows.splice(index, 1);
        }

        this.setState({
            languageRows: _languageRows
        });
    }

    handleChangeRequestedDocumentChekbox = (index, e) => {
        let list = this.state.requestedDocumentsRows;
        list[index].checked = e.target.checked;

        this.setState({
            requestedDocumentsRows: list.slice()
        });
    }

    handleChangeRequestedDocumentDescription = (index, e) => {
        let list = this.state.requestedDocumentsRows;
        list[index].description = e.target.value;

        this.setState({
            requestedDocumentsRows: list.slice()
        });
    }

    addNewRequestedDocument = () => {
        _requestedDocumentsRows.push(
            {
                requestedDocumentIndex: _requestedDocumentsRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            requestedDocumentsRows: _requestedDocumentsRows
        });
    }

    removeRequestedDocumentLine = (index, e) => {
        if (_requestedDocumentsRows.length !== 1) {
            _requestedDocumentsRows.splice(index, 1);
        }

        this.setState({
            requestedDocumentsRows: _requestedDocumentsRows
        });
    }

    handleChangeOtherChekbox = (index, e) => {
        let list = this.state.otherRows;
        list[index].checked = e.target.checked;

        this.setState({
            otherRows: list.slice()
        });
    }

    handleChangeOtherDescription = (index, e) => {
        let list = this.state.otherRows;
        list[index].description = e.target.value;

        this.setState({
            otherRows: list.slice()
        });
    }

    addNewOther = () => {
        if (this.state.otherRows[this.state.otherRows.length - 1].description !== "") {
            _otherRows.push(
                {
                    otherIndex: _otherRows.length + 1,
                    description: "",
                    checked: false
                }
            );

            this.setState({
                otherRows: _otherRows
            });
        }
    }

    removeOtherLine = (index, e) => {
        if (_otherRows.length !== 1) {
            _otherRows.splice(index, 1);
        }

        this.setState({
            otherRows: _otherRows
        });
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");
        if (response.data.length === 0) {
            this.setState({
                rfqList: [],
                lastRfq: [],
                rfqNo: "RFQ-001"
            });
        }

        if (response.data.length !== 0) {
            let flagLength = response.data[response.data.length - 1].id.toString();
            let flagNumber = flagLength.padStart(3, '0');
            this.setState({
                rfqList: response.data,
                lastRfq: response.data[response.data.length - 1],
                rfqNo: "RFQ-" + flagNumber
            });
        }

        this.setState({
            rfqId: null
        });
    }

    deleteModal = (row) => {
        this.setState({
            rfqId: row.id,
            selectedRfq: row,
            deleteModal: true
        });
    }

    deleteRFQ = async () => {
        if (this.state.rfqId !== null) {
            const selectedRFQ1 = {
                id: this.state.rfqId
            }
            var response = await handleRequest("POST", "/RequestForQuotation/delete", selectedRFQ1);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    // var selectedItem = this.state.allItems.find(p => p.id === this.state.selectedRfq.itemId);
                    // const updatedProjectTakeOffList = {
                    //     id: selectedItem.id,
                    //     type: selectedItem.type,
                    //     groupId: selectedItem.groupId,
                    //     disciplineId: selectedItem.disciplineId,
                    //     description: selectedItem.description,
                    //     code: selectedItem.code,
                    //     location: selectedItem.location,
                    //     quantity: selectedItem.quantity,
                    //     unitId: selectedItem.unitId,
                    //     clientCode: selectedItem.clientCode,
                    //     drawingPath: selectedItem.drawingPath,
                    //     spec: "Done",
                    //     rfq: ""
                    // };
                    // var response2 = await handleRequest("POST", "/ProjectTakeOffList/update", updatedProjectTakeOffList);
                    this.getItems();
                    this.setState({
                        deleteModal: false,
                    });
                    this.getItems();
                    this.restartTable();
                    this.showSuccess("Data has been deleted successfully.");
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }

            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0px";
            }, 500);
        }
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdate = async (row) => {
        const response = await handleRequest("GET", "/ProjectTakeOffList/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                items: [],
            });
        }

        if (response.data.length !== 0) {
            let responseData = response.data;
            let selectedResponseData = [];
            for (let index = 0; index < responseData.length; index++) {
                if (responseData[index].spec !== undefined && responseData[index].rfq !== undefined) {
                    if (responseData[index].spec === "Done")
                        selectedResponseData.push(responseData[index]);
                }
            }
            this.setState({
                items: selectedResponseData
            });
        }

        var updatedGeneralTerms = [];
        var updatedGeneralTermFiles = [];
        var updatedTechReqs = [];
        var updatedTechReqFiles = [];
        var updatedPrices = [];
        var updatedPayments = [];
        var updatedEvaCriterias = [];
        var updatedWarranties = [];
        var updatedLanguages = [];
        var updatedRequestedDocuments = [];
        var updatedOthers = [];

        for (let index = 0; index < row.generalTerms.length; index++) {
            updatedGeneralTerms.push({
                id: row.generalTerms[index].id,
                active: row.generalTerms[index].active,
                generalTermIndex: index + 1,
                checked: row.generalTerms[index].rfqGeneralTermCheck,
                necessary: row.generalTerms[index].necessary,
                point: row.generalTerms[index].point,
                description: row.generalTerms[index].rfqGeneralTerm
            });
        }

        if (updatedGeneralTerms.length === 0) {
            updatedGeneralTerms.push({
                generalTermIndex: 1,
                description: "",
                checked: false
            });
        }

        _generalTermsRows = updatedGeneralTerms;

        for (let index = 0; index < row.generalTermFiles.length; index++) {
            updatedGeneralTermFiles.push({
                id: row.generalTermFiles[index].id,
                active: row.generalTermFiles[index].active,
                rfqGeneralTermFilePath: row.generalTermFiles[index].rfqGeneralTermFilePath,

            });
        }

        for (let index = 0; index < row.techRequirements.length; index++) {
            updatedTechReqs.push({
                id: row.techRequirements[index].id,
                active: row.techRequirements[index].active,
                necessary: row.techRequirements[index].necessary,
                point: row.techRequirements[index].point,

                techReqIndex: index + 1,
                checked: row.techRequirements[index].rfqTechRequirementCheck,
                description: row.techRequirements[index].rfqTechRequirement
            });
        }

        if (updatedTechReqs.length === 0) {
            updatedTechReqs.push({
                techReqIndex: 1,
                description: "",
                checked: false
            });
        }

        _techReqsRows = updatedTechReqs;

        for (let index = 0; index < row.techRequirementFiles.length; index++) {
            updatedTechReqFiles.push({
                id: row.techRequirementFiles[index].id,
                active: row.techRequirementFiles[index].active,
                index: index + 1,
                rfqTechReqFilePath: row.techRequirementFiles[index].rfqTechReqFilePath,
            });
        }

        for (let index = 0; index < row.prices.length; index++) {
            updatedPrices.push({
                id: row.prices[index].id,
                active: row.prices[index].active,
                necessary: row.prices[index].necessary,
                point: row.prices[index].point,

                priceIndex: index + 1,
                checked: row.prices[index].rfqPriceCheck,
                description: row.prices[index].rfqPrice
            });
        }

        _priceRows = updatedPrices;

        for (let index = 0; index < row.paymentTerms.length; index++) {
            updatedPayments.push({
                id: row.paymentTerms[index].id,
                active: row.paymentTerms[index].active,
                necessary: row.paymentTerms[index].necessary,
                point: row.paymentTerms[index].point,

                paymentIndex: index + 1,
                checked: row.paymentTerms[index].rfqPaymentTermCheck,
                description: row.paymentTerms[index].rfqPaymentTerm
            });
        }

        _paymentRows = updatedPayments;

        for (let index = 0; index < row.evaCriterias.length; index++) {
            updatedEvaCriterias.push({
                id: row.evaCriterias[index].id,
                active: row.evaCriterias[index].active,
                necessary: row.evaCriterias[index].necessary,
                point: row.evaCriterias[index].point,

                evaCriteriaIndex: index + 1,
                checked: row.evaCriterias[index].rfqEvaCriteriaCheck,
                description: row.evaCriterias[index].rfqEvaCriteria
            });
        }

        _evaCriteriasRows = updatedEvaCriterias;

        for (let index = 0; index < row.warranties.length; index++) {
            updatedWarranties.push({
                id: row.warranties[index].id,
                active: row.warranties[index].active,
                necessary: row.warranties[index].necessary,
                point: row.warranties[index].point,

                warrantyIndex: index + 1,
                checked: row.warranties[index].rfqWarrantyCheck,
                description: row.warranties[index].rfqWarranty
            });
        }

        if (updatedWarranties.length === 0) {
            updatedWarranties.push({
                warrantyIndex: 1,
                description: "",
                checked: false
            });
        }

        _warrantyRows = updatedWarranties;

        for (let index = 0; index < row.languages.length; index++) {
            updatedLanguages.push({
                id: row.languages[index].id,
                active: row.languages[index].active,
                necessary: row.languages[index].necessary,
                point: row.languages[index].point,

                languageIndex: index + 1,
                checked: row.languages[index].rfqLanguageCheck,
                description: row.languages[index].rfqLanguage
            });
        }

        if (updatedLanguages.length === 0) {
            updatedLanguages.push({
                languageIndex: 1,
                description: "",
                checked: false
            });
        }

        _languageRows = updatedLanguages;

        for (let index = 0; index < row.requestedDocuments.length; index++) {
            updatedRequestedDocuments.push({
                id: row.requestedDocuments[index].id,
                active: row.requestedDocuments[index].active,
                necessary: row.requestedDocuments[index].necessary,
                point: row.requestedDocuments[index].point,

                requestedDocumentIndex: index + 1,
                checked: row.requestedDocuments[index].rfqRequestedDocumentCheck,
                description: row.requestedDocuments[index].rfqRequestedDocument
            });
        }

        if (updatedRequestedDocuments.length === 0) {
            updatedRequestedDocuments.push({
                requestedDocumentIndex: 1,
                description: "",
                checked: false
            });
        }

        _requestedDocumentsRows = updatedRequestedDocuments;

        for (let index = 0; index < row.others.length; index++) {
            updatedOthers.push({
                id: row.others[index].id,
                active: row.others[index].active,
                necessary: row.others[index].necessary,
                point: row.others[index].point,

                otherIndex: index + 1,
                checked: row.others[index].rfqOtherCheck,
                description: row.others[index].rfqOther
            });
        }

        if (updatedOthers.length === 0) {
            updatedOthers.push({
                otherIndex: 1,
                description: "",
                checked: false
            });
        }

        _otherRows = updatedOthers;

        this.setState({
            hidden: true,
            modalEdit: true,
            rfqNo: row.rfqNo,
            rfqId: row.id,
            itemId: row.itemId,
            generalTerms: row.generalTermCheck,
            generalTermsRowsBackup: row.generalTerms,
            generalTermsRows: updatedGeneralTerms,
            generalTermFiles: row.generalTermFiles,
            techReqs: row.techRequirementCheck,
            techReqsRows: updatedTechReqs,
            techReqsRowsBackup: row.techRequirements,
            techReqFiles: row.techRequirementFiles,
            price: row.priceCheck,
            yourOfferPrice: row.yourOfferPrice,
            priceRows: updatedPrices,
            priceRowsBackup: row.prices,
            payment: row.paymentTermCheck,
            yourOfferPayment: row.yourOfferPayment,
            paymentRows: updatedPayments,
            paymentRowsBackup: row.paymentTerms,
            deliveryPlace: row.deliveryPlaceCheck,
            deliveryPlaceText: row.deliveryPlace,
            deliveryPlaceNecessary: row.deliveryPlaceNecessary,
            deliveryPlacePoint: row.deliveryPlacePoint,
            quoteValidityPoint: row.quoteValidityPoint,  
            quoteValidityNecessary: row.quoteValidityNecessary,
            markerPosition: { lat: parseFloat(row.deliveryPlaceLat), lng: parseFloat(row.deliveryPlaceLng) },
            validityQuotation: row.quoteValidityCheck,
            validityQuotationValue: row.quoteValidityValue,
            validityQuotationType: row.quoteValidityDateType,
            evaluationCriterias: row.evaCriteriaCheck,
            evaDeliveryDate: row.evaCriteriaDeliveryDateCheck,
            evaPrice: row.evaCriteriaPriceCheck,
            evaCriteriasRows: updatedEvaCriterias,
            evaCriteriasRowsBackup: row.evaCriterias,
            receptionOfQuotation: row.receptionForQuotationCheck,
            receptionOtherCheck: row.receptionForQuotationOtherCheck,
            receptionOther: row.receptionForQuotationOtherOption,
            selectedReception: row.receptionForQuotationOptions.split(","),
            modeOfTransport: row.modeOfTranportOtherCheck,
            transportOtherCheck: row.modeOfTransportCheck,
            transportOther: row.modeOfTranportOtherOption,
            selectedTransport: row.receptionForQuotationOptions.split(","),
            warranty: row.warrantyCheck,
            warrantyRows: updatedWarranties,
            warrantyRowsBackup: row.warranties,
            language: row.languageCheck,
            languageRows: updatedLanguages,
            languageRowsBackup: row.languages,
            requestedDocuments: row.requestedDocumentCheck,
            requestedDocumentsRows: updatedRequestedDocuments,
            requestedDocumentsRowsBackup: row.requestedDocuments,
            other: row.otherCheck,
            otherRows: updatedOthers,
            otherRowsBackup: row.others,
            updatedProjectTakeOffLists: row.projectTakeOffLists,
            updatedProjectTakeOffListSubs: row.projectTakeOffListSubs,
            updatedVendorLogs: row.vendorLogs,
            vendorEvaluationDate: row.evaluationDate
        });
    }

    updateRFQ = async () => {
        if (this.state.rfqId !== null && this.state.rfqNo !== "") {

            var flagGeneralTerms = [];
            var flagGeneralTermFiles = [];
            var flagTechReqs = [];
            var flagTechReqFiles = [];
            var flagPrices = [];
            var flagPayments = [];
            var flagEvaCriterias = [];
            var flagWarranties = [];
            var flagLanguages = [];
            var flagRequestedDocuments = [];
            var flagOthers = [];

            if (this.state.generalTerms)
                for (let index = 0; index < this.state.generalTermsRows.length; index++) {
                    if (this.state.generalTermsRows[index].id !== undefined) {
                        if (this.state.generalTermsRows[index].id !== null) {
                            flagGeneralTerms.push({
                                id: this.state.generalTermsRows[index].id,
                                active: true,
                                rfqGeneralTermCheck: this.state.generalTermsRows[index].checked,
                                rfqGeneralTerm: this.state.generalTermsRows[index].description,
                                necessary: this.state.generalTermsRows[index].necessary,
                                point: this.state.generalTermsRows[index].point
                            });
                        }
                    } else {
                        flagGeneralTerms.push({
                            rfqGeneralTermCheck: this.state.generalTermsRows[index].checked,
                            rfqGeneralTerm: this.state.generalTermsRows[index].description,
                            necessary: this.state.generalTermsRows[index].necessary,
                            point: this.state.generalTermsRows[index].point

                        });
                    }
                }

            for (let index = 0; index < this.state.generalTermsRowsBackup.length; index++) {
                let whichIndexGeneralTerm = this.state.generalTermsRows.findIndex(i => Boolean(i.id) && i.id === this.state.generalTermsRowsBackup[index].id);

                if (whichIndexGeneralTerm === -1) {
                    flagGeneralTerms.push({
                        id: this.state.generalTermsRowsBackup[index].id,
                        active: false,
                        rfqGeneralTermCheck: this.state.generalTermsRowsBackup[index].rfqGeneralTermCheck,
                        rfqGeneralTerm: this.state.generalTermsRowsBackup[index].rfqGeneralTerm
                    });
                }
            }

            for (let index = 0; index < this.state.generalTermFiles.length; index++) {
                if (this.state.generalTermFiles[index].id !== undefined) {
                    if (this.state.generalTermFiles[index].id !== null) {
                        flagGeneralTermFiles.push({
                            id: this.state.generalTermFiles[index].id,
                            active: true,
                            rfqGeneralTermFilePath: this.state.generalTermFiles[index].rfqGeneralTermFilePath
                        });
                    }
                } else {
                    flagGeneralTermFiles.push({
                        rfqGeneralTermFilePath: this.state.generalTermFiles[index].rfqGeneralTermFilePath
                    });
                }
            }

            if (this.state.techReqs)
                for (let index = 0; index < this.state.techReqsRows.length; index++) {
                    if (this.state.techReqsRows[index].id !== undefined) {
                        if (this.state.techReqsRows[index].id !== null) {
                            flagTechReqs.push({
                                id: this.state.techReqsRows[index].id,
                                active: true,
                                rfqTechRequirementCheck: this.state.techReqsRows[index].checked,
                                rfqTechRequirement: this.state.techReqsRows[index].description,
                                necessary: this.state.techReqsRows[index].necessary,
                                point: this.state.techReqsRows[index].point

                            });
                        }
                    } else {
                        flagTechReqs.push({
                            rfqTechRequirementCheck: this.state.techReqsRows[index].checked,
                            rfqTechRequirement: this.state.techReqsRows[index].description,
                            necessary: this.state.techReqsRows[index].necessary,
                            point: this.state.techReqsRows[index].point


                        });
                    }
                }

            for (let index = 0; index < this.state.techReqsRowsBackup.length; index++) {
                let whichIndexTechReq = this.state.techReqsRows.findIndex(i => Boolean(i.id) && i.id === this.state.techReqsRowsBackup[index].id);

                if (whichIndexTechReq === -1) {
                    flagTechReqs.push({
                        id: this.state.techReqsRowsBackup[index].id,
                        active: false,
                        rfqTechRequirementCheck: this.state.techReqsRowsBackup[index].rfqTechRequirementCheck,
                        rfqTechRequirement: this.state.techReqsRowsBackup[index].rfqTechRequirement
                    });
                }
            }

            for (let index = 0; index < this.state.techReqFiles.length; index++) {
                if (this.state.techReqFiles[index].id !== undefined) {
                    if (this.state.techReqFiles[index].id !== null) {
                        flagTechReqFiles.push({
                            id: this.state.techReqFiles[index].id,
                            active: true,
                            rfqTechReqFilePath: this.state.techReqFiles[index].rfqTechReqFilePath
                        });
                    }
                } else {
                    flagTechReqFiles.push({
                        id: 0,
                        rfqTechReqFilePath: this.state.techReqFiles[index].rfqTechReqFilePath
                    });
                }
            }

            if (this.state.price)
                for (let index = 0; index < this.state.priceRows.length; index++) {
                    if (this.state.priceRows[index].id !== undefined) {
                        if (this.state.priceRows[index].id !== null) {
                            flagPrices.push({
                                id: this.state.priceRows[index].id,
                                active: true,
                                rfqPriceCheck: this.state.priceRows[index].checked,
                                rfqPrice: this.state.priceRows[index].description,
                                necessary: this.state.priceRows[index].necessary,
                                point: this.state.priceRows[index].point


                            });
                        }
                    } else {
                        flagPrices.push({
                            rfqPriceCheck: this.state.priceRows[index].checked,
                            rfqPrice: this.state.priceRows[index].description,
                            necessary: this.state.priceRows[index].necessary,
                            point: this.state.priceRows[index].point


                        });
                    }
                }

            for (let index = 0; index < this.state.priceRowsBackup.length; index++) {
                let whichIndexPrice = this.state.priceRows.findIndex(i => Boolean(i.id) && i.id === this.state.priceRowsBackup[index].id);

                if (whichIndexPrice === -1) {
                    flagPrices.push({
                        id: this.state.priceRowsBackup[index].id,
                        active: false,
                        rfqPriceCheck: this.state.priceRowsBackup[index].rfqPriceCheck,
                        rfqPrice: this.state.priceRowsBackup[index].rfqPrice
                    });
                }
            }

            if (this.state.payment)
                for (let index = 0; index < this.state.paymentRows.length; index++) {
                    if (this.state.paymentRows[index].id !== undefined) {
                        if (this.state.paymentRows[index].id !== null) {
                            flagPayments.push({
                                id: this.state.paymentRows[index].id,
                                active: true,
                                rfqPaymentTermCheck: this.state.paymentRows[index].checked,
                                rfqPaymentTerm: this.state.paymentRows[index].description,
                                necessary: this.state.paymentRows[index].necessary,
                                point: this.state.paymentRows[index].point

                            });
                        }
                    } else {
                        flagPayments.push({
                            rfqPaymentTermCheck: this.state.paymentRows[index].checked,
                            rfqPaymentTerm: this.state.paymentRows[index].description,
                            necessary: this.state.paymentRows[index].necessary,
                            point: this.state.paymentRows[index].point


                        });
                    }
                }

            for (let index = 0; index < this.state.paymentRowsBackup.length; index++) {
                let whichIndexPayment = this.state.paymentRows.findIndex(i => Boolean(i.id) && i.id === this.state.paymentRowsBackup[index].id);

                if (whichIndexPayment === -1) {
                    flagPayments.push({
                        id: this.state.paymentRowsBackup[index].id,
                        active: false,
                        rfqPaymentTermCheck: this.state.paymentRowsBackup[index].rfqPaymentTermCheck,
                        rfqPaymentTerm: this.state.paymentRowsBackup[index].rfqPaymentTerm
                    });
                }
            }

            if (this.state.evaluationCriterias)
                for (let index = 0; index < this.state.evaCriteriasRows.length; index++) {
                    if (this.state.evaCriteriasRows[index].id !== undefined) {
                        if (this.state.evaCriteriasRows[index].id !== null) {
                            flagEvaCriterias.push({
                                id: this.state.evaCriteriasRows[index].id,
                                active: true,
                                rfqEvaCriteriaCheck: this.state.evaCriteriasRows[index].checked,
                                rfqEvaCriteria: this.state.evaCriteriasRows[index].description,
                                necessary: this.state.evaCriteriasRows[index].necessary,
                                point: this.state.evaCriteriasRows[index].point


                            });
                        }
                    } else {
                        flagEvaCriterias.push({
                            rfqEvaCriteriaCheck: this.state.evaCriteriasRows[index].checked,
                            rfqEvaCriteria: this.state.evaCriteriasRows[index].description,
                            necessary: this.state.evaCriteriasRows[index].necessary,
                            point: this.state.evaCriteriasRows[index].point


                        });
                    }
                }

            for (let index = 0; index < this.state.evaCriteriasRowsBackup.length; index++) {
                let whichIndexEva = this.state.evaCriteriasRows.findIndex(i => Boolean(i.id) && i.id === this.state.evaCriteriasRowsBackup[index].id);

                if (whichIndexEva === -1) {
                    flagEvaCriterias.push({
                        id: this.state.evaCriteriasRowsBackup[index].id,
                        active: false,
                        rfqEvaCriteriaCheck: this.state.evaCriteriasRowsBackup[index].rfqEvaCriteriaCheck,
                        rfqEvaCriteria: this.state.evaCriteriasRowsBackup[index].rfqEvaCriteria,

                    });
                }
            }

            if (this.state.warranty)
                for (let index = 0; index < this.state.warrantyRows.length; index++) {
                    if (this.state.warrantyRows[index].id !== undefined) {
                        if (this.state.warrantyRows[index].id !== null) {
                            flagWarranties.push({
                                id: this.state.warrantyRows[index].id,
                                active: true,
                                rfqWarrantyCheck: this.state.warrantyRows[index].checked,
                                rfqWarranty: this.state.warrantyRows[index].description,
                                necessary: this.state.warrantyRows[index].necessary,
                                point: this.state.warrantyRows[index].point

                            });
                        }
                    } else {
                        flagWarranties.push({
                            rfqWarrantyCheck: this.state.warrantyRows[index].checked,
                            rfqWarranty: this.state.warrantyRows[index].description,
                            necessary: this.state.warrantyRows[index].necessary,
                            point: this.state.warrantyRows[index].point

                        });
                    }
                }

            for (let index = 0; index < this.state.warrantyRowsBackup.length; index++) {
                let whichIndexWarranty = this.state.warrantyRows.findIndex(i => Boolean(i.id) && i.id === this.state.warrantyRowsBackup[index].id);

                if (whichIndexWarranty === -1) {
                    flagWarranties.push({
                        id: this.state.warrantyRowsBackup[index].id,
                        active: false,
                        rfqWarrantyCheck: this.state.warrantyRowsBackup[index].rfqWarrantyCheck,
                        rfqWarranty: this.state.warrantyRowsBackup[index].rfqWarranty
                    });
                }
            }

            if (this.state.language)
                for (let index = 0; index < this.state.languageRows.length; index++) {
                    if (this.state.languageRows[index].id !== undefined) {
                        if (this.state.languageRows[index].id !== null) {
                            flagLanguages.push({
                                id: this.state.languageRows[index].id,
                                active: true,
                                rfqLanguageCheck: this.state.languageRows[index].checked,
                                rfqLanguage: this.state.languageRows[index].description,
                                necessary: this.state.languageRows[index].necessary,
                                point: this.state.languageRows[index].point

                            });
                        }
                    } else {
                        flagLanguages.push({
                            rfqLanguageCheck: this.state.languageRows[index].checked,
                            rfqLanguage: this.state.languageRows[index].description,
                            necessary: this.state.languageRows[index].necessary,
                            point: this.state.languageRows[index].point


                        });
                    }
                }

            for (let index = 0; index < this.state.languageRowsBackup.length; index++) {
                let whichIndexLanguage = this.state.languageRows.findIndex(i => Boolean(i.id) && i.id === this.state.languageRowsBackup[index].id);

                if (whichIndexLanguage === -1) {
                    flagLanguages.push({
                        id: this.state.languageRowsBackup[index].id,
                        active: false,
                        rfqLanguageCheck: this.state.languageRowsBackup[index].rfqLanguageCheck,
                        rfqLanguage: this.state.languageRowsBackup[index].rfqLanguage
                    });
                }
            }

            if (this.state.requestedDocuments)
                for (let index = 0; index < this.state.requestedDocumentsRows.length; index++) {
                    if (this.state.requestedDocumentsRows[index].id !== undefined) {
                        if (this.state.requestedDocumentsRows[index].id !== null) {
                            flagRequestedDocuments.push({
                                id: this.state.requestedDocumentsRows[index].id,
                                active: true,
                                rfqRequestedDocumentCheck: this.state.requestedDocumentsRows[index].checked,
                                rfqRequestedDocument: this.state.requestedDocumentsRows[index].description,
                                necessary: this.state.requestedDocumentsRows[index].necessary,
                                point: this.state.requestedDocumentsRows[index].point

                            });
                        }
                    } else {
                        flagRequestedDocuments.push({
                            rfqRequestedDocumentCheck: this.state.requestedDocumentsRows[index].checked,
                            rfqRequestedDocument: this.state.requestedDocumentsRows[index].description,
                            necessary: this.state.requestedDocumentsRows[index].necessary,
                            point: this.state.requestedDocumentsRows[index].point


                        });
                    }
                }

            for (let index = 0; index < this.state.requestedDocumentsRowsBackup.length; index++) {
                let whichIndexReqDoc = this.state.requestedDocumentsRows.findIndex(i => Boolean(i.id) && i.id === this.state.requestedDocumentsRowsBackup[index].id);

                if (whichIndexReqDoc === -1) {
                    flagRequestedDocuments.push({
                        id: this.state.requestedDocumentsRowsBackup[index].id,
                        active: false,
                        rfqRequestedDocumentCheck: this.state.requestedDocumentsRowsBackup[index].rfqRequestedDocumentCheck,
                        rfqRequestedDocument: this.state.requestedDocumentsRowsBackup[index].rfqRequestedDocument,

                    });
                }
            }

            if (this.state.other)
                for (let index = 0; index < this.state.otherRows.length; index++) {
                    if (this.state.otherRows[index].id !== undefined) {
                        if (this.state.otherRows[index].id !== null) {
                            flagOthers.push({
                                id: this.state.otherRows[index].id,
                                active: true,
                                rfqOtherCheck: this.state.otherRows[index].checked,
                                rfqOther: this.state.otherRows[index].description,
                                necessary: this.state.otherRows[index].necessary,
                                point: this.state.otherRows[index].point,

                            });
                        }
                    } else {
                        flagOthers.push({
                            rfqOtherCheck: this.state.otherRows[index].checked,
                            rfqOther: this.state.otherRows[index].description,
                            necessary: this.state.otherRows[index].necessary,
                            point: this.state.otherRows[index].point,

                        });
                    }
                }

            for (let index = 0; index < this.state.otherRowsBackup.length; index++) {
                let whichIndexOther = this.state.otherRows.findIndex(i => Boolean(i.id) && i.id === this.state.otherRowsBackup[index].id);

                if (whichIndexOther === -1) {
                    flagOthers.push({
                        id: this.state.otherRowsBackup[index].id,
                        active: false,
                        rfqOtherCheck: this.state.otherRowsBackup[index].rfqOtherCheck,
                        rfqOther: this.state.otherRowsBackup[index].rfqOther
                    });
                }
            }




            for (let index = 0; index < this.state.otherRows.length; index++) {
                if (this.state.otherRows[index].id !== undefined) {
                    if (this.state.otherRows[index].id !== null) {
                        flagOthers.push({
                            id: this.state.otherRows[index].id,
                            active: true,
                            rfqOtherCheck: this.state.otherRows[index].checked,
                            rfqOther: this.state.otherRows[index].description
                        });
                    }
                } else {
                    flagOthers.push({
                        rfqOtherCheck: this.state.otherRows[index].checked,
                        rfqOther: this.state.otherRows[index].description
                    });
                }
            }

            for (let index = 0; index < this.state.otherRowsBackup.length; index++) {
                let whichIndexOther = this.state.otherRows.findIndex(i => Boolean(i.id) && i.id === this.state.otherRowsBackup[index].id);

                if (whichIndexOther === -1) {
                    flagOthers.push({
                        id: this.state.otherRowsBackup[index].id,
                        active: false,
                        rfqOtherCheck: this.state.otherRowsBackup[index].rfqOtherCheck,
                        rfqOther: this.state.otherRowsBackup[index].rfqOther
                    });
                }
            }

            const updatedRFQ = {
                id: this.state.rfqId,
                rfqNo: this.state.rfqNo,
                itemId: this.state.itemId,
                generalTermCheck: this.state.generalTerms,
                generalTermFiles: flagGeneralTermFiles,
                generalTerms: flagGeneralTerms,
                techRequirementCheck: this.state.techReqs,
                techRequirementFiles: flagTechReqFiles,
                techRequirements: flagTechReqs,
                priceCheck: this.state.price,
                prices: flagPrices,
                deliveryPlaceNecessary: this.state.deliveryPlaceNecessary,
                quoteValidityNecessary: this.state.quoteValidityNecessary,
                deliveryPlacePoint: this.state.deliveryPlacePoint,
                quoteValidityPoint: this.state.quoteValidityPoint,  
                paymentTermCheck: this.state.payment,
                paymentTerms: flagPayments,
                deliveryPlaceCheck: this.state.deliveryPlace,
                deliveryPlace: this.state.deliveryPlaceText,
                deliveryPlaceLat: this.state.markerPosition.lat,
                deliveryPlaceLng: this.state.markerPosition.lng,
                quoteValidityCheck: this.state.validityQuotation,
                quoteValidityValue: this.state.validityQuotationValue,
                quoteValidityDateType: this.state.validityQuotationType,
                evaCriteriaCheck: this.state.evaluationCriterias,
                evaCriteriaDeliveryDateCheck: this.state.evaDeliveryDate,
                evaCriteriaPriceCheck: this.state.evaPrice,
                evaCriterias: flagEvaCriterias,
                receptionForQuotationCheck: this.state.receptionOfQuotation,
                receptionForQuotationOptions: this.state.selectedReception.join(),
                receptionForQuotationOtherCheck: this.state.receptionOtherCheck,
                receptionForQuotationOtherOption: this.state.receptionOther,
                modeOfTransportCheck: this.state.modeOfTransport,
                modeOfTranportOptions: this.state.selectedTransport.join(),
                modeOfTranportOtherCheck: this.state.transportOtherCheck,
                modeOfTranportOtherOption: this.state.transportOther,
                warrantyCheck: this.state.warranty,
                warranties: flagWarranties,
                languageCheck: this.state.language,
                languages: flagLanguages,
                requestedDocumentCheck: this.state.requestedDocuments,
                requestedDocuments: flagRequestedDocuments,
                otherCheck: this.state.other,
                others: flagOthers,
                projectTakeOffLists: this.state.updatedProjectTakeOffLists,
                projectTakeOffListSubs: this.state.updatedProjectTakeOffListSubs,
                vendorLogs: this.state.updatedVendorLogs,
                evaluationDate: this.state.vendorEvaluationDate
            }

            var response = await handleRequest("POST", "/RequestForQuotation/update", updatedRFQ);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        modalEdit: false
                    });
                    this.getItems();
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess("All data has been updated successfully.");
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    saveRFQButton = () => {
        var selectedItem = this.state.items.find(p => p.id === this.state.itemId);
        let selectedItemForTable = [];
        selectedItemForTable.push(selectedItem);
        this.setState({
            selectedItem: selectedItemForTable
        });
        this.showDeliveryDateModal();
    }

    saveRFQ = async () => {
        if (this.state.rfqNo !== "" && this.state.selectedRowsVendor.length > 0) {

            var flagGeneralTerms = [];
            var flagTechReqs = [];
            var flagPrices = [];
            var flagPayments = [];
            var flagEvaCriterias = [];
            var flagWarranties = [];
            var flagLanguages = [];
            var flagRequestedDocuments = [];
            var flagOthers = [];

            if (this.state.generalTerms)
                for (let index = 0; index < this.state.generalTermsRows.length; index++) {
                    flagGeneralTerms.push({
                        rfqGeneralTermCheck: this.state.generalTermsRows[index].checked,
                        rfqGeneralTerm: this.state.generalTermsRows[index].description,
                        necessary: this.state.generalTermsRows[index].necessary,
                        point: this.state.generalTermsRows[index].point
                    });
                }

            if (this.state.techReqs)
                for (let index = 0; index < this.state.techReqsRows.length; index++) {
                    flagTechReqs.push({
                        rfqTechRequirementCheck: this.state.techReqsRows[index].checked,
                        rfqTechRequirement: this.state.techReqsRows[index].description,
                        necessary: this.state.techReqsRows[index].necessary,
                        point: this.state.techReqsRows[index].point


                    });
                }

            if (this.state.price)
                for (let index = 0; index < this.state.priceRows.length; index++) {
                    flagPrices.push({
                        rfqPriceCheck: this.state.priceRows[index].checked,
                        rfqPrice: this.state.priceRows[index].description,
                        necessary: this.state.priceRows[index].necessary,
                        point: this.state.priceRows[index].point


                    });
                }

            if (this.state.payment)
                for (let index = 0; index < this.state.paymentRows.length; index++) {
                    flagPayments.push({
                        rfqPaymentTermCheck: this.state.paymentRows[index].checked,
                        rfqPaymentTerm: this.state.paymentRows[index].description,
                        necessary: this.state.paymentRows[index].necessary,
                        point: this.state.paymentRows[index].point


                    });
                }

            if (this.state.evaluationCriterias)
                for (let index = 0; index < this.state.evaCriteriasRows.length; index++) {
                    flagEvaCriterias.push({
                        rfqEvaCriteriaCheck: this.state.evaCriteriasRows[index].checked,
                        rfqEvaCriteria: this.state.evaCriteriasRows[index].description,
                        necessary: this.state.evaCriteriasRows[index].necessary,
                        point: this.state.evaCriteriasRows[index].point


                    });
                }

            if (this.state.warranty)
                for (let index = 0; index < this.state.warrantyRows.length; index++) {
                    flagWarranties.push({
                        rfqWarrantyCheck: this.state.warrantyRows[index].checked,
                        rfqWarranty: this.state.warrantyRows[index].description,
                        necessary: this.state.warrantyRows[index].necessary,
                        point: this.state.warrantyRows[index].point


                    });
                }

            if (this.state.language)
                for (let index = 0; index < this.state.languageRows.length; index++) {
                    flagLanguages.push({
                        rfqLanguageCheck: this.state.languageRows[index].checked,
                        rfqLanguage: this.state.languageRows[index].description,
                        necessary: this.state.languageRows[index].necessary,
                        point: this.state.languageRows[index].point


                    });
                }

            if (this.state.requestedDocuments)
                for (let index = 0; index < this.state.requestedDocumentsRows.length; index++) {
                    flagRequestedDocuments.push({
                        rfqRequestedDocumentCheck: this.state.requestedDocumentsRows[index].checked,
                        rfqRequestedDocument: this.state.requestedDocumentsRows[index].description,
                        necessary: this.state.requestedDocumentsRows[index].necessary,
                        point: this.state.requestedDocumentsRows[index].point


                    });
                }

            if (this.state.other)
                for (let index = 0; index < this.state.otherRows.length; index++) {
                    flagOthers.push({
                        rfqOtherCheck: this.state.otherRows[index].checked,
                        rfqOther: this.state.otherRows[index].description,
                        necessary: this.state.otherRows[index].necessary,
                        point: this.state.otherRows[index].point


                    });
                }


            let flagPITO = [];
            let flagDeliveryDate = "";
            if (this.state.selectedItem[0].deliveryDate !== undefined)
                flagDeliveryDate = this.state.selectedItem[0].deliveryDate;

            flagPITO.push({
                projectTakeOffListId: this.state.selectedItem[0].id,
                quantity: this.state.selectedItem[0].quantity,
                deliveryDate: flagDeliveryDate
            });


            let flagPITOSubs = [];
            let list = this.state.selectedItem;
            let flagList = this.state.selectedItem[0].projectTakeOffListSubs;
            let list2 = this.state.selectedRowsSub;
            let flagIndexes = [];

            for (let index = 0; index < flagList.length; index++) {
                for (let index2 = 0; index2 < list2.length; index2++) {
                    if (flagList[index].id === list2[index2].id)
                        if (!flagIndexes.includes(index))
                            flagIndexes.push(index);
                }
            }

            for (let index = 0; index < flagIndexes.length; index++) {
                let flagIndex = flagIndexes[index];
                let flagDeliveryDate = "";
                if (list[0].projectTakeOffListSubs[flagIndex].deliveryDate !== undefined)
                    flagDeliveryDate = list[0].projectTakeOffListSubs[flagIndex].deliveryDate;

                flagPITOSubs.push({
                    projectTakeOffListSubId: list[0].projectTakeOffListSubs[flagIndex].id,
                    quantity: list[0].projectTakeOffListSubs[flagIndex].quantity,
                    deliveryDate: flagDeliveryDate
                });
            }


            let flagVendors = [];
            for (let index = 0; index < this.state.selectedRowsVendor.length; index++) {
                flagVendors.push({
                    vendorLogId: this.state.selectedRowsVendor[index].id
                });
            }

            const newRFQ = {
                rfqNo: this.state.rfqNo,
                itemId: this.state.itemId,
                generalTermCheck: this.state.generalTerms,
                generalTermFiles: this.state.generalTermFiles,
                generalTerms: flagGeneralTerms,
                techRequirementCheck: this.state.techReqs,
                techRequirementFiles: this.state.techReqFiles,
                techRequirements: flagTechReqs,
                priceCheck: this.state.price,
                prices: flagPrices,
                deliveryPlaceNecessary: this.state.deliveryPlaceNecessary,
                quoteValidityNecessary: this.state.quoteValidityNecessary,
                deliveryPlacePoint: this.state.deliveryPlacePoint,
                quoteValidityPoint: this.state.quoteValidityPoint,  
                paymentTermCheck: this.state.payment,
                paymentTerms: flagPayments,
                deliveryPlaceCheck: this.state.deliveryPlace,
                deliveryPlace: this.state.deliveryPlaceText,
                deliveryPlaceLat: this.state.markerPosition.lat,
                deliveryPlaceLng: this.state.markerPosition.lng,
                quoteValidityCheck: this.state.validityQuotation,
                quoteValidityValue: this.state.validityQuotationValue,
                quoteValidityDateType: this.state.validityQuotationType,
                evaCriteriaCheck: this.state.evaluationCriterias,
                evaCriteriaDeliveryDateCheck: this.state.evaDeliveryDate,
                evaCriteriaPriceCheck: this.state.evaPrice,
                evaCriterias: flagEvaCriterias,
                receptionForQuotationCheck: this.state.receptionOfQuotation,
                receptionForQuotationOptions: this.state.selectedReception.join(),
                receptionForQuotationOtherCheck: this.state.receptionOtherCheck,
                receptionForQuotationOtherOption: this.state.receptionOther,
                modeOfTransportCheck: this.state.modeOfTransport,
                modeOfTranportOptions: this.state.selectedTransport.join(),
                modeOfTranportOtherCheck: this.state.transportOtherCheck,
                modeOfTranportOtherOption: this.state.transportOther,
                warrantyCheck: this.state.warranty,
                warranties: flagWarranties,
                languageCheck: this.state.language,
                languages: flagLanguages,
                requestedDocumentCheck: this.state.requestedDocuments,
                requestedDocuments: flagRequestedDocuments,
                otherCheck: this.state.other,
                others: flagOthers,
                projectTakeOffLists: flagPITO,
                projectTakeOffListSubs: flagPITOSubs,
                vendorLogs: flagVendors,
                evaluationDate: this.state.vendorEvaluationDate
            }

            var response = await handleRequest("POST", "/RequestForQuotation/add", newRFQ);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    // var selectedItem = this.state.items.find(p => p.id === this.state.itemId);
                    // const updatedProjectTakeOffList = {
                    //     id: selectedItem.id,
                    //     type: selectedItem.type,
                    //     groupId: selectedItem.groupId,
                    //     disciplineId: selectedItem.disciplineId,
                    //     description: selectedItem.description,
                    //     code: selectedItem.code,
                    //     location: selectedItem.location,
                    //     quantity: selectedItem.quantity,
                    //     unitId: selectedItem.unitId,
                    //     clientCode: selectedItem.clientCode,
                    //     drawingPath: selectedItem.drawingPath,
                    //     spec: "Done",
                    //     rfq: "Done",
                    //     projectTakeOffListSubs: selectedItem.projectTakeOffListSubs
                    // };
                    // console.log(updatedProjectTakeOffList);
                    // let response2 = await handleRequest("POST", "/ProjectTakeOffList/update", updatedProjectTakeOffList);
                    this.getItems();
                    this.setState({
                        vendorModal: false
                    });
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess("All data has been saved successfully.");
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalEdit = () => {
        this.resetInputs();
        if (!this.state.modalEdit) {
            this.getItems();
        };

        this.setState({
            modalEdit: !this.state.modalEdit,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeItem = (e) => {
        this.setState({
            itemId: e
        });
    }

    showOrHideModalDeliveryDate = () => {
        this.setState({
            deliveryDateModal: !this.state.deliveryDateModal,
            vendorModal: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalVendor = () => {
        this.setState({
            deliveryDateModal: false,
            vendorModal: !this.state.vendorModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showDeliveryDateModal = () => {
        this.setState({
            deliveryDateModal: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalFix = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            fixModal: !this.state.fixModal,
            deliveryDateModal: today,
            quantityModal: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showModalFix = (row) => {
        if (!(row.quantity.toString().includes(","))) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            today = dd + '/' + mm + '/' + yyyy;

            this.setState({
                fixModal: true,
                selectedFixRow: row,
                deliveryDateModal: today,
                quantityModal: row.quantity
            });
        } else {
            let overvalue = false;
            let flagSplitRows = [];

            let flagQuantityTotal = 0;
            let flagQuantity = row.quantity.split(",");
            let flagDate = row.deliveryDate.split(",");

            for (let index = 0; index < flagQuantity.length; index++) {
                flagSplitRows.push(
                    {
                        splitIndex: index + 1,
                        quantity: flagQuantity[index],
                        date: flagDate[index]
                    }
                );
                flagQuantityTotal += Number(flagQuantity[index]);
            }

            if (flagQuantity < 0) {
                overvalue = true;
            } else {
                overvalue = false;
            }

            _splitRows = flagSplitRows;

            this.setState({
                selectedFixRow: row,
                splitQuantityModal: true,
                splitQuantityTotalModal: flagQuantityTotal,
                splitQuantityRemainingModal: 0,
                splitRows: flagSplitRows,
                overvalue: overvalue
            });
        }

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    fixItem = () => {
        if (this.state.selectedFixRow.projectTakeOffListSubs === undefined) {
            let list = this.state.selectedItem;
            let flagList = this.state.selectedItem[0].projectTakeOffListSubs;
            let flagIndex = 0;

            for (let index = 0; index < flagList.length; index++) {
                if (flagList[index].id === this.state.selectedFixRow.id)
                    flagIndex = index;
            }

            list[0].projectTakeOffListSubs[flagIndex].quantity = this.state.quantityModal;
            list[0].projectTakeOffListSubs[flagIndex].deliveryDate = this.state.deliveryDateModal;

            this.setState({
                selectedItem: list.slice()
            });
        } else {
            let flagSelectedItem = this.state.selectedFixRow;
            flagSelectedItem.quantity = this.state.quantityModal;
            flagSelectedItem.deliveryDate = this.state.deliveryDateModal;
            let flagArray = [];
            flagArray.push(flagSelectedItem);

            this.setState({
                selectedItem: flagArray.slice()
            });
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            fixModal: false,
            selectedFixRow: null,
            deliveryDateModal: today,
            quantityModal: ""
        });
    }

    applyToOthers = () => {
        if (this.state.selectedFixRow.projectTakeOffListSubs === undefined) {
            let list = this.state.selectedItem;
            let flagList = this.state.selectedItem[0].projectTakeOffListSubs;
            let list2 = this.state.selectedRowsSub;
            let flagIndexes = [];

            for (let index = 0; index < flagList.length; index++) {
                for (let index2 = 0; index2 < list2.length; index2++) {
                    if (flagList[index].id === list2[index2].id)
                        if (!flagIndexes.includes(index))
                            flagIndexes.push(index);
                }
            }

            for (let index = 0; index < flagIndexes.length; index++) {
                let flagIndex = flagIndexes[index];
                list[0].projectTakeOffListSubs[flagIndex].deliveryDate = this.state.deliveryDateModal;
            }

            this.setState({
                selectedItem: list.slice()
            });
        } else {
            let flagSelectedItem = this.state.selectedFixRow;
            flagSelectedItem.deliveryDate = this.state.deliveryDateModal;
            let flagArray = [];
            flagArray.push(flagSelectedItem);

            this.setState({
                selectedItem: flagArray.slice()
            });
        }
    }

    showOrHideModalSplitQuantity = () => {
        this.setState({
            fixModal: false,
            splitQuantityModal: !this.state.splitQuantityModal,
            splitQuantityTotalModal: "",
            splitQuantityRemainingModal: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showModalSplitQuantity = () => {
        _splitRows = [
            {
                splitIndex: 1,
                quantity: "",
                date: today
            }
        ];

        this.setState({
            splitQuantityModal: true,
            splitRows: _splitRows,
            overvalue: false,
            splitQuantityTotalModal: this.state.selectedFixRow.quantity,
            splitQuantityRemainingModal: this.state.selectedFixRow.quantity
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalMessage2 = () => {
        this.setState({
            modalMessage2: !this.state.modalMessage2,
            remainingValueOK: false,
            sameDateOK: false,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    fixItemAsk = () => {
        this.setState({
            remainingValueOK: true,
            sameDateOK: true
        },
            () => this.fixItem2()
        );
    }

    fixItemBefore = () => {
        let flagSameDates = false;
        let flagRemainingValue = false;

        let flagQuantity = this.state.splitQuantityTotalModal;
        let list = this.state.splitRows;

        for (let index = 0; index < list.length; index++) {
            if (list[index].quantity !== null && list[index].quantity !== "")
                flagQuantity -= list[index].quantity;
        }

        if (flagQuantity > 0) {
            flagRemainingValue = true;
        } else {
            flagRemainingValue = false;
        }

        let deliveryDates = [];

        for (let index = 0; index < list.length; index++) {
            if (list[index].date !== null && list[index].date !== "")
                deliveryDates.push(list[index].date);
        }

        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
        let duplicateDates = findDuplicates(deliveryDates);

        if (duplicateDates.length > 0) {
            flagSameDates = true;
        } else {
            flagSameDates = false;
        }

        this.setState({
            remainingValue: flagRemainingValue,
            sameDate: flagSameDates
        });

        if (flagSameDates || flagRemainingValue)
            this.showOrHideModalMessage2();
        else {
            this.setState({
                sameDateOK: true,
                remainingValueOK: true
            },
                () => this.fixItem2()
            );
        }
    }

    fixItem2 = () => {
        if (this.state.overvalue)
            this.showWarning2();
        else if (this.state.sameDateOK && this.state.remainingValueOK && (!this.state.overvalue)) {
            if (this.state.selectedFixRow.projectTakeOffListSubs === undefined) {
                let list = this.state.selectedItem;
                let flagList = this.state.selectedItem[0].projectTakeOffListSubs;
                let flagIndex = 0;

                for (let index = 0; index < flagList.length; index++) {
                    if (flagList[index].id === this.state.selectedFixRow.id)
                        flagIndex = index;
                }

                let splitQuantities = [];
                let splitDates = [];
                for (let index = 0; index < this.state.splitRows.length; index++) {
                    splitQuantities.push(this.state.splitRows[index].quantity);
                    splitDates.push(this.state.splitRows[index].date);
                }

                list[0].projectTakeOffListSubs[flagIndex].quantity = splitQuantities.join();
                list[0].projectTakeOffListSubs[flagIndex].deliveryDate = splitDates.join();

                this.setState({
                    selectedItem: list.slice()
                });
            } else {
                let flagSelectedItem = this.state.selectedFixRow;
                let splitQuantities = [];
                let splitDates = [];

                for (let index = 0; index < this.state.splitRows.length; index++) {
                    splitQuantities.push(this.state.splitRows[index].quantity);
                    splitDates.push(this.state.splitRows[index].date);
                }

                flagSelectedItem.quantity = splitQuantities.join();
                flagSelectedItem.deliveryDate = splitDates.join();
                let flagArray = [];
                flagArray.push(flagSelectedItem);

                this.setState({
                    selectedItem: flagArray.slice()
                });
            }

            this.setState({
                fixModal: false,
                splitQuantityModal: false,
                remainingValueOK: false,
                sameDateOK: false,
                modalMessage2: false
            });
        }
    }

    addNewSplit = () => {
        _splitRows.push(
            {
                splitIndex: _splitRows.length + 1,
                quantity: "",
                date: today
            }
        );

        this.setState({
            splitRows: _splitRows
        });
    }

    removeSplit = (index, e) => {
        if (_splitRows.length !== 1) {
            _splitRows.splice(index, 1);
        }

        this.setState({
            splitRows: _splitRows
        });
    }

    onChangeSplitDateDelivery = async (index, date, dateString) => {
        let list = this.state.splitRows;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            list[index].date = dateString;
        } else {
            list[index].date = today;
        }

        this.setState({
            splitRows: list.slice()
        });
    }

    handleChangeSplitQuantity = (index, value) => {
        let overvalue = false;
        let list = this.state.splitRows;
        list[index].quantity = value;

        let flagQuantity = this.state.splitQuantityTotalModal;

        for (let index = 0; index < list.length; index++) {
            if (list[index].quantity !== null && list[index].quantity !== "")
                flagQuantity -= list[index].quantity;
        }

        if (flagQuantity < 0) {
            overvalue = true;
        } else {
            overvalue = false;
        }

        this.setState({
            splitRows: list.slice(),
            splitQuantityRemainingModal: flagQuantity,
            overvalue: overvalue
        });
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;
        const { selectedRowKeysUp } = this.state;
        const { selectedRowKeysSub } = this.state;
        const { selectedRowKeysVendor } = this.state;

        const mapContainerStyle = {
            height: "300px",
            width: "100%"
        };

        const rowSelection = {
            selectedRowKeysUp,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowsUp: selectedRows,
                    selectedRowKeysUp: selectedRowKeys
                    //projectList: selectedRows[0].projects
                });
                //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },

            getCheckboxProps: record => ({
                disabled: (record.projectTakeOffListSubs !== undefined) && (record.projectTakeOffListSubs.length !== 0),
            }), // child'lar varsa en üstteki seçilemez olacak, child yoksa seçilebilir.
        };

        const rowSelectionSub = {
            selectedRowKeysSub,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowsSub: selectedRows,
                    selectedRowKeysSub: selectedRowKeys
                });
                //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
        };

        const rowSelectionVendor = {
            selectedRowKeysVendor,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowsVendor: selectedRows,
                    selectedRowKeysVendor: selectedRowKeys
                });
                //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
        };

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteRFQ}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalEdit}
                        onHide={this.showOrHideModalEdit}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Editing Selected Request for Quotation
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Item:
                                    </Form.Label>
                                    <Col sm="2">
                                        <Select name="Item"
                                            disabled
                                            style={{ width: '100%' }}
                                            value={this.state.itemId}
                                            onChange={this.handleChangeItem}>
                                            {this.state.items.map(i => (
                                                <Option key={i.id} value={i.id}>{i.code + "-" + i.description}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                        Request for Quotation No.:
                                    </Form.Label>
                                    <Col sm="2">
                                        <Input readOnly={true} value={this.state.rfqNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ rfqNo: e.target.value }) }} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ generalTerms: e.target.checked }) }} checked={this.state.generalTerms}>General Terms and Conditions</Checkbox>
                                    </Col>
                                    {(this.state.generalTerms) && (
                                        <Col sm="5">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeGeneralTermsChekbox(0, e)} checked={this.state.generalTermsRows[0].checked} />
                                                <Input value={this.state.generalTermsRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeGeneralTermsDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.generalTermsRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        generalTermsRows: list.slice()
                                                    });
                                                }} checked={this.state.generalTermsRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber min={0} value={this.state.generalTermsRows[0].point} onChange={(value) => {
                                                    let list = this.state.generalTermsRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        generalTermsRows: list.slice()
                                                    });
                                                }} />

                                            </Space>
                                        </Col>)}
                                    {(this.state.generalTerms) && (
                                        <Col sm="1">
                                            <Space size={40}>
                                                <AntButton type="primary" onClick={this.addNewGeneralTermLine} icon={<PlusOutlined />}></AntButton>
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/RequestForQuotation/upload`} beforeUpload={this.beforeUpload}
                                                    onChange={this.handleGeneralTermFileChange}>
                                                    <AntButton>
                                                        <UploadOutlined /> Upload File
                                            </AntButton>
                                                </Upload>
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.generalTermsRows.map((item, index) => (
                                    (index !== 0) && (this.state.generalTerms) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeGeneralTermsChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeGeneralTermsDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.generalTermsRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            generalTermsRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.generalTermsRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            generalTermsRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeGeneralTermLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ techReqs: e.target.checked }) }} checked={this.state.techReqs}>Technical Requirements</Checkbox>
                                    </Col>
                                    {(this.state.techReqs) && (<Col sm="4">
                                        <Space>
                                            <Checkbox onChange={(e) => this.handleChangeTechReqsChekbox(0, e)} checked={this.state.techReqsRows[0].checked} />
                                            <Input value={this.state.techReqsRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeTechReqsDescription(0, e)} />
                                            <Checkbox onChange={(e) => {
                                                let list = this.state.techReqsRows;
                                                list[0].necessary = e.target.checked;

                                                this.setState({
                                                    techReqsRows: list.slice()
                                                });
                                            }} checked={this.state.techReqsRows[0].necessary} > Necessary for quotation </Checkbox>
                                            <InputNumber placeholder="Point" min={0} value={this.state.techReqsRows[0].point} onChange={(value) => {
                                                let list = this.state.techReqsRows;
                                                list[0].point = value;

                                                this.setState({
                                                    techReqsRows: list.slice()
                                                });
                                            }} />
                                        </Space>
                                    </Col>
                                    )}
                                    {(this.state.techReqs) && (<Col sm="1">
                                        <Space size={40}>
                                            <AntButton type="primary" onClick={this.addNewTechReq} icon={<PlusOutlined />}></AntButton>
                                            <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/RequestForQuotation/upload`} beforeUpload={this.beforeUpload}
                                                onChange={this.handleTechReqFileChange}>
                                                <AntButton>
                                                    <UploadOutlined /> Upload File
                                        </AntButton>
                                            </Upload>
                                        </Space>
                                    </Col>
                                    )}
                                </Form.Group>

                                {this.state.techReqsRows.map((item, index) => (
                                    (index !== 0) && (this.state.techReqs) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeTechReqsChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeTechReqsDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.techReqsRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            techReqsRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.techReqsRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            techReqsRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeTechReqLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ price: e.target.checked }) }} checked={this.state.price}>Price</Checkbox>
                                    </Col>
                                    {(this.state.price) && (
                                        <Col sm="3">
                                            <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ yourOfferPrice: e.target.checked }) }} checked={this.state.yourOfferPrice}>Your offer for price</Checkbox>
                                        </Col>
                                    )}
                                    {(this.state.price) && (<Col sm="1">
                                        <AntButton type="primary" onClick={this.addNewPrice} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                    )}
                                </Form.Group>

                                {this.state.priceRows.map((item, index) => (
                                    (this.state.price) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangePriceChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangePriceDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.priceRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            priceRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.priceRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            priceRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removePriceLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ payment: e.target.checked }) }} checked={this.state.payment}>Payment Terms</Checkbox>
                                    </Col>
                                    {(this.state.payment) && (
                                        <Col sm="3">
                                            <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ yourOfferPayment: e.target.checked }) }} checked={this.state.yourOfferPayment}>Your terms for payment</Checkbox>
                                        </Col>
                                    )}
                                    {(this.state.payment) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewPayment} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.paymentRows.map((item, index) => (
                                    (this.state.payment) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="3">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangePaymentChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangePaymentDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.paymentRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            paymentRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.paymentRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            paymentRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removePaymentLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ deliveryPlace: e.target.checked }) }} checked={this.state.deliveryPlace}>Delivery Place</Checkbox>
                                    </Col>

                                    <Col sm="2">
                                        <Input value={this.state.deliveryPlaceText} style={{ width: "100%" }} onChange={(e) => { this.setState({ deliveryPlaceText: e.target.value }) }} />
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox onChange={(e) => {

                                            this.setState({
                                                deliveryPlaceNecessary: e.target.checked
                                            });
                                        }} checked={this.state.deliveryPlaceNecessary} > Necessary for quotation </Checkbox>

                                    </Col>
                                    <Col sm="2">
                                        <InputNumber placeholder="Point" min={0} value={this.state.deliveryPlacePoint} onChange={(value) => {
                                            this.setState({
                                                deliveryPlacePoint: value
                                            });
                                        }} />
                                    </Col>


                                </Form.Group>

                                {(this.state.deliveryPlace) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="5">
                                        <LoadScript
                                            id="script-loader"
                                            googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc"
                                        >
                                            <GoogleMap
                                                id="marker-example"
                                                mapContainerStyle={mapContainerStyle}
                                                zoom={8}
                                                center={this.state.markerPosition}
                                            >
                                                <Marker
                                                    position={this.state.markerPosition}
                                                    draggable={true}
                                                    onDragEnd={this.onMarkerPositionChanged}
                                                />
                                            </GoogleMap>
                                        </LoadScript>
                                    </Col>
                                </Form.Group>)}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ validityQuotation: e.target.checked }) }} checked={this.state.validityQuotation}>Validity of Quotation</Checkbox>
                                    </Col>
                                    {(this.state.validityQuotation) && (
                                        <Col sm="5">
                                            <Space>
                                                <InputNumber min={0} value={this.state.validityQuotationValue} onChange={(value) => { this.setState({ validityQuotationValue: value }) }} />
                                                <Select value={this.state.validityQuotationType} placeholder="Select material"
                                                    onChange={(value) => { this.setState({ validityQuotationType: value }) }} >
                                                    <Select.Option value="">Select</Select.Option>
                                                    <Select.Option value="day">Day</Select.Option>
                                                    <Select.Option value="week">Week</Select.Option>
                                                    <Select.Option value="month">Month</Select.Option>
                                                    <Select.Option value="year">Year</Select.Option>
                                                </Select>
                                                <Checkbox onChange={(e) => {

                                                    this.setState({
                                                        quoteValidityNecessary: e.target.checked
                                                    });
                                                }} checked={this.state.quoteValidityNecessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.deliveryPlacePoint} onChange={(value) => {
                                                    this.setState({
                                                        quoteValidityCheck: value
                                                    });
                                                }} />
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ evaluationCriterias: e.target.checked }) }} checked={this.state.evaluationCriterias}>Evaluation Criterias</Checkbox>
                                    </Col>
                                    {(this.state.evaluationCriterias) && (
                                        <Col sm="3">
                                            <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ evaDeliveryDate: e.target.checked }) }} checked={this.state.evaDeliveryDate}>Delivery Date</Checkbox>
                                        </Col>
                                    )}
                                    {(this.state.evaluationCriterias) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewEvaCriterias} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {(this.state.evaluationCriterias) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="3">
                                        <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ evaPrice: e.target.checked }) }} checked={this.state.evaPrice}>Price</Checkbox>
                                    </Col>
                                </Form.Group>)}

                                {this.state.evaCriteriasRows.map((item, index) => (
                                    (this.state.evaluationCriterias) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="3">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeEvaCriteriasChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeEvaCriteriasDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.evaCriteriasRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            evaCriteriasRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.evaCriteriasRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            evaCriteriasRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeEvaCriteriasLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black' }} checked={true}>Evaluation Template</Checkbox>
                                    </Col>
                                    <Col sm="3">
                                        <DatePicker disabled format="DD-MM-YYYY HH:mm:ss" value={moment(this.state.vendorEvaluationDate)} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ receptionOfQuotation: e.target.checked }) }} checked={this.state.receptionOfQuotation}>Reception of Quotation</Checkbox>
                                    </Col>
                                    {(this.state.receptionOfQuotation) && (
                                        <Col sm="6">
                                            <Space>
                                                <Checkbox.Group value={this.state.selectedReception} options={this.state.receptionOfQuotationOptions} onChange={this.onChangeReceptionOptions} />
                                                <Checkbox onChange={(e) => { this.setState({ receptionOtherCheck: e.target.checked }) }} checked={this.state.receptionOtherCheck}></Checkbox>
                                                <Input placeholder="Other" style={{ width: 200 }} value={this.state.receptionOther} onChange={(e) => { this.setState({ receptionOther: e.target.value }) }} />
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ modeOfTransport: e.target.checked }) }} checked={this.state.modeOfTransport}>Mode of Transport</Checkbox>
                                    </Col>
                                    {(this.state.modeOfTransport) && (
                                        <Col sm="6">
                                            <Space>
                                                <Checkbox.Group value={this.state.selectedTransport} options={this.state.modeOfTransportOptions} onChange={this.onChangeModeOfTransportOptions} />
                                                <Checkbox onChange={(e) => { this.setState({ transportOtherCheck: e.target.checked }) }} checked={this.state.transportOtherCheck}></Checkbox>
                                                <Input placeholder="Other" style={{ width: 200 }} value={this.state.transportOther} onChange={(e) => { this.setState({ transportOther: e.target.value }) }} />
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ warranty: e.target.checked }) }} checked={this.state.warranty}>Warranty</Checkbox>
                                    </Col>
                                    {(this.state.warranty) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeWarrantyChekbox(0, e)} checked={this.state.warrantyRows[0].checked} />
                                                <Input value={this.state.warrantyRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeWarrantyDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.warrantyRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        warrantyRows: list.slice()
                                                    });
                                                }} checked={this.state.warrantyRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.warrantyRows[0].point} onChange={(value) => {
                                                    let list = this.state.warrantyRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        warrantyRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.warranty) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewWarranty} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.warrantyRows.map((item, index) => (
                                    (index !== 0) && (this.state.warranty) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeWarrantyChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeWarrantyDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.warrantyRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            warrantyRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.warrantyRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            warrantyRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeWarrantyLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ language: e.target.checked }) }} checked={this.state.language}>Language</Checkbox>
                                    </Col>
                                    {(this.state.language) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeLanguageChekbox(0, e)} checked={this.state.languageRows[0].checked} />
                                                <Input value={this.state.languageRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeLanguageDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.languageRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        languageRows: list.slice()
                                                    });
                                                }} checked={this.state.languageRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.languageRows[0].point} onChange={(value) => {
                                                    let list = this.state.languageRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        languageRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.language) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewLanguage} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.languageRows.map((item, index) => (
                                    (index !== 0) && (this.state.language) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeLanguageChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeLanguageDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.languageRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            languageRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.languageRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            languageRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeLanguageLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ requestedDocuments: e.target.checked }) }} checked={this.state.requestedDocuments}>Requested Documents</Checkbox>
                                    </Col>
                                    {(this.state.requestedDocuments) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeRequestedDocumentChekbox(0, e)} checked={this.state.requestedDocumentsRows[0].checked} />
                                                <Input value={this.state.requestedDocumentsRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeRequestedDocumentDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.requestedDocumentsRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        requestedDocumentsRows: list.slice()
                                                    });
                                                }} checked={this.state.requestedDocumentsRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.requestedDocumentsRows[0].point} onChange={(value) => {
                                                    let list = this.state.requestedDocumentsRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        requestedDocumentsRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.requestedDocuments) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewRequestedDocument} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.requestedDocumentsRows.map((item, index) => (
                                    (index !== 0) && (this.state.requestedDocuments) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeRequestedDocumentChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeRequestedDocumentDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.requestedDocumentsRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            requestedDocumentsRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.requestedDocumentsRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            requestedDocumentsRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeRequestedDocumentLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ other: e.target.checked }) }} checked={this.state.other}>Other</Checkbox>
                                    </Col>
                                    {(this.state.other) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeOtherChekbox(0, e)} checked={this.state.otherRows[0].checked} />
                                                <Input value={this.state.otherRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeOtherDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.otherRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        otherRows: list.slice()
                                                    });
                                                }} checked={this.state.otherRows[0].necessary} > Necessary for quotation </Checkbox>
                                                   <InputNumber placeholder="Point" min={0} value={this.state.otherRows[0].point} onChange={(value) => {
                                                    let list = this.state.otherRows;
                                                    list[0].point = value; 

                                                    this.setState({
                                                        otherRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.other) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewOther} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.otherRows.map((item, index) => (
                                    (index !== 0) && (this.state.other) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeOtherChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeOtherDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.otherRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            otherRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                       <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                    let list = this.state.otherRows;
                                                    list[index].point = value;

                                                    this.setState({
                                                        otherRows: list.slice()
                                                    });
                                                }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeOtherLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalEdit}>Close</Button>
                            <Button variant="warning" onClick={this.updateRFQ}>Update</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deliveryDateModal}
                        onHide={this.showOrHideModalDeliveryDate}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="RequestForQuotationDeliveryDateModal" defaultMessage="Please fix your quantity and delivery time" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Table
                                style={{ padding: "4px 1rem 4px 4px" }}
                                expandable={{
                                    expandedRowRender: record =>
                                        <Table
                                            rowSelection={{
                                                type: "checkbox",
                                                ...rowSelectionSub,
                                            }}
                                            dataSource={record.projectTakeOffListSubs} pagination={false}>
                                            <Column title={<FormattedMessage id="PrjTakeOffCode" defaultMessage="Code" />} key="code" dataIndex="code" />
                                            <Column title={<FormattedMessage id="PrjTakeOffDescription" defaultMessage="Description" />} key="description" dataIndex="description" />
                                            <Column title={<FormattedMessage id="PrjTakeOffDimension" defaultMessage="Dimension" />} key="dimension" dataIndex="dimension" />
                                            <Column title={<FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity" />} key="quantity" dataIndex="quantity" />
                                            <Column title={<FormattedMessage id="PrjTakeOffDeliveryDate" defaultMessage="Delivery Date" />} key="deliveryDate" dataIndex="deliveryDate" />
                                            <Column title="" width='100px' key="set" render={(value, row, index) => {
                                                return <AntButton type="primary" onClick={() => this.showModalFix(row)}>Fix Quantity and D.Date</AntButton>
                                            }} />
                                        </Table>,
                                    rowExpandable: record => record.projectTakeOffListSubs.length !== 0,
                                }}
                                rowSelection={{
                                    type: "checkbox",
                                    ...rowSelection,
                                }}
                                dataSource={this.state.selectedItem} bordered>
                                <Column title={<FormattedMessage id="PrjTakeOffCode" defaultMessage="Code" />} key="code" dataIndex="code" />
                                <Column title={<FormattedMessage id="PrjTakeOffDescription" defaultMessage="Description" />} key="description" dataIndex="description" />
                                <Column title={<FormattedMessage id="PrjTakeOffDimension" defaultMessage="Dimension" />} key="dimension" dataIndex="dimension" />
                                <Column title={<FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity" />} key="quantity" dataIndex="quantity" />
                                <Column title={<FormattedMessage id="PrjTakeOffDeliveryDate" defaultMessage="Delivery Date" />} key="deliveryDate" dataIndex="deliveryDate" />
                                <Column title="" width='100px' key="set" render={(value, row, index) => {
                                    if (row.projectTakeOffListSubs !== undefined)
                                        if (row.projectTakeOffListSubs.length === 0)
                                            return <AntButton type="primary" onClick={() => this.showModalFix(row)}>Fix Quantity and D.Date</AntButton>
                                        else
                                            return <AntButton disabled type="primary" onClick={() => this.showModalFix(row)}>Fix Quantity and D.Date</AntButton>
                                    else
                                        return <AntButton type="primary" onClick={() => this.showModalFix(row)}>Fix Quantity and D.Date</AntButton>
                                }} />
                            </Table>

                        </Modal.Body>
                        <Modal.Footer style={{ padding: "0px", marginTop: "1rem", marginBottom: "1rem" }}>
                            <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.showOrHideModalVendor} icon={<RightCircleOutlined style={{ fontSize: '32px' }} />} />

                            {/* <img onClick={this.showOrHideModalVendor} src="/media/next1.png" alt="" height="35px"></img> */}
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.vendorModal}
                        onHide={this.showOrHideModalVendor}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="RequestForQuotationVendorModal" defaultMessage="Please select Vendor to send your Material Requisition" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Table
                                style={{ padding: "4px 1rem 4px 4px" }}
                                rowSelection={{
                                    type: "checkbox",
                                    ...rowSelectionVendor,
                                }}
                                dataSource={this.state.vendorLogs} bordered>
                                <Column title={<FormattedMessage id="RFQVendorCode" defaultMessage="Code" />} key="companyId" dataIndex="id" />
                                <Column title={<FormattedMessage id="RFQVendorVendor" defaultMessage="Vendor" />} key="fullName" dataIndex="fullName" />
                                <Column title={<FormattedMessage id="RFQVendorSupplierNo" defaultMessage="Supplier No" />} key="dimension" dataIndex="dimension" />
                                <Column title={<FormattedMessage id="RFQVendorManufacturerNo" defaultMessage="Manufacturer No" />} key="quantity" dataIndex="quantity" />
                                <Column title={<FormattedMessage id="RFQVendorAddress" defaultMessage="Address" />} key="deliveryDate" render={(value, row, index) => {
                                    if (row.contact !== undefined)
                                        if (row.contact != null)
                                            return row.contact.country;
                                        else
                                            return '';
                                    return '';
                                }} />
                            </Table>

                        </Modal.Body>
                        <Modal.Footer style={{ padding: "0px", marginBottom: "1rem" }}>
                            <Space>
                                <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.showOrHideModalDeliveryDate} icon={<LeftCircleOutlined style={{ fontSize: '32px' }} />} />
                                <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.saveRFQ} icon={<RightCircleOutlined style={{ fontSize: '32px' }} />} />

                                {/* <img onClick={this.showOrHideModalDeliveryDate} src="/media/back1.png" alt="" height="35px"></img>
                                <img onClick={this.saveRFQ} src="/media/next1.png" alt="" height="35px"></img> */}
                            </Space>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.fixModal}
                        onHide={this.showOrHideModalFix}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        size="lg"
                        centered >
                        <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="RFQFixItem" defaultMessage="Fix Quantity and Delivery Date" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Quantity:
                                    </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ quantityModal: e })}
                                        value={this.state.quantityModal} />
                                </Col>
                                <Col xs={4}>
                                    <AntButton type="primary" onClick={this.showModalSplitQuantity}>Split Quantity</AntButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Delivery Date:
                                    </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <DatePicker disabledDate={this.disabledDate} value={moment(this.state.deliveryDateModal, "DD/MM/YYYY")}
                                        format="DD/MM/YYYY" onChange={this.onChangeDateDelivery} />
                                </Col>
                                <Col xs={4}>
                                    {/*<AntButton type="primary" onClick={this.applyToOthers}>Apply to Others</AntButton>*/}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                            <ReactButton variant="light" onClick={this.showOrHideModalFix}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="primary" onClick={this.fixItem}>
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.splitQuantityModal}
                        onHide={this.showOrHideModalSplitQuantity}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        size="lg"
                        centered >
                        <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="RFQFixItem" defaultMessage="Fix Splitted Quantities and Delivery Dates" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Total Quantity:
                                    </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ splitQuantityTotalModal: e })}
                                        readOnly value={this.state.splitQuantityTotalModal} />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={4} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Remaining:
                                    </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ splitQuantityRemainingModal: e })}
                                        readOnly value={this.state.splitQuantityRemainingModal} />
                                </Col>
                                <Col xs={4}>
                                    {(this.state.overvalue) && (<Form.Label style={{ color: "red", marginTop: "0.5rem", fontWeight: "bold" }}>Overvalue</Form.Label>)}
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={2} style={{ textAlign: "right" }}>
                                </Col>
                                <Col xs={4} style={{ textAlign: "center" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Splitted Quantity
                                    </Form.Label>
                                </Col>
                                <Col xs={4} style={{ textAlign: "center" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        Date
                                    </Form.Label>
                                </Col>
                            </Row>

                            {this.state.splitRows.map((item, index) => (
                                <Row style={{ marginBottom: '1rem' }}>
                                    <Col xs={2} style={{ textAlign: "right" }}>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "center" }}>
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={(value) => this.handleChangeSplitQuantity(index, value)} value={item.quantity} />
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "center" }}>
                                        <DatePicker disabledDate={this.disabledDate} value={moment(item.date, "DD/MM/YYYY")}
                                            format="DD/MM/YYYY" onChange={(date, dateString) => { this.onChangeSplitDateDelivery(index, date, dateString) }} />
                                    </Col>
                                    <Col xs={2}>
                                        {(index === 0) && (<AntButton type="primary" onClick={this.addNewSplit} icon={<PlusOutlined />}></AntButton>)}
                                        {(index !== 0) && (<AntButton type="primary" danger onClick={(e) => this.removeSplit(index, e)} icon={<CloseOutlined />}></AntButton>)}
                                    </Col>
                                </Row>))}

                        </Modal.Body>
                        <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                            <ReactButton variant="light" onClick={this.showOrHideModalSplitQuantity}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="primary" onClick={this.fixItemBefore}>
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage2}
                        onHide={this.showOrHideModalMessage2}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                {this.state.remainingValue && <Col sm="12">
                                    There are remaining quantities. Do you confirm?
                                </Col>}

                                {this.state.sameDate && <Col sm="12">
                                    Are you sure you want to use the same delivery dates? Do you confirm?
                                </Col>}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage2}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            <ReactButton variant="primary" onClick={this.fixItemAsk}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="RequestForQuotationCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="RequestForQuotationStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                            <Form>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Item:
                                    </Form.Label>
                                    <Col sm="2">
                                        <Select name="Item"
                                            style={{ width: '100%' }}
                                            value={this.state.itemId}
                                            onChange={this.handleChangeItem}>
                                            {this.state.items.map(i => (
                                                <Option key={i.id} value={i.id}>{i.code + "-" + i.description}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                        Request for Quotation No.:
                                    </Form.Label>
                                    <Col sm="2">
                                        <Input readOnly={true} value={this.state.rfqNo} style={{ width: "100%" }} onChange={(e) => { this.setState({ rfqNo: e.target.value }) }} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ generalTerms: e.target.checked }) }} checked={this.state.generalTerms}>General Terms and Conditions</Checkbox>
                                    </Col>
                                    {(this.state.generalTerms) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeGeneralTermsChekbox(0, e)} checked={this.state.generalTermsRows[0].checked} />
                                                <Input value={this.state.generalTermsRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeGeneralTermsDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.generalTermsRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        generalTermsRows: list.slice()
                                                    });
                                                }} checked={this.state.generalTermsRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.generalTermsRows[0].point} onChange={(value) => {
                                                    let list = this.state.generalTermsRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        generalTermsRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.generalTerms) && (
                                        <Col sm="1">
                                            <Space size={40}>
                                                <AntButton type="primary" onClick={this.addNewGeneralTermLine} icon={<PlusOutlined />}></AntButton>
                                                <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/RequestForQuotation/upload`} beforeUpload={this.beforeUpload}
                                                    onChange={this.handleGeneralTermFileChange}>
                                                    <AntButton>
                                                        <UploadOutlined /> Upload File
                                            </AntButton>
                                                </Upload>
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.generalTermsRows.map((item, index) => (
                                    (index !== 0) && (this.state.generalTerms) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeGeneralTermsChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeGeneralTermsDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.generalTermsRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            generalTermsRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.generalTermsRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            generalTermsRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeGeneralTermLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ techReqs: e.target.checked }) }} checked={this.state.techReqs}>Technical Requirements</Checkbox>
                                    </Col>
                                    {(this.state.techReqs) && (<Col sm="4">
                                        <Space>
                                            <Checkbox onChange={(e) => this.handleChangeTechReqsChekbox(0, e)} checked={this.state.techReqsRows[0].checked} />
                                            <Input value={this.state.techReqsRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeTechReqsDescription(0, e)} />
                                            <Checkbox onChange={(e) => {
                                                let list = this.state.techReqsRows;
                                                list[0].necessary = e.target.checked;

                                                this.setState({
                                                    techReqsRows: list.slice()
                                                });
                                            }} checked={this.state.techReqsRows[0].necessary} > Necessary for quotation </Checkbox>
                                            <InputNumber placeholder="Point" min={0} value={this.state.techReqsRows[0].point} onChange={(value) => {
                                                let list = this.state.techReqsRows;
                                                list[0].point = value;

                                                this.setState({
                                                    techReqsRows: list.slice()
                                                });
                                            }} />
                                        </Space>
                                    </Col>
                                    )}
                                    {(this.state.techReqs) && (<Col sm="1">
                                        <Space size={40}>
                                            <AntButton type="primary" onClick={this.addNewTechReq} icon={<PlusOutlined />}></AntButton>
                                            <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/RequestForQuotation/upload`} beforeUpload={this.beforeUpload}
                                                onChange={this.handleTechReqFileChange}>
                                                <AntButton>
                                                    <UploadOutlined /> Upload File
                                        </AntButton>
                                            </Upload>
                                        </Space>
                                    </Col>
                                    )}
                                </Form.Group>

                                {this.state.techReqsRows.map((item, index) => (
                                    (index !== 0) && (this.state.techReqs) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeTechReqsChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeTechReqsDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.techReqsRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            techReqsRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.techReqsRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            techReqsRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeTechReqLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ price: e.target.checked }) }} checked={this.state.price}>Price</Checkbox>
                                    </Col>
                                    {(this.state.price) && (
                                        <Col sm="3">
                                            <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ yourOfferPrice: e.target.checked }) }} checked={this.state.yourOfferPrice}>Your offer for price</Checkbox>
                                        </Col>
                                    )}
                                    {(this.state.price) && (<Col sm="1">
                                        <AntButton type="primary" onClick={this.addNewPrice} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                    )}
                                </Form.Group>

                                {this.state.priceRows.map((item, index) => (
                                    (this.state.price) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangePriceChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangePriceDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.priceRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            priceRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.priceRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            priceRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removePriceLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ payment: e.target.checked }) }} checked={this.state.payment}>Payment Terms</Checkbox>
                                    </Col>
                                    {(this.state.payment) && (
                                        <Col sm="3">
                                            <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ yourOfferPayment: e.target.checked }) }} checked={this.state.yourOfferPayment}>Your terms for payment</Checkbox>
                                        </Col>
                                    )}
                                    {(this.state.payment) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewPayment} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.paymentRows.map((item, index) => (
                                    (this.state.payment) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangePaymentChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangePaymentDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.paymentRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            paymentRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.paymentRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            paymentRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removePaymentLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ deliveryPlace: e.target.checked }) }} checked={this.state.deliveryPlace}>Delivery Place</Checkbox>
                                    </Col>

                                    <Col sm="2">
                                        <Input value={this.state.deliveryPlaceText} style={{ width: "100%" }} onChange={(e) => { this.setState({ deliveryPlaceText: e.target.value }) }} />
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox onChange={(e) => {

                                            this.setState({
                                                deliveryPlaceNecessary: e.target.checked
                                            });
                                        }} checked={this.state.deliveryPlaceNecessary} > Necessary for quotation </Checkbox>

                                    </Col>
                                    <Col sm="2">
                                        <InputNumber placeholder="Point" min={0} value={this.state.deliveryPlacePoint} onChange={(value) => {
                                            this.setState({
                                                deliveryPlacePoint: value
                                            });
                                        }} />

                                    </Col>

                                </Form.Group>

                                {(this.state.deliveryPlace) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="5">
                                        <LoadScript
                                            id="script-loader"
                                            googleMapsApiKey="AIzaSyBdXzd0sXo2ZjL-GaYs4RtKRUM10KCauIc"
                                        >
                                            <GoogleMap
                                                id="marker-example"
                                                mapContainerStyle={mapContainerStyle}
                                                zoom={8}
                                                center={this.state.markerPosition}
                                            >
                                                <Marker
                                                    position={this.state.markerPosition}
                                                    draggable={true}
                                                    onDragEnd={this.onMarkerPositionChanged}
                                                />
                                            </GoogleMap>
                                        </LoadScript>
                                    </Col>
                                </Form.Group>)}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ validityQuotation: e.target.checked }) }} checked={this.state.validityQuotation}>Validity of Quotation</Checkbox>
                                    </Col>
                                    {(this.state.validityQuotation) && (
                                        <Col sm="4">
                                            <Space>
                                                <InputNumber min={0} value={this.state.validityQuotationValue} onChange={(value) => { this.setState({ validityQuotationValue: value }) }} />
                                                <Select value={this.state.validityQuotationType} placeholder="Select material"
                                                    onChange={(value) => { this.setState({ validityQuotationType: value }) }} >
                                                    <Select.Option value="">Select</Select.Option>
                                                    <Select.Option value="day">Day</Select.Option>
                                                    <Select.Option value="week">Week</Select.Option>
                                                    <Select.Option value="month">Month</Select.Option>
                                                    <Select.Option value="year">Year</Select.Option>
                                                </Select>
                                                <Checkbox onChange={(e) => {

                                                    this.setState({
                                                        quoteValidityNecessary: e.target.checked
                                                    });
                                                }} checked={this.state.quoteValidityNecessary} > Necessary for quotation </Checkbox>

                                                <InputNumber placeholder="Point" min={0} value={this.state.deliveryPlacePoint} onChange={(value) => {
                                                    this.setState({
                                                        quoteValidityCheck: value
                                                    });
                                                }} />
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ evaluationCriterias: e.target.checked }) }} checked={this.state.evaluationCriterias}>Evaluation Criterias</Checkbox>
                                    </Col>
                                    {(this.state.evaluationCriterias) && (
                                        <Col sm="3">
                                            <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ evaDeliveryDate: e.target.checked }) }} checked={this.state.evaDeliveryDate}>Delivery Date</Checkbox>
                                        </Col>
                                    )}
                                    {(this.state.evaluationCriterias) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewEvaCriterias} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {(this.state.evaluationCriterias) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="3">
                                        <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ evaPrice: e.target.checked }) }} checked={this.state.evaPrice}>Price</Checkbox>
                                    </Col>
                                </Form.Group>)}

                                {this.state.evaCriteriasRows.map((item, index) => (
                                    (this.state.evaluationCriterias) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeEvaCriteriasChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeEvaCriteriasDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.evaCriteriasRows;
                                                        list[index].necessary = e.target.checked;
                                                        this.setState({
                                                            evaCriteriasRows: list.slice()
                                                        });

                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.evaCriteriasRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            evaCriteriasRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeEvaCriteriasLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black' }} checked={true}>Evaluation Template</Checkbox>
                                    </Col>
                                    <Col sm="3">
                                        <DatePicker disabled format="DD-MM-YYYY HH:mm:ss" value={moment(this.state.vendorEvaluationDate)} />
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ receptionOfQuotation: e.target.checked }) }} checked={this.state.receptionOfQuotation}>Reception of Quotation</Checkbox>
                                    </Col>
                                    {(this.state.receptionOfQuotation) && (
                                        <Col sm="6">
                                            <Space>
                                                <Checkbox.Group value={this.state.selectedReception} options={this.state.receptionOfQuotationOptions} onChange={this.onChangeReceptionOptions} />
                                                <Checkbox onChange={(e) => { this.setState({ receptionOtherCheck: e.target.checked }) }} checked={this.state.receptionOtherCheck}></Checkbox>
                                                <Input placeholder="Other" style={{ width: 200 }} value={this.state.receptionOther} onChange={(e) => { this.setState({ receptionOther: e.target.value }) }} />
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ modeOfTransport: e.target.checked }) }} checked={this.state.modeOfTransport}>Mode of Transport</Checkbox>
                                    </Col>
                                    {(this.state.modeOfTransport) && (
                                        <Col sm="6">
                                            <Space>
                                                <Checkbox.Group value={this.state.selectedTransport} options={this.state.modeOfTransportOptions} onChange={this.onChangeModeOfTransportOptions} />
                                                <Checkbox onChange={(e) => { this.setState({ transportOtherCheck: e.target.checked }) }} checked={this.state.transportOtherCheck}></Checkbox>
                                                <Input placeholder="Other" style={{ width: 200 }} value={this.state.transportOther} onChange={(e) => { this.setState({ transportOther: e.target.value }) }} />
                                            </Space>
                                        </Col>
                                    )}
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ warranty: e.target.checked }) }} checked={this.state.warranty}>Warranty</Checkbox>
                                    </Col>
                                    {(this.state.warranty) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeWarrantyChekbox(0, e)} checked={this.state.warrantyRows[0].checked} />
                                                <Input value={this.state.warrantyRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeWarrantyDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.warrantyRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        warrantyRows: list.slice()
                                                    });
                                                }} checked={this.state.warrantyRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.warrantyRows[0].point} onChange={(value) => {
                                                    let list = this.state.warrantyRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        warrantyRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.warranty) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewWarranty} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.warrantyRows.map((item, index) => (
                                    (index !== 0) && (this.state.warranty) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeWarrantyChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeWarrantyDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.warrantyRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            warrantyRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.warrantyRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            warrantyRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeWarrantyLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ language: e.target.checked }) }} checked={this.state.language}>Language</Checkbox>
                                    </Col>
                                    {(this.state.language) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeLanguageChekbox(0, e)} checked={this.state.languageRows[0].checked} />
                                                <Input value={this.state.languageRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeLanguageDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.languageRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        languageRows: list.slice()
                                                    });
                                                }} checked={this.state.languageRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.languageRows[0].point} onChange={(value) => {
                                                    let list = this.state.languageRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        languageRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.language) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewLanguage} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.languageRows.map((item, index) => (
                                    (index !== 0) && (this.state.language) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeLanguageChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeLanguageDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.languageRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            languageRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.languageRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            languageRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeLanguageLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ requestedDocuments: e.target.checked }) }} checked={this.state.requestedDocuments}>Requested Documents</Checkbox>
                                    </Col>
                                    {(this.state.requestedDocuments) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeRequestedDocumentChekbox(0, e)} checked={this.state.requestedDocumentsRows[0].checked} />
                                                <Input value={this.state.requestedDocumentsRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeRequestedDocumentDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.requestedDocumentsRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        requestedDocumentsRows: list.slice()
                                                    });
                                                }} checked={this.state.requestedDocumentsRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.requestedDocumentsRows[0].point} onChange={(value) => {
                                                    let list = this.state.requestedDocumentsRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        requestedDocumentsRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.requestedDocuments) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewRequestedDocument} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.requestedDocumentsRows.map((item, index) => (
                                    (index !== 0) && (this.state.requestedDocuments) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeRequestedDocumentChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeRequestedDocumentDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.requestedDocumentsRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            requestedDocumentsRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                    <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                        let list = this.state.requestedDocumentsRows;
                                                        list[index].point = value;

                                                        this.setState({
                                                            requestedDocumentsRows: list.slice()
                                                        });
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeRequestedDocumentLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="2">
                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ other: e.target.checked }) }} checked={this.state.other}>Other</Checkbox>
                                    </Col>
                                    {(this.state.other) && (
                                        <Col sm="4">
                                            <Space>
                                                <Checkbox onChange={(e) => this.handleChangeOtherChekbox(0, e)} checked={this.state.otherRows[0].checked} />
                                                <Input value={this.state.otherRows[0].description} style={{ width: "100%" }} onChange={(e) => this.handleChangeOtherDescription(0, e)} />
                                                <Checkbox onChange={(e) => {
                                                    let list = this.state.otherRows;
                                                    list[0].necessary = e.target.checked;

                                                    this.setState({
                                                        otherRows: list.slice()
                                                    });
                                                }} checked={this.state.otherRows[0].necessary} > Necessary for quotation </Checkbox>
                                                <InputNumber placeholder="Point" min={0} value={this.state.otherRows[0].point} onChange={(value) => {
                                                    let list = this.state.otherRows;
                                                    list[0].point = value;

                                                    this.setState({
                                                        otherRows: list.slice()
                                                    });
                                                }} />
                                            </Space>
                                        </Col>)}
                                    {(this.state.other) && (
                                        <Col sm="1">
                                            <AntButton type="primary" onClick={this.addNewOther} icon={<PlusOutlined />}></AntButton>
                                        </Col>
                                    )}
                                </Form.Group>

                                {this.state.otherRows.map((item, index) => (
                                    (index !== 0) && (this.state.other) && (
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <Space>
                                                    <Checkbox onChange={(e) => this.handleChangeOtherChekbox(index, e)} checked={item.checked} />
                                                    <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeOtherDescription(index, e)} />
                                                    <Checkbox onChange={(e) => {
                                                        let list = this.state.otherRows;
                                                        list[index].necessary = e.target.checked;

                                                        this.setState({
                                                            otherRows: list.slice()
                                                        });
                                                    }} checked={item.necessary} > Necessary for quotation </Checkbox>
                                                      <InputNumber placeholder="Point" min={0} value={item.point} onChange={(value) => {
                                                    let list = this.state.otherRows;
                                                    list[index].point = value;

                                                    this.setState({
                                                        otherRows: list.slice()
                                                    });
                                                }} />
                                                </Space>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeOtherLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>
                                    )
                                ))}

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="4">
                                                <ReactButton id="RFQSaveButton"
                                                    style={{ width: '100%' }} onClick={this.saveRFQButton} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>
                            </Form>
                        </div>

                        <Table style={{ padding: "4px 1rem 0px 4px" }} dataSource={this.state.rfqList} bordered>
                            <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                            <Column title="Request for Quotation No" key="RFQNo" dataIndex="rfqNo" />
                            <Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => {
                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                        if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                            (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                            return (<div style={{ textAlign: "center" }}>
                                                <React.Fragment key={record.id} >
                                                    <CustomMaterialMenuDeleteEdit row={record}
                                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
                                                </React.Fragment>
                                            </div>);
                                        }
                                        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuEdit row={record}
                                                    onEditRow={this.changeButtonUpdate.bind(this)} />
                                            </React.Fragment>);
                                        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuDelete row={record}
                                                    onDeleteRow={this.deleteModal.bind(this)} />
                                            </React.Fragment>);
                                        }
                                    }
                                    else {
                                        return (<div style={{ textAlign: "center" }}>
                                        </div>);
                                    }
                                }}
                            />
                        </Table>

                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RequestForQuotation)
