
import axios from "axios";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import store from '../../../redux/store';

export const API_BASE = "https://dynamia.mndsoftware.com";
//export const API_BASE = "https://dynamia.mndsoftware.com"

export const TEMPLATE_Dynamia_99_USERID=2; 

export async function handleRequest(httpMethod, URL, data) {

    var flagConfig;
    const state = store.getState();
    const user = state.auth.user; 
    const authToken = state.auth.authToken;
    if(!Boolean(user)){
        flagConfig = { headers: { 'accessToken': "", 'userId': "" } };
    } else {
        flagConfig = { headers: { 'accessToken': authToken, 'userId': user.id } }; 
    }

    var response; //Authorization e�er false geliyorsa y�nlendirme yap�lacak.
    const flagURL = API_BASE + URL;
    try {
        switch (httpMethod) {
            case "GET":
                response = await axios.get(`${flagURL}`, flagConfig);
                break;
            case "POST":
                response = await axios.post(`${flagURL}`, data, flagConfig);
                break;
            case "PUT":
                response = await axios.put(`${flagURL}`, data, flagConfig);
                break;
            case "DELETE":        
                response = await axios.delete(`${flagURL}`, flagConfig);
                break; 
            case "GETBLOB":
                response = await axios.post(`${flagURL}`,data, flagConfig);
                break;
            default:
                break;
        }

        // if(Boolean(response) && Boolean(response.data) && Boolean(response.data.httpStatus) && response.data.httpStatus===401)
        //  return <Redirect to="/auth/login" /> 

        return response;
    } catch (error) {      

        console.log(error);
       
        if (error===undefined) {
            return {data:[]}; 
        }
      
        if(!error.status) {            
             error.data = [] ;             
        } 
        
        error.type = "ERROR"; 

        return error ;   
        //ornek error objesi :  { data: "This record added before! Please change your selections.", status: 406, statusText: "", headers: {�}, config: {�}, request: XMLHttpRequest }
    }
}

