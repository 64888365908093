/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Button } from "react-bootstrap";
import { Button as AntdButton, Avatar } from 'antd';
import { Modal, Col, Row, Table } from "react-bootstrap";
import { Card, Accordion } from "react-bootstrap";
import { handleRequest } from "../../redux/config/env"; 
import { DownloadOutlined } from '@ant-design/icons';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 

export class MaterialLogSub extends Component {
  constructor() {
    super();
    this.state = {
      deleteId: 0,
      deleteFormName: "",
      editId: 0,
      testModal: false,
      customerId: 0,
      hidden: true,
      expanded: [],
      brand: "",
      model: "",
      serialNo: "",
      chasisNo: "",
      departments: [],
      departmentId: null,
      disciplines: [],
      disciplineId: null,
      materialLogId: 0,
      MaterialClients: [],
      Manufacturings: [],
      MaterialPackings:[],
      MaterialHandlings:[],
      MaterialTransportations:[],
      MaterialMaintenances:[],

      specifications: [],
      demo: [],
      MaterialDimensions:[],
      MaterialWarrantys:[],
      MaterialClassifications:[],
    };

    this.renderDownloadFile=this.renderDownloadFile.bind(this);  
  }

  componentDidMount = async () => {
    this.getMaterialClassifications();
    this.getMaterialDimensions(); 
    this.getClientsMaterials();
    this.getManufacturings(); 
    this.getPackings();
    this.getHandlings();
    this.getTransportations();
    this.getMaintenances();
    this.getMaterialWarrantys(); 

  };

  getMaterialClassifications = async () => {
    console.log("Sub");
    console.log(this.props);
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      id: materialId
    }   
    const response = await handleRequest("POST", "/MaterialIdentification/getMaterialIdentificationResult", newObj); 

    if (response.data.length != 0) {
      console.log("Classification");
      console.log(response.data);
      this.setState({ 
        MaterialClassifications: response.data,
       });
    } else { 
      this.setState({ 
        MaterialClassifications: [],
     });
    }
  }

  getMaterialDimensions = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialDimension/getMaterialDimensionResult", newObj); 

    if (response.data.length != 0) {
      this.setState({ 
        MaterialDimensions: response.data,
       });
    } else { 
      this.setState({ 
        MaterialDimensions: [],
     });
    }
  }

  getClientsMaterials = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialClient/getMaterialClientResult", newObj); 

    if (response.data.length != 0) {
      this.setState({ 
        MaterialClients: response.data,
       });
    } else { 
      this.setState({ 
        MaterialClients: [],
     });
    }
  };

  getManufacturings = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialManufacturing/getMaterialManufacturingResult", newObj); 
    if (response.data.length != 0) {
      this.setState({ 
        Manufacturings: response.data,
       });
    } else { 
      this.setState({ 
        Manufacturings: [],
     });
    }
  };

  getPackings = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialPacking/getMaterialPackingResult", newObj); 
    if (response.data.length != 0) {
      this.setState({ 
        MaterialPackings: response.data,
       });
    } else { 
      this.setState({ 
        MaterialPackings: [],
     });
    }
  };

  getHandlings = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialHandling/getMaterialHandlingResult", newObj); 
    if (response.data.length != 0) {
      this.setState({ 
        MaterialHandlings: response.data,
       });
    } else { 
      this.setState({ 
        MaterialHandlings: [],
     });
    }
  };

  getTransportations = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialTransportation/getMaterialTransportationResult", newObj); 
    if (response.data.length != 0) {
      this.setState({ 
        MaterialTransportations: response.data,
       });
    } else { 
      this.setState({ 
        MaterialTransportations: [],
     });
    }
  };

  getMaintenances = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialMaintenance/getMaterialMaintenanceResult", newObj); 
    if (response.data.length != 0) {
      this.setState({ 
        MaterialMaintenances: response.data,
       });
    } else { 
      this.setState({ 
        MaterialMaintenances: [],
     });
    }
  };

  getMaterialWarrantys = async () => {
    var materialId="-1";
    if(this.props.row!=null && this.props.row!="" ){
      materialId=this.props.row;
    }
    const newObj = {
      materialId: materialId
    }   
    const response = await handleRequest("POST", "/MaterialWarranty/getMaterialWarrantyResult", newObj); 

    if (response.data.length != 0) {
      this.setState({ 
        MaterialWarrantys: response.data,
       });
    } else { 
      this.setState({ 
        MaterialWarrantys: [],
     });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }; 
     
  showOrHideModalDelete = () => {
    this.setState({
      modalShowDelete: !this.state.modalShowDelete,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
  };  

  renderDownloadFile(rowData) { 
    console.log("WWW", rowData);
    if (rowData!==null && rowData!=="" && rowData!==undefined){
      return <Avatar size={64} src={rowData} onClick={() => this.showReport(rowData)} /> 
    return "";
    } 
  };
  
showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.pdf')) {
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true,
        });
      } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
  
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      } else {
        window.location.href = fileRealUrl;
      }
    } catch (error) { 
      this.showError("File cant found.");
    }
};

showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }

  render() {
    const layoutView = (
        isSidebarOpened,
        container,
        main,
        toolbar,
        sidebar,
        ) => {
        return defaultLayout(
            isSidebarOpened,
            container,
            main,
            toolbar(renderToolbar),
            sidebar,
        );
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div style={{ padding: "15px" }}>
        <Modal
          show={this.state.modalShowDelete}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this data?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              Close
            </Button>
            <Button variant="danger" onClick={this.deleteRecord}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
     
        <Modal
            size="xl"
            show={this.state.modalPhotoShow}
            onHide={this.showOrHidePhotoModal}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.imageUrl !== "" ? (
                <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
                />
            ) : (
                <p>Photo has not been uploaded.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            size="xl"
            show={this.state.modalPdfViewer}
            onHide={this.showOrHideModalPdfViewer}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                PDF Report Viewer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                <Button style={{ marginLeft: "1rem" }}
                onClick={this.printPdf}
                >
                Print
            </Button>
                <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Voucher PDF File Link">
                <FacebookIcon size={32} round />
                </FacebookShareButton>
                <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Voucher PDF File Link is here: " subject="Voucher PDF File Link">
                <EmailIcon size={32} round />
                </EmailShareButton>
                <WhatsappShareButton
                style={{ marginLeft: "1rem" }}
                url={this.state.pdfFile}
                title="Voucher PDF File Link"
                separator=": "
                >
                <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div> */} 

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                </div>
            </Worker> 

            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="secondary"
                onClick={this.showOrHideModalPdfViewer}
            >
                Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Classification
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion" eventKey="0">
              <Card.Body>
              <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Project Material</th>
                        <th>Permanent</th>
                        <th>Re-Usable</th>
                        <th>Inspectible</th>
                        <th>Inspection Level</th>
                        <th>Engineering</th>
                        <th>Photo</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialClassifications.map(classification => (
                          <tr key={classification.index}>
                          <td>{classification.index}</td>
                          <td>{classification.switchMaterialAck}</td>
                          <td>{classification.switchPermanentAck}</td>
                          <td>{classification.switchUsableAck}</td>
                          <td>{classification.switchInspectibleAck}</td>
                          <td>{classification.inspectionlevelAck}</td> 
                          <td>{classification.switchEngineeringAck}</td>
                          <td>{this.renderDownloadFile(classification.identificationFilePath)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="1">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Dimension Information
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallDimension" eventKey="0">
              <Card.Body>
              <Table responsive striped bordered hover>
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <th>S/N</th>
                    <th>Dimension</th>
                    <th>Weight</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {this.state.MaterialDimensions.map(Dimension => (
                      <tr key={Dimension.index}>
                      <td>{Dimension.index}</td>
                      <td>{Dimension.materialDimension}</td>
                      <td>{Dimension.materialWeight}</td>
                    </tr>
                  ))}
                </tbody>
              </Table> 
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="2">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Client's Material Information
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion1" eventKey="0">
              <Card.Body>  

              <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Material Description</th>
                        <th>Material Code</th>
                        <th>Specification</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialClients.map(client => (
                          <tr key={client.index}>
                          <td>{client.index}</td>
                          <td>{client.materialDescription}</td>
                          <td>{client.materialCode}</td>
                          <td>{client.specification}</td>
                          <td>{this.renderDownloadFile(client.clientFilePath)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>


              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="3">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Manufacturing Information
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion2" eventKey="0">
              <Card.Body>
              <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Manufacturer</th>
                          <th>Material Name</th>
                          <th>Material Code</th>
                          <th>Supplier</th>
                          <th>Manufacturer Manual</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.Manufacturings.map(client => (
                            <tr key={client.index}>
                            <td>{client.index}</td>
                            <td>{client.materialManufacturer}</td>
                            <td>{client.manufacturerMaterialName}</td>
                            <td>{client.manufacturerMaterialCode}</td> 
                            <td>{client.materialSupplier}</td>
                            <td>{this.renderDownloadFile(client.manufacturerFilePath)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="4">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Packing
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion3" eventKey="0">
              <Card.Body>
                <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Rules for Packing</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialPackings.map(packing => (
                          <tr key={packing.index}>
                          <td>{packing.index}</td>
                          <td>{packing.rulesForPacking}</td>
                          <td>{this.renderDownloadFile(packing.packingFilePath)}</td> 
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="5">
        <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Handling
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion3" eventKey="0">
              <Card.Body>
                <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Rules for Handling</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialHandlings.map(handling => (
                          <tr key={handling.index}>
                          <td>{handling.index}</td>
                          <td>{handling.rulesForHandling}</td>
                          <td>{this.renderDownloadFile(handling.handlingFilePath)}</td> 
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
       
        </Accordion>
        <br />
        <Accordion defaultActiveKey="6">
        <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Transportation
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion3" eventKey="0">
              <Card.Body>
                <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Rules for Transportation</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialTransportations.map(transition => (
                          <tr key={transition.index}>
                          <td>{transition.index}</td>
                          <td>{transition.rulesForTransportation}</td>
                          <td>{this.renderDownloadFile(transition.transportationFilePath)}</td> 
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
      
        </Accordion>
        <br />
        <Accordion defaultActiveKey="7">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Stocking
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion6" eventKey="0">
              <Card.Body>
                 
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="8">
        <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Maintenance
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion3" eventKey="0">
              <Card.Body>
                <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Rules for Maintenance</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialMaintenances.map(maintenance => (
                          <tr key={maintenance.index}>
                          <td>{maintenance.index}</td>
                          <td>{maintenance.rulesForMaintenance}</td>
                          <td>{this.renderDownloadFile(maintenance.maintenanceFilePath)}</td>  
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
       
        </Accordion>
        <br />
        <Accordion defaultActiveKey="9">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Accessories and Spare Parts
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion6" eventKey="0">
              <Card.Body>
                  
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="10">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0"
            >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Waranty
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="overallAccordion6" eventKey="0">
              <Card.Body>
          
              <Table responsive striped bordered hover>
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Warranty period</th>
                        <th>Period Type</th>
                        <th>Warranty Start Date</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {this.state.MaterialWarrantys.map(Warranty => (
                          <tr key={Warranty.index}>
                          <td>{Warranty.index}</td>
                          <td>{Warranty.warrantyPeriod}</td>
                          <td>{Warranty.periodTip}</td>
                          <td>{this.setDateFormat(Warranty.startDate)}</td>
                          <td>{this.renderDownloadFile(Warranty.warrantyFilePath)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialLogSub);