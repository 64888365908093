/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { Form, Col, Row } from "react-bootstrap";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Radio, Button as AntButton, Checkbox as AntCheckbox, Upload, InputNumber, Switch, message } from "antd";
import "react-toggle/style.css";
import { Toast } from "primereact/toast";
import { handleRequest } from "../../redux/config/env";
import * as Mnd from "../../partials/content/CustomComponents";
import { Select, Divider, Input, Modal as AntModal } from 'antd';
import { UploadOutlined, CloseOutlined, EditOutlined, LinkOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import "../settings/css/AntUpload.css";
import RadioGroup from "antd/lib/radio/group";
import { InputText } from 'primereact/inputtext';

import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.css';


const { confirm } = AntModal;
const { Option } = Select;

const alphabetUpper = [
  {
    aIndex: 1,
    letter: "A",
  },
  {
    aIndex: 2,
    letter: "B",
  },
  {
    aIndex: 3,
    letter: "C",
  },
  {
    aIndex: 4,
    letter: "D",
  },
  {
    aIndex: 5,
    letter: "E",
  },
  {
    aIndex: 6,
    letter: "F",
  },
  {
    aIndex: 7,
    letter: "G",
  },
  {
    aIndex: 8,
    letter: "H",
  },
  {
    aIndex: 9,
    letter: "I",
  },
  {
    aIndex: 10,
    letter: "J",
  },
  {
    aIndex: 11,
    letter: "K",
  },
  {
    aIndex: 12,
    letter: "L",
  },
  {
    aIndex: 13,
    letter: "M",
  },
  {
    aIndex: 14,
    letter: "N",
  },
  {
    aIndex: 15,
    letter: "O",
  },
  {
    aIndex: 16,
    letter: "P",
  },
  {
    aIndex: 17,
    letter: "Q",
  },
  {
    aIndex: 18,
    letter: "R",
  },
  {
    aIndex: 19,
    letter: "S",
  },
  {
    aIndex: 20,
    letter: "T",
  },
  {
    aIndex: 21,
    letter: "U",
  },
  {
    aIndex: 22,
    letter: "V",
  },
  {
    aIndex: 23,
    letter: "W",
  },
  {
    aIndex: 24,
    letter: "X",
  },
  {
    aIndex: 25,
    letter: "Y",
  },
  {
    aIndex: 26,
    letter: "Z",
  },
];

class QuestionnaryExpand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      addButton: "Add",
      createNewButton: "Create New",
      createNewButtonVariant: "p-button-info",
      buttonClassName: "p-button-success",
      answerId: 0,
      letterItemGroupIndex: 0,
      letterItemNoIndex: 0,

      itemGroupNo: "",
      itemGroup: "",
      chkItemGroupLetter: false,
      chkItemGroupNumber: false,
      itemGroupLetterorNumber: "",
      itemGroupName: "",
      itemGroupDescription: "",
      itemGroupList: [],
      itemGroupId: '',
      itemGroupType: "",
      itemGroupTypeNumber: 1,

      itemNo: "",
      chkItemNoLetter: false,
      chkItemNoNumber: false,
      itemNoLetterorNumber: "",
      itemNoType: "",
      itemNoTypeNumber: 1,

      question: "",
      answerFormat: "",
      answers: "",
      evaluation: 0,
      checkEvaluation: false,

      chkItemGroup: false,
      itemGroupTypeHidden: true,
      itemGroupLetterorNumberHidden: true,
      itemGroupNameHidden: true,

      chkItemNo: false,
      itemNoTypeHidden: true,
      itemNoLetterorNumberHidden: true,

      questions: [],
      linkableQuestions: [],
      toLinkQuestionId: '',
      toLinkOptionId: '',
      linkedQuestion: false,
      rows: [],

      questionContent: '',
      questionId: 0,
      type: '',
      newOption: '',
      selectedOptions: [],
      selectedColumns: [],
      options: [],
      oldOptions: [],
      oldColumns: [],
      oldRows: [],
      oldFileList: [],
      rateMax: 5,
      labelTrue: '',
      labelFalse: '',
      fileList: [],
      orderQuestion: null,
      questionnary: null,

    };

    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentDidMount = async () => {

    this.fillOptions();
    this.getQuestions();
    this.fillCodes();


    var response = await handleRequest(
      "GET",
      "/Questionnary/" + this.props.row.id
    );

    if (Boolean(response.data)) {
      this.setState({
        questionnary: response.data,
        evaluation: response.data.point
      })
    }
  }

  fillCodes = async () => {

    const response = await handleRequest("GET", "/Code/type/qig"); // qig : QuestionaryItemGroups

    console.log(response.data);
    if (response.data !== null && response.data !== undefined) {
      this.setState({
        itemGroupList: response.data
      });
    }


  }

  fillOptions = async () => {

    const response = await handleRequest("GET", "/Option/getAll");


    if (Boolean(response.data)) {
      this.setState({
        options: response.data

      });
    }

  }


  showSuccess() {
    this.toast.show({
      severity: "success",
      summary: "Success Message",
      detail: "Successful.",
    });
  }

  showError(msg) {
    if (!msg) {
      msg = "Something went wrong. Please check inputs.";
    }
    this.toast.show({
      severity: "error",
      summary: "Error Message",
      detail: msg,
    });
  }



  getQuestions = async () => {
    var response2 = await handleRequest("GET", "/MyQuestion/getAll");
    if (response2.data.length !== 0) {

      const _questions = response2.data.filter(p => p.questionnaryId === this.props.row.id);
      const linkableTypes = ["checkbox", "radiogroup", "dropdown", "boolean"];
      const _linkableQuestions = _questions.filter(p => linkableTypes.includes(p.type));
      const allGrups = this.state.itemGroupList;
      var grupList = [];

      _questions.forEach(element => {

        if (Boolean(element.grupId) && element.grupId > 0 && !grupList.includes(element.grupId))
          grupList.push(element.grupId);

      });

      grupList.forEach(element => {

        var index = _questions.findIndex(p => p.grupId === element);

        _questions.filter(p => p.grupId === element).forEach((q, i) => {

          q.code = i + 1 + '.';
          q.grupEx=q.grup.slice(); 
          q.grup = q.grup + '.' ;
       
        });

        let obj = { questionContent: allGrups.filter(p => p.id === element)[0].name, grup: _questions[index].grup, type: 'group' }
        _questions.splice(index, 0, obj);
      });

      _questions.filter(p => p.grupId === 0 || p.grupId === null).forEach((q, i) => {

        q.code = i + 1 + '.';
        q.grupEx=q.grup.slice(); 
        q.grup = '';
      });




      this.setState({
        questions: _questions,
        linkableQuestions: _linkableQuestions

      });
    } else {
      this.setState({ questions: [] });
    }


  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      selectedColumns: [],
      selectedOptions: [],
      rows: []
    });

  };

  setDateFormat(d) {
    //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return (
        "" +
        dt
          .getFullYear()
          .toString()
          .padStart(4, "0") +
        "-" +
        (dt.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        dt
          .getDate()
          .toString()
          .padStart(2, "0")
      );
    } else return "";
  }

  cancelButtonUpdate = async (row) => {
    this.setState({
      hidden: true,
      answerId: 0,
      itemGroupNo: "",
      itemGroup: "",
      itemNo: "",
      question: "",
      answerFormat: "",
      answers: "",
      evaluation: 0,
    });
  };

  showNewQuestionForm = async (row) => {
    this.setState({
      hidden: false,
      answerId: 0,
      itemGroupNo: "",
      itemGroup: "",
      itemNo: "",
      question: "",
      answerFormat: "",
      answers: "",
      evaluation: 0
    });
  };

  addNewRow = () => {
    const { rows } = this.state;
    rows.push({
      id: 0,
      questionContent: "",
    });

    this.setState({
      rows: rows,
    });
  };

  removeSpecialRow = (index) => {

    console.log(index);
    let rows = this.state.rows;
    //rows[index].questionContent = '';

    rows.splice(index, 1);
    this.setState({
      rows: rows,
    });
  };

  removeLastRow = (index) => {
    const { rows } = this.state;
    if (rows.length !== 1) {
      rows.pop();
    }

    this.setState({
      rows: rows,
    });
  };

  handleChangeRow = (index, e) => {
    e.preventDefault();
    let list = this.state.rows;
    list[index].questionContent = e.target.value;

    this.setState({
      rows: list.slice(),
    });
  };

  handleItemGroupUpChange = (e) => {
    e.preventDefault();
    let sayi =
      this.state.itemGroupType === "number"
        ? this.state.itemGroupTypeNumber + 1
        : this.state.itemGroupTypeNumber;
    let harfindex =
      this.state.itemGroupType === "letter" &&
        alphabetUpper[this.state.letterItemGroupIndex].letter !== "Z"
        ? this.state.letterItemGroupIndex + 1
        : this.state.letterItemGroupIndex;
    this.setState({
      itemGroupLetterorNumber:
        this.state.itemGroupType === "letter"
          ? alphabetUpper[harfindex].letter
          : sayi,
      letterItemGroupIndex: harfindex,
      itemGroupTypeNumber: sayi,
    });
  };

  handleItemGroupDownChange = (e) => {
    e.preventDefault();
    let sayi =
      this.state.itemGroupType === "number" &&
        this.state.itemGroupTypeNumber > 1
        ? this.state.itemGroupTypeNumber - 1
        : this.state.itemGroupTypeNumber;
    let harfindex =
      this.state.itemGroupType === "letter" &&
        alphabetUpper[this.state.letterItemGroupIndex].letter !== "A"
        ? this.state.letterItemGroupIndex - 1
        : this.state.letterItemGroupIndex;
    this.setState({
      itemGroupLetterorNumber:
        this.state.itemGroupType === "letter"
          ? alphabetUpper[harfindex].letter
          : sayi,
      letterItemGroupIndex: harfindex,
      itemGroupTypeNumber: sayi,
    });
  };

  handleItemNoUpChange = (e) => {
    e.preventDefault();
    let sayi =
      this.state.itemNoType === "number"
        ? this.state.itemNoTypeNumber + 1
        : this.state.itemNoTypeNumber;

    let harfindex =
      this.state.itemNoType === "letter" &&
        alphabetUpper[this.state.letterItemNoIndex].letter !== "Z"
        ? this.state.letterItemNoIndex + 1
        : this.state.letterItemNoIndex;
    this.setState({
      itemNoLetterorNumber:
        this.state.itemNoType === "letter"
          ? alphabetUpper[harfindex].letter
          : sayi,
      letterItemNoIndex: harfindex,
      itemNoTypeNumber: sayi,
    });
  };

  handleItemNoDownChange = (e) => {
    e.preventDefault();
    let sayi =
      this.state.itemNoType === "number" && this.state.itemNoTypeNumber > 1
        ? this.state.itemNoTypeNumber - 1
        : this.state.itemNoTypeNumber;

    let harfindex =
      this.state.itemNoType === "letter" &&
        alphabetUpper[this.state.letterItemNoIndex].letter !== "A"
        ? this.state.letterItemNoIndex - 1
        : this.state.letterItemNoIndex;
    this.setState({
      itemNoLetterorNumber:
        this.state.itemNoType === "letter"
          ? alphabetUpper[harfindex].letter
          : sayi,
      letterItemNoIndex: harfindex,
      itemNoTypeNumber: sayi,
    });
  };

  addItem = async () => {
    const { newOption } = this.state;


    if (newOption.length > 0) {

      const newItem = {
        optionContent: newOption,

      }

      await handleRequest("POST", "/Option/add", newItem);


      this.setState({

        newOption: '',
      });

      this.fillOptions();
    }

  };

  showFiles = async () => {

    console.log(this.state.fileList);

  };

  onNewOptionChange = event => {
    this.setState({
      newOption: event.target.value,
    });
  };

  addQuestion = async () => {

    var _options = this.state.oldOptions;
    var _columns = this.state.oldColumns;
    var _rows = this.state.oldRows;
    var _imageOptions = this.state.oldFileList;

    _options.forEach(a => {
      a.active = false;
    });

    _columns.forEach(b => {
      b.active = false;
    });

    _rows.forEach(c => {
      c.active = false;
    });

    _imageOptions.forEach(d => {
      d.active = false;
    });

    if (Boolean(this.state.selectedOptions) && Boolean(this.state.type) && this.state.type !== 'imagepicker') {

      this.state.selectedOptions.forEach(element => {
        let obj = { optionId: element, type: 'option' };

        let index = _options.findIndex(p => p.optionId == obj.optionId);
        if (index === -1)
          _options.push(obj);
        else
          _options[index].active = true;


      });


    }

    if (Boolean(this.state.selectedColumns)) {
      this.state.selectedColumns.forEach(element => {
        let obj = { optionId: element, type: 'column' };

        let index = _columns.findIndex(p => p.optionId == obj.optionId);
        if (index === -1)
          _columns.push(obj);
        else
          _columns[index].active = true;
      });
    }

    if (Boolean(this.state.rows))
      this.state.rows.forEach(element => {
        if (element.id > 0) {
          let index = _rows.findIndex(p => p.id == element.id);
          if (index === -1)
            _rows.push(element);
          else
            _rows[index].active = true;
        }
        else _rows.push(element);

      });

    if (Boolean(this.state.fileList))
      this.state.fileList.forEach(element => {

        if (Boolean(element.id)) {
          let index = _imageOptions.findIndex(p => p.id == element.id);
          if (index > -1)
            _imageOptions[index].active = true;

          let index2 = _options.findIndex(p => p.optionId == element.id);
          if (index2 > -1)
            _options[index2].active = true;

        }
        else {
          let obj = { imagePath: element.response.url };
          _imageOptions.push(obj);
        }

      });

    const { questions, toLinkQuestionId, linkedQuestion } = this.state;

    let _orderQuestion = 0;
    if (this.state.orderQuestion !== null) {
      _orderQuestion = this.state.orderQuestion;
    }
    else {
      if (Boolean(questions))
        _orderQuestion = questions.length;
    }

    if (linkedQuestion)
      _orderQuestion = questions.find(p => p.id == toLinkQuestionId).orderQuestion;

    const item = {
      id: this.state.questionId,
      questionnaryId: this.props.row.id,
      questionContent: this.state.questionContent,
      type: this.state.type,
      rateMax: this.state.rateMax,
      labelTrue: this.state.labelTrue,
      labelFalse: this.state.labelFalse,
      options: _options,
      imageOptions: _imageOptions,
      columns: _columns,
      rows: _rows,
      grup: this.state.itemGroupLetterorNumber,
      code: this.state.itemNoLetterorNumber,
      linkedQuestion: linkedQuestion,
      toLinkedQuestionId: toLinkQuestionId,
      toLinkedOptionId: this.state.toLinkOptionId,
      orderQuestion: _orderQuestion,
      grupId: this.state.itemGroupId

    }

    console.log(item);


    if (item.id === 0)
      var response = await handleRequest("POST", "/MyQuestion/add", item);
    else
      response = await handleRequest("POST", "/MyQuestion/update", item);


    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS-QUESTION-001") {

        this.showSuccess();
        this.getQuestions();
        this.resetInputs();
      }
      if (response.data === "ERROR-QUESTION-001") {
        this.showError("An error was occured please try again later !");
      }

    }


  }

  saveEvaluation = async () => {


    var item = this.state.questionnary;
    var flag = this.state.questions;
    var questions=[];
    item.point = this.state.evaluation;
    var maxQuestionPoint = 1;
    var total=0; 

    flag.forEach(element => {
      if(Boolean(element.type) && element.type!=='group')
     { 
      element.grup=element.grupEx; 
      questions.push(element);       
    }
    });

    if (Boolean(questions) && questions.length > 0) {

      questions.forEach(element => {

        total = total + element.point ; 
        // if (element.point > maxQuestionPoint)
        //   maxQuestionPoint = element.point;

        if (Boolean(element.rows) && element.rows.length > 0) {
          let maxRowPoint = 1;

          element.rows.forEach(row => {
            if (row.point > maxRowPoint)
              maxRowPoint = row.point;

            if (Boolean(element.columns) && element.columns.length > 0) {
              let maxColumnPoint = 1;
              element.columns.forEach(column => {
                if (column.point > maxColumnPoint)
                  maxColumnPoint = column.point;


                if (Boolean(element.options) && element.options.length > 0) {
                  let maxOptionPoint = 1;
                  element.options.forEach(option => {
                    if (option.point > maxOptionPoint)
                      maxOptionPoint = option.point;
                  });
                  column.factor = column.point / maxOptionPoint;
                }

              });
              row.factor = row.point / maxColumnPoint;
            }

          });

          element.factor = element.point / maxRowPoint;
        } else
          if (Boolean(element.options) && element.options.length > 0) {

            let maxOptionPoint = 1;
            element.options.forEach(option => {
              if (option.point > maxOptionPoint)
                maxOptionPoint = option.point;


            });
            element.factor = element.point / maxOptionPoint;

          }


      });

      item.factor = item.point / maxQuestionPoint;
      item.questions = questions;

      console.log(total); 
      if(total!=item.point)
      {
        message.error("Total evaluations of questions must same as total evaluation")
        return;
      }

      var response = await handleRequest("POST", "/Questionnary/updateForEvaluation", item);

      if (Boolean(response)) {
        if (response.data === "SUCCESS-QUESTIONNARY-001") {
          message.success("Evaluations saved successfully.");
          this.getQuestions();
        } else if (response.data === "ERROR-QUESTIONNARY-001") {
          message.error("An error was occured please try again later!")
        }
      } else
        message.error("An error was occured please try again later!")


    }
    else this.showError("Please add questions before setting evaluation !")




  }

  handleFileChange = ({ fileList }) => this.setState({ fileList });

  getRatingList = (max) => {
    const numbers = [];

    for (let index = 1; index <= max; index++) {
      numbers.push(index);

    }

    return numbers;

  }


  deleteModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this question?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected question will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteQuestion(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });


  }

  deleteQuestion = async (pId) => {
    const item = {
      id: pId
    }

    await handleRequest("POST", "/MyQuestion/delete", item);

    this.getQuestions();
    this.showSuccess();

  }

  edit = async (row) => {

    console.log(row);

    let _options = [];
    let _columns = [];
    let _rows = [];
    let _fileList = [];

    if (Boolean(row.options)) {
      this.setState({
        oldOptions: row.options.slice()
      })

      row.options.forEach(element => {
        _options.push(element.optionId);

      });

    }

    if (Boolean(row.columns)) {

      this.setState({
        oldColumns: row.columns.slice()
      })

      row.columns.forEach(element => {
        _columns.push(element.optionId);

      });
    }

    if (Boolean(row.rows)) {
      _rows = row.rows

    }

    if (Boolean(row.type) && row.type === 'imagepicker') {

      if (Boolean(row.options)) {
        row.options.forEach(element => {
          let obj = { url: element.option.imagePath, uid: element.option.id, imagePath: element.option.imagePath, id: element.option.id, name: element.option.imagePath.substring(element.option.imagePath.lastIndexOf('/') + 1) }
          _fileList.push(obj);
        });
      }

    }


    this.setState({
      questionId: row.id,
      selectedOptions: _options,
      questionContent: row.questionContent,
      type: row.type,
      rateMax: row.rateMax,
      labelTrue: row.labelTrue,
      labelFalse: row.labelFalse,
      selectedColumns: _columns,
      rows: _rows,
      oldRows: _rows,
      fileList: _fileList,
      oldFileList: _fileList,
      toLinkQuestionId: row.toLinkedQuestionId,
      linkedQuestion: row.linkedQuestion,
      toLinkOptionId: row.toLinkedOptionId,
      orderQuestion: row.orderQuestion,
      itemGroupId: row.grupId,
    })

  }

  resetInputs = () => {
    this.setState({
      questionId: 0,
      selectedOptions: [],
      questionContent: '',
      type: '',
      rateMax: 5,
      labelTrue: '',
      labelFalse: '',
      selectedColumns: [],
      rows: [],
      oldRows: [],
      oldColumns: [],
      oldOptions: [],
      fileList: [],
      oldFileList: [],
      toLinkQuestionId: '',
      linkedQuestion: false,
      toLinkOptionId: '',
      orderQuestion: null,
      itemGroupId: '',

    })
  }

  addItemGroupDescription = async () => {
    const { itemGroupDescription } = this.state;


    if (itemGroupDescription.length > 0) {

      const newItem = {
        name: itemGroupDescription,
        type: "qig"
      }

      await handleRequest("POST", "/Code/add", newItem);


      this.setState({

        itemGroupDescription: '',
      });

      this.fillCodes();
    }

  };

  render() {


    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };



    let radioGroupOptions = (
      <div>
        <Radio value={1}>Letter</Radio>
        <Radio value={2}>Number</Radio>
      </div>
    );


    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };


    return (
      <div>
        <div className="col-md-12">
          <Toast ref={(el) => this.toast = el}></Toast>
          {/* <div style={{ backgroundColor: "white", padding: "10px" }}>
            <Row>
              <Col sm="12" md={{ size: 2, offset: 10 }}>
                <Button
                  id="btn-createnew"
                  style={{ width: "100%" }}
                  className={this.state.createNewButtonVariant}
                  onClick={this.showNewQuestionForm}
                  label="Add Question"
                />
              </Col>
            </Row>
          </div>
          <br></br> */}
          <div
            style={{ backgroundColor: "white", padding: "10px" }}

          >
            <Form>
              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">
                  <Mnd.MNDCheckbox
                    onChange={(e) =>
                      this.setState({
                        chkItemGroup: e.checked,
                        itemGroupTypeHidden: !e.checked,
                        itemGroupLetterorNumberHidden: !e.checked,
                        itemGroupNameHidden: !e.checked,
                      })
                    }
                    checked={this.state.chkItemGroup}
                    label="Item Group"
                  />
                </Col>
                <Col xs="3">
                  <div hidden={this.state.itemGroupTypeHidden}>
                    <Mnd.MNDRadioGroup
                      onChange={(e) => {
                        this.setState({
                          itemGroupType:
                            e.target.value === 1 ? "letter" : "number",
                          itemGroupLetterorNumber:
                            e.target.value === 1
                              ? alphabetUpper[this.state.letterItemGroupIndex]
                                .letter
                              : this.state.itemGroupTypeNumber,
                        });
                      }}
                      radios={radioGroupOptions}
                    />
                  </div>
                </Col>
                <Col xs="2">
                  <div className="p-inputgroup">
                    <Button icon="pi pi-minus" className="p-button-secondary" hidden={this.state.itemGroupNameHidden}
                      type="button"
                      onClick={this.handleItemGroupDownChange} />
                    <Form.Control hidden={this.state.itemGroupLetterorNumberHidden}
                      onChange={(e) =>
                        this.setState({
                          itemGroupLetterorNumber: e.target.value,
                        })
                      }
                      value={this.state.itemGroupLetterorNumber}
                      placeholder=""
                      style={{ width: "100%" }} />

                    <Button icon="pi pi-plus" className="p-button-secondary" hidden={this.state.itemGroupNameHidden}
                      type="button"

                      onClick={this.handleItemGroupUpChange}
                    />
                  </div>


                </Col>

              </Form.Group>

              {/* <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">
                  <Mnd.MNDCheckbox
                    onChange={(e) =>
                      this.setState({
                        chkItemNo: e.checked,
                        itemNoTypeHidden: !e.checked,
                        itemNoLetterorNumberHidden: !e.checked,
                      })
                    }
                    checked={this.state.chkItemNo}
                    label="Item"
                  />
                </Col>
                <Col xs="3">
                  <div hidden={this.state.itemNoTypeHidden}>
                    <Mnd.MNDRadioGroup
                      onChange={(e) => {
                        this.setState({
                          itemNoType:
                            e.target.value === 1 ? "letter" : "number",
                          itemNoLetterorNumber:
                            e.target.value === 1
                              ? alphabetUpper[this.state.letterItemNoIndex]
                                .letter
                              : this.state.itemNoTypeNumber,
                        });
                      }}
                      radios={radioGroupOptions}
                    />
                  </div>
                </Col>
                <Col xs="1">
                  <Form.Control hidden={this.state.itemNoLetterorNumberHidden}
                    onChange={(e) =>
                      this.setState({
                        itemNoLetterorNumber: e.target.value,
                      })
                    }
                    value={this.state.itemNoLetterorNumber}
                    placeholder=""
                    style={{ width: "100%" }} />

                 
                </Col>
                <Col xs="3"></Col>
                <Col xs="2">
                  <Mnd.MNDButton
                    hidden={this.state.itemNoLetterorNumberHidden}
                    btnId="btn-updatestep"
                    style={{ width: "50%" }}
                    btnClassName={this.state.createNewButtonVariant}
                    btnLabel=""
                    icon="pi pi-angle-double-up"
                    onClickFunc={this.handleItemNoUpChange}
                  />
                  <Mnd.MNDButton
                    hidden={this.state.itemNoLetterorNumberHidden}
                    btnId="btn-updatestep"
                    style={{ width: "50%" }}
                    btnClassName={this.state.createNewButtonVariant}
                    btnLabel=""
                    icon="pi pi-angle-double-down"
                    onClickFunc={this.handleItemNoDownChange}
                  />
                </Col>
              </Form.Group> */}
              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Item Group Description</Col>
                <Col xs="9">

                  <Select size='large'
                    style={{ width: '100%' }}
                    placeholder="Unit"
                    value={this.state.itemGroupId}
                    onChange={(value) => {
                      this.setState({
                        itemGroupId: value
                      })
                    }}

                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.itemGroupDescription} onChange={(event) => { this.setState({ itemGroupDescription: event.target.value }) }} />
                          <a
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addItemGroupDescription}
                          >
                            <PlusOutlined /> Add item
                                                               </a>
                        </div>
                      </div>
                    )}
                  >
                    {this.state.itemGroupList.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>


                </Col>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Question</Col>
                <Col xs="9">

                  <Form.Control style={{ width: "100%" }} name="questionContent" value={this.state.questionContent}
                    onChange={(e) =>
                      this.setState({
                        questionContent: e.target.value,
                      })
                    } />

                </Col>
              </Form.Group>

              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">  <AntCheckbox checked={this.state.linkedQuestion} onChange={(e) => {
                  if (e.target.checked === false)
                    this.setState({ toLinkQuestionId: '', toLinkOptionId: '' })

                  this.setState({ linkedQuestion: e.target.checked })
                }} > Linked Question </AntCheckbox> </Col>
                <Col xs="3">

                  <Form.Control name="type" hidden={!this.state.linkedQuestion}
                    as="select" onChange={(e) => {
                      e.persist();
                      let val = e.target.value;

                      this.setState({ toLinkQuestionId: val })

                    }} value={this.state.toLinkQuestionId}
                  >
                    <option value="Select">Select Option</option>
                    {this.state.linkableQuestions.map((item, j) => (
                      <option value={item.id}>{item.questionContent}</option>

                    ))}
                  </Form.Control>

                </Col>

                <Col xs="3">

                  {Boolean(this.state.toLinkQuestionId) && Boolean(this.state.questions.filter(p => p.id == this.state.toLinkQuestionId)[0]) && this.state.questions.filter(p => p.id == this.state.toLinkQuestionId)[0].type === 'boolean' && <Form.Control name="type" hidden={!Boolean(this.state.toLinkQuestionId)}
                    as="select" onChange={(e) => {
                      e.persist();
                      let val = e.target.value;

                      this.setState({ toLinkOptionId: val })

                    }}
                    value={this.state.toLinkOptionId}
                  >
                    <option value="Select">Select Option</option>
                    <option value={1}> {this.state.linkableQuestions.filter(p => p.id == this.state.toLinkQuestionId)[0].labelTrue} </option>
                    <option value={0}>{this.state.linkableQuestions.filter(p => p.id == this.state.toLinkQuestionId)[0].labelFalse}</option>
                  </Form.Control>}


                  {Boolean(this.state.toLinkQuestionId) && Boolean(this.state.questions.filter(p => p.id == this.state.toLinkQuestionId)[0]) && this.state.questions.filter(p => p.id == this.state.toLinkQuestionId)[0].type !== 'boolean' && <Form.Control name="type" hidden={!Boolean(this.state.toLinkQuestionId)}
                    as="select" onChange={(e) => {
                      e.persist();
                      let val = e.target.value;

                      this.setState({ toLinkOptionId: val })

                    }}
                    value={this.state.toLinkOptionId}
                  >
                    <option value="Select">Select Option</option>
                    {
                      this.state.linkableQuestions.filter(p => p.id == this.state.toLinkQuestionId)[0].options.map((item, j) => (
                        <option value={item.optionId}>{item.option.optionContent}</option>

                      ))
                    }
                  </Form.Control>}

                </Col>
              </Form.Group>

              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Answer Format</Col>
                <Col xs="3">
                  <Form.Control name="type" value={this.state.type}
                    onChange={this.handleChange} as="select"  >
                    <option value="">Select ...</option>
                    <option value="text">Comment</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="radiogroup">Radiogroup</option>
                    <option value="dropdown">Dropdown </option>
                    <option value="rating">Rating </option>
                    <option value="imagepicker">Imagepicker </option>
                    <option value="boolean">Boolean </option>
                    <option value="file">File </option>
                    <option value="matrix">Matrix (Single choice) </option>
                    <option value="matrixdropdown">Matrix (Multiple choice) </option>


                  </Form.Control>

                </Col>
              </Form.Group>

              {/* <div className="p-col-12 p-md-3">
                        Horizontal with Step
                        <InputNumber value={this.state.value18} onChange={(e) => this.setState({value18: e.value})} showButtons buttonLayout="horizontal" step={0.25}
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="currency" currency="EUR"/>
                    </div>
                     */}
              {Boolean(this.state.type) && (this.state.type === 'matrix' || this.state.type === 'matrixdropdown') && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Rows</Col>
                <Col xs="9">
                  {this.state.rows.map((item, index) => (
                    <Form.Group
                      key={index}
                      style={{ marginBottom: "1rem" }}
                      as={Row}
                    >

                      <Col sm="10">
                        <Form.Control

                          value={item.questionContent}
                          onChange={(e) => this.handleChangeRow(index, e)}
                        />
                      </Col>
                      <Col sm="2">
                        <AntButton type="dashed" onClick={() => this.removeSpecialRow(index)} shape="circle" icon={<CloseOutlined />} />
                      </Col>

                    </Form.Group>
                  ))}

                  <Form.Group style={{ marginBottom: "1rem" }} as={Row}>

                    <Col sm="4">
                      <AntButton
                        type="dashed"
                        onClick={this.removeLastRow}
                        style={{ width: "100%" }}
                      >
                        <MinusOutlined /> Remove Row
                  </AntButton>
                    </Col>
                    <Col sm="4">
                      <AntButton
                        type="dashed"
                        onClick={this.addNewRow}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Row
                  </AntButton>
                    </Col>

                  </Form.Group>
                </Col>
              </Form.Group>}

              {Boolean(this.state.type) && (this.state.type === 'matrix' || this.state.type === 'matrixdropdown') && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Columns</Col>
                <Col xs="3">
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="columns"

                    value={this.state.selectedColumns}
                    onChange={(value) => {
                      this.setState({ selectedColumns: value })
                    }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newOption} onChange={this.onNewOptionChange} />
                          <a
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addItem}
                          >
                            <PlusOutlined /> Add item
                                </a>
                        </div>
                      </div>
                    )}
                  >
                    {this.state.options.map(i => (
                      Boolean(i.optionContent) ? <Option key={i.id} value={i.id}>{i.optionContent}</Option> : ''
                    ))}
                  </Select>
                </Col>
              </Form.Group>}



              {Boolean(this.state.type) && (this.state.type === 'checkbox' || this.state.type === 'radiogroup' || this.state.type === 'dropdown' || this.state.type === 'matrixdropdown') && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Options</Col>
                <Col xs="3">
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Options"

                    value={this.state.selectedOptions}
                    onChange={(value) => {
                      this.setState({ selectedOptions: value })
                    }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.newOption} onChange={this.onNewOptionChange} />
                          <a
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addItem}
                          >
                            <PlusOutlined /> Add item
                                </a>
                        </div>
                      </div>
                    )}
                  >
                    {this.state.options.filter(p => Boolean(p.optionContent)).map(i => (
                      <Option key={i.id} value={i.id}>{i.optionContent}</Option>
                    ))}
                  </Select>
                </Col>
              </Form.Group>}

              {Boolean(this.state.type) && this.state.type === 'rating' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Max Rate</Col>
                <Col xs="3">
                  <InputNumber min={1} value={this.state.rateMax} onChange={(value) => {
                    this.setState({ rateMax: value })
                  }} />
                </Col>
              </Form.Group>}

              {Boolean(this.state.type) && this.state.type === 'imagepicker' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">Choose Images</Col>
                <Col xs="3">
                  <Upload {...props}
                    onChange={this.handleFileChange}
                  >
                    <AntButton>
                      <UploadOutlined /> Upload
                     </AntButton>
                  </Upload>
                </Col>
              </Form.Group>}



              {Boolean(this.state.type) && this.state.type === 'boolean' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3">First Option</Col>
                <Col xs="3">
                  <Form.Control style={{ width: "100%" }} name="labelTrue" value={this.state.labelTrue}
                    onChange={(e) =>
                      this.setState({
                        labelTrue: e.target.value,
                      })
                    } />
                </Col>
                <Col xs="3">Second Option</Col>
                <Col xs="3">
                  <Form.Control style={{ width: "100%" }} name="labelFalse" value={this.state.labelFalse}
                    onChange={(e) =>
                      this.setState({
                        labelFalse: e.target.value,
                      })
                    } />
                </Col>
              </Form.Group>}


              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Col xs="3"><AntCheckbox checked={this.state.checkEvaluation} onChange={(e) => {

                  this.setState({ checkEvaluation: e.target.checked })
                }} > Evaluation </AntCheckbox> </Col>

                {this.state.checkEvaluation && <Col xs="3">
                  <InputNumber min={1} value={this.state.evaluation} onChange={(value) => {
                    this.setState({ evaluation: value })
                  }} />(Total evaluation)
                </Col>}


              </Form.Group>
            </Form>
            <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
              <Col sm="12" md={{ size: 2, offset: 10 }}>
                <Button
                  id="btn-addnew"
                  style={{ width: "100%" }}
                  className={this.state.buttonClassName}
                  onClick={this.addQuestion}
                  label="Save"
                />

                {/* <Button
                  id="btn-addnew"
                  style={{ width: "100%" }}
                  className={this.state.buttonClassName}
                  onClick={this.showFiles}
                  label="Show Files "
                /> */}


              </Col>
            </Form.Group>
          </div>
          <br></br>
          {this.state.questions.map((item, index) => (
            <>
              <div
                style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}

              >
                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  {this.state.checkEvaluation && Boolean(item.type) && item.type !== 'group' && <Col xs="1">
                    <InputNumber min={1} value={item.point} onChange={(value) => {
                      this.setState(prevState => ({
                        questions: prevState.questions.map(
                          el => el.id === item.id ? { ...el, point: value } : el
                        )
                      }))
                    }} />
                  </Col>}
                  <Form.Label style={{ color: 'black' }} column sm="9">
                    {item.grup}  {item.code}  {item.questionContent}
                  </Form.Label>


                  <Col sm="2">
                    <div style={{ float: 'right' }} >
                      {item.linkedQuestion && <AntButton style={{ marginRight: '10px' }} type="dashed" shape="circle" icon={<LinkOutlined />} />}
                      <AntButton style={{ marginRight: '10px' }} type="dashed" onClick={() => this.edit(item)} shape="circle" icon={<EditOutlined />} />
                      <AntButton type="dashed" onClick={() => this.deleteModal(item)} shape="circle" icon={<CloseOutlined />} />
                    </div>
                  </Col>

                </Form.Group>
                {Boolean(item.type) && item.type === 'text' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="12">
                    <Form.Control as="textarea" rows="3" />
                  </Col>

                </Form.Group>}


                {Boolean(item.type) && item.type === 'radiogroup' &&

                  <>
                    {item.options.map((opt, j) => (
                      <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                        {
                          this.state.checkEvaluation &&
                          <Col xs="1">  <InputNumber min={1} value={opt.point} onChange={(value) => {

                            let _options = this.state.questions.find(p => p.id === item.id).options;
                            _options.find(p => p.id === opt.id).point = value;

                            this.setState(prevState => ({
                              questions: prevState.questions.map(
                                el => el.id === opt.id ? { ...el, options: _options } : el
                              )
                            }))
                          }} /></Col>

                        }
                        <Col xs="11">   < Radio checked={false} >
                          {opt.option.optionContent}
                        </Radio>
                        </Col>
                      </Form.Group>

                    ))}      </>

                }

                {Boolean(item.type) && item.type === 'imagepicker' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="12">

                    {item.options.map((opt, j) => (
                      <>
                        {
                          this.state.checkEvaluation &&
                          <InputNumber style={{ marginRight: "1rem" }} min={1} value={opt.point} onChange={(value) => {

                            let _options = this.state.questions.find(p => p.id === item.id).options;
                            _options.find(p => p.id === opt.id).point = value;

                            this.setState(prevState => ({
                              question: prevState.questions.map(
                                el => el.id === opt.id ? { ...el, options: _options } : el
                              )
                            }))
                          }} />

                        }
                        <RadioGroup>
                          <Radio checked={false} >
                            <img alt="option" style={{ width: '120px', height: '120px' }} src={opt.option.imagePath} />
                          </Radio>
                        </RadioGroup></>
                    ))}


                  </Col>

                </Form.Group>}

                {Boolean(item.type) && item.type === 'rating' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="12">
                    <Radio.Group buttonStyle="solid">
                      {this.getRatingList(item.rateMax).map((element, index) => (
                        <Radio.Button value={element}>{element}</Radio.Button>
                      ))
                      }
                    </Radio.Group>

                  </Col>

                </Form.Group>}

                {Boolean(item.type) && item.type === 'boolean' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="12">

                    <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked />

                  </Col>

                </Form.Group>}

                {Boolean(item.type) && item.type === 'checkbox' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>


                  {item.options.map((opt, j) => (
                    <>
                      {
                        this.state.checkEvaluation &&
                        <Col style={{ marginBottom: "1rem" }} xs="1">  <InputNumber min={1} value={opt.point} onChange={(value) => {

                          let _options = this.state.questions.find(p => p.id === item.id).options;
                          _options.find(p => p.id === opt.id).point = value;

                          this.setState(prevState => ({
                            question: prevState.questions.map(
                              el => el.id === opt.id ? { ...el, options: _options } : el
                            )
                          }))
                        }} /></Col>

                      }
                      <Col style={{ marginBottom: "1rem" }} xs="11">   <AntCheckbox> {opt.option.optionContent}</AntCheckbox>
                      </Col>   <br /> </>

                  ))}


                </Form.Group>}

                {!this.state.checkEvaluation && Boolean(item.type) && item.type === 'dropdown' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>

                  <Col xs="4">
                    <Form.Control name="type"
                      as="select"  >
                      <option value="Select">Select Option</option>
                      {item.options.map((opt, j) => (
                        <option value={opt.option.optionContent}>{opt.option.optionContent}</option>

                      ))}
                    </Form.Control>
                  </Col>

                </Form.Group>}

                {this.state.checkEvaluation && Boolean(item.type) && item.type === 'dropdown' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>


                  {item.options.map((opt, j) => (
                    <>
                      <Col style={{ marginBottom: "1rem" }} xs="1">  <InputNumber min={1} value={opt.point} onChange={(value) => {

                        let _options = this.state.questions.find(p => p.id === item.id).options;
                        _options.find(p => p.id === opt.id).point = value;

                        this.setState(prevState => ({
                          question: prevState.questions.map(
                            el => el.id === opt.id ? { ...el, options: _options } : el
                          )
                        }))
                      }} />

                      </Col>
                      <Col style={{ marginBottom: "1rem" }} xs="11">   {opt.option.optionContent}
                      </Col>   <br /> </>

                  ))}

                </Form.Group>}

                {Boolean(item.type) && item.type === 'file' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col xs="4">
                    <Upload>
                      <AntButton>
                        <UploadOutlined /> Click to Upload
                      </AntButton>
                    </Upload>
                  </Col>
                </Form.Group>}

                {Boolean(item.type) && item.type === 'matrix' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col > </Col>
                  {item.columns.map((col, j) => (
                    <Col>
                      {
                        this.state.checkEvaluation &&
                        <InputNumber style={{ marginRight: "1rem" }} min={1} value={col.point} onChange={(value) => {

                          let _columns = this.state.questions.find(p => p.id === item.id).columns;
                          _columns.find(p => p.id === col.id).point = value;

                          this.setState(prevState => ({
                            questions: prevState.questions.map(
                              el => el.id === item.id ? { ...el, columns: _columns } : el
                            )
                          }))
                        }} />

                      }
                      {col.option.optionContent}
                    </Col>

                  ))}

                </Form.Group>}

                {Boolean(item.type) && item.type === 'matrixdropdown' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col > </Col>
                  {item.columns.map((col, j) => (
                    <Col >
                      {
                        this.state.checkEvaluation &&
                        <InputNumber style={{ marginRight: "1rem" }} min={1} value={col.point} onChange={(value) => {

                          let _columns = this.state.questions.find(p => p.id === item.id).columns;
                          _columns.find(p => p.id === col.id).point = value;

                          this.setState(prevState => ({
                            questions: prevState.questions.map(
                              el => el.id === item.id ? { ...el, columns: _columns } : el
                            )
                          }))
                        }} />

                      }
                      {col.option.optionContent}
                    </Col>

                  ))}
                </Form.Group>}
                {Boolean(item.type) && item.type === 'matrix' && item.rows.map((row, k) => (
                  <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <Col >
                      {
                        this.state.checkEvaluation &&
                        <InputNumber style={{ marginRight: "1rem" }} min={1} value={row.point} onChange={(value) => {

                          let _rows = this.state.questions.find(p => p.id === item.id).rows;
                          _rows.find(p => p.id === row.id).point = value;

                          this.setState(prevState => ({
                            questions: prevState.questions.map(
                              el => el.id === item.id ? { ...el, rows: _rows } : el
                            )
                          }))
                        }} />

                      }
                      {row.questionContent} </Col>
                    {item.columns.map((col, j) => (
                      <Col>

                        <Radio style={radioStyle} checked={false} >
                        </Radio>
                      </Col>

                    ))}

                  </Form.Group>

                ))}

                {!this.state.checkEvaluation && Boolean(item.type) && item.type === 'matrixdropdown' && item.rows.map((row, k) => (
                  <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <Col > {row.questionContent} </Col>
                    {item.columns.map((col, j) => (
                      <Col>
                        <Form.Control name="type"
                          as="select"  >
                          <option value="Select">Select Option</option>
                          {item.options.map((opt, j) => (
                            <option value={opt.option.optionContent}>{opt.option.optionContent}</option>

                          ))}
                        </Form.Control>
                      </Col>

                    ))}

                  </Form.Group>

                ))}

                {this.state.checkEvaluation && Boolean(item.type) && item.type === 'matrixdropdown' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                  <Col > </Col>
                  {item.options.map((opt, j) => (
                    <Col >
                      {
                        this.state.checkEvaluation &&
                        <InputNumber min={1} value={opt.point} onChange={(value) => {

                          let _options = this.state.questions.find(p => p.id === item.id).options;
                          _options.find(p => p.id === opt.id).point = value;

                          this.setState(prevState => ({
                            question: prevState.questions.map(
                              el => el.id === opt.id ? { ...el, options: _options } : el
                            )
                          }))
                        }} />

                      }
                      {opt.option.optionContent}
                    </Col>

                  ))}

                </Form.Group>}

                {this.state.checkEvaluation && Boolean(item.type) && item.type === 'matrixdropdown' && item.rows.map((row, k) => (
                  <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                    <Col >
                      {
                        this.state.checkEvaluation &&
                        <InputNumber style={{ marginRight: "1rem" }} min={1} value={row.point} onChange={(value) => {

                          let _rows = this.state.questions.find(p => p.id === item.id).rows;
                          _rows.find(p => p.id === row.id).point = value;

                          this.setState(prevState => ({
                            questions: prevState.questions.map(
                              el => el.id === item.id ? { ...el, rows: _rows } : el
                            )
                          }))
                        }} />

                      }
                      {row.questionContent} </Col>
                    {item.columns.map((col, j) => (
                      <Col>

                        <Form.Control name="type"
                          as="select"  >
                          <option value="Select">Select Option</option>
                          {item.options.map((opt, j) => (
                            <option value={opt.option.optionContent}>{opt.option.optionContent}</option>

                          ))}
                        </Form.Control>
                      </Col>

                    ))}

                  </Form.Group>

                ))}

              </div>
              <br />
            </>
          ))}

          {this.state.checkEvaluation && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
            <Col sm="12">
              <Button
                id="btn-addnew"
                style={{ width: "100%" }}
                className="p-button-success"
                onClick={this.saveEvaluation}
                label="Save Evaluation"
              />


            </Col>
          </Form.Group>}

        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    i18n: state.i18n,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaryExpand);
