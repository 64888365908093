/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Col, Modal } from 'react-bootstrap';
import { message } from 'antd';
import { handleRequest } from '../../redux/config/env';

var isDirty = function () { return false; }

export class MobileDashboard extends Component {

    constructor() {
        super();
        this.state = {
            location: "N/A now...",
            modalShow: false,
            ended: true,
            buttonVariantStart: "success",
            buttonVariantLoading: "secondary",
            buttonVariantCheckPoint: "secondary",
            buttonVariantFuelRefill: "secondary",
            buttonVariantBreakdown: "secondary",
            buttonVariantEnd: "secondary",
            frequencyGetLocation: null
        };

    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/TrailerLocationFrequency/getCompanyItems");

        if (response.data.length === 0) {
            this.setState({
                frequencyGetLocation: 5
            });
        }

        if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {
                let flagFreqValue = parseFloat(response.data[index].minValue);

                console.log(flagFreqValue);

                this.setState({
                    frequencyGetLocation: flagFreqValue
                });
            }
        }

        const urlWithPersonelId = "/Staff/" + this.props.auth.user.staffId;

        const response2 = await handleRequest("GET", urlWithPersonelId);

        var personelFlag;
        let flagWorkingStatus = "";

        if (response2.data.length !== 0) {
            personelFlag = response2.data;
        }

        if (response2.data.length !== 0) {
            if (personelFlag.workingStatus !== undefined && personelFlag.workingStatus !== null)
                flagWorkingStatus = personelFlag.workingStatus;
            else
                flagWorkingStatus = "";
        }

        if (flagWorkingStatus === "Started") {

            this.interval = window.setInterval(this.testTimer, 60000); //60000 * this.state.frequencyGetLocation

            this.setState({
                ended: false,
                buttonVariantStart: "secondary",
                buttonVariantLoading: "primary",
                buttonVariantCheckPoint: "primary",
                buttonVariantFuelRefill: "warning",
                buttonVariantBreakdown: "warning",
                buttonVariantEnd: "danger"
            });
        } else if (flagWorkingStatus === "" || flagWorkingStatus === "Waiting For Start" || flagWorkingStatus === "Ended") {
            this.setState({
                ended: true,
                buttonVariantStart: "success",
                buttonVariantLoading: "secondary",
                buttonVariantCheckPoint: "secondary",
                buttonVariantFuelRefill: "secondary",
                buttonVariantBreakdown: "secondary",
                buttonVariantEnd: "secondary"
            });
        }
    }

    testTimer = async () => {
        let latitude = "";
        let longitude = "";
        let flagLocation = "";

        this.setState({
            ended: false,
            buttonVariantStart: "secondary",
            buttonVariantLoading: "primary",
            buttonVariantCheckPoint: "primary",
            buttonVariantFuelRefill: "warning",
            buttonVariantBreakdown: "warning",
            buttonVariantEnd: "danger"
        });

        if (navigator.geolocation) {
            const successCallback = async (position) => {
                flagLocation = position.coords.latitude.toString() + "/" + position.coords.longitude.toString();
                latitude = position.coords.latitude.toString();
                longitude = position.coords.longitude.toString();
                this.setState({
                    location: flagLocation,
                    modalShow: true  //mobilde test edildikten sonra kaldırılacak.
                });
                console.log(position);

                var date = new Date();
                console.log(date);

                if (flagLocation != "") {

                    const newLocationData = {
                        longitude: longitude,
                        latitude: latitude,
                        deviceId: this.props.auth.user.staffId
                    }

                    await handleRequest("POST", "/TrailerLocation/add", newLocationData);
                    this.success();
                } else {
                    this.error();
                }
            };

            const errorCallback = (error) => {
                latitude = "";
                longitude = "";
                flagLocation = "";
                this.setState({
                    location: "Can't detect!",
                    modalShow: true
                });
                console.error(error);
            };

            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        }
    }

    success = () => {
        message.success("Location information was received.");
    }

    error = () => {
        message.error('Location information could not be obtained.');
    }

    onStart = async () => {
        const updatedPersonel = {
            id: this.props.auth.user.staffId,
            workingStatus: "Started"
        }

        await handleRequest("POST", "/Staff/updateWorkingStatus", updatedPersonel);

        this.testTimer();

        this.interval = window.setInterval(this.testTimer, 60000); //60000 * this.state.frequencyGetLocation
    }

    onLoading = () => {
        window.clearInterval(this.interval);
        window.location.href = '/LoadingMobile';
    }

    onCheckPoint = () => {
        window.clearInterval(this.interval);
        window.location.href = '/CheckPointMobile';
    }

    onFuelRefill = () => {
        window.clearInterval(this.interval);
        window.location.href = '/FuelRefillMobile';
    }

    onBreakdown = () => {
        window.clearInterval(this.interval);
        window.location.href = '/BreakdownMobile1';
    }

    onEndOfWork = async () => {

        const updatedPersonel = {
            id: this.props.auth.user.staffId,
            workingStatus: "Ended"
        }

        await handleRequest("POST", "/Staff/updateWorkingStatus", updatedPersonel);

        window.clearInterval(this.interval);

        this.setState({
            ended: true,
            buttonVariantStart: "success",
            buttonVariantLoading: "secondary",
            buttonVariantCheckPoint: "secondary",
            buttonVariantFuelRefill: "secondary",
            buttonVariantBreakdown: "secondary",
            buttonVariantEnd: "secondary"
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    render() {

        return (
            <div>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Location Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.state.location}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHideModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <div hidden={!this.state.ended}>
                    <Form.Group style={{ width: "100%" }}>
                        <Col sm="12">
                            <Button variant={this.state.buttonVariantStart} onClick={this.onStart} disabled={!this.state.ended} style={{ height: "200px", width: "100%" }}>Start</Button>
                        </Col>
                    </Form.Group>
                </div>

                <div hidden={this.state.ended}>
                    <Form.Group style={{ width: "100%" }}>
                        <Col sm="12">
                            <Button variant={this.state.buttonVariantLoading} onClick={this.onLoading} disabled={this.state.ended} style={{ height: "60px", width: "100%" }}>Loading</Button>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{ width: "100%" }}>
                        <Col sm="12">
                            <Button variant={this.state.buttonVariantCheckPoint} onClick={this.onCheckPoint} disabled={this.state.ended} style={{ height: "60px", width: "100%" }}>Check Point</Button>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{ width: "100%" }}>
                        <Col sm="12">
                            <Button variant={this.state.buttonVariantFuelRefill} onClick={this.onFuelRefill} disabled={this.state.ended} style={{ height: "60px", width: "100%" }}>Fuel Refill</Button>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{ width: "100%" }}>
                        <Col sm="12">
                            <Button variant={this.state.buttonVariantBreakdown} onClick={this.onBreakdown} disabled={this.state.ended} style={{ height: "60px", width: "100%" }}>Breakdown</Button>
                        </Col>
                    </Form.Group>
                    <Form.Group style={{ width: "100%" }}>
                        <Col sm="12">
                            <Button variant={this.state.buttonVariantEnd} onClick={this.onEndOfWork} disabled={this.state.ended} style={{ height: "60px", width: "100%" }}>End Of Work</Button>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileDashboard)