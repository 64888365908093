/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Card } from "primereact/card";
import { MultiSelect } from 'primereact/multiselect';
import { Cascader, Select, InputNumber, Table, message } from 'antd';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import $ from 'jquery';

const { Option } = Select;
const { Column } = Table;

export class RentedEquipmentAverages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            values: [],
            nodes: [],
            equipmentLogs: [],
            equipments: [],
            equipmentOptions: [],
            selectedEquipments: [],
            fuel: "",
            fuelSelect: "Km",
            maintenance: "",
            maintenanceSelect: "Km",
            repairs: "",
            repairsSelect: "Km",
            insurance: "",
            insuranceSelect: "Km",
            taxes: "",
            taxesSelect: "Km",
            fees: "",
            feesSelect: "Km",
            selectedRow: null,
            rentedEquipmentAveragesList: [],
            rentedEquipmentAveragesId: 0,
            editedRentedEquipmentAverageId: 0,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        };
    }

    componentDidMount = async () => {
        const responseEquipmentType = await handleRequest("GET", "/EquipmentType/getNodes");

        if (responseEquipmentType.length === 0 || responseEquipmentType.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (responseEquipmentType.data.length !== 0) {
            this.setState({
                nodes: responseEquipmentType.data
            });
        }

        const responseEquipmentLog = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipmentLog.data.length === 0) {
            this.setState({
                equipmentLogs: []
            });
        }

        if (responseEquipmentLog.data.length !== 0) {
            this.setState({
                equipmentLogs: responseEquipmentLog.data,
            });
        }

        const responseRentedEquipmentAverages = await handleRequest("GET", "/RentedEquipmentAverages/getAll");

        if (responseRentedEquipmentAverages.data.length === 0) {
            this.setState({
                rentedEquipmentAveragesList: []
            });
        }

        if (responseRentedEquipmentAverages.data.length !== 0) {
            this.setState({
                rentedEquipmentAveragesList: responseRentedEquipmentAverages.data,
            });
        }
    }

    onChange = (value, data) => {
        let equipmentTypeIdFlag = value[value.length - 1];

        if (this.state.equipmentLogs.length === 0) {
            this.setState({
                equipments: [],
                equipmentOptions: []
            });
        } else if (this.state.equipmentLogs.length > 0) {

            let equipmentLogs = this.state.equipmentLogs;
            let options = [];

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options.push({ label: equipmentLogs[index].equipmentDescription.toString(), value: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipments: equipmentLogs,
                equipmentOptions: options
            });
        }

        this.setState({
            values: value,
            selectedItems: data
        })
    }

    onEquipmentChange = async (e) => {

        const _selectedEquipments = e.value;

        this.setState({
            selectedEquipments: _selectedEquipments
        });
    }

    deleteModal = (row) => {
        this.setState({
            rentedEquipmentAveragesId: row.id,
            modalShow: true
        });
    }

    delete = async () => {
        const deletedRentedEquipmentAverages = {
            id: this.state.rentedEquipmentAveragesId
        }

        await handleRequest("POST", "/RentedEquipmentAverages/delete", deletedRentedEquipmentAverages);

        this.restartTable();
        message.success(`The operation is successful.`);
        this.showOrHideModal();
    }

    showOrHideModal = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    edit = async (rowData) => {

        var flagValues = [];
        var flagEquipmentTypeId = null;

        if (rowData.equipmentType !== null) {

            flagEquipmentTypeId = rowData.equipmentType.id;

            flagValues = getParents(this.state.nodes, rowData.equipmentType.id).parents;

            flagValues.push(rowData.equipmentType.id);

            function getParentsHelper(tree, id, parents) {

                if (tree.data.id == id) {
                    return {
                        found: true,
                        parents: parents
                    };
                }
                var result = {
                    found: false,
                }
                if (tree.children) {
                    $.each(tree.children, function (index, subtree) {
                        var maybeParents = $.merge([], parents);
                        if (tree.data.id != undefined) {
                            maybeParents.push(tree.data.id);
                        }
                        var maybeResult = getParentsHelper(subtree, id, maybeParents);
                        if (maybeResult.found) {
                            result = maybeResult;
                            return false;
                        }
                    });
                }
                return result;
            }

            function getParents(data1, id) {
                var tree = {
                    data: {},
                    children: data1
                }
                return getParentsHelper(tree, id, []);
            }
        }

        let equipmentTypeIdFlag = flagEquipmentTypeId;
        let equipmentLogs = this.state.equipmentLogs;

        if (equipmentLogs.length === 0 || flagEquipmentTypeId === null) {
            this.setState({
                equipmentOptions: []
            });
        } else if (equipmentLogs.length !== 0) {
            let options = [];
            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options.push({ label: equipmentLogs[index].equipmentDescription.toString(), value: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipmentOptions: options
            });
        }

        let selectedEquipments = [];

        for (let index = 0; index < rowData.rentedEquipmentAveragesEquipmentList.length; index++) {
            selectedEquipments.push(rowData.rentedEquipmentAveragesEquipmentList[index].equipmentLogId)
        }

        this.setState({
            selectedRow: rowData,
            values: flagValues,
            selectedEquipments: selectedEquipments,
            fuel: rowData.fuel,
            fuelSelect: rowData.fuelSelect,
            maintenance: rowData.maintenance,
            maintenanceSelect: rowData.maintenanceSelect,
            repairs: rowData.repairs,
            insurance: rowData.insurance,
            insuranceSelect: rowData.insuranceSelect,
            taxes: rowData.taxes,
            taxesSelect: rowData.taxesSelect,
            fees: rowData.fees,
            feesSelect: rowData.feesSelect,
            editedRentedEquipmentAverageId: rowData.id,
            lastButtonName: "Update",
            lastButtonVariant: "warning"
        });
    }

    saveAll = async () => {
        if (this.state.editedRentedEquipmentAverageId === 0) {
            const newRentedEquipmentAverage = {
                equipments: this.state.selectedEquipments,
                equipmentTypeId: this.state.values[this.state.values.length - 1],
                fuel: this.state.fuel,
                fuelSelect: this.state.fuelSelect,
                maintenance: this.state.maintenance,
                maintenanceSelect: this.state.maintenanceSelect,
                repairs: this.state.repairs,
                repairsSelect: this.state.repairsSelect,
                insurance: this.state.insurance,
                insuranceSelect: this.state.insuranceSelect,
                taxes: this.state.taxes,
                taxesSelect: this.state.taxesSelect,
                fees: this.state.fees,
                feesSelect: this.state.feesSelect
            }

            await handleRequest("POST", "/RentedEquipmentAverages/add", newRentedEquipmentAverage);

            message.success(`The operation is successful.`);
            this.restartTable();
            this.refreshValues();
        }
        else {
            const updatedRentedEquipmentAverage = {
                id: this.state.editedRentedEquipmentAverageId,
                equipments: this.state.selectedEquipments,
                equipmentTypeId: this.state.values[this.state.values.length - 1],
                fuel: this.state.fuel,
                fuelSelect: this.state.fuelSelect,
                maintenance: this.state.maintenance,
                maintenanceSelect: this.state.maintenanceSelect,
                repairs: this.state.repairs,
                repairsSelect: this.state.repairsSelect,
                insurance: this.state.insurance,
                insuranceSelect: this.state.insuranceSelect,
                taxes: this.state.taxes,
                taxesSelect: this.state.taxesSelect,
                fees: this.state.fees,
                feesSelect: this.state.feesSelect
            }

            await handleRequest("POST", "/RentedEquipmentAverages/update", updatedRentedEquipmentAverage);

            message.success(`The operation is successful.`);
            this.restartTable();
            this.refreshValues();
        }
    }

    restartTable = async () => {
        const responseRentedEquipmentAverages = await handleRequest("GET", "/RentedEquipmentAverages/getAll");

        if (responseRentedEquipmentAverages.data.length === 0) {
            this.setState({
                rentedEquipmentAveragesList: []
            });
        }

        if (responseRentedEquipmentAverages.data.length !== 0) {
            this.setState({
                rentedEquipmentAveragesList: responseRentedEquipmentAverages.data,
            });
        }
    }

    refreshValues = () => {
        this.setState({
            values: [],
            selectedItems: null,
            selectedEquipments: [],
            fuel: "",
            fuelSelect: "Km",
            maintenance: "",
            maintenanceSelect: "Km",
            repairs: "",
            repairsSelect: "Km",
            insurance: "",
            insuranceSelect: "Km",
            taxes: "",
            taxesSelect: "Km",
            fees: "",
            feesSelect: "Km",
            editedRentedEquipmentAverageId: 0,
            selectedRow: null,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        });
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Rented Equipment Average Data?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>All informations about rented equipment average data will be deleted. Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Equipment Type:</Form.Label>
                        </Col>
                        <Col xs={3}>
                            <Cascader defaultValue={this.state.values} value={this.state.values} size="large" options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Equipment(s):</Form.Label>
                        </Col>
                        <Col xs={3}>
                            <MultiSelect value={this.state.selectedEquipments} options={this.state.equipmentOptions} onChange={this.onEquipmentChange}
                                style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Fuel:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.fuel}
                                onChange={(value) => {
                                    this.setState({
                                        fuel: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.fuelSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        fuelSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Maintenance:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.maintenance}
                                onChange={(value) => {
                                    this.setState({
                                        maintenance: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.maintenanceSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        maintenanceSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Repairs:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.repairs}
                                onChange={(value) => {
                                    this.setState({
                                        repairs: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.repairsSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        repairsSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Insurance:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.insurance}
                                onChange={(value) => {
                                    this.setState({
                                        insurance: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.insuranceSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        insuranceSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Taxes:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.taxes}
                                onChange={(value) => {
                                    this.setState({
                                        taxes: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.taxesSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        taxesSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={2}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Fees:</Form.Label>
                        </Col>
                        <Col xs={2}>
                            <InputNumber style={{ width: '100%' }} min={0} value={this.state.fees}
                                onChange={(value) => {
                                    this.setState({
                                        fees: value
                                    });
                                }} />
                        </Col>
                        <Col xs={1}>
                            <Form.Label style={{ color: 'black', marginTop: '0.5rem', marginLeft: '10px' }}>per</Form.Label>
                        </Col>
                        <Col xs={1}>
                            <Select defaultValue="Km" value={this.state.feesSelect} style={{ width: '100%' }}
                                onChange={(value) => {
                                    this.setState({
                                        feesSelect: value
                                    });
                                }}>
                                <Option value="Km">Km</Option>
                                <Option value="Trip">Trip</Option>
                                <Option value="Day">Day</Option>
                                <Option value="Week">Week</Option>
                                <Option value="Month">Month</Option>
                                <Option value="Year">Year</Option>
                                <Option value="m3">m3</Option>
                                <Option value="Ton">Ton</Option>
                            </Select>
                        </Col>
                        <Col xs={3}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", width: "100%" }}>
                        <Col xs={4}>
                        </Col>
                        <Col xs={4}>
                            <Button style={{ width: '100%' }} variant={this.state.lastButtonVariant} onClick={this.saveAll} >{this.state.lastButtonName}</Button>
                        </Col>
                        <Col xs={4}>
                        </Col>
                    </Row>
                </Card>
                <hr />
                <Card>
                    <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.rentedEquipmentAveragesList} bordered>
                        <Column
                            width='50px'
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <div style={{ textAlign: "center" }}>
                                    <React.Fragment key={record.id} > <CustomMaterialMenu row={record}
                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
                                    </React.Fragment>
                                </div>
                            )}
                        />
                        <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                        <Column title="Equipment Type" key="id" render={(value, row, index) => {
                            if (row.equipmentType != null && row.equipmentType !== undefined) {
                                return row.equipmentType.equipmentTypeName;
                            } else
                                return '';
                        }} />
                        <Column title="Equipment(s)" key="cfn" render={(value, row, index) => {
                            if (row.rentedEquipmentAveragesEquipmentList != null && row.rentedEquipmentAveragesEquipmentList !== undefined
                                && row.rentedEquipmentAveragesEquipmentList.length > 0) {

                                let equipmentNames = "";

                                for (let index = 0; index < row.rentedEquipmentAveragesEquipmentList.length; index++) {
                                    if (index !== (row.rentedEquipmentAveragesEquipmentList.length - 1))
                                        equipmentNames += (row.rentedEquipmentAveragesEquipmentList[index].equipmentLog.equipmentDescription + ", ");
                                    else
                                        equipmentNames += row.rentedEquipmentAveragesEquipmentList[index].equipmentLog.equipmentDescription;
                                }
                                return equipmentNames;
                            } else
                                return '';
                        }} />
                        <Column title="Fuel" key="fuel" render={(row) => {
                            return row.fuel + " SR per " + row.fuelSelect;
                        }} />
                        <Column title="Maintenance" key="maintenance" render={(row) => {
                            return row.maintenance + " SR per " + row.maintenanceSelect;
                        }} />
                        <Column title="Repairs" key="repairs" render={(row) => {
                            return row.repairs + " SR per " + row.repairsSelect;
                        }} />
                        <Column title="Insurance" key="insurance" render={(row) => {
                            return row.insurance + " SR per " + row.insuranceSelect;
                        }} />
                        <Column title="Taxes" key="taxes" render={(row) => {
                            return row.taxes + " SR per " + row.taxesSelect;
                        }} />
                        <Column title="Fees" key="fees" render={(row) => {
                            return row.fees + " SR per " + row.feesSelect;
                        }} />
                    </Table>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RentedEquipmentAverages)