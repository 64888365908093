

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Card } from "primereact/card";
import { SplitButton } from 'primereact/splitbutton';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Col, Modal, Form, Row } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { ContextMenu } from 'primereact/contextmenu';
import $ from 'jquery';
import { message } from "antd";

export class DocumentTypes extends Component {

    constructor() {
        super();

        this.state = {
            nodes: [],
            expandedKeys: {},
            addModal: false,
            addModal2: false,
            deleteModal: false,
            editModal: false,
            documentTypeId: null,
            documentTypeParentId: null,
            modalDocumentChildTypeName: "",
            modalDocumentParentTypeName: "",
            modalEditDocumentTypeName: "",
            rootDocumentTypeName: "",
            page: "",
            modalEditDocumentpage: "",
            modalDocumentParentpage: "",
            modalDocumentChildpage: "",
            selectedNodeKey: null,
            globalFilter: null,
            splitButtonMenu: [
                {
                    label: 'Add Child',
                    icon: 'pi pi-fw pi-plus',
                    command: () => {
                        this.addChildDocumentType();
                    }
                },
                {
                    label: 'Add Parent',
                    icon: 'pi pi-fw pi-plus',
                    command: () => {
                        this.addParentDocumentType();
                    }
                },
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editDocumentType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteDocumentType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleDocumentType();
                    }
                }
            ],
            leftX: 0,
            topY: 0,
            splitButtonMenu2: [
                {
                    label: 'Add',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'Child',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addChildDocumentType();
                            }
                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addParentDocumentType();
                            }
                        }
                    ]
                },
                {
                    separator: true
                },
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editDocumentType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteDocumentType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleDocumentType();
                    }
                }
            ]
        };

        this.actionTemplate = this.actionTemplate.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.pageTemplate = this.pageTemplate.bind(this);
    }

    componentDidMount = async () => {
        this.restartTable();
        document.addEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        event.preventDefault();
        setTimeout(function () {
            $(".p-contextmenu").css("left", event.clientX - 264 + "px");
            $(".p-contextmenu").css("top", event.clientY - 141 + "px");
        }, 50);
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/DocumentType/getNodes");

        if (response.data.length == 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length != 0) {
            this.setState({
                nodes: response.data
            });
        }
    }

    actionTemplate(rowData, column) {
        return <SplitButton type="button" icon="pi pi-cog" className="p-button-secondary" model={this.state.splitButtonMenu} ></SplitButton>;
    }

    toggleDocumentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        let expandedKeys = { ...this.state.expandedKeys };

        if (expandedKeys[arr[0]])
            delete expandedKeys[arr[0]];
        else
            expandedKeys[arr[0]] = true;

        this.setState({ expandedKeys: expandedKeys });
    }

    addChildDocumentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal: true,
            documentTypeParentId: arr[0]
        });
    }

    addChildDocumentTypeOK = async () => {
        var item = {
            parentId: this.state.documentTypeParentId,
            documentType: this.state.modalDocumentChildTypeName,
            page: this.state.modalDocumentChildpage,
        }

        if (this.state.modalDocumentChildTypeName != null && this.state.modalDocumentChildTypeName != "") {
            var response = await handleRequest("POST", "/DocumentType/add", item);

            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.setState({
                        addModal: false
                    });

                    this.restartControls();
                    this.restartTable();
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            }
            else {
                this.showError("An error was occured please try again later !");
            }
        } else {
            this.showError();
        }
    }

    addParentDocumentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal2: true,
            documentTypeParentId: arr[0]  //seçili olanın id'si bu.
        });
    }

    addParentDocumentTypeOK = async () => {

        var response = await handleRequest("GET", "/DocumentType/" + this.state.documentTypeParentId);
        var item;

        if (response !== undefined || response !== null)
            item = response.data;

        var itemNew = {
            parentWillChangeId: this.state.documentTypeParentId,
            documentType: this.state.modalDocumentParentTypeName,
            parentId: item.parentId,
            page: this.state.modalDocumentParentpage
        }

        if (this.state.modalDocumentParentTypeName != null && this.state.modalDocumentParentTypeName != "") {

            var responseAdd = await handleRequest("POST", "/DocumentType/addParent", itemNew);

            if (responseAdd.data !== null && responseAdd.data !== undefined) {
                if (responseAdd.data == "SUCCESS") {
                    this.setState({
                        addModal2: false
                    });

                    this.restartControls();
                    this.restartTable();
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            }
            else {
                this.showError("An error was occured please try again later !");
            }
        }
        else {
            this.showError();
        }
    }

    editDocumentType = async () => {
        var arr = this.state.selectedNodeKey.split('.');

        var response = await handleRequest("GET", "/DocumentType/" + arr[0]);

        this.setState({
            editModal: true,
            documentTypeId: response.data.id,
            documentTypeParentId: response.data.parentId,
            modalEditDocumentTypeName: response.data.documentType,
            modalEditDocumentpage: response.data.page
        });
    }

    editDocumentTypeOK = async () => {
        var item = {
            parentId: this.state.documentTypeParentId,
            id: this.state.documentTypeId,
            documentType: this.state.modalEditDocumentTypeName,
            page: this.state.modalEditDocumentpage
        }

        if (this.state.modalEditDocumentTypeName != null && this.state.modalEditDocumentTypeName != "") {
            var response = await handleRequest("POST", "/DocumentType/update", item);

            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.setState({
                        editModal: false
                    });

                    this.restartTable();
                    this.restartControls();
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            }
            else {
                this.showError("An error was occured please try again later !");
            }
        }
        else {
            this.showError();
        }
    }

    deleteDocumentType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            deleteModal: true,
            documentTypeId: arr[0]
        });
    }

    deleteDocumentTypeOK = async () => {
        const deletedItem = {
            id: this.state.documentTypeId
        }

        if (this.state.documentTypeId > 0) {
            var response = await handleRequest("POST", "/DocumentType/delete", deletedItem);

            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.showSuccess();
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            } else {
                this.showError("An error was occured please try again later !");
            }
        } else {
            this.showError();
        }
        this.setState({
            deleteModal: false
        });
        this.restartControls();
        this.restartTable();
    }

    showOrHideModalDeleteDocumentType = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    showOrHideModalAddChildDocumentType = () => {
        this.setState({
            addModal: !this.state.addModal
        });
    }

    showOrHideModalAddParentDocumentType = () => {
        this.setState({
            addModal2: !this.state.addModal2
        });
    }

    showOrHideModalEditDocumentType = () => {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    saveDocumentType = async () => {
        if (this.state.documentTypeId == null && this.state.rootDocumentTypeName != "") {
            var item = {
                documentType: this.state.rootDocumentTypeName,
                parentId: 0,
                page: this.state.page
            }

            var response = await handleRequest("POST", "/DocumentType/add", item);

            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.showSuccess();
                    this.restartTable();
                    this.restartControls();
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            }
        } else {
            this.showError();
        }
    }

    showSuccess() {
        message.success("Successful")
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        message.error(msg)
    };

    restartControls = () => {
        this.setState({
            documentTypeId: null,
            documentTypeParentId: null,
            rootDocumentTypeName: "",
            modalDocumentChildTypeName: "",
            modalDocumentParentTypeName: "",
            modalEditDocumentTypeName: "",
            page: "",
            modalEditDocumentpage: "",
            modalDocumentParentpage: "",
            modalDocumentChildpage: "",
        });
    }

    handleSplitMenuChange = e => {
        this.setState({ selectedNodeKey: e.value });
    }

    handleChangePage = e => {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleChangemodalEditDocumentPage = e => {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleChangemodalParentDocumentPage = e => {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleChangemodalDocumentChildpage = e => {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    pageTemplate(rowData) {
        const row = rowData.data;
        if (Boolean(row.page)) {
            if (row.page == "EditDocument") {
                return "Edit Document";
            } else if (row.page == "MaintenancePlan") {
                return "Maintenance Plan";
            } if (row.page == "MaintenanceChecklist") {
                return "Maintenance Checklist";
            } if (row.page == "RoleDocumentItem") {
                return "Role Description";
            }
            return row.page;
        }
        return "";
    }

    render() {
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        let header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
        </div>;

        return (
            <div>
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteDocumentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Document Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Document Type?
                    </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related types.
                    </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteDocumentType}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteDocumentTypeOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalAddChildDocumentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Child Document Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Child Document Type:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <InputText onChange={(e) => this.setState({ modalDocumentChildTypeName: e.target.value })}
                                        value={this.state.modalDocumentChildTypeName} placeholder="Document Type" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Format:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Control name="modalDocumentChildpage" value={this.state.modalDocumentChildpage}
                                        onChange={this.handleChangemodalDocumentChildpage} as="select" style={{ width: '100%' }} >

                                        <option value=""> Select Format </option>
                                        <option value="Itp"> Itp </option>
                                        <option value="Checklist"> Checklist </option>
                                        <option value="EditDocument"> Edit Document </option>
                                        <option value="Wps"> Wps </option>
                                        <option value="Drawing"> Drawing </option>
                                        <option value="Nmr"> Nmr </option>
                                        <option value="MaintenancePlan"> Maintenance Plan </option>
                                        <option value="MaintenanceChecklist"> Maintenance Checklist </option>
                                        <option value="RoleDocumentItem"> Role Description </option>
                                        <option value="SA"> SA </option>
                                        <option value="SAEP"> SAEP </option>
                                        <option value="SAES"> SAES </option>
                                        <option value="SAMSS"> SAMSS </option>
                                        <option value="SA-175"> SA-175 </option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddChildDocumentType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addChildDocumentTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal2}
                    onHide={this.showOrHideModalAddParentDocumentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Parent Document Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Parent Document Type:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalDocumentParentTypeName: e.target.value })}
                                        value={this.state.modalDocumentParentTypeName} placeholder="Document Type" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Format:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Control name="modalDocumentParentpage" value={this.state.modalDocumentParentpage}
                                        onChange={this.handleChangemodalParentDocumentPage} as="select" style={{ width: '100%' }} >

                                        <option value=""> Select Format </option>
                                        <option value="Itp"> Itp </option>
                                        <option value="Checklist"> Checklist </option>
                                        <option value="EditDocument"> Edit Document </option>
                                        <option value="Wps"> Wps </option>
                                        <option value="Drawing"> Drawing </option>
                                        <option value="Nmr"> Nmr </option>                                    
                                        <option value="MaintenancePlan"> Maintenance Plan </option>
                                        <option value="MaintenanceChecklist"> Maintenance Checklist </option>
                                        <option value="RoleDocumentItem"> Role Description </option>
                                        <option value="SA"> SA </option>
                                        <option value="SAEP"> SAEP </option>
                                        <option value="SAES"> SAES </option>
                                        <option value="SAMSS"> SAMSS </option>
                                        <option value="SA-175"> SA-175 </option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddParentDocumentType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addParentDocumentTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.editModal}
                    onHide={this.showOrHideModalEditDocumentType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Edit Document Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Document Type:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalEditDocumentTypeName: e.target.value })}
                                        value={this.state.modalEditDocumentTypeName} placeholder="Document Type" style={{ width: '100%' }} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Format:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Control name="modalEditDocumentpage" value={this.state.modalEditDocumentpage}
                                        onChange={this.handleChangemodalEditDocumentPage} as="select" style={{ width: '100%' }} >

                                        <option value=""> Select Format </option>
                                        <option value="Itp"> Itp </option>
                                        <option value="Checklist"> Checklist </option>
                                        <option value="EditDocument"> Edit Document </option>
                                        <option value="Wps"> Wps </option>
                                        <option value="Drawing"> Drawing </option>
                                        <option value="Nmr"> Nmr </option>
                                        <option value="MaintenancePlan"> Maintenance Plan </option>
                                        <option value="MaintenanceChecklist"> Maintenance Checklist </option>
                                        <option value="RoleDocumentItem"> Role Description </option>
                                        <option value="SA"> SA </option>
                                        <option value="SAEP"> SAEP </option>
                                        <option value="SAES"> SAES </option>
                                        <option value="SAMSS"> SAMSS </option>
                                        <option value="SA-175"> SA-175 </option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalEditDocumentType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.editDocumentTypeOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Card style={{ backgroundColor: "white", width: '700px' }}>
                    <Form style={{ padding: '5px 0px 5px 5px' }}>
                        <Form>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGriddocumenttype">
                                <Form.Label column sm="3" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Document Type:
                                </Form.Label>

                                <Col sm="9">
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ rootDocumentTypeName: e.target.value })} value={this.state.rootDocumentTypeName} placeholder="Document Type" />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGriddocumenttype">
                                <Form.Label column sm="3" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Format:
                                </Form.Label>

                                <Col sm="9">
                                    <Form.Control name="page" value={this.state.page}
                                        onChange={this.handleChangePage} as="select" style={{ width: '100%' }} >

                                        <option value=""> Select Format </option>
                                        <option value="Itp"> Itp </option>
                                        <option value="Checklist"> Checklist </option>
                                        <option value="EditDocument"> Edit Document </option>
                                        <option value="Wps"> Wps </option>
                                        <option value="Drawing"> Drawing </option>
                                        <option value="Nmr"> Nmr </option>
                                        <option value="MaintenancePlan"> Maintenance Plan </option>
                                        <option value="MaintenanceChecklist"> Maintenance Checklist </option>
                                        <option value="RoleDocumentItem"> Role Description </option>
                                        <option value="SA"> SA </option>
                                        <option value="SAEP"> SAEP </option>
                                        <option value="SAES"> SAES </option>
                                        <option value="SAMSS"> SAMSS </option>
                                        <option value="SA-175"> SA-175 </option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGriddocumenttype">
                                <ReactButton type="button" style={{ width: '100%' }} onClick={this.saveDocumentType} variant="info">Add</ReactButton>
                            </Form.Group>
                        </Form>
                    </Form>

                    <div style={{ left: `${this.state.leftX}`, top: `${this.state.topY}` }} className="content-section implementation">
                        <ContextMenu model={this.state.splitButtonMenu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />

                        <TreeTable value={this.state.nodes} expandedKeys={this.state.expandedKeys} emptyMessage="No Document Types Found"
                            selectionMode="single" selectionKeys={this.state.selectedNodeKey} onSelectionChange={this.handleSplitMenuChange}
                            onToggle={e => this.setState({ expandedKeys: e.value })} globalFilter={this.state.globalFilter} header={header}
                            contextMenuSelection={this.state.selectedNodeKey} onContextMenuSelectionChange={e => this.setState({ selectedNodeKey: e.value })}
                            onContextMenu={e => this.cm.show(e.originalEvent)}>
                            <Column field="documentType" header="Document Type" bodyStyle={{ fontWeight: 'bold' }} expander />
                            <Column body={this.pageTemplate} header="Format"></Column>

                        </TreeTable>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypes)