/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import { Col, Form, Row, Modal as ModalReact, Button } from 'react-bootstrap';
import { Button as PrimeButton } from "primereact/button";

const expandable = { expandedRowRender: record => <b>{record.disciplineName}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class WeldMap extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isometricData: [],
            selectedIsometric: "",
            checkedIsometric: [],
            WeldList: [],
            kontrol: false,
            revno: "",
            spool: "",
            spoolSbt: ""
        }
    }

    componentDidMount = async () => {
        this.getWeldList();
        await this.getTableProperties();
    }

    getWeldList = async () => {
        const newObj = {
            id: 0,
        }
        const response = await handleRequest("POST", "/WeldList/getWeldListPaging", newObj);
        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                WeldList: []
            });
        } else if (response.data.length !== 0) {
            this.setState({
                WeldList: response.data.data
            });
        }
    }

    getTableProperties = async () => {
        //MNDTableSettings.getTableProperties("weldList");

        this.weldMap = JSON.parse(localStorage.getItem('weldMap'));
        console.log("GETTABLE", this.weldMap);
        if (this.weldMap != null && this.weldMap != undefined && this.weldMap != "") {

            this.setState({
                bordered: this.weldMap.bordered,
                title: this.weldMap.titleDrm == true ? title : undefined,
                titleDrm: this.weldMap.titleDrm,
                showHeader: this.weldMap.showHeader,
                footer: this.weldMap.footerDrm == true ? footer : undefined,
                footerDrm: this.weldMap.footerDrm,
                expandable: this.weldMap.expandableDrm == true ? expandable : {},
                expandableDrm: this.weldMap.expandableDrm,
                rowSelection: this.weldMap.rowSelection,
                ellipsis: this.weldMap.ellipsis,
                tableLayout: this.weldMap.tableLayout,
                size: this.weldMap.size,
                top: this.weldMap.top,
                bottom: this.weldMap.bottom,
                editBgColor: this.weldMap.editBgColor,
                deleteBgColor: this.weldMap.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.weldMap.yScroll,
                xScroll: this.weldMap.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.WeldList.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.WeldList.length, showQuickJumper: true,
                    position: [this.weldMap.top, this.weldMap.bottom]
                },
                kontrol: true,
            });
        } else {
            this.setState({
                bordered: true,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomLeft',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.WeldList.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.WeldList.length, showQuickJumper: true,
                    position: ['none', 'bottomLeft']
                },
                kontrol: true,
                xScroll: 'scroll'
            });
        }
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
        await this.getTableProperties();
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
    }

    showSuccess(msg) {
        if (!msg) {
            msg = 'Successfull';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    goWeldList = () => {
        window.location.href = '/WeldList';
    }

    render() {
        const columns = [
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="WELDIsometric" defaultMessage="Isometric#" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        // var nameOfIsometric = this.state.isometricData.findIndex(p => p.children[0].data.id === data.isometricId);
                        // return nameOfIsometric.children[0].label;
                        return data.spool + "/";
                    }
                    return "";
                }
            },
            {
                dataIndex: "revno",
                key: "key",
                title: <FormattedMessage id="WELDRevno" defaultMessage="Rev." />,
            },
            {
                dataIndex: "spool",
                key: "key",
                title: <FormattedMessage id="WELDSpool" defaultMessage="Spool#" />,
            },
            {
                dataIndex: "weld",
                key: "key",
                title: <FormattedMessage id="WELDWeld" defaultMessage="Weld #" />,
            },
            {
                dataIndex: "repExtAck",
                key: "key",
                title: <FormattedMessage id="WELDRepExt" defaultMessage="REP Ext" />,
            },
            {
                dataIndex: "pwhtExtAck",
                key: "key",
                title: <FormattedMessage id="WELDPWHTExt" defaultMessage="PWHT Ext" />,
            },
            {
                dataIndex: "tracerAck",
                key: "key",
                title: <FormattedMessage id="WELDTracer" defaultMessage="Tracer" />,
            },
            {
                dataIndex: "ipAck",
                key: "key",
                title: <FormattedMessage id="WELDIP" defaultMessage="I.P." />,
            },
            {
                dataIndex: "rwReasonAck",
                key: "key",
                title: <FormattedMessage id="WELDRWReason" defaultMessage="RW Reason" />,
            },
            {
                dataIndex: "pipingClassAck",
                key: "key",
                title: <FormattedMessage id="WELDPipingClass" defaultMessage="Piping Class" />,
            },
            {
                dataIndex: "serviceAck",
                key: "key",
                title: <FormattedMessage id="WELDService" defaultMessage="Service" />,
            },
            {
                dataIndex: "materialClassAck",
                key: "key",
                title: <FormattedMessage id="WELDMaterialClass" defaultMessage="Material Class" />,
            },
            {
                dataIndex: "codeWeldSize",
                key: "key",
                title: <FormattedMessage id="WELDSize" defaultMessage="Size" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return data.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "codeWallThick",
                key: "key",
                title: <FormattedMessage id="WELDWallThick" defaultMessage="Wall Thick." />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return data.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "categoryAck",
                key: "key",
                title: <FormattedMessage id="WELDCategory" defaultMessage="Weld Category" />,
            },
            {
                dataIndex: "typeAck",
                key: "key",
                title: <FormattedMessage id="WELDType" defaultMessage="Weld Type" />,
            },
            {
                dataIndex: "WPS",
                key: "key",
                title: <FormattedMessage id="WELDWPS" defaultMessage="WPS" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "codeMark1",
                key: "key",
                title: <FormattedMessage id="WELDMark1" defaultMessage="Mark 1" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return data.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "HeatNumber1",
                key: "key",
                title: <FormattedMessage id="WELDHeatNumber1" defaultMessage="Heat Number 1" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "codeMark2",
                key: "key",
                title: <FormattedMessage id="WELDMark2" defaultMessage="Mark 2" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return data.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "HeatNumber2",
                key: "key",
                title: <FormattedMessage id="WELDHeatNumber2" defaultMessage="Heat Number 2" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "weldDate",
                key: "key",
                title: <FormattedMessage id="WELDWeldDate" defaultMessage="Weld Date" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "welders",
                key: "key",
                title: <FormattedMessage id="WELDWelders" defaultMessage="Welders" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "FITUP",
                key: "key",
                title: <FormattedMessage id="WELDFITUP" defaultMessage="FITUP" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "we",
                key: "key",
                title: <FormattedMessage id="WELDWE" defaultMessage="WE" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "PWHT",
                key: "key",
                title: <FormattedMessage id="WELDPWHT" defaultMessage="PWHT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "VT",
                key: "key",
                title: <FormattedMessage id="WELDPVT" defaultMessage="VT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "RTAUT",
                key: "key",
                title: <FormattedMessage id="WELDRTAUT" defaultMessage="RT/AUT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "FT",
                key: "key",
                title: <FormattedMessage id="WELDFT" defaultMessage="FT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "MTPT",
                key: "key",
                title: <FormattedMessage id="WELDMTPT" defaultMessage="MT/PT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "HT",
                key: "key",
                title: <FormattedMessage id="WELDHT" defaultMessage="HT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "PMI",
                key: "key",
                title: <FormattedMessage id="WELDPMI" defaultMessage="PMI" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "UT",
                key: "key",
                title: <FormattedMessage id="WELDUT" defaultMessage="UT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "REP",
                key: "key",
                title: <FormattedMessage id="WELDREP" defaultMessage="REP" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "CUT",
                key: "key",
                title: <FormattedMessage id="WELDCUT" defaultMessage="CUT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "PNT",
                key: "key",
                title: <FormattedMessage id="WELDPNT" defaultMessage="PNT" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "REL",
                key: "key",
                title: <FormattedMessage id="WELDREL" defaultMessage="REL" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
            {
                dataIndex: "testPacks",
                key: "key",
                title: <FormattedMessage id="WELDtestPacks" defaultMessage="Test Packs" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return "";
                    }
                    return "";
                }
            },
        ];

        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        return (
            <div>
                <Form>
                    <Row>
                        <Col xs={8}></Col>
                        <Col xs={3}>
                            <Button onClick={this.goWeldList} style={{ width: '100%' }}
                                variant="info"><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" />
                            </Button>
                        </Col>
                        <Col xs={1}>
                            <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                        </Col>
                    </Row>

                    <br />
                    <br />
                    {this.state.kontrol === true && <Mnd.MNDTable
                        state={this.state}
                        columns={tableColumns} dataSource={this.state.WeldList}
                        pagination={this.state.paginationDisabled}
                        expandable={this.state.expandable}
                        footer={this.state.footer}
                        title={this.state.title}
                        scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    />}

                    <ModalReact
                        show={this.state.modalTable}
                        onHide={this.showOrHideModalTable}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="lg">

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    <MNDTableSettings id="weldMap" />
                                </Col>
                            </Row>
                        </ModalReact.Body>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDeleteWeldList}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ModalReact.Title>
                        </ModalReact.Header>
                        <ModalReact.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" />
                            </p>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalDeleteWeldList}
                            >
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </Button>
                            <Button variant="danger" onClick={this.deleteWeldListOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Button>
                        </ModalReact.Footer>
                    </ModalReact>

                </Form>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WeldMap)
