/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { Toast } from 'primereact/toast';
import { Input, Checkbox, Form as FormAnt } from 'antd';
import SettingsIcon from '@material-ui/icons/Settings';


class Settings extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            modalMNDSettings:false,
            modalSettings:false,
            howToUseSetting: false,
            whatIsSetting: false,
            userSettingsId:0,
        }

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount() {
        this.getUserSettings();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin:  this.props.auth.user.userType===2 ? true:false
                });
             
            }
    }

    showMNDSettings = () => {
        this.getUserSettings();
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.getUserSettings();
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    
    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong. Please check inputs.' });
    }


    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.props.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettingsId: responseUserSettings.data.id,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettingsId: 0,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }

        console.log(responseUserSettings.data);
    }

    setUserSettings = async () => {
        if (this.state.userSettingsId === 0) {
            const newUserSettings = {
                formId: this.props.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }
            console.log(newUserSettings); 

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettingsId,
                formId:this.props.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }
            console.log(updatedUserSettings);

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }



        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess();
        this.props.onSaveChanges(this.props.formId);  
    }
        

    render() {

        return (
            <div>
              <Toast ref={(el) => this.toast = el} />
             
                <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>                               
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    {this.state.isAdmin ? 
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}
  
  const mapDispatchToProps = {
  
  } 
  
  export default connect(mapStateToProps, mapDispatchToProps)(Settings) 
