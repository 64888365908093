/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomInspectionMenu from '../settings/ForComponents/CustomInspectionMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "inspectionlog";

export class inspectionlog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            logType: null,
            rfiNumber: null,
            mirNumber: null,
            revNo: null,
            disciplineId: null,
            worksInspected: null,
            description: null,
            issueDate: null,
            inspectionDate: null,
            inspectionTime: null,
            locationId: null,
            drawingReferenceId: null,
            itpId: null,
            checklistId: null,
            insLevelClient: null,
            insLevelcompany: null,
            qciId: null,
            clientPmtId: null,
            clientQcId: null,
            ncrNoId: null,
            remarks: null,

            drawingReferenceSelectItems: [],
            locationSelectItems: [],
            disciplineSelectItems: [],
            itpLogSelectItems: [],
            checklistSelectItems: [],
            manpowerSelectItems: [],
            clientManpowerSelectItems: [],
            violationLogSelectItems: [],
            violationLogSelectItemsAll: [],

            showResultModal: false,
            showRemarksModal: false,
            result: null,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
        this.fillDrawingReferences();
        this.fillLocations();
        this.fillComboboxes();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            logType: this.state.logType,
            rfiNumber: this.state.rfiNumber,
            mirNumber: this.state.mirNumber,
            revNo: this.state.revNo,
            disciplineId: this.state.disciplineId,
            worksInspected: this.state.worksInspected,
            description: this.state.description,
            issueDate: this.state.issueDate,
            inspectionDate: this.state.inspectionDate,
            inspectionTime: this.state.inspectionTime,
            locationId: this.state.locationId,
            drawingReferenceId: this.state.drawingReferenceId,
            itpId: this.state.itpId,
            checklistId: this.state.checklistId,
            insLevelClient: this.state.insLevelClient,
            insLevelcompany: this.state.insLevelcompany,
            qciId: this.state.qciId,
            clientPmtId: this.state.clientPmtId,
            clientQcId: this.state.clientQcId,
            ncrNoId: this.state.ncrNoId,
            remarks: this.state.remarks,

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/InspectionLog/add", obj);
        else
            await handleRequest("POST", "/InspectionLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            logType: row.logType,
            rfiNumber: row.rfiNumber,
            mirNumber: row.mirNumber,
            revNo: row.revNo,
            disciplineId: row.disciplineId,
            worksInspected: row.worksInspected,
            description: row.description,
            issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
            inspectionDate: Boolean(row.inspectionDate) ? moment(row.inspectionDate) : null,
            inspectionTime: Boolean(row.inspectionTime) ? moment(row.inspectionTime) : null,
            locationId: row.locationId,
            drawingReferenceId: row.drawingReferenceId,
            itpId: row.itpId,
            checklistId: row.checklistId,
            insLevelClient: row.insLevelClient,
            insLevelcompany: row.insLevelcompany,
            qciId: row.qciId,
            clientPmtId: row.clientPmtId,
            clientQcId: row.clientQcId,
            ncrNoId: row.ncrNoId,
            remarks: row.remarks,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            logType: row.logType,
            rfiNumber: row.rfiNumber,
            mirNumber: row.mirNumber,
            revNo: row.revNo,
            disciplineId: row.disciplineId,
            worksInspected: row.worksInspected,
            description: row.description,
            issueDate: Boolean(row.issueDate) ? moment(row.issueDate) : null,
            inspectionDate: Boolean(row.inspectionDate) ? moment(row.inspectionDate) : null,
            inspectionTime: Boolean(row.inspectionTime) ? moment(row.inspectionTime) : null,
            locationId: row.locationId,
            drawingReferenceId: row.drawingReferenceId,
            itpId: row.itpId,
            checklistId: row.checklistId,
            insLevelClient: row.insLevelClient,
            insLevelcompany: row.insLevelcompany,
            qciId: row.qciId,
            clientPmtId: row.clientPmtId,
            clientQcId: row.clientQcId,
            ncrNoId: row.ncrNoId,
            remarks: row.remarks,



        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/InspectionLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    remarksModal = (row) => {
        this.setState({
            showRemarksModal: true,
            id: row.id
        });
    }



    resultModal = (row) => {
        this.setState({
            showResultModal: true,
            id: row.id
        });
    }

    addRemarks = async () => {

        const obj = {
            id: this.state.id,
            remarks: this.state.remarks
        }

        await handleRequest("POST", "/InspectionLog/addRemarks", obj);

        this.restartTable();
        this.showSuccess();
        this.resetInputs();
    }

    addResult = async () => {

        const obj = {
            id: this.state.id,
            result: this.state.result
        }

        await handleRequest("POST", "/InspectionLog/addResult", obj);

        this.restartTable();
        this.showSuccess();
        this.resetInputs();

    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomInspectionMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onAddRemarks={this.remarksModal.bind(this)}
                onAddResult={this.resultModal.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/InspectionLog/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            logType: null,
            rfiNumber: null,
            mirNumber: null,
            revNo: null,
            disciplineId: null,
            worksInspected: null,
            description: null,
            issueDate: null,
            inspectionDate: null,
            inspectionTime: null,
            locationId: null,
            drawingReferenceId: null,
            itpId: null,
            checklistId: null,
            insLevelClient: null,
            insLevelcompany: null,
            qciId: null,
            clientPmtId: null,
            clientQcId: null,
            ncrNoId: null,
            remarks: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            logType: null,
            rfiNumber: null,
            mirNumber: null,
            revNo: null,
            disciplineId: null,
            worksInspected: null,
            description: null,
            issueDate: null,
            inspectionDate: null,
            inspectionTime: null,
            locationId: null,
            drawingReferenceId: null,
            itpId: null,
            checklistId: null,
            insLevelClient: null,
            insLevelcompany: null,
            qciId: null,
            clientPmtId: null,
            clientQcId: null,
            ncrNoId: null,
            remarks: null,
            showResultModal: false,
            showRemarksModal: false,
            result: null,


        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addCodelocationId = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "location"
            }

            var response = await handleRequest("POST", "/Code/add", newItem);
            if (response.type === "ERROR") {
                this.error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillLocations();
            }
        } else {
            this.showError("Please enter department");
        }
    };

    fillLocations = async () => {
        const response = await handleRequest("GET", "/Code/type/location");
        if (Boolean(response.data)) {
            this.setState({
                locationSelectItems: response.data
            });
        }
    }


    addCodedrawingReferenceId = async () => {
        const { codeName } = this.state;
        if (Boolean(codeName)) {
            const newItem = {
                name: codeName,
                type: "drawingReference"
            }

            var response = await handleRequest("POST", "/Code/add", newItem);
            if (response.type === "ERROR") {
                this.error(response);
            } else {
                this.setState({
                    codeName: '',
                });
                this.fillDrawingReferences();
            }
        } else {
            this.showError("Please enter department");
        }
    };

    fillDrawingReferences = async () => {
        const response = await handleRequest("GET", "/Code/type/drawingReference");
        if (Boolean(response.data)) {
            this.setState({
                drawingReferenceSelectItems: response.data
            });
        }
    }

    fillComboboxes = async () => {

        // itpLogSelectItems:[],
        // checklistSelectItems:[],
        // manpowerSelectItems:[],
        // clientManpowerSelectItems:[],
        // violationLogSelectItems:[],

        const response = await handleRequest("GET", "/QualityItp/getAllByCompany");


        if (response.data.length !== 0) {

            this.setState({
                itpLogSelectItems: response.data
            });

        }


        const response2 = await handleRequest("GET", "/Discipline/getallByCompany");


        if (response2.data.length !== 0) {

            this.setState({
                disciplineSelectItems: response2.data
            });

        }

        const response3 = await handleRequest("GET", "/ClientPersonnelLog/getAllByCompany");


        if (response3.data.length !== 0) {

            this.setState({
                clientManpowerSelectItems: response3.data
            });

        }

        const response4 = await handleRequest("GET", "/ManpowerLog/getAllByCompany");


        if (response4.data.length !== 0) {

            this.setState({
                manpowerSelectItems: response4.data
            });

        }

        const response5 = await handleRequest("GET", "/ViolationLog/getAllByCompany");

        if (response5.data.length !== 0) {

            this.setState({
                violationLogSelectItemsAll: response5.data
            });

        }



    }

    resetViolationLogSelectItems = () => {
        const { violationLogSelectItemsAll, logType } = this.state;
        if (Boolean(logType) && logType.length > 0)
            if (logType === "Construction Inspection") {
                let res = violationLogSelectItemsAll.filter(p => p.violationType === "NCR" || p.violationType === "Standard Violation");
                this.setState({
                    violationLogSelectItems: Boolean(res) ? res : []
                })
            }

        if (logType === "Item Receiving") {
            let res = violationLogSelectItemsAll.filter(p => p.violationType === "NCR");
            this.setState({
                violationLogSelectItems: Boolean(res) ? res : []
            })
        }

    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.logtype").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.logtype" defaultMessage="Construction Inspection / Item Receiving" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="logType"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.logtype")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.logtype").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.logtype")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.logtype").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Radio.Group id="InspectionLog.logtype" onChange={(e) => {

                                                            this.formRef.current.setFieldsValue({
                                                                logType: e.target.value,
                                                                ncrNoId: null,
                                                                rfiNumber: null,
                                                                mirNumber: null,
                                                            });

                                                            this.setState({
                                                                logType: e.target.value,
                                                                ncrNoId: null,
                                                                rfiNumber: null,
                                                                mirNumber: null,
                                                            }, this.resetViolationLogSelectItems);
                                                        }} value={this.state.logType}>
                                                            <Radio value={"Construction Inspection"}>Construction Inspection</Radio>
                                                            <Radio value={"Item Receiving"}>Item Receiving</Radio>
                                                        </Radio.Group>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {Boolean(this.state.logType) && this.state.logType === "Construction Inspection" && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.rfi").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.rfi" defaultMessage="RFI #" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="rfiNumber"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.rfi")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.rfi").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.rfi")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.rfi").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="InspectionLog.rfi" placeholder="RFI #"
                                                            min={0} value={this.state.rfiNumber} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    rfiNumber: value
                                                                });
                                                                this.setState({ rfiNumber: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {Boolean(this.state.logType) && this.state.logType === "Item Receiving" && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.mir").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.mir" defaultMessage="MIR #" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="mirNumber"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.mir")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.mir").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.mir")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.mir").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="InspectionLog.mir" placeholder="MIR #"
                                                            min={0} value={this.state.mirNumber} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    mirNumber: value
                                                                });
                                                                this.setState({ mirNumber: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.revno").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.revno" defaultMessage="Rev. No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="revNo"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.revno")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.revno").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.revno")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.revno").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="InspectionLog.revno" placeholder="Rev. No"
                                                            min={0} value={this.state.revNo} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    revNo: value
                                                                });
                                                                this.setState({ revNo: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.discipline").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.discipline" defaultMessage="Discipline" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="disciplineId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.discipline")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.discipline").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.discipline")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.discipline").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Discipline" id="InspectionLog.discipline" value={this.state.disciplineId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                disciplineId: value
                                                            });
                                                            this.setState({ disciplineId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.disciplineSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.wti").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.wti" defaultMessage="Works to be inspected" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="worksInspected"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.wti")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.wti").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.wti")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.wti").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Works to be inspected" style={{ width: "100%" }} id="InspectionLog.wti" value={this.state.worksInspected} onChange={(e) => { this.setState({ worksInspected: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.description").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.description" defaultMessage="Description" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="description"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.description")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.description").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.description")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.description").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Description" style={{ width: "100%" }} id="InspectionLog.description" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "inspectionlog.issuedate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="inspectionlog.issuedate" defaultMessage="Issue Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="issueDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "inspectionlog.issuedate")) ? this.state.dynamicInputs.find(p => p.inputKey === "inspectionlog.issuedate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "inspectionlog.issuedate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "inspectionlog.issuedate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="inspectionlog.issuedate" format="DD-MM-YYYY" value={this.state.issueDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                issueDate: date
                                                            });
                                                            this.setState({
                                                                issueDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.inspectiondate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.inspectiondate" defaultMessage="Inspection Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="inspectionDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiondate")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiondate").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiondate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiondate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="InspectionLog.inspectiondate" format="DD-MM-YYYY" value={this.state.inspectionDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                inspectionDate: date
                                                            });
                                                            this.setState({
                                                                inspectionDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.inspectiontime").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.inspectiontime" defaultMessage="Inspection Time" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="inspectionTime"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiontime")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiontime").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiontime")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.inspectiontime").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <TimePicker id="InspectionLog.inspectiontime" value={this.state.inspectionTime} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                inspectionTime: value
                                                            });
                                                            this.setState({
                                                                inspectionTime: value
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.location").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.location" defaultMessage="Location" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="locationId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.location")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.location").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.location")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.location").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Location"
                                                            value={this.state.locationId} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    locationId: value
                                                                });
                                                                this.setState({
                                                                    locationId: value
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodelocationId}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.locationSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.drawingreference").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.drawingreference" defaultMessage="Drawing Reference" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="drawingReferenceId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.drawingreference")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.drawingreference").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.drawingreference")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.drawingreference").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Drawing Reference"
                                                            value={this.state.drawingReferenceId} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    drawingReferenceId: value
                                                                });
                                                                this.setState({
                                                                    drawingReferenceId: value
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodedrawingReferenceId}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.drawingReferenceSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.itp").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.itp" defaultMessage="ITP" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="itpId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.itp")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.itp").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.itp")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.itp").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="ITP" id="InspectionLog.itp" value={this.state.itpId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                itpId: value
                                                            });
                                                            this.setState({ itpId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.itpLogSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.description}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.checklist").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.checklist" defaultMessage="Checklist" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="checklistId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.checklist")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.checklist").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.checklist")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.checklist").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Checklist" id="InspectionLog.checklist" value={this.state.checklistId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                checklistId: value
                                                            });
                                                            this.setState({ checklistId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>

                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.InsLevelClient").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.InsLevelClient" defaultMessage="Ins.Level Client" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="insLevelClient"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelClient")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelClient").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelClient")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelClient").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Ins.Level Client" id="InspectionLog.InsLevelClient" value={this.state.insLevelClient} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                insLevelClient: value
                                                            });
                                                            this.setState({ insLevelClient: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            <Option key={"R"} value={"R"}>R</Option>
                                                            <Option key={"RH"} value={"RH"}>RH</Option>
                                                            <Option key={"S"} value={"S"}>S</Option>
                                                            <Option key={"W"} value={"W"}>W</Option>
                                                            <Option key={"H"} value={"H"}>H</Option>

                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.InsLevelCompany").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.InsLevelCompany" defaultMessage="Ins.Level Company" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="insLevelcompany"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelCompany")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelCompany").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelCompany")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.InsLevelCompany").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Ins.Level Company" id="InspectionLog.InsLevelCompany" value={this.state.insLevelcompany} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                insLevelcompany: value
                                                            });
                                                            this.setState({ insLevelcompany: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option> 
                                                        <Option key={"R"} value={"R"}>R</Option>
                                                        <Option key={"RH"} value={"RH"}>RH</Option>
                                                        <Option key={"S"} value={"S"}>S</Option>
                                                        <Option key={"W"} value={"W"}>W</Option>
                                                        <Option key={"H"} value={"H"}>H</Option>
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.qci").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.qci" defaultMessage="QCI" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="qciId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.qci")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.qci").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.qci")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.qci").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="QCI" id="InspectionLog.qci" value={this.state.qciId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                qciId: value
                                                            });
                                                            this.setState({ qciId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.manpowerSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{Boolean(i.staff) ? i.staff.fullName : ""}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.ClientPMT").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.ClientPMT" defaultMessage="Client PMT" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="clientPmtId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ClientPMT")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ClientPMT").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ClientPMT")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ClientPMT").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Client PMT" id="InspectionLog.ClientPMT" value={this.state.clientPmtId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                clientPmtId: value
                                                            });
                                                            this.setState({ clientPmtId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.clientManpowerSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name + " " + i.surname}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLogClientQC").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLogClientQC" defaultMessage="Client QC" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="clientQcId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLogClientQC")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLogClientQC").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLogClientQC")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLogClientQC").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Client QC" id="InspectionLogClientQC" value={this.state.clientQcId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                clientQcId: value
                                                            });
                                                            this.setState({ clientQcId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.clientManpowerSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name + " " + i.surname}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.ncrno").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.ncrno" defaultMessage="NCR No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="ncrNoId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ncrno")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ncrno").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ncrno")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.ncrno").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="NCR No" id="InspectionLog.ncrno" value={this.state.ncrNoId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                ncrNoId: value
                                                            });
                                                            this.setState({ ncrNoId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.violationLogSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.description}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLog.remarks").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="InspectionLog.remarks" defaultMessage="Remarks" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="remarks"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.remarks")) ? this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.remarks").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.remarks")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "InspectionLog.remarks").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Remarks" style={{ width: "100%" }} id="InspectionLog.remarks" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />
                                    <Column title="S/N" dataIndex="index" />
                                    <Column title='Type' dataIndex='logType' render={(value, row, index) => { return value; }} />
                                    <Column title='RFI#' dataIndex='rfiNumber' render={(value, row, index) => { return value; }} />
                                    <Column title='MIR#' dataIndex='mirNumber' render={(value, row, index) => { return value; }} />
                                    <Column title='Rev. No' dataIndex='revNo' render={(value, row, index) => { return value; }} />
                                    <Column title='Discipline' dataIndex='discipline' render={(value, row, index) => { return Boolean(value) ? value.disciplineName : ""; }} />
                                    <Column title='Works to be inspected' dataIndex='worksInspected' render={(value, row, index) => { return value; }} />
                                    <Column title='Description' dataIndex='description' render={(value, row, index) => { return value; }} />
                                    <Column title='Issue Date' dataIndex='issueDate' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />
                                    <Column title='Inspection Date' dataIndex='inspectionDate' render={(value, row, index) => { return Boolean(value) ? moment(value).format("DD-MM-YYYY") : ""; }} />
                                    <Column title='Inspection Time' dataIndex='inspectionTime' render={(value, row, index) => { return Boolean(value) ? moment(value).format("HH:mm:ss") : ""; }} />
                                    <Column title='Location' dataIndex='location' render={(value, row, index) => { return Boolean(value) ? value.name : ""; }} />
                                    <Column title='Drawing Reference' dataIndex='drawingReference' render={(value, row, index) => { return Boolean(value) ? value.name : ""; }} />
                                    <Column title='ITP' dataIndex='itp' render={(value, row, index) => { return Boolean(value) ? value.description : ""; }} />
                                    <Column title='Checklist' dataIndex='checklistId' render={(value, row, index) => { return value; }} />
                                    <Column title='InsLevel Client' dataIndex='insLevelClient' render={(value, row, index) => { return value; }} />
                                    <Column title='InsLevel Company' dataIndex='insLevelcompany' render={(value, row, index) => { return value; }} />
                                    <Column title='QCI' dataIndex='qci' render={(value, row, index) => { return Boolean(value) && Boolean(value.staff) ? value.staff.fullName : ""; }} />
                                    <Column title='Client Pmt' dataIndex='clientPmt' render={(value, row, index) => { return Boolean(value) ? value.name + " " + value.surname : ""; }} />
                                    <Column title='Client Qc' dataIndex='clientQc' render={(value, row, index) => { return Boolean(value) ? value.name + " " + value.surname : ""; }} />

                                    <Column title='NCR No' dataIndex='ncrNo' render={(value, row, index) => { return Boolean(value) ? value.description : ""; }} />
                                    <Column title='Result' dataIndex='result' render={(value, row, index) => { return value; }} />
                                    <Column title='remarks' dataIndex='remarks' render={(value, row, index) => { return value; }} />


                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showRemarksModal}
                            onHide={() => {
                                this.setState({
                                    showRemarksModal: false
                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Add remarks</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Input placeholder="Remarks" style={{ width: "100%" }} id="violationlog.remarks.modal" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />

                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showRemarksModal: false
                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addRemarks} >Save</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showResultModal}
                            onHide={() => {
                                this.setState({
                                    showResultModal: false
                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Add Result</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Select style={{ width: "100%" }} placeholder="Ins.Level Client" id="InspectionLog.InsLevelClient" value={this.state.result} onChange={(value) => {

                                    this.setState({ result: value })
                                }}>
                                    <Option key={null} value={null}>Select</Option>
                                    <Option key={"Approved"} value={"Approved"}>Approved</Option>
                                    <Option key={"Rejected"} value={"Rejected"}>Rejected</Option>
                                    <Option key={"On Hold"} value={"On Hold"}>On Hold</Option>
                                    <Option key={"Cancelled"} value={"Cancelled"}>Cancelled</Option>

                                </Select>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        showResultModal: false
                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="info" onClick={this.addResult} >Save</ReactButton>
                            </Modal.Footer>
                        </Modal>


                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(inspectionlog)
