
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { MenuProvider } from 'react-contexify';
import CustomMessageMenu from '../settings/ForComponents/CustomMessageMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, TreeSelect, Button as AntButton, Col as ColAnt, Row as RowAnt, Image } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import { isThisHour } from 'date-fns/esm';
import { getLastLocation } from "../../../_metronic/_helpers/RouterHelpers"
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

const { TextArea } = Input;
const { Column } = Table;
const { Option } = Select;
const pageName = "Messaging";


export class Messaging extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            modalCloseShow: false,
            modalPdfViewer: false,
            pdfFile: "",
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePaths: [],
            fileList: [],
            overall: true,
            menuId: null,
            message: null,
            subject: null,
            nodes: [],
            selectedRow: null,


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        var page = getLastLocation();

        console.log("page", page);

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();

        const user = await handleRequest("GET", "/User/me");
        const menuList = user.data.authList.filter(p => p.action === "view");

        this.setState({
            menuList: menuList
        });
        // const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTreeDynamicMenu");

        // var items = responseDynamicMenu.data;

        const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTree");  // getDynamicMenuNodes

        var items = responseDynamicMenu.data;

        console.log(menuList);


        if (Boolean(items) && items.length > 0) {
            items[0].disabled = true;
            var root = [];
            root.push(Object.assign({}, items[0]));
            root[0].children = [];
            items[0].children.forEach((element, i) => {
                if (menuList.findIndex(el => el.menuId == element.menuId || el.form === "All") >= 0) {
                    element.disabled = true;
                    this.getMenu(element, root[0].children);
                }

            });
        }



        this.setState({
            nodes: root
        });
    }



    getMenu = async (element, list) => {

        if (Boolean(element)) {

            if (Boolean(element.children) && element.children.length > 0)
                element.disabled = true;

            var obj = Object.assign({}, element);
            obj.children = [];
            list.push(obj);

            if (element.children) {
                element.children.forEach(el => {

                    if (this.state.menuList.findIndex(p => p.menuId == el.menuId || p.form === "All") >= 0)
                        this.getMenu(el, obj.children);
                    //  list.push(obj);
                });

            }


        }


    }



    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        const { filePaths } = this.state;

        let obj = {
            id: this.state.id,
            overall: this.state.overall,
            menuId: this.state.menuId,
            message: this.state.message,
            subject: this.state.subject,
            filePath: Boolean(filePaths) ? filePaths.toString() : "",
            status: "Open"
        };

        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/Message/add", obj);
        else
            await handleRequest("POST", "/Message/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        let fileList = [];
        let pathList = Boolean(row.filePath) ? row.filePath.split(",") : [];
        if (Boolean(pathList) && pathList.length > 0) {

            pathList.forEach((element, index) => {
                let obj = {
                    uid: index,
                    name: element,
                    url: element,
                    thumbUrl: element,
                };

                fileList.push(obj);
            });
        }

        this.formRef.current.setFieldsValue({
            overall: row.overall,
            menuId: row.menuId,
            message: row.message,
            subject: row.subject,
            filePath: Boolean(row.filePath) ? row.filePath.split(",") : [],

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            overall: row.overall,
            menuId: row.menuId,
            message: row.message,
            subject: row.subject,
            fileList: fileList,
            filePaths: Boolean(row.filePath) ? row.filePath.split(",") : [],

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/Message/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    close = async () => {

        const obj = this.state.selectedRow;
        obj.status = "Closed";

        await handleRequest("POST", "/Message/update", obj);

        this.restartTable();
        this.showSuccess();
        this.resetInputs();

    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    closeModal = (row) => {
        this.setState({
            modalCloseShow: true,
            id: row.id,
            selectedRow: row,
        });
    }


    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            msg: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            msg: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            msg: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {

        return <React.Fragment>
            <CustomMessageMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onClose={this.closeModal.bind(this)} />
        </React.Fragment>;

    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/Message/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            overall: true,
            menuId: null,
            message: null,
            filePaths: [],
            fileList: [],
            subject: null,


        });

        this.setState({
            modalDeleteShow: false,
            modalCloseShow: false,
            selectedRow: null,
            hideSave: false,
            hideUpdate: true,
            id: null,
            overall: true,
            menuId: null,
            message: null,
            filePaths: [],
            subject: null,
            fileList: [],



        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    onChangeTreeSelect = (value, label, extra) => {

        var menuId = parseInt(value);

        this.formRef.current.setFieldsValue({
            menuId: menuId
        });

        this.setState({
            menuId: menuId

        });
    };


    downloadFile = (url) => {
        window.location.href = url;
    }

    downloadMultipleFile = async (url) => {

        let pathList = url.split(",");
        console.log(pathList);

        if (Boolean(pathList) && pathList.length > 0) {

            if (pathList.length === 1) {
                if (pathList[0].toLowerCase().endsWith("pdf"))
                    this.setState({
                        pdfFile: pathList[0],
                        modalPdfViewer: true,
                    });
                else
                    this.downloadFile(pathList[0]);
            }
            else {
                var response = await handleRequest("POST", "/File/download", pathList);

                if (response.type === "ERROR") {
                    this.error(response);
                }
                else {

                    try {
                        const link = document.createElement('a')
                        link.href = response.data;
                        link.download = "Fİles.zip";
                        link.click();

                    } catch (error) {
                        console.log(error);

                    }

                }
            }

        }


    }


    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer
        });
        // setTimeout(() => {
        //     document.body.style.overflow = "auto";
        //     document.body.style.paddingRight = "0px";
        // }, 500);
    }



    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Messaging.overall").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Messaging.overall" defaultMessage="Overall" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="overall"
                                                rules={[{ required: false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.overall")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Messaging.overall").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Checkbox id="Messaging.overall" onChange={(e) => {
                                                            this.setState({
                                                                overall: e.target.checked
                                                            })

                                                            if (e.target.checked)
                                                                this.setState({
                                                                    menuId: null
                                                                })

                                                        }} checked={this.state.overall}>  </Checkbox>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }

                                        {this.state.overall === true && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Messaging.subject").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Messaging.subject" defaultMessage="Subject" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="subject"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.subject")) ? this.state.dynamicInputs.find(p => p.inputKey === "Messaging.subject").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.subject")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Messaging.subject").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>

                                                        <TextArea placeholder="Subject" style={{ width: "100%" }} id="Messaging.subject" value={this.state.subject} onChange={(e) => { this.setState({ subject: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }
                                        {
                                            this.state.overall === false && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Messaging.menu").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Messaging.menu" defaultMessage="Menu" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="menuId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.menu")) ? this.state.dynamicInputs.find(p => p.inputKey === "Messaging.menu").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.menu")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Messaging.menu").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <TreeSelect
                                                            style={{ width: '100%' }}
                                                            value={this.state.menuId !== null && this.state.menuId > 0 && this.state.menuId.toString()}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            treeData={this.state.nodes}
                                                            placeholder="Menu" id="Messaging.menu"
                                                            treeDefaultExpandAll
                                                            onChange={this.onChangeTreeSelect}
                                                        />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Messaging.message").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Messaging.message" defaultMessage="Message" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="message"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.message")) ? this.state.dynamicInputs.find(p => p.inputKey === "Messaging.message").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Messaging.message")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Messaging.message").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>

                                                        <TextArea placeholder="Message" style={{ width: "100%" }} id="Messaging.message" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Message.filePath").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="Message.filePath" defaultMessage="Upload" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="filePath"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Message.filePath")) ? this.state.dynamicInputs.find(p => p.inputKey === "Message.filePath").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "Message.filePath")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "Message.filePath").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Upload {...props} id="path"
                                                            showUploadList={true}
                                                            multiple
                                                            fileList={this.state.fileList}
                                                            onChange={({ fileList }) => {
                                                                var filePaths = [];
                                                                const stateFileList = this.state.fileList;
                                                                if (fileList.length >= 1) {
                                                                    for (let index = 0; index < fileList.length; index++) {

                                                                        if (Boolean(fileList[index].response)) {
                                                                            filePaths[index] = fileList[index].response.url;
                                                                            if (filePaths.length === fileList.length && fileList.length > stateFileList.length)
                                                                                this.showSuccess("Files uploaded successfully");

                                                                            // fileName: info.file.name, 
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({
                                                                    fileList: fileList,
                                                                    filePaths: filePaths,
                                                                });
                                                            }}
                                                            beforeUpload={(fileList) => {

                                                                return true;
                                                            }}
                                                            onRemove={({ fileList }) => {


                                                            }
                                                            }>
                                                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                                <UploadOutlined /> Upload
                                                            </AntButton>
                                                        </Upload>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="S/N" dataIndex="index"  />

                                    <Column title='Subject' dataIndex='overall' render={(value, row, index) => { if (Boolean(value)) return row.subject; else return Boolean(row.menu) ? row.menu.menuTitle : "" }} />
                                    {/* <Column title='Menu' dataIndex='menu' render={(value, row, index) => { if (Boolean(value)) return value.menuTitle; return "";; }} /> */}
                                    <Column  title='Date' dataIndex='date' render={(value, row, index) => { if (Boolean(value)) return moment(value).format("DD-MM-YYYY HH:mm"); else return "" }} />

                                    <Column  title='Message' dataIndex='message' render={(value, row, index) => { return value; }} />
                                    <Column title='Status' dataIndex='status' render={(value, row, index) => { return value; }} />
                                    <Column title='Download' dataIndex='filePath' render={(value, row, index) => {
                                        if (Boolean(value)) {
                                            let pathList = value.split(","); "asdf".toLowerCase().endsWith()
                                            if (Boolean(pathList) && pathList.length === 1 && (pathList[0].toLowerCase().endsWith("jpeg") || pathList[0].toLowerCase().endsWith("jpg") || pathList[0].toLowerCase().endsWith("png"))) {
                                                return <Image width={100}
                                                    src={pathList[0]}
                                                />
                                            }
                                            else
                                                return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadMultipleFile(value)} type="primary" shape="round" icon={<DownloadOutlined />}> </AntButton>
                                        }
                                    }} />
                                    <Column title='Close Out Date' dataIndex='closeDate' render={(value, row, index) => { if (Boolean(value)) return moment(value).format("DD-MM-YYYY HH:mm"); else return "" }} />


                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />

                                </Table>}
                            </Card>

                        </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            size="xl"
                            show={this.state.modalPdfViewer}
                            onHide={this.showOrHideModalPdfViewer}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    PDF  Viewer
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                                    <div style={{ height: "900px" }}>
                                        <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                                    </div>
                                </Worker>

                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton
                                    variant="secondary"
                                    onClick={this.showOrHideModalPdfViewer}
                                >
                                    Close
                                </ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalCloseShow}
                            onHide={() => {
                                this.setState({
                                    modalCloseShow: false,
                                    selectedRow: null

                                })
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be closed !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={() => {
                                    this.setState({
                                        modalCloseShow: false,
                                        selectedRow: null

                                    })
                                }}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.close} >Close</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.msg}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Messaging)
