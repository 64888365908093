/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Column } from "primereact/column";
import { Card } from 'react-bootstrap';
import "react-toggle/style.css";
import { DataTable } from 'primereact/components/datatable/DataTable';
import "../settings/css/DataTable.scss";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Calendar } from 'primereact/calendar';
import WorkFlowExpand from './WorkFlowExpand';
import { InputText } from "primereact/inputtext";
import { PickList } from 'primereact/picklist';
import { Link } from "react-router-dom";
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import moment from 'moment';
import { Modal as AntModal, Timeline, Button as AntButton } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Collapse, Select } from 'antd';
import { TreeSelect } from 'antd';
import { CloseOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';


const { confirm } = AntModal;
const { Panel } = Collapse;
const { Option } = Select;

function callback(key) {
  console.log(key);
}



class WorkFlow extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      hidden: true,
      cancelhidden: true,
      tablehidden: false,
      createNewButton: "Create New",
      lastButton: "Save",
      addButton: "Save",
      buttonClassName: "p-button-success",
      createNewButtonVariant: "p-button-info",
      displayFlowchartDialog: false,
      displayLastButton: true,

      workFlowName: "",
      workFlowId: 0,
      dataTableValue: [],
      WorkFlowStepAll: [],
      departments: [],
      departmentId: 0,
      sequence: 0,
      every: 1,
      period: '',
      periodDate: null,
      monthWeekStyle: '',
      executionArea: 0,
      periodPoint: 1,
      periodTime: null,
      firstLast: '',
      firstLastPoint: '1',
      activities: [],
      activityId: 0,
      workflowList: [],
      stepWorkflowId: 0,
      steps: [],
      stepList: [],
      personels: [],
      nodes: [],
      menuList: [],
      toKeys: [],
      ccKeys: [],
      byWho: '',
      activationId: '',
      activationUrl: '',
      toMessageType: '',
      ccMessageType: '',
      selectedStep: null


    };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);

  }

  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
  };

  showError(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
  };

  componentWillMount = async () => {

    try {
      this.setState({
        menuList: this.props.auth.user.authList.filter(p => p.action === "view")
      });

    } catch (error) {

    }


    setTimeout(() => {
      console.log(this.state.menuList);
    }, 500);


    const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTreeDynamicMenu");


    // var items = MenuConfig.aside.items;
    var items = responseDynamicMenu.data;

    console.log(items);
    if (Boolean(items) && items.length > 0) {
      items[0].disabled = true;
      var root = [];
      root.push(Object.assign({}, items[0]));
      root[0].children = [];
      items[0].children.forEach((element, i) => {
        if (this.state.menuList.findIndex(el => el.menuId === element.menuId || el.form === "All") >= 0) {
          element.disabled = true;
          this.getMenu(element, root[0].children);
        }

      });
    }
    // const _actionList = ActionsList.map(function (row) {
    //   return { label: row.label.toString(), key: row.key.toString(), title: row.label.toString() }
    // })

    console.log(root);

    this.setState({
      nodes: root
    });

  }

  getMenu = async (element, list) => {

    if (Boolean(element)) {

      if (Boolean(element.children) && element.children.length > 0)
        element.disabled = true;

      var obj = Object.assign({}, element);
      obj.children = [];
      list.push(obj);

      if (element.children) {
        element.children.forEach(el => {

          if (this.state.menuList.findIndex(p => p.menuId === el.menuId || p.form === "All") >= 0)
            this.getMenu(el, obj.children);
          //  list.push(obj);
        });

      }


    }


  }

  componentDidMount = async () => {

    this.getWorkFlow();
    this.getPersonels();
    const response = await handleRequest("GET", "/Department/getAll");

    if (response.data.length !== 0) {

      const columns = response.data;

      const options = columns.map(function (row) {
        return { name: row.departmentName.toString(), id: row.id }
      })

      this.setState({
        departments: options,

      });
    }

    const response2 = await handleRequest("GET", "/WFActivity/getAll");

    if (response2.data.length !== 0) {

      this.setState({
        activities: response2.data

      });
    }



  }

  getPersonels = async () => {

    const responseUser = await handleRequest("GET", "/User/getCompanyItems");

    if (responseUser.data.length !== 0) {


      var filteredUsers = responseUser.data.filter(p => p.staffId > 0);

      const options = filteredUsers.map(function (row) {
        return { name: row.staff.fullName, id: row.id }
      })

      this.setState({
        personels: options,
      });

    }

  }

  getWorkFlow = async () => {


    const response = await handleRequest("GET", "/WorkFlow/getAll");

    if (Boolean(response.data) && response.data.length > 0) {
      const data = response.data;

      data.forEach(element => {
        element.key = element.id;
        if (Boolean(element.startDate))
          element.startDateF = moment(element.startDate).format("DD/MM/YYYY HH:mm");
        else element.startDateF = '';

        if (Boolean(element.endDate))
          element.endDateF = moment(element.endDate).format("DD/MM/YYYY HH:mm");
        else element.endDateF = '';

        if (element.sequence === 1)
          element.period = "Variable"

        // if (Boolean(element.periods))
        //   element.periods.forEach(period => {
        //     const obj = Object.assign({}, element);
        //     obj.key = element.id + '.' + period.id;
        //     obj.periodId = period.id;
        //     obj.periodStartDate = moment(period.startDate).format("DD/MM/YYYY HH:mm");
        //     obj.periodOrder = period.periodOrder;
        //     list.push(obj);
        //   });

      });

      this.setState({ dataTableValue: data, workflowList: data });

    }

  }

  getWorkFlowStep = async () => {

    const response = await handleRequest("GET", "/WorkFlowStep/getAll");
    console.log(response)
    if (response.data.length !== 0) {
      this.setState({ WorkFlowStepAll: response.data });
    } else {
      this.setState({ WorkFlowStepAll: [] });
    }
  }

  createButtonClick = () => {
    if (this.state.createNewButton === "Create New") {
      this.setState({
        hidden: false,
        cancelhidden: true,
        tablehidden: true,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "p-button-danger",
      });
    }
    else {
      this.cancelButtonClick();
    }
  }

  cancelButtonClick = () => {
    this.setState({
      hidden: true,
      tablehidden: false,
      addButton: "Add",
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "p-button-info",
      workFlowName: "",
      executionArea: 0,
      stepList: [],
      toKeys: [],
      ccKeys: [],
      byWho: '',
      activationId: '',
      activationUrl: '',
      toMessageType: '',
      ccMessageType: '',
      workFlowId: 0,
      sequence: '0',
      startDate: null,
      endDate: null,
      departmentId: 0,
      every: 0,
      period: '',
      periodDate: null,
      periodPoint: '',
      periodTime: null,
      monthWeekStyle: '',
      firstLastPoint: '1',
      selectedStep: null
    });
  }

  openFlowchartDialog = async () => {
    if (this.state.workFlowName !== "") {
      this.setState({
        displayFlowchartDialog: true
      })
    }
    else {

    }
  }

  saveWorkFlow2 = async () => {
    console.log(this.state.stepList);
  }
  saveWorkFlow = async () => {
    var result = true;
    var errorMessage = [];

    console.log(this.state.periodTime);
    if (!Boolean(this.state.workFlowName)) {
      result = false;
      errorMessage.push('Please type workflow name! ')
    }
    if (!Boolean(this.state.sequence)) {
      result = false;
      errorMessage.push('Please select sequence! ')
    }
    // if (!Boolean(this.state.startDate)) {
    //   result = false;
    //   errorMessage.push('Please select start date!')
    // }

    if (this.state.sequence === '2' && !Boolean(this.state.endDate)) {
      result = false;
      errorMessage.push('Please select end date!');
    }

    if (this.state.sequence === '2' && !Boolean(this.state.period)) {
      result = false;
      errorMessage.push('Please select period!');
    }

    /* getMonthLabels = (moments) => {

        var first = moments[0];
        var result = [];
        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.format("YYYY-MM"),
                year: first.get('year'),
                month: first.month() + 1
            }
            result.push(obj);
            first = first.add(1, 'months')
            console.log(first);
        }

        return result;

    }*/

    if (result) {
      const _periods = [];

      if (this.state.sequence === '1') {
        let obj = { startDate: null };
        _periods.push(obj); 
      }
      else
        switch (this.state.period) {
          case 'year':
            let sd = this.state.startDate;
            let first = moment(sd);
            let end = moment(this.state.endDate);
            let every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'years');

              console.log(first);

            }
            break;
          case 'month':
            sd = this.state.startDate;
            let periodTime = moment(this.state.periodTime).format('HH:mm:ss');
            if (this.state.monthWeekStyle === '1') {
              first = moment(sd).date(this.state.periodPoint);
              if (first.isBefore(sd))
                first.add(1, 'months');
            }
            if (this.state.monthWeekStyle === '2') {
              if (this.state.firstLast === 'first') {
                first = moment(sd).startOf('month');
                first = first.day(parseInt(this.state.firstLastPoint));
                if (first.date() > 7)
                  first.add(7, 'd');

                if (first.isBefore(sd)) {
                  first.add(1, 'months');
                  first = first.startOf('month');
                  first = first.day(this.state.firstLastPoint);
                  if (first.date() > 7)
                    first.add(7, 'd');
                }


              }

              if (this.state.firstLast === 'last') {

                first = first.endOf('month');
                first = first.day(this.state.firstLastPoint);
                if (first.isBefore(sd)) {
                  first.add(1, 'months');
                  first = first.endOf('month');
                  first = first.day(this.state.firstLastPoint);
                }
              }

            }

            console.log(periodTime);
            console.log(first);
            let time = periodTime.split(':');
            first.set({
              hours: time[0],
              minutes: time[1],
              seconds: time[2]
            })
            console.log(first);
            end = moment(this.state.endDate);
            every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'months');

              if (this.state.monthWeekStyle === '2') {
                if (this.state.firstLast === 'first') {
                  first = first.startOf('month');
                  first = first.day(this.state.firstLastPoint);
                  if (first.date() > 7)
                    first.add(7, 'd');
                }

                if (this.state.firstLast === 'last') {

                  first = first.endOf('month');
                  first = first.day(this.state.firstLastPoint);

                }

                time = periodTime.split(':');
                first.set({
                  hours: time[0],
                  minutes: time[1],
                  seconds: time[2]
                })

              }


              console.log(first);

            }

            break;
          case 'week':

            sd = this.state.startDate;
            first = moment(sd);
            periodTime = moment(this.state.periodTime).format('HH:mm:ss');
            if (this.state.monthWeekStyle === '1') {
              first = moment(sd).day(this.state.periodPoint);
              if (first.isBefore(sd))
                first.add(1, 'weeks');
            }

            time = periodTime.split(':');
            first.set({
              hours: time[0],
              minutes: time[1],

            })

            if (first.isBefore(sd))
              first.add(1, 'weeks');

            end = moment(this.state.endDate);
            every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'weeks');

              console.log(first);

            }

            break;
          case 'day':
            sd = this.state.startDate;
            first = moment(sd);

            periodTime = moment(this.state.periodTime).format('HH:mm:ss');
            time = periodTime.split(':');
            first.set({
              hours: time[0],
              minutes: time[1],

            })

            if (first.isBefore(sd))
              first.add(1, 'days');

            end = moment(this.state.endDate);
            every = parseInt(this.state.every);
            while (first.isSameOrBefore(end)) {
              let obj = {
                startDate: first.toDate(),
              }
              _periods.push(obj);
              first.add(every, 'days');

              console.log(first);

            }
            break;

          default:
            break;
        }


      console.log(_periods);

      //----- 

      const { toKeys, ccKeys, byWho, activationId, activationUrl, toMessageType, ccMessageType } = this.state;
      if (Boolean(this.state.selectedStep)) {
        var lastSelected = this.state.stepList.find(p => p.id == this.state.selectedStep.id && p.type === this.state.selectedStep.type)
        lastSelected.toKeys = toKeys;
        lastSelected.ccKeys = ccKeys;
        lastSelected.byWho = byWho;
        lastSelected.activationId = activationId;
        lastSelected.activationUrl = activationUrl;
        lastSelected.toMessageType = toMessageType;
        lastSelected.ccMessageType = ccMessageType;
      }

      var _steps = [];

      console.log(this.state.stepList);
      this.state.stepList.forEach((element, index) => {

        let _messages = [];

        if (element.oldMessages) {
          _messages = element.oldMessages;
          _messages.forEach(m => {
            m.active = false;
          });
        }

        if (Boolean(element.ccKeys))
          element.ccKeys.forEach(e => {

            let obj = { userId: e, messageType: element.ccMessageType };
            let index = _messages.findIndex(p => p.userId === e);
            if (index === -1)
              _messages.push(obj);
            else
              _messages[index].active = true;

          });

        if (Boolean(element.toKeys))
          element.toKeys.forEach(e => {

            let obj = { userId: e, action: true, messageType: element.toMessageType };
            let index = _messages.findIndex(p => p.userId === e);
            if (index === -1)
              _messages.push(obj);
            else {
              _messages[index].active = true;
              _messages[index].action = true;
            }


          });

        var obj = { id: element.id, steporder: index, active: element.active, activityId: element.activityId, stepWorkflowId: element.stepWorkflowId, byWho: element.byWho, activationId: element.activationId, activationUrl: element.activationUrl, messages: _messages, stepType: element.type }


        _steps.push(obj);

      });

      const item = {
        id: this.state.workFlowId,
        workFlowName: this.state.workFlowName,
        departmentId: this.state.departmentId,
        sequence: this.state.sequence,
        every: this.state.every,
        period: this.state.period,
        periodDate: this.state.startDate,
        periodPoint: this.state.periodPoint,
        periodTime: this.state.periodTime,
        monthWeekStyle: this.state.monthWeekStyle,
        firstLast: this.state.firstLast,
        firstLastPoint: this.state.firstLastPoint,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        periods: _periods,
        executionArea: this.state.executionArea,
        steps: _steps
      }

      console.log(item);

      if (item.id === 0)
        var response = await handleRequest("POST", "/WorkFlow/add", item);
      else
        response = await handleRequest("POST", "/WorkFlow/update", item);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS-WORKFLOW-001") {
          this.changeButtonSave();
          this.getWorkFlow();
          this.showSuccess();
          this.cancelButtonClick();
        }
        if (response.data === "ERROR-WORKFLOW-001") {
          this.showError("An error was occured please try again later !");
        }
        if (response.data === "ERROR-UPDATE-001") {
          this.showError("This workflow has already been started steps!");
        }
      }
      else if (response.data === "ERROR-WORKFLOW-001") {
        this.showError("An error was occured please try again later !");
      }

    }
    else this.showError(errorMessage.toString());

  };

  rowExpansionTemplate(data) {
    console.log(data);
    return <WorkFlowExpand row={data} />;
  }

  convertdateR(rowData, column) {

    var d = null;
    try {
      if (column.field == 'approvaldate')
        d = rowData.approvaldate;
    } catch (error) {
      d = null;
    }
    if (d != null) {
      var dt = new Date(d);
      return (''
        + dt.getDate().toString().padStart(2, '0') + '/'
        + (dt.getMonth() + 1).toString().padStart(2, '0') + '/'
        + dt.getFullYear().toString().padStart(4, '0') + ' '
        + dt.getHours().toString().padStart(2, '0') + ':'
        + dt.getMinutes().toString().padStart(2, '0') + ':'
        + dt.getSeconds().toString().padStart(2, '0'));
    } else return '';
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleFlowchartHide = async () => {
    let node = this.state.selectedNodeKey2;
    this.setState({
      displayFlowchartDialog: false,
      workFlowName: "",
    });
    this.getWorkFlow();
  }

  actionTemplate(rowData, column) {
    return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
  };

  linkTemplate(rowData, column) {
    console.log(rowData);
    return <React.Fragment> <Link
      to={{
        pathname: "/WFStart",
        data: rowData
      }}>{rowData.workFlowName}</Link>   </React.Fragment>;
  };

  edit = async (row) => {
    console.log(row);

    let _periodTime = '';
    if (Boolean(row.periodTime) && row.periodTime.includes(':')) {
      _periodTime = moment(new Date());
      var time = row.periodTime.split(':');
      _periodTime.set({
        hours: time[0],
        minutes: time[1],

      });
      _periodTime.add(1, 'hours');
    }

    var _steps = [];

    if (Boolean(row.steps)) {
      row.steps.forEach(element => {

        let obj = { id: element.id, active: element.active, type: element.stepType, messages: element.messages, byWho: element.byWho, activationId: element.activationId, activationUrl: element.activationUrl }
        let _toKeys = [];
        let _ccKeys = [];
        let toMessageType = '';
        let ccMessageType = '';
        if (Boolean(element.messages) && element.messages.length > 0) {
          obj.oldMessages = element.messages;
          element.messages.forEach(e => {
            if (e.action) {
              _toKeys.push(e.userId);
              toMessageType = e.messageType;
            }
            else {
              _ccKeys.push(e.userId);
              ccMessageType = e.messageType;
            }
          });
        }

        obj.toKeys = _toKeys;
        obj.ccKeys = _ccKeys;
        obj.toMessageType = toMessageType;
        obj.ccMessageType = ccMessageType;

        if (element.stepType === 'activity') {

          obj.activityId = element.activityId;
          obj.label = this.durationTemplate(element.activity);
          if (Boolean(element.activity))
            obj.value = element.activity.activityName;
        }

        if (element.stepType === 'workflow') {

          obj.stepWorkflowId = element.stepWorkflowId;
          obj.label = '';
          if (Boolean(element.stepWf))
            obj.value = element.stepWf.workFlowName;


        }

        _steps.push(obj);

      });
    }

    console.log(_steps);

    this.setState({
      hidden: false,
      cancelhidden: false,
      tablehidden: false,
      createNewButton: "Cancel",
      addButton: "Update",
      displayLastButton: false,
      createNewButtonVariant: "p-button-danger",
      workFlowName: row.workFlowName,
      workFlowId: row.id,

      sequence: row.sequence.toString(),
      startDate: new Date(row.startDate),
      endDate: Boolean(row.endDate) ? new Date(row.endDate) : '',
      departmentId: row.departmentId,
      every: row.every.toString(),
      period: row.period,
      periodDate: new Date(row.periodDate),
      periodPoint: row.periodPoint.toString(),
      periodTime: Boolean(row.endDate) && Boolean(_periodTime) ? new Date(_periodTime.format()) : '', //new Date(),// _periodTime.format('HH:mm') ,// moment(row.periodTime).format('HH:mm'),
      monthWeekStyle: row.monthWeekStyle.toString(),
      firstLastPoint: row.firstLastPoint.toString(),
      executionArea: row.executionArea,

      stepList: _steps

    });

    document.getElementById('kt_scrolltop').click();


  };

  cancelButtonUpdate = async (row) => {
    this.setState({
      hidden: true,
      cancelhidden: true,
      tablehidden: false,
      createNewButton: "Create New",
      addButton: "Add",
      displayLastButton: true,
      createNewButtonVariant: "p-button-info",
      workFlowName: ""
    });
  };

  changeButtonSave = () => {
    this.setState({
      workFlowName: "",
      hidden: true,
      buttonName: "Add",
      buttonClassName: "p-button-success",
      workFlowId: 0,
      sequence: '0',
      startDate: null,
      endDate: null,
      departmentId: 0,
      every: 0,
      period: '',
      periodDate: null,
      periodPoint: '',
      periodTime: null,
      monthWeekStyle: '',
      firstLastPoint: '1'
    });


  };



  wfStepTemplate(wfs) {
    //var imageSource = 'showcase/resources/demo/images/car/' + car.brand + '.png';
    //<img src={imageSource} alt={car.brand} style={{ display: 'inline-block', margin: '2px 0 2px 2px', width: 48 }} />

    return (
      <div className="p-clearfix">
        <div style={{ fontSize: '14px', float: 'right', margin: '15px 5px 0 0' }}>{wfs.actiontype}</div>
      </div>
    );
  }


  deleteWorkflow = async (pId) => {
    const item = {
      id: pId
    }

    await handleRequest("POST", "/WorkFlow/delete", item);

    this.getWorkFlow();
    this.showSuccess();

  }

  deleteModal = (row) => {

    const that = this;

    confirm({
      title: 'Are you sure delete this workflow?',
      icon: <ExclamationCircleOutlined />,
      content: 'Selected workflow will be deleted !',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { that.deleteWorkflow(row.id) },
      onCancel() {
        console.log('Cancel');
      },
    });


  }

  addStep = () => {

    const list = this.state.stepList.filter(p => p.active === true);

    if (this.state.activityId > 0) {

      let isThere = list.find(p => p.type === 'activity' && p.activityId == this.state.activityId);

      if (Boolean(isThere)) {
        this.showError("This activity added before as a step !")
      }
      else {
        let fObj = Object.assign({}, this.state.activities.find(p => p.id == this.state.activityId));
        console.log(fObj);
        if (Boolean(fObj)) {
          fObj.label = this.durationTemplate(fObj);
          fObj.value = fObj.activityName;
          fObj.type = 'activity';
          fObj.id = 0;
          fObj.activityId = this.state.activityId;
          fObj.toKeys = [];
          fObj.ccKeys = [];
          fObj.byWho = '';
          fObj.activationId = '';
          fObj.activationUrl = '';
          fObj.toMessageType = '';
          fObj.ccMessageType = '';
          fObj.active = true;
          list.push(fObj);
        }
      }

    }

    if (this.state.stepWorkflowId > 0) {

      let isThere = list.find(p => p.type === 'workflow' && p.stepWorkflowId == this.state.stepWorkflowId);
      if (Boolean(isThere)) {
        this.showError("This workflow added before as a step !")
      }
      else {
        let fObj = Object.assign({}, this.state.workflowList.find(p => p.id == this.state.stepWorkflowId));

        if (Boolean(fObj)) {
          fObj.label = '';
          fObj.value = fObj.workFlowName;
          fObj.type = 'workflow';
          fObj.id = 0;
          fObj.stepWorkflowId = this.state.stepWorkflowId;
          fObj.active = true;
          list.push(fObj);

        }
      }

    }

    this.setState({
      stepList: list,
      selectedStep: Boolean(list) && list.length > 0 ? list[list.length - 1] : null
    })

  }

  durationTemplate(rowData) {
    const row = rowData;
    var result = "";
    if (Boolean(row))
      var pObj = row.duration;
    if (pObj !== undefined && pObj !== null) {
      if (pObj.year > 0)
        result = result + pObj.year + "Years ";
      if (pObj.month > 0)
        result = result + pObj.month + "Months ";
      if (pObj.day > 0)
        result = result + pObj.day + "Days ";
      if (pObj.hour > 0)
        result = result + pObj.hour + "Hours ";
      if (pObj.minute > 0)
        result = result + pObj.minute + "Minutes ";
      if (pObj.second > 0)
        result = result + pObj.second + "Seconds ";
    }

    return <> {result}</>;
  }

  clickStepValue = (step) => {

    console.log(step);
    const { toKeys, ccKeys, byWho, activationId, activationUrl, toMessageType, ccMessageType } = this.state;

    if (Boolean(this.state.selectedStep)) {
      if (step.type === 'workflow') {
        this.setState({
          toKeys: [],
          ccKeys: [],
          byWho: '',
          activationId: '',
          activationUrl: '',
          toMessageType: '',
          ccMessageType: ''
        })
      }
      else {

        this.setState(prevState => ({
          stepList: prevState.stepList.map(
            el => el.id === this.state.selectedStep.id && el.active === true && el.activityId === this.state.selectedStep.activityId ? {
              ...el,
              toKeys: toKeys,
              ccKeys: ccKeys,
              byWho: byWho,
              activationId: activationId.toString(),
              activationUrl: activationUrl,
              toMessageType: toMessageType,
              ccMessageType: ccMessageType
            } : el
          )
        }))



      }
    }

    this.setState({
      toKeys: step.toKeys,
      ccKeys: step.ccKeys,
      byWho: step.byWho,
      activationId: step.activationId.toString(),
      activationUrl: step.activationUrl,
      toMessageType: step.toMessageType,
      ccMessageType: step.ccMessageType
    })

    this.setState({ selectedStep: step });
  }

  onCheck = (checkedKeys, e) => {

  }

  onChangeTo = (value) => {

    this.setState({
      toKeys: value
    });

  }

  onChangeCc = (value) => {

    this.setState({
      ccKeys: value
    });

  }

  onChangeTreeSelect = (value, label, extra) => {
    console.log(value);
    console.log(label);
    console.log(extra);
    this.setState({ activationId: value });
  };

  removeStep = (step) => {

    if (Boolean(step) && step.type === 'activity')
      this.setState(prevState => ({
        stepList: prevState.stepList.map(
          el => el.id === step.id && el.activityId === step.activityId ? { ...el, active: false } : el
        )
      }))

    if (Boolean(step) && step.type === 'workflow')
      this.setState(prevState => ({
        stepList: prevState.stepList.map(
          el => el.id === step.id && el.stepWorkflowId === step.stepWorkflowId ? { ...el, active: false } : el
        )
      }))

  }

  moveUp = () => {

    const list = this.state.stepList;
    const step = this.state.selectedStep;

    if (Boolean(list) && list.length > 0) {

      if (step.type === 'activity') {
        var index = list.findIndex(p => p.id == step.id && p.activityId == step.activityId);
      }

      if (step.type === 'workflow') {
        index = list.findIndex(p => p.id == step.id && p.stepWorkflowId == step.stepWorkflowId)
      }
      console.log(index);
      console.log(list);
      if (index > 0) {

        let flag = list[index - 1];
        list[index - 1] = step;
        list[index] = flag;

      }

      this.setState({
        stepList: list
      });

    }

  }

  moveDown = () => {

    var list = this.state.stepList;
    const step = this.state.selectedStep;

    if (Boolean(list) && list.length > 0) {

      if (step.type === 'activity') {
        var index = list.findIndex(p => p.id == step.id && p.activityId == step.activityId);
      }

      if (step.type === 'workflow') {
        index = list.findIndex(p => p.id == step.id && p.stepWorkflowId == step.stepWorkflowId)
      }
      console.log(index);
      console.log(list);
      if (index < list.length - 1) {

        let flag = list[index + 1];
        list[index + 1] = step;
        list[index] = flag;

      }

      this.setState({
        stepList: list
      });

    }

  }

  render() {
    let actionHeader = <Button type="button" icon="pi pi-cog"></Button>

    let smClose = () => this.setState({ smShow: false });


    let dialogFlowchartFooter = <div className="ui-dialog-buttonpane p-clearfix">
      <Button>Save</Button>
    </div>;


    let optionsDepartment = this.state.departments.map((data) =>
      <option
        key={data.id}
        value={data.id}
      >
        {data.name}
      </option>
    );

    return (
      <div>

        <Modal
          size="sm"
          show={this.state.smShow}
          onHide={smClose}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              {this.state.messagesHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.messages}</Modal.Body>
        </Modal>

        <div style={{ backgroundColor: "white", padding: "10px" }}  >
          <Row >
            <Col sm="12" md={{ size: 2, offset: 10 }}>
              <Button id='btn-createnew' style={{ width: '100%' }} type="button" className={this.state.createNewButtonVariant} onClick={this.createButtonClick} label={this.state.createNewButton} />
            </Col>
          </Row>

        </div>
        <br />
        <Toast ref={(el) => this.toast = el}></Toast>
        <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.hidden} >
          <div className="p-grid p-fluid">


            <div className="p-col-12">
              <form>
                <Collapse
                  defaultActiveKey={['1']}
                  onChange={callback}
                  expandIconPosition={this.state.expandIconPosition}
                >


                  <Panel header="Description" key="1"  >
                    <div>
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Workflow Name
                   </Form.Label>
                        <Col sm="3">
                          <InputText onChange={(e) => this.setState({ workFlowName: e.target.value })} value={this.state.workFlowName} placeholder="Workflow Name" />
                        </Col>
                        <Col sm="3">
                        </Col>
                      </Form.Group>
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Custodian Department
                   </Form.Label>
                        <Col sm="3">
                          <Form.Control readOnly={this.state.id > 0} name="departmentId" value={this.state.departmentId}
                            onChange={(e) => this.setState({ departmentId: e.target.value })} as="select">
                            <option value="">
                              Select Department
                      </option>
                            {optionsDepartment}
                          </Form.Control>
                        </Col>

                      </Form.Group>
                      <Form.Row>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Frequency
                   </Form.Label>

                        <Form.Group as={Col} sm="3" controlId="formGriJointdesignRevno">
                          <Form.Control name="sequence" value={this.state.sequence}
                            onChange={(e) => this.setState({ sequence: e.target.value })} as="select">
                            <option value="0">Select Sequence</option>
                            <option value="1">Variable</option>
                            <option value="2">Periodical</option>
                          </Form.Control>
                        </Form.Group>

                      </Form.Row>

                      <Form.Row>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Execution Area
                   </Form.Label>

                        <Form.Group as={Col} controlId="formGridJointdesignRevno" sm="3">
                          <Form.Control name="monthWeekStyle" value={this.state.executionArea}
                            onChange={(e) => this.setState({ executionArea: e.target.value })} as="select">
                            <option value="">Select</option>
                            <option disabled={this.state.dataTableValue.length > 0 && this.state.dataTableValue.findIndex(p => p.executionArea == 1) >= 0} value="1">Insurance of Equipment</option>
                            <option disabled={this.state.dataTableValue.length > 0 && this.state.dataTableValue.findIndex(p => p.executionArea == 2) >= 0} value="2">Equipment Calibration</option>
                            <option disabled={this.state.dataTableValue.length > 0 && this.state.dataTableValue.findIndex(p => p.executionArea == 3) >= 0} value="3">Vehicle Licence</option>
                            <option disabled={this.state.dataTableValue.length > 0 && this.state.dataTableValue.findIndex(p => p.executionArea == 4) >= 0} value="4">Equipment Maintenance</option>
                          </Form.Control>
                        </Form.Group>

                      </Form.Row>

                      <Form.Row hidden={this.state.sequence !== "2"}>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Every
                   </Form.Label>
                        <Form.Group as={Col} controlId="formGridJointdesignRevno">
                          <Form.Control name="every" sm="3" value={this.state.every}
                            onChange={(e) => this.setState({ every: e.target.value })} as="select">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm="3" controlId="formGriJointdesignRevno">
                          <Form.Control name="period" value={this.state.period}
                            onChange={(e) => this.setState({ period: e.target.value })} as="select">
                            <option value="">Select</option>
                            <option value="year">Year</option>
                            <option value="month">Month</option>
                            <option value="week">Week</option>
                            <option value="day">Day</option>
                          </Form.Control>
                        </Form.Group>

                      </Form.Row>
                      {/* <Form.Row hidden={!(this.state.sequence === "2" && this.state.period === "year")}>
                  <Col sm="3">
                  </Col>
                  <Form.Label style={{ color: 'black' }} column sm="3">
                    Start Date and Time
                   </Form.Label>

                  <Form.Group as={Col} sm="3" >
                    <Calendar value={this.state.periodDate} onChange={(e) => this.setState({ periodDate: e.target.value })} showTime={true} showSeconds={true} dateFormat="dd/mm/yy" />
                  </Form.Group>

                </Form.Row> */}
                      <Form.Row hidden={!(this.state.sequence === "2" && this.state.period === "month")}>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Month Style
                   </Form.Label>

                        <Form.Group as={Col} controlId="formGridJointdesignRevno" sm="3">
                          <Form.Control name="monthWeekStyle" value={this.state.monthWeekStyle}
                            onChange={(e) => this.setState({ monthWeekStyle: e.target.value })} as="select">
                            <option value="">Select</option>
                            <option value="1">Day Number</option>
                            <option value="2">Specific Day</option>
                          </Form.Control>
                        </Form.Group>

                      </Form.Row>
                      <div hidden={!(this.state.sequence === "2" && this.state.period === "month" && this.state.monthWeekStyle === "1")}>
                        <Form.Row>
                          <Col sm="3">
                          </Col>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Start Day of Month and Time
                   </Form.Label>

                          <Form.Group as={Col} controlId="formGridJointdesignRevno" sm="3">
                            <Form.Control name="periodPoint" value={this.state.periodPoint}
                              onChange={(e) => this.setState({ periodPoint: e.target.value })} as="select">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} sm="3" controlId="formGriJointdesignRevno">
                            <Calendar value={this.state.periodTime} onChange={(e) => this.setState({ periodTime: e.target.value })} timeOnly={true} />
                          </Form.Group>

                        </Form.Row>
                      </div>
                      <div hidden={!(this.state.sequence === "2" && this.state.period === "month" && this.state.monthWeekStyle === "2")}>
                        <Form.Row>
                          <Col sm="3">
                          </Col>

                          <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                            <Form.Control name="flSelect" value={this.state.firstLast}
                              onChange={(e) => this.setState({ firstLast: e.target.value })} as="select">
                              <option value="">Select</option>
                              <option value="first">First</option>
                              <option value="last">Last</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                            <Form.Control name="firstLastPoint" value={this.state.firstLastPoint}
                              onChange={(e) => this.setState({ firstLastPoint: e.target.value })} as="select">
                              <option value="1">Monday</option>
                              <option value="2">Tuesday</option>
                              <option value="3">Wednesday</option>
                              <option value="4">Thursday</option>
                              <option value="5">Friday</option>
                              <option value="6">Saturday</option>
                              <option value="0">Sunday</option>
                              <option value="7">Working Day</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group as={Col} sm="3" controlId="formGriJointdesignRevno">
                            <Calendar value={this.state.periodTime} onChange={(e) => this.setState({ periodTime: e.target.value })} timeOnly={true} />
                          </Form.Group>
                        </Form.Row>
                      </div>
                      <Form.Row hidden={!(this.state.sequence === "2" && this.state.period === "week")}>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Week Style
                   </Form.Label>
                        <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                          <Form.Control name="weekStyle" value={this.state.monthWeekStyle}
                            onChange={(e) => this.setState({ monthWeekStyle: e.target.value })} as="select">
                            <option value="0">Select</option>
                            <option value="1">Week Days</option>
                            <option value="2">Spesific Day</option>
                          </Form.Control>
                        </Form.Group>

                      </Form.Row>
                      <Form.Row hidden={!(this.state.sequence === "2" && this.state.period === "week" && this.state.monthWeekStyle === "1")}>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Start Day and Time
                   </Form.Label>

                        <Form.Group as={Col} controlId="formGridJointdesignRevno">
                          <Form.Control sm="3" name="periodPoint" value={this.state.periodPoint}
                            onChange={(e) => this.setState({ periodPoint: e.target.value })} as="select">
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                            <option value="0">Sunday</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm="3" controlId="formGriJointdesignRevno">
                          <Calendar value={this.state.periodTime} onChange={(e) => this.setState({ periodTime: e.target.value })} timeOnly={true} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridJointdesignRevno">
                        </Form.Group>
                      </Form.Row>
                      <Form.Row hidden={!(this.state.sequence === "2" && this.state.period === "week" && this.state.monthWeekStyle === "2")}>
                        <Col sm="6">
                        </Col>
                        <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                          <Form.Control name="flSelect" value={this.state.firstLast}
                            onChange={(e) => this.setState({ firstLast: e.target.value })} as="select">
                            <option value="">Select</option>
                            <option value="first">First Working Day</option>
                            <option value="last">Last Working Day</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm="3" controlId="formGriJointdesignRevno">
                          <Calendar value={this.state.periodTime} onChange={(e) => this.setState({ periodTime: e.target.value })} timeOnly={true} />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row hidden={!(this.state.sequence === "2" && this.state.period === "day")}>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Start Hour
                   </Form.Label>
                        <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                          <Calendar value={this.state.periodTime} onChange={(e) => this.setState({ periodTime: e.target.value })} timeOnly={true} />
                        </Form.Group>
                      </Form.Row>

                      <Form.Row hidden={this.state.sequence !== "2"} >
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Start Time
                   </Form.Label>

                        <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                          <Calendar value={this.state.startDate} onChange={(e) => this.setState({ startDate: e.target.value })} showTime={true} showSeconds={true} dateFormat="dd/mm/yy" />
                        </Form.Group>

                      </Form.Row>

                      <Form.Row hidden={this.state.sequence !== "2"}>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          End Time
                   </Form.Label>
                        <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                          <Calendar value={this.state.endDate} onChange={(e) => this.setState({ endDate: e.target.value })} showTime={true} showSeconds={true} dateFormat="dd/mm/yy" />
                        </Form.Group>
                      </Form.Row>



                    </div>
                  </Panel>
                  <Panel header="Steps" key="2"  >
                    <div>
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Activity
                   </Form.Label>
                        <Col sm="3">
                          <Form.Control readOnly={this.state.id > 0} name="departmentId" value={this.state.activityId}
                            onChange={(e) => this.setState({ activityId: e.target.value, stepWorkflowId: 0 })} as="select">
                            <option value={0}>
                              Select Activity
                      </option>
                            {this.state.activities.map((opt, j) => (
                              <option value={opt.id}>{opt.activityName}</option>

                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm="2">
                          <Link
                            to={{
                              pathname: "/ActivityLog"
                            }}
                          >  <AntButton type="primary">Create Activity</AntButton>
                          </Link>
                        </Col>
                        <Col sm="1">
                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Workflow
                   </Form.Label>
                        <Col sm="3">
                          <Form.Control style={{ width: '100%' }} name="stepWorkflowId" value={this.state.stepWorkflowId}
                            as="select" onChange={(e) => {
                              e.persist();
                              let val = e.target.value;

                              this.setState({
                                stepWorkflowId: val, activityId: 0
                              })
                            }}  >
                            <option value={0}>
                              Select Workflow as Step
                          </option>
                            {this.state.workflowList.map((opt, j) => (
                              <option value={opt.id}>{opt.workFlowName}</option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm="1">
                          <AntButton style={{ width: '100%' }} onClick={this.addStep} type="primary">Add</AntButton>
                        </Col>
                        <Col sm="2">

                        </Col>
                      </Form.Group>

                      <Form.Group style={{ marginBottom: '1rem', marginTop: '5rem' }} as={Row} controlId="formGridDepartment">
                        <Col sm="3">
                          <Form.Row hidden={!Boolean(this.state.selectedStep)} >
                            <Col sm="8">

                            </Col>
                            <Col sm="4">
                              <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.moveUp} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />

                            </Col>
                            <Col sm="8">

                            </Col>
                            <Col sm="4">
                              <AntButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={this.moveDown} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                            </Col>
                          </Form.Row>
                        </Col>

                        <Col sm="6">
                          <Timeline mode='left'>

                            {this.state.stepList.filter(p => p.active === true).map((opt, j) => (
                              <Timeline.Item label={opt.label}> <a
                                style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                                onClick={() => this.clickStepValue(opt)}
                              >
                                {opt.value}
                                <AntButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.removeStep(opt)} shape="circle" icon={<CloseOutlined />} />
                              </a>  </Timeline.Item>
                            ))}

                          </Timeline>
                        </Col>

                      </Form.Group>
                      <div hidden={!(Boolean(this.state.selectedStep) && Boolean(this.state.selectedStep.type === 'activity'))}>

                        <Form.Group style={{ marginBottom: '1rem', marginTop: '5rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Step Name
                       </Form.Label>
                          {Boolean(this.state.selectedStep) && <Form.Label style={{ color: 'black', font: 'bold', fontSize: '16pt' }} column sm="3">
                            {this.state.selectedStep.value}
                          </Form.Label>}
                          <Col sm="3">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>
                          <Form.Label style={{ color: 'black' }} column sm="3">
                            By
                       </Form.Label>
                          <Col sm="3">
                            <Select style={{ width: '100%' }} placeholder="By" value={this.state.byWho}
                              onChange={(value) => {
                                this.setState({ byWho: value })
                              }}
                            >
                              {this.state.personels.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                              ))}
                            </Select>
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>


                        {/* <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                        <Col sm="3">
                        </Col>

                        <Form.Label style={{ color: 'black' }} column sm="3">
                          Activation
                   </Form.Label>
                        <Col sm="3">
                          <Tree2 style={{ width: '100%' }}
                            checkable
                            showLine={true}
                            onCheck={this.onCheck}
                            checkedKeys={this.state.checkedKeys}
                            treeData={this.state.nodes}
                            defaultExpandedKeys={['All']}
                          />
                        </Col>

                        <Col sm="3">
                        </Col>


                      </Form.Group> */}

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>

                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Activation
                   </Form.Label>
                          <Col sm="3">

                            <TreeSelect
                              style={{ width: '100%' }}
                              value={this.state.activationId}
                              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                              treeData={this.state.nodes}
                              placeholder="Please select"
                              treeDefaultExpandAll
                              onChange={this.onChangeTreeSelect}
                            />
                          </Col>

                          <Col sm="3">
                          </Col>


                        </Form.Group>


                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>

                          <Form.Label style={{ color: 'black' }} column sm="3">
                            To
                   </Form.Label>
                          <Col sm="3">
                            <Select mode="tags" style={{ width: '100%' }} placeholder="To" value={this.state.toKeys} onChange={this.onChangeTo}>
                              {this.state.personels.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                              ))}
                            </Select>
                          </Col>

                          <Col sm="3">
                          </Col>


                        </Form.Group>


                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>

                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Message
                   </Form.Label>
                          <Col sm="3">
                            <Select style={{ width: '100%' }} placeholder="Message Template" value={this.state.toMessageType}
                              onChange={(value) => {
                                this.setState({ toMessageType: value })
                              }}>

                              <Option key='Standart' value='Standart'>Standart</Option>

                            </Select>
                          </Col>

                          <Col sm="3">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>

                          <Form.Label style={{ color: 'black' }} column sm="3">
                            cc
                   </Form.Label>
                          <Col sm="3">
                            <Select mode="tags" style={{ width: '100%' }} placeholder="cc" value={this.state.ccKeys} onChange={this.onChangeCc}>
                              {this.state.personels.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                              ))}
                            </Select>
                          </Col>

                          <Col sm="3">
                          </Col>


                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                          <Col sm="3">
                          </Col>

                          <Form.Label style={{ color: 'black' }} column sm="3">
                            Message
                   </Form.Label>
                          <Col sm="3">
                            <Select style={{ width: '100%' }} placeholder="Message Template" value={this.state.ccMessageType}
                              onChange={(value) => {
                                this.setState({ ccMessageType: value })
                              }}>
                              <Option key='Standart' value='Standart'>Standart</Option>

                            </Select>
                          </Col>

                          <Col sm="3">
                          </Col>


                        </Form.Group>
                      </div>
                      <Form.Row>
                        <Col sm="3">
                        </Col>
                        <Form.Label style={{ color: 'black' }} column sm="3">

                        </Form.Label>
                        <Form.Group as={Col} sm="3" controlId="formGridJointdesignRevno">
                          <Button id='save-workFlow' type="button" className={this.state.buttonClassName} onClick={this.saveWorkFlow} label={this.state.addButton} />
                        </Form.Group>

                      </Form.Row>

                    </div>
                  </Panel>

                </Collapse>

              </form></div>

          </div>

          <Dialog visible={this.state.displayFlowchartDialog} style={{ width: "700px", height: "700px" }} header="{{this.state.workFlowName}} Flowchart Description" modal={true} footer={dialogFlowchartFooter} onHide={this.handleFlowchartHide}>
            {
              <Card style={{ width: "700px", height: "700px" }}>
                <Toast ref={(el) => this.toast = el} />
                <Card.Body>
                  <Container>
                    <PickList source={this.state.WorkFlowStepAll} target={this.state.WorkFlowStepAll} itemTemplate={this.wfStepTemplate}
                      onChange={(e) => this.setState({ source: e.source, target: e.target })} sourceHeader="Available" targetHeader="Seleced" responsive={true} />

                  </Container>
                </Card.Body>
              </Card>

            }
          </Dialog>

        </div>
        <br />
        {/* <WFStart stepId="1" workFlowName="wfn" /> */}
        {/* <br /> */}
        <div hidden={this.state.tablehidden} className="col-md-12" style={{ marginTop: '10px' }}>
          <div className="datatable-doc-demo" style={{ backgroundColor: 'white' }}  >
            <div style={{ marginTop: '30px', marginBottom: '30px' }} className="content-section implementation ">
              <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue}
                style={{ minWidth: '1000px' }} virtualScroll={true} id="itptable"
                className="p-datatable-customers" dataKey="key" rowHover globalFilter={this.state.globalFilter}
                expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                rowExpansionTemplate={this.rowExpansionTemplate}
                paginator rows={10} emptyMessage="No customers found" rowsPerPageOptions={[10, 25, 50]}>
                <Column selectionMode="single" style={{ width: '3em' }} expander={true} />
                <Column header="" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                <Column field="index" style={{ textAlign: 'center' }} sortable filter header="S/N" />
                <Column field="workFlowName" header="Workflow Name" body={this.linkTemplate} style={{ textAlign: 'center' }} sortable filter />

                <Column field="department.departmentName" style={{ textAlign: 'center' }} sortable filter header="Custodian Department" />
                {/* <Column field="every" style={{ textAlign: 'center' }} sortable filter header="Every" /> */}
                <Column field="period" style={{ textAlign: 'center' }} sortable filter header="Period" />
                <Column field="startDateF" style={{ textAlign: 'center' }} sortable filter header=" Start up Date" />
                <Column field="endDateF" style={{ textAlign: 'center' }} sortable filter header="End Date" />
                <Column field="status" style={{ textAlign: 'center' }} sortable filter header="Status" />
              </DataTable>

            </div>
          </div>
        </div>
      </div>


    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WorkFlow)
