/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row as PrimeRow } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Card } from "primereact/card";
import { Chart } from 'primereact/chart';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Button, Modal, Col, Row, Form } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import { Row as AntRow, Col as AntCol, DatePicker, Button as AntButton, Select, Cascader } from 'antd';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import "./css/ShipmentLog.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

function getDayLabels(moments) {
    const arr = moments;
    var first = arr[0].format();
    first = moment(first);
    var result = [];
    while (first.isSameOrBefore(arr[1])) {
        var obj = {
            fuelRefillTime: first.format('DD/MM/YYYY HH:mm'),
            year: first.get('year'),
            month: first.get('month') + 1,
            day: first.date()
        }
        result.push(obj);
        first = first.add(1, 'days');
    }
    return result;
}

export class FuelLog extends Component {

    constructor() {
        super();
        this.state = {
            startDate: null,
            endDate: null,
            selectedConfirmation: null,
            globalFilter: null,
            showGlobalFilter: null,
            modalPhotoShow: false,
            fuelLogsStatic: [],
            fuelLogs: [],
            imageUrl: "",
            totalFuel: 0,
            totalPrice: 0,
            averageKmLt: 0,
            totalKm: 0,

            duration: "day",
            customers: [],
            customerOptions: [],
            selectedCustomers: [],
            projects: [],
            projectOptions: [],
            selectedProjects: [],
            products: [],
            selectedProducts: [],
            chartType1: "bar",
            assignments: [],
            chartData1: {},
            moments1: [],
            dateStrings1: [],
            values: [],
            selectedItems: [],
            nodes: [],
            equipmentLogs: [],
            equipments: [],
            equipmentOptions: [],
            selectedEquipments: [],
            filled: true,
            graphsHide: true,
            graphsButtonName: "Show Graphs",
            graphsButtonClass: "p-button-primary"
        };

        this.InvoiceNoBodyTemplate = this.InvoiceNoBodyTemplate.bind(this);
        this.KMBodyTemplate = this.KMBodyTemplate.bind(this);
        this.averageKmLiter = this.averageKmLiter.bind(this);
        this.indexTemplate = this.indexTemplate.bind(this);

        this.aofTemplate = this.aofTemplate.bind(this);
        this.upTemplate = this.upTemplate.bind(this);
        this.pTemplate = this.pTemplate.bind(this);
    }

    componentDidMount = async () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var dd1 = String(today.getDate() + 1).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            startDate: today,
            endDate: today
        });

        const response = await handleRequest("GET", "/FuelRefill/getAll");

        if (response.data.length === 0) {
            this.setState({
                fuelLogs: [],
                fuelLogsStatic: []
            });
        } else {
            this.setState({
                fuelLogs: response.data,
                fuelLogsStatic: response.data
            });
        }

        if (response.data.length !== 0) {

            var flagTotalPrice = 0;
            var flagTotalFuel = 0;

            for (let index = 0; index < response.data.length; index++) {
                flagTotalFuel += parseFloat(response.data[index].amountOfFuel);
                flagTotalPrice += parseFloat(response.data[index].price);
            }

            this.setState({
                totalFuel: flagTotalFuel,
                totalPrice: flagTotalPrice
            });

            var driverFuelData = response.data;
            let drivers = [];

            for (let index = 0; index < driverFuelData.length; index++) {
                if (!(drivers.includes(driverFuelData[index].staffId))) {
                    drivers.push(driverFuelData[index].staffId);
                }
            }

            var driverDatas = new Array(drivers.length);

            for (let index = 0; index < drivers.length; index++) {
                driverDatas[index] = [];
            }

            for (let index = 0; index < drivers.length; index++) {
                for (let index1 = 0; index1 < driverFuelData.length; index1++) {
                    if (drivers[index] === driverFuelData[index1].staffId) {
                        driverDatas[index].push(driverFuelData[index1]);
                    }
                }
            }

            var totalKm = 0;

            for (let index = 0; index < drivers.length; index++) {
                totalKm += (driverDatas[index][driverDatas[index].length - 1].kmOfTruck - driverDatas[index][0].kmOfTruck)
            }

            this.setState({
                totalKm: totalKm
            })
        }

        this.drawChart();

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (responseCustomer.data.length > 0) {
            const columns2 = responseCustomer.data;

            const options2 = columns2.map(function (row) {
                return { label: row.fullName.toString(), value: row.id }
            })

            this.setState({
                customers: responseCustomer.data,
                customerOptions: options2
            });
        }

        const responseAssignment = await handleRequest("GET", "/Assignment/getAll");

        if (responseAssignment.data.length > 0) {
            this.setState({
                assignments: responseAssignment.data,
            });
        }

        const responseEquipment = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipment.data.length !== 0) {
            this.setState({
                equipmentLogs: responseEquipment.data
            });

            var list = responseEquipment.data;
            var _truckList = list.filter(p => p.equipmentType != null && p.equipmentType.equipmentTypeName === "Truck");

            const options = _truckList.map(function (row) {
                return { label: row.equipmentDescription.toString() + " - " + row.equipmentCode.toString(), value: row.id }
            });

            this.setState({
                trucks: options
            });
        }

        const response3 = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response3.length === 0 || response3.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data
            });
        }
    }

    renderHeader() {
        let displayGlobalFilter = this.state.showGlobalFilter;
        return (
            <div style={{ border: '0 none', padding: '12px', textAlign: 'left', fontSize: '20px' }}>
                Fuel Logs
                <div style={{ float: 'right', width: '200px', display: displayGlobalFilter }} className="p-datatable-globalfilter-container">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                </div>
            </div>
        );
    }

    InvoiceNoBodyTemplate(rowData) {
        if (rowData.invoicePhotoPath !== "" && rowData.invoicePhotoPath !== null) {
            return (
                <div
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                    onClick={() =>
                        this.setState({
                            imageUrl: `${API_BASE}/FuelRefill/download/` + rowData.id,
                            modalPhotoShow: true,
                        })
                    }
                >
                    Invoice Photo
                </div>
            );
        }

        //return <Button type="button" onClick={() => this.setState({ imageUrl: `${API_BASE}/FuelRefill/download/` + rowData.id, modalPhotoShow: true })}>Photo</Button>
    }

    KMBodyTemplate(rowData) {
        if (rowData.meterPhotoPath !== "" && rowData.meterPhotoPath !== null) {
            return (
                <div
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                    onClick={() =>
                        this.setState({
                            imageUrl: `${API_BASE}/FuelRefill/download2/` + rowData.id,
                            modalPhotoShow: true,
                        })
                    }
                >
                    {rowData.kmOfTruck}
                </div>
            );
        } else {
            if (rowData.kmOfTruck === null)
                return "";
            else
                return rowData.kmOfTruck;
        }

        //return <Button type="button" onClick={() => this.setState({ imageUrl: `${API_BASE}/FuelRefill/download2/` + rowData.id, modalPhotoShow: true })}>Photo</Button>
    }

    nameTemplate(rowData, column) {
        return rowData.staff.name + " " + rowData.staff.lastName;
    }

    indexTemplate(rowData, column) {
        return this.state.fuelLogs.findIndex(obj => obj.id === rowData.id) + 1;
    }

    averageKmLiter(rowData, column) {

        var average = "N/A";
        var hasSecondValue = 0;
        var otherKmValues = [];
        var usingFuelLogs = this.state.fuelLogsStatic;

        if (usingFuelLogs.length !== 0) {
            if (usingFuelLogs.length === 1) {
                average = "N/A";
            } else {
                for (let index = 0; index < usingFuelLogs.length; index++) {

                    if ((rowData.staffId === usingFuelLogs[index].staffId) && (rowData.id === usingFuelLogs[index].id)) {

                        for (let index1 = (index + 1); index1 < usingFuelLogs.length; index1++) {

                            if ((rowData.staffId === usingFuelLogs[index1].staffId) && (rowData.id < usingFuelLogs[index1].id)) {
                                otherKmValues.push(usingFuelLogs[index1].kmOfTruck);
                                hasSecondValue = 1;
                            }
                        }

                    }
                }

                if (hasSecondValue === 1) {
                    average = (parseFloat(otherKmValues[0]) - parseFloat(rowData.kmOfTruck)) / parseFloat(rowData.amountOfFuel);
                } else {
                    average = "N/A"
                }
            }
        }

        return Number.parseFloat(average).toFixed(2);;
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow
        });
    }

    onChange3 = async (date, dateString) => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString[0] !== "" || dateString[1] !== "") {
            this.setState({
                startDate: dateString[0],
                endDate: dateString[1]
            });
        } else {
            this.setState({
                startDate: today,
                endDate: today
            });
        }

        const response = await handleRequest("GET", "/FuelRefill/getAll");
        var flagFuelRefill = [];
        var flagFuelRefillRange = [];

        if (response.data.length === 0) {
            flagFuelRefill = [];
        } else {
            flagFuelRefill = response.data;
        }

        if (flagFuelRefill.length !== 0) {
            for (let index = 0; index < flagFuelRefill.length; index++) {

                let flagDate = flagFuelRefill[index].fuelRefillTime.split(" ")[0];
                const [day, month, year] = flagDate.split("/");
                let flagDate2 = new Date(year, month - 1, day);

                if (date === null) {
                    var flagToday = new Date();
                    var flagdd = String(flagToday.getDate()).padStart(2, '0');
                    var flagmm = String(flagToday.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var flagyyyy = flagToday.getFullYear();

                    flagToday = flagdd + '/' + flagmm + '/' + flagyyyy;

                    const [day, month, year] = flagToday.split("/");
                    let flagToday2 = new Date(year, month - 1, day);

                    if (flagDate2 >= flagToday2 && flagDate2 <= flagToday2) {
                        flagFuelRefillRange.push(flagFuelRefill[index]);
                    }
                } else if (flagDate2 >= date[0] && flagDate2 <= date[1]) {
                    flagFuelRefillRange.push(flagFuelRefill[index]);
                }
            }

            var flagTotalPrice = 0;
            var flagTotalFuel = 0;

            for (let index = 0; index < flagFuelRefillRange.length; index++) {
                flagTotalFuel += parseFloat(flagFuelRefillRange[index].amountOfFuel);
                flagTotalPrice += parseFloat(flagFuelRefillRange[index].price);
            }

            this.setState({
                fuelLogs: flagFuelRefillRange,
                totalFuel: flagTotalFuel,
                totalPrice: flagTotalPrice
            });

            var driverFuelData = flagFuelRefillRange;
            let drivers = [];

            if (flagFuelRefillRange.length !== 0) {

                for (let index = 0; index < driverFuelData.length; index++) {
                    if (!(drivers.includes(driverFuelData[index].staffId))) {
                        drivers.push(driverFuelData[index].staffId);
                    }
                }

                var driverDatas = new Array(drivers.length);

                for (let index = 0; index < drivers.length; index++) {
                    driverDatas[index] = [];
                }

                for (let index = 0; index < drivers.length; index++) {
                    for (let index1 = 0; index1 < driverFuelData.length; index1++) {
                        if (drivers[index] === driverFuelData[index1].staffId) {
                            driverDatas[index].push(driverFuelData[index1]);
                        }
                    }
                }

                var totalKm = 0;

                for (let index = 0; index < drivers.length; index++) {
                    totalKm += (driverDatas[index][driverDatas[index].length - 1].kmOfTruck - driverDatas[index][0].kmOfTruck)
                }

                console.log(totalKm);

                this.setState({
                    totalKm: totalKm
                })
            } else {
                this.setState({
                    totalKm: 0
                })
            }
        }

    }

    disabledDate(current) {
        return current > moment().endOf('day');
    }

    aofTemplate(rowData, column) {
        return Number.parseFloat(rowData.amountOfFuel).toFixed(2);
    }

    upTemplate(rowData, column) {
        return Number.parseFloat(rowData.unitPrice).toFixed(2);
    }

    pTemplate(rowData, column) {
        return Number.parseFloat(rowData.price).toFixed(2);
    }

    showGraphs = () => {
        //window.location.href = '/FuelGraph';
        this.setState({
            graphsHide: !this.state.graphsHide
        });
        if (this.state.graphsHide === true) {
            this.setState({
                graphsButtonName: "Hide Graphs",
                graphsButtonClass: "p-button-danger"
            });
        } else {
            this.setState({
                graphsButtonName: "Show Graphs",
                graphsButtonClass: "p-button-primary"
            });
        }
    }

    getWeekLabels = (moments) => {
        var first = moments[0].format();
        first = moment(first);
        var result = [];

        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.year() + '/ W' + first.week(),
                year: first.get('year'),
                week: first.week()
            }
            result.push(obj);
            first = first.add(1, 'weeks')
        }

        return result;
    }

    getMonthLabels = (moments) => {
        var first = moments[0].format();
        first = moment(first);
        var result = [];
        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.format("YYYY-MM"),
                year: first.get('year'),
                month: first.month() + 1
            }
            result.push(obj);
            first = first.add(1, 'months')
            console.log(first);
        }
        return result;
    }

    getYearLabels = (moments) => {
        var first = moments[0].format();
        first = moment(first);
        var result = [];
        while (first.isSameOrBefore(moments[1])) {
            var obj = {
                period: first.format("YYYY"),
                year: first.get('year'),

            }
            result.push(obj);
            first = first.add(1, 'years')
            console.log(first);
        }
        return result;
    }

    drawChart = async () => {
        const colorMap = [
            '#E3F4BF',
            '#BEF7C8',
            '#86E6C8',
            '#36CFC9',
            '#209BDD',
            '#1581E6',
            '#0860BF'
        ];

        const chart1Duration = this.state.duration;
        var _chartLabels = [];
        var _datasets = [];

        var _customers = [];
        var _projects = [];
        var _equipments = [];
        var _labels = [];

        switch (chart1Duration) {
            case 'day':
                const responseDay1 = await handleRequest("GET", "/FuelRefill/getFuelRefillDay");

                if (responseDay1 !== null && responseDay1.data !== null && responseDay1.data.customers !== undefined) {

                    var rData = responseDay1.data;

                    if (this.state.selectedCustomers.length > 0) {
                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {
                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));
                    }
                    else _projects = rData.projects;

                    if (this.state.selectedEquipments.length > 0) {
                        _equipments = rData.equipments.filter(p => this.state.selectedEquipments.includes(p.equipmentLogId));
                    }
                    else _equipments = rData.equipments;

                    const moments = this.state.moments1;

                    if (moments !== null && moments !== undefined && moments.length > 0) {
                        _labels = getDayLabels(moments);
                    }
                    else
                        _labels = responseDay1.data.labels;

                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _equipments.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'equipmentLogId' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'equipmentLogId',
                                id: element.equipmentLogId,
                                label: element.equipmentName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    if (_labels.length > 0) {
                        _labels.forEach(element => {
                            _chartLabels.push(element.fuelRefillTime.split(' ')[0]);
                            _datasets.forEach(e => {
                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (i >= 0)
                                        e.data.push(parseFloat(_customers[i].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_projects[j].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'equipmentLogId') {
                                    var j = _equipments.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_equipments[j].amountOfFuel));
                                    else e.data.push(0);
                                }
                            });
                        });
                    }

                    const customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({
                        chartData1: customData
                    });
                }
                break;
            case 'week':

                const responseWeek = await handleRequest("GET", "/FuelRefill/getFuelRefillWeek");

                if (responseWeek.data !== null) {
                    rData = responseWeek.data;

                    if (this.state.selectedCustomers.length > 0) {
                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {
                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));
                    }
                    else _projects = rData.projects;

                    if (this.state.selectedEquipments.length > 0) {
                        _equipments = rData.equipments.filter(p => this.state.selectedEquipments.includes(p.equipmentLogId));
                    }
                    else _equipments = rData.equipments;

                    const moments = this.state.moments1;

                    if (moments !== null && moments !== undefined && moments.length > 0) {
                        _labels = this.getWeekLabels(moments);
                    }

                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _equipments.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'equipmentLogId' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'equipmentLogId',
                                id: element.equipmentLogId,
                                label: element.equipmentName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    if (_labels.length > 0) {
                        _labels.forEach(element => {
                            _chartLabels.push(element.period);
                            _datasets.forEach(e => {
                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year && p.week === element.week);
                                    if (i >= 0)
                                        e.data.push(parseFloat(_customers[i].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year && p.week === element.week);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_projects[j].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'equipmentLogId') {
                                    var j = _equipments.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_equipments[j].amountOfFuel));
                                    else e.data.push(0);
                                }
                            });
                        });
                    }

                    const customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({
                        chartData1: customData
                    });
                }
                break;
            case 'month':

                const responseMonth = await handleRequest("GET", "/FuelRefill/getFuelRefillMonth");

                if (responseMonth.data !== null) {
                    rData = responseMonth.data;

                    if (this.state.selectedCustomers.length > 0) {
                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                        console.log(_customers);
                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {
                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));
                    }
                    else _projects = rData.projects;

                    if (this.state.selectedEquipments.length > 0) {
                        _equipments = rData.equipments.filter(p => this.state.selectedEquipments.includes(p.equipmentLogId));
                    }
                    else _equipments = rData.equipments;

                    const moments = this.state.moments1;

                    if (moments !== null && moments !== undefined && moments.length > 0) {
                        _labels = this.getMonthLabels(moments);
                    }

                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _equipments.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'equipmentLogId' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'equipmentLogId',
                                id: element.equipmentLogId,
                                label: element.equipmentName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    if (_labels.length > 0) {
                        _labels.forEach(element => {
                            _chartLabels.push(element.period);
                            _datasets.forEach(e => {
                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year && p.month === element.month);
                                    if (i >= 0)
                                        e.data.push(parseFloat(_customers[i].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year && p.month === element.month);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_projects[j].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'equipmentLogId') {
                                    var j = _equipments.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_equipments[j].amountOfFuel));
                                    else e.data.push(0);
                                }
                            });
                        });
                    }

                    const customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({
                        chartData1: customData
                    });
                }
                break;
            case 'year':
                const responseYear = await handleRequest("GET", "/FuelRefill/getFuelRefillYear");
                if (responseYear.data !== null) {
                    rData = responseYear.data;

                    if (this.state.selectedCustomers.length > 0) {
                        _customers = rData.customers.filter(p => this.state.selectedCustomers.includes(p.customer));
                    }
                    else _customers = rData.customers;

                    if (this.state.selectedProjects.length > 0) {
                        _projects = rData.projects.filter(p => this.state.selectedProjects.includes(p.project));
                    }
                    else _projects = rData.projects;

                    if (this.state.selectedEquipments.length > 0) {
                        _equipments = rData.equipments.filter(p => this.state.selectedEquipments.includes(p.equipmentLogId));
                    }
                    else _equipments = rData.equipments;

                    const moments = this.state.moments1;

                    if (moments !== null && moments !== undefined && moments.length > 0) {
                        _labels = this.getYearLabels(moments);
                    }

                    _customers.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'customer' && p.id === element.customer);
                        if (index === -1) {
                            var obj = {
                                customType: 'customer',
                                id: element.customer,
                                label: element.customerName,
                                fill: this.state.filled,
                                borderColor: colorMap[6],
                                backgroundColor: colorMap[6],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _projects.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'project' && p.id === element.project);
                        if (index === -1) {
                            var obj = {
                                customType: 'project',
                                id: element.project,
                                label: element.projectName,
                                fill: this.state.filled,
                                borderColor: colorMap[3],
                                backgroundColor: colorMap[3],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    _equipments.forEach(element => {
                        var index = _datasets.findIndex(p => p.customType === 'equipmentLogId' && p.id === element.equipmentLogId);
                        if (index === -1) {
                            var obj = {
                                customType: 'equipmentLogId',
                                id: element.equipmentLogId,
                                label: element.equipmentName,
                                fill: this.state.filled,
                                borderColor: colorMap[0],
                                backgroundColor: colorMap[0],
                                data: []
                            }
                            _datasets.push(obj);
                        }
                    });

                    if (_labels.length > 0) {
                        _labels.forEach(element => {
                            _chartLabels.push(element.period);
                            _datasets.forEach(e => {
                                if (e.customType === 'customer') {
                                    var i = _customers.findIndex(p => p.customer === e.id && p.year === element.year);
                                    if (i >= 0)
                                        e.data.push(parseFloat(_customers[i].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'project') {
                                    var j = _projects.findIndex(p => p.project === e.id && p.year === element.year);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_projects[j].amountOfFuel));
                                    else e.data.push(0);
                                }

                                if (e.customType === 'equipmentLogId') {
                                    var j = _equipments.findIndex(p => p.equipmentLogId === e.id && p.year === element.year && p.month === element.month && p.day === element.day);
                                    if (j >= 0)
                                        e.data.push(parseFloat(_equipments[j].amountOfFuel));
                                    else e.data.push(0);
                                }
                            });
                        });
                    }

                    const customData = { labels: _chartLabels, datasets: _datasets }

                    this.setState({
                        chartData1: customData
                    });
                }
                break;
            default:
                break;
        }
    }

    onCustomerChange = (e) => {
        const _selectedCustomers = e.value;
        var _projectList = [];
        var customerList = this.state.customers;

        _selectedCustomers.forEach(element => {
            var index = customerList.findIndex(p => p.id == element)

            if (index >= 0 && customerList[index].projects !== null && customerList[index].projects !== undefined && customerList[index].projects.length > 0) {
                customerList[index].projects.forEach(element => {
                    _projectList.push(element);
                });
            }
        });

        var options = _projectList.map(function (row, index) {
            return { label: row.projectName.toString(), value: row.id }
        })

        this.setState({
            selectedCustomers: _selectedCustomers,
            projects: _projectList === null ? [] : _projectList,
            projectOptions: options === null ? [] : options
        })

        if (_selectedCustomers == null || _selectedCustomers.length === 0) {
            this.setState({
                selectedProjects: []
            });
        }

        setTimeout(() => {
            this.drawChart();
        }, 500);
    }

    onProjectChange = (e) => {
        const _selectesProjects = e.value;

        this.setState({
            selectedProjects: _selectesProjects
        });

        setTimeout(() => {
            this.drawChart();
        }, 500);
    }

    onChangeCascader = (value, data) => {
        let equipmentTypeIdFlag = value[value.length - 1];

        if (this.state.equipmentLogs.length === 0) {
            this.setState({
                equipments: [],
                equipmentOptions: []
            });
        } else if (this.state.equipmentLogs.length > 0) {

            let equipmentLogs = this.state.equipmentLogs;
            let options = [];

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options.push({ label: equipmentLogs[index].equipmentDescription.toString(), value: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipments: equipmentLogs,
                equipmentOptions: options
            });
        }

        this.setState({
            values: value,
            selectedItems: data
        })
    }

    onEquipmentChange = (e) => {
        const _selectedEquipments = e.value;

        this.setState({
            selectedEquipments: _selectedEquipments
        })

        this.drawChart();
    }

    onChange2 = (value, dateString) => {

        // console.log(value);

        // if (value === null) {
        //     var today = new Date();
        //     var tomorrow = new Date();
        //     tomorrow.setDate(tomorrow.getDate() + 1);
        //     this.setState({
        //         startDate: today,
        //         endDate: tomorrow
        //     });
        // } else {
        //     this.setState({
        //         startDate: value[0]._d,
        //         endDate: value[1]._d
        //     });
        // }

        this.setState({
            moments1: value,
            dateStrings1: dateString
        });

        this.drawChart();
    }

    render() {
        const header = this.renderHeader();
        let totalTicketFooter = this.state.fuelLogs.length + " ea.s of tickets";
        let totalAmountOfFuel = this.state.totalFuel.toFixed(2) + " lt";
        let totalPrice = this.state.totalPrice.toFixed(2) + " SR";
        // let averageKmLt = 0;
        // if (this.state.totalKm === 0) {
        //     averageKmLt = 0;
        // } else {
        //     averageKmLt = parseFloat(this.state.totalKm) / parseFloat(this.state.totalFuel);
        // }

        let footerGroup = <ColumnGroup>
            <PrimeRow>
                <Column footer={totalTicketFooter} colSpan={9} />
                {/*<Column footer={averageKmLt.toFixed(2)} />*/}
                <Column footer={totalAmountOfFuel} />
                <Column />
                <Column footer={totalPrice} />
            </PrimeRow>
        </ColumnGroup>;
        return (
            <div>
                <div>
                        <Modal
                            show={this.state.modalPhotoShow}
                            onHide={this.showOrHidePhotoModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {(this.state.imageUrl !== "") ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : <p>Photo has not been uploaded.</p>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="info" onClick={this.showOrHidePhotoModal}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                        <div>
                            <Card>
                                <div style={{ marginBottom: '2rem', marginTop: '1rem' }}>
                                    <Row>
                                        <Col xs={2} style={{ textAlign: 'right' }}><Form.Label style={{ color: 'black', marginTop: '0.5rem', fontWeight: 'bold' }}>Date:</Form.Label></Col>
                                        <Col xs={2}><RangePicker value={[moment(this.state.startDate, 'DD/MM/YYYY'), moment(this.state.endDate, 'DD/MM/YYYY')]}
                                            disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChange3} /></Col>
                                        <Col xs={2}></Col>
                                        <Col xs={2}>
                                            <PrimeButton style={{ width: '100%' }} onClick={this.showGraphs} label={this.state.graphsButtonName} className={this.state.graphsButtonClass} />
                                        </Col>
                                        <Col xs={4}></Col>
                                    </Row>
                                </div>
                                <div className="content-section implementation">
                                    <DataTable ref={(el) => this.dt = el} responsive={true} style={{ textAlign: 'center' }} editable={true} value={this.state.fuelLogs}
                                        globalFilter={this.state.globalFilter} paginator={true} emptyMessage="No fuel log found" rowsPerPageOptions={[5, 10, 20]} rows={10}
                                        footerColumnGroup={footerGroup}>
                                        <Column header="S/N" body={this.indexTemplate} sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column field="fuelRefillTime" header="Date" sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column field="customerProject.projectName" header="Project" sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column field="equipmentLog.equipmentDescription" header="Truck" sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column header="Driver" body={this.nameTemplate} sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column header="Invoice" body={this.InvoiceNoBodyTemplate} filter={true} filterMatchMode="contains" />
                                        <Column field="sourceOfFuelCode.name" header="Source of Fuel" sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column header="Km" body={this.KMBodyTemplate} filter={true} filterMatchMode="contains" />
                                        <Column header="Km/Lt" body={this.averageKmLiter} sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column body={this.aofTemplate} header="Amount of Fuel" sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column body={this.upTemplate} header="Unit Price (SR)" sortable={true} filter={true} filterMatchMode="contains" />
                                        <Column body={this.pTemplate} header="Price (SR)" sortable={true} filter={true} filterMatchMode="contains" />
                                    </DataTable>
                                </div>
                            </Card>
                        </div>
                        <hr />
                        <div hidden={this.state.graphsHide}>
                            <Card>
                                <AntRow>
                                    <AntCol span={8}><div style={{ color: 'black', fontWeight: 'bold' }}> Period Selection:</div></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', fontWeight: 'bold' }}> Customer:</div></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', fontWeight: 'bold' }}> Project:</div></AntCol>
                                    <AntCol span={4}><div style={{ color: 'black', fontWeight: 'bold' }}> Equipment Type:</div></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', fontWeight: 'bold' }}> Equipment:</div></AntCol>
                                    <AntCol span={3}><div style={{ color: 'black', fontWeight: 'bold' }}> Chart Selection:</div></AntCol>
                                </AntRow>
                                <AntRow style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                                    <AntCol span={2}>
                                        <Select defaultValue="day" size="large" style={{ width: '100%' }}
                                            onChange={(value) => {
                                                this.setState({
                                                    duration: value
                                                });
                                            }}>
                                            <Option value="day">Day</Option>
                                            <Option value="week">Week</Option>
                                            <Option value="month">Month</Option>
                                            <Option value="year">Year</Option>
                                        </Select>

                                    </AntCol>
                                    <AntCol span={6}>
                                        <RangePicker size="large" picker={this.state.duration} placeholder={["From", "To"]} onChange={this.onChange2} />
                                    </AntCol>
                                    <AntCol span={3}>
                                        <MultiSelect value={this.state.selectedCustomers} options={this.state.customerOptions} onChange={this.onCustomerChange}
                                            style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />
                                    </AntCol>
                                    <AntCol span={3}>
                                        <MultiSelect value={this.state.selectedProjects} options={this.state.projectOptions} onChange={this.onProjectChange}
                                            style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />
                                    </AntCol>
                                    <AntCol span={4}>
                                        <Cascader size="large" defaultValue={this.state.values} options={this.state.nodes} onChange={this.onChangeCascader} changeOnSelect />
                                    </AntCol>
                                    <AntCol span={3}>
                                        <MultiSelect value={this.state.selectedEquipments} options={this.state.equipmentOptions} onChange={this.onEquipmentChange}
                                            style={{ minWidth: '12em' }} filter={true} filterPlaceholder="Search" placeholder="Choose" />
                                    </AntCol>
                                    <AntCol span={3}>
                                        <Select size="large" defaultValue="bar"
                                            onChange={(value) => {
                                                if (value === "line") {
                                                    this.setState({ filled: false, chartType1: "line" });
                                                } else if (value === "filled line") {
                                                    this.setState({ filled: true, chartType1: "line" });
                                                } else {
                                                    this.setState({ filled: false, chartType1: "bar" });
                                                }

                                                this.drawChart();
                                            }}>
                                            <Option value="bar">Bar</Option>
                                            <Option value="line">Line</Option>
                                            <Option value="filled line">Filled Line</Option>
                                        </Select>
                                    </AntCol>
                                </AntRow>
                                <div className="content-section implementation">
                                    <Chart type={this.state.chartType1} data={this.state.chartData1} />
                                </div>
                            </Card>
                        </div>
                    </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelLog)