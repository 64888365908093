import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { API_BASE, handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, InputNumber, Divider, Radio, Button as AntButton, Checkbox, Upload, message, Table, Avatar } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import CustomMaterialMenuDeleteEditAdd from '../settings/ForComponents/CustomMaterialMenuDeleteEditAdd';
import CustomMaterialMenuEditAdd from '../settings/ForComponents/CustomMaterialMenuEditAdd';
import CustomMaterialMenuDeleteAdd from '../settings/ForComponents/CustomMaterialMenuDeleteAdd';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import $ from 'jquery';

const { Option } = Select;
const { Column } = Table;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

var _childItemsForModal = [];

class ProjectItemsTakeOffList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      nodes: [],
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      fileList: [],
      FileUpload: [],
      filePath: "",
      ProjectTakeOffListGroups: [],
      disciplines: [],
      units: [],
      haveChildItems: false,
      drawingPath: "",
      childItemModal: false,
      childItemEditModal: false,
      createdProjectTakeOffListId: 0,
      itemDescriptionModal: "",
      codeModal: "",
      dimensionModal: "",
      quantityModal: "",
      unitIdModal: "",
      drawingChildPath: "",
      clientCodeModal: "",
      selectedProjectTakeOffList: [],
      selectedProjectTakeOffList2: [],
      selectedProjectTakeOffListSub: null,
      deleteModal2: false,
      spec: "",
      childItemsForModal: [],
      imageUrl: "",
      modalPhotoShow: false,
      quantity: 0,
      toDoListTable:[],
      coms:[],
      relatedComList: [],
      oldProjectTakeOff9Coms:[],
      comsModal:[],
      oldProjectTakeOff9ComsModal:[] 
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.actionTemplate2 = this.actionTemplate2.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDiscipline = await handleRequest("GET", "/Discipline/getallByCompany");
    if (responseDiscipline.data.length !== 0) {
      this.setState({
        disciplines: responseDiscipline.data
      });
    }

    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ProjectItemsTakeOffList" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "ProjectItemsTakeOffList",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ProjectItemsTakeOffList");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getCodesProjectTakeOffListGroup();
    this.getTableProperties();
  }

  getTableProperties = async () => {
    this.projectTakeOffList = JSON.parse(localStorage.getItem('projectTakeOffList'));
    if (this.projectTakeOffList != null && this.projectTakeOffList != undefined && this.projectTakeOffList != "") {

      await this.setState({
        bordered: this.projectTakeOffList.bordered,
        title: this.projectTakeOffList.titleDrm == true ? title : undefined,
        titleDrm: this.projectTakeOffList.titleDrm,
        showHeader: this.projectTakeOffList.showHeader,
        footer: this.projectTakeOffList.footerDrm == true ? footer : undefined,
        footerDrm: this.projectTakeOffList.footerDrm,
        expandable: this.projectTakeOffList.expandableDrm == true ? expandable : {},
        expandableDrm: this.projectTakeOffList.expandableDrm,
        rowSelection: this.projectTakeOffList.rowSelection,
        ellipsis: this.projectTakeOffList.ellipsis,
        tableLayout: this.projectTakeOffList.tableLayout,
        size: this.projectTakeOffList.size,
        top: this.projectTakeOffList.top,
        bottom: this.projectTakeOffList.bottom,
        editBgColor: this.projectTakeOffList.editBgColor,
        deleteBgColor: this.projectTakeOffList.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.projectTakeOffList.yScroll,
        xScroll: this.projectTakeOffList.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.toDoListTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.toDoListTable.length, showQuickJumper: true,
          position: [this.projectTakeOffList.top, this.projectTakeOffList.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEditAdd row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} onAddRow={this.addChildModal.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEditAdd row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} onAddRow={this.addChildModal.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteAdd row={rowData} onDeleteRow={this.deleteModal.bind(this)} onAddRow={this.addChildModal.bind(this)} />
      </React.Fragment>;
    }
  }

  addChildModal = (row) => {


    this.setState({
      childItemModal: true,
      clientCodeModal: row.clientCode,
      createdProjectTakeOffListId: row.id,
      selectedProjectTakeOffList: row,
      selectedProjectTakeOffList2: row
    });
  }

  actionTemplate2(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal2.bind(this)}
          onEditRow={this.changeButtonUpdate2.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate2.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal2.bind(this)} />
      </React.Fragment>;
    }
  }

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedProjectTakeOffList: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }


  deleteModal2 = (row) => {
    this.setState({
      deleteModal2: true,
      selectedProjectTakeOffListSub: row
    });
  }

  showOrHideModalDelete2 = () => {
    this.setState({
      deleteModal2: !this.state.deleteModal2
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveProjectTakeOffList = async () => {

    let coms = this.state.coms;
    let projectTakeOff9Coms = []; 
    if(Boolean(coms) && coms.length>0)
    {
      coms.forEach(element => {
      let obj = { com : element }

      projectTakeOff9Coms.push(obj); 
        
      });

    }

    const addProjectTakeOffList = {
      type: this.state.type,
      groupId: this.state.groupId,
      disciplineId: this.state.disciplineId,
      description: this.state.description,
      code: this.state.code,
      location: this.state.location,
      quantity: this.state.quantity,
      unitId: this.state.unitId,
      clientCode: this.state.clientCode,
      projectTakeOff9Coms:projectTakeOff9Coms
    }
    var response = await handleRequest("POST", "/ProjectTakeOffList/add", addProjectTakeOffList);
    if (response.data !== null && response.data !== undefined) {
      if (response.data !== "0" && response.data !== 0) {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  updateProjectTakeOffList = async () => {

    let coms = this.state.coms;
    let oldProjectTakeOff9Coms = Boolean(this.state.oldProjectTakeOff9Coms) ? this.state.oldProjectTakeOff9Coms : []; 
  
    if(Boolean(oldProjectTakeOff9Coms) && oldProjectTakeOff9Coms.length>0) 
    { 
      oldProjectTakeOff9Coms.forEach(element => {
        element.active = false ; 
      });
    }
    if(Boolean(coms) && coms.length>0)
    {
      coms.forEach(element => {
      
        let index = oldProjectTakeOff9Coms.findIndex(p=>p.com===element);
        if(index===-1)
        {
          let obj = { com : element }
          oldProjectTakeOff9Coms.push(obj); 
        }
        else {          
          oldProjectTakeOff9Coms[index].active = true ; 
        } 
     
       
      });

    }

    const updatedProjectTakeOffList = {
      id: this.state.selectedProjectTakeOffList.id,
      type: this.state.type,
      groupId: this.state.groupId,
      disciplineId: this.state.disciplineId,
      description: this.state.description,
      code: this.state.code,
      location: this.state.location,
      quantity: this.state.quantity,
      unitId: this.state.unitId,
      clientCode: this.state.clientCode,
      drawingPath: this.state.drawingPath,
      spec: this.state.spec,
      projectTakeOff9Coms:oldProjectTakeOff9Coms 
    }
    var response2 = await handleRequest("POST", "/ProjectTakeOffList/update", updatedProjectTakeOffList);
    if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/ProjectTakeOffList/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        toDoListTable: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        toDoListTable: response.data,
      });
    }
  }

  deleteProjectTakeOffListOK = async () => {
    const deleteProjectTakeOffList = {
      id: this.state.selectedProjectTakeOffList.id
    }
    var response = await handleRequest("POST", "/ProjectTakeOffList/delete", deleteProjectTakeOffList);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  deleteProjectTakeOffListSubOK = async () => {
    const deleteProjectTakeOffList = {
      id: this.state.selectedProjectTakeOffListSub.id
    }
    var response = await handleRequest("POST", "/ProjectTakeOffListSub/delete", deleteProjectTakeOffList);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete2();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = (row) => {

    let oldProjectTakeOff9Coms = []; 
    let coms = [];
    if (Boolean(row.projectTakeOff9Coms)) {
      row.projectTakeOff9Coms.forEach(element => {
        coms.push(element.com); 
          let go = {};
          Object.assign(go, element); 
          oldProjectTakeOff9Coms.push(go);
      });
  }

    this.formRef.current.setFieldsValue({
      PrjTakeOffType: row.type,
      PrjTakeOffGroup: row.codeGroup.name,
      Discipline: row.disciplineId,
      Description: row.description,
      Code: row.code,
      Location: row.location,
      Quantity: row.quantity,
      Unit: row.unitId,
      ClientCode: coms
    });

    this.setState({
      selectedProjectTakeOffList: row,

      type: row.type,
      groupId: row.groupId,
      disciplineId: row.disciplineId,
      description: row.description,
      code: row.code,
      location: row.location,
      quantity: row.quantity,
      unitId: row.unitId,
      clientCode: row.clientCode,
      drawingPath: row.drawingPath,
      spec: row.spec,
      coms:coms,
      oldProjectTakeOff9Coms:oldProjectTakeOff9Coms,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  changeButtonUpdate2 = (row) => {

    let oldProjectTakeOff9ComsModal = []; 
    let coms = [];
    if (Boolean(row.projectTakeOffSub9Coms)) {
      row.projectTakeOffSub9Coms.forEach(element => {
        coms.push(element.com); 
          let go = {};
          Object.assign(go, element); 
          oldProjectTakeOff9ComsModal.push(go);
      });
  }    

    this.setState({
      selectedProjectTakeOffListSub: row,
      childItemEditModal: true,
      createdProjectTakeOffListId: row.projectTakeOffListId,
      itemDescriptionModal: row.description,
      codeModal: row.code,
      dimensionModal: row.dimension,
      quantityModal: row.quantity,
      unitIdModal: row.unitId,
      drawingChildPath: row.drawingPath,
      comsModal:coms,
      oldProjectTakeOff9ComsModal:oldProjectTakeOff9ComsModal
     
    });
  }

  resetInputs = () => {
    // this.formRef.current.setFieldsValue({
    //   PrjTakeOffType: "",
    //   PrjTakeOffGroup: "",
    //   Discipline: "",
    //   Description: "",
    //   Code: "",
    //   Location: "",
    //   Quantity: "",
    //   Unit: "",
    //   ClientCode: "",
    //   ChildItem: false
    // });

    this.formRef.current.resetFields();

    this.setState({
      selectedProjectTakeOffList: [],
      type: "",
      groupId: "",
      disciplineId: "",
      description: "",
      code: "",
      location: "",
      quantity: "",
      unitId: "",
      clientCode: "",
      drawingPath: "",
      spec: "",
      coms:[],
      comsModal:[],
      oldProjectTakeOff9Coms:[],
      oldProjectTakeOff9ComsModal:[],
      haveChildItems: false,
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
  }

  changeButtonCancel = () => {
    this.formRef.current.setFieldsValue({
      PrjTakeOffType: "",
      PrjTakeOffGroup: "",
      Discipline: "",
      Description: "",
      Code: "",
      Location: "",
      Quantity: "",
      Unit: "",
      ClientCode: ""
    });

    this.setState({
      selectedProjectTakeOffList: [],
      type: "",
      groupId: "",
      disciplineId: "",
      description: "",
      code: "",
      location: "",
      quantity: "",
      unitId: "",
      clientCode: "",
      drawingPath: "",
      spec: "",
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenCreateNewButton: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenCreateNewButton: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  showWarning2(msg) {
    if (!msg) {
      msg = 'Something went wrong. The quantity of child items should not exceed the parent item.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  handleChangeProjectTakeOffListGroup = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.ProjectTakeOffListGroups.length; z++) {
      if (this.state.ProjectTakeOffListGroups[z].id == e) {
        deger = this.state.ProjectTakeOffListGroups[z].name;
      }
    }
    this.setState({
      groupAck: deger,
      groupId: e,
    });
  }

  addProjectTakeOffListGroups = async () => {
    const { itemProjectTakeOffListGroups } = this.state;
    if (itemProjectTakeOffListGroups != null && itemProjectTakeOffListGroups != "" && itemProjectTakeOffListGroups != undefined) {
      const newItem = {
        name: itemProjectTakeOffListGroups,
        type: "ProjectTakeOffListGroup"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemProjectTakeOffListGroups: '',
      });
      this.getCodesProjectTakeOffListGroup();
    } else {
      this.showWarning();
    }
  }

  getCodesProjectTakeOffListGroup = async () => {
    const response = await handleRequest("GET", "/Code/type/ProjectTakeOffListGroup");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        ProjectTakeOffListGroups: response.data
      });
    }
  }

  onDisciplineChange = (value) => {
    this.setState({
      disciplineId: value,
    });
  }

  uploadDrawing = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);

      console.log(info.file);
      if (info.file.response.length > 0) {
        this.setState({
          drawingPath: info.file.response[0],
        });
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  uploadDrawingChild = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      if (info.file.response.length > 0) {
        this.setState({
          drawingChildPath: info.file.response[0],
        });
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  onChangeHaveChildItemCB = (e) => {
    if (e.target.checked && (this.state.createdProjectTakeOffListId === 0))
      this.saveProjectTakeOffListCheckbox();

    this.setState({
      haveChildItems: e.target.checked
    });
  }

  showOrHideModalChildItem = () => {
    this.setState({
      childItemModal: !this.state.childItemModal,
    });

    this.resetInputs();
    if (this.state.selectedProjectTakeOffList2.length !== 0) {
      this.setState({
        selectedProjectTakeOffList2: [],
        createdProjectTakeOffListId: 0
      });
    }
    else
      this.createNewClientButton();


    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalChildItemEdit = () => {
    this.setState({
      childItemEditModal: !this.state.childItemEditModal,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveProjectTakeOffListCheckbox = async () => {
    const addProjectTakeOffList = {
      type: this.state.type,
      groupId: this.state.groupId,
      disciplineId: this.state.disciplineId,
      description: this.state.description,
      code: this.state.code,
      location: this.state.location,
      quantity: this.state.quantity,
      unitId: this.state.unitId,
      drawingPath: this.state.drawingPath,
      clientCode: this.state.clientCode
    }
    var response = await handleRequest("POST", "/ProjectTakeOffList/add", addProjectTakeOffList);
    if (response.data !== null && response.data !== undefined) {
      if (response.data !== "0" && response.data !== 0) {
        this.restartTable();
        this.setState({
          createdProjectTakeOffListId: Number(response.data),
          clientCodeModal: this.state.clientCode,
          childItemModal: true
        });
      }
      else {
        this.showError();
      }
    }
  }

  addNewChildItem = async () => {
    if (this.state.createdProjectTakeOffListId > 0 && this.state.itemDescriptionModal !== "" && this.state.codeModal !== ""
      ) {

        let comsModal = this.state.comsModal;
        let projectTakeOff9Coms = []; 
        if(Boolean(comsModal) && comsModal.length>0)
        {
          comsModal.forEach(element => {
          let obj = { com : element }
    
          projectTakeOff9Coms.push(obj); 
            
          });
    
        }

      const addProjectTakeOffListSub = {
        projectTakeOffListId: this.state.createdProjectTakeOffListId,
        description: this.state.itemDescriptionModal,
        code: this.state.codeModal,
        dimension: this.state.dimensionModal,
        quantity: this.state.quantityModal,
        unitId: this.state.unitIdModal,
        drawingPath: this.state.drawingChildPath,
        projectTakeOffSub9Coms:projectTakeOff9Coms
      }

      let flagParentQuantity = this.state.quantity;

      for (let index = 0; index < this.state.childItemsForModal.length; index++)
        flagParentQuantity -= Number(this.state.childItemsForModal[index].quantity);

      flagParentQuantity -= Number(this.state.quantityModal);

      console.log(this.state.childItemsForModal);
      console.log(flagParentQuantity);

      if (flagParentQuantity >= 0) {
        var response = await handleRequest("POST", "/ProjectTakeOffListSub/add", addProjectTakeOffListSub);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.setState({
              childItemsForModal: [...this.state.childItemsForModal, addProjectTakeOffListSub],
              itemDescriptionModal: "",
              codeModal: "",
              dimensionModal: "",
              quantityModal: "",
              unitIdModal: "",
              drawingChildPath: "",
              clientCodeModal: this.state.clientCode,           
              comsModal:[],           
              oldProjectTakeOff9ComsModal:[],
              
            });
            $(".ant-upload-list").empty();
            this.restartTable();
            this.showSuccess();
          }
          else
            this.showError();
        } else
          this.showWarning();
      } else
        this.showWarning2();
    } else
      this.showWarning();
  }

  saveChildItem = async () => {
    if (this.state.createdProjectTakeOffListId > 0 && this.state.itemDescriptionModal !== "" && this.state.codeModal !== ""
      ) {
        let comsModal = this.state.comsModal;
        let projectTakeOff9Coms = []; 
        if(Boolean(comsModal) && comsModal.length>0)
        {
          comsModal.forEach(element => {
          let obj = { com : element }
    
          projectTakeOff9Coms.push(obj); 
            
          });
    
        }
      const addProjectTakeOffListSub = {
        projectTakeOffListId: this.state.createdProjectTakeOffListId,
        description: this.state.itemDescriptionModal,
        code: this.state.codeModal,
        dimension: this.state.dimensionModal,
        quantity: this.state.quantityModal,
        unitId: this.state.unitIdModal,
        drawingPath: this.state.drawingChildPath,
        clientCode: this.state.clientCodeModal,
        projectTakeOffSub9Coms:projectTakeOff9Coms
      }
      console.log(addProjectTakeOffListSub);
      var response = await handleRequest("POST", "/ProjectTakeOffListSub/add", addProjectTakeOffListSub);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.setState({
            itemDescriptionModal: "",
            codeModal: "",
            dimensionModal: "",
            quantityModal: "",
            unitIdModal: "",
            drawingChildPath: "",
            clientCodeModal: "",
            clientCode: "",
            createdProjectTakeOffListId: 0,
            selectedProjectTakeOffList2: [],
            childItemModal: false,
            childItemsForModal: [],
            comsModal:[],           
            oldProjectTakeOff9ComsModal:[],
          });
          $(".ant-upload-list").empty();
          this.restartTable();
          this.resetInputs();
          if (this.state.createNewButton !== "Create New") {
            this.setState({
              hiddenDataGiris: true,
              hiddenSave: true,
              hiddenUpdate: false,
              hiddenCreateNewButton: false,
              createNewButton: "Create New",
              createNewButtonVariant: "info",
            });
          }
          this.showSuccess();
        }
        else {
          this.showError();
        }
      }
    } else
      this.showWarning();
  }

  updateChildItem = async () => {
    if (this.state.selectedProjectTakeOffListSub !== null && this.state.createdProjectTakeOffListId > 0 && this.state.itemDescriptionModal !== ""
      && this.state.codeModal !== "") {

        
    let comsModal = this.state.comsModal;
    let oldProjectTakeOff9Coms = Boolean(this.state.oldProjectTakeOff9ComsModal) ? this.state.oldProjectTakeOff9ComsModal : []; 
  
    if(Boolean(oldProjectTakeOff9Coms) && oldProjectTakeOff9Coms.length>0) 
    { 
      oldProjectTakeOff9Coms.forEach(element => {
        element.active = false ; 
      });
    }
    if(Boolean(comsModal) && comsModal.length>0)
    {
      comsModal.forEach(element => {
      
        let index = oldProjectTakeOff9Coms.findIndex(p=>p.com===element);
        if(index===-1)
        {
          let obj = { com : element }
          oldProjectTakeOff9Coms.push(obj); 
        }
        else {          
          oldProjectTakeOff9Coms[index].active = true ; 
        } 
     
       
      });

    }


      const updatedProjectTakeOffListSub = {
        id: this.state.selectedProjectTakeOffListSub.id,
        projectTakeOffListId: this.state.createdProjectTakeOffListId,
        description: this.state.itemDescriptionModal,
        code: this.state.codeModal,
        dimension: this.state.dimensionModal,
        quantity: this.state.quantityModal,
        unitId: this.state.unitIdModal,
        drawingPath: this.state.drawingChildPath,
        projectTakeOffSub9Coms:oldProjectTakeOff9Coms,
      }
      console.log(updatedProjectTakeOffListSub);
      var response = await handleRequest("POST", "/ProjectTakeOffListSub/update", updatedProjectTakeOffListSub);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.setState({
            createdProjectTakeOffListId: 0,
            itemDescriptionModal: "",
            codeModal: "",
            dimensionModal: "",
            quantityModal: "",
            unitIdModal: "",
            drawingChildPath: "",
            clientCodeModal: "",
            imageUrl: "",
            childItemEditModal: false,
            comsModal:[],
            oldProjectTakeOff9ComsModal:[],
          });
          this.restartTable();
          this.resetInputs();
          this.showSuccess();
        }
        else {
          this.showError();
        }
      }
    } else
      this.showWarning();
  }

  showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      }
    } catch (error) {
      this.showError("File cant found.");
    }
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  }

  
  handleSearch = async (value) => {
    if(value.length>0)
   { const response = await handleRequest("GET", "/ComListing/getComs/" + value);

   console.log( response.data); 
    this.setState({
        relatedComList: Boolean(response.data) ? response.data : [],
    });} 

}

  render() {
    var itemGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Group");
    var itemTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Type");
    var itemDescriptionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Description");
    var itemDisciplineAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline");
    var itemCodeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Code");
    var itemLocationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Location");
    var itemUnitAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Unit");
    var itemClientCodeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientCode");
    var itemQuantityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Quantity");

    var itemDrawingAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Drawing");
    var itemChildItemAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ChildItem");

    var itemGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "Group");
    var itemTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Type");
    var itemDescriptionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Description");
    var itemDisciplineRequired = this.state.dynamicInputs.find(p => p.inputKey === "Discipline");
    var itemCodeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Code");
    var itemLocationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Location");
    var itemUnitRequired = this.state.dynamicInputs.find(p => p.inputKey === "Unit");
    var itemClientCodeRequired = this.state.dynamicInputs.find(p => p.inputKey === "ClientCode");
    var itemQuantityRequired = this.state.dynamicInputs.find(p => p.inputKey === "Quantity");

    var itemDrawingRequired = this.state.dynamicInputs.find(p => p.inputKey === "Drawing");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("projectTakeOffList")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "type",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffType" defaultMessage="Type" />,
      },
      {
        dataIndex: "codeGroup",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffGroup" defaultMessage="Group" />,
        render: (data) => {
          if (data != null && data != undefined) {
            return data.name;
          }
          return "";
        }
      },
      {
        dataIndex: "discipline",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffDiscipline" defaultMessage="Discipline" />,
        render: (data) => {
          if (data != null && data != undefined) {
            return data.disciplineName;
          }
          return "";
        }
      },
      {
        dataIndex: "description",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffDescription" defaultMessage="Item Description" />,
      },
      {
        dataIndex: "code",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffCode" defaultMessage="Code" />,
      },
      {
        dataIndex: "location",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffLocation" defaultMessage="Location" />,
      },
      {
        dataIndex: "quantity",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity" />,
      },
      {
        dataIndex: "unitId",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffUnit" defaultMessage="Unit" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffDrawing" defaultMessage="Drawing" />,
        render: (record) => {
          if (record.drawingPath !== undefined)
            if (record.drawingPath !== "" && record.drawingPath !== null) {
              let str = record.drawingPath.split("\\");
              let str2 = str[str.length - 1].split(".");
              let drawingName = str2[0];
              return (
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    this.setState({
                      imageUrl: `${API_BASE}/ProjectTakeOffList/download/` + record.id,
                      modalPhotoShow: true,
                    })
                  }
                >
                  {drawingName}
                </div>
              );
            } else
              return "";
          else
            return "";
        }
      },
      {
        dataIndex: "projectTakeOff9Coms",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffClientCode" defaultMessage="Client Code" />,
        render: (data) => {
            var result = [];  
            if(Boolean(data) && data.length>0) 
              data.forEach(element => {
                result.push(element.com); 
              }); 
              return result.toString(); 
        }
      },
      {
        dataIndex: "spec",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffSpec" defaultMessage="Spec" />,
      },
      {
        dataIndex: "rfq",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffRFQ" defaultMessage="RFQ" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffBE" defaultMessage="BE" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffNoL" defaultMessage="NoL" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffPO" defaultMessage="PO" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffIAP" defaultMessage="IAP" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffNMR601" defaultMessage="NMR 601" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffPIM" defaultMessage="PIM" />,
      },

      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffNMR602" defaultMessage="NMR 602" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffNotification" defaultMessage="Notification" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffIPI" defaultMessage="IPI" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffFI" defaultMessage="FI" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffReleaseCertficate" defaultMessage="Release Certficate" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffShipmentOrder" defaultMessage="Shipment Order" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffNMR603" defaultMessage="NMR 603" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffReceiving" defaultMessage="Receiving" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffReceivingInspection" defaultMessage="Receiving Inspection" />,
      },
    ];

    const subColumns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate2
      },
      {
        dataIndex: "description",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffDescription" defaultMessage="Child Item Description" />,
      },
      {
        dataIndex: "code",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffCode" defaultMessage="Code" />,
      },
      {
        dataIndex: "dimension",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffLocation" defaultMessage="Dimension" />,
      },
      {
        dataIndex: "quantity",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity" />,
      },
      {
        dataIndex: "unitId",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffUnit" defaultMessage="Unit" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="PrjTakeOffDrawing" defaultMessage="Drawing" />,
        render: (record) => {
          if (record.drawingPath !== undefined)
            if (record.drawingPath !== "" && record.drawingPath !== null) {
              let str = record.drawingPath.split("\\");
              let str2 = str[str.length - 1].split(".");
              let drawingName = str2[0];
              return (
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    this.setState({
                      imageUrl: `${API_BASE}/ProjectTakeOffListSub/download/` + record.id,
                      modalPhotoShow: true,
                    })
                  }
                >
                  {drawingName}
                </div>
              );
            } else
              return "";
          else
            return "";
        }
      },
      {
        dataIndex: "projectTakeOffSub9Coms",
        key: "key",
        title: <FormattedMessage id="PrjTakeOffClientCode" defaultMessage="Client Code" />,
        render: (data) => {
          var result = [];  
          if(Boolean(data) && data.length>0) 
            data.forEach(element => {
              result.push(element.com); 
            }); 
            return result.toString(); 
      }
      }
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    let radioGroupOptions = (
      <FormBoot.Group as={Row}  >
        <Col sm="5">
          <Radio value={"Equipment"}><FormattedMessage id="PrjTakeOffEquipment" defaultMessage="Equipment" /></Radio>
        </Col>
        <Col sm="2">
        </Col>
        <Col sm="5">
          <Radio value={"Material"}><FormattedMessage id="PrjTakeOffMaterial" defaultMessage="Material" /></Radio>
        </Col>
      </FormBoot.Group>
    );

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateProjectTakeOffList : this.saveProjectTakeOffList}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("projectTakeOffList")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />

              {(itemTypeAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffType" defaultMessage="Type" />}
                  name="PrjTakeOffType"
                  rules={[{ required: itemTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTypeRequired.translateRequired) == true ? itemTypeRequired.translateRequired : -1} defaultMessage="Please input your Type!" /> }]} >
                  <Mnd.MNDRadioGroup value={this.state.type}
                    onChange={(e) => {
                      this.setState({
                        type: e.target.value
                      })
                    }}
                    radios={radioGroupOptions} />
                </Form.Item>}

              {(itemGroupAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffGroup" defaultMessage="Group" />}
                  name="PrjTakeOffGroup"
                  rules={[{ required: itemGroupRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemGroupRequired.translateRequired) == true ? itemGroupRequired.translateRequired : -1} defaultMessage="Please input your Group!" /> }]} >
                  <Select
                    name="groupId"
                    style={{ width: '100%' }}
                    value={this.state.groupAck}
                    onChange={this.handleChangeProjectTakeOffListGroup}

                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.itemProjectTakeOffListGroups}
                            onChange={(event) => { this.setState({ itemProjectTakeOffListGroups: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addProjectTakeOffListGroups} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.ProjectTakeOffListGroups.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemDisciplineAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffDiscipline" defaultMessage="Discipline" />}
                  name="Discipline"
                  rules={[{ required: itemDisciplineRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDisciplineRequired.translateRequired) == true ? itemDisciplineRequired.translateRequired : -1} defaultMessage="Please input your Discipline!" /> }]} >
                  <Select size='large'
                    style={{ width: '100%' }}
                    value={this.state.disciplineId}
                    onChange={this.onDisciplineChange}
                  >
                    {this.state.disciplines.map(discipline => (
                      <Option key={discipline.id} value={discipline.id}>{
                        discipline.disciplineName
                      }</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemDescriptionAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffDescription" defaultMessage="Item Description" />}
                  name="Description"
                  rules={[{ required: itemDescriptionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDescriptionRequired.translateRequired) == true ? itemDescriptionRequired.translateRequired : -1} defaultMessage="Please input your Description!" /> }]} >
                  <Input id="PrjTakeOffDescription" value={this.state.description} onChange={(e) =>
                    this.setState({ description: e.target.value })
                  } />
                </Form.Item>}

              {(itemCodeAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffCode" defaultMessage="Code" />}
                  name="Code"
                  rules={[{ required: itemCodeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCodeRequired.translateRequired) == true ? itemCodeRequired.translateRequired : -1} defaultMessage="Please input your Code!" /> }]} >
                  <Input id="PrjTakeOffCode" value={this.state.code} onChange={(e) =>
                    this.setState({ code: e.target.value })
                  } />
                </Form.Item>}

              {(itemLocationAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffLocation" defaultMessage="Location" />}
                  name="Location"
                  rules={[{ required: itemLocationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLocationRequired.translateRequired) == true ? itemLocationRequired.translateRequired : -1} defaultMessage="Please input your Location!" /> }]} >
                  <Input id="PrjTakeOffLocation" value={this.state.location} onChange={(e) =>
                    this.setState({ location: e.target.value })
                  } />
                </Form.Item>}

              {(itemQuantityAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity" />}
                  name="Quantity"
                  rules={[{ required: itemQuantityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemQuantityRequired.translateRequired) == true ? itemQuantityRequired.translateRequired : -1} defaultMessage="Please input your Quantity!" /> }]} >
                  <InputNumber min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ quantity: e })} value={this.state.quantity} />
                </Form.Item>}

              {(itemUnitAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffUnit" defaultMessage="Unit" />}
                  name="Unit"
                  rules={[{ required: itemUnitRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemUnitRequired.translateRequired) == true ? itemUnitRequired.translateRequired : -1} defaultMessage="Please input your Unit!" /> }]} >
                  <Select size='large'
                    style={{ width: '100%' }}
                    value={this.state.unitId}
                    onChange={(value) => this.setState({ unitId: value })}
                  >
                    {this.state.units.map(unit => (
                      <Option key={unit.id} value={unit.id}>{
                        unit.name
                      }</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemDrawingAttributes.visible) &&
                <Form.Item name="Drawing" label={<FormattedMessage id="PrjTakeOffDrawing" defaultMessage="Drawing" />}
                  rules={[{ required: itemDrawingRequired.canBeRequired, message: <FormattedMessage id={itemDrawingRequired.translateRequired} defaultMessage="Please upload a drawing file!" /> }]} >
                  <Upload name="avatar" showUploadList={true} action={`${API_BASE}/ProjectTakeOffList/upload`} onChange={this.uploadDrawing}>
                    <AntButton><UploadOutlined />
                              Upload
                    </AntButton>
                  </Upload>
                </Form.Item>}

              {(itemClientCodeAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="PrjTakeOffClientCode" defaultMessage="Client Material Code" />}
                  name="ClientCode"
                  rules={[{ required: itemClientCodeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemClientCodeRequired.translateRequired) == true ? itemClientCodeRequired.translateRequired : -1} defaultMessage="Please input your Client Code!" /> }]} >
                
                   <Select   style={{ width: "100%" }} showSearch mode="multiple"
                                                            onSearch={this.handleSearch}
                                                            placeholder="9com#" id="PrjTakeOffClientCode" value={this.state.coms} onChange={(value) => {
                                                               
                                                                this.setState({ coms: value })
                                                            }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.relatedComList.map(i => (
                                                            <Option key={i} value={i}>{i}</Option> 
                                                        ))}
                                                             </Select>
                </Form.Item>}

              {/* <div hidden={this.state.hiddenSave}>
                {(itemChildItemAttributes.visible) &&
                  <Form.Item name="ChildItem" label={<FormattedMessage id="PrjTakeOffClientCode" defaultMessage="Child Item" />}>
                    <Checkbox id="PrjTakeOffHaveChildItems" value={this.state.haveChildItems} onChange={this.onChangeHaveChildItemCB}>
                      <FormattedMessage id="PrjTakeOffHaveChildItems" defaultMessage="Create Child Items" />
                    </Checkbox>
                  </Form.Item>}
              </div> */}

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2">
                    </Col>
                    <Col sm="4">
                      <Button id="ProjectTakeOffListSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="2">
                      <Button id="ProjectTakeOffListCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button id="ProjectTakeOffListUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.toDoListTable}
                  pagination={this.state.paginationDisabled}
                  expandable={{
                    expandedRowRender: record =>
                      <Table columns={subColumns} dataSource={record.projectTakeOffListSubs} pagination={false} />,
                    rowExpandable: record => record.projectTakeOffListSubs.length !== 0,
                  }}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>}

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteProjectTakeOffListOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.childItemModal}
          onHide={this.showOrHideModalChildItem}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          size="lg"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="PrjTakeOffChildItemModalHeader" defaultMessage="Child Item" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormBoot>
              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffDescription" defaultMessage="Item Description:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Input id="PrjTakeOffDescriptionModal" value={this.state.itemDescriptionModal} onChange={(e) =>
                    this.setState({ itemDescriptionModal: e.target.value })
                  } />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffCode" defaultMessage="Code:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Input id="PrjTakeOffCodeModal" value={this.state.codeModal} onChange={(e) =>
                    this.setState({ codeModal: e.target.value })
                  } />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffDimension" defaultMessage="Dimension:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Input id="PrjTakeOffDimensionModal" value={this.state.dimensionModal} onChange={(e) =>
                    this.setState({ dimensionModal: e.target.value })
                  } />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <InputNumber id="PrjTakeOffQuantityModal" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ quantityModal: e })} value={this.state.quantityModal} />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffUnit" defaultMessage="Unit:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Select size='large'
                    style={{ width: '100%' }}
                    value={this.state.unitIdModal}
                    onChange={(value) => this.setState({ unitIdModal: value })}
                  >
                    {this.state.units.map(unit => (
                      <Option key={unit.id} value={unit.id}>{
                        unit.name
                      }</Option>
                    ))}
                  </Select>
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffDrawing" defaultMessage="Drawing:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Upload name="avatar" showUploadList={true} action={`${API_BASE}/ProjectTakeOffList/upload`} onChange={this.uploadDrawingChild}>
                    <AntButton>
                      <UploadOutlined /><FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                    </AntButton>
                  </Upload>
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffClientCode" defaultMessage="Client Material Code:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                
                  <Select   style={{ width: "100%" }} showSearch mode="multiple"
                                                            onSearch={this.handleSearch}
                                                            placeholder="9com#" id="PrjTakeOffClientCode" value={this.state.comsModal} onChange={(value) => {
                                                               
                                                                this.setState({ comsModal: value })
                                                            }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.relatedComList.map(i => (
                                                            <Option key={i} value={i}>{i}</Option> 
                                                        ))}
                                                             </Select>
                </Col>
                <Col sm="4">
                  <Button variant="primary" style={{ width: '100%' }} onClick={this.addNewChildItem}><FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New Child Item" /></Button>
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                </Col>
                <Col sm="4">
                  <Button variant="primary" style={{ width: '100%' }} onClick={this.saveChildItem}><FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All Entries" /></Button>
                </Col>
                <Col sm="4">
                </Col>
              </FormBoot.Group>

              <Table
                style={{ padding: "4px 1rem 4px 4px" }}
                dataSource={this.state.childItemsForModal} bordered>
                <Column title={<FormattedMessage id="PITOItemDescriptionModal" defaultMessage="Item Description" />} key="description" dataIndex="description" />
                <Column title={<FormattedMessage id="PITOCodeModal" defaultMessage="Code" />} key="code" dataIndex="code" />
                <Column title={<FormattedMessage id="PITODimensionModal" defaultMessage="Dimension" />} key="dimension" dataIndex="dimension" />
                <Column title={<FormattedMessage id="PITOQuantityModal" defaultMessage="Quantity" />} key="quantity" dataIndex="quantity" />
                {/*<Column title={<FormattedMessage id="PITODrawingModal" defaultMessage="Drawing" />} key="drawing" render={(value, row, index) => {
                  if (row.drawingPath !== undefined)
                    if (row.drawingPath != null && row.drawingPath !== "")
                      return <Avatar size={64} src={row.drawingPath} onClick={() => this.showReport(row.drawingPath)} />
                    else
                      return '';
                  return '';
                }} />*/}
                <Column title={<FormattedMessage id="PITOClientMaterialCodeModal" defaultMessage="Client Material Code" />} key="clientCode" dataIndex="clientCode" />
              </Table>


            </FormBoot>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
                    </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModal2}
          onHide={this.showOrHideModalDelete2}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete2}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteProjectTakeOffListSubOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.childItemEditModal}
          onHide={this.showOrHideModalChildItemEdit}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          size="lg"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="PrjTakeOffChildItemModalHeader" defaultMessage="Child Item" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormBoot>
              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffDescription" defaultMessage="Item Description:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Input id="PrjTakeOffDescriptionModal" value={this.state.itemDescriptionModal} onChange={(e) =>
                    this.setState({ itemDescriptionModal: e.target.value })
                  } />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffCode" defaultMessage="Code:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Input id="PrjTakeOffCodeModal" value={this.state.codeModal} onChange={(e) =>
                    this.setState({ codeModal: e.target.value })
                  } />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffDimension" defaultMessage="Dimension:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Input id="PrjTakeOffDimensionModal" value={this.state.dimensionModal} onChange={(e) =>
                    this.setState({ dimensionModal: e.target.value })
                  } />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffQuantity" defaultMessage="Quantity:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <InputNumber id="PrjTakeOffQuantityModal" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ quantityModal: e })} value={this.state.quantityModal} />
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffUnit" defaultMessage="Unit:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Select size='large'
                    style={{ width: '100%' }}
                    value={this.state.unitIdModal}
                    onChange={(value) => this.setState({ unitIdModal: value })}
                  >
                    {this.state.units.map(unit => (
                      <Option key={unit.id} value={unit.id}>{
                        unit.name
                      }</Option>
                    ))}
                  </Select>
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffDrawing" defaultMessage="Drawing:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                  <Upload name="avatar" showUploadList={true} action={`${API_BASE}/ProjectTakeOffList/upload`} onChange={this.uploadDrawingChild}>
                    <AntButton>
                      <UploadOutlined /><FormattedMessage id="GeneralButtonUpload" defaultMessage="Upload" />
                    </AntButton>
                  </Upload>
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                  <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                    <FormattedMessage id="PrjTakeOffClientCode" defaultMessage="Client Material Code:" />
                  </FormBoot.Label>
                </Col>
                <Col sm="4">
                <Select   style={{ width: "100%" }} showSearch mode="multiple"
                                                            onSearch={this.handleSearch}
                                                            placeholder="9com#" id="PrjTakeOffClientCode" value={this.state.comsModal} onChange={(value) => {
                                                               
                                                                this.setState({ comsModal: value })
                                                            }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.relatedComList.map(i => (
                                                            <Option key={i} value={i}>{i}</Option> 
                                                        ))}
                   </Select>
                </Col>
              </FormBoot.Group>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="4">
                </Col>
                <Col sm="4">
                  <Button variant="primary" style={{ width: '100%' }} onClick={this.updateChildItem}><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                </Col>
                <Col sm="4">
                </Col>
              </FormBoot.Group>
            </FormBoot>
          </Modal.Body>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectItemsTakeOffList)