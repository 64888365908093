import React, { Component } from 'react';

import { Button as PrimeButton } from "primereact/button";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './OrganizationChartCustomStyle.css';
import { Tree } from 'primereact/tree';


export class LocationAuthorization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
            globalFilter: null,
            expandedKeys: {},
            selectedNodeKeys: null,

            users: [],
            userId: 0,
            userName: ""



        };



    }

    componentWillMount = async () => {

        const responseUser = await handleRequest("GET", "/User/getAll");

        if (responseUser.data.length === 0) {
            this.setState({
                users: []
            });
        }

        if (responseUser.data.length !== 0) {


            const options = responseUser.data.map(function (row) {
                return { name: row.username.toString(), id: row.id.toString() }
            })

            this.setState({
                users: options,
            });

        }

        this.restartTable();

    }




    restartTable = async () => {


        var response = [];
        response = await handleRequest("GET", "/WFLocation/getNodesLocations");


        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                nodes: []

            });
        } else
            if (response.data.length !== 0) {

                this.setState({
                    nodes: response.data
                });
            }

        if (this.state.userId > 0) {

            var response2 = [];
            var param = { userId: this.state.userId };
            response2 = await handleRequest("POST", "/WFLocation/getUserNodes", param);

            console.log(response2);
            if (response2.length === 0 || response2.data.length === 0) {
                this.setState({
                    selectedNodeKeys: null

                });
            } else
                if (response2.data.length !== 0) {
                    const obj = {};
                   
                    for (const key of response2.data) {
                        obj[key] = {checked: true } ; 
                    }
                  
                    this.setState({
                        selectedNodeKeys: obj
                    });
                }
        }
    }

    handleUserChange = e => {
        e.preventDefault();
        var _disId;

        for (var z = 0; z < this.state.users.length; z++) {
            if (this.state.users[z].name === e.target.value) {
                _disId = this.state.users[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            userId: _disId,
            selectedNodeKeys:null

        });

        this.restartTable();
    };


    saveAll = async () => {

        console.log(this.state.selectedNodeKeys);

        if (this.state.selectedNodeKeys !== null && this.state.userId > 0) {

            var list = [];
            var flagArray = Object.keys(this.state.selectedNodeKeys);

            flagArray.forEach(element => {
                console.log(element.split(".")[1]);
                const newItem = {
                    id: 0,
                    locationId: element.split(".")[1],
                    userId: this.state.userId
                }
                list.push(newItem);
            });


            await handleRequest("POST", "/WFLocation/addUserWFLocation", list);
            this.restartTable();
            this.showSuccess();

        } else {
            this.showError("Please Select Locations and User !");
        }
    };



    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleDragDrop = (event) => {
      //  console.log(event.value); 
        this.setState({nodes: event.value})
    }



    render() {


        let optionsUsers = this.state.users.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Card>
                    <Row>

                        <Col sm="4">

                        </Col>
                        <Col sm="4">
                            <Form>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        User
                                     </Form.Label>
                                    <Col sm="6">
                                        <Form.Control name="userName" value={this.state.userName}
                                            onChange={this.handleUserChange} as="select">
                                            <option value="Select User ">
                                                Select User
                                            </option>
                                            {optionsUsers}
                                        </Form.Control> 
                                    </Col>
                                   
                                </Form.Group>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                    <Col sm="12">
                                        <Tree value={this.state.nodes} selectionMode="checkbox" dragdropScope="demo" onDragDrop={this.handleDragDrop} selectionKeys={this.state.selectedNodeKeys} onSelectionChange={e => this.setState({ selectedNodeKeys: e.value })} />

                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col sm="4">
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                        </Col>
                        <Col sm="3">
                            <PrimeButton style={{ width: '100%' }} id='add-authorization' className="p-button-info" onClick={this.saveAll} label="Save All Changes" />
                        </Col>
                        <Col sm="5">
                        </Col>
                    </Row>
                </Card>

            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(LocationAuthorization) 
