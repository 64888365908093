/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { FormattedMessage } from "react-intl";
import { Menu, Item } from 'react-contexify';


class HowtoUse extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            inputDescription:'',
            modalShowWhatIs: false,
            selectID:'',
            selectURI:''

        }
    }

    componentWillMount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
        
    }
    

    componentDidMount() {
        document.addEventListener('contextmenu', this._handleContextMenu);
        
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti whatis' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }

     
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    dynamicInputs = async (e) => {
        const  {selectID}  = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "", 
        });

       
        
        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }


            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error);
            }
        }
    }

    render() {

        const { selectID } = this.state.selectID; 

        return (
            <div>
                   <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {
  
    }
  }
  
  const mapDispatchToProps = {
  
  } 
  
  export default connect(mapStateToProps, mapDispatchToProps)(HowtoUse) 
