
import { handleRequest } from '../config/env';
import { userTokn } from "../../store/ducks/auth.duck";

import store from '../../../redux/store';

export async function setStandardization(pageName) {
        var responseDP = null ;
        if(Boolean(pageName) && pageName.length>0)
        responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: pageName });
        var result = {dynamicAttributes:[],dynamicInputs:[]}; 
        if (Boolean(responseDP) && Boolean(responseDP.data) && responseDP.data.length > 0) {
            result.dynamicAttributes=responseDP.data;
        }
        var responseInputs = null;
        if(Boolean(pageName) && pageName.length>0)
         responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", { pageName: pageName });
        if (Boolean(responseInputs) && Boolean(responseInputs.data) && responseInputs.data.length !== 0) {
            result.dynamicInputs=responseInputs.data;            
        } 

        return result;
       

    }

export async function getDynamicMenu (pageName) {
    const state = store.getState();
    const user = state.auth.user; 
    console.log("State", state); 
    var response = null;
    if(Boolean(pageName) && pageName.length>0)
    response = await handleRequest("GET", "/DynamicMenu/getFormId/" + pageName);
    var result = {formId:0,authorizations:[]};  
    
    if (Boolean(response) && Boolean(response.data)) {
     result = {
            formId: response.data,
            authorizations: user.authList.filter(p => p.menuId === response.data || p.form === "All") 
        };      
    } 

    return result; 
}

export async function  getUserSettings(formId){
    var responseUserSettings = null ;
    if( Boolean(formId) && formId>0)
     responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
    var result = {
        howToUseSetting: false,
        whatIsSetting: false
    }; 
    if (Boolean(responseUserSettings) && Boolean(responseUserSettings.data) && responseUserSettings.data.length !== 0) {
        result = {
            howToUseSetting: responseUserSettings.data.howToUseSetting,
            whatIsSetting: responseUserSettings.data.whatIsSetting
        };
    } 
    return result; 
}

export async function getCodeSelectItems (type) {
    var response = null;
    var result = [];
    if(Boolean(type) && type.length>0)
     response = await handleRequest("GET", "/Code/type/"+type);

        if (Boolean(response) && Boolean(response.data)) {
            result = response.data
        }

    return result; 
}
