import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import { Col, Form, Row, Button } from "react-bootstrap";
import { Select, } from "antd";
import Loader from 'react-loader-spinner';
import { handleRequest, API_BASE } from "../../redux/config/env";
import { Upload, message, Button as AntButton } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import $ from 'jquery';

const { Option } = Select;

export class QRCodeGenerator extends Component {

    constructor(props) {
        super(props)
        this.state = {
            truck: "",
            truckList: [],
            isGetting: true,
            value: "",
            size: 300,
            fgColor: '#000000',
            bgColor: '#ffffff',
            includeMargin: false,
            includeImage: false,
            imageH: 24,
            imageW: 24,
            imageX: 0,
            imageY: 0,
            imageSrc: "",
            imageExcavate: true,
            centerImage: true,
            qrLogoPath: ""
        };
    }

    componentDidMount = async () => {
        this.setState({
            isGetting: true
        });

        const responseEquipment = await handleRequest("GET", "/EquipmentLog/getAll");

        if (responseEquipment.data.length !== 0) {
            var list = responseEquipment.data;

            var _truckList = list.filter((p) => p.equipmentType != null && p.equipmentType.equipmentTypeName === "Truck");

            for (let index = 0; index < _truckList.length; index++) {
                if (_truckList[index].equipmentVehicleLicenceList !== null && _truckList[index].equipmentVehicleLicenceList.length !== 0) {
                    _truckList[index].plateNo = _truckList[index].equipmentVehicleLicenceList[0].plateNo.toString();
                } else {
                    _truckList[index].plateNo = "";
                }
            }

            this.setState({
                truckList: _truckList,
            });
        }

        this.setState({
            isGetting: false
        });
    }

    downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "Truck-QR-Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    downloadQR2 = () => {
        const canvas = document.getElementById("789123");
        const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "Truck-QR-Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = async (info) => {
        setTimeout(() => {
            $(".ant-upload-list").empty();
        }, 200);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    qrLogoPath: info.file.response[0]
                });

                const newQRLogo = {
                    qrLogoPhotoPath: info.file.response[0]
                }

                const response = await handleRequest("POST", "/QRCode/add", newQRLogo);

                this.setState({
                    imageSrc: API_BASE + "/QRCode/download/" + response.data
                });
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    render() {
        return (
            <div>
                {this.state.isGetting ? <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div> :
                    <div>
                        <h3 style={{ marginBottom: "1rem" }}> QR Code Generator - 1 </h3>
                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Truck
                            </Form.Label>
                            <Col sm="3">
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Truck"
                                    value={this.state.truck}
                                    onChange={(value) => {
                                        this.setState({ truck: value });
                                    }}
                                >
                                    {this.state.truckList.map((i) => (
                                        <Option key={i.id} value={i.id}>
                                            {i.equipmentDescription} - {i.plateNo}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Truck's QR Code
                            </Form.Label>
                            <Col sm="3">
                                <QRCode
                                    id="123456"
                                    value={this.state.truck}
                                    size={290}
                                    level={"H"}
                                    includeMargin={true}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Col sm="1">
                            </Col>
                            <Col sm="3">
                                <Button onClick={this.downloadQR} >
                                    Download QR Code
                                </Button>
                            </Col>
                        </Form.Group>

                        <h3 style={{ marginBottom: "1rem" }}> QR Code Generator - 2 </h3>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Size(px):
                            </Form.Label>
                            <Col sm="1">
                                <input
                                    style={{ marginTop: "0.5rem" }}
                                    type="number"
                                    size="8"
                                    onChange={(e) =>
                                        this.setState({ size: parseInt(e.target.value, 10) || 0 })
                                    }
                                    value={this.state.size}
                                />
                            </Col>
                            {/*<Form.Label style={{ color: "black" }} column sm="2">
                                Background Color:
                            </Form.Label>
                            <Col sm="1">
                                <input
                                    style={{ marginTop: "0.5rem" }}
                                    type="color"
                                    onChange={(e) => this.setState({ bgColor: e.target.value })}
                                    value={this.state.bgColor}
                                />
                            </Col>
                            <Form.Label style={{ color: "black" }} column sm="2">
                                Foreground Color:
                            </Form.Label>
                            <Col sm="1">
                                <input
                                    style={{ marginTop: "0.5rem" }}
                                    type="color"
                                    onChange={(e) => this.setState({ fgColor: e.target.value })}
                                    value={this.state.fgColor}
                                />
                                </Col>*/}
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Include Logo:
                            </Form.Label>
                            <Col sm="1">
                                <input
                                    style={{ marginTop: "1rem" }}
                                    type="checkbox"
                                    checked={this.state.includeImage}
                                    onChange={(e) => this.setState({ includeImage: e.target.checked })}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Logo:
                            </Form.Label>
                            <Col sm="1">
                                <Upload
                                    disabled={!this.state.includeImage}
                                    name="avatar"
                                    className="avatar-uploader"
                                    action={`${API_BASE}/QRCode/upload`}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handlePhotoChange}
                                    style={{ width: '90%' }}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Attach Logo
                                    </AntButton>
                                </Upload>
                                {/*<input
                                    disabled={!this.state.includeImage}
                                    style={{ marginTop: "0.5rem" }}
                                    type="text"
                                    onChange={(e) => this.setState({ imageSrc: e.target.value })}
                                    value={this.state.imageSrc}
                                />*/}
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Logo Width:
                            </Form.Label>
                            <Col sm="1">
                                <input
                                    size="8"
                                    disabled={!this.state.includeImage}
                                    style={{ marginTop: "0.5rem" }}
                                    type="number"
                                    value={this.state.imageW}
                                    onChange={(e) =>
                                        this.setState({ imageW: parseInt(e.target.value, 10) })
                                    }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Logo Height:
                            </Form.Label>
                            <Col sm="1">
                                <input
                                    size="8"
                                    disabled={!this.state.includeImage}
                                    style={{ marginTop: "0.5rem" }}
                                    type="number"
                                    value={this.state.imageH}
                                    onChange={(e) =>
                                        this.setState({ imageH: parseInt(e.target.value, 10) })
                                    }
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Form.Label style={{ color: "black" }} column sm="1">
                                Truck's QR Code
                            </Form.Label>
                            <Col sm="3">
                                <QRCode
                                    id="789123"
                                    value={this.state.truck}
                                    size={this.state.size}
                                    fgColor={this.state.fgColor}
                                    bgColor={this.state.bgColor}
                                    level="H"
                                    includeMargin={true}
                                    imageSettings={
                                        this.state.includeImage
                                            ? {
                                                src: this.state.imageSrc,
                                                height: this.state.imageH,
                                                width: this.state.imageW,
                                                x: null,
                                                y: null,
                                                excavate: true,
                                            }
                                            : null
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: "1rem" }} as={Row} >
                            <Col sm="1">
                            </Col>
                            <Col sm="3">
                                <Button onClick={this.downloadQR2} >
                                    Download QR Code
                                </Button>
                            </Col>
                        </Form.Group>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        i18n: state.i18n,
        auth: state.auth,
    };
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerator)