import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDelete';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, Button as AntdButton } from 'antd';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import moment from 'moment';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker; 
const { Column } = Table;
const { Option } = Select;
export class OutgoingTransmittal extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            outgoingTransmittalList: [],
            selectedRowKeys: [],

            documentType: '',
            documentCode: '',
            description: '',
            toUserClient: [],
            actionType: '',
            filePath: '',
            fileList: [],

            outgoingTranmittalId: 0,
            type: '', //1 deliverable , 2 documentType 

            customerList: [],
            userList: [],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            moments: [],
            dateStrings: [], 

            formId: null
        };


    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        const responseUser = await handleRequest("GET", "/User/getCompanyItems");

        if (Boolean(responseUser) && Boolean(responseUser.data) && responseUser.data.length !== 0) {
            this.setState({
                userList: responseUser.data,
            });
        }

        var m1 = moment().subtract(15,"days");
        var m2 = moment().add(15,"days"); 

        var mArray = [];
        mArray.push(m1);
        mArray.push(m2); 
        this.setState({
            moments: mArray
        })
        this.setComboboxes();
        this.restartTable(mArray); 
        this.setStandardizations();

    }

    save = async () => {

        const obj = {
            id: this.state.outgoingTransmittalId,
            filePath: this.state.filePath

        }


        if (this.state.type === 1)
            await handleRequest("POST", "/OutgoingTransmittalDeliverable/update", obj);
        if (this.state.type === 2)
            await handleRequest("POST", "/OutgoingTransmittalDocument/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable(this.state.moments);

    }

    edit = (row) => {

        this.setState({
            outgoingTransmittalId: row.id,

            deliverable: row.deliverable,
            departmentId: row.departmentId,
            disciplineId: row.disciplineId,
            workflowId: row.workflowId,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.outgoingTransmittalId
        }


        if (this.state.type === 1)
            await handleRequest("POST", "/OutgoingTransmittalDeliverable/delete", obj);
        if (this.state.type === 2)
            await handleRequest("POST", "/OutgoingTransmittalDocument/delete", obj);

        this.restartTable(this.state.moments);
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            outgoingTransmittalId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async (ms) => {

        if(Boolean(ms) && ms.length===2)
        var obj = {startDate:ms[0].toDate(),endDate:ms[1].toDate() }  ;
        else {
            var m1 = moment().subtract(15,"days");
            var m2 = moment().add(15,"days"); 
    
            var mArray = [];
            mArray.push(m1);
            mArray.push(m2); 
            this.setState({
                moments: mArray
            });
            ms = mArray; 
         obj = {startDate:ms[0].toDate(),endDate:ms[1].toDate() }  ;

        }
        console.log(obj); 
        const responseDocument = await handleRequest("POST", "/OutgoingTransmittalDocument/getAllByCompany2",obj);
        const responseDeliverable = await handleRequest("POST", "/OutgoingTransmittalDeliverable/getAllByCompany2",obj);
        const _outgoingTransmittalList = [];
        var index = 0;
        if (responseDocument.data.length !== 0) {
            const list = responseDocument.data; 
            list.forEach(element => {
                element.id = '2~' + element.id;
                element.key = element.id;
                element.index = ++index;
                element.description = element.transmittalDocumentType.type + ' - ' + element.transmittalMaterialEquipment.description;
                element.department = element.transmittalDocumentType.department.departmentName;
                console.log(element.transmittalMaterialEquipment.discipline);
                element.discipline = element.transmittalMaterialEquipment.discipline.disciplineName;
                element.documentType = element.transmittalDocumentType.type;
                element.documentCode = element.transmittalDocumentType.abbreviation;
                element.approvalDate = Boolean(element.approvalDate) ? moment(new Date(element.approvalDate)).format("DD/MM/YYYY HH:mm"):"" ; 
                element.submittalDate = Boolean(element.submittalDate) ?  moment(new Date(element.submittalDate)).format("DD/MM/YYYY HH:mm"):"" ;
                element.replyDate =  Boolean(element.replyDate) ?  moment(new Date(element.replyDate)).format("DD/MM/YYYY HH:mm"):"" ; 
                element.response = Boolean(element.clientReplyType) ? element.clientReplyType.type : ''; 
                element.status = Boolean(element.step) ? element.step.stepName : ''; 
                let by = [];
                console.log(element.byUser);
                if (Boolean(element.byUser) && this.state.userList.length > 0)
              {  let obj = this.state.userList.find(p => p.id ==  element.byUser);
                by.push(obj.staff.fullName); }

                element.by = by.toString();

                let to = [];
                if (element.toType == 1) {
                    if (Boolean(element.toUser) && element.toUser.length > 0 && this.state.userList.length > 0)
                        element.toUser.forEach(element => {
                            let obj = this.state.userList.find(p => p.id == element);
                            to.push(obj.staff.fullName); 
                           // to.push(obj.username);
                        });

                    element.to = to.toString();
                }

                if (element.toType == 2) {
                    if (Boolean(element.toClient) && element.toClient.length > 0 && this.state.customerList.length > 0)
                        element.toClient.forEach(element => {
                            let obj = this.state.customerList.find(p => p.id == element);
                            to.push(obj.fullName);
                        });

                    element.to = to.toString();
                }



                _outgoingTransmittalList.push(element);


            });

        }

        if (responseDeliverable.data.length !== 0) {
            const list = responseDeliverable.data;
            list.forEach(element => {
                element.id = '1~' + element.id;
                element.key = element.id;
                element.index = ++index;
                element.description = element.deliverable.deliverable;
                element.department = element.deliverable.department.departmentName;
                element.discipline = element.deliverable.discipline.disciplineName;

                element.documentType = '';
                element.documentCode = '';

                element.approvalDate = Boolean(element.approvalDate) ? moment(new Date(element.approvalDate)).format("DD/MM/YYYY HH:mm"):"" ; 
                element.submittalDate = Boolean(element.submittalDate) ?  moment(new Date(element.submittalDate)).format("DD/MM/YYYY HH:mm"):"" ;
                element.replyDate =  Boolean(element.replyDate) ?  moment(new Date(element.replyDate)).format("DD/MM/YYYY HH:mm"):"" ; 
                element.response = Boolean(element.clientReplyType) ? element.clientReplyType.type : ''; 
                element.status = Boolean(element.step) ? element.step.stepName : ''; 

                let by = [];

                if (Boolean(element.byUser) && element.byUser.length > 0 && this.state.userList.length > 0)
                    element.byUser.forEach(element => {
                        let obj = this.state.userList.find(p => p.id == element);
                        by.push(obj.username);
                    });

                element.by = by.toString();

                let to = [];
                if (element.toType == 1) {
                    if (Boolean(element.toUser) && element.toUser.length > 0 && this.state.userList.length > 0)
                        element.toUser.forEach(element => {
                            let obj = this.state.userList.find(p => p.id == element);
                            to.push(obj.username);
                        });

                    element.to = to.toString();
                }

                if (element.toType == 2) {
                    if (Boolean(element.toClient) && element.toClient.length > 0 && this.state.customerList.length > 0)
                        element.toClient.forEach(element => {
                            let obj = this.state.customerList.find(p => p.id == element);
                            to.push(obj.fullName);
                        });

                    element.to = to.toString();
                }



                _outgoingTransmittalList.push(element);
            });

        }


        this.setState({
            outgoingTransmittalList: _outgoingTransmittalList,
        });


    }


    setComboboxes = async () => {

        const response = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (Boolean(response.data) && response.data.length > 0) {
            this.setState({
                customerList: response.data
            });
        }





    }
    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Deliverable" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Deliverable"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Deliverable");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            deliverable: '',
            outgoingTransmittalId: 0,
            departmentId: 0,
            disciplineId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.resetInputs();
    }



    resetInputs = () => {
        this.setState({
            selectedRowKeys: [],
            outgoingTransmittalId: null,
            type: 0,
            documentType: '',
            documentCode: '',
            description: '',
            toUserClient: [],
            actionType: '',
            filePath: '',
            hideInputs: true,
            modalDeleteShow: false,
            hideUpdate: true,

        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRows);
        const row = selectedRows.pop();
        this.setState({
            selectedRowKeys: selectedRowKeys,
            outgoingTransmittalId: parseInt(row.id.split('~').pop()),
            type: parseInt(row.id.split('~')[0]),

            documentType: row.documentType,
            documentCode: row.documentCode,
            description: row.description,
            toUserClient: row.to,
            actionType: row.actionType,
            filePath: row.filePath,

            hideInputs: false,
            hideUpdate: false,
        });
    };


    downloadFile = async (rowData) => {
        if (rowData.filePath.includes(API_BASE)) {
            window.location.href = rowData.filePath;
        }
    }


    render() {
        const { selectedRowKeys } = this.state;

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            type: "radio"
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen submittal log will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {/* {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    } */}

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >



                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Document Type" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" disabled={true} value={this.state.documentType} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Description" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" disabled={true} value={this.state.description} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Document Code" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" disabled={true} value={this.state.documentCode} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="To" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" disabled={true} value={this.state.toUserClient} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Action Type" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" disabled={true} value={this.state.actionType} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Action Type" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Upload {...props}
                                                    showUploadList={true}
                                                    fileList={this.state.fileList}
                                                    onChange={({ fileList }) => {
                                                        if (fileList.length == 1) {
                                                            var filePath = "";
                                                            if (Boolean(fileList[0].response)) {
                                                                filePath = fileList[0].response.url;
                                                                this.showSuccess("file uploaded successfully");
                                                                // fileName: info.file.name, 
                                                            }
                                                            this.setState({
                                                                fileList: fileList,
                                                                filePath: filePath,
                                                            });
                                                        }
                                                    }}
                                                    beforeUpload={(fileList) => {
                                                        if (this.state.fileList.length > 0) {
                                                            this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                            return false;
                                                        }
                                                        return true;
                                                    }}
                                                    onRemove={({ fileList }) => {
                                                        this.setState({
                                                            fileList: [],
                                                            filePath: "",
                                                        })
                                                    }
                                                    }>
                                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                        <UploadOutlined />Upload File
                            </AntdButton>
                                                </Upload>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                         



                                {/* <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div> */}



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {
                                      <FormAnt.Item
                                      label={<FormattedMessage id="todo" defaultMessage="Filter" />}
                                      name="OrganizationalLevelDesignation"
                                      rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                      <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                          <Col sm="6">

                                          <RangePicker picker={"day"}  onChange={(value, dateString) => {
                                        console.log(value);
                                        console.log(dateString);
                                        this.setState({ 
                                            moments: value,
                                            dateStrings: dateString 
                                        })
        
                                       this.restartTable(value); 
        
                                       
        
                                    }} />

                                          </Col>
                                          <Col sm="6">
                                          </Col>
                                      </Form.Group>
                                  </FormAnt.Item>
                                   
                                }

                        {this.state.outgoingTransmittalList.length > 0 && <Table rowSelection={rowSelection} style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.outgoingTransmittalList} bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Transmittal No" dataIndex="transmittalNo" />
                            <Column title="Rev No." dataIndex="revNo" />
                            <Column title="File" render={(value, row, index) => {

                                if (Boolean(row.filePath) && row.filePath.length > 0)
                                    return <React.Fragment>
                                        <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(row)} type="primary" shape="round" icon={<DownloadOutlined />} >

                                        </AntdButton>

                                    </React.Fragment>


                            }} />
                            <Column title="Department" dataIndex="department" />
                            <Column title="Discipline" dataIndex="discipline" />
                            <Column title="Document Type" dataIndex="documentType" />
                            <Column title="Description" dataIndex="description" />
                            <Column title="Document Code" dataIndex="documentCode" />
                            <Column title="By" dataIndex="by" />
                            <Column title="To" dataIndex="to" />
                            <Column title="Action Type" dataIndex="actiontype" /> 
                            <Column title="Approval Date" dataIndex="approvalDate" />
                            <Column title="Submittal date" dataIndex="submittalDate" />
                            <Column title="Start Date" render={(value, row, index) => {

                                if (Boolean(row.startDate))
                                    return moment(row.startDate).format("DD-MM-YYYY HH:mm:ss");
                                else return "No";

                            }} />
                            <Column title="Reply date" dataIndex="replyDate" />
                            <Column title="Reference No" dataIndex="referenceNo" />
                            <Column title="From" dataIndex="to" /> 
                            <Column title="Response" dataIndex="response" />
                            <Column title="Status" dataIndex="status" />

                            <Column title="Remarks" dataIndex="remarks" />


                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
      
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingTransmittal) 