import React, { Component } from 'react'
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { Card } from "primereact/card";

class LinkedText extends Component {

    constructor(props) {
        super(props)

        this.state = {
            linkedItemText: null,
            id: null
        }
    }

    componentDidMount() {
        var pathArray = this.props.location.pathname.split('/');
        if (pathArray.length === 3)
            this.showLinkedItem(pathArray[2]);

    }

    showLinkedItem = async (id) => {

        var response = await handleRequest("GET", "/DocumentedReportItem/" + id);
        if (response.data !== null && response.data !== undefined) {

            this.setState({
                modalLinkedItemText: true,
                linkedItemText: response.data.itemValue
            })

        } else {
            this.showError();
        }
    }

    render() {
        return (
            <Card>
                <div>
                    <p  style={{textAlign: "left", color: '#0070C0', font: 'bold', fontSize: '15pt' }} >
                        {this.state.linkedItemText}
                    </p>
                </div>
            </Card>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedText)
