/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import CustomMaterialMenuEditDeleteRevision from '../settings/ForComponents/CustomMaterialMenuEditDeleteRevision';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import MaskedInput from 'antd-mask-input'

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "SAMaterialsSystemSpecifications";

export class SAMaterialsSystemSpecifications extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            code: '',
            revno: '',
            description: '',
            date: null,
            attachmentUrl: '',

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            code:  this.state.code,
            revno: this.state.revno,
            description: this.state.description,
            date: this.state.date,

            attachmentUrl: this.state.filePath
        };
        if (obj.id === null)
            await handleRequest("POST", "/SaMaterialsSystemSpecifications/add", obj);
        else
            await handleRequest("POST", "/SaMaterialsSystemSpecifications/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        let code = row.code;
        


        let fileList = [];
        if (Boolean(row.attachmentUrl) && row.attachmentUrl.length > 0)
            fileList = [{
                uid: 1,
                name: row.attachmentUrl,
                url: row.attachmentUrl,
                thumbUrl: row.attachmentUrl,
            }]

        this.formRef.current.setFieldsValue({
            id: row.id,
            code: code,
            revno: row.revno,
            description: row.description,
            date: moment(row.date),
            filePath: row.attachmentUrl,
            attachmentUrl: row.attachmentUrl
        });

        this.setState({
            id: row.id,
            code: code,
            revno: row.revno,
            description: row.description,
            date: moment(row.date),
            relatedCom: row.relatedCom,
            filePath: row.attachmentUrl,
            fileList: fileList,
            attachmentUrl: row.attachmentUrl,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    revision = (row) => {

        let code = row.code;

        let fileList = [];
        if (Boolean(row.attachmentUrl) && row.attachmentUrl.length > 0)
            fileList = [{
                uid: 1,
                name: row.attachmentUrl,
                url: row.attachmentUrl,
                thumbUrl: row.attachmentUrl,
            }]

        this.formRef.current.setFieldsValue({
            id: row.id,
            code: code,
            revno: row.revno + 1,
            description: row.description,
            date: moment(row.date),
            filePath: row.attachmentUrl,
            attachmentUrl: row.attachmentUrl
        });

        this.setState({
            id: row.id,
            code: code,
            revno: row.revno + 1,
            description: row.description,
            date: moment(row.date),
            filePath: row.attachmentUrl,
            fileList: fileList,
            attachmentUrl: row.attachmentUrl,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/SaMaterialsSystemSpecifications/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenuEditDeleteRevision row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} onRevision={this.revision.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/SaMaterialsSystemSpecifications/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({

            filePath: '',
            fileList: [],
            code: '',
            revno: '',
            description: '',
            date: null,
            attachmentUrl: '',

        });

        this.setState({
            id: null,
            filePath: '',
            fileList: [],
            code: '',
            revno: '',
            description: '',
            date: null,
            attachmentUrl: '',
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }



    cancel = () => {

        this.resetInputs();
    }








    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    downloadFile = async (url) => {
        window.location.href = url;
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAMSS.Code").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SAMSS.Code" defaultMessage="Code" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="code"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Code")) ? this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Code").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Code")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Code").translateRequired} defaultMessage="This field is required" /> : '' }]} >

                                                <MaskedInput mask="11-SAMSS-111"
                                                formatCharacters={{
                                                    'A': null
                                                  }}
                                                maxLength={6}  style={{ width: "50%" }} id="SAMSSCode" value={this.state.code} onChange={(e) => { this.setState({ code: e.target.value }) }} />


                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAMSS.Revno").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SAMSS.Revno" defaultMessage="Rev. No" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="revno"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Revno")) ? this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Revno").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Revno")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SAMSS.Revno").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber id="SAMSS.Revno" disabled={Boolean(this.state.id) && this.state.id > 0} placeholder="Rev. No"
                                                            min={0} value={this.state.revno} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    revno: value
                                                                });
                                                                this.setState({ revno: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAMSSDescription").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SAMSSDescription" defaultMessage="SAMSS Description" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="description"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDescription")) ? this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDescription").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDescription")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDescription").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input style={{ width: "100%" }} id="SAMSSDescription" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAMSSDAte").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SAMSSDAte" defaultMessage="Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="date"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDAte")) ? this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDAte").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDAte")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SAMSSDAte").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="SAMSSDAte" format="DD-MM-YYYY" value={this.state.date} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                date: date
                                                            });
                                                            this.setState({
                                                                date: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAMSSAttachment").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="SAMSSAttachment" defaultMessage="Attachment" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="attachmentUrl"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSSAttachment")) ? this.state.dynamicInputs.find(p => p.inputKey === "SAMSSAttachment").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "SAMSSAttachment")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "SAMSSAttachment").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Upload {...props} id="SAMSSAttachment"
                                                            showUploadList={true}
                                                            fileList={this.state.fileList}
                                                            onChange={({ fileList }) => {
                                                                if (fileList.length == 1) {
                                                                    var filePath = "";
                                                                    if (Boolean(fileList[0].response)) {
                                                                        filePath = fileList[0].response.url;
                                                                        this.showSuccess("file uploaded successfully");
                                                                        // fileName: info.file.name, 
                                                                    }
                                                                    this.setState({
                                                                        fileList: fileList,
                                                                        filePath: filePath,
                                                                    });
                                                                }
                                                            }}
                                                            beforeUpload={(fileList) => {
                                                                if (this.state.fileList.length > 0) {
                                                                    this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                                    return false;
                                                                }
                                                                return true;
                                                            }}
                                                            onRemove={({ fileList }) => {
                                                                this.setState({
                                                                    fileList: [],
                                                                    filePath: "",
                                                                })
                                                            }
                                                            }>
                                                            <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                                <UploadOutlined /> Upload
                            </AntButton>
                                                        </Upload>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                    <Column title="S/N" dataIndex="index" />
                                    <Column title="Code" dataIndex="code" />
                                    <Column title="Rev No" dataIndex="revno" />
                                    <Column title="Description" dataIndex="description" />
                                    <Column title="Date" render={(value, row, index) => {

                                        if (Boolean(row.date))
                                            return moment(row.date).format("DD/MM/YYYY");

                                    }} />
                                    <Column title="Attachment" render={(value, row, index) => {

                                        if (Boolean(row.attachmentUrl))
                                            return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(row.attachmentUrl)} type="primary" shape="round" icon={<DownloadOutlined />} >

                                            </AntButton>

                                    }} />
                                    <Column title="Action" render={(value, row, index) => {

                                        return this.actionTemplate(row);

                                    }} />
                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SAMaterialsSystemSpecifications)
