import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Col, Row, Form, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input } from 'antd';
import QrReader from 'react-qr-reader';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import moment from 'moment';

const pageName = "TimelineQRScan";

export class TimelineQRScan extends Component {

    constructor() {
        super();
        let d = new Date();
        d.setHours(0, 0, 0, 0);
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            tableList: [],
            userLogList: [],
            qrCodeList: [],
            qrCodeUserScans: [],
            qrCodeId: 0,
            selectedQrCode: null,
            scanForTimeInOut: "Scan for Time In",
            delay: 500,
            result: "",
            date: moment(d),

            columns: [
                {
                    key: "key",
                    title: "Action",
                    render: (value, row, index) => {
                        return this.actionTemplate(row);
                    }
                },
                {
                    key: "id",
                    title: "ID",
                    render: (data) => {
                        if (data != null && data != undefined) {
                            if (data.user !== undefined && data.user !== null)
                                return data.user.id;
                        }
                        return "";
                    }
                },
                {
                    key: "name",
                    title: "Name",
                    render: (data) => {
                        if (data != null && data != undefined) {
                            if (data.user !== undefined && data.user !== null)
                                return data.user.name + " " + data.user.middleName + " " + data.user.lastName;
                        }
                        return "";
                    }
                },
                {
                    key: "department",
                    title: "Department",
                    render: (data) => {
                        if (data.user.staff != null && data.user.staff != undefined) {
                            if (data.user.staff.department !== undefined && data.user.staff.department !== null)
                                return data.user.staff.department.departmentName;
                        }
                        return "";
                    }
                },
                {
                    key: "designation",
                    title: "Designation",
                    render: (data) => {
                        if (data.user.staff != null && data.user.staff != undefined) {
                            if (data.user.staff.designation !== undefined && data.user.staff.designation !== null)
                                return data.user.staff.designation.designationName;
                        }
                        return "";
                    }
                },
                {
                    key: "qrCodeScanDateTime",
                    title: "Date, time",
                    dataIndex: "qrCodeScanDateTime"
                },
                {
                    key: "location",
                    title: "Location",
                    dataIndex: "location"
                },
                {
                    key: "coordinates",
                    title: "Coordinates",
                    render: (data) => {
                        if (data != null && data != undefined) {
                            return "Lat: " + data.latitude + " / " + "Long: " + data.longitude;
                        }
                        return "";
                    }
                },
                {
                    key: "distance",
                    title: "Distance (m)",
                    dataIndex: "distance"
                },
                {
                    key: "scanningStatus",
                    title: "Scanning Status",
                    dataIndex: "scanningStatus"
                }
            ],

            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            dynamicAttributes: [],
            formId: 0
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        });


        this.restartTable();
        this.testLocation();
    }

    getUserSettings = async (formId) => {
        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    testLocation = async () => {
        if (navigator.geolocation) {
            const successCallback = async (position) => {
                this.setState({
                    latitude: position.coords.latitude.toString(),
                    longitude: position.coords.longitude.toString()
                });
                console.log(position);
            };

            const errorCallback = (error) => {
                this.setState({
                    latitude: "",
                    longitude: ""
                });
                console.error(error);
            };

            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        } else {
            this.setState({
                latitude: "",
                longitude: ""
            });
        }
    }

    save = async () => {
        let _today = new Date();
        var HH = String(_today.getHours()).padStart(2, '0');
        var minute = String(_today.getMinutes()).padStart(2, '0');
        var dd = String(_today.getDate()).padStart(2, '0');
        var mm = String(_today.getMonth() + 1).padStart(2, '0');
        var yyyy = _today.getFullYear();

        let today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
        let today2 = dd + '/' + mm + '/' + yyyy;

        if (this.state.selectedQrCode !== null) {
            let scanningStatus = false;
            let lat1 = parseFloat(this.state.latitude);
            let lon1 = parseFloat(this.state.longitude);
            let lat2 = parseFloat(this.state.selectedQrCode.latitude);
            let lon2 = parseFloat(this.state.selectedQrCode.longitude);

            let distance = this.calcCrow(lat1, lon1, lat2, lon2);
            let distanceMeter = distance * 1000;

            if (distance < 50) {
                scanningStatus = true;
            }

            const obj = {
                qrCodeId: this.state.qrCodeId,
                qrCodeData: this.state.result,
                qrCodeScanDate: today2,
                qrCodeScanDateTime: today,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                location: this.state.location,
                distance: distanceMeter.toString(),
                scanningStatus: (scanningStatus) ? "Acceptable" : "Unacceptable",
                scannedById: this.props.auth.user.id
            }

            var responseAdd = await handleRequest("POST", "/TimelineQRScan/add", obj);
            if (responseAdd.data !== null && responseAdd.data !== undefined) {
                if (responseAdd.data === "SUCCESS") {

                    if (this.state.userLogList.length > 0) {
                        //OUT
                        let timeOut = moment(new Date());
                        let timeIn = moment(this.state.userLogList[0].timeIn);
                        let flagWorkingHours = 0;
                        let flagOvertime = 0;

                        if (Boolean(timeOut) && Boolean(timeIn)) {
                            let overtime = 0;
                            let result = timeOut.diff(timeIn, 'hours', true);

                            if (result > 9)
                                overtime = (result - 9).toFixed(2);

                            flagWorkingHours = (result - 1).toFixed(2);
                            flagOvertime = overtime;
                        }

                        let obj = {
                            id: this.state.userLogList[0].id,
                            staffId: this.state.userLogList[0].staffId,
                            date: this.state.date,
                            timeIn: moment(this.state.userLogList[0].timeIn),
                            departmentId: Boolean(this.state.userLogList[0].departmentId) ? this.state.userLogList[0].departmentId : null,
                            designationId: Boolean(this.state.userLogList[0].designationId) ? this.state.userLogList[0].designationId : null,
                            timeOut: timeOut,
                            workingHours: 0,   //flagWorkingHours
                            overtime: 0,       //flagOvertime
                        };

                        await handleRequest("POST", "/TimesheetLog/update", obj);
                    } else {
                        //IN
                        let timeIn = new Date();

                        let timeOut = new Date();
                        timeOut.setHours(17, 0, 0, 0);

                        let obj = {
                            staffId: this.props.auth.user.staffId,
                            date: this.state.date,
                            timeIn: timeIn,
                            departmentId: Boolean(this.props.auth.user.staff) ? this.props.auth.user.staff.departmentId : null,
                            designationId: Boolean(this.props.auth.user.staff) ? this.props.auth.user.staff.designationId : null,
                            timeOut: timeOut,
                            workingHours: 0,
                            overtime: 0,
                        };

                        await handleRequest("POST", "/TimesheetLog/add", obj);
                    }

                    this.resetInputs();
                    this.showSuccess();
                    this.restartTable();
                }
                else {
                    this.showError();
                }
            }
        } else {
            this.showWarning("This QR code is no longer valid!");
        }
    }

    delete = async () => {
        const obj = {
            id: this.state.qrId
        };
        await handleRequest("POST", "/TimelineQRScan/delete", obj);
        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            qrId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successful" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenuDelete row={rowData} onDeleteRow={this.deleteModal.bind(this)} />
        </React.Fragment>;
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/TimelineQRSetting/getAllByCompany");

        if (response.data.length !== 0) {
            this.setState({
                qrCodeList: response.data,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                qrCodeList: [],
            });
        }

        const responseScans = await handleRequest("GET", "/TimelineQRScan/getAllByCompany");

        let _today = new Date();
        var dd = String(_today.getDate()).padStart(2, '0');
        var mm = String(_today.getMonth() + 1).padStart(2, '0');
        var yyyy = _today.getFullYear();

        let today2 = dd + '/' + mm + '/' + yyyy;
        let todayScans = [];

        if (responseScans.data.length !== 0) {
            this.setState({
                qrCodeUserScans: responseScans.data,
            });
        }

        if (responseScans.data.length === 0) {
            this.setState({
                qrCodeUserScans: [],
            });
        }

        let todaysScan = 0;

        for (let index = 0; index < responseScans.data.length; index++) {
            if (responseScans.data[index].qrCodeScanDate === today2)
                todaysScan++;
        }

        if (todayScans.length > 0) {
            if ((todayScans.length % 2) === 0) {
                this.setState({
                    scanForTimeInOut: "Scan for Time In",
                });
            } else {
                this.setState({
                    scanForTimeInOut: "Scan for Time Out",
                });
            }
        } else {
            this.setState({
                scanForTimeInOut: "Scan for Time In",
            });
        }

        const responseList = await handleRequest("POST", "/TimesheetLog/getAllByDate", this.state.date);

        if (responseList.data.length !== 0) {
            const list = responseList.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        let flagUserLogList = [];

        for (let index = 0; index < responseList.data.length; index++) {
            if (this.props.auth.user.staffId !== undefined && this.props.auth.user.staffId !== null
                && responseList.data[index].staffId == this.props.auth.user.staffId) {
                flagUserLogList.push(responseList.data[index]);
            }
        }

        if (responseList.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }

        this.setState({
            userLogList: flagUserLogList,
        });
    }

    createNew = () => {
        if (this.state.latitude !== "" && this.state.longitude !== "") {
            this.setState({
                qrId: 0,
                hideInputs: false,
                camera: true,
                modalDeleteShow: false
            });
        } else {
            this.testLocation();
            this.showWarning("Something went wrong. Please allow location data sharing.");
        }
    }

    stop = () => {
        this.setState({
            qrId: 0,
            modalDeleteShow: false,
            hideInputs: true
        });
    }

    resetInputs = () => {
        this.setState({
            qrId: 0,
            qrCodeId: 0,
            qrCode: "",
            location: "",
            selectedQrCode: null,
            modalDeleteShow: false,
            hideInputs: true
        });
    }

    edit = (row) => {
        console.log(row, "Do Nothing!");
    }

    cancel = () => {
        this.resetInputs();
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    handleScan = (data) => {
        if (data) {
            this.setState({ result: data });
            console.log(data);

            let flagQRCodeId = 0;
            let flagLocation = "";
            let flagQRCode = null;

            for (let index = 0; index < this.state.qrCodeList.length; index++) {
                if (this.state.qrCodeList[index].qrCodeData === data) {
                    flagQRCodeId = this.state.qrCodeList[index].id;
                    flagLocation = this.state.qrCodeList[index].location;
                    flagQRCode = this.state.qrCodeList[index];
                }
            }

            if (flagQRCodeId !== 0) {
                this.setState({
                    camera: false,
                    qrCodeId: flagQRCodeId,
                    location: flagLocation,
                    selectedQrCode: flagQRCode,
                    hideInputs: true
                }, this.save);
            } else {
                this.showWarning("This QR code is no longer valid!");
            }
        }
    }

    handleError = (err) => {
        console.error(err);
    }

    calcCrow = (lat1, lon1, lat2, lon2) => {
        var R = 6371;
        var dLat = this.toRad(lat2 - lat1);
        var dLon = this.toRad(lon2 - lon1);
        var lat1 = this.toRad(lat1);
        var lat2 = this.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    toRad = (Value) => {
        return Value * Math.PI / 180;
    }

    render() {
        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">

                            <Modal
                                show={this.state.modalMessage}
                                onHide={this.showOrHideModalMessage}
                                backdrop="static"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>

                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <Row >
                                            <Col sm="12">
                                                {this.state.messageTip == "Success" &&
                                                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                {this.state.messageTip == "Warning" &&
                                                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                {this.state.messageTip == "Error" &&
                                                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                            </Col>
                                        </Row>
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <Row >
                                        <Col sm="12">
                                            {this.state.message}
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {this.state.hideInputs &&
                                                <ReactButton id="TimelineQRScanCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}>{this.state.scanForTimeInOut}</ReactButton>
                                            }
                                            {!this.state.hideInputs &&
                                                <ReactButton id="TimelineQRScanCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '1rem' }}>
                                    <Row>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="10">
                                            {this.state.camera && <QrReader
                                                ref="qrReader2"
                                                delay={this.state.delay}
                                                style={{ height: 300, width: 300 }}
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                            />
                                            }
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                    </Row>
                                </div>

                                <Table style={{ marginTop: '3rem' }} columns={this.state.columns} dataSource={this.state.qrCodeUserScans} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }}></Table>

                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis></Whatis>}
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (<div></div>);
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineQRScan) 