/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'react-bootstrap';
import { Button as PrimeButton } from "primereact/button";
import { Modal, Form, Col, Row } from 'react-bootstrap';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { handleRequest } from '../../redux/config/env';
import { InputText } from "primereact/inputtext";
import { DatePicker, Cascader, Input, Select, Divider, Radio } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
import { Column } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import "./css/ShipmentLog.scss";
import $ from 'jquery';
import { FormattedMessage } from "react-intl";
import { PlusOutlined  } from '@ant-design/icons';
import * as Mnd from "../../partials/content/CustomComponents";

const { Option } = Select;

const _DurationTypes = [
    { label: "Year", value: 1 },
    { label: "Month", value: 2 },
    { label: "Day", value: 3 },
    { label: "Hour", value: 4 },
    { label: "Minute", value: 5 },
    { label: "Second", value: 6 }
];

export class MaintenanceLogSub extends Component {

    constructor() {
        super();
        this.state = {
            equipmentLogId: "",
            equipmentType: "",
            department: "",
            equipmentCode: "",
            equipmentName: "",
            lastButtonModal: "Save",   
            lastButtonModalChecklist: "Save",
            modalShow: false,
            deleteModal: false,
            maintenanceTypeId: null,
            scope: "",
            periodOfMaintenance: "",
            periodOfMaintenanceType: "Select Period",
            durationOfMaintenance: "",
            durationOfMaintenanceType: "Select Time Period",
            maintenanceLocation: null,
            values: [],
            selectedItems: [],
            periods: [],
            timePeriods: [],
            maintenanceLocations: [],
            periodId: null,
            timePeriodId: null,
            maintenanceLocationId: null,
            date: null,
            dateFixed: null,
            equipmentMaintenanceLogs: [],
            selectedStatus: null,
            nodes: [],
            statuses: [
                { label: 'Planned', value: 'Planned' },
                { label: 'Performed', value: 'Performed' },
                { label: 'Not Performed', value: 'Not Performed' }
            ],
            selectedMaintenance: null,
            status: "Not Performed",

            EquipmentPartList:[],
            EquipmentPartCodeName:[],
            modalShowChecklist:false,
            MSLActions:[],
            MSLCheckItems:[],
            MSLCriterias:[],
            durationTypes: _DurationTypes.slice(),
            durationType: 0,
            duration: "",
            durationNumber: "",
            durationObj: {},
           
            hiddenFixed:true,
            hiddenFixedHour:true,
            hiddenFixedKm:true,
            hiddenFixedTime:true,
            hiddenPeriodic:true,
            hiddenPeriodicHour:true,
            hiddenPeriodicKm:true,
            hiddenPeriodicTime:true,
            hiddenReference:true,
            equipmentPartListId:0,
            period:0,

        };

        this.actionTemplate = this.actionTemplate.bind(this);

        this.onMaintenanceLocationChange = this.onMaintenanceLocationChange.bind(this);

        this.onStatusChange = this.onStatusChange.bind(this);
        //this.statusEditor = this.statusEditor.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.statusItemTemplate = this.statusItemTemplate.bind(this);

        this.checkListBodyTemplate = this.checkListBodyTemplate.bind(this);

        this.onMaintenanceTypeFilterChange = this.onMaintenanceTypeFilterChange.bind(this);
    }

    componentDidMount = async () => {
        this.setState({
            equipmentLogId: this.props.row.equipmentLog.id,
            equipmentType: this.props.row.equipmentLog.equipmentTypeName,
            department: this.props.row.equipmentLog.department.departmentName,
            equipmentCode: this.props.row.equipmentLog.equipmentCode,
            equipmentName: this.props.row.equipmentLog.equipmentDescription
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: today,
            dateFixed: null
        });

        const response = await handleRequest("GET", "/MaintenanceType/getNodes");

        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }

        const response1 = await handleRequest("GET", "/Code/getAll");

        if (response1.data.length !== 0) {

            const columns = response1.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "timePeriod") {
                    options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                periods: options,
                timePeriods: options
            });

            options = [];

            try {
                 for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "maintenanceLocation") {
                     options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            } 
            } catch (error) {
                
            }
          

            this.setState({
                maintenanceLocations: options
            });
        }

        const response2 = await handleRequest("GET", "/EquipmentMaintenance/getAll");

        if (response2.data.length === 0) {
            this.setState({
                equipmentMaintenanceLogs: [],
            });
        }

        if (response2.data.length !== 0) {

            const columns = response2.data;

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            console.log(options);

            this.setState({
                equipmentMaintenanceLogs: options,
            });
        }

        this.getEquipmentPartList();
        this.getCodesMSLCheckItem();
        this.getCodesMSLCriteria();
        this.getCodesMSLAction();
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Success"
        });
      }; 
        
      showError(msg) {
        if (!msg) {
            msg =  <FormattedMessage id="GeneralMessageError1" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Error"
        });
      };
      
      showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" />;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Warning"
        });
      };

      showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    } 

    renderStatusFilter() {
        return (
            <Dropdown style={{ width: '100%' }} placeholder="Select Status" value={this.state.selectedStatus}
                options={this.state.statuses} itemTemplate={this.statusItemTemplate} onChange={this.onStatusChange} showClear={true} />
        );
    }

    onStatusChange(event) {
        this.dt.filter(event.value, 'status', 'equals');
        this.setState({ selectedStatus: event.value });
    }

    // statusEditor(props) {
    //     return (
    //         <Dropdown value={props.value[props.rowIndex].status} options={this.state.statuses}
    //             itemTemplate={this.statusItemTemplate} onChange={(e) => this.onEditorValueChange(props, e.value)} style={{ width: '100%' }} />
    //     );
    // }

    // onEditorValueChange = async (props, value) => {
    //     let updatedMaintenances = [...props.value];
    //     updatedMaintenances[props.rowIndex][props.field] = value;
    //     this.setState({ equipmentMaintenanceLogs: updatedMaintenances });

    //     const updatedMaintenance = {
    //         id: props.rowData.id,
    //         equipmentLogId: props.rowData.equipmentLogId,
    //         maintenanceTypeId: props.rowData.maintenanceType.id,
    //         scope: props.rowData.scope,
    //         periodOfMaintenance: props.rowData.periodOfMaintenance,
    //         periodOfMaintenanceType: props.rowData.periodOfMaintenanceType,
    //         durationOfMaintenance: props.rowData.durationOfMaintenance,
    //         durationOfMaintenanceType: props.rowData.durationOfMaintenanceType,
    //         maintenanceStartDate: props.rowData.maintenanceStartDate,
    //         maintenanceLocation: props.rowData.maintenanceLocationTypeCode.name,
    //         status: value
    //     };

    //     await handleRequest("POST", `/EquipmentMaintenance/update`, updatedMaintenance);
    // }

    statusBodyTemplate(rowData) {
        var color;
        if (rowData.status === "Performed")
            color = "qualified";
        else if (rowData.status === "Not Performed")
            color = "unqualified";
        else
            color = "new";
        return <span className={classNames('customer-badge', 'status-' + color)}>{rowData.status}</span>;
    }

    statusItemTemplate(option) {
        var color;
        if (option.value === "Performed")
            color = "qualified";
        else if (option.value === "Not Performed")
            color = "unqualified";
        else
            color = "new";
        return (
            <span className={classNames('customer-badge', 'status-' + color)}>{option.value}</span>
        );
    }

    saveOrEditMaintenance = async () => {
        if (this.state.lastButtonModal === "Save") {

            var flagStatus = "";

            if (this.state.selectedItems.length >= 2) {
                if (this.state.selectedItems[this.state.selectedItems.length - 2].data.maintenanceTypeName === "PERIODIC MAINTENANCE") {
                    flagStatus = "Planned";
                }  else {
                    flagStatus = "Not Performed";
                }
            }
            else
                flagStatus = "Not Performed";

            const newEquipmentOperatingLog = {
                equipmentLogId: this.state.equipmentLogId,
                maintenanceTypeId: this.state.values[this.state.values.length - 1],
                scope: this.state.scope,
                periodOfMaintenance: this.state.periodOfMaintenance,
                periodOfMaintenanceType: this.state.periodId,
                durationOfMaintenance: this.state.durationOfMaintenance,
                durationOfMaintenanceType: this.state.timePeriodId,
                maintenanceStartDate: this.state.date,
                maintenanceLocation: this.state.maintenanceLocation,
                status: flagStatus
            }

            await handleRequest("POST", "/EquipmentMaintenance/add", newEquipmentOperatingLog);

            this.refreshStates();
            this.restartMaintenanceTable();
        }
        else if (this.state.lastButtonModal === "Update") {

            const updatedMaintenance = {
                id: this.state.editId,
                equipmentLogId: this.state.equipmentLogId,
                maintenanceTypeId: this.state.values[this.state.values.length - 1],
                scope: this.state.scope,
                periodOfMaintenance: this.state.periodOfMaintenance,
                periodOfMaintenanceType: this.state.periodId,
                durationOfMaintenance: this.state.durationOfMaintenance,
                durationOfMaintenanceType: this.state.timePeriodId,
                maintenanceStartDate: this.state.date,
                maintenanceLocation: this.state.maintenanceLocation,
                status: this.state.status
            };

            await handleRequest("POST", "/EquipmentMaintenance/update", updatedMaintenance);

            this.refreshStates();
            this.restartMaintenanceTable();
        }
    }

    saveMaintenanceChecklist = async () => {
        if(this.state.equipmentLogId!=null && this.state.equipmentLogId!=undefined && this.state.equipmentLogId!="" &&
        this.state.equipmentPartListId!=null && this.state.equipmentPartListId!=undefined && this.state.equipmentPartListId!=0)
        {
            var Duration=[];  
            if(this.state.duration!=null && this.state.duration!=undefined && this.state.duration!=""){
                Duration.push(this.state.durationObj);
            }

            var date=null;
            if(this.state.dateFixed!=null){
                date = moment(this.state.dateFixed, 'DD/MM/YYYY');
            }

            const newMaintenanceChecklist = {
                equipmentLogId: this.state.equipmentLogId,
                equipmentPartListId: this.state.equipmentPartListId,
                checkItem: this.state.checkItem,
                criteria: this.state.criteria,
                action: this.state.action,
                duration: Duration,
                scheduleType: this.state.scheduleType,
                reference: this.state.reference,
                km: this.state.km,
                hour: this.state.hour,
                dateFixed: date,
                periodicKmPeriod: this.state.periodicKmPeriod,
                periodicHourPeriod: this.state.periodicHourPeriod,
                period: this.state.period,
                periodicTimePeriod: this.state.periodicTimePeriod,

                every: this.state.every,
                monthStyle: this.state.monthStyle,
                periodMonthPoint: this.state.periodMonthPoint,
                firstLast: this.state.firstLast,
                firstLastPoint: this.state.firstLastPoint,
                weekStyle: this.state.weekStyle,
                periodWeekPoint: this.state.periodWeekPoint,
                flSelect: this.state.flSelect,
            } 

            console.log("DDD", newMaintenanceChecklist);
            var response =  await handleRequest("POST", "/MaintenanceChecklist/add", newMaintenanceChecklist);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        modalShowChecklist:false,
                    });
                    this.showSuccess();
                    this. resetMaintenanceChecklist(true);
                }
                else {
                    this.showError();
                }
            } else {
            this.showError();
            }
        }
        else{
        this.showWarning();
        }
    }

    refreshStates = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            modalShow: !this.state.modalShow,
            editId: 0,
            maintenanceTypeId: null,
            scope: "",
            periodOfMaintenance: "",
            durationOfMaintenance: "",
            maintenanceLocation: null,
            periodId: null,
            timePeriodId: null,
            values: [],
            date: today,
            periodOfMaintenanceType: "Select Period",
            durationOfMaintenanceType: "Select Time Period",
            status: "Not Performed",
            lastButtonModal: "Save"
        });
    }

    restartMaintenanceTable = async () => {
        const response = await handleRequest("GET", "/EquipmentMaintenance/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentMaintenanceLogs: [],
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].equipmentLogId === this.props.row.id) {
                    options.push(columns[index]);
                }
            }

            console.log(options);

            this.setState({
                equipmentMaintenanceLogs: options,
            });
        }

        const response1 = await handleRequest("GET", "/Code/getAll");

        if (response1.data.length !== 0) {

            const columns = response1.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "maintenanceLocation") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                maintenanceLocations: options
            });
        }
    }

    showOrHideModal = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            modalShow: !this.state.modalShow,
            editId: 0,
            maintenanceTypeId: null,
            scope: "",
            periodOfMaintenance: "",
            durationOfMaintenance: "",
            maintenanceLocation: null,
            periodId: null,
            timePeriodId: null,
            values: [],
            date: today,
            periodOfMaintenanceType: "Select Period",
            durationOfMaintenanceType: "Select Time Period",
            status: "Not Performed",
            lastButtonModal: "Save"
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }
    
    showOrHideModalChecklist = () => {
        this.setState({
            modalShowChecklist: !this.state.modalShowChecklist
        });
        this.resetMaintenanceChecklist(true);
    }

    resetMaintenanceChecklist = async (deger) => {
        if(deger){
           await this.setState({
                equipmentPartListId:0,
                unitGroup: 0,
                partCodeName:0,
                checkItem:"",
                checkItemAck:"",
                criteria:"",
                criteriaAck:"",
                action: "",
                actionAck:"",
                durationNumber:"",
                durationType:0,
                duration:"",
                durationTypes: _DurationTypes.slice(),
    
                scheduleTypeAck: "",
                scheduleType: 0 ,
                reference:0,
                hiddenReference:true,
                hiddenFixed:true,
                hiddenFixedKm:true,
                km:"",
                hiddenFixedHour:true,
                hour:"",
                hiddenFixedTime:true,
                dateFixed:null,
                hiddenPeriodic:true,
                hiddenPeriodicKm:true,
                periodicKmPeriod:0,
                hiddenPeriodicHour:true,
                periodicHourPeriod:0,
                hiddenPeriodicTime:true,
                every:0,
                period:0,
                monthStyle:0,
                periodMonthPoint:0,
                flSelect:"",
                firstLastPoint:0,
                weekStyle:0,
                periodWeekPoint:0, 
            });
        }else{  //Bazı alanların Temizlenmesi
            await this.setState({
                hiddenFixed:true,
                hiddenFixedKm:true,
                km:"",
                hiddenFixedHour:true,
                hour:"",
                hiddenFixedTime:true,
                dateFixed:null,
                hiddenPeriodic:true,
                hiddenPeriodicKm:true,
                periodicKmPeriod:0,
                hiddenPeriodicHour:true,
                periodicHourPeriod:0,
                hiddenPeriodicTime:true,
                every:0,
                period:0,
                monthStyle:0,
                periodMonthPoint:0,
                flSelect:"",
                firstLastPoint:0,
                weekStyle:0,
                periodWeekPoint:0, 
            });
        }
    }

    onChange = async (value, data) => {

        if (data.length !== 0) {
            if (data[data.length - 1].data.maintenanceTypeName === "TIME BASED") {
                const response1 = await handleRequest("GET", "/Code/getAll");

                if (response1.data.length !== 0) {

                    const columns = response1.data;

                    var options = [];

                    for (let index = 0; index < columns.length; index++) {
                        if (columns[index].type === "timePeriod") {
                            options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                        }
                    }

                    this.setState({
                        periods: options
                    });
                }
            } else if (data[data.length - 1].data.maintenanceTypeName === "KM BASED") {
                const response1 = await handleRequest("GET", "/Code/getAll");

                if (response1.data.length !== 0) {

                    const columns = response1.data;

                    var options = [];

                    for (let index = 0; index < columns.length; index++) {
                        if (columns[index].type === "meterPeriod") {
                            options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                        }
                    }

                    this.setState({
                        periods: options
                    });
                }
            }
        }

        var flagStatus1 = "";

        if (data.length >= 2) {
            if (data[data.length - 2].data.maintenanceTypeName === "PERIODIC MAINTENANCE") {
                flagStatus1 = "Planned";
            } else {
                flagStatus1 = "Not Performed";
            }
        }
        else
            flagStatus1 = "Not Performed";

        this.setState({
            values: value,
            selectedItems: data,
            status: flagStatus1
        })
    }

    onChangeDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                date: dateString
            });
        } else {
            this.setState({
                date: today
            });
        }
    }

    onChangeDateFixed = async (date, dateString) => {
        console.log("DDD", date);
        console.log(dateString);
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                dateFixed: dateString
            });
        } else {
            this.setState({
                dateFixed: null
            });
        }
    }

    handleChangePeriod = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.periods.length; z++) {
            if (this.state.periods[z].name === e.target.value) {
                idValue = this.state.periods[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            periodId: idValue
        });
    }

    handleChangeTimePeriod = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.timePeriods.length; z++) {
            if (this.state.timePeriods[z].name === e.target.value) {
                idValue = this.state.timePeriods[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            timePeriodId: idValue
        });
    }

    onMaintenanceLocationChange(e) {
        this.setState({ maintenanceLocation: e.value });
    }

    periodTemplate(rowData, column) {
        return rowData.periodOfMaintenance + " " + rowData.periodOfMaintenanceTypeCode.name;
    }

    durationTemplate(rowData, column) {
        return rowData.durationOfMaintenance + " " + rowData.durationOfMaintenanceTypeCode.name;
    }

    renderMaintenanceTypeFilter() {
        return (
            <Cascader options={this.state.nodes} onChange={this.onMaintenanceTypeFilterChange} changeOnSelect />
        );
    }

    onMaintenanceTypeFilterChange(value, data) {
        //-------------------------------------------------------
        //ÇALIŞAN KISIM -- Seçili tüm equipmentTypeName'lere göre filtre yapar.
        var flagNamesArray = data.map(o => o.label);
        if (flagNamesArray.length !== 0) {
            this.dt.filter(flagNamesArray, 'maintenanceType.maintenanceTypeName', 'in');
        } else {
            this.dt.filter('', 'maintenanceType.maintenanceTypeName', 'in');
        }
        //-------------------------------------------------------
    }

    actionTemplate(rowData, column) {
        return <React.Fragment><CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.editModal.bind(this)} /></React.Fragment>;
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            editId: row.id
        });
    }

    deleteMaintenanceLogOK = async () => {
        const deletedMaintenanceLog = {
            id: this.state.editId
        }

        await handleRequest("POST", "/EquipmentMaintenance/delete", deletedMaintenanceLog);

        this.setState({
            deleteModal: false
        });
        this.restartMaintenanceTable();
    }

    editModal = async (row) => {

        var flagValues = [];
        var flagMaintenanceTypeId = null;

        if (row.maintenanceType !== null) {

            flagMaintenanceTypeId = row.maintenanceType.id;

            flagValues = getParents(this.state.nodes, row.maintenanceType.id).parents;

            flagValues.push(row.maintenanceType.id);

            function getParentsHelper(tree, id, parents) {

                if (tree.data.id == id) {
                    return {
                        found: true,
                        parents: parents
                    };
                }
                var result = {
                    found: false,
                }
                if (tree.children) {
                    $.each(tree.children, function (index, subtree) {
                        var maybeParents = $.merge([], parents);
                        if (tree.data.id != undefined) {
                            maybeParents.push(tree.data.id);
                        }
                        var maybeResult = getParentsHelper(subtree, id, maybeParents);
                        if (maybeResult.found) {
                            result = maybeResult;
                            return false;
                        }
                    });
                }
                return result;
            }

            function getParents(data1, id) {
                var tree = {
                    data: {},
                    children: data1
                }
                return getParentsHelper(tree, id, []);
            }
        }

        if (row.maintenanceType.maintenanceTypeName === "TIME BASED") {
            const response1 = await handleRequest("GET", "/Code/getAll");

            if (response1.data.length !== 0) {

                const columns = response1.data;

                var options = [];

                for (let index = 0; index < columns.length; index++) {
                    if (columns[index].type === "timePeriod") {
                        options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                    }
                }

                this.setState({
                    periods: options
                });
            }
        } else if (row.maintenanceType.maintenanceTypeName === "KM BASED") {
            const response1 = await handleRequest("GET", "/Code/getAll");

            if (response1.data.length !== 0) {

                const columns = response1.data;

                var options = [];

                for (let index = 0; index < columns.length; index++) {
                    if (columns[index].type === "meterPeriod") {
                        options.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                    }
                }

                this.setState({
                    periods: options
                });
            }
        }

        this.setState({
            modalShow: true,
            editId: row.id,
            equipmentLogId: row.equipmentLogId,
            values: flagValues,
            maintenanceTypeId: flagMaintenanceTypeId,
            scope: row.scope,
            periodOfMaintenance: row.periodOfMaintenance,
            periodOfMaintenanceType: row.periodOfMaintenanceTypeCode.name,
            periodId: row.periodOfMaintenanceType,
            durationOfMaintenance: row.durationOfMaintenance,
            durationOfMaintenanceType: row.durationOfMaintenanceTypeCode.name,
            timePeriodId: row.durationOfMaintenanceType,
            date: row.maintenanceStartDate,
            maintenanceLocation: row.maintenanceLocationTypeCode.name,
            status: row.status,
            lastButtonModal: "Update"
        });
    }

    checkListBodyTemplate(rowData) {
        return <Button type="button">Attach</Button>
    }

    handleChangeUnitGroup = e => {
        e.preventDefault();
        var IdValue;
        for (var z = 0; z < this.state.EquipmentPartList.length; z++) {
            if (this.state.EquipmentPartList[z].name === e.target.value) {
                IdValue = this.state.EquipmentPartList[z].id
            }
        }
        this.setState({
            [e.target.name]: e.target.value,
            unitGroupId: IdValue,
        });

        this.getPartCodeName(IdValue);
    };  
      
    handleChangePartCodeName = e => {
        e.preventDefault();
        var IdValue;
        for (var z = 0; z < this.state.EquipmentPartCodeName.length; z++) {
            if (this.state.EquipmentPartCodeName[z].name === e.target.value) {
                IdValue = this.state.EquipmentPartCodeName[z].id
            }
        }
        this.setState({
            [e.target.name]: e.target.value,
            equipmentPartListId: IdValue,
        });
    };

    getEquipmentPartList= async () => {  
       const response = await handleRequest("GET", "/EquipmentPartList/getAll"); 
       if (response.data.length !== 0) {
            const columns = response.data;
            const options = columns.map(function (row) {
                return { name: row.codeUnitGroup.name.toString(), id: row.unitGroup.toString() }
            })
            this.setState({
                EquipmentPartList: options,
            });
        }else{
          this.setState({
            EquipmentPartList: [],
        });
        }
    }  
    
    getPartCodeName= async (unitGroup) => { 
        if(unitGroup==null || unitGroup==undefined || unitGroup==0){
            unitGroup=-1;
        }
        const newObj = {
            unitGroup: unitGroup
        } 
       const response = await handleRequest("POST", "/EquipmentPartList/getResult", newObj); 
        if (response.data.length !== 0) {
            const columns = response.data;
            const options = columns.map(function (row) {
                return { name: row.partCode.toString() +"-" + row.partName.toString() , id: row.id.toString() }
            })
            this.setState({
                EquipmentPartCodeName: options,
            });
        }else{
          this.setState({
            EquipmentPartCodeName: [],
        });
        }
    } 

    handleChangeMSLCheckItem = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.MSLCheckItems.length; z++) {
          if (this.state.MSLCheckItems[z].id == e) {
            deger = this.state.MSLCheckItems[z].name;
          }
        }
        this.setState({
            checkItemAck: deger,
            checkItem: e,
        });
    };
    
    addMSLCheckItems = async () => {
    const { itemMSLCheckItems } = this.state;
    if (itemMSLCheckItems != null && itemMSLCheckItems != "" && itemMSLCheckItems != undefined) {
        const newItem = {
        name: itemMSLCheckItems,
        type: "MSLCheckItem"
        }
    
        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemMSLCheckItems: '',
        });
        this.getCodesMSLCheckItem();
    } else {
        this.showWarning();
    }
    };
    
    getCodesMSLCheckItem = async () => {
    const response = await handleRequest("GET", "/Code/type/MSLCheckItem");   
    
    if (response.data !== null && response.data !== undefined) {
        this.setState({
        MSLCheckItems: response.data
        });
    }
    } 

    handleChangeMSLCriteria = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.MSLCriterias.length; z++) {
          if (this.state.MSLCriterias[z].id == e) {
            deger = this.state.MSLCriterias[z].name;
          }
        }
        this.setState({
            criteriaAck: deger,
            criteria: e,
        });
    };
    
    addMSLCriterias = async () => {
    const { itemMSLCriterias } = this.state;
    if (itemMSLCriterias != null && itemMSLCriterias != "" && itemMSLCriterias != undefined) {
        const newItem = {
        name: itemMSLCriterias,
        type: "MSLCriteria"
        }
    
        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemMSLCriterias: '',
        });
        this.getCodesMSLCriteria();
    } else {
        this.showWarning();
    }
    };
    
    getCodesMSLCriteria = async () => {
    const response = await handleRequest("GET", "/Code/type/MSLCriteria");   
    
    if (response.data !== null && response.data !== undefined) {
        this.setState({
        MSLCriterias: response.data
        });
    }
    } 

    handleChangeMSLAction = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.MSLActions.length; z++) {
          if (this.state.MSLActions[z].id == e) {
            deger = this.state.MSLActions[z].name;
          }
        }
        this.setState({
            actionAck: deger,
            action: e,
        });
    };
    
    addMSLActions = async () => {
    const { itemMSLActions } = this.state;
    if (itemMSLActions != null && itemMSLActions != "" && itemMSLActions != undefined) {
        const newItem = {
        name: itemMSLActions,
        type: "MSLAction"
        }
    
        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemMSLActions: '',
        });
        this.getCodesMSLAction();
    } else {
        this.showWarning();
    }
    };
    
    getCodesMSLAction = async () => {
    const response = await handleRequest("GET", "/Code/type/MSLAction");   
    
    if (response.data !== null && response.data !== undefined) {
        this.setState({
        MSLActions: response.data
        });
    }
    } 

    calculateDuration = () => {
        var myObj = this.state.durationObj;
        var types = this.state.durationTypes;
        const index = this.state.durationTypes.findIndex(p => p.value === this.state.durationType);
        console.log(index);
        if (index >= 0 && this.state.durationNumber > 0) {
            console.log(this.state.durationType);
            var durationDesc = this.state.duration + this.state.durationNumber + " " + types[index].label + "s ";

            myObj[types[index].label.toLowerCase()] = this.state.durationNumber;

            types.splice(0, index + 1);

            this.setState({
                durationObj: myObj,
                duration: durationDesc,
                durationTypes: types
            });
        }
        else {
            this.showWarning("Please type duration inputs !");
        }


    }

    undoDuration = () => {
        if (this.state.duration.trim() !== "") {
            var pObj = this.state.durationObj;

            if (pObj !== null) {
                if (pObj.second && pObj.second > 0)
                    pObj.second = 0;
                else if (pObj.minute && pObj.minute > 0)
                    pObj.minute = 0;
                else if (pObj.hour && pObj.hour > 0)
                    pObj.hour = 0;
                else if (pObj.day && pObj.day > 0)
                    pObj.day = 0;
                else if (pObj.month && pObj.month > 0)
                    pObj.month = 0;
                else if (pObj.year && pObj.year > 0)
                    pObj.year = 0;
                else pObj = {};

            }
            var types = _DurationTypes.slice();
            var durationDesc = this.getDurationDesc(pObj);
            var index = -1;

            if (pObj !== {}) {
                if (pObj.second && pObj.second > 0)
                    index = 5;
                else if (pObj.minute && pObj.minute > 0)
                    index = 4;
                else if (pObj.hour && pObj.hour > 0)
                    index = 3;
                else if (pObj.day && pObj.day > 0)
                    index = 2;
                else if (pObj.month && pObj.month > 0)
                    index = 1;
                else if (pObj.year && pObj.year > 0)
                    index = 0;
            }

            types.splice(0, index + 1);

            this.setState({
                durationObj: pObj,
                duration: durationDesc,
                durationTypes: types
            });
        }
        else { this.showWarning("Please firstly type any duration!"); } 
    }

    
    removeDuration = () => { 
        this.setState({
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationTypes: _DurationTypes.slice(),
            durationType: 0
        });
    }

    getDurationDesc = (pObj) => {
        var result = "";
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }
        return result;
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
          [e.target.name]: e.target.value
        });
    }; 
    
    handleChangeReference = e => {
        e.preventDefault();
        var hiddenFixed=true;
        var hiddenPeriodic=true;
        var hiddenFixedKm=true;
        var hiddenFixedHour=true;
        var hiddenFixedTime=true;
        var hiddenPeriodicKm=true;
        var hiddenPeriodicHour=true;
        var hiddenPeriodicTime=true;
        this.resetMaintenanceChecklist(false);
        if(this.state.scheduleType==1){ //Periodic
            if(e.target.value>0){
                if(e.target.value==1){
                    hiddenPeriodicKm=false;
                    hiddenPeriodic=false;
                }else if(e.target.value==2){
                    hiddenPeriodicHour=false;
                    hiddenPeriodic=false;
                }else if(e.target.value==3){
                    hiddenPeriodicTime=false;
                    hiddenPeriodic=false;
                }
            }
        }else if(this.state.scheduleType==2){ //Fixed
            if(e.target.value>0){
                if(e.target.value==1){
                    hiddenFixedKm=false;
                    hiddenFixed=false;
                }else if(e.target.value==2){
                    hiddenFixedHour=false;
                    hiddenFixed=false;
                }else if(e.target.value==3){
                    hiddenFixedTime=false;
                    hiddenFixed=false;
                }
            }
        }
      
        this.setState({
          [e.target.name]: e.target.value,
          hiddenFixed:hiddenFixed, 
          hiddenFixedKm:hiddenFixedKm,
          hiddenFixedHour:hiddenFixedHour,
          hiddenFixedTime:hiddenFixedTime,

          hiddenPeriodic:hiddenPeriodic,
          hiddenPeriodicKm:hiddenPeriodicKm,
          hiddenPeriodicHour:hiddenPeriodicHour,
          hiddenPeriodicTime:hiddenPeriodicTime,
        });
    };

    render() {
        let optionsUnitGroup = this.state.EquipmentPartList.map((data) =>
        <option
            key={data.id}
            value={data.name}
        >
            {data.name}
        </option>
        );

        let optionsPartCodeName = this.state.EquipmentPartCodeName.map((data) =>
        <option
            key={data.id}
            value={data.name}
        >
            {data.name}
        </option>
        );

        let optionsPeriods = this.state.periods.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        )

        let optionsTimePeriods = this.state.timePeriods.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        )

        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>

        const statusFilter = this.renderStatusFilter();
        const maintenanceTypeFilter = this.renderMaintenanceTypeFilter();

        let radioGroupOptions = (
            <Form.Group  as={Row}  >
                <Col sm="5">
                    <Radio value={1}>Periodical</Radio>
                </Col>
                <Col sm="2">
                </Col>
                <Col sm="5">
                    <Radio value={2}>Fixed</Radio>
                </Col>
            </Form.Group>
          );

        return (
            <div style={{ backgroundColor: "white", padding: "15px" }}>

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                            <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                            
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                            </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row > 
                        <Col sm="12">
                            {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" /></Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowChecklist}
                    onHide={this.showOrHideModalChecklist}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Checklist</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="EQLUnitGroup" /> 
                            </Form.Label>
                            <Col sm="4">
                            <Form.Control name="unitGroup" value={this.state.unitGroup}
                                onChange={this.handleChangeUnitGroup} as="select"  >
                                <option value="">
                                    Select Unit Group
                                </option>
                                {optionsUnitGroup}
                            </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="EQLPartCodeName" /> 
                            </Form.Label>
                            <Col sm="4">
                            <Form.Control name="partCodeName" value={this.state.partCodeName}
                                onChange={this.handleChangePartCodeName} as="select"  >
                                <option value="">
                                    Select Part
                                </option>
                                {optionsPartCodeName}
                            </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="MSLCheckItem" /> 
                            </Form.Label>
                            <Col sm="4">
                            <Select
                                name="checkItem"
                                style={{ width: '100%' }}
                                value={this.state.checkItemAck}
                                onChange={this.handleChangeMSLCheckItem}

                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={this.state.itemMSLCheckItems}
                                        onChange={(event) => { 
                                            this.setState({ 
                                                itemMSLCheckItems: event.target.value,
                                             }) 
                                            }
                                            }/>
                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addMSLCheckItems} >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                                    </div>
                                </div>
                                )}>
                                {this.state.MSLCheckItems.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                                </Select>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="MSLCriteria" /> 
                            </Form.Label>
                            <Col sm="4">
                            <Select
                                name="criteria"
                                style={{ width: '100%' }}
                                value={this.state.criteriaAck}
                                onChange={this.handleChangeMSLCriteria}

                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={this.state.itemMSLCriterias}
                                        onChange={(event) => { this.setState({ itemMSLCriterias: event.target.value }) }} />
                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addMSLCriterias} >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                                    </div>
                                </div>
                                )}>
                                {this.state.MSLCriterias.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                                </Select>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="MSLAction" /> 
                            </Form.Label>
                            <Col sm="4">
                            <Select
                                name="action"
                                style={{ width: '100%' }}
                                value={this.state.actionAck}
                                onChange={this.handleChangeMSLAction}

                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={this.state.itemMSLActions}
                                        onChange={(event) => { this.setState({ itemMSLActions: event.target.value }) }} />
                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addMSLActions} >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" />  </a>
                                    </div>
                                </div>
                                )}>
                                {this.state.MSLActions.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                                </Select>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                Duration:
                                        </Form.Label>
                            <Col sm="2">
                                <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumber} onChange={(e) => this.setState({ durationNumber: e.target.value })} />
                            </Col>

                            <Col sm="2">
                                <PrimeDropdown style={{ width: '100%', marginRight: '1rem' }} value={this.state.durationType} options={this.state.durationTypes} onChange={(e) => this.setState({ durationType: e.target.value })}
                                    placeholder="Select ..." />
                            </Col>
                            <Col sm="1">
                                <PrimeButton type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDuration} />
                                <PrimeButton type="Button" icon="pi pi-undo" onClick={this.undoDuration} />
                            </Col>


                            <Col sm="4">
                                <InputText style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.duration} />
                            </Col>
                            <Col sm="1">
                                <PrimeButton type="Button" icon="pi pi-times" onClick={this.removeDuration} className="p-button-danger" />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="MSLSchedule" /> 
                            </Form.Label>
                            <Col sm="6">
                                <Mnd.MNDRadioGroup
                                    onChange={(e) => {
                                        var hiddenReference=true;
                                        var hiddenFixed=true;
                                        var hiddenPeriodic=true;
                                        this.resetMaintenanceChecklist(false);
                                        if(e.target.value==1 || e.target.value==2){
                                            if(e.target.value==1){
                                                hiddenPeriodic=false;
                                            }else if(e.target.value==2){
                                                hiddenFixed=false;
                                            }
                                            hiddenReference=false;
                                        }
                                        this.setState({
                                            scheduleTypeAck: e.target.value === 1 ? "Periodical" : "Fixed",
                                            scheduleType: e.target.value ,
                                            hiddenReference:hiddenReference,
                                            hiddenFixed:hiddenFixed,
                                            hiddenPeriodic:hiddenPeriodic,
                                            reference:0,
                                        });
                                    }}
                                    radios={radioGroupOptions}
                                    />
                            </Col>
                        </Form.Group>
                       
                        <div hidden={this.state.hiddenReference}>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    <FormattedMessage id="MSLReference" /> 
                                </Form.Label>
                                <Col sm="4">
                                    <Form.Control name="reference" value={this.state.reference}
                                        onChange={this.handleChangeReference} as="select"  >
                                        <option value="0"> Select </option>
                                        <option value="1"> Km based </option>
                                        <option value="2"> Hour based </option>
                                        <option value="3"> Time based </option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>   
                        </div>

                        <div hidden={this.state.hiddenFixed}>
                            <div hidden={this.state.hiddenFixedKm}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.km} onChange={(e) => this.setState({ km: e.target.value })} />
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLKm" /> 
                                    </Form.Label>
                                </Form.Group>
                            </div>

                            <div hidden={this.state.hiddenFixedHour}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.hour} onChange={(e) => this.setState({ hour: e.target.value })} />
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLHour" /> 
                                    </Form.Label>
                                </Form.Group>
                            </div>

                            <div hidden={this.state.hiddenFixedTime}>
                                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Col sm="2"></Col>
                                        <Col sm="4">
                                            <DatePicker style={{ width: '100%' }} value={this.state.dateFixed!=null ? moment(this.state.dateFixed, 'DD/MM/YYYY') : null} format="DD/MM/YYYY" onChange={this.onChangeDateFixed} />
                                        </Col>
                                    </Form.Group>
                                </div>
                        </div>
                        
                        <div hidden={this.state.hiddenPeriodic}>
                            <div hidden={this.state.hiddenPeriodicKm}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLEvery" /> 
                                    </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.km} onChange={(e) => this.setState({ km: e.target.value })} />
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLKm" /> 
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLRuleForTheNextMaintenance" /> 
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control name="periodicKmPeriod" value={this.state.periodicKmPeriod}
                                            onChange={this.handleChange} as="select"  >
                                            <option value="0"> Select </option>
                                            <option value="1"> Last Km </option>
                                            <option value="2"> Fixed Km </option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>   
                            </div>

                            <div hidden={this.state.hiddenPeriodicHour}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLEvery" /> 
                                    </Form.Label>
                                    <Col sm="4">
                                        <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.hour} onChange={(e) => this.setState({ hour: e.target.value })} />
                                    </Col>
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLHour" /> 
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="MSLRuleForTheNextMaintenance" /> 
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control name="periodicHourPeriod" value={this.state.periodicHourPeriod}
                                            onChange={this.handleChange} as="select"  >
                                            <option value="0"> Select </option>
                                            <option value="1"> Last Hour </option>
                                            <option value="2"> Fixed Hour </option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>  
                            </div>

                            <div hidden={this.state.hiddenPeriodicTime}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Every
                                    </Form.Label>
                                    <Col sm="4">
                                    <Form.Control name="every" sm="2" value={this.state.every}
                                        onChange={(e) => this.setState({ every: e.target.value })} as="select">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                        </Form.Control>
                                    </Col>
                                      <Col sm="4">
                                      <Form.Control name="period" value={this.state.period}
                                            onChange={(e) => 
                                                this.setState({ period: e.target.value })
                                            } as="select">
                                            <option value="0"> Select </option>
                                            <option value="1"> Daily </option>
                                            <option value="2"> Weekly </option>
                                            <option value="3"> Monthly </option>
                                            <option value="4"> Yearly </option>
                                            </Form.Control>
                                      </Col>
                                </Form.Group>   

                            <div hidden={!(this.state.period ==3)}>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Month Style
                                    </Form.Label>

                                    <Col sm="4">
                                        <Form.Control name="monthStyle" value={this.state.monthStyle}
                                        onChange={(e) => this.setState({ monthStyle: e.target.value })} as="select">
                                        <option value="0">Select</option>
                                        <option value="1">Day Number</option>
                                        <option value="2">Specific Day</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>   
                                </div>

                                <div hidden={!(this.state.period ==3 && this.state.monthStyle === "1")}>
                                   <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                        Start Day of Month 
                                    </Form.Label>

                                    <Col sm="4">
                                        <Form.Control name="periodMonthPoint" value={this.state.periodMonthPoint}
                                            onChange={(e) => this.setState({ periodMonthPoint: e.target.value })} as="select">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">31</option>
                                        </Form.Control>
                                    </Col>
                                    </Form.Group>
                                    </div>

                                <div hidden={!(this.state.period == 3 && this.state.monthStyle === "2")}>
                                     <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                     <Col sm="2">
                                     </Col>
                                     <Col sm="4">
                                        <Form.Control name="flSelect" value={this.state.firstLast}
                                            onChange={(e) => this.setState({ firstLast: e.target.value })} as="select">
                                            <option value="">Select</option>
                                            <option value="first">First</option>
                                            <option value="last">Last</option>
                                        </Form.Control>
                                        </Col>

                                        <Col sm="4">
                                        <Form.Control name="firstLastPoint" value={this.state.firstLastPoint}
                                            onChange={(e) => this.setState({ firstLastPoint: e.target.value })} as="select">
                                            <option value="1">Monday</option>
                                            <option value="2">Tuesday</option>
                                            <option value="3">Wednesday</option>
                                            <option value="4">Thursday</option>
                                            <option value="5">Friday</option>
                                            <option value="6">Saturday</option>
                                            <option value="0">Sunday</option>
                                            <option value="7">Working Day</option>
                                        </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    </div>
                                
                                <Form.Group hidden={!(this.state.period == 2)}style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Week Style
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control name="weekStyle" value={this.state.weekStyle}
                                        onChange={(e) => this.setState({ weekStyle: e.target.value })} as="select">
                                        <option value="0">Select</option>
                                        <option value="1">Week Days</option>
                                        <option value="2">Spesific Day</option>
                                        </Form.Control>
                                    </Col>

                                </Form.Group>

                                <Form.Group hidden={!(this.state.period == 2 && this.state.weekStyle === "1")} style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Start Day 
                                    </Form.Label>

                                    <Col sm="4">
                                        <Form.Control sm="4" name="periodWeekPoint" value={this.state.periodWeekPoint}
                                        onChange={(e) => this.setState({ periodWeekPoint: e.target.value })} as="select">
                                        <option value="1">Monday</option>
                                        <option value="2">Tuesday</option>
                                        <option value="3">Wednesday</option>
                                        <option value="4">Thursday</option>
                                        <option value="5">Friday</option>
                                        <option value="6">Saturday</option>
                                        <option value="0">Sunday</option>
                                        </Form.Control>
                                    </Col> 
                                    </Form.Group>
                                    
                                <Form.Group hidden={!(this.state.period == 2 && this.state.weekStyle === "2")} style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                    <Col sm="2">
                                     </Col>
                                    <Col sm="4">
                                        <Form.Control name="flSelect" value={this.state.firstLast}
                                        onChange={(e) => this.setState({ firstLast: e.target.value })} as="select">
                                        <option value="">Select</option>
                                        <option value="first">First Working Day</option>
                                        <option value="last">Last Working Day</option>
                                        </Form.Control>
                                    </Col> 
                                </Form.Group> 
                          </div> 
                        </div>
                        
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalChecklist}>Close</Button>
                        <Button variant="info" onClick={this.saveMaintenanceChecklist}>{this.state.lastButtonModalChecklist}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Maintenance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Equipment Type:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: '#64b5f6', fontWeight: 'bold' }}>{this.state.equipmentType}</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Department:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: '#64b5f6', fontWeight: 'bold' }}>{this.state.department}</Form.Label>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Equipment Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: '#64b5f6', fontWeight: 'bold' }}>{this.state.equipmentName}</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Equipment Code:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: '#64b5f6', fontWeight: 'bold' }}>{this.state.equipmentCode}</Form.Label>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Type of Maintenance:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Cascader defaultValue={this.state.values} options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Scope:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ scope: e.target.value })} value={this.state.scope} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Period of Maintenance:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ periodOfMaintenance: e.target.value })} value={this.state.periodOfMaintenance} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="periodOfMaintenanceType" value={this.state.periodOfMaintenanceType}
                                        onChange={this.handleChangePeriod} as="select">
                                        <option value="">
                                            Select Period
                                        </option>
                                        {optionsPeriods}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Duration of Maintenance:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ durationOfMaintenance: e.target.value })} value={this.state.durationOfMaintenance} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="durationOfMaintenanceType" value={this.state.durationOfMaintenanceType}
                                        onChange={this.handleChangeTimePeriod} as="select">
                                        <option value="">
                                            Select Time Period
                                            </option>
                                        {optionsTimePeriods}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Maintenance Start Date:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <DatePicker style={{ width: '100%' }} value={moment(this.state.date, 'DD/MM/YYYY')} format="DD/MM/YYYY" onChange={this.onChangeDate} />
                                </Form.Group>

                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Maintenance Location:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.maintenanceLocation} options={this.state.maintenanceLocations}
                                        onChange={this.onMaintenanceLocationChange} editable={true} placeholder="Select Location" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ marginTop: '0.5rem', color: 'black', textAlign: 'right' }}>Check List:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <PrimeButton onClick={this.showOrHideModalChecklist} type="button" style={{ width: "100%" }} label="Attach" />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                        <Button variant="info" onClick={this.saveOrEditMaintenance}>{this.state.lastButtonModal}</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Maintenance Data?
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>Close</Button>
                        <Button variant="danger" onClick={this.deleteMaintenanceLogOK}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Row style={{ marginBottom: "1rem" }}>
                    <Col xs={3}></Col>
                    <Col xs={3}></Col>
                    <Col xs={3}></Col>
                    <Col xs={3}><PrimeButton onClick={this.showOrHideModal} type="button" style={{ width: "60%" }} label="Create New" /></Col>
                </Row>

                <div className="datatable-doc-demo">
                    <div className="content-section implementation">
                        <PrimeDataTable ref={(el) => this.dt = el} responsive={true} value={this.state.equipmentMaintenanceLogs}
                            paginator={true} emptyMessage="No Maintenance Records Found" style={{ textAlign: 'center' }} rowsPerPageOptions={[5, 10, 20]} rows={10}>
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '5em' }} />
                            <Column field="index" header="S/N" style={{ textAlign: 'center', width: '5em' }} sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="maintenanceStartDate" header="Date of Maintenance" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="maintenanceType.maintenanceTypeName" header="Type of Maintenance" sortable={true} filter={true} filterElement={maintenanceTypeFilter} />
                            <Column field="scope" header="Scope" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column body={this.periodTemplate} header="Period of Maintenance" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column body={this.durationTemplate} header="Duration of Maintenance" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="maintenanceStartDate" header="Maintenance Start Date" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="maintenanceLocationTypeCode.name" header="Maintenance Location" sortable={true} filter={true} filterMatchMode="contains" />
                            <Column header="Check List" body={this.checkListBodyTemplate} sortable={true} filter={true} filterMatchMode="contains" />
                            <Column field="status" header="Status" body={this.statusBodyTemplate} style={{ width: '200px' }} sortable={true} filter={true} filterElement={statusFilter} />
                        </PrimeDataTable>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceLogSub)