/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import "react-toggle/style.css";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { handleRequest } from '../../redux/config/env';
import memoize from 'memoize-one';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import CompanyDetail from './CompanyDetail';
import { IconButton  } from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import { Toast } from 'primereact/toast';
 
 
const sortIcon = <ArrowDownward />;
const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton> 
);

const columns = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />, 
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Full Name",
        selector: "fullName",
        sortable: true,
      //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Short Name",
        selector: "shortName",
        sortable: true 
    },
    {
        name: "About",
        selector: "about",
        sortable: true 
    },
    {
        name: "Mission",
        selector: "mision",
        sortable: true,
 
    },
    {
        name: 'Vission',
        selector: 'vision',
        sortable: true,  
 
    }
]);

export class Company extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false, 
            companyId:0,
            selectedRow: {},
            companyList: [],
            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            lastButton: "Save",
            lastButtonVariant: "success",
            companyFullName: "",
            companyShortName: "",
            companyLogoName: "",
            aboutCompanyName: "",
            aboutCompanyText:"",            
            missionName: "",
            missionText:"",
            visionName: "", 
            visionText:"",
            itemsContacts: [],
            location: "Select",
            address: "",
            phones: [],
            faxes: [],
            webs: [],
            emails: [],
            socials: [],
            inputP: '',
            inputF: '',
            inputW: '',
            inputE: '',
            inputS: '',
            itemsCertificates: [],
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
        };

        // #region tags functions


        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        // #endregion tags functions
        this.restartTable();
    }

    restartTable = async () => {

        const response = await handleRequest("GET", "/Company/getAll");

        if (response.data.length === 0) { 
            this.setState({
                companyList: []

            });
        }

        if (response.data.length !== 0) {  
            this.setState({
                companyList: response.data 
            });
        }
    }

    createNew= () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hidden: false,
                createNewButton: "Stop Adding New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "danger",
                companyId:0,
                companyFullName:"",
                companyShortName:"",
                aboutCompanyText:"", 
                visionText:"",
                missionText:""
            });
            //this.refreshAccordions();
        } else if (this.state.createNewButton === "Stop Updating") {
            this.setState({
                hidden: true,
                createNewButton: "Create New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "info",
                companyId:0,
                companyFullName:"",
                companyShortName:"",
                aboutCompanyText:"", 
                visionText:"",
                missionText:""
            });
            //this.refreshAccordions();
        }
        else {
            this.setState({
                hidden: true,
                createNewButton: "Create New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "info",
                companyId:0,
                companyFullName:"",
                companyShortName:"",
                aboutCompanyText:"", 
                visionText:"",
                missionText:""
            });
            //this.refreshAccordions();
        }
    };

    saveCompany = async () => {

        console.log(this.state.aboutCompanyText); 
       
        if (this.state.companyFullName.trim() !== "" && this.state.aboutCompanyText.trim() !== "") {

           
            const newItem = {
                id: this.state.companyId, 
                fullName:this.state.companyFullName,
                shortName:this.state.companyShortName, 
                vision:this.state.visionText,
                mision:this.state.missionText,
                about:this.state.aboutCompanyText

            }

            if (newItem.id === 0)
                await await handleRequest("POST", "/Company/add", newItem);
            else await await handleRequest("POST", "/Company/update", newItem);

            this.resetInputs(); 
            this.restartTable();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetInputs = () => {
        this.setState({
            hidden: true,
            modalShow:false,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",
            companyId:0
        });
    };


    edit = (row) => {  
       
        this.setState({
            hidden: false,
            createNewButton: "Stop Updating",
            lastButton: "Update",
            lastButtonVariant: "warning",
            createNewButtonVariant: "danger",
            companyId:row.id,
            companyFullName:row.fullName,
            companyShortName:row.shortName,
            aboutCompanyText:row.about, 
            visionText:row.vision,
            missionText:row.mision
           
        });        
    }

    deleteModal = (row) => {
        
        this.setState({
            companyId : row.id ,
           modalShow : true 
        });
    }

    delete =  async ()  => { 

      console.log(this.companyId); 

        const deletedItem = {
            id: this.state.companyId

        }

        await handleRequest("POST", "/Company/delete", deletedItem);

        this.restartTable();
        this.resetInputs();
        this.showSuccess();
       
    }


    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };


    handleInputChangeP(evt) {
        this.setState({ inputP: evt.target.value });
    }

    handleInputKeyDownP(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                phones: [...state.phones, value],
                inputP: ''
            }));
        }

        if (this.state.phones.length && evt.keyCode === 8 && !this.state.inputP.length) {
            this.setState(state => ({
                phones: state.phones.slice(0, state.phones.length - 1)
            }));
        }
    }

    handleRemoveItemP(index) {
        return () => {
            this.setState(state => ({
                phones: state.phones.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeF(evt) {
        this.setState({ inputF: evt.target.value });
    }

    handleInputKeyDownF(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                faxes: [...state.faxes, value],
                inputF: ''
            }));
        }

        if (this.state.faxes.length && evt.keyCode === 8 && !this.state.inputF.length) {
            this.setState(state => ({
                faxes: state.faxes.slice(0, state.faxes.length - 1)
            }));
        }
    }

    handleRemoveItemF(index) {
        return () => {
            this.setState(state => ({
                faxes: state.faxes.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeW(evt) {
        this.setState({ inputW: evt.target.value });
    }

    handleInputKeyDownW(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                webs: [...state.webs, value],
                inputW: ''
            }));
        }

        if (this.state.webs.length && evt.keyCode === 8 && !this.state.inputW.length) {
            this.setState(state => ({
                webs: state.webs.slice(0, state.webs.length - 1)
            }));
        }
    }

    handleRemoveItemW(index) {
        return () => {
            this.setState(state => ({
                webs: state.webs.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeE(evt) {
        this.setState({ inputE: evt.target.value });
    }

    handleInputKeyDownE(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                emails: [...state.emails, value],
                inputE: ''
            }));
        }

        if (this.state.emails.length && evt.keyCode === 8 && !this.state.inputE.length) {
            this.setState(state => ({
                emails: state.emails.slice(0, state.emails.length - 1)
            }));
        }
    }

    handleRemoveItemE(index) {
        return () => {
            this.setState(state => ({
                emails: state.emails.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeS(evt) {
        this.setState({ inputS: evt.target.value });
    }

    handleInputKeyDownS(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                socials: [...state.socials, value],
                inputS: ''
            }));
        }

        if (this.state.socials.length && evt.keyCode === 8 && !this.state.inputS.length) {
            this.setState(state => ({
                socials: state.socials.slice(0, state.socials.length - 1)
            }));
        }
    }

    handleRemoveItemS(index) {
        return () => {
            this.setState(state => ({
                socials: state.socials.filter((item, i) => i !== index)
            }));
        }
    }

    // #endregion tags

    onChange = date => this.setState({ date })

    addContact = () => {
        this.addContactItem();
        this.setState({
            location: "Select",
            address: "",
            phones: [],
            faxes: [],
            webs: [],
            emails: [],
            socials: []
        });
    }

    addContactItem = () => {
        this.setState(state => ({
            itemsContacts: [...state.itemsContacts, {
                contactId: this.state.itemsContacts.length,
                location: this.state.location,
                address: this.state.address,
                phone: this.state.phones,
                fax: this.state.faxes,
                web: this.state.webs,
                email: this.state.emails,
                soccAcc: this.state.socials
            }]
        }));
    }

    addCertificate = () => {
        this.addCertificateItem();
        this.setState({
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: ""
        });
    }

  

    addCertificateItem = () => {
        this.setState(state => ({
            itemsCertificates: [...state.itemsCertificates, {
                certificateId: this.state.itemsCertificates.length,
                name: this.state.nameOfCertificate,
                validity: this.state.validity,
                respDepartment: this.state.respDepartment,
                certificate: this.state.certificate
            }]
        }));
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onEditClick(e) {
        if (e.target.value === undefined)
            e.stopPropagation();
    }

    companyLogoChange = e => {
        this.setState({
            companyLogoName: e.target.files[0].name
        });
    }

    aboutCompanyChange = e => {
        this.setState({
            aboutCompanyName: e.target.files[0].name
        });
    }

    missionChange = e => {
        this.setState({
            missionName: e.target.files[0].name
        });
    }

    visionChange = e => {
        this.setState({
            visionName: e.target.files[0].name
        });
    }

    certificateChange = e => {
        this.setState({
            certificate: e.target.files[0].name
        });
    }

    render() {
        const styles = {
            container: {
                border: '1px solid #ddd',
                padding: '5px',
                borderRadius: '5px',
            },
            items: {
                display: 'inline-block',
                padding: '2px',
                border: '1px solid blue',
                fontFamily: 'Helvetica, sans-serif',
                borderRadius: '5px',
                marginRight: '5px',
                cursor: 'pointer'
            },
            input: {
                outline: 'none',
                border: 'none',
                fontSize: '14px',
                fontFamily: 'Helvetica, sans-serif'
            }
        };
        const ExpanableComponent = ({ data }) => <CompanyDetail row={data} />; 
        return (
            <div>
                 <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={3}> <Toast ref={(el) => this.toast = el} /></Col>  
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col> 
                    </Row>
                </div>
                <div hidden={this.state.hidden} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Company's Full Name:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="companyFullName" value={this.state.companyFullName}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Company's Short Name:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="companyShortName" value={this.state.companyShortName}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyLogo">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Add Company Logo:
                                                 </Form.Label>
                                        <Col sm="4">
                                            <div style={{ textAlign: 'center' }}>
                                                <div className='custom-file'>
                                                    <input
                                                        type='file'
                                                        className='custom-file-input'
                                                        id='customFile'
                                                        onChange={this.companyLogoChange}
                                                    />
                                                    <label className='custom-file-label' htmlFor='customFile'>
                                                        {this.state.companyLogoName}
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                            <Col>
                                                <Image align="left" thumbnail />
                                            </Col>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlAboutCompany">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            About Company:
                                            </Form.Label>
                                        <Col  sm="4">                                           
                                            <Form.Control as="textarea"  rows="3"  value={this.state.aboutCompanyText}  onChange={(e) => this.setState({ aboutCompanyText: e.target.value })}
                                               />  
                                        </Col>
                                        <Col sm="2">
                                            <div style={{ textAlign: 'center' }}>
                                                <div className='custom-file'>
                                                    <input
                                                        type='file'
                                                        className='custom-file-input'
                                                        id='customFile'
                                                        onChange={this.aboutCompanyChange}
                                                    />
                                                    <label className='custom-file-label' htmlFor='customFile'>
                                                        {this.state.aboutCompanyName}
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlMission">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Mission:
                                            </Form.Label>
                                            <Col  sm="4">                                           
                                            <Form.Control as="textarea"  rows="3"  value={this.state.missionText}  onChange={(e) => this.setState({ missionText: e.target.value })}
                                               />   
                                        </Col>
                                        <Col sm="2">
                                            <div style={{ textAlign: 'center' }}>
                                                <div className='custom-file'>
                                                    <input
                                                        type='file'
                                                        className='custom-file-input'
                                                        id='customFile'
                                                        onChange={this.missionChange}
                                                    />
                                                    <label className='custom-file-label' htmlFor='customFile'>
                                                        {this.state.missionName}
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Vision:
                                            </Form.Label>
                                            <Col  sm="4">                                           
                                            <Form.Control as="textarea"  rows="3"  value={this.state.visionText}  onChange={(e) => this.setState({ visionText: e.target.value })}
                                               />   
                                        </Col>
                                        <Col sm="2"> 
                                            <div style={{ textAlign: 'center' }}>
                                                <div className='custom-file'>
                                                    <input
                                                        type='file'
                                                        className='custom-file-input'
                                                        id='customFile'
                                                        onChange={this.visionChange}
                                                    />
                                                    <label className='custom-file-label' htmlFor='customFile'>
                                                        {this.state.visionName}
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">
                                        
                                            <Col  sm="3">                                           
                                          
                                               
                                        </Col>
                                        <Col sm="4"> 
                                        <Button type="button" style={{ width: '100%' }} onClick={this.saveCompany} variant={this.state.lastButtonVariant} >{this.state.lastButton}</Button>
                                        </Col>
                                    </Form.Group> 
                                </Form>
                               
                            </Container></Card.Body>
                    </Card>
                </div>
                <DataTable style={{ marginTop: '3rem'}} 
                    noHeader
                    columns={columns( this.deleteModal, this.edit)}    
                    data={this.state.companyList}  
                    sortIcon={sortIcon}
                    pagination
                    expandableRows
                    persistTableHead
                    expandableRowsComponent={<ExpanableComponent style={{ marginLeft: '2rem' ,marginRight : '2rem' }} />} 
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    // subHeader
                    // subHeaderComponent={
                    //     (
                    //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                    //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                    //             <Icon1 style={{ margin: '5px' }} color="action" />
                    //             <Icon2 style={{ margin: '5px' }} color="action" />
                    //             <Icon3 style={{ margin: '5px' }} color="action" />
                    //         </div>
                    //     )
                    // }
                    actions={actions}
                    onRowClicked={this.handleRowClicked}
                    onSelectedRowsChange={this.handleChangeRow}
                />

                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}> 
                            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.modalShow}
                                onHide={this.showOrHideModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Company Info ?</Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                    <p>All informations about company will be deleted. Are you sure?</p> 
                                </Modal.Body> 
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                                    <Button variant="danger" onClick={this.delete} >Delete</Button>  
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>
                        
                    </Row>
                </Container>
              
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Company)
