import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, TimePicker, Switch, Button as AntButton } from 'antd';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

function ordinal_suffix_of(i) {

    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

const paymentTypes = [{ id: 1, name: 'Company' }, { id: 2, name: 'Participant' }, { id: 3, name: 'Host' }];
const moneyUnits = [{ id: 1, value: "SAR" }, { id: 2, value: "$" }, { id: 3, value: "TL" }];
const pricePeriods = [{ id: 'hour', value: "For a hour" }, { id: "day", value: "For a day" }, { id: "week", value: "For a week" }, { id: "month", value: "For a month" }, { id: "year", value: "For a year" }]
export class BusinessTripRequest extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            tableList: [],
            id: 0,
            requestNo: '',
            description: '',
            singleLocation: null,
            codeName: '',
            purposeOfTripId: null,
            purposeOfTripSelectItems: [],
            pusrposeOfTripWithId: null,
            selectedUsers: [],
            userSelectItems: [],
            selectedDepartments: [],
            departmentSelectItems: [],
            accomodationSelectItems: [],
            nameOfOrganizator: '',
            webPage: '',
            phone: '',
            address: '',
            contactPersonEmail: '',
            contactPersonName: '',
            contactPersonPhone: '',
            dateList: [],
            accomodationList: [{
                typeOfAccomodationId: null, nameOfAccomodation: '', active: true,
                countryId: null, cityId: null,
                location: '', phone: '', email: '',
                webPage: '', price: 0.0, priceType: null, pricePeriod: '',
                breakfast: false, lunch: false, dinner: false,fullPackage:false,
            }],
            paymentList: [{ businessTripGroupId: null, paymentType: null, active: true }],
            modeOfTripSelectItems: [],
            routeSelectItems: [],
            transportationSelectItems: [],
            businessTripGroupSelectItems: [],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,
            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.setComboboxes();
        this.fillCodes();
        this.restartTable();


    }

    save = async () => {

        var _userList = [];
        var _departmentList = [];
        if (Boolean(this.state.selectedUsers)) {
            this.state.selectedUsers.forEach(element => {
                let obj = { userId: element };
                _userList.push(obj);
            });

        }

        if (Boolean(this.state.selectedDepartments)) {
            this.state.selectedDepartments.forEach(element => {
                let obj = { departmentId: element };
                _departmentList.push(obj);
            });

        }


        const obj = {
            id: this.state.id,
            description: this.state.description,
            singleLocation: this.state.singleLocation === 1 ? true : false,
            purposeOfTripId: this.state.purposeOfTripId,
            pusrposeOfTripWithId: this.state.pusrposeOfTripWithId,
            userList: _userList,
            departmentList: _departmentList,
            nameOfOrganizator: this.state.nameOfOrganizator,
            webPage: this.state.webPage,
            phone: this.state.phone,
            address: this.state.address,
            location: this.state.address,
            countryId: this.state.countryId,
            cityId: this.state.cityId,
            contactPersonEmail: this.state.contactPersonEmail,
            contactPersonName: this.state.contactPersonName,
            contactPersonPhone: this.state.contactPersonPhone,
            accomodationList: this.state.accomodationList,
            paymentList: this.state.paymentList,
            dateList: this.state.dateList
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/BusinessTripRequest/add", obj);
        else
            await handleRequest("POST", "/BusinessTripRequest/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.setState({
            id: row.id,
            status: row.status,
            abbreviation: row.abbreviation,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/BusinessTripRequest/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        const response = await handleRequest("GET", "/BusinessTripRequest/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "BusinessTripRequest" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "BusinessTripRequest"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "BusinessTripRequest");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            id: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            id: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            status: '',
            abbreviation: '',
            id: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    addCodePurposeOfTrip = async () => {
        const { codeName } = this.state;
        let pType = "purposeOfTrip";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addCodeModeOfTrip = async () => {
        const { codeName } = this.state;
        let pType = "modeOfTrip";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addCodeRouteTrip = async () => {
        const { codeName } = this.state;
        let pType = "routeOfTrip";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addTransportation = async () => {
        const { codeName } = this.state;
        let pType = "transportation";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addCodeAccomodation = async () => {
        const { codeName } = this.state;
        let pType = "accomodationType";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };



    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/purposeOfTrip");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                purposeOfTripSelectItems: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/modeOfTrip");

        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                modeOfTripSelectItems: response2.data
            });
        }

        const response3 = await handleRequest("GET", "/Code/type/routeOfTrip");

        if (response3.data !== null && response3.data !== undefined) {
            this.setState({
                routeSelectItems: response3.data
            });
        }

        const response4 = await handleRequest("GET", "/Code/type/transportation");

        if (response4.data !== null && response4.data !== undefined) {
            this.setState({
                transportationSelectItems: response4.data
            });
        }

        const response5 = await handleRequest("GET", "/Code/type/accomodationType");

        if (response5.data !== null && response5.data !== undefined) {
            this.setState({
                accomodationSelectItems: response5.data
            });
        }



    }

    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/Department/getallByCompany");
        if (resDepList.data.length > 0) {
            this.setState({
                departmentSelectItems: resDepList.data
            });
        } else {
            this.setState({
                departmentSelectItems: []
            });
        }

        const resUserList = await handleRequest("GET", "/User/getCompanyItems");
        if (resUserList.data.length > 0) {
            this.setState({
                userSelectItems: resUserList.data
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }

        const responsBtg = await handleRequest("GET", "/BusinessTripGroup/getAllByCompany");
        if (responsBtg.data.length > 0) {
            this.setState({
                businessTripGroupSelectItems: responsBtg.data
            });
        } else {
            this.setState({
                businessTripGroupSelectItems: []
            });
        }


    }

    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Request No" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="todo" value={Boolean(this.state.tableList) ? this.state.tableList.length + 1 : 1} />


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Description of Business Trip" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Destination" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Radio.Group onChange={(e) => {
                                                    this.setState({
                                                        singleLocation: e.target.value
                                                    })
                                                }} value={this.state.singleLocation}>
                                                    <Radio value={1}>Single Location</Radio>
                                                    <Radio value={2}>Multiple Location</Radio>
                                                </Radio.Group>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                            </Col>
                                            <Col sm="2">

                                                <div>
                                                    <Checkbox onChange={(e) => {
                                                        this.setState({
                                                            international: e.target.checked
                                                        })
                                                    }} checked={this.state.international}> International </Checkbox>
                                                </div>


                                            </Col>
                                            <Col sm="2">

                                                <div>
                                                    <Checkbox onChange={(e) => {
                                                        this.setState({
                                                            intercity: e.target.checked
                                                        })
                                                    }} checked={this.state.intercity}> Intercity </Checkbox>
                                                </div>


                                            </Col>
                                            <Col sm="2">

                                                <div>
                                                    <Checkbox onChange={(e) => {
                                                        this.setState({
                                                            intracity: e.target.checked
                                                        })
                                                    }} checked={this.state.intracity}> Intracity </Checkbox>
                                                </div>


                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="Purpose of Trip" />}
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                        <Col sm="6">
                                            <Select
                                                style={{ width: '180px' }}
                                                placeholder="Unit"
                                                value={this.state.purposeOfTripId} onChange={(value) => {
                                                    this.setState({
                                                        purposeOfTripId: value
                                                    })
                                                }}


                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                this.setState({
                                                                    codeName: event.target.value,
                                                                });
                                                            }} />
                                                            <a
                                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                onClick={this.addCodePurposeOfTrip}
                                                            >
                                                                <PlusOutlined /> Add item
                                                               </a>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {this.state.purposeOfTripSelectItems.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>


                                        </Col>

                                    </Form.Group>

                                </FormAnt.Item>}

                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="(Purpose of Trip) With" />}
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                        <Col sm="6">
                                            <Select
                                                style={{ width: '180px' }}
                                                placeholder="Unit"
                                                value={this.state.pusrposeOfTripWithId} onChange={(value) => {
                                                    this.setState({
                                                        pusrposeOfTripWithId: value
                                                    })
                                                }}


                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                this.setState({
                                                                    codeName: event.target.value,
                                                                });
                                                            }} />
                                                            <a
                                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                onClick={this.addCodePurposeOfTrip}
                                                            >
                                                                <PlusOutlined /> Add item
                                                               </a>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                {this.state.purposeOfTripSelectItems.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>


                                        </Col>

                                    </Form.Group>

                                </FormAnt.Item>}
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Participants" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedUsers} onChange={(value) => {


                                                    this.setState({
                                                        selectedUsers: value,

                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.userSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.username}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Invitation For Other Departments" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDepartments} onChange={(value) => {


                                                    this.setState({
                                                        selectedDepartments: value,

                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.departmentSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="(Purpose of Trip) Information" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Name of Organizator" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.nameOfOrganizator} onChange={(e) => this.setState({ nameOfOrganizator: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }



                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Country" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select placeholder="" value={this.state.countryId} onChange={(value) => {

                                                        this.setState({
                                                            countryId: value,
                                                        })
                                                    }}
                                                    >
                                                        <Option key={0} value={0}>Select</Option>
                                                        {COUNTRIES.map(i => (
                                                            <Option key={i.value} value={i.value}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="City" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select placeholder="" value={this.state.cityId} onChange={(value) => {

                                                        this.setState({
                                                            cityId: value,
                                                        })
                                                    }}
                                                    >
                                                        <Option key={0} value={0}>Select</Option>
                                                        {COUNTRIES.map(i => (
                                                            <Option key={i.value} value={i.value}>{i.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Location" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Phone" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Web page" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.webPage} onChange={(e) => this.setState({ webPage: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Contact Person Name" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.contactPersonName} onChange={(e) => this.setState({ contactPersonName: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Contact Person Phone" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.contactPersonPhone} onChange={(e) => this.setState({ contactPersonPhone: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Contact Person Email" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">

                                                    <Input id="todo" value={this.state.contactPersonEmail} onChange={(e) => this.setState({ contactPersonEmail: e.target.value })} />


                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                </div>

                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Dates of (Purpose of Trip)" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Number of (Purpose of trip) Days" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <InputNumber id="todo" min={1} value={this.state.numberOfDays} onChange={(value) => {
                                                        let dateList = this.state.dateList;
                                                        for (let index = 1; index <= value; index++) {
                                                            if (dateList.filter(p => p.active === true).length < index) {
                                                                const element = {
                                                                    id: 0, date: null, active: true, dateOrder: index,
                                                                    timelineList: [{ modeOfTripId: null, routeFromId: null, routeToId: null, transportationId: null, estimatedHourFrom: null, estimatedHourTo: null, active: true }]
                                                                };
                                                                dateList.push(element);
                                                            }


                                                        }
                                                        if (dateList.filter(p => p.active === true).length > value) {
                                                            dateList.forEach((element, i) => {
                                                                if (i >= value)
                                                                    element.active = false;
                                                            });
                                                        }
                                                        this.setState({ numberOfDays: value, dateList: dateList })
                                                    }} />
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }
                                    {Boolean(this.state.dateList) && this.state.dateList.filter(p => p.active === true).map((item, i) => (
                                        <div>

                                            {  <FormAnt.Item
                                                label={""}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                <Form.Group key={item.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                    <Col sm="6">

                                                    </Col>
                                                    <Col sm="1">
                                                        <span>{ordinal_suffix_of(i + 1)} Day</span>
                                                    </Col>

                                                    <Col sm="4">
                                                        <DatePicker showTime onOk={(value) => {
                                                            this.setState(prevState => ({
                                                                dateList: prevState.dateList.map(
                                                                    el => el.active === true && el.dateOrder === i + 1 ? { ...el, date: value } : el
                                                                )
                                                            }))

                                                        }} value={item.date}

                                                            format="DD-MM-YYYY HH:mm"
                                                        />
                                                    </Col>


                                                </Form.Group>

                                            </FormAnt.Item>}

                                        </div>
                                    ))}


                                </div>

                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Trip Timeline" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}
                                    {Boolean(this.state.dateList) && this.state.dateList.filter(p => p.active === true).map((item, i) => (
                                        <div>

                                            {  <FormAnt.Item
                                                label={""}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                <Form.Group key={item.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                    <Col sm="6">

                                                    </Col>
                                                    <Col sm="2">
                                                        <span style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} > {Boolean(item.date) ? moment(item.date).format("DD/MM/YYYY HH:mm") : ''} </span>
                                                    </Col>


                                                </Form.Group>

                                            </FormAnt.Item>}
                                            {Boolean(item.timelineList) && item.timelineList.filter(p => p.active === true).map((time, j) => (
                                                <div>
                                                    {  <FormAnt.Item
                                                        label={<FormattedMessage id="todo" defaultMessage="Mode Of Trip" />}
                                                        name="OrganizationalLevelRoleGroup"
                                                        id="OrganizationalLevelRoleGroup"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                            <Col sm="6">
                                                                <Select
                                                                    style={{ width: '180px' }}
                                                                    placeholder="Unit"
                                                                    value={time.modeOfTripId} onChange={(value) => {
                                                                        let list = this.state.dateList;
                                                                        let obj = list.find(p => p.dateOrder === item.dateOrder && p.active === true);
                                                                        if (Boolean(obj.timelineList) && obj.timelineList.length > 0) {
                                                                            obj.timelineList[j].modeOfTripId = value;
                                                                        }

                                                                        this.setState({
                                                                            dateList: list
                                                                        })
                                                                    }}


                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                                    this.setState({
                                                                                        codeName: event.target.value,
                                                                                    });
                                                                                }} />
                                                                                <a
                                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                    onClick={this.addCodeModeOfTrip}
                                                                                >
                                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {this.state.modeOfTripSelectItems.map(i => (
                                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                    ))}
                                                                </Select>


                                                            </Col>
                                                            <Col sm="6">
                                                            </Col>
                                                        </Form.Group>
                                                    </FormAnt.Item>}
                                                    {  <FormAnt.Item
                                                        label={<FormattedMessage id="todo" defaultMessage="Route" />}
                                                        name="OrganizationalLevelRoleGroup"
                                                        id="OrganizationalLevelRoleGroup"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                            <Col sm="3">
                                                                <Select
                                                                    style={{ width: '180px' }}
                                                                    placeholder="Unit"
                                                                    value={time.routeFromId} onChange={(value) => {
                                                                        let list = this.state.dateList;
                                                                        let obj = list.find(p => p.dateOrder === item.dateOrder && p.active === true);
                                                                        if (Boolean(obj.timelineList) && obj.timelineList.length > 0) {
                                                                            obj.timelineList[j].routeFromId = value;
                                                                        }

                                                                        this.setState({
                                                                            dateList: list
                                                                        })
                                                                    }}


                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                                    this.setState({
                                                                                        codeName: event.target.value,
                                                                                    });
                                                                                }} />
                                                                                <a
                                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                    onClick={this.addCodeRouteTrip}
                                                                                >
                                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {this.state.routeSelectItems.map(i => (
                                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                    ))}
                                                                </Select>


                                                            </Col>
                                                            <Col sm="1">
                                                                to
                                                            </Col>
                                                            <Col sm="3">
                                                                <Select
                                                                    style={{ width: '180px' }}
                                                                    placeholder="Unit"
                                                                    value={time.routeToId} onChange={(value) => {
                                                                        let list = this.state.dateList;
                                                                        let obj = list.find(p => p.dateOrder === item.dateOrder && p.active === true);
                                                                        if (Boolean(obj.timelineList) && obj.timelineList.length > 0) {
                                                                            obj.timelineList[j].routeToId = value;
                                                                        }

                                                                        this.setState({
                                                                            dateList: list
                                                                        })
                                                                    }}


                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                                    this.setState({
                                                                                        codeName: event.target.value,
                                                                                    });
                                                                                }} />
                                                                                <a
                                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                    onClick={this.addCodeRouteTrip}
                                                                                >
                                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {this.state.routeSelectItems.map(i => (
                                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                    ))}
                                                                </Select>


                                                            </Col>
                                                        </Form.Group>
                                                    </FormAnt.Item>}

                                                    {  <FormAnt.Item
                                                        label={<FormattedMessage id="todo" defaultMessage="Transportation" />}
                                                        name="OrganizationalLevelRoleGroup"
                                                        id="OrganizationalLevelRoleGroup"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                            <Col sm="6">
                                                                <Select
                                                                    style={{ width: '180px' }}
                                                                    placeholder="Unit"
                                                                    value={time.transportationId} onChange={(value) => {
                                                                        let list = this.state.dateList;
                                                                        let obj = list.find(p => p.dateOrder === item.dateOrder && p.active === true);
                                                                        if (Boolean(obj.timelineList) && obj.timelineList.length > 0) {
                                                                            obj.timelineList[j].transportationId = value;
                                                                        }

                                                                        this.setState({
                                                                            dateList: list
                                                                        })
                                                                    }}


                                                                    dropdownRender={menu => (
                                                                        <div>
                                                                            {menu}
                                                                            <Divider style={{ margin: '4px 0' }} />
                                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                                    this.setState({
                                                                                        codeName: event.target.value,
                                                                                    });
                                                                                }} />
                                                                                <a
                                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                                    onClick={this.addTransportation}
                                                                                >
                                                                                    <PlusOutlined /> Add item
                                                                             </a>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                >
                                                                    {this.state.transportationSelectItems.map(i => (
                                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                                    ))}
                                                                </Select>


                                                            </Col>
                                                            <Col sm="6">
                                                            </Col>
                                                        </Form.Group>
                                                    </FormAnt.Item>}

                                                    {  <FormAnt.Item
                                                        label={<FormattedMessage id="todo" defaultMessage="Estimated Hours" />}
                                                        name="OrganizationalLevelRoleGroup"
                                                        id="OrganizationalLevelRoleGroup"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                            <Col sm="3">
                                                                <TimePicker value={time.estimatedHourFrom} onChange={(value) => {
                                                                    let list = this.state.dateList;
                                                                    let obj = list[i];
                                                                    if (Boolean(obj.timelineList) && obj.timelineList.length > 0) {
                                                                        obj.timelineList[j].estimatedHourFrom = value;
                                                                    }

                                                                    this.setState({
                                                                        dateList: list
                                                                    })
                                                                }} format="HH:mm" />
                                                            </Col>
                                                            <Col sm="1">
                                                                to
                                                            </Col>
                                                            <Col sm="3">
                                                                <TimePicker value={time.estimatedHourTo} onChange={(value) => {
                                                                    let list = this.state.dateList;
                                                                    let obj = list[i];
                                                                    if (Boolean(obj.timelineList) && obj.timelineList.length > 0) {
                                                                        obj.timelineList[j].estimatedHourTo = value;
                                                                    }

                                                                    this.setState({
                                                                        dateList: list
                                                                    })
                                                                }} format="HH:mm" />
                                                            </Col>
                                                        </Form.Group>
                                                    </FormAnt.Item>}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                </div>))}
                                            {
                                                <FormAnt.Item

                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                        <Col sm="10">

                                                        </Col>
                                                        <Col sm="2">
                                                            <AntButton type="primary" onClick={() => {
                                                                let list = this.state.dateList;
                                                                let obj = list[i];
                                                                obj.timelineList.push({ modeOfTripId: null, routeFromId: null, routeToId: null, transportationId: null, estimatedHourFrom: null, estimatedHourTo: null, active: true });

                                                                this.setState({
                                                                    dateList: list
                                                                })
                                                            }} icon={<PlusOutlined />}></AntButton>

                                                        </Col>

                                                    </Form.Group>

                                                </FormAnt.Item>}

                                        </div>))}
                                </div>


                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Accomodation" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}
                                    {Boolean(this.state.accomodationList) && this.state.accomodationList.filter(p => p.active === true).map((item, i) => (
                                        <div>
                                            {  <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Type of Accomodation" />}
                                                name="OrganizationalLevelRoleGroup"
                                                id="OrganizationalLevelRoleGroup"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Type of Accomodation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="6">
                                                        <Select
                                                            style={{ width: '180px' }}
                                                            placeholder="Unit"
                                                            value={item.typeOfAccomodationId} onChange={(value) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.typeOfAccomodationId = value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }}


                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addCodeAccomodation}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.accomodationSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>


                                                    </Col>
                                                    <Col sm="6">
                                                    </Col>
                                                </Form.Group>
                                            </FormAnt.Item>}
                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Name of Accomodation" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">

                                                            <Input id="todo" value={item.nameOfAccomodation} onChange={(e) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.nameOfAccomodation = e.target.value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }} />


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }



                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Country" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select placeholder="" value={item.countryId} onChange={(value) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.countryId = value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }}
                                                            >
                                                                <Option key={0} value={0}>Select</Option>
                                                                {COUNTRIES.map(i => (
                                                                    <Option key={i.value} value={i.value}>{i.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="City" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">
                                                            <Select placeholder="" value={item.cityId} onChange={(value) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.cityId = value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }}
                                                            >
                                                                <Option key={0} value={0}>Select</Option>
                                                                {COUNTRIES.map(i => (
                                                                    <Option key={i.value} value={i.value}>{i.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }
                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Location" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">

                                                            <Input id="todo" value={item.location} onChange={(e) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.location = e.target.value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }} />


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Phone" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">

                                                            <Input id="todo" value={item.phone} onChange={(e) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.phone = e.target.value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }} />


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Email" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">

                                                            <Input id="todo" value={item.email} onChange={(e) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.email = e.target.value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }} />


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Web page" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="6">

                                                            <Input id="todo" value={item.webPage} onChange={(e) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.webPage = e.target.value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }} />


                                                        </Col>
                                                        <Col sm="6">
                                                        </Col>
                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Price" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="2">

                                                            <InputNumber id="todo" min={0} value={item.price} onChange={(value) => {
                                                                let list = this.state.accomodationList;
                                                                let obj = list[i];
                                                                obj.price = value;

                                                                this.setState({
                                                                    accomodationList: list
                                                                })
                                                            }} />


                                                        </Col>
                                                        <Col sm="2">
                                                            <Select

                                                                placeholder="Currency"
                                                                value={item.priceType} onChange={(value) => {
                                                                    let list = this.state.accomodationList;
                                                                    let obj = list[i];
                                                                    obj.priceType = value;

                                                                    this.setState({
                                                                        accomodationList: list
                                                                    })
                                                                }}
                                                            >
                                                                {moneyUnits.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>
                                                        <Col sm="2">

                                                            <Select

                                                                placeholder="Currency"
                                                                value={item.pricePeriod} onChange={(value) => {
                                                                    let list = this.state.accomodationList;
                                                                    let obj = list[i];
                                                                    obj.pricePeriod = value;

                                                                    this.setState({
                                                                        accomodationList: list
                                                                    })
                                                                }}
                                                            >
                                                                {pricePeriods.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>

                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }

                                            {
                                                <FormAnt.Item
                                                    label={<FormattedMessage id="todo" defaultMessage="Accomodation Covarage" />}
                                                    name="OrganizationalLevelRoleGroup"
                                                    id="OrganizationalLevelRoleGroup"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                        <Col sm="2">

                                                            <div>
                                                                <Checkbox onChange={(e) => {
                                                                    let list = this.state.accomodationList;
                                                                    let obj = list[i];
                                                                    obj.breakfast = e.target.checked;
    
                                                                    this.setState({
                                                                        accomodationList: list
                                                                    })
                                                                }} checked={item.breakfast}> Breakfast </Checkbox>
                                                            </div>
                                                           


                                                        </Col>
                                                        <Col sm="2">
                                                        <div>
                                                                <Checkbox onChange={(e) => {
                                                                    let list = this.state.accomodationList;
                                                                    let obj = list[i];
                                                                    obj.lunch = e.target.checked;
    
                                                                    this.setState({
                                                                        accomodationList: list
                                                                    })
                                                                }} checked={item.lunch}> Lunch </Checkbox>
                                                            </div>


                                                        </Col>
                                                        <Col sm="2">

                                                        <div>
                                                                <Checkbox onChange={(e) => {
                                                                    let list = this.state.accomodationList;
                                                                    let obj = list[i];
                                                                    obj.dinner = e.target.checked;
    
                                                                    this.setState({
                                                                        accomodationList: list
                                                                    })
                                                                }} checked={item.dinner}> Dinner </Checkbox>
                                                            </div>


                                                        </Col>
                                                        <Col sm="2">

                                                        <div>
                                                                <Checkbox onChange={(e) => {
                                                                    let list = this.state.accomodationList;
                                                                    let obj = list[i];
                                                                    obj.fullPackage = e.target.checked;
                                                                    obj.breakfast = e.target.checked;
                                                                    obj.lunch = e.target.checked;
                                                                    obj.dinner = e.target.checked;
    
                                                                    this.setState({
                                                                        accomodationList: list
                                                                    })
                                                                }} checked={item.dinner}> Full Package </Checkbox>
                                                            </div>


                                                        </Col>

                                                    </Form.Group>
                                                </FormAnt.Item>
                                            }


                                            <Divider></Divider>
                                        </div>))}

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="10">

                                                </Col>
                                                <Col sm="2">
                                                    <AntButton type="primary" onClick={() => {

                                                        let list = this.state.accomodationList;

                                                        list.push({
                                                            typeOfAccomodationId: null, nameOfAccomodation: '', active: true,
                                                            countryId: null, cityId: null,
                                                            location: '', phone: '', email: '',
                                                            webPage: '', price: 0.0, priceType: null, pricePeriod: '',
                                                            breakfast: false, lunch: false, dinner: false,fullPackage:false
                                                        });

                                                        this.setState({
                                                            accomodationList: list
                                                        })
                                                    }} icon={<PlusOutlined />}></AntButton>

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}

                                </div>

                                <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="6">
                                                    <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                        {<FormattedMessage id="todo" defaultMessage="Payment" />}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="5">

                                                </Col>
                                                <Col sm="1">

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}

                                    {Boolean(this.state.paymentList) && this.state.paymentList.filter(p => p.active === true).map((item, i) => (
                                        <div>
                                            {  <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Payment" />}
                                                name="OrganizationalLevelRoleGroup"
                                                id="OrganizationalLevelRoleGroup"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Type of Accomodation" /> }]} >
                                                <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                    <Col sm="3">
                                                        <Select
                                                            style={{ width: '180px' }}
                                                            placeholder="Unit"
                                                            value={item.typeOfAccomodationId} onChange={(value) => {
                                                                let list = this.state.paymentList;
                                                                let obj = list[i];
                                                                obj.businessTripGroupId = value;

                                                                this.setState({
                                                                    paymentList: list
                                                                })
                                                            }}
                                                        >
                                                            {this.state.businessTripGroupSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.groupName}</Option>
                                                            ))}
                                                        </Select>


                                                    </Col>
                                                    <Col sm="3">
                                                        <Select
                                                            style={{ width: '180px' }}
                                                            placeholder="Unit"
                                                            value={item.paymentType} onChange={(value) => {
                                                                let list = this.state.paymentList;
                                                                let obj = list[i];
                                                                obj.paymentType = value;

                                                                this.setState({
                                                                    paymentList: list
                                                                })
                                                            }}
                                                        >
                                                            {paymentTypes.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>


                                                    </Col>

                                                </Form.Group>
                                            </FormAnt.Item>}</div>))}

                                    {
                                        <FormAnt.Item

                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                <Col sm="10">

                                                </Col>
                                                <Col sm="2">
                                                    <AntButton type="primary" onClick={() => {

                                                        let list = this.state.paymentList;

                                                        list.push({ businessTripGroupId: null, paymentType: null, active: true });

                                                        this.setState({
                                                            paymentList: list
                                                        })
                                                    }} icon={<PlusOutlined />}></AntButton>

                                                </Col>

                                            </Form.Group>

                                        </FormAnt.Item>}
                                </div>



                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Description" dataIndex="description" />
                            <Column title="Name of Organizator" dataIndex="nameOfOrganizator" />
                            <Column title="Country" render={(value, row, index) => {

                                if (Boolean(row.countryId)) {
                                    let c = COUNTRIES.find(p => p.value == row.countryId);
                                    return c.name;
                                }

                            }} />
                            <Column title="Location" dataIndex="location" />
                            <Column title="Phone" dataIndex="phone" />
                            <Column title="Web Page" dataIndex="webPage" />

                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
     
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTripRequest)