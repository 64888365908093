import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest, API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Button as AntdButton, Form, Input, Select, InputNumber, DatePicker, Upload, Table, Switch, Checkbox } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";

const { Option } = Select;
const { Column } = Table;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class ItemReceiving extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hiddenSave: false,
            hiddenDataGiris: true,
            hiddenUpdate: true,
            pdfFiles: [],
            dynamicAttributes: [],
            dynamicInputs: [],
            authorizations: [],
            createNewButtonVariant: "info",
            createNewButton: "Create New",
            inspectionResultClientTable: [],
            inspectionResultClientTableA: [],
            vendors: [],
            vendors2: [],
            vendorsA: [],
            itemDescriptions: [],
            inspectionResultClientId: null,
            vendorId: null,
            vendorDisable: false,
            itemDisable: false,
            fileList: [],
            invoiceCheck: false,
            deliveryNoteCheck: false,
            packingListCheck: false,
            PackingListFileUpload: [],
            packingList: "",
            testReportCheck: false,
            TestReportFileUpload: [],
            testReport: "",
            manufacturerManualCheck: false,
            ManufacturerManualFileUpload: [],
            manufacturerManual: "",
            requiredDocumentRows: [],
            quantityModal: false,
            selectedQuantityRow: null,
            selectedQuantityRowIndex: null,
            setReceivedQuantityModal: "",
            setAcceptedQuantityModal: "",
            setRemarkModal: "",
            modalPdfViewer: false,
            modalPhotoShow: false,
            shippingReleaseTable: [],
            purchaseOrders: [],
            vendorEvaluations: [],
            invoiceConfirm: true,
            invoiceProvidedVendorCheck: false,
            invoiceDateNew: null,
            invoiceNew: "",
            invoiceNoNew: "",
            deliveryNoteConfirm: true,
            deliveryNoteProvidedVendorCheck: false,
            deliveryDateNew: null,
            deliveryNoNew: "",
            deliveryNoteNew: "",
            packingListConfirm: true,
            packingListProvidedVendorCheck: false,
            packingListNew: "",
            testReportConfirm: true,
            testReportProvidedVendorCheck: false,
            testReportNew: "",
            manufacturerManualConfirm: true,
            manufacturerManualProvidedVendorCheck: false,
            manufacturerManualNew: "",
            rfqItemList: [],
            selectedVendorEvaluation: null,
            modalInspection: false,
            modalInspectionDate: false,
            inspectionRequestModal: false,
            inspectionDateTime: null,
            inspectionResultTable: [],
        };

        this.actionTemplate = this.actionTemplate.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ItemReceiving" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "ItemReceiving",
        };
        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ItemReceiving");
        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {
        }

        this.restartTable();
        this.getTableProperties();
    }

    getTableProperties = async () => {
        this.ItemReceiving = JSON.parse(localStorage.getItem('ItemReceiving'));
        if (this.ItemReceiving != null && this.ItemReceiving != undefined && this.ItemReceiving != "") {

            await this.setState({
                bordered: this.ItemReceiving.bordered,
                title: this.ItemReceiving.titleDrm == true ? title : undefined,
                titleDrm: this.ItemReceiving.titleDrm,
                showHeader: this.ItemReceiving.showHeader,
                footer: this.ItemReceiving.footerDrm == true ? footer : undefined,
                footerDrm: this.ItemReceiving.footerDrm,
                expandable: this.ItemReceiving.expandableDrm == true ? expandable : {},
                expandableDrm: this.ItemReceiving.expandableDrm,
                rowSelection: this.ItemReceiving.rowSelection,
                ellipsis: this.ItemReceiving.ellipsis,
                tableLayout: this.ItemReceiving.tableLayout,
                size: this.ItemReceiving.size,
                top: this.ItemReceiving.top,
                bottom: this.ItemReceiving.bottom,
                editBgColor: this.ItemReceiving.editBgColor,
                deleteBgColor: this.ItemReceiving.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.ItemReceiving.yScroll,
                xScroll: this.ItemReceiving.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.ItemReceivingTable.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.ItemReceivingTable.length, showQuickJumper: true,
                    position: [this.ItemReceiving.top, this.ItemReceiving.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    actionTemplate(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.deleteModal.bind(this)} />
            </React.Fragment>;
        }
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedItemReceiving: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    saveItemReceiving = async () => {
        let list = this.state.rfqItemList;
        let flagItemReceivingList = [];
        let flagItemReceivingListSub = [];

        let flagVendorEvaluation = this.state.selectedVendorEvaluation;

        for (let index = 0; index < list.length; index++) {
            if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs !== undefined && flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs !== null
                && flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length !== 0) {
                if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0)
                    flagItemReceivingListSub.push({
                        materialName: list[index].materialName,
                        materialCode: list[index].materialCode,
                        requiredQuantity: list[index].requiredQuantity,
                        receivedQuantity: list[index].receivedQuantity,
                        acceptedQuantity: list[index].acceptedQuantity,
                        remarks: list[index].remarks,
                        rfqProjectTakeOffListSubId: list[index].projectTakeOffListSub.id
                    });
            }
            else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists !== undefined && flagVendorEvaluation.requestForQuotation.projectTakeOffLists !== null
                && flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length !== 0) {
                if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0)
                    flagItemReceivingList.push({
                        materialName: list[index].materialName,
                        materialCode: list[index].materialCode,
                        requiredQuantity: list[index].requiredQuantity,
                        receivedQuantity: list[index].receivedQuantity,
                        acceptedQuantity: list[index].acceptedQuantity,
                        remarks: list[index].remarks,
                        rfqProjectTakeOffListId: list[index].projectTakeOffList.id
                    });
            }
        }

        let list2 = this.state.requiredDocumentRows;
        let flagItemReceivingRequiredDocuments = [];

        for (let index = 0; index < list2.length; index++) {
            flagItemReceivingRequiredDocuments.push({
                description: list2[index].description,
                checked: list2[index].checked,
                confirm: list2[index].confirm,
                providedVendorCheck: list2[index].providedVendorCheck,
                file: list2[index].file,
                fileNew: list2[index].fileNew
            });
        }

        const addItemReceiving = {
            itemReceivingDate: this.state.itemReceivingDate,
            vendorLogId: this.state.vendorId,
            inspectionResultClientId: this.state.inspectionResultClientId,
            itemReceivingItemList: flagItemReceivingList,
            itemReceivingItemListSub: flagItemReceivingListSub,
            invoiceShippingDate: this.state.invoiceDate,
            invoiceShippingNo: this.state.invoiceNo,
            invoiceShipping: this.state.invoice,
            invoiceConfirm: this.state.invoiceConfirm,
            invoiceProvidedVendorCheck: this.state.invoiceProvidedVendorCheck,
            invoiceNewDate: this.state.invoiceDateNew,
            invoiceNewNo: this.state.invoiceNoNew,
            invoiceNew: this.state.invoiceNew,
            deliveryNoteDate: this.state.deliveryNoteDate,
            deliveryNoteNo: this.state.deliveryNoteNo,
            deliveryNote: this.state.deliveryNote,
            deliveryNoteConfirm: this.state.deliveryNoteConfirm,
            deliveryNoteProvidedVendorCheck: this.state.deliveryNoteProvidedVendorCheck,
            deliveryNoteNewDate: this.state.deliveryNoteDateNew,
            deliveryNoteNewNo: this.state.deliveryNoteNoNew,
            deliveryNoteNew: this.state.deliveryNoteNew,
            packingListCheck: this.state.packingListCheck,
            packingList: this.state.packingList,
            packingListConfirm: this.state.packingListConfirm,
            packingListProvidedVendorCheck: this.state.packingListProvidedVendorCheck,
            packingListNew: this.state.packingListNew,
            testReportCheck: this.state.testReportCheck,
            testReport: this.state.testReport,
            testReportConfirm: this.state.testReportConfirm,
            testReportProvidedVendorCheck: this.state.testReportProvidedVendorCheck,
            testReportNew: this.state.testReportNew,
            manufacturerManualCheck: this.state.manufacturerManualCheck,
            manufacturerManual: this.state.manufacturerManual,
            manufacturerManualConfirm: this.state.manufacturerManualConfirm,
            manufacturerManualProvidedVendorCheck: this.state.manufacturerManualProvidedVendorCheck,
            manufacturerManualNew: this.state.manufacturerManualNew,
            itemReceivingRequiredDocuments: flagItemReceivingRequiredDocuments
        }
        var response = await handleRequest("POST", "/ItemReceiving/add", addItemReceiving);
        if (response.data !== null && response.data !== undefined) {
            let splitReponse = response.data.split(",");
            if (splitReponse[0] === "SUCCESS") {
                if (this.state.inspectionRequestModal && splitReponse[1] !== undefined && splitReponse[1] !== -1) {
                    const addInspectionResult = {
                        itemReceivingId: Number(splitReponse[1]),
                        inspectionDateTime: this.state.inspectionDateTime
                    }
                    var response2 = await handleRequest("POST", "/InspectionResult/add", addInspectionResult);
                }
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
                this.createNewClientButton();
            }
            else {
                this.showError();
            }
        }
    }

    updateItemReceiving = async () => {
        let list = this.state.rfqItemList;
        let flagItemReceivingList = [];
        let flagItemReceivingListSub = [];

        for (let index = 0; index < list.length; index++) {
            if (this.state.selectedItemReceiving.itemReceivingItemListSub !== undefined && this.state.selectedItemReceiving.itemReceivingItemListSub !== null
                && this.state.selectedItemReceiving.itemReceivingItemListSub.length !== 0) {
                if (this.state.selectedItemReceiving.itemReceivingItemListSub.length > 0)
                    flagItemReceivingListSub.push({
                        id: list[index].id,
                        materialName: list[index].materialName,
                        materialCode: list[index].materialCode,
                        active: true,
                        requiredQuantity: list[index].requiredQuantity,
                        receivedQuantity: list[index].receivedQuantity,
                        acceptedQuantity: list[index].acceptedQuantity,
                        remarks: list[index].remarks,
                        rfqProjectTakeOffListSubId: list[index].rfqProjectTakeOffListSubId
                    });
            }
            else if (this.state.selectedItemReceiving.itemReceivingItemList !== undefined && this.state.selectedItemReceiving.itemReceivingItemList !== null
                && this.state.selectedItemReceiving.itemReceivingItemList.length !== 0) {
                if (this.state.selectedItemReceiving.itemReceivingItemList.length > 0)
                    flagItemReceivingList.push({
                        id: list[index].id,
                        materialName: list[index].materialName,
                        materialCode: list[index].materialCode,
                        active: true,
                        requiredQuantity: list[index].requiredQuantity,
                        receivedQuantity: list[index].receivedQuantity,
                        acceptedQuantity: list[index].acceptedQuantity,
                        remarks: list[index].remarks,
                        rfqProjectTakeOffListId: list[index].rfqProjectTakeOffListId
                    });
            }
        }

        let list2 = this.state.requiredDocumentRows;
        let flagItemReceivingRequiredDocuments = [];

        for (let index = 0; index < list2.length; index++) {
            flagItemReceivingRequiredDocuments.push({
                id: list2[index].id,
                active: true,
                description: list2[index].description,
                checked: list2[index].checked,
                confirm: list2[index].confirm,
                providedVendorCheck: list2[index].providedVendorCheck,
                file: list2[index].file,
                fileNew: list2[index].fileNew
            });
        }

        const updatedItemReceiving = {
            id: this.state.selectedItemReceiving.id,
            itemReceivingDate: this.state.itemReceivingDate,
            vendorLogId: this.state.vendorId,
            inspectionResultClientId: this.state.inspectionResultClientId,
            itemReceivingItemList: flagItemReceivingList,
            itemReceivingItemListSub: flagItemReceivingListSub,
            invoiceShippingDate: this.state.invoiceDate,
            invoiceShippingNo: this.state.invoiceNo,
            invoiceShipping: this.state.invoice,
            invoiceConfirm: this.state.invoiceConfirm,
            invoiceProvidedVendorCheck: this.state.invoiceProvidedVendorCheck,
            invoiceNewDate: this.state.invoiceDateNew,
            invoiceNewNo: this.state.invoiceNoNew,
            invoiceNew: this.state.invoiceNew,
            deliveryNoteDate: this.state.deliveryNoteDate,
            deliveryNoteNo: this.state.deliveryNoteNo,
            deliveryNote: this.state.deliveryNote,
            deliveryNoteConfirm: this.state.deliveryNoteConfirm,
            deliveryNoteProvidedVendorCheck: this.state.deliveryNoteProvidedVendorCheck,
            deliveryNoteNewDate: this.state.deliveryNoteDateNew,
            deliveryNoteNewNo: this.state.deliveryNoteNoNew,
            deliveryNoteNew: this.state.deliveryNoteNew,
            packingListCheck: this.state.packingListCheck,
            packingList: this.state.packingList,
            packingListConfirm: this.state.packingListConfirm,
            packingListProvidedVendorCheck: this.state.packingListProvidedVendorCheck,
            packingListNew: this.state.packingListNew,
            testReportCheck: this.state.testReportCheck,
            testReport: this.state.testReport,
            testReportConfirm: this.state.testReportConfirm,
            testReportProvidedVendorCheck: this.state.testReportProvidedVendorCheck,
            testReportNew: this.state.testReportNew,
            manufacturerManualCheck: this.state.manufacturerManualCheck,
            manufacturerManual: this.state.manufacturerManual,
            manufacturerManualConfirm: this.state.manufacturerManualConfirm,
            manufacturerManualProvidedVendorCheck: this.state.manufacturerManualProvidedVendorCheck,
            manufacturerManualNew: this.state.manufacturerManualNew,
            itemReceivingRequiredDocuments: flagItemReceivingRequiredDocuments
        }
        var response2 = await handleRequest("POST", "/ItemReceiving/update", updatedItemReceiving);
        if (response2.data !== null && response2.data !== undefined) {
            if (response2.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
                this.createNewClientButton();
            }
            else {
                this.showError();
            }
        }
    }

    restartTable = async () => {
        const responseInspectionResult = await handleRequest("GET", "/InspectionResult/getallByCompany");
        if (responseInspectionResult.data.length === 0) {
            this.setState({
                inspectionResultTable: [],
            });
        }

        if (responseInspectionResult.data.length !== 0) {
            this.setState({
                inspectionResultTable: responseInspectionResult.data,
            });
        }

        const responseItemReceiving = await handleRequest("GET", "/ItemReceiving/getAllByCompany");
        if (responseItemReceiving.data.length === 0) {
            this.setState({
                ItemReceivingTable: [],
            });
        }

        if (responseItemReceiving.data.length !== 0) {
            this.setState({
                ItemReceivingTable: responseItemReceiving.data,
            });
        }

        let _today = new Date();
        var HH = String(_today.getHours()).padStart(2, '0');
        var minute = String(_today.getMinutes()).padStart(2, '0');
        var dd = String(_today.getDate()).padStart(2, '0');
        var mm = String(_today.getMonth() + 1).padStart(2, '0');
        var yyyy = _today.getFullYear();

        let today = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;
        let today2 = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.setState({
            invoiceDateNew: _today,
            deliveryNoteDateNew: _today,
            itemReceivingDate: today
        });

        this.formRef.current.setFieldsValue({
            ItemReceivingDate: moment(today2, 'DD/MM/YYYY HH:mm'),
        });

        const responseShippingRelease = await handleRequest("GET", "/ShippingRelease/getallByCompany");
        if (responseShippingRelease.data.length === 0) {
            this.setState({
                shippingReleaseTable: [],
            });
        }

        if (responseShippingRelease.data.length !== 0) {
            this.setState({
                shippingReleaseTable: responseShippingRelease.data,
            });
        }

        const responsePurchaseOrder = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");
        if (responsePurchaseOrder.data.length === 0) {
            this.setState({
                purchaseOrders: [],
            });
        }

        if (responsePurchaseOrder.data.length !== 0) {
            this.setState({
                purchaseOrders: responsePurchaseOrder.data,
            });
        }

        let shippingReleseaseClientIds = [];
        for (let index = 0; index < responseShippingRelease.data.length; index++) {
            shippingReleseaseClientIds.push(responseShippingRelease.data[index].inspectionResultClientId);
        }

        let itemReceivingClientIds = [];
        for (let index = 0; index < responseItemReceiving.data.length; index++) {
            itemReceivingClientIds.push(responseItemReceiving.data[index].inspectionResultClientId);
        }

        const responseResult = await handleRequest("GET", "/InspectionResultClient/getallByCompany");
        if (responseResult.data.length === 0) {
            this.setState({
                inspectionResultClientTable: [],
            });
        }

        let flagResults = [];
        if (responseResult.data.length !== 0) {
            for (let index = 0; index < responseResult.data.length; index++) {
                if ((responseResult.data[index].clientReply === "Approved") && (responseResult.data[index].inspection.includes("FAT"))
                    && shippingReleseaseClientIds.includes(responseResult.data[index].id) && !(itemReceivingClientIds.includes(responseResult.data[index].id)))
                    flagResults.push(responseResult.data[index]);
            }
            this.setState({
                inspectionResultClientTable: flagResults,
                inspectionResultClientTableA: flagResults
            });
        }

        let flagSelectedVendors = [];
        const responseVendorSelect = await handleRequest("GET", "/VendorEvaluationSelected/getAllByCompany");

        if (responseVendorSelect.data.length === 0) {
            this.setState({
                vendorEvaluations: [],
            });
        }

        if (responseVendorSelect.data.length !== 0) {
            this.setState({
                vendorEvaluations: responseVendorSelect.data,
            });
        }

        if (responseVendorSelect.data.length !== 0) {
            for (let index = 0; index < responseVendorSelect.data.length; index++) {
                if (responseVendorSelect.data[index].selected) {
                    flagSelectedVendors.push(responseVendorSelect.data[index]);
                }
            }
        }

        let flagVendors = [];
        let flagVendors2 = [];
        let flagVendorIds = [];
        for (let index = 0; index < flagResults.length; index++) {
            for (let index1 = 0; index1 < flagSelectedVendors.length; index1++) {
                if ((flagSelectedVendors[index1].rfqId === flagResults[index].assignmentPackage.requestForQuotation.id)
                    && !(flagVendorIds.includes(flagSelectedVendors[index1].vendorLogId))) {
                    flagVendors.push({
                        id: flagSelectedVendors[index1].vendorLogId,
                        rfqId: flagSelectedVendors[index1].rfqId,
                        vendorName: flagSelectedVendors[index1].vendorLog.fullName
                    });
                    flagVendorIds.push(flagSelectedVendors[index1].vendorLogId);
                }

                if (flagSelectedVendors[index1].rfqId === flagResults[index].assignmentPackage.requestForQuotation.id) {
                    flagVendors2.push({
                        id: flagSelectedVendors[index1].vendorLogId,
                        rfqId: flagSelectedVendors[index1].rfqId,
                        vendorName: flagSelectedVendors[index1].vendorLog.fullName
                    });
                }
            }
        }

        this.setState({
            vendors: flagVendors,
            vendors2: flagVendors2
        });
    }

    deleteItemReceivingOK = async () => {
        const deleteItemReceiving = {
            id: this.state.selectedItemReceiving.id
        }
        var response = await handleRequest("POST", "/ItemReceiving/delete", deleteItemReceiving);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                for (let index = 0; index < this.state.inspectionResultTable.length; index++) {
                    if (this.state.inspectionResultTable[index].itemReceivingId === this.state.selectedItemReceiving.id) {
                        const deleteInspectionResult = {
                            id: this.state.inspectionResultTable[index].id
                        }
                        var response = await handleRequest("POST", "/InspectionResult/delete", deleteInspectionResult);
                    }
                }
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdate = async (row) => {
        this.formRef.current.setFieldsValue({
            ItemReceivingDate: moment(row.itemReceivingDate, 'DD/MM/YYYY HH:mm'),
            Vendor: row.vendorLog.fullName,
            ItemDescription: row.inspectionResultClient.assignmentPackage.requestForQuotation.projectTakeOffList.description + " - " + row.inspectionResultClient.inspection,
        });

        let _rfqItemList = [];

        if (row.itemReceivingItemListSub.length > 0) {
            for (let index = 0; index < row.itemReceivingItemListSub.length; index++)
                _rfqItemList.push({
                    id: row.itemReceivingItemListSub[index].id,
                    active: true,
                    materialName: row.itemReceivingItemListSub[index].materialName,
                    materialCode: row.itemReceivingItemListSub[index].materialCode,
                    unit: "",
                    requiredQuantity: row.itemReceivingItemListSub[index].requiredQuantity,
                    receivedQuantity: row.itemReceivingItemListSub[index].receivedQuantity,
                    acceptedQuantity: row.itemReceivingItemListSub[index].acceptedQuantity,
                    remarks: row.itemReceivingItemListSub[index].remarks
                });
        } else if (row.itemReceivingItemList.length > 0) {
            for (let index = 0; index < row.itemReceivingItemList.length; index++)
                _rfqItemList.push({
                    id: row.itemReceivingItemList[index].id,
                    active: true,
                    materialName: row.itemReceivingItemList[index].materialName,
                    materialCode: row.itemReceivingItemList[index].materialCode,
                    unit: "",
                    requiredQuantity: row.itemReceivingItemList[index].requiredQuantity,
                    receivedQuantity: row.itemReceivingItemList[index].receivedQuantity,
                    acceptedQuantity: row.itemReceivingItemList[index].acceptedQuantity,
                    remarks: row.itemReceivingItemList[index].remarks
                });
        } else
            _rfqItemList = [];

        let _requiredDocumentRows = [];

        if (row.itemReceivingRequiredDocuments.length > 0) {
            for (let index = 0; index < row.itemReceivingRequiredDocuments.length; index++)
                _requiredDocumentRows.push({
                    requiredDocumentIndex: index,
                    id: row.itemReceivingRequiredDocuments[index].id,
                    active: true,
                    description: row.itemReceivingRequiredDocuments[index].description,
                    checked: row.itemReceivingRequiredDocuments[index].checked,
                    confirm: "",
                    providedVendorCheck: row.itemReceivingRequiredDocuments[index].providedVendorCheck,
                    file: row.itemReceivingRequiredDocuments[index].file,
                    fileNew: row.itemReceivingRequiredDocuments[index].fileNew,
                    fileUpload: []
                });
        }

        let invoiceCheck = false;

        if (row.invoiceShipping !== undefined && row.invoiceShipping !== null && row.invoiceShipping !== "") {
            invoiceCheck = true;
        }

        let deliveryNoteCheck = false;

        if (row.deliveryNote !== undefined && row.deliveryNote !== null && row.deliveryNote !== "") {
            deliveryNoteCheck = true;
        }

        await this.setState({
            selectedItemReceiving: row,
            inspectionResultClientId: row.inspectionResultClientId,
            vendorId: row.vendorLogId,
            vendorDisable: true,
            itemDisable: true,
            rfqItemList: _rfqItemList,
            itemReceivingDate: row.itemReceivingDate,
            invoiceCheck: invoiceCheck,
            invoice: row.invoiceShipping,
            invoiceNo: row.invoiceShippingNo,
            invoiceDate: row.invoiceShippingDate,
            invoiceConfirm: row.invoiceConfirm,
            invoiceProvidedVendorCheck: row.invoiceProvidedVendorCheck,
            invoiceDateNew: row.invoiceNewDate,
            invoiceNew: row.invoiceNew,
            invoiceNoNew: row.invoiceNoNew,
            deliveryNoteCheck: deliveryNoteCheck,
            deliveryNote: row.deliveryNote,
            deliveryNoteNo: row.deliveryNoteNo,
            deliveryNoteDate: row.deliveryNoteDate,
            deliveryNoteConfirm: row.deliveryNoteConfirm,
            deliveryNoteProvidedVendorCheck: row.deliveryNoteProvidedVendorCheck,
            deliveryNoteDateNew: row.deliveryNoteNewDate,
            deliveryNoteNoNew: row.deliveryNoteNewNo,
            deliveryNoteNew: row.deliveryNoteNew,
            packingListCheck: row.packingListCheck,
            packingList: row.packingList,
            packingListConfirm: row.packingListConfirm,
            packingListProvidedVendorCheck: row.packingListProvidedVendorCheck,
            packingListNew: row.packingListNew,
            testReportCheck: row.testReportCheck,
            testReport: row.testReport,
            testReportConfirm: row.testReportConfirm,
            testReportProvidedVendorCheck: row.testReportProvidedVendorCheck,
            testReportNew: row.testReportNew,
            manufacturerManualCheck: row.manufacturerManualCheck,
            manufacturerManual: row.manufacturerManual,
            manufacturerManualConfirm: row.manufacturerManualConfirm,
            manufacturerManualProvidedVendorCheck: row.manufacturerManualProvidedVendorCheck,
            manufacturerManualNew: row.manufacturerManualNew,
            requiredDocumentRows: _requiredDocumentRows,
            hiddenSave: true,
            hiddenUpdate: false,
            hiddenDataGiris: false,
            createNewButton: "Cancel",
            createNewButtonVariant: "danger",
        });
    }

    resetInputs = () => {
        let today = new Date();
        let HH = String(today.getHours()).padStart(2, '0');
        let minute = String(today.getMinutes()).padStart(2, '0');
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();

        let todayDate = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

        this.formRef.current.setFieldsValue({
            Vendor: "",
            ItemDescription: ""
        });

        this.setState({
            inspectionDateTime: todayDate,
            selectedItemReceiving: [],
            vendorId: null,
            itemDescription: "",
            rfqItemList: [],
            invoice: "",
            invoiceNo: "",
            invoiceDate: "",
            invoiceConfirm: true,
            invoiceProvidedVendorCheck: false,
            invoiceDateNew: null,
            invoiceNew: "",
            invoiceNoNew: "",
            deliveryNoteConfirm: true,
            deliveryNoteProvidedVendorCheck: false,
            deliveryDateNew: null,
            deliveryNoNew: "",
            deliveryNoteNew: "",
            packingListConfirm: true,
            packingListProvidedVendorCheck: false,
            packingListNew: "",
            testReportConfirm: true,
            testReportProvidedVendorCheck: false,
            testReportNew: "",
            manufacturerManualConfirm: true,
            manufacturerManualProvidedVendorCheck: false,
            manufacturerManualNew: "",
            selectedVendorEvaluation: null,
            InvoiceFileUpload: [],
            VoucherFileUpload: [],
            PackingListFileUpload: [],
            TestReportFileUpload: [],
            ManufacturerManualFileUpload: [],
            requiredDocumentRows: [],
            fileList: [],
            inspectionResultClientTableA: this.state.inspectionResultClientTable,
            vendorsA: this.state.vendors,
            inspectionResultClientId: null,
            vendorDisable: false,
            itemDisable: false,
            hiddenSave: false,
            hiddenUpdate: true,
            hiddenDataGiris: true
        });
    }

    changeButtonCancel = () => {
        this.resetInputs();

        this.setState({
            hiddenSave: false,
            hiddenUpdate: true,
            hiddenDataGiris: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
        });
    }

    createNewClientButton = async () => {
        await this.resetInputs();

        if (this.state.createNewButton == "Create New") {
            this.setState({
                hiddenDataGiris: false,
                hiddenSave: false,
                hiddenUpdate: true,
                createNewButton: "Cancel",
                createNewButtonVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGiris: true,
                hiddenSave: true,
                hiddenUpdate: false,
                createNewButton: "Create New",
                createNewButtonVariant: "info",
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalTable = async (tableTip) => {
        this.setState({
            modalTable: !this.state.modalTable,
            tableTip: tableTip,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    disabledDate(current) {
        return moment().add(-1, 'days').endOf('day') > current;
    }

    handleChangeVendor = (e) => {
        let flagItems = [];
        let flagItemInspections = [];
        for (let index = 0; index < this.state.inspectionResultClientTable.length; index++) {
            for (let index2 = 0; index2 < this.state.vendors2.length; index2++) {
                if ((this.state.vendors2[index2].rfqId === this.state.inspectionResultClientTable[index].assignmentPackage.requestForQuotation.id)
                    && (this.state.vendors2[index2].id === e)
                    && !(flagItemInspections.includes(this.state.inspectionResultClientTable[index].assignmentPackage.requestForQuotation.projectTakeOffList.description
                        + " - " + this.state.inspectionResultClientTable[index].inspection))) {
                    flagItems.push(this.state.inspectionResultClientTable[index]);
                    flagItemInspections.push(this.state.inspectionResultClientTable[index].assignmentPackage.requestForQuotation.projectTakeOffList.description
                        + " - " + this.state.inspectionResultClientTable[index].inspection);
                }
            }
        }

        this.setState({
            vendorId: e,
            inspectionResultClientTableA: flagItems
        });
    }

    handleChangeInspectionResultClient = (e) => {
        let flagVendors = [];
        let flagVendorIds = [];
        let selectedIRCT = null;

        let selectedIRCTIndex = this.state.inspectionResultClientTable.findIndex(p => p.id === e);
        if (selectedIRCTIndex !== -1) selectedIRCT = this.state.inspectionResultClientTable[selectedIRCTIndex];

        if (selectedIRCT !== null) {
            for (let index = 0; index < this.state.vendors2.length; index++) {
                if ((this.state.vendors2[index] !== undefined) && (this.state.vendors2[index].rfqId === selectedIRCT.assignmentPackage.requestForQuotation.id) && !(flagVendorIds.includes(this.state.vendors2[index].id))) {
                    flagVendors.push({
                        id: this.state.vendors2[index].id,
                        rfqId: this.state.vendors2[index].rfqId,
                        vendorName: this.state.vendors2[index].vendorName
                    });
                    flagVendorIds.push(this.state.vendors2[index].id);
                }
            }

            let _rfqItemList = [];

            for (let index = 0; index < this.state.purchaseOrders.length; index++) {
                if (selectedIRCT.assignmentPackage.requestForQuotation.id === this.state.purchaseOrders[index].rfqId) {
                    if (this.state.purchaseOrders[index].vendorEvaluationSelected !== undefined && this.state.purchaseOrders[index].vendorEvaluationSelected !== null
                        && this.state.purchaseOrders[index].purchaseOrderItemList !== undefined && this.state.purchaseOrders[index].purchaseOrderItemList !== null) {

                        let flagIndex = -1;
                        for (let index3 = 0; index3 < this.state.vendorEvaluations.length; index3++) {
                            if (this.state.vendorEvaluations[index3].id === this.state.purchaseOrders[index].vendorEvaluationSelectedId)
                                flagIndex = index3;
                        }

                        let flagVendorEvaluation = null;

                        if (flagIndex !== -1)
                            if (this.state.vendorEvaluations[flagIndex] !== undefined)
                                if (this.state.vendorEvaluations[flagIndex] !== null) {
                                    flagVendorEvaluation = this.state.vendorEvaluations[flagIndex];

                                    this.setState({
                                        selectedVendorEvaluation: flagVendorEvaluation
                                    });
                                }

                        if (flagVendorEvaluation.requestForQuotation !== undefined) {
                            if (flagVendorEvaluation.requestForQuotation !== null) {
                                if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0) {
                                    let a = 0;
                                    for (let index1 = 0; index1 < flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length; index1++) {
                                        if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.includes(",")) {
                                            var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",").length;
                                            var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",");
                                            var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate.split(",");
                                            for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                                _rfqItemList.push({
                                                    id: this.state.purchaseOrders[index].purchaseOrderItemListSub[a].id,
                                                    active: true,
                                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                                    unit: "",
                                                    price: this.state.purchaseOrders[index].purchaseOrderItemListSub[a].price,
                                                    priceCurrency: this.state.purchaseOrders[index].purchaseOrderItemListSub[a].currency,
                                                    discount: this.state.purchaseOrders[index].purchaseOrderItemListSub[a].discount,
                                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                                    projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1],
                                                    requiredQuantity: flagQuantity[index2],
                                                    deliveryDate: flagDeliveryDate[index2]
                                                });
                                                a = a + 1;
                                            }
                                        } else {
                                            _rfqItemList.push({
                                                id: this.state.purchaseOrders[index].purchaseOrderItemListSub[index1].id,
                                                active: true,
                                                materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                                materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                                unit: "",
                                                price: this.state.purchaseOrders[index].purchaseOrderItemListSub[index1].price,
                                                priceCurrency: this.state.purchaseOrders[index].purchaseOrderItemListSub[index1].currency,
                                                discount: this.state.purchaseOrders[index].purchaseOrderItemListSub[index1].discount,
                                                projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                                projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1],
                                                requiredQuantity: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity,
                                                deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate
                                            });
                                        }
                                    }
                                } else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0) {
                                    let a = 0;
                                    if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.includes(",")) {
                                        var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",").length;
                                        var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",");
                                        var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate.split(",");
                                        for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                            _rfqItemList.push({
                                                id: this.state.purchaseOrders[index].purchaseOrderItemList[a].id,
                                                active: true,
                                                materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                                materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                                unit: "",
                                                price: this.state.purchaseOrders[index].purchaseOrderItemList[a].price,
                                                priceCurrency: this.state.purchaseOrders[index].purchaseOrderItemList[a].currency,
                                                discount: this.state.purchaseOrders[index].purchaseOrderItemList[a].discount,
                                                projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                                projectTakeOffListSub: null,
                                                requiredQuantity: flagQuantity[index2],
                                                deliveryDate: flagDeliveryDate[index2]
                                            });
                                            a = a + 1;
                                        }
                                    } else {
                                        _rfqItemList.push({
                                            id: this.state.purchaseOrders[index].purchaseOrderItemList[0].id,
                                            active: true,
                                            materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                            materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                            unit: "",
                                            price: this.state.purchaseOrders[index].purchaseOrderItemList[0].price,
                                            priceCurrency: this.state.purchaseOrders[index].purchaseOrderItemList[0].currency,
                                            discount: this.state.purchaseOrders[index].purchaseOrderItemList[0].discount,
                                            projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                            projectTakeOffListSub: null,
                                            requiredQuantity: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity,
                                            deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate
                                        });
                                    }
                                } else
                                    _rfqItemList = [];
                            }
                            else
                                _rfqItemList = [];
                        }

                        this.setState({
                            rfqItemList: _rfqItemList
                        });
                    }
                }
            }

            for (let index = 0; index < this.state.shippingReleaseTable.length; index++) {
                if (selectedIRCT.id === this.state.shippingReleaseTable[index].inspectionResultClientId) {
                    if (this.state.shippingReleaseTable[index].invoice !== undefined && this.state.shippingReleaseTable[index].invoice !== null
                        && this.state.shippingReleaseTable[index].voucher !== undefined && this.state.shippingReleaseTable[index].voucher !== null
                        && this.state.shippingReleaseTable[index].packingList !== undefined && this.state.shippingReleaseTable[index].packingList !== null
                        && this.state.shippingReleaseTable[index].testReport !== undefined && this.state.shippingReleaseTable[index].testReport !== null
                        && this.state.shippingReleaseTable[index].manufacturerManual !== undefined && this.state.shippingReleaseTable[index].manufacturerManual !== null
                        && this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments !== undefined && this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments !== null) {

                        let _requiredDocuments = [];

                        for (let index1 = 0; index1 < this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments.length; index1++) {
                            if (this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments[index1].checked)
                                _requiredDocuments.push({
                                    requiredDocumentIndex: _requiredDocuments.length + 1,
                                    description: this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments[index1].description,
                                    checked: this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments[index1].checked,
                                    confirm: true,
                                    providedVendorCheck: false,
                                    file: this.state.shippingReleaseTable[index].shippingReleaseRequiredDocuments[index1].file,
                                    fileNew: "",
                                    fileUpload: []
                                });
                        }

                        let invoiceCheck = false;
                        let deliveryNoteCheck = false;

                        if (this.state.shippingReleaseTable[index].invoice !== undefined && this.state.shippingReleaseTable[index].invoice !== null
                            && this.state.shippingReleaseTable[index].invoice !== "") {
                            invoiceCheck = true;
                        }

                        if (this.state.shippingReleaseTable[index].voucher !== undefined && this.state.shippingReleaseTable[index].voucher !== null
                            && this.state.shippingReleaseTable[index].voucher !== "") {
                            deliveryNoteCheck = true;
                        }

                        this.setState({
                            invoice: this.state.shippingReleaseTable[index].invoice,
                            invoiceNo: this.state.shippingReleaseTable[index].invoiceNo,
                            invoiceDate: this.state.shippingReleaseTable[index].invoiceDate,
                            deliveryNote: this.state.shippingReleaseTable[index].voucher,
                            deliveryNoteNo: this.state.shippingReleaseTable[index].voucherNo,
                            deliveryNoteDate: this.state.shippingReleaseTable[index].voucherDate,
                            packingList: this.state.shippingReleaseTable[index].packingList,
                            testReport: this.state.shippingReleaseTable[index].testReport,
                            manufacturerManual: this.state.shippingReleaseTable[index].manufacturerManual,
                            invoiceCheck: invoiceCheck,
                            deliveryNoteCheck: deliveryNoteCheck,
                            packingListCheck: this.state.shippingReleaseTable[index].packingListCheck,
                            testReportCheck: this.state.shippingReleaseTable[index].testReportCheck,
                            manufacturerManualCheck: this.state.shippingReleaseTable[index].manufacturerManualCheck,
                            requiredDocumentRows: _requiredDocuments
                        });
                    }
                }
            }
        }

        this.setState({
            inspectionResultClientId: e,
            vendorsA: flagVendors
        });
    }

    showModalQuantity = (row, index) => {
        this.setState({
            selectedQuantityRow: row,
            selectedQuantityRowIndex: index,
            setReceivedQuantityModal: row.receivedQuantity,
            setAcceptedQuantityModal: row.acceptedQuantity,
            setRemarkModal: row.remarks,
            quantityModal: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalQuantity = () => {
        this.setState({
            quantityModal: !this.state.quantityModal,
            selectedQuantityRow: null,
            selectedQuantityRowIndex: null,
            setReceivedQuantityModal: "",
            setAcceptedQuantityModal: "",
            setRemarkModal: "",
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    setQuantity = () => {
        if (this.state.setReceivedQuantityModal !== null && this.state.setReceivedQuantityModal !== ""
            && this.state.setAcceptedQuantityModal !== null && this.state.setAcceptedQuantityModal !== "") {

            let list = this.state.rfqItemList;
            let flagIndex = this.state.selectedQuantityRowIndex;
            list[flagIndex].receivedQuantity = this.state.setReceivedQuantityModal;
            list[flagIndex].acceptedQuantity = this.state.setAcceptedQuantityModal;
            list[flagIndex].remarks = this.state.setRemarkModal;

            this.setState({
                rfqItemList: list.slice(),
                quantityModal: false
            });
        } else {
            this.showWarning();
        }
    }

    showReport = async (fileRealUrl) => {
        try {
            if (fileRealUrl.endsWith('.pdf')) {
                this.setState({
                    pdfFile: fileRealUrl,
                    modalPdfViewer: true,
                });
            } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
                || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
                this.setState({
                    imageUrl: fileRealUrl,
                    modalPhotoShow: true,
                })
            } else {
                window.location.href = fileRealUrl;
            }
        } catch (error) {
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
        }
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    onChangeInvoiceDateNew = (value, dateString) => {
        let _today = new Date();

        if (dateString !== "")
            this.setState({
                invoiceDateNew: dateString,
            });
        else
            this.setState({
                invoiceDateNew: _today
            });
    }

    onChangeDeliveryNoteDateNew = (value, dateString) => {
        let _today = new Date();

        if (dateString !== "")
            this.setState({
                deliveryNoteDateNew: dateString,
            });
        else
            this.setState({
                deliveryNoteDateNew: _today
            });
    }

    onChangeDateTime = (value, dateString) => {
        if (dateString !== "")
            this.setState({
                inspectionDateTime: dateString,
            });
        else {
            let today = new Date();
            let HH = String(today.getHours()).padStart(2, '0');
            let minute = String(today.getMinutes()).padStart(2, '0');
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();

            let todayDate = dd + '/' + mm + '/' + yyyy + ' ' + HH + ':' + minute;

            this.setState({
                inspectionDateTime: todayDate,
            });
        }
    }

    yesToInspection = () => {
        this.setState({
            inspectionRequestModal: true,
            modalInspection: false,
            modalInspectionDate: true
        });
    }

    noToInspection = () => {
        this.setState({
            inspectionRequestModal: false,
            modalInspection: false
        });

        this.saveItemReceiving();
    }

    saveInspectionDate = () => {
        if (this.state.inspectionDateTime !== null) {
            this.setState({
                modalInspectionDate: false
            });
            this.saveItemReceiving();
        } else {
            this.showWarning();
        }
    }

    showModalInspection = () => {
        this.setState({
            modalInspection: true,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalInspection = () => {
        this.setState({
            modalInspection: !this.state.modalInspection,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalInspectionDate = () => {
        this.setState({
            modalInspectionDate: !this.state.modalInspectionDate,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    render() {
        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        var itemDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Date");
        var itemVendorAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Vendor");
        var itemItemDescriptionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemDescription");

        var itemDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "Date");
        var itemVendorRequired = this.state.dynamicInputs.find(p => p.inputKey === "Vendor");
        var itemItemDescriptionRequired = this.state.dynamicInputs.find(p => p.inputKey === "ItemDescription");

        const columns = [
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("ItemReceiving")} icon="pi pi-cog"></PrimeButton>,
            },
            {
                dataIndex: "itemReceivingDate",
                key: "key",
                title: <FormattedMessage id="ItemReceivingDate" defaultMessage="Date" />,
            },
            {
                key: "key",
                title: <FormattedMessage id="ItemReceivingItemDescription" defaultMessage="Item Code" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.inspectionResultClient !== undefined)
                            return data.inspectionResultClient.assignmentPackage.requestForQuotation.projectTakeOffList.code;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="ItemReceivingItemDescription" defaultMessage="Item Description" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.inspectionResultClient !== undefined)
                            return data.inspectionResultClient.assignmentPackage.requestForQuotation.projectTakeOffList.description + " - " + data.inspectionResultClient.inspection;
                    }
                    return "";
                }
            },
            {
                key: "key",
                title: <FormattedMessage id="ItemReceivingVendor" defaultMessage="Vendor" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.vendorLog !== undefined)
                            return data.vendorLog.fullName;
                    }
                    return "";
                }
            },
            {
                dataIndex: "poNo",
                key: "key",
                title: <FormattedMessage id="ItemReceivingDate" defaultMessage="PO No/Quantity" />,
            },
            {
                dataIndex: "invoiceQuantity",
                key: "key",
                title: <FormattedMessage id="ItemReceivingDate" defaultMessage="Invoice Quantity" />,
            },
            {
                dataIndex: "receivedQuantity",
                key: "key",
                title: <FormattedMessage id="ItemReceivingDate" defaultMessage="Received Quantity" />,
            },
            {
                dataIndex: "acceptedQuantity",
                key: "key",
                title: <FormattedMessage id="ItemReceivingDate" defaultMessage="Accepted Quantity" />,
            },
        ];

        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                    <Form
                        {...layout}
                        initialValues={{ remember: false }}
                        onFinish={this.state.hiddenUpdate == false ? this.updateItemReceiving : this.showModalInspection}
                        onFinishFailed={onFinishFailed}
                        ref={this.formRef} >

                        <Row>
                            <Col xs={8}></Col>
                            <Col xs={3}>
                                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                                    variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                                </Button>
                            </Col>
                            <Col xs={1}>
                                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("ItemReceiving")} icon="pi pi-cog"></PrimeButton>
                            </Col>
                        </Row>

                        <div hidden={this.state.hiddenDataGiris}>
                            <br />

                            {(itemDateAttributes.visible) &&
                                <Form.Item
                                    label={<FormattedMessage id="ItemReceivingDate" defaultMessage="Date" />}
                                    name="ItemReceivingDate"
                                    rules={[{ required: itemDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateRequired.translateRequired) == true ? itemDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                                    <DatePicker disabledDate={this.disabledDate} value={moment(this.state.itemReceivingDate, 'DD/MM/YYYY HH:mm')}
                                        disabled showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
                                </Form.Item>}

                            {(itemVendorAttributes.visible) &&
                                <Form.Item
                                    label={<FormattedMessage id="ItemReceivingVendor" defaultMessage="Vendor" />}
                                    name="Vendor"
                                    rules={[{ required: itemVendorRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemVendorRequired.translateRequired) == true ? itemVendorRequired.translateRequired : -1} defaultMessage="Please input your Vendor!" /> }]} >
                                    <Select name="Vendor"
                                        disabled={this.state.vendorDisable}
                                        style={{ width: '100%' }}
                                        value={this.state.vendorId}
                                        onChange={this.handleChangeVendor}>
                                        {this.state.vendorsA.map(i => (<Option key={i.id} value={i.id}>{i.vendorName}</Option>))}
                                    </Select>
                                </Form.Item>}

                            {(itemItemDescriptionAttributes.visible) &&
                                <Form.Item
                                    label={<FormattedMessage id="ItemReceivingItemDescription" defaultMessage="Item Description" />}
                                    name="ItemDescription"
                                    rules={[{ required: itemItemDescriptionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemItemDescriptionRequired.translateRequired) == true ? itemItemDescriptionRequired.translateRequired : -1} defaultMessage="Please input your Item Description!" /> }]} >
                                    <Select name="ItemDescription"
                                        disabled={this.state.itemDisable}
                                        style={{ width: '100%' }}
                                        value={this.state.inspectionResultClientId}
                                        onChange={this.handleChangeInspectionResultClient}>
                                        {this.state.inspectionResultClientTableA.map(i => (
                                            <Option key={i.id} value={i.id}>{i.assignmentPackage.requestForQuotation.projectTakeOffList.description + " - " + i.inspection}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>}

                            <Form.Item
                                label={<FormattedMessage id="ItemReceivingQuantity" defaultMessage="Quantity" />}
                                style={{ marginBottom: "0px" }}>
                            </Form.Item>

                            <FormBoot>
                                <FormBoot.Group as={Row}>
                                    <Col sm="3">
                                    </Col>
                                    <Col sm="6">
                                        <Table dataSource={this.state.rfqItemList} pagination={false}>
                                            <Column title="Description" key="description" dataIndex="materialName" />
                                            <Column title="Code" key="code" dataIndex="materialCode" />
                                            <Column title="Required Quantity" key="requiredQuantity" dataIndex="requiredQuantity" />
                                            <Column title="Unit" key="unit" dataIndex="unit" />
                                            <Column title="Received Quantity" key="receivedQuantity" dataIndex="receivedQuantity" />
                                            <Column title="Accepted Quantity" key="acceptedQuantity" dataIndex="acceptedQuantity" />
                                            <Column title="Remarks" key="remarks" dataIndex="remarks" />
                                            <Column title="" width='100px' key="set" render={(value, row, index) => {
                                                return <AntdButton type="primary" onClick={() => this.showModalQuantity(row, index)}>Set Quantity</AntdButton>
                                            }} />
                                        </Table>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>

                            <Form.Item
                                label={<FormattedMessage id="ItemReceivingRequiredDocuments" defaultMessage="Required Documents" />}
                                name="ShippingReleaseRequiredDocuments"
                                style={{ marginBottom: "0px" }}>
                            </Form.Item>

                            {(this.state.invoiceCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoice" defaultMessage="Invoice: " /></FormBoot.Label>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}>No: {this.state.invoiceNo} - Date: {this.state.invoiceDate}</FormBoot.Label>
                                {(this.state.invoice != null && this.state.invoice != undefined && this.state.invoice != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.invoice)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                                <Switch checkedChildren="Confirmed" unCheckedChildren="Not Confirmed"
                                    checked={this.state.invoiceConfirm} defaultChecked style={{ marginRight: "2rem" }}
                                    onChange={(value) => { this.setState({ invoiceConfirm: value }) }} />
                            </Form.Item>}

                            {!(this.state.invoiceCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoice" defaultMessage="Invoice: " /></FormBoot.Label>
                                {(this.state.invoiceNew === "") && <FormBoot.Label style={{ color: 'red', marginRight: "2rem" }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceNotFound" defaultMessage="No any data. Please encode P.O details." />
                                </FormBoot.Label>}
                                <Checkbox style={{ marginRight: "2rem" }}
                                    onChange={(e) => {
                                        this.setState({ invoiceProvidedVendorCheck: e.target.checked });
                                        if (!this.state.invoiceConfirm)
                                            this.setState({ invoiceConfirm: true });
                                    }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceCheckbox" defaultMessage="Not provided by vendor" />
                                </Checkbox>
                                {!(this.state.invoiceProvidedVendorCheck) && <AntdButton style={{ marginRight: "2rem" }} onClick={() => { this.setState({ invoiceConfirm: !this.state.invoiceConfirm }) }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceAddDetails" defaultMessage="Add Details" />
                                </AntdButton>}
                            </Form.Item>}

                            {(!this.state.invoiceConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceNoNew" defaultMessage="Invoice No: " /></FormBoot.Label>
                                <Input id="InvoiceNoNew" style={{ width: '100px' }} value={this.state.invoiceNoNew} onChange={(e) => this.setState({ invoiceNoNew: e.target.value })} />
                            </Form.Item>}

                            {(!this.state.invoiceConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceDateNew" defaultMessage="Invoice Date: " /></FormBoot.Label>
                                <DatePicker value={moment(this.state.invoiceDateNew, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeInvoiceDateNew} />
                            </Form.Item>}

                            {(!this.state.invoiceConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoice" defaultMessage="Invoice File: " /></FormBoot.Label>
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.InvoiceFileUpload}
                                    onChange={({ fileList }) => {
                                        if (fileList.length == 1) {
                                            var invoiceNew = "";
                                            if (Boolean(fileList[0].response)) {
                                                invoiceNew = fileList[0].response.url;
                                            }
                                            this.setState({
                                                InvoiceFileUpload: fileList,
                                                invoiceNew: invoiceNew,
                                            });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.InvoiceFileUpload.length > 0) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                            InvoiceFileUpload: [],
                                            invoiceNew: "",
                                        })
                                    }
                                    }>
                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                                    </AntdButton>
                                </Upload>
                            </Form.Item>}

                            {(!this.state.hiddenUpdate) && (this.state.invoiceNew !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Uploaded File: " /></FormBoot.Label>
                                {(this.state.invoiceNew != null && this.state.invoiceNew != undefined && this.state.invoiceNew != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.invoiceNew)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceNewAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                            </Form.Item>}

                            {(this.state.deliveryNoteCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNote" defaultMessage="Delivery Note: " /></FormBoot.Label>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}>No: {this.state.deliveryNoteNo} - Date: {this.state.deliveryNoteDate}</FormBoot.Label>
                                {(this.state.deliveryNote != null && this.state.deliveryNote != undefined && this.state.deliveryNote != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.deliveryNote)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                                <Switch checkedChildren="Confirmed" unCheckedChildren="Not Confirmed"
                                    checked={this.state.deliveryNoteConfirm} defaultChecked style={{ marginRight: "2rem" }}
                                    onChange={(value) => { this.setState({ deliveryNoteConfirm: value }) }} />
                            </Form.Item>}

                            {!(this.state.deliveryNoteCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNote" defaultMessage="Delivery Note: " /></FormBoot.Label>
                                {(this.state.deliveryNoteNew === "") && <FormBoot.Label style={{ color: 'red', marginRight: "2rem" }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteNotFound" defaultMessage="No any data. Please encode P.O details." />
                                </FormBoot.Label>}
                                <Checkbox style={{ marginRight: "2rem" }}
                                    onChange={(e) => {
                                        this.setState({ deliveryNoteProvidedVendorCheck: e.target.checked });
                                        if (!this.state.deliveryNoteConfirm)
                                            this.setState({ deliveryNoteConfirm: true });
                                    }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteCheckbox" defaultMessage="Not provided by vendor" />
                                </Checkbox>
                                {!(this.state.deliveryNoteProvidedVendorCheck) && <AntdButton style={{ marginRight: "2rem" }} onClick={() => { this.setState({ deliveryNoteConfirm: !this.state.deliveryNoteConfirm }) }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteAddDetails" defaultMessage="Add Details" />
                                </AntdButton>}
                            </Form.Item>}

                            {(!this.state.deliveryNoteConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoiceNoNew" defaultMessage="Delivery No: " /></FormBoot.Label>
                                <Input id="InvoiceNoNew" style={{ width: '100px' }} value={this.state.deliveryNoteNoNew} onChange={(e) => this.setState({ deliveryNoteNoNew: e.target.value })} />
                            </Form.Item>}

                            {(!this.state.deliveryNoteConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Delivery Date: " /></FormBoot.Label>
                                <DatePicker value={moment(this.state.deliveryNoteDateNew, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDeliveryNoteDateNew} />
                            </Form.Item>}

                            {(!this.state.deliveryNoteConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNote" defaultMessage="Delivery File: " /></FormBoot.Label>
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.VoucherFileUpload}
                                    onChange={({ fileList }) => {
                                        if (fileList.length == 1) {
                                            var deliveryNoteNew = "";
                                            if (Boolean(fileList[0].response)) {
                                                deliveryNoteNew = fileList[0].response.url;
                                            }
                                            this.setState({
                                                VoucherFileUpload: fileList,
                                                deliveryNoteNew: deliveryNoteNew,
                                            });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.VoucherFileUpload.length > 0) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                            VoucherFileUpload: [],
                                            deliveryNoteNew: "",
                                        })
                                    }
                                    }>
                                    <AntdButton style={{ marginTop: "0.2rem" }}>
                                        <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                                    </AntdButton>
                                </Upload>
                            </Form.Item>}

                            {(!this.state.hiddenUpdate) && (this.state.deliveryNoteNew !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Uploaded File: " /></FormBoot.Label>
                                {(this.state.deliveryNoteNew != null && this.state.deliveryNoteNew != undefined && this.state.deliveryNoteNew != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.deliveryNoteNew)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteNewAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                            </Form.Item>}

                            {(this.state.packingListCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsInvoice" defaultMessage="Packing List: " /></FormBoot.Label>
                                {(this.state.packingList != null && this.state.packingList != undefined && this.state.packingList != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.packingList)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsPackingListAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                                <Switch checkedChildren="Confirmed" unCheckedChildren="Not Confirmed"
                                    checked={this.state.packingListConfirm} defaultChecked style={{ marginRight: "2rem" }}
                                    onChange={(value) => { this.setState({ packingListConfirm: value }) }} />
                            </Form.Item>}

                            {!(this.state.packingListCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsPackingList" defaultMessage="Packing List: " /></FormBoot.Label>
                                {(this.state.packingListNew === "") && <FormBoot.Label style={{ color: 'red', marginRight: "2rem" }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsPackingListNotFound" defaultMessage="No any attachment. Please upload Packıng List" />
                                </FormBoot.Label>}
                                <Checkbox style={{ marginRight: "2rem" }}
                                    checked={this.state.packingListProvidedVendorCheck}
                                    onChange={(e) => {
                                        this.setState({ packingListProvidedVendorCheck: e.target.checked });
                                        if (!this.state.packingListConfirm)
                                            this.setState({ packingListConfirm: true });
                                    }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsPackingListCheckbox" defaultMessage="Not provided by vendor" />
                                </Checkbox>
                                {!(this.state.packingListProvidedVendorCheck) && <AntdButton style={{ marginRight: "2rem" }} onClick={() => { this.setState({ packingListConfirm: !this.state.packingListConfirm }) }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsPackingListAddDetails" defaultMessage="Upload" />
                                </AntdButton>}
                            </Form.Item>}

                            {(!this.state.packingListConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsPackingList" defaultMessage="Packing List File: " /></FormBoot.Label>
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.PackingListFileUpload}
                                    onChange={({ fileList }) => {
                                        if (fileList.length == 1) {
                                            var packingListNew = "";
                                            if (Boolean(fileList[0].response)) {
                                                packingListNew = fileList[0].response.url;
                                            }
                                            this.setState({
                                                PackingListFileUpload: fileList,
                                                packingListNew: packingListNew,
                                            });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.PackingListFileUpload.length > 0) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                            PackingListFileUpload: [],
                                            packingListNew: "",
                                        })
                                    }
                                    }>
                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                                    </AntdButton>
                                </Upload>
                            </Form.Item>}

                            {(!this.state.hiddenUpdate) && (this.state.packingListNew !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Uploaded File: " /></FormBoot.Label>
                                {(this.state.packingListNew != null && this.state.packingListNew != undefined && this.state.packingListNew != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.packingListNew)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentspackingListNewAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                            </Form.Item>}

                            {(this.state.testReportCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsTestReport" defaultMessage="Test Report: " /></FormBoot.Label>
                                {(this.state.testReport != null && this.state.testReport != undefined && this.state.testReport != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.testReport)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                                <Switch checkedChildren="Confirmed" unCheckedChildren="Not Confirmed"
                                    checked={this.state.testReportConfirm} defaultChecked style={{ marginRight: "2rem" }}
                                    onChange={(value) => { this.setState({ testReportConfirm: value }) }} />
                            </Form.Item>}

                            {!(this.state.testReportCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsTestReport" defaultMessage="Test Report: " /></FormBoot.Label>
                                {(this.state.testReportNew === "") && <FormBoot.Label style={{ color: 'red', marginRight: "2rem" }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportNotFound" defaultMessage="No any attachment. Please upload Test Report" />
                                </FormBoot.Label>}
                                <Checkbox style={{ marginRight: "2rem" }}
                                    checked={this.state.testReportProvidedVendorCheck}
                                    onChange={(e) => {
                                        this.setState({ testReportProvidedVendorCheck: e.target.checked });
                                        if (!this.state.testReportConfirm)
                                            this.setState({ testReportConfirm: true });
                                    }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportCheckbox" defaultMessage="Not provided by vendor" />
                                </Checkbox>
                                {!(this.state.testReportProvidedVendorCheck) && <AntdButton style={{ marginRight: "2rem" }} onClick={() => { this.setState({ testReportConfirm: !this.state.testReportConfirm }) }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportAddDetails" defaultMessage="Upload" />
                                </AntdButton>}
                            </Form.Item>}

                            {(!this.state.testReportConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsPackingList" defaultMessage="Test Report File: " /></FormBoot.Label>
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.TestReportFileUpload}
                                    onChange={({ fileList }) => {
                                        if (fileList.length == 1) {
                                            var testReportNew = "";
                                            if (Boolean(fileList[0].response)) {
                                                testReportNew = fileList[0].response.url;
                                            }
                                            this.setState({
                                                TestReportFileUpload: fileList,
                                                testReportNew: testReportNew,
                                            });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.TestReportFileUpload.length > 0) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                            TestReportFileUpload: [],
                                            testReportNew: "",
                                        })
                                    }
                                    }>
                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                                    </AntdButton>
                                </Upload>
                            </Form.Item>}

                            {(!this.state.hiddenUpdate) && (this.state.testReportNew !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Uploaded File: " /></FormBoot.Label>
                                {(this.state.testReportNew != null && this.state.testReportNew != undefined && this.state.testReportNew != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.testReportNew)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportNewAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                            </Form.Item>}

                            {(this.state.manufacturerManualCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManual" defaultMessage="Manufacturer Manual: " /></FormBoot.Label>
                                {(this.state.manufacturerManual != null && this.state.manufacturerManual != undefined && this.state.manufacturerManual != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.manufacturerManual)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                                <Switch checkedChildren="Confirmed" unCheckedChildren="Not Confirmed"
                                    checked={this.state.manufacturerManualConfirm} defaultChecked style={{ marginRight: "2rem" }}
                                    onChange={(value) => { this.setState({ manufacturerManualConfirm: value }) }} />
                            </Form.Item>}

                            {!(this.state.manufacturerManualCheck) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManual" defaultMessage="Manufacturer Manual: " /></FormBoot.Label>
                                {(this.state.manufacturerManualNew === "") && <FormBoot.Label style={{ color: 'red', marginRight: "2rem" }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsTestReportNotFound" defaultMessage="No any attachment. Please upload Manufacturer Manual" />
                                </FormBoot.Label>}
                                <Checkbox style={{ marginRight: "2rem" }}
                                    checked={this.state.manufacturerManualProvidedVendorCheck}
                                    onChange={(e) => {
                                        this.setState({ manufacturerManualProvidedVendorCheck: e.target.checked });
                                        if (!this.state.manufacturerManualConfirm)
                                            this.setState({ manufacturerManualConfirm: true });
                                    }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManualCheckbox" defaultMessage="Not provided by vendor" />
                                </Checkbox>
                                {!(this.state.manufacturerManualProvidedVendorCheck) && <AntdButton style={{ marginRight: "2rem" }} onClick={() => { this.setState({ manufacturerManualConfirm: !this.state.manufacturerManualConfirm }) }}>
                                    <FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManualAddDetails" defaultMessage="Upload" />
                                </AntdButton>}
                            </Form.Item>}

                            {(!this.state.manufacturerManualConfirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManual" defaultMessage="Manufacturer Manual File: " /></FormBoot.Label>
                                <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.ManufacturerManualFileUpload}
                                    onChange={({ fileList }) => {
                                        if (fileList.length == 1) {
                                            var manufacturerManualNew = "";
                                            if (Boolean(fileList[0].response)) {
                                                manufacturerManualNew = fileList[0].response.url;
                                            }
                                            this.setState({
                                                ManufacturerManualFileUpload: fileList,
                                                manufacturerManualNew: manufacturerManualNew,
                                            });
                                        }
                                    }}
                                    beforeUpload={(fileList) => {
                                        if (this.state.ManufacturerManualFileUpload.length > 0) {
                                            this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                        this.setState({
                                            ManufacturerManualFileUpload: [],
                                            manufacturerManualNew: "",
                                        })
                                    }
                                    }>
                                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                        <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                                    </AntdButton>
                                </Upload>
                            </Form.Item>}

                            {(!this.state.hiddenUpdate) && (this.state.manufacturerManualNew !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                {...tailFormItemLayout}>
                                <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Uploaded File: " /></FormBoot.Label>
                                {(this.state.manufacturerManualNew != null && this.state.manufacturerManualNew != undefined && this.state.manufacturerManualNew != "") &&
                                    <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.manufacturerManualNew)} type="primary" icon={<DownloadOutlined />} >
                                        <FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManualNewAttachment" defaultMessage="Attachment" />
                                    </AntdButton>}
                            </Form.Item>}

                            {this.state.requiredDocumentRows.map((item, index) => (
                                <div>
                                    {(item.file !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                        {...tailFormItemLayout}>
                                        <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}>{item.description}:</FormBoot.Label>
                                        {(this.state.requiredDocumentRows[index].file != null && this.state.requiredDocumentRows[index].file != undefined
                                            && this.state.requiredDocumentRows[index].file != "") &&
                                            <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(this.state.requiredDocumentRows[index].file)} type="primary" icon={<DownloadOutlined />} >
                                                <FormattedMessage id="ItemReceivingRequiredDocumentsAttachment" defaultMessage="Attachment" />
                                            </AntdButton>}
                                        <Switch checkedChildren="Confirmed" unCheckedChildren="Not Confirmed"
                                            checked={this.state.requiredDocumentRows[index].confirm} defaultChecked style={{ marginRight: "2rem" }}
                                            onChange={(value) => {
                                                let list = this.state.requiredDocumentRows;
                                                list[index].confirm = value;
                                                this.setState({
                                                    requiredDocumentRows: list.slice()
                                                });
                                            }} />
                                    </Form.Item>}

                                    {(item.file === "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                        {...tailFormItemLayout}>
                                        <FormBoot.Label style={{ color: 'black', marginRight: "2rem" }}>{item.description}:</FormBoot.Label>
                                        {(item.fileNew === "") && <FormBoot.Label style={{ color: 'red', marginRight: "2rem" }}>
                                            No any attachment. Please upload {item.description}.
                                        </FormBoot.Label>}
                                        <Checkbox style={{ marginRight: "2rem" }}
                                            checked={item.providedVendorCheck}
                                            onChange={(e) => {
                                                let list = this.state.requiredDocumentRows;
                                                list[index].providedVendorCheck = e.target.checked;
                                                this.setState({
                                                    requiredDocumentRows: list.slice()
                                                });
                                                if (!item.confirm) {
                                                    let list = this.state.requiredDocumentRows;
                                                    list[index].confirm = true;
                                                    this.setState({
                                                        requiredDocumentRows: list.slice()
                                                    });
                                                }
                                            }}>
                                            <FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManualCheckbox" defaultMessage="Not provided by vendor" />
                                        </Checkbox>
                                        {!(item.providedVendorCheck) && <AntdButton style={{ marginRight: "2rem" }}
                                            onClick={() => {
                                                let list = this.state.requiredDocumentRows;
                                                list[index].confirm = !list[index].confirm;
                                                this.setState({
                                                    requiredDocumentRows: list.slice()
                                                });
                                            }}>
                                            <FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManualAddDetails" defaultMessage="Upload" />
                                        </AntdButton>}
                                    </Form.Item>}

                                    {!(item.confirm) && (this.state.inspectionResultClientId !== null) && <Form.Item
                                        {...tailFormItemLayout}>
                                        <FormBoot.Label style={{ color: '#6c757d', marginRight: "2rem" }}>{item.description} File: </FormBoot.Label>
                                        <Upload {...props}
                                            showUploadList={true}
                                            fileList={item.fileUpload}
                                            onChange={({ fileList }) => {
                                                if (fileList.length == 1) {
                                                    var itemNew = "";
                                                    if (Boolean(fileList[0].response)) {
                                                        itemNew = fileList[0].response.url;
                                                    }

                                                    let list = this.state.requiredDocumentRows;
                                                    list[index].fileUpload = fileList;
                                                    list[index].fileNew = itemNew;
                                                    this.setState({
                                                        requiredDocumentRows: list.slice()
                                                    });
                                                }
                                            }}
                                            beforeUpload={(fileList) => {
                                                if (this.state.requiredDocumentRows[index].fileUpload.length > 0) {
                                                    this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                                                    return false;
                                                }
                                                return true;
                                            }}
                                            onRemove={({ fileList }) => {
                                                let list = this.state.requiredDocumentRows;
                                                list[index].fileUpload = [];
                                                list[index].fileNew = "";
                                                this.setState({
                                                    requiredDocumentRows: list.slice()
                                                });
                                            }
                                            }>
                                            <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                                <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                                            </AntdButton>
                                        </Upload>
                                    </Form.Item>}

                                    {(!this.state.hiddenUpdate) && (item.fileNew !== "") && (this.state.inspectionResultClientId !== null) && <Form.Item
                                        {...tailFormItemLayout}>
                                        <FormBoot.Label style={{ color: '#6c757d', marginRight: "1rem" }}><FormattedMessage id="ItemReceivingRequiredDocumentsDeliveryNoteDateNew" defaultMessage="Uploaded File: " /></FormBoot.Label>
                                        {(item.fileNew != null && item.fileNew != undefined && item.fileNew != "") &&
                                            <AntdButton style={{ backgroundColor: "green", marginRight: "2rem" }} onClick={() => this.showReport(item.fileNew)} type="primary" icon={<DownloadOutlined />} >
                                                <FormattedMessage id="ItemReceivingRequiredDocumentsManufacturerManualNewAttachment" defaultMessage="Attachment" />
                                            </AntdButton>}
                                    </Form.Item>}
                                </div>
                            ))}

                            <div hidden={this.state.hiddenSave}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="5">
                                            <Button id="ItemReceivingSaveButton"
                                                style={{ width: '100%' }} type="submit"
                                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </FormBoot.Group>
                                }
                            </div>

                            <div hidden={this.state.hiddenUpdate}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3"></Col>
                                        <Col sm="2">
                                            <Button id="ItemReceivingCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                        </Col>
                                        <Col sm="3">
                                            <Button id="ItemReceivingUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </FormBoot.Group>
                                }
                            </div>

                        </div>
                        <br />

                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumns} dataSource={this.state.ItemReceivingTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>

                    </Form>}

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >

                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    {this.state.messageTip == "Success" &&
                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                    {this.state.messageTip == "Warning" &&
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                    {this.state.messageTip == "Error" &&
                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <Row >
                            <Col sm="12">
                                {this.state.message}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                        <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id={this.state.tableTip} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                        <Button variant="danger" onClick={this.deleteItemReceivingOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.quantityModal}
                    onHide={this.showOrHideModalQuantity}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="ItemReceivingFixItem" defaultMessage="Set Quantity" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Received Quantity:
                                </FormBoot.Label>
                            </Col>
                            <Col xs={8}>
                                <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ setReceivedQuantityModal: e })}
                                    value={this.state.setReceivedQuantityModal} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Accepted Quantity:
                                </FormBoot.Label>
                            </Col>
                            <Col xs={8}>
                                <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ setAcceptedQuantityModal: e })}
                                    value={this.state.setAcceptedQuantityModal} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Remarks:
                                </FormBoot.Label>
                            </Col>
                            <Col xs={8}>
                                <Input id="Remarks" style={{ width: '100%' }} value={this.state.setRemarkModal} onChange={(e) =>
                                    this.setState({ setRemarkModal: e.target.value })} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                        <Button variant="light" onClick={this.showOrHideModalQuantity}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                        <Button variant="primary" onClick={this.setQuantity}>
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.imageUrl !== "" ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                            />
                        ) : (
                                <p>Photo has not been uploaded.</p>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHidePhotoModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalInspection}
                    onHide={this.showOrHideModalInspection}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="ItemReceivingWantInspection" defaultMessage="Do you want inspection of received items?" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <FormBoot>
                            <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="6">
                                    <Button style={{ width: '100%' }} onClick={this.yesToInspection}>
                                        <FormattedMessage id="GeneralTextYes" defaultMessage="Yes" />
                                    </Button>
                                </Col>
                                <Col sm="6">
                                    <Button style={{ width: '100%' }} onClick={this.noToInspection}>
                                        <FormattedMessage id="GeneralTextNo" defaultMessage="No" />
                                    </Button>
                                </Col>
                            </FormBoot.Group>
                        </FormBoot>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.modalInspectionDate}
                    onHide={this.showOrHideModalInspectionDate}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="ItemReceivingSetInspectionDate" defaultMessage="Set Inspection Date" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <FormBoot>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={4}>
                                    <FormBoot.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                        <FormattedMessage id="ItemReceivingWantInspection" defaultMessage="Inspection Date: " />
                                    </FormBoot.Label>
                                </Col>
                                <Col xs={8}>
                                    <DatePicker disabledDate={this.disabledDate} value={moment(this.state.inspectionDateTime, 'DD/MM/YYYY HH:mm')}
                                        showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeDateTime} />
                                </Col>
                            </Row>

                            <FormBoot.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                                <Col sm="12">
                                    <Button style={{ width: '100%' }} onClick={this.saveInspectionDate}>
                                        <FormattedMessage id="GeneralTextSave" defaultMessage="Save" />
                                    </Button>
                                </Col>
                            </FormBoot.Group>
                        </FormBoot>
                    </Modal.Body>
                </Modal>

            </div>
        ));
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ItemReceiving)