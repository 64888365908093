/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import MNDTableSettings from '../MND/MNDTableSettings';
import * as Mnd from "../../partials/content/CustomComponents";
import CustomMaterialMenuInventory from '../settings/ForComponents/CustomMaterialMenuInventory';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Col, Row, Modal, Button, Form as FormBoot} from 'react-bootstrap';
import { Input, Cascader, InputNumber, Select, Divider, DatePicker } from 'antd';
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 
import InventoryItems from "./InventoryItems";
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const expandable = { expandedRowRender: data => <InventoryItems row={data} />};
const title = () => 'Here is title';
var footer = () => 'Here is footer';

const { Option } = Select;

export class InventoryLog extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            inventoryLogList: [],
            type: '',
            abbreviation: '',
            departmentSelectItems:[],
            departmentId:0,
            disciplineSelectItems:[],
            disciplineId:0,
            selectedDisciplineIds:[],
            relatedDisciplines:[],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null,
            departmentCascader: [],
            selectedInventory:[],
            InventoryUnits:[],
            date: null,
            
        };

        this.actionTemplate = this.actionTemplate.bind(this); 

    }

    formRef = React.createRef();

    componentDidMount = async () => {
        var today = new Date();
        var todayDate;
        var HH = String(today.getHours()).padStart(2, '0');
        var minute = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
    
        todayDate = dd + '/' + mm + '/' + yyyy;

        this.setState({
            date: todayDate
        });

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.restartTable();
        this.getTableDynamicInputsProperties();
        this.getDepartment();
    }

    getTableDynamicInputsProperties = async () => {
        this.inventoryLog = JSON.parse(localStorage.getItem('inventoryLog'));
        if (this.inventoryLog != null && this.inventoryLog != undefined && this.inventoryLog != "") {
    
            await this.setState({
                bordered: this.inventoryLog.bordered,
                title: this.inventoryLog.titleDrm == true ? title : undefined,
                titleDrm: this.inventoryLog.titleDrm,
                showHeader: this.inventoryLog.showHeader,
                footer: this.inventoryLog.footerDrm == true ? footer : undefined,
                footerDrm: this.inventoryLog.footerDrm,
                expandable: this.inventoryLog.expandableDrm == true ? expandable : {},
                expandableDrm: this.inventoryLog.expandableDrm,
                rowSelection: this.inventoryLog.rowSelection,
                ellipsis: this.inventoryLog.ellipsis,
                tableLayout: this.inventoryLog.tableLayout,
                size: this.inventoryLog.size,
                top: this.inventoryLog.top,
                bottom: this.inventoryLog.bottom,
                editBgColor: this.inventoryLog.editBgColor,
                deleteBgColor: this.inventoryLog.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.inventoryLog.yScroll,
                xScroll: this.inventoryLog.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.inventoryLogList.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.inventoryLogList.length, showQuickJumper: true,
                    position: [this.inventoryLog.top, this.inventoryLog.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: expandable,
                expandableDrm: true,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
          }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/InventoryLog/getallByCompany");
        console.log("RRR", response.data);
        if (response.data.length !== 0) {
            const list =  response.data;
            list.forEach(element => {
                element.key=element.id
            });
            this.setState({
                inventoryLogList: list, 
            });
        }
        if (response.data.length === 0) {
            this.setState({
                inventoryLogList: [],
            });
        }
    }
  
    getDepartment = async () => {
        const responseTree = await handleRequest("GET", "/Department/getTreeModel");

        if (responseTree.data.length !== 0) {
            this.setState({
                departmentCascader: responseTree.data.cascader
            });
        }else{
            this.setState({
                departmentCascader: []
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }; 

    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InventoryLog" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "InventoryLog"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InventoryLog");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHidePhotoModal = () => {
        this.setState({
          modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
          modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };
    
    showOrHideReleaseModal = () => {
        this.setState({
            modalRelease: !this.state.modalRelease,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };

    resetReleaseModalInputs = () => {
        this.setState({
            values:[],
            quantity:"",
            unit:"",
            date:null,
        });
    }

    showReport = async (fileRealUrl) => {
        try { 
          if (fileRealUrl.endsWith('.pdf')) {
            this.setState({
              pdfFile: fileRealUrl,
              modalPdfViewer: true,
            });
          } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
            || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
            this.setState({
              imageUrl: fileRealUrl,
              modalPhotoShow: true,
            })
          } else {
            window.location.href = fileRealUrl;
          }
        } catch (error) { 
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found."/>);
        }
    };

    actionTemplate(rowData, column) {
        return <React.Fragment>
        <CustomMaterialMenuInventory row={rowData} onInventoryRow={this.releaseClick.bind(this)}/>
        </React.Fragment>;
    };

    releaseClick = (row) => {
        this.setState({
          modalRelease: true,
          selectedInventory: row,
        });
        this.resetReleaseModalInputs();
    }; 

    saveRelease = async () => {
        if(this.state.values.length != 0 && this.state.selectedInventory.id>0 
            && this.state.quantity!=null && this.state.quantity!=undefined && this.state.quantity!="" && this.state.quantity>0 
            && this.state.unit!=null && this.state.unit!=undefined && this.state.unit!="" 
            && this.state.date!=null && this.state.date!=undefined && this.state.date!=""){ 
            const newInventoryItems = {
                inventoryLogId:this.state.selectedInventory.id,
                action: "Released",
                quantity: this.state.quantity,
                fromTo: this.state.values[this.state.values.length - 1],
                unit:this.state.unit,
                date: moment(this.state.date, 'DD/MM/YYYY'),
            }
            var response = await handleRequest("POST", "/InventoryItems/add", newInventoryItems);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.restartTable();
                    this.showSuccess();
                    this.showOrHideReleaseModal();
                }
                else {
                    this.showError();
                } 
            }
        }else{
            this.showWarning();
        }
    };

    onDepartmentCascaderChange = (value) => {
        this.setState({
            values: value
        });
    }

    onChangeDate = (value, dateString) => {
        this.setState({
          date: dateString,
        });
    }

    disabledDate(current) {
        return moment().endOf('day') < current ;
    }

    render() {
        const columns = [
            {
              dataIndex: "index",
              key: "index",
              title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
              dataIndex: "materialIdentification",
              key: "typeId",
              title: <FormattedMessage id="InventoryType" defaultMessage="Type" />,
              render: (data) =>   
              {  
                if(data!=null && data!=undefined){
                    return data.materialTypeName + "/ " + data.materialName;
                } 
                return "";
              }
            },
            {
              dataIndex: "groupId",
              key: "groupId",
              title: <FormattedMessage id="InventoryGroup" defaultMessage="Group" />,
            },
            {
              dataIndex: "code",
              key: "code",
              title: <FormattedMessage id="InventoryCode" defaultMessage="Code" />,
            },
            {
              dataIndex: "description",
              key: "description",
              title: <FormattedMessage id="InventoryDescription" defaultMessage="Description" />,
              },
            {
              dataIndex: "actualQuantity",
              key: "actualQuantity",
              title: <FormattedMessage id="InventoryActualQuantity" defaultMessage="Actual Quantity" />,
            },
            {
              dataIndex: "unit",
              key: "unit",
              title: <FormattedMessage id="InventoryUnit" defaultMessage="Unit" />,
            },
            {
              dataIndex: "received",
              key: "received",
              title: <FormattedMessage id="InventoryReceived" defaultMessage="Received" />,
            },
            {
              dataIndex: "released",
              key: "released",
              title: <FormattedMessage id="InventoryReleased" defaultMessage="Released" />,
            },
            {
              dataIndex: "stockCodeName",
              key: "stockCodeName",
              title: <FormattedMessage id="InventoryStockCodeName" defaultMessage="Stock Code-Name" />,
            },
            {
              dataIndex: "remarks",
              key: "remarks",
              title: <FormattedMessage id="InventoryRemarks" defaultMessage="Remarks" />,
            },
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },
        ];
        
        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
          tableColumns[0].fixed = true;
          tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
          ) => {
            return defaultLayout(
              isSidebarOpened,
              container,
              main,
              toolbar(renderToolbar),
              sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                  </div>
                </div>
              </div>
            );
        };

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        return <div>
                <MenuProvider id="menu_id">
                    <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>
                        <br/>
                        <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>

                        {this.state.inventoryLogList.length > 0 && 
                            <Mnd.MNDTable
                            state={this.state}
                            columns={tableColumns} dataSource={this.state.inventoryLogList}
                            pagination={this.state.paginationDisabled}
                            expandable={this.state.expandable}
                            footer={this.state.footer}
                            title={this.state.title}
                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}/> 
                        }
               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>
                }

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                        <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                        
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                        </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Row > 
                        <Col sm="12">
                        {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id="inventoryLog" />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.imageUrl !== "" ? (
                        <img
                        src={this.state.imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                        />
                    ) : (
                        <p>Photo has not been uploaded.</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHidePhotoModal}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                        <div style={{ height: "900px" }}>
                        <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                        </div>
                    </Worker> 

                    </Modal.Body>
                    <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.showOrHideModalPdfViewer}
                    >
                          <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalRelease}
                    onHide={this.showOrHideReleaseModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralReleaseHeader" defaultMessage="Release" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="3">
                            <FormattedMessage id="InventorCodeDescription" defaultMessage="Code-Description" />
                        </FormBoot.Label>
                        <Col sm="5">
                            <Input style={{ flex: 'auto' }} readOnly={true} value={this.state.selectedInventory.code + "-" + this.state.selectedInventory.description}/>   
                        </Col>
                        <Col sm="4"></Col>
                    </FormBoot.Group>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="3">
                            <FormattedMessage id="InventorTo" defaultMessage="To" />
                        </FormBoot.Label>
                        <Col sm="5">
                            <Cascader style={{ width: '100%' }} value={this.state.values} 
                            defaultValue={this.state.values} options={this.state.departmentCascader} 
                            onChange={this.onDepartmentCascaderChange} changeOnSelect />  
                        </Col>
                        <Col sm="4"></Col>
                    </FormBoot.Group>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="3">
                            <FormattedMessage id="InventorQuantity" defaultMessage="Quantity" />
                        </FormBoot.Label>
                        <Col sm="5">
                            <InputNumber min={0} max={this.state.selectedInventory.actualQuantity} style={{ width: '100%' }}
                              onChange={(e) => this.setState({ quantity: e })} value={this.state.quantity} placeholder="Quantity" />
                        </Col>
                        <Col sm="4"></Col>
                    </FormBoot.Group>   
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="3">
                            <FormattedMessage id="InventorUnit" defaultMessage="Unit" />
                        </FormBoot.Label>
                        <Col sm="5">
                            <Input style={{ flex: 'auto' }} value={this.state.unit}
                                onChange={(event) => { this.setState({ unit: event.target.value }) }} />
                        </Col>
                        <Col sm="4"></Col>
                    </FormBoot.Group>
                     <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="3">
                            <FormattedMessage id="InventorDate" defaultMessage="Date" />
                        </FormBoot.Label>
                        <Col sm="5">
                         <DatePicker disabledDate={this.disabledDate} value={(this.state.date!=null && this.state.date!=undefined && this.state.date!="") ? moment(this.state.date, 'DD/MM/YYYY') : null}
                            disabled={this.state.datePickerDisable} format="DD/MM/YYYY" onChange={this.onChangeDate} />
                        </Col>
                        <Col sm="4"></Col>
                    </FormBoot.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHideReleaseModal}>
                    <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </Button>
                    <Button variant="success" onClick={this.saveRelease}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Modal.Footer>
                </Modal>

            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLog)