/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, InputNumber, DatePicker, Radio, Button as AntButton, Switch, Checkbox, Divider } from 'antd';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class ContractSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      contractSettingsTable: [],
      departments: [],
      disciplines: [],
      designations: [],
      hiddenDataGirisContract: true,
      hiddenDataGirisBasicSalary: true,
      hiddenDataGirisInsurance: true,
      hiddenDataGirisAllowances: true,
      hiddenDataGirisFamilyStatus: true,
      ContractSettingsEditable: [],
      ContractSettingsEditableKayit: 0,
      ContractSettingsEndOfServices: [],
      ContractSettingsEndOfServicesKayit: 0,
      contractSettingsId: 0,
      hiddenDirectPayment: true,
      hiddenRentofHouse: true,
      hiddenCompanyAccomadation: true,
      hiddenHomeAllowance: true,
      hiddenFamilyStatus: true,
      hiddenTransportation: true,
      hiddenFood: true,
      hiddenCompanyVehicle: true,
      hiddenVehicleClass: true,
      hiddenTransportationDirectPayment: true,
      hiddenCompanyFood: true,
      hiddenFoodClass: true,
      hiddenDataGirisAnnualVacation: true,
      hiddenDataGirisFlights: true,
      hiddenDataGirisOvertime: true,
      hiddenDataGirisBenefits: true,

      hiddenFood: true,
      hiddenFoodDirectPayment: true,
      spouse: true,
      sameAsEmployeeStatus: true,
      hiddenChildreenEduaction: true,
      familyMembersFlightStatus: true,
      AnnaualVacationStartPeriods: [],
      MaxContractSettings: [],
      percent: 0,
      disabledWorkingYearsMinVoluntary: false,
      disabledWorkingYearsMinInvoluntary: false,
    };

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ContractSettings" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "ContractSettings",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ContractSettings");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
  }

  getTableProperties = async () => {
    this.contractSettings = JSON.parse(localStorage.getItem('contractSettings'));
    if (this.contractSettings != null && this.contractSettings != undefined && this.contractSettings != "") {

      await this.setState({
        bordered: this.contractSettings.bordered,
        title: this.contractSettings.titleDrm == true ? title : undefined,
        titleDrm: this.contractSettings.titleDrm,
        showHeader: this.contractSettings.showHeader,
        footer: this.contractSettings.footerDrm == true ? footer : undefined,
        footerDrm: this.contractSettings.footerDrm,
        expandable: this.contractSettings.expandableDrm == true ? expandable : {},
        expandableDrm: this.contractSettings.expandableDrm,
        rowSelection: this.contractSettings.rowSelection,
        ellipsis: this.contractSettings.ellipsis,
        tableLayout: this.contractSettings.tableLayout,
        size: this.contractSettings.size,
        top: this.contractSettings.top,
        bottom: this.contractSettings.bottom,
        editBgColor: this.contractSettings.editBgColor,
        deleteBgColor: this.contractSettings.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.contractSettings.yScroll,
        xScroll: this.contractSettings.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.contractSettingsTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.contractSettingsTable.length, showQuickJumper: true,
          position: [this.contractSettings.top, this.contractSettings.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  };

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedContractSettings: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalPercent = () => {
    this.setState({
      percentModal: !this.state.percentModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalPercentGiris = () => {
    this.setState({
      percentModalGiris: !this.state.percentModalGiris,
      percentModal: false,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalPercentGirisOK = async () => {
    if (this.state.percent != null && this.state.percent != null && this.state.percent != "" && this.state.percent > 0 &&
      this.state.MaxContractSettings != null && this.state.MaxContractSettings != undefined && this.state.MaxContractSettings.length > 0) {
      console.log("YYY", this.state.MaxContractSettings);
      console.log(this.state.percent);

      var hiddenDirectPayment = true;
      var hiddenRentofHouse = true;
      var hiddenCompanyAccomadation = true;
      if (this.state.MaxContractSettings[0].homeAllowanceType == "Direct payment") {
        hiddenDirectPayment = false;
      } else if (this.state.MaxContractSettings[0].homeAllowanceType == "Rent of house") {
        hiddenRentofHouse = false;
      } else if (this.state.MaxContractSettings[0].homeAllowanceType == "Company Accomadation") {
        hiddenCompanyAccomadation = false;
      }

      var hiddenTransportationDirectPayment = true;
      var hiddenVehicleClass = true;
      if (this.state.MaxContractSettings[0].transportationType == "Direct payment Transportation") {
        hiddenTransportationDirectPayment = false;
      } else if (this.state.MaxContractSettings[0].transportationType == "Company Vehicle") {
        hiddenVehicleClass = false;
      }

      var hiddenFoodDirectPayment = true;
      var hiddenFoodClass = true;
      if (this.state.MaxContractSettings[0].foodType == "Direct payment Food") {
        hiddenFoodDirectPayment = false;
      } else if (this.state.MaxContractSettings[0].foodType == "Company Food") {
        hiddenFoodClass = false;
      }

      this.setState({
        // contractDuration: this.state.MaxContractSettings[0].contractDuration, 
        // autoRenewal: this.state.MaxContractSettings[0].autoRenewal, 
        // renewalReminder: this.state.MaxContractSettings[0].renewalReminder, 
        // noticePeriodNotRenew: this.state.MaxContractSettings[0].noticePeriodNotRenew, 
        // dailyWorkingHours: this.state.MaxContractSettings[0].dailyWorkingHours, 
        // dailyOvertimeHours: this.state.MaxContractSettings[0].dailyOvertimeHours, 
        // weeklyWorkingDays: this.state.MaxContractSettings[0].weeklyWorkingDays, 
        // weeklyWorkingDaysKeys: this.state.MaxContractSettings[0].weeklyWorkingDaysKeys, 
        // monthlyWorkingHours: this.state.MaxContractSettings[0].monthlyWorkingHours, 
        // salaryMatrix: this.state.MaxContractSettings[0].salaryMatrix,
        salaryRangeType: this.state.MaxContractSettings[0].salaryRangeType,
        salaryRangeMin: this.state.MaxContractSettings[0].salaryRangeMin + ((this.state.MaxContractSettings[0].salaryRangeMin * this.state.percent) / 100),
        salaryRangeMax: this.state.MaxContractSettings[0].salaryRangeMax + ((this.state.MaxContractSettings[0].salaryRangeMax * this.state.percent) / 100),
        // insuranceClass: this.state.MaxContractSettings[0].insuranceClass,
        homeAllowance: this.state.MaxContractSettings[0].homeAllowance,
        homeAllowanceType: this.state.MaxContractSettings[0].homeAllowanceType,
        hiddenHomeAllowance: !this.state.MaxContractSettings[0].homeAllowance,
        familyStatus: this.state.MaxContractSettings[0].familyStatus,
        hiddenFamilyStatus: !this.state.MaxContractSettings[0].familyStatus,

        payment: this.state.MaxContractSettings[0].payment + ((this.state.MaxContractSettings[0].payment * this.state.percent) / 100),
        paymentType: this.state.MaxContractSettings[0].paymentType,
        paymentToHouseholderType: this.state.MaxContractSettings[0].paymentToHouseholderType,
        paymentToHouseholder: this.state.MaxContractSettings[0].paymentToHouseholder + ((this.state.MaxContractSettings[0].paymentToHouseholder * this.state.percent) / 100),
        electricity: this.state.MaxContractSettings[0].electricity + ((this.state.MaxContractSettings[0].electricity * this.state.percent) / 100),
        electricityType: this.state.MaxContractSettings[0].electricityType,
        water: this.state.MaxContractSettings[0].water + ((this.state.MaxContractSettings[0].water * this.state.percent) / 100),
        waterType: this.state.MaxContractSettings[0].waterType,
        furniture: this.state.MaxContractSettings[0].furniture + ((this.state.MaxContractSettings[0].furniture * this.state.percent) / 100),

        furnitureType: this.state.MaxContractSettings[0].furnitureType,
        companyAccomadation: this.state.MaxContractSettings[0].companyAccomadation,
        companyAccomadationType: this.state.MaxContractSettings[0].companyAccomadationType,
        transportation: this.state.MaxContractSettings[0].transportation,
        transportationType: this.state.MaxContractSettings[0].transportationType,
        hiddenTransportation: !this.state.MaxContractSettings[0].transportation,
        food: this.state.MaxContractSettings[0].food,
        foodType: this.state.MaxContractSettings[0].foodType,
        hiddenFood: !this.state.MaxContractSettings[0].food,
        paymentTransportationType: this.state.MaxContractSettings[0].paymentTransportationType,
        paymentTransportation: this.state.MaxContractSettings[0].paymentTransportation,
        vehicleClass: this.state.MaxContractSettings[0].vehicleClass,
        paymentFoodType: this.state.MaxContractSettings[0].paymentFoodType,
        paymentFood: this.state.MaxContractSettings[0].paymentFood + ((this.state.MaxContractSettings[0].paymentFood * this.state.percent) / 100),

        foodClass: this.state.MaxContractSettings[0].foodClass,
        // flightFees:this.state.MaxContractSettings[0].flightFees,
        // spouse: this.state.MaxContractSettings[0].spouse, 
        // childrenFamilyStatus: this.state.MaxContractSettings[0].childrenFamilyStatus, 
        // ofChildren: this.state.MaxContractSettings[0].ofChildren, 
        // iqama: this.state.MaxContractSettings[0].iqama, 
        // sameAsEmployeeStatus: this.state.MaxContractSettings[0].sameAsEmployeeStatus, 
        // insuranceClassFamilyStatus: this.state.MaxContractSettings[0].insuranceClassFamilyStatus, 
        childreenEduaction: this.state.MaxContractSettings[0].childreenEduaction,
        hiddenChildreenEduaction: !this.state.MaxContractSettings[0].childreenEduaction,
        elementarySchoolType: this.state.MaxContractSettings[0].elementarySchoolType,
        elementarySchool: this.state.MaxContractSettings[0].elementarySchool + ((this.state.MaxContractSettings[0].elementarySchool * this.state.percent) / 100),

        highSchoolType: this.state.MaxContractSettings[0].highSchoolType,
        highSchool: this.state.MaxContractSettings[0].highSchool + ((this.state.MaxContractSettings[0].highSchool * this.state.percent) / 100),

        collegeType: this.state.MaxContractSettings[0].collegeType,
        college: this.state.MaxContractSettings[0].college + ((this.state.MaxContractSettings[0].college * this.state.percent) / 100),

        universityType: this.state.MaxContractSettings[0].universityType,
        university: this.state.MaxContractSettings[0].university + ((this.state.MaxContractSettings[0].university * this.state.percent) / 100),

        // annualVacationPeriod: this.state.MaxContractSettings[0].annualVacationPeriod, 
        // annualVacationPeriodType: this.state.MaxContractSettings[0].annualVacationPeriodType, 
        // annualVacationDays: this.state.MaxContractSettings[0].annualVacationDays, 
        // annaualVacationStartPeriod: this.state.MaxContractSettings[0].annaualVacationStartPeriod, 
        // paymentForNotUsedAnnualVacationDays: this.state.MaxContractSettings[0].paymentForNotUsedAnnualVacationDays, 
        // leaveDaysCompensationsFromAnnualVacationDays: this.state.MaxContractSettings[0].leaveDaysCompensationsFromAnnualVacationDays, 

        // numberOfFamilyMembers: this.state.MaxContractSettings[0].numberOfFamilyMembers, 
        // numberFamilyMembersChildren: this.state.MaxContractSettings[0].numberFamilyMembersChildren, 
        // numberOfFamilyMembersChildren: this.state.MaxContractSettings[0].numberOfFamilyMembersChildren, 
        // flightStatus: this.state.MaxContractSettings[0].flightStatus, 
        // flightRoute: this.state.MaxContractSettings[0].flightRoute, 
        // familyMembersFlightStatus: this.state.MaxContractSettings[0].familyMembersFlightStatus, 
        // flightStatusSameAsEmployee: this.state.MaxContractSettings[0].flightStatusSameAsEmployee, 
        // flightRouteSameAsEmployee: this.state.MaxContractSettings[0].flightRouteSameAsEmployee, 
        // workingYearsMin: this.state.MaxContractSettings[0].workingYearsMin, 
        // workingYearsMax: this.state.MaxContractSettings[0].workingYearsMax, 
        // workingYearsMaxDahil: this.state.MaxContractSettings[0].workingYearsMaxDahil, 
        // percentOfBasicSalaryForEachYear: this.state.MaxContractSettings[0].percentOfBasicSalaryForEachYear, 
        // workingYearsMinEndOfService: this.state.MaxContractSettings[0].workingYearsMinEndOfService, 
        // workingYearsMaxEndOfService: this.state.MaxContractSettings[0].workingYearsMaxEndOfService, 
        // workingYearsMaxEndOfServiceDahil: this.state.MaxContractSettings[0].workingYearsMaxEndOfServiceDahil, 
        // percentOfBasicSalaryForEachYearEndOfService: this.state.MaxContractSettings[0].percentOfBasicSalaryForEachYearEndOfService, 

        hiddenDataGirisContract: true,
        hiddenDataGirisBasicSalary: true,
        hiddenDataGirisInsurance: true,
        hiddenDataGirisAllowances: true,
        hiddenDataGirisFamilyStatus: true,
        hiddenDataGirisAnnualVacation: true,
        hiddenDataGirisFlights: true,
        hiddenDataGirisOvertime: true,
        hiddenDataGirisBenefits: true,
        hiddenDirectPayment: hiddenDirectPayment,
        hiddenRentofHouse: hiddenRentofHouse,
        hiddenCompanyAccomadation: hiddenCompanyAccomadation,
        hiddenTransportationDirectPayment: hiddenTransportationDirectPayment,
        hiddenVehicleClass: hiddenVehicleClass,
        hiddenFoodDirectPayment: hiddenFoodDirectPayment,
        hiddenFoodClass: hiddenFoodClass,

        percent: 0,
        percentModalGiris: false,
      });

    } else {
      this.showWarning();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveContractSettings = async () => {
    const addContractSettings = {
      periodBsl: moment(this.state.periodBsl, 'YYYY-MM-DD'),
      periodBslAck: this.state.periodBsl,
      periodBts: moment(this.state.periodBts, 'YYYY-MM-DD'),
      periodBtsAck: this.state.periodBts,
      contractDuration: this.state.contractDuration,
      autoRenewal: this.state.autoRenewal,
      renewalReminder: this.state.renewalReminder,
      noticePeriodNotRenew: this.state.noticePeriodNotRenew,
      dailyWorkingHours: this.state.dailyWorkingHours,
      dailyOvertimeHours: this.state.dailyOvertimeHours,
      weeklyWorkingDaysKeys: this.state.weeklyWorkingDaysKeys,
      monthlyWorkingHours: this.state.monthlyWorkingHours,
      salaryMatrix: this.state.salaryMatrix,
      salaryRangeType: this.state.salaryRangeType,
      salaryRangeMin: this.state.salaryRangeMin,
      salaryRangeMax: this.state.salaryRangeMax,
      insuranceClass: this.state.insuranceClass,
      homeAllowance: this.state.homeAllowance,
      homeAllowanceType: this.state.homeAllowanceType,
      paymentType: this.state.paymentType,
      payment: this.state.payment,
      paymentToHouseholderType: this.state.paymentToHouseholderType,
      paymentToHouseholder: this.state.paymentToHouseholder,
      electricityType: this.state.electricityType,
      electricity: this.state.electricity,
      waterType: this.state.waterType,
      water: this.state.water,
      furnitureType: this.state.furnitureType,
      furniture: this.state.furniture,
      companyAccomadationType: this.state.companyAccomadationType,
      transportation: this.state.transportation,
      transportationType: this.state.transportationType,
      paymentTransportationType: this.state.paymentTransportationType,
      paymentTransportation: this.state.paymentTransportation,
      vehicleClass: this.state.vehicleClass,
      food: this.state.food,
      foodType: this.state.foodType,
      paymentFoodType: this.state.paymentFoodType,
      paymentFood: this.state.paymentFood,
      foodClass: this.state.foodClass,
      familyStatus: this.state.familyStatus,
      spouse: this.state.spouse,
      childrenFamilyStatus: this.state.childrenFamilyStatus,
      ofChildren: this.state.ofChildren,
      iqama: this.state.iqama,
      sameAsEmployeeStatus: this.state.sameAsEmployeeStatus,
      insuranceClassFamilyStatus: this.state.insuranceClassFamilyStatus,
      childreenEduaction: this.state.childreenEduaction,
      elementarySchoolType: this.state.elementarySchoolType,
      elementarySchool: this.state.elementarySchool,
      highSchoolType: this.state.highSchoolType,
      highSchool: this.state.highSchool,
      collegeType: this.state.collegeType,
      college: this.state.college,
      universityType: this.state.universityType,
      university: this.state.university,
      annualVacationPeriod: this.state.annualVacationPeriod,
      annualVacationPeriodType: this.state.annualVacationPeriodType,
      annualVacationDays: this.state.annualVacationDays,
      annaualVacationStartPeriod: this.state.annaualVacationStartPeriod,
      paymentForNotUsedAnnualVacationDays: this.state.paymentForNotUsedAnnualVacationDays,
      leaveDaysCompensationsFromAnnualVacationDays: this.state.leaveDaysCompensationsFromAnnualVacationDays,
      flightFees: this.state.flightFees,
      numberOfFamilyMembers: this.state.numberOfFamilyMembers,
      numberFamilyMembersChildren: this.state.numberFamilyMembersChildren,
      numberOfFamilyMembersChildren: this.state.numberOfFamilyMembersChildren,
      flightStatus: this.state.flightStatus,
      flightRoute: this.state.flightRoute,
      familyMembersFlightStatus: this.state.familyMembersFlightStatus,
      flightStatusSameAsEmployee: this.state.flightStatusSameAsEmployee,
      flightRouteSameAsEmployee: this.state.flightRouteSameAsEmployee,
      workingYearsMin: this.state.workingYearsMin,
      workingYearsMax: this.state.workingYearsMax,
      workingYearsMaxDahil: this.state.workingYearsMaxDahil,
      percentOfBasicSalaryForEachYear: this.state.percentOfBasicSalaryForEachYear,
      workingYearsMinEndOfService: this.state.workingYearsMinEndOfService,
      workingYearsMaxEndOfService: this.state.workingYearsMaxEndOfService,
      workingYearsMaxEndOfServiceDahil: this.state.workingYearsMaxEndOfServiceDahil,
      percentOfBasicSalaryForEachYearEndOfService: this.state.percentOfBasicSalaryForEachYearEndOfService,

      department: this.state.department,
      discipline: this.state.discipline,
      designation: this.state.designation,
      contractSettingsEditables: this.state.ContractSettingsEditable,
      contractSettingsEndOfServices: this.state.ContractSettingsEndOfServices,

      overtimeAllowed:this.state.overtimeAllowed,
      maximumOvertimeHoursDay: this.state.maximumOvertimeHoursDay,
      maximumOvertimeHoursWeek: this.state.maximumOvertimeHoursWeek,
      maximumOvertimeHoursMonth: this.state.maximumOvertimeHoursMonth,
      maximumOvertimeHoursYear: this.state.maximumOvertimeHoursYear,
      holidayWorksCheck: this.state.holidayWorksCheck,
      eidWorksCheck: this.state.eidWorksCheck,
      overtimeRate: this.state.overtimeRate,
      normalDays: this.state.normalDays,
      holidays: this.state.holidays,
      holidaysOvertimeRate: this.state.holidaysOvertimeRate,
      eidDays:this.state.eidDays,
      eidsOvertimeRate: this.state.eidsOvertimeRate,
    }
    console.log("ADD", addContractSettings);
    var response = await handleRequest("POST", "/ContractSettings/add", addContractSettings);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  updateContractSettings = async () => {
    const updatedContractSettings = {
      id: this.state.selectedContractSettings.id,
      periodBsl: moment(this.state.periodBsl, 'YYYY-MM-DD'),
      periodBslAck: this.state.periodBsl,
      periodBts: moment(this.state.periodBts, 'YYYY-MM-DD'),
      periodBtsAck: this.state.periodBts,
      contractDuration: this.state.contractDuration,
      autoRenewal: this.state.autoRenewal,
      renewalReminder: this.state.renewalReminder,
      noticePeriodNotRenew: this.state.noticePeriodNotRenew,
      dailyWorkingHours: this.state.dailyWorkingHours,
      dailyOvertimeHours: this.state.dailyOvertimeHours,
      weeklyWorkingDaysKeys: this.state.weeklyWorkingDaysKeys,
      monthlyWorkingHours: this.state.monthlyWorkingHours,
      salaryMatrix: this.state.salaryMatrix,
      salaryRangeType: this.state.salaryRangeType,
      salaryRangeMin: this.state.salaryRangeMin,
      salaryRangeMax: this.state.salaryRangeMax,
      insuranceClass: this.state.insuranceClass,
      homeAllowance: this.state.homeAllowance,
      homeAllowanceType: this.state.homeAllowanceType,
      paymentType: this.state.paymentType,
      payment: this.state.payment,
      paymentToHouseholderType: this.state.paymentToHouseholderType,
      paymentToHouseholder: this.state.paymentToHouseholder,
      electricityType: this.state.electricityType,
      electricity: this.state.electricity,
      waterType: this.state.waterType,
      water: this.state.water,
      furnitureType: this.state.furnitureType,
      furniture: this.state.furniture,
      companyAccomadationType: this.state.companyAccomadationType,
      transportation: this.state.transportation,
      transportationType: this.state.transportationType,
      paymentTransportationType: this.state.paymentTransportationType,
      paymentTransportation: this.state.paymentTransportation,
      vehicleClass: this.state.vehicleClass,
      food: this.state.food,
      foodType: this.state.foodType,
      paymentFoodType: this.state.paymentFoodType,
      paymentFood: this.state.paymentFood,
      foodClass: this.state.foodClass,
      familyStatus: this.state.familyStatus,
      spouse: this.state.spouse,
      childrenFamilyStatus: this.state.childrenFamilyStatus,
      ofChildren: this.state.ofChildren,
      iqama: this.state.iqama,
      sameAsEmployeeStatus: this.state.sameAsEmployeeStatus,
      insuranceClassFamilyStatus: this.state.insuranceClassFamilyStatus,
      childreenEduaction: this.state.childreenEduaction,
      elementarySchoolType: this.state.elementarySchoolType,
      elementarySchool: this.state.elementarySchool,
      highSchoolType: this.state.highSchoolType,
      highSchool: this.state.highSchool,
      collegeType: this.state.collegeType,
      college: this.state.college,
      universityType: this.state.universityType,
      university: this.state.university,
      annualVacationPeriod: this.state.annualVacationPeriod,
      annualVacationPeriodType: this.state.annualVacationPeriodType,
      annualVacationDays: this.state.annualVacationDays,
      annaualVacationStartPeriod: this.state.annaualVacationStartPeriod,
      paymentForNotUsedAnnualVacationDays: this.state.paymentForNotUsedAnnualVacationDays,
      leaveDaysCompensationsFromAnnualVacationDays: this.state.leaveDaysCompensationsFromAnnualVacationDays,
      flightFees: this.state.flightFees,
      numberOfFamilyMembers: this.state.numberOfFamilyMembers,
      numberFamilyMembersChildren: this.state.numberFamilyMembersChildren,
      numberOfFamilyMembersChildren: this.state.numberOfFamilyMembersChildren,
      flightStatus: this.state.flightStatus,
      flightRoute: this.state.flightRoute,
      familyMembersFlightStatus: this.state.familyMembersFlightStatus,
      flightStatusSameAsEmployee: this.state.flightStatusSameAsEmployee,
      flightRouteSameAsEmployee: this.state.flightRouteSameAsEmployee,
      workingYearsMin: this.state.workingYearsMin,
      workingYearsMax: this.state.workingYearsMax,
      workingYearsMaxDahil: this.state.workingYearsMaxDahil,
      percentOfBasicSalaryForEachYear: this.state.percentOfBasicSalaryForEachYear,
      workingYearsMinEndOfService: this.state.workingYearsMinEndOfService,
      workingYearsMaxEndOfService: this.state.workingYearsMaxEndOfService,
      workingYearsMaxEndOfServiceDahil: this.state.workingYearsMaxEndOfServiceDahil,
      percentOfBasicSalaryForEachYearEndOfService: this.state.percentOfBasicSalaryForEachYearEndOfService,

      department: this.state.department,
      discipline: this.state.discipline,
      designation: this.state.designation,
      contractSettingsEditables: this.state.ContractSettingsEditable,
      contractSettingsEndOfServices: this.state.ContractSettingsEndOfServices,

      overtimeAllowed:this.state.overtimeAllowed,
      maximumOvertimeHoursDay: this.state.maximumOvertimeHoursDay,
      maximumOvertimeHoursWeek: this.state.maximumOvertimeHoursWeek,
      maximumOvertimeHoursMonth: this.state.maximumOvertimeHoursMonth,
      maximumOvertimeHoursYear: this.state.maximumOvertimeHoursYear,
      holidayWorksCheck: this.state.holidayWorksCheck,
      eidWorksCheck: this.state.eidWorksCheck,
      overtimeRate: this.state.overtimeRate,
      normalDays: this.state.normalDays,
      holidays: this.state.holidays,
      holidaysOvertimeRate: this.state.holidaysOvertimeRate,
      eidDays:this.state.eidDays,
      eidsOvertimeRate: this.state.eidsOvertimeRate,
    }
    console.log("UPD", updatedContractSettings);
    var response = await handleRequest("POST", "/ContractSettings/update", updatedContractSettings);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/ContractSettings/getallByCompany");
    console.log("AAA", response.data);
    if (response.data.length === 0) {
      this.setState({
        contractSettingsTable: [],
        MaxContractSettings: [],
      });
    }

    if (response.data.length !== 0) {
      var MaxContractSettings = [];
      MaxContractSettings.push(response.data[response.data.length - 1]);
      this.setState({
        contractSettingsTable: response.data,
        MaxContractSettings: MaxContractSettings,
      });
    }
    this.getDepartment();
    this.getDiscipline();
    this.getDesignation();
    this.getContractSettingsEditable();
    this.getCodesAnnaualVacationStartPeriod();
  }

  getContractSettingsEditable = async () => {
    const response = await handleRequest("GET", "/ContractSettings/getContractSettingsEditable");
    console.log("BBB", response.data);
    if (response.data.length === 0) {
      this.setState({
        ContractSettingsEditable: [],
        ContractSettingsEditableKayit: 0,
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        ContractSettingsEditable: response.data,
        ContractSettingsEditableKayit: response.data[response.data.length - 1].id
      });
    }
  }

  getDepartment = async () => {
    const response = await handleRequest("GET", "/Department/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        departments: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        departments: response.data,
      });
    }
  }

  getDiscipline = async () => {
    const response = await handleRequest("GET", "/Discipline/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        disciplines: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        disciplines: response.data,
      });
    }
  }

  getDesignation = async () => {
    const response = await handleRequest("GET", "/Designation/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        designations: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        designations: response.data,
      });
    }
  }

  deleteContractSettingsOK = async () => {
    const deleteContractSettings = {
      id: this.state.selectedContractSettings.id
    }
    var response = await handleRequest("POST", "/ContractSettings/delete", deleteContractSettings);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    console.log("ROW", row);
    var period = [];
    if (row.periodBsl != null && row.periodBsl != undefined && row.periodBsl != "" && row.periodBts != null && row.periodBts != undefined && row.periodBts != "") {
      period.push(moment(row.periodBsl, 'YYYY-MM-DD'), moment(row.periodBts, 'YYYY-MM-DD'));
    }
    this.formRef.current.setFieldsValue({
      Period: period,
      Department: row.department,
      Discipline: row.discipline,
      Designation: row.designation,
    });

    var hiddenDirectPayment = true;
    var hiddenRentofHouse = true;
    var hiddenCompanyAccomadation = true;
    if (row.homeAllowanceType == "Direct payment") {
      hiddenDirectPayment = false;
    } else if (row.homeAllowanceType == "Rent of house") {
      hiddenRentofHouse = false;
    } else if (row.homeAllowanceType == "Company Accomadation") {
      hiddenCompanyAccomadation = false;
    }

    var hiddenTransportationDirectPayment = true;
    var hiddenVehicleClass = true;
    if (row.transportationType == "Direct payment Transportation") {
      hiddenTransportationDirectPayment = false;
    } else if (row.transportationType == "Company Vehicle") {
      hiddenVehicleClass = false;
    }

    var hiddenFoodDirectPayment = true;
    var hiddenFoodClass = true;
    if (row.foodType == "Direct payment Food") {
      hiddenFoodDirectPayment = false;
    } else if (row.foodType == "Company Food") {
      hiddenFoodClass = false;
    }

    if (this.state.paymentType == "PercentSalary") {
      this.setState({
        maxPayment: 100
      });
    } else {
      this.setState({
        maxPayment: 0
      });
    }

    if (this.state.paymentToHouseholderType == "PercentSalary") {
      this.setState({
        maxPaymentToHouseholder: 100
      });
    } else {
      this.setState({
        maxPaymentToHouseholder: 0
      });
    }

    if (this.state.electricityType == "PercentSalary") {
      this.setState({
        maxElectricity: 100
      });
    } else {
      this.setState({
        maxElectricity: 0
      });
    }

    if (this.state.waterType == "PercentSalary") {
      this.setState({
        maxWater: 100
      });
    } else {
      this.setState({
        maxWater: 0
      });
    }

    if (this.state.furnitureType == "PercentSalary") {
      this.setState({
        maxFurniture: 100
      });
    } else {
      this.setState({
        maxFurniture: 0
      });
    }

    if (this.state.elementarySchoolType == "PercentOfBasicSalary" || this.state.elementarySchoolType == "PercentOfSchoolFree") {
      this.setState({
        maxElementarySchool: 100
      });
    } else {
      this.setState({
        maxElementarySchool: 0
      });
    }

    if (this.state.highSchoolType == "PercentOfBasicSalary" || this.state.highSchoolType == "PercentOfSchoolFree") {
      this.setState({
        maxHighSchool: 100
      });
    } else {
      this.setState({
        maxHighSchool: 0
      });
    }

    if (this.state.maxCollege == "PercentOfBasicSalary" || this.state.maxCollege == "PercentOfSchoolFree") {
      this.setState({
        maxCollege: 100
      });
    } else {
      this.setState({
        maxCollege: 0
      });
    }

    if (this.state.universityType == "PercentOfBasicSalary" || this.state.universityType == "PercentOfSchoolFree") {
      this.setState({
        maxUniversity: 100
      });
    } else {
      this.setState({
        maxUniversity: 0
      });
    }

    var ContractSettingsEndOfServices = [];
    var ContractSettingsEndOfServicesKayit = 0;
    var workingYearsMinInvoluntary = 0;
    var workingYearsMinVoluntary = 0;
    var disabledWorkingYearsMinVoluntary = false;
    var disabledWorkingYearsMinInvoluntary = false;

    if (row.contractSettingsEndOfServices != null && row.contractSettingsEndOfServices != undefined && row.contractSettingsEndOfServices.length > 0) {
      ContractSettingsEndOfServices = row.contractSettingsEndOfServices;
      ContractSettingsEndOfServicesKayit = row.contractSettingsEndOfServices[row.contractSettingsEndOfServices.length - 1].id;

      var Involuntary = row.contractSettingsEndOfServices.filter(p => p.type == "Involuntary");

      if (Involuntary != null && Involuntary != undefined && Involuntary.length > 0) {
        workingYearsMinInvoluntary = Involuntary[Involuntary.length - 1].workingYearsMax;
        disabledWorkingYearsMinInvoluntary = true;
      }

      var Voluntary = row.contractSettingsEndOfServices.filter(p => p.type == "Voluntary");

      if (Voluntary != null && Voluntary != undefined && Voluntary.length > 0) {
        workingYearsMinVoluntary = Voluntary[Voluntary.length - 1].workingYearsMax;
        disabledWorkingYearsMinVoluntary = true;
      }
    }

    console.log("XXX", ContractSettingsEndOfServices);
    console.log(ContractSettingsEndOfServicesKayit);
    await this.setState({
      selectedContractSettings: row,

      workingYearsMinInvoluntary: workingYearsMinInvoluntary,
      workingYearsMinVoluntary: workingYearsMinVoluntary,
      disabledWorkingYearsMinVoluntary: disabledWorkingYearsMinVoluntary,
      disabledWorkingYearsMinInvoluntary: disabledWorkingYearsMinInvoluntary,

      ContractSettingsEndOfServices: ContractSettingsEndOfServices,
      ContractSettingsEndOfServicesKayit: ContractSettingsEndOfServicesKayit,
      contractSettingsId: row.id,
      department: row.department,
      discipline: row.discipline,
      designation: row.designation,
      periodBsl: row.periodBsl,
      periodBts: row.periodBts,
      contractDuration: row.contractDuration,
      autoRenewal: row.autoRenewal,
      renewalReminder: row.renewalReminder,
      noticePeriodNotRenew: row.noticePeriodNotRenew,
      dailyWorkingHours: row.dailyWorkingHours,
      dailyOvertimeHours: row.dailyOvertimeHours,
      weeklyWorkingDays: row.weeklyWorkingDays,
      weeklyWorkingDaysKeys: row.weeklyWorkingDaysKeys,
      monthlyWorkingHours: row.monthlyWorkingHours,
      salaryMatrix: row.salaryMatrix,
      salaryRangeType: row.salaryRangeType,
      salaryRangeMin: row.salaryRangeMin,
      salaryRangeMax: row.salaryRangeMax,
      insuranceClass: row.insuranceClass,
      homeAllowance: row.homeAllowance,
      homeAllowanceType: row.homeAllowanceType,
      hiddenHomeAllowance: !row.homeAllowance,
      familyStatus: row.familyStatus,
      hiddenFamilyStatus: !row.familyStatus,

      payment: row.payment,
      paymentType: row.paymentType,
      paymentToHouseholderType: row.paymentToHouseholderType,
      paymentToHouseholder: row.paymentToHouseholder,
      electricity: row.electricity,
      electricityType: row.electricityType,
      water: row.water,
      waterType: row.waterType,
      furniture: row.furniture,
      furnitureType: row.furnitureType,
      companyAccomadation: row.companyAccomadation,
      companyAccomadationType: row.companyAccomadationType,
      transportation: row.transportation,
      transportationType: row.transportationType,
      hiddenTransportation: !row.transportation,
      food: row.food,
      foodType: row.foodType,
      hiddenFood: !row.food,
      paymentTransportationType: row.paymentTransportationType,
      paymentTransportation: row.paymentTransportation,
      vehicleClass: row.vehicleClass,
      paymentFoodType: row.paymentFoodType,
      paymentFood: row.paymentFood,
      foodClass: row.foodClass,
      flightFees: row.flightFees,

      spouse: row.spouse,
      childrenFamilyStatus: row.childrenFamilyStatus,
      ofChildren: row.ofChildren,
      iqama: row.iqama,
      sameAsEmployeeStatus: row.sameAsEmployeeStatus,
      insuranceClassFamilyStatus: row.insuranceClassFamilyStatus,
      childreenEduaction: row.childreenEduaction,
      hiddenChildreenEduaction: !row.childreenEduaction,
      elementarySchoolType: row.elementarySchoolType,
      elementarySchool: row.elementarySchool,
      highSchoolType: row.highSchoolType,
      highSchool: row.highSchool,
      collegeType: row.collegeType,
      college: row.college,
      universityType: row.universityType,
      university: row.university,
      annualVacationPeriod: row.annualVacationPeriod,
      annualVacationPeriodType: row.annualVacationPeriodType,
      annualVacationDays: row.annualVacationDays,
      annaualVacationStartPeriod: row.annaualVacationStartPeriod,
      paymentForNotUsedAnnualVacationDays: row.paymentForNotUsedAnnualVacationDays,
      leaveDaysCompensationsFromAnnualVacationDays: row.leaveDaysCompensationsFromAnnualVacationDays,
      numberOfFamilyMembers: row.numberOfFamilyMembers,
      numberFamilyMembersChildren: row.numberFamilyMembersChildren,
      numberOfFamilyMembersChildren: row.numberOfFamilyMembersChildren,
      flightStatus: row.flightStatus,
      flightRoute: row.flightRoute,
      familyMembersFlightStatus: row.familyMembersFlightStatus,
      flightStatusSameAsEmployee: row.flightStatusSameAsEmployee,
      flightRouteSameAsEmployee: row.flightRouteSameAsEmployee,
      workingYearsMin: row.workingYearsMin,
      workingYearsMax: row.workingYearsMax,
      workingYearsMaxDahil: row.workingYearsMaxDahil,
      percentOfBasicSalaryForEachYear: row.percentOfBasicSalaryForEachYear,
      workingYearsMinEndOfService: row.workingYearsMinEndOfService,
      workingYearsMaxEndOfService: row.workingYearsMaxEndOfService,
      workingYearsMaxEndOfServiceDahil: row.workingYearsMaxEndOfServiceDahil,
      percentOfBasicSalaryForEachYearEndOfService: row.percentOfBasicSalaryForEachYearEndOfService,

      overtimeAllowed: row.overtimeAllowed,
      maximumOvertimeHoursDay: row.maximumOvertimeHoursDay,
      maximumOvertimeHoursWeek: row.maximumOvertimeHoursWeek,
      maximumOvertimeHoursMonth: row.maximumOvertimeHoursMonth,
      maximumOvertimeHoursYear: row.maximumOvertimeHoursYear,
      holidayWorksCheck: row.holidayWorksCheck,
      eidWorksCheck: row.eidWorksCheck,
      overtimeRate: row.overtimeRate,
      normalDays: row.normalDays,
      holidays: row.holidays,
      holidaysOvertimeRate: row.holidaysOvertimeRate,
      eidDays:row.eidDays,
      eidsOvertimeRate: row.eidsOvertimeRate,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      hiddenDataGirisContract: true,
      hiddenDataGirisBasicSalary: true,
      hiddenDataGirisInsurance: true,
      hiddenDataGirisAllowances: true,
      hiddenDataGirisFamilyStatus: true,
      hiddenDataGirisAnnualVacation: true,
      hiddenDataGirisFlights: true,
      hiddenDataGirisOvertime: true,
      hiddenDataGirisBenefits: true,
      hiddenDirectPayment: hiddenDirectPayment,
      hiddenRentofHouse: hiddenRentofHouse,
      hiddenCompanyAccomadation: hiddenCompanyAccomadation,
      hiddenTransportationDirectPayment: hiddenTransportationDirectPayment,
      hiddenVehicleClass: hiddenVehicleClass,
      hiddenFoodDirectPayment: hiddenFoodDirectPayment,
      hiddenFoodClass: hiddenFoodClass,

      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      Period: "",
      Department: [],
      Discipline: [],
      Designation: [],
    });

    this.setState({
      selectedContractSettings: [],

      contractSettingsId: 0,
      department: [],
      discipline: [],
      designation: [],
      periodBsl: "",
      periodBts: "",
      contractDuration: "",
      autoRenewal: "",
      renewalReminder: "",
      noticePeriodNotRenew: "",
      dailyWorkingHours: "",
      dailyOvertimeHours: "",
      weeklyWorkingDays: [],
      weeklyWorkingDaysKeys: [],
      monthlyWorkingHours: "",
      salaryMatrix: "",
      salaryRangeType: "",
      salaryRangeMin: "",
      salaryRangeMax: "",
      insuranceClass: "",
      homeAllowance: "",
      familyStatus: "",
      payment: "",
      paymentType: "",
      paymentToHouseholderType: "",
      paymentToHouseholder: "",
      electricity: "",
      electricityType: "",
      water: "",
      waterType: "",
      furniture: "",
      furnitureType: "",
      companyAccomadation: "",
      companyAccomadationType: "",
      transportation: "",
      food: "",
      paymentTransportationType: "",
      paymentTransportation: "",
      vehicleClass: "",
      paymentFoodType: "",
      paymentFood: "",
      foodClass: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      hiddenDataGirisContract: true,
      hiddenDataGirisBasicSalary: true,
      hiddenDataGirisInsurance: true,
      hiddenDataGirisAllowances: true,
      hiddenDataGirisFamilyStatus: true,
      hiddenDataGirisAnnualVacation: true,
      hiddenDataGirisFlights: true,
      hiddenDataGirisOvertime: true,
      hiddenDataGirisBenefits: true,
    });
  }

  changeButtonCancel = () => {
    this.formRef.current.setFieldsValue({
      Period: "",
      Department: [],
      Discipline: [],
      Designation: [],
    });

    this.setState({
      selectedContractSettings: [],

      contractSettingsId: 0,
      department: [],
      discipline: [],
      designation: [],
      periodBsl: "",
      periodBts: "",
      contractDuration: "",
      autoRenewal: "",
      renewalReminder: "",
      noticePeriodNotRenew: "",
      dailyWorkingHours: "",
      dailyOvertimeHours: "",
      weeklyWorkingDays: [],
      monthlyWorkingHours: "",
      salaryMatrix: "",
      salaryRangeType: "",
      salaryRangeMin: "",
      salaryRangeMax: "",
      insuranceClass: "",
      homeAllowance: "",
      familyStatus: "",
      payment: "",
      paymentType: "",
      paymentToHouseholderType: "",
      paymentToHouseholder: "",
      electricity: "",
      electricityType: "",
      water: "",
      waterType: "",
      furniture: "",
      furnitureType: "",
      companyAccomadation: "",
      companyAccomadationType: "",
      transportation: "",
      food: "",
      paymentTransportationType: "",
      paymentTransportation: "",
      vehicleClass: "",
      paymentFoodType: "",
      paymentFood: "",
      foodClass: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    await this.resetInputs();
    console.log("XXX", this.state.MaxContractSettings);
    if (this.state.createNewButton == "Create New") {
      if (this.state.MaxContractSettings != null && this.state.MaxContractSettings != undefined && this.state.MaxContractSettings.length > 0) {
          this.setState({
            overtimeAllowed: this.state.MaxContractSettings[0].overtimeAllowed,
            maximumOvertimeHoursDay: this.state.MaxContractSettings[0].maximumOvertimeHoursDay,
            maximumOvertimeHoursWeek: this.state.MaxContractSettings[0].maximumOvertimeHoursWeek,
            maximumOvertimeHoursMonth: this.state.MaxContractSettings[0].maximumOvertimeHoursMonth,
            maximumOvertimeHoursYear: this.state.MaxContractSettings[0].maximumOvertimeHoursYear,
            holidayWorksCheck: this.state.MaxContractSettings[0].holidayWorksCheck,
            eidWorksCheck: this.state.MaxContractSettings[0].eidWorksCheck,
            overtimeRate: this.state.MaxContractSettings[0].overtimeRate,
            normalDays: this.state.MaxContractSettings[0].normalDays,
            holidays: this.state.MaxContractSettings[0].holidays,
            holidaysOvertimeRate: this.state.MaxContractSettings[0].holidaysOvertimeRate,
            eidDays: this.state.MaxContractSettings[0].eidDays,
            eidsOvertimeRate: this.state.MaxContractSettings[0].eidsOvertimeRate,
          });
        this.showOrHideModalPercent();
      }
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenCreateNewButton: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenCreateNewButton: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeDate = async (date, dateString) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    if (dateString[0] !== "" || dateString[1] !== "") {
      this.setState({
        periodBsl: dateString[0],
        periodBts: dateString[1]
      });
    } else {
      this.setState({
        periodBsl: today,
        periodBts: today
      });
    }
  }

  onChangeDepartment = async (value) => {
    this.setState({
      department: value,
      // discipline:[],
      // designation:[],
    });
  }

  addContract = async () => {
    this.setState({
      hiddenDataGirisContract: !this.state.hiddenDataGirisContract,
    });
  };

  addBasicSalary = async () => {
    this.setState({
      hiddenDataGirisBasicSalary: !this.state.hiddenDataGirisBasicSalary,
    });
  };

  addNewItemBasicSalary = async () => {
    await this.setState({
      ContractSettingsEditableKayit: this.state.ContractSettingsEditableKayit + 1,
    });
    this.setState(prevState => ({
      ContractSettingsEditable: [...prevState.ContractSettingsEditable, {
        key: this.state.ContractSettingsEditableKayit,
        id: 0,
        index: this.state.ContractSettingsEditableKayit,
        textName: "",
        textField: "",
        type: "Basic salary",
      }]
    }))
  };

  removeItemBasicSalary = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEditable.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEditable.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  addInsurance = async () => {
    this.setState({
      hiddenDataGirisInsurance: !this.state.hiddenDataGirisInsurance,
    });
  };

  addNewItemInsurance = async () => {
    await this.setState({
      ContractSettingsEditableKayit: this.state.ContractSettingsEditableKayit + 1,
    });
    this.setState(prevState => ({
      ContractSettingsEditable: [...prevState.ContractSettingsEditable, {
        key: this.state.ContractSettingsEditableKayit,
        id: 0,
        index: this.state.ContractSettingsEditableKayit,
        textName: "",
        textField: "",
        type: "Insurance",
      }]
    }))
  };

  removeItemInsurance = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEditable.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEditable.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  addAllowances = async () => {
    this.setState({
      hiddenDataGirisAllowances: !this.state.hiddenDataGirisAllowances,
    });
  };

  addNewItemAllowances = async () => {
    await this.setState({
      ContractSettingsEditableKayit: this.state.ContractSettingsEditableKayit + 1,
    });
    this.setState(prevState => ({
      ContractSettingsEditable: [...prevState.ContractSettingsEditable, {
        key: this.state.ContractSettingsEditableKayit,
        id: 0,
        index: this.state.ContractSettingsEditableKayit,
        textName: "",
        textField: "",
        type: "Allowances",
      }]
    }))
  };

  removeItemAllowances = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEditable.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEditable.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  addFamilyStatus = async () => {
    this.setState({
      hiddenDataGirisFamilyStatus: !this.state.hiddenDataGirisFamilyStatus,
    });
  };

  addNewItemFamilyStatus = async () => {
    await this.setState({
      ContractSettingsEditableKayit: this.state.ContractSettingsEditableKayit + 1,
    });
    this.setState(prevState => ({
      ContractSettingsEditable: [...prevState.ContractSettingsEditable, {
        key: this.state.ContractSettingsEditableKayit,
        id: 0,
        index: this.state.ContractSettingsEditableKayit,
        textName: "",
        textField: "",
        type: "FamilyStatus",
      }]
    }))
  };

  removeItemFamilyStatus = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEditable.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEditable.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  addAnnualVacation = async () => {
    this.setState({
      hiddenDataGirisAnnualVacation: !this.state.hiddenDataGirisAnnualVacation,
    });
  };

  addNewItemAnnualVacation = async () => {
    await this.setState({
      ContractSettingsEditableKayit: this.state.ContractSettingsEditableKayit + 1,
    });
    this.setState(prevState => ({
      ContractSettingsEditable: [...prevState.ContractSettingsEditable, {
        key: this.state.ContractSettingsEditableKayit,
        id: 0,
        index: this.state.ContractSettingsEditableKayit,
        textName: "",
        textField: "",
        type: "AnnualVacation",
      }]
    }))
  };

  removeItemAnnualVacation = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEditable.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEditable.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  addFlights = async () => {
    this.setState({
      hiddenDataGirisFlights: !this.state.hiddenDataGirisFlights,
    });
  };

  addNewItemFlights = async () => {
    await this.setState({
      ContractSettingsEditableKayit: this.state.ContractSettingsEditableKayit + 1,
    });
    this.setState(prevState => ({
      ContractSettingsEditable: [...prevState.ContractSettingsEditable, {
        key: this.state.ContractSettingsEditableKayit,
        id: 0,
        index: this.state.ContractSettingsEditableKayit,
        textName: "",
        textField: "",
        type: "Flights",
      }]
    }))
  };

  removeItemFlights = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEditable.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEditable.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEditable: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  addOvertime = async () => {
    this.setState({
      hiddenDataGirisOvertime: !this.state.hiddenDataGirisOvertime,
    });
  };

  addBenefits = async () => {
    this.setState({
      hiddenDataGirisBenefits: !this.state.hiddenDataGirisBenefits,
    });
  };

  addNewItemEndOfServiceVoluntary = async () => {
    console.log("AAA", this.state.ContractSettingsEndOfServices);
    console.log(this.state.ContractSettingsEndOfServicesKayit);
    if (this.state.workingYearsMinVoluntary != null && this.state.workingYearsMinVoluntary != undefined && this.state.workingYearsMinVoluntary > 0) {
      if (this.state.percentOfBasicSalaryForEachYearVoluntary != null && this.state.percentOfBasicSalaryForEachYearVoluntary != undefined && this.state.percentOfBasicSalaryForEachYearVoluntary > 0) {
        await this.setState({
          ContractSettingsEndOfServicesKayit: this.state.ContractSettingsEndOfServicesKayit + 1,
        });
        this.setState(prevState => ({
          ContractSettingsEndOfServices: [...prevState.ContractSettingsEndOfServices, {
            key: this.state.ContractSettingsEndOfServicesKayit,
            id: 0,
            index: this.state.ContractSettingsEndOfServicesKayit,
            workingYearsMin: this.state.workingYearsMinVoluntary,
            workingYearsMax: this.state.workingYearsMaxVoluntary,
            workingYearsMaxDahil: this.state.workingYearsMaxDahilVoluntary,
            percentOfBasicSalaryForEachYear: this.state.percentOfBasicSalaryForEachYearVoluntary,
            calculationWillAddedTotal: this.state.calculationWillAddedTotalVoluntary,
            type: "Voluntary"
          }]
        }));
        this.setState({
          workingYearsMinVoluntary: this.state.workingYearsMaxVoluntary,
          disabledWorkingYearsMinVoluntary: true,
          workingYearsMaxVoluntary: "",
          workingYearsMaxDahilVoluntary: "",
          percentOfBasicSalaryForEachYearVoluntary: "",
          calculationWillAddedTotalVoluntary: "",
        });
      } else {
        this.showWarning("Please input your Percent of basic salary for each year !");
      }
    } else {
      this.showWarning("Please enter the starting year of working !");
    }
  };

  removeItemEndOfServiceVoluntary = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEndOfServices.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEndOfServices.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEndOfServices: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEndOfServices: newItem,
        })
      }

      var workingYearsMinInvoluntary = 0;
      var workingYearsMinVoluntary = 0;
      var disabledWorkingYearsMinVoluntary = false;
      var disabledWorkingYearsMinInvoluntary = false;

      if (newItem != null && newItem != undefined && newItem.length > 0) {
        var Involuntary = newItem.filter(p => p.type == "Involuntary");

        if (Involuntary != null && Involuntary != undefined && Involuntary.length > 0) {
          workingYearsMinInvoluntary = Involuntary[Involuntary.length - 1].workingYearsMax;
          disabledWorkingYearsMinInvoluntary = true;
        }

        var Voluntary = newItem.filter(p => p.type == "Voluntary");

        if (Voluntary != null && Voluntary != undefined && Voluntary.length > 0) {
          workingYearsMinVoluntary = Voluntary[Voluntary.length - 1].workingYearsMax;
          disabledWorkingYearsMinVoluntary = true;
        }
      }

      this.setState({
        disabledWorkingYearsMinVoluntary: disabledWorkingYearsMinVoluntary,
        workingYearsMinVoluntary: workingYearsMinVoluntary,
        disabledWorkingYearsMinInvoluntary: disabledWorkingYearsMinInvoluntary,
        workingYearsMinInvoluntary: workingYearsMinInvoluntary,
      });

    } else {
      this.showWarning();
    }
  }

  addNewItemEndOfServiceInvoluntary = async () => {
    if (this.state.workingYearsMinInvoluntary != null && this.state.workingYearsMinInvoluntary != undefined && this.state.workingYearsMinInvoluntary > 0) {
      if (this.state.percentOfBasicSalaryForEachYearInvoluntary != null && this.state.percentOfBasicSalaryForEachYearInvoluntary != undefined && this.state.percentOfBasicSalaryForEachYearInvoluntary > 0) {
        console.log("AAA", this.state.ContractSettingsEndOfServices);
        console.log(this.state.ContractSettingsEndOfServicesKayit);
        await this.setState({
          ContractSettingsEndOfServicesKayit: this.state.ContractSettingsEndOfServicesKayit + 1,
        });
        this.setState(prevState => ({
          ContractSettingsEndOfServices: [...prevState.ContractSettingsEndOfServices, {
            key: this.state.ContractSettingsEndOfServicesKayit,
            id: 0,
            index: this.state.ContractSettingsEndOfServicesKayit,
            workingYearsMin: this.state.workingYearsMinInvoluntary,
            workingYearsMax: this.state.workingYearsMaxInvoluntary,
            workingYearsMaxDahil: this.state.workingYearsMaxDahilInvoluntary,
            percentOfBasicSalaryForEachYear: this.state.percentOfBasicSalaryForEachYearInvoluntary,
            calculationWillAddedTotal: this.state.calculationWillAddedTotalInvoluntary,
            type: "Involuntary"
          }]
        }));
        this.setState({
          workingYearsMinInvoluntary: this.state.workingYearsMaxInvoluntary,
          workingYearsMaxInvoluntary: "",
          workingYearsMaxDahilInvoluntary: "",
          percentOfBasicSalaryForEachYearInvoluntary: "",
          calculationWillAddedTotalInvoluntary: "",
        });
      } else {
        this.showWarning("Please input your Percent of basic salary for each year !");
      }
    } else {
      this.showWarning("Please enter the starting year of working !");
    }
  };

  removeItemEndOfServiceInvoluntary = async (index) => {
    if (index > 0) {
      var newItem = this.state.ContractSettingsEndOfServices.filter(p => p.index != index)
      var newRemoveItem = this.state.ContractSettingsEndOfServices.filter(p => p.index == index)

      if (newRemoveItem != null && newRemoveItem != undefined && newRemoveItem.length == 1) {
        this.setState({
          ContractSettingsEndOfServices: newItem,
        })
      } else {
        this.setState({
          ContractSettingsEndOfServices: newItem,
        })
      }
    } else {
      this.showWarning();
    }
  }

  handleChangeDailyWorkingHours = (e) => {
    this.setState({
      dailyWorkingHours: e,
      monthlyWorkingHours: e * 30,
    });

    this.formRef.current.setFieldsValue({
      MonthlyWorkingHours: e * 30,
    });
  };

  handleChangeAnnaualVacationStartPeriod = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.AnnaualVacationStartPeriods.length; z++) {
      if (this.state.AnnaualVacationStartPeriods[z].id == e) {
        deger = this.state.AnnaualVacationStartPeriods[z].name;
      }
    }
    this.setState({
      annaualVacationStartPeriodAck: deger,
      annaualVacationStartPeriod: e,
    });
  };

  addAnnaualVacationStartPeriods = async () => {
    const { itemAnnaualVacationStartPeriods } = this.state;
    if (itemAnnaualVacationStartPeriods != null && itemAnnaualVacationStartPeriods != "" && itemAnnaualVacationStartPeriods != undefined) {
      const newItem = {
        name: itemAnnaualVacationStartPeriods,
        type: "AnnaualVacationStartPeriod"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemAnnaualVacationStartPeriods: '',
      });
      this.getCodesAnnaualVacationStartPeriod();
    } else {
      this.showWarning();
    }
  };

  getCodesAnnaualVacationStartPeriod = async () => {
    const response = await handleRequest("GET", "/Code/type/AnnaualVacationStartPeriod");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        AnnaualVacationStartPeriods: response.data
      });
    }
  }

  resetHomeAllowanceDirectPayment = () => {
    this.setState({
      paymentType: "",
      payment: "",

    });
  }

  resetHomeAllowanceRentofHouse = () => {
    this.setState({
      paymentToHouseholderType: "",
      paymentToHouseholder: "",
      electricityType: "",
      electricity: "",
      waterType: "",
      water: "",
      furnitureType: "",
      furniture: "",
    });
  }

  resetHomeAllowanceCompanyAccomadation = () => {
    this.setState({
      companyAccomadationType: "",
    });
  }

  resetHomeAllowanceDirectPaymentTransportation = () => {
    this.setState({
      paymentTransportationType: "",
      paymentTransportation: "",
    });
  }

  resetHomeAllowanceCompanyVehicle = () => {
    this.setState({
      vehicleClass: "",
    });
  }

  resetHomeAllowanceDirectPaymentFood = () => {
    this.setState({
      paymentFoodType: "",
      paymentFood: "",
    });
  }

  resetHomeAllowanceCompanyFood = () => {
    this.setState({
      foodClass: "",
    });
  }

  render() {
    var itemPeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Period");
    var itemDepartmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Department");
    var itemDisciplineAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline");
    var itemDesignationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Designation");

    var itemPeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "Period");
    var itemDepartmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "Department");
    var itemDisciplineRequired = this.state.dynamicInputs.find(p => p.inputKey === "Discipline");
    var itemDesignationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Designation");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("contractSettings")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "departmentAck",
        key: "key",
        title: <FormattedMessage id="ContractSettingsDepartment" defaultMessage="Department" />,
      },
      {
        dataIndex: "contractDuration",
        key: "key",
        title: <FormattedMessage id="ContractSettingsContractDuration" defaultMessage="Contract Duration" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="ContractSettingsAutoRenewal" defaultMessage="Auto Renewal" />,
        render: (record) => {
          if (record != null && record != undefined) {
            if (record.autoRenewal) {
              return <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />;
            } else {
              return <FormattedMessage id="GeneralButtonNo" defaultMessage="No" />;
            }
          }
          return "";
        }
      },
      {
        dataIndex: "renewalReminder",
        key: "key",
        title: <FormattedMessage id="ContractSettingsRenewalReminder" defaultMessage="Contract renewal reminder" />,
      },
      {
        dataIndex: "noticePeriodNotRenew",
        key: "key",
        title: <FormattedMessage id="ContractSettingsNoticePeriodNotRenew" defaultMessage="Employee notice period not to renew (min.)" />,
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateContractSettings : this.saveContractSettings}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("contractSettings")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />

              {(itemPeriodAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ContractSettingsPeriod" defaultMessage="Period" />}
                  name="Period"
                  rules={[{ required: itemPeriodRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPeriodRequired.translateRequired) == true ? itemPeriodRequired.translateRequired : -1} defaultMessage="Please input your Period!" /> }]} >
                  <RangePicker value={[moment(this.state.periodBsl, 'YYYY-MM-DD'), moment(this.state.periodBts, 'YYYY-MM-DD')]}
                    disabledDate={this.disabledDate} format="YYYY-MM-DD" onChange={this.onChangeDate} />
                </Form.Item>}

              {(itemDepartmentAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ContractSettingsDepartment" defaultMessage="Department" />}
                  name="Department"
                  rules={[{ required: itemDepartmentRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDepartmentRequired.translateRequired) == true ? itemDepartmentRequired.translateRequired : -1} defaultMessage="Please input your Department!" /> }]} >
                  <Select size="large" mode="multiple" style={{ width: '100%' }} placeholder="Select Department" value={this.state.department}
                    onChange={this.onChangeDepartment}>
                    {this.state.departments.map(i => (
                      <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemDisciplineAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ContractSettingsDiscipline" defaultMessage="Discipline" />}
                  name="Discipline"
                  rules={[{ required: itemDisciplineRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDisciplineRequired.translateRequired) == true ? itemDisciplineRequired.translateRequired : -1} defaultMessage="Please input your Discipline!" /> }]} >
                  <Select size="large" mode="multiple" style={{ width: '100%' }} placeholder="Select Discipline" value={this.state.discipline} onChange={(value) => {
                    this.setState({
                      discipline: value
                    });
                  }}>
                    {this.state.disciplines.map(i => (
                      <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemDesignationAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ContractSettingsDesignation" defaultMessage="Designation" />}
                  name="Designation"
                  rules={[{ required: itemDesignationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDesignationRequired.translateRequired) == true ? itemDesignationRequired.translateRequired : -1} defaultMessage="Please input your Designation!" /> }]} >
                  <Select size="large" mode="multiple" style={{ width: '100%' }} placeholder="Select Designation" value={this.state.designation} onChange={(value) => {
                    console.log("XXX", value);
                    this.setState({
                      designation: value
                    });
                  }}>
                    {this.state.designations.map(i => (
                      <Option key={i.id} value={i.id}>{i.designationName}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisContract} onClick={this.addContract} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisContract} onClick={this.addContract} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsContract" defaultMessage="Contract" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisContract}>
                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsContractDuration" defaultMessage="Contract Duration" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ contractDuration: e })} value={this.state.contractDuration} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsAutoRenewal" defaultMessage="Auto Renewal" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />} checked={this.state.autoRenewal} defaultChecked onChange={(value) => {
                      this.setState({ autoRenewal: value })
                    }} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsRenewalReminder" defaultMessage="Renewal Reminder Days" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ renewalReminder: e })} value={this.state.renewalReminder} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsNoticePeriodNotRenew" defaultMessage="Employee notice period not to renew (min.)" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ noticePeriodNotRenew: e })} value={this.state.noticePeriodNotRenew} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsDailyWorkingHours" defaultMessage="Daily working hours" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }} value={this.state.dailyWorkingHours}
                      onChange={this.handleChangeDailyWorkingHours} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsDailyOvertimeHours" defaultMessage="Daily overtime hours" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ dailyOvertimeHours: e })} value={this.state.dailyOvertimeHours} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsWeeklyWorkingDays" defaultMessage="Weekly working days" />
                  </FormBoot.Label>
                  <Col sm="3">
                    <Select size="large" mode="multiple" style={{ width: '100%' }} placeholder="Select Weekly working days" value={this.state.weeklyWorkingDaysKeys} onChange={(value) => {
                      this.setState({
                        weeklyWorkingDaysKeys: value
                      });
                    }}>
                      <Option key={"Monday"} value={"Monday"}>{"Monday"}</Option>
                      <Option key={"Tuesday"} value={"Tuesday"}>{"Tuesday"}</Option>
                      <Option key={"Wednesday"} value={"Wednesday"}>{"Wednesday"}</Option>
                      <Option key={"Thursday"} value={"Thursday"}>{"Thursday"}</Option>
                      <Option key={"Friday"} value={"Friday"}>{"Friday"}</Option>
                      <Option key={"Saturday"} value={"Saturday"}>{"Saturday"}</Option>
                      <Option key={"Sunday"} value={"Sunday"}>{"Sunday"}</Option>
                    </Select>
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsMonthlyWorkingHours" defaultMessage="Monthly Working hours" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ monthlyWorkingHours: e })} value={this.state.monthlyWorkingHours} />
                  </Col>
                </FormBoot.Group>
              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisBasicSalary} onClick={this.addBasicSalary} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisBasicSalary} onClick={this.addBasicSalary} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsBasicSalary" defaultMessage="Basic salary" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisBasicSalary}>
                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsSalaryMatrix" defaultMessage="as per salary matrix" />
                  </FormBoot.Label>
                  <Col sm="3">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                      checked={this.state.salaryMatrix} defaultChecked onChange={(value) => {
                        this.setState({ salaryMatrix: value })
                      }} />
                  </Col>
                </FormBoot.Group>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsSalaryRange" defaultMessage="Salary range" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ salaryRangeMin: e })} value={this.state.salaryRangeMin} />
                  </Col>
                  <Col sm="2">
                    <InputNumber min={0} style={{ width: '100%' }}
                      onChange={(e) => this.setState({ salaryRangeMax: e })} value={this.state.salaryRangeMax} />
                  </Col>
                  <Col sm="2">
                    <Select mode="single" style={{ width: '100%' }} value={this.state.salaryRangeType} onChange={(value) => {
                      console.log("XXX", value);
                      this.setState({
                        salaryRangeType: value
                      });
                    }}>
                      <Option key={1} value={1}>{"SR"}</Option>
                      <Option key={2} value={2}>{"$"}</Option>
                      <Option key={3} value={3}>{"TL"}</Option>
                    </Select>
                  </Col>
                  <Col sm="1">
                    {/* {index==this.state.ContractSettingsEditable.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 && */}
                    <AntButton type="primary" onClick={this.addNewItemBasicSalary} icon={<PlusOutlined />}></AntButton>
                    {/* } */}
                  </Col>
                </FormBoot.Group>

                {this.state.ContractSettingsEditable.filter(p => p.type == "Basic salary").map((item, index) => (
                  <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    {item.id == 0 ?
                      <Col sm="3">
                        <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                              el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))
                        }} />
                      </Col> :
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        {item.textName}
                      </FormBoot.Label>
                    }

                    <Col sm="5">
                      <Input value={item.textField} style={{ width: "100%" }} onChange={(e) => {
                        e.persist();
                        this.setState(prevState => ({
                          ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                            el => el.index === item.index ? { ...el, textField: e.target.value } : el
                          )
                        }))
                      }} />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={() => this.removeItemBasicSalary(item.index)} danger icon={<CloseOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>
                ))}

              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisInsurance} onClick={this.addInsurance} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisInsurance} onClick={this.addInsurance} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsInsurance" defaultMessage="Insurance" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisInsurance}>
                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsInsuranceClass" defaultMessage="Insurance Class" />
                  </FormBoot.Label>
                  <Col sm="3">
                    <Select mode="single" style={{ width: '100%' }} value={this.state.insuranceClass} onChange={(value) => {
                      this.setState({
                        insuranceClass: value
                      });
                    }}>
                      <Option key={"Insurance-01"} value={"Insurance-01"}>{"Insurance-01"}</Option>
                      <Option key={"Insurance-02"} value={"Insurance-02"}>{"Insurance-02"}</Option>
                      <Option key={"Insurance-03"} value={"Insurance-03"}>{"Insurance-03"}</Option>
                      <Option key={"Insurance-04"} value={"Insurance-04"}>{"Insurance-04"}</Option>
                    </Select>
                  </Col>
                </FormBoot.Group>

                {this.state.ContractSettingsEditable.filter(p => p.type == "Insurance").map((item, index) => (
                  <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    {item.id == 0 ?
                      <Col sm="3">
                        <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                              el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))
                        }} />
                      </Col> :
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        {item.textName}
                      </FormBoot.Label>
                    }

                    <Col sm="5">
                      <Input value={item.textField} style={{ width: "100%" }} onChange={(e) => {
                        e.persist();
                        this.setState(prevState => ({
                          ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                            el => el.index === item.index ? { ...el, textField: e.target.value } : el
                          )
                        }))
                      }} />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={() => this.removeItemInsurance(item.index)} danger icon={<CloseOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>
                ))}

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="11"></Col>
                  <Col sm="1">
                    {/* {index==this.state.ContractSettingsEditable.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 && */}
                    <AntButton type="primary" onClick={this.addNewItemInsurance} icon={<PlusOutlined />}></AntButton>
                    {/* } */}
                  </Col>
                </FormBoot.Group>

              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisAllowances} onClick={this.addAllowances} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisAllowances} onClick={this.addAllowances} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsAllowances" defaultMessage="Allowances" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisAllowances}>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="ContractSettingsHomeAllowance" defaultMessage="Home Allowance" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                      checked={this.state.homeAllowance} defaultChecked onChange={(value) => {
                        this.setState({ homeAllowance: value, hiddenHomeAllowance: !value })
                      }} />
                  </Col>
                </FormBoot.Group>

                <div hidden={this.state.hiddenHomeAllowance}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="6">
                      <Radio.Group onChange={(e) => {
                        var hiddenDirectPayment = true;
                        var hiddenRentofHouse = true;
                        var hiddenCompanyAccomadation = true;
                        if (e.target.value == "Direct payment") {
                          hiddenDirectPayment = false;
                          this.resetHomeAllowanceRentofHouse();
                          this.resetHomeAllowanceCompanyAccomadation();
                        } else if (e.target.value == "Rent of house") {
                          this.resetHomeAllowanceDirectPayment();
                          this.resetHomeAllowanceCompanyAccomadation();
                          hiddenRentofHouse = false;
                        } else if (e.target.value == "Company Accomadation") {
                          this.resetHomeAllowanceDirectPayment();
                          this.resetHomeAllowanceRentofHouse();
                          hiddenCompanyAccomadation = false;
                        }
                        this.setState({
                          homeAllowanceType: e.target.value,
                          hiddenDirectPayment: hiddenDirectPayment,
                          hiddenRentofHouse: hiddenRentofHouse,
                          hiddenCompanyAccomadation: hiddenCompanyAccomadation,
                        });
                      }}
                        value={this.state.homeAllowanceType}>
                        <Radio value={"Direct payment"}><FormattedMessage id="ContractSettingsDirectPayment" defaultMessage="Direct payment" /></Radio>
                        <Radio value={"Rent of house"}><FormattedMessage id="ContractSettingsRentOfHouse" defaultMessage="Rent of house" /></Radio>
                        <Radio value={"Company Accomadation"}><FormattedMessage id="ContractSettingsCompanyAccomadation" defaultMessage="Company Accomadation" /></Radio>
                      </Radio.Group>
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.hiddenDirectPayment}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsPayment" defaultMessage="Payment" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.paymentType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              payment: 0
                            });
                          }
                          if (value == "PercentSalary") {
                            this.setState({
                              maxPayment: 100
                            });
                          } else {
                            this.setState({
                              maxPayment: 0
                            });
                          }
                          this.setState({
                            paymentType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.paymentType == "Unlimited"}>
                        {(this.state.maxPayment != null && this.state.maxPayment != undefined && this.state.maxPayment > 0) ?
                          <InputNumber min={0} max={this.state.maxPayment} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ payment: e })} value={this.state.payment} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ payment: e })} value={this.state.payment} />}
                      </Col>
                    </FormBoot.Group>
                  </div>

                  <div hidden={this.state.hiddenRentofHouse}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsPaymentToHouseholderType" defaultMessage="Payment to householder" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.paymentToHouseholderType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              paymentToHouseholder: 0
                            });
                          }
                          if (value == "PercentSalary") {
                            this.setState({
                              maxPaymentToHouseholder: 100
                            });
                          } else {
                            this.setState({
                              maxPaymentToHouseholder: 0
                            });
                          }
                          this.setState({
                            paymentToHouseholderType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.paymentToHouseholderType == "Unlimited"}>
                        {(this.state.maxPaymentToHouseholder != null && this.state.maxPaymentToHouseholder != undefined && this.state.maxPaymentToHouseholder > 0) ?
                          <InputNumber min={0} max={this.state.maxPaymentToHouseholder} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ paymentToHouseholder: e })} value={this.state.paymentToHouseholder} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ paymentToHouseholder: e })} value={this.state.paymentToHouseholder} />}
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsElectricityType" defaultMessage="Electricity" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.electricityType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              electricity: 0
                            });
                          }
                          if (value == "PercentSalary") {
                            this.setState({
                              maxElectricity: 100
                            });
                          } else {
                            this.setState({
                              maxElectricity: 0
                            });
                          }
                          this.setState({
                            electricityType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.electricityType == "Unlimited"}>
                        {(this.state.maxElectricity != null && this.state.maxElectricity != undefined && this.state.maxElectricity > 0) ?
                          <InputNumber min={0} max={this.state.maxElectricity} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ electricity: e })} value={this.state.electricity} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ electricity: e })} value={this.state.electricity} />}
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsWaterType" defaultMessage="Water" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.waterType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              water: 0
                            });
                          }
                          if (value == "PercentSalary") {
                            this.setState({
                              maxWater: 100
                            });
                          } else {
                            this.setState({
                              maxWater: 0
                            });
                          }
                          this.setState({
                            waterType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.waterType == "Unlimited"}>
                        {(this.state.maxWater != null && this.state.maxWater != undefined && this.state.maxWater > 0) ?
                          <InputNumber min={0} max={this.state.maxWater} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ water: e })} value={this.state.water} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ water: e })} value={this.state.water} />}
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsFurnitureType" defaultMessage="Furniture" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.furnitureType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              furniture: 0
                            });
                          }
                          if (value == "PercentSalary") {
                            this.setState({
                              maxFurniture: 100
                            });
                          } else {
                            this.setState({
                              maxFurniture: 0
                            });
                          }
                          this.setState({
                            furnitureType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.furnitureType == "Unlimited"}>
                        {(this.state.maxFurniture != null && this.state.maxFurniture != undefined && this.state.maxFurniture > 0) ?
                          <InputNumber min={0} max={this.state.maxFurniture} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ furniture: e })} value={this.state.furniture} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ furniture: e })} value={this.state.furniture} />}
                      </Col>
                    </FormBoot.Group>
                  </div>

                  <div hidden={this.state.hiddenCompanyAccomadation}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsCompanyAccomadation" defaultMessage="Company Accomadation" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.companyAccomadationType} onChange={(value) => {
                          this.setState({
                            companyAccomadationType: value
                          });
                        }}>
                          <Option key={"Hotel"} value={"Hotel"}>{"Hotel"}</Option>
                          <Option key={"Camp-01"} value={"Camp-01"}>{"Camp-01"}</Option>
                          <Option key={"Camp-02"} value={"Camp-02"}>{"Camp-02"}</Option>
                          <Option key={"Camp-03"} value={"Camp-03"}>{"Camp-03"}</Option>
                          <Option key={"Camp-04"} value={"Camp-04"}>{"Camp-04"}</Option>
                          <Option key={"Camp-05"} value={"Camp-05"}>{"Camp-05"}</Option>
                        </Select>
                      </Col>
                    </FormBoot.Group>
                  </div>
                </div>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="ContractSettingsTransportation" defaultMessage="Transportation" />
                  </FormBoot.Label>
                  <Col sm="3">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                      checked={this.state.transportation} defaultChecked onChange={(value) => {
                        this.setState({ transportation: value, hiddenTransportation: !value })
                      }} />
                  </Col>
                </FormBoot.Group>

                <div hidden={this.state.hiddenTransportation}>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="6">
                      <Radio.Group onChange={(e) => {
                        var hiddenTransportationDirectPayment = true;
                        var hiddenVehicleClass = true;
                        if (e.target.value == "Direct payment Transportation") {
                          this.resetHomeAllowanceCompanyVehicle();
                          hiddenTransportationDirectPayment = false;
                        } else if (e.target.value == "Company Vehicle") {
                          this.resetHomeAllowanceDirectPaymentTransportation();
                          hiddenVehicleClass = false;
                        }
                        this.setState({
                          transportationType: e.target.value,
                          hiddenTransportationDirectPayment: hiddenTransportationDirectPayment,
                          hiddenVehicleClass: hiddenVehicleClass,
                        });
                      }}
                        value={this.state.transportationType}>
                        <Radio value={"Direct payment Transportation"}><FormattedMessage id="ContractSettingsDirectPayment" defaultMessage="Direct payment" /></Radio>
                        <Radio value={"Company Vehicle"}><FormattedMessage id="ContractSettingsCompanyVehicle" defaultMessage="Company Vehicle" /></Radio>
                      </Radio.Group>
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.hiddenTransportationDirectPayment}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsPaymentTransportation" defaultMessage="Payment" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.paymentTransportationType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              paymentTransportation: 0
                            });
                          }
                          if (value == "PercentSalary") {
                            this.setState({
                              maxPaymentTransportation: 100
                            });
                          } else {
                            this.setState({
                              maxPaymentTransportation: 0
                            });
                          }
                          this.setState({
                            paymentTransportationType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.paymentTransportationType == "Unlimited"}>
                        {(this.state.maxPaymentTransportation != null && this.state.maxPaymentTransportation != undefined && this.state.maxPaymentTransportation > 0) ?
                          <InputNumber min={0} max={this.state.maxPaymentTransportation} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ paymentTransportation: e })} value={this.state.paymentTransportation} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ paymentTransportation: e })} value={this.state.paymentTransportation} />}
                      </Col>
                    </FormBoot.Group>
                  </div>

                  <div hidden={this.state.hiddenVehicleClass}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsFoodClass" defaultMessage="Food class" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.vehicleClass} onChange={(value) => {
                          this.setState({
                            vehicleClass: value
                          });
                        }}>
                          <Option key={"Vehicle-01"} value={"Vehicle-01"}>{"Vehicle-01"}</Option>
                          <Option key={"Vehicle-02"} value={"Vehicle-02"}>{"Vehicle-02"}</Option>
                          <Option key={"Vehicle-03"} value={"Vehicle-03"}>{"Vehicle-03"}</Option>
                          <Option key={"Vehicle-04"} value={"Vehicle-04"}>{"Vehicle-04"}</Option>
                          <Option key={"Vehicle-05"} value={"Vehicle-05"}>{"Vehicle-05"}</Option>
                          <Option key={"Vehicle-06"} value={"Vehicle-06"}>{"Vehicle-06"}</Option>
                        </Select>
                      </Col>
                    </FormBoot.Group>
                  </div>
                </div>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="ContractSettingsFood" defaultMessage="Food" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                      checked={this.state.food} defaultChecked onChange={(value) => {
                        this.setState({ food: value, hiddenFood: !value })
                      }} />
                  </Col>
                </FormBoot.Group>
                <div hidden={this.state.hiddenFood}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="6">
                      <Radio.Group onChange={(e) => {
                        var hiddenFoodDirectPayment = true;
                        var hiddenFoodClass = true;
                        if (e.target.value == "Direct payment Food") {
                          this.resetHomeAllowanceCompanyFood();
                          hiddenFoodDirectPayment = false;
                        } else if (e.target.value == "Company Food") {
                          this.resetHomeAllowanceDirectPaymentFood();
                          hiddenFoodClass = false;
                        }
                        this.setState({
                          foodType: e.target.value,
                          hiddenFoodDirectPayment: hiddenFoodDirectPayment,
                          hiddenFoodClass: hiddenFoodClass,
                        });
                      }}
                        value={this.state.foodType}>
                        <Radio value={"Direct payment Food"}><FormattedMessage id="ContractSettingsDirectPayment" defaultMessage="Direct payment" /></Radio>
                        <Radio value={"Company Food"}><FormattedMessage id="ContractSettingsCompanyFood" defaultMessage="Company Food" /></Radio>
                      </Radio.Group>
                    </Col>
                  </FormBoot.Group>
                  <div hidden={this.state.hiddenFoodDirectPayment}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsPaymentFood" defaultMessage="Payment" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.paymentFoodType} onChange={(value) => {
                          if (value == "PercentSalary") {
                            this.setState({
                              maxPaymentFood: 100
                            });
                          } else {
                            this.setState({
                              maxPaymentFood: 0
                            });
                          }
                          this.setState({
                            paymentFoodType: value
                          });
                        }}>
                          <Option key={"PercentSalary"} value={"PercentSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.paymentFoodType == "Unlimited"}>
                        {(this.state.maxPaymentFood != null && this.state.maxPaymentFood != undefined && this.state.maxPaymentFood > 0) ?
                          <InputNumber min={0} max={this.state.maxPaymentFood} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ paymentFood: e })} value={this.state.paymentFood} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ paymentFood: e })} value={this.state.paymentFood} />}
                      </Col>
                    </FormBoot.Group>
                  </div>

                  <div hidden={this.state.hiddenFoodClass}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="ContractSettingsFoodClass" defaultMessage="Food Class" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.foodClass} onChange={(value) => {
                          this.setState({
                            foodClass: value
                          });
                        }}>
                          <Option key={"Food-01"} value={"Food-01"}>{"Food-01"}</Option>
                          <Option key={"Food-02"} value={"Food-02"}>{"Food-02"}</Option>
                          <Option key={"Food-03"} value={"Food-03"}>{"Food-03"}</Option>
                        </Select>
                      </Col>
                    </FormBoot.Group>
                  </div>
                </div>

                {this.state.ContractSettingsEditable.filter(p => p.type == "Allowances").map((item, index) => (
                  <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    {item.id == 0 ?
                      <Col sm="3">
                        <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                              el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))
                        }} />
                      </Col> :
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        {item.textName}
                      </FormBoot.Label>
                    }

                    <Col sm="5">
                      <Input value={item.textField} style={{ width: "100%" }} onChange={(e) => {
                        e.persist();
                        this.setState(prevState => ({
                          ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                            el => el.index === item.index ? { ...el, textField: e.target.value } : el
                          )
                        }))
                      }} />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={() => this.removeItemAllowances(item.index)} danger icon={<CloseOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>
                ))}

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="11"></Col>
                  <Col sm="1">
                    {/* {index==this.state.ContractSettingsEditable.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 && */}
                    <AntButton type="primary" onClick={this.addNewItemAllowances} icon={<PlusOutlined />}></AntButton>
                    {/* } */}
                  </Col>
                </FormBoot.Group>

              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisFamilyStatus} onClick={this.addFamilyStatus} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisFamilyStatus} onClick={this.addFamilyStatus} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsFamilyStatus" defaultMessage="Family Status" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisFamilyStatus}>

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="ContractSettingsFamilyStatus" defaultMessage="Family Status" />
                  </FormBoot.Label>
                  <Col sm="2">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                      checked={this.state.familyStatus} defaultChecked onChange={(value) => {
                        if (value == false) {
                          this.setState({
                            spouse: "",
                            childrenFamilyStatus: "",
                            ofChildren: "",
                            iqama: "",
                            sameAsEmployeeStatus: "",
                            insuranceClassFamilyStatus: "",
                            childreenEduaction: "",
                            elementarySchoolType: "",
                            elementarySchool: "",
                            highSchoolType: "",
                            highSchool: "",
                            college: "",
                            collegeType: "",
                            university: "",
                            universityType: "",
                          })
                        }
                        this.setState({
                          familyStatus: value,
                          hiddenFamilyStatus: !value
                        })
                      }} />
                  </Col>
                </FormBoot.Group>

                <div hidden={this.state.hiddenFamilyStatus}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsNumberOfFamilyMembers" defaultMessage="Number of family members" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Checkbox checked={this.state.spouse} onChange={(e) => {
                        this.setState({
                          spouse: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsSpouse" defaultMessage="Spouse" /></Checkbox>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="3">
                      <Checkbox checked={this.state.childrenFamilyStatus} onChange={(e) => {
                        if (e.target.checked == false) {
                          this.setState({
                            ofChildren: 0,
                          });
                        }
                        this.setState({
                          childrenFamilyStatus: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsChildren" defaultMessage="Children" /></Checkbox>
                    </Col>
                    <Col sm="2" hidden={!this.state.childrenFamilyStatus}>
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ ofChildren: e })} value={this.state.ofChildren} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="3">
                      <Checkbox checked={this.state.iqama} onChange={(e) => {
                        this.setState({
                          iqama: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsIqama" defaultMessage="iqama" /></Checkbox>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsHealthInsurance" defaultMessage="Health insurance" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Checkbox checked={this.state.sameAsEmployeeStatus} onChange={(e) => {
                        if (e.target.checked) {
                          this.setState({
                            insuranceClassFamilyStatus: "",
                          });
                        }
                        this.setState({
                          sameAsEmployeeStatus: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsSameAsEmployeeStatus" defaultMessage="Same as Employee status" /></Checkbox>
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.sameAsEmployeeStatus}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsInsuranceClass" defaultMessage="Insurance class" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.insuranceClassFamilyStatus} onChange={(value) => {
                          this.setState({
                            insuranceClassFamilyStatus: value
                          });
                        }}>
                          <Option key={"Insurance-01"} value={"Insurance-01"}>{"Insurance-01"}</Option>
                          <Option key={"Insurance-02"} value={"Insurance-02"}>{"Insurance-02"}</Option>
                          <Option key={"Insurance-03"} value={"Insurance-03"}>{"Insurance-03"}</Option>
                          <Option key={"Insurance-04"} value={"Insurance-04"}>{"Insurance-04"}</Option>
                        </Select>
                      </Col>
                    </FormBoot.Group>
                  </div>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsChildreenEduaction" defaultMessage="Childreen Eduaction" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                        checked={this.state.childreenEduaction} defaultChecked onChange={(value) => {
                          if (value == false) {
                            this.setState({
                              elementarySchoolType: "",
                              elementarySchool: "",
                              highSchoolType: "",
                              highSchool: "",
                              college: "",
                              collegeType: "",
                              university: "",
                              universityType: "",
                            });
                          }
                          this.setState({
                            childreenEduaction: value,
                            hiddenChildreenEduaction: !value
                          })
                        }} />
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.hiddenChildreenEduaction}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsElementarySchool" defaultMessage="Elementary school" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.elementarySchoolType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              elementarySchool: 0
                            });
                          }
                          if (value == "PercentOfBasicSalary" || value == "PercentOfSchoolFree") {
                            this.setState({
                              maxElementarySchool: 100
                            });
                          } else {
                            this.setState({
                              maxElementarySchool: 0
                            });
                          }
                          this.setState({
                            elementarySchoolType: value
                          });
                        }}>
                          <Option key={"PercentOfBasicSalary"} value={"PercentOfBasicSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                          <Option key={"PercentOfSchoolFree"} value={"PercentOfSchoolFree"}>{"Percent (%) of school free"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.elementarySchoolType == "Unlimited"}>
                        {(this.state.maxElementarySchool != null && this.state.maxElementarySchool != undefined && this.state.maxElementarySchool > 0) ?
                          <InputNumber min={0} max={this.state.maxElementarySchool} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ elementarySchool: e })} value={this.state.elementarySchool} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ elementarySchool: e })} value={this.state.elementarySchool} />}
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsHighSchool" defaultMessage="High school" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.highSchoolType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              highSchool: 0
                            });
                          }
                          if (value == "PercentOfBasicSalary" || value == "PercentOfSchoolFree") {
                            this.setState({
                              maxHighSchool: 100
                            });
                          } else {
                            this.setState({
                              maxHighSchool: 0
                            });
                          }
                          this.setState({
                            highSchoolType: value
                          });
                        }}>
                          <Option key={"PercentOfBasicSalary"} value={"PercentOfBasicSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                          <Option key={"PercentOfSchoolFree"} value={"PercentOfSchoolFree"}>{"Percent (%) of school free"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.highSchoolType == "Unlimited"}>
                        {(this.state.maxHighSchool != null && this.state.maxHighSchool != undefined && this.state.maxHighSchool > 0) ?
                          <InputNumber min={0} max={this.state.maxHighSchool} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ highSchool: e })} value={this.state.highSchool} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ highSchool: e })} value={this.state.highSchool} />}
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsCollege" defaultMessage="College" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.collegeType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              college: 0
                            });
                          }
                          if (value == "PercentOfBasicSalary" || value == "PercentOfSchoolFree") {
                            this.setState({
                              maxCollege: 100
                            });
                          } else {
                            this.setState({
                              maxCollege: 0
                            });
                          }
                          this.setState({
                            collegeType: value
                          });
                        }}>
                          <Option key={"PercentOfBasicSalary"} value={"PercentOfBasicSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                          <Option key={"PercentOfSchoolFree"} value={"PercentOfSchoolFree"}>{"Percent (%) of school free"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.collegeType == "Unlimited"}>
                        {(this.state.maxCollege != null && this.state.maxCollege != undefined && this.state.maxCollege > 0) ?
                          <InputNumber min={0} max={this.state.maxCollege} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ college: e })} value={this.state.college} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ college: e })} value={this.state.college} />}
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsUniversity" defaultMessage="University" />
                      </FormBoot.Label>
                      <Col sm="3">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.universityType} onChange={(value) => {
                          if (value == "Unlimited") {
                            this.setState({
                              university: 0
                            });
                          }
                          if (value == "PercentOfBasicSalary" || value == "PercentOfSchoolFree") {
                            this.setState({
                              maxUniversity: 100
                            });
                          } else {
                            this.setState({
                              maxUniversity: 0
                            });
                          }
                          this.setState({
                            universityType: value
                          });
                        }}>
                          <Option key={"PercentOfBasicSalary"} value={"PercentOfBasicSalary"}>{"Percent (%) of basic salary"}</Option>
                          <Option key={"MaximumAmount"} value={"MaximumAmount"}>{"Maximum amount"}</Option>
                          <Option key={"Unlimited"} value={"Unlimited"}>{"Unlimited"}</Option>
                          <Option key={"PercentOfSchoolFree"} value={"PercentOfSchoolFree"}>{"Percent (%) of school free"}</Option>
                        </Select>
                      </Col>
                      <Col sm="2" hidden={this.state.universityType == "Unlimited"}>
                        {(this.state.maxUniversity != null && this.state.maxUniversity != undefined && this.state.maxUniversity > 0) ?
                          <InputNumber min={0} max={this.state.maxUniversity} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ university: e })} value={this.state.university} />
                          :
                          <InputNumber min={0} style={{ width: '100%' }}
                            onChange={(e) => this.setState({ university: e })} value={this.state.university} />}
                      </Col>
                    </FormBoot.Group>
                  </div>
                </div>

                {this.state.ContractSettingsEditable.filter(p => p.type == "FamilyStatus").map((item, index) => (
                  <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    {item.id == 0 ?
                      <Col sm="3">
                        <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                              el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))
                        }} />
                      </Col> :
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        {item.textName}
                      </FormBoot.Label>
                    }

                    <Col sm="5">
                      <Input value={item.textField} style={{ width: "100%" }} onChange={(e) => {
                        e.persist();
                        this.setState(prevState => ({
                          ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                            el => el.index === item.index ? { ...el, textField: e.target.value } : el
                          )
                        }))
                      }} />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={() => this.removeItemFamilyStatus(item.index)} danger icon={<CloseOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>
                ))}

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="11"></Col>
                  <Col sm="1">
                    {/* {index==this.state.ContractSettingsEditable.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 && */}
                    <AntButton type="primary" onClick={this.addNewItemFamilyStatus} icon={<PlusOutlined />}></AntButton>
                    {/* } */}
                  </Col>
                </FormBoot.Group>

              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisAnnualVacation} onClick={this.addAnnualVacation} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisAnnualVacation} onClick={this.addAnnualVacation} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsAnnualVacation" defaultMessage="Annual Vacation" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisAnnualVacation}>

                <div>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsAnnualVacationPeriod" defaultMessage="Annual Vacation Period" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ annualVacationPeriod: e })} value={this.state.annualVacationPeriod} />
                    </Col>
                    <Col sm="3">
                      <Select mode="single" style={{ width: '100%' }} value={this.state.annualVacationPeriodType} onChange={(value) => {
                        this.setState({
                          annualVacationPeriodType: value
                        });
                      }}>
                        <Option key={"Mounth"} value={"Mounth"}>{"Mounth"}</Option>
                        <Option key={"Year"} value={"Year"}>{"Year"}</Option>
                      </Select>
                    </Col>
                  </FormBoot.Group>


                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsAnnualVacationDays" defaultMessage="Annual Vacation Days" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ annualVacationDays: e })} value={this.state.annualVacationDays} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsAnnaualVacationStartPeriod" defaultMessage="Annaual Vacation Start Period" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Select
                        name="annaualVacationStartPeriod"
                        style={{ width: '100%' }}
                        value={this.state.annaualVacationStartPeriod}
                        onChange={this.handleChangeAnnaualVacationStartPeriod}

                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                              <Input style={{ flex: 'auto' }} value={this.state.itemAnnaualVacationStartPeriods}
                                onChange={(event) => { this.setState({ itemAnnaualVacationStartPeriods: event.target.value }) }} />
                              <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                onClick={this.addAnnaualVacationStartPeriods} >
                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                            </div>
                          </div>
                        )}>
                        {this.state.AnnaualVacationStartPeriods.map(i => (
                          <Option key={i.id} value={i.id}>{i.name}</Option>
                        ))}
                      </Select>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsPaymentForNotUsedAnnualVacationDays" defaultMessage="Payment for not used Annual Vacation days" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                        checked={this.state.paymentForNotUsedAnnualVacationDays} defaultChecked onChange={(value) => {
                          this.setState({ paymentForNotUsedAnnualVacationDays: value, hiddenFamilyStatus: !value })
                        }} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsLeaveDaysCompensationsFromAnnualVacationDays" defaultMessage="Leave days compensations from annual vacation days" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />} unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                        checked={this.state.leaveDaysCompensationsFromAnnualVacationDays} defaultChecked onChange={(value) => {
                          this.setState({ leaveDaysCompensationsFromAnnualVacationDays: value, hiddenFamilyStatus: !value })
                        }} />
                    </Col>
                  </FormBoot.Group>

                </div>

                {this.state.ContractSettingsEditable.filter(p => p.type == "AnnualVacation").map((item, index) => (
                  <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    {item.id == 0 ?
                      <Col sm="3">
                        <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                              el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))
                        }} />
                      </Col> :
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        {item.textName}
                      </FormBoot.Label>
                    }

                    <Col sm="5">
                      <Input value={item.textField} style={{ width: "100%" }} onChange={(e) => {
                        e.persist();
                        this.setState(prevState => ({
                          ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                            el => el.index === item.index ? { ...el, textField: e.target.value } : el
                          )
                        }))
                      }} />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={() => this.removeItemAnnualVacation(item.index)} danger icon={<CloseOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>
                ))}

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="11"></Col>
                  <Col sm="1">
                    {/* {index==this.state.ContractSettingsEditable.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 && */}
                    <AntButton type="primary" onClick={this.addNewItemAnnualVacation} icon={<PlusOutlined />}></AntButton>
                    {/* } */}
                  </Col>
                </FormBoot.Group>

              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisFlights} onClick={this.addFlights} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisFlights} onClick={this.addFlights} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsFlights" defaultMessage="Flights" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisFlights}>

                <div>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsFlightFees" defaultMessage="Flight Fees" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Radio.Group onChange={(e) => {
                        this.setState({
                          flightFees: e.target.value,
                        });
                      }}
                        value={this.state.flightFees}>
                        <Radio value={"Company"}><FormattedMessage id="ContractSettingsCompany" defaultMessage="Company" /></Radio>
                        <Radio value={"Employee"}><FormattedMessage id="ContractSettingsEmployee" defaultMessage="Employee" /></Radio>
                      </Radio.Group>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsNumberOfFamilyMembers" defaultMessage="Number of Family members" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Checkbox checked={this.state.numberOfFamilyMembers} onChange={(e) => {
                        this.setState({
                          numberOfFamilyMembers: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsSpouse" defaultMessage="Spouse" /></Checkbox>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="5"></Col>
                    <Col sm="2">
                      <Checkbox checked={this.state.numberFamilyMembersChildren} onChange={(e) => {
                        this.setState({
                          numberFamilyMembersChildren: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsChildren" defaultMessage="Children" /></Checkbox>
                    </Col>
                    <Col sm="2" hidden={!this.state.numberFamilyMembersChildren}>
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ numberOfFamilyMembersChildren: e })} value={this.state.numberOfFamilyMembersChildren} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsFlightStatus" defaultMessage="Flight status" />
                    </FormBoot.Label>
                    <Col sm="4">
                      <Select mode="single" style={{ width: '100%' }} value={this.state.flightStatus} onChange={(value) => {
                        this.setState({
                          flightStatus: value
                        });
                      }}>
                        <Option key={"Economic"} value={"Economic"}>{"Economic"}</Option>
                        <Option key={"First Class"} value={"First Class"}>{"First Class"}</Option>
                        <Option key={"Business Class"} value={"Business Class"}>{"Business Class"}</Option>
                      </Select>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsFlightRoute" defaultMessage="Flight route" />
                    </FormBoot.Label>
                    <Col sm="4">
                      <Select mode="single" style={{ width: '100%' }} value={this.state.flightRoute} onChange={(value) => {
                        this.setState({
                          flightRoute: value
                        });
                      }}>
                        <Option key={"RoundTrip"} value={"RoundTrip"}>{"Round trip"}</Option>
                      </Select>
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsFamilyMembersFlightStatus" defaultMessage="Family member's Flight status" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Checkbox checked={this.state.familyMembersFlightStatus} onChange={(e) => {
                        this.setState({
                          familyMembersFlightStatus: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsSameAsEmployeesStatus" defaultMessage="Same as Employee's status" /></Checkbox>
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.familyMembersFlightStatus}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsFlightStatus" defaultMessage="Flight status" />
                      </FormBoot.Label>
                      <Col sm="4">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.flightStatusSameAsEmployee} onChange={(value) => {
                          this.setState({
                            flightStatusSameAsEmployee: value
                          });
                        }}>
                          <Option key={"Economic"} value={"Economic"}>{"Economic"}</Option>
                          <Option key={"First Class"} value={"First Class"}>{"First Class"}</Option>
                          <Option key={"Business Class"} value={"Business Class"}>{"Business Class"}</Option>
                        </Select>
                      </Col>
                    </FormBoot.Group>

                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsFlightRoute" defaultMessage="Flight route" />
                      </FormBoot.Label>
                      <Col sm="4">
                        <Select mode="single" style={{ width: '100%' }} value={this.state.flightRouteSameAsEmployee} onChange={(value) => {
                          this.setState({
                            flightRouteSameAsEmployee: value
                          });
                        }}>
                          <Option key={"RoundTrip"} value={"RoundTrip"}>{"Round trip"}</Option>
                        </Select>
                      </Col>
                    </FormBoot.Group>
                  </div>

                </div>

                {this.state.ContractSettingsEditable.filter(p => p.type == "Flights").map((item, index) => (
                  <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    {item.id == 0 ?
                      <Col sm="3">
                        <Input value={item.textName} style={{ width: "100%" }} onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                              el => el.index === item.index ? { ...el, textName: e.target.value } : el
                            )
                          }))
                        }} />
                      </Col> :
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        {item.textName}
                      </FormBoot.Label>
                    }

                    <Col sm="5">
                      <Input value={item.textField} style={{ width: "100%" }} onChange={(e) => {
                        e.persist();
                        this.setState(prevState => ({
                          ContractSettingsEditable: prevState.ContractSettingsEditable.map(
                            el => el.index === item.index ? { ...el, textField: e.target.value } : el
                          )
                        }))
                      }} />
                    </Col>
                    <Col sm="1"></Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={() => this.removeItemFlights(item.index)} danger icon={<CloseOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>
                ))}

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="11"></Col>
                  <Col sm="1">
                    {/* {index==this.state.ContractSettingsEditable.length - 1 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 && */}
                    <AntButton type="primary" onClick={this.addNewItemFlights} icon={<PlusOutlined />}></AntButton>
                    {/* } */}
                  </Col>
                </FormBoot.Group>
              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisOvertime} onClick={this.addOvertime} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisOvertime} onClick={this.addOvertime} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsOvertime" defaultMessage="Overtime" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisOvertime}>
                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                  <Col sm="2"></Col>
                  <FormBoot.Label style={{ color: 'black' }} column sm="3">
                    <FormattedMessage id="ContractSettingsOvertimeAllowed" defaultMessage="Overtime allowed" />
                  </FormBoot.Label>
                  <Col sm="3">
                    <Switch checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
                      unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                      checked={this.state.overtimeAllowed} defaultChecked onChange={(value) => {
                        if(value==false){
                          this.setState({
                            maximumOvertimeHoursDay: 0,
                            maximumOvertimeHoursWeek: 0,
                            maximumOvertimeHoursMonth: 0, 
                            maximumOvertimeHoursYear: 0,
                            holidayWorksCheck:true,
                            eidWorksCheck:true,
                            overtimeRate: 0,
                            normalDays: true,
                            holidays: true,
                            eidDays: true,
                            holidaysOvertimeRate:0,
                            eidsOvertimeRate:0,
                          });
                        }
                        this.setState({
                          overtimeAllowed: value,
                          holidayWorksCheck: true,
                          eidWorksCheck: true,
                          normalDays: true,
                          holidays: true,
                          eidDays: true,
                        });
                      }} />
                  </Col>
                </FormBoot.Group>
                <div hidden={!this.state.overtimeAllowed}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsMaximumOvertimeHoursDay" defaultMessage="Maximum overtime hours / day" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ maximumOvertimeHoursDay: e })}
                        value={this.state.maximumOvertimeHoursDay} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsMaximumOvertimeHoursWeek" defaultMessage="Maximum overtime hours / week" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ maximumOvertimeHoursWeek: e })}
                        value={this.state.maximumOvertimeHoursWeek} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsMaximumOvertimeHoursMonth" defaultMessage="Maximum overtime hours / month" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ maximumOvertimeHoursMonth: e })}
                        value={this.state.maximumOvertimeHoursMonth} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsMaximumOvertimeHoursYear" defaultMessage="Maximum overtime hours / year" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ maximumOvertimeHoursYear: e })}
                        value={this.state.maximumOvertimeHoursYear} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsHolidayWorks" defaultMessage="Holiday works" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Switch disabled={true} checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
                        unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                        checked={this.state.holidayWorksCheck} defaultChecked />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsEidWorks" defaultMessage="Eid works" />
                    </FormBoot.Label>
                    <Col sm="3">
                      <Switch disabled={true} checkedChildren={<FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />}
                        unCheckedChildren={<FormattedMessage id="GeneralButtonNo" defaultMessage="No" />}
                        checked={this.state.eidWorksCheck} defaultChecked />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsOvertimeRate" defaultMessage="Overtime rate (% of basic hourly wage)" />
                    </FormBoot.Label>
                    <Col sm="1">
                      <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ overtimeRate: e })}
                        value={this.state.overtimeRate} />
                    </Col>
                    <Col sm="2">
                      <Checkbox checked={this.state.normalDays} disabled={true}><FormattedMessage id="ContractSettingsNormalDays" defaultMessage="Normal Days" /></Checkbox>
                    </Col>
                    <Col sm="2">
                      <Checkbox checked={this.state.holidays} onChange={(e) => {
                        if(e.target.checked==false){
                          this.setState({
                            holidaysOvertimeRate: 0,
                          });
                        }
                       this.setState({
                          holidays: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsHolidays" defaultMessage="Holidays" /></Checkbox>
                    </Col>
                    <Col sm="2">
                      <Checkbox checked={this.state.eidDays} onChange={(e) => {
                        if(e.target.checked==false){
                          this.setState({
                            eidsOvertimeRate: 0,
                          });
                        }
                        this.setState({
                          eidDays: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsEiddays" defaultMessage="Eid days" /></Checkbox>
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.holidays}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsHolidaysOvertimeRate" defaultMessage="Holidays Overtime rate (% of basic hourly wage)" />
                      </FormBoot.Label>
                      <Col sm="2">
                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ holidaysOvertimeRate: e })}
                          value={this.state.holidaysOvertimeRate} />
                      </Col>
                    </FormBoot.Group>
                  </div>

                  <div hidden={this.state.eidDays}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="2"></Col>
                      <FormBoot.Label style={{ color: 'black' }} column sm="3">
                        <FormattedMessage id="ContractSettingsEidsOvertimeRate" defaultMessage="Eids Overtime rate (% of basic hourly wage)" />
                      </FormBoot.Label>
                      <Col sm="2">
                        <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ eidsOvertimeRate: e })}
                          value={this.state.eidsOvertimeRate} />
                      </Col>
                    </FormBoot.Group>
                  </div>
                </div>
              </div>

              <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="1"></Col>
                <Col sm="1">
                  <AntButton type="primary" hidden={!this.state.hiddenDataGirisBenefits} onClick={this.addBenefits} icon={<PlusOutlined />}></AntButton>
                  <AntButton type="primary" hidden={this.state.hiddenDataGirisBenefits} onClick={this.addBenefits} danger icon={<MinusOutlined />}></AntButton>
                </Col>
                <FormBoot.Label column sm="9" style={{ fontWeight: 'bold' }}>
                  <FormattedMessage id="ContractSettingsBenefits" defaultMessage="Benefits" />
                </FormBoot.Label>
              </FormBoot.Group>

              <div hidden={this.state.hiddenDataGirisBenefits}>

                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsEndOfService" defaultMessage="End of service" />
                    </FormBoot.Label>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsVoluntary" defaultMessage="Voluntary" />
                    </FormBoot.Label>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsWorkingYears" defaultMessage="Working years" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }} disabled={this.state.disabledWorkingYearsMinVoluntary}
                        onChange={(e) => this.setState({ workingYearsMinVoluntary: e })} value={this.state.workingYearsMinVoluntary} />
                    </Col>
                    <Col sm="1">to</Col>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ workingYearsMaxVoluntary: e })} value={this.state.workingYearsMaxVoluntary} />
                    </Col>
                    <Col sm="1">
                      <Switch checkedChildren="+" unCheckedChildren="-"
                        checked={this.state.workingYearsMaxDahilVoluntary} defaultChecked onChange={(value) => {
                          this.setState({ workingYearsMaxDahilVoluntary: value })
                        }} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsPercentOfBasicSalaryForEachYear" defaultMessage="Percent of basic salary for each year" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} max={100} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ percentOfBasicSalaryForEachYearVoluntary: e })} value={this.state.percentOfBasicSalaryForEachYearVoluntary} />
                    </Col>
                    <Col sm="3">
                      <Checkbox checked={this.state.calculationWillAddedTotalVoluntary} onChange={(e) => {
                        this.setState({
                          calculationWillAddedTotalVoluntary: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsCalculationWillAddedTotal" defaultMessage="This section calculation will be added on previous total" /></Checkbox>
                    </Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={this.addNewItemEndOfServiceVoluntary} icon={<PlusOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>

                  {(this.state.ContractSettingsEndOfServices != null && this.state.ContractSettingsEndOfServices != undefined && this.state.ContractSettingsEndOfServices.length > 0) &&
                    this.state.ContractSettingsEndOfServices.filter(p => p.type == "Voluntary").map((item, index) => (
                      <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="3"></Col>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} value={item.workingYearsMin} onChange={(e) => {
                            this.setState(prevState => ({
                              ContractSettingsEndOfServices: prevState.ContractSettingsEndOfServices.map(
                                el => el.index === item.index ? { ...el, workingYearsMin: e } : el
                              )
                            }))
                          }} />
                        </Col>
                        <Col sm="1">
                          to
                    </Col>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} value={item.workingYearsMax} onChange={(e) => {
                            this.setState(prevState => ({
                              ContractSettingsEndOfServices: prevState.ContractSettingsEndOfServices.map(
                                el => el.index === item.index ? { ...el, workingYearsMax: e } : el
                              )
                            }))
                          }} />
                        </Col>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} value={item.percentOfBasicSalaryForEachYear} onChange={(e) => {
                            this.setState(prevState => ({
                              ContractSettingsEndOfServices: prevState.ContractSettingsEndOfServices.map(
                                el => el.index === item.index ? { ...el, percentOfBasicSalaryForEachYear: e } : el
                              )
                            }))
                          }} />
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="1">
                          <AntButton type="primary" onClick={() => this.removeItemEndOfServiceVoluntary(item.index)} danger icon={<CloseOutlined />}></AntButton>
                        </Col>
                      </FormBoot.Group>
                    ))}
                </div>
                <br />
                <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsInvoluntary" defaultMessage="Involuntary" />
                    </FormBoot.Label>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsWorkingYears" defaultMessage="Working years" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }} disabled={this.state.disabledWorkingYearsMinInvoluntary}
                        onChange={(e) => this.setState({ workingYearsMinInvoluntary: e })} value={this.state.workingYearsMinInvoluntary} />
                    </Col>
                    <Col sm="1">to</Col>
                    <Col sm="2">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ workingYearsMaxInvoluntary: e })} value={this.state.workingYearsMaxInvoluntary} />
                    </Col>
                    <Col sm="1">
                      <Switch checkedChildren="+" unCheckedChildren="-"
                        checked={this.state.workingYearsMaxDahilInvoluntary} defaultChecked onChange={(value) => {
                          this.setState({ workingYearsMaxDahilInvoluntary: value })
                        }} />
                    </Col>
                  </FormBoot.Group>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <FormBoot.Label style={{ color: 'black' }} column sm="3">
                      <FormattedMessage id="ContractSettingsPercentOfBasicSalaryForEachYear" defaultMessage="Percent of basic salary for each year" />
                    </FormBoot.Label>
                    <Col sm="2">
                      <InputNumber min={0} max={100} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ percentOfBasicSalaryForEachYearInvoluntary: e })} value={this.state.percentOfBasicSalaryForEachYearInvoluntary} />
                    </Col>
                    <Col sm="3">
                      <Checkbox checked={this.state.calculationWillAddedTotalInvoluntary} onChange={(e) => {
                        this.setState({
                          calculationWillAddedTotalInvoluntary: e.target.checked,
                        });
                      }}><FormattedMessage id="ContractSettingsCalculationWillAddedTotal" defaultMessage="This section calculation will be added on previous total" /></Checkbox>
                    </Col>
                    <Col sm="1">
                      <AntButton type="primary" onClick={this.addNewItemEndOfServiceInvoluntary} icon={<PlusOutlined />}></AntButton>
                    </Col>
                  </FormBoot.Group>


                  {(this.state.ContractSettingsEndOfServices != null && this.state.ContractSettingsEndOfServices != undefined && this.state.ContractSettingsEndOfServices.length > 0) &&
                    this.state.ContractSettingsEndOfServices.filter(p => p.type == "Involuntary").map((item, index) => (
                      <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="3"></Col>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} value={item.workingYearsMin} onChange={(e) => {
                            this.setState(prevState => ({
                              ContractSettingsEndOfServices: prevState.ContractSettingsEndOfServices.map(
                                el => el.index === item.index ? { ...el, workingYearsMin: e } : el
                              )
                            }))
                          }} />
                        </Col>
                        <Col sm="1">
                          to
                    </Col>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} value={item.workingYearsMax} onChange={(e) => {
                            this.setState(prevState => ({
                              ContractSettingsEndOfServices: prevState.ContractSettingsEndOfServices.map(
                                el => el.index === item.index ? { ...el, workingYearsMax: e } : el
                              )
                            }))
                          }} />
                        </Col>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} value={item.percentOfBasicSalaryForEachYear} onChange={(e) => {
                            this.setState(prevState => ({
                              ContractSettingsEndOfServices: prevState.ContractSettingsEndOfServices.map(
                                el => el.index === item.index ? { ...el, percentOfBasicSalaryForEachYear: e } : el
                              )
                            }))
                          }} />
                        </Col>
                        <Col sm="1"></Col>
                        <Col sm="1">
                          <AntButton type="primary" onClick={() => this.removeItemEndOfServiceInvoluntary(item.index)} danger icon={<CloseOutlined />}></AntButton>
                        </Col>
                      </FormBoot.Group>
                    ))}
                </div>
              </div>
              <br />
              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="4">
                    </Col>
                    <Col sm="4">
                      <Button id="ContractSettingsSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="4"></Col>
                    <Col sm="2">
                      <Button id="ContractSettingsCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button id="ContractSettingsUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.contractSettingsTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>}

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteContractSettingsOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.percentModal}
          onHide={this.showOrHideModalPercent}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          size="md">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="ContractSettingsMessage1" defaultMessage="Do you want increase all (Period, rev. no) amount in a same percent to be applicale for your new span of period?" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPercent}>
              <FormattedMessage id="GeneralTextNo" defaultMessage="No" />
            </Button>
            <Button variant="danger" onClick={this.showOrHideModalPercentGiris}>
              <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.percentModalGiris}
          onHide={this.showOrHideModalPercentGiris}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          size="xs">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputNumber min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')}
              style={{ width: '100%' }} onChange={(e) => this.setState({ percent: e })} value={this.state.percent} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPercentGiris}>
              <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="danger" onClick={this.showOrHideModalPercentGirisOK}>
              <FormattedMessage id="GeneralButtonOk" defaultMessage="Ok" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSettings)