/* eslint-disable */

import React, { Component } from 'react';
import { Form, Row, Col, Button, Jumbotron, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import HorizontalTimeline from "react-horizontal-timeline";
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Upload, message, Button as AntButton } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import $ from 'jquery';

export class CheckPoint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            shipmentLogs: [],
            modalShow: false,
            modalPhotoShow: false,
            modalShowTicketNumber: false,
            color: "#7B9D6F",
            trailer: "",
            trailerId: null,
            driver: "",
            driverId: null,
            capacity: "",
            capacityType: "",
            volume: "",
            invoiceNo: "",
            ticketNo: "",
            ticketPhoto: "",
            imageUrl: "",
            curIdx: 0,
            prevIdx: -1,
            customer: "",
            project: "",
            projectId: null,
            productId: null,
            customerId: null,
            customerList: [],
            displayButton: true,
            ticketNumbers: []
        };
    }

    showOrHideModalTicketNumber = () => {
        this.setState({
            modalShowTicketNumber: !this.state.modalShowTicketNumber
        });
    }

    componentDidMount = async () => {
        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data
            });
        }

        if (this.props.auth.user.staff !== null) {
            const responseAssignment = await handleRequest("GET", "/Assignment/getEmployee/" + this.props.auth.user.staffId);

            if (responseAssignment.data.length !== 0) {
                const columns = responseAssignment.data;
                if (columns.staffId === this.props.auth.user.staffId) {
                    if (columns.equipmentLog !== null && columns.equipmentLog.length !== 0) {
                        if (columns.equipmentLog.equipmentVehicleLicenceList !== null && columns.equipmentLog.equipmentVehicleLicenceList.length !== 0
                            && columns.customerProject !== null && columns.customerProject.length !== 0
                            && columns.customerLogId !== null && columns.productId !== null) {

                            let customerList1 = this.state.customerList;

                            let customerIndex = customerList1.findIndex(p => p.id === columns.customerLogId);

                            if (customerList1[customerIndex] !== undefined && customerList1[customerIndex] !== null) {
                                this.setState({
                                    customer: customerList1[customerIndex].fullName
                                });
                            }

                            //TODO: if'e product kontrolü eklenmeli.
                            this.setState({
                                driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
                                driverId: this.props.auth.user.staffId,
                                trailer: columns.equipmentLog.equipmentCode + ", " + columns.equipmentLog.equipmentVehicleLicenceList[0].plateNo,
                                trailerId: columns.equipmentLog.id,
                                projectId: columns.customerProjectId,
                                project: columns.customerProject.projectName,
                                customerId: columns.customerLogId,
                                productId: columns.productId
                            });

                            if (columns.equipmentLog.equipmentOperatingList !== null) {
                                this.setState({
                                    capacity: columns.equipmentLog.equipmentOperatingList[0].capacity,
                                    volume: columns.equipmentLog.equipmentOperatingList[0].capacity,
                                    capacityType: columns.equipmentLog.equipmentOperatingList[0].capacityTypeCode.name,
                                });
                            }
                            else {
                                this.setState({
                                    capacity: "",
                                    volume: "",
                                    capacityType: ""
                                });
                            }
                        }
                        else {
                            this.setState({
                                driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
                                driverId: this.props.auth.user.staffId,
                                trailer: columns.equipmentLog.equipmentCode,
                                trailerId: columns.equipmentLog.id
                            });
                        }
                    }
                }
            }

            //Hızlandırma çalışmasından öncesi;

            // if (this.props.auth.user.staff !== null) {
            //     const response2 = await handleRequest("GET", "/Assignment/getAll");

            //     if (response2.data.length !== 0) {

            //         const columns = response2.data;

            //         for (let index = 0; index < columns.length; index++) {
            //             if (columns[index].staffId === this.props.auth.user.staffId) {
            //                 if (columns[index].equipmentLog !== null && columns[index].equipmentLog.length !== 0) {
            //                     if (columns[index].equipmentLog.equipmentVehicleLicenceList !== null && columns[index].equipmentLog.equipmentVehicleLicenceList.length !== 0
            //                         && columns[index].customerProject !== null && columns[index].customerProject.length !== 0
            //                         && columns[index].customerLogId !== null && columns[index].productId !== null) {

            //                         let customerList1 = this.state.customerList;

            //                         let customerIndex = customerList1.findIndex(p => p.id === columns[index].customerLogId);

            //                         if (index >= 0 && customerList1[customerIndex] !== undefined && customerList1[customerIndex] !== null) {
            //                             this.setState({
            //                                 customer: customerList1[customerIndex].fullName
            //                             });
            //                         }

            //                         //TODO: if'e product kontrolü eklenmeli.
            //                         this.setState({
            //                             driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
            //                             driverId: this.props.auth.user.staffId,
            //                             trailer: columns[index].equipmentLog.equipmentCode + ", " + columns[index].equipmentLog.equipmentVehicleLicenceList[0].plateNo,
            //                             trailerId: columns[index].equipmentLog.id,
            //                             projectId: columns[index].customerProjectId,
            //                             project: columns[index].customerProject.projectName,
            //                             customerId: columns[index].customerLogId,
            //                             productId: columns[index].productId
            //                         });
            //                         if (columns[index].equipmentLog.equipmentOperatingList !== null) {
            //                             this.setState({
            //                                 capacity: columns[index].equipmentLog.equipmentOperatingList[0].capacity,
            //                                 volume: columns[index].equipmentLog.equipmentOperatingList[0].capacity,
            //                                 capacityType: columns[index].equipmentLog.equipmentOperatingList[0].capacityTypeCode.name,
            //                             });
            //                         }
            //                         else {
            //                             this.setState({
            //                                 capacity: "",
            //                                 volume: "",
            //                                 capacityType: ""
            //                             });
            //                         }
            //                     }
            //                     else {
            //                         this.setState({
            //                             driver: this.props.auth.user.staff.name + " " + this.props.auth.user.staff.lastName,
            //                             driverId: this.props.auth.user.staffId,
            //                             trailer: columns[index].equipmentLog.equipmentCode,
            //                             trailerId: columns[index].equipmentLog.id
            //                         });
            //                     }
            //                 }
            //             }
            //         }
            //     }

            const response = await handleRequest("GET", "/Shipment/getToday");

            var driverShipmentData = [];

            if (response.data.length === 0) {
                this.setState({
                    shipmentLogs: [],
                    displayButton: true
                });
            }

            if (response.data.length !== 0) {

                let _ticketNumbers = [];

                for (let index = 0; index < response.data.length; index++) {
                    if ((this.props.auth.user.staffId === response.data[index].staffId)) {
                        driverShipmentData.push(response.data[index]);
                    }
                    _ticketNumbers.push(response.data[index].ticketNo);
                }

                this.setState({
                    ticketNumbers: _ticketNumbers,
                    shipmentLogs: driverShipmentData
                });
            }

            if (driverShipmentData.length !== 0) {
                if (driverShipmentData[driverShipmentData.length - 1].checkStatus === true) {
                    var _imageUrl = "";
                    var row = driverShipmentData[driverShipmentData.length - 1];

                    if (row.invoicePhotoPath !== null && row.invoicePhotoPath !== undefined && row.invoicePhotoPath.length > 0) {
                        _imageUrl = `${API_BASE}/Shipment/download/` + row.id
                    }

                    this.setState({
                        color: "#7B9D6F",
                        imageUrl: _imageUrl,
                        curIdx: driverShipmentData.length - 1,
                        prevIdx: driverShipmentData.length - 2,
                        invoiceNo: driverShipmentData[driverShipmentData.length - 1].invoiceNo,
                        displayButton: true
                    });
                }
                else {
                    var _imageUrl = "";
                    var row = driverShipmentData[driverShipmentData.length - 1];

                    if (row.invoicePhotoPath !== null && row.invoicePhotoPath !== undefined && row.invoicePhotoPath.length > 0) {
                        _imageUrl = `${API_BASE}/Shipment/download/` + row.id
                    }

                    this.setState({
                        color: "#1A79AD",
                        imageUrl: _imageUrl,
                        curIdx: driverShipmentData.length - 1,
                        prevIdx: driverShipmentData.length - 2,
                        invoiceNo: driverShipmentData[driverShipmentData.length - 1].invoiceNo,
                        displayButton: false
                    });
                }
            }
        }
    }

    checkTrip = async () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (!isNaN(this.state.volume) && this.state.volume !== "" && this.state.ticketNo !== "" && this.state.ticketPhoto !== "") {

            if (!(this.state.ticketNumbers.includes(this.state.ticketNo))) {
                this.setState({
                    color: "#7B9D6F",
                    curIdx: this.state.shipmentLogs.length - 1,
                    prevIdx: this.state.shipmentLogs.length - 2
                });

                const checkedTrip = {
                    id: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].id,
                    flagData: "2000-02-17",
                    projectId: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].projectId,
                    equipmentLogId: this.state.trailerId,
                    staffId: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].staffId,
                    volume: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].volume,
                    invoiceNo: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].invoiceNo,
                    invoicePhotoPath: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].invoicePhotoPath,
                    company: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].company,
                    checkVolume: this.state.volume,
                    ticketNo: this.state.ticketNo,
                    ticketPhotoPath: this.state.ticketPhoto,
                    checkStatus: true,
                    shipmentDay: today,
                    confirmation: "Under Evaluation",
                    customer: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].customer,
                    project: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].project,
                    product: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].product,
                    voucherPdfPath: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].voucherPdfPath,
                    truckWeight: this.state.shipmentLogs[this.state.shipmentLogs.length - 1].truckWeight
                };

                await handleRequest("PUT", `/Shipment/update`, checkedTrip);

                this.restartForm();
            } else {
                this.showOrHideModalTicketNumber();
            }
        }
        else {
            this.showOrHideModal();
        }
    }

    restartForm = async () => {

        this.setState({
            volume: "",
            ticketNo: "",
            ticketPhoto: "",
            imageUrl: "",
            //ticketPhotoName: "Attach Ticket Photo"
        });

        const response = await handleRequest("GET", "/Shipment/getToday");

        var driverShipmentData = [];

        if (response.data.length === 0) {
            this.setState({
                shipmentLogs: [],
                displayButton: true
            });
        }

        if (response.data.length !== 0) {

            let _ticketNumbers = [];

            for (let index = 0; index < response.data.length; index++) {
                if ((this.props.auth.user.staffId === response.data[index].staffId)) {
                    driverShipmentData.push(response.data[index]);
                }
                _ticketNumbers.push(response.data[index].invoiceNo);
            }

            this.setState({
                ticketNumbers: _ticketNumbers,
                shipmentLogs: driverShipmentData.reverse()
            });
        }

        if (driverShipmentData.length !== 0) {
            if (driverShipmentData[driverShipmentData.length - 1].checkStatus === true) {
                this.setState({
                    color: "#7B9D6F",
                    curIdx: driverShipmentData.length - 1,
                    prevIdx: driverShipmentData.length - 2,
                    invoiceNo: driverShipmentData[driverShipmentData.length - 1].invoiceNo,
                    displayButton: true
                });
            }
            else {
                this.setState({
                    color: "#1A79AD",
                    curIdx: driverShipmentData.length - 1,
                    prevIdx: driverShipmentData.length - 2,
                    invoiceNo: driverShipmentData[driverShipmentData.length - 1].invoiceNo,
                    displayButton: false
                });
            }
        }
        $(".ant-upload-list").empty();
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isJpgOrPng && isLt2M;
    }

    transformFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const canvas = document.createElement('canvas');
                const img = document.createElement('img');
                img.src = reader.result;
                img.onload = () => {
                    let width = img.width
                    let height = img.height

                    canvas.width = width
                    canvas.height = height

                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    canvas.toBlob(resolve, 'image/jpeg', 0.5);
                };
            }
        });
    }

    handlePhotoChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file)

            if (info.file.response.length > 0) {
                this.setState({
                    ticketPhoto: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    render() {
        const stateConst = this.state;
        let curStatus;
        let timeline;

        if (stateConst.shipmentLogs.length !== 0) {
            var s = ["th", "st", "nd", "rd"], v = (stateConst.shipmentLogs.length) % 100;
            var which = (stateConst.shipmentLogs.length) + (s[(v - 20) % 10] || s[v] || s[0]) + " trip"

            curStatus = which;
            timeline = <div
                style={{
                    width: "90%",
                    height: "100px",
                    margin: "0 auto",
                    marginTop: "10px",
                    fontSize: "15px"
                }}
            >
                <HorizontalTimeline
                    styles={{
                        background: "#E9ECEF",
                        foreground: this.state.color,
                        outline: "#dfdfdf"
                    }}
                    isOpenEnding={false}
                    isOpenBeginning={false}
                    index={this.state.curIdx}
                    labelWidth={170}
                    getLabel={function (date, index) {
                        var s = ["th", "st", "nd", "rd"],
                            v = (index + 1) % 100;

                        var total = 0;
                        var total1 = 0;

                        for (var i = 0; i <= index; i++) {
                            total += parseInt(stateConst.shipmentLogs[i].checkVolume);
                        }

                        total1 = total + parseInt(stateConst.shipmentLogs[stateConst.shipmentLogs.length - 1].volume);

                        //return (index + 1) + (s[(v - 20) % 10] || s[v] || s[0]) + " trip (" + stateConst.shipmentLogs[index].volume + "m3 / " + total + "m3)";

                        if ((index === (stateConst.shipmentLogs.length - 1)) && stateConst.shipmentLogs[stateConst.shipmentLogs.length - 1].checkVolume === 0)
                            return <div style={{ color: "gray" }}> {(index + 1) + (s[(v - 20) % 10] || s[v] || s[0]) + " trip (" + stateConst.shipmentLogs[index].volume + "m3 / " + total1 + "m3)"}</div>;
                        else
                            return <div style={{ color: "black" }}> {(index + 1) + (s[(v - 20) % 10] || s[v] || s[0]) + " trip (" + stateConst.shipmentLogs[index].checkVolume + "m3 / " + total + "m3)"}</div>;

                    }}
                    indexClick={index => {
                        const curIdx = this.state.curIdx;
                        var _imageUrl = `${API_BASE}/Shipment/download/` + stateConst.shipmentLogs[index].id

                        this.setState({ curIdx: index, prevIdx: curIdx, invoiceNo: stateConst.shipmentLogs[index].invoiceNo, imageUrl: _imageUrl });
                    }}
                    values={this.state.shipmentLogs.map(x => x.flagData)}
                />
            </div>;
        } else {
            timeline = <h3 style={{ width: "100%", textAlign: "center", marginBottom: "30px", marginTop: "30px" }}>No new trip added yet.</h3>;
        }
        return (
            <div>
                <div>
                    <Modal
                        show={this.state.modalShow}
                        onHide={this.showOrHideModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">There are missing trip information values.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Can you check all the values you entered?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalPhotoShow}
                        onHide={this.showOrHidePhotoModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Invoice Photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(this.state.imageUrl !== "") ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : <p>Invoice photo has not been uploaded.</p>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHidePhotoModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowTicketNumber}
                        onHide={this.showOrHideModalTicketNumber}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">This ticket number has been used before</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Please change the ticket number.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalTicketNumber}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Jumbotron style={{ padding: '20px' }}>
                        <Form.Group style={{ marginTop: "1rem" }} as={Row} >
                            <Col sm="4">
                            </Col>
                            <Col sm="4" style={{ textAlign: 'center' }}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.customer}, {this.state.project}</Form.Label>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: "1rem" }} as={Row} >
                            <Col sm="4">
                            </Col>
                            <Col sm="4" style={{ textAlign: 'center' }}>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} >{this.state.trailer + ", " + this.state.capacity + " " + this.state.capacityType}</Form.Label>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </Jumbotron>
                    <Jumbotron style={{ padding: '20px' }}>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Invoice No:
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control readOnly style={{ color: '#7F8FA4' }} name="invoiceNo" value={this.state.invoiceNo}
                                    onChange={this.handleChange} />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: "1rem" }} as={Row} >
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <Button style={{ width: '100%' }} variant="info" onClick={this.showOrHidePhotoModal}>Loaded Photo</Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>

                        {timeline}

                        <Form.Group style={{ marginTop: "1rem" }} as={Row} >
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <h4 style={{ textAlign: 'center', color: 'orange' }}>{curStatus}</h4>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Volume:
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control name="volume" value={this.state.volume}
                                    onChange={this.handleChange} />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Ticket No:
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control name="ticketNo" value={this.state.ticketNo}
                                    onChange={this.handleChange} />
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                Invoice Photo:
                            </Form.Label>
                            <Col sm="3">
                                <Upload
                                    name="avatar"
                                    className="avatar-uploader"
                                    action={`${API_BASE}/Shipment/upload`}
                                    beforeUpload={this.beforeUpload}
                                    transformFile={this.transformFile}
                                    onChange={this.handlePhotoChange}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Attach Ticket Photo
                                    </AntButton>
                                </Upload>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: "2rem" }} as={Row} >
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <Button id='check-trip' hidden={this.state.displayButton} style={{ width: '100%' }} onClick={this.checkTrip} variant="info" >Save</Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </Jumbotron>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckPoint)
