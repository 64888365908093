/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';

export class OrganizationLevel extends Component {

    constructor() {
        super();
        this.state = {
            modalShow: false,
            modalDeleteShow: false,
            newOrgLevel: "",
            hiddenCreateNew: true,
            hiddenSave: false,
            hiddenUpdate: true,
            roleGroup: "Select Role Group",
            roleGroupId: null,
            name: "Select Name",
            budgeNo: "Select Budge No",
            budgeNoId: null,
            designation: "Select Designation",
            designationId: null,
            id: null,
            organizationalLevel: "Select Organizational Level",
            organizationalLevelId: null,
            selectedOrganizationalLevel: null,
            organizationalLevels: [],
            roleGroups: [],
            names: [
                { name: 'Andrew Baldwin', id: '1' },
                { name: 'Phyllis Hale', id: '2' },
                { name: 'Darrell Jimenez', id: '3' },
                { name: 'Lorraine Allison', id: '4' },
                { name: 'Ismael Frazier', id: '5' }
            ],
            budgeNos: [
                { name: '1234', id: '1' },
                { name: '5678', id: '2' },
                { name: '1245', id: '3' },
                { name: '3241', id: '4' },
                { name: '5643', id: '5' }
            ],
            designations: [],
            organizationalLevelDrops: [],

            
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,           
            modalShowRevisions: false,
            revisionDatas: [],          
            howToUseSetting: false,
            whatIsSetting: false,
            
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            formId: null
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
     
    }

    formRef = React.createRef();

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage={msg} />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow,
            newOrgLevel: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    addNewOrgLevel = async () => {

        const newOrgLevelDrop = {
            name: this.state.newOrgLevel,
            type: "1"
        }

        await handleRequest("POST", "/Code/add", newOrgLevelDrop);

        const response3 = await handleRequest("GET", "/Code/getallByCompany");

        if (response3.data.length !== 0) {

            const columns = response3.data;

            var options6 = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "1") {
                    options6.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                organizationalLevelDrops: options6
            });
        }

        this.showOrHideModal();
    }

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    // renderFooter(name) {
    //     return (
    //         <div>
    //             <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteOrganizationalLevel(name)} className="p-button-danger" />
    //             <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
    //         </div>
    //     );
    // }

    actionTemplate(rowData, column) {
        // return <React.Fragment>
        //     <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>
        //     <Dialog header="Delete This Organizational Level Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
        //         <p>This organizational level record will be deleted. Are you sure?</p>
        //     </Dialog>
        //     <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>
        // </React.Fragment>;

        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.deleteModal.bind(this)} />
            </React.Fragment>;
        }
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            selectedOrganizationalLevel: row
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    delete = async () => {
        const deletedOrganizationalLevel = {
            id: this.state.selectedOrganizationalLevel.id
        }

        await handleRequest("POST", "/OrganizationLevel/delete", deletedOrganizationalLevel);

        this.restartTable();
        this.showSuccess("Data has been deleted successfully.");
        this.showOrHideDeleteModal();
    }

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin:  this.props.auth.user.userType===2 ? true:false
                });
               /* var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }*/
            }

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "OrganizationalLevel" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "OrganizationalLevel",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "OrganizationalLevel");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        const response = await handleRequest("GET", "/OrganizationLevel/getallByCompany");

        if (response.data.length !== 0) {
            this.setState({
                organizationalLevels: response.data,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                organizationalLevels: [],
            });
        }

        const response2 = await handleRequest("GET", "/RoleGroup/getallByCompany");

        if (response2.data.length !== 0) {
            const columns2 = response2.data;
            const options2 = columns2.map(function (row) {
                return { name: row.roleName.toString(), id: row.id.toString() }
            })

            this.setState({
                roleGroups: options2,
            });
        }

        const response3 = await handleRequest("GET", "/Code/getallByCompany");

        console.log(response3.data);

        if (response3.data.length !== 0) {
            const columns = response3.data;
            var options5 = [];
            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "1") {
                    options5.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                organizationalLevelDrops: options5
            });
        }

        const response4 = await handleRequest("GET", "/Designation/getallByCompany");

        if (response4.data.length !== 0) {
            const columns4 = response4.data;
            const options4 = columns4.map(function (row) {
                return { name: row.designationName.toString(), id: row.id.toString() }
            });

            this.setState({
                designations: options4,
            });
        }
    }

   

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(); 
        } else {
            this.setState({
                formId: null
            });
        }
    }

    saveOrganizationalLevel = async () => {

        // if (this.state.roleGroup !== "Select Role Group" && this.state.name !== "" && this.state.budgeNo !== "" 
        //     && this.state.designation !== "Select Designation" && this.state.organizationalLevel !== "Select Organizational Level")

        if (this.state.roleGroup !== "Select Role Group" && this.state.designation !== "Select Designation" && this.state.organizationalLevel !== "Select Organizational Level") {

            const newOrganizationalLevel = {
                designationId: this.state.designationId,
                organizationLevelId: this.state.organizationalLevelId,
                personelId: 0,
                roleId: this.state.roleGroupId
            }

            await handleRequest("POST", "/OrganizationLevel/add", newOrganizationalLevel);

            this.changeButtonSave();
            this.restartTable();
        } else {
            this.showError();
        }
    }

    restartTable = async () => {

        const response = await handleRequest("GET", "/OrganizationLevel/getallByCompany");

        if (response.data.length !== 0) {
            this.setState({
                organizationalLevels: response.data,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                organizationalLevels: [],
            });
        }

        const response2 = await handleRequest("GET", "/RoleGroup/getallByCompany");

        if (response2.data.length !== 0) {

            const columns2 = response2.data;

            const options2 = columns2.map(function (row) {
                return { name: row.roleName.toString(), id: row.id.toString() }
            })

            this.setState({
                roleGroups: options2,
            });
        }


        const response3 = await handleRequest("GET", "/Code/getallByCompany");

        if (response3.data.length !== 0) {

            const columns = response3.data;

            var options7 = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "1") {
                    options7.push({ name: columns[index].name.toString(), id: columns[index].id.toString() });
                }
            }

            this.setState({
                organizationalLevelDrops: options7
            });
        }

        const response4 = await handleRequest("GET", "/Designation/getallByCompany");

        if (response4.data.length !== 0) {

            const columns4 = response4.data;

            const options4 = columns4.map(function (row) {
                return { name: row.designationName.toString(), id: row.id.toString() }
            })

            this.setState({
                designations: options4,
            });
        }
    }

    deleteOrganizationalLevel = async (name) => {

        const deletedOrganizationalLevel = {
            id: this.state.selectedOrganizationalLevel.id
        }

        await handleRequest("POST", "/OrganizationLevel/delete", deletedOrganizationalLevel);

        this.restartTable();
        this.showSuccess("Data has been deleted successfully.");
        this.onHideModal(name);
    }

    createNew = () => {
        if (this.state.hiddenCreateNew) {
            this.setState({
                hiddenCreateNew: false
            });
        }
        else {
            this.setState({
                hiddenCreateNew: true,
                newOrgLevel: "",
                hiddenSave: false,
                hiddenUpdate: true,
                id: null,
                roleGroup: "Select Role Group",
                roleGroupId: null,
                name: "Select Name",
                budgeNo: "Select Budge No",
                budgeNoId: null,
                designation: "Select Designation",
                designationId: null,
                organizationalLevel: "Select Organizational Level",
                organizationalLevelId: null
            });
        }
    }

    changeButtonUpdate = (rowData) => {
        this.setState({
            hiddenUpdate: false,
            hiddenCreateNew: false,
            hiddenSave: true,
            roleGroup: rowData.roleGroup.roleName,
            roleGroupId: rowData.roleGroup.id,
            name: "Select Name",
            budgeNo: "Select Budge No",
            designation: rowData.designation.designationName,
            designationId: rowData.designation.id,
            id: rowData.id,
            organizationalLevel: rowData.code.name,
            organizationalLevelId: rowData.code.id
        });
    }

    changeButtonSave = () => {
        this.setState({
            newOrgLevel: "",
            hiddenSave: false,
            hiddenUpdate: true,
            id: null,
            roleGroup: "Select Role Group",
            roleGroupId: null,
            name: "Select Name",
            budgeNo: "Select Budge No",
            budgeNoId: null,
            designation: "Select Designation",
            designationId: null,
            organizationalLevel: "Select Organizational Level",
            organizationalLevelId: null
        });
        this.showSuccess("All data has been saved successfully.");
    }

    changeButtonCancel = () => {
        this.setState({
            newOrgLevel: "",
            hiddenCreateNew: true,
            hiddenSave: false,
            hiddenUpdate: true,
            id: null,
            roleGroup: "Select Role Group",
            roleGroupId: null,
            name: "Select Name",
            budgeNo: "Select Budge No",
            budgeNoId: null,
            designation: "Select Designation",
            designationId: null,
            organizationalLevel: "Select Organizational Level",
            organizationalLevelId: null
        });
        //this.showError();
    }

    updateDone = async () => {

        if (this.state.roleGroup !== "Select Role Group" && this.state.designation !== "Select Designation" && this.state.organizationalLevel !== "Select Organizational Level") {
            const updatedOrganizationalLevel = {
                id: this.state.id,
                designationId: this.state.designationId,
                organizationLevelId: this.state.organizationalLevelId,
                personelId: 0,
                roleId: this.state.roleGroupId
            }
            await handleRequest("POST", "/OrganizationLevel/update", updatedOrganizationalLevel);
        }

        this.restartTable();
        this.setState({
            newOrgLevel: "",
            hiddenSave: false,
            hiddenUpdate: true,
            id: null,
            roleGroup: "Select Role Group",
            roleGroupId: null,
            name: "Select Name",
            budgeNo: "Select Budge No",
            budgeNoId: null,
            designation: "Select Designation",
            designationId: null,
            organizationalLevel: "Select Organizational Level",
            organizationalLevelId: null
        });
        this.showSuccess("All data has been saved successfully.");
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChange1 = e => {
        e.preventDefault();
        var roleGroupIdValue;
        for (var z = 0; z < this.state.roleGroups.length; z++) {
            if (this.state.roleGroups[z].name === e.target.value) {
                roleGroupIdValue = this.state.roleGroups[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            roleGroupId: roleGroupIdValue
        });
    }

    handleChange2 = e => {
        e.preventDefault();
        var designationIdValue;
        for (var z = 0; z < this.state.designations.length; z++) {
            if (this.state.designations[z].name === e.target.value) {
                designationIdValue = this.state.designations[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            designationId: designationIdValue
        });
    }

    handleChange3 = e => {
        e.preventDefault();
        var organizationLevelIdValue;
        for (var z = 0; z < this.state.organizationalLevelDrops.length; z++) {
            if (this.state.organizationalLevelDrops[z].name === e.target.value) {
                organizationLevelIdValue = this.state.organizationalLevelDrops[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            organizationalLevelId: organizationLevelIdValue
        });
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

   


   
    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
             
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    

    render() {
console.log( this.state.dynamicInputs); 
        var itemRoleGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OrganizationalLevelRoleGroup");
        var itemDesignationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OrganizationalLevelDesignation");
        var itemOrganizationalLevelAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "OrganizationalLevelOrganizationalLevel");

        var itemRoleGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "OrganizationalLevelRoleGroup");
        var itemDesignationRequired = this.state.dynamicInputs.find(p => p.inputKey === "OrganizationalLevelDesignation");
        var itemOrganizationalLevelRequired = this.state.dynamicInputs.find(p => p.inputKey === "OrganizationalLevelOrganizationalLevel");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;
        let optionsRoleGroups = this.state.roleGroups.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );
        // let optionsNames = this.state.names.map((data) =>
        //     <option
        //         key={data.id}
        //         value={data.name}
        //     >
        //         {data.name}
        //     </option>
        // );
        // let optionsbudgeNos = this.state.budgeNos.map((data) =>
        //     <option
        //         key={data.id}
        //         value={data.name}
        //     >
        //         {data.name}
        //     </option>
        // );
        let optionsDesignations = this.state.designations.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );
        let optionsOrgLevels = this.state.organizationalLevelDrops.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );
        return <div>

                <MenuProvider id="menu_id">
          

                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete Organizational Level?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Selected organizational level will be deleted. Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                   

                
                    {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Card>

                        <div>
                            <Row>
                                <Col sm="1"> 
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>
                                    {/* {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    } */}
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>
                                    
                                    // <IconButton color="primary" onClick={this.showHowToUse}>
                                    //     <HelpIcon />
                                    // </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hiddenCreateNew} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdate == false ? this.updateDone : this.saveOrganizationalLevel}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {(itemRoleGroupAttributes.visible) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="OrganizationalLevelRoleGroup" defaultMessage="Role Group" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: itemRoleGroupRequired.canBeRequired, message: <FormattedMessage id={itemRoleGroupRequired.translateRequired} defaultMessage="Please select a role group!" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Form.Control id="OrganizationalLevelRoleGroup" name="roleGroup" value={this.state.roleGroup}
                                                    onChange={this.handleChange1} as="select">
                                                    <option value="">
                                                        Select Role Group
                                                    </option>
                                                    {optionsRoleGroups}
                                                </Form.Control>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {(itemDesignationAttributes.visible) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="OrganizationalLevelDesignation" defaultMessage="Designation" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: itemDesignationRequired.canBeRequired, message: <FormattedMessage id={itemDesignationRequired.translateRequired} defaultMessage="Please select a designation!" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Form.Control id="OrganizationalLevelDesignation" name="designation" value={this.state.designation}
                                                    onChange={this.handleChange2} as="select">
                                                    <option value="">
                                                        Select Designation
                                                    </option>
                                                    {optionsDesignations}
                                                </Form.Control>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {(itemOrganizationalLevelAttributes.visible) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="OrganizationalLevelOrganizationalLevel" defaultMessage="Organizational Level" />}
                                        name="OrganizationalLevelOrganizationalLevel"
                                        rules={[{ required: itemOrganizationalLevelRequired.canBeRequired, message: <FormattedMessage id={itemOrganizationalLevelRequired.translateRequired} defaultMessage="Please select a designation!" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Form.Control id="OrganizationalLevelOrganizationalLevel" name="organizationalLevel" value={this.state.organizationalLevel}
                                                    onChange={this.handleChange3} as="select">
                                                    <option value="">
                                                        Select Organizational Level
                                                    </option>
                                                    {optionsOrgLevels}
                                                </Form.Control>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button type='button' id='add-orgLevel' onClick={this.showOrHideModal} className="p-button-info" label="Add New" />*/}
                                                <ReactButton id="NewOrganizationalLevelButton" variant="primary" onClick={this.showOrHideModal}><FormattedMessage id="GeneralButtonSave" defaultMessage="Add New" /></ReactButton>
                                                <Modal
                                                    show={this.state.modalShow}
                                                    onHide={this.showOrHideModal}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title id="contained-modal-title-vcenter">Add New Organizational Level</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Form>
                                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                                                <Form.Label style={{ color: 'black' }} column sm="6">
                                                                    New Organizational Level:
                                            </Form.Label>
                                                                <Col sm="6">
                                                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ newOrgLevel: e.target.value })} value={this.state.newOrgLevel} />
                                                                </Col>
                                                            </Form.Group>
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <ReactButton variant="secondary" onClick={this.showOrHideModal}>Close</ReactButton>
                                                        <ReactButton variant="info" onClick={this.addNewOrgLevel}>Add</ReactButton>
                                                    </Modal.Footer>
                                                </Modal>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.changeButtonCancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" onClick={this.updateDone}>
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>

                                {/*<Form>
                                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                        <Col sm="3">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Role Group:
                            </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="roleGroup" value={this.state.roleGroup}
                                                onChange={this.handleChange1} as="select">
                                                <option value="">
                                                    Select Role Group
                                    </option>
                                                {optionsRoleGroups}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                Name:
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control name="name" value={this.state.name}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Name
                                    </option>
                                    {optionsNames}
                                </Form.Control>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                            <Col sm="3">
                            </Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                Budge No:
                        </Form.Label>
                            <Col sm="3">
                                <Form.Control name="budgeNo" value={this.state.budgeNo}
                                    onChange={this.handleChange} as="select">
                                    <option value="">
                                        Select Budge No
                                </option>
                                    {optionsbudgeNos}
                                </Form.Control>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                        <Col sm="3">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Designation:
                            </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="designation" value={this.state.designation}
                                                onChange={this.handleChange2} as="select">
                                                <option value="">
                                                    Select Designation
                                    </option>
                                                {optionsDesignations}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                        <Col sm="3">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Organizational Level:
                            </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="organizationalLevel" value={this.state.organizationalLevel}
                                                onChange={this.handleChange3} as="select">
                                                <option value="">
                                                    Select Organizational Level
                                    </option>
                                                {optionsOrgLevels}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                            <Button type='button' id='add-orgLevel' onClick={this.showOrHideModal} className="p-button-info" label="Add New" />
                                            <Modal
                                                show={this.state.modalShow}
                                                onHide={this.showOrHideModal}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title id="contained-modal-title-vcenter">Add New Organizational Level</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form>
                                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formPlaintextEmail">
                                                            <Form.Label style={{ color: 'black' }} column sm="6">
                                                                New Organizational Level:
                                                </Form.Label>
                                                            <Col sm="6">
                                                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ newOrgLevel: e.target.value })} value={this.state.newOrgLevel} />
                                                            </Col>
                                                        </Form.Group>
                                                    </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <ReactButton variant="secondary" onClick={this.showOrHideModal}>Close</ReactButton>
                                                    <ReactButton variant="info" onClick={this.addNewOrgLevel}>Add</ReactButton>
                                                </Modal.Footer>
                                            </Modal>
                                        </Col>
                                    </Form.Group>
                                </Form>*/}

                            </FormAnt>
                        </div>

                        <div className="content-section implementation">
                            <Toast ref={(el) => this.toast = el} />
                            <DataTable ref={(el) => this.dt = el} value={this.state.organizationalLevels} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-organizationallevels"
                                selectionMode="single" selection={this.state.selectedOrganizationalLevel} onSelectionChange={e => this.setState({ selectedOrganizationalLevel: e.value })}>
                                <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                                {/*<Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable header="Id" />
                                <Column field="budgeNo" header="Budge No" filter sortable />
                                <Column field="budgeNoId" style={{ textAlign: 'center', display: 'none' }} header="Budge No Id" filter sortable />
                                <Column field="name" header="Name" filter sortable />
                                <Column field="nameId" style={{ textAlign: 'center', display: 'none' }} header="Name Id" filter sortable /> */}
                                <Column field="designation.designationName" header="Designation" filter sortable />
                                <Column field="designation.id" style={{ textAlign: 'center', display: 'none' }} header="Designation Id" filter sortable />
                                <Column field="roleGroup.roleName" header="Role Group" filter sortable />
                                <Column field="roleGroup.id" style={{ textAlign: 'center', display: 'none' }} header="Role Group Id" filter sortable />
                                <Column field="code.name" header="Organizational Level" filter sortable />
                                <Column field="code.id" style={{ textAlign: 'center', display: 'none' }} header="Organizational Level Id" filter sortable />
                                {(this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 || this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) &&
                                    <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                                }
                            </DataTable>
                        </div>
                    </Card>
                    }
               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>  

               
                }
            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLevel)