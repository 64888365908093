/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Modal, Button as BootButton } from 'react-bootstrap';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Table, Switch, Radio, Form, Input, Button as Button1, Space, Menu } from 'antd';
import Highlighter from 'react-highlight-words';
import { FilterOutlined } from '@ant-design/icons';
import { MultiSelect } from 'primereact/multiselect';
import $ from 'jquery';
import { ColorPicker } from 'primereact/colorpicker';

const expandable = { expandedRowRender: record => <b>{record.disciplineName}</b> };
const title = () => '<b>Here is title</b>';
const showHeader = true;
var footer = () => 'Here is footer';
const pagination = { position: ['top', 'bottom'] };
const menu3 = (
    <Menu>
        <Menu.Item key="1">1st menu item</Menu.Item>
        <Menu.Item key="2">2nd menu item</Menu.Item>
        <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
);

export class DynamicTable extends Component {

    constructor() {
        super();
        this.state = {
            bordered: false,
            loading: false,
            pagination,
            size: 'small',
            expandable: false,
            title: undefined,
            paginationDisabled: "",
            showHeader,
            footer,
            rowSelection: undefined,
            scroll: undefined,
            hasData: true,
            tableLayout: undefined,
            top: 'topRight',
            bottom: 'bottomRight',
            searchText: '',
            searchedColumn: '',
            modalShow: false,
            hidden: true,
            disciplineNameText: "",
            selectedDiscipline: null,
            buttonName: "Add",
            buttonClassName: "p-button-success",
            gelenData: null,
            disciplines: [],
            objFilters: [],
            optionsFilter: [],
            siraNo: [{ "id": "1" },
            { "id": "2" },
            { "id": "3" }],
            editBgColor: '#fff',
            deleteBgColor: '#fff',
            leftX: 0,
            topY: 0,
            selectedNodeKey: null,

            menu: [
                {
                    label: 'Create',
                    icon: 'pi pi-fw pi-file',
                    command: (event) => {
                        console.log(event);
                        this.openLocationChildDialog();

                    },
                    items: [
                        {
                            label: 'Child',
                            data: 5,
                            icon: 'pi pi-fw pi-plus',
                            command: (event) => {
                                console.log(event);
                                this.openLocationChildDialog();

                            }

                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {

                                this.openLocationParentDialog();

                            }

                        },
                        {
                            label: 'Activity',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {

                                this.openLocationActivityDialog();

                            }

                        },
                        {
                            label: 'FlowChart',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {

                                this.openLocationFlowchartDialog();

                            }

                        }

                    ]

                },

                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {

                        this.openLocationEditDialog();

                    }
                },
                {
                    separator: true
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        //  this.toast.show({severity: 'success', summary: 'Node Key', detail: this.state.selectedNodeKey});
                        this.deleteModal();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-cog',
                    command: () => {
                        let expandedKeys = { ...this.state.expandedKeys };
                        if (expandedKeys[this.state.selectedNodeKey])
                            delete expandedKeys[this.state.selectedNodeKey];
                        else
                            expandedKeys[this.state.selectedNodeKey] = true;

                        this.setState({ expandedKeys: expandedKeys });
                    }
                }
            ]
            ,

            menu2: [
                {
                    label: 'Create FlowChart',
                    icon: 'pi pi-fw pi-plus',

                    command: () => {

                        this.openActivityFlowchartDialog();

                    }

                },

                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {

                        this.openActivityEditDialog();

                    }
                },
                {
                    separator: true
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        //  this.toast.show({severity: 'success', summary: 'Node Key', detail: this.state.selectedNodeKey});
                        this.deleteActivityModal();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-cog',
                    command: () => {
                        let expandedKeys = { ...this.state.expandedKeys };
                        if (expandedKeys[this.state.selectedNodeKey])
                            delete expandedKeys[this.state.selectedNodeKey];
                        else
                            expandedKeys[this.state.selectedNodeKey] = true;

                        this.setState({ expandedKeys: expandedKeys });
                    }
                }
            ]
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    };

    componentDidMount = async () => {
        // const response = await axios.get(`${API_BASE}/Discipline/getAll`);
        const response = await handleRequest("GET", "/Discipline/getAll");

        console.log(this.props.prm)


        if (response.data.length === 0) {
            this.setState({
                disciplines: [],
            });
        }

        if (response.data.length !== 0) {
            // const keys = response.data.map(function (key) {
            //     response.data.push({ key: key.disciplineName.toString() });
            // })
            this.setState({
                disciplines: response.data,
            });
        }

        const columns = response.data;
        const options = columns.map(function (row) {
            return { text: row.disciplineName.toString(), value: row.disciplineName.toString() }
        })

        this.setState({
            objFilters: options,
        });

        let optionsFilters = [];
        this.state.objFilters.forEach(data => {
            optionsFilters.push({ label: data.text, value: data.value });
        });

        this.setState({
            optionsFilter: optionsFilters,
        });

        this.setState({
            paginationDisabled: { pageSize: 5, defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'], total: this.state.disciplines.length, showQuickJumper: true, position: [this.state.top, this.state.bottom] }
        })

        var flagMenu = this.state.menu;
        flagMenu[0].items.push({
            label: 'My C',
            data: 5,
            icon: 'pi pi-fw pi-plus',
            command: (event) => {
                console.log(event);
                this.openLocationChildDialog();

            }

        });
        this.restartTable();
        
        this.setState({
            flowChartData: this.state.data,
            menu: flagMenu
        });

        document.addEventListener('contextmenu', this._handleContextMenu);
    };

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteDiscipline(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />   </React.Fragment>;
    };

    deleteModal = (row) => {

        this.setState({
            modalShow: true,
            selectedDiscipline: row
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    saveDiscipline = async () => {
        if (this.state.buttonName === "Add" && this.state.disciplineNameText !== "") {

            const newDiscipline = {
                disciplineName: this.state.disciplineNameText
            }

            //await axios.post(`${API_BASE}/Discipline/add`, newDiscipline);
            var response = await handleRequest("POST", "/Discipline/add", newDiscipline);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS-DISCIPLINE-001") {
                    this.changeButtonSave();
                    this.restartTable();
                    this.showSuccess();
                }
                else if (response.data === "ERROR-DISCIPLINE-001") {
                    this.showError("An error was occured please try again later !");
                } else if (response.data === "ERROR-DISCIPLINE-002") {
                    this.showError("This record added before please change discipline name !");
                }
            }

        }
        else if (this.state.buttonName === "Update" && this.state.disciplineNameText !== "") {

            const updatedDiscipline = {
                disciplineName: this.state.disciplineNameText,
                id: this.state.selectedDiscipline.id,
                active: this.state.selectedDiscipline.active
            }

            // await axios.put(`${API_BASE}/Discipline/update`, updatedDiscipline);
            var response2 = await handleRequest("PUT", "/Discipline/update", updatedDiscipline);



            if (response2.data !== null && response2.data !== undefined) {
                if (response2.data === "SUCCESS-DISCIPLINE-001") {
                    this.changeButtonSave();
                    this.restartTable();
                    this.showSuccess();
                }
                else if (response2.data === "ERROR-DISCIPLINE-001") {
                    this.showError("An error was occured please try again later !");
                } else if (response2.data === "ERROR-DISCIPLINE-002") {
                    this.showError("This record added before please change discipline name !");
                }
            }

        } else {
            this.showError();
        }
    };

    restartTable = async () => {

        const response = await handleRequest("GET", "/Discipline/getAll");

        if (response.data.length === 0) {
            this.setState({
                disciplines: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                disciplines: response.data,
            });
        }

        const columns = response.data;
        const options = columns.map(function (row) {
            return { text: row.disciplineName.toString(), value: row.disciplineName.toString() }
        })

        this.setState({
            objFilters: options,
        });
    }

    delete = async (name) => {

        const newDiscipline = {
            id: this.state.selectedDiscipline.id
        }

        // await axios.put(`${API_BASE}/Discipline/delete`, newDiscipline);
        await handleRequest("PUT", "/Discipline/delete", newDiscipline);

        this.restartTable();
        this.showSuccess();
        this.showOrHideModal();
    };

    changeButtonUpdate = (row) => {
        this.setState({
            disciplineNameText: row.disciplineName,
            selectedDiscipline: row,
            hidden: false,
            buttonName: "Update",
            buttonClassName: "p-button-warning"
        });
    };

    changeButtonSave = () => {
        this.setState({
            disciplineNameText: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });

    };

    changeButtonCancel = () => {
        this.setState({
            disciplineNameText: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
        //this.showError();
    };

    handleToggle = prop => enable => {
        this.setState({ [prop]: enable });
    };

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    handleTableLayoutChange = e => {
        this.setState({ tableLayout: e.target.value });
    };

    handleExpandChange = enable => {
        this.setState({ expandable: enable ? expandable : undefined });
    };

    handleEllipsisChange = enable => {
        this.setState({ ellipsis: enable });
    };

    handleTitleChange = enable => {
        this.setState({ title: enable ? title : undefined });
    };

    handleHeaderChange = enable => {
        this.setState({ showHeader: enable ? showHeader : false });
    };

    handleFooterChange = enable => {
        this.setState({ footer: enable ? footer : undefined });
    };

    handleRowSelectionChange = enable => {
        this.setState({ rowSelection: enable ? {} : undefined });
    };

    handleYScrollChange = enable => {
        this.setState({ yScroll: enable, paginationDisabled: false });
        if (enable == false) {
            this.setState({
                paginationDisabled: { pageSize: 5, defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'], total: this.state.disciplines.length, showQuickJumper: true, position: [this.state.top, this.state.bottom] }
            })
        }
    };

    handleXScrollChange = e => {
        this.setState({ xScroll: e.target.value });
    };

    handleDataChange = hasData => {
        this.setState({ hasData });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button1
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<FilterOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
          </Button1>
                    <Button1 onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
          </Button1>
                </Space>
            </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    _handleContextMenu = (event) => {
        event.preventDefault();

        /*this.setState({ leftX: event.clientX-264 , topY:event.clientY-141 }); 
         console.log(document.getElementsByClassName('p-contextmenu p-component')[0].style);   
         document.getElementsByClassName('p-contextmenu p-component')[0].style.left=event.clientX-264+"px";//this.state.leftX;
         document.getElementsByClassName('p-contextmenu p-component')[0].style.top= event.clientY-141+"px" ;//this.state.topY; 
         console.log(document.getElementsByClassName('p-contextmenu p-component')[0].style);   
      
         console.log($(".p-contextmenu"));  */

        setTimeout(function () {
            $(".p-contextmenu").css("left", event.clientX - 264 + "px");
            $(".p-contextmenu").css("top", event.clientY - 141 + "px");
        }, 50);
        // event.clientX= event.clientX - 264 ;
        //event.clientX= event.clientX - 141 ;


    }

    render() {
        const { xScroll, yScroll, ...state } = this.state;
        footer = () => 'Total records: ' + this.state.disciplines.length;
        const scroll = {};
        if (yScroll) {
            scroll.y = 240;
        }
        if (xScroll) {
            scroll.x = '100vw';
        }

        var objFilter = this.state.disciplines.map(item => ({ ...item, ellipsis: state.ellipsis }));

        const tableColumns = this.state.disciplines.map(item => ({ ...item, ellipsis: state.ellipsis }));
        if (xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>
        return (
            <div>
                <Card>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <InputText onChange={(e) => this.setState({ disciplineNameText: e.target.value })} value={this.state.disciplineNameText} placeholder="Discipline" />
                                <Button id='save-discipline' className={this.state.buttonClassName} onClick={this.saveDiscipline} label={this.state.buttonName} />
                                <Button hidden={this.state.hidden} id='cancel-discipline' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                            </div>
                        </div>
                    </div>
                    <Form
                        layout="inline"
                        className="components-table-demo-control-bar"
                        style={{ marginBottom: 16 }}
                    >
                        <Form.Item label="Bordered">
                            <Switch checked={state.bordered} onChange={this.handleToggle('bordered')} />
                        </Form.Item>
                        <Form.Item label="loading">
                            <Switch checked={state.loading} onChange={this.handleToggle('loading')} />
                        </Form.Item>
                        <Form.Item label="Title">
                            <Switch checked={!!state.title} onChange={this.handleTitleChange} />
                        </Form.Item>
                        <Form.Item label="Column Header">
                            <Switch checked={!!state.showHeader} onChange={this.handleHeaderChange} />
                        </Form.Item>
                        <Form.Item label="Footer">
                            <Switch checked={!!state.footer} onChange={this.handleFooterChange} />
                        </Form.Item>
                        <Form.Item label="Expandable">
                            <Switch checked={!!state.expandable} onChange={this.handleExpandChange} />
                        </Form.Item>
                        <Form.Item label="Checkbox">
                            <Switch checked={!!state.rowSelection} onChange={this.handleRowSelectionChange} />
                        </Form.Item>
                        <Form.Item label="Fixed Header">
                            <Switch checked={!!yScroll} onChange={this.handleYScrollChange} />
                        </Form.Item>
                        <Form.Item label="Has Data">
                            <Switch checked={!!state.hasData} onChange={this.handleDataChange} />
                        </Form.Item>
                        <Form.Item label="Ellipsis">
                            <Switch checked={!!state.ellipsis} onChange={this.handleEllipsisChange} />
                        </Form.Item>
                        <Form.Item label="Size">
                            <Radio.Group value={this.state.size} onChange={this.handleSizeChange}>
                                <Radio.Button value="default">Default</Radio.Button>
                                <Radio.Button value="middle">Middle</Radio.Button>
                                <Radio.Button value="small">Small</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Table Scroll">
                            <Radio.Group value={xScroll} onChange={this.handleXScrollChange}>
                                <Radio.Button value={undefined}>Unset</Radio.Button>
                                <Radio.Button value="scroll">Scroll</Radio.Button>
                                <Radio.Button value="fixed">Fixed Columns</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Table Layout">
                            <Radio.Group value={state.tableLayout} onChange={this.handleTableLayoutChange}>
                                <Radio.Button value={undefined}>Unset</Radio.Button>
                                <Radio.Button value="fixed">Fixed</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Pagination Top">
                            <Radio.Group
                                value={this.state.top}
                                onChange={e => {
                                    this.setState({ top: e.target.value });
                                }}
                            >
                                <Radio.Button value="topLeft">TopLeft</Radio.Button>
                                <Radio.Button value="topCenter">TopCenter</Radio.Button>
                                <Radio.Button value="topRight">TopRight</Radio.Button>
                                <Radio.Button value="none">None</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Pagination Bottom">
                            <Radio.Group
                                value={this.state.bottom}
                                onChange={e => {
                                    this.setState({ bottom: e.target.value });
                                }}
                            >
                                <Radio.Button value="bottomLeft">BottomLeft</Radio.Button>
                                <Radio.Button value="bottomCenter">BottomCenter</Radio.Button>
                                <Radio.Button value="bottomRight">BottomRight</Radio.Button>
                                <Radio.Button value="none">None</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Set Edit Button Background Color">
                            <ColorPicker style={{ width: '10%' }} value={this.state.editBgColor} onChange={(e) => this.setState({ editBgColor: e.value })} />
                        </Form.Item>
                        <Form.Item label="Set Delete Button Background Color">
                            <ColorPicker style={{ width: '10%' }} value={this.state.deleteBgColor} onChange={(e) => this.setState({ deleteBgColor: e.value })} />
                        </Form.Item>
                    </Form>
                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <ContextMenu model={(this.state.selectedNodeKey !== null && this.state.selectedNodeKey.split(".")[0] === "L") ? this.state.menu : this.state.menu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />
                        <Table
                            {...this.state}
                            pagination={this.state.paginationDisabled}
                            dataSource={state.hasData ? state.disciplines : null}
                            scroll={scroll}
                            contextMenuSelectionKey={this.state.selectedNodeKey} onContextMenuSelectionChange={event => this.setState({ selectedNodeKey: event.value })}
                            onContextMenu={event => this.cm.show(event.originalEvent)} 
                            >

                            <Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <React.Fragment> <CustomMaterialMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} editBgColor={this.state.editBgColor} deleteBgColor={this.state.deleteBgColor} />   </React.Fragment>
                                )}
                            />
                            <Column title="S/N" dataIndex="index" style={{ textAlign: 'center', width: '10%' }} sorter={(a, b) => a.index - b.index} {...this.getColumnSearchProps('index')} />
                            <Column title="Id" dataIndex="id" style={{ textAlign: 'center', width: '0px', 'display': 'none' }} />
                            <Column title="Discipline" dataIndex="disciplineName"
                                sorter={(a, b) => a.disciplineName.length - b.disciplineName.length}
                                filters={this.state.objFilters}
                                onFilter={(value, record) => record.disciplineName.indexOf(value) === 0}
                                key="disciplineName"
                                {...this.getColumnSearchProps('disciplineName')} />

                            <Column title="Discipline Id" dataIndex="id" style={{ textAlign: 'center', display: 'none' }} />
                            <Column title="Active" dataIndex="active" style={{ textAlign: 'center', display: 'none' }} />

                        </Table>
                    </div>

                    <Modal
                        show={this.state.modalShow}
                        onHide={this.showOrHideModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete  Discipline ?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Selected discipline will be deleted. Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <BootButton variant="secondary" onClick={this.showOrHideModal}>Cancel</BootButton>
                            <BootButton variant="danger" onClick={this.delete} >Delete</BootButton>
                        </Modal.Footer>
                    </Modal>

                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicTable)