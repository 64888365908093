/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { TreeTable } from 'primereact/treetable';
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import { Container, Col, Form, Row, Button as BootButton } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { SlideMenu } from 'primereact/slidemenu';
import { BreadCrumb } from 'primereact/breadcrumb';
import { SplitButton } from 'primereact/splitbutton';
import { Link } from 'react-router-dom';
import { Select, Divider, Input, Switch, Button as AntButton, Cascader } from 'antd';
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { OrganizationChart } from 'primereact/organizationchart';
import classNames from 'classnames';
import './OrganizationChartCustomStyle.css';
import classes from './LocationActivityStyle.module.scss';
import { OrderList } from 'primereact/orderlist';


const { Option } = Select;

const _DurationTypes = [
    { label: "Year", value: 1 },
    { label: "Month", value: 2 },
    { label: "Day", value: 3 },
    { label: "Hour", value: 4 },
    { label: "Minute", value: 5 },
    { label: "Second", value: 6 }
];

export class ActivityLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
            items: [],
            itemsPath: [],
            parentId: 0,
            position: 'top',
            rolegroupLabel: "",
            rolegroupLabelId: 0,
            departmentLabel: "",
            departmentLabelId: 0,
            designationLabel: "",
            designationLabelId: 0,
            staffLabel: "",
            staffId: 0,
            disciplineLabel: "",
            disciplineLabelId: 0,
            expandedKeys: {},
            hideActivityInputs: true,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",
            remarks: "",

            activityId: 0,
            parentActivityId: 0,
            flowChartType: "activity",
            flowChartBelongId: 0,
            flowChartItem: false,

            disciplineName: "",
            disciplineId: 0,
            departmentId: 0,
            disciplines: [],

            activityTypes: [],
            activityType: 0,
            newActivityType: '',
            activityCode: "",
            activityDescription: "",
            placeOfExecution: 0,
            newExecution: '',
            placeOfExecutions: [],
            displayPreDialog: false,
            dialogDisciplineName: "",

            dialogDisciplineId: 0,
            dialogActivityTypes: [],
            dialogActivityTypeId: 0,
            dialogActivityList: [],
            selectedPreActivities: [],

            durationTypes: _DurationTypes.slice(),
            durationType: 0,
            duration: "",
            durationNumber: "",
            durationObj: {},

            durationObjStart: {},
            durationStart: "",
            durationTypesStart:  _DurationTypes.slice(),
            durationTypeStart: 0,
            durationNumberStart: "", 

            iddlePeriodTypes: _DurationTypes.slice(),
            iddlePeriodType: 0,
            iddlePeriod: "",
            iddlePeriodNumber: "",
            idleDescription: "",
            iddlePeriodObj: {},
            departments: [],
            departrmentId: '',
            disciplines2: [],
            roleGroups: [],
            designations: [],
            staffs: [],
            selectedNodeKey: null,

            type: true,
            flowChartData: [],
            isFlowchartPocess: false,
            orderItemList: [],
            orderModal: false,
            defaultchart: [{
                label: '',
                className: classNames(classes.departmentCto),
                expanded: true,
                children: [

                ]
            }],
            activityList: [],
            responsibilityNodesValues: [],
            responsibilityNodes: [],
            users: [],
            userKeys: [],
            splitButtonMenu: [
                {
                    label: 'Create FlowChart',
                    icon: 'pi pi-fw pi-plus',

                    command: () => {

                        this.CreateFlowchart();

                    }

                },

                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {

                        this.PreEdit();

                    }
                },

                {
                    label: 'Order Childs',
                    icon: 'pi pi-fw pi-sort-alpha-down',
                    command: () => {

                        this.Order();

                    }
                },

                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        //  this.toast.show({severity: 'success', summary: 'Node Key', detail: this.state.selectedNodeKey});
                        this.OpenDeleteActivityDialog();
                    }
                }
            ]

        };

        this.onClickPrerequisition = this.onClickPrerequisition.bind(this);
        this.placeTemplate = this.placeTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.removeTemplate = this.removeTemplate.bind(this);
        // this.durationTemplate= this.durationTemplate(this);
        // this.idleTemplate = this.idleTemplate(this);
    }

    // { label: "In Situ", value: 1 },
    // { label: "Sub Contractor", value: 2 },
    // { label: "Workshop", value: 3 },
    // { label: "Direct suply", value: 4 }

    OpenDeleteActivityDialog = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            modalActivityDeleteShow: true,
            activityId: arr[1]
        });
    }

    PreEdit = async () => {

        this.resetInputs();
        const activityList = this.state.activityList;
        var arr = this.state.selectedNodeKey.split('.');
        var response = await handleRequest("GET", "/WFActivity/" + arr[1]);
        let _selectedPreActivities = [], _userKeys = [], _responsibilityNodesValues=[];
        var item = response.data;

        console.log(item);

        if (Boolean(item.prerequisitionList))
            item.prerequisitionList.forEach(element => {
                let obj = activityList.find(p => p.id === element.preActivityId);
                if (Boolean(obj))
                    _selectedPreActivities.push(obj);
            });

        if (Boolean(item.resUserList))
            item.resUserList.forEach(element => {
                _userKeys.push(element.userId.toString());
            });

        if (Boolean(item.resDepartmentId))
            _responsibilityNodesValues.push(item.resDepartmentId.toString());
        if (Boolean(item.resDisciplineId))
            _responsibilityNodesValues.push(item.resDisciplineId.toString());
        if (Boolean(item.resDesignationId)) 
            _responsibilityNodesValues.push(item.resDesignationId.toString());
       
            console.log(_responsibilityNodesValues); 
        this.setDuration(item.duration);
        this.setDurationStart(item.startDuration); 
        this.setIdlePeriod(item.idlePeriod);
        this.setState({
            hideActivityInputs: false,
            activityId: arr[1],
            createNewButton: "Stop Editting",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "danger",
            isFlowchartPocess: false,
            activityType: item.activityTypeId,
            activityDescription: item.activityName,
            idleDescription: item.idleDescription,
            activityCode: item.code,
            disciplineId: item.disciplineId,
            departmentId: item.departmentId,
            placeOfExecution: item.placeOfExecutionId,
            prerequisitionList: item.prerequisitionList,
            remarks: item.remarks,
            flowChartBelongId: item.flowChartBelongId,
            flowChartItem: item.flowChartItem,
            flowChartType: item.flowChartType,
            parentActivityId: item.parentId,
            type: item.type,
            selectedPreActivities: _selectedPreActivities,
            userKeys:_userKeys,
            responsibilityNodesValues:_responsibilityNodesValues 

        });


        document.getElementById('kt_scrolltop').click();

    }

    Order = async () => {
        var arr = this.state.selectedNodeKey.split('.');
        var response = await handleRequest("GET", "/WFActivity/" + arr[1]);

        var item = response.data;

        this.setState({
            orderItemList: item.childActivities,
            orderModal: true
        })

    }

    CreateFlowchart = async () => {

        this.resetInputs();
        var arr = this.state.selectedNodeKey.split('.');
        var response = await handleRequest("GET", "/WFActivity/" + arr[1]);
        var _flowChartBelongId = 0;

        if (Boolean(response) && Boolean(response.data))
            var item = response.data;

        if (Boolean(item)) {
            if (item.parentId === '0')
                _flowChartBelongId = item.id;
            else _flowChartBelongId = item.flowChartBelongId
        }



        var param = {

            flowChartBelongId: _flowChartBelongId
        };

        const response2 = await handleRequest("POST", "/WFActivity/getFlowChart", param);
        if (response2.data.length !== 0) {
            this.setState({
                flowChartData: response2.data

            });
        }



        this.setState({
            hideActivityInputs: false,
            activityId: 0,
            parentActivityId: arr[1],
            createNewButton: "Stop Creating Flowchart",
            lastButton: "Add Flowchart",
            lastButtonVariant: "success",
            createNewButtonVariant: "danger",
            flowChartBelongId: _flowChartBelongId,
            flowChartItem: true,
            activityType: item.activityTypeId,
            disciplineId: item.disciplineId,
            departmentId: item.departmentId,
            isFlowchartPocess: true,
            type: item.type

        });

        document.getElementById('kt_scrolltop').click();
    }



    toggleApplications() {
        let expandedKeys = { ...this.state.expandedKeys };
        if (expandedKeys['0'])
            delete expandedKeys['0'];
        else
            expandedKeys['0'] = true;

        this.setState({ expandedKeys: expandedKeys });
    }

    saveActivity = async () => {

        if (this.state.disciplineId > 0) {
            var list = [];
            var _resUserList=[];
            this.state.selectedPreActivities.forEach(f => {

                const newItem = {

                    preActivityId: f.id
                }

                list.push(newItem);
            });


            this.state.userKeys.forEach(f => {

                const obj = {

                    userId: f
                }

                _resUserList.push(obj);
            });

            var _orderNo;

            var res = await handleRequest("GET", "/WFActivity/getOrder/" + this.state.parentActivityId);

            if (Boolean(res.data))
                _orderNo = res.data;

            const responsibilityList = this.state.responsibilityNodesValues;
            var _resDepartmentId;
            var _resDisciplineId;
            var _resDesignationId;

            if (responsibilityList.length > 0) {

                responsibilityList.forEach((element, index) => {
                    if (index === 0)
                        _resDepartmentId = element;
                    if (index === 1)
                        _resDisciplineId = element;
                    if (index === 2)
                        _resDesignationId = element;
                });

            }

            var item = {

                id: this.state.activityId,
                parentId: this.state.parentActivityId,
                disciplineId: this.state.disciplineId,
                departmentId: this.state.departmentId,
                type: this.state.type,
                activityTypeId: this.state.activityType,
                activityName: this.state.activityDescription,
                idleDescription: this.state.idleDescription,
                code: this.state.activityCode,
                placeOfExecutionId: this.state.placeOfExecution,
                prerequisitionList: list,
                duration: this.state.durationObj,
                startDuration:this.state.durationObjStart, 
                idlePeriod: this.state.iddlePeriodObj,
                remarks: this.state.remarks,
                staffId: this.state.staffId,
                flowChartBelongId: this.state.flowChartBelongId,
                flowChartType: this.state.flowChartType,
                flowChartItem: this.state.flowChartItem,
                orderNo: _orderNo,
                resDepartmentId: _resDepartmentId,
                resDisciplineId: _resDisciplineId,
                resDesignationId: _resDesignationId,
                resUserList: _resUserList

            }

            console.log(item);

            if (item.id > 0) {
                await handleRequest("POST", "/WFActivity/updateWithMetaData", item);
            }
            else
                await handleRequest("POST", "/WFActivity/addWithMetadata", item);

            this.restartTable();
            if (this.state.isFlowchartPocess)
                this.resetFlowChartInputs();
            else
                this.resetInputs();
            this.showSuccess();

        } else {
            this.showError();
        }
    };

    resetInputs = () => {

        this.setState({
            parentActivityId: 0,
            activityId: 0,
            disciplineId: 0,
            activityCode: '',
            activityDescription: '',
            idleDescription: '',
            duration: '',
            durationObj: {},
            durationNumber: '',
            itemsPath: [],
            parentId: 0,
            position: 'top',
            rolegroupLabel: "",
            rolegroupLabelId: 0,
            departmentLabel: "",
            departmentLabelId: 0,
            designationLabel: "",
            designationLabelId: 0,
            staffLabel: "",
            staffId: 0,
            remarks: '',
            iddlePeriod: '',
            iddlePeriodNumber: '',
            iddlePeriodObj: {},
            prerequisitionList: [],
            disciplineName: "",
            activityType: "",
            placeOfExecution: "",
            displayPreDialog: false,
            dialogDisciplineName: "",
            modalActivityDeleteShow: false,
            flowChartBelongId: 0,
            flowChartItem: false,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",
            selectedPreActivities: [],
            durationTypes: _DurationTypes.slice(),
            iddlePeriodTypes: _DurationTypes.slice(),
            isFlowchartPocess: false,
            departmentId: 0

        });
    };

    resetFlowChartInputs = () => {

        this.setState({

            activityId: 0,
            activityCode: '',
            activityDescription: '',
            idleDescription: '',
            duration: '',
            durationObj: {},
            durationNumber: '',
            itemsPath: [],
            parentId: 0,
            position: 'top',
            rolegroupLabel: "",
            rolegroupLabelId: 0,

            designationLabel: "",
            designationLabelId: 0,
            staffLabel: "",
            staffId: 0,
            remarks: '',
            iddlePeriod: '',
            iddlePeriodNumber: '',
            iddlePeriodObj: {},
            prerequisitionList: [],

            placeOfExecution: "",
            displayPreDialog: false,
            dialogDisciplineName: "",
            modalActivityDeleteShow: false,
            selectedPreActivities: [],
            durationTypes: _DurationTypes.slice(),
            iddlePeriodTypes: _DurationTypes.slice(),


        });
    };

    restartTable = async () => {

        const response1 = await handleRequest("GET", "/WFActivity/getNodes");

        if (response1.data.length !== 0) {

            this.setState({
                nodes: response1.data
            });

        }


        const response2 = await handleRequest("GET", "/WFActivity/getAllByCompany"); 

        if (response2.data.length !== 0) {

            this.setState({
                activityList: response2.data
            });

        }


        if (this.state.isFlowchartPocess) {

            var item = {
                flowChartType: this.state.flowChartType,
                flowChartBelongId: this.state.flowChartBelongId
            };

            const response = await handleRequest("POST", "/WFActivity/getFlowChart", item);
            if (response.data.length !== 0) {
                this.setState({
                    flowChartData: response.data

                });
            }
        }

    }

    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/ACTIVITYTYPE");
        if (Boolean(response.data)) {
            this.setState({
                activityTypes: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/PLACEOFEXECUTION");
        if (Boolean(response2.data)) {
            this.setState({
                placeOfExecutions: response2.data
            });
        }

    }

    componentDidMount = async () => {
        // this.nodeservice.getTreeTableNodes().then(data => this.setState({nodes: data}));

        this.restartTable();
        this.fillCodes();

        const responseUser = await handleRequest("GET", "/User/getCompanyItems");


        if (responseUser.data.length !== 0) {

            const options = responseUser.data.map(function (row) {
                return { name: row.username.toString(), id: row.id.toString() }
            })

            this.setState({
                users: options,
            });

        }

        const resNodes = await handleRequest("GET", "/Department/getNodes");


        if (resNodes.data.length !== 0) {

            this.setState({
                responsibilityNodes: resNodes.data
            });

        }

        const response2 = await handleRequest("GET", "/Discipline/getallByCompany");


        if (response2.data.length !== 0) {

            this.setState({
                disciplines: response2.data
            });

        }

        const responseDep = await handleRequest("GET", "/Department/getallByCompany"); 


        if (responseDep.data.length !== 0) {

            this.setState({
                departments: responseDep.data
            });

        }

        const responseRG = await handleRequest("GET", "/RoleGroup/getallByCompany");

        if (responseRG.data.length !== 0) {


            this.setState({
                roleGroups: responseRG.data
            });

        }

        const responseDes = await handleRequest("GET", "/Designation/getallByCompany");

        if (responseDes.data.length !== 0) {


            this.setState({
                designations: responseDes.data
            });

        }

        const responseStaff = await handleRequest("GET", "/Staff/getallByCompany"); 

        console.log(responseStaff);
        if (responseStaff.data.length !== 0) {

            this.setState({
                staffs: responseStaff.data
            });

        }
        var menu = [];
        menu.push({ label: "Departments" }, {
            separator: true
        });
        this.state.departments.forEach(department => {
            var menuItemDepartment = {
                label: department.departmentName, icon: 'pi pi-chevron-circle-right', type: 'departmentLabel', id: department.id, command: (event) => {

                    this.selectedItem(event);

                }, items: []
            }
            menuItemDepartment.items.push({ label: "Disciplines" }, {
                separator: true
            });

            if (department.disciplineDepartmentList !== null && department.disciplineDepartmentList !== undefined && department.disciplineDepartmentList.length > 0) {

                department.disciplineDepartmentList.forEach(discipline => {

                    var menuItemDiscipline = {
                        label: discipline.discipline.disciplineName, icon: 'pi pi-chevron-circle-right', type: 'disciplineLabel', id: discipline.disciplineId, command: (event) => {

                            this.selectedItem(event);

                        }, items: []
                    }

                    menuItemDiscipline.items.push({ label: "Role Groups" }, {
                        separator: true
                    });
                    this.state.roleGroups.forEach(rolegroup => {
                        var menuItemRoleGroup = {
                            label: rolegroup.roleName, icon: 'pi pi-chevron-circle-right', type: 'rolegroupLabel', id: rolegroup.id, command: (event) => {

                                this.selectedItem(event);

                            }, items: []
                        }
                        menuItemRoleGroup.items.push({ label: "Designations" }, {
                            separator: true
                        });
                        var designations = this.state.designations.filter(p => p.roleId === rolegroup.id);
                        if (designations !== null && designations !== undefined && designations.length > 0) {

                            designations.forEach(designation => {

                                var menuItemDesignation = {
                                    label: designation.designationName, icon: 'pi pi-chevron-circle-right', type: 'designationLabel', id: designation.id, command: (event) => {

                                        this.selectedItem(event);

                                    }, items: []
                                }
                                menuItemDesignation.items.push({ label: "Users" }, {
                                    separator: true
                                });
                                var users = this.state.staffs.filter(p => p.designationId === designation.id);
                                if (users !== null && users !== undefined && users.length > 0) {
                                    users.forEach(user => {

                                        var menuItemUser = {
                                            label: user.name + " " + user.midName + " " + user.lastName, type: 'staffLabel', id: user.id,
                                            icon: 'pi pi-fw pi-user',
                                            command: (event) => {

                                                this.selectedItem(event);

                                            }
                                        }
                                        menuItemDesignation.items.push(menuItemUser);
                                    });
                                }
                                menuItemRoleGroup.items.push(menuItemDesignation);
                            });
                        }
                        menuItemDiscipline.items.push(menuItemRoleGroup);
                    });
                    menuItemDepartment.items.push(menuItemDiscipline);


                });
            }
            menu.push(menuItemDepartment);
        });

        this.setState({ items: menu });

    }

    selectedItem = (event) => {

        this.setState({
            [`${event.item.type}`]: event.item.label,
            [`${event.item.type}Id`]: event.item.id
        });

        if (event.item.type === "departmentLabel") {

            this.setState({
                disciplineLabel: '',
                disciplineLabelId: 0,
                rolegroupLabel: '',
                rolegroupLabelId: 0,
                designationLabel: '',
                designationLabelId: 0,
                staffLabel: '',
                staffId: 0
            });


        }

        if (event.item.type === "disciplineLabel") {
            this.setState({

                rolegroupLabel: '',
                rolegroupLabelId: 0,
                designationLabel: '',
                designationLabelId: 0,
                staffLabel: '',
                staffId: 0
            });
        }

        if (event.item.type === "rolegroupLabel") {
            this.setState({

                designationLabel: '',
                designationLabelId: 0,
                staffLabel: '',
                staffId: 0
            });
        }

        if (event.item.type === "designationLabel") {
            this.setState({

                staffLabel: '',
                staffId: 0
            });
        }


        if (event.item.type === "staffLabel") {

            setTimeout(
                function () {
                    const _itemPath = [
                        { label: this.state.departmentLabel },
                        { label: this.state.disciplineLabel },
                        { label: this.state.rolegroupLabel },
                        { label: this.state.designationLabel },
                        { label: this.state.staffLabel }
                    ];
                    this.setState({
                        displayUserDialog: false,
                        staffId: event.item.id,
                        itemsPath: _itemPath
                    })
                }
                    .bind(this),
                100)


        }
    }
    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    handleDisciplineChange = e => {


        this.setState({

            disciplineId: e.target.value

        });
    };

    setDisciplineName = (pId) => {



        if (pId !== null) {

            this.setState({
                disciplineName: pId.disciplineName,
                disciplineId: pId.id

            });


        }

    };


    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onClickPrerequisition = (name) => {

        this.setState({
            displayPreDialog: true
        })
    }

    onClickUser = (name) => {

        this.setState({
            displayUserDialog: true
        })
    }

    onHide = (name) => {

        this.setState({
            displayPreDialog: false,
            displayUserDialog: false,
            orderModal: false,
            dialogActivityTypeId: 0,
            dialogDisciplineId: 0
        })
    }

    confirmSelecteds = (name) => {
        //TODO
        if (this.state.selectedPreActivities.length === 0) {
            this.showError("Please select any record !");
        }
        else {
            this.setState({
                displayPreDialog: false,
                dialogActivityTypeId: 0,
                dialogDisciplineId: 0

            });
        }


    }
    ok = () => {
        console.log(this.state.departmentLabelId);
        console.log(this.state.designationLabelId);

        setTimeout(
            function () {
                const _itemPath = [
                ];
                if (this.state.departmentLabel !== '')
                    _itemPath.push({ label: this.state.departmentLabel });
                if (this.state.disciplineLabel !== '')
                    _itemPath.push({ label: this.state.disciplineLabel });
                if (this.state.rolegroupLabel !== '')
                    _itemPath.push({ label: this.state.rolegroupLabel });
                if (this.state.designationLabel !== '')
                    _itemPath.push({ label: this.state.designationLabel });
                if (this.state.staffLabel !== '')
                    _itemPath.push({ label: this.state.staffLabel });

                this.setState({
                    displayUserDialog: false,
                    itemsPath: _itemPath
                })
            }
                .bind(this),
            100)
    }

    saveOrder = async () => {

        const list = this.state.orderItemList;
        const orderList = []; 



        list.forEach((element, i) => {
            var obj = {id:element.id, orderNo:i,parentId:element.parentId};
            element.orderNo = i;
            orderList.push(obj); 
        });

        await handleRequest("POST", "/WFActivity/updateOrder", orderList); 


        this.restartTable();
        if (this.state.isFlowchartPocess)
            this.resetFlowChartInputs();
        else
            this.resetInputs();
        this.showSuccess();


    }


    refreshDialogTable = async () => {
        var item = {
            disciplineId: this.state.dialogDisciplineId,
            activityTypeId: this.state.dialogActivityTypeId
        }
        console.log(item);
        if (item.disciplineId > 0 || item.activityTypeId > 0) {
            const response2 = await handleRequest("POST", "/WFActivity/getSelectedActivities", item);

            console.log(response2);
            if (response2.data.length === 0) {
                this.setState({
                    dialogActivityList: []
                });
            }

            if (response2.data.length !== 0) {

                this.setState({
                    dialogActivityList: response2.data
                });

            }
        }

    }

    calculateDuration = () => {

        var myObj = this.state.durationObj;
        var types = this.state.durationTypes;
        const index = this.state.durationTypes.findIndex(p => p.value === this.state.durationType);
        console.log(index);
        if (index >= 0 && this.state.durationNumber > 0) {
            console.log(this.state.durationType);
            var durationDesc = this.state.duration + this.state.durationNumber + " " + types[index].label + "s ";

            myObj[types[index].label.toLowerCase()] = this.state.durationNumber;

            types.splice(0, index + 1);

            this.setState({
                durationObj: myObj,
                duration: durationDesc,
                durationTypes: types
            });
        }
        else {
            this.showError("Please type duration inputs !");
        }


    }

    undoDuration = () => {
        if (this.state.duration.trim() !== "") {
            var pObj = this.state.durationObj;

            if (pObj !== null) {
                if (pObj.second && pObj.second > 0)
                    pObj.second = 0;
                else if (pObj.minute && pObj.minute > 0)
                    pObj.minute = 0;
                else if (pObj.hour && pObj.hour > 0)
                    pObj.hour = 0;
                else if (pObj.day && pObj.day > 0)
                    pObj.day = 0;
                else if (pObj.month && pObj.month > 0)
                    pObj.month = 0;
                else if (pObj.year && pObj.year > 0)
                    pObj.year = 0;
                else pObj = {};

            }
            var types = _DurationTypes.slice();
            var durationDesc = this.getDurationDesc(pObj);
            var index = -1;

            if (pObj !== {}) {
                if (pObj.second && pObj.second > 0)
                    index = 5;
                else if (pObj.minute && pObj.minute > 0)
                    index = 4;
                else if (pObj.hour && pObj.hour > 0)
                    index = 3;
                else if (pObj.day && pObj.day > 0)
                    index = 2;
                else if (pObj.month && pObj.month > 0)
                    index = 1;
                else if (pObj.year && pObj.year > 0)
                    index = 0;

            }

            types.splice(0, index + 1);

            this.setState({
                durationObj: pObj,
                duration: durationDesc,
                durationTypes: types
            });
        }
        else { this.showError("Please firstly type any duration!"); }


    }

    
  calculateDurationStart = () => {

    var myObj = this.state.durationObjStart;
    var types = this.state.durationTypesStart;
    const index = this.state.durationTypesStart.findIndex(p => p.value === this.state.durationTypeStart);
    console.log(index);
    if (index >= 0 && this.state.durationNumberStart > 0) {

      var durationDesc = this.state.durationStart + this.state.durationNumberStart + " " + types[index].label + "s ";

      myObj[types[index].label.toLowerCase()] = this.state.durationNumberStart;

      types.splice(0, index + 1);

      this.setState({
        durationObjStart: myObj,
        durationStart: durationDesc,
        durationTypesStart: types,
        durationTypeStart: '',
      });
    }
    else {
      this.showError("Please type duration inputs !");
    }


  }

  undoDurationStart = () => {
    if (this.state.durationStart.trim() !== "") {
      var pObj = this.state.durationObjStart;

      if (pObj !== null) {
        if (pObj.second && pObj.second > 0)
          pObj.second = 0;
        else if (pObj.minute && pObj.minute > 0)
          pObj.minute = 0;
        else if (pObj.hour && pObj.hour > 0)
          pObj.hour = 0;
        else if (pObj.day && pObj.day > 0)
          pObj.day = 0;
        else if (pObj.month && pObj.month > 0)
          pObj.month = 0;
        else if (pObj.year && pObj.year > 0)
          pObj.year = 0;
        else pObj = {};

      }
      var types = _DurationTypes.slice();
      var durationDesc = this.getDurationDesc(pObj);
      var index = -1;

      if (pObj !== {}) {
        if (pObj.second && pObj.second > 0)
          index = 5;
        else if (pObj.minute && pObj.minute > 0)
          index = 4;
        else if (pObj.hour && pObj.hour > 0)
          index = 3;
        else if (pObj.day && pObj.day > 0)
          index = 2;
        else if (pObj.month && pObj.month > 0)
          index = 1;
        else if (pObj.year && pObj.year > 0)
          index = 0;

      }

      types.splice(0, index + 1);

      this.setState({
        durationObjStart: pObj,
        durationStart: durationDesc,
        durationTypesStart: types
      });
    }
    else { this.showError("Please firstly type any duration!"); }


  }

  removeDurationStart = () => {

    this.setState({
      durationObjStart: {},
      durationStart: "",
      durationNumberStart: "",
      durationTypesStart: _DurationTypes.slice(),
      durationTypeStart: ''
    });
  }


    undoIddlePeriod = () => {
        if (this.state.iddlePeriod.trim() !== "") {
            var pObj = this.state.iddlePeriodObj;

            if (pObj !== null) {
                if (pObj.second && pObj.second > 0)
                    pObj.second = 0;
                else if (pObj.minute && pObj.minute > 0)
                    pObj.minute = 0;
                else if (pObj.hour && pObj.hour > 0)
                    pObj.hour = 0;
                else if (pObj.day && pObj.day > 0)
                    pObj.day = 0;
                else if (pObj.month && pObj.month > 0)
                    pObj.month = 0;
                else if (pObj.year && pObj.year > 0)
                    pObj.year = 0;
                else pObj = {};

            }
            var types = _DurationTypes.slice();
            var durationDesc = this.getDurationDesc(pObj);
            var index = -1;

            if (pObj !== {}) {
                if (pObj.second && pObj.second > 0)
                    index = 5;
                else if (pObj.minute && pObj.minute > 0)
                    index = 4;
                else if (pObj.hour && pObj.hour > 0)
                    index = 3;
                else if (pObj.day && pObj.day > 0)
                    index = 2;
                else if (pObj.month && pObj.month > 0)
                    index = 1;
                else if (pObj.year && pObj.year > 0)
                    index = 0;

            }

            types.splice(0, index + 1);
            this.setState({
                iddlePeriodObj: pObj,
                iddlePeriod: durationDesc,
                iddlePeriodTypes: types
            });
        }
        else { this.showError("Please firstly type any idle duration!"); }


    }

    calculateIddlePeriod = () => {

        var myObj = this.state.iddlePeriodObj;
        var types = this.state.iddlePeriodTypes;
        const index = this.state.iddlePeriodTypes.findIndex(p => p.value === this.state.iddlePeriodType);
        console.log(index);
        if (index >= 0 && this.state.iddlePeriodNumber > 0) {
            console.log(this.state.iddlePeriodType);
            var periodDesc = this.state.iddlePeriod + this.state.iddlePeriodNumber + " " + types[index].label + "s ";

            myObj[types[index].label.toLowerCase()] = this.state.iddlePeriodNumber;

            types.splice(0, index + 1);

            this.setState({
                iddlePeriodObj: myObj,
                iddlePeriod: periodDesc,
                iddlePeriodTypes: types
            });
        }
        else {
            this.showError("Please type iddle period inputs !");
        }


    }

    removeDuration = () => {

        this.setState({
            durationObj: {},
            duration: "",
            durationNumber: "",
            durationTypes: _DurationTypes.slice(),
            durationType: 0
        });
    }

    removeIddlePeriod = () => {

        this.setState({
            iddlePeriodObj: {},
            iddlePeriod: "",
            iddlePeriodNumber: "",
            iddlePeriodTypes: _DurationTypes.slice(),
            iddlePeriodType: 0
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Confirm" icon="pi pi-check" onClick={() => this.confirmSelecteds(name)} />
                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-secondary" />
            </div>
        );
    }

    renderFooter2() {
        return (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={() => this.ok()} />
            </div>
        );
    }

    renderFooter3() {
        return (
            <div>
                <Button label="Save" icon="pi pi-check" onClick={() => this.saveOrder()} />
                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide()} className="p-button-secondary" />
            </div>
        );
    }

    createNew = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hideActivityInputs: false,
                createNewButton: "Stop Adding New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "danger"


            });
            //this.refreshAccordions();
        }
        else {
            this.setState({
                hideActivityInputs: true,
                createNewButton: "Create New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "info"

            });
            //this.refreshAccordions();
            this.resetInputs();
        }
    };

    placeTemplate(rowData) {
        const row = rowData.data;
        var place = '';
        /*  const snc = this.state.placeOfExecutions.findIndex(p=>p.value===row.placeOfExecution);
          if(snc>=0)
          place =this.state.placeOfExecutions[snc].label;*/
        if (row.codePlaceOfExecution !== null && row.codePlaceOfExecution !== undefined)
            place = row.codePlaceOfExecution.name;

        return <> {place}</>;

    }

    setDuration = (pObj) => {
        var result = "";

        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }
        else { pObj = {} }

        this.setState({
            duration: result,
            durationObj: pObj
        })
    }

    setDurationStart = (pObj) => { 
        var result = "";

        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }
        else { pObj = {} }

        this.setState({
            durationStart: result,
            durationObjStart: pObj 
        })
    }

    setIdlePeriod = (pObj) => {
        var result = "";

        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }
        else { pObj = {} }

        this.setState({
            iddlePeriod: result,
            iddlePeriodObj: pObj
        })
    }

    durationTemplate(rowData) {
        const row = rowData.data;
        var result = "";
        var pObj = row.duration;
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }

        return <> {result}</>;
    }

    
    durationTemplateStart(rowData) { 
        const row = rowData.data;
        var result = "";
        var pObj = row.startDuration;
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }

        return <> {result}</>;
    }

    getDurationDesc = (pObj) => {
        var result = "";
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }

        return result;
    }

    idleTemplate(rowData) {
        const row = rowData.data;
        var result = "";
        var pObj = row.idlePeriod;
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }

        return <> {result}</>;
    }

    actionBodyTemplate(rowData) {

        return (
            <SplitButton type="button" icon="pi pi-cog" className="p-button-secondary" model={this.state.splitButtonMenu} ></SplitButton>
        );
    }

    resourcesTemplate(rowData) {

        return (

            <Link
                to={{
                    pathname: "/Resources",
                    data: rowData.data
                }}
            > RESOURCES
            </Link>

        );
    }

    timelineTemplate(rowData) {

        return (

            <Link
                to={{
                    pathname: "/ResourceTimeline",
                    data: rowData.data
                }}
            > Resources Timeline
            </Link>

        );
    }

    deleteActivity = async () => {


        const deletedItem = {
            id: this.state.activityId

        }

        await handleRequest("POST", "/WFActivity/delete", deletedItem);



        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }

    addActivityType = async () => {
        const { newActivityType } = this.state;

        console.log(newActivityType);
        if (newActivityType.length > 0) {

            const newItem = {
                name: newActivityType,
                type: "ACTIVITYTYPE"
            }

            console.log(newItem);
            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                newActivityType: '',
            });

            this.fillCodes();
        }

    };

    addExecution = async () => {
        const { newExecution } = this.state;


        if (newExecution.length > 0) {

            const newItem = {
                name: newExecution,
                type: "PLACEOFEXECUTION"
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                newExecution: '',
            });

            this.fillCodes();
        }

    };

    template(item) {
        //var imageSource = 'showcase/resources/demo/images/car/' + car.brand + '.png';
        //<img src={imageSource} alt={car.brand} style={{ display: 'inline-block', margin: '2px 0 2px 2px', width: 48 }} />

        return (
            <div className="p-clearfix">
                <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}>{item.activityName}</div>
            </div>
        );
    }

    removeTemplate(rowData) {

        return <AntButton type="dashed" onClick={() => this.removeSelectedPre(rowData)} shape="circle" icon={<CloseOutlined />} />;

    }

    removeSelectedPre = (row) => {

        console.log(row);
        const list = this.state.selectedPreActivities;
        const index = list.findIndex(p => p.id === row.id);

        list.splice(index, 1);

        this.setState({
            selectedPreActivities: list
        })

    }

    onResponsibilityChange = (value) => {

        console.log(value); 
        this.setState({
            responsibilityNodesValues: value

        })

    }



    render() {

        const home = { icon: 'pi pi-home' };
        return (
            <div>
                <div className="content-section">
                    <Toast ref={(el) => this.toast = el} />
                </div>
                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>

                        <Col xs={9}>

                        </Col>

                        <Col xs={3}>
                            <BootButton onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</BootButton>
                        </Col>
                    </Row>
                </div>
                <div hidden={this.state.hideActivityInputs} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                        {<FormattedMessage id="GENERAL.DEPARTMENT" />} 
                                         </Form.Label>
                                        <Col sm="4">
                                            <Select size='large'
                                                style={{ width: '100%' }}
                                                disabled={this.state.isFlowchartPocess}
                                                value={this.state.departmentId}
                                                onChange={(value) => {
                                                    this.setState({
                                                        departmentId: value
                                                    })
                                                }}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.departments.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                ))}
                                            </Select>

                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Discipline :
                                         </Form.Label>
                                        <Col sm="4">
                                            <Select size='large'
                                                style={{ width: '100%' }}
                                                disabled={this.state.isFlowchartPocess}
                                                value={this.state.disciplineId}
                                                onChange={(value) => {
                                                    this.setState({
                                                        disciplineId: value
                                                    })
                                                }}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.disciplines.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Activity Type :
                                                    </Form.Label>
                                        <Col sm="4">


                                            <Select size='large'
                                                style={{ width: '100%' }}
                                                disabled={this.state.isFlowchartPocess}
                                                value={this.state.activityType}
                                                onChange={(value) => {
                                                    this.setState({
                                                        activityType: value
                                                    })
                                                }}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Input style={{ flex: 'auto' }} value={this.state.newActivityType} onChange={(event) => { this.setState({ newActivityType: event.target.value }) }} />
                                                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                onClick={this.addActivityType}>
                                                                <PlusOutlined /> Add item
                                                               </a>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.activityTypes.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>

                                        </Col>
                                    </Form.Group>


                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Activity Description :
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="activityDescription" value={this.state.activityDescription}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Place of Execution:
                                                    </Form.Label>
                                        <Col sm="4">

                                            <Select size='large'
                                                style={{ width: '100%' }}

                                                value={this.state.placeOfExecution}
                                                onChange={(value) => {
                                                    this.setState({
                                                        placeOfExecution: value
                                                    })
                                                }}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                            <Input style={{ flex: 'auto' }} value={this.state.newExecution} onChange={(event) => { this.setState({ newExecution: event.target.value }) }} />
                                                            <a
                                                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                onClick={this.addExecution}
                                                            >
                                                                <PlusOutlined /> Add item
                                                               </a>
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.placeOfExecutions.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Prerequisition:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Button type="Button" label="Select" icon="pi pi-external-link" onClick={() => this.onClickPrerequisition('displayPreDialog')} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <div hidden={this.state.selectedPreActivities.length > 0 ? false : true}>
                                            <DataTable value={this.state.selectedPreActivities}  >

                                                <Column field="codeDesc" header="Code" />
                                                <Column field="activityName" header="Activity Name" />
                                                <Column body={this.removeTemplate} header="Remove" />
                                            </DataTable>
                                        </div>
                                    </Form.Group>



                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">

                                        </Form.Label>
                                        <Col sm="1">
                                            <Switch disabled={this.state.isFlowchartPocess} checkedChildren="Timeline" unCheckedChildren="OnWorkflow" defaultChecked checked={this.state.type} onChange={(value) => {
                                                this.setState({ type: value })
                                            }} />
                                        </Col>
                                    </Form.Group>
                                    {this.state.parentActivityId!=0 && <Form.Group hidden={!this.state.type} style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Starting Point:
                                                    </Form.Label>
                                        <Col sm="1">
                                            <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumberStart} onChange={(e) => this.setState({ durationNumberStart: e.target.value })} />
                                        </Col>

                                        <Col sm="2">
                                            <PrimeDropdown style={{ width: '100%', marginRight: '1rem' }} value={this.state.durationTypeStart} options={this.state.durationTypesStart} onChange={(e) => this.setState({ durationTypeStart: e.target.value })}
                                                placeholder="Select ..." />

                                        </Col>
                                        <Col sm="1">
                                            <Button type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDurationStart} />
                                            <Button type="Button" icon="pi pi-undo" onClick={this.undoDurationStart} />
                                        </Col>


                                        <Col sm="4">
                                            <InputText style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.durationStart} />

                                        </Col>
                                        <Col sm="1">
                                            <Button type="Button" icon="pi pi-times" onClick={this.removeDurationStart} className="p-button-danger" /> 
                                        </Col>
                                    </Form.Group>}
                                    <Form.Group hidden={!this.state.type} style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Duration:
                                                    </Form.Label>
                                        <Col sm="1">
                                            <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.durationNumber} onChange={(e) => this.setState({ durationNumber: e.target.value })} />
                                        </Col>

                                        <Col sm="2">
                                            <PrimeDropdown style={{ width: '100%', marginRight: '1rem' }} value={this.state.durationType} options={this.state.durationTypes} onChange={(e) => this.setState({ durationType: e.target.value })}
                                                placeholder="Select ..." />

                                        </Col>
                                        <Col sm="1">
                                            <Button type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateDuration} />
                                            <Button type="Button" icon="pi pi-undo" onClick={this.undoDuration} />
                                        </Col>


                                        <Col sm="4">
                                            <InputText style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.duration} />

                                        </Col>
                                        <Col sm="1">
                                            <Button type="Button" icon="pi pi-times" onClick={this.removeDuration} className="p-button-danger" />
                                        </Col>
                                    </Form.Group>
                                    {/* <Form.Group hidden={!this.state.type} style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Idle period
                                            (Waiting period for following activity) :
                                                    </Form.Label>
                                        <Col sm="1">
                                            <InputText style={{ width: '100%' }} type="text" keyfilter="pint" value={this.state.iddlePeriodNumber} onChange={(e) => this.setState({ iddlePeriodNumber: e.target.value })} />
                                        </Col>

                                        <Col sm="2">
                                            <PrimeDropdown style={{ width: '100%', marginRight: '1rem' }} value={this.state.iddlePeriodType} options={this.state.iddlePeriodTypes} onChange={(e) => this.setState({ iddlePeriodType: e.target.value })}
                                                placeholder="Select ..." />

                                        </Col>
                                        <Col sm="1">
                                            <Button type="Button" style={{ marginRight: '5px' }} icon="pi pi-arrow-right" onClick={this.calculateIddlePeriod} />
                                            <Button type="Button" icon="pi pi-undo" onClick={this.undoIddlePeriod} />
                                        </Col>

                                        <Col sm="4">
                                            <InputText style={{ width: '100%', marginRight: '1rem' }} type="text" value={this.state.iddlePeriod} />

                                        </Col>
                                        <Col sm="1">
                                            <Button type="Button" icon="pi pi-times" onClick={this.removeIddlePeriod} className="p-button-danger" />
                                        </Col>
                                    </Form.Group> */}

                                    {/* <Form.Group hidden={!this.state.type} style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Idle Description :
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="idleDescription" value={this.state.idleDescription}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group> */}

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Remarks:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control as="textarea" rows="3" name="remarks" value={this.state.remarks}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Responsibility:
                                                    </Form.Label>
                                        <Col sm="3">
                                            <Cascader size="large" style={{ width: '100%' }} options={this.state.responsibilityNodes} onChange={this.onResponsibilityChange} value={this.state.responsibilityNodesValues} changeOnSelect />
                                            {/* <Button type="Button" label="Select" icon="pi pi-external-link" onClick={() => this.onClickUser('displayUserDialog')} /> */}
                                        </Col>

                                        <Form.Label style={{ color: 'black' }} column sm="1">
                                            User:
                                      </Form.Label>
                                        <Col sm="4">
                                            <Select size="large" mode="tags" style={{ width: '100%' }} placeholder="Select Users" value={this.state.userKeys} onChange={(value) => {
                                                this.setState({
                                                    userKeys: value
                                                });
                                            }}>
                                                {this.state.users.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>
                                        </Col>

                                        {/* <Col sm="7">
                                            <BreadCrumb model={this.state.itemsPath} home={home} />
                                        </Col> */}
                                    </Form.Group>



                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">

                                        <Col sm="3">
                                        </Col>
                                        <Col sm="4">
                                            <BootButton type="button" style={{ width: '100%' }} onClick={this.saveActivity} variant={this.state.lastButtonVariant} >{this.state.lastButton}</BootButton>
                                        </Col>
                                    </Form.Group>



                                </Form>

                            </Container></Card.Body>
                    </Card>
                </div>
                <div hidden={!this.state.isFlowchartPocess} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>

                        <Form.Group style={{ marginBottom: '1rem', marginTop: '1rem' }} as={Row} controlId="controlVision">  <Col xs={12}>

                            {Boolean(this.state.flowChartData) && this.state.flowChartData.length > 0 && <OrganizationChart value={this.state.flowChartData} selectionMode="single" selection={this.state.selectedNode} onSelectionChange={event => this.setState({ selectedNode: event.data })}></OrganizationChart>
                            }
                        </Col>
                        </Form.Group>
                    </Card>
                </div>
                <Dialog header="Select Prerequisitions" position={this.state.position} visible={this.state.displayPreDialog} style={{ width: '50vw' }} onHide={() => this.onHide('displayPreDialog')} footer={this.renderFooter('displayBasic2')}>
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Discipline :
                                                    </Form.Label>
                                        <Col sm="4">

                                            <Select size='large'
                                                style={{ width: '100%' }}
                                                value={this.state.dialogDisciplineId}
                                                onChange={(value) => {
                                                    this.setState({
                                                        dialogDisciplineId: value
                                                    })

                                                    setTimeout(
                                                        function () {
                                                            this.refreshDialogTable();
                                                        }
                                                            .bind(this),
                                                        100);

                                                }}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.disciplines.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                ))}
                                            </Select>


                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Activity Type :
                                                    </Form.Label>
                                        <Col sm="4">

                                            <Select size='large'
                                                style={{ width: '100%' }}

                                                value={this.state.dialogActivityTypeId}
                                                onChange={(value) => {
                                                    this.setState({
                                                        dialogActivityTypeId: value
                                                    })
                                                    setTimeout(
                                                        function () {
                                                            this.refreshDialogTable();
                                                        }
                                                            .bind(this),
                                                        100);
                                                }}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.activityTypes.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                                ))}
                                            </Select>

                                        </Col>
                                    </Form.Group>

                                </Form>

                                <DataTable value={this.state.dialogActivityList}
                                    selection={this.state.selectedPreActivities} onSelectionChange={e => this.setState({ selectedPreActivities: e.value })}>
                                    <Column selectionMode="multiple" />
                                    <Column field="codeDesc" header="Code" />
                                    <Column field="activityName" header="Activity Name" />

                                </DataTable>

                            </Container></Card.Body>
                    </Card>
                </Dialog>

                <Dialog position="center" style={{ width: '20vw' }} modal={true} header="Select Responsibility" visible={this.state.displayUserDialog} onHide={() => this.onHide('displayUserDialog')} footer={this.renderFooter2()}>

                    <Form>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                            <Col sm="2">
                            </Col>
                            <Col sm="8">
                                <SlideMenu viewportHeight={350} menuWidth={250} model={this.state.items} ></SlideMenu>
                            </Col>

                            <Col sm="2">
                            </Col>
                        </Form.Group>
                    </Form>

                </Dialog>

                <Dialog position="center" style={{ width: '20vw' }} modal={true} header="Order Settings" visible={this.state.orderModal} onHide={() => this.onHide('displayUserDialog')} footer={this.renderFooter3()}>

                    <Form>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                            <Col sm="12">

                                {this.state.orderItemList.length > 0 && <OrderList value={this.state.orderItemList} responsive={true} itemTemplate={this.template}
                                    onChange={(e) => this.setState({ orderItemList: e.value })}></OrderList>}

                            </Col>

                        </Form.Group>
                    </Form>

                </Dialog>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}>
                            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.modalActivityDeleteShow}
                                onHide={() => this.setState({ modalActivityDeleteShow: false, activityId: 0 })}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Activity ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>All informations about activity will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <BootButton variant="secondary" onClick={() => this.setState({ modalActivityDeleteShow: false, activityId: 0 })}>Close</BootButton>
                                    <BootButton variant="danger" onClick={this.deleteActivity} >Delete</BootButton>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>

                    </Row>
                </Container>

                <div className="content-section implementation">

                    <h3>Activities</h3>

                    <TreeTable value={this.state.nodes} expandedKeys={this.state.expandedKeys} selectionMode="single" selectionKeys={this.state.selectedNodeKey} onSelectionChange={e => this.setState({ selectedNodeKey: e.value })}
                        onToggle={e => this.setState({ expandedKeys: e.value })} style={{ marginTop: '.5em', marginBottom: '.5em' }}>
                        {/* <Column field="index" header="S/N" ></Column> */}
                        <Column field="codeDesc" header="Activity Code" expander></Column>
                        <Column field="activityName" header="Activity Description"></Column>
                        <Column field="department.departmentName" header="Department" ></Column>
                        <Column field="discipline.disciplineName" header="Discipline" ></Column>
                        <Column field="codeActivityType.name" header="Activity Type"></Column>
                        <Column body={this.placeTemplate} header="Place of Executıon"></Column>
                        <Column body={this.durationTemplateStart} header="Starting Point"></Column>
                        <Column body={this.durationTemplate} header="Duration"></Column>
                        {/* <Column body={this.idleTemplate} header="Idle Duration"></Column> */}
                        {/* <Column field="idleDescription" header="Idle Description"></Column> */}
                        <Column body={this.actionBodyTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
                        <Column body={this.resourcesTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />
                        <Column body={this.timelineTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />

                    </TreeTable>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog) 
