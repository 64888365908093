
/* eslint-disable */ 

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_BASE } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button as PrimeButton } from 'primereact/button';
import { Col, Row, Form, Modal, Button as ReactButton } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import EquipmentLogSub from './EquipmentLogSub';
import { handleRequest } from '../../redux/config/env';
import "./css/ShipmentLog.scss";
import { Cascader, Button as AntdButton, Switch, Upload, Avatar } from 'antd';
import 'antd/dist/antd.css';
import $ from 'jquery';
import { UploadOutlined } from '@ant-design/icons';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

class EquipmentLog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedConfirmation: null,
            globalFilter: null,
            showGlobalFilter: null,
            selectedDepartments: null,
            selectedDisciplines: null,
            disciplinesForFilter: [],
            departmentsForFilter: [],
            equipmentTypeId: 0,
            equipmentLogId: 0,
            equipmentLogs: [],
            expandedRows: null,
            addModal: false,
            promptModal: false,
            equipmentCode: "",
            equipmentDescription: "",
            department: "Select Department",
            discipline: "Select Discipline",
            departmentId: null,
            disciplineId: null,
            nodes: [],
            expandIconPosition: 'left',
            loading: false,
            visible: false,
            values: [],
            selectedItems: [],
            values1: [],
            selectedItems1: [],
            newItem: '',
            parentId: null,
            companyEquipment: true,

            PhotoFileUpload: [],
            photoPath: "",
            fileList: [],
        };

        this.actionTemplate = this.actionTemplate.bind(this);
        this.equipmentCodeTemplate = this.equipmentCodeTemplate.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.onDepartmentFilterChange = this.onDepartmentFilterChange.bind(this);
        this.onDisciplineFilterChange = this.onDisciplineFilterChange.bind(this);
        this.onEquipmentTypeFilterChange = this.onEquipmentTypeFilterChange.bind(this);
        this.renderDownloadButton = this.renderDownloadButton.bind(this);

    }

    componentDidMount = async () => {
        const response4 = await handleRequest("GET", "/EquipmentLog/getAll");

        if (response4.data.length === 0) {
            this.setState({
                equipmentLogs: [],
            });
        }

        if (response4.data.length !== 0) {
            this.setState({
                equipmentLogs: response4.data,
            });
        }

        const response = await handleRequest("GET", "/Department/getAll");

        if (response.data.length === 0) {
            this.setState({
                departments: []
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() }
            })

            this.setState({
                departmentsForFilter: options,
            });
        }

        const response2 = await handleRequest("GET", "/Discipline/getAll");

        if (response2.data.length !== 0) {

            const columns2 = response2.data;

            const options2 = columns2.map(function (row) {
                return { name: row.disciplineName.toString(), id: row.id.toString() }
            })

            this.setState({
                disciplinesForFilter: options2,
            });
        }

        const response3 = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response3.length === 0 || response3.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data
            });
        }


    }

    rowExpansionTemplate(data) {
        return (
            <EquipmentLogSub row={data} />
        );
    }

    renderEquipmentTypeFilter() {
        return (
            <Cascader options={this.state.nodes} onChange={this.onEquipmentTypeFilterChange} changeOnSelect />
        );
    }

    onEquipmentTypeFilterChange(value, data) {
        //-------------------------------------------------------
        //ÇALIŞAN KISIM -- Seçili tüm equipmentTypeName'lere göre filtre yapar.
        var flagNamesArray = data.map(o => o.label);
        if (flagNamesArray.length !== 0) {
            this.dt.filter(flagNamesArray, 'equipmentTypeName', 'in');
        } else {
            this.dt.filter('', 'equipmentTypeName', 'in');
        }
        //-------------------------------------------------------
    }

    renderDepartmentFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDepartments} options={this.state.departmentsForFilter}
                onChange={this.onDepartmentFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDepartmentFilterChange(event) {
        this.dt.filter(event.value, 'department.departmentName', 'in');
        this.setState({ selectedDepartments: event.value });
    }

    renderDisciplineFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDisciplines} options={this.state.disciplinesForFilter}
                onChange={this.onDisciplineFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDisciplineFilterChange(event) {
        this.dt.filter(event.value, 'discipline.disciplineName', 'in');
        this.setState({ selectedDisciplines: event.value });
    }

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteEquipmentLog.bind(this)}
                onEditRow={this.editEquipmentLog.bind(this)} />
        </React.Fragment>;
    }

    equipmentCodeTemplate(rowData, column) {
        if (rowData.companyEquipment === false)
            return "R-" + rowData.equipmentCode;
        else
            return rowData.equipmentCode;
    }

    deleteEquipmentLog = (rowData) => {

        console.log(rowData);

        this.setState({
            deleteModal: true,
            equipmentLogId: rowData.id
        });
    }

    deleteEquipmentLogOK = async () => {
        const deletedEquipmentLog = {
            id: this.state.equipmentLogId
        }

        await handleRequest("PUT", "/EquipmentLog/delete", deletedEquipmentLog);

        this.setState({
            deleteModal: false
        });
        this.restartTable();
    }

    restartTable = async () => {

        const response = await handleRequest("GET", "/EquipmentLog/getAll");

        if (response.data.length === 0) {
            this.setState({
                equipmentLogs: [],
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                equipmentLogs: response.data,
            });
        }
    }

    showOrHideModalDeleteEquipmentLog = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalAddEquipmentLog = () => {
        this.resetInputs();
        this.setState({
            addModal: !this.state.addModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalPromptEquipmentLog = () => {
        this.setState({
            addModal: false,
            promptModal: !this.state.promptModal,
            equipmentLogId: 0,
            equipmentCode: "",
            equipmentDescription: "",
            department: "Select Department",
            discipline: "Select Discipline",
            values: [],
            departmentId: null,
            disciplineId: null,
            companyEquipment: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    editEquipmentLog = (row) => {
        console.log("AAA", row);

        var flagValues = getParents(this.state.nodes, row.equipmentTypeId).parents;
        flagValues.push(row.equipmentTypeId);

        function getParentsHelper(tree, id, parents) {

            if (tree.data.id == id) {
                return {
                    found: true,
                    parents: parents
                };
            }
            var result = {
                found: false,
            }
            if (tree.children) {
                $.each(tree.children, function (index, subtree) {
                    var maybeParents = $.merge([], parents);
                    if (tree.data.id != undefined) {
                        maybeParents.push(tree.data.id);
                    }
                    var maybeResult = getParentsHelper(subtree, id, maybeParents);
                    if (maybeResult.found) {
                        result = maybeResult;
                        return false;
                    }
                });
            }
            return result;
        }

        function getParents(data1, id) {
            var tree = {
                data: {},
                children: data1
            }
            return getParentsHelper(tree, id, []);
        }

        this.setState({
            promptModal: true,
            equipmentLogId: row.id,
            equipmentCode: row.equipmentCode,
            equipmentDescription: row.equipmentDescription,
            equipmentType: row.equipmentTypeName,
            values: flagValues,
            equipmentTypeId: row.equipmentTypeId,
            department: row.department.departmentName,
            departmentId: row.department.id,
            discipline: row.discipline.disciplineName,
            disciplineId: row.discipline.id,
            companyEquipment: row.companyEquipment,
            photoPath: row.photoPath
        });
    }

    handleChangeDiscipline = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.disciplinesForFilter.length; z++) {
            if (this.state.disciplinesForFilter[z].name === e.target.value) {
                idValue = this.state.disciplinesForFilter[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            disciplineId: idValue
        });
    }

    handleChangeDepartment = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.departmentsForFilter.length; z++) {
            if (this.state.departmentsForFilter[z].name === e.target.value) {
                idValue = this.state.departmentsForFilter[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            departmentId: idValue
        });
    }

    onChange = (value, data) => {
        this.setState({
            values: value,
            selectedItems: data
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = () => {
        this.setState({ loading: true });
        const length = this.state.values.length;

        if (length > 0)
            this.setState({ parentId: this.state.values[length - 1] });

        setTimeout(() => {
            this.setState({ loading: false, visible: false });
            this.saveItem();
        }, 1000);
    }

    handleCancel = () => {
        this.setState({ visible: false });
        this.resetInputs();

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    saveItem = async () => {
        if (this.state.newItem.trim() !== "") {

            const newObj = {
                equipmentTypeName: this.state.newItem,
                parentId: this.state.parentId
            }

            await handleRequest("POST", "/EquipmentType/add", newObj);

            const response3 = await handleRequest("GET", "/EquipmentType/getNodes");

            if (response3.length === 0 || response3.data.length === 0) {
                this.setState({
                    nodes: []
                });
            } else if (response3.data.length !== 0) {
                this.setState({
                    nodes: response3.data
                });
            }

            this.resetInputs();
        }
    }

    onChangeInput = ({ target: { value } }) => {
        //console.log(value);
        this.setState({ newItem: value });
    }

    resetInputs = () => {
        this.setState({
            parentId: null,
            newItem: '',
            PhotoFileUpload: [],
            photoPath: "",
            fileList: [],
        });
    }

    saveEquipmentLogOK = async () => {
        if (this.state.equipmentCode !== "" && this.state.equipmentDescription !== "" && this.state.values.length !== 0
            && this.state.departmentId !== null && this.state.disciplineId !== null) {

            if (this.state.equipmentLogId === 0) {
                const newEquipmentLog = {
                    companyEquipment: this.state.companyEquipment,
                    equipmentCode: this.state.equipmentCode,
                    equipmentDescription: this.state.equipmentDescription,
                    equipmentTypeId: this.state.values[this.state.values.length - 1],
                    departmentId: this.state.departmentId,
                    disciplineId: this.state.disciplineId,
                    photoPath: this.state.photoPath
                }

                await handleRequest("POST", "/EquipmentLog/add", newEquipmentLog);
            } else {
                const editEquipmentLog = {
                    id: this.state.equipmentLogId,
                    companyEquipment: this.state.companyEquipment,
                    equipmentCode: this.state.equipmentCode,
                    equipmentDescription: this.state.equipmentDescription,
                    equipmentTypeId: this.state.values[this.state.values.length - 1],
                    departmentId: this.state.departmentId,
                    disciplineId: this.state.disciplineId,
                    photoPath: this.state.photoPath
                }

                await handleRequest("PUT", "/EquipmentLog/update", editEquipmentLog);
            }

            this.restartTable();
            this.refreshValues();
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0px";
            }, 500);
        }
    }

    refreshValues = () => {
        this.setState({
            promptModal: false,
            equipmentLogId: 0,
            equipmentCode: "",
            equipmentDescription: "",
            department: "Select Department",
            discipline: "Select Discipline",
            values: [],
            departmentId: null,
            disciplineId: null,
            companyEquipment: true,
            PhotoFileUpload: [],
            photoPath: "",
            fileList: [],
        });
    }

    renderDownloadButton(rowData, column) {
        if (rowData !== null && rowData !== undefined && rowData.photoPath !== null && rowData.photoPath !== undefined && rowData.photoPath !== "")
            return <Avatar size={64} src={rowData.photoPath} onClick={() => this.showReport(rowData.photoPath)} />
        return "";
    };

    showReport = async (fileRealUrl) => {
        try {
            if (fileRealUrl.endsWith('.pdf')) {
                this.setState({
                    pdfFile: fileRealUrl,
                    modalPdfViewer: true,
                });
            } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
                || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {

                this.setState({
                    imageUrl: fileRealUrl,
                    modalPhotoShow: true,
                })
            } else {
                window.location.href = fileRealUrl;
            }
        } catch (error) {
            this.showError("File cant found.");
        }
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    render() {
        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        let optionsDepartments = this.state.departmentsForFilter.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDisciplines = this.state.disciplinesForFilter.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        const equipmentTypeFilter = this.renderEquipmentTypeFilter();
        const departmentFilter = this.renderDepartmentFilter();
        const disciplineFilter = this.renderDisciplineFilter();
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteEquipmentLog}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Equipment Log Data?
                            </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related datas.
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteEquipmentLog}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteEquipmentLogOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalAddEquipmentLog}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">New Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactButton onClick={this.showOrHideModalPromptEquipmentLog} style={{ width: '100%', marginBottom: '2rem' }} variant="success">Prompt Record</ReactButton>
                        <ReactButton onClick={this.showOrHideModalAddEquipmentLog} style={{ width: '100%', marginBottom: '1rem' }} variant="warning">Normal Record</ReactButton>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.visible}
                    onHide={this.handleCancel}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">New Equipment Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>New Equipment Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={this.onChangeInput} value={this.state.newItem} />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.handleCancel}>Close</ReactButton>
                        <ReactButton variant="info" onClick={this.handleOk}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.promptModal}
                    onHide={this.showOrHideModalPromptEquipmentLog}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">New Record</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Switch checkedChildren="Company" unCheckedChildren="Rental" checked={this.state.companyEquipment} defaultChecked onChange={(value) => {
                                        this.setState({ companyEquipment: value })
                                    }} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Code:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ equipmentCode: e.target.value })} value={this.state.equipmentCode} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Description:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ equipmentDescription: e.target.value })} value={this.state.equipmentDescription} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Type:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Cascader defaultValue={this.state.values} options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <AntdButton onClick={this.showModal} type="primary">Add New</AntdButton>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Department:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="department" value={this.state.department}
                                        onChange={this.handleChangeDepartment} as="select">
                                        <option value="">
                                            Select Department
                                        </option>
                                        {optionsDepartments}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Discipline:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="discipline" value={this.state.discipline}
                                        onChange={this.handleChangeDiscipline} as="select">
                                        <option value="">
                                            Select Discipline
                                        </option>
                                        {optionsDisciplines}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col}>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Upload {...props}
                                        showUploadList={true}
                                        fileList={this.state.PhotoFileUpload}
                                        onChange={({ fileList }) => {
                                            if (fileList.length == 1) {
                                                var photoPath = "";
                                                if (Boolean(fileList[0].response)) {
                                                    photoPath = fileList[0].response.url;
                                                }
                                                this.setState({
                                                    PhotoFileUpload: fileList,
                                                    photoPath: photoPath,
                                                });
                                            }
                                        }}
                                        beforeUpload={(fileList) => {
                                            if (this.state.PhotoFileUpload.length > 0) {
                                                this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                                                return false;
                                            }
                                            return true;
                                        }}
                                        onRemove={({ fileList }) => {
                                            this.setState({
                                                PhotoFileUpload: [],
                                                photoPath: "",
                                            })
                                        }
                                        }>
                                        <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                            <UploadOutlined /> <FormattedMessage id="EQLAttachPhoto" defaultMessage="Attach Photo" />
                                        </AntdButton>
                                    </Upload>
                                </Form.Group>

                                <Form.Group as={Col}>
                  
                                {(this.state.photoPath !== null && this.state.photoPath !== undefined && this.state.photoPath !== null && this.state.photoPath !== undefined && this.state.photoPath !== "") ?    
                                    <Avatar size={64} src={this.state.photoPath} onClick={() => this.showReport(this.state.photoPath)} /> : ""
                                }
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalPromptEquipmentLog}>Close</ReactButton>
                        <ReactButton variant="info" onClick={this.saveEquipmentLogOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.imageUrl !== "" ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                            />
                        ) : (
                                <p>Photo has not been uploaded.</p>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="info" onClick={this.showOrHidePhotoModal}>
                            Close
                    </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                        <Button style={{ marginLeft: "1rem" }}
                        onClick={this.printPdf}
                        >
                        Print
                    </Button>
                        <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Voucher PDF File Link">
                        <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Voucher PDF File Link is here: " subject="Voucher PDF File Link">
                        <EmailIcon size={32} round />
                        </EmailShareButton>
                        <WhatsappShareButton
                        style={{ marginLeft: "1rem" }}
                        url={this.state.pdfFile}
                        title="Voucher PDF File Link"
                        separator=": "
                        >
                        <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </div> */}

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                            </div>
                        </Worker>

                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <div style={{ padding: "8px 16px 8px 24px" }}>
                    <Row>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><ReactButton onClick={this.showOrHideModalAddEquipmentLog} style={{ width: '100%' }} >Create New</ReactButton></Col>
                    </Row>
                </div>

                <div className="datatable-doc-demo">
                    <Card>
                        <div className="content-section implementation">
                            <DataTable ref={(el) => this.dt = el} responsive={true} value={this.state.equipmentLogs} globalFilter={this.state.globalFilter} paginator={true}
                                emptyMessage="No Equipment Logs Found" rowsPerPageOptions={[5, 10, 20]} rows={10} expandedRows={this.state.expandedRows}
                                onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                rowExpansionTemplate={this.rowExpansionTemplate} >
                                <Column expander={true} style={{ textAlign: 'center', width: '4em' }} />
                                <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '5em' }} />
                                <Column field="index" header="S/N" style={{ textAlign: 'center', width: '5em' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentTypeName" header="Equipment Type" sortable={true} filter={true} filterElement={equipmentTypeFilter} />
                                <Column header="Equipment Code" body={this.equipmentCodeTemplate} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentDescription" header="E. Description" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="department.departmentName" header="Department" sortable filter filterElement={departmentFilter} />
                                <Column field="discipline.disciplineName" header="Discipline" sortable filter filterElement={disciplineFilter} />
                                <Column body={this.renderDownloadButton} header="Photo" sortable={true} filter={true} filterMatchMode="contains" ></Column>

                                <Column field="Status" header="Status" style={{ width: '200px' }} sortable={true} filter={true} filterMatchMode="contains" />
                            </DataTable>
                        </div>
                    </Card>
                </div>

            </div>
        )
    }
};

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentLog)