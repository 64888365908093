/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env'; 
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Col, Row, Modal, Form,  Button as BootButton } from 'react-bootstrap';

export class InspectionLevel extends Component {

    constructor() {
        super();
        this.state = {
            modalShow: false, 
            hidden: true,
            displayBasic: false,
            position: 'center',
            explanation: "",
            abbraviation:"",
            selectedIncpectionLevel: null,
            buttonName: "Save",
            buttonClassName: "p-button-success",
            incpectionLevels: []
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true,
            selectedIncpectionLevel: rowData
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteIncpectionLevel(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    actionTemplate2(rowData, column) {
        return <React.Fragment>
            <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{ marginRight: '.5em' }}></Button>
            <Dialog header="Delete This Incpection Level Record?" visible={this.state.displayBasic} style={{ width: '25vw' }} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
                <p>This discipline record will be deleted. Are you sure?</p>
            </Dialog>
            <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>
        </React.Fragment>;
    };

    actionTemplate(rowData, column) {
        return  <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />   </React.Fragment> ;
    };

    deleteModal = (row) => {
        
        this.setState({
            modalShow: true,
            selectedDiscipline: row
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }


    componentDidMount = async () => {

        const response = await handleRequest("GET", "/IncpectionLevel/getAll");

        if (response.data.length === 0){
            this.setState({
                incpectionLevels: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                incpectionLevels: response.data,
            });
        }
    };
    
    saveIncpectionLevel = async () => {
        if (this.state.buttonName === "Save" && this.state.abbraviation !== ""){

            const newIncpectionLevel = {
                explanation : this.state.explanation,
                abbraviation : this.state.abbraviation,
            }

            var response = await handleRequest("POST", "/IncpectionLevel/add", newIncpectionLevel);
    
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS")
               {
                    this.changeButtonSave();
                    this.restartTable();
                    this.showSuccess();
               } 
               else  {
                    this.showError("An error was occured please try again later !"); 
               }  
            }
            
        }
        else if(this.state.buttonName === "Update" && this.state.abbraviation !== "" ){
            const updatedIncpectionLevel = {
                explanation : this.state.explanation,
                abbraviation : this.state.abbraviation,
                id: this.state.selectedIncpectionLevel.id,
                active: this.state.selectedIncpectionLevel.active
            }
            
            var response2 = await handleRequest("POST", "/IncpectionLevel/update", updatedIncpectionLevel);

            if(response2.data!==null && response2.data!==undefined){
                if(response2.data==="SUCCESS")
               {
                    this.changeButtonSave();
                    this.restartTable();
                    this.showSuccess();
               } 
               else   {
                    this.showError("An error was occured please try again later !"); 
               }  
            }
        }
        else{
            this.showError();
        }
    };

    restartTable = async () => {

        const response = await handleRequest("GET", "/IncpectionLevel/getAll"); 

        if (response.data.length === 0){
            this.setState({
                incpectionLevels: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                incpectionLevels: response.data,
            });
        }
    }

    deleteIncpectionLevel = async (name) => {

        const deletedIncpectionLevel = {
            id : this.state.selectedIncpectionLevel.id
        }

        await handleRequest("POST", "/IncpectionLevel/delete", deletedIncpectionLevel); 

        console.log(deletedIncpectionLevel);

        this.restartTable();
        this.showSuccess();
        this.onHideModal(name);
    };

    delete = async (name) => {

        const deletedIncpectionLevel = {
            id : this.state.selectedIncpectionLevel.id
        }

        await handleRequest("POST", "/IncpectionLevel/delete", deletedIncpectionLevel); 

        console.log(deletedIncpectionLevel);

        this.restartTable();
        this.showSuccess();
        this.showOrHideModal();
    };

    changeButtonUpdate = (row) => {
        this.setState({
            explanation: row.explanation,
            abbraviation: row.abbraviation,
            hidden: false,
            buttonName: "Update",
            buttonClassName: "p-button-warning"
        });
    };

    changeButtonSave = () => {
        this.setState({
            explanation: "",
            abbraviation: "",
            hidden: true,
            buttonName: "Save",
            buttonClassName: "p-button-success"
        });
      
    };

    changeButtonCancel = () => {
        this.setState({
            explanation: "",
            abbraviation: "",
            hidden: true,
            buttonName: "Save",
            buttonClassName: "p-button-success"
        });
    };


    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>
        return (
            <div>
                <Card style={{ width: '700px' }}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <InputText onChange={(e) => this.setState({ abbraviation: e.target.value })} value={this.state.abbraviation} placeholder="Abbraviation" />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <InputText onChange={(e) => this.setState({ explanation: e.target.value })} value={this.state.explanation} placeholder="Explanation" />
                            </Form.Group> 

                            <Form.Group as={Col}>
                                 <Button id='save-IncpectionLevel' className={this.state.buttonClassName} onClick={this.saveIncpectionLevel} label={this.state.buttonName} />
                                 <Button id='cancel-IncpectionLevel' hidden={this.state.hidden} onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                            </Form.Group>
                        </Form.Row>

                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable ref={(el) => this.dt = el} value={this.state.incpectionLevels} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-incpectionLevels"
                            selectionMode="single" selection={this.state.selectedIncpectionLevel} onSelectionChange={e => this.setState({ selectedIncpectionLevel: e.value })}>
                            <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                            <Column field="abbraviation" header="Abbraviation" filter sortable />
                            <Column field="explanation" header="Explanation" filter sortable />
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        </DataTable>
                    </div>
                    
                    <Modal
                        show={this.state.modalShow}
                        onHide={this.showOrHideModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                            >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete  Incpection Level ?</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            <p>Selected Incpection Level will be deleted. Are you sure?</p>  
                        </Modal.Body> 
                        <Modal.Footer>
                            <BootButton variant="secondary" onClick={this.showOrHideModal}>Cancel</BootButton>
                            <BootButton variant="danger" onClick={this.delete} >Delete</BootButton>  
                        </Modal.Footer>
                    </Modal>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionLevel)