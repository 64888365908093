
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, DatePicker, Upload, Button as ButtonA } from 'antd';
import { API_BASE } from '../../redux/config/env';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';

const { Column } = Table;
const { Option } = Select;
export class ClientReply extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            transmittalDocumentTypeList: [],
            transmittalSelectItems: [],
            transmittalId: '',
            transmittalDocumentTypeId: 0,

            filePath: '',
            fileList: [],
            replyDate: null,
            clientReplyTypeId: 0,
            replyTypeSelectItems: [],

            customerList: [],
            userList: [],
            from:'', 

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }


        this.setComboboxes();
        this.setStandardizations();


        const responseUser = await handleRequest("GET", "/User/getCompanyItems");

        if (Boolean(responseUser) && Boolean(responseUser.data) && responseUser.data.length !== 0) {
            this.setState({
                userList: responseUser.data,
            });
        }

        const response = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (Boolean(response.data) && response.data.length > 0) {
            this.setState({
                customerList: response.data
            });
        }





    }

    save = async () => {


        var id = this.state.transmittalId;
        if (Boolean(id))
            var arr = id.split("~");

        if (Boolean(arr) && arr.length == 2) {
            const obj = {
                id: arr[1],
                replyDate: this.state.replyDate.toDate(),
                clientReplyTypeId: this.state.clientReplyTypeId,
                clientFilePath:this.state.filePath,

            }

            console.log(obj);
            if (arr[0] == 1)
                await handleRequest("POST", "/OutgoingTransmittalDeliverable/clientReply", obj);
            if (arr[0] == 2)
                await handleRequest("POST", "/OutgoingTransmittalDocument/clientReply", obj); 

            this.showSuccess();
            this.resetInputs();
            this.setComboboxes();

        }
        else {
            this.showError("An error was occured !");
        }


    }




    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {

        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;

    }

    setComboboxes = async () => {
        const responseDocument = await handleRequest("GET", "/OutgoingTransmittalDocument/getAllByCompany");
        const responseDeliverable = await handleRequest("GET", "/OutgoingTransmittalDeliverable/getAllByCompany");
        const _outgoingTransmittalList = [];

        if (responseDocument.data.length !== 0) {
            const list = responseDocument.data.filter(p => p.approval === true && p.submittal === true && p.clientReply === false);
            list.forEach(element => {
                element.id = '2~' + element.id;
                element.description = element.transmittalDocumentType.type + ' - ' + element.transmittalMaterialEquipment.description;
                _outgoingTransmittalList.push(element);
            })
        }

        if (responseDeliverable.data.length !== 0) {
            const list = responseDeliverable.data.filter(p => p.approval === true && p.clientReply === false);
            list.forEach(element => {
                element.id = '1~' + element.id;
                element.description = element.deliverable.deliverable;
                _outgoingTransmittalList.push(element);
            })
        }

        this.setState({
            transmittalSelectItems: _outgoingTransmittalList
        });

        const response = await handleRequest("GET", "/ReplyType/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;

            this.setState({
                replyTypeSelectItems: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                replyTypeSelectItems: [],
            });
        }

    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Submittal" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Submittal"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Submittal");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            if (Boolean(result) && Boolean(result.data)) {
                this.setState({
                    formId: result.data.id
                });

                this.getUserSettings();
            }

        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            type: '',
            abbreviation: '',
            transmittalDocumentTypeId: 0,
            transmittalId: 0,
            disciplineId: 0,
            relatedDisciplines: [],
            selectedDisciplineIds: [],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            type: '',
            abbreviation: '',
            transmittalDocumentTypeId: 0,
            transmittalId: 0,
            relatedDisciplines: [],
            selectedDisciplineIds: [],
            disciplineId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            type: '',
            abbreviation: '',
            transmittalDocumentTypeId: 0,
            transmittalId: 0,

            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }



    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen material/equipment will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="Transmittal" />}
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="6">
                                            <Select placeholder="" value={this.state.transmittalId} onChange={(value) => {

                                                var element = this.state.transmittalSelectItems.filter(p => p.id === value);

                                                let to = [];
                                                if (element.toType == 1) {
                                                    if (Boolean(element.toUser) && element.toUser.length > 0 && this.state.userList.length > 0)
                                                        element.toUser.forEach(element => {
                                                            let obj = this.state.userList.find(p => p.id == element);
                                                            to.push(obj.staff.fullName);
                                                            // to.push(obj.username);
                                                        });

                                                    element.to = to.toString();
                                                }

                                                if (element.toType == 2) {
                                                    if (Boolean(element.toClient) && element.toClient.length > 0 && this.state.customerList.length > 0)
                                                        element.toClient.forEach(element => {
                                                            let obj = this.state.customerList.find(p => p.id == element);
                                                            to.push(obj.fullName);
                                                        });

                                                    element.to = to.toString();
                                                }

                                                this.setState({
                                                    transmittalId: value,
                                                    from: element.to
                                                })
                                            }}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.transmittalSelectItems.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.description}</Option>
                                                ))}
                                            </Select>


                                        </Col>
                                        <Col sm="6">
                                        </Col>
                                    </Form.Group>
                                </FormAnt.Item>
                                }


                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="From" />}
                                    name="OrganizationalLevelDesignation" 
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="6">
                                        <Input id="todo" disabled={true} value={this.state.from} />
 
                                        </Col>
                                        <Col sm="6">
                                        </Col>
                                    </Form.Group>
                                </FormAnt.Item>
                                }

                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="Date and Time" />}
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="6">
                                            <DatePicker showTime onOk={(value) => {
                                                this.setState({
                                                    replyDate: value
                                                })
                                            }} value={this.state.replyDate}

                                                format="DD-MM-YYYY HH:mm"
                                            />

                                        </Col>
                                        <Col sm="6">
                                        </Col>
                                    </Form.Group>
                                </FormAnt.Item>
                                }

                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="Client Reply" />} 
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="6">
                                            <Select placeholder="" value={this.state.clientReplyTypeId} onChange={(value) => {
                                                this.setState({
                                                    clientReplyTypeId: value
                                                })
                                            }}
                                            >
                                                <Option key={0} value={0}>Select</Option>
                                                {this.state.replyTypeSelectItems.map(i => (
                                                    <Option key={i.id} value={i.id}>{i.type}</Option>
                                                ))}
                                            </Select>


                                        </Col>
                                        <Col sm="6">
                                        </Col>
                                    </Form.Group>
                                </FormAnt.Item>
                                }

                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="Date and Time" />}
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="6">

                                            <Upload {...props}
                                                showUploadList={true}
                                                fileList={this.state.fileList}
                                                onChange={({ fileList }) => {
                                                    if (fileList.length == 1) {
                                                        var filePath = "";
                                                        if (Boolean(fileList[0].response)) {
                                                            filePath = fileList[0].response.url;
                                                            this.showSuccess("file uploaded successfully");
                                                            // fileName: info.file.name, 
                                                        }
                                                        this.setState({
                                                            fileList: fileList,
                                                            filePath: filePath,
                                                        });
                                                    }
                                                }}
                                                beforeUpload={(fileList) => {
                                                    if (this.state.fileList.length > 0) {
                                                        this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                                onRemove={({ fileList }) => {
                                                    this.setState({
                                                        fileList: [],
                                                        filePath: "",
                                                    })
                                                }
                                                }>
                                                <ButtonA style={{ width: "100%", marginTop: "0.2rem" }}>
                                                    <UploadOutlined />Upload File
                                                    </ButtonA>
                                            </Upload>


                                        </Col>
                                        <Col sm="6">
                                        </Col>
                                    </Form.Group>
                                </FormAnt.Item>
                                }




                                <div>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>




                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>


                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientReply)  