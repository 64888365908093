/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Toast } from 'primereact/toast';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button as PrimeBotton } from "primereact/button";
import { Table } from 'antd';
import { Tree } from 'primereact/tree';
import { API_BASE } from '../../redux/config/env';
import { Avatar } from 'antd';
import { Tree as Tree2 } from 'antd';

const { Column } = Table;
var map = {} ;
var expandedKeys = {} ;
export class MNDProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteModalShow: false,
            hideInputs: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            actionButton: "Save",
            actionButtonVariant: "success",
            loading: false, // For photo upload

            productsId: 0,
            imageUrl: "",
            productName: "",
            code: "",
            selectedNodeKeys: [], 
            nodes: [],
            modalNodes:[],
            productList: null, 
            logoPath:"",

            coverageModalShow: false,
            items:[], 
            halfCheckedKeys:[] , 
            expandedKeys:{}
 
        };

        // #region tags functions


        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

        // #endregion tags functions
        this.restartTable();

    }

    componentDidMount = async () => {

        // var items = MenuConfig.aside.items;

        const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTree");  // getDynamicMenuNodes
       
        this.setState({ 
            nodes: responseDynamicMenu.data,
          
        })

    }

    getAllchilds = (element, list,ids) => {

       
            var obj = { key: element.key, label: element.label, data: "", children: [] }
            
                list.push(obj);
               

            if (element.children) {
                element.children.forEach(el => {
                    if(ids.includes(el.key) ) 
                    this.getAllchilds(el, obj.children,ids);
                  //  list.push(obj); 
                });

            }
               
        


    }


    restartTable = async () => {

        const response = await handleRequest("GET", "/MNDProducts/getAll");



        if (response.data.length !== 0) {

            this.setState({
                productList: response.data
            });
        }
    }

    createNew = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hideInputs: false,
                createNewButton: "Stop Adding New",
                actionButton: "Save",
                actionButtonVariant: "success",
                createNewButtonVariant: "danger",
                productsId: 0,
                productName: "",
                code: "",
                imageUrl: "",
                logoPath:""

            });

        } else {
            this.resetInputs();
        }

    };

    saveItem = async () => {

        console.log(this.state.aboutCompanyText);
        var response = { data: null };

        if (true) {

            console.log(map); 

            var list = [];
          

            this.state.selectedNodeKeys.forEach(element => {
              
               if(element!=='All'){ const e = { 
                    id: 0,
                    menuId:element ,
                    halfChecked:false                   
                     
                } 
                list.push(e);}
            });

            this.state.halfCheckedKeys.forEach(element => {
              
               if(element!=='All'){ const e = { 
                    id: 0,
                    menuId:element,
                    halfChecked:true        
                    
                }
                list.push(e);}
            });

            

            const newItem = {
                id: this.state.productsId,
                productName: this.state.productName,
                code: this.state.code, 
                menus: list,
                logoPath:this.state.logoPath 

            }

            console.log(newItem);

            if (newItem.id === 0)
                response =  await handleRequest("POST", "/MNDProducts/add", newItem);
            else response =  await handleRequest("POST", "/MNDProducts/update", newItem);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS-MNDPRODUCTS-001") {
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess();
                }
                else if (response.data === "ERROR-MNDPRODUCTS-001") {
                    this.showError("An error was occured please try again later !");
                } else if (response.data === "ERROR-MNDPRODUCTS-002") {
                    this.showError("This record added before please check your product name or code !");
                }
            }
            else this.showError("An error was occured. Please try again later !");


        } else {
            this.showError();
        }
    };

    resetInputs = () => {
        this.setState({
            hideInputs: true,
            deleteModalShow: false,
            createNewButton: "Create New",
            actionButton: "Save",
            actionButtonVariant: "success",
            createNewButtonVariant: "info",

            productsId: 0,
            productName: "",
            code: "",
            imageUrl: "",
            selectedNodeKeys:[],
            logoPath:""



        });
    };


    edit = (row) => {

        console.log(row);
        //TODO selectedNodeKeys için setleme eklenecek.

        
        const _menus = [];

        if (row.menus!==null && row.menus!==undefined && row.menus.length>0) {
            for (const key of row.menus ) {
                if(key.halfChecked==false)
                _menus.push(key.menuId.toString()); 
            }
        } 

        var _imageUrl = "" ;

        if (row.logoPath!==null && row.logoPath!==undefined && row.logoPath.length>0) {
            _imageUrl = `${API_BASE}/MNDProducts/download/`+row.id
        }

        console.log(_imageUrl); 

        // this.getBase64(_imageUrl, imageUrl =>
        //     this.setState({
        //         imageUrl,
        //         loading: false,
        //     }),
        // );

        this.setState({
            hideInputs: false,
            createNewButton: "Stop Updating",
            actionButton: "Update",
            actionButtonVariant: "warning",
            createNewButtonVariant: "danger",

            productsId: row.id,
            productName: row.productName,
            code: row.code,
            imageUrl:  _imageUrl ,
            logoPath:row.logoPath,
            selectedNodeKeys:_menus 



        });


        document.getElementById('kt_scrolltop').click();
    }

    deleteModal = (row) => {

        this.setState({
            productsId: row.id,
            deleteModalShow: true
        });
    }

    delete = async () => {

        console.log(this.companyId);

        const deletedItem = {
            id: this.state.productsId

        }

        await handleRequest("POST", "/MNDProducts/delete", deletedItem);

        this.resetInputs();
        this.restartTable();
        this.showSuccess();

    }


    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };

    showMenu = (record) => {

        //TODO MENU TREE DOLDURULACAK. ve state eklenip o state e set edilecek. 
        console.log(record);   
        this.setState({expandedKeys: {}}); 
        var _formTitles = [];  
        var _menuIds = [];  
        if(record.menus!==null && record.menus!==undefined && record.menus.length>0){
            record.menus.forEach(element => {
                 _formTitles.push(element.dynamicMenu.menuTitle);  
                 _menuIds.push(element.menuId.toString()); 
            });

 
            var items=this.state.nodes[0].children;  
            var root = [];
            console.log(items);    
            expandedKeys={};
            items.forEach(element => {
                if(_menuIds.includes(element.key) )  
                this.getMenu(element, root,_menuIds);   
    
            }); 
    
            console.log(root);
    
            this.setState({
                coverageModalShow: true,
                modalNodes:root 
            });



        } 

        // var items = MenuConfig.aside.items;

      

    }

    getMenu = (element, list,ids) => {

            var obj = { key: element.key, label: element.label, data: "", children: [] }

          
            expandedKeys[element.key] = true;
          
            list.push(obj);
               
            if (element.children) {
                element.children.forEach(el => {
                    if(ids.includes(el.key) ) 
                   {
                    expandedKeys[el.key] = true; 

                    this.getMenu(el, obj.children,ids); 
                   }
                  //  list.push(obj); 
                });

            }
               
            this.setState({expandedKeys: expandedKeys});


    }


    showOrHideDeleteModal = () => {
        this.setState({
            deleteModalShow: !this.state.deleteModalShow
        });
    }

    showOrHideCoverageModal = () => {
        this.setState({
            coverageModalShow: !this.state.coverageModalShow
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            console.log(info.file)
            
            if(info.file.response.length>0)
            {
                this.setState({
                    logoPath:info.file.response[0] 
                })
            }
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
    };


    handleSelectionChange = (checkedKeys, e) => {
      

      console.log('onCheck', checkedKeys);
      console.log('halfCheckedKeys', e.halfCheckedKeys);
      console.log(e);
      this.setState({
         selectedNodeKeys: checkedKeys, 
          halfCheckedKeys: e.halfCheckedKeys
      });
    }

    renderCoverage = (record) => {

        return  <Button variant="warning" onClick={() => this.showMenu(record)} > Coverage </Button>;
    }

    render() {


        let actionHeader = <PrimeBotton type="button" icon="pi pi-cog"></PrimeBotton>



        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );



        //const ExpanableComponent = ({ data }) => <CompanyDetail row={data} />; 
        return (
            <div>
                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={3}> <Toast ref={(el) => this.toast = el} /></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                    </Row>
                </div>
                <div hidden={this.state.hideInputs} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Logo Of Product:
                                                    </Form.Label>

                                        <Col sm="2">   <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false} 
                                           
                                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            action={`${API_BASE}/MNDProducts/upload`}  
                                            beforeUpload={this.beforeUpload}
                                            onChange={this.handlePhotoChange}

                                        >
                                            {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                        </Upload></Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">

                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Name of Product:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="productName" value={this.state.productName}
                                                onChange={this.handleChange} />
                                        </Col>

                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Code:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="code" value={this.state.code}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Coverage:
                                                    </Form.Label>
                                        <Col sm="4">
                                          
                                            <Tree2 style={{ width: '100%' }}
                                        checkable
                                        showLine={true}
                                        onCheck={this.handleSelectionChange} 
                                        checkedKeys={this.state.selectedNodeKeys}                                      
                                        treeData={this.state.nodes}
                                       


                                    />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">

                                        <Col sm="3">

                                        </Col>
                                        <Col sm="4">
                                            <Button type="button" style={{ width: '100%' }} onClick={this.saveItem} variant={this.state.actionButtonVariant} >{this.state.actionButton}</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>

                            </Container></Card.Body>
                    </Card>
                </div>


                {/* <div className="content-section implementation">
                    <PrimeDataTable value={this.state.companyList} headerColumnGroup={headerGroup} style={{ textAlign: 'center' }}
                        paginator rows={10} emptyMessage="No customers found" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10, 25, 50]}               >
                        <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="index" />
                        <Column field="id" />
                        <Column field={this.numberOfProjects} />
                        <Column field={this.productList} />
                        <Column field="fullName" />
                        <Column field="shortName" />
                     
                    </PrimeDataTable>
                </div> */}

                <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.productList} bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }}>

                    <Column
                        title="Action"
                        key="action"
                        render={(text, record) => (
                            <React.Fragment> <CustomMaterialMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>
                        )}
                    />
                    <Column title="S/N" dataIndex="index" />
                    <Column title="Logo"  render = {(value,row,index) => {
                        if(row.logoPath!=null && row.logoPath!==undefined) 
                         return <Avatar shape="square" size={64} src={`${API_BASE}/MNDProducts/download/`+row.id } /> ;    
                         else return "";  

                    }} />
                    <Column title="Name Of Product" dataIndex="productName" />
                    <Column title="Code" dataIndex="code" />
                    <Column title="Coverage" render={(record) => (this.renderCoverage(record))} />     
                    {/* <Column title="Coverage" render={(record) => (this.productList2(record))} /> */}


                </Table>,

                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}>
                            {/* <Button onClick={this.showOrHideDeleteModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.deleteModalShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Product ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>All informations about product will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.showOrHideDeleteModal}>Close</Button>
                                    <Button variant="danger" onClick={this.delete} >Delete</Button>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>

                    </Row>
                </Container>

                <Modal
                    show={this.state.coverageModalShow}
                    onHide={this.showOrHideCoverageModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Menu List </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 
                    <Tree value={this.state.modalNodes} expandedKeys={this.state.expandedKeys}/>  

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideCoverageModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MNDProducts) 
