/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { Toast } from 'primereact/toast';
import { Upload, message } from 'antd';
import { Select, Divider, Input, Modal as AntModal } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined, LeftCircleFilled } from '@ant-design/icons';
import { Radio, Button as AntButton, Checkbox as AntCheckbox, InputNumber, Switch } from "antd";
import { Button as PrimeBotton } from "primereact/button";
import { Table } from 'antd';
import { API_BASE } from '../../redux/config/env';
import RadioGroup from "antd/lib/radio/group";

const { Column } = Table;
const { TextArea } = Input;

export class Empty extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteModalShow: false,
            hideInputs: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            actionButton: "Save",
            actionButtonVariant: "success",
            loading: false, // For photo upload

            questionnaryId: 0,
            questions: [],
            answers: [],
            fileList: [],
            questionnary: {}

        };

        // #region tags functions


        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

        // #endregion tags functions
        this.restartTable();
    }

    restartTable = async () => {

        // const response = await handleRequest("GET", "/Company/getAll");



        // if (response.data.length !== 0) {          

        //     this.setState({
        //         companyList: response.data 
        //     });
        // }
    }

    componentDidMount = async () => {

        let pathArray = this.props.location.pathname.split('/');
        var _answers = [];
        const _userId = this.props.auth.user.id;
        if (Boolean(pathArray[2])) {

            this.setState({
                questionnaryId: pathArray[2]
            });

            const response = await handleRequest("GET", "/Questionnary/" + pathArray[2]);



            if (response.data.length !== 0) {

                this.setState({
                    questionnary: response.data
                });
            }

            const r = await handleRequest("GET", "/Code/type/qig"); // qig : QuestionaryItemGroups

            console.log(r.data);
            var allGrups
            if (r.data !== null && r.data !== undefined) {
                allGrups = r.data;
            }


            var response2 = await handleRequest("GET", "/MyQuestion/getAllByQuestionnary/" + pathArray[2]);
            console.log(response2.data);
            if (response2.data.length !== 0) {
                const result = response2.data;

                result.forEach(element => {
                    let obj = { questionnaryId: pathArray[2], questionId: element.id, userId: _userId, result: true, rate: 0, comment: '', filePath: '', fileList: [], type:element.type };


                    if (Boolean(element.rows) && element.rows.length > 0) {
                        element.rows.forEach(row => {


                            if (Boolean(element.columns) && element.columns.length > 0) {
                                element.columns.forEach(column => {


                                    if (Boolean(element.options) && element.options.length > 0) {
                                        element.options.forEach(option => {
                                            let obj = { questionnaryId: pathArray[2], questionId: element.id, userId: _userId, result: false, rate: 0, comment: '', filePath: '', fileList: [] };
                                            obj.rowId = row.id;
                                            obj.columnId = column.optionId;
                                            obj.optionId = option.optionId;
                                            _answers.push(obj);
                                        });
                                    } else {

                                        let obj = { questionnaryId: pathArray[2], questionId: element.id, userId: _userId, result: false, rate: 0, comment: '', filePath: '', fileList: [] };
                                        obj.rowId = row.id;
                                        obj.columnId = column.optionId;
                                        _answers.push(obj);
                                    }


                                });
                            } else {
                                let obj = { questionnaryId: pathArray[2], questionId: element.id, userId: _userId, result: false, rate: 0, comment: '', filePath: '', fileList: [] };
                                obj.rowId = row.id;
                                _answers.push(obj);
                            }


                        });
                    } else

                        if (Boolean(element.options) && element.options.length > 0) {
                            element.options.forEach(option => {
                                let obj = { questionnaryId: pathArray[2], questionId: element.id, userId: _userId, result: false, rate: 0, comment: '', filePath: '', fileList: [] };
                                obj.optionId = option.optionId;
                                _answers.push(obj);
                            });
                        } else _answers.push(obj);

                });
                console.log(_answers);


                var grupList = [];
                var _questions = result;
                _questions.forEach(element => {

                    if (Boolean(element.grupId) && element.grupId > 0 && !grupList.includes(element.grupId))
                        grupList.push(element.grupId);

                });

                grupList.forEach(element => {

                    var index = _questions.findIndex(p => p.grupId === element);

                    _questions.filter(p => p.grupId === element).forEach((q, i) => {

                        q.code = i + 1 + '.';
                        q.grup = q.grup + '.'
                    });

                    let obj = { questionContent: allGrups.filter(p => p.id === element)[0].name, grup: _questions[index].grup, type: 'group' }
                    _questions.splice(index, 0, obj);
                });

                _questions.filter(p => p.grupId === 0 || p.grupId === null).forEach((q, i) => {

                    q.code = i + 1 + '.';
                    q.grup = '';
                });

                this.setState({
                    questions: result,
                    answers: _answers
                });

            }

        }

    }


    createNew = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hideInputs: false,
                createNewButton: "Stop Adding New",
                actionButton: "Save",
                actionButtonVariant: "success",
                createNewButtonVariant: "danger",
                companyId: 0,
                companyFullName: "",
                companyShortName: "",
                aboutCompanyText: "",
                visionText: "",
                missionText: ""
            });

        } else {
            this.resetInputs();
        }

    };


    resetInputs = () => {
        this.setState({
            hideInputs: true,
            deleteModalShow: false,
            createNewButton: "Create New",
            actionButton: "Save",
            actionButtonVariant: "success",
            createNewButtonVariant: "info",




        });
    };


    edit = (row) => {

        console.log(row);

        this.setState({
            hideInputs: false,
            createNewButton: "Stop Updating",
            actionButton: "Update",
            actionButtonVariant: "warning",
            createNewButtonVariant: "danger",


        });


        document.getElementById('kt_scrolltop').click();
    }

    deleteModal = (row) => {

        this.setState({
            companyId: row.id,
            deleteModalShow: true
        });
    }

    delete = async () => {

        console.log(this.companyId);

        const deletedItem = {
            // id: this.state.companyId

        }

        await handleRequest("POST", "/Company/delete", deletedItem);

        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }


    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };


    showOrHideDeleteModal = () => {
        this.setState({
            deleteModalShow: !this.state.deleteModalShow
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }




    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
    };


    getRatingList = (max) => {
        const numbers = [];

        for (let index = 1; index <= max; index++) {
            numbers.push(index);

        }

        return numbers;

    }

    sendQuestionnaire = async () => {
        console.log(this.state.answers);

        const items = this.state.answers;

        const fileTypeList = items.filter(p => p.fileList.length > 0);

        fileTypeList.forEach(element => {
            element.filePath = element.fileList[0].response.url;
        });

        var response = await handleRequest("POST", "/Answer/addAll", items);

        if (Boolean(response) && Boolean(response.data)) {
            this.showSuccess();
        }
        else this.showError("An error was occured when sending questionnaire please try again later.");

    }


    handleFileChange = ({ fileList }) => {

        if (this.state.fileList.length === 0)
            this.setState({ fileList });

    }

    beforeUpload = (fileList) => {


        if (this.state.fileList.length > 0) {
            message.error('You have been already choose a file! For change firstly remove the uploaded file.');


            return false;
        }

        return true;

    }

    render() {

       
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',

        };
        const header = this.state.questionnary.header;
        const footer = this.state.questionnary.footer;
        //const ExpanableComponent = ({ data }) => <CompanyDetail row={data} />; 
        const isLinked = (item) => {
           
            var linkedQuestion = this.state.answers.find(p => p.questionId === item.toLinkedQuestionId);

            if (!Boolean(linkedQuestion))
                return true;

            if (linkedQuestion.type !== 'boolean')
                return (!item.linkedQuestion || (item.linkedQuestion && Boolean(this.state.answers.find(p => p.questionId == item.toLinkedQuestionId && p.optionId === item.toLinkedOptionId && p.result === true))))
            else {
                let snc = false;
                if (item.toLinkedOptionId === 1)
                    snc = true;

                return (!item.linkedQuestion || (item.linkedQuestion && Boolean(this.state.answers.find(p => p.questionId == item.toLinkedQuestionId && p.result == snc))))

            }

        }
        return (
            <div>
                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={3}> <Toast ref={(el) => this.toast = el} /></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                    </Row>
                </div>
                <div
                    style={{ backgroundColor: "white", padding: "10px" }} dangerouslySetInnerHTML={{ __html: header }}>


                </div>
                {this.state.questions.map((item, index) => (
                    <>
                        <br />
                        {isLinked(item) &&
                         <div
                            style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}
                        >
                            <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Form.Label style={{ color: 'black' }} column sm="11">
                                    {item.grup}  {item.code}  {item.questionContent}
                                </Form.Label>
                            </Form.Group>

                            {Boolean(item.type) && item.type === 'text' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col xs="12">
                                    <TextArea rows={4} value={this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id)].comment}
                                        onChange={(e) => {
                                            e.persist();
                                            this.setState(prevState => ({
                                                answers: prevState.answers.map(
                                                    el => el.questionId === item.id ? { ...el, comment: e.target.value } : el
                                                )
                                            }))
                                        }} />

                                </Col>

                            </Form.Group>}


                            {Boolean(item.type) && item.type === 'radiogroup' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col xs="12">
                                    <Radio.Group onChange={(e) => {

                                        let val = e.target.value;

                                        this.setState(prevState => ({
                                            answers: prevState.answers.map(
                                                el => el.questionId === item.id && el.optionId == val ? { ...el, result: true } : (el.questionId === item.id ? { ...el, result: false } : el)
                                            )
                                        }))
                                    }} value={this.state.answers.findIndex(p => p.questionId === item.id && p.result === true) > -1 ? this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id && p.result === true)].optionId : ''}>
                                        {item.options.map((opt, j) => (

                                            <Radio style={radioStyle} checked={false} value={opt.optionId} >
                                                {opt.option.optionContent}
                                            </Radio>

                                        ))}
                                    </Radio.Group>

                                </Col>

                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'imagepicker' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col xs="12">
                                    <Radio.Group onChange={(e) => {

                                        let val = e.target.value;

                                        this.setState(prevState => ({
                                            answers: prevState.answers.map(
                                                el => el.questionId === item.id && el.optionId == val ? { ...el, result: true } : (el.questionId === item.id ? { ...el, result: false } : el)
                                            )
                                        }))
                                    }} value={this.state.answers.findIndex(p => p.questionId === item.id && p.result === true) > -1 ? this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id && p.result === true)].optionId : ''}>
                                        {item.options.map((opt, j) => (

                                            <Radio checked={false} value={opt.optionId} >
                                                <img alt="option" style={{ width: '120px', height: '120px' }} src={opt.option.imagePath} />
                                            </Radio>

                                        ))}
                                    </Radio.Group>

                                </Col>

                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'rating' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col xs="12">
                                    <Radio.Group buttonStyle="solid"
                                        value={this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id)].rate}
                                        onChange={(e) => {

                                            this.setState(prevState => ({
                                                answers: prevState.answers.map(
                                                    el => el.questionId === item.id ? { ...el, rate: e.target.value } : el
                                                )
                                            }))
                                        }}
                                    >
                                        {this.getRatingList(item.rateMax).map((element, index) => (
                                            <Radio.Button value={element}>{element}</Radio.Button>
                                        ))
                                        }
                                    </Radio.Group>

                                </Col>

                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'boolean' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col xs="12">

                                    <Switch checkedChildren={item.labelTrue} unCheckedChildren={item.labelFalse} defaultChecked checked={this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id)].result} onChange={(value) => {

                                        this.setState(prevState => ({
                                            answers: prevState.answers.map(
                                                el => el.questionId === item.id ? { ...el, result: value } : el
                                            )
                                        }))
                                    }} />

                                </Col>

                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'checkbox' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>


                                {item.options.map((opt, j) => (
                                    <>   <Col xs="12">   <AntCheckbox onChange={(e) => {

                                        this.setState(prevState => ({
                                            answers: prevState.answers.map(
                                                el => el.questionId === item.id && el.optionId === opt.optionId ? { ...el, result: e.target.checked } : el
                                            )
                                        }))
                                    }} checked={this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id && p.optionId === opt.optionId)].result}  > {opt.option.optionContent}</AntCheckbox>
                                        <br /></Col> </>

                                ))}


                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'dropdown' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>

                                <Col xs="4">
                                    <Form.Control name="type"
                                        as="select" onChange={(e) => {
                                            e.persist();
                                            let val = e.target.value;
                                            this.setState(prevState => ({
                                                answers: prevState.answers.map(
                                                    el => el.questionId === item.id && el.optionId == val ? { ...el, result: true } : (el.questionId === item.id ? { ...el, result: false } : el)
                                                )
                                            })) 
                                        }} value={this.state.answers.findIndex(p => p.questionId === item.id && p.result === true) > -1 ? this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id && p.result === true)].optionId : ''}
                                    >
                                        <option value="Select">Select Option</option>
                                        {item.options.map((opt, j) => (
                                            <option value={opt.optionId}>{opt.option.optionContent}</option>

                                        ))}
                                    </Form.Control>
                                </Col>

                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'file' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col xs="4">
                                    <Upload {...props}
                                        fileList={this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id)].fileList}
                                        onChange={({ fileList }) => {

                                            if (fileList.length === 1) {
                                                this.setState(prevState => ({
                                                    answers: prevState.answers.map(
                                                        el => el.questionId === item.id ? { ...el, result: true, fileList: fileList } : el
                                                    )

                                                }))
                                            }

                                        }}
                                        beforeUpload={(fileList) => {

                                            if (this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id)].fileList.length > 0) {
                                                message.error('You have been already choose a file! For change firstly remove the uploaded file.');


                                                return false;
                                            }

                                            return true;
                                        }}
                                        onRemove={({ fileList }) => {



                                            this.setState(prevState => ({
                                                answers: prevState.answers.map(
                                                    el => el.questionId === item.id ? { ...el, result: false, fileList: [] } : el
                                                )

                                            }))

                                        }}

                                    >
                                        <AntButton>
                                            <UploadOutlined /> Click To Upload
                                     </AntButton>
                                    </Upload>
                                    {/* <AntButton type="dashed" onClick={() => this.deleteModal(item)} shape="circle" icon={<CloseOutlined />} /> */}
                                </Col>
                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'matrix' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col > </Col>
                                {item.columns.map((col, j) => (
                                    <Col>   {col.option.optionContent}
                                    </Col>

                                ))}


                            </Form.Group>}

                            {Boolean(item.type) && item.type === 'matrixdropdown' && <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col > </Col>
                                {item.columns.map((col, j) => (
                                    <Col >   {col.option.optionContent}
                                    </Col>

                                ))}



                            </Form.Group>}


                            {Boolean(item.type) && item.type === 'matrix' && item.rows.map((row, k) => (
                                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                    <Col > {row.questionContent} </Col>
                                    {/* <Radio.Group onChange={(e) => {

                                        let val = e.target.value;

                                        this.setState(prevState => ({
                                            answers: prevState.answers.map(
                                                el => el.questionId === item.id &&  el.rowId === row.id &&  el.columnId == val ? { ...el, result: true } : (el.questionId === item.id  &&  el.rowId === row.id  ? { ...el, result: false } : el)
                                            )
                                        }))
                                    }} value={this.state.answers.findIndex(p => p.questionId === item.id  &&  p.rowId === row.id  && p.result === true) > -1 ? this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id  &&  p.rowId === row.id && p.result === true)].columnId : ''}> */}

                                    {item.columns.map((col, j) => (
                                        <Col>   <Radio checked={this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id && p.rowId === row.id && p.columnId === col.optionId)].result}
                                            onChange={(e) => {

                                                let val = e.target.value;

                                                this.setState(prevState => ({
                                                    answers: prevState.answers.map(
                                                        el => el.questionId === item.id && el.rowId === row.id && el.columnId == val ? { ...el, result: true } : (el.questionId === item.id && el.rowId === row.id ? { ...el, result: false } : el)
                                                    )
                                                }))
                                            }}
                                            value={col.optionId} >

                                        </Radio>
                                        </Col>

                                    ))}
                                    {/* </Radio.Group> */}
                                </Form.Group>

                            ))}

                            {Boolean(item.type) && item.type === 'matrixdropdown' && item.rows.map((row, k) => (
                                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                    <Col > {row.questionContent} </Col>
                                    {item.columns.map((col, j) => (
                                        <Col>
                                            <Form.Control name="type"
                                                as="select" onChange={(e) => {
                                                    e.persist();
                                                    let val = e.target.value;

                                                    this.setState(prevState => ({
                                                        answers: prevState.answers.map(
                                                            el => el.questionId === item.id && el.rowId === row.id && el.columnId === col.optionId && el.optionId == val ? { ...el, result: true } : (el.questionId === item.id && el.rowId === row.id && el.columnId === col.optionId ? { ...el, result: false } : el)
                                                        )
                                                    }))
                                                }} value={this.state.answers.findIndex(p => p.questionId === item.id && p.rowId === row.id && p.columnId === col.optionId && p.result === true) > -1 ? this.state.answers[this.state.answers.findIndex(p => p.questionId === item.id && p.rowId === row.id && p.columnId === col.optionId && p.result === true)].optionId : ''}>

                                                <option value="Select">Select Option</option>
                                                {item.options.map((opt, j) => (
                                                    <option value={opt.optionId}>{opt.option.optionContent}</option>

                                                ))}
                                            </Form.Control>
                                        </Col>

                                    ))}

                                </Form.Group>

                            ))}


                        </div>}

                    </>
                ))}
                <div
                    style={{ backgroundColor: "white", padding: "10px" }} dangerouslySetInnerHTML={{ __html: footer }} >


                </div>
                <br></br>
                <Form.Group style={{ marginBottom: "1rem" }} as={Row} >

                    <Col xs="12">
                        <AntButton type="primary" style={{ width: '100%' }} size='large' onClick={this.sendQuestionnaire} >
                            Complete and Send Questionnaire
                    </AntButton>
                    </Col>

                </Form.Group>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Empty) 
