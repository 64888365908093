import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Switch, Button as AntButton } from 'antd';
import moment from 'moment'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const amountTypesSelectItems = [{ id: 1, value: "%" }, { id: 2, value: "Amount" }];

export class PaymentSchdule extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            paymentStepList: [],
            paymentSchduleList: [],
            paymentTotal: '',
            remainingAmount: '',
            paymentDescription: '',
            customised: false,
            id: 0,

            termsOfPaymentSelectItems: [],
            requiredDocumentSelectItems: [],
            methodOfPaymentSelectItems: [],
            paymentTypeSelectItems: [],
            paymentTypeId: null,
            codeName: '',



            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.fillCodes();
        this.restartTable();


    }

    addPaymentStep = () => {

        const paymentStepList = this.state.paymentStepList;
        const paymentTypeId = this.state.paymentTypeId;


        if (Boolean(paymentTypeId)) {
            let paymentType = this.state.paymentTypeSelectItems.find(p => p.id == paymentTypeId);

            let obj = {
                id: -1 * paymentStepList.length, paymentStepDocuments: [], documentCodeIds: [], paymentType: paymentType, paymentTypeId: paymentTypeId, paymentSchduleId: null, termsOfPaymentId: null,
                amountType: null, amountBePaid: 0, dateOfPayment: null, maxDay: 0, methodOfPaymentId: null, active: true, maxDayAfterAggrement: false
            };
            paymentStepList.push(obj);

            this.setState({
                paymentStepList: paymentStepList
            })

        }

    }

    deletePaymentStep = async (type) => {

        const paymentStepList = this.state.paymentStepList;

        var index = paymentStepList.findIndex(p => p.id === type.id);

        if (index >= 0) {
            if (paymentStepList[index].id > 0) {
                paymentStepList[index].active = false;
            }
            else {
                paymentStepList.splice(index, 1);
            }
        }

    }

    addTermsOfPayment = async () => {
        const { codeName } = this.state;
        let pType = "termsOfPayment";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addRequiredDocuments = async () => {
        const { codeName } = this.state;
        let pType = "requiredDocuments";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addMethodOfPayment = async () => {
        const { codeName } = this.state;
        let pType = "methodOfPayment";

        if (codeName.length > 0) {

            const newItem = {
                name: codeName,
                type: pType
            }

            await handleRequest("POST", "/Code/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };

    addPaymentType = async () => {
        const { codeName } = this.state;

        if (codeName.length > 0) {

            const newItem = {
                typeName: codeName
            }

            await handleRequest("POST", "/PaymentType/add", newItem);


            this.setState({

                codeName: '',
            });

            this.fillCodes();
        }

    };



    fillCodes = async () => {

        const response = await handleRequest("GET", "/Code/type/termsOfPayment");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                termsOfPaymentSelectItems: response.data
            });
        }

        const response2 = await handleRequest("GET", "/Code/type/requiredDocuments");

        if (response2.data !== null && response2.data !== undefined) {
            this.setState({
                requiredDocumentSelectItems: response2.data
            });
        }

        const response3 = await handleRequest("GET", "/Code/type/methodOfPayment");

        if (Boolean(response3.data)) {
            this.setState({
                methodOfPaymentSelectItems: response3.data
            });
        }

        const response4 = await handleRequest("GET", "/PaymentType/getAllByCompany");

        if (Boolean(response4.data)) {
            this.setState({
                paymentTypeSelectItems: response4.data
            });
        }
    }


    save = async () => {

        if (this.state.remainingAmount === 0) {
            const obj = {
                id: this.state.id,
                paymentDescription: this.state.paymentDescription,
                paymentTotal: this.state.paymentTotal,
                customised: this.state.customised,
                paymentSteps: this.state.paymentStepList,
            }

            console.log(obj);
            if (obj.id === 0)
                await handleRequest("POST", "/PaymentSchdule/add", obj);
            else
                await handleRequest("POST", "/PaymentSchdule/update", obj);

            this.showSuccess();
            this.resetInputs();
            this.restartTable();
        }
        else this.showError("Remaining amount must be zero for save payment schdule.")

    }

    edit = (row) => {

        if (Boolean(row.paymentSteps) && row.paymentSteps.length > 0) {

            row.paymentSteps.forEach(element => {
                if (Boolean(element.paymentStepDocuments) && element.paymentStepDocuments.length > 0) {
                    element.documentCodeIds = [];
                    element.paymentStepDocuments.forEach(step => {
                        element.documentCodeIds.push(step.documentCodeId);
                    });
                }
            });
        }

        this.setState({
            id: row.id,
            paymentTotal: row.paymentTotal,
            paymentDescription: row.paymentDescription,
            customised: row.customised,
            paymentStepList: Boolean(row.paymentSteps) ? row.paymentSteps : [],
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/PaymentSchdule/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/PaymentSchdule/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                paymentSchduleList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                paymentSchduleList: [],
            });
        }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "PaymentSchdule" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "PaymentSchdule"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "PaymentSchdule");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            paymentStepList: [],
            paymentTotal: '',
            paymentDescription: '',
            customised: false,
            id: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            paymentStepList: [],
            paymentTotal: '',
            paymentDescription: '',
            customised: false,
            id: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            paymentStepList: [],
            paymentTotal: '',
            paymentDescription: '',
            customised: false,
            id: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    calculateRemaining = () => {

        var total = this.state.paymentTotal;
        var remaining = this.state.paymentTotal;
        var paymentStepList = Boolean(this.state.paymentStepList) && this.state.paymentStepList.length > 0 ? this.state.paymentStepList.filter(p => p.active === true) : [];

        if (Boolean(paymentStepList) && paymentStepList.length > 0) {

            paymentStepList.forEach(element => {
                if (element.amountType === 1)
                    remaining = remaining - (total * element.amountBePaid) / 100;
                if (element.amountType === 2)
                    remaining = remaining - element.amountBePaid
            });

        }

        if (remaining >= 0) {
            this.setState({
                remainingAmount: remaining
            })
        }
        else {
            this.showError("Remaining amount could not be negative please control your 'Amount to be paid' inputs. ");
        }

    }

    expand = (record) => {

        var data = [];
        var columns = [];

        data = record.paymentSteps;
        columns = [


            {
                title: 'Paymnent step', key: 'paymentType', render: (value, row, index) => {
                    if (Boolean(row.paymentType))
                        return row.paymentType.typeName;
                }
            },
            {
                title: 'Amount to be paid', key: 'termsOfPayment', dataIndex: 'amountBePaid', render: (value, row, index) => {

                    let result = row.amountBePaid;
                    if (Boolean(row.amountType) && row.amountType === 1)
                        result = result + ' %';
                    return result;
                }
            },
            {
                title: 'Required documents', key: 'paymentStepDocuments', render: (value, row, index) => {
                    if (Boolean(row.paymentStepDocuments) && row.paymentStepDocuments.length > 0) {
                        let result = [];
                        row.paymentStepDocuments.forEach(element => {
                            if (Boolean(element.documentCode))
                                result.push(element.documentCode.name);
                        });
                        return result.toString();
                    }
                }
            },
            {
                title: 'Date of payment', key: 'paymentType', render: (value, row, index) => {
                    let result = '';
                    if (Boolean(row.maxDayAfterAggrement))
                        result = row.maxDay;
                    else if (Boolean(row.dateOfPayment))
                        result = moment(row.dateOfPayment).format("DD/MM/YYYY");

                    return result;
                }
            },

            {
                title: 'Method of payment', key: 'paymentType', render: (value, row, index) => {
                    if (Boolean(row.methodOfPayment))
                        return row.methodOfPayment.name;
                }
            },

        ];

        return <Table columns={columns} dataSource={data} pagination={false} />;
    }


    render() {

        const columns = [
            {
                title: 'Actions', key: 'actions', render: (value, row, index) => {

                    return this.actionTemplate(row);

                }
            },
            { title: 'Payment Description', dataIndex: 'paymentDescription', key: 'paymentDescription' },
            { title: 'Payment Total', dataIndex: 'paymentTotal', key: 'paymentTotal' },

        ];

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Payment Description" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="todo" value={this.state.paymentDescription} onChange={(e) => this.setState({ paymentDescription: e.target.value })} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Payment Total" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <InputNumber min={0} value={this.state.paymentTotal}
                                                    onChange={(value) => {
                                                        this.setState({ paymentTotal: value })
                                                    }} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Remaining Amount" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <InputNumber min={0} value={this.state.remainingAmount}
                                                />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                            </Col>
                                            <Col sm="6">
                                                <Switch checkedChildren="Parameterised" unCheckedChildren="Customised" checked={this.state.customised}
                                                    onChange={(value) => {
                                                        this.setState({ customised: value })
                                                    }} />
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                            </Col>
                                            <Col sm="4">
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Unit"
                                                    value={this.state.paymentTypeId} onChange={(value) => {
                                                        this.setState({
                                                            paymentTypeId: value
                                                        })
                                                    }}

                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                    this.setState({
                                                                        codeName: event.target.value,
                                                                    });
                                                                }} />
                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={this.addPaymentType}
                                                                >
                                                                    <PlusOutlined /> Add item
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {this.state.paymentTypeSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.typeName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" onClick={this.addPaymentStep} icon={<PlusOutlined />}></AntButton>

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.paymentStepList) && this.state.paymentStepList.length > 0 && this.state.paymentStepList.filter(p => p.active === true).map((step, i) => (
                                    <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                        {
                                            <FormAnt.Item

                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                    <Col sm="6">
                                                        <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                            {Boolean(step.paymentType) ? step.paymentType.typeName : ''}
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm="5">

                                                    </Col>
                                                    <Col sm="1">
                                                        <AntButton type="dashed" onClick={() => this.deletePaymentStep(step)} shape="circle" icon={<CloseOutlined />} />
                                                    </Col>

                                                </Form.Group>

                                            </FormAnt.Item>}
                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Terms of payment" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >


                                                    <Col sm="6">
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Unit"
                                                            value={step.termsOfPaymentId} onChange={(value) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.termsOfPaymentId = value;
                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                })
                                                            }}

                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addTermsOfPayment}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.termsOfPaymentSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>



                                                </Form.Group>

                                            </FormAnt.Item>}

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Amount to be paid" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >


                                                    <Col sm="4">
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select"
                                                            value={step.amountType} onChange={(value) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.amountType = value;
                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                })
                                                            }}


                                                        >
                                                            {amountTypesSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.value}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>

                                                    <Col sm="2">
                                                        <InputNumber min={0} value={step.amountBePaid}
                                                            onChange={(value) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.amountBePaid = value;
                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                }, this.calculateRemaining)
                                                            }} />
                                                    </Col>



                                                </Form.Group>

                                            </FormAnt.Item>}

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Required documents" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >


                                                    <Col sm="6">
                                                        <Select mode="tags"
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Documents"
                                                            value={step.documentCodeIds} onChange={(value) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.documentCodeIds = value;

                                                                if (Boolean(obj.paymentStepDocuments) && obj.paymentStepDocuments.length > 0) {
                                                                    obj.paymentStepDocuments.forEach(element => {
                                                                        element.active = false;
                                                                    });
                                                                }

                                                                if (Boolean(value)) {

                                                                    value.forEach(element => {
                                                                        if (Boolean(obj.paymentStepDocuments) && obj.paymentStepDocuments.length > 0) {
                                                                            let index = obj.paymentStepDocuments(p => p.documentCodeId === element);
                                                                            if (index >= 0) {
                                                                                obj.paymentStepDocuments[index].active = true;
                                                                            }
                                                                            else {
                                                                                let doc = { id: 0, paymentStepId: step.id, documentCodeId: element };
                                                                                obj.paymentStepDocuments.push(doc);
                                                                            }
                                                                        }
                                                                        else {
                                                                            let doc = { id: 0, paymentStepId: step.id, documentCodeId: element };
                                                                            obj.paymentStepDocuments.push(doc);
                                                                        }
                                                                    });

                                                                }

                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                })
                                                            }}

                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addRequiredDocuments}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.requiredDocumentSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>



                                                </Form.Group>

                                            </FormAnt.Item>}

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Date of payment" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >


                                                    <Col sm="4">


                                                        <div>
                                                            <Checkbox checked={step.maxDayAfterAggrement} onChange={(e) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.maxDayAfterAggrement = e.target.checked;
                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                })
                                                            }}> Max. day after agrement on terms </Checkbox>
                                                        </div>
                                                    </Col>

                                                    {step.maxDayAfterAggrement && <Col sm="2">
                                                        <InputNumber min={0} value={step.maxDay}
                                                            onChange={(value) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.maxDay = value;
                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                })
                                                            }} />
                                                    </Col>}
                                                    {!step.maxDayAfterAggrement && <Col sm="2">

                                                        <DatePicker onChange={(value) => {
                                                            var paymentStepList = this.state.paymentStepList;
                                                            var obj = paymentStepList[i];
                                                            obj.dateOfPayment = value.toDate();
                                                            this.setState({
                                                                paymentStepList: paymentStepList
                                                            })
                                                        }} value={Boolean(step.dateOfPayment) ? moment(step.dateOfPayment) : moment()}

                                                            format="DD-MM-YYYY"
                                                        />

                                                    </Col>}



                                                </Form.Group>

                                            </FormAnt.Item>}

                                        {
                                            <FormAnt.Item
                                                label={<FormattedMessage id="todo" defaultMessage="Method of payment" />}
                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >


                                                    <Col sm="6">
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Unit"
                                                            value={step.methodOfPaymentId} onChange={(value) => {
                                                                var paymentStepList = this.state.paymentStepList;
                                                                var obj = paymentStepList[i];
                                                                obj.methodOfPaymentId = value;
                                                                this.setState({
                                                                    paymentStepList: paymentStepList
                                                                })
                                                            }}

                                                            dropdownRender={menu => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: '4px 0' }} />
                                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                        <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {
                                                                            this.setState({
                                                                                codeName: event.target.value,
                                                                            });
                                                                        }} />
                                                                        <a
                                                                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                            onClick={this.addMethodOfPayment}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                               </a>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        >
                                                            {this.state.methodOfPaymentSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>



                                                </Form.Group>

                                            </FormAnt.Item>}

                                    </div>))}




                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {Boolean(this.state.paymentSchduleList) && this.state.paymentSchduleList.length > 0 && <Table
                            className="components-table-demo-nested"
                            columns={columns}
                            expandable={{ expandedRowRender: record => this.expand(record) }}
                            dataSource={this.state.paymentSchduleList}
                        />}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSchdule)