/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Select, InputNumber, DatePicker, Button as AntButton, Input, Radio, Row as RowAnt, Col as ColAnt } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { element } from 'prop-types';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class EndOfServiceCalculation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      hiddenDataGirisTotalPayment: true,
      hiddenDataGirisSalary: true,
      hiddenDataGirisAllowances: true,
      hiddenDataGirisVacation: true,
      hiddenDataGirisEndOfServiceBenefits: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      endOfServiceCalculationsTable: [],
      salaryPeriodMax: 0,
      months: [],
      departments: [],
      employeeNames: [],
      salaryTable: [],
      vacationTable: [],
      endOfServiceBenefitsTable: [],
      endOfServiceType: 0,
      hiddenEndOfServiceType: false,
      endOfServiceBenefits: [],
      hiddenCalculate: true,
      totalPayment: 0,
      totalEndOfServiceBenefit: 0,
    };
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "EndOfServiceCalculation" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "EndOfServiceCalculation",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const responseContractSettings = await handleRequest("GET", "/ContractSettings/getallByCompanyMax");
    if (responseContractSettings.data.length !== 0) {
      this.setState({
        endOfServiceBenefits: responseContractSettings.data,
      });
    } else {
      this.setState({
        endOfServiceBenefits: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "EndOfServiceCalculation");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
    this.getDepartments();
    this.getEmployeeNames();
  }

  getTableProperties = async () => {
    this.EndOfServiceCalculation = JSON.parse(localStorage.getItem('EndOfServiceCalculation'));
    if (this.EndOfServiceCalculation != null && this.EndOfServiceCalculation != undefined && this.EndOfServiceCalculation != "") {

      await this.setState({
        bordered: this.EndOfServiceCalculation.bordered,
        title: this.EndOfServiceCalculation.titleDrm == true ? title : undefined,
        titleDrm: this.EndOfServiceCalculation.titleDrm,
        showHeader: this.EndOfServiceCalculation.showHeader,
        footer: this.EndOfServiceCalculation.footerDrm == true ? footer : undefined,
        footerDrm: this.EndOfServiceCalculation.footerDrm,
        expandable: this.EndOfServiceCalculation.expandableDrm == true ? expandable : {},
        expandableDrm: this.EndOfServiceCalculation.expandableDrm,
        rowSelection: this.EndOfServiceCalculation.rowSelection,
        ellipsis: this.EndOfServiceCalculation.ellipsis,
        tableLayout: this.EndOfServiceCalculation.tableLayout,
        size: this.EndOfServiceCalculation.size,
        top: this.EndOfServiceCalculation.top,
        bottom: this.EndOfServiceCalculation.bottom,
        editBgColor: this.EndOfServiceCalculation.editBgColor,
        deleteBgColor: this.EndOfServiceCalculation.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.EndOfServiceCalculation.yScroll,
        xScroll: this.EndOfServiceCalculation.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.endOfServiceCalculationsTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.endOfServiceCalculationsTable.length, showQuickJumper: true,
          position: [this.EndOfServiceCalculation.top, this.EndOfServiceCalculation.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  getDepartments = async () => {
    const response = await handleRequest("GET", "/Department/getallByCompany");

    if (response.data.length === 0) {
      this.setState({
        departments: []
      });
    }
    if (response.data.length !== 0) {
      this.setState({
        departments: response.data,
      });
    }
  }

  getEmployeeNames = async (department) => {
    var response = [];
    if (department > 0) {
      var newObj = {
        departmentId: department
      }
      response = await handleRequest("POST", "/Staff/getResult", newObj);
    } else {
      response = await handleRequest("GET", "/Staff/getallByCompany");
    }

    if (response.data.length === 0) {
      this.setState({
        employeeNames: [],
      });
    }
    if (response.data.length !== 0) {
      this.setState({
        employeeNames: response.data,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  };

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedEndOfServiceCalculation: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  endOfServiceCalculation = async () => {
    var staffId = 0;
    if (Boolean(this.state.employeeId)) {
      staffId = this.state.employeeId
    } else if (Boolean(this.state.employeeName)) {
      staffId = this.state.employeeName
    }
    if (staffId > 0) {
      const obj = {
        staffId: staffId,
        endOfService: this.state.contractEndDate,
        contractStartDate: this.state.contractStartDate
      };
      const response = await handleRequest("POST", "/SalarySetting/calculateEndOfServiceSalary", obj);
      if (response.data.length !== 0) {
        var totalSalaryBalance = 0;
        var totalAllowancesBalance = 0;
        response.data.forEach((element, index) => {
          element.index = index + 1;

          totalSalaryBalance = this.calcSalaryBalance(element, totalSalaryBalance);

          totalAllowancesBalance = this.calcAllowancesBalance(element, totalAllowancesBalance);
        });

        await this.renderVacation(staffId);

        this.renderEndOfServiceBenefits(staffId);

        var totalPayment = Number(totalSalaryBalance) + Number(totalAllowancesBalance) + Number(this.state.totalVacation) + Number(this.state.totalEndOfServiceBenefit);
        this.setState({
          salaryTable: response.data,
          totalSalaryBalance: totalSalaryBalance,
          totalAllowancesBalance: totalAllowancesBalance,
          totalPayment: totalPayment.toFixed(2),
        });
      } else {
        this.setState({
          salaryTable: [],
          totalSalaryBalance: 0,
          totalAllowancesBalance: 0,
          totalPayment: 0,
        });
      }
    } else {
      this.showWarning("Please select Employee name or Employee ID");
    }
  }

  updateEndOfServiceCalculation = async () => {
    const updatedEndOfServiceCalculation = {
      id: this.state.selectedEndOfServiceCalculation.id,
      department: this.state.department,
      employeeName: this.state.employeeName,
      employeeId: this.state.employeeId,
      contractStartDate: this.state.contractStartDate,
      contractEndDate: this.state.contractEndDate,
      totalWorkingYears: this.state.totalWorkingYears,
      endOfServiceType: this.state.endOfServiceType,

    }
    var response = await handleRequest("POST", "/EndOfServiceCalculation/update", updatedEndOfServiceCalculation);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    // const response = await handleRequest("GET", "/EndOfServiceCalculation/getAllByCompany");
    // if (response.data.length === 0) {
    //   this.setState({
    //     endOfServiceCalculationsTable: [],
    //   });
    // }

    // if (response.data.length !== 0) {
    //   this.setState({
    //     endOfServiceCalculationsTable: response.data,
    //   });
    // }
  }

  deleteEndOfServiceCalculationOK = async () => {
    const deleteEndOfServiceCalculation = {
      id: this.state.selectedEndOfServiceCalculation.id
    }
    var response = await handleRequest("POST", "/EndOfServiceCalculation/delete", deleteEndOfServiceCalculation);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    this.formRef.current.setFieldsValue({
      Department: row.department,
      EmployeeName: row.employeeName,
      EmployeeId: row.employeeId,
      ContractStartDate: row.contractStartDate,
      ContractEndDate: row.contractEndDate,
      TotalWorkingYears: row.totalWorkingYears,
      EndOfServiceType: row.endOfServiceType,

    });

    await this.setState({
      selectedEndOfServiceCalculation: row,
      department: row.department,
      employeeName: row.employeeName,
      employeeId: row.employeeId,
      contractStartDate: row.contractStartDate,
      contractEndDate: row.contractEndDate,
      totalWorkingYears: row.totalWorkingYears,
      endOfServiceType: row.endOfServiceType,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      Department: "",
      EmployeeName: "",
      EmployeeId: "",
      ContractStartDate: "",
      ContractEndDate: "",
      TotalWorkingYears: "",
      EndOfServiceType: "",

    });

    this.setState({
      selectedEndOfServiceCalculation: [],
      department: "",
      employeeName: "",
      employeeId: "",
      contractStartDate: "",
      contractEndDate: "",
      totalWorkingYears: "",
      endOfServiceType: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
  }

  changeButtonCancel = () => {
    this.resetInputs();

    this.setState({
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  calcAllowancesBalance(element, totalAllowancesBalance) {
    let x = element.countTimesheetLog / (element.lastDay - element.firstDay + 1);
    var total = 0;
    if (Boolean(element.staff) && Boolean(element.staff.staffContractList) && element.staff.staffContractList.length > 0) {
      let contract = element.staff.staffContractList[element.staff.staffContractList.length - 1];
      if (Boolean(contract.homeAllowance)) {
        total += contract.homeAllowance * x;
      }
      if (Boolean(contract.transportationAllowance)) {
        total += contract.transportationAllowance * x;
      }
      if (Boolean(contract.foodAllowance)) {
        total += contract.foodAllowance * x;
      }
      if (Boolean(contract.otherPayments)) {
        total += contract.otherPayments * x;
      }
    }
    if (total > 0) {
      var salaryPaymentDate = null;
      if (element != null && element != undefined) { //"Salary Period" un son günü ile "Salary Day" toplamı
        if (element.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
          try {
            var lastPeriodDay = moment(element.year + "-" + element.month + "-" + element.lastDay);
            if (Boolean(lastPeriodDay)) {
              var addDate = lastPeriodDay.add(element.paymentDay, 'd');
              if (addDate.day() === 5) {
                addDate = lastPeriodDay.add(2, 'd');
                salaryPaymentDate = addDate;
              }
              else if (addDate.day() === 6) {
                addDate = lastPeriodDay.add(1, 'd');
                salaryPaymentDate = addDate;
              }
              else {
                salaryPaymentDate = addDate;
              }
            }
          }
          catch (error) {
          }
        }
        else {
          try {
            var lastPeriodDay = moment(element.year + "-" + element.month + "-" + element.lastDay);
            if (Boolean(lastPeriodDay)) {
              salaryPaymentDate = lastPeriodDay.add(element.paymentDay, 'd');
            }
          }
          catch (error) {
          }
        }
      }
      if (salaryPaymentDate.isSameOrBefore(this.state.contractEndDate)) {
        element.balanceAllowances = 0;
      }
      else {
        element.balanceAllowances = total.toFixed(2);
        totalAllowancesBalance += total.toFixed(2);
      }
    }
    return totalAllowancesBalance;
  }

  calcSalaryBalance(element, totalSalaryBalance) {
    let x = element.countTimesheetLog / (element.lastDay - element.firstDay + 1);
    if (Boolean(element.staff) && Boolean(element.staff.staffContractList) && element.staff.staffContractList.length > 0) {
      let contract = element.staff.staffContractList[element.staff.staffContractList.length - 1];
      if (Boolean(contract.basicSalary)) {
        var salaryPaymentDate = null;
        if (element != null && element != undefined) { //"Salary Period" un son günü ile "Salary Day" toplamı
          if (element.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
            try {
              var lastPeriodDay = moment(element.year + "-" + element.month + "-" + element.lastDay);
              if (Boolean(lastPeriodDay)) {
                var addDate = lastPeriodDay.add(element.paymentDay, 'd');
                if (addDate.day() === 5) {
                  addDate = lastPeriodDay.add(2, 'd');
                  salaryPaymentDate = addDate;
                }
                else if (addDate.day() === 6) {
                  addDate = lastPeriodDay.add(1, 'd');
                  salaryPaymentDate = addDate;
                }
                else {
                  salaryPaymentDate = addDate;
                }
              }
            }
            catch (error) {
            }
          }
          else {
            try {
              var lastPeriodDay = moment(element.year + "-" + element.month + "-" + element.lastDay);
              if (Boolean(lastPeriodDay)) {
                salaryPaymentDate = lastPeriodDay.add(element.paymentDay, 'd');
              }
            }
            catch (error) {
            }
          }
        }
        if (salaryPaymentDate.isSameOrBefore(this.state.contractEndDate)) {
          element.balanceSalary = 0;
        }
        else {
          element.balanceSalary = (contract.basicSalary * x).toFixed(2);
          totalSalaryBalance += (contract.basicSalary * x).toFixed(2);
        }
      }
    }
    return totalSalaryBalance;
  }

  renderEndOfServiceBenefits(staffId) {
    var endOfServiceBenefitsTable = [];
    if (Boolean(this.state.endOfServiceBenefits[0].contractSettingsEndOfServices) && this.state.endOfServiceBenefits[0].contractSettingsEndOfServices.length > 0) {

      var array = ["Voluntary", "Involuntary"];

      array.forEach(typeBenefits => {
        var endOfServiceBenefits = this.state.endOfServiceBenefits[0].contractSettingsEndOfServices.filter(p => p.type == typeBenefits);

        let workingTotalYears = 0;
        if (Boolean(endOfServiceBenefits) && endOfServiceBenefits.length > 0) {
          endOfServiceBenefits.forEach((element, index) => {
            var previousEndOfService = {};
            if (index > 0) {
              previousEndOfService = this.state.endOfServiceBenefits[0].contractSettingsEndOfServices[index];
            }
            var workingYears = 0;
            var period = "";
            if (element.workingYearsMaxDahil) {
              if (Boolean(element.workingYearsMax)) {
                if (Boolean(previousEndOfService) && Boolean(previousEndOfService.workingYearsMaxDahil) && previousEndOfService.workingYearsMaxDahil) {
                  if (this.state.totalYear >= element.workingYearsMax) {
                    workingYears = element.workingYearsMax - element.workingYearsMin;
                    workingTotalYears = workingTotalYears + workingYears;
                    console.log(workingTotalYears);
                  } else {
                    workingYears = (this.state.totalYear - element.workingYearsMin) > 0 ? (this.state.totalYear - element.workingYearsMin) : 0;
                    workingTotalYears = workingTotalYears + workingYears;
                    console.log(workingTotalYears);
                  }
                  period = element.workingYearsMin + " to " + element.workingYearsMax;
                } else {
                  if (this.state.totalYear >= element.workingYearsMax) {
                    workingYears = element.workingYearsMax - element.workingYearsMin + 1;
                    workingTotalYears = workingTotalYears + workingYears;
                    console.log(workingTotalYears);
                  } else {
                    workingYears = (this.state.totalYear - element.workingYearsMin + 1) > 0 ? (this.state.totalYear - element.workingYearsMin + 1) : 0;
                    workingTotalYears = workingTotalYears + workingYears;
                    console.log(workingTotalYears);
                  }
                  period = element.workingYearsMin + " to " + element.workingYearsMax;
                }
              }
              else {
                if (this.state.totalYear >= element.workingYearsMin) {
                  console.log(workingTotalYears);
                  console.log(this.state.totalYear);
                  if (workingTotalYears == this.state.totalYear) {
                    workingYears = this.state.totalYear;
                  } else {
                    workingYears = this.state.totalYear - workingTotalYears;
                  }
                } else {
                  workingYears = 0;
                }
                period = element.workingYearsMin + " +";
              }
            }
            else {
              if (Boolean(element.workingYearsMax)) {
                if (this.state.totalYear >= element.workingYearsMax) {
                  workingYears = element.workingYearsMax - element.workingYearsMin;
                  workingTotalYears = workingTotalYears + workingYears;
                } else {
                  workingYears = (this.state.totalYear - element.workingYearsMin) > 0 ? (this.state.totalYear - element.workingYearsMin) : 0;
                  workingTotalYears = workingTotalYears + workingYears;
                }
                period = element.workingYearsMin + " to " + element.workingYearsMax;
              }
              else {
                if (this.state.totalYear >= element.workingYearsMin) {
                  if (workingTotalYears == this.state.totalYear) {
                    workingYears = this.state.totalYear;
                  } else {
                    workingYears = this.state.totalYear - workingTotalYears;
                  }
                } else {
                  workingYears = 0;
                }
                period = element.workingYearsMin + " +";
              }
            }
            var newObj = {
              index: index + 1,
              period: period,
              workingYears: workingYears,
              payment: 0,
              type: element.type,
              calculationWillAddedTotal: element.calculationWillAddedTotal,
              percentOfBasicSalaryForEachYear: element.percentOfBasicSalaryForEachYear,
            };
            endOfServiceBenefitsTable.push(newObj);
          });
        }
      });
    }

    var totalEndOfServiceBenefit = 0;

    var basicSalary = 0;
    var employee = this.state.employeeNames.find(p => p.id == staffId);
    if (Boolean(employee) && Boolean(employee.staffContractList) && employee.staffContractList.length > 0) {
      basicSalary = employee.staffContractList[employee.staffContractList.length - 1].basicSalary;
    }

    if (this.state.endOfServiceType == 1 || this.state.endOfServiceType == 2) {   //Termination ve Resignation seçili ise
      if (Boolean(endOfServiceBenefitsTable) && endOfServiceBenefitsTable.length > 0) {
        var list = endOfServiceBenefitsTable.filter(p => p.workingYears > 0 && p.type == (this.state.endOfServiceType == 1 ? "Involuntary" : "Voluntary"));
        console.log("KKK", list);
        console.log("NNN", basicSalary);

        if (Boolean(list) && list.length > 0) {
          let lastElement = list[list.length - 1];
          if (lastElement.calculationWillAddedTotal == false) {//Toplama yok
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              console.log("LLL", element);

              element.payment = (element.workingYears * basicSalary * lastElement.percentOfBasicSalaryForEachYear) / 100;

              totalEndOfServiceBenefit += element.payment;
            }
          } else {
            for (let index = list.length - 1; index >= 0; index--) {
              const element = list[index];
              console.log("MMM", element);
              element.payment = (element.workingYears * basicSalary * element.percentOfBasicSalaryForEachYear) / 100;

              totalEndOfServiceBenefit += element.payment;
            }
          }
        }
      }
    }
    this.setState({
      endOfServiceBenefitsTable: endOfServiceBenefitsTable,
      totalEndOfServiceBenefit: totalEndOfServiceBenefit.toFixed(2),
      hiddenCalculate: false,
    });
  }

  async renderVacation(staffId) {
    const newLeaveRequest = {
      userId: staffId,
    };
    const responseLeaveRequest = await handleRequest("POST", "/LeaveRequest/getResult", newLeaveRequest);
    if (responseLeaveRequest.data.length !== 0) {
      responseLeaveRequest.data.forEach((element, index) => {
        element.index = index + 1;
      });
      this.setState({
        leaveRequestTable: responseLeaveRequest.data,
      });
    }
    else {
      this.setState({
        leaveRequestTable: [],
      });
    }
    var contractStartDate = null;
    var employee = this.state.employeeNames.find(p => p.id == staffId);
    if (Boolean(employee) && Boolean(employee.staffContractList) && employee.staffContractList.length > 0) {
      contractStartDate = employee.staffContractList[employee.staffContractList.length - 1].contractDate;
    }
    if (Boolean(contractStartDate)) {
      var vacationTable = [];
      var basicSalary = 0;
      if (Boolean(employee)) {
        var staffContract = employee.staffContractList[employee.staffContractList.length - 1];
        if (Boolean(staffContract)) {
          basicSalary = staffContract.basicSalary;
          var index = 1;
          var entitledDays = staffContract.annualVacationDays;
          var usedDays = 0;
          var remainingDays = 0;
          var totalRemainingDays = 0;
          var periodStart = moment(staffContract.contractDate);
          var flagStart = new Date(staffContract.contractDate);
          var periodEnd = moment(flagStart).add(staffContract.annualVacationPeriod, (staffContract.annualVacationPeriodType == 1) ? 'Y' : 'M').add(-1, "d");
          const list = this.state.leaveRequestTable.filter(p => Boolean(p.leavePolicyGroup) && Boolean(p.leavePolicyGroup.vacationLeave) && p.leavePolicyGroup.vacationLeave);
          while (periodEnd.isSameOrBefore(this.state.contractEndDate)) {
            usedDays = 0;
            remainingDays = 0;
            if (Boolean(list)) {
              list.forEach(element => {
                if (periodStart.isSameOrBefore((moment(element.startOfLeave))) && moment(element.endOfLeave).isSameOrBefore((periodEnd))) { //İzin tarihleri period arasında ise
                  let a = moment(element.endOfLeave, "YYYY-MM-DD");
                  let b = moment(element.startOfLeave, "YYYY-MM-DD");
                  usedDays += a.diff(b, 'days') + 1;
                }
                if (moment(element.startOfLeave).isSameOrBefore(periodStart) && periodStart.isSameOrBefore((moment(element.endOfLeave)))) { //İzin başlangıcı period başlangıcından küçük ve izin bitişi period başlangıcından büyük ise
                  let a = moment(element.endOfLeave, "YYYY-MM-DD");
                  let b = moment(periodStart.format("YYYY-MM-DD"), "YYYY-MM-DD");
                  usedDays += a.diff(b, 'days') + 1;
                }
                if (moment(element.startOfLeave).isSameOrBefore(periodEnd) && periodEnd.isSameOrBefore(moment(element.endOfLeave))) { //İzin başlangıcı period bitişinden küçük ve izin bitişi period bitişinden büyük ise
                  let a = moment(periodEnd.format("YYYY-MM-DD"), "YYYY-MM-DD");
                  let b = moment(element.startOfLeave, "YYYY-MM-DD");
                  usedDays += a.diff(b, 'days') + 1;
                }
              });
            }
            remainingDays = entitledDays - usedDays;
            totalRemainingDays += remainingDays;
            var newObj = {
              index: index,
              period: periodStart.format("DD-MM-YYYY") + " to " + periodEnd.format("DD-MM-YYYY"),
              entitledDays: entitledDays,
              usedDays: usedDays,
              remainingDays: remainingDays
            };
            vacationTable.push(newObj);
            index++;
            flagStart = periodEnd.toDate();
            periodStart = moment(flagStart).add(1, "d");
            periodEnd = periodEnd.add(staffContract.annualVacationPeriod, (staffContract.annualVacationPeriodType == 1) ? 'Y' : 'M');
          }
          usedDays = 0;
          remainingDays = 0;
          flagStart = moment(flagStart).add(1, "d");
          let flagEnd = this.state.contractEndDate;
          let b = periodStart;
          let days = flagEnd.diff(b, 'days');
          let totalDay = 0;
          if (days > 0) {
            totalDay += days;
          }
          let periodTotalDay = (staffContract.annualVacationPeriodType == 1) ? staffContract.annualVacationPeriod * 365 : staffContract.annualVacationPeriod * 30;
          entitledDays = Math.round((staffContract.annualVacationDays * totalDay) / periodTotalDay);

          if (Boolean(list)) {
            list.forEach(element => {
              if (flagStart.isSameOrBefore((moment(element.startOfLeave))) && moment(element.endOfLeave).isSameOrBefore((flagEnd))) { //İzin tarihleri period arasında ise
                let a = moment(element.endOfLeave, "YYYY-MM-DD");
                let b = moment(element.startOfLeave, "YYYY-MM-DD");
                usedDays += a.diff(b, 'days') + 1;
              }
              if (moment(element.startOfLeave).isSameOrBefore(flagStart) && flagStart.isSameOrBefore((moment(element.endOfLeave)))) { //İzin başlangıcı period başlangıcından küçük ve izin bitişi period başlangıcından büyük ise
                let a = moment(element.endOfLeave, "YYYY-MM-DD");
                let b = moment(flagStart.format("YYYY-MM-DD"), "YYYY-MM-DD");
                usedDays += a.diff(b, 'days') + 1;
              }
              if (moment(element.startOfLeave).isSameOrBefore(flagEnd) && flagEnd.isSameOrBefore(moment(element.endOfLeave))) { //İzin başlangıcı period bitişinden küçük ve izin bitişi period bitişinden büyük ise
                let a = moment(flagEnd.format("YYYY-MM-DD"), "YYYY-MM-DD");
                let b = moment(element.startOfLeave, "YYYY-MM-DD");
                usedDays += a.diff(b, 'days') + 1;
              }
            });
          }

          remainingDays = entitledDays - usedDays;
          totalRemainingDays += remainingDays;
          var newObj = {
            index: index,
            period: flagStart.format("DD-MM-YYYY") + " to " + flagEnd.format("DD-MM-YYYY"),
            entitledDays: entitledDays,
            usedDays: usedDays,
            remainingDays: remainingDays
          };
          vacationTable.push(newObj);
          index++;
        }
      }

      var totalVacation = 0;
      if (totalRemainingDays > 0) {
        totalVacation = (totalRemainingDays * ((basicSalary * 12) / 365)).toFixed(2);
      }

      this.setState({
        hiddenEndOfServiceType: false,
        vacationTable: vacationTable,
        totalVacation: Number(totalVacation),
      });
    }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeTransportationAllowanceDate = (value, dateString) => {
    this.setState({
      transportationAllowanceDate: dateString,
    });
  }

  onChangeFoodAllowanceDate = (value, dateString) => {
    this.setState({
      foodAllowanceDate: dateString,
    });
  }

  onChangeOvertimeDate = (value, dateString) => {
    this.setState({
      overtimeDate: dateString,
    });
  }

  onChangeBusinessTripDate = (value, dateString) => {
    this.setState({
      businessTripDate: dateString,
    });
  }

  onChangeOtherPaymentsDate = (value, dateString) => {
    this.setState({
      otherPaymentsDate: dateString,
    });
  }

  disabledDate(current) {
    return current > moment().endOf('day');
  }

  showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.pdf')) {
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true,
        });
      } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      } else {
        window.location.href = fileRealUrl;
      }
    } catch (error) {
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
    }
  };

  addTotalPayment = async () => {
    this.setState({
      hiddenDataGirisTotalPayment: !this.state.hiddenDataGirisTotalPayment,
    });
  };

  addSalary = async () => {
    this.setState({
      hiddenDataGirisSalary: !this.state.hiddenDataGirisSalary,
    });
  };

  addAllowances = async () => {
    this.setState({
      hiddenDataGirisAllowances: !this.state.hiddenDataGirisAllowances,
    });
  };

  addVacation = async () => {
    this.setState({
      hiddenDataGirisVacation: !this.state.hiddenDataGirisVacation,
    });
  };

  addEndOfServiceBenefits = async () => {
    this.setState({
      hiddenDataGirisEndOfServiceBenefits: !this.state.hiddenDataGirisEndOfServiceBenefits,
    });
  };

  render() {
    var itemDepartmentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Department");
    var itemEmployeeNameAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EmployeeName");
    var itemEmployeeIdAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EmployeeId");
    var itemContractStartDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ContractStartDate");
    var itemContractEndDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ContractEndDate");
    var itemTotalWorkingYearsAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TotalWorkingYears");
    var itemEndOfServiceTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EndOfServiceType");

    var itemDepartmentRequired = this.state.dynamicInputs.find(p => p.inputKey === "Department");
    var itemEmployeeNameRequired = this.state.dynamicInputs.find(p => p.inputKey === "EmployeeName");
    var itemEmployeeIdRequired = this.state.dynamicInputs.find(p => p.inputKey === "EmployeeId");
    var itemContractStartDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "ContractStartDate");
    var itemContractEndDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "ContractEndDate");
    var itemTotalWorkingYearsRequired = this.state.dynamicInputs.find(p => p.inputKey === "TotalWorkingYears");
    var itemEndOfServiceTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "EndOfServiceType");


    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EndOfServiceCalculation")} icon="pi pi-cog"></PrimeButton>,
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const columnsSalary = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EndOfServiceCalculation")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPeriod" defaultMessage="Period" />,
        render: (value, row, index) => {
          var dayFirst = moment().month(row.month - 1).format('MMM');
          return row.firstDay + " " + dayFirst + " - " + row.lastDay + " " + dayFirst;
        }
      },
      {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationEntitledAmount" defaultMessage="Entitled Amount" />,
        render: (value, row, index) => {
          let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
          if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
            let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
            if (Boolean(contract.basicSalary))
              return (contract.basicSalary * x).toFixed(2);
          }
        }
      },
      {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPayment" defaultMessage="Payment" />,
        render: (value, row, index) => {
          let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
          if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
            let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
            if (Boolean(contract.basicSalary)) {
              var salaryPaymentDate = null;
              if (row != null && row != undefined) { //"Salary Period" un son günü ile "Salary Day" toplamı
                if (row.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                  try {
                    var lastPeriodDay = moment(row.year + "-" + row.month + "-" + row.lastDay);
                    if (Boolean(lastPeriodDay)) {
                      var addDate = lastPeriodDay.add(row.paymentDay, 'd');
                      if (addDate.day() === 5) {
                        addDate = lastPeriodDay.add(2, 'd');
                        salaryPaymentDate = addDate;
                      } else if (addDate.day() === 6) {
                        addDate = lastPeriodDay.add(1, 'd');
                        salaryPaymentDate = addDate;
                      } else {
                        salaryPaymentDate = addDate;
                      }
                    }
                  } catch (error) {
                  }
                } else {
                  try {
                    var lastPeriodDay = moment(row.year + "-" + row.month + "-" + row.lastDay);
                    if (Boolean(lastPeriodDay)) {
                      salaryPaymentDate = lastPeriodDay.add(row.paymentDay, 'd');
                    }
                  } catch (error) {
                  }
                }
              }
              if (salaryPaymentDate.isSameOrBefore(this.state.contractEndDate)) {
                return (contract.basicSalary * x).toFixed(2);
              } else {
                return 0;
              }
            }
          }
        }
      },
      {
        dataIndex: "balanceSalary",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationBalance" defaultMessage="Balance" />,
      },
    ];

    const tableColumnsSalary = columnsSalary.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsSalary[0].fixed = true;
      tableColumnsSalary[tableColumnsSalary.length - 1].fixed = 'right';
    }

    const columnsAllowances = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EndOfServiceCalculation")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPeriod" defaultMessage="Period" />,
        render: (value, row, index) => {
          var dayFirst = moment().month(row.month - 1).format('MMM');
          return row.firstDay + " " + dayFirst + " - " + row.lastDay + " " + dayFirst;
        }
      },
      {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationEntitledAmount" defaultMessage="Entitled Amount" />,
        render: (value, row, index) => {
          let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
          var total = 0;
          if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
            let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
            if (Boolean(contract.homeAllowance)) {
              total += contract.homeAllowance * x;
            }
            if (Boolean(contract.transportationAllowance)) {
              total += contract.transportationAllowance * x;
            }
            if (Boolean(contract.foodAllowance)) {
              total += contract.foodAllowance * x;
            }
            if (Boolean(contract.otherPayments)) {
              total += contract.otherPayments * x;
            }
          }
          return total.toFixed(2);
        }
      },
      {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPayment" defaultMessage="Payment" />,
        render: (value, row, index) => {
          let x = row.countTimesheetLog / (row.lastDay - row.firstDay + 1);
          var total = 0;
          if (Boolean(row.staff) && Boolean(row.staff.staffContractList) && row.staff.staffContractList.length > 0) {
            let contract = row.staff.staffContractList[row.staff.staffContractList.length - 1];
            if (Boolean(contract.homeAllowance)) {
              total += contract.homeAllowance * x;
            }
            if (Boolean(contract.transportationAllowance)) {
              total += contract.transportationAllowance * x;
            }
            if (Boolean(contract.foodAllowance)) {
              total += contract.foodAllowance * x;
            }
            if (Boolean(contract.otherPayments)) {
              total += contract.otherPayments * x;
            }
          }

          if (total > 0) {
            var salaryPaymentDate = null;
            if (row != null && row != undefined) { //"Salary Period" un son günü ile "Salary Day" toplamı
              if (row.paymentInFinancialDay) { //"In first fiscal date" işaretli ise, Friday ve Saturday' e denk gelen gün tarihleri yazılmamalı; sonraki ilk pazar günün tarihi yazılmalı
                try {
                  var lastPeriodDay = moment(row.year + "-" + row.month + "-" + row.lastDay);
                  if (Boolean(lastPeriodDay)) {
                    var addDate = lastPeriodDay.add(row.paymentDay, 'd');
                    if (addDate.day() === 5) {
                      addDate = lastPeriodDay.add(2, 'd');
                      salaryPaymentDate = addDate;
                    } else if (addDate.day() === 6) {
                      addDate = lastPeriodDay.add(1, 'd');
                      salaryPaymentDate = addDate;
                    } else {
                      salaryPaymentDate = addDate;
                    }
                  }
                } catch (error) {
                }
              } else {
                try {
                  var lastPeriodDay = moment(row.year + "-" + row.month + "-" + row.lastDay);
                  if (Boolean(lastPeriodDay)) {
                    salaryPaymentDate = lastPeriodDay.add(row.paymentDay, 'd');
                  }
                } catch (error) {
                }
              }
            }
            if (salaryPaymentDate.isSameOrBefore(this.state.contractEndDate)) {
              return total.toFixed(2);;
            } else {
              return 0;
            }
          }
          return total.toFixed(2);
        }
      },
      {
        dataIndex: "balanceAllowances",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationBalance" defaultMessage="Balance" />,
      },
    ];

    const tableColumnsAllowances = columnsAllowances.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsAllowances[0].fixed = true;
      tableColumnsAllowances[tableColumnsAllowances.length - 1].fixed = 'right';
    }

    const columnsVacation = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EndOfServiceCalculation")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "period",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPeriod" defaultMessage="Period" />,
      },
      {
        dataIndex: "entitledDays",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationEntitledDays" defaultMessage="Entitled Days" />,
      },
      {
        dataIndex: "usedDays",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationUsedDays" defaultMessage="Used Days" />,
      },
      {
        dataIndex: "remainingDays",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationRemainingDays" defaultMessage="Remaining Days" />,
      },
    ];

    const tableColumnsVacation = columnsVacation.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsVacation[0].fixed = true;
      tableColumnsVacation[tableColumnsVacation.length - 1].fixed = 'right';
    }

    const columnsEndOfServiceBenefits = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("EndOfServiceCalculation")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "period",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPeriod" defaultMessage="Period" />,
      },
      {
        dataIndex: "workingYears",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationWorkingYears" defaultMessage="Working Years" />,
      },
      {
        dataIndex: "payment",
        key: "key",
        title: <FormattedMessage id="EndOfServiceCalculationPayment" defaultMessage="Payment" />,
      },
    ];

    const tableColumnsEndOfServiceBenefits = columnsEndOfServiceBenefits.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsEndOfServiceBenefits[0].fixed = true;
      tableColumnsEndOfServiceBenefits[tableColumnsEndOfServiceBenefits.length - 1].fixed = 'right';
    }


    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      this.showWarning();
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };

    const layout1 = {
      labelCol: { span: 0 },
      wrapperCol: { span: 18 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Card>
            <Form
              initialValues={{ remember: false }}
              onFinish={this.endOfServiceCalculation}
              onFinishFailed={onFinishFailed}
              ref={this.formRef} >

              {/* <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("EndOfServiceCalculation")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row> */}

              {/* <div hidden={this.state.hiddenDataGiris}> */}
              <div>
                <br />

                {(itemDepartmentAttributes.visible) &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EndOfServiceCalculationDepartment" defaultMessage="Department" />}
                    name="Department"
                    rules={[{ required: itemDepartmentRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDepartmentRequired.translateRequired) == true ? itemDepartmentRequired.translateRequired : -1} defaultMessage="Please input your Department!" /> }]} >
                    <Select mode="single" value={this.state.department} onChange={(value) => {
                      this.getEmployeeNames(value);
                      this.formRef.current.setFieldsValue({
                        EmployeeId: "",
                        EmployeeName: "",
                      });
                      this.setState({
                        employeeName: "",
                        employeeId: "",
                        department: value,
                      });
                    }}
                    >
                      {this.state.departments.map(i => (
                        <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }

                {(itemEmployeeNameAttributes.visible) &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EndOfServiceCalculationEmployeeName" defaultMessage="Employee name" />}
                    name="EmployeeName"
                    rules={[{ required: itemEmployeeNameRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemEmployeeNameRequired.translateRequired) == true ? itemEmployeeNameRequired.translateRequired : -1} defaultMessage="Please input your Employee name!" /> }]} >
                    <Select mode="single" value={this.state.employeeName} onChange={(value) => {
                      var contractStartDate = null;
                      var employee = this.state.employeeNames.find(p => p.id == value);
                      if (Boolean(employee) && Boolean(employee.staffContractList) && employee.staffContractList.length > 0) {
                        contractStartDate = employee.staffContractList[employee.staffContractList.length - 1].contractDate;
                      }
                      this.formRef.current.setFieldsValue({
                        ContractStartDate: (Boolean(contractStartDate) && contractStartDate != null && contractStartDate != undefined) ? moment(contractStartDate) : null,
                        EmployeeId: "",
                        ContractEndDate: "",
                        TotalWorkingYears: "",
                        EndOfServiceType: "",
                      });

                      this.setState({
                        hiddenCalculate: true,
                        hiddenDataGirisTotalPayment: true,
                        hiddenDataGirisSalary: true,
                        hiddenDataGirisAllowances: true,
                        hiddenDataGirisVacation: true,
                        hiddenDataGirisEndOfServiceBenefits: true,
                        employeeName: value,
                        employeeId: "",
                        contractEndDate: "",
                        totalWorkingYears: "",
                        endOfServiceType: 0,
                        contractStartDate: Boolean(contractStartDate) ? moment(contractStartDate) : null,
                      });
                    }}
                    >
                      {this.state.employeeNames.map(i => (
                        <Option key={i.id} value={i.id}>{i.fullName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }

                {(itemEmployeeIdAttributes.visible) &&
                  <Form.Item
                    {...layout}
                    label={<FormattedMessage id="EndOfServiceCalculationEmployeeId" defaultMessage="Employee ID" />}
                    name="EmployeeId"
                    rules={[{ required: itemEmployeeIdRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemEmployeeIdRequired.translateRequired) == true ? itemEmployeeIdRequired.translateRequired : -1} defaultMessage="Please input your Employee ID!" /> }]} >
                    <Select mode="single" value={this.state.employeeId} onChange={(value) => {
                      var contractStartDate = null;
                      var employee = this.state.employeeNames.find(p => p.id == value);
                      if (Boolean(employee) && Boolean(employee.staffContractList) && employee.staffContractList.length > 0) {
                        contractStartDate = employee.staffContractList[employee.staffContractList.length - 1].contractDate;
                      }
                      this.formRef.current.setFieldsValue({
                        ContractStartDate: (Boolean(contractStartDate)) ? moment(contractStartDate) : null,
                        EmployeeName: "",
                        ContractEndDate: "",
                        TotalWorkingYears: "",
                        EndOfServiceType: "",
                      });

                      this.setState({
                        hiddenCalculate: true,
                        hiddenDataGirisTotalPayment: true,
                        hiddenDataGirisSalary: true,
                        hiddenDataGirisAllowances: true,
                        hiddenDataGirisVacation: true,
                        hiddenDataGirisEndOfServiceBenefits: true,
                        employeeId: value,
                        employeeName: "",
                        contractEndDate: "",
                        totalWorkingYears: "",
                        endOfServiceType: 0,
                        contractStartDate: Boolean(contractStartDate) ? moment(contractStartDate) : null,
                      });
                    }}
                    >
                      {this.state.employeeNames.map(i => (
                        <Option key={i.id} value={i.id}>{i.budgeNo}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                }

                <div hidden={((this.state.employeeName != null && this.state.employeeName != undefined && this.state.employeeName != "")
                  || (this.state.employeeId != null && this.state.employeeId != undefined && this.state.employeeId != "")) ? false : true}>

                  {(itemContractStartDateAttributes.visible) &&
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EndOfServiceCalculationContractStartDate" defaultMessage="Contract Start Date" />}
                      name="ContractStartDate"
                      rules={[{ required: itemContractStartDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemContractStartDateRequired.translateRequired) == true ? itemContractStartDateRequired.translateRequired : -1} defaultMessage="Please input your Contract Start Date!" /> }]} >
                      <DatePicker disabled value={this.state.contractStartDate}
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  }

                  {(itemContractEndDateAttributes.visible) &&
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EndOfServiceCalculationContractEndDate" defaultMessage="Contract End Date" />}
                      name="ContractEndDate"
                      rules={[{ required: itemContractEndDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemContractEndDateRequired.translateRequired) == true ? itemContractEndDateRequired.translateRequired : -1} defaultMessage="Please input your Contract End Date!" /> }]} >
                      <DatePicker disabledDate={this.disabledDate} onChange={(value) => {
                        if (Boolean(this.state.contractStartDate) && this.state.contractStartDate != null && this.state.contractStartDate != undefined) {

                          var a = moment(value, "DD-MM-YYYY");
                          var b = moment(this.state.contractStartDate, "DD-MM-YYYY");

                          var years = a.diff(b, 'year');
                          b.add(years, 'years');

                          var months = a.diff(b, 'months');
                          b.add(months, 'months');

                          var days = a.diff(b, 'days');

                          var totalWorkingYears = "";
                          var totalYear = 0;
                          if (years > 0) {
                            totalWorkingYears += years + ' years ';
                            totalYear = years;
                          }
                          if (months > 0) {
                            totalWorkingYears += months + ' months ';
                          }
                          if (days > 0) {
                            totalWorkingYears += days + ' days ';
                          }

                          this.formRef.current.setFieldsValue({
                            TotalWorkingYears: totalWorkingYears,
                          });

                          this.setState({
                            contractEndDate: value,
                            totalWorkingYears: totalWorkingYears,
                            totalYear: totalYear,
                          });
                        } else {
                          this.showWarning("Contract Start Date not found.");
                        }
                      }} value={this.state.contractEndDate}
                        format="DD-MM-YYYY"
                      />
                    </Form.Item>
                  }

                  {(itemTotalWorkingYearsAttributes.visible) &&
                    <Form.Item
                      {...layout}
                      label={<FormattedMessage id="EndOfServiceCalculationTotalWorkingYears" defaultMessage="Total working years" />}
                      name="TotalWorkingYears"
                      rules={[{ required: itemTotalWorkingYearsRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTotalWorkingYearsRequired.translateRequired) == true ? itemTotalWorkingYearsRequired.translateRequired : -1} defaultMessage="Please input your Total working years!" /> }]} >
                      <Input style={{ width: '100%' }} disabled value={this.state.totalWorkingYears} />
                    </Form.Item>
                  }

                  <RowAnt gutter={8}>
                    <ColAnt span={6}></ColAnt>
                    <ColAnt span={15}>
                      {(itemEndOfServiceTypeAttributes.visible && this.state.hiddenEndOfServiceType == false) &&
                        <Form.Item
                          {...layout1}
                          name="EndOfServiceType"
                          rules={[{ required: itemEndOfServiceTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemEndOfServiceTypeRequired.translateRequired) == true ? itemEndOfServiceTypeRequired.translateRequired : -1} defaultMessage="Please input your Type!" /> }]} >
                          <Radio.Group onChange={(e) => {
                            var endOfServiceBenefitsType = "";
                            if (e.target.value == 1) {
                              endOfServiceBenefitsType = "Involuntary";
                            } else if (e.target.value == 2) {
                              endOfServiceBenefitsType = "Voluntary";
                            }
                            this.setState({
                              endOfServiceType: e.target.value,
                              endOfServiceBenefitsType: endOfServiceBenefitsType,
                            });
                          }}
                            value={this.state.endOfServiceType}>
                            <Radio value={1}><FormattedMessage id="EndOfServiceCalculationTermination" defaultMessage="Termination" /></Radio>
                            <Radio value={2}><FormattedMessage id="EndOfServiceCalculationResignation" defaultMessage="Resignation" /></Radio>
                            <Radio value={3}><FormattedMessage id="EndOfServiceCalculationArtical81" defaultMessage="Artical 81" /></Radio>
                          </Radio.Group>
                        </Form.Item>
                      }
                    </ColAnt>
                  </RowAnt>

                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="5">
                      <Button id="EndOfServiceCalculationCalculateButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonCalculate" defaultMessage="Calculate" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>

                  <div hidden={this.state.hiddenCalculate}>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Col sm="1">
                        <AntButton type="primary" hidden={!this.state.hiddenDataGirisTotalPayment} onClick={this.addTotalPayment} icon={<PlusOutlined />}></AntButton>
                        <AntButton type="primary" hidden={this.state.hiddenDataGirisTotalPayment} onClick={this.addTotalPayment} danger icon={<MinusOutlined />}></AntButton>
                      </Col>
                      <FormBoot.Label column sm="3" style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id="EndOfServiceCalculationTotalPayment" defaultMessage="Total Payment" />
                      </FormBoot.Label>
                      <Col sm="2">
                        <InputNumber min={0} style={{ width: '100%' }} disabled value={this.state.totalPayment} />
                      </Col>
                    </FormBoot.Group>

                    <div hidden={this.state.hiddenDataGirisTotalPayment}>
                      <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2"></Col>
                        <Col sm="1">
                          <AntButton type="primary" hidden={!this.state.hiddenDataGirisSalary} onClick={this.addSalary} icon={<PlusOutlined />}></AntButton>
                          <AntButton type="primary" hidden={this.state.hiddenDataGirisSalary} onClick={this.addSalary} danger icon={<MinusOutlined />}></AntButton>
                        </Col>
                        <FormBoot.Label column sm="2" style={{ fontWeight: 'bold' }}>
                          <FormattedMessage id="EndOfServiceCalculationSalary" defaultMessage="Salary" />
                        </FormBoot.Label>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} disabled value={this.state.totalSalaryBalance} />
                        </Col>
                      </FormBoot.Group>

                      <div hidden={this.state.hiddenDataGirisSalary}>
                        <div className="datatable-doc-demo">
                          <div className="content-section implementation">
                            <Col sm="2"></Col>
                            <Col sm="10">
                              <Mnd.MNDTable
                                state={this.state}
                                columns={tableColumnsSalary} dataSource={this.state.salaryTable}
                                pagination={this.state.paginationDisabled}
                                expandable={this.state.expandable}
                                footer={this.state.footer}
                                title={this.state.title}
                                scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                              />
                            </Col>
                            <br />
                          </div>
                        </div>
                      </div>

                      <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2"></Col>
                        <Col sm="1">
                          <AntButton type="primary" hidden={!this.state.hiddenDataGirisAllowances} onClick={this.addAllowances} icon={<PlusOutlined />}></AntButton>
                          <AntButton type="primary" hidden={this.state.hiddenDataGirisAllowances} onClick={this.addAllowances} danger icon={<MinusOutlined />}></AntButton>
                        </Col>
                        <FormBoot.Label column sm="2" style={{ fontWeight: 'bold' }}>
                          <FormattedMessage id="EndOfServiceCalculationAllowances" defaultMessage="Allowances" />
                        </FormBoot.Label>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} disabled value={this.state.totalAllowancesBalance} />
                        </Col>
                      </FormBoot.Group>

                      <div hidden={this.state.hiddenDataGirisAllowances}>
                        <div className="datatable-doc-demo">
                          <div className="content-section implementation">
                            <Col sm="2"></Col>
                            <Col sm="10">
                              <Mnd.MNDTable
                                state={this.state}
                                columns={tableColumnsAllowances} dataSource={this.state.salaryTable}
                                pagination={this.state.paginationDisabled}
                                expandable={this.state.expandable}
                                footer={this.state.footer}
                                title={this.state.title}
                                scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                              />
                            </Col>
                            <br />
                          </div>
                        </div>
                      </div>

                      <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2"></Col>
                        <Col sm="1">
                          <AntButton type="primary" hidden={!this.state.hiddenDataGirisVacation} onClick={this.addVacation} icon={<PlusOutlined />}></AntButton>
                          <AntButton type="primary" hidden={this.state.hiddenDataGirisVacation} onClick={this.addVacation} danger icon={<MinusOutlined />}></AntButton>
                        </Col>
                        <FormBoot.Label column sm="2" style={{ fontWeight: 'bold' }}>
                          <FormattedMessage id="EndOfServiceCalculationVacation" defaultMessage="Vacation" />
                        </FormBoot.Label>
                        <Col sm="2">
                          <InputNumber min={0} style={{ width: '100%' }} disabled value={this.state.totalVacation} />
                        </Col>
                      </FormBoot.Group>

                      <div hidden={this.state.hiddenDataGirisVacation}>
                        <div className="datatable-doc-demo">
                          <div className="content-section implementation">
                            <Col sm="2"></Col>
                            <Col sm="10">
                              <Mnd.MNDTable
                                state={this.state}
                                columns={tableColumnsVacation} dataSource={this.state.vacationTable}
                                pagination={this.state.paginationDisabled}
                                expandable={this.state.expandable}
                                footer={this.state.footer}
                                title={this.state.title}
                                scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                              />
                              <br />
                            </Col>
                          </div>
                        </div>
                      </div>

                      <div hidden={(this.state.endOfServiceType == 1 || this.state.endOfServiceType == 2) == true ? false : true}>
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Col sm="2"></Col>
                          <Col sm="1">
                            <AntButton type="primary" hidden={!this.state.hiddenDataGirisEndOfServiceBenefits} onClick={this.addEndOfServiceBenefits} icon={<PlusOutlined />}></AntButton>
                            <AntButton type="primary" hidden={this.state.hiddenDataGirisEndOfServiceBenefits} onClick={this.addEndOfServiceBenefits} danger icon={<MinusOutlined />}></AntButton>
                          </Col>
                          <FormBoot.Label column sm="2" style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="EndOfServiceCalculationEndOfServiceBenefits" defaultMessage="End of Service Benefits" />
                          </FormBoot.Label>
                          <Col sm="2">
                            <InputNumber min={0} style={{ width: '100%' }} disabled value={this.state.totalEndOfServiceBenefit} />
                          </Col>
                        </FormBoot.Group>

                        <div hidden={this.state.hiddenDataGirisEndOfServiceBenefits}>
                          <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                              <Col sm="2"></Col>
                              <Col sm="10">
                                <Mnd.MNDTable
                                  state={this.state}
                                  columns={tableColumnsEndOfServiceBenefits} dataSource={this.state.endOfServiceBenefitsTable.filter(p => p.type == this.state.endOfServiceBenefitsType)}
                                  pagination={this.state.paginationDisabled}
                                  expandable={this.state.expandable}
                                  footer={this.state.footer}
                                  title={this.state.title}
                                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                                <br />
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div hidden={this.state.hiddenUpdate}>
                  {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="3"></Col>
                      <Col sm="2">
                        <Button id="EndOfServiceCalculationCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                      </Col>
                      <Col sm="3">
                        <Button id="EndOfServiceCalculationUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                      </Col>
                      <Col sm="4">
                      </Col>
                    </FormBoot.Group>
                  }
                </div>

              </div>
              <br />
              <br />

              <div className="datatable-doc-demo">
                <div className="content-section implementation">
                  {/* <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.endOfServiceCalculationsTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                /> */}
                </div>
              </div>

            </Form>
          </Card>
        }

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteEndOfServiceCalculationOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(EndOfServiceCalculation)