import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt, Select } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Checkbox, Timeline, Button as AntButton } from 'antd';
import { CloseOutlined, UpCircleOutlined, DownCircleOutlined, SecurityScanOutlined } from '@ant-design/icons';

const { Option } = Select;

const { Column } = Table;

export class Status extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            timesheetWorkflowId: 0,
            timesheetWorkflowList: [],
            workflowName: '',
            dynamiaIntegrated: false,
            modeOfTimesheetId: null,
            methodOfLoggingId: null,
            workflowStepSelectItems: [{ id: 1, description: 'Logging' }, { id: 2, description: 'Review' }, { id: 3, description: 'Confirmation' }],
            stepNameId: null,
            modeOfTimesheetSelectItems: [{ id: 1, description: 'Eye Scan' }, { id: 2, description: 'QR Scan' }, { id: 3, description: 'Manuel' }, { id: 4, description: 'Coordinates' }, { id: 5, description: 'None' }],
            methodOfLoggingSelectItems: [{ id: 1, description: 'Manuel' }, { id: 2, description: 'Import From Excel' }],
            stepList: [],
            selectedStep: null,
            selectedDepartments: [],
            selectedDisciplines: [],
            selectedDesignations: [],
            departmentSelectItems: [],
            disciplineSelectItems: [],
            disciplineSelectItemsAll: [],
            designationSelectItems: [],
            oldTimesheetApplicableAreas: [],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.restartTable();
        this.setComboboxes();

    }

    save = async () => {

        const list = this.state.stepList;
        const selectedDepartments = this.state.selectedDepartments;
        const selectedDisciplines = this.state.selectedDisciplines;
        const selectedDesignations = this.state.selectedDesignations;

        list.forEach((element, index) => {
            element.stepOrder = index;
        });

        if (Boolean(selectedDepartments) && selectedDepartments.length === 0 && Boolean(selectedDisciplines) && selectedDisciplines.length === 0 && Boolean(selectedDesignations) && selectedDesignations.length === 0) {

            this.showError("Please select at least one applicable area.");
            return;
        }

        let _timesheetApplicableAreas = [];
        let _timesheetApplicableDepartments = [];
        let _timesheetApplicableDisciplines = [];
        if (Boolean(selectedDepartments) && selectedDepartments.length > 0) {
            selectedDepartments.forEach(department => {

                let obj = { departmentId: department, disciplineId: null, designationId: null }
                _timesheetApplicableDepartments.push(obj);
            });
        }
        else {
            let obj = { departmentId: null, disciplineId: null, designationId: null }
            _timesheetApplicableDepartments.push(obj);
        }

        if (Boolean(selectedDisciplines) && selectedDisciplines.length > 0) {
            selectedDisciplines.forEach(discipline => {

                _timesheetApplicableDepartments.forEach(element => {

                    let obj = { departmentId: element.departmentId, disciplineId: discipline, designationId: null }                  
                    _timesheetApplicableDisciplines.push(obj);

                });

            });
        }
        else {
            _timesheetApplicableDepartments.forEach(element => {

                _timesheetApplicableDisciplines.push(element);


            });
        }


        if (Boolean(selectedDesignations) && selectedDesignations.length > 0) {
            selectedDesignations.forEach(designation => {

                _timesheetApplicableDisciplines.forEach(element => {

                    let obj = { departmentId: element.departmentId, disciplineId: element.disciplineId, designationId: designation }    
                    _timesheetApplicableAreas.push(obj);

                });

            });
        }
        else {
            _timesheetApplicableDisciplines.forEach(element => {

                _timesheetApplicableAreas.push(element);


            });
        }
        const oldTimesheetApplicableAreas = this.state.oldTimesheetApplicableAreas;
        if (Boolean(oldTimesheetApplicableAreas) && oldTimesheetApplicableAreas.length > 0) {

            oldTimesheetApplicableAreas.forEach(element => {
                element.active=false;
            });

        }

        _timesheetApplicableAreas.forEach(element => {
            
            let index = oldTimesheetApplicableAreas.findIndex(p=>p.departmentId===element.departmentId && p.disciplineId===element.disciplineId && p.designationId===element.designationId);
            if(index===-1)
            oldTimesheetApplicableAreas.push(element);
            if(index>=0)
            oldTimesheetApplicableAreas[index].active = true; 

        });


        const obj = {
            id: this.state.timesheetWorkflowId,
            workflowName: this.state.workflowName,
            dynamiaIntegrated: this.state.dynamiaIntegrated,
            modeOfTimesheetId: this.state.modeOfTimesheetId,
            methodOfLoggingId: this.state.methodOfLoggingId,
            timesheetWorkflowSteps: list,
            timesheetApplicableAreas: oldTimesheetApplicableAreas
        }

        console.log(obj);
        if (obj.id === 0)
            await handleRequest("POST", "/TimesheetWorkflow/add", obj);
        else
            await handleRequest("POST", "/TimesheetWorkflow/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        // const _stepList = [];
        const _depList = [];
        const _disList = [];
        const _desList = [];

        if (Boolean(row.timesheetWorkflowSteps) && row.timesheetWorkflowSteps.length > 0) {
            row.timesheetWorkflowSteps.forEach(element => {

                element.value = this.state.workflowStepSelectItems.find(p => p.id === element.stepNameId).description;


            });
        }

        if (Boolean(row.timesheetApplicableAreas) && row.timesheetApplicableAreas.length > 0) {
            row.timesheetApplicableAreas.forEach(element => {

                if (Boolean(element.departmentId) && !_depList.includes(element.departmentId))
                    _depList.push(element.departmentId);
                if (Boolean(element.disciplineId) && !_disList.includes(element.disciplineId))
                    _disList.push(element.disciplineId);
                if (Boolean(element.designationId) && !_desList.includes(element.designationId))
                    _desList.push(element.designationId); 


            });
        }

        this.setState({
            timesheetWorkflowId: row.id,
            workflowName: row.workflowName,
            dynamiaIntegrated: row.dynamiaIntegrated,
            modeOfTimesheetId: row.modeOfTimesheetId,
            methodOfLoggingId: row.methodOfLoggingId,
            stepList: row.timesheetWorkflowSteps,
            selectedDepartments: _depList,
            selectedDisciplines: _disList,
            selectedDesignations: _desList,
            oldTimesheetApplicableAreas: row.timesheetApplicableAreas,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.timesheetWorkflowId
        }

        await handleRequest("POST", "/TimesheetWorkflow/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            timesheetWorkflowId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        const response = await handleRequest("GET", "/TimesheetWorkflow/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                timesheetWorkflowList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                timesheetWorkflowList: [],
            });
        }
    }

    setComboboxes = async () => {
        const resDepList = await handleRequest("GET", "/Department/getallByCompany");
        if (resDepList.data.length > 0) {
            this.setState({
                departmentSelectItems: resDepList.data
            });
        } else {
            this.setState({
                departmentSelectItems: []
            });
        }

        const resDisList = await handleRequest("GET", "/Discipline/getallByCompany");
        if (resDisList.data.length > 0) {
            this.setState({
                disciplineSelectItems: resDisList.data,
                disciplineSelectItemsAll: resDisList.data
            });
        } else {
            this.setState({
                disciplineSelectItems: []
            });
        }

        const repDesList = await handleRequest("GET", "/Designation/getallByCompany");
        if (repDesList.data.length > 0) {
            this.setState({
                designationSelectItems: repDesList.data
            });
        } else {
            this.setState({
                designationSelectItems: []
            });
        }
    }

    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "TimesheetWorkflow" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "TimesheetWorkflow"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "TimesheetWorkflow");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }
            if (Boolean(result) && Boolean(result.data)) {
                this.setState({
                    formId: result.data.id
                });

                this.getUserSettings();
            }
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            timesheetWorkflowId: 0,
            workflowName: '',
            dynamiaIntegrated: false,
            modeOfTimesheetId: null,
            methodOfLoggingId: null,
            stepNameId: null,
            stepList: [],
            selectedStep: null,
            selectedDepartments: [],
            selectedDisciplines: [],
            selectedDesignations: [],
            oldTimesheetApplicableAreas:[],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({

            timesheetWorkflowId: 0,
            workflowName: '',
            dynamiaIntegrated: false,
            modeOfTimesheetId: null,
            methodOfLoggingId: null,
            stepNameId: null,
            stepList: [],
            selectedStep: null,
            selectedDepartments: [],
            selectedDisciplines: [],
            selectedDesignations: [],
            oldTimesheetApplicableAreas:[],
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({

            timesheetWorkflowId: 0,
            workflowName: '',
            dynamiaIntegrated: false,
            modeOfTimesheetId: null,
            methodOfLoggingId: null,
            stepNameId: null,
            stepList: [],
            selectedStep: null,
            selectedDepartments: [],
            selectedDisciplines: [],
            selectedDesignations: [],
            oldTimesheetApplicableAreas:[],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    moveUp = () => {

        const list = this.state.stepList;
        const step = this.state.selectedStep;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.stepNameId === step.stepNameId && p.active === true);

            if (index > 0) {

                let flag = list[index - 1];
                list[index - 1] = step;
                list[index] = flag;

            }

            this.setState({
                stepList: list
            });

        }

    }

    moveDown = () => {

        var list = this.state.stepList;
        const step = this.state.selectedStep;

        if (Boolean(list) && list.length > 0) {

            var index = list.findIndex(p => p.stepNameId === step.stepNameId && p.active === true);

            if (index < list.length - 1 && index !== -1) {

                let flag = list[index + 1];
                list[index + 1] = step;
                list[index] = flag;

            }

            this.setState({
                stepList: list
            });

        }

    }

    removeStep = (step) => {

        this.setState(prevState => ({
            stepList: prevState.stepList.map(
                el => el.stepNameId === step.stepNameId ? { ...el, active: false } : el
            )
        }))

        this.setState({ selectedStep: null })
    }

    addStep = async () => {


        var list = this.state.stepList.filter(p => p.active === true);

        if (Boolean(this.state.stepNameId) && this.state.stepNameId > 0) {

            let isThere = list.find(p => p.stepNameId === this.state.stepNameId);
            if (Boolean(isThere)) {
                this.showError("This workflow added before as a step !")
                return;
            }

            var fObj = {
                id: 0,
                stepNameId: this.state.stepNameId,
                label: this.state.workflowStepSelectItems.find(p => p.id === this.state.stepNameId).description,
                value: this.state.workflowStepSelectItems.find(p => p.id === this.state.stepNameId).description,
                stepOrder: this.state.stepList.length,
                active: true
            };

            list.push(fObj);

            this.setState({
                stepList: list
            })

        }
        else this.showError("Please select workflow step ! ");



    }

    clickStepValue = (step) => {
        this.setState({ selectedStep: step });
    }



    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Workflow Name" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Input id="todo" value={this.state.workflowName} onChange={(e) => this.setState({ workflowName: e.target.value })} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Workflow Steps" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.stepNameId} onChange={(value) => {
                                                    this.setState({
                                                        stepNameId: value,
                                                        // methodOfLoggingId: null,
                                                        // dynamiaIntegrated: false,
                                                        // modeOfTimesheetId: null
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.workflowStepSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.description}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {Boolean(this.state.stepNameId) && this.state.stepNameId === 1 &&
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                            </Col>
                                            <Col sm="6">
                                                <div>
                                                    <Checkbox onChange={(e) => {
                                                        this.setState({
                                                            methodOfLoggingId: null,
                                                            dynamiaIntegrated: e.target.checked
                                                        })
                                                    }} checked={this.state.dynamiaIntegrated}> Not dynamia integrated applications </Checkbox>
                                                </div>

                                            </Col>

                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.stepNameId) && this.state.stepNameId === 1 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Mode of timesheet" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.modeOfTimesheetId} onChange={(value) => {
                                                    this.setState({
                                                        modeOfTimesheetId: value
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.modeOfTimesheetSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.description}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.stepNameId) && this.state.stepNameId === 1 && this.state.dynamiaIntegrated &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Method Of Logging" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.methodOfLoggingId} onChange={(value) => {
                                                    this.setState({
                                                        methodOfLoggingId: value
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.methodOfLoggingSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.description}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                            </Col>

                                            <Col sm="6">
                                                <ReactButton id="OrganizationalLevelSaveButton" type="button" onClick={this.addStep}
                                                    variant="outline-info" ><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" /></ReactButton>
                                            </Col>

                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                            </Col>
                                            <Col sm="2">
                                                <Form.Row hidden={!Boolean(this.state.selectedStep)} >
                                                    <Col sm="8">

                                                    </Col>
                                                    <Col sm="4">
                                                        <AntButton style={{ marginRight: '10px', border: '0px', alignContent: 'right' }} type="text" onClick={this.moveUp} icon={<UpCircleOutlined style={{ fontSize: '32px' }} />} />

                                                    </Col>
                                                    <Col sm="8">

                                                    </Col>
                                                    <Col sm="4">
                                                        <AntButton style={{ marginRight: '10px', marginTop: '5px', border: '0px', alignContent: 'right' }} type="text" onClick={this.moveDown} icon={<DownCircleOutlined style={{ fontSize: '32px' }} />} />

                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                            <Col sm="4">
                                                <Timeline mode='left'>

                                                    {this.state.stepList.filter(p => p.active === true).map((opt, j) => (
                                                        <Timeline.Item> <a
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => this.clickStepValue(opt)}
                                                        >
                                                            {opt.value}
                                                            <AntButton style={{ marginLeft: '10px' }} type="dashed" onClick={() => this.removeStep(opt)} shape="circle" icon={<CloseOutlined />} />
                                                        </a>
                                                        </Timeline.Item>

                                                    ))}

                                                </Timeline>
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Applicable Areas" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Department" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDepartments} onChange={(value) => {

                                                    if (Boolean(value) && value.length > 0) {
                                                        var listDep = this.state.departmentSelectItems.filter(p => value.includes(p.id));
                                                        var list = this.state.disciplineSelectItemsAll.filter(p => listDep.findIndex(element => element.disciplineDepartmentList.findIndex(t => t.disciplineId === p.id) >= 0) >= 0);

                                                    }
                                                    else list = this.state.disciplineSelectItemsAll;
                                                    this.setState({
                                                        selectedDepartments: value,
                                                        disciplineSelectItems: list
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.departmentSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDisciplines} onChange={(value) => {
                                                    this.setState({
                                                        selectedDisciplines: value
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.disciplineSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Designation" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDesignations} onChange={(value) => {
                                                    this.setState({
                                                        selectedDesignations: value
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.designationSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.designationName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.timesheetWorkflowList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.timesheetWorkflowList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Workflow Name" dataIndex="workflowName" />
                            <Column title="Mode Of Timesheet" render={(value, row, index) => {

                                if (Boolean(row.modeOfTimesheetId) && row.modeOfTimesheetId > 0)
                                    return this.state.modeOfTimesheetSelectItems.find(p => p.id === row.modeOfTimesheetId).description;

                            }} />

                            <Column title="Method Of Logging" render={(value, row, index) => {

                                if (Boolean(row.methodOfLoggingId) && row.methodOfLoggingId > 0)
                                    return this.state.methodOfLoggingSelectItems.find(p => p.id === row.methodOfLoggingId).description;

                            }} />

                            <Column title="Applicaple Areas" render={(value, row, index) => {

                                let result = [];
                                if (Boolean(row.timesheetApplicableAreas) && row.timesheetApplicableAreas.length > 0) {
                                    row.timesheetApplicableAreas.forEach(element => {
                                        if (Boolean(element.department) && !result.includes(element.department.departmentName))
                                            result.push(element.department.departmentName);
                                        if (Boolean(element.discipline) && !result.includes(element.discipline.disciplineName))
                                            result.push(element.discipline.disciplineName);
                                        if (Boolean(element.designation) && !result.includes(element.designation.designationName))
                                            result.push(element.designation.designationName);
                                    });
                                }
                                return result.toString();

                            }} />
                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Status)