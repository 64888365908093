
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { SyncOutlined } from '@ant-design/icons'
import { setStandardization,getDynamicMenu,getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "TimesheetLog";


export class TimesheetLog extends Component {

    constructor() {
        super();
        let d = new Date();
        d.setHours(0, 0, 0, 0);


        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: 0,
            tableList: [],
            filePath: '',
            fileList: [],

            userSelectItems: [],
            staffId: null,
            date: moment(d),
            timeIn: null,
            timeOut: null,
            wokingHours: null,
            overtime: null,
            //[STATE_FIELDS],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
       
        var isAdmin = false; 
        let dynamicMenu = await getDynamicMenu(pageName);          
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {

            isAdmin = true ;         

        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes:standardization.dynamicAttributes,
            dynamicInputs:standardization.dynamicInputs,
            isAdmin:isAdmin,
            formId:dynamicMenu.formId,
            authorizations:dynamicMenu.authorizations
        })

       
        this.setComboboxes();
        this.restartTable();


    }

    getUserSettings = async (formId) => {

        let userSettings =  await getUserSettings(formId);
         this.setState(
          {
              howToUseSetting: userSettings.howToUseSetting,
              whatIsSetting: userSettings.whatIsSetting 
          }
         )
      }

    disabledTimeIn = (current) => {
        // Can not select days before today and today
        console.log(this.state.date);
        if (Boolean(this.state.date))
            return current < this.state.date;
        else return false;

    }

    disabledTimeOut = (current) => {
        // Can not select days before today and today
        if (Boolean(this.state.timeIn))
            return current < this.state.timeIn;
        else return false;

    }


    setComboboxes = async () => {

        const resDepList = await handleRequest("GET", "/Staff/getallByCompany");
        if (resDepList.data.length > 0) {

            this.setState({
                userSelectItems: resDepList.data
            });
        } else {
            this.setState({
                userSelectItems: []
            });
        }



    }

    save = async (fieldsValue) => {

        let staffId = this.state.staffId;
        let userSelectItems = this.state.userSelectItems;
        let staff = userSelectItems.find(p => p.id === staffId);;
        let obj = this.state.tableList.find(p => p.staffId === staff.id);

        fieldsValue.id = Boolean(obj) ? obj.id : 0;
        fieldsValue.departmentId = Boolean(staff) ? staff.departmentId : null;
        fieldsValue.designationId = Boolean(staff) ? staff.designationId : null;
        fieldsValue.date = this.state.date;

        if (fieldsValue.id === 0)
            await handleRequest("POST", "/TimesheetLog/add", fieldsValue);
        else
            await handleRequest("POST", "/TimesheetLog/update", fieldsValue);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }


    addAllUsers = async () => {

        const list = [];
        let userSelectItems = this.state.userSelectItems;
        let timeIn = new Date();
        timeIn.setHours(8, 0, 0, 0);

        let timeOut = new Date();
        timeOut.setHours(17, 0, 0, 0);


        userSelectItems.forEach(element => {

            let obj = {
                staffId: element.id,
                date: this.state.date,
                timeIn: timeIn,
                departmentId: Boolean(element) ? element.departmentId : null,
                designationId: Boolean(element) ? element.designationId : null,
                timeOut: timeOut,
                workingHours: this.state.wokingHours,
                overtime: this.state.overtime,
            };

            list.push(obj);
        });
        console.log(list);
        await handleRequest("POST", "/TimesheetLog/addAll", list);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            staffId: row.staffId,
            date: moment(row.date),
            timeIn: moment(row.timeIn),
            timeOut: moment(row.timeOut),
        });

        this.setState({
            id: row.id,
            staffId: row.staffId,
            date: moment(row.date),
            timeIn: moment(row.timeIn),
            timeOut: moment(row.timeOut),
            workingHours: row.workingHours,
            overtime: row.overtime,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/TimesheetLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        const response = await handleRequest("POST", "/TimesheetLog/getAllByDate", this.state.date);


        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        this.formRef.current.setFieldsValue({
            staffId: null,
            date: moment(d),
            timeIn: null,
            timeOut: null,
            wokingHours: null,
            overtime: null,
        });

        this.setState({
            id: 0,
            staffId: null,
            date: moment(d),
            timeIn: null,
            timeOut: null,
            wokingHours: null,
            overtime: null,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }



   


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    render() {





        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>

                        <MenuProvider id="menu_id">
                            <Modal
                                show={this.state.modalDeleteShow}
                                onHide={this.showOrHideDeleteModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                    <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                                </Modal.Footer>
                            </Modal>

                            <Card>

                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>



                                <div style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimesheetDate").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="TimesheetDate" defaultMessage="Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="date"
                                                rules={[{ required: false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetDate")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "TimesheetDate").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Form.Label style={{ color: 'black', font: 'bold', fontSize: '16pt', marginRight: '10px' }} >
                                                            {this.state.date.format("DD-MM-YYYY")}
                                                        </Form.Label>

                                                        {(!Boolean(this.state.tableList) || this.state.tableList.length === 0) && <AntButton onClick={this.addAllUsers} icon={<SyncOutlined />} />}


                                                        {/* <DatePicker id="TimesheetDate" value={this.state.date}  format="DD-MM-YYYY" 
                                                //  onChange={(date, dateString) => {

                                                //     var time = date.toDate();  // This will return a copy of the Date that the moment uses

                                                //     time.setHours(0);
                                                //     time.setMinutes(0);
                                                //     time.setSeconds(0);
                                                //     time.setMilliseconds(0);

                                                //     date = moment(time);

                                                //     this.formRef.current.setFieldsValue({
                                                //         //  date: //(inspectionDate!=null && inspectionDate!=undefined && inspectionDate!="") && moment(inspectionDate, 'YYYY-MM-DD'),
                                                //         date: date
                                                //     });
                                                //     this.setState({
                                                //         date: date
                                                //     })
                                                // }} 
                                            /> */}
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {this.state.tableList.length > 0 && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimesheetUser").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="TimesheetUser" defaultMessage="User" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="staffId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetUser")) ? this.state.dynamicInputs.find(p => p.inputKey === "TimesheetUser").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetUser")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "TimesheetUser").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt  gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                    <Select style={{ width: "100%" }} placeholder="User" id="TimesheetUser" value={this.state.staffId}
                                                            onChange={(value) => {
                                                             
                                                                this.formRef.current.setFieldsValue({
                                                                    staffId: value
                                                                });
                                                                this.setState({ staffId: value })
                                                            }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.userSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.fullName}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>
                                                 

                                            </FormAnt.Item>
                                        }  {this.state.tableList.length > 0 && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimesheetTimeIn").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="TimesheetTimeIn" defaultMessage="Time In" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="timeIn"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeIn")) ? this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeIn").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeIn")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeIn").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker showTime id="TimesheetTimeIn" disabledDate={this.disabledTimeIn} format="DD-MM-YYYY HH:mm" value={this.state.timeIn} onChange={(value) => {

                                                            if (Boolean(value) && Boolean(this.state.timeOut)) {
                                                                let overtime = 0;
                                                                let result = this.state.timeOut.diff(value, 'hours', true);
                                                                if (result > 9)
                                                                    overtime = (result - 9).toFixed(2);

                                                                this.formRef.current.setFieldsValue({
                                                                    workingHours: (result - 1).toFixed(2),
                                                                    overtime: overtime,
                                                                });
                                                                this.setState({
                                                                    workingHours: (result - 1).toFixed(2),
                                                                    overtime: overtime,
                                                                })



                                                            }
                                                            this.formRef.current.setFieldsValue({
                                                                //  date: //(inspectionDate!=null && inspectionDate!=undefined && inspectionDate!="") && moment(inspectionDate, 'YYYY-MM-DD'),
                                                                timeIn: value
                                                            });

                                                            this.setState({
                                                                timeIn: value
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {this.state.tableList.length > 0 && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimesheetTimeOut").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="TimesheetTimeOut" defaultMessage="Time Out" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="timeOut"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeOut")) ? this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeOut").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeOut")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "TimesheetTimeOut").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker disabledDate={this.disabledTimeOut} showTime id="TimesheetTimeOut" format="DD-MM-YYYY HH:mm" value={this.state.timeOut} onChange={(value) => {
                                                            if (Boolean(value) && Boolean(this.state.timeIn)) {
                                                                let overtime = 0;
                                                                let result = value.diff(this.state.timeIn, 'hours', true);
                                                                if (result > 9)
                                                                    overtime = (result - 9).toFixed(2);

                                                                this.formRef.current.setFieldsValue({
                                                                    workingHours: (result - 1).toFixed(2),
                                                                    overtime: overtime,
                                                                });
                                                                this.setState({
                                                                    workingHours: (result - 1).toFixed(2),
                                                                    overtime: overtime,
                                                                })

                                                            }
                                                            this.formRef.current.setFieldsValue({
                                                                timeOut: value
                                                            });
                                                            this.setState({
                                                                timeOut: value
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {this.state.tableList.length > 0 && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimesheetWorkingHours").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="TimesheetWorkingHours" defaultMessage="Working Hours" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="workingHours"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetWorkingHours")) ? this.state.dynamicInputs.find(p => p.inputKey === "TimesheetWorkingHours").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetWorkingHours")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "TimesheetWorkingHours").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Working Hours" style={{ width: "100%" }} id="TimesheetWorkingHours" value={this.state.workingHours} onChange={(e) => { this.setState({ workingHours: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {this.state.tableList.length > 0 && ((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "TimesheetOvertime").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="TimesheetOvertime" defaultMessage="Overtime" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="overtime"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetOvertime")) ? this.state.dynamicInputs.find(p => p.inputKey === "TimesheetOvertime").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "TimesheetOvertime")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "TimesheetOvertime").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Overtime" style={{ width: "100%" }} id="TimesheetOvertime" value={this.state.overtime}
                                                            onChange={(e) => { this.setState({ overtime: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {this.state.tableList.length > 0 &&
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                        <Modal
                                            show={this.state.modalMessage}
                                            onHide={this.showOrHideModalMessage}
                                            backdrop="static"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered>

                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <Row >
                                                        <Col sm="12">
                                                            {this.state.messageTip == "Success" &&
                                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                            {this.state.messageTip == "Warning" &&
                                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                            {this.state.messageTip == "Error" &&
                                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                        </Col>
                                                    </Row>
                                                </Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.message}
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                            </Modal.Footer>
                                        </Modal>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                                    <Column title="S/N" dataIndex="index" />
                                    <Column title="Date" render={(value, row, index) => {

                                        return moment(row.date).format("DD/MM/YYYY");

                                    }} />
                                    <Column title="Department" render={(value, row, index) => {

                                        if (Boolean(row.department))
                                            return row.department.departmentName;

                                    }} />
                                    <Column title="Designation" render={(value, row, index) => {

                                        if (Boolean(row.designation))
                                            return row.designation.designationName;

                                    }} />

                                    <Column title="User" render={(value, row, index) => {

                                        if (Boolean(row.staff))
                                            return row.staff.fullName;

                                    }} />

                                    <Column title="Time In" render={(value, row, index) => {

                                        return moment(row.timeIn).format("DD/MM/YYYY HH:mm");

                                    }} />

                                    <Column title="Time Out" render={(value, row, index) => {

                                        return moment(row.timeOut).format("DD/MM/YYYY HH:mm");

                                    }} />

                                    <Column title="Working Hours" dataIndex="workingHours" />

                                    <Column title="Overtime" dataIndex="overtime" />

                                    <Column title="Action" render={(value, row, index) => {

                                        return this.actionTemplate(row);

                                    }} />
                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>


                        }
                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
                <div></div>
            );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetLog)
