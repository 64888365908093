/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, Radio } from 'antd';
import { Checkbox } from 'primereact/checkbox';

const { Column } = Table;
const { Option } = Select;
export class StatusMatrix extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,


            actionTypeSelectItems: [],
            actionTypeId: null,

            replyTypeSelectItems: [],

            statusSelectItems: [],
            sentStatusId: null,
            responseRequirement: false,
            StatusMatrixList: [],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.setComboboxes();
        this.restartTable();


    }

    save = async () => {
        const replyTypes = this.state.replyTypeSelectItems;
        var list = [];


        if (this.state.responseRequirement && Boolean(replyTypes) && replyTypes.findIndex(p => p.checked === true) > -1) {
            replyTypes.forEach(element => {
                if (element.checked) {
                    let obj = {
                        actionTypeId: this.state.actionTypeId,
                        sentStatusId: this.state.sentStatusId,
                        responseRequirement: this.state.responseRequirement
                    }
                    obj.replyTypeId = element.id;
                    obj.receivedStatusId = element.receivedStatusId;
                    list.push(obj);
                }
            });
        }
        else {
            let obj = {
                actionTypeId: this.state.actionTypeId,
                sentStatusId: this.state.sentStatusId,
                responseRequirement: this.state.responseRequirement
            }

            list.push(obj);
        }


        await handleRequest("POST", "/StatusMatrix/saveAll", list);


        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        console.log(row);
        var list = this.state.StatusMatrixList.filter(p => p.actionTypeId === row.actionTypeId);
        var replyTypes = this.state.replyTypeSelectItems;
        console.log(list);
        console.log(replyTypes);
        if (Boolean(list) && list.length > 0) {

            list.forEach(element => {
            

                let obj = replyTypes.find(p => p.id === element.replyTypeId);
                if (Boolean(obj)) {
                    obj.checked = true;
                    obj.receivedStatusId = element.receivedStatusId;
                }
            });

            this.setState({
                actionTypeId: list[0].actionTypeId,
                sentStatusId: list[0].sentStatusId,
                responseRequirement: list[0].responseRequirement,
                // replyTypeSelectItems: replyTypes,
                hideSave: true,
                hideUpdate: false,
                hideInputs: false,

            });



        }



    }


    delete = async () => {
        const obj = {
            id: this.state.statusMatrixId
        }

        await handleRequest("POST", "/StatusMatrix/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusMatrixId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/StatusMatrix/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                StatusMatrixList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                StatusMatrixList: [],
            });
        }
    }


    setComboboxes = async () => {
        const resActionList = await handleRequest("GET", "/ActionType/getAllByCompany");
        if (resActionList.data.length > 0) {
            this.setState({
                actionTypeSelectItems: resActionList.data
            });
        } else {
            this.setState({
                actionTypeSelectItems: []
            });
        }

        const resStatusList = await handleRequest("GET", "/Status/getAllByCompany");
        if (resStatusList.data.length > 0) {
            this.setState({
                statusSelectItems: resStatusList.data
            });
        } else {
            this.setState({
                statusSelectItems: []
            });
        }

        const resReplyList = await handleRequest("GET", "/ReplyType/getAllByCompany");
        if (resReplyList.data.length > 0) {
            const list = resReplyList.data;
            list.forEach(element => {
                element.receivedStatusId = null;
                element.checked = false;
            });
            this.setState({

                replyTypeSelectItems: list
            });
        } else {
            this.setState({
                replyTypeSelectItems: []
            });
        }



    }
    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "StatusMatrix" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "StatusMatrix"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "StatusMatrix");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                console.log(this.props.location.pathname.split("/"));
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            StatusMatrix: '',
            statusMatrixId: 0,
            departmentId: 0,
            disciplineId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            StatusMatrix: '',
            statusMatrixId: 0,
            departmentId: 0,
            disciplineId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            StatusMatrix: '',
            statusMatrixId: 0,
            departmentId: 0,
            disciplineId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        return  <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen Status Matrix will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Action Type" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.actionTypeId} onChange={(value) => {
                                                    this.setState({
                                                        actionTypeId: value
                                                    })
                                                }}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.actionTypeSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.type}</Option>
                                                    ))}
                                                </Select>


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Response Requirement" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select response requirement" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Radio.Group onChange={(e) => {
                                                    this.setState({
                                                        responseRequirement: e.target.value
                                                    })
                                                }} value={this.state.responseRequirement}>
                                                    <Radio value={true}>Yes</Radio>
                                                    <Radio value={false}>No</Radio>
                                                </Radio.Group>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }



                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Status (sent)" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.sentStatusId} onChange={(value) => {
                                                    this.setState({
                                                        sentStatusId: value
                                                    })
                                                }}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.statusSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.status}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.responseRequirement &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Status (received)" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >

                                        {this.state.replyTypeSelectItems.map(item => (

                                            <Form.Group key={item.productId} style={{ marginBottom: '1rem' }} as={Row} >


                                                <Col sm="3">
                                                    <div>
                                                        <Checkbox onChange={(e) => {
                                                            this.setState(prevState => ({
                                                                replyTypeSelectItems: prevState.replyTypeSelectItems.map(
                                                                    el => el.id === item.id ? { ...el, checked: e.checked } : el
                                                                )
                                                            }))
                                                        }} checked={item.checked}></Checkbox>
                                                        <label htmlFor="cb2" className="p-checkbox-label">{item.type}</label>
                                                    </div>
                                                </Col>
                                                <Col sm="3">
                                                    <div hidden={!item.checked} >
                                                        <Select placeholder="" value={item.receivedStatusId} onChange={(value) => {
                                                            this.setState(prevState => ({
                                                                replyTypeSelectItems: prevState.replyTypeSelectItems.map(
                                                                    el => el.id === item.id ? { ...el, receivedStatusId: value } : el
                                                                )
                                                            }))
                                                        }}
                                                        >
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.statusSelectItems.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.status}</Option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                </Col>


                                                <Col sm="6">

                                                </Col>



                                            </Form.Group>
                                        ))}



                                    </FormAnt.Item>
                                }



                                <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.StatusMatrixList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.StatusMatrixList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Action Type" render={(value, row, index) => {

                                if (Boolean(row.actionType))
                                    return row.actionType.type;

                            }} />
                            <Column title="Response Requirement" render={(value, row, index) => {

                                if (Boolean(row.responseRequirement))
                                    return "Yes";
                                else return "No";

                            }} />



                            <Column title="Status (Sent)" render={(value, row, index) => {

                                if (Boolean(row.sentStatus))
                                    return row.sentStatus.status;

                            }} />

                            <Column title="Reply Type" render={(value, row, index) => {

                                if (Boolean(row.replyType))
                                    return row.replyType.type;

                            }} />

                            <Column title="Status (Received)" render={(value, row, index) => {

                                if (Boolean(row.receivedStatus))
                                    return row.receivedStatus.status;

                            }} />



                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusMatrix)