import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { Modal, Button as ReactButton, Col, Form as FormBoot, Row } from 'react-bootstrap';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import { Card, Form, Table, Input, Checkbox, Select, Divider, Button as AntButton, InputNumber } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { Redirect } from "react-router-dom";

const { Column } = Table;
const { Option } = Select;

var _itemUnitRows = [
    {
        itemUnitIndex: 1,
        name: "",
        abbreviation: "",
        coefficent: "",
        subSuperscript: false,
        scriptType: "",
        script: ""
    }
];

export class Units extends Component {
    constructor() {
        super();
        this.state = {
            modalMessage: false,
            modalScript: false,
            deleteModal: false,
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            selectedUnit: null,
            unitList: [],
            unitTypes: [],
            unitTypesForConverter: [],

            unitsQuantity1: [],
            unitsQuantity2: [],
            value1: 0,
            value2: 0,
            value1Suffix: "",
            value2Suffix: "",
            quantity1: null,
            quantity2: null,

            unitType: null,
            unitTypeId: null,
            unitScriptType: "",
            unitScript: "",
            itemUnitRows: [],
            itemUnitRowsBackup: [],
            lastCheckedIndex: null,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.dynamicInputs = this.dynamicInputs.bind(this);
    };

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Units" });

        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Units",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Units");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {
        }

        this.setState({
            itemUnitRows: _itemUnitRows
        });

        this.restartTable();
    }

    getCodesUnitType = async () => {
        const response = await handleRequest("GET", "/Code/type/unitTypeForUnits");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                unitTypesForConverter: response.data
            });
        } else {
            this.setState({
                unitTypesForConverter: []
            });
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    restartTable = async () => {
        this.getCodesUnitType();

        let flagUnitTypes = [];
        let enteredParentTypes = [];

        const response = await handleRequest("GET", "/UnitType/getallByCompany");
        console.log(response.data);
        const response2 = await handleRequest("GET", "/Code/type/unitTypeForUnits");
        console.log(response2.data);

        if (response.data.length === 0) {
            this.setState({
                unitList: [],
            });

            if (response2.data !== null && response2.data !== undefined) {
                this.setState({
                    unitTypes: response2.data
                });
            } else {
                this.setState({
                    unitTypes: []
                });
            }
        }

        if (response.data.length !== 0) {
            let flagData = response.data;

            for (let index = 0; index < flagData.length; index++) {
                flagUnitTypes.push({
                    id: response.data[index].id,
                    key: response.data[index].id,
                    parentUnitId: response.data[index].parentUnitId,
                    codeParentUnit: response.data[index].codeParentUnit,
                    unitTypes: response.data[index].unitTypes,
                    requestStatus: response.data[index].requestStatus
                });

                enteredParentTypes.push(response.data[index].parentUnitId);
            }

            if (response2.data !== null && response2.data !== undefined) {
                let flagResponseData = response2.data;
                let reducedData = [];

                for (let index = 0; index < flagResponseData.length; index++) {
                    if (!(enteredParentTypes.includes(flagResponseData[index].id)))
                        reducedData.push(flagResponseData[index]);
                }

                this.setState({
                    unitTypes: reducedData
                });
            } else {
                this.setState({
                    unitTypes: []
                });
            }

            this.setState({
                unitList: flagUnitTypes,
            });
        }

        this.setState({
            unitId: null
        });
    }

    deleteModal = (row) => {
        this.setState({
            unitId: row.id,
            deleteModal: true
        });
    }

    deleteUnitOK = async () => {
        if (this.state.unitId !== null) {
            const selectedUnit = {
                id: this.state.unitId
            }
            var response = await handleRequest("POST", "/UnitType/delete", selectedUnit);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        deleteModal: false,
                    });
                    this.restartTable();
                    this.showSuccess("Data has been deleted successfully.");
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }

            setTimeout(() => {
                document.body.style.overflow = "auto";
            }, 500);
        }
    }

    resetInputs = () => {
        this.formRef.current.resetFields();

        _itemUnitRows = [
            {
                itemUnitIndex: 1,
                name: "",
                abbreviation: "",
                coefficent: "",
                subSuperscript: false,
                scriptType: "",
                script: ""
            }
        ];

        this.setState({
            hidden: true,
            unitType: null,
            unitTypeId: null,
            unitScriptType: "",
            unitScript: "",
            itemUnitRows: _itemUnitRows,
            selectedUnit: null,
            lastCheckedIndex: null,
        });
    }

    changeButtonCancel = () => {
        this.formRef.current.resetFields();

        _itemUnitRows = [
            {
                itemUnitIndex: 1,
                name: "",
                abbreviation: "",
                coefficent: "",
                subSuperscript: false,
                scriptType: "",
                script: ""
            }
        ];

        this.setState({
            unitType: null,
            unitTypeId: null,
            unitScriptType: "",
            unitScript: "",
            itemUnitRows: _itemUnitRows,
            lastCheckedIndex: null,
            selectedUnit: null,
            hiddenSave: false,
            hiddenUpdate: true,
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    scriptOK = () => {
        if (this.state.lastCheckedIndex !== null) {
            let list = this.state.itemUnitRows;
            list[this.state.lastCheckedIndex].scriptType = this.state.unitScriptType;
            list[this.state.lastCheckedIndex].script = this.state.unitScript;

            this.setState({
                itemUnitRows: list.slice()
            });
        }

        this.setState({
            modalScript: false,
            lastCheckedIndex: null,
            unitScript: "",
            unitScriptType: ""
        });
    }

    showOrHideModalScript = () => {
        this.setState({
            modalScript: !this.state.modalScript
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    addNewUnitType = async () => {
        const { newUnitType } = this.state;
        if (newUnitType != null && newUnitType != "" && newUnitType != undefined) {
            const newItem = {
                name: newUnitType,
                type: "unitTypeForUnits"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                newUnitType: "",
            });
            this.restartTable();
        } else {
            this.showWarning();
        }
    }

    handleChangeUnitType = (e) => {
        var value = "";

        for (var z = 0; z < this.state.unitTypes.length; z++) {
            if (this.state.unitTypes[z].id == e) {
                value = this.state.unitTypes[z].name;
            }
        }

        this.setState({
            unitType: value,
            unitTypeId: e,
        });
    }

    addNewUnit = () => {
        _itemUnitRows.push(
            {
                itemUnitIndex: _itemUnitRows.length + 1,
                name: "",
                abbreviation: "",
                coefficent: "",
                subSuperscript: false,
                scriptType: "",
                script: ""
            }
        );

        this.setState({
            itemUnitRows: _itemUnitRows
        });
    }

    removeUnit = (index, e) => {
        if (_itemUnitRows.length !== 1) {
            _itemUnitRows.splice(index, 1);
        }

        this.setState({
            itemUnitRows: _itemUnitRows
        });
    }

    handleChangeItemUnitName = (index, e) => {
        let list = this.state.itemUnitRows;
        list[index].name = e.target.value;

        this.setState({
            itemUnitRows: list.slice()
        });
    }

    handleChangeItemUnitAbbreviation = (index, e) => {
        let list = this.state.itemUnitRows;
        list[index].abbreviation = e.target.value;

        this.setState({
            itemUnitRows: list.slice()
        });
    }

    handleChangeItemUnitCoefficent = (index, value) => {
        let list = this.state.itemUnitRows;
        list[index].coefficent = value;

        this.setState({
            itemUnitRows: list.slice()
        });
    }

    handleChangeItemUnitChekbox = (index, e) => {
        let list = this.state.itemUnitRows;
        list[index].subSuperscript = e.target.checked;

        if (e.target.checked) {
            this.setState({
                lastCheckedIndex: index,
                modalScript: true
            });
        }

        this.setState({
            itemUnitRows: list.slice()
        });
    }

    createNew = async () => {
        if (this.state.hidden) {
            this.formRef.current.resetFields();

            _itemUnitRows = [
                {
                    itemUnitIndex: 1,
                    name: "",
                    abbreviation: "",
                    coefficent: "",
                    subSuperscript: false,
                    scriptType: "",
                    script: ""
                }
            ];

            this.setState({
                hidden: false,
                unitType: null,
                unitTypeId: null,
                unitScriptType: "",
                unitScript: "",
                itemUnitRows: _itemUnitRows,
                lastCheckedIndex: null,
                selectedUnit: null,
            });
        } else {
            this.resetInputs();
        }
    }

    stopProcess = () => {
        this.resetInputs();
    }

    saveUnit = async () => {
        if (this.state.unitTypeId !== null && this.state.unitType !== "" && this.state.itemUnitRows.length > 0) {

            let flagUnitTypes = [];

            for (let index = 0; index < this.state.itemUnitRows.length; index++) {
                flagUnitTypes.push({
                    name: this.state.itemUnitRows[index].name,
                    abbreviation: this.state.itemUnitRows[index].abbreviation,
                    coefficent: this.state.itemUnitRows[index].coefficent,
                    subSuperscript: this.state.itemUnitRows[index].subSuperscript,
                    scriptType: this.state.itemUnitRows[index].scriptType,
                    script: this.state.itemUnitRows[index].script
                });
            }

            const newUnit = {
                parentUnitId: this.state.unitTypeId,
                unitTypes: flagUnitTypes,
                requestStatus: "Changes have been received."
            }

            var response = await handleRequest("POST", "/UnitType/add", newUnit);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess("All data has been saved successfully.");
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    updateUnit = async () => {
        if (this.state.unitTypeId !== null && this.state.unitType !== "" && this.state.itemUnitRows.length > 0) {

            let flagUnitTypes = [];

            for (let index = 0; index < this.state.itemUnitRows.length; index++) {
                if (this.state.itemUnitRows[index].id !== undefined) {
                    if (this.state.itemUnitRows[index].id !== null) {
                        flagUnitTypes.push({
                            id: this.state.itemUnitRows[index].id,
                            active: true,
                            name: this.state.itemUnitRows[index].name,
                            abbreviation: this.state.itemUnitRows[index].abbreviation,
                            coefficent: this.state.itemUnitRows[index].coefficent,
                            subSuperscript: this.state.itemUnitRows[index].subSuperscript,
                            scriptType: this.state.itemUnitRows[index].scriptType,
                            script: this.state.itemUnitRows[index].script
                        });
                    }
                } else {
                    flagUnitTypes.push({
                        name: this.state.itemUnitRows[index].name,
                        abbreviation: this.state.itemUnitRows[index].abbreviation,
                        coefficent: this.state.itemUnitRows[index].coefficent,
                        subSuperscript: this.state.itemUnitRows[index].subSuperscript,
                        scriptType: this.state.itemUnitRows[index].scriptType,
                        script: this.state.itemUnitRows[index].script
                    });
                }
            }

            for (let index = 0; index < this.state.itemUnitRowsBackup.length; index++) {
                let whichIndexItemUnit = this.state.itemUnitRows.findIndex(i => Boolean(i.id) && i.id === this.state.itemUnitRowsBackup[index].id);

                if (whichIndexItemUnit === -1) {
                    flagUnitTypes.push({
                        id: this.state.itemUnitRowsBackup[index].id,
                        active: false,
                        name: this.state.itemUnitRowsBackup[index].name,
                        abbreviation: this.state.itemUnitRowsBackup[index].abbreviation,
                        coefficent: this.state.itemUnitRowsBackup[index].coefficent,
                        subSuperscript: this.state.itemUnitRowsBackup[index].subSuperscript,
                        scriptType: this.state.itemUnitRowsBackup[index].scriptType,
                        script: this.state.itemUnitRowsBackup[index].script
                    });
                }
            }

            const updatedUnit = {
                id: this.state.unitId,
                parentUnitId: this.state.unitTypeId,
                unitTypes: flagUnitTypes,
                requestStatus: "Changes have been received."
            }

            console.log(updatedUnit);

            var response = await handleRequest("POST", "/UnitType/update", updatedUnit);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess("All data has been saved successfully.");
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    changeButtonUpdate = (row) => {
        this.formRef.current.setFieldsValue({
            UnitsParentUnitType: row.codeParentUnit.name
        });

        let flagUnitTypes = [];

        for (let index = 0; index < row.unitTypes.length; index++) {
            flagUnitTypes.push({
                id: row.unitTypes[index].id,
                active: row.unitTypes[index].active,
                itemUnitIndex: index + 1,
                name: row.unitTypes[index].name,
                abbreviation: row.unitTypes[index].abbreviation,
                coefficent: row.unitTypes[index].coefficent,
                subSuperscript: row.unitTypes[index].subSuperscript,
                scriptType: row.unitTypes[index].scriptType,
                script: row.unitTypes[index].script,
            });
        }

        if (flagUnitTypes.length === 0) {
            flagUnitTypes.push({
                itemUnitIndex: 1,
                name: "",
                abbreviation: "",
                coefficent: "",
                subSuperscript: false,
                scriptType: "",
                script: ""
            });
        }

        _itemUnitRows = flagUnitTypes;

        this.setState({
            selectedUnit: row,
            unitId: row.id,
            unitType: row.codeParentUnit.name,
            unitTypeId: row.codeParentUnit.id,
            itemUnitRows: _itemUnitRows,
            itemUnitRowsBackup: row.unitTypes,
            hiddenSave: true,
            hiddenUpdate: false,
            hidden: false
        });
    }

    onUnitTypeConverterChange = (value) => {
        let unitIndex = this.state.unitList.findIndex(p => p.parentUnitId === value);

        if (this.state.unitList[unitIndex] !== undefined) {
            this.setState({
                unitsQuantity1: this.state.unitList[unitIndex].unitTypes,
                unitsQuantity2: this.state.unitList[unitIndex].unitTypes
            });
        } else {
            this.setState({
                unitsQuantity1: [],
                unitsQuantity2: []
            });
        }

        this.setState({
            unitTypeConverterId: value,
            quantity1: null,
            quantity2: null,
            value1: "",
            value2: "",
            value1Suffix: "",
            value2Suffix: ""
        });
    }

    onChangeQuantity1 = (value) => {
        let unitIndex = this.state.unitsQuantity1.findIndex(p => p.id === value);

        if (unitIndex !== undefined && unitIndex !== null) {
            if (this.state.unitsQuantity1[unitIndex].subSuperscript) {
                if (this.state.unitsQuantity1[unitIndex].scriptType === "superscript")
                    this.setState({
                        value1Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity1[unitIndex].abbreviation}<sup>{this.state.unitsQuantity1[unitIndex].script}</sup>
                        </p>,
                    });
                else
                    this.setState({
                        value1Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity1[unitIndex].abbreviation}<sub>{this.state.unitsQuantity1[unitIndex].script}</sub>
                        </p>,
                    });
            } else
                this.setState({
                    value1Suffix: <p style={{ marginBottom: "0px" }}>{this.state.unitsQuantity1[unitIndex].abbreviation}</p>,
                });
        }

        this.setState({
            quantity1: value
        },
            () => this.calculateConvert(2)
        );
    }

    onChangeQuantity2 = (value) => {
        let unitIndex = this.state.unitsQuantity2.findIndex(p => p.id === value);

        if (unitIndex !== undefined && unitIndex !== null) {
            if (this.state.unitsQuantity2[unitIndex].subSuperscript) {
                if (this.state.unitsQuantity2[unitIndex].scriptType === "superscript")
                    this.setState({
                        value2Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity2[unitIndex].abbreviation}<sup>{this.state.unitsQuantity2[unitIndex].script}</sup>
                        </p>,
                    });
                else
                    this.setState({
                        value2Suffix: <p style={{ marginBottom: "0px" }}>
                            {this.state.unitsQuantity2[unitIndex].abbreviation}<sub>{this.state.unitsQuantity2[unitIndex].script}</sub>
                        </p>,
                    });
            } else
                this.setState({
                    value2Suffix: <p style={{ marginBottom: "0px" }}>{this.state.unitsQuantity2[unitIndex].abbreviation}</p>,
                });
        }

        this.setState({
            quantity2: value
        },
            () => this.calculateConvert(1)
        );
    }

    handleChangeValue1 = (e) => {
        this.setState({
            value1: e.target.value
        },
            () => this.calculateConvert(1)
        );
    }

    handleChangeValue2 = (e) => {
        this.setState({
            value2: e.target.value
        },
            () => this.calculateConvert(2)
        );
    }

    calculateConvert = (changedValue) => {
        let calculatedValue1 = 0;
        let calculatedValue2 = 0;

        let unitIndex1 = this.state.unitsQuantity1.findIndex(p => p.id === this.state.quantity1);
        let unitIndex2 = this.state.unitsQuantity2.findIndex(p => p.id === this.state.quantity2);

        if (changedValue === 1 && this.state.quantity1 !== null && this.state.quantity2 !== null &&
            unitIndex1 !== undefined && unitIndex1 !== null && unitIndex1 !== -1 &&
            unitIndex2 !== undefined && unitIndex2 !== null && unitIndex2 !== -1) {

            calculatedValue2 = this.state.value1 * (Number(this.state.unitsQuantity2[unitIndex2].coefficent) / Number(this.state.unitsQuantity1[unitIndex1].coefficent));

            this.setState({
                value2: (Math.round(calculatedValue2 * 100) / 100)
            });
        } else if (changedValue === 2 && this.state.quantity1 !== null && this.state.quantity2 !== null &&
            unitIndex1 !== undefined && unitIndex1 !== null && unitIndex1 !== -1 &&
            unitIndex2 !== undefined && unitIndex2 !== null && unitIndex2 !== -1) {

            calculatedValue1 = this.state.value2 * (Number(this.state.unitsQuantity1[unitIndex1].coefficent) / Number(this.state.unitsQuantity2[unitIndex2].coefficent));

            this.setState({
                value1: (Math.round(calculatedValue1 * 100) / 100)
            });
        } else {
            this.setState({
                value1: 0,
                value2: 0
            });
        }
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        var itemUnitTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "UnitsUnitType");
        var itemUnitAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "UnitsUnit");

        var itemUnitTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "UnitsUnitType");
        var itemUnitRequired = this.state.dynamicInputs.find(p => p.inputKey === "UnitsUnit");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 16 },
        };

        return <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteUnitOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalScript}
                        onHide={this.showOrHideModalScript}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="UnitsScriptModal" defaultMessage="Please enter values of unit's superscript/subscript" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row style={{ marginTop: "1rem", width: "100%" }}>
                                <Col sm="4">
                                    <FormBoot.Label style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                        <FormattedMessage id="UnitsScriptModalUnitScriptType" defaultMessage="Unit Script Type" />
                                    </FormBoot.Label>
                                </Col>
                                <Col sm="4">
                                    <Select style={{ width: "100%" }} value={this.state.unitScriptType} onChange={(value) => this.setState({ unitScriptType: value })} >
                                        <Option value="subscript"><FormattedMessage id="UnitsScriptModalSubscript" defaultMessage="subscript" /></Option>
                                        <Option value="superscript"><FormattedMessage id="UnitsScriptModalSuperscript" defaultMessage="superscript" /></Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem", width: "100%" }}>
                                <Col sm="4">
                                    <FormBoot.Label style={{ marginTop: "0.5rem", textAlign: "right" }}>
                                        <FormattedMessage id="UnitsScriptModalUnitScript" defaultMessage="Unit Script" />
                                    </FormBoot.Label>
                                </Col>
                                <Col sm="4">
                                    <Input id="UnitsScriptModalUnitScript" value={this.state.unitScript} onChange={(e) => this.setState({ unitScript: e.target.value })} />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalScript}>Close</ReactButton>
                            <ReactButton variant="primary" onClick={this.scriptOK}>Save</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <div>
                        <Row>
                            <Col sm="1">
                                {this.state.isAdmin ?
                                    <IconButton color="primary" onClick={this.showMNDSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                    :
                                    <IconButton color="primary" onClick={this.showSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                }
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                    <HelpIcon />
                                </IconButton>
                                }
                            </Col>
                        </Row>
                    </div>

                    <div style={{ padding: "4px 16px 4px 16px" }}>
                        <Row>
                            <Col xs={3}></Col>
                            <Col xs={3}></Col>
                            <Col xs={3}></Col>
                            <Col xs={3}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <div>
                                        {this.state.hidden &&
                                            <ReactButton id="UnitCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                        }
                                        {!this.state.hidden &&
                                            <ReactButton id="UnitStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                        }
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>

                    <Card style={{ width: '800px', marginBottom: '1rem' }}>
                        <FormBoot.Group as={Row} style={{ marginBottom: '1rem' }}>
                            <Col sm="12">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Measurement"
                                    value={this.state.unitTypeConverterId}
                                    onChange={this.onUnitTypeConverterChange}
                                >
                                    {this.state.unitTypesForConverter.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </FormBoot.Group>

                        <FormBoot.Group as={Row} style={{ marginBottom: '1rem' }}>
                            <Col sm="6">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Quantity"
                                    value={this.state.quantity1}
                                    onChange={this.onChangeQuantity1}
                                >
                                    {this.state.unitsQuantity1.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col sm="6">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Quantity"
                                    value={this.state.quantity2}
                                    onChange={this.onChangeQuantity2}
                                >
                                    {this.state.unitsQuantity2.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </FormBoot.Group>

                        <FormBoot.Group as={Row} style={{ marginBottom: '1rem' }}>
                            <Col sm="6">
                                <Input suffix={this.state.value1Suffix} style={{ width: "100%" }} value={this.state.value1} onChange={this.handleChangeValue1} />
                            </Col>
                            <Col sm="6">
                                <Input suffix={this.state.value2Suffix} style={{ width: "100%" }} value={this.state.value2} onChange={this.handleChangeValue2} />
                            </Col>
                        </FormBoot.Group>
                    </Card>

                    <Card>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>

                            {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Form
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.state.hiddenUpdate == false ? this.updateUnit : this.saveUnit}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {(itemUnitTypeAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="UnitsParentUnitType" defaultMessage="Parent Unit" />}
                                        name="UnitsParentUnitType"
                                        rules={[{ required: itemUnitTypeRequired.canBeRequired, message: <FormattedMessage id={itemUnitTypeRequired.translateRequired} defaultMessage="Please input parent unit type!" /> }]} >
                                        <Select
                                            name="unitTypeId"
                                            style={{ width: '300px' }}
                                            value={this.state.unitType}
                                            onChange={this.handleChangeUnitType}
                                            dropdownRender={menu => (
                                                <div>
                                                    {menu}
                                                    <Divider style={{ margin: '4px 0' }} />
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Input style={{ flex: 'auto' }} value={this.state.newUnitType}
                                                            onChange={(event) => { this.setState({ newUnitType: event.target.value }) }} />
                                                        <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                            onClick={this.addNewUnitType} >
                                                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Unit Type" />  </a>
                                                    </div>
                                                </div>
                                            )}>
                                            {this.state.unitTypes.map(i => (
                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}

                                {this.state.itemUnitRows.map((item, index) => (
                                    (itemUnitAttributes.visible) &&
                                    (<Form.Item
                                        label={<FormattedMessage id="UnitsUnitType" defaultMessage="Unit" />}
                                        required rules={[{ required: false, message: <FormattedMessage id={itemUnitRequired.translateRequired} defaultMessage="Please input unit type!" /> }]} >
                                        <FormBoot.Group key={index} as={Row} style={{ marginBottom: '0rem' }}>
                                            <Col sm="2">
                                                <Input value={item.name} style={{ width: "100%" }} onChange={(e) => this.handleChangeItemUnitName(index, e)} />
                                            </Col>
                                            <Col sm="2" style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '14px', textAlign: 'right', marginTop: '0.25rem' }}>
                                                <FormattedMessage id="UnitsAbbreviation" defaultMessage="Abbreviation:" />
                                            </Col>
                                            <Col sm="1">
                                                <Input value={item.abbreviation} style={{ width: "100%" }} onChange={(e) => this.handleChangeItemUnitAbbreviation(index, e)} />
                                            </Col>
                                            <Col sm="2" style={{ marginLeft: '14px', marginTop: '0.25rem' }}>
                                                <Checkbox style={{ color: 'rgba(0, 0, 0, 0.85)' }} onChange={(e) => this.handleChangeItemUnitChekbox(index, e)} checked={item.subSuperscript}>
                                                    <FormattedMessage id="UnitsUnitScriptCheck" defaultMessage="Script" />
                                                </Checkbox>
                                            </Col>
                                            <Col sm="2" style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', fontSize: '14px', textAlign: 'right', marginTop: '0.25rem' }}>
                                                <FormattedMessage id="UnitsCoefficent" defaultMessage="Coefficent:" />
                                            </Col>
                                            <Col sm="1">
                                                <InputNumber value={item.coefficent} style={{ width: "100%" }} onChange={(value) => this.handleChangeItemUnitCoefficent(index, value)} />
                                            </Col>
                                            <Col sm="1">
                                                {(index === 0) && (<AntButton type="primary" onClick={this.addNewUnit} icon={<PlusOutlined />}></AntButton>)}
                                                {(index !== 0) && (<AntButton type="primary" danger onClick={(e) => this.removeUnit(index, e)} icon={<CloseOutlined />}></AntButton>)}
                                            </Col>
                                        </FormBoot.Group>
                                    </Form.Item>)
                                ))}

                                <div hidden={this.state.hiddenSave} >
                                    {
                                        this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="4">
                                                <ReactButton id="UnitSaveButton"
                                                    style={{ width: '100%' }} type="submit" variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3"></Col>
                                            <Col sm="2">
                                                <ReactButton id="UnitCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="2">
                                                <ReactButton id="UnitUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                            </Form>}

                        </div>

                        <Table size={`small`} dataSource={this.state.unitList} bordered
                            expandable={{
                                expandedRowRender: record =>
                                    <Table dataSource={record.unitTypes} style={{ marginTop: '10px', marginBottom: '10px' }} pagination={false}>
                                        <Column title="Unit Name" key="name" dataIndex="name" />
                                        <Column title="Abbreviation" key="abbreviation" dataIndex="abbreviation" />
                                        <Column title="Unit" key="unit" render={(text, record) => {
                                            if (record.subSuperscript) {
                                                if (record.scriptType === "subscript") {
                                                    return <p style={{ marginBottom: "0px" }}>{record.abbreviation}<sub>{record.script}</sub></p>
                                                } else if (record.scriptType === "superscript") {
                                                    return <p style={{ marginBottom: "0px" }}>{record.abbreviation}<sup>{record.script}</sup></p>
                                                }
                                            } else
                                                return <p style={{ marginBottom: "0px" }}>{record.abbreviation}</p>
                                        }} />
                                        <Column title="Coefficent" key="coefficent" dataIndex="coefficent" />
                                    </Table>
                            }}
                        >
                            <Column title="Parent Unit" key="parentUnit" render={(text, record) => {
                                return record.codeParentUnit.name;
                            }} />
                            <Column title="Request Status" key="requestStatus" render={(text, record) => {
                                if (record.requestStatus === "Changes have been received.") {
                                    return (
                                        <p style={{ marginBottom: "0px", textAlign: "center", color: "Orange" }}><FormattedMessage id="UnitTypeTableMessage" defaultMessage="Changes have been received." /></p>
                                    );
                                } else {
                                    return (
                                        <p style={{ marginBottom: "0px", textAlign: "center", color: "MediumSeaGreen" }}><FormattedMessage id="UnitTypeTableMessage2" defaultMessage="Changes were added." /></p>
                                    );
                                }
                            }} />
                            <Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => {

                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                        if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                            (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                            return (<div style={{ textAlign: "center" }}>
                                                <React.Fragment key={record.id} >
                                                    <CustomMaterialMenuDeleteEdit row={record}
                                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
                                                </React.Fragment>
                                            </div>);
                                        }
                                        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuEdit row={record}
                                                    onEditRow={this.changeButtonUpdate.bind(this)} />
                                            </React.Fragment>);
                                        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuDelete row={record}
                                                    onDeleteRow={this.deleteModal.bind(this)} />
                                            </React.Fragment>);
                                        }
                                    }
                                    else {
                                        return (<div style={{ textAlign: "center" }}>
                                        </div>);
                                    }
                                }}
                            />
                        </Table>

                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Units)