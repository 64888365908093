/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { handleRequest } from '../../redux/config/env';
import { Modal , Button as BootButton, Col, Form, Row} from 'react-bootstrap';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { FormattedMessage } from "react-intl";
import { Checkbox as AntCheckbox } from "antd";
import { Toast } from 'primereact/toast';

export class RoleGroupTemplate extends Component {
    constructor() {
        super();
        this.state = {
            modalShow: false, 
            hiddenUpdate: true,
            hiddenSave:false,
            displayBasic: false,
            position: 'center',
            roleNameText: "",
            selectedRole: null,
            buttonName: "Add", 
            roleGroupTemplates: []
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: <FormattedMessage id="GeneralSuccesMessageHeader" />, detail: <FormattedMessage id="GeneralMessageSuccess1" /> });
    };

    showError(msg) { 
        if (!msg) {
           msg=<FormattedMessage id="GeneralMessageCheckInputs" />;
        }
        this.toast.show({ severity: 'error', summary: <FormattedMessage id="GeneralErrorMessageHeader" />, detail: msg });
    };

     showWarning(msg) { 
        if (!msg) {
            msg=<FormattedMessage id="GeneralMessageCheckInputs" />;
        }
        this.toast.show({ severity: 'warn', summary: <FormattedMessage id="GeneralWarningMessageHeader" />, detail: msg });
    };

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true,
            selectedRole: rowData
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    actionTemplate(rowData, column) {
        return  <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />   </React.Fragment> ;
    };

    renderZorunlu(rowData, column) {
       if(rowData.zorunlu){
           return <FormattedMessage id="GeneralTextYes" />;
       }else{
            return <FormattedMessage id="GeneralTextNo" />;
       }
    };

    deleteModal = (row) => {
        
        this.setState({
            modalShow: true,
            selectedDiscipline: row
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }


    componentDidMount = async () => {

        const response = await handleRequest("GET", "/RoleGroupTemplate/getAll");

        console.log("All", response.data);

        if (response.data.length === 0){
            this.setState({
                roleGroupTemplates: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                roleGroupTemplates: response.data,
            });
        }
    };

    addRoleGroupTemplate = async () => {
        if (this.state.roleNameText !== null && this.state.roleNameText !== undefined && this.state.roleNameText !== ""){

            const newRoleGroupTemplate = {
                roleName : this.state.roleNameText,
                zorunlu : this.state.zorunlu
            }

            var response = await handleRequest("POST", "/RoleGroupTemplate/add", newRoleGroupTemplate);
    
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS")
               {
                this.changeButtonSave();
                this.restartTable();
                this.showSuccess();
               } 
               else  if(response.data==="ERROR1") {
                this.showError(<FormattedMessage id="GeneralMessageError1" />); 
               }  else  if(response.data==="ERROR2") {
                   this.showWarning(<FormattedMessage id="RoleGroupMessageWarning" />);   
               }
            } 
        } 
        else{
            this.showError();
        }
    };

    updateRoleGroupTemplate = async () => {
        if (this.state.roleNameText !== null && this.state.roleNameText !== undefined && this.state.roleNameText !== ""){
            const updatedRoleGroupTemplate = {
                roleName : this.state.roleNameText,
                id: this.state.selectedRole.id,
                active: this.state.selectedRole.active,
                zorunlu : this.state.zorunlu
            } 

            var response = await handleRequest("POST", "/RoleGroupTemplate/update", updatedRoleGroupTemplate);
     
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS")
               {
                this.changeButtonSave();
                this.restartTable();
                this.showSuccess();
               } 
               else  if(response.data==="ERROR1") {
                this.showError(<FormattedMessage id="GeneralMessageError1" />); 
               }  else  if(response.data==="ERROR2") {
                   this.showWarning(<FormattedMessage id="RoleGroupMessageWarning" />);   
               }
            }else{
                this.showError();
            }
        } 
        else{
            this.showError();
        }
    };

    restartTable = async () => { 
        const response = await handleRequest("GET", "/RoleGroupTemplate/getAll");  
        if (response.data.length === 0){
            this.setState({
                roleGroupTemplates: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                roleGroupTemplates: response.data,
            });
        }
    }

    deleteRoleGroupTemplate = async (name) => { 
        const deletedRoleGroupTemplate = {
            id : this.state.selectedRole.id
        }  

        var response2 = await handleRequest("POST", "/RoleGroupTemplate/delete", deletedRoleGroupTemplate); 
     
        if(response2.data!==null && response2.data!==undefined){
            if(response2.data==="SUCCESS")
           {
            this.restartTable();
            this.showSuccess();
            this.onHideModal(name);
           } 
           else if(response2.data==="ERROR") {
            this.showError(<FormattedMessage id="GeneralMessageError1" />); 
            } 
        } else{
            this.showError();
        } 
    };

    delete = async (name) => {

        const deletedRoleGroupTemplate = {
            id : this.state.selectedRole.id
        } 
        var response2 =   await handleRequest("POST", "/RoleGroupTemplate/delete", deletedRoleGroupTemplate);  
     
        if(response2.data!==null && response2.data!==undefined){
            if(response2.data==="SUCCESS")
           {
            this.restartTable();
            this.showSuccess();
            this.showOrHideModal();
           } 
           else  if(response2.data==="ERROR") {
            this.showError(<FormattedMessage id="GeneralMessageError1" />); 
           } 
        } else{
            this.showError();
        } 
    };

    changeButtonUpdate = (row) => {
        this.setState({
            roleNameText: row.roleName,
            hiddenUpdate: false, 
            hiddenSave:true,
            zorunlu:row.zorunlu,
        });
    };

    changeButtonSave = () => {
        this.setState({
            roleNameText: "",
            hiddenUpdate: true, 
            hiddenSave:false,
            zorunlu:false,
        });
      
    };

    changeButtonCancel = () => {
        this.setState({
            roleNameText: "",
            hiddenUpdate: true, 
            hiddenSave:false,
            zorunlu:false,
        }); 
    };

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>
        return (
            <div>
            <Toast ref={(el) => this.toast = el}></Toast>

            <Card style={{ backgroundColor: "white", width: '700px' }}>
                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                            <FormattedMessage id="TemplateRG.Role.Group" />
                        </Form.Label>
                      <Col sm="6">
                        <InputText style={{ marginTop: '0.2rem', width: '100%' }} onChange={(e) => this.setState({ roleNameText: e.target.value })} value={this.state.roleNameText} />
                      </Col>
                      <Col sm="3">
                      <AntCheckbox  style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }}  checked={this.state.zorunlu} onChange={(e) => { 
                          if(e.target.checked){
                            this.setState({ 
                                zorunlu: e.target.checked,
                            });
                          }else{
                            this.setState({ 
                                zorunlu: e.target.checked,
                             })  
                          }
                          }}>
                          <FormattedMessage id="TemplateRGCompulsory"/></AntCheckbox>
                      </Col>
                </Form.Group>
                
                <div hidden={this.state.hiddenSave}>
                 <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="6">
                        <Button  style={{ marginTop: '0.2rem', width: '100%' }} id='save-rolegroup' className="p-button-success" onClick={this.addRoleGroupTemplate} label={<FormattedMessage id="GeneralButtonAdd" />} />
                    </Col> 
                      <Col sm="3">
                    </Col>
                </Form.Group>
                </div>
                <div hidden={this.state.hiddenUpdate}>
                 <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="3">
                        <Button  style={{ marginTop: '0.2rem', width: '100%' }} id='cancel-rolegroup' className="p-button-danger" onClick={this.changeButtonCancel} label={<FormattedMessage id="GeneralButtonCancel" />} />
                    </Col> 
                    <Col sm="3">
                        <Button  style={{ marginTop: '0.2rem', width: '100%' }} id='save-rolegroup' className="p-button-warning" onClick={this.updateRoleGroupTemplate} label={<FormattedMessage id="GeneralButtonUpdate" />} />
                    </Col> 
                      <Col sm="3">
                    </Col>
                </Form.Group>
                </div>

                <div className="content-section implementation">
                    <DataTable ref={(el) => this.dt = el} value={this.state.roleGroupTemplates} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-rolegroups"
                        selectionMode="single" selection={this.state.selectedRole} onSelectionChange={e => this.setState({ selectedRole: e.value })}>
                        <Column field="index" style={{ textAlign: 'center' }} sortable header={<FormattedMessage id="GeneralTableSN" />} />
                        <Column field="roleName" header={<FormattedMessage id="TemplateRG.Role.Group" />} filter sortable />
                        <Column body={this.renderZorunlu} header={<FormattedMessage id="TemplateRGCompulsory" />} filter sortable />
                        <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="Role Group Id" filter sortable />
                        <Column field="active" style={{ textAlign: 'center', display: 'none' }} header="Active" filter sortable />
                    </DataTable>
                </div>
                    
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="TemplateRG.Delete.Message1" />}</Modal.Title>
                    </Modal.Header> 
                    <Modal.Body>
                        <p>{<FormattedMessage id="TemplateRG.Delete.Message2" />}</p>  
                    </Modal.Body> 
                    <Modal.Footer>
                        <BootButton variant="secondary" onClick={this.showOrHideModal}>{<FormattedMessage id="GeneralButtonCancel" />}</BootButton>
                        <BootButton variant="danger" onClick={this.delete} >{<FormattedMessage id="GeneralButtonDelete" />}</BootButton>  
                    </Modal.Footer>
                </Modal>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleGroupTemplate)