/* eslint-disable */

import React from 'react'
import { FormattedMessage } from "react-intl";
import moment from 'moment';

export const TEMPLATE_Dynamia_ClientID=26; //2

export const columnsVehicleRegistration = [  
    {
        dataIndex: "index",
        key: "key",
        title: <FormattedMessage id="GeneralTableSN" defaultMessage="S/N" />,
    }, 
    {
        dataIndex: "plateNo",
        key: "key",
        title: <FormattedMessage id="VRPlateNo" defaultMessage="Plate No" />,
    },
    {
        dataIndex: "codeMake",
        key: "key",
        title: <FormattedMessage id="VRMake" defaultMessage="Make" />,
        render: (data) =>   
           {  
            if(data!=null && data!=undefined){
               return data.name;
            } 
            return "";
        }
    },
    {
        dataIndex: "codeModelYear",
        key: "key",
        title: <FormattedMessage id="VRModelYear" defaultMessage="Model Year" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "codeGrade",
        key: "key",
        title: <FormattedMessage id="VRGrade" defaultMessage="Grade" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "codeType",
        key: "key",
        title: <FormattedMessage id="VRType" defaultMessage="Type" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "codeColour",
        key: "key",
        title: <FormattedMessage id="VRColour" defaultMessage="Colour" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "engineNo",
        key: "key",
        title: <FormattedMessage id="VREngineNo" defaultMessage="Engine No" />,
    },  
    {
        dataIndex: "identificationNumber",
        key: "key",
        title: <FormattedMessage id="VRIdentificationNumber" defaultMessage="Identification Number" />,
    },
    {
        dataIndex: "cylinderVolume",
        key: "key",
        title: <FormattedMessage id="VRCylinderVolume" defaultMessage="Cylinder Volume" />,
    },
    {
        dataIndex: "enginePowerHP",
        key: "key",
        title: <FormattedMessage id="VREnginePowerHP" defaultMessage="Engine Power HP" />,
    },
    {
        dataIndex: "codeFuelType",
        key: "key",
        title: <FormattedMessage id="VRFuelType" defaultMessage="Fuel Type" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "vehicleClass",
        key: "key",
        title: <FormattedMessage id="VRVehicleClass" defaultMessage="Vehicle Class" />,
    },
    {
        dataIndex: "weight",
        key: "key",
        title: <FormattedMessage id="VRWeight" defaultMessage="Weight" />,
    },
    {
        dataIndex: "maxLoadCapacity",
        key: "key",
        title: <FormattedMessage id="VRMaxLoadCapacity" defaultMessage="Max.Load Capacity" />,
    },
    {
        dataIndex: "maxGrossWeight",
        key: "key",
        title: <FormattedMessage id="VRMaxGrossWeight" defaultMessage="Max.Gross Weight" />,
    },
    {
        dataIndex: "kerbWeight",
        key: "key",
        title: <FormattedMessage id="VRKerbWeight" defaultMessage="Kerb Weight" />,
    },
    {
        dataIndex: "trailerMaxLoadedWeight",
        key: "key",
        title: <FormattedMessage id="VRTrailerMaxLoadedWeight" defaultMessage="Trailer Max.Loaded Weight" />,
    },
    {
        dataIndex: "seatingCapacity",
        key: "key",
        title: <FormattedMessage id="VRSeatingCapacity" defaultMessage="Seating Capacity" />,
    },
    {
        dataIndex: "typeOfBody",
        key: "key",
        title: <FormattedMessage id="VRTypeOfBody" defaultMessage="Type of Body" />,
    },
    {
        dataIndex: "issuingProvinceCountry",
        key: "key",
        title: <FormattedMessage id="VRIssuingProvinceCountry" defaultMessage="Issuing Province Country" />,
    },
    {
        dataIndex: "theDateOfUsageOnTraffic",
        key: "key",
        title: <FormattedMessage id="VRTheDateOfUsageOnTraffic" defaultMessage="The Date of Usage on Traffic" />,
        render: (record) =>  
        {
            var _date = '';
            if (record !== null && record !== undefined) { 
                _date = moment(record).format("DD/MM/YYYY");
                return _date;
            } 
            return _date; 
        }
    },
    {
        dataIndex: "firstRegistrationDate",
        key: "key",
        title: <FormattedMessage id="VRFirstRegistrationDate" defaultMessage="First Registration Date" />,
        render: (record) =>  
        {
            var _date = '';
            if (record !== null && record !== undefined) { 
                _date = moment(record).format("DD/MM/YYYY");
                return _date;
            } 
            return _date; 
        }
    },
    {
        dataIndex: "registrationSerialNo",
        key: "key",
        title: <FormattedMessage id="VRRegistrationSerialNo" defaultMessage="Registration Serial No" />,
    },
    {
        dataIndex: "registrationDate",
        key: "key",
        title: <FormattedMessage id="VRRegistrationDate" defaultMessage="Registration Date" />,
        render: (record) =>  
        {
            var _date = '';
            if (record !== null && record !== undefined) { 
                _date = moment(record).format("DD/MM/YYYY");
                return _date;
            } 
            return _date; 
        }
    },
    {
        dataIndex: "tradeName",
        key: "key",
        title: <FormattedMessage id="VRTradeName" defaultMessage="Trade Name" />,
    },
    {
        dataIndex: "value",
        key: "key",
        title: <FormattedMessage id="VRValue" defaultMessage="Value" />,
    },
    {
        dataIndex: "nameOfOwner",
        key: "key",
        title: <FormattedMessage id="VRNameOfOwner" defaultMessage="Name of Owner" />,
    },
    {
        dataIndex: "ownerIqamaNo",
        key: "key",
        title: <FormattedMessage id="VROwnerIqamaNo" defaultMessage="Owner Iqama No" />,
    },
    {
        dataIndex: "vehicleNo",
        key: "key",
        title: <FormattedMessage id="VRVehicleNo" defaultMessage="Vehicle No" />,
    },
    {
        dataIndex: "registrationNo",
        key: "key",
        title: <FormattedMessage id="VRRegistrationNo" defaultMessage="Registration No" />,
    },
    {
        dataIndex: "sequenceNo",
        key: "key",
        title: <FormattedMessage id="VRSequenceNo" defaultMessage="Sequence No" />,
    },
    {
        dataIndex: "expiryDate",
        key: "key",
        title: <FormattedMessage id="VRExpiryDate" defaultMessage="Expiry Date" />,
        render: (record) =>  
        {
            var _date = '';
            if (record !== null && record !== undefined) { 
                _date = moment(record).format("DD/MM/YYYY");
                return _date;
            } 
            return _date; 
        }
    },
    {
        dataIndex: "firstTestExpiryDate",
        key: "key",
        title: <FormattedMessage id="VRFirstTestExpiryDate" defaultMessage="First Test Expiry Date" />, 
    },
    {
        dataIndex: "kmYear",
        key: "key",
        title: <FormattedMessage id="VRKmYear" defaultMessage="Km Year" />,
    },
    {
        dataIndex: "mobilizationOrder",
        key: "key",
        title: <FormattedMessage id="VRMobilizationOrder" defaultMessage="Mobilization Order" />,
    },
];

export const columnsStoreLog = [ 
    {
      dataIndex: "index",
      key: "index",
      title: "S/N",
    },
    {
      dataIndex: "stockGroupDescription",
      key: "stockGroupDescription",
      title: "Stock Group",
    //   filterDropdown: () => (
    //     <div style={{ padding: 8 }}>
    //       <Cascader
    //         style={{ width: "100%" }}
    //         value={this.state.values}
    //         options={this.state.nodes}
    //         onChange={this.onChangeStoreGroupFilter}
    //         changeOnSelect
    //       />
    //     </div>
    //   ),
    //   filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockGroupDescriptionColor }} 
    //   />
    },
    {
      dataIndex: "stockCode",
      key: "stockCode",
      title: "Stock Code",
    //   filterDropdown: () => (
    //     <div style={{ padding: 8 }}>
    //       <Input style={{ flex: 'auto' }} value={this.state.stockCode} placeholder="Stock Code"
    //         onChange={this.onChangeStockCode}
    //       />
    //     </div>),
    //   filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockCodeColor }} />
    },

    {
      dataIndex: "stockDescription",
      key: "stockDescription",
      title: "Stock Description",
    //   filterDropdown: () => (
    //     <div style={{ padding: 8 }}>
    //       <Input style={{ flex: 'auto' }} value={this.state.stockDescription} placeholder="Stock Description"
    //         onChange={this.onChangeStockDescription}
    //       />
    //     </div>),
    //   filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockDescriptionColor }} />
    },
    {
      dataIndex: "stockTypeId",
      key: "stockTypeId",
      title: "Stocking Type",
    },
    {
      dataIndex: "stockLocation",
      key: "stockLocation",
      title: "Location",
    //   filterDropdown: () => (
    //     <div style={{ padding: 8 }}>
    //       <Input style={{ flex: 'auto' }} value={this.state.stockLocation} placeholder="Stock Location"
    //         onChange={this.onChangeStockLocation}
    //       />
    //     </div>),
    //   filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockLocationColor }} />
    },
    {
      dataIndex: "materialGroupId",
      key: "materialGroupId",
      title: "Material Group",
    },
    {
      dataIndex: "materialNames",
      key: "materialNames",
      title: "Materials",
    //   filterDropdown: () => (
    //     <div style={{ padding: 8 }}>
    //       <MultiSelect className="p-column-filter" value={this.state.materialSelect}
    //         options={this.state.optionMaterialSelects} onChange={this.onMaterialChangeFilter} />
    //     </div>
    //   ),
    //   filterIcon: filtered => <FilterOutlined style={{ color: this.state.materialSelectColor }} />
    },
];

export const columnsWeldList = [ 
    {
      dataIndex: "index",
      key: "key",
      title: <FormattedMessage id="GeneralTableSN" defaultMessage="S/N" />,
    },
    {
        dataIndex: "revno",
        key: "key",
        title: <FormattedMessage id="WELDRevno" defaultMessage="Revno" />,
    },
    {
        dataIndex: "spool",
        key: "key",
        title: <FormattedMessage id="WELDSpool" defaultMessage="Spool" />,
    },
    {
        dataIndex: "weld",
        key: "key",
        title: <FormattedMessage id="WELDWeld" defaultMessage="Weld" />,
    },
    {
        dataIndex: "repExtAck",
        key: "key",
        title: <FormattedMessage id="WELDRepExt" defaultMessage="Rep Ext" />,
    },
    {
        dataIndex: "pwhtExtAck",
        key: "key",
        title: <FormattedMessage id="WELDPWHTExt" defaultMessage="PWHT Ext" />,
    },
    {
        dataIndex: "tracerAck",
        key: "key",
        title: <FormattedMessage id="WELDTracer" defaultMessage="Tracer" />,
    },  
    {
        dataIndex: "ipAck",
        key: "key",
        title: <FormattedMessage id="WELDIP" defaultMessage="I.P." />,
    }, 
    {
        dataIndex: "rwReasonAck",
        key: "key",
        title: <FormattedMessage id="WELDRWReason" defaultMessage="Reason" />,
    }, 
    {
        dataIndex: "pipingClassAck",
        key: "key",
        title: <FormattedMessage id="WELDPipingClass" defaultMessage="Piping Class" />,
    }, 
    {
        dataIndex: "serviceAck",
        key: "key",
        title: <FormattedMessage id="WELDService" defaultMessage="Service" />,
    }, 
    {
        dataIndex: "materialClassAck",
        key: "key",
        title: <FormattedMessage id="WELDMaterialClass" defaultMessage="Material Class" />,
    }, 
    {
        dataIndex: "codeWeldSize",
        key: "key",
        title: <FormattedMessage id="WELDSize" defaultMessage="Size" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "codeWallThick",
        key: "key",
        title: <FormattedMessage id="WELDWallThick" defaultMessage="Wall Thick" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "categoryAck",
        key: "key",
        title: <FormattedMessage id="WELDCategory" defaultMessage="Category" />,
    }, 
    {
        dataIndex: "typeAck",
        key: "key",
        title: <FormattedMessage id="WELDType" defaultMessage="Type" />,
    }, 
    {
        dataIndex: "codeMark1",
        key: "key",
        title: <FormattedMessage id="WELDMark1" defaultMessage="Mark1" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
    {
        dataIndex: "codeMark2",
        key: "key",
        title: <FormattedMessage id="WELDMark2" defaultMessage="Mark2" />,
        render: (data) =>   
        {  
         if(data!=null && data!=undefined){
            return data.name;
         } 
         return "";
        }
    },
];