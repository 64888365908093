/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Button } from "react-bootstrap";
import { Button as PrimeButton } from "primereact/button";
import { Button as AntdButton } from 'antd';
import { Modal, Form, Col, Row, Table } from "react-bootstrap";
import { Card, Accordion } from "react-bootstrap";
import { handleRequest } from "../../redux/config/env"; 
import { DownloadOutlined } from '@ant-design/icons';

const sortIcon = <ArrowDownward />; 

export class StoreLogSub extends Component {
  constructor() {
    super();
    this.state = {
      StockAddressings:[],


      deleteId: 0,
      deleteFormName: "",
      editId: 0,
      testModal: false,
      customerId: 0,
      hidden: true,
      expanded: [],
      brand: "",
      model: "",
      serialNo: "",
      chasisNo: "",
      departments: [],
      departmentId: null,
      disciplines: [],
      disciplineId: null,
      materialLogId: 0,
      MaterialClients: [],
      Manufacturings: [],
      MaterialPackings:[],
      MaterialHandlings:[],
      MaterialTransportations:[],
      MaterialMaintenances:[],

      specifications: [],
      demo: [],
      MaterialDimensions:[],
      MaterialWarrantys:[],
      MaterialClassifications:[],
    };

    this.renderDownloadFile=this.renderDownloadFile.bind(this);  
  }

  componentDidMount = async () => {

    this.getStockAddressings();
  };

  getStockAddressings = async () => {
    var storeLogId="-1";
    if(this.props.row.id!=null && this.props.row.id!="" ){
      storeLogId=this.props.row.id;
    }
    const newObj = {
      storeLogId: storeLogId
    } 
    const response = await handleRequest("POST", "/StockAddressing/getStockAddressingResult", newObj); 

    if (response.data.length !== 0) {

      this.setState({ 
        StockAddressings: response.data,
       });
    } else { 
      this.setState({ 
        StockAddressings: [],
     });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }; 
     
  showOrHideModalDelete = () => {
    this.setState({
      modalShowDelete: !this.state.modalShowDelete,
    });
  };  

  renderDownloadFile(rowData) { 
    if (rowData!==null && rowData!=="" && rowData!==undefined){
        return <React.Fragment>
            <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
        </React.Fragment>
    return "";
    } 
  };

  renderDownloadFileLayout(rowData) { 
    if (rowData.layoutFilePath!==null && rowData.layoutFilePath!=="" && rowData.layoutFilePath!==undefined){
        return <React.Fragment>
            <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData.layoutFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
        </React.Fragment>
    } 
    return "";
  };

  renderDownloadFileAdressingPhoto(rowData) { 
    if (rowData.adressingPhotoFilePath!==null && rowData.adressingPhotoFilePath!=="" && rowData.adressingPhotoFilePath!==undefined){
        return <React.Fragment>
            <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData.adressingPhotoFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
        </React.Fragment>
    } 
    return "";
  };

  downloadFile = async (url) => {
    window.location.href = url;
  } 

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }

render() {
  return (
    <div style={{ padding: "15px" }}>
        <Modal
          show={this.state.modalShowDelete}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this data?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              Close
            </Button>
            <Button variant="danger" onClick={this.deleteRecord}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
     
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0">
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Stock Preservation
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="AccordionPreservation" eventKey="0">
              <Card.Body>
              

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion defaultActiveKey="1">
          <Card>
            <Accordion.Toggle
              style={{ backgroundColor: "#E0E4FF" }}
              as={Card.Header}
              eventKey="0" >
              <Row>
                <Col>
                  <Card.Header
                    style={{
                      backgroundColor: "#E0E4FF",
                      color: "#5d78ff",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                    as="h5"
                  >
                    &#x25bc; Stock Addressing
                  </Card.Header>
                </Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="AccordionAddressing" eventKey="0">
              <Card.Body>
              <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Section</th>
                          <th>Shelf no</th>
                          <th>Code</th>
                          <th>Coordinates</th>
                          <th>Dimension</th>
                          <th>Load Capacity</th>
                          <th>Storing Equipment</th>
                          <th>Photo</th>
                          <th>Layout</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.StockAddressings.map(stockAddressing => (
                            <tr key={stockAddressing.index}>
                            <td>{stockAddressing.index}</td>
                            <td>{stockAddressing.section}</td>
                            <td>{stockAddressing.shelfno}</td>
                            <td>{stockAddressing.addressingCode}</td>
                            <td>{stockAddressing.coordinatesAck}</td>
                            <td>{stockAddressing.dimensionAck}</td>
                            <td>{stockAddressing.capacity}</td>
                            <td>{stockAddressing.storingEquipmentAck}</td>
                            <td>{this.renderDownloadFileAdressingPhoto(stockAddressing)}</td>
                            <td>{this.renderDownloadFileLayout(stockAddressing)}</td>
                            </tr>
                        ))}
                      </tbody>
                    </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
     </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StoreLogSub);