import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import { Select } from 'antd';
import { Form as FormAnt, message } from 'antd';
import { FormattedMessage } from "react-intl";

const { Option } = Select;

class CreateJavaClass extends Component {

    constructor(props) {
        super(props)

        this.state = { 
            selectedEntities: [],
            entitiySelectItems: []
        }
    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/ApplicationSettings/getEntitiesFromLocal");

        console.log(response); 
        if (Boolean(response.data) && response.data.length > 0) {

            const data = response.data;
            console.log(data);
            this.setState({
                entitiySelectItems: data

            });
        }


    }

    create = async () => {

        if (Boolean(this.state.selectedEntities) && this.state.selectedEntities.length > 0) {
            await handleRequest("POST", "/ApplicationSettings/createDaoandServiceObjects", this.state.selectedEntities);
            message.success("Creating objects(DAO,Service,Controller) completed succesfully.")
        }
        else message.error("Please select entity names");
    }


    render() {
        return (
            <div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">

                {
                    <FormAnt.Item
                        label={<FormattedMessage id="todo" defaultMessage="Entities" />}
                        name="OrganizationalLevelDesignation"
                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                            <Col sm="6">
                                <Select 
                                 showSearch
                                 optionFilterProp="children"
                                mode="tags" placeholder="" value={this.state.selectedEntities} onChange={(value) => {
                                    this.setState({
                                        selectedEntities: value
                                    })
                                }}
                                >
                                    <Option key={0} value={0}>Select</Option>
                                    {this.state.entitiySelectItems.map(i => (
                                        <Option key={i} value={i}>{i}</Option>
                                    ))}
                                </Select>

                            </Col>
                            <Col sm="6">
                                <ReactButton id="OrganizationalLevelSaveButton" type="button" onClick={this.create} style={{ width: '100%' }}
                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Create" /></ReactButton>
                            </Col>
                        </Form.Group>
                    </FormAnt.Item>
                }
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJavaClass)
