import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ShowIcon from '@material-ui/icons/ShopTwo';
import { Divider} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export default ({ row, onEditRow, onDeleteRow2, onRevision, size }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editRow = () => {
    if (onEditRow) {
      onEditRow(row);
      setAnchorEl(null);
    }
  };

  const deleteRow2 = () => {
    if (onDeleteRow2) {
      onDeleteRow2(row);
      setAnchorEl(null);
    }
  };

  const revision = () => {
    if (onRevision) {
      onRevision(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={size}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem onClick={editRow}>
          <Link style={{color:"black"}} to={`/PersonalInfo/${row.id}`} > <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
            <Typography variant="inherit">
              Edit
          </Typography>
          </Link>

        </MenuItem>

        <Divider />

        <MenuItem onClick={deleteRow2}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit">
            Delete
          </Typography>
        </MenuItem>



        <Divider />

        <MenuItem onClick={revision}>
          <ListItemIcon>
            <ShowIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">
            Revisions
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};