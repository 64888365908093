/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { handleRequest } from '../../redux/config/env';
import { TEMPLATE_Dynamia_ClientID } from '../MND/TableColumns';
import { Modal , Button as BootButton,Col, Form, Row} from 'react-bootstrap';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import { FormattedMessage } from "react-intl";
import { Checkbox } from "antd";
    
export class RoleGroup extends Component {
    constructor() {
        super();
        this.state = {
            modalShow: false,  
            modalShowTemplate: false, 
            modalShowRoleName:false,
            hiddenUpdate: true,
            hiddenSave:false,
            displayBasic: false,
            position: 'center',
            roleNameText: "",
            required:false,
            selectedRole: null,
            roleGroups: [],
            roleGroupTemplates:[],
            selectedRowVal:null,
            roleGroupRowChangeId:0,
            hiddenRequired:false,
        };
        this.actionTemplate = this.actionTemplate.bind(this);  
        this.actionRoleName = this.actionRoleName.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.onselectChangeVal = this.onselectChangeVal.bind(this); 

    }

    componentDidMount = async () => { 
      
        if(this.props.auth.user.companyId==TEMPLATE_Dynamia_ClientID){
            this.setState({
                hiddenRequired: true,
            });
        }
        else{
            this.setState({
                hiddenRequired: false,
            });
        }         

        this.getRoleGroup();
        this.getRoleGroupTemplate();
    };

    getRoleGroup = async () => {
        const response = await handleRequest("GET", "/RoleGroup/getallByCompany"); 
        if (response.data.length === 0){
            this.setState({
                roleGroups: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                roleGroups: response.data,
            });
        }
    }

    getRoleGroupTemplate = async () => {
        const response = await handleRequest("GET", "/RoleGroup/getTemplateRoleGroupResult"); 
        if (response.data.length === 0){
            this.setState({
                roleGroupTemplates: [],
                selectedRowVal: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                roleGroupTemplates: response.data,
                selectedRowVal:response.data.filter(x=>x.required==1 )
            });
        }
    }
 
    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successful"/>;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !"/>;
        }
        console.log(msg);
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Error"
        });
    };

    showWarning(msg) {
        console.log(msg);
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs."/>;
        }
        this.setState({
            modalMessage:true,
            message:msg,
            messageTip:"Warning"
        });
    };

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true,
            selectedRole: rowData
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    } 

    actionTemplate(rowData, column) {
        return <React.Fragment> 
            <CustomMaterialMenuDeleteEdit row={rowData} 
            onDeleteRow={this.deleteModal.bind(this)} 
            onEditRow={this.changeButtonUpdate.bind(this)} />   
            </React.Fragment> ;
    }; 
    //style={{ marginTop: "-12px", marginLeft: "50px" }}
    actionRoleName(rowData) {
        return <React.Fragment>   
            <Form.Group as={Row}>
                <Col sm="11">
                <Form.Label style={{ marginTop: '3rem' }}>
                {rowData.roleName} 
                </Form.Label>  
                </Col>
                <Col sm="1" style={{ marginTop: '2rem' }}>
                    <CustomMaterialMenuEdit row={rowData} 
                    onEditRow={this.changeRoleName.bind(this)} />   
                </Col>
            </Form.Group>
            </React.Fragment> ;
    };

    deleteModal = (row) => {
        this.setState({
            modalShow: true,
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }  
    
    showOrHideModalRoleName = () => {
        this.setState({
            modalShowRoleName: !this.state.modalShowRoleName
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

   showOrHideModalTemplate = () => {
        this.setState({
            modalShowTemplate: !this.state.modalShowTemplate
        });
    }
 
    addRoleGroup = async () => {
        if (this.state.roleNameText !== null && this.state.roleNameText !== undefined && this.state.roleNameText !== ""){
            const newRoleGroup = {
                roleName : this.state.roleNameText,
                required: this.state.required,
            }
            var response = await handleRequest("POST", "/RoleGroup/add", newRoleGroup);
    
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS-ROLEGROUP-001")
               {
                this.changeButtonSave();
                this.restartTable();
                this.showSuccess();
               } 
               else  if(response.data==="ERROR-ROLEGROUP-001") {
                this.showError(); 
               }  else  if(response.data==="ERROR-ROLEGROUP-002") {
                this.showWarning(<FormattedMessage id="RoleGroupMessageWarning" defaultMessage="This record added before, please chechk role group name !"/>);  
               }
            }
            
        } 
        else{
            this.showWarning();
        }
    };

   updateRoleGroup = async () => {
        if (this.state.roleNameText !== null && this.state.roleNameText !== undefined && this.state.roleNameText !== ""){
            const updatedRoleGroup = {
                roleName : this.state.roleNameText,
                id: this.state.selectedRole.id,
                active: this.state.selectedRole.active, 
                roleGroupTemplateId: this.state.selectedRole.roleGroupTemplateId,
                required:this.state.required
            }
            var response2 = await handleRequest("POST", "/RoleGroup/update", updatedRoleGroup);
            if(response2.data!==null && response2.data!==undefined){
                if(response2.data==="SUCCESS-ROLEGROUP-001")
                {
                this.changeButtonSave();
                this.restartTable();
                this.showSuccess();
                } 
                else  if(response2.data==="ERROR-ROLEGROUP-001") {
                this.showError(); 
                }  else  if(response2.data==="ERROR-ROLEGROUP-002") {  
                this.showWarning(<FormattedMessage id="RoleGroupMessageWarning" defaultMessage="This record added before, please chechk role group name !"/>);  
                }
            }
        } 
        else{
            this.showWarning();
        }
    };

    saveRoleGroupName = async () => {
        if (this.state.roleNameText !== null && this.state.roleNameText !== undefined && this.state.roleNameText !== ""){
           if(this.state.roleGroupRowChangeId>0){
                this.state.roleGroupTemplates.filter(x=>x.id==this.state.roleGroupRowChangeId).forEach(element => {
                    element.roleName=this.state.roleNameText;
                });
                this.showOrHideModalRoleName();
            }
            else{
                this.showWarning();
            } 
        }
        else{
            this.showWarning();
        }
    };

    templateRoleGroup = async () => {
        // var select=[];
        // if(this.state.roleGroupTemplates.length>0){
        //     select=this.state.roleGroupTemplates.filter(x=>x.required==1);
        // }
        this.getRoleGroupTemplate();
        this.setState({
            modalShowTemplate: true,
            // selectedRowVal:select,
        });
    };

     renderRequired(rowData, column) {
        if(rowData.required){
            return <FormattedMessage id="GeneralTextYes" defaultMessage="Yes" />;
        }else{
             return <FormattedMessage id="GeneralTextNo" defaultMessage="No"/>;
        }
     };

    restartTable = async () => {
        this.getRoleGroup();
    }

    deleteRoleGroup = async (name) => {
        const deletedRoleGroup = {
            id : this.state.selectedRole.id
        }

        await handleRequest("POST", "/RoleGroup/delete", deletedRoleGroup);

        console.log(deletedRoleGroup);

        this.restartTable();
        this.showSuccess();
        this.onHideModal(name); 
    };

    delete = async (name) => {
        const deletedRoleGroup = {
            id : this.state.selectedRole.id
        }
        await handleRequest("POST", "/RoleGroup/delete", deletedRoleGroup);

        console.log(deletedRoleGroup);

        this.restartTable();
        this.showSuccess();
        this.showOrHideModal(); 
    };

    changeRoleName = (row) => {
        this.setState({
            roleGroupRowChangeId:row.id,
            roleNameText:row.roleName,
            required:row.required,
            modalShowRoleName: true
        });
    };

    changeButtonUpdate = (row) => {
        this.setState({
            roleNameText: row.roleName,
            required:row.required,
            hiddenUpdate: false, 
            hiddenSave:true,
        });
    };

    changeButtonSave = () => {
        this.setState({
            roleNameText: "",
            required:false,
            hiddenUpdate: true, 
            hiddenSave:false,
        });
      
    };

    changeButtonCancel = () => {
        this.setState({
            roleNameText: "",
            required:false,
            hiddenUpdate: true, 
            hiddenSave:false,
        }); 
    };

    onselectChangeVal = async (event) => {
        var deger=[];
        this.state.selectedRowVal.forEach(element => {
          if((element.required)){
            deger.push(element);
          }
        });
        event.value.forEach(element => {
            var knt= deger.filter(x=>x.id==element.id );
            if(knt.length==0){
                deger.push(element);
            }
        });
        await this.setState({selectedRowVal: deger}); 
    }

    modalTemplateSelect = async () => { 
        const {selectedRowVal}= this.state;
        if(selectedRowVal!=null&&selectedRowVal.length>0)
        {
            var response = await handleRequest("POST", "/RoleGroup/addTemplate", selectedRowVal);
    
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS-ROLEGROUP-001")
                {
                    this.changeButtonSave();
                    this.restartTable();
                    this.showSuccess();
                    this.showOrHideModalTemplate();
                } 
                else  if(response.data==="ERROR-ROLEGROUP-001") {
                    this.showError(); 
                }   
                else  if(response.data==="ERROR-ROLEGROUP-002") {
                    this.showWarning(<FormattedMessage id="RoleGroupMessageWarning" defaultMessage="This record added before, please chechk role group name !"/>); 
                }   
            }
            else {
                this.showError();
            }
        }
        else {
            this.showWarning();
        }
    }

    render() {
        let actionHeader = <Button  hidden={this.state.hiddenRequired} onClick={this.templateRoleGroup} type="button" icon="pi pi-cog"></Button>
        return (
            <div>
                <Card style={{ width: '800px' }}>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                            <FormattedMessage id="TemplateRG.Role.Group" defaultMessage="Role Group" />
                        </Form.Label>
                      <Col sm="6">
                        <InputText style={{ marginTop: '0.2rem', width: '100%' }} onChange={(e) => this.setState({ roleNameText: e.target.value })} value={this.state.roleNameText} />
                      </Col>
                      <Col sm="3">
                      {this.state.hiddenRequired && <Checkbox  style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }}  checked={this.state.required} onChange={(e) => { 
                            this.setState({ 
                                required: e.target.checked,
                            });
                          }}>
                          <FormattedMessage id="RGRequired" defaultMessage="Required" /></Checkbox>}
                      </Col>
                    </Form.Group>
                
                <div hidden={this.state.hiddenSave}>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="3"></Col>
                        <Col sm="6">
                            <Button  style={{ marginTop: '0.2rem', width: '100%' }} id='save-rolegroup' className="p-button-success" onClick={this.addRoleGroup} label={<FormattedMessage id="GeneralButtonAdd" defaultMessage="Add" />} />
                        </Col> 
                        <Col sm="3">
                        </Col>
                    </Form.Group>
                </div>
                <div hidden={this.state.hiddenUpdate}>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="3"></Col>
                        <Col sm="3">
                            <Button  style={{ marginTop: '0.2rem', width: '100%' }} id='cancel-rolegroup' className="p-button-danger" onClick={this.changeButtonCancel} label={<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />} />
                        </Col> 
                        <Col sm="3">
                            <Button  style={{ marginTop: '0.2rem', width: '100%' }} id='save-rolegroup' className="p-button-warning" onClick={this.updateRoleGroup} label={<FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/>} />
                        </Col> 
                        <Col sm="3">
                        </Col>
                    </Form.Group>
                </div>

                    <div className="content-section implementation">
                        <DataTable ref={(el) => this.dt = el} value={this.state.roleGroups} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10} className="p-datatable-rolegroups"
                            selectionMode="single" selection={this.state.selectedRole} onSelectionChange={e => this.setState({ selectedRole: e.value })}>
                            <Column field="index" style={{ textAlign: 'center', width: '10%' }} sortable header={<FormattedMessage id="GeneralTableSN" defaultMessage="S/N" />} />
                            <Column field="roleName" style={{ width: '70%' }} header={<FormattedMessage id="TemplateRG.Role.Group" defaultMessage="Role Group"/>} filter sortable />
                            {this.state.hiddenRequired &&
                            <Column body={this.renderRequired} style={{ width: '10%' }} header={<FormattedMessage id="RGRequired" defaultMessage="Required"/>} filter sortable />}
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '10%' }} />
                        </DataTable>
                    </div>
                    
                    <Modal
                        show={this.state.modalShow}
                        onHide={this.showOrHideModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="TemplateRG.Delete.Message1" defaultMessage="Delete This Role Group Record?" />}</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            <p>{<FormattedMessage id="TemplateRG.Delete.Message2" defaultMessage="This record will be deleted. Are you sure?"/>}</p>  
                        </Modal.Body> 
                        <Modal.Footer>
                            <BootButton variant="secondary" onClick={this.showOrHideModal}>{<FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/>}</BootButton>
                            <BootButton variant="danger" onClick={this.delete} >{<FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />}</BootButton>  
                        </Modal.Footer>
                    </Modal>

 
                    <Modal 
                    show={this.state.modalShowTemplate} size="lg" 
                    onHide={this.showOrHideModalTemplate} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered  >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="Menu.TemplateRG.Role.Group" defaultMessage="Template Role Group" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <DataTable ref={(el) => this.dt = el} value={this.state.roleGroupTemplates}   
                            virtualScroll={true} responsive
                            className="p-datatable-customers" dataKey="id" rowHover  
                            selection={this.state.selectedRowVal} 
                            onSelectionChange={this.onselectChangeVal}
                            paginator rows={10} emptyMessage="No data found" >
                        <Column selectionMode="multiple" style={{ textAlign: 'center', width: '10%' }}/>
                        <Column field="index" style={{ textAlign: 'center', width: '10%' }} sortable header="S/N" />
                        <Column header={<FormattedMessage id="TemplateRG.Role.Group" defaultMessage="Role Group"/>} style={{ width: '60%' }} body={this.actionRoleName} />
                        <Column body={this.renderRequired} style={{ textAlign: 'center', width: '20%' }} header={<FormattedMessage id="RGRequired" defaultMessage="Required"/>} sortable />
                      </DataTable>
                    </Modal.Body>
                    <Modal.Footer>
                      
                      <BootButton variant="secondary" onClick={this.showOrHideModalTemplate}> <FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></BootButton> &nbsp;&nbsp;&nbsp;
                      <BootButton variant="success" onClick={this.modalTemplateSelect}><FormattedMessage id="GeneralTextSelect" defaultMessage="Select"/></BootButton> 
                    </Modal.Footer>
                  </Modal> 

                    <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                            <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                            
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                            </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row > 
                        <Col sm="12">
                            {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <BootButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></BootButton>
                    </Modal.Footer>
                    </Modal>
     
                    <Modal
                        show={this.state.modalShowRoleName}
                        onHide={this.showOrHideModalRoleName}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="RoleGroupModalName" defaultMessage="Change  Role Group Name"/></Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Form.Label style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }} column sm="3">
                            <FormattedMessage id="TemplateRG.Role.Group" defaultMessage="Role Group" />
                        </Form.Label>
                      <Col sm="6">
                        <InputText style={{ marginTop: '0.2rem', width: '100%' }} onChange={(e) => this.setState({ roleNameText: e.target.value })} value={this.state.roleNameText} />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>
                    </Modal.Body> 
                    <Modal.Footer>
                        <BootButton variant="secondary" onClick={this.showOrHideModalRoleName}><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></BootButton>
                        <BootButton variant="success" onClick={this.saveRoleGroupName}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save"/></BootButton>  
                    </Modal.Footer>
                </Modal>    

                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleGroup)