/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Button as PrimeButton } from "primereact/button";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { Form, Col, Row, Button, Modal } from 'react-bootstrap';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { handleRequest } from '../../redux/config/env';
import { Select, Divider, Input, Cascader } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import $ from 'jquery';
import { Dropdown as ReactDropdown } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';

const { Option } = Select;

let index = 0;


export class BreakdownTypes extends Component {

    constructor() {
        super();
        this.state = {
            editId: 0,
            hiddenDiv: true,
            createButton: "Create New",
            hiddenSave: false,
            hiddenUpdate: true,
            breakdownTypes: [],
            items: [],
            itemsIds: [],
            itemsForDb: [],
            name: '',
            nodes: [],
            equipmentLogId: null,
            equipmentLogs1: [],
            equipmentCodes: [],
            equipmentDescriptions: [],
            discipline: null,
            disciplines: [],
            group: null,
            groups: [],
            breakdown: null,
            breakdowns: [],
            sourceOfDiagnosis: null,
            sourceOfDiagnosises: [],
            values: [],
            selectedItems: []
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        this.actionTemplate = this.actionTemplate.bind(this);

        this.onDisciplineChange = this.onDisciplineChange.bind(this);
        this.onGroupChange = this.onGroupChange.bind(this);
        this.onBreakdownChange = this.onBreakdownChange.bind(this);
        this.onSourceOfDiagnosisChange = this.onSourceOfDiagnosisChange.bind(this);
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong. Please check inputs.' });
    }

    componentDidMount = async () => {

        const response00 = await handleRequest("GET", "/BreakdownType/getAll");

        if (response00.data.length === 0) {
            this.setState({
                breakdownTypes: []
            });
        }

        if (response00.data.length !== 0) {
            this.setState({
                breakdownTypes: response00.data,
            });
        }

        console.log(response00.data);

        const response0 = await handleRequest("GET", "/EquipmentLog/getAll");

        if (response0.data.length === 0) {
            this.setState({
                equipmentLogs1: []
            });
        }

        if (response0.data.length !== 0) {
            this.setState({
                equipmentLogs1: response0.data,
            });
        }

        const response = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }

        const response1 = await handleRequest("GET", "/Code/getAll");

        if (response1.data.length !== 0) {

            const columns = response1.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "discipline") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                disciplines: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "group") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                groups: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "breakdown") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                breakdowns: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "sourceOfDiagnosis") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                sourceOfDiagnosises: options
            });
        }

        const response2 = await handleRequest("GET", "/Proposal/getAll");

        var flagItems = [];
        var flagIds = [];

        if (response2.length === 0 || response2.data.length === 0) {
            this.setState({
                items: []
            });
        } else if (response2.data.length !== 0) {
            for (let index = 0; index < response2.data.length; index++) {
                flagItems.push(response2.data[index].proposalName.toString());
                flagIds.push(response2.data[index].id.toString());
            }

            this.setState({
                items: flagItems,
                itemsIds: flagIds
            });
        }

    }

    createNew = () => {
        if (this.state.createButton === "Create New") {
            this.setState({
                hiddenDiv: false,
                createButton: "Stop Adding New",
                createButtonVariant: "danger",
                hiddenSave: false,
                hiddenUpdate: true
            });
            this.refreshStates();
        } else if (this.state.createButton === "Stop Adding New") {
            this.setState({
                hiddenDiv: true,
                createButton: "Create New",
                createButtonVariant: "primary",
                hiddenSave: true,
                hiddenUpdate: true
            });
            this.refreshStates();
        } else if (this.state.createButton === "Stop Updating") {
            this.setState({
                hiddenDiv: true,
                createButton: "Create New",
                createButtonVariant: "primary",
                hiddenSave: true,
                hiddenUpdate: true
            });
            this.refreshStates();
        }
    }

    updateDone = async () => {

        var flagItems = this.state.items;

        console.log(flagItems);

        for (let index = 0; index < this.state.itemsForDb.length; index++) {
            if (!(flagItems.includes(this.state.itemsForDb[index]))) {

                var item = {
                    proposalName: this.state.itemsForDb[index],
                }

                await handleRequest("POST", "/Proposal/add", item);
            }
        }

        const response = await handleRequest("GET", "/Proposal/getAll");

        flagItems = [];
        var flagIds = [];

        if (response.length === 0 || response.data.length === 0) {
            flagItems = [];
        } else if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {
                flagItems.push(response.data[index].proposalName.toString());
                flagIds.push(response.data[index].id.toString());
            }
        }

        var itemIdsForDb = [];

        for (let index = 0; index < this.state.itemsForDb.length; index++) {
            for (let index1 = 0; index1 < flagItems.length; index1++) {
                if (this.state.itemsForDb[index] === flagItems[index1]) {
                    itemIdsForDb.push(flagIds[index1]);
                }
            }
        }

        //console.log(itemIdsForDb);   //bunu "proposals" olarak vereceğiz.

        const updatedBreakdownType = {
            id: this.state.editId,
            equipmentLogId: this.state.equipmentLogId,
            equipmentTypeId: this.state.values[this.state.values.length - 1],
            discipline: this.state.discipline,
            group: this.state.group,
            breakdown: this.state.breakdown,
            sourceOfDiagnosis: this.state.sourceOfDiagnosis,
            proposals: itemIdsForDb
        }

        await handleRequest("POST", "/BreakdownType/update", updatedBreakdownType);

        this.setState({
            hiddenSave: false,
            hiddenUpdate: true,
        });

        this.showSuccess();
        this.refreshStates();
        this.restartBreakdownTypeTable();
    }

    buttonCancel = () => {
        this.setState({
            hiddenDiv: true,
            hiddenSave: false,
            hiddenUpdate: true,
            createButton: "Create New",
            createButtonVariant: "primary",
        });

        this.refreshStates();
    }

    actionTemplate(rowData, column) {
        return <React.Fragment><CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.editModal.bind(this)} /></React.Fragment>;
    }


    deleteModal = async (row) => {
        this.setState({
            deleteModal: true,
            editId: row.id
        });
    }

    editModal = async (rowData) => {

        if (this.state.createButton === "Create New") {
            this.setState({
                hiddenDiv: false,
                createButton: "Stop Updating",
                createButtonVariant: "danger",
                hiddenSave: true,
                hiddenUpdate: false
            });
        } else if (this.state.createButton === "Stop Adding New") {
            this.setState({
                hiddenDiv: false,
                createButton: "Stop Updating",
                createButtonVariant: "danger",
                hiddenSave: true,
                hiddenUpdate: false
            });
        } else if (this.state.createButton === "Stop Updating") {
            this.setState({
                hiddenDiv: false,
                createButton: "Stop Updating",
                createButtonVariant: "danger",
                hiddenSave: true,
                hiddenUpdate: false
            });
        }

        var flagValues = [];
        var flagEquipmentTypeId = null;

        if (rowData.equipmentType !== null) {

            flagEquipmentTypeId = rowData.equipmentType.id;

            flagValues = getParents(this.state.nodes, rowData.equipmentType.id).parents;

            flagValues.push(rowData.equipmentType.id);

            function getParentsHelper(tree, id, parents) {

                if (tree.data.id == id) {
                    return {
                        found: true,
                        parents: parents
                    };
                }
                var result = {
                    found: false,
                }
                if (tree.children) {
                    $.each(tree.children, function (index, subtree) {
                        var maybeParents = $.merge([], parents);
                        if (tree.data.id != undefined) {
                            maybeParents.push(tree.data.id);
                        }
                        var maybeResult = getParentsHelper(subtree, id, maybeParents);
                        if (maybeResult.found) {
                            result = maybeResult;
                            return false;
                        }
                    });
                }
                return result;
            }

            function getParents(data1, id) {
                var tree = {
                    data: {},
                    children: data1
                }
                return getParentsHelper(tree, id, []);
            }
        }

        let equipmentTypeIdFlag = flagEquipmentTypeId;
        let equipmentLogs = this.state.equipmentLogs1;

        if (this.state.equipmentLogs1.length === 0 || flagEquipmentTypeId === null) {
            this.setState({
                equipmentCodes: [],
                equipmentDescriptions: []
            });
        } else if (this.state.equipmentLogs1.length !== 0) {

            let options = [];
            let options2 = [];

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options.push({ name: equipmentLogs[index].equipmentCode.toString(), id: equipmentLogs[index].id.toString() });
                }
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options2.push({ name: equipmentLogs[index].equipmentDescription.toString(), id: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipmentCodes: options,
                equipmentDescriptions: options2
            });
        }

        var flagEquipmentDesc = "Select Equipment Description";
        var flagEquipmentCode = "Select Equipment Code";

        for (let index = 0; index < equipmentLogs.length; index++) {
            if (equipmentLogs[index].id === rowData.equipmentLogId) {
                flagEquipmentCode = equipmentLogs[index].equipmentCode;
                flagEquipmentDesc = equipmentLogs[index].equipmentDescription;
            }
        }

        var arr = rowData.proposalNames;
        const result = arr.filter(arr => arr != ", ");

        this.setState({
            editId: rowData.id,
            values: flagValues,
            equipmentTypeId: flagEquipmentTypeId,
            equipmentCode: flagEquipmentCode,
            equipmentDescription: flagEquipmentDesc,
            equipmentLogId: rowData.equipmentLogId,
            discipline: rowData.disciplineTypeCode.name,
            group: rowData.groupTypeCode.name,
            breakdown: rowData.breakdownType1Code.name,
            sourceOfDiagnosis: rowData.sourceOfDiagnosisTypeCode.name,
            itemsForDb: result,
            editableDropdownValue: result
        });
    }

    onNameChange = event => {
        this.setState({
            name: event.target.value,
        });
    }

    handleChange = (value) => {  //TODO: En son db'ye kaydetme işlemi olmazsa burada kaydet ve id'lerini çek
        //console.log(`selected ${value}`);
        this.setState({
            itemsForDb: value,
            editableDropdownValue: value
        });
    }

    addItem = () => {
        const { items, name } = this.state;
        this.setState({
            items: [...items, name || `New item ${index++}`],
            name: '',
        });
    }

    saveBreakdownType = async () => {

        var flagItems = this.state.items;

        for (let index = 0; index < this.state.itemsForDb.length; index++) {
            if (!(flagItems.includes(this.state.itemsForDb[index]))) {

                var item = {
                    proposalName: this.state.itemsForDb[index],
                }

                await handleRequest("POST", "/Proposal/add", item);
            }
        }

        const response = await handleRequest("GET", "/Proposal/getAll");

        flagItems = [];
        var flagIds = [];

        if (response.length === 0 || response.data.length === 0) {
            flagItems = [];
        } else if (response.data.length !== 0) {
            for (let index = 0; index < response.data.length; index++) {
                flagItems.push(response.data[index].proposalName.toString());
                flagIds.push(response.data[index].id.toString());
            }
        }

        var itemIdsForDb = [];

        for (let index = 0; index < this.state.itemsForDb.length; index++) {
            for (let index1 = 0; index1 < flagItems.length; index1++) {
                if (this.state.itemsForDb[index] === flagItems[index1]) {
                    itemIdsForDb.push(flagIds[index1]);
                }
            }
        }

        //console.log(itemIdsForDb);   //bunu "proposals" olarak vereceğiz.

        const newBreakdownType = {
            equipmentLogId: this.state.equipmentLogId,
            equipmentTypeId: this.state.values[this.state.values.length - 1],
            discipline: this.state.discipline,
            group: this.state.group,
            breakdown: this.state.breakdown,
            sourceOfDiagnosis: this.state.sourceOfDiagnosis,
            proposals: itemIdsForDb
        }

        await handleRequest("POST", "/BreakdownType/add", newBreakdownType);

        this.showSuccess();
        this.refreshStates();
        this.restartBreakdownTypeTable();
    }

    refreshStates = () => {
        this.setState({
            editId: 0,
            values: [],
            selectedItems: [],
            itemsIds: [],
            itemsForDb: [],
            discipline: null,
            group: null,
            breakdown: null,
            sourceOfDiagnosis: null,
            equipmentCodes: [],
            equipmentDescriptions: [],
            equipmentLogId: null,
            editableDropdownValue: []
        });
    }

    restartBreakdownTypeTable = async () => {
        const response0 = await handleRequest("GET", "/BreakdownType/getAll");

        if (response0.data.length === 0) {
            this.setState({
                breakdownTypes: []
            });
        }

        if (response0.data.length !== 0) {
            this.setState({
                breakdownTypes: response0.data,
            });
        }

        const response1 = await handleRequest("GET", "/Code/getAll");

        if (response1.data.length !== 0) {

            const columns = response1.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "discipline") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                disciplines: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "group") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                groups: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "breakdown") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                breakdowns: options
            });

            options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "sourceOfDiagnosis") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                sourceOfDiagnosises: options
            });
        }

        const response2 = await handleRequest("GET", "/Proposal/getAll");

        var flagItems = [];
        var flagIds = [];

        if (response2.length === 0 || response2.data.length === 0) {
            this.setState({
                items: []
            });
        } else if (response2.data.length !== 0) {
            for (let index = 0; index < response2.data.length; index++) {
                flagItems.push(response2.data[index].proposalName.toString());
                flagIds.push(response2.data[index].id.toString());
            }

            this.setState({
                items: flagItems,
                itemsIds: flagIds
            });
        }
    }

    onChange = (value, data) => {

        if (value.length > 0) {
            let equipmentTypeIdFlag = value[value.length - 1];

            if (this.state.equipmentLogs1.length === 0) {
                this.setState({
                    equipmentCodes: [],
                    equipmentDescriptions: []
                });
            } else if (this.state.equipmentLogs1.length !== 0) {

                let equipmentLogs = this.state.equipmentLogs1;
                let options = [];
                let options2 = [];

                for (let index = 0; index < equipmentLogs.length; index++) {
                    if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                        options.push({ name: equipmentLogs[index].equipmentCode.toString(), id: equipmentLogs[index].id.toString() });
                    }
                    if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                        options2.push({ name: equipmentLogs[index].equipmentDescription.toString(), id: equipmentLogs[index].id.toString() });
                    }
                }

                this.setState({
                    equipmentCodes: options,
                    equipmentDescriptions: options2
                });
            }
        }

        this.setState({
            values: value,
            selectedItems: data
        })
    }

    handleChangeEquipmentCode = e => {
        e.preventDefault();
        var idValue;
        var equipmentCodeValue;

        for (var z = 0; z < this.state.equipmentCodes.length; z++) {
            if (this.state.equipmentCodes[z].name === e.target.value) {
                idValue = this.state.equipmentCodes[z].id
            }
        }

        for (let index = 0; index < this.state.equipmentLogs1.length; index++) {
            if (this.state.equipmentLogs1[index].id === idValue) {
                equipmentCodeValue = this.state.equipmentLogs1[index].equipmentCode;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            equipmentCode: equipmentCodeValue,
            equipmentLogId: idValue
        });
    }

    handleChangeEquipmentCode = e => {
        e.preventDefault();
        var idValue;
        var equipmentCodeValue;

        for (var z = 0; z < this.state.equipmentCodes.length; z++) {
            if (this.state.equipmentCodes[z].name === e.target.value) {
                idValue = this.state.equipmentCodes[z].id
            }
        }

        for (let index = 0; index < this.state.equipmentLogs1.length; index++) {
            if (this.state.equipmentLogs1[index].id === idValue) {
                equipmentCodeValue = this.state.equipmentLogs1[index].equipmentCode;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            equipmentCode: equipmentCodeValue,
            equipmentLogId: idValue
        });
    }

    onDisciplineChange = e => {
        this.setState({ discipline: e.value });
    }

    onGroupChange = e => {
        this.setState({ group: e.value });
    }

    onBreakdownChange = e => {
        this.setState({ breakdown: e.value });
    }

    onSourceOfDiagnosisChange = e => {
        this.setState({ sourceOfDiagnosis: e.value });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    deleteBreakdownTypeOK = async () => {
        const deletedBreakdownType = {
            id: this.state.editId
        }

        await handleRequest("POST", "/BreakdownType/delete", deletedBreakdownType);

        this.setState({
            deleteModal: false
        });

        this.restartBreakdownTypeTable();
    }

    render() {
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        const { items, name } = this.state;

        let optionsEquipmentCodes = this.state.equipmentCodes.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        )

        let optionsEquipmentDescription = this.state.equipmentDescriptions.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        )

        return (
            <div>
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Breakdown Type Data?
                    </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>Close</Button>
                        <Button variant="danger" onClick={this.deleteBreakdownTypeOK}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Row>
                        <Col xs={8}>
                        </Col>
                        <Col xs={3}>
                            <Button style={{ width: '100%' }} variant={this.state.createButtonVariant} onClick={this.createNew}>{this.state.createButton}</Button>
                        </Col>
                        <Col xs={1}>
                            {/*<Button style={{ width: '100%' }} variant="warning" onClick={this.update}>Update</Button>*/}
                        </Col>
                    </Row>

                    <div style={{ marginTop: '3rem' }} hidden={this.state.hiddenDiv} >
                        <Form style={{ marginBottom: '1rem' }}>

                            <Form.Row>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Equipment Type:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <Cascader style={{ width: '100%' }} value={this.state.values} defaultValue={this.state.values} options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>

                            <Form.Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Equipment:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <Form.Control name="equipmentCode" value={this.state.equipmentCode}
                                        onChange={this.handleChangeEquipmentCode} as="select">
                                        <option value="">
                                            Select Equipment Code
                                        </option>
                                        {optionsEquipmentCodes}
                                    </Form.Control>
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>

                            <Form.Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Discipline:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.discipline} options={this.state.disciplines}
                                        onChange={this.onDisciplineChange} editable={true} placeholder="Select Discipline" />
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>

                            <Form.Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Group:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.group} options={this.state.groups}
                                        onChange={this.onGroupChange} editable={true} placeholder="Select Group" />
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>

                            <Form.Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Breakdown:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.breakdown} options={this.state.breakdowns}
                                        onChange={this.onBreakdownChange} editable={true} placeholder="Select Breakdown" />
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>

                            <Form.Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Source of Diagnosis:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.sourceOfDiagnosis} options={this.state.sourceOfDiagnosises}
                                        onChange={this.onSourceOfDiagnosisChange} editable={true} placeholder="Select Source of Diagnosis" />
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>

                            <Form.Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                </Col>
                                <Col xs={2}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Proposal:</Form.Label>
                                </Col>
                                <Col xs={2}>
                                    <Select
                                        mode="tags"
                                        style={{ width: 255 }}
                                        placeholder="Add Proposal"
                                        value={this.state.editableDropdownValue}
                                        onChange={this.handleChange}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={this.addItem}
                                                    >
                                                        <PlusOutlined /> Add Proposal
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {items.map(item => (
                                            <Option key={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col xs={4}>
                                </Col>
                            </Form.Row>
                        </Form>

                        <div hidden={this.state.hiddenSave}>
                            <Row>
                                <Col xs={3}>
                                </Col>
                                <Col xs={6}>
                                    <Button style={{ width: '100%' }} variant="success" onClick={this.saveBreakdownType} >Save</Button>
                                </Col>
                                <Col xs={3}>
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hiddenUpdate}>
                            <Row>
                                <Col xs={3}>
                                </Col>
                                <Col xs={2}>
                                    <Button style={{ width: '100%' }} variant="danger" onClick={this.buttonCancel}>Cancel</Button>
                                </Col>
                                <Col xs={4}>
                                    <Button style={{ width: '100%' }} variant="warning" onClick={this.updateDone}>Update</Button>
                                </Col>
                                <Col xs={3}>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div style={{ marginTop: '2rem' }} className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable ref={(el) => this.dt = el} value={this.state.breakdownTypes} paginator={true} responsive={true} rowsPerPageOptions={[5, 10, 20]} rows={10}
                            emptyMessage="No Breakdown Type Records Found" >
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '5em' }} />
                            <Column field="index" header="S/N" style={{ textAlign: 'center', width: '5em' }} sortable filter />
                            <Column field="equipmentLog.equipmentDescription" header="Equipment" filter sortable />
                            <Column field="disciplineTypeCode.name" header="Discipline" filter sortable />
                            <Column field="groupTypeCode.name" header="Group" filter sortable />
                            <Column field="breakdownType1Code.name" header="Breakdown" filter sortable />
                            <Column field="sourceOfDiagnosisTypeCode.name" header="Source of Diagnosis" filter sortable />
                            <Column field="proposalNames" header="Proposals" filter sortable />
                        </DataTable>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakdownTypes)