import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Button as AntdButton, Form, Input, Select, InputNumber, DatePicker, Divider, Upload, Checkbox } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class ShippingRelease extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      invoiceDate: null,
      voucherDate: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      shippingReleaseTable: [],
      ReleasedAuthorizedBys: [],
      InvoiceFileUpload: [],
      invoice: "",
      VoucherFileUpload: [],
      voucher: "",
      fileList: [],
      hiddenInvoiceCheck: true,
      hiddenVoucherCheck: true,
      certificateIssuedBys: [],
      inspectionResultClientTable: [],
      inspectionResultClientTableA: [],
      vendors: [],
      vendors2: [],
      vendorsA: [],
      itemDescriptions: [],
      inspectionResultClientId: null,
      vendorId: null,
      vendorDisable: false,
      itemDisable: false,
      packingListCheck: false,
      PackingListFileUpload: [],
      packingList: "",
      testReportCheck: false,
      TestReportFileUpload: [],
      testReport: "",
      manufacturerManualCheck: false,
      ManufacturerManualFileUpload: [],
      manufacturerManual: "",
      requiredDocumentRows: [],
      purchaseOrders: [],
    };
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ShippingRelease" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "ShippingRelease",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ShippingRelease");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
    this.getCodesReleasedAuthorizedBy();
    this.getAssigneds();
  }

  getAssigneds = async () => {
    const response = await handleRequest("GET", "/User/getCompanyItems");
    if (response.data.length !== 0) {
      this.setState({
        certificateIssuedBys: response.data,
      });
    } else {
      this.setState({
        certificateIssuedBys: [],
      });
    }
  }

  getTableProperties = async () => {
    this.ShippingRelease = JSON.parse(localStorage.getItem('ShippingRelease'));
    if (this.ShippingRelease != null && this.ShippingRelease != undefined && this.ShippingRelease != "") {

      await this.setState({
        bordered: this.ShippingRelease.bordered,
        title: this.ShippingRelease.titleDrm == true ? title : undefined,
        titleDrm: this.ShippingRelease.titleDrm,
        showHeader: this.ShippingRelease.showHeader,
        footer: this.ShippingRelease.footerDrm == true ? footer : undefined,
        footerDrm: this.ShippingRelease.footerDrm,
        expandable: this.ShippingRelease.expandableDrm == true ? expandable : {},
        expandableDrm: this.ShippingRelease.expandableDrm,
        rowSelection: this.ShippingRelease.rowSelection,
        ellipsis: this.ShippingRelease.ellipsis,
        tableLayout: this.ShippingRelease.tableLayout,
        size: this.ShippingRelease.size,
        top: this.ShippingRelease.top,
        bottom: this.ShippingRelease.bottom,
        editBgColor: this.ShippingRelease.editBgColor,
        deleteBgColor: this.ShippingRelease.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.ShippingRelease.yScroll,
        xScroll: this.ShippingRelease.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.shippingReleaseTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.shippingReleaseTable.length, showQuickJumper: true,
          position: [this.ShippingRelease.top, this.ShippingRelease.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  }

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  }

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedShippingRelease: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveShippingRelease = async () => {
    let flagShippingReleaseRequiredDocuments = [];

    for (let index = 0; index < this.state.requiredDocumentRows.length; index++) {
      flagShippingReleaseRequiredDocuments.push({
        file: this.state.requiredDocumentRows[index].file,
        checked: this.state.requiredDocumentRows[index].checked,
        description: this.state.requiredDocumentRows[index].description
      });
    }

    const addShippingRelease = {
      date: moment(this.state.date, 'YYYY-MM-DD'),
      dateAck: this.state.date,
      invoiceDate: moment(this.state.invoiceDate, 'YYYY-MM-DD'),
      invoiceDateAck: this.state.invoiceDate,
      voucherDate: moment(this.state.voucherDate, 'YYYY-MM-DD'),
      voucherDateAck: this.state.voucherDate,
      vendorLogId: this.state.vendorId,
      inspectionResultClientId: this.state.inspectionResultClientId,
      quantityReleased: this.state.quantityReleased,
      releasedAuthorizedBy: this.state.releasedAuthorizedBy,
      certificateIssuedBy: this.state.certificateIssuedBy,
      certificateNo: this.state.certificateNo,
      refNo: this.state.refNo,
      invoiceNo: this.state.invoiceNo,
      invoice: this.state.invoice,
      voucherNo: this.state.voucherNo,
      voucher: this.state.voucher,
      packingListCheck: this.state.packingListCheck,
      packingList: this.state.packingList,
      testReportCheck: this.state.testReportCheck,
      testReport: this.state.testReport,
      manufacturerManualCheck: this.state.manufacturerManualCheck,
      manufacturerManual: this.state.manufacturerManual,
      shippingReleaseRequiredDocuments: flagShippingReleaseRequiredDocuments
    }
    var response = await handleRequest("POST", "/ShippingRelease/add", addShippingRelease);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  updateShippingRelease = async () => {
    let flagShippingReleaseRequiredDocuments = [];

    for (let index = 0; index < this.state.requiredDocumentRows.length; index++) {
      flagShippingReleaseRequiredDocuments.push({
        id: this.state.requiredDocumentRows[index].id,
        file: this.state.requiredDocumentRows[index].file,
        checked: this.state.requiredDocumentRows[index].checked,
        description: this.state.requiredDocumentRows[index].description
      });
    }

    const updatedShippingRelease = {
      id: this.state.selectedShippingRelease.id,
      date: moment(this.state.date, 'YYYY-MM-DD'),
      dateAck: this.state.date,
      invoiceDate: moment(this.state.invoiceDate, 'YYYY-MM-DD'),
      invoiceDateAck: this.state.invoiceDate,
      voucherDate: moment(this.state.voucherDate, 'YYYY-MM-DD'),
      voucherDateAck: this.state.voucherDate,
      vendorLogId: this.state.vendorId,
      inspectionResultClientId: this.state.inspectionResultClientId,
      quantityReleased: this.state.quantityReleased,
      releasedAuthorizedBy: this.state.releasedAuthorizedBy,
      certificateIssuedBy: this.state.certificateIssuedBy,
      certificateNo: this.state.certificateNo,
      refNo: this.state.refNo,
      invoiceNo: this.state.invoiceNo,
      invoice: this.state.invoice,
      voucherNo: this.state.voucherNo,
      voucher: this.state.voucher,
      packingListCheck: this.state.packingListCheck,
      packingList: this.state.packingList,
      testReportCheck: this.state.testReportCheck,
      testReport: this.state.testReport,
      manufacturerManualCheck: this.state.manufacturerManualCheck,
      manufacturerManual: this.state.manufacturerManual,
      shippingReleaseRequiredDocuments: flagShippingReleaseRequiredDocuments
    }
    var response2 = await handleRequest("POST", "/ShippingRelease/update", updatedShippingRelease);
    if (response2.data !== null && response2.data !== undefined) {
      if (response2.data === "SUCCESS") {
        this.resetInputs();
        this.restartTable();
        this.showSuccess();
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/ShippingRelease/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        shippingReleaseTable: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        shippingReleaseTable: response.data,
      });
    }

    let shippingReleseaseClientIds = [];
    for (let index = 0; index < response.data.length; index++) {
      shippingReleseaseClientIds.push(response.data[index].inspectionResultClientId);
    }

    const responseResult = await handleRequest("GET", "/InspectionResultClient/getallByCompany");
    if (responseResult.data.length === 0) {
      this.setState({
        inspectionResultClientTable: [],
      });
    }

    let flagResults = [];
    if (responseResult.data.length !== 0) {
      for (let index = 0; index < responseResult.data.length; index++) {
        if ((responseResult.data[index].clientReply === "Approved") && (responseResult.data[index].inspection.includes("FAT"))
          && !(shippingReleseaseClientIds.includes(responseResult.data[index].id)))
          flagResults.push(responseResult.data[index]);
      }
      console.log(flagResults);
      this.setState({
        inspectionResultClientTable: flagResults,
        inspectionResultClientTableA: flagResults
      });
    }

    let flagSelectedVendors = [];
    const responseVendorSelect = await handleRequest("GET", "/VendorEvaluationSelected/getAllByCompany");

    if (responseVendorSelect.data.length !== 0) {
      for (let index = 0; index < responseVendorSelect.data.length; index++) {
        if (responseVendorSelect.data[index].selected) {
          flagSelectedVendors.push(responseVendorSelect.data[index]);
        }
      }
    }

    let flagVendors = [];
    let flagVendors2 = [];
    let flagVendorIds = [];
    for (let index = 0; index < flagResults.length; index++) {
      for (let index1 = 0; index1 < flagSelectedVendors.length; index1++) {
        if ((flagSelectedVendors[index1].rfqId === flagResults[index].assignmentPackage.rfqId)
          && !(flagVendorIds.includes(flagSelectedVendors[index1].vendorLogId))) {
          flagVendors.push({
            id: flagSelectedVendors[index1].vendorLogId,
            rfqId: flagSelectedVendors[index1].rfqId,
            vendorName: flagSelectedVendors[index1].vendorLog.fullName
          });
          flagVendorIds.push(flagSelectedVendors[index1].vendorLogId);
        }

        if (flagSelectedVendors[index1].rfqId === flagResults[index].assignmentPackage.rfqId) {
          flagVendors2.push({
            id: flagSelectedVendors[index1].vendorLogId,
            rfqId: flagSelectedVendors[index1].rfqId,
            vendorName: flagSelectedVendors[index1].vendorLog.fullName
          });
        }
      }
    }

    this.setState({
      vendors: flagVendors,
      vendors2: flagVendors2
    });

    const responsePurchaseOrder = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");
    if (responsePurchaseOrder.data.length === 0) {
      this.setState({
        purchaseOrders: [],
      });
    }

    if (responsePurchaseOrder.data.length !== 0) {
      this.setState({
        purchaseOrders: responsePurchaseOrder.data,
      });
    }
  }

  deleteShippingReleaseOK = async () => {
    const deleteShippingRelease = {
      id: this.state.selectedShippingRelease.id
    }
    var response = await handleRequest("POST", "/ShippingRelease/delete", deleteShippingRelease);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    this.formRef.current.setFieldsValue({
      Date: (row.date != null && row.date != undefined && row.date != "") && moment(row.date, 'YYYY-MM-DD'),
      InvoiceDate: (row.invoiceDate != null && row.invoiceDate != undefined && row.invoiceDate != "") && moment(row.invoiceDate, 'YYYY-MM-DD'),
      VoucherDate: (row.voucherDate != null && row.voucherDate != undefined && row.voucherDate != "") && moment(row.voucherDate, 'YYYY-MM-DD'),
      Vendor: row.vendorLog.fullName,
      ItemDescription: row.inspectionResultClient.assignmentPackage.requestForQuotation.projectTakeOffList.description + " - " + row.inspectionResultClient.inspection,
      QuantityReleased: row.quantityReleased,
      ReleasedAuthorizedBy: row.releasedAuthorizedBy,
      CertificateIssuedBy: row.certificateIssuedBy,
      CertificateNo: row.certificateNo,
      RefNo: row.refNo,
      InvoiceNo: row.invoiceNo,
      Invoice: row.invoice,
      VoucherNo: row.voucherNo,
      Voucher: row.voucher,
    });

    var hiddenInvoiceCheck = true;
    if ((row.invoiceDate != null && row.invoiceDate != undefined && row.invoiceDate != "") ||
      (row.invoiceNo != null && row.invoiceNo != undefined && row.invoiceNo != "") ||
      (row.invoice != null && row.invoice != undefined && row.invoice != "")) {
      hiddenInvoiceCheck = false;
    }

    var hiddenVoucherCheck = true;
    if ((row.voucherDate != null && row.voucherDate != undefined && row.voucherDate != "") ||
      (row.voucherNo != null && row.voucherNo != undefined && row.voucherNo != "") ||
      (row.voucher != null && row.voucher != undefined && row.voucher != "")) {
      hiddenVoucherCheck = false;
    }

    let flagShippingReleaseRequiredDocuments = [];

    for (let index = 0; index < row.shippingReleaseRequiredDocuments.length; index++) {
      flagShippingReleaseRequiredDocuments.push({
        id: row.shippingReleaseRequiredDocuments[index].id,
        file: row.shippingReleaseRequiredDocuments[index].file,
        fileUpload: [],
        checked: row.shippingReleaseRequiredDocuments[index].checked,
        description: row.shippingReleaseRequiredDocuments[index].description
      });
    }

    await this.setState({
      selectedShippingRelease: row,
      date: row.date,
      invoiceDate: row.invoiceDate,
      voucherDate: row.voucherDate,
      vendor: row.vendor,
      itemDescription: row.itemDescription,
      quantityReleased: row.quantityReleased,
      releasedAuthorizedBy: row.releasedAuthorizedBy,
      certificateIssuedBy: row.certificateIssuedBy,
      certificateNo: row.certificateNo,
      refNo: row.refNo,
      invoiceNo: row.invoiceNo,
      invoice: row.invoice,
      voucherNo: row.voucherNo,
      voucher: row.voucher,
      hiddenInvoiceCheck: hiddenInvoiceCheck,
      hiddenVoucherCheck: hiddenVoucherCheck,
      inspectionResultClientId: row.inspectionResultClientId,
      vendorId: row.vendorLogId,
      vendorDisable: true,
      itemDisable: true,
      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
      packingListCheck: row.packingListCheck,
      packingList: row.packingList,
      testReportCheck: row.testReportCheck,
      testReport: row.testReport,
      manufacturerManualCheck: row.manufacturerManualCheck,
      manufacturerManual: row.manufacturerManual,
      requiredDocumentRows: flagShippingReleaseRequiredDocuments
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      Date: "",
      InvoiceDate: "",
      VoucherDate: "",
      Vendor: "",
      ItemDescription: "",
      ReleasedAuthorizedBy: "",
      QuantityReleased: "",
      CertificateIssuedBy: "",
      RefNo: "",
      InvoiceNo: "",
      VoucherNo: "",
      Invoice: "",
      Voucher: "",
      CertificateNo: "",
    });

    this.setState({
      selectedShippingRelease: [],
      date: "",
      invoiceDate: "",
      voucherDate: "",
      vendor: "",
      itemDescription: "",
      releasedAuthorizedBy: "",
      quantityReleased: "",
      certificateIssuedBy: "",
      refNo: "",
      invoiceNo: "",
      invoice: "",
      InvoiceFileUpload: [],
      fileList: [],
      voucherNo: "",
      voucher: "",
      VoucherFileUpload: [],
      certificateNo: "",
      hiddenInvoiceCheck: true,
      hiddenVoucherCheck: true,
      inspectionResultClientTableA: this.state.inspectionResultClientTable,
      vendorsA: this.state.vendors,
      inspectionResultClientId: null,
      vendorId: null,
      vendorDisable: false,
      itemDisable: false,
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      packingListCheck: false,
      packingList: "",
      PackingListFileUpload: [],
      testReportCheck: false,
      testReport: "",
      TestReportFileUpload: [],
      manufacturerManualCheck: false,
      manufacturerManual: "",
      ManufacturerManualFileUpload: [],
      requiredDocumentRows: [],
    });
  }

  changeButtonCancel = () => {
    this.resetInputs();

    this.setState({
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  }

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeDate = (value, dateString) => {
    this.setState({
      date: dateString,
    });
  }

  onChangeInvoiceDate = (value, invoiceDateString) => {
    this.setState({
      invoiceDate: invoiceDateString,
    });
  }

  onChangeVoucherDate = (value, voucherDateString) => {
    this.setState({
      voucherDate: voucherDateString,
    });
  }

  disabledDate(current) {
    return moment().add(-1, 'days').endOf('day') > current;
  }

  handleChangeReleasedAuthorizedBy = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.ReleasedAuthorizedBys.length; z++) {
      if (this.state.ReleasedAuthorizedBys[z].id == e) {
        deger = this.state.ReleasedAuthorizedBys[z].name;
      }
    }
    this.setState({
      releasedAuthorizedByAck: deger,
      releasedAuthorizedBy: e,
    });
  }

  addReleasedAuthorizedBys = async () => {
    const { itemReleasedAuthorizedBys } = this.state;
    if (itemReleasedAuthorizedBys != null && itemReleasedAuthorizedBys != "" && itemReleasedAuthorizedBys != undefined) {
      const newItem = {
        name: itemReleasedAuthorizedBys,
        type: "ReleasedAuthorizedBy"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemReleasedAuthorizedBys: '',
      });
      this.getCodesReleasedAuthorizedBy();
    } else {
      this.showWarning();
    }
  }

  getCodesReleasedAuthorizedBy = async () => {
    const response = await handleRequest("GET", "/Code/type/ReleasedAuthorizedBy");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        ReleasedAuthorizedBys: response.data
      });
    }
  }

  showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.pdf')) {
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true,
        });
      } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      } else {
        window.location.href = fileRealUrl;
      }
    } catch (error) {
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
    }
  }

  onCertificateIssuedBy = (value) => {
    this.setState({
      certificateIssuedBy: value,
    });
  }

  handleChangeVendor = (e) => {
    let flagItems = [];
    let flagItemInspections = [];
    for (let index = 0; index < this.state.inspectionResultClientTable.length; index++) {
      for (let index2 = 0; index2 < this.state.vendors2.length; index2++) {
        if ((this.state.vendors2[index2].rfqId === this.state.inspectionResultClientTable[index].assignmentPackage.requestForQuotation.id)
          && (this.state.vendors2[index2].id === e)
          && !(flagItemInspections.includes(this.state.inspectionResultClientTable[index].assignmentPackage.requestForQuotation.projectTakeOffList.description
            + " - " + this.state.inspectionResultClientTable[index].inspection))) {
          flagItems.push(this.state.inspectionResultClientTable[index]);
          flagItemInspections.push(this.state.inspectionResultClientTable[index].assignmentPackage.requestForQuotation.projectTakeOffList.description
            + " - " + this.state.inspectionResultClientTable[index].inspection);
        }
      }
    }

    this.setState({
      vendorId: e,
      inspectionResultClientTableA: flagItems
    });
  }

  handleChangeInspectionResultClient = (e) => {
    let flagVendors = [];
    let flagVendorIds = [];
    let selectedIRCT = null;

    let selectedIRCTIndex = this.state.inspectionResultClientTable.findIndex(p => p.id === e);
    if (selectedIRCTIndex !== -1) selectedIRCT = this.state.inspectionResultClientTable[selectedIRCTIndex];

    if (selectedIRCT !== null) {
      //let vendorLogLength = selectedIRCT.assignmentPackage.requestForQuotation.vendorLogs.length;
      for (let index = 0; index < this.state.vendors2.length; index++) {
        if ((this.state.vendors2[index] !== undefined) && (this.state.vendors2[index].rfqId === selectedIRCT.assignmentPackage.requestForQuotation.id) && !(flagVendorIds.includes(this.state.vendors2[index].id))) {
          flagVendors.push({
            id: this.state.vendors2[index].id,
            rfqId: this.state.vendors2[index].rfqId,
            vendorName: this.state.vendors2[index].vendorName
          });
          flagVendorIds.push(this.state.vendors2[index].id);
        }
      }

      for (let index = 0; index < this.state.purchaseOrders.length; index++) {
        if (selectedIRCT.assignmentPackage.requestForQuotation.id === this.state.purchaseOrders[index].rfqId) {
          if (this.state.purchaseOrders[index].invoice !== undefined && this.state.purchaseOrders[index].invoice !== null
            && this.state.purchaseOrders[index].deliveryNote !== undefined && this.state.purchaseOrders[index].deliveryNote !== null
            && this.state.purchaseOrders[index].packingList !== undefined && this.state.purchaseOrders[index].packingList !== null
            && this.state.purchaseOrders[index].testReport !== undefined && this.state.purchaseOrders[index].testReport !== null
            && this.state.purchaseOrders[index].manufacturerManual !== undefined && this.state.purchaseOrders[index].manufacturerManual !== null
            && this.state.purchaseOrders[index].purchaseOrderRequiredDocuments !== undefined && this.state.purchaseOrders[index].purchaseOrderRequiredDocuments !== null) {

            let _requiredDocuments = [];

            for (let index1 = 0; index1 < this.state.purchaseOrders[index].purchaseOrderRequiredDocuments.length; index1++) {
              if (this.state.purchaseOrders[index].purchaseOrderRequiredDocuments[index1].checked)
                _requiredDocuments.push({
                  requiredDocumentIndex: _requiredDocuments.length + 1,
                  description: this.state.purchaseOrders[index].purchaseOrderRequiredDocuments[index1].description,
                  checked: this.state.purchaseOrders[index].purchaseOrderRequiredDocuments[index1].checked,
                  file: "",
                  fileUpload: []
                });
            }

            this.setState({
              hiddenInvoiceCheck: !(this.state.purchaseOrders[index].invoice),
              hiddenVoucherCheck: !(this.state.purchaseOrders[index].deliveryNote),
              packingListCheck: this.state.purchaseOrders[index].packingList,
              testReportCheck: this.state.purchaseOrders[index].testReport,
              manufacturerManualCheck: this.state.purchaseOrders[index].manufacturerManual,
              requiredDocumentRows: _requiredDocuments
            });
          }
        }
      }
    }

    this.setState({
      inspectionResultClientId: e,
      vendorsA: flagVendors
    });
  }

  render() {
    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };

    var itemDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Date");
    var itemInvoiceDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InvoiceDate");
    var itemVoucherDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "VoucherDate");
    var itemVendorAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Vendor");
    var itemItemDescriptionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ItemDescription");
    var itemReleasedAuthorizedByAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ReleasedAuthorizedBy");
    var itemQuantityReleasedAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "QuantityReleased");
    var itemCertificateIssuedByAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CertificateIssuedBy");
    var itemRefNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RefNo");
    var itemInvoiceNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InvoiceNo");
    var itemVoucherNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "VoucherNo");
    var itemInvoiceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Invoice");
    var itemVoucherAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Voucher");
    var itemCertificateNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CertificateNo");
    var itemVoucherCheckAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "VoucherCheck");
    var itemInvoiceCheckAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InvoiceCheck");

    var itemDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "Date");
    var itemInvoiceDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "InvoiceDate");
    var itemVoucherDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "VoucherDate");
    var itemVendorRequired = this.state.dynamicInputs.find(p => p.inputKey === "Vendor");
    var itemItemDescriptionRequired = this.state.dynamicInputs.find(p => p.inputKey === "ItemDescription");
    var itemReleasedAuthorizedByRequired = this.state.dynamicInputs.find(p => p.inputKey === "ReleasedAuthorizedBy");
    var itemQuantityReleasedRequired = this.state.dynamicInputs.find(p => p.inputKey === "QuantityReleased");
    var itemCertificateIssuedByRequired = this.state.dynamicInputs.find(p => p.inputKey === "CertificateIssuedBy");
    var itemRefNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "RefNo");
    var itemInvoiceNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "InvoiceNo");
    var itemVoucherNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "VoucherNo");
    var itemInvoiceRequired = this.state.dynamicInputs.find(p => p.inputKey === "Invoice");
    var itemVoucherRequired = this.state.dynamicInputs.find(p => p.inputKey === "Voucher");
    var itemCertificateNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "CertificateNo");
    var itemVoucherCheckRequired = this.state.dynamicInputs.find(p => p.inputKey === "VoucherCheck");
    var itemInvoiceCheckRequired = this.state.dynamicInputs.find(p => p.inputKey === "InvoiceCheck");

    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("ShippingRelease")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "date",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseDate" defaultMessage="Date" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="ShippingReleaseVendor" defaultMessage="Vendor" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.vendorLog !== undefined)
              return data.vendorLog.fullName;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="ShippingReleaseItemDescription" defaultMessage="Item Description" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data.inspectionResultClient !== undefined)
              return data.inspectionResultClient.assignmentPackage.requestForQuotation.projectTakeOffList.description + " - " + data.inspectionResultClient.inspection;
          }
          return "";
        }
      },
      {
        dataIndex: "codeReleasedAuthorizedBy",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseReleasedAuthorizedBy" defaultMessage="Released Authorized By" />,
        render: (data) => {
          if (data != null && data != undefined) {
            return data.name;
          }
          return "";
        }
      },
      {
        dataIndex: "userCertificateIssuedBy",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseCertificateIssuedBy" defaultMessage="Certificate Issued By" />,
        render: (data) => {
          if (data != null && data != undefined) {
            return data.staff.name + " " + data.staff.lastName;
          }
          return "";
        }
      },
      {
        dataIndex: "certificateNo",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseCertificateNo" defaultMessage="Certificate No" />,
      },
      {
        dataIndex: "refNo",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseRefNo" defaultMessage="Ref No" />,
      },
      {
        dataIndex: "invoiceDate",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseInvoiceDate" defaultMessage="Invoice Date" />,
      },
      {
        dataIndex: "invoiceNo",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseInvoice" defaultMessage="Invoice No" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="ShippingReleaseInvoice" defaultMessage="Invoice" />,
        render: (data) => {
          if (data != null && data != undefined && data.invoice != null && data.invoice != undefined && data.invoice != "") {
            return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(data.invoice)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
          }
          return "";
        }
      },
      {
        dataIndex: "voucherDate",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseVoucherDate" defaultMessage="Voucher Date" />,
      },
      {
        dataIndex: "voucherNo",
        key: "key",
        title: <FormattedMessage id="ShippingReleaseVoucherNo" defaultMessage="Voucher No" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="ShippingReleaseVoucher" defaultMessage="Voucher" />,
        render: (data) => {
          if (data != null && data != undefined && data.voucher != null && data.voucher != undefined && data.voucher != "") {
            return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(data.voucher)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
          }
          return "";
        }
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 6,
        },
      },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateShippingRelease : this.saveShippingRelease}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("ShippingRelease")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />

              {(itemDateAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseDate" defaultMessage="Date" />}
                  name="Date"
                  rules={[{ required: itemDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateRequired.translateRequired) == true ? itemDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                  <DatePicker disabledDate={this.disabledDate} value={moment(this.state.date, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDate} />
                </Form.Item>}

              {(itemVendorAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseVendor" defaultMessage="Vendor" />}
                  name="Vendor"
                  rules={[{ required: itemVendorRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemVendorRequired.translateRequired) == true ? itemVendorRequired.translateRequired : -1} defaultMessage="Please input your Vendor!" /> }]} >
                  <Select name="Vendor"
                    disabled={this.state.vendorDisable}
                    style={{ width: '100%' }}
                    value={this.state.vendorId}
                    onChange={this.handleChangeVendor}>
                    {this.state.vendorsA.map(i => (<Option key={i.id} value={i.id}>{i.vendorName}</Option>))}
                  </Select>
                </Form.Item>}

              {(itemItemDescriptionAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseItemDescription" defaultMessage="Item Description" />}
                  name="ItemDescription"
                  rules={[{ required: itemItemDescriptionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemItemDescriptionRequired.translateRequired) == true ? itemItemDescriptionRequired.translateRequired : -1} defaultMessage="Please input your Item Description!" /> }]} >
                  <Select name="ItemDescription"
                    disabled={this.state.itemDisable}
                    style={{ width: '100%' }}
                    value={this.state.inspectionResultClientId}
                    onChange={this.handleChangeInspectionResultClient}>
                    {this.state.inspectionResultClientTableA.map(i => (
                      <Option key={i.id} value={i.id}>{i.assignmentPackage.requestForQuotation.projectTakeOffList.description + " - " + i.inspection}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemQuantityReleasedAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseQuantityReleased" defaultMessage="Quantity Released" />}
                  name="QuantityReleased"
                  rules={[{ required: itemQuantityReleasedRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemQuantityReleasedRequired.translateRequired) == true ? itemQuantityReleasedRequired.translateRequired : -1} defaultMessage="Please input your Quantity Released!" /> }]} >
                  <InputNumber id="ShippingReleaseQuantityReleased" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ quantityReleased: e })} value={this.state.quantityReleased} />
                </Form.Item>}

              {(itemReleasedAuthorizedByAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseReleasedAuthorizedBy" defaultMessage="Released Authorized By" />}
                  name="ReleasedAuthorizedBy"
                  rules={[{ required: itemReleasedAuthorizedByRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemReleasedAuthorizedByRequired.translateRequired) == true ? itemReleasedAuthorizedByRequired.translateRequired : -1} defaultMessage="Please input your Released Authorized By!" /> }]} >
                  <Select
                    name="releasedAuthorizedBy"
                    style={{ width: '100%' }}
                    value={this.state.releasedAuthorizedByAck}
                    onChange={this.handleChangeReleasedAuthorizedBy}

                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.itemReleasedAuthorizedBys}
                            onChange={(event) => { this.setState({ itemReleasedAuthorizedBys: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addReleasedAuthorizedBys} >
                            <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                        </div>
                      </div>
                    )}>
                    {this.state.ReleasedAuthorizedBys.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemCertificateIssuedByAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseCertificateIssuedBy" defaultMessage="Certificate Issued By" />}
                  name="CertificateIssuedBy"
                  rules={[{ required: itemCertificateIssuedByRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCertificateIssuedByRequired.translateRequired) == true ? itemCertificateIssuedByRequired.translateRequired : -1} defaultMessage="Please input your Certificate Issued By!" /> }]} >
                  <Select size='large'
                    style={{ width: '100%' }}
                    value={this.state.certificateIssuedBy}
                    onChange={this.onCertificateIssuedBy}
                  >
                    {this.state.certificateIssuedBys.map(user => (
                      (user.staff != null && user.staff != undefined) &&
                      <Option key={user.id} value={user.id}>{
                        user.staff.name + " " +
                        user.staff.lastName
                      }</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemCertificateNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseCertificateNo" defaultMessage="Certificate No" />}
                  name="CertificateNo"
                  rules={[{ required: itemCertificateNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCertificateNoRequired.translateRequired) == true ? itemCertificateNoRequired.translateRequired : -1} defaultMessage="Please input your Certificate No!" /> }]} >
                  <Input id="CertificateNo" value={this.state.certificateNo} onChange={(e) =>
                    this.setState({ certificateNo: e.target.value })} />
                </Form.Item>}

              {(itemRefNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseRefNo" defaultMessage="Ref No" />}
                  name="RefNo"
                  rules={[{ required: itemRefNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRefNoRequired.translateRequired) == true ? itemRefNoRequired.translateRequired : -1} defaultMessage="Please input your Ref No!" /> }]} >
                  <Input id="RefNo" value={this.state.refNo} onChange={(e) =>
                    this.setState({ refNo: e.target.value })} />
                </Form.Item>}

              {(itemInvoiceCheckAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseInvoiceCheck" defaultMessage="Invoice" />}
                  name="InvoiceCheck"
                  rules={[{ required: itemInvoiceCheckRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInvoiceCheckRequired.translateRequired) == true ? itemInvoiceCheckRequired.translateRequired : -1} defaultMessage="Please input your Invoice!" /> }]} >
                  <Checkbox checked={!this.state.hiddenInvoiceCheck} onChange={(e) => {
                    var hiddenInvoiceCheck = false;
                    if (e.target.checked == false) {
                      hiddenInvoiceCheck = true;
                      this.setState({
                        invoiceDate: null,
                        invoiceNo: "",
                        invoice: "",
                        InvoiceFileUpload: [],
                        fileList: [],
                      });
                      this.formRef.current.setFieldsValue({
                        InvoiceDate: "",
                        InvoiceNo: "",
                        Invoice: "",
                      });
                    }
                    this.setState({
                      hiddenInvoiceCheck: hiddenInvoiceCheck,
                    });
                  }} style={{ color: 'black', marginTop: '0.25rem' }}>{<FormattedMessage id="ShippingReleaseInvoice" defaultMessage="Invoice" />}</Checkbox>
                </Form.Item>}

              {(itemInvoiceDateAttributes.visible && this.state.hiddenInvoiceCheck == false) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseInvoiceDate" defaultMessage="Invoice Date" />}
                  name="InvoiceDate"
                  rules={[{ required: itemInvoiceDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInvoiceDateRequired.translateRequired) == true ? itemInvoiceDateRequired.translateRequired : -1} defaultMessage="Please input your Invoice Date!" /> }]} >
                  <DatePicker value={moment(this.state.invoiceDate, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeInvoiceDate} />
                </Form.Item>}

              {(itemInvoiceNoAttributes.visible && this.state.hiddenInvoiceCheck == false) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseInvoiceNo" defaultMessage="Invoice No" />}
                  name="InvoiceNo"
                  rules={[{ required: itemInvoiceNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInvoiceNoRequired.translateRequired) == true ? itemInvoiceNoRequired.translateRequired : -1} defaultMessage="Please input your Invoice No!" /> }]} >
                  <Input id="InvoiceNo" value={this.state.invoiceNo} onChange={(e) =>
                    this.setState({ invoiceNo: e.target.value })} />
                </Form.Item>}

              {(itemInvoiceAttributes.visible && this.state.hiddenInvoiceCheck == false) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseInvoice" defaultMessage="Invoice" />}
                  name="Invoice"
                  rules={[{ required: itemInvoiceRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInvoiceRequired.translateRequired) == true ? itemInvoiceRequired.translateRequired : -1} defaultMessage="Please input your Invoice!" /> }]} >
                  <Upload {...props}
                    showUploadList={true}
                    fileList={this.state.InvoiceFileUpload}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        var invoice = "";
                        if (Boolean(fileList[0].response)) {
                          invoice = fileList[0].response.url;
                        }
                        this.setState({
                          InvoiceFileUpload: fileList,
                          invoice: invoice,
                        });
                      }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.InvoiceFileUpload.length > 0) {
                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        InvoiceFileUpload: [],
                        invoice: "",
                      })
                    }
                    }>
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                    </AntdButton>
                  </Upload>

                  {(this.state.invoice != null && this.state.invoice != undefined && this.state.invoice != "") &&
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }} onClick={() => this.showReport(this.state.invoice)} type="primary" shape="round" icon={<DownloadOutlined />} >
                    </AntdButton>}
                </Form.Item>}

              {(itemVoucherCheckAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseVoucherCheck" defaultMessage="Delivery Note" />}
                  name="VoucherCheck"
                  rules={[{ required: itemVoucherCheckRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemVoucherCheckRequired.translateRequired) == true ? itemVoucherCheckRequired.translateRequired : -1} defaultMessage="Please input your Voucher!" /> }]} >
                  <Checkbox checked={!this.state.hiddenVoucherCheck} onChange={(e) => {
                    var hiddenVoucherCheck = false;
                    if (e.target.checked == false) {
                      hiddenVoucherCheck = true;
                      this.setState({
                        voucherDate: null,
                        voucherNo: "",
                        voucher: "",
                        VoucherFileUpload: [],
                        fileList: [],
                      });
                      this.formRef.current.setFieldsValue({
                        VoucherDate: "",
                        VoucherNo: "",
                        Voucher: "",
                      });
                    }
                    this.setState({
                      hiddenVoucherCheck: hiddenVoucherCheck,
                    });
                  }} style={{ color: 'black', marginTop: '0.25rem' }}>{<FormattedMessage id="ShippingReleaseVoucher" defaultMessage="Delivery Note" />}</Checkbox>
                </Form.Item>}

              {(itemVoucherDateAttributes.visible && this.state.hiddenVoucherCheck == false) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseVoucherDate" defaultMessage="Delivery Date" />}
                  name="VoucherDate"
                  rules={[{ required: itemVoucherDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemVoucherDateRequired.translateRequired) == true ? itemVoucherDateRequired.translateRequired : -1} defaultMessage="Please input your Voucher Date!" /> }]} >
                  <DatePicker value={moment(this.state.voucherDate, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeVoucherDate} />
                </Form.Item>}

              {(itemVoucherNoAttributes.visible && this.state.hiddenVoucherCheck == false) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseVoucherNo" defaultMessage="Delivery No" />}
                  name="VoucherNo"
                  rules={[{ required: itemVoucherNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemVoucherNoRequired.translateRequired) == true ? itemVoucherNoRequired.translateRequired : -1} defaultMessage="Please input your Voucher No!" /> }]} >
                  <Input id="VoucherNo" value={this.state.voucherNo} onChange={(e) =>
                    this.setState({ voucherNo: e.target.value })} />
                </Form.Item>}

              {(itemVoucherAttributes.visible && this.state.hiddenVoucherCheck == false) &&
                <Form.Item
                  label={<FormattedMessage id="ShippingReleaseVoucher" defaultMessage="Delivery" />}
                  name="Voucher"
                  rules={[{ required: itemVoucherRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemVoucherRequired.translateRequired) == true ? itemVoucherRequired.translateRequired : -1} defaultMessage="Please input your Voucher!" /> }]} >
                  <Upload {...props}
                    showUploadList={true}
                    fileList={this.state.VoucherFileUpload}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        var voucher = "";
                        if (Boolean(fileList[0].response)) {
                          voucher = fileList[0].response.url;
                        }
                        this.setState({
                          VoucherFileUpload: fileList,
                          voucher: voucher,
                        });
                      }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.VoucherFileUpload.length > 0) {
                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon." />);
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        VoucherFileUpload: [],
                        voucher: "",
                      })
                    }
                    }>
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                    </AntdButton>
                  </Upload>
                  {(this.state.voucher != null && this.state.voucher != undefined && this.state.voucher != "") &&
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }} onClick={() => this.showReport(this.state.voucher)} type="primary" shape="round" icon={<DownloadOutlined />} >
                    </AntdButton>}
                </Form.Item>}

              <Form.Item
                label={<FormattedMessage id="ShippingReleaseRequiredDocuments" defaultMessage="Required Documents" />}
                name="ShippingReleaseRequiredDocuments"
                style={{ marginBottom: "0px" }}>
              </Form.Item>

              {this.state.packingListCheck && <Form.Item
                {...tailFormItemLayout}>
                <FormBoot.Label style={{ color: 'black', marginRight: "0.5rem" }}><FormattedMessage id="ShippingReleaseRequiredDocumentsPackingList" defaultMessage="Packing List" /></FormBoot.Label>
                <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.PackingListFileUpload}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      var packingList = "";
                      if (Boolean(fileList[0].response)) {
                        packingList = fileList[0].response.url;
                      }
                      this.setState({
                        PackingListFileUpload: fileList,
                        packingList: packingList,
                      });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.PackingListFileUpload.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      PackingListFileUpload: [],
                      packingList: "",
                    })
                  }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                  </AntdButton>
                </Upload>
                {(this.state.packingList != null && this.state.packingList != undefined && this.state.packingList != "") &&
                  <AntdButton style={{ width: "50%", marginTop: "0.2rem" }} onClick={() => this.showReport(this.state.packingList)} type="primary" shape="round" icon={<DownloadOutlined />} >
                  </AntdButton>}
              </Form.Item>}

              {this.state.testReportCheck && <Form.Item
                {...tailFormItemLayout}>
                <FormBoot.Label style={{ color: 'black', marginRight: "0.5rem" }}><FormattedMessage id="ShippingReleaseRequiredDocumentsTestReport" defaultMessage="Test Report" /></FormBoot.Label>
                <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.TestReportFileUpload}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      var testReport = "";
                      if (Boolean(fileList[0].response)) {
                        testReport = fileList[0].response.url;
                      }
                      this.setState({
                        TestReportFileUpload: fileList,
                        testReport: testReport,
                      });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.TestReportFileUpload.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      TestReportFileUpload: [],
                      testReport: "",
                    })
                  }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                  </AntdButton>
                </Upload>
                {(this.state.testReport != null && this.state.testReport != undefined && this.state.testReport != "") &&
                  <AntdButton style={{ width: "50%", marginTop: "0.2rem" }} onClick={() => this.showReport(this.state.testReport)} type="primary" shape="round" icon={<DownloadOutlined />} >
                  </AntdButton>}
              </Form.Item>}

              {this.state.manufacturerManualCheck && <Form.Item
                {...tailFormItemLayout}>
                <FormBoot.Label style={{ color: 'black', marginRight: "0.5rem" }}><FormattedMessage id="ShippingReleaseRequiredDocumentsManufacturerManual" defaultMessage="Manufacturer Manual" /></FormBoot.Label>
                <Upload {...props}
                  showUploadList={true}
                  fileList={this.state.ManufacturerManualFileUpload}
                  onChange={({ fileList }) => {
                    if (fileList.length == 1) {
                      var manufacturerManual = "";
                      if (Boolean(fileList[0].response)) {
                        manufacturerManual = fileList[0].response.url;
                      }
                      this.setState({
                        ManufacturerManualFileUpload: fileList,
                        manufacturerManual: manufacturerManual,
                      });
                    }
                  }}
                  beforeUpload={(fileList) => {
                    if (this.state.ManufacturerManualFileUpload.length > 0) {
                      this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                      return false;
                    }
                    return true;
                  }}
                  onRemove={({ fileList }) => {
                    this.setState({
                      ManufacturerManualFileUpload: [],
                      manufacturerManual: "",
                    })
                  }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                  </AntdButton>
                </Upload>
                {(this.state.manufacturerManual != null && this.state.manufacturerManual != undefined && this.state.manufacturerManual != "") &&
                  <AntdButton style={{ width: "50%", marginTop: "0.2rem" }} onClick={() => this.showReport(this.state.manufacturerManual)} type="primary" shape="round" icon={<DownloadOutlined />} >
                  </AntdButton>}
              </Form.Item>}

              {this.state.requiredDocumentRows.map((item, index) => (
                <Form.Item
                  {...tailFormItemLayout}>
                  <FormBoot.Label style={{ color: 'black', marginRight: "0.5rem" }}>{item.description}</FormBoot.Label>
                  <Upload {...props}
                    showUploadList={true}
                    fileList={item.fileUpload}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        var item = "";
                        if (Boolean(fileList[0].response)) {
                          item = fileList[0].response.url;
                        }

                        let list = this.state.requiredDocumentRows;
                        list[index].fileUpload = fileList;
                        list[index].file = item;
                        this.setState({
                          requiredDocumentRows: list.slice()
                        });
                      }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.requiredDocumentRows[index].fileUpload.length > 0) {
                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a file! For change firstly remove the uploaded file." />);
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      let list = this.state.requiredDocumentRows;
                      list[index].fileUpload = [];
                      list[index].file = "";
                      this.setState({
                        requiredDocumentRows: list.slice()
                      });
                    }
                    }>
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> <FormattedMessage id="GeneralUploadFile" defaultMessage="Upload File" />
                    </AntdButton>
                  </Upload>
                  {(this.state.requiredDocumentRows[index].file != null && this.state.requiredDocumentRows[index].file != undefined && this.state.requiredDocumentRows[index].file != "") &&
                    <AntdButton style={{ width: "50%", marginTop: "0.2rem" }} onClick={() => this.showReport(this.state.requiredDocumentRows[index].file)} type="primary" shape="round" icon={<DownloadOutlined />} >
                    </AntdButton>}
                </Form.Item>
              ))}

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3">
                    </Col>
                    <Col sm="5">
                      <Button id="ShippingReleaseSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="2">
                      <Button id="ShippingReleaseCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="3">
                      <Button id="ShippingReleaseUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.shippingReleaseTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>}

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteShippingReleaseOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingRelease)