/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "Mrm";

export class Mrm extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            //[STATE_FIELDS],
            mrm: null,
            plannedDate: null,
            actualDate: null,
            status: null,
            reportSubmittalDate: null,
            remarks: null,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        //[SAVE_OBJECT]
        let obj = {
            id: this.state.id,
            mrm: this.state.mrm,
            plannedDate: this.state.plannedDate,
            actualDate: this.state.actualDate,
            status: this.state.status,
            reportSubmittalDate: this.state.reportSubmittalDate,
            remarks: this.state.remarks,
        };
        //SAVE_OBJECT kodlandığında kaldırılacak
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ManagementReviewMeeting/add", obj);
        else
            await handleRequest("POST", "/ManagementReviewMeeting/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            mrm: row.mrm,
            plannedDate: Boolean(row.plannedDate) ? moment(row.plannedDate) : null,
            actualDate: Boolean(row.actualDate) ? moment(row.actualDate) : null,
            status: row.status,
            reportSubmittalDate: Boolean(row.reportSubmittalDate) ? moment(row.reportSubmittalDate) : null,
            remarks: row.remarks,
        });

        this.setState({
            //  [EDIT_STATE_FIELDS], 
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            mrm: row.mrm,
            plannedDate: Boolean(row.plannedDate) ? moment(row.plannedDate) : null,
            actualDate: Boolean(row.actualDate) ? moment(row.actualDate) : null,
            status: row.status,
            reportSubmittalDate: Boolean(row.reportSubmittalDate) ? moment(row.reportSubmittalDate) : null,
            remarks: row.remarks,

        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ManagementReviewMeeting/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/ManagementReviewMeeting/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            mrm: null,
            plannedDate: null,
            actualDate: null,
            status: null,
            reportSubmittalDate: null,
            remarks: null,

        });

        this.setState({
            // [RESETINPUTS_STATES],
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            mrm: null,
            plannedDate: null,
            actualDate: null,
            status: null,
            reportSubmittalDate: null,
            remarks: null,

        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "mrm.mrm").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="mrm.mrm" defaultMessage="MRM" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="mrm"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.mrm")) ? this.state.dynamicInputs.find(p => p.inputKey === "mrm.mrm").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.mrm")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "mrm.mrm").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="MRM" style={{ width: "100%" }} id="mrm.mrm" value={this.state.mrm} onChange={(e) => { this.setState({ mrm: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "mrm.pd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="mrm.pd" defaultMessage="Planned Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="plannedDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.pd")) ? this.state.dynamicInputs.find(p => p.inputKey === "mrm.pd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.pd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "mrm.pd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="mrm.pd" format="DD-MM-YYYY" value={this.state.plannedDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                plannedDate: date
                                                            });
                                                            this.setState({
                                                                plannedDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "mrm.ad").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="mrm.ad" defaultMessage="Actual Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="actualDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.ad")) ? this.state.dynamicInputs.find(p => p.inputKey === "mrm.ad").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.ad")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "mrm.ad").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="mrm.ad" format="DD-MM-YYYY" value={this.state.actualDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                actualDate: date
                                                            });
                                                            this.setState({
                                                                actualDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "mrm.status").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="mrm.status" defaultMessage="Status" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="status"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.status")) ? this.state.dynamicInputs.find(p => p.inputKey === "mrm.status").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.status")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "mrm.status").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Status" style={{ width: "100%" }} id="mrm.status" value={this.state.status} onChange={(e) => { this.setState({ status: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "mrm.rsd").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="mrm.rsd" defaultMessage="Report Submittal Date" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="reportSubmittalDate"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.rsd")) ? this.state.dynamicInputs.find(p => p.inputKey === "mrm.rsd").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.rsd")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "mrm.rsd").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="mrm.rsd" format="DD-MM-YYYY" value={this.state.reportSubmittalDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                reportSubmittalDate: date
                                                            });
                                                            this.setState({
                                                                reportSubmittalDate: date
                                                            })
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "mrm.remarks").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="mrm.remarks" defaultMessage="Remarks" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="remarks"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.remarks")) ? this.state.dynamicInputs.find(p => p.inputKey === "mrm.remarks").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "mrm.remarks")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "mrm.remarks").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Remarks" style={{ width: "100%" }} id="mrm.remarks" value={this.state.remarks} onChange={(e) => { this.setState({ remarks: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table id={"Table"+this.props.id} style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} 
                                bordered  >


                                    <Column title="" align="center"  render={(value, row, index) => {

                                        return <div style={{ fontWeight: 800 }}>{row.mrm}</div>;


                                    }} />

                                    <Column title="Planned  Date" render={(value, row, index) => {
                                        if (Boolean(row.plannedDate))
                                            return moment(row.plannedDate).format("DD/MM/YYYY");

                                    }} />
                                    <Column title="Actual  Date" render={(value, row, index) => {
                                        if (Boolean(row.actualDate))
                                            return moment(row.actualDate).format("DD/MM/YYYY");

                                    }} />
                                    <Column title="Status" dataIndex="status" />
                                    <Column title="Report Submittal  Date" render={(value, row, index) => {
                                        if (Boolean(row.reportSubmittalDate))
                                            return moment(row.reportSubmittalDate).format("DD/MM/YYYY");

                                    }} />
                                    <Column title="Remarks" dataIndex="remarks" />

                                   { !Boolean(this.props.id) && <Column title="Action" render={(value, row, index) => {

                                        return this.actionTemplate(row);

                                    }} />} 
                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Mrm)
