/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Card } from "primereact/card";
import { Dropdown as ReactDropdown } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import { Tree } from 'primereact/tree';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { MultiSelect } from 'primereact/multiselect';
import { Cascader } from 'antd';
import { DatePicker } from 'antd';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu3';
import 'antd/dist/antd.css';
import $ from 'jquery';
import moment from 'moment';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export class Assignment extends Component {

    constructor() {
        super();
        this.state = {
            assignmentId: "",
            deleteModal: false,
            addModal: false,
            infoModal: false,
            infoModal2: false,
            infoModal3: false,
            nodes: [],
            disciplinesForFilter: [],
            departmentsForFilter: [],
            roleGroupsForFilter: [],
            designationsForFilter: [],
            workLocations: [],
            selectedDepartments: null,
            selectedDisciplines: null,
            selectedRoleGroups: null,
            selectedDesignations: null,
            statusText: "Contract Status", //Contract-Current Status
            employeeName: "",
            department: "Select Department",
            discipline: "Select Discipline",
            roleGroup: "Select Role Group",
            designation: "Select Designation",
            workLocation: "Select Work Location",
            departmentId: null,
            disciplineId: null,
            roleGroupId: null,
            designationId: null,
            workLocationId: null,
            statusButtonText: "Change", //Change-Save
            values: [],
            selectedItems: [],
            shifts: [],
            shift: "Select Shift",
            shiftId: null,
            assignments: [],
            selectedEmployee: null,
            projectDisable: true,
            designationDisable: true,
            disciplineDisable: true,
            roleGroupDisable: true,
            departmentDisable: true,
            selectedRow: null,
            startDate: null,
            endDate: null,
            staffId: null,
            equipmentLogId: null,
            equipmentLogs1: [],
            equipmentCode: "Select Equipment Code",
            equipmentCodes: [],
            equipmentDescription: "Select Equipment Description",
            equipmentDescriptions: [],
            staffDepartment: "",
            staffDiscipline: "",
            staffRoleGroup: "",
            staffDesignation: "",
            displayContractStatusButton: 'none',
            displayCancelButton: 'none',
            locations: [],
            selectedLocations: [],
            nodes1: [],
            selectedNodeKeys: null,
            shiftWorkLocations: [],
            project: "Select Project",
            customer: "Select Customer",
            projectId: null,
            customerId: null,
            projects: [],
            projectList: [],
            customers: [],
            companyId: null,
            companyProjectId: null,
            lastWorkLocations: [],
            customerList: [],
            product: "Select Product",
            productId: null,
            products: [],
            productList: []
        };

        this.actionTemplate = this.actionTemplate.bind(this);
        this.onDepartmentFilterChange = this.onDepartmentFilterChange.bind(this);
        this.onDisciplineFilterChange = this.onDisciplineFilterChange.bind(this);
        this.onRoleFilterChange = this.onRoleFilterChange.bind(this);
        this.onDesignationFilterChange = this.onDesignationFilterChange.bind(this);
        this.onEquipmentTypeFilterChange = this.onEquipmentTypeFilterChange.bind(this);
    }

    componentDidMount = async () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var dd1 = String(today.getDate() + 1).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        var today1 = dd1 + '/' + mm + '/' + yyyy;

        this.setState({
            startDate: today,
            endDate: today1
        });

        const response0 = await handleRequest("GET", "/Assignment/getCompanyItems");

        if (response0.data.length === 0) {
            this.setState({
                assignments: [],
            });
        }

        if (response0.data.length !== 0) {
            console.log(response0.data);
            this.setState({
                assignments: response0.data,
            });
        }

        const response = await handleRequest("GET", "/Department/getAll");

        if (response.data.length === 0) {
            this.setState({
                departmentsForFilter: []
            });
        }

        if (response.data.length !== 0) {

            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() }
            })

            this.setState({
                departmentsForFilter: options,
            });
        }

        const response2 = await handleRequest("GET", "/Discipline/getAll");

        if (response2.data.length === 0) {
            this.setState({
                disciplinesForFilter: []
            });
        }

        if (response2.data.length !== 0) {

            const columns2 = response2.data;

            const options2 = columns2.map(function (row) {
                return { name: row.disciplineName.toString(), id: row.id.toString() }
            })

            this.setState({
                disciplinesForFilter: options2,
            });
        }

        const response3 = await handleRequest("GET", "/EquipmentType/getNodes");

        if (response3.length === 0 || response3.data.length === 0) {
            this.setState({
                nodes: []
            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data
            });
        }

        const response4 = await handleRequest("GET", "/RoleGroup/getAll");

        if (response4.data.length === 0) {
            this.setState({
                roleGroupsForFilter: []
            });
        }

        if (response4.data.length !== 0) {

            const columns4 = response4.data;

            const options4 = columns4.map(function (row) {
                return { name: row.roleName.toString(), id: row.id.toString() }
            })

            this.setState({
                roleGroupsForFilter: options4,
            });
        }

        const response5 = await handleRequest("GET", "/Designation/getAll");

        if (response5.data.length === 0) {
            this.setState({
                designationsForFilter: []
            });
        }

        if (response5.data.length !== 0) {

            const columns5 = response5.data;

            const options5 = columns5.map(function (row) {
                return { name: row.designationName.toString(), id: row.id.toString() }
            })

            this.setState({
                designationsForFilter: options5,
            });
        }

        const response6 = await handleRequest("GET", "/Location/getAll");

        if (response6.data.length === 0) {
            this.setState({
                workLocations: []
            });
        }

        if (response6.data.length !== 0) {

            const columns6 = response6.data;

            const options6 = columns6.map(function (row) {
                return { name: row.description.toString(), id: row.id.toString() }
            })

            this.setState({
                workLocations: options6,
            });
        }

        const response7 = await handleRequest("GET", "/Shift/getAll");

        if (response7.data.length === 0) {
            this.setState({
                shifts1: []
            });
        }

        if (response7.data.length !== 0) {
            this.setState({
                shifts1: response7.data,
            });
        }

        const response8 = await handleRequest("GET", "/EquipmentLog/getAll");

        if (response8.data.length === 0) {
            this.setState({
                equipmentLogs1: []
            });
        }

        if (response8.data.length !== 0) {
            this.setState({
                equipmentLogs1: response8.data,
            });
        }

        var response9 = [];
        response9 = await handleRequest("GET", "/WFLocation/getNodesLocations");


        if (response9.length === 0 || response9.data.length === 0) {
            this.setState({
                nodes1: []

            });
        } else {
            if (response9.data.length !== 0) {

                this.setState({
                    nodes1: response9.data
                });
            }
        }

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data
            });

            const options7 = responseCustomer.data.map(function (row) {
                return { name: row.fullName.toString(), id: row.id.toString() }
            })

            this.setState({
                customers: options7,
            });
        }
    }

    handleChangeCustomer = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.customerList.length; z++) {
            if (this.state.customerList[z].fullName === e.target.value) {
                idValue = this.state.customerList[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            customerId: idValue
        });

        setTimeout(() => {
            this.fillProjects();
        }, 500);

    }

    fillProjects = () => {
        var customerList = this.state.customerList;
        var customerId = this.state.customerId;

        var index = customerList.findIndex(p => p.id === customerId)

        if (index >= 0 && customerList[index].projects !== null && customerList[index].projects !== undefined && customerList[index].projects.length > 0) {
            this.setState({
                projectList: customerList[index].projects
            });

            const options8 = customerList[index].projects.map(function (row) {
                return { name: row.projectName.toString(), id: row.id.toString() }
            })

            this.setState({
                projects: options8,
            });
        }
    }

    fillServices = () => {
        var projectList = this.state.projectList;
        var projectId = this.state.projectId;

        var index = projectList.findIndex(p => p.id.toString() === projectId.toString());

        if (index >= 0 && projectList[index].services !== null && projectList[index].services !== undefined && projectList[index].services.length > 0) {

            this.setState({
                productList: projectList[index].services
            });

            let options = projectList[index].services.map(function (row) {
                return { name: row.companyService.description.toString(), id: row.companyService.id.toString() }
            });

            this.setState({
                products: options,
            });
        }
    }

    handleChangeProject = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.projects.length; z++) {
            if (this.state.projects[z].name === e.target.value) {
                idValue = this.state.projects[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            projectId: idValue
        });

        setTimeout(() => {
            this.fillServices();
        }, 500);
    }

    handleChangeProduct = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.products.length; z++) {
            if (this.state.products[z].name === e.target.value) {
                idValue = this.state.products[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            productId: idValue
        });
    }

    renderEquipmentTypeFilter() {
        return (
            <Cascader options={this.state.nodes} onChange={this.onEquipmentTypeFilterChange} changeOnSelect />
        );
    }

    onEquipmentTypeFilterChange(value, data) {

        //-------------------------------------------------------
        //ÇALIŞAN KISIM -- Seçili en son equipmentTypeName'e göre filtre yapar.
        // if (data.length !== 0) {
        //     this.dt.filter(data[data.length - 1].label, 'equipmentType.equipmentTypeName', 'equals');
        // } else {
        //     this.dt.filter('','equipmentType.equipmentTypeName', 'equals');
        // }
        //-------------------------------------------------------

        //-------------------------------------------------------
        //ÇALIŞAN KISIM -- Seçili tüm equipmentTypeName'lere göre filtre yapar.
        var flagNamesArray = data.map(o => o.label);
        if (flagNamesArray.length !== 0) {
            this.dt.filter(flagNamesArray, 'equipmentType.equipmentTypeName', 'in');
        } else {
            this.dt.filter('', 'equipmentType.equipmentTypeName', 'in');
        }
        //-------------------------------------------------------
    }

    renderDepartmentFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDepartments} options={this.state.departmentsForFilter}
                onChange={this.onDepartmentFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDepartmentFilterChange(event) {
        this.dt.filter(event.value, 'department.departmentName', 'in');
        this.setState({ selectedDepartments: event.value });
    }

    renderDisciplineFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDisciplines} options={this.state.disciplinesForFilter}
                onChange={this.onDisciplineFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDisciplineFilterChange(event) {
        this.dt.filter(event.value, 'discipline.disciplineName', 'in');
        this.setState({ selectedDisciplines: event.value });
    }

    renderRoleFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedRoleGroups} options={this.state.roleGroupsForFilter}
                onChange={this.onRoleFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onRoleFilterChange(event) {
        this.dt.filter(event.value, 'roleGroup.roleName', 'in');
        this.setState({ selectedRoleGroups: event.value });
    }

    renderDesignationFilter() {
        return (
            <MultiSelect className="p-column-filter" value={this.state.selectedDesignations} options={this.state.designationsForFilter}
                onChange={this.onDesignationFilterChange} placeholder="All" optionLabel="name" optionValue="name" filter />
        );
    }

    onDesignationFilterChange(event) {
        this.dt.filter(event.value, 'designation.designationName', 'in');
        this.setState({ selectedDesignations: event.value });
    }

    actionTemplate(rowData, column) {
        return <React.Fragment><CustomMaterialMenu row={rowData} onEditRow={this.editAssignment.bind(this)} /></React.Fragment>;
    }

    nameTemplate(rowData, column) {
        return rowData.staff.name + " " + rowData.staff.midName + " " + rowData.staff.lastName;
    }

    deleteAssignment = (rowData) => {
        this.setState({
            deleteModal: true,
            assignmentId: rowData.id
        });
    }

    deleteAssignmentOK = async () => {
        const deletedAssignment = {
            id: this.state.assignmentId
        }

        await handleRequest("POST", "/Assignment/delete", deletedAssignment);

        this.setState({
            deleteModal: false
        });
        this.restartTable();
    }

    showOrHideModalDeleteAssignment = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    editAssignment = (rowData) => {

        setTimeout(() => {
            var flagValues = [];
            var flagEquipmentTypeId = null;

            if (rowData.equipmentType !== null) {

                flagEquipmentTypeId = rowData.equipmentType.id;

                flagValues = getParents(this.state.nodes, rowData.equipmentType.id).parents;

                flagValues.push(rowData.equipmentType.id);

                function getParentsHelper(tree, id, parents) {

                    if (tree.data.id == id) {
                        return {
                            found: true,
                            parents: parents
                        };
                    }
                    var result = {
                        found: false,
                    }
                    if (tree.children) {
                        $.each(tree.children, function (index, subtree) {
                            var maybeParents = $.merge([], parents);
                            if (tree.data.id != undefined) {
                                maybeParents.push(tree.data.id);
                            }
                            var maybeResult = getParentsHelper(subtree, id, maybeParents);
                            if (maybeResult.found) {
                                result = maybeResult;
                                return false;
                            }
                        });
                    }
                    return result;
                }

                function getParents(data1, id) {
                    var tree = {
                        data: {},
                        children: data1
                    }
                    return getParentsHelper(tree, id, []);
                }
            }

            var flagShiftId = null;
            var flagShiftName = "Select Shift";

            if (rowData.shift !== null) {
                flagShiftId = rowData.shift.id;
                flagShiftName = rowData.shift.shiftDescription;
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var dd1 = String(today.getDate() + 1).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            var today1 = dd1 + '/' + mm + '/' + yyyy;

            var flagStartDate = today;

            if (rowData.startDate !== "") {
                flagStartDate = rowData.startDate;
            }

            var flagEndDate = today1;

            if (rowData.endDate !== "") {
                flagEndDate = rowData.endDate;
            }

            var status = "";
            var statusButton = "";

            if ((rowData.designation.id.toString() === rowData.staff.designation.id.toString())
                && (rowData.department.id.toString() === rowData.staff.department.id.toString())
                && (rowData.roleGroup.id.toString() === rowData.staff.roleGroup.id.toString())
                && (rowData.discipline.id.toString() === rowData.staff.discipline.id.toString())) {
                status = "Contract Status";
                statusButton = "none"
            } else {
                status = "Current Status";
                statusButton = "block"
            }

            let equipmentTypeIdFlag = flagEquipmentTypeId;
            let equipmentLogs = this.state.equipmentLogs1;

            if (this.state.equipmentLogs1.length === 0 || flagEquipmentTypeId === null) {
                this.setState({
                    equipmentCodes: [],
                    equipmentDescriptions: []
                });
            } else if (this.state.equipmentLogs1.length !== 0) {

                let options = [];
                let options2 = [];

                for (let index = 0; index < equipmentLogs.length; index++) {
                    if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                        options.push({ name: equipmentLogs[index].equipmentCode.toString(), id: equipmentLogs[index].id.toString() });
                    }
                    if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                        options2.push({ name: equipmentLogs[index].equipmentDescription.toString(), id: equipmentLogs[index].id.toString() });
                    }
                }

                this.setState({
                    equipmentCodes: options,
                    equipmentDescriptions: options2
                });
            }

            var flagEquipmentDesc = "Select Equipment Description";
            var flagEquipmentCode = "Select Equipment Code";

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].id === rowData.equipmentLogId) {
                    flagEquipmentCode = equipmentLogs[index].equipmentCode;
                    flagEquipmentDesc = equipmentLogs[index].equipmentDescription;
                }
            }

            let response = rowData.assignmentWorkLocationList;

            if (response.length === 0) {
                this.setState({
                    selectedNodeKeys: null
                });
            } else if (response.length !== 0) {

                const obj = {};

                for (let index = 0; index < response.length; index++) {
                    let key = "L." + response[index].workLocationId;
                    obj[key] = { checked: response[index].checked, partialChecked: response[index].partialChecked };
                }

                this.setState({
                    selectedNodeKeys: obj
                });
            }

            let flagList = rowData.workLocations;

            if (flagList.length === 0) {
                this.setState({
                    shifts: []
                });
            }

            if (flagList.length !== 0) {

                const columns = this.state.shifts1;
                let options = [];
                let flagShifts = [];

                for (let index = 0; index < flagList.length; index++) {
                    for (let index1 = 0; index1 < columns.length; index1++) {
                        if (columns[index1].workLocations.includes(flagList[index]) && !flagShifts.includes(columns[index1].id.toString())) {
                            flagShifts.push(columns[index1].id.toString());
                            options.push({ name: columns[index1].shiftDescription.toString(), id: columns[index1].id.toString() });
                        }
                    }
                }

                this.setState({
                    shifts: options,
                });
            }

            let customerList1 = this.state.customerList;
            let projectList = [];

            var index = customerList1.findIndex(p => p.id === rowData.customerLogId);

            let options8 = [];

            if (index >= 0 && customerList1[index].projects !== null && customerList1[index].projects !== undefined && customerList1[index].projects.length > 0) {

                projectList = customerList1[index].projects;

                this.setState({
                    projectList: customerList1[index].projects,
                    customer: customerList1[index].fullName,
                    customerId: customerList1[index].id
                });

                options8 = customerList1[index].projects.map(function (row) {
                    return { name: row.projectName.toString(), id: row.id.toString() }
                })

                this.setState({
                    projects: options8,
                });
            }

            let projectName = "";

            if (rowData.customerProjectId !== null) {
                for (var z = 0; z < options8.length; z++) {
                    if (options8[z].id === rowData.customerProjectId.toString()) {
                        projectName = options8[z].name;
                    }
                }
            }

            var projectId = rowData.customerProjectId;
            let options9 = [];
            let productName = "";

            if (projectId !== null) {
                var index = projectList.findIndex(p => p.id.toString() === projectId.toString());

                if (index >= 0 && projectList[index].services !== null && projectList[index].services !== undefined && projectList[index].services.length > 0) {

                    this.setState({
                        productList: projectList[index].services
                    });

                    options9 = projectList[index].services.map(function (row) {
                        return { name: row.companyService.description.toString(), id: row.companyService.id.toString() }
                    });

                    this.setState({
                        products: options9,
                    });
                }

                if (rowData.productId !== null) {
                    for (var z = 0; z < options9.length; z++) {
                        if (options9[z].id === rowData.productId.toString()) {
                            productName = options9[z].name;
                        }
                    }
                }
            }

            this.setState({
                selectedRow: rowData,
                addModal: true,
                assignmentId: rowData.id,
                staffId: rowData.staff.id,
                employeeName: rowData.staff.name + " " + rowData.staff.midName + " " + rowData.staff.lastName,
                project: rowData.project,
                departmentId: rowData.department.id,
                department: rowData.department.departmentName,
                disciplineId: rowData.discipline.id,
                discipline: rowData.discipline.disciplineName,
                roleGroupId: rowData.roleGroup.id,
                roleGroup: rowData.roleGroup.roleName,
                designation: rowData.designation.designationName,
                designationId: rowData.designation.id,
                startDate: flagStartDate,
                endDate: flagEndDate,
                values: flagValues,
                equipmentTypeId: flagEquipmentTypeId,
                equipmentLogId: rowData.equipmentLogId,
                equipmentCode: flagEquipmentCode,
                equipmentDescription: flagEquipmentDesc,
                shift: flagShiftName,
                shiftId: flagShiftId,
                statusText: status,
                displayContractStatusButton: statusButton,
                staffDepartment: rowData.staff.department.departmentName,
                staffDiscipline: rowData.staff.discipline.disciplineName,
                staffRoleGroup: rowData.staff.roleGroup.roleName,
                staffDesignation: rowData.staff.designation.designationName,
                companyId: rowData.companyId,
                companyProjectId: rowData.projectId,
                lastWorkLocations: rowData.workLocations
            });

            if (rowData.customerLogId === null) {
                this.setState({
                    customer: "Select Customer",
                    customerId: null
                });
            }

            if (projectName === "") {
                this.setState({
                    project: "Select Project",
                    projectId: null
                });
            } else {
                this.setState({
                    project: projectName,
                    projectId: rowData.customerProjectId
                });
            }

            if (productName === "") {
                this.setState({
                    product: "Select Product",
                    productId: null
                });
            } else {
                this.setState({
                    product: productName,
                    productId: rowData.productId
                });
            }
        }, 1000);
    }

    showOrHideModalAddAssignment = () => {
        this.setState({
            addModal: !this.state.addModal,
            projectDisable: true,
            designationDisable: true,
            disciplineDisable: true,
            roleGroupDisable: true,
            departmentDisable: true,
            statusText: "Contract Status",
            statusButtonText: "Change"
        });
    }

    showOrHideModalAddAssignment1 = () => {
        if (this.state.selectedEmployee === null) {
            this.setState({
                infoModal: true
            });
        } else {

            const rowData = this.state.selectedEmployee;

            var flagValues = [];
            var flagEquipmentTypeId = null;

            if (rowData.equipmentType !== null) {

                flagEquipmentTypeId = rowData.equipmentType.id;

                flagValues = getParents(this.state.nodes, rowData.equipmentType.id).parents;

                flagValues.push(rowData.equipmentType.id);

                function getParentsHelper(tree, id, parents) {

                    if (tree.data.id == id) {
                        return {
                            found: true,
                            parents: parents
                        };
                    }
                    var result = {
                        found: false,
                    }
                    if (tree.children) {
                        $.each(tree.children, function (index, subtree) {
                            var maybeParents = $.merge([], parents);
                            if (tree.data.id != undefined) {
                                maybeParents.push(tree.data.id);
                            }
                            var maybeResult = getParentsHelper(subtree, id, maybeParents);
                            if (maybeResult.found) {
                                result = maybeResult;
                                return false;
                            }
                        });
                    }
                    return result;
                }

                function getParents(data1, id) {
                    var tree = {
                        data: {},
                        children: data1
                    }
                    return getParentsHelper(tree, id, []);
                }
            }

            var flagShiftId = null;
            var flagShiftName = "Select Shift";

            if (rowData.shift !== null) {
                flagShiftId = rowData.shift.id;
                flagShiftName = rowData.shift.shiftDescription;
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var dd1 = String(today.getDate() + 1).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            var today1 = dd1 + '/' + mm + '/' + yyyy;

            var flagStartDate = today;

            if (rowData.startDate !== "") {
                flagStartDate = rowData.startDate;
            }

            var flagEndDate = today1;

            if (rowData.endDate !== "") {
                flagEndDate = rowData.endDate;
            }

            var status = "";
            var statusButton = "";

            if ((rowData.designation.id.toString() === rowData.staff.designation.id.toString())
                && (rowData.department.id.toString() === rowData.staff.department.id.toString())
                && (rowData.roleGroup.id.toString() === rowData.staff.roleGroup.id.toString())
                && (rowData.discipline.id.toString() === rowData.staff.discipline.id.toString())) {
                status = "Contract Status";
                statusButton = "none"
            } else {
                status = "Current Status";
                statusButton = "block"
            }

            let equipmentTypeIdFlag = flagEquipmentTypeId;
            let equipmentLogs = this.state.equipmentLogs1;

            if (this.state.equipmentLogs1.length === 0 || flagEquipmentTypeId === null) {
                this.setState({
                    equipmentCodes: [],
                    equipmentDescriptions: []
                });
            } else if (this.state.equipmentLogs1.length !== 0) {

                let options = [];
                let options2 = [];

                for (let index = 0; index < equipmentLogs.length; index++) {
                    if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                        options.push({ name: equipmentLogs[index].equipmentCode.toString(), id: equipmentLogs[index].id.toString() });
                    }
                    if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                        options2.push({ name: equipmentLogs[index].equipmentDescription.toString(), id: equipmentLogs[index].id.toString() });
                    }
                }

                this.setState({
                    equipmentCodes: options,
                    equipmentDescriptions: options2
                });
            }

            var flagEquipmentDesc = "Select Equipment Description";
            var flagEquipmentCode = "Select Equipment Code";

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].id === rowData.equipmentLogId) {
                    flagEquipmentCode = equipmentLogs[index].equipmentCode;
                    flagEquipmentDesc = equipmentLogs[index].equipmentDescription;
                }
            }

            let response = rowData.assignmentWorkLocationList;

            if (response.length === 0) {
                this.setState({
                    selectedNodeKeys: null
                });
            } else if (response.length !== 0) {

                const obj = {};

                for (let index = 0; index < response.length; index++) {
                    let key = "L." + response[index].workLocationId;
                    obj[key] = { checked: response[index].checked, partialChecked: response[index].partialChecked };
                }

                this.setState({
                    selectedNodeKeys: obj
                });
            }

            let flagList = rowData.workLocations;

            if (flagList.length === 0) {
                this.setState({
                    shifts: []
                });
            }

            if (flagList.length !== 0) {

                const columns = this.state.shifts1;
                let options = [];
                let flagShifts = [];

                for (let index = 0; index < flagList.length; index++) {
                    for (let index1 = 0; index1 < columns.length; index1++) {
                        if (columns[index1].workLocations.includes(flagList[index]) && !flagShifts.includes(columns[index1].id.toString())) {
                            flagShifts.push(columns[index1].id.toString());
                            options.push({ name: columns[index1].shiftDescription.toString(), id: columns[index1].id.toString() });
                        }
                    }
                }

                this.setState({
                    shifts: options,
                });
            }

            let customerList1 = this.state.customerList;
            let projectList = [];

            var index = customerList1.findIndex(p => p.id === rowData.customerLogId);

            let options8 = [];

            if (index >= 0 && customerList1[index].projects !== null && customerList1[index].projects !== undefined && customerList1[index].projects.length > 0) {

                this.setState({
                    projectList: customerList1[index].projects,
                    customer: customerList1[index].fullName,
                    customerId: customerList1[index].id
                });

                options8 = customerList1[index].projects.map(function (row) {
                    return { name: row.projectName.toString(), id: row.id.toString() }
                })

                this.setState({
                    projects: options8,
                });
            }

            let projectName = "";

            if (rowData.customerProjectId !== null) {
                for (var z = 0; z < options8.length; z++) {
                    if (options8[z].id === rowData.customerProjectId.toString()) {
                        projectName = options8[z].name;
                    }
                }
            }

            var projectId = rowData.customerProjectId;
            let options9 = [];
            let productName = "";

            if (projectId !== null) {
                var index = projectList.findIndex(p => p.id.toString() === projectId.toString());

                if (index >= 0 && projectList[index].services !== null && projectList[index].services !== undefined && projectList[index].services.length > 0) {

                    this.setState({
                        productList: projectList[index].services
                    });

                    options9 = projectList[index].services.map(function (row) {
                        return { name: row.companyService.description.toString(), id: row.companyService.id.toString() }
                    });

                    this.setState({
                        products: options9,
                    });
                }

                if (rowData.productId !== null) {
                    for (var z = 0; z < options9.length; z++) {
                        if (options9[z].id === rowData.productId.toString()) {
                            productName = options9[z].name;
                        }
                    }
                }
            }

            this.setState({
                selectedRow: rowData,
                addModal: true,
                assignmentId: rowData.id,
                staffId: rowData.staff.id,
                employeeName: rowData.staff.name + " " + rowData.staff.midName + " " + rowData.staff.lastName,
                project: rowData.project,
                departmentId: rowData.department.id,
                department: rowData.department.departmentName,
                disciplineId: rowData.discipline.id,
                discipline: rowData.discipline.disciplineName,
                roleGroupId: rowData.roleGroup.id,
                roleGroup: rowData.roleGroup.roleName,
                designation: rowData.designation.designationName,
                designationId: rowData.designation.id,
                startDate: flagStartDate,
                endDate: flagEndDate,
                values: flagValues,
                equipmentTypeId: flagEquipmentTypeId,
                equipmentLogId: rowData.equipmentLogId,
                equipmentCode: flagEquipmentCode,
                equipmentDescription: flagEquipmentDesc,
                shift: flagShiftName,
                shiftId: flagShiftId,
                statusText: status,
                displayContractStatusButton: statusButton,
                staffDepartment: rowData.staff.department.departmentName,
                staffDiscipline: rowData.staff.discipline.disciplineName,
                staffRoleGroup: rowData.staff.roleGroup.roleName,
                staffDesignation: rowData.staff.designation.designationName,
                companyId: rowData.companyId,
                companyProjectId: rowData.projectId,
                lastWorkLocations: rowData.workLocations
            });

            if (rowData.customerLogId === null) {
                this.setState({
                    customer: "Select Customer",
                    customerId: null
                });
            }

            if (projectName === "") {
                this.setState({
                    project: "Select Project",
                    projectId: null
                });
            } else {
                this.setState({
                    project: projectName,
                    projectId: rowData.customerProjectId
                });
            }

            if (productName === "") {
                this.setState({
                    product: "Select Product",
                    productId: null
                });
            } else {
                this.setState({
                    product: productName,
                    productId: rowData.productId
                });
            }
        }
    }

    restartTable = async () => {
        const response0 = await handleRequest("GET", "/Assignment/getCompanyItems");

        if (response0.data.length === 0) {
            this.setState({
                assignments: [],
            });
        }

        if (response0.data.length !== 0) {
            this.setState({
                assignments: response0.data,
            });
        }
    }

    cancelSaveStatus = async () => {
        var status = "";
        var statusButton = "";

        if ((this.state.selectedRow.designationId.toString() === this.state.selectedRow.staff.designation.id.toString())
            && (this.state.selectedRow.departmentId.toString() === this.state.selectedRow.staff.department.id.toString())
            && (this.state.selectedRow.roleGroupId.toString() === this.state.selectedRow.staff.roleGroup.id.toString())
            && (this.state.selectedRow.disciplineId.toString() === this.state.selectedRow.staff.discipline.id.toString())) {
            status = "Contract Status";
            statusButton = "none"
        } else {
            status = "Current Status";
            statusButton = "block"
        }

        this.setState({
            projectDisable: true,
            designationDisable: true,
            disciplineDisable: true,
            roleGroupDisable: true,
            departmentDisable: true,
            project: this.state.selectedRow.project,
            department: this.state.selectedRow.department.departmentName,
            departmentId: this.state.selectedRow.departmentId,
            discipline: this.state.selectedRow.discipline.disciplineName,
            disciplineId: this.state.selectedRow.disciplineId,
            roleGroup: this.state.selectedRow.roleGroup.roleName,
            roleGroupId: this.state.selectedRow.roleGroupId,
            designation: this.state.selectedRow.designation.designationName,
            designationId: this.state.selectedRow.designationId,
            statusText: status,
            displayCancelButton: "none",
            displayContractStatusButton: statusButton,
            statusButtonText: "Change"
        });
    }

    changeSaveStatus = async () => {
        if (this.state.statusButtonText === "Change") {
            this.setState({
                projectDisable: false,
                designationDisable: false,
                disciplineDisable: false,
                roleGroupDisable: false,
                departmentDisable: false,
                displayCancelButton: "block",
                statusText: "Current Status",
                statusButtonText: "Save"
            });
        } else if (this.state.statusButtonText === "Save") {

            var status = "";
            var statusButton = "";

            if ((this.state.designationId.toString() === this.state.selectedRow.staff.designation.id.toString())
                && (this.state.departmentId.toString() === this.state.selectedRow.staff.department.id.toString())
                && (this.state.roleGroupId.toString() === this.state.selectedRow.staff.roleGroup.id.toString())
                && (this.state.disciplineId.toString() === this.state.selectedRow.staff.discipline.id.toString())) {
                status = "Contract Status";
                statusButton = "none"
            } else {
                status = "Current Status";
                statusButton = "block"
            }

            this.setState({
                projectDisable: true,
                designationDisable: true,
                disciplineDisable: true,
                roleGroupDisable: true,
                departmentDisable: true,
                statusText: status,
                displayCancelButton: "none",
                displayContractStatusButton: statusButton,
                statusButtonText: "Change"
            });
        }

    }

    handleChangeDiscipline = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.disciplinesForFilter.length; z++) {
            if (this.state.disciplinesForFilter[z].name === e.target.value) {
                idValue = this.state.disciplinesForFilter[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            disciplineId: idValue
        });
    }

    handleChangeDepartment = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.departmentsForFilter.length; z++) {
            if (this.state.departmentsForFilter[z].name === e.target.value) {
                idValue = this.state.departmentsForFilter[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            departmentId: idValue
        });
    }

    handleChangeRoleGroup = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.roleGroupsForFilter.length; z++) {
            if (this.state.roleGroupsForFilter[z].name === e.target.value) {
                idValue = this.state.roleGroupsForFilter[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            roleGroupId: idValue
        });
    }

    handleChangeDesignation = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.designationsForFilter.length; z++) {
            if (this.state.designationsForFilter[z].name === e.target.value) {
                idValue = this.state.designationsForFilter[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            designationId: idValue
        });
    }

    handleChangeShift = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.shifts.length; z++) {
            if (this.state.shifts[z].name === e.target.value) {
                idValue = this.state.shifts[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            shiftId: idValue
        });
    }

    handleChangeEquipmentDescription = e => {
        e.preventDefault();
        var idValue;
        var equipmentCodeValue;

        for (var z = 0; z < this.state.equipmentDescriptions.length; z++) {
            if (this.state.equipmentDescriptions[z].name === e.target.value) {
                idValue = this.state.equipmentDescriptions[z].id
            }
        }

        for (let index = 0; index < this.state.equipmentLogs1.length; index++) {
            if (this.state.equipmentLogs1[index].id === idValue) {
                equipmentCodeValue = this.state.equipmentLogs1[index].equipmentCode;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            equipmentCode: equipmentCodeValue,
            equipmentLogId: idValue
        });
    }

    handleChangeEquipmentCode = e => {
        e.preventDefault();
        var idValue;
        var equipmentDescriptionValue;

        for (var z = 0; z < this.state.equipmentCodes.length; z++) {
            if (this.state.equipmentCodes[z].name === e.target.value) {
                idValue = this.state.equipmentCodes[z].id
            }
        }

        for (let index = 0; index < this.state.equipmentLogs1.length; index++) {
            if (this.state.equipmentLogs1[index].id === idValue) {
                equipmentDescriptionValue = this.state.equipmentLogs1[index].equipmentDescription;
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            equipmentDescription: equipmentDescriptionValue,
            equipmentLogId: idValue
        });
    }

    onSelectionChange1 = e => {

        let flagList = [];

        if (e.value !== null) {
            var flagArray = Object.keys(e.value);

            flagArray.forEach(element => {
                flagList.push(element.split(".")[1]);
            });
        }

        this.setState({ selectedNodeKeys: e.value })

        if (flagList.length === 0) {
            this.setState({
                shifts: []
            });
        }

        if (flagList.length !== 0) {

            const columns = this.state.shifts1;
            let options = [];
            let flagShifts = [];

            for (let index = 0; index < flagList.length; index++) {
                for (let index1 = 0; index1 < columns.length; index1++) {
                    if (columns[index1].workLocations.includes(flagList[index]) && !flagShifts.includes(columns[index1].id.toString())) {
                        flagShifts.push(columns[index1].id.toString());
                        options.push({ name: columns[index1].shiftDescription.toString(), id: columns[index1].id.toString() });
                    }
                }
            }

            this.setState({
                shifts: options,
            });
        }
    }

    onChange = (value, data) => {
        let equipmentTypeIdFlag = value[value.length - 1];

        if (this.state.equipmentLogs1.length === 0) {
            this.setState({
                equipmentCodes: [],
                equipmentDescriptions: []
            });
        } else if (this.state.equipmentLogs1.length !== 0) {

            let equipmentLogs = this.state.equipmentLogs1;
            let options = [];
            let options2 = [];

            for (let index = 0; index < equipmentLogs.length; index++) {
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options.push({ name: equipmentLogs[index].equipmentCode.toString(), id: equipmentLogs[index].id.toString() });
                }
                if (equipmentLogs[index].equipmentType.id === equipmentTypeIdFlag) {
                    options2.push({ name: equipmentLogs[index].equipmentDescription.toString(), id: equipmentLogs[index].id.toString() });
                }
            }

            this.setState({
                equipmentCodes: options,
                equipmentDescriptions: options2
            });
        }

        this.setState({
            values: value,
            selectedItems: data
        })
    }

    onChange2 = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        });
    }

    saveAssignment = async () => {

        var list = [];
        var list2 = [];

        if (this.state.selectedNodeKeys !== null) {

            var flagArray = Object.keys(this.state.selectedNodeKeys);
            var flagArray1 = Object.values(this.state.selectedNodeKeys);

            console.log(flagArray);
            console.log(flagArray1);

            for (let index = 0; index < flagArray.length; index++) {
                let obj = { workLocationId: flagArray[index].split(".")[1], checked: flagArray1[index].checked, partialChecked: flagArray1[index].partialChecked }
                list.push(obj);
            }

            for (let index = 0; index < flagArray.length; index++) {
                let obj1 = flagArray[index].split(".")[1];
                list2.push(obj1);
            }
        }

        console.log(list);

        if (this.state.statusButtonText === "Change") {
            const editAssignment = {
                id: this.state.assignmentId,
                staffId: this.state.staffId,
                departmentId: this.state.departmentId,
                disciplineId: this.state.disciplineId,
                roleGroupId: this.state.roleGroupId,
                designationId: this.state.designationId,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                workLocations: list2,
                assignmentWorkLocationList: list,
                equipmentTypeId: this.state.values[this.state.values.length - 1],
                equipmentLogId: this.state.equipmentLogId,
                shiftId: this.state.shiftId,
                customerProjectId: this.state.projectId,
                customerLogId: this.state.customerId,
                companyId: this.state.companyId,
                projectId: this.state.companyProjectId,
                productId: this.state.productId
            }

            await handleRequest("POST", "/Assignment/update", editAssignment);

            this.setState({
                addModal: false
            });
            this.restartTable();
        } else if (this.state.statusButtonText === "Save") {
            this.setState({
                infoModal2: true
            });
        }

    }

    showOrHideModalInfoAssignment = () => {
        this.setState({
            infoModal: !this.state.infoModal
        });
    }

    showOrHideModalInfoAssignment2 = () => {
        this.setState({
            infoModal2: !this.state.infoModal2
        });
    }

    showOrHideModalInfoAssignment3 = () => {
        this.setState({
            infoModal3: !this.state.infoModal3
        });
    }

    disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    render() {
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        let optionsDepartments = this.state.departmentsForFilter.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDisciplines = this.state.disciplinesForFilter.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsRoleGroups = this.state.roleGroupsForFilter.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsDesignations = this.state.designationsForFilter.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsShifts = this.state.shifts.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsEquipmentCodes = this.state.equipmentCodes.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsEquipmentDescriptions = this.state.equipmentDescriptions.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsCustomers = this.state.customers.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsProjects = this.state.projects.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsProducts = this.state.products.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        const equipmentTypeFilter = this.renderEquipmentTypeFilter();
        const departmentFilter = this.renderDepartmentFilter();
        const disciplineFilter = this.renderDisciplineFilter();
        const roleFilter = this.renderRoleFilter();
        const designationFilter = this.renderDesignationFilter();

        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>

                <Modal
                    show={this.state.infoModal}
                    onHide={this.showOrHideModalInfoAssignment}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Select an Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Please select an employee from the list to assign.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalInfoAssignment}>Close</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.infoModal2}
                    onHide={this.showOrHideModalInfoAssignment2}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Save Employee Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Please save the "Employee Status" before saving the assignment.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalInfoAssignment2}>Close</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.infoModal3}
                    onHide={this.showOrHideModalInfoAssignment3}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Employee Contract Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ marginTop: '0.5rem' }}>Department:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: 'black' }} >
                                <Form.Label style={{ marginTop: '0.5rem' }}>{this.state.staffDepartment}</Form.Label>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ marginTop: '0.5rem' }}>Discipline:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: 'black' }} >
                                <Form.Label style={{ marginTop: '0.5rem' }}>{this.state.staffDiscipline}</Form.Label>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ marginTop: '0.5rem' }}>Role Group:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: 'black' }} >
                                <Form.Label style={{ marginTop: '0.5rem' }}>{this.state.staffRoleGroup}</Form.Label>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                <Form.Label style={{ marginTop: '0.5rem' }}>Designation:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} style={{ color: 'black' }} >
                                <Form.Label style={{ marginTop: '0.5rem' }}>{this.state.staffDesignation}</Form.Label>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalInfoAssignment3}>Close</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteAssignment}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete This?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Assignment Data?
                    </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related datas.
                    </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteAssignment}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteAssignmentOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalAddAssignment}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Assignment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Collapse defaultActiveKey={['2']}>
                            <Panel header={this.state.statusText} key="1">

                                {/* <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'center' }}>
                                        <Form.Label style={{ color: 'black', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                            {this.state.statusText}
                                        </Form.Label>
                                    </Form.Group>
                                </Form.Row> */}

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem', textAlign: 'right' }}>Employee:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label style={{ marginTop: '0.5rem', textAlign: 'right', color: 'black' }}>{this.state.employeeName}</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Department:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control disabled={this.state.departmentDisable} name="department" value={this.state.department}
                                            onChange={this.handleChangeDepartment} as="select">
                                            <option value="">
                                                Select Department
                                </option>
                                            {optionsDepartments}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Discipline:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control disabled={this.state.disciplineDisable} name="discipline" value={this.state.discipline}
                                            onChange={this.handleChangeDiscipline} as="select">
                                            <option value="">
                                                Select Discipline
                                </option>
                                            {optionsDisciplines}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Role Group:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control disabled={this.state.roleGroupDisable} name="roleGroup" value={this.state.roleGroup}
                                            onChange={this.handleChangeRoleGroup} as="select">
                                            <option value="">
                                                Select Role Group
                                </option>
                                            {optionsRoleGroups}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Designation:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control disabled={this.state.designationDisable} name="designation" value={this.state.designation}
                                            onChange={this.handleChangeDesignation} as="select">
                                            <option value="">
                                                Select Designation
                                </option>
                                            {optionsDesignations}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row style={{ marginBottom: '-1rem' }}>
                                    <Form.Group as={Col}>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ display: this.state.displayContractStatusButton }}>
                                        <ReactButton style={{ width: '100%' }} variant="secondary" onClick={this.showOrHideModalInfoAssignment3}>Employee's Contract Status</ReactButton>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ display: this.state.displayCancelButton }}>
                                        <ReactButton style={{ width: '100%' }} variant="danger" onClick={this.cancelSaveStatus}>Cancel</ReactButton>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <ReactButton style={{ width: '100%' }} variant="success" onClick={this.changeSaveStatus}>{this.state.statusButtonText}</ReactButton>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>
                            </Panel>
                            <Panel header="Assign" key="2">
                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Start-End Date:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <RangePicker value={[moment(this.state.startDate, 'DD/MM/YYYY'), moment(this.state.endDate, 'DD/MM/YYYY')]}
                                            disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChange2} />
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Work Location:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Tree value={this.state.nodes1} selectionMode="checkbox" selectionKeys={this.state.selectedNodeKeys}
                                            onSelectionChange={this.onSelectionChange1} />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Customer:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control name="customer" value={this.state.customer}
                                            onChange={this.handleChangeCustomer} as="select">
                                            <option value="">
                                                Select Customer
                                            </option>
                                            {optionsCustomers}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Project:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control name="project" value={this.state.project}
                                            onChange={this.handleChangeProject} as="select">
                                            <option value="">
                                                Select Project
                                            </option>
                                            {optionsProjects}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Product:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control name="product" value={this.state.product}
                                            onChange={this.handleChangeProduct} as="select">
                                            <option value="">
                                                Select Product
                                            </option>
                                            {optionsProducts}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Type:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Cascader defaultValue={this.state.values} options={this.state.nodes} onChange={this.onChange} changeOnSelect />
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Description:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control name="equipmentDescription" value={this.state.equipmentDescription}
                                            onChange={this.handleChangeEquipmentDescription} as="select">
                                            <option value="">
                                                Select Equipment Description
                            </option>
                                            {optionsEquipmentDescriptions}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Equipment Code:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control name="equipmentCode" value={this.state.equipmentCode}
                                            onChange={this.handleChangeEquipmentCode} as="select">
                                            <option value="">
                                                Select Equipment Code
                                    </option>
                                            {optionsEquipmentCodes}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'right' }}>
                                        <Form.Label style={{ marginTop: '0.5rem' }}>Shift:</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control name="shift" value={this.state.shift}
                                            onChange={this.handleChangeShift} as="select">
                                            <option value="">
                                                Select Shift
                                    </option>
                                            {optionsShifts}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} style={{ textAlign: 'center' }}>
                                        <ReactButton style={{ width: '80%' }} onClick={this.saveAssignment}>Save</ReactButton>
                                    </Form.Group>
                                </Form.Row>
                            </Panel>
                        </Collapse>
                    </Modal.Body>
                </Modal>

                <div style={{ padding: "8px 16px 8px 24px" }}>
                    <Row>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><ReactButton onClick={this.showOrHideModalAddAssignment1} style={{ width: '100%' }} >Assign</ReactButton></Col>
                    </Row>
                </div>

                <div className="datatable-doc-demo">
                    <Card>
                        <div className="content-section implementation">
                            <DataTable ref={(el) => this.dt = el} value={this.state.assignments} paginator={true}
                                emptyMessage="No Assignments Found" rowsPerPageOptions={[5, 10, 20]} rows={10} selectionMode="single"
                                selection={this.state.selectedEmployee} onSelectionChange={e => this.setState({ selectedEmployee: e.value })} style={{ width: '100%' }}>
                                <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '5em' }} />
                                <Column field="index" header="S/N" style={{ textAlign: 'center', width: '75px' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="staff.nationalId" header="ID" style={{ textAlign: 'center' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column header="Name" body={this.nameTemplate} style={{ textAlign: 'center' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="department.departmentName" header="Department" sortable filter filterElement={departmentFilter} />
                                <Column field="discipline.disciplineName" header="Discipline" sortable filter filterElement={disciplineFilter} />
                                <Column field="roleGroup.roleName" header="Role Group" sortable filter filterElement={roleFilter} />
                                <Column field="designation.designationName" header="Designation" sortable filter filterElement={designationFilter} />
                                <Column field="shift.shiftDescription" header="Shift" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="workLocationNames" header="Work Location" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="startDate" header="Start Date" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="endDate" header="End Date" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentType.equipmentTypeName" header="Equipment Type" sortable={true} filter={true} filterElement={equipmentTypeFilter} />
                            </DataTable>

                            {/* Scrollable
                            <DataTable ref={(el) => this.dt = el} value={this.state.assignments} paginator={true} scrollHeight="200px"
                                emptyMessage="No Assignments Found" rowsPerPageOptions={[5, 10, 20]} rows={10} scrollable={true}
                                style={{ width: '100%' }}>
                                <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '75px' }} />
                                <Column field="index" header="S/N" style={{ textAlign: 'center', width: '100px' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="nationalId" header="ID" style={{ textAlign: 'center', width: '200px' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="name" header="Name" style={{ textAlign: 'center', width: '250px' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="project" header="Project" style={{ textAlign: 'center', width: '250px' }} sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="department.departmentName" style={{ width: '250px' }} header="Department" sortable filter filterMatchMode="contains" />
                                <Column field="discipline.disciplineName" style={{ width: '250px' }} header="Discipline" sortable filter filterMatchMode="contains" />
                                <Column field="roleGroup.roleName" style={{ width: '250px' }} header="Role Group" sortable filter filterMatchMode="contains" />
                                <Column field="designation.designationName" style={{ width: '250px' }} header="Designation" sortable filter filterMatchMode="contains" />
                                <Column field="shiftDescriptionTypeCode.name" style={{ width: '250px' }} header="Shift" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="workLocation.description" style={{ width: '250px' }} header="Work Location" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="startDate" style={{ width: '250px' }} header="Start Date" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="endDate" style={{ width: '250px' }} header="End Date" sortable={true} filter={true} filterMatchMode="contains" />
                                <Column field="equipmentType.equipmentTypeName" style={{ width: '250px' }} header="Equipment Type" sortable={true} filter={true} filterElement={equipmentTypeFilter} />
                            </DataTable> */}
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Assignment)