/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card, Accordion } from 'react-bootstrap';
import { Table, Container, Col, Form, Row } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Button as AntdButton } from 'antd';
import { InputText } from "primereact/inputtext";
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import { Column } from 'primereact/components/column/Column';
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import "./css/DataTable.scss";
import { Modal } from "react-bootstrap";
import { TreeTable } from 'primereact/treetable';
import { Switch } from "antd";
import MaterialLogSub from "./MaterialLogSub";
import { Cascader, message, Upload, Avatar, Divider, Input, Select } from "antd";
import { InputNumber } from 'antd';
import { Calendar } from 'primereact/calendar';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import CustomMaterialMenuMaterialLog from './ForComponents/CustomMaterialMenuMaterialLog';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 

const { Option } = Select;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.value, lineage: node.data.lineage });
        if (node.children) {  
            generateList(node.children); 
        }
    }
};

class MaterialLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedConfirmation: null,
      globalFilter: null,
      showGlobalFilter: null,
      selectedDepartments: null,
      selectedDisciplines: null,
      disciplinesForFilter: [],
      departmentsForFilter: [],
      inspectionlevelForFilter: [],
      materialTypeId: 0,
      materialLogId: "",
      MaterialLogs:[],
      expandedRows: null,
      addModal: false,
      promptModal: false,
      promptMaterialLogSub:false,
      materialCode: "",
      clientMaterialCode:"",
      materialName: "",
      department: "Select Department",
      discipline: "Select Discipline",
      departmentId: 0,
      disciplineId: 0,
      nodes: [],
      expandIconPosition: "left",
      loading: false,
      visible: false,
      values: [],
      selectedItems: [],
      values1: [],
      selectedItems1: [],
      newItem: "",
      parentId: null,
      switchMaterial: false,
      switchPermanent: false,
      switchEngineering: false,
      switchUsable: false,
      switchInspectible: false,
      inspectionlevel:"",
      inspectionlevelId:0,
      hiddeninspectionlevel:true,
      barcode:"",
      remarks:"",

      startDate:null,

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonDimension: "Create New",
      createNewButtonWarranty: "Create New",
      createNewButtonClient: "Create New",
      createNewButtonManufacturer:"Create New",
      createNewButtonPacking:"Create New",
      createNewButtonHandling:"Create New",
      createNewButtonTransportation:"Create New",
      createNewButtonMaintenance:"Create New",

      //modalKontrol:true,
      MaterialDimensions:[],
      MaterialDimensionsKayit:0,
      MaterialClients:[],
      MaterialClientsKayit:0,
      MaterialIdentifications:[],
      MaterialIdentificationsKayit:0,
      Manufacturings:[],
      ManufacturingsKayit:0, 
      MaterialPackings:[],
      MaterialPackingsKayit:0,
      MaterialTransportations:[],
      MaterialTransportationsKayit:0,
      MaterialMaintenances:[],
      MaterialMaintenancesKayit:0,
      MaterialHandlings:[],
      MaterialHandlingsKayit:0,
      MaterialLogs:[],
      MaterialWarrantys:[],
      MaterialWarrantysKayit:0,
      MaterialWarrantyAddItems: true,
      MaterialDimensionAddItems: true,
      MaterialClientAddItems:true,
      MaterialIdentificationAddItems:false,
      MaterialManufacturerAddItems:true,
      MaterialPackingAddItems:true,
      MaterialTransportationAddItems:true,
      MaterialHandlingAddItems:true,
      MaterialMaintenanceAddItems:true, 
      hiddenDataGirisMaterialIdentification:false,

      IdentificationFileUpload:[],
      identificationFilePath:"",
      WarrantyFileUpload:[],
      warrantyFilePath:"",
      ClientFileUpload:[],
      clientFilePath:"",
      ManufacturingFileUpload:[],
      manufacturerFilePath:"",
      PackingFileUpload:[],
      packingFilePath:"",
      HandlingFileUpload:[],
      handlingFilePath:"",
      TransportationFileUpload:[],
      transportationFilePath:"", 
      MaintenanceFileUpload:[],
      maintenanceFilePath:"",

      fileList: [],
      MaterialLogSubId:"",
      MatLogAreaofuses:[],
      areaOfUse: "",
      requiredQuantity: "",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.onDepartmentFilterChange = this.onDepartmentFilterChange.bind(this);
    this.onDisciplineFilterChange = this.onDisciplineFilterChange.bind(this);
    this.onMaterialTypeFilterChange = this.onMaterialTypeFilterChange.bind(this);
    this.renderDownloadFile=this.renderDownloadFile.bind(this);   
    this.renderDownloadButton=this.renderDownloadButton.bind(this); 
  }

  componentDidMount = async () => { 
    this.restartTable();

    const response = await handleRequest("GET", "/Department/getAll");

    if (response.data.length === 0) {
      this.setState({
        departments: [],
      });
    }

    if (response.data.length !== 0) {
      const columns = response.data;

      const options = columns.map(function(row) {
        return { name: row.departmentName.toString(), id: row.id };
      });

      this.setState({
        departmentsForFilter: options,
      });
    }

    const response2 = await handleRequest("GET", "/Discipline/getAll");

    if (response2.data.length !== 0) {
      const columns2 = response2.data;

      const options2 = columns2.map(function(row) {
        return { name: row.disciplineName.toString(), id: row.id };
      });

      this.setState({
        disciplinesForFilter: options2,
      });
    }

    const response3 = await handleRequest("GET", "/MaterialType/getNodes");

    if (response3.data.length == 0) { 
      this.setState({
        nodes: [],
      });
    } else if (response3.data.length != 0) {
      generateList(response3.data); 
      this.setState({
        nodes: response3.data,
      });
    }

    const response5 = await handleRequest("GET", "/IncpectionLevel/getAll");

    if (response5.data.length !== 0) {
      const columns5 = response5.data;

      const options5 = columns5.map(function(row) {
        return { name: row.abbraviation.toString(), id: row.id };
      });

      this.setState({
        inspectionlevelForFilter: options5,
      });
    }else {
      this.setState({
        inspectionlevelForFilter: [],
      });
    } 
  
  this.getMaterialIdentifications();
  this.getMaterialDimensions();
  this.getMaterialClients();
  this.getManufacturings();
  this.getMaterialPackings();
  this.getMaterialHandlings();
  this.getMaterialTransportations();
  this.getMaterialMaintenances();
  this.getMaterialWarrantys();  
  this.getCodesMatLogAreaofuse();
};

  getMaterialDimensions = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialDimension/getMaterialDimensionResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialDimensions: response.data,
        MaterialDimensionsKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialDimensions: [],
        MaterialDimensionsKayit:0,
     });
    }
  } 
  
  getMaterialWarrantys = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialWarranty/getMaterialWarrantyResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialWarrantys: response.data,
        MaterialWarrantysKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialWarrantys: [],
        MaterialWarrantysKayit:0,
     });
    }
  }   
  
  getMaterialIdentifications = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      id: materialId
    } 
    const response = await handleRequest("POST", "/MaterialIdentification/getMaterialIdentificationResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialIdentifications: response.data,
        MaterialIdentificationsKayit:response.data.length,
        hiddenDataGirisMaterialIdentification:true
       });
    } else { 
      this.setState({ 
        MaterialIdentifications: [],
        MaterialIdentificationsKayit:0,
        hiddenDataGirisMaterialIdentification:false
     });
    }
  }

  getMaterialClients = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialClient/getMaterialClientResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialClients: response.data,
        MaterialClientsKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialClients: [],
        MaterialClientsKayit:0,
     });
    }
  }

  getManufacturings = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialManufacturing/getMaterialManufacturingResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        Manufacturings: response.data,
        ManufacturingsKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        Manufacturings: [],
        ManufacturingsKayit:0,
     });
    }
  }

  getMaterialPackings = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialPacking/getMaterialPackingResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialPackings: response.data,
        MaterialPackingsKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialPackings: [],
        MaterialPackingsKayit:0,
     });
    }
  }

  getMaterialHandlings = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialHandling/getMaterialHandlingResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialHandlings: response.data,
        MaterialHandlingsKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialHandlings: [],
        MaterialHandlingsKayit:0,
     });
    }
  }

  getMaterialTransportations = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialTransportation/getMaterialTransportationResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialTransportations: response.data,
        MaterialTransportationsKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialTransportations: [],
        MaterialTransportationsKayit:0,
     });
    }
  }

  getMaterialMaintenances = async () => {
    var materialId="-1";
    if(this.state.materialLogId!=null && this.state.materialLogId!="" ){
      materialId=this.state.materialLogId;
    }
    const newObj = {
      materialId: materialId
    } 
    const response = await handleRequest("POST", "/MaterialMaintenance/getMaterialMaintenanceResult", newObj); 

    if (response.data.length !== 0) {
      this.setState({ 
        MaterialMaintenances: response.data,
        MaterialMaintenancesKayit:response.data.length,
       });
    } else { 
      this.setState({ 
        MaterialMaintenances: [],
        MaterialMaintenancesKayit:0,
     });
    }
  }

  createNewClientButton = async () => {
     if (this.state.createNewButton == "Create New") {
       this.setState({
         addModal:false,
         hiddenSave: false,
         hiddenUpdate:true,
         hiddenDataGiris:false,
         createNewButton: "Cancel",
         lastButton: "Save",
         createNewButtonVariant: "danger",
         hiddenDataGirisMaterialIdentification:false
       });
       this.refreshAccordions("MaterialIdentification");
     }
     else {
       this.setState({
         addModal:false,
         hiddenSave: true,
         hiddenUpdate:false,
         hiddenDataGiris:true,
         createNewButton: "Create New",
         lastButton: "Save",
         createNewButtonVariant: "info",
         hiddenDataGirisMaterialIdentification:false
       });
       this.refreshAccordions("MaterialIdentification");
     }
  }
 
  changeButtonCancel = () => {
    this.setState({
      hiddenSave: true,
      hiddenUpdate:false,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",
    });
    this.resetInputs();
    this.refreshAccordions();
  };

  updateDone = async () => {
    if ( this.state.MaterialIdentificationsKayit>0){
      var updateMaterialIdentification = {
        id: this.state.MaterialIdentifications[0].id,
        materialCode: this.state.MaterialIdentifications[0].materialCode,
        materialName: this.state.MaterialIdentifications[0].materialName,
        materialTypeId: this.state.MaterialIdentifications[0].materialTypeId,
        disciplineId: this.state.MaterialIdentifications[0].disciplineId,
        departmentId: this.state.MaterialIdentifications[0].departmentId,
        inspectionlevel: this.state.MaterialIdentifications[0].inspectionlevel,
        switchMaterial: this.state.MaterialIdentifications[0].switchMaterial,
        switchPermanent: this.state.MaterialIdentifications[0].switchPermanent,
        switchEngineering: this.state.MaterialIdentifications[0].switchEngineering,
        switchUsable: this.state.MaterialIdentifications[0].switchUsable,
        switchInspectible: this.state.MaterialIdentifications[0].switchInspectible,
        remarks:this.state.MaterialIdentifications[0].remarks,
        identificationFilePath: this.state.MaterialIdentifications[0].identificationFilePath,
        barcode:this.state.MaterialIdentifications[0].barcode, 
        areaOfUse: this.state.MaterialIdentifications[0].areaOfUse,  
        requiredQuantity: this.state.MaterialIdentifications[0].requiredQuantity,  
        dimensions:this.state.MaterialDimensions,
        materialClients:this.state.MaterialClients,
        materialManufacturings: this.state.Manufacturings,
        materialPackings:this.state.MaterialPackings,
        materialHandlings:this.state.MaterialHandlings,
        materialTransportations:this.state.MaterialTransportations,
        materialMaintenances:this.state.MaterialMaintenances, 
        materialWarranties:this.state.MaterialWarrantys, 
      };
      var response = await handleRequest("POST", "/MaterialIdentification/update", updateMaterialIdentification);

    if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.createNewClientButton();
        }
        else   {
            this.showError();
        } 
      } 
      else {
        this.showError();
      }   
    }
    else {
      this.showWarning();
    } 
  };

  rowExpansionTemplate(data) {
    return <MaterialLogSub row={data} />;
  }

  renderMaterialTypeFilter() {
    return (
      <Cascader
        options={this.state.nodes}
        onChange={this.onMaterialTypeFilterChange}
        changeOnSelect
      />
    );
  }

  onMaterialTypeFilterChange(value, data) {
    //-------------------------------------------------------
    //ÇALIŞAN KISIM -- Seçili en son materialTypeName'e göre filtre yapar.
    // if (data.length !== 0) {
    //     this.dt.filter(data[data.length - 1].label, 'materialType.materialTypeName', 'equals');
    // } else {
    //     this.dt.filter('','materialType.materialTypeName', 'equals');
    // }
    //-------------------------------------------------------

    //-------------------------------------------------------
    //ÇALIŞAN KISIM -- Seçili tüm materialTypeName'lere göre filtre yapar.
    var flagNamesArray = data.map((o) => o.label);
    if (flagNamesArray.length !== 0) {
      this.dt.filter(flagNamesArray, "materialType.materialTypeName", "in");
    } else {
      this.dt.filter("", "materialType.materialTypeName", "in");
    }
    //-------------------------------------------------------
  }

  renderDepartmentFilter() {
    return (
      <MultiSelect
        className="p-column-filter"
        value={this.state.selectedDepartments}
        options={this.state.departmentsForFilter}
        onChange={this.onDepartmentFilterChange}
        placeholder="All"
        optionLabel="name"
        optionValue="name"
        filter
      />
    );
  }

  onDepartmentFilterChange(event) {
    this.dt.filter(event.value, "department.departmentName", "in");
    this.setState({ selectedDepartments: event.value });
  }

  renderDisciplineFilter() {
    return (
      <MultiSelect
        className="p-column-filter"
        value={this.state.selectedDisciplines}
        options={this.state.disciplinesForFilter}
        onChange={this.onDisciplineFilterChange}
        placeholder="All"
        optionLabel="name"
        optionValue="name"
        filter
      />
    );
  }

  onDisciplineFilterChange(event) {
    this.dt.filter(event.value, "discipline.disciplineName", "in");
    this.setState({ selectedDisciplines: event.value });
  }

  actionTemplate(rowData) {
    if (rowData.data.id!=null && rowData.data.id!=undefined && rowData.data.id!=""){
      return <React.Fragment> <CustomMaterialMenuMaterialLog row={rowData} 
      onDeleteRow={this.deleteMaterialLog.bind(this)} 
      onEditRow={this.editMaterialLog.bind(this)} 
      onViewRow={this.viewMaterialLog.bind(this)} 
      />   
      </React.Fragment>;
    }else{
      return "";
    }
  }; 

  deleteMaterialLog = (rowData) => {
    this.setState({
      deleteModal: true,
      materialLogId: rowData.id,
    });
  };

  deleteMaterialLogOK = async () => {
    const deletedMaterialLog = {
      id: this.state.materialLogId,
    };

    var response = await handleRequest("POST", "/MaterialIdentification/delete", deletedMaterialLog);
    
    if(response.data!==null && response.data!==undefined){
        if(response.data==="SUCCESS")
       {    
          this.setState({
              deleteModal: false,
          });
          this.restartTable();
          this.showSuccess();
       } 
       else  {
            this.showError(); 
       }  
    }else {
      this.showError();
    } 
  };

  restartTable = async () => {
    const response = await handleRequest("GET", "/MaterialIdentification/getTypeMaterialIdentifications");
    console.log("RRR", response.data);
    if (response.data.length === 0) {
      this.setState({
        MaterialLogs: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        MaterialLogs: response.data,
      });
    }
  };

  showOrHideModalDeleteMaterialLog = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
  };

  showOrHideModalAddMaterialLog = () => {
    this.resetInputs();

    if(this.state.createNewButton!="Cancel"){
      this.setState({
        addModal: !this.state.addModal,
        hiddenDataGiris:true,
      }); 

      // if(this.state.modalKontrol==true){
      //   this.setState({
      //     addModal: true,
      //     hiddenDataGiris:true,
      //     modalKontrol:false,
      //   }); 
      // }else{
      //   this.createNewClientButton();
      // }
    }else{
      this.setState({
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info",
        hiddenDataGiris:true,
      });
    }

    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  showOrHideModalPromptMaterialLog = () => {
    this.resetInputs();
    this.setState({
      addModal: false,
      promptModal: !this.state.promptModal, 
      department: "Select Department",
      discipline: "Select Discipline", 
      createNewButton: "Create New", 
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
  };  
  
  showOrHideModalPromptMaterialLogSub = () => { 
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);

    this.setState({ 
      promptMaterialLogSub:false,
      showOrHideModalPromptMaterialLogSub: !this.state.showOrHideModalPromptMaterialLogSub,  
    });
  };

  viewMaterialLog = async (row) => { 
    await this.setState({ 
      promptMaterialLogSub:true,
      MaterialLogSubId:row.data.id
    });
  }

  editMaterialLog = async (row) => { 
    console.log(row);
    this.createNewClientButton();  
    var deger = dataList.filter(p=>p.key==row.data.materialTypeId)
    if(deger!=null && deger!='undefined' && deger!=''){
        this.setState({
          values:deger[0].lineage
        });
    }else{
        this.setState({
          values:[]
        });
    }
    await this.setState({
      addModal:false,
      hiddenSave: true,
      hiddenUpdate:false,
      hiddenDataGiris:false,
      hiddenCreateNew: false,
      createNewButton: "Cancel",
     // lastButton: "Save",
      createNewButtonVariant: "danger",
      hiddenDataGirisMaterialIdentification:false,

      id: row.data.id,
      materialLogId: row.data.id,
      materialCode: row.data.materialCode,
      materialName: row.data.materialName,
      materialType: row.data.materialTypeName,
      //values: flagValues,
      materialTypeId: row.data.materialTypeId,
      department: row.data.departmentName,
      departmentId: row.data.departmentId,
      discipline: row.data.disciplineName,
      disciplineId: row.data.disciplineId,
      inspectionlevel:row.data.inspectionlevelAck,
      inspectionlevelId: row.data.inspectionlevel,
      switchMaterial: row.data.switchMaterial,
      switchPermanent: row.data.switchPermanent,
      switchEngineering: row.data.switchEngineering,
      switchUsable: row.switchUsable,
      switchInspectible: row.data.switchInspectible,
      barcode:row.data.barcode,
      areaOfUse: row.data.areaOfUse,  
      requiredQuantity: row.data.requiredQuantity,
      identificationFilePath:row.data.identificationFilePath,
      remarks:row.data.remarks,
    });

    this.getMaterialIdentifications();
    this.getMaterialDimensions();
    this.getMaterialClients();
    this.getManufacturings();
    this.getMaterialPackings();
    this.getMaterialHandlings();
    this.getMaterialTransportations();
    this.getMaterialMaintenances();
    this.getMaterialWarrantys();  
  };

  onBasicUpload() {}

  handleChangeDiscipline = (e) => {
    e.preventDefault();
    var idValue=0;

    for (var z = 0; z < this.state.disciplinesForFilter.length; z++) {
      if (this.state.disciplinesForFilter[z].name === e.target.value) {
        idValue = this.state.disciplinesForFilter[z].id;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      disciplineId: idValue,
    });
  };

  handleChangeInspectionlevel = (e) => {
    e.preventDefault();
    var idValue=0;

    for (var z = 0; z < this.state.inspectionlevelForFilter.length; z++) {
      if (this.state.inspectionlevelForFilter[z].name === e.target.value) {
        idValue = this.state.inspectionlevelForFilter[z].id;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      inspectionlevelId: idValue,
    });
  };

  handleChangeInspectible  = (value) => {
    if(value==false){
      this.setState({
        inspectionlevelId:0,
        inspectionlevel:"Inspection Level",
      });
    }
    
    this.setState({
      switchInspectible: value,
      hiddeninspectionlevel: !value,
    });
  };

  handleChangeDepartment = (e) => {
    e.preventDefault();
    var idValue=0;

    for (var z = 0; z < this.state.departmentsForFilter.length; z++) {
      if (this.state.departmentsForFilter[z].name === e.target.value) {
        idValue = this.state.departmentsForFilter[z].id;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      departmentId: idValue,
    });
  };

  onChangeMaterialType = (value, data) => {
    this.setState({
      values: value,
      selectedItems: data,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    const length = this.state.values.length;

    if (length > 0) this.setState({ parentId: this.state.values[length - 1] });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
      this.saveItem();
    }, 1000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.resetInputs();

    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
  };

  saveItem = async () => {
    if (this.state.newItem.trim() !== "") {
      const newObj = {
        materialTypeName: this.state.newItem,
        parentId: this.state.parentId,
      };

      await handleRequest("POST", "/MaterialType/add", newObj);

      const response3 = await handleRequest("GET", "/MaterialType/getNodes");

      if (response3.length === 0 || response3.data.length === 0) {
        this.setState({
          nodes: [],
        });
      } else if (response3.data.length !== 0) {
        this.setState({
          nodes: response3.data,
        });
      }
      this.resetInputs();
    }
  };
  
  addMaterialIdentification = async () => { 
    if(this.state.materialCode!=null && this.state.materialCode!="" && this.state.materialName!=null && this.state.materialName!=""
      && this.state.disciplineId!=null && this.state.disciplineId!="" && this.state.departmentId!=null && this.state.departmentId!="" ){
      var flag= true;
      const MaterialIdentifications = [];
      const materialType=[];
      const discipline=[];
      const department=[];
  
      if(MaterialIdentifications.length>0) 
      flag= true;
    
      for (let index = 0; index < MaterialIdentifications.length; index++) {
         if(MaterialIdentifications[index].checkitem.length>0){ 
          flag= true;
        }else {
          flag= false;
          break;
        }
      } 

      if(flag) {
       await this.setState({
          MaterialIdentificationsKayit:this.state.MaterialIdentificationsKayit+1,
        })

        materialType.push({materialTypeName: this.state.selectedItems[this.state.selectedItems.length - 1].label}); 
        discipline.push({disciplineName: this.state.discipline}); 
        department.push({departmentName: this.state.department}); 

        var degerswitchMaterialAck="";
        if(this.state.switchMaterial==true){
          degerswitchMaterialAck="Yes";
        }else{
          degerswitchMaterialAck="No";
        }

        var degerswitchPermanentAck="";
        if(this.state.switchPermanent==true){
          degerswitchPermanentAck="Yes";
        }else{
          degerswitchPermanentAck="No";
        }

        var degerswitchEngineeringAck="";
        if(this.state.switchEngineering==true){
          degerswitchEngineeringAck="Yes";
        }else{
          degerswitchEngineeringAck="No";
        }

        var degerswitchUsableAck="";
        if(this.state.switchUsable==true){
          degerswitchUsableAck="Yes";
        }else{
          degerswitchUsableAck="No";
        }

        var degerswitchInspectibleAck="";
        if(this.state.switchInspectible==true){
          degerswitchInspectibleAck="Yes";
        }else{
          degerswitchInspectibleAck="No";
        }

        await this.setState(state => ({
          MaterialIdentifications:[...state.MaterialIdentifications, { 
          key:this.state.MaterialIdentificationsKayit,
          id:this.state.materialLogId,
          index:this.state.MaterialIdentificationsKayit,
          materialId: this.state.materialLogId,
          materialCode: this.state.materialCode,
          materialName: this.state.materialName,
          materialTypeId: this.state.values[this.state.values.length - 1],
          materialTypeName: this.state.selectedItems[this.state.selectedItems.length - 1].label,
          disciplineId: this.state.disciplineId,
          disciplineName: this.state.discipline,
          departmentId: this.state.departmentId,
          departmentName: this.state.department,
          inspectionlevel: this.state.inspectionlevelId,
          switchMaterial: this.state.switchMaterial,
          switchPermanent: this.state.switchPermanent,
          switchEngineering: this.state.switchEngineering,
          switchUsable: this.state.switchUsable,
          switchInspectible: this.state.switchInspectible,

          switchMaterialAck: degerswitchMaterialAck,
          switchPermanentAck: degerswitchPermanentAck,
          switchEngineeringAck: degerswitchEngineeringAck,
          switchUsableAck: degerswitchUsableAck,
          switchInspectibleAck: degerswitchInspectibleAck,
          inspectionlevelAck: this.state.inspectionlevel,

          remarks:this.state.remarks,
          identificationFilePath: this.state.identificationFilePath,
          barcode:this.state.barcode, 
          materialType:materialType[0],
          discipline: discipline[0],
          department: department[0],

          identificationFilePath: this.state.identificationFilePath 
         }]
        }));

        await this.setState({
          materialCode: "",
          materialName: "",
          disciplineId: "",
          discipline: "",
          departmentId: "",
          department:"",
          inspectionlevel: "",
          inspectionlevelId:0,
          switchMaterial: "",
          switchPermanent: "",
          switchEngineering: "",
          switchUsable: "",
          switchInspectible: "",
          remarks: "", 
          barcode: "", 
          materialTypeId:"",
          materialTypeName:"",
          values: [],
          selectedItems: [],
          hiddenDataGirisMaterialIdentification:true, 
          identificationFilePath:"",
          IdentificationFileUpload:[],
          fileList:[],
        });
      } 
      this.showSuccess();
      this.refreshAccordions("MaterialDimension");
   }else{
    this.showWarning();
   }
  };

  addMaterialDimension = async () => { 
   if(this.state.materialDimension!=null && this.state.materialDimension!="" &&
    this.state.materialWeight!=null && this.state.materialWeight!=""){

      var flag= true;
      const MaterialDimensions = [];
  
      if(MaterialDimensions.length>0) 
      flag= true;
    
      for (let index = 0; index < MaterialDimensions.length; index++) {
         if(MaterialDimensions[index].checkitem.length>0){ 
          flag= true;
        }else {
          flag= false;
          break;
        }
      } 

      if(flag) {
       await this.setState({
          MaterialDimensionsKayit:this.state.MaterialDimensionsKayit+1,
        })

        await this.setState(state => ({
          MaterialDimensions:[...state.MaterialDimensions, { 
          key:this.state.MaterialDimensionsKayit,
          id:this.state.materialLogId,
          index:this.state.MaterialDimensionsKayit,
          materialId: this.state.materialLogId,
          materialDimension:this.state.materialDimension,
          materialWeight:this.state.materialWeight, 
         }]
        }));
        await this.setState({
          materialDimension:"",
          materialWeight:"",
        });
      }
      this.showSuccess();
   }else{
    this.showWarning();
   }
  };

  addMaterialWarranty = async () => { 
    if(
      this.state.warrantyPeriod!=null && this.state.warrantyPeriod!="" &&
      this.state.periodTip!=null && this.state.periodTip!="" &&
      this.state.startDate!=null && this.state.startDate!="")
     {
       var flag= true;
       const MaterialWarrantys = [];
   
       if(MaterialWarrantys.length>0) 
       flag= true;
     
       for (let index = 0; index < MaterialWarrantys.length; index++) {
          if(MaterialWarrantys[index].checkitem.length>0){ 
           flag= true;
         }else {
           flag= false;
           break;
         }
       } 
 
       if(flag) {
        await this.setState({
           MaterialWarrantysKayit:this.state.MaterialWarrantysKayit+1,
         })
 
         await this.setState(state => ({
           MaterialWarrantys:[...state.MaterialWarrantys, { 
           key:this.state.MaterialWarrantysKayit,
           id:this.state.materialLogId,
           index:this.state.MaterialWarrantysKayit,
           materialId: this.state.materialLogId,
           warrantyPeriod:this.state.warrantyPeriod,
           periodTip:this.state.periodTip, 
           periodTipAck:this.state.periodTipAck, 
           startDate: this.setDateFormat(this.state.startDate), 
           warrantyFilePath:this.state.warrantyFilePath, 
          }]
         }));
         await this.setState({
          warrantyPeriod: "",
          periodTip: "", 
          periodTipAck: "", 
          startDate: "", 
          warrantyFilePath: "", 
          WarrantyFileUpload:[],
          fileList: [],
         });
       }
       this.showSuccess();
    }else{
     this.showWarning();
    }
   };

  addMaterialClient = async () => { 
    if(this.state.materialDescription!=null && this.state.materialDescription!="" &&
     this.state.clientMaterialCode!=null && this.state.clientMaterialCode!=""){
 
       var flag= true;
       const MaterialClients = [];
   
       if(MaterialClients.length>0) 
       flag= true;
     
       for (let index = 0; index < MaterialClients.length; index++) {
          if(MaterialClients[index].checkitem.length>0){ 
           flag= true;
         }else {
           flag= false;
           break;
         }
       } 
 
       if(flag) {
        await this.setState({
           MaterialClientsKayit:this.state.MaterialClientsKayit+1,
         })
 
         await this.setState(state => ({
           MaterialClients:[...state.MaterialClients, { 
           key:this.state.MaterialClientsKayit,
           id:this.state.materialLogId,
           index:this.state.MaterialClientsKayit,
           materialId: this.state.materialLogId,
           clientMaterialCode:this.state.clientMaterialCode,
           materialDescription:this.state.materialDescription, 
           specification:this.state.specification,
           clientFilePath: this.state.clientFilePath
          }]
         }));
         await this.setState({
          clientMaterialCode:"",
          materialDescription:"",
          specification:"",
          clientFilePath:"",
          ClientFileUpload:[],
          fileList:[],
         });
       }
       this.showSuccess();
    }else{
     this.showWarning();
    }
   };
  
  addMaterialManufacturer = async () => {  
   if(this.state.materialManufacturer!=null && this.state.materialManufacturer!="" 
   && this.state.manufacturerMaterialName!=null && this.state.manufacturerMaterialName!=""
   && this.state.manufacturerMaterialCode!=null && this.state.manufacturerMaterialCode!=""
   && this.state.materialSupplier!=null && this.state.materialSupplier!="")
    {
      var flag= true;
      const Manufacturings = [];
  
      if(Manufacturings.length>0) 
      flag= true;
    
      for (let index = 0; index < Manufacturings.length; index++) {
         if(Manufacturings[index].checkitem.length>0){ 
          flag= true;
        }else {
          flag= false;
          break;
        }
      } 

      if(flag) {
       await this.setState({
        ManufacturingsKayit:this.state.ManufacturingsKayit+1,
        })

        await this.setState(state => ({
          Manufacturings:[...state.Manufacturings, { 
          key:this.state.ManufacturingsKayit,
          id:this.state.materialLogId,
          index:this.state.ManufacturingsKayit,
          materialId: this.state.materialLogId,
          materialManufacturer:this.state.materialManufacturer,
          manufacturerMaterialName:this.state.manufacturerMaterialName, 
          manufacturerMaterialCode:this.state.manufacturerMaterialCode, 
          manufacturerFilePath:this.state.manufacturerFilePath, 
          materialSupplier:this.state.materialSupplier, 
         }]
        }));
        await this.setState({
          materialManufacturer:"",
          manufacturerMaterialName:"",
          manufacturerMaterialCode:"",
          materialSupplier:"",
          manufacturerFilePath:"",
          ManufacturingFileUpload:[],
          fileList:[],
        });
      }
      this.showSuccess();
   }else{
    this.showWarning();
   }
  };

  addMaterialPacking = async () => {  
   if(this.state.rulesForPacking!=null && this.state.rulesForPacking!="")
    {
      var flag= true;
      const MaterialPackings = [];
  
      if(MaterialPackings.length>0) 
      flag= true;
    
      for (let index = 0; index < MaterialPackings.length; index++) {
         if(MaterialPackings[index].checkitem.length>0){ 
          flag= true;
        }else {
          flag= false;
          break;
        }
      } 

      if(flag) {
       await this.setState({
        MaterialPackingsKayit:this.state.MaterialPackingsKayit+1,
        })

        await this.setState(state => ({
          MaterialPackings:[...state.MaterialPackings, { 
          key:this.state.MaterialPackingsKayit,
          id:this.state.materialLogId,
          index:this.state.MaterialPackingsKayit,
          materialId: this.state.materialLogId,
          rulesForPacking:this.state.rulesForPacking,
          packingFilePath:this.state.packingFilePath,
         }]
        }));
        await this.setState({
          rulesForPacking:"",
          packingFilePath:"",
          PackingFileUpload:[],
          fileList:[],
        });
      }
      this.showSuccess();
   }else{
    this.showWarning();
   }
  };

  addMaterialHandling = async () => {  
    if(this.state.rulesForHandling!=null && this.state.rulesForHandling!="")
     {
       var flag= true;
       const MaterialHandlings = [];
   
       if(MaterialHandlings.length>0) 
       flag= true;
     
       for (let index = 0; index < MaterialHandlings.length; index++) {
          if(MaterialHandlings[index].checkitem.length>0){ 
           flag= true;
         }else {
           flag= false;
           break;
         }
       } 
 
       if(flag) {
        await this.setState({
          MaterialHandlingsKayit:this.state.MaterialHandlingsKayit+1,
         })
 
         await this.setState(state => ({
           MaterialHandlings:[...state.MaterialHandlings, { 
           key:this.state.MaterialHandlingsKayit,
           id:this.state.materialLogId,
           index:this.state.MaterialHandlingsKayit,
           materialId: this.state.materialLogId,
           rulesForHandling:this.state.rulesForHandling,
           handlingFilePath:this.state.handlingFilePath,
          }]
         }));
         await this.setState({
           rulesForHandling:"",
           handlingFilePath:"",
           HandlingFileUpload:[],
           fileList:[],
         });
       }
       this.showSuccess();
    }else{
     this.showWarning();
    }
   };

  addMaterialTransportation = async () => {  
    if(this.state.rulesForTransportation!=null && this.state.rulesForTransportation!="")
     {
       var flag= true;
       const MaterialTransportations = [];
   
       if(MaterialTransportations.length>0) 
       flag= true;
     
       for (let index = 0; index < MaterialTransportations.length; index++) {
          if(MaterialTransportations[index].checkitem.length>0){ 
           flag= true;
         }else {
           flag= false;
           break;
         }
       } 
 
       if(flag) {
        await this.setState({
          MaterialTransportationsKayit:this.state.MaterialTransportationsKayit+1,
         })
 
         await this.setState(state => ({
           MaterialTransportations:[...state.MaterialTransportations, { 
           key:this.state.MaterialTransportationsKayit,
           id:this.state.materialLogId,
           index:this.state.MaterialTransportationsKayit,
           materialId: this.state.materialLogId,
           rulesForTransportation:this.state.rulesForTransportation,
           transportationFilePath:this.state.transportationFilePath,
          }]
         }));
         await this.setState({
           rulesForTransportation:"",
           transportationFilePath:"",
           TransportationFileUpload:[],
           fileList:[],
         });
       }
       this.showSuccess();
    }else{
     this.showWarning();
    }
   };

  addMaterialMaintenance = async () => {  
    if(this.state.rulesForMaintenance!=null && this.state.rulesForMaintenance!="")
     {
       var flag= true;
       const MaterialMaintenances = [];
   
       if(MaterialMaintenances.length>0) 
       flag= true;
     
       for (let index = 0; index < MaterialMaintenances.length; index++) {
          if(MaterialMaintenances[index].checkitem.length>0){ 
           flag= true;
         }else {
           flag= false;
           break;
         }
       } 
 
       if(flag) {
        await this.setState({
         MaterialMaintenancesKayit:this.state.MaterialMaintenancesKayit+1,
         })
 
         await this.setState(state => ({
           MaterialMaintenances:[...state.MaterialMaintenances, { 
           key:this.state.MaterialMaintenancesKayit,
           id:this.state.materialLogId,
           index:this.state.MaterialMaintenancesKayit,
           materialId: this.state.materialLogId,
           rulesForMaintenance:this.state.rulesForMaintenance,
           maintenanceFilePath:this.state.maintenanceFilePath,
          }]
         }));
         await this.setState({
           rulesForMaintenance:"",
           maintenanceFilePath:"",
           MaintenanceFileUpload:[],
           fileList:[]
         });
       }
       this.showSuccess();
    }else{
     this.showWarning();
    }
   };

  onChangeInput = ({ target: { value } }) => {
    this.setState({ newItem: value });
  };

  resetInputs = async() => {
    await this.setState({
      values:[],
      selectedItems: [], 
      MaterialIdentifications:[],
      MaterialIdentificationsKayit:0,
      MaterialDimensions:[],
      MaterialDimensionsKayit:0,
      MaterialWarrantys:[],
      MaterialWarrantysKayit:0,
      MaterialClients:[],
      MaterialClientsKayit:0,
      Manufacturings:[],
      ManufacturingsKayit:0,
      MaterialPackings:[],
      MaterialPackingsKayit:0,
      MaterialHandlings:[],
      MaterialHandlingsKayit:0,
      MaterialTransportations:[],
      MaterialTransportationsKayit:0,
      MaterialMaintenances:[],
      MaterialMaintenancesKayit:0,
      startDate:null,

      IdentificationFileUpload:[],
      identificationFilePath:"",
      WarrantyFileUpload:[],
      warrantyFilePath:"",
      ClientFileUpload:[],
      clientFilePath:"",
      ManufacturingFileUpload:[],
      manufacturerFilePath:"",
      PackingFileUpload:[],
      packingFilePath:"",
      HandlingFileUpload:[],
      handlingFilePath:"",
      TransportationFileUpload:[],
      transportationFilePath:"",
      MaintenanceFileUpload:[],
      maintenanceFilePath:"",

      fileList: [],
      MaterialLogSubId: "",

      materialCode: "",
      materialName: "",
      disciplineId: "",
      discipline: "",
      departmentId: "",
      department:"",
      inspectionlevel: "",
      switchMaterial: "",
      switchPermanent: "",
      switchEngineering: "",
      switchUsable: "",
      switchInspectible: "",
      remarks: "", 
      barcode: "", 
      areaOfUse: "",  
      areaOfUseAck: "",  
      requiredQuantity: "",

      materialTypeId:"",
      materialTypeName:"", 

      materialDimension:"",
      materialWeight:"",

      warrantyPeriod: "",
      periodTip: "", 
      periodTipAck: "", 
      startDate: "",
    
      clientMaterialCode:"",
      materialDescription:"",
      specification:"",

      materialManufacturer:"",
      manufacturerMaterialName:"",
      manufacturerMaterialCode:"",
      materialSupplier:"",

      rulesForPacking:"",

      rulesForHandling:"",

      rulesForTransportation:"",

      rulesForMaintenance:"",
    });
  };

  saveMaterialIdentificationOK = async () => { 
    if ( this.state.materialCode != "" && this.state.materialName != "" &&  this.state.values.length != 0 &&
      this.state.disciplineId != 0
    ) { 
          const newMaterialIdentification = {
            materialCode: this.state.materialCode,
            materialName: this.state.materialName,
            materialTypeId: this.state.values[this.state.values.length - 1],
            disciplineId: this.state.disciplineId,
            departmentId: this.state.departmentId,
            inspectionlevel: this.state.inspectionlevelId,
            switchMaterial: this.state.switchMaterial,
            switchPermanent: this.state.switchPermanent,
            switchEngineering: this.state.switchEngineering,
            switchUsable: this.state.switchUsable,
            switchInspectible: this.state.switchInspectible,
            remarks:this.state.remarks,
            identificationFilePath: this.state.identificationFilePath,
            barcode:this.state.barcode, 
            areaOfUse: this.state.areaOfUse,
            requiredQuantity: this.state.requiredQuantity,
        };
       
       var response = await handleRequest("POST", "/MaterialIdentification/add", newMaterialIdentification);
    
       if(response.data!==null && response.data!==undefined){
           if(response.data==="SUCCESS")
          { 
            this.showSuccess();
          } 
          else  {
            this.showError(); 
          }  
       }else {
         this.showError();
      }  

      this.setState({
        promptModal: false,
      });
      this.restartTable();
    }else { 
      this.showWarning();
    }
  }; 
  
  addMaterialLog = async () => {  
    if ( this.state.MaterialIdentificationsKayit>0){
      var newMaterialIdentification = {
        materialCode: this.state.MaterialIdentifications[0].materialCode,
        materialName: this.state.MaterialIdentifications[0].materialName,
        materialTypeId: this.state.MaterialIdentifications[0].materialTypeId,
        disciplineId: this.state.MaterialIdentifications[0].disciplineId,
        departmentId: this.state.MaterialIdentifications[0].departmentId,
        inspectionlevel: this.state.MaterialIdentifications[0].inspectionlevel,
        switchMaterial: this.state.MaterialIdentifications[0].switchMaterial,
        switchPermanent: this.state.MaterialIdentifications[0].switchPermanent,
        switchEngineering: this.state.MaterialIdentifications[0].switchEngineering,
        switchUsable: this.state.MaterialIdentifications[0].switchUsable,
        switchInspectible: this.state.MaterialIdentifications[0].switchInspectible,
        remarks:this.state.MaterialIdentifications[0].remarks,
        identificationFilePath: this.state.MaterialIdentifications[0].identificationFilePath,
        barcode:this.state.MaterialIdentifications[0].barcode, 
        dimensions:this.state.MaterialDimensions,
        materialClients:this.state.MaterialClients,
        materialManufacturings: this.state.Manufacturings,
        materialPackings:this.state.MaterialPackings,
        materialHandlings:this.state.MaterialHandlings,
        materialTransportations:this.state.MaterialTransportations,
        materialMaintenances:this.state.MaterialMaintenances, 
        materialWarranties:this.state.MaterialWarrantys, 
      };

      var response = await handleRequest("POST", "/MaterialIdentification/add", newMaterialIdentification);
       if(response.data!==null && response.data!==undefined){
           if(response.data==="SUCCESS")
          { 
              this.setState({ 
                hiddenDataGiris:true,
                createNewButton: "Create New",
                lastButton: "Save",
                createNewButtonVariant: "info",
              });
              this.showSuccess();
              this.refreshAccordions();
              this.resetInputs();
          } 
          else  {
            this.showError(); 
          }  
       }else {
         this.showError();
      } 
      this.setState({
        promptModal: false,
      });
      this.restartTable();
    }else { 
      this.showWarning();
    }
  };

  handleChange = (e) => {
    e.preventDefault(); 
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangePeriod = (e) => {
    e.preventDefault(); 

    var periodTipAck="";
    if(Boolean(e.target.value)){
      if(e.target.value=="1"){
        periodTipAck="Year";
      }else if(e.target.value=="2"){
        periodTipAck="Month";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      periodTipAck:periodTipAck,
    });
  };

  addMaterialDimensionShow = () => {
    if(this.state.createNewButtonDimension=="Create New"){ 
      this.setState({
        createNewButtonDimension: "Cancel", 
        createNewButtonDimensionVariant: "danger",
        MaterialDimensionAddItems: false,
      });
    }else{
      this.setState({
        createNewButtonDimension: "Create New",
        createNewButtonDimensionVariant: "info",
        MaterialDimensionAddItems: true,
      });
    }   
  } 

  addMaterialWarrantyShow = () => {
    if(this.state.createNewButtonWarranty=="Create New"){ 
      this.setState({
        createNewButtonWarranty: "Cancel", 
        createNewButtonWarrantyVariant: "danger",
        MaterialWarrantyAddItems: false,
      });
    }else{
      this.setState({
        createNewButtonWarranty: "Create New",
        createNewButtonWarrantyVariant: "info",
        MaterialWarrantyAddItems: true,
      });
    }   
  } 

  addMaterialClientShow = () => { 
    if(this.state.createNewButtonClient=="Create New"){ 
      this.setState({
        createNewButtonClient: "Cancel", 
        createNewButtonClientVariant: "danger",
        MaterialClientAddItems: false 
      });
    }else{
      this.setState({
        createNewButtonClient: "Create New",
        createNewButtonClientVariant: "info",
        MaterialClientAddItems: true 
      });
    }  


  } 

  addMaterialManufacturerShow = () => { 
    if(this.state.createNewButtonManufacturer=="Create New"){ 
      this.setState({
        createNewButtonManufacturer: "Cancel", 
        createNewButtonManufacturerVariant: "danger",
        MaterialManufacturerAddItems: false 
      });
    }else{
      this.setState({
        createNewButtonManufacturer: "Create New",
        createNewButtonManufacturerVariant: "info",
        MaterialManufacturerAddItems: true 
      });
    }   
  } 

  addMaterialPackingShow = () => { 
    if(this.state.createNewButtonPacking=="Create New"){ 
      this.setState({
        createNewButtonPacking: "Cancel", 
        createNewButtonPackingVariant: "danger",
        MaterialPackingAddItems: false 
      });
    }else{
      this.setState({
        createNewButtonPacking: "Create New",
        createNewButtonPackingVariant: "info",
        MaterialPackingAddItems: true 
      });
    }   
  } 

  addMaterialHandlingShow = () => { 
    if(this.state.createNewButtonHandling=="Create New"){ 
      this.setState({
        createNewButtonHandling: "Cancel", 
        createNewButtonHandlingVariant: "danger",
        MaterialHandlingAddItems: false 
      });
    }else{
      this.setState({
        createNewButtonHandling: "Create New",
        createNewButtonHandlingVariant: "info",
        MaterialHandlingAddItems: true 
      });
    }   
  } 

  addMaterialTransportationShow = () => { 
    if(this.state.createNewButtonTransportation=="Create New"){ 
      this.setState({
        createNewButtonTransportation: "Cancel", 
        createNewButtonTransportationVariant: "danger",
        MaterialTransportationAddItems: false 
      });
    }else{
      this.setState({
        createNewButtonTransportation: "Create New",
        createNewButtonTransportationVariant: "info",
        MaterialTransportationAddItems: true 
      });
    }   
  } 

  addMaterialMaintenanceShow = () => { 
    if(this.state.createNewButtonMaintenance=="Create New"){ 
      this.setState({
        createNewButtonMaintenance: "Cancel", 
        createNewButtonMaintenanceVariant: "danger",
        MaterialMaintenanceAddItems: false 
      });
    }else{
      this.setState({
        createNewButtonMaintenance: "Create New",
        createNewButtonMaintenanceVariant: "info",
        MaterialMaintenanceAddItems: true 
      });
    }   
  } 

  showSuccess(msg) {
      if (!msg) {
          msg = 'Successfull';
      }
      this.setState({
          modalMessage: true,
          message: msg,
          messageTip: "Success"
      });
  };

  showError(msg) {
      if (!msg) {
          msg = 'An error was occured please try again later !';
      }
      this.setState({
          modalMessage: true,
          message: msg,
          messageTip: "Error"
      });
  };

  showWarning(msg) {
      if (!msg) {
          msg = 'Something went wrong. Please check inputs.';
      }
      this.setState({
          modalMessage: true,
          message: msg,
          messageTip: "Warning"
      });
  };

  refreshAccordions = (deger) => {
    this.setState({
      MaterialDimensionAddItems: true,
      MaterialWarrantyAddItems: true,
      MaterialClientAddItems:true, 
      MaterialManufacturerAddItems:true,
      MaterialPackingAddItems:true,
      MaterialTransportationAddItems:true,
      MaterialHandlingAddItems:true,
      MaterialMaintenanceAddItems:true, 
      MaterialIdentificationAddItems: false,
    });
    if(deger=='MaterialIdentification'){
      document.getElementById('MaterialIdentification').className = "collapse show";
    }else{
      document.getElementById('MaterialIdentification').className = "collapse";
    }
    if(deger=="MaterialDimension"){
      document.getElementById('MaterialDimension').className = "collapse show"; 
    }else{
      document.getElementById('MaterialDimension').className = "collapse"; 
    }
    document.getElementById('ClientsMaterialInformation').className = "collapse ";
    document.getElementById('ManufacturingInformation').className = "collapse ";
    document.getElementById('Packing').className = "collapse ";
    document.getElementById('Handling').className = "collapse ";
    document.getElementById('Transportation').className = "collapse ";
    document.getElementById('Stocking').className = "collapse ";
    document.getElementById('Maintenance').className = "collapse ";
    document.getElementById('AccessoriesandSpareParts').className = "collapse ";
    document.getElementById('MaterialWarranty').className = "collapse ";
  }

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }
 
  renderDownloadFile(rowData) { 
    if (rowData!==null && rowData!=="" && rowData!==undefined){
      return <Avatar size={64} src={rowData} onClick={() => this.showReport(rowData)} /> 
    return "";
    } 
  };

  renderDownloadButton(rowData, column) {
    if (rowData.data !== null && rowData.data !== undefined && rowData.data.identificationFilePath !== null && rowData.data.identificationFilePath !== undefined && rowData.data.identificationFilePath !== "")
      return <Avatar size={64} src={rowData.data.identificationFilePath} onClick={() => this.showReport(rowData.data.identificationFilePath)} /> 
    return "";
};
  
showReport = async (fileRealUrl) => {
    try {
      if (fileRealUrl.endsWith('.pdf')) {
        this.setState({
          pdfFile: fileRealUrl,
          modalPdfViewer: true,
        });
      } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
        || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
  
        this.setState({
          imageUrl: fileRealUrl,
          modalPhotoShow: true,
        })
      } else {
        window.location.href = fileRealUrl;
      }
    } catch (error) { 
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found."/>);
    }
};

showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

showOrHideModalMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  });
  setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
}

handleChangeMatLogAreaofuse = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.MatLogAreaofuses.length; z++) {
      if (this.state.MatLogAreaofuses[z].id == e) {
          deger = this.state.MatLogAreaofuses[z].name;
      }
  }
  this.setState({
    areaOfUseAck: deger,
    areaOfUse: e,
  });
};

addMatLogAreaofuses = async () => {
  const { itemMatLogAreaofuses } = this.state;
  if (itemMatLogAreaofuses != null && itemMatLogAreaofuses != "" && itemMatLogAreaofuses != undefined) {
      const newItem = {
          name: itemMatLogAreaofuses,
          type: "MatLogAreaofuse"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
          itemMatLogAreaofuses: '',
      });
      this.getCodesMatLogAreaofuse();
  } else {
      this.showWarning();
  }
};

getCodesMatLogAreaofuse = async () => {
  const response = await handleRequest("GET", "/Code/type/MatLogAreaofuse");

  if (response.data !== null && response.data !== undefined) {
      this.setState({
          MatLogAreaofuses: response.data
      });
  }
}

  render() {
    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };

  const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
      ) => {
      return defaultLayout(
          isSidebarOpened,
          container,
          main,
          toolbar(renderToolbar),
          sidebar,
      );
  };

  const renderToolbar = (toolbarSlot) => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'center',
          }}
        >
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.previousPageButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.nextPageButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomOutButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomPopover}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomInButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.downloadButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.moreActionsPopover}
          </div>
        </div>
      </div>
    );
  };

    let optionsDepartments = this.state.departmentsForFilter.map((data) => (
      <option key={data.id} value={data.name}>
        {data.name}
      </option>
    ));

    let optionsDisciplines = this.state.disciplinesForFilter.map((data) => (
      <option key={data.id} value={data.name}>
        {data.name}
      </option>
    ));
  
    let optionsinspectionlevels = this.state.inspectionlevelForFilter.map((data) => (
      <option key={data.id} value={data.name}>
        {data.name}
      </option>
    ));

    let optionsSpecifications ;

    const materialTypeFilter = this.renderMaterialTypeFilter();
    const departmentFilter = this.renderDepartmentFilter();
    const disciplineFilter = this.renderDisciplineFilter();
    let actionHeader = (
      <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>
    );
      return ( 
        <div style={{ backgroundColor: "white", padding: "10px" }}>
        
          <Modal
            show={this.state.deleteModal}
            onHide={this.showOrHideModalDeleteMaterialLog}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete This?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ textAlign: "center", color: "black" }}>
                Do you want to delete this Material Log Data?
              </p>
              <p style={{ textAlign: "center", color: "black" }}>
                This process will delete all related datas.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <ReactButton
                variant="secondary"
                onClick={this.showOrHideModalDeleteMaterialLog}
              >
                Close
              </ReactButton>
              <ReactButton variant="danger" onClick={this.deleteMaterialLogOK}>
                Delete
              </ReactButton>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.addModal}
            onHide={this.showOrHideModalAddMaterialLog}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Record
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactButton
                onClick={this.showOrHideModalPromptMaterialLog}
                style={{ width: "100%", marginBottom: "2rem" }}
                variant="success"
              >
                Prompt Record
              </ReactButton>
              <ReactButton
                onClick={this.createNewClientButton}
                style={{ width: "100%", marginBottom: "1rem" }}
                variant="warning"
              >
                Normal Record
              </ReactButton>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.visible}
            onHide={this.handleCancel}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Material Type
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form style={{ marginTop: "2rem" }}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label style={{ marginTop: "0.5rem" }}>
                      New Material Type Name:
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <InputText
                      style={{ width: "100%" }}
                      onChange={this.onChangeInput}
                      value={this.state.newItem}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <ReactButton variant="secondary" onClick={this.handleCancel}>
                Close
              </ReactButton>
              <ReactButton variant="info" onClick={this.handleOk}>
                Add
              </ReactButton>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.promptModal}
            onHide={this.showOrHideModalPromptMaterialLog}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                New Record
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.hiddenModalIdentification} >
              <div className="p-grid p-fluid">
                <div className="p-col-12">
                  <form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Material Type:
                      </Form.Label>
                      <Col sm="4">
                        <Cascader
                         style={{ width: "100%" }}
                        value={this.state.values}
                        options={this.state.nodes}
                        onChange={this.onChangeMaterialType}
                        changeOnSelect
                          />
                      </Col>
                      <Col sm="2">
                        <AntdButton onClick={this.showModal} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}>
                        Add New
                      </AntdButton>
                      </Col>
                      <Col sm="2">
                        {/* <Upload {...props}
                          showUploadList={true}
                          fileList={this.state.IdentificationFileUpload}
                          onChange={({ fileList }) => {
                            if (fileList.length == 1) {  
                              var identificationFilePath="";
                              if(Boolean(fileList[0].response)){
                                identificationFilePath=fileList[0].response.url;
                              }
                                this.setState({
                                  IdentificationFileUpload:fileList,
                                  identificationFilePath:identificationFilePath,
                                });
                            }
                        }}
                        beforeUpload={(fileList) => {
                          if (this.state.IdentificationFileUpload.length > 0) {
                              message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                              return false;
                          }
                          return true;
                        }}
                        onRemove={({ fileList }) => {
                          this.setState( {
                            IdentificationFileUpload:[], 
                            identificationFilePath:"",
                          })
                        }
                      }>
                        <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                          <UploadOutlined /> Attach photo of material
                        </AntdButton>
                      </Upload>*/}
                      </Col> 

                  <Col sm="1">
                  </Col>

                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                        Material Code:
                      </Form.Label>
                      <Col sm="4">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({ materialCode: e.target.value })
                        }
                        value={this.state.materialCode} />
                      </Col>
                      <Col sm="2">
                        
                      </Col>
                      <Col sm="2">
                        {/* <AntdButton type="primary">(Auto QR ve barcode)</AntdButton> */}
                      </Col>
                      <Col sm="1">
                      </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                        Material Name:
                      </Form.Label>
                      <Col sm="4">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({
                            materialName: e.target.value,
                          })
                        }
                        value={this.state.materialName}
                      />
                      </Col>
                      <Col sm="5">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                      Remarks:
                      </Form.Label>
                      <Col sm="4">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({
                            remarks: e.target.value,
                          })
                        }
                        value={this.state.remarks}
                      />
                      </Col>
                      <Col sm="5">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                    Department:
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                        name="department"
                        value={this.state.department}
                        onChange={this.handleChangeDepartment}
                        as="select"
                      >
                        <option value="">Select Department</option>
                        {optionsDepartments}
                      </Form.Control>
                      </Col>
                      <Col sm="5">
                      </Col> 
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                    Discipline:
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                        name="discipline"
                        value={this.state.discipline}
                        onChange={this.handleChangeDiscipline}
                        as="select"
                      >
                        <option value="">Select Discipline</option>
                        {optionsDisciplines}
                      </Form.Control>
                      </Col>
                      <Col sm="5">
                      </Col> 
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                      Required Quantity:
                      </Form.Label>
                      <Col sm="4">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({
                            requiredQuantity: e.target.value,
                          })
                        }
                        value={this.state.requiredQuantity}
                      />
                      </Col>
                      <Col sm="5">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                      Area of use:
                      </Form.Label>
                      <Col sm="4">
                        <Select
                          name="areaOfUse"
                          style={{ width: '100%' }}
                          value={this.state.areaOfUseAck}
                          onChange={this.handleChangeMatLogAreaofuse}

                          dropdownRender={menu => (
                              <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                      <Input style={{ flex: 'auto' }} value={this.state.itemMatLogAreaofuses}
                                          onChange={(event) => { this.setState({ itemMatLogAreaofuses: event.target.value }) }} />
                                      <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                          onClick={this.addMatLogAreaofuses} >
                                          <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add"/>  </a>
                                  </div>
                              </div>
                          )}>
                          {this.state.MatLogAreaofuses.map(i => (
                              <Option key={i.id} value={i.id}>{i.name}</Option>
                          ))}
                      </Select>
                      </Col>
                      <Col sm="5">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                      Material Group:
                      </Form.Label>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Project material"
                        unCheckedChildren="Non-Project material"
                        checked={this.state.switchMaterial}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchMaterial: value });
                        }}
                      />
                      </Col>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Permanent"
                        unCheckedChildren="Non-Permanent"
                        checked={this.state.switchPermanent}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchPermanent: value });
                        }}
                      />
                      </Col> 
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Engineering"
                        unCheckedChildren="Non-Engineering"
                        checked={this.state.switchEngineering}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchEngineering: value });
                        }}
                      />
                      </Col> 
                  </Form.Group>
                  
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}> 
                      <Col sm="3"></Col>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Re-Usable"
                        unCheckedChildren="Non-Usable"
                        checked={this.state.switchUsable}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchUsable: value });
                        }}
                      />
                      </Col>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Inspectible"
                        unCheckedChildren="Non-Inspectible"
                        checked={this.state.switchInspectible}
                        defaultChecked
                        onChange={this.handleChangeInspectible}
                      />
                      </Col> 
                      <Col sm="3">
                    <Form.Control
                      hidden={this.state.hiddeninspectionlevel}
                      name="inspectionlevel"
                      value={this.state.inspectionlevel}
                      onChange={this.handleChangeInspectionlevel}
                      style={{ width: "100%" }}
                      as="select"
                    >
                      <option value="0">Inspection Level</option>
                      {optionsinspectionlevels}
                    </Form.Control>
                      </Col> 
                  </Form.Group>

                  </form>
                  </div>
                </div>
                </div> 
            </Modal.Body>
            <Modal.Footer>
              <ReactButton
                variant="secondary"
                onClick={this.showOrHideModalPromptMaterialLog}
              >
                Close
              </ReactButton>
              <ReactButton variant="info" onClick={this.saveMaterialIdentificationOK}>
                Save
              </ReactButton>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.promptMaterialLogSub}
            onHide={this.showOrHideModalPromptMaterialLogSub}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.hiddenModalMaterialLogSub} >
                <div className="p-grid p-fluid">
                  <div className="p-col-12">
                  <MaterialLogSub row={this.state.MaterialLogSubId} />
                  </div>
                </div>
              </div> 
            </Modal.Body>
          </Modal>

          <Modal
            size="md"
            show={this.state.modalPhotoShow}
            onHide={this.showOrHidePhotoModal}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.imageUrl !== "" ? (
                <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
                />
            ) : (
                <p>Photo has not been uploaded.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
            <ReactButton variant="info" onClick={this.showOrHidePhotoModal}>
                Close
            </ReactButton>
            </Modal.Footer>
        </Modal>

          <Modal
              size="xl"
              show={this.state.modalPdfViewer}
              onHide={this.showOrHideModalPdfViewer}
              backdrop="static"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  PDF Report Viewer
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                  <div style={{ height: "900px" }}>
                  <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                  </div>
              </Worker> 

              </Modal.Body>
              <Modal.Footer>
              <ReactButton
                  variant="secondary"
                  onClick={this.showOrHideModalPdfViewer}
              >
                  Close
                  </ReactButton>
              </Modal.Footer>
          </Modal>
          
          <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  <Row >
                      <Col sm="12">
                          {this.state.messageTip == "Success" &&
                              <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                          {this.state.messageTip == "Warning" &&
                              <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                          {this.state.messageTip == "Error" &&
                              <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                      </Col>
                  </Row>
              </Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <Row >
                  <Col sm="12">
                      {this.state.message}
                  </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
              <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></ReactButton>
          </Modal.Footer>
      </Modal>


          <div style={{ padding: "8px 16px 8px 24px" }}>
            <Row>
              <Col xs={9}></Col>
                <Col xs={3}>
                  <ReactButton onClick={this.showOrHideModalAddMaterialLog} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >
                    {this.state.createNewButton}
                  </ReactButton>
                </Col>
            </Row>
          </div>
          <br />
          <div hidden={this.state.hiddenDataGiris} className="col-md-12">
          <br />
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Material Identification</Card.Header></Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse id="MaterialIdentification" eventKey="0" >
              <Card.Body>
                <Form>
            <div style={{ backgroundColor: "white", padding: "10px" }} >
              <div className="p-grid p-fluid">
                <div className="p-col-12">
                  <form>
                    <div hidden={this.state.hiddenDataGirisMaterialIdentification}>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Material Type:
                      </Form.Label>
                      <Col sm="3">
                        <Cascader
                         style={{ width: "100%" }}
                        value={this.state.values}
                        options={this.state.nodes}
                        onChange={this.onChangeMaterialType}
                        changeOnSelect
                          />
                      </Col>
                      <Col sm="2">
                        <AntdButton onClick={this.showModal} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}>
                        Add New
                      </AntdButton>
                      </Col>
                      <Col sm="1">
                      </Col>
                      <Col sm="3">
                            <Upload {...props}
                            showUploadList={true}
                            fileList={this.state.IdentificationFileUpload}
                            onChange={({ fileList }) => {
                              if (fileList.length == 1) {  
                                var identificationFilePath="";
                                if(Boolean(fileList[0].response)){
                                  identificationFilePath=fileList[0].response.url;
                                }
                                  this.setState({
                                    IdentificationFileUpload:fileList,
                                    identificationFilePath:identificationFilePath,
                                  });
                              }
                          }}
                          beforeUpload={(fileList) => {
                            if (this.state.IdentificationFileUpload.length > 0) {
                                message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                                return false;
                            }
                            return true;
                          }}
                          onRemove={({ fileList }) => {
                            this.setState( {
                              IdentificationFileUpload:[], 
                              identificationFilePath:"",
                            })
                          }
                        }>
                        <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                          <UploadOutlined /> Attach photo of material
                          </AntdButton>
                        </Upload>
                      </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                        Material Code:
                      </Form.Label>
                      <Col sm="3">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({ materialCode: e.target.value })
                        }
                        value={this.state.materialCode} />
                      </Col>
                      <Col sm="3">
                        
                      </Col>
                      <Col sm="3">
                        <AntdButton type="primary" style={{ width: "100%", marginTop: "0.2rem" }}>(Auto QR ve barcode)</AntdButton>
                      </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                        Material Name:
                      </Form.Label>
                      <Col sm="3">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({
                            materialName: e.target.value,
                          })
                        }
                        value={this.state.materialName}
                      />
                      </Col>
                      <Col sm="6">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                      Remarks:
                      </Form.Label>
                      <Col sm="3">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({
                            remarks: e.target.value,
                          })
                        }
                        value={this.state.remarks}
                      />
                      </Col>
                      <Col sm="6">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                    Department:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                        name="department"
                        value={this.state.department}
                        onChange={this.handleChangeDepartment}
                        as="select"
                      >
                        <option value="">Select Department</option>
                        {optionsDepartments}
                      </Form.Control>
                      </Col>
                      <Col sm="6">
                      </Col> 
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                    Discipline:
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                        name="discipline"
                        value={this.state.discipline}
                        onChange={this.handleChangeDiscipline}
                        as="select"
                      >
                        <option value="">Select Discipline</option>
                        {optionsDisciplines}
                      </Form.Control>
                      </Col>
                      <Col sm="6">
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="3">
                      Material Group:
                      </Form.Label>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Project material"
                        unCheckedChildren="Non-Project material"
                        checked={this.state.switchMaterial}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchMaterial: value });
                        }}
                      />
                      </Col>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Permanent"
                        unCheckedChildren="Non-Permanent"
                        checked={this.state.switchPermanent}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchPermanent: value });
                        }}
                      />
                      </Col> 
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Engineering"
                        unCheckedChildren="Non-Engineering"
                        checked={this.state.switchEngineering}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchEngineering: value });
                        }}
                      />
                      </Col> 
                  </Form.Group>
                  
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}> 
                      <Col sm="3"></Col>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Re-Usable"
                        unCheckedChildren="Non-Usable"
                        checked={this.state.switchUsable}
                        defaultChecked
                        onChange={(value) => {
                          this.setState({ switchUsable: value });
                        }}
                      />
                      </Col>
                      <Col sm="3">
                        <Switch
                        style={{ width: "100%", marginTop: "0.2rem" }}
                        checkedChildren="Inspectible"
                        unCheckedChildren="Non-Inspectible"
                        checked={this.state.switchInspectible}
                        defaultChecked
                        onChange={this.handleChangeInspectible}
                      />
                      </Col> 
                      <Col sm="3">
                      
                    <Form.Control
                      hidden={this.state.hiddeninspectionlevel}
                      name="inspectionlevel"
                      value={this.state.inspectionlevel}
                      onChange={this.handleChangeInspectionlevel}
                      style={{ width: "100%" }}
                      as="select"
                    >
                        <option value="0">Inspection Level</option>
                        {optionsinspectionlevels}
                    </Form.Control>
                      </Col> 
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="3">
                      </Col>
                      <Col sm="6">
                      <ReactButton onClick={this.addMaterialIdentification} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="3">
                      </Col>
                  </Form.Group> 
                  </div>

                  <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Material Type</th>
                          <th>Material Code</th>
                          <th>Material Name</th>
                          <th>Remarks</th>
                          <th>Department</th>
                          <th>Discipline</th>
                          <th>Project Material</th>
                          <th>Permanent</th>
                          <th>Re-Usable</th>
                          <th>Engineering</th>
                          <th>Inspectible</th>
                          <th>Inspection Level</th>
                          <th>Photo</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialIdentifications.map(idetification => (
                            <tr key={idetification.index}>
                            <td>{idetification.index}</td>
                            <td>{idetification.materialTypeName}</td>
                            <td>{idetification.materialCode}</td>
                            <td>{idetification.materialName}</td>
                            <td>{idetification.remarks}</td>
                            <td>{idetification.department.departmentName}</td>
                            <td>{idetification.discipline.disciplineName}</td> 
                            <td>{idetification.switchMaterialAck}</td>
                            <td>{idetification.switchPermanentAck}</td>
                            <td>{idetification.switchUsableAck}</td>
                            <td>{idetification.switchEngineeringAck}</td>
                            <td>{idetification.switchInspectibleAck}</td>
                            <td>{idetification.inspectionlevelAck}</td> 
                            <td>{this.renderDownloadFile(idetification.identificationFilePath)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>  
                  </form>
                  </div>
                </div>
                </div> 
              </Form>
              </Card.Body>
            </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Material Dimension</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="MaterialDimension" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialDimensionShow} style={{ width: '100%' }} variant={this.state.createNewButtonDimensionVariant} >
                    {this.state.createNewButtonDimension}
                  </ReactButton>
                      </Col>
                    </Row>
                    <br />
                    <br />
                  
                  <div hidden={this.state.MaterialDimensionAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Form.Label column sm="2" >Dimension</Form.Label>
                      <Col sm="4">
                      <InputNumber min={1} style={{ width: '100%' }} 
                  onChange={(e) => this.setState({ materialDimension: e })} value={this.state.materialDimension} placeholder="Dimension" />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Form.Label column sm="2" >Weight</Form.Label>
                      <Col sm="4">
                      <InputNumber min={1} style={{ width: '100%' }} 
                  onChange={(e) => this.setState({ materialWeight: e })} value={this.state.materialWeight} placeholder="Weight" />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialDimension} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                  </div>
                  <br/>

                  <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Dimension</th>
                          <th>Weight</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialDimensions.map(Dimension => (
                            <tr key={Dimension.index}>
                            <td>{Dimension.index}</td>
                            <td>{Dimension.materialDimension}</td>
                            <td>{Dimension.materialWeight}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>  

                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="2">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Client's Material Information</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="ClientsMaterialInformation" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                  <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialClientShow} style={{ width: '100%' }} variant={this.state.createNewButtonClientVariant} >
                    {this.state.createNewButtonClient}  </ReactButton> 
                      </Col>
                  </Row>
                  <br />
                  <br />
                  
                  <div hidden={this.state.MaterialClientAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridClientAdds">
                      <Form.Label column sm="2" >Material Description</Form.Label>
                      <Col sm="4">
                      <Form.Control name="materialDescription" value={this.state.materialDescription}  onChange={this.handleChange} />
                      </Col>
                      <Col sm="3">
                      </Col>
                  </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Form.Label column sm="2" >Material Code</Form.Label>
                      <Col sm="4">
                      <Form.Control name="clientMaterialCode" value={this.state.clientMaterialCode} onChange={this.handleChange} />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="2">
                        Specification:
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                        name="specification"
                        value={this.state.specification}
                        onChange={this.handleChangeSpecification}
                        as="select"
                      >
                        <option value="">Select Specification</option>
                        {optionsSpecifications}
                      </Form.Control>
                      </Col>
                      <Col sm="3">

                  <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.ClientFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var clientFilePath="";
                          if(Boolean(fileList[0].response)){
                            clientFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              ClientFileUpload:fileList,
                              clientFilePath:clientFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.ClientFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        ClientFileUpload:[], 
                        clientFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach specification
                    </AntdButton>
                  </Upload>
                        
                      </Col> 
                  </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialClient} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                  </div>
                  <br/>

                  <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Material Description</th>
                          <th>Material Code</th>
                          <th>Specification</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialClients.map(client => (
                            <tr key={client.index}>
                            <td>{client.index}</td>
                            <td>{client.materialDescription}</td>
                            <td>{client.clientMaterialCode}</td>
                            <td>{client.specification}</td>
                            <td>{this.renderDownloadFile(client.clientFilePath)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>  
                  <br /> 
                  </Container> 
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="3">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Manufacturing Information</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="ManufacturingInformation" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialManufacturerShow} style={{ width: '100%' }} variant={this.state.createNewButtonManufacturerVariant} >
                    {this.state.createNewButtonManufacturer}  </ReactButton> 
                      </Col>
                  </Row>
                  <br />
                  <br />
                  
                  <div hidden={this.state.MaterialManufacturerAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridManufacturerAdds">
                      <Form.Label column sm="2" >Manufacturer</Form.Label>
                      <Col sm="4">
                      <InputText style={{ width: "100%" }}  onChange={(e) => this.setState({ materialManufacturer: e.target.value,  })  }
                      value={this.state.materialManufacturer}
                    />
                      </Col>
                      <Col sm="3">
                      </Col>
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridManufacturerAdds">
                      <Form.Label column sm="2" >Material Name</Form.Label>
                      <Col sm="4">
                      <InputText
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        this.setState({
                          manufacturerMaterialName: e.target.value,
                        })
                      }
                      value={this.state.manufacturerMaterialName} />
                      </Col>
                      <Col sm="3">
                      </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridManufacturerAdds">
                      <Form.Label column sm="2" >Material Code</Form.Label>
                      <Col sm="4">
                      <InputText
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        this.setState({
                          manufacturerMaterialCode: e.target.value,
                        })
                      }
                      value={this.state.manufacturerMaterialCode}/>
                      </Col>
                      <Col sm="3">
                      </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridManufacturerAdds">
                      <Form.Label column sm="2" >Manufacturer manual</Form.Label>
                      <Col sm="4">
                  <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.ManufacturingFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var manufacturerFilePath="";
                          if(Boolean(fileList[0].response)){
                            manufacturerFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              ManufacturingFileUpload:fileList,
                              manufacturerFilePath:manufacturerFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.ManufacturingFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        ManufacturingFileUpload:[], 
                        manufacturerFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach manual
                    </AntdButton>
                  </Upload>
                      </Col>
                      <Col sm="3"></Col>
                      <Col sm="1">
                      <ReactButton variant="success">Barcode</ReactButton>
                      </Col>
                      <Col sm="2">
                      <ReactButton variant="success">QR Code</ReactButton>
                      </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridManufacturerAdds">
                      <Form.Label column sm="2" >Supplier</Form.Label>
                      <Col sm="4">
                        <InputText
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setState({
                            materialSupplier: e.target.value,
                          })
                        }
                        value={this.state.materialSupplier}
                      />
                      </Col>
                      <Col sm="3">
                      </Col>
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialManufacturer} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                </div>

                <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Manufacturer</th>
                          <th>Material Name</th>
                          <th>Material Code</th>
                          <th>Supplier</th>
                          <th>Manufacturer Manual</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.Manufacturings.map(client => (
                            <tr key={client.index}>
                            <td>{client.index}</td>
                            <td>{client.materialManufacturer}</td>
                            <td>{client.manufacturerMaterialName}</td>
                            <td>{client.manufacturerMaterialCode}</td> 
                            <td>{client.materialSupplier}</td>
                            <td>{this.renderDownloadFile(client.manufacturerFilePath)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>  
                  <br /> 

                  </Container> 
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="4">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Packing</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="Packing" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialPackingShow} style={{ width: '100%' }} variant={this.state.createNewButtonPackingVariant} >
                    {this.state.createNewButtonPacking}  </ReactButton> 
                      </Col>
                  </Row>
                  <br />
                  <br />
                  
                <div hidden={this.state.MaterialPackingAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridPackingAdds">
                      <Form.Label column sm="2" >Rules for Packing</Form.Label>
                      <Col sm="4">
                      <InputText style={{ width: "100%" }}  onChange={(e) => this.setState({ rulesForPacking: e.target.value,  })  }
                      value={this.state.rulesForPacking}
                    />
                      </Col>
                  
                  <Col sm="3">
                    <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.PackingFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var packingFilePath="";
                          if(Boolean(fileList[0].response)){
                            packingFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              PackingFileUpload:fileList,
                              packingFilePath:packingFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.PackingFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        PackingFileUpload:[], 
                        packingFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach document
                    </AntdButton>
                  </Upload>
                  </Col>
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialPacking} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                </div>

                <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Rules for Packing</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialPackings.map(packing => (
                            <tr key={packing.index}>
                            <td>{packing.index}</td>
                            <td>{packing.rulesForPacking}</td>
                            <td>{this.renderDownloadFile(packing.packingFilePath)}</td> 
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>  
                  <br /> 
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="5">
          <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Handling</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="Handling" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialHandlingShow} style={{ width: '100%' }} variant={this.state.createNewButtonHandlingVariant} >
                    {this.state.createNewButtonHandling}  </ReactButton> 
                      </Col>
                  </Row>
                  <br />
                  <br />
                  
                <div hidden={this.state.MaterialHandlingAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridHandlingAdds">
                      <Form.Label column sm="2" >Rules for Handling</Form.Label>
                      <Col sm="4">
                      <InputText style={{ width: "100%" }}  onChange={(e) => this.setState({ rulesForHandling: e.target.value,  })  }
                      value={this.state.rulesForHandling}
                    />
                    </Col>
                    <Col sm="3">

                    <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.HandlingFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var handlingFilePath="";
                          if(Boolean(fileList[0].response)){
                            handlingFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              HandlingFileUpload:fileList,
                              handlingFilePath:handlingFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.HandlingFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        HandlingFileUpload:[], 
                        handlingFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach document
                    </AntdButton>
                  </Upload>
                    
                      
                    </Col>
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialHandling} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                </div>

                <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Rules for Handling</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialHandlings.map(handling => (
                            <tr key={handling.index}>
                            <td>{handling.index}</td>
                            <td>{handling.rulesForHandling}</td>
                            <td>{this.renderDownloadFile(handling.handlingFilePath)}</td> 
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>  
                  <br /> 
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          
          </Accordion>
          <br /> 
          <Accordion defaultActiveKey="6">
          <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Transportation</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="Transportation" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialTransportationShow} style={{ width: '100%' }} variant={this.state.createNewButtonTransportationVariant} >
                    {this.state.createNewButtonTransportation}  </ReactButton> 
                      </Col>
                  </Row>
                  <br />
                  <br />
                  
                <div hidden={this.state.MaterialTransportationAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridTransportationAdds">
                      <Form.Label column sm="2" >Rules for Transportation</Form.Label>
                      <Col sm="4">
                      <InputText style={{ width: "100%" }}  onChange={(e) => this.setState({ rulesForTransportation: e.target.value,  })  }
                      value={this.state.rulesForTransportation}
                    />
                      </Col>
                      <Col sm="3">
                        
                    <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.TransportationFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var transportationFilePath="";
                          if(Boolean(fileList[0].response)){
                            transportationFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              TransportationFileUpload:fileList,
                              transportationFilePath:transportationFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.TransportationFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        TransportationFileUpload:[], 
                        transportationFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach document
                    </AntdButton>
                  </Upload>

                      </Col>
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialTransportation} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                </div>

                <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Rules for Transportation</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialTransportations.map(transition => (
                            <tr key={transition.index}>
                            <td>{transition.index}</td>
                            <td>{transition.rulesForTransportation}</td>
                            <td>{this.renderDownloadFile(transition.transportationFilePath)}</td> 
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>  
                  <br /> 
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
        
          </Accordion>
          <br />
          <Accordion defaultActiveKey="7">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Stocking</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="Stocking" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>


                  </Container> 
                  <br /> 
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="8">
          <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Maintenance</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="Maintenance" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialMaintenanceShow} style={{ width: '100%' }} variant={this.state.createNewButtonMaintenanceVariant} >
                    {this.state.createNewButtonMaintenance}  </ReactButton> 
                      </Col>
                  </Row>
                  <br />
                  <br />
                  
                <div hidden={this.state.MaterialMaintenanceAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridMaintenanceAdds">
                      <Form.Label column sm="2" >Rules for Maintenance</Form.Label>
                      <Col sm="4">
                      <InputText style={{ width: "100%" }}  onChange={(e) => this.setState({ rulesForMaintenance: e.target.value,  })  }
                      value={this.state.rulesForMaintenance}
                    />
                      </Col>
                      <Col sm="3">
                      
                      <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.MaintenanceFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var maintenanceFilePath="";
                          if(Boolean(fileList[0].response)){
                            maintenanceFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              MaintenanceFileUpload:fileList,
                              maintenanceFilePath:maintenanceFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.MaintenanceFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        MaintenanceFileUpload:[], 
                        maintenanceFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach document
                    </AntdButton>
                  </Upload>
                      
                  </Col>
                  </Form.Group> 

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="4">
                      <ReactButton onClick={this.addMaterialMaintenance} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="6">
                      </Col>
                    </Form.Group>
                </div>

                <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Rules for Maintenance</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialMaintenances.map(maintenance => (
                            <tr key={maintenance.index}>
                            <td>{maintenance.index}</td>
                            <td>{maintenance.rulesForMaintenance}</td>
                            <td>{this.renderDownloadFile(maintenance.maintenanceFilePath)}</td>  
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>  
                  <br /> 
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          
          </Accordion>
          <br />
          <Accordion defaultActiveKey="9">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Accessories and Spare Parts</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="AccessoriesandSpareParts" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="2">
                      <ReactButton onClick={this.addMaterialAccessory} style={{ width: '100%' }} 
                      variant="info">Add Accessory</ReactButton>
                      </Col>
                      <Col sm="2">
                      <ReactButton onClick={this.addMaterialSparePart} style={{ width: '100%' }} 
                      variant="info">Spare Part</ReactButton>
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group> 
                  </Container> 
                  <br /> 
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="10">
          <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Warranty</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="MaterialWarranty" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addMaterialWarrantyShow} style={{ width: '100%' }} variant={this.state.createNewButtonWarrantyVariant} >
                    {this.state.createNewButtonWarranty}
                  </ReactButton>
                      </Col>
                    </Row>
                    <br />
                    <br />
                  
                  <div hidden={this.state.MaterialWarrantyAddItems}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Form.Label column sm="2" >Warranty Period</Form.Label>
                      <Col sm="3">
                      <InputNumber min={1} style={{ width: '100%' }} 
                  onChange={(e) => this.setState({ warrantyPeriod: e })} value={this.state.warrantyPeriod} placeholder="Warranty Period" />
                      </Col>
                      <Col sm="2">
                      <Form.Control name="periodTip" value={this.state.periodTip}
                                          onChange={this.handleChangePeriod} as="select"  >
                            <option value="0">
                                Period Type
                            </option>
                            <option value="1">
                                Year
                            </option>
                            <option value="2">
                                Month
                            </option>
                        </Form.Control>   
                      </Col>
                      <Col sm="5">
                        </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Form.Label column sm="2" >Warranty Start Date</Form.Label>
                      <Col sm="3">
                      <Calendar dateFormat="dd/mm/yy" value={this.state.startDate} showButtonBar={true} style={{ width: '200px' }}
                            onChange={(e) => this.setState({ startDate: e.value })} monthNavigator={true} autocomplete="on"
                            yearNavigator={true} yearRange="2010:2020" /> 
                      </Col>
                      <Col sm="3">
                        <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.WarrantyFileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var warrantyFilePath="";
                          if(Boolean(fileList[0].response)){
                            warrantyFilePath=fileList[0].response.url;
                          }
                            this.setState({
                              WarrantyFileUpload:fileList,
                              warrantyFilePath:warrantyFilePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.WarrantyFileUpload.length > 0) {
                          message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                          return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        WarrantyFileUpload:[], 
                        warrantyFilePath:"",
                      })
                    }
                  }>
                  <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                    <UploadOutlined /> Attach warranty certificate
                      </AntdButton>
                      </Upload>
                      </Col>  
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                      <Col sm="2">
                      </Col>
                      <Col sm="5">
                      <ReactButton onClick={this.addMaterialWarranty} style={{ width: '100%' }} variant="success">Add</ReactButton>
                      </Col>
                      <Col sm="5">
                      </Col>
                    </Form.Group>
                  </div>
                  <br/>

                  <div className="content-section implementation">
                    <Table responsive striped bordered hover>
                      <thead style={{ textAlign: 'center' }}>
                        <tr>
                          <th>S/N</th>
                          <th>Warranty period</th>
                          <th>Period Type</th>
                          <th>Warranty Start Date</th>
                          <th>File</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {this.state.MaterialWarrantys.map(Warranty => (
                            <tr key={Warranty.index}>
                            <td>{Warranty.index}</td>
                            <td>{Warranty.warrantyPeriod}</td>
                            <td>{Warranty.periodTipAck}</td>
                            <td>{this.setDateFormat(Warranty.startDate)}</td>
                            <td>{this.renderDownloadFile(Warranty.warrantyFilePath)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>  

                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          
          </Accordion>
          <br />
          <div hidden={this.state.hiddenSave}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <ReactButton onClick={this.addMaterialLog} style={{ width: '100%' }} variant="success">{this.state.lastButton}</ReactButton>
              </Row>
            </Container>
          </div>

          <div hidden={this.state.hiddenUpdate}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="3">
                </Col>
                <Col sm="2">
                  <ReactButton onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</ReactButton>
                </Col>
                <Col sm="3">
                  <ReactButton onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</ReactButton>
                </Col>
                <Col sm="3">
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="datatable-doc-demo">
            <Card>
              <div className="content-section implementation">
              <TreeTable value={this.state.MaterialLogs}>
                  <Column field="materialTypeName" header="Material Type" expander filter sortable></Column>
                  <Column field="materialCode" header="Material Code" filter sortable></Column>
                  <Column field="materialName" header="Material Name" filter ></Column>
                  <Column field="disciplineName" header="Discipline" filter sortable></Column> 
                  <Column field="areaOfUse" header="Area ofUse" filter sortable></Column> 
                  <Column field="requiredQuantity" header="Required Quantity" filter ></Column> 
                  <Column field="quantityInStock" header="Quantity in Stock" filter ></Column> 
                  <Column field="minStockQuantity" header="Min Quantity in Stock" filter ></Column> 
                  <Column field="stocks" header="Stocks" filter ></Column>        
                  <Column field="remarks" header="Remarks" filter ></Column> 
                  <Column body={this.renderDownloadButton} header="Photo" filter={true}></Column>
                  <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
              </TreeTable>

                {/* <DataTable
                  ref={(el) => (this.dt = el)}
                  responsive={true}
                  value={this.state.MaterialLogs}
                  globalFilter={this.state.globalFilter}
                  paginator={true}
                  emptyMessage="No Material Logs Found"
                  rowsPerPageOptions={[5, 10, 20]}
                  rows={10}
                  expandedRows={this.state.expandedRows}
                  onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                  rowExpansionTemplate={this.rowExpansionTemplate}
                >
                  <Column
                    expander={true}
                    style={{ textAlign: "center", width: "4em" }}
                  />
                  <Column
                    header={actionHeader}
                    body={this.actionTemplate}
                    style={{ textAlign: "center", width: "5em" }}
                  />
                  <Column
                    field="index"
                    header="S/N"
                    style={{ textAlign: "center", width: "5em" }}
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="materialType.materialTypeName"
                    header="Material Type"
                    sortable={true}
                    filter={true}
                    filterElement={materialTypeFilter}
                  />
                  <Column
                    field="materialCode"
                    header="Material Code"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="materialName"
                    header="Material Name"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="discipline.disciplineName"
                    header="Discipline"
                    sortable
                    filter
                    filterElement={disciplineFilter}
                  />
                  <Column
                    field="areaOfUse"
                    header="Area ofUse"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="requiredQuantity"
                    header="Required Quantity"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="quantityInStock"
                    header="Quantity In Stock"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="minStockQuantity"
                    header="Min Stock Quantity "
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="stocks"
                    header="Stocks"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="remarks"
                    header="Remarks"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                  />
                </DataTable>
               */}
              </div>
            </Card>
          </div>
      </div>
      );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialLog);
