/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Modal, Form as FormBoot, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { Input, Select, Button as AntButton } from 'antd';
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import IAPChecklistTemplate from "./IAPChecklistTemplate";
import { CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

export class IAPChecklist extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",

            IAPChecklistData: [],
            type: '',
            abbreviation: '',
            departmentId:0,
            disciplineSelectItems:[],
            disciplineId:0,
            relatedDisciplines:[],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null,
        };
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        await this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });
            }

        this.setStandardizations();
        this.restartTable();    
    }
    
    saveAllCheckList = async () => { 
        if(this.state.IAPChecklistData!=null && this.state.IAPChecklistData!=undefined && this.state.IAPChecklistData.length>0){
            var list = [];
            list.push(this.state.IAPChecklistData);
            var response = await handleRequest("POST", "/IAPChecklist/saveAll", this.state.IAPChecklistData);
            if (response.data) {
              if (response.data === "ERROR") {
                this.showError();
              }else if (response.data === "SUCCESS") {
                this.showSuccess();
                this.restartTable();
              }
            }
        }else{
            this.showWarning();
        }
    }

    createNewChecklist = async () => {
        if(this.state.question!=null && this.state.question!=undefined && this.state.question!=""){
        const addIAPChecklist = {
            question: this.state.question,
            name: this.state.name,
        }
        var response = await handleRequest("POST", "/IAPChecklist/add", addIAPChecklist);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.createNewClientButton();
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        }else{
            this.showWarning();
        }
    }
 
    editTemplateClick = async () => {
       this.setState({
        modalIAPChecklistTemplate:true,
       });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalIAPChecklistTemplate = () => {
        if(this.state.modalIAPChecklistTemplate){
            this.restartTable();
        }
        this.setState({
            modalIAPChecklistTemplate: !this.state.modalIAPChecklistTemplate,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    restartTable = async () => {
        const response = await handleRequest("GET", "/IAPChecklist/getAllByCompany");
        if (response.data.length !== 0) {
            const list =  response.data;
            list.forEach(element => {
                element.key=element.id;
            });
            this.setState({
                IAPChecklistData: list, 
            });
        }

        if (response.data.length === 0) {
            this.setState({
                IAPChecklistData: [],
            });
        }
    }

    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "IAPChecklist" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "IAPChecklist"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {
            console.log(error)
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    } 

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    createNewClientButton = async () => {
         if (this.state.createNewButton=="Create New") {
           this.setState({ 
            createNewButton: "Cancel",
            createNewButtonVariant: "danger",
            modalCreateNew:true,
           });
        }
        else {
           this.setState({ 
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            modalCreateNew:false,
           });
        }
    }

    showOrHideCreateNewModal = () => {
        this.setState({
            modalCreateNew: !this.state.modalCreateNew,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
          document.body.style.paddingRight = "0px";
      }, 500);
    }
    
    handleChangeStatus = (item, e) => { 
        this.setState(prevState => ({
            IAPChecklistData: prevState.IAPChecklistData.map(
            el => el.id === item.id ? { ...el, status: e } : el)
        }));
    };

    removeChecklistLine = async (item) => { 
        this.setState({
            modalDeleteShow: true,
            selectedIAPChecklist: item
        });
    }  

    deleteIAPChecklistTemplateOK = async () => {
        const newIAPChecklist = {
            id: this.state.selectedIAPChecklist.id
        }
        var response = await handleRequest("POST", "/IAPChecklist/delete", newIAPChecklist);

        if (response.length!=0 && response.data != null && response.data != undefined) {
            if (response.data === "SUCCESS") {
                this.showSuccess();
                this.showOrHideDeleteModal();
                this.restartTable();
            }
            else {
                this.showError();
            } 
        }   
        else {
            this.showError();
        } 
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    };

    render() {

        return <div>
                <MenuProvider id="menu_id"> 
                    <div>
                        <Row>
                            <Col sm="1">
                                <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>
                                }
                            </Col>
                        </Row>
                    </div> 
                    <br/>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="9">
                        </Col>
                        <Col sm="3">
                            <ReactButton id="IAPChecklistEditTemplateButton"
                                style={{ width: '100%' }} onClick={this.editTemplateClick}
                                variant="info"><FormattedMessage id="IAPChecklistEditTemplateButton" defaultMessage="Edit Template" /></ReactButton>
                        </Col>
                    </FormBoot.Group>
                    <br/>
                    {this.state.IAPChecklistData.map((item) => (
                        <FormBoot.Group key={item.index} style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label column sm="5" style={{ background: 'white' }}>
                                {item.question}
                            </FormBoot.Label>   
                            <Col sm="1"> 
                                <Select name="Status"
                                    style={{ width: '100%' }}
                                    value={item.status} 
                                    disabled={item.statusDisabled}
                                    onChange={this.handleChangeStatus.bind(this, item)}>

                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                    <Option value="N/A">N/A</Option>
                                </Select> 
                            </Col>
                            <Col sm="5">
                                <Input value={item.remarks} style={{ width: "100%" }} onChange={(e) => {
                                    e.persist();
                                    var remarks=""; 
                                    if(item.name=="Aramco Dummy PO number" || item.name=="9 com #" || item.name=="Level of Inspection, PIM"){
                                        if(!e.target.value.includes(item.remarks)){
                                            remarks = item.remarks 
                                        }else{
                                            remarks = e.target.value
                                        }
                                    }
                                    else{
                                        remarks = e.target.value
                                    }
                                   
                                    this.setState(prevState => ({
                                        IAPChecklistData: prevState.IAPChecklistData.map(
                                        el => el.id === item.id ? { ...el, remarks: remarks } : el
                                       )
                                    })) 
                                }}/>
                            </Col> 
                            <Col sm="1"> 
                                <AntButton type="primary" onClick={() => this.removeChecklistLine(item)} danger icon={<CloseOutlined />}></AntButton>
                            </Col> 
                        </FormBoot.Group>
                    ))} 

                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2">
                        </Col>
                        <Col sm="8">
                            <ReactButton id="IAPChecklistSaveButton"
                                style={{ width: '100%' }} onClick={this.saveAllCheckList}
                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                        </Col>
                        <Col sm="2">
                        </Col>
                    </FormBoot.Group>
                    }

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>
                }

            <Modal
                show={this.state.modalDeleteShow}
                onHide={this.showOrHideDeleteModal}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                    </p>
                    <p style={{ textAlign: "center", color: "black" }}>
                        <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </ReactButton>
                    <ReactButton variant="danger" onClick={this.deleteIAPChecklistTemplateOK}>
                        <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                    </ReactButton>
                </Modal.Footer>
            </Modal>
                
            <Modal
                show={this.state.modalCreateNew}
                onHide={this.showOrHideCreateNewModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="IAPChecklistCreateNewHeader" defaultMessage="Create New Material Requisition Checklist" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="2">
                        <FormattedMessage id="IAPChecklistHeader" defaultMessage="Header" /> 
                        </FormBoot.Label>   
                        <Col sm="10">
                            <Input style={{ flex: 'auto' }} value={this.state.name}
                            onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Col>
                    </FormBoot.Group>
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <FormBoot.Label style={{ color: 'black' }} column sm="2">
                        <FormattedMessage id="IAPChecklistQuestion" defaultMessage="Question" /> 
                        </FormBoot.Label>   
                        <Col sm="10">
                            <Input style={{ flex: 'auto' }} value={this.state.question}
                            onChange={(event) => { this.setState({ question: event.target.value }) }} />
                        </Col>
                    </FormBoot.Group>
                </Modal.Body>
                <Modal.Footer>
                <ReactButton variant="secondary" onClick={this.showOrHideCreateNewModal}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                <ReactButton variant="success" onClick={this.createNewChecklist}><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
            </Modal.Footer>
            </Modal>
                
            <Modal
                show={this.state.modalMessage}
                onHide={this.showOrHideModalMessage}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    <Row > 
                        <Col sm="12">
                        {this.state.messageTip=="Success" &&  
                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                        
                        {this.state.messageTip=="Warning" &&  
                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                        {this.state.messageTip=="Error" &&  
                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                        </Col>
                    </Row>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row > 
                    <Col sm="12">
                        {this.state.message} 
                    </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                </Modal.Footer>
                </Modal>

            <Modal
                show={this.state.modalIAPChecklistTemplate}
                onHide={this.showOrHideModalIAPChecklistTemplate}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="IAPChecklistTemplateHeader" defaultMessage="Material Requisition Checklist Template" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IAPChecklistTemplate id={this.state.id}/>
                </Modal.Body>
            </Modal>

            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(IAPChecklist)