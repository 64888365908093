import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt, TreeSelect } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "NotificationStep";

export class NotificationStep extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            stepName: null,
            menuId: null,
            actionId: null,
            toUserList: null,
            toUser: [],
            nextMenuId: null,
            message: null,
            actionList: [],
            nodes: [],
            notificationWorkflowId: null,
            userList: [],


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        // console.log(this.props.auth.user.authList.filter(p => p.action === "view")); 
        // try {
        //     this.setState({
        //         menuList: this.props.auth.user.authList.filter(p => p.action === "view")
        //     });

        // } catch (error) {

        // }

        // const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTree"); 

        // var items = responseDynamicMenu.data;

        // console.log(this.state.menuList);
        // console.log(items);

        // if (Boolean(items) && items.length > 0) {
        //     items[0].disabled = true;
        //     var root = [];
        //     root.push(Object.assign({}, items[0]));
        //     root[0].children = [];
        //     items[0].children.forEach((element, i) => {
        //         if (this.state.menuList.findIndex(el => el.menuId == element.menuId || el.form === "All") >= 0) {
        //             element.disabled = true;
        //             this.getMenu(element, root[0].children);
        //         }

        //     });
        // }

        // this.setState({
        //     nodes: root
        // });

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.initialize();

        const responseUser = await handleRequest("GET", "/User/getCompanyItems");

        if (Boolean(responseUser) && Boolean(responseUser.data) && responseUser.data.length !== 0) {
            var users = responseUser.data;

            const options = users.map(function (row) {
                return { name: row.email, id: row.id }
            })

            this.setState({
                userList: options,
            });
        }


    }

    componentWillMount = async () => {


        const user = await handleRequest("GET", "/User/me");
        const menuList = user.data.authList.filter(p => p.action === "view");

        this.setState({
            menuList: menuList
        });
        // const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTreeDynamicMenu");

        // var items = responseDynamicMenu.data;

        const responseDynamicMenu = await handleRequest("GET", "/DynamicMenu/getTree");  // getDynamicMenuNodes

        var items = responseDynamicMenu.data;

        console.log(menuList);


        if (Boolean(items) && items.length > 0) {
            items[0].disabled = true;
            var root = [];
            root.push(Object.assign({}, items[0]));
            root[0].children = [];
            items[0].children.forEach((element, i) => {
                if (menuList.findIndex(el => el.menuId == element.menuId || el.form === "All") >= 0) {
                    element.disabled = true;
                    this.getMenu(element, root[0].children);
                }

            });
        }
        // const _actionList = ActionsList.map(function (row) {
        //   return { label: row.label.toString(), key: row.key.toString(), title: row.label.toString() }
        // })

        console.log(root);

        this.setState({
            nodes: root
        });

    }



    componentDidUpdate = (prevProps) => {

        let notificationWorkflowId = Boolean(this.props) && Boolean(this.props.notificationWorkflowId) ? this.props.notificationWorkflowId : null;
        let prevNotificationWorkflowId = this.state.notificationWorkflowId;  //Boolean(prevProps) && Boolean(prevProps.logId) ? prevProps.logId : null;



        if (prevNotificationWorkflowId !== notificationWorkflowId) {

            this.initialize();
        }
    }

    initialize = async () => {


        this.setState({
            notificationWorkflowId: Boolean(this.props) && Boolean(this.props.notificationWorkflowId) ? this.props.notificationWorkflowId : null,
        }, this.restartTable)

    }


    getMenu = async (element, list) => {

        if (Boolean(element)) {

            if (Boolean(element.children) && element.children.length > 0)
                element.disabled = true;

            var obj = Object.assign({}, element);
            obj.children = [];
            list.push(obj);

            if (element.children) {
                element.children.forEach(el => {

                    if (this.state.menuList.findIndex(p => p.menuId == el.menuId || p.form === "All") >= 0)
                        this.getMenu(el, obj.children);
                    //  list.push(obj);
                });

            }


        }


    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            stepName: this.state.stepName,
            menuId: this.state.menuId,
            actionId: this.state.actionId,
            toUser: this.state.toUser,
            nextMenuId: this.state.nextMenuId,
            message: this.state.message,
            notificationWorkflowId:this.state.notificationWorkflowId

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/NotificationStep/add", obj);
        else
            await handleRequest("POST", "/NotificationStep/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            stepName: row.stepName,
            menuId: row.menuId,
            actionId: row.actionId,
            toUser: row.toUser,
            nextMenuId: row.nextMenuId,
            message: row.message,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            stepName: row.stepName,
            menuId: row.menuId,
            actionId: row.actionId,
            toUser: row.toUser,
            nextMenuId: row.nextMenuId,
            message: row.message,


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/NotificationStep/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/NotificationStep/getAllByCompany");

        if (response.data.length !== 0 && Boolean(this.state.notificationWorkflowId)) {
            const list = response.data.filter(p => p.notificationWorkflowId === this.state.notificationWorkflowId);
            list.forEach((element,index) => {
                element.key = element.id
                element.index = index +1 ; 
            });
            this.setState({
                tableList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            stepName: null,
            menuId: null,
            actionId: null,
            toUserList: null,
            nextMenuId: null,
            message: null
        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            stepName: null,
            menuId: null,
            actionId: null,
            toUserList: null, 
            toUser:null,
            nextMenuId: null,
            message: null,



        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    onChangeTreeSelect = (value, label, extra) => {

        var menuId = parseInt(value);
        this.fillActions(menuId);

        this.formRef.current.setFieldsValue({
            menuId: menuId
        });

        this.setState({
            menuId: menuId

        });
    };

    onChangeTreeSelect2 = (value, label, extra) => {

        var menuId = parseInt(value);


        this.formRef.current.setFieldsValue({
            nextMenuId: menuId
        });

        this.setState({
            nextMenuId: menuId

        });
    };

    fillActions = async (menuId) => {

        var obj = { dynamicMenuId: menuId }
        const response = await handleRequest("POST", "/DynamicAction/getActions", obj);

        if (response.data.length !== 0) {

            this.setState({
                actionList: response.data
            })
        }
        else this.setState({
            actionList: []
        })

    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "notificationWorkflowStep.stepName").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="notificationWorkflowStep.stepName" defaultMessage="Step Name" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="stepName"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.stepName")) ? this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.stepName").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.stepName")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.stepName").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Step Name" style={{ width: "100%" }} id="notificationWorkflowStep.stepName" value={this.state.stepName} onChange={(e) => { this.setState({ stepName: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "notificationWorkflowStep.page").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="notificationWorkflowStep.page" defaultMessage="Page" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="menuId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.page")) ? this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.page").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.page")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.page").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <TreeSelect
                                                            style={{ width: '100%' }}
                                                            value={this.state.menuId !== null && this.state.menuId > 0 && this.state.menuId.toString()}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            treeData={this.state.nodes}
                                                            placeholder="Page" id="notificationWorkflowStep.page"
                                                            treeDefaultExpandAll
                                                            onChange={this.onChangeTreeSelect}
                                                        />

                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "notificationWorkflowStep.action").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="notificationWorkflowStep.action" defaultMessage="Action" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="actionId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.action")) ? this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.action").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.action")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.action").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select style={{ width: "100%" }} placeholder="Action" id="notificationWorkflowStep.action" value={this.state.actionId} onChange={(value) => {
                                                            this.formRef.current.setFieldsValue({
                                                                actionId: value
                                                            });
                                                            this.setState({ actionId: value })
                                                        }}>
                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.actionList.filter(p => p.active === true).map(i => (
                                                                <Option key={i.id} value={i.id}>{i.actionValue}</Option>
                                                            ))}
                                                        </Select>
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "notificationWorkflowStep.to").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="notificationWorkflowStep.to" defaultMessage="To" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="toUser"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.to")) ? this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.to").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.to")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.to").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            mode="tags" style={{ width: '100%' }} placeholder="To" id="notificationWorkflowStep.to" value={this.state.toUser} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    toUser: value
                                                                });
                                                                this.setState({ toUser: value })
                                                            }}>

                                                            <Option key={null} value={null}>Select</Option>
                                                            {this.state.userList.map(i => (
                                                                <Option key={i.id} value={i.id}>{i.name}</Option>
                                                            ))}
                                                        </Select>

                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "notificationWorkflowStep.NextPage").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="notificationWorkflowStep.NextPage" defaultMessage="Next Page" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="nextMenuId"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.NextPage")) ? this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.NextPage").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.NextPage")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.NextPage").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <TreeSelect
                                                            style={{ width: '100%' }}
                                                            value={this.state.nextMenuId !== null && this.state.nextMenuId > 0 && this.state.nextMenuId.toString()}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            treeData={this.state.nodes}
                                                            placeholder="Next Page" id="notificationWorkflowStep.NextPage"
                                                            treeDefaultExpandAll
                                                            onChange={this.onChangeTreeSelect2}
                                                        />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "notificationWorkflowStep.message").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="notificationWorkflowStep.message" defaultMessage="Massage" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="message"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.message")) ? this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.message").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.message")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "notificationWorkflowStep.message").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <Input placeholder="Massage" style={{ width: "100%" }} id="notificationWorkflowStep.message" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                                    <Column title="S/N" dataIndex="index" />

                                    <Column title='Step Name' dataIndex='stepName' render={(value, row, index) => { return value; }} />
                                    <Column title='Page' render={(value, row, index) => {
                                        if (Boolean(row.menu))
                                            return row.menu.menuTitle;
                                    }} />
                                    <Column title='Action' render={(value, row, index) => {
                                        if (Boolean(row.action))
                                            return row.action.actionValue;
                                    }} />
                                    <Column title='To' render={(value, row, index) => {

                                        if (Boolean(row.toUserList) && row.toUserList.length > 0) {
                                            let userNames = [];
                                            row.toUserList.forEach(element => {
                                                userNames.push(element.user.email);
                                            });

                                            return userNames.toString();
                                        }
                                       
                                    }} />

                                    <Column title='Next Page' render={(value, row, index) => {
                                        if (Boolean(row.nextMenu))
                                            return row.nextMenu.menuTitle;
                                    }} />
                                    <Column title='Message' dataIndex='message' render={(value, row, index) => { return value; }} />


                                    <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} />

                                </Table>}
                            </Card>

                        </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationStep)
