import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';

export class Activity extends Component {

    constructor() {
        super();
        this.state = {
            hidden: true,
            displayBasic: false,
            position: 'center',
            _ActivityServiceText: "",
            selectedActivity: null,
            buttonName: "Add",
            buttonClassName: "p-button-success",
            gelenData: null,
            Activity: [] 
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    };

    componentDidMount = async () => { 
        const response = await handleRequest("GET", "/Activity/getAll"); 


        if (response.data.length === 0){
            this.setState({
                Activity: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                Activity: response.data,
            });
        }
    };

    showSuccess() {
        this.toast.show({severity: 'success', summary: 'Success Message', detail: 'Successful.'});
    };

    showError() {
        this.toast.show({severity: 'error', summary: 'Error Message', detail: 'Activity name can\'t be blank'});
    };

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true,
            selectedActivity: rowData
        });
    }

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteActivity(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <Button type="button" onClick={() => this.onClickModal('displayBasic', rowData)} icon="pi pi-times" className="p-button-danger" style={{marginRight: '.5em'}}></Button>
            <Dialog header="Delete This Discipline Record?" visible={this.state.displayBasic} style={{width: '25vw'}} onHide={() => this.onHideModal('displayBasic')} modal={false} footer={this.renderFooter('displayBasic')}>
                        <p>This Activity record will be deleted. Are you sure?</p>
            </Dialog>
            <Button type="button" onClick={() => this.changeButtonUpdate(rowData)} icon="pi pi-pencil" className="p-button-warning"></Button>
        </React.Fragment>;
    };

    saveDiscipline = async () => {
        if (this.state.buttonName === "Add" && this.state._ActivityServiceText !== ""){

            const newDiscipline = {
                description : this.state._ActivityServiceText
            }
    
            //await axios.post(`${API_BASE}/Discipline/add`, newDiscipline);
            await handleRequest("POST", "/Activity/add",newDiscipline);   
    
            this.changeButtonSave();
            this.restartTable();
        }
        else if(this.state.buttonName === "Update" && this.state._ActivityServiceText !== ""){

            const updatedDiscipline = {
                description : this.state._ActivityServiceText,
                id: this.state.selectedActivity.id,
                active: this.state.selectedActivity.active
            }
    
           // await axios.put(`${API_BASE}/Discipline/update`, updatedDiscipline);
            await handleRequest("PUT", "/Activity/update",updatedDiscipline);    
    
            this.changeButtonSave();
            this.restartTable();
        }else{
            this.showError();
        }
    };

    restartTable = async () => {
        
      
        const response = await handleRequest("GET", "/Activity/getAll");    

        if (response.data.length === 0){
            this.setState({
                Activity: [],
            });
        }

        if (response.data.length !== 0){
            this.setState({
                Activity: response.data,
            });
        }
    }

    deleteActivity = async (name) => {

        const prm = {
            id : this.state.selectedActivity.id
        }
 
        await handleRequest("PUT", "/Activity/delete",prm);    

        this.restartTable();
        this.showSuccess();
        this.onHideModal(name);
    };

    changeButtonUpdate = (rowData) => {
        this.setState({
            _ActivityServiceText: rowData.description,
            selectedActivity: rowData,
            hidden: false,
            buttonName: "Update",
            buttonClassName: "p-button-warning"
        });
    };

    changeButtonSave = () => {
        this.setState({
            _ActivityServiceText: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
        this.showSuccess();
    };

    changeButtonCancel = () => {
        this.setState({
            _ActivityServiceText: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
        //this.showError();
    };

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>
        return (
            <div>
                <Card style={{ width: '500px' }}>
                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable ref={(el) => this.dt = el} value={this.state.Activity} paginator={true} responsive={true} rowsPerPageOptions={[5,10,20]} rows={10} className="p-datatable-disciplines"
                            selectionMode="single" selection={this.state.selectedActivity} onSelectionChange={e => this.setState({ selectedActivity: e.value })}>
                            <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />
                            <Column field="id" style={{ textAlign: 'center', display: 'none' }} sortable header="S/N" />
                            <Column field="description" header="Activity" filter={true} sortable />
                            <Column field="id" style={{ textAlign: 'center', display: 'none' }} header="Activity Id" filter sortable />
                            <Column field="active" style={{ textAlign: 'center', display: 'none' }} header="Active" filter sortable />
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        </DataTable>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <InputText onChange={(e) => this.setState({ _ActivityServiceText: e.target.value })} value={this.state._ActivityServiceText} placeholder="Activity" />
                                <Button id='save-discipline' className={this.state.buttonClassName} onClick={this.saveDiscipline} label={this.state.buttonName} />
                                <Button hidden={this.state.hidden} id='cancel-discipline' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity)