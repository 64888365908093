import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Form, Input, Select, InputNumber, DatePicker, Divider, Button as AntButton } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { getCodeSelectItems } from '../../redux/config/imu.js';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon
} from "react-share";

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class MaterialRequisition extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenUpdate: true,
      pdfFiles: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      materials: [],
      allMaterials: [],
      materialRequisitionTable: [],
      maxQuantity: 0,
      codeName: '',

      form175Id: null,
      nmrId: null,
      samssId: null,
      saepId: null,
      saesId: null,
      specificationId: null,
      specificationUrl: null,
      dataSheetId: null,
      drawingNo: null,
      packageRequirementId: null,
      sparePartDataSheetId: null,
      industrialCodeId: null,
      filePath: '',
      fileList: [],
      specificationSelectItems: [],
      sparePartDataSheetSelectItems: [],
      industrialCodeSelectItems: [],
      saDocuments: null,
      modalPdfViewer: false,

    };

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  formRef = React.createRef();

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }


  componentDidMount = async () => {

    // getSaDocuments

    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "MaterialRequisition" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const responseSaDocuments = await handleRequest("GET", "/MaterialRequisition/getSaDocuments");

    if (Boolean(responseSaDocuments) && Boolean(responseSaDocuments.data)) {
      console.log(responseSaDocuments.data);
      this.setState({
        saDocuments: responseSaDocuments.data
      })
    }

    this.fillComboBoxes();
    const responsSaeps = await handleRequest("GET", "/SaEngineeringProcedures/getAllByCompany");

    if (Boolean(responsSaeps) && Boolean(responsSaeps.data))
      this.setState({
        packageRequirementSelectItems: responsSaeps.data
      })




    const obj = {
      pageName: "MaterialRequisition",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "MaterialRequisition");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getTableProperties();
    this.getMaterials();
  }

  getTableProperties = async () => {
    this.materialRequisitionPackage = JSON.parse(localStorage.getItem('materialRequisitionPackage'));
    if (this.materialRequisitionPackage != null && this.materialRequisitionPackage != undefined && this.materialRequisitionPackage != "") {

      await this.setState({
        bordered: this.materialRequisitionPackage.bordered,
        title: this.materialRequisitionPackage.titleDrm == true ? title : undefined,
        titleDrm: this.materialRequisitionPackage.titleDrm,
        showHeader: this.materialRequisitionPackage.showHeader,
        footer: this.materialRequisitionPackage.footerDrm == true ? footer : undefined,
        footerDrm: this.materialRequisitionPackage.footerDrm,
        expandable: this.materialRequisitionPackage.expandableDrm == true ? expandable : {},
        expandableDrm: this.materialRequisitionPackage.expandableDrm,
        rowSelection: this.materialRequisitionPackage.rowSelection,
        ellipsis: this.materialRequisitionPackage.ellipsis,
        tableLayout: this.materialRequisitionPackage.tableLayout,
        size: this.materialRequisitionPackage.size,
        top: this.materialRequisitionPackage.top,
        bottom: this.materialRequisitionPackage.bottom,
        editBgColor: this.materialRequisitionPackage.editBgColor,
        deleteBgColor: this.materialRequisitionPackage.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.materialRequisitionPackage.yScroll,
        xScroll: this.materialRequisitionPackage.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.materialRequisitionTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.materialRequisitionTable.length, showQuickJumper: true,
          position: [this.materialRequisitionPackage.top, this.materialRequisitionPackage.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showOrHideModalPdfMultiViewer = () => {
    if (this.state.pdfFiles.length > 0) {
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    } else {
      this.showError("File cant found.");
    }
  };

  actionTemplate(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdate.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
    }
  };

  deleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectedMaterialRequisition: row
    });
  }

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideModalPIM = () => {
    this.setState({
      PIMModal: !this.state.PIMModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  saveMaterialRequisition = async () => {
    const addMaterialRequisition = {
      date: moment(this.state.date, 'YYYY-MM-DD'),
      dateAck: this.state.date,
      materialRequestion: this.state.materialRequestion,
      revNo: this.state.revNo,
      materialId: this.state.materialId,
      detail: this.state.detail,
      quantity: this.state.quantity,
      dummyPoNo: this.state.dummyPoNo,
      pim: this.state.pim,
      inspectionLevel: this.state.inspectionLevel,
      com9: this.state.com9,
      form175Id: this.state.form175Id,
      nmrId: this.state.nmrId,
      samssId: this.state.samssId,
      saepId: this.state.saepId,
      saesId: this.state.saesId,
      specificationId: this.state.specificationId,
      specificationUrl: this.state.filePath,
      dataSheetId: this.state.dataSheetId,
      drawingNo: this.state.drawingNo,
      packageRequirementId: this.state.packageRequirementId,
      sparePartDataSheetId: this.state.sparePartDataSheetId,
      industrialCodeId: this.state.industrialCodeId,
    }
    var response = await handleRequest("POST", "/MaterialRequisition/add", addMaterialRequisition);
    if (response.data !== null && response.data !== undefined) {
      if (response.data.id > 0 ) {
        var selectedItem = this.state.allMaterials.find(p => p.id === this.state.materialId);
        const updatedProjectTakeOffList = {
          id: selectedItem.id,
          type: selectedItem.type,
          groupId: selectedItem.groupId,
          disciplineId: selectedItem.disciplineId,
          description: selectedItem.description,
          code: selectedItem.code,
          location: this.state.location,
          quantity: this.state.quantity,
          unitId: selectedItem.unitId,
          clientCode: this.state.com9,
          drawingPath: selectedItem.drawingPath,
          spec: "Done",
          rfq: selectedItem.rfq
        };
        var response2 = await handleRequest("POST", "/ProjectTakeOffList/update", updatedProjectTakeOffList);
        this.getMaterials();
        this.resetInputs();
        this.restartTable();
        this.setState({
          pdfFile: response.data.reportURL,
          modalPdfViewer: true,
        });
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  updateMaterialRequisition = async () => {
    const updatedMaterialRequisition = {
      id: this.state.selectedMaterialRequisition.id,
      date: moment(this.state.date, 'YYYY-MM-DD'),
      dateAck: this.state.date,
      materialRequestion: this.state.materialRequestion,
      revNo: this.state.revNo,
      materialId: this.state.materialId,
      detail: this.state.detail,
      quantity: this.state.quantity,
      dummyPoNo: this.state.dummyPoNo,
      pim: this.state.pim,
      inspectionLevel: this.state.inspectionLevel,
      com9: this.state.com9,
      form175Id: this.state.form175Id,
      nmrId: this.state.nmrId,
      samssId: this.state.samssId,
      saepId: this.state.saepId,
      saesId: this.state.saesId,
      specificationId: this.state.specificationId,
      specificationUrl: this.state.filePath,
      dataSheetId: this.state.dataSheetId,
      drawingNo: this.state.drawingNo,
      packageRequirementId: this.state.packageRequirementId,
      sparePartDataSheetId: this.state.sparePartDataSheetId,
      industrialCodeId: this.state.industrialCodeId,
    }
    var response2 = await handleRequest("POST", "/MaterialRequisition/update", updatedMaterialRequisition);
    if (response2.data !== null && response2.data !== undefined) {
      console.log(response2.data); 
      if (response2.data.id>0) {
        var selectedItem = this.state.allMaterials.find(p => p.id === this.state.materialId);
        if (Boolean(selectedItem)) {
          const updatedProjectTakeOffList = {
            id: selectedItem.id,
            type: selectedItem.type,
            groupId: selectedItem.groupId,
            disciplineId: selectedItem.disciplineId,
            description: selectedItem.description,
            code: selectedItem.code,
            location: this.state.location,
            quantity: this.state.quantity,
            unitId: selectedItem.unitId,
            clientCode: this.state.com9,
            drawingPath: selectedItem.drawingPath,
            spec: "Done",
            rfq: selectedItem.rfq
          };
          var response3 = await handleRequest("POST", "/ProjectTakeOffList/update", updatedProjectTakeOffList);
        }
        this.getMaterials();
        this.resetInputs();
        this.restartTable();
        this.setState({
          pdfFile: response2.data.reportURL,
          modalPdfViewer: true,
        });
        this.createNewClientButton();
      }
      else {
        this.showError();
      }
    }
  }

  restartTable = async () => {
    const response = await handleRequest("GET", "/MaterialRequisition/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        materialRequisitionTable: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        materialRequisitionTable: response.data,
      });
    }
  }

  getMaterials = async () => {
    // var newObj = {
    //   type: "Material"
    // }
    //const response = await handleRequest("POST", "/ProjectTakeOffList/getResult", newObj);
    const response = await handleRequest("GET", "/ProjectTakeOffList/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        materials: [],
      });
    }

    if (response.data.length !== 0) {
      let responseData = response.data;
      let selectedResponseData = [];
      for (let index = 0; index < responseData.length; index++) {
        if (responseData[index].spec !== undefined)
          if (responseData[index].spec !== "Done")
            selectedResponseData.push(responseData[index]);
      }
      this.setState({
        materials: selectedResponseData,
        allMaterials: response.data
      });
    }
  }

  deleteMaterialRequisitionOK = async () => {
    const deleteMaterialRequisition = {
      id: this.state.selectedMaterialRequisition.id
    }
    var response = await handleRequest("POST", "/MaterialRequisition/delete", deleteMaterialRequisition);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {

        var selectedItem = this.state.allMaterials.find(p => p.id === this.state.selectedMaterialRequisition.materialId);
        const updatedProjectTakeOffList = {
          id: selectedItem.id,
          type: selectedItem.type,
          groupId: selectedItem.groupId,
          disciplineId: selectedItem.disciplineId,
          description: selectedItem.description,
          code: selectedItem.code,
          location: selectedItem.location,
          quantity: selectedItem.quantity,
          unitId: selectedItem.unitId,
          clientCode: selectedItem.clientCode,
          drawingPath: selectedItem.drawingPath,
          spec: "",
          rfq: ""
        };
        var response2 = await handleRequest("POST", "/ProjectTakeOffList/update", updatedProjectTakeOffList);
        this.getMaterials();
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
        this.showError();
      }
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  pimNoOK = async () => {
    this.setState({
      pim: "No",
      PIMModal: false,
    });

    this.formRef.current.setFieldsValue({
      PIM: "No",
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  pimYesOK = async () => {
    this.setState({
      pim: "Yes",
      PIMModal: false,
    });

    this.formRef.current.setFieldsValue({
      PIM: "Yes",
    });

    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonUpdate = async (row) => {
    this.setState({
      materials: this.state.allMaterials
    });

    this.handleChangeMaterial(row.materialId);

    let fileList = [];
    if (Boolean(row.specificationUrl) && row.specificationUrl.length > 0)
      fileList = [{
        uid: 1,
        name: row.specificationUrl,
        url: row.specificationUrl,
        thumbUrl: row.specificationUrl,
      }]

    this.formRef.current.setFieldsValue({
      MR: row.materialRequestion,
      Rev: row.revNo,
      Detail: row.detail,
      Quantity: row.quantity,
      DummyPoNo: row.dummyPoNo,
      PIM: row.pim,
      InspectionLevel: row.inspectionLevel,
      Com9: row.com9,
      Material: row.materialId,

      Form175: row.form175Id,
      NMR: row.nmrId,
      SAMSS: row.samssId,
      SAEP: row.saepId,
      SAES: row.saesId,
      Specification: row.specificationId,
      DataSheet: row.dataSheetId,
      DrawingNo: row.drawingNo,
      PackageRequirement: row.packageRequirementId,
      SparePartDataSheet: row.sparePartDataSheetId,
      IndustrialCode: row.industrialCodeId,
      Date: (row.date != null && row.date != undefined && row.date != "") && moment(row.date, 'YYYY-MM-DD'),
    });

    await this.setState({
      selectedMaterialRequisition: row,

      date: row.date,
      materialRequestion: row.materialRequestion,
      revNo: row.revNo,
      detail: row.detail,
      quantity: row.quantity,
      dummyPoNo: row.dummyPoNo,
      pim: row.pim,
      inspectionLevel: row.inspectionLevel,
      com9: row.com9,
      materialId: row.materialId,
      form175Id: row.form175Id,
      nmrId: row.nmrId,
      samssId: row.samssId,
      saepId: row.saepId,
      saesId: row.saesId,
      specificationId: row.specificationId,
      specificationUrl: row.specificationUrl,
      filePath: row.specificationUrl,
      fileList: fileList,
      dataSheetId: row.dataSheetId,
      drawingNo: row.drawingNo,
      packageRequirementId: row.packageRequirementId,
      sparePartDataSheetId: row.sparePartDataSheetId,
      industrialCodeId: row.industrialCodeId,

      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
    });
  }

  resetInputs = () => {
    this.formRef.current.setFieldsValue({
      Date: "",
      MR: "",
      Rev: "",
      Detail: "",
      Quantity: "",
      DummyPoNo: "",
      PIM: "",
      InspectionLevel: "",
      Com9: "",
      Discipline: "",
      Location: "",
      Unit: "",
      Material: "",
    });

    this.setState({
      selectedMaterialRequisition: [],
      date: "",
      materialRequestion: "",
      revNo: "",
      detail: "",
      quantity: "",
      dummyPoNo: "",
      pim: "",
      inspectionLevel: "",
      com9: "",
      discipline: "",
      location: "",
      unit: "",
      materialId: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
    });
  }

  changeButtonCancel = () => {
    this.getMaterials();

    this.formRef.current.setFieldsValue({
      Date: "",
      MR: "",
      Rev: "",
      Detail: "",
      Quantity: "",
      DummyPoNo: "",
      PIM: "",
      InspectionLevel: "",
      Com9: "",
      Discipline: "",
      Location: "",
      Unit: "",
      Material: "",
    });

    this.setState({
      selectedMaterialRequisition: [],
      date: "",
      materialRequestion: "",
      revNo: "",
      detail: "",
      quantity: "",
      dummyPoNo: "",
      pim: "",
      inspectionLevel: "",
      com9: "",
      discipline: "",
      location: "",
      unit: "",
      materialId: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
    });
  }

  showOrHideModalDeleteMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenDataGiris: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenCreateNewButton: false,
        createNewButton: "Cancel",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.getMaterials();
      this.setState({
        hiddenDataGiris: true,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenCreateNewButton: false,
        createNewButton: "Create New",
        createNewButtonVariant: "info",
      });
    }
  }

  showOrHideModalPdfViewer = () => {
    this.setState({
      modalPdfViewer: !this.state.modalPdfViewer,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  onChangeDate = (value, dateString) => {
    this.setState({
      date: dateString,
    });
  }

  handleChangeMaterial = (e) => {
    this.setState({
      materialId: e
    });
    var selectMaterial = [];
    selectMaterial.push(this.state.allMaterials.find(p => p.id === e));
    var discipline = "";
    var unit = "";
    var quantity = "";
    var com9 = "";
    var location = "";
    if (Boolean(selectMaterial) && selectMaterial.length === 1) {
      selectMaterial.forEach(element => {
        if (Boolean(element) && element.discipline != null && element.discipline !== undefined && element.discipline.length != 0) {
          discipline = element.discipline.disciplineName;
        }

        if (Boolean(element) && element.unitId != null && element.unitId !== undefined) {
          unit = element.unitId;
        }

        if (Boolean(element) && element.quantity != null && element.quantity !== undefined) {
          quantity = element.quantity;
        }

        if (Boolean(element) && element.projectTakeOff9Coms != null && element.projectTakeOff9Coms !== undefined) {
          let data = element.projectTakeOff9Coms;
          var result = [];  
         
            data.forEach(element => {
              result.push(element.com); 
            }); 
             
          com9 = result.toString(); 
          }

        if (Boolean(element) && element.location != null && element.location !== undefined) {
          location = element.location;
        }
      });
    }
    this.formRef.current.setFieldsValue({
      Quantity: quantity,
      Com9: com9,
      Discipline: discipline,
      Location: location,
      Unit: unit,
    });

    this.setState({
      discipline: discipline,
      unit: unit,
      quantity: quantity,
      maxQuantity: quantity,
      com9: com9,
      location: location,
    });
  }

  handleChangeInspectionLevel = (e) => {
    var pim = "";
    var PIMModal = false;
    if (e < 2) {
      pim = "No";
    } else if (e == 2) {
      PIMModal = true;
    }
    else if (e > 2) {
      pim = "Yes";
    }

    this.formRef.current.setFieldsValue({
      PIM: pim,
    });

    this.setState({
      inspectionLevel: e,
      pim: pim,
      PIMModal: PIMModal,
    });
  }

  addSpecification = async () => {
    const { codeName } = this.state;
    let pType = "Specification";

    if (codeName.length > 0) {

      const newItem = {
        name: codeName,
        type: pType
      }

      await handleRequest("POST", "/Code/add", newItem);

      let result = await getCodeSelectItems(pType);

      this.setState({
        specificationSelectItems: result,
        codeName: '',
      });
    }

  };

  addSparePartDataSheet = async () => {
    const { codeName } = this.state;
    let pType = "SparePartDataSheet";

    if (codeName.length > 0) {

      const newItem = {
        name: codeName,
        type: pType
      }

      await handleRequest("POST", "/Code/add", newItem);

      let result = await getCodeSelectItems(pType);

      this.setState({
        sparePartDataSheetSelectItems: result,
        codeName: '',
      });
    }

  };

  addindustrialCode = async () => {
    const { codeName } = this.state;
    let pType = "IndustrialCode";

    if (codeName.length > 0) {

      const newItem = {
        name: codeName,
        type: pType
      }

      await handleRequest("POST", "/Code/add", newItem);

      let result = await getCodeSelectItems(pType);

      this.setState({
        industrialCodeSelectItems: result,
        codeName: '',
      });
    }

  };

  fillComboBoxes = async () => {

    const specificationSelectItems = await getCodeSelectItems('Specification');
    const sparePartDataSheetSelectItems = await getCodeSelectItems('SparePartDataSheet');
    const industrialCodeSelectItems = await getCodeSelectItems('IndustrialCode');

    this.setState({
      specificationSelectItems: specificationSelectItems,
      sparePartDataSheetSelectItems: sparePartDataSheetSelectItems,
      industrialCodeSelectItems: industrialCodeSelectItems
    });

  }

  showVoucherReport = async (rowData) => {
    try {
      const fileRealUrl = rowData.attachmentUrl;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });

    } catch (error) {
      alert("File cant found.");
    }
  }

  showReport = async (url) => {
    try {
      const fileRealUrl = url;

      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });

    } catch (error) {
      alert("File cant found.");
    }
  }

  downloadFile = async (url) => {
    window.location.href = url;
  }

  render() {
    var itemDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Date");
    var itemMRAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MR");
    var itemRevAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Rev");
    var itemDetailAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Detail");
    var itemQuantityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Quantity");
    var itemDummyPoNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DummyPoNo");
    var itemPIMAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PIM");
    var itemInspectionLevelAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "InspectionLevel");
    var itemCom9Attributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Com9");
    var itemDisciplineAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Discipline");
    var itemLocationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Location");
    var itemUnitAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Unit");
    var itemMaterialAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Material");
    var itemForm175Attributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Form175");
    var itemNMRAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "NMR");
    var itemSAMSSAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAMSS");
    var itemSAEPAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAEP");
    var itemSAESAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SAES");
    var itemSpecificationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Specification");
    var itemDataSheetAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DataSheet");
    var itemDrawingNoAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "DrawingNo");
    var itemPackageRequirementAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PackageRequirement");
    var itemSparePartDataSheetAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "SparePartDataSheet");
    var itemIndustrialCodeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "IndustrialCode");

    var itemDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "Date");
    var itemMRRequired = this.state.dynamicInputs.find(p => p.inputKey === "MR");
    var itemRevRequired = this.state.dynamicInputs.find(p => p.inputKey === "Rev");
    var itemDetailRequired = this.state.dynamicInputs.find(p => p.inputKey === "Detail");
    var itemQuantityRequired = this.state.dynamicInputs.find(p => p.inputKey === "Quantity");
    var itemDummyPoNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "DummyPoNo");
    var itemPIMRequired = this.state.dynamicInputs.find(p => p.inputKey === "PIM");
    var itemInspectionLevelRequired = this.state.dynamicInputs.find(p => p.inputKey === "InspectionLevel");
    var itemCom9Required = this.state.dynamicInputs.find(p => p.inputKey === "Com9");
    var itemDisciplineRequired = this.state.dynamicInputs.find(p => p.inputKey === "Discipline");
    var itemLocationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Location");
    var itemUnitRequired = this.state.dynamicInputs.find(p => p.inputKey === "Unit");
    var itemMaterialRequired = this.state.dynamicInputs.find(p => p.inputKey === "Material");
    var itemForm175Required = this.state.dynamicInputs.find(p => p.inputKey === "Form175");
    var itemNMRRequired = this.state.dynamicInputs.find(p => p.inputKey === "NMR");
    var itemSAMSSRequired = this.state.dynamicInputs.find(p => p.inputKey === "SAMSS");
    var itemSAEPRequired = this.state.dynamicInputs.find(p => p.inputKey === "SAEP");
    var itemSAESRequired = this.state.dynamicInputs.find(p => p.inputKey === "SAES");
    var itemSpecificationRequired = this.state.dynamicInputs.find(p => p.inputKey === "Specification");
    var itemDataSheetRequired = this.state.dynamicInputs.find(p => p.inputKey === "DataSheet");
    var itemDrawingNoRequired = this.state.dynamicInputs.find(p => p.inputKey === "DrawingNo");
    var itemPackageRequirementRequired = this.state.dynamicInputs.find(p => p.inputKey === "PackageRequirement");
    var itemSparePartDataSheetRequired = this.state.dynamicInputs.find(p => p.inputKey === "SparePartDataSheet");
    var itemIndustrialCodeRequired = this.state.dynamicInputs.find(p => p.inputKey === "IndustrialCode");

    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };


    const columns = [
      {
        key: "key",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("materialRequisitionPackage")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "date",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionDate" defaultMessage="Date" />,
      },
      {
        dataIndex: "materialRequestion",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionMR" defaultMessage="MR" />,
      },
      {
        dataIndex: "revNo",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionRevNo" defaultMessage="Rev." />,
      },
      {
        dataIndex: "projectTakeOffList",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionMaterial" defaultMessage="Material" />,
        render:(value, row, index) => {
          if (Boolean(row) && Boolean(row.reportURL) && row.reportURL.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showReport(row.reportURL)}> 
                  { Boolean(value) ? value.code + "-" + value.description : ''}  
                </div>
              </React.Fragment>
            );
          } else {
            return   Boolean(value) ? value.code + "-" + value.description : '' ; 
          }
        },
       
      },
      {
        dataIndex: "detail",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionDetail" defaultMessage="Detail" />,
      },
      {
        dataIndex: "projectTakeOffList",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionDiscipline" defaultMessage="Discipline" />,
        render: (data) => {
          if (data != null && data != undefined && data.discipline != null && data.discipline != undefined) {
            return data.discipline.disciplineName;
          }
          return "";
        }
      },
      {
        dataIndex: "projectTakeOffList",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionUnit" defaultMessage="Unit" />,
        render: (data) => {
          if (data != null && data != undefined && data.unitId != null && data.unitId != undefined) {
            return data.unitId;
          }
          return "";
        }
      },
      {
        dataIndex: "projectTakeOffList",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionLocation" defaultMessage="Location" />,
        render: (data) => {
          if (data != null && data != undefined && data.location != null && data.location != undefined) {
            return data.location;
          }
          return "";
        }
      },
      {
        dataIndex: "quantity",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionQuantity" defaultMessage="Quantity" />,
      },
      {
        dataIndex: "dummyPoNo",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionDummyPoNo" defaultMessage="Dummy PO no" />,
      },
      {
        dataIndex: "com9",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionCom9" defaultMessage="9.Com #" />,
      },
      {
        dataIndex: "inspectionLevel",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionInspectionLevel" defaultMessage="Inspection Level" />,
        render: (data) => {
          if (data != null && data != undefined) {
            if (data == -1) {
              return "Non-Inspectible";
            } else {
              return data;
            }
          }
          return "";
        }
      },
      {
        dataIndex: "pim",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionPIM" defaultMessage="PIM" />,
      },
      {

        dataIndex: "sa175Forms",
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionForm175" defaultMessage="Form 175" />,
        render: (data) => {
          if (Boolean(data) && Boolean(data.attachmentUrl) && data.attachmentUrl.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showVoucherReport(data)}>
                  {data.code}
                </div>
              </React.Fragment>
            );
          } else {
            if (Boolean(data))
              return data.code;
            else
              return "";
          }
        }
      },
      {

        key: "key",
        title: <FormattedMessage id="MaterialRequisitionNMR" defaultMessage="NMR" />,
        dataIndex: "saNonMaterialRequirement",
        render: (data) => {
          if (Boolean(data) && Boolean(data.attachmentUrl) && data.attachmentUrl.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showVoucherReport(data)}>
                  {data.code}
                </div>
              </React.Fragment>
            );
          } else {
            if (Boolean(data))
              return data.code;
            else
              return "";
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionSAMSS" defaultMessage="SAMSS" />,
        dataIndex: "saMaterialsSystemSpecifications",
        render: (data) => {
          if (Boolean(data) && Boolean(data.attachmentUrl) && data.attachmentUrl.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showVoucherReport(data)}>
                  {data.code}
                </div>
              </React.Fragment>
            );
          } else {
            if (Boolean(data))
              return data.code;
            else
              return "";
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionSAEP" defaultMessage="SAEP" />,
        dataIndex: "saEngineeringProcedures",
        render: (data) => {
          if (Boolean(data) && Boolean(data.attachmentUrl) && data.attachmentUrl.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showVoucherReport(data)}>
                  {data.code}
                </div>
              </React.Fragment>
            );
          } else {
            if (Boolean(data))
              return data.code;
            else
              return "";
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionSAES" defaultMessage="SAES" />,
        dataIndex: "saEngineeringStandards",
        render: (data) => {
          if (Boolean(data) && Boolean(data.attachmentUrl) && data.attachmentUrl.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showVoucherReport(data)}>
                  {data.code}
                </div>
              </React.Fragment>
            );
          } else {
            if (Boolean(data))
              return data.code;
            else
              return "";
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionSpecification" defaultMessage="Specification" />,
        dataIndex: "specification",
        render: (data) => {
          if (Boolean(data)) {
            return data.name
          } else
            return "";

        }

      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionAttachment" defaultMessage="Attachment" />,
        dataIndex: "specificationUrl",
        render: (data) => {
          if (Boolean(data)) {
            return <AntButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(data)} type="primary" shape="round" icon={<DownloadOutlined />} >

            </AntButton>
          } else
            return "";

        }

      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionDataSheet" defaultMessage="DataSheet" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionDrawingNo" defaultMessage="Drawing No" />,
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionPackageRequirement" defaultMessage="PackageRequirement" />,
        dataIndex: "packageRequirement",
        render: (data) => {
          if (Boolean(data) && Boolean(data.attachmentUrl) && data.attachmentUrl.length > 0) {
            return (
              <React.Fragment>
                <div style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
                  onClick={(e) => this.showVoucherReport(data)}>
                  {data.code}
                </div>
              </React.Fragment>
            );
          } else {
            if (Boolean(data))
              return data.code;
            else
              return "";
          }
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionSparePartDataSheet" defaultMessage="Spare Part Data Sheet" />,
        dataIndex: "sparePartDataSheet",
        render: (data) => {
          if (Boolean(data)) {
            return data.name
          } else
            return "";

        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaterialRequisitionIndustrialCode" defaultMessage="Industrial Code" />,
        dataIndex: "industrialCode",
        render: (data) => {
          if (Boolean(data)) {
            return data.name
          } else
            return "";

        }
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };



    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
          <Form
            {...layout}
            initialValues={{ remember: false }}
            onFinish={this.state.hiddenUpdate == false ? this.updateMaterialRequisition : this.saveMaterialRequisition}
            onFinishFailed={onFinishFailed}
            ref={this.formRef} >

            <Row>
              <Col xs={8}></Col>
              <Col xs={3}>
                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                  variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                </Button>
              </Col>
              <Col xs={1}>
                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("materialRequisitionPackage")} icon="pi pi-cog"></PrimeButton>
              </Col>
            </Row>

            <div hidden={this.state.hiddenDataGiris}>
              <br />

              {(itemMaterialAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionMaterial" defaultMessage="Item" />}
                  name="Material"
                  // itemMaterialRequired.canBeRequired 
                  rules={[{ required: false, message: <FormattedMessage id={Boolean(itemMaterialRequired.translateRequired) == true ? itemMaterialRequired.translateRequired : -1} defaultMessage="Please input your Material!" /> }]} >
                  <Select name="Material"
                    style={{ width: '100%' }}
                    value={this.state.materialId}
                    onChange={this.handleChangeMaterial}>
                    {this.state.materials.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "-" + i.description}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemDateAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionDate" defaultMessage="Date" />}
                  name="Date"
                  rules={[{ required: itemDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateRequired.translateRequired) == true ? itemDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                  <DatePicker value={moment(this.state.date, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDate} />
                </Form.Item>}

              {(itemMRAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionMR" defaultMessage="MR#" />}
                  name="MR"
                  rules={[{ required: itemMRRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMRRequired.translateRequired) == true ? itemMRRequired.translateRequired : -1} defaultMessage="Please input your MR!" /> }]} >
                  <Input id="MaterialRequisitionMR" value={this.state.materialRequestion} onChange={(e) =>
                    this.setState({ materialRequestion: e.target.value })
                  } />
                </Form.Item>}

              {(itemRevAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionRev" defaultMessage="Rev." />}
                  name="Rev"
                  rules={[{ required: itemRevRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemRevRequired.translateRequired) == true ? itemRevRequired.translateRequired : -1} defaultMessage="Please input your Rev.!" /> }]} >
                  <Input id="MaterialRequisitionRev" value={this.state.revNo} onChange={(e) =>
                    this.setState({ revNo: e.target.value })
                  } />
                </Form.Item>}

              {(itemDetailAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionDetail" defaultMessage="Detail" />}
                  name="Detail"
                  rules={[{ required: itemDetailRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDetailRequired.translateRequired) == true ? itemDetailRequired.translateRequired : -1} defaultMessage="Please input your Detail!" /> }]} >
                  <Input id="MaterialRequisitionDetail" value={this.state.detail} onChange={(e) =>
                    this.setState({ detail: e.target.value })
                  } />
                </Form.Item>}

              {(itemDisciplineAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionDiscipline" defaultMessage="Discipline" />}
                  name="Discipline"
                  rules={[{ required: itemDisciplineRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDisciplineRequired.translateRequired) == true ? itemDisciplineRequired.translateRequired : -1} defaultMessage="Please input your Discipline!" /> }]} >
                  <Input id="MaterialRequisitionDiscipline" value={this.state.discipline} readOnly={true} />
                </Form.Item>}

              {(itemUnitAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionUnit" defaultMessage="Unit" />}
                  name="Unit"
                  rules={[{ required: itemUnitRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemUnitRequired.translateRequired) == true ? itemUnitRequired.translateRequired : -1} defaultMessage="Please input your Unit!" /> }]} >
                  <Input id="MaterialRequisitionUnit" value={this.state.unit} readOnly={true} />
                </Form.Item>}

              {(itemQuantityAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionQuantity" defaultMessage="Quantity" />}
                  name="Quantity"
                  rules={[{ required: itemQuantityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemQuantityRequired.translateRequired) == true ? itemQuantityRequired.translateRequired : -1} defaultMessage="Please input your Quantity!" /> }]} >
                  <InputNumber id="MaterialRequisitionQuantity" min={0} max={this.state.maxQuantity} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ quantity: e })} value={this.state.quantity} />
                </Form.Item>}

              {(itemLocationAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionLocation" defaultMessage="Location" />}
                  name="Location"
                  rules={[{ required: itemLocationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemLocationRequired.translateRequired) == true ? itemLocationRequired.translateRequired : -1} defaultMessage="Please input your Location!" /> }]} >
                  <Input id="MaterialRequisitionLocation" value={this.state.location} onChange={(e) => this.setState({ location: e.target.value })} />
                </Form.Item>}

              {(itemDummyPoNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionDummyPoNo" defaultMessage="Dummy PO no" />}
                  name="DummyPoNo"
                  rules={[{ required: itemDummyPoNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDummyPoNoRequired.translateRequired) == true ? itemDummyPoNoRequired.translateRequired : -1} defaultMessage="Please input your Dummy PO no!" /> }]} >
                  <Input id="MaterialRequisitionDummyPoNo" value={this.state.dummyPoNo} onChange={(e) =>
                    this.setState({ dummyPoNo: e.target.value })
                  } />
                </Form.Item>}

              {(itemCom9Attributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionCom9" defaultMessage="9.Com #" />}
                  name="Com9"
                  rules={[{ required: itemCom9Required.canBeRequired, message: <FormattedMessage id={Boolean(itemCom9Required.translateRequired) == true ? itemCom9Required.translateRequired : -1} defaultMessage="Please input your 9.Com !" /> }]} >
                  <Input id="MaterialRequisitionCom9" value={this.state.com9} onChange={(e) =>
                    this.setState({ com9: e.target.value })
                  } />
                </Form.Item>}

              {(itemInspectionLevelAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionLevelRequisitionInspectionLevel" defaultMessage="Inspection Level" />}
                  name="InspectionLevel"
                  rules={[{ required: itemInspectionLevelRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemInspectionLevelRequired.translateRequired) == true ? itemInspectionLevelRequired.translateRequired : -1} defaultMessage="Please input your Inspection Level!" /> }]} >
                  <Select name="InspectionLevel"
                    style={{ width: '100%' }}
                    value={this.state.inspectionLevel}
                    onChange={this.handleChangeInspectionLevel}>

                    <Option key={-1} value={-1}>{"Non-Inspectible"}</Option>
                    <Option key={0} value={0}>{0}</Option>
                    <Option key={1} value={1}>{1}</Option>
                    <Option key={2} value={2}>{2}</Option>
                    <Option key={3} value={3}>{3}</Option>
                    <Option key={4} value={4}>{4}</Option>
                  </Select>
                </Form.Item>}

              {(itemPIMAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="MaterialRequisitionPIM" defaultMessage="PIM" />}
                  name="PIM"
                  rules={[{ required: itemPIMRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPIMRequired.translateRequired) == true ? itemPIMRequired.translateRequired : -1} defaultMessage="Please input your PIM!" /> }]} >
                  <Input id="MaterialRequisitionPIM" value={this.state.pim} readOnly={true} onChange={(e) =>
                    this.setState({ pim: e.target.value })
                  } />
                </Form.Item>}

              {(itemForm175Attributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentForm175" defaultMessage="Form 175" />}
                  name="Form175"
                  rules={[{ required: itemForm175Required.canBeRequired, message: <FormattedMessage id={Boolean(itemForm175Required.translateRequired) == true ? itemForm175Required.translateRequired : -1} defaultMessage="Please input your Form 175!" /> }]} >
                  <Select name="Form175" style={{ width: '100%' }} value={this.state.form175Id}
                    onChange={(value) => {
                      this.setState({
                        form175Id: value
                      })
                    }}
                  >
                    <Option key={null} value={null}>{"Select"}</Option>
                    {Boolean(this.state.saDocuments.sa175list) && this.state.saDocuments.sa175list.length > 0 && this.state.saDocuments.sa175list.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "/" + i.description + "(" + i.revno + ")"}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemNMRAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentNMR" defaultMessage="NMR" />}
                  name="NMR"
                  rules={[{ required: itemNMRRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemNMRRequired.translateRequired) == true ? itemNMRRequired.translateRequired : -1} defaultMessage="Please input your NMR!" /> }]} >
                  <Select name="NMR" style={{ width: '100%' }} value={this.state.nmrId}
                    onChange={(value) => {
                      this.setState({
                        nmrId: value
                      })
                    }}>
                    <Option key={null} value={null}>{"Select"}</Option>
                    {Boolean(this.state.saDocuments.nmrList) && this.state.saDocuments.nmrList.length > 0 && this.state.saDocuments.nmrList.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "/" + i.description + "(" + i.revno + ")"}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemSAMSSAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentSAMSS" defaultMessage="SAMSS" />}
                  name="SAMSS"
                  rules={[{ required: itemSAMSSRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSAMSSRequired.translateRequired) == true ? itemSAMSSRequired.translateRequired : -1} defaultMessage="Please input your SAMSS!" /> }]} >
                  <Select name="SAMSS" style={{ width: '100%' }} value={this.state.samssId}
                    onChange={(value) => {
                      this.setState({
                        samssId: value
                      })
                    }}>
                    <Option key={null} value={null}>{"Select"}</Option>
                    {Boolean(this.state.saDocuments.samssList) && this.state.saDocuments.samssList.length > 0 && this.state.saDocuments.samssList.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "/" + i.description + "(" + i.revno + ")"}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemSAEPAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentSAEP" defaultMessage="SAEP" />}
                  name="SAEP"
                  rules={[{ required: itemSAEPRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSAEPRequired.translateRequired) == true ? itemSAEPRequired.translateRequired : -1} defaultMessage="Please input your SAEP!" /> }]} >
                  <Select name="SAEP" style={{ width: '100%' }}
                    value={this.state.saepId}
                    onChange={(value) => {
                      this.setState({
                        saepId: value
                      })
                    }}>
                    <Option key={null} value={null}>{"Select"}</Option>
                    {Boolean(this.state.saDocuments.saepList) && this.state.saDocuments.saepList.length > 0 && this.state.saDocuments.saepList.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "/" + i.description + "(" + i.revno + ")"}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemSAESAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentSAES" defaultMessage="SAES" />}
                  name="SAES"
                  rules={[{ required: itemSAESRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSAESRequired.translateRequired) == true ? itemSAESRequired.translateRequired : -1} defaultMessage="Please input your SAES!" /> }]} >
                  <Select name="SAES" style={{ width: '100%' }}
                    value={this.state.saesId}
                    onChange={(value) => {
                      this.setState({
                        saesId: value
                      })
                    }}>
                    <Option key={null} value={null}>{"Select"}</Option>
                    {Boolean(this.state.saDocuments.saesList) && this.state.saDocuments.saesList.length > 0 && this.state.saDocuments.saesList.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "/" + i.description + "(" + i.revno + ")"}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemSpecificationAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentSpecification" defaultMessage="Specification" />}
                  name="Specification"
                  rules={[{ required: itemSpecificationRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSpecificationRequired.translateRequired) == true ? itemSpecificationRequired.translateRequired : -1} defaultMessage="Please input your Specification!" /> }]} >
                  <Select name="Specification" style={{ width: '100%' }}
                    value={this.state.specificationId} onChange={(value) => {

                      this.setState({
                        specificationId: value
                      })
                    }}


                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                            this.setState({
                              codeName: event.target.value,
                            });
                          }} />
                          <a
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addSpecification}
                          >
                            <PlusOutlined /> Add item
                             </a>
                        </div>
                      </div>
                    )}
                  >
                    <Option key={null} value={null}>Select</Option>
                    {this.state.specificationSelectItems.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}
              {
                <Form.Item


                  label={<FormattedMessage id="NonMaterialRequirementsAttachment" defaultMessage="Attachment" />}

                  name="attachmentUrl"
                  rules={[{ required: false, message: <FormattedMessage id="MaterialRequisitionAttachmentRequired" defaultMessage="This field is required" /> }]} >

                  <Upload {...props} id="NonMaterialRequirementsAttachment"
                    showUploadList={true}
                    fileList={this.state.fileList}
                    onChange={({ fileList }) => {
                      if (fileList.length == 1) {
                        var filePath = "";
                        if (Boolean(fileList[0].response)) {
                          filePath = fileList[0].response.url;
                          this.showSuccess("file uploaded successfully");
                          // fileName: info.file.name, 
                        }
                        this.setState({
                          fileList: fileList,
                          filePath: filePath,
                        });
                      }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.fileList.length > 0) {
                        this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState({
                        fileList: [],
                        filePath: "",
                      })
                    }
                    }>
                    <AntButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> Upload
                                </AntButton>
                  </Upload>


                </Form.Item>

              }

              {(itemDataSheetAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentDataSheet" defaultMessage="Data Sheet" />}
                  name="DataSheet"
                  rules={[{ required: itemDataSheetRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDataSheetRequired.translateRequired) == true ? itemDataSheetRequired.translateRequired : -1} defaultMessage="Please input your Data Sheet!" /> }]} >
                  <Select name="DataSheet" style={{ width: '100%' }}>
                  </Select>
                </Form.Item>}

              {(itemDrawingNoAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentDrawingNo" defaultMessage="Drawing No" />}
                  name="DrawingNo"
                  rules={[{ required: itemDrawingNoRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDrawingNoRequired.translateRequired) == true ? itemDrawingNoRequired.translateRequired : -1} defaultMessage="Please input your Drawing No!" /> }]} >
                  <Select name="DrawingNo" style={{ width: '100%' }}>
                  </Select>
                </Form.Item>}

              {(itemPackageRequirementAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentPackageRequirement" defaultMessage="Package Requirement" />}
                  name="PackageRequirement"
                  rules={[{ required: itemPackageRequirementRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemPackageRequirementRequired.translateRequired) == true ? itemPackageRequirementRequired.translateRequired : -1} defaultMessage="Please input your Package Requirement!" /> }]} >
                  <Select name="PackageRequirement" style={{ width: '100%' }} value={this.state.packageRequirementId} onChange={(value) => {

                    this.setState({
                      packageRequirementId: value
                    })
                  }}>
                    <Option key={null} value={null}>{"Select"}</Option>
                    {this.state.packageRequirementSelectItems.map(i => (
                      <Option key={i.id} value={i.id}>{i.code + "/" + i.description + "(" + i.revno + ")"}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemSparePartDataSheetAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentSparePartDataSheet" defaultMessage="Spare Part Data Sheet" />}
                  name="SparePartDataSheet"
                  rules={[{ required: itemSparePartDataSheetRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemSparePartDataSheetRequired.translateRequired) == true ? itemSparePartDataSheetRequired.translateRequired : -1} defaultMessage="Please input your Spare Part Data Sheet!" /> }]} >
                  <Select name="SparePartDataSheet" style={{ width: '100%' }}
                    value={this.state.sparePartDataSheetId} onChange={(value) => {

                      this.setState({
                        sparePartDataSheetId: value
                      })
                    }}


                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                            this.setState({
                              codeName: event.target.value,
                            });
                          }} />
                          <a
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addSparePartDataSheet}
                          >
                            <PlusOutlined /> Add item
                           </a>
                        </div>
                      </div>
                    )}
                  >
                    <Option key={null} value={null}>Select</Option>
                    {this.state.sparePartDataSheetSelectItems.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              {(itemIndustrialCodeAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="InspectionAssignmentIndustrialCode" defaultMessage="Industrial Code" />}
                  name="IndustrialCode"
                  rules={[{ required: itemIndustrialCodeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemIndustrialCodeRequired.translateRequired) == true ? itemIndustrialCodeRequired.translateRequired : -1} defaultMessage="Please input your Industrial Code!" /> }]} >
                  <Select name="IndustrialCode" style={{ width: '100%' }}

                    value={this.state.industrialCodeId} onChange={(value) => {

                      this.setState({
                        industrialCodeId: value
                      })
                    }}


                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.codeName} onChange={(event) => {

                            this.setState({
                              codeName: event.target.value,
                            });
                          }} />
                          <a
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.addindustrialCode}
                          >
                            <PlusOutlined /> Add item
                           </a>
                        </div>
                      </div>
                    )}
                  >
                    <Option key={null} value={null}>Select</Option>
                    {this.state.industrialCodeSelectItems.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Form.Item>}

              <div hidden={this.state.hiddenSave}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2">
                    </Col>
                    <Col sm="4">
                      <Button id="MaterialRequisitionSaveButton"
                        style={{ width: '100%' }} type="submit"
                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

              <div hidden={this.state.hiddenUpdate}>
                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                  <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2"></Col>
                    <Col sm="2">
                      <Button id="MaterialRequisitionCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                    </Col>
                    <Col sm="2">
                      <Button id="MaterialRequisitionUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </FormBoot.Group>
                }
              </div>

            </div>
            <br />
            <br />

            <div className="datatable-doc-demo">
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.materialRequisitionTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </div>

          </Form>}


        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
                <p>Photo has not been uploaded.</p>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
        </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={this.showOrHideModalPdfViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.showOrHideModalPdfViewer}
            >
              Close
          </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalShowPdfMultiViewer} size="xl"
          onHide={this.showOrHideModalPdfMultiViewer}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteMaterialRequisitionOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.PIMModal}
          onHide={this.showOrHideModalPIM}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          size="md" >
          <Modal.Body>
            <FormattedMessage id="MaterialRequisitionPIMModalMessage" defaultMessage="Is Pre-Inspection Meeting Applicable?" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.pimNoOK}>
              <FormattedMessage id="GeneralTextNo" defaultMessage="No" />
            </Button>
            <Button variant="danger" onClick={this.pimYesOK}>
              <FormattedMessage id="GeneralButtonYes" defaultMessage="Yes" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialRequisition)