// import axios from "axios";
// import {API_BASE as API_BASE_ROOT} from "../../../redux/config/env" ;

// export const LOGIN_URL = API_BASE_ROOT + "/login";  
// export const TWO_FA_LOGIN_URL = API_BASE_ROOT + "/2falogin";  
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

// export const ME_URL = API_BASE_ROOT+"/api/users/me";

// export function login(username, password) {
//   return axios.post(LOGIN_URL, { username, password });
// }

// export function twoFalogin(username,token) {
//   return axios.post(TWO_FA_LOGIN_URL, {username,token});  
// }

// export function register(email, fullname, username, password) {
//   return axios.post(REGISTER_URL, { email, fullname, username, password });
// }

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   // return axios.get(ME_URL);
// }

import axios from "axios";
import {API_BASE } from "../../../redux/config/env" ;

//export const LOGIN_URL = API_BASE+ "/User/login";
//export const LOGIN_URL = "http://81.169.196.60:8081/User/login";
export const LOGIN_URL = API_BASE +"/User/login";
export const REGISTER_URL = API_BASE+ "/auth/register";
export const REQUEST_PASSWORD_URL = API_BASE+ "/auth/forgot-password";

export const ME_URL = API_BASE+"/User/me";
//export const ME_URL = "http://81.169.196.60:8081/user/1"; 

export function login(email, password) {
  var gelen=axios.post(LOGIN_URL, { email, password });
  //console.log('gelen',gelen);
  return gelen;
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  //console.log("BURAYA.BAK",axios.get(ME_URL));
  return axios.get(ME_URL);// axios.get(ME_URL);
}