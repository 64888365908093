import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest, API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot, Col, Modal, Row } from 'react-bootstrap';
import { Button as AntdButton, Form, Input, Select, InputNumber, DatePicker, Divider, Switch, Checkbox, Cascader, Space } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

const itemTypeSelectItems = [{ id: 1, value: "Material" }, { id: 2, value: "Equipment" }];

class RequestForPurchasing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemGroupSelectItems: [],
            nodes: [],
            allNodes: [],
            itemId: 0,
            itemType: null,
            groupId: null,
            itemName: '',
            itemGroupIds: [],
            itemGroups: [],
            itemListSelectItems: [],
            allProducts: [],
            products: [],
            selectedItemId: null,
            selectedItem: null,
            quantity: "",
            requestedDeliveryDate: null,
            locations: [],
            purposeToUses: [],
            purposeToUse: "",
            purposeToUseId: null,
            newPurposeToUse: "",
            estimatedPrice: "",
            estimatedDeliveryStart: "",
            estimatedDeliveryEnd: "",
            clientApproval: true,
            materialSpecification: true,
            projectType: null,
            permanentCheck: false,
            permanentType: null,
            inspectibleCheck: false,
            inspectibleType: null,
            engineeringCheck: false,
            engineeringType: null,
            reusableCheck: false,
            reusableType: null,
            limitedUseCheck: false,
            limitedUseType: null,

            hiddenSave: false,
            hiddenDataGiris: true,
            hiddenUpdate: true,
            dynamicAttributes: [],
            dynamicInputs: [],
            authorizations: [],
            createNewButtonVariant: "info",
            createNewButton: "Create New"
        };
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    formRef = React.createRef();

    componentDidMount = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "RequestForPurchasing" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "RequestForPurchasing",
        };
        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "RequestForPurchasing");
        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {
        }

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            requestedDeliveryDate: today
        });

        this.getCodesPurposeToUse();
        this.restartTable();
        this.fillNodes();
        this.getTableProperties();
    }

    getTableProperties = async () => {
        this.RequestForPurchasing = JSON.parse(localStorage.getItem('RequestForPurchasing'));
        if (this.RequestForPurchasing != null && this.RequestForPurchasing != undefined && this.RequestForPurchasing != "") {

            await this.setState({
                bordered: this.RequestForPurchasing.bordered,
                title: this.RequestForPurchasing.titleDrm == true ? title : undefined,
                titleDrm: this.RequestForPurchasing.titleDrm,
                showHeader: this.RequestForPurchasing.showHeader,
                footer: this.RequestForPurchasing.footerDrm == true ? footer : undefined,
                footerDrm: this.RequestForPurchasing.footerDrm,
                expandable: this.RequestForPurchasing.expandableDrm == true ? expandable : {},
                expandableDrm: this.RequestForPurchasing.expandableDrm,
                rowSelection: this.RequestForPurchasing.rowSelection,
                ellipsis: this.RequestForPurchasing.ellipsis,
                tableLayout: this.RequestForPurchasing.tableLayout,
                size: this.RequestForPurchasing.size,
                top: this.RequestForPurchasing.top,
                bottom: this.RequestForPurchasing.bottom,
                editBgColor: this.RequestForPurchasing.editBgColor,
                deleteBgColor: this.RequestForPurchasing.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.RequestForPurchasing.yScroll,
                xScroll: this.RequestForPurchasing.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.requestForPurchasingTable.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.requestForPurchasingTable.length, showQuickJumper: true,
                    position: [this.RequestForPurchasing.top, this.RequestForPurchasing.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    actionTemplate(rowData) {
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
            return <React.Fragment>
                <CustomMaterialMenuDelete row={rowData}
                    onDeleteRow={this.deleteModal.bind(this)} />
            </React.Fragment>;
        }
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedRequestForPurchasing: row
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    saveRequestForPurchasing = async () => {
        const addRequestForPurchasing = {
            itemId: this.state.selectedItemId,
            productId: this.state.productId,
            projectType: this.state.projectType,
            permanentCheck: this.state.permanentCheck,
            permanentType: this.state.permanentType,
            inspectibleCheck: this.state.inspectibleCheck,
            inspectibleType: this.state.inspectibleType,
            engineeringCheck: this.state.engineeringCheck,
            engineeringType: this.state.engineeringType,
            reusableCheck: this.state.reusableCheck,
            reusableType: this.state.reusableType,
            limitedUseCheck: this.state.limitedUseCheck,
            limitedUseType: this.state.limitedUseType,
            quantity: this.state.quantity,
            requestedDeliveryDate: this.state.requestedDeliveryDate,
            purposeToUseId: this.state.purposeToUseId,
            estimatedPrice: this.state.estimatedPrice,
            estimatedDeliveryStart: this.state.estimatedDeliveryStart,
            estimatedDeliveryEnd: this.state.estimatedDeliveryEnd,
            clientApproval: this.state.clientApproval,
            materialSpecification: this.state.materialSpecification
        }
        var response = await handleRequest("POST", "/RequestForPurchasing/add", addRequestForPurchasing);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
                this.createNewClientButton();
            }
            else {
                this.showError();
            }
        }
    }

    updateRequestForPurchasing = async () => {
        const updatedRequestForPurchasing = {
            id: this.state.selectedRequestForPurchasing.id,
            itemId: this.state.selectedItemId,
            productId: this.state.productId,
            projectType: this.state.projectType,
            permanentCheck: this.state.permanentCheck,
            permanentType: this.state.permanentType,
            inspectibleCheck: this.state.inspectibleCheck,
            inspectibleType: this.state.inspectibleType,
            engineeringCheck: this.state.engineeringCheck,
            engineeringType: this.state.engineeringType,
            reusableCheck: this.state.reusableCheck,
            reusableType: this.state.reusableType,
            limitedUseCheck: this.state.limitedUseCheck,
            limitedUseType: this.state.limitedUseType,
            quantity: this.state.quantity,
            requestedDeliveryDate: this.state.requestedDeliveryDate,
            purposeToUseId: this.state.purposeToUseId,
            estimatedPrice: this.state.estimatedPrice,
            estimatedDeliveryStart: this.state.estimatedDeliveryStart,
            estimatedDeliveryEnd: this.state.estimatedDeliveryEnd,
            clientApproval: this.state.clientApproval,
            materialSpecification: this.state.materialSpecification
        }
        var response2 = await handleRequest("POST", "/RequestForPurchasing/update", updatedRequestForPurchasing);
        if (response2.data !== null && response2.data !== undefined) {
            if (response2.data === "SUCCESS") {
                this.resetInputs();
                this.restartTable();
                this.showSuccess();
                this.createNewClientButton();
            }
            else {
                this.showError();
            }
        }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/ItemMaterialEquipment/getAllByCompany");
        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id;
                if (Boolean(element.itemDimensions) && element.itemDimensions.length > 0) {
                    element.itemDimensions.forEach(dimension => {
                        element[dimension.dimensionId + ""] = <CheckOutlined style={{ fontSize: '24px', color: '#08c' }} ></CheckOutlined>;
                    });
                }
            });
            this.setState({
                itemList: list
            });
        }

        if (response.data.length === 0) {
            this.setState({
                itemList: [],
            });
        }

        const responseItemProduct = await handleRequest("GET", "/ItemProduct/getAllByCompany");
        if (responseItemProduct.data.length === 0) {
            this.setState({
                allProducts: [],
            });
        }

        if (responseItemProduct.data.length !== 0) {
            this.setState({
                allProducts: responseItemProduct.data,
            });
        }

        const responseRequestForPurchasing = await handleRequest("GET", "/RequestForPurchasing/getAllByCompany");
        if (responseRequestForPurchasing.data.length === 0) {
            this.setState({
                requestForPurchasingTable: [],
            });
        }

        if (responseRequestForPurchasing.data.length !== 0) {
            this.setState({
                requestForPurchasingTable: responseRequestForPurchasing.data,
            });
        }
    }

    deleteRequestForPurchasingOK = async () => {
        const deleteRequestForPurchasing = {
            id: this.state.selectedRequestForPurchasing.id
        }
        var response = await handleRequest("POST", "/RequestForPurchasing/delete", deleteRequestForPurchasing);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.restartTable();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    changeButtonUpdate = async (row) => {
        console.log(row);

        var _selectedItem = row.itemMaterialEquipment;
        let ids = [];
        if (Boolean(_selectedItem.itemGroup)) {

            if (Boolean(_selectedItem.itemGroup.hierarchyCode) && _selectedItem.itemGroup.hierarchyCode.length > 0) {
                if (_selectedItem.itemGroup.hierarchyCode.includes(","))
                    ids = _selectedItem.itemGroup.hierarchyCode.split(",");
                else ids.push(_selectedItem.itemGroup.hierarchyCode);
            }
            ids.push(_selectedItem.groupId.toString());
        }

        let flagItemType = "Material";

        if (row.itemMaterialEquipment !== undefined && row.itemMaterialEquipment !== null) {
            if (row.itemMaterialEquipment.itemType === 2) {
                flagItemType = "Equipment";

                this.setState({
                    itemType: row.itemMaterialEquipment.itemType,
                    selectedItemId: row.itemId,
                    selectedItem: row.itemMaterialEquipment,
                });
            } else
                this.setState({
                    selectedItemId: null,
                    selectedItem: null
                });
        } else
            this.setState({
                selectedItemId: null,
                selectedItem: null
            });

        let flagProducts = [];
        for (let index = 0; index < this.state.allProducts.length; index++) {
            if (this.state.allProducts[index].itemId === row.itemId)
                flagProducts.push(this.state.allProducts[index]);
        }

        this.setState({
            products: flagProducts
        });

        this.formRef.current.setFieldsValue({
            Type: flagItemType,
            Parent: (row.itemMaterialEquipment != null && row.itemMaterialEquipment != undefined && row.itemMaterialEquipment != "") && ids,
            Product: (row.itemMaterialEquipment != null && row.itemMaterialEquipment != undefined && row.itemMaterialEquipment != "") && row.itemMaterialEquipment.itemName,
            Code: (row.product != null && row.product != undefined && row.product != "") && row.product.productCode,
            Quantity: row.quantity,
            RequestedDeliveryDate: (row.requestedDeliveryDate != null && row.requestedDeliveryDate != undefined && row.requestedDeliveryDate != "") && moment(row.requestedDeliveryDate, 'DD/MM/YYYY'),
            LocationToUse: "",
            PurposeToUse: row.codePurposeToUse.name,
            EstimatedPrice: row.estimatedPrice,
            ClientApproval: row.clientApproval,
            MaterialSpecification: row.materialSpecification,
            EstimatedDeliveryDays: (row.estimatedDeliveryStart != "" && row.estimatedDeliveryEnd != "")
                ? [moment(row.estimatedDeliveryStart, 'DD/MM/YYYY'), moment(row.estimatedDeliveryEnd, 'DD/MM/YYYY')] : "",
        });

        await this.setState({
            selectedRequestForPurchasing: row,
            itemId: row.itemId,
            selectedItemId: row.itemId,
            products: flagProducts,
            productId: row.productId,
            quantity: row.quantity,
            requestedDeliveryDate: row.requestedDeliveryDate,
            purposeToUse: (row.codePurposeToUse != null && row.codePurposeToUse != undefined && row.codePurposeToUse != "") && row.codePurposeToUse.name,
            purposeToUseId: row.purposeToUseId,
            newPurposeToUse: "",
            estimatedPrice: row.estimatedPrice,
            estimatedDeliveryStart: row.estimatedDeliveryStart,
            estimatedDeliveryEnd: row.estimatedDeliveryEnd,
            clientApproval: row.clientApproval,
            materialSpecification: row.materialSpecification,
            projectType: row.projectType,
            permanentCheck: row.permanentCheck,
            permanentType: row.permanentType,
            inspectibleCheck: row.inspectibleCheck,
            inspectibleType: row.inspectibleType,
            engineeringCheck: row.engineeringCheck,
            engineeringType: row.engineeringType,
            reusableCheck: row.reusableCheck,
            reusableType: row.reusableType,
            limitedUseCheck: row.limitedUseCheck,
            limitedUseType: row.limitedUseType,
            hiddenSave: true,
            hiddenUpdate: false,
            hiddenDataGiris: false,
            createNewButton: "Cancel",
            createNewButtonVariant: "danger"
        });
    }

    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            Type: "",
            Parent: "",
            Product: "",
            Code: "",
            Quantity: "",
            RequestedDeliveryDate: "",
            LocationToUse: "",
            PurposeToUse: "",
            EstimatedPrice: "",
            EstimatedDeliveryDays: ""
        });

        this.setState({
            selectedRequestForPurchasing: [],
            itemGroupIds: [],
            itemType: null,
            itemId: 0,
            products: [],
            selectedItemId: null,
            selectedItem: null,
            quantity: "",
            requestedDeliveryDate: null,
            purposeToUse: "",
            purposeToUseId: null,
            newPurposeToUse: "",
            estimatedPrice: "",
            estimatedDeliveryStart: "",
            estimatedDeliveryEnd: "",
            clientApproval: true,
            materialSpecification: true,
            projectType: null,
            permanentCheck: false,
            permanentType: null,
            inspectibleCheck: false,
            inspectibleType: null,
            engineeringCheck: false,
            engineeringType: null,
            reusableCheck: false,
            reusableType: null,
            limitedUseCheck: false,
            limitedUseType: null,
            hiddenSave: false,
            hiddenUpdate: true,
            hiddenDataGiris: true
        });
    }

    changeButtonCancel = () => {
        this.resetInputs();

        this.setState({
            hiddenSave: false,
            hiddenUpdate: true,
            hiddenDataGiris: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
        });
    }

    createNewClientButton = async () => {
        await this.resetInputs();

        if (this.state.createNewButton == "Create New") {
            this.setState({
                hiddenDataGiris: false,
                hiddenSave: false,
                hiddenUpdate: true,
                createNewButton: "Cancel",
                createNewButtonVariant: "danger",
            });
        }
        else {
            this.setState({
                hiddenDataGiris: true,
                hiddenSave: true,
                hiddenUpdate: false,
                createNewButton: "Create New",
                createNewButtonVariant: "info",
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalTable = async (tableTip) => {
        this.setState({
            modalTable: !this.state.modalTable,
            tableTip: tableTip,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    fillCodes = async () => {
        const response = await handleRequest("GET", "/ItemGroup/type/" + this.state.itemType);
        if (response.data !== null && response.data !== undefined) {
            this.setState({
                itemGroupSelectItems: response.data
            });
        }
    }

    fillNodes = async () => {
        const response3 = await handleRequest("GET", "/ItemGroup/getNodes");
        if (response3.data.length === 0) {
            this.setState({
                nodes: [],
                allNodes: [],
            });
        } else if (response3.data.length !== 0) {
            this.setState({
                nodes: response3.data,
                allNodes: response3.data
            });
        }
    }

    refreshNodes = () => {
        var list = this.state.allNodes;
        if (Boolean(list) && list.length > 0 && Boolean(this.state.itemType)) {
            list = list.filter(p => p.itemType === this.state.itemType)
        }
        this.setState({
            nodes: list
        });
    }

    onChangeItemGroup = (value, data) => {
        this.setState({
            itemGroupIds: value,
            itemGroups: data,
        }, this.fillMaterials);
    }

    fillMaterials = async () => {
        var groupId = null;
        if (Boolean(this.state.itemGroupIds) && this.state.itemGroupIds.length > 0) {
            groupId = this.state.itemGroupIds[this.state.itemGroupIds.length - 1];
        }

        var responseItemMaterialEquipment = await handleRequest("GET", "/ItemMaterialEquipment/getAllByCompany");

        if (responseItemMaterialEquipment.data.length !== 0) {
            var list = responseItemMaterialEquipment.data;
            if (Boolean(groupId))
                list = list.filter(p => p.groupId == groupId);
            list.forEach(element => {
                element.minValue = 0;
                element.maxValue = 0;
            });
            this.setState({
                itemListSelectItems: list
            });
        }
    }

    handleChangeProductCode = (e) => {
        this.setState({
            productId: e
        });
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    onChangeRequestedDeliveryDate = async (date, dateString) => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                requestedDeliveryDate: dateString
            });
        } else {
            this.setState({
                requestedDeliveryDate: today
            });
        }
    }

    handleChangeLocation = (e) => {
        this.setState({
            locationId: e
        });
    }

    handleChangePurposeToUse = (e) => {
        var value = "";
        for (var z = 0; z < this.state.purposeToUses.length; z++) {
            if (this.state.purposeToUses[z].id == e) {
                value = this.state.purposeToUses[z].name;
            }
        }
        this.setState({
            purposeToUse: value,
            purposeToUseId: e,
        });
    }

    addPurposeToUse = async () => {
        const { newPurposeToUse } = this.state;
        if (newPurposeToUse != null && newPurposeToUse != "" && newPurposeToUse != undefined) {
            const newItem = {
                name: newPurposeToUse,
                type: "PurposeToUse"
            };

            await handleRequest("POST", "/Code/add", newItem);

            this.setState({
                newPurposeToUse: ""
            });

            this.getCodesPurposeToUse();
        } else {
            this.showWarning();
        }
    }

    getCodesPurposeToUse = async () => {
        const responsePurpose = await handleRequest("GET", "/Code/type/PurposeToUse");

        if (responsePurpose.data !== null && responsePurpose.data !== undefined) {
            this.setState({
                purposeToUses: responsePurpose.data
            });
        }
    }

    onChangeEstimatedDeliveryDays = async (date, dateString) => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString[0] !== "" || dateString[1] !== "") {
            this.setState({
                estimatedDeliveryStart: dateString[0],
                estimatedDeliveryEnd: dateString[1]
            });
        } else {
            this.setState({
                estimatedDeliveryStart: "",
                estimatedDeliveryEnd: ""
            });
        }
    }

    createNewProduct = () => {
        var win = window.open('/ProductDefine', '_blank');
        win.focus();
    }

    render() {
        var itemTypeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Type");
        var itemParentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Parent");
        var itemProductAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Product");
        var itemCodeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Code");
        var itemProjectItemAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectItem");
        var itemPermanentAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Permanent");
        var itemInspectibleAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Inspectible");
        var itemEngineeringAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Engineering");
        var itemReusableAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Reusable");
        var itemLimitedUseAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "LimitedUse");
        var itemQuantityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Quantity");
        var itemRequestedDeliveryDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "RequestedDeliveryDate");
        var itemLocationToUseAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "LocationToUse");
        var itemPurposeToUseAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurposeToUse");
        var itemEstimatedPriceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EstimatedPrice");
        var itemEstimatedDeliveryDaysAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "EstimatedDeliveryDays");
        var itemClientApprovalAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ClientApproval");
        var itemMaterialSpecificationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MaterialSpecification");

        var itemTypeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Type");
        var itemParentRequired = this.state.dynamicInputs.find(p => p.inputKey === "Parent");
        var itemProductRequired = this.state.dynamicInputs.find(p => p.inputKey === "Product");
        var itemCodeRequired = this.state.dynamicInputs.find(p => p.inputKey === "Code");
        var itemQuantityRequired = this.state.dynamicInputs.find(p => p.inputKey === "Quantity");
        var itemRequestedDeliveryDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "RequestedDeliveryDate");
        var itemLocationToUseRequired = this.state.dynamicInputs.find(p => p.inputKey === "LocationToUse");
        var itemPurposeToUseRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurposeToUse");
        var itemEstimatedPriceRequired = this.state.dynamicInputs.find(p => p.inputKey === "EstimatedPrice");
        var itemEstimatedDeliveryDaysRequired = this.state.dynamicInputs.find(p => p.inputKey === "EstimatedDeliveryDays");


        const columns = [
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("RequestForPurchasing")} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "Item",
                title: <FormattedMessage id="RequestForPurchasingItem" defaultMessage="Item" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.itemMaterialEquipment !== undefined && data.itemMaterialEquipment !== null)
                            return data.itemMaterialEquipment.itemName;
                    }
                    return "";
                }
            },
            {
                key: "Product",
                title: <FormattedMessage id="RequestForPurchasingProduct" defaultMessage="Product" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.product !== undefined && data.product !== null)
                            return data.product.productCode;
                    }
                    return "";
                }
            },
            {
                dataIndex: "quantity",
                key: "Quantity",
                title: <FormattedMessage id="RequestForPurchasingQuantity" defaultMessage="Quantity" />
            },
            {
                dataIndex: "requestedDeliveryDate",
                key: "RequestedDeliveryDate",
                title: <FormattedMessage id="RequestForPurchasingItemRequestedDeliveryDate" defaultMessage="Requested Delivery Date" />
            },
            {
                key: "PurposeToUse",
                title: <FormattedMessage id="RequestForPurchasingItemPurposeToUse" defaultMessage="Purpose to Use" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        if (data.codePurposeToUse !== undefined && data.codePurposeToUse !== null)
                            return data.codePurposeToUse.name;
                    }
                    return "";
                }
            }
        ];

        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
        };

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) &&
                    <Form
                        {...layout}
                        initialValues={{ remember: false }}
                        onFinish={this.state.hiddenUpdate == false ? this.updateRequestForPurchasing : this.saveRequestForPurchasing}
                        onFinishFailed={onFinishFailed}
                        ref={this.formRef} >
                        <Row>
                            <Col xs={8}></Col>
                            <Col xs={3}>
                                <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                                    variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                                </Button>
                            </Col>
                            <Col xs={1}>
                                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("RequestForPurchasing")} icon="pi pi-cog"></PrimeButton>
                            </Col>
                        </Row>

                        <div hidden={this.state.hiddenDataGiris}>
                            <br />

                            {(itemTypeAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingType" defaultMessage="Type" />}
                                name="Type"
                                rules={[{ required: itemTypeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTypeRequired.translateRequired) == true ? itemTypeRequired.translateRequired : -1} defaultMessage="Please input a Type" /> }]}>
                                <Select placeholder="" disabled={Boolean(this.state.itemId) && this.state.itemId > 0} value={this.state.itemType}
                                    onChange={(value) => {
                                        this.setState({
                                            itemType: value,
                                            groupId: null
                                        }, this.refreshNodes);
                                    }}
                                >
                                    <Option key={null} value={null}>Select</Option>
                                    {itemTypeSelectItems.map(i => (
                                        <Option key={i.id} value={i.id}>{i.value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>}

                            {(itemParentAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingParent" defaultMessage="Parent" />}
                                name="Parent"
                                rules={[{ required: itemParentRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemParentRequired.translateRequired) == true ? itemParentRequired.translateRequired : -1} defaultMessage="Please input a Parent" /> }]}>
                                <Cascader disabled={Boolean(this.state.itemId) && this.state.itemId > 0}
                                    style={{ width: "100%" }}
                                    value={this.state.itemGroupIds}
                                    options={this.state.nodes}
                                    onChange={this.onChangeItemGroup}
                                    changeOnSelect
                                />
                            </Form.Item>}

                            {(itemProductAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingDescription" defaultMessage="Product" />}>
                                <Space>
                                    <Form.Item name="Product" noStyle
                                        rules={[{
                                            required: itemProductRequired.canBeRequired, message:
                                                <FormattedMessage id={Boolean(itemProductRequired.translateRequired) == true ? itemProductRequired.translateRequired : -1} defaultMessage="Please input a Product!" />
                                        }]}>
                                        <Select style={{ width: '200px' }} disabled={Boolean(this.state.itemId) && this.state.itemId > 0}
                                            placeholder="" value={this.state.selectedItemId} onChange={(value) => {
                                                let obj = this.state.itemListSelectItems.find(p => p.id === value);
                                                if ((obj !== undefined) && Boolean(obj.itemDimensions))
                                                    obj.itemDimensions.forEach(element => {
                                                        element.id = 0;
                                                    });
                                                if (obj !== undefined) {
                                                    this.setState({
                                                        selectedItemId: value,
                                                        selectedItem: obj
                                                    });

                                                    let flagProducts = [];
                                                    for (let index = 0; index < this.state.allProducts.length; index++) {
                                                        if (this.state.allProducts[index].itemId === value)
                                                            flagProducts.push(this.state.allProducts[index]);
                                                    }

                                                    this.setState({
                                                        products: flagProducts
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        selectedItemId: null,
                                                        selectedItem: null
                                                    });

                                                    this.setState({
                                                        products: []
                                                    });
                                                }
                                            }}>
                                            <Option key={null} value={null}>Select</Option>
                                            {this.state.itemListSelectItems.map(i => (
                                                <Option key={i.id} value={i.id}>{i.itemName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <AntdButton type="primary" onClick={this.createNewProduct}><FormattedMessage id="GeneralTextCreateNew" defaultMessage="Create New" /></AntdButton>
                                </Space>
                            </Form.Item>}

                            {(itemCodeAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingCode" defaultMessage="Code" />}
                                name="Code"
                                rules={[{ required: itemCodeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCodeRequired.translateRequired) == true ? itemCodeRequired.translateRequired : -1} defaultMessage="Please select a Product Code!" /> }]}>
                                <Select name="ProductCode"

                                    style={{ width: '200px' }}
                                    value={this.state.productId}
                                    onChange={this.handleChangeProductCode}>
                                    {this.state.products.map(i => (<Option key={i.id} value={i.id}>{i.productCode}</Option>))}
                                </Select>
                            </Form.Item>}

                            {/* Bu alana Properties kontrolleri gelecek...
                            {(itemParentAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingProperties" defaultMessage="Properties" />}
                                name="Properties">

                            </Form.Item>}
                            */}

                            {(itemProjectItemAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingProjectType" defaultMessage="Project Item" />}>
                                <Select placeholder="" style={{ width: '200px' }} value={this.state.projectType}
                                    onChange={(value) => {
                                        this.setState({
                                            projectType: value
                                        });

                                        if (value === "Yes" || value === "No") {
                                            this.setState({
                                                permanentCheck: true,
                                                permanentType: null
                                            });
                                        } else {
                                            this.setState({
                                                permanentCheck: false,
                                                permanentType: null,
                                                engineeringCheck: false,
                                                engineeringType: null,
                                                reusableCheck: false,
                                                reusableType: null,
                                                limitedUseCheck: false,
                                                limitedUseType: null
                                            });
                                        }
                                    }}
                                >
                                    <Option key={null} value={null}>None</Option>
                                    <Option key="Yes" value="Yes">Yes</Option>
                                    <Option key="No" value="No">No</Option>
                                </Select>
                            </Form.Item>}

                            {(itemPermanentAttributes.visible) && (this.state.permanentCheck) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingPermanentType" defaultMessage="Permanent" />}>
                                <Select placeholder="" style={{ width: '200px' }} value={this.state.permanentType}
                                    onChange={(value) => {
                                        this.setState({
                                            permanentType: value
                                        });

                                        if (value === "Yes") {
                                            this.setState({
                                                inspectibleCheck: true,
                                                inspectibleType: null,
                                                engineeringCheck: true,
                                                engineeringType: null,
                                                reusableCheck: false,
                                                reusableType: null,
                                                limitedUseCheck: false,
                                                limitedUseType: null
                                            });
                                        } else if (value === "No") {
                                            this.setState({
                                                inspectibleCheck: true,
                                                inspectibleType: null,
                                                engineeringCheck: true,
                                                engineeringType: null,
                                                reusableCheck: true,
                                                reusableType: null,
                                            });
                                        }
                                        else {
                                            this.setState({
                                                inspectibleCheck: false,
                                                inspectibleType: null,
                                                engineeringCheck: false,
                                                engineeringType: null,
                                                reusableCheck: false,
                                                reusableType: null,
                                                limitedUseCheck: false,
                                                limitedUseType: null
                                            });
                                        }
                                    }}
                                >
                                    <Option key={null} value={null}>None</Option>
                                    <Option key="Yes" value="Yes">Yes</Option>
                                    <Option key="No" value="No">No</Option>
                                </Select>
                            </Form.Item>}

                            {(itemInspectibleAttributes.visible) && (this.state.inspectibleCheck) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingInspectibleType" defaultMessage="Inspectible" />}>
                                <Select placeholder="" style={{ width: '200px' }} value={this.state.inspectibleType}
                                    onChange={(value) => {
                                        this.setState({
                                            inspectibleType: value
                                        });
                                    }}
                                >
                                    <Option key={null} value={null}>None</Option>
                                    <Option key="Yes" value="Yes">Yes</Option>
                                    <Option key="No" value="No">No</Option>
                                </Select>
                            </Form.Item>}

                            {(itemEngineeringAttributes.visible) && (this.state.engineeringCheck) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingEngineeringType" defaultMessage="Engineering" />}>
                                <Select placeholder="" style={{ width: '200px' }} value={this.state.engineeringType}
                                    onChange={(value) => {
                                        this.setState({
                                            engineeringType: value
                                        });
                                    }}
                                >
                                    <Option key={null} value={null}>None</Option>
                                    <Option key="Yes" value="Yes">Yes</Option>
                                    <Option key="No" value="No">No</Option>
                                </Select>
                            </Form.Item>}

                            {(itemReusableAttributes.visible) && (this.state.reusableCheck) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingEngineeringType" defaultMessage="Reusable" />}>
                                <Select placeholder="" style={{ width: '200px' }} value={this.state.reusableType}
                                    onChange={(value) => {
                                        this.setState({
                                            reusableType: value
                                        });

                                        if (value === "Yes" || value === "No") {
                                            this.setState({
                                                limitedUseCheck: true,
                                                limitedUseType: null,
                                            });
                                        } else {
                                            this.setState({
                                                limitedUseCheck: false,
                                                limitedUseType: null,
                                            });
                                        }
                                    }}
                                >
                                    <Option key={null} value={null}>None</Option>
                                    <Option key="Yes" value="Yes">Yes</Option>
                                    <Option key="No" value="No">No</Option>
                                </Select>
                            </Form.Item>}

                            {(itemLimitedUseAttributes.visible) && (this.state.limitedUseCheck) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingEngineeringType" defaultMessage="Limited Use" />}>
                                <Select placeholder="" style={{ width: '200px' }} value={this.state.limitedUseType}
                                    onChange={(value) => {
                                        this.setState({
                                            limitedUseType: value
                                        });
                                    }}
                                >
                                    <Option key={null} value={null}>None</Option>
                                    <Option key="Yes" value="Yes">Yes</Option>
                                    <Option key="No" value="No">No</Option>
                                </Select>
                            </Form.Item>}

                            {(itemQuantityAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingQuantity" defaultMessage="Quantity" />}
                                name="Quantity"
                                rules={[{ required: itemQuantityRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemQuantityRequired.translateRequired) == true ? itemQuantityRequired.translateRequired : -1} defaultMessage="Please input Quantity!" /> }]}>
                                <InputNumber id="RequestForPurchasingQuantity" min={0} style={{ width: '50%' }}
                                    onChange={(e) => this.setState({ quantity: e })} value={this.state.quantity} />
                            </Form.Item>}

                            {(itemRequestedDeliveryDateAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingRequestedDeliveryDate" defaultMessage="Requested Delivery Date" />}
                                name="RequestedDeliveryDate"
                                rules={[{
                                    required: itemRequestedDeliveryDateRequired.canBeRequired,
                                    message: <FormattedMessage id={Boolean(itemRequestedDeliveryDateRequired.translateRequired) == true ? itemRequestedDeliveryDateRequired.translateRequired : -1} defaultMessage="Please input Requested Delivery Date!" />
                                }]}>
                                <DatePicker id="RequestForPurchasingRequestedDeliveryDate" value={moment(this.state.requestedDeliveryDate, 'DD/MM/YYYY')}
                                    disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeRequestedDeliveryDate} />
                            </Form.Item>}

                            {(itemLocationToUseAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingLocationToUse" defaultMessage="Location to Use" />}
                                name="LocationToUse"
                                rules={[{
                                    required: itemLocationToUseRequired.canBeRequired,
                                    message: <FormattedMessage id={Boolean(itemLocationToUseRequired.translateRequired) == true ? itemLocationToUseRequired.translateRequired : -1} defaultMessage="Please input Location to Use!" />
                                }]}>
                                <Select name="LocationToUse"
                                    style={{ width: '100%' }}
                                    value={this.state.locationId}
                                    onChange={this.handleChangeLocation}>
                                    {this.state.locations.map(i => (<Option key={i.id} value={i.id}>{i.productCode}</Option>))}
                                </Select>
                            </Form.Item>}

                            {(itemPurposeToUseAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingPurposeToUse" defaultMessage="Purpose to Use" />}
                                name="PurposeToUse"
                                rules={[{
                                    required: itemPurposeToUseRequired.canBeRequired,
                                    message: <FormattedMessage id={Boolean(itemPurposeToUseRequired.translateRequired) == true ? itemPurposeToUseRequired.translateRequired : -1} defaultMessage="Please input Purpose to Use!" />
                                }]}>
                                <Select
                                    name="PurposeToUse"
                                    style={{ width: '100%' }}
                                    value={this.state.purposeToUse}
                                    onChange={this.handleChangePurposeToUse}
                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.newPurposeToUse}
                                                    onChange={(event) => { this.setState({ newPurposeToUse: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addPurposeToUse} >
                                                    <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Purpose To Use" />  </a>
                                            </div>
                                        </div>
                                    )}>
                                    {this.state.purposeToUses.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>}

                            {(itemEstimatedPriceAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingEstimatedPrice" defaultMessage="Estimated Price" />}
                                name="EstimatedPrice"
                                rules={[{
                                    required: itemEstimatedPriceRequired.canBeRequired,
                                    message: <FormattedMessage id={Boolean(itemEstimatedPriceRequired.translateRequired) == true ? itemEstimatedPriceRequired.translateRequired : -1} defaultMessage="Please input Estimated Price!" />
                                }]}>
                                <InputNumber id="RequestForPurchasingEstimatedPrice" min={0}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} style={{ width: '50%' }}
                                    onChange={(e) => this.setState({ estimatedPrice: e })} value={this.state.estimatedPrice} />
                            </Form.Item>}

                            {(itemEstimatedDeliveryDaysAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingEstimatedDeliveryDays" defaultMessage="Estimated Delivery Days" />}
                                name="EstimatedDeliveryDays"
                                rules={[{
                                    required: itemEstimatedDeliveryDaysRequired.canBeRequired,
                                    message: <FormattedMessage id={Boolean(itemEstimatedDeliveryDaysRequired.translateRequired) == true ? itemEstimatedDeliveryDaysRequired.translateRequired : -1} defaultMessage="Please input Estimated Delivery Days!" />
                                }]}>
                                <RangePicker disabledDate={this.disabledDate} value={(this.state.estimatedDeliveryStart != null && this.state.estimatedDeliveryStart != undefined && this.state.estimatedDeliveryStart != ""
                                    && this.state.estimatedDeliveryEnd != null && this.state.estimatedDeliveryEnd != undefined && this.state.estimatedDeliveryEnd != "")
                                    ? [moment(this.state.estimatedDeliveryStart, 'DD/MM/YYYY'), moment(this.state.estimatedDeliveryEnd, 'DD/MM/YYYY')]
                                    : ""} format="DD/MM/YYYY" onChange={this.onChangeEstimatedDeliveryDays} />
                            </Form.Item>}

                            {(itemClientApprovalAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingClientApproval" defaultMessage="Client Approval" />}
                                name="ClientApproval">
                                <Switch checkedChildren="Yes" unCheckedChildren="No"
                                    checked={this.state.clientApproval} defaultChecked
                                    onChange={(value) => { this.setState({ clientApproval: value }); console.log(value); }} />
                            </Form.Item>}

                            {(itemMaterialSpecificationAttributes.visible) && <Form.Item
                                label={<FormattedMessage id="RequestForPurchasingMaterialSpecification" defaultMessage="Material Specification" />}
                                name="MaterialSpecification">
                                <Switch checkedChildren="Yes" unCheckedChildren="No"
                                    checked={this.state.materialSpecification} defaultChecked
                                    onChange={(value) => { this.setState({ materialSpecification: value }) }} />
                            </Form.Item>}

                            <div hidden={this.state.hiddenSave}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="5">
                                            <Button id="RequestForPurchasingSaveButton"
                                                style={{ width: '100%' }} type="submit"
                                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </FormBoot.Group>
                                }
                            </div>

                            <div hidden={this.state.hiddenUpdate}>
                                {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="3"></Col>
                                        <Col sm="2">
                                            <Button id="RequestForPurchasingCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                        </Col>
                                        <Col sm="3">
                                            <Button id="RequestForPurchasingUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </FormBoot.Group>
                                }
                            </div>

                        </div>
                        <br />
                        <br />

                        <div className="datatable-doc-demo">
                            <div className="content-section implementation">
                                <Mnd.MNDTable
                                    state={this.state}
                                    columns={tableColumns} dataSource={this.state.requestForPurchasingTable}
                                    pagination={this.state.paginationDisabled}
                                    expandable={this.state.expandable}
                                    footer={this.state.footer}
                                    title={this.state.title}
                                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                />
                            </div>
                        </div>

                    </Form>}

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >

                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    {this.state.messageTip == "Success" &&
                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                    {this.state.messageTip == "Warning" &&
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                    {this.state.messageTip == "Error" &&
                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <Row >
                            <Col sm="12">
                                {this.state.message}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                        <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id={this.state.tableTip} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                        <Button variant="danger" onClick={this.deleteRequestForPurchasingOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        ));
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RequestForPurchasing)