import React, { Component } from 'react';

import { Button as PrimeButton } from "primereact/button";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './OrganizationChartCustomStyle.css';
import { Tree } from 'primereact/tree';
import { Cascader } from 'antd';


export class LocationDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departmentNodes: [],
            globalFilter: null,
            expandedKeys: {},
            selectedDepartmentKeys: null,

            locations: [],
            selectedLocations: [],
          



        };



    }

    componentWillMount = async () => {

        const response = await handleRequest("GET", "/WFLocation/getNodesLocations");

       

        if (response.data.length !== 0) {
           

            this.setState({
                locations: response.data,
            });
        }

        this.restartTable();

    }




    restartTable = async () => {


        var response = [];
        response = await handleRequest("GET", "/Department/getTreeModel");


        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                departmentNodes: []

            });
        } else
            if (response.data.length !== 0) {

                this.setState({
                    departmentNodes: response.data.cascader 
                });
            }

        if (this.state.selectedLocations.length > 0) {

            var response2 = [];
           // var param = { userId: this.state.userId };
            response2 = await handleRequest("POST", "/WFLocation/getLocationDepartments", this.state.selectedLocations);

            console.log(response2);
            if (response2.length === 0 || response2.data.length === 0) {
                this.setState({
                    selectedDepartmentKeys: null

                });
            } else
                if (response2.data.length !== 0) {
                    const obj = {};

                    for (const key of response2.data) {
                        obj[key] = {checked: true } ; 
                    }
                    console.log(obj); 
                    this.setState({
                        selectedDepartmentKeys: obj
                    });
                }
        }
    }

   


    saveAll = async () => {

        console.log(this.state.selectedDepartmentKeys);

        if (this.state.selectedLocations.length > 0 && this.state.selectedDepartmentKeys!==null) {

            var list = [];
            var flagArray = Object.keys(this.state.selectedDepartmentKeys);

            this.state.selectedLocations.forEach(location => {
                
                flagArray.forEach(department => {
                   
                    const newItem = {
                      
                        departmentId: department,
                        locationId : location
                    }
                    list.push(newItem);
                });
            });
         


            await handleRequest("POST", "/WFLocation/addLocationDepartment", list); 
            this.restartTable();
            this.showSuccess();

        } else {
            this.showError("Please Select Locations and Departments !");
        }
    };



    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

 
    
    
    onCascaderChange = (value) => {

        console.log(value); 
        this.setState({
            selectedLocations: value

        })
        this.restartTable();

    }


    render() {


        
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Card>
                    <Row>

                        <Col sm="2">

                        </Col>
                        <Col sm="8">
                            <Form>

                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >

                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        Work-Location :
                                     </Form.Label>
                                    <Col sm="10">
                                    <Cascader style={{ width: '100%' }} options={this.state.locations} onChange={this.onCascaderChange} changeOnSelect /> 
                                       
                                    </Col>                                   
                                </Form.Group>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                       Department :
                                     </Form.Label>
                                    <Col sm="10">
                                        <Tree style={{ width: '100%' }} value={this.state.departmentNodes} selectionMode="checkbox"  selectionKeys={this.state.selectedDepartmentKeys} onSelectionChange={e => this.setState({ selectedDepartmentKeys: e.value })} />

                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col sm="2">
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="2">
                        </Col>
                        <Col sm="8">
                            <PrimeButton style={{ width: '100%' }} id='add-authorization' className="p-button-info" onClick={this.saveAll} label="Save All Changes" />
                        </Col>
                        <Col sm="2"> 
                        </Col>
                    </Row>
                </Card>

            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(LocationDepartment)  
