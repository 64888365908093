import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { Modal, Button as ReactButton, Col, Form, Form as FormBoot, Row } from 'react-bootstrap';
import { Card, Checkbox, Select, InputNumber as InputNumberAnt, Table, Space } from 'antd';
import { InputNumber } from 'primereact/inputnumber';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { Redirect } from "react-router-dom";
import { MultiCascader } from 'rsuite';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
//import 'rsuite/dist/styles/rsuite-default.css';
import './css/rsuite-default-forcascader.css'

const { Column } = Table;
const { Option } = Select;

export class PurchasingAuthority extends Component {
    constructor() {
        super();
        this.state = {
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            deleteModal: false,
            modalEdit: false,
            purchasingAuthorityId: null,
            purchasingAuthorityList: [],
            designationCascader: [],
            valuesDesignation: [],
            equipmentCascaderData: [],
            materialCascaderData: [],
            itemGroup: "",
            itemCascader: [],
            valuesItem: [],
            selectedCategory: [],
            categoryOptions: ['Project Item', 'Permanent Use', 'Reusable', 'Other'],
            selectedAuthority: [],
            authorityOptions: ['Request', 'Review', 'Approval'],
            quantity: 1,
            authorityPeriodValue: "",
            authorityPeriodType: "",
            priceMin: "",
            priceMax: "",
            priceCurrency: "",

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.dynamicInputs = this.dynamicInputs.bind(this);
        this.multiCascaderChange = this.multiCascaderChange.bind(this);
        this.multiCascaderChange1 = this.multiCascaderChange1.bind(this);
    };

    formRef = React.createRef();

    // deleteEmptyChildren(node){
    //     if (node.children !== undefined) {
    //         node.children = node.children.filter(
    //             function(children) {
    //                 if (children.length === 0) {
    //                     console.log(children);
    //                     return false;
    //                 } else {
    //                     console.log(children);
    //                     this.deleteEmptyChildren(children);  //bu this çalışmıyor?
    //                     return true;
    //                 }
    //             }
    //         );
    //     }
    // }

    componentDidMount = async () => {

        //test için designationCascader'den gelen data kullanılır.
        // var abc = this.deleteEmptyChildren(test[0]);
        // console.log("ÇIKTI");
        // console.log(abc);

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "PurchasingAuthority" });

        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "PurchasingAuthority",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "PurchasingAuthority");

        console.log(menuObj);

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {

        }

        if (document.getElementsByClassName('p-inputtext')[0] !== undefined)
            document.getElementsByClassName('p-inputtext')[0].style.textAlign = "center";

        const responseCascader = await handleRequest("GET", "/Department/getNodes2");

        if (responseCascader.data.length !== 0) {
            this.setState({
                designationCascader: responseCascader.data
            });
        }

        const responseCascaderEquipment = await handleRequest("GET", "/EquipmentLog/getEquipmentLogNodes");

        // console.log(responseCascaderEquipment.data.map(this.formatData));
        // var sampleArray = responseCascaderEquipment.data.map(this.formatData);
        // var sampleArray = this.removeEmptyArrays(responseCascaderEquipment.data);
        // console.log(sampleArray);

        if (responseCascaderEquipment.data.length !== 0) {
            this.setState({
                equipmentCascaderData: responseCascaderEquipment.data
            });
        }

        const responseCascaderMaterial = await handleRequest("GET", "/MaterialIdentification/getTypeMaterialIdentifications2");

        if (responseCascaderMaterial.data.length !== 0) {
            this.setState({
                materialCascaderData: responseCascaderMaterial.data
            });
        }

        const responsePA = await handleRequest("GET", "/PurchasingAuthority/getAllByCompany");

        if (responsePA.data.length === 0) {
            this.setState({
                purchasingAuthorityList: []
            });
        } else {
            this.setState({
                purchasingAuthorityList: responsePA.data
            });
        }
    }

    // removeEmptyArrays = (data) => {
    //     for (var key in data) {
    //         var item = data[key];
    //         if (Array.isArray(item)) {
    //             if (item.length == 0) {
    //                 delete data[key];
    //             }
    //         } else if (typeof item == "object") {
    //             this.removeEmptyArrays(item);
    //         }
    //     }
    // }

    // formatData = (val) => {
    //     if (val.children !== undefined) {
    //         if (val.children.length > 0) val.children.map(a => a.children.length > 0 ? this.formatData(a.children) : this.deleteSubs(a));
    //         else this.deleteSubs(val);
    //     }
    //     return val;
    // }

    // deleteSubs = (val) => {
    //     delete val.children;
    // }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs. Inputs can't be empty or 0." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    createNew = async () => {
        if (this.state.hidden) {
            this.setState({
                hidden: false
            });
        } else {
            this.resetInputs();
        }
    }

    stopProcess = () => {
        this.resetInputs();
    }

    restartTable = async () => {
        const responsePA = await handleRequest("GET", "/PurchasingAuthority/getAllByCompany");

        if (responsePA.data.length === 0) {
            this.setState({
                purchasingAuthorityList: []
            });
        } else {
            this.setState({
                purchasingAuthorityList: responsePA.data
            });
        }
    }

    resetInputs = () => {
        this.setState({
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            purchasingAuthorityId: null,
            valuesDesignation: [],
            itemGroup: "",
            itemCascader: [],
            valuesItem: [],
            selectedCategory: [],
            selectedAuthority: [],
            quantity: 1,
            authorityPeriodValue: "",
            authorityPeriodType: "",
            priceMin: "",
            priceMax: "",
            priceCurrency: "",
        });
    }

    onChangeCategoryOptions = (checkedValues) => {
        this.setState({
            selectedCategory: checkedValues
        });
    }

    onChangeAuthorityOptions = (checkedValues) => {
        this.setState({
            selectedAuthority: checkedValues
        });
    }

    showMultiCascader = () => {
        document.getElementsByClassName('rs-picker-menu')[0].style.opacity = "1";
    }

    hideMultiCascader = () => {
        document.getElementsByClassName('rs-picker-menu')[0].style.opacity = "";
    }

    multiCascaderChange(value) {
        console.log('handleChange1', value);
        this.setState({
            valuesDesignation: value
        });
    }

    multiCascaderChange1(value) {
        console.log('handleChange2', value);
        this.setState({
            valuesItem: value
        });
    }

    handleChangeItemGroup = (value) => {
        this.setState({ itemGroup: value });

        if (value === "Equipment") {
            this.setState({
                itemCascader: this.state.equipmentCascaderData
            });
        } else if (value === "Material") {
            this.setState({
                itemCascader: this.state.materialCascaderData
            });
        }
    }

    savePurchasingAuthority = async () => {
        if (this.state.hiddenUpdate) {
            if (this.state.valuesDesignation.length !== 0 && this.state.itemGroup !== "" && this.state.valuesItem.length !== 0
                && this.state.selectedCategory.length !== 0 && this.state.selectedAuthority.length !== 0 && this.state.priceMin !== ""
                && this.state.priceMax !== 0 && this.state.priceCurrency !== "" && this.state.authorityPeriodValue !== ""
                && this.state.authorityPeriodType !== "") {

                const newPA = {
                    departmentDesignationIds: this.state.valuesDesignation.join(),
                    itemGroup: this.state.itemGroup,
                    itemIds: this.state.valuesItem.join(),
                    categories: this.state.selectedCategory.join(),
                    authorities: this.state.selectedAuthority.join(),
                    minPrice: this.state.priceMin,
                    maxPrice: this.state.priceMax,
                    currency: this.state.priceCurrency,
                    quantity: this.state.quantity,
                    authorityPeriod: this.state.authorityPeriodValue,
                    authorityPeriodType: this.state.authorityPeriodType
                }

                var response = await handleRequest("POST", "/PurchasingAuthority/add", newPA);
                if (response.data !== null && response.data !== undefined) {
                    if (response.data === "SUCCESS") {
                        this.resetInputs();
                        this.restartTable();
                        this.showSuccess("All data has been saved successfully.");
                    }
                    else {
                        this.showError();
                    }
                }
                else {
                    this.showError();
                }
            } else {
                this.showWarning();
            }
        } else if (this.state.hiddenSave) {
            if (this.state.purchasingAuthorityId !== null && this.state.valuesDesignation.length !== 0 && this.state.itemGroup !== ""
                && this.state.valuesItem.length !== 0 && this.state.selectedCategory.length !== 0 && this.state.selectedAuthority.length !== 0
                && this.state.priceMin !== "" && this.state.priceMax !== 0 && this.state.priceCurrency !== ""
                && this.state.authorityPeriodValue !== "" && this.state.authorityPeriodType !== "") {

                const updatedPA = {
                    id: this.state.purchasingAuthorityId,
                    departmentDesignationIds: this.state.valuesDesignation.join(),
                    itemGroup: this.state.itemGroup,
                    itemIds: this.state.valuesItem.join(),
                    categories: this.state.selectedCategory.join(),
                    authorities: this.state.selectedAuthority.join(),
                    minPrice: this.state.priceMin,
                    maxPrice: this.state.priceMax,
                    currency: this.state.priceCurrency,
                    quantity: this.state.quantity,
                    authorityPeriod: this.state.authorityPeriodValue,
                    authorityPeriodType: this.state.authorityPeriodType
                }

                var response = await handleRequest("POST", "/PurchasingAuthority/update", updatedPA);
                if (response.data !== null && response.data !== undefined) {
                    if (response.data === "SUCCESS") {
                        this.setState({
                            modalEdit: false,
                            purchasingAuthorityId: null
                        });
                        this.resetInputs();
                        this.restartTable();
                        this.showSuccess("All data has been updated successfully.");
                    }
                    else {
                        this.showError();
                    }
                }
                else {
                    this.showError();
                }
            } else {
                this.showWarning();
            }
        }
    }

    deleteModal = (row) => {
        this.setState({
            purchasingAuthorityId: row.id,
            deleteModal: true
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deletePurchasingAuthority = async () => {
        if (this.state.purchasingAuthorityId !== null) {
            const selectedPA = {
                id: this.state.purchasingAuthorityId
            }
            var response = await handleRequest("POST", "/PurchasingAuthority/delete", selectedPA);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        deleteModal: false,
                        purchasingAuthorityId: null
                    });
                    this.restartTable();
                    this.showSuccess("Data has been deleted successfully.");
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }

            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0px";
            }, 500);
        }
    }

    changeButtonUpdate = (row) => {

        if (row.itemGroup === "Material") {
            this.setState({
                itemCascader: this.state.materialCascaderData
            });
        } else if (row.itemGroup === "Equipment") {
            this.setState({
                itemCascader: this.state.equipmentCascaderData
            });
        }

        this.setState({
            hidden: false,
            hiddenSave: true,
            hiddenUpdate: false,
            purchasingAuthorityId: row.id,
            valuesDesignation: row.departmentDesignationIds.split(","),
            itemGroup: row.itemGroup,
            valuesItem: row.itemIds.split(","),
            selectedCategory: row.categories.split(","),
            selectedAuthority: row.authorities.split(","),
            priceMin: row.minPrice,
            priceMax: row.maxPrice,
            priceCurrency: row.currency,
            quantity: row.quantity,
            authorityPeriodType: row.authorityPeriodType,
            authorityPeriodValue: row.authorityPeriod
        });
    }

    cancelPA = () => {
        this.resetInputs();
    }

    searchCascader = (element, matchingTitle) => {
        if (element.value == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchCascader(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        // var itemPADesignationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityDesignation");
        // var itemPAItemGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityItemGroup");
        // var itemPAItemAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityItem");
        // var itemPACategoryAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityCategory");
        // var itemPAAuthorityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityAuthority");
        // var itemPAPriceAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityPrice");
        // var itemPAQuantityAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityQuantity");
        // var itemPAAuthorityPeriodAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityAuthorityPeriod");

        // var itemPADesignationRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityDesignation");
        // var itemPAItemGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityItemGroup");
        // var itemPAItemRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityItem");
        // var itemPACategoryRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityCategory");
        // var itemPAAuthorityRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityAuthority");
        // var itemPAPriceRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityPrice");
        // var itemPAQuantityRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityQuantity");
        // var itemPAAuthorityPeriodRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityAuthorityPeriod");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 4,
                span: 16,
            },
        };

        return <div>

                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deletePurchasingAuthority}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <div>
                        <Row>
                            <Col sm="1">
                                {this.state.isAdmin ?
                                    <IconButton color="primary" onClick={this.showMNDSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                    :
                                    <IconButton color="primary" onClick={this.showSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                }
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                    <HelpIcon />
                                </IconButton>
                                }
                            </Col>
                        </Row>
                    </div>

                    {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && <Card>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="PurchasingAuthorityCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="PurchasingAuthorityStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden}>
                            {/*<Form
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.savePurchasingAuthority}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {(itemPADesignationAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityDesignation" defaultMessage="Designation" />}
                                        name="PurchasingAuthorityDesignation"
                                        rules={[{ required: itemPADesignationRequired.canBeRequired, message: <FormattedMessage id={itemPADesignationRequired.translateRequired} defaultMessage="Please select department/designation!" /> }]} >
                                        <MultiCascader
                                            id="PurchasingAuthorityDesignation"
                                            data={this.state.designationCascader}
                                            style={{ width: '100%' }}
                                            onOpen={this.showMultiCascader}
                                            onExit={this.hideMultiCascader}
                                            value={this.state.valuesDesignation}
                                            onChange={this.multiCascaderChange} />
                                    </Form.Item>
                                }

                                {(itemPAItemGroupAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityItemGroup" defaultMessage="Item Group" />}
                                        name="PurchasingAuthorityItemGroup"
                                        rules={[{ required: itemPAItemGroupRequired.canBeRequired, message: <FormattedMessage id={itemPAItemGroupRequired.translateRequired} defaultMessage="Please select an item group!" /> }]} >
                                        <Select value={this.state.itemGroup} placeholder="Select" style={{ width: 200 }}
                                            onChange={this.handleChangeItemGroup} id="PurchasingAuthorityItemGroup">
                                            <Select.Option value="">Select</Select.Option>
                                            <Select.Option value="Material">Material</Select.Option>
                                            <Select.Option value="Equipment">Equipment</Select.Option>
                                            <Select.Option value="Spare Parts">Spare Parts</Select.Option>
                                            <Select.Option value="Office Staff">Office Staff</Select.Option>
                                            <Select.Option value="Stationary">Stationary</Select.Option>
                                            <Select.Option value="Subcontracting">Subcontracting</Select.Option>
                                            <Select.Option value="Service">Service</Select.Option>
                                            <Select.Option value="Repair">Repair</Select.Option>
                                            <Select.Option value="Furniture">Furniture</Select.Option>
                                        </Select>
                                        {" "}
                                        <ReactButton id="PurchasingAuthorityNewItemGroup" variant="info">
                                            <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                        </ReactButton>
                                    </Form.Item>
                                }

                                {(itemPAItemAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityItem" defaultMessage="Item Group" />}
                                        name="PurchasingAuthorityItem"
                                        rules={[{ required: itemPAItemRequired.canBeRequired, message: <FormattedMessage id={itemPAItemRequired.translateRequired} defaultMessage="Please select an item!" /> }]} >
                                        <MultiCascader
                                            id="PurchasingAuthorityItem"
                                            data={this.state.itemCascader}
                                            style={{ width: '100%' }}
                                            onOpen={this.showMultiCascader}
                                            onExit={this.hideMultiCascader}
                                            value={this.state.valuesItem}
                                            onChange={this.multiCascaderChange1} />
                                    </Form.Item>
                                }

                                {(itemPACategoryAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityCategory" defaultMessage="Category" />}
                                        name="PurchasingAuthorityCategory"
                                        rules={[{ required: itemPACategoryRequired.canBeRequired, message: <FormattedMessage id={itemPACategoryRequired.translateRequired} defaultMessage="Please select a category!" /> }]} >
                                        <Checkbox.Group id="PurchasingAuthorityCategory" value={this.state.selectedCategory} options={this.state.categoryOptions} onChange={this.onChangeCategoryOptions} />
                                    </Form.Item>
                                }

                                {(itemPAAuthorityAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityAuthority" defaultMessage="Authority" />}
                                        name="PurchasingAuthorityAuthority"
                                        rules={[{ required: itemPAAuthorityRequired.canBeRequired, message: <FormattedMessage id={itemPAAuthorityRequired.translateRequired} defaultMessage="Please select an authority!" /> }]} >
                                        <Checkbox.Group id="PurchasingAuthorityAuthority" value={this.state.selectedAuthority} options={this.state.authorityOptions} onChange={this.onChangeAuthorityOptions} />
                                    </Form.Item>
                                }

                                {(itemPAPriceAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityPrice" defaultMessage="Price" />}
                                        name="PurchasingAuthorityPrice"
                                        rules={[{ required: itemPAPriceRequired.canBeRequired, message: <FormattedMessage id={itemPAPriceRequired.translateRequired} defaultMessage="Please input minimum and maximum prices!" /> }]} >
                                        <InputNumberAnt id="PurchasingAuthorityPrice" min={1} value={this.state.priceMin} onChange={(value) => { this.setState({ priceMin: value }) }} />
                                        {" - "}
                                        <InputNumberAnt id="PurchasingAuthorityPrice" min={1} value={this.state.priceMax} onChange={(value) => { this.setState({ priceMax: value }) }} />
                                        {" "}
                                        <Select id="PurchasingAuthorityPrice" style={{ width: '50px' }} value={this.state.priceCurrency} placeholder="Select a Currency" onChange={(value) => { this.setState({ priceCurrency: value }) }} >
                                            <Option value="$">$</Option>
                                            <Option value="€">€</Option>
                                            <Option value="SR">SR</Option>
                                        </Select>
                                    </Form.Item>
                                }

                                {(itemPAQuantityAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityQuantity" defaultMessage="Quantity" />}
                                        name="PurchasingAuthorityQuantity"
                                        rules={[{ required: itemPAQuantityRequired.canBeRequired, message: <FormattedMessage id={itemPAQuantityRequired.translateRequired} defaultMessage="Please input quantity!" /> }]} >
                                        <InputNumber
                                            size={15}
                                            id="PurchasingAuthorityQuantity"
                                            value={this.state.quantity}
                                            onValueChange={(e) => this.setState({ quantity: e.value })}
                                            showButtons
                                            buttonLayout="horizontal"
                                            step={1}
                                            min={0}
                                            decrementButtonClassName="p-button-danger"
                                            incrementButtonClassName="p-button-success"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"
                                        />
                                    </Form.Item>
                                }

                                {(itemPAAuthorityPeriodAttributes.visible) &&
                                    <Form.Item
                                        label={<FormattedMessage id="PurchasingAuthorityAuthorityPeriod" defaultMessage="Authority Period" />}
                                        name="PurchasingAuthorityAuthorityPeriod"
                                        rules={[{ required: itemPAAuthorityPeriodRequired.canBeRequired, message: <FormattedMessage id={itemPAAuthorityPeriodRequired.translateRequired} defaultMessage="Please input quantity!" /> }]} >
                                        <InputNumberAnt id="PurchasingAuthorityAuthorityPeriod" min={1} value={this.state.authorityPeriodValue} onChange={(value) => { this.setState({ authorityPeriodValue: value }) }} />
                                        {" "}
                                        <Select id="PurchasingAuthorityAuthorityPeriod" value={this.state.authorityPeriodType} placeholder="Select Period"
                                        style={{ width: '100px' }} onChange={(value) => { this.setState({ authorityPeriodType: value }) }} >
                                            <Select.Option value="">Select</Select.Option>
                                            <Select.Option value="day">Day</Select.Option>
                                            <Select.Option value="week">Week</Select.Option>
                                            <Select.Option value="month">Month</Select.Option>
                                            <Select.Option value="year">Year</Select.Option>
                                        </Select>
                                    </Form.Item>
                                } */}

                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Designation:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <MultiCascader
                                            data={this.state.designationCascader}
                                            style={{ width: '100%' }}
                                            onOpen={this.showMultiCascader}
                                            onExit={this.hideMultiCascader}
                                            value={this.state.valuesDesignation}
                                            onChange={this.multiCascaderChange} />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                            </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Item Group:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <Select value={this.state.itemGroup} placeholder="Select" style={{ width: 200 }}
                                            onChange={this.handleChangeItemGroup} >
                                            <Select.Option value="">Select</Select.Option>
                                            <Select.Option value="Material">Material</Select.Option>
                                            <Select.Option value="Equipment">Equipment</Select.Option>
                                            <Select.Option value="Spare Parts">Spare Parts</Select.Option>
                                            <Select.Option value="Office Staff">Office Staff</Select.Option>
                                            <Select.Option value="Stationary">Stationary</Select.Option>
                                            <Select.Option value="Subcontracting">Subcontracting</Select.Option>
                                            <Select.Option value="Service">Service</Select.Option>
                                            <Select.Option value="Repair">Repair</Select.Option>
                                            <Select.Option value="Furniture">Furniture</Select.Option>
                                        </Select>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton id="PurchasingAuthorityNewItemGroup" style={{ width: '100%' }} variant="info">
                                            <FormattedMessage id="GeneralButtonAddNew" defaultMessage="Add New" />
                                        </ReactButton>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Item:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <MultiCascader
                                            data={this.state.itemCascader}
                                            style={{ width: '100%' }}
                                            onOpen={this.showMultiCascader}
                                            onExit={this.hideMultiCascader}
                                            value={this.state.valuesItem}
                                            onChange={this.multiCascaderChange1} />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Category:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="5">
                                        <Checkbox.Group value={this.state.selectedCategory} options={this.state.categoryOptions} onChange={this.onChangeCategoryOptions} />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Authority:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="5">
                                        <Checkbox.Group value={this.state.selectedAuthority} options={this.state.authorityOptions} onChange={this.onChangeAuthorityOptions} />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Price:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <Space>
                                            <InputNumberAnt min={1} value={this.state.priceMin} onChange={(value) => { this.setState({ priceMin: value }) }} />
                                            <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                -
                                            </FormBoot.Label>
                                            <InputNumberAnt min={1} value={this.state.priceMax} onChange={(value) => { this.setState({ priceMax: value }) }} />
                                            <Select value={this.state.priceCurrency} placeholder="Select a Currency" onChange={(value) => { this.setState({ priceCurrency: value }) }} >
                                                <Option value="$">$</Option>
                                                <Option value="€">€</Option>
                                                <Option value="SR">SR</Option>
                                            </Select>
                                        </Space>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Quantity:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <InputNumber
                                            size={15}
                                            id="horizontal"
                                            value={this.state.quantity}
                                            onValueChange={(e) => this.setState({ quantity: e.value })}
                                            showButtons
                                            buttonLayout="horizontal"
                                            step={1}
                                            min={0}
                                            decrementButtonClassName="p-button-danger"
                                            incrementButtonClassName="p-button-success"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"
                                        />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Authority Period:
                                </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <Space>
                                            <InputNumberAnt min={1} value={this.state.authorityPeriodValue} onChange={(value) => { this.setState({ authorityPeriodValue: value }) }} />
                                            <Select value={this.state.authorityPeriodType} placeholder="Select Period"
                                                onChange={(value) => { this.setState({ authorityPeriodType: value }) }} >
                                                <Select.Option value="">Select</Select.Option>
                                                <Select.Option value="day">Day</Select.Option>
                                                <Select.Option value="week">Week</Select.Option>
                                                <Select.Option value="month">Month</Select.Option>
                                                <Select.Option value="year">Year</Select.Option>
                                            </Select>
                                        </Space>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={this.savePurchasingAuthority} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PurchasingAuthorityCancelButton"
                                                    style={{ width: '100%' }} onClick={this.cancelPA} variant="secondary"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PurchasingAuthorityUpdateButton"
                                                    style={{ width: '100%' }} onClick={this.savePurchasingAuthority} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                            </Form>
                        </div>

                        <Table style={{ padding: "4px 1rem 0px 4px" }} dataSource={this.state.purchasingAuthorityList} bordered>
                            <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                            <Column title="Designation" key="departmentDesignationIds" render={(text, record) => {
                                var foundedValues = "";
                                if (record.departmentDesignationIds !== "") {
                                    var depDesIds = record.departmentDesignationIds.split(",");
                                    for (let index1 = 0; index1 < this.state.designationCascader.length; index1++) {
                                        for (let index = 0; index < depDesIds.length; index++) {
                                            var deger = this.searchCascader(this.state.designationCascader[index1], depDesIds[index].toString());
                                            if (deger !== null) {
                                                if (foundedValues === "")
                                                    foundedValues += deger.label;
                                                else
                                                    foundedValues += "," + deger.label;
                                            }
                                        }
                                    }
                                    return foundedValues;
                                }
                                else {
                                    return "";
                                }
                            }} />
                            <Column title="Item Group" key="itemGroup" dataIndex="itemGroup" />
                            <Column title="Purchasing Item" key="purchasingItem" render={(text, record) => {
                                var foundedValues = "";
                                if (record.itemIds !== "") {
                                    var items = record.itemIds.split(",");
                                    if (record.itemGroup === "Material") {
                                        for (let index1 = 0; index1 < this.state.materialCascaderData.length; index1++) {
                                            for (let index = 0; index < items.length; index++) {
                                                var deger = this.searchCascader(this.state.materialCascaderData[index1], items[index].toString());
                                                if (deger !== null) {
                                                    if (foundedValues === "")
                                                        foundedValues += deger.label;
                                                    else
                                                        foundedValues += " , " + deger.label;
                                                }
                                            }
                                        }
                                    }

                                    if (record.itemGroup === "Equipment") {
                                        for (let index1 = 0; index1 < this.state.equipmentCascaderData.length; index1++) {
                                            for (let index = 0; index < items.length; index++) {
                                                var deger = this.searchCascader(this.state.equipmentCascaderData[index1], items[index].toString());
                                                if (deger !== null) {
                                                    if (foundedValues === "")
                                                        foundedValues += deger.label;
                                                    else
                                                        foundedValues += " , " + deger.label;
                                                }
                                            }
                                        }
                                    }
                                    return foundedValues;
                                }
                                else {
                                    return "";
                                }
                            }} />
                            <Column title="Category" key="category" dataIndex="categories" />
                            <Column title="Authority" key="authority" dataIndex="authorities" />
                            <Column title="Price" key="price" render={(text, record) => {
                                if (record.minPrice === "" || record.maxPrice === "" || record.currency === "")
                                    return "";
                                else
                                    return record.minPrice + "-" + record.maxPrice + " " + record.currency;
                            }} />
                            <Column title="Quantity" key="quantity" dataIndex="quantity" />
                            <Column title="Authority Period" key="authorityPeriod" render={(text, record) => {
                                if (record.authorityPeriod === "" || record.authorityPeriodType === "")
                                    return "";
                                else
                                    return record.authorityPeriod + " " + record.authorityPeriodType;
                            }} />
                            <Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => {
                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                        if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                            (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                            return (<div style={{ textAlign: "center" }}>
                                                <React.Fragment key={record.id} >
                                                    <CustomMaterialMenuDeleteEdit row={record}
                                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />
                                                </React.Fragment>
                                            </div>);
                                        }
                                        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuEdit row={record}
                                                    onEditRow={this.changeButtonUpdate.bind(this)} />
                                            </React.Fragment>);
                                        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuDelete row={record}
                                                    onDeleteRow={this.deleteModal.bind(this)} />
                                            </React.Fragment>);
                                        }
                                    }
                                    else {
                                        return (<div style={{ textAlign: "center" }}>
                                        </div>);
                                    }
                                }}
                            />
                        </Table>
                    </Card>}
               </MenuProvider>
                {!this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingAuthority)