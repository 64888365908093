/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import DatePicker from 'react-date-picker';
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { handleRequest } from '../../redux/config/env';
import memoize from 'memoize-one';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { IconButton  } from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import {OrganizationChart} from 'primereact/organizationchart';
import { Link } from 'react-router-dom';


const sortIcon = <ArrowDownward />;
const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton> 
);

       


const columnsContact = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />, 
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Location",
        selector: "location", 
        sortable: true,
      //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Address",
        selector: "address",
        sortable: true,
      //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Phone",
        selector: "phone",
        sortable: true 
    },
    {
        name: "Fax",
        selector: "fax",
        sortable: true 
    },
    {
        name: "E-Mail",
        selector: "email",
        sortable: true,
 
    },
    {
        name: 'Social',
        selector: 'socialAccount', 
        sortable: true,  
 
    }
]);


const columnsCertificates = memoize((a, b) => [
    {
        cell: row => <CustomMaterialMenu row={row} onDeleteRow={a} onEditRow={b} />, 
        allowOverflow: true,
        button: true,
        width: '56px',
    },
    {
        name: "Name of Certificate",
        selector: "certificate",
        sortable: true,
      //  cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
    },
    {
        name: "Validity",
        selector: "validity", 
        sortable: true 
    },
    {
        name: "Responsible Department", 
        selector: "responsibleDepartment", 
        sortable: true 
    }
]);

export class CompanyDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            certificatesList:[], 
            contactList:[], 
            modalContactShow: false,     
            modalCertificatesShow: false,      
            modalConfirmShow : false,      

            contactId:0,
            location: "Select",
            address: "",
            phone: "",
            fax:  "",
            web: "",
            email:  "",
            social:  "",          
       
            certificatesId:0,
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
            orgChartNodes:[{
                label: '',
                className: "",
                expanded: true,
                children: [

                ]}]
        };

        // #region tags functions

     

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        // #endregion tags functions
        this.restartContactTable();
      
        this.restartCertificatesTable();
 
        // #endregion tags functions
    }

    // #region tags
    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    restartContactTable = async () => {

        const response = await handleRequest("GET", "/CompanyContact/getAll");

        if (response.data.length === 0) { 
            this.setState({
                contactList: []

            });
        }

        if (response.data.length !== 0) {  
            this.setState({
                contactList: response.data .filter(p=>p.companyId===this.props.row.id)  
            });
        }
    }

    restartOrgChart = async () => {

        const responseTree  = await handleRequest("GET", "/Department/getTreeModel"); 

        console.log(responseTree.data);  
        if (responseTree.data.length !== 0) {
            this.setState({
                orgChartNodes:responseTree.data.cascader 
            });
        
           
        }
    }

    addContact = () => {
        console.log("Geldi");
        this.setState({
            
            contactId:0,
            location: "Select",
            address: "",
            phone: "", 
            fax:  "",
            web: "",
            email:  "",
            social:  "",
            modalContactShow : true      
            
            
        });
    }

    saveContact = async () => {

        
       
        if (this.state.location.trim() !== "Select" ) {

            const newItem = {
                id: this.state.contactId, 
                companyId:this.props.row.id,
                location: this.state.location,
                address: this.state.address,
                phone:this.state.phone,
                fax:this.state.fax,
                web:this.state.web,
                email:this.state.email,
                socialAccount:this.state.social 

            }

            if (newItem.id === 0)
                await await handleRequest("POST", "/CompanyContact/add", newItem);
            else await await handleRequest("POST", "/CompanyContact/update", newItem);

            this.resetContactInputs(); 
            this.restartContactTable();  
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetContactInputs = () => {
        this.setState({
            contactId:0,
            location: "Select",
            address: "",
            phone: "",
            fax:  "",
            web: "",
            email:  "",
            social:  "", 
            modalContactShow : false ,
            modalConfirmShow:false
        });
    };


    editContact = (row) => {  
       
        console.log(row); 

        this.setState({
          
            contactId:row.id,
            location: row.location,
            address: row.address,
            phone: row.phone,
            fax:  row.fax,
            web: row.web,
            email:  row.email,
            social:  row.socialAccount,
            modalContactShow : true,                 
           
        });        
    }

    deleteContactModal = (row) => {
        
        this.setState({
           contactId : row.id ,
           modalConfirmShow : true  
        });
    } 

    delete =  async ()  => { 

     
        if (this.state.contactId>0) {
            
            const deletedItem = {
                id: this.state.contactId
    
            }
    
            await handleRequest("POST", "/CompanyContact/delete", deletedItem);
    
            this.restartContactTable();
            this.resetContactInputs();
            this.showSuccess();
        }

        if (this.state.certificatesId>0) {
            
            const deletedItem = {
                id: this.state.certificatesId
    
            }
    
            await handleRequest("POST", "/CompanyCertificates/delete", deletedItem);
    
            this.restartCertificatesTable();
            this.resetCertificatesInputs();
            this.showSuccess(); 
        }
        
       
    }

    restartCertificatesTable = async () => {

        const response = await handleRequest("GET", "/CompanyCertificates/getAll");

        if (response.data.length === 0) { 
            this.setState({
                certificatesList: []

            });
        }

        if (response.data.length !== 0) {  
            this.setState({
                certificatesList: response.data.filter(p=>p.companyId===this.props.row.id)  
            });
        }
    }

  

    addCertificates = () => {
        
        this.setState({ 
            
            certificatesId:0,
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
            modalCertificatesShow : true      
            
            
        });
    }

    saveCertificates = async () => {

      
        
       
        if (this.state.nameOfCertificate.trim() !== "Select" ) {

            const newItem = {
                id: this.state.certificatesId, 
                companyId: this.props.row.id,
                certificate: this.state.nameOfCertificate,
                validity: this.state.date,
                responsibleDepartment :  this.state.respDepartment 

            }

            if (newItem.id === 0)
                await await handleRequest("POST", "/CompanyCertificates/add", newItem);
            else await await handleRequest("POST", "/CompanyCertificates/update", newItem); 

            this.resetCertificatesInputs(); 
            this.restartCertificatesTable();   
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetCertificatesInputs = () => {
        this.setState({
            certificatesId:0,
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
            modalCertificatesShow : false ,
            modalConfirmShow:false  
           
        });
    };

 
    editCertificates = (row) => {  
       
        this.setState({
          
            certificatesId:row.id,
            nameOfCertificate: row.certificate,
            date: new Date(row.validity), 
            respDepartment: row.responsibleDepartment,            
            modalCertificatesShow : true                
            
        });         
    }

    deleteCertificatesModal = (row) => {
        
        this.setState({ 
           contactId : row.id , 
           modalConfirmShow : true  
        });
    } 



    handleInputChangeP(evt) {
        this.setState({ inputP: evt.target.value });
    }

    handleInputKeyDownP(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                phones: [...state.phones, value],
                inputP: ''
            }));
        }

        if (this.state.phones.length && evt.keyCode === 8 && !this.state.inputP.length) {
            this.setState(state => ({
                phones: state.phones.slice(0, state.phones.length - 1)
            }));
        }
    }

    handleRemoveItemP(index) {
        return () => {
            this.setState(state => ({
                phones: state.phones.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeF(evt) {
        this.setState({ inputF: evt.target.value });
    }

    handleInputKeyDownF(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                faxes: [...state.faxes, value],
                inputF: ''
            }));
        }

        if (this.state.faxes.length && evt.keyCode === 8 && !this.state.inputF.length) {
            this.setState(state => ({
                faxes: state.faxes.slice(0, state.faxes.length - 1)
            }));
        }
    }

    handleRemoveItemF(index) {
        return () => {
            this.setState(state => ({
                faxes: state.faxes.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeW(evt) {
        this.setState({ inputW: evt.target.value });
    }

    handleInputKeyDownW(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                webs: [...state.webs, value],
                inputW: ''
            }));
        }

        if (this.state.webs.length && evt.keyCode === 8 && !this.state.inputW.length) {
            this.setState(state => ({
                webs: state.webs.slice(0, state.webs.length - 1)
            }));
        }
    }

    handleRemoveItemW(index) {
        return () => {
            this.setState(state => ({
                webs: state.webs.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeE(evt) {
        this.setState({ inputE: evt.target.value });
    }

    handleInputKeyDownE(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                emails: [...state.emails, value],
                inputE: ''
            }));
        }

        if (this.state.emails.length && evt.keyCode === 8 && !this.state.inputE.length) {
            this.setState(state => ({
                emails: state.emails.slice(0, state.emails.length - 1)
            }));
        }
    }

    handleRemoveItemE(index) {
        return () => {
            this.setState(state => ({
                emails: state.emails.filter((item, i) => i !== index)
            }));
        }
    }

    handleInputChangeS(evt) {
        this.setState({ inputS: evt.target.value });
    }

    handleInputKeyDownS(evt) {
        if (evt.keyCode === 13) {
            const { value } = evt.target;

            this.setState(state => ({
                socials: [...state.socials, value],
                inputS: ''
            }));
        }

        if (this.state.socials.length && evt.keyCode === 8 && !this.state.inputS.length) {
            this.setState(state => ({
                socials: state.socials.slice(0, state.socials.length - 1)
            }));
        }
    }

    handleRemoveItemS(index) {
        return () => {
            this.setState(state => ({
                socials: state.socials.filter((item, i) => i !== index)
            }));
        }
    }

    // #endregion tags

    onChange = date => this.setState({ date })

   

    showOrHideConfirmModal= () => {
        this.setState({
            modalConfirmShow: !this.state.modalConfirmShow          
        }); 
    }

   
    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    showOrHideContactModal = () => {
        this.setState({
            modalContactShow: !this.state.modalContactShow  
        });
        this.resetContactInputs();
    }

    showOrHideCertificatesModal = () => {
        this.setState({
            modalCertificatesShow: !this.state.modalCertificatesShow  
        }); 
        this.resetCertificatesInputs();

    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onEditClick(e) {
        if (e.target.value === undefined)
            e.stopPropagation();
    }

    companyLogoChange = e => {
        this.setState({
            companyLogoName: e.target.files[0].name
        });
    }

    aboutCompanyChange = e => {
        this.setState({
            aboutCompanyName: e.target.files[0].name
        });
    }

    missionChange = e => {
        this.setState({
            missionName: e.target.files[0].name
        });
    }

    visionChange = e => {
        this.setState({
            visionName: e.target.files[0].name
        });
    }

    certificateChange = e => {
        this.setState({
            certificate: e.target.files[0].name
        });
    }

    render() {
        const styles = {
            container: {
                border: '1px solid #ddd',
                padding: '5px',
                borderRadius: '5px',
            },
            items: {
                display: 'inline-block',
                padding: '2px',
                border: '1px solid blue',
                fontFamily: 'Helvetica, sans-serif',
                borderRadius: '5px',
                marginRight: '5px',
                cursor: 'pointer'
            },
            input: {
                outline: 'none',
                border: 'none',
                fontSize: '14px',
                fontFamily: 'Helvetica, sans-serif'
            }
        };
        return (
            <div style={{ padding: "15px" }} className="row"> 
                <Toast ref={(el) => this.toast = el} />

                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      
                            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.modalConfirmShow} 
                                onHide={this.showOrHideConfirmModal} 
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete </Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                    <p>All informations about selected item will be deleted. Are you sure?</p> 
                                </Modal.Body> 
                                <Modal.Footer> 
                                    <Button variant="secondary" onClick={this.showOrHideConfirmModal}>Close</Button> 
                                    <Button variant="danger" onClick={this.delete} >Delete</Button>   
                                </Modal.Footer>
                            </Modal>
                       
                    </Row>
                </Container>
            
                <div className="col-md-12">

<Accordion defaultActiveKey="1">
    <Card>
        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
            <Row >
                <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Contact Info</Card.Header></Col>
                <Col xs={2}>
                </Col>
            </Row>
        </Accordion.Toggle>
        <Accordion.Collapse id="contactAccordion" eventKey="0">
            <Card.Body>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={10}></Col>
                        <Col xs={2}><Button onClick={this.addContact} style={{ width: '100%' }} variant="info">Add New</Button></Col>
                    </Row>
                   
                  </Container>
                <DataTable style={{  marginTop: '3rem'}} 
                    noHeader
                    columns={columnsContact( this.deleteContactModal, this.editContact)}     
                    data={this.state.contactList}   
                    sortIcon={sortIcon}
                    pagination                
                    persistTableHead                  
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    // subHeader
                    // subHeaderComponent={
                    //     (
                    //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                    //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                    //             <Icon1 style={{ margin: '5px' }} color="action" />
                    //             <Icon2 style={{ margin: '5px' }} color="action" />
                    //             <Icon3 style={{ margin: '5px' }} color="action" />
                    //         </div>
                    //     )
                    // }
                    actions={actions}
                    onRowClicked={this.handleRowClicked}
                    onSelectedRowsChange={this.handleChangeRow}
                />

<Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={12}> 
                           
                            <Modal
                                show={this.state.modalContactShow}
                                onHide={this.showOrHideContactModal} 
                                aria-labelledby="contained-modal-title-vcenter"
                                size="xl"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Company Contact Detail</Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                <Form>
                                
                        <Form.Group as={Row} controlId="formGridLocation">
                        <Col sm="2">
                            
                                <Form.Label style={{ color: 'black' }}  >Location</Form.Label>
                               
                            </Col>
                            <Col sm="6">  
                            <Form.Control name="location" value={this.state.location}
                                onChange={this.handleChange} as="select">
                                <option>Select</option>                              
                                <option>Head Office</option>  
                                <option>Branche</option>
                            </Form.Control>
                            </Col> 
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridAddress">
                        <Col sm="2">    
                       <div style={{ textAlign: 'left' }}>                        
                                <Form.Label style={{ color: 'black' }}  >Address</Form.Label>        
                                </div>                   
                            </Col> 
                            <Col sm="6">
                             <Form.Control as="textarea"  rows="3"  name="address" value={this.state.address}
                                onChange={this.handleChange} />
                            </Col>

                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridPhone">
                        <Col sm="2">    
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Phone</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">    
                             <Form.Control  value={this.state.phone}  onChange={(e) => this.setState({ phone: e.target.value })}
                                               />   
                                               </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridFax">
                        <Col sm="2">    
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Fax</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">    
                            <Form.Control  value={this.state.fax}  onChange={(e) => this.setState({ fax: e.target.value })}
                                               />   
                             </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridWeb">
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Web</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <Form.Control  value={this.state.web}  onChange={(e) => this.setState({ web: e.target.value })}
                                               />    
                                               </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridEmail">
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>E-Mail</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <Form.Control  value={this.state.email}  onChange={(e) => this.setState({ email: e.target.value })}
                                               />   
                                               </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridFax"> 
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Social Accounts</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <Form.Control  value={this.state.social}  onChange={(e) => this.setState({ social: e.target.value })}
                                               />   
                                               </Col>
                        </Form.Group>
                      </Form>
               
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.showOrHideContactModal}>Close</Button>
                                    <Button variant="info" onClick={this.saveContact} >Save</Button>  
                                </Modal.Footer>
                            </Modal></Col> 
                     
                      
                    </Row>
                </Container>
            </Card.Body>
        </Accordion.Collapse>
    </Card>
</Accordion>
                  
<br />
<Accordion defaultActiveKey="1">
    <Card>
        <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
            <Row >
                <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Certificates</Card.Header></Col>
                <Col xs={2}>
                </Col>
            </Row>
        </Accordion.Toggle>
        <Accordion.Collapse id="certificateAccordion" eventKey="0">
            <Card.Body>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={10}></Col>
                        <Col xs={2}><Button onClick={this.addCertificates} style={{ width: '100%' }} variant="info">Add New</Button></Col>
                    </Row>
                   
                   
                </Container>

                <DataTable style={{  marginTop: '3rem'}} 
                    noHeader
                    columns={columnsCertificates( this.deleteCertificatesModal, this.editCertificates)}     
                    data={this.state.certificatesList}     
                    sortIcon={sortIcon}
                    pagination                
                    persistTableHead                  
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    // subHeader
                    // subHeaderComponent={
                    //     (
                    //         <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    //             <div style={{ marginRight: '1rem' }}><ReactButton onClick={this.showFilters} variant="info"><GoSearch /></ReactButton></div>
                    //             <TextField id="outlined-basic" label="Search" variant="outlined" size="small" />
                    //             <Icon1 style={{ margin: '5px' }} color="action" />
                    //             <Icon2 style={{ margin: '5px' }} color="action" />
                    //             <Icon3 style={{ margin: '5px' }} color="action" />
                    //         </div>
                    //     )
                    // }
                    actions={actions}
                    onRowClicked={this.handleRowClicked}
                    onSelectedRowsChange={this.handleChangeRow}
                />

<Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={12}> 
                           
                            <Modal
                                show={this.state.modalCertificatesShow}
                                onHide={this.showOrHideCertificatesModal}  
                                aria-labelledby="contained-modal-title-vcenter"
                                size="xl"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Company Certificate Detail</Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                <Form>
                                
                        <Form.Group as={Row}   controlId="formGridNameOfCertificate">
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }} >Name of Certificate</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <Form.Control name="nameOfCertificate" value={this.state.nameOfCertificate}
                                onChange={this.handleChange} as="select">
                                <option>Select</option>                              
                                <option>ISO 9001</option>
                                <option>ISO 14001</option>
                                <option>ISO 18001</option>
                            </Form.Control>
                            </Col>
                        
                        </Form.Group>
                        
                       
                        <Form.Group as={Row}   controlId="formGridValidity">
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Validity</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <div style={{ textAlign: 'left' }}> 
                            <Calendar dateFormat="dd/mm/yy" value={this.state.date} showButtonBar={true} onChange={(e) => this.setState({ date: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1950:2025" />
                                {/* <DatePicker
                                    onChange={this.onChange}
                                    value={this.state.date}
                                /> */}

                            </div>
                        </Col>
                        </Form.Group>
                       
                       
                        <Form.Group  as={Row}  controlId="formGridRespDepartment">
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Responsible Department</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <Form.Control name="respDepartment" value={this.state.respDepartment}
                              onChange={(e) => this.setState({ respDepartment: e.target.value })} />
                              </Col> 
                        </Form.Group>
                       
                        <Form.Group as={Row}  controlId="formGridCertificate">
                        <Col sm="2">   
                            <div style={{ textAlign: 'left' }}>
                                <Form.Label style={{ color: 'black' }}>Certificate</Form.Label>
                            </div>
                            </Col>
                            <Col sm="6">   
                            <div style={{ textAlign: 'center' }}>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        className='custom-file-input'
                                        id='customFile'
                                        onChange={this.certificateChange}
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        {this.state.certificate}
                                    </label>
                                </div>
                            </div>
                            </Col>
                        </Form.Group>
                   
                        
                      </Form> 
               
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.showOrHideCertificatesModal}>Close</Button>
                                    <Button variant="info" onClick={this.saveCertificates} >Save</Button>   
                                </Modal.Footer>
                            </Modal></Col> 
                     
                      
                    </Row>
                </Container>
            </Card.Body>

           
        </Accordion.Collapse>
    </Card>
</Accordion>
<br />
<Accordion defaultActiveKey="1">
    <Card>
        <Accordion.Toggle onClick={this.restartOrgChart} style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
            <Row>
                <Col xs={10}><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Organization</Card.Header></Col>
                <Col xs={2}>
                </Col>
            </Row>
        </Accordion.Toggle>
        <Accordion.Collapse id="organization" eventKey="0">
            <Card.Body>
                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    
                    <Row>
                        <Col xs={12}>
                        
                        <OrganizationChart value={this.state.orgChartNodes} selectionMode="single" selection={this.state.selectedNode} onSelectionChange={event => this.setState({ selectedNode: event.data })}></OrganizationChart>

                        </Col>
                       
                    </Row>
                    <Row>
                        <Col xs={3}></Col>
                        <Col xs={6}>
                        <Link
                      to={{
                        pathname: "/Department"                      
                      }}
                    >     <Button style={{ width: '100%' }} variant="info"> Create Organization Chart</Button>
                    </Link>
                         </Col>
                        <Col xs={3}></Col>
                    </Row>
                </Container>
            </Card.Body>
        </Accordion.Collapse>
    </Card>
</Accordion>
</div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail)
